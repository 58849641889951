import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { TopNavBar } from '../../Home/navigation/components/TopNavBar';
import { SettingsStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { WithAuthenticationLegalNameChange } from '../../PersonalDetails/screens/LegalNameChange';
import { useView } from '../../utils/hooks/useBreakpoint';
import { useExpoAuth } from '../../utils/hooks/useExpoAuth';
import { WithAuthenticationConsentHistoryDetails } from '../screens/ConsentHistoryDetails';
import { WithAuthenticationDailyTransferLimit } from '../screens/DailyTransferLimit';
import { WithAuthenticationDataSharingDetails } from '../screens/DataSharingDetails';
import { WithAuthenticationManageDataSharing } from '../screens/ManageDataSharing';
import { WithAuthenticationManageFaceId } from '../screens/ManageFaceId';
import { WithAuthenticationNotifications } from '../screens/Notifications';
import { WithAuthenticationPersonalDetails } from '../screens/PersonalDetails';
import { WithAuthenticationSecurity } from '../screens/Security';
import { WithAuthenticationSettings } from '../screens/Settings';
import { WithAuthenticationSettingsBankAccountDetails } from '../screens/SettingsBankAccountDetails';
import { WithAuthenticationSettingsBankProfile } from '../screens/SettingsBankProfile';
import { WithAuthenticationSettingsConnectedBanks } from '../screens/SettingsConnectedBanks';
import { WithAuthenticationSettingsDataSharingNotificationPreferences } from '../screens/SettingsDataSharingNotificationPreferences';
import { WithAuthenticationSettingsDataSharingPreferences } from '../screens/SettingsDataSharingPreferences';
import { WithAuthenticationSettingsJointAccountPreferences } from '../screens/SettingsJointAccountPreferences';
import { WithAuthenticationSettingsNewFeatures } from '../screens/SettingsNewFeatures';
import { WithAuthenticationSettingsDataSharingAccountNotificationPreferences } from '../screens/WithAuthenticationSettingsDataSharingAccountNotificationPreferences';

const SettingsStack = createStackNavigator<SettingsStackParams>();

export function SettingsNavigator() {
  const navigation = useNavigation();
  const { isMobileNativeView, isDesktopWebView } = useView();

  const { supportFaceId } = useExpoAuth();

  return (
    <SettingsStack.Navigator
      initialRouteName={Screen.SETTINGS_HOME}
      screenOptions={{
        detachPreviousScreen: !navigation.isFocused(),
        header: TopNavBar,
        headerTransparent: true,
        headerShown: isMobileNativeView || isDesktopWebView,
      }}
    >
      <SettingsStack.Screen
        name={Screen.SETTINGS_HOME}
        component={WithAuthenticationSettings}
        options={{
          title: getPageTitle(Screen.SETTINGS_HOME),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_PERSONAL}
        component={WithAuthenticationPersonalDetails}
        options={{
          title: getPageTitle(Screen.SETTINGS_PERSONAL),
        }}
      />
      {supportFaceId ? (
        <SettingsStack.Screen
          name={Screen.SETTINGS_SECURITY}
          component={WithAuthenticationSecurity}
          options={{
            title: getPageTitle(Screen.SETTINGS_SECURITY),
          }}
        />
      ) : null}
      <SettingsStack.Screen
        name={Screen.SETTINGS_SECURITY_FACE_ID}
        component={WithAuthenticationManageFaceId}
        options={{
          title: getPageTitle(Screen.SETTINGS_SECURITY_FACE_ID),
        }}
      />
      <SettingsStack.Screen
        name={Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE}
        component={WithAuthenticationLegalNameChange}
        options={{
          title: getPageTitle(Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_DAILY_TRANSFER_LIMIT}
        component={WithAuthenticationDailyTransferLimit}
        options={{
          title: getPageTitle(Screen.SETTINGS_DAILY_TRANSFER_LIMIT),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_NOTIFICATION}
        component={WithAuthenticationNotifications}
        options={{
          title: getPageTitle(Screen.SETTINGS_NOTIFICATION),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_CONNECTED_BANKS}
        component={WithAuthenticationSettingsConnectedBanks}
        options={{
          title: getPageTitle(Screen.SETTINGS_CONNECTED_BANKS),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_BANK_PROFILE}
        component={WithAuthenticationSettingsBankProfile}
        options={{
          title: getPageTitle(Screen.SETTINGS_BANK_PROFILE),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_BANK_ACCOUNT_DETAIL}
        component={WithAuthenticationSettingsBankAccountDetails}
        options={{
          title: getPageTitle(Screen.SETTINGS_BANK_ACCOUNT_DETAIL),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_MANAGE_DATA_SHARING}
        component={WithAuthenticationManageDataSharing}
        options={{
          title: getPageTitle(Screen.SETTINGS_MANAGE_DATA_SHARING),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_DATA_SHARING_DETAILS}
        component={WithAuthenticationDataSharingDetails}
        options={{
          title: getPageTitle(Screen.SETTINGS_DATA_SHARING_DETAILS),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_DATA_SHARING_PREFERENCES}
        component={WithAuthenticationSettingsDataSharingPreferences}
        options={{
          title: getPageTitle(Screen.SETTINGS_DATA_SHARING_PREFERENCES),
        }}
      />
      <SettingsStack.Screen
        name={
          Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST
        }
        component={WithAuthenticationSettingsDataSharingNotificationPreferences}
        options={{
          title: getPageTitle(
            Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST,
          ),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY}
        component={WithAuthenticationConsentHistoryDetails}
        options={{
          title: getPageTitle(Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES}
        component={
          WithAuthenticationSettingsDataSharingAccountNotificationPreferences
        }
        options={{
          title: getPageTitle(
            Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES,
          ),
        }}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES}
        component={WithAuthenticationSettingsJointAccountPreferences}
        options={({
          route: {
            params: { name },
          },
        }) => ({
          title: name,
        })}
      />
      <SettingsStack.Screen
        name={Screen.SETTINGS_NEW_FEATURES}
        component={WithAuthenticationSettingsNewFeatures}
        options={{
          title: getPageTitle(Screen.SETTINGS_NEW_FEATURES),
        }}
      />
    </SettingsStack.Navigator>
  );
}
