import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const UserProfileIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    width={resizeIconWidth(32, 33, size)}
    height={size}
    viewBox="0 0 32 33"
    fill="none"
    {...props}
  >
    <Path
      d="M16 32.6562C24.7188 32.6562 31.9375 25.4375 31.9375 16.7188C31.9375 8.01562 24.7031 0.78125 15.9844 0.78125C7.28125 0.78125 0.0625 8.01562 0.0625 16.7188C0.0625 25.4375 7.29688 32.6562 16 32.6562ZM16 22.0469C11.2969 22.0469 7.6875 23.7188 6.09375 25.5938C4 23.25 2.73438 20.1406 2.73438 16.7188C2.73438 9.35938 8.60938 3.4375 15.9844 3.4375C23.3594 3.4375 29.2656 9.35938 29.2812 16.7188C29.2969 20.1562 28.0156 23.2656 25.9062 25.6094C24.3281 23.7344 20.7031 22.0469 16 22.0469ZM16 19.3906C19 19.4219 21.3594 16.8594 21.3594 13.5C21.3594 10.3438 19 7.71875 16 7.71875C13 7.71875 10.625 10.3438 10.6406 13.5C10.6562 16.8594 13 19.3594 16 19.3906Z"
      fill={color}
    />
  </Svg>
);
