import { useGetSuggestedAddressFromDomainApiLazyQuery } from '../../generated/graphql';
import { SuggestionDataLoader } from '../../ui/v2/PropertyInput';
import { isNotNullOrUndefined } from '../arrayHelpers';
import { mapDomainAPIAddressToAppAddress } from '../mapToAppAddress';

export function usePropertySuggestionLoader(): SuggestionDataLoader {
  // TODO(uiv2): handle error when address suggestion fails
  const [getSuggestions, { loading, data, error: _error }] =
    useGetSuggestedAddressFromDomainApiLazyQuery();

  return {
    isLoadingSuggestionData: loading,
    loadSuggestionData: async ({ suggestionCount, suggestionSearchText }) => {
      await getSuggestions({
        variables: {
          search: suggestionSearchText,
          resultLimit: suggestionCount,
        },
      });
    },
    propertySuggestionData: data?.suggested_domain_api_addresses
      ?.filter(isNotNullOrUndefined)
      .map((address) => mapDomainAPIAddressToAppAddress(address)),
  };
}
