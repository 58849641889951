import { SxProp } from 'dripsy';
import React from 'react';

import { WarningRow } from '../../components/WarningRow';

type ExpectedRentalIncomeUpdateWarningRowProps = {
  sx?: SxProp;
};

export function ExpectedRentalIncomeUpdateWarningRow({
  sx,
}: ExpectedRentalIncomeUpdateWarningRowProps) {
  return (
    <WarningRow
      sx={sx}
      message={t('Content.YourIncome.ExpectedRentalIncomeUpdateWarning')}
    />
  );
}
