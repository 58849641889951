import { View } from 'dripsy';
import * as React from 'react';

import { useTheme } from '../../ui/theme';

type HomeLoanColumnProps = {
  children?: React.ReactNode;
  isRight?: boolean;
};

export function HomeLoanColumn({ isRight, ...props }: HomeLoanColumnProps) {
  const { constants } = useTheme();

  return (
    <View
      sx={{
        width: '100%',
        maxWidth: constants.maxContentColumnWidth,
        mb: '$24',
      }}
      {...props}
    />
  );
}
