import { useResponsiveProp, useTheme } from '@shopify/restyle';
import * as React from 'react';
import { SvgCssUri } from 'react-native-svg';

import { isWeb } from '../../utils/platformUtils';
import { Theme } from '../theme';
import { Size } from '../types';
import { Box } from './Box';
import { Props as StyledImageProps, StyledImage } from './StyledImage';

const DEFAULT_AVATAR_URI = 'https://i.ibb.co/cYrLBPD/image.png';

export type Props = Omit<StyledImageProps, 'source'> & {
  source?: StyledImageProps['source'];
  url: string | undefined | null;
  size?: Size;
  square?: boolean;
};

export const Avatar: React.FC<Props> = ({
  url,
  source,
  size,
  square,
  ...props
}) => {
  const theme = useTheme<Theme>();

  const isSvg = url?.endsWith('.SVG') || url?.endsWith('.svg');

  const imgSource = source || { uri: url || DEFAULT_AVATAR_URI };

  const sizeProp = useResponsiveProp(size) || 'l';
  const avatarSize = theme.sizes[sizeProp];

  if (url && isSvg && !isWeb) {
    return (
      <Box
        width={avatarSize}
        height={avatarSize}
        borderRadius="rounded"
        {...(square && {
          borderRadius: 'card',
        })}
        {...props}
      >
        <SvgCssUri uri={url} width="100%" height="100%" />
      </Box>
    );
  }

  return (
    <StyledImage
      source={imgSource}
      width={avatarSize}
      height={avatarSize}
      borderRadius="rounded"
      {...(square && {
        borderRadius: 'card',
      })}
      {...props}
    />
  );
};
