import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function LiabilityCreditCardIcon({
  color,
  ...otherProps
}: SvgIconProps) {
  return (
    <Svg width={26} height={24} viewBox="0 0 26 24" fill="none" {...otherProps}>
      <Path
        d="M22.249 3.081H3.751c-2.245 0-3.373 1.117-3.373 3.33v1h25.244v-1c0-2.213-1.117-3.33-3.373-3.33zM4.771 18.1c-.633 0-1.052-.43-1.052-1.032V15.08c0-.612.419-1.031 1.052-1.031h2.632c.634 0 1.053.419 1.053 1.031v1.987c0 .602-.419 1.032-1.053 1.032H4.771zm-1.02 3.33h18.498c2.256 0 3.373-1.107 3.373-3.32V9.85H.378v8.26c0 2.213 1.128 3.32 3.373 3.32z"
        fill={color}
      />
    </Svg>
  );
}
