import { FetchResult, gql } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { addMonths } from 'date-fns';
import { Text } from 'dripsy';
import { useContext, useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  EditLiabilityV2ForLiabilityWithCurrentLiabilityMutationMutation,
  EditLiabilityV2ForLiabilityWithoutCurrentLiabilityMutationV2Mutation,
  EditLiabilityV2Query,
  Frequency_Enum,
  Liability_Type_Enum,
  Loan_Interest_Rate_Type_Enum,
  Loan_Interest_Rate_Type_Input_Enum,
  MergedLiabilityForEditLiabilityV2Fragment,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  State_Enum,
  Update_Current_Liability_Details_Input,
  Upsert_Detected_Liabilities_Details_V2_Input,
  useEditLiabilityV2ForLiabilityWithCurrentLiabilityMutationMutation,
  useEditLiabilityV2ForLiabilityWithoutCurrentLiabilityMutationV2Mutation,
  useEditLiabilityV2Query,
} from '../../generated/graphql';
import { HasuraAddress } from '../../LoanApplication/graphql/fragments';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import type {
  SingleModalNavigationScreenProp,
  SingleModalStackRoute,
} from '../../navigation/SingleModalNavigator';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { safelyFormatDate, safelyParseDate } from '../../utils/dateHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { isTopUpHomeLoan } from '../../utils/isTopUpHomeLoan';
import { mapHasuraAddressToAppAddress } from '../../utils/mapToAppAddress';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import {
  calculateAvailableBalanceValue,
  LoanDetailsForm,
  LoanDetailsFormField,
  LoanDetailsFormValues,
  LoanDetailsManualAddressFormField,
  pickBalanceAndLimitValue,
} from '../components/LoanDetailsForm';
import { TopUpHomeLoanLiabilityDetails } from '../components/TopUpHomeLoanLiabilityDetails';
import {
  getHeaderTextByLiabilityType,
  getLoanTermValue,
  getRepaymentFrequencyFromLiabilityType,
} from '../utils/debtsScreenUtils';
import { mapApplicantsToAccountOwnerOptions } from '../utils/liabilityRemoteDataHelpers';
import {
  getPropertyAddressFromFormValues,
  mapLoanDetailsFormValuesToDetectedLiabilityDetails,
} from '../utils/loanDetailsFormUtils';
import {
  splitMonthsIntoYearMonthParts,
  toMonthlyAmount,
} from '../utils/mergedLiabilityUtils';

export const EDIT_LIABILITY_V2_DOC = gql`
  query EditLiabilityV2(
    $loanApplicationId: uuid!
    $mergedLiabilityOrFilter: [merged_liability_bool_exp!]!
  ) {
    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      latest_full_name
      hecs_loan_balance
      user_identity_profile {
        id
        credit_checks(
          where: {
            state: { _eq: COMPLETED }
            loan_application_id: { _is_null: true }
          }
        ) {
          id
          created_at
        }
        credit_checks_by_loan_application: credit_checks(
          where: {
            _and: [
              {
                loan_application_id: { _eq: $loanApplicationId }
                state: { _eq: COMPLETED }
              }
            ]
          }
          order_by: { created_at: desc }
          limit: 1
        ) {
          id
          created_at
        }
      }
    }
    mergedLiability: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        _or: $mergedLiabilityOrFilter
      }
      limit: 1
    ) {
      ...MergedLiabilityForEditLiabilityV2
    }
    mergedLiabilities: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        dynamite_flagged_incorrect: { _is_null: true }
      }
    ) {
      id
      dynamite_applicant_ids
      dynamite_liability_type
    }
    institutions: institution(
      where: { provided_by_user_id: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    states: state {
      code
      name
    }
  }

  fragment MergedLiabilityForEditLiabilityV2 on merged_liability {
    id
    current_liability_id
    detected_liability_identifier

    customer_limit
    dynamite_account_number_source
    dynamite_account_number
    dynamite_applicant_ids
    dynamite_balance
    dynamite_institution_name
    dynamite_liability_type
    dynamite_limit
    dynamite_term_months_end_date
    dynamite_loan_term_months
    dynamite_interest_rate_type
    dynamite_fixed_rate_expiry_date
    dynamite_flagged_incorrect
    dynamite_for_refinancing
    dynamite_institution_id
    dynamite_interest_rate
    dynamite_repayment_amount
    dynamite_repayment_frequency
    dynamite_address {
      ...HasuraAddress
    }
    ccr_balance
  }
  ${HasuraAddress}
`;

export const EDIT_LIABILITY_V2_MUTATION_DOC = gql`
  mutation EditLiabilityV2ForLiabilityWithCurrentLiabilityMutation(
    $input: update_current_liability_details_input!
  ) {
    update_current_liability_details(data: $input) {
      current_liability_id
    }
  }

  mutation EditLiabilityV2ForLiabilityWithoutCurrentLiabilityMutationV2(
    $input: upsert_detected_liabilities_details_v2_input!
  ) {
    upsert_detected_liabilities_details_v2(input: $input) {
      success
      current_liability_ids
    }
  }
`;

function buildInitialFormValues(params: {
  mergedLiability: MergedLiabilityForEditLiabilityV2Fragment;
  liabilityManuallyAdded: boolean;
  allowEditAvailableBalanceField?: boolean;
}): LoanDetailsFormValues {
  const {
    mergedLiability: ml,
    liabilityManuallyAdded,
    allowEditAvailableBalanceField = false,
  } = params;

  const termInMonths = getLoanTermValue(ml);
  const [termInMonthsYearPart, termInMonthsMonthPart] =
    termInMonths != null
      ? splitMonthsIntoYearMonthParts(termInMonths)
      : [null, null];

  const monthlyRepaymentAmount =
    ml.dynamite_repayment_amount != null &&
    ml.dynamite_repayment_frequency != null
      ? toMonthlyAmount(
          ml.dynamite_repayment_amount,
          ml.dynamite_repayment_frequency as Frequency_Enum,
        )
      : null;

  const propertyAddress = ml?.dynamite_address
    ? mapHasuraAddressToAppAddress(ml.dynamite_address)
    : null;

  const { balance, limit } = pickBalanceAndLimitValue({
    liabilityType: ml.dynamite_liability_type as Liability_Type_Enum,
    mlBalance: ml.dynamite_balance,
    mlLimit: ml.dynamite_limit,
    forceUseLimit: !liabilityManuallyAdded,
  });

  const available = calculateAvailableBalanceValue({
    liabilityType: ml.dynamite_liability_type as Liability_Type_Enum,
    mlBalance: ml.dynamite_balance,
    mlLimit: ml.dynamite_limit,
    liabilityManuallyAdded,
    allowEditAvailableBalanceField,
  });

  return {
    [LoanDetailsFormField.AccountNumber]: ml.dynamite_account_number ?? '',
    [LoanDetailsFormField.AccountOwners]: ml.dynamite_applicant_ids ?? [],
    [LoanDetailsFormField.Balance]: balance ?? null,
    [LoanDetailsFormField.AvailableBalance]: available,
    [LoanDetailsFormField.LoanRateType]:
      (ml.dynamite_interest_rate_type as Loan_Interest_Rate_Type_Enum) ?? null,
    [LoanDetailsFormField.FixedRateExpiryDate]: safelyParseDate(
      ml.dynamite_fixed_rate_expiry_date,
      'yyyy-MM-dd',
    ),
    [LoanDetailsFormField.Institution]:
      ml.dynamite_institution_name != null
        ? {
            id: ml.dynamite_institution_id ?? undefined,
            name: ml.dynamite_institution_name,
          }
        : null,
    [LoanDetailsFormField.InterestRate]:
      ml.dynamite_interest_rate != null
        ? String(ml.dynamite_interest_rate)
        : null,
    [LoanDetailsFormField.Limit]: limit ?? null,
    [LoanDetailsFormField.MonthlyRepaymentAmount]: monthlyRepaymentAmount,
    [LoanDetailsFormField.PropertyAddress]: propertyAddress,
    [LoanDetailsFormField.TermInMonthsMonthPart]: termInMonthsMonthPart,
    [LoanDetailsFormField.TermInMonthsYearPart]: termInMonthsYearPart,

    [LoanDetailsManualAddressFormField.isManualInput]:
      propertyAddress?.isProvidedByUser ?? null,
    [LoanDetailsManualAddressFormField.postcode]:
      propertyAddress?.postcode ?? null,
    [LoanDetailsManualAddressFormField.state]:
      parseEnumType(State_Enum, propertyAddress?.state) ?? null,
    [LoanDetailsManualAddressFormField.streetName]:
      propertyAddress?.street ?? null,
    [LoanDetailsManualAddressFormField.streetNo]:
      propertyAddress?.streetNo ?? null,
    [LoanDetailsManualAddressFormField.streetType]: propertyAddress?.streetType
      ? { label: propertyAddress.streetType, value: propertyAddress.streetType }
      : null,
    [LoanDetailsManualAddressFormField.suburb]: propertyAddress?.suburb ?? null,
    [LoanDetailsManualAddressFormField.unitNo]: propertyAddress?.unitNo ?? null,
  };
}

function buildMutationInputWithCurrentLiability(
  {
    formValues,
    currentLiabilityId,
    liabilityType,
  }: {
    formValues: LoanDetailsFormValues;
    currentLiabilityId: string;
    liabilityType: Liability_Type_Enum;
  },
  allowEditAvailableBalanceField: boolean,
): Update_Current_Liability_Details_Input {
  let propertyData: Pick<
    Update_Current_Liability_Details_Input,
    'user_provided_property' | 'domain_api_address_input'
  > | null = null;

  const propertyAddress = getPropertyAddressFromFormValues(formValues);
  if (propertyAddress) {
    const { manualAddressInput, domainApiAddressInput } =
      parseAddressInputForMutationAction(propertyAddress);
    propertyData = {
      user_provided_property: manualAddressInput,
      domain_api_address_input: domainApiAddressInput,
    };
  }

  const interestRateType = parseEnumType(
    Loan_Interest_Rate_Type_Input_Enum,
    formValues[LoanDetailsFormField.LoanRateType],
  );

  const totalTermInMonths =
    formValues[LoanDetailsFormField.TermInMonthsYearPart] != null &&
    formValues[LoanDetailsFormField.TermInMonthsMonthPart] != null
      ? formValues[LoanDetailsFormField.TermInMonthsYearPart] +
        formValues[LoanDetailsFormField.TermInMonthsMonthPart]
      : null;

  const calculatedLimit =
    allowEditAvailableBalanceField &&
    formValues[LoanDetailsFormField.AvailableBalance] != null &&
    formValues[LoanDetailsFormField.Balance] != null
      ? formValues[LoanDetailsFormField.AvailableBalance] +
        formValues[LoanDetailsFormField.Balance]
      : null;

  return {
    current_liability_id: currentLiabilityId,
    account_owners: formValues[LoanDetailsFormField.AccountOwners],
    account_number: formValues[LoanDetailsFormField.AccountNumber],
    balance: formValues[LoanDetailsFormField.Balance],
    term_months_end_date:
      totalTermInMonths != null
        ? addMonths(new Date(), totalTermInMonths).toISOString()
        : null,
    repayment_amount: formValues[LoanDetailsFormField.MonthlyRepaymentAmount],
    repayment_frequency: getRepaymentFrequencyFromLiabilityType(liabilityType),
    institution: {
      id: formValues[LoanDetailsFormField.Institution]?.id || null,
      name: formValues[LoanDetailsFormField.Institution]?.name || '',
    },
    limit: formValues[LoanDetailsFormField.Limit] ?? calculatedLimit,
    interest_rate: formValues[LoanDetailsFormField.InterestRate]
      ? parseFloat(formValues[LoanDetailsFormField.InterestRate])
      : null,
    ...propertyData,
    ...(interestRateType
      ? {
          interest_rate_type: interestRateType,
          fixed_rate_expiry_date:
            interestRateType === Loan_Interest_Rate_Type_Input_Enum.Fixed &&
            formValues[LoanDetailsFormField.FixedRateExpiryDate]
              ? safelyFormatDate(
                  formValues[LoanDetailsFormField.FixedRateExpiryDate],
                  'yyyy-MM-dd',
                )
              : null,
        }
      : null),

    loan_term_months: totalTermInMonths,
  };
}

function buildMutationInputWithoutCurrentLiability({
  formValues,
  loanApplicationId,
  detectedLiabilityIdentifier,
}: {
  formValues: LoanDetailsFormValues;
  loanApplicationId: string;
  detectedLiabilityIdentifier: string;
}): Upsert_Detected_Liabilities_Details_V2_Input {
  return {
    detected_liabilities_details: [
      mapLoanDetailsFormValuesToDetectedLiabilityDetails({
        detectedLiabilityIdentifier,
        formValues,
      }),
    ],
    loan_application_id: loanApplicationId,
  };
}

export function findLatestCreditCheckCreatedAt(
  applicants: EditLiabilityV2Query['applicants'],
  ownerOfLiability: string,
) {
  const identityProfile = applicants.find(
    (applicant) => applicant.id === ownerOfLiability,
  )?.user_identity_profile;

  return (
    identityProfile?.credit_checks_by_loan_application[0]?.created_at ??
    identityProfile?.credit_checks
      .map((cc) => cc?.created_at)
      .reduce((acc, curr) => {
        if (acc && curr && new Date(acc) > new Date(curr)) {
          return acc;
        }
        return curr;
      }, '')
  );
}

export function buildDisplayErrorFromMutationResult(
  res:
    | FetchResult<EditLiabilityV2ForLiabilityWithCurrentLiabilityMutationMutation>
    | FetchResult<EditLiabilityV2ForLiabilityWithoutCurrentLiabilityMutationV2Mutation>
    | null,
): string | null {
  if (res?.data == null) {
    return t('Content.EditLiabilityV2.MutationError');
  }

  return null;
}

export function EditLiabilityV2() {
  const navigation =
    useNavigation<
      SingleModalNavigationScreenProp<Screen.DEBTS_EDIT_LIABILITY_V2_MODAL>
    >();
  const route =
    useRoute<SingleModalStackRoute<Screen.DEBTS_EDIT_LIABILITY_V2_MODAL>>();

  const {
    loanApplicationId,
    liabilityType,
    currentLiabilityId: currentLiabilityIdFromParams,
    detectedLiabilityIdentifier: detectedLiabilityIdentifierFromParams,
    liabilityManuallyAdded,
    mutationErrorFromParams,
    _forceLoading,
    _forceDataQueryError,
  } = route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { flags } = useContext(FeatureFlagsContext);
  const enableAvailableFundsCapture = flags.ENABLE_AVAILABLE_FUNDS_CAPTURE;

  const forceLoading = _forceLoading ?? false;
  const forceDataQueryError = _forceDataQueryError ?? false;

  const noLiabilityId =
    currentLiabilityIdFromParams == null &&
    detectedLiabilityIdentifierFromParams == null;
  const isScreenInvalidState =
    loanApplicationId == null || liabilityType == null || noLiabilityId;

  const propertySuggestionLoader = usePropertySuggestionLoader();

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useEditLiabilityV2Query({
    variables: {
      loanApplicationId: loanApplicationId || '',
      mergedLiabilityOrFilter: [
        {
          detected_liability_identifier: {
            _eq: detectedLiabilityIdentifierFromParams,
          },
        },
      ],
    },
    skip: isScreenInvalidState || forceDataQueryError || forceLoading,
    context: {
      sentryContext: {
        ...route.params,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const mergedLiability = data?.mergedLiability?.[0];
  const detectedLiabilityIdentifier =
    mergedLiability?.detected_liability_identifier;
  const isScreenInvalidStateAfterQuery =
    // Screen is invalid if there's no liability returned from the query
    mergedLiability == null ||
    detectedLiabilityIdentifier == null ||
    isScreenInvalidState;

  const ownerOfLiability = mergedLiability?.dynamite_applicant_ids?.[0];
  const latestCreditCheckCreatedAt =
    data?.applicants &&
    ownerOfLiability &&
    findLatestCreditCheckCreatedAt(data.applicants, ownerOfLiability);

  const [editMutationErrorForDisplay, setEditMutationErrorForDisplay] =
    useState<string | null>(null);

  const [
    commitWithCurrentLiability,
    { loading: isSubmittingWithCurrentLiability },
  ] = useEditLiabilityV2ForLiabilityWithCurrentLiabilityMutationMutation({
    onError: (_err) => {
      setEditMutationErrorForDisplay(
        t('Content.EditLiabilityV2.MutationError'),
      );
    },
  });

  const [
    commitWithoutCurrentLiabilityV2,
    { loading: isSubmittingWithoutCurrentLiabilityV2 },
  ] = useEditLiabilityV2ForLiabilityWithoutCurrentLiabilityMutationV2Mutation({
    onError: (_err) => {
      setEditMutationErrorForDisplay(
        t('Content.EditLiabilityV2.MutationError'),
      );
    },
  });

  const isSubmitting =
    isSubmittingWithCurrentLiability || isSubmittingWithoutCurrentLiabilityV2;
  const isLoading = queryLoading || forceLoading;
  const isQueryError = queryError != null || forceDataQueryError;

  const accountOwnerOptions = mapApplicantsToAccountOwnerOptions({
    applicants: data?.applicants,
    mergedLiabilities: data?.mergedLiabilities,
    selectedLiabilityId: mergedLiability?.id,
    selectedLiabilityType: liabilityType,
    isEditingLiability: true,
  });
  const manualAddressStateOptions = useMemo(
    () =>
      data?.states?.map(({ code }) => ({
        label: code,
        value: code,
      })) ?? [],
    [data?.states],
  );

  const { loanApplicationType, navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onCloseModal = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Debts,
      }),
    );
  };
  const clearMutationError = () => {
    setEditMutationErrorForDisplay(null);
    navigation.setParams({
      mutationErrorFromParams: undefined,
    });
  };

  const currentLiabilityId = mergedLiability?.current_liability_id;
  const onDelete =
    liabilityManuallyAdded && detectedLiabilityIdentifier
      ? () => {
          clearMutationError();
          navigation.navigate(
            ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET,
            {
              loanApplicationId,
              detectedLiabilityIdentifier,
              returnToParams: { ...route.params },
            },
          );
        }
      : undefined;

  const liabilityForRefinancing = !!mergedLiability?.dynamite_for_refinancing;
  const showFlagAsIncorrectButton =
    !liabilityManuallyAdded && !liabilityForRefinancing;
  const onFlagAsIncorrect =
    showFlagAsIncorrectButton && detectedLiabilityIdentifier
      ? () => {
          clearMutationError();
          navigation.navigate(ActionSheetType.FLAG_DETECTED_LIABILITY, {
            loanApplicationId,
            currentLiabilityId: currentLiabilityId || undefined,
            detectedLiabilityIdentifier,
            onErrorReturnTo: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
            onErrorReturnToParams: { ...route.params },
          });
        }
      : undefined;

  const onSubmit = async (formValues: LoanDetailsFormValues) => {
    clearMutationError();
    if (isScreenInvalidStateAfterQuery) {
      return;
    }

    const commonMutationOptions = {
      refetchQueries: [
        refetchYourDebtsQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: { sentryContext: { loanApplicationId } },
    };

    const withCurrentLiability = currentLiabilityId != null;

    // In V1, there are 2 separate screens for editing,
    // one for manually added liability, one for detected liability.
    // In V2, these screens are consolidated into this single edit screen.
    // But we don't have new mutations for V2,
    // so we need to conditionally use old mutations here.
    // This has concurrency issue since we do either insert or update
    // based on client side condition.
    // There could be 2 concurrent clients doing insert,
    // one succeeding, and the other one failing with constraint violation.
    // TODO(uiv2): Need upsert mutation for editing liabilities
    if (withCurrentLiability) {
      const [res] = await safelyCallMutation(commitWithCurrentLiability, {
        variables: {
          input: buildMutationInputWithCurrentLiability(
            {
              formValues,
              currentLiabilityId,
              liabilityType:
                mergedLiability.dynamite_liability_type as Liability_Type_Enum,
            },
            allowEditAvailableBalanceField,
          ),
        },
        ...commonMutationOptions,
      });

      const displayErr = buildDisplayErrorFromMutationResult(res);
      if (displayErr) {
        setEditMutationErrorForDisplay(displayErr);
        return;
      }
      onCloseModal();
    } else {
      const inputWithoutCurrentLiability =
        buildMutationInputWithoutCurrentLiability({
          formValues,
          loanApplicationId,
          detectedLiabilityIdentifier,
        });

      const [resV2] = await safelyCallMutation(
        commitWithoutCurrentLiabilityV2,
        {
          variables: {
            input: inputWithoutCurrentLiability,
          },
          ...commonMutationOptions,
        },
      );

      const displayErr = buildDisplayErrorFromMutationResult(resV2);

      if (displayErr) {
        setEditMutationErrorForDisplay(displayErr);
        return;
      }
      onCloseModal();
    }
  };

  if (isLoading) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <ScreenLoadingContainer flex={1} centered loading />
      </ModalScreenContainer>
    );
  }

  if (isQueryError || isScreenInvalidStateAfterQuery) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <EmptyState
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }

  const mutationErrorMessage =
    editMutationErrorForDisplay || mutationErrorFromParams;

  const isTopUpHomeLoanLiability = isTopUpHomeLoan({
    loanApplicationType,
    liabilityType: parseEnumType(
      Liability_Type_Enum,
      mergedLiability.dynamite_liability_type,
    ),
    forRefinancing: mergedLiability?.dynamite_for_refinancing,
  });

  if (isTopUpHomeLoanLiability && mergedLiability) {
    return (
      <ModalScreenContainer
        headerText={getHeaderTextByLiabilityType(liabilityType)}
        scrollable
        onClose={onCloseModal}
        hideBackButton
        loading={isSubmitting}
      >
        {liabilityManuallyAdded ? null : (
          <Text sx={{ mb: '$32' }}>
            {t('Content.EditLiabilityV2.AccountInformationSourced')}
          </Text>
        )}
        <TopUpHomeLoanLiabilityDetails
          liabilityType={liabilityType}
          values={buildInitialFormValues({
            mergedLiability,
            liabilityManuallyAdded: !!liabilityManuallyAdded,
          })}
          accountOwnerOptions={accountOwnerOptions}
        />
      </ModalScreenContainer>
    );
  }

  // For edit form, show available balance field when FF is ON
  // OR if customer limit is not null, because we allow them to edit
  // their previous input even when FF is OFF
  const allowEditAvailableBalanceField =
    enableAvailableFundsCapture || mergedLiability.customer_limit != null;

  return (
    <ModalScreenContainer
      headerText={getHeaderTextByLiabilityType(liabilityType)}
      scrollable
      onClose={onCloseModal}
      hideBackButton
      loading={isSubmitting}
    >
      <ErrorRow
        message={mutationErrorMessage}
        mb="l"
        testID={TestID.EditLiabilityV2.MutationErrorRow}
      />
      {liabilityManuallyAdded ? null : (
        <Text sx={{ mb: '$32' }}>
          {t('Content.EditLiabilityV2.AccountInformationSourced')}
        </Text>
      )}
      {liabilityManuallyAdded ? (
        <Text
          variant="sHeader"
          sx={{
            mb: '$16',
          }}
        >
          {t('Content.AddManualLiabilityV2.EnterYourLoanDetails')}
        </Text>
      ) : null}
      <LoanDetailsForm
        screen={Screen.DEBTS_EDIT_LIABILITY_V2_MODAL}
        accountOwnerOptions={accountOwnerOptions}
        manualAddressStateOptions={manualAddressStateOptions}
        manualAddressStreetTypeOptions={streetTypeOptions}
        liabilityType={liabilityType}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onFlagAsIncorrect={onFlagAsIncorrect}
        liabilityManuallyAdded={!!liabilityManuallyAdded}
        showDeleteButton={liabilityManuallyAdded}
        showFlagAsIncorrectButton={showFlagAsIncorrectButton}
        isSubmitting={isSubmitting}
        institutionsData={data?.institutions}
        initialValues={
          mergedLiability
            ? buildInitialFormValues({
                mergedLiability,
                liabilityManuallyAdded: !!liabilityManuallyAdded,
                // Show available balance field if customer limit is not null
                allowEditAvailableBalanceField,
              })
            : undefined
        }
        creditCheckDate={latestCreditCheckCreatedAt}
        ccrBalance={mergedLiability?.ccr_balance}
        {...propertySuggestionLoader}
        allowEditAvailableBalanceField={allowEditAvailableBalanceField}
      />
    </ModalScreenContainer>
  );
}
