import { gql } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Security_Cost_Calculation_State_Enum,
  useRetriggerSecurityCostCalculationMutation,
  useSecurityCostCalculationStateSubscription,
} from '../../generated/graphql';
import { addBreadcrumb } from '../../sentry';
import { safelyCallMutation } from './errorUtils';
import useTimeout from './useTimeout';

export const SecurityCostCalculationState = gql`
  subscription SecurityCostCalculationState($loanApplicationId: uuid!) {
    loan_application_security(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      security_cost_calculation_state
    }
  }
`;

export const RetriggerSecurityCostCalculationMutation = gql`
  mutation RetriggerSecurityCostCalculation($loan_application_id: uuid!) {
    trigger_security_cost_calculation_for_app(
      loan_application_id: $loan_application_id
    ) {
      loan_application_id
    }
  }
`;

const COST_CALCULATION_STATES_WITH_RETRIGGER_REQUIRED = [
  Security_Cost_Calculation_State_Enum.ProcessingError,
  Security_Cost_Calculation_State_Enum.RequireCalculation,
];

function shouldShowErrorBasedOnCostCalculationState(
  costCalculationState?: Security_Cost_Calculation_State_Enum | null,
  isInProgressStateTimedOut?: boolean,
): boolean {
  if (!costCalculationState) return false;
  if (isInProgressStateTimedOut) return true;
  return COST_CALCULATION_STATES_WITH_RETRIGGER_REQUIRED.includes(
    costCalculationState,
  );
}

type CostCalculationSubscriptionOptions = {
  loanApplicationId: string | undefined;
  onCalculationComplete?: () => Promise<void>;
  logToSentryIfCalculationTookLongTime?: boolean;
  onLogCalculationTookTooLong?: (
    currentCostCalculationState?: Security_Cost_Calculation_State_Enum | null,
  ) => void;
};

const SECURITY_COST_CALCULATION_STATE_SUBSCRIPTION_TIMEOUT = 5000;
const SECURITY_COST_CALCULATION_STATE_INPROGRESS_TIMEOUT = 2000;

export function useSecurityCostCalculationState({
  loanApplicationId,
  onCalculationComplete,
  logToSentryIfCalculationTookLongTime = false,
  onLogCalculationTookTooLong,
}: CostCalculationSubscriptionOptions) {
  // Timeout to log if the initial state is taking too long to get to success state
  const { hasTimedout } = useTimeout(
    SECURITY_COST_CALCULATION_STATE_SUBSCRIPTION_TIMEOUT,
  );

  const hasCompletedOnce = useRef(false);
  const [inProgressStateHasTimedout, setInProgressStateHasTimedout] =
    useState(false);
  // This state is used to prevent flicker on ErrorRow because there'll be a gap
  // between retry mutation call and when the event is picked up and processed.
  const [
    isWaitingForCostCalculationStateUpdate,
    setIsWaitingForCostCalculationStateUpdate,
  ] = useState(false);

  const { data, loading, error } = useSecurityCostCalculationStateSubscription({
    variables: { loanApplicationId: loanApplicationId ?? '' },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
    onData: async ({ data: incomingData }) => {
      const currentCostCalculationState =
        incomingData?.data?.loan_application_security?.[0]
          ?.security_cost_calculation_state;

      addBreadcrumb('Security cost calculation state updated', {
        currentCostCalculationState,
      });

      if (
        currentCostCalculationState ===
        Security_Cost_Calculation_State_Enum.Completed
      ) {
        hasCompletedOnce.current = true;
        await onCalculationComplete?.();
      }
      // Only reset the state after all process is done
      if (
        currentCostCalculationState !==
        Security_Cost_Calculation_State_Enum.InProgress
      ) {
        setIsWaitingForCostCalculationStateUpdate(false);
        setInProgressStateHasTimedout(false);
      }
    },
  });

  const costCalculationState =
    data?.loan_application_security?.[0]?.security_cost_calculation_state;
  const waitingForCostCalculationToBeCompleted =
    costCalculationState !== Security_Cost_Calculation_State_Enum.Completed ||
    loading;

  // For logging purposes
  useEffect(() => {
    if (
      !logToSentryIfCalculationTookLongTime ||
      !loanApplicationId ||
      hasCompletedOnce.current
    ) {
      return;
    }
    if (hasTimedout && waitingForCostCalculationToBeCompleted) {
      addBreadcrumb(
        `Still waiting for cost calculation after ${SECURITY_COST_CALCULATION_STATE_SUBSCRIPTION_TIMEOUT}ms`,
        {
          loanApplicationId,
          costCalculationState,
          isLoadingInitialSubscriptionConnection: loading,
          // This would still be logged in error link. Putting it here just for convenience on debug.
          apolloError: error,
        },
      );
      onLogCalculationTookTooLong?.(costCalculationState);
    }
  }, [
    hasTimedout,
    loanApplicationId,
    onLogCalculationTookTooLong,
    logToSentryIfCalculationTookLongTime,
    waitingForCostCalculationToBeCompleted,
    costCalculationState,
    loading,
    error,
  ]);

  // To detect if stuck on InProgress State.
  // Whenever cost calculation state change, set a timer, after 2s check
  // if it still stuck in InProgress state
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        costCalculationState === Security_Cost_Calculation_State_Enum.InProgress
      ) {
        setInProgressStateHasTimedout(true);
      }
    }, SECURITY_COST_CALCULATION_STATE_INPROGRESS_TIMEOUT);

    return () => clearTimeout(timer);
  }, [costCalculationState]);

  const shouldShowErrorForCostCalculation =
    shouldShowErrorBasedOnCostCalculationState(
      costCalculationState,
      inProgressStateHasTimedout,
    );
  const [
    retriggerSecurityCostCalculation,
    { loading: retriggerSecurityCostCalculationLoading },
  ] = useRetriggerSecurityCostCalculationMutation();
  const retrySecurityCostCalculation = useCallback(async () => {
    if (
      !loanApplicationId ||
      !shouldShowErrorForCostCalculation ||
      retriggerSecurityCostCalculationLoading ||
      isWaitingForCostCalculationStateUpdate
    ) {
      return;
    }
    setIsWaitingForCostCalculationStateUpdate(true);
    await safelyCallMutation(retriggerSecurityCostCalculation, {
      variables: {
        loan_application_id: loanApplicationId,
      },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  }, [
    isWaitingForCostCalculationStateUpdate,
    loanApplicationId,
    retriggerSecurityCostCalculation,
    retriggerSecurityCostCalculationLoading,
    shouldShowErrorForCostCalculation,
  ]);

  return {
    costCalculationState,
    waitingForCostCalculationToBeCompleted,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
    isWaitingForRetryToComplete:
      retriggerSecurityCostCalculationLoading ||
      isWaitingForCostCalculationStateUpdate,
  };
}
