"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.env = exports.expoPublicEnv = exports.isNative = exports.useAuth0Sandbox = exports.ENVIRONMENT = void 0;
var ENVIRONMENT;
(function (ENVIRONMENT) {
    ENVIRONMENT["PROD_V3"] = "prod-v3";
    ENVIRONMENT["STAGING_V3"] = "staging-v3";
    ENVIRONMENT["TEST_V3"] = "test-v3";
    ENVIRONMENT["DEV_V3"] = "dev-v3";
    ENVIRONMENT["LOCAL_DEV"] = "local-dev";
})(ENVIRONMENT || (exports.ENVIRONMENT = ENVIRONMENT = {}));
const appManifest = (_a = process.env) === null || _a === void 0 ? void 0 : _a.APP_MANIFEST;
// Expo handles env variables differently in web and native
// https://docs.expo.dev/guides/environment-variables/
exports.useAuth0Sandbox = ((_b = appManifest === null || appManifest === void 0 ? void 0 : appManifest.extra) === null || _b === void 0 ? void 0 : _b.useAuth0Sandbox) ||
    process.env.EXPO_PUBLIC_UNLOAN_AUTH0_SANDBOX === 'true';
const native = process.env.EXPO_PUBLIC_NATIVE;
exports.isNative = native === 'true';
exports.expoPublicEnv = process.env.EXPO_PUBLIC_UNLOAN_ENV;
exports.env = (exports.expoPublicEnv !== null && exports.expoPublicEnv !== void 0 ? exports.expoPublicEnv : ENVIRONMENT.LOCAL_DEV);
