import { View } from 'dripsy';
import { useState } from 'react';
import Animated from 'react-native-reanimated';

import { StyledImage } from '../../../ui/atoms/StyledImage';
import { useCardScaleAnimatedStyle } from '../../../ui/utils/useCardScaleAnimatedStyle';

const Image = {
  source: require('../../../../assets/applyLoan.png'),
  width: 824,
  height: 610,
};

type Props = {
  isHover?: boolean;
};

export const ApplyLoanCardHeader = ({ isHover = false }: Props) => {
  const [resizedImageWidth, setResizedImageWidth] = useState<number | null>(
    null,
  );

  const imageHeightByWidthRatio = Image.height / Image.width;
  const resizedImageHeight = Math.ceil(
    (resizedImageWidth ?? 0) * imageHeightByWidthRatio,
  );

  const animatedStyle = useCardScaleAnimatedStyle(isHover);

  return (
    <View
      onLayout={(e) => setResizedImageWidth(e.nativeEvent.layout?.width ?? 0)}
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderTopLeftRadius: '$card',
        borderTopRightRadius: '$card',
      }}
    >
      {resizedImageWidth != null ? (
        <Animated.View style={animatedStyle}>
          <StyledImage
            style={{
              height: resizedImageHeight,
              width: resizedImageWidth,
            }}
            contentFit="contain"
            source={Image.source}
            aria-hidden
          />
        </Animated.View>
      ) : null}
    </View>
  );
};
