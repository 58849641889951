import { styled, Text, useSx, View } from 'dripsy';
import { useCallback, useState } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { BorrowerFormV2ContextValues } from '../../components/form/types';
import { ContactDetails } from '../../config';
import { Applicant_Role_Enum } from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import {
  RouteProp,
  SingleModalNavigatorStackParams,
  SingleModalStackNavigationProps,
  SingleModalStackScreenProps,
} from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { currentLoggedInBorrowerDetailsV2Atom } from '../currentLoggedInBorrowerDetailsAtom';
import { learnMoreReturnScreenAtom } from '../learnMoreReturnScreenAtom';
import {
  getUpdateBorrowerErrorMessage,
  useUpdateBorrower,
} from '../utils/useUpdateBorrower';

type NavigationProps = {
  navigation: SingleModalStackNavigationProps<Screen.MANUAL_VERIFICATION_V2_MODAL>;
  route: RouteProp<
    SingleModalNavigatorStackParams,
    Screen.MANUAL_VERIFICATION_V2_MODAL
  >;
  currentLoggedInBorrowerDetailsValues: BorrowerFormV2ContextValues | null;
  returnDestination: Screen | null;
  loanApplicationId: string;
  setCurrentLoggedInBorrowerDetails: SetterOrUpdater<BorrowerFormV2ContextValues | null>;
  setLearnMoreReturnScreenAtom: SetterOrUpdater<Screen | null>;
  navigateToLoanApplicationScreen: ({
    section,
  }: {
    section: LoanApplicationSection;
  }) => void;
};

function useNavigationProps({
  navigation,
  route,
  currentLoggedInBorrowerDetailsValues,
  returnDestination,
  loanApplicationId,
  setCurrentLoggedInBorrowerDetails,
  setLearnMoreReturnScreenAtom,
  navigateToLoanApplicationScreen,
}: NavigationProps) {
  const onBackToDigitalIdentityVerificationPress = () => {
    setCurrentLoggedInBorrowerDetails(null);
    setLearnMoreReturnScreenAtom(null);
    if (returnDestination === Screen.LOAN_APPLICATION_V2_BORROWERS) {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Borrowers,
      });
    }
    if (returnDestination === Screen.IDENTIFICATION_V2_YOUR_INFORMATION) {
      navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
        screen: Screen.IDENTIFICATION_V2_YOUR_INFORMATION,
        params: {
          applicantId: currentLoggedInBorrowerDetailsValues?.applicantId || '',
          applicantRole:
            currentLoggedInBorrowerDetailsValues?.applicantRole ||
            Applicant_Role_Enum.PrimaryBorrower,
          ...route.params,
        },
      });
    }
    if (
      returnDestination === Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT
    ) {
      navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
        screen: Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT,
      });
    }
  };

  const navigateBack =
    returnDestination === Screen.LOAN_APPLICATION_V2_BORROWERS
      ? () =>
          navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
            screen: Screen.IDENTITY_VERIFICATION_PENDING_V2,
            params: {
              loanApplicationId,
            },
          })
      : onBackToDigitalIdentityVerificationPress;

  return { onBackToDigitalIdentityVerificationPress, navigateBack };
}

export type Props =
  SingleModalStackScreenProps<Screen.MANUAL_VERIFICATION_V2_MODAL>;
export function ManualIdentityVerificationModalV2({
  navigation,
  route,
}: Props) {
  const loanApplicationId = route.params?.loanApplicationId || '';
  const sx = useSx();

  const [
    currentLoggedInBorrowerDetailsValues,
    setCurrentLoggedInBorrowerDetails,
  ] = useRecoilState(currentLoggedInBorrowerDetailsV2Atom(loanApplicationId));

  const [returnDestination, setLearnMoreReturnScreenAtom] = useRecoilState(
    learnMoreReturnScreenAtom(loanApplicationId),
  );

  const { updateBorrower, updateBorrowerLoading } = useUpdateBorrower({
    loanApplicationId,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const { onBackToDigitalIdentityVerificationPress, navigateBack } =
    useNavigationProps({
      navigation,
      route,
      currentLoggedInBorrowerDetailsValues,
      returnDestination,
      loanApplicationId,
      setCurrentLoggedInBorrowerDetails,
      setLearnMoreReturnScreenAtom,
      navigateToLoanApplicationScreen,
    });

  const shouldDisableActionButton =
    updateBorrowerLoading || loadingLoanApplicationMetadata;

  const updateBorrowerAndRunManualKYC = useCallback(async () => {
    if (loanApplicationId && currentLoggedInBorrowerDetailsValues) {
      const { data, error } = await updateBorrower({
        values: currentLoggedInBorrowerDetailsValues,
        isManualKyc: true,
      });

      if (data) {
        // Reset atom on success
        setCurrentLoggedInBorrowerDetails(null);
        navigateToLoanApplicationScreen({
          section: LoanApplicationSection.Borrowers,
        });
        return;
      }
      setErrorMessage(getUpdateBorrowerErrorMessage(error));
      return;
    }
    setErrorMessage(t('Content.Common.Error.NoAssociatedLoanApplication'));
  }, [
    currentLoggedInBorrowerDetailsValues,
    loanApplicationId,
    navigateToLoanApplicationScreen,
    setCurrentLoggedInBorrowerDetails,
    updateBorrower,
  ]);

  return (
    <ModalScreenContainer
      onClose={navigateBack}
      headerText={t('Content.ManualIdentityVerification.Title')}
      scrollable
      loading={updateBorrowerLoading}
    >
      {errorMessage ? <ErrorRow mb="l" message={errorMessage} /> : null}

      {loadingLoanApplicationMetadata ? (
        <Spinner />
      ) : (
        <>
          <Text sx={{ mb: '$16' }}>
            {t('Content.ManualIdentityVerification.Intro')}
          </Text>
          <RowView sx={{ mb: '$16' }}>
            <Text sx={{ mr: '$16' }}>1.</Text>
            <View sx={{ flex: 1 }}>
              <Text>
                {t('Content.ManualIdentityVerification.Instruction1')}
              </Text>
              <RowView sx={{ alignItems: 'flex-start' }}>
                <Text sx={{ mr: '$14' }}>•</Text>
                <Text variant="body">
                  {t('Content.ManualIdentityVerification.Document1')}
                </Text>
              </RowView>
              <RowView sx={{ alignItems: 'flex-start' }}>
                <Text sx={{ mr: '$14' }}>•</Text>
                <Text variant="body">
                  {t('Content.ManualIdentityVerification.Document2')}
                </Text>
              </RowView>
              <RowView sx={{ alignItems: 'flex-start' }}>
                <Text sx={{ mr: '$14' }}>•</Text>
                <Text>{t('Content.ManualIdentityVerification.Document3')}</Text>
              </RowView>
            </View>
          </RowView>

          <RowView sx={{ mb: '$16' }}>
            <Text sx={{ mr: '$16' }}>2.</Text>
            <Text>
              {t('Content.ManualIdentityVerification.Instruction2a')}
              {` `}
              <Link
                style={sx({ variant: 'text.body', color: '$link' })}
                href={`mailto:${ContactDetails.unloanEmail}`}
              >
                {ContactDetails.unloanEmail}
              </Link>
              {t('Content.ManualIdentityVerification.Instruction2b')}
            </Text>
          </RowView>
          <RowView>
            <Text sx={{ mr: '$16' }}>3.</Text>
            <Text>{t('Content.ManualIdentityVerification.Instruction3')}</Text>
          </RowView>
        </>
      )}
      <View sx={{ mt: '$8' }}>
        <Text variant="caption" sx={{ p: '$16', textAlign: 'center' }}>
          {t('Content.ManualIdentityVerification.Disclaimer')}
        </Text>
        <Button
          label={t(
            'Content.ManualIdentityVerification.ButtonLabel.BackToDigitalIdentityVerification',
          )}
          testID={
            TestID.ManualIdentityVerificationModal
              .BackToDigitalIdentityVerificationButton
          }
          alignSelf="stretch"
          onPress={onBackToDigitalIdentityVerificationPress}
          disabled={shouldDisableActionButton}
        />
        <Button
          tertiary
          fontWeight="normal"
          label={t(
            'Content.ManualIdentityVerification.ButtonLabel.ContinueWithManualVerification',
          )}
          mb="s"
          alignSelf="stretch"
          onPress={updateBorrowerAndRunManualKYC}
          testID={
            TestID.ManualIdentityVerificationModal
              .ContinueWithManualVerificationButton
          }
          showSpinner={updateBorrowerLoading}
          disabled={shouldDisableActionButton}
        />
      </View>
    </ModalScreenContainer>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
