import { useEffect } from 'react';

import {
  Income_Verification_State_Enum,
  Income_Verification_Status_Enum,
  IncomeVerificationStateSubscription,
} from '../../generated/graphql';

export function usePrepopulateIncome({
  commenceIncomeVerificationLoading,
  incomeVerificationLoading,
  validIncomeVerificationId,
  enablePrepopulateEmploymentIncome,
  incomeVerificationState,
  incomeVerificationStatus,
  navigateToPrepopulatedEmploymentIncomeScreen,
  navigateToCheckEmploymentIncomeWizard,
  detectedEmploymentIncomeSummaries,
  setValidIncomeVerificationId,
  hasUserDeclaredIncome,
  getYourFinancialsLoading,
}: {
  commenceIncomeVerificationLoading: boolean;
  incomeVerificationLoading: boolean;
  validIncomeVerificationId: string | null;
  enablePrepopulateEmploymentIncome: boolean;
  hasUserDeclaredIncome: boolean;
  getYourFinancialsLoading: boolean;
  incomeVerificationState?: Income_Verification_State_Enum;
  incomeVerificationStatus?: Income_Verification_Status_Enum | null;
  detectedEmploymentIncomeSummaries:
    | NonNullable<
        IncomeVerificationStateSubscription['income_verification_by_pk']
      >['detectedEmploymentIncomeSummaries']
    | undefined;
  navigateToPrepopulatedEmploymentIncomeScreen: (
    validIncomeVerificationId: string,
  ) => void;
  navigateToCheckEmploymentIncomeWizard: (
    validIncomeVerificationId?: string,
  ) => void;
  setValidIncomeVerificationId: (validIncomeVerifId: string | null) => void;
}) {
  useEffect(() => {
    if (
      incomeVerificationLoading ||
      getYourFinancialsLoading ||
      commenceIncomeVerificationLoading
    ) {
      return;
    }

    const hasCommenceIncomeVerification = !!validIncomeVerificationId;

    if (
      enablePrepopulateEmploymentIncome &&
      hasCommenceIncomeVerification &&
      !hasUserDeclaredIncome
    ) {
      /**
       * Only try to auto navigate to wizard when:
       * - Feature flag is enabled
       * - Has commence income verification. This can be marked by having validIncomeVerificationId
       * - Either has finish income verification and calculation for detected employment income or has timed out
       * - And there is no user declared income yet
       */
      switch (incomeVerificationState) {
        case Income_Verification_State_Enum.Completed:
          /**
           * Prepopulate data only when:
           * - there is a match
           * - there is only one detected employment AND it should have annual salary (we only want to populate when there is annual salary)
           */
          if (
            incomeVerificationStatus ===
              Income_Verification_Status_Enum.Match &&
            detectedEmploymentIncomeSummaries?.length === 1 &&
            detectedEmploymentIncomeSummaries[0]?.annual_salary
          ) {
            navigateToPrepopulatedEmploymentIncomeScreen(
              validIncomeVerificationId,
            );
          } else {
            navigateToCheckEmploymentIncomeWizard(validIncomeVerificationId);
          }
          break;
        case Income_Verification_State_Enum.Failed:
        case Income_Verification_State_Enum.InProgress:
        case Income_Verification_State_Enum.Pending:
        default:
          navigateToCheckEmploymentIncomeWizard(validIncomeVerificationId);
          break;
      }

      setValidIncomeVerificationId(null);
    }
  }, [
    validIncomeVerificationId,
    navigateToPrepopulatedEmploymentIncomeScreen,
    navigateToCheckEmploymentIncomeWizard,
    incomeVerificationStatus,
    incomeVerificationState,
    incomeVerificationLoading,
    enablePrepopulateEmploymentIncome,
    commenceIncomeVerificationLoading,
    setValidIncomeVerificationId,
    detectedEmploymentIncomeSummaries,
    hasUserDeclaredIncome,
    getYourFinancialsLoading,
  ]);
}
