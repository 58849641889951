import { useRecoilValue } from 'recoil';

import { PickerTrigger } from '../../components/form/PickerTrigger';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { bankIcon } from '../../ExternalAccount/components/AccountSelector';
import { AccountDetail } from '../../ExternalAccount/types';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { useTheme } from '../../ui/theme';
import { generateCensoredCreditCardNumber } from '../../utils/stringHelpers';
import { useRecoilObserver } from '../utils/useRecoilObserver';

export const AutopayAccountSelectorControl = ({
  disabled,
  onPress,
  onAccountSelected,
  validationErrorMessage,
}: {
  disabled?: boolean;
  onPress: () => void;
  onAccountSelected: (selectedAccount: AccountDetail | null) => void;
  validationErrorMessage: string | null | undefined;
}) => {
  const theme = useTheme();

  useRecoilObserver(selectedExternalAccountAtom, onAccountSelected);

  const directDebitAccount = useRecoilValue(selectedExternalAccountAtom);

  return (
    <ListRowGroup
      mx={0}
      headerText={t('Content.AutopaySettings.FromAccount')}
      uppercaseHeader
      footerErrorMessage={validationErrorMessage}
    >
      {directDebitAccount ? (
        <ListRow
          disabled={disabled}
          onPress={onPress}
          label={directDebitAccount.name}
          caption={generateCensoredCreditCardNumber(
            directDebitAccount.maskedAccountNumber?.toString().slice(-4),
          )}
          useArrow
          last
          left={bankIcon(
            directDebitAccount,
            directDebitAccount.type,
            theme.sizes.m,
          )}
        />
      ) : (
        <PickerTrigger
          disabled={disabled}
          onPress={onPress}
          placeholder={t('Content.AutopaySettings.AccountSelect')}
          placeholderColor="secondaryContent"
          hasArrow
          last
        />
      )}
    </ListRowGroup>
  );
};
