import { Text, View } from 'dripsy';
import { useCallback, useEffect } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Approval_Status_Enum,
  useGetLoanAccountPropertyAddressLazyQuery,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { ReduceRepaymentsApprovalResponses } from '../components/ReduceRepaymentsApprovalResponses';
import { UpdatedRepaymentsDetails } from '../components/UpdatedRepaymentsDetails';
import { getExpiryTimeInHours } from '../utils/reduceRepaymentsUtils';
import { useGetPendingLoanVariationRequest } from '../utils/useGetPendingLoanVariationRequest';
import { useLoanVariationReduceRepayments } from '../utils/useLoanVariationReduceRepayments';

type Props =
  HomeTabScreenProps<Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN>;

export const ReduceRepaymentsApprovalScreen = ({
  navigation,
  route,
}: Props) => {
  const { cbaAccountId } = route.params;
  const {
    approvalRequestId,
    isLoanVariationRequestProcessing,
    loading: loanVariationRequestLoading,
    isPendingMyApproval,
    loanVariationRequest,
    amountToBeReduced,
    allApprovalResponses,
  } = useGetPendingLoanVariationRequest(cbaAccountId);

  const {
    loanAccountLoading,
    minimumRepaymentLoading,
    isMinimumMonthlyRepaymentDataFetched,
    updateMinimumMonthlyRepayments,
    newMinimumMonthlyRepaymentAmount: newMinimumRepaymentAmount,
    currentMonthlyRepaymentAmount: previousRepaymentAmount,
    monthlyRepaymentDifference: minimumRepaymentReducedAmount,
    redrawBalance: previousRedrawAmount,
  } = useLoanVariationReduceRepayments(cbaAccountId);

  // To fetch the property address
  const [
    getLoanAccountPropertyAddress,
    {
      data: propertyAddressData,
      loading: propertyAddressLoading,
      called: propertyAddressCalled,
    },
  ] = useGetLoanAccountPropertyAddressLazyQuery();

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useEffect(() => {
    /*
      Should force navigate back when - 
      - If loading has finished AND there is no pending loan variation request OR
      - If loading has finished AND the loan request is processing
    */
    const shouldGoBack =
      !loanVariationRequestLoading &&
      (!loanVariationRequest || isLoanVariationRequestProcessing);
    if (shouldGoBack) {
      goBack();
    }
    // Fetch the security address
    if (!loanVariationRequestLoading && !propertyAddressCalled) {
      getLoanAccountPropertyAddress({
        variables: {
          cba_account_id: cbaAccountId,
        },
        context: {
          sentryContext: {
            description: 'Get Loan Account Property Address',
          },
        },
      });
    }
    // Fetch Updated Minimum repayment
    if (!isMinimumMonthlyRepaymentDataFetched && amountToBeReduced) {
      updateMinimumMonthlyRepayments(amountToBeReduced);
    }
  }, [
    isMinimumMonthlyRepaymentDataFetched,
    isLoanVariationRequestProcessing,
    propertyAddressCalled,
    getLoanAccountPropertyAddress,
    loanVariationRequest,
    loanVariationRequestLoading,
    amountToBeReduced,
    goBack,
    cbaAccountId,
    updateMinimumMonthlyRepayments,
  ]);

  const cancelRequest = useCallback(() => {
    if (approvalRequestId) {
      navigation.navigate(ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST, {
        approvalRequestId,
        cbaAccountId,
      });
    }
  }, [approvalRequestId, cbaAccountId, navigation]);

  const approveRequest = useCallback(() => {
    if (approvalRequestId) {
      navigation.navigate(ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST, {
        approvalRequestId,
        cbaAccountId,
      });
    }
  }, [approvalRequestId, navigation, cbaAccountId]);

  const declineRequest = useCallback(() => {
    if (approvalRequestId) {
      navigation.navigate(ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST, {
        approvalRequestId,
        cbaAccountId,
      });
    }
  }, [approvalRequestId, navigation, cbaAccountId]);

  const allResponses = allApprovalResponses?.map((response) => ({
    id: response.id,
    name: response.fullName ?? '--',
    status:
      response.status === Approval_Status_Enum.Approved
        ? t('Content.LoanVariation.ReduceRepaymentApproverFlow.Approved')
        : t('Content.LoanVariation.ReduceRepaymentApproverFlow.Pending'),
  }));

  const loading =
    loanVariationRequestLoading ||
    propertyAddressLoading ||
    loanAccountLoading ||
    minimumRepaymentLoading;
  const address =
    propertyAddressData?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.property.address.short_address_format;

  const pageSubTitle = address
    ? t('Content.LoanVariation.ReduceRepaymentApproverFlow.SubTitle', {
        address,
      })
    : t(
        'Content.LoanVariation.ReduceRepaymentApproverFlow.SubTitleWithoutAddress',
      );

  const redrawBalanceAvailable =
    previousRedrawAmount - (amountToBeReduced ?? 0);
  const redrawReduced = amountToBeReduced ?? 0;

  const expiresInHours = getExpiryTimeInHours(loanVariationRequest?.expires_at);

  return (
    <UpliftScreenContainer onPressBack={goBack}>
      <HomeHeader
        title={t('Content.LoanVariation.ReduceRepaymentApproverFlow.Title')}
        subTitle={pageSubTitle}
      />
      <Separator spacer py="xs" />
      <Text variant="sHeader" sx={{ py: '$8' }}>
        {t(
          'Content.LoanVariation.ReduceRepaymentApproverFlow.UpdatedLoanDetails',
        )}
      </Text>
      <UpdatedRepaymentsDetails
        loading={loading}
        newMinimumRepaymentAmount={newMinimumRepaymentAmount ?? 0}
        previousRepaymentAmount={previousRepaymentAmount ?? 0}
        minimumRepaymentReducedAmount={minimumRepaymentReducedAmount}
        redrawBalanceAvailable={redrawBalanceAvailable}
        previousRedrawAmount={previousRedrawAmount}
        redrawReduced={redrawReduced}
      />
      <ReduceRepaymentsApprovalResponses
        loading={loading}
        responses={allResponses}
      />
      <Text variant="caption" sx={{ py: '$8' }}>
        {t('Content.LoanVariation.ReduceRepaymentApproverFlow.Expiry', {
          hours: expiresInHours,
        })}
      </Text>
      <Separator spacer py={isPendingMyApproval ? 0 : 'm'} />
      {isPendingMyApproval ? (
        <View sx={{ py: '$16' }}>
          <Text
            sx={{ py: '$8', mb: '$8' }}
            variant="caption"
            style={{ textAlign: 'center' }}
          >
            {t(
              'Content.LoanVariation.ReduceRepaymentApproverFlow.ApproverDisclaimer',
            )}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Button
              flex={1}
              label={t('Content.Common.ButtonLabel.Decline')}
              testID={TestID.LoanVariation.RepaymentApproval.DeclineButton}
              secondary
              color="error"
              mr="s"
              onPress={declineRequest}
              disabled={loading}
            />
            <Button
              flex={1}
              label={t('Content.Common.ButtonLabel.Approve')}
              testID={TestID.LoanVariation.RepaymentApproval.ApproveButton}
              ml="s"
              onPress={approveRequest}
              disabled={loading}
            />
          </View>
        </View>
      ) : (
        <Button
          alignSelf="stretch"
          testID={TestID.LoanVariation.RepaymentApproval.CancelButton}
          secondary
          label={t(
            'Content.LoanVariation.ReduceRepaymentApproverFlow.CancelRequest',
          )}
          onPress={cancelRequest}
          disabled={loading}
        />
      )}
    </UpliftScreenContainer>
  );
};
