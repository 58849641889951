import { H1, Text, View } from 'dripsy';
import * as React from 'react';

type Props = {
  title: string;
  subTitle?: string;
};

export const HomeHeader: React.FC<Props> = ({ title, subTitle }) => (
  <View sx={{ pb: '$24' }}>
    <H1 variant="header" sx={{ mt: '$8', mb: 0, py: '$8' }}>
      {title}
    </H1>

    {subTitle ? (
      <Text variant="subHeading" sx={{ py: '$8' }}>
        {subTitle}
      </Text>
    ) : null}
  </View>
);
