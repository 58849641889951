import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function DataSharingIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(24, 23, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 24 23" fill="none" {...props}>
      <Path
        d="M12 0.885254C9.85547 0.885254 8.11719 2.52928 8.03125 4.56188C4.42578 6.02656 2 9.4379 2 13.32C2 13.4844 1.99219 13.6638 2 13.8581C0.816406 14.5232 0 15.745 0 17.1461C0 19.2497 1.80078 20.9722 4 20.9722C4.57422 20.9722 5.12891 20.8564 5.625 20.6434C7.35938 22.0371 9.61719 22.8853 12 22.8853C14.3828 22.8853 16.6406 22.0371 18.375 20.6434C18.8711 20.8564 19.4258 20.9722 20 20.9722C22.1992 20.9722 24 19.2497 24 17.1461C24 15.73 23.1758 14.4895 21.9688 13.8282C21.9844 13.675 22 13.5181 22 13.32C22 9.4379 19.5742 6.02656 15.9688 4.56188C15.8828 2.52928 14.1445 0.885254 12 0.885254ZM12 2.7983C13.1172 2.7983 14 3.64273 14 4.71134C14 5.77995 13.1172 6.62438 12 6.62438C10.8828 6.62438 10 5.77995 10 4.71134C10 3.64273 10.8828 2.7983 12 2.7983ZM8.46875 6.47493C9.14062 7.693 10.4688 8.53743 12 8.53743C13.5312 8.53743 14.8594 7.693 15.5312 6.47493C18.2109 7.71915 20 10.3346 20 13.32C17.8008 13.32 16 15.0425 16 17.1461C16 18.0018 16.3086 18.7789 16.8125 19.4179C15.4766 20.3856 13.7578 20.9722 12 20.9722C10.2422 20.9722 8.52344 20.3856 7.1875 19.4179C7.69141 18.7789 8 18.0018 8 17.1461C8 15.0425 6.19922 13.32 4 13.32C4 10.3346 5.78906 7.71915 8.46875 6.47493ZM4 15.2331C5.11719 15.2331 6 16.0775 6 17.1461C6 18.2147 5.11719 19.0592 4 19.0592C2.88281 19.0592 2 18.2147 2 17.1461C2 16.0775 2.88281 15.2331 4 15.2331ZM20 15.2331C21.1172 15.2331 22 16.0775 22 17.1461C22 18.2147 21.1172 19.0592 20 19.0592C18.8828 19.0592 18 18.2147 18 17.1461C18 16.0775 18.8828 15.2331 20 15.2331Z"
        fill={color}
      />
    </Svg>
  );
}
