import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import {
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchYourExpensesQuery,
  useRemoveHouseholdMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.DELETE_EXPENSE_ACTION_SHEET>;

export function DeleteExpenseActionSheet({ navigation, route }: Props) {
  const [removeHousehold, { loading }] = useRemoveHouseholdMutation();
  const { householdId, loanApplicationId } = route.params || {};

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onRemoveHousehold = async () => {
    if (!loanApplicationId) {
      return;
    }
    const [res, error] = await safelyCallMutation(removeHousehold, {
      variables: { householdId },
      refetchQueries: [
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
        refetchYourExpensesQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          householdId,
        },
      },
    });

    if (res == null || error) {
      return;
    }
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Expense,
    });
  };

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  return (
    <ActionSheet
      closeModal={closeModal}
      preventCloseModal={loading}
      title={t('Content.DeleteExpense.Title')}
      message={t('Content.DeleteExpense.Message')}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          disabled: loading,
          secondary: true,
          onPress: closeModal,
          flex: 1,
          testID: TestID.DeleteExpense.CancelButton,
        },
        {
          label: t('Content.Common.ButtonLabel.Delete'),
          disabled: loading,
          showSpinner: loading,
          secondary: true,
          isDestructive: true,
          onPress: onRemoveHousehold,
          flex: 1,
          testID: TestID.DeleteExpense.DeleteButton,
        },
      ]}
    />
  );
}
