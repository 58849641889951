import { AppAddressFormat } from '../../../components/form/types';
import { State_Enum } from '../../../generated/graphql';
import { parseEnumType } from '../../../utils/ensureEnumType';
import {
  BorrowerDetailsEditField,
  manualMailingAddressInputFieldNames,
} from './forms';

export function parseAddressToBorrowerDetailsEditFormInitialValues(
  mailingAddress: AppAddressFormat | null,
) {
  return {
    // Only populate this if current address is from suggestion result
    // reason being that manual address values are in separate fields
    [BorrowerDetailsEditField.MailingAddress]: mailingAddress?.isProvidedByUser
      ? null
      : mailingAddress,

    // Manual address input initial values
    [manualMailingAddressInputFieldNames.isManualInput]:
      mailingAddress?.isProvidedByUser ?? undefined,
    [manualMailingAddressInputFieldNames.postcode]:
      mailingAddress?.postcode ?? undefined,
    [manualMailingAddressInputFieldNames.state]:
      parseEnumType(State_Enum, mailingAddress?.state) ?? undefined,
    [manualMailingAddressInputFieldNames.streetName]:
      mailingAddress?.street ?? undefined,
    [manualMailingAddressInputFieldNames.streetNo]:
      mailingAddress?.streetNo ?? undefined,
    [manualMailingAddressInputFieldNames.streetType]: mailingAddress?.streetType
      ? {
          label: mailingAddress.streetType,
          value: mailingAddress.streetType,
        }
      : undefined,
    [manualMailingAddressInputFieldNames.suburb]:
      mailingAddress?.suburb ?? undefined,
    [manualMailingAddressInputFieldNames.unitNo]:
      mailingAddress?.unitNo ?? undefined,
  };
}
