import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const AutopayEmploymentIncome: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => {
  const width = resizeIconWidth(45, 45, size);

  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 45 45"
      fill="none"
      {...props}
    >
      <Path
        fill="url(#a)"
        d="M41.25 6.563h-.938a.938.938 0 0 1-1.874 0h-1.876a.938.938 0 0 1-1.874 0h-1.876a.938.938 0 0 1-1.874 0h-1.875a.938.938 0 0 1-1.875 0h-1.875a.938.938 0 0 1-1.875 0h-1.875a.938.938 0 0 1-1.875 0h-1.875a.938.938 0 0 1-1.875 0h-1.876a.938.938 0 0 1-1.874 0h-1.876a.938.938 0 0 1-1.874 0H6.562a.938.938 0 0 1-1.875 0H3.75a.938.938 0 0 0-.938.937v30c0 1.036.84.938 1.876.938h35.624c1.036 0 1.876.098 1.876-.938v-30a.938.938 0 0 0-.938-.938Z"
      />
      <Path
        fill="#2C2C2C"
        d="M22.031 15.938H7.97a.469.469 0 0 1-.469-.47v-.937c0-.258.21-.469.469-.469H22.03c.259 0 .469.21.469.47v.937c0 .258-.21.469-.469.469ZM19.219 19.688H7.969a.469.469 0 0 1-.469-.47v-.937c0-.258.21-.468.469-.468h11.25c.258 0 .468.21.468.468v.938c0 .258-.21.468-.468.468ZM38.225 26.894c0-5.3-7.827-4.266-7.827-7.398 0-1.635 1.838-1.721 2.414-1.721 1.38 0 2.715.37 3.73.848.321.15.689-.073.689-.426v-1.542c0-.518-.195-.824-.505-.945-.698-.271-1.698-.47-3.178-.518v-1.698a.369.369 0 0 0-.369-.368h-.737a.369.369 0 0 0-.368.368v1.725c-2.295.224-4.676 1.52-4.676 4.535 0 5.495 7.825 4.405 7.825 7.3 0 .909-.581 1.694-2.413 1.694-1.842 0-3.534-.644-4.47-1.123a.473.473 0 0 0-.687.427v1.756c0 .336.203.636.513.768 1.11.471 2.598.827 3.907.932v1.876c0 .204.165.369.368.369h.737a.368.368 0 0 0 .369-.369V31.51c3.436-.286 4.678-2.585 4.678-4.615Z"
      />
      <Path
        fill="#A3A3A3"
        d="M13.125 33.75c-.443 0-.83-.207-1.066-.567-.158-.244-.239-.547-.239-.9 0-.614.327-1.685.848-2.756-.144-.944-.415-1.953-.845-2.684-.276-.47-.495-.593-.573-.593-.978 0-1.709 2.055-1.893 2.992a.937.937 0 1 1-1.84-.36c.09-.46.963-4.507 3.733-4.507 1.408 0 2.303 1.384 2.824 2.976.583-.658 1.226-1.101 1.864-1.101 1.868 0 2.482 2.168 2.931 3.75l.049.17.113-.226c.598-1.205 1.276-2.569 2.532-2.569a.937.937 0 0 1 .077 1.872c-.262.188-.694 1.057-.93 1.531-.541 1.092-1.01 2.035-1.96 2.035-1.033 0-1.335-1.066-1.685-2.3-.184-.654-.677-2.388-1.128-2.388-.303.117-.853.888-1.333 1.751.047.431.069.828.069 1.16 0 .63-.065 1.155-.194 1.559-.332 1.043-1.058 1.155-1.354 1.155Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={2.813}
          x2={33.992}
          y1={6.563}
          y2={45.075}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
