import {
  Loan_Application_Type_Enum,
  State_Enum,
  useGetLoanApplicationSecurityDetailsFromLoanApplicationQuery,
} from '../../generated/graphql';
import { parseEnumType } from '../../utils/ensureEnumType';
import {
  mapHasuraAddressToAppAddress,
  mapHasuraAddressToAppAddressForTopUps,
} from '../../utils/mapToAppAddress';
import {
  PropertyDetailsFormFields,
  PropertyDetailsFormValue,
  propertyDetailsManualInputFieldNames,
} from '../utils/propertyDetailsFormUtils';

export function useGetInitialFormValues({
  loanApplicationId,
  loanApplicationSecurityId,
}: {
  loanApplicationId?: string;
  loanApplicationSecurityId?: string;
}) {
  const { data, ...others } =
    useGetLoanApplicationSecurityDetailsFromLoanApplicationQuery({
      variables: {
        loanApplicationId: loanApplicationId ?? '',
        loanApplicationSecurityIdWhere: loanApplicationSecurityId
          ? {
              _eq: loanApplicationSecurityId,
            }
          : {},
      },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationSecurityId,
        },
      },
    });

  const loanApplication = data?.loan_application_by_pk;

  const initialTopUpPropertyValue =
    loanApplication?.parent_loan_account?.security?.owner_estimated_value;

  // Populate the initial state when provided.
  const currentLoanApplicationSecurity =
    loanApplication?.loan_application_securities?.[0];
  const currentSelectedProperty =
    currentLoanApplicationSecurity?.property.address;

  let currentAppAddressForCurrentProperty:
    | ReturnType<typeof mapHasuraAddressToAppAddress>
    | undefined;

  if (currentSelectedProperty) {
    currentAppAddressForCurrentProperty =
      loanApplication?.type === Loan_Application_Type_Enum.TopUp
        ? mapHasuraAddressToAppAddressForTopUps(currentSelectedProperty)
        : mapHasuraAddressToAppAddress(currentSelectedProperty);
  }

  const existingPropertyPurpose =
    currentLoanApplicationSecurity?.property_purpose ??
    loanApplication?.initial_property_purpose;

  const initialFormValues: PropertyDetailsFormValue = {
    addressId: currentSelectedProperty?.id,

    [PropertyDetailsFormFields.PropertyPurpose]:
      existingPropertyPurpose ?? undefined,
    [PropertyDetailsFormFields.PropertyType]:
      currentLoanApplicationSecurity?.property_type ?? undefined,
    [PropertyDetailsFormFields.PropertyValue]:
      currentLoanApplicationSecurity?.owner_estimated_value ||
      initialTopUpPropertyValue ||
      undefined,

    // Only populate this if current address is from suggestion result
    // reason being that manual address values are in separate fields
    [PropertyDetailsFormFields.AddressResult]:
      !currentAppAddressForCurrentProperty?.isProvidedByUser
        ? currentAppAddressForCurrentProperty
        : undefined,

    // Manual address input initial values
    [propertyDetailsManualInputFieldNames.isManualInput]:
      currentAppAddressForCurrentProperty?.isProvidedByUser ?? undefined,
    [propertyDetailsManualInputFieldNames.postcode]:
      currentAppAddressForCurrentProperty?.postcode ?? undefined,
    [propertyDetailsManualInputFieldNames.state]:
      parseEnumType(State_Enum, currentAppAddressForCurrentProperty?.state) ??
      undefined,
    [propertyDetailsManualInputFieldNames.streetName]:
      currentAppAddressForCurrentProperty?.street ?? undefined,
    [propertyDetailsManualInputFieldNames.streetNo]:
      currentAppAddressForCurrentProperty?.streetNo ?? undefined,
    [propertyDetailsManualInputFieldNames.streetType]:
      currentAppAddressForCurrentProperty?.streetType
        ? {
            label: currentAppAddressForCurrentProperty.streetType,
            value: currentAppAddressForCurrentProperty.streetType,
          }
        : undefined,
    [propertyDetailsManualInputFieldNames.suburb]:
      currentAppAddressForCurrentProperty?.suburb ?? undefined,
    [propertyDetailsManualInputFieldNames.unitNo]:
      currentAppAddressForCurrentProperty?.unitNo ?? undefined,
  };

  return {
    initialFormValues,
    loanApplicationTargetId:
      currentLoanApplicationSecurity?.loan_application_target_id,
    ...others,
  };
}
