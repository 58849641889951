import {
  FetchResult,
  MutationFunctionOptions,
  MutationTuple,
} from '@apollo/client';

import { Action_Error_Details } from '../../generated/graphql';
import { normalizeError } from '../normalizeError';

export async function safelyCallMutation<ReturnData, Variables>(
  fn: MutationTuple<ReturnData, Variables>[0],
  options: MutationFunctionOptions<ReturnData, Variables>,
): Promise<[null, Error] | [FetchResult<ReturnData>, null]> {
  try {
    const res = await fn(options);
    return [res, null];
  } catch (e: unknown) {
    // Apollo errors automatically logged by errorLoggerLink in the Apollo Client.
    // See src/utils/apollo.ts for more details
    // Return the error to the caller
    return [null, normalizeError(e)];
  }
}

const ErrorSeparator = '. ';
export function flattenActionErrorDetails(
  errorDetails: Array<Action_Error_Details> | null | undefined,
  errorFallback: string,
) {
  if (errorDetails == null || errorDetails.length === 0) {
    return errorFallback;
  }
  const mappedMessage = errorDetails.map(({ message }) =>
    message.join(ErrorSeparator),
  );
  return mappedMessage.join(ErrorSeparator);
}
