import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

import { usePrevious } from './usePrevious';

export const EXPANDED_COLLAPSIBLE_FIELD = 'expandedCollapsibleField' as const;

export type CollapsibleField = {
  [EXPANDED_COLLAPSIBLE_FIELD]?: string;
};

type UseFormCollapsibleReturn = {
  onPressWrapped: () => void;
  setPickerOpened: (v: boolean) => void;
  isPickerOpened: boolean;
};

type UseFormCollapsibleParams = {
  collapsedProp?: boolean;
  name?: string;
  onPress?: () => void;
  optionsLength?: number;
  // Applicable when collapsible picker is a subpicker (e.g: repayment type picker )
  parentCollapsibleFieldName?: string;
  disableAutoClose?: boolean;
  alwaysExpand?: boolean;
};

export const useFormCollapsible = ({
  collapsedProp,
  name,
  onPress,
  optionsLength,
  parentCollapsibleFieldName,
  disableAutoClose,
  alwaysExpand = false,
}: UseFormCollapsibleParams): UseFormCollapsibleReturn => {
  const { setFieldValue: setExpandedCollapsibleField, values } =
    useFormikContext<CollapsibleField>();

  // isCollapsed state depends on `collapsed` prop & the other picker's state
  const isCollapsed =
    collapsedProp ||
    values[EXPANDED_COLLAPSIBLE_FIELD] !== (parentCollapsibleFieldName ?? name);
  const prevIsCollapsed = usePrevious(isCollapsed);
  // @listiani13 why do we have both isCollapsed and isPickerOpened here?
  // Seems like we sync isCollapsed to isPickerOpened using useEffect
  // What is the goal here?
  // To have a collapsible that refers to the parent context if context is available
  // otherwise fallback to local state?
  const [isPickerOpened, setPickerOpenedState] = useState(
    alwaysExpand || !isCollapsed,
  );

  useEffect(() => {
    // In some case, always expand might
    // start as undefined, e.g. loaded from network
    // We want the collapsible to be initially
    // whatever the computed initial state,
    // Then, expand the selection after loaded.
    if (alwaysExpand) {
      setPickerOpenedState(alwaysExpand);
    }
  }, [alwaysExpand]);

  const setPickerOpened = useCallback(
    (isOpened: boolean) => {
      if (alwaysExpand) {
        // When always expand is turned on,
        // we don't want the picker to be closed.
        return;
      }
      setPickerOpenedState(isOpened);
    },
    [alwaysExpand],
  );

  const onPressWrapped = () => {
    if (name && !disableAutoClose) {
      // Set current expanded collapsible field in Form
      setExpandedCollapsibleField(
        EXPANDED_COLLAPSIBLE_FIELD,
        // If this is subpicker, set current expanded collapsible field to its parent name
        parentCollapsibleFieldName ?? name,
      );
    }
    if (optionsLength) {
      // Only set picker state if collapsible picker has options
      setPickerOpened(!isPickerOpened);
    }
    onPress?.();
  };

  useEffect(() => {
    if (prevIsCollapsed !== isCollapsed) {
      setPickerOpened(!isCollapsed);
    }
  }, [setPickerOpened, isCollapsed, prevIsCollapsed]);

  return { onPressWrapped, setPickerOpened, isPickerOpened };
};
