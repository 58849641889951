import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  Applicant_Role_Enum,
  BorrowerListItem_BorrowerFragment,
  LoanApplicationBorrowerFragment,
  useGetLoanApplicantsQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { EmptyState as EmptyStateComponent } from '../../ui/organisms/EmptyState';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { BorrowerListV2 } from '../components/BorrowerListV2';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';
import { sortApplicants } from '../utils/sortApplicants';

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_BORROWERS>;
const currentSection = LoanApplicationSection.Borrowers;

function LoanApplicationBorrowersBaseV2({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;

  const { data, loading, error, refetch } = useGetLoanApplicantsQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const loanApplication = data?.loan_application_by_pk;

  const loanApplicants = sortApplicants(loanApplication?.applicants);
  const [currentBorrower] = loanApplicants.filter(
    (a) => a.is_current_logged_in_applicant,
  );

  const primaryBorrower = loanApplication?.applicants.find(
    (a) => a.role === Applicant_Role_Enum.PrimaryBorrower,
  )?.user_identity_profile;

  const currentBorrowerIsCoBorrower =
    currentBorrower?.role === Applicant_Role_Enum.Borrower;

  const hasCurrentBorrowerDoneIdCheck = hasDoneIdCheck(currentBorrower);
  const { navigateToNextLoanApplicationScreen, getScreenOrder } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  const onContinuePress = () => {
    if (hasCurrentBorrowerDoneIdCheck) {
      navigateToNextLoanApplicationScreen({
        currentSection: LoanApplicationSection.Borrowers,
      });
      return;
    }

    /** This block should be unreachable */
    if (currentBorrower?.id == null) {
      captureException('Current Borrower has no id', { currentBorrower });
      return;
    }

    // If current borrower data hasnt been completed, navigate to Borrower Wizard
    navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
      screen: Screen.IDENTIFICATION_V2_YOUR_INFORMATION,
      params: {
        applicantId: currentBorrower.id,
        applicantRole: Applicant_Role_Enum.PrimaryBorrower,
        currentBorrowerIsCoBorrower,
        currentBorrowerFirstName: currentBorrower.first_name,
        primaryBorrowerFullName: {
          firstName: primaryBorrower?.first_name,
          middleName: primaryBorrower?.middle_name,
          lastName: primaryBorrower?.last_name,
        },
        primaryBorrowerDriverLicenceNumber:
          primaryBorrower?.frankieone_entity_details?.driver_licence
            ?.licence_number,
        primaryBorrowerPassportNumber:
          primaryBorrower?.frankieone_entity_details?.passport?.passport_number,
        ...route.params,
      },
    });
  };

  const onBorrowerPress = (applicant: BorrowerListItem_BorrowerFragment) => {
    navigation.navigate(Screen.BORROWER_V2_MODAL, {
      screen: Screen.BORROWER_V2_DETAILS,
      params: {
        borrowerId: applicant.id,
        isCurrentLoggedInApplicant: applicant.is_current_logged_in_applicant,
        ...route.params,
      },
    });
  };
  const onAddBorrower = useCallback(() => {
    navigation.navigate(Screen.BORROWER_V2_MODAL, {
      screen: Screen.BORROWER_V2_INVITE_COBORROWER,
      params: {
        loanApplicationId,
      },
    });
  }, [navigation, loanApplicationId]);

  /**
   * This block should be unreachable,
   * unless the app somehow reach this screen
   * with unintended method
   */
  if (!loanApplicationId) {
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <EmptyStateComponent
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </LoanApplicationScreenContainer>
    );
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.Borrowers.Error.FailFetchApplicants')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });

  if (!loading && loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer bg="bg">
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={
          hasCurrentBorrowerDoneIdCheck
            ? t('Content.Borrowers.Header.Title')
            : t('Content.Borrowers.Header.YourInformation')
        }
        caption={
          currentBorrowerIsCoBorrower
            ? t('Content.Borrowers.Header.CaptionCoBorrower', {
                coBorrowerFullName: currentBorrower.latest_full_name || '',
              })
            : t('Content.Borrowers.Header.Caption')
        }
      />
      <ScreenLoadingContainer loading={loading}>
        {!loading && loanApplicants?.length && hasCurrentBorrowerDoneIdCheck ? (
          <BorrowerListV2
            mt="xl"
            mb="l"
            px="l"
            borrowers={loanApplicants}
            onBorrowerPress={onBorrowerPress}
            onAddBorrower={onAddBorrower}
          />
        ) : (
          <EmptyState />
        )}
      </ScreenLoadingContainer>
      <LoanApplicationWizardFooter
        primaryButtonLabel={
          hasCurrentBorrowerDoneIdCheck
            ? t('Content.Common.ButtonLabel.Continue')
            : t('Content.Borrowers.ButtonLabel.AddYourInformation')
        }
        onPrimaryButtonPress={onContinuePress}
        primaryButtonTestID={TestID.LoanApplicationBorrowers.ContinueButton}
        isPrimaryButtonLoading={loading}
        style={{ marginTop: 0 }}
      />
    </LoanApplicationScreenContainer>
  );
}

function EmptyState() {
  return (
    <Box flex={1} alignItems="center" my="xl">
      <EmptyStateIllustration name="borrowerV2" />
    </Box>
  );
}

function hasDoneIdCheck(borrower: LoanApplicationBorrowerFragment | undefined) {
  return borrower?.user_identity_profile?.frankieone_entity_id != null;
}

export const LoanApplicationBorrowersV2 = withAuthenticationRequired(
  LoanApplicationBorrowersBaseV2,
);
