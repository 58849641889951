import { useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useGetPaymentFailureDetailsQuery } from '../../generated/graphql';

// This query is used on the PaymentFailureModal
// as well as the PaymentFailureNotification component
export function usePaymentFailureDetails(cbaAccountId?: string) {
  const { flags } = useContext(FeatureFlagsContext);
  const {
    ENABLE_APP_PAYMENT_FAILURE_ALERT: amortizationPaymentFailureEnabled,
    ENABLE_APP_DIRECT_DEBIT_FAILURE_ALERT: directDebitFailureEnabled,
  } = flags;

  const { data, loading } = useGetPaymentFailureDetailsQuery({
    nextFetchPolicy: 'cache-first',
    context: {
      sentryContext: {
        cbaAccountId,
        description: 'Payment failure notification',
      },
    },
    variables: {
      cbaAccountId: cbaAccountId ?? '',
      amortizationPaymentFailureEnabled,
      directDebitFailureEnabled,
    },
    skip:
      !cbaAccountId ||
      (!amortizationPaymentFailureEnabled && !directDebitFailureEnabled),
  });

  const {
    amortization_payment_failure,
    direct_debit_failure,
    next_installment,
    next_repayment,
    settings,
  } = data?.loan_account_by_id ?? {};

  const accountDetails = settings
    ? {
        accountNumber: settings.account_number,
        bsb: settings.bsb,
        name: settings.name,
      }
    : null;

  const nextRepaymentDate = next_installment?.next_installment_date;
  const nextDirectDebitDate = next_repayment?.next_repayment_day;

  const amortizationFailureDetails =
    amortization_payment_failure && accountDetails
      ? {
          accountDetails,
          daysInArrears: amortization_payment_failure.days_in_arrears,
          daysLate: amortization_payment_failure.days_late,
          totalOverdueAmount: amortization_payment_failure.total_overdue_amount,
        }
      : null;

  const isAutoPayEnabled = !!settings?.autopay?.id;
  const directDebitFailureDetails =
    isAutoPayEnabled &&
    direct_debit_failure &&
    accountDetails &&
    nextRepaymentDate &&
    nextDirectDebitDate
      ? {
          daysLate: direct_debit_failure.days_late,
          detailedReason: direct_debit_failure.detailed_reason,
          loanAccountName: accountDetails.name,
          nextRepaymentDate,
          nextDirectDebitDate,
          failureType: direct_debit_failure.failure_type,
          willRetry: direct_debit_failure.will_retry,
        }
      : null;

  return { amortizationFailureDetails, directDebitFailureDetails, loading };
}
