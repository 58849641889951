import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useUser } from '../../Auth/hooks';
import { autopayCancellationStatusAtom } from '../../AutopaySettings/autopayAtom';
import {
  Approval_Status_Enum,
  Change_Request_Status_Enum,
} from '../../generated/graphql';
import { useLoanAccountWithTransactions } from '../../HomeLoan/graphql/loanAccountQueries';
import { Screen } from '../../navigation/types/screens';
import { AutoPayDebitCard } from './AutoPayDebitCard';
import { RepaymentCard } from './RepaymentCard';

type HomeLoanOnSchedulePaymentProps = {
  cbaAccountId: string;
  showAccountName?: boolean;
  loan: ReturnType<typeof useLoanAccountWithTransactions>['data'];
};

enum SCHEDULE_STATUS_ENUM {
  'PENDING_APPROVAL' = 'PENDING_APPROVAL',
  'APPROVED_AND_PROCESSING' = 'APPROVED_AND_PROCESSING',
  'AUTOPAY_FINALIZED_BY_MAMBU' = 'AUTOPAY_FINALIZED_BY_MAMBU',
  'AUTOPAY_FINALIZED_BY_REQUEST' = 'AUTOPAY_FINALIZED_BY_REQUEST',
  'REPAYMENT' = 'REPAYMENT',
}

export function HomeLoanOnSchedulePayment({
  cbaAccountId,
  showAccountName = false,
  loan,
}: HomeLoanOnSchedulePaymentProps) {
  const isLastRequestTheCurrentActiveOne = useRef(false);
  const setAutopayCancellationStatus = useSetRecoilState(
    autopayCancellationStatusAtom,
  );
  const autopayCancellationStatus = useRecoilValue(
    autopayCancellationStatusAtom,
  );

  const navigation = useNavigation();
  const { user } = useUser();

  const currentMambuAutopay = loan?.settings?.autopay;
  const nextRepayment = loan?.next_repayment;
  const lastAutopayChangeRequest = loan?.last_autopay_change_request;

  const pendingApprovalFromWho =
    lastAutopayChangeRequest?.approval_request?.requested_by.user_id ===
    user?.id
      ? 'coBorrower'
      : 'me';

  const isAutopayBeApprovedAndInProcessing =
    lastAutopayChangeRequest?.approval_request?.status ===
      Approval_Status_Enum.Approved &&
    lastAutopayChangeRequest?.status === Change_Request_Status_Enum.Pending;

  const isAutopayPendingMyApproval =
    lastAutopayChangeRequest?.approval_request?.status ===
      Approval_Status_Enum.Pending && pendingApprovalFromWho === 'me';

  const isAutopayPendingCoBorrowerApproval =
    lastAutopayChangeRequest?.approval_request?.status ===
      Approval_Status_Enum.Pending && pendingApprovalFromWho === 'coBorrower';

  const computeSchedule = useCallback(() => {
    if (isAutopayPendingMyApproval || isAutopayPendingCoBorrowerApproval) {
      isLastRequestTheCurrentActiveOne.current = false;
      return SCHEDULE_STATUS_ENUM.PENDING_APPROVAL;
    }

    if (isAutopayBeApprovedAndInProcessing) {
      isLastRequestTheCurrentActiveOne.current = true;
      return SCHEDULE_STATUS_ENUM.APPROVED_AND_PROCESSING;
    }

    if (
      lastAutopayChangeRequest?.status === Change_Request_Status_Enum.Applied &&
      isLastRequestTheCurrentActiveOne.current &&
      autopayCancellationStatus !== Change_Request_Status_Enum.Applied
    ) {
      return SCHEDULE_STATUS_ENUM.AUTOPAY_FINALIZED_BY_REQUEST;
    }

    if (currentMambuAutopay) {
      return SCHEDULE_STATUS_ENUM.AUTOPAY_FINALIZED_BY_MAMBU;
    }

    return SCHEDULE_STATUS_ENUM.REPAYMENT;
  }, [
    autopayCancellationStatus,
    currentMambuAutopay,
    isAutopayBeApprovedAndInProcessing,
    isAutopayPendingCoBorrowerApproval,
    isAutopayPendingMyApproval,
    lastAutopayChangeRequest?.status,
  ]);

  const schedule = computeSchedule();

  useEffect(() => {
    if (
      schedule === SCHEDULE_STATUS_ENUM.PENDING_APPROVAL ||
      schedule === SCHEDULE_STATUS_ENUM.APPROVED_AND_PROCESSING
    ) {
      setAutopayCancellationStatus(Change_Request_Status_Enum.Pending);
    }
  }, [schedule, setAutopayCancellationStatus]);

  const propertyShortAddress =
    loan?.loan_application_security?.property?.address?.short_address_format;

  const finalizedPayment = {
    nextRepaymentDate: loan?.next_repayment?.next_repayment_day || '',
    repaymentAmount: loan?.next_repayment?.repayment_amount || 0,
    propertyAddress: showAccountName
      ? propertyShortAddress || loan?.settings?.name
      : '',
  };

  const onRequestedAutopayPayment = {
    nextRepaymentDate: lastAutopayChangeRequest?.first_repayment_date || '',
    repaymentAmount: lastAutopayChangeRequest?.repayment_amount || 0,
    propertyAddress: showAccountName
      ? propertyShortAddress || loan?.settings?.name
      : '',
  };

  const coBorrowers = lastAutopayChangeRequest?.approval_request?.responses
    ?.filter(
      (response) => response.identity_profile_id !== loan?.myIdentityProfileId,
    )
    .map((response) => response.identity_profile?.first_name ?? '');

  const openAutopayChangeRequestModal = useCallback(() => {
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_CHANGE_REQUEST,
      params: {
        cbaAccountId,
        autopayChangeRequestId: lastAutopayChangeRequest?.id || '',
      },
    });
  }, [navigation, cbaAccountId, lastAutopayChangeRequest?.id]);

  const openAutopayExistingModal = useCallback(() => {
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_EXISTING_SETTINGS,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const openAutopaySetupModal = useCallback(() => {
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_SETUP,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const openNextRepaymentDetailsModal = useCallback(() => {
    if (nextRepayment) {
      navigation.navigate(Screen.HOME_LOAN_MODAL, {
        screen: Screen.HOME_NEXT_REPAYMENT,
        params: {
          cbaAccountId,
          repaymentId: nextRepayment.id,
          amount: nextRepayment.repayment_amount,
          dueDate: nextRepayment.next_repayment_day,
        },
      });
    }
  }, [cbaAccountId, navigation, nextRepayment]);

  if (schedule === SCHEDULE_STATUS_ENUM.AUTOPAY_FINALIZED_BY_MAMBU) {
    return (
      <AutoPayDebitCard
        {...finalizedPayment}
        onViewAutopay={openAutopayExistingModal}
      />
    );
  }

  if (schedule === SCHEDULE_STATUS_ENUM.AUTOPAY_FINALIZED_BY_REQUEST) {
    return (
      <AutoPayDebitCard
        {...onRequestedAutopayPayment}
        onViewAutopay={openAutopayExistingModal}
      />
    );
  }

  if (schedule === SCHEDULE_STATUS_ENUM.APPROVED_AND_PROCESSING) {
    return (
      <AutoPayDebitCard
        {...onRequestedAutopayPayment}
        onViewAutopay={openAutopayChangeRequestModal}
      />
    );
  }

  if (schedule === SCHEDULE_STATUS_ENUM.PENDING_APPROVAL) {
    if (pendingApprovalFromWho === 'me') {
      return (
        <AutoPayDebitCard
          {...onRequestedAutopayPayment}
          onPendingMyApproval={openAutopayChangeRequestModal}
        />
      );
    }

    if (pendingApprovalFromWho === 'coBorrower') {
      return (
        <AutoPayDebitCard
          {...onRequestedAutopayPayment}
          coBorrowers={coBorrowers}
          onViewAutopay={openAutopayChangeRequestModal}
        />
      );
    }
  }

  if (schedule === SCHEDULE_STATUS_ENUM.REPAYMENT) {
    return (
      <RepaymentCard
        onSetupAutopay={openAutopaySetupModal}
        onNextRepayment={openNextRepaymentDetailsModal}
        {...finalizedPayment}
      />
    );
  }

  return null;
}
