import { Text, View } from 'dripsy';
import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ActionSheet } from '../../ActionSheet/components/ActionSheet';
import { ActionSheetScreenProps } from '../../ActionSheet/navigation/types';
import {
  useApproveRequestMutation,
  useCancelRequestMutation,
  useDeclineRequestMutation,
} from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { AccountPermission, ApprovalResponseType } from '../types';
import { AccountPermissionsApprovalScreenErrorAtomFamily } from '../utils/atoms';
import {
  formatPermissions,
  getMessageToDisplay,
} from '../utils/disclaimerText';

const titleMap: Record<ApprovalResponseType, string> = {
  Approve: t('Content.AccountPermissions.ApprovalActionSheet.Title.Approve'),
  Decline: t('Content.AccountPermissions.ApprovalActionSheet.Title.Decline'),
  Cancel: t('Content.AccountPermissions.ApprovalActionSheet.Title.Cancel'),
};

const confirmMessageMap: Record<ApprovalResponseType, string> = {
  Approve: t(
    'Content.AccountPermissions.ApprovalSubmittedActionSheet.Approved',
  ),
  Decline: t(
    'Content.AccountPermissions.ApprovalSubmittedActionSheet.Declined',
  ),
  Cancel: t(
    'Content.AccountPermissions.ApprovalSubmittedActionSheet.Cancelled',
  ),
};

export type Props =
  ActionSheetScreenProps<ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL>;

type ContentProps = {
  responseType: ApprovalResponseType;
  permissions: AccountPermission[];
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

type ApproveContentProps = {
  permissions: AccountPermission[];
};

const ApproveContent = ({ permissions }: ApproveContentProps) => {
  const approveMessage = useMemo(
    () => getMessageToDisplay(formatPermissions(permissions)),
    [permissions],
  );

  const approveSubText = useMemo(() => {
    const areAllPermissionsOneToApprove = permissions.every(
      (p) => !p.require_approvals,
    );
    const textPrefix = 'Content.AccountPermissions.ConfirmChanges.SubText';
    return areAllPermissionsOneToApprove
      ? t(`${textPrefix}.EmailConfirmationOnApprove`)
      : t(`${textPrefix}.EmailConfirmationOnSubmit`);
  }, [permissions]);

  return (
    <View sx={{ py: '$8', px: '$16' }}>
      <Text sx={{ textAlign: 'center' }}>{approveMessage}</Text>
      <Text sx={{ mt: '$8', textAlign: 'center' }}>{approveSubText}</Text>
    </View>
  );
};

const DeclineAndCancelContent = ({ message }: { message: string }) => (
  <Text variant="body" sx={{ py: '$8', px: '$16', textAlign: 'center' }}>
    {message}
  </Text>
);

export const AccountPermissionsApprovalActionSheetContent = ({
  permissions,
  responseType,
  loading = false,
  onCancel,
  onConfirm,
}: ContentProps) => {
  const content = useMemo(() => {
    const renderMap: Record<ApprovalResponseType, JSX.Element> = {
      Approve: <ApproveContent permissions={permissions} />,
      Cancel: (
        <DeclineAndCancelContent
          message={t(
            'Content.AccountPermissions.ApprovalActionSheet.Text.Cancel',
          )}
        />
      ),
      Decline: (
        <DeclineAndCancelContent
          message={t(
            'Content.AccountPermissions.ApprovalActionSheet.Text.Decline',
          )}
        />
      ),
    };
    return renderMap[responseType];
  }, [permissions, responseType]);

  return (
    <ActionSheet
      showCloseButton={false}
      isRowButton
      title={titleMap[responseType]}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.AccountPermissions.ApprovalActionSheet.CancelButton,
          disabled: loading,
          onPress: onCancel,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          showSpinner: loading,
          disabled: loading,
          testID: TestID.AccountPermissions.ApprovalActionSheet.ConfirmButton,
          onPress: onConfirm,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      {content}
    </ActionSheet>
  );
};

export const AccountPermissionsApprovalActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { responseType, approvalRequestId, cbaAccountId, permissions } =
    route.params;

  const [approveRequest, { loading: approveRequestLoading }] =
    useApproveRequestMutation();
  const [declineRequest, { loading: declineRequestLoading }] =
    useDeclineRequestMutation();
  const [cancelRequest, { loading: cancelRequestLoading }] =
    useCancelRequestMutation();

  const ShowErrorAtom =
    AccountPermissionsApprovalScreenErrorAtomFamily(cbaAccountId);
  const setShowError = useSetRecoilState(ShowErrorAtom);

  const navigateOnConfirm = useCallback(() => {
    const isApprove = responseType === ApprovalResponseType.Approve;
    navigation.replace(
      ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED,
      {
        cbaAccountId,
        message: confirmMessageMap[responseType],
        supportingText: !isApprove
          ? undefined
          : t(
              'Content.AccountPermissions.ApprovalSubmittedActionSheet.EmailConfirmation',
            ),
      },
    );
  }, [cbaAccountId, responseType, navigation]);

  const onConfirm = useCallback(async () => {
    type Mutation =
      | typeof approveRequest
      | typeof declineRequest
      | typeof cancelRequest;

    const mutationMap: Record<ApprovalResponseType, Mutation> = {
      Approve: approveRequest,
      Decline: declineRequest,
      Cancel: cancelRequest,
    };

    const [_, error] = await safelyCallMutation(mutationMap[responseType], {
      variables: {
        approval_request_id: approvalRequestId,
      },
      context: {
        sentryContext: {
          description: `Account Permissions - ${responseType} Request Mutation`,
        },
      },
    });

    if (error) {
      setShowError(true);
      navigation.goBack();
    } else {
      navigateOnConfirm();
    }
  }, [
    navigation,
    approvalRequestId,
    responseType,
    approveRequest,
    declineRequest,
    cancelRequest,
    navigateOnConfirm,
    setShowError,
  ]);

  const onCancel = useCallback(() => {
    // Navigate back to the approval screen
    navigation.goBack();
  }, [navigation]);

  const loading =
    approveRequestLoading || declineRequestLoading || cancelRequestLoading;

  return (
    <AccountPermissionsApprovalActionSheetContent
      loading={loading}
      permissions={permissions}
      responseType={responseType}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
