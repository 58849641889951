import { Text } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { useApproveRequestMutation } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST>;

export const ReduceRepaymentsApproveRequestActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { approvalRequestId, cbaAccountId } = route.params;
  const [
    approveRequest,
    { loading: approveRequestLoading, error: approveRequestError },
  ] = useApproveRequestMutation();

  const onPress = useCallback(async () => {
    const [response, error] = await safelyCallMutation(approveRequest, {
      variables: {
        approval_request_id: approvalRequestId,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Loan Variation - Approve Request Mutation',
        },
      },
    });
    if (!error && !!response.data) {
      navigation.replace(ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED);
    }
  }, [cbaAccountId, approvalRequestId, approveRequest, navigation]);

  const errorMessage = approveRequestError
    ? (t(
        'Content.LoanVariation.ReduceRepaymentConfirmation.UnableToProcessRequest',
      ) as string)
    : undefined;

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      showCloseButton={false}
      isRowButton
      errorMessage={errorMessage}
      title={t('Content.LoanVariation.ReduceRepaymentConfirmation.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          disabled: approveRequestLoading,
          testID: TestID.LoanVariation.ApproveRequest.CancelButton,
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          testID: TestID.LoanVariation.ApproveRequest.ConfirmButton,
          showSpinner: approveRequestLoading,
          disabled: approveRequestLoading || !!approveRequestError,
          onPress,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <Text
        variant="body"
        sx={{ py: '$8', px: '$16', mb: '$16', textAlign: 'center' }}
      >
        {t('Content.LoanVariation.ReduceRepaymentConfirmation.WarningText')}
      </Text>
    </ActionSheet>
  );
};
