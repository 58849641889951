import { useAuth0 } from '@auth0/auth0-react';
import { LDContext, LDMultiKindContext } from 'launchdarkly-react-client-sdk';
import { useCallback, useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { anonymousContext } from '../../FeatureFlags/LaunchDarklyClient';
import {
  getAnonymousUserIdContext,
  useFetchLdUserContext,
} from '../../FeatureFlags/ldContextUtils';

export const useRefreshLdContext = () => {
  const { isAuthenticated } = useAuth0();
  const { identify, setLdContext } = useContext(FeatureFlagsContext);
  const fetchLdUserContext = useFetchLdUserContext();

  return useCallback(
    async (loanApplicationId?: string) => {
      const userContext = isAuthenticated ? await fetchLdUserContext() : null;
      const context = userContext
        ? {
            kind: 'multi',
            user: {
              ...(userContext as LDContext),
            },
            loan_application_id: {
              kind: 'loan_application_id',
              ...(loanApplicationId
                ? { key: loanApplicationId }
                : { anonymous: true }),
            },
            ...getAnonymousUserIdContext(),
          }
        : anonymousContext;
      await identify?.((context as LDMultiKindContext) ?? anonymousContext);
      if (userContext?.key) {
        setLdContext?.({
          userId: userContext?.key,
          loanApplicationId,
        });
      }
    },
    [isAuthenticated, fetchLdUserContext, identify, setLdContext],
  );
};
