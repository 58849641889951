import { Text, useSx } from 'dripsy';

import { Box } from '../../../ui/atoms/Box';
import { Link } from '../../../ui/atoms/Link';
import { formatCurrency } from '../../../utils/currencyHelpers';

type Props = {
  amount: number;
  alertMessage: string;
};
export const NoInterestSavingsAlert = ({ amount, alertMessage }: Props) => {
  const sx = useSx();
  return (
    <Box width="100%">
      <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
        {t(
          'Content.HomeLoan.Insights.TotalInterestSavings.TotalSavedOnInterest',
        )}
      </Text>
      <Text variant="lHeader">
        {formatCurrency(amount, {
          noFraction: true,
        })}
      </Text>
      <Text variant="default" sx={(theme) => ({ marginTop: theme.space.$18 })}>
        {alertMessage}
      </Text>
      <Text
        variant="caption"
        sx={(theme) => ({
          marginTop: theme.space.$32,
          color: theme.colors.$labelsPrimary,
        })}
      >
        {t(
          'Content.HomeLoan.Insights.TotalInterestSavings.YouCloudSaveOnInterest',
        )}
        <Link
          style={sx({ variant: 'text.caption', color: '$link' })}
          href={t('Link.LearnMoreOnInterestSaving')}
        >
          {t('Content.HomeLoan.Insights.TotalInterestSavings.LearnMore')}
        </Link>
      </Text>
    </Box>
  );
};
