import { FormikHelpers } from 'formik';

import {
  Action_Error_Details,
  Add_Coborrower_Error_Type,
} from '../../../generated/graphql';
import { flattenActionErrorDetails } from '../../../utils/hooks/errorUtils';
import { assertUnreachable } from '../../../utils/typesHelpers';
import { InviteCoBorrowerField, InviteCoBorrowerFormValues } from './forms';
import {
  InviteCoBorrowerDuplicateError,
  InviteCoBorrowerOtherError,
} from './inviteCoBorrowerErrorEmitter';

export function mapInviteCoBorrowerErrorToFormError(
  errorType: InviteCoBorrowerOtherError | null,
  errorDetails?: Array<Action_Error_Details> | null,
) {
  const defaultErrorMessage = t('Content.Common.Error.FailInviteCoBorrower');
  if (errorType === null) {
    return defaultErrorMessage;
  }

  switch (errorType) {
    case Add_Coborrower_Error_Type.InvalidInput:
      return flattenActionErrorDetails(errorDetails, defaultErrorMessage);
    case Add_Coborrower_Error_Type.MaxBorrowersReached:
      return t('Content.InviteCoBorrower.Error.MaxBorrowersReached');
    default:
      assertUnreachable(errorType);
      return defaultErrorMessage;
  }
}

type InviteCoBorrowerSetFieldError =
  FormikHelpers<InviteCoBorrowerFormValues>['setFieldError'];
const duplicateEmail = (setFieldError: InviteCoBorrowerSetFieldError) =>
  setFieldError(
    InviteCoBorrowerField.Email,
    t('Content.InviteCoBorrower.Error.DuplicateEmail'),
  );
const duplicateNumber = (setFieldError: InviteCoBorrowerSetFieldError) =>
  setFieldError(
    InviteCoBorrowerField.Mobile,
    t('Content.InviteCoBorrower.Error.DuplicateNumber'),
  );
const duplicateEmailAndNumber = (
  setFieldError: InviteCoBorrowerSetFieldError,
) => {
  duplicateEmail(setFieldError);
  duplicateNumber(setFieldError);
};

export function mapInviteCoBorrowerErrorToFieldError(
  errorType: InviteCoBorrowerDuplicateError | null,
  setFieldError: InviteCoBorrowerSetFieldError,
) {
  if (errorType === null) {
    return;
  }
  switch (errorType) {
    case Add_Coborrower_Error_Type.DuplicateNumber: {
      duplicateNumber(setFieldError);
      break;
    }
    case Add_Coborrower_Error_Type.DuplicateEmail: {
      duplicateEmail(setFieldError);
      break;
    }
    case Add_Coborrower_Error_Type.DuplicateNumberAndEmail: {
      duplicateEmailAndNumber(setFieldError);
      break;
    }
    default: {
      assertUnreachable(errorType);
    }
  }
}
