import { RouteProp, useRoute } from '@react-navigation/native';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  getBizaAccount,
  getUserId,
} from '../../DataSharingConfirmation/utils/biza';
import { useGetBizaAccountByIdQuery } from '../../generated/graphql';
import { SettingsStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import DataSharingContent from '../components/DataSharingContent';
import ErrorMessage from '../components/ErrorMessage';
import { DataSharingFooterCaption } from './ManageDataSharing';

const DetailsNotFound: React.FC = () => (
  <>
    <StyledText
      testID={TestID.ManageDataSharing.DataSharingAccountDetailsNotFound}
      textAlign="center"
      variant="caption"
      pt="m"
    >
      {t('Content.DataSharingPreferences.MenuItems.JointAccounts.NoAccounts')}
    </StyledText>
    <Separator spacer />
  </>
);

const Content: React.FC<{
  accountId: string;
}> = ({ accountId }) => {
  const { data, loading, error } = useGetBizaAccountByIdQuery({
    variables: { accountId },
    nextFetchPolicy: 'cache-and-network',
    context: {
      sentryContext: {
        bizaAccountId: accountId,
        description: 'Fetching Biza Account By Id.',
      },
    },
  });

  if (error) return <ErrorMessage />;
  if (loading) return <Spinner />;
  if (!data) return <ErrorMessage />;

  const userId = getUserId(data);
  const account = getBizaAccount(data);

  if (!account) return <DetailsNotFound />;

  return <DataSharingContent userId={userId} account={account} />;
};

function JointAccountPreferencesBase(): JSX.Element {
  const route =
    useRoute<
      RouteProp<SettingsStackParams, Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>
    >();

  const { id: accountId } = route.params;

  return (
    <ScreenContainer bg="bgSecondary" scrollable px="m">
      <NavHeaderSpacer />
      <Content accountId={accountId} />
      <Separator spacer />
      <DataSharingFooterCaption />
    </ScreenContainer>
  );
}

export const WithAuthenticationSettingsJointAccountPreferences =
  withAuthenticationRequired(JointAccountPreferencesBase);
