import { A, Text, View } from 'dripsy';

import { Button } from '../../ui/atoms/Button';
import { AutopayEmploymentIncome } from '../../ui/svgs/AutopayEmploymentIncome';
import { AutopayOutgoingExpenses } from '../../ui/svgs/AutopayOutgoingExpenses';
import { AutopayRefinanceIcon } from '../../ui/svgs/AutopayRefinanceIcon';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useIsMobile } from '../../utils/hooks/useBreakpoint';

type AutopayGetStartedV2Props = {
  onClose: () => void;
  onGetStarted: () => void;
};

export function AutopayGetStartedV2({
  onClose,
  onGetStarted,
}: AutopayGetStartedV2Props) {
  const isMobile = useIsMobile();

  return (
    <ModalScreenContainer
      onClose={onClose}
      hideBackButton
      aria-label={`${t('Content.Accessibility.Autopay.AutopaySetupModal')} ${t(
        'Content.AutopaySettings.GetStarted.Description',
      )}`}
      role="dialog"
    >
      <View
        sx={{
          flex: 1,
          alignItems: 'center',
          paddingX: isMobile ? undefined : '$40',
        }}
      >
        <Text
          role="heading"
          variant="lHeader"
          sx={{ mt: '$16', textAlign: 'center' }}
        >
          {t('Content.AutopaySettings.GetStarted.Title')}
        </Text>
        <Text
          variant="subHeading"
          sx={{ mt: '$16', mb: '$40', textAlign: 'center' }}
        >
          {t('Content.AutopaySettings.GetStarted.Description')}{' '}
          <A
            href={t('Link.LearnMoreAutopay')}
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            variant="link"
            aria-label={t(
              'Content.Accessibility.Autopay.LearnMoreAboutAutopayLink',
            )}
          >
            {t('Content.Common.ButtonLabel.LearnMore')}
          </A>
        </Text>

        <View sx={{ flexDirection: 'column', mb: '$40' }}>
          <View sx={{ flexDirection: 'row', mb: '$16', maxWidth: '100%' }}>
            <View sx={{ mr: '$16' }}>
              <AutopayRefinanceIcon size={45} />
            </View>
            <View sx={{ flexDirection: 'column', flexShrink: 1 }}>
              <Text variant="body" sx={{ fontWeight: 'semibold' }}>
                {t('Content.AutopaySettings.GetStarted.Row1.Title')}
              </Text>
              <Text variant="sBody">
                {t('Content.AutopaySettings.GetStarted.Row1.Description')}
              </Text>
            </View>
          </View>

          <View sx={{ flexDirection: 'row', mb: '$16', maxWidth: '100%' }}>
            <View sx={{ mr: '$16' }}>
              <AutopayOutgoingExpenses size={45} />
            </View>
            <View sx={{ flexDirection: 'column', flex: 1, flexShrink: 1 }}>
              <Text variant="body" sx={{ fontWeight: 'semibold' }}>
                {t('Content.AutopaySettings.GetStarted.Row2.Title')}
              </Text>
              <Text variant="sBody">
                {t('Content.AutopaySettings.GetStarted.Row2.Description')}
              </Text>
            </View>
          </View>

          <View sx={{ flexDirection: 'row', maxWidth: '100%' }}>
            <View sx={{ mr: '$16' }}>
              <AutopayEmploymentIncome size={45} />
            </View>
            <View sx={{ flexDirection: 'column', flex: 1, flexShrink: 1 }}>
              <Text variant="body" sx={{ fontWeight: 'semibold' }}>
                {t('Content.AutopaySettings.GetStarted.Row3.Title')}
              </Text>
              <Text variant="sBody">
                {t('Content.AutopaySettings.GetStarted.Row3.Description')}
              </Text>
            </View>
          </View>
        </View>

        <Button
          label={t('Content.Common.ButtonLabel.GetStarted')}
          width="100%"
          onPress={onGetStarted}
        />
      </View>
    </ModalScreenContainer>
  );
}
