import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  useGetLoanAccountDataForPrincipalReductionQuery,
  useGetMinimumRepaymentLazyQuery,
} from '../../generated/graphql';
import { useDebounce } from '../../utils/hooks/useDebounce';
import {
  ReduceRepaymentsAtomFamily,
  ReduceRepaymentsConfirmedAtomFamily,
} from './reduceRepaymentsAtom';

const DEBOUNCE_WAIT_TIME_IN_MS = 300;

export const useLoanVariationReduceRepayments = (cbaAccountId: string) => {
  const amountToBeReduced = useRef<number>(0);
  const {
    data: loanData,
    loading: loanAccountLoading,
    error: loanDataError,
    refetch: refetchLoanAccountState,
  } = useGetLoanAccountDataForPrincipalReductionQuery({
    context: {
      sentryContext: {
        cbaAccountId,
        description: 'Get Loan Account Data for Principal Reduction',
      },
    },
    variables: {
      cbaAccountId,
    },
  });

  const [
    getMinimumRepayments,
    {
      data: minimumRepaymentData,
      loading: minimumRepaymentLoading,
      called: minimumRepaymentCalled,
      error: minimumRepaymentError,
    },
  ] = useGetMinimumRepaymentLazyQuery({
    context: {
      sentryContext: {
        cbaAccountId,
        description: 'Get Minimum Repayment Value for Principal Reduction',
      },
    },
  });

  const setReduceRepaymentsAtom = useSetRecoilState(
    ReduceRepaymentsAtomFamily(cbaAccountId),
  );

  // To track if the user has confirmed the loan variation request
  const isRepaymentConfirmed = useRecoilValue(
    ReduceRepaymentsConfirmedAtomFamily(cbaAccountId),
  );

  const resetIsRepaymentConfirmed = useResetRecoilState(
    ReduceRepaymentsConfirmedAtomFamily(cbaAccountId),
  );

  const loanBalances = loanData?.loan_account_by_id?.balances;
  const loanSettings = loanData?.loan_account_by_id?.settings;
  const newRepaymentSchedule = minimumRepaymentData?.repayment_schedule;

  // Values to be used in the UI
  const loanPrincipalBalance = loanBalances?.principal_balance;
  const totalRedrawAmount = loanBalances?.available_redraw_balance;
  const currentMonthlyRepaymentAmount = loanData?.next_installment_amount;
  const interestRate = loanSettings?.interest_rate;
  const termsInMonths = loanSettings?.remaining_term_in_months;
  const newMinimumMonthlyRepaymentAmount = newRepaymentSchedule?.monthly_amount;
  const monthlyRepaymentDifference = useMemo(
    () =>
      currentMonthlyRepaymentAmount && newMinimumMonthlyRepaymentAmount
        ? currentMonthlyRepaymentAmount - newMinimumMonthlyRepaymentAmount
        : 0,
    [currentMonthlyRepaymentAmount, newMinimumMonthlyRepaymentAmount],
  );
  const redrawBalance = useMemo(() => {
    if (totalRedrawAmount && currentMonthlyRepaymentAmount) {
      return Math.floor(totalRedrawAmount - currentMonthlyRepaymentAmount);
    }
    return 0;
  }, [totalRedrawAmount, currentMonthlyRepaymentAmount]);

  // Redraw balance is calculate when loan data is fetched
  // and loan data is fetched only once
  // Hence the below useEffect should only run once
  useEffect(() => {
    if (redrawBalance) {
      amountToBeReduced.current = redrawBalance;
    }
  }, [redrawBalance, amountToBeReduced]);

  // The minimum repayments query required data from the loan account query
  // Hence the below useEffect should only run when loan data is fetched
  useEffect(() => {
    const shouldFetchMinimumRepayment =
      !loanAccountLoading &&
      !minimumRepaymentCalled &&
      loanPrincipalBalance &&
      interestRate &&
      termsInMonths;
    if (shouldFetchMinimumRepayment) {
      const newLoanAmount = loanPrincipalBalance - redrawBalance;
      getMinimumRepayments({
        variables: {
          loanAmount: newLoanAmount,
          interestRate,
          termInMonths: termsInMonths,
        },
      });
    }
  }, [
    redrawBalance,
    getMinimumRepayments,
    loanAccountLoading,
    minimumRepaymentCalled,
    loanPrincipalBalance,
    interestRate,
    termsInMonths,
  ]);

  // To set the values of the atom when the minimum repayment data is fetched
  useEffect(() => {
    if (newMinimumMonthlyRepaymentAmount && currentMonthlyRepaymentAmount) {
      setReduceRepaymentsAtom({
        newMinimumRepaymentAmount: newMinimumMonthlyRepaymentAmount,
        previousRepaymentAmount: currentMonthlyRepaymentAmount,
        minimumRepaymentReducedAmount: monthlyRepaymentDifference,
        redrawBalanceAvailable: redrawBalance - amountToBeReduced.current,
        previousRedrawAmount: redrawBalance,
        redrawReduced: amountToBeReduced.current,
      });
    }
  }, [
    newMinimumMonthlyRepaymentAmount,
    currentMonthlyRepaymentAmount,
    monthlyRepaymentDifference,
    redrawBalance,
    loanData,
    minimumRepaymentData,
    amountToBeReduced,
    setReduceRepaymentsAtom,
  ]);

  const updateMinimumMonthlyRepayments = useCallback(
    (amount: number) => {
      if (!loanPrincipalBalance) return;
      amountToBeReduced.current = amount;
      const newLoanAmount = loanPrincipalBalance - amount;
      if (interestRate && termsInMonths) {
        getMinimumRepayments({
          variables: {
            loanAmount: newLoanAmount,
            interestRate,
            termInMonths: termsInMonths,
          },
        });
      }
    },
    [loanPrincipalBalance, interestRate, termsInMonths, getMinimumRepayments],
  );

  const refetchQueries = useCallback(async () => {
    await refetchLoanAccountState();
  }, [refetchLoanAccountState]);

  return {
    currentMonthlyRepaymentAmount,
    error: loanDataError ?? minimumRepaymentError,
    interestRate,
    isMinimumMonthlyRepaymentDataFetched: minimumRepaymentCalled,
    isRepaymentConfirmed,
    loanAccountLoading,
    minimumRepaymentLoading,
    monthlyRepaymentDifference,
    newMinimumMonthlyRepaymentAmount,
    refetchQueries,
    redrawBalance,
    resetIsRepaymentConfirmed,
    termsInMonths,
    updateMinimumMonthlyRepayments: useDebounce(
      updateMinimumMonthlyRepayments,
      DEBOUNCE_WAIT_TIME_IN_MS,
    ),
  };
};
