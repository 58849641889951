export enum ExpensesFormField {
  LivingSituation = 'livingSituation',
  SelectedBorrowers = 'selectedBorrowers',
  DependentsCount = 'dependentsCount',
}

export enum ExpensesTypesField {
  FoodAndGroceries = 'foodAndGroceries',
  FoodAndGroceriesFrequency = 'foodAndGroceriesFrequency',

  HomeAndUtilities = 'homeAndUtilities',
  HomeAndUtilitiesFrequency = 'homeAndUtilitiesFrequency',

  ClothingAndPersonalCare = 'clothingAndPersonalCare',
  ClothingAndPersonalCareFrequency = 'clothingAndPersonalCareFrequency',

  MedicalHealthFitness = 'medicalHealthFitness',
  MedicalHealthFitnessFrequency = 'medicalHealthFitnessFrequency',

  Transport = 'transport',
  TransportFrequency = 'transportFrequency',

  EntertainmentAndPets = 'entertainmentAndPets',
  EntertainmentAndPetsFrequency = 'entertainmentAndPetsFrequency',

  ChildcareAndEducation = 'childcareAndEducation',
  ChildcareAndEducationFrequency = 'childcareAndEducationFrequency',

  RentOrBoard = 'rentOrBoard',
  RentOrBoardFrequency = 'rentOrBoardFrequency',

  ChildAndSpouseSupport = 'childAndSpouseSupport',
  ChildAndSpouseSupportFrequency = 'childAndSpouseSupportFrequency',

  AdditionalExpenses = 'additionalExpenses',
  AdditionalExpensesFrequency = 'additionalExpensesFrequency',
}
