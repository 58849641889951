import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function InstitutionIcon({ color, ...props }: SvgIconProps) {
  return (
    <Svg width={22} height={21} viewBox="0 0 22 21" fill="none" {...props}>
      <Path
        d="M20.281 18.656h-.343V16.25c0-.559-.473-1.031-1.032-1.031h-.687V8.688h-2.063v6.53h-2.062v-6.53H12.03v6.53H9.97v-6.53H7.906v6.53H5.844v-6.53H3.78v6.53h-.687c-.602 0-1.031.473-1.031 1.032v2.406h-.344c-.602 0-1.032.473-1.032 1.032v.687c0 .215.13.344.344.344H20.97a.339.339 0 00.343-.344v-.688c0-.558-.472-1.03-1.03-1.03zm-2.406 0H4.125v-1.375h13.75v1.375zM20.969 4.82L11.644.91C11.474.867 11.173.781 11 .781c-.215 0-.516.086-.688.13L.989 4.82c-.172.042-.3.257-.3.472v1.16c0 .3.214.516.515.516h.86v.515c0 .301.214.516.515.516h16.844a.522.522 0 00.515-.516V6.97h.86a.522.522 0 00.515-.516v-1.16c0-.215-.171-.43-.343-.473zM2.75 5.937L11 2.845l8.25 3.094H2.75z"
        fill={color}
      />
    </Svg>
  );
}
