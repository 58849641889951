import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { ChangeRequestResponse } from '../types';

type Props = {
  loading?: boolean;
  responses: ChangeRequestResponse[];
};

const TextPrefix = 'Content.AccountPermissions.ApprovalScreen';
export const ApprovalResponses = ({ loading = false, responses }: Props) => (
  <DataRowGroup
    testID={TestID.AccountPermissions.ApprovalScreen.ApprovalResponses}
  >
    <DataRow
      loading={loading}
      label={
        <Text variant="xsHeader">
          {t(`${TextPrefix}.ApprovalResponses.Status`)}
        </Text>
      }
      caption={t(`${TextPrefix}.ApprovalResponses.PendingApproval`)}
    />
    {responses.length > 0 ? <DataRowSeparator /> : null}
    {responses.map((response) => (
      <DataRow
        key={response.id}
        loading={loading}
        label={<Text variant="xsHeader">{response.name}</Text>}
        caption={
          response.hasApproved
            ? t(`${TextPrefix}.ApprovalResponses.Approved`)
            : '--'
        }
      />
    ))}
  </DataRowGroup>
);
