import { SxProp, Text, useSx } from 'dripsy';
import * as React from 'react';

import { Box, BoxProps } from '../ui/atoms/Box';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { BannerWrapper } from './BannerWrapper';

type Props = Omit<BoxProps, 'style'> & {
  message?: string | undefined | null;
  sx?: SxProp;
  children?: React.ReactNode;
};

export const ErrorRow: React.FC<Props> = ({
  message,
  sx: sxProp,
  children,
  ...props
}) => {
  const sx = useSx();

  if (!message && !children) {
    return null;
  }
  return (
    <BannerWrapper>
      <Box
        bg="errorBg"
        row
        borderRadius="card"
        p="m"
        alignItems="center"
        style={sxProp ? sx(sxProp) : undefined}
        {...props}
      >
        <StyledIcon name="alert-circle" color="error" mr="s" />
        {message ? (
          <Text variant="caption" sx={{ flex: 1, color: '$labelsPrimary' }}>
            {message}
          </Text>
        ) : null}
        {children}
      </Box>
    </BannerWrapper>
  );
};
