import LottieView from 'lottie-react-native';
import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';

import { Box, BoxProps } from '../../ui/atoms/Box';
import { chromaticIgnoreDataSet } from '../../utils/chromatic';

export type GreenCheckAnimationProps = Pick<
  ComponentProps<typeof LottieView>,
  'onAnimationFinish'
> &
  Pick<BoxProps, 'my'> & {
    testID?: string;
  };

export function GreenCheckAnimation({
  my,
  ...lottieProps
}: GreenCheckAnimationProps) {
  return (
    <Box centered dataSet={chromaticIgnoreDataSet} my={my}>
      <LottieView
        key="check"
        style={styles.lottieView}
        autoPlay
        loop={false}
        source={require('../../../assets/lottie/checkGreen.json')}
        {...lottieProps}
      />
    </Box>
  );
}

const styles = StyleSheet.create({
  lottieView: {
    width: 171,
    height: 171,
  },
});
