import { useNavigateToLoanApplicationScreen } from '../../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../../navigation/types/navTypes';
import { Screen } from '../../../navigation/types/screens';
import { useAppSummaryScreenNavigation } from '../../../utils/hooks/useAppSummaryScreenNavigation';
import { SetupLoanTopUpBase } from '../base/SetupLoanTopUpBase';

export type Props =
  SingleModalStackScreenProps<Screen.SETUP_LOAN_FOR_TOP_UP_MODAL>;

export function SetupLoanTopUp({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;
  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourUnloan = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.YourUnloan,
      }),
    );
  };

  return (
    <SetupLoanTopUpBase
      screen={Screen.SETUP_LOAN_FOR_TOP_UP_MODAL}
      hideBackButton
      onSubmitSuccess={navigateToYourUnloan}
      loanApplicationId={route.params?.loanApplicationId}
      loanApplicationTargetId={route.params?.loanApplicationTargetId}
      onClose={navigateToYourUnloan}
    />
  );
}
