import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCheckboxInputV2,
  FormCurrencyInputV2,
  FormPropertyInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import {
  AppAddressFormat,
  PickerOption,
  PickerOptions,
  yupAddress,
} from '../../components/form/types';
import {
  Frequency_Enum,
  Property_Type_Enum,
  State_Enum,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames,
  ManualPropertyInputFields,
} from '../../ui/v2/ManualPropertyInputFields';
import { SuggestionDataLoader } from '../../ui/v2/PropertyInput';
import { makeTestId } from '../../utils/stringHelpers';
import { yup } from '../../utils/yup';

const manualAddressPrefix = 'rentalIncomeManualAddressInput';
export const manualInputFieldNames =
  getManualInputFieldNames(manualAddressPrefix);

export enum RentalIncomeFormFields {
  PropertyAddress = 'propertyAddress',
  PropertyType = 'propertyType',
  RentalIncome = 'rentalIncome',
  RentalIncomeFrequency = 'rentalIncomeFrequency',
  RentalExpenses = 'rentalExpenses',
  RentalExpensesFrequency = 'rentalExpensesFrequency',
  PropertyOwner = 'propertyOwners',
}

const getRentalIncomeSchema = () =>
  yup
    .object({
      [RentalIncomeFormFields.PropertyAddress]: yup
        .object()
        .when(manualInputFieldNames.isManualInput, {
          is: true,
          then: yup.object().nullable(),
          otherwise: yupAddress().nullable().required(),
        }),
      [RentalIncomeFormFields.PropertyType]: yup
        .string()
        .nullable()
        .required(t('Content.YourIncome.Form.PropertyTypeError')),
      [RentalIncomeFormFields.RentalIncome]: yup
        .number()
        .nullable()
        .required(t('Content.YourIncome.Form.RentalIncomeError')),
      [RentalIncomeFormFields.RentalIncomeFrequency]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
      [RentalIncomeFormFields.RentalExpenses]: yup
        .number()
        .nullable()
        .required(t('Content.YourIncome.Form.RentalExpensesError')),
      [RentalIncomeFormFields.RentalExpensesFrequency]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
      [RentalIncomeFormFields.PropertyOwner]: yup
        .array()
        .of(yup.string().required())
        .nullable()
        .min(1)
        .required(),
    })
    // Append with manual address input fields
    .concat(getManualAddressYupSchema(manualAddressPrefix));

export type RentalIncomeFormDetails = {
  [RentalIncomeFormFields.PropertyAddress]: AppAddressFormat | undefined;
  [RentalIncomeFormFields.PropertyType]: Property_Type_Enum | undefined;
  [RentalIncomeFormFields.RentalIncome]: number | undefined;
  [RentalIncomeFormFields.RentalIncomeFrequency]: Frequency_Enum | undefined;
  [RentalIncomeFormFields.RentalExpenses]: number | undefined;
  [RentalIncomeFormFields.RentalExpensesFrequency]: Frequency_Enum | undefined;
  [RentalIncomeFormFields.PropertyOwner]: Array<string> | undefined;

  // Manual address input values
  [manualInputFieldNames.isManualInput]: boolean | undefined;
  [manualInputFieldNames.postcode]: string | undefined;
  [manualInputFieldNames.state]: State_Enum | undefined;
  [manualInputFieldNames.streetName]: string | undefined;
  [manualInputFieldNames.streetNo]: string | undefined;
  [manualInputFieldNames.streetType]: PickerOption<string> | undefined;
  [manualInputFieldNames.suburb]: string | undefined;
  [manualInputFieldNames.unitNo]: string | undefined;
};

export const DEFAULT_RENTAL_INCOME_INITIAL_VALUES: RentalIncomeFormDetails = {
  propertyAddress: undefined,
  propertyOwners: undefined,
  propertyType: undefined,
  rentalExpenses: undefined,
  rentalExpensesFrequency: Frequency_Enum.Monthly,
  rentalIncome: undefined,
  rentalIncomeFrequency: Frequency_Enum.Monthly,

  // Manual address input values
  [manualInputFieldNames.isManualInput]: undefined,
  [manualInputFieldNames.postcode]: undefined,
  [manualInputFieldNames.state]: undefined,
  [manualInputFieldNames.streetName]: undefined,
  [manualInputFieldNames.streetNo]: undefined,
  [manualInputFieldNames.streetType]: undefined,
  [manualInputFieldNames.suburb]: undefined,
  [manualInputFieldNames.unitNo]: undefined,
};

export enum RentalIncomeFormSubmitLabel {
  Done = 'Done',
  Next = 'Next',
}

export type RentalIncomeFormProps = {
  screen: Screen;
  submitLabel?: RentalIncomeFormSubmitLabel;
  initialValues?: RentalIncomeFormDetails;
  propertyTypeOptions: PickerOptions<string>;
  frequencyOptions: PickerOptions<string>;
  propertyOwnerOptions: PickerOptions<string>;
  streetTypeOptions: PickerOptions<string>;
  stateOptions: PickerOptions<string>;
  isSubmitting?: boolean;
  onSubmit: (values: RentalIncomeFormDetails) => Promise<void>;
  onRentalExpenseLearnMoreClick?: () => void;
  showDeleteButton?: boolean;
  onDelete?: () => Promise<void>;
} & SuggestionDataLoader;

export function RentalIncomeForm({
  screen,
  submitLabel = RentalIncomeFormSubmitLabel.Done,
  initialValues = DEFAULT_RENTAL_INCOME_INITIAL_VALUES,
  propertyTypeOptions = [],
  frequencyOptions = [],
  propertyOwnerOptions = [],
  stateOptions = [],
  streetTypeOptions = [],
  isSubmitting,
  onSubmit,
  onRentalExpenseLearnMoreClick,
  showDeleteButton,
  onDelete,

  // For property address input
  loadSuggestionData,
  isLoadingSuggestionData,
  propertySuggestionData,
}: RentalIncomeFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={() => getRentalIncomeSchema()}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <SHeaderText>
            {t('Content.YourIncome.Form.EnterPropertyDetails')}
          </SHeaderText>
          {formProps.values?.[manualInputFieldNames.isManualInput] ? (
            <>
              <ManualPropertyInputFields
                screen={screen}
                sectionInteractionKey={SectionInteractionKey.Income}
                fieldNamePrefix={manualAddressPrefix}
                containerSx={{ mt: '$16' }}
                stateOptions={stateOptions}
                streetTypeOptions={streetTypeOptions}
              />
              <Text
                variants={['caption', 'link']}
                sx={{ mt: '$8' }}
                onPress={() =>
                  formProps.setFieldValue(
                    manualInputFieldNames.isManualInput,
                    false,
                    false,
                  )
                }
              >
                {t('Content.PropertyForm.FormV2.BackToSelectInput')}
              </Text>
            </>
          ) : (
            <>
              <FormPropertyInputV2
                name={RentalIncomeFormFields.PropertyAddress}
                label={t('Content.YourIncome.Form.PropertyAddress')}
                inputTestID={TestID.RentalIncomeForm.PropertyAddressPicker}
                propertySuggestionData={propertySuggestionData}
                loadSuggestionData={loadSuggestionData}
                isLoadingSuggestionData={isLoadingSuggestionData}
                sx={{ mt: '$16' }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  screen,
                  FieldInteractionKey.PropertyAddress,
                )}
              />
              <FormikFormError
                name={RentalIncomeFormFields.PropertyAddress}
                sx={{ mt: '$8' }}
                errorMessageParser={() =>
                  t('Content.YourIncome.Form.PropertyAddressError')
                }
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                {t('Content.PropertyForm.FormV2.GoToManualInput')}
                <Text
                  variants={['caption', 'link']}
                  onPress={() =>
                    formProps.setFieldValue(
                      manualInputFieldNames.isManualInput,
                      true,
                      false,
                    )
                  }
                >
                  {t('Content.PropertyForm.FormV2.GoToManualInputLink')}
                </Text>
              </Text>
            </>
          )}
          <FormSelectV2
            name={RentalIncomeFormFields.PropertyType}
            label={t('Content.YourIncome.Form.PropertyType')}
            testID={TestID.RentalIncomeForm.PropertyType}
            items={propertyTypeOptions}
            sx={{ mt: '$16' }}
          />
          <FormikFormError
            name={RentalIncomeFormFields.PropertyType}
            sx={{ mt: '$8' }}
          />
          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.YourIncome.Form.EnterRentalIncome')}
          </SHeaderText>
          <RowView sx={{ mt: '$16' }}>
            <FormCurrencyInputV2
              name={RentalIncomeFormFields.RentalIncome}
              sx={{ mr: '$16', flex: 1 }}
              label={t('Content.YourIncome.Form.RentalIncome')}
              inputTestID={TestID.RentalIncomeForm.RentalIncomeValueInput}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.RentalIncome,
              )}
            />
            <FormSelectV2
              name={RentalIncomeFormFields.RentalIncomeFrequency}
              label={t('Content.YourIncome.Form.IncomeFrequency')}
              testID={TestID.RentalIncomeForm.RentalIncomeFrequency}
              items={frequencyOptions}
              sx={{ flex: 1 }}
            />
          </RowView>
          <FormikFormError
            name={RentalIncomeFormFields.RentalIncome}
            sx={{ mt: '$8' }}
          />
          <FormikFormError
            name={RentalIncomeFormFields.RentalIncomeFrequency}
            sx={{ mt: '$8' }}
          />
          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.YourIncome.Form.EnterRentalExpenses')}
          </SHeaderText>
          <RowView sx={{ mt: '$16' }}>
            <FormCurrencyInputV2
              name={RentalIncomeFormFields.RentalExpenses}
              sx={{ mr: '$16', flex: 1 }}
              label={t('Content.YourIncome.Form.RentalExpenses')}
              inputTestID={TestID.RentalIncomeForm.RentalExpensesValueInput}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.RentalExpenses,
              )}
            />
            <FormSelectV2
              name={RentalIncomeFormFields.RentalExpensesFrequency}
              label={t('Content.YourIncome.Form.IncomeFrequency')}
              testID={TestID.RentalIncomeForm.RentalExpensesFrequency}
              items={frequencyOptions}
              sx={{ flex: 1 }}
            />
          </RowView>
          <FormikFormError
            name={RentalIncomeFormFields.RentalExpenses}
            sx={{ mt: '$8' }}
          />
          <FormikFormError
            name={RentalIncomeFormFields.RentalExpensesFrequency}
            sx={{ mt: '$8' }}
          />
          <Text sx={{ mt: '$8' }}>
            <Text
              variants={['caption', 'link']}
              onPress={() => onRentalExpenseLearnMoreClick?.()}
            >
              {t('Content.YourIncome.Form.RentalExpensesHelp')}
            </Text>
          </Text>

          <SHeaderText sx={{ mt: '$32', mb: '$8' }}>
            {t('Content.YourIncome.Form.EnterPropertyOwner')}
          </SHeaderText>
          {propertyOwnerOptions.map(({ value, label, disabled }) => (
            <FormCheckboxInputV2
              key={value}
              name={RentalIncomeFormFields.PropertyOwner}
              testID={makeTestId([
                TestID.RentalIncomeForm.PropertyOwners,
                value,
              ])}
              value={value}
              label={label}
              disabled={disabled}
              containerStyle={{
                mt: '$8',
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.PropertyOwner,
              )}
            />
          ))}
          <FormikFormError
            name={RentalIncomeFormFields.PropertyOwner}
            sx={{ mt: '$8' }}
            errorMessageParser={() =>
              t('Content.YourIncome.Form.PropertyOwnerError')
            }
          />
          <BottomSpacer />
          <Button
            label={
              submitLabel === RentalIncomeFormSubmitLabel.Done
                ? t('Content.Common.ButtonLabel.Done')
                : t('Content.Common.ButtonLabel.Next')
            }
            testID={TestID.RentalIncomeForm.SubmitButton}
            onPress={async () => {
              formProps.handleSubmit();
            }}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            py="m"
            alignSelf="stretch"
          />
          {showDeleteButton ? (
            <Button
              label={t('Content.YourIncome.Form.DeleteIncomeButton')}
              testID={TestID.RentalIncomeForm.DeleteButton}
              onPress={onDelete}
              tertiary
              disabled={isSubmitting}
              fontWeight="normal"
              alignSelf="stretch"
              mt="s"
              style={{
                paddingVertical: 0,
              }}
            />
          ) : null}
        </View>
      )}
    </Formik>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
const BottomSpacer = styled(View)({
  mb: '$32',
});
