import { gql } from '@apollo/client';
import { StackScreenProps } from '@react-navigation/stack';
import { Pressable, useDripsyTheme } from 'dripsy';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { AuthContextValue, useAuthContext } from '../../Auth/context';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  ApplicantStatusInfoFragmentDoc,
  Loan_Application_Type_Enum,
  useGetApplicantForApplicationTrackingQuery,
  useGetLoanContractDownloadUrlQuery,
} from '../../generated/graphql';
import { LoanApplicationScreenContainer } from '../../LoanApplication/components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../../LoanApplication/components/LoanApplicationWizardFooter';
import { AppStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { captureException, captureMessage } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { Spinner } from '../../ui/atoms/Spinner';
import {
  Props as StyledTextProps,
  StyledText,
} from '../../ui/atoms/StyledText';
import VerticalStepper, { StepState } from '../../ui/molecules/VerticalStepper';
import { CloseIcon } from '../../ui/svgs/CloseIcon';
import { useTheme } from '../../ui/theme';
import { getGlobal } from '../../utils/getGlobal';
import { normalizeError } from '../../utils/normalizeError';
import { YourApplicationHeader } from '../components/YourApplicationHeader';
import {
  ApplicantState,
  ApplicationTrackingStage,
  useApplicantStates,
} from '../utils/useApplicantStates';
import { useEnableNativeAppMonitor } from '../utils/useEnableNativeAppMonitor';

type Props = StackScreenProps<AppStackParams, Screen.APPLICATION_TRACKING>;

export const GetApplicantForApplicationTracking = gql`
  query GetApplicantForApplicationTracking($loanApplicationId: uuid!) {
    me {
      user {
        appliedLoans: applicants(
          where: { loan_application_id: { _eq: $loanApplicationId } }
          limit: 1
        ) {
          ...ApplicantStatusInfo
        }
      }
    }
  }
  ${ApplicantStatusInfoFragmentDoc}
`;

// TODO: i18n - use object with getter
const getV1States = (applicationType?: Loan_Application_Type_Enum) => [
  // TODO: caption depends on applicant status
  {
    stage: ApplicationTrackingStage.Apply,
    name: t('Content.ApplicationTracking.Apply'),
    caption: t('Content.ApplicationTracking.ApplyCaption'),
  },
  {
    stage: ApplicationTrackingStage.Decision,
    name: t('Content.ApplicationTracking.Decision'),
    caption: t('Content.ApplicationTracking.DecisionCaption'),
    currentCaption: t('Content.ApplicationTracking.DecisionCurrentCaption'),
  },
  {
    stage: ApplicationTrackingStage.Accept,
    name: t('Content.ApplicationTracking.Accept'),
    caption: t('Content.ApplicationTracking.AcceptCaption'),
    completedCaption: t('Content.ApplicationTracking.AcceptedCaption'),
    captionLinkText: t('Content.ApplicationTracking.AcceptedCaptionLink'),
  },
  {
    stage: ApplicationTrackingStage.Settlement,
    name:
      applicationType === Loan_Application_Type_Enum.TopUp
        ? t('Content.ApplicationTracking.Disbursement')
        : t('Content.ApplicationTracking.Settlement'),
    caption:
      applicationType === Loan_Application_Type_Enum.TopUp
        ? t('Content.ApplicationTracking.DisbursementCaption')
        : t('Content.ApplicationTracking.SettlementCaption'),
  },
];

const getV2States = (applicationType?: Loan_Application_Type_Enum) => [
  // TODO: caption depends on applicant status
  {
    stage: ApplicationTrackingStage.Apply,
    name: t('Content.ApplicationTracker.Apply'),
    caption: t('Content.ApplicationTracking.ApplyCaption'),
  },
  {
    stage: ApplicationTrackingStage.Decision,
    name: t('Content.ApplicationTracker.Approve'),
    caption: t('Content.ApplicationTracking.DecisionCaption'),
    currentCaption: t('Content.ApplicationTracking.DecisionCurrentCaption'),
  },
  {
    stage: ApplicationTrackingStage.Accept,
    name: t('Content.ApplicationTracker.Sign'),
    caption: t('Content.ApplicationTracking.AcceptCaption'),
    completedCaption: t('Content.ApplicationTracking.AcceptedCaption'),
    captionLinkText: t('Content.ApplicationTracking.AcceptedCaptionLink'),
  },
  {
    stage: ApplicationTrackingStage.Settlement,
    name:
      applicationType === Loan_Application_Type_Enum.TopUp
        ? t('Content.ApplicationTracker.Disbursement')
        : t('Content.ApplicationTracker.Settlement'),
    caption:
      applicationType === Loan_Application_Type_Enum.TopUp
        ? t('Content.ApplicationTracking.DisbursementCaption')
        : t('Content.ApplicationTracking.SettlementCaption'),
  },
];

const applicantStateAllowedForLoanContractDownload = new Set([
  ApplicantState.PendingPortalApproved,
  ApplicantState.PaperContract,
  ApplicantState.Signed,
  ApplicantState.DocumentsCompleted,
  ApplicantState.Booked,
]);

function useTrackerRowHeight() {
  const theme = useTheme();
  // This height is determined by the content of the tracker row
  const height =
    theme.textVariants.default.lineHeight +
    theme.textVariants.caption.lineHeight +
    theme.spacing.l;
  return height;
}

type TrackerRowProps = {
  name: string;
  caption: string;
  currentCaption?: string;
  completedCaption?: string;
  captionLinkText?: string;
  captionLink?: string;
  state: StepState;
  loanApplicationId: string;
  captionLinkOnPress?: () => Promise<void>;
  captionSpinnerSize?: number;
  fileDownloadUrl?: string;
};

function DownloadDocumentCaptionLink({
  captionLinkText,
  captionSpinnerSize,
  captionLinkOnPress,
  fileDownloadUrl,
  loanApplicationId,
}: Pick<
  TrackerRowProps,
  | 'captionSpinnerSize'
  | 'captionLinkText'
  | 'fileDownloadUrl'
  | 'loanApplicationId'
> & {
  captionLinkOnPress: () => Promise<string | void>;
}) {
  const [isRunningCaptionLinkOnPress, setIsRunningCaptionLinkOnPress] =
    useState(false);

  useEffect(() => {
    if (!fileDownloadUrl) {
      return;
    }

    setIsRunningCaptionLinkOnPress(true);

    Linking.openURL(fileDownloadUrl)
      .catch((error) => {
        captureException(
          'Exception when opening loan contract object URL',
          {
            contractObjectUrl: fileDownloadUrl,
            loanApplicationId,
          },
          error,
        );
      })
      .finally(() => setIsRunningCaptionLinkOnPress(false));
  }, [fileDownloadUrl, loanApplicationId]);

  if (fileDownloadUrl) {
    return (
      <>
        {'.\n'}
        {t(
          'Content.ApplicationTracking.AcceptedCaptionLinkFallback.Caption',
        )}{' '}
        <StyledText
          variant="caption"
          color="link"
          role="link"
          onPress={async () => {
            setIsRunningCaptionLinkOnPress(true);
            try {
              await Linking.openURL(fileDownloadUrl);
            } catch (error) {
              captureException(
                'Exception when opening loan contract object URL',
                {
                  contractObjectUrl: fileDownloadUrl,
                  loanApplicationId,
                },
                error,
              );
            }
            setIsRunningCaptionLinkOnPress(false);
          }}
        >
          {t('Content.ApplicationTracking.AcceptedCaptionLinkFallback.Link')}{' '}
          {isRunningCaptionLinkOnPress ? (
            <Spinner size={captionSpinnerSize || 'small'} />
          ) : null}
        </StyledText>
      </>
    );
  }

  return (
    <>
      {'.\n'}
      <StyledText
        variant="caption"
        color="link"
        role="link"
        testID={TestID.ApplicationTracking.DownloadDocumentsLink}
        onPress={async () => {
          if (isRunningCaptionLinkOnPress) {
            return;
          }
          setIsRunningCaptionLinkOnPress(true);
          await captionLinkOnPress();
        }}
      >
        {captionLinkText}{' '}
        {isRunningCaptionLinkOnPress ? (
          <Spinner size={captionSpinnerSize || 'small'} />
        ) : null}
      </StyledText>
    </>
  );
}

function CaptionText({
  caption,
  currentCaption,
  completedCaption,
  captionLinkText,
  color,
  loanApplicationId,
  state,
  captionLinkOnPress,
  captionSpinnerSize,
  fileDownloadUrl,
}: Omit<TrackerRowProps, 'name'> & {
  color: StyledTextProps['color'];
}) {
  let captionToDisplay = caption;
  if (state === 'complete') {
    captionToDisplay = completedCaption ?? '';
  }

  if (state === 'current' && currentCaption) {
    captionToDisplay = currentCaption;
  }

  return (
    <StyledText variant="caption" color={color}>
      {captionToDisplay}
      {captionLinkOnPress ? (
        <DownloadDocumentCaptionLink
          captionLinkText={captionLinkText}
          captionSpinnerSize={captionSpinnerSize}
          captionLinkOnPress={captionLinkOnPress}
          loanApplicationId={loanApplicationId}
          fileDownloadUrl={fileDownloadUrl}
        />
      ) : null}
    </StyledText>
  );
}

function TrackerRow(props: TrackerRowProps) {
  const { name, state } = props;
  const rowHeight = useTrackerRowHeight();
  const color: StyledTextProps['color'] =
    state === 'incomplete' ? 'disabledContent' : undefined;
  return (
    <Box px="m" height={rowHeight} justifyContent="center" flexShrink={1}>
      <StyledText
        fontWeight={state === 'current' ? 'semiBold' : 'medium'}
        color={color}
      >
        {name}
      </StyledText>
      <CaptionText {...props} color={color} />
    </Box>
  );
}

async function prepareFileDownloadUrl(
  auth: AuthContextValue | null,
  {
    downloadUrl,
    loanApplicationId,
  }: { downloadUrl: string; loanApplicationId: string },
) {
  if (Platform.OS !== 'web') {
    captureMessage('Unsupported platform for loan contract download', {
      platform: Platform.OS,
      downloadUrl,
      loanApplicationId,
    });
    return;
  }

  let accessToken: string;

  try {
    const token = await auth?.getAccessToken();
    if (token == null) {
      throw new Error('No access token');
    }
    accessToken = token;
  } catch (error) {
    captureException(
      'Exception when getting access token for loan contract download',
      {
        downloadUrl,
        loanApplicationId,
      },
      error,
    );
    return;
  }

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  let res: Awaited<ReturnType<typeof fetch>>;

  try {
    res = await fetch(downloadUrl, {
      method: 'GET',
      headers,
    });
  } catch (error) {
    captureException(
      'Exception when fetching loan contract',
      {
        downloadUrl,
        loanApplicationId,
      },
      error,
    );
    return;
  }

  if (!res.ok) {
    let bodyAsText: string;
    let error: Error | undefined;
    try {
      bodyAsText = await res.text();
    } catch (e) {
      bodyAsText = 'Unable to read body as text';
      error = normalizeError(e);
    }
    captureMessage(
      'Unexpected not ok response from download loan contract URL',
      {
        downloadUrl,
        status: res.status,
        statusText: res.statusText,
        bodyAsText,
        bodyAsTextError: error?.message,
        loanApplicationId,
      },
    );
    return;
  }

  let bodyAsBlob;

  try {
    bodyAsBlob = await res.blob();
  } catch (error) {
    captureException(
      'Exception when reading download loan contract response as blob',
      {
        downloadUrl,
        loanApplicationId,
      },
      error,
    );
    return;
  }

  let contractObjectUrl: string;
  try {
    const url = getGlobal()?.URL.createObjectURL(bodyAsBlob);
    if (url == null) {
      throw new Error('No object URL');
    }
    contractObjectUrl = url;
  } catch (error) {
    captureException(
      'Exception when creating loan contract object URL',
      {
        downloadUrl,
        isGlobalAvailable: !!getGlobal(),
        loanApplicationId,
      },
      error,
    );
    return;
  }

  const canOpen = await Linking.canOpenURL(contractObjectUrl);

  if (!canOpen) {
    captureMessage('Unsupported object URL', {
      downloadUrl,
      objectUrl: contractObjectUrl,
      loanApplicationId,
    });
    return;
  }

  return contractObjectUrl; // eslint-disable-line consistent-return
}

export function ApplicationTrackingBase({ navigation, route }: Props) {
  const auth = useAuthContext();
  const { flags } = useContext(FeatureFlagsContext);

  const loanApplicationId = route.params?.loanApplicationId || '';
  const { data, loading } = useGetApplicantForApplicationTrackingQuery({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const states = useMemo(
    () =>
      flags.DASHBOARD_VERSION === 'V2'
        ? getV2States(
            data?.me?.[0]?.user?.appliedLoans[0]?.loanApplication?.type,
          )
        : getV1States(
            data?.me?.[0]?.user?.appliedLoans[0]?.loanApplication?.type,
          ),
    [data?.me, flags.DASHBOARD_VERSION],
  );

  const redirectedFromNativeApp = route.params?.redirectedFromNativeApp;
  useEnableNativeAppMonitor(redirectedFromNativeApp);

  const { data: urlData, loading: urlLoading } =
    useGetLoanContractDownloadUrlQuery({
      variables: { loan_application_id: loanApplicationId },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

  const downloadUrl = urlData?.get_loan_contract_download_url?.url;

  const myUser = data?.me[0]?.user;
  const appliedLoan = myUser?.appliedLoans[0];

  const theme = useTheme();
  const { theme: themeV2 } = useDripsyTheme();
  const rowHeight = useTrackerRowHeight();
  const insets = useSafeAreaInsets();

  const {
    action,
    applicantState,
    applicationTrackingStage,
    applicantStateLoading,
    caption,
    shortAddressFormat,
  } = useApplicantStates(navigation, route, appliedLoan);

  const contentLoading = loading || applicantStateLoading || urlLoading;

  const activeStepIndex = useMemo(() => {
    const foundIndex = states.findIndex(
      (step) => step.stage === applicationTrackingStage,
    );

    if (foundIndex === -1 && !contentLoading) {
      captureException(
        'ApplicationTracking tried to render unexpected applicationTrackingStage',
        {
          loanApplicationId,
          applicationTrackingStage,
          applicantState,
        },
      );
      return 0;
    }

    return foundIndex;
  }, [
    states,
    contentLoading,
    applicationTrackingStage,
    loanApplicationId,
    applicantState,
  ]);

  const [fileDownloadUrl, setFileDownloadUrl] = useState<string>();

  const downloadDocumentFunc = useCallback(async () => {
    if (!downloadUrl) {
      return;
    }
    prepareFileDownloadUrl(auth, {
      downloadUrl,
      loanApplicationId,
    }).then(setFileDownloadUrl);
  }, [auth, downloadUrl, loanApplicationId]);

  // Allow the download url to be shown from "Signed" up until "Booked".
  const canDownloadDocument =
    !!applicantState &&
    applicantStateAllowedForLoanContractDownload.has(applicantState) &&
    !!downloadUrl;

  const content = contentLoading ? (
    <Box centered flex={1}>
      <Spinner size="large" />
    </Box>
  ) : (
    <Box px="m">
      <VerticalStepper
        data={states}
        rowHeight={rowHeight}
        renderItem={(item, _, state) => (
          <TrackerRow
            {...item}
            currentCaption={caption || item.currentCaption}
            state={state}
            loanApplicationId={loanApplicationId}
            captionSpinnerSize={theme.textVariants.caption.fontSize}
            fileDownloadUrl={fileDownloadUrl}
            captionLinkOnPress={
              item.stage === ApplicationTrackingStage.Accept &&
              canDownloadDocument
                ? downloadDocumentFunc
                : undefined
            }
          />
        )}
        activeStep={activeStepIndex}
      />
    </Box>
  );

  const onCloseButtonPress = () =>
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: { screen: Screen.HOME },
    });

  return (
    <LoanApplicationScreenContainer scrollable={false} mt="xl">
      <ScrollContainer>
        <YourApplicationHeader
          caption={shortAddressFormat || ''}
          croppedImage={false}
        />
        {content}
        <Pressable
          onPress={onCloseButtonPress}
          testID={TestID.ApplicationTracking.CloseButton}
          sx={{
            position: 'absolute',
            top: insets.top + themeV2.space.$16,
            right: '$16',
            borderRadius: themeV2.iconSizes.$close,
          }}
        >
          <CloseIcon size={themeV2.iconSizes.$close} />
        </Pressable>
        <LoanApplicationWizardFooter
          primaryButtonLabel={action?.actionLabelLong}
          onPrimaryButtonPress={action?.onPress}
          disablePrimaryButton={loading}
          primaryButtonTestID={TestID.ApplicationTracking.ApplicantActionButton}
        />
      </ScrollContainer>
    </LoanApplicationScreenContainer>
  );
}

export const ApplicationTracking = withAuthenticationRequired(
  ApplicationTrackingBase,
);
