import { Pressable, SxProp, useDripsyTheme } from 'dripsy';

import { ChevronRightIcon } from '../../svgs/ChevronRightIcon';

type ChevronDownIconProps = {
  disabled?: boolean;
  isOpen?: boolean;
  onPress?: () => void;
  sx?: SxProp;
};

export function ChevronDownIcon({
  disabled,
  isOpen,
  onPress,
  sx,
}: ChevronDownIconProps) {
  const { theme } = useDripsyTheme();

  return (
    <Pressable
      onPress={onPress}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '$24',
        px: '$16',
        ...sx,
      }}
    >
      <ChevronRightIcon
        size={12}
        style={{
          transform: [
            { rotate: isOpen ? '-90deg' : '90deg' },
            { translateY: 2 },
          ],
        }}
        color={disabled ? theme.colors.$secondaryDisabled : undefined}
      />
    </Pressable>
  );
}
