import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const HouseIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none" {...props}>
    <Path
      d="M4.99988 16.6667L19.9999 5L34.9999 16.6667M31.6665 14.0741V35M31.6665 35L8.33321 35M31.6665 35L34.9999 35M8.33321 35V14.0741M8.33321 35L4.99988 35M23.3332 35V23.3333H16.6665V35"
      stroke={color}
      strokeOpacity="0.95"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
