import { SxProp } from 'dripsy';
import React from 'react';

import { WarningRow } from '../../components/WarningRow';

type SecurityUpdateWarningRowProps = {
  sx?: SxProp;
};

export function SecurityUpdateWarningRow({
  sx,
}: SecurityUpdateWarningRowProps) {
  return (
    <WarningRow
      sx={sx}
      message={t('Content.PropertyDetails.SecurityUpdateWarning')}
    />
  );
}
