import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { Text, useSx } from 'dripsy';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { ComponentProps } from 'react';
import * as React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { Form } from '../../components/form/FormikInputs';
import { Button } from '../../ui/atoms/Button';
import { useTheme } from '../../ui/theme';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

type SubmitButtonProps = Pick<
  ComponentProps<typeof Button>,
  'onPress' | 'disabled' | 'label' | 'showSpinner'
>;

const SubmitButton = (props: SubmitButtonProps) => {
  const { bottom: bottomInset } = useSafeAreaInsets();
  const theme = useTheme();

  const sx = useSx();

  return (
    <Button
      {...props}
      testID={TestID.PersonalDetails.ChangePersonalDetailsContinueButton}
      alignSelf="stretch"
      style={sx({
        marginBottom: bottomInset + theme.spacing.l,
        // This maxWidth: undefined is required to unset the maxWidth applied to the Button
        maxWidth: undefined,
        mt: '$32',
      })}
    />
  );
};

type Props<T> = Pick<
  FormikConfig<T>,
  'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  children?: (formProps: FormikProps<T>) => React.ReactNode;
  showSpinner?: boolean;
  submitLabel: string;
  description?: React.ReactNode;
  title?: string;
  errorMessage?: string;
  navigation: NavigationProp<ParamListBase>;
};

export function PersonalDetailsModalForm<T extends FormikValues>({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  showSpinner = false,
  submitLabel,
  description,
  title,
  errorMessage,
  navigation,
}: Props<T>) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formProps) => (
        <ModalScreenContainer
          headerText={title}
          onClose={navigation.goBack}
          scrollable
          hideBackButton
        >
          <ErrorRow
            message={errorMessage}
            testID={TestID.PersonalDetails.ErrorMessage}
            sx={{ mb: '$16' }}
          />
          {description ? <Text sx={{ mb: '$16' }}>{description}</Text> : null}
          <Form overflow="visible">
            {children?.(formProps)}

            <SubmitButton
              disabled={showSpinner || !!errorMessage}
              label={submitLabel}
              showSpinner={showSpinner}
              onPress={() => formProps.handleSubmit()}
            />
          </Form>
        </ModalScreenContainer>
      )}
    </Formik>
  );
}
