import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { useAuthContext } from '../../Auth/context';
import { ErrorFallback } from '../../components/ErrorFallback';
import { Screen } from '../types/screens';

export function NotFoundScreen() {
  const navigation = useNavigation();
  const authContext = useAuthContext();
  const navigateToHomepage = useCallback(() => {
    if (authContext?.isAuthenticated) {
      navigation.navigate(Screen.MAIN_NAVIGATOR, {
        screen: Screen.HOME_DASHBOARD,
        params: {
          screen: Screen.HOME,
        },
      });
    } else {
      navigation.navigate(Screen.AUTH_FLOW);
    }
  }, [navigation, authContext?.isAuthenticated]);
  return (
    <ErrorFallback
      title={t('Content.NotFoundScreen.Title')}
      caption={t('Content.NotFoundScreen.Caption')}
      captionLink={t('Content.NotFoundScreen.CaptionLink')}
      onCaptionLinkPress={navigateToHomepage}
    />
  );
}
