import { Text, useSx } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { Link } from '../../../ui/atoms/Link';

export const SuggestAdditionalPaymentsCaption = () => {
  const sx = useSx();
  return (
    <Text
      variant="sBody"
      testID={TestID.Insights.TermRemaining.SuggestAdditionalPayment}
    >
      {t('Content.HomeLoan.Insights.TermRemaining.SuggestAdditionalPayments')}
      <Link
        style={sx({ variant: 'text.sBody', color: '$link' })}
        href={t('Link.LearnMoreOnRedrawFeature')}
      >
        {t('Content.HomeLoan.Insights.TermRemaining.LearnMore')}
      </Link>
    </Text>
  );
};
