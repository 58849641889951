import { useMemo } from 'react';

import {
  Loan_Application_Type_Enum,
  Property_Purpose_Enum,
} from '../../generated/graphql';
import { useYourFinancialsScreenQuery } from '../../LoanApplication/graphql/queries';

export function useCheckExpectedRentalIncome(loanApplicationId: string) {
  const { propertyPurpose, loanApplicationType, loading } =
    useYourFinancialsScreenQuery(loanApplicationId);

  const shouldIncludeAddExpectedRentalIncome = useMemo(
    () =>
      loanApplicationType === Loan_Application_Type_Enum.Purchase &&
      propertyPurpose === Property_Purpose_Enum.Investing,
    [propertyPurpose, loanApplicationType],
  );

  return { shouldIncludeAddExpectedRentalIncome, loading };
}
