import { File_Type } from '../../generated/graphql';

// TODO: Move this out of DocumentCentre directory
export const MimeTypeDictionary: Record<
  string,
  {
    fileType: File_Type;
    extensions: Array<string>;
  }
> = {
  // For now disallow uploading office docs
  // 'application/msword': {
  //   fileType: File_Type.ApplicationMsword,
  //   extensions: ['doc'],
  // },
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
  //   fileType:
  //     File_Type.ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument,
  //   extensions: ['docx'],
  // },
  // 'application/vnd.ms-excel': {
  //   fileType: File_Type.ApplicationVndMsExcel,
  //   extensions: ['xls'],
  // },
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
  //   fileType:
  //     File_Type.ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet,
  //   extensions: ['xlsx'],
  // },
  // Allow only read only docs like PDF and images
  'application/pdf': {
    fileType: File_Type.ApplicationPdf,
    extensions: ['pdf'],
  },
  'image/jpeg': {
    fileType: File_Type.ImageJpeg,
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#jpeg
    extensions: ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'],
  },
  'image/png': {
    fileType: File_Type.ImagePng,
    extensions: ['png'],
  },
};

export const allowedMimeTypes = Object.keys(MimeTypeDictionary);
export const allowedExtensions = Object.values(MimeTypeDictionary).reduce(
  (prevValue, { extensions }) => [...prevValue, ...extensions],
  [] as Array<string>,
);
