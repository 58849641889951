import { MergedLiabilityForSetupLoanScreenFragment } from '../../generated/graphql';

export function getSumOfMergedLiabilities(
  mergedLiabilities: MergedLiabilityForSetupLoanScreenFragment[],
) {
  return mergedLiabilities.reduce(
    (acc, liability) =>
      acc + (liability.dynamite_balance ?? liability.dynamite_limit ?? 0),
    0,
  );
}

export function getSumOfSelectedRefinancedLiabilities({
  mergedLiabilities,
  selectedCurrentLiabilityForRefinanceIds,
}: {
  mergedLiabilities: Array<MergedLiabilityForSetupLoanScreenFragment>;
  selectedCurrentLiabilityForRefinanceIds: Set<string>;
}): number {
  return getSumOfMergedLiabilities(
    mergedLiabilities.filter(
      (liability) =>
        liability.current_liability_id &&
        selectedCurrentLiabilityForRefinanceIds.has(
          liability.current_liability_id,
        ),
    ),
  );
}
