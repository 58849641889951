import { BrowserTracing } from '@sentry/tracing';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import { DEFAULT_ENV, ENVIRONMENT } from './config';
import { getGlobal } from './utils/getGlobal';
import { getLogger } from './utils/loggerHelper';
import { normalizeError } from './utils/normalizeError';
import { isTest } from './utils/platformUtils';
import { normalizePathForTracing } from './utils/sentryHelpers';
import { assertUnreachable } from './utils/typesHelpers';

const isWeb = () => Platform.OS === 'web';

const logger = getLogger('APP');

export type SentryContextForApollo = {
  loanApplicationId?: string | null;
  cbaAccountId?: string;
  description?: string;
} & Record<string, unknown>;

const SENTRY_DSN =
  'https://2c67fe12eff84153b8a5effc3ab06e62@o464708.ingest.sentry.io/5474860';
// Currently setting sentry's context depth to 5
const MAX_SENTRY_CONTEXT_DEPTH = 5;

type SentryEnv =
  | 'production'
  | 'staging'
  | 'test'
  | 'development'
  | 'local-dev';

export function mapUnloanEnvToSentryEnv(env: ENVIRONMENT): SentryEnv {
  switch (env) {
    case ENVIRONMENT.PROD_V3:
      return 'production';
    case ENVIRONMENT.STAGING_V3:
      return 'staging';
    case ENVIRONMENT.TEST_V3:
      return 'test';
    case ENVIRONMENT.DEV_V3:
      return 'development';
    case ENVIRONMENT.LOCAL_DEV:
      return 'local-dev';
    default:
      assertUnreachable(env);
      return 'local-dev';
  }
}

const SampleRateConfig: Record<SentryEnv, number> = {
  production: 0.01,
  staging: 0.5,
  test: 0.5,
  development: 0.5,
  'local-dev': 1,
};

const showConsoleLog = __DEV__ && !isTest;

/**
 * We use Sentry for capturing exceptions and monitoring app performance.
 * To monitor the app performance, we use 2 product from Sentry:
 * - Performance - https://docs.sentry.io/product/performance/
 * - React Profiler (also referred to as "React component tracking" by Sentry) - https://docs.sentry.io/platforms/javascript/guides/react/features/component-tracking/
 * React profiler is a specific feature from the Sentry JS SDK, not to be confused with "Profiling" product from Sentry.
 *
 * We do not use Profiling product right now, as it is in beta, and only support profiling native codes, no JS support.
 * See https://docs.sentry.io/product/profiling/ for details
 *
 * ---
 *
 * To tell Sentry how to measure the app performs, we need to provide
 * "instruments".
 *
 * Performance Instruments:
 * - Web - https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
 * For web, we use Sentry.BrowserTracing from Sentry that capture
 * page load, browser navigations, and network requests.
 * - Native - https://docs.sentry.io/platforms/react-native/performance/instrumentation/automatic-instrumentation/
 * For native, we use Sentry.wrap() and Sentry.ReactNavigationInstrumentation.
 *
 *
 * React Profiler:
 * - Web
 * We're using Sentry.withProfiler (@sentry/react) from Sentry, implemented in `withSentryProfiler.web.tsx`.
 *
 * - Native
 * We're using Sentry.withProfiler (@sentry/react-native) from Sentry, implemented in `withSentryProfiler.tsx`.
 */

function getWebIntegrations() {
  const webPerfInstrument = new BrowserTracing({
    idleTimeout: 5000,
    shouldCreateSpanForRequest,
    beforeNavigate: (context) => ({
      ...context,
      name: normalizePathForTracing(
        getGlobal()?.location.pathname ?? 'no-pathname',
      ),
    }),
  });
  return [webPerfInstrument];
}

export const routingInstrumentation = isWeb()
  ? undefined
  : new Sentry.Native.ReactNavigationInstrumentation();

function getNativeIntegrations() {
  const nativePerfInstrument = new Sentry.Native.ReactNativeTracing({
    idleTimeout: 5000,
    shouldCreateSpanForRequest,
    routingInstrumentation,
  });

  return [nativePerfInstrument];
}

export const shouldCreateSpanForRequest = (url: string) => {
  // GraphQL calls have custom instrumentation via Apollo middleware, so
  // automatic tracing should be turned off to avoid a duplicate span
  if (url.includes('graphql')) return false;

  // Clarity is used to periodically capture screenshots for troubleshooting.
  // Capturing those in Sentry is problematic because it artificially extends
  // the transaction duration
  if (url.includes('clarity.ms')) return false;

  return true;
};

function getSentryIntegrations() {
  return isWeb() ? getWebIntegrations() : getNativeIntegrations();
}

export function getSentryConfig(
  unloanEnv: ENVIRONMENT = DEFAULT_ENV,
  // TODO: Properly split the options for web and native
): Sentry.SentryExpoNativeOptions | Sentry.SentryExpoWebOptions {
  const sentryEnv = mapUnloanEnvToSentryEnv(unloanEnv);
  if (__DEV__ || sentryEnv === 'local-dev') {
    return {
      enabled: false,
      environment: sentryEnv,
    };
  }

  const integrations = getSentryIntegrations();

  return {
    dsn: SENTRY_DSN,
    enableInExpoDevelopment: false, // Enables sending errors to sentry when doing development
    debug: false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
    enabled: true,
    environment: sentryEnv,
    integrations,
    denyUrls: [/chromatic\.com/],
    // The value for normalizeDepth needs to be Depth + 1  https://github.com/getsentry/sentry-javascript/issues/2539#issuecomment-616638746
    normalizeDepth: MAX_SENTRY_CONTEXT_DEPTH + 1,
    // Sample rate of 1 = 100% https://docs.sentry.io/platforms/react-native/performance/
    tracesSampleRate: SampleRateConfig[sentryEnv],
  };
}

Sentry.init(getSentryConfig());

const SentryInstance = () => (isWeb() ? Sentry.Browser : Sentry.Native);

export function addBreadcrumb(message: string, data?: Record<string, unknown>) {
  if (showConsoleLog) {
    // eslint-disable-next-line no-console
    console.log(message, data, '[as Sentry Breadcrumb]');
  }
  SentryInstance().addBreadcrumb({ message, data });
}

export function captureMessage(
  message: string,
  context?: {
    loanApplicationId?: string;
  } & {
    [key: string]: unknown;
  },
) {
  if (showConsoleLog) {
    // eslint-disable-next-line no-console
    console.log(message);
  }

  SentryInstance().withScope((scope) => {
    const { loanApplicationId } = context || {};
    if (loanApplicationId) {
      // Sentry index the event tag,
      // setting the loanApplicationId to a tag will make it easier to search for it.
      scope.setTag('loanApplicationId', trimTagForSentry(loanApplicationId));
    }
    if (context) {
      scope.setExtra('context', context);
    }

    SentryInstance().captureMessage(message);
  });
}

function trimTagForSentry(tagValue: string) {
  // Sentry tag value is limited to 200 chars
  return tagValue.slice(0, 200);
}

export function captureException(
  description: string,
  // Q: Why context is required?
  // A: It's to force the dev to provide more context around the exception
  context:
    | (ObjectOf<unknown> &
        SentryContextForApollo & {
          mutationDescription?: string;
          operationName?: string;
          hasuraRequestId?: string;
        })
    | null,
  error?: unknown,
) {
  if (showConsoleLog) {
    // eslint-disable-next-line no-console
    console.warn(
      'An exception occurred.',
      {
        description,
        ...context,
        errorMessage: normalizeError(error).message,
      },
      error,
    );
  }

  SentryInstance().withScope((scope) => {
    const { loanApplicationId, cbaAccountId, operationName, hasuraRequestId } =
      context || {};
    if (loanApplicationId) {
      // Sentry index the event tag,
      // setting the loanApplicationId to a tag will make it easier to search for it.
      scope.setTag('loanApplicationId', trimTagForSentry(loanApplicationId));
    }
    if (cbaAccountId) {
      // Sentry index the event tag,
      // setting the cbaAccountId to a tag will make it easier to search for it.
      scope.setTag('cbaAccountId', trimTagForSentry(cbaAccountId));
    }

    if (operationName) {
      scope.setTag('operationName', trimTagForSentry(operationName));
    }

    if (hasuraRequestId) {
      scope.setTag('hasuraRequestId', trimTagForSentry(hasuraRequestId));
    }

    if (context) {
      scope.setExtra('context', context);
    }

    if (error) {
      scope.setExtra('description', description);
      SentryInstance().captureException(error);
    } else {
      SentryInstance().captureMessage(description, 'error');
    }
  });
}

export function setSentryUserId(id: string | undefined | null) {
  logger?.log(`Sentry User Id: ${id}`);
  const sentryUser = id ? { id } : null;

  SentryInstance().setUser(sentryUser);
}

export function setSentryCustomerSessionId(
  customerSessionId: string | undefined | null,
) {
  logger?.log(`Sentry Customer Session Id: ${customerSessionId}`);
  SentryInstance().setTag('customerSessionId', customerSessionId);
}
