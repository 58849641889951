import { useState } from 'react';

import { Box } from '../atoms/Box';
import { Slider } from '../atoms/Slider';
import { SliderProps } from '../atoms/types';

export type FlexSliderProps = SliderProps;

export function FlexSlider(props: FlexSliderProps) {
  const [sliderLength, setSliderLength] = useState<number | null>(null);

  if (sliderLength == null) {
    return (
      <Box
        flex={1}
        maxHeight={1}
        onLayout={(e) => {
          const { width } = e.nativeEvent.layout;
          setSliderLength(width);
        }}
      />
    );
  }

  return <Slider {...props} sliderLength={sliderLength} />;
}
