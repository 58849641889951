import { ComponentProps, useContext } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useLoanApplicationXaiIdSubscription } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { useHideBackButton } from '../../utils/hooks/useHeaderButton';
import { GreenCheckAnimation } from '../components/GreenCheckAnimation';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import SubmittingLoanIndicator from '../components/SubmittingLoanIndicator';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';
import { useLoanApplication } from '../utils/useLoanApplication';

type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT>;

export function InterstitialLoanHeader({
  canContinue,
}: {
  canContinue: boolean;
}) {
  return (
    <LoanScreenHeader
      title={t('Content.InterstitialLoanSubmitted.ScreenHeader')}
      caption={t('Content.InterstitialLoanSubmitted.ScreenCaption')}
      style={{
        opacity: canContinue ? 1 : 0,
      }}
    />
  );
}

export function InterstitialLoanSubmittedBaseV2({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId || '';

  const { data, loading: loadingXaiArn } = useLoanApplicationXaiIdSubscription({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
  });

  const { flags } = useContext(FeatureFlagsContext);
  const enableOpenBankingDataRecipient =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT;

  const { loading: loadingLoanApplication, loanApplicationType } =
    useLoanApplication(loanApplicationId);

  const isXaiArnPopulated = data?.loan_application_by_pk?.x_app_ref_id != null;

  const loadingRequiredData = loadingXaiArn || loadingLoanApplication;
  const canContinue = isXaiArnPopulated && !loadingRequiredData;

  useHideBackButton(navigation);

  let modalFooterProps: ComponentProps<typeof LoanApplicationWizardFooter> = {};

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToOpenBanking = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.VerifyIncomeOpenBanking,
    });
  };

  const navigateToAppSubmit = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Submit,
    });
  };

  if (canContinue) {
    modalFooterProps = enableOpenBankingDataRecipient
      ? {
          primaryButtonLabel: t(
            'Content.InterstitialLoanSubmitted.VerifyYourIncome',
          ),
          onPrimaryButtonPress: navigateToOpenBanking,
        }
      : {
          primaryButtonLabel: t(
            'Content.InterstitialLoanSubmitted.VerifyDocuments',
          ),
          onPrimaryButtonPress: navigateToAppSubmit,
        };
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: {
      id: loanApplicationId,
      type: loanApplicationType,
    },
  });

  if (
    !loadingRequiredData &&
    loanValidationResult !== LoanValidationResult.Valid
  ) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <ScrollContainer>
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <InterstitialLoanHeader canContinue={canContinue} />
        {canContinue ? (
          <GreenCheckAnimation my="xl" />
        ) : (
          <SubmittingLoanIndicator
            testID={TestID.InterstitialLoanSubmitted.Loading}
          />
        )}
        <LoanApplicationWizardFooter
          {...modalFooterProps}
          primaryButtonTestID={
            TestID.InterstitialLoanSubmitted.SubmittedDoneButton
          }
        />
      </LoanApplicationScreenContainer>
    </ScrollContainer>
  );
}

export const InterstitialLoanSubmittedV2 = withAuthenticationRequired(
  InterstitialLoanSubmittedBaseV2,
);
