import omitBy from 'lodash/omitBy';
import { useCallback, useRef } from 'react';

import { useGTM } from '../../Analytics/GTMProvider';
import {
  AppInteractionEvent,
  AppInteractionEventAdditionalData,
  ApplicanTypeForGTM,
  EventAdditionalData,
  GTMAppInteractionEventDescription,
  GTMEvent,
  LoanAppEvent,
  VirtualPageViewEvent,
  VirtualPageViewEventAdditionalData,
} from '../../Analytics/types';
import { useAuthContext } from '../../Auth/context';
import {
  Applicant_Role_Enum,
  LoanAppForGtmEventFragment,
  useGetMeQuery,
} from '../../generated/graphql';

type SendVirtualPageViewEventToGTM = {
  virtualPageName: string;
  additionalData?: VirtualPageViewEventAdditionalData;
};

type SendAppInteractionEventToGTM = {
  description: GTMAppInteractionEventDescription;
  additionalData?: AppInteractionEventAdditionalData;
};

function transformAppInteractionAdditionalDataToPartialGtmEvent(
  additionalData: SendAppInteractionEventToGTM['additionalData'],
): Partial<AppInteractionEvent> | null {
  if (additionalData == null) {
    return null;
  }

  const partial: Partial<AppInteractionEvent> = {
    ...additionalData,
  };

  return omitBy(
    partial,
    (value, key) => value === undefined || key === 'loanApplicationId',
  );
}

function transformVirtualPageViewAdditionalDataToPartialGtmEvent(
  additionalData: SendVirtualPageViewEventToGTM['additionalData'],
): Partial<VirtualPageViewEvent> | null {
  if (additionalData == null) {
    return null;
  }

  const partial: Partial<VirtualPageViewEvent> = {
    ...additionalData,
  };

  return omitBy(
    partial,
    (value, key) =>
      value === undefined ||
      key === 'event' ||
      key === 'virtual_page_name' ||
      key === 'loanApplicationId',
  );
}

export function useSendDataToGTM() {
  const authContext = useAuthContext();
  const meQuery = useGetMeQuery({
    skip: !authContext?.isAuthenticated,
    context: {
      sentryContext: {
        isAuthenticated: authContext?.isAuthenticated,
        description: 'Error occured in useSendDataToGTM hook',
      },
    },
  });
  const myApplicants = meQuery?.data?.me?.[0]?.user?.applicants;

  const { sendDataToGTM } = useGTM();
  const previousPageVirtualTitle = useRef('');

  const getEventApplicationDetail = useCallback(
    (additionalData?: EventAdditionalData) => {
      if (!additionalData) {
        return {};
      }

      const { current_application_id: applicationId } = additionalData;
      const selectedApplicant = myApplicants?.find(
        (a) => a.loan_application_id === applicationId,
      );

      const borrowerType = mapToLoanApplicantType(selectedApplicant?.role);

      const applicationDetails =
        applicationId || borrowerType
          ? {
              current_application_id: applicationId,
              loan_applicant_type: borrowerType,
              loan_application_type: selectedApplicant?.loan_application?.type,
            }
          : {};
      return applicationDetails;
    },
    [myApplicants],
  );

  const sendVirtualPageViewEventToGTM = useCallback(
    async ({
      virtualPageName,
      additionalData,
    }: SendVirtualPageViewEventToGTM) => {
      if (virtualPageName !== previousPageVirtualTitle.current) {
        previousPageVirtualTitle.current = virtualPageName;
        sendDataToGTM({
          event: GTMEvent.VirtualPageView,
          virtual_page_name: virtualPageName,
          ...getEventApplicationDetail(additionalData),
          ...transformVirtualPageViewAdditionalDataToPartialGtmEvent(
            additionalData,
          ),
        });
      }
    },
    [getEventApplicationDetail, sendDataToGTM],
  );

  const sendAppInteractionEventToGTM = useCallback(
    ({ description, additionalData }: SendAppInteractionEventToGTM) => {
      sendDataToGTM({
        event: GTMEvent.AppInteractionEvent,
        description,
        ...getEventApplicationDetail(additionalData),
        ...transformAppInteractionAdditionalDataToPartialGtmEvent(
          additionalData,
        ),
      });
    },
    [getEventApplicationDetail, sendDataToGTM],
  );

  return {
    sendAppInteractionEventToGTM,
    sendVirtualPageViewEventToGTM,
  };
}

/**
 * Hook to send Loan Application events to GTM.
 * Unlike useSendDataToGTM, this hook is specifically designed for Loan Application events.
 * It does not query loan details but expects them to be passed as function parameters.
 * @returns sendLoanAppEventToGTM
 */
export function useSendLoanAppEventToGTM() {
  const { sendDataToGTM } = useGTM();

  const sendLoanAppEventToGTM = useCallback(
    ({
      loanApplicationDetails,
      event,
    }: {
      loanApplicationDetails: LoanAppForGtmEventFragment;
      event: LoanAppEvent['event'];
    }) => {
      const currentApplicant = loanApplicationDetails.applicants?.find(
        (i) => i.is_current_logged_in_applicant,
      );

      sendDataToGTM({
        event,
        loan_app_id: loanApplicationDetails.id,
        loan_applicant_type: mapToLoanApplicantType(currentApplicant?.role),
        loan_application_type: loanApplicationDetails.type,
      });
    },
    [sendDataToGTM],
  );

  return sendLoanAppEventToGTM;
}

function mapToLoanApplicantType(
  role: Applicant_Role_Enum | undefined,
): ApplicanTypeForGTM | undefined {
  if (role == null) {
    return undefined;
  }
  switch (role) {
    case Applicant_Role_Enum.PrimaryBorrower:
      return ApplicanTypeForGTM.Borrower;
    case Applicant_Role_Enum.Borrower:
      return ApplicanTypeForGTM.Coborrower;
    default:
      return undefined;
  }
}
