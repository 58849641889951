import { Theme, useTheme } from '../theme';

export function useCalculateTextSkeletonSize(
  textVariant: keyof Theme['textVariants'] = 'default',
  numOfCharacters = 3,
) {
  const theme = useTheme();
  return {
    width: theme.textVariants[textVariant].fontSize * numOfCharacters,
    height: theme.textVariants[textVariant].lineHeight,
  };
}
