import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function LightBulbIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(24, 26, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 24 26" fill="none" {...props}>
      <Path
        d="M4.81348 6.92041C4.81348 3.21436 7.98242 0.303223 12 0.303223C16.0176 0.303223 19.1865 3.21436 19.1865 6.92041C19.1865 11.0454 16.7266 12.0659 16.0391 19.231C15.9961 19.6177 15.7812 19.8647 15.3623 19.8647H8.6377C8.22949 19.8647 8.01465 19.6177 7.97168 19.231C7.28418 12.0659 4.81348 11.0454 4.81348 6.92041ZM6.43555 6.92041C6.43555 10.0034 8.47656 10.7339 9.4541 18.2427H14.5566C15.5234 10.7339 17.5645 10.0034 17.5645 6.92041C17.5645 4.02002 15.0186 1.92529 12 1.92529C8.98145 1.92529 6.43555 4.02002 6.43555 6.92041ZM8.60547 22.1851C8.25098 22.1851 7.97168 21.9058 7.97168 21.5513C7.97168 21.2075 8.25098 20.9282 8.60547 20.9282H15.4053C15.7598 20.9282 16.0283 21.2075 16.0283 21.5513C16.0283 21.9058 15.7598 22.1851 15.4053 22.1851H8.60547ZM12 25.3003C10.3242 25.3003 8.92773 24.4839 8.85254 23.2485H15.1582C15.0508 24.4839 13.665 25.3003 12 25.3003Z"
        fill={color}
      />
    </Svg>
  );
}
