import { ReactNode, useEffect } from 'react';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { Box } from '../ui/atoms/Box';
import { RowRight } from '../ui/atoms/RowRight';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { StyledText } from '../ui/atoms/StyledText';
import { Props as RowProps, Row } from '../ui/molecules/Row';
import { useTheme } from '../ui/theme';
import { usePrevious } from '../utils/hooks/usePrevious';

type Props = RowProps & {
  isCollapsed: boolean;
  collapsedContent?: string | ReactNode;
  onPress: () => void;
};

export const CollapsibleListRow: React.FC<Props> = ({
  isCollapsed,
  collapsedContent,
  onPress,
  last,
  ...props
}) => {
  const theme = useTheme();

  const arrowAnimatedStyle = useRotateAnimatedStyle(isCollapsed);

  return (
    <Box
      borderBottomColor="border"
      borderBottomWidth={last ? 0 : theme.constants.borderWidth}
    >
      <TouchableOpacity role="button" onPress={onPress}>
        <Row labelFontWeight="medium" last {...props}>
          <RowRight>
            <Animated.View style={arrowAnimatedStyle}>
              <StyledIcon
                family="svg"
                name="downArrow"
                accessibilityLabel="Collapsible Row Arrow"
                color="secondaryContent"
              />
            </Animated.View>
          </RowRight>
        </Row>
      </TouchableOpacity>
      <Collapsible collapsed={isCollapsed}>
        <Box px="m" pb="m" flex={1}>
          {typeof collapsedContent === 'string' ? (
            <StyledText variant="body" color="secondaryContent">
              {collapsedContent}
            </StyledText>
          ) : (
            collapsedContent
          )}
        </Box>
      </Collapsible>
    </Box>
  );
};

function useRotateAnimatedStyle(isCollapsed: boolean) {
  const rotate = useSharedValue(0);
  const prevValue = usePrevious(isCollapsed);
  useEffect(() => {
    if (prevValue !== isCollapsed) {
      rotate.value = withTiming(isCollapsed ? 0 : 180, { duration: 200 });
    }
  }, [isCollapsed, prevValue, rotate]);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ rotate: `${rotate.value}deg` }],
  }));
  return animatedStyle;
}
