import { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { RowRight } from '../../ui/atoms/RowRight';
import { InputRow, Props as InputRowProps } from '../../ui/organisms/InputRow';
import { CurrencyInput, Props as CurrencyInputProps } from './CurrencyInput';

export type Props = Omit<InputRowProps, 'ref' | 'noBorder' | 'testID'> &
  CurrencyInputProps & {
    inputTestID?: string;
  };

export const CurrencyInputRow = forwardRef<RNTextInput, Props>(
  ({ last, children, inputTestID, testID, ...props }, ref) => (
    <InputRow
      fixedLabel={false}
      fontWeight="normal"
      pr="m"
      noBorder={last}
      last={last}
      testID={testID}
      {...props}
    >
      <CurrencyInput
        ref={ref}
        returnKeyType="next"
        placeholder={t('Content.Common.Placeholder.EnterAmount')}
        keyboardType="decimal-pad"
        testID={inputTestID}
        {...props}
      />
      {children ? <RowRight>{children}</RowRight> : null}
    </InputRow>
  ),
);
