import {
  Liability_Type_Enum,
  MergedLiabilityForYourDebtsFragment,
} from '../../generated/graphql';
import { LiabilityTypeSupportedByApp } from '../../supportedEnumTypes';
import { parseEnumType } from '../../utils/ensureEnumType';

type PossibleMissingField = keyof Pick<
  MergedLiabilityForYourDebtsFragment,
  'dynamite_interest_rate' | 'dynamite_repayment_amount'
>;
type PossibleMissingFieldsByLiabilityType = Record<
  LiabilityTypeSupportedByApp,
  Array<PossibleMissingField>
>;

// JoinedLiabilityFormField in shape of `{detected_liability.identifier}-{fieldName}`
type JoinedLiabilityFormField = `${string}-${PossibleMissingField}`;
export type JoinedLiabilityFormValues = Record<
  JoinedLiabilityFormField,
  string | number
>;

// Right now the UI in the wizard only works with at most 1 missing field per liability.
export const POSSIBLE_MISSING_FIELDS_BY_LIABILITY_TYPE: PossibleMissingFieldsByLiabilityType =
  {
    [Liability_Type_Enum.HomeLoan]: ['dynamite_interest_rate'],
    [Liability_Type_Enum.LineOfCredit]: ['dynamite_interest_rate'],
    [Liability_Type_Enum.MarginOrInvestmentLoan]: ['dynamite_interest_rate'],
    [Liability_Type_Enum.Personal]: ['dynamite_repayment_amount'],
    [Liability_Type_Enum.PersonalOverdraft]: [],
    [Liability_Type_Enum.CreditCard]: [],
    [Liability_Type_Enum.ChargeCard]: [],
    [Liability_Type_Enum.Bnpl]: [],
    [Liability_Type_Enum.StoreCard]: [],
    [Liability_Type_Enum.NovatedLease]: [],
    [Liability_Type_Enum.HecsHelp]: [],
    [Liability_Type_Enum.Other]: [],
  };

export function doesMLHasMissingDetails(
  ml: MergedLiabilityForYourDebtsFragment,
  possibleMissingFieldsByLiabilityType = POSSIBLE_MISSING_FIELDS_BY_LIABILITY_TYPE,
): boolean {
  if (ml.dynamite_flagged_incorrect) {
    // For liability flagged as incorrect, we don't care if it has missing details.
    return false;
  }

  const liabilityType = parseEnumType(
    Liability_Type_Enum,
    ml.dynamite_liability_type,
  );
  if (!liabilityType) {
    return false;
  }

  const requiredFields =
    liabilityType in possibleMissingFieldsByLiabilityType
      ? possibleMissingFieldsByLiabilityType[
          liabilityType as keyof typeof possibleMissingFieldsByLiabilityType
        ]
      : null;

  if (!requiredFields) {
    return false;
  }

  // Check the value for every required fields
  for (const field of requiredFields) {
    const fieldValue = ml[field];
    // Only check the field if it's not an array.
    if (!Array.isArray(fieldValue) && fieldValue == null) {
      return true;
    }
  }
  return false;
}
