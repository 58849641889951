import { useIsFocused } from '@react-navigation/native';
import { TransitionPresets, useCardAnimation } from '@react-navigation/stack';
import { ComponentProps } from 'react';
import * as React from 'react';
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  ViewProps,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { FocusOn } from '../../components/FocusOn';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';
import { useIsMobile, useIsTablet } from '../../utils/hooks/useBreakpoint';
import {
  MAX_DRAWER_CONTENT_HEIGHT_MOBILE,
  MAX_DRAWER_CONTENT_HEIGHT_TABLET,
  MAX_DRAWER_WIDTH,
} from '../../utils/NavConstants';
import { isWeb } from '../../utils/platformUtils';
import {
  ActionListButton,
  Props as ActionListButtonProps,
} from './ActionListButton';
import {
  ActionSheetHeader,
  Props as ActionSheetHeaderProps,
} from './ActionSheetHeader';

export type Props = Omit<ActionSheetHeaderProps, 'onClosePress'> & {
  closeModal?: () => void;
  showCloseButton?: boolean;
  onFooterTextLinkPress?: () => void;
  /**
   * Prevent the user from closing the modal through:
   * - clicking on outside area of the modal
   * - pressing esc key
   * - pressing close button if visible
   * This does not imply close button is invisible
   */
  preventCloseModal?: boolean;
  message?: string | JSX.Element;
  isRowButton?: boolean;
  footerText?: string | JSX.Element;
  /** Only used if footerText is a string */
  footerTextLink?: string;
  actionList?: Array<ActionListButtonProps>;
  errorMessage?: string | null;
  contentContainerStyle?: BoxProps['style'];
  children?: React.ReactNode;
} & ViewProps;

export function ActionSheet({
  closeModal: closeModalProp,
  showCloseButton = true,
  children,
  actionList,
  preventCloseModal,
  errorMessage,
  onFooterTextLinkPress,
  message,
  footerText,
  footerTextLink,
  caption,
  title,
  headerRightTitle,
  headerLeftTitle,
  onHeaderLeftPress,
  onHeaderRightPress,
  isRowButton,
  contentContainerStyle,
  ...viewProps
}: Props) {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const isTablet = useIsTablet();
  const cardAnimation = useCardAnimation();
  const Transition =
    TransitionPresets.FadeFromBottomAndroid.cardStyleInterpolator(
      cardAnimation,
    );

  const messageEl =
    typeof message === 'string' ? (
      <MessageText pt="m">{message}</MessageText>
    ) : (
      message
    );

  const footerEl =
    typeof footerText === 'string' ? (
      <StyledText variant="caption" pt="m" textAlign="center">
        <StyledText variant="caption">{footerText}</StyledText>
        {footerTextLink ? (
          <StyledText
            variant="caption"
            color="link"
            onPress={onFooterTextLinkPress}
          >
            {footerTextLink}
          </StyledText>
        ) : null}
      </StyledText>
    ) : (
      footerText
    );

  const closeModal = preventCloseModal ? undefined : closeModalProp;

  const isMobile = useIsMobile();
  const isCurrentScreenFocused = useIsFocused();
  return (
    <Box
      {...styles.root}
      flex={1}
      alignItems="center"
      style={{
        paddingBottom: isTablet ? 0 : insets.bottom || theme.spacing['3xl'],
      }}
      testID={TestID.ActionSheet.ContentContainer}
    >
      <Animated.View
        style={{
          ...StyleSheet.absoluteFillObject,
          // TODO need to add overlay color into theme
          backgroundColor: 'black',
          opacity: cardAnimation.current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.4],
            extrapolate: 'clamp',
          }),
        }}
      />
      <FocusOn
        onClickOutside={closeModal}
        onEscapeKey={closeModal}
        style={styles.focusWrapper}
        enabled={isCurrentScreenFocused}
      >
        <Animated.View
          style={{
            // Set max height to make sure card container follow mobile dimensions
            maxHeight:
              Platform.OS === 'web' && isMobile
                ? Dimensions.get('window').height
                : undefined,
            width: '100%',
            backgroundColor: theme.colors.shapeBg,
            borderRadius: theme.borderRadii.card,
            ...Transition.cardStyle,
          }}
          {...viewProps}
        >
          <ActionSheetHeader
            caption={caption}
            title={title}
            onClosePress={closeModal}
            showCloseButton={showCloseButton}
            disableCloseButton={preventCloseModal}
            headerRightTitle={headerRightTitle}
            headerLeftTitle={headerLeftTitle}
            onHeaderLeftPress={onHeaderLeftPress}
            onHeaderRightPress={onHeaderRightPress}
          />

          <Box
            {...styles.sheetContentContainer}
            flex={isWeb ? 1 : undefined}
            pb="l"
            width="100%"
            style={[
              contentContainerStyle,
              isWeb
                ? {
                    overflow: 'auto' as const,
                  }
                : undefined,
            ]}
          >
            <ErrorRow message={errorMessage} mt="m" />
            {messageEl}
            {children ? <Box alignSelf="stretch">{children}</Box> : null}
            {footerEl}
            {actionList && actionList.length > 0 ? (
              <Box pt="m" row={isRowButton}>
                {actionList.map((actionListProps, index) => (
                  <ActionListButton
                    {...actionListProps}
                    key={actionListProps.label}
                    {...(isRowButton
                      ? { ml: index === 0 ? 0 : 's' }
                      : { mt: index === 0 ? 0 : 's' })}
                  />
                ))}
              </Box>
            ) : null}
          </Box>
        </Animated.View>
      </FocusOn>
    </Box>
  );
}

function MessageText(props: ComponentProps<typeof StyledText>) {
  return (
    <StyledText
      variant="body"
      textAlign="center"
      alignSelf="center"
      {...props}
    />
  );
}

ActionSheet.MessageText = MessageText;

const styles = {
  root: {
    justifyContent: {
      mobile: 'flex-end',
      tablet: 'center',
    },
    px: {
      mobile: 's',
      tablet: 'm',
    },
  },
  focusWrapper: {
    width: '100%',
    maxWidth: MAX_DRAWER_WIDTH,
  },
  sheetContentContainer: {
    px: { mobile: 'm', tablet: 'l' },
    maxHeight: {
      tablet: MAX_DRAWER_CONTENT_HEIGHT_TABLET,
      mobile: MAX_DRAWER_CONTENT_HEIGHT_MOBILE,
    },
  },
} as const;
