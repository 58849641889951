import { isKnownEnumValue, StandardEnum, WithFutureType } from './typesHelpers';

export function transformStringToEnum<T extends StandardEnum>(
  myString: string,
  myEnum: T,
): WithFutureType<T[keyof T]>;
export function transformStringToEnum<T extends StandardEnum>(
  myString: null | undefined,
  myEnum: T,
): null;
export function transformStringToEnum<T extends StandardEnum>(
  myString: string | null | undefined,
  myEnum: T,
): WithFutureType<T[keyof T] | null>;
export function transformStringToEnum<T extends StandardEnum>(
  myString: string | null | undefined,
  myEnum: T,
): WithFutureType<T[keyof T] | null> {
  if (myString == null) {
    return null;
  }

  if (isKnownEnumValue(myEnum, myString)) {
    return myString;
  }

  return '%future added value';
}
