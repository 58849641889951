import { LDMultiKindContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';

import { LaunchDarklyClient } from './context';
import { FALLBACK_FLAGS, Flags } from './featureFlags';

export const anonymousContext: LDMultiKindContext = {
  kind: 'multi',
  user: {
    anonymous: true,
  },
  loan_application_id: {
    anonymous: true,
  },
};

export function useCustomLDClient(
  setFlags: React.Dispatch<React.SetStateAction<Flags>>,
): LaunchDarklyClient {
  const client = useLDClient();

  const fetchAllFlags = useCallback(() => {
    const allFlags = client?.allFlags() ?? FALLBACK_FLAGS;
    setFlags(allFlags as Flags);
  }, [client, setFlags]);

  const setupClient = useCallback(() => {
    client?.on('error', (_error) => {
      // Do nothing on network error as LD client will handle the reconnect.
      // https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error-
    });

    fetchAllFlags();

    client?.on('change', (updatedFlags) => {
      if (!isEmpty(updatedFlags)) {
        fetchAllFlags();
      }
    });

    return () => {
      client?.off('change', () => {});
    };
  }, [client, fetchAllFlags]);

  useEffect(() => setupClient(), [setupClient, client]);

  return {
    identify: client?.identify,
    variation: client?.variation,
    track: client?.track,
    allFlags: client?.allFlags,
  };
}
