import { format } from 'date-fns';
import { Formik } from 'formik';
import { useCallback, useContext, useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { Form, FormDatePicker } from '../../components/form/FormikInputs';
import { SubmitButton } from '../../components/form/SubmitButton';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { oneOfEnum } from '../../components/utils/oneOfEnum';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Autopay_Frequency_Input_Enum,
  useGetExternalAccountsQuery,
} from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Separator } from '../../ui/atoms/Separator';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { getMaximumDate, getMinimumDateToday } from '../../utils/dateHelpers';
import { makeGetErrorMessage } from '../../utils/makeGetErrorMessage';
import { yup } from '../../utils/yup';
import {
  autopayChangeFormAtom,
  autopayCustomRepaymentFormAtom,
} from '../autopayAtom';
import { AutopayAccountSelectorControl } from '../components/AutopayAccountSelectorControl';
import { AutopayRepaymentSelect } from '../components/AutopayRepaymentSelect';
import { useDirectDebitLimits } from '../hooks/useDirectDebitLimits';
import { useGetEstimatedShortfallAmount } from '../hooks/useGetEstimatedShortfallAmount';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { AutopayChangeRequestForm, AutoPaySetupFormField } from '../types';

const INIT_VALS: AutopayChangeRequestForm = {
  [AutoPaySetupFormField.RepaymentFrequency]: undefined as
    | Autopay_Frequency_Input_Enum
    | undefined,
  [AutoPaySetupFormField.RepaymentAmount]: 0,
  [AutoPaySetupFormField.ExternalAccountId]: '',
  [AutoPaySetupFormField.DirectDebitAccountNumber]: '',
  [AutoPaySetupFormField.PaymentStartDate]: undefined as Date | undefined,
  [AutoPaySetupFormField.IsMinimumAmount]: true,
};

export const validationSchema = () =>
  yup.object({
    [AutoPaySetupFormField.RepaymentFrequency]: oneOfEnum(
      Autopay_Frequency_Input_Enum,
    ).required(t('Content.AutopaySettings.RepaymentFrequencyRequired')),
    [AutoPaySetupFormField.RepaymentAmount]: yup
      .string()
      .required(t('Content.AutopaySettings.RepaymentAmountRequired')),
    [AutoPaySetupFormField.ExternalAccountId]: yup
      .string()
      .required(t('Content.AutopaySettings.AccountRequired')),
    [AutoPaySetupFormField.DirectDebitAccountNumber]: yup
      .string()
      .required(t('Content.AutopaySettings.AccountRequired')),
    [AutoPaySetupFormField.PaymentStartDate]: yup
      .date()
      .required(t('Content.AutopaySettings.InvalidDateRequired'))
      .typeError(t('Content.AutopaySettings.InvalidDateWithFormat'))
      .min(
        getMinimumDateToday(),
        t('Content.AutopaySettings.InvalidDateMustBeInFuture'),
      )
      .max(
        getMaximumDate(),
        t('Content.AutopaySettings.InvalidDateMustBeNotTooFarInFuture'),
      ),
    [AutoPaySetupFormField.IsMinimumAmount]: yup.boolean().required(),
  });

const useResetAutopaySetupStateOnCloseModal = () => {
  const resetSelectedExternalAccount = useResetRecoilState(
    selectedExternalAccountAtom,
  );
  const resetCustomRepaymentAtom = useResetRecoilState(
    autopayCustomRepaymentFormAtom,
  );
  const resetAutopayChangeFormAtom = useResetRecoilState(autopayChangeFormAtom);

  useEffect(
    () => () => {
      resetAutopayChangeFormAtom();
      resetSelectedExternalAccount();
      resetCustomRepaymentAtom();
    },
    [
      resetAutopayChangeFormAtom,
      resetSelectedExternalAccount,
      resetCustomRepaymentAtom,
    ],
  );
};

type AutopaySetupProps = AutopaySettingsScreenProps<Screen.AUTOPAY_SETUP>;

export const AutopaySetup = ({ navigation, route }: AutopaySetupProps) => {
  const { cbaAccountId, _initialValues: initialValues = INIT_VALS } =
    route.params;
  const { flags } = useContext(FeatureFlagsContext);
  const isShortfallAlertEnabled = flags.ENABLE_APP_SHORTFALL_ALERT;

  useResetAutopaySetupStateOnCloseModal();

  const setAutopayChangeFormAtom = useSetRecoilState(autopayChangeFormAtom);

  // Adding the get external accounts query here means the external account list
  // is already loaded when user presses "Select account" button
  const { loading: loadingExternalAccounts } = useGetExternalAccountsQuery();

  const { loading: loadingLimits, data: limitsData } =
    useDirectDebitLimits(cbaAccountId);

  const loading = loadingExternalAccounts || loadingLimits;

  const monthlyMinimumRepayment = limitsData?.minimimRepaymentAmount.Monthly;

  const { getEstimatedShortfallAmount } = useGetEstimatedShortfallAmount({
    cbaAccountId,
  });

  const handleSubmit = useCallback(
    async ({ ...values }: AutopayChangeRequestForm) => {
      if (!values.repaymentFrequency) {
        return;
      }

      if (!values.paymentStartDate) {
        return;
      }

      if (!limitsData?.minimimRepaymentAmount) {
        return;
      }

      const minimumRepaymentAmount =
        limitsData.minimimRepaymentAmount[values.repaymentFrequency];

      const [calculateShortfallRes] = await getEstimatedShortfallAmount({
        cba_account_id: route.params.cbaAccountId,
        effective_date: format(values.paymentStartDate, 'yyyy-MM-dd'),
        is_minimum_repayment: values.repaymentAmount === minimumRepaymentAmount,
        new_repayment_amount: values.repaymentAmount,
        repayment_frequency: values.repaymentFrequency,
      });

      const shortfallAmount = calculateShortfallRes?.data?.estimated_shortfall;

      if (typeof shortfallAmount !== 'number') {
        throw new Error('Could not calculate shortfall');
      }

      setAutopayChangeFormAtom({
        cbaAccountId,
        repaymentAmount: values.repaymentAmount,
        repaymentFrequency: values.repaymentFrequency,
        externalAccountId: values.externalAccountId,
        directDebitAccountNumber: values.directDebitAccountNumber,
        paymentStartDate: values.paymentStartDate,
        estimatedShortfall: shortfallAmount,
        isMinimumAmount: values.isMinimumAmount,
      });

      if (shortfallAmount > 0 && isShortfallAlertEnabled) {
        navigation.navigate(ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION, {
          cbaAccountId,
          shortfallAmount,
        });
      } else {
        navigation.navigate(ActionSheetType.AUTOPAY_CONFIRMATION, {
          cbaAccountId,
        });
      }
    },
    [
      limitsData?.minimimRepaymentAmount,
      getEstimatedShortfallAmount,
      route.params.cbaAccountId,
      setAutopayChangeFormAtom,
      cbaAccountId,
      navigation,
      isShortfallAlertEnabled,
    ],
  );

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.HomeLoan.SetupAutopay')}
      onClose={onClose}
      scrollable
      hideBackButton
      loading={loading}
    >
      <ScreenLoadingContainer loading={loading}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formProps) => (
            <Form>
              <AutopayRepaymentSelect
                disabled={formProps.isSubmitting}
                monthlyMinimumRepaymentAmount={monthlyMinimumRepayment ?? 0}
                selectedRepaymentFrequency={formProps.values.repaymentFrequency}
                selectedRepaymentAmount={formProps.values.repaymentAmount}
                onSelect={({
                  selectedRepaymentAmount,
                  selectedRepaymentFrequency,
                  isMinimumAmount,
                }) => {
                  formProps.setFieldValue(
                    AutoPaySetupFormField.RepaymentAmount,
                    selectedRepaymentAmount,
                  );
                  formProps.setFieldValue(
                    AutoPaySetupFormField.RepaymentFrequency,
                    selectedRepaymentFrequency,
                  );
                  formProps.setFieldValue(
                    AutoPaySetupFormField.IsMinimumAmount,
                    isMinimumAmount,
                  );
                }}
                onPressCustom={() =>
                  navigation.navigate(Screen.AUTOPAY_CUSTOM_REPAYMENT, {
                    cbaAccountId: route.params.cbaAccountId,
                  })
                }
                footerErrorMessage={makeGetErrorMessage(formProps)(
                  AutoPaySetupFormField.RepaymentFrequency,
                )}
              />
              <Separator spacer />
              <AutopayAccountSelectorControl
                disabled={formProps.isSubmitting}
                onAccountSelected={(selectedAccount) => {
                  formProps.setFieldValue(
                    AutoPaySetupFormField.ExternalAccountId,
                    selectedAccount?.externalAccountId,
                  );
                  formProps.setFieldValue(
                    AutoPaySetupFormField.DirectDebitAccountNumber,
                    selectedAccount?.maskedAccountNumber,
                  );
                }}
                onPress={() => {
                  navigation.navigate(Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT, {
                    cbaAccountId: route.params.cbaAccountId,
                  });
                }}
                validationErrorMessage={makeGetErrorMessage(formProps)(
                  AutoPaySetupFormField.DirectDebitAccountNumber,
                )}
              />
              <Separator spacer />
              <ListRowGroup
                mx={0}
                headerText={t('Content.AutopaySettings.Commencing')}
                footerErrorMessage={makeGetErrorMessage(formProps)(
                  AutoPaySetupFormField.PaymentStartDate,
                )}
              >
                <FormDatePicker
                  name={AutoPaySetupFormField.PaymentStartDate}
                  placeholder="DD/MM/YYYY"
                  minimumDate={getMinimumDateToday()}
                  maximumDate={getMaximumDate()}
                  displayError={false}
                  disabled={formProps.isSubmitting}
                  last
                />
              </ListRowGroup>
              <SubmitButton
                testID={TestID.AutopaySetup.SubmitButton}
                mt="l"
                showSpinner={formProps.isSubmitting}
                disabled={formProps.isSubmitting}
                onPress={formProps.submitForm}
              />
            </Form>
          )}
        </Formik>
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
};
