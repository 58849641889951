import { ComponentProps } from 'react';
import { Dimensions, Platform, ScrollView as RNScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { TestID } from '../../../testID/constants';
import { createBox } from '../utils/createBox';

type ScrollContainerProps = ComponentProps<typeof RNScrollView> & {
  /**
   * When set to true, will use KeyboardAwareScrollView instead of RNScrollView.
   * On Android, we still use the native behaviour set in `windowSoftInputMode` inside AndroidManifest.xml,
   * so KeyboardAwareScrollView will be disabled. Can be overrided by setting `enableOnAndroid` to true.
   * Default to true.
   *
   * For complete docs, see https://github.com/APSL/react-native-keyboard-aware-scroll-view
   */
  isKeyboardAware?: boolean;
};

function ScrollView({
  isKeyboardAware = true,
  ...otherProps
}: ScrollContainerProps) {
  const ScrollComponent = isKeyboardAware
    ? KeyboardAwareScrollView
    : RNScrollView;
  return <ScrollComponent testID={TestID.ScrollContainer} {...otherProps} />;
}
export const ScrollContainer = createBox(ScrollView);

ScrollContainer.defaultProps = {
  showsVerticalScrollIndicator: false,
  width: '100%',
  //   To make sure ScrollContainer didn't expand larger than the screen's height, which resulting in inability to scroll
  height: Platform.OS === 'web' ? Dimensions.get('window').height : undefined,
};
