import { useTheme } from '@shopify/restyle';
import { ComponentProps, memo, useCallback, useRef, useState } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { TestID } from '../../../testID/constants';
import { Box, BoxProps } from '../atoms/Box';
import { StyledIcon } from '../atoms/StyledIcon';
import { StyledText } from '../atoms/StyledText';
import { StyledTextInput } from '../atoms/StyledTextInput';
import { TouchableWithoutFeedback } from '../atoms/TouchableWithoutFeedback';
import { Theme } from '../theme';
import { useLayoutAnimation } from '../utils/useLayoutAnimation';

type Props = BoxProps &
  Pick<ComponentProps<typeof StyledTextInput>, 'autoWrapTextLengthLimit'> & {
    onChangeText?: (text: string) => void;
    onFocus?: () => void;
    onCancel?: () => void;
    autoFocus?: boolean;
    onBlur?: () => void;
    value?: string;
    style?: StyleProp<ViewStyle>;
    placeholder?: string;
    enableCancelButton?: boolean;
    searchBoxProps?: BoxProps;
    withSearchIcon?: boolean;
    wrapText?: boolean;
    wrapNumberOfLines?: number;
  };

export const SearchBar = memo<Props>(
  ({
    autoFocus,
    value,
    onChangeText,
    placeholder = 'Search',
    enableCancelButton,
    onBlur,
    onFocus,
    onCancel,
    searchBoxProps,
    withSearchIcon = false,
    wrapText,
    wrapNumberOfLines,
    autoWrapTextLengthLimit,
    ...props
  }) => {
    const [isEditing, setIsEditing] = useState(false);

    const showCancel = isEditing || value;

    useLayoutAnimation([isEditing]);

    const inputRef = useRef<TextInput>(null);

    const handleBlur = useCallback(() => {
      setIsEditing(false);
      if (onBlur) onBlur();
    }, [onBlur]);

    const handleFocus = useCallback(() => {
      setIsEditing(true);
      if (onFocus) onFocus();
    }, [onFocus]);

    const handleInputPress = useCallback(() => {
      if (inputRef.current && !isEditing) {
        inputRef.current.focus();
      }
    }, [isEditing, inputRef]);

    const handleCancel = useCallback(() => {
      if (inputRef.current) {
        inputRef.current.blur();
        if (onCancel) onCancel();
      }
    }, [inputRef, onCancel]);

    const theme = useTheme<Theme>();

    return (
      <Box row {...props}>
        <TouchableWithoutFeedback
          style={styles.fullWidth}
          onPress={handleInputPress}
        >
          <Box
            row
            alignItems="center"
            flex={1}
            borderRadius="field"
            bg="buttonDisabledBg"
            {...searchBoxProps}
          >
            {withSearchIcon ? (
              <StyledIcon
                name="search"
                color="secondaryContent"
                size="s"
                mx="s"
                family="svg"
              />
            ) : (
              <Box mx="s" />
            )}
            <StyledTextInput
              ref={inputRef}
              flex={
                Platform.OS === 'android' || !enableCancelButton || showCancel
                  ? 1
                  : 0
              }
              value={value}
              onChangeText={onChangeText}
              placeholder={placeholder}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholderTextColor={theme.colors.secondaryContent}
              clearButtonMode="while-editing"
              returnKeyType="done"
              autoCapitalize="none"
              autoCorrect={false}
              autoFocus={autoFocus}
              wrapText={wrapText}
              wrapNumberOfLines={wrapNumberOfLines}
              autoWrapTextLengthLimit={autoWrapTextLengthLimit}
              py="s"
              testID={TestID.SearchBar.AddressSearchPropertyInput}
            />
          </Box>
        </TouchableWithoutFeedback>
        {enableCancelButton ? (
          <TouchableOpacity style={styles.cancel} onPress={handleCancel}>
            <StyledText
              color="accent"
              ml="s"
              style={!showCancel ? styles.cancelHidden : null}
            >
              Cancel
            </StyledText>
          </TouchableOpacity>
        ) : null}
      </Box>
    );
  },
);

const styles = StyleSheet.create({
  cancel: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  cancelHidden: { position: 'absolute', right: -60 },
  fullWidth: { width: '100%' },
});
