import { useMemo } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { formatLiabilityLabel } from '../../components/utils/formatLiabilityRow';
import {
  Get_Disclosure_Content_Output,
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
  MergedLiabilityForDebtConsolidationFragment,
  Top_Up_Reason_Disclosure_Category_Enum,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Link } from '../../ui/atoms/Link';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { CheckBox } from '../../ui/molecules/CheckBox';
import { isKnownEnumValue } from '../../utils/typesHelpers';

export type DisclosureSectionData = {
  mergedLiabilitiesForRefinancing: Array<MergedLiabilityForDebtConsolidationFragment>;
  disclosureContent: Get_Disclosure_Content_Output | undefined;
};

export type DisclosureSectionProps = {
  screen: Screen;
  disclosureData: DisclosureSectionData;
  getDisclosureLoading?: boolean;
  onDisclosureCheckboxPress?: () => void;
  disclosureCheckboxLoading?: boolean;
  onCheckboxLayout?: (event: LayoutChangeEvent) => void;
  loanApplicationType?: Loan_Application_Type_Enum;
};

export function DisclosureSection({
  screen,
  disclosureData,
  onDisclosureCheckboxPress,
  getDisclosureLoading,
  disclosureCheckboxLoading,
  onCheckboxLayout,
  loanApplicationType,
}: DisclosureSectionProps) {
  const {
    disclosureContent,
    mergedLiabilitiesForRefinancing: initialMergedLiabilitiesForRefinancing,
  } = disclosureData;

  const mergedLiabilitiesForRefinancing =
    loanApplicationType === Loan_Application_Type_Enum.TopUp
      ? initialMergedLiabilitiesForRefinancing.filter(
          (liability) =>
            liability.dynamite_liability_type !== Liability_Type_Enum.HomeLoan,
        )
      : initialMergedLiabilitiesForRefinancing;

  const title = useMemo(() => {
    switch (loanApplicationType) {
      case Loan_Application_Type_Enum.TopUp:
        return t('Consent.TopUpCategory.TopUpTitle');
      default:
        return t('Consent.TopUpCategory.Title');
    }
  }, [loanApplicationType]);

  const topUpCategories =
    disclosureContent?.required_top_up_reasons_disclosure || [];
  const needDebtConsolidationConsent =
    disclosureContent?.need_debt_consolidation_disclosure;

  const shouldShowDisclosureSection =
    topUpCategories.length > 0 || needDebtConsolidationConsent;

  if (getDisclosureLoading) {
    return (
      <>
        <Separator my="m" />
        <Spinner />
      </>
    );
  }

  if (!shouldShowDisclosureSection) {
    return null;
  }

  return (
    <>
      <Separator my="m" />
      {topUpCategories.length > 0 ? (
        <>
          <StyledText variant="caption" fontWeight="bold">
            {`${title}\n\n`}
          </StyledText>
          {topUpCategories.map((topUpCategory) => {
            if (
              !isKnownEnumValue(
                Top_Up_Reason_Disclosure_Category_Enum,
                topUpCategory,
              )
            ) {
              return null;
            }

            return (
              <StyledText key={topUpCategory} variant="caption">
                {t(`Consent.TopUpCategory.${topUpCategory}.Content`)}
                <Link
                  variant="caption"
                  href={t(`Link.TopUpCategory.${topUpCategory}`)}
                >{` ${t(`Content.Common.ButtonLabel.LearnMore`)}\n\n`}</Link>
              </StyledText>
            );
          })}
          <StyledText variant="caption">
            {t('Consent.Common.ContractuallyObliged')}
          </StyledText>
        </>
      ) : null}
      {needDebtConsolidationConsent ? (
        <Box mt={topUpCategories.length ? 'm' : 0}>
          <StyledText variant="caption" fontWeight="bold">
            {`${t('Consent.DebtConsolidation.Title')}\n\n`}
          </StyledText>
          <StyledText variant="caption">
            {`${t('Consent.DebtConsolidation.Content.FirstDescription')}\n`}
          </StyledText>
          <Box>
            {mergedLiabilitiesForRefinancing.map(
              ({ id, dynamite_institution_name, dynamite_liability_type }) => {
                const formattedProductName = formatLiabilityLabel({
                  institutionName: dynamite_institution_name,
                  liabilityType: dynamite_liability_type,
                });
                return (
                  <Box row key={id}>
                    <StyledText variant="caption">{`\u2022 `}</StyledText>
                    <StyledText variant="caption" flex={1}>
                      {formattedProductName}
                    </StyledText>
                  </Box>
                );
              },
            )}
          </Box>
          <StyledText variant="caption">
            {`${t('Consent.DebtConsolidation.Content.SecondDescription')} `}
            <Link
              variant="caption"
              textAlign="center"
              alignSelf="center"
              href={t(`Link.DebtConsolidation`)}
            >
              {t('Content.Common.ButtonLabel.LearnMore')}
            </Link>
          </StyledText>
        </Box>
      ) : null}
      <Box alignItems="center" flexDirection="row" mt="l">
        <Box mr="s">
          {disclosureCheckboxLoading ? (
            <Spinner />
          ) : (
            <CheckBox
              onLayout={onCheckboxLayout}
              borderRadius="tag"
              onPress={onDisclosureCheckboxPress}
              testID={TestID.ReviewApplicationSummary.DisclosureCheckbox}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.YourUnloan,
                screen,
                FieldInteractionKey.DebtConsolidationDisclosure,
              )}
            />
          )}
        </Box>

        <StyledText variant="caption">
          {t('Consent.Common.IUnderstand')}
        </StyledText>
      </Box>
    </>
  );
}
