import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReduceRepaymentsConfirmedAtomFamily } from '../../LoanVariation/utils/reduceRepaymentsAtom';
import { ActionSheetType } from '../../navigation/types/screens';
import { BaseConfirmationSuccessActionSheet } from '../components/BaseConfirmationSuccessActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED>;

export function ReduceRepaymentsSubmittedActionSheet({
  navigation,
  route,
}: Props) {
  const { cbaAccountId, otherBorrowersNames } = route.params;

  const setRepaymentConfirmed = useSetRecoilState(
    ReduceRepaymentsConfirmedAtomFamily(cbaAccountId),
  );

  const onClose = useCallback(() => {
    setRepaymentConfirmed(true);
    navigation.goBack();
  }, [navigation, setRepaymentConfirmed]);

  const message =
    !!otherBorrowersNames && otherBorrowersNames.length > 0
      ? t('Content.LoanVariation.ReduceRepaymentConfirmation.RequestSubmitted')
      : t(
          'Content.LoanVariation.ReduceRepaymentConfirmation.RepaymentSubmittedConfirmationTitle',
        );

  const supportingText =
    !!otherBorrowersNames && otherBorrowersNames.length > 0
      ? t(
          'Content.LoanVariation.ReduceRepaymentConfirmation.CoBorrowerApprovalRequired',
          {
            coBorrowerNames: otherBorrowersNames,
          },
        )
      : t(
          'Content.LoanVariation.ReduceRepaymentConfirmation.RepaymentSubmittedConfirmationSubText',
        );

  return (
    <BaseConfirmationSuccessActionSheet
      message={message}
      supportingText={supportingText}
      includeCloseButton
      onClose={onClose}
    />
  );
}
