import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { TestID } from '../../../../testID/constants';

const DisabledLine = () => (
  <Svg
    viewBox="0 0 167 95"
    fill="none"
    testID={TestID.Insights.PropertyValue.StaticLine.Disabled}
  >
    <Path
      opacity="0.2"
      d="M22.497 52.0917C14.497 52.0917 6.99704 50.5917 1.02808 48.4598V79.5917H167.028V37.4461C154.408 36.1758 142.497 31.0917 125.997 31.0917C115.935 33.1077 106.497 33.0917 95.497 35.5917C89.9735 35.5073 89.7792 37.4461 85.9808 38.7282C77.497 41.5917 75.5584 41.0522 60.997 48.4598C56.1432 49.4558 52.497 50.5917 39.997 52.0917C32.497 52.9917 27.497 53.0917 22.497 52.0917Z"
      fill="#3C3C43"
      fillOpacity="0.33"
    />
    <Path
      d="M22.497 52.0917C13.997 52.0917 6.99704 50.0917 1.02808 48.4598V79.5917H167.028V37.4461C154.408 36.1758 145.997 33.0917 127.997 31.5917C117.497 31.5917 113.497 31.5917 101.997 33.0917C96.497 35.0917 88.7015 40.347 85.9808 38.7282C74.997 42.0917 76.0584 41.0522 61.497 48.4598C56.6432 49.4558 48.497 51.0917 38.497 52.0917C30.997 52.8417 26.497 52.0917 22.497 52.0917Z"
      fill="url(#paint0_linear_2621_89394)"
    />
    <Path
      d="M1.99707 49.0917C77.4971 66.0917 79.4971 14.5917 165.028 37.4651"
      stroke="#3C3C43"
      strokeOpacity="0.33"
      strokeWidth="2.43787"
      strokeLinecap="round"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_2621_89394"
        x1="84.0281"
        y1="40.421"
        x2="84.0281"
        y2="79.5917"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="white" stopOpacity="0.05" />
        <Stop offset="0.380208" stopColor="white" stopOpacity="0.4" />
        <Stop offset="0.75" stopColor="white" stopOpacity="0.8087" />
        <Stop offset="0.985707" stopColor="white" />
      </LinearGradient>
    </Defs>
  </Svg>
);

const ActiveLine = () => (
  <Svg
    viewBox="0 0 167 95"
    fill="none"
    testID={TestID.Insights.PropertyValue.StaticLine.Active}
  >
    <Path
      opacity="0.2"
      d="M0 52.5C14 52.5 6.5 51 0.531036 48.8681V80H166.531V37.8544C153.911 36.5841 142 31.5 125.5 31.5C115.438 33.516 106 33.5 95 36C89.4764 35.9156 89.2821 37.8544 85.4838 39.1365C77 42 75.0614 41.4605 60.5 48.8681C55.6462 49.8641 52 51 39.5 52.5C32 53.4 27 53.5 22 52.5Z"
      fill="url(#paint0_linear_1717_73789)"
    />
    <Path
      d="M0 52.5C13.5 52.5 6.5 50.5 0.531036 48.8681V80H166.531V37.8544C153.911 36.5841 145.5 33.5 127.5 32C117 32 113 32 101.5 33.5C96 35.5 88.2045 40.7553 85.4838 39.1364C74.5 42.5 75.5614 41.4604 61 48.8681C56.1462 49.8641 48 51.5 38 52.5C30.5 53.25 26 52.5 22 52.5Z"
      fill="url(#paint1_linear_1717_73789)"
    />
    <Path
      d="M1.5 49.5C77 66.5 79 15 164.531 37.8734"
      stroke="url(#paint2_linear_1717_73789)"
      strokeWidth="2.43787"
      strokeLinecap="round"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_1717_73789"
        x1="0.531036"
        y1="52.473"
        x2="141.452"
        y2="52.4661"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF7BAD" />
        <Stop offset="1" stopColor="#5188EE" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_1717_73789"
        x1="83.531"
        y1="40.8293"
        x2="83.531"
        y2="80"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="white" stopOpacity="0.05" />
        <Stop offset="0.380208" stopColor="white" stopOpacity="0.4" />
        <Stop offset="0.75" stopColor="white" stopOpacity="0.8087" />
        <Stop offset="0.985707" stopColor="white" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_1717_73789"
        x1="1.5"
        y1="41.0341"
        x2="139.901"
        y2="41.0189"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF7BAD" />
        <Stop offset="1" stopColor="#5188EE" />
      </LinearGradient>
    </Defs>
  </Svg>
);

type Props = {
  disabled?: boolean;
};
export const StaticWidgetLine = ({ disabled = false }: Props) =>
  disabled ? <DisabledLine /> : <ActiveLine />;
