import { SxProp, Text, View } from 'dripsy';
import { FormikContextType } from 'formik';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import type { FormikControlProps } from '../../components/form/types';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { SelectableRow } from './SelectableRow';

type CheckboxProps = {
  label: string;
  testID?: string;
  containerStyle?: SxProp;
  subtitle?: string;
  onItemPress?: () => void;
  isSelected?: boolean;
  labelId?: string;
  disabled?: boolean;
} & Pick<React.ComponentProps<typeof SelectableRow>, 'bordered'>;

export function Checkbox(props: CheckboxProps) {
  const {
    onItemPress,
    isSelected,
    labelId,
    label,
    subtitle,
    disabled,
    bordered,
    ...otherProps
  } = props;

  return (
    <SelectableRow
      onPress={onItemPress}
      iconType="checkbox"
      aria-labelledby={labelId}
      isSelected={isSelected || false}
      disabled={disabled}
      bordered={bordered}
      {...otherProps}
    >
      <View sx={{ flex: 1 }}>
        <Text
          nativeID={labelId}
          sx={{
            ...(disabled && {
              color: '$secondary',
            }),
          }}
        >
          {label}
        </Text>
        {subtitle ? <Text variant="caption">{subtitle}</Text> : null}
      </View>
    </SelectableRow>
  );
}

export type CheckboxInputForFormikProps = Expand<
  Omit<CheckboxProps, 'maxSelection'> &
    Pick<
      React.ComponentProps<typeof SelectableRow>,
      'onFocus' | 'onBlur' | 'focused' | 'disabled'
    > &
    Omit<FormikControlProps<string>, 'setFieldValue'> & {
      // For checkbox, the value in Formik state is an array,
      // but the invidiual checkbox value is a string.
      // Here, we need to override the `setFieldValue`
      // from `FormikControlProps`, to make individual
      // checkbox integrate with Formik directly instead of using
      // additional provider.
      value: string;
      setFieldValue?: (
        value: Array<string>,
        shouldValidate?: boolean,
      ) => Promise<void>;
      formik?: FormikContextType<Record<string, Array<string>>>;
      onItemPressCallback?: () => void;
      interactionKey: ApplicationInteractionEventKeyType;
    }
>;

export function CheckboxInputForFormik(props: CheckboxInputForFormikProps) {
  const {
    name,
    value: thisItemValue,
    label,
    subtitle,
    disabled,
    interactionKey,

    // Formik props
    formik,
    setFieldValue,
    setFieldTouched,

    onItemPressCallback,

    ...otherProps
  } = props;

  const [labelId] = React.useState(() => uuidv4());

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const values = formik?.values || {};
  const thisFieldValue = new Set(
    name && Array.isArray(values[name]) ? values[name] || [] : [],
  );

  const isSelected = thisFieldValue.has(thisItemValue);
  const setFieldValueAndTouch = async (valueToSet: typeof thisFieldValue) => {
    await setFieldValue?.(Array.from(valueToSet), false);
    setFieldTouched?.(true, true);
  };

  const onItemPress = async () => {
    if (interactionKey) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.CheckboxClicked,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      });
    }
    if (isSelected) {
      thisFieldValue.delete(thisItemValue);
      await setFieldValueAndTouch(thisFieldValue);
      onItemPressCallback?.();
      return;
    }
    thisFieldValue.add(thisItemValue);
    await setFieldValueAndTouch(thisFieldValue);
    onItemPressCallback?.();
  };

  return (
    <Checkbox
      {...otherProps}
      onItemPress={onItemPress}
      labelId={labelId}
      label={label}
      subtitle={subtitle}
      isSelected={isSelected}
      disabled={disabled}
    />
  );
}
