import * as React from 'react';

import { Box } from '../../ui/atoms/Box';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';

type Props = {
  icon: string;
  title: string;
  description: string;
};

export const IntroSection: React.FC<Props> = ({ title, description, icon }) => (
  <Box row pb="l" maxWidth={500}>
    <StyledIcon name={icon} size="l" family="svg" color="accent" />
    <Box ml="l" flex={1}>
      <StyledText variant="body" fontWeight="semiBold">
        {title}
      </StyledText>
      <StyledText variant="body" color="secondaryContent">
        {description}
      </StyledText>
    </Box>
  </Box>
);
