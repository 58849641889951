import { Text, View } from 'dripsy';
import { useMemo } from 'react';

import { TestID } from '../../../../testID/constants';
import { Tooltip } from '../../../ui/atoms/Tooltip';
import { formatCurrency } from '../../../utils/currencyHelpers';

type Props = {
  lowValue: number;
  midValue: number;
  highValue: number;
};

const Line = () => (
  <View
    sx={{
      height: 1,
      flexGrow: 1,
      backgroundColor: '$border',
      mx: '$12',
      my: '$10',
    }}
  />
);

const EstimatedValue = ({
  value,
  label,
  bigger = false,
}: {
  value: string;
  label: string;
  bigger?: boolean;
}) => (
  <View
    sx={{
      height: 36,
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <Text variant={bigger ? 'body' : 'label'} sx={{ fontWeight: '600' }}>
      {value}
    </Text>
    <Text variant="tiny" sx={{ color: '$fieldPromptText' }}>
      {label}
    </Text>
  </View>
);

export const EstimatedPropertyValue = ({
  lowValue,
  midValue,
  highValue,
}: Props) => {
  const formattedValuesInThousandsOrMillion = useMemo(
    () => ({
      lowValue: formatCurrency(lowValue, {
        displayInMillion: true,
        displayInThousand: true,
      }),
      midValue: formatCurrency(midValue, {
        displayInMillion: true,
        displayInThousand: true,
      }),
      highValue: formatCurrency(highValue, {
        displayInMillion: true,
        displayInThousand: true,
      }),
    }),
    [highValue, lowValue, midValue],
  );

  const formattedValue = useMemo(
    () => ({
      lowValue: formatCurrency(lowValue, {}),
      midValue: formatCurrency(midValue, {}),
      highValue: formatCurrency(highValue, {}),
    }),
    [highValue, lowValue, midValue],
  );

  return (
    <View>
      <View sx={{ flexDirection: 'row', mb: '$4', alignItems: 'center' }}>
        <Text variant="caption" sx={{ mr: '$4', color: '$fieldPromptText' }}>
          {t(
            'Content.HomeLoan.Insights.PropertyValue.EstimatedPropertyValueHeader',
          )}
        </Text>
        <Tooltip
          content={t(
            'Content.HomeLoan.Insights.PropertyValue.EstimatedPropertyValueInfo',
            {
              lowValue: formattedValue.lowValue,
              midValue: formattedValue.midValue,
              highValue: formattedValue.highValue,
            },
          )}
          iconSize="s"
          iconColor="infoIcon"
          iconOpacity={1}
          testID={TestID.Insights.PropertyValue.EstimatedPropertyValueInfo}
        />
      </View>

      <View sx={{ flexDirection: 'row', alignItems: 'flex-start', ml: '$4' }}>
        <EstimatedValue
          value={formattedValuesInThousandsOrMillion.lowValue}
          label={t('Content.HomeLoan.Insights.PropertyValue.LowValue')}
        />

        <Line />

        <EstimatedValue
          bigger
          value={formattedValuesInThousandsOrMillion.midValue}
          label={t('Content.HomeLoan.Insights.PropertyValue.MidValue')}
        />

        <Line />

        <EstimatedValue
          value={formattedValuesInThousandsOrMillion.highValue}
          label={t('Content.HomeLoan.Insights.PropertyValue.HighValue')}
        />
      </View>
    </View>
  );
};
