import { Pressable, SxProp, Text, View } from 'dripsy';
import { useState } from 'react';
import * as React from 'react';
import Animated from 'react-native-reanimated';

import { LoadingRow } from '../molecules/LoadingRow';
import { ChevronRightIcon } from '../svgs/ChevronRightIcon';
import { useCardChevronTranslateXAnimatedStyle } from '../utils/useCardChevronTranslateXAnimatedStyle';
import { buttonBackgroundTransition } from './constants';

type TransactionsCardProps = {
  testID?: string;
  sx?: SxProp;
  children?: React.ReactNode;
};

export function TransactionsCard({
  sx: sxProp = {},
  ...props
}: TransactionsCardProps) {
  return (
    <View
      sx={{
        backgroundColor: '$inputBackground',
        borderRadius: '$card',
        overflow: 'hidden',
        cursor: 'unset',
        ...sxProp,
      }}
      {...props}
    />
  );
}

type TransactionRowProps = {
  label?: string;
  amountLabel?: string;
  caption?: string;
  subCaption?: string;
  onPress?: () => void;
  loading?: boolean;
  testID?: string;
};
export function TransactionRow({
  label,
  amountLabel,
  caption,
  subCaption,
  onPress,
  loading,
  testID,
}: TransactionRowProps) {
  const [isHover, setIsHover] = useState(false);

  const animatedChevronStyle = useCardChevronTranslateXAnimatedStyle(isHover);

  if (loading) {
    return <LoadingRow />;
  }

  const content = (
    <>
      <View>
        {label ? (
          <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
            {label}
          </Text>
        ) : null}
        {caption ? (
          <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
            {caption}
          </Text>
        ) : null}

        {subCaption ? <Text variant="caption">{subCaption}</Text> : null}
      </View>

      <View sx={{ flexDirection: 'row' }}>
        <Text variant="sBody">{amountLabel}</Text>
        {onPress ? (
          <View sx={{ ml: '$16', mt: '$4' }}>
            <Animated.View style={animatedChevronStyle}>
              <ChevronRightIcon size={14} />
            </Animated.View>
          </View>
        ) : null}
      </View>
    </>
  );

  const sxProps: SxProp = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingX: '$16',
    paddingY: '$12',
    cursor: onPress ? 'pointer' : 'unset',
    backgroundColor: isHover ? '$quanternaryBackground' : undefined,
    transition: buttonBackgroundTransition,
  };

  if (onPress) {
    return (
      <Pressable
        testID={testID}
        role="button"
        sx={sxProps}
        onPress={onPress}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPressIn={() => setIsHover(true)}
        onPressOut={() => setIsHover(false)}
      >
        {content}
      </Pressable>
    );
  }

  return (
    <View testID={testID} sx={sxProps}>
      {content}
    </View>
  );
}
