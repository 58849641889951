import { isAfter, isBefore } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { NewFeaturesModalMonitor } from '../../NewFeatures/hooks/useNewFeaturesModalMonitor';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { underMaintenanceAtom } from '../atoms/underMaintenanceAtom';
import { Dashboard } from '../components/Dashboard';
import { DashboardV2 } from '../components/DashboardV2';
import { useOpenNativeAppMonitor } from '../utils/useOpenNativeAppMonitor';

type Props = {
  currentTime?: Date;
};

function Home({ currentTime = new Date() }: Props) {
  useOpenNativeAppMonitor();

  const { config } = useEnvConfig();
  const { flags } = useContext(FeatureFlagsContext);

  const [currTime, setCurrTime] = useState<Date>(currentTime); // to force refresh dashboard

  const setUnderMaintenance = useSetRecoilState(underMaintenanceAtom);

  useEffect(() => {
    const maintenanceStartTime = new Date(config.maintenanceStartTime);
    const maintenanceEndTime = new Date(config.maintenanceEndTime);

    const isUnderMaintenance =
      isBefore(maintenanceStartTime, currTime) &&
      isAfter(maintenanceEndTime, currTime);

    setUnderMaintenance(isUnderMaintenance);

    // Force refresh in case the screen is loaded
    // before the maintenance window
    const isBeforeMaintenance = isBefore(currTime, maintenanceStartTime);

    let timeoutValue: number;

    if (isBeforeMaintenance) {
      timeoutValue = maintenanceStartTime.getTime() - currTime.getTime();
    } else if (isUnderMaintenance) {
      timeoutValue = maintenanceEndTime.getTime() - currTime.getTime();
    } else {
      timeoutValue = 0;
    }

    const timer = setTimeout(() => {
      if (isBeforeMaintenance || isUnderMaintenance) {
        setCurrTime(new Date());
      }
    }, timeoutValue);

    return () => {
      clearTimeout(timer);
    };
  }, [
    currTime,
    setUnderMaintenance,
    config.maintenanceStartTime,
    config.maintenanceEndTime,
  ]);

  return (
    <>
      <NewFeaturesModalMonitor />
      {flags.DASHBOARD_VERSION === 'V2' ? (
        <DashboardV2 currentTime={currentTime} />
      ) : (
        <Dashboard currentTime={currentTime} />
      )}
    </>
  );
}

export const WithAuthenticationHome = withAuthenticationRequired(Home);
