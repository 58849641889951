import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';

export const MAX_PERIODS = 6;

export const useLoanAccountInterestSavings = (cbaAccountId: string) => {
  const { data: loan, loading: isLoadingLoanAccount } =
    useLoanAccountState(cbaAccountId);

  const result = useMemo(() => {
    const predictedInterestSavingsTillEndOfLoan =
      loan?.term_remaining_insights?.predicted_interest_savings || 0;

    const historicalInterestSavings = sortBy(
      loan?.historical_interest_savings?.details?.map((obj) => ({
        interestSaving: obj?.interest_saving,
        scheduleDate: obj?.schedule_date || '',
      })),
      (obj) => new Date(obj.scheduleDate).getTime(),
    ).slice(-MAX_PERIODS);

    const totalInterestSavingsUntilNow =
      loan?.historical_interest_savings?.total_interest_savings;

    return {
      historicalInterestSavings,
      totalInterestSavingsUntilNow,
      predictedTotalInterestSavings:
        (totalInterestSavingsUntilNow || 0) +
        predictedInterestSavingsTillEndOfLoan,
      loading: isLoadingLoanAccount,
    };
  }, [
    isLoadingLoanAccount,
    loan?.historical_interest_savings?.details,
    loan?.historical_interest_savings?.total_interest_savings,
    loan?.term_remaining_insights?.predicted_interest_savings,
  ]);

  return result;
};
