import { AccountClosureCreditCardIcon } from './AccountClosureCreditCardIcon';
import { AccountClosureHomeLoanIcon } from './AccountClosureHomeLoanIcon';
import { AccountClosurePersonalLoanIcon } from './AccountClosurePersonalLoanIcon';
import { ActiveFilterIcon } from './ActiveFilterIcon';
import { AddIcon } from './AddIcon';
import { AlertCircleIcon } from './AlertCircleIcon';
import { ApplicationSummaryCreditCard } from './ApplicationSummaryCreditCard';
import { ApplicationSummaryEmploymentIncome } from './ApplicationSummaryEmploymentIncome';
import { ApplicationSummaryExpenses } from './ApplicationSummaryExpenses';
import { ApplicationSummaryHomeLoan } from './ApplicationSummaryHomeLoan';
import { ApplicationSummaryProfile } from './ApplicationSummaryProfile';
import { ApplicationSummaryProperty } from './ApplicationSummaryProperty';
import { ApplicationSummaryRentalIncome } from './ApplicationSummaryRentalIncome';
import { ArrowLeftIcon } from './ArrowLeftIcon';
import { BathIcon } from './BathIcon';
import { BedIcon } from './BedIcon';
import { BorrowerProfileIcon } from './BorrowerProfileIcon';
import { BorrowersIcon } from './BorrowersIcon';
import { CarIcon } from './CarIcon';
import { CDRLogo } from './CDRLogo';
import { ChatIcon } from './ChatIcon';
import { ChevronLeftIcon } from './ChevronLeftIcon';
import { ChevronRightIcon } from './ChevronRightIcon';
import { CloseIcon } from './CloseIcon';
import { CogWheelIcon } from './CogWheelIcon';
import { ConditionalApprovalCalculatorIcon } from './ConditionalApprovalCalculatorIcon';
import { ConditionalApprovalDocumentIcon } from './ConditionalApprovalDocumentIcon';
import { ConditionalApprovalDocumentPendingIcon } from './ConditionalApprovalDocumentPendingIcon';
import { ConditionalApprovalPurchaseIcon } from './ConditionalApprovalPurchaseIcon';
import { ConditionalApprovalVerifyIcon } from './ConditionalApprovalVerifyIcon';
import { ContractIcon } from './ContractIcon';
import { CopyIcon } from './CopyIcon';
import { DataSharingIcon } from './DataSharingIcon';
import { DeleteIcon } from './DeleteIcon';
import { DocumentIcon } from './DocumentIcon';
import { DollarCircleFilledIcon } from './DollarCircleFilledIcon';
import { DownArrowIcon } from './DownArrowIcon';
import { DownloadIcon } from './DownloadIcon';
import { FilterIcon } from './FilterIcon';
import { FinancialEmploymentIncomeIcon } from './FinancialEmploymentIncomeIcon';
import { FinancialHouseholdExpenseIcon } from './FinancialHouseholdExpenseIcon';
import { FinancialIcon } from './FinancialIcon';
import { FinancialOtherIncomeIcon } from './FinancialOtherIncomeIcon';
import { FinancialRentalIncomeIcon } from './FinancialRentalIncomeIcon';
import { FinancialSavingIncomeIcon } from './FinancialSavingIncomeIcon';
import { FingerprintIcon } from './FingerprintIcon';
import { FlashIcon } from './FlashIcon';
import { GoodFinancialDecisionIcon } from './GoodFinancialDecisionIcon';
import { GoogleIcon } from './GoogleIcon';
import { GradientBallIcon } from './GradientBallIcon';
import { HomeIcon } from './HomeIcon';
import { HouseIcon } from './HouseIcon';
import { IdentityDocumentIcon } from './IdentityDocumentIcon';
import { IDVIcon } from './IDVIcon';
import { InfoIcon } from './InfoIcon';
import { InfoIconFilled } from './InfoIconFilled';
import { InstitutionIcon } from './InstitutionIcon';
import { IntercomSupportIcon } from './IntercomSupportIcon';
import { LiabilityCreditCardIcon } from './LiabilityCreditCardIcon';
import { LiabilityHomeLoanIcon } from './LiabilityHomeLoanIcon';
import { LiabilityOtherLoanIcon } from './LiabilityOtherLoanIcon';
import { LiabilityPersonalLoanIcon } from './LiabilityPersonalLoanIcon';
import { LightBulbIcon } from './LightBulbIcon';
import { ManageDataIcon } from './ManageDataIcon';
import { MoreIcon } from './MoreIcon';
import { NotificationIcon } from './NotificationIcon';
import { RefinanceIcon } from './RefinanceIcon';
import { RefinancingSupportingDocumentIcon } from './RefinancingSupportingDocumentIcon';
import { RightArrowIcon } from './RightArrowIcon';
import { SafariShareIcon } from './SafariShareIcon';
import { SearchIcon } from './SearchIcon';
import { SecurityIcon } from './SecurityIcon';
import { SettingsIcon } from './SettingsIcon';
import { ShareIcon } from './ShareIcon';
import { StackedScreensIcon } from './StackedScreensIcon';
import { StatementsIcon } from './StatementsIcon';
import { SupportFilledIcon } from './SupportFilledIcon';
import { SupportIcon } from './SupportIcon';
import { TabNavSettingsIcon } from './TabNavSettingsIcon';
import { TaskRequestIcon } from './TaskRequestIcon';
import { TickCircleIcon } from './TickCircleIcon';
import { TickIcon } from './TickIcon';
import { TickIconV2 } from './TickIconV2';
import { TimeIcon } from './TimeIcon';
import { TooltipArrowIcon } from './TooltipArrowIcon';
import { TransferIcon } from './TransferIcon';
import { UnloanIcon } from './UnloanIcon';
import { UnloanLogo } from './UnloanLogo';
import { UnloanLogoRound } from './UnloanLogoRound';
import { UserProfileFilledIcon } from './UserProfileFilledIcon';
import { UserProfileIcon } from './UserProfileIcon';
import { WarningIcon } from './WarningIcon';
import { YourCurrentLoanIcon } from './YourCurrentLoanIcon';
import { YourFinancialsIcon } from './YourFinancialIcon';
import { YourHomeLoanIcon } from './YourHomeLoanIcon';
import { YourLiabilityCreditCardIcon } from './YourLiabilityCreditCardIcon';
import { YourLiabilityHomeLoanIcon } from './YourLiabilityHomeLoanIcon';
import { YourLiabilityPersonalLoanIcon } from './YourLiabilityPersonalLoanIcon';
import { YourPropertyIcon } from './YourPropertyIcon';

export const SVG_ICON_LIST = {
  // Please keep this list sorted
  alertCircle: AlertCircleIcon,
  accountClosureHomeLoan: AccountClosureHomeLoanIcon,
  accountClosurePersonalLoan: AccountClosurePersonalLoanIcon,
  accountClosureCreditCard: AccountClosureCreditCardIcon,
  activeFilter: ActiveFilterIcon,
  add: AddIcon,
  arrowLeft: ArrowLeftIcon,
  bed: BedIcon,
  bath: BathIcon,
  borrowerProfile: BorrowerProfileIcon,
  borrowers: BorrowersIcon,
  car: CarIcon,
  cdr: CDRLogo,
  chat: ChatIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  closeIcon: CloseIcon,
  copy: CopyIcon,
  conditionalApprovalCalculatorIcon: ConditionalApprovalCalculatorIcon,
  conditionalApprovalDocumentIcon: ConditionalApprovalDocumentIcon,
  conditionalApprovalDocumentPendingIcon:
    ConditionalApprovalDocumentPendingIcon,
  conditionalApprovalPurchaseIcon: ConditionalApprovalPurchaseIcon,
  conditionalApprovalVerifyIcon: ConditionalApprovalVerifyIcon,
  contract: ContractIcon,
  dataSharing: DataSharingIcon,
  delete: DeleteIcon,
  documentIcon: DocumentIcon,
  dollarCircleFilledIcon: DollarCircleFilledIcon,
  downArrow: DownArrowIcon,
  download: DownloadIcon,
  filter: FilterIcon,
  financial: FinancialIcon,
  financialEmploymentIncome: FinancialEmploymentIncomeIcon,
  financialHouseholdExpense: FinancialHouseholdExpenseIcon,
  financialOtherIncome: FinancialOtherIncomeIcon,
  financialRentalIncome: FinancialRentalIncomeIcon,
  financialSavingIncome: FinancialSavingIncomeIcon,
  fingerprint: FingerprintIcon,
  flash: FlashIcon,
  gradientBall: GradientBallIcon,
  google: GoogleIcon,
  goodFinancialDecision: GoodFinancialDecisionIcon,
  home: HomeIcon,
  house: HouseIcon,
  identityDocument: IdentityDocumentIcon,
  idv: IDVIcon,
  info: InfoIcon,
  infoFilled: InfoIconFilled,
  institution: InstitutionIcon,
  intercomSupport: IntercomSupportIcon,
  liabilityCreditCard: LiabilityCreditCardIcon,
  liabilityHomeLoan: LiabilityHomeLoanIcon,
  liabilityOtherLoan: LiabilityOtherLoanIcon,
  liabilityPersonalLoan: LiabilityPersonalLoanIcon,
  lightBulb: LightBulbIcon,
  manageData: ManageDataIcon,
  more: MoreIcon,
  notification: NotificationIcon,
  profile: UserProfileIcon,
  profileFilled: UserProfileFilledIcon,
  profileMenuHome: StackedScreensIcon,
  profileMenuSettings: CogWheelIcon,
  profileMenuSignOut: UserProfileIcon,
  refinance: RefinanceIcon,
  refinancingSupportingDocument: RefinancingSupportingDocumentIcon,
  rightArrow: RightArrowIcon,
  safariShare: SafariShareIcon,
  search: SearchIcon,
  security: SecurityIcon,
  settings: SettingsIcon,
  share: ShareIcon,
  statements: StatementsIcon,
  summaryCreditLoan: ApplicationSummaryCreditCard,
  summaryEmploymentIncome: ApplicationSummaryEmploymentIncome,
  summaryExpenses: ApplicationSummaryExpenses,
  summaryHomeLoan: ApplicationSummaryHomeLoan,
  summaryPersonalLoan: ApplicationSummaryExpenses,
  summaryBorrower: ApplicationSummaryProfile,
  summaryProperty: ApplicationSummaryProperty,
  summaryRentIncome: ApplicationSummaryRentalIncome,
  support: SupportIcon,
  supportFilled: SupportFilledIcon,
  tabNavSettings: TabNavSettingsIcon,
  taskRequest: TaskRequestIcon,
  tick: TickIcon,
  tickV2: TickIconV2,
  tickCircle: TickCircleIcon,
  time: TimeIcon,
  tooltipArrow: TooltipArrowIcon,
  transfer: TransferIcon,
  unloanIcon: UnloanIcon,
  unloanLogo: UnloanLogo,
  unloanLogoRound: UnloanLogoRound,
  warningIcon: WarningIcon,
  yourHomeLoan: YourHomeLoanIcon,
  yourCurrentLoan: YourCurrentLoanIcon,
  yourFinancial: YourFinancialsIcon,
  yourLiabilityCreditCard: YourLiabilityCreditCardIcon,
  yourLiabilityHomeLoan: YourLiabilityHomeLoanIcon,
  yourLiabilityPersonalLoan: YourLiabilityPersonalLoanIcon,
  yourProperty: YourPropertyIcon,
};

export type SvgIconName = keyof typeof SVG_ICON_LIST;
