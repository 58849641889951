import { useState } from 'react';
import * as React from 'react';

import {
  Location,
  MapSize,
  StaticMapView,
} from '../../components/StaticMapView';
import { Box } from '../../ui/atoms/Box';
import { EmptyState } from '../../ui/organisms/EmptyState';

type Props = {
  location?: Location | string | null;
  locationName: string;
  containerHeight?: number;
  zoom?: number;
  scale?: React.ComponentProps<typeof StaticMapView>['scale'];
  onError?: () => void;
};

// Aspect ratio = width / height
export const MAP_VIEW_ASPECT_RATIO = 1.6;

export default function PropertyStaticMap(props: Props) {
  const { location, locationName, containerHeight, zoom, scale, onError } =
    props;
  const [mapSize, setMapSize] = useState<null | MapSize>(null);

  if (!mapSize) {
    // On first render, we try to calculate the available space first
    // because we need a specific number before requesting the static
    // map image to the API.
    return (
      <Box
        flex={1}
        onLayout={(e) => {
          const { width } = e.nativeEvent.layout;
          const height = width / MAP_VIEW_ASPECT_RATIO;
          setMapSize({ width, height });
        }}
      />
    );
  }

  return (
    <Box width={mapSize.width} height={containerHeight || mapSize.height}>
      {location ? (
        <StaticMapView
          location={location}
          size={mapSize}
          zoom={zoom}
          scale={scale}
          onError={onError}
        />
      ) : (
        <EmptyState
          title={t('Content.PropertyCheck.NoMapView')}
          description={`for ${locationName}`}
        />
      )}
    </Box>
  );
}
