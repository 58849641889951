import { AppAddressFormat } from '../components/form/types';
import {
  Address_Details,
  AddressSearchSuggestionFragment,
  Country_Enum,
  HasuraAddressFragment,
} from '../generated/graphql';
import { isNotNullOrUndefined } from './arrayHelpers';
import { parseEnumType } from './ensureEnumType';

/**
 * A helper function to map address from hasura to the address format
 * we use accross the app. We do this because we want to keep things
 * consistent for form state.
 */
export function mapHasuraAddressToAppAddress(
  hasuraAddress: HasuraAddressFragment,
): AppAddressFormat {
  return {
    displayAddress: hasuraAddress.display_address,
    postcode: hasuraAddress.postcode,
    state: hasuraAddress.state,
    street: hasuraAddress.street,
    streetNo: hasuraAddress.street_no,
    streetNoTwo: hasuraAddress.street_no_two,
    streetType: hasuraAddress.street_type,
    suburb: hasuraAddress.suburb,
    unitNo: hasuraAddress.unit_no,
    countryCode: hasuraAddress.country_code,
    // Vendor spesific properties
    domainApiId: hasuraAddress.domain_api_property_id,
    isProvidedByUser: !!hasuraAddress.provided_by_user_id,
  };
}

export function mapHasuraAddressToAppAddressForTopUps(
  hasuraAddress: HasuraAddressFragment,
): AppAddressFormat {
  return {
    ...mapHasuraAddressToAppAddress(hasuraAddress),
    isProvidedByUser: !hasuraAddress.domain_api_property_id,
  };
}

export function mapFrankieoneAddressToAppAddress(
  frankieAddress: Address_Details,
): AppAddressFormat | null {
  return {
    displayAddress: [
      frankieAddress.street_no,
      frankieAddress.street_name,
      frankieAddress.street_type,
    ]
      .filter(isNotNullOrUndefined)
      .join(' '),
    postcode: frankieAddress.postcode,
    state: frankieAddress.state,
    street: frankieAddress.street_name,
    streetNo: frankieAddress.street_no,
    streetType: frankieAddress.street_type,
    suburb: frankieAddress.suburb,
    unitNo: frankieAddress.unit_no,
    countryCode: parseEnumType(Country_Enum, frankieAddress.country),
  };
}

export function mapDomainAPIAddressToAppAddress(
  domainAddress: AddressSearchSuggestionFragment,
): AppAddressFormat {
  const {
    display_address: displayAddress,
    postcode,
    domain_api_property_id: domainApiId,
    state,
    street,
    street_no: streetNo,
    street_type: streetType,
    suburb,
    unit_no: unitNo,
  } = domainAddress;
  return {
    displayAddress,
    postcode: postcode?.trim(),
    state: state?.trim(),
    street,
    streetNo: streetNo?.trim(),
    streetType: streetType?.trim(),
    suburb: suburb?.trim(),
    unitNo: unitNo?.trim(),
    domainApiId,
    // Make sure these are not sent as input
    isProvidedByUser: false,
  };
}
