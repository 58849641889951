import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';

import {
  AppStackNavigationProps,
  ExpensesWizardStackParams,
} from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { ExpensesWizardMonthlyExpensesDetails } from '../screens/ExpensesWizardMonthlyExpensesDetails';
import { ExpensesWizardMonthlyExpensesTypes } from '../screens/ExpensesWizardMonthlyExpensesTypes';
import { ExpensesFormProvider } from '../utils/ExpensesFormProvider';
import { ExpensesTypeFormInitialValues } from '../utils/forms';

export type ExpensesWizardStackNavigationProp =
  AppStackNavigationProps<Screen.YOUR_EXPENSES_V2_WIZARD>;

export type ExpensesWizardStackNavigation<
  T extends keyof ExpensesWizardStackParams,
> = CompositeNavigationProp<
  StackNavigationProp<ExpensesWizardStackParams, T>,
  ExpensesWizardStackNavigationProp
>;
export type ExpensesWizardStackRoute<
  T extends keyof ExpensesWizardStackParams,
> = RouteProp<ExpensesWizardStackParams, T>;

export type ExpensesWizardScreenProps<
  T extends keyof ExpensesWizardStackParams,
> = {
  navigation: ExpensesWizardStackNavigation<T>;
  route: RouteProp<ExpensesWizardStackParams, T>;
};

export function getExpensesWizardInitialValues() {
  return {
    expenses: ExpensesTypeFormInitialValues,
  };
}

export const ExpensesWizardStack =
  createStackNavigator<ExpensesWizardStackParams>();

export function ExpensesWizardNavigator() {
  return (
    <ExpensesFormProvider initialValues={getExpensesWizardInitialValues()}>
      <ExpensesWizardStack.Navigator
        screenOptions={DefaultModalV2NavigatorOptions}
      >
        <ExpensesWizardStack.Screen
          name={Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS}
          component={ExpensesWizardMonthlyExpensesDetails}
          options={{
            title: getPageTitle(
              Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
            ),
          }}
        />
        <ExpensesWizardStack.Screen
          name={Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES}
          component={ExpensesWizardMonthlyExpensesTypes}
          options={{
            title: getPageTitle(
              Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES,
            ),
          }}
        />
      </ExpensesWizardStack.Navigator>
    </ExpensesFormProvider>
  );
}
