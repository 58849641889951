import { Box, BoxProps } from '../../ui/atoms/Box';
import { FlexSlider, FlexSliderProps } from '../../ui/molecules/FlexSlider';
import {
  CurrencyInputRow,
  Props as CurrencyInputRowProps,
} from './CurrencyInputRow';

export type CurrencySliderInputRowProps = CurrencyInputRowProps &
  BoxProps &
  FlexSliderProps;

export function CurrencySliderInputRow({
  min,
  max,
  onChangeText,
  value,
  label,
  placeholder,
  ...otherProps
}: CurrencySliderInputRowProps) {
  return (
    <Box borderRadius="card" bg="shapeBg" {...otherProps}>
      <CurrencyInputRow
        label={label}
        placeholder={placeholder}
        textAlign="right"
        last
        value={value}
        onChangeText={onChangeText}
      />
      <Box mx="m" py="s" overflow="visible">
        <FlexSlider
          value={value}
          onValueChange={onChangeText}
          min={min}
          max={max}
        />
      </Box>
    </Box>
  );
}
