import { connect } from 'formik';
import {
  withFocus,
  withFormikControl,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
} from 'react-native-formik';
import { compose } from 'recompose';

import { Box } from '../../ui/atoms/Box';
import { CheckBox, Props as CheckboxProps } from '../../ui/molecules/CheckBox';
import {
  Props as SwitchRowProps,
  SwitchRow,
} from '../../ui/molecules/SwitchRow';
import {
  InputField,
  Props as InputFieldProps,
} from '../../ui/organisms/InputField';
import {
  MultilineTextInputRow,
  Props as TextInputRowProps,
  TextInputRow,
} from '../../ui/organisms/TextInputRow';
import {
  CheckboxInputForFormik,
  CheckboxInputForFormikProps,
} from '../../ui/v2/Checkbox';
import {
  CurrencyInput as CurrencyInputV2,
  CurrencyInputV2Props,
} from '../../ui/v2/CurrencyInput';
import { DateInputForFormik } from '../../ui/v2/DateInput';
import { DateInputForFormikProps } from '../../ui/v2/DateInputTypes';
import {
  FormikAwareInstitutionInput,
  InstitutionInputPropsForFormik,
} from '../../ui/v2/InstitutionInput';
import {
  MultilineTextInput as MultilineTextInputV2,
  MultilineTextInputV2Props,
} from '../../ui/v2/MultilineTextInput';
import {
  PercentInput as PercentInputV2,
  PercentInputV2Props,
} from '../../ui/v2/PercentInput';
import { PhoneInputForFormik } from '../../ui/v2/PhoneInput';
import { PhoneInputForFormikProps } from '../../ui/v2/PhoneInputTypes';
import {
  FormikAwarePropertyInput,
  PropertyInputPropsForFormik,
} from '../../ui/v2/PropertyInput';
import {
  RadioItemForFormik,
  RadioItemForFormikProps,
} from '../../ui/v2/RadioGroup';
import {
  SelectForFormik,
  SelectForFormikProps,
} from '../../ui/v2/Select/SelectForFormik';
import {
  FormikAwareSuburbAutocompleteInput,
  SuburbAutocompleteInputPropsForFormik,
} from '../../ui/v2/SuburbAutocompleteInput';
import {
  TextInput as TextInputV2,
  TextInputV2Props,
} from '../../ui/v2/TextInput';
import {
  FormikAwareTextPickerInput,
  TextPickerInputPropsForFormik,
} from '../../ui/v2/TextPickerInput';
import { handleTextInput, HandleTextInputProps } from '../../utils/formHelpers';
import { PostcodeField, Props as PostcodeFieldProps } from '../PostcodeField';
import { AmountPicker, Props as AmountPickerProps } from './AmountPicker';
import {
  CollapsibleSelectionRow,
  Props as CollapsibleSelectionRowProps,
} from './CollapsibleSelectionRow';
import {
  CurrencyInputRow,
  Props as CurrencyInputRowProps,
} from './CurrencyInputRow';
import {
  CurrencyInputRowWithSuffixSelection,
  Props as CurrencyInputRowWithSuffixSelectionProps,
} from './CurrencyInputRowWithSuffixSelection';
import {
  CurrencySliderInputRow,
  CurrencySliderInputRowProps,
} from './CurrencySliderInputRow';
import { DatePickerRow, Props as DatePickerProps } from './DatePickerRow';
import FileInputRow, { FileInputRowProps } from './FileInputRow';
import {
  PaymentAccountRow,
  Props as PaymentAccountRowProps,
} from './PaymentAccountRow';
import { PhoneInputRow, Props as PhoneInputProps } from './PhoneInputRow';
import {
  Props as SegmentedControlProps,
  SegmentedControl,
} from './SegmentedControl';
import {
  Props as SelectionRowGroupProps,
  SelectionRowGroup,
} from './SelectionRowGroup';
import { Props as StepperRowProps, StepperRow } from './StepperRow';
import { TermSliderRow, TermSliderRowProps } from './TermSliderRow';

export type FieldProps = {
  name: string;
  type?: 'email' | 'password' | 'digits' | 'name';
};

export type FormCurrencyInputProps = CurrencyInputRowProps & FieldProps;
const FormCurrencyInput = compose<
  CurrencyInputRowProps,
  FormCurrencyInputProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(CurrencyInputRow);

type FormCurrencySliderInputProps = CurrencySliderInputRowProps & FieldProps;
const FormCurrencySliderInput = compose<
  CurrencySliderInputRowProps,
  FormCurrencySliderInputProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(CurrencySliderInputRow);

const FormInputField = compose<InputFieldProps, InputFieldProps & FieldProps>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(InputField);

const FormTermSliderRow = compose<
  TermSliderRowProps,
  TermSliderRowProps & FieldProps
>(withFormikControl)(TermSliderRow);

const FormTextInput = compose<
  TextInputRowProps,
  TextInputRowProps & FieldProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(TextInputRow);

const FormMultilineTextInput = compose<
  TextInputRowProps,
  TextInputRowProps & FieldProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(MultilineTextInputRow);

const FormSwitch = compose<SwitchRowProps, SwitchRowProps & FieldProps>(
  withFormikControl,
)(SwitchRow);

const FormDatePicker = compose<DatePickerProps, DatePickerProps & FieldProps>(
  withFormikControl,
)(DatePickerRow);

const FormPaymentAccount = compose<
  PaymentAccountRowProps,
  PaymentAccountRowProps & FieldProps
>(withFormikControl)(PaymentAccountRow);

const FormPhoneInput = compose<PhoneInputProps, PhoneInputProps & FieldProps>(
  withFormikControl,
)(PhoneInputRow);

export type FormSelectionProps<Value> = SelectionRowGroupProps<Value> &
  FieldProps;

const FormSelection = compose<
  SelectionRowGroupProps<string | boolean>,
  FormSelectionProps<string | boolean>
>(withFormikControl)(SelectionRowGroup);

const FormCollapsibleSelectionRow = compose<
  CollapsibleSelectionRowProps,
  CollapsibleSelectionRowProps & FieldProps
>(withFormikControl)(CollapsibleSelectionRow);

const FormCheckBox = compose<CheckboxProps, CheckboxProps & FieldProps>(
  withFormikControl,
)(CheckBox);

const FormSegmentedControl = compose<
  SegmentedControlProps,
  SegmentedControlProps & FieldProps
>(withFormikControl)(SegmentedControl);

const FormAmountPicker = compose<
  AmountPickerProps,
  AmountPickerProps & FieldProps
>(handleTextInput)(AmountPicker);

const FormPostcodeField = compose<
  PostcodeFieldProps,
  PostcodeFieldProps & FieldProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(PostcodeField);

const FormCurrencyInputWithSuffixSelection = compose<
  CurrencyInputRowWithSuffixSelectionProps,
  CurrencyInputRowWithSuffixSelectionProps & FieldProps
>(
  handleTextInput,
  withNextInputAutoFocusInput,
)(CurrencyInputRowWithSuffixSelection);

const FormStepper = compose<StepperRowProps, StepperRowProps & FieldProps>(
  withFormikControl,
)(StepperRow);

const FormFileInputRow = compose<
  FileInputRowProps,
  FileInputRowProps & Pick<FieldProps, 'name'>
>(withFormikControl)(FileInputRow);

const Form = withNextInputAutoFocusForm(Box, { submitAfterLastInput: false });

/** V2 Inputs */

const FormTextInputV2 = compose<
  TextInputV2Props,
  TextInputV2Props &
    FieldProps &
    Pick<
      HandleTextInputProps,
      'alphaNumericOnly' | 'escapeEmoji' | 'escapeCharsBy'
    >
>(handleTextInput)(TextInputV2);

const FormMultilineTextInputV2 = compose<
  MultilineTextInputV2Props,
  MultilineTextInputV2Props & FieldProps
>(handleTextInput)(MultilineTextInputV2);

const FormCurrencyInputV2 = compose<
  CurrencyInputV2Props,
  CurrencyInputV2Props & FieldProps
>(handleTextInput)(CurrencyInputV2);

const FormPercentInputV2 = compose<
  PercentInputV2Props,
  PercentInputV2Props & FieldProps
>(handleTextInput)(PercentInputV2);

const FormPhoneInputV2 = compose<
  PhoneInputForFormikProps,
  PhoneInputForFormikProps & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(PhoneInputForFormik);

const FormDateInputV2 = compose<
  DateInputForFormikProps,
  DateInputForFormikProps & FieldProps
>(handleTextInput)(DateInputForFormik);

const FormRadioInputV2 = compose<
  RadioItemForFormikProps,
  RadioItemForFormikProps & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(RadioItemForFormik);

const FormCheckboxInputV2 = compose<
  CheckboxInputForFormikProps,
  CheckboxInputForFormikProps & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(CheckboxInputForFormik);

const FormSelectV2 = compose<
  SelectForFormikProps,
  SelectForFormikProps & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(SelectForFormik);

const FormInstitutionInputV2 = compose<
  InstitutionInputPropsForFormik,
  InstitutionInputPropsForFormik & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(FormikAwareInstitutionInput);

const FormPropertyInputV2 = compose<
  PropertyInputPropsForFormik,
  PropertyInputPropsForFormik & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(FormikAwarePropertyInput);

const FormTextPickerInputV2 = compose<
  TextPickerInputPropsForFormik,
  TextPickerInputPropsForFormik & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(FormikAwareTextPickerInput);

const FormSuburbAutocompleteInput = compose<
  SuburbAutocompleteInputPropsForFormik,
  SuburbAutocompleteInputPropsForFormik & FieldProps
>(
  withFocus,
  withFormikControl,
  connect,
)(FormikAwareSuburbAutocompleteInput);

export {
  FormAmountPicker,
  FormCheckBox,
  FormCurrencyInput,
  FormCurrencyInputWithSuffixSelection,
  FormTextInput,
  FormTextInputV2,
  FormMultilineTextInputV2,
  FormCurrencyInputV2,
  FormPercentInputV2,
  FormPhoneInputV2,
  FormDateInputV2,
  FormRadioInputV2,
  FormCheckboxInputV2,
  FormSelectV2,
  FormInstitutionInputV2,
  FormPropertyInputV2,
  FormTextPickerInputV2,
  FormInputField,
  Form,
  FormDatePicker,
  FormSwitch,
  FormPaymentAccount,
  FormPhoneInput,
  FormStepper,
  FormCollapsibleSelectionRow,
  FormSelection,
  FormSegmentedControl,
  FormPostcodeField,
  FormCurrencySliderInput,
  FormTermSliderRow,
  FormMultilineTextInput,
  FormFileInputRow,
  FormSuburbAutocompleteInput,
};
