/* eslint-disable no-alert */

import { AlertButton, AlertStatic } from 'react-native';

// Might need prettier alert for web
export const Alert: Pick<AlertStatic, 'alert'> = {
  alert(title: string, message?: string, buttons?: AlertButton[]): void {
    if (buttons == null || buttons.length === 0) {
      window.alert([title, message].filter(Boolean).join('\n'));
      return;
    }

    const result = window.confirm([title, message].filter(Boolean).join('\n'));

    if (result) {
      const confirm = buttons.find(({ style }) => style !== 'cancel');
      confirm?.onPress?.();
      return;
    }

    const cancel = buttons.find(({ style }) => style === 'cancel');
    cancel?.onPress?.();
  },
};
