import { Pressable, SxProp, Text, View } from 'dripsy';
import { useMemo } from 'react';
import * as React from 'react';

import { isLast } from '../../../utils/arrayHelpers';
import { isWeb } from '../../../utils/platformUtils';
import { makeTestId } from '../../../utils/stringHelpers';

function Separator() {
  return (
    <View
      sx={{
        borderBottomWidth: '$1',
        borderBottomColor: '$border',
        mx: '$16',
      }}
    />
  );
}

const onMouseProp = isWeb
  ? {
      // eslint-disable-next-line
      // @ts-ignore - onMouseDown is forwarded to View, but not exposed in Pressable
      onMouseDown: (event) => {
        event.preventDefault();
      },
    }
  : null;

export type SuggestionsResultProps<TValue> = {
  containerSx?: SxProp;
  searchResult: Array<TValue>;
  showResults: boolean;
  suggestionsTestIDPrefix?: string;
  isLoading?: boolean;
  onResultItemPress: (data: TValue) => void;
  selectedValue?: TValue;
};

export function makeSuggestionsResult<TValue>(
  SuggestionItemComponent: React.ComponentType<{ data: TValue }>,
  {
    getSuggestionItemKey,
    isSelected,
  }: {
    getSuggestionItemKey: (item: TValue) => string;
    isSelected: (suggestionItem: TValue, selectedValue?: TValue) => boolean;
  },
) {
  function SuggestionsResult({
    containerSx,
    searchResult,
    showResults,
    onResultItemPress,
    suggestionsTestIDPrefix,
    isLoading,
    selectedValue,
  }: SuggestionsResultProps<TValue>) {
    const Results = useMemo(() => {
      if (isLoading || searchResult.length === 0) {
        return (
          <View
            sx={{
              backgroundColor: '$inputBackground',
              justifyContent: 'center',
              py: '$14',
              px: '$16',
            }}
            focusable={false}
          >
            <Text variant="caption">
              {isLoading
                ? t('Content.Common.Placeholder.Loading')
                : t('Content.Common.Placeholder.NoResultsFound')}
            </Text>
          </View>
        );
      }

      return (
        <>
          {searchResult.map((data, index, results) => {
            const last = isLast(results, index);

            return (
              <Pressable
                key={getSuggestionItemKey(data)}
                onPress={() => onResultItemPress(data)}
                // This is here to prevent blur event when selecting the item.
                // We'll call blur imperatively after we set the values
                {...onMouseProp}
                focusable={false}
                testID={makeTestId([
                  suggestionsTestIDPrefix,
                  getSuggestionItemKey(data),
                ])}
              >
                <View
                  sx={{
                    justifyContent: 'center',
                    backgroundColor: isSelected(data, selectedValue)
                      ? '$secondaryBackground'
                      : undefined,
                    py: '$10',
                    px: '$16',
                  }}
                >
                  <SuggestionItemComponent data={data} />
                </View>
                {!last && <Separator />}
              </Pressable>
            );
          })}
        </>
      );
    }, [
      isLoading,
      onResultItemPress,
      searchResult,
      selectedValue,
      suggestionsTestIDPrefix,
    ]);

    if (!showResults) {
      return null;
    }

    return <View sx={containerSx}>{Results}</View>;
  }

  return SuggestionsResult;
}
