import { ReactNode } from 'react';

import { Box } from '../ui/atoms/Box';
import {
  Props as ScreenContainerProps,
  ScreenContainer,
} from '../ui/atoms/ScreenContainer';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { StyledText, StyledTextProps } from '../ui/atoms/StyledText';

type Props = ScreenContainerProps & {
  title: string;
  caption: string;
  children?: ReactNode;
  onCaptionLinkPress?: () => void;
  captionLink?: string;
  hideUnloanLogo?: boolean;
  titleFontWeight?: StyledTextProps['fontWeight'];
};

export function ErrorFallback({
  title,
  caption,
  children,
  onCaptionLinkPress,
  captionLink,
  hideUnloanLogo,
  titleFontWeight,
  ...props
}: Props) {
  return (
    <ScreenContainer {...props} height="100%" alignItems="center">
      <Box alignItems="center" justifyContent="center" mx="m" flex={1}>
        {hideUnloanLogo ? null : (
          <StyledIcon
            size="l"
            name="unloanLogo"
            family="svg"
            mt="l"
            mb="3xl"
            alignSelf="center"
          />
        )}
        <StyledIcon
          family="svg"
          name="warningIcon"
          size="xl"
          m="m"
          color="alert"
        />
        <StyledText
          variant="header"
          textAlign="center"
          fontWeight={titleFontWeight}
        >
          {title}
        </StyledText>
        <StyledText my="m" textAlign="center">
          {caption}
          {onCaptionLinkPress || captionLink ? (
            <StyledText color="link" onPress={onCaptionLinkPress}>
              {captionLink}
            </StyledText>
          ) : null}
        </StyledText>
        {children}
      </Box>
    </ScreenContainer>
  );
}
