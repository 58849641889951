import { RouteProp, useIsFocused } from '@react-navigation/native';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { HomeTabNavigationProps } from '../../Home/navigation/types';
import { HomeDashboardStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { isWeb as defaultIsWeb } from '../../utils/platformUtils';
import { HomeLoanModal } from '../types';

const DEFAULT_NAVIGATE_TIMEOUT = 150;

const screenToModalMappings = {
  [Screen.HOME_LOAN_WITHDRAWAL]: HomeLoanModal.TRANSFER,
};

const modalToScreenMappings = Object.keys(screenToModalMappings).reduce(
  (prev, key) => ({
    ...prev,
    [screenToModalMappings[key as keyof typeof screenToModalMappings]]: key,
  }),
  {} as Partial<Record<HomeLoanModal, keyof typeof screenToModalMappings>>,
);

export type UseShowHomeLoanModalProps = {
  navigation: HomeTabNavigationProps<Screen.HOME_LOAN>;
  route: RouteProp<HomeDashboardStackParams, Screen.HOME_LOAN>;
  isWithdrawDisabled: boolean;
  loading: boolean;
  isWeb?: boolean;
};

export const useShowHomeLoanModal = ({
  navigation,
  route,
  isWithdrawDisabled,
  loading: initialLoading,
  isWeb = defaultIsWeb,
}: UseShowHomeLoanModalProps) => {
  const { flags } = useContext(FeatureFlagsContext);

  const transferModalV2Enabled = flags.ENABLE_TRANSFER_MODAL_V2;

  const loading = initialLoading;

  const { cbaAccountId, modal } = route.params;

  const navigateToHomeLoanScreen = useCallback(
    (screen: keyof typeof screenToModalMappings) =>
      navigation.setParams({ modal: screenToModalMappings[screen] }),
    [navigation],
  );

  const modalIsOpenRef = useRef(false);

  const navigateToHomeLoanModal = useCallback(
    (screen: keyof typeof screenToModalMappings) => {
      modalIsOpenRef.current = true;

      // Timeout is needed to ensure the route has been modified before navigating again
      setTimeout(
        () =>
          navigation.navigate(Screen.HOME_LOAN_MODAL, {
            screen:
              screen === Screen.HOME_LOAN_WITHDRAWAL && transferModalV2Enabled
                ? Screen.HOME_LOAN_WITHDRAWAL_V2
                : screen,
            params: {
              cbaAccountId,
            },
          }),
        DEFAULT_NAVIGATE_TIMEOUT,
      );
    },
    [navigation, transferModalV2Enabled, cbaAccountId],
  );

  const isFocused = useIsFocused();

  useEffect(() => {
    // Prevent opening the modal when screen is not focused.
    if (!isFocused) {
      return;
    }

    if (loading) {
      return;
    }

    if (!modal) {
      return;
    }

    const screen = modalToScreenMappings[modal];

    if (!screen) {
      return;
    }

    if (
      (screen === Screen.HOME_LOAN_WITHDRAWAL && isWithdrawDisabled) ||
      modalIsOpenRef.current
    ) {
      modalIsOpenRef.current = false;

      setTimeout(() => {
        navigation.setParams({ modal: HomeLoanModal.NONE });
      }, DEFAULT_NAVIGATE_TIMEOUT);
    } else {
      navigateToHomeLoanModal(screen);
    }
  }, [
    isFocused,
    isWithdrawDisabled,
    loading,
    modal,
    navigateToHomeLoanModal,
    navigation,
    route.name,
  ]);

  return {
    showHomeLoanModal: isWeb
      ? navigateToHomeLoanScreen
      : navigateToHomeLoanModal,
  };
};
