import {
  addDays,
  format,
  formatDistanceStrict,
  isValid,
  parse,
  parseISO,
  startOfDay,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enAU } from 'date-fns/locale';
import * as Localization from 'expo-localization';
import { Platform } from 'react-native';

import { captureException } from '../sentry';

const MIN_AGE_REQUIREMENT = 18;

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

export enum AustralianTimezones {
  ADELAIDE = 'Australia/Adelaide',
  BRISBANE = 'Australia/Brisbane',
  DARWIN = 'Australia/Darwin',
  SYDNEY = 'Australia/Sydney',
  PERTH = 'Australia/Perth',
}

export function getMaximumDOB(
  minAge: number = MIN_AGE_REQUIREMENT,
  today: Date = new Date(),
): Date {
  const maxDate = new Date(today);
  maxDate.setMonth(maxDate.getMonth() - minAge * 12);
  maxDate.setHours(0, 0, 0);
  return maxDate;
}

export function getClientTimezone(): string {
  const timezone =
    Platform.OS === 'web'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : Localization.getCalendars()[0]?.timeZone;

  return timezone || AustralianTimezones.SYDNEY;
}

export function getMinimumDate() {
  const minDate = new Date(1000, 0, 1);
  return minDate;
}

export const getMinimumDateToday = () => startOfDay(addDays(Date.now(), 1));
export const getMaximumDate = () => addDays(new Date(), 30);

export function limit(value: string, max: string): string {
  let newValue = value;
  if (value.length === 1 && Number(value[0]) > Number(max[0])) {
    //  Convert first digit to second digit when provided first digit is bigger than first digit of maximum value
    newValue = value.padStart(2, '0');
  }

  if (value.length === 2) {
    if (Number(value) === 0) {
      newValue = '01';
    } else if (value > max) {
      newValue = max;
    }
  }

  return newValue;
}

export const monthLength = {
  '01': '31',
  '02': '28',
  '03': '31',
  '04': '30',
  '05': '31',
  '06': '30',
  '07': '31',
  '08': '31',
  '09': '30',
  '10': '31',
  '11': '30',
  '12': '31',
};

export const isLeapYear = (year: number): boolean =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const getTotalDays = (month: string, year: string): string => {
  if (month?.length === 2 && Object.keys(monthLength).includes(month)) {
    if (isLeapYear(Number(year)) && Number(month) === 2) {
      return '29';
    }

    return monthLength[month as keyof typeof monthLength];
  }

  return '31';
};

export function convertMaskedStringToDate(
  value: string,
  separator = '/',
): null | Date {
  const { isDayValid, isMonthValid, isYearValid } = isMaskedDateValid(value);
  if (!isDayValid || !isMonthValid || !isYearValid) {
    // Make sure if the date is completed
    return null;
  }
  const [date, month, year] = value.split(separator);
  const convertedDate = new Date(`${month}/${date}/${year}`);
  return convertedDate;
}

type Options = {
  separator?: string;
  dayPlaceholder?: string;
  monthPlaceholder?: string;
  yearPlaceholder?: string;
};

export function isMaskedDateValid(
  value: string,
  opts?: Options,
): { isDayValid: boolean; isMonthValid: boolean; isYearValid: boolean } {
  const {
    separator = '/',
    dayPlaceholder = 'd',
    monthPlaceholder = 'm',
    yearPlaceholder = 'y',
  } = opts ?? {};
  const [date, month, year] = value.split(separator);
  return {
    isDayValid: !(date ?? '').includes(dayPlaceholder),
    isMonthValid: !(month ?? '').includes(monthPlaceholder),
    isYearValid: !(year ?? '').includes(yearPlaceholder),
  };
}

export function getDaysUntilDate(date: Date): number {
  const timeDiff = date.getTime() - new Date().getTime();
  return Math.round(timeDiff / ONE_DAY);
}

const formatDistanceLocale = {
  lessThanXSeconds: {
    one: 'less than a second',
    other: 'less than {{count}} seconds',
  },

  xSeconds: {
    one: '1 second',
    other: '{{count}} seconds',
  },

  halfAMinute: 'half a minute',

  lessThanXMinutes: {
    one: 'less than a minute',
    other: 'less than {{count}} minutes',
  },

  xMinutes: {
    one: '1 minute',
    other: '{{count}} minutes',
  },

  aboutXHours: {
    one: 'about 1 hour',
    other: 'about {{count}} hours',
  },

  xHours: {
    one: '1 hour',
    other: '{{count}} hours',
  },

  xDays: {
    one: '1 day',
    other: '{{count}} days',
  },

  aboutXWeeks: {
    one: 'about 1 week',
    other: 'about {{count}} weeks',
  },

  xWeeks: {
    one: '1 week',
    other: '{{count}} weeks',
  },

  aboutXMonths: {
    one: 'about 1 month',
    other: 'about {{count}} months',
  },

  xMonths: {
    one: '1 month',
    other: '{{count}} months',
  },

  aboutXYears: {
    one: 'about 1 year',
    other: 'about {{count}} years',
  },

  xYears: {
    one: '1 year',
    other: '{{count}} years',
  },

  overXYears: {
    one: 'over 1 year',
    other: 'over {{count}} years',
  },

  almostXYears: {
    one: 'almost 1 year',
    other: 'almost {{count}} years',
  },
};

const formatDistance = (
  date: Date,
  token: keyof typeof formatDistanceLocale,
  count: number,
  options?: {
    addSuffix?: boolean;
    comparison?: -1 | 0 | 1;
    dateFormat?: string;
  },
) => {
  let result;

  if (
    token === 'halfAMinute' ||
    token === 'xSeconds' ||
    token === 'lessThanXSeconds'
  ) {
    return 'Just now';
  }
  if (
    token === 'xDays' &&
    count === 1 &&
    options?.comparison &&
    options.comparison < 0
  ) {
    return 'Yesterday';
  }
  // Show full date if distance > 7 days
  if (
    token === 'aboutXWeeks' ||
    token === 'xWeeks' ||
    token === 'aboutXMonths' ||
    token === 'xMonths' ||
    token === 'aboutXYears' ||
    token === 'xYears' ||
    token === 'overXYears' ||
    token === 'almostXYears' ||
    (token === 'xDays' && count > 6)
  ) {
    return format(date, options?.dateFormat || 'd MMM yyyy');
  }
  const tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === 'string') {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString());
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return `in ${result}`;
    }
    return `${result} ago`;
  }

  return result;
};

export function formatDistanceToNow(date: Date, dateFormat?: string): string {
  const now = new Date();

  const formatted = formatDistanceStrict(date, now, {
    locale: {
      ...enAU,
      formatDistance: (token, count, options) =>
        formatDistance(date, token, count, { ...options, dateFormat }),
    },
    addSuffix: true,
  });

  return formatted;
}

export const DATE_INPUT_FORMAT = 'yyyy-MM-dd';
export function safelyFormatDate(
  value: Date | string | null | undefined,
  dateFormat = DATE_INPUT_FORMAT,
) {
  if (!value) {
    return null;
  }

  const dateToFormat = typeof value === 'string' ? parseISO(value) : value;

  if (!isValid(dateToFormat)) {
    return null;
  }
  try {
    return format(dateToFormat, dateFormat);
  } catch (e: unknown) {
    captureException('Unable to format date', { value, dateFormat }, e);
    return null;
  }
}

export function safelyParseDate(
  dateString: string | null | undefined,
  formatString: string,
  referenceDate = new Date(),
) {
  if (dateString == null) {
    return null;
  }

  try {
    const parsed = parse(dateString, formatString, referenceDate);
    return isValid(parsed) ? parsed : null;
  } catch (error: unknown) {
    captureException(
      `Failed to parse date: ${dateString} with format: ${formatString}`,
      { dateString, formatString, referenceDate },
      error,
    );
    return null;
  }
}

export function formatExpiryCountdown(
  expireAt: Date,
  {
    referenceDate = new Date(),
  }: Partial<{
    referenceDate: Date;
  }> = {},
) {
  const timeDiff = expireAt.getTime() - referenceDate.getTime();

  if (timeDiff > ONE_DAY) {
    const days = Math.floor(timeDiff / ONE_DAY);
    return days === 1
      ? t('Content.ExpiryCountdown.ValidForOneDay')
      : t('Content.ExpiryCountdown.ValidForXDays', { x: days });
  }

  if (timeDiff > ONE_HOUR) {
    const hours = Math.floor(timeDiff / ONE_HOUR);
    return hours === 1
      ? t('Content.ExpiryCountdown.ValidForOneHour')
      : t('Content.ExpiryCountdown.ValidForXHours', { x: hours });
  }

  if (timeDiff > 0) {
    return t('Content.ExpiryCountdown.ValidForLessThanAnHour');
  }

  return t('Content.ExpiryCountdown.Expired');
}
