import { Text, View } from 'dripsy';
import { useCallback, useContext, useMemo } from 'react';
import { RefreshControl as RNRefreshControl } from 'react-native';

import { TestID } from '../../../testID/constants';
import { useUser } from '../../Auth/hooks';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import ScheduledMaintenance from '../../components/ScheduledMaintenance';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import { AccountPermissionsSection } from '../../HomeLoan/components/AccountPermissionsSection';
import { LoanVariationStatusCard } from '../../LoanVariation/components/LoanVariationStatusCard';
import { Screen } from '../../navigation/types/screens';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { LoadingRow, LoadingRowGroup } from '../../ui/molecules/LoadingRow';
import { useTheme } from '../../ui/theme';
import { isLast } from '../../utils/arrayHelpers';
import { generateGreeting } from '../../utils/generateGreeting';
import { useIsDesktop, useView } from '../../utils/hooks/useBreakpoint';
import { isWeb } from '../../utils/platformUtils';
import { useDashboardQuery } from '../utils/useDashboardQuery';
import { useHomeNavigation } from '../utils/useHomeNavigation';
import { useNativeAppApplicationNavigation } from '../utils/useNativeAppApplicationNavigation';
import { ActiveLoanCard } from './ActiveLoanCard';
import { ApplicationTracker } from './ApplicationTracker/ApplicationTracker';
import { ApplyLoanCard } from './ApplyLoanCard/ApplyLoanCard';
import { DashboardLoadingLoanCard } from './DashboardLoadingLoanCard';
import { DashboardUpcomings } from './DashboardUpcomings';
import { TransferDisabledWarningRow } from './TransferDisabledWarningRow';

const SCREEN_COLUMN_WIDTH = 420;

type Props = {
  currentTime: Date;
};

const NoUpcomingTransactions = () => (
  <Box row alignItems="center">
    <StyledIcon
      family="svg"
      name="tick"
      size="xs"
      color="overlayContent"
      roundBg
    />
    <StyledText variant="caption" ml="xs" color="disabledContent">
      {t('Content.HomeLoan.NoUpcomingTransactions')}
    </StyledText>
  </Box>
);

const DashboardHeader = ({ title }: { title: string }) => {
  const isDesktop = useIsDesktop();
  const marginStyle: BoxProps = isWeb
    ? {
        mt: { desktop: 'm', mobile: 0 },
        mb: isDesktop ? '2xl' : 'l',
      }
    : { mt: 'm', mb: 'm' };
  return (
    <Box
      alignItems="flex-start"
      width="100%"
      maxWidth={isDesktop ? '100%' : SCREEN_COLUMN_WIDTH}
      {...marginStyle}
    >
      <Text variant="lHeader" role="heading">
        {title}
      </Text>
    </Box>
  );
};

function DashboardBase({ currentTime }: Props) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const { isMobileNativeView, isMobileWebView } = useView();
  const { user } = useUser();
  const { flags } = useContext(FeatureFlagsContext);

  const navigation = useHomeNavigation();
  const userFirstName = user?.identity_profile?.first_name;

  const {
    loanAccountsForDisplay,
    appliedLoansForDisplay,
    loanAccountsLoading,
    loanApplicationsLoading,
    loanApplicationsError,
    loanAccountsError,
    refetchAll,
    isRefetching,
  } = useDashboardQuery();

  const isEmpty =
    appliedLoansForDisplay.length + loanAccountsForDisplay.length === 0;

  const { onSetupLoan: onSetupLoanPress } = useNativeAppApplicationNavigation();

  const onActiveLoanPress = useCallback(
    (cbaAccountId: string) =>
      navigation.navigate(Screen.HOME_LOAN, {
        cbaAccountId,
      }),
    [navigation],
  );

  const navigateToLoanVariationApprovalPage = useCallback(
    (cbaAccountId: string) => {
      navigation.navigate(
        Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN,
        {
          cbaAccountId,
        },
      );
    },
    [navigation],
  );

  const navigationToAccountPermissionsApprovalScreen = useCallback(
    (accountId: string) => {
      navigation.navigate(Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN, {
        cbaAccountId: accountId,
      });
    },
    [navigation],
  );

  const combinedLoading = loanAccountsLoading || loanApplicationsLoading;

  const isAccountPermissionsEnabled =
    !!flags[FeatureFlag.EnableAccountPermissions];

  const applyLoan = useMemo(
    () => (
      <>
        {!loanAccountsLoading && !isEmpty ? (
          <>
            {!isDesktop && <Separator spacer />}
            <Text variant="sHeader" sx={{ my: '$16' }}>
              {t('Content.Home.ApplyForAnotherLoan')}
            </Text>
          </>
        ) : null}
        {!loanAccountsLoading && isEmpty ? (
          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.Home.ApplyForAHomeLoan')}
          </Text>
        ) : null}
        {!loanAccountsLoading ? (
          <ApplyLoanCard onPress={onSetupLoanPress} />
        ) : null}
      </>
    ),
    [isDesktop, isEmpty, loanAccountsLoading, onSetupLoanPress],
  );

  const loansColumn = useMemo(
    () => (
      <Box overflow="visible" width="100%" maxWidth={SCREEN_COLUMN_WIDTH}>
        {appliedLoansForDisplay.length ? (
          <View sx={{ mb: '$16' }}>
            <Text variant="sHeader" sx={{ mb: '$16' }}>
              {t('Content.Home.Applications')}
            </Text>
            {loanApplicationsLoading ? (
              <DashboardLoadingLoanCard width="100%" mb="l" />
            ) : (
              appliedLoansForDisplay.map((appliedLoan) => (
                <ApplicationTracker
                  key={appliedLoan.loanApplication.id}
                  appliedLoan={appliedLoan}
                />
              ))
            )}
          </View>
        ) : null}

        {loanAccountsForDisplay.length ? (
          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.Home.Accounts')}
          </Text>
        ) : null}
        {loanAccountsLoading ? (
          <DashboardLoadingLoanCard
            width="100%"
            testID={TestID.Dashboard.LoanAccountLoading}
          />
        ) : (
          loanAccountsForDisplay.map((account, index) => (
            <ActiveLoanCard
              key={account.cba_account_id}
              cbaAccountId={account.cba_account_id}
              onPress={onActiveLoanPress}
              mb={isLast(loanAccountsForDisplay, index) ? undefined : 'xl'}
              testID={TestID.Dashboard.ActiveLoanCard}
            />
          ))
        )}
        {applyLoan}
      </Box>
    ),
    [
      appliedLoansForDisplay,
      applyLoan,
      loanAccountsForDisplay,
      loanAccountsLoading,
      loanApplicationsLoading,
      onActiveLoanPress,
    ],
  );

  const upcomingColumn = useMemo(
    () => (
      <Box overflow="visible" maxWidth={SCREEN_COLUMN_WIDTH} width="100%">
        {isAccountPermissionsEnabled
          ? loanAccountsForDisplay.map(({ id, cba_account_id }) => (
              <AccountPermissionsSection
                key={id}
                mt={0}
                mb="s"
                cbaAccountId={cba_account_id}
                loading={combinedLoading}
                onPress={() =>
                  navigationToAccountPermissionsApprovalScreen(cba_account_id)
                }
                hideWhenNoPendingRequest
              />
            ))
          : null}
        {loanAccountsForDisplay.map(({ cba_account_id }) => (
          <LoanVariationStatusCard
            key={cba_account_id}
            cbaAccountId={cba_account_id}
            loading={combinedLoading}
            onPress={() => navigateToLoanVariationApprovalPage(cba_account_id)}
          />
        ))}
        <Text variant="sHeader" sx={{ mb: '$16' }}>
          {t('Content.HomeLoan.UpcomingHeading')}
        </Text>
        {!loanAccountsLoading &&
          (loanAccountsForDisplay.length === 0 ? (
            <NoUpcomingTransactions />
          ) : (
            <>
              {loanAccountsForDisplay.map(({ cba_account_id }, index) => (
                <DashboardUpcomings
                  key={cba_account_id}
                  index={index}
                  cbaAccountId={cba_account_id}
                  numOfHomeLoans={loanAccountsForDisplay.length}
                />
              ))}
            </>
          ))}
        {loanAccountsLoading ? (
          <LoadingRowGroup>
            <LoadingRow />
          </LoadingRowGroup>
        ) : null}
        {!isDesktop && <Separator spacer />}
      </Box>
    ),
    [
      combinedLoading,
      isAccountPermissionsEnabled,
      isDesktop,
      loanAccountsForDisplay,
      loanAccountsLoading,
      navigateToLoanVariationApprovalPage,
      navigationToAccountPermissionsApprovalScreen,
    ],
  );

  const component = useMemo(
    () =>
      isDesktop ? (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          overflow="visible"
          width="100%"
        >
          {loansColumn}

          {upcomingColumn}
        </Box>
      ) : (
        <Box
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          overflow="visible"
          width="100%"
        >
          {upcomingColumn}

          {loansColumn}
        </Box>
      ),
    [isDesktop, loansColumn, upcomingColumn],
  );

  return (
    <UpliftScreenContainer
      showTopNavBar={isMobileNativeView}
      wideContentContainer
      refreshControl={
        <RNRefreshControl
          tintColor={theme.colors.secondaryContent}
          refreshing={isRefetching}
          onRefresh={refetchAll}
        />
      }
    >
      <Box height={isMobileWebView ? theme.sizes['2xl'] : 0} />

      <Box
        pb="m"
        overflow="visible"
        alignItems={isDesktop ? undefined : 'center'}
      >
        <ScreenErrorFallback
          error={loanApplicationsError}
          displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
          refetch={refetchAll}
          testID={TestID.Dashboard.ScreenError}
        >
          <DashboardHeader
            title={
              userFirstName
                ? `${generateGreeting(currentTime)}, ${userFirstName}`
                : generateGreeting(currentTime)
            }
          />

          {loanAccountsForDisplay.length > 0 ? (
            <ScheduledMaintenance mb="l" />
          ) : null}

          {!flags.ENABLE_TRANSFER_FEATURE && (
            <TransferDisabledWarningRow
              sx={{
                mb: '$8',
                width: '100%',
                maxWidth: isDesktop ? '100%' : SCREEN_COLUMN_WIDTH,
              }}
            />
          )}

          {loanAccountsError ? (
            <ErrorRow
              sx={{ mb: '$8' }}
              message={t('Content.Home.ErrorFetchingAccounts')}
              width="100%"
              maxWidth={isDesktop ? '100%' : SCREEN_COLUMN_WIDTH}
            />
          ) : null}
          {component}
        </ScreenErrorFallback>
      </Box>
    </UpliftScreenContainer>
  );
}

export const DashboardV2 = withAuthenticationRequired(DashboardBase);
