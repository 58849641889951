import { Pressable, SxProp, useDripsyTheme, View } from 'dripsy';
import * as React from 'react';

import { TestID } from '../../../../testID/constants';
import { Skeleton } from '../Skeleton';

type ListItemProps = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  line?: React.ReactNode;
  action?: React.ReactNode;
  testID?: string;
  sx?: SxProp;
  onAction?: () => void;
};

export const ListItem = ({
  icon,
  title,
  line,
  action,
  sx = {},
  testID,
  onAction = () => {},
}: ListItemProps) => (
  <View sx={{ flexDirection: 'row', ...sx }} testID={testID}>
    {icon || null}
    <View sx={{ flex: 1, justifyContent: 'center' }}>
      {title}
      {line || null}
    </View>
    {action ? (
      <Pressable
        sx={{ justifyContent: 'center', marginRight: '$16' }}
        onPress={onAction}
      >
        {action}
      </Pressable>
    ) : null}
  </View>
);

export function LoadingListItem(props: Omit<ListItemProps, 'title' | 'line'>) {
  const { theme } = useDripsyTheme();

  return (
    <ListItem
      sx={{ py: '$16', mx: '$16' }}
      title={
        <View testID={TestID.LoadingRow.Skeleton} sx={{ mb: '$4' }}>
          <Skeleton show width="60%" height={theme.space.$20} />
        </View>
      }
      line={
        <View testID={TestID.LoadingRow.Skeleton}>
          <Skeleton show width="40%" height={theme.space.$10} />
        </View>
      }
      {...props}
    />
  );
}
