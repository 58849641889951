import { memo } from 'react';

type Props = {
  link: string;
};

export const PdfPreview = memo(({ link }: Props) => (
  <object
    data={link}
    type="application/pdf"
    width="100%"
    height="100%"
    className="chromatic-ignore"
  >
    <embed
      src={link}
      type="application/pdf"
      width="100%"
      height="100%"
      className="chromatic-ignore"
    />
  </object>
));
