import { createRestyleComponent, createVariant } from '@shopify/restyle';
import { Pressable, SxProp } from 'dripsy';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { PressableProps } from 'react-native';

import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import { Theme } from '../theme';
import { Color, FontSize, FontWeight, Size } from '../types';
import { boxRestyleFunctions, BoxStyleProps } from '../utils/createBox';
import { buttonBackgroundTransition } from '../v2/constants';
import { Box } from './Box';
import { Spinner } from './Spinner';
import { IconFamilyName, StyledIcon } from './StyledIcon';
import { Props as StyledTextProps, StyledText } from './StyledText';

type StyleProps = BoxStyleProps & { variant?: keyof Theme['buttonVariants'] };

type BaseButtonProps = StyleProps &
  Omit<PressableProps, keyof StyleProps> & {
    children?: React.ReactNode;
    sx?: SxProp;
  };

const restyleFunctions = [
  ...boxRestyleFunctions,
  createVariant<Theme, 'buttonVariants'>({
    themeKey: 'buttonVariants',
    defaults: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'button',
      alignSelf: {
        // Why are we using different align-self on mobile vs tablet here?
        mobile: 'flex-start',
        tablet: 'center',
      },
      overflow: 'hidden',
      px: 'm',
      py: 'xs',
    },
  }),
];

export const BaseButton = createRestyleComponent<BaseButtonProps, Theme>(
  restyleFunctions,
  Pressable,
);

BaseButton.defaultProps = {
  variant: 'base',
};

export type ButtonColorVariantProps = Partial<
  | {
      primaryLight: true;
      secondary?: false;
      tertiary?: false;
    }
  | {
      primaryLight?: false;
      secondary: true;
      tertiary?: false;
    }
  | {
      primaryLight?: false;
      secondary?: false;
      tertiary: true;
    }
>;

export type Props = BaseButtonProps &
  ButtonColorVariantProps & {
    color?: Color;
    icon?: string;
    iconPosition?: 'right' | 'left' | 'top';
    showSpinner?: boolean;
    label?: string;
    labelVariant?: StyledTextProps['variant'];
    iconSize?: Size;
    iconFamily?: IconFamilyName;
    iconTestId?: string;
    fontSize?: FontSize;
    fontWeight?: FontWeight;
    outline?: boolean;
    bordered?: boolean;
    uppercaseLabel?: boolean;
  };

const primaryButtonStyles: Partial<Props> = {
  fontWeight: 'semiBold',
  color: 'buttonPrimaryContent',
  bg: 'buttonPrimaryBg',
};

const primaryLightButtonStyles: Partial<Props> = {
  fontWeight: 'semiBold',
  color: 'buttonPrimaryLightContent',
  bg: 'buttonPrimaryLightBg',
};

const secondaryButtonStyles: Partial<Props> = {
  fontWeight: 'semiBold',
  color: 'buttonSecondaryContent',
  bg: 'buttonSecondaryBg',
};

const tertiaryButtonStyles: Partial<Props> = {
  fontWeight: 'semiBold',
  color: 'buttonTertiaryContent',
  bg: 'buttonTertiaryBg',
};

const disabledButtonStyles: Partial<Props> = {
  color: 'disabledContent',
  bg: 'buttonDisabledBg',
};

const tertiaryDisabledButtonStyles: Partial<Props> = {
  color: 'disabledContent',
  bg: 'buttonTertiaryDisabledBg',
};

export const Button = React.memo<Props>(
  ({
    label,
    labelVariant,
    icon,
    iconPosition = 'left',
    iconSize = 'm',
    iconTestId,
    iconFamily,
    primaryLight,
    secondary,
    tertiary,
    outline,
    showSpinner,
    bg: bgProp,
    color: colorProp,
    fontWeight: fontWeightProp,
    uppercaseLabel,
    testID,
    onHoverIn: baseOnHoverIn,
    onHoverOut: baseOnHoverOut,
    onPressIn: baseOnPressIn,
    onPressOut: baseOnPressOut,
    sx: sxProps = {},
    ...props
  }) => {
    // introduce pillFontSize for backward compatibility
    const pillFontSize = useMemo(
      () => (props.variant === 'pill' ? props.fontSize ?? 's' : 's'),
      [props.fontSize, props.variant],
    );
    const fontSize = useMemo(() => props.fontSize ?? 'm', [props.fontSize]);
    const [isHover, setIsHover] = useState(false);

    const onHoverIn: Props['onHoverIn'] = (event) => {
      setIsHover(true);
      baseOnHoverIn?.(event);
    };

    const onHoverOut: Props['onHoverOut'] = (event) => {
      setIsHover(false);
      baseOnHoverOut?.(event);
    };

    const onPressIn: Props['onPressIn'] = (event) => {
      setIsHover(true);
      baseOnPressIn?.(event);
    };

    const onPressOut: Props['onPressOut'] = (event) => {
      setIsHover(false);
      baseOnPressOut?.(event);
    };

    const memoizedButtonStyles = useMemo((): Partial<Props> => {
      /**
       * Creates initial styles depending on whether the button is has the props
       * `primaryLight`, `secondary`, `tertiary`, `outline`, & `disabled`.
       */
      const getInitialStyles = (): Partial<Props> => {
        if (primaryLight) return primaryLightButtonStyles;
        if (secondary)
          return isHover ? primaryButtonStyles : secondaryButtonStyles;
        if (tertiary) return tertiaryButtonStyles;
        return isHover
          ? { ...primaryButtonStyles, bg: 'secondaryV2' }
          : primaryButtonStyles;
      };

      const disabledStyles = tertiary
        ? tertiaryDisabledButtonStyles
        : disabledButtonStyles;

      // Overwrite default state styles if explicit style props are passed
      const nonOutlineStyles: Partial<Props> = {
        ...getInitialStyles(),
        ...(bgProp && { bg: bgProp }),
        ...(colorProp && { color: colorProp }),
        ...(fontWeightProp && { fontWeight: fontWeightProp }),
        ...(props.disabled && disabledStyles),
      } as Partial<Props>;

      return outline
        ? {
            ...nonOutlineStyles,
            borderColor: nonOutlineStyles.bg,
            borderWidth: 1,
            bg: 'transparent',
          }
        : nonOutlineStyles;
    }, [
      tertiary,
      bgProp,
      colorProp,
      fontWeightProp,
      props.disabled,
      outline,
      primaryLight,
      secondary,
      isHover,
    ]);

    const { color, fontWeight, ...otherButtonStyles } = memoizedButtonStyles;

    return (
      <BaseButton
        {...otherButtonStyles}
        role="button"
        testID={testID}
        {...(iconPosition === 'top' && {
          flexDirection: 'column',
          justifyContent: 'center',
          py: 's',
        })}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        sx={{ transition: buttonBackgroundTransition, ...sxProps }}
        aria-label={label}
        {...props}
      >
        {icon && (iconPosition === 'left' || iconPosition === 'top') ? (
          <StyledIcon
            name={icon}
            size={iconSize}
            color={color}
            fixedWidth={props.variant === 'circle'}
            family={iconFamily}
            testID={iconTestId}
            aria-hidden
          />
        ) : null}
        {label ? (
          <StyledText
            ml={icon && iconPosition === 'left' ? 's' : 0}
            mr={(icon && iconPosition === 'right') || showSpinner ? 's' : 0}
            mt={icon && iconPosition === 'top' ? 'xs' : 0}
            color={color}
            {...(labelVariant ? { variant: labelVariant } : { fontSize })}
            fontWeight={fontWeight}
            lineHeight={undefined}
            textAlign="center"
            {...(props.variant === 'pill' && {
              fontSize: pillFontSize,
              fontWeight: 'semiBold',
              ml: icon && iconPosition === 'left' ? 'xs' : 0,
            })}
          >
            {uppercaseLabel ? label.toUpperCase() : label}
          </StyledText>
        ) : null}
        {showSpinner ? (
          <Box
            mr={icon && iconPosition === 'right' ? 's' : 0}
            dataSet={chromaticIgnoreDataSet}
          >
            <Spinner color={color} />
          </Box>
        ) : null}
        {icon && iconPosition === 'right' ? (
          <StyledIcon name={icon} size={iconSize} color={color} fixedHeight />
        ) : null}
      </BaseButton>
    );
  },
);
