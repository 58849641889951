import { useHeaderHeight } from '@react-navigation/elements';
import { Text, View } from 'dripsy';
import { useMemo } from 'react';
import { Image, StyleSheet } from 'react-native';

import { TestID } from '../../testID/constants';
import { useIntercom } from '../Home/navigation/utils/useIntercom';
import { ModalScreenContainer } from '../ui/v2/ModalScreenContainer';

const LOGO_HEIGHT = 40;

const styles = StyleSheet.create({
  image: {
    height: LOGO_HEIGHT,
    width: 111,
  },
});

export const OutOfServiceScreen = () => {
  const { onChatIconPress } = useIntercom();
  const headerHeight = useHeaderHeight();

  const offset = useMemo(() => -(headerHeight + LOGO_HEIGHT), [headerHeight]);

  return (
    <ModalScreenContainer scrollable={false} hideBackButton>
      <View sx={{ alignItems: 'center', alignSelf: 'center' }}>
        <Image
          source={require('../../assets/unloan-cba-logo.png')}
          style={styles.image}
          resizeMode="cover"
          testID={TestID.EnableFaceId.FaceIdImage}
        />

        <View sx={{ flex: 1, justifyContent: 'center', top: offset }}>
          <View sx={{ alignSelf: 'center', marginHorizontal: '$32' }}>
            <Text variant="header" sx={{ mt: '$24', textAlign: 'center' }}>
              {t('Content.OutOfService.Title')}
            </Text>
          </View>

          <Text
            variant="sBody"
            sx={{
              mt: '$16',
              mb: '$24',
              marginHorizontal: '$32',
              textAlign: 'center',
            }}
          >
            {t('Content.OutOfService.Body1')}
            <Text
              onPress={onChatIconPress}
              role="button"
              aria-label={t('Content.OutOfService.ContactSupport')}
              variant="sBodyLink"
              sx={{ mt: '$8', mb: '$24', textAlign: 'center' }}
            >
              {t('Content.OutOfService.ContactSupport')}
            </Text>
            {t('Content.OutOfService.Body2')}
          </Text>
        </View>
      </View>
    </ModalScreenContainer>
  );
};
