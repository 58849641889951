import { ApolloError } from '@apollo/client';
import { FlatList, FlatListProps, StyleSheet } from 'react-native';

import { Separator } from '../../ui/atoms/Separator';
import { ListRow } from '../../ui/molecules/ListRow';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { SearchBar } from '../../ui/organisms/SearchBar';
import { isLast } from '../../utils/arrayHelpers';

type Props<T> = Pick<
  FlatListProps<T>,
  'ListFooterComponent' | 'contentContainerStyle'
> & {
  data: Array<T>;
  onInstitutionPress: (institution: T) => void;
  setSearchValue: (searchValue: string) => void;
  /**
   * hasExtraRow: will determine the last prop of the last item in the list.
   * hasExtraRow=true => last=false and vice versa.
   */
  hasExtraRow?: boolean;
  loading?: boolean;
  error?: ApolloError;
  searchValue?: string;
};

type UnifiedInstitution = {
  id: string | number;
  name: string;
  favicon?: string;
};

export function InstitutionList<T extends UnifiedInstitution>({
  onInstitutionPress,
  hasExtraRow,
  loading,
  error,
  setSearchValue,
  searchValue,
  ...otherProps
}: Props<T>) {
  const renderEmpty = () => {
    if (loading) return <LoadingState />;

    if (error) {
      return (
        <EmptyState
          style={styles.emptyList}
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchInstitutions')}
        />
      );
    }

    if (searchValue) {
      return (
        <EmptyState
          style={styles.emptyList}
          title={`No result found for "${searchValue}"`}
          description="Please try searching with another term"
        />
      );
    }

    return null;
  };
  return (
    <FlatList
      {...otherProps}
      stickyHeaderIndices={[0]}
      ListHeaderComponent={
        <>
          <SearchBar
            px="m"
            placeholder={t(
              'Content.SelectInstitution.SearchInstitutionPlaceholder',
            )}
            onChangeText={setSearchValue}
            autoFocus
          />
          <Separator spacer />
        </>
      }
      keyExtractor={(p) => `${p.id}`}
      keyboardShouldPersistTaps="handled"
      style={styles.fullWidth}
      ListEmptyComponent={renderEmpty}
      renderItem={({ item, index }) => (
        <InstitutionListItem
          {...item}
          imageUrl={item.favicon}
          onPress={() => onInstitutionPress(item)}
          last={!hasExtraRow && isLast(otherProps.data, index)}
        />
      )}
    />
  );
}

type InstitutionListItemProps<T> = T & {
  last?: boolean;
  onPress: () => void;
  imageUrl?: string;
};
function InstitutionListItem<T extends UnifiedInstitution>({
  name,
  imageUrl,
  id,
  ...otherProps
}: InstitutionListItemProps<T>) {
  return (
    <ListRow
      id={String(id)}
      mx="m"
      label={name || ''}
      imageUrl={imageUrl}
      borderedImage
      useArrow={false}
      {...otherProps}
    />
  );
}

const styles = StyleSheet.create({
  fullWidth: { width: '100%' },
  emptyList: { flex: 1 },
});
