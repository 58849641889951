import { styled, Text, View } from 'dripsy';
import { useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import { RadioGroup, RadioGroupItem } from '../../ui/v2/RadioGroup';
import { parseEnumType } from '../../utils/ensureEnumType';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import { makeTestId } from '../../utils/stringHelpers';
import {
  DEFAULT_RENTAL_INCOME_INITIAL_VALUES,
  RentalIncomeForm,
  RentalIncomeFormDetails,
  RentalIncomeFormSubmitLabel,
} from '../components/RentalIncomeForm';
import {
  useCreateRentalIncomeForV2Form,
  useGetOptionsForRentalIncomeQuery,
} from '../graphql/incomeHooks';
import { YourIncomeWizardScreenProps } from '../navigation/incomeTypes';
import { IncomeYesNoEnum } from '../utils/incomeFormTypes';

export type Props =
  YourIncomeWizardScreenProps<Screen.YOUR_INCOME_V2_WIZARD_RENTAL>;

function useFormInitialValues({
  initialOwnerApplicantId,
}: {
  initialOwnerApplicantId?: string;
}): {
  formInitialValues: RentalIncomeFormDetails;
} {
  const formInitialValues: RentalIncomeFormDetails = useMemo(
    () => ({
      ...DEFAULT_RENTAL_INCOME_INITIAL_VALUES,
      propertyOwners: initialOwnerApplicantId
        ? [initialOwnerApplicantId]
        : undefined,
    }),
    [initialOwnerApplicantId],
  );
  return { formInitialValues };
}

export function RentalIncomeWizardScreen({ navigation, route }: Props) {
  const { params } = route;
  const { loanApplicationId = '' } = params;

  const [showFormEnumState, setShowFormEnumState] = useState<IncomeYesNoEnum>();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const {
    frequencyOptions,
    propertyTypeOptions,
    stateOptions,
    streetTypeOptions,
    incomeOwnerOptions,
    loading: rentalIncomeOptionsLoading,
  } = useGetOptionsForRentalIncomeQuery({
    loanApplicationId,
  });

  const { formInitialValues } = useFormInitialValues({
    initialOwnerApplicantId: incomeOwnerOptions[0]?.value,
  });

  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  const { createExistingRentalIncome, isCreatingRentalIncome } =
    useCreateRentalIncomeForV2Form({
      loanApplicationId,
    });

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateToMainScreen = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Income,
    });
  };

  const onSubmit = async (formValues: RentalIncomeFormDetails) => {
    if (showFormEnumState === IncomeYesNoEnum.No) {
      navigateToMainScreen();
      return;
    }
    const { success, errorMessage } = await createExistingRentalIncome(
      formValues,
    );
    if (!success) {
      setFormErrorMessage(errorMessage);
      return;
    }
    navigateToMainScreen();
  };

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.RentalIncome')}
      scrollable
      loading={isCreatingRentalIncome}
      onClose={navigateToMainScreen}
      // Hide back button to prevent applicant trying to "edit" previously
      // submitted employment income in this wizard.
      // To edit, user need to go to dedicated edit screen instead.
      hideBackButton
    >
      {rentalIncomeOptionsLoading || loadingLoanApplicationMetadata ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.RentalIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage}
            testID={TestID.RentalIncomeForm.ErrorText}
          />
          <SHeaderText>
            {t('Content.YourIncome.Form.IsHavingRentalIncomeQuestion')}
          </SHeaderText>
          <RowView sx={{ mt: '$16', mb: '$32' }}>
            <RadioGroup
              value={showFormEnumState}
              onValueSelected={(v) => {
                const parsedValue = parseEnumType(IncomeYesNoEnum, v);
                if (parsedValue) {
                  setShowFormEnumState(parsedValue);
                }
              }}
            >
              <RadioGroupItem
                value={IncomeYesNoEnum.Yes}
                label={t('Content.Common.ButtonLabel.Yes')}
                testID={makeTestId([
                  TestID.RentalIncomeForm.HaveRentalIncome,
                  IncomeYesNoEnum.Yes,
                ])}
                containerStyle={{
                  mr: '$16',
                  flex: 1,
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  Screen.YOUR_INCOME_V2_WIZARD_RENTAL,
                  FieldInteractionKey.DoYouRecieveRentalIncome,
                )}
              />
              <RadioGroupItem
                value={IncomeYesNoEnum.No}
                label={t('Content.Common.ButtonLabel.No')}
                testID={makeTestId([
                  TestID.RentalIncomeForm.HaveRentalIncome,
                  IncomeYesNoEnum.No,
                ])}
                containerStyle={{
                  flex: 1,
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  Screen.YOUR_INCOME_V2_WIZARD_RENTAL,
                  FieldInteractionKey.DoYouRecieveRentalIncome,
                )}
              />
            </RadioGroup>
          </RowView>
          {showFormEnumState === IncomeYesNoEnum.Yes ? (
            <RentalIncomeForm
              screen={Screen.YOUR_INCOME_V2_WIZARD_RENTAL}
              initialValues={formInitialValues}
              isSubmitting={isCreatingRentalIncome}
              onSubmit={onSubmit}
              frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
              propertyOwnerOptions={incomeOwnerOptions}
              propertyTypeOptions={propertyTypeOptions}
              propertySuggestionData={propertySuggestionData}
              streetTypeOptions={streetTypeOptions}
              stateOptions={stateOptions}
              loadSuggestionData={loadSuggestionData}
              isLoadingSuggestionData={isLoadingSuggestionData}
              submitLabel={RentalIncomeFormSubmitLabel.Done}
              onRentalExpenseLearnMoreClick={() => {
                navigation.navigate(Screen.SINGLE_V2_MODAL, {
                  screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
                });
              }}
            />
          ) : (
            // A duplicated button with the same style as the one used in RentalIncomeForm.
            // Needed to skip this step if applicant doesn't have employment income.
            <Button
              label={t('Content.Common.ButtonLabel.Done')}
              testID={TestID.RentalIncomeForm.SubmitButton}
              onPress={() => navigateToMainScreen()}
              disabled={!showFormEnumState}
              py="m"
              alignSelf="stretch"
            />
          )}
        </>
      )}
    </ModalScreenContainer>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
