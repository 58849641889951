import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function FinancialHouseholdExpenseIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 18.71c-.73 0-1.418-.214-2.02-.687L1 11.535v9.152c0 1.16.902 2.063 2.063 2.063h17.875A2.063 2.063 0 0023 20.687v-9.152l-9.023 6.488a3.162 3.162 0 01-1.977.688zM22.184 7.755c-.387-.258-.73-.559-1.247-.945V4.875c0-1.117-.945-2.063-2.062-2.063h-3.352l-.386-.257C14.407 2.039 12.988.75 12 .75c-1.031 0-2.45 1.29-3.18 1.805l-.386.257H5.125c-1.16 0-2.063.946-2.063 2.063v1.934c-.558.386-.902.687-1.289.945C1.343 8.098 1 8.828 1 9.387v.472l4.125 2.965V4.875h13.75v7.95L23 9.858v-.472c0-.645-.3-1.203-.816-1.633zm-9.84-1.16h-.688a.339.339 0 00-.344.343v.731c-1.03 0-1.847.86-1.847 1.934 0 .859.558 1.59 1.332 1.847l1.933.602c.258.043.387.258.387.515 0 .344-.215.559-.515.559h-1.204c-.171 0-.386-.043-.558-.129a.33.33 0 00-.43.043l-.473.473a.322.322 0 000 .515c.387.301.86.473 1.376.473v.688c0 .214.128.343.343.343h.688a.339.339 0 00.344-.344V14.5c.988 0 1.804-.86 1.804-1.934 0-.859-.558-1.632-1.332-1.847l-1.933-.602c-.258-.043-.387-.258-.387-.515 0-.344.215-.602.515-.602h1.204c.171 0 .386.086.558.172a.33.33 0 00.43-.043l.473-.473a.322.322 0 000-.515c-.387-.301-.86-.473-1.332-.473v-.73a.37.37 0 00-.344-.344z"
        fill={color}
      />
    </Svg>
  );
}
