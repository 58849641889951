import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { BaseConfirmationSuccessActionSheet } from '../../ActionSheet/components/BaseConfirmationSuccessActionSheet';
import { ActionSheetScreenProps } from '../../ActionSheet/navigation/types';
import { ActionSheetType } from '../../navigation/types/screens';
import { LeaveAccountPermissionsScreenAtomFamily } from '../utils/atoms';

export type Props =
  ActionSheetScreenProps<ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED>;

export const AccountPermissionsChangeRequestSubmittedActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { message } = route.params;
  const { supportingText } = route.params;
  const { cbaAccountId } = route.params;

  const LeavePageAtom = LeaveAccountPermissionsScreenAtomFamily(cbaAccountId);
  const setShouldGoBack = useSetRecoilState(LeavePageAtom);

  const onClose = useCallback(() => {
    setShouldGoBack(true);
    navigation.goBack();
  }, [navigation, setShouldGoBack]);

  return (
    <BaseConfirmationSuccessActionSheet
      message={message}
      onClose={onClose}
      supportingText={supportingText}
      includeCloseButton={!!supportingText}
    />
  );
};
