/* eslint-disable no-nested-ternary */
import { A, Text, useSx, View } from 'dripsy';
import { useMemo } from 'react';
import { Dimensions } from 'react-native';
import Markdown from 'react-native-markdown-display';

import ContentJson from '../../../assets/newFeatures/content.json';
import { HomeHeader } from '../../Home/components/HomeHeader';
import {
  createFeatureMarkdownRules,
  getFeatureProps,
} from '../../NewFeatures/components/BaseNewFeatures';
import { StyledImage } from '../../ui/atoms/StyledImage';

const rules = createFeatureMarkdownRules({ textAlign: 'left' });

const DEFAULT_IMAGE_SIZE = 208;

const DEFAULT_IMAGE_MOBILE_SIZE = 335;

type FeatureItemProps = {
  index: number;
  feature: (typeof ContentJson.features)[0];
};

function FeatureItem({ index, feature }: FeatureItemProps) {
  const sx = useSx();

  const imageProps = useMemo(() => getFeatureProps(feature.key), [feature.key]);

  const maxImageSize = useMemo(() => Dimensions.get('window').width - 32, []);

  return (
    <View
      sx={{
        mt: index > 0 ? '$32' : undefined,
        flexDirection: ['column', 'row'],
      }}
    >
      {imageProps ? (
        feature.image_cta?.url ? (
          <A
            href={feature.image_cta.url}
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            sx={{ alignSelf: 'center', flexWrap: 'wrap' }}
          >
            <StyledImage
              width={{
                mobile: DEFAULT_IMAGE_MOBILE_SIZE,
                tablet: DEFAULT_IMAGE_SIZE,
              }}
              height={{
                mobile: DEFAULT_IMAGE_MOBILE_SIZE,
                tablet: DEFAULT_IMAGE_SIZE,
              }}
              maxWidth={maxImageSize}
              maxHeight={maxImageSize}
              contentFit="contain"
              style={sx({
                borderRadius: '$card',
                mr: [0, '$32'],
              })}
              {...imageProps}
            />
          </A>
        ) : (
          <StyledImage
            width={{
              mobile: DEFAULT_IMAGE_MOBILE_SIZE,
              tablet: DEFAULT_IMAGE_SIZE,
            }}
            height={{
              mobile: DEFAULT_IMAGE_MOBILE_SIZE,
              tablet: DEFAULT_IMAGE_SIZE,
            }}
            maxWidth={maxImageSize}
            maxHeight={maxImageSize}
            contentFit="contain"
            style={sx({
              borderRadius: '$card',
              mr: [0, '$32'],
            })}
            {...imageProps}
          />
        )
      ) : null}

      <View sx={{ flex: 1, flexDirection: 'column', mt: ['$16', 0] }}>
        {feature.title ? (
          <Text variant="sHeader" sx={{ mb: '$8' }}>
            {feature.title}
          </Text>
        ) : null}

        <View>
          <Markdown rules={rules}>{feature.supportingText}</Markdown>
        </View>

        {feature.caption ? (
          <Text variant="caption" sx={{ mt: '$16' }}>
            {feature.caption}
          </Text>
        ) : null}
      </View>
    </View>
  );
}

export type BaseSettingsNewFeaturesProps = {
  content: typeof ContentJson;
};

export function BaseSettingsNewFeatures({
  content,
}: BaseSettingsNewFeaturesProps) {
  return (
    <>
      <HomeHeader
        title={content.page.title}
        subTitle={content.page.supportingText}
      />

      <View>
        {content.features.map((feature, index) => (
          <FeatureItem key={feature.key} index={index} feature={feature} />
        ))}
      </View>
    </>
  );
}
