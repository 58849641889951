import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import {
  Loan_Application_Type_Enum,
  useLoanApplicationSecuritiesQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Spinner } from '../../ui/atoms/Spinner';
import { ListRow } from '../../ui/molecules/ListRow';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { formatAddress } from '../../utils/stringHelpers';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { PropertyDetailsCaption } from '../components/PropertyDetailsCaption';
import PropertyStaticMap from '../components/PropertyStaticMap';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

const getPropertyModalScreen = (
  loanApplicationType?: Loan_Application_Type_Enum,
) => {
  if (loanApplicationType === Loan_Application_Type_Enum.Purchase) {
    return Screen.YOUR_PROPERTY_PURCHASE_MODAL;
  }
  if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
    return Screen.TOP_UP_PROPERTY_VALUE_MODAL;
  }

  return Screen.YOUR_PROPERTY_V2_MODAL;
};

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY>;
const currentSection = LoanApplicationSection.Property;

/**
 * This currently only handle refinance flow
 */
function LoanApplicationYourPropertyV2Base({ navigation, route }: Props) {
  const { params } = route;
  const loanApplicationId = params?.loanApplicationId;

  const {
    data,
    loading: applicationSecuritiesLoading,
    error: applicationSecuritiesError,
    refetch: applicationSecuritiesRefetch,
  } = useLoanApplicationSecuritiesQuery({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });
  const loanApplication = data?.loan_application_by_pk;
  const loanApplicationSecurities =
    loanApplication?.loan_application_securities ?? [];
  const loanApplicationType = loanApplication?.type;

  const hasSavedProperties = loanApplicationSecurities.length > 0;

  const [firstLoanApplicationSecurity] = loanApplicationSecurities;
  const { latitude, longitude } =
    firstLoanApplicationSecurity?.property?.address || {};
  const addressLatLong =
    latitude != null && longitude != null ? { latitude, longitude } : null;
  const [first, last] = formatAddress(
    firstLoanApplicationSecurity?.property?.address,
  );
  const googleMapDisplayLabel =
    firstLoanApplicationSecurity?.property.address.display_address;
  const primaryButtonLabel = hasSavedProperties
    ? t('Content.Common.ButtonLabel.Continue')
    : t('Content.PropertyDetails.ButtonLabel.SelectYourProperty');

  const navigateToPropertyDetailsModal = () => {
    const screenName = getPropertyModalScreen(loanApplicationType);

    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: screenName,
      params: {
        ...params,
        loanApplicationSecurityId: firstLoanApplicationSecurity?.id,
      },
    });
  };

  const { navigateToNextLoanApplicationScreen, getScreenOrder } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  const isTopUpWithoutValuation =
    loanApplicationType === Loan_Application_Type_Enum.TopUp &&
    !firstLoanApplicationSecurity?.owner_estimated_value;

  const initialTopUpPropertyValue =
    loanApplication?.parent_loan_account?.security?.owner_estimated_value;

  const onPrimaryButtonPress = () => {
    if (hasSavedProperties && !isTopUpWithoutValuation) {
      navigateToNextLoanApplicationScreen({
        currentSection,
      });
      return;
    }
    navigateToPropertyDetailsModal();
  };

  const propertyStreetAddress =
    firstLoanApplicationSecurity?.property?.address?.short_address_format;

  const screenHeaderCaption = useMemo(() => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      if (
        propertyStreetAddress &&
        !firstLoanApplicationSecurity?.owner_estimated_value
      ) {
        return t(
          'Content.PropertyDetails.Header.CaptionTopUpWithoutValuation',
          {
            propertyStreetAddress,
          },
        );
      }

      return t('Content.PropertyDetails.Header.CaptionTopUpWithValuation');
    }

    return t('Content.PropertyDetails.Header.CaptionSelectProperties');
  }, [
    firstLoanApplicationSecurity?.owner_estimated_value,
    loanApplicationType,
    propertyStreetAddress,
  ]);

  if (!loanApplicationId) {
    return (
      <LoanApplicationScreenContainer>
        <EmptyState
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </LoanApplicationScreenContainer>
    );
  }

  if (applicationSecuritiesLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <EmptyState title={t('Content.PropertyDetails.Header.Checking')}>
          <Spinner size="large" />
        </EmptyState>
      </LoanApplicationScreenContainer>
    );
  }

  if (applicationSecuritiesError) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={applicationSecuritiesError}
          displayMessage={t('Content.PropertyCheck.Error.ScreenInitialQuery')}
          refetch={applicationSecuritiesRefetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.PropertyDetails.Header.Title')}
        caption={screenHeaderCaption}
      />
      {hasSavedProperties ? (
        <Box my="xl" borderRadius="card" bg="shapeBg" px="xs" py="xs" mx="m">
          <Box borderRadius="card">
            <PropertyStaticMap
              location={addressLatLong || googleMapDisplayLabel}
              locationName={first}
            />
            <ListRow
              arrowForwardColor="secondaryContent"
              isButton
              label={first || ''}
              labelFontWeight="semiBold"
              caption={last || ''}
              useArrow
              color="primaryContent"
              onPress={navigateToPropertyDetailsModal}
              inset
              last
            />
            <PropertyDetailsCaption
              propertyValue={
                firstLoanApplicationSecurity?.owner_estimated_value ||
                initialTopUpPropertyValue ||
                null
              }
              propertyPurpose={
                firstLoanApplicationSecurity?.property_purpose || null
              }
              propertyType={firstLoanApplicationSecurity?.property_type || null}
              testId={
                TestID.LoanApplicationPropertyCheck.SelectedPropertyDetails
              }
            />
          </Box>
        </Box>
      ) : (
        <Box my="xl" alignItems="center" bg="bg">
          <EmptyStateIllustration
            testID={TestID.LoanApplicationPropertyCheck.EmptyStateIllustration}
            name="propertyV2"
          />
        </Box>
      )}
      <LoanApplicationWizardFooter
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonPress={onPrimaryButtonPress}
        primaryButtonTestID={TestID.LoanApplicationPropertyCheck.ContinueButton}
      />
    </LoanApplicationScreenContainer>
  );
}

export const LoanApplicationYourPropertyV2 = withAuthenticationRequired(
  LoanApplicationYourPropertyV2Base,
);
