import { Top_Up_Reason_Enum } from '../../generated/graphql';

type AdditionalCashoutObject = {
  [Top_Up_Reason_Enum.YourHome]: number | null | undefined;
  [Top_Up_Reason_Enum.InvestmentProperty]: number | null | undefined;
  [Top_Up_Reason_Enum.FinancialInvestment]: number | null | undefined;
  [Top_Up_Reason_Enum.PersonalUse]: number | null | undefined;
  [Top_Up_Reason_Enum.Other]: number | null | undefined;
};

// This should match the filter
// for `new_top_up_reasons`
// in `GetFinancialDeclarationOptions` query.
export const DISPLAYED_TOP_UP_REASONS = [
  Top_Up_Reason_Enum.YourHome,
  Top_Up_Reason_Enum.InvestmentProperty,
  Top_Up_Reason_Enum.FinancialInvestment,
  Top_Up_Reason_Enum.PersonalUse,
  Top_Up_Reason_Enum.Other,
] as const;

export function getTotalCashoutAmount(
  values: Partial<AdditionalCashoutObject>,
) {
  return DISPLAYED_TOP_UP_REASONS.reduce(
    (sum, topUpReason) => sum + (values[topUpReason] || 0),
    0,
  );
}
