import { ComponentProps, useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Approval_Status_Enum,
  Change_Request_Status_Enum,
  useGetPendingAccountPermissionChangeRequestsSubscription,
} from '../../generated/graphql';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { ActionCard } from '../../ui/organisms/ActionCard';

type Props = {
  cbaAccountId: string;
  hideWhenNoPendingRequest?: boolean;
  loading: boolean;
  onPress: (hasPendingRequest: boolean) => void;
} & Pick<ComponentProps<typeof DataRowGroup>, 'mt' | 'mb'>;

type ContentProps = {
  loading: boolean;
  onPress: () => void;
};

const TextPrefix = 'Content.AccountPermissions.PendingApprovalCard';

export const PendingMyApproval = ({ onPress, loading }: ContentProps) => (
  <ActionCard
    label={t(`${TextPrefix}.PendingMyApproval.Title`)}
    loading={loading}
    caption={t(`${TextPrefix}.PendingMyApproval.Pending`)}
    alertAction={{
      alertLabel: t(`${TextPrefix}.PendingMyApproval.YourApprovalIsRequired`),
      alertActionLabel: t(`${TextPrefix}.PendingMyApproval.ViewAndApprove`),
      onPressAlertAction: onPress,
    }}
  />
);

export const PendingCoBorrowerApproval = ({
  onPress,
  loading,
  coBorrowers,
}: ContentProps & { coBorrowers: string }) => (
  <ActionCard
    label={t(`${TextPrefix}.PendingCoBorrowerApproval.Title`)}
    onPress={onPress}
    subCaption={t(`${TextPrefix}.PendingCoBorrowerApproval.Pending`)}
    loading={loading}
    alertAction={{
      showPendingIcon: true,
      alertLabel: t(
        `${TextPrefix}.PendingCoBorrowerApproval.PendingCoBorrowerApprovals`,
        {
          coBorrowers,
        },
      ),
      alertActionLabel: t(`${TextPrefix}.PendingCoBorrowerApproval.Title`),
    }}
  />
);

export const NoPendingRequests = ({ loading, onPress }: ContentProps) => (
  <DataRow
    loading={loading}
    label={t('Content.LoanDetail.AccountPermissions')}
    onPress={onPress}
    testID={TestID.LoanDetails.AccountPermissions}
  />
);

// The component is used on Dashboard as well
// When there are no pending requests, we don't want to show the section on the dashboard
// Whereas on Loan Details, we want to show the button if there are no pending requests
// So we need to set the hideWhenNoPendingRequest prop when using on Dashboard
export const AccountPermissionsSection = ({
  cbaAccountId,
  loading,
  onPress,
  hideWhenNoPendingRequest,
  ...otherProps
}: Props) => {
  const {
    data,
    loading: subscriptionLoading,
    error,
  } = useGetPendingAccountPermissionChangeRequestsSubscription({
    context: {
      sentryContext: {
        description:
          'Account Permissions - Get Pending Change Requests Subscription',
      },
    },
    variables: {
      cbaAccountId,
    },
  });

  const isLoading = subscriptionLoading || loading;
  const changeRequests = data?.account_permission_change_request ?? [];
  const pendingChangeRequest = changeRequests.find(
    (request) => request.status === Change_Request_Status_Enum.Pending,
  );
  // All existing account permission change requests are linked to
  // one approval request
  const hasPendingApprovals =
    (changeRequests[0]?.approval_request.pending_responses.length ?? 0) > 0;
  const hasPendingRequest = !!pendingChangeRequest;
  const onButtonPress = useCallback(
    () => onPress(hasPendingRequest),
    [onPress, hasPendingRequest],
  );

  if (!isLoading && error) {
    return null;
  }

  if (hasPendingRequest && hasPendingApprovals) {
    const approvalRequest = pendingChangeRequest.approval_request;
    const myApprovalResponse = approvalRequest.my_approval?.response;
    const pendingResponses = approvalRequest.pending_responses;
    const borrowersWithPendingResponse = pendingResponses
      .map((response) => response.identity_profile.first_name)
      .join(' and ');
    return myApprovalResponse?.status === Approval_Status_Enum.Pending ? (
      <DataRowGroup {...otherProps}>
        <PendingMyApproval loading={isLoading} onPress={onButtonPress} />
      </DataRowGroup>
    ) : (
      <DataRowGroup {...otherProps}>
        <PendingCoBorrowerApproval
          loading={isLoading}
          onPress={onButtonPress}
          coBorrowers={borrowersWithPendingResponse}
        />
      </DataRowGroup>
    );
  }

  if (!isLoading && !!hideWhenNoPendingRequest) {
    return null;
  }

  return (
    <DataRowGroup {...otherProps}>
      <NoPendingRequests loading={isLoading} onPress={onButtonPress} />
    </DataRowGroup>
  );
};
