import { gql } from '@apollo/client';

import { LOAN_APPLICATION } from '../../LoanApplication/graphql/fragments';

// TODO: This fragment needs to be broken down to smaller fragments
export const LOAN_APPLICATION_BORROWER = gql`
  fragment LoanApplicationBorrowerWithLoanApplication on applicant {
    ...LoanApplicationBorrower
    loan_application {
      ...LoanApplication
    }
  }

  fragment LoanApplicationBorrower on applicant {
    id
    role
    is_current_logged_in_applicant
    status
    household_id
    pre_dvs_consent_at
    email
    mobile
    reviewed_liability_at
    latest_full_name
    latest_first_name
    first_name
    middle_name
    last_name
    user_id
    user_identity_profile {
      id
      first_name
      middle_name
      last_name
      kyc_status
      manual_kyc_result
      two_plus_result
      frankieone_entity_id
      frankieone_entity_details {
        id
        current_mailing_address {
          id
        }
        current_residential_address {
          id
        }
        driver_licence {
          id
          licence_number
        }
        passport {
          id
          passport_number
        }
      }
    }
    applicant_invites {
      is_expired
    }
  }

  fragment LoanApplicationBorrowerWithAddress on applicant {
    id
    role
    is_current_logged_in_applicant
    status
    household_id
    pre_dvs_consent_at
    email
    mobile
    latest_email
    latest_mobile
    latest_full_name
    latest_first_name
    user {
      mailing_address {
        ...HasuraAddress
      }
      residential_address {
        id
      }
    }
    user_identity_profile {
      id
      first_name
      middle_name
      last_name
      dob
      frankieone_entity_id
      frankieone_entity_details {
        id
        current_residential_address {
          ...FrankieoneAddress
        }
        current_mailing_address {
          ...FrankieoneAddress
        }
      }
    }
  }

  fragment LoanApplicantFullName on applicant {
    id
    latest_full_name
  }
  ${LOAN_APPLICATION}

  fragment FrankieoneAddress on address_details {
    id
    building_name
    country
    long_form
    postcode
    state
    street_name
    street_no
    street_type
    suburb
    unit_no
  }
`;
