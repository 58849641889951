import { Text } from 'dripsy';

type Props = {
  absoluteDate: string;
  termEarlier: string;
};

export const PredictablePayOffDateCaption = ({
  absoluteDate,
  termEarlier,
}: Props) => (
  <Text variant="sBody">
    {t('Content.HomeLoan.Insights.TermRemaining.YouCouldPayOffYourHomeLoanBy', {
      date: `${absoluteDate} (${termEarlier} early)`,
    })}
  </Text>
);
