import { memo, useCallback } from 'react';

import {
  Applicant_Role_Enum,
  BorrowerListItem_BorrowerFragment,
} from '../../generated/graphql';
import { NewListRow, NewListRowProps } from '../../ui/molecules/NewListRow';

export type Props = BorrowerListItem_BorrowerFragment &
  Pick<NewListRowProps, 'mt' | 'mb'> & {
    onBorrowerPress?: (item: BorrowerListItem_BorrowerFragment) => void;
    last?: boolean;
    testIDPrefix?: string;
  };

type ShowAlertStyle = {
  rightIconColor: NewListRowProps['rightIconColor'];
  rightIconName: NewListRowProps['rightIconName'];
};

function coBorrowerProps(
  borrower: BorrowerListItem_BorrowerFragment,
  hasAllInvitesExpired: boolean,
  hasAnyInviteBeenAccepted: boolean,
) {
  const isNotPrimaryBorrower = borrower.role === Applicant_Role_Enum.Borrower;
  if (isNotPrimaryBorrower) {
    const hasRunKycCheck =
      !!borrower.user_identity_profile?.frankieone_entity_id;
    if (hasAllInvitesExpired && !hasAnyInviteBeenAccepted) {
      return {
        caption: t('Content.Borrowers.CoBorrowerCaption.InvitationHasExpired'),
        ...({
          rightIconColor: 'error',
          rightIconName: 'alert-circle',
        } as ShowAlertStyle),
      };
    }
    if (!hasRunKycCheck) {
      return {
        caption: t(
          'Content.Borrowers.CoBorrowerCaption.BorrowerInformationRequired',
        ),
      };
    }
  }
  return {};
}

export const BorrowerListItem = memo<Props>(
  ({ onBorrowerPress, testIDPrefix, mt, mb, ...borrower }) => {
    const onPress = useCallback(
      () => onBorrowerPress?.(borrower),
      [onBorrowerPress, borrower],
    );
    const hasAllInvitesExpired =
      borrower.applicant_invites?.length > 0 &&
      borrower.applicant_invites?.every((invite) => invite.is_expired);

    const hasAnyInviteBeenAccepted =
      borrower.applicant_invites?.length > 0 &&
      borrower.applicant_invites?.find((invite) => invite.accepted_at);

    return (
      <NewListRow
        key={borrower.id}
        leftIconFamily="svg"
        leftIconName="summaryBorrower"
        leftIconColor="primaryContent"
        leftIconSize="l"
        title={borrower.latest_full_name || ''}
        testID={`${testIDPrefix}-${borrower.id}`}
        showArrow={!!onBorrowerPress}
        onRowPress={onBorrowerPress ? onPress : undefined}
        shadowed={false}
        mt={mt}
        mb={mb}
        {...coBorrowerProps(
          borrower,
          hasAllInvitesExpired,
          !!hasAnyInviteBeenAccepted,
        )}
      />
    );
  },
);
