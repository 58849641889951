import { Gesture, GestureDetector } from 'react-native-gesture-handler';

import { noop } from '../../../utils/noop';
import { TouchableWithoutFeedbackProps } from './models';

export const TouchableWithoutFeedback = ({
  children,
  onPress = noop,
  onPressIn = noop,
  onPressOut = noop,
}: TouchableWithoutFeedbackProps) => {
  const hover = Gesture.Hover()
    .activeCursor('pointer')
    .onBegin(() => {
      onPressIn();
    })
    .onEnd(() => {
      onPressOut();
    })
    .runOnJS(true);

  const tap = Gesture.Tap()
    .onBegin(() => {
      onPressIn();
    })
    .onEnd((_, success) => {
      onPressOut();
      if (success) {
        onPress();
      }
    })
    .runOnJS(true);

  return (
    <GestureDetector gesture={Gesture.Exclusive(tap, hover)}>
      {children}
    </GestureDetector>
  );
};
