import { pluralize } from '../../utils/stringHelpers';
import { SelectionRowOptions } from '../form/SelectionRowGroup';

function getFirstWordOfLabel(label: string) {
  const [first] = label.split(' ');
  return first;
}

export function getMultiselectDisplayValue<T>(
  selectedValues: SelectionRowOptions<T>,
  availableOptions: SelectionRowOptions<T>,
  {
    unit,
    separator = ' & ',
    shortenValueForMultipleSelectedValues = true,
  }: {
    unit?: string;
    separator?: string;
    /**
     * When selected values length === 2, display only the first word.
     * E.g:
     * Selected values: `['Sean Curtis', 'Jean Curtis']`
     * Display value: 'Sean & Jean'
     *
     * When there is only 1 selected value, display the full word.
     * E.g:
     * Selected values: `['Sean Curtis']`
     * Display value: 'Sean Curtis'
     */
    shortenValueForMultipleSelectedValues?: boolean;
  },
) {
  const selectedValuesLength = selectedValues.length;
  if (availableOptions.length < 1 || selectedValuesLength < 1) {
    return '';
  }
  if (selectedValuesLength === 1 && selectedValues?.[0]) {
    if (shortenValueForMultipleSelectedValues) {
      return getFirstWordOfLabel(selectedValues?.[0].label);
    }
    return selectedValues?.[0].label;
  }

  if (selectedValuesLength === availableOptions.length) {
    return t('Content.Common.Placeholder.All');
  }
  if (selectedValuesLength <= 2) {
    return selectedValues
      .map(({ label }) => {
        if (shortenValueForMultipleSelectedValues) {
          return getFirstWordOfLabel(label);
        }
        return label;
      })
      .join(separator);
  }
  return unit
    ? pluralize(selectedValuesLength, unit)
    : String(selectedValuesLength);
}
