import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { LoanConfigurationV2ScreenProps } from '../navigation/types';
import { SelectRefiReasonV2Base } from './base/SelectRefiReasonV2Base';

type Props =
  LoanConfigurationV2ScreenProps<Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON>;

export function LoanConfigurationSelectRefiReasonScreenV2({
  navigation,
  route,
}: Props) {
  const { loanApplicationId } = route.params || {};
  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourUnloan = () =>
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.YourUnloan,
    });

  const navigateToSetupLoan = () =>
    navigation.navigate(Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN, route.params);

  return (
    <SelectRefiReasonV2Base
      screen={Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON}
      onSubmitSuccess={navigateToSetupLoan}
      loanApplicationId={loanApplicationId}
      onClose={navigateToYourUnloan}
      hideBackButton
    />
  );
}
