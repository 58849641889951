export type RGB = {
  r: number;
  g: number;
  b: number;
};

export const convertRGB = (rgb: RGB): string =>
  `rgb(${Object.values(rgb).join(' ')})`;

export const getRGB = (first: RGB, second: RGB, percentage: number): string => {
  const result: RGB = { r: 0, g: 0, b: 0 };
  (Object.keys(first) as Array<keyof RGB>).forEach((key) => {
    const start = first[key];
    const end = second[key];
    const offset = (start - end) * percentage;
    if (offset >= 0) {
      Math.abs(offset);
    }
    result[key] = start - offset;
  });
  return convertRGB(result);
};
