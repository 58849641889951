import * as React from 'react';

import {
  AccountStatus,
  BizaAccount,
  Status,
} from '../../DataSharingConfirmation/types';
import {
  getUsersWithDomsRequest,
  isUserPendingDomsRequest,
} from '../../DataSharingConfirmation/utils/biza';
import { Biza_Account_Sharing_Status } from '../../generated/graphql';
import { isKnownEnumValue } from '../../utils/typesHelpers';
import DataSharingChangeStatus from './DataSharingChangeStatus';
import DataSharingMessage from './DataSharingMessage';
import DataSharingStatus from './DataSharingStatus';
import ErrorMessage from './ErrorMessage';

type DataSharingContentProps = {
  account: BizaAccount;
  userId?: string;
};

const DataSharingContent: React.FC<DataSharingContentProps> = ({
  account,
  userId,
}) => {
  if (!userId)
    return (
      <ErrorMessage
        message={t('Content.DataSharingPreferences.Error.UserIdNotFound')}
      />
    );

  const {
    sharing_status: sharingStatus,
    pending_doms_request: pendingDomsRequest,
  } = account;
  if (
    !sharingStatus ||
    !isKnownEnumValue(Biza_Account_Sharing_Status, sharingStatus)
  ) {
    return <ErrorMessage />;
  }
  const status: Status = AccountStatus[sharingStatus];

  const isMyResponsePending = isUserPendingDomsRequest(
    userId,
    pendingDomsRequest,
  );

  return (
    <>
      <DataSharingStatus
        status={status}
        domRequestUsers={getUsersWithDomsRequest(userId, pendingDomsRequest)}
      />
      <DataSharingMessage status={status} />
      <DataSharingChangeStatus
        isMyResponsePending={isMyResponsePending}
        status={status}
        accountId={account.id}
        requestId={pendingDomsRequest?.id}
      />
    </>
  );
};

export default DataSharingContent;
