import { useTheme } from '@shopify/restyle';
import { useCallback } from 'react';
import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { TooltipProps as BaseTooltipProps } from 'react-native-walkthrough-tooltip';

import { Alert } from '../atoms/Alert';
import { Box } from '../atoms/Box';
import { Skeleton } from '../atoms/Skeleton';
import { StyledIcon } from '../atoms/StyledIcon';
import { StyledText } from '../atoms/StyledText';
import { Tooltip } from '../atoms/Tooltip';
import { Theme } from '../theme';
import { FontSize, FontWeight } from '../types';
import { useCalculateTextSkeletonSize } from '../utils/calculateSkeletonSize';
import { createBox } from '../utils/createBox';

const BaseInputRow = createBox();

type SubLabelProps = {
  subLabel?: string;
  subLabelLoading?: boolean;
  testID?: string;
};
export type Props = React.ComponentProps<typeof BaseInputRow> &
  SubLabelProps &
  Pick<BaseTooltipProps, 'placement'> & {
    noBorder?: boolean;
    last?: boolean;
    noMargin?: boolean;
    fixedLabel?: boolean;
    error?: string | null;
    touched?: boolean;
    label?: string;
    labelIconName?: string;
    onLabelIconPress?: () => void;
    left?: React.ReactNode;
    onPress?: () => void;
    showErrorText?: boolean;
    fontSize?: FontSize;
    fontWeight?: FontWeight;
    /** Allow to display error, defaults to true */
    displayError?: boolean;
    tooltipContent?: string;
    tooltipTestID?: string;
  };

function SubLabel({ subLabel, subLabelLoading, testID }: SubLabelProps) {
  const calculatedSkeletonSize = useCalculateTextSkeletonSize('caption');
  if (subLabelLoading) {
    return (
      <Skeleton show width="100%" height={calculatedSkeletonSize.height} />
    );
  }

  if (subLabel == null) {
    return null;
  }

  return (
    <StyledText
      variant="caption"
      mr="m"
      testID={testID ? `${testID}-sublabel` : undefined}
    >
      {subLabel}
    </StyledText>
  );
}

export const InputRow: React.FC<Props> = ({
  bg = 'shapeBg',
  error,
  touched,
  // TODO [App / UI]: Merge `noBorder` and `last` prop into one prop if possible
  noBorder,
  last,
  noMargin,
  label,
  subLabel,
  fixedLabel,
  left,
  onPress,
  children,
  showErrorText,
  fontSize = 'm',
  fontWeight,
  displayError: displayErrorProp = true,
  labelIconName,
  onLabelIconPress,
  subLabelLoading,
  testID,
  tooltipContent,
  placement,
  tooltipTestID,
  ...props
}) => {
  const theme = useTheme<Theme>();

  const displayError = displayErrorProp && Boolean(error && touched);

  const showErrorAlert = useCallback(() => {
    if (error) {
      Alert.alert(error);
    }
  }, [error]);

  const content = (
    <BaseInputRow
      borderBottomWidth={noBorder ? 0 : theme.constants.borderWidth}
      minHeight={theme.constants.minRowHeight}
      borderColor={displayError ? 'error' : 'border'}
      ml="m"
      flexDirection="row"
      alignItems="center"
      {...(last && {
        ml: 0,
        pl: 'm',
      })}
      {...props}
    >
      {left || null}
      {label ? (
        <Box width={fixedLabel ? 130 : undefined} my="s" flex={2}>
          <Box row alignItems="center">
            <StyledText
              fontSize={fontSize}
              fontWeight={fontWeight || 'semiBold'}
              mr={labelIconName || tooltipContent ? 'xs' : 'm'}
            >
              {label}
            </StyledText>
            {labelIconName ? (
              <StyledIcon
                name={labelIconName}
                onPress={onLabelIconPress}
                color="primaryContent"
              />
            ) : null}
            {tooltipContent ? (
              <Tooltip
                content={tooltipContent}
                placement={placement}
                testID={tooltipTestID}
              />
            ) : null}
          </Box>
          <SubLabel subLabel={subLabel} subLabelLoading={subLabelLoading} />
        </Box>
      ) : null}
      {children}
      {displayError ? (
        <StyledIcon
          name="alert"
          onPress={showErrorAlert}
          color="error"
          size="s"
          style={styles.errorIcon}
          fixedHeight
        />
      ) : null}
    </BaseInputRow>
  );

  const wrappedContent = onPress ? (
    <TouchableOpacity
      role="button"
      activeOpacity={1}
      onPress={onPress}
      testID={testID}
    >
      {content}
    </TouchableOpacity>
  ) : (
    content
  );

  return (
    <Box
      bg={bg}
      overflow={props.overflow}
      borderRadius={props.borderRadius}
      borderTopStartRadius={props.borderTopStartRadius}
      borderTopEndRadius={props.borderTopEndRadius}
    >
      {wrappedContent}
      {displayError && showErrorText ? (
        <StyledText mt="s" ml={noMargin ? 0 : 'm'} variant="body" color="error">
          {error}
        </StyledText>
      ) : null}
    </Box>
  );
};

const styles = StyleSheet.create({
  errorIcon: {
    position: 'absolute',
    right: 0,
  },
});
