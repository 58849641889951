import { atom } from 'recoil';

import { asyncStorageEffect } from '../../../../utils/hooks/useAsyncStorage';

export const pwaInstallBannerVisibleAtom = atom({
  key: 'pwaInstallBannerVisible',
  default: true,
  effects: [asyncStorageEffect('pwaInstallBannerVisible')],
});

export const pwaInstallTooltipVisibleAtom = atom({
  key: 'pwaInstallTooltipVisible',
  default: false,
});
