export const percentageFormatter = (num: number): `${number}%` =>
  new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num) as `${number}%`;

export function formatLvrPercentage(lvr: number): string {
  return `${lvr}%`;
}
