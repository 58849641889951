import { useCallback } from 'react';

import { useEnvConfig } from './hooks/useEnvConfig';

// Refer to https://github.com/unloan/unloan-app/issues/4424 - Unable to transfer to Bankwest (starts with 30) or Mambu (067841) accounts
export const VALID_ACCOUNT_BSB_FOR_WITHDRAWAL_REGEX =
  /^(?=(?!067841))(?=(?!30).\d)/;

export function isBsbNumberForWithdrawValid(bsbNumber: string) {
  return VALID_ACCOUNT_BSB_FOR_WITHDRAWAL_REGEX.test(bsbNumber);
}

export function useIsBsbNumberForWithdrawValid() {
  const { allowTransferToBankwestMambu } = useEnvConfig().config;
  return useCallback(
    (bsbNumber: string) => {
      if (allowTransferToBankwestMambu) {
        return true;
      }
      return isBsbNumberForWithdrawValid(bsbNumber);
    },
    [allowTransferToBankwestMambu],
  );
}
