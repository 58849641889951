import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function WarningIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(55, 50, size);
  const theme = useTheme();
  return (
    <Svg width={width} height={size} viewBox="0 0 55 50" fill="none" {...props}>
      <Path
        d="M7.031 49.59h40.912c3.906 0 6.354-2.813 6.354-6.355 0-1.068-.26-2.135-.833-3.125L32.969 3.443C31.797 1.334 29.635.24 27.5.24c-2.162 0-4.323 1.094-5.495 3.203L1.536 40.136c-.572.99-.859 2.031-.859 3.099 0 3.542 2.448 6.354 6.354 6.354zM27.5 31.75c-1.38 0-2.135-.78-2.188-2.187L24.948 16.7c-.052-1.407 1.042-2.448 2.526-2.448 1.458 0 2.578 1.067 2.526 2.474l-.365 12.838c-.052 1.432-.833 2.188-2.135 2.188zm0 9.61c-1.51 0-2.917-1.198-2.917-2.813 0-1.614 1.38-2.812 2.917-2.812 1.51 0 2.917 1.172 2.917 2.813 0 1.64-1.433 2.812-2.917 2.812z"
        fill={color ?? theme.colors.alert}
      />
    </Svg>
  );
}
