import { styled, SxProp, View } from 'dripsy';
import { useFormikContext } from 'formik';
import { ComponentProps, useCallback, useContext, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormSelectV2,
  FormSuburbAutocompleteInput,
  FormTextInputV2,
  FormTextPickerInputV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { MaxCharacter } from '../../constants/fieldRules';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { ALPHANUMERIC_SEPARATED_BY_DASH_REGEX } from '../../localization/constants';
import { Screen } from '../../navigation/types/screens';
import { makeTestId } from '../../utils/stringHelpers';
import { yup, yupNullableString, yupPostcode } from '../../utils/yup';
import {
  checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames,
  computeManualAddressInputFieldName,
} from '../utils/manualAddressHelper';
import { FormikFormError } from './FormError';

export enum ManualPropertyFieldNames {
  UnitNo = 'unitNo',
  StreetNo = 'streetNo',
  StreetName = 'streetName',
  StreetType = 'streetType',
  Suburb = 'suburb',
  State = 'state',
  Postcode = 'postcode',

  // Internal field name to determine whether current form is using
  // manual or suggested property input
  isManualInput = 'isManualInput',
}

export function getManualInputFieldNames<T extends string>(fieldsPrefix: T) {
  return {
    isManualInput: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.isManualInput,
    ),
    unitNo: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.UnitNo,
    ),
    streetNo: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.StreetNo,
    ),
    streetName: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.StreetName,
    ),
    streetType: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.StreetType,
    ),
    suburb: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.Suburb,
    ),
    state: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.State,
    ),
    postcode: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualPropertyFieldNames.Postcode,
    ),
  };
}

export function getManualInputFieldDefaultValues<T extends string>(
  fieldsPrefix: T,
) {
  const fieldNames = getManualInputFieldNames(fieldsPrefix);
  return {
    [fieldNames.isManualInput]: undefined,
    [fieldNames.postcode]: undefined,
    [fieldNames.state]: undefined,
    [fieldNames.streetName]: undefined,
    [fieldNames.streetNo]: undefined,
    [fieldNames.streetType]: undefined,
    [fieldNames.suburb]: undefined,
    [fieldNames.unitNo]: undefined,
  };
}

/**
 * Use this function to get schema that can be used to extend a form schema
 * to include fields for manual address input
 *
 * so the resulting schema will look like
 *
 * {
 *   ...ExistingSchema,
 *   "FieldPrefix-unitNo": yupValidation(),
 *   "FieldPrefix-streetName": yupValidation(),
 *   "FieldPrefix-streetNo": yupValidation(),
 *   "FieldPrefix-state": yupValidation(),
 *   . . .
 * }
 *
 * @param options - used to determine if field should become
 * required based on other fields values.
 */
export const getManualAddressYupSchema = (
  fieldsPrefix: string,
  options?: {
    dependencyFieldNames: Array<string>;
    /** Current value passed here maps 1:1 from array in dependencyFieldNames */
    dependencyCheckFunctions: Array<(currentValue: unknown) => boolean>;
  },
) => {
  const fieldNames = getManualInputFieldNames(fieldsPrefix);

  return yup.object({
    [fieldNames.isManualInput]: yup.boolean().nullable(),
    [fieldNames.unitNo]: yupNullableString,
    [fieldNames.streetNo]: yupNullableString.when(
      [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
      {
        is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
          options?.dependencyCheckFunctions,
        ),
        then: yupNullableString
          .matches(
            ALPHANUMERIC_SEPARATED_BY_DASH_REGEX,
            'Street number should only contain alphanumeric separated with a dash',
          )
          .required(t('Content.PropertyForm.FormV2.StreetNumberError')),
      },
    ),
    [fieldNames.streetName]: yupNullableString.when(
      [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
      {
        is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
          options?.dependencyCheckFunctions,
        ),
        then: yupNullableString.required(
          t('Content.PropertyForm.FormV2.StreetNameError'),
        ),
      },
    ),
    [fieldNames.streetType]: yup
      .object()
      .nullable()
      .when(
        [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
        {
          is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
            options?.dependencyCheckFunctions,
          ),
          then: yup.object().nullable().shape({
            label: yup.string(),
            value: yup.string(),
          }),
        },
      ),
    [fieldNames.suburb]: yupNullableString.when(
      [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
      {
        is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
          options?.dependencyCheckFunctions,
        ),
        then: yupNullableString.required(
          t('Content.PropertyForm.FormV2.SuburbError'),
        ),
      },
    ),
    [fieldNames.state]: yupNullableString.when(
      [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
      {
        is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
          options?.dependencyCheckFunctions,
        ),
        then: yupNullableString.required(
          t('Content.PropertyForm.FormV2.StateError'),
        ),
      },
    ),
    [fieldNames.postcode]: yupNullableString.when(
      [fieldNames.isManualInput, ...(options?.dependencyFieldNames || [])],
      {
        is: checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
          options?.dependencyCheckFunctions,
        ),
        then: yupPostcode,
      },
    ),
  });
};

export type ManualPropertyFieldsProps = {
  screen: Screen;
  sectionInteractionKey: SectionInteractionKey;
  stateOptions?: PickerOptions<string>;
  streetTypeOptions?: PickerOptions<string>;
  containerSx?: SxProp;
  // This component is designed to work with FormikProvider
  fieldNamePrefix: string;
};

/**
 * This component were made specifically as fragment for manual address input fields.
 * Each of the input fields in this component will have a prefixed field name to use directly
 * with a form provider where this component is at.
 *
 * Use this component inside a <Formik> provider which form schema has been extended
 * with schema from `getManualAddressYupSchema` function
 */
export function ManualPropertyInputFields({
  screen,
  sectionInteractionKey,
  stateOptions = [],
  streetTypeOptions = [],
  containerSx,

  fieldNamePrefix,
}: ManualPropertyFieldsProps) {
  const formik = useFormikContext();

  const fieldNames = useMemo(
    () => getManualInputFieldNames(fieldNamePrefix),
    [fieldNamePrefix],
  );

  const { flags } = useContext(FeatureFlagsContext);
  const enableSuburbAutocomplete = flags.ENABLE_SUBURB_AUTOCOMPLETE;

  const commonSuburbInputProps: Expand<
    ComponentProps<typeof FormSuburbAutocompleteInput> &
      ComponentProps<typeof FormTextInputV2>
  > = useMemo(
    () => ({
      name: fieldNames.suburb,
      sx: { mt: '$16', flex: 1 },
      label: t('Content.PropertyForm.FormV2.Suburb'),
      inputTestID: makeTestId([
        fieldNamePrefix,
        TestID.PropertyDetails.ManualAddressInput.Suburb,
      ]),
      interactionKey: buildApplicationInteractionEventKey(
        sectionInteractionKey,
        screen,
        FieldInteractionKey.Suburb,
      ),
    }),
    [fieldNamePrefix, fieldNames.suburb, screen, sectionInteractionKey],
  );

  const onSuburbSelect = useCallback<
    NonNullable<
      ComponentProps<typeof FormSuburbAutocompleteInput>['onSuburbSelect']
    >
  >(
    (suburbDetails) => {
      const { state, postcode } = suburbDetails;
      if (state != null) {
        formik.setFieldValue(fieldNames.state, state);
      }
      if (postcode != null) {
        formik.setFieldValue(fieldNames.postcode, postcode);
      }
      setTimeout(() => {
        formik.validateForm();
      }, 1);
    },
    [fieldNames.postcode, fieldNames.state, formik],
  );

  const suburbInput = enableSuburbAutocomplete ? (
    <FormSuburbAutocompleteInput
      {...commonSuburbInputProps}
      onSuburbSelect={onSuburbSelect}
    />
  ) : (
    <FormTextInputV2 {...commonSuburbInputProps} />
  );

  return (
    <View
      sx={{
        // Set these fields higher to avoid suggestion items got hidden behind other
        // components
        zIndex: 2,
        ...containerSx,
      }}
    >
      <RowView>
        <FormTextInputV2
          name={fieldNames.unitNo}
          sx={{ mr: '$16', flex: 1 }}
          label={t('Content.PropertyForm.FormV2.Unit')}
          inputTestID={makeTestId([
            fieldNamePrefix,
            TestID.PropertyDetails.ManualAddressInput.UnitNo,
          ])}
          interactionKey={buildApplicationInteractionEventKey(
            sectionInteractionKey,
            screen,
            FieldInteractionKey.UnitNumber,
          )}
        />
        <FormTextInputV2
          name={fieldNames.streetNo}
          sx={{ flex: 1 }}
          label={t('Content.PropertyForm.FormV2.StreetNumber')}
          inputTestID={makeTestId([
            fieldNamePrefix,
            TestID.PropertyDetails.ManualAddressInput.StreetNumber,
          ])}
          interactionKey={buildApplicationInteractionEventKey(
            sectionInteractionKey,
            screen,
            FieldInteractionKey.StreetNumber,
          )}
        />
      </RowView>
      <FormikFormError name={fieldNames.unitNo} sx={{ mt: '$8' }} />
      <FormikFormError name={fieldNames.streetNo} sx={{ mt: '$8' }} />
      <FormTextInputV2
        name={fieldNames.streetName}
        sx={{ mt: '$16', flex: 1 }}
        label={t('Content.PropertyForm.FormV2.StreetName')}
        inputTestID={makeTestId([
          fieldNamePrefix,
          TestID.PropertyDetails.ManualAddressInput.StreetName,
        ])}
        interactionKey={buildApplicationInteractionEventKey(
          sectionInteractionKey,
          screen,
          FieldInteractionKey.StreetName,
        )}
      />
      <FormikFormError name={fieldNames.streetName} sx={{ mt: '$8' }} />
      <FormTextPickerInputV2
        name={fieldNames.streetType}
        sx={{ mt: '$16', flex: 1 }}
        label={t('Content.PropertyForm.FormV2.StreetType')}
        inputTestID={makeTestId([
          fieldNamePrefix,
          TestID.PropertyDetails.ManualAddressInput.StreetType,
        ])}
        optionsData={streetTypeOptions}
        allowUserInput
        maxSuggestions={5}
        interactionKey={buildApplicationInteractionEventKey(
          sectionInteractionKey,
          screen,
          FieldInteractionKey.StreetType,
        )}
      />
      {suburbInput}
      <FormikFormError name={fieldNames.suburb} sx={{ mt: '$8' }} />
      <RowView sx={{ mt: '$16' }}>
        <FormSelectV2
          name={fieldNames.state}
          sx={{ mr: '$16', flex: 1 }}
          label={t('Content.PropertyForm.FormV2.State')}
          testID={makeTestId([
            fieldNamePrefix,
            TestID.PropertyDetails.ManualAddressInput.State,
          ])}
          items={stateOptions}
        />
        <FormTextInputV2
          name={fieldNames.postcode}
          sx={{ flex: 1 }}
          maxLength={MaxCharacter.postcode}
          label={t('Content.PropertyForm.FormV2.Postcode')}
          inputTestID={makeTestId([
            fieldNamePrefix,
            TestID.PropertyDetails.ManualAddressInput.Postcode,
          ])}
          interactionKey={buildApplicationInteractionEventKey(
            sectionInteractionKey,
            screen,
            FieldInteractionKey.Postcode,
          )}
        />
      </RowView>
      <FormikFormError name={fieldNames.state} sx={{ mt: '$8' }} />
      <FormikFormError name={fieldNames.postcode} sx={{ mt: '$8' }} />
    </View>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
