import { useCallback } from 'react';
import * as React from 'react';

import { mockPaymentSource } from '../../AutopaySettings/mockData';
import { ListRow } from '../../ui/molecules/ListRow';
import {
  ListRowGroup,
  Props as ListRowGroupProps,
} from '../../ui/molecules/ListRowGroup';
import { useLayoutAnimation } from '../../ui/utils/useLayoutAnimation';
import { generateCensoredCreditCardNumber } from '../../utils/stringHelpers';
import { FormikControlProps } from './types';

export type Props = FormikControlProps<number | null> &
  ListRowGroupProps & {
    onPress?: () => void;
    account?: typeof mockPaymentSource;
  };

export const PaymentAccountRow: React.FC<Props> = ({
  account,
  setFieldValue,
  value,
  onPress,
  ...props
}) => {
  const onPressWrapped = useCallback(() => {
    // NOTE: Mock onpress
    if (value == null) {
      setFieldValue?.(mockPaymentSource.id);
    } else {
      setFieldValue?.(null);
    }
    onPress?.();
  }, [value, setFieldValue, onPress]);

  useLayoutAnimation([value]);

  return (
    <ListRowGroup {...props}>
      <ListRow
        last
        onPress={onPressWrapped}
        label={account?.name ?? 'Select Account'}
        caption={
          account
            ? generateCensoredCreditCardNumber(account.cardNumber)
            : undefined
        }
        imageUrl={account?.favicon ?? undefined}
        useArrow
        isButton={!account}
        color={account ? undefined : 'disabledContent'}
        borderedImage
      />
    </ListRowGroup>
  );
};
