import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { RemovePropertyActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import { ErrorRow } from '../../components/ErrorRow';
import { AppAddressFormat } from '../../components/form/types';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  refetchLoanApplicationSecuritiesQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  UpsertLoanApplicationSecurityMutationVariables,
  useGetLoanApplicationDetailsQuery,
  useUpsertLoanApplicationSecurityMutation,
} from '../../generated/graphql';
import { useStateOptionsQuery } from '../../Identification/hooks/useStateOptions';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import { PropertyDetailsForm } from '../components/PropertyDetailsForm';
import { usePropertyOptions } from '../graphql/query';
import { useGetInitialFormValues } from '../hooks/useGetInitialFormValues';
import {
  PropertyDetailsFormValue,
  propertyDetailsManualInputFieldNames,
} from '../utils/propertyDetailsFormUtils';
import {
  getUpsertApplicationSecurityErrorMessage,
  mapPropertyPurposeForV2,
} from '../utils/propertyDetailsUtils';

export type Props = SingleModalStackScreenProps<Screen.YOUR_PROPERTY_V2_MODAL>;

export function PropertyDetailsV2({ navigation, route }: Props) {
  const { loanApplicationId, loanApplicationSecurityId, _initialErrorMessage } =
    route.params || {};

  const { tryNavigateBackToSummary, shouldBackToAppSummary } =
    useAppSummaryScreenNavigation({
      navigation,
      route,
      loanApplicationId,
    });

  const [formErrorMessage, setFormErrorMessage] = useState(
    _initialErrorMessage ?? '',
  );
  const removePropertyActionSheetErrorMessage = useRecoilValue(
    RemovePropertyActionSheetErrorAtom,
  );
  const resetRemovePropertyActionSheetErrorMessage = useResetRecoilState(
    RemovePropertyActionSheetErrorAtom,
  );

  useFocusEffect(() => () => resetRemovePropertyActionSheetErrorMessage());

  const {
    loadingPropertyOptions,
    propertyPurposeOptions,
    propertyTypeOptions,
  } = usePropertyOptions();

  const { data: stateOptions, loading: stateOptionsLoading } =
    useStateOptionsQuery();

  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  const { data, loading: loanApplicationDetailsLoading } =
    useGetLoanApplicationDetailsQuery({
      variables: {
        id: loanApplicationId || '',
      },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

  // Populate the initial state when provided.
  const {
    initialFormValues,
    loanApplicationTargetId: currentSecurityLoanApplicationTargetId,
    loading: initialFormLoading,
  } = useGetInitialFormValues({
    loanApplicationId,
    loanApplicationSecurityId,
  });

  const [
    upsertLoanApplicationSecurity,
    { loading: upsertLoanApplicationSecurityLoading },
  ] = useUpsertLoanApplicationSecurityMutation();
  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToPropertyScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Property,
      });
    });
  };

  const onSubmit = async (values: PropertyDetailsFormValue) => {
    resetRemovePropertyActionSheetErrorMessage();

    const { propertyType, propertyValue, addressResult, propertyPurpose } =
      values;

    const isManualInput =
      values[propertyDetailsManualInputFieldNames.isManualInput];
    let addressToSubmit: AppAddressFormat | null = null;
    if (isManualInput) {
      addressToSubmit = {
        isProvidedByUser: true,
        postcode: values[propertyDetailsManualInputFieldNames.postcode],
        street: values[propertyDetailsManualInputFieldNames.streetName],
        streetNo: values[propertyDetailsManualInputFieldNames.streetNo],
        unitNo: values[propertyDetailsManualInputFieldNames.unitNo],
        state: values[propertyDetailsManualInputFieldNames.state],
        streetType:
          values[propertyDetailsManualInputFieldNames.streetType]?.value,
        suburb: values[propertyDetailsManualInputFieldNames.suburb],
      };
    } else {
      addressToSubmit = addressResult ?? null;
    }

    if (
      !loanApplicationId ||
      !propertyType ||
      propertyValue == null ||
      propertyPurpose == null ||
      addressToSubmit == null
    ) {
      setFormErrorMessage(
        t('Content.PropertyDetails.Error.FailSubmittingForm'),
      );
      return;
    }

    const {
      manualAddressInput: validatedManualAddressInput,
      domainApiAddressInput: validatedDomainApiAddressInput,
    } = parseAddressInputForMutationAction(addressToSubmit);

    const propertyVariables: Pick<
      UpsertLoanApplicationSecurityMutationVariables,
      'manualAddressInput' | 'domainApiAddressInput'
    > = {
      domainApiAddressInput: validatedDomainApiAddressInput,
      manualAddressInput: validatedManualAddressInput,
    };

    if (
      !propertyVariables.domainApiAddressInput &&
      !propertyVariables.manualAddressInput
    ) {
      setFormErrorMessage(t('Content.Common.Error.InvalidInputtedAddress'));
      return;
    }
    const loanApplicationTargetId =
      currentSecurityLoanApplicationTargetId ??
      data?.loan_application_by_pk?.loan_application_targets?.[0]?.id;

    const variables: UpsertLoanApplicationSecurityMutationVariables = {
      loanApplicationSecurityId,
      loanApplicationTargetId,
      loanApplicationId,
      propertyType,
      propertyValue,
      propertyPurpose,
      ...propertyVariables,
      isCreate: !loanApplicationSecurityId,
    };
    const [res] = await safelyCallMutation(upsertLoanApplicationSecurity, {
      variables,
      refetchQueries: [
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: { variables, loanApplicationId },
      },
    });

    const errMessage = getUpsertApplicationSecurityErrorMessage(res);

    if (errMessage != null) {
      setFormErrorMessage(errMessage);
      return;
    }

    navigateBackToPropertyScreen();
  };

  const onDelete = async () => {
    navigation.navigate(ActionSheetType.REMOVE_PROPERTY_V2, {
      loanApplicationSecurityId: loanApplicationSecurityId || '',
      loanApplicationId: loanApplicationId ?? '',
      ...route.params,
    });
  };

  const showLoading =
    loanApplicationDetailsLoading ||
    loadingPropertyOptions ||
    stateOptionsLoading ||
    initialFormLoading;

  return (
    <ModalScreenContainer
      headerText={t('Content.PropertyDetails.Header.Title')}
      scrollable
      hideBackButton
      onClose={navigateBackToPropertyScreen}
      loading={upsertLoanApplicationSecurityLoading}
    >
      {showLoading ? (
        <ScreenLoadingContainer loading />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={removePropertyActionSheetErrorMessage || formErrorMessage}
          />
          <PropertyDetailsForm
            screen={Screen.YOUR_PROPERTY_V2_MODAL}
            initialValues={initialFormValues}
            propertyPurposeOptions={mapPropertyPurposeForV2(
              propertyPurposeOptions,
            )}
            propertyTypeOptions={propertyTypeOptions}
            stateOptions={stateOptions}
            streetTypeOptions={streetTypeOptions}
            isSubmitting={upsertLoanApplicationSecurityLoading}
            onSubmit={onSubmit}
            isLoadingSuggestionData={isLoadingSuggestionData}
            loadSuggestionData={loadSuggestionData}
            propertySuggestionData={propertySuggestionData}
            onDelete={onDelete}
            showDeleteButton={
              shouldBackToAppSummary ? false : !!loanApplicationSecurityId
            }
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
