import { withAuthenticationRequired as withAuth0AuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';

import { isRunningOnStorybook } from '../utils/isRunningOnStorybook';

const IsStorybook = isRunningOnStorybook();
// eslint-disable-next-line @typescript-eslint/ban-types
export function withAuthenticationRequired<Props extends object>(
  Component: ComponentType<Props>,
) {
  if (IsStorybook) {
    return Component;
  }
  return withAuth0AuthenticationRequired(Component);
}
