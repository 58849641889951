import { StatusBar, StatusBarProps } from 'expo-status-bar';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '../ui/atoms/Box';
import {
  Props as ScreenContainerProps,
  ScreenContainer,
} from '../ui/atoms/ScreenContainer';
import { ScrollContainer } from '../ui/atoms/ScrollContainer';
import { useTheme } from '../ui/theme';
import { useIsTablet } from '../utils/hooks/useBreakpoint';
import { MAX_MOBILE_WIDTH } from '../utils/NavConstants';

type Props = ScreenContainerProps & {
  statusBarStyle?: StatusBarProps['style'];
  /** When set to true, will add padding horizontal. Default to true */
  inset?: boolean;
  /**
   * When set to true, will add ScrollContainer as the wrapper.
   * When `scrollable` & `inset` are true, padding will be applied inside the Scroll Container.
   */
  scrollable?: boolean;
};

const CONTENT_CONTAINER_STYLE = { px: { tablet: 'm' as const } };

export function ModalScreenContainer({
  statusBarStyle = 'light',
  inset = true,
  scrollable = true,
  children,
  ...props
}: Props) {
  const isTablet = useIsTablet();
  const theme = useTheme();
  return (
    <>
      <ScreenContainer
        safeAreaTop={false}
        pt={0}
        width="100%"
        containerStyle={isTablet ? styles.container : undefined}
        {...(inset && !scrollable ? CONTENT_CONTAINER_STYLE : {})}
        {...props}
      >
        {scrollable ? (
          <ScrollContainer
            contentContainerStyle={{
              paddingHorizontal:
                isTablet && inset
                  ? theme.spacing[CONTENT_CONTAINER_STYLE.px.tablet]
                  : 0,
            }}
          >
            {children}
          </ScrollContainer>
        ) : (
          children
        )}
      </ScreenContainer>
      <StatusBar style={statusBarStyle} />
    </>
  );
}

/**
 * This is a content wrapper for Modal's screen.
 * This can be used in case we don't want to apply padding to the whole screen (e.g. when you have Footer in your modal)
 *
 * Usage:
 * 💡 Set `inset` prop to false so you don't have double padding
 * ```
 * <ModalScreenContainer>
 *  <ModalScreenContainer.Content>
 *    <StyledText> Hi </StyledText>
 *  </ ModalScreenContainer.Content>
 * </ ModalScreenContainer>
 * ```
 */
ModalScreenContainer.Content = function ContentContainer({
  children,
  scrollable,
}: {
  children: ReactNode;
  /**
   * When set to true, will add ScrollContainer as the wrapper.
   * E.g. If you are using ModalScreenContainer.Content and ModalFooter but don't want ModalFooter to be scrollable,
   * set `scrollable` to true here instead of in ModalScreenContainer.
   */
  scrollable?: boolean;
}) {
  const content = (
    <Box flex={1} {...CONTENT_CONTAINER_STYLE}>
      {children}
    </Box>
  );

  return scrollable ? <ScrollContainer>{content}</ScrollContainer> : content;
};

const styles = StyleSheet.create({
  container: {
    maxWidth: MAX_MOBILE_WIDTH,
    alignSelf: 'center',
    width: '100%',
  },
});
