import { Nav } from '@expo/html-elements';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import * as React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { BlurView } from '../../../components/BlurView';
import { ChatButton } from '../../../components/ChatButton';
import { UnloanBuiltByCBALogo } from '../../../components/UnloanBuiltByCBALogo';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../../constants/intercom';
import { Screen } from '../../../navigation/types/screens';
import { Box } from '../../../ui/atoms/Box';
import { useTheme } from '../../../ui/theme';
import { useIsMobile, useView } from '../../../utils/hooks/useBreakpoint';
import { isWeb } from '../../../utils/platformUtils';
import { useIntercom } from '../utils/useIntercom';
import { ProfileMenu } from './ProfileMenu';

type Props = Partial<StackHeaderProps> & {
  hideProfileButton?: boolean;
  hideIntercomButton?: boolean;
};

export const TopNavBar: React.FC<Props> = ({
  hideIntercomButton = false,
  hideProfileButton = false,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { isMobileNativeView } = useView();
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { unreadMessagesCount } = useIntercom();
  const isCurrentScreenFocused = useIsFocused();

  const navigateToDashboard = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);

  return (
    <BlurView>
      <Nav aria-hidden={!isCurrentScreenFocused}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pb="s"
          borderBottomWidth={isMobile ? 0 : 1}
          borderBottomColor="border"
          px={isMobile ? 'm' : 'xl'}
          style={{
            paddingTop: insets.top || theme.spacing.s,
            backgroundColor: isMobileNativeView
              ? theme.colors.bg
              : 'transparent',
          }}
        >
          {isMobileNativeView ? null : (
            <>
              <UnloanBuiltByCBALogo onPress={navigateToDashboard} />
              <Box row>
                {!hideIntercomButton ? (
                  <ChatButton
                    showBadge={!!unreadMessagesCount}
                    nativeID={
                      isWeb ? INTERCOM_CUSTOM_LAUNCHER_SELECTOR : undefined
                    }
                  />
                ) : null}

                {!hideProfileButton ? <ProfileMenu /> : null}
              </Box>
            </>
          )}
        </Box>
      </Nav>
    </BlurView>
  );
};

export const UnauthenticatedTopNavBar = () => (
  <TopNavBar hideIntercomButton hideProfileButton />
);
