import { endOfDay, isBefore, isSameDay, startOfDay } from 'date-fns';
import { Text, View } from 'dripsy';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { Form, FormDateInputV2 } from '../../components/form/FormikInputs';
import { SubmitButton } from '../../components/form/SubmitButton';
import { Screen } from '../../navigation/types/screens';
import { FormikFormError } from '../../ui/v2/FormError';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { yup } from '../../utils/yup';
import { StatementsV2ModalScreenProps } from '../navigation/types';
import {
  StatementDateRangeFormField,
  useStatementDateRangeValidationSchema,
} from '../utils/useStatementDateRangeValidationSchema';

export type FormValues = yup.Asserts<
  ReturnType<typeof useStatementDateRangeValidationSchema>
>;
type Props = StatementsV2ModalScreenProps<Screen.STATEMENTS_FILTER_V2_MODAL>;

export function StatementsFilterV2({ navigation, route }: Props) {
  // TODO: support checking on loan close date
  const validationSchema = useStatementDateRangeValidationSchema({
    loanStartDate: route.params.startDate,
  });

  const initialValues = useMemo<FormValues>(
    () => ({
      startDate: startOfDay(new Date(route.params.startDate)),
      endDate: new Date(route.params.endDate),
    }),
    [route.params.endDate, route.params.startDate],
  );

  const onSubmit = (value: FormValues, formik: FormikHelpers<FormValues>) => {
    formik.setSubmitting(false);

    navigation.navigate(Screen.STATEMENTS_V2_MODAL, {
      screen: Screen.STATEMENTS_PREVIEW_V2_MODAL,
      params: {
        cbaAccountId: route.params.cbaAccountId,
        startDate: isBefore(value.startDate, route.params.startDate)
          ? route.params.startDate
          : startOfDay(value.startDate).getTime(),
        endDate: isSameDay(value.endDate, new Date())
          ? new Date().getTime()
          : endOfDay(value.endDate).getTime(),
      },
    });
  };

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.StatementsV2.GenerateAStatement')}
      onClose={onClose}
      scrollable
      hideBackButton
    >
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formProps) => (
          <Form overflow="visible">
            <>
              <Text variant="sHeader" sx={{ mb: '$16' }}>
                {t('Content.StatementsV2.FilterStartDate')}
              </Text>

              <FormDateInputV2
                inputTestID={TestID.Statements.StatementsFilter.StartDateInput}
                name={StatementDateRangeFormField.startDate}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Statements,
                  Screen.STATEMENTS_FILTER_V2_MODAL,
                  FieldInteractionKey.StartDate,
                )}
              />

              <FormikFormError
                testID={TestID.Statements.StatementsFilter.StartDateInputAlert}
                name={StatementDateRangeFormField.startDate}
                sx={{ mt: '$16' }}
              />
            </>

            <View sx={{ mt: '$40' }}>
              <Text variant="sHeader" sx={{ mb: '$16' }}>
                {t('Content.StatementsV2.FilterEndDate')}
              </Text>

              <FormDateInputV2
                name={StatementDateRangeFormField.endDate}
                inputTestID={TestID.Statements.StatementsFilter.EndDateInput}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Statements,
                  Screen.STATEMENTS_FILTER_V2_MODAL,
                  FieldInteractionKey.EndDate,
                )}
              />

              <FormikFormError
                testID={TestID.Statements.StatementsFilter.EndDateInputAlert}
                name={StatementDateRangeFormField.endDate}
                sx={{ mt: '$16' }}
              />
            </View>
            <SubmitButton
              label={t('Content.StatementsV2.FilterDateDone')}
              testID={TestID.Statements.StatementsFilter.DoneButton}
              onPress={() => formProps.handleSubmit()}
              sx={{ mt: '$32' }}
            />
          </Form>
        )}
      </Formik>
    </ModalScreenContainer>
  );
}
