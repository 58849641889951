import { createStackNavigator } from '@react-navigation/stack';

import { SelectInstitutionStackParams } from '../navigation/types/navTypes';
import { getPageTitle, Screen } from '../navigation/types/screens';
import { TranslucentModalNavOptions } from '../utils/NavConstants';
import { BankAccountRefresh } from './screens/BankAccountRefresh';
import { BankLogin } from './screens/BankLogin';
import { ManageConsent } from './screens/ManageConsents';
import { SelectBank } from './screens/SelectBank';

const Stack = createStackNavigator<SelectInstitutionStackParams>();
export function SelectInstitutionNavigator() {
  return (
    <Stack.Navigator screenOptions={TranslucentModalNavOptions}>
      <Stack.Screen
        name={Screen.BANK_LOGIN}
        component={BankLogin}
        options={{
          headerTitle: t('Content.BankLogin.ScreenTitle'),
          title: getPageTitle(Screen.BANK_LOGIN),
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={Screen.BANK_REFRESH}
        component={BankAccountRefresh}
        options={{
          headerTitle: t('Content.BankLogin.ScreenTitle'),
          title: getPageTitle(Screen.BANK_REFRESH),
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={Screen.SELECT_BANK}
        component={SelectBank}
        options={{
          headerTitle: t('Content.SelectInstitution.ScreenTitle'),
          title: getPageTitle(Screen.SELECT_BANK),
        }}
      />
      <Stack.Screen
        name={Screen.MANAGE_CONSENTS}
        component={ManageConsent}
        options={{
          headerTitle: t('Content.ManageConsents.ScreenTitle'),
          title: getPageTitle(Screen.MANAGE_CONSENTS),
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}
