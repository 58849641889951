import Svg, { Path, Rect } from 'react-native-svg';

import { SvgIconProps } from './types';

export function IdentityDocumentIcon(props: SvgIconProps) {
  return (
    <Svg width={136} height={85} viewBox="34 0 136 85" fill="none" {...props}>
      <Rect
        x={34.2422}
        y={0.333496}
        width={135.111}
        height={85.9259}
        rx={8}
        fill="#F4F4F4"
      />
      <Path
        d="M34.2422 8.3335C34.2422 3.91522 37.8239 0.333496 42.2422 0.333496H161.353C165.772 0.333496 169.353 3.91522 169.353 8.3335V16.3335H34.2422V8.3335Z"
        fill="black"
        fillOpacity={0.1}
      />
      <Rect
        x={85.2052}
        y={30.5557}
        width={28.4444}
        height={3.55556}
        rx={1.77778}
        fill="black"
        fillOpacity={0.2}
      />
      <Rect
        x={85.2052}
        y={40.0371}
        width={55.7037}
        height={3.55555}
        rx={1.77777}
        fill="black"
        fillOpacity={0.2}
      />
      <Rect
        x={85.2052}
        y={49.5186}
        width={7.11111}
        height={3.55556}
        rx={1.77778}
        fill="black"
        fillOpacity={0.2}
      />
      <Rect
        x={95.8718}
        y={49.5186}
        width={7.11111}
        height={3.55556}
        rx={1.77778}
        fill="black"
        fillOpacity={0.2}
      />
      <Rect
        x={106.538}
        y={49.5186}
        width={7.11111}
        height={3.55556}
        rx={1.77778}
        fill="black"
        fillOpacity={0.2}
      />
      <Path
        d="M123.723 76.7781C131.723 76.4818 145.649 65.8152 137.056 65.2226C128.464 64.63 114.242 67.2966 113.056 71.1485C112.108 74.23 132.612 71.0498 142.982 69.0745C141.995 70.0621 139.96 72.0374 139.723 72.0374C139.486 72.0374 142.39 71.4448 143.871 71.1485V72.0374L148.612 69.9633C148.118 70.7534 147.782 72.0966 150.39 71.1485C153.649 69.9633 143.871 74.4078 146.242 74.4078C148.612 74.4078 149.797 68.7782 151.279 69.9633C152.464 70.9115 154.637 71.1485 155.131 71.1485C156.118 71.7411 158.745 71.8596 160.168 71.1485"
        stroke="black"
        strokeOpacity={0.2}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M43.1311 29.2222C43.1311 27.013 44.922 25.2222 47.1311 25.2222H72.3163C74.5254 25.2222 76.3163 27.013 76.3163 29.2222V64.4814C76.3163 66.6906 74.5254 68.4814 72.3163 68.4814H47.1311C44.922 68.4814 43.1311 66.6906 43.1311 64.4814V29.2222Z"
        fill="black"
        fillOpacity={0.05}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3163 60.7052V64.4815C76.3163 66.6907 74.5254 68.4815 72.3163 68.4815H47.1311C44.922 68.4815 43.1311 66.6907 43.1311 64.4815V60.3951C47.2163 57.2058 53.5364 55.0927 59.5298 55.0927C65.7397 55.0927 72.2566 57.3434 76.3163 60.7052ZM69.2162 39.321C69.2162 33.6041 64.9335 28.7778 59.5298 28.7778C54.1549 28.7778 49.8147 33.6041 49.8435 39.321C49.8435 45.4114 54.0399 50.1228 59.5298 50.1228C65.0485 50.1228 69.2162 45.4114 69.2162 39.321Z"
        fill="black"
        fillOpacity={0.6}
      />
    </Svg>
  );
}
