import { gql } from '@apollo/client';
import { View } from 'dripsy';
import { useEffect } from 'react';

import { TestID } from '../../../testID/constants';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { RefreshPageErrorRow } from '../../components/RefreshPageErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  useGetMinimumRepaymentLazyQuery,
  useGetStoredLoanAmountAndSecurityCostsLazyQuery,
  usePurchaseYourUnloanScreenQuery,
} from '../../generated/graphql';
import { UpfrontCostsDetails } from '../../LoanConfiguration/screens/components/UpfrontCostsDetails';
import { Screen } from '../../navigation/types/screens';
import { useSecurityCostCalculationState } from '../../utils/hooks/useSecurityCostCalculationState';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { PurchaseYourUnloanCard } from '../components/PurchaseYourUnloanCard';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

export const PurchaseYourUnloanScreen = gql`
  query PurchaseYourUnloanScreen($loanApplicationId: uuid!) {
    loan_application_target(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      term_months
      loan_application_securities {
        id
        property {
          id
          address {
            id
            state
          }
        }
      }
      target_product_rate {
        interest_rate
        id: product_rate_id
        rate_adjustment
        product_type
      }
    }
    loanApplication: loan_application_by_pk(id: $loanApplicationId) {
      id
      ...LoanApplicationForYourUnloanPurchaseScreen
    }
  }

  fragment LoanApplicationForYourUnloanPurchaseScreen on loan_application {
    id
    type
    incomes(
      where: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
    ) {
      id
      rental_income {
        id
        estimated_loan_amount
      }
    }
  }

  query GetStoredLoanAmountAndSecurityCosts($loanApplicationId: uuid!) {
    loan_application_target(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      loan_amount
      total_and_fees {
        id
        lmi_premium
      }
    }

    get_estimated_upfront_costs_and_max_loan(
      input: { loan_application_id: $loanApplicationId }
    ) {
      ...EstimatedUpfrontCostsAndMaxLoan
    }
  }

  fragment EstimatedUpfrontCostsAndMaxLoan on get_estimated_upfront_costs_and_max_loan_output {
    maxLoanAmount: max_loan_amount
    maxLvr: max_lvr
    rangeLvrWithLmiMin: range_lvr_with_lmi_min
    rangeLvrWithLmiMax: range_lvr_with_lmi_max
    government_fees {
      totalGovernmentFees: total_government_fees
      dischargeFee: discharge_fee
      registrationFee: registration_fee
      transferFee: transfer_fee
    }
    upfront_costs {
      totalUpfrontCost: total_upfront_cost
      stampDuty: stamp_duty
      estimatedConveyancing: estimated_conveyancing
      deposit
    }
  }
`;

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN>;

export function LoanApplicationYourUnloanPurchase({
  navigation,
  route,
}: Props) {
  const loanApplicationId = route.params?.loanApplicationId;

  const [
    callMinimumRepaymentQuery,
    { data: minimumRepaymentResult, loading: minRepaymentLoading },
  ] = useGetMinimumRepaymentLazyQuery();

  const { data, loading, error, refetch } = usePurchaseYourUnloanScreenQuery({
    variables: {
      loanApplicationId: loanApplicationId ?? '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [
    getLoanAmountAndSecurityCosts,
    {
      data: loanAmountAndSecurityCosts,
      loading: getLoanAmountAndSecurityCostsLoading,
    },
  ] = useGetStoredLoanAmountAndSecurityCostsLazyQuery();

  const {
    waitingForCostCalculationToBeCompleted,
    isWaitingForRetryToComplete,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    onCalculationComplete: async () => {
      try {
        await getLoanAmountAndSecurityCosts({
          variables: { loanApplicationId: loanApplicationId ?? '' },
          fetchPolicy: 'network-only',
          context: {
            sentryContext: {
              loanApplicationId,
            },
          },
        });
      } catch (e) {
        // Error has been logged using apollo error link
      }
    },
  });

  const loadingLoanAmountAndSecurityCosts =
    getLoanAmountAndSecurityCostsLoading ||
    waitingForCostCalculationToBeCompleted;

  const loanApplicationTarget = data?.loan_application_target?.[0];
  const termInMonths = loanApplicationTarget?.term_months ?? 0;
  const interestRate =
    loanApplicationTarget?.target_product_rate?.interest_rate;

  const loanSecurityState =
    loanApplicationTarget?.loan_application_securities?.[0]?.property.address
      .state;

  const { totalUpfrontCost, stampDuty, estimatedConveyancing, deposit } =
    loanAmountAndSecurityCosts?.get_estimated_upfront_costs_and_max_loan
      .upfront_costs ?? {};
  const governmentFees =
    loanAmountAndSecurityCosts?.get_estimated_upfront_costs_and_max_loan
      .government_fees;
  // Why this screen access loan target from 2 different query?
  const firstTargetInLoanAmountAndSecurityCosts =
    loanAmountAndSecurityCosts?.loan_application_target?.[0];
  const loanAmount = firstTargetInLoanAmountAndSecurityCosts?.loan_amount;
  const lmiPremium =
    firstTargetInLoanAmountAndSecurityCosts?.total_and_fees?.lmi_premium;

  useEffect(() => {
    if (loanAmount && interestRate && termInMonths) {
      callMinimumRepaymentQuery({
        variables: {
          loanAmount,
          interestRate,
          termInMonths,
        },
      });
    }
  }, [callMinimumRepaymentQuery, interestRate, loanAmount, termInMonths]);

  const minimumRepaymentAmount =
    minimumRepaymentResult?.repayment_schedule.monthly_amount;
  const minRepaymentCalcError =
    minimumRepaymentResult?.repayment_schedule.repayment_calculation_error;

  const navigateToSetupLoan = () =>
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.SETUP_LOAN_FOR_PURCHASE_MODAL,
      params: {
        loanApplicationId,
      },
    });
  const hasLoanApplicationTarget =
    data?.loan_application_target != null &&
    data?.loan_application_target.length > 0;
  const onPrimaryButtonPress = () => {
    if (hasLoanApplicationTarget) {
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.FINANCIAL_DECLARATION_MODAL_V2,
        params: { loanApplicationId },
      });
    } else {
      navigateToSetupLoan();
    }
  };
  if (loading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.PurchaseYourUnloan.Header.Title')}
          caption={t('Content.PurchaseYourUnloan.Header.Caption')}
        />
        <ScreenLoadingContainer loading />
      </LoanApplicationScreenContainer>
    );
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.YourUnloan.QueryError')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: data?.loanApplication,
  });
  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer flex={1}>
      <NavHeaderSpacer />
      {shouldShowErrorForCostCalculation ? (
        <View sx={{ mx: '$32' }}>
          <RefreshPageErrorRow
            onRefreshPress={retrySecurityCostCalculation}
            refreshDisabled={isWaitingForRetryToComplete}
            refreshLoading={isWaitingForRetryToComplete}
          />
        </View>
      ) : null}
      <LoanScreenHeader
        title={t('Content.PurchaseYourUnloan.Header.Title')}
        caption={t('Content.PurchaseYourUnloan.Header.Caption')}
      />
      {hasLoanApplicationTarget ? (
        <View sx={{ mx: '$32', mb: '$32' }}>
          <PurchaseYourUnloanCard
            containerSx={{ mt: '$32', mb: '$8' }}
            loanAmount={loanAmount}
            lmiPremium={lmiPremium}
            loanAmountLoading={loadingLoanAmountAndSecurityCosts}
            minRepaymentAmount={minimumRepaymentAmount}
            minRepaymentCalcError={minRepaymentCalcError}
            minRepaymentAmountLoading={minRepaymentLoading}
            termInMonths={termInMonths}
            interestRate={interestRate}
            rateAdjustment={
              loanApplicationTarget?.target_product_rate.rate_adjustment
            }
            productType={
              loanApplicationTarget?.target_product_rate.product_type
            }
            canBePressed
            onPress={navigateToSetupLoan}
            governmentFees={governmentFees?.totalGovernmentFees}
            cardTestID={TestID.YourUnloan.CardTestId}
            totalLoanAmountTestID={TestID.YourUnloan.TotalLoanAmountTestId}
          />
          <UpfrontCostsDetails
            loanSecurityState={loanSecurityState}
            deposit={deposit}
            depositLoading={loadingLoanAmountAndSecurityCosts}
            estimatedConveyancing={estimatedConveyancing}
            estimatedConveyancingLoading={loadingLoanAmountAndSecurityCosts}
            stampDuty={stampDuty}
            stampDutyLoading={loadingLoanAmountAndSecurityCosts}
            estimatedTotalUpfrontCost={totalUpfrontCost}
            estimatedTotalUpfrontCostLoading={loadingLoanAmountAndSecurityCosts}
          />
        </View>
      ) : (
        <EmptyState />
      )}

      <LoanApplicationWizardFooter
        primaryButtonLabel={
          loanApplicationTarget
            ? t('Content.Common.ButtonLabel.Continue')
            : t('Content.YourUnloan.SetupHomeLoan')
        }
        primaryButtonTestID={TestID.YourUnloan.ContinueButton}
        onPrimaryButtonPress={onPrimaryButtonPress}
        isPrimaryButtonLoading={loading}
        footerCaption={
          loanApplicationTarget ? t('Content.YourUnloan.Footer') : undefined
        }
        disablePrimaryButton={loadingLoanAmountAndSecurityCosts}
      />
    </LoanApplicationScreenContainer>
  );
}

function EmptyState() {
  return (
    <View
      sx={{ alignItems: 'center', my: '$32' }}
      testID={TestID.YourUnloan.EmptyStateIllustration}
    >
      <EmptyStateIllustration name="loanV2" />
    </View>
  );
}
