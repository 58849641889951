import { memo } from 'react';

import { StyledImage } from '../../ui/atoms/StyledImage';

type Props = {
  link: string;
};

export const ImagePreview = memo(({ link }: Props) => (
  <StyledImage
    source={{ uri: link }}
    width="100%"
    maxHeight="100%"
    flexGrow={1}
    contentFit="contain"
  />
));
