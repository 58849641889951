import { useIsFocused } from '@react-navigation/native';
import { isNull } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import * as React from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ArrowBackButton } from '../../components/ArrowBackButton';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { SuccessRow } from '../../components/SuccessRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Approval_Status_Enum } from '../../generated/graphql';
import { useLoanAccountWithTransactions } from '../../HomeLoan/graphql/loanAccountQueries';
import { useShowHomeLoanModal } from '../../HomeLoan/utils/useShowHomeLoanModal';
import { Screen } from '../../navigation/types/screens';
import { PaymentFailureNotification } from '../../PaymentFailure/components/PaymentFailureNotification';
import { Alert } from '../../ui/atoms/Alert';
import { Box } from '../../ui/atoms/Box';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { LoadingRow } from '../../ui/molecules/LoadingRow';
import { useTheme } from '../../ui/theme';
import { UpcomingCard } from '../../ui/v2/UpcomingCard';
import { useIsDesktop, useIsMobile } from '../../utils/hooks/useBreakpoint';
import { HomeLoanButtons } from '../components/HomeLoanButtons';
import { HomeLoanColumn } from '../components/HomeLoanColumn';
import { HomeLoanGrid } from '../components/HomeLoanGrid';
import { HomeLoanHeading } from '../components/HomeLoanHeading';
import { HomeLoanInsightsWidgetsContainer } from '../components/HomeLoanInsightsWidgetsContainer';
import { HomeLoanOnSchedulePayment } from '../components/HomeLoanOnSchedulePayment';
import { HomeLoanProgressBar } from '../components/HomeLoanProgressBar';
import { HomeLoanTitle } from '../components/HomeLoanTitle';
import { InsightsDiscountBumpWidget } from '../components/InsightsDiscountBumpWidget';
import { InsightsInterestSavingsWidget } from '../components/InsightsInterestSavingsWidget';
import { InsightsPropertyValueWidget } from '../components/InsightsPropertyValueWidget';
import { InsightsTermRemainingWidget } from '../components/InsightsTermRemainingWidget';
import { PendingWithdrawals } from '../components/PendingWithdrawals';
import { ProcessingPendingWithdrawals } from '../components/ProcessingPendingWithdrawals';
import { TransactionList } from '../components/TransactionList';
import { TransferDisabledWarningRow } from '../components/TransferDisabledWarningRow';
import { HomeTabScreenProps } from '../navigation/types';
import { isWithdrawDisabled } from '../utils/isWithdrawDisabled';
import { homeLoanSuccessMessageAtom } from './recoil/homeLoanSuccessMessage';

type Props = HomeTabScreenProps<Screen.HOME_LOAN>;

const HomeLoanBase: React.FC<Props> = ({ navigation, route }) => {
  const { constants } = useTheme();
  const isMobile = useIsMobile();

  const [successMessage, setSuccessMessage] = useRecoilState(
    homeLoanSuccessMessageAtom,
  );

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) {
      setSuccessMessage(null);
    }
  }, [isFocused, setSuccessMessage]);

  const { cbaAccountId } = route.params;

  const { flags } = useContext(FeatureFlagsContext);

  const {
    loading: loanAccountWithTransactionsLoading,
    data: loan,
    refetch,
    error,
  } = useLoanAccountWithTransactions(cbaAccountId);

  const isAtLeastOneInsightEnabled =
    flags.ENABLE_ACCOUNT_INSIGHTS_DISCOUNT_BUMP ||
    flags.ENABLE_ACCOUNT_INSIGHTS_ESTIMATED_PROPERTY_VALUE ||
    flags.ENABLE_ACCOUNT_INSIGHTS_TERM_REMAINING ||
    flags.ENABLE_ACCOUNT_INSIGHTS_TOTAL_INTEREST_SAVINGS;

  const withdrawDisabled = isWithdrawDisabled(loan);

  const { showHomeLoanModal } = useShowHomeLoanModal({
    navigation,
    route,
    isWithdrawDisabled: withdrawDisabled,
    loading: loanAccountWithTransactionsLoading,
  });

  const loading = loanAccountWithTransactionsLoading;

  useEffect(() => {
    if (!loan && !loading) {
      // eslint-disable-next-line no-console
      console.error('Loan is null for cba_account_id', cbaAccountId);
      Alert.alert(
        'Error occured while trying to fetch loan details. Please try again later',
      );
      navigation.goBack();
    }
  }, [cbaAccountId, loading, loan, navigation]);

  const onDetailPress = () =>
    navigation.navigate(Screen.HOME_LOAN_SETTINGS, { cbaAccountId });

  const onWithdrawPress = () => showHomeLoanModal(Screen.HOME_LOAN_WITHDRAWAL);

  const onPressTransaction = useCallback(
    (transactionId: string) => {
      navigation.navigate(Screen.HOME_LOAN_MODAL, {
        screen: Screen.HOME_PROCESSED_TRANSACTION,
        params: {
          cbaAccountId,
          transactionId,
        },
      });
    },
    [cbaAccountId, navigation],
  );

  const openPaymentFailureDetailsModal = useCallback(() => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.PAYMENT_FAILURE_MODAL,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const isDesktop = useIsDesktop();

  const pendingWithdrawals =
    loan?.pending_withdrawals?.filter(
      (item) =>
        item.withdrawal_request?.approval_request?.status ===
        Approval_Status_Enum.Pending,
    ) ?? [];

  const processingPendingWithdrawals =
    loan?.pending_withdrawals?.filter(
      (item) =>
        item.withdrawal_request?.approval_request?.status !==
        Approval_Status_Enum.Pending,
    ) ?? [];

  const propertyShortAddress =
    loan?.loan_application_security?.property?.address?.short_address_format;

  return (
    <UpliftScreenContainer
      bg="bg"
      safeAreaBottom={false}
      contentContainer={false}
      containerStyle={{ width: '100%' }}
    >
      <ScreenErrorFallback
        error={error}
        refetch={refetch}
        displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
      >
        <HomeLoanGrid>
          <Box
            column
            flex={1}
            justifyContent="flex-start"
            width="100%"
            maxWidth={{
              mobile: constants.maxContentColumnWidth,
              desktop: '100%',
            }}
            pb="m"
            mt={isMobile ? 'm' : 0}
          >
            <ArrowBackButton onPress={navigation.goBack} />
            <Box
              row
              mt="l"
              mb="m"
              alignItems="center"
              justifyContent="space-between"
            >
              <HomeLoanTitle
                loading={loading}
                name={loan?.settings?.name}
                propertyDisplayAddress={propertyShortAddress}
                bsb={loan?.settings?.bsb}
                accountNumber={loan?.settings?.account_number}
              />
              {isDesktop ? (
                <HomeLoanButtons
                  loading={loading}
                  withdrawDisabled={withdrawDisabled}
                  onWithdrawPress={onWithdrawPress}
                  onDetailPress={onDetailPress}
                />
              ) : null}
            </Box>

            {!flags.ENABLE_TRANSFER_FEATURE && isDesktop ? (
              <TransferDisabledWarningRow sx={{ mb: '$8' }} />
            ) : null}

            {successMessage ? (
              <SuccessRow message={successMessage} autoDismiss />
            ) : null}

            <PaymentFailureNotification
              sx={{ mt: '$8' }}
              cbaAccountId={cbaAccountId}
              navigateOnPress={openPaymentFailureDetailsModal}
            />
          </Box>
        </HomeLoanGrid>
        <HomeLoanGrid>
          <HomeLoanColumn>
            <HomeLoanProgressBar
              loading={loading}
              loanData={{
                balance: loan?.balances?.balance || 0,
                principalBalance: loan?.balances?.principal_balance || 0,
                availableRedrawBalance:
                  loan?.balances?.available_redraw_balance || 0,
              }}
            />
            <Separator spacer py="m" />
            {!isDesktop ? (
              <HomeLoanButtons
                loading={loading}
                withdrawDisabled={withdrawDisabled}
                onWithdrawPress={onWithdrawPress}
                onDetailPress={onDetailPress}
              />
            ) : null}

            {!flags.ENABLE_TRANSFER_FEATURE && !isDesktop && (
              <TransferDisabledWarningRow sx={{ mt: '$8' }} />
            )}

            {isAtLeastOneInsightEnabled ? (
              <>
                {!isDesktop ? <Separator spacer py="m" /> : null}
                <HomeLoanHeading
                  title={t('Content.HomeLoan.Insights.Header')}
                />
                <HomeLoanInsightsWidgetsContainer>
                  {flags.ENABLE_ACCOUNT_INSIGHTS_TERM_REMAINING ? (
                    <InsightsTermRemainingWidget
                      // got null from the backend if error happens, then display disabled state
                      disabled={isNull(loan?.term_remaining_insights)}
                      loading={loading}
                      remainingTermsInMonths={
                        loan?.settings?.remaining_term_in_months || 0
                      }
                      cbaAccountId={route.params.cbaAccountId}
                    />
                  ) : null}
                  {flags.ENABLE_ACCOUNT_INSIGHTS_DISCOUNT_BUMP ? (
                    <InsightsDiscountBumpWidget
                      loading={loading}
                      cbaAccountId={route.params.cbaAccountId}
                    />
                  ) : null}
                  {flags.ENABLE_ACCOUNT_INSIGHTS_ESTIMATED_PROPERTY_VALUE ? (
                    <InsightsPropertyValueWidget
                      cbaAccountId={cbaAccountId}
                      loading={loading}
                    />
                  ) : null}
                  {flags.ENABLE_ACCOUNT_INSIGHTS_TOTAL_INTEREST_SAVINGS ? (
                    <InsightsInterestSavingsWidget
                      // got null from the backend if error happens, then display disabled state
                      disabled={isNull(loan?.historical_interest_savings)}
                      cbaAccountId={cbaAccountId}
                      loading={loading}
                    />
                  ) : null}
                </HomeLoanInsightsWidgetsContainer>
              </>
            ) : null}
          </HomeLoanColumn>
          <HomeLoanColumn isRight>
            <HomeLoanHeading title={t('Content.HomeLoan.UpcomingHeading')} />
            {pendingWithdrawals.length ? (
              <PendingWithdrawals
                myIdentityProfileId={loan?.myIdentityProfileId}
                pending_withdrawals={pendingWithdrawals}
              />
            ) : null}

            <UpcomingCard
              testID={TestID.HomeLoan.Repayment.List}
              sx={{ mt: pendingWithdrawals.length > 0 ? '$8' : undefined }}
            >
              {loading ? (
                <LoadingRow />
              ) : (
                <HomeLoanOnSchedulePayment
                  cbaAccountId={cbaAccountId}
                  loan={loan}
                />
              )}
            </UpcomingCard>

            <Separator spacer />

            <HomeLoanHeading title={t('Content.HomeLoan.RecentHeading')} />
            {processingPendingWithdrawals.length ? (
              <ProcessingPendingWithdrawals
                pending_withdrawals={processingPendingWithdrawals}
              />
            ) : null}
            <TransactionList
              loading={loading}
              transactions={loan?.recent_transactions ?? []}
              onPressCompletedTransaction={onPressTransaction}
              sx={{
                mt: processingPendingWithdrawals.length > 0 ? '$8' : undefined,
              }}
            />
          </HomeLoanColumn>
        </HomeLoanGrid>
      </ScreenErrorFallback>
    </UpliftScreenContainer>
  );
};

export const HomeLoan = withAuthenticationRequired(HomeLoanBase);
