import * as React from 'react';

import { ErrorRow } from '../../../components/ErrorRow';
import { Repayment_Calculation_Error } from '../../../generated/graphql';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { PickRequired, WithFutureType } from '../../../utils/typesHelpers';

type Props = Pick<React.ComponentProps<typeof ErrorRow>, 'sx'> & {
  minMonthlyRepaymentError:
    | WithFutureType<Repayment_Calculation_Error>
    | null
    | undefined;
  minLoanAmountToCalculate: number | undefined;
  maxLoanAmountToCalculate: number | undefined;
};

function formatRepaymentCalculationErrorMessage({
  minMonthlyRepaymentError,
  minLoanAmountToCalculate,
  maxLoanAmountToCalculate,
}: PickRequired<
  Props,
  | 'minMonthlyRepaymentError'
  | 'minLoanAmountToCalculate'
  | 'maxLoanAmountToCalculate'
>) {
  switch (minMonthlyRepaymentError) {
    case Repayment_Calculation_Error.LessThanMinAmount:
      return t('Content.SetupLoan.FieldError.MinXLoanAmount', {
        x: formatCurrency(minLoanAmountToCalculate),
      });
    case Repayment_Calculation_Error.MoreThanMaxAmount:
      return t('Content.SetupLoan.FieldError.MaxXLoanAmount', {
        x: formatCurrency(maxLoanAmountToCalculate),
      });
    case Repayment_Calculation_Error.InvalidAmount:
    case Repayment_Calculation_Error.MambuApiCallFailed:
    case Repayment_Calculation_Error.NoLoanPreviewProductCode:
      return t('Content.SetupLoan.FieldError.UnableToCalculateMinRepayment');
    default:
      return t('Content.SetupLoan.FieldError.UnableToCalculateMinRepayment');
  }
}

export function MonthlyRepaymentError({
  minMonthlyRepaymentError,
  minLoanAmountToCalculate,
  maxLoanAmountToCalculate,
  sx,
}: Props) {
  if (
    minMonthlyRepaymentError == null ||
    minLoanAmountToCalculate == null ||
    maxLoanAmountToCalculate == null
  ) {
    return null;
  }

  return (
    <ErrorRow
      sx={sx}
      message={formatRepaymentCalculationErrorMessage({
        minMonthlyRepaymentError,
        minLoanAmountToCalculate,
        maxLoanAmountToCalculate,
      })}
    />
  );
}
