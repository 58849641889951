import { Text, useDripsyTheme, View } from 'dripsy';
import { ErrorMessage } from 'formik';
import { ComponentProps } from 'react';

import { SvgIcon } from '../svgs/SvgIcon';

type FormErrorTextProps = Omit<ComponentProps<typeof View>, 'children'> & {
  children: string | null | undefined;
};

export function FormErrorText({
  sx,
  children,
  ...otherProps
}: FormErrorTextProps) {
  const { theme } = useDripsyTheme();

  if (!children) {
    return null;
  }

  const alertIconSize = 16;
  const mergedSx: FormErrorTextProps['sx'] = {
    pl: alertIconSize + 8,
    ...sx,
  };

  return (
    <View sx={mergedSx} {...otherProps}>
      <SvgIcon
        name="alertCircle"
        size={alertIconSize}
        color={theme.colors.$error}
        style={{
          position: 'absolute',
          left: 0,
        }}
      />
      <Text variant="caption" sx={{ color: '$error' }}>
        {children}
      </Text>
    </View>
  );
}

type FormikFormErrorProps<FieldsName> = Omit<FormErrorTextProps, 'children'> & {
  name: FieldsName;
  errorMessageParser?: (name: FieldsName, errorCode: string) => string | null;
};

export function FormikFormError<FieldsName extends string>(
  props: FormikFormErrorProps<FieldsName>,
) {
  const { name, errorMessageParser, ...otherProps } = props;
  return (
    <ErrorMessage
      name={name}
      render={(error) => (
        <FormErrorText {...otherProps}>
          {errorMessageParser ? errorMessageParser(name, error) : error}
        </FormErrorText>
      )}
    />
  );
}
