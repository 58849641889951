import { PaymentRequestFragment } from '../../generated/graphql';

export const getPaymentRequestResponse = ({
  paymentRequest,
  myIdentityProfileId,
}: {
  paymentRequest?: PaymentRequestFragment;
  myIdentityProfileId?: string;
}) =>
  paymentRequest?.withdrawal_request?.approval_request.responses.find(
    (response) => response?.identity_profile?.id === myIdentityProfileId,
  );
