import { Text } from 'dripsy';
import { useContext } from 'react';

import { TestID } from '../../../../testID/constants';
import { InfoRow } from '../../../components/InfoRow';
import { InlineInfoRow } from '../../../components/InlineInfoRow';
import { FeatureFlagsContext } from '../../../FeatureFlags/context';
import { Button } from '../../../ui/atoms/Button';
import { capitalize, capitalizeSentence } from '../../../utils/stringHelpers';
import { BorrowerDataView, BorrowerDataViewProps } from './BorrowerDataView';

export type Props = BorrowerDataViewProps & {
  firstName: string;
} & Partial<{
    onPressRemove: () => void;
    onPressDone: () => void;
    showCoBorrowerEmailInviteBanner: boolean;
  }>;

export function CoBorrowerDetails(props: Props) {
  const { flags } = useContext(FeatureFlagsContext);
  const enableDuplicateCoBorrowerHandler =
    flags.ENABLE_DUPLICATE_CO_BORROWER_HANDLER;

  const {
    showCoBorrowerEmailInviteBanner,
    firstName,
    fullName,
    onPressDone,
    onPressRemove,
    ...borrowerData
  } = props;

  return (
    <>
      {showCoBorrowerEmailInviteBanner ? (
        <InfoRow mode="warning" mb="m">
          <Text variant="caption" sx={{ flex: 1, color: '$labelsPrimary' }}>
            {t(
              'Content.Borrower.YourInformation.EmailInviteToCoBorrowerBanner',
              {
                coborrower: `${capitalizeSentence(fullName)}`,
              },
            )}
          </Text>
        </InfoRow>
      ) : null}
      <Text variant="sHeader">
        {t('Content.Borrower.YourInformation.Details.CoBorrowerTitle', {
          name: capitalize(firstName),
        })}
      </Text>
      <BorrowerDataView fullName={fullName} {...borrowerData} />
      {enableDuplicateCoBorrowerHandler ? (
        <InlineInfoRow
          sx={{ mt: '$8' }}
          message={t(
            'Content.Borrower.YourInformation.Details.EditCoBorrowerInformation',
            { name: capitalize(firstName) },
          )}
        />
      ) : null}
      <Button
        onPress={onPressDone}
        label={t('Content.Common.ButtonLabel.Done')}
        width="100%"
        mt="xl"
        testID={TestID.CoBorrowerDetails.DoneButton}
      />
      {onPressRemove ? (
        <Button
          onPress={onPressRemove}
          label={t('Content.Borrower.YourInformation.Details.RemoveCoBorrower')}
          tertiary
          fontWeight="normal"
          width="100%"
          mt="s"
          testID={TestID.CoBorrowerDetails.RemoveCoBorrowerButton}
        />
      ) : null}
    </>
  );
}
