import { useEffect, useState } from 'react';

import { shouldShowReferral } from '../referralHelpers';

export function useReferralDisclosure() {
  const [showReferralDisclosure, setShowReferralDisclosure] = useState(false);

  useEffect(() => {
    if (shouldShowReferral()) {
      setShowReferralDisclosure(true);
    }
  }, []);

  return showReferralDisclosure;
}
