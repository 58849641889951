import {
  Cb_RepaymentType,
  Loan_Repayment_Type_Enum,
} from '../generated/graphql';
import { formatLoanTerm } from './stringHelpers';

type RepaymentType = Cb_RepaymentType;

export function getRepaymentLabel(
  repaymentType?: RepaymentType | Loan_Repayment_Type_Enum | null,
): string {
  if (!repaymentType) {
    return '--';
  }
  return repaymentType === Cb_RepaymentType.PrincipleAndInterest ||
    repaymentType === Loan_Repayment_Type_Enum.PrincipalAndInterest
    ? 'Principal & Interest'
    : 'Interest Only';
}

export function getRepaymentLabelWithInterestMonths(
  repaymentType?: RepaymentType,
  interestOnlyPeriodMonths?: number | null,
): string {
  if (!repaymentType) {
    return '--';
  }

  switch (repaymentType) {
    case Cb_RepaymentType.InterestOnly:
      return `${getRepaymentLabel(repaymentType)} (${
        interestOnlyPeriodMonths
          ? formatLoanTerm(interestOnlyPeriodMonths, true)
          : ''
      })`;

    case Cb_RepaymentType.PrincipleAndInterest:
      return getRepaymentLabel(repaymentType);

    // We've covered all valid cases, just return '--' if we ran into something unexpected
    default:
      return '--';
  }
}
