import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';

import { ENV_CONFIG, ENVIRONMENT, getEnvironmentName } from '../../config';
import { VERSION_STRING } from '../../constants/appVersion';
import { ActionSheetStackParams } from '../../navigation/types/navTypes';
import { ActionSheetType } from '../../navigation/types/screens';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import {
  ActionSheet,
  Props as ActionSheetProps,
} from '../components/ActionSheet';

type Props = StackScreenProps<
  ActionSheetStackParams,
  ActionSheetType.ENV_SWITCHER
>;

export const EnvSwitcherActionSheet: React.FC<Props> = ({ navigation }) => {
  const { env, setEnv } = useEnvConfig();

  const actionList: ActionSheetProps['actionList'] = Object.keys(
    ENV_CONFIG,
  ).map((envKey) => ({
    label: getEnvironmentName(envKey as ENVIRONMENT),
    icon: env === envKey ? 'md-checkmark' : undefined,
    iconPosition: 'right',
    onPress: () => {
      setEnv(envKey as ENVIRONMENT);
      navigation.goBack();
    },
  }));

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      title="Switch Environment"
      caption={`v${VERSION_STRING}`}
      actionList={actionList}
    />
  );
};
