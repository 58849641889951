import { useCallback, useEffect, useState } from 'react';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useSyncApplicantSignerStatusMutation } from '../../generated/graphql';
import { TopNavBar } from '../../Home/navigation/components/TopNavBar';
import { useLoanApplication } from '../../LoanApplication/utils/useLoanApplication';
import { AppStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useHideBackButton } from '../../utils/hooks/useHeaderButton';
import { CompletedContractSigning } from '../components/CompletedContractSigning';

type Props = AppStackScreenProps<Screen.DOCUSIGN_CONTRACT_RETURN>;

function DocusignContractReturnBase({ navigation, route }: Props) {
  useHideBackButton(navigation);
  const loanApplicationId = route.params?.loanApplicationId;

  // List of possible docusign events can be found here:
  // https://developers.docusign.com/docs/esign-rest-api/reference/envelopes/envelopeviews/createrecipient/#schema__recipientviewrequest_returnurl
  const docusignEvent = route.params?.event;

  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);

  const { loading: loadingLoanApplicationType, loanApplicationType } =
    useLoanApplication(loanApplicationId);

  const [syncSignerStatus, { loading: loadingSyncApplicantSignerStatus }] =
    useSyncApplicantSignerStatusMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    // Immediately navigate to home if event is not "signing_complete"
    if (docusignEvent !== 'signing_complete') {
      navigateToHome();
      return;
    }

    const syncSigner = async () => {
      const [res, error] = await safelyCallMutation(syncSignerStatus, {
        context: { sentryContext: { loanApplicationId } },
        variables: { loan_application_id: loanApplicationId || '' },
      });

      if (res == null || error) {
        setErrorMessage(
          t('Content.Common.Error.FailSyncApplicantSignerStatus'),
        );
      }
    };

    syncSigner();
  }, [docusignEvent, loanApplicationId, navigateToHome, syncSignerStatus]);

  if (loadingSyncApplicantSignerStatus || loadingLoanApplicationType) {
    return (
      <>
        <TopNavBar />
        <NavHeaderSpacer />
        <ScreenLoadingContainer flex={1} centered loading />
      </>
    );
  }

  return (
    <>
      {/* 
        Currently DocusignContractReturn screen is a standalone 
        screen in top level App navigator. Hence TopNavBar is declared here instead 
      */}
      <TopNavBar />
      <NavHeaderSpacer />
      <Box mx="m">
        <CompletedContractSigning
          navigateToHome={navigateToHome}
          errorMessage={errorMessage}
          loanApplicationType={loanApplicationType}
        />
      </Box>
    </>
  );
}

export const DocusignContractReturn = withAuthenticationRequired(
  DocusignContractReturnBase,
);
