import { Theme as NavTheme } from '@react-navigation/native';

import {
  createTheme,
  defaultDarkTheme,
  defaultLightTheme,
  defaultTabletLightTheme,
} from '../ui/theme';
import { useIsTablet } from '../utils/hooks/useBreakpoint';

export const LightTheme = createTheme({
  ...defaultLightTheme,
});

export const DarkTheme = createTheme({
  ...defaultDarkTheme,
});

export function useInitialTheme() {
  const isTablet = useIsTablet();
  // Disabling dark mode until we polished design in dark mode
  //   if (scheme === 'dark') {
  //     return isTablet ? defaultTabletDarkTheme : defaultDarkTheme;
  //   }
  return isTablet ? defaultTabletLightTheme : defaultLightTheme;
}

export const LightNavTheme: NavTheme = {
  dark: false,
  colors: {
    primary: LightTheme.colors.accent,
    background: LightTheme.colors.bg,
    card: LightTheme.colors.shapeBg,
    text: LightTheme.colors.primaryContent,
    border: LightTheme.colors.border,
    notification: LightTheme.colors.error,
  },
};

export const DarkNavTheme: NavTheme = {
  dark: true,
  colors: {
    primary: DarkTheme.colors.accent,
    background: DarkTheme.colors.bg,
    card: DarkTheme.colors.shapeBg,
    text: DarkTheme.colors.primaryContent,
    border: DarkTheme.colors.border,
    notification: DarkTheme.colors.error,
  },
};
