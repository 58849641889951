import { atom } from 'recoil';

export type HomeLoanWithdrawalDetail = {
  cba_account_id: string;
  external_account_id: string;
  amount: number;
  description: string;
  reference?: string | null;
};

export const homeLoanWithdrawalDetailAtom = atom<HomeLoanWithdrawalDetail>({
  key: 'homeLoanWithdrawalDetailState',
  default: {
    cba_account_id: '',
    external_account_id: '',
    amount: 0,
    description: '',
  },
});
