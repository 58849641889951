import { Text, View } from 'dripsy';
import React, { useCallback, useContext, useMemo } from 'react';
import { RefreshControl as RNRefreshControl } from 'react-native';

import { TestID } from '../../../testID/constants';
import { useUser } from '../../Auth/hooks';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import ScheduledMaintenance from '../../components/ScheduledMaintenance';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  Id_Validation_Result_Enum,
  useProcessIdvCheckMutation,
} from '../../generated/graphql';
import { AccountPermissionsSection } from '../../HomeLoan/components/AccountPermissionsSection';
import { useStartIDValidation } from '../../Identification/hooks/useStartIDValidation';
import {
  onfidoCompletedEvents,
  onfidoConsentGrantedEvents,
  OnfidoEventOrigin,
} from '../../LoanApplication/utils/idValidationEventEmitter';
import { LoanVariationStatusCard } from '../../LoanVariation/components/LoanVariationStatusCard';
import { Screen } from '../../navigation/types/screens';
import { PaymentFailureNotification } from '../../PaymentFailure/components/PaymentFailureNotification';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { LoadingRow, LoadingRowGroup } from '../../ui/molecules/LoadingRow';
import { NewListRow } from '../../ui/molecules/NewListRow';
import { useTheme } from '../../ui/theme';
import { isLast } from '../../utils/arrayHelpers';
import { formatCurrency } from '../../utils/currencyHelpers';
import { generateGreeting } from '../../utils/generateGreeting';
import { getIdvErrorMessage } from '../../utils/getErrorMessage';
import { useIsDesktop, useView } from '../../utils/hooks/useBreakpoint';
import { useOnfidoEvents } from '../../utils/hooks/useOnfidoEvents';
import { isWeb } from '../../utils/platformUtils';
import { useDashboardQuery } from '../utils/useDashboardQuery';
import { useHomeNavigation } from '../utils/useHomeNavigation';
import { useNativeAppApplicationNavigation } from '../utils/useNativeAppApplicationNavigation';
import { ActiveLoanCard } from './ActiveLoanCard';
import { ApplyCard } from './ApplyCard';
import { ApplyForAnotherLoanButton } from './ApplyForAnotherLoanButton';
import { ApplyLoanCard } from './ApplyLoanCard/ApplyLoanCard';
import { DashboardLoadingLoanCard } from './DashboardLoadingLoanCard';
import { DashboardLoanCard } from './DashboardLoanCard';
import { DashboardUpcomings } from './DashboardUpcomings';
import { TransferDisabledWarningRow } from './TransferDisabledWarningRow';

const SCREEN_COLUMN_WIDTH = 420;

type Props = {
  currentTime: Date;
};

const NoUpcomingTransactions = () => (
  <Box row alignItems="center">
    <StyledIcon
      family="svg"
      name="tick"
      size="xs"
      color="overlayContent"
      roundBg
    />
    <StyledText variant="caption" ml="xs" color="disabledContent">
      {t('Content.HomeLoan.NoUpcomingTransactions')}
    </StyledText>
  </Box>
);

const DashboardHeader = ({ title }: { title: string }) => {
  const isDesktop = useIsDesktop();
  const marginStyle: BoxProps = isWeb
    ? {
        mt: { desktop: 'm', mobile: 0 },
        mb: isDesktop ? '2xl' : 'l',
      }
    : { mt: 'm', mb: 'm' };
  return (
    <Box
      alignItems="flex-start"
      width="100%"
      maxWidth={isDesktop ? '100%' : SCREEN_COLUMN_WIDTH}
      {...marginStyle}
    >
      <Text variant="lHeader" role="heading">
        {title}
      </Text>
    </Box>
  );
};

function DashboardBase({ currentTime }: Props) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const { isMobileNativeView, isMobileWebView } = useView();
  const { user } = useUser();
  const { flags } = useContext(FeatureFlagsContext);

  const navigation = useHomeNavigation();
  const userFirstName = user?.identity_profile?.first_name;
  const showIDVTaskTile =
    user?.identity_profile?.id_validation_result ===
      Id_Validation_Result_Enum.Fail &&
    user?.identity_profile?.has_active_id_validation_restart_request;

  const {
    loanAccountsForDisplay,
    appliedLoansForDisplay,
    loanAccountsLoading,
    loanApplicationsLoading,
    loanApplicationsError,
    loanAccountsError,
    refetchAll,
    isRefetching,
  } = useDashboardQuery();

  const { startIDValidation, createIDVCheckMutationResult, idvError } =
    useStartIDValidation({
      completedEvent: onfidoCompletedEvents[OnfidoEventOrigin.Dashboard],
    });
  const [processIDVCheck, processIDVCheckMutationResult] =
    useProcessIdvCheckMutation();

  useOnfidoEvents({
    startIDValidation,
    processIDVCheck,
    onfidoEventOrigin: OnfidoEventOrigin.Dashboard,
    user,
    navigation,
  });

  const idvErrorMessage = getIdvErrorMessage({
    processIDVError: processIDVCheckMutationResult.error,
    getTokenError: createIDVCheckMutationResult.error,
    idvError,
  });

  const idvTaskTile = useMemo(() => {
    const onPress = async () => {
      navigation.navigate(Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL, {
        consentGrantedEvent:
          onfidoConsentGrantedEvents[OnfidoEventOrigin.Dashboard],
      });
    };

    return (
      <>
        <ErrorRow
          message={idvErrorMessage}
          testID={TestID.Dashboard.IDVTaskTileErrorRow}
          sx={{ mb: '$16' }}
        />
        <NewListRow
          title={t('Content.Home.IDV.Title')}
          caption={t('Content.Home.IDV.Caption')}
          leftIconFamily="svg"
          leftIconName="idv"
          width="100%"
          mt={0}
          mb="m"
          buttonLabel={t('Content.Home.IDV.ButtonLabel')}
          buttonLoading={
            createIDVCheckMutationResult.loading ||
            processIDVCheckMutationResult.loading
          }
          onButtonPress={onPress}
          testID={TestID.Dashboard.IDVTaskTile}
        />
      </>
    );
  }, [
    createIDVCheckMutationResult,
    idvErrorMessage,
    navigation,
    processIDVCheckMutationResult,
  ]);

  const isEmpty =
    appliedLoansForDisplay.length + loanAccountsForDisplay.length === 0;

  const isPostEvidenceUploadFeatureEnabled =
    !!flags[FeatureFlag.EnablePostEvidenceUpload];

  const showTaskRequestTile = React.useMemo(
    () =>
      appliedLoansForDisplay.some(
        (loan) =>
          loan.loanApplication.activeLoanApplicationTaskRequests.length > 0,
      ) && isPostEvidenceUploadFeatureEnabled,
    [appliedLoansForDisplay, isPostEvidenceUploadFeatureEnabled],
  );

  const loanApplicationRequestsTiles = useMemo(() => {
    // TODO: map this onpress to open the new document upload modal in the next story
    const onPress = async () => {
      navigation.navigate(Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL, {
        consentGrantedEvent:
          onfidoConsentGrantedEvents[OnfidoEventOrigin.Dashboard],
      });
    };

    return (
      <>
        {appliedLoansForDisplay.map((loan) =>
          loan.loanApplication.activeLoanApplicationTaskRequests.map(
            (taskRequest) => {
              const securityAddress =
                taskRequest?.loan_application?.loan_application_securities?.[0]
                  ?.property.address.short_address_format;
              const conditonalLoanAmount =
                taskRequest?.loan_application
                  .financial_serviceability_policies?.[0]
                  ?.conditionally_approved_amount;
              let caption;
              if (securityAddress) {
                caption = t(
                  'Content.Home.LoanApplicationRequest.CaptionAddress',
                  { address: securityAddress },
                );
              } else {
                caption = t(
                  'Content.Home.LoanApplicationRequest.CaptionLoanAmount',
                  { loanAmount: formatCurrency(conditonalLoanAmount) },
                );
              }
              return (
                <NewListRow
                  key={taskRequest.id}
                  title={t('Content.Home.LoanApplicationRequest.Title')}
                  caption={caption}
                  leftIconFamily="svg"
                  leftIconName="taskRequest"
                  leftIconSize="xl"
                  width="100%"
                  mt={0}
                  mb="m"
                  buttonLabel={t(
                    'Content.Home.LoanApplicationRequest.ButtonLabel',
                  )}
                  buttonLoading={loanApplicationsLoading}
                  onButtonPress={onPress}
                  testID={TestID.Dashboard.LoanApplicationTaskRequest}
                />
              );
            },
          ),
        )}
      </>
    );
  }, [appliedLoansForDisplay, navigation, loanApplicationsLoading]);

  const { onSetupLoan: onSetupLoanPress } = useNativeAppApplicationNavigation();

  const onActiveLoanPress = useCallback(
    (cbaAccountId: string) =>
      navigation.navigate(Screen.HOME_LOAN, {
        cbaAccountId,
      }),
    [navigation],
  );

  const navigateToLoanVariationApprovalPage = useCallback(
    (cbaAccountId: string) => {
      navigation.navigate(
        Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN,
        {
          cbaAccountId,
        },
      );
    },
    [navigation],
  );

  const navigationToAccountPermissionsApprovalScreen = useCallback(
    (accountId: string) => {
      navigation.navigate(Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN, {
        cbaAccountId: accountId,
      });
    },
    [navigation],
  );

  const openPaymentFailureDetailsModal = useCallback(
    (cbaAccountId: string) => {
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.PAYMENT_FAILURE_MODAL,
        params: {
          cbaAccountId,
        },
      });
    },
    [navigation],
  );

  const combinedLoading = loanAccountsLoading || loanApplicationsLoading;

  const isAccountPermissionsEnabled =
    !!flags[FeatureFlag.EnableAccountPermissions];

  const applyLoan = useMemo(
    () => (
      <>
        {!loanAccountsLoading && !isEmpty ? (
          <>
            {!isDesktop && <Separator spacer />}
            <StyledText variant="headerSmall" mb="m">
              {t('Content.Home.ApplyForAnotherLoan')}
            </StyledText>
          </>
        ) : null}
        {!loanAccountsLoading && isEmpty ? (
          <StyledText variant="headerSmall" mb="m">
            {t('Content.Home.ApplyForAHomeLoan')}
          </StyledText>
        ) : null}
        {!loanAccountsLoading ? (
          <ApplyLoanCard onPress={onSetupLoanPress} />
        ) : null}
      </>
    ),
    [isDesktop, isEmpty, loanAccountsLoading, onSetupLoanPress],
  );

  const loansColumn = useMemo(
    () => (
      <Box overflow="visible" width="100%" maxWidth={SCREEN_COLUMN_WIDTH}>
        {isDesktop && showIDVTaskTile ? idvTaskTile : null}
        {isDesktop && showTaskRequestTile ? loanApplicationRequestsTiles : null}

        {!flags.ENABLE_DASHBOARD_V2 ||
        (flags.ENABLE_DASHBOARD_V2 && !isEmpty) ? (
          <View
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '$16',
            }}
          >
            {!flags.ENABLE_DASHBOARD_V2 ? (
              <>
                <StyledText variant="headerSmall" alignSelf="center">
                  {t('Content.Home.HomeLoans')}
                </StyledText>
                {!combinedLoading && !isEmpty ? (
                  <ApplyForAnotherLoanButton onPress={onSetupLoanPress} />
                ) : null}
              </>
            ) : null}
            {flags.ENABLE_DASHBOARD_V2 && !isEmpty ? (
              <StyledText variant="headerSmall" alignSelf="center">
                {t('Content.Home.HomeLoans')}
              </StyledText>
            ) : null}
          </View>
        ) : null}

        {!flags.ENABLE_DASHBOARD_V2 && !combinedLoading && isEmpty ? (
          <ApplyCard onPress={onSetupLoanPress} />
        ) : null}
        {loanApplicationsLoading ? (
          <DashboardLoadingLoanCard width="100%" mb="l" />
        ) : (
          appliedLoansForDisplay.map((appliedLoan) => (
            <DashboardLoanCard
              key={appliedLoan.loanApplication.id}
              appliedLoan={appliedLoan}
            />
          ))
        )}
        {loanAccountsLoading ? (
          <DashboardLoadingLoanCard
            width="100%"
            testID={TestID.Dashboard.LoanAccountLoading}
          />
        ) : (
          loanAccountsForDisplay.map((account, index) => (
            <ActiveLoanCard
              key={account.cba_account_id}
              cbaAccountId={account.cba_account_id}
              onPress={onActiveLoanPress}
              mb={isLast(loanAccountsForDisplay, index) ? undefined : 'xl'}
              testID={TestID.Dashboard.ActiveLoanCard}
            />
          ))
        )}
        {flags.ENABLE_DASHBOARD_V2 ? applyLoan : null}
      </Box>
    ),
    [
      isDesktop,
      showIDVTaskTile,
      idvTaskTile,
      showTaskRequestTile,
      loanApplicationRequestsTiles,
      flags.ENABLE_DASHBOARD_V2,
      isEmpty,
      combinedLoading,
      onSetupLoanPress,
      loanApplicationsLoading,
      appliedLoansForDisplay,
      loanAccountsLoading,
      loanAccountsForDisplay,
      applyLoan,
      onActiveLoanPress,
    ],
  );

  const upcomingColumn = useMemo(
    () => (
      <Box overflow="visible" maxWidth={SCREEN_COLUMN_WIDTH} width="100%">
        {(isMobileNativeView || !isDesktop) && showIDVTaskTile
          ? idvTaskTile
          : null}
        {(isMobileNativeView || !isDesktop) && showTaskRequestTile
          ? loanApplicationRequestsTiles
          : null}

        {loanAccountsForDisplay.map(({ cba_account_id: cbaAccountId }) => (
          <PaymentFailureNotification
            key={`payment-failure-notification-${cbaAccountId}`}
            cbaAccountId={cbaAccountId}
            navigateOnPress={() => openPaymentFailureDetailsModal(cbaAccountId)}
          />
        ))}

        {isAccountPermissionsEnabled
          ? loanAccountsForDisplay.map(({ id, cba_account_id }) => (
              <AccountPermissionsSection
                key={id}
                mt={0}
                mb="s"
                cbaAccountId={cba_account_id}
                loading={combinedLoading}
                onPress={() =>
                  navigationToAccountPermissionsApprovalScreen(cba_account_id)
                }
                hideWhenNoPendingRequest
              />
            ))
          : null}
        {loanAccountsForDisplay.map(({ cba_account_id }) => (
          <LoanVariationStatusCard
            key={cba_account_id}
            cbaAccountId={cba_account_id}
            loading={combinedLoading}
            onPress={() => navigateToLoanVariationApprovalPage(cba_account_id)}
          />
        ))}
        <StyledText variant="headerSmall" mb="m">
          {t('Content.HomeLoan.UpcomingHeading')}
        </StyledText>
        {!loanAccountsLoading &&
          (loanAccountsForDisplay.length === 0 ? (
            <NoUpcomingTransactions />
          ) : (
            <>
              {loanAccountsForDisplay.map(({ cba_account_id }, index) => (
                <DashboardUpcomings
                  key={cba_account_id}
                  index={index}
                  cbaAccountId={cba_account_id}
                  numOfHomeLoans={loanAccountsForDisplay.length}
                />
              ))}
            </>
          ))}
        {loanAccountsLoading ? (
          <LoadingRowGroup>
            <LoadingRow />
          </LoadingRowGroup>
        ) : null}
        {!isDesktop && <Separator spacer />}
      </Box>
    ),
    [
      isMobileNativeView,
      isDesktop,
      showIDVTaskTile,
      idvTaskTile,
      showTaskRequestTile,
      loanApplicationRequestsTiles,
      loanAccountsForDisplay,
      isAccountPermissionsEnabled,
      loanAccountsLoading,
      openPaymentFailureDetailsModal,
      combinedLoading,
      navigationToAccountPermissionsApprovalScreen,
      navigateToLoanVariationApprovalPage,
    ],
  );

  const component = useMemo(
    () =>
      isDesktop ? (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          overflow="visible"
          width="100%"
        >
          {loansColumn}

          {upcomingColumn}
        </Box>
      ) : (
        <Box
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          overflow="visible"
          width="100%"
        >
          {upcomingColumn}

          {loansColumn}
        </Box>
      ),
    [isDesktop, loansColumn, upcomingColumn],
  );

  return (
    <UpliftScreenContainer
      showTopNavBar={isMobileNativeView}
      wideContentContainer
      refreshControl={
        <RNRefreshControl
          tintColor={theme.colors.secondaryContent}
          refreshing={isRefetching}
          onRefresh={refetchAll}
        />
      }
    >
      <Box height={isMobileWebView ? theme.sizes['2xl'] : 0} />

      <Box
        pb="m"
        overflow="visible"
        alignItems={isDesktop ? undefined : 'center'}
      >
        <ScreenErrorFallback
          error={loanApplicationsError}
          displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
          refetch={refetchAll}
          testID={TestID.Dashboard.ScreenError}
        >
          <DashboardHeader
            title={
              userFirstName
                ? `${generateGreeting(currentTime)}, ${userFirstName}`
                : generateGreeting(currentTime)
            }
          />

          {loanAccountsForDisplay.length > 0 ? (
            <ScheduledMaintenance mb="l" />
          ) : null}

          {!flags.ENABLE_TRANSFER_FEATURE && (
            <TransferDisabledWarningRow
              sx={{
                mb: '$8',
                width: '100%',
                maxWidth: isDesktop ? '100%' : SCREEN_COLUMN_WIDTH,
              }}
            />
          )}

          {loanAccountsError ? (
            <ErrorRow
              sx={{ mb: '$8' }}
              message={t('Content.Home.ErrorFetchingAccounts')}
              width="100%"
              maxWidth={isDesktop ? '100%' : SCREEN_COLUMN_WIDTH}
            />
          ) : null}
          {component}
        </ScreenErrorFallback>
      </Box>
    </UpliftScreenContainer>
  );
}

export const Dashboard = withAuthenticationRequired(DashboardBase);
