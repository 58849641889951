import { ApolloError } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { Linking } from 'react-native';

import { ContactDetails } from '../config';
import { addBreadcrumb, captureException } from '../sentry';
import { Props as ScreenContainerProps } from '../ui/atoms/ScreenContainer';
import { ErrorFallback } from './ErrorFallback';

type Props = ScreenContainerProps & {
  error: ApolloError | undefined;
  displayMessage: string;
  refetch: () => void;
  children?: React.ReactNode;
  extraContext?: Record<string, unknown>;
};

function NetworkError() {
  const onCaptionLinkPress = useCallback(
    // This probably should open Intercom chat and fallback to email
    () => Linking.openURL(`mailto:${ContactDetails.supportEmail}`),
    [],
  );
  return (
    <ErrorFallback
      title={t('Content.Common.Error.NetworkError.Title')}
      caption={t('Content.Common.Error.NetworkError.Caption')}
      captionLink={ContactDetails.supportEmail}
      hideUnloanLogo
      bg="transparent"
      onCaptionLinkPress={onCaptionLinkPress}
    />
  );
}

export function ScreenErrorFallback({
  refetch,
  displayMessage,
  error,
  children,
  extraContext = {},
  ...otherProps
}: Props) {
  const onRefresh = useCallback(async () => {
    try {
      // This refetch doesnt always return promise,
      // this try/catch will not work if the inner promise
      // is not properly awaited.
      await refetch();
    } catch (e: unknown) {
      // Error here has been capture by the above useEffect
      captureException('Error occured while refetching query', extraContext, e);
    }
  }, [refetch, extraContext]);

  useEffect(() => {
    if (!error) {
      return;
    }
    addBreadcrumb('ScreenErrorFallback', extraContext);
    // This is intended, we only want to capture error to sentry whenever the error changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error?.networkError) {
    return <NetworkError />;
  }

  if (error) {
    return (
      <ErrorFallback
        title={displayMessage}
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        captionLink={t('Content.Common.Error.SomethingWentWrong.CaptionLink')}
        onCaptionLinkPress={onRefresh}
        hideUnloanLogo
        bg="transparent"
        flex={1}
        justifyContent="center"
        {...otherProps}
      />
    );
  }
  // Fragment needed to convert React.ReactNode to JSX.Element
  // otherwise some compoenents like ScrollContainer errors out
  // with ScreenErrorFallback having invalid return type
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
