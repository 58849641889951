import { useCallback, useState } from 'react';

import { CollapsibleListRow } from '../../components/CollapsibleListRow';
import { LearnMoreScreenContainer } from '../../components/LearnMoreScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Separator } from '../../ui/atoms/Separator';
import { Props as TextProps, StyledText } from '../../ui/atoms/StyledText';

// TODO: i18n - use object with getter
const AcceptableDocuments = [
  {
    label: t('Content.AcceptableDocumentsModal.EmploymentIncome'),
    content: (
      <>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.WeWillNeedAnyOneOf')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.EmploymentIncomeLineOne')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.EmploymentIncomeLineTwo')}
        </ContentText>
      </>
    ),
  },
  {
    label: t('Content.AcceptableDocumentsModal.RentalIncome'),
    content: (
      <>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.WeWillNeedAnyOneOf')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.RentalIncomeLineOne')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.RentalIncomeLineTwo')}
        </ContentText>
      </>
    ),
  },
  {
    label: t('Content.AcceptableDocumentsModal.InvestmentIncome'),
    content: (
      <ContentText>
        {t('Content.AcceptableDocumentsModal.InvestmentIncomeLineOne')}
      </ContentText>
    ),
  },
  {
    label: t('Content.AcceptableDocumentsModal.GovernmentPayments'),
    content: (
      <>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.WeWillNeedAnyOneOf')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.GovernmentPaymentsLineOne')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.GovernmentPaymentsLineTwo')}
        </ContentText>
      </>
    ),
  },
  {
    label: t('Content.AcceptableDocumentsModal.SelfEmployedIncome'),
    content: (
      <>
        <ContentText>
          {t(
            'Content.AcceptableDocumentsModal.WeWIllNeedYourLast6MonthsStatements',
          )}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.SelfEmployedIncomeLineOne')}
        </ContentText>
        <ContentText>
          {t('Content.AcceptableDocumentsModal.SelfEmployedIncomeLineTwo')}
        </ContentText>
      </>
    ),
  },
];

function ContentText(props: TextProps) {
  return <StyledText variant="body" color="secondaryContent" {...props} />;
}

export function AcceptableDocumentsModal() {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const makeOnPress = useCallback(
    (newIndex: number) => () =>
      setSelectedIndex((selected) => (selected === newIndex ? null : newIndex)),
    [],
  );

  return (
    <LearnMoreScreenContainer>
      <NavHeaderSpacer />
      <StyledText variant="header" fontSize="xl" textAlign="center" mb="l">
        {t('Content.AcceptableDocumentsModal.Header')}
      </StyledText>
      <StyledText variant="body" mb="s">
        {t('Content.AcceptableDocumentsModal.CaptionOne')}
      </StyledText>
      <StyledText variant="body" mb="s">
        {t('Content.AcceptableDocumentsModal.CaptionTwo')}
      </StyledText>
      <StyledText variant="body" mb="m">
        {t('Content.AcceptableDocumentsModal.CaptionThree')}
      </StyledText>
      <Separator />
      {AcceptableDocuments.map(({ label, content }, index) => (
        <CollapsibleListRow
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          label={label}
          collapsedContent={content}
          isCollapsed={index !== selectedIndex}
          onPress={makeOnPress(index)}
        />
      ))}
    </LearnMoreScreenContainer>
  );
}
