import { View } from 'dripsy';
import { useMemo } from 'react';

import { Loan_Repayment_Type_Enum } from '../../generated/graphql';
import { MAX_LOAN_TERM_IN_YEARS } from '../../LoanConfiguration/utils/setupLoanFormHelper';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatLoanTerm } from '../../utils/stringHelpers';
import { LoanDetailsRenderProps } from './types';
import { LoanDetails } from './YouUnloanCardLoanDetails';

function getRepaymentTypeText(
  repaymentType: Loan_Repayment_Type_Enum | null | undefined,
): string {
  if (!repaymentType) {
    return '';
  }

  switch (repaymentType) {
    case Loan_Repayment_Type_Enum.PrincipalAndInterest:
      return t(
        'Content.YourUnloan.TopUpVariant.RepaymentType.PrincipalAndInterest',
      );
    case Loan_Repayment_Type_Enum.InterestOnly:
      return t('Content.YourUnloan.TopUpVariant.RepaymentType.InterestOnly');
    default:
      return '';
  }
}

export function LoanDetailsTopUps({
  totalLoanAmount,
  interestRate,
  rateAdjustment,
  termInMonths,
  totalLoanAmountLoading,
  repaymentType,
  minimumRepaymentTestId,
  totalLoanAmountTestId,
  loanTermTestId,
  totalLoanAmountCaption,
  productName,
  minRepaymentText,
  previousTermInMonths,
}: LoanDetailsRenderProps) {
  const loanTermCaption = useMemo(() => {
    if (!previousTermInMonths) return null;
    const termGap = termInMonths - previousTermInMonths;

    if (termGap === 0) return null;

    return (
      <View>
        <StyledText variant="caption">
          {termGap > 0
            ? t(
                'Content.YourUnloan.LoanCard.LoanTerm.LoanTermVariation.Increase',
                {
                  term: formatLoanTerm(termGap, true, false),
                },
              )
            : t(
                'Content.YourUnloan.LoanCard.LoanTerm.LoanTermVariation.Decrease',
                {
                  term: formatLoanTerm(-termGap, true, false),
                },
              )}
        </StyledText>
      </View>
    );
  }, [previousTermInMonths, termInMonths]);

  return (
    <>
      <Separator mt="s" mb="m" />
      <LoanDetails
        label={t('Content.YourUnloan.TopUpVariant.BalanceRemaining.Title')}
        valueTestId={totalLoanAmountTestId}
        value={formatCurrency(totalLoanAmount)}
        loading={totalLoanAmountLoading}
        caption={totalLoanAmountCaption}
      />

      <Separator my="m" />
      <LoanDetails
        label={t('Content.YourUnloan.TopUpVariant.InterestRate.Title')}
        value={t('Content.YourUnloan.TopUpVariant.InterestRate.SubTitle', {
          interestRate,
        })}
        caption={t('Content.YourUnloan.TopUpVariant.InterestRate.Caption', {
          productName,
          rateAdjustment: rateAdjustment ? Math.abs(rateAdjustment) : '--',
          maxLoanTermInYears: MAX_LOAN_TERM_IN_YEARS,
        })}
      />

      <Separator my="m" />
      <LoanDetails
        label={t('Content.YourUnloan.TopUpVariant.LoanTerm.Title')}
        value={formatLoanTerm(termInMonths, true)}
        valueTestId={loanTermTestId}
        caption={loanTermCaption}
      />

      <Separator my="m" />
      <LoanDetails
        label={t(
          'Content.YourUnloan.TopUpVariant.MinimumMonthlyRepayments.Title',
        )}
        valueTestId={minimumRepaymentTestId}
        value={minRepaymentText}
        caption={t(
          'Content.YourUnloan.TopUpVariant.MinimumMonthlyRepayments.Caption',
          {
            repaymentType: getRepaymentTypeText(repaymentType),
            loanTerm: formatLoanTerm(termInMonths, true),
            interestRate,
          },
        )}
      />
    </>
  );
}
