import { TouchableOpacity } from 'react-native';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../../testID/constants';
import { Box } from '../../atoms/Box';
import { StyledIcon } from '../../atoms/StyledIcon';
import { StyledText } from '../../atoms/StyledText';
import { pwaInstallTooltipVisibleAtom } from './recoil/pwaInstall';

export function InstallPwaTooltip() {
  const [isVisible, setIsVisible] = useRecoilState(
    pwaInstallTooltipVisibleAtom,
  );

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Box
        testID={TestID.Pwa.InstallTooltip}
        position="absolute"
        left={0}
        bottom={0}
        width="100%"
        px="m"
        pb="s"
      >
        <Box
          row
          backgroundColor="tooltipPrimaryContent"
          p="m"
          borderRadius="field"
          alignItems="center"
        >
          <StyledText flex={1} variant="body" color="buttonPrimaryContent">
            {t('Content.Pwa.Tooltip.ToInstall')}
            <StyledIcon
              family="svg"
              name="safariShare"
              size="s"
              color="buttonPrimaryContent"
              alignContent="flex-end"
            />
            {t('Content.Pwa.Tooltip.AndThen')}
            <StyledText
              variant="body"
              color="buttonPrimaryContent"
              fontWeight="semiBold"
            >
              {t('Content.Pwa.Tooltip.AddToHomeScreen')}
            </StyledText>
          </StyledText>

          <TouchableOpacity
            testID={TestID.Pwa.CloseTooltipButton}
            onPress={() => setIsVisible(false)}
          >
            <StyledIcon
              family="ionicons"
              name="close-outline"
              size="m"
              color="chinContentSecondary"
            />
          </TouchableOpacity>
        </Box>
      </Box>
      <Box
        position="absolute"
        bottom={1}
        width="100%"
        row
        justifyContent="center"
        alignItems="center"
      >
        <StyledIcon
          family="svg"
          name="tooltipArrow"
          size="xs"
          color="tooltipPrimaryContent"
        />
      </Box>
    </>
  );
}
