import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  getApolloContext,
  GraphQLRequest,
  HttpLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getCurrentHub } from '@sentry/react';
import { getActiveTransaction } from '@sentry/tracing';
import { createClient as createGraphqlWSClient } from 'graphql-ws';
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AuthContextValue } from '../Auth/context';
import {
  addBreadcrumb,
  captureException,
  SentryContextForApollo,
} from '../sentry';
import { getCurrentWindowPath } from './getCurrentWindowPath';
import { getLogger } from './loggerHelper';
import { isTest, isWeb } from './platformUtils';

type AuthenticationHeader = {
  authorization: `Bearer ${string}`;
};

type HasuraRequestIdHeader = {
  ['x-request-id']?: string | undefined;
};

export type ApolloOperationContext = {
  /** Please pass anythingThis will be passed as extra context to Sentry */
  sentryContext: SentryContextForApollo;
  headers?: AuthenticationHeader & HasuraRequestIdHeader;
  response?: { headers?: Headers | Record<string, string> };
} & Record<string, unknown>;

const logger = getLogger('AUTH');

const httpLink = (graphqlURL: string): HttpLink =>
  new HttpLink({ uri: graphqlURL });

export function buildAuthHeader(accessToken: string): AuthenticationHeader {
  return {
    authorization: `Bearer ${accessToken}`,
  };
}

export const buildHeadersForApolloLink = async (
  authContext: AuthContextValue | null,
  operation: GraphQLRequest,
  headers: Record<string, string>,
) => {
  addBreadcrumb('Auth Link', {
    operationName: operation.operationName,
    isAuthenticated: authContext?.isAuthenticated,
  });

  const token = await authContext?.getAccessToken();
  logger?.log(
    'operation:',
    operation.operationName,
    'authToken:',
    token?.slice(0, 12),
  );

  const authHeader = token ? buildAuthHeader(token) : undefined;

  return {
    headers: {
      // Will be undefined if user is not authenticated
      ...authHeader,
      // Allows overriding any headers incl. auth with custom header options
      // We need to override authorization header for the invite tokens
      ...headers,
    },
  };
};

// Set access token in Apollo's context
export function authLink(authContext: AuthContextValue | null) {
  return setContext(async (operation, { headers }) =>
    buildHeadersForApolloLink(authContext, operation, headers),
  );
}

const wsLink = (
  graphqlSubscriptionUrl: string,
  authContext: AuthContextValue | null,
) =>
  new GraphQLWsLink(
    createGraphqlWSClient({
      url: graphqlSubscriptionUrl,
      connectionParams: async () => {
        const authToken = await authContext?.getAccessToken();
        return { headers: authToken ? buildAuthHeader(authToken) : '' };
      },
      on: {
        opened: () => {
          addBreadcrumb('WS Link opened');
        },
        connecting: () => {
          addBreadcrumb('WS Link connecting');
        },
        connected: () => {
          addBreadcrumb('WS Link connected');
        },
        closed: () => {
          addBreadcrumb('WS Link closed');
        },
        error: () => {
          addBreadcrumb('WS Link error');
        },
      },
      lazy: true,
      shouldRetry: () => true,
    }),
  );

const errorLoggerLink = (authContext: AuthContextValue | null) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const errors: {
      graphQLErrors: Array<Record<string, unknown>>;
      networkError: Record<string, unknown>;
    } = {
      graphQLErrors: [],
      networkError: {},
    };

    const operationContext: Partial<ApolloOperationContext> =
      operation.getContext();

    const hasuraRequestId = operationContext.headers?.['x-request-id'];
    const errorContext = {
      ...operationContext.sentryContext,
      hasuraRequestId,
      operationName: operation.operationName,
    };

    if (operationContext.headers?.authorization == null) {
      // Force login if there is no auth token
      if (isWeb) {
        const returnTo = getCurrentWindowPath();
        authContext?.login({ returnTo });
      }
      // Do not log the error if no token is being sent
      return;
    }

    if (graphQLErrors) {
      errors.graphQLErrors = graphQLErrors.map(
        ({ message, locations, path }) => ({ message, locations, path }),
      );

      graphQLErrors.forEach((errorDetails) => {
        captureException(errorDetails.message, {
          ...errorContext,
          graphQLError: errorDetails,
        });
      });
    }

    if (networkError) {
      const serverErrorResult: Record<string, unknown> = {};
      if ('statusCode' in networkError) {
        serverErrorResult.statusCode = networkError.statusCode;
      }

      if ('bodyText' in networkError) {
        serverErrorResult.bodyText = networkError.bodyText;
      }

      if ('result' in networkError) {
        serverErrorResult.result = networkError.result;
      }

      captureException(networkError.message, {
        ...errorContext,
        networkError: serverErrorResult,
      });
    }
  });

const splitLink = (
  graphqlURL: string,
  graphqlSubscriptionUrl: string,
  authContext: AuthContextValue | null,
) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(graphqlSubscriptionUrl, authContext),
    httpLink(graphqlURL),
  );

const sentryTracingLink = () =>
  new ApolloLink((operation, forward) => {
    const { query } = operation;
    const definition = getMainDefinition(query);
    const operationType =
      definition.kind === 'OperationDefinition' ? definition.operation : '';

    if (operationType === 'subscription') {
      return forward(operation);
    }

    let transaction = getActiveTransaction();
    let closeTransactionOnFinish = false;
    if (operationType === 'mutation') {
      transaction?.finish();
      transaction = getCurrentHub().startTransaction({
        op: 'mutation',
        name: operation.operationName,
      });
      closeTransactionOnFinish = true;
    }

    const span = transaction?.startChild({
      op: `GraphQL ${operationType}`,
      description: operation.operationName,
    });

    const hasuraRequestId = uuidv4();
    const context = operation.getContext();
    context.headers = {
      ...context.headers,
      'x-request-id': hasuraRequestId,
    };

    if (span) {
      span.setTag('hasuraRequestId', hasuraRequestId);
      const sentryTraceHeader = span.toTraceparent();
      context.headers = {
        ...context.headers,
        'sentry-trace': sentryTraceHeader,
      };
    }
    operation.setContext(context);

    return forward(operation).map((res) => {
      span?.finish();
      if (closeTransactionOnFinish) {
        transaction?.finish();
      }
      return res;
    });
  });

export const createClient = ({
  graphqlURL,
  graphqlSubscriptionUrl,
  cache,
  authContext,
}: {
  graphqlURL: string;
  graphqlSubscriptionUrl: string;
  cache: ApolloCache<NormalizedCacheObject>;
  authContext: AuthContextValue | null;
}): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache,
    link: ApolloLink.from([
      sentryTracingLink(),
      errorLoggerLink(authContext),
      authLink(authContext),
      splitLink(graphqlURL, graphqlSubscriptionUrl, authContext),
    ]),
    // `connectToDevTools` default to true on nonprod env,
    // which causes Jest to hang after all tests are done.
    ...(isTest ? { connectToDevTools: false } : null),
  });

export const createUnauthenticatedClient = ({
  graphqlURL,
  cache,
}: {
  graphqlURL: string;
  cache: ApolloCache<NormalizedCacheObject>;
}): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache,
    link: ApolloLink.from([
      sentryTracingLink(),
      errorLoggerLink(null),
      httpLink(graphqlURL),
    ]),
    ...(isTest ? { connectToDevTools: false } : null),
  });

export function useApolloClient() {
  const apolloClient = useContext(getApolloContext()).client;
  if (!apolloClient) {
    throw new Error('useApolloClient must be used inside Apollo Provider');
  }
  return apolloClient as ApolloClient<NormalizedCacheObject>;
}
