import { DEFAULT_ENV, ENV_CONFIG, ENVIRONMENT, IConfig } from '../../config';
import { createContext } from '../createContext';
import { useAsyncStorage } from './useAsyncStorage';

const ASYNC_STORAGE_KEY = 'UNLOAN_ENV';

export const [useEnvConfig, EnvContextProvider] =
  createContext<EnvContextValue>('EnvConfig');

export type EnvContextValue = {
  env: ENVIRONMENT;
  config: IConfig;
  setEnv: (env: ENVIRONMENT) => void;
};

// Inferred type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePersistedEnv = () => {
  const result = useAsyncStorage<ENVIRONMENT>(ASYNC_STORAGE_KEY, DEFAULT_ENV);
  const [env, setEnv] = result;
  // Handle if persisted env does not match defined env
  const theEnv = ENV_CONFIG[env] ? env : DEFAULT_ENV;
  if (env !== theEnv) {
    setEnv(theEnv);
  }
  result[0] = theEnv;
  return result;
};
