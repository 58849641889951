import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Use AsyncStorage here because it will be reset after installation.
 * On the other side, Expo SecureStore store data in keychain which won't be reset
 */

/**
 * Don't show Face ID prompt if it has been shown or faceId has been set up in system
 */
export const NOT_SHOWN_FACE_ID_PROMPT = 'unloan-not-shown-face-id-prompt';

export const setNotShownFaceIdPrompt = async (): Promise<void> => {
  await AsyncStorage.setItem(NOT_SHOWN_FACE_ID_PROMPT, 'true');
};

export const getNotShownFaceIdPrompt = async (): Promise<boolean> => {
  const hasShownFaceIdPrompt =
    (await AsyncStorage.getItem(NOT_SHOWN_FACE_ID_PROMPT)) === 'true' || false;
  return hasShownFaceIdPrompt;
};

export const clearNotShownFaceIdPrompt = async (): Promise<void> => {
  await AsyncStorage.removeItem(NOT_SHOWN_FACE_ID_PROMPT);
};
