import { Box, BoxProps } from '../../ui/atoms/Box';
import { Card, Props as CardProps } from '../../ui/atoms/Card';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';

type Props = BoxProps & {
  onUploadDocumentPress: CardProps['onPress'];
};

export function DocumentUpload({
  onUploadDocumentPress,
  testID,
  ...otherProps
}: Props) {
  return (
    <Box {...otherProps}>
      <Card
        borderStyle="dashed"
        borderWidth={1}
        borderColor="border"
        py="xl"
        onPress={onUploadDocumentPress}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flex={1}
          testID={testID}
        >
          <StyledIcon name="add" mr="s" />
          <StyledText variant="body" fontWeight="medium" textAlign="center">
            {t('Content.UploadDocument.Placeholder')}
          </StyledText>
        </Box>
      </Card>
      <StyledText fontSize="xs" color="secondaryContent" mt="xs">
        {t('Content.UploadDocument.AllowedFilesLabel')}
      </StyledText>
    </Box>
  );
}
