import { Approval_Status_Enum } from '../../generated/graphql';

export const shouldShowApproveDeclineButtons = ({
  overallApprovalStatus,
  myResponseStatus,
}: {
  overallApprovalStatus: Approval_Status_Enum;
  myResponseStatus: Approval_Status_Enum;
}) =>
  overallApprovalStatus === Approval_Status_Enum.Pending &&
  myResponseStatus === Approval_Status_Enum.Pending;
