import { isWeb } from './platformUtils';

export function getCookie(name: string): string | null {
  if (!isWeb) {
    return null;
  }

  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] ||
    null
  );
}

/**
 *
 * Clears a cookie by setting the max age to 0, see link for
 * more detail.
 *
 * @link
 * https://javascript.info/cookie#expires-max-age
 *
 * @param name Name of the cookie
 * @param path The path attribute of the cookie
 * @param domain The domain of the cookie as set in the browser
 * @returns true on success, otherwise null if not web environment or
 * failed to get cookie
 */
export function deleteCookie(
  name: string,
  path: string,
  domain: string,
): true | null {
  if (isWeb && getCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${
      domain ? `;domain=${domain}` : ''
    };max-age=0`;
    return true;
  }

  return null;
}
