import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  AccountStatus,
  BizaAccount,
} from '../../DataSharingConfirmation/types';
import { getBizaAccounts } from '../../DataSharingConfirmation/utils/biza';
import {
  Biza_Account_Sharing_Status,
  Biza_Account_Type,
  useGetBizaAccountsQuery,
} from '../../generated/graphql';
import { JointAccountPreferencesParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { RowRight } from '../../ui/atoms/RowRight';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast } from '../../utils/arrayHelpers';
import { isKnownEnumValue } from '../../utils/typesHelpers';
import { SettingStackNavigationProp } from '../navigation/types';
import { DataSharingFooterCaption } from './ManageDataSharing';

const WarningIcon: React.FC = () => (
  <RowRight ml="m" row alignItems="center">
    <StyledIcon
      name="warning"
      color="warning"
      mr="2xs"
      testID={TestID.Icon.Warning}
    />
  </RowRight>
);

const AccountList: React.FC<{ accounts: BizaAccount[] }> = ({ accounts }) => {
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>
    >();

  const navigateToJointAccountPref = (params: JointAccountPreferencesParams) =>
    navigation.navigate(Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES, params);

  return (
    <ListRowGroup
      mx={0}
      headerText={t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.Heading',
      )}
      overflow="visible"
      testID={TestID.ManageDataSharing.DataSharingAccountList}
    >
      {accounts.map((account: BizaAccount, idx: number) => {
        const {
          id,
          masked_account_number: maskedAccountNumber,
          name,
          sharing_status: sharingStatus,
        } = account;
        if (!(id && maskedAccountNumber && name && sharingStatus)) return null;
        const params: JointAccountPreferencesParams = {
          id,
          name,
        };
        return (
          <ListRow
            key={id}
            label={name}
            labelFontWeight="semiBold"
            sublabels={[maskedAccountNumber]}
            caption={
              sharingStatus === Biza_Account_Sharing_Status.PendingApproval
                ? t('Content.DataSharingPreferences.PendingApproval')
                : t('Content.DataSharingPreferences.Status', {
                    status: isKnownEnumValue(
                      Biza_Account_Sharing_Status,
                      sharingStatus,
                    )
                      ? AccountStatus[sharingStatus].toLowerCase()
                      : 'unknown',
                  })
            }
            onPress={() => navigateToJointAccountPref(params)}
            last={isLast(accounts, idx)}
          >
            {sharingStatus === Biza_Account_Sharing_Status.PendingApproval && (
              <WarningIcon />
            )}
          </ListRow>
        );
      })}
    </ListRowGroup>
  );
};

const NoAccountFoundMessage = (): JSX.Element => (
  <>
    <StyledText
      testID={TestID.ManageDataSharing.DataSharingNoAccountsMessage}
      textAlign="center"
      variant="caption"
      pt="m"
    >
      {t('Content.DataSharingPreferences.MenuItems.JointAccounts.NoAccounts')}
    </StyledText>
    <Separator spacer />
  </>
);

function SettingsDataSharingPreferencesBase(): JSX.Element {
  const { data, loading, error } = useGetBizaAccountsQuery({
    variables: {
      type: Biza_Account_Type.Joint,
    },
    context: {
      sentryContext: {
        description: 'Fetching Biza accounts for this user.',
      },
    },
  });
  let content;

  if (error)
    content = <ErrorRow message="Unable to fetch data sharing preferences." />;
  if (loading) content = <Spinner />;
  if (data) {
    const accounts: BizaAccount[] = getBizaAccounts(data);
    content =
      accounts && accounts.length > 0 ? (
        <AccountList accounts={accounts} />
      ) : (
        <NoAccountFoundMessage />
      );
  }

  return (
    <ScreenContainer bg="bgSecondary" scrollable px="m">
      <NavHeaderSpacer />
      {content}
      <Separator spacer />
      <DataSharingFooterCaption />
    </ScreenContainer>
  );
}

export const WithAuthenticationSettingsDataSharingPreferences =
  withAuthenticationRequired(SettingsDataSharingPreferencesBase);
