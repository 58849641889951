import { useEffect, useState } from 'react';

function useTimeout(delay: number) {
  const [hasTimedout, setHasTimedout] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setHasTimedout(true);
    }, delay);

    return () => clearTimeout(id);
  }, [delay]);

  return { hasTimedout };
}

export default useTimeout;
