import type { CreateTypes, Options as ConfettiOptions } from 'canvas-confetti';
import {
  ComponentProps,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dimensions } from 'react-native';

import { captureException } from '../../sentry';
import { breakpoints } from '../../ui/theme';
import { useSingleShotEffect } from '../../utils/hooks/useSingleShotEffect';
import { ApprovedConfettiProps } from './ApprovedConfettiCommon';

const ReactCanvasConfetti = lazy(() => import('react-canvas-confetti'));
type ReactCanvasConfettiProps = ComponentProps<typeof ReactCanvasConfetti>;

const CONFETTI_GLOBAL_OPTIONS: ReactCanvasConfettiProps['globalOptions'] = {
  disableForReducedMotion: true,
  resize: true,
  useWorker: true,
};

const CONFETTI_COLORS = [
  // These are the same colors used in dynamite
  '#26ccff',
  '#a25afd',
  '#ff5e7e',
  '#88ff5a',
  '#fcff42',
  '#ffa62d',
  '#ff36ff',
];

const DEFAULT_CONFETTI_OPTIONS: ConfettiOptions = {
  ticks: 400,
  colors: CONFETTI_COLORS,
};

/**
 * The base confetti shape is a cone (imagine 🎉).
 * For approved confetti, we want a top to bottom confetti rain (imagine 🎊).
 * To achieve this, we're going to interleave multiple cones firing from above
 * directed to the bottom.
 *
 * Ideally we could simulate 🎊 better by using requestAnimationFrame().
 * e.g. With a fixed interval between wave, fire a horizontal wave of particles
 * with random offset to origin and speed. Spread them evenly across the screen
 * width.
 *
 * Not doing it right now as our designer is okay with current implementation.
 */

/**
 * Wave (or lane) is the Y offset of one or more cone.
 * Using single wave of cone will leave ugly empty space behind the cone.
 * To fill the empty space, we're going to use multiple waves.
 * This is not perfect since the space behind blast origin point
 * still has empty space but it's good enough for now.
 *
 * Wave visualiation:
 * --- Top edge of the screen ---
 * | ~~~~~~~~~ wave 3 ~~~~~~~~~ |
 * | ~~~~~~~~~ wave 2 ~~~~~~~~~ |
 * | ~~~~~~~~~ wave 1 ~~~~~~~~~ |
 */
const WAVE = {
  ONE: -0.2,
  TWO: -0.25,
  THREE: -0.3,
} as const;

const ANGLE = {
  DOWN: 270, // ↓
  DOWN_RIGHT: 292.5, // ↘
  DOWN_LEFT: 247.5, // ↙
} as const;

function invertOffset(offset: number): number {
  return 1 - offset;
}

function clamp(min: number, max: number, value: number): number {
  return Math.max(min, Math.min(max, value));
}

function fireConfetti(confetti: CreateTypes | null) {
  if (confetti == null) {
    return;
  }

  const { width } = Dimensions.get('window');

  // The particle count is scaled based on window width,
  // to keep the density consistent across different screen sizes.
  // The clamp is there to avoid too low count which makes the confetti
  // bland. Or too high count which makes the confetti laggy on big screen
  // with low compute power.
  const totalParticleCount = clamp(
    400,
    2000,
    Math.floor((width / breakpoints.desktop) * 1000),
  );

  // Center
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.3),
    spread: 120,
    origin: { x: 0.5, y: WAVE.ONE },
    angle: ANGLE.DOWN,
  });

  // Center left
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.05),
    spread: 40,
    origin: { x: 0.55, y: WAVE.TWO },
    angle: ANGLE.DOWN,
    startVelocity: 40,
  });

  // Center right
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.05),
    spread: 40,
    origin: { x: 0.45, y: WAVE.TWO },
    angle: ANGLE.DOWN,
    startVelocity: 40,
  });

  // The outermost confetti is not at 0 offset to avoid the confetti cut off
  // by the screen edge.
  const EDGE_OFFSET = 0.005;
  const SECOND_EDGE_OFFSET = 0.25;

  // Left side
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.15),
    origin: { x: EDGE_OFFSET, y: WAVE.TWO },
    angle: ANGLE.DOWN_RIGHT,
  });
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.15),
    origin: { x: SECOND_EDGE_OFFSET, y: WAVE.THREE },
    angle: ANGLE.DOWN,
  });

  // Right side
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.15),
    origin: { x: invertOffset(EDGE_OFFSET), y: WAVE.TWO },
    angle: ANGLE.DOWN_LEFT,
  });
  confetti({
    ...DEFAULT_CONFETTI_OPTIONS,
    particleCount: Math.floor(totalParticleCount * 0.15),
    origin: { x: invertOffset(SECOND_EDGE_OFFSET), y: WAVE.THREE },
    angle: ANGLE.DOWN,
  });
}

const DEFAULT_STYLE: ReactCanvasConfettiProps['style'] = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

export function ApprovedConfetti({ applicantId }: ApprovedConfettiProps) {
  const [confettiState, setConfettiState] = useState<{
    confetti: CreateTypes;
  } | null>(null);

  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  // Confetti should only fire once for each applicant per loan application.
  // As our designer said:
  // Want to keep it as a 'special' moment incase customer gets multiple VCA's.
  useSingleShotEffect(
    () => {
      if (isMountedRef.current) {
        try {
          fireConfetti(confettiState?.confetti ?? null);
        } catch (error) {
          captureException(
            'Failed to fire confetti for VCA',
            {
              applicantId,
            },
            error,
          );
        }
      }
    },
    {
      storageKey: applicantId,
      storageKeyPrefix: 'vca_confetti_',
      skip: !confettiState,
    },
  );

  const onInit = useCallback<
    NonNullable<ComponentProps<typeof ReactCanvasConfetti>['onInit']>
  >((arg) => {
    setConfettiState({ confetti: arg.confetti });
  }, []);

  return (
    <Suspense fallback={null}>
      <ReactCanvasConfetti
        globalOptions={CONFETTI_GLOBAL_OPTIONS}
        onInit={onInit}
        className="chromatic-ignore"
        // Specifying className removes the default styling,
        // so we need to reapply it here.
        style={DEFAULT_STYLE}
      />
    </Suspense>
  );
}
