import { MutationResult } from '@apollo/client';

import { CreateIdvCheckMutation } from '../../generated/graphql';
import { getLogger } from '../../utils/loggerHelper';

export const idCheckLogger = getLogger('IDENTITY_CHECK');

// ID Validation has 2 different implementation.
// Both use Onfido and has dedicated sdk per platform.
// web => `onfido-sdk-ui`
// native => `@onfido/react-native-sdk`

// @platform web
// Whenever `onfido-sdk-ui` is updated, please check
// the CSP rules needed for web SDK to work here:
// https://documentation.onfido.com/sdk/web/#content-security-policy-issues
// and make sure they are listed in our CSP.
// See docs/CONTENT_SECURITY_POLICY_CSP.md on how to modify our CSP.

// These platform differences are abstracted in:
// web => `useStartIDValidation.web.ts`
// native => `useStartIDValidation.ts`

export type StartIdValidationHookResult = {
  startIDValidation: () => Promise<void>;
  createIDVCheckMutationResult: MutationResult<CreateIdvCheckMutation>;
  idvError: IdvDisplayError | null;
};

export enum IdvDisplayError {
  NoCameraAccess,
  DiskWriteError,
  UnknownError,
}

export class FakeOnfidoErrorForTesting extends Error {
  code: string;

  constructor(code: string, message?: string) {
    super(message);
    this.name = 'FakeOnfidoErrorForTesting';
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
