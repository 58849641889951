import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { AutopaySettingsStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { AutopayAddAccount } from '../screens/AutopayAddAccount';
import { AutopayChangeRequestScreen } from '../screens/AutopayChangeRequest';
import { AutopayCustomRepayment } from '../screens/AutopayCustomRepayment';
import { AutopayEditV2 } from '../screens/AutopayEditV2';
import { AutopayExistingSettings } from '../screens/AutopayExistingSettings';
import { AutopaySelectAccount } from '../screens/AutopaySelectAccount';
import { AutopaySetupModal } from '../screens/AutopaySetupModal';

const AutopaySettingsStack = createStackNavigator<AutopaySettingsStackParams>();

export const AutopaySettingsNavigator: React.FC = () => (
  <AutopaySettingsStack.Navigator
    screenOptions={DefaultModalV2NavigatorOptions}
  >
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_EXISTING_SETTINGS}
      component={AutopayExistingSettings}
      options={{
        title: getPageTitle(Screen.AUTOPAY_EXISTING_SETTINGS),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_SETUP}
      component={AutopaySetupModal}
      options={{
        title: getPageTitle(Screen.AUTOPAY_SETUP),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_EDIT}
      component={AutopayEditV2}
      options={{
        title: getPageTitle(Screen.AUTOPAY_EDIT),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_CUSTOM_REPAYMENT}
      component={AutopayCustomRepayment}
      options={{
        title: getPageTitle(Screen.AUTOPAY_CUSTOM_REPAYMENT),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT}
      component={AutopaySelectAccount}
      options={{
        title: getPageTitle(Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT}
      component={AutopayAddAccount}
      options={{
        title: getPageTitle(Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT),
      }}
    />
    <AutopaySettingsStack.Screen
      name={Screen.AUTOPAY_CHANGE_REQUEST}
      component={AutopayChangeRequestScreen}
      options={{
        title: getPageTitle(Screen.AUTOPAY_CHANGE_REQUEST),
      }}
    />
  </AutopaySettingsStack.Navigator>
);
