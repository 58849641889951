import Svg, { Ellipse, Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function CoupleIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(31, 24, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 31 24" fill="none" {...props}>
      <Path
        d="M30.1151 12.3075C30.1151 10.4382 28.5997 8.92285 26.7305 8.92285H21.8074C20.4258 8.92285 19.2805 9.93457 19.072 11.2575L14.5091 19.7274C14.0761 20.5312 14.3767 21.534 15.1805 21.967C15.9844 22.4001 16.9871 22.0995 17.4201 21.2956L19.0381 18.2922V23.9998H29.4997V22.0745C29.8755 21.7641 30.1151 21.2945 30.1151 20.769V12.3075Z"
        fill="#A0A0A0"
      />
      <Ellipse
        rx="3.96785"
        ry="4.05228"
        transform="matrix(-1 0 0 1 23.7284 4.05228)"
        fill="#A0A0A0"
      />
      <Path
        d="M4.26987 8.92285C2.4006 8.92285 0.885254 10.4382 0.885254 12.3075V20.769C0.885254 21.2945 1.12479 21.7641 1.50064 22.0745V23.9998H11.9622V18.2762L13.5888 21.2957C14.0219 22.0995 15.0246 22.4001 15.8284 21.9671C16.6323 21.534 16.9329 20.5313 16.4998 19.7275L11.9246 11.2347C11.7066 9.92295 10.5666 8.92285 9.19294 8.92285H4.26987Z"
        fill="black"
      />
      <Ellipse
        cx="7.27181"
        cy="4.05228"
        rx="3.96785"
        ry="4.05228"
        fill="black"
      />
    </Svg>
  );
}
