import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { LoanApplicationScreenContainer } from '../../LoanApplication/components/LoanApplicationScreenContainer';
import { Link } from '../../ui/atoms/Link';
import { Props as TextProps, StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';
import { useXButton } from '../../utils/hooks/useHeaderButton';

function BodyText(props: TextProps) {
  return <StyledText variant="body" mb="l" {...props} />;
}

function SectionHeaderText(props: TextProps) {
  return <BodyText fontWeight="semiBold" mb="s" {...props} />;
}

export function LearnOpenBankingModal() {
  const navigation = useNavigation();
  useXButton(navigation);
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  return (
    <LoanApplicationScreenContainer
      scrollable
      containerStyle={{
        padding: theme.spacing.m,
        paddingTop: 0,
        paddingBottom: theme.spacing.m + insets.bottom,
      }}
    >
      <NavHeaderSpacer />
      <StyledText variant="header" fontSize="xl" textAlign="center" mb="m">
        {t('Content.LearnOpenBankingModal.Header')}
      </StyledText>
      <BodyText>{t('Content.LearnOpenBankingModal.Caption')}</BodyText>
      <SectionHeaderText>
        {t('Content.LearnOpenBankingModal.Benefits')}
      </SectionHeaderText>
      <BodyText>{t('Content.LearnOpenBankingModal.BenefitsCaption')}</BodyText>
      <SectionHeaderText>
        {t('Content.LearnOpenBankingModal.SharingData')}
      </SectionHeaderText>
      <BodyText>
        {t('Content.LearnOpenBankingModal.SharingDataCaption')}
      </BodyText>
      <SectionHeaderText>
        {t('Content.LearnOpenBankingModal.CDR')}
      </SectionHeaderText>
      <BodyText>
        {t('Content.LearnOpenBankingModal.CDRCaption')}
        <Link variant="body" href={t('Link.UnloanConsumerDataRightPolicy')}>
          {t('Content.LearnOpenBankingModal.DownloadCDRLink')}
        </Link>
      </BodyText>
    </LoanApplicationScreenContainer>
  );
}
