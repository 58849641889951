import { Role, RoleWebOnly } from 'react-native';

import { isWeb } from '../../utils/platformUtils';

export const LISTBOX_ACCESSBILITY_ROLE: Role | RoleWebOnly = isWeb
  ? 'listbox'
  : 'menu';
export const OPTION_ACCESSBILITY_ROLE: Role | RoleWebOnly = isWeb
  ? 'option'
  : 'menuitem';
