import addMonths from 'date-fns/addMonths';

import { AppAddressFormat } from '../../components/form/types';
import {
  Detected_Liability_Details_V2,
  Loan_Interest_Rate_Type_Input_Enum,
} from '../../generated/graphql';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import {
  LoanDetailsFormField,
  LoanDetailsFormValues,
  LoanDetailsManualAddressFormField,
} from '../components/LoanDetailsForm';

export function mapLoanDetailsFormValuesToDetectedLiabilityDetails({
  detectedLiabilityIdentifier,
  formValues,
}: {
  formValues: LoanDetailsFormValues;
  detectedLiabilityIdentifier: string;
}): Detected_Liability_Details_V2 {
  let propertyData: Pick<
    Detected_Liability_Details_V2,
    'user_provided_property' | 'domain_api_address_input'
  > | null = null;

  const propertyAddress = getPropertyAddressFromFormValues(formValues);
  if (propertyAddress) {
    const { manualAddressInput, domainApiAddressInput } =
      parseAddressInputForMutationAction(propertyAddress);
    propertyData = {
      user_provided_property: manualAddressInput,
      domain_api_address_input: domainApiAddressInput,
    };
  }

  const interestRateType = parseEnumType(
    Loan_Interest_Rate_Type_Input_Enum,
    formValues[LoanDetailsFormField.LoanRateType],
  );

  const totalTermInMonths =
    formValues[LoanDetailsFormField.TermInMonthsYearPart] != null &&
    formValues[LoanDetailsFormField.TermInMonthsMonthPart] != null
      ? formValues[LoanDetailsFormField.TermInMonthsYearPart] +
        formValues[LoanDetailsFormField.TermInMonthsMonthPart]
      : null;

  return {
    account_owners: formValues[LoanDetailsFormField.AccountOwners],
    account_number: formValues[LoanDetailsFormField.AccountNumber],
    identifier: detectedLiabilityIdentifier,
    interest_rate: formValues[LoanDetailsFormField.InterestRate]
      ? parseFloat(formValues[LoanDetailsFormField.InterestRate])
      : null,
    monthly_repayment: formValues[LoanDetailsFormField.MonthlyRepaymentAmount],
    loan_term_end_date: totalTermInMonths
      ? addMonths(new Date(), totalTermInMonths).toISOString()
      : null,
    maximum_credit_available: Math.max(
      formValues[LoanDetailsFormField.Balance] ?? 0,
      formValues[LoanDetailsFormField.Limit] ?? 0,
    ),
    institution: {
      id: formValues[LoanDetailsFormField.Institution]?.id || null,
      name: formValues[LoanDetailsFormField.Institution]?.name || '',
    },
    ...propertyData,
    ...(interestRateType
      ? {
          interest_rate_type: interestRateType,
          fixed_rate_expiry_date:
            interestRateType === Loan_Interest_Rate_Type_Input_Enum.Fixed &&
            formValues[LoanDetailsFormField.FixedRateExpiryDate]
              ? safelyFormatDate(
                  formValues[LoanDetailsFormField.FixedRateExpiryDate],
                  'yyyy-MM-dd',
                )
              : null,
        }
      : null),
    loan_term_months: totalTermInMonths,
  };
}

export function getPropertyAddressFromFormValues(
  formValues: LoanDetailsFormValues,
): AppAddressFormat | null {
  const isManualInput =
    formValues[LoanDetailsManualAddressFormField.isManualInput];

  return isManualInput
    ? {
        isProvidedByUser: true,
        postcode: formValues[LoanDetailsManualAddressFormField.postcode],
        street: formValues[LoanDetailsManualAddressFormField.streetName],
        streetNo: formValues[LoanDetailsManualAddressFormField.streetNo],
        unitNo: formValues[LoanDetailsManualAddressFormField.unitNo],
        state: formValues[LoanDetailsManualAddressFormField.state],
        streetType:
          formValues[LoanDetailsManualAddressFormField.streetType]?.value,
        suburb: formValues[LoanDetailsManualAddressFormField.suburb],
      }
    : formValues[LoanDetailsFormField.PropertyAddress];
}
