import { Text, View } from 'dripsy';
import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';

type AccountDetailsSectionProps = {
  email: string;
  mobile: string;
  featureFlagLoading?: boolean;
  emailChangeInProgress?: boolean;
  mobileChangeInProgress?: boolean;
  onEmailChange?: () => void;
  onMobileChange?: () => void;
};

export const AccountDetailsSection = ({
  email,
  mobile,
  featureFlagLoading = false,
  emailChangeInProgress = false,
  mobileChangeInProgress = false,
  onEmailChange,
  onMobileChange,
}: AccountDetailsSectionProps) => {
  const emailDataRowCaption = useMemo(
    () => (
      <View>
        <Text variant="xsHeader">{t('Content.PersonalDetails.Email')}</Text>
        <Text variant="body">
          {featureFlagLoading ? t('Content.Common.Placeholder.Loading') : email}
        </Text>
      </View>
    ),
    [email, featureFlagLoading],
  );

  const mobileDataRowCaption = useMemo(
    () => (
      <View>
        <Text variant="xsHeader">{t('Content.PersonalDetails.Mobile')}</Text>
        <Text variant="body">
          {featureFlagLoading
            ? t('Content.Common.Placeholder.Loading')
            : mobile}
        </Text>
      </View>
    ),
    [featureFlagLoading, mobile],
  );

  const emailLoading = featureFlagLoading || emailChangeInProgress;

  const mobileLoading = featureFlagLoading || mobileChangeInProgress;

  return (
    <View>
      <Text variant="sHeader" sx={{ py: '$8', mb: '$8' }}>
        {t('Content.PersonalDetails.AccountDetailsHeading')}
      </Text>
      <DataRowGroup mt={0}>
        <DataRow
          label=""
          onPress={emailLoading ? undefined : onEmailChange}
          loading={emailLoading}
          caption={emailDataRowCaption}
          testID={TestID.PersonalDetails.ChangeEmailButton}
        />
        <DataRowSeparator />
        <DataRow
          label=""
          onPress={mobileLoading ? undefined : onMobileChange}
          loading={mobileLoading}
          caption={mobileDataRowCaption}
          testID={TestID.PersonalDetails.ChangeMobileButton}
        />
      </DataRowGroup>
    </View>
  );
};
