import { StyleSheet } from 'react-native';

import { FocusOn } from '../../components/FocusOn';
import { SpinnerWithTextOverlay } from '../../ui/organisms/Overlay';

export function UploadOverlay() {
  return (
    <FocusOn style={StyleSheet.absoluteFill}>
      <SpinnerWithTextOverlay
        text={t('Content.SubmitLoanApplication.Uploading')}
      />
    </FocusOn>
  );
}
