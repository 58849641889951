import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import * as React from 'react';

import { ActionCard } from '../../ui/organisms/ActionCard';
import { formatCurrency } from '../../utils/currencyHelpers';
import { joinListOfString } from '../../utils/stringHelpers';
import { formatNextAutopayDate } from '../utils/formatNextAutopayDate';

type Props = {
  nextRepaymentDate: string;
  repaymentAmount: number;
  propertyAddress?: string;
  onPendingMyApproval?: () => void;
  onViewAutopay?: () => void;
  coBorrowers?: string[];
  isProcessing?: boolean;
  loading?: boolean;
};

export const AutoPayDebitCard: React.FC<Props> = ({
  nextRepaymentDate,
  repaymentAmount,
  propertyAddress,
  onPendingMyApproval,
  onViewAutopay,
  coBorrowers,
  isProcessing,
  loading,
}) => {
  const displayActionAlert =
    onPendingMyApproval || !isEmpty(coBorrowers) || isProcessing;

  const getAlertLabel = useCallback(() => {
    if (onPendingMyApproval) {
      return t('Content.Approvals.ApprovalRequired');
    }
    if (coBorrowers && !isEmpty(coBorrowers)) {
      if (coBorrowers.length === 1) {
        return t('Content.Approvals.NeedsToApproveThisRequest', {
          approver: joinListOfString(coBorrowers),
        });
      }
      return t('Content.Approvals.NeedToApproveThisRequest', {
        approvers: joinListOfString(coBorrowers),
      });
    }
    if (isProcessing) {
      return t('Content.Approvals.Processing');
    }
    return '';
  }, [onPendingMyApproval, coBorrowers, isProcessing]);

  const getCaption = useCallback(() => {
    if (onPendingMyApproval) {
      return t('Content.Approvals.Pending');
    }

    return formatNextAutopayDate(nextRepaymentDate);
  }, [nextRepaymentDate, onPendingMyApproval]);

  return (
    <ActionCard
      loading={loading}
      label={t('Content.HomeLoan.AutoPayDebit')}
      caption={getCaption()}
      subCaption={propertyAddress}
      amountLabel={`${formatCurrency(repaymentAmount)}`}
      onPress={onViewAutopay}
      alertAction={
        displayActionAlert
          ? {
              showPendingIcon: !onPendingMyApproval,
              alertLabel: getAlertLabel(),
              alertActionLabel: t('Content.Approvals.ViewAndApprove'),
              onPressAlertAction: onPendingMyApproval,
            }
          : undefined
      }
    />
  );
};
