import { ApplicantParam } from '../../navigation/types/navTypes';
import { joinListOfString } from '../../utils/stringHelpers';
import { Props as ModalFooterProps } from '../components/LoanApplicationWizardFooter';
import { useYourExpensesScreenQuery } from '../graphql/queries';

export function getYourExpensesModalFooterPropsV2({
  householdExpenses,
  applicants,
  loading,
  navigateToAddExpenseScreen,
  navigateToNextScreen,
}: Pick<
  ReturnType<typeof useYourExpensesScreenQuery>,
  'householdExpenses' | 'loading'
> & {
  applicants: Array<ApplicantParam>;
  navigateToNextScreen: () => void;
  navigateToAddExpenseScreen: () => void;
}): ModalFooterProps {
  if (loading) {
    return {
      isPrimaryButtonLoading: true,
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
    };
  }
  if (householdExpenses == null || householdExpenses.length < 1) {
    return {
      primaryButtonLabel: t('Content.YourExpenses.ButtonLabel.AddExpenses'),
      onPrimaryButtonPress: navigateToAddExpenseScreen,
    };
  }

  const expenseError = getExpenseError({ applicants, loading });
  return {
    primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
    onPrimaryButtonPress: navigateToNextScreen,
    footerCaption: t('Content.YourExpenses.ConfirmationFooter'),
    ...expenseError,
  };
}

function getExpenseError({
  applicants,
  loading,
}: {
  applicants: ApplicantParam[];
  loading: boolean;
}) {
  const applicantWithoutHousehold: Array<string> = applicants
    .filter(({ household_id }) => !household_id)
    .map(({ latest_full_name }) => latest_full_name || '');
  if (applicantWithoutHousehold.length < 1 || loading) {
    return {};
  }
  const lastApplicantWithoutHousehold =
    applicantWithoutHousehold?.[applicantWithoutHousehold.length];
  const joinedApplicants = joinListOfString(applicantWithoutHousehold);
  return {
    disablePrimaryButton: true,
    footerCaption: lastApplicantWithoutHousehold?.endsWith('s')
      ? t(
          'Content.YourFinancials.MissingDeclaredHousehold.NamedHouseholdEndWithS',
          {
            applicantNames: joinedApplicants,
          },
        )
      : t('Content.YourFinancials.MissingDeclaredHousehold.NamedHousehold', {
          applicantNames: joinedApplicants,
        }),
  };
}
