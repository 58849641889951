import { Text } from 'dripsy';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { InfoRow } from '../../components/InfoRow';
import { useGetReduceRepaymentsConfirmationScreenDataQuery } from '../../generated/graphql';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { UpdatedRepaymentsDetails } from '../components/UpdatedRepaymentsDetails';
import {
  ReduceRepaymentsAtomFamily,
  ReduceRepaymentsConfirmedAtomFamily,
} from '../utils/reduceRepaymentsAtom';

type Props =
  HomeTabScreenProps<Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION>;

export const ReduceRepaymentsConfirmation = ({ navigation, route }: Props) => {
  const { cbaAccountId } = route.params;
  const { data, loading } = useGetReduceRepaymentsConfirmationScreenDataQuery({
    context: {
      sentryContext: {
        description: 'Fetch data for Reduce Repayments Confirmation screen',
      },
    },
    variables: {
      cbaAccountId,
    },
  });

  const {
    newMinimumRepaymentAmount,
    previousRepaymentAmount,
    minimumRepaymentReducedAmount,
    redrawBalanceAvailable,
    previousRedrawAmount,
    redrawReduced,
  } = useRecoilValue(ReduceRepaymentsAtomFamily(cbaAccountId));

  // To track if the user has confirmed the loan variation request
  const isRepaymentConfirmed = useRecoilValue(
    ReduceRepaymentsConfirmedAtomFamily(cbaAccountId),
  );

  const otherBorrowersNames = useMemo(() => {
    const allBorrowersIdentityProfiles =
      data?.loan_account_by_id?.loan_application_target?.applicants.map(
        (applicant) => applicant.user_identity_profile,
      );
    const currentUserIdentityProfileId =
      data?.me[0]?.user?.identity_profile?.id;
    return allBorrowersIdentityProfiles
      ?.filter(
        (identityProfile) =>
          identityProfile?.id !== currentUserIdentityProfileId,
      )
      .map((identityProfile) => identityProfile?.full_name)
      .join(' and ');
  }, [data]);

  const navigateBack = useCallback(() => navigation.goBack(), [navigation]);

  // When the state is cleared, we want to navigate the user back
  useEffect(() => {
    if (
      newMinimumRepaymentAmount < 1 ||
      previousRepaymentAmount < 1 ||
      minimumRepaymentReducedAmount < 1 ||
      previousRedrawAmount < 1 ||
      redrawReduced < 1 ||
      isRepaymentConfirmed
    ) {
      navigateBack();
    }
  }, [
    navigateBack,
    newMinimumRepaymentAmount,
    previousRepaymentAmount,
    minimumRepaymentReducedAmount,
    previousRedrawAmount,
    redrawReduced,
    isRepaymentConfirmed,
  ]);

  const onSubmit = useCallback(() => {
    navigation.navigate(ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES, {
      cbaAccountId,
      otherBorrowersNames,
    });
  }, [cbaAccountId, navigation, otherBorrowersNames]);

  return (
    <UpliftScreenContainer onPressBack={navigateBack}>
      <Text variant="header" sx={{ mt: '$8' }}>
        {t('Content.LoanVariation.ReduceRepaymentConfirmation.Title')}
      </Text>

      <Text
        variant="subHeading"
        sx={{ mt: '$8', mb: otherBorrowersNames ? '$16' : '32' }}
      >
        {t('Content.LoanVariation.ReduceRepaymentConfirmation.SubHeading')}
      </Text>

      {otherBorrowersNames ? (
        <InfoRow
          message={t(
            'Content.LoanVariation.ReduceRepaymentConfirmation.NeedCoBorrowerApproval',
            {
              coBorrowerNames: otherBorrowersNames,
            },
          )}
        />
      ) : null}
      <Separator
        spacer
        py={otherBorrowersNames ? 's' : 'xs'}
        my={otherBorrowersNames ? 'xs' : 0}
      />
      <UpdatedRepaymentsDetails
        loading={loading}
        newMinimumRepaymentAmount={newMinimumRepaymentAmount}
        previousRepaymentAmount={previousRepaymentAmount}
        minimumRepaymentReducedAmount={minimumRepaymentReducedAmount}
        redrawBalanceAvailable={redrawBalanceAvailable}
        previousRedrawAmount={previousRedrawAmount}
        redrawReduced={redrawReduced}
      />
      <Separator spacer py="s" my="xs" />
      <Text
        sx={{ my: '$16', mx: '$24' }}
        style={{ textAlign: 'center' }}
        variant="caption"
      >
        {t('Content.LoanVariation.ReduceRepaymentConfirmation.Disclaimer')}
      </Text>
      <Button
        testID={TestID.LoanVariation.ConfirmRepayment.SubmitButton}
        disabled={loading}
        label={t('Content.Common.ButtonLabel.Continue')}
        alignSelf="stretch"
        onPress={onSubmit}
      />
    </UpliftScreenContainer>
  );
};
