import { Text, useSx } from 'dripsy';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { useBorrowerWizardState } from '../../Identification/components/BorrowerFormContext';
import { currentLoggedInBorrowerDetailsV2Atom } from '../../Identification/currentLoggedInBorrowerDetailsAtom';
import { learnMoreReturnScreenAtom } from '../../Identification/learnMoreReturnScreenAtom';
import { IdentificationV2ScreenProps } from '../../Identification/navigation/types';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';

function Message({
  onLearnMoreClick,
}: {
  onLearnMoreClick: () => Promise<void>;
}) {
  return (
    <>
      <Text variant="sBody" sx={{ textAlign: 'center' }}>
        {t('Content.InformationForm.IdentityVerification.Pending.Message')}
      </Text>
      <Text
        onPress={onLearnMoreClick}
        variant="sBody"
        sx={{ textAlign: 'center', color: '$link' }}
      >
        {t('Content.InformationForm.IdentityVerification.ViewNotice')}
      </Text>
    </>
  );
}

type Props =
  IdentificationV2ScreenProps<Screen.IDENTITY_VERIFICATION_PENDING_V2>;

export function IdentityVerificationPendingActionSheetV2({
  navigation,
  route,
}: Props) {
  const sx = useSx();

  const { loanApplicationId } = route.params;
  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onContinuePress = async () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  };

  const { values } = useBorrowerWizardState();
  const setCurrentLoggedInBorrowerDetails = useSetRecoilState(
    currentLoggedInBorrowerDetailsV2Atom(loanApplicationId || ''),
  );
  const setLearnMoreReturnScreenAtom = useSetRecoilState(
    learnMoreReturnScreenAtom(loanApplicationId || ''),
  );

  const onViewNoticePress = async () => {
    setCurrentLoggedInBorrowerDetails(values);
    setLearnMoreReturnScreenAtom(Screen.LOAN_APPLICATION_V2_BORROWERS);
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL,
      params: { loanApplicationId },
    });
  };

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      title={t('Content.InformationForm.IdentityVerification.Pending.Title')}
      message={<Message onLearnMoreClick={onViewNoticePress} />}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Continue'),
          onPress: onContinuePress,
          testID: TestID.IdentityVerificationPendingActionSheet.ContinueButton,
          style: sx({ mt: '$8' }),
        },
      ]}
    />
  );
}
