import { gql } from '@apollo/client';
import { useDripsyTheme, View } from 'dripsy';
import * as React from 'react';

import { ErrorFallback } from '../../components/ErrorFallback';
import {
  Approval_Status_Enum,
  ApprovalRequestNotificationFragment,
  Payment_Request_Status_Enum,
  WithdrawalApprovalRequestFragment,
} from '../../generated/graphql';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { Skeleton } from '../../ui/v2/Skeleton';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import { ApprovalControls } from './ApprovalControls';
import { ApprovalStatusListGroup } from './ApprovalStatusListGroup';

export const WithdrawalApprovalRequestFragmentDef = gql`
  fragment WithdrawalApprovalRequest on approval_request {
    withdrawal_request {
      id
      amount
      description
      external_account {
        id
        masked_account_number
        account_name
        account_bsb
        account_number
      }
      payment_request {
        id
        status
        cba_account_id
        cba_receipt_id
        created_at
      }
    }
  }
`;

type Props = {
  loading: boolean;
  approvalRequest: ApprovalRequestNotificationFragment & {
    withdrawal_request: NonNullable<
      WithdrawalApprovalRequestFragment['withdrawal_request']
    >;
  };
};

const getWithdrawalRequestStatusLabel = (
  paymentStatus: Payment_Request_Status_Enum,
) => {
  switch (paymentStatus) {
    case Payment_Request_Status_Enum.Completed:
      return t('Content.Approvals.Processed');
    case Payment_Request_Status_Enum.Failed:
      return t('Content.Approvals.Failed');
    default:
      return t('Content.Approvals.Processing');
  }
};

export const ApprovalWithdrawal: React.FC<Props> = ({
  loading: approvalRequestLoading,
  approvalRequest,
}) => {
  const withdrawalRequest = approvalRequest.withdrawal_request;
  const paymentRequest = withdrawalRequest.payment_request;
  const externalAccount = withdrawalRequest.external_account;
  const isApproved = approvalRequest?.status === Approval_Status_Enum.Approved;

  const {
    data: loanAccountNameData,
    loading: loanAccountNameLoading,
    error: loanAccountNameError,
  } = useGetLoanAccountName(paymentRequest.cba_account_id);

  const { accountName = '', bsbJoinedWithAccountNumber = '' } =
    loanAccountNameData ?? {};

  const loading = approvalRequestLoading || loanAccountNameLoading;

  const statusListGroup = React.useMemo(() => {
    if (withdrawalRequest && isApproved) {
      return (
        <DataRowGroup>
          <DataRow
            loading={loading}
            label={t('Content.Approvals.Status')}
            caption={getWithdrawalRequestStatusLabel(
              withdrawalRequest.payment_request.status,
            )}
          />
        </DataRowGroup>
      );
    }

    return (
      <ApprovalStatusListGroup
        loading={loading}
        approvalRequest={approvalRequest}
      />
    );
  }, [withdrawalRequest, isApproved, loading, approvalRequest]);

  const { theme } = useDripsyTheme();

  if (loanAccountNameError) {
    return (
      <ErrorFallback
        title=""
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        captionLink={t('Content.Common.Error.SomethingWentWrong.CaptionLink')}
        hideUnloanLogo
        bg="transparent"
        flex={1}
        justifyContent="center"
      />
    );
  }

  return (
    <>
      {loading ? (
        <View
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Skeleton show height={theme.space.$56} width={160} />

          <View sx={{ mt: '$8' }}>
            <Skeleton show height={theme.space.$20} width={100} />
          </View>
        </View>
      ) : (
        <>
          <StyledText textAlign="center" variant="numberLarge">
            {`-${formatCurrency(withdrawalRequest.amount)}`}
          </StyledText>
          <StyledText textAlign="center">Transfer</StyledText>
          <StyledText textAlign="center" color="secondaryContent">
            {withdrawalRequest.description}
          </StyledText>
        </>
      )}

      {statusListGroup}

      <DataRowGroup>
        <DataRow
          loading={loading}
          label="From"
          caption={
            <>
              {accountName}
              <Separator spacer py={0} />
              {bsbJoinedWithAccountNumber}
            </>
          }
        />

        <DataRowSeparator />

        <DataRow
          loading={loading}
          label="To"
          caption={
            <>
              {externalAccount?.account_name}

              <Separator spacer py={0} />

              {formatBsbAndAccountNumber(
                externalAccount?.account_bsb ?? '',
                externalAccount?.account_number ?? '',
              )}
            </>
          }
        />
      </DataRowGroup>
      <ApprovalControls loading={loading} approvalRequest={approvalRequest} />
    </>
  );
};
