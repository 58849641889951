import { gql } from '@apollo/client';

import { Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Fastlink } from '../components/Fastlink';
import { SelectInstitutionScreenProps } from '../navigation/types';
import { fastlinkEmitter } from '../utils/fastlinkEmitter';

type Props = SelectInstitutionScreenProps<Screen.BANK_LOGIN>;

export const GET_ACCESS_TOKEN = gql`
  query GetOBAccessToken {
    getOpenBankingAccessToken {
      accessToken
    }
  }
`;

export function BankLogin({ navigation, route }: Props) {
  const { loanApplicationId } = route.params;

  const navigateToManualUpload = () =>
    navigation.navigate(Screen.LOAN_APPLICATION_V2, {
      screen: Screen.LOAN_APPLICATION_V2_SUBMIT,
      params: { loanApplicationId },
    });

  return (
    <ScreenContainer safeAreaBottom={false}>
      <Fastlink
        onSuccess={({ providerAccountId, requestId }) => {
          fastlinkEmitter.emit('completed', {
            requestId,
            institutionConnectionId: Number(providerAccountId),
          });
        }}
        onError={({
          providerAccountId: institutionConnectionId,
          requestId,
          reason,
        }) => {
          fastlinkEmitter.emit('failed', {
            institutionConnectionId: Number(institutionConnectionId),
            requestId,
            reason,
          });
        }}
        onClose={navigation.goBack}
        errorContext={route.params}
        navigateToManualUpload={navigateToManualUpload}
        navigateBack={navigation.goBack}
      />
    </ScreenContainer>
  );
}
