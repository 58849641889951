import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function DownloadIcon({ size, color, opacity, ...props }: SvgIconProps) {
  const width = resizeIconWidth(28, 24, size);

  return (
    <Svg
      width={width}
      height={size}
      fill="none"
      viewBox={`0 0 ${width} ${size}`}
      {...props}
    >
      <Path
        d="M17.212 17.014v1.73h5.017c3.297 0 5.274-1.988 5.274-4.588 0-2.17-1.3-3.953-3.395-4.802.022-4.79-3.426-8.25-7.82-8.25-2.911 0-4.984 1.558-6.273 3.438-2.439-.655-5.522 1.235-5.576 4.254-2.427.376-3.942 2.363-3.942 4.898 0 2.793 2.095 5.05 5.618 5.05h4.77v-1.73h-4.78c-2.546 0-3.878-1.472-3.878-3.32 0-2.051 1.3-3.577 3.577-3.577.15 0 .204-.086.204-.225 0-3.298 2.374-4.211 4.587-3.567.129.043.204.022.268-.096 1.042-1.859 2.643-3.395 5.425-3.395 3.523 0 5.962 2.793 6.145 5.994.032.484 0 1.064-.043 1.569-.011.14.043.225.171.247 1.956.43 3.212 1.654 3.212 3.512 0 1.654-1.203 2.858-3.555 2.858h-5.006Zm-3.158 8.11c.215 0 .386-.086.612-.29l3.63-3.395a.714.714 0 0 0 .259-.569c0-.43-.344-.752-.785-.752a.846.846 0 0 0-.58.258l-1.632 1.633-.72.752.064-1.6v-9.218a.855.855 0 0 0-.848-.838c-.462 0-.86.387-.86.838v9.217l.065 1.611-.72-.762-1.633-1.633a.87.87 0 0 0-.59-.258c-.452 0-.774.322-.774.752 0 .236.086.42.258.57l3.63 3.394c.226.204.398.29.624.29Z"
        fill={color}
        opacity={opacity}
      />
    </Svg>
  );
}
