import { Text, View } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { FormSpacer } from '../../components/form/FormSpacer';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { EstimatedEquityPositionCard } from '../components/PropertyValue/EstimatedEquityPositionCard';
import { Loading } from '../components/PropertyValue/Loading';
import { PropertyAttributesCard } from '../components/PropertyValue/PropertyAttributesCard';
import { usePropertyValueInsight } from '../hooks/usePropertyValueInsight';
import { HomeLoanInsightsScreenProps } from '../navigation/types';

type Props =
  HomeLoanInsightsScreenProps<Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE>;

export const PropertyValueInsightsScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { cbaAccountId } = route.params;
  const {
    unloanValuation,
    domainValuation,
    propertyShortAddress,
    propertyGeoLocation,
    propertyState,
    loading,
    domainPropertyDetailsData,
    equity,
  } = usePropertyValueInsight(cbaAccountId);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  if (loading) {
    return (
      <ModalScreenContainer
        headerText={t('Content.HomeLoan.Insights.PropertyValue.Header')}
        onClose={onClose}
        hideBackButton
        scrollable
      >
        <Loading />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      headerText={t('Content.HomeLoan.Insights.PropertyValue.Header')}
      onClose={onClose}
      hideBackButton
      scrollable
    >
      <PropertyAttributesCard
        geoLocation={propertyGeoLocation || undefined}
        propertyDetails={{
          shortFormatAddress: propertyShortAddress || '',
          addressState: propertyState,
          bedrooms:
            domainPropertyDetailsData?.domain_property_detail.bedrooms || 0,
          bathrooms:
            domainPropertyDetailsData?.domain_property_detail.bathrooms || 0,
          garages:
            domainPropertyDetailsData?.domain_property_detail.car_spaces || 0,
          propertyType:
            domainPropertyDetailsData?.domain_property_detail.property_type ||
            '',
        }}
        estimatedPropertyValue={domainValuation || undefined}
        originalValuation={unloanValuation || 0}
      />

      {!domainValuation ? (
        <Text variant="caption" sx={{ mt: '$8' }}>
          {t(
            'Content.HomeLoan.Insights.PropertyValue.OriginalValuationDisclaimer',
          )}
        </Text>
      ) : null}

      {equity?.equityAmount && equity.equityAmount >= 0 ? (
        <View>
          <FormSpacer py="$16" />
          <EstimatedEquityPositionCard
            equityAmount={equity?.equityAmount || 0}
            equityPercentage={equity?.equityPercentage || 0}
            isThirdPartyValuation={!!domainValuation}
          />
        </View>
      ) : null}

      <Text variant="caption" sx={{ mt: '$24' }}>
        {domainValuation
          ? t(
              'Content.HomeLoan.Insights.PropertyValue.InsightDisclaimerWithThirdPartyValuation',
            )
          : t(
              'Content.HomeLoan.Insights.PropertyValue.InsightDisclaimerWithUnloanValuation',
            )}
      </Text>
    </ModalScreenContainer>
  );
};
