import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { SvgIconProps } from './types';

export const CDRLogo: React.FC<SvgIconProps> = ({ size, color, ...props }) => {
  const theme = useTheme();
  return (
    <Svg width={size} height={size} viewBox="0 0 40 40" fill="none" {...props}>
      <G opacity="0.6">
        <Path
          d="M7.27384 33.7526C6.80023 34.2644 5.99815 34.2981 5.51723 33.7931C3.47966 31.6537 1.93761 29.0852 1.00815 26.2697C0.0786837 23.4543 -0.211537 20.4725 0.151803 17.5405C0.23756 16.8484 0.902017 16.3979 1.58726 16.5272C2.2725 16.6564 2.71842 17.3166 2.63898 18.0093C2.35109 20.5201 2.61071 23.0688 3.40609 25.4781C4.20147 27.8874 5.51028 30.0897 7.23642 31.9356C7.71271 32.4449 7.74745 33.2408 7.27384 33.7526Z"
          fill={color}
        />
        <Path
          d="M2.39605 13.582C1.74091 13.3432 1.39972 12.6165 1.67943 11.9777C2.6136 9.84434 3.91461 7.88743 5.52636 6.19733C7.13811 4.50723 9.03111 3.11487 11.1178 2.08056C11.7426 1.77087 12.4846 2.0772 12.7543 2.72027C13.0239 3.36335 12.7187 4.09917 12.0968 4.41455C10.3298 5.31054 8.72529 6.50194 7.35383 7.94008C5.98236 9.37821 4.86841 11.0375 4.05723 12.8449C3.77171 13.4811 3.05119 13.8209 2.39605 13.582Z"
          fill={color}
        />
        <Path
          d="M15.2483 1.87513C15.0715 1.2006 15.4744 0.506265 16.1588 0.372333C19.3284 -0.247997 22.6079 -0.0925674 25.715 0.833909C28.8221 1.76039 31.6511 3.4264 33.9634 5.68134C34.4626 6.16819 34.4195 6.96985 33.9022 7.43737C33.3848 7.9049 32.5894 7.86074 32.0857 7.37846C30.0868 5.46441 27.657 4.0481 24.9934 3.25385C22.3297 2.4596 19.5211 2.3139 16.8004 2.82065C16.1148 2.94834 15.4251 2.54966 15.2483 1.87513Z"
          fill={color}
        />
        <Path
          d="M35.4327 9.37356C36.007 8.97809 36.797 9.12121 37.1554 9.71936C38.5997 12.1294 39.5225 14.8211 39.8579 17.6202C40.1934 20.4193 39.9327 23.2528 39.0988 25.9358C38.8919 26.6017 38.1581 26.9275 37.5066 26.6789C36.8551 26.4303 36.5331 25.7017 36.734 25.0339C37.4256 22.7349 37.6374 20.3134 37.3506 17.9207C37.0639 15.528 36.2859 13.225 35.0707 11.1545C34.7177 10.5531 34.8584 9.76903 35.4327 9.37356Z"
          fill={color}
        />
        <Path
          d="M36.3729 29.1114C36.9822 29.4505 37.2046 30.2219 36.8278 30.8086C36.2881 31.6489 35.6862 32.4476 35.0271 33.1979C34.567 33.7219 33.7642 33.7206 33.2703 33.2283C32.7765 32.736 32.7791 31.9394 33.2344 31.4113C33.7348 30.831 34.1964 30.2185 34.6163 29.5776C34.9985 28.9944 35.7635 28.7724 36.3729 29.1114Z"
          fill={color}
        />
      </G>
      <G opacity="0.6">
        <Path
          d="M9.8299 30.1701C9.39357 30.6064 8.6827 30.6089 8.27892 30.1423C7.30993 29.0225 6.50638 27.7695 5.89285 26.4217C5.63719 25.8601 5.93601 25.2151 6.51458 25.0005C7.09316 24.786 7.73184 25.0836 7.9943 25.6421C8.48745 26.6915 9.11609 27.6717 9.86408 28.5576C10.2622 29.0291 10.2662 29.7338 9.8299 30.1701Z"
          fill={color}
        />
        <Path
          d="M5.98276 23.2216C5.38136 23.3598 4.77756 22.9846 4.68301 22.3748C4.39681 20.529 4.44617 18.6434 4.83193 16.8095C5.29448 14.6104 6.22966 12.5383 7.57264 10.7366C8.91563 8.9349 10.6343 7.44678 12.6096 6.37533C14.2569 5.48178 16.0498 4.89585 17.9005 4.64284C18.5119 4.55926 19.0439 5.03074 19.0833 5.64656C19.1226 6.26238 18.6541 6.78866 18.0437 6.87966C16.5163 7.1074 15.0375 7.60055 13.675 8.33959C11.9846 9.25657 10.5137 10.5302 9.36429 12.0721C8.21492 13.614 7.41457 15.3874 7.0187 17.2694C6.69965 18.7862 6.64948 20.3443 6.86754 21.8731C6.95467 22.484 6.58415 23.0834 5.98276 23.2216Z"
          fill={color}
        />
        <Path
          d="M28.8714 8.67919C29.252 8.19348 29.9574 8.10539 30.4144 8.52C31.6879 9.67525 32.7622 11.0362 33.5913 12.5484C34.5997 14.3877 35.2243 16.4126 35.4273 18.5003C35.6302 20.5881 35.4073 22.6954 34.772 24.6945C34.2497 26.3381 33.4575 27.8804 32.4304 29.2593C32.0618 29.7542 31.3526 29.8036 30.8856 29.4003C30.4186 28.997 30.3712 28.2939 30.7338 27.7946C31.5666 26.6478 32.2116 25.373 32.6423 24.0177C33.186 22.3068 33.3768 20.5033 33.2031 18.7165C33.0294 16.9298 32.4949 15.1968 31.6318 13.6227C30.9482 12.3757 30.0697 11.249 29.0316 10.284C28.5796 9.86389 28.4907 9.1649 28.8714 8.67919Z"
          fill={color}
        />
        <Path
          d="M21.5085 5.69664C21.5732 5.08297 22.1243 4.63386 22.7317 4.74261C24.1021 4.98796 25.4332 5.41683 26.689 6.01763C27.2457 6.28393 27.4309 6.97025 27.1252 7.50628C26.8195 8.04231 26.1388 8.22435 25.579 7.96483C24.5958 7.50909 23.5605 7.17552 22.4962 6.97161C21.8902 6.85549 21.4438 6.31031 21.5085 5.69664Z"
          fill={color}
        />
      </G>
      <G opacity="0.6">
        <Path
          d="M13.3849 27.1151C12.9783 27.5217 12.3145 27.5246 11.949 27.0806C10.5418 25.3705 9.69145 23.2598 9.52865 21.0343C9.36585 18.8087 9.90004 16.5967 11.0434 14.7001C11.3403 14.2076 11.9975 14.1139 12.459 14.457C12.9204 14.8001 13.0102 15.4499 12.7248 15.9492C11.8734 17.4389 11.4793 19.1556 11.6057 20.8823C11.732 22.6091 12.3717 24.2502 13.4308 25.6001C13.7858 26.0525 13.7915 26.7085 13.3849 27.1151Z"
          fill={color}
        />
        <Path
          d="M14.2081 12.6503C13.8503 12.2002 13.9226 11.5404 14.4053 11.2277C15.6773 10.4037 17.1127 9.85573 18.6185 9.62452C20.4683 9.34049 22.3602 9.54431 24.1071 10.2158C25.854 10.8873 27.3953 12.0032 28.5786 13.4532C29.5418 14.6334 30.2406 16.0018 30.6332 17.4656C30.7821 18.0211 30.3939 18.5595 29.8266 18.654C29.2594 18.7486 28.729 18.3627 28.5671 17.8108C28.2425 16.7037 27.699 15.6691 26.9651 14.7699C26.0111 13.6008 24.7684 12.7011 23.3599 12.1597C21.9514 11.6183 20.426 11.454 18.9346 11.683C17.7873 11.8591 16.6908 12.2633 15.7082 12.8678C15.2183 13.1692 14.566 13.1005 14.2081 12.6503Z"
          fill={color}
        />
        <Path
          d="M29.9243 21.0669C30.4973 21.1153 30.9278 21.6206 30.8242 22.1863C30.5367 23.7562 29.9031 25.2427 28.9697 26.5373C28.6333 27.0038 27.9707 27.0432 27.5389 26.6633C27.1072 26.2834 27.0711 25.6285 27.3965 25.1543C28.0477 24.2054 28.5035 23.136 28.7371 22.0091C28.8538 21.446 29.3512 21.0185 29.9243 21.0669Z"
          fill={color}
        />
      </G>
      <Circle
        cx="20.5"
        cy="20"
        r="5"
        fill={theme.colors.primaryContent}
        fillOpacity="0.95"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91992 36.6529C10.7812 30.9908 15.2647 27 20.5 27C25.5594 27 29.9167 30.7273 31.8837 36.0882C28.5612 38.5464 24.4503 40 20 40C15.9019 40 12.0917 38.7675 8.91992 36.6529Z"
        fill={theme.colors.primaryContent}
        fillOpacity="0.95"
      />
    </Svg>
  );
};
