import { FormikContextType } from 'formik';
import { StyleProp, ViewStyle } from 'react-native';
import _RNPhoneInput, {
  ReactNativePhoneInputProps,
} from 'react-native-phone-input';
import UntypedReactPhoneInput, {
  Props as ReactPhoneInputProps,
  State as ReactPhoneInputState,
} from 'react-phone-number-input';

import { TextInputV2Props } from './TextInput';

export type PhoneNumberData = {
  phoneNumber: string;
  // Why the typing here is optional for these
  // but required in the yup schema (yupMobileNumber)?
  countryIso?: string;
  countryPrefix?: string;
  isValid?: boolean;
};

// TODO this does not work with native version
// export type PhoneInputV2Props = Omit<
//   ReactPhoneInputProps<TextInputV2Props>,
//   'onChange' | 'value'
// > & {
//   onChangePhoneNumber: (data: PhoneNumberData) => void;
//   value?: PhoneNumberData;
//   style?: StyleProp<ViewStyle>;
// };

export type PhoneInputV2Props = Omit<
  TextInputV2Props,
  'onChangeText' | 'value'
> & {
  onChangePhoneNumber: (data: PhoneNumberData) => void;
  value?: PhoneNumberData;
};

export type WithoutOnChangePhoneNumber<P> = Omit<P, 'onChangePhoneNumber'>;

// TODO maybe this should use formikcontrolprops
export type PhoneInputForFormikProps =
  WithoutOnChangePhoneNumber<PhoneInputV2Props> & {
    formik?: FormikContextType<unknown>;
    setFieldValue?: (value: PhoneNumberData) => void;
    setFieldTouched?: (isTouched?: boolean, validate?: boolean) => void;
  };

// TODO Fix types for native
export type __DO_NOT_USE_YET_PhoneInputV2Props = Omit<
  // ReactNativePhoneInputProps<typeof TextInput>,
  ReactNativePhoneInputProps,
  'onChangePhoneNumber' | 'value' | 'style'
> & {
  onChangePhoneNumber: (data: PhoneNumberData) => void;
  value?: PhoneNumberData;
  label?: string;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

export const WebPhoneInput =
  UntypedReactPhoneInput as unknown as React.ComponentClass<
    ReactPhoneInputProps<TextInputV2Props>,
    ReactPhoneInputState<ReactPhoneInputProps<TextInputV2Props>>
  >;
