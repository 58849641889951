import * as Linking from 'expo-linking';
import { useContext, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ENV_EXPO_CONFIG } from '../../config/expo';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { isAndroidWeb, isIosWeb } from '../../utils/platformUtils';
import { redirectedFromNativeAppAtom } from './atoms/redirectedFromNativeAppAtom';

export function useOpenNativeAppMonitor() {
  const enabledNativeAppMonitor = useRecoilValue(redirectedFromNativeAppAtom);

  const { env } = useEnvConfig();
  const expoConfig = useMemo(() => ENV_EXPO_CONFIG[env], [env]);

  const { flags } = useContext(FeatureFlagsContext);

  const isEnabled =
    (isIosWeb() || isAndroidWeb()) &&
    flags.ENABLE_NATIVE_APP_ONBOARDING_HANDOFF &&
    enabledNativeAppMonitor;

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const url = `${expoConfig.bundleIdentifier}://`;

    Linking.openURL(url);
  }, [expoConfig.bundleIdentifier, isEnabled]);
}
