import { A, Text } from 'dripsy';

import { Box } from '../../ui/atoms/Box';

export const AutopayAgreementText = () => (
  <Box>
    <Text variant="caption">{t('Content.AutopaySettings.AgreementText1')}</Text>
    <Text variant="caption" sx={{ mt: '$16' }}>
      {t('Content.AutopaySettings.AgreementText2')}{' '}
      <A
        href={t('Link.DirectDebitTerms')}
        hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
        variant="link"
      >
        {t('Content.AutopaySettings.DirectDebitsServiceAgreement')}
      </A>{' '}
      {t('Content.AutopaySettings.AgreementText3')}
    </Text>
  </Box>
);
