import type { LDContext as LDReactContext } from 'launchdarkly-react-client-sdk';
import type { LDContext as LDNativeContext } from 'launchdarkly-react-native-client-sdk';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import * as React from 'react';

import { FeatureFlagsContext, LaunchDarklyContextProps } from './context';
import { FALLBACK_FLAGS, Flags } from './featureFlags';
import { useCustomLDClient } from './LaunchDarklyClient';

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [flags, setFlags] = useState<Flags>(FALLBACK_FLAGS);
  const [ldContext, setLdContext] = useState<LaunchDarklyContextProps>({});

  const { identify, variation, track, allFlags } = useCustomLDClient(setFlags);

  const identifyContext = useCallback(
    async (context: LDReactContext | LDNativeContext): Promise<void> => {
      await identify?.(context);
      setFlags(((await allFlags?.()) || FALLBACK_FLAGS) as Flags);
    },
    [identify, allFlags],
  );

  const value = useMemo(
    () => ({
      flags,
      identify: identifyContext,
      track,
      variation,
      ldContext,
      setLdContext,
    }),
    [flags, identifyContext, ldContext, track, variation],
  );

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
