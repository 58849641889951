import { useNavigation } from '@react-navigation/native';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Approval_Status_Enum,
  PaymentRequestFragment,
} from '../../generated/graphql';
import { useLoanAccountWithTransactions } from '../../HomeLoan/graphql/loanAccountQueries';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { BaseListRowProps } from '../../ui/molecules/ListRow';
import {
  TransferRow,
  TransferStatusPendingLabel,
  TransferStatusRow,
  UpcomingCard,
} from '../../ui/v2/UpcomingCard';
import { formatCurrency } from '../../utils/currencyHelpers';
import { joinListOfString } from '../../utils/stringHelpers';
import { getPaymentRequestResponse } from '../utils/getPaymentRequestResponse';
import { shouldShowApproveDeclineButtons } from '../utils/shouldShowApproveDeclineButtons';

const getStatusLabel = ({
  showApproveDeclineButtons,
  pendingApprovers,
}: {
  showApproveDeclineButtons: boolean;
  pendingApprovers: string[];
}) => {
  if (showApproveDeclineButtons || pendingApprovers.length === 0) {
    return t('Content.Approvals.ApprovalRequired');
  }

  if (pendingApprovers.length === 1) {
    return t('Content.Approvals.NeedsToApproveThisRequest', {
      approver: joinListOfString(pendingApprovers),
    });
  }

  return t('Content.Approvals.NeedToApproveThisRequest', {
    approvers: joinListOfString(pendingApprovers),
  });
};

type PendingWithdrawalListRowProps = Pick<BaseListRowProps, 'onPress'> & {
  overallApprovalStatus?: Approval_Status_Enum | null;
  myResponseStatus?: Approval_Status_Enum | null;
  amount: number | null | undefined;
  pendingApprovers?: string[];
  accountName?: string;
};

export const PendingWithdrawalListRow = ({
  overallApprovalStatus,
  myResponseStatus,
  amount,
  onPress,
  pendingApprovers = [],
  accountName,
}: PendingWithdrawalListRowProps) => {
  const label = t('Content.TransactionList.Withdrawal');
  const amountLabel = `-${formatCurrency(Math.abs(amount ?? 0), {
    withFractionOnRoundedAmount: true,
  })}`;

  const showApproveDeclineButtons = shouldShowApproveDeclineButtons({
    overallApprovalStatus:
      overallApprovalStatus ?? Approval_Status_Enum.Pending,
    myResponseStatus: myResponseStatus ?? Approval_Status_Enum.Pending,
  });

  const headingRow = useMemo(() => {
    switch (overallApprovalStatus) {
      case Approval_Status_Enum.Pending:
        return (
          <TransferRow
            label={label}
            amountLabel={amountLabel}
            caption={t('Content.TransactionList.Pending')}
            subCaption={accountName}
            onPress={showApproveDeclineButtons ? undefined : onPress}
          />
        );

      // In case the payment takes too long to process, or anything fails and a retry is expected, show processing
      default:
        return (
          <TransferRow
            label={label}
            amountLabel={amountLabel}
            caption={t('Content.Approvals.Processing')}
            subCaption={accountName}
            onPress={onPress}
          />
        );
    }
  }, [
    accountName,
    amountLabel,
    label,
    onPress,
    overallApprovalStatus,
    showApproveDeclineButtons,
  ]);

  const statusLabel = getStatusLabel({
    showApproveDeclineButtons,
    pendingApprovers,
  });

  const statusRow = useMemo(() => {
    switch (overallApprovalStatus) {
      case Approval_Status_Enum.Pending:
        return (
          <TransferStatusRow
            label={
              <TransferStatusPendingLabel
                showPendingIcon={!showApproveDeclineButtons}
                label={statusLabel}
              />
            }
            buttonTestID={TestID.PendingWithdrawal.ViewAndApproveButton}
            buttonLabel={
              showApproveDeclineButtons
                ? t('Content.Approvals.ViewAndApprove')
                : undefined
            }
            onPress={showApproveDeclineButtons ? onPress : undefined}
          />
        );
      default:
        return null;
    }
  }, [onPress, overallApprovalStatus, showApproveDeclineButtons, statusLabel]);

  return (
    <>
      {headingRow}
      {statusRow}
    </>
  );
};

type PendingWithdrawalsProps = {
  showAccountName?: boolean;
  loan?: ReturnType<typeof useLoanAccountWithTransactions>['data'];
  myIdentityProfileId?: string;
  pending_withdrawals: Array<PaymentRequestFragment>;
};

export function PendingWithdrawals({
  showAccountName,
  loan,
  myIdentityProfileId,
  pending_withdrawals,
}: PendingWithdrawalsProps) {
  const navigation = useNavigation();

  const propertyShortAddress =
    loan?.loan_application_security?.property?.address?.short_address_format;

  const accountName = showAccountName
    ? propertyShortAddress || loan?.settings?.name
    : undefined;

  const onPressPendingWithdrawal = (paymentRequestId: string) =>
    navigation.navigate(Screen.HOME_LOAN_MODAL, {
      screen: Screen.HOME_PENDING_TRANSACTION,
      params: {
        paymentRequestId,
      },
    });

  return (
    <Box overflow="visible" column>
      {(pending_withdrawals ?? []).map((item, index) => {
        const myResponse = getPaymentRequestResponse({
          paymentRequest: item,
          myIdentityProfileId,
        });

        const pendingApprovers = sortBy(
          item.withdrawal_request?.approval_request?.responses
            ?.filter(
              (response) =>
                response.status === Approval_Status_Enum.Pending &&
                response.identity_profile?.id !== myIdentityProfileId,
            )
            ?.map((response) => response.identity_profile?.first_name ?? '') ??
            [],
        );

        return (
          <UpcomingCard
            key={item.id}
            testID={TestID.HomeLoan.PendingWithdrawalsList}
            sx={{ mt: index > 0 ? '$8' : undefined }}
          >
            <PendingWithdrawalListRow
              overallApprovalStatus={
                item.withdrawal_request?.approval_request.status
              }
              myResponseStatus={myResponse?.status}
              amount={item.withdrawal_request?.amount}
              onPress={() => onPressPendingWithdrawal(item.id)}
              pendingApprovers={pendingApprovers}
              accountName={accountName}
            />
          </UpcomingCard>
        );
      })}
    </Box>
  );
}
