import * as React from 'react';

import {
  Approval_Status_Enum,
  ApprovalRequestFragment,
} from '../../generated/graphql';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';

const getLabelForResponseStatus = (status: Approval_Status_Enum) => {
  switch (status) {
    case Approval_Status_Enum.Approved:
      return t('Content.Approvals.Approved');
    case Approval_Status_Enum.Declined:
      return t('Content.Approvals.Declined');
    default:
      return '--';
  }
};

export const getApprovalStatusLabel = (
  approvalRequest: ApprovalRequestFragment,
) => {
  switch (approvalRequest.status) {
    case Approval_Status_Enum.Approved:
      return t('Content.Approvals.Approved');
    case Approval_Status_Enum.Declined:
      return t('Content.Approvals.Declined');
    case Approval_Status_Enum.Cancelled:
      return t('Content.Approvals.Cancelled');
    case Approval_Status_Enum.Pending:
      return t('Content.Approvals.PendingApproval');
    default:
      throw new Error(`Unknown approval status`);
  }
};

export const ApprovalStatusListGroup: React.FC<{
  loading?: boolean;
  approvalRequest?: ApprovalRequestFragment;
  expiryWarningText?: string;
}> = ({
  loading,
  approvalRequest,
  expiryWarningText = t('Content.Approvals.ExpiryWarning'),
}) => (
  <>
    <DataRowGroup>
      <DataRow
        loading={loading}
        label={t('Content.Approvals.Status')}
        caption={
          approvalRequest ? getApprovalStatusLabel(approvalRequest) : undefined
        }
      />
      {approvalRequest?.responses.map((response) => (
        <React.Fragment key={response.id}>
          <DataRowSeparator />
          <DataRow
            loading={loading}
            label={response.identity_profile?.full_name ?? ''}
            caption={getLabelForResponseStatus(response.status)}
          />
        </React.Fragment>
      )) ?? undefined}
    </DataRowGroup>
    <Box mt="s" opacity={loading ? 0 : undefined}>
      {approvalRequest?.status === Approval_Status_Enum.Pending ? (
        <StyledText variant="caption" color="secondaryContent">
          {expiryWarningText}
        </StyledText>
      ) : undefined}
    </Box>
  </>
);
