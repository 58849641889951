/**
 * This is the entry point for a co-borrower to join a loan application.
 * This component will:
 * - read the invite token from the URL
 * - check that it's not expired
 * - fetches information about the inviter and invitee using the token
 * as an auth header (works for anonymous users)
 * - renders errors if token is expired, used, revoked or a query fails
 * - stores the invite token in SecureStore
 * - if all good, enters the auth flow with the token as an argument
 * - after that JoinApplicationInvite context provider takes over the process
 */
import { A } from 'dripsy';
import { useEffect, useState } from 'react';

import { useAuth } from '../../Auth/hooks';
import { setInviteCode } from '../../Auth/inviteCodeStore';
import { AppStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { Link } from '../../ui/atoms/Link';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { CoupleIcon } from '../../ui/svgs/CoupleIcon';
import { useTheme } from '../../ui/theme';
import { useReferralDisclosure } from '../../utils/hooks/useReferralDisclosure';
import { isTokenExpiryDateInFuture } from '../../utils/tokenUtil';
import { JoinApplicationContent } from '../components/JoinApplicationContent';
import { useJoinApplicationDetailsQuery } from '../remoteData';

type Props = AppStackScreenProps<Screen.JOIN_APPLICATION_MODAL>;

export function JoinApplication({ route }: Props) {
  const { login, loading: authLoading } = useAuth();
  const [expiredToken, setExpiredToken] = useState(false);
  const shouldShowReferralDisclosure = useReferralDisclosure();

  useEffect(() => {
    if (route?.params?.token) {
      if (!isTokenExpiryDateInFuture(route.params.token)) {
        // Avoid making this query if we don't have a token with an exp date
        // in the future. It will create too much noise in Sentry
        setExpiredToken(true);
        return;
      }

      getLoanApplicationDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params?.token]);

  const {
    getLoanApplicationDetails,
    data,
    loading: applicationDetailsLoading,
    error,
  } = useJoinApplicationDetailsQuery(route?.params?.token || '');

  useEffect(() => {
    if (error) {
      captureException(
        'Fail to fetch join application modal',
        { token: route.params?.token?.slice(0, 4) },
        error,
      );
    }
  }, [error, route.params?.token]);

  const theme = useTheme();

  const onJoinApplication = async () => {
    if (data?.inviteCode && login) {
      await setInviteCode(data.inviteCode);
      await login(data.inviteCode);
    }
  };

  const loading = applicationDetailsLoading || authLoading;

  if (loading) {
    return (
      <ScreenContainer>
        <Box flex={1} alignItems="center" justifyContent="center">
          <Spinner size="large" />
        </Box>
      </ScreenContainer>
    );
  }

  if (error) {
    // When the initial query to fetch information about this invitation fails
    // we get in this block
    return (
      <JoinApplicationContent
        title={t('Content.JoinApplication.InvalidInvitation.Title')}
        message={t('Content.JoinApplication.InvalidInvitation.Description')}
      />
    );
  }

  // This block needs to come before the check for data == null, otherwise
  // we'll never get here
  if (expiredToken || data?.isExpired) {
    return (
      <JoinApplicationContent
        title={t('Content.JoinApplication.ExpiredInvitation.Title')}
        message={t('Content.JoinApplication.ExpiredInvitation.Description')}
      />
    );
  }

  if (data?.isRevoked) {
    return (
      <JoinApplicationContent
        title={t('Content.JoinApplication.RevokedInvitation.Title')}
        message={t('Content.JoinApplication.RevokedInvitation.Description')}
      />
    );
  }

  if (data?.isUsed) {
    return (
      <JoinApplicationContent
        title={t('Content.JoinApplication.UsedInvitation.Title')}
        message={t('Content.JoinApplication.UsedInvitation.Description')}
      />
    );
  }

  // This needs to come after the check for "expiredToken"
  if (data == null) {
    return (
      <JoinApplicationContent
        title={t('Content.JoinApplication.InvalidInvitation.Title')}
        message={t('Content.JoinApplication.InvalidInvitation.Description')}
      />
    );
  }

  const lmiPrivacyPolicyInLine = data.isLmiEnabled
    ? t('Content.Common.Disclaimer.PrivacyPolicyLmiInLine')
    : '';

  return (
    <JoinApplicationContent
      onJoinApplication={onJoinApplication}
      onJoinApplicationLoading={loading}
      title={t('Content.JoinApplication.Title')}
      message={t('Content.JoinApplication.Message', {
        invitee: data?.invitee,
        inviter: data?.inviter,
      })}
    >
      <Box
        alignItems="center"
        alignSelf="center"
        my="s"
        px="m"
        contentContainer
      >
        <CoupleIcon size={theme.sizes.l} />
        <StyledText variant="caption" pt="m" textAlign="center">
          {shouldShowReferralDisclosure ? (
            <StyledText variant="caption">
              {t('Content.Common.Disclaimer.ReferralDisclosure')}{' '}
              <A
                href={t('Link.ReferralArticle')}
                hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
                variant="link"
                sx={{ py: '$8' }}
              >
                {t('Content.Common.Disclaimer.ReferralDisclosureInlineLink')}
                {'\n'}
              </A>
            </StyledText>
          ) : null}
          <StyledText variant="caption">
            {t('Content.Common.Disclaimer.PrivacyPolicy', {
              lmiPrivacyPolicyInLine,
            })}
          </StyledText>
          <Link variant="caption" href={t('Link.PrivacyPolicy')}>
            {t('Content.Common.Disclaimer.PrivacyPolicyInlineLink')}
          </Link>
        </StyledText>
        <Separator spacer />
      </Box>
    </JoinApplicationContent>
  );
}
