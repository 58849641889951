import { Formik, FormikProps } from 'formik';
import { FC, Fragment, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import { TestID } from '../../../testID/constants';
import { Form, FormTextInput } from '../../components/form/FormikInputs';
import { SubmitButton } from '../../components/form/SubmitButton';
import { TranslateKeys } from '../../localization/constants';
import { Separator } from '../../ui/atoms/Separator';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { makeGetErrorMessage } from '../../utils/makeGetErrorMessage';
import {
  yupExternalAccountBsb,
  yupExternalAccountName,
  yupExternalAccountNumber,
} from '../../utils/yup';
import { newExternalAccountAtom } from '../atoms/newExternalAccountAtom';

const ACCOUNT_INIT_VALS = {
  account_name: '',
  account_bsb: '',
  account_number: '',
};

const ACCOUNT_TRANSLATION: Record<ACCOUNT_KEYS, TranslateKeys> = {
  account_name: 'Content.Withdraw.AccountName',
  account_bsb: 'Content.Withdraw.BSB',
  account_number: 'Content.Withdraw.AccountNumber',
};

const ACCOUNT_TEST_IDS: Record<ACCOUNT_KEYS, string> = {
  account_name: TestID.AddExternalAccount.AccountName,
  account_bsb: TestID.AddExternalAccount.Bsb,
  account_number: TestID.AddExternalAccount.AccountNumber,
};

const accountValidationSchema = yup.object({
  account_name: yupExternalAccountName,
  account_bsb: yupExternalAccountBsb,
  account_number: yupExternalAccountNumber,
});

export type AddExternalAccountFormValues = yup.Asserts<
  typeof accountValidationSchema
>;
type ACCOUNT_KEYS = keyof AddExternalAccountFormValues;

type Props = {
  onSubmit: (values: AddExternalAccountFormValues) => void;
  isSubmitting?: boolean;
};

export const AddExternalAccount: FC<Props> = ({ onSubmit, isSubmitting }) => {
  const setNewExternalAccount = useSetRecoilState(newExternalAccountAtom);

  const handleSubmit = useCallback(
    async (values: AddExternalAccountFormValues) => {
      setNewExternalAccount(values);
      onSubmit(values);
    },
    [onSubmit, setNewExternalAccount],
  );

  const makeOnSubmitPress =
    ({ isValid, submitForm }: FormikProps<AddExternalAccountFormValues>) =>
    () => {
      if (!isValid) {
        return;
      }
      submitForm();
    };

  return (
    <Formik
      initialValues={ACCOUNT_INIT_VALS}
      validationSchema={accountValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form>
          {Object.keys(ACCOUNT_TRANSLATION).map((field) => (
            <Fragment key={field}>
              <Separator spacer />
              <ListRowGroup
                mx={0}
                headerText={t(ACCOUNT_TRANSLATION[field as ACCOUNT_KEYS])}
                footerErrorMessage={makeGetErrorMessage(formProps)(
                  field as ACCOUNT_KEYS,
                )}
                uppercaseHeader
              >
                <FormTextInput
                  testID={ACCOUNT_TEST_IDS[field as ACCOUNT_KEYS]}
                  noBorder
                  name={field}
                  placeholder={t('Content.Common.Placeholder.Required')}
                />
              </ListRowGroup>
            </Fragment>
          ))}
          <Separator spacer />
          <SubmitButton
            testID={TestID.AddExternalAccount.SubmitButton}
            label={t('Content.Common.ButtonLabel.Done')}
            onPress={makeOnSubmitPress(formProps)}
            disabled={isSubmitting}
            showSpinner={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
