import { gql } from '@apollo/client';
import * as React from 'react';

import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  InstitutionAccountDetailsFragment,
  InstitutionAccountType,
  useGetInstitutionDetailQuery,
} from '../../generated/graphql';
import { BankAccountDetailsScreenParams } from '../../navigation/types/navTypes';
import { Avatar } from '../../ui/atoms/Avatar';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { isLast } from '../../utils/arrayHelpers';
import { ensureEnumType } from '../../utils/ensureEnumType';
import { ACCOUNT_LABEL_LIST, getFieldConfigList } from '../constants';

type Props = BankAccountDetailsScreenParams;

export const QUERY = gql`
  query GetInstitutionDetail($institutionAccountId: String!) {
    getInstitutionAccount(institutionAccountId: $institutionAccountId) {
      ...InstitutionAccountDetails
    }
  }

  fragment InstitutionAccountDetails on InstitutionAccount {
    id
    institutionName
    accountName
    accountNumber
    accountType
    container
    interestRate
    interestRateType
    termInMonths
    repaymentType
    balance {
      currency
      amount
    }
    minimumAmountDue {
      amount
      currency
    }
    totalCreditLine {
      currency
      amount
    }
    institution {
      id
      favicon
    }
  }
`;

export const BankAccountDetails: React.FC<Props> = ({
  account,
  institutionFaviconUri,
}) => {
  const institutionAccountId = account.id;
  const { data, loading } = useGetInstitutionDetailQuery({
    variables: {
      institutionAccountId: String(institutionAccountId),
    },
    skip: institutionAccountId == null,
    context: {
      sentryContext: {
        institutionAccountId,
      },
    },
  });
  const accountDetails = data?.getInstitutionAccount;

  return (
    <ModalScreenContainer bg="bg" scrollable>
      <NavHeaderSpacer />
      <BankAccountDetailHeader
        institutionFaviconUri={institutionFaviconUri}
        accountName={account?.accountName || '--'}
        accountType={account?.accountType}
      />
      <BankAccountDetailsContent
        loading={loading}
        accountDetails={accountDetails}
      />
    </ModalScreenContainer>
  );
};

function BankAccountDetailHeader({
  accountName,
  accountType,
  institutionFaviconUri,
}: {
  accountName: string;
  accountType?: string | null;
  institutionFaviconUri: string;
}) {
  const accountTypeTitle = accountType
    ? ACCOUNT_LABEL_LIST[ensureEnumType(InstitutionAccountType, accountType)]
    : '--';
  return (
    <Box row m="m" alignItems="center">
      <Box
        width={60}
        height={60}
        bg="shapeBg"
        justifyContent="center"
        alignItems="center"
        borderRadius="field"
        mr="l"
      >
        <Avatar size="l" url={institutionFaviconUri} square />
      </Box>
      <Box>
        <StyledText variant="headerSmall">{accountName}</StyledText>
        <StyledText variant="caption" color="primaryContent">
          {accountTypeTitle}
        </StyledText>
      </Box>
    </Box>
  );
}

function BankAccountDetailsContent({
  loading,
  accountDetails,
}: {
  loading: boolean;
  accountDetails?: InstitutionAccountDetailsFragment | null;
}) {
  if (loading) {
    return <LoadingState />;
  }
  if (!accountDetails) {
    return <EmptyState title="No Account Detail" />;
  }

  const { container } = accountDetails;

  const fieldConfigList = getFieldConfigList(container);
  const fieldKeys = Object.keys(fieldConfigList) as Array<
    keyof typeof fieldConfigList
  >;

  return (
    <ListRowGroup>
      {fieldKeys.map((fieldKey, index) => {
        const config = fieldConfigList[fieldKey];

        if (config == null) {
          return null;
        }
        const datum = accountDetails[fieldKey as keyof typeof accountDetails];

        // FIXME: Need to type this better. Right now Typescript infer `formatValue` param types as `undefined`. Might need to do other approach
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = config.formatValue(datum as any);
        return (
          <ListRow
            key={config.label}
            label={config.label}
            placeholder={value}
            last={isLast(fieldKeys, index)}
          />
        );
      })}
    </ListRowGroup>
  );
}
