import { SxProp, View } from 'dripsy';
import { useState } from 'react';
import Animated from 'react-native-reanimated';

import { TestID } from '../../../testID/constants';
import { StyledImage } from '../../ui/atoms/StyledImage';
import { StyledText } from '../../ui/atoms/StyledText';
import { useCardScaleAnimatedStyle } from '../../ui/utils/useCardScaleAnimatedStyle';

type Props = {
  title?: string;
  caption?: string;
  containerSx?: SxProp;
  croppedImage?: boolean;
  isHover?: boolean;
};

const CroppedImage = {
  source: require('../../../assets/whiteHouseCropped-824x610.png'),
  width: 824,
  height: 610,
};

const NonCroppedImage = {
  source: require('../../../assets/whiteHouse-652x421.png'),
  width: 652,
  height: 421,
};

export function YourApplicationHeader({
  title = t('Content.Home.YourApplication'),
  caption = '',
  containerSx,
  croppedImage = true,
  isHover = false,
}: Props) {
  const [resizedImageWidth, setResizedImageWidth] = useState<number | null>(
    null,
  );

  const ImageToUse = croppedImage ? CroppedImage : NonCroppedImage;
  const imageHeightByWidthRatio = ImageToUse.height / ImageToUse.width;
  const resizedImageHeight = Math.ceil(
    (resizedImageWidth ?? 0) * imageHeightByWidthRatio,
  );

  const animatedStyle = useCardScaleAnimatedStyle(isHover);

  return (
    <View
      onLayout={(e) => setResizedImageWidth(e.nativeEvent.layout?.width ?? 0)}
      sx={{
        width: '100%',
        overflow: 'hidden',
        ...containerSx,
      }}
    >
      {resizedImageWidth != null ? (
        <Animated.View style={animatedStyle}>
          <StyledImage
            style={{
              height: resizedImageHeight,
              width: resizedImageWidth,
            }}
            contentFit="contain"
            source={ImageToUse.source}
            aria-hidden
            testID={TestID.ApplyCard.LoanApplicationHeader}
          />
        </Animated.View>
      ) : null}
      <View
        sx={{
          p: '$16',
        }}
      >
        <StyledText
          variant="body"
          color="secondaryContent"
          fontWeight="semiBold"
        >
          {caption.toUpperCase()}
        </StyledText>
        <StyledText variant="cardTitle">{title}</StyledText>
      </View>
    </View>
  );
}
