import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const HomeIcon: React.FC<SvgIconProps> = ({ color, size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 28 24" fill="none" {...props}>
    <Path
      d="M27.079 10.125L15.5.609C15.126.281 14.376 0 13.813 0c-.516 0-1.266.281-1.64.61L.593 10.124a.865.865 0 00-.234.563c0 .14.047.374.14.468l.516.61c.094.14.375.234.563.234.14 0 .375-.047.468-.14L3.36 10.78V13.5h-.047v9.75c0 .422.328.75.75.75H23.61c.375 0 .75-.328.75-.75V10.781l1.266 1.078a.833.833 0 00.468.188c.188 0 .47-.14.61-.281l.468-.61a.833.833 0 00.188-.469c0-.187-.14-.421-.281-.562zm-4.969.375h-.047v11.25H16.86V15c0-.797-.703-1.5-1.5-1.5h-3c-.844 0-1.5.703-1.5 1.5v6.75H5.563V9.14h.047v-.187l8.25-6.797 8.25 6.797V10.5z"
      fill={color}
    />
  </Svg>
);
