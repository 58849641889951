import { Avatar } from '../atoms/Avatar';
import { Box, BoxProps } from '../atoms/Box';
import { Button } from '../atoms/Button';
import { Card, Props as CardProps } from '../atoms/Card';
import { Separator } from '../atoms/Separator';
import {
  IconFamilyName,
  Props as StyledIconProps,
  StyledIcon,
} from '../atoms/StyledIcon';
import { StyledText } from '../atoms/StyledText';
import { Color, Size } from '../types';

type RightListRowProps = {
  rightIconName?: string;
  rightIconFamily?: IconFamilyName;
  rightIconColor?: Color;
  rightIconSize?: Size;
  rightIconTestID?: string;
  buttonLabel?: string;
  buttonLoading?: boolean;
  buttonTestID?: string;
  onButtonPress?: () => void;
  disableButton?: boolean;
  showArrow?: boolean;
  arrowTestId?: string;
};

const ArrowIconRight: StyledIconProps = {
  name: 'chevronRight',
  family: 'svg',
  size: 's',
  color: 'secondaryContent',
};

function RightListRow({
  rightIconFamily,
  rightIconName,
  buttonLabel,
  onButtonPress,
  disableButton,
  rightIconColor,
  rightIconSize,
  rightIconTestID,
  buttonLoading,
  buttonTestID,
  showArrow,
  arrowTestId,
}: RightListRowProps) {
  if (!showArrow && !rightIconName && !buttonLabel) {
    return null;
  }

  return (
    <Box flexDirection="row" alignItems="center" ml="m">
      {rightIconName ? (
        <StyledIcon
          name={rightIconName}
          family={rightIconFamily}
          mr={buttonLabel || showArrow ? 'm' : 0}
          color={rightIconColor}
          size={rightIconSize}
          testID={rightIconTestID}
        />
      ) : null}
      {buttonLabel ? (
        <Button
          variant="pill"
          label={buttonLabel}
          onPress={onButtonPress}
          disabled={onButtonPress == null || disableButton || buttonLoading}
          showSpinner={buttonLoading}
          testID={buttonTestID}
        />
      ) : null}
      {showArrow ? (
        <StyledIcon {...ArrowIconRight} testID={arrowTestId} />
      ) : null}
    </Box>
  );
}

type LeftListRowProps = {
  leftIconName?: string;
  leftIconFamily?: IconFamilyName;
  leftIconColor?: Color;
  leftIconRoundBg?: boolean;
  leftIconSize?: Size;
  imageUrl?: string;
};

function LeftListRowWrapper(props: BoxProps) {
  return <Box mr="m" {...props} />;
}
function LeftListRow({
  leftIconFamily,
  leftIconName,
  leftIconColor,
  leftIconRoundBg,
  leftIconSize,
  imageUrl,
}: LeftListRowProps) {
  if (leftIconName) {
    return (
      <LeftListRowWrapper>
        <StyledIcon
          name={leftIconName}
          family={leftIconFamily}
          color={leftIconColor}
          roundBg={leftIconRoundBg}
          size={leftIconSize || 'l'}
        />
      </LeftListRowWrapper>
    );
  }
  if (imageUrl) {
    return (
      <LeftListRowWrapper>
        <Box borderRadius="field" p="s" bg="shapeBg" bordered>
          <Avatar url={imageUrl} size="s" square bg="transparent" />
        </Box>
      </LeftListRowWrapper>
    );
  }
  return null;
}

export type NewListRowProps = Omit<CardProps, 'onPress'> &
  RightListRowProps &
  LeftListRowProps & {
    title: string;
    titleColor?: Color;
    caption?: string;
    captionColor?: Color;
    subcaption?: string;
    subcaptionColor?: Color;
    footer?: string | JSX.Element;
    onRowPress?: CardProps['onPress'];
    strikethroughTexts?: boolean;
  };

/** TODO: consider renaming this component */
export function NewListRow({
  title,
  caption,
  subcaption,
  footer,
  rightIconFamily,
  rightIconName,
  rightIconSize,
  rightIconColor,
  leftIconFamily,
  leftIconName,
  leftIconSize,
  leftIconColor,
  leftIconRoundBg,
  buttonLabel,
  buttonLoading,
  disableButton,
  testID,
  disabled: disabledProp,
  rightIconTestID,
  buttonTestID,
  imageUrl,
  onButtonPress,
  onRowPress,
  showArrow,
  arrowTestId,
  strikethroughTexts,
  titleColor = 'primaryContent',
  captionColor = 'primaryContent',
  subcaptionColor = 'secondaryContent',
  ...otherProps
}: NewListRowProps) {
  const textDecorationLine = strikethroughTexts ? 'line-through' : undefined;

  return (
    <Card
      justifyContent="space-between"
      onPress={onRowPress}
      p="m"
      my="s"
      shadowed={false}
      testID={testID}
      {...otherProps}
    >
      <Box flexDirection="row" alignItems="center" mb={footer ? 's' : 0}>
        <LeftListRow
          leftIconName={leftIconName}
          leftIconFamily={leftIconFamily}
          leftIconColor={leftIconColor}
          leftIconRoundBg={leftIconRoundBg}
          leftIconSize={leftIconSize}
          imageUrl={imageUrl}
        />
        <Box flex={1}>
          <StyledText
            fontWeight="semiBold"
            testID={testID ? `${testID}-title` : undefined}
            textDecorationLine={textDecorationLine}
            color={titleColor}
          >
            {title}
          </StyledText>
          {caption ? (
            <StyledText
              variant="caption"
              color={captionColor}
              testID={testID ? `${testID}-caption` : undefined}
              textDecorationLine={textDecorationLine}
            >
              {caption}
            </StyledText>
          ) : null}
          {subcaption ? (
            <StyledText
              variant="caption"
              testID={testID ? `${testID}-subcaption` : undefined}
              textDecorationLine={textDecorationLine}
              color={subcaptionColor}
            >
              {subcaption}
            </StyledText>
          ) : null}
        </Box>
        <RightListRow
          rightIconFamily={rightIconFamily}
          rightIconName={rightIconName}
          buttonLabel={buttonLabel}
          buttonLoading={buttonLoading}
          onButtonPress={onButtonPress}
          buttonTestID={buttonTestID}
          disableButton={disableButton}
          rightIconColor={rightIconColor}
          rightIconSize={rightIconSize}
          rightIconTestID={rightIconTestID}
          showArrow={showArrow}
          arrowTestId={arrowTestId}
        />
      </Box>
      {footer && typeof footer === 'string' ? (
        <>
          <Separator mx="xs" my="s" />
          <StyledText
            variant="caption"
            testID={testID ? `${testID}-footerText` : undefined}
          >
            {footer}
          </StyledText>
        </>
      ) : (
        footer
      )}
    </Card>
  );
}
