import { useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { useHideBackButton } from '../../utils/hooks/useHeaderButton';
import { GreenCheckAnimation } from '../components/GreenCheckAnimation';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_VOI_COMPLETED>;

function VOICompletedBase({ navigation, route }: Props) {
  const { loanApplicationId } = route.params || {};
  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  useHideBackButton(navigation);

  const navigateToLoanApplicationSubmit = useCallback(() => {
    navigateToNextLoanApplicationScreen({
      currentSection: LoanApplicationSection.VOICompleted,
    });
  }, [navigateToNextLoanApplicationScreen]);

  return (
    <LoanApplicationScreenContainer containerStyle={styles.root} px="l">
      <NavHeaderSpacer />
      <GreenCheckAnimation />
      <StyledText variant="header" mt="l" textAlign="center">
        {t('Content.FinaliseYourApplication.VOICompleted.Header')}
      </StyledText>
      <StyledText my="s" mb="m" textAlign="center">
        {t('Content.FinaliseYourApplication.VOICompleted.Caption')}
      </StyledText>
      <Button
        label="Continue"
        testID={TestID.CompletedLoanApplication.BackToDashboardButton}
        width="100%"
        onPress={navigateToLoanApplicationSubmit}
      />
    </LoanApplicationScreenContainer>
  );
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const VOICompleted = withAuthenticationRequired(VOICompletedBase);
