import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  GetLoanApplicationTasks_LoanApplicationTaskItemFragment,
  Income_Type_Enum,
  IncomeSupportingDocumentUpload_IncomeDetailFragmentDoc,
  Liability_Type_Enum,
  Loan_Application_Task_Status,
  Loan_Application_Task_Type,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { NewListRow, NewListRowProps } from '../../ui/molecules/NewListRow';
import { SVG_ICON_LIST } from '../../ui/svgs/svgIconList';
import { Color } from '../../ui/types';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { joinListOfString, makeTestId } from '../../utils/stringHelpers';
import { assertUnreachable } from '../../utils/typesHelpers';
import { LiabilitySupportingDocumentUploadLiabilityDetailFragment } from '../screens/LiabilitySupportingDocumentUpload';
import {
  onfidoConsentGrantedEvents,
  OnfidoEventOrigin,
} from '../utils/idValidationEventEmitter';

export const LoanApplicationTaskItemFragment = gql`
  fragment GetLoanApplicationTasks_LoanApplicationTaskItem on loan_application_task {
    id
    type
    status
    income_id
    income {
      ...IncomeSupportingDocumentUpload_IncomeDetail
    }
    applicant_id
    applicant {
      id
      latest_full_name
      is_current_logged_in_applicant
      user_identity_profile {
        id
        has_active_id_validation_restart_request
      }
    }
    loan_application_security_id
    loan_application_security {
      id
      property {
        id
        address {
          id
          short_address_format
        }
      }
    }
    merged_liability_id
    merged_liability {
      ...LiabilitySupportingDocumentUpload_LiabilityDetailFragment
    }
  }

  ${IncomeSupportingDocumentUpload_IncomeDetailFragmentDoc}
  ${LiabilitySupportingDocumentUploadLiabilityDetailFragment}
`;

export type LoanApplicationTaskData =
  GetLoanApplicationTasks_LoanApplicationTaskItemFragment;

export type LoanApplicationTaskItemProps = {
  loanApplicationId: string;
  taskData: LoanApplicationTaskData;
  currentLoggedInApplicantIDVLoading?: boolean;

  enableEvidenceScreenUplift: boolean;
  isLast?: boolean;
  /**
   * Why we don't get the screen name with useRoute ?
   * To enforce this component usage inside a screen,
   * avoiding null value for screen name in GTM events.
   */
  parentScreenName: Screen;
};

type ErrorStyle = {
  subcaptionColor: Color;
};

const GreenTickIcon: Omit<NewListRowProps, 'title'> = {
  rightIconColor: 'success',
  rightIconName: 'tickCircle',
  rightIconFamily: 'svg',
  rightIconSize: '2s',
};

function getIDVTaskItemProps({
  taskData,
  currentLoggedInApplicantIDVLoading,
}: Pick<LoanApplicationTaskItemProps, 'currentLoggedInApplicantIDVLoading'> & {
  taskData: LoanApplicationTaskData;
}) {
  const { status, applicant } = taskData;

  const errorStyle: ErrorStyle = {
    subcaptionColor: 'error',
  };

  const isProvided = status === Loan_Application_Task_Status.Provided;
  if (isProvided) {
    return {
      ...GreenTickIcon,
      rightIconTestID: `${TestID.LoanApplicationTaskItem.IDVSuccessIcon}-${applicant?.latest_full_name}`,
      subcaption: t(
        'Content.LoanApplicationTasks.TaskItem.IDV.CompletedSubCaption',
      ),
    };
  }
  if (applicant?.is_current_logged_in_applicant) {
    const hasActiveIdValidationRestartRequest =
      applicant?.user_identity_profile
        ?.has_active_id_validation_restart_request;

    return {
      buttonLabel: t('Content.LoanApplicationTasks.TaskItem.IDV.ButtonLabel'),
      buttonLoading: currentLoggedInApplicantIDVLoading,
      buttonTestID: `${TestID.LoanApplicationTaskItem.IDVButton}-${applicant.latest_full_name}`,
      ...(hasActiveIdValidationRestartRequest
        ? {
            subcaption: t(
              'Content.LoanApplicationTasks.TaskItem.IDV.IncompleteWithRestartCurrentLoggedInApplicantSubCaption',
            ),
            ...errorStyle,
          }
        : {
            subcaption: t(
              'Content.LoanApplicationTasks.TaskItem.IDV.IncompleteCurrentLoggedInApplicantSubCaption',
            ),
          }),
    };
  }
  return {
    subcaption: t(
      'Content.LoanApplicationTasks.TaskItem.IDV.IncompleteCoBorrowerSubCaption',
      {
        coBorrowerFullName: applicant?.latest_full_name,
      },
    ),
  };
}

export const LIABILITIES_ICON_NAME_BY_TYPE: Partial<
  Record<Liability_Type_Enum, keyof typeof SVG_ICON_LIST>
> = {
  HOME_LOAN: 'accountClosureHomeLoan',

  PERSONAL: 'accountClosurePersonalLoan',
  MARGIN_OR_INVESTMENT_LOAN: 'accountClosurePersonalLoan',
  PERSONAL_OVERDRAFT: 'accountClosurePersonalLoan',
  BNPL: 'accountClosurePersonalLoan',
  NOVATED_LEASE: 'accountClosurePersonalLoan',
  HECS_HELP: 'accountClosurePersonalLoan',
  OTHER: 'accountClosurePersonalLoan',

  CREDIT_CARD: 'accountClosureCreditCard',
  STORE_CARD: 'accountClosureCreditCard',
  LINE_OF_CREDIT: 'accountClosureCreditCard',
  CHARGE_CARD: 'accountClosureCreditCard',
};

function getLiabilityTaskItemTitle(liabilityType: Liability_Type_Enum) {
  switch (liabilityType) {
    case Liability_Type_Enum.CreditCard:
      return t('Content.Common.LiabilityType.CREDIT_CARD');
    case Liability_Type_Enum.HomeLoan:
      return t('Content.Common.LiabilityType.HOME_LOAN');
    case Liability_Type_Enum.Personal:
      return t('Content.Common.LiabilityType.PERSONAL');
    case Liability_Type_Enum.MarginOrInvestmentLoan:
      return t('Content.Common.LiabilityType.MARGIN_OR_INVESTMENT_LOAN');
    case Liability_Type_Enum.PersonalOverdraft:
      return t('Content.Common.LiabilityType.PERSONAL_OVERDRAFT');
    case Liability_Type_Enum.Bnpl:
      return t('Content.Common.LiabilityType.BNPL');
    case Liability_Type_Enum.NovatedLease:
      return t('Content.Common.LiabilityType.NOVATED_LEASE');
    case Liability_Type_Enum.HecsHelp:
      return t('Content.Common.LiabilityType.HECS_HELP');
    case Liability_Type_Enum.Other:
      return t('Content.Common.LiabilityType.OTHER');
    case Liability_Type_Enum.StoreCard:
      return t('Content.Common.LiabilityType.STORE_CARD');
    case Liability_Type_Enum.LineOfCredit:
      return t('Content.Common.LiabilityType.LINE_OF_CREDIT');
    case Liability_Type_Enum.ChargeCard:
      return t('Content.Common.LiabilityType.CHARGE_CARD');
    default:
      return t('Content.Common.LiabilityType.OTHER');
  }
}

export function LoanApplicationTaskItem(props: LoanApplicationTaskItemProps) {
  const {
    loanApplicationId,
    isLast,
    currentLoggedInApplicantIDVLoading,
    enableEvidenceScreenUplift,
    taskData,
    parentScreenName,
  } = props;

  const navigation = useNavigation();

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const {
    type,
    applicant,
    income,
    status,
    loan_application_security: loanApplicationSecurity,
    merged_liability: mergedLiability,
  } = taskData;

  const commonStyles: Partial<React.ComponentProps<typeof NewListRow>> = {
    mt: 0,
    mb: isLast ? 0 : 's',
    bg: 'shapeBg',
    shadowed: false,
  } as const;

  if (type === Loan_Application_Task_Type.Idv && applicant) {
    const idvTaskItemProps = getIDVTaskItemProps({
      taskData,
      currentLoggedInApplicantIDVLoading,
    });
    const onPress = async () => {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              parentScreenName,
              FieldInteractionKey.IdvTask,
            ),
        },
      });
      navigation.navigate(Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL, {
        loanApplicationId,
        consentGrantedEvent:
          onfidoConsentGrantedEvents[OnfidoEventOrigin.FinaliseLoanApplication],
      });
    };

    return (
      <NewListRow
        {...idvTaskItemProps}
        title={t('Content.LoanApplicationTasks.TaskItem.IDV.Title')}
        caption={applicant.latest_full_name || '--'}
        onButtonPress={onPress}
        leftIconFamily="svg"
        leftIconName="idv"
        {...commonStyles}
      />
    );
  }

  if (type === Loan_Application_Task_Type.IncomeSupportingDocument && income) {
    const onOpenPress = () => {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              parentScreenName,
              FieldInteractionKey.IncomeTask,
            ),
        },
      });
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.INCOME_SUPPORTING_DOCUMENT_MODAL,
        params: {
          incomeId: income.id,
          loanApplicationId,
        },
      });
    };
    const incomeOwners =
      income.income_owners
        .map(({ applicant: incomeOwner }) => incomeOwner.latest_full_name)
        .filter(isNotNullOrUndefined) || [];
    const rightElement =
      status === Loan_Application_Task_Status.Provided
        ? {
            ...GreenTickIcon,
            rightIconTestID: `${
              TestID.LoanApplicationTaskItem.UploadIncomeDocumentSuccessIcon
            }-${income.income_type}-${incomeOwners.join('-')}`,
            // When the task status is provided,
            // we don't show the button in the right side of the row,
            // but we allow the user to upload more docs
            // by pressing the row itself.
            onRowPress: onOpenPress,
          }
        : {
            buttonLabel: t('Content.Common.ButtonLabel.Upload'),
            buttonTestID: `${
              TestID.LoanApplicationTaskItem.UploadIncomeDocumentButton
            }-${income.income_type}-${incomeOwners.join('-')}`,
          };

    const owners = joinListOfString(incomeOwners);
    const employer = income.employment_income?.employer || '--';
    const rentalAddress =
      income.rental_income?.address?.short_address_format || '--';

    // TODO: revisit these title and caption mapping on evidence screen uplift FF clean up
    //       consider to not use template string for i18n fn.
    const title = t(
      `Content.LoanApplicationTasks.TaskItem.INCOME_SUPPORTING_DOCUMENT.${income.income_type}.Title`,
    );
    const getTextProps = () => {
      if (enableEvidenceScreenUplift) {
        switch (income.income_type) {
          case Income_Type_Enum.Employment:
            return {
              title,
              caption: employer,
              subcaption: owners,
            };
          case Income_Type_Enum.Rental:
            return {
              title,
              caption: rentalAddress,
              subcaption: owners,
            };
          case Income_Type_Enum.GovernmentPayments:
          case Income_Type_Enum.SavingsInterest:
            return {
              title,
              subcaption: owners,
            };
          case Income_Type_Enum.ShareDividends:
            return {
              title: t(
                'Content.LoanApplicationTasks.TaskItem.INCOME_SUPPORTING_DOCUMENT.SHARE_DIVIDENDS.TitleV2',
              ),
              subcaption: owners,
            };
          default:
            assertUnreachable(income.income_type);
        }
      }

      return {
        title,
        caption:
          income.income_type === Income_Type_Enum.Rental
            ? rentalAddress
            : owners,
        subcaption: t(
          `Content.LoanApplicationTasks.TaskItem.INCOME_SUPPORTING_DOCUMENT.${income.income_type}.SubCaption`,
          {
            employer,
          },
        ),
      };
    };

    const getLeftIconElementProps: () => Pick<
      NewListRowProps,
      'leftIconFamily' | 'leftIconName'
    > = () => {
      switch (income.income_type) {
        case Income_Type_Enum.Employment: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'financialEmploymentIncome',
          };
        }
        case Income_Type_Enum.Rental: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'financialRentalIncome',
          };
        }
        case Income_Type_Enum.GovernmentPayments:
        case Income_Type_Enum.SavingsInterest:
        case Income_Type_Enum.ShareDividends: {
          if (enableEvidenceScreenUplift) {
            return {
              leftIconFamily: 'svg',
              leftIconName: 'financialOtherIncome',
            };
          }
          return {
            leftIconFamily: 'svg',
            leftIconName: 'financialEmploymentIncome',
          };
        }
        default: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'financialEmploymentIncome',
          };
        }
      }
    };
    return (
      <NewListRow
        {...getTextProps()}
        onButtonPress={onOpenPress}
        {...rightElement}
        {...commonStyles}
        {...getLeftIconElementProps()}
      />
    );
  }

  if (
    type === Loan_Application_Task_Type.SecuritySupportingDocument &&
    loanApplicationSecurity != null
  ) {
    // Right now we only have single document type for security supporting docs
    const docType = 'ContractOfSale';

    const openUploadSecurityDocumentModal = () => {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              parentScreenName,
              FieldInteractionKey.SecurityTask,
            ),
        },
      });
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL,
        params: {
          loanApplicationSecurityId: loanApplicationSecurity.id,
          loanApplicationId,
        },
      });
    };

    const caption =
      loanApplicationSecurity.property?.address.short_address_format ?? '--';
    return (
      <NewListRow
        title={t(
          'Content.LoanApplicationTasks.TaskItem.SECURITY_SUPPORTING_DOCUMENT.ContractOfSale',
        )}
        caption={caption}
        subcaption={t(
          'Content.LoanApplicationTasks.TaskItem.SECURITY_SUPPORTING_DOCUMENT.ContractOfSaleSubcaption',
        )}
        onButtonPress={openUploadSecurityDocumentModal}
        {...commonStyles}
        leftIconFamily="svg"
        leftIconName="contract"
        {...(status === Loan_Application_Task_Status.Provided
          ? {
              ...GreenTickIcon,
              rightIconTestID: makeTestId([
                TestID.LoanApplicationTaskItem
                  .UploadSecurityDocumentSuccessIcon,
                docType,
                caption,
              ]),
              onRowPress: openUploadSecurityDocumentModal,
            }
          : {
              buttonLabel: t('Content.Common.ButtonLabel.Upload'),
              buttonTestID: makeTestId([
                TestID.LoanApplicationTaskItem.UploadSecurityDocumentButton,
                docType,
                caption,
              ]),
            })}
      />
    );
  }

  if (
    type === Loan_Application_Task_Type.AccountClosureSupportingDocument ||
    type === Loan_Application_Task_Type.ReducedLimitSupportingDocument ||
    type === Loan_Application_Task_Type.RefinancingSupportingDocument
  ) {
    const liabilityType =
      mergedLiability?.dynamite_liability_type as Liability_Type_Enum;

    const getLeftIconElementProps: () => Pick<
      NewListRowProps,
      'leftIconFamily' | 'leftIconName'
    > = () => {
      switch (type) {
        case Loan_Application_Task_Type.AccountClosureSupportingDocument: {
          return {
            leftIconFamily: 'svg',
            leftIconName: LIABILITIES_ICON_NAME_BY_TYPE[liabilityType],
          };
        }
        case Loan_Application_Task_Type.ReducedLimitSupportingDocument: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'summaryCreditLoan',
          };
        }
        case Loan_Application_Task_Type.RefinancingSupportingDocument: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'refinancingSupportingDocument',
          };
        }
        default: {
          return {
            leftIconFamily: 'svg',
            leftIconName: 'financialEmploymentIncome',
          };
        }
      }
    };
    const onOpenPress = () => {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              parentScreenName,
              FieldInteractionKey.LiabilityTask,
            ),
        },
      });
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL,
        params: {
          mergedLiabilityId: mergedLiability?.id,
          loanApplicationId,
          loanApplicationTaskType: type,
        },
      });
    };
    const rightElement =
      status === Loan_Application_Task_Status.Provided
        ? {
            ...GreenTickIcon,
            onRowPress: onOpenPress,
          }
        : {
            buttonLabel: t('Content.Common.ButtonLabel.Upload'),
          };

    const placeHolder = '--';
    const caption = mergedLiability?.dynamite_institution_name || placeHolder;
    const subCaption =
      mergedLiability?.dynamite_display_address ||
      (mergedLiability?.dynamite_applicant_full_names &&
        joinListOfString(mergedLiability.dynamite_applicant_full_names)) ||
      placeHolder;

    return (
      <NewListRow
        title={getLiabilityTaskItemTitle(liabilityType)}
        caption={caption}
        subcaption={subCaption}
        onButtonPress={onOpenPress}
        {...commonStyles}
        {...rightElement}
        {...getLeftIconElementProps()}
      />
    );
  }

  return null;
}
