export function toFloat(input: unknown): number {
  switch (typeof input) {
    case 'number':
      return Number.isFinite(input) ? input : 0;
    case 'string':
      return parseFloat(input) || 0;
    default:
      return 0;
  }
}
export function toInt(input: unknown): number {
  switch (typeof input) {
    case 'number':
      return Number.isFinite(input) ? input : 0;
    case 'string':
      return parseInt(input, 10) || 0;
    default:
      return 0;
  }
}
