import BioCatchPlugin from '@unloan/react-native-biocatch';
import { NativeModules } from 'react-native';

import { getLogger } from '../utils/loggerHelper';
import { BiocatchConfig } from './biocatchConfigHelper';
import { BIOCATCH_CUSTOMER_BRAND } from './brand';
import { generateCustomerSessionId } from './generateCSID';

const logger = getLogger('APP');

enum BiocatchState {
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  STOPPED = 'STOPPED',
  PAUSED = 'PAUSED',
}

export function isBiocatchNativeAvailable(): boolean {
  const loaded = !!NativeModules.Biocatch;
  logger?.log('Biocatch - isBiocatchNativeAvailable', loaded);
  return loaded;
}

let initialised = false;

export function isBiocatchNativeInitialised(): boolean {
  logger?.log('Biocatch - isBiocatchNativeInitialised', initialised);
  return initialised;
}

export async function startBiocatchDataCaptureNative(
  config: BiocatchConfig,
): Promise<string> {
  if (!isBiocatchNativeAvailable()) {
    throw new Error('Biocatch native SDK is not loaded');
  }

  const customerSessionId = generateCustomerSessionId();

  const enableHybridSolution = false;

  const state = await BioCatchPlugin.getState();

  logger?.log('Biocatch state:', state);

  if (state === BiocatchState.STARTED) {
    await BioCatchPlugin.startNewSession(customerSessionId);
  } else {
    await BioCatchPlugin.startWithLog(
      config.biocatchWupServerURL,
      config.biocatchLogServerURL,
      config.biocatchCustomerId,
      customerSessionId,
      enableHybridSolution,
    );
  }
  initialised = true;
  logger?.log('Biocatch started with customerSessionId:', customerSessionId);

  await BioCatchPlugin.setCustomerBrand(BIOCATCH_CUSTOMER_BRAND);
  logger?.log('Biocatch brand set', BIOCATCH_CUSTOMER_BRAND);

  return customerSessionId;
}

export async function changeContextNative(event: string) {
  if (!isBiocatchNativeInitialised()) {
    logger?.log(
      'Biocatch - skipping change context because native SDK is not initialised',
    );
    return;
  }

  const state = await BioCatchPlugin.getState();

  logger?.log('Biocatch changeContext event=', event, 'state=', state);

  if (state === BiocatchState.STARTED) {
    await BioCatchPlugin.changeContext(event);
  }
}

export async function startNewSessionNative(): Promise<string> {
  if (!isBiocatchNativeInitialised()) {
    throw new Error('Biocatch native SDK is not initialised');
  }

  const customerSessionId = generateCustomerSessionId();

  await BioCatchPlugin.startNewSession(customerSessionId);

  logger?.log(
    'Biocatch started new session with customerSessionId:',
    customerSessionId,
  );

  return customerSessionId;
}
