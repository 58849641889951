import { FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Asserts } from 'yup';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormPhoneInputV2 } from '../../components/form/FormikInputs';
import {
  Change_Personal_Details_Error_Type,
  useCreateMobileChangeRequestMutation,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { FormErrorText, FormikFormError } from '../../ui/v2/FormError';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { FieldValidationError, yupMobileNumber } from '../../utils/yup';
import { PersonalDetailsModalForm } from '../components/PersonalDetailsModalForm';
import { PersonalDetailsModalScreenProps } from '../navigation/types';
import getErrorMessage from '../utils/getErrorMessage';

const NEW_MOBILE_FIELD = 'newMobile';

const validationSchema = yup.object({
  [NEW_MOBILE_FIELD]: yupMobileNumber,
});

type MobileChangeNewMobileValues = Asserts<typeof validationSchema>;

const INIT_VALS: MobileChangeNewMobileValues = {
  [NEW_MOBILE_FIELD]: {
    phoneNumber: '',
    countryIso: 'AU',
    countryPrefix: '+61',
    isValid: true,
  },
};

export const buildErrorMessage = (
  _: keyof MobileChangeNewMobileValues,
  errorCode: string,
) => {
  switch (errorCode) {
    case FieldValidationError.Required:
      return t('Content.PersonalDetails.ChangeMobile.NewMobileRequiredLabel');
    case FieldValidationError.InvalidPhoneNumber:
      return t('Content.PersonalDetails.ChangeMobile.NewMobileValidLabel');
    default:
      return null;
  }
};

type Props =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE>;

export const MobileChangeNewMobile = ({ navigation, route }: Props) => {
  const { auth0MfaSessionId } = route.params;

  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    createMobileChangeRequest,
    {
      loading: createMobileChangeRequestLoading,
      error: createMobileChangeRequestError,
    },
  ] = useCreateMobileChangeRequestMutation();

  const onSubmit = useCallback(
    async ({ newMobile: newMobileProp }: MobileChangeNewMobileValues) => {
      if (!newMobileProp.phoneNumber) {
        return;
      }

      const newMobile = newMobileProp.phoneNumber.trim();

      setValidationErrorMessage(undefined);
      setErrorMessage(undefined);
      const [response] = await safelyCallMutation(createMobileChangeRequest, {
        variables: {
          auth0_mfa_session_id: auth0MfaSessionId,
          new_mobile_number: newMobile,
        },
        context: {
          sentryContext: {
            description: 'Mobile Change Request - Create Change Request',
          },
        },
      });

      const errorType =
        response?.data?.create_mobile_change_request?.error_type;

      if (errorType) {
        const message = getErrorMessage(errorType, 'mobile');
        if (
          errorType === Change_Personal_Details_Error_Type.InvalidMobileNumber
        ) {
          setValidationErrorMessage(message);
          return;
        }

        setErrorMessage(message);
        return;
      }

      const mobileChangeRequestId =
        response?.data?.create_mobile_change_request?.mobile_change_request_id;

      if (!mobileChangeRequestId) {
        const message = t(
          'Content.PersonalDetails.ChangeMobile.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(Screen.PERSONAL_DETAILS_MODAL, {
        screen: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY,
        params: {
          mobileChangeRequestId,
          newMobile,
          auth0MfaSessionId,
        },
      });
    },
    [auth0MfaSessionId, createMobileChangeRequest, navigation],
  );

  const hasError = (formProps: FormikProps<MobileChangeNewMobileValues>) =>
    formProps.touched[NEW_MOBILE_FIELD] && !!formProps.errors[NEW_MOBILE_FIELD];

  return (
    <PersonalDetailsModalForm
      title={t('Content.PersonalDetails.ChangeMobile.NewMobileTitle')}
      initialValues={INIT_VALS}
      onSubmit={onSubmit}
      showSpinner={createMobileChangeRequestLoading}
      validationSchema={validationSchema}
      navigation={navigation}
      submitLabel={t('Content.Common.ButtonLabel.Continue')}
      errorMessage={
        createMobileChangeRequestError
          ? t('Content.PersonalDetails.ChangeMobile.UnableToProcessRequest')
          : errorMessage
      }
    >
      {(formProps) => (
        <>
          <FormPhoneInputV2
            inputTestID={TestID.PersonalDetails.ChangeMobileNewMobileInput}
            label={t('Content.Common.Placeholder.MobileNumber')}
            name={NEW_MOBILE_FIELD}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.PersonalDetails,
              Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE,
              FieldInteractionKey.NewMobileNumber,
            )}
          />

          <FormikFormError
            name={NEW_MOBILE_FIELD}
            sx={{ mt: '$8' }}
            errorMessageParser={buildErrorMessage}
          />

          {!hasError(formProps) && validationErrorMessage ? (
            <FormErrorText sx={{ mt: '$8' }}>
              {validationErrorMessage}
            </FormErrorText>
          ) : null}
        </>
      )}
    </PersonalDetailsModalForm>
  );
};
