import { useCallback } from 'react';

import { ActionSheetType } from '../../navigation/types/screens';
import { BaseConfirmationSuccessActionSheet } from '../components/BaseConfirmationSuccessActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED>;

export function ReduceRepaymentsRequestApprovedActionSheet({
  navigation,
}: Props) {
  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <BaseConfirmationSuccessActionSheet
      message={t(
        'Content.LoanVariation.ReduceRepaymentApproverFlow.LoanVariationChangeApproved',
      )}
      onClose={onClose}
      supportingText={t(
        'Content.LoanVariation.ReduceRepaymentConfirmation.RepaymentSubmittedConfirmationSubText',
      )}
      includeCloseButton
    />
  );
}
