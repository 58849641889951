import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormRadioInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Frequency_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import { makeTestId } from '../../utils/stringHelpers';
import { yup } from '../../utils/yup';

export enum GovernmentIncomeFormFields {
  Amount = 'amount',
  Frequency = 'frequency',
  Payees = 'payees',
}

const GovernmentIncomeSchema = yup.object({
  [GovernmentIncomeFormFields.Amount]: yup
    .number()
    .nullable()
    .required(t('Content.YourIncome.Form.IncomeAmountError')),
  [GovernmentIncomeFormFields.Frequency]: yup
    .string()
    .nullable()
    .required(t('Content.Common.Error.PleaseSelectAnOption')),
  [GovernmentIncomeFormFields.Payees]: yup
    .array()
    .of(yup.string().required())
    .nullable()
    .min(1)
    .required(),
});

export type GovernmentIncomeFormDetails = {
  [GovernmentIncomeFormFields.Amount]: number | undefined;
  [GovernmentIncomeFormFields.Frequency]: Frequency_Enum | undefined;
  [GovernmentIncomeFormFields.Payees]: Array<string> | undefined;
};

export const DEFAULT_GOVERNMENT_INCOME_INITIAL_VALUES: GovernmentIncomeFormDetails =
  {
    amount: undefined,
    frequency: Frequency_Enum.Monthly,
    payees: undefined,
  };

export type GovernmentIncomeFormProps = {
  screen: Screen;
  initialValues?: GovernmentIncomeFormDetails;
  payeeOptions: PickerOptions<string>;
  frequencyOptions: PickerOptions<string>;
  isSubmitting?: boolean;
  onSubmit: (values: GovernmentIncomeFormDetails) => Promise<void>;
  showDeleteButton?: boolean;
  onDelete?: () => Promise<void>;
};

export function GovernmentIncomeForm({
  screen,
  initialValues = DEFAULT_GOVERNMENT_INCOME_INITIAL_VALUES,
  payeeOptions = [],
  frequencyOptions = [],
  isSubmitting,
  onSubmit,
  showDeleteButton,
  onDelete,
}: GovernmentIncomeFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={GovernmentIncomeSchema}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <SHeaderText>
            {t('Content.YourIncome.Form.EnterOtherIncome')}
          </SHeaderText>
          <RowView sx={{ mt: '$16' }}>
            <FormCurrencyInputV2
              name={GovernmentIncomeFormFields.Amount}
              sx={{ mr: '$16', flex: 1 }}
              label={t('Content.YourIncome.Form.IncomeAmount')}
              inputTestID={TestID.OtherIncomeForm.AmountInput}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.IncomeBeforeTax,
              )}
            />
            <FormSelectV2
              name={GovernmentIncomeFormFields.Frequency}
              label={t('Content.YourIncome.Form.IncomeFrequency')}
              testID={TestID.OtherIncomeForm.AmountFrequencyInput}
              items={frequencyOptions}
              sx={{ flex: 1 }}
            />
          </RowView>
          <FormikFormError
            name={GovernmentIncomeFormFields.Amount}
            sx={{ mt: '$8' }}
          />
          <FormikFormError
            name={GovernmentIncomeFormFields.Frequency}
            sx={{ mt: '$8' }}
          />
          <SHeaderText sx={{ mt: '$32', mb: '$8' }}>
            {t('Content.YourIncome.Form.EnterPaidTo')}
          </SHeaderText>
          {payeeOptions.map(({ value, label }) => (
            <FormRadioInputV2
              key={value}
              name={GovernmentIncomeFormFields.Payees}
              testID={makeTestId([TestID.OtherIncomeForm.IncomeOwner, value])}
              value={value}
              label={label}
              checkboxCompatible
              containerStyle={{
                mt: '$8',
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.WhoIsThisIncomePaidTo,
              )}
            />
          ))}
          <FormikFormError
            name={GovernmentIncomeFormFields.Payees}
            sx={{ mt: '$8' }}
            errorMessageParser={() =>
              t('Content.YourIncome.Form.IncomeOwnerError')
            }
          />
          <BottomSpacer />
          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            testID={TestID.OtherIncomeForm.SubmitButton}
            onPress={async () => {
              formProps.handleSubmit();
            }}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            py="m"
            alignSelf="stretch"
          />
          {showDeleteButton ? (
            <Button
              label={t('Content.YourIncome.Form.DeleteIncomeButton')}
              testID={TestID.OtherIncomeForm.DeleteButton}
              onPress={onDelete}
              tertiary
              disabled={isSubmitting}
              fontWeight="normal"
              alignSelf="stretch"
              mt="s"
              style={{
                paddingVertical: 0,
              }}
            />
          ) : null}
        </View>
      )}
    </Formik>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
const BottomSpacer = styled(View)({
  mb: '$32',
});
