import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function ChevronRightIcon({ color, size, ...props }: SvgIconProps) {
  const theme = useTheme();
  const fill = color ?? theme.colors.buttonPrimaryBg;
  const width = resizeIconWidth(8, 14, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 8 14" fill="none" {...props}>
      <Path
        d="M.957 13.217a.914.914 0 00.663-.274l5.243-5.12c.233-.232.342-.471.349-.765 0-.294-.11-.54-.349-.766L1.62 1.165A.925.925 0 00.957.892a.95.95 0 00-.957.95c0 .26.11.506.3.697l4.65 4.519L.3 11.569a.986.986 0 00-.3.698c0 .526.424.95.957.95z"
        fill={fill}
      />
    </Svg>
  );
}
