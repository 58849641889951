import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { FormikProps } from 'formik';
import { ReactNode } from 'react';

import { shouldDisableSubmit } from './formHelpers';
import { useCancelButton, useSubmitButton } from './hooks/useHeaderButton';

export type Props<Values, NavProp extends NavigationProp<ParamListBase>> = {
  formProps: FormikProps<Values>;
  navigation: NavProp;
  isSubmitting?: boolean;
  submitText?: string;
  onSubmit?: () => void;
  isEditing?: boolean;
  onEdit?: () => void;
  editText?: string;
  /**
   * Override the default behaviour when pressing the cancel button.
   * Useful when the form have edit and view mode.
   * Defaults to navigation.goBack.
   */
  onCancel?: () => void;
  allowClean?: boolean;
  children?: ReactNode;
  /** Allow to disable submit button & override the disabled state from the form state */
  disabled?: boolean;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  submitButtonTestID?: string;
};

export const FormNavButtonHandler = <
  TValues extends Record<string, unknown>,
  NavProp extends NavigationProp<ParamListBase>,
>({
  allowClean,
  children,
  formProps,
  navigation,
  onSubmit: onSubmitProp,
  submitText = t('Content.Common.ButtonLabel.Done'),
  isEditing = true,
  onEdit,
  editText = t('Content.Common.ButtonLabel.Edit'),
  isSubmitting,
  disabled: disabledProp,
  onCancel,
  hideCancelButton,
  hideSubmitButton,
  submitButtonTestID,
}: Props<TValues, NavProp> & {
  children?: JSX.Element;
}): JSX.Element | null => {
  const submitDisabled =
    disabledProp ?? shouldDisableSubmit(formProps, allowClean);
  const { handleSubmit } = formProps;
  const onSubmit = onSubmitProp || handleSubmit;
  const text = isEditing ? submitText : editText;
  const onPress = isEditing ? onSubmit : onEdit;
  const disabled = isEditing ? submitDisabled : false;

  useCancelButton(navigation, {
    displayNone: !isEditing || hideCancelButton,
    onPress: onCancel || navigation.goBack,
  });

  useSubmitButton(navigation, {
    text,
    disabled,
    onPress,
    loading: isSubmitting || formProps.isSubmitting,
    displayNone: hideSubmitButton,
    testID: submitButtonTestID,
  });

  if (children === undefined) {
    return null;
  }

  return children;
};
