import { ExperimentFlagConfig } from '../FeatureFlags/featureFlags';
import { Loan_Application_Type_Enum } from '../generated/graphql';
import { ApplicationInteractionEventKeyType } from './utils/gtmKeyUtils';

// This is coming from defined events from Marketing
export enum GTMEvent {
  AppInteractionEvent = 'app_interaction',
  /**
   * loan_event is referring to `loan_app_started`
   * There is an incoming work to rename this to make it clearer
   */
  LoanEvent = 'loan_event',
  LoanAppSubmitted = 'loan_app_submitted',
  LoginEvent = 'login',
  VirtualPageView = 'virtual_page_view',
  ExperimentExposure = 'experiment_exposure',
}

export enum GTMAppInteractionEventDescription {
  AddBankConnection = 'bank_connection_added',
  TextInputChanged = 'text_input_changed',
  ButtonClicked = 'button_clicked',
  ValueChanged = 'value_changed',
  WarningShown = 'warning_shown',
  RadioSelected = 'radio_selected',
  CheckboxClicked = 'checkbox_clicked',
}

export type BaseGTMEvent = {
  user_id?: string;
};

export enum ApplicanTypeForGTM {
  Borrower = 'Borrower',
  Coborrower = 'Co-borrower',
}

export type LoanAppEvent = {
  event: GTMEvent.LoanEvent | GTMEvent.LoanAppSubmitted;
  loan_app_id: string;
  loan_applicant_type?: ApplicanTypeForGTM;
  loan_application_type?: Loan_Application_Type_Enum;
} & BaseGTMEvent;

type LoginEvent = {
  event: GTMEvent.LoginEvent;
  intercom_hash?: string | null;
  intercom_user_id?: string | null;
} & BaseGTMEvent;

export type EventAdditionalData = {
  current_application_id?: string | null;
  loan_applicant_type?: ApplicanTypeForGTM;
  loan_application_type?: Loan_Application_Type_Enum;
};

export interface VirtualPageViewEventAdditionalData
  extends EventAdditionalData {
  item_list_count?: number;
}

export interface AppInteractionEventAdditionalData extends EventAdditionalData {
  application_interaction_event_key?: ApplicationInteractionEventKeyType;
  savings_banner_visible?: boolean;
  savings_amount?: number;
  connected_bank_list_count?: number;
  warning_shown_for?: string;
}

export type VirtualPageViewEvent = {
  event: GTMEvent.VirtualPageView;
  virtual_page_name: string | null;
} & BaseGTMEvent &
  EventAdditionalData &
  VirtualPageViewEventAdditionalData;

export type AppInteractionEvent = {
  event: GTMEvent.AppInteractionEvent;
  description: string;
} & BaseGTMEvent &
  EventAdditionalData &
  AppInteractionEventAdditionalData;

export type ExperimentExposureEvent = {
  event: GTMEvent.ExperimentExposure;
  experiment_flag: string;
  experiment_variant: string | boolean | ExperimentFlagConfig[] | undefined;
  experiment_name: string;
  user_id: string;
  loan_application_id?: string;
} & BaseGTMEvent;

export type GTMEvents =
  | VirtualPageViewEvent
  | LoanAppEvent
  | LoginEvent
  | AppInteractionEvent
  | ExperimentExposureEvent;

export type SendDataToGTM = (events: GTMEvents) => void;

// SectionInteractionKey and FieldInteractionKey keys are used
// to build the application interaction event key for GTM.

export enum SectionInteractionKey {
  Splash = 'splash',
  Property = 'property',
  Borrowers = 'borrowers',
  Income = 'income',
  Expenses = 'expenses',
  Debts = 'debts',
  YourUnloan = 'your_unloan',
  ConditionalApproval = 'conditional_approval',
  ReviewSummary = 'review_summary',
  AccountPermissions = 'account_permissions',
  PersonalDetails = 'personal_details',
  DailyTransferLimit = 'daily_transfer_limit',
  Statements = 'statements',
  AutopaySetup = 'autopay_setup',
  ReduceRepayments = 'reduce_repayments',
  Withdrawal = 'withdrawal',
  Accounts = 'accounts',
  MockAccountModal = 'mock_account_modal',
  LoanApplicationTask = 'loan_application_task',
}

export enum FieldInteractionKey {
  AccountBsb = 'account_bsb',
  AccountList = 'account_list',
  AccountName = 'account_name',
  AccountNumber = 'account_number',
  AccountOwner = 'account_owner',
  AdditionalExpenses = 'additional_expenses',
  Allowances = 'allowances',
  AllToAuthorizeOption = 'all_to_authorize_option',
  Amount = 'amount',
  AreYouCurrentlyEmployed = 'are_you_currently_employed',
  AreYouLivingInThisProperty = 'are_you_living_in_this_property',
  AvailableBalance = 'available_balance',
  Balance = 'balance',
  Bonus = 'bonus',
  Calculators = 'calculators',
  CanStopVoluntaryDeductions = 'can_stop_voluntary_deductions',
  CardNumber = 'card_number',
  CashOutFinancialInvestment = 'cash_out_financial_investment',
  CashOutInvestmentProperty = 'cash_out_investment_property',
  CashOutOther = 'cash_out_other',
  CashOutPersonalUse = 'cash_out_personal_use',
  CashOutProperty = 'cash_out_property',
  CashOutYourHome = 'cash_out_your_home',
  ChildAndSpouseSupport = 'childcare_and_spouse_support',
  ChildcareEducation = 'childcare_and_education',
  ClothingPersonalCare = 'clothing_and_personal_care',
  Code = 'code',
  Commissions = 'commissions',
  Continue = 'continue',
  CustomRepaymentCustom = 'custom_repayment_custom',
  CustomRepaymentCustomAmount = 'custom_repayment_custom_amount',
  CustomRepaymentMinimum = 'custom_repayment_minimum_amount',
  DateOfBirth = 'date_of_birth',
  DebtConsolidationDisclosure = 'debt_consolidation_disclosure',
  Description = 'description',
  DisbursementDate = 'disbursement_date',
  DocumentNumber = 'document_number',
  DoYouHaveAnyLoans = 'do_you_have_any_loans',
  DoYouRecieveRentalIncome = 'do_you_receive_rental_income',
  EmailAddress = 'email_address',
  EmployerName = 'employer_name',
  EndDate = 'end_date',
  EntertainmentPets = 'entertainment_and_pets',
  EnterYourIdentityDocumentDetails = 'enter_your_identity_document_details',
  EstimatedLoanAmount = 'estimated_loan_amount',
  EstimatedPropertyValue = 'estimated_property_value',
  ExpiryDate = 'expiry_date',
  FinancialDeclarationExpectedChanges = 'financial_declaration_expected_changes',
  FinancialDeclarationExplainOtherPayOffMethod = 'financial_declaration_explain_other_pay_off_method',
  FinancialDeclarationRetirement = 'financial_declaration_retirement',
  Finish = 'finish',
  FirstName = 'first_name',
  FirstTimeHomeBuyer = 'first_time_home_buyer',
  FixedRateExpiryDate = 'fixed_rate_expiry_date',
  FoodGroceriesTakeAway = 'food_groceries_takeaway',
  FortnightlyRepayment = 'fortnightly_repayment',
  FromAccountBsbNumer = 'from_bsb_number',
  FromAccountList = 'from_account_list',
  FromAccountName = 'from_account_name',
  FromAccountNumber = 'from_account_number',
  Gender = 'gender',
  HasCashOutAmount = 'has_cash_out_amount',
  HasNovatedLeases = 'has_novated_leases',
  HasVoluntaryDeductions = 'has_voluntary_deductions',
  HecsHelpMonthlyRepayment = 'hecs_monthly_repayment',
  HomeUtilities = 'home_and_utilities',
  IdvTask = 'idv_task',
  IncomeBeforeTax = 'income_before_tax',
  IncomeTask = 'income_task',
  IncomeTypeDividends = 'income_type_dividends',
  IncomeTypeEmployment = 'income_type_employment',
  IncomeTypeGovernmentPayments = 'income_type_government_payments',
  IncomeTypeRental = 'income_type_rental',
  Institution = 'institution',
  InterestRate = 'interest_rate',
  IsPayingHECS = 'is_paying_hecs',
  LastName = 'last_name',
  LiabilityTask = 'liability_task',
  LiabilityType = 'liability_type',
  LicenceNumber = 'licence_number',
  Limit = 'limit',
  LivingSituation = 'living_situation',
  LoanAmount = 'loan_amount',
  LoanType = 'loan_type',
  MailingAddress = 'mailing_address',
  MedicalHealthFitness = 'medical_health_and_fitness',
  MiddleName = 'middle_name',
  MobileNumber = 'mobile_number',
  MonthlyRepayment = 'monthly_repayment',
  MonthlyRepaymentAmount = 'monthly_repayment_amount',
  NewEmailAddress = 'update_email_address',
  NewMobileNumber = 'update_mobile_number',
  NewResidentialAddress = 'new_residential_address',
  NovatedLeaseMonthlyRepayment = 'novated_lease_monthly_repayment',
  OccupationType = 'occupation_type',
  OneToAuthorizeOption = 'one_to_authorize_option',
  OtherCashoutDescription = 'other_top_up_description',
  OtherDescription = 'other_description',
  OtherNames = 'other_names',
  Overtime = 'overtime',
  Postcode = 'postcode',
  PropertyAddress = 'property_address',
  PropertyInsights = 'property_insights',
  PropertyOwner = 'property_owner',
  PropertyPurpose = 'property_purpose',
  PropertyType = 'property_type',
  PurchasePrice = 'purchase_price',
  Reason = 'reason',
  RedrawAmountToUse = 'redraw_amount_to_use',
  Reference = 'reference',
  RefinancingReason = 'refinancing_reason',
  RentalExpenses = 'rental_expenses',
  RentalIncome = 'rental_income',
  RentOrBoard = 'rent_or_board',
  ResidentialAddress = 'residential_address',
  Salary = 'salary',
  SavingsValueChange = 'savings_value_changed',
  SecurityTask = 'security_task',
  SelectedLoansToBeRefinanced = 'selected_loans_to_be_refinanced',
  StartDate = 'start_date',
  StreetName = 'street_name',
  StreetNumber = 'street_number',
  StreetType = 'street_type',
  Suburb = 'suburb',
  Transport = 'transport',
  UnitNumber = 'unit_number',
  UseResidentialAddressAsMailingAddress = 'use_residential_address_as_mailing_address',
  WarningShown = 'warning_shown',
  WeeklyRepayment = 'weekly_repayment',
  WhatAreYouLookingToDo = 'what_are_you_looking_to_do',
  WhatsThePurposeOfYourLoan = 'whats_the_purpose_of_your_loan',
  WhoAreTheBorrowersInThisHousehold = 'who_are_the_borrowers_in_this_household',
  WhoIsThisIncomePaidTo = 'who_is_this_income_paid_to',
}
