import { useCallback, useContext, useEffect } from 'react';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ContactDetails } from '../../config';
import { useConnectedInstitutionsQuery } from '../../ConnectedBanks/remoteData';
import { formatFinancialBankListItemCaption } from '../../ConnectedBanks/utils/formatFinancialBankListItemCaption';
import { getInstitutionConnectionName } from '../../ConnectedBanks/utils/getInstitutionConnectionName';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { BankProfileParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { fastlinkEmitter } from '../../SelectInstitution/utils/fastlinkEmitter';
import { Box } from '../../ui/atoms/Box';
import { Link } from '../../ui/atoms/Link';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { useMyYodleeFastlinkRefreshSubscription } from '../../utils/hooks/useMyYodleeFastlinkRefreshSubscription';
import { useThrottleRefetch } from '../../utils/hooks/useThrottleRefetch';
import { SettingsScreenProps } from '../navigation/types';

type Props = SettingsScreenProps<Screen.SETTINGS_CONNECTED_BANKS>;

function SettingsConnectedBanksBase({ navigation }: Props) {
  const navigateToBankLogin = useCallback(
    () =>
      navigation.navigate(Screen.SELECT_INSTITUTION_MODAL, {
        screen: Screen.BANK_LOGIN,
        // For connecting bank account outside of apply loan flow, we won't
        // have loanApplicationId. This can serve as flag to know whether
        // Bank Login was accessed from Settings or from somewhere in our wizard
        params: { loanApplicationId: undefined },
      }),
    [navigation],
  );

  const navigateToBankDetail = useCallback(
    (bankProfileParams: BankProfileParams) => {
      navigation.navigate(Screen.SETTINGS_BANK_PROFILE, bankProfileParams);
    },
    [navigation],
  );

  const navigateToManageConsents = useCallback(
    () =>
      navigation.navigate(Screen.SELECT_INSTITUTION_MODAL, {
        screen: Screen.MANAGE_CONSENTS,
        params: {},
      }),
    [navigation],
  );

  const { data, loading, connectedInstitutions, error, refetch } =
    useConnectedInstitutionsQuery();
  const refetchConnectedInstitutions = useThrottleRefetch(refetch);

  const myUserId = data?.me[0]?.user?.id;
  useMyYodleeFastlinkRefreshSubscription({
    myUserId,
    onYodleeDataRefresh: async () => {
      await refetchConnectedInstitutions.throttled();
    },
  });

  const { flags } = useContext(FeatureFlagsContext);
  const enableOpenBankingDataRecipient =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT;

  useEffect(() => {
    fastlinkEmitter.on('completed', async () => {
      await refetchConnectedInstitutions.throttled();
    });
    return () => {
      fastlinkEmitter.off('completed');
    };
  }, [refetchConnectedInstitutions]);

  if (!enableOpenBankingDataRecipient) {
    return <NotFoundScreen />;
  }
  return (
    <UpliftScreenContainer onPressBack={navigation.goBack}>
      <HomeHeader title={t('Content.ConnectedBanks.ScreenTitle')} />

      {loading ? (
        <Box my="m">
          <Spinner />
        </Box>
      ) : null}
      <ListRowGroup
        headerText={t('Content.ConnectedBanks.Header')}
        uppercaseHeader={false}
        mx={0}
      >
        {error ? (
          <EmptyState
            title={t('Content.Common.Error.FailLoad')}
            description={error?.message}
          />
        ) : null}
        {connectedInstitutions.map((connection) => (
          <ListRow
            label={connection.institution.name}
            caption={formatFinancialBankListItemCaption(
              getInstitutionConnectionName(connection.profiles),
              connection.accountCount,
            )}
            imageUrl={connection.institution.favicon}
            borderedImage
            key={connection.id}
            onPress={() =>
              navigateToBankDetail({
                institutionConnectionId: connection.id,
              })
            }
          />
        ))}
        <ListRow
          isButton
          label={t('Content.ConnectedBanks.ManageConsent')}
          last
          onPress={navigateToManageConsents}
        />
      </ListRowGroup>

      <ListRowGroup
        uppercaseHeader={false}
        headerText={t('Content.ConnectedBanks.ConnectBankLegalsText')}
        spacer={false}
        mx={0}
      >
        <ListRow
          isButton
          label={t('Content.ConnectedBanks.ConnectAnotherBank')}
          last
          onPress={navigateToBankLogin}
        />
      </ListRowGroup>
      <Box mt="m">
        <StyledText mx="m" mt="s" variant="caption">
          {t('Content.ConnectedBanks.Footer')}
          <Link
            variant="caption"
            href={t('Link.UnloanConsumerDataRightPolicy')}
          >
            {t('Content.ConnectedBanks.FooterLink')}
          </Link>
        </StyledText>

        <StyledText mx="m" mt="s" variant="caption">
          {t('Content.ConnectedBanks.RequestRecords')}
          <Link variant="caption" href={`mailto:${ContactDetails.unloanEmail}`}>
            {ContactDetails.unloanEmail}.
          </Link>
        </StyledText>
      </Box>
    </UpliftScreenContainer>
  );
}

export const WithAuthenticationSettingsConnectedBanks =
  withAuthenticationRequired(SettingsConnectedBanksBase);
