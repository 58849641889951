// Taken from street type enum in Lixi schema
export const STREET_TYPE_SUGGESTIONS = [
  'Access',
  'Alley',
  'Alleyway',
  'Amble',
  'Anchorage',
  'Approach',
  'Arcade',
  'Arterial',
  'Artery',
  'Avenue',
  'Banan',
  'Bank',
  'Basin',
  'Bay',
  'Beach',
  'Bend',
  'Block',
  'Boardwalk',
  'Boulevard',
  'Boulevarde',
  'Bowl',
  'Brace',
  'Brae',
  'Break',
  'Bridge',
  'Broadway',
  'Brow',
  'Bypass',
  'Byway',
  'Canal',
  'Causeway',
  'Centre',
  'Centreway',
  'Chase',
  'Circle',
  'Circlet',
  'Circuit',
  'Circus',
  'Close',
  'Cluster',
  'Colonnade',
  'Common',
  'Concourse',
  'Connection',
  'Copse',
  'Corner',
  'Corso',
  'Course',
  'Court',
  'Courtyard',
  'Cove',
  'Crescent',
  'Crest',
  'Cross',
  'Crossing',
  'Crossroad',
  'Crossway',
  'Cruiseway',
  'Cul-De-Sac',
  'Cutting',
  'Dale',
  'Dell',
  'Dene',
  'Deviation',
  'Dip',
  'Distributor',
  'Divide',
  'Dock',
  'Domain',
  'Downs',
  'Drive',
  'Driveway',
  'Edge',
  'Elbow',
  'End',
  'Entrance',
  'Esplanade',
  'Estate',
  'Estuary',
  'Expressway',
  'Extension',
  'Fairway',
  'Fire Track',
  'Firebreak',
  'Fireline',
  'Firetrack',
  'Firetrail',
  'Flat',
  'Follow',
  'Footway',
  'Ford',
  'Foreshore',
  'Formation',
  'Freeway',
  'Front',
  'Frontage',
  'Gap',
  'Garden',
  'Gardens',
  'Gate',
  'Gates',
  'Gateway',
  'Glade',
  'Glen',
  'Grange',
  'Green',
  'Ground',
  'Grove',
  'Gully',
  'Harbour',
  'Heath',
  'Heights',
  'Highroad',
  'Highway',
  'Hill',
  'Hollow',
  'Hub',
  'Interchange',
  'Intersection',
  'Junction',
  'Key',
  'Keys',
  'Landing',
  'Lane',
  'Laneway',
  'Lees',
  'Line',
  'Link',
  'Little',
  'Lookout',
  'Loop',
  'Lower',
  'Mall',
  'Manor',
  'Mead',
  'Meander',
  'Mears',
  'Mew',
  'Mews',
  'Mile',
  'Motorway',
  'Mount',
  'Nook',
  'Outlet',
  'Outlook',
  'Parade',
  'Park',
  'Parklands',
  'Parkway',
  'Part',
  'Pass',
  'Passage',
  'Path',
  'Pathway',
  'Piazza',
  'Pier',
  'Place',
  'Plateau',
  'Plaza',
  'Pocket',
  'Point',
  'Port',
  'Portal',
  'Promenade',
  'Pursuit',
  'Quad',
  'Quadrangle',
  'Quadrant',
  'Quay',
  'Quays',
  'Ramble',
  'Ramp',
  'Range',
  'Reach',
  'Reserve',
  'Rest',
  'Retreat',
  'Return',
  'Ride',
  'Ridge',
  'Ridgeway',
  'Right Of Way',
  'Ring',
  'Rise',
  'Rising',
  'River',
  'Riverway',
  'Riviera',
  'Road',
  'Roads',
  'Roadside',
  'Roadway',
  'Ronde',
  'Rosebowl',
  'Rotary',
  'Round',
  'Route',
  'Row',
  'Rue',
  'Run',
  'Service Way',
  'Shunt',
  'Siding',
  'Slope',
  'Sound',
  'Spur',
  'Square',
  'Stairs',
  'State Highway',
  'Steps',
  'Strand',
  'Street',
  'Streets',
  'Strip',
  'Subway',
  'Tarn',
  'Tarnice Way',
  'Terrace',
  'Thoroughfare',
  'Throughway',
  'Tollway',
  'Top',
  'Tor',
  'Towers',
  'Track',
  'Trail',
  'Trailer',
  'Tramway',
  'Triangle',
  'Trunkway',
  'Turn',
  'Twist',
  'Underpass',
  'Upper',
  'Vale',
  'Valley',
  'Viaduct',
  'View',
  'Village',
  'Villas',
  'Vista',
  'Wade',
  'Walk',
  'Walkway',
  'Waterway',
  'Way',
  'Wharf',
  'Woods',
  'Wynd',
  'Yard',
];
