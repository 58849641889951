import { LOG_CONFIG } from '../config';

class Logger {
  private prefix: string;

  constructor(loggerType: keyof typeof LOG_CONFIG) {
    this.prefix = `### ${loggerType} ###`;
  }

  public log(message: string, ...params: unknown[]): void {
    // eslint-disable-next-line no-console
    console.log(this.prefix, message, ...params);
  }

  public warn(message: string, ...params: unknown[]): void {
    // eslint-disable-next-line no-console
    console.warn(this.prefix, message, ...params);
  }

  public error(message: string, ...params: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(this.prefix, message, ...params);
  }
}

function isLoggerEnabled(loggerType: keyof typeof LOG_CONFIG) {
  return LOG_CONFIG[loggerType];
}

export const getLogger = (
  loggerType: keyof typeof LOG_CONFIG,
): Logger | null => {
  if (isLoggerEnabled(loggerType)) {
    return new Logger(loggerType);
  }
  return null;
};
