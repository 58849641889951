import { TouchableOpacity, TouchableWithoutFeedbackProps } from 'react-native';

/**
 * There's an issue of TouchableWithoutFeedback in RNW
 * https://github.com/necolas/react-native-web/issues/1663
 * We can remove this after expo upgrade RNW version to the latest
 */
export function TouchableWithoutFeedback(props: TouchableWithoutFeedbackProps) {
  return <TouchableOpacity activeOpacity={1} {...props} />;
}
