import * as React from 'react';

import {
  TransferRow,
  TransferStatusPendingLabel,
  TransferStatusRow,
} from '../v2/UpcomingCard';

type AlertActionType = {
  showPendingIcon?: boolean;
  alertLabel?: string;
  alertActionLabel?: string;
  onPressAlertAction?: () => void;
  alertActionSecondary?: boolean;
};

type Props = {
  amountLabel?: string;
  label: string;
  caption?: string;
  subCaption?: string;
  onPress?: () => void;
  loading?: boolean;
  alertAction?: AlertActionType;
};

export const ActionCard: React.FC<Props> = ({
  amountLabel,
  label,
  caption,
  subCaption,
  onPress,
  alertAction,
  loading,
}) => {
  const {
    showPendingIcon,
    alertLabel = '',
    onPressAlertAction,
    alertActionLabel,
    alertActionSecondary,
  } = alertAction || {};

  const conditionalText = loading
    ? {}
    : {
        caption,
        subCaption,
        amountLabel,
      };

  return (
    <>
      <TransferRow
        label={label}
        onPress={onPress}
        loading={loading}
        {...conditionalText}
      />
      {!loading && alertAction ? (
        <TransferStatusRow
          label={
            <TransferStatusPendingLabel
              showPendingIcon={showPendingIcon}
              label={alertLabel}
              isSecondary={alertActionSecondary}
            />
          }
          buttonLabel={alertActionLabel}
          onPress={onPressAlertAction}
          isSecondary={alertActionSecondary}
        />
      ) : null}
    </>
  );
};
