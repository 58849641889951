import { useCallback, useMemo } from 'react';
import * as React from 'react';

import { SelectionRow } from '../../components/form/SelectionRow';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { formatCurrency } from '../../utils/currencyHelpers';
import { autopayCustomRepaymentFormAtom } from '../autopayAtom';
import { useRecoilObserver } from '../utils/useRecoilObserver';

type Props = {
  disabled?: boolean;
  monthlyMinimumRepaymentAmount: number;
  selectedRepaymentAmount: number | undefined;
  selectedRepaymentFrequency: Autopay_Frequency_Input_Enum | undefined;
  onSelect: (value: {
    selectedRepaymentAmount: number;
    selectedRepaymentFrequency: Autopay_Frequency_Input_Enum;
    isMinimumAmount: boolean;
  }) => void;
  onPressCustom: () => void;
  footerErrorMessage?: string | null | undefined;
};

export const AutopayRepaymentSelect: React.FC<Props> = ({
  disabled,
  monthlyMinimumRepaymentAmount,
  selectedRepaymentAmount,
  selectedRepaymentFrequency,
  onSelect,
  onPressCustom,
  footerErrorMessage,
}) => {
  const fortnightlyMinimumRepaymentAmount = useMemo(
    () => Math.ceil(monthlyMinimumRepaymentAmount / 2),
    [monthlyMinimumRepaymentAmount],
  );

  const weeklyMinimumRepaymentAmount = useMemo(
    () => Math.ceil(monthlyMinimumRepaymentAmount / 4),
    [monthlyMinimumRepaymentAmount],
  );

  const onSelectMonthlyMinimum = useCallback(() => {
    onSelect({
      selectedRepaymentAmount: Math.ceil(monthlyMinimumRepaymentAmount),
      selectedRepaymentFrequency: Autopay_Frequency_Input_Enum.Monthly,
      isMinimumAmount: true,
    });
  }, [onSelect, monthlyMinimumRepaymentAmount]);

  const onSelectFortnightlyMinimum = useCallback(() => {
    onSelect({
      selectedRepaymentAmount: fortnightlyMinimumRepaymentAmount,
      selectedRepaymentFrequency: Autopay_Frequency_Input_Enum.Fortnightly,
      isMinimumAmount: true,
    });
  }, [onSelect, fortnightlyMinimumRepaymentAmount]);

  const onSelectWeeklyMinimum = useCallback(() => {
    onSelect({
      selectedRepaymentAmount: weeklyMinimumRepaymentAmount,
      selectedRepaymentFrequency: Autopay_Frequency_Input_Enum.Weekly,
      isMinimumAmount: true,
    });
  }, [onSelect, weeklyMinimumRepaymentAmount]);

  const isCustomSelected = useMemo(
    () =>
      typeof selectedRepaymentFrequency === 'string' &&
      typeof selectedRepaymentAmount === 'number' &&
      ((selectedRepaymentFrequency === Autopay_Frequency_Input_Enum.Monthly &&
        selectedRepaymentAmount > monthlyMinimumRepaymentAmount) ||
        (selectedRepaymentFrequency ===
          Autopay_Frequency_Input_Enum.Fortnightly &&
          selectedRepaymentAmount > fortnightlyMinimumRepaymentAmount) ||
        (selectedRepaymentFrequency === Autopay_Frequency_Input_Enum.Weekly &&
          selectedRepaymentAmount > weeklyMinimumRepaymentAmount)),
    [
      selectedRepaymentFrequency,
      selectedRepaymentAmount,
      monthlyMinimumRepaymentAmount,
      fortnightlyMinimumRepaymentAmount,
      weeklyMinimumRepaymentAmount,
    ],
  );

  useRecoilObserver(
    autopayCustomRepaymentFormAtom,
    ({ customRepaymentFrequency, customRepaymentAmount }) => {
      if (customRepaymentFrequency === '') {
        return;
      }
      if (customRepaymentAmount === null) {
        return;
      }
      onSelect({
        selectedRepaymentFrequency: customRepaymentFrequency,
        selectedRepaymentAmount: customRepaymentAmount,
        isMinimumAmount: false,
      });
    },
  );

  return (
    <ListRowGroup
      mx={0}
      headerText={t('Content.AutopaySettings.SelectRepaymentTypeHeader')}
      footerErrorMessage={footerErrorMessage}
    >
      <SelectionRow
        disabled={disabled}
        label={t('Content.AutopaySettings.MonthlyMinimum')}
        onPress={onSelectMonthlyMinimum}
        selected={
          selectedRepaymentFrequency === Autopay_Frequency_Input_Enum.Monthly &&
          monthlyMinimumRepaymentAmount === selectedRepaymentAmount
        }
        caption={t('Content.AutopaySettings.MonthlyAmount', {
          amount: formatCurrency(monthlyMinimumRepaymentAmount, {
            noFraction: true,
          }),
        })}
      />
      <SelectionRow
        disabled={disabled}
        label={t('Content.AutopaySettings.FortnightlyMinimum')}
        onPress={onSelectFortnightlyMinimum}
        selected={
          selectedRepaymentFrequency ===
            Autopay_Frequency_Input_Enum.Fortnightly &&
          fortnightlyMinimumRepaymentAmount === selectedRepaymentAmount
        }
        caption={t('Content.AutopaySettings.FortnightlyAmount', {
          amount: formatCurrency(fortnightlyMinimumRepaymentAmount, {
            noFraction: true,
          }),
        })}
      />
      <SelectionRow
        disabled={disabled}
        label={t('Content.AutopaySettings.WeeklyMinimum')}
        onPress={onSelectWeeklyMinimum}
        selected={
          selectedRepaymentFrequency === Autopay_Frequency_Input_Enum.Weekly &&
          weeklyMinimumRepaymentAmount === selectedRepaymentAmount
        }
        caption={t('Content.AutopaySettings.WeeklyAmount', {
          amount: formatCurrency(weeklyMinimumRepaymentAmount, {
            noFraction: true,
          }),
        })}
      />
      <SelectionRow
        disabled={disabled}
        label={t('Content.AutopaySettings.Custom')}
        onPress={onPressCustom}
        last
        selected={isCustomSelected}
        caption={
          isCustomSelected
            ? getLabelForCustomRepayment({
                selectedRepaymentAmount,
                selectedRepaymentFrequency,
              })
            : undefined
        }
      />
    </ListRowGroup>
  );
};

function getSelectedFrequencyLabel(
  customRepaymentFrequency: Autopay_Frequency_Input_Enum | undefined,
) {
  switch (customRepaymentFrequency) {
    case Autopay_Frequency_Input_Enum.Weekly:
      return t('Content.Common.FrequencyEnum.WEEKLY');
    case Autopay_Frequency_Input_Enum.Fortnightly:
      return t('Content.Common.FrequencyEnum.FORTNIGHTLY');
    case Autopay_Frequency_Input_Enum.Monthly:
    default:
      return t('Content.Common.FrequencyEnum.MONTHLY');
  }
}

function getLabelForCustomRepayment({
  selectedRepaymentAmount,
  selectedRepaymentFrequency,
}: {
  selectedRepaymentAmount: number | undefined;
  selectedRepaymentFrequency: Autopay_Frequency_Input_Enum | undefined;
}): string | undefined {
  if (
    typeof selectedRepaymentFrequency !== 'string' &&
    typeof selectedRepaymentAmount !== 'number'
  ) {
    return '';
  }

  const formattedAmount = formatCurrency(selectedRepaymentAmount);

  const formattedFrequency = getSelectedFrequencyLabel(
    selectedRepaymentFrequency,
  );

  return `${formattedAmount} ${formattedFrequency}`;
}
