import { Text } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { useCancelRequestMutation } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST>;

export const ReduceRepaymentsCancelRequestActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { approvalRequestId, cbaAccountId } = route.params;
  const [
    cancelRequest,
    { loading: cancelRequestLoading, error: cancelRequestError },
  ] = useCancelRequestMutation();

  const onPress = useCallback(async () => {
    const [response, error] = await safelyCallMutation(cancelRequest, {
      variables: {
        approval_request_id: approvalRequestId,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Loan Variation - Cancel Request Mutation',
        },
      },
    });

    if (!error && !!response.data) {
      navigation.replace(ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED);
    }
  }, [cbaAccountId, approvalRequestId, cancelRequest, navigation]);

  const errorMessage = cancelRequestError
    ? (t(
        'Content.LoanVariation.ReduceRepaymentCancelRequest.UnableToProcessRequest',
      ) as string)
    : undefined;

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      showCloseButton={false}
      isRowButton
      errorMessage={errorMessage}
      title={t('Content.LoanVariation.ReduceRepaymentCancelRequest.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.LoanVariation.CancelRequest.CancelButton,
          disabled: cancelRequestLoading,
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          testID: TestID.LoanVariation.CancelRequest.ConfirmButton,
          showSpinner: cancelRequestLoading,
          disabled: cancelRequestLoading || !!cancelRequestError,
          onPress,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <Text
        variant="body"
        sx={{ py: '$8', px: '$16', mb: '$16', textAlign: 'center' }}
      >
        {t('Content.LoanVariation.ReduceRepaymentCancelRequest.SubTitle')}
      </Text>
    </ActionSheet>
  );
};
