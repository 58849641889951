import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  Biza_Account_Sharing_Status,
  useGetBizaAccountByIdQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast } from '../../utils/arrayHelpers';
import {
  useCancelButton,
  useNextButton,
} from '../../utils/hooks/useHeaderButton';
import { isKnownEnumValue } from '../../utils/typesHelpers';
import {
  DataSharingConfirmationProps,
  DataSharingModalStackNavigationProp,
} from '../navigation/types';
import { AccountStatus, Status } from '../types';
import { getBizaAccount } from '../utils/biza';

type Props = DataSharingConfirmationProps<Screen.DATA_SHARING_CURRENT_STATUS>;

const statusLabel = {
  [Status.Enabled]: t('Content.DataSharingPreferences.CurrentStatus.Enable'),
  [Status.Disabled]: t('Content.DataSharingPreferences.CurrentStatus.Disable'),
};

const statusSubText = {
  [Status.Enabled]: t(
    'Content.DataSharingPreferences.CurrentStatus.EnableSubText',
  ),
  [Status.Disabled]: t(
    'Content.DataSharingPreferences.CurrentStatus.DisableSubText',
  ),
};

const AccountStatusList: React.FC<{
  selectedStatus: Status;
  setStatus: (status: Status) => void;
}> = ({ selectedStatus, setStatus }) => {
  const statuses: Exclude<Status, Status.Pending>[] = [
    Status.Disabled,
    Status.Enabled,
  ];

  return (
    <ListRowGroup
      mx="m"
      headerText={t('Content.DataSharingPreferences.CurrentStatus.Title')}
      overflow="visible"
      uppercaseHeader={false}
      testID={TestID.DataSharingConfirmation.CurrentStatus.StatusList}
    >
      {statuses.map(
        (eachStatus: Exclude<Status, Status.Pending>, idx: number) => (
          <ListRow
            key={eachStatus}
            label={statusLabel[eachStatus]}
            labelFontWeight="semiBold"
            caption={statusSubText[eachStatus]}
            rightIconName={selectedStatus === eachStatus ? 'check' : undefined}
            rightIconFamilyName="fontAwesome"
            onPress={() => setStatus(eachStatus)}
            useArrow={false}
            last={isLast(statuses, idx)}
          />
        ),
      )}
    </ListRowGroup>
  );
};

const Content: React.FC<{
  accountId: string;
  navigation: DataSharingModalStackNavigationProp<Screen.DATA_SHARING_CURRENT_STATUS>;
}> = ({ accountId, navigation }) => {
  const currentStatus = useRef<Status>();
  const [selectedStatus, setSelectedStatus] = useState<Status>();

  const { data, loading, error } = useGetBizaAccountByIdQuery({
    variables: { accountId },
    context: {
      sentryContext: {
        bizaAccountId: accountId,
        description: 'Fetching Biza Account By Id.',
      },
    },
  });

  useEffect(() => {
    if (data && !selectedStatus) {
      const account = getBizaAccount(data);
      if (account) {
        const { sharing_status: sharingStatus } = account;
        if (
          sharingStatus &&
          isKnownEnumValue(Biza_Account_Sharing_Status, sharingStatus)
        ) {
          const status: Status = AccountStatus[sharingStatus];
          setSelectedStatus(status);
          currentStatus.current = status;
        }
      }
    }
  }, [data, selectedStatus, setSelectedStatus, currentStatus]);

  const onPress = useCallback(() => {
    if (selectedStatus) {
      navigation.replace(Screen.DATA_SHARING_SUMMARY, {
        accountId,
      });
    }
  }, [accountId, navigation, selectedStatus]);

  useCancelButton(navigation);
  useNextButton(navigation, {
    onPress,
    disabled:
      !!selectedStatus &&
      !!currentStatus.current &&
      currentStatus.current === selectedStatus,
  });

  if (error)
    return (
      <ErrorRow
        message={t('Content.DataSharingPreferences.Error.FetchAccount')}
      />
    );

  if (loading)
    return (
      <Spinner testID={TestID.DataSharingConfirmation.CurrentStatus.Spinner} />
    );

  const account = getBizaAccount(data);

  if (!account) return <ErrorRow message="Account details not found." />;

  const { sharing_status: sharingStatus } = account;

  if (
    !sharingStatus ||
    !isKnownEnumValue(Biza_Account_Sharing_Status, sharingStatus)
  )
    return (
      <ErrorRow
        message={t('Content.DataSharingPreferences.Error.AccountNotFound')}
      />
    );

  const status: Status = selectedStatus || AccountStatus[sharingStatus];

  return (
    <AccountStatusList
      selectedStatus={status}
      setStatus={(newStatus: Status) => setSelectedStatus(newStatus)}
    />
  );
};

export const DataSharingCurrentStatus: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { accountId } = route.params;

  return (
    <ModalScreenContainer bg="bg" inset scrollable>
      <NavHeaderSpacer />
      <Content accountId={accountId} navigation={navigation} />
    </ModalScreenContainer>
  );
};
