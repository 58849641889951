import { useNavigation } from '@react-navigation/native';
import { A, Text } from 'dripsy';

import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { TextList } from '../../ui/v2/TextList';

const DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS = {
  rel: 'noreferrer',
  target: '_blank',
} as const;

export function YourConditionalApprovalDetails() {
  const navigation = useNavigation();
  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      headerText={t(
        'Content.ConditionalApproval.YourConditionalApprovalModal.Title',
      )}
      hideBackButton
    >
      <Text>
        {t(
          'Content.ConditionalApproval.YourConditionalApprovalModal.ContentCaption',
        )}
      </Text>
      <TextList
        listSx={{
          pl: '$8',
          pt: '$8',
        }}
        ordered={false}
        items={[
          t(
            'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemOne',
          ),
          t(
            'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemTwo',
          ),
          t(
            'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemThree',
          ),
          t(
            'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFour',
          ),
        ]}
      />
      <TextList
        listSx={{
          pl: '$24',
          pb: '$8',
        }}
        ordered={false}
        items={[
          <Text key="conditional-approval-sub-item-one">
            {t(
              'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne1',
            )}

            <A
              variant="link"
              sx={{ cursor: 'pointer' }}
              nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
            >
              {t(
                'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne2',
              )}
            </A>
            {t(
              'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne3',
            )}
            <A
              href={t('Link.LmiBenefits')}
              variant="link"
              hrefAttrs={DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS}
            >
              {t(
                'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne4',
              )}
            </A>
            {t(
              'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne5',
            )}
            <A
              href={t('Link.ReferenceRates')}
              variant="link"
              hrefAttrs={DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS}
            >
              {t(
                'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne6',
              )}
            </A>
            {t(
              'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemOne7',
            )}
          </Text>,
          t(
            'Content.ConditionalApproval.YourConditionalApprovalModal.CaptionItemFourSubItemTwo',
          ),
        ]}
      />
    </ModalScreenContainer>
  );
}
