import * as React from 'react';

export function createContext<A>(contextName: string) {
  const Context = React.createContext<A | undefined>(undefined);
  const useContext = () => {
    const context = React.useContext(Context);
    if (!context) {
      throw new Error(
        `use${contextName}Context must be inside a ${contextName}Provider with a value`,
      );
    }
    return context;
  };
  return [useContext, Context.Provider] as const;
}
