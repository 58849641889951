import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import { atom, useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import {
  Form,
  FormCurrencyInput,
  FormDatePicker,
  FormSelection,
} from '../../components/form/FormikInputs';
import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Screen } from '../../navigation/types/screens';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { FormNavButtonHandler } from '../../utils/FormNavButtonHandler';
import {
  useCancelButton,
  useDoneButton,
} from '../../utils/hooks/useHeaderButton';
import { TransactionFilterFormValues } from '../components/types';
import { MOCK_CATEGORY_OPTIONS } from '../mockData';
import { TransactionModalNavigationProps } from '../navigation/types';

const isSubmitting = false;

export const transactionFilterAtom = atom<TransactionFilterFormValues>({
  key: 'transactionFilterState',
  default: {
    category: [],
    startDate: '',
    endDate: '',
    amountFrom: null,
    amountTo: null,
  },
});

export function TransactionFilter() {
  const navigation =
    useNavigation<TransactionModalNavigationProps<Screen.TRANSACTION_FILTER>>();
  const onSubmitForm = (val: TransactionFilterFormValues) => {
    setFilterDetail({
      ...val,
    });
    navigation.goBack();
  };

  const [filterDetail, setFilterDetail] = useRecoilState(transactionFilterAtom);

  const initialValues: TransactionFilterFormValues = {
    category: filterDetail.category,
    startDate: filterDetail.startDate,
    endDate: filterDetail.endDate,
    amountFrom: filterDetail.amountFrom,
    amountTo: filterDetail.amountTo,
  };

  useCancelButton(navigation);
  useDoneButton(navigation);

  return (
    <ModalScreenContainer bg="bg" safeAreaBottom={false} scrollable>
      <NavHeaderSpacer />
      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {(formProps) => (
          <FormNavButtonHandler
            formProps={formProps}
            navigation={navigation}
            isSubmitting={isSubmitting}
            submitText={t('Content.Common.ButtonLabel.Done')}
          >
            <Form>
              <ListRowGroup
                headerText={t('Content.TransactionHistory.Category')}
              >
                <FormSelection
                  name="category"
                  tickPosition="right"
                  groupTestId={TestID.TransactionFilter.Category}
                  options={MOCK_CATEGORY_OPTIONS}
                  last
                  isMultiselect
                />
              </ListRowGroup>
              <ListRowGroup headerText={t('Content.TransactionHistory.Date')}>
                <FormDatePicker
                  name="startDate"
                  placeholder="DD/MM/YYYY"
                  label={t('Content.TransactionHistory.StartDate')}
                />
                <FormDatePicker
                  name="endDate"
                  placeholder="DD/MM/YYYY"
                  label={t('Content.TransactionHistory.EndDate')}
                  last
                />
              </ListRowGroup>
              <ListRowGroup headerText={t('Content.TransactionHistory.Amount')}>
                <FormCurrencyInput
                  name="amountFrom"
                  label={t('Content.TransactionHistory.AmountFrom')}
                  placeholder={t('Content.Common.Placeholder.Optional')}
                  textAlign="right"
                />
                <FormCurrencyInput
                  name="amountTo"
                  label={t('Content.TransactionHistory.AmountTo')}
                  placeholder={t('Content.Common.Placeholder.Optional')}
                  textAlign="right"
                />
              </ListRowGroup>
            </Form>
          </FormNavButtonHandler>
        )}
      </Formik>
    </ModalScreenContainer>
  );
}
