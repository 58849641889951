// Current version of react-native-reanimated broke only after built
// This can be tested by rendering a component that uses MotiView from moti
// in storybook as moti internally depends on reanimated and storybook uses
// the built code.
// Upstream fix has been done but not yet released (this https://github.com/software-mansion/react-native-reanimated/pull/3418)
// Until then, we need to apply the fix manually here.
// Details: https://github.com/software-mansion/react-native-reanimated/issues/3355#issuecomment-1207792018
if (
  'browser' in process &&
  (process as unknown as { browser: boolean }).browser
) {
  // eslint-disable-next-line no-underscore-dangle
  window._frameTimestamp = null;
}
