import {
  NavigationProp,
  ParamListBase,
  useRoute as useRouteBase,
} from '@react-navigation/native';
import {
  Header,
  StackHeaderProps,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { Pressable, useDripsyTheme, View } from 'dripsy';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { Keyboard, View as RNView } from 'react-native';

import { TestID } from '../../../testID/constants';
import { BackButton } from '../../components/BackButton';
import { Box } from '../../ui/atoms/Box';
import {
  HeaderButton,
  Props as HeaderButtonProps,
} from '../../ui/atoms/HeaderButton';
import { Spinner } from '../../ui/atoms/Spinner';
import { ArrowBackIcon } from '../../ui/svgs/ArrowBackIcon';
import { CloseIcon } from '../../ui/svgs/CloseIcon';
import { useTheme } from '../../ui/theme';
import { isWeb } from '../platformUtils';
import { capitalize } from '../stringHelpers';

type UseHeaderButtonOpts = Partial<HeaderButtonProps> & {
  loading?: boolean;
  /** When `displayNone` sets to `true`, `HeaderButton` will not be rendered. Whilst `hidden` only hide `HeaderButton`, but it's still got rendered */
  displayNone?: boolean;
};

type UseCloseButtonOpts = {
  // Allows for custom navigation path when pressed
  navigateTo?: () => void;
};

export function useCloseButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  opts?: UseHeaderButtonOpts & UseCloseButtonOpts,
): void {
  useHeaderButton(navigation, {
    position: 'left',
    text: 'Close',
    onPress: opts?.navigateTo ? opts.navigateTo : navigation.goBack,
    ...opts,
  });
}

/**
 * `useCancelButton` is only intended for usage in Modal screen
 */
export function useCancelButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  opts?: UseHeaderButtonOpts,
): void {
  const onEscapeButtonPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        navigation.goBack();
        event.stopImmediatePropagation();
      }
    },
    [navigation],
  );

  useEffect(() => {
    if (!isWeb || document == null) {
      return undefined;
    }
    document.addEventListener('keydown', onEscapeButtonPress, false);

    return () => {
      document.removeEventListener('keydown', onEscapeButtonPress, false);
    };
  }, [onEscapeButtonPress]);
  useHeaderButton(navigation, {
    position: 'left',
    text: 'Cancel',
    iconName: 'close',
    onPress: navigation.goBack,
    ...opts,
  });
}

export function useHideBackButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  shouldHide = true,
): void {
  const theme = useTheme();
  useEffect(() => {
    navigation.setOptions({
      headerLeft: shouldHide ? () => null : BackButton,
    });
  }, [navigation, shouldHide, theme]);
}

export function useSetHeaderShown<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  shown: boolean,
): void {
  useEffect(() => {
    navigation.setOptions({
      headerShown: shown,
    });
  }, [navigation, shown]);
}

export function useDoneButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  opts?: UseHeaderButtonOpts,
): void {
  useHeaderButton(navigation, {
    position: 'right',
    text: 'Done',
    onPress: navigation.goBack,
    ...opts,
  });
}

export function useNextButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  opts?: UseHeaderButtonOpts,
): void {
  useHeaderButton(navigation, {
    position: 'right',
    text: 'Next',
    onPress: navigation.goBack,
    ...opts,
  });
}

export function useSubmitButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  { text, disabled, onPress, ...opts }: UseHeaderButtonOpts,
): void {
  const onPressWrapped = useCallback(() => {
    Keyboard.dismiss();
    onPress?.();
  }, [onPress]);

  useHeaderButton(navigation, {
    position: 'right',
    text,
    disabled,
    onPress: onPressWrapped,
    ...opts,
  });
}

export function useHeaderButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  optionParams?: UseHeaderButtonOpts,
  navigationOptions?: StackNavigationOptions,
): void {
  const opts = optionParams || {};
  const ariaLabel = opts['aria-label'];

  const type = opts.position || 'left';
  const headerType = `header${capitalize(type)}`;
  const theme = useTheme();
  const route = useRouteBase();
  useEffect(() => {
    if (!opts.displayNone) {
      navigation.setOptions({
        [headerType]: () =>
          opts.loading ? (
            <Box px="m">
              <Spinner testID={TestID.HeaderButton.Spinner} />
            </Box>
          ) : (
            <HeaderButton
              testID={opts.testID || `${opts.text}-${route.name}`}
              position={type}
              text={opts.text}
              iconName={opts.iconName}
              disabled={opts.disabled}
              onPress={opts.onPress}
              iconFamily={opts.iconFamily}
              iconSize={opts.iconSize}
              iconRoundedBg={opts.iconRoundedBg}
              iconBg={opts.iconBg}
              color={opts.color}
              hidden={opts.hidden}
              style={{
                paddingHorizontal: theme.spacing.m,
              }}
              height={opts.height}
              width={opts.width}
              textStyle={opts.textStyle}
              aria-label={ariaLabel}
            />
          ),
        ...navigationOptions,
      });
    } else {
      navigation.setOptions({
        [headerType]: undefined,
        ...navigationOptions,
      });
    }
  }, [
    navigation,
    opts.text,
    type,
    headerType,
    opts.onPress,
    opts.iconName,
    opts.iconFamily,
    opts.iconSize,
    opts.iconRoundedBg,
    opts.hidden,
    opts.disabled,
    theme.spacing.m,
    opts.loading,
    navigationOptions,
    opts.displayNone,
    route.name,
    opts.testID,
    opts.iconBg,
    opts.color,
    opts.height,
    opts.width,
    opts.textStyle,
    ariaLabel,
  ]);
}

export const xButtonHeaderOptions = {
  position: 'right',
  iconName: 'close',
  iconSize: 'm',
  iconBg: 'buttonSecondaryBg',
  iconRoundedBg: true,
  color: 'secondaryContent',
} as const;

export function useXButton<T extends NavigationProp<ParamListBase>>(
  navigation: T,
  opts?: UseHeaderButtonOpts & { hideBackButton?: boolean },
): void {
  const hideBackButton = opts?.hideBackButton ?? true;
  useHideBackButton(navigation, hideBackButton);
  const route = useRouteBase();
  useHeaderButton(navigation, {
    testID: `Close-${route.name}`,
    ...xButtonHeaderOptions,
    onPress: navigation.goBack,
    ...opts,
  });
}

// UI V2 //
export function useModalHeaderButtonV2<
  T extends NavigationProp<ParamListBase>,
>({
  onClose,
  onBack,
  hideBackButton,
  headerRef,
  navigation,
  disableCloseButton,
  isCurrentScreenFocused,
}: {
  onClose?: () => void;
  onBack?: () => void;
  navigation: T;
  hideBackButton?: boolean;
  headerRef?: MutableRefObject<RNView | null>;
  disableCloseButton?: boolean;
  isCurrentScreenFocused?: boolean;
}) {
  const { theme: themeV2 } = useDripsyTheme();
  useEffect(() => {
    navigation.setOptions({
      headerLeft: hideBackButton
        ? null
        : () => (
            <Pressable
              testID={TestID.ModalScreenContainer.BackButton}
              onPress={onBack ?? navigation.goBack}
              sx={{ marginLeft: '$16' }}
              aria-hidden={!isCurrentScreenFocused}
            >
              <ArrowBackIcon size={themeV2.iconSizes.$arrowBack} />
            </Pressable>
          ),
      headerRight: () =>
        onClose ? (
          <Pressable
            onPress={onClose}
            testID={TestID.ModalScreenContainer.CloseButton}
            sx={{ marginRight: '$16' }}
            disabled={disableCloseButton}
            aria-hidden={!isCurrentScreenFocused}
            aria-label={t('Content.Accessibility.Modal.CloseModalButton')}
            role="button"
          >
            <CloseIcon size={themeV2.iconSizes.$close} />
          </Pressable>
        ) : null,

      header: (props: StackHeaderProps) => (
        <View ref={headerRef}>
          <Header {...props} />
        </View>
      ),
    });
  }, [
    disableCloseButton,
    headerRef,
    hideBackButton,
    isCurrentScreenFocused,
    navigation,
    onBack,
    onClose,
    themeV2.iconSizes.$arrowBack,
    themeV2.iconSizes.$close,
  ]);
}
