import {
  backgroundColor,
  backgroundColorShorthand,
  BaseTheme,
  border,
  createRestyleComponent,
  layout,
  opacity,
  position,
  ResponsiveValue,
  shadow,
  spacingShorthand,
  visible,
} from '@shopify/restyle';
import * as React from 'react';
import { View } from 'react-native';

import { Theme } from '../theme';
import {
  BackgroundColorProps,
  BorderProps,
  LayoutProps,
  OpacityProps,
  PositionProps,
  ShadowProps,
  SpacingProps,
  VisibleProps,
} from '../types';

export type ResponsiveProps<Theme extends BaseTheme, Props> = {
  [P in keyof Props]?: ResponsiveValue<Props[P], Theme>;
};
export type BoxStyleProps = BackgroundColorProps &
  OpacityProps &
  VisibleProps &
  LayoutProps &
  SpacingProps &
  BorderProps &
  ShadowProps &
  PositionProps;
export const boxRestyleFunctions = [
  backgroundColor,
  backgroundColorShorthand,
  opacity,
  visible,
  layout,
  spacingShorthand,
  border,
  shadow,
  position,
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createBox<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends React.ComponentType<any> = typeof View,
>(BaseComponent?: Component) {
  return createRestyleComponent<
    BoxStyleProps &
      Omit<
        React.ComponentProps<Component> & { children?: React.ReactNode },
        keyof BoxStyleProps
      > & {
        children?: React.ReactNode;
      },
    Theme
  >(boxRestyleFunctions, BaseComponent || View);
}
