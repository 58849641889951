import { Pressable, Text } from 'dripsy';
import { FormikProps } from 'formik';
import { useEffect, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormTextInputV2 } from '../../components/form/FormikInputs';
import { FormSpacer } from '../../components/form/FormSpacer';
import { AccountDropdown } from '../../HomeLoan/components/AccountDropdown';
import { ExternalAccount } from '../../HomeLoan/types';
import { Screen } from '../../navigation/types/screens';
import { FormikFormError } from '../../ui/v2/FormError';
import { AutopayChangeRequestFormV2, AutoPaySetupFormField } from '../types';

type AutopaySetupV2FromSectionProps = {
  screen: Screen;
  suggestionData: Array<ExternalAccount>;
  formProps: FormikProps<AutopayChangeRequestFormV2>;
};

export function AutopaySetupV2FromSection({
  screen,
  suggestionData,
  formProps,
}: AutopaySetupV2FromSectionProps) {
  const isManualInput =
    formProps.values[AutoPaySetupFormField.IsManualAccountInput];

  const isManualInputTouched =
    formProps.touched[AutoPaySetupFormField.IsManualAccountInput];

  const suggestionsCount = suggestionData.length;

  // Below is needed to avoid triggering the effect on formProps changes
  const formPropsRef = useRef(formProps);

  useEffect(() => {
    if (!isManualInputTouched) {
      formPropsRef.current.setFieldValue(
        AutoPaySetupFormField.IsManualAccountInput,
        !suggestionsCount,
      );
    }
  }, [isManualInputTouched, suggestionsCount]);

  const toggleIsManualInput = () => {
    formProps.setFieldValue(
      AutoPaySetupFormField.IsManualAccountInput,
      !isManualInput,
    );

    formProps.setTouched({
      ...formProps.touched,
      [AutoPaySetupFormField.ExternalAccountId]: false,
      [AutoPaySetupFormField.DirectDebitAccountName]: false,
      [AutoPaySetupFormField.DirectDebitAccountBsb]: false,
      [AutoPaySetupFormField.DirectDebitAccountNumber]: false,
    });
  };

  const heading = (
    <>
      <Text variant="sHeader" sx={{ mb: '$16' }}>
        {t('Content.AutopaySettings.FromAccount')}
      </Text>

      <Text variant="sBody" sx={{ mb: '$16' }}>
        {t('Content.AutopaySettings.FromAccountSupportingText')}
      </Text>
    </>
  );

  if (isManualInput) {
    return (
      <>
        {heading}

        <FormTextInputV2
          inputTestID={TestID.AutopaySetup.AccountNameInput}
          name={AutoPaySetupFormField.DirectDebitAccountName}
          label={t('Content.Withdraw.AccountName')}
          aria-required
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.AutopaySetup,
            screen,
            FieldInteractionKey.FromAccountName,
          )}
        />

        <FormikFormError
          name={AutoPaySetupFormField.DirectDebitAccountName}
          role="alert"
          sx={{ mt: '$8' }}
        />

        <FormSpacer />

        <FormTextInputV2
          inputTestID={TestID.AutopaySetup.AccountBsbInput}
          name={AutoPaySetupFormField.DirectDebitAccountBsb}
          label={t('Content.Withdraw.BSB')}
          aria-required
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.AutopaySetup,
            screen,
            FieldInteractionKey.FromAccountBsbNumer,
          )}
        />

        <FormikFormError
          name={AutoPaySetupFormField.DirectDebitAccountBsb}
          sx={{ mt: '$8' }}
          role="alert"
        />

        <FormSpacer />

        <FormTextInputV2
          inputTestID={TestID.AutopaySetup.AccountNumberInput}
          name={AutoPaySetupFormField.DirectDebitAccountNumber}
          label={t('Content.Withdraw.AccountNumber')}
          aria-required
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.AutopaySetup,
            screen,
            FieldInteractionKey.FromAccountNumber,
          )}
        />

        <FormikFormError
          name={AutoPaySetupFormField.DirectDebitAccountNumber}
          sx={{ mt: '$8' }}
          role="alert"
        />

        {suggestionsCount > 0 ? (
          <Pressable
            onPress={toggleIsManualInput}
            sx={{ mt: '$8' }}
            style={{ alignSelf: 'flex-start' }}
          >
            <Text variant="caption" sx={{ color: '$link' }}>
              {t('Content.Withdraw.BackToAccountList')}
            </Text>
          </Pressable>
        ) : null}
      </>
    );
  }

  return (
    <>
      {heading}

      <AccountDropdown
        inputTestID={TestID.AutopaySetup.AccountDropdownInput}
        containerTestID={TestID.AutopaySetup.AccountDropdownRowInput}
        suggestionsTestIDPrefix={TestID.AutopaySetup.AccountDropdownInput}
        name={AutoPaySetupFormField.ExternalAccountId}
        suggestionData={suggestionData}
        placeholder={t('Content.Withdraw.AccountSelect')}
        focusPlaceholder={t('Content.Withdraw.AccountSearch')}
        selectedAccountId={
          formProps.values[AutoPaySetupFormField.ExternalAccountId]
        }
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.AutopaySetup,
          screen,
          FieldInteractionKey.FromAccountList,
        )}
      />

      <FormikFormError
        name={AutoPaySetupFormField.ExternalAccountId}
        sx={{ mt: '$8' }}
      />

      <Pressable
        onPress={toggleIsManualInput}
        sx={{ mt: '$8' }}
        style={{ alignSelf: 'flex-start' }}
      >
        <Text variant="caption" sx={{ color: '$link' }}>
          {t('Content.Withdraw.AccountAdd')}
        </Text>
      </Pressable>
    </>
  );
}
