import { useHeaderHeight } from '@react-navigation/elements';
import { View } from 'react-native';

import { Separator } from '../ui/atoms/Separator';

export function NavHeaderSpacer() {
  const headerHeight = useHeaderHeight();
  return (
    <>
      <View style={{ height: headerHeight, width: '100%' }} />
      <Separator spacer />
    </>
  );
}
