import {
  backgroundColorShorthand,
  border,
  BorderProps,
  spacingShorthand,
  SpacingShorthandProps,
} from '@shopify/restyle';
import omitBy from 'lodash/omitBy';
import { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { RowRight } from '../atoms/RowRight';
import { StyledText } from '../atoms/StyledText';
import {
  Props as StyledTextInputProps,
  StyledTextInput,
} from '../atoms/StyledTextInput';
import { Row } from '../molecules/Row';
import { Theme } from '../theme';
import { InputRow, Props as InputRowProps } from './InputRow';

export type Props = Omit<InputRowProps, 'label' | 'ref' | 'noBorder'> &
  Omit<StyledTextInputProps, 'type' | 'label'> & {
    label: string;
    sublabels?: Array<string>;
    suffix?: string;
    inputTestID?: string;
    focused?: boolean;
  };

const INVALID_STYLED_TEXT_INPUT_RESTYLE_PROPS = {
  ...[...border, ...spacingShorthand]
    .map((x) => x.property)
    .reduce((acc, property) => {
      acc[property] = true;
      return acc;
    }, {} as { [key in keyof BorderProps<Theme>]: true } & { [key in keyof SpacingShorthandProps<Theme>]: true }),

  [backgroundColorShorthand.property]: true,
};

export function omitInvalidStyledTextInputProps(
  props: Omit<StyledTextInputProps, 'type' | 'label'>,
): Omit<StyledTextInputProps, 'type' | 'label'> {
  return omitBy(
    props,
    (_value, key) =>
      !!INVALID_STYLED_TEXT_INPUT_RESTYLE_PROPS[
        key as keyof typeof INVALID_STYLED_TEXT_INPUT_RESTYLE_PROPS
      ],
  );
}

export const InputField = forwardRef<RNTextInput, Props>(
  (
    {
      label,
      sublabels,
      suffix,
      last,
      value,
      inputTestID,
      testID,
      ...otherProps
    }: Props,
    ref,
  ) => {
    const hasValue = value != null && String(value) !== '';
    return (
      <InputRow
        pr="m"
        last={last}
        noBorder={last}
        testID={testID}
        {...otherProps}
      >
        <Row
          label={label}
          sublabels={sublabels}
          sublabelsColor="secondaryContent"
          last
          alignItems="center"
          flex={1}
        >
          <RowRight flex={1}>
            <StyledTextInput
              textAlign="right"
              testID={inputTestID}
              py={0}
              // `otherProps` are being spread into
              // `InputRow` and `StyledTextInput`.
              // This is bad because each components has different set
              // of valid props.
              // Worse case, this could cause a crash to native because a prop
              // intended for either one will be duplicated to both.
              // A proper fix would be to change `InputField` Props
              // to stop accepting both `InputRowProps` and `StyledTextInputProps`.
              // at top level.
              // `TextInputRow` also has the same problem, which has been
              // addressed by listing the known props and splitting
              // otherProps into two using the list to avoid duplicating
              // props.
              // TODO: Need better strategy on preventing bad duplicate props
              // being spread to multiple different components
              {...omitInvalidStyledTextInputProps(otherProps)}
              ref={ref}
              value={value ?? ''}
            />
          </RowRight>
          {/* Don't display the suffix when displaying the placeholder */}
          {/* TODO: Focus on the text input when the suffix is pressed. */}
          {hasValue && suffix ? (
            <StyledText alignSelf="center">{suffix}</StyledText>
          ) : null}
        </Row>
      </InputRow>
    );
  },
);

InputField.displayName = 'InputField';
