import { Box, BoxProps } from '../../atoms/Box';
import { StyledIcon } from '../../atoms/StyledIcon';
import { useTheme } from '../../theme';

export function PwaUnloanLogo() {
  const theme = useTheme();

  const logoProps: BoxProps = {
    borderStyle: 'solid',
    borderWidth: theme.constants.borderWidth,
    borderColor: 'border',
    p: 's',
    borderRadius: 'card',
    backgroundColor: 'buttonPrimaryLightBg',
  };

  return (
    <Box row {...logoProps}>
      <StyledIcon family="svg" name="unloanIcon" size="m" />
    </Box>
  );
}
