import { gql } from '@apollo/client';
import { ReactNode, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { useXaiDocumentLinkQuery } from '../../generated/graphql';
import { AppStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';
import { useCaptureException } from '../../utils/hooks/useCaptureException';
import { useCloseButton } from '../../utils/hooks/useHeaderButton';
import { formatBytes } from '../../utils/stringHelpers';
import { ImagePreview } from '../components/ImagePreview';
import { NoPreview } from '../components/NoPreview';
import { PdfPreview } from '../components/PdfPreview';

type Props = AppStackScreenProps<Screen.DOCUMENT_PREVIEW_MODAL>;

export const XaiDocumentLink = gql`
  query XaiDocumentLink($documentId: Int!, $loanApplicationId: String!) {
    xai_document_link(
      document_id: $documentId
      loan_application_id: $loanApplicationId
    )
  }
`;

function renderPreviewByMimeType(
  mimeType: string,
  fileName: string,
  link: string,
): ReactNode {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
      return <ImagePreview link={link} />;
    case 'application/pdf':
      return <PdfPreview link={link} fileName={fileName} />;
    default:
      return <NoPreview fileName={fileName} />;
  }
}

/**
 * We use react-navigation full screen modal with animation that slides
 * from below and make the screen looks like a page opened on top of
 * the current screen with offset from the top of the window.
 * But the screen height is still counted as if the top offset is zero.
 * To take account of the top offset in the window height,
 * we pad the bottom of the screen to avoid cut offs in the screen.
 */
const MODAL_SCREEN_BOTTOM_ADDITIONAL_PADDING = 10;

export function DocumentPreview({ navigation, route }: Props) {
  useCloseButton(navigation);
  const {
    loanApplicationId,
    // If file upload is not yet confirmed,
    // xaiDocumentId will be null even if the file has
    // been successfully uploaded.
    xaiDocumentId,
    fileName: fileNameParams,
    mimeType,
    fileSize: fileSizeParams,
  } = route.params || {};

  const fileName = fileNameParams || '--';
  const fileSize = fileSizeParams != null ? formatBytes(fileSizeParams) : '--';
  useEffect(() => {
    navigation.setOptions({
      title: t('Content.DocumentPreview.Title', { documentName: fileName }),
    });
  }, [fileName, navigation]);

  const hasMissingParams =
    loanApplicationId == null || xaiDocumentId == null || mimeType == null;

  const { data, error } = useXaiDocumentLinkQuery({
    variables: {
      documentId: xaiDocumentId || -1,
      loanApplicationId: loanApplicationId || '',
    },
    skip: hasMissingParams,
    context: {
      sentryContext: {
        loanApplicationId,
        xaiDocumentId,
        mimeType,
      },
    },
  });

  useCaptureException(
    'Failed to get XAI document link',
    route.params || null,
    error,
  );

  let content: ReactNode = <Spinner />;

  if (hasMissingParams) {
    content = (
      <StyledText variant="body">
        {t('Content.DocumentPreview.NoPreview', { documentName: fileName })}
      </StyledText>
    );
  } else if (data) {
    content = renderPreviewByMimeType(
      mimeType,
      fileName,
      data.xai_document_link,
    );
  } else if (error) {
    content = (
      <StyledText variant="body">
        {t('Content.DocumentPreview.Error', { documentName: fileName })}
      </StyledText>
    );
  }

  const insets = useSafeAreaInsets();
  const theme = useTheme();

  return (
    <ModalScreenContainer
      bg="bg"
      safeAreaBottom={false}
      scrollable={false}
      inset={false}
      containerStyle={styles.containerStyle}
    >
      <NavHeaderSpacer />
      <Box flexGrow={1} centered>
        {content}
      </Box>
      <Box
        row
        p="m"
        bg="shapeBg"
        centered
        justifyContent="space-between"
        style={{
          paddingBottom:
            theme.spacing.m +
            insets.bottom +
            MODAL_SCREEN_BOTTOM_ADDITIONAL_PADDING,
        }}
      >
        <Box centered pt="s" flex={1}>
          <StyledText variant="body" numberOfLines={1} lineBreakMode="middle">
            {fileName}
          </StyledText>
          <StyledText variant="caption" fontSize="2xs">
            {fileSize}
          </StyledText>
        </Box>
      </Box>
    </ModalScreenContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    justifyContent: 'space-between',
  },
});
