import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

export type Props =
  SingleModalStackScreenProps<Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL>;
export function UnableToVerifyYourIdentityViewMoreModal({
  navigation,
  route,
}: Props) {
  const navigateToManualIdentityVerification = () => {
    navigation.navigate(
      Screen.MANUAL_VERIFICATION_V2_MODAL,
      route.params ?? {},
    );
  };

  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      headerText={t('Consent.UnableToVerifyYourIdentity.Title')}
      scrollable
      hideBackButton
    >
      <Text>{t('Consent.UnableToVerifyYourIdentity.Body')}</Text>
      <Text variant="caption" sx={{ mt: '$24' }}>
        {t('Consent.UnableToVerifyYourIdentity.Caption')}{' '}
        <Text
          variants={['caption', 'link']}
          onPress={navigateToManualIdentityVerification}
          testID={TestID.UnableToVerifyYourIdentityModal.LearnMoreButton}
        >
          {t('Content.Common.ButtonLabel.LearnMore')}
        </Text>
      </Text>
    </ModalScreenContainer>
  );
}
