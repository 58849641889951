import { Formik } from 'formik';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { FormCurrencyInputV2 } from '../../components/form/FormikInputs';
import { SubmitButton } from '../../components/form/SubmitButton';
import {
  useGetWithdrawLimitQuery,
  useUpsertWithdrawLimitMutation,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { homeLoanSuccessMessageAtom } from '../../Home/screens/recoil/homeLoanSuccessMessage';
import { MAX_DAILY_WITHDRAW_LIMIT } from '../../HomeLoan/constants';
import { Screen } from '../../navigation/types/screens';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { FormikFormError } from '../../ui/v2/FormError';
import { LoadingInput } from '../../ui/v2/LoadingInput';
import { formatCurrency } from '../../utils/currencyHelpers';
import { SettingsScreenProps } from '../navigation/types';
import { settingsSuccessMessageAtom } from '../recoil/settingsSuccessMessage';

const limitLabel = formatCurrency(MAX_DAILY_WITHDRAW_LIMIT, {
  noFraction: true,
});

const withdrawalLimitValidationSchema = yup.object({
  withdrawalLimit: yup
    .number()
    .typeError(t('Content.Settings.DailyTransferLimit.AmountRequired'))
    .required(t('Content.Settings.DailyTransferLimit.AmountRequired'))
    .max(
      MAX_DAILY_WITHDRAW_LIMIT,
      t('Content.Settings.DailyTransferLimit.InvalidAmount', {
        limit: limitLabel,
      }),
    ),
});

type FormValues = yup.Asserts<typeof withdrawalLimitValidationSchema>;

type BaseDailyTransferLimitProps = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  onPressBack: () => void;
  isError?: boolean;
  getWithdrawLimitLoading?: boolean;
  upsertWithdrawLimitLoading?: boolean;
};

export function BaseDailyTransferLimit({
  initialValues,
  onSubmit,
  onPressBack,
  isError,
  getWithdrawLimitLoading,
  upsertWithdrawLimitLoading,
}: BaseDailyTransferLimitProps) {
  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader title={t('Content.Settings.DailyTransferLimit.Title')} />

      {isError ? (
        <ErrorRow
          message={t('Content.Common.Error.UnableToProcessRequest')}
          mb="s"
        />
      ) : null}

      <Formik
        onSubmit={onSubmit}
        validationSchema={withdrawalLimitValidationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formProps) => (
          <>
            {getWithdrawLimitLoading ? <LoadingInput /> : null}
            {!getWithdrawLimitLoading && (
              <FormCurrencyInputV2
                inputTestID={TestID.Settings.DailyTransferLimit.AmountInput}
                label={t('Content.Settings.DailyTransferLimit.Amount')}
                keyboardType="decimal-pad"
                name="withdrawalLimit"
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.DailyTransferLimit,
                  Screen.SETTINGS_DAILY_TRANSFER_LIMIT,
                  FieldInteractionKey.Amount,
                )}
              />
            )}
            <FormikFormError name="withdrawalLimit" sx={{ mt: '$8' }} />
            <StyledText variant="caption" mt="s">
              {t('Content.Settings.DailyTransferLimit.MaxLimit', {
                limit: limitLabel,
              })}
            </StyledText>

            <Separator spacer py="m" />

            <SubmitButton
              testID={TestID.Settings.DailyTransferLimit.SubmitButton}
              label={t('Content.Common.ButtonLabel.Done')}
              onPress={() => formProps.handleSubmit()}
              showSpinner={upsertWithdrawLimitLoading}
              disabled={getWithdrawLimitLoading || upsertWithdrawLimitLoading}
            />
          </>
        )}
      </Formik>

      <Separator spacer />
    </UpliftScreenContainer>
  );
}

type DailyTransferLimitProps =
  SettingsScreenProps<Screen.SETTINGS_DAILY_TRANSFER_LIMIT>;

function DailyTransferLimit({ navigation, route }: DailyTransferLimitProps) {
  const { data, loading: getWithdrawLimitLoading } = useGetWithdrawLimitQuery();
  const [
    upsertWithdrawLimit,
    {
      loading: upsertWithdrawLimitLoading,
      error: upsertWithdrawLimitError,
      called: upsertWithdrawLimitCalled,
    },
  ] = useUpsertWithdrawLimitMutation();

  const setSettingsSuccessMessageAtom = useSetRecoilState(
    settingsSuccessMessageAtom,
  );

  const setHomeLoanSuccessMessageAtom = useSetRecoilState(
    homeLoanSuccessMessageAtom,
  );

  const isError = upsertWithdrawLimitCalled && !!upsertWithdrawLimitError;

  const currentWithdrawalLimit = Number(
    data?.me?.[0]?.user?.identity_profile?.daily_withdraw_limit?.value ?? 0,
  );

  const initialValues: FormValues = {
    withdrawalLimit: currentWithdrawalLimit,
  };

  const onSubmit = async ({ withdrawalLimit }: FormValues) => {
    if (currentWithdrawalLimit === withdrawalLimit) {
      navigation.goBack();
    } else {
      const response = await upsertWithdrawLimit({
        variables: { withdraw_limit: withdrawalLimit },
        context: {
          sentryContext: {
            withdrawalLimit,
          },
        },
      });

      if (!response.errors?.length) {
        const message = t(
          'Content.Settings.DailyTransferLimit.SuccessMessage',
          {
            limit: formatCurrency(withdrawalLimit, { noFraction: true }),
          },
        );

        if (route.params?.previousScreen === Screen.HOME_LOAN) {
          setHomeLoanSuccessMessageAtom(message);
        } else {
          setSettingsSuccessMessageAtom(message);
        }

        navigation.goBack();
      }
    }
  };

  return (
    <BaseDailyTransferLimit
      initialValues={initialValues}
      onSubmit={onSubmit}
      onPressBack={() => navigation.goBack()}
      isError={isError}
      getWithdrawLimitLoading={getWithdrawLimitLoading}
      upsertWithdrawLimitLoading={upsertWithdrawLimitLoading}
    />
  );
}

export const WithAuthenticationDailyTransferLimit =
  withAuthenticationRequired(DailyTransferLimit);
