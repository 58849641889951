import { useCallback } from 'react';
import * as React from 'react';
import { Image as RNImage, StyleSheet } from 'react-native';
import { Marker, StaticGoogleMap } from 'react-static-google-map';

import { GOOGLE_MAPS_API_KEY } from '../Address/GoogleMapsApiCommon';

export type MapSize = { width: number; height: number };
export type Location = { latitude: number; longitude: number };

type Props = {
  location: Location | string;
  size: MapSize;
  zoom?: number;
  scale?: React.ComponentProps<typeof StaticGoogleMap>['scale'];
  onError?: () => void;
};

// Hide point of interest from the map to reduce the noise.
// Styling docs: https://developers.google.com/maps/documentation/maps-static/styling?hl=en_US
const MAP_STYLE = 'feature:poi|visibility:off';
const encodedMapStyle = encodeURIComponent(MAP_STYLE);
// This inverted style will revert all color from the map.
// For now, we will be using this until we get a proper styling for the dark mode map.
const INVERT_LIGHTNESS_MAP_STYLE = `${encodedMapStyle}&style=invert_lightness:true`;

const MARKER_ICON_URL =
  'https://uploads-ssl.webflow.com/6213e151e80699c74710709e/644f4e31edcce41d070aa33e_pin.png';

export function StaticMapView({ size, location, zoom, scale, onError }: Props) {
  // TODO: StaticMapView should respect selected color theme
  const isDarkMode = false;
  const usingLabelLocation = typeof location === 'string';

  const MapImage = useCallback(
    (prop: { src?: string }) => {
      if (!prop.src) {
        return null;
      }
      const decodeSrc = decodeURI(prop.src);

      return (
        <RNImage
          source={{ uri: decodeSrc }}
          style={StyleSheet.absoluteFill}
          onError={onError}
        />
      );
    },
    [onError],
  );

  return (
    <StaticGoogleMap
      size={formatSizeForStaticGoogleMap(size)}
      apiKey={GOOGLE_MAPS_API_KEY}
      style={isDarkMode ? INVERT_LIGHTNESS_MAP_STYLE : MAP_STYLE}
      as={MapImage}
      center={usingLabelLocation ? location : undefined}
      zoom={zoom}
      scale={scale}
      onError={onError}
    >
      {/*
        The iconURL must point to a publicly available GIF, JPEG or PNG image.
        Using an image from the project assets will not work in local dev as Google cannot access the image.
        Icon format:
          - Icon images may be in PNG, JPEG or GIF formats, though PNG is recommended.
          - Icons may be up to 4096 pixels maximum size (64x64 for square images).
        See https://developers.google.com/maps/documentation/maps-static/start#CustomIcons

        Image https://uploads-ssl.webflow.com/6213e151e80699c74710709e/644f4e31edcce41d070aa33e_pin.png is stored in Webflow.
       */}
      <Marker
        location={
          usingLabelLocation
            ? location
            : formatLocationForStaticGoogleMap(location)
        }
        color="black"
        iconURL={MARKER_ICON_URL}
      />
    </StaticGoogleMap>
  );
}

function formatSizeForStaticGoogleMap(size: MapSize) {
  // The size must be integer, float value will make the API return HTTP 400.
  return `${Math.ceil(size.width)}x${Math.ceil(size.height)}`;
}

function formatLocationForStaticGoogleMap(location: Location) {
  return `${location.latitude},${location.longitude}`;
}
