import { useMemo } from 'react';

import {
  Frequency_Enum,
  Loan_Application_Type_Enum,
} from '../../generated/graphql';
import {
  formatCurrency,
  formatCurrencyWithPeriod,
} from '../../utils/currencyHelpers';
import { joinListOfString, titleCase } from '../../utils/stringHelpers';
import { LoanDetailsSectionProps } from './types';
import { LoanDetailsDefault } from './YourUnloanCardLoanDetailsDefault';
import { LoanDetailsTopUps } from './YourUnloanCardLoanDetailsTopUps';

export function LoanDetailsSection(props: LoanDetailsSectionProps) {
  const {
    propertyPurpose,
    loanInterestRateType,
    feesAndBufferAmount,
    feesAndBufferAmountLoading,
    bufferAmount,
    oldCashoutAmount,
    cashoutList,
    minRepaymentAmount,
    minRepaymentCalcError,
    minRepaymentLoading,
    loanApplicationType,
  } = props;

  const productName = useMemo(() => {
    /**
     * Ideally this should use `target_product_rate.product_type`
     * instead of combining `security.property_purpose` and
     * `target_product_rate.loan_interest_rate_type`.
     *
     * For now we're keeping this implementation to avoid making changes
     * to top up and refi.
     * Purchase already use the product type implementation and
     * is implemented in `PurchaseYourUnloanCard`.
     */
    if (propertyPurpose && loanInterestRateType) {
      const propertyPurposeText = t(
        `Content.YourUnloan.LoanCard.PropertyPurpose.${propertyPurpose}`,
      );

      const loanInterestRateTypeText = t(
        `Content.YourUnloan.LoanCard.InterestRateType.${loanInterestRateType}`,
      );

      return `${propertyPurposeText} ${loanInterestRateTypeText}`;
    }
    return '--';
  }, [propertyPurpose, loanInterestRateType]);

  const cashoutDescription = useMemo(() => {
    const hasOldCashout = (oldCashoutAmount || 0) > 0;
    const newCashoutList =
      cashoutList?.filter((i) => i.amount && i.amount > 0) ?? [];
    const hasCashout = hasOldCashout || newCashoutList.length > 0;

    if (!hasCashout) {
      return null;
    }

    if (oldCashoutAmount != null && oldCashoutAmount > 0) {
      return t('Content.YourUnloan.LoanCard.TotalLoanAmount.CashOut', {
        cashoutReason: joinListOfString(
          (cashoutList ?? []).map(({ reason }) =>
            titleCase(
              t(
                `Content.YourUnloan.LoanCard.TotalLoanAmount.CashoutReason.${reason}`,
              ),
            ),
          ),
        ),
        cashoutAmount: formatCurrency(oldCashoutAmount),
      });
    }

    return newCashoutList
      .map((i) =>
        t('Content.YourUnloan.LoanCard.TotalLoanAmount.CashOut', {
          cashoutReason: t(
            `Content.YourUnloan.LoanCard.TotalLoanAmount.CashoutReason.${i.reason}`,
          ),
          cashoutAmount: formatCurrency(i.amount),
        }),
      )
      .join('; ');
  }, [cashoutList, oldCashoutAmount]);

  const feesAndBufferCaption: string = useMemo(() => {
    if (bufferAmount === 0) {
      return t('Content.YourUnloan.LoanCard.TotalLoanAmount.Fees', {
        feesAmount: feesAndBufferAmountLoading
          ? '--'
          : formatCurrency(feesAndBufferAmount),
      });
    }

    return t('Content.YourUnloan.LoanCard.TotalLoanAmount.FeesAndBuffer', {
      feesAndBufferAmount: feesAndBufferAmountLoading
        ? '--'
        : formatCurrency(feesAndBufferAmount),
    });
  }, [bufferAmount, feesAndBufferAmount, feesAndBufferAmountLoading]);

  const minRepaymentText: string = useMemo(() => {
    if (minRepaymentLoading) {
      return '...';
    }
    if (minRepaymentCalcError == null && minRepaymentAmount != null) {
      return formatCurrencyWithPeriod(
        minRepaymentAmount,
        Frequency_Enum.Monthly,
      );
    }
    return t('Content.Common.X/Month', { x: '$--' });
  }, [minRepaymentLoading, minRepaymentCalcError, minRepaymentAmount]);

  const otherProps = {
    cashoutDescription,
    feesAndBufferCaption,
    productName,
    minRepaymentText,
  };

  if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
    return <LoanDetailsTopUps {...props} {...otherProps} />;
  }

  /**
   * If you're making changes to this default component
   * and don't see your changes reflected in the UI,
   * it might be because your application type is handled
   * elsewhere.
   * e.g. Purchase is handled using different screen
   * and card component.
   */
  return <LoanDetailsDefault {...props} {...otherProps} />;
}
