import { View } from 'dripsy';
import { MotiView } from 'moti';
import { ReactNode } from 'react';

import { chromaticIgnoreDataSet } from '../utils/chromatic';
import { isWeb } from '../utils/platformUtils';

export const FadeInAnimationPreset = {
  from: {
    translateY: -10,
    opacity: 0,
  },
  animate: {
    translateY: 0,
    opacity: 1,
  },
  transition: {
    type: 'timing' as const,
    delay: 200,
  },
};

export function BannerWrapper({ children }: { children: ReactNode }) {
  const content = (
    <MotiView {...FadeInAnimationPreset} dataSet={chromaticIgnoreDataSet}>
      {children}
    </MotiView>
  );

  if (isWeb) {
    return content;
  }

  return <View sx={{ width: '100%' }}>{content}</View>;
}
