import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function AddIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(16, 17, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 16 17" fill="none" {...props}>
      <Path
        d="M.863 9.816h6.274v6.274c0 .465.388.863.863.863a.88.88 0 00.873-.863V9.816h6.264A.871.871 0 0016 8.953a.88.88 0 00-.863-.873H8.873V1.816A.88.88 0 008 .953a.871.871 0 00-.863.863V8.08H.863A.88.88 0 000 8.953c0 .475.398.863.863.863z"
        fill={color}
      />
    </Svg>
  );
}
