import { useEffect, useRef, useState } from 'react';

function useCountInterval(interval: number, stopAfterCount?: number) {
  const [count, setCount] = useState(0);

  const shouldStop = useRef(false);
  if (stopAfterCount != null && count >= stopAfterCount) {
    shouldStop.current = true;
  }

  useEffect(() => {
    if (shouldStop.current) {
      return undefined;
    }
    const id = setTimeout(() => {
      setCount((c) => c + 1);
    }, interval);
    return () => clearTimeout(id);
  }, [count, interval]);

  return { currentCount: count };
}

export default useCountInterval;
