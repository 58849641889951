import { createStackNavigator } from '@react-navigation/stack';

import { BorrowerV2StackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { BorrowerDetailsV2 } from '../screens/BorrowerDetailsV2';
import { InviteCoBorrowerV2 } from '../screens/InviteCoBorrowerV2';

const BorrowerV2Stack = createStackNavigator<BorrowerV2StackParams>();
export function BorrowerV2Navigator() {
  return (
    <BorrowerV2Stack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
      <BorrowerV2Stack.Screen
        name={Screen.BORROWER_V2_DETAILS}
        component={BorrowerDetailsV2}
        options={{
          title: getPageTitle(Screen.BORROWER_V2_DETAILS),
        }}
      />
      <BorrowerV2Stack.Screen
        name={Screen.BORROWER_V2_INVITE_COBORROWER}
        component={InviteCoBorrowerV2}
        options={{
          title: getPageTitle(Screen.BORROWER_V2_INVITE_COBORROWER),
        }}
      />
    </BorrowerV2Stack.Navigator>
  );
}
