import { Formik, FormikProps } from 'formik';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import * as yup from 'yup';

import { TestID } from '../../../testID/constants';
import {
  Form,
  FormAmountPicker,
  FormTextInput,
} from '../../components/form/FormikInputs';
import { PickerTrigger } from '../../components/form/PickerTrigger';
import { SubmitButton } from '../../components/form/SubmitButton';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { DEFAULT_UNLOAN_ACCOUNT_NAME } from '../../constants/unloan';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { bankIcon } from '../../ExternalAccount/components/AccountSelector';
import { useGetLoanAccountPropertyAddressQuery } from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { UnloanIcon } from '../../ui/svgs/UnloanIcon';
import { useTheme } from '../../ui/theme';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { formatCurrency } from '../../utils/currencyHelpers';
import { generateCensoredCreditCardNumber } from '../../utils/stringHelpers';
import { useLoanAccountState } from '../graphql/loanAccountQueries';
import { HomeLoanScreenProps } from '../navigation/types';
import { homeLoanWithdrawalDetailAtom } from '../recoil/homeLoanWithdrawalDetailAtom';
import { WithdrawalFormField } from '../types';

const INIT_VALS: {
  amount: number;
  external_account_id: string;
  description: string;
} = {
  [WithdrawalFormField.Amount]: 0,
  [WithdrawalFormField.ExternalAccountId]: '',
  [WithdrawalFormField.Description]: '',
};

type Props = HomeLoanScreenProps<Screen.HOME_LOAN_WITHDRAWAL>;

const validationSchema = yup.object({
  [WithdrawalFormField.ExternalAccountId]: yup
    .string()
    .uuid()
    .required(t('Content.Withdraw.Error.AccountRequired')),
  [WithdrawalFormField.Amount]: yup
    .number()
    .moreThan(0, t('Content.Withdraw.Error.AmountRequired'))
    .required(t('Content.Withdraw.Error.AmountRequired')),
  [WithdrawalFormField.Description]: yup
    .string()
    .required(t('Content.Withdraw.Error.DescriptionRequired')),
});

type WithdrawalFormValues = yup.Asserts<typeof validationSchema>;

const AccountSelectorControl = ({
  onPress,
  formProps,
  ...otherProps
}: Pick<
  ComponentProps<typeof ListRowGroup>,
  'footerErrorMessage' | 'footerErrorMessageTestID'
> & {
  onPress: () => void;
  formProps: FormikProps<typeof INIT_VALS>;
}) => {
  const selectedAccount = useRecoilValue(selectedExternalAccountAtom);

  const theme = useTheme();

  const formRef = useRef(formProps);

  useEffect(() => {
    formRef.current.setFieldValue(
      'external_account_id',
      selectedAccount?.externalAccountId,
    );
    formRef.current.setFieldValue(
      'target_account_number',
      selectedAccount?.accountDetails,
    );
  }, [selectedAccount]);

  return (
    <ListRowGroup
      mx={0}
      headerText={t('Content.Withdraw.ToSection')}
      uppercaseHeader
      {...otherProps}
    >
      {selectedAccount ? (
        <ListRow
          onPress={onPress}
          label={selectedAccount.name}
          caption={selectedAccount.accountDetails}
          useArrow
          last
          left={bankIcon(selectedAccount, selectedAccount.type, theme.sizes.m)}
        />
      ) : (
        <PickerTrigger
          onPress={onPress}
          placeholder={t('Content.Withdraw.AccountSelect')}
          placeholderColor="secondaryContent"
          hasArrow
          last
        />
      )}
    </ListRowGroup>
  );
};

export function HomeLoanWithdrawal({ navigation, route }: Props) {
  const resetSelectedExternalAccount = useResetRecoilState(
    selectedExternalAccountAtom,
  );
  const cbaAccountId = route.params.cbaAccountId || '';

  const onClose = useCallback(() => {
    resetSelectedExternalAccount();
    navigation.goBack();
  }, [navigation, resetSelectedExternalAccount]);

  const setHomeLoanWithdrawDetail = useSetRecoilState(
    homeLoanWithdrawalDetailAtom,
  );

  const [showValidationError, setShowValidationError] = useState(false);

  const handleSubmit = useCallback(
    ({ ...values }: typeof INIT_VALS) => {
      setHomeLoanWithdrawDetail({
        cba_account_id: cbaAccountId,
        ...values,
      });
      navigation.navigate(ActionSheetType.WITHDRAW_CONFIRMATION, {
        cbaAccountId,
      });
    },
    [navigation, setHomeLoanWithdrawDetail, cbaAccountId],
  );

  const makeOnTransferPress =
    ({ isValid, submitForm }: FormikProps<WithdrawalFormValues>) =>
    () => {
      // Formik immediately validate on mount.
      // Want to show error only after submit.
      setShowValidationError(false);
      if (!isValid) {
        setShowValidationError(true);
        return;
      }
      submitForm();
    };

  const {
    loading: loanAccountLoading,
    data: loanAccount,
    error,
    refetch,
  } = useLoanAccountState(route.params.cbaAccountId);

  const { loading: propertyAddressLoading, data: propertyAddress } =
    useGetLoanAccountPropertyAddressQuery({
      variables: { cba_account_id: cbaAccountId },
      context: {
        sentryContext: {
          cbaAccountId,
        },
      },
    });

  const loading = loanAccountLoading || propertyAddressLoading;

  const availableRedrawAmount =
    loanAccount?.balances?.available_redraw_balance ?? 0;

  const propertyDisplayAddress =
    propertyAddress?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.property?.address?.short_address_format;

  const unloanAccountName =
    (propertyDisplayAddress || loanAccount?.settings?.name) ??
    DEFAULT_UNLOAN_ACCOUNT_NAME;

  const unloanAccountNumber = loanAccount?.settings?.account_number ?? '';

  return (
    <ModalScreenContainer
      headerText={t('Content.Withdraw.Transfer')}
      onClose={onClose}
      scrollable
      hideBackButton
      loading={loading}
    >
      <ScreenLoadingContainer loading={loading}>
        <ScreenErrorFallback
          error={error}
          refetch={refetch}
          displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
        >
          <Formik
            initialValues={INIT_VALS}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formProps) => (
              <Form>
                <ListRowGroup
                  mx={0}
                  footerErrorMessage={
                    showValidationError
                      ? formProps.errors[WithdrawalFormField.Amount]
                      : undefined
                  }
                  footerErrorMessageTestID={TestID.Withdraw.AmountRequiredError}
                >
                  <FormAmountPicker
                    name={WithdrawalFormField.Amount}
                    caption={t('Content.Withdraw.AmountAvailable', {
                      amount: formatCurrency(availableRedrawAmount, {
                        noFraction: true,
                      }),
                    })}
                    maximumValue={availableRedrawAmount}
                  />
                </ListRowGroup>
                <Separator spacer />
                <FromSection
                  accountName={unloanAccountName}
                  accountNumber={unloanAccountNumber}
                />
                <Separator spacer />
                <AccountSelectorControl
                  formProps={formProps}
                  onPress={() => {
                    navigation.navigate(Screen.HOME_LOAN_WITHDRAWAL_TO, {
                      cbaAccountId: route.params.cbaAccountId,
                    });
                  }}
                  footerErrorMessage={
                    showValidationError &&
                    /**
                     * Formik workaround:
                     * <AccountSelectorControl> set the form value directly using
                     * setFieldValue().
                     * setFieldValue() third argument can force revalidate
                     * the form after setting the value.
                     * But, it does not work in this case.
                     * Hence, we need to manually hide the error by checking the
                     * field value here.
                     */
                    !formProps.values[WithdrawalFormField.ExternalAccountId]
                      ? formProps.errors[WithdrawalFormField.ExternalAccountId]
                      : null
                  }
                  footerErrorMessageTestID={
                    TestID.Withdraw.AccountRequiredError
                  }
                />
                <Separator spacer />
                <ListRowGroup
                  mx={0}
                  headerText={t('Content.Withdraw.DescriptionSection')}
                  uppercaseHeader
                  footerErrorMessage={
                    showValidationError
                      ? formProps.errors[WithdrawalFormField.Description]
                      : null
                  }
                  footerErrorMessageTestID={
                    TestID.Withdraw.DescriptionRequiredError
                  }
                >
                  <FormTextInput
                    noBorder
                    name={WithdrawalFormField.Description}
                    placeholder={t('Content.Common.Placeholder.Required')}
                    displayError={false}
                    alphaNumericOnly
                    textInputProps={{
                      maxLength: 100,
                    }}
                  />
                </ListRowGroup>
                <StyledText variant="caption" m="l" mx={0}>
                  {t('Content.Withdraw.ConfirmCaption')}
                </StyledText>
                <SubmitButton
                  testID={TestID.Withdraw.TransferButton}
                  label={t('Content.Withdraw.Transfer')}
                  onPress={makeOnTransferPress(formProps)}
                />
              </Form>
            )}
          </Formik>
        </ScreenErrorFallback>
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
}

type FromSectionProps = {
  accountName: string;
  accountNumber: string;
};

function FromSection({ accountName, accountNumber }: FromSectionProps) {
  const theme = useTheme();
  return (
    <ListRowGroup
      mx={0}
      headerText={t('Content.Withdraw.FromSection')}
      uppercaseHeader
    >
      <ListRow
        label={accountName}
        caption={generateCensoredCreditCardNumber(accountNumber.slice(-4))}
        last
        left={<UnloanIcon size={theme.sizes.m} />}
      />
    </ListRowGroup>
  );
}
