import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export function ContractIcon(props: SvgProps) {
  const id = useSvgIdPrefix('ContractIcon');
  return (
    <Svg width={27} height={33} fill="none" {...props}>
      <Path
        fill={`url(#${id})`}
        d="M27 9.773v21.33c0 .944-.784 1.708-1.749 1.708H2.5c-.965 0-1.749-.764-1.749-1.708V2.093c0-.943.784-1.708 1.749-1.708h14.877l2.625 6.828L27 9.773Z"
      />
      <Path
        fill="#A3A3A3"
        d="M17.376.385v7.68c0 .943.78 1.708 1.749 1.708H27L17.376.385Z"
      />
      <Path
        fill="#000"
        d="M21.314 16.598H6.437A.433.433 0 0 1 6 16.173v-.855a.43.43 0 0 1 .437-.425h14.877a.43.43 0 0 1 .436.425v.854a.433.433 0 0 1-.436.426ZM19.562 20.012H6.437A.434.434 0 0 1 6 19.584v-.851c0-.237.197-.43.437-.43h13.125c.242 0 .44.193.44.43v.85a.434.434 0 0 1-.44.43ZM21.314 23.423H6.437A.43.43 0 0 1 6 22.998v-.855c0-.233.197-.425.437-.425h14.877c.24 0 .436.192.436.425v.855a.43.43 0 0 1-.436.425ZM19.562 26.837H6.437A.433.433 0 0 1 6 26.412v-.855a.43.43 0 0 1 .437-.425h13.125c.242 0 .44.189.44.425v.855a.434.434 0 0 1-.44.425Z"
      />
      <Defs>
        <LinearGradient
          id={id}
          x1={0.75}
          x2={32.466}
          y1={0.385}
          y2={26.059}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
