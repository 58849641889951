import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function YourLiabilityHomeLoanIcon({
  color,
  ...otherProps
}: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('YourLiabilityHomeLoanIcon');

  return (
    <Svg width={31} height={31} viewBox="0 0 31 31" fill="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08984 13.4992V27.7478V27.7482V29.9977C2.08984 30.7589 2.64725 30.7545 3.37018 30.7487C3.43427 30.7482 3.49967 30.7476 3.56609 30.7476H27.186C27.2524 30.7476 27.3178 30.7482 27.3819 30.7487C28.1048 30.7545 28.6622 30.7589 28.6622 29.9977V27.7482V27.7478V13.4992L15.376 0.75L2.08984 13.4992Z"
        fill={`url(#${idPrefix}_paint0_linear_8859_3088)`}
      />
      <Path
        opacity="0.05"
        d="M15.3751 1.5C15.0252 1.5 14.6753 1.62599 14.3963 1.87723L10.4407 5.48524L2.08887 13.4992V17.5415L15.3751 5.57073L28.6613 17.5415V13.4992L20.3094 5.48524L16.3538 1.87723C16.0748 1.62599 15.7249 1.5 15.3751 1.5Z"
        fill="#2C2C2C"
      />
      <Path
        opacity="0.07"
        d="M15.3751 0.75C15.0252 0.75 14.6753 0.875242 14.3963 1.12498L10.4407 4.71274L2.08887 12.681V16.7L15.3751 4.79749L28.6613 16.7V12.681L20.3094 4.71274L16.3538 1.12498C16.0748 0.875242 15.7249 0.75 15.3751 0.75Z"
        fill="#2C2C2C"
      />
      <Path
        d="M30.5028 13.1294L16.3538 0.377225C16.0747 0.125992 15.7249 0 15.375 0C15.0251 0 14.6753 0.125992 14.3963 0.377225L0.247184 13.1294C-0.0628276 13.4099 -0.0842333 13.8943 0.199944 14.2018L1.40751 15.5105C1.67988 15.806 2.13382 15.8285 2.4335 15.5607L15.375 4.07073L28.3165 15.5615C28.6154 15.8285 29.0701 15.8067 29.3425 15.5112L30.5501 14.2026C30.8342 13.8943 30.8128 13.4099 30.5028 13.1294Z"
        fill="#A3A3A3"
      />
      <Path
        d="M10.9815 20.3392C10.0852 20.3392 9.36313 20.043 8.81561 19.4507C8.26771 18.8584 7.99414 18.0617 7.99414 17.0602C7.99414 15.9673 8.28547 15.1001 8.86813 14.4594C9.45117 13.8182 10.2288 13.498 11.2014 13.498C12.1329 13.498 12.8606 13.7879 13.3851 14.368C13.9096 14.9481 14.1718 15.7633 14.1718 16.8133C14.1718 17.8939 13.8816 18.7521 13.302 19.3869C12.7212 20.0214 11.9481 20.3392 10.9815 20.3392ZM20.0013 13.6808C20.0798 13.6808 20.1277 13.7672 20.0861 13.8338L12.0039 26.767C11.9856 26.7963 11.9536 26.814 11.9191 26.814H10.0108C9.93226 26.814 9.88437 26.7277 9.9259 26.6611L17.991 13.7279C18.0092 13.6986 18.0413 13.6808 18.0758 13.6808H20.0013ZM11.1481 15.1096C10.2927 15.1096 9.86493 15.7291 9.86493 16.9688C9.86493 18.1412 10.2723 18.7272 11.0866 18.7272C11.9182 18.7272 12.3343 18.1168 12.3343 16.8956C12.3346 15.7046 11.939 15.1096 11.1481 15.1096ZM18.8286 26.9972C17.9323 26.9972 17.2102 26.701 16.6623 26.1087C16.1144 25.5168 15.8408 24.7201 15.8408 23.7186C15.8408 22.6257 16.1321 21.7585 16.7152 21.1178C17.2978 20.4766 18.0759 20.156 19.0481 20.156C19.9852 20.156 20.7148 20.4431 21.2363 21.0173C21.7574 21.5911 22.0185 22.4036 22.0185 23.4535C22.0185 24.5342 21.7283 25.3955 21.1482 26.0366C20.5686 26.677 19.7951 26.9972 18.8286 26.9972ZM18.9691 21.768C18.1193 21.768 17.695 22.3875 17.695 23.6272C17.695 24.7996 18.108 25.3856 18.934 25.3856C19.766 25.3856 20.1817 24.7752 20.1817 23.554C20.1817 22.9865 20.0706 22.5466 19.848 22.235C19.6251 21.9231 19.3322 21.768 18.9691 21.768Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8859_3088`}
          x1="2.08984"
          y1="0.75"
          x2="31.8701"
          y2="27.1281"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
