import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const YourHomeLoanIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
    <G opacity="0.2">
      <Path d="M34.158 31.5H23.3171V36H34.158V31.5Z" fill={color} />
      <Path d="M12.6817 31.5H1.84082V36H12.6817V31.5Z" fill={color} />
    </G>
    <Path d="M23.4003 21.49H12.6003V35.9988H23.4003V21.49Z" fill={color} />
    <Path
      d="M18 0L0 16.8665L1.8 18.8614L18 3.71788L34.2 18.8614L36 16.8665L18 0Z"
      fill={color}
    />
    <Path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5 12.6501L27 8.43348V4.26196H31.5V12.6501Z"
      fill={color}
    />
    <Path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80103 18.8604L18.0001 3.71777L34.2001 18.8613L34.201 18.8603V31.4664H23.4022V21.4891H12.6022V31.4664H1.80103V18.8604Z"
      fill={color}
    />
  </Svg>
);
