import { atomFamily } from 'recoil';

import { Screen } from '../navigation/types/screens';

type Source = Screen | null;
type LoanApplicationId = string;
export const learnMoreReturnScreenAtom = atomFamily<Source, LoanApplicationId>({
  key: 'LearnMoreReturnScreen',
  default: null,
});
