import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { ConnectedBanksModalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { TranslucentModalNavOptions } from '../../utils/NavConstants';
import { BankAccountDetail } from '../screens/BankAccountDetail';

const ConnectedBanksModalStack =
  createStackNavigator<ConnectedBanksModalStackParams>();
export const ConnectedBanksModalNavigator: React.FC = () => (
  <ConnectedBanksModalStack.Navigator
    screenOptions={TranslucentModalNavOptions}
  >
    <ConnectedBanksModalStack.Screen
      name={Screen.BANK_ACCOUNT_DETAILS}
      component={BankAccountDetail}
      options={{
        title: getPageTitle(Screen.BANK_ACCOUNT_DETAILS),
      }}
    />
  </ConnectedBanksModalStack.Navigator>
);
