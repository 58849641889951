import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  AutopaySetupConfirmationStep,
  AutopaySetupConfirmationStepAtom,
} from '../../ActionSheet/recoil/AutopaySetupConfirmationStep';
import { useConfirmAutopay } from '../../ActionSheet/utils/useConfirmAutopay';
import {
  AddExternalAccountFor,
  useAddExternalAccount,
} from '../../ExternalAccount/utils/useAddExternalAccount';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { AutopaySettingsNavigationProps } from '../navigation/types';
import {
  AutopayChangeRequestFormV2,
  MinimumRepaymentAmountsByFrequency,
} from '../types';
import { useGetEstimatedShortfallAmount } from './useGetEstimatedShortfallAmount';

type UseAutopaySetupActionsProps = {
  navigation: AutopaySettingsNavigationProps<
    Screen.AUTOPAY_SETUP | Screen.AUTOPAY_EDIT
  >;
  cbaAccountId: string;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  monthlyMinimumRepaymentAmountError?: ApolloError;
};

export const useAutopaySetupActions = ({
  navigation,
  cbaAccountId,
  minimumRepaymentAmountsByFrequency,
  monthlyMinimumRepaymentAmountError,
}: UseAutopaySetupActionsProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { flags } = useContext(FeatureFlagsContext);
  const isShortfallAlertEnabled = flags.ENABLE_APP_SHORTFALL_ALERT;

  const autopaySetupConfirmationStep = useRecoilValue(
    AutopaySetupConfirmationStepAtom,
  );

  const resetAutopaySetupConfirmationStep = useResetRecoilState(
    AutopaySetupConfirmationStepAtom,
  );

  const onBack = useCallback(() => {
    resetAutopaySetupConfirmationStep();
    setShowConfirmation(false);
  }, [resetAutopaySetupConfirmationStep]);

  const getRepayment = useCallback(
    ({
      repaymentFrequency,
      isCustomRepaymentAmount,
      repaymentAmount,
    }: AutopayChangeRequestFormV2) => {
      if (isCustomRepaymentAmount) {
        if (!repaymentFrequency) {
          return null;
        }

        return {
          frequency: repaymentFrequency,
          amount: repaymentAmount ?? 0,
          isMinimumRepayment:
            minimumRepaymentAmountsByFrequency[repaymentFrequency] ===
            repaymentAmount,
        };
      }

      if (!repaymentFrequency) {
        return null;
      }

      return {
        frequency: repaymentFrequency,
        amount: minimumRepaymentAmountsByFrequency[repaymentFrequency],
        isMinimumRepayment: true,
      };
    },
    [minimumRepaymentAmountsByFrequency],
  );

  const {
    getEstimatedShortfallAmount,
    loading: getEstimatedShortfallAmountLoading,
    error: getEstimatedShortfallAmountError,
  } = useGetEstimatedShortfallAmount({
    cbaAccountId,
  });

  const [incorrectShortfallAmount, setIncorrectShortfallAmount] =
    useState(false);

  const formValuesRef = useRef<AutopayChangeRequestFormV2 | null>(null);

  const onContinuePress = useCallback(
    async (values: AutopayChangeRequestFormV2) => {
      const { paymentStartDate } = values;

      // This should not happen due to form validation
      if (!paymentStartDate) {
        return;
      }

      // If new account is added, show new account confirmation modal
      if (
        autopaySetupConfirmationStep !==
          AutopaySetupConfirmationStep.RequiresNewAccountConfirmationPassed &&
        values.isManualAccountInput
      ) {
        formValuesRef.current = values;
        navigation.navigate(
          ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2,
        );
        return;
      }

      resetAutopaySetupConfirmationStep();

      const repayment = getRepayment(values);

      if (!repayment) {
        return;
      }

      const { amount, frequency, isMinimumRepayment } = repayment;

      const [calculateShortfallRes] = await getEstimatedShortfallAmount({
        cba_account_id: cbaAccountId,
        effective_date: format(paymentStartDate, 'yyyy-MM-dd'),
        is_minimum_repayment: isMinimumRepayment,
        new_repayment_amount: amount,
        repayment_frequency: frequency,
      });

      const shortfallAmount = calculateShortfallRes?.data?.estimated_shortfall;

      if (typeof shortfallAmount !== 'number') {
        setIncorrectShortfallAmount(true);
        return;
      }

      if (shortfallAmount > 0 && isShortfallAlertEnabled) {
        navigation.navigate(ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2, {
          shortfallAmount,
        });
        return;
      }

      setShowConfirmation(true);
    },
    [
      autopaySetupConfirmationStep,
      cbaAccountId,
      getEstimatedShortfallAmount,
      getRepayment,
      navigation,
      resetAutopaySetupConfirmationStep,
      isShortfallAlertEnabled,
    ],
  );

  // Cleanup effect
  useEffect(
    () => () => resetAutopaySetupConfirmationStep(),
    [resetAutopaySetupConfirmationStep],
  );

  // If new account confirmation has passed, continue with form submission
  useEffect(() => {
    if (
      autopaySetupConfirmationStep ===
        AutopaySetupConfirmationStep.RequiresNewAccountConfirmationPassed &&
      formValuesRef.current
    ) {
      onContinuePress(formValuesRef.current);
    }
  }, [autopaySetupConfirmationStep, onContinuePress]);

  // If shortfall confirmation has passed, show summary screen
  useEffect(() => {
    if (
      autopaySetupConfirmationStep ===
      AutopaySetupConfirmationStep.RequiresShortfallConfirmationPassed
    ) {
      setShowConfirmation(true);
    }
  }, [autopaySetupConfirmationStep]);

  const {
    confirmAutopay,
    loading: confirmAutopayLoading,
    error: confirmAutopayError,
  } = useConfirmAutopay({
    cbaAccountId,
    screen: Screen.AUTOPAY_SETUP,
  });

  const { addExternalAccount, addExternalAccountLoading } =
    useAddExternalAccount({
      cbaAccountId,
      accountFor: AddExternalAccountFor.AutoPay,
      preventSettingSelectedExternalAccount: true,
    });

  const getOrAddExternalAccountId = useCallback(
    async (formValues: AutopayChangeRequestFormV2) => {
      if (!formValues.isManualAccountInput) {
        return formValues.externalAccountId;
      }

      const newExternalAccountId = await addExternalAccount({
        account_name: formValues.directDebitAccountName,
        account_bsb: formValues.directDebitAccountBsb,
        account_number: formValues.directDebitAccountNumber,
      });

      return newExternalAccountId;
    },
    [addExternalAccount],
  );

  const onConfirmPress = useCallback(
    async (values: AutopayChangeRequestFormV2) => {
      const { paymentStartDate } = values;

      const externalAccountId = await getOrAddExternalAccountId(values);

      if (!externalAccountId || !paymentStartDate) {
        return;
      }

      const repayment = getRepayment(values);

      if (!repayment) {
        return;
      }

      const { frequency, amount } = repayment;

      const autopayChangeRequestId = await confirmAutopay({
        externalAccountId,
        repaymentFrequency: frequency,
        repaymentAmount: amount,
        paymentStartDate,
        isMinimumAmount: !values.isCustomRepaymentAmount,
      });

      if (autopayChangeRequestId) {
        navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
          message: t('Content.AutopaySettings.ConfirmSuccessMessage'),
        });
      }
    },
    [confirmAutopay, getOrAddExternalAccountId, getRepayment, navigation],
  );

  const errorMessage: string = useMemo(() => {
    if (monthlyMinimumRepaymentAmountError) {
      return t('Content.Common.Error.FailMinimumMonthlyRepaymentAmount');
    }

    if (confirmAutopayError) {
      return t('Content.Common.Error.FailAutopayConfirmation');
    }

    if (getEstimatedShortfallAmountError || incorrectShortfallAmount) {
      return t('Content.Common.Error.FailAutopayShortfallConfirmation');
    }
    return '';
  }, [
    confirmAutopayError,
    getEstimatedShortfallAmountError,
    incorrectShortfallAmount,
    monthlyMinimumRepaymentAmountError,
  ]);

  return {
    onContinuePress,
    onConfirmPress,
    onBack,
    showConfirmation,
    getEstimatedShortfallAmountLoading,
    confirmAutopayLoading: addExternalAccountLoading || confirmAutopayLoading,
    errorMessage,
  };
};
