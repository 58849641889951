import { Text } from 'dripsy';
import { FormikProps } from 'formik';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { Form, FormDateInputV2 } from '../../components/form/FormikInputs';
import { FormSpacer } from '../../components/form/FormSpacer';
import { SubmitButton } from '../../components/form/SubmitButton';
import { UnloanAccountInputRow } from '../../HomeLoan/components/UnloanAccountInputRow';
import { ExternalAccount } from '../../HomeLoan/types';
import { Screen } from '../../navigation/types/screens';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { FormikFormError } from '../../ui/v2/FormError';
import {
  AutopayChangeRequestFormV2,
  AutoPaySetupFormField,
  MinimumRepaymentAmountsByFrequency,
} from '../types';
import { autopayStartDateValidationSchema } from '../utils/useAutopaySetupV2ValidationSchema';
import { AutopaySetupV2FromSection } from './AutopaySetupV2FromSection';
import { AutopaySetupV2RepaymentSelect } from './AutopaySetupV2RepaymentSelect';

type AutopaySetupV2FormProps = {
  screen: Screen;
  formProps: FormikProps<AutopayChangeRequestFormV2>;
  suggestionData: Array<ExternalAccount>;
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  loading?: boolean;
  onSubmitPress: () => void;
  submitLoading?: boolean;
  refetchMonthlyMinimumRepaymentAmount?: (newStartDate: string) => void;
  isLoadingMonthlyMinimumRepaymentAmount?: boolean;
};

export function AutopaySetupV2Form({
  screen,
  formProps,
  suggestionData,
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  minimumRepaymentAmountsByFrequency,
  loading,
  onSubmitPress,
  submitLoading,
  refetchMonthlyMinimumRepaymentAmount,
  isLoadingMonthlyMinimumRepaymentAmount,
}: AutopaySetupV2FormProps) {
  const handleStartDateChange = useCallback(
    (startDate: string) => {
      if (autopayStartDateValidationSchema().isValidSync(startDate)) {
        refetchMonthlyMinimumRepaymentAmount?.(startDate);
      }
    },
    [refetchMonthlyMinimumRepaymentAmount],
  );

  return (
    <Form overflow="visible">
      <Skeleton show={loading}>
        <AutopaySetupV2FromSection
          screen={screen}
          formProps={formProps}
          suggestionData={suggestionData}
        />
      </Skeleton>

      <FormSpacer py="$12" />

      <Skeleton show={loading}>
        <>
          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.AutopaySettings.ToAccount')}
          </Text>

          <UnloanAccountInputRow
            accountName={unloanAccountName}
            bsb={unloanBsb}
            accountNumber={unloanAccountNumber}
          />
        </>
      </Skeleton>

      <FormSpacer py="$12" />

      <Skeleton show={loading}>
        <>
          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.AutopaySettings.StartDate')}
          </Text>

          <FormDateInputV2
            inputTestID={TestID.AutopaySetup.StartDateInput}
            name={AutoPaySetupFormField.PaymentStartDate}
            onDateFullyEntered={handleStartDateChange}
            aria-label={t('Content.AutopaySettings.StartDate')}
            aria-required
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.AutopaySetup,
              screen,
              FieldInteractionKey.StartDate,
            )}
          />

          <FormikFormError
            sx={{ mt: '$8' }}
            name={AutoPaySetupFormField.PaymentStartDate}
          />
        </>
      </Skeleton>

      <FormSpacer py="$12" />

      <AutopaySetupV2RepaymentSelect
        screen={screen}
        formProps={formProps}
        minimumRepaymentAmountsByFrequency={minimumRepaymentAmountsByFrequency}
        loading={loading}
        isLoadingMinimumRepaymentAmount={isLoadingMonthlyMinimumRepaymentAmount}
      />

      <FormSpacer py="$32" />

      <Skeleton show={loading}>
        <SubmitButton
          testID={TestID.AutopaySetup.ContinueButton}
          label={t('Content.Common.ButtonLabel.Continue')}
          onPress={onSubmitPress}
          showSpinner={submitLoading}
          disabled={submitLoading || isLoadingMonthlyMinimumRepaymentAmount}
        />
      </Skeleton>
    </Form>
  );
}
