import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import { TransitionPresets } from '@react-navigation/stack';
import { createContext, MutableRefObject, useEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { TopNavBar } from '../../Home/navigation/components/TopNavBar';
import { MainStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { useTheme } from '../../ui/theme';
import { BackButton } from '../../ui/v2/BackButton';
import { useIsMobile } from '../../utils/hooks/useBreakpoint';
import { useJoinApplication } from '../../utils/JoinApplicationInvite';
import {
  DefaultNavOptions,
  NoBorderNavOptions,
  TranslucentNavOptions,
} from '../../utils/NavConstants';
import { isWeb } from '../../utils/platformUtils';
import { LoanApplicationBaseNavigator } from './LoanApplicationBaseNavigator';
import { useCoborrowerLoanApplicationFlowV2 } from './LoanApplicationV2NavigatorUtils';

export const ScrollContainerRefContext =
  createContext<MutableRefObject<ScrollView | null> | null>(null);

const LoanApplicationNavOptionsV2 = {
  ...TransitionPresets.SlideFromRightIOS,
  ...DefaultNavOptions,
  ...TranslucentNavOptions,
  ...NoBorderNavOptions,
  headerMode: 'float' as const,
  headerTitle: '',
  animationEnabled: !isWeb,
  headerBackImage: () => <BackButton style={{ marginLeft: -11 }} />, // 11px is the margin wrapped by react-navigation,
  cardStyle: {
    flexBasis: 'auto' as const,
    flexShrink: 0,
    minHeight: 0,
  },
};
export function LoanApplicationV2Navigator() {
  const { initialParams, initialRouteName } =
    useCoborrowerLoanApplicationFlowV2();

  const theme = useTheme();
  const isMobile = useIsMobile();
  const route =
    useRoute<RouteProp<MainStackParams, Screen.LOAN_APPLICATION_V2>>();

  const loanApplicationId =
    route.params?.params?.loanApplicationId ?? initialParams?.loanApplicationId;

  const scrollContainerRef = useRef<ScrollView | null>(null);

  useEffect(() => {
    // when switching between screens, make sure the screen starting from the top
    scrollContainerRef.current?.scrollTo({ y: 0, animated: false });
  }, [route]);

  const joinApplicationData = useJoinApplication();
  useFocusEffect(() => {
    joinApplicationData?.resetLoanApplication();
  });

  return (
    <Box flex={1}>
      {!isMobile && (
        <Box style={styles.topSticky}>
          <TopNavBar />
        </Box>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={scrollContainerRef}
        style={{ height: '100%' }}
        contentContainerStyle={{
          flexGrow: 1,
          // topnav is not 60px
          paddingTop: !isMobile ? theme.sizes.xl : 0,
        }}
      >
        {isMobile ? <TopNavBar /> : null}
        <ScrollContainerRefContext.Provider value={scrollContainerRef}>
          <LoanApplicationBaseNavigator
            loanApplicationId={loanApplicationId}
            initialRouteName={initialRouteName}
            screenOptions={{
              ...LoanApplicationNavOptionsV2,
              headerStyle: {
                height: isMobile ? theme.sizes.l : theme.sizes.xl,
              },
              headerLeftContainerStyle: {
                paddingLeft: isMobile ? theme.spacing.m : theme.spacing.xl,
              },
              headerRightContainerStyle: {
                paddingRight: isMobile ? theme.spacing.m : theme.spacing.xl, // make an extra padding in right to make sure progress bar in center
              },
            }}
          />
        </ScrollContainerRefContext.Provider>
      </ScrollView>
    </Box>
  );
}

const styles = StyleSheet.create({
  topSticky: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
});
