import { Component, ErrorInfo, ReactNode } from 'react';

import { captureException } from '../sentry';
import { ErrorFallback } from './ErrorFallback';

type Props = {
  children: ReactNode;
};
export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureException('Unhandled Error in App', { errorInfo }, error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorFallback
          title={t('Content.Common.Error.SomethingWentWrong.Title')}
          caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        />
      );
    }
    return children;
  }
}
