import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function TickCircleIcon({ size, color, ...otherProps }: SvgIconProps) {
  const width = resizeIconWidth(18, 17, size);
  const theme = useTheme();
  const actualColor = color ?? theme.colors.success;

  return (
    <Svg
      width={width}
      height={size}
      fill="none"
      viewBox="0 0 18 17"
      {...otherProps}
    >
      <Path
        fill={actualColor}
        d="M8.547 16.974c-4.623 0-8.466-3.844-8.466-8.467C.08 3.875 3.916.04 8.539.04c4.632 0 8.475 3.835 8.475 8.467 0 4.623-3.835 8.467-8.467 8.467Zm-.93-4.408c.283 0 .524-.133.698-.407l3.901-6.142c.1-.175.208-.366.208-.548 0-.39-.34-.64-.706-.64-.216 0-.431.133-.59.382l-3.544 5.695L5.9 8.73c-.207-.274-.39-.349-.63-.349a.67.67 0 0 0-.665.681c0 .19.075.374.2.54l2.083 2.556c.216.29.448.407.73.407Z"
      />
    </Svg>
  );
}
