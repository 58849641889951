import { Text } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { formatCurrency } from '../../../utils/currencyHelpers';

type Props = {
  amount: number;
};

export const PredictedTotalInterestSavingsCaption = ({ amount }: Props) => (
  <Text
    variant="body"
    testID={TestID.Insights.InterestSavings.ProjectedTotalInterestSavings}
  >
    {t(
      'Content.HomeLoan.Insights.TotalInterestSavings.PredictedInterestSavings',
      {
        totalInterestSavings: formatCurrency(amount, {
          noFraction: false,
          withFractionOnRoundedAmount: true,
        }),
      },
    )}
  </Text>
);
