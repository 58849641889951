import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryExpenses = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryExpenses');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        d="M33 22.5H15v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 19.5H15v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill={`url(#${idPrefix}_a)`}
      />
      <Path
        d="M33 16.5H15v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 13.5H15v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill={`url(#${idPrefix}_b)`}
      />
      <Path
        d="M33 10.5H15v3c0 2.486 4.03 4.5 9 4.5s9-2.014 9-4.5v-3Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 7.5H15v3c0 2.486 4.03 4.5 9 4.5s9-2.014 9-4.5v-3Z"
        fill={`url(#${idPrefix}_c)`}
      />
      <Path
        d="M24 12c4.97 0 9-2.015 9-4.5S28.97 3 24 3s-9 2.015-9 4.5 4.03 4.5 9 4.5Z"
        fill="#C6C6C6"
      />
      <Path
        opacity={0.08}
        d="M15 13.734V25.5c0 2.18 3.103 3.998 7.221 4.411.176-.45.279-.92.279-1.411v-9c0-2.82-3.07-5.063-7.5-5.766Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.08}
        d="M15 14.496V25.5c0 2.04 2.719 3.761 6.442 4.313.194-.419.308-.857.308-1.313v-9c0-2.377-2.795-4.337-6.75-5.004Z"
        fill="#2C2C2C"
      />
      <Path
        d="M21 25.5H3v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill={`url(#${idPrefix}_d)`}
      />
      <Path
        d="M21 22.5H3v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill="#2C2C2C"
      />
      <Path
        d="M21 19.5H3v3c0 2.485 4.03 4.5 9 4.5s9-2.015 9-4.5v-3Z"
        fill={`url(#${idPrefix}_e)`}
      />
      <Path
        d="M12 24c4.97 0 9-2.015 9-4.5S16.97 15 12 15s-9 2.015-9 4.5S7.03 24 12 24Z"
        fill={`url(#${idPrefix}_f)`}
      />
      <Path
        d="M12 24c4.97 0 9-2.015 9-4.5S16.97 15 12 15s-9 2.015-9 4.5S7.03 24 12 24Z"
        fill="#C6C6C6"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_a`}
          x1={15}
          y1={19.5}
          x2={20.325}
          y2={32.281}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_b`}
          x1={15}
          y1={13.5}
          x2={20.325}
          y2={26.281}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_c`}
          x1={15}
          y1={7.5}
          x2={20.325}
          y2={20.281}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_d`}
          x1={3}
          y1={25.5}
          x2={8.325}
          y2={38.281}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_e`}
          x1={3}
          y1={19.5}
          x2={8.325}
          y2={32.281}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_f`}
          x1={7.094}
          y1={14.594}
          x2={17.013}
          y2={24.513}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FEDE00" />
          <Stop offset={1} stopColor="#FFD000" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
