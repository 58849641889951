import { Text } from 'dripsy';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ActionSheet } from '../../ActionSheet/components/ActionSheet';
import { ActionSheetScreenProps } from '../../ActionSheet/navigation/types';
import { ActionSheetType } from '../../navigation/types/screens';
import { LeaveAccountPermissionsScreenAtomFamily } from '../utils/atoms';

export type Props =
  ActionSheetScreenProps<ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE>;

export const AccountPermissionsLeavePageActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { cbaAccountId } = route.params;

  const GoBackAtom = LeaveAccountPermissionsScreenAtomFamily(cbaAccountId);
  const setShouldGoBack = useSetRecoilState(GoBackAtom);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onLeave = useCallback(() => {
    setShouldGoBack(true);
    goBack();
  }, [goBack, setShouldGoBack]);

  return (
    <ActionSheet
      closeModal={goBack}
      showCloseButton={false}
      isRowButton
      title={t('Content.AccountPermissions.LeavePage.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Leave'),
          testID: TestID.AccountPermissions.LeavePage.LeaveButton,
          onPress: onLeave,
          secondary: true,
          color: 'error',
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Stay'),
          testID: TestID.AccountPermissions.LeavePage.StayButton,
          onPress: goBack,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <Text
        variant="body"
        sx={{ py: '$8', px: '$16', mb: '$8', textAlign: 'center' }}
      >
        {t('Content.AccountPermissions.LeavePage.Message')}
      </Text>
    </ActionSheet>
  );
};
