import { Pressable, SxProp, View } from 'dripsy';
import { useMemo, useState } from 'react';
import Animated from 'react-native-reanimated';

import { SettlementProcessRow } from '../../Settlement/components/SettlementProcessRow';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import VerticalStepper from '../../ui/molecules/VerticalStepper';
import { useTheme } from '../../ui/theme';
import { useCardHoverShadowStyle } from '../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../ui/utils/useCardTranslateYAnimatedStyle';
import { ApplicationTrackingStage } from '../utils/useApplicantStates';
import { YourApplicationHeader } from './YourApplicationHeader';

type Props = {
  headerTitle?: string;
  caption: string;
  applicationTrackingStage: ApplicationTrackingStage;
  applicantStateLoading: boolean;
  onCardPress: () => void;
  onActionPress?: () => void;
  actionLabel?: string;
  actionButtonTestID?: string;
  link?: string;
  isTopUp?: boolean;
  onLinkPress?: () => void;
  testID?: string;
  sx?: SxProp;
};

// This placeholder is used so the vertical stepper render the
// bar connecting to the next row, the row itself is rendered.
const PlaceholderRow = { title: '', caption: '', placeholder: true };

function getTitleByStage(
  applicationTrackingStage: ApplicationTrackingStage,
  isTopUp: boolean,
) {
  switch (applicationTrackingStage) {
    case ApplicationTrackingStage.Apply:
      return t('Content.ApplicationTracking.Apply');
    case ApplicationTrackingStage.Decision:
      return t('Content.ApplicationTracking.Decision');
    case ApplicationTrackingStage.Accept:
      return t('Content.ApplicationTracking.Accept');
    case ApplicationTrackingStage.Settlement:
      return isTopUp
        ? t('Content.ApplicationTracking.Disbursement')
        : t('Content.ApplicationTracking.Settle');
    default:
      return t('Content.ApplicationTracking.Apply');
  }
}

function makeStepperProps(
  applicationTrackingStage: ApplicationTrackingStage,
  caption: string,
  isTopUp: boolean,
) {
  const title = getTitleByStage(applicationTrackingStage, isTopUp);
  const stepperData: Array<typeof PlaceholderRow> = [
    {
      title,
      caption,
      placeholder: false,
    },
  ];
  let isEdge = true;
  let justifyContent: BoxProps['justifyContent'];
  if (applicationTrackingStage === ApplicationTrackingStage.Apply) {
    justifyContent = 'flex-start';
    stepperData.push(PlaceholderRow);
  } else if (applicationTrackingStage === ApplicationTrackingStage.Settlement) {
    justifyContent = 'flex-end';
    stepperData.unshift(PlaceholderRow);
  } else {
    isEdge = false;
    justifyContent = 'center';
    stepperData.push(PlaceholderRow);
    stepperData.unshift(PlaceholderRow);
  }
  const activeStep = stepperData.findIndex((step) => step.title === title);
  return { justifyContent, stepperData, activeStep, isEdge };
}

export function PreDisbursementApplicationCard({
  caption,
  onCardPress,
  onActionPress,
  actionLabel,
  applicationTrackingStage,
  applicantStateLoading,
  headerTitle,
  actionButtonTestID,
  link,
  onLinkPress,
  testID,
  isTopUp = false,
  sx: sxProps = {},
}: Props) {
  const theme = useTheme();
  const rowHeight = SettlementProcessRow.height - 2 * theme.spacing.s;

  const [isHover, setIsHover] = useState(false);

  const { activeStep, justifyContent, stepperData, isEdge } = useMemo(
    () => makeStepperProps(applicationTrackingStage, caption, isTopUp),
    [applicationTrackingStage, caption, isTopUp],
  );

  const stepper = (
    <VerticalStepper
      rowHeight={SettlementProcessRow.height}
      data={stepperData}
      renderItem={(step) =>
        step.placeholder ? null : (
          <SettlementProcessRow
            {...step}
            buttonLabel={actionLabel}
            onButtonPress={onActionPress}
            link={link}
            onLinkPress={onLinkPress}
            buttonTestId={actionButtonTestID}
            onPressIn={() => setIsHover(true)}
            onPressOut={() => setIsHover(false)}
          />
        )
      }
      activeStep={activeStep}
    />
  );

  const stepperRow = isEdge ? (
    stepper
  ) : (
    <Box height={rowHeight} justifyContent={justifyContent} overflow="hidden">
      {/* This extra box is used to emulate the vertical padding by creating
      a whitespace using difference in the height and overflow hidden.
      Done to provide a cuttoff effect to stepper that has vertical bar on both sides.
      */}
      {stepper}
    </Box>
  );
  const bottomRowContent = applicantStateLoading ? (
    <Box flex={1} centered>
      <Spinner size="large" />
    </Box>
  ) : (
    stepperRow
  );

  const shadowProps = useCardHoverShadowStyle({ isHover });

  const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

  return (
    <Animated.View style={animatedStyle}>
      <View
        onPointerEnter={() => setIsHover(true)}
        onPointerLeave={() => setIsHover(false)}
        sx={{
          backgroundColor: '$inputBackground',
          borderRadius: '$card',
          overflow: 'visible',
          cursor: 'unset',
          ...shadowProps,
          ...sxProps,
        }}
      >
        <Pressable
          testID={testID}
          role="button"
          onPress={onCardPress}
          onPressIn={() => setIsHover(true)}
          onPressOut={() => setIsHover(false)}
        >
          <YourApplicationHeader
            caption={headerTitle}
            containerSx={{
              borderTopLeftRadius: '$card',
              borderTopRightRadius: '$card',
            }}
            isHover={isHover}
          />
        </Pressable>
        <Separator mx="m" />
        <Box
          px="m"
          height={SettlementProcessRow.height}
          justifyContent={justifyContent}
        >
          {bottomRowContent}
        </Box>
      </View>
    </Animated.View>
  );
}
