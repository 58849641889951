import { useDripsyTheme } from 'dripsy';
import { memo, useEffect } from 'react';
import Animated, {
  Easing,
  useAnimatedProps,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';
import { Rect } from 'react-native-svg';

export type SingleBarProps = {
  maxBarValue: number;
  barAreaHeight: number;
  value: number;
  xPos: number;
  barWidth: number;
  animateDelay?: number;
  animationDuration?: number;
  disabled?: boolean;
};

export const SingleBar = memo(
  ({
    value,
    maxBarValue,
    barAreaHeight,
    xPos,
    barWidth,
    animateDelay = 0,
    animationDuration = 0,
    disabled,
  }: SingleBarProps) => {
    const { theme } = useDripsyTheme();
    const AnimatedRect = Animated.createAnimatedComponent(Rect);
    const progressBar = useSharedValue(0);
    const animatedProps = useAnimatedProps(() => ({
      height:
        maxBarValue === 0
          ? 0
          : ((progressBar.value || 0) * barAreaHeight) / maxBarValue,
    }));

    useEffect(() => {
      progressBar.value = withDelay(
        animateDelay,
        withTiming(value || 0, {
          duration: animationDuration,
          easing: Easing.out(Easing.ease),
        }),
      );
    }, [animateDelay, animationDuration, progressBar, value]);

    if (disabled) {
      return (
        <Rect
          transform={`scale(1,-1) translate(0, ${-barAreaHeight})`}
          x={xPos}
          y={0}
          width={barWidth}
          height={1.5}
          fill={theme.colors.$darkerDisabled}
        />
      );
    }

    return (
      <AnimatedRect
        transform={`scale(1,-1) translate(0, ${-barAreaHeight})`}
        x={xPos}
        y={0}
        width={barWidth}
        animatedProps={animatedProps}
        rx={1}
        fill="url(#gradientBar)"
      />
    );
  },
);
