import { ComponentProps, forwardRef, useMemo } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { FieldRules } from '../../constants/fieldRules';
import {
  formatCurrency,
  stripDollarSign,
  stripNonDigit,
  stripNonNumericLetters,
} from '../../utils/currencyHelpers';
import { toFloat } from '../../utils/numberHelpers';
import { TextInput, TextInputV2Props } from './TextInput';

// Original CurrencyInput use number for callback value and value.
// This currency input will use the same approach to make
// migration easier.
type OnChangeNumber = (value: number | string | null) => void;

const getDisplayValueWithoutDecimals = (value?: number | null) =>
  value != null ? formatCurrency(value, { noFraction: true }) : '';
const getDisplayValueWithDecimals = (value?: number | null) =>
  value != null ? formatCurrency(value) : '';

export type CurrencyInputV2Props = Omit<
  TextInputV2Props,
  'onChangeText' | 'value' | 'onSelectionChange' | 'selection'
> & {
  onChangeText?: OnChangeNumber;
  value?: number | null;
  allowsCents?: boolean;
};

function wrapOnChangeTextWithoutDecimals(
  onChangeText: OnChangeNumber | undefined,
): ComponentProps<typeof TextInput>['onChangeText'] {
  if (onChangeText == null) {
    return undefined;
  }

  return (text) => {
    const withoutDollarSign = stripDollarSign(text);
    // Right now we strip all non-digit chars, including commas and dots,
    // this will prevent the user to input fraction for the currency.
    const digitOnly = stripNonDigit(withoutDollarSign);
    // Only run onChangeText() if the text contains valid changes
    if (digitOnly === withoutDollarSign) {
      const parsedDigit =
        withoutDollarSign === '' ? null : toFloat(withoutDollarSign);

      if (parsedDigit == null) {
        onChangeText(null);
      } else if (FieldRules.currency.isValid(parsedDigit)) {
        onChangeText(parsedDigit);
      }
    }
  };
}

const parseNumber = (value?: number | null): number => toFloat(value);

function wrapOnChangeTextWithDecimals(
  onChangeText: OnChangeNumber | undefined,
  focused: boolean = false,
): ComponentProps<typeof TextInput>['onChangeText'] {
  if (onChangeText == null) {
    return undefined;
  }

  return (text) => {
    const withoutDollarSign = stripDollarSign(text);
    // Right now we strip all non-digit chars, including commas and dots,
    // this will prevent the user to input fraction for the currency.
    const digitOnly = stripNonNumericLetters(withoutDollarSign);
    // Only run onChangeText() if the text contains valid changes
    if (focused) {
      onChangeText(digitOnly);
      return;
    }

    if (digitOnly === withoutDollarSign) {
      const parsedDigit =
        withoutDollarSign === '' ? null : toFloat(withoutDollarSign);
      if (parsedDigit == null) {
        onChangeText(null);
      } else if (FieldRules.currency.isValid(parsedDigit)) {
        onChangeText(parsedDigit);
      }
    }
  };
}

export const CurrencyInput = forwardRef<RNTextInput, CurrencyInputV2Props>(
  (
    {
      value,
      onChangeText,
      onBlur,
      allowsCents = false,
      focused,
      ...otherProps
    },
    ref,
  ) => {
    const wrapOnChangeText = useMemo(
      () =>
        allowsCents
          ? wrapOnChangeTextWithDecimals
          : wrapOnChangeTextWithoutDecimals,
      [allowsCents],
    );

    const wrappedOnChange = useMemo(
      () => wrapOnChangeText(onChangeText, focused),
      [focused, onChangeText, wrapOnChangeText],
    );

    const getDisplayValue = useMemo(
      () =>
        allowsCents
          ? getDisplayValueWithDecimals
          : getDisplayValueWithoutDecimals,
      [allowsCents],
    );

    const displayedValue = useMemo(
      () =>
        focused && value && allowsCents ? `$${value}` : getDisplayValue(value),
      [allowsCents, focused, getDisplayValue, value],
    );

    return (
      <TextInput
        ref={ref}
        keyboardType="numeric"
        value={displayedValue}
        focused={focused}
        onBlur={(e) => {
          onBlur?.(e);
          onChangeText?.(parseNumber(value));
        }}
        onChangeText={(text) => {
          wrappedOnChange?.(text);
        }}
        {...otherProps}
      />
    );
  },
);

CurrencyInput.displayName = 'CurrencyInputV2';
