import { Suspense } from 'react';
import { TouchableOpacity } from 'react-native';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { TestID } from '../../../../testID/constants';
import { useAndroidInstallPwaEvent } from '../../../Home/navigation/utils/useAndroidInstallPwaEvent';
import { redirectedFromNativeAppAtom } from '../../../Home/utils/atoms/redirectedFromNativeAppAtom';
import { Box, BoxProps } from '../../atoms/Box';
import { Button } from '../../atoms/Button';
import { StyledIcon } from '../../atoms/StyledIcon';
import { StyledText } from '../../atoms/StyledText';
import { useTheme } from '../../theme';
import { InstallPwaRules } from './InstallPwaRules';
import { PwaUnloanLogo } from './PwaUnloanLogo';
import {
  pwaInstallBannerVisibleAtom,
  pwaInstallTooltipVisibleAtom,
} from './recoil/pwaInstall';

export function BaseInstallPwaBanner() {
  const [isVisible, setIsVisible] = useRecoilState(pwaInstallBannerVisibleAtom);
  const setIsTooltipVisible = useSetRecoilState(pwaInstallTooltipVisibleAtom);

  const redirectedFromNativeApp = useRecoilValue(redirectedFromNativeAppAtom);

  const androidInstallPwaEvent = useAndroidInstallPwaEvent();

  const theme = useTheme();

  const bannerProps: BoxProps = {
    row: true,
    alignItems: 'center',
    borderStyle: 'solid',
    borderBottomWidth: theme.constants.borderWidth,
    borderBottomColor: 'border',
    px: 'm',
    py: 'm',
  };

  const onInstallPress = async () => {
    if (androidInstallPwaEvent) {
      await androidInstallPwaEvent.prompt();

      const { outcome } = await androidInstallPwaEvent.userChoice;

      if (outcome === 'accepted') {
        setIsVisible(false);
      }
    } else {
      setIsTooltipVisible(true);
    }
  };

  // PWA Banner should not be visible if the native app monitor is enabled
  if (!isVisible || redirectedFromNativeApp) {
    return null;
  }

  return (
    <InstallPwaRules>
      <Box testID={TestID.Pwa.InstallBanner} {...bannerProps}>
        <TouchableOpacity
          testID={TestID.Pwa.CloseBannerButton}
          onPress={() => setIsVisible(false)}
        >
          <StyledIcon
            family="ionicons"
            name="close-outline"
            size="m"
            color="secondaryContent"
          />
        </TouchableOpacity>
        <Box row flex={1} ml="xs" justifyContent="space-between">
          <Box row>
            <PwaUnloanLogo />
            <Box ml="s" justifyContent="center">
              <StyledText variant="body" fontWeight="semiBold">
                Unloan
              </StyledText>
              <StyledText variant="body">
                {t('Content.Pwa.Banner.Install')}
              </StyledText>
            </Box>
          </Box>
          <Button
            testID={TestID.Pwa.InstallPwaButton}
            variant="pill"
            label="Install"
            alignSelf="center"
            onPress={onInstallPress}
          />
        </Box>
      </Box>
    </InstallPwaRules>
  );
}

export function InstallPwaBanner() {
  return (
    <Suspense fallback={null}>
      <BaseInstallPwaBanner />
    </Suspense>
  );
}
