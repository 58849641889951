import { gql } from '@apollo/client';
import { Text, useDripsyTheme, View } from 'dripsy';
import { useCallback, useContext, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import {
  getTransactionAmountLabel,
  getTransactionTypeLabel,
} from '../../components/utils/getTransactionTypeLabel';
import { DEFAULT_DATE_DISPLAY_FORMAT } from '../../constants/dateFormats';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Cb_Transaction,
  Cb_TransactionType,
  Maybe,
  useGetAutopaySettingsQuery,
  useGetLoanTransactionByIdQuery,
} from '../../generated/graphql';
import { TranslateKeys } from '../../localization/constants';
import { Screen } from '../../navigation/types/screens';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { HomeLoanScreenProps } from '../navigation/types';
import { isAutopayDebit } from '../utils/isAutopayDebit';
import { useShowHomeLoanAutopayModal } from '../utils/useShowHomeLoanAutopayModal';

export type Props = HomeLoanScreenProps<Screen.HOME_PROCESSED_TRANSACTION>;

export const GET_LOAN_TRANSACTION_BY_ID = gql`
  query GetLoanTransactionById(
    $cba_account_id: String!
    $loan_transaction_id: String!
  ) {
    transaction: loan_transaction_by_id(
      cba_account_id: $cba_account_id
      transaction_id: $loan_transaction_id
    ) {
      id
      date
      amount
      type
      description
      reference
      cba_receipt_id
      channel_type
      overlay
      category_purpose
      creditor_reference
      identification
      scheme_name
    }
  }
`;

type AutopayDebitContentProps = {
  transactionId: string;
  cbaAccountId: string;
  navigation: Props['navigation'];
};

function AutopayDebitContent({
  transactionId,
  cbaAccountId,
  navigation,
}: AutopayDebitContentProps) {
  const { loading: autopaySettingsLoading, data: autopaySettingsData } =
    useGetAutopaySettingsQuery({
      variables: { cba_account_id: cbaAccountId },
      context: {
        sentryContext: {
          transactionId,
          cbaAccountId,
        },
      },
    });

  const currentAutopayChangeRequest =
    autopaySettingsData?.current_autopay_change_request?.[0];

  const onSetupAutopay = useShowHomeLoanAutopayModal({
    navigate: navigation.navigate,
    cbaAccountId,
    currentAutopayChangeRequest,
    hasAutopay: !!autopaySettingsData?.loan_account_settings?.autopay,
    loading: autopaySettingsLoading,
    deeplinkModal: false,
  });

  return (
    <>
      <StyledText variant="caption" mt="l">
        {t('Content.Transaction.ToMakeOngoingChangesView')}{' '}
        <StyledText variant="caption" color="link" onPress={onSetupAutopay}>
          {t('Content.Transaction.AutopaySettings')}
        </StyledText>
      </StyledText>

      <StyledText variant="caption" mt="s">
        {t(
          'Content.Transaction.UnloanInitiatesDirectDebitPaymentsInAccordanceWithOur',
        )}{' '}
        <Link variant="caption" href={t('Link.DirectDebitTerms')}>
          {t('Content.Transaction.DirectDebitsServiceAgreement')}
        </Link>
        {t('Content.Transaction.AndYourInstructionsAbove')}
      </StyledText>
    </>
  );
}

type TransactionViewProps = {
  transaction?: Maybe<Cb_Transaction>;
  loading?: boolean;
  transactionId: string;
  cbaAccountId: string;
  navigation: Props['navigation'];
};

export function TransactionView({
  transaction,
  loading = false,
  transactionId,
  cbaAccountId,
  navigation,
}: TransactionViewProps) {
  const { theme } = useDripsyTheme();
  const { flags } = useContext(FeatureFlagsContext);

  const {
    date,
    type,
    description,
    reference,
    cba_receipt_id: receiptId,
    channel_type: channelType,
    creditor_reference: creditorReference,
    identification,
    scheme_name: schemeName,
  } = transaction ?? {};

  const extraIncomingTxnFieldsMapping = useMemo(
    () =>
      new Map([
        ['CreditorReference', creditorReference],
        ['Identification', identification],
        ['SchemeName', schemeName],
      ]),
    [creditorReference, identification, schemeName],
  );

  const isIncomingTransaction =
    type === Cb_TransactionType.Repayment ||
    type === Cb_TransactionType.PaymentMade;

  const validExtraNppFields = useMemo(
    () =>
      Array.from(extraIncomingTxnFieldsMapping.keys()).filter(
        (key) => !!extraIncomingTxnFieldsMapping.get(key),
      ),
    [extraIncomingTxnFieldsMapping],
  );

  return (
    <>
      {loading ? (
        <View
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Skeleton show height={theme.space.$56} width={160} />

          <View sx={{ mt: '$8' }}>
            <Skeleton show height={theme.space.$20} width={100} />
          </View>
        </View>
      ) : (
        <>
          <Text
            role="heading"
            testID={TestID.Transaction.TransactionAmount}
            variant="xlNumber"
            sx={{ textAlign: 'center' }}
          >
            {transaction ? getTransactionAmountLabel(transaction) : ''}
          </Text>

          <StyledText textAlign="center">
            {type ? getTransactionTypeLabel(type, channelType) : ''}
          </StyledText>

          <StyledText
            textAlign="center"
            color="secondaryContent"
            testID={TestID.Transaction.TransactionDescription}
          >
            {description ?? ''}
          </StyledText>
        </>
      )}

      <DataRowGroup>
        <DataRow
          loading={loading}
          label={t('Content.Approvals.Status')}
          caption={t('Content.Transaction.Processed')}
          captionTestId={TestID.Transaction.TransactionStatus}
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label={t('Content.Approvals.When')}
          caption={safelyFormatDate(date, DEFAULT_DATE_DISPLAY_FORMAT) ?? ''}
          captionTestId={TestID.Transaction.TransactionDate}
        />

        {reference && flags.ENABLE_TRANSFER_REFERENCE_ID ? (
          <>
            <DataRowSeparator />
            <DataRow
              loading={loading}
              label={t('Content.Transaction.Reference')}
              caption={reference}
              captionTestId={TestID.Transaction.TransactionReference}
            />
          </>
        ) : null}
        {receiptId ? (
          <>
            <DataRowSeparator />
            <DataRow
              loading={loading}
              label={t('Content.Transaction.ReceiptNo')}
              caption={receiptId}
              captionTestId={TestID.Transaction.TransactionReceiptNo}
            />
          </>
        ) : null}
      </DataRowGroup>

      {flags.ENABLE_NPP_EXTRA_INCOMING_TXN_FIELDS &&
      isIncomingTransaction &&
      validExtraNppFields.length > 0 ? (
        <DataRowGroup>
          {validExtraNppFields.map((key, index, { length }) => {
            const value = extraIncomingTxnFieldsMapping.get(key) as string;
            return (
              <>
                <DataRow
                  loading={loading}
                  label={t(`Content.Transaction.${key}` as TranslateKeys)}
                  caption={value}
                />
                {index + 1 < length ? <DataRowSeparator /> : null}
              </>
            );
          })}
        </DataRowGroup>
      ) : null}

      {!loading && isAutopayDebit(channelType) && (
        <AutopayDebitContent
          transactionId={transactionId}
          cbaAccountId={cbaAccountId}
          navigation={navigation}
        />
      )}
    </>
  );
}

export function Transaction({ navigation, route }: Props) {
  const { cbaAccountId, transactionId } = route.params;

  const { data, error, loading, refetch } = useGetLoanTransactionByIdQuery({
    variables: {
      cba_account_id: cbaAccountId,
      loan_transaction_id: transactionId,
    },
    context: {
      sentryContext: {
        cbaAccountId,
        transactionId,
      },
    },
  });

  const transaction = data?.transaction;

  const onClose = useCallback(() => navigation.goBack(), [navigation]);

  return (
    <ModalScreenContainer onClose={onClose} hideBackButton scrollable>
      <ScreenErrorFallback
        error={error}
        displayMessage={t('Content.Common.Error.FailFetchTransaction')}
        refetch={refetch}
      >
        <TransactionView
          loading={loading}
          transaction={transaction}
          transactionId={transactionId}
          cbaAccountId={cbaAccountId}
          navigation={navigation}
        />
      </ScreenErrorFallback>
    </ModalScreenContainer>
  );
}
