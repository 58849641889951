/**
 * This context provider is the handler for a co-borrower to join a loan
 * application. It will:
 * - check whether user is authenticated and if there is an invite code
 * stored in the SecureStore
 * - call a mutation to join the application with the found invite code
 * - handle errors returned from the mutation call
 * - if all good, redirects user to continue the application / home screen
 */
import { gql } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../Auth/hooks';
import { clearInviteCode, getInviteCode } from '../Auth/inviteCodeStore';
import {
  Accept_Application_Invite_Error,
  Loan_Application_Type_Enum,
  useAcceptApplicationInviteMutation,
} from '../generated/graphql';
import { AppStackParams } from '../navigation/types/navTypes';
import { Screen } from '../navigation/types/screens';
import { AppLoading } from '../ui/organisms/AppLoading';
import { createContext } from './createContext';
import { safelyCallMutation } from './hooks/errorUtils';

export const ACCEPT_APPLICATION_INVITE = gql`
  mutation AcceptApplicationInvite($invite_code: String!) {
    accept_application_invite(invite_code: $invite_code) {
      loan_application {
        id
        type
      }
      accept_application_invite_error
    }
  }
`;

type Props = {
  children: JSX.Element;
};

export type JoinApplicationErrorType =
  | Accept_Application_Invite_Error
  | 'EXCEPTION';

export type JoinApplicationContextValue = {
  loading: boolean;
  resetLoanApplication: () => void;
} & Partial<{
  loanApplicationId: string;
  loanApplicationType: Loan_Application_Type_Enum;
  errorType: JoinApplicationErrorType;
  initialRouteName: keyof AppStackParams;
}>;

const [useJoinApplication, JoinApplicationProvider] =
  createContext<JoinApplicationContextValue | null>('JoinApplication');

export function JoinApplicationInvite({ children }: Props) {
  const [acceptInvite, { error: acceptError }] =
    useAcceptApplicationInviteMutation();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [errorType, setErrorType] = useState<
    JoinApplicationErrorType | undefined
  >();
  const [joinedLoanApplicationId, setJoinedLoanApplicationId] = useState<
    string | undefined
  >(undefined);
  const [loanApplicationType, setLoanApplicationType] = useState<
    Loan_Application_Type_Enum | undefined
  >(undefined);

  useEffect(() => {
    const joinInvite = async () => {
      // If joinedLoanApplicationId exists, this means we have joined the
      // current application
      if (joinedLoanApplicationId) {
        setLoading(false);
        return;
      }

      const inviteCode = await getInviteCode();

      if (inviteCode && isAuthenticated) {
        // This mutation will return a successful response in most error cases
        // as they are not really actual errors.
        // Hence you must check for an error code in the response
        const [res] = await safelyCallMutation(acceptInvite, {
          variables: {
            invite_code: inviteCode,
          },
          context: {
            sentryContext: {
              inviteCode,
            },
          },
        });

        await clearInviteCode();

        if (res == null) {
          // If this is null, acceptError is most likely populated and this may
          // be redundant
          setErrorType('EXCEPTION');
          setLoading(false);
          return;
        }

        const acceptApplicationInviteErrorType =
          res.data?.accept_application_invite?.accept_application_invite_error;

        if (acceptApplicationInviteErrorType) {
          setErrorType(acceptApplicationInviteErrorType);
          setLoading(false);
          return;
        }

        const { id: loanApplicationIdResult, type: loanApplicationTypeResult } =
          res.data?.accept_application_invite?.loan_application ?? {};

        if (loanApplicationIdResult && loanApplicationTypeResult) {
          setJoinedLoanApplicationId(loanApplicationIdResult);
          setLoanApplicationType(loanApplicationTypeResult);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
    };

    joinInvite();
  }, [acceptInvite, isAuthenticated, joinedLoanApplicationId]);

  const getInitialRouteName = (): keyof AppStackParams | undefined => {
    if (!isAuthenticated) {
      return undefined;
    }

    if (errorType === Accept_Application_Invite_Error.ExistingMember) {
      // If current user is an existing member (edge case!),
      // just navigate to Home screen
      return Screen.MAIN_NAVIGATOR;
    }

    if (acceptError || errorType) {
      // This component will show a different text depending on the errorType
      return Screen.INVALID_APPLICATION_INVITE;
    }

    if (joinedLoanApplicationId) {
      return Screen.LOAN_APPLICATION_V2;
    }

    return Screen.MAIN_NAVIGATOR;
  };
  const initialRouteName = getInitialRouteName();

  const contextValue = useMemo<JoinApplicationContextValue>(
    () => ({
      loading,
      loanApplicationId: joinedLoanApplicationId,
      loanApplicationType,
      initialRouteName,
      errorType,
      resetLoanApplication: () => setJoinedLoanApplicationId(undefined),
    }),
    [
      loading,
      joinedLoanApplicationId,
      loanApplicationType,
      initialRouteName,
      errorType,
    ],
  );

  if (loading) {
    return <AppLoading />;
  }

  return (
    <JoinApplicationProvider value={contextValue}>
      {children}
    </JoinApplicationProvider>
  );
}

export { useJoinApplication, JoinApplicationProvider };
