import { useCallback, useRef, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Change_Personal_Details_Error_Type,
  useMfaLoginVerifyEmailMutation,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { EmailVerify } from '../components/EmailVerify';
import { useMFALoginInit } from '../hooks/useMFALoginInit';
import { PersonalDetailsModalScreenProps } from '../navigation/types';
import getErrorMessage from '../utils/getErrorMessage';

type MobileChangeEmailVerifyProps =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY>;

export const MobileChangeEmailVerify = ({
  navigation,
  route,
}: MobileChangeEmailVerifyProps) => {
  const auth0MfaSessionId = useRef<string>(route.params.auth0MfaSessionId);

  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    mfaLoginVerifyEmail,
    { loading: mfaLoginVerifyEmailLoading, error: mfaLoginVerifyEmailError },
  ] = useMfaLoginVerifyEmailMutation();

  const {
    mfaLoginInit,
    loading: mfaLoginInitLoading,
    error: mfaLoginInitError,
  } = useMFALoginInit();

  const onSubmit = useCallback(
    async ({ code }: { code: string }) => {
      setValidationErrorMessage(undefined);
      setErrorMessage(undefined);

      const [response] = await safelyCallMutation(mfaLoginVerifyEmail, {
        variables: {
          auth0_mfa_session_id: auth0MfaSessionId.current,
          otp: code,
        },
        context: {
          sentryContext: {
            description: 'Mobile Change Request - Verify Email',
          },
        },
      });

      const errorType = response?.data?.mfa_login_verify_email.error_type;

      if (errorType) {
        const message = getErrorMessage(errorType);

        if (errorType === Change_Personal_Details_Error_Type.SystemError) {
          setErrorMessage(message);
          return;
        }

        setValidationErrorMessage(message);
        return;
      }

      if (!response?.data?.mfa_login_verify_email.success) {
        const message = t(
          'Content.PersonalDetails.Common.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(Screen.PERSONAL_DETAILS_MODAL, {
        screen: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY,
        params: {
          auth0MfaSessionId: auth0MfaSessionId.current,
        },
      });
    },
    [
      mfaLoginVerifyEmail,
      auth0MfaSessionId,
      navigation,
      setErrorMessage,
      setValidationErrorMessage,
    ],
  );

  const onPressResend = useCallback(async () => {
    setValidationErrorMessage(undefined);
    setErrorMessage(undefined);

    const [response] = await safelyCallMutation(mfaLoginInit, {
      context: {
        sentryContext: {
          description: 'Mobile Change Request(Resend Code) - Verify Email',
        },
      },
    });

    const errorType = response?.data?.mfa_login_init.error_type;

    if (errorType || !response?.data?.mfa_login_init.auth0_mfa_session_id) {
      const message = t('Content.PersonalDetails.Common.ResendFailed');
      setErrorMessage(message);
      return;
    }

    auth0MfaSessionId.current =
      response?.data?.mfa_login_init.auth0_mfa_session_id;
  }, [
    mfaLoginInit,
    auth0MfaSessionId,
    setErrorMessage,
    setValidationErrorMessage,
  ]);

  return (
    <EmailVerify
      screen={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY}
      title={t('Content.PersonalDetails.Common.VerifyEmailTitle')}
      description={t('Content.PersonalDetails.Common.VerifyEmailContent')}
      inputValidationError={validationErrorMessage}
      errorMessage={
        mfaLoginVerifyEmailError || mfaLoginInitError
          ? t('Content.PersonalDetails.Common.UnableToProcessRequest')
          : errorMessage
      }
      loading={mfaLoginVerifyEmailLoading}
      testID={TestID.PersonalDetails.ChangeMobileVerifyEmail}
      navigation={navigation}
      onSubmit={onSubmit}
      onPressResend={!mfaLoginInitLoading ? onPressResend : undefined}
    />
  );
};
