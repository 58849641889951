import { version } from '../../../../package.json';

export const APP_VERSION = version;
export const BUILD_NUMBER = 'N/A';
/**
 * On the web, these __VALUES__ will be provided
 * at compile time by a webpack plugin.
 *
 * The actual value came from git repository,
 * and will be cached by webpack.
 * E.g. __COMMIT_HASH__ is the output of `git rev-parse HEAD`
 *
 * This has some caveats that might cause
 * the actual value and the displayed value
 * to be out of sync on local env.
 *
 * Caveats:
 * 1. DefinePlugin used to provide the value doesn't watch git changes.
 * E.g. after pulling new tags, the displayed version still use old version
 * because webpack doesn't know the latest tag has been updated.
 * This can be fixed by using runtime value for define plugin instead
 * https://webpack.js.org/plugins/define-plugin/#runtime-values-via-runtimevalue
 * But to do that, we need to tell webpack which
 * files to watch when git has been updated.
 */
export const COMMIT_HASH = __COMMIT_HASH__;

export const VERSION_STRING = `${APP_VERSION}(${COMMIT_HASH?.slice(0, 8)})`;
