import { gql } from '@apollo/client';
import { Text, useDripsyTheme, View } from 'dripsy';
import { useCallback, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  Employment_Type_Enum,
  Income_Type_Enum,
  IncomeSupportingDocumentUploadIncomeDetailQuery,
  useIncomeSupportingDocumentsSubscription,
  useIncomeSupportingDocumentUploadIncomeDetailQuery,
} from '../../generated/graphql';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { TextList } from '../../ui/v2/TextList';
import { useDocumentPicker } from '../../utils/hooks/useDocumentPicker';
import { useExperimentFeatureFlag } from '../../utils/hooks/useExperimentFeatureFlag';
import {
  getErrorMessage,
  useIncomeSupportingDocumentUpload,
} from '../../utils/hooks/useSupportingDocumentUpload';
import { assertUnreachable } from '../../utils/typesHelpers';
import { DocumentRow } from '../../VerifyFinancials/components/DocumentRow';
import { mapMimeTypeToDocumentIconType } from '../../VerifyFinancials/utils/mapMimeTypeToDocumentIconType';
import { DocumentUpload } from '../components/DocumentUpload';
import { IncomeSummaryCard } from '../components/IncomeSummaryCard';
import { UploadOverlay } from '../components/UploadOverlay';

export const IncomeSupportingDocumentsSubscription = gql`
  subscription IncomeSupportingDocuments($incomeId: uuid!) {
    incomeSupportingDocs: income_supporting_document(
      where: {
        income_id: { _eq: $incomeId }
        file_upload: { status: { _neq: PENDING_UPLOAD } }
      }
      order_by: { file_upload: { created_at: desc } }
    ) {
      id
      file_upload {
        id
        status
        mimeType: mime_type
        originalName: original_name
        sizeInBytes: size_in_bytes
        xaiDocumentId: xai_document_id
        createdAt: created_at
      }
    }
  }
`;

export const IncomeSupportingDocumentsScreenQuery = gql`
  query IncomeSupportingDocumentUploadIncomeDetail($incomeId: uuid!) {
    income_by_pk(id: $incomeId) {
      id
      amount
      frequency
      income_type
      loan_application_id
      income_owners {
        id
        applicant {
          id
          latest_first_name
          latest_full_name
        }
      }
      employment_income {
        id
        annual_allowances
        annual_bonus
        annual_commissions
        annual_overtime
        employer
        employment_type
      }
      rental_income {
        id
        address {
          id
          short_address_format
        }
      }
    }
  }
`;

export const IncomeSupportingDocumentUploadIncomeDetailFragment = gql`
  fragment IncomeSupportingDocumentUpload_IncomeDetail on income {
    id
    income_type
    loan_application_id
    income_owners {
      id
      applicant {
        id
        latest_first_name
        latest_full_name
      }
    }
    employment_income {
      id
      employer
      employment_type
    }
    rental_income {
      id
      address {
        id
        short_address_format
      }
    }
  }
`;

export function getTitleAndCaptionV2(
  income: IncomeSupportingDocumentUploadIncomeDetailQuery['income_by_pk'],
): {
  title: string;
  captions: {
    title?: string;
    intro: string;
    items: string[];
  }[];
  tip?: {
    title: string;
    content: string;
  };
} {
  const emptyTitleAndCaptions = { title: '', captions: [] };

  const incomeType = income?.income_type;
  if (!incomeType) {
    return emptyTitleAndCaptions;
  }

  switch (incomeType) {
    case Income_Type_Enum.Employment: {
      const employmentIncome = income?.employment_income;
      if (!employmentIncome) {
        return emptyTitleAndCaptions;
      }

      const employmentType = employmentIncome.employment_type;

      if (employmentType === Employment_Type_Enum.SelfEmployed) {
        return {
          title: t(
            `Content.IncomeSupportingDocumentUpload.${incomeType}.Title`,
          ),
          captions: [
            {
              title: t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.Title`,
              ),
              intro: t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.Intro`,
              ),
              items: [],
            },
            {
              title: t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.Title`,
              ),
              intro: t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.Intro`,
              ),
              items: [
                t(
                  `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.ItemOne`,
                ),
                t(
                  `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.ItemTwo`,
                ),
              ],
            },
            {
              intro: t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionThree.Intro`,
              ),
              items: [
                t(
                  `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionThree.ItemOne`,
                ),
                t(
                  `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionThree.ItemTwo`,
                ),
              ],
            },
          ],
        };
      }

      return {
        title: t(`Content.IncomeSupportingDocumentUpload.${incomeType}.Title`),
        captions: [
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.Intro`,
            ),
            items: [
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.ItemOne`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionOne.ItemTwo`,
              ),
            ],
          },
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.Intro`,
            ),
            items: [
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.ItemOne`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.${employmentType}.CaptionTwo.ItemTwo`,
              ),
            ],
          },
        ],
      };
    }

    case Income_Type_Enum.Rental:
      return {
        title: t(`Content.IncomeSupportingDocumentUpload.${incomeType}.Title`),
        captions: [
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.Intro`,
            ),
            items: [
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.ItemOne`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.ItemTwo`,
              ),
            ],
          },
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.Intro`,
            ),
            items: [],
          },
        ],
      };

    case Income_Type_Enum.GovernmentPayments:
      return {
        title: t(`Content.IncomeSupportingDocumentUpload.${incomeType}.Title`),
        captions: [
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.Intro`,
            ),
            items: [
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.ItemOne`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.ItemTwo`,
              ),
            ],
          },
          {
            title: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.Title`,
            ),
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.Intro`,
            ),
            items: [
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.ItemOne`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.ItemTwo`,
              ),
              t(
                `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionTwo.ItemThree`,
              ),
            ],
          },
        ],
      };

    case Income_Type_Enum.ShareDividends:
      return {
        title: t(`Content.IncomeSupportingDocumentUpload.${incomeType}.Title`),
        captions: [
          {
            intro: t(
              `Content.IncomeSupportingDocumentUpload.${incomeType}.CaptionOne.Intro`,
            ),
            items: [],
          },
        ],
        tip: {
          title: t(
            `Content.IncomeSupportingDocumentUpload.${incomeType}.Tip.Title`,
          ),
          content: t(
            `Content.IncomeSupportingDocumentUpload.${incomeType}.Tip.Content`,
          ),
        },
      };

    case Income_Type_Enum.SavingsInterest:
      return emptyTitleAndCaptions;

    default:
      assertUnreachable(incomeType);
      return emptyTitleAndCaptions;
  }
}

export function getTitleAndCaption(
  income: IncomeSupportingDocumentUploadIncomeDetailQuery['income_by_pk'],
) {
  const emptyTitleAndCaption = { title: '', caption: '', captionItems: [] };
  if (income?.income_type == null) {
    return emptyTitleAndCaption;
  }
  switch (income.income_type) {
    case Income_Type_Enum.Employment:
      if (
        income.employment_income?.employment_type ===
        Employment_Type_Enum.SelfEmployed
      ) {
        return {
          title: t(
            'Content.IncomeSupportingDocumentUpload.SELF_EMPLOYED.Title',
          ),
          caption: t(
            'Content.IncomeSupportingDocumentUpload.SELF_EMPLOYED.Caption',
          ),
          captionItems: [
            t(
              'Content.IncomeSupportingDocumentUpload.SELF_EMPLOYED.CaptionItemOne',
            ),
            t(
              'Content.IncomeSupportingDocumentUpload.SELF_EMPLOYED.CaptionItemTwo',
            ),
          ],
        };
      }
      return {
        title: t('Content.IncomeSupportingDocumentUpload.EMPLOYMENT.Title'),
        caption: t('Content.IncomeSupportingDocumentUpload.EMPLOYMENT.Caption'),
        captionItems: [
          t('Content.IncomeSupportingDocumentUpload.EMPLOYMENT.CaptionItemOne'),
          t('Content.IncomeSupportingDocumentUpload.EMPLOYMENT.CaptionItemTwo'),
          t(
            'Content.IncomeSupportingDocumentUpload.EMPLOYMENT.CaptionItemThree',
          ),
        ],
      };
    case Income_Type_Enum.GovernmentPayments:
      return {
        title: t(
          'Content.IncomeSupportingDocumentUpload.GOVERNMENT_PAYMENTS.Title',
        ),
        caption: t(
          'Content.IncomeSupportingDocumentUpload.GOVERNMENT_PAYMENTS.Caption',
        ),
        captionItems: [
          t(
            'Content.IncomeSupportingDocumentUpload.GOVERNMENT_PAYMENTS.CaptionItemOne',
          ),
          t(
            'Content.IncomeSupportingDocumentUpload.GOVERNMENT_PAYMENTS.CaptionItemTwo',
          ),
        ],
      };
    case Income_Type_Enum.Rental:
      return {
        title: t('Content.IncomeSupportingDocumentUpload.RENTAL.Title'),
        caption: t('Content.IncomeSupportingDocumentUpload.RENTAL.Caption'),
        captionItems: [
          t('Content.IncomeSupportingDocumentUpload.RENTAL.CaptionItemOne'),
          t('Content.IncomeSupportingDocumentUpload.RENTAL.CaptionItemTwo'),
          t('Content.IncomeSupportingDocumentUpload.RENTAL.CaptionItemThree'),
        ],
      };
    case Income_Type_Enum.SavingsInterest:
    case Income_Type_Enum.ShareDividends:
      return {
        title: t(
          'Content.IncomeSupportingDocumentUpload.INVESTMENT_INCOME.Title',
        ),
        caption: t(
          'Content.IncomeSupportingDocumentUpload.INVESTMENT_INCOME.Caption',
        ),
        captionItems: [],
      };
    default:
      assertUnreachable(income.income_type);
      return emptyTitleAndCaption;
  }
}

type Props =
  SingleModalStackScreenProps<Screen.INCOME_SUPPORTING_DOCUMENT_MODAL>;

export function IncomeSupportingDocumentUpload({ route, navigation }: Props) {
  const loanApplicationId = route.params?.loanApplicationId ?? '';

  const {
    uploadIncomeSupportingDocument,
    isUploading,
    error: uploadError,
  } = useIncomeSupportingDocumentUpload(loanApplicationId);
  const incomeId = route.params?.incomeId;
  const {
    error: incomeDetailQueryError,
    loading: incomeDetailQueryLoading,
    data: incomeDetailQueryResult,
    refetch,
  } = useIncomeSupportingDocumentUploadIncomeDetailQuery({
    variables: {
      incomeId: incomeId || '',
    },
    context: {
      sentryContext: {
        incomeId,
      },
    },
  });

  const {
    featureFlag: enableEvidenceScreenUplift,
    loading: enableEvidenceScreenUpliftLoading,
  } = useExperimentFeatureFlag(FeatureFlag.EnableEvidenceScreenUplift);
  const { title, caption, captionItems } = getTitleAndCaption(
    incomeDetailQueryResult?.income_by_pk,
  );
  const { theme } = useDripsyTheme();

  const {
    title: titleV2,
    captions,
    tip,
  } = getTitleAndCaptionV2(incomeDetailQueryResult?.income_by_pk);

  const { makeDocumentPickerHandler } = useDocumentPicker();

  const incomeSupportingDocsSubscription =
    useIncomeSupportingDocumentsSubscription({
      variables: {
        incomeId: incomeId || '',
      },
      skip: !incomeId,
    });
  const { loading, error: incomeSupportingDocsSubscriptionError } =
    incomeSupportingDocsSubscription;
  const incomeSupportingDocs =
    incomeSupportingDocsSubscription.data?.incomeSupportingDocs || [];
  const openDocumentPicker = useRef(
    makeDocumentPickerHandler({
      onDocumentSelect: async (selectedDocs) => {
        await uploadIncomeSupportingDocument({
          document: selectedDocs,
          incomeId: incomeId || '',
        });
      },
    }),
  );
  const onUploadDocumentPress = useCallback(async () => {
    await openDocumentPicker.current();
  }, []);

  let errorMessage = null;

  if (incomeSupportingDocsSubscriptionError) {
    errorMessage = t('Content.Common.Error.FailFetchUploadedDocuments');
  } else if (uploadError) {
    errorMessage = getErrorMessage(uploadError);
  }

  const descriptionComparableCount = 7;
  const bodyPlaceholderHeight =
    theme.sizes.minRowHeight * descriptionComparableCount;

  if (enableEvidenceScreenUpliftLoading) {
    return (
      <ModalScreenContainer onClose={navigation.goBack} hideBackButton>
        <Skeleton show height={theme.space.$56} width="100%" />
        <Separator spacer />
        <Skeleton show height={bodyPlaceholderHeight} width="100%" />
      </ModalScreenContainer>
    );
  }

  return (
    <>
      <ModalScreenContainer
        onClose={navigation.goBack}
        headerText={enableEvidenceScreenUplift ? titleV2 : title}
        scrollable
        hideBackButton
        loading={incomeDetailQueryLoading || isUploading}
      >
        <ScreenErrorFallback
          error={incomeDetailQueryError}
          displayMessage={t(
            'Content.IncomeSupportingDocumentUpload.Error.FailFetchIncomeDetail',
          )}
          refetch={refetch}
        >
          <ErrorRow message={errorMessage} mb="l" />
          {enableEvidenceScreenUplift ? (
            <>
              <IncomeSummaryCard
                income={incomeDetailQueryResult?.income_by_pk}
              />
              {captions.map((c) => (
                <View
                  sx={{
                    mb: 16,
                    mt: c.title ? 0 : -16,
                  }}
                  key={c.title}
                >
                  <Text variant="subHeading2" sx={{ mb: 8 }}>
                    {c.title}
                  </Text>
                  <Text>{c.intro}</Text>
                  <TextList
                    listSx={{
                      pl: '$8',
                      py: '$8',
                    }}
                    ordered={false}
                    items={c.items}
                  />
                  <Text />
                </View>
              ))}
              {tip ? (
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>{tip.title}</Text>{' '}
                  {tip.content}
                </Text>
              ) : null}
            </>
          ) : (
            <>
              <Text>{caption}</Text>
              <TextList
                listSx={{
                  pl: '$8',
                  py: '$8',
                }}
                ordered={false}
                items={captionItems}
              />
            </>
          )}
          <DocumentUpload
            onUploadDocumentPress={onUploadDocumentPress}
            my="l"
            testID={TestID.IncomeSupportingDocumentUpload.UploadButton}
          />
          {loading ? (
            <>
              <Separator spacer />
              <Spinner />
              <Separator spacer />
            </>
          ) : null}
          {incomeSupportingDocs.map(
            ({
              file_upload: {
                id,
                originalName,
                mimeType,
                sizeInBytes,
                createdAt,
              },
            }) => (
              <DocumentRow
                key={id}
                name={originalName}
                sizeInBytes={sizeInBytes}
                createdAt={createdAt}
                iconType={mapMimeTypeToDocumentIconType(mimeType)}
              />
            ),
          )}
          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            width="100%"
            mb="l"
            mt="xl"
            testID={TestID.IncomeSupportingDocumentUpload.DoneButton}
            disabled={incomeDetailQueryLoading}
            onPress={navigation.goBack}
          />
        </ScreenErrorFallback>
      </ModalScreenContainer>
      {isUploading ? <UploadOverlay /> : null}
    </>
  );
}
