import { Pressable, Text, View } from 'dripsy';
import { ReactElement, useState } from 'react';
import * as React from 'react';
import Animated from 'react-native-reanimated';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { useCardHoverShadowStyle } from '../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../ui/utils/useCardTranslateYAnimatedStyle';
import { useIsMobile } from '../../utils/hooks/useBreakpoint';
import { MAX_MOBILE_WIDTH } from '../../utils/NavConstants';

type Props = {
  caption: string | ReactElement;
  header: string | ReactElement;
  graphPlaceholder?: React.ReactNode;
  onPress?: () => void;
  disabled?: boolean;
  testID?: string;
};

export const InsightWidgetContainer = ({
  caption,
  header,
  graphPlaceholder,
  disabled = false,
  ...props
}: Props) => {
  const isMobile = useIsMobile();

  const [isHover, setIsHover] = useState(false);

  const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

  const shadowProps = useCardHoverShadowStyle({
    isHover,
    excludeInitialShadow: true,
  });

  return (
    <Animated.View style={animatedStyle}>
      <Pressable
        testID={TestID.Insights.InsightWidgetContainer}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPressIn={() => setIsHover(true)}
        onPressOut={() => setIsHover(false)}
        sx={{
          aspectRatio: 1,
          alignSelf: 'center',
          width: '100%',
          maxWidth: MAX_MOBILE_WIDTH,
          backgroundColor: '$inputBackground',
          borderRadius: '$card',
          overflow: 'visible',
          padding: '$20',
          ...shadowProps,
        }}
        disabled={disabled}
        {...props}
      >
        <Box maxHeight={90} flexShrink={1}>
          {graphPlaceholder}
        </Box>

        <View sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          {typeof caption === 'string' ? (
            <Text
              variant="sBody"
              sx={{
                fontWeight: 'medium',
                fontSize: isMobile ? '$13' : undefined,
                color: disabled ? '$darkerDisabled' : undefined,
              }}
            >
              {caption}
            </Text>
          ) : (
            caption
          )}
          {typeof header === 'string' ? (
            <Text
              variant={isMobile ? 'header' : 'lHeader'}
              sx={{
                color: disabled ? '$darkerDisabled' : undefined,
              }}
            >
              {disabled ? '--' : header}
            </Text>
          ) : (
            header
          )}
        </View>
      </Pressable>
    </Animated.View>
  );
};
