import { Pressable } from 'dripsy';
import { PressableProps, StyleSheet } from 'react-native';

import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { LoanDetailsSectionProps } from './types';
import {
  DisclosureSection,
  DisclosureSectionProps,
} from './YourUnloanCardDisclosureSection';
import { LoanDetailsSection } from './YourUnloanCardLoanDetailsSection';

type Props = LoanDetailsSectionProps &
  Partial<DisclosureSectionProps> & {
    screen: Screen;
    canBePressed: boolean;
    onPress: PressableProps['onPress'];
    showRequiredDisclosureError?: boolean;
    cardTestID?: string;
    loanApplicationType?: Loan_Application_Type_Enum;
  };

export function YourUnloanCard(props: Props) {
  const {
    screen,
    canBePressed,
    onPress,
    disclosureData,
    getDisclosureLoading,
    disclosureCheckboxLoading,
    onDisclosureCheckboxPress,
    onCheckboxLayout,
    showRequiredDisclosureError,
    cardTestID,
    loanApplicationType,
  } = props;

  const RowWrapper = canBePressed ? Pressable : Box;

  return (
    <>
      <Box bg="shapeBg" borderRadius="card" p="m">
        <RowWrapper
          style={styles.headerButton}
          onPress={canBePressed ? onPress : undefined}
          testID={cardTestID}
        >
          <Box flex={1}>
            <StyledText fontWeight="semiBold" color="primaryContent">
              {t('Content.YourUnloan.LoanCard.LoanDetails')}
            </StyledText>
          </Box>
          {canBePressed ? (
            <StyledIcon
              name="chevronRight"
              family="svg"
              size="s"
              color="secondaryContent"
            />
          ) : null}
        </RowWrapper>
        <LoanDetailsSection {...props} />
        {disclosureData ? (
          <DisclosureSection
            screen={screen}
            disclosureData={disclosureData}
            getDisclosureLoading={getDisclosureLoading}
            disclosureCheckboxLoading={disclosureCheckboxLoading}
            onDisclosureCheckboxPress={onDisclosureCheckboxPress}
            onCheckboxLayout={onCheckboxLayout}
            loanApplicationType={loanApplicationType}
          />
        ) : null}
      </Box>
      {showRequiredDisclosureError ? (
        <Box flexDirection="row" alignItems="center" mx="m" mt="s">
          <StyledIcon
            name="alert-circle"
            family="ionicons"
            color="error"
            size="m"
            mr="xs"
          />
          <StyledText variant="caption" color="error">
            {t('Content.YourUnloan.LoanCard.RequiredError')}
          </StyledText>
        </Box>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  headerButton: { flex: 1, alignItems: 'center', flexDirection: 'row' },
});
