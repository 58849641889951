import { withLDProvider as lDWithLDProvider } from 'launchdarkly-react-client-sdk';

import { anonymousContext } from './LaunchDarklyClient.web';

const clientSideId = process.env.LD_CLIENT_SIDE_ID ?? '';

export function withLDProvider(Component: React.ComponentType) {
  return lDWithLDProvider({
    clientSideID: clientSideId,
    context: anonymousContext,
  })(Component);
}
