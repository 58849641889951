import { Main } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { useDripsyTheme, useSx, View } from 'dripsy';
import * as React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { ArrowBackButton } from '../../components/ArrowBackButton';
import { TopNavBar } from '../../Home/navigation/components/TopNavBar';
import { useView } from '../../utils/hooks/useBreakpoint';
import { isWeb } from '../../utils/platformUtils';
import { Color } from '../types';
import { Box, BoxProps } from './Box';
import { Copyright } from './Copyright';
import { ScrollContainer } from './ScrollContainer';

export type Props = BoxProps & {
  safeAreaTop?: boolean;
  safeAreaBottom?: boolean;
  wideContentContainer?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  bgTop?: Color;
  scrollable?: boolean;
  contentContainer?: boolean;
};

const TopSafeArea = () => {
  const { isDesktopWebView, isMobileNativeView, isMobileWebView } = useView();
  const insets = useSafeAreaInsets();
  const { theme } = useDripsyTheme();

  if (isDesktopWebView) {
    return <View style={{ height: theme.space.$96 }} />;
  }

  if (isMobileWebView) {
    return null;
  }

  if (isMobileNativeView) {
    return <View style={{ height: insets.top + theme.space.$24 }} />;
  }

  return null;
};

export type UpliftScreenContainerProps = React.ComponentProps<
  typeof ScrollContainer
> & {
  showTopNavBar?: boolean;
  wideContentContainer?: boolean;
  onPressBack?: () => void;
  safeAreaBottom?: boolean;
  contentContainer?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  hideCopyright?: boolean;
};

export function UpliftScreenContainer({
  children,
  showTopNavBar = true,
  onPressBack,
  safeAreaBottom,
  contentContainer,
  containerStyle,
  wideContentContainer = false,
  hideCopyright = true,
  ...props
}: UpliftScreenContainerProps) {
  const { isMobileNativeView, isMobileWebView } = useView();
  const sx = useSx();
  const isCurrentScreenFocused = useIsFocused();

  return (
    <ScrollContainer
      bg="bg"
      contentContainerStyle={{ flexGrow: 1 }}
      {...props}
      aria-hidden={!isCurrentScreenFocused}
    >
      {showTopNavBar && isMobileWebView ? <TopNavBar /> : null}
      <TopSafeArea />

      <ScreenContainer
        safeAreaTop={false}
        safeAreaBottom={safeAreaBottom}
        bg="bg"
        pb="xl"
        contentContainer={contentContainer}
        containerStyle={containerStyle}
        wideContentContainer={wideContentContainer}
      >
        <Main style={sx({ px: '$16' })}>
          {onPressBack ? (
            <ArrowBackButton
              onPress={onPressBack}
              testID={TestID.ScreenContainer.BackButton}
              style={sx({ width: 32 })}
            />
          ) : null}
          {children}
        </Main>
      </ScreenContainer>
      {isMobileNativeView && hideCopyright ? null : (
        <Copyright copyrightText={t('Content.App.Copyright')} />
      )}
    </ScrollContainer>
  );
}

export function ScreenContainer({
  children,
  safeAreaTop = true,
  safeAreaBottom = true,
  wideContentContainer = false,
  containerStyle: propsContainerStyle,
  bgTop,
  scrollable,
  contentContainer = true,
  ...props
}: Props) {
  const containerStyle: ViewStyle = {};
  const insets = useSafeAreaInsets();

  if (safeAreaTop) {
    containerStyle.paddingTop = insets.top;
  }

  if (safeAreaBottom) {
    containerStyle.marginBottom = insets.bottom;
  }

  return (
    <Box flex={1} centered {...props} bg="bg">
      {bgTop ? (
        <Box
          bg={bgTop}
          position="absolute"
          width="100%"
          height={{ mobile: '50%', tablet: 0 }}
          top={0}
        />
      ) : null}
      {!isWeb || scrollable ? (
        <ScrollContainer
          contentContainerStyle={styles.scrollContainer}
          testID={TestID.ScrollContainer}
        >
          <Box
            flex={1}
            contentContainer={contentContainer}
            style={[containerStyle, propsContainerStyle]}
            alignItems="stretch"
          >
            {children}
          </Box>
        </ScrollContainer>
      ) : (
        <Box
          flex={1}
          contentContainer={contentContainer}
          wideContentContainer={wideContentContainer}
          style={[containerStyle, propsContainerStyle]}
          alignItems="stretch"
          overflow="visible"
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

const styles = StyleSheet.create({
  scrollContainer: { flexGrow: 1, alignItems: 'center' },
});
