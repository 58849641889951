import { useMemo } from 'react';
import { LayoutAnimation } from 'react-native';

type LayoutAnimationEasingFunction = keyof LayoutAnimation['Presets'];

/**
 * @deprecated
 * This was a hack that only works on native.
 * Currently there is no hooks equivalent for
 * componentWillUpdate or getSnapshotBeforeUpdate. Once there is, we should
 * use that instead: https://github.com/facebook/react/issues/15221
 * As of June 2023, React contributor has closed that issue as unresolvable.
 */
export const useLayoutAnimation = (
  deps: unknown[],
  easing: LayoutAnimationEasingFunction = 'easeInEaseOut',
): void => {
  useMemo(() => {
    LayoutAnimation[easing]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, easing]);
};
