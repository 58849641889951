import * as React from 'react';

import { Detected_Liability_Details_V2 } from '../../generated/graphql';
import { createContext } from '../../utils/createContext';
import { LoanDetailsFormValues } from '../components/LoanDetailsForm';
import { mapLoanDetailsFormValuesToDetectedLiabilityDetails } from './loanDetailsFormUtils';

type SetContextStateArgs = {
  formValues: LoanDetailsFormValues;
  detectedLiabilityIdentifier: string;
};

type DebtsWizardContextType = {
  setFormValuesToWizardState: (updates: SetContextStateArgs) => void;
  getMutationVariablesForSubmitLiabilityDetails: () => {
    detected_liabilities_details: Array<Detected_Liability_Details_V2>;
  };
};

const [useDebtsWizardContextV2, ProviderV2] =
  createContext<DebtsWizardContextType>('DebtsWizardContextV2');

function DebtsWizardContextProviderV2({
  ...otherProps
}: {
  children: React.ReactNode;
}) {
  // Using a mutable ref instead of state
  // because we only care about consolidating all values
  // across the wizard and only use the values when user submits
  // at the end of the wizard.
  const loanDetailsByLiabilityIdentifierRef = React.useRef<
    Record<string, LoanDetailsFormValues>
  >({});

  const setFormValuesToWizardState = React.useCallback(
    ({
      formValues,
      detectedLiabilityIdentifier,
    }: {
      formValues: LoanDetailsFormValues;
      detectedLiabilityIdentifier: string;
    }) => {
      loanDetailsByLiabilityIdentifierRef.current = {
        ...loanDetailsByLiabilityIdentifierRef.current,
        [detectedLiabilityIdentifier]: formValues,
      };
    },
    [],
  );

  const getMutationVariablesForSubmitLiabilityDetails = React.useCallback(
    () => ({
      detected_liabilities_details: Object.entries(
        loanDetailsByLiabilityIdentifierRef.current,
      ).map(([detectedLiabilityIdentifier, formValues]) =>
        mapLoanDetailsFormValuesToDetectedLiabilityDetails({
          detectedLiabilityIdentifier,
          formValues,
        }),
      ),
    }),
    [],
  );

  const value = React.useMemo(
    () => ({
      setFormValuesToWizardState,
      getMutationVariablesForSubmitLiabilityDetails,
    }),
    [getMutationVariablesForSubmitLiabilityDetails, setFormValuesToWizardState],
  );

  return <ProviderV2 value={value} {...otherProps} />;
}

export { useDebtsWizardContextV2, DebtsWizardContextProviderV2 };
