import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import ScheduledMaintenance from '../../components/ScheduledMaintenance';
import {
  Approval_Status_Enum,
  ApprovalRequestFragment,
  ApprovalRequestFragmentDoc,
  useApproveRequestMutation,
  useCancelRequestMutation,
  useDeclineRequestMutation,
} from '../../generated/graphql';
import { underMaintenanceAtom } from '../../Home/atoms/underMaintenanceAtom';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { notNil } from '../../utils/typesHelpers';

export const APPROVE_REQUEST = gql`
  mutation ApproveRequest($approval_request_id: uuid!) {
    approve_request(approval_request_id: $approval_request_id) {
      returning {
        approval_request {
          ...ApprovalRequest
        }
      }
    }
  }

  ${ApprovalRequestFragmentDoc}
`;

export const DECLINE_REQUEST = gql`
  mutation DeclineRequest($approval_request_id: uuid!) {
    decline_approval_request(approval_request_id: $approval_request_id) {
      returning {
        approval_request {
          ...ApprovalRequest
        }
      }
    }
  }

  ${ApprovalRequestFragmentDoc}
`;

export const CANCEL_REQUEST = gql`
  mutation CancelRequest($approval_request_id: uuid!) {
    cancel_approval_request(approval_request_id: $approval_request_id) {
      returning {
        approval_request {
          ...ApprovalRequest
        }
      }
    }
  }

  ${ApprovalRequestFragmentDoc}
`;

export const useApprovalControls = (
  approvalRequestId: string,
): {
  onPressApprove: () => Promise<void>;
  onPressDecline: () => Promise<void>;
  onPressCancel: () => Promise<void>;
  approveLoading: boolean;
  declineLoading: boolean;
  cancelLoading: boolean;
} => {
  const [approve, { loading: approveLoading }] = useApproveRequestMutation();
  const [decline, { loading: declineLoading }] = useDeclineRequestMutation();
  const [cancel, { loading: cancelLoading }] = useCancelRequestMutation();

  const onPressApprove = async () => {
    await approve({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });
  };

  const onPressDecline = async () => {
    await decline({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });
  };

  const onPressCancel = async () => {
    await cancel({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });
  };

  return {
    onPressApprove,
    onPressDecline,
    onPressCancel,
    approveLoading,
    declineLoading,
    cancelLoading,
  };
};

type ApprovalControlsProps = {
  loading?: boolean;
  approvalRequest: ApprovalRequestFragment;
  onApproveRequestCallback?: () => void;
  onDeclineRequestCallback?: () => void;
  onCancelRequestCallback?: () => void;
};

export function ApprovalControls({
  loading,
  approvalRequest,
  onApproveRequestCallback,
  onDeclineRequestCallback,
  onCancelRequestCallback,
}: ApprovalControlsProps) {
  const {
    onPressApprove: initialOnPressApprove,
    onPressDecline: initialOnPressDecline,
    onPressCancel: initialOnPressCancel,
    approveLoading,
    declineLoading,
    cancelLoading,
  } = useApprovalControls(approvalRequest.id);

  const onPressApprove = useCallback(async () => {
    await initialOnPressApprove();
    onApproveRequestCallback?.();
  }, [initialOnPressApprove, onApproveRequestCallback]);

  const onPressDecline = useCallback(async () => {
    await initialOnPressDecline();
    onDeclineRequestCallback?.();
  }, [initialOnPressDecline, onDeclineRequestCallback]);

  const onPressCancel = useCallback(async () => {
    await initialOnPressCancel();
    onCancelRequestCallback?.();
  }, [initialOnPressCancel, onCancelRequestCallback]);

  const isAppUnderMaintenance = useRecoilValue(underMaintenanceAtom);

  const requestStatus = approvalRequest.status;
  const myResponseStatus = approvalRequest.my_approval?.response?.status;

  const showCancelButton =
    requestStatus === Approval_Status_Enum.Pending &&
    myResponseStatus === Approval_Status_Enum.Approved;

  if (showCancelButton) {
    return (
      <Box mt="l" opacity={loading ? 0 : undefined}>
        <Button
          secondary
          label={t('Content.Approvals.CancelRequest')}
          onPress={onPressCancel}
          showSpinner={cancelLoading}
          disabled={loading || cancelLoading}
          width="100%"
          style={{ maxWidth: '100%' }}
        />
      </Box>
    );
  }

  const showApproveDeclineButtons =
    requestStatus !== Approval_Status_Enum.Cancelled &&
    myResponseStatus === Approval_Status_Enum.Pending;

  if (showApproveDeclineButtons) {
    return (
      <Box flexDirection="column" flexGrow={1}>
        <ScheduledMaintenance mt="m" />
        <Box
          mt="l"
          flexDirection="row"
          bottom={0}
          justifyContent="space-between"
          alignItems="stretch"
          opacity={loading ? 0 : undefined}
        >
          <Button
            secondary
            label={t('Content.Approvals.Decline')}
            color="error"
            flexGrow={1}
            mr="s"
            onPress={onPressDecline}
            showSpinner={declineLoading}
            disabled={loading || isAppUnderMaintenance || declineLoading}
          />
          <Button
            label={t('Content.Approvals.Approve')}
            onPress={onPressApprove}
            showSpinner={approveLoading}
            flexGrow={1}
            ml="s"
            disabled={loading || isAppUnderMaintenance || approveLoading}
          />
        </Box>
      </Box>
    );
  }

  return null;
}
