import { Text, useSx, View } from 'dripsy';
import { Image as ExpoImage } from 'expo-image';
import { ComponentProps } from 'react';

const DESKTOP_SOURCE_MAP = {
  onWhite: {
    default: {
      source: require('../../assets/google/desktop/google_on_white-59x18.png'),
      width: 59,
      height: 18,
    },
    hdpi: {
      source: require('../../assets/google/desktop/google_on_white_hdpi-119x36.png'),
      width: 119,
      height: 36,
    },
  },
  onNonWhite: {
    default: {
      source: require('../../assets/google/desktop/google_on_non_white-59x18.png'),
      width: 59,
      height: 18,
    },
    hdpi: {
      source: require('../../assets/google/desktop/google_on_non_white_hdpi-119x36.png'),
      width: 119,
      height: 36,
    },
  },
};

type GoogleLogoProps = {
  height: '18' | '36';
};
function GoogleLogo({ height: heightProp }: GoogleLogoProps) {
  // We don't do dark theme right now.
  const isDarkMode = false;
  const resolutionMap = isDarkMode
    ? DESKTOP_SOURCE_MAP.onNonWhite
    : DESKTOP_SOURCE_MAP.onWhite;

  const imageToUse =
    heightProp === '18' ? resolutionMap.default : resolutionMap.hdpi;
  const { source, width, height } = imageToUse;

  return (
    <ExpoImage source={source} style={{ width, height }} alt="Google logo" />
  );
}

type PoweredByGoogleProps = ComponentProps<typeof View>;

export function PoweredByGoogle({
  sx: sxProp,
  ...otherProps
}: PoweredByGoogleProps) {
  const sx = useSx();
  const resolvedStyle = sxProp && sx(sxProp);
  return (
    <View
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        ...resolvedStyle,
      }}
      {...otherProps}
    >
      <Text variant="caption">{t('Content.ApiAttribution.PoweredBy')}</Text>
      <GoogleLogo height="18" />
    </View>
  );
}
