import { useDripsyTheme, View } from 'dripsy';
import { useState } from 'react';
import * as React from 'react';
import Svg from 'react-native-svg';

import { TestID } from '../../../testID/constants';
import { VictoryPie } from '../../components/Chart';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { Colors } from '../../ui/theme';
import { Color } from '../../ui/types';
import { Skeleton } from '../../ui/v2/Skeleton';
import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import { formatCurrency } from '../../utils/currencyHelpers';
import { LoanDataProps } from '../types';
import { useChartConfig } from '../utils/chartConfig';
import { getLoanChartData } from '../utils/getLoanChartData';

export type LoanCircularProgressBarProps = {
  active?: boolean;
  loanData: LoanDataProps;
  secondaryBarColor?: Colors[keyof Colors];
  loading?: boolean;
};

export const LoanCircularProgressBar: React.FC<
  LoanCircularProgressBarProps
> = ({ active = true, loanData, secondaryBarColor, loading }) => {
  const { theme } = useDripsyTheme();

  const [chartContainerWidth, setChartContainerWidth] = useState<number>(300);
  const chartConfig = useChartConfig({
    chartContainerWidth,
    activeChart: active,
    secondaryBarColor,
    loading,
  });
  const { principalBalance, balance, availableRedrawBalance } = loanData;
  const textColor: Color = active ? undefined : 'disabledContent';
  return (
    <Box
      aspectRatio={1}
      flex={1}
      testID={TestID.LoanCircularProgressBar.Container}
      onLayout={(e) => {
        setChartContainerWidth(e.nativeEvent.layout.width);
      }}
      maxWidth="100%"
      alignSelf="center"
      width={300}
    >
      <Svg
        width="100%"
        height={chartContainerWidth}
        dataSet={chromaticIgnoreDataSet}
        aria-hidden
      >
        <VictoryPie
          width={chartContainerWidth}
          height={chartContainerWidth}
          data={getLoanChartData(principalBalance, availableRedrawBalance)}
          {...chartConfig}
        />
      </Svg>

      <Box position="absolute" left={0} right={0} top={0} bottom={0} centered>
        {loading ? (
          <>
            <View sx={{ mb: '$8' }}>
              <Skeleton show width={240} height={theme.space.$60} />
            </View>
            <View sx={{ mb: '$8' }}>
              <Skeleton show width={134} height={theme.space.$20} />
            </View>
          </>
        ) : (
          <>
            <StyledText
              variant="number"
              color={textColor}
              dataSet={chromaticIgnoreDataSet}
              testID={TestID.LoanCircularProgressBar.LoanAmount}
            >
              {formatCurrency(balance, { withFractionOnRoundedAmount: true })}
            </StyledText>

            <StyledText
              variant="default"
              color={textColor}
              dataSet={chromaticIgnoreDataSet}
              testID={TestID.LoanCircularProgressBar.RedrawAvailable}
            >
              {t('Content.HomeLoan.RedrawAvailable', {
                amount: formatCurrency(availableRedrawBalance, {
                  withFractionOnRoundedAmount: true,
                }),
              })}
            </StyledText>
          </>
        )}
      </Box>
    </Box>
  );
};
