import { parse } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { formatWithMask, Masks } from 'react-native-mask-input';
import type { DateTimePickerProps } from 'react-native-modal-datetime-picker';

import {
  Props as TextInputRowProps,
  TextInputRow,
} from '../../ui/organisms/TextInputRow';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { FormikControlProps } from './types';

export type Props = Omit<TextInputRowProps, 'value'> &
  FormikControlProps<Date | null> &
  Pick<DateTimePickerProps, 'maximumDate' | 'minimumDate'> & {
    onSubmitEditing?: () => void;
  };

const MaskPlaceholder = 'dd/mm/yyyy';
const DateFormat = 'dd/MM/yyyy';

const ValidInputRegex = /^[0-9/]*$/;

export const DatePickerRow: React.FC<Props> = ({
  setFieldTouched,
  setFieldValue,
  value,
  last,
  testID,
  ...props
}) => {
  const initialValue = safelyFormatDate(value, DateFormat) ?? '';
  const [inputValue, setInputValue] = useState<string>(initialValue);

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const onChangeText = useCallback(
    (text: string) => {
      const { masked } = formatWithMask({
        text,
        mask: Masks.DATE_DDMMYYYY,
      });

      if (masked.length > MaskPlaceholder.length) {
        return;
      }
      const isValid = ValidInputRegex.test(masked);
      if (!isValid) {
        return;
      }

      // Here, we keep the local state in sync with the input value
      setInputValue(masked.trim());

      // And sync the Date with Formik state. Formik will do the validation.
      const convertedDate = parse(masked, DateFormat, new Date());
      setFieldValue?.(convertedDate);

      // Timeout workaround for stale Formik value issue
      setTimeout(() => {
        if (isMounted.current) {
          // We can't sequentially `setFieldTouched` after `setFieldValue`
          // This is a known Formik issue: https://github.com/formium/formik/issues/2457
          setFieldTouched?.();
        }
      }, 100);
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <TextInputRow
      // Make the input text align to right side if the row has a label on the left side
      {...(props.label != null ? { textAlign: 'right' } : null)}
      // Padding right to balance out the padding left injected by inner <InputRow>
      pr="m"
      testID={testID}
      placeholder={MaskPlaceholder}
      {...props}
      noBorder={last}
      last={last}
      value={inputValue}
      onChangeText={onChangeText}
      returnKeyType="done"
    />
  );
};
