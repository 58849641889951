import { SxProp, View } from 'dripsy';
import { ReactNode, useState } from 'react';
import Animated from 'react-native-reanimated';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { useCardHoverShadowStyle } from '../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../ui/utils/useCardTranslateYAnimatedStyle';
import { TouchableWithoutFeedback } from '../../ui/v2/Gesture/TouchableWithoutFeedback';
import { MAX_MOBILE_WIDTH } from '../../utils/NavConstants';
import { InLifeCardContent, InLifeCardContentProps } from './InLifeCardContent';

export type LoanCardProps = Omit<
  InLifeCardContentProps,
  'loading' | 'secondaryBarColor'
> & {
  onPress?: () => void;
  sx?: SxProp;
  children?: ReactNode;
  testID?: string;
};

export function LoanCard({
  onPress,
  children,
  displayName,
  loanData,
  active,
  showError,
  sx: sxProps = {},
  ...props
}: LoanCardProps) {
  const [isHover, setIsHover] = useState(false);

  const shadowProps = useCardHoverShadowStyle({
    isHover,
  });

  const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      onPressIn={() => setIsHover(true)}
      onPressOut={() => setIsHover(false)}
    >
      <Animated.View style={animatedStyle} testID={TestID.ApplyCard.Card}>
        <View
          accessible
          role="button"
          sx={{
            backgroundColor: '$inputBackground',
            borderRadius: '$card',
            overflow: 'visible',
            mb: '$24',
            pb: '$16',
            width: '100%',
            maxWidth: MAX_MOBILE_WIDTH,
            alignSelf: 'center',
            ...shadowProps,
            ...sxProps,
          }}
          {...props}
        >
          <Box
            px="m"
            overflow="hidden"
            borderTopLeftRadius="card"
            borderTopRightRadius="card"
          >
            <InLifeCardContent
              active={active}
              showError={showError}
              displayName={displayName}
              loanData={loanData}
            />
          </Box>
          {children ? (
            <>
              <Separator my="m" mx="m" />
              {children}
            </>
          ) : (
            <Separator spacer py="m" />
          )}
        </View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
}
