import { Text, View } from 'dripsy';
import { Formik } from 'formik';
import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCheckboxInputV2,
  FormDateInputV2,
  FormMultilineTextInputV2,
  FormPropertyInputV2,
  FormRadioInputV2,
  FormSelectV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Gender_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import {
  ManualPropertyFieldsProps,
  ManualPropertyInputFields,
} from '../../ui/v2/ManualPropertyInputFields';
import { SuggestionDataLoader } from '../../ui/v2/PropertyInput';
import { makeTestId } from '../../utils/stringHelpers';
import {
  BorrowerDetailLabel,
  BorrowerDetailsField,
  BorrowerDetailsInitialValues,
  BorrowerForm,
  FullName,
  getBorrowerDetailsValidationSchema,
  manualMailingAddressPrefix,
  manualMailingInputFieldNames,
  manualResidentialAddressPrefix,
  manualResidentialInputFieldNames,
  UseResidentialAddressAsMailingAddressYesNoEnum,
} from '../utils/borrowerFormUtil';
import { buildBorrowerDetailsErrorMessage } from '../utils/buildFormErrorMessage';

type AddressInputProps = Pick<
  ManualPropertyFieldsProps,
  'fieldNamePrefix' | 'stateOptions' | 'streetTypeOptions'
> &
  SuggestionDataLoader & {
    screen: Screen;
    label: string;
    inputTestID: string;
    selectPropertyFieldName: BorrowerDetailsField;
    isManualInput?: boolean;
    onChangeUseManualInput: (value: boolean) => void;
  };

function BorrowerAddressInput(props: AddressInputProps) {
  const {
    screen,
    fieldNamePrefix,
    isManualInput,
    label,
    selectPropertyFieldName,
    stateOptions,
    streetTypeOptions,
    onChangeUseManualInput,
    ...otherSelectPropertyProps
  } = props;

  return isManualInput ? (
    <>
      <Text variant="emphasis" sx={{ mt: '$16' }}>
        {label}
      </Text>
      <ManualPropertyInputFields
        screen={screen}
        sectionInteractionKey={SectionInteractionKey.Borrowers}
        fieldNamePrefix={fieldNamePrefix}
        containerSx={{ mt: '$8', zIndex: 2 }}
        stateOptions={stateOptions}
        streetTypeOptions={streetTypeOptions}
      />
      <Text
        variants={['caption', 'link']}
        sx={{ mt: '$8' }}
        onPress={() => onChangeUseManualInput(false)}
      >
        {t('Content.PropertyForm.FormV2.BackToSelectInput')}
      </Text>
    </>
  ) : (
    <>
      <FormPropertyInputV2
        name={selectPropertyFieldName}
        label={label}
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Borrowers,
          screen,
          FieldInteractionKey.ResidentialAddress,
        )}
        {...otherSelectPropertyProps}
      />
      <FormikFormError
        name={selectPropertyFieldName}
        errorMessageParser={buildBorrowerDetailsErrorMessage}
        sx={{ mt: '$8' }}
      />
      <Text variant="caption" sx={{ my: '$8' }}>
        {t('Content.Address.UseManualInput')}
        <Text
          variants={['caption', 'link']}
          onPress={() => onChangeUseManualInput(true)}
        >
          {t('Content.Address.UseManualInputLink')}
        </Text>
      </Text>
    </>
  );
}

export type BorrowerDetailsFormProps = {
  screen: Screen;
  citizenshipOptions: PickerOptions<string>;
} & Partial<
  Pick<ManualPropertyFieldsProps, 'stateOptions' | 'streetTypeOptions'> & {
    initialValues: BorrowerForm;
    currentBorrowerIsCoBorrower: boolean;
    currentBorrowerFirstName: string | null;
    primaryBorrowerFullName: FullName;
    loadResidentialAddressSuggestionData: SuggestionDataLoader['loadSuggestionData'];
    isLoadingResidentialAddressSuggestionData: SuggestionDataLoader['isLoadingSuggestionData'];
    residentialAddressSuggestionData: SuggestionDataLoader['propertySuggestionData'];
    loadMailingAddressSuggestionData: SuggestionDataLoader['loadSuggestionData'];
    isLoadingMailingAddressSuggestionData: SuggestionDataLoader['isLoadingSuggestionData'];
    mailingAddressSuggestionData: SuggestionDataLoader['propertySuggestionData'];
    isSubmitting: boolean;
    onSubmit: (values: BorrowerForm) => void;
  }
>;

export function BorrowerDetailsForm(props: BorrowerDetailsFormProps) {
  const {
    screen,
    citizenshipOptions,
    loadResidentialAddressSuggestionData,
    loadMailingAddressSuggestionData,
    isLoadingMailingAddressSuggestionData,
    isLoadingResidentialAddressSuggestionData,
    residentialAddressSuggestionData,
    mailingAddressSuggestionData,
    streetTypeOptions = [],
    stateOptions = [],
    isSubmitting,
    onSubmit = () => {},
    initialValues = BorrowerDetailsInitialValues,
    currentBorrowerIsCoBorrower,
    currentBorrowerFirstName,
    primaryBorrowerFullName,
  } = props;
  const [showOtherNamesField, setShowOtherNamesField] = useState(
    !!initialValues.otherNames,
  );

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={() =>
        getBorrowerDetailsValidationSchema({
          primaryBorrowerFullName,
        })
      }
      initialValues={initialValues}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <Text variant="sHeader">
            {currentBorrowerIsCoBorrower
              ? t('Content.Borrower.DetailsForm.Name.TitleCoBorrower', {
                  coBorrowerFirstName: currentBorrowerFirstName || '',
                })
              : t('Content.Borrower.DetailsForm.Name.Title')}
          </Text>
          <FormTextInputV2
            name={BorrowerDetailsField.FirstName}
            label={BorrowerDetailLabel[BorrowerDetailsField.FirstName]}
            inputTestID={TestID.BorrowerDetailsForm.FirstNameInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.FirstName,
            )}
          />
          <FormikFormError
            name={BorrowerDetailsField.FirstName}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />
          <FormTextInputV2
            name={BorrowerDetailsField.MiddleName}
            label={BorrowerDetailLabel[BorrowerDetailsField.MiddleName]}
            inputTestID={TestID.BorrowerDetailsForm.MiddleNameInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.MiddleName,
            )}
          />
          <FormikFormError
            name={BorrowerDetailsField.MiddleName}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />
          <FormTextInputV2
            name={BorrowerDetailsField.LastName}
            label={BorrowerDetailLabel[BorrowerDetailsField.LastName]}
            inputTestID={TestID.BorrowerDetailsForm.LastNameInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.LastName,
            )}
          />
          <FormikFormError
            name={BorrowerDetailsField.LastName}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />
          {showOtherNamesField ? (
            <>
              <Text variant="sHeader" sx={{ mt: '$32' }}>
                {t('Content.Borrower.DetailsForm.OtherNames.Title')}
              </Text>
              <FormMultilineTextInputV2
                name={BorrowerDetailsField.OtherNames}
                label={BorrowerDetailLabel[BorrowerDetailsField.OtherNames]}
                inputTestID={TestID.BorrowerDetailsForm.OtherNamesInput}
                sx={{ mt: '$16' }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Borrowers,
                  screen,
                  FieldInteractionKey.OtherNames,
                )}
              />
              <FormikFormError
                name={BorrowerDetailsField.OtherNames}
                errorMessageParser={buildBorrowerDetailsErrorMessage}
                sx={{ mt: '$8' }}
              />
            </>
          ) : (
            <Text variant="caption" sx={{ my: '$8' }}>
              {t('Content.Borrower.DetailsForm.OtherNames.AddOtherNames')}
              <Text
                variants={['caption', 'link']}
                onPress={() => setShowOtherNamesField(true)}
                testID={TestID.BorrowerDetailsForm.AddOtherNames}
              >
                {t('Content.Borrower.DetailsForm.OtherNames.AddNames')}
              </Text>
            </Text>
          )}

          <Text variant="sHeader" sx={{ mt: '$32' }}>
            {t('Content.Borrower.DetailsForm.OtherInformation.Title')}
          </Text>
          <FormDateInputV2
            name={BorrowerDetailsField.DateOfBirth}
            label={BorrowerDetailLabel[BorrowerDetailsField.DateOfBirth]}
            inputTestID={TestID.BorrowerDetailsForm.DateOfBirthInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.DateOfBirth,
            )}
          />
          <FormikFormError
            name={BorrowerDetailsField.DateOfBirth}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />

          <Text variant="emphasis" sx={{ mt: '$16' }}>
            {t('Content.Borrower.DetailsForm.OtherInformation.Gender')}
          </Text>
          <View sx={{ flexDirection: 'row', mt: '$8' }}>
            <FormRadioInputV2
              name={BorrowerDetailsField.Gender}
              value={Gender_Enum.Female}
              testID={makeTestId([
                TestID.BorrowerDetailsForm.GenderOption,
                Gender_Enum.Female,
              ])}
              label={t('Content.Gender.Female')}
              containerStyle={{ mr: '$16', flex: 1 }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Borrowers,
                screen,
                FieldInteractionKey.Gender,
              )}
            />
            <FormRadioInputV2
              name={BorrowerDetailsField.Gender}
              value={Gender_Enum.Male}
              testID={makeTestId([
                TestID.BorrowerDetailsForm.GenderOption,
                Gender_Enum.Male,
              ])}
              label={t('Content.Gender.Male')}
              containerStyle={{ flex: 1 }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Borrowers,
                screen,
                FieldInteractionKey.Gender,
              )}
            />
          </View>
          <FormikFormError
            name={BorrowerDetailsField.Gender}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />
          <FormSelectV2
            name={BorrowerDetailsField.Citizenship}
            label={BorrowerDetailLabel[BorrowerDetailsField.Citizenship]}
            items={citizenshipOptions}
            testID={TestID.BorrowerDetailsForm.CitizenshipInput}
            sx={{ mt: '$16' }}
          />
          <FormikFormError
            name={BorrowerDetailsField.Citizenship}
            errorMessageParser={buildBorrowerDetailsErrorMessage}
            sx={{ mt: '$8' }}
          />

          <Text variant="sHeader" sx={{ mt: '$32' }}>
            {t('Content.Borrower.DetailsForm.Address.Title')}
          </Text>
          <BorrowerAddressInput
            screen={screen}
            selectPropertyFieldName={BorrowerDetailsField.ResidentialAddress}
            fieldNamePrefix={manualResidentialAddressPrefix}
            label={BorrowerDetailLabel[BorrowerDetailsField.ResidentialAddress]}
            onChangeUseManualInput={(value) =>
              formProps.setFieldValue(
                manualResidentialInputFieldNames.isManualInput,
                value,
                false,
              )
            }
            stateOptions={stateOptions}
            streetTypeOptions={streetTypeOptions}
            propertySuggestionData={residentialAddressSuggestionData}
            loadSuggestionData={loadResidentialAddressSuggestionData}
            isLoadingSuggestionData={isLoadingResidentialAddressSuggestionData}
            isManualInput={
              formProps.values?.[manualResidentialInputFieldNames.isManualInput]
            }
            inputTestID={TestID.BorrowerDetailsForm.ResidentialAddressInput}
          />
          <FormCheckboxInputV2
            name={BorrowerDetailsField.UseResidentialAddressAsMailingAddress}
            label={
              BorrowerDetailLabel[
                BorrowerDetailsField.UseResidentialAddressAsMailingAddress
              ]
            }
            testID={TestID.BorrowerDetailsForm.UseAsMailingAddressCheckBox}
            value={UseResidentialAddressAsMailingAddressYesNoEnum.Yes}
            containerStyle={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.UseResidentialAddressAsMailingAddress,
            )}
          />
          {formProps.values?.useResidentialAddressAsMailingAddress?.[0] !==
            UseResidentialAddressAsMailingAddressYesNoEnum.Yes && (
            <BorrowerAddressInput
              screen={screen}
              selectPropertyFieldName={BorrowerDetailsField.MailingAddress}
              fieldNamePrefix={manualMailingAddressPrefix}
              label={BorrowerDetailLabel[BorrowerDetailsField.MailingAddress]}
              onChangeUseManualInput={(value) =>
                formProps.setFieldValue(
                  manualMailingInputFieldNames.isManualInput,
                  value,
                  false,
                )
              }
              stateOptions={stateOptions}
              streetTypeOptions={streetTypeOptions}
              propertySuggestionData={mailingAddressSuggestionData}
              loadSuggestionData={loadMailingAddressSuggestionData}
              isLoadingSuggestionData={isLoadingMailingAddressSuggestionData}
              isManualInput={
                formProps.values?.[manualMailingInputFieldNames.isManualInput]
              }
              inputTestID={TestID.BorrowerDetailsForm.MailingAddressInput}
            />
          )}
          <Button
            onPress={() => formProps.handleSubmit()}
            label={t('Content.Common.ButtonLabel.Next')}
            alignSelf="stretch"
            mt="xl"
            showSpinner={isSubmitting}
            testID={TestID.BorrowerDetailsForm.NextButton}
          />
        </View>
      )}
    </Formik>
  );
}
