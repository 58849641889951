import { gql } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { autopayCancellationStatusAtom } from '../../AutopaySettings/autopayAtom';
import SubmittingAutopayIndicator from '../../AutopaySettings/components/SubmittingAutopayIndicator';
import {
  Change_Request_Status_Enum,
  refetchGetLoanAccountByIdQuery,
  useCancelAutopayRequestMutation,
  useFetchAutopayCancelStatusSubscription,
} from '../../generated/graphql';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION>;

export const CANCEL_AUTOPAY_REQUEST = gql`
  mutation CancelAutopayRequest($input: cancel_autopay_input!) {
    cancel_autopay(input: $input) {
      id
    }
  }
`;

export const FETCH_AUTOPAY_CANCEL_STATUS = gql`
  subscription FetchAutopayCancelStatus($id: uuid!) {
    autopay_cancel_request_by_pk(id: $id) {
      status
    }
  }
`;

const autopayStatusContent = ({
  cancelAutopayStatus,
  onAnimationFinish,
}: {
  cancelAutopayStatus: Change_Request_Status_Enum;
  onAnimationFinish?: () => void;
}): { text: string; component: React.ReactNode } => {
  const autopayStatusMap = {
    [Change_Request_Status_Enum.Pending]: {
      text: t('Content.AutopaySettings.CancellingAutopay'),
      component: <SubmittingAutopayIndicator />,
    },
    [Change_Request_Status_Enum.Applied]: {
      text: t('Content.AutopaySettings.CancellingAutopaySuccess'),
      component: <GreenCheckAnimation onAnimationFinish={onAnimationFinish} />,
    },
    [Change_Request_Status_Enum.Failed]: {
      text: '',
      component: null,
    },
  };

  return autopayStatusMap[cancelAutopayStatus];
};

export const AutopayCancellationConfirmationActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const setAutopayCancellationStatus = useSetRecoilState(
    autopayCancellationStatusAtom,
  );
  const [
    submitCancelAutopayRequest,
    { data: cancelAutopayRequestData, called: cancelAutopayInitiated, error },
  ] = useCancelAutopayRequestMutation();

  const { data } = useFetchAutopayCancelStatusSubscription({
    variables: {
      id: cancelAutopayRequestData?.cancel_autopay?.id ?? '',
    },
    skip: !cancelAutopayRequestData?.cancel_autopay?.id,
  });

  const cancelAutopayStatus = useMemo(
    () => data?.autopay_cancel_request_by_pk?.status,
    [data?.autopay_cancel_request_by_pk?.status],
  );

  const navigateToAutopayModal = useCallback(() => {
    navigation.replace(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_EXISTING_SETTINGS,
      params: {
        cbaAccountId: route.params.cbaAccountId,
      },
    });
  }, [navigation, route.params.cbaAccountId]);

  const handleError = useCallback(() => {
    setAutopayCancellationStatus(Change_Request_Status_Enum.Failed);
    navigateToAutopayModal();
  }, [navigateToAutopayModal, setAutopayCancellationStatus]);
  const { cbaAccountId } = route.params || {};
  const onPressConfirm = useCallback(async () => {
    const [response] = await safelyCallMutation(submitCancelAutopayRequest, {
      variables: {
        input: {
          cba_account_id: cbaAccountId,
        },
      },
      context: {
        sentryContext: {
          cbaAccountId,
        },
      },
      refetchQueries: [
        refetchGetLoanAccountByIdQuery({ cba_account_id: cbaAccountId }),
      ],
    });

    if (!response?.data?.cancel_autopay?.id) {
      handleError();
    }
  }, [handleError, submitCancelAutopayRequest, cbaAccountId]);

  useEffect(() => {
    setAutopayCancellationStatus(
      cancelAutopayStatus || Change_Request_Status_Enum.Pending,
    );

    if (error || cancelAutopayStatus === Change_Request_Status_Enum.Failed) {
      handleError();
    }
  }, [
    cancelAutopayStatus,
    error,
    handleError,
    navigation,
    setAutopayCancellationStatus,
  ]);

  if (cancelAutopayInitiated) {
    const { text, component } = autopayStatusContent({
      cancelAutopayStatus:
        cancelAutopayStatus || Change_Request_Status_Enum.Pending,
      onAnimationFinish: () => navigation.goBack(),
    });

    return (
      <ActionSheet
        closeModal={navigateToAutopayModal}
        showCloseButton={
          cancelAutopayStatus === Change_Request_Status_Enum.Applied
        }
        footerText={text}
      >
        {component}
      </ActionSheet>
    );
  }

  return (
    <ActionSheet
      closeModal={navigateToAutopayModal}
      title={t('Content.AutopaySettings.CancelAutopay')}
      actionList={[
        {
          label: t('Content.AutopaySettings.CancelAutopayConfirmCancellation'),
          color: 'error',
          secondary: true,
          onPress: onPressConfirm,
          testID: TestID.AutopayCancellationConfirmationActionSheet.Confirm,
        },
      ]}
    >
      <Box p="m" px="xl">
        <StyledText textAlign="center">
          {t(
            'Content.AutopaySettings.CancelAutopayConfirmCancellationDisclaimer',
          )}
        </StyledText>
      </Box>
    </ActionSheet>
  );
};
