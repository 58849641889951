import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const TabNavSettingsIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    width={resizeIconWidth(21, 21, size)}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.625 10C20.625 15.5228 16.1478 20 10.625 20C5.10215 20 0.625 15.5228 0.625 10C0.625 4.47715 5.10215 0 10.625 0C16.1478 0 20.625 4.47715 20.625 10ZM14.4236 7.56137C14.4236 9.85045 12.7447 11.7061 10.6736 11.7061C8.60254 11.7061 6.92361 9.85045 6.92361 7.56137C6.92361 5.2723 8.60254 3.41664 10.6736 3.41664C12.7447 3.41664 14.4236 5.2723 14.4236 7.56137ZM16.723 15.0692C15.2158 16.5715 13.1365 17.5001 10.8403 17.5001C8.4586 17.5001 6.31027 16.501 4.79159 14.899C6.26381 13.9605 8.27999 13.3332 10.6262 13.3332C13.1181 13.3332 15.2358 14.0362 16.723 15.0692Z"
      fill={color}
    />
  </Svg>
);
