import { useCallback } from 'react';

import {
  refetchGetExternalAccountsQuery,
  useDeleteExternalAccountMutation,
} from '../../../generated/graphql';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';

export const useDeleteExternalAccount = (cbaAccountId: string) => {
  const [executeDeleteExternalAccountMutation, { loading }] =
    useDeleteExternalAccountMutation();

  const deleteExternalAccount = useCallback(
    async (id: string) =>
      safelyCallMutation(executeDeleteExternalAccountMutation, {
        variables: { id },
        context: { sentryContext: { cbaAccountId } },
        refetchQueries: [refetchGetExternalAccountsQuery()],
      }),
    [cbaAccountId, executeDeleteExternalAccountMutation],
  );

  return {
    deleteExternalAccount,
    loading,
  };
};
