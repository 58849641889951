import { View } from 'dripsy';
import * as React from 'react';

import { Approval_Status_Enum } from '../../generated/graphql';
import { useLoanAccountWithTransactions } from '../../HomeLoan/graphql/loanAccountQueries';
import { LoadingRow, LoadingRowGroup } from '../../ui/molecules/LoadingRow';
import { UpcomingCard } from '../../ui/v2/UpcomingCard';
import { HomeLoanOnSchedulePayment } from './HomeLoanOnSchedulePayment';
import { PendingWithdrawals } from './PendingWithdrawals';

type Props = {
  index: number;
  cbaAccountId: string;
  numOfHomeLoans: number;
};
export const DashboardUpcomings: React.FC<Props> = ({
  index,
  cbaAccountId,
  numOfHomeLoans,
}) => {
  const { loading, data: loan } = useLoanAccountWithTransactions(cbaAccountId);

  if (loan?.state?.account_state !== 'ACTIVE' && !loading) {
    return null;
  }

  const pendingWithdrawals =
    loan?.pending_withdrawals?.filter(
      (item) =>
        item.withdrawal_request?.approval_request?.status ===
        Approval_Status_Enum.Pending,
    ) ?? [];

  if (loading) {
    return (
      <LoadingRowGroup sx={{ mb: '$8' }}>
        <LoadingRow />
      </LoadingRowGroup>
    );
  }

  return (
    <View sx={{ overflow: 'visible', mt: index > 0 ? '$8' : undefined }}>
      {!!pendingWithdrawals.length && (
        <PendingWithdrawals
          loan={loan}
          showAccountName={numOfHomeLoans > 1}
          myIdentityProfileId={loan?.myIdentityProfileId}
          pending_withdrawals={pendingWithdrawals}
        />
      )}
      <UpcomingCard sx={{ mt: pendingWithdrawals.length ? '$8' : undefined }}>
        <HomeLoanOnSchedulePayment
          cbaAccountId={cbaAccountId}
          loan={loan}
          showAccountName={numOfHomeLoans > 1}
        />
      </UpcomingCard>
    </View>
  );
};
