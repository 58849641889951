import { View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { InfoRow } from '../../components/InfoRow';

export type Props = {
  isError?: boolean;
  showNotification: boolean;
  message: string;
};

export const Notification = ({
  isError = false,
  showNotification,
  message,
}: Props) => {
  if (!showNotification) return null;
  return (
    <View sx={{ mt: '$16', mb: '$8' }}>
      {isError ? (
        <ErrorRow
          testID={TestID.AccountPermissions.ErrorMessage}
          message={message}
        />
      ) : (
        <InfoRow
          testID={TestID.AccountPermissions.Notification}
          message={message}
        />
      )}
    </View>
  );
};
