import { GTMProvider as BaseGTMProvider } from '@elgorditosalsero/react-gtm-hook';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAuthContext } from '../Auth/context';
import { useGetMeQuery } from '../generated/graphql';
import { setSentryUserId } from '../sentry';
import { useEnvConfig } from '../utils/hooks/useEnvConfig';
import { isWeb } from '../utils/platformUtils';
import { GTMEvent, GTMEvents, SendDataToGTM } from './types';
import { useGTMDispatchInstance } from './useGTMDispatchInstance';

type Props = {
  children: ReactNode;
};

type GTMContextValue = {
  loading: boolean;
  sendDataToGTM: (event: GTMEvents) => void;
};
const GTMContext = createContext<GTMContextValue>({
  loading: false,
  sendDataToGTM: () => {},
});

export function useGTM() {
  const context = useContext(GTMContext);
  return context;
}

const GTMLayer = ({ children }: Props) => {
  const sendDataToGTM: SendDataToGTM = useGTMDispatchInstance();
  // Don't send GTM events until we have a shaUserId
  const [queuedGTMEvents, setQueuedGTMEvents] = useState<Array<GTMEvents>>([]);
  const authContext = useAuthContext();
  const [shaUserId, setShaUserId] = useState<string>();
  const getMeQuery = useGetMeQuery({
    skip: !authContext?.isAuthenticated,
    onCompleted: (me) => {
      const userId = me.me?.[0]?.user?.id;
      setSentryUserId(userId);
      if (userId) {
        // the intercomConnection.user_id is already hashed so we can save the
        // effort of rehashing the user id we recieved.
        // We still need to pass up intercom_user_id so gtm can properly
        // setup the intercom user
        const intercomConnection = me.me?.[0]?.user?.intercom_connection;
        if (intercomConnection) {
          sendDataToGTM({
            event: GTMEvent.LoginEvent,
            user_id: intercomConnection.user_id,
            intercom_hash: intercomConnection.hash_id,
            intercom_user_id: intercomConnection.user_id,
          });
          setShaUserId(intercomConnection.user_id);
        }
      }
    },
    context: {
      sentryContext: {
        description: 'Error occured in GTM Provider',
      },
    },
  });
  const loading = getMeQuery?.loading;

  useEffect(() => {
    if (shaUserId && queuedGTMEvents.length > 0) {
      queuedGTMEvents.forEach((event) => {
        sendDataToGTM({
          ...event,
          user_id: shaUserId,
        } as GTMEvents);
      });
      setQueuedGTMEvents([]);
    }
  }, [shaUserId, sendDataToGTM, queuedGTMEvents]);

  const context = useMemo(
    () => ({
      loading,
      sendDataToGTM: (event: GTMEvents) => {
        if (shaUserId) {
          sendDataToGTM({
            ...event,
            user_id: shaUserId,
          });
        } else {
          setQueuedGTMEvents((existingEvents) => [...existingEvents, event]);
        }
      },
    }),
    [loading, sendDataToGTM, shaUserId],
  );
  return <GTMContext.Provider value={context}>{children}</GTMContext.Provider>;
};

export function GTMProvider({ children }: Props) {
  const { config } = useEnvConfig();

  if (isWeb) {
    return (
      <BaseGTMProvider state={config.gtmConfig}>
        <GTMLayer>{children}</GTMLayer>
      </BaseGTMProvider>
    );
  }
  return <GTMLayer>{children}</GTMLayer>;
}
