import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { LoanConfigurationV2ScreenProps } from '../navigation/types';
import { SetupLoanV2Base } from './base/SetupLoanScreenV2Base';
import { SetupLoanTopUpBase } from './base/SetupLoanTopUpBase';

type Props =
  LoanConfigurationV2ScreenProps<Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN>;

export function LoanConfigurationSetupLoanScreenV2({
  navigation,
  route,
}: Props) {
  const { loanApplicationId, targetLoanId } = route.params || {};

  const { loanApplicationType, navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourUnloan = () =>
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.YourUnloan,
    });

  const navigateToYourDebts = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Debts,
    });
  };

  if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
    return (
      <SetupLoanTopUpBase
        screen={Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN}
        onSubmitSuccess={navigateToYourUnloan}
        loanApplicationId={loanApplicationId}
        onClose={navigateToYourUnloan}
        hideBackButton={false}
        loanApplicationTargetId={targetLoanId}
      />
    );
  }

  return (
    <SetupLoanV2Base
      screen={Screen.SETUP_LOAN_V2_MODAL}
      showSelectRefiReason={false}
      onSubmitSuccess={navigateToYourUnloan}
      navigateToYourDebts={navigateToYourDebts}
      loanApplicationId={loanApplicationId}
      onClose={navigateToYourUnloan}
      hideBackButton={false}
      loanApplicationTargetId={targetLoanId}
    />
  );
}
