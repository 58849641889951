import { Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormDateInputV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import { Id_Type_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import {
  BorrowerIdentityDetailsFormValues,
  BorrowerPassportField,
  BorrowerPassportFormValues,
  BorrowerPassportInitialValues,
  BorrowerPassportLabel,
  getBorrowerPassportValidationSchema,
} from '../utils/borrowerFormUtil';

export type BorrowerPassportFormProps = { screen: Screen } & Partial<{
  primaryBorrowerPassportNumber: string | null;
  initialValues: BorrowerPassportFormValues;
  isSubmitting: boolean;
  onSubmit: (values: BorrowerIdentityDetailsFormValues) => void;
}>;

export function BorrowerPassportForm(props: BorrowerPassportFormProps) {
  const {
    screen,
    primaryBorrowerPassportNumber,
    initialValues = BorrowerPassportInitialValues,
    isSubmitting,
    onSubmit = () => {},
  } = props;

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={() =>
        getBorrowerPassportValidationSchema({
          primaryBorrowerPassportNumber,
        })
      }
      initialValues={{ ...initialValues, identityType: Id_Type_Enum.Passport }}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <Text variant="sHeader">
            {t('Content.Borrower.PassportForm.Title')}
          </Text>

          <FormTextInputV2
            name={BorrowerPassportField.PassportNo}
            label={BorrowerPassportLabel[BorrowerPassportField.PassportNo]}
            inputTestID={TestID.BorrowerPassportForm.PassportNoInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.DocumentNumber,
            )}
          />
          <FormikFormError
            name={BorrowerPassportField.PassportNo}
            sx={{ mt: '$8' }}
          />
          <FormDateInputV2
            name={BorrowerPassportField.ExpiryDate}
            label={BorrowerPassportLabel[BorrowerPassportField.ExpiryDate]}
            inputTestID={TestID.BorrowerPassportForm.ExpiryDateInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Borrowers,
              screen,
              FieldInteractionKey.ExpiryDate,
            )}
          />
          <FormikFormError
            name={BorrowerPassportField.ExpiryDate}
            sx={{ mt: '$8' }}
          />

          <Button
            onPress={() => formProps.handleSubmit()}
            label={t('Content.Common.ButtonLabel.Done')}
            alignSelf="stretch"
            mt="xl"
            disabled={isSubmitting}
            showSpinner={isSubmitting}
            // TODO: Change test ID button for automation
            testID={TestID.BorrowerPassportForm.DoneButton}
          />
        </View>
      )}
    </Formik>
  );
}
