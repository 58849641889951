import { SxProp, Text, View } from 'dripsy';

import { SvgIcon } from '../../../ui/svgs/SvgIcon';
import { formatCurrency } from '../../../utils/currencyHelpers';

type SetupLoanSavingsBannerProps = {
  savingsAmount: number;
  sx?: SxProp;
};

export const SetupLoanSavingsBanner = ({
  savingsAmount,
  sx = {},
}: SetupLoanSavingsBannerProps) => (
  <View
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      padding: '$16',
      borderRadius: 12,
      backgroundColor: '$savingsBannerBackground',
      ...sx,
    }}
  >
    <SvgIcon
      name="dollarCircleFilledIcon"
      size={32}
      style={{ marginRight: 8, flexShrink: 0 }}
    />
    <Text variant="caption" style={{ color: '$labelsPrimary' }}>
      {t('Content.SetupLoanV2.SavingsSection.YouCould')}{' '}
      <Text
        variant="caption"
        style={{ fontWeight: 'bold', color: '$labelsPrimary' }}
      >
        {t('Content.SetupLoanV2.SavingsSection.SaveUpTo', {
          savingsAmount: formatCurrency(savingsAmount),
        })}{' '}
      </Text>
      {t('Content.SetupLoanV2.SavingsSection.OverTheLifeOfTheLoan')}
    </Text>
  </View>
);
