import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const DownArrowIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M12 15.6157C12.1831 15.6157 12.3662 15.5425 12.4907 15.4033L18.1597 9.59521C18.2842 9.4707 18.3574 9.30957 18.3574 9.12646C18.3574 8.74561 18.0718 8.45264 17.6909 8.45264C17.5078 8.45264 17.3394 8.52588 17.2148 8.64307L12 13.9751L6.77783 8.64307C6.66064 8.52588 6.49219 8.45264 6.30176 8.45264C5.9209 8.45264 5.63525 8.74561 5.63525 9.12646C5.63525 9.30957 5.7085 9.4707 5.83301 9.60254L11.502 15.4033C11.6411 15.5425 11.8096 15.6157 12 15.6157Z"
      fill={color}
    />
  </Svg>
);
