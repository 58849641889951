import { gql } from '@apollo/client';
import { useContext } from 'react';

import { HOUSEHOLD_FOR_SUMMARY_FRAGMENT } from '../../ApplicationSummary/remoteData';
import { LOAN_APPLICATION_BORROWER } from '../../Borrower/graphql/fragments';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Identity_Check_Status_Enum,
  useYourExpensesQuery,
  useYourFinancialsQuery,
} from '../../generated/graphql';
import { sortApplicants } from '../utils/sortApplicants';
import {
  EMPLOYMENT_INCOME_FRAGMENT,
  EXPECTED_RENTAL_INCOME_FRAGMENT,
  INCOME_OWNER_FRAGMENT,
  LOAN_APPLICATION,
  LOAN_APPLICATION_SECURITY,
} from './fragments';

export const LOAN_APPLICATION_DETAILS = gql`
  query LoanApplication($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      is_lmi_enabled
    }
  }
`;

// TODO: Revisit this query to breakdown income and expenses to smaller pieces
export const YOUR_FINANCIALS = gql`
  query YourFinancials($loanApplicationId: uuid!) {
    me {
      id
      user {
        id
        identity_profile {
          id
          kyc_status
        }
      }
    }

    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      initial_property_purpose
      loan_application_securities {
        id
        property_purpose
      }
      applicants {
        ...LoanApplicationBorrower
      }
      incomes(order_by: { income_type: asc, created_at: asc }) {
        ...IncomeWithExpectedRentalIncome
      }
    }

    get_household_expenses_fields(loan_application_id: $loanApplicationId) {
      household_id
      household_expenses_order
      household {
        ...HouseholdForSummary
      }
    }
  }

  fragment IncomeWithExpectedRentalIncome on income {
    id
    amount
    frequency
    income_type
    income_owners {
      ...IncomeOwner
    }

    employment_income {
      ...EmploymentIncome
    }
    rental_income {
      ...ExpectedRentalIncome
    }
  }

  ${EMPLOYMENT_INCOME_FRAGMENT}
  ${EXPECTED_RENTAL_INCOME_FRAGMENT}
  ${INCOME_OWNER_FRAGMENT}
  ${LOAN_APPLICATION_BORROWER}
  ${HOUSEHOLD_FOR_SUMMARY_FRAGMENT}
`;

export const YOUR_EXPENSES = gql`
  query YourExpenses($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      applicants {
        # Why are we using massive fragments here?
        ...LoanApplicationBorrower
      }
    }

    get_household_expenses_fields(loan_application_id: $loanApplicationId) {
      household_id
      household_expenses_order
      household {
        ...HouseholdForSummary
      }
    }
  }
  ${LOAN_APPLICATION_BORROWER}
  ${HOUSEHOLD_FOR_SUMMARY_FRAGMENT}
`;

export function useYourExpensesScreenQuery(loanApplicationId?: string | null) {
  const { data, ...others } = useYourExpensesQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    returnPartialData: true,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const loanApplication = data?.loan_application_by_pk;
  const householdExpenses = data?.get_household_expenses_fields;
  const mergedApplicants = sortApplicants(loanApplication?.applicants);

  return {
    applicants: mergedApplicants,
    householdExpenses,
    loanApplication,
    ...others,
  };
}

export function useYourFinancialsScreenQuery(
  loanApplicationId?: string | null,
) {
  const { data, ...others } = useYourFinancialsQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    returnPartialData: true,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const { flags } = useContext(FeatureFlagsContext);

  // Enable Equifax Income Verification if equifax income verification
  // ff is on and user has passed KYC process
  const kycStatus = data?.me[0]?.user?.identity_profile?.kyc_status;
  const userHasPassedKYC =
    kycStatus === Identity_Check_Status_Enum.Pass ||
    kycStatus === Identity_Check_Status_Enum.PassManual;
  const enableEquifaxIncomeVerificationForPOC =
    flags.ENABLE_EQUIFAX_INCOME_VERIFICATION;
  const loanApplication = data?.loan_application_by_pk;
  const householdExpenses = data?.get_household_expenses_fields;
  const mergedApplicants = sortApplicants(loanApplication?.applicants);
  const loanApplicationType = data?.loan_application_by_pk?.type ?? undefined;

  const initialPropertyPurpose =
    data?.loan_application_by_pk?.initial_property_purpose ?? undefined;
  const loanPropertyPurpose =
    data?.loan_application_by_pk?.loan_application_securities?.[0]
      ?.property_purpose;
  const propertyPurpose = loanPropertyPurpose ?? initialPropertyPurpose;

  return {
    applicants: mergedApplicants,
    incomes: loanApplication?.incomes,
    householdExpenses,
    loanApplication,
    loanApplicationType,
    propertyPurpose,
    enableEquifaxIncomeVerificationForPOC,
    userHasPassedKYC,
    ...others,
  };
}

export const LOAN_APPLICATION_SECURITIES_QUERY = gql`
  query LoanApplicationSecurities($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      ...LoanApplication
      loan_application_securities {
        ...LoanApplicationSecurityDetails
      }
      expectedRentalIncomes: incomes(
        where: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
      ) {
        id
        rentalIncome: amount
        rentalIncomeFrequency: frequency
        rental_income {
          id
          rentalExpense: rental_expense
          rentalExpenseFrequency: rental_expense_frequency
        }
      }
      parent_loan_account {
        security {
          owner_estimated_value
        }
      }
    }
  }

  ${LOAN_APPLICATION_SECURITY}
  ${LOAN_APPLICATION}
`;

export const GET_LOAN_APPLICANTS = gql`
  query GetLoanApplicants($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      ...LoanApplication
      applicants(where: { loan_application_id: { _eq: $loanApplicationId } }) {
        ...LoanApplicationBorrower
      }
    }
  }
  ${LOAN_APPLICATION}
  ${LOAN_APPLICATION_BORROWER}
`;

// Currently used only for splash screen
export const GET_LOAN_APPLICATION_INITIAL_DATA = gql`
  query GetLoanApplicationInitialData($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      initial_property_purpose
      is_lmi_enabled
    }
  }
`;

export const GET_DEFAULT_INTEREST_RATE_FOR_SPLASH_SCREEN = gql`
  query GetDefaultProductRateForSplashScreen {
    product_rate(where: { max_lvr: { _gte: 80 }, min_lvr: { _lt: 80 } }) {
      id
      property_purpose
      comparison_rate
      interest_rate
    }
  }
`;

export const GET_CREDIT_CHECK_BY_LOAN_APPLICATION_ID = gql`
  query GetCreditCheckByLoanApplicationId($loanApplicationId: uuid!) {
    applicant(where: { loan_application_id: { _eq: $loanApplicationId } }) {
      id
      is_current_logged_in_applicant
      user_identity_profile {
        id
        credit_checks(order_by: { created_at: desc }, limit: 1) {
          id
          state
          created_at
          loan_application_id
        }
      }
    }
  }
`;
