import { useCallback } from 'react';
import { Linking, Platform } from 'react-native';

import { useAuth } from '../../Auth/hooks';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { ActionSheetType } from '../../navigation/types/screens';
import { StyledText } from '../../ui/atoms/StyledText';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET>;

export const DeleteAccountCompletedActionSheet = ({ navigation }: Props) => {
  const { logout } = useAuth();

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);
  const logOutUser = useCallback(() => {
    closeModal();
    // Redirect to unloan.com.au if 'Web'
    if (Platform.OS === 'web') {
      Linking.openURL(t('Link.HomePage'));
    }
    logout();
  }, [closeModal, logout]);

  return (
    <ActionSheet closeModal={closeModal} title="" showCloseButton={false}>
      <GreenCheckAnimation onAnimationFinish={logOutUser} />
      <StyledText fontWeight="semiBold" mt="m" textAlign="center" fontSize="m">
        {t('Content.Settings.DeleteAccount.CompletedText')}
      </StyledText>
      <StyledText my="m" textAlign="center" fontSize="s">
        {t('Content.Settings.DeleteAccount.CompletedSubText')}
      </StyledText>
    </ActionSheet>
  );
};
