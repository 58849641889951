import { Text } from 'dripsy';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import { ModalCanBeClosedAtomFamily } from '../recoil/PreventClosingModal';

type PreventClosingModalActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET>;

export function PreventClosingModalActionSheet({
  navigation,
  route,
}: PreventClosingModalActionSheetProps) {
  const { modalId, title } = route.params;

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  const setModalCanBeClosed = useSetRecoilState(
    ModalCanBeClosedAtomFamily(modalId),
  );

  const onLeavePress = useCallback(() => {
    setModalCanBeClosed(true);
    closeModal();
  }, [closeModal, setModalCanBeClosed]);

  return (
    <ActionSheet
      closeModal={closeModal}
      title={title}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Leave'),
          isDestructive: true,
          flex: 1,
          onPress: onLeavePress,
        },
        {
          label: t('Content.Common.ButtonLabel.Stay'),
          secondary: true,
          flex: 1,
          onPress: closeModal,
        },
      ]}
      isRowButton
      role="alertdialog"
    >
      <Text variant="body" sx={{ textAlign: 'center' }}>
        {t('Content.PreventClosingModalActionSheet.Message')}
      </Text>
    </ActionSheet>
  );
}
