import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { BizaAccount } from '../../DataSharingConfirmation/types';
import {
  Biza_Account_Type,
  GetBizaAccountsQuery,
  useGetBizaAccountsQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast } from '../../utils/arrayHelpers';
import { SettingStackNavigationProp } from '../navigation/types';
import { DataSharingFooterCaption } from './ManageDataSharing';

const getBizaAccounts = (data: GetBizaAccountsQuery): BizaAccount[] => {
  const user = data?.me[0]?.user;
  if (!user) return [];
  const { biza_accounts: accounts } = user;
  return accounts as BizaAccount[];
};

const AccountList: React.FC<{ accounts: BizaAccount[] }> = ({ accounts }) => {
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>
    >();

  const navigateToJointAccountPref = (params: { accountId: string }) =>
    navigation.navigate(
      Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES,
      params,
    );

  return (
    <ListRowGroup
      mx={0}
      headerText={t(
        'Content.DataSharingNotificationPreferences.MenuItems.JointAccounts.Heading',
      )}
      overflow="visible"
      testID={TestID.ManageDataSharing.DataSharingAccountList}
    >
      {accounts.map((account: BizaAccount, idx: number) => {
        const {
          id,
          masked_account_number: maskedAccountNumber,
          name,
          sharing_status: sharingStatus,
        } = account;
        if (!(id && maskedAccountNumber && name && sharingStatus)) return null;
        const params = {
          accountId: id,
        };
        return (
          <ListRow
            key={id}
            label={name}
            labelFontWeight="semiBold"
            sublabels={[maskedAccountNumber]}
            onPress={() => navigateToJointAccountPref(params)}
            last={isLast(accounts, idx)}
          />
        );
      })}
    </ListRowGroup>
  );
};

const NoAccountFoundMessage = (): JSX.Element => (
  <>
    <StyledText
      testID={TestID.ManageDataSharing.DataSharingNoAccountsMessage}
      textAlign="center"
      variant="caption"
      pt="m"
    >
      {t('Content.DataSharingPreferences.MenuItems.JointAccounts.NoAccounts')}
    </StyledText>
    <Separator spacer />
  </>
);

function SettingsDataSharingNotificationPreferencesBase(): JSX.Element {
  const { data, loading, error } = useGetBizaAccountsQuery({
    variables: {
      type: Biza_Account_Type.Joint,
    },
    context: {
      sentryContext: {
        description: 'Fetching Biza accounts for this user.',
      },
    },
  });
  let content;

  if (error)
    content = <ErrorRow message="Unable to fetch data sharings preferences." />;
  if (loading) content = <Spinner />;
  if (data) {
    const accounts: BizaAccount[] = getBizaAccounts(data);
    content =
      accounts && accounts.length > 0 ? (
        <AccountList accounts={accounts} />
      ) : (
        <NoAccountFoundMessage />
      );
  }

  return (
    <ScreenContainer bg="bgSecondary" scrollable px="m">
      <NavHeaderSpacer />
      {content}
      <Separator spacer />
      <DataSharingFooterCaption />
    </ScreenContainer>
  );
}

export const WithAuthenticationSettingsDataSharingNotificationPreferences =
  withAuthenticationRequired(SettingsDataSharingNotificationPreferencesBase);
