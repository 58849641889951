import { ReviewApplicationSummaryV2 } from '../../ApplicationSummary/screens/ReviewApplicationSummaryV2';
import { LoanApplicationV2StackScreenProps } from '../../LoanApplication/navigation/types';
import { LoanApplicationBorrowersV2 } from '../../LoanApplication/screens/BorrowersV2';
import { CompletedLoanApplication } from '../../LoanApplication/screens/CompletedLoanApplication';
import { LoanApplicationConditionalApproval } from '../../LoanApplication/screens/ConditionalApproval';
import { FinaliseLoanApplication } from '../../LoanApplication/screens/FinaliseLoanApplication';
import { InterstitialLoanSubmittedV2 } from '../../LoanApplication/screens/InterstitialLoanSubmittedV2';
import { LoanApplicationIntroV2 } from '../../LoanApplication/screens/IntroV2';
import { TopUpIntro } from '../../LoanApplication/screens/TopUpIntro';
import { VerifyIncomeOpenBankingV2 } from '../../LoanApplication/screens/VerifyIncomeOpenBankingV2';
import { VOICompleted } from '../../LoanApplication/screens/VOICompleted';
import { WithAuthenticationLoanApplicationYourDebtsV2 } from '../../LoanApplication/screens/YourDebtsV2';
import { LoanApplicationYourExpensesV2 } from '../../LoanApplication/screens/YourExpensesV2';
import { LoanApplicationYourIncomeV2 } from '../../LoanApplication/screens/YourIncomeV2';
import { LoanApplicationYourPropertyPurchase } from '../../LoanApplication/screens/YourPropertyPurchase';
import { LoanApplicationYourPropertyV2 } from '../../LoanApplication/screens/YourPropertyV2';
import { YourUnloanOfferEsign } from '../../LoanApplication/screens/YourUnloanOfferEsign';
import { YourUnloanOfferV2 } from '../../LoanApplication/screens/YourUnloanOfferV2';
import { LoanApplicationYourUnloanPurchase } from '../../LoanApplication/screens/YourUnloanPurchase';
import { LoanApplicationYourUnloanV2 } from '../../LoanApplication/screens/YourUnloanV2';
import { Screen } from '../../navigation/types/screens';

// This `LoanApplicationScreenComponent` temporarily suppresses the following error:
// Type 'FunctionComponent<Props>' is not assignable to type 'FunctionComponent<{}>'.
// Types of parameters 'props' and 'props' are incompatible.
//   Type '{}' is not assignable to type 'Props'.ts(2322)
// Related:
// https://github.com/react-navigation/react-navigation/discussions/11164
// https://github.com/react-navigation/react-navigation/issues/11469
export type LoanApplicationScreenComponent = React.ComponentType<unknown>;

export type LoanApplicationRouteListType = Array<
  LoanApplicationV2StackScreenProps & {
    key: LoanApplicationV2StackScreenProps['name'];
  }
>;

export const RefiLoanApplicationV2RouteList: LoanApplicationRouteListType = [
  {
    key: Screen.LOAN_APPLICATION_V2_INTRO,
    name: Screen.LOAN_APPLICATION_V2_INTRO,
    component: LoanApplicationIntroV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY,
    name: Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY,
    component: LoanApplicationYourPropertyV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_BORROWERS,
    name: Screen.LOAN_APPLICATION_V2_BORROWERS,
    component: LoanApplicationBorrowersV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_YOUR_INCOME,
    name: Screen.LOAN_APPLICATION_V2_YOUR_INCOME,
    component: LoanApplicationYourIncomeV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES,
    name: Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES,
    component: LoanApplicationYourExpensesV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_YOUR_DEBTS,
    name: Screen.LOAN_APPLICATION_V2_YOUR_DEBTS,
    component:
      WithAuthenticationLoanApplicationYourDebtsV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
    name: Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
    component: LoanApplicationYourUnloanV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY,
    name: Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY,
    component: ReviewApplicationSummaryV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT,
    name: Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT,
    component: InterstitialLoanSubmittedV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB,
    name: Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB,
    component: VerifyIncomeOpenBankingV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_SUBMIT,
    name: Screen.LOAN_APPLICATION_V2_SUBMIT,
    options: {
      headerShown: false,
    },
    component: FinaliseLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_VOI_COMPLETED,
    name: Screen.LOAN_APPLICATION_V2_VOI_COMPLETED,
    component: VOICompleted as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_COMPLETED,
    name: Screen.LOAN_APPLICATION_V2_COMPLETED,
    component: CompletedLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_OFFER,
    name: Screen.LOAN_APPLICATION_V2_OFFER,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_V2_OFFER_ESIGN,
    name: Screen.LOAN_APPLICATION_V2_OFFER_ESIGN,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferEsign as LoanApplicationScreenComponent,
  },
];

export const TopUpLoanApplicationRouteList: LoanApplicationRouteListType = [
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_INTRO,
    name: Screen.LOAN_APPLICATION_TOP_UP_INTRO,
    component: TopUpIntro as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY,
    name: Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY,
    component: LoanApplicationYourPropertyV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME,
    name: Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME,
    component: LoanApplicationYourIncomeV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES,
    name: Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES,
    component: LoanApplicationYourExpensesV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS,
    name: Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS,
    component:
      WithAuthenticationLoanApplicationYourDebtsV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN,
    name: Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN,
    component: LoanApplicationYourUnloanV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY,
    name: Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY,
    component: ReviewApplicationSummaryV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT,
    name: Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT,
    component: InterstitialLoanSubmittedV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB,
    name: Screen.LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB,
    component: VerifyIncomeOpenBankingV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_SUBMIT,
    name: Screen.LOAN_APPLICATION_TOP_UP_SUBMIT,
    options: {
      headerShown: false,
    },
    component: FinaliseLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_COMPLETED,
    name: Screen.LOAN_APPLICATION_TOP_UP_COMPLETED,
    component: CompletedLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_OFFER,
    name: Screen.LOAN_APPLICATION_TOP_UP_OFFER,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_TOP_UP_OFFER_ESIGN,
    name: Screen.LOAN_APPLICATION_TOP_UP_OFFER_ESIGN,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferEsign as LoanApplicationScreenComponent,
  },
];

export const PurchaseLoanApplicationRouteList: LoanApplicationRouteListType = [
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_INTRO,
    name: Screen.LOAN_APPLICATION_PURCHASE_INTRO,
    component: LoanApplicationIntroV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
    name: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
    component:
      LoanApplicationYourPropertyPurchase as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_BORROWERS,
    name: Screen.LOAN_APPLICATION_PURCHASE_BORROWERS,
    component: LoanApplicationBorrowersV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME,
    name: Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME,
    component: LoanApplicationYourIncomeV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES,
    name: Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES,
    component: LoanApplicationYourExpensesV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS,
    name: Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS,
    component:
      WithAuthenticationLoanApplicationYourDebtsV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN,
    name: Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN,
    component:
      LoanApplicationYourUnloanPurchase as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY,
    name: Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY,
    component: ReviewApplicationSummaryV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT,
    name: Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT,
    component: InterstitialLoanSubmittedV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB,
    name: Screen.LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB,
    component: VerifyIncomeOpenBankingV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_SUBMIT,
    name: Screen.LOAN_APPLICATION_PURCHASE_SUBMIT,
    options: {
      headerShown: false,
    },
    component: FinaliseLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED,
    name: Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED,
    component: VOICompleted as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_COMPLETED,
    name: Screen.LOAN_APPLICATION_PURCHASE_COMPLETED,
    component: CompletedLoanApplication as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_OFFER,
    name: Screen.LOAN_APPLICATION_PURCHASE_OFFER,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferV2 as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_OFFER_ESIGN,
    name: Screen.LOAN_APPLICATION_PURCHASE_OFFER_ESIGN,
    options: {
      headerShown: false,
    },
    component: YourUnloanOfferEsign as LoanApplicationScreenComponent,
  },
  {
    key: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
    name: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
    options: {
      headerShown: false,
    },
    component:
      LoanApplicationConditionalApproval as LoanApplicationScreenComponent,
  },
];
