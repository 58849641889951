import { useEffect, useRef } from 'react';
import { RecoilValue, useRecoilValue } from 'recoil';

// Invokes the callback whenever the recoil state changes
// Note this is a bit of an anti-pattern in recoil land, but it's
// useful for bridging to other stateful libraries, eg Formik
export function useRecoilObserver<T>(
  atom: RecoilValue<T>,
  callback: (newValue: T) => void,
) {
  const value = useRecoilValue(atom);
  const valueRef = useRef(value);
  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      callback(value);
    }
  }, [value, callback]);
}
