import {
  Pressable,
  styled,
  SxProp,
  Text,
  useDripsyTheme,
  useSx,
  View,
} from 'dripsy';
import { useState } from 'react';

import { TestID } from '../../../../testID/constants';
import { State_Enum } from '../../../generated/graphql';
import { Link } from '../../../ui/atoms/Link';
import { StyledIcon } from '../../../ui/atoms/StyledIcon';
import { ChevronRightIcon } from '../../../ui/svgs/ChevronRightIcon';
import { Skeleton } from '../../../ui/v2/Skeleton';
import { formatCurrency } from '../../../utils/currencyHelpers';

type UpfrontCostsDetailsProps = {
  loanSecurityState: State_Enum | undefined;
  deposit: number | undefined;
  depositLoading: boolean;
  estimatedConveyancing: number | undefined;
  estimatedConveyancingLoading: boolean;
  stampDuty: number | undefined;
  stampDutyLoading: boolean;
  estimatedTotalUpfrontCost: number | undefined;
  estimatedTotalUpfrontCostLoading: boolean;
  sxProp?: SxProp;
};

export function UpfrontCostsDetails({
  loanSecurityState,
  deposit,
  depositLoading,
  estimatedConveyancing,
  estimatedConveyancingLoading,
  estimatedTotalUpfrontCost,
  estimatedTotalUpfrontCostLoading,
  stampDuty,
  stampDutyLoading,
  sxProp,
}: UpfrontCostsDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const sx = useSx();
  const { theme } = useDripsyTheme();
  return (
    <View
      sx={{
        backgroundColor: '$quanternaryBackground',
        borderRadius: '$card',
        ...sxProp,
      }}
    >
      <Pressable
        sx={{
          flex: 1,
          pt: '$16',
          px: '$16',
        }}
        onPress={() => setIsOpen(!isOpen)}
        testID={TestID.UpfrontCostsDetails.Pressable}
      >
        <RowView
          sx={{
            borderColor: '$border',
            borderBottomWidth: isOpen ? '$1' : 0,
            alignItems: 'center',
            pb: '$16',
          }}
        >
          <StyledIcon
            name="information-circle"
            color="secondaryContent"
            style={sx({ color: '$fieldPromptText' })}
          />

          <Text variant="label" sx={{ mx: '$8', flex: 1 }}>
            {t('Content.UpfrontCostsDetails.Header1')}{' '}
            {estimatedTotalUpfrontCostLoading ? (
              <Skeleton width={50} height={theme.text.label.fontSize} show />
            ) : (
              <Text
                variant="label"
                testID={TestID.UpfrontCostsDetails.TotalUpfrontCost}
              >
                {estimatedTotalUpfrontCost != null
                  ? formatCurrency(estimatedTotalUpfrontCost, {
                      noFraction: false,
                    })
                  : '--'}
              </Text>
            )}{' '}
            {t('Content.UpfrontCostsDetails.Header2')}
          </Text>

          <ChevronDownIcon isOpen={isOpen} />
        </RowView>
      </Pressable>
      {isOpen ? (
        <View sx={{ pt: '$8', mx: '$16', mb: '$16' }}>
          <UpfrontCostDataRow
            label={t('Content.UpfrontCostsDetails.Deposit')}
            value={formatCurrency(deposit, {
              noFraction: false,
            })}
            loading={depositLoading}
          />

          <UpfrontCostDataRow
            label={t('Content.UpfrontCostsDetails.EstimatedConveyancing')}
            value={formatCurrency(estimatedConveyancing, {
              noFraction: false,
            })}
            loading={estimatedConveyancingLoading}
          />

          <UpfrontCostDataRow
            label={t('Content.UpfrontCostsDetails.StampDuty', {
              loanSecurityState: loanSecurityState ?? '--',
            })}
            value={formatCurrency(stampDuty, {
              noFraction: false,
            })}
            loading={stampDutyLoading}
          />
          <Text variant="caption" sx={{ pt: '$8' }}>
            {t('Content.UpfrontCostsDetails.WhatAreUpfrontCosts')}{' '}
            <Link
              style={sx({ variant: 'text.caption', color: '$link' })}
              href={t('Link.LearnMoreOnUpfrontCosts')}
            >
              {t('Content.Common.ButtonLabel.LearnMore')}
            </Link>
          </Text>
        </View>
      ) : null}
    </View>
  );
}

function UpfrontCostDataRow({
  label,
  value,
  loading,
}: {
  label: string;
  value: string;
  loading: boolean;
}) {
  const { theme } = useDripsyTheme();
  return (
    <RowView sx={{ justifyContent: 'space-between' }}>
      <DetailText>{label}</DetailText>
      {loading ? (
        <Skeleton width={40} height={theme.fontSizes.$13} show />
      ) : (
        <DetailText>{value}</DetailText>
      )}
    </RowView>
  );
}

function ChevronDownIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <ChevronRightIcon
      size={12}
      style={{
        right: 0,
        transform: [{ rotate: isOpen ? '-90deg' : '90deg' }],
      }}
    />
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const DetailText = styled(Text)({
  fontSize: '$13',
});
