import {
  Autopay_Frequency_Input_Enum,
  useGetLoanAccountDirectDebitLimitsQuery,
} from '../../generated/graphql';
import { MAXIMUM_DIRECT_DEBIT_AMOUNT } from '../constants';

export const useDirectDebitLimits = (cbaAccountId: string) => {
  const { data, loading } = useGetLoanAccountDirectDebitLimitsQuery({
    variables: { cba_account_id: cbaAccountId },
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  const account = data?.loan_account[0];

  if (!account) {
    return { loading, data: null };
  }

  const maximumDirectDebitAmount =
    account.limits?.maximum_direct_debit_amount ?? MAXIMUM_DIRECT_DEBIT_AMOUNT;

  const monthlyMinimumRepaymentAmount = account.monthly_minimum_repayment;

  if (!monthlyMinimumRepaymentAmount) {
    return { loading, data: null };
  }

  return {
    loading,
    data: {
      maximumDirectDebitAmount,
      minimimRepaymentAmount: {
        [Autopay_Frequency_Input_Enum.Weekly]: Math.ceil(
          monthlyMinimumRepaymentAmount / 4,
        ),
        [Autopay_Frequency_Input_Enum.Fortnightly]: Math.ceil(
          monthlyMinimumRepaymentAmount / 2,
        ),
        [Autopay_Frequency_Input_Enum.Monthly]: Math.ceil(
          monthlyMinimumRepaymentAmount,
        ),
      } as Record<Autopay_Frequency_Input_Enum, number>,
    },
  };
};
