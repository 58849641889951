import { H2, Pressable, Text, View } from 'dripsy';
import { useCallback, useState } from 'react';

import { TestID } from '../../../../testID/constants';
import { ErrorRow } from '../../../components/ErrorRow';
import { SuccessRow } from '../../../components/SuccessRow';
import { HomeHeader } from '../../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../../Home/navigation/types';
import { ActionSheetType, Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../../ui/atoms/ScreenContainer';
import { LoadingRow } from '../../../ui/molecules/LoadingRow';
import { CloseIcon } from '../../../ui/svgs/CloseIcon';
import { UnloanLogoRound } from '../../../ui/svgs/UnloanLogoRound';
import { Divider } from '../../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../../ui/v2/List';
import { InitialsAvatar } from './InitialsAvatar';
import { ProcessAccountStatus } from './models';
import { useAccounts } from './useAccounts';

type AccountCardListItemProps = {
  accountName: string;
  paymentInfo: string;
  onAction?: () => void;
};

const UnloanAccountCardListItem = ({
  accountName,
  paymentInfo,
}: Pick<AccountCardListItemProps, 'accountName' | 'paymentInfo'>) => (
  <ListItem
    sx={{ paddingVertical: '$10' }}
    icon={
      <View sx={{ mt: '$10', mr: '$12', mb: '$18', ml: '$16' }}>
        <UnloanLogoRound size={36} />
      </View>
    }
    title={<Text variant="emphasis">{accountName}</Text>}
    line={<Text variant="sSubHeading">{paymentInfo}</Text>}
    testID={TestID.AccountAddressBook.UnloanAccount}
  />
);

const OtherAccountCardListItem = ({
  accountName,
  paymentInfo,
  onAction,
}: AccountCardListItemProps) => (
  <ListItem
    sx={{ paddingVertical: '$10' }}
    icon={
      <InitialsAvatar
        sx={{ mt: '$10', mr: '$12', mb: '$18', ml: '$16' }}
        name={accountName}
      />
    }
    title={<Text variant="emphasis">{accountName}</Text>}
    line={<Text variant="sSubHeading">{paymentInfo}</Text>}
    action={
      <Pressable
        onPress={onAction}
        testID={TestID.AccountAddressBook.DeleteExternalAccountButton}
      >
        <CloseIcon size={22} />
      </Pressable>
    }
    testID={TestID.AccountAddressBook.ExternalAccount}
  />
);

type Props = HomeTabScreenProps<Screen.ACCOUNT_ADDRESS_BOOK>;

export const AccountAddressBook = ({ navigation, route }: Props) => {
  const { cbaAccountId } = route.params;

  const [processAccountStatus, setProcessAccountStatus] = useState<
    ProcessAccountStatus | undefined
  >();

  const onNavigateBack = useCallback(
    (status: ProcessAccountStatus) => setProcessAccountStatus(status),
    [],
  );

  const { unloanAccounts, externalAccounts, loading } = useAccounts();

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const navigateToAddNewAccount = useCallback(() => {
    setProcessAccountStatus(undefined);
    navigation.navigate(Screen.ADD_NEW_ACCOUNT, {
      cbaAccountId,
      onNavigateBack,
    });
  }, [cbaAccountId, navigation, onNavigateBack]);

  const deleteAccount = useCallback(
    (externalAccountId: string) => {
      navigation.navigate(ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE, {
        cbaAccountId,
        externalAccountId,
        onNavigateBack,
      });
    },
    [cbaAccountId, navigation, onNavigateBack],
  );

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader
        title={t('Content.AccountAddressBook.Header')}
        subTitle={t('Content.AccountAddressBook.SubHeading')}
      />

      {processAccountStatus === ProcessAccountStatus.Success ? (
        <SuccessRow
          message={t('Content.AccountAddressBook.AccountAdded')}
          mb="m"
          autoDismiss
        />
      ) : null}

      {processAccountStatus === ProcessAccountStatus.Fail ? (
        <ErrorRow
          message={t('Content.AccountAddressBook.AddAccountFailed')}
          mb="m"
        />
      ) : null}

      <H2 variant="sHeader" sx={{ mt: -8 }}>
        {t('Content.AccountAddressBook.UnloanAccounts.SectionHeader')}
      </H2>

      <CardList>
        {loading ? (
          <LoadingRow />
        ) : (
          unloanAccounts.map((unloanAccount, index) => (
            <>
              {index > 0 ? <Divider /> : null}
              <UnloanAccountCardListItem
                key={unloanAccount.id}
                accountName={unloanAccount.accountName}
                paymentInfo={unloanAccount.accountDetails}
              />
            </>
          ))
        )}
      </CardList>

      <H2 variant="sHeader">
        {t('Content.AccountAddressBook.OtherAccounts.SectionHeader')}
      </H2>

      {!loading && !externalAccounts.length ? (
        <Text
          variant="subHeading"
          sx={{ textAlign: 'center', my: '$10', color: '$secondary' }}
        >
          {t('Content.AccountAddressBook.OtherAccounts.NoOtherAccounts')}
        </Text>
      ) : null}

      <CardList>
        {loading ? (
          <LoadingRow />
        ) : (
          externalAccounts.map((externalAccount, index) => (
            <>
              {index > 0 ? <Divider /> : null}
              <OtherAccountCardListItem
                key={externalAccount.id}
                accountName={externalAccount.account_name}
                paymentInfo={externalAccount.account_details ?? ''}
                onAction={() => deleteAccount(externalAccount.id)}
              />
            </>
          ))
        )}
      </CardList>

      <Button
        secondary
        label={t('Content.AccountAddressBook.Action')}
        sx={{ mt: '$16' }}
        width="100%"
        onPress={navigateToAddNewAccount}
      />
    </UpliftScreenContainer>
  );
};
