import { ActionCard } from '../../ui/organisms/ActionCard';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatNextAutopayDate } from '../utils/formatNextAutopayDate';

type Props = {
  nextRepaymentDate: string;
  repaymentAmount: number;
  propertyAddress?: string;
  onSetupAutopay: () => void;
  onNextRepayment: () => void;
  loading?: boolean;
};

export function RepaymentCard({
  nextRepaymentDate,
  repaymentAmount,
  propertyAddress,
  onSetupAutopay,
  onNextRepayment,
  loading,
}: Props) {
  return (
    <ActionCard
      loading={loading}
      label={t('Content.HomeLoan.Repayment')}
      caption={
        nextRepaymentDate ? formatNextAutopayDate(nextRepaymentDate) : ''
      }
      subCaption={propertyAddress}
      amountLabel={`${formatCurrency(repaymentAmount)}`}
      onPress={onNextRepayment}
      alertAction={{
        alertLabel: t('Content.HomeLoan.AutomateYourLoanRepayments'),
        alertActionLabel: t('Content.HomeLoan.SetupAutopay'),
        onPressAlertAction: onSetupAutopay,
        alertActionSecondary: true,
      }}
    />
  );
}
