import { atomFamily } from 'recoil';

import { asyncStorageEffect } from '../../utils/hooks/useAsyncStorage';

export const newFeaturesModalVersionAtomFamily = atomFamily<
  string | null,
  string
>({
  key: 'newFeaturesModalVersion',
  default: null,
  effects: (key) =>
    key ? [asyncStorageEffect(`newFeaturesModalVersion_${key}`)] : [],
});
