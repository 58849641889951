type HasCurrentlyLoggedIn = {
  is_current_logged_in_applicant?: boolean | null;
};
/**
 * Sort applicants so applicant with is_current_logged_in_applicant true
 * is always first.
 */
export function sortApplicants<T extends HasCurrentlyLoggedIn>(
  applicants?: Array<T>,
) {
  if (!applicants || !applicants.length) {
    return [];
  }
  return applicants
    .slice()
    .sort(
      (a, b) =>
        Number(b.is_current_logged_in_applicant || false) -
        Number(a.is_current_logged_in_applicant || false),
    );
}
