import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { autopayCancellationStatusAtom } from '../../AutopaySettings/autopayAtom';
import { isAutopayChangeRequestExpired } from '../../AutopaySettings/utils/isAutopayChangeRequestExpired';
import { Change_Request_Status_Enum } from '../../generated/graphql';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { Screen } from '../../navigation/types/screens';
import { isWeb as defaultIsWeb } from '../../utils/platformUtils';
import { useLoanAccountWithTransactions } from '../graphql/loanAccountQueries';
import { HomeLoanModal } from '../types';
import { useHomeLoanModalIsOpenEffect } from './useHomeLoanModalIsOpenEffect';

const DEFAULT_NAVIGATE_TIMEOUT = 150;

const screenToModalMappings = {
  [Screen.AUTOPAY_CHANGE_REQUEST]: HomeLoanModal.AUTOPAY_CHANGE_REQUEST,
  [Screen.AUTOPAY_SETUP]: HomeLoanModal.AUTOPAY_SETUP,
  [Screen.AUTOPAY_EXISTING_SETTINGS]: HomeLoanModal.AUTOPAY_EXISTING_SETTINGS,
};

const modalToScreenMappings = Object.keys(screenToModalMappings).reduce(
  (prev, key) => ({
    ...prev,
    [screenToModalMappings[key as keyof typeof screenToModalMappings]]: key,
  }),
  {} as Partial<Record<HomeLoanModal, keyof typeof screenToModalMappings>>,
);

type NavigateToAutoPayModalFunc = (
  screen: keyof typeof screenToModalMappings,
  autopayChangeRequestId: string,
) => void;

type CurrentAutopayChangeRequest = NonNullable<
  NonNullable<
    ReturnType<typeof useLoanAccountWithTransactions>['data']
  >['current_autopay_change_request']
>[0];

const computeAutopayModal = ({
  currentAutopayChangeRequest,
  autopayCancellationStatus,
  hasAutopay,
}: {
  currentAutopayChangeRequest?: CurrentAutopayChangeRequest;
  autopayCancellationStatus?: Change_Request_Status_Enum | null;
  hasAutopay?: boolean;
}): { modal: HomeLoanModal; autopayChangeRequestId: string } => {
  // Existing autopay change request has not yet been completed, cancelled or expired
  if (
    currentAutopayChangeRequest &&
    !isAutopayChangeRequestExpired(currentAutopayChangeRequest)
  ) {
    return {
      modal: HomeLoanModal.AUTOPAY_CHANGE_REQUEST,
      autopayChangeRequestId: currentAutopayChangeRequest.id,
    };
  }
  // Autopay has been successfully cancelled
  if (autopayCancellationStatus === Change_Request_Status_Enum.Applied) {
    return { modal: HomeLoanModal.AUTOPAY_SETUP, autopayChangeRequestId: '' };
  }
  // Account already has autopay settings but no in-progress change request
  if (hasAutopay) {
    return {
      modal: HomeLoanModal.AUTOPAY_EXISTING_SETTINGS,
      autopayChangeRequestId: '',
    };
  }

  // Account has no autopay settings or in-progress change request
  return { modal: HomeLoanModal.AUTOPAY_SETUP, autopayChangeRequestId: '' };
};

export type UseShowHomeLoanAutopayModalProps = {
  cbaAccountId: string;
  modal?: HomeLoanModal;
  navigate: HomeTabScreenProps<Screen.HOME_LOAN>['navigation']['navigate'];
  setParams?: HomeTabScreenProps<Screen.HOME_LOAN>['navigation']['setParams'];
  currentAutopayChangeRequest?: CurrentAutopayChangeRequest;
  hasAutopay: boolean;
  loading: boolean;
  deeplinkModal?: boolean;
};

export const useShowHomeLoanAutopayModal = ({
  cbaAccountId,
  modal,
  navigate,
  setParams,
  currentAutopayChangeRequest,
  hasAutopay,
  loading,
  deeplinkModal = defaultIsWeb,
}: UseShowHomeLoanAutopayModalProps) => {
  const autopayCancellationStatus = useRecoilValue(
    autopayCancellationStatusAtom,
  );

  const autopayChangeRequestIdRef = useRef('');

  const navigateToAutopayScreen: NavigateToAutoPayModalFunc = useCallback(
    (screen, autopayChangeRequestId = '') => {
      autopayChangeRequestIdRef.current = autopayChangeRequestId;

      setParams?.({
        modal: screenToModalMappings[screen],
      });
    },
    [setParams],
  );

  const navigateToAutopayModal: NavigateToAutoPayModalFunc = useCallback(
    (screen, autopayChangeRequestId = '') =>
      // Timeout is needed to ensure the route has been modified before navigating again
      setTimeout(
        () =>
          screen === Screen.AUTOPAY_CHANGE_REQUEST
            ? navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
                screen,
                params: {
                  cbaAccountId,
                  autopayChangeRequestId,
                },
              })
            : navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
                screen,
                params: {
                  cbaAccountId,
                },
              }),
        DEFAULT_NAVIGATE_TIMEOUT,
      ),
    [navigate, cbaAccountId],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!modal) {
      return;
    }

    // Deeplink account/{cbaAccountId}/autopay-setup will catch all autopay use cases
    const screen = modalToScreenMappings[modal];

    if (!screen) {
      return;
    }

    const { modal: computedModal, autopayChangeRequestId } =
      computeAutopayModal({
        currentAutopayChangeRequest,
        autopayCancellationStatus,
        hasAutopay,
      });

    if (modal !== computedModal) {
      autopayChangeRequestIdRef.current = autopayChangeRequestId;

      setParams?.({ modal: computedModal });
    } else {
      navigateToAutopayModal(screen, autopayChangeRequestIdRef.current);
    }
  }, [
    autopayCancellationStatus,
    currentAutopayChangeRequest,
    hasAutopay,
    loading,
    modal,
    navigateToAutopayModal,
    setParams,
  ]);

  useHomeLoanModalIsOpenEffect({ modalToScreenMappings, setParams, modal });

  const showAutopayModal = useCallback(() => {
    const { modal: computedModal, autopayChangeRequestId } =
      computeAutopayModal({
        currentAutopayChangeRequest,
        autopayCancellationStatus,
        hasAutopay,
      });

    const screen = modalToScreenMappings[computedModal];

    if (!screen) {
      return;
    }

    if (deeplinkModal) {
      navigateToAutopayScreen(screen, autopayChangeRequestId);
    } else {
      navigateToAutopayModal(screen, autopayChangeRequestId);
    }
  }, [
    autopayCancellationStatus,
    currentAutopayChangeRequest,
    deeplinkModal,
    hasAutopay,
    navigateToAutopayModal,
    navigateToAutopayScreen,
  ]);

  return showAutopayModal;
};
