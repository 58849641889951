import { useNavigation } from '@react-navigation/native';
import { Text } from 'dripsy';

import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

export function AMLCTFActModalV2() {
  const navigation = useNavigation();

  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      headerText={t('Content.AMLCTFActModal.Title')}
      hideBackButton
    >
      <Text>{t('Content.AMLCTFActModal.Content')}</Text>
    </ModalScreenContainer>
  );
}
