import {
  Text,
  TextInput as DripsyTextInput,
  ThemeColorName,
  useDripsyTheme,
  useSx,
} from 'dripsy';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { MaxCharacter } from '../../../constants/fieldRules';
import { mergeRefs } from '../../../utils/mergeRefs';
import { isWeb } from '../../../utils/platformUtils';
import { Box } from '../../atoms/Box';
import { InputRow } from '../InputRow';
import { TextInputV2Props } from '../TextInput';
import { ChevronDownIcon } from './ChevronDownIcon';
import { SuggestionsResultProps } from './SuggestionsResult';

export type ComboboxProps<TValue> = Expand<
  Omit<TextInputV2Props, 'label' | 'onChangeText' | 'value'> & {
    label?: string;
    value?: TValue;
    getSearchValueText?: (value?: TValue) => string;
    isLoadingSuggestionData?: boolean;
    suggestionData: Array<TValue>;
    suggestionsTestIDPrefix?: string;
    suggestionResultZIndex?: number;
    onChangeSearchText?: (search: string) => void;
    onSuggestionSelect?: (value: TValue) => Promise<void>;
    focusLabel?: string;
    focusPlaceholder?: string;

    // Specific for test and storybook
    searchTextInitialValue?: string;
  }
>;

const focusedDataset = isWeb ? { focused: 'true' } : undefined;

export type ComboboxSelectionProps<TValue> = { data?: TValue };

export function makeCombobox<TValue>(
  SuggestionsResultComponent: React.ComponentType<
    SuggestionsResultProps<TValue>
  >,
  ComboboxSelectionComponent?: React.ComponentType<
    ComboboxSelectionProps<TValue>
  >,
) {
  const Combobox = React.forwardRef<RNTextInput | null, ComboboxProps<TValue>>(
    (
      {
        label: initialLabel,
        suggestionData,
        suggestionsTestIDPrefix,
        inputTestID,
        containerTestID,
        disabled,
        isLoadingSuggestionData,
        getSearchValueText,
        // Generally we want this to be higher than other components
        suggestionResultZIndex = 2,
        sx: sxProp,
        onChangeSearchText,
        onSuggestionSelect,
        searchTextInitialValue,
        // These need to be controlled by a form controller
        value,
        error,
        focused,
        onBlur: onBlurProp,
        onFocus: onFocusProp,
        // These should go to RN text input
        maxLength,
        keyboardType,
        placeholder: initialPlaceholder,
        focusLabel,
        focusPlaceholder,
      },
      ref,
    ) => {
      const localTextInputRefs = useRef<RNTextInput>(null);
      const [labelId] = useState(() => uuidv4());
      const [searchValue, setSearchValue] = useState(
        (searchTextInitialValue || getSearchValueText?.(value)) ?? '',
      );
      useEffect(() => {
        onChangeSearchText?.(searchValue);
      }, [onChangeSearchText, searchValue]);

      const onBlur = useCallback(
        (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
          onBlurProp?.(e);
        },
        [onBlurProp],
      );

      const onFocus = useCallback(
        (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
          if (disabled) {
            return;
          }

          onFocusProp?.(e);
        },
        [disabled, onFocusProp],
      );

      const onResultItemPress = useCallback(
        async (data: TValue) => {
          setSearchValue(getSearchValueText?.(data) ?? '');
          await onSuggestionSelect?.(data);
          localTextInputRefs.current?.blur();
        },
        [getSearchValueText, onSuggestionSelect],
      );
      let borderColor: ThemeColorName = '$border';
      let labelColor: ThemeColorName = '$secondary';
      let bgColor: ThemeColorName = '$inputBackground';
      let valueColor: ThemeColorName = '$labelsPrimary';
      if (error) {
        borderColor = '$error';
        labelColor = '$error';
      }
      if (focused) {
        borderColor = '$focus';
      }
      if (disabled) {
        labelColor = '$secondaryDisabled';
        bgColor = '$inputBackgroundDisabled';
        valueColor = '$secondary';
      }
      const {
        theme: {
          colors: { $secondary: placeholderTextColor },
        },
      } = useDripsyTheme();

      const label = focused && focusLabel ? focusLabel : initialLabel;

      const showLabel =
        !!label && (!ComboboxSelectionComponent || !value || focused);

      const showSmallLabel = showLabel && (focused || searchValue.length > 0);

      const sx = useSx();

      const showResults =
        focused === true && (searchValue.length || suggestionData.length) > 0;

      const placeholder =
        focused && focusPlaceholder ? focusPlaceholder : initialPlaceholder;

      const showPlaceholder =
        !!placeholder && (!ComboboxSelectionComponent || !value || focused);

      return (
        <InputRow
          sx={{
            bg: bgColor,
            py: '$7',
            borderColor,
            flexDirection: 'row',
            alignItems: 'center',
            ...(focused
              ? { overflow: 'visible', zIndex: suggestionResultZIndex }
              : null),
            ...sxProp,
          }}
          testID={containerTestID}
          dataSet={focused ? focusedDataset : undefined}
        >
          {showLabel ? (
            <Text
              variant={showSmallLabel ? 'tiny' : 'default'}
              sx={{
                px: '$16',
                color: labelColor,
                position: 'absolute',
                ...(showSmallLabel ? { top: '$7' } : { zIndex: -1 }),
              }}
              numberOfLines={1}
              nativeID={labelId}
              selectable={false}
            >
              {label}
            </Text>
          ) : null}
          {!showLabel && !showPlaceholder && ComboboxSelectionComponent ? (
            <Box
              style={sx({
                px: '$16',
                position: 'absolute',
                left: 0,
                zIndex: -1,
              })}
            >
              <ComboboxSelectionComponent data={value} />
            </Box>
          ) : null}
          <DripsyTextInput
            sx={{
              variant: 'text.default',
              color: valueColor,
              pt: label ? '$16' : undefined,
              px: '$16',
              flexGrow: 1,
            }}
            value={searchValue}
            editable={!disabled}
            maxLength={maxLength || MaxCharacter.general}
            numberOfLines={1}
            keyboardType={keyboardType}
            onChangeText={setSearchValue}
            onBlur={onBlur}
            onFocus={onFocus}
            underlineColorAndroid="transparent"
            placeholder={showPlaceholder ? placeholder : ''}
            // This is a browser specific fix for Chrome only.
            // see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error `chrome-off` is a web only enum and
            // our RN types override stopped working since Expo 49 upgrades,
            // see `@types/react-native.d.ts` for existing overrides.
            autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
            testID={inputTestID}
            ref={mergeRefs([localTextInputRefs, ref])}
            accessibilityLabelledBy={labelId}
            placeholderTextColor={placeholderTextColor}
            autoCorrect={false}
          />
          <ChevronDownIcon disabled={disabled} isOpen={showResults} />
          <SuggestionsResultComponent
            searchResult={suggestionData}
            isLoading={isLoadingSuggestionData}
            showResults={showResults}
            suggestionsTestIDPrefix={suggestionsTestIDPrefix}
            onResultItemPress={onResultItemPress}
            containerSx={(theme) => ({
              position: 'absolute',
              width: '100%',
              // Positioned below input
              top: theme.sizes.inputRow.minHeight - theme.borderWidths.$1,
              borderBottomLeftRadius: '$input',
              borderBottomRightRadius: '$input',
              borderWidth: '$1',
              borderColor: '$border',
              justifyContent: 'center',
              minHeight: theme.sizes.inputRow.minHeight,
              backgroundColor: '$inputBackground',
              boxShadow: 'dropShadow',
              zIndex: suggestionResultZIndex,
              overflow: 'hidden',
            })}
            selectedValue={value}
          />
        </InputRow>
      );
    },
  );
  Combobox.displayName = 'ComboboxV2';

  return Combobox;
}
