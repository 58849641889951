import { Pressable, SxProp, View } from 'dripsy';
import { memo, useState } from 'react';
import Animated from 'react-native-reanimated';

import { TestID } from '../../../testID/constants';
import { SettlementProcessRow } from '../../Settlement/components/SettlementProcessRow';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { useCardHoverShadowStyle } from '../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../ui/utils/useCardTranslateYAnimatedStyle';
import { LoanCardChinContainer } from './LoanCardChinContainer';
import { YourApplicationHeader } from './YourApplicationHeader';

type Props = {
  onPress?: () => void;
  primaryButtonText?: string;
  sx?: SxProp;
};

export const ApplyCard = memo<Props>(
  ({
    onPress,
    primaryButtonText = t('Content.Common.ButtonLabel.GetStarted'),
    sx: sxProps = {},
  }) => {
    const [isHover, setIsHover] = useState(false);

    const shadowProps = useCardHoverShadowStyle({
      isHover,
    });

    const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

    return (
      <Animated.View style={animatedStyle}>
        <View
          onPointerEnter={() => setIsHover(true)}
          onPointerLeave={() => setIsHover(false)}
          sx={{
            backgroundColor: '$inputBackground',
            borderRadius: '$card',
            overflow: 'visible',
            cursor: 'unset',
            ...shadowProps,
            ...sxProps,
          }}
        >
          <Pressable
            testID={TestID.ApplyCard.Card}
            role="button"
            onPress={onPress}
            onPressIn={() => setIsHover(true)}
            onPressOut={() => setIsHover(false)}
          >
            <YourApplicationHeader
              title={t('Content.Home.NewKindOfHomeLoan')}
              containerSx={{
                borderTopLeftRadius: '$card',
                borderTopRightRadius: '$card',
              }}
              isHover={isHover}
            />
          </Pressable>
          <Separator mx="m" />
          <LoanCardChinContainer
            justifyContent="space-between"
            height={SettlementProcessRow.height}
          >
            <StyledText variant="button">
              {t('Content.Home.ApplyForUnloan')}
            </StyledText>
            <Button
              label={primaryButtonText}
              variant="pill"
              onPress={onPress}
              alignSelf="center"
              testID={TestID.ApplyCard.ApplyLoanButton}
              onPressIn={() => setIsHover(true)}
              onPressOut={() => setIsHover(false)}
            />
          </LoanCardChinContainer>
        </View>
      </Animated.View>
    );
  },
);
