import { useTheme } from '@shopify/restyle';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import { UnauthenticatedTopNavBar } from '../../Home/navigation/components/TopNavBar';
import { Box } from '../../ui/atoms/Box';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';

export const AuthScreenContainer = ({
  children,
  scrollable,
}: {
  children: React.ReactNode;
  scrollable?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box flex={1}>
      <Box style={styles.topSticky}>
        <UnauthenticatedTopNavBar />
      </Box>
      <ScreenContainer
        containerStyle={{ maxWidth: 750, paddingTop: theme.sizes.xl }}
        scrollable={scrollable}
      >
        {children}
      </ScreenContainer>
    </Box>
  );
};

const styles = StyleSheet.create({
  topSticky: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
});
