import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { isAndroidWeb } from '../../../utils/platformUtils';

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const Context = createContext<BeforeInstallPromptEvent | null>(null);

export function useAndroidInstallPwaEvent() {
  return useContext(Context);
}

export function AndroidInstallPwaEventProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [event, setEvent] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setEvent(e);
    };

    if (isAndroidWeb()) {
      window.addEventListener('beforeinstallprompt', handler);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  return <Context.Provider value={event}>{children}</Context.Provider>;
}
