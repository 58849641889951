import * as React from 'react';

import { useGetMeQuery } from '../../generated/graphql';
import { BaseMobileVerify } from './BaseMobileVerify';

type Props = Omit<React.ComponentProps<typeof BaseMobileVerify>, 'target'> & {
  onPressResend?: () => void;
};

export const OldMobileVerify = ({ onPressResend, ...props }: Props) => {
  const { data } = useGetMeQuery();
  const oldMobile = data?.me[0]?.user?.mobile ?? '';

  return (
    <BaseMobileVerify
      onPressResend={onPressResend}
      target={oldMobile}
      {...props}
    />
  );
};
