import { Text, View } from 'dripsy';
import { Formik } from 'formik';
import { useContext } from 'react';

import { TestID } from '../../../../testID/constants';
import { ErrorRow } from '../../../components/ErrorRow';
import { FormSelectV2 } from '../../../components/form/FormikInputs';
import { FullNameByApplicantIds } from '../../../DebtsWizard/utils/types';
import { FeatureFlagsContext } from '../../../FeatureFlags/context';
import { FeatureFlag } from '../../../FeatureFlags/featureFlags';
import {
  MergedLiabilityForSetupLoanScreenFragment,
  Repayment_Calculation_Error,
  Top_Up_Reason_Enum,
} from '../../../generated/graphql';
import { Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { FormikFormError } from '../../../ui/v2/FormError';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { transformStringToEnum } from '../../../utils/enumHelpers';
import {
  formatInterestRate,
  formatLoanTerm,
  pluralize,
} from '../../../utils/stringHelpers';
import { getSumOfSelectedRefinancedLiabilities } from '../../utils/getSumOfSelectedRefinancedLiabilities';
import { getTotalCashoutAmount } from '../../utils/getTotalCashoutAmount';
import {
  makeSetupLoanTopUpValidationSchema,
  MAX_LOAN_TERM_IN_MONTHS,
  MONTH_TERM_OPTIONS,
  SetupLoanTopUpFieldNameMap,
  SetupLoanTopUpFormField,
  SetupLoanTopUpFormInitialValues,
  SetupLoanYesNoEnum,
  YEAR_TERM_OPTIONS,
} from '../../utils/setupLoanFormHelper';
import { UseLoanAmountCalculationV2Return } from '../../utils/useSetupLoanCalculation';
import { MonthlyRepaymentError } from './MonthlyRepaymentError';
import { SectionHeading, SHeaderText } from './SetupLoanFormWithLoanAmount';
import { SetupLoanTopUpFooter } from './SetupLoanTopUpFooter';
import { SetupLoanTopUpFormConsolidateYourDebts } from './SetupLoanTopUpFormConsolidateYourDebts';
import { SetupLoanTopUpFormTopUp } from './SetupLoanTopUpFormTopUp';

const createLoanTermLabel = ({
  termYearsInMonths,
  termMonths,
}: {
  termYearsInMonths: number;
  termMonths: number;
}) => {
  const years = termYearsInMonths / 12;
  const yearsLabel = pluralize(
    years,
    t('Content.SetupLoanTopUp.SummarySection.Year'),
  );

  if (termMonths) {
    const monthsLabel = pluralize(
      termMonths,
      t('Content.SetupLoanTopUp.SummarySection.Month'),
    );

    return `${yearsLabel} ${t('Content.Common.Word.and')} ${monthsLabel}`;
  }

  return yearsLabel;
};

export type SetupLoanTopUpFormProps = UseLoanAmountCalculationV2Return & {
  screen: Screen;
  topUpLiability: MergedLiabilityForSetupLoanScreenFragment;
  liabilitiesAllowedToBeConsolidated: Array<MergedLiabilityForSetupLoanScreenFragment>;
  fullNameByApplicantIds: FullNameByApplicantIds;
  productRate: number | undefined;
  initialValues: SetupLoanTopUpFormInitialValues;
  loanApplicationId: string;
  isSubmitting?: boolean;
  onSubmit: (values: SetupLoanTopUpFormInitialValues) => void;
  /** For storybook purpose */
  forceShowMonthlyRepaymentError?: boolean;
  forceShowTopUpLessThanMinError?: boolean;
  initialLoanTermMonths?: number | null;
  redrawBalance?: number;
};

function mapTopUpFields(values: SetupLoanTopUpFormInitialValues) {
  return {
    [Top_Up_Reason_Enum.YourHome]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.YourHome]],
    [Top_Up_Reason_Enum.FinancialInvestment]:
      values[
        SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.FinancialInvestment]
      ],
    [Top_Up_Reason_Enum.InvestmentProperty]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.InvestmentProperty]],
    [Top_Up_Reason_Enum.PersonalUse]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.PersonalUse]],
    [Top_Up_Reason_Enum.Other]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.Other]],
  };
}

const validationSchema = makeSetupLoanTopUpValidationSchema();

export function SetupLoanTopUpForm({
  screen,
  topUpLiability,
  liabilitiesAllowedToBeConsolidated,
  fullNameByApplicantIds,
  productRate,
  initialValues,
  loanApplicationId,
  isSubmitting,
  onSubmit,
  calculationData,
  calculationLoading,
  calculateLoanAmounts,
  forceShowMonthlyRepaymentError,
  forceShowTopUpLessThanMinError,
  initialLoanTermMonths,
  redrawBalance,
}: SetupLoanTopUpFormProps) {
  const { flags } = useContext(FeatureFlagsContext);

  const enableTopUpDebtConsolidation =
    flags[FeatureFlag.EnableTopUpDebtConsolidation];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleSubmit, dirty, setFieldValue }) => {
        /**
         * Only show error for monthly repayment calculation when:
         * 1. Form is dirty
         * We don't want to display any error from repayment calculation when form first initialized.
         * This is to avoid displaying error when it is user's first time visiting this screen
         * (for example when there is no target loan being setup yet)
         * 2. When it is not fetching the calculation data
         */
        const showMonthlyRepaymentErrorRow =
          forceShowMonthlyRepaymentError || (dirty && !calculationLoading);

        const termYearsInMonths =
          values[SetupLoanTopUpFormField.TermYearsInMonths] ?? 0;
        const termMonths = values[SetupLoanTopUpFormField.TermMonths] ?? 0;

        const previousTermInMonths = initialLoanTermMonths ?? 0;

        const termInMonths = termYearsInMonths + termMonths;

        const sumTopUpAmount =
          values[SetupLoanTopUpFormField.HasTopUpAmount] ===
          SetupLoanYesNoEnum.Yes
            ? getTotalCashoutAmount(mapTopUpFields(values))
            : 0;

        const isTopUpLessThanMin =
          (forceShowTopUpLessThanMinError || (dirty && !calculationLoading)) &&
          calculationData?.calculate_app_total_loan_amount_v2
            .is_top_up_less_than_min;

        const minRepaymentError = transformStringToEnum(
          calculationData?.calculate_app_total_loan_amount_v2
            .min_repayment_error_type,
          Repayment_Calculation_Error,
        );
        const minLoanAmount =
          calculationData?.calculate_app_total_loan_amount_v2.min_loan_amount;
        const maxLoanAmount =
          calculationData?.calculate_app_total_loan_amount_v2.max_loan_amount;

        const sumOfSelectedConsolidatedLiabilities =
          getSumOfSelectedRefinancedLiabilities({
            mergedLiabilities: liabilitiesAllowedToBeConsolidated,
            selectedCurrentLiabilityForRefinanceIds: new Set(
              values[SetupLoanTopUpFormField.SelectedLoansToBeRefinanced],
            ),
          });

        const resetDesiredLoanAmount = (selectedLiabilityId: string) => () => {
          if (!selectedLiabilityId) {
            return;
          }

          const selectedCurrentLiabilityForRefinanceIds = new Set(
            values[SetupLoanTopUpFormField.SelectedLoansToBeRefinanced],
          );

          if (
            selectedCurrentLiabilityForRefinanceIds.has(selectedLiabilityId)
          ) {
            selectedCurrentLiabilityForRefinanceIds.delete(selectedLiabilityId);
          } else {
            selectedCurrentLiabilityForRefinanceIds.add(selectedLiabilityId);
          }

          const sumOfSelectedRefinancedLiabilities =
            getSumOfSelectedRefinancedLiabilities({
              mergedLiabilities: liabilitiesAllowedToBeConsolidated,
              selectedCurrentLiabilityForRefinanceIds,
            });

          setFieldValue(
            SetupLoanTopUpFormField.DesiredLoanAmount,
            (topUpLiability?.dynamite_limit ?? 0) +
              sumOfSelectedRefinancedLiabilities,
            true,
          );
        };

        const loanTermLabel = createLoanTermLabel({
          termYearsInMonths,
          termMonths,
        });

        return (
          <View pointerEvents={isSubmitting ? 'none' : undefined}>
            {showMonthlyRepaymentErrorRow ? (
              <MonthlyRepaymentError
                minMonthlyRepaymentError={minRepaymentError}
                minLoanAmountToCalculate={minLoanAmount}
                maxLoanAmountToCalculate={maxLoanAmount}
                sx={{ my: '$8' }}
              />
            ) : null}

            {isTopUpLessThanMin ? (
              <ErrorRow
                sx={{ my: '$8' }}
                message={t('Content.SetupLoan.FieldError.MinXTopUpAmount', {
                  x: formatCurrency(minLoanAmount),
                })}
              />
            ) : null}

            <SetupLoanTopUpFormTopUp
              screen={screen}
              values={values}
              errors={errors}
              sumTopUpAmount={
                sumOfSelectedConsolidatedLiabilities + sumTopUpAmount
              }
            />

            {enableTopUpDebtConsolidation &&
            liabilitiesAllowedToBeConsolidated.length > 0 ? (
              <SetupLoanTopUpFormConsolidateYourDebts
                screen={screen}
                fullNameByApplicantIds={fullNameByApplicantIds}
                liabilitiesAllowedToBeConsolidated={
                  liabilitiesAllowedToBeConsolidated
                }
                resetDesiredLoanAmount={resetDesiredLoanAmount}
                sumOfSelectedConsolidatedLiabilities={
                  sumOfSelectedConsolidatedLiabilities
                }
              />
            ) : null}

            <View sx={{ mt: '$24' }}>
              <SectionHeading
                sectionTitle={t('Content.SetupLoanTopUp.LoanTermSection.Title')}
                sectionValue={
                  <View sx={{ flexDirection: 'row' }}>
                    {previousTermInMonths !== termInMonths ? (
                      <SHeaderText
                        sx={{
                          color: '$secondary',
                          fontWeight: '400',
                          textDecorationLine: 'line-through',
                          mr: '$8',
                        }}
                      >
                        {formatLoanTerm(previousTermInMonths)}
                      </SHeaderText>
                    ) : null}

                    <SHeaderText sx={{ fontWeight: '400' }}>
                      {formatLoanTerm(termInMonths)}
                    </SHeaderText>
                  </View>
                }
              />
              <View sx={{ flexDirection: 'row' }}>
                <View sx={{ flex: 1, flexDirection: 'column', mr: '$16' }}>
                  <FormSelectV2
                    sx={{ mt: '$8' }}
                    name={SetupLoanTopUpFormField.TermYearsInMonths}
                    testID={TestID.SetupLoanForm.LoanTermYearsInMonths}
                    items={YEAR_TERM_OPTIONS}
                    label={t('Content.SetupLoanTopUp.LoanTermSection.Years')}
                    onValueChange={(value) => {
                      if (value === MAX_LOAN_TERM_IN_MONTHS) {
                        setFieldValue(SetupLoanTopUpFormField.TermMonths, 0);
                      }
                    }}
                  />
                  <FormikFormError
                    name={SetupLoanTopUpFormField.TermYearsInMonths}
                    sx={{ mt: '$8' }}
                  />
                </View>
                <View sx={{ flex: 1, flexDirection: 'column' }}>
                  <FormSelectV2
                    sx={{ mt: '$8' }}
                    name={SetupLoanTopUpFormField.TermMonths}
                    testID={TestID.SetupLoanForm.LoanTermMonths}
                    items={MONTH_TERM_OPTIONS}
                    label={t('Content.SetupLoanTopUp.LoanTermSection.Months')}
                    disabled={
                      values[SetupLoanTopUpFormField.TermYearsInMonths] ===
                      MAX_LOAN_TERM_IN_MONTHS
                    }
                  />
                  <FormikFormError
                    name={SetupLoanTopUpFormField.TermMonths}
                    sx={{ mt: '$8' }}
                  />
                </View>
              </View>
            </View>

            <View
              sx={{
                mt: '$24',
                py: '$8',
                borderTopColor: '$border',
                borderTopWidth: '$1',
              }}
            >
              <SetupLoanTopUpFooter
                termInMonths={termInMonths}
                sumTopUpAmount={
                  sumOfSelectedConsolidatedLiabilities + sumTopUpAmount
                }
                desiredLoanAmount={topUpLiability?.dynamite_limit ?? 0}
                loanApplicationId={loanApplicationId}
                calculateLoanAmounts={calculateLoanAmounts}
                calculationData={calculationData}
                calculationLoading={calculationLoading}
                topUpLiability={topUpLiability}
                redrawBalance={redrawBalance}
              />
              <Text variant="caption" sx={{ py: '$8' }}>
                {t('Content.SetupLoanTopUp.SummarySection.TopUpDescription')}
              </Text>

              <Text variant="caption" sx={{ py: '$8' }}>
                {t(
                  'Content.SetupLoanTopUp.SummarySection.MinRepaymentsDescription',
                  {
                    loanTermLabel,
                    productRate: formatInterestRate(productRate),
                  },
                )}
              </Text>
            </View>

            <Button
              label={t('Content.Common.ButtonLabel.Done')}
              mt="l"
              alignSelf="stretch"
              testID={TestID.SetupLoanForm.SubmitButton}
              onPress={() => handleSubmit()}
              showSpinner={isSubmitting}
              disabled={
                isSubmitting ||
                calculationLoading ||
                !!minRepaymentError ||
                isTopUpLessThanMin
              }
            />
          </View>
        );
      }}
    </Formik>
  );
}
