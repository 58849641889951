import { A, Text } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { IntroSection } from '../components/IntroSection';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { getNextScreenNameForLoanApplicationWizard } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import { useCreateTopUpLoanApplication } from '../utils/useCreateLoanApplication';

type Props = LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_TOP_UP_INTRO>;

const loanApplicationType = Loan_Application_Type_Enum.TopUp;

/**
 * We're using `getNextScreenNameForLoanApplicationWizard` explicitly here instead of
 * `navigateToNextLoanApplicationModal` because we want to navigate to the next screen based on the created loan application ID and type.
 */
const nextScreenName = getNextScreenNameForLoanApplicationWizard(
  Loan_Application_Type_Enum.TopUp,
  LoanApplicationSection.Intro,
  // When we pass `nextScreenName` to `navigation.navigate`, TypeScript throws an error even though it is a valid LoanApplicationScreen name.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any;

function BaseTopUpIntro({ navigation, route }: Props) {
  const cbaAccountId = route.params?.cbaAccountId || '';
  const [createTopUpLoanApplication, { loading }] =
    useCreateTopUpLoanApplication();

  const continueLoanApplication = useCallback(async () => {
    try {
      const { loanApplicationId } = await createTopUpLoanApplication(
        cbaAccountId,
      );

      navigation.navigate(Screen.LOAN_APPLICATION_V2, {
        screen: nextScreenName,
        params: {
          loanApplicationId,
        },
      });
    } catch (error: unknown) {
      captureException(
        t('Content.Common.Error.FailCreateLoanApplication'),
        null,
        error,
      );
    }
  }, [cbaAccountId, createTopUpLoanApplication, navigation]);

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.LoanApplicationIntro.BeforeWeBegin')}
        caption={t(
          `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.Introduction`,
        )}
        pb="xl"
      />
      <Box mx="l">
        <IntroSection
          icon="yourProperty"
          title={t('Content.LoanApplicationIntro.YourProperty')}
          description={t(
            `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.YourPropertyCaption`,
          )}
        />
        <IntroSection
          icon="yourFinancial"
          title={t('Content.LoanApplicationIntro.YourFinancials')}
          description={t(
            `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.YourFinancialsCaption`,
          )}
        />
        <Text variant="caption" sx={{ textAlign: 'center' }}>
          {t('Content.Common.Disclaimer.PrivacyPolicyForSplash')}

          <A
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            variant="link"
            onPress={() => {
              navigation.navigate(Screen.SINGLE_V2_MODAL, {
                screen: Screen.PRIVACY_COLLECTION_STATEMENT_MODAL,
                params: {
                  loanApplicationId: route.params?.loanApplicationId,
                },
              });
            }}
          >
            {t('Content.Common.Disclaimer.PrivacyPolicyForSplashInlineLink')}
          </A>
        </Text>
      </Box>
      <LoanApplicationWizardFooter
        primaryButtonLabel={t(
          `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.ButtonLabel`,
        )}
        onPrimaryButtonPress={continueLoanApplication}
        primaryButtonTestID={TestID.Intro.BeforeWeBeginContinueButton}
        isPrimaryButtonLoading={loading}
        disablePrimaryButton={loading}
      />
    </LoanApplicationScreenContainer>
  );
}

export const TopUpIntro = withAuthenticationRequired(BaseTopUpIntro);
