import { SxProp } from 'dripsy';
import React from 'react';

import { usePaymentFailureDetails } from '../hooks/usePaymentFailureDetails';
import { AmortizationFailureNotification } from './AmortizationFailureNotification';
import { DirectDebitFailureNotification } from './DirectDebitFailureNotification';

type Props = {
  cbaAccountId: string;
  navigateOnPress: () => void;
  sx?: SxProp;
};

// Shows only one notification at a time.
// Amortization / repayment failure notification is prioritized over direct
// debit failure notification.
export const PaymentFailureNotification = ({
  cbaAccountId,
  navigateOnPress,
  sx,
}: Props) => {
  const { amortizationFailureDetails, directDebitFailureDetails } =
    usePaymentFailureDetails(cbaAccountId);

  if (amortizationFailureDetails) {
    return (
      <AmortizationFailureNotification
        sx={sx}
        navigateOnPress={navigateOnPress}
        loanAccountName={amortizationFailureDetails.accountDetails.name}
        {...amortizationFailureDetails}
      />
    );
  }

  if (directDebitFailureDetails) {
    return (
      <DirectDebitFailureNotification
        sx={sx}
        navigateOnPress={navigateOnPress}
        {...directDebitFailureDetails}
      />
    );
  }

  return null;
};
