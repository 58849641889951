import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export const YourCurrentLoanIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => {
  const idPrefix = useSvgIdPrefix('YourCurrentLoanIcon');
  return (
    <Svg width={45} height={45} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.362 20.624v20.623c0 .952.697.946 1.6.939l.245-.001h29.77c.904.008 1.6.014 1.6-.938V20.624L22.97 4.688 6.362 20.623Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        opacity={0.05}
        d="M22.968 5.625c-.437 0-.874.157-1.223.472l-4.945 4.51L6.36 20.623v5.053l16.608-14.964 16.608 14.964v-5.053l-10.44-10.017-4.944-4.51a1.824 1.824 0 0 0-1.224-.472Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.07}
        d="M22.968 4.688a1.83 1.83 0 0 0-1.223.468L16.8 9.641 6.36 19.6v5.024L22.969 9.747l16.608 14.878V19.6l-10.44-9.96-4.944-4.485a1.83 1.83 0 0 0-1.224-.468Z"
        fill="#2C2C2C"
      />
      <Path
        d="M41.879 20.162 24.192 4.222a1.824 1.824 0 0 0-2.447 0L4.06 20.162a.947.947 0 0 0-.06 1.34l1.51 1.636c.34.37.908.398 1.283.063L22.969 8.838l16.177 14.364a.913.913 0 0 0 1.282-.063l1.51-1.636a.948.948 0 0 0-.06-1.341Z"
        fill="#A3A3A3"
      />
      <Path
        d="M17.477 29.175c-1.12 0-2.024-.37-2.708-1.11-.685-.741-1.027-1.737-1.027-2.989 0-1.366.364-2.45 1.093-3.25.729-.802 1.7-1.203 2.916-1.203 1.165 0 2.074.363 2.73 1.088.656.725.983 1.744.983 3.057 0 1.35-.362 2.423-1.087 3.217-.726.793-1.692 1.19-2.9 1.19Zm11.32-8.323a.1.1 0 0 1 .084.153L18.747 37.22a.1.1 0 0 1-.085.048h-2.444a.1.1 0 0 1-.085-.153l10.113-16.217a.1.1 0 0 1 .085-.047h2.465Zm-11.112 1.786c-1.07 0-1.604.774-1.604 2.324 0 1.465.509 2.198 1.527 2.198 1.04 0 1.56-.763 1.56-2.29 0-1.488-.495-2.232-1.483-2.232Zm9.6 14.86c-1.12 0-2.023-.37-2.707-1.111-.685-.74-1.027-1.736-1.027-2.988 0-1.366.364-2.45 1.093-3.25.728-.802 1.7-1.203 2.916-1.203 1.171 0 2.083.359 2.735 1.077.651.717.978 1.733.978 3.045 0 1.35-.363 2.427-1.088 3.229-.725.8-1.691 1.2-2.9 1.2Zm.176-6.537c-1.062 0-1.593.774-1.593 2.324 0 1.466.517 2.198 1.55 2.198 1.04 0 1.559-.763 1.559-2.29 0-.709-.14-1.259-.417-1.648-.279-.39-.645-.584-1.099-.584Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={6.362}
          y1={4.688}
          x2={43.587}
          y2={37.66}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
