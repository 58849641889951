import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function FinancialSavingIncomeIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('FinancialSavingIncomeIcon');

  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        d="M30.5402 25.1127C31.6497 25.1127 32.5491 24.2253 32.5491 23.1306C32.5491 22.0359 31.6497 21.1484 30.5402 21.1484C29.4307 21.1484 28.5312 22.0359 28.5312 23.1306C28.5312 24.2253 29.4307 25.1127 30.5402 25.1127Z"
        fill="#A3A3A3"
      />
      <Path
        d="M19.861 31.2052L31.4878 24.8702L29.7682 21.3008L19.6914 25.9065L19.861 31.2052Z"
        fill="#A3A3A3"
      />
      <Path
        d="M34.959 23.9267C34.959 22.8317 34.0598 21.9445 32.9501 21.9445C32.6768 21.9445 32.4165 21.9992 32.1794 22.0968L32.1786 22.096L32.1698 22.0999C32.1682 22.1007 32.1666 22.1015 32.165 22.1023L22.1018 26.7017L20.8965 25.9088C22.0062 25.9088 22.9054 25.0216 22.9054 23.9267C22.9054 23.1893 22.4924 22.5534 21.8873 22.2117L21.8905 22.199L10.9209 16.7553H10.9009C10.3946 16.5285 9.83693 16.3945 9.2447 16.3945C8.66773 16.3945 8.12211 16.519 7.6255 16.7355H7.60782L1.20898 19.566V29.8731L7.70988 26.9253L20.4955 32.0852H20.4963C20.743 32.1914 21.013 32.2517 21.2983 32.2517C21.6478 32.2517 21.9717 32.1557 22.2585 32.0003L22.2722 31.9995L33.8991 25.6654L33.8983 25.6638C34.5267 25.3292 34.959 24.6815 34.959 23.9267Z"
        fill={`url(#${idPrefix}_paint0_linear_8888_821)`}
      />
      <Path
        d="M20.8968 25.1129C21.5625 25.1129 22.1021 24.5805 22.1021 23.9237C22.1021 23.2668 21.5625 22.7344 20.8968 22.7344C20.2311 22.7344 19.6914 23.2668 19.6914 23.9237C19.6914 24.5805 20.2311 25.1129 20.8968 25.1129Z"
        fill="#2C2C2C"
      />
      <Path
        d="M22.1018 26.7016L20.9053 25.9103C19.8968 25.8627 19.1037 25.5392 12.459 23.125V25.9087L21.741 29.6621C21.741 29.6621 21.0837 27.852 22.1018 26.7016Z"
        fill={`url(#${idPrefix}_paint1_linear_8888_821)`}
      />
      <Path
        d="M24.4062 18.75C28.3413 18.75 31.5312 15.56 31.5312 11.625C31.5312 7.68997 28.3413 4.5 24.4062 4.5C20.4712 4.5 17.2812 7.68997 17.2812 11.625C17.2812 15.56 20.4712 18.75 24.4062 18.75Z"
        fill="#2C2C2C"
      />
      <Path
        d="M24.375 17.25C27.4816 17.25 30 14.7316 30 11.625C30 8.5184 27.4816 6 24.375 6C21.2684 6 18.75 8.5184 18.75 11.625C18.75 14.7316 21.2684 17.25 24.375 17.25Z"
        fill={`url(#${idPrefix}_paint2_linear_8888_821)`}
      />
      <Path
        d="M27 13.0067C27 10.8867 23.7473 11.3007 23.7473 10.0479C23.7473 9.39395 24.511 9.35946 24.7502 9.35946C25.3241 9.35946 25.8786 9.50757 26.301 9.69843C26.4342 9.75842 26.587 9.66955 26.587 9.52819V8.91137C26.587 8.70439 26.5059 8.58178 26.377 8.53341C26.0871 8.42504 25.6713 8.34592 25.0561 8.32642V7.64736C25.0561 7.56599 24.9875 7.5 24.9029 7.5H24.5967C24.5121 7.5 24.4435 7.56599 24.4435 7.64736V8.3373C23.4897 8.42692 22.5 8.94512 22.5 10.151C22.5 12.3487 25.7524 11.913 25.7524 13.0705C25.7524 13.4342 25.5108 13.7481 24.7494 13.7481C23.9842 13.7481 23.2808 13.4908 22.8916 13.2992C22.7607 13.2347 22.6064 13.3281 22.6064 13.4698V14.1721C22.6064 14.3068 22.6905 14.4267 22.8195 14.4792C23.2808 14.6678 23.8992 14.8103 24.4432 14.8523V15.6026C24.4432 15.684 24.5117 15.75 24.5963 15.75H24.9025C24.9871 15.75 25.0557 15.684 25.0557 15.6026V14.8527C26.4841 14.7383 27 13.8186 27 13.0067Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8888_821`}
          x1="1.20898"
          y1="16.3945"
          x2="13.4151"
          y2="42.3739"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint1_linear_8888_821`}
          x1="16.7476"
          y1="26.9934"
          x2="18.1542"
          y2="24.535"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CCCCCC" stopOpacity="0.5" />
          <Stop offset="0.512" stopColor="#A3A3A3" stopOpacity="0.5" />
          <Stop offset="1" stopColor="#A3A3A3" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint2_linear_8888_821`}
          x1="18.75"
          y1="6"
          x2="30"
          y2="17.25"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
