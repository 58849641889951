import { Text, useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { FormSpacer } from '../../../components/form/FormSpacer';
import { Card } from '../../../ui/atoms/Card';
import { Separator } from '../../../ui/atoms/Separator';
import { Tooltip } from '../../../ui/atoms/Tooltip';
import { Skeleton } from '../../../ui/v2/Skeleton';

export const Loading = () => {
  const { theme } = useDripsyTheme();
  return (
    <View>
      <Card>
        <View sx={{ height: 85, backgroundColor: '#D0D1D1' }} />

        <View sx={{ paddingHorizontal: '$24', pb: '$24', pt: '$16' }}>
          <View sx={{ width: '30%', mb: '$4' }}>
            <Skeleton show width="100%" height={theme.space.$20} />
          </View>
          <Skeleton show width="20%" height={theme.space.$10} />

          <Separator mb="m" mt="s" />

          <View sx={{ width: '30%', mb: '$4' }}>
            <Skeleton show width="100%" height={theme.space.$20} />
          </View>
          <Skeleton show width="20%" height={theme.space.$10} />
        </View>
      </Card>

      <FormSpacer py="$16" />

      <Card>
        <View sx={{ px: '$20', pt: '$20', pb: '$32' }}>
          <View sx={{ flexDirection: 'row', mb: '$4', alignItems: 'center' }}>
            <Text variant="xsHeader" sx={{ mr: '$4' }}>
              {t(
                'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityPositionHeader',
              )}
            </Text>
            <Tooltip
              content={t(
                'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityPositionInfo',
              )}
              iconSize="2s"
              iconColor="infoIcon"
              iconOpacity={1}
              testID={TestID.Insights.PropertyValue.EstimatedPropertyValueInfo}
            />
          </View>
          <View sx={{ width: '35%', mb: '$4' }}>
            <Skeleton show width="100%" height={theme.space.$40} />
          </View>
          <View sx={{ width: '100%' }}>
            <Skeleton show width="100%" height={theme.space.$16} />
          </View>
        </View>
      </Card>
    </View>
  );
};
