import {
  Expense_Type_Enum,
  HouseholdForSummaryFragment,
  Living_Situation_Enum,
} from '../../generated/graphql';
import { Box } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { formatCurrency } from '../../utils/currencyHelpers';

export function getExpenseFinancialRowDetailsFooter({
  householdExpense,
  livingSituation,
  numberOfDependents,
  testIDPrefix,
  showDependentsAndFutureLivingSituation = false,
}: {
  householdExpense?: HouseholdForSummaryFragment['unordered_household_expenses'];
  livingSituation?: Living_Situation_Enum;
  numberOfDependents?: number;
  testIDPrefix?: string;
  showDependentsAndFutureLivingSituation?: boolean;
}) {
  return (
    <Box>
      {showDependentsAndFutureLivingSituation ? (
        <>
          <Separator mx="xs" my="s" />
          <Box
            row
            pb="2xs"
            justifyContent="space-between"
            testID={
              testIDPrefix ? `${testIDPrefix}-footerText-dependents` : undefined
            }
          >
            <StyledText variant="caption">
              {t(`Content.ApplicationSummary.Dependents`)}
            </StyledText>
            <StyledText variant="caption">
              {Number(numberOfDependents)}
            </StyledText>
          </Box>
          {livingSituation ? (
            <Box
              row
              pb="2xs"
              justifyContent="space-between"
              testID={
                testIDPrefix
                  ? `${testIDPrefix}-footerText-livingSituation`
                  : undefined
              }
            >
              <StyledText variant="caption">
                {t(`Content.LivingExpenses.FutureLivingSituation`)}
              </StyledText>
              <StyledText variant="caption">
                {t(
                  `Content.ApplicationSummary.LivingSituationValues.${livingSituation}`,
                )}
              </StyledText>
            </Box>
          ) : null}
        </>
      ) : null}
      <Separator mx="xs" my="s" />
      {householdExpense?.map(({ expense_type, id, monthly_amount }) => {
        const isRenting =
          livingSituation === Living_Situation_Enum.LivingWithFamily ||
          livingSituation === Living_Situation_Enum.Renting;
        // If customer is not renting, hide Rent / Board value
        if (!isRenting && expense_type === Expense_Type_Enum.RentOrBoard) {
          return null;
        }
        return (
          <Box
            row
            pb="2xs"
            justifyContent="space-between"
            key={id}
            testID={
              testIDPrefix ? `${testIDPrefix}-footerText-${id}` : undefined
            }
          >
            <StyledText variant="caption">
              {t(`Content.Common.ExpenseType.${expense_type}.title`)}
            </StyledText>
            <StyledText variant="caption">
              {formatCurrency(monthly_amount)}
            </StyledText>
          </Box>
        );
      })}
    </Box>
  );
}
