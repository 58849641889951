import {
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
  MergedLiabilityForSetupLoanScreenFragment,
} from '../generated/graphql';
import { isNotNullOrUndefined } from './arrayHelpers';
import { parseEnumType } from './ensureEnumType';
import { isTopUpHomeLoan } from './isTopUpHomeLoan';

type LiabilityToBeRefinanced = Pick<
  MergedLiabilityForSetupLoanScreenFragment,
  'dynamite_flagged_incorrect'
>;

export const getMergedLiabilitiesAllowedToBeRefinanced = <
  T extends LiabilityToBeRefinanced,
>(
  liabilities?: (T | null | undefined)[],
) =>
  liabilities?.filter(
    (ml): ml is T =>
      isNotNullOrUndefined(ml) && ml.dynamite_flagged_incorrect == null,
  ) || [];

type LiabilityToBeConsolidated = Pick<
  MergedLiabilityForSetupLoanScreenFragment,
  'dynamite_liability_type' | 'dynamite_for_refinancing'
>;

export const getMergedLiabilitiesAllowedToBeConsolidated = <
  T extends LiabilityToBeConsolidated,
>(
  liabilities: T[],
  loanApplicationType?: Loan_Application_Type_Enum,
) =>
  liabilities.filter(
    (liability) =>
      !isTopUpHomeLoan({
        loanApplicationType,
        liabilityType: parseEnumType(
          Liability_Type_Enum,
          liability.dynamite_liability_type,
        ),
        forRefinancing: liability.dynamite_for_refinancing,
      }) && liability.dynamite_liability_type !== Liability_Type_Enum.HomeLoan,
  );
