import { Text } from 'dripsy';
import { useSetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { Identity_Check_Result_Enum } from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { useTheme } from '../../ui/theme';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { streetTypeOptions } from '../../utils/formOptions';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { BorrowerDetailsForm } from '../components/BorrowerDetailsForm';
import { useBorrowerWizardState } from '../components/BorrowerFormContext';
import { currentLoggedInBorrowerDetailsV2Atom } from '../currentLoggedInBorrowerDetailsAtom';
import { useCountryOptionsQuery } from '../hooks/useCountryOptions';
import { useStateOptionsQuery } from '../hooks/useStateOptions';
import { learnMoreReturnScreenAtom } from '../learnMoreReturnScreenAtom';
import { IdentificationV2ScreenProps } from '../navigation/types';
import { BorrowerForm } from '../utils/borrowerFormUtil';

type Props =
  IdentificationV2ScreenProps<Screen.IDENTIFICATION_V2_YOUR_INFORMATION>;

export function BorrowerDetails({ navigation, route }: Props) {
  const { values, setValues } = useBorrowerWizardState();
  const theme = useTheme();
  const {
    applicantId,
    applicantRole,
    loanApplicationId,
    currentBorrowerIsCoBorrower,
    currentBorrowerFirstName,
    primaryBorrowerFullName,
    primaryBorrowerDriverLicenceNumber,
    primaryBorrowerPassportNumber,
  } = route.params || {};

  const kycFail =
    values.identityCheckResult === Identity_Check_Result_Enum.Fail ||
    values.identityCheckResult === Identity_Check_Result_Enum.FailManual;

  const { data: countryOptions, loading: countryOptionsLoading } =
    useCountryOptionsQuery();

  const { data: stateOptions, loading: stateOptionsLoading } =
    useStateOptionsQuery();

  const {
    isLoadingSuggestionData: isLoadingResidentialAddressSuggestionData,
    loadSuggestionData: loadResidentialAddressSuggestionData,
    propertySuggestionData: residentialAddressSuggestionData,
  } = usePropertySuggestionLoader();

  const {
    isLoadingSuggestionData: isLoadingMailingAddressSuggestionData,
    loadSuggestionData: loadMailingAddressSuggestionData,
    propertySuggestionData: mailingAddressSuggestionData,
  } = usePropertySuggestionLoader();

  const onSubmit = async (formValues: BorrowerForm) => {
    const { middleName, otherNames, ...otherFormData } = formValues;
    setValues({
      ...otherFormData,
      ...(applicantId ? { applicantId } : null),
      ...(applicantRole ? { applicantRole } : null),
      loanApplicationId,
      middleName: middleName || '',
      otherNames: otherNames || '',
    });

    navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
      screen: Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE,
      params: {
        primaryBorrowerDriverLicenceNumber,
        primaryBorrowerPassportNumber,
      },
    });
  };

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onCloseModal = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  };

  const { values: borrowerDetails } = useBorrowerWizardState();
  const setCurrentLoggedInBorrowerDetails = useSetRecoilState(
    currentLoggedInBorrowerDetailsV2Atom(loanApplicationId || ''),
  );
  const setLearnMoreReturnScreenAtom = useSetRecoilState(
    learnMoreReturnScreenAtom(loanApplicationId || ''),
  );

  const onLeanMorePress = async () => {
    setCurrentLoggedInBorrowerDetails(borrowerDetails);
    setLearnMoreReturnScreenAtom(Screen.IDENTIFICATION_V2_YOUR_INFORMATION);
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL,
      params: { loanApplicationId },
    });
  };

  const queryLoading = countryOptionsLoading || stateOptionsLoading;

  return (
    <ModalScreenContainer
      headerText={t('Content.Borrowers.Header.YourInformation')}
      scrollable
      hideBackButton
      onClose={onCloseModal}
    >
      {queryLoading ? (
        <Skeleton
          show
          width="100%"
          radius={theme.borderRadii.card}
          height={800}
        />
      ) : (
        <>
          {kycFail ? (
            <ErrorRow mb="l">
              <Text variant="caption" sx={{ flex: 1, color: '$labelsPrimary' }}>
                {t('Content.Common.Error.FailedIdentityCheckV2')}{' '}
                <Text variants={['caption', 'link']} onPress={onLeanMorePress}>
                  {t('Content.Borrowers.Error.ViewNotice')}
                </Text>
              </Text>
            </ErrorRow>
          ) : null}
          <BorrowerDetailsForm
            screen={Screen.IDENTIFICATION_V2_YOUR_INFORMATION}
            onSubmit={onSubmit}
            stateOptions={stateOptions}
            streetTypeOptions={streetTypeOptions}
            citizenshipOptions={countryOptions}
            initialValues={values}
            residentialAddressSuggestionData={residentialAddressSuggestionData}
            loadResidentialAddressSuggestionData={
              loadResidentialAddressSuggestionData
            }
            isLoadingResidentialAddressSuggestionData={
              isLoadingResidentialAddressSuggestionData
            }
            mailingAddressSuggestionData={mailingAddressSuggestionData}
            loadMailingAddressSuggestionData={loadMailingAddressSuggestionData}
            isLoadingMailingAddressSuggestionData={
              isLoadingMailingAddressSuggestionData
            }
            currentBorrowerIsCoBorrower={currentBorrowerIsCoBorrower}
            currentBorrowerFirstName={currentBorrowerFirstName}
            primaryBorrowerFullName={primaryBorrowerFullName}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
