import { Text, View } from 'dripsy';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormRadioInputV2 } from '../../components/form/FormikInputs';
import { Screen } from '../../navigation/types/screens';
import { AccountPermissionType, ApprovalType } from '../types';

type Props = {
  title: string;
  permissionType: AccountPermissionType;
  onChangeValue: () => void;
} & Pick<React.ComponentProps<typeof View>, 'sx'>;

export const PermissionSection = ({
  title,
  permissionType,
  sx,
  onChangeValue,
}: Props) => {
  const { Autopay, Withdrawal } = AccountPermissionType;
  const allToAuthorizeSubTitle: Record<AccountPermissionType, string> = {
    [Autopay]: t('Content.AccountPermissions.AutoPay.AllToAuthorizeSubTitle'),
    [Withdrawal]: t(
      'Content.AccountPermissions.Withdrawal.AllToAuthorizeSubTitle',
    ),
  };
  const oneToAuthorizeSubTitle: Record<AccountPermissionType, string> = {
    [Autopay]: t('Content.AccountPermissions.AutoPay.OneToAuthorizeSubTitle'),
    [Withdrawal]: t(
      'Content.AccountPermissions.Withdrawal.OneToAuthorizeSubTitle',
    ),
  };

  return (
    <View sx={sx}>
      <Text
        testID={
          TestID.AccountPermissions[
            permissionType === AccountPermissionType.Autopay
              ? 'AutoPay'
              : 'Withdrawal'
          ].Title
        }
        variant="sHeader"
        sx={{ py: '$8' }}
      >
        {title}
      </Text>
      <FormRadioInputV2
        name={permissionType}
        value={ApprovalType.AllToApprove}
        label={t('Content.AccountPermissions.AllToAuthorize')}
        subtitle={allToAuthorizeSubTitle[permissionType]}
        containerStyle={{ mt: '$8' }}
        testID={
          TestID.AccountPermissions[
            permissionType === AccountPermissionType.Autopay
              ? 'AutoPay'
              : 'Withdrawal'
          ].AllToAuthorizeOption
        }
        onChangeValue={onChangeValue}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.AccountPermissions,
          Screen.ACCOUNT_PERMISSIONS,
          FieldInteractionKey.AllToAuthorizeOption,
        )}
      />
      <FormRadioInputV2
        name={permissionType}
        value={ApprovalType.OneToApprove}
        label={t('Content.AccountPermissions.OneToAuthorize')}
        subtitle={oneToAuthorizeSubTitle[permissionType]}
        containerStyle={{ mt: '$8' }}
        testID={
          TestID.AccountPermissions[
            permissionType === AccountPermissionType.Autopay
              ? 'AutoPay'
              : 'Withdrawal'
          ].OneToAuthorizeOption
        }
        onChangeValue={onChangeValue}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.AccountPermissions,
          Screen.ACCOUNT_PERMISSIONS,
          FieldInteractionKey.OneToAuthorizeOption,
        )}
      />
    </View>
  );
};
