import { TestID } from '../../../testID/constants';
import { useUpdateFinancialDeclarationMutation } from '../../generated/graphql';
import { NoLoanApplicationFound } from '../../LoanApplication/components/NoLoanApplicationFound';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2>;

export const RepaymentPlanForRetirementActionSheetV2 = ({
  navigation,
  route,
}: Props) => {
  const [
    updateFinancialDeclaration,
    {
      loading: updateFinancialDeclarationLoading,
      error: updateFinancialDeclarationError,
    },
  ] = useUpdateFinancialDeclarationMutation();

  const loading = updateFinancialDeclarationLoading;
  const loanApplicationId = route.params?.loanApplicationId;
  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  if (!loanApplicationId) {
    return <NoLoanApplicationFound />;
  }

  const onConfirm = async () => {
    const [res, _] = await safelyCallMutation(updateFinancialDeclaration, {
      variables: {
        data: {
          retirement_repayment_plan_confirmed_at: new Date().toISOString(),
          loan_application_id: loanApplicationId,
        },
      },
      context: {
        sentryContext: { loanApplicationId },
      },
    });

    if (res) {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.ReviewSummary,
      });
    }
  };

  return (
    <ActionSheet
      title={t('Content.RepaymentPlanForRetirementActionSheet.Title')}
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      footerText={t('Content.RepaymentPlanForRetirementActionSheet.Disclaimer')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Accept'),
          showSpinner: loading,
          disabled: loading,
          onPress: onConfirm,
          testID: TestID.RepaymentPlanForRetirementActionSheet.ConfirmButton,
        },
      ]}
      errorMessage={
        updateFinancialDeclarationError
          ? t('Content.Common.Error.FailUpdateFinancialDeclaration')
          : null
      }
    >
      <StyledText variant="body" textAlign="center" alignSelf="center" py="m">
        {t('Content.RepaymentPlanForRetirementActionSheet.Content')}{' '}
        <Link variant="body" href={t('Link.RepaymentPlanForRetirement')}>
          {t('Content.Common.ButtonLabel.LearnMore')}
        </Link>
      </StyledText>
    </ActionSheet>
  );
};
