import { DEFAULT_UNLOAN_ACCOUNT_NAME } from '../../../../constants/unloan';
import { Cb_LoanState, GetAccountsQuery } from '../../../../generated/graphql';
import { formatBsbAndAccountNumber } from '../../../../utils/formatBsbAndAccountNumber';

export type UnloanAccount = {
  id: string;
  accountName: string;
  accountDetails: string;
};

export const getUnloanAccounts = (data?: GetAccountsQuery): UnloanAccount[] => {
  const loanAccounts = data?.me[0]?.user?.loan_accounts;
  const unloanAccounts =
    loanAccounts?.map((loanAccount) => {
      const propertyDisplayAddress =
        loanAccount.loan_application_target?.loan_application_securities[0]
          ?.property?.address?.short_address_format;
      const accountName =
        propertyDisplayAddress ||
        loanAccount.settings?.name ||
        DEFAULT_UNLOAN_ACCOUNT_NAME;

      const unloanBsb = loanAccount.settings?.bsb ?? '';

      const loanAccountState = loanAccount.state?.account_state;

      const unloanAccountNumber = loanAccount.settings?.account_number ?? '';

      return {
        id: loanAccount.settings?.id ?? '',
        accountName,
        loanAccountState,
        accountDetails: formatBsbAndAccountNumber(
          unloanBsb,
          unloanAccountNumber,
        ),
      };
    }) ?? [];
  const activeUnloanAccounts = unloanAccounts.filter(
    (account) => account.loanAccountState === Cb_LoanState.Active,
  );

  return activeUnloanAccounts.map(({ id, accountDetails, accountName }) => ({
    id,
    accountName,
    accountDetails,
  }));
};
