import { useIsFocused } from '@react-navigation/native';
import { TransitionPresets, useCardAnimation } from '@react-navigation/stack';
import { Animated, Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { FocusOn } from '../../components/FocusOn';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { useTheme } from '../../ui/theme';
import { useIsTablet } from '../../utils/hooks/useBreakpoint';
import {
  MAX_DRAWER_CONTENT_HEIGHT_TABLET,
  MAX_DRAWER_WIDTH,
} from '../../utils/NavConstants';
import {
  ActionListButton,
  Props as ActionListButtonProps,
} from './ActionListButton';
import {
  ActionSheetHeader,
  Props as ActionSheetHeaderProps,
} from './ActionSheetHeader';

export type Props = Omit<ActionSheetHeaderProps, 'onClosePress'> &
  BoxProps & {
    closeModal: () => void;
    onFooterTextLinkPress?: () => void;
    disableBackdropAction?: boolean;
    message?: string | JSX.Element;
    isRowButton?: boolean;
    footerText?: string | JSX.Element;
    /** Only used if footerText is a string */
    footerTextLink?: string;
    actionList?: Array<ActionListButtonProps>;
    errorMessage?: string | null;
  };

export function FullScreenActionSheet({
  closeModal,
  children,
  actionList,
  disableBackdropAction,
  errorMessage,
  onFooterTextLinkPress,
  message,
  footerText,
  footerTextLink,
  caption,
  title,
  headerRightTitle,
  headerLeftTitle,
  onHeaderLeftPress,
  onHeaderRightPress,
  isRowButton,
  ...props
}: Props) {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const isTablet = useIsTablet();
  const cardAnimation = useCardAnimation();
  const Transition =
    TransitionPresets.FadeFromBottomAndroid.cardStyleInterpolator(
      cardAnimation,
    );

  const isCurrentScreenFocused = useIsFocused();
  return (
    <Box
      {...styles.root}
      flex={1}
      alignItems="center"
      style={{
        paddingTop: isTablet ? 0 : insets.top + theme.spacing.xl,
        paddingBottom: isTablet ? 0 : insets.bottom || theme.spacing['3xl'],
      }}
      testID={TestID.ActionSheet.ContentContainer}
    >
      <Animated.View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: 'black',
          opacity: cardAnimation.current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.4],
            extrapolate: 'clamp',
          }),
        }}
      />
      <FocusOn
        onClickOutside={disableBackdropAction ? undefined : closeModal}
        onEscapeKey={closeModal}
        style={{
          maxHeight: isTablet ? MAX_DRAWER_CONTENT_HEIGHT_TABLET : undefined,
          ...styles.focusWrapper,
        }}
        enabled={isCurrentScreenFocused}
      >
        <Animated.View
          style={{
            width: '100%',
            backgroundColor: theme.colors.shapeBg,
            borderRadius: theme.borderRadii.card,
            flexGrow: 1,
            ...Transition.cardStyle,
          }}
        >
          <ActionSheetHeader
            caption={caption}
            title={title}
            onClosePress={closeModal}
            headerRightTitle={headerRightTitle}
            headerLeftTitle={headerLeftTitle}
            onHeaderLeftPress={onHeaderLeftPress}
            onHeaderRightPress={onHeaderRightPress}
          />
          <Box
            width="100%"
            flexGrow={1}
            height={1}
            {...styles.sheetContentContainer}
          >
            <ScrollContainer
              scrollEnabled
              contentContainerStyle={{ flexGrow: 1 }}
            >
              <Box>
                <ErrorRow message={errorMessage} mt="m" />
                {children ? (
                  <Box alignSelf={styles.childrenAlignSelf} {...props}>
                    {children}
                  </Box>
                ) : null}
                {actionList && actionList.length > 0 ? (
                  <Box pt="m" pb="xl" row={isRowButton}>
                    {actionList.map((actionListProps, index) => (
                      <ActionListButton
                        {...actionListProps}
                        key={actionListProps.label}
                        {...(isRowButton
                          ? { ml: index === 0 ? 0 : 's' }
                          : { mt: index === 0 ? 0 : 's' })}
                      />
                    ))}
                  </Box>
                ) : null}
              </Box>
            </ScrollContainer>
          </Box>
        </Animated.View>
      </FocusOn>
    </Box>
  );
}

const styles = {
  root: {
    justifyContent: {
      mobile: 'flex-end',
      tablet: 'center',
    },
    px: {
      mobile: 's',
      tablet: 'm',
    },
  },
  focusWrapper: {
    width: '100%',
    display: 'flex',
    maxWidth: MAX_DRAWER_WIDTH,
    flex: 1,
  },
  sheetContentContainer: {
    px: { mobile: 'm', tablet: 'l' },
    style:
      Platform.OS === 'web'
        ? {
            overflow: 'auto' as const,
          }
        : undefined,
  },
  childrenAlignSelf: { tablet: 'center', mobile: 'stretch' },
} as const;
