import {
  Pressable,
  SxProp,
  Text,
  TextInput as DripsyTextInput,
  ThemeColorName,
  useDripsyTheme,
  View,
} from 'dripsy';
import { FormikContextType } from 'formik';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { TestID } from '../../../testID/constants';
import {
  AppAddressFormat,
  FormikControlProps,
} from '../../components/form/types';
import { MaxCharacter } from '../../constants/fieldRules';
import { useGetSuggestedAddressFromDomainApiLazyQuery } from '../../generated/graphql';
import { isLast, isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { mapDomainAPIAddressToAppAddress } from '../../utils/mapToAppAddress';
import { mergeRefs } from '../../utils/mergeRefs';
import { isWeb } from '../../utils/platformUtils';
import { formatAddress, makeTestId } from '../../utils/stringHelpers';
import { CloseIcon } from '../svgs/CloseIcon';
import { InputRow } from './InputRow';
import { TextInputV2Props } from './TextInput';
import { LISTBOX_ACCESSBILITY_ROLE, OPTION_ACCESSBILITY_ROLE } from './utils';

// Can we do something with these confusing names?
export type PropertySuggestionData = Array<AppAddressFormat>;
type PropertySuggestion = AppAddressFormat;

type PropertyInputValue = PropertySuggestion | null;

export type PropertyInputProps = Expand<
  Omit<TextInputV2Props, 'onChangeText' | 'value'> & {
    value?: PropertyInputValue;
    isLoadingSuggestionData?: boolean;
    propertySuggestionData: PropertySuggestionData;
    suggestionsTestIDPrefix?: string;
    suggestionResultZIndex?: number;
    onChangeSearchText?: (search: string) => void;
    onSuggestionSelect?: (value: PropertyInputValue) => Promise<void>;
    onPressClearButton?: () => void;
    clearable?: boolean;
    // Specific for test and storybook
    searchTextInitialValue?: string;
  }
>;

const focusedDataset = isWeb ? { focused: 'true' } : undefined;
export const PropertyInputBase = forwardRef<
  RNTextInput | null,
  PropertyInputProps
>(
  (
    {
      label,
      propertySuggestionData,
      suggestionsTestIDPrefix,
      inputTestID,
      containerTestID,
      disabled,
      isLoadingSuggestionData,
      // Generally we want this to be higher than other components
      suggestionResultZIndex = 2,
      sx: sxProp,
      onChangeSearchText,
      onSuggestionSelect,
      searchTextInitialValue,
      clearable = false,
      // These need to be controlled by a form controller
      value,
      error,
      focused,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      // These should go to RN text input
      maxLength,
      keyboardType,
    }: PropertyInputProps,
    ref,
  ) => {
    const { theme: themeV2 } = useDripsyTheme();
    const localTextInputRefs = useRef<RNTextInput>(null);

    const [labelId] = useState(() => uuidv4());
    const [searchValue, setSearchValue] = useState(
      (searchTextInitialValue || value?.displayAddress) ?? '',
    );

    const changeSearchText = useCallback(
      (searchText: string) => {
        setSearchValue(searchText);
        onChangeSearchText?.(searchText);
      },
      [onChangeSearchText],
    );

    const onBlur = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onBlurProp?.(e);
      },
      [onBlurProp],
    );

    const onFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (disabled) {
          return;
        }

        onFocusProp?.(e);
      },
      [disabled, onFocusProp],
    );

    const onClearInput = useCallback(() => {
      changeSearchText('');
    }, [changeSearchText]);

    const onResultItemPress = useCallback(
      async (data: PropertyInputValue) => {
        setSearchValue(data?.displayAddress ?? '');
        await onSuggestionSelect?.(data);
        localTextInputRefs.current?.blur();
      },
      [onSuggestionSelect],
    );

    let borderColor: ThemeColorName = '$border';
    let labelColor: ThemeColorName = '$secondary';
    let bgColor: ThemeColorName = '$inputBackground';
    let valueColor: ThemeColorName = '$labelsPrimary';
    if (error) {
      borderColor = '$error';
      labelColor = '$error';
    }
    if (focused) {
      borderColor = '$focus';
    }
    if (disabled) {
      labelColor = '$secondaryDisabled';
      bgColor = '$inputBackgroundDisabled';
      valueColor = '$secondary';
    }

    const smallLabel = focused || searchValue.length > 0;
    const isClearButtonVisible =
      clearable && !disabled && searchValue.length > 0;

    return (
      <InputRow
        sx={{
          bg: bgColor,
          py: '$7',
          borderColor,
          ...(focused
            ? { overflow: 'visible', zIndex: suggestionResultZIndex }
            : null),
          ...sxProp,
        }}
        testID={containerTestID}
        dataSet={focused ? focusedDataset : undefined}
      >
        <Text
          variant={smallLabel ? 'tiny' : 'default'}
          sx={{
            px: '$16',
            color: labelColor,
            position: 'absolute',
            ...(smallLabel ? { top: '$7' } : { zIndex: -1 }),
          }}
          numberOfLines={1}
          nativeID={labelId}
          selectable={false}
        >
          {label}
        </Text>
        <View
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <DripsyTextInput
            sx={{
              variant: 'text.default',
              color: valueColor,
              pt: '$16',
              px: '$16',
              width: '100%',
              flexShrink: 1,
            }}
            value={searchValue}
            editable={!disabled}
            maxLength={maxLength || MaxCharacter.general}
            numberOfLines={1}
            keyboardType={keyboardType}
            onChangeText={changeSearchText}
            onBlur={onBlur}
            onFocus={onFocus}
            underlineColorAndroid="transparent"
            // This is a browser specific fix for Chrome only.
            // see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error `chrome-off` is a web only enum and
            // our RN types override stopped working since Expo 49 upgrades,
            // see `@types/react-native.d.ts` for existing overrides.
            autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
            testID={inputTestID}
            ref={mergeRefs([localTextInputRefs, ref])}
            accessibilityLabelledBy={labelId}
            autoCorrect={false}
          />
          {isClearButtonVisible ? (
            <Pressable
              onPress={onClearInput}
              testID={TestID.Input.ClearButton}
              sx={{ marginHorizontal: themeV2.space.$12 }}
              hitSlop={themeV2.space.$12}
            >
              <CloseIcon size={themeV2.iconSizes.$inputClose} />
            </Pressable>
          ) : null}
        </View>
        <SuggestionsResult
          searchResult={propertySuggestionData}
          isLoading={isLoadingSuggestionData}
          showResults={focused === true && searchValue.length > 0}
          suggestionsTestIDPrefix={suggestionsTestIDPrefix}
          onResultItemPress={onResultItemPress}
          containerSx={(theme) => ({
            px: '$16',
            position: 'absolute',
            width: '100%',
            // Positioned below input
            top: theme.sizes.inputRow.minHeight - theme.borderWidths.$1,
            borderBottomLeftRadius: '$input',
            borderBottomRightRadius: '$input',
            borderWidth: '$1',
            borderColor: '$border',
            justifyContent: 'center',
            minHeight: theme.sizes.inputRow.minHeight,
            backgroundColor: '$inputBackground',
            boxShadow: 'dropShadow',
          })}
        />
      </InputRow>
    );
  },
);
PropertyInputBase.displayName = 'PropertyInputV2';

function SuggestionsResult({
  containerSx,
  searchResult,
  showResults,
  onResultItemPress,
  suggestionsTestIDPrefix,
  isLoading,
}: {
  containerSx?: SxProp;
  searchResult: PropertySuggestionData;
  showResults: boolean;
  suggestionsTestIDPrefix?: string;
  isLoading?: boolean;
  onResultItemPress: (data: PropertySuggestion) => void;
}) {
  const Results = useMemo(() => {
    if (isLoading || searchResult.length === 0) {
      return (
        <View
          sx={{
            backgroundColor: '$inputBackground',
            justifyContent: 'center',
            py: '$14',
          }}
          focusable={false}
        >
          <Text variant="caption">
            {isLoading
              ? t('Content.Common.Placeholder.Loading')
              : t('Content.Common.Placeholder.NoResultsFound')}
          </Text>
        </View>
      );
    }

    const onMouseProp = isWeb
      ? {
          // eslint-disable-next-line
          // @ts-ignore - onMouseDown is forwarded to View, but not exposed in Pressable
          onMouseDown: (event) => {
            event.preventDefault();
          },
        }
      : null;

    return (
      <>
        {searchResult.map((data, index, results) => {
          const [firstLine, secondLine] = formatAddress(data);
          const last = isLast(results, index);

          return (
            <Pressable
              key={data.domainApiId}
              onPress={() => {
                onResultItemPress(data);
              }}
              // This is here to prevent blur event when selecting the item.
              // We'll call blur imperatively after we set the values
              {...onMouseProp}
              focusable={false}
              testID={makeTestId([suggestionsTestIDPrefix, data.domainApiId])}
              role={OPTION_ACCESSBILITY_ROLE}
            >
              <View
                sx={{
                  justifyContent: 'center',
                  ...(last
                    ? undefined
                    : {
                        borderBottomWidth: '$1',
                        borderBottomColor: '$border',
                      }),
                  py: '$10',
                }}
              >
                <Text variant="default">{firstLine}</Text>
                <Text variant="tiny">{secondLine}</Text>
              </View>
            </Pressable>
          );
        })}
      </>
    );
  }, [isLoading, onResultItemPress, searchResult, suggestionsTestIDPrefix]);

  if (!showResults) {
    return null;
  }

  return (
    <View sx={containerSx} role={LISTBOX_ACCESSBILITY_ROLE}>
      {Results}
    </View>
  );
}

export type SuggestionDataLoader = {
  loadSuggestionData?: (suggestionOptions: {
    suggestionCount: number;
    suggestionSearchText: string;
  }) => Promise<void>;
  isLoadingSuggestionData?: boolean;
  propertySuggestionData?: PropertySuggestionData;
};

export type PropertyInputPropsForFormik = Omit<
  PropertyInputProps,
  | 'onChange'
  | 'onChangeText'
  | 'onSuggestionSelect'
  | 'propertySuggestionData'
  | 'setFieldValue'
  | 'setFieldTouched'
  // This is specific for test and storybook
  | 'searchTextInitialValue'
> &
  Omit<
    FormikControlProps<PropertyInputValue>,
    'error' | 'setFieldValue' | 'setFieldTouched'
  > &
  SuggestionDataLoader & {
    maxSuggestions?: number;
    // This formik is injected by `connect` from formik
    formik?: FormikContextType<unknown>;
    error?: string;
    // Internally, Formik return Promise
    // for setFieldValue and setFieldTouched
    // https://github.com/jaredpalmer/formik/issues/2457#issuecomment-1227803618
    setFieldValue?(
      value: PropertyInputValue,
      validate?: boolean,
    ): Promise<void>;
    setFieldTouched?(isTouched?: boolean, validate?: boolean): Promise<void>;
  };

export const PROPERTY_INPUT_LAZY_QUERY_WAIT_AFTER_CHANGE_TEXT_IN_MS = 300;

// TODO: Fix error on focus
// For example on implementing suggestion loader,
// @see src/DebtsWizard/components/LoanDetailsForm.stories.tsx
export const FormikAwarePropertyInput = forwardRef<
  RNTextInput,
  PropertyInputPropsForFormik
>(
  (
    {
      maxSuggestions = 5,
      propertySuggestionData,
      isLoadingSuggestionData,
      clearable,
      loadSuggestionData,

      // From formik
      name,
      formik,
      setFieldValue,
      setFieldTouched,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      value,
      error,
      ...otherProps
    },
    ref,
  ) => {
    const hasChangedSearchText = useRef(false);
    const [_, setIsLoadingSuggestion] = useState(false);

    const debounceSearch = useDebounce(async (userSearch: string) => {
      if (!userSearch || userSearch === value?.displayAddress) {
        setIsLoadingSuggestion(false);
        return;
      }

      await loadSuggestionData?.({
        suggestionCount: maxSuggestions,
        suggestionSearchText: userSearch,
      });

      setIsLoadingSuggestion(false);
    }, PROPERTY_INPUT_LAZY_QUERY_WAIT_AFTER_CHANGE_TEXT_IN_MS);

    const onBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        // Only set field as touched if user has typed in the input before
        // This effectively make the input to validate only after user
        // has done editing the field
        if (hasChangedSearchText.current) {
          setFieldTouched?.(true);
        }
        onBlurProp?.(event);
      },
      [onBlurProp, setFieldTouched],
    );

    const onFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocusProp?.(event);
      },
      [onFocusProp],
    );

    const onChangeSearchText = useCallback(
      async (searchText: string) => {
        // set pseudo touched
        if (searchText && !hasChangedSearchText.current) {
          hasChangedSearchText.current = true;
        }
        // Reset field value on change to make sure user select another option
        if (value) {
          await setFieldValue?.(null);
        }
        setIsLoadingSuggestion(true);
        debounceSearch(searchText);
      },
      [debounceSearch, setFieldValue, value],
    );

    const onSuggestionSelect = useCallback(
      async (data: PropertyInputValue) => {
        await setFieldValue?.(data);
      },
      [setFieldValue],
    );

    return (
      <PropertyInputBase
        clearable={clearable}
        ref={ref}
        onSuggestionSelect={onSuggestionSelect}
        onChangeSearchText={onChangeSearchText}
        onBlur={onBlur}
        onFocus={onFocus}
        isLoadingSuggestionData={isLoadingSuggestionData}
        error={error}
        propertySuggestionData={(propertySuggestionData ?? []).slice(
          0,
          maxSuggestions,
        )}
        value={value}
        {...otherProps}
      />
    );
  },
);

/**
 * Domain API data loader
 * */
export function useDomainAPIPropertySuggestionLoader(): SuggestionDataLoader {
  // TODO(uiv2): handle error when address suggestion fails
  const [getSuggestions, { loading, data, error: _error }] =
    useGetSuggestedAddressFromDomainApiLazyQuery();

  return {
    isLoadingSuggestionData: loading,
    loadSuggestionData: async ({ suggestionCount, suggestionSearchText }) => {
      await getSuggestions({
        variables: {
          search: suggestionSearchText,
          resultLimit: suggestionCount,
        },
      });
    },
    propertySuggestionData: data?.suggested_domain_api_addresses
      ?.filter(isNotNullOrUndefined)
      .map((address) => mapDomainAPIAddressToAppAddress(address)),
  };
}
