import { gql } from '@apollo/client';
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { CollapsibleListRow } from '../../components/CollapsibleListRow';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  GetGrantByIdQuery,
  GrantAccountStatus,
  GrantDetailsFragment,
  GrantDetailsFragmentDoc,
  useGetGrantByIdQuery,
} from '../../generated/graphql';
import {
  AppStackParams,
  SettingsStackParams,
} from '../../navigation/types/navTypes';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast, isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import {
  DataSharingDateFormat,
  DataSharingFooterCaption,
} from './ManageDataSharing';

export const GetGrantById = gql`
  query GetGrantById($id: String!) {
    me {
      user {
        grant(grantId: $id) {
          ...GrantDetails
        }
        cbaCustomer: cba_customer {
          accounts {
            name
            accountNumber: account_number
            bsb
            id
          }
        }
      }
    }
  }

  ${GrantDetailsFragmentDoc}
`;

function DataSharingSummaryRow(props: ComponentProps<typeof ListRow>) {
  return (
    <ListRow
      placeholderColor="primaryContent"
      labelFontWeight="medium"
      {...props}
    />
  );
}

type CbaCustomer = NonNullable<
  GetGrantByIdQuery['me'][number]['user']
>['cbaCustomer'];

export type CbaCustomerAccounts = NonNullable<CbaCustomer>['accounts'];
export type CbaCustomerAccount = NonNullable<CbaCustomerAccounts>[number];

export type AccountForDisplay = {
  id: string;
  accountName: string | null | undefined;
  caption: string | null | undefined;
};

export type GrantAccounts = GrantDetailsFragment['accounts'];

function formatCbaCustomerAccountNumber(account: CbaCustomerAccount) {
  // Right now we dont have a decision on the format to use for displaying
  // full number.
  // After we have a decision on this, the format should be consolidated
  // and consistence accross the app.
  // See Figma for further discussion
  // https://www.figma.com/file/1Y9ixCjYekmJJnQVSxRVN1?node-id=20293:111142#170199619
  return [account.bsb, account.accountNumber].filter(Boolean).join(' ');
}

/**
 * TODO: Make join between OB grant accounts and Unloan loan accounts happen in backend.
 */
export function joinGrantAccountsAndCbaCustomerAccountsForDisplay(
  grantAccounts: GrantAccounts,
  cbaCustomerAccounts: CbaCustomerAccounts,
): Array<AccountForDisplay> {
  const displayAccountById: Record<string, AccountForDisplay> = {};
  cbaCustomerAccounts?.forEach((cbaAccount) => {
    displayAccountById[cbaAccount.id] = {
      id: cbaAccount.id,
      accountName: cbaAccount.name,
      caption: formatCbaCustomerAccountNumber(cbaAccount),
    };
  });

  return (
    grantAccounts
      ?.map((grantAccount) => {
        // Here, we join the accounts from OB API
        // with the accounts from CBA on the CBA customer id
        // for the account name and number.
        const { principalId } = grantAccount;
        const accountForDisplay = principalId
          ? displayAccountById[principalId]
          : null;
        return accountForDisplay;
      })
      .filter(isNotNullOrUndefined) || []
  );
}

function AccountsSharedGroup({
  cbaCustomer,
  grant,
}: {
  cbaCustomer: CbaCustomer;
  grant: GrantDetailsFragment;
}) {
  const grantAccounts = grant.accounts;
  const accountsForDisplay = useMemo(
    () =>
      joinGrantAccountsAndCbaCustomerAccountsForDisplay(
        grantAccounts,
        cbaCustomer?.accounts,
      ),
    [cbaCustomer?.accounts, grantAccounts],
  );

  if (accountsForDisplay.length === 0) {
    return null;
  }

  return (
    <>
      <ListRowGroup headerText={t('Content.DataSharingDetails.AccountsShared')}>
        {accountsForDisplay.map((account, i) => (
          <DataSharingSummaryRow
            key={account.id}
            label={account.accountName || '--'}
            caption={account.caption || '--'}
            last={isLast(accountsForDisplay, i)}
          />
        ))}
      </ListRowGroup>
      <Separator spacer />
    </>
  );
}

function mapGrantToContentRow(grant: GrantDetailsFragment) {
  if (!grant.status) {
    return '--';
  }

  switch (grant.status) {
    case GrantAccountStatus.Active:
      return t('Content.DataSharingDetails.Active');
    case GrantAccountStatus.Initialise:
      return t('Content.DataSharingDetails.Pending');
    case GrantAccountStatus.Revoked:
      return t('Content.DataSharingDetails.Withdrawn');
    case GrantAccountStatus.Expired:
      return t('Content.DataSharingDetails.Expired');
    default:
      return '--';
  }
}

function mapGrantCxScopeToCollapsibleContent(
  cxScope:
    | NonNullable<GrantDetailsFragment['cxScopes']>[number]
    | null
    | undefined,
) {
  if (!cxScope || !cxScope.permissionLanguage?.length) {
    return '--';
  }

  const { permissionLanguage } = cxScope;

  return permissionLanguage.map((permission) => `• ${permission}`).join(`\n`);
}

function DataSharingDetailsBase() {
  const navigation = useNavigation<NavigationProp<AppStackParams>>();
  const settingsNavigation =
    useNavigation<NavigationProp<SettingsStackParams>>();
  const route =
    useRoute<
      RouteProp<SettingsStackParams, Screen.SETTINGS_DATA_SHARING_DETAILS>
    >();

  const [openCollapsibleRowIndex, setOpenCollapsibleRowIndex] = useState<
    number | null
  >(null);

  const { grantId, brandName } = route.params;
  const { data, loading, error } = useGetGrantByIdQuery({
    variables: {
      id: grantId || '',
    },
    skip: !grantId,
    context: {
      sentryContext: {
        grantId,
      },
    },
  });

  const myUser = data?.me[0]?.user;
  const grant = myUser?.grant;

  useEffect(() => {
    if (brandName) {
      navigation.setOptions({ title: brandName });
    }
  }, [navigation, brandName]);

  const onStopDataSharingPress = () => {
    navigation.navigate(ActionSheetType.STOP_DATA_SHARE_CONFIRMATION, {
      grantId,
      brandName,
    });
  };

  let content;

  if (grant) {
    const showStopSharingButton =
      grant.status === GrantAccountStatus.Active ||
      grant.status === GrantAccountStatus.Initialise;

    const stopSharingButtonEl = showStopSharingButton ? (
      <>
        <Separator spacer />
        <ListRowGroup overflow="visible">
          <ListRow
            label={t('Content.DataSharingDetails.StopDataSharing')}
            onPress={onStopDataSharingPress}
            isButton
            color="error"
            last
            testID={TestID.DataSharingDetails.StopDataSharingButton}
          />
        </ListRowGroup>
      </>
    ) : null;

    content = (
      <>
        <ListRowGroup
          headerText={t('Content.DataSharingDetails.DataSharingSummary')}
        >
          <DataSharingSummaryRow
            label={t('Content.DataSharingDetails.Consent')}
            placeholder={mapGrantToContentRow(grant)}
          />
          <DataSharingSummaryRow
            label={t('Content.DataSharingDetails.From')}
            placeholder={
              safelyFormatDate(grant?.created, DataSharingDateFormat) || '--'
            }
          />
          <DataSharingSummaryRow
            label={t('Content.DataSharingDetails.To')}
            placeholder={
              safelyFormatDate(grant?.expiry, DataSharingDateFormat) || '--'
            }
            last
          />
        </ListRowGroup>
        <ListRowGroup headerText="">
          <ListRow
            key="consent-history"
            label={t('Content.DataSharingDetails.ConsentHistory')}
            labelFontWeight="medium"
            onPress={() =>
              settingsNavigation.navigate(
                Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY,
                { grantId: grantId || '' },
              )
            }
            last
          />
        </ListRowGroup>
        <Separator spacer />
        <AccountsSharedGroup grant={grant} cbaCustomer={myUser.cbaCustomer} />
        <ListRowGroup
          headerText={t('Content.DataSharingDetails.DataShared')}
          overflow="visible"
        >
          {grant.cxScopes?.map((cxScope, i) => (
            <CollapsibleListRow
              key={cxScope.id}
              label={cxScope.description || '--'}
              isCollapsed={i !== openCollapsibleRowIndex}
              onPress={() => {
                setOpenCollapsibleRowIndex((openIndex) =>
                  openIndex === i ? null : i,
                );
              }}
              collapsedContent={mapGrantCxScopeToCollapsibleContent(cxScope)}
              px="m"
              last={isLast(grant.cxScopes || [], i)}
            />
          ))}
        </ListRowGroup>
        {stopSharingButtonEl}
        <Separator spacer />
        <DataSharingFooterCaption mx="xl" />
        <Separator spacer />
      </>
    );
  }

  if (error || !grant) {
    content = <ErrorRow message="Data sharing not found." />;
  }

  if (loading) {
    content = <Spinner />;
  }

  return (
    <ScreenContainer bg="bgSecondary" scrollable>
      <NavHeaderSpacer />
      {content}
    </ScreenContainer>
  );
}

export const WithAuthenticationDataSharingDetails = withAuthenticationRequired(
  DataSharingDetailsBase,
);
