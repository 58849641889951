import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function LiabilityPersonalLoanIcon({
  color,
  ...otherProps
}: SvgIconProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        d="M1 18.195V20c0 1.547 3.695 2.75 8.25 2.75S17.5 21.547 17.5 20v-1.805C15.695 19.441 12.473 20 9.25 20c-3.266 0-6.488-.559-8.25-1.805zM14.75 6.25C19.305 6.25 23 5.047 23 3.5 23 1.996 19.305.75 14.75.75S6.5 1.996 6.5 3.5c0 1.547 3.695 2.75 8.25 2.75zM1 13.684v2.191c0 1.547 3.695 2.75 8.25 2.75s8.25-1.203 8.25-2.75v-2.191c-1.805 1.46-5.027 2.191-8.25 2.191-3.266 0-6.488-.73-8.25-2.191zm17.875.472C21.325 13.684 23 12.781 23 11.75V9.945c-1.031.688-2.492 1.16-4.125 1.461v2.75zM9.25 7.625C4.695 7.625 1 9.172 1 11.063 1 12.995 4.695 14.5 9.25 14.5s8.25-1.504 8.25-3.438c0-1.89-3.695-3.437-8.25-3.437zm9.41 2.45C21.238 9.601 23 8.698 23 7.624V5.82c-1.547 1.075-4.168 1.633-6.918 1.805 1.246.602 2.191 1.418 2.578 2.45z"
        fill={color}
      />
    </Svg>
  );
}
