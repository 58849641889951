import { Direct_Debit_Failure_Type_Enum } from '../generated/graphql';

/**
 * The Direct Debit modal shows instructions on how to make sure the customer
 * has enough funds in their redraw to make the next repayment.
 *
 * In case of a Technical Failure during a Direct Debit, the MPO job will
 * retry the Direct Debit in 24 hours. In this case the user has nothing to
 * action. Hence we do not show the modal with more details, only the notification
 * would persist.
 *
 * Once the retries reaches the max attempts (3), in that case the Direct Debit is
 * skipped. In this case we show the modal with more details since user has to make
 * sure they have enough funds in their redraw to make the repayment.
 */
export const showDirectDebitModal = ({
  failureType,
  willRetry,
}: {
  failureType: Direct_Debit_Failure_Type_Enum;
  willRetry: boolean;
}) =>
  !(
    failureType === Direct_Debit_Failure_Type_Enum.TechnicalFailure && willRetry
  );
