import { useEffect } from 'react';
import {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { usePrevious } from '../../utils/hooks/usePrevious';

const DEFAULT_DURATION = 300;

const INITIAL_TRANSITION_Y = 0;
const HOVER_TRANSITION_Y = -4;

export function useCardTranslateYAnimatedStyle(isHover: boolean) {
  const translateY = useSharedValue(INITIAL_TRANSITION_Y);

  const previousValue = usePrevious(isHover);

  useEffect(() => {
    if (previousValue !== isHover) {
      translateY.value = withTiming(
        isHover ? HOVER_TRANSITION_Y : INITIAL_TRANSITION_Y,
        {
          duration: DEFAULT_DURATION,
        },
      );
    }
  }, [isHover, previousValue, translateY]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
  }));

  return animatedStyle;
}
