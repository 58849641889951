import { Text, View } from 'dripsy';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import Animated, {
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { G, Line } from 'react-native-svg';

import { safelyFormatDate } from '../../../utils/dateHelpers';
import { GRADIENT } from '../../constants';
import {
  INSIGHT_SVG_GRAPH_HEIGHT,
  INSIGHT_SVG_GRAPH_WIDTH,
  InsightGraphContainer,
} from '../InsightGraphContainer';
import { SvgGradientDefs } from '../SvgGradientDefs';
import { INTEREST_SAVING_GRAPH_SVG_PADDING } from './const';
import { SavedOnInterestTooltip } from './SavedOnInterestTooltip';
import { SingleBar } from './SingleBar';
import { TooltipInteractiveArea } from './TooltipInteractiveArea';

type Props = {
  values: number[];
  labels?: string[];
  totalInterestSavingsUntilNow: number;
  enableBarHoverOn?: boolean;
  displayTooltip?: boolean;
  scale?: number;
  ratio?: number;
  onBarBeHovered?: (index: number) => void;
} & React.ComponentProps<typeof InsightGraphContainer>;

export const getLabelLeftPositionByIndex = (
  labelIndex: number,
  labelTextWidthInPixel: number,
  svgPaddingInPixel: number,
  barWidthInPixel: number,
  barGapInPixel: number,
) =>
  svgPaddingInPixel +
  barWidthInPixel * 0.5 +
  labelIndex * (barWidthInPixel + barGapInPixel) -
  labelTextWidthInPixel * 0.5;

export const getSvgMetaData = (
  barValues: number[],
  graphWidthInPx: number,
  barWidthGapRatio: number,
  scale: number,
) => {
  const barWidthInSvg =
    (INSIGHT_SVG_GRAPH_WIDTH * barWidthGapRatio) /
    ((barWidthGapRatio + 1) * barValues.length - 1);
  const barGapInSvg =
    (INSIGHT_SVG_GRAPH_WIDTH - barValues.length * barWidthInSvg) /
    (barValues.length - 1);
  const svgAreaWidthInPixel =
    (graphWidthInPx *
      (INSIGHT_SVG_GRAPH_WIDTH - 2 * INTEREST_SAVING_GRAPH_SVG_PADDING)) /
    INSIGHT_SVG_GRAPH_WIDTH;
  const barWidthInPixel =
    (svgAreaWidthInPixel * 2) / (barValues.length * (barWidthGapRatio + 1) - 1);
  const barGapInPixel = barWidthInPixel / barWidthGapRatio;
  const svgPaddingInPixel =
    (graphWidthInPx * INTEREST_SAVING_GRAPH_SVG_PADDING) /
    INSIGHT_SVG_GRAPH_WIDTH;
  const maxBarValue = Math.max(...barValues) * scale;

  return {
    barWidthInSvg,
    barGapInSvg,
    svgAreaWidthInPixel,
    barWidthInPixel,
    barGapInPixel,
    svgPaddingInPixel,
    maxBarValue,
  };
};

export const SavedOnInterestBarChartGraph = ({
  values,
  labels,
  totalInterestSavingsUntilNow,
  enableBarHoverOn = true,
  scale = 1.05,
  displayTooltip = true,
  numOfDotsLines = 5,
  ratio = 2,
  onBarBeHovered,
}: Props) => {
  const [graphWidthInPx, setGraphWidthInPx] = useState(0);
  const [tooltipContainerWidthInPixel, setTooltipContainerWidthInPixel] =
    useState(0);
  const [labelTextWidthInPx, setLabelTextWidthInPx] = useState(0);
  const [activeBarIndex, setActiveBarIndex] = useState(-1);

  const svgMetaData = useMemo(
    () => getSvgMetaData(values, graphWidthInPx, ratio, scale),
    [graphWidthInPx, ratio, scale, values],
  );

  const AnimatedLine = Animated.createAnimatedComponent(Line);

  const sharedActiveBarIndex = useSharedValue(activeBarIndex);
  const animatedProps = useAnimatedProps(() => {
    const xPos =
      sharedActiveBarIndex.value * svgMetaData.barWidthInSvg +
      sharedActiveBarIndex.value * svgMetaData.barGapInSvg +
      svgMetaData.barWidthInSvg / 2;
    return {
      x1: xPos,
      x2: xPos,
    };
  });
  const animatedTooltipStyle = useAnimatedStyle(() => {
    const translateX = Math.min(
      Math.max(
        0,
        (graphWidthInPx *
          (sharedActiveBarIndex.value *
            (svgMetaData.barWidthInSvg + svgMetaData.barGapInSvg))) /
          INSIGHT_SVG_GRAPH_WIDTH -
          tooltipContainerWidthInPixel * 0.5 +
          (svgMetaData.barWidthInSvg * 0.5 * graphWidthInPx) /
            INSIGHT_SVG_GRAPH_WIDTH,
      ),
      graphWidthInPx - tooltipContainerWidthInPixel,
    );

    return {
      transform: [
        {
          translateX,
        },
      ],
    };
  });

  const getBarAnimationDurationInMs = useCallback(
    (barValue: number) =>
      ((barValue * INSIGHT_SVG_GRAPH_HEIGHT) / svgMetaData.maxBarValue) * 12,
    [svgMetaData.maxBarValue],
  );

  const singleBarValues = useMemo(() => {
    let accumulatedDelayTimeInMs = 500;
    return values.map((value, index) => {
      if (index > 0) {
        const preBarAnimationPeriodInMs = getBarAnimationDurationInMs(
          values[index - 1] || 0,
        );
        accumulatedDelayTimeInMs += preBarAnimationPeriodInMs * 0.8;
      }
      return {
        value,
        animateDelay: accumulatedDelayTimeInMs,
        animationDuration: getBarAnimationDurationInMs(value),
      };
    });
  }, [getBarAnimationDurationInMs, values]);

  useEffect(() => {
    sharedActiveBarIndex.value = withTiming(activeBarIndex, {
      duration: 400,
    });
  }, [activeBarIndex, sharedActiveBarIndex]);

  useEffect(() => {
    if (onBarBeHovered) {
      onBarBeHovered(activeBarIndex);
    }
  }, [activeBarIndex, onBarBeHovered]);

  return (
    <>
      {displayTooltip ? (
        <Animated.View style={animatedTooltipStyle}>
          <SavedOnInterestTooltip
            onLayout={(event) => {
              setTooltipContainerWidthInPixel(event.nativeEvent.layout.width);
            }}
            date={labels?.[activeBarIndex] || ''}
            savedInterestAmount={
              isNil(values?.[activeBarIndex])
                ? totalInterestSavingsUntilNow || 0
                : values?.[activeBarIndex] || 0
            }
            displayBoarder={activeBarIndex !== -1}
          />
        </Animated.View>
      ) : null}
      <View>
        <TooltipInteractiveArea
          enableBarHoverOn={enableBarHoverOn}
          barGap={svgMetaData.barGapInSvg}
          barWidth={svgMetaData.barWidthInSvg}
          graphWidthInPx={graphWidthInPx}
          setActiveBarIndex={setActiveBarIndex}
        />
        <InsightGraphContainer
          showBackgroundDotsLine
          numOfDotsLines={numOfDotsLines}
          onLayout={(event) => {
            setGraphWidthInPx(event.nativeEvent.layout.width);
          }}
          viewBox="0 -2 100 52"
        >
          <G>
            <G>
              <SvgGradientDefs
                direction="vertical"
                gradient={GRADIENT}
                id="gradientBar"
                reverse
              />
              <G>
                {activeBarIndex !== -1 && (
                  <AnimatedLine
                    y1={-20}
                    y2={INSIGHT_SVG_GRAPH_HEIGHT}
                    strokeWidth={0.5}
                    opacity={0.15}
                    stroke="#000"
                    strokeLinecap="round"
                    animatedProps={animatedProps}
                    transform=""
                  />
                )}
                {singleBarValues.map(
                  ({ animateDelay, animationDuration, value }, index) => (
                    <SingleBar
                      disabled={!totalInterestSavingsUntilNow}
                      animateDelay={animateDelay}
                      animationDuration={animationDuration}
                      key={`${
                        index * svgMetaData.barWidthInSvg +
                        index * svgMetaData.barGapInSvg
                      }`}
                      value={value || 0}
                      barAreaHeight={INSIGHT_SVG_GRAPH_HEIGHT}
                      barWidth={svgMetaData.barWidthInSvg}
                      xPos={
                        index * svgMetaData.barWidthInSvg +
                        index * svgMetaData.barGapInSvg
                      }
                      maxBarValue={svgMetaData.maxBarValue}
                    />
                  ),
                )}
              </G>
            </G>
          </G>
        </InsightGraphContainer>
      </View>
      {labels && labels.length === values.length ? (
        <View
          sx={(theme) => ({
            position: 'relative',
            mt: '$16',
            height: theme.space.$20,
          })}
        >
          {labels.map((label, index) => (
            <Text
              variant="label"
              key={label}
              onLayout={(event) => {
                if (index === 0) {
                  setLabelTextWidthInPx(event.nativeEvent.layout.width);
                }
              }}
              style={{
                position: 'absolute',
                left: getLabelLeftPositionByIndex(
                  index,
                  labelTextWidthInPx,
                  svgMetaData.svgPaddingInPixel,
                  svgMetaData.barWidthInPixel,
                  svgMetaData.barGapInPixel,
                ),
              }}
            >
              {safelyFormatDate(label, 'MMM')?.toUpperCase()}
            </Text>
          ))}
        </View>
      ) : null}
    </>
  );
};
