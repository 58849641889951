import { ResponsiveValue } from '@shopify/restyle';
import { ComponentProps } from 'react';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { ArrowForward } from '../atoms/ArrowForward';
import { Avatar } from '../atoms/Avatar';
import { Box, BoxProps } from '../atoms/Box';
import { Button } from '../atoms/Button';
import { RowRight } from '../atoms/RowRight';
import { Spinner } from '../atoms/Spinner';
import { IconFamilyName, StyledIcon } from '../atoms/StyledIcon';
import { Theme, useTheme } from '../theme';
import { Color, Size } from '../types';
import { createBox } from '../utils/createBox';
import { useListRowGroupInset } from '../utils/ListRowGroupInsetContext';
import { PressableIcon } from './PressableIcon';
import { Props as RowProps, Row } from './Row';

export type BaseListRowProps = RowProps & {
  arrowForwardColor?: Color;
  iconName?: string;
  iconFamilyName?: IconFamilyName;
  iconBackgroundColor?: Color;
  underlineRow?: boolean;
  underlineRowMargin?: boolean;
  rightIconName?: string;
  rightIconFamilyName?: IconFamilyName;
  imageUrl?: string;
  borderedImage?: boolean;
  isButton?: boolean;
  iconRoundBg?: boolean;
  useArrow?: boolean;
  color?: Color;
  iconColor?: Color;
  rightIconColor?: Color;
  iconSize?: Size;
  disabled?: boolean;
  iconOpacity?: ResponsiveValue<number, Theme>;
  rightIconOpacity?: ResponsiveValue<number, Theme>;
  left?: React.ReactNode;
  onPress?: () => void;
  roundedTop?: boolean;
  roundedBottom?: boolean;
  rowRightProps?: ComponentProps<typeof RowRight>;
  onRightIconPress?: ComponentProps<typeof PressableIcon>['onPress'];
  onRightButtonPress?: ComponentProps<typeof Button>['onPress'];
  rightButtonSecondary?: boolean;
  rightButtonLabel?: string;
  rightButtonTestID?: string;
  rightButtonDisabled?: boolean;
  loading?: boolean;
  leftAlignItems?: BoxProps['alignItems'];
};

export type Props = Omit<BoxProps, keyof BaseListRowProps> & BaseListRowProps;

const StyledTouchableHighlight = createBox(TouchableOpacity);
const rightIconHitSlop = {
  top: 8,
  left: 8,
  right: 8,
  bottom: 8,
};

const defaultColor: Props['color'] = 'primaryContent';

export const ListRow = React.memo<Props>(
  ({
    arrowForwardColor = defaultColor,
    label,
    caption,
    subCaption,
    bg,
    color = 'link',
    placeholder,
    placeholderColor = defaultColor,
    placeholderCaption,
    placeholderTestId,
    placeholderNumberOfLines,
    iconName,
    iconOpacity,
    rightIconOpacity,
    iconSize = 'm',
    iconRoundBg,
    iconBackgroundColor,
    underlineRow,
    underlineRowMargin,
    isButton,
    rightIconName,
    imageUrl,
    height,
    useArrow,
    left,
    onPress,
    disabled: disabledProp,
    last,
    roundedBottom,
    roundedTop,
    centered,
    children,
    labelFontSize,
    labelFontWeight,
    iconFamilyName,
    alignItems = 'stretch',
    captionVariant,
    captionColor = defaultColor,
    captionLink,
    onCaptionLinkPress,
    captionLinkTestId,
    captionLinkAlignItems,
    captionLinkInlineFlex,
    inset: propsInset,
    borderedImage,
    loading,
    rightIconFamilyName,
    labelNumberOfLines,
    labelVariant,
    placeholderVariant,
    labelTestId,
    captionTestId,
    labelWrapperProps,
    rowRightProps,
    onRightIconPress,
    onRightButtonPress,
    rightButtonSecondary,
    rightButtonLabel,
    rightButtonTestID,
    rightButtonDisabled,
    labelColor: baseLabelColor = defaultColor,
    labelMl,
    leftAlignItems = 'center',
    ...props
  }) => {
    const disabled = loading || disabledProp;
    const labelColor = isButton && !disabled ? color : baseLabelColor;

    let iconColor = props.iconColor || color;
    let rightIconColor = props.rightIconColor || iconColor;

    const theme = useTheme();
    const listRowGroupInset = useListRowGroupInset();
    const inset = (propsInset ?? listRowGroupInset) && !underlineRowMargin;

    if (disabled) {
      iconColor = 'disabledContent';
      rightIconColor = 'disabledContent';
    }

    const hasArrow =
      useArrow == null ? onPress && !disabled && !isButton : useArrow;

    const rightContent = (
      <>
        {loading ? <Spinner /> : children}
        {rightIconName || onRightButtonPress ? (
          <RowRight {...rowRightProps}>
            {rightIconName && onRightIconPress ? (
              <PressableIcon
                iconName={rightIconName}
                iconColor={rightIconColor}
                iconSize={iconSize}
                iconRoundBg={iconRoundBg}
                iconFamily={rightIconFamilyName}
                iconOpacity={rightIconOpacity}
                iconFixedHeight
                onPress={onRightIconPress}
                hitSlop={rightIconHitSlop}
              />
            ) : null}
            {onRightButtonPress ? (
              <Button
                testID={rightButtonTestID}
                variant="pill"
                label={rightButtonLabel}
                onPress={onRightButtonPress}
                secondary={rightButtonSecondary}
                disabled={disabled || rightButtonDisabled}
              />
            ) : null}
            {rightIconName && !onRightIconPress && !onRightButtonPress ? (
              <StyledIcon
                name={rightIconName}
                color={rightIconColor}
                size={iconSize}
                roundBg={iconRoundBg}
                family={rightIconFamilyName}
                opacity={rightIconOpacity}
                fixedHeight
              />
            ) : null}
          </RowRight>
        ) : null}

        {hasArrow && !disabled ? (
          <RowRight ml={rightIconName ? 's' : 'm'}>
            <ArrowForward color={arrowForwardColor} aria-hidden />
          </RowRight>
        ) : null}
      </>
    );

    const content = (
      <Row
        labelNumberOfLines={labelNumberOfLines}
        height={height}
        disabled={disabled}
        label={label}
        labelMl={labelMl}
        labelTestId={labelTestId}
        labelFontSize={labelFontSize}
        labelFontWeight={labelFontWeight}
        labelColor={labelColor}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        placeholderCaption={placeholderCaption}
        placeholderTestId={placeholderTestId}
        placeholderNumberOfLines={placeholderNumberOfLines}
        caption={caption}
        captionLink={captionLink}
        onCaptionLinkPress={onCaptionLinkPress}
        captionLinkTestId={captionLinkTestId}
        captionLinkInlineFlex={captionLinkInlineFlex}
        captionLinkAlignItems={captionLinkAlignItems}
        captionTestId={captionTestId}
        subCaption={subCaption}
        last={last}
        centered={centered}
        left={
          imageUrl || iconName || left ? (
            <Box row ml={inset ? 'm' : 0} alignItems={leftAlignItems} pb="s">
              {imageUrl ? (
                <Box
                  borderRadius="field"
                  p="s"
                  bg="shapeBg"
                  bordered={borderedImage}
                >
                  <Avatar url={imageUrl} size="s" square bg="transparent" />
                </Box>
              ) : null}
              {iconName ? (
                <StyledIcon
                  name={iconName}
                  bg={iconBackgroundColor}
                  color={iconColor}
                  size={iconSize}
                  family={iconFamilyName}
                  roundBg={iconRoundBg}
                  fixedHeight
                  fixedWidth
                  opacity={iconOpacity}
                />
              ) : null}
              {left}
            </Box>
          ) : null
        }
        bg={bg}
        alignItems={alignItems}
        captionVariant={captionVariant}
        captionColor={captionColor}
        inset={inset}
        labelVariant={labelVariant}
        placeholderVariant={placeholderVariant}
        labelWrapperProps={labelWrapperProps}
        {...(underlineRow && {
          borderBottomWidth: theme.constants.borderWidth,
        })}
        {...(underlineRowMargin && {
          mx: 'm',
        })}
        sublabels={props.sublabels}
        rightContent={rightContent}
      />
    );

    const wrapperProps = {
      overflow: 'hidden' as const,
      ...(roundedTop && {
        borderTopLeftRadius: 'card' as const,
        borderTopRightRadius: 'card' as const,
      }),
      ...(roundedBottom && {
        borderBottomLeftRadius: 'card' as const,
        borderBottomRightRadius: 'card' as const,
      }),
      ...props,
    };

    if (!onPress) {
      return <Box {...wrapperProps}>{content}</Box>;
    }

    return (
      <StyledTouchableHighlight
        onPress={onPress}
        disabled={disabled}
        role="button"
        {...wrapperProps}
      >
        {/* Direct child of StyledTouchableHighlight must be a native component */}
        <View>{content}</View>
      </StyledTouchableHighlight>
    );
  },
);
