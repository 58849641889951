import { captureException } from '../sentry';
import { isKnownEnumValue, StandardEnum } from './typesHelpers';

/**
 * @deprecated Use parseEnumType instead
 */
export function ensureEnumType<ActionEnum, DBEnum extends StandardEnum>(
  dbEnum: DBEnum,
  input: ActionEnum | null | undefined,
): DBEnum[keyof DBEnum] {
  if (input == null) {
    throw new Error('Input is null or undefined');
  }
  const enumDbValues = Object.values(dbEnum);
  const castedEnum = input as unknown as DBEnum[keyof DBEnum];
  if (!enumDbValues.includes(castedEnum)) {
    throw new Error(
      `Invalid enum types. Expected values were ${enumDbValues.join(
        ', ',
      )}. But received ${input} instead`,
    );
  }
  return castedEnum;
}

export function parseEnumType<ActionEnum, DBEnum extends StandardEnum>(
  dbEnum: DBEnum,
  input: ActionEnum | null | undefined,
  description?: string,
): DBEnum[keyof DBEnum] | null {
  if (input == null) {
    return null;
  }
  const enumDbValues = Object.values(dbEnum);
  if (!isKnownEnumValue(dbEnum, input)) {
    captureException(
      `Invalid enum types found while parsing. Expected values were ${enumDbValues.join(
        ', ',
      )}. But received ${input} instead. Description:  ${description}`,
      {
        input,
        enumDbValues,
      },
    );
    return null;
  }
  return input;
}
