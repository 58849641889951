import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  Credit_Check_State_Enum,
  CreditCheckForCompletenessCheckFragment,
  Liability_Type_Enum,
  useCreditCheckStatesByLoanApplicationIdSubscription,
} from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';

export const CREDIT_CHECK_STATES_BY_LOAN_APPLICATION_ID_SUBSCRIPTION = gql`
  subscription CreditCheckStatesByLoanApplicationId($loanApplicationId: uuid!) {
    applicant(where: { loan_application_id: { _eq: $loanApplicationId } }) {
      id
      is_current_logged_in_applicant
      user_identity_profile {
        id
        credit_checks(
          where: { loan_application_id: { _eq: $loanApplicationId } }
        ) {
          ...CreditCheckForCompletenessCheck
        }
      }
      loan_application {
        merged_liabilities(
          where: { loan_application_id: { _eq: $loanApplicationId } }
        ) {
          id
          current_liability_id
          ccr_applicant_ids
          dynamite_liability_type
        }
      }
    }
  }

  fragment CreditCheckForCompletenessCheck on credit_check {
    id
    state
  }
`;

function isCreditCheckCompleted(
  creditCheck: CreditCheckForCompletenessCheckFragment,
) {
  return creditCheck.state === Credit_Check_State_Enum.Completed;
}

export function useCompletedCreditChecksByLoanIdQuery({
  loanApplicationId,
  skip,
  currentApplicantId,
}: {
  loanApplicationId: string | undefined;
  skip: boolean;
  currentApplicantId: string | undefined;
}) {
  const skipSubscription = skip || !loanApplicationId;

  const { data, loading, error } =
    useCreditCheckStatesByLoanApplicationIdSubscription({
      variables: { loanApplicationId: loanApplicationId || '' },
      skip: skipSubscription,
    });

  const { isAllCreditCheckCompleted, isMergedLiabilitiesPopulated } =
    useMemo(() => {
      const checks = (
        data?.applicant.find((x) => x.is_current_logged_in_applicant)
          ?.user_identity_profile?.credit_checks || []
      ).filter(isNotNullOrUndefined);

      const mergedLiabilities = data?.applicant.find(
        (x) => x.is_current_logged_in_applicant,
      )?.loan_application?.merged_liabilities;

      const filterByApplicantId = mergedLiabilities?.filter((liability) =>
        liability.ccr_applicant_ids?.includes(currentApplicantId || ''),
      );
      // Filter out novated lease type liabilities as these can be added before debts screen
      const filterByNovatedLease = filterByApplicantId?.filter(
        (liability) =>
          liability.dynamite_liability_type !==
          Liability_Type_Enum.NovatedLease,
      );

      return {
        creditChecks: checks,
        // If the applicants doesnt have any credit check, treat it as finished
        // and to manual flow.
        isAllCreditCheckCompleted: checks.every(isCreditCheckCompleted),
        isMergedLiabilitiesPopulated: filterByNovatedLease
          ? filterByNovatedLease.length > 0
          : false,
      };
    }, [currentApplicantId, data?.applicant]);

  return {
    error,
    loading,
    isAllCreditCheckCompleted,
    isMergedLiabilitiesPopulated,
  };
}
