import { SxProp, Text, View } from 'dripsy';

import { getInitials } from './utils/getInitials';

type InitialsAvatarProps = {
  name: string;
  sx?: SxProp;
};

export const InitialsAvatar = ({ name, sx = {} }: InitialsAvatarProps) => {
  const initials = getInitials(name);

  return (
    <View
      sx={{
        borderRadius: 18,
        width: 36,
        height: 36,
        backgroundColor: '$initialsAvatarBackground',
        ...sx,
      }}
    >
      <View
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 6,
        }}
      >
        <Text variant="emphasis" style={{ color: 'black' }}>
          {initials}
        </Text>
      </View>
    </View>
  );
};
