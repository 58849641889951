import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { HeaderButton } from '../../ui/atoms/HeaderButton';
import { StyledText } from '../../ui/atoms/StyledText';
import { MODAL_HEADER_HEIGHT } from '../../utils/NavConstants';

export type Props = {
  onClosePress?: React.ComponentProps<typeof Button>['onPress'];
  caption?: string;
  title?: string;
  /* When headerRightTitle is not set, we will show close button */
  headerRightTitle?: string;
  onHeaderRightPress?: () => void;
  showCloseButton?: boolean;
  disableCloseButton?: boolean;
  headerLeftTitle?: string;
  onHeaderLeftPress?: () => void;
};

export const ActionSheetHeader: React.FC<Props> = ({
  caption,
  title,
  onClosePress,
  headerRightTitle,
  showCloseButton = true,
  disableCloseButton,
  headerLeftTitle,
  onHeaderLeftPress,
  onHeaderRightPress,
}) => (
  <Box row height={MODAL_HEADER_HEIGHT} mx="m" centered>
    <Box position="absolute" left={0}>
      {headerLeftTitle ? (
        <HeaderButton
          position="left"
          text={headerLeftTitle}
          onPress={onHeaderLeftPress}
        />
      ) : null}
    </Box>
    <Box>
      <StyledText role="heading" fontWeight="semiBold" textAlign="center">
        {title}
      </StyledText>
      {caption ? (
        <StyledText variant="caption" textAlign="center">
          {caption}
        </StyledText>
      ) : null}
    </Box>
    {showCloseButton ? (
      <Box position="absolute" right={0}>
        {headerRightTitle ? (
          <HeaderButton
            position="right"
            text={headerRightTitle}
            onPress={onHeaderRightPress}
          />
        ) : (
          <Button
            variant="circle"
            icon="close"
            bg="bg"
            p="s"
            color="secondaryContent"
            onPress={onClosePress}
            disabled={disableCloseButton}
            testID={TestID.ActionSheet.CloseButton}
            width={30}
            height={30}
            aria-label={t('Content.Accessibility.Modal.CloseModalButton')}
          />
        )}
      </Box>
    ) : null}
  </Box>
);
