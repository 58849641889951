import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ColorProps, SpacingProps } from '../types';
import { StyledIcon } from './StyledIcon';

type Props = SpacingProps & {
  style?: StyleProp<ViewStyle>;
  color?: ColorProps['color'];
};

export const ArrowForward: React.FC<Props> = ({ color, style, ...props }) => (
  <StyledIcon
    name="chevronRight"
    family="svg"
    color={color ?? 'border'}
    size="s"
    style={style}
    fixedHeight
    {...props}
  />
);
