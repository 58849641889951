import { TestID } from '../../../testID/constants';
import {
  Props as ScreenContainerProps,
  ScreenContainer,
} from '../../ui/atoms/ScreenContainer';
import { isWeb } from '../../utils/platformUtils';

type Props = ScreenContainerProps;

export function LoanApplicationScreenContainer(props: Props) {
  const scrollable = !isWeb;
  return (
    <ScreenContainer
      testID={TestID.LoanApplicationScreenContainer.ScreenContainer}
      scrollable={scrollable}
      bg="bg"
      safeAreaTop={false}
      safeAreaBottom={false}
      flex={undefined} // make the container not full screen, so no gapping on top of the button
      {...props}
    />
  );
}
