import { ChartSlice } from './chartConfig';

export const getLoanChartData = (
  principalBalance: number,
  availableRedrawBalance: number,
): Array<{ x: ChartSlice; y: number }> => {
  let principalPercentage = 0;
  if (principalBalance > 0) {
    principalPercentage =
      principalBalance / (principalBalance + availableRedrawBalance);
  }
  return [
    { x: ChartSlice.REDRAW_PERCENTAGE, y: 1 - principalPercentage },
    { x: ChartSlice.PRINCIPAL_PERCENTAGE, y: principalPercentage },
  ];
};
