import {
  StackNavigationOptions,
  TransitionPresets,
} from '@react-navigation/stack';
import {
  StackHeaderMode,
  StackHeaderOptions,
} from '@react-navigation/stack/lib/typescript/src/types';
import {
  StackCardInterpolatedStyle,
  StackCardInterpolationProps,
} from '@react-navigation/stack/src/types';
import { useMemo } from 'react';
import { Animated, Platform, StyleProp, ViewStyle } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { useMediaQuery } from 'react-responsive';

import { BackButton } from '../components/BackButton';
import { NavHeaderBackground } from '../components/NavHeaderBackground';
import { Box } from '../ui/atoms/Box';
import {
  borderRadii,
  breakpoints,
  fontWeights,
  spacing,
  tabletConstants,
} from '../ui/theme';
import { useIsTablet } from './hooks/useBreakpoint';

const { add, multiply } = Animated;

export const MODAL_HEADER_HEIGHT = 56;
export const MAX_MOBILE_WIDTH = tabletConstants.maxContentWidth;
export const MAX_MOBILE_HEIGHT = 828;
export const MAX_DRAWER_WIDTH = 400;
export const MAX_DRAWER_CONTENT_HEIGHT_MOBILE = 400;
export const MAX_DRAWER_CONTENT_HEIGHT_TABLET = 700;
export const HEADER_MODE: StackHeaderMode = Platform.select({
  ios: 'float',
  default: 'screen',
});

export const DefaultNavOptions: StackHeaderOptions = {
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
  headerBackImage: BackButton,
};

export const TranslucentNavOptions: StackHeaderOptions = {
  headerTransparent: true,
  headerBackground: () => <NavHeaderBackground />,
};

export const TransparentHeaderNavOptions: StackHeaderOptions = {
  headerTransparent: true,
  headerBackground: () => <Box bg="transparent" />,
};

export const NoBorderNavOptions = {
  headerStyle: {
    borderBottomWidth: 0,
    shadowOpacity: 0,
    elevation: 0,
  },
};

export const TranslucentHeaderModalNavOptions: StackNavigationOptions = {
  ...DefaultNavOptions,
  ...TranslucentNavOptions,
  ...NoBorderNavOptions,
  headerStatusBarHeight: 0,
  headerTitleStyle: {
    fontFamily: fontWeights.semiBold,
  },
  headerStyle: {
    height: MODAL_HEADER_HEIGHT,
    ...NoBorderNavOptions.headerStyle,
  },
};

export const TranslucentModalNavOptions: StackNavigationOptions = {
  ...TranslucentHeaderModalNavOptions,
  headerMode: 'float',
  ...TransitionPresets.SlideFromRightIOS,
};

export const TabletModalCardStyle: StyleProp<ViewStyle> = {
  width: '100%',
  height: '100%',
  alignSelf: 'center',
  justifyContent: 'center',
};

export const TabletCardStyle: StyleProp<ViewStyle> = {
  margin: spacing.xl,
  width: '100%',
  maxWidth: MAX_MOBILE_WIDTH,
  minHeight: MAX_MOBILE_WIDTH,
  maxHeight: MAX_MOBILE_HEIGHT,
  alignSelf: 'center',
  borderRadius: borderRadii.card,
  backgroundColor: 'transparent',
};

export function forModalPresentation({
  index,
  current,
  next,
  inverted,
  layouts: { screen },
  insets,
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
  const isTablet = screen.width > breakpoints.tablet;
  const topOffset = isTablet && index === 0 ? 0 : 10;
  const statusBarHeight = insets.top;
  const aspectRatio = screen.height / screen.width;
  const progress = add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0,
  );

  const borderRadius =
    // eslint-disable-next-line no-nested-ternary
    isTablet
      ? 0
      : index === 0
      ? progress.interpolate({
          inputRange: [0, 1, 1.0001, 2],
          outputRange: [0, 0, isIphoneX() ? 38 : 0, 10],
        })
      : borderRadii.card;

  const translateY = multiply(
    progress.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [
        screen.height,
        index === 0 ? 0 : topOffset,
        (index === 0 ? statusBarHeight : 0) - topOffset * aspectRatio,
      ],
    }),
    inverted,
  );

  if (index <= 1 && isTablet && !next) {
    return {
      cardStyle: {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        opacity: progress.interpolate({
          inputRange: [0, 0.5, 0.9, 1],
          outputRange: [0, 0.25, 0.7, 1],
        }),
        transform: [{ translateY }],
        justifyContent: 'center',
      },
      overlayStyle: {
        opacity: progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 0.5],
          extrapolate: 'clamp',
        }),
      },
    };
  }

  const overlayOpacity = progress.interpolate({
    inputRange: [0, 1, 2],
    outputRange: [0, 0.5, 0.3],
    extrapolate: 'clamp',
  });

  const scale =
    isTablet && index === 0
      ? 1
      : progress.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [
            1,
            1,
            screen.width ? 1 - (topOffset * 2) / screen.width : 1,
          ],
        });

  return {
    cardStyle: {
      overflow: 'hidden',
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      marginTop: index < 1 ? 0 : statusBarHeight,
      transform: [{ translateY }, { scale }],
      justifyContent: 'center',
    },
    overlayStyle: { opacity: overlayOpacity },
  };
}

export const ModalPreset = {
  headerShown: false,
  gestureEnabled: true,
  animationEnabled: true,
  cardOverlayEnabled: true,
  ...Platform.select({
    ios: TransitionPresets.ModalPresentationIOS,
    default: TransitionPresets.DefaultTransition,
  }),
  cardStyleInterpolator: forModalPresentation,
  headerLeft: BackButton,
};

export const ActionSheetPreset: StackNavigationOptions = {
  presentation: 'transparentModal',
  cardStyle: { backgroundColor: 'transparent' },
  animationEnabled: true,
  cardOverlayEnabled: true,
  detachPreviousScreen: false,
};

export const SlideIOSPreset = Platform.select({
  android: {},
  default: {
    gestureEnabled: true,
    cardOverlayEnabled: true,
    animationEnabled: true,
    ...TransitionPresets.SlideFromRightIOS,
  },
});

export function useModalScreenOptions() {
  const isTablet = useIsTablet();

  const modalScreenOptions = useMemo(
    () => ({
      ...ModalPreset,
      presentation: 'modal' as const,
      cardStyle: [
        { backgroundColor: 'transparent' },
        isTablet ? TabletCardStyle : {},
      ],
      detachPreviousScreen: false,
    }),
    [isTablet],
  );
  return modalScreenOptions;
}

export const MODAL_MAX_WIDTH = 800;
const MARGIN_MODAL_TABLET = 60;
const MAX_MODAL_HEIGHT = 960;
const LARGE_SCREEN_HEIGHT_BREAKPOINT = 1080;
export const TabletCardStyleV2: StyleProp<ViewStyle> = {
  marginVertical: MARGIN_MODAL_TABLET,
  width: '100%',
  maxWidth: MODAL_MAX_WIDTH,
  minHeight: MAX_MOBILE_WIDTH,
  alignSelf: 'center',
  borderRadius: borderRadii.card,
  backgroundColor: 'transparent',
};

export const DefaultModalV2NavigatorOptions = {
  ...TranslucentModalNavOptions,
  ...TransparentHeaderNavOptions,
  headerTitle: () => null,
};

export function useModalScreenOptionsV2(fullScreen = false) {
  const isTablet = useIsTablet();

  const shouldSetModalMaxHeight = useMediaQuery({
    minHeight: LARGE_SCREEN_HEIGHT_BREAKPOINT,
  });

  const modalScreenOptions = useMemo(
    () => ({
      ...ModalPreset,
      presentation: 'modal' as const,
      cardStyle: [
        {
          backgroundColor: 'transparent',
          maxHeight: shouldSetModalMaxHeight ? MAX_MODAL_HEIGHT : undefined,
        },
        isTablet ? TabletCardStyleV2 : {},
      ],
      detachPreviousScreen: false,
      cardOverlayEnabled: !fullScreen,
    }),
    [fullScreen, isTablet, shouldSetModalMaxHeight],
  );
  return modalScreenOptions;
}
