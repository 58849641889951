import { RefCallback } from 'react';
import ReactSlider from 'react-slider';

import { useTheme } from '../theme';
import { Box } from './Box';
import { Card } from './Card';
import { SliderProps } from './types';

function Thumb() {
  const theme = useTheme();
  return (
    <Card
      shadowed
      activeOpacity={0.8}
      bg="shapeBg"
      height={theme.constants.markerSize}
      width={theme.constants.markerSize}
      borderRadius="rounded"
    />
  );
}
function SliderThumb(props: { ref: RefCallback<HTMLDivElement> }) {
  return (
    <div {...props}>
      <Thumb />
    </div>
  );
}

type TrackProps = {
  index: number;
};

function Track({ index }: TrackProps) {
  const theme = useTheme();
  return (
    <Box
      bg={index === 0 ? 'buttonPrimaryBg' : 'border'}
      height={2}
      borderRadius="rounded"
      // Center aligned the track
      top={theme.constants.markerSize / 2}
    />
  );
}

function SliderTrack(
  props: { ref: RefCallback<HTMLDivElement> },
  { index }: { index: number; value: number },
) {
  return (
    <div {...props}>
      <Track index={index} />
    </div>
  );
}

export function Slider({ value, onValueChange, ...otherProps }: SliderProps) {
  const theme = useTheme();
  return (
    <Box minHeight={theme.constants.markerSize} overflow="visible">
      <ReactSlider
        renderTrack={SliderTrack}
        renderThumb={SliderThumb}
        value={value ?? 0}
        onChange={onValueChange}
        {...otherProps}
      />
    </Box>
  );
}
