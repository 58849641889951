import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props = ActionSheetScreenProps<ActionSheetType.CREDIT_CHECK_CONFIRMATION>;

export function CreditCheckConfirmationActionSheet({ navigation }: Props) {
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    navigation.goBack();
  };
  return (
    <ActionSheet
      title={t('Content.CreditCheckConfirmationActionSheet.SheetTitle')}
      closeModal={onCloseModal}
      preventCloseModal={loading}
      actionList={[
        {
          label: loading
            ? t(
                'Content.CreditCheckConfirmationActionSheet.ConfirmButtonLoadingLabel',
              )
            : t(
                'Content.CreditCheckConfirmationActionSheet.ConfirmButtonLabel',
              ),
          secondary: false,
          showSpinner: loading,
          disabled: loading,
          onPress: () => {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              navigation.goBack();
            }, 500);
          },
          testID: TestID.CreditCheckConfirmationActionSheet.ConfirmButton,
        },
      ]}
      message={t('Content.CreditCheckConfirmationActionSheet.SheetMessage')}
    />
  );
}
