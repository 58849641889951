import { SxProp, Text, Theme } from 'dripsy';

export type TextProps = {
  children: string;
  sx?: SxProp;
  variant?: keyof Theme['text'];
  variants?: Array<keyof Theme['text']>;
};

export const CompoundText = ({ children, sx, variant }: TextProps) =>
  children
    .split(' ')
    // eslint-disable-next-line react/jsx-key
    .map((word: string) => <Text sx={sx} variant={variant}>{`${word} `}</Text>);
