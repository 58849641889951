import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import {
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchYourFinancialsQuery,
  useRemoveIncomeMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import { DeleteIncomeActionSheetErrorAtom } from '../recoil/ActionSheetErrors';

type Props = ActionSheetScreenProps<ActionSheetType.DELETE_INCOME_ACTION_SHEET>;

export function DeleteIncomeActionSheet({ navigation, route }: Props) {
  const { incomeId, loanApplicationId } = route.params || {};
  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const setDeleteIncomeError = useSetRecoilState(
    DeleteIncomeActionSheetErrorAtom,
  );

  const [removeIncome, { loading }] = useRemoveIncomeMutation();

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onDeleteIncomePress = async () => {
    const [res, error] = await safelyCallMutation(removeIncome, {
      variables: {
        incomeId,
      },
      refetchQueries: [
        refetchYourFinancialsQuery({
          loanApplicationId: loanApplicationId ?? '',
        }),
        refetchReviewLoanApplicationQuery({
          loanApplicationId: loanApplicationId ?? '',
        }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId: loanApplicationId ?? '',
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          incomeId,
        },
      },
    });

    if (res == null || error) {
      setDeleteIncomeError(t('Content.Common.Error.FailRemoveIncome'));
      navigation.goBack();
      return;
    }

    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      title={t('Content.YourIncome.Form.DeleteIncomeActionSheet.Title')}
      message={t('Content.YourIncome.Form.DeleteIncomeActionSheet.Message')}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.DeleteIncomeActionSheet.Cancel,
          disabled: loading,
          secondary: true,
          onPress: () => navigation.goBack(),
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Delete'),
          testID: TestID.DeleteIncomeActionSheet.Delete,
          disabled: loading,
          showSpinner: loading,
          secondary: true,
          isDestructive: true,
          onPress: onDeleteIncomePress,
          flex: 1,
        },
      ]}
    />
  );
}
