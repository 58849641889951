import { Text } from 'dripsy';
import React from 'react';

import { Spinner } from '../ui/atoms/Spinner';
import { ErrorRow } from './ErrorRow';

type Props = {
  onRefreshPress?: React.ComponentProps<typeof Text>['onPress'];
  refreshDisabled?: React.ComponentProps<typeof Text>['disabled'];
  sx?: React.ComponentProps<typeof ErrorRow>['sx'];
  refreshLoading?: boolean;
  testID?: string;
};

export function RefreshPageErrorRow({
  onRefreshPress,
  refreshDisabled,
  refreshLoading,
  sx,
  testID,
}: Props) {
  return (
    <ErrorRow sx={sx} testID={testID}>
      <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
        {t('Content.RefreshPageErrorRow.SomethingWentWrongPart1')}
        <Text
          variants={[
            'caption',
            'link',
            ...(refreshDisabled ? (['disabled'] as const) : []),
          ]}
          onPress={refreshDisabled ? undefined : onRefreshPress}
          disabled={refreshDisabled}
        >
          {t('Content.RefreshPageErrorRow.SomethingWentWrongPart2')}
          {refreshLoading ? (
            <Spinner size={8} style={{ marginLeft: 4 }} />
          ) : null}
        </Text>
        {t('Content.RefreshPageErrorRow.SomethingWentWrongPart3')}
      </Text>
    </ErrorRow>
  );
}
