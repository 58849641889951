import { pluralize } from '../../utils/stringHelpers';

export const formatFinancialBankListItemCaption = (
  name: string,
  numberOfAccounts?: number,
  isLoading?: boolean,
): string =>
  `${name}${getFormattedTotalBankAccounts(numberOfAccounts, isLoading)}`;

const getFormattedTotalBankAccounts = (
  numberOfAccounts?: number,
  isLoading?: boolean,
) => {
  const prefix = ' • ';
  if (isLoading) {
    return `${prefix}Connecting...`;
  }
  return numberOfAccounts
    ? `${prefix}${pluralize(numberOfAccounts, 'account')}`
    : '';
};
