import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';
import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormPropertyInputV2,
  FormRadioInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Property_Purpose_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import { ManualPropertyInputFields } from '../../ui/v2/ManualPropertyInputFields';
import { makeTestId } from '../../utils/stringHelpers';
import {
  DEFAULT_PROPERTY_DETAILS_PURCHASE_INITIAL_VALUES,
  getPropertyDetailsPurchaseFormSchema,
  PropertyDetailsPurchaseFormFields,
  PropertyDetailsPurchaseFormValue,
  propertyDetailsPurchaseManualAddressPrefix,
  propertyDetailsPurchaseManualInputFieldNames,
  PropertyYesNoEnum,
} from '../utils/propertyDetailsPurchaseFormUtils';
import { PropertyDetailsFormProps } from './PropertyDetailsForm';

export type PropertyDetailsPurchaseFormProps = Omit<
  PropertyDetailsFormProps,
  'initialValues' | 'showDeleteButton' | 'onSubmit' | 'onDelete'
> & {
  screen: Screen;
  initialValues?: PropertyDetailsPurchaseFormValue;
  onSubmit: (values: PropertyDetailsPurchaseFormValue) => Promise<void>;
  frequencyOptions: PickerOptions<string>;
  onRentalExpenseLearnMoreClick?: () => void;
};

export function PropertyDetailsPurchaseForm({
  screen,
  initialValues = DEFAULT_PROPERTY_DETAILS_PURCHASE_INITIAL_VALUES,
  propertyTypeOptions = [],
  propertyPurposeOptions = [],
  stateOptions = [],
  streetTypeOptions = [],
  isSubmitting,
  onSubmit,

  frequencyOptions = [],
  onRentalExpenseLearnMoreClick,

  // For property address input
  loadSuggestionData,
  isLoadingSuggestionData,
  propertySuggestionData,
}: PropertyDetailsPurchaseFormProps) {
  // Using state here to avoid caption text from changing when initial value
  // got updated that's caused by upsert function returning updated values.
  const [haveInitialExpectedRentalIncome] = useState(
    !!(
      initialValues[PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses] ||
      initialValues[PropertyDetailsPurchaseFormFields.ExpectedRentalIncome]
    ),
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={() => getPropertyDetailsPurchaseFormSchema()}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <SHeaderText>
            {t('Content.PropertyDetails.Form.WhatsYourPropertyAddress')}
          </SHeaderText>
          {formProps.values?.[
            propertyDetailsPurchaseManualInputFieldNames.isManualInput
          ] ? (
            <>
              <ManualPropertyInputFields
                screen={screen}
                sectionInteractionKey={SectionInteractionKey.Property}
                fieldNamePrefix={propertyDetailsPurchaseManualAddressPrefix}
                containerSx={{ mt: '$16' }}
                stateOptions={stateOptions}
                streetTypeOptions={streetTypeOptions}
              />
              <Text
                variants={['caption', 'link']}
                sx={{ mt: '$8' }}
                onPress={() =>
                  formProps.setFieldValue(
                    propertyDetailsPurchaseManualInputFieldNames.isManualInput,
                    false,
                    false,
                  )
                }
              >
                {t('Content.PropertyForm.FormV2.BackToSelectInput')}
              </Text>
            </>
          ) : (
            <>
              <FormPropertyInputV2
                name={PropertyDetailsPurchaseFormFields.AddressResult}
                label={t('Content.PropertyDetails.Form.PropertyAddress')}
                inputTestID={
                  TestID.PropertyDetailsPurchase.PropertyAddressPicker
                }
                propertySuggestionData={propertySuggestionData}
                loadSuggestionData={loadSuggestionData}
                isLoadingSuggestionData={isLoadingSuggestionData}
                sx={{ mt: '$16' }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Property,
                  screen,
                  FieldInteractionKey.PropertyAddress,
                )}
              />
              <FormikFormError
                name={PropertyDetailsPurchaseFormFields.AddressResult}
                sx={{ mt: '$8' }}
                errorMessageParser={() =>
                  t('Content.PropertyDetails.Form.PropertyAddressError')
                }
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                {t('Content.PropertyForm.FormV2.GoToManualInput')}
                <Text
                  variants={['caption', 'link']}
                  onPress={() =>
                    formProps.setFieldValue(
                      propertyDetailsPurchaseManualInputFieldNames.isManualInput,
                      true,
                      false,
                    )
                  }
                >
                  {t('Content.PropertyForm.FormV2.GoToManualInputLink')}
                </Text>
              </Text>
            </>
          )}
          <FormSelectV2
            name={PropertyDetailsPurchaseFormFields.PropertyType}
            label={t('Content.PropertyDetails.Form.PropertyType')}
            testID={TestID.PropertyDetailsPurchase.PropertyType}
            items={propertyTypeOptions}
            sx={{ mt: '$24' }}
          />
          <FormikFormError
            name={PropertyDetailsPurchaseFormFields.PropertyType}
            sx={{ mt: '$8' }}
          />
          <FormCurrencyInputV2
            name={PropertyDetailsPurchaseFormFields.PropertyValue}
            label={t('Content.PropertyDetails.PurchaseForm.PurchasePrice')}
            inputTestID={TestID.PropertyDetailsPurchase.PropertyValueInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Property,
              screen,
              FieldInteractionKey.PurchasePrice,
            )}
          />
          <FormikFormError
            name={PropertyDetailsPurchaseFormFields.PropertyValue}
            sx={{ mt: '$8' }}
          />
          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.PropertyDetails.PurchaseForm.PurchasePriceCaption')}
          </Text>

          <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
            {t(
              'Content.PropertyDetails.PurchaseForm.AreYouGoingToLiveInThisProperty',
            )}
          </SHeaderText>
          {propertyPurposeOptions.map(({ label, value }, index) => (
            <FormRadioInputV2
              key={value}
              name={PropertyDetailsPurchaseFormFields.PropertyPurpose}
              value={value}
              testID={makeTestId([
                TestID.PropertyDetailsPurchase.PropertyPurpose,
                value,
              ])}
              label={label}
              containerStyle={{
                flex: 1,
                ...(index !== 0 ? { mt: '$8' } : undefined),
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Property,
                screen,
                FieldInteractionKey.PropertyPurpose,
              )}
            />
          ))}
          <FormikFormError
            name={PropertyDetailsPurchaseFormFields.PropertyPurpose}
            sx={{ mt: '$8' }}
          />
          {formProps.values?.[
            PropertyDetailsPurchaseFormFields.PropertyPurpose
          ] === Property_Purpose_Enum.LivingIn ? (
            <>
              <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
                {t(
                  'Content.PropertyDetails.PurchaseForm.AreFirstTimeHomeBuyers',
                )}
              </SHeaderText>
              <RowView sx={{ my: '$8' }}>
                <FormRadioInputV2
                  name={PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer}
                  value={PropertyYesNoEnum.Yes}
                  testID={makeTestId([
                    TestID.PropertyDetailsPurchase.FirstTimeHomeBuyers,
                    PropertyYesNoEnum.Yes,
                  ])}
                  label={t('Content.Common.ButtonLabel.Yes')}
                  containerStyle={{
                    mr: '$16',
                    flex: 1,
                  }}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Property,
                    screen,
                    FieldInteractionKey.FirstTimeHomeBuyer,
                  )}
                />
                <FormRadioInputV2
                  name={PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer}
                  value={PropertyYesNoEnum.No}
                  testID={makeTestId([
                    TestID.PropertyDetailsPurchase.FirstTimeHomeBuyers,
                    PropertyYesNoEnum.No,
                  ])}
                  label={t('Content.Common.ButtonLabel.No')}
                  containerStyle={{
                    flex: 1,
                  }}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Property,
                    screen,
                    FieldInteractionKey.FirstTimeHomeBuyer,
                  )}
                />
              </RowView>
              <FormikFormError
                name={PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer}
                sx={{ mb: '$8' }}
              />
            </>
          ) : null}
          {formProps.values?.[
            PropertyDetailsPurchaseFormFields.PropertyPurpose
          ] === Property_Purpose_Enum.Investing ? (
            <>
              <SHeaderText sx={{ mt: '$32' }}>
                {haveInitialExpectedRentalIncome
                  ? t(
                      'Content.PropertyDetails.PurchaseForm.ReviewExpectedRentalIncome',
                    )
                  : t(
                      'Content.PropertyDetails.PurchaseForm.EnterExpectedRentalIncome',
                    )}
              </SHeaderText>
              <RowView sx={{ mt: '$16' }}>
                <FormCurrencyInputV2
                  name={PropertyDetailsPurchaseFormFields.ExpectedRentalIncome}
                  sx={{ mr: '$16', flex: 1 }}
                  label={t('Content.YourIncome.Form.RentalIncome')}
                  inputTestID={
                    TestID.ExpectedRentalIncomeForm.RentalIncomeValueInput
                  }
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Property,
                    screen,
                    FieldInteractionKey.RentalIncome,
                  )}
                />
                <FormSelectV2
                  name={
                    PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency
                  }
                  label={t('Content.YourIncome.Form.IncomeFrequency')}
                  testID={TestID.ExpectedRentalIncomeForm.RentalIncomeFrequency}
                  items={frequencyOptions}
                  sx={{ flex: 1 }}
                />
              </RowView>
              <FormikFormError
                name={PropertyDetailsPurchaseFormFields.ExpectedRentalIncome}
                sx={{ mt: '$8' }}
              />
              <FormikFormError
                name={
                  PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency
                }
                sx={{ mt: '$8' }}
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                {t('Content.YourIncome.Form.ExpectedRentalIncomeCaption')}
              </Text>

              <RowView sx={{ mt: '$16' }}>
                <FormCurrencyInputV2
                  name={
                    PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses
                  }
                  sx={{ mr: '$16', flex: 1 }}
                  label={t('Content.YourIncome.Form.RentalExpenses')}
                  inputTestID={
                    TestID.ExpectedRentalIncomeForm.RentalExpensesValueInput
                  }
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Property,
                    screen,
                    FieldInteractionKey.RentalExpenses,
                  )}
                />
                <FormSelectV2
                  name={
                    PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency
                  }
                  label={t('Content.YourIncome.Form.IncomeFrequency')}
                  testID={
                    TestID.ExpectedRentalIncomeForm.RentalExpensesFrequency
                  }
                  items={frequencyOptions}
                  sx={{ flex: 1 }}
                />
              </RowView>
              <FormikFormError
                name={PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses}
                sx={{ mt: '$8' }}
              />
              <FormikFormError
                name={
                  PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency
                }
                sx={{ mt: '$8' }}
              />
              <Text
                sx={{ mt: '$8' }}
                variants={['caption', 'link']}
                onPress={() => onRentalExpenseLearnMoreClick?.()}
              >
                {t('Content.YourIncome.Form.RentalExpensesHelp')}
              </Text>
            </>
          ) : null}

          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            testID={TestID.PropertyDetailsPurchase.SubmitButton}
            onPress={() => {
              formProps.handleSubmit();
            }}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            py="m"
            mt="xl"
            alignSelf="stretch"
          />
        </View>
      )}
    </Formik>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
