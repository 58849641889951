import {
  yup,
  yupExternalAccountBsb,
  yupExternalAccountName,
  yupExternalAccountNumber,
} from '../../../utils/yup';

export enum AccountField {
  AccountName = 'account_name',
  AccountBsb = 'account_bsb',
  AccountNumber = 'account_number',
}

export const accountValidationSchema = yup.object({
  [AccountField.AccountBsb]: yupExternalAccountBsb,
  [AccountField.AccountName]: yupExternalAccountName,
  [AccountField.AccountNumber]: yupExternalAccountNumber,
});

export type AddExternalAccountFormValues = yup.Asserts<
  typeof accountValidationSchema
>;

export enum ProcessAccountStatus {
  Success = 'success',
  Fail = 'fail',
}
