import * as React from 'react';

import { ArrowForward } from '../../ui/atoms/ArrowForward';
import { Box } from '../../ui/atoms/Box';
import { RowRight } from '../../ui/atoms/RowRight';
import { Props as RowProps, Row } from '../../ui/molecules/Row';
import { InputRow, Props as InputRowProps } from '../../ui/organisms/InputRow';
import { Color } from '../../ui/types';

export type Props = Omit<InputRowProps, 'ref' | 'noBorder'> &
  Omit<RowProps, 'label' | 'ref'> & {
    value?: string;
    hasArrow?: boolean;
    ignoreValueForPlaceholderColor?: boolean;
  };

export const PickerTrigger: React.FC<Props> = ({
  value,
  label: labelProp,
  placeholder: placeholderProp = 'Select',
  labelColor: labelColorProp = 'primaryContent',
  placeholderColor: placeholderColorProp = 'link',
  ignoreValueForPlaceholderColor,
  onPress,
  last,
  hasArrow,
  disabled,
  placeholderContainerMaxWidth,
  labelTestId,
  testID,
  placeholderTestId,
  ...otherProps
}: Props) => {
  const label = labelProp || value || placeholderProp || '';
  const labelColor = labelProp || value ? labelColorProp : 'disabledContent';
  const placeholder = labelProp ? value || placeholderProp : '';
  const placeholderColor: Color =
    !!value || ignoreValueForPlaceholderColor
      ? placeholderColorProp
      : 'disabledContent';
  return (
    <InputRow
      fixedLabel
      {...otherProps}
      last={last}
      noBorder={last}
      onPress={onPress}
      testID={testID}
    >
      <Box flex={1} width="100%">
        <Row
          label={label}
          labelColor={labelColor}
          placeholder={placeholder}
          placeholderTestId={placeholderTestId}
          placeholderColor={placeholderColor}
          labelTestId={labelTestId}
          pr="m"
          last
          placeholderContainerMaxWidth={placeholderContainerMaxWidth}
        >
          {hasArrow && !disabled ? (
            <RowRight ml="m">
              <ArrowForward />
            </RowRight>
          ) : null}
        </Row>
      </Box>
    </InputRow>
  );
};
