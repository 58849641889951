import { RadioIcon } from '@unloan/common-ui';
import { Pressable, SxProp, Text, useDripsyTheme, View } from 'dripsy';
import { FormikContextType } from 'formik';
import * as React from 'react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import type { FormikControlProps } from '../../components/form/types';
import { createContext } from '../../utils/createContext';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { Card } from './Card';
import { SelectableRow } from './SelectableRow';

export type RadioGroupProps = {
  value?: string | null;
  children?: ReactNode;
  disabled?: boolean;
  onValueSelected?: (v: string) => void;
  interactionKey?: ApplicationInteractionEventKeyType;
};

type RadioGroupContextValue = {
  value: string | null;
  disabled: boolean;
  onSelect: (value: string) => void;
};

export const [useRadioGroupContext, RadioGroupContextProvider] =
  createContext<RadioGroupContextValue>('RadioGroup');

/**
 * Stateless RadioGroup for wrapping RadioGroupItems.
 * Avoid using this RadioGroup for form wrapped in formik.
 * Consider using RadioItemForFormik and let formik handle the item state directly
 */
export function RadioGroup(props: RadioGroupProps) {
  const { value, disabled, onValueSelected, children, interactionKey } = props;
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onValueChange = useCallback(
    (option: string) => {
      if (interactionKey) {
        sendAppInteractionEventToGTM({
          description: GTMAppInteractionEventDescription.RadioSelected,
          additionalData: {
            application_interaction_event_key: interactionKey,
          },
        });
      }
      onValueSelected?.(option);
    },
    [onValueSelected, interactionKey, sendAppInteractionEventToGTM],
  );

  const contextValue: RadioGroupContextValue = useMemo(
    () => ({
      value: value ?? null,
      disabled: !!disabled,
      onSelect: onValueChange,
    }),
    [disabled, onValueChange, value],
  );

  return (
    <RadioGroupContextProvider value={contextValue}>
      {children}
    </RadioGroupContextProvider>
  );
}

type RadioGroupItemCommonProps = {
  value: string;
  label: string;
  subtitle?: React.ReactNode;
  testID?: string;
  containerStyle?: SxProp;
  interactionKey: ApplicationInteractionEventKeyType;
};

/**
 * Avoid making "smart" RadioGroupItem component,
 * If we need another set of variant for radio item,
 * consider making a new RadioGroupItem component for it
 */
export function RadioGroupItem(props: RadioGroupItemCommonProps) {
  const [labelId] = React.useState(() => uuidv4());
  const {
    value: thisItemValue,
    label,
    testID,
    containerStyle,
    subtitle,
    interactionKey,
  } = props;
  const { value: currentValue, onSelect, disabled } = useRadioGroupContext();
  const isSelected = currentValue === thisItemValue;
  const [focused, setFocused] = useState(false);
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onItemPress = useCallback(() => {
    if (interactionKey) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.RadioSelected,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      });
    }
    if (disabled) {
      return;
    }
    onSelect(thisItemValue);
  }, [
    onSelect,
    thisItemValue,
    disabled,
    interactionKey,
    sendAppInteractionEventToGTM,
  ]);

  const disabledStyle = {
    color: '$secondary',
  };

  return (
    <SelectableRow
      onPress={onItemPress}
      testID={testID}
      iconType="radio"
      containerStyle={containerStyle}
      isSelected={isSelected}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      focused={focused}
      aria-labelledby={labelId}
      disabled={disabled}
    >
      <View sx={{ flex: 1 }}>
        <Text
          nativeID={labelId}
          sx={{
            flex: 1,
            ...(disabled && disabledStyle),
          }}
        >
          {label}
        </Text>
        {/*
          Why we have this `subtitle` when
          we have dedicated `RadioGroupItemWithSubtitle`?

          This should also not accept ReactNode as that
          imply we accept any child node, but we wrap with
          Text here, which only support nesting Text component.
         */}
        {subtitle != null ? <Text>{subtitle}</Text> : null}
      </View>
    </SelectableRow>
  );
}

type RadioGroupItemWithSubtitleProps = RadioGroupItemCommonProps & {
  subtitle: string;
};

export function RadioGroupItemWithSubtitle(
  props: RadioGroupItemWithSubtitleProps,
) {
  const [labelId] = React.useState(() => uuidv4());
  const {
    value: thisItemValue,
    label,
    subtitle,
    testID,
    containerStyle,
    interactionKey,
  } = props;
  const { value: currentValue, onSelect, disabled } = useRadioGroupContext();
  const isSelected = currentValue === thisItemValue;
  const [focused, setFocused] = useState(false);
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onItemPress = useCallback(() => {
    if (disabled) {
      return;
    }
    if (interactionKey) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.RadioSelected,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      });
    }
    onSelect(thisItemValue);
  }, [
    onSelect,
    thisItemValue,
    disabled,
    interactionKey,
    sendAppInteractionEventToGTM,
  ]);

  const disabledStyle = {
    color: '$secondary',
  };

  return (
    <SelectableRow
      onPress={onItemPress}
      testID={testID}
      iconType="radio"
      containerStyle={containerStyle}
      isSelected={isSelected}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      focused={focused}
      aria-labelledby={labelId}
      disabled={disabled}
    >
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            ...(disabled && disabledStyle),
          }}
        >
          {label}
        </Text>
        {subtitle != null ? (
          <Text
            variant="caption"
            sx={{
              ...(disabled && disabledStyle),
            }}
          >
            {subtitle}
          </Text>
        ) : null}
      </View>
    </SelectableRow>
  );
}

type RadioGroupItemAsCardProps = {
  value: string;
  children: React.ReactNode;
  compact?: boolean;
  /** The default behaviour is to show an empty radio indicator when the option is unselected;
   * Setting this to true will instead hide the option indicator if it is unselected.  */
  noRadioIfUnselected?: boolean;
  testID?: string;
  interactionKey?: ApplicationInteractionEventKeyType;
};

export function RadioGroupItemAsCard(props: RadioGroupItemAsCardProps) {
  const [labelId] = React.useState(() => uuidv4());
  const {
    value: thisItemValue,
    children,
    testID,
    compact = false,
    noRadioIfUnselected = false,
    interactionKey,
  } = props;
  const { value: currentValue, onSelect, disabled } = useRadioGroupContext();
  const isSelected = currentValue === thisItemValue;
  const [focused, setFocused] = useState(false);
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onItemPress = useCallback(() => {
    if (disabled) {
      return;
    }
    if (interactionKey) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.RadioSelected,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      });
    }
    onSelect(thisItemValue);
  }, [
    onSelect,
    thisItemValue,
    disabled,
    interactionKey,
    sendAppInteractionEventToGTM,
  ]);

  const isWeb = Platform.OS === 'web';

  const showRadio = isSelected || !noRadioIfUnselected;

  const dataSet: Record<string, unknown> = useMemo(() => {
    if (!isWeb) return {};
    return {
      ...(isSelected && { selected: 'true' }),
      ...(focused && { focused: 'true' }),
    };
  }, [isWeb, focused, isSelected]);

  const cardBorderStyle = useMemo(() => {
    const borderWidth = isSelected ? 2 : 1;
    const insetSize = (compact ? 16 : 24) - borderWidth;

    if (disabled) {
      return { borderColor: '$disabled', p: insetSize, borderWidth };
    }
    if (isSelected) {
      return { borderColor: '$labelsPrimary', p: insetSize, borderWidth };
    }
    return { borderColor: '$border', p: insetSize, borderWidth };
  }, [isSelected, disabled, compact]);

  const { theme } = useDripsyTheme();

  return (
    <Pressable
      sx={{
        borderRadius: '$card',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        overflow: 'visible',
      }}
      disabled={disabled}
      dataSet={dataSet}
      role="radio"
      aria-checked={isSelected}
      aria-disabled={disabled}
      aria-labelledby={labelId}
      onPress={onItemPress}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      testID={testID}
    >
      <Card
        sx={{
          width: '100%',
          ...(disabled && {
            backgroundColor: '$inputBackgroundDisabled',
          }),
          marginBottom: 0,
          height: '100%',
          ...cardBorderStyle,
        }}
        dataSet={dataSet}
      >
        {children}
        <View sx={{ position: 'absolute', top: '$8', right: '$8' }}>
          {showRadio ? (
            <RadioIcon
              isSelected={isSelected}
              size={16}
              {...(disabled && {
                color: theme.colors.$iconBackgroundDisabled,
              })}
            />
          ) : null}
        </View>
      </Card>
    </Pressable>
  );
}

type RadioItemFormikValue =
  | string
  // Value will be an array of string if checkboxCompatible is true
  | Array<string>;

export type RadioItemForFormikProps = RadioGroupItemCommonProps &
  Pick<
    React.ComponentProps<typeof SelectableRow>,
    'onFocus' | 'onBlur' | 'focused'
  > &
  Omit<FormikControlProps<RadioItemFormikValue>, 'setFieldValue'> & {
    name: string;
    setFieldValue?: (
      value: RadioItemFormikValue,
      shouldValidate?: boolean,
    ) => Promise<void>;
    formik?: FormikContextType<Record<string, RadioItemFormikValue>>;
    /**
     * Make the radio item interchangeable with checkbox
     * inside a formik form with the same field name.
     */
    checkboxCompatible?: boolean;
    onChangeValue?: (value: RadioItemFormikValue) => void;
    subtitle?: string;
    disabled?: boolean;
    interactionKey: ApplicationInteractionEventKeyType;
  };

export function RadioItemForFormik(props: RadioItemForFormikProps) {
  const {
    name,
    value: thisItemValue,
    label,
    setFieldValue,
    setFieldTouched,
    formik,
    checkboxCompatible,
    onChangeValue,
    subtitle,
    disabled,
    interactionKey,
    ...otherProps
  } = props;
  const [labelId] = React.useState(() => uuidv4());
  const rawFieldValue = name && formik?.values?.[name];
  let isSelected = false;
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();
  if (rawFieldValue != null) {
    if (checkboxCompatible) {
      isSelected = (
        Array.isArray(rawFieldValue) ? rawFieldValue : [rawFieldValue]
      ).includes(thisItemValue);
    } else {
      isSelected = rawFieldValue === thisItemValue;
    }
  }

  const onItemPress = useCallback(async () => {
    if (interactionKey) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.RadioSelected,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      });
    }
    const newValue = checkboxCompatible ? [thisItemValue] : thisItemValue;

    await setFieldValue?.(newValue, false);

    onChangeValue?.(newValue);

    setFieldTouched?.(true, true);
  }, [
    checkboxCompatible,
    onChangeValue,
    setFieldTouched,
    setFieldValue,
    thisItemValue,
    interactionKey,
    sendAppInteractionEventToGTM,
  ]);

  const disabledStyle = {
    color: '$secondary',
  };

  return (
    <SelectableRow
      iconType="radio"
      isSelected={isSelected}
      onPress={onItemPress}
      aria-labelledby={labelId}
      disabled={disabled}
      {...otherProps}
    >
      <View sx={{ flex: 1 }}>
        <Text nativeID={labelId} sx={{ ...(disabled && disabledStyle) }}>
          {label}
        </Text>
        {subtitle ? <Text variant="caption">{subtitle}</Text> : null}
      </View>
    </SelectableRow>
  );
}
