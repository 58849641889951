import {
  Frequency_Input_Enum,
  Living_Situation_Input_Enum,
} from '../../generated/graphql';
import { yup } from '../../utils/yup';
import { ExpensesFormField, ExpensesTypesField } from './types';

export const expensesFormSchema = {
  [ExpensesFormField.SelectedBorrowers]: yup
    .array()
    .of(yup.string().required())
    .min(1, t('Content.ExpensesForm.BorrowersError')),
  [ExpensesFormField.DependentsCount]: yup
    .number()
    .nullable()
    .required(t('Content.ExpensesForm.DependentsError')),
  [ExpensesFormField.LivingSituation]: yup
    .mixed<Living_Situation_Input_Enum>()
    .oneOf(Object.values(Living_Situation_Input_Enum))
    .required(t('Content.Common.Error.SelectAnOption')),
};

export const ExpensesFormValidationSchema = yup.object({
  ...expensesFormSchema,
});

export const ExpensesFormInitialValues: ExpensesFormValues = {
  [ExpensesFormField.LivingSituation]: undefined,
  [ExpensesFormField.SelectedBorrowers]: [],
  [ExpensesFormField.DependentsCount]: undefined,
};

export type ExpensesFormValues = Partial<
  yup.Asserts<typeof ExpensesFormValidationSchema>
>;

export const yupExpensesAmount = yup
  .number()
  .nullable()
  .required(t('Content.ExpensesForm.ExpensesAmountError'));

const expensesTypeOwnPropertySchema = {
  [ExpensesTypesField.FoodAndGroceries]: yupExpensesAmount,
  [ExpensesTypesField.FoodAndGroceriesFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.HomeAndUtilities]: yupExpensesAmount,
  [ExpensesTypesField.HomeAndUtilitiesFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.ClothingAndPersonalCare]: yupExpensesAmount,
  [ExpensesTypesField.ClothingAndPersonalCareFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.MedicalHealthFitness]: yupExpensesAmount,
  [ExpensesTypesField.MedicalHealthFitnessFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.Transport]: yupExpensesAmount,
  [ExpensesTypesField.TransportFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.EntertainmentAndPets]: yupExpensesAmount,
  [ExpensesTypesField.EntertainmentAndPetsFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.ChildcareAndEducation]: yupExpensesAmount,
  [ExpensesTypesField.ChildcareAndEducationFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.ChildAndSpouseSupport]: yupExpensesAmount,
  [ExpensesTypesField.ChildAndSpouseSupportFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),

  [ExpensesTypesField.AdditionalExpenses]: yupExpensesAmount,
  [ExpensesTypesField.AdditionalExpensesFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),
};

const expensesTypeRentSchema = {
  [ExpensesTypesField.RentOrBoard]: yupExpensesAmount,
  [ExpensesTypesField.RentOrBoardFrequency]: yup
    .string()
    .nullable()
    .required(t('Content.ExpensesForm.SelectFrequency')),
};

export const ExpensesTypeFormValidationSchemaOwnProperty = yup.object({
  ...expensesTypeOwnPropertySchema,
});

export const ExpensesTypeFormValidationSchemaRent = yup.object({
  ...expensesTypeOwnPropertySchema,
  ...expensesTypeRentSchema,
});

export const ExpensesTypeFormInitialValues: ExpensesTypeFormValuesRent = {
  [ExpensesTypesField.FoodAndGroceries]: undefined,
  [ExpensesTypesField.FoodAndGroceriesFrequency]: Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.HomeAndUtilities]: undefined,
  [ExpensesTypesField.HomeAndUtilitiesFrequency]: Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.ClothingAndPersonalCare]: undefined,
  [ExpensesTypesField.ClothingAndPersonalCareFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.MedicalHealthFitness]: undefined,
  [ExpensesTypesField.MedicalHealthFitnessFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.Transport]: undefined,
  [ExpensesTypesField.TransportFrequency]: Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.EntertainmentAndPets]: undefined,
  [ExpensesTypesField.EntertainmentAndPetsFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.ChildcareAndEducation]: undefined,
  [ExpensesTypesField.ChildcareAndEducationFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.ChildAndSpouseSupport]: undefined,
  [ExpensesTypesField.ChildAndSpouseSupportFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.AdditionalExpenses]: undefined,
  [ExpensesTypesField.AdditionalExpensesFrequency]:
    Frequency_Input_Enum.Monthly,

  [ExpensesTypesField.RentOrBoard]: undefined,
  [ExpensesTypesField.RentOrBoardFrequency]: Frequency_Input_Enum.Monthly,
};

export type ExpensesTypeFormValuesOwnProperty = Partial<
  yup.Asserts<typeof ExpensesTypeFormValidationSchemaOwnProperty>
>;

export type ExpensesTypeFormValuesRent = Partial<
  yup.Asserts<typeof ExpensesTypeFormValidationSchemaRent>
>;

export type AllExpensesTypeFormValues = ExpensesTypeFormValuesRent;

export const CombinedExpensesFormValidationSchemaRent = yup.object({
  ...expensesFormSchema,
  ...expensesTypeOwnPropertySchema,
  ...expensesTypeRentSchema,
});

export type CombinedExpensesFormValues = Partial<
  yup.Asserts<typeof CombinedExpensesFormValidationSchemaRent>
>;
