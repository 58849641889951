import { HTMLAttributes } from 'react';
import * as React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

type Props = {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export const BlurView: React.FC<Props> = ({ children, style }) => {
  // FIXME: this flatten return ViewStyle, not CSS style
  const styles = StyleSheet.flatten(
    style,
  ) as HTMLAttributes<HTMLDivElement>['style'];

  return (
    <div
      style={{
        backdropFilter: 'blur(20px)',
        WebkitBackdropFilter: 'blur(20px)',
        ...styles,
      }}
    >
      {children}
    </div>
  );
};
