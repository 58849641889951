import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const ArrowLeftIcon = ({ color, size, ...props }: SvgIconProps) => {
  const theme = useTheme();
  const fill = color ?? theme.colors.buttonPrimaryBg;
  const width = resizeIconWidth(23, 19, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 23 19" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.425781 9.52734C0.425781 9.87891 0.578125 10.2188 0.847656 10.4766L8.57031 18.1992C8.85156 18.4688 9.16797 18.5977 9.48438 18.5977C10.2227 18.5977 10.75 18.0703 10.75 17.3672C10.75 17.0039 10.6094 16.6875 10.375 16.4648L7.75 13.8047L4.28125 10.6406L7.02344 10.8047H21.2734C22.0352 10.8047 22.5742 10.2773 22.5742 9.52734C22.5742 8.76562 22.0352 8.25 21.2734 8.25H7.02344L4.29297 8.41406L7.75 5.25L10.375 2.58984C10.6094 2.35547 10.75 2.03906 10.75 1.67578C10.75 0.972656 10.2227 0.457031 9.48438 0.457031C9.16797 0.457031 8.85156 0.574219 8.54688 0.867188L0.847656 8.57812C0.578125 8.82422 0.425781 9.17578 0.425781 9.52734Z"
        fill={fill}
      />
    </Svg>
  );
};
