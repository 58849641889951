import { styled, SxProp, Text, useSx, View } from 'dripsy';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import {
  FormCheckboxInputV2,
  FormCurrencyInputV2,
  FormMultilineTextInputV2,
  FormRadioInputV2,
  FormSelectV2,
} from '../../../components/form/FormikInputs';
import { buildLiabilityTestId } from '../../../components/LiabilityPanel';
import {
  formatLiabilityLabel,
  formatLiabilitySublabels,
  formatOwners,
} from '../../../components/utils/formatLiabilityRow';
import { FullNameByApplicantIds } from '../../../DebtsWizard/utils/types';
import { FeatureFlagsContext } from '../../../FeatureFlags/context';
import {
  Liability_Type_Enum,
  LoanApplicationCostAndBufferForSetupLoanScreenFragment,
  MergedLiabilityForSetupLoanScreenFragment,
  Repayment_Calculation_Error,
  Top_Up_Reason_Enum,
} from '../../../generated/graphql';
import { Screen } from '../../../navigation/types/screens';
import { addBreadcrumb } from '../../../sentry';
import { Button } from '../../../ui/atoms/Button';
import { Link } from '../../../ui/atoms/Link';
import { Tooltip } from '../../../ui/atoms/Tooltip';
import { FormikFormError } from '../../../ui/v2/FormError';
import { Skeleton, useTextSkeletonDimension } from '../../../ui/v2/Skeleton';
import { isLast } from '../../../utils/arrayHelpers';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { parseEnumType } from '../../../utils/ensureEnumType';
import { transformStringToEnum } from '../../../utils/enumHelpers';
import { useDebouncedGtmDataLayer } from '../../../utils/hooks/useDebouncedSendToGTM';
import {
  formatInterestRate,
  formatInterestRates,
  makeTestId,
} from '../../../utils/stringHelpers';
import { getSumOfSelectedRefinancedLiabilities } from '../../utils/getSumOfSelectedRefinancedLiabilities';
import {
  DISPLAYED_TOP_UP_REASONS,
  getTotalCashoutAmount,
} from '../../utils/getTotalCashoutAmount';
import {
  DefaultSetupLoanFormWithLoanAmountInitialValues,
  LiabilityCheckerOptions,
  makeSetupLoanWithLoanAmountValidationSchema,
  SetupLoanFormWithLoanAmountField,
  SetupLoanFormWithLoanAmountInitialValues,
  SetupLoanTopUpFieldNameMap,
  SetupLoanYesNoEnum,
  YEAR_TERM_OPTIONS,
} from '../../utils/setupLoanFormHelper';
import { UseLoanAmountCalculationV3Return } from '../../utils/useSetupLoanCalculation';
import { MonthlyRepaymentError } from './MonthlyRepaymentError';
import { SetupLoanSavingsBanner } from './SetupLoanSavingsBanner';

export type SetupLoanFormProps = UseLoanAmountCalculationV3Return & {
  screen: Screen;
  liabilitiesAllowedToBeRefinanced: Array<MergedLiabilityForSetupLoanScreenFragment>;
  allSecurities: LiabilityCheckerOptions['allSecurities'];
  costAndBufferDetails:
    | LoanApplicationCostAndBufferForSetupLoanScreenFragment
    | undefined;
  costAndBufferLoading: boolean;
  fullNameByApplicantIds: FullNameByApplicantIds;
  productRate: number | undefined;
  initialValues?: SetupLoanFormWithLoanAmountInitialValues;
  loanApplicationId: string;
  isSubmitting?: boolean;
  onSubmit: (values: SetupLoanFormWithLoanAmountInitialValues) => void;
  navigateToYourDebts: () => void;
  /** For storybook purpose */
  forceShowMonthlyRepaymentError?: boolean;
};

function mapTopUpFields(values: SetupLoanFormWithLoanAmountInitialValues) {
  return {
    [Top_Up_Reason_Enum.YourHome]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.YourHome]],
    [Top_Up_Reason_Enum.FinancialInvestment]:
      values[
        SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.FinancialInvestment]
      ],
    [Top_Up_Reason_Enum.InvestmentProperty]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.InvestmentProperty]],
    [Top_Up_Reason_Enum.PersonalUse]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.PersonalUse]],
    [Top_Up_Reason_Enum.Other]:
      values[SetupLoanTopUpFieldNameMap[Top_Up_Reason_Enum.Other]],
  };
}

function getSetupYourUnloanInteractionEventKey(screen: Screen) {
  return {
    [Top_Up_Reason_Enum.YourHome]: buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      screen,
      FieldInteractionKey.CashOutYourHome,
    ),
    [Top_Up_Reason_Enum.FinancialInvestment]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.YourUnloan,
        screen,
        FieldInteractionKey.CashOutFinancialInvestment,
      ),
    [Top_Up_Reason_Enum.InvestmentProperty]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.YourUnloan,
        screen,
        FieldInteractionKey.CashOutInvestmentProperty,
      ),
    [Top_Up_Reason_Enum.PersonalUse]: buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      screen,
      FieldInteractionKey.CashOutPersonalUse,
    ),
    [Top_Up_Reason_Enum.Other]: buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      screen,
      FieldInteractionKey.CashOutOther,
    ),
    [Top_Up_Reason_Enum.Property]: buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      screen,
      FieldInteractionKey.CashOutProperty,
    ),
  };
}

export function SetupLoanFooter({
  screen,
  loanApplicationId,
  cashoutSelected,
  sumTopUpAmount,
  termInMonths,
  desiredLoanAmount,
  calculateLoanAmounts,
  calculationLoading,
  calculationData,
  sumSecurityCosts,
  mergedLiabilityForRefinanceIds,
  productRate,
}: UseLoanAmountCalculationV3Return & {
  screen: Screen;
  loanApplicationId?: string;
  termInMonths?: number;
  desiredLoanAmount?: number;
  cashoutSelected: boolean;
  sumTopUpAmount: number;
  sumSecurityCosts?: number;
  mergedLiabilityForRefinanceIds?: Array<string>;
  productRate: number | undefined;
}) {
  const { flags } = useContext(FeatureFlagsContext);
  const enableShowSavingsOnYourUnloan =
    flags.ENABLE_SHOW_SAVINGS_ON_YOUR_UNLOAN;

  const debouncedCalculateSetupLoanAmounts = useRef(
    debounce(calculateLoanAmounts, 300),
  );

  useEffect(() => {
    if (!loanApplicationId) {
      return;
    }
    addBreadcrumb('Calculate Setup Loan Amounts Args', {
      loan_application_id: loanApplicationId,
      loan_term_in_months: termInMonths,
      desired_loan_amount: desiredLoanAmount,
      top_up_amount: sumTopUpAmount,
    });
    debouncedCalculateSetupLoanAmounts.current({
      data: {
        loan_application_id: loanApplicationId,
        loan_term_in_months: termInMonths || 0,
        desired_loan_amount: desiredLoanAmount || 0,
        top_up_amount: sumTopUpAmount,
      },
      loanApplicationId,
      desiredLoanAmount: desiredLoanAmount || 0,
      liabilityIds: mergedLiabilityForRefinanceIds || [],
      loanTerm: termInMonths || 0,
    });
  }, [
    termInMonths,
    desiredLoanAmount,
    loanApplicationId,
    sumTopUpAmount,
    // When security costs change, we need to recalculate the total loan amount
    sumSecurityCosts,
    mergedLiabilityForRefinanceIds,
  ]);
  const totalLoanAmountSkeleton = useTextSkeletonDimension(6, 1, 'lNumber');
  const minMonthlyRepaymentSkeleton = useTextSkeletonDimension(
    6,
    1,
    'xsHeader',
  );

  const returning = calculationData?.calculate_app_total_loan_amount_v2;

  const savingsAmount = calculationData?.get_savings_calculation?.savings;
  const interestRates =
    calculationData?.get_savings_calculation?.merged_liabilities?.map(
      (ml) => ml?.interest_rate,
    ) || [];

  const showSavingsAmountAndDescription =
    enableShowSavingsOnYourUnloan &&
    !cashoutSelected &&
    savingsAmount &&
    savingsAmount > 0;

  useDebouncedGtmDataLayer(
    GTMAppInteractionEventDescription.ValueChanged,
    buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      screen,
      FieldInteractionKey.SavingsValueChange,
    ),
    savingsAmount,
    {
      savings_amount: savingsAmount,
      savings_banner_visible: !!showSavingsAmountAndDescription,
    },
  );

  const formattedMinRepaymentAmount =
    returning?.min_repayment_error_type != null
      ? '$--'
      : formatCurrency(returning?.monthly_repayment_amount, {
          withFractionOnRoundedAmount: true,
        });

  return (
    <>
      {calculationLoading ? (
        <View sx={{ mb: '$8' }}>
          <Skeleton
            width={totalLoanAmountSkeleton.width}
            height={totalLoanAmountSkeleton.height}
            show
          />
        </View>
      ) : (
        <Text variant="lNumber" testID={TestID.SetupLoanForm.TotalLoanAmount}>
          {formatCurrency(returning?.total_loan_amount, {
            withFractionOnRoundedAmount: true,
          })}
        </Text>
      )}

      {calculationLoading ? (
        <Skeleton
          width={minMonthlyRepaymentSkeleton.width}
          height={minMonthlyRepaymentSkeleton.height}
          show
        />
      ) : (
        <Text
          variant="xsHeader"
          testID={TestID.SetupLoanScreen.MinRepaymentAmountText}
        >
          {t('Content.Common.XPerMonth*', { x: formattedMinRepaymentAmount })}
        </Text>
      )}
      {showSavingsAmountAndDescription ? (
        <SetupLoanSavingsBanner
          savingsAmount={savingsAmount}
          sx={{ marginY: '$24' }}
        />
      ) : null}
      <Text variant="caption" sx={{ paddingTop: '$8' }}>
        {t('Content.SetupLoanV2.MinRepaymentsDescription', {
          loanTerm: termInMonths ? termInMonths / 12 : 0,
          productRate: formatInterestRate(productRate),
        })}
      </Text>
      {showSavingsAmountAndDescription ? (
        <>
          <Text variant="caption" sx={{ paddingTop: '$8' }}>
            {t('Content.SetupLoanV2.SavingsDescription1', {
              s: interestRates.length > 1 ? 's' : '',
              interestRates: formatInterestRates(interestRates),
              productRate: formatInterestRate(productRate),
            })}
          </Text>
          <Text variant="caption" sx={{ paddingTop: '$8' }}>
            {t('Content.SetupLoanV2.SavingsDescription2')}
          </Text>
        </>
      ) : null}
    </>
  );
}

export function getTitleWhenNoLoansToRefinance() {
  return (
    <Text variant="caption" sx={{ mb: '$8' }}>
      {t('Content.SetupLoanV2.SelectRefiSection.GoBackToDebtsLiabilities')}
    </Text>
  );
}

export function getRefinanceSectionHeading(noLiabilitiesToRefinance: boolean) {
  return noLiabilitiesToRefinance
    ? {
        sectionTitle: t(
          'Content.SetupLoanWithLoanAmount.RefinanceSection.TitleV2',
        ),
        sectionValue: formatCurrency(0, {
          withFractionOnRoundedAmount: true,
        }),
      }
    : {
        sectionTitle: t(
          'Content.SetupLoanWithLoanAmount.RefinanceSection.Title',
        ),
        sectionValue: '',
      };
}

export function SetupLoanForm({
  screen,
  liabilitiesAllowedToBeRefinanced,
  costAndBufferDetails,
  costAndBufferLoading,
  fullNameByApplicantIds,
  productRate,
  initialValues = DefaultSetupLoanFormWithLoanAmountInitialValues,
  loanApplicationId,
  isSubmitting,
  onSubmit,
  calculationData,
  calculationLoading,
  calculateLoanAmounts,
  forceShowMonthlyRepaymentError,
  allSecurities,
}: SetupLoanFormProps) {
  const [mergedLiabilityForRefinanceIds, setMergedLiabilityForRefinanceIds] =
    useState<Array<string>>(
      initialValues.selectedMergedLiabilitiesToBeRefinanced || [],
    );

  const sx = useSx();
  const validationSchema = useMemo(
    () =>
      makeSetupLoanWithLoanAmountValidationSchema({
        allSecurities,
        mergedLiabilities: liabilitiesAllowedToBeRefinanced,
      }),
    [allSecurities, liabilitiesAllowedToBeRefinanced],
  );

  const setupYourUnloanInteractionEventKeys =
    getSetupYourUnloanInteractionEventKey(screen);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, handleSubmit, dirty, setFieldValue }) => {
        /**
         * Only show error for monthly repayment calculation when:
         * 1. Form is dirty
         * We don't want to display any error from repayment calculation when form first initialized.
         * This is to avoid displaying error when it is user's first time visiting this screen
         * (for example when there is no target loan being setup yet)
         * 2. When it is not fetching the calculation data
         */
        const showMonthlyRepaymentErrorRow =
          forceShowMonthlyRepaymentError || (dirty && !calculationLoading);

        const termInMonths =
          values[SetupLoanFormWithLoanAmountField.TermInMonths];

        const cashoutSelected =
          values[SetupLoanFormWithLoanAmountField.HasTopUpAmount] ===
          SetupLoanYesNoEnum.Yes;

        const sumTopUpAmount = cashoutSelected
          ? getTotalCashoutAmount(mapTopUpFields(values))
          : 0;

        const minRepaymentError = transformStringToEnum(
          calculationData?.calculate_app_total_loan_amount_v2
            .min_repayment_error_type,
          Repayment_Calculation_Error,
        );
        const minLoanAmount =
          calculationData?.calculate_app_total_loan_amount_v2.min_loan_amount;
        const maxLoanAmount =
          calculationData?.calculate_app_total_loan_amount_v2.max_loan_amount;

        const currentSelectedLiabilitiesForRefinance =
          getSumOfSelectedRefinancedLiabilities({
            mergedLiabilities: liabilitiesAllowedToBeRefinanced,
            selectedCurrentLiabilityForRefinanceIds: new Set(
              values[
                SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced
              ],
            ),
          });

        const handleLiabilityPress = (selectedLiabilityId: string) => () => {
          if (!selectedLiabilityId) {
            return;
          }

          const selectedCurrentLiabilityForRefinanceIds = new Set(
            values[
              SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced
            ],
          );

          if (
            selectedCurrentLiabilityForRefinanceIds.has(selectedLiabilityId)
          ) {
            selectedCurrentLiabilityForRefinanceIds.delete(selectedLiabilityId);
          } else {
            selectedCurrentLiabilityForRefinanceIds.add(selectedLiabilityId);
          }

          const sumOfSelectedRefinancedLiabilities =
            getSumOfSelectedRefinancedLiabilities({
              mergedLiabilities: liabilitiesAllowedToBeRefinanced,
              selectedCurrentLiabilityForRefinanceIds,
            });

          setFieldValue(
            SetupLoanFormWithLoanAmountField.DesiredLoanAmount,
            sumOfSelectedRefinancedLiabilities,
            true,
          );

          const mergedLiabilityIds: Array<string> = [];
          selectedCurrentLiabilityForRefinanceIds.forEach(
            (currentLiabilityId) => {
              const mergedLiabilityToBeRefinanced =
                liabilitiesAllowedToBeRefinanced.find(
                  (ml) => ml.current_liability_id === currentLiabilityId,
                );
              if (mergedLiabilityToBeRefinanced)
                mergedLiabilityIds.push(mergedLiabilityToBeRefinanced.id);
            },
          );

          setMergedLiabilityForRefinanceIds(mergedLiabilityIds);
        };

        return (
          <View pointerEvents={isSubmitting ? 'none' : undefined}>
            {showMonthlyRepaymentErrorRow ? (
              <MonthlyRepaymentError
                minMonthlyRepaymentError={minRepaymentError}
                minLoanAmountToCalculate={minLoanAmount}
                maxLoanAmountToCalculate={maxLoanAmount}
                sx={{ my: '$8' }}
              />
            ) : null}

            <SectionHeading
              {...getRefinanceSectionHeading(
                liabilitiesAllowedToBeRefinanced.length === 0,
              )}
            />
            {liabilitiesAllowedToBeRefinanced.length === 0 ? (
              getTitleWhenNoLoansToRefinance()
            ) : (
              <>
                <FormCurrencyInputV2
                  name={SetupLoanFormWithLoanAmountField.DesiredLoanAmount}
                  label={t(
                    'Content.SetupLoanWithLoanAmount.RefinanceSection.LoanAmount',
                  )}
                  sx={{ mt: '$8' }}
                  inputTestID={TestID.SetupLoanForm.LoanAmountInput}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.YourUnloan,
                    screen,
                    FieldInteractionKey.LoanAmount,
                  )}
                />

                {values[SetupLoanFormWithLoanAmountField.DesiredLoanAmount] ? (
                  <FormikFormError
                    name={SetupLoanFormWithLoanAmountField.DesiredLoanAmount}
                    sx={{ mt: '$8' }}
                  />
                ) : null}

                {currentSelectedLiabilitiesForRefinance === 0 ? null : (
                  <Text sx={{ mt: '$8' }} variant="caption">
                    {t(
                      'Content.SetupLoanWithLoanAmount.RefinanceSection.Description',
                      {
                        loanBalance: formatCurrency(
                          currentSelectedLiabilitiesForRefinance,
                          {
                            withFractionOnRoundedAmount: true,
                          },
                        ),
                      },
                    )}
                    <Link
                      href={t('Link.RefinanceLoanAmount')}
                      style={sx({ variant: 'text.caption', color: '$link' })}
                    >
                      {' '}
                      {t(
                        'Content.SetupLoanWithLoanAmount.RefinanceSection.LearnMore',
                      )}
                    </Link>
                  </Text>
                )}
                <Text sx={{ mt: '$16' }} variant="sBody">
                  {t(
                    'Content.SetupLoanWithLoanAmount.RefinanceSection.Subtitle',
                  )}
                </Text>
                {liabilitiesAllowedToBeRefinanced.map((item, index) => {
                  if (item.current_liability_id == null) {
                    return null;
                  }

                  const parsedLiabilityType =
                    parseEnumType(
                      Liability_Type_Enum,
                      item.dynamite_liability_type,
                    ) || Liability_Type_Enum.Other;
                  return (
                    <FormCheckboxInputV2
                      key={item.current_liability_id}
                      name={
                        SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced
                      }
                      value={item.current_liability_id}
                      label={formatLiabilityLabel({
                        institutionName: item.dynamite_institution_name,
                        liabilityType: item.dynamite_liability_type,
                      })}
                      subtitle={[
                        ...formatLiabilitySublabels({
                          balance: item.dynamite_balance,
                          limit: item.dynamite_limit,
                          liabilityType: parsedLiabilityType,
                          address: item.dynamite_address?.short_address_format,
                        }),
                        formatOwners({
                          fullNameByApplicantIds,
                          applicantIds: item.dynamite_applicant_ids,
                        }),
                      ].join('\n')}
                      disabled={false}
                      containerStyle={{
                        mt: '$8',
                        mb: isLast(liabilitiesAllowedToBeRefinanced, index)
                          ? '$8'
                          : undefined,
                      }}
                      testID={buildLiabilityTestId(
                        TestID.SetupLoanForm.LoansToRefinance,
                        parsedLiabilityType,
                        item.dynamite_account_number,
                      )}
                      onItemPressCallback={handleLiabilityPress(
                        item.current_liability_id,
                      )}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.YourUnloan,
                        screen,
                        FieldInteractionKey.SelectedLoansToBeRefinanced,
                      )}
                    />
                  );
                })}
                <FormikFormError
                  name={
                    SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced
                  }
                  sx={{ mt: '$8' }}
                />
              </>
            )}

            <SectionHeading
              sectionTitle={t('Content.SetupLoanV2.CashOutSection.Title')}
              sectionValue={formatCurrency(sumTopUpAmount, {
                withFractionOnRoundedAmount: true,
              })}
              containerSx={{ mt: '$24' }}
            />
            <Text sx={{ mb: '$8', variant: 'text.sBody' }}>
              {t('Content.SetupLoanV2.CashOutSection.SubtitleV2')}
            </Text>
            <RowView sx={{ my: '$8' }}>
              <FormRadioInputV2
                name={SetupLoanFormWithLoanAmountField.HasTopUpAmount}
                value={SetupLoanYesNoEnum.Yes}
                testID={makeTestId([
                  TestID.SetupLoanForm.HasTopUpAmount,
                  SetupLoanYesNoEnum.Yes,
                ])}
                label={t('Content.Common.ButtonLabel.Yes')}
                containerStyle={{
                  mr: '$16',
                  flex: 1,
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.YourUnloan,
                  screen,
                  FieldInteractionKey.HasCashOutAmount,
                )}
              />
              <FormRadioInputV2
                name={SetupLoanFormWithLoanAmountField.HasTopUpAmount}
                value={SetupLoanYesNoEnum.No}
                testID={makeTestId([
                  TestID.SetupLoanForm.HasTopUpAmount,
                  SetupLoanYesNoEnum.No,
                ])}
                label={t('Content.Common.ButtonLabel.No')}
                containerStyle={{
                  flex: 1,
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.YourUnloan,
                  screen,
                  FieldInteractionKey.HasCashOutAmount,
                )}
              />
            </RowView>
            <FormikFormError
              name={SetupLoanFormWithLoanAmountField.HasTopUpAmount}
              sx={{ mb: '$8' }}
            />
            {values[SetupLoanFormWithLoanAmountField.HasTopUpAmount] ===
            SetupLoanYesNoEnum.Yes ? (
              <View sx={{ mt: '$24' }}>
                <Text sx={{ variant: 'text.sBody' }}>
                  {t('Content.SetupLoanV2.CashOutSection.EnterAmountAndReason')}
                </Text>
                {DISPLAYED_TOP_UP_REASONS.map((topUpReason) => {
                  const fieldName = SetupLoanTopUpFieldNameMap[topUpReason];
                  const description =
                    topUpReason === Top_Up_Reason_Enum.Other
                      ? t('Content.TopUpReasonEnum.OTHER.descriptionV2')
                      : t(`Content.TopUpReasonEnum.${topUpReason}.description`);
                  return (
                    <Fragment key={topUpReason}>
                      <FormCurrencyInputV2
                        name={fieldName}
                        // Because we're using ghost field to validate whether any of the top up amount is filled
                        // We need to inject SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled's error state in this field
                        error={
                          errors[fieldName] ||
                          errors[
                            SetupLoanFormWithLoanAmountField
                              .IsAnyOfTopUpAmountFilled
                          ]
                        }
                        label={t(
                          `Content.TopUpReasonEnum.${topUpReason}.title`,
                        )}
                        sx={{ mt: '$16' }}
                        inputTestID={
                          TestID.SetupLoanForm.TopUpFields[topUpReason]
                        }
                        interactionKey={
                          setupYourUnloanInteractionEventKeys[topUpReason]
                        }
                      />
                      <FormikFormError name={fieldName} sx={{ my: '$8' }} />
                      <FormikFormError
                        name={
                          SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled
                        }
                        sx={{ my: '$8' }}
                      />
                      <Text sx={{ my: '$8' }} variant="caption">
                        {description}
                      </Text>
                    </Fragment>
                  );
                })}
                {values[SetupLoanFormWithLoanAmountField.OtherTopUpAmount] ? (
                  <>
                    <FormMultilineTextInputV2
                      name={
                        SetupLoanFormWithLoanAmountField.OtherTopUpDescription
                      }
                      label={t(
                        'Content.SetupLoanV2.CashOutSection.OtherDescriptionLabel',
                      )}
                      sx={{ mt: '$8' }}
                      inputTestID={
                        TestID.SetupLoanForm.TopUpFields.OtherDescription
                      }
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.YourUnloan,
                        screen,
                        FieldInteractionKey.OtherCashoutDescription,
                      )}
                    />
                    <FormikFormError
                      name={
                        SetupLoanFormWithLoanAmountField.OtherTopUpDescription
                      }
                      sx={{ mt: '$8' }}
                    />
                  </>
                ) : null}
              </View>
            ) : null}

            <View sx={{ mt: '$24' }}>
              <SectionHeading
                sectionTitle={t('Content.SetupLoanV2.LoanTermSection.Title')}
              />
              <FormSelectV2
                sx={{ mt: '$8' }}
                name={SetupLoanFormWithLoanAmountField.TermInMonths}
                testID={TestID.SetupLoanForm.LoanTerm}
                items={YEAR_TERM_OPTIONS}
                label={t('Content.SetupLoanV2.LoanTermSection.Title')}
              />
              <FormikFormError
                name={SetupLoanFormWithLoanAmountField.TermInMonths}
                sx={{ mt: '$8' }}
              />
            </View>

            <View sx={{ mt: '$24' }}>
              <SectionHeading
                sectionTitle={t('Content.SetupLoanV2.FeesSection.Title')}
                sectionValue={
                  costAndBufferLoading
                    ? '--'
                    : formatCurrency(
                        costAndBufferDetails?.sum_loan_application_security_costs,
                        {
                          withFractionOnRoundedAmount: true,
                        },
                      )
                }
                sectionValueTestID={TestID.SetupLoanForm.TotalFeesAmount}
                tooltipContent={t('Content.SetupLoan.FeesInformation')}
              />
              <Text variant="caption">
                {t('Content.SetupLoanV2.FeesSection.UnloanFees', {
                  // Unloan fee is always 0
                  fee: formatCurrency(0, {
                    withFractionOnRoundedAmount: true,
                  }),
                })}
              </Text>
              <Text variant="caption">
                {t('Content.SetupLoanV2.FeesSection.MortgageRegistrationFee', {
                  fee: costAndBufferLoading
                    ? '--'
                    : formatCurrency(
                        costAndBufferDetails?.mortgage_registration_amount,
                        {
                          withFractionOnRoundedAmount: true,
                        },
                      ),
                })}
              </Text>
              <Text variant="caption">
                {t('Content.SetupLoanV2.FeesSection.MortgageDischargeFee', {
                  fee: costAndBufferLoading
                    ? '--'
                    : formatCurrency(
                        costAndBufferDetails?.mortgage_discharge_amount,
                        {
                          withFractionOnRoundedAmount: true,
                        },
                      ),
                })}
              </Text>
              {costAndBufferDetails?.lender_discharge_amount &&
              costAndBufferDetails.lender_discharge_amount > 0 ? (
                <Text variant="caption">
                  {t('Content.SetupLoanV2.FeesSection.LenderDischargeFee', {
                    fee: costAndBufferLoading
                      ? '--'
                      : formatCurrency(
                          costAndBufferDetails?.lender_discharge_amount,
                          {
                            withFractionOnRoundedAmount: true,
                          },
                        ),
                  })}
                </Text>
              ) : null}
            </View>

            {costAndBufferDetails?.buffer_amount &&
            costAndBufferDetails?.buffer_amount > 0 ? (
              <View sx={{ mt: '$16' }}>
                <SectionHeading
                  sectionTitle={t('Content.SetupLoanV2.BufferSection.Title')}
                  sectionValueTestID={TestID.SetupLoanForm.BufferAmount}
                  sectionValue={formatCurrency(
                    costAndBufferDetails?.buffer_amount,
                    {
                      withFractionOnRoundedAmount: true,
                    },
                  )}
                  tooltipContent={t(
                    'Content.SetupLoan.BufferAmountInformation',
                    {
                      buffer: formatCurrency(
                        costAndBufferDetails?.buffer_amount,
                      ),
                    },
                  )}
                />
                <Text variant="caption">
                  {t('Content.SetupLoanV2.BufferSection.Subtitle')}
                </Text>
              </View>
            ) : null}

            <View
              sx={{
                mt: '$24',
                py: '$8',
                borderTopColor: '$border',
                borderTopWidth: '$1',
              }}
            >
              <Text variant="xsHeader" sx={{ color: '$secondary' }}>
                {t('Content.SetupLoanWithLoanAmount.TotalLoanAmount')}
              </Text>
              <SetupLoanFooter
                screen={screen}
                termInMonths={termInMonths}
                cashoutSelected={cashoutSelected}
                sumTopUpAmount={sumTopUpAmount}
                sumSecurityCosts={
                  costAndBufferDetails?.sum_loan_application_security_costs
                }
                desiredLoanAmount={
                  values[SetupLoanFormWithLoanAmountField.DesiredLoanAmount]
                }
                loanApplicationId={loanApplicationId}
                calculateLoanAmounts={calculateLoanAmounts}
                calculationData={calculationData}
                calculationLoading={calculationLoading}
                mergedLiabilityForRefinanceIds={mergedLiabilityForRefinanceIds}
                productRate={productRate}
              />
            </View>

            <Button
              label={t('Content.Common.ButtonLabel.Done')}
              mt="l"
              alignSelf="stretch"
              testID={TestID.SetupLoanForm.SubmitButton}
              onPress={() => handleSubmit()}
              showSpinner={isSubmitting}
              disabled={
                isSubmitting || calculationLoading || !!minRepaymentError
              }
            />
          </View>
        );
      }}
    </Formik>
  );
}

export const RowView = styled(View)({
  flexDirection: 'row',
});

export const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});

export function SectionHeading({
  containerSx,
  sectionTitle,
  sectionValue,
  sectionValueTestID,
  tooltipContent,
  tooltipTestID,
}: {
  containerSx?: SxProp;
  sectionTitle: string;
  sectionValue?: React.ReactNode;
  sectionValueTestID?: string;
  tooltipContent?: string;
  tooltipTestID?: string;
}) {
  return (
    <RowView sx={{ justifyContent: 'space-between', py: '$8', ...containerSx }}>
      <RowView sx={{ alignItems: 'center' }}>
        <SHeaderText sx={{ mr: tooltipContent ? '$8' : '$0' }}>
          {sectionTitle}
        </SHeaderText>
        {tooltipContent ? (
          <Tooltip content={tooltipContent} testID={tooltipTestID} />
        ) : null}
      </RowView>

      {typeof sectionValue === 'string' ? (
        <SHeaderText sx={{ fontWeight: '400' }} testID={sectionValueTestID}>
          {sectionValue}
        </SHeaderText>
      ) : (
        sectionValue
      )}
    </RowView>
  );
}
