import { useMemo } from 'react';

import { useGetExternalAccountsQuery } from '../../generated/graphql';
import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';

export const useAutopaySetupData = (cbaAccountId: string) => {
  const { loading: externalAccountsLoading, data: externalAccountsData } =
    useGetExternalAccountsQuery();

  const externalAccounts = useMemo(
    () =>
      externalAccountsData?.me?.[0]?.user?.identity_profile
        ?.external_accounts ?? [],
    [externalAccountsData?.me],
  );

  const { loading: loanAccountLoading, data: loanAccountData } =
    useLoanAccountState(cbaAccountId);

  const { loading: loanAccountNameLoading, data: loanAccountNameData } =
    useGetLoanAccountName(cbaAccountId);

  const loading =
    externalAccountsLoading || loanAccountLoading || loanAccountNameLoading;

  const maximumDirectDebitAmount =
    loanAccountData?.maximum_direct_debit_amount ?? 0;

  const {
    accountName: unloanAccountName = '',
    accountBsb: unloanBsb = '',
    accountNumber: unloanAccountNumber = '',
  } = loanAccountNameData ?? {};

  const nextRepayment = loanAccountData?.next_repayment;

  const nextInstallment = loanAccountData?.next_installment;

  return {
    loading,
    externalAccounts,
    maximumDirectDebitAmount,
    unloanAccountName,
    unloanBsb,
    unloanAccountNumber,
    nextRepayment,
    nextInstallment,
    availableRedrawBalance: loanAccountData?.balances?.available_redraw_balance,
  };
};
