import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const IDVIcon = ({ color, size, ...props }: SvgIconProps) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill={color ?? 'none'}
    {...props}
  >
    <Path
      fill="#A3A3A3"
      d="M5.078 17.12c1.357 0 2.077-.742 2.077-2.099V10.23c0-2.057 1.082-3.075 3.054-3.075h4.897c1.357 0 2.099-.742 2.099-2.077 0-1.336-.742-2.078-2.099-2.078H9.954C5.374 3 3 5.353 3 9.87v5.151c0 1.357.742 2.1 2.078 2.1Zm37.844 0c1.357 0 2.078-.742 2.078-2.099V9.87C45 5.353 42.625 3 38.046 3h-5.152c-1.357 0-2.099.742-2.099 2.078 0 1.335.742 2.077 2.099 2.077h4.898c1.95 0 3.052 1.018 3.052 3.075v4.791c0 1.357.743 2.1 2.078 2.1ZM9.954 45h5.152c1.357 0 2.099-.742 2.099-2.078 0-1.335-.742-2.078-2.099-2.078h-4.897c-1.972 0-3.054-1.017-3.054-3.095v-4.791c0-1.357-.742-2.1-2.077-2.1-1.357 0-2.078.743-2.078 2.1v5.151C3 42.647 5.375 45 9.954 45Zm22.94 0h5.152C42.626 45 45 42.647 45 38.11v-5.152c0-1.357-.742-2.1-2.078-2.1-1.357 0-2.078.764-2.078 2.1v4.791c0 2.078-1.102 3.095-3.053 3.095h-4.897c-1.357 0-2.099.743-2.099 2.078 0 1.336.742 2.078 2.099 2.078Z"
    />
    <Path
      fill="#2C2C2C"
      d="M24 24.182c-3.782-.024-6.838-3.01-6.838-7.013 0-3.713 3.056-6.819 6.838-6.819s6.838 3.106 6.838 6.819c0 4.004-3.056 7.037-6.838 7.013ZM12.111 37.65c-1.191 0-1.761-.752-1.761-1.747 0-2.718 4.3-9.731 13.65-9.731 9.35 0 13.65 7.013 13.65 9.73 0 .996-.57 1.748-1.761 1.748H12.11Z"
    />
  </Svg>
);
