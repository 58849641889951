import { useCallback } from 'react';

import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { ExpensesDetailsForm } from '../components/ExpensesDetailsForm';
import { ExpensesWizardScreenProps } from '../navigation/ExpensesWizardNavigator';
import { useExpensesForm } from '../utils/ExpensesFormProvider';
import { CombinedExpensesFormValues } from '../utils/forms';
import {
  useGetExpensesDetailsInitialValues,
  useGetOptionsForExpensesDetails,
} from '../utils/remoteData';

export type Props =
  ExpensesWizardScreenProps<Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS>;

export function ExpensesWizardMonthlyExpensesDetails({
  navigation,
  route,
}: Props) {
  const { loanApplicationId = '', householdId = '' } = route.params || {};

  const { initialValues, loading: initialValuesLoading } =
    useGetExpensesDetailsInitialValues(loanApplicationId, householdId);

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const {
    applicantOptions,
    livingSituationOptions,
    loading: optionsLoading,
  } = useGetOptionsForExpensesDetails({ loanApplicationId, householdId });

  const { setExpenses } = useExpensesForm();

  const onSubmit = useCallback(
    (formVal: CombinedExpensesFormValues) => {
      setExpenses(formVal);
      navigation.navigate(Screen.YOUR_EXPENSES_V2_WIZARD, {
        screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES,
        params: {
          loanApplicationId,
          householdId,
        },
      });
    },
    [householdId, loanApplicationId, navigation, setExpenses],
  );

  const loading = initialValuesLoading || optionsLoading;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToPreviousScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Expense,
      });
    });
  };

  return (
    <ModalScreenContainer
      headerText="Your Expenses"
      hideBackButton
      scrollable
      onClose={navigateBackToPreviousScreen}
    >
      <ExpensesDetailsForm
        screen={Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS}
        initialValues={initialValues}
        onSubmit={onSubmit}
        applicantOptions={applicantOptions}
        livingSituationOptions={livingSituationOptions}
        loading={loading}
      />
    </ModalScreenContainer>
  );
}
