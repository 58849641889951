import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ExpoSecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

type Store = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
};

// Only used in dev environment only.
const devStore: Store = {
  getItem: (key) => AsyncStorage.getItem(key),
  setItem: (key, value) => AsyncStorage.setItem(key, value),
  removeItem: (key) => AsyncStorage.removeItem(key),
};

const sessionStore: Store = {
  getItem: async (key) => sessionStorage.getItem(key),
  setItem: async (key, value) => sessionStorage.setItem(key, value),
  removeItem: async (key) => sessionStorage.removeItem(key),
};

const ATTEMPTS_LIMIT = 3;

const resetKeychain = async (key: string): Promise<void> => {
  await ExpoSecureStore.deleteItemAsync(key);
};

const secureStore: Store = {
  // this is fixing https://github.com/expo/expo/issues/23426
  // could be removed after upgrading to Expo 50
  getItem: async (key) => {
    let attempts = 0;
    while (attempts < ATTEMPTS_LIMIT) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const data = await ExpoSecureStore.getItemAsync(key);

        return data;
      } catch (err) {
        attempts += 1;
      }
    }
    // reset the keychain if keeps failing
    await resetKeychain(key);
    return null;
  },
  setItem: (key, value) => ExpoSecureStore.setItemAsync(key, value),
  removeItem: (key) => ExpoSecureStore.deleteItemAsync(key),
};

type SecureStoreType =
  | {
      isAvailable: false;
    }
  | ({
      isAvailable: true;
    } & Store);

function isSessionStorageAvailable() {
  if (Platform.OS !== 'web') {
    return false;
  }

  const testValue = new Date().toISOString();
  const testKey = '__ss';
  try {
    sessionStorage.setItem(testKey, testValue);
    const storedValue = sessionStorage.getItem(testKey);
    return testValue === storedValue;
  } catch (_: unknown) {
    return false;
  }
}

const isWebDev = Platform.OS === 'web' && __DEV__;
// ExpoSecureStore is only available on iOS or Android.
const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';

function makeSecureStore(): SecureStoreType {
  if (isMobile) {
    return {
      ...secureStore,
      isAvailable: true,
    };
  }
  if (isWebDev) {
    return {
      ...devStore,
      isAvailable: true,
    };
  }
  if (isSessionStorageAvailable()) {
    return {
      ...sessionStore,
      isAvailable: true,
    };
  }
  return { isAvailable: false };
}

export const SecureStore = makeSecureStore();
