import { useNavigation } from '@react-navigation/native';
import { useDripsyTheme } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';
import { Path } from 'react-native-svg';

import { TestID } from '../../../testID/constants';
import { InsightGraphContainer } from '../../HomeLoanInsights/components/InsightGraphContainer';
import { TermRemainingInsightGraph } from '../../HomeLoanInsights/components/TermRemaining/TermRemainingInsightGraph';
import { useLoanAccountTermRemainingInsight } from '../../HomeLoanInsights/hooks/useLoanAccountTermRemainingInsight';
import { Screen } from '../../navigation/types/screens';
import { formatLoanTerm } from '../../utils/stringHelpers';
import { HomeLoanLoadingInsight } from './HomeLoanLoadingInsight';
import { InsightWidgetContainer } from './InsightWidgetContainer';

type Props = Partial<React.ComponentProps<typeof InsightWidgetContainer>> & {
  disabled?: boolean;
  loading?: boolean;
  remainingTermsInMonths?: number;
  cbaAccountId: string;
};

const DEFAULT_GRAY_PATH_FOR_DISABLED_STATE =
  'M 0,0 1.42,0.49 4.82,1.26 8.22,2.08 11.61,2.94 15.01,3.85 18.41,4.79 21.81,5.79 25.21,6.84 28.61,7.95 32.01,9.1 35.41,10.32 38.81,11.61 42.21,12.96 45.61,14.37 49.01,15.86 52.41,17.42 55.81,19.07 59.21,20.79 62.61,22.61 66.01,24.52 69.41,26.52 72.8,28.63 76.2,30.85 79.6,33.18 83,35.63 86.4,38.2 89.8,40.91 93.2,43.75 96.6,46.74 99.15,49.08 100,50';

export const InsightsTermRemainingWidget = ({
  disabled = false,
  cbaAccountId,
  remainingTermsInMonths,
  loading: initialLoading = false,
  onPress,
  ...props
}: Props) => {
  const { theme } = useDripsyTheme();
  const navigation = useNavigation();

  const { data, loading: isLoadingInsightsData } =
    useLoanAccountTermRemainingInsight(cbaAccountId);

  const loading = initialLoading || isLoadingInsightsData;

  const openTermRemainingInsightModal = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN_INSIGHTS_MODAL, {
      screen: Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  if (loading) {
    return <HomeLoanLoadingInsight />;
  }

  return (
    <InsightWidgetContainer
      testID={TestID.Insights.TermRemaining.WidgetContainer}
      onPress={onPress || openTermRemainingInsightModal}
      disabled={disabled}
      header={formatLoanTerm(remainingTermsInMonths)}
      caption={t('Content.HomeLoan.Insights.TermRemaining.Header')}
      graphPlaceholder={
        disabled ? (
          <InsightGraphContainer
            showBackgroundDotsLine
            numOfDotsLines={4}
            viewBox="-2 -2 104 54"
            testID={TestID.Insights.TermRemaining.DisabledLineGraph}
          >
            <Path
              transform=""
              d={DEFAULT_GRAY_PATH_FOR_DISABLED_STATE}
              strokeWidth={1}
              fill="none"
              opacity={0.33}
              stroke={theme.colors.$gray}
              strokeLinecap="round"
              strokeDasharray="120"
            />
          </InsightGraphContainer>
        ) : (
          <TermRemainingInsightGraph
            numOfDotsLines={4}
            data={data}
            hideLabels
          />
        )
      }
      {...props}
    />
  );
};
