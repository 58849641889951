import type { CompositeNavigationProp } from '@react-navigation/core';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';

import { AddManualLiabilityV2 } from '../DebtsWizard/screens/AddManualLiabilityV2';
import { EditLiabilityV2 } from '../DebtsWizard/screens/EditLiabilityV2';
import { NoDetectedDebt } from '../DebtsWizard/screens/NoDetectedDebt';
import { MockAccountModal } from '../DevTools/MockAccountModal';
import { FinancialDeclarationBasic } from '../FinancialDeclaration/screens/FinancialDeclarationBasicV2';
import { AMLCTFActModalV2 } from '../Identification/screens/AMLCTFActModalV2';
import { ManualIdentityVerificationModalV2 } from '../Identification/screens/ManualIdentityVerificationModalV2';
import { UnableToVerifyYourIdentityViewMoreModal } from '../Identification/screens/UnableToVerifyYourIdentityViewMoreModal';
import { VerifyingYourIdentityLearnMoreModalV2 } from '../Identification/screens/VerifyingYourIdentityLearnMoreModalV2';
import { RentalExpensesLearnMoreModalV2 } from '../Income/screens/RentalExpensesLearnMoreModalV2';
import { IncomeSupportingDocumentUpload } from '../LoanApplication/screens/IncomeSupportingDocumentUpload';
import { LiabilitySupportingDocumentUpload } from '../LoanApplication/screens/LiabilitySupportingDocumentUpload';
import { PrivacyCollectionStatementModal } from '../LoanApplication/screens/PrivacyCollectionStatementModal';
import { SecuritySupportingDocumentUpload } from '../LoanApplication/screens/SecuritySupportingDocumentUpload';
import { SelectRefiReasonModalV2 } from '../LoanConfiguration/screens/standalone/SelectRefiReasonStandaloneModalV2';
import { SetupLoanPurchase } from '../LoanConfiguration/screens/standalone/SetupLoanPurchase';
import { SetupLoanStandaloneModalV2 } from '../LoanConfiguration/screens/standalone/SetupLoanStandaloneModalV2';
import { SetupLoanTopUp } from '../LoanConfiguration/screens/standalone/SetupLoanTopUp';
import { NewFeaturesModal } from '../NewFeatures/screens/NewFeaturesModal';
import { PaymentFailureModal } from '../PaymentFailure/screens/PaymentFailureModal';
import { UpdateAddressModal } from '../PersonalDetails/screens/UpdateAddressModal';
import { PropertyDetailsPurchase } from '../PropertyDetails/screens/PropertyDetailsPurchase';
import { PropertyDetailsV2 } from '../PropertyDetails/screens/PropertyDetailsV2';
import { TopUpPropertyValueModal } from '../PropertyDetails/screens/TopUpPropertyValueModal';
import { DefaultModalV2NavigatorOptions } from '../utils/NavConstants';
import {
  AppStackNavigationProps,
  RouteProp,
  SingleModalNavigatorStackParams,
} from './types/navTypes';
import { getPageTitle, Screen } from './types/screens';

export type SingleModalNavigationProp =
  AppStackNavigationProps<Screen.SINGLE_V2_MODAL>;

export type SingleModalNavigationScreenProp<
  T extends keyof SingleModalNavigatorStackParams,
> = CompositeNavigationProp<
  StackNavigationProp<SingleModalNavigatorStackParams, T>,
  SingleModalNavigationProp
>;
export type SingleModalStackRoute<
  T extends keyof SingleModalNavigatorStackParams,
> = RouteProp<SingleModalNavigatorStackParams, T>;

const SingleModalStack =
  createStackNavigator<SingleModalNavigatorStackParams>();

export function SingleModalNavigator() {
  return (
    <SingleModalStack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
      <SingleModalStack.Screen
        name={Screen.YOUR_PROPERTY_V2_MODAL}
        component={PropertyDetailsV2}
        options={{
          title: getPageTitle(Screen.YOUR_PROPERTY_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.YOUR_PROPERTY_PURCHASE_MODAL}
        component={PropertyDetailsPurchase}
        options={{
          title: getPageTitle(Screen.YOUR_PROPERTY_PURCHASE_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.TOP_UP_PROPERTY_VALUE_MODAL}
        component={TopUpPropertyValueModal}
        options={{
          title: getPageTitle(Screen.TOP_UP_PROPERTY_VALUE_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL}
        component={NoDetectedDebt}
        options={{
          title: getPageTitle(Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL}
        component={AddManualLiabilityV2}
        options={{
          title: getPageTitle(Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.DEBTS_EDIT_LIABILITY_V2_MODAL}
        component={EditLiabilityV2}
        options={{
          title: getPageTitle(Screen.DEBTS_EDIT_LIABILITY_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.FINANCIAL_DECLARATION_MODAL_V2}
        component={FinancialDeclarationBasic}
        options={{
          title: getPageTitle(Screen.FINANCIAL_DECLARATION_MODAL_V2),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.SELECT_REFI_REASON_V2_MODAL}
        component={SelectRefiReasonModalV2}
        options={{
          title: getPageTitle(Screen.SELECT_REFI_REASON_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.SETUP_LOAN_V2_MODAL}
        component={SetupLoanStandaloneModalV2}
        options={{
          title: getPageTitle(Screen.SETUP_LOAN_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.SETUP_LOAN_FOR_PURCHASE_MODAL}
        component={SetupLoanPurchase}
        options={{
          title: getPageTitle(Screen.SETUP_LOAN_FOR_PURCHASE_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.SETUP_LOAN_FOR_TOP_UP_MODAL}
        component={SetupLoanTopUp}
        options={{
          title: getPageTitle(Screen.SETUP_LOAN_FOR_TOP_UP_MODAL),
        }}
      />

      {/* V2 Learn more modals */}
      <SingleModalStack.Screen
        name={Screen.PRIVACY_COLLECTION_STATEMENT_MODAL}
        component={PrivacyCollectionStatementModal}
        options={{
          title: getPageTitle(Screen.PRIVACY_COLLECTION_STATEMENT_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL}
        component={RentalExpensesLearnMoreModalV2}
        options={{
          title: getPageTitle(Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.AML_CTF_ACT_V2_MODAL}
        component={AMLCTFActModalV2}
        options={{
          title: getPageTitle(Screen.AML_CTF_ACT_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL}
        component={VerifyingYourIdentityLearnMoreModalV2}
        options={{
          title: getPageTitle(Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL}
        component={UnableToVerifyYourIdentityViewMoreModal}
        options={{
          title: getPageTitle(Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.MANUAL_VERIFICATION_V2_MODAL}
        component={ManualIdentityVerificationModalV2}
        options={{
          title: getPageTitle(Screen.MANUAL_VERIFICATION_V2_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.INCOME_SUPPORTING_DOCUMENT_MODAL}
        component={IncomeSupportingDocumentUpload}
        options={{
          title: getPageTitle(Screen.INCOME_SUPPORTING_DOCUMENT_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL}
        component={SecuritySupportingDocumentUpload}
        options={{
          title: getPageTitle(Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL}
        component={LiabilitySupportingDocumentUpload}
        options={{
          title: getPageTitle(Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL),
        }}
      />
      <SingleModalStack.Screen
        name={Screen.UPDATE_ADDRESS_MODAL}
        component={UpdateAddressModal}
        options={{
          title: getPageTitle(Screen.UPDATE_ADDRESS_MODAL),
        }}
      />

      <SingleModalStack.Screen
        name={Screen.NEW_FEATURES_MODAL}
        component={NewFeaturesModal}
        options={{
          title: getPageTitle(Screen.NEW_FEATURES_MODAL),
        }}
      />

      <SingleModalStack.Screen
        name={Screen.MOCK_ACCOUNT_MODAL}
        component={MockAccountModal}
        options={{
          title: getPageTitle(Screen.MOCK_ACCOUNT_MODAL),
        }}
      />

      <SingleModalStack.Screen
        name={Screen.PAYMENT_FAILURE_MODAL}
        component={PaymentFailureModal}
        options={{
          title: getPageTitle(Screen.PAYMENT_FAILURE_MODAL),
        }}
      />
    </SingleModalStack.Navigator>
  );
}
