import { createStackNavigator } from '@react-navigation/stack';

import { YourIncomeModalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { AddExpectedRentalIncomeScreen } from '../screens/AddExpectedRentalIncomeScreen';
import { AddNewIncomeScreen } from '../screens/AddNewIncomeScreen';
import { EditDividendIncomeScreen } from '../screens/EditDividendIncomeScreen';
import { EditEmploymentIncomeScreen } from '../screens/EditEmploymentIncomeScreen';
import { EditExpectedRentalIncomeScreen } from '../screens/EditExpectedRentalIncomeScreen';
import { EditGovernmentIncomeScreen } from '../screens/EditGovernmentIncomeScreen';
import { EditRentalIncomeScreen } from '../screens/EditRentalIncomeScreen';

const YourIncomeModalStackNavigator =
  createStackNavigator<YourIncomeModalStackParams>();

export function YourIncomeModalNavigator() {
  return (
    <YourIncomeModalStackNavigator.Navigator
      screenOptions={DefaultModalV2NavigatorOptions}
    >
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL}
        component={AddNewIncomeScreen}
        options={{
          title: getPageTitle(Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL}
        component={EditEmploymentIncomeScreen}
        options={{
          title: getPageTitle(
            Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL,
          ),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL}
        component={EditExpectedRentalIncomeScreen}
        options={{
          title: getPageTitle(
            Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL,
          ),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL}
        component={EditRentalIncomeScreen}
        options={{
          title: getPageTitle(Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL}
        component={EditGovernmentIncomeScreen}
        options={{
          title: getPageTitle(
            Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL,
          ),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL}
        component={EditDividendIncomeScreen}
        options={{
          title: getPageTitle(Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL),
        }}
      />
      <YourIncomeModalStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL}
        component={AddExpectedRentalIncomeScreen}
        options={{
          title: getPageTitle(
            Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL,
          ),
        }}
      />
    </YourIncomeModalStackNavigator.Navigator>
  );
}
