import { Text, View } from 'dripsy';
import * as React from 'react';

import { TestID } from '../../../../testID/constants';
import { Box } from '../../../ui/atoms/Box';

export type DiscountGraphContentProps = {
  isAnniversaryPeriod: boolean;
  interestRateDiscount: string;
  discountBumpInDays: number;
};

export const DiscountGraphContent: React.FC<DiscountGraphContentProps> = ({
  isAnniversaryPeriod,
  interestRateDiscount,
  discountBumpInDays,
}) => (
  <Box position="absolute" left={0} right={0} top={0} bottom={0} centered>
    <Text
      variant="sBody"
      testID={TestID.Insights.DiscountBump.DiscountHeader}
      sx={{
        textAlign: 'center',
        fontWeight: 'medium',
      }}
    >
      {isAnniversaryPeriod
        ? t(
            'Content.HomeLoan.Insights.DiscountBump.InAnniversaryPeriod.DiscountIncreasedTo',
          )
        : t(
            'Content.HomeLoan.Insights.DiscountBump.NotInAnniversaryPeriod.DiscountBumpIn',
          )}
    </Text>
    {isAnniversaryPeriod ? (
      <View sx={{ flexDirection: 'row' }}>
        <Text
          variant="lHeader"
          testID={TestID.Insights.DiscountBump.DiscountData}
        >
          {interestRateDiscount}
        </Text>
        <Text variant="xsHeader">p.a.</Text>
      </View>
    ) : (
      <Text
        variant="lHeader"
        testID={TestID.Insights.DiscountBump.DiscountData}
      >
        {t(
          'Content.HomeLoan.Insights.DiscountBump.NotInAnniversaryPeriod.DaysUntilDiscount',
          {
            days: discountBumpInDays,
          },
        )}
      </Text>
    )}
  </Box>
);
