import { gql } from '@apollo/client';

import { useGetLoanApplicationTargetTopupAmountQuery } from '../../../generated/graphql';

export const CalculateTotalLoanAmountV3 = gql`
  query CalculateAppSetupLoanApplicationAmountsV3(
    $data: calculate_app_total_loan_amount_v2_input!
    $loanApplicationId: uuid!
    $desiredLoanAmount: Float!
    $liabilityIds: [String!]!
    $loanTerm: Int!
  ) {
    calculate_app_total_loan_amount_v2(data: $data) {
      ...CalculateAppTotalLoanAmountV2Output
    }

    get_savings_calculation(
      loan_application_id: $loanApplicationId
      desired_loan_amount: $desiredLoanAmount
      liability_ids: $liabilityIds
      loan_term: $loanTerm
    ) {
      savings
      merged_liabilities {
        id
        interest_rate
      }
      savings_calculation_error_type
    }
  }
`;

/**
 *  Used in the top up flow which does not include a savings calculation.
 */
export const CalculateTotalLoanAmountV2 = gql`
  query CalculateAppSetupLoanApplicationAmountsV2(
    $data: calculate_app_total_loan_amount_v2_input!
  ) {
    calculate_app_total_loan_amount_v2(data: $data) {
      ...CalculateAppTotalLoanAmountV2Output
    }
  }
`;

export const CALCULATE_APP_TOTAL_LOAN_AMOUNT_V2_OUTPUT_FRAGMENT = gql`
  fragment CalculateAppTotalLoanAmountV2Output on calculate_app_total_loan_amount_v2_output {
    is_top_up_less_than_min
    interest_rate
    max_loan_amount
    min_loan_amount
    min_repayment_error_type
    min_top_up_amount
    monthly_repayment_amount
    total_loan_amount
  }
`;

export const GetLoanApplicationTargetTopupAmount = gql`
  query GetLoanApplicationTargetTopupAmount($loanApplicationId: uuid!) {
    loan_application_target(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      top_up_amount
    }
  }
`;

export function useGetExistingTopupAmount({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const {
    data: loanApplicationTargetData,
    loading: loanApplicationTargetLoading,
  } = useGetLoanApplicationTargetTopupAmountQuery({
    variables: {
      loanApplicationId,
    },
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const topUpAmount =
    loanApplicationTargetData?.loan_application_target[0]?.top_up_amount ?? 0;

  const showOldCashoutUi = topUpAmount > 0;

  return {
    showOldCashoutUi,
    topUpAmount,
    loading: loanApplicationTargetLoading,
  };
}
