import { Suspense, useState } from 'react';
import { Platform } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { TestID } from '../../../../testID/constants';
import { useAndroidInstallPwaEvent } from '../../../Home/navigation/utils/useAndroidInstallPwaEvent';
import {
  DataRow,
  DataRowGroup,
  DataRowGroupProps,
} from '../../molecules/DataRow';
import { InstallPwaRules } from './InstallPwaRules';
import {
  pwaInstallBannerVisibleAtom,
  pwaInstallTooltipVisibleAtom,
} from './recoil/pwaInstall';

type InstallPwaListRowProps = DataRowGroupProps & { loading?: boolean };

function BaseInstallPwaListRow({ loading, ...props }: InstallPwaListRowProps) {
  const isBannerVisible = useRecoilValue(pwaInstallBannerVisibleAtom);
  const setIsTooltipVisible = useSetRecoilState(pwaInstallTooltipVisibleAtom);

  const androidInstallPwaEvent = useAndroidInstallPwaEvent();

  const [isAndroidPwaInstallAccepted, setIsAndroidPwaInstallAccepted] =
    useState(false);

  const onInstallPress = async () => {
    if (androidInstallPwaEvent) {
      await androidInstallPwaEvent.prompt();

      const { outcome } = await androidInstallPwaEvent.userChoice;

      setIsAndroidPwaInstallAccepted(outcome === 'accepted');
    } else {
      setIsTooltipVisible(true);
    }
  };

  if (isBannerVisible) {
    return null;
  }

  return (
    <InstallPwaRules>
      <DataRowGroup {...props}>
        <DataRow
          loading={loading}
          label={t('Content.Settings.UnloanApp.Title')}
          iconName="download"
          iconFamilyName="svg"
          iconColor="accent"
          onRightButtonPress={onInstallPress}
          rightButtonTestID={TestID.Settings.InstallApp}
          rightButtonLabel="Install"
          sublabels={[t('Content.Settings.UnloanApp.SupportingText')]}
          sublabelsColor="secondaryContent"
          disabled={isAndroidPwaInstallAccepted}
        />
      </DataRowGroup>
    </InstallPwaRules>
  );
}

export function InstallPwaListRow(props: InstallPwaListRowProps) {
  if (Platform.OS !== 'web') {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <BaseInstallPwaListRow {...props} />
    </Suspense>
  );
}
