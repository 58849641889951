import Svg, { Path, Rect } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function ManageDataIcon({ size, color, ...otherProps }: SvgIconProps) {
  const width = resizeIconWidth(24, 24, size);
  return (
    <Svg width={width} height={width} fill="none" {...otherProps}>
      <Path
        d="M3 16a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2"
        stroke={color}
        strokeWidth={2}
      />
      <Rect
        x={4}
        y={6}
        width={19}
        height={14}
        rx={2}
        stroke={color}
        strokeWidth={2}
      />
      <Path
        d="M9 14.233C9 15.894 10.07 17 12.038 17h.854a.427.427 0 0 0 .447-.437.426.426 0 0 0-.447-.431h-.854c-1.447 0-2.172-.842-2.172-1.948 0-1.106.725-1.888 2.172-1.888h2.655l.995-.048-.769.641-1.14 1.112a.39.39 0 0 0-.118.291c0 .254.172.431.43.431a.48.48 0 0 0 .312-.134l2.457-2.4a.446.446 0 0 0 .14-.33.435.435 0 0 0-.14-.324l-2.457-2.4A.454.454 0 0 0 14.091 9c-.258 0-.43.173-.43.426 0 .124.043.221.119.297l1.14 1.111.768.642-.995-.049h-2.57c-2.015 0-3.123 1.15-3.123 2.806Z"
        fill={color}
        stroke={color}
        strokeWidth={0.7}
      />
    </Svg>
  );
}
