import { gql } from '@apollo/client';

import {
  ApprovalRequestFragmentDoc,
  HasuraAddressFragmentDoc,
} from '../../generated/graphql';

export const APPROVAL_REQUEST_FRAGMENT = gql`
  fragment ApprovalRequest on approval_request {
    id
    status
    approval_type
    requested_by {
      id
      user_id
      full_name
    }
    my_approval {
      response {
        status
      }
    }
    responses {
      id
      status
      identity_profile {
        id
        full_name
        first_name
      }
    }
  }
`;

export const INLIFE_PAYMENT_REQUEST_FRAGMENT = gql`
  fragment PaymentRequest on payment_request {
    id
    status
    type
    created_at
    cba_account_id
    cba_receipt_id
    withdrawal_request {
      description
      reference
      created_at
      external_account {
        account_name
        masked_account_number
        account_name
        account_bsb
        account_number
      }
      amount
      approval_request {
        ...ApprovalRequest
      }
    }
  }

  ${ApprovalRequestFragmentDoc}
`;

export const APPLICANT_STATUS_INFO_FRAGMENT = gql`
  fragment ApplicantStatusInfo on applicant {
    id
    myRole: role
    myStatusInfo: status_info {
      id
      myStatus: applicant_status
    }
    myIncomeVerificationMethod: income_verification_method
    incomeVerificationMethodSelectedAt: income_verification_method_selected_at
    conditionalApprovalEvaluatedAt: conditional_approval_evaluated_at
    # fms_esign_signer_summary remote schema needs to be fixed and secured
    # before it can be used.
    # myEsignSignerSummary: fms_esign_signer_summary {
    #   myPortalUrl: portal_url
    # }
    loanApplication: loan_application {
      id
      type
      applicants {
        id
        latestFirstName: latest_first_name
        isCurrent: is_current_logged_in_applicant
        statusInfo: status_info {
          id
          applicantStatus: applicant_status
        }
      }
      statusInfo: status_info {
        id
        loanApplicationStage: loan_application_stage
        loanApplicationStatus: loan_application_status
        settlementBookedAt: settlement_booked_at
      }
      loanApplicationTargets: loan_application_targets {
        id
        cbaAccountId: cba_account_id
      }
      loanApplicationSecurities: loan_application_securities {
        id
        property {
          id
          address {
            id
            shortAddressFormat: short_address_format
          }
        }
      }
      fms_deals {
        id
        documents_delivery_method
        pexa_workspaces {
          id
          acceptance_status
          workspace_id
        }
      }
      docusign_envelope {
        id
        envelope_id
      }
      activeLoanApplicationTaskRequests: loan_application_task_requests(
        where: { status: { _eq: STARTED } }
      ) {
        id
        status
        loan_application {
          id
          loan_application_securities {
            id
            property {
              id
              address {
                id
                short_address_format
              }
            }
          }
          financial_serviceability_policies(
            where: { policy_type: { _eq: "DEFAULT" } }
          ) {
            id
            policy_type
            conditionally_approved_amount
          }
        }
      }
    }
  }
  ${HasuraAddressFragmentDoc}
`;
