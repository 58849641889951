import { Picker } from '@react-native-picker/picker';
import { forwardRef, ReactNode, useImperativeHandle, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import Collapsible from 'react-native-collapsible';

import { Box } from '../../ui/atoms/Box';
import { useTheme } from '../../ui/theme';
import { useFormCollapsible } from '../../utils/hooks/useFormCollapsible';
import {
  extractTermInMonths,
  generateMonthTermOptions,
  generateYearTermOptions,
  getTermPickerLabel,
  MAX_YEAR,
  MONTHS_IN_A_YEAR,
} from '../../utils/pickerHelpers';
import { FocusingInputRef, FormikControlProps } from './types';

export type Props = FormikControlProps<number> & {
  /** Scroll Picker Trigger */
  children: (props: {
    isPickerOpened: boolean;
    selectedLabel: string;
    onPress: () => void;
  }) => ReactNode;
  onPress?: () => void;
  collapsed?: boolean;
  name?: string;
  parentCollapsibleFieldName?: string;
  showMonthPicker?: boolean;
  placeholder?: string;
  /**
   * Set the maximum value for term (in months), by default will use the max year.
   */
  max?: number;
  /** Test ID for year selector */
  yearPickerTestID?: string;
  /** Test ID for month selector */
  monthPickerTestID?: string;
};

const MONTH_TERM_OPTIONS = generateMonthTermOptions();

export const TermPicker = forwardRef<FocusingInputRef, Props>(
  (
    {
      value,
      children,
      onPress,
      collapsed: collapsedProp,
      setFieldValue,
      name,
      parentCollapsibleFieldName,
      showMonthPicker,
      placeholder,
      max = MAX_YEAR * MONTHS_IN_A_YEAR,
      yearPickerTestID,
      monthPickerTestID,
    },
    ref,
  ) => {
    const YEAR_TERM_OPTIONS = useMemo(
      () =>
        generateYearTermOptions({
          startFromZero: showMonthPicker,
          maxYear: MAX_YEAR,
        }),
      [showMonthPicker],
    );
    const { isPickerOpened, onPressWrapped, setPickerOpened } =
      useFormCollapsible({
        collapsedProp,
        name,
        onPress,
        optionsLength: YEAR_TERM_OPTIONS.length || MONTH_TERM_OPTIONS.length,
        parentCollapsibleFieldName,
      });

    const { yearInMonths, remainderMonths } = extractTermInMonths(value);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {},
        blur: () => {
          setPickerOpened(false);
        },
      }),
      [setPickerOpened],
    );
    const theme = useTheme();

    return (
      <>
        {children({
          isPickerOpened,
          selectedLabel: getTermPickerLabel(value, placeholder),
          onPress: onPressWrapped,
        })}
        <Box width="100%">
          <Collapsible collapsed={!isPickerOpened}>
            <Box flexDirection="row">
              <Picker
                selectedValue={yearInMonths}
                onValueChange={(selectedYearInMonths) => {
                  if (selectedYearInMonths >= max) {
                    setFieldValue?.(max);
                    return;
                  }
                  setFieldValue?.(selectedYearInMonths + remainderMonths);
                }}
                style={styles.picker}
                testID={yearPickerTestID}
              >
                {YEAR_TERM_OPTIONS.map(({ value: itemValue, label }) => (
                  <Picker.Item
                    label={label}
                    value={itemValue}
                    key={itemValue}
                    color={theme.colors.primaryContent}
                  />
                ))}
              </Picker>
              {showMonthPicker ? (
                <Picker
                  selectedValue={remainderMonths}
                  onValueChange={(selectedMonth) => {
                    const newValue = selectedMonth + yearInMonths;
                    if (newValue >= max) {
                      setFieldValue?.(max);
                      return;
                    }
                    setFieldValue?.(selectedMonth + yearInMonths);
                  }}
                  style={styles.picker}
                  enabled={value != null ? value <= max : undefined}
                  testID={monthPickerTestID}
                >
                  {MONTH_TERM_OPTIONS.map(({ value: itemValue, label }) => (
                    <Picker.Item
                      label={label}
                      value={itemValue}
                      key={itemValue}
                      color={theme.colors.primaryContent}
                    />
                  ))}
                </Picker>
              ) : null}
            </Box>
          </Collapsible>
        </Box>
      </>
    );
  },
);

const styles = StyleSheet.create({
  picker: {
    flex: 1,
  },
});
