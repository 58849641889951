import { useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Card, Props as CardProps } from '../../ui/atoms/Card';
import { Skeleton } from '../../ui/v2/Skeleton';
import { LoanCircularProgressBar } from './LoanCircularProgressBar';

const DEFAULT_LOAN_DATA = {
  principalBalance: 0,
  availableRedrawBalance: 0,
  balance: 0,
};

export function DashboardLoadingLoanCard(props: CardProps) {
  const { theme } = useDripsyTheme();

  return (
    <Card
      borderRadius="card"
      testID={TestID.Dashboard.LoanAccountLoading}
      shadowed
      {...props}
    >
      <View sx={{ px: '$16', pt: '$16', pb: '$60' }}>
        <View sx={{ mb: '$24' }}>
          <Skeleton show width={134} height={theme.space.$20} />
        </View>

        <LoanCircularProgressBar loading loanData={DEFAULT_LOAN_DATA} />
      </View>
    </Card>
  );
}
