import { Text, useDripsyTheme, useSx } from 'dripsy';
import { useCallback, useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useBorrowerWizardState } from '../../Identification/components/BorrowerFormContext';
import { currentLoggedInBorrowerDetailsV2Atom } from '../../Identification/currentLoggedInBorrowerDetailsAtom';
import { learnMoreReturnScreenAtom } from '../../Identification/learnMoreReturnScreenAtom';
import { IdentificationV2ScreenProps } from '../../Identification/navigation/types';
import { handleIdentityCheckResult } from '../../Identification/utils/handleIdentityCheckResult';
import {
  getUpdateBorrowerErrorMessage,
  useUpdateBorrower,
} from '../../Identification/utils/useUpdateBorrower';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { Link } from '../../ui/atoms/Link';
import { CoupleIcon } from '../../ui/svgs/CoupleIcon';
import { ActionSheet } from '../components/ActionSheet';
import { IDVerificationConsentActionSheetErrorAtom } from '../recoil/ActionSheetErrors';

type Props =
  IdentificationV2ScreenProps<Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT>;

export function IDVerificationConsentActionSheetV2({
  navigation,
  route,
}: Props) {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  const { flags } = useContext(FeatureFlagsContext);

  /** This error atom will be used on BorrowerDriverLicense modal */
  const setUpdateBorrowerErrorMessage = useSetRecoilState(
    IDVerificationConsentActionSheetErrorAtom,
  );
  const { values, setValues } = useBorrowerWizardState();
  const loanApplicationId = values.loanApplicationId ?? '';

  const { updateBorrower, updateBorrowerLoading } = useUpdateBorrower({
    loanApplicationId,
  });

  const navigateToYourInformation = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Screen.IDENTIFICATION_V2_YOUR_INFORMATION,
            params: {
              loanApplicationId,
            },
          },
        ],
      }),
    [loanApplicationId, navigation],
  );

  const navigateToIdvPendingActionSheet = useCallback(
    () =>
      navigation.navigate(Screen.IDENTITY_VERIFICATION_PENDING_V2, {
        loanApplicationId,
      }),
    [loanApplicationId, navigation],
  );

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateToBorrowers = useCallback(
    () =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Borrowers,
      }),
    [navigateToLoanApplicationScreen],
  );

  const onContinuePress = useCallback(async () => {
    /**
     * This block should be unreachable unless we reach this screen
     * using unintended method
     */
    if (values.loanApplicationId == null || values.applicantId == null) {
      setUpdateBorrowerErrorMessage(
        t('Content.Common.Error.NoAssociatedLoanApplication'),
      );
      captureException(
        'Unable to store IDV Consent because there is no associated loan application / applicant',
        {
          loanApplicationId,
          applicantId: values.applicantId,
        },
      );
      return;
    }

    const { data, error } = await updateBorrower({
      values,
      isManualKyc: false,
    });

    if (data) {
      setValues({ identityCheckResult: data.identity_check_result });

      handleIdentityCheckResult({
        data,
        navigateToYourInformation,
        navigateToIdvPendingActionSheet,
        navigateToBorrowers,
        isSummary: false,
      });
      return;
    }

    setUpdateBorrowerErrorMessage(getUpdateBorrowerErrorMessage(error));

    // On error, go back to driver license modal
    // Note: We can explicitly declare navigate back to
    //       Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE here.
    //       But by doing so, we'll need to cast/parse to make sure all params
    //       are available
    navigation.goBack();
  }, [
    loanApplicationId,
    navigateToBorrowers,
    navigateToIdvPendingActionSheet,
    navigateToYourInformation,
    navigation,
    setUpdateBorrowerErrorMessage,
    setValues,
    updateBorrower,
    values,
  ]);

  const onPressActModal = () => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.AML_CTF_ACT_V2_MODAL,
    });
  };

  const setCurrentLoggedInBorrowerDetails = useSetRecoilState(
    currentLoggedInBorrowerDetailsV2Atom(loanApplicationId),
  );
  const setLearnMoreReturnScreenAtom = useSetRecoilState(
    learnMoreReturnScreenAtom(loanApplicationId || ''),
  );

  const onLearnMorePress = () => {
    setCurrentLoggedInBorrowerDetails(values);
    setLearnMoreReturnScreenAtom(
      Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT,
    );

    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL,
      params: { loanApplicationId },
    });
  };

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={updateBorrowerLoading}
      title={t('Consent.IDVerification.Title')}
      message={
        <Message
          onPressActModal={onPressActModal}
          onLearnMorePress={onLearnMorePress}
          allowManualKYC={flags.ENABLE_MANUAL_KYC}
        />
      }
      footerText={
        <Text variant="caption" sx={{ pt: '$8', textAlign: 'center' }}>
          <Text variant="caption">{t('Consent.IDVerification.Footer')}</Text>
          <Link
            style={sx({ variant: 'text.caption', color: '$link' })}
            href={t('Link.PrivacyPolicy')}
          >
            {t('Consent.IDVerification.FooterInlinePrivacyPolicyLink')}
          </Link>
          <Text variant="caption">{t('Consent.IDVerification.Footer2')}</Text>
        </Text>
      }
      actionList={[
        {
          label: t('Consent.Common.Consent'),
          onPress: onContinuePress,
          showSpinner: updateBorrowerLoading,
          disabled: updateBorrowerLoading,
        },
      ]}
    >
      <Box centered pt="m">
        <CoupleIcon size={theme.iconSizes.$coupleIDV} />
      </Box>
    </ActionSheet>
  );
}

function Message({
  onPressActModal,
  onLearnMorePress,
  allowManualKYC,
}: {
  onPressActModal?: () => void;
  onLearnMorePress: () => void;
  allowManualKYC?: boolean;
}) {
  const sx = useSx();
  return (
    <ActionSheet.MessageText style={sx({ variant: 'text.sBody' })}>
      {t('Consent.IDVerification.EnsureObligations')}
      <ActionSheet.MessageText
        style={sx({ variant: 'text.sBody', color: '$link' })}
        onPress={onPressActModal}
        testID={TestID.IDVerificationConsentActionSheet.AmlCtfLink}
      >
        {t('Consent.IDVerification.AMLCTFAct')}
      </ActionSheet.MessageText>
      {t('Consent.IDVerification.ConfirmIdentity')}
      {allowManualKYC ? (
        <ActionSheet.MessageText
          style={sx({ variant: 'text.sBody', color: '$link' })}
          onPress={onLearnMorePress}
          testID={TestID.IDVerificationConsentActionSheet.ManualKYCLink}
        >
          {` `}
          {t('Content.Common.ButtonLabel.LearnMore')}
        </ActionSheet.MessageText>
      ) : null}
    </ActionSheet.MessageText>
  );
}
