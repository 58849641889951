export enum ENVIRONMENT {
  PROD_V3 = 'prod-v3',
  STAGING_V3 = 'staging-v3',
  TEST_V3 = 'test-v3',
  DEV_V3 = 'dev-v3',
  LOCAL_DEV = 'local-dev',
}

const appManifest = process.env?.APP_MANIFEST as {
  extra?: {
    useAuth0Sandbox?: boolean;
  };
};
// Expo handles env variables differently in web and native
// https://docs.expo.dev/guides/environment-variables/
export const useAuth0Sandbox =
  appManifest?.extra?.useAuth0Sandbox ||
  process.env.EXPO_PUBLIC_UNLOAN_AUTH0_SANDBOX === 'true';

const native = process.env.EXPO_PUBLIC_NATIVE;
export const isNative = native === 'true';
export const expoPublicEnv = process.env.EXPO_PUBLIC_UNLOAN_ENV;
export const env = (expoPublicEnv ?? ENVIRONMENT.LOCAL_DEV) as ENVIRONMENT;
