import { Text, View } from 'dripsy';
import { ComponentProps, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { LoanVariationStatusCard } from '../../LoanVariation/components/LoanVariationStatusCard';
import { useGetPendingLoanVariationRequest } from '../../LoanVariation/utils/useGetPendingLoanVariationRequest';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { formatCurrency } from '../../utils/currencyHelpers';

export type Props = {
  loading: boolean;
  cbaAccountId: string;
  redrawAmount: number;
  nextRepaymentAmount: number;
  onPress?: ComponentProps<typeof DataRow>['onPress'];
  navigateToLoanVariationApprovalPage: () => void;
};

export const LoanVariationSection = ({
  loading,
  cbaAccountId,
  redrawAmount,
  nextRepaymentAmount,
  onPress,
  navigateToLoanVariationApprovalPage,
}: Props) => {
  const {
    loading: loanVariationRequestLoading,
    loanVariationRequest,
    isLoanVariationRequestProcessing,
  } = useGetPendingLoanVariationRequest(cbaAccountId);

  const isLoading = loading || loanVariationRequestLoading;

  /*
    Should be able to reduce payments if
    - redraw balance is available
    - redraw balance is greater than next repayment amount
  */
  const canReduceRepayments = useMemo(() => {
    const redrawBalance = redrawAmount - nextRepaymentAmount;
    return Math.floor(redrawBalance) > 0;
  }, [redrawAmount, nextRepaymentAmount]);

  const caption = !canReduceRepayments
    ? t('Content.LoanDetail.ReduceRepayments.CaptionWithoutRedraw')
    : t('Content.LoanDetail.ReduceRepayments.CaptionWithRedraw', {
        availableRedraw: formatCurrency(redrawAmount, {
          withFractionOnRoundedAmount: true,
        }),
      });

  // when there are no pending loan variation requests
  if (!loanVariationRequest) {
    return (
      <>
        <DataRowGroup mt={0}>
          <DataRow
            loading={isLoading}
            disabled={!isLoading && !canReduceRepayments}
            label={t('Content.LoanDetail.ReduceRepayments.Title')}
            subCaption={caption}
            captionVariant="caption"
            testID={TestID.LoanDetails.ReduceYourRepaymentsDataRow}
            onPress={canReduceRepayments ? onPress : undefined}
          />
        </DataRowGroup>
        {!isLoading && !canReduceRepayments ? (
          <View
            testID={TestID.LoanDetails.ReduceYourRepaymentsInsufficientText}
            sx={{ mt: '$8', flexDirection: 'row' }}
            style={{ alignItems: 'center' }}
          >
            <StyledIcon
              name="information-circle-outline"
              color="secondaryContent"
              size="navIcon"
            />
            <Text variant="caption" sx={{ ml: '$4' }}>
              {t(
                'Content.LoanDetail.ReduceRepayments.InsufficientRedrawBalance',
              )}
            </Text>
          </View>
        ) : null}
      </>
    );
  }

  return (
    <LoanVariationStatusCard
      cbaAccountId={cbaAccountId}
      onPress={
        !isLoanVariationRequestProcessing
          ? navigateToLoanVariationApprovalPage
          : undefined
      }
      loading={isLoading}
    />
  );
};
