import {
  refetchGetLoanApplicantsQuery,
  refetchYourFinancialsQuery,
  useAddCoBorrowerMutation,
} from '../../../generated/graphql';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { InviteCoBorrowerFormValues } from './forms';
import {
  emitInviteCoBorrowerErrorBasedOnType,
  inviteCoBorrowerErrorEmitter,
} from './inviteCoBorrowerErrorEmitter';

export function useInviteCoBorrower({
  loanApplicationId,
  navigateBackToBorrowerScreen,
  navigateBackToInviteCoBorrowerScreen,
}: {
  loanApplicationId: string;
  navigateBackToBorrowerScreen: () => void;
  navigateBackToInviteCoBorrowerScreen?: () => void;
}) {
  const [addCoBorrower, { loading: addCoBorrowerLoading }] =
    useAddCoBorrowerMutation();

  const inviteCoBorrower = async ({
    values,
    shouldNavToInviteCoBorrowerScreenOnError,
  }: {
    values: InviteCoBorrowerFormValues;
    shouldNavToInviteCoBorrowerScreenOnError: boolean;
  }) => {
    const { email, firstName, lastName, mobile } = values;
    if (!email || !firstName || !lastName || !mobile?.phoneNumber) {
      return;
    }

    inviteCoBorrowerErrorEmitter.emit(
      'clearError',
      'co-borrower is successfully added.',
    );

    const [res] = await safelyCallMutation(addCoBorrower, {
      variables: {
        data: {
          email: email.trim(),
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          loan_application_id: loanApplicationId,
          mobile: mobile.phoneNumber.trim(),
        },
      },
      refetchQueries: [
        refetchGetLoanApplicantsQuery({ loanApplicationId }),
        refetchYourFinancialsQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: { sentryContext: { loanApplicationId } },
    });

    const data = res?.data?.add_coborrower;

    if (data == null) {
      inviteCoBorrowerErrorEmitter.emit('formLevelError', {
        type: null,
      });
      if (shouldNavToInviteCoBorrowerScreenOnError) {
        navigateBackToInviteCoBorrowerScreen?.();
      }
      return;
    }

    if (data.applicant_id) {
      navigateBackToBorrowerScreen();
      return;
    }

    emitInviteCoBorrowerErrorBasedOnType(data);
    if (shouldNavToInviteCoBorrowerScreenOnError) {
      navigateBackToInviteCoBorrowerScreen?.();
    }
  };

  return {
    inviteCoBorrower,
    loading: addCoBorrowerLoading,
  };
}
