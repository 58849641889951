import { Text } from 'dripsy';
import { Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { toFloat } from '../../utils/numberHelpers';
import { NewMonthlyRepayments } from '../components/NewMonthlyRepayments';
import { RedrawAmountPicker } from '../components/RedrawAmountPicker';
import {
  FormFields,
  FormValues,
  getInitialValue,
  getValidationSchema,
} from '../utils/reduceRepaymentsUtils';
import { useLoanVariationReduceRepayments } from '../utils/useLoanVariationReduceRepayments';

type Props = HomeTabScreenProps<Screen.LOAN_VARIATION_REDUCE_REPAYMENTS>;

export const ReduceRepayments = ({ navigation, route }: Props) => {
  const { cbaAccountId } = route.params;
  const {
    error,
    interestRate,
    isRepaymentConfirmed,
    loanAccountLoading,
    minimumRepaymentLoading,
    monthlyRepaymentDifference,
    newMinimumMonthlyRepaymentAmount,
    refetchQueries,
    redrawBalance,
    resetIsRepaymentConfirmed,
    termsInMonths,
    updateMinimumMonthlyRepayments,
  } = useLoanVariationReduceRepayments(cbaAccountId);

  const validationSchema = useMemo(
    () => getValidationSchema(redrawBalance),
    [redrawBalance],
  );

  // need to revisit this
  const onSubmit = useCallback(
    (_: FormValues) => {
      navigation.navigate(
        Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION,
        { cbaAccountId },
      );
    },
    [navigation, cbaAccountId],
  );

  const onAmountChange = useCallback(
    (formProps: FormikProps<FormValues>, newVal: number | string | null) => {
      formProps.setTouched({ [FormFields.Amount]: true });
      const val = toFloat(newVal);
      let valueToBeSet = 0;
      if (val) {
        if (val < 1) {
          valueToBeSet = 0;
        } else if (val > redrawBalance) {
          valueToBeSet = redrawBalance;
        } else {
          valueToBeSet = val;
        }
      }
      formProps.setValues({ [FormFields.Amount]: valueToBeSet });
      updateMinimumMonthlyRepayments(valueToBeSet);
      if (valueToBeSet < 1 || valueToBeSet > redrawBalance) {
        formProps.setErrors({
          [FormFields.Amount]: 'Amount must be greater than $0',
        });
      }
    },
    [redrawBalance, updateMinimumMonthlyRepayments],
  );

  useEffect(() => {
    if (isRepaymentConfirmed) {
      navigation.goBack();
      resetIsRepaymentConfirmed();
    }
  }, [isRepaymentConfirmed, navigation, resetIsRepaymentConfirmed]);

  return (
    <UpliftScreenContainer onPressBack={navigation.goBack}>
      <ScreenErrorFallback
        error={error}
        displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
        refetch={refetchQueries}
      >
        <Formik
          enableReinitialize
          initialValues={getInitialValue(redrawBalance)}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formProps) => (
            <>
              <HomeHeader
                title={t('Content.LoanVariation.ReduceRepayment.Title')}
                subTitle={t('Content.LoanVariation.ReduceRepayment.SubHeading')}
              />
              <Text sx={{ py: '$8' }} variant="sHeader">
                {t(
                  'Content.LoanVariation.ReduceRepayment.ReductionAmountHeading',
                )}
              </Text>
              <RedrawAmountPicker
                screen={Screen.LOAN_VARIATION_REDUCE_REPAYMENTS}
                loading={loanAccountLoading}
                value={formProps.values.amount}
                maxValue={redrawBalance}
                onValueChange={(val: number | string | null) =>
                  onAmountChange(formProps, val)
                }
              />
              <Separator mt="xs" pt="m" />
              <NewMonthlyRepayments
                loading={minimumRepaymentLoading}
                newMonthlyRepayment={newMinimumMonthlyRepaymentAmount ?? 0}
                reducedAmount={monthlyRepaymentDifference}
              />
              {!!termsInMonths && !!interestRate ? (
                <Text variant="caption" sx={{ py: '$8' }}>
                  {t('Content.LoanVariation.ReduceRepayment.Disclaimer', {
                    numberOfYears: Math.floor(termsInMonths / 12),
                    interestRate,
                  })}
                </Text>
              ) : null}
              <Separator spacer py="s" my="xs" />
              <Button
                mt="xs"
                label={t('Content.Common.ButtonLabel.Continue')}
                testID={TestID.LoanVariation.ReduceRepayments.SubmitButton}
                disabled={loanAccountLoading || minimumRepaymentLoading}
                alignSelf="stretch"
                onPress={() => formProps.handleSubmit()}
              />
            </>
          )}
        </Formik>
      </ScreenErrorFallback>
    </UpliftScreenContainer>
  );
};
