import { formatInTimeZone } from 'date-fns-tz';
import { Text, View } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Card } from '../../ui/atoms/Card';
import { Separator } from '../../ui/atoms/Separator';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { DiscountCircularGraph } from '../components/DiscountBump/DiscountCircularGraph';
import { DiscountGraphContent } from '../components/DiscountBump/DiscountGraphContent';
import { InfoCard } from '../components/DiscountBump/InfoCard';
import {
  ANNUAL_DISCOUNT_BUMP,
  useDiscountBumpInsight,
} from '../hooks/useDiscountBumpInsight';
import { HomeLoanInsightsScreenProps } from '../navigation/types';

type Props =
  HomeLoanInsightsScreenProps<Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP>;

export const DiscountBumpInsightsScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const { cbaAccountId } = route.params;
  const {
    loading,
    percentageDaysRemaining,
    isAnniversaryPeriod,
    currentInterestRateDiscount,
    nextInterestRateDiscount,
    nextAnniversaryDate,
    passedAnniversaryDate,
    discountBumpInDays,
    currentInterestRate,
  } = useDiscountBumpInsight(cbaAccountId);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.HomeLoan.Insights.DiscountBump.Header')}
      onClose={onClose}
      hideBackButton
      scrollable
      loading={loading}
    >
      <ScreenLoadingContainer loading={loading}>
        <Card p="m" pb="l" mb="l">
          <Box px="l">
            <DiscountCircularGraph
              aspectRatio={1}
              percentageRemaining={percentageDaysRemaining}
            >
              <DiscountGraphContent
                isAnniversaryPeriod={isAnniversaryPeriod}
                interestRateDiscount={currentInterestRateDiscount}
                discountBumpInDays={discountBumpInDays}
              />
            </DiscountCircularGraph>
          </Box>

          <Separator my="m" />
          {isAnniversaryPeriod ? (
            <View>
              <Text variant="sBody" sx={{ mb: '$8' }}>
                {t(
                  'Content.HomeLoan.Insights.DiscountBump.InAnniversaryPeriod.HappyAnniversary',
                  {
                    rate: currentInterestRate,
                  },
                )}
              </Text>
              <InfoCard passedAnniversaryDate={passedAnniversaryDate} />
            </View>
          ) : (
            <View testID={TestID.Insights.DiscountBump.NextDiscountDescription}>
              <Text variant="sBody" sx={{ mb: '$12' }}>
                {t(
                  'Content.HomeLoan.Insights.DiscountBump.NotInAnniversaryPeriod.YourCurrentDiscount',
                  {
                    currentDiscount: currentInterestRateDiscount,
                  },
                )}
              </Text>
              {nextAnniversaryDate ? (
                <Text variant="sBody">
                  {t(
                    'Content.HomeLoan.Insights.DiscountBump.NotInAnniversaryPeriod.YourNextDiscount',
                    {
                      nextAnniversaryDate: formatInTimeZone(
                        nextAnniversaryDate,
                        'Australia/Sydney',
                        'dd MMMM, yyyy',
                      ),
                      totalDiscount: nextInterestRateDiscount,
                      discountIncrease: ANNUAL_DISCOUNT_BUMP,
                    },
                  )}
                </Text>
              ) : null}
            </View>
          )}
        </Card>
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
};
