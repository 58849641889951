import { useCallback } from 'react';

import {
  Frequency_Enum,
  HouseholdForSummaryFragment,
  Living_Situation_Enum,
} from '../../generated/graphql';
import { NewListRow } from '../../ui/molecules/NewListRow';
import { formatCurrencyWithPeriod } from '../../utils/currencyHelpers';
import { getExpenseFinancialRowDetailsFooter } from '../utils/getExpenseDetailsFooter';

type ExpenseRowProps = {
  onPress?: (id: string) => void;
  id: string;
  householdOwners: Array<string>;
  expenseDetails: HouseholdForSummaryFragment['unordered_household_expenses'];
  index: number;
  testIDPrefix: string;
  livingSituation: Living_Situation_Enum;
};

export function ExpenseRow({
  onPress,
  id,
  expenseDetails,
  householdOwners,
  index,
  testIDPrefix,
  livingSituation,
}: ExpenseRowProps) {
  const onPressWrapped = useCallback(() => {
    onPress?.(id);
  }, [onPress, id]);

  const totalExpenses = expenseDetails.reduce(
    (prev, expense) => prev + (expense?.monthly_amount || 0),
    0,
  );
  const formattedExpenseAmount = formatCurrencyWithPeriod(
    totalExpenses,
    Frequency_Enum.Monthly,
    true,
  );

  return (
    <NewListRow
      key={id}
      leftIconFamily="svg"
      leftIconName="summaryExpenses"
      leftIconColor="primaryContent"
      leftIconSize="l"
      title={t('Content.ApplicationSummary.HouseholdIndex', {
        index: index + 1,
      })}
      caption={`${formattedExpenseAmount} `}
      subcaption={householdOwners.join(', ')}
      testID={`${testIDPrefix}-${id}`}
      onRowPress={onPress ? onPressWrapped : undefined}
      showArrow
      footer={getExpenseFinancialRowDetailsFooter({
        householdExpense: expenseDetails,
        livingSituation,
        testIDPrefix: `${testIDPrefix}-${id}`,
      })}
      shadowed={false}
    />
  );
}
