import { endOfToday, isValid, subDays } from 'date-fns';
import { useMemo } from 'react';

import { yup } from '../../utils/yup';

export enum StatementDateRangeFormField {
  startDate = 'startDate',
  endDate = 'endDate',
}

type Props = {
  loanStartDate: number;
};

export const useStatementDateRangeValidationSchema = ({
  loanStartDate,
}: Props) =>
  useMemo(
    () =>
      yup.object({
        [StatementDateRangeFormField.startDate]: yup
          .date()
          .required(t('Content.StatementsV2.StartDateRequired'))
          .typeError(t('Content.StatementsV2.StartDateRequired'))
          .test('statementStartDate', (value, ctx) => {
            if (!value) {
              return ctx.createError({
                message: t('Content.StatementsV2.StartDateRequired'),
              });
            }
            const localStartDateInUTC = value.getTime();

            if (localStartDateInUTC <= subDays(loanStartDate, 1).getTime()) {
              return ctx.createError({
                message: t(
                  'Content.StatementsV2.StartDateMustBeAfterLoanStartDate',
                ),
              });
            }

            if (localStartDateInUTC > endOfToday().getTime()) {
              return ctx.createError({
                message: t('Content.StatementsV2.StartDateMustBeBeforeToday'),
              });
            }

            return true;
          }),
        [StatementDateRangeFormField.endDate]: yup
          .date()
          .required(t('Content.StatementsV2.EndDateRequired'))
          .typeError(t('Content.StatementsV2.EndDateRequired'))
          .when(StatementDateRangeFormField.startDate, (startDate, schema) => {
            if (isValid(startDate)) {
              return schema
                .min(
                  startDate,
                  t('Content.StatementsV2.EndDateMustBeAfterStartDate'),
                )
                .max(
                  endOfToday(),
                  t('Content.StatementsV2.EndDateMustBeBeforeToday'),
                );
            }
            return schema;
          }),
      }),
    [loanStartDate],
  );
