import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const ChatIcon: React.FC<SvgIconProps> = ({ size, ...props }) => {
  const theme = useTheme();
  const width = resizeIconWidth(81, 63, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 81 63" fill="none" {...props}>
      <Path
        d="M48.8335 19.6875C62.6402 19.6875 73.8335 27.6203 73.8335 37.4062C73.8335 42.0499 71.2918 46.263 67.1685 49.4235C67.1752 49.8448 67.1668 53.8125 73.8335 56.4375C73.8335 56.4375 72.1668 57.75 68.0002 57.75C58.8335 57.75 55.5002 54.4688 55.5002 54.4688H55.5018C53.3752 54.8848 51.1468 55.125 48.8335 55.125C35.0268 55.125 23.8335 47.1923 23.8335 37.4062C23.8335 27.6203 35.0268 19.6875 48.8335 19.6875Z"
        fill={theme.colors.alert}
      />
      <Path
        d="M58.6235 30.1875C59.8168 27.9405 60.5002 25.5137 60.5002 22.9688C60.5002 22.5435 60.4468 22.1288 60.4118 21.7101C56.9485 20.4251 53.0152 19.6875 48.8335 19.6875C35.0268 19.6875 23.8335 27.6203 23.8335 37.4063C23.8335 39.5496 24.3968 41.5931 25.3802 43.4949C25.7818 43.2954 26.1552 43.092 26.4852 42.8873C28.3835 43.1694 30.2885 43.3125 32.1668 43.3125C38.4135 43.3125 44.1818 41.8438 48.8685 39.375H48.8335L51.4995 37.8L52.9602 36.75C54.4918 35.5583 55.8402 34.2405 56.9452 32.8125L57.4995 31.8938L57.9995 31.1063L58.6235 30.1875Z"
        fill={theme.colors.primaryContent}
        fillOpacity={0.2}
      />
      <Path
        d="M32.1665 5.25C18.3598 5.25 7.1665 13.1828 7.1665 22.9688C7.1665 27.6124 9.70817 31.8255 13.8315 34.986C13.8248 35.4073 13.8332 39.375 7.1665 42C7.1665 42 8.83317 43.3125 12.9998 43.3125C22.1665 43.3125 25.4998 40.0312 25.4998 40.0312H25.4982C27.6248 40.4473 29.8532 40.6875 32.1665 40.6875C45.9732 40.6875 57.1665 32.7547 57.1665 22.9688C57.1665 13.1828 45.9732 5.25 32.1665 5.25Z"
        fill={theme.colors.bg}
      />
    </Svg>
  );
};
