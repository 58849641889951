// Groups the url path of transactions so that we can get a track of each screen performance
const uuidRegex =
  /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g;
const joinApplicationRegex = /\/join-application\/*.*/;

export function normalizePathForTracing(path: string) {
  return path
    .replace(uuidRegex, '/<uuid>')
    .replace(joinApplicationRegex, '/join-application');
}
