import {
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
} from '../generated/graphql';

export const isTopUpHomeLoan = ({
  loanApplicationType,
  liabilityType,
  forRefinancing,
}: Partial<{
  loanApplicationType: Loan_Application_Type_Enum;
  liabilityType: Liability_Type_Enum | null;
  forRefinancing: boolean | null;
}>) =>
  loanApplicationType === Loan_Application_Type_Enum.TopUp &&
  liabilityType === Liability_Type_Enum.HomeLoan &&
  !!forRefinancing;
