import { Change_Personal_Details_Error_Type } from '../../generated/graphql';

function getErrorMessage(
  errorType: Change_Personal_Details_Error_Type | null | undefined,
  changeType?: 'email' | 'mobile',
): string | undefined {
  switch (errorType) {
    case Change_Personal_Details_Error_Type.Expired:
      return t('Content.PersonalDetails.Common.SessionExpired');
    case Change_Personal_Details_Error_Type.InvalidOtp:
      return t('Content.PersonalDetails.Common.InvalidCode');
    case Change_Personal_Details_Error_Type.InvalidMobileNumber:
      return t('Content.PersonalDetails.ChangeMobile.InvalidMobileNumber');
    case Change_Personal_Details_Error_Type.SystemError:
      switch (changeType) {
        case 'email':
          return t(
            'Content.PersonalDetails.ChangeEmail.UnableToProcessRequest',
          );
        case 'mobile':
          return t(
            'Content.PersonalDetails.ChangeMobile.UnableToProcessRequest',
          );
        default:
          return t('Content.PersonalDetails.Common.UnableToProcessRequest');
      }
    default:
      return undefined;
  }
}

export default getErrorMessage;
