import { Text } from 'dripsy';
import React from 'react';

import { ErrorRow } from '../../../components/ErrorRow';
import { Spinner } from '../../../ui/atoms/Spinner';

type Props = {
  onTryAgainPress?: React.ComponentProps<typeof Text>['onPress'];
  tryAgainDisabled?: React.ComponentProps<typeof Text>['disabled'];
  sx?: React.ComponentProps<typeof ErrorRow>['sx'];
  tryAgainLoading?: boolean;
  testID?: string;
};

export function TryAgainErrorRow({
  onTryAgainPress,
  tryAgainDisabled,
  tryAgainLoading,
  sx,
  testID,
}: Props) {
  return (
    <ErrorRow sx={sx} testID={testID}>
      <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
        {t('Content.TryAgainErrorRow.SomethingWentWrongPart1')}
        <Text
          variants={[
            'caption',
            'link',
            ...(tryAgainDisabled ? (['disabled'] as const) : []),
          ]}
          onPress={tryAgainDisabled ? undefined : onTryAgainPress}
          disabled={tryAgainDisabled}
        >
          {t('Content.TryAgainErrorRow.SomethingWentWrongPart2')}
          {tryAgainLoading ? (
            <Spinner size={8} style={{ marginLeft: 4 }} />
          ) : null}
        </Text>
        {t('Content.TryAgainErrorRow.SomethingWentWrongPart3')}
      </Text>
    </ErrorRow>
  );
}
