import { useCallback, useState } from 'react';
import * as React from 'react';
import { useRecoilValue } from 'recoil';

import {
  autopayChangeFormAtom,
  SubmittedAutopayChangeRequest,
} from '../../AutopaySettings/autopayAtom';
import { AutopayAgreementText } from '../../AutopaySettings/components/AutopayAgreementText';
import { ErrorRow } from '../../components/ErrorRow';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { Row } from '../../ui/molecules/Row';
import { isLast } from '../../utils/arrayHelpers';
import { formatCurrency } from '../../utils/currencyHelpers';
import { generateCensoredCreditCardNumber } from '../../utils/stringHelpers';
import { FullScreenActionSheet } from '../components/FullScreenActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import { useConfirmAutopay } from '../utils/useConfirmAutopay';

type Props = ActionSheetScreenProps<ActionSheetType.AUTOPAY_CONFIRMATION>;

const getFrequencyLabel = (frequency: Autopay_Frequency_Input_Enum): string => {
  switch (frequency) {
    case Autopay_Frequency_Input_Enum.Weekly:
      return t('Content.Common.Frequency.Week');
    case Autopay_Frequency_Input_Enum.Fortnightly:
      return t('Content.Common.Frequency.Fortnight');
    case Autopay_Frequency_Input_Enum.Monthly:
    default:
      return t('Content.Common.Frequency.Month');
  }
};

const formatFirstPaymentDate = (paymentStartDate: string | Date): string =>
  new Date(paymentStartDate).toDateString();

const getActionSheetValues = (
  loanAccountName: string,
  autopayChangeRequest: SubmittedAutopayChangeRequest,
) => {
  const formattedRepaymentAmount = formatCurrency(
    autopayChangeRequest.repaymentAmount,
    { noFraction: true },
  );

  const frequencyLabel = getFrequencyLabel(
    autopayChangeRequest.repaymentFrequency,
  );

  return [
    {
      label: t('Content.AutopaySettings.Amount'),
      value: `${formattedRepaymentAmount}/${frequencyLabel}`,
    },
    {
      label: t('Content.AutopaySettings.FromAccount'),
      value: `${generateCensoredCreditCardNumber(
        autopayChangeRequest.directDebitAccountNumber.slice(-4),
      )}`,
    },
    {
      label: t('Content.AutopaySettings.ToAccount'),
      value: loanAccountName,
    },
    {
      label: t('Content.AutopaySettings.Commencing'),
      value: formatFirstPaymentDate(autopayChangeRequest.paymentStartDate),
    },
  ];
};

export const AutopayChangeConfirmationActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const [noIdError, setNoIdError] = useState(false);

  const { cbaAccountId } = route.params;

  const autopayChangeRequest = useRecoilValue(autopayChangeFormAtom);

  const {
    confirmAutopay,
    loading,
    error: submitAutopayChangeRequestError,
  } = useConfirmAutopay({
    screen: ActionSheetType.AUTOPAY_CONFIRMATION,
    cbaAccountId,
  });

  const onPressConfirm = useCallback(async () => {
    if (!autopayChangeRequest) {
      captureException('Submitted Autopay Setup Request Not Found', {
        cbaAccountId,
        screen: ActionSheetType.AUTOPAY_CONFIRMATION,
        description:
          'Unexpected error: Submitted autopay setup request not found in the Recoil state.',
      });
      setNoIdError(true);
      return;
    }

    const autopayChangeRequestId = await confirmAutopay(autopayChangeRequest);

    if (!autopayChangeRequestId) {
      setNoIdError(true);
      return;
    }

    // Great success!
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_CHANGE_REQUEST,
      params: {
        cbaAccountId,
        autopayChangeRequestId,
      },
    });
  }, [autopayChangeRequest, confirmAutopay, navigation, cbaAccountId]);

  const actionList = [
    {
      label: 'Confirm',
      secondary: false,
      disabled: loading,
      showSpinner: loading,
      onPress: onPressConfirm,
    },
  ];

  const { data: loanData } = useLoanAccountState(route.params.cbaAccountId);

  const loanAccountName = loanData?.settings?.name ?? '';

  const values = autopayChangeRequest
    ? getActionSheetValues(loanAccountName, autopayChangeRequest)
    : [];

  return (
    <FullScreenActionSheet
      width="100%"
      closeModal={navigation.goBack}
      actionList={actionList}
      title=""
    >
      <StyledText variant="header" textAlign="center" mb="m" mt="s">
        {t('Content.AutopaySettings.ConfirmAutopayHeading')}
      </StyledText>
      <StyledText variant="body" textAlign="center">
        {t('Content.AutopaySettings.ConfirmAutopaySupportingText')}
      </StyledText>
      <Box my="l">
        {submitAutopayChangeRequestError || noIdError ? (
          <ErrorRow
            message={t('Content.Common.Error.FailAutopayChangeRequest')}
          />
        ) : null}
        {values.map(({ label, value }, index) => (
          <Row
            labelFontWeight="bold"
            label={label}
            placeholder={value}
            key={label}
            last={isLast(values, index)}
          />
        ))}
      </Box>
      <Box mb="l">
        <AutopayAgreementText />
      </Box>
    </FullScreenActionSheet>
  );
};
