import { ApolloError } from '@apollo/client';
import { useIsFocused } from '@react-navigation/native';
import { Text } from 'dripsy';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ErrorRow } from '../../components/ErrorRow';
import { FormSpacer } from '../../components/form/FormSpacer';
import { ExternalAccount } from '../../HomeLoan/types';
import { Screen } from '../../navigation/types/screens';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { DATE_INPUT_FORMAT, safelyFormatDate } from '../../utils/dateHelpers';
import { useAutopayMinimumRepaymentAmount } from '../hooks/useAutopayMinimumRepaymentAmount';
import { useAutopaySetupActions } from '../hooks/useAutopaySetupActions';
import {
  AutopayChangeRequestFormV2,
  AutoPaySetupFormField,
  MinimumRepaymentAmountsByFrequency,
} from '../types';
import { useAutopaySetupV2ValidationSchema } from '../utils/useAutopaySetupV2ValidationSchema';
import { AutopaySetupV2Confirmation } from './AutopaySetupV2Confirmation';
import { AutopaySetupV2Form } from './AutopaySetupV2Form';

export type BaseAutopaySetupV2Props = {
  screen: Screen;
  initialValues: AutopayChangeRequestFormV2;
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  maximumDirectDebitAmount: number;
  externalAccounts: ExternalAccount[];
  showConfirmation?: boolean;
  onClose: () => void;
  onBack: () => void;
  onContinuePress: ReturnType<typeof useAutopaySetupActions>['onContinuePress'];
  onConfirmPress: (values: AutopayChangeRequestFormV2) => void;
  loading?: boolean;
  getEstimatedShortfallAmountLoading?: boolean;
  confirmAutopayLoading?: boolean;
  errorMessage?: string;
  isEdit?: boolean;
  refetchMonthlyMinimumRepaymentAmount?: ReturnType<
    typeof useAutopayMinimumRepaymentAmount
  >['refetchMonthlyMinimumRepaymentAmount'];
  monthlyMinimumRepaymentAmountError?: ApolloError;
  isLoadingMonthlyMinimumRepaymentAmount?: boolean;
  nextInstallmentAmount?: number;
  nextInstallmentDate?: string;
  availableRedrawBalance?: number;
};

export function BaseAutopaySetupV2({
  initialValues,
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  minimumRepaymentAmountsByFrequency,
  maximumDirectDebitAmount,
  externalAccounts,
  showConfirmation,
  onClose,
  onBack,
  onContinuePress,
  onConfirmPress,
  loading,
  getEstimatedShortfallAmountLoading,
  confirmAutopayLoading,
  errorMessage,
  isEdit,
  refetchMonthlyMinimumRepaymentAmount,
  monthlyMinimumRepaymentAmountError,
  isLoadingMonthlyMinimumRepaymentAmount,
  nextInstallmentAmount,
  nextInstallmentDate,
  availableRedrawBalance,
  screen,
}: BaseAutopaySetupV2Props) {
  const formRef = useRef<FormikProps<AutopayChangeRequestFormV2>>(null);
  const [refetchMinimumAmountSuccess, setRefetchMinimumAmountSuccess] =
    useState(false);
  const validationSchema = useAutopaySetupV2ValidationSchema({
    maximumDirectDebitAmount,
    minimumRepaymentAmountsByFrequency,
    externalAccounts,
  });
  const isCurrentScreenFocused = useIsFocused();

  const headerText = useMemo(() => {
    if (showConfirmation) {
      return t('Content.AutopaySettings.ConfirmAutopayHeading');
    }

    if (isEdit) {
      return t('Content.HomeLoan.EditAutopay');
    }

    return t('Content.HomeLoan.SetupAutopay');
  }, [isEdit, showConfirmation]);

  useEffect(() => {
    const validateFormWhenFetchSuccessAndContinue = async () => {
      if (refetchMinimumAmountSuccess && formRef.current) {
        setRefetchMinimumAmountSuccess(false);
        const validationErrors = await formRef.current.validateForm(
          formRef.current.values,
        );
        if (isEmpty(validationErrors)) {
          onContinuePress(formRef.current?.values);
        }
      }
    };
    validateFormWhenFetchSuccessAndContinue();
  }, [
    minimumRepaymentAmountsByFrequency,
    onContinuePress,
    refetchMinimumAmountSuccess,
  ]);

  const onSubmit = useCallback(async () => {
    if (!formRef.current) {
      return;
    }
    if (!monthlyMinimumRepaymentAmountError) {
      onContinuePress(formRef.current?.values);
      return;
    }

    const dateInString = safelyFormatDate(
      formRef.current?.values[AutoPaySetupFormField.PaymentStartDate],
      DATE_INPUT_FORMAT,
    );
    if (!dateInString) {
      return;
    }
    const result = await refetchMonthlyMinimumRepaymentAmount?.(dateInString);
    if (result && !result.error) {
      setRefetchMinimumAmountSuccess(true);
    }
  }, [
    monthlyMinimumRepaymentAmountError,
    onContinuePress,
    refetchMonthlyMinimumRepaymentAmount,
  ]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <ModalScreenContainer
          headerText={headerText}
          onClose={onClose}
          scrollable
          loading={loading}
          hideBackButton={!showConfirmation}
          onBack={onBack}
          preventClosingModalWithDialog={formProps.dirty}
          preventClosingModalTitle={t(
            'Content.AutopaySettings.LeaveAutoPaySetup',
          )}
          aria-hidden={!isCurrentScreenFocused}
        >
          <ErrorRow message={errorMessage} mb="l" />

          <Skeleton show={loading}>
            <Text variant="sBody">
              {!showConfirmation &&
                t('Content.AutopaySettings.SupportingText', {
                  accountName: unloanAccountName,
                })}
              {showConfirmation
                ? t('Content.AutopaySettings.ConfirmAutopaySupportingText')
                : null}
            </Text>
          </Skeleton>

          <FormSpacer py="$16" />

          {!showConfirmation && (
            <AutopaySetupV2Form
              screen={screen}
              formProps={formProps}
              suggestionData={externalAccounts}
              unloanAccountName={unloanAccountName}
              unloanBsb={unloanBsb}
              unloanAccountNumber={unloanAccountNumber}
              loading={loading}
              onSubmitPress={formProps.handleSubmit}
              submitLoading={getEstimatedShortfallAmountLoading}
              refetchMonthlyMinimumRepaymentAmount={
                refetchMonthlyMinimumRepaymentAmount
              }
              minimumRepaymentAmountsByFrequency={
                minimumRepaymentAmountsByFrequency
              }
              isLoadingMonthlyMinimumRepaymentAmount={
                isLoadingMonthlyMinimumRepaymentAmount
              }
            />
          )}

          {showConfirmation ? (
            <AutopaySetupV2Confirmation
              values={formProps.values}
              externalAccounts={externalAccounts}
              unloanAccountName={unloanAccountName}
              unloanBsb={unloanBsb}
              unloanAccountNumber={unloanAccountNumber}
              onConfirmPress={() => onConfirmPress(formProps.values)}
              confirmLoading={confirmAutopayLoading}
              minimumRepaymentAmountsByFrequency={
                minimumRepaymentAmountsByFrequency
              }
              nextInstallmentAmount={nextInstallmentAmount}
              nextInstallmentDate={nextInstallmentDate}
              availableRedrawBalance={availableRedrawBalance}
            />
          ) : null}
        </ModalScreenContainer>
      )}
    </Formik>
  );
}
