import { FormikContextType } from 'formik';
import { useCallback, useState } from 'react';
import * as React from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';

import { FormikControlProps } from '../../../components/form/types';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { ComboboxProps } from './Combobox';

export const LAZY_QUERY_WAIT_AFTER_CHANGE_TEXT_IN_MS = 300;

export type FormikAwareComboboxProps<TDataItem, TFieldValue> = Omit<
  ComboboxProps<TDataItem>,
  | 'value'
  | 'onChange'
  | 'onChangeText'
  | 'onSuggestionSelect'
  | 'setFieldValue'
  | 'setFieldTouched'
  // This is specific for test and storybook
  | 'searchTextInitialValue'
> &
  Omit<
    FormikControlProps<TFieldValue | null>,
    'error' | 'setFieldValue' | 'setFieldTouched'
  > & {
    loadSuggestionData?: (suggestionOptions: {
      suggestionCount: number;
      suggestionSearchText: string;
    }) => Promise<void>;
    maxSuggestions?: number;
    // This formik is injected by `connect` from formik
    formik?: FormikContextType<unknown>;
    error?: string;
    // Internally, Formik return Promise
    // for setFieldValue and setFieldTouched
    // https://github.com/jaredpalmer/formik/issues/2457#issuecomment-1227803618
    setFieldValue?(
      value: TFieldValue | null,
      validate?: boolean,
    ): Promise<void>;
    setFieldTouched?(isTouched?: boolean, validate?: boolean): Promise<void>;
  };

export function makeFormikAwareCombobox<TDataItem, TFieldValue>(
  ComboboxComponent: React.ForwardRefExoticComponent<
    ComboboxProps<TDataItem> & React.RefAttributes<RNTextInput | null>
  >,
  {
    getDataItem,
    getFieldValue,
  }: {
    getDataItem: (
      data: Array<TDataItem>,
      fieldValue?: TFieldValue | null,
    ) => TDataItem | undefined;
    getFieldValue: (dataItem: TDataItem) => TFieldValue;
  },
) {
  const FormikAwareCombobox = React.forwardRef<
    RNTextInput,
    FormikAwareComboboxProps<TDataItem, TFieldValue>
  >(
    (
      {
        maxSuggestions = 5,
        suggestionData,
        isLoadingSuggestionData,
        loadSuggestionData,

        // From formik
        name,
        formik,
        setFieldValue,
        setFieldTouched,
        onBlur: onBlurProp,
        onFocus: onFocusProp,
        value,
        error,
        ...otherProps
      },
      ref,
    ) => {
      const [_, setIsLoadingSuggestion] = useState(false);

      const debounceSearch = useDebounce(async (userSearch: string) => {
        await loadSuggestionData?.({
          suggestionCount: maxSuggestions,
          suggestionSearchText: userSearch,
        });

        setIsLoadingSuggestion(false);
      }, LAZY_QUERY_WAIT_AFTER_CHANGE_TEXT_IN_MS);

      const onBlur = useCallback(
        (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
          setFieldTouched?.(true);
          onBlurProp?.(event);
        },
        [onBlurProp, setFieldTouched],
      );

      const onFocus = useCallback(
        (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
          onFocusProp?.(event);
        },
        [onFocusProp],
      );

      const onChangeSearchText = useCallback(
        async (searchText: string) => {
          if (value) {
            await setFieldValue?.(null);
          }
          setIsLoadingSuggestion(true);
          debounceSearch(searchText);
        },
        [debounceSearch, setFieldValue, value],
      );

      const onSuggestionSelect = useCallback(
        async (data: TDataItem) => {
          await setFieldValue?.(getFieldValue(data));
        },
        [setFieldValue],
      );

      return (
        <ComboboxComponent
          ref={ref}
          onSuggestionSelect={onSuggestionSelect}
          onChangeSearchText={onChangeSearchText}
          onBlur={onBlur}
          onFocus={onFocus}
          isLoadingSuggestionData={isLoadingSuggestionData}
          error={error}
          suggestionData={(suggestionData ?? []).slice(0, maxSuggestions)}
          value={getDataItem(suggestionData, value)}
          {...otherProps}
        />
      );
    },
  );

  return FormikAwareCombobox;
}
