import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const FinancialIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
    <Path
      opacity="0.2"
      d="M0 17.9997C0 8.10166 8.10166 0 17.9997 0V17.9997H0Z"
      fill={color}
    />
    <Path
      opacity="0.4"
      d="M35.9999 17.9997C35.9999 8.10166 27.8983 0 18.0002 0V17.9997L30.3012 31.1007C33.8004 27.8977 35.9999 23.1989 35.9999 17.9997Z"
      fill={color}
    />
    <Path
      opacity="0.2"
      d="M17.9997 18.0002H0C0 22.6991 1.89807 27.1018 4.89861 30.3012C8.19885 33.8004 12.8986 35.9999 17.9997 35.9999C22.6985 35.9999 26.9995 34.1019 30.3007 31.1013L17.9997 18.0002Z"
      fill={color}
    />
    <Path
      d="M18.6 25.7895C19.2875 25.7895 23 25.2368 23 21.3684C23 15.7039 15.9875 17.0855 15.85 13.7697C15.85 13.4934 15.85 11.8355 17.775 11.8355C19.5625 11.8355 19.8375 13.6316 19.8375 14.4605H23C23 10.3158 19.975 9.48684 18.875 9.34869V7H17.0875V9.34869C15.7125 9.34869 12.6875 10.3158 12.6875 13.7697C12.6875 19.2961 19.8375 17.7763 19.8375 21.3684C19.8375 21.6447 19.8375 23.3026 17.775 23.3026C15.3 23.3026 15.3 21.0921 15.3 20.5395H12C12 25.2368 16.2625 25.7895 16.8125 25.7895V28H18.6V25.7895Z"
      fill={color}
      fillOpacity="0.2"
    />
    <Path
      d="M18.6 25.7895C19.2875 25.7895 23 25.2368 23 21.3684C23 15.7039 15.9875 17.0855 15.85 13.7697C15.85 13.4934 15.85 11.8355 17.775 11.8355C19.5625 11.8355 19.8375 13.6316 19.8375 14.4605H23C23 10.3158 19.975 9.48684 18.875 9.34869V7H17.0875V9.34869C15.7125 9.34869 12.6875 10.3158 12.6875 13.7697C12.6875 19.2961 19.8375 17.7763 19.8375 21.3684C19.8375 21.6447 19.8375 23.3026 17.775 23.3026C15.3 23.3026 15.3 21.0921 15.3 20.5395H12C12 25.2368 16.2625 25.7895 16.8125 25.7895V28H18.6V25.7895Z"
      fill={color}
    />
  </Svg>
);
