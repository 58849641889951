import { Text, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { BoxProps } from '../../../ui/atoms/Box';
import { GradientBallIcon } from '../../../ui/svgs/GradientBallIcon';
import { GrayBallIcon } from '../../../ui/svgs/GrayBallIcon';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { useIsMobile } from '../../../utils/hooks/useBreakpoint';
import { TooltipContainer, TooltipContainerProps } from '../TooltipContainer';

type Props = {
  remainingYears: string;
  loanAmount: number;
  estimatedBalance: number;
  displayBoarder?: boolean;
  displayProjectedBalance?: boolean;
} & BoxProps &
  TooltipContainerProps;

const AmountRow = ({
  label,
  amount,
  badge,
}: {
  label: string;
  amount: number;
  badge: 'gray' | 'gradient';
}) => (
  <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
    {badge === 'gradient' ? (
      <GradientBallIcon size={12} />
    ) : (
      <GrayBallIcon size={12} />
    )}
    <Text variant="caption" sx={{ ml: '$4', color: '$labelsPrimary' }}>
      {`${label}: ${formatCurrency(amount, {
        noFraction: false,
        withFractionOnRoundedAmount: true,
      })}`}
    </Text>
  </View>
);

export const TermRemainingTooltip = ({
  remainingYears,
  loanAmount,
  estimatedBalance,
  displayProjectedBalance = false,
  ...props
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <TooltipContainer
      testID={TestID.Insights.TermRemaining.SayGoodbyeIn}
      {...props}
    >
      <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
        {t('Content.HomeLoan.Insights.TermRemaining.SayGoodbyeIn')}
      </Text>
      <Text variant={isMobile ? 'header' : 'lHeader'}>{remainingYears}</Text>
      <AmountRow
        label={t('Content.HomeLoan.Insights.TermRemaining.LoanAmount')}
        amount={loanAmount}
        badge="gray"
      />
      {displayProjectedBalance ? (
        <AmountRow
          label={t('Content.HomeLoan.Insights.TermRemaining.EstimatedBalance')}
          amount={estimatedBalance}
          badge="gradient"
        />
      ) : null}
    </TooltipContainer>
  );
};
