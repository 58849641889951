import { Text, useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormCurrencyInputV2 } from '../../components/form/FormikInputs';
import { Screen } from '../../navigation/types/screens';
import { FlexSlider } from '../../ui/molecules/FlexSlider';
import { FormikFormError } from '../../ui/v2/FormError';
import { Skeleton } from '../../ui/v2/Skeleton';
import { FormFields } from '../utils/reduceRepaymentsUtils';

type Props = {
  screen: Screen;
  loading: boolean;
  minValue?: number;
  maxValue: number;
  value: number;
  onValueChange: (val: number | string | null) => void;
};

export const RedrawAmountPicker = ({
  screen,
  loading,
  minValue = 0,
  maxValue,
  value,
  onValueChange,
}: Props) => {
  const { theme } = useDripsyTheme();
  return (
    <View>
      {loading ? (
        <View style={{ alignSelf: 'stretch' }} sx={{ my: '4' }}>
          <Skeleton height={theme.space.$56} show />
        </View>
      ) : (
        <View sx={{ my: '$8' }}>
          <FormCurrencyInputV2
            inputTestID={
              TestID.LoanVariation.ReduceRepayments.AmountToBeReducedInput
            }
            name={FormFields.Amount}
            keyboardType="decimal-pad"
            label="Amount"
            value={value}
            onChangeText={onValueChange}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.ReduceRepayments,
              screen,
              FieldInteractionKey.RedrawAmountToUse,
            )}
          />
          <FormikFormError
            name={FormFields.Amount}
            errorMessageParser={() => 'Amount must be greater than $0'}
            sx={{ mt: '$8' }}
          />
        </View>
      )}
      <View sx={{ mt: '$8' }}>
        {loading ? (
          <View style={{ alignSelf: 'stretch' }}>
            <Skeleton height={theme.space.$56} show />
          </View>
        ) : (
          <>
            <FlexSlider
              min={minValue}
              max={maxValue}
              value={value}
              onValueChange={onValueChange}
            />
            <View
              sx={{ py: '$4' }}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text variant="caption">${minValue}</Text>
              <Text variant="caption">${maxValue}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};
