import { Text, View } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET>;

export const GreenTickConfirmationActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { bottomText } = route.params;
  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  return (
    <ActionSheet closeModal={closeModal} title="" showCloseButton={false}>
      <View
        sx={{
          py: '$32',
          mb: '$48',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GreenCheckAnimation
          testID={TestID.ActionSheet.GreenTicketConfirmation}
          onAnimationFinish={closeModal}
        />
        <Text variant="sHeader" sx={{ my: '$22' }}>
          {bottomText}
        </Text>
      </View>
    </ActionSheet>
  );
};
