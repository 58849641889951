import {
  ApplicantStatusInfoFragment,
  Pexa_Acceptance_Status_Enum,
} from '../../generated/graphql';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { ApplicantState } from './useApplicantStates';

export interface ApplicantWorkspace {
  id: string;
  acceptance_status: Pexa_Acceptance_Status_Enum;
  workspace_id: string;
}

export function getPexaWorkspaceStatus(
  workspaces: Array<ApplicantWorkspace>,
): Pexa_Acceptance_Status_Enum | null {
  if (workspaces?.length === 0) {
    return null;
  }

  return (
    workspaces?.filter(
      (workspace) =>
        workspace?.acceptance_status !== Pexa_Acceptance_Status_Enum.Accepted,
    ) ?? []
  )?.length > 0
    ? Pexa_Acceptance_Status_Enum.Pending
    : Pexa_Acceptance_Status_Enum.Accepted;
}

export function getApplicantStateFromWorkspace(
  workspaces: Array<ApplicantWorkspace>,
): ApplicantState {
  const workspaceStatus = getPexaWorkspaceStatus(workspaces);
  const applicantStateMapping: Map<
    Pexa_Acceptance_Status_Enum,
    ApplicantState
  > = new Map([
    [
      Pexa_Acceptance_Status_Enum.Pending,
      ApplicantState.BookedWorkspacePending,
    ],
    [
      Pexa_Acceptance_Status_Enum.Accepted,
      ApplicantState.BookedWorkspaceAccepted,
    ],
  ]);

  return workspaceStatus
    ? applicantStateMapping.get(workspaceStatus) || ApplicantState.Booked
    : ApplicantState.Booked;
}

export function getWorkspaceBookedCaption(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
  pexaWorkspaceStatus: Pexa_Acceptance_Status_Enum | null,
) {
  const bookedAt =
    applicantStatusInfo?.loanApplication?.statusInfo?.settlementBookedAt;

  const showBookedAndAcceptedMsg =
    pexaWorkspaceStatus === Pexa_Acceptance_Status_Enum.Accepted &&
    Boolean(bookedAt);

  return showBookedAndAcceptedMsg
    ? t('Content.Home.BookedPexaWorkspaceAcceptedCaption', {
        settlementBookedAt: safelyFormatDate(bookedAt, 'd MMM yyyy') || '--',
      })
    : t('Content.Home.BookedPexaWorkspacePendingCaption');
}
