import { Text } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { ProcessAccountStatus } from '../../HomeLoan/screens/AccountAddressBook/models';
import { useDeleteExternalAccount } from '../../HomeLoan/screens/AccountAddressBook/useDeleteExternalAccount';
import { ActionSheetType } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE>;

export const AccountAddressBookDeleteActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { cbaAccountId, externalAccountId, onNavigateBack } = route.params;

  const { deleteExternalAccount, loading } =
    useDeleteExternalAccount(cbaAccountId);

  const confirm = useCallback(async () => {
    const [result, error] = await deleteExternalAccount(externalAccountId);
    if (!result?.data?.remove_external_account?.id || error) {
      navigation.goBack();
      onNavigateBack(ProcessAccountStatus.Fail);
      return;
    }
    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.AccountAddressBook.Delete.ConfirmSuccessMessage'),
    });
  }, [deleteExternalAccount, externalAccountId, navigation, onNavigateBack]);

  return (
    <ActionSheet
      showCloseButton={false}
      preventCloseModal={loading}
      isRowButton
      title={t('Content.AccountAddressBook.Delete.Title')}
      actionList={[
        {
          label: t('Content.AccountAddressBook.Delete.Cancel'),
          secondary: true,
          disabled: loading,
          testID: TestID.AccountAddressBookDeleteActionSheet.CancelButton,
          onPress: navigation.goBack,
          flex: 1,
        },
        {
          label: t('Content.AccountAddressBook.Delete.Confirm'),
          secondary: true,
          disabled: loading,
          showSpinner: loading,
          testID: TestID.AccountAddressBookDeleteActionSheet.ConfirmButton,
          onPress: confirm,
          flex: 1,
        },
      ]}
      errorMessage={undefined}
    >
      <Box p="m" px="xl">
        <Text variant="sSubHeading" sx={{ textAlign: 'center' }}>
          {t('Content.AccountAddressBook.Delete.Body')}
        </Text>
      </Box>
    </ActionSheet>
  );
};
