import { useCallback, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Change_Personal_Details_Error_Type,
  refetchGetMeQuery,
  useCreateMfaEnrollmentRequestMutation,
  useVerifyMobileChangeRequestMutation,
} from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { BaseMobileVerify } from '../components/BaseMobileVerify';
import { PersonalDetailsModalScreenProps } from '../navigation/types';
import getErrorMessage from '../utils/getErrorMessage';

type MobileChangeNewMobileVerifyProps =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY>;

export const MobileChangeNewMobileVerify = ({
  navigation,
  route,
}: MobileChangeNewMobileVerifyProps) => {
  const { auth0MfaSessionId, mobileChangeRequestId, newMobile } = route.params;

  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    verifyNewMobile,
    { loading: verifyNewMobileLoading, error: verifyNewMobileError },
  ] = useVerifyMobileChangeRequestMutation();

  const [
    createMfaEnrollment,
    { loading: createMfaEnrollmentLoading, error: createMfaEnrollmentError },
  ] = useCreateMfaEnrollmentRequestMutation();

  const onSubmit = useCallback(
    async ({ code }: { code: string }) => {
      setValidationErrorMessage(undefined);
      setErrorMessage(undefined);

      const [response] = await safelyCallMutation(verifyNewMobile, {
        variables: {
          mobile_change_request_id: mobileChangeRequestId,
          otp: code,
        },
        refetchQueries: [refetchGetMeQuery()],
        context: {
          sentryContext: {
            description: 'Mobile Change Request - Verify New Mobile',
          },
        },
      });

      const errorType = response?.data?.verify_mobile_change_request.error_type;

      if (errorType) {
        const message = getErrorMessage(errorType, 'mobile');

        if (errorType === Change_Personal_Details_Error_Type.SystemError) {
          setErrorMessage(message);
          return;
        }

        setValidationErrorMessage(message);
        return;
      }

      if (!response?.data?.verify_mobile_change_request?.success) {
        const message = t(
          'Content.PersonalDetails.ChangeMobile.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
        message: t('Content.PersonalDetails.ChangeMobile.MobileNumberUpdated'),
      });
    },
    [
      verifyNewMobile,
      mobileChangeRequestId,
      navigation,
      setErrorMessage,
      setValidationErrorMessage,
    ],
  );

  const onPressResend = useCallback(async () => {
    setValidationErrorMessage(undefined);
    setErrorMessage(undefined);

    const [response] = await safelyCallMutation(createMfaEnrollment, {
      variables: {
        auth0_mfa_session_id: auth0MfaSessionId,
        new_mobile: newMobile,
      },
      context: {
        sentryContext: {
          auth0MfaSessionId,
          description: 'Mobile Change Request(Resend Code) - Verify New Mobile',
        },
      },
    });

    const errorType = response?.data?.create_mfa_enrollment_request.error_type;

    if (errorType || !response?.data?.create_mfa_enrollment_request.success) {
      const message = t('Content.PersonalDetails.Common.ResendFailed');
      setErrorMessage(message);
    }
  }, [
    auth0MfaSessionId,
    createMfaEnrollment,
    newMobile,
    setValidationErrorMessage,
    setErrorMessage,
  ]);

  return (
    <BaseMobileVerify
      screen={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY}
      title={t('Content.PersonalDetails.ChangeMobile.VerifyNewMobileTitle')}
      description={t('Content.PersonalDetails.Common.VerifyMobileContent')}
      loading={verifyNewMobileLoading}
      target={newMobile}
      testID={TestID.PersonalDetails.ChangeMobileVerifyNewMobile}
      navigation={navigation}
      onSubmit={onSubmit}
      onPressResend={!createMfaEnrollmentLoading ? onPressResend : undefined}
      inputValidationError={validationErrorMessage}
      errorMessage={
        verifyNewMobileError || createMfaEnrollmentError
          ? t('Content.PersonalDetails.ChangeMobile.UnableToProcessRequest')
          : errorMessage
      }
    />
  );
};
