import {
  ApolloProvider,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client';
import { useMemo } from 'react';
import * as React from 'react';

import { useAuthContext } from '../Auth/context';
import { AppLoading } from '../ui/organisms/AppLoading';
import { createClient } from './apollo';
import { useEnvConfig } from './hooks/useEnvConfig';

type Props = { children: React.ReactNode };

const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loan_application_security_by_pk: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'loan_application_security',
              id: args?.id,
            });
          },
        },
        loan_application_target_by_pk: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'loan_application_target',
              id: args?.id,
            });
          },
        },
        applicant_by_pk: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'applicant',
              id: args?.id,
            });
          },
        },
        income_by_pk: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'income',
              id: args?.id,
            });
          },
        },
      },
    },
  },
  // All object in the graph must have a unique identifier
  // to make sure the component that read from cache
  // are notified that the cache has been updated
  // and properly re-render with fresh data.
  // See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
  // TODO: id mapping here is incomplete compared to what's used in dynamite
  // See https://github.com/unloan/dynamite/blob/499d10e893848075a9dc5e599c5936f84b548620/packages/dynamite/src/utils/RelayEnvironment.ts#L145-L156
  dataIdFromObject(responseObject) {
    // eslint-disable-next-line no-underscore-dangle
    switch (responseObject.__typename) {
      case 'security_product_rate':
        return `security_product_rate:${responseObject.loan_security_id}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

let apolloClient: ReturnType<typeof createClient> | null = null;

/**
 * Use this in afterEach() if you want to
 * clear the Apollo cache between tests.
 */
export async function clearApolloCacheForTest() {
  await apolloClient?.clearStore();
}

/**
 * Teardown the Apollo client, killing any active queries.
 *
 * Because we store the Apollo client reference outside the
 * component tree, client be still available
 * even after test component has been unmounted.
 * This means active query could still send request to server,
 * which results in unhandled request logged by msw server.
 *
 * To prevent extra request being sent after test has been completed,
 * we should call this function before resetting the msw handler.
 */
export function clearApolloClientForTest() {
  if (apolloClient) {
    apolloClient.stop();
    apolloClient = null;
  }
}

export function ApolloClientProvider(props: Props) {
  const { config } = useEnvConfig();
  const authContext = useAuthContext();

  const client = useMemo(
    () =>
      createClient({
        graphqlURL: config.graphqlURL,
        graphqlSubscriptionUrl: config.graphqlSubscriptionURL,
        cache: apolloCache,
        authContext,
      }),
    [config.graphqlURL, config.graphqlSubscriptionURL, authContext],
  );

  apolloClient = client;

  if (authContext?.isLoading) {
    return <AppLoading />;
  }
  return <ApolloProvider client={client} {...props} />;
}
