import { useEffect, useMemo } from 'react';

import { Location } from '../../components/StaticMapView';
import {
  useGetDomainPropertyDetailsLazyQuery,
  useGetLoanAccountPropertyDetailsQuery,
} from '../../generated/graphql';
import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import { captureException } from '../../sentry';

export const usePropertyValueInsight = (cbaAccountId: string) => {
  const { data: loan, loading: isLoadingLoanAccount } =
    useLoanAccountState(cbaAccountId);

  const effectiveBalance = loan?.balances?.balance;

  const { data: propertyDetails, loading: isLoadingLoanAccountDetails } =
    useGetLoanAccountPropertyDetailsQuery({
      variables: {
        cba_account_id: cbaAccountId,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Fetching loan account property details.',
        },
      },
    });

  const [
    getDomainPropertyDetails,
    {
      data: domainPropertyDetailsData,
      loading: isLoadingDomainPropertyDetails,
    },
  ] = useGetDomainPropertyDetailsLazyQuery({
    context: {
      sentryContext: {
        description: 'Fetching domain property details.',
      },
    },
  });

  const isLoading =
    isLoadingLoanAccount ||
    isLoadingLoanAccountDetails ||
    isLoadingDomainPropertyDetails;

  const unloanValuation =
    propertyDetails?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.selected_property_valuation?.amount;

  const propertyAddress =
    propertyDetails?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.property.address;
  const propertyShortAddress = propertyAddress?.short_address_format;
  const propertyState = `${propertyAddress?.suburb ?? ''} ${
    propertyAddress?.state ?? ''
  } ${propertyAddress?.postcode ?? ''}`.trim();
  const propertyDomainId = propertyAddress?.domain_api_property_id;

  const propertyGeoLocation = useMemo(() => {
    if (propertyAddress?.latitude && propertyAddress?.longitude) {
      return {
        latitude: propertyAddress.latitude,
        longitude: propertyAddress.longitude,
      } as Location;
    }

    if (propertyAddress?.display_address) {
      return propertyAddress?.display_address;
    }

    return null;
  }, [propertyAddress]);

  const domainValuation = useMemo(() => {
    if (
      domainPropertyDetailsData?.domain_price_estimate?.lower_price &&
      domainPropertyDetailsData?.domain_price_estimate?.mid_price &&
      domainPropertyDetailsData?.domain_price_estimate?.upper_price
    ) {
      return {
        lowValue: domainPropertyDetailsData.domain_price_estimate.lower_price,
        midValue: domainPropertyDetailsData.domain_price_estimate.mid_price,
        highValue: domainPropertyDetailsData.domain_price_estimate.upper_price,
      };
    }

    return null;
  }, [domainPropertyDetailsData]);

  const propertyValuation = useMemo(() => {
    if (domainValuation) {
      return domainValuation.midValue;
    }

    if (unloanValuation) {
      return unloanValuation;
    }

    if (!isLoading) {
      captureException('Fetch loan account property valuation', {
        cbaAccountId,
        description:
          'Unexpected error: there is no available Domain Valuation or Unloan Valuation.',
      });
    }

    return null;
  }, [cbaAccountId, domainValuation, isLoading, unloanValuation]);

  const equity = useMemo(() => {
    if (effectiveBalance) {
      if (domainValuation) {
        return {
          equityAmount: domainValuation.midValue - effectiveBalance,
          equityPercentage:
            ((domainValuation.midValue - effectiveBalance) /
              domainValuation.midValue) *
            100,
        };
      }

      if (unloanValuation) {
        return {
          equityAmount: unloanValuation - effectiveBalance,
          equityPercentage:
            ((unloanValuation - effectiveBalance) / unloanValuation) * 100,
        };
      }

      return null;
    }
    return null;
  }, [effectiveBalance, domainValuation, unloanValuation]);

  useEffect(() => {
    if (propertyDomainId) {
      getDomainPropertyDetails({
        variables: {
          id: propertyDomainId,
        },
        context: {
          sentryContext: {
            cbaAccountId,
            propertyDomainId,
          },
        },
      });
    }
  }, [cbaAccountId, getDomainPropertyDetails, propertyDomainId]);

  return {
    propertyValuation,
    propertyShortAddress,
    propertyGeoLocation,
    propertyState,
    domainPropertyDetailsData,
    unloanValuation,
    domainValuation,
    equity,
    loading: isLoading,
  };
};
