import ContentJson from '../../../assets/newFeatures/content.json';
import { TestID } from '../../../testID/constants';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { BaseNewFeatures } from '../components/BaseNewFeatures';

export type NewFeaturesModalProps =
  SingleModalStackScreenProps<Screen.NEW_FEATURES_MODAL>;

export function NewFeaturesModal({ navigation }: NewFeaturesModalProps) {
  return (
    <ModalScreenContainer
      testID={TestID.NewFeaturesModal}
      onClose={navigation.goBack}
      hideBackButton
      scrollable
    >
      <BaseNewFeatures content={ContentJson} />
    </ModalScreenContainer>
  );
}
