import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { DataSharingEventType } from '../../DataSharingConfirmation/types';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { ActionSheetType } from '../../navigation/types/screens';
import { StyledText } from '../../ui/atoms/StyledText';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE>;

const Message: React.FC<{
  closeModal: () => void;
  message: string;
}> = ({ closeModal, message }) => (
  <>
    <GreenCheckAnimation
      testID={TestID.ActionSheet.DataSharingConfirmation.GreenCheck}
      onAnimationFinish={closeModal}
    />
    <StyledText variant="body" mx="m" my="l" textAlign="center">
      {message}
    </StyledText>
  </>
);

const ConfirmationMessage = {
  [DataSharingEventType.Disable]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.DataSharingStopped',
  ),
  [DataSharingEventType.Cancel]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.CancelDataSharingRequest',
  ),
  [DataSharingEventType.Decline]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.DeclineDataSharingRequest',
  ),
};

const DataSharingConfirmationMessage: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { dataSharingEventType } = route.params;

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  const message = ConfirmationMessage[dataSharingEventType];

  return (
    <ActionSheet
      closeModal={closeModal}
      title=""
      headerRightTitle={' '}
      message={<Message closeModal={closeModal} message={message || ''} />}
    />
  );
};

export default DataSharingConfirmationMessage;
