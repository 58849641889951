import { useDripsyTheme, View } from 'dripsy';
import { useEffect } from 'react';
import Animated, {
  Easing,
  useAnimatedProps,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';
import Svg, { G, Rect } from 'react-native-svg';

import { GRADIENT } from '../../constants';
import { SvgGradientDefs } from '../SvgGradientDefs';

const AnimatedRect = Animated.createAnimatedComponent(Rect);

type Props = {
  equityPercentage: number;
};

export const EstimatedEquityPositionBar = ({ equityPercentage }: Props) => {
  const progressBar = useSharedValue(0);
  const animatedProps = useAnimatedProps(() => ({
    width: `${progressBar.value}%`,
  }));
  const { theme } = useDripsyTheme();

  useEffect(() => {
    progressBar.value = withDelay(
      300,
      withTiming(equityPercentage || 0, {
        duration: 2000,
        easing: Easing.out(Easing.ease),
      }),
    );
  }, [equityPercentage, progressBar]);

  return (
    <View
      sx={{
        height: theme.space.$16,
        borderRadius: theme.space.$4,
        overflow: 'hidden',
        backgroundColor: '$buttonSecondaryBg',
      }}
    >
      <Svg width="100%" height="100%">
        <G>
          <SvgGradientDefs gradient={GRADIENT} id="equityPositionBar" />
          <AnimatedRect
            transform=""
            height="100%"
            x={0}
            y={0}
            animatedProps={animatedProps}
            rx={1}
            fill="url(#equityPositionBar)"
          />
        </G>
      </Svg>
    </View>
  );
};
