import { createRestyleComponent } from '@shopify/restyle';
import * as React from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';

import { Box } from '../atoms/Box';
import { StyledIcon } from '../atoms/StyledIcon';
import { Theme } from '../theme';
import { boxRestyleFunctions, BoxStyleProps } from '../utils/createBox';

type BaseButtonProps = BoxStyleProps &
  Omit<TouchableOpacityProps, keyof BoxStyleProps> & {
    children?: React.ReactNode;
  };

export const StepperButton = createRestyleComponent<BaseButtonProps, Theme>(
  boxRestyleFunctions,
  TouchableOpacity,
);

export type Props = {
  onDecreasePress?: () => void;
  onIncreasePress?: () => void;
  decreaseButtonTestID?: string;
  increaseButtonTestID?: string;
};

export function Stepper({
  onDecreasePress,
  onIncreasePress,
  decreaseButtonTestID,
  increaseButtonTestID,
}: Props) {
  return (
    <Box
      bg="buttonSecondaryBg"
      row
      py="xs"
      borderRadius="rowIcon"
      alignSelf="flex-end"
    >
      <StepperButton
        alignItems="center"
        px="m"
        onPress={onDecreasePress}
        testID={decreaseButtonTestID}
      >
        <StyledIcon name="remove" />
      </StepperButton>
      <Box bg="border" width={1} my="xs" />
      <StepperButton
        alignItems="center"
        px="m"
        onPress={onIncreasePress}
        testID={increaseButtonTestID}
      >
        <StyledIcon name="add" />
      </StepperButton>
    </Box>
  );
}
