import { gql } from '@apollo/client';

export const AddressSearchSuggestion = gql`
  fragment AddressSearchSuggestion on address_suggestion {
    domain_api_property_id
    display_address
    unit_no
    street_no
    street
    street_type
    suburb
    postcode
    state
  }
`;

export const HasuraAddress = gql`
  fragment HasuraAddress on address {
    building_name
    country_code
    display_address
    dpid
    id
    latitude
    level_no
    level_type
    longitude
    lot_no
    postcode
    state
    street
    street_no
    street_no_two
    street_type
    suburb
    unit_no
    unit_type
    domain_api_property_id
    provided_by_user_id
    full_display_address
    short_address_format
    short_address_format_two
  }
`;

export const LOAN_APPLICATION = gql`
  fragment LoanApplication on loan_application {
    id
    status
    type
  }
`;

export const LOAN_APPLICATION_SECURITY = gql`
  fragment LoanApplicationSecurityDetails on loan_application_security {
    id
    loan_application_target_id
    loan_application_target {
      id
    }
    property_type
    property_purpose
    owner_estimated_value
    purchase_price
    is_first_home_buyer
    property {
      id
      address {
        ...HasuraAddress
      }
    }
  }
  ${HasuraAddress}
`;

// Financials Fragments

export const EMPLOYMENT_INCOME_FRAGMENT = gql`
  fragment EmploymentIncome on employment_income {
    id
    annual_overtime
    annual_bonus
    annual_commissions
    annual_allowances
    employer
    employment_type
    occupation_type
  }
`;

export const RENTAL_INCOME_FRAGMENT = gql`
  fragment RentalIncome on rental_income {
    id
    rental_income_type
    address {
      ...HasuraAddress
    }
  }

  ${HasuraAddress}
`;

export const EXPECTED_RENTAL_INCOME_FRAGMENT = gql`
  fragment ExpectedRentalIncome on rental_income {
    id
    rental_income_type
    expected_suburb
    rental_expense
    rental_expense_frequency
    estimated_loan_amount
    address {
      ...HasuraAddress
    }
  }

  ${HasuraAddress}
`;

export const INCOME_OWNER_FRAGMENT = gql`
  fragment IncomeOwner on income_owner {
    id
    applicant {
      id
      is_repaying_help
      hecs_loan_balance
      latest_full_name
      user_identity_profile {
        id
      }
    }
  }
`;
