import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function InfoIcon({
  color,
  size,
  fillOpacity,
  ...otherProps
}: SvgIconProps) {
  return (
    <Svg
      width={resizeIconWidth(18, 19, size)}
      height={size}
      viewBox="0 0 18 19"
      fill={color}
      fillOpacity={fillOpacity}
      {...otherProps}
    >
      <Path d="M9 17.75C13.5132 17.75 17.25 14.0051 17.25 9.5C17.25 4.98676 13.5051 1.25 8.99191 1.25C4.48676 1.25 0.75 4.98676 0.75 9.5C0.75 14.0051 4.49485 17.75 9 17.75ZM9 16.375C5.18235 16.375 2.13309 13.3176 2.13309 9.5C2.13309 5.68235 5.17426 2.625 8.99191 2.625C12.8096 2.625 15.875 5.68235 15.875 9.5C15.875 13.3176 12.8176 16.375 9 16.375ZM8.92721 6.70147C9.51765 6.70147 9.97868 6.23235 9.97868 5.64191C9.97868 5.05147 9.51765 4.58235 8.92721 4.58235C8.34485 4.58235 7.87574 5.05147 7.87574 5.64191C7.87574 6.23235 8.34485 6.70147 8.92721 6.70147ZM7.58456 14.0294H10.8765C11.2081 14.0294 11.4669 13.7868 11.4669 13.4551C11.4669 13.1397 11.2081 12.889 10.8765 12.889H9.87353V8.77206C9.87353 8.33529 9.65515 8.04412 9.24265 8.04412H7.72206C7.39044 8.04412 7.13162 8.29485 7.13162 8.61029C7.13162 8.94191 7.39044 9.18456 7.72206 9.18456H8.5875V12.889H7.58456C7.25294 12.889 6.99412 13.1397 6.99412 13.4551C6.99412 13.7868 7.25294 14.0294 7.58456 14.0294Z" />
    </Svg>
  );
}
