import { monthsToYears } from 'date-fns';
import {
  A,
  Pressable,
  styled,
  SxProp,
  Text,
  useDripsyTheme,
  View,
} from 'dripsy';
import { PressableProps } from 'react-native';

import {
  Frequency_Enum,
  Product_Rate_By_Loan_Application_Target_Output,
  Product_Type_Enum,
  Repayment_Calculation_Error,
} from '../../generated/graphql';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { Skeleton } from '../../ui/v2/Skeleton';
import {
  formatCurrency,
  formatCurrencyWithPeriod,
} from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { formatInterestRates } from '../../utils/stringHelpers';
import { Maybe } from '../../utils/typesHelpers';

type Props = {
  containerSx?: SxProp;
  loanAmount: number | undefined | null;
  lmiPremium: number | undefined | null;
  loanAmountLoading?: boolean;
  minRepaymentAmount: Maybe<number>;
  minRepaymentCalcError: Maybe<Repayment_Calculation_Error>;
  minRepaymentAmountLoading?: boolean;
  termInMonths: number;
  governmentFees: number | undefined;
  interestRate: Product_Rate_By_Loan_Application_Target_Output['interest_rate'];
  rateAdjustment: Product_Rate_By_Loan_Application_Target_Output['rate_adjustment'];
  productType: Product_Rate_By_Loan_Application_Target_Output['product_type'];
  canBePressed: boolean;
  cardTestID?: string;
  totalLoanAmountTestID?: string;
  onPress: PressableProps['onPress'];
  showLinkToLmiFactSheet?: boolean;
};

function mapProductTypeToInterestRateCaption(
  rawProductType: Props['productType'],
  {
    discountRate,
  }: {
    discountRate: string;
  },
): string {
  const productType = parseEnumType(Product_Type_Enum, rawProductType);
  switch (productType) {
    case Product_Type_Enum.VariableInvestPrincipalInterest:
      return t('Content.PurchaseYourUnloanCard.InvestInterestRateCaption', {
        discountRate,
      });
    case Product_Type_Enum.VariableLiveInPrincipalInterest:
      return t('Content.PurchaseYourUnloanCard.LiveInInterestRateCaption', {
        discountRate,
      });
    case Product_Type_Enum.VariableLiveInPrincipalInterestStaff:
      return t('Content.PurchaseYourUnloanCard.LiveInInterestRateCaption', {
        discountRate,
      });
    default:
      return '--';
  }
}

export function PurchaseYourUnloanCard({
  containerSx,
  loanAmount,
  loanAmountLoading,
  minRepaymentAmount,
  minRepaymentCalcError,
  minRepaymentAmountLoading,
  termInMonths,
  interestRate,
  rateAdjustment,
  productType,
  onPress,
  governmentFees,
  lmiPremium,
  canBePressed,
  cardTestID,
  totalLoanAmountTestID,
  showLinkToLmiFactSheet,
}: Props) {
  const loanTermInYears = monthsToYears(termInMonths);

  let minRepaymentText = t('Content.Common.X/Month', { x: '$--' });
  if (minRepaymentAmountLoading) {
    minRepaymentText = '...';
  } else if (minRepaymentCalcError == null && minRepaymentAmount != null) {
    minRepaymentText = formatCurrencyWithPeriod(
      minRepaymentAmount,
      Frequency_Enum.Monthly,
    );
  }

  const RowWrapper = canBePressed ? Pressable : View;

  const interestRateCaption = mapProductTypeToInterestRateCaption(productType, {
    discountRate: rateAdjustment
      ? // Rate adjustment is negative (e.g. -0.01)
        // and we want to display it as positive discount rate (0.01%)
        Math.abs(rateAdjustment).toFixed(2)
      : '--',
  });

  const { theme } = useDripsyTheme();
  return (
    <View
      sx={{ bg: '$shapeBackground', borderRadius: '$card', ...containerSx }}
    >
      <RowWrapper
        sx={{ pt: '$16', px: '$16' }}
        onPress={canBePressed ? onPress : undefined}
        testID={cardTestID}
      >
        <RowView
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '$16',
            borderBottomWidth: '$1',
            borderColor: '$border',
          }}
        >
          <Text sx={{ fontWeight: '600' }}>
            {t('Content.PurchaseYourUnloanCard.LoanDetails')}
          </Text>
          {canBePressed ? (
            <StyledIcon
              name="chevronRight"
              family="svg"
              size="s"
              color="secondaryContent"
            />
          ) : null}
        </RowView>
      </RowWrapper>

      <View
        sx={{
          pt: '$8',
          pb: '$16',
          mx: '$16',
          borderBottomColor: '$border',
          borderBottomWidth: 1,
        }}
      >
        <Text variant="xsHeader">
          {t('Content.PurchaseYourUnloanCard.TotalLoanAmount')}
        </Text>
        {loanAmountLoading ? (
          <Skeleton width={120} height={theme.text.sHeader.lineHeight} show />
        ) : (
          <Text variant="sHeader" testID={totalLoanAmountTestID}>
            {formatCurrency(loanAmount, { noFraction: false })}
          </Text>
        )}
        <Text variant="caption" sx={{ pt: '$8' }}>
          {lmiPremium
            ? t('Content.PurchaseYourUnloanCard.IncludesGovernmentFeesAndLmi', {
                governmentFees: governmentFees
                  ? formatCurrency(governmentFees, { noFraction: false })
                  : '--',
                lmiPremium: formatCurrency(lmiPremium, { noFraction: false }),
              })
            : t('Content.PurchaseYourUnloanCard.IncludesGovernmentFees', {
                governmentFees: governmentFees
                  ? formatCurrency(governmentFees, { noFraction: false })
                  : '--',
              })}
          {showLinkToLmiFactSheet ? (
            <A
              variants={['caption', 'link']}
              href={t('Link.LmiFactSheet')}
              hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            >
              {` ${t('Content.PurchaseYourUnloan.ViewLmiFactSheet')}`}
            </A>
          ) : null}
        </Text>
      </View>
      <View
        sx={{
          pt: '$8',
          pb: '$16',
          mx: '$16',
          borderBottomColor: '$border',
          borderBottomWidth: 1,
        }}
      >
        <Text variant="xsHeader">
          {t('Content.PurchaseYourUnloanCard.InterestRate')}
        </Text>
        <Text variant="sHeader">{formatInterestRates([interestRate])}</Text>
        <Text variant="caption" sx={{ pt: '$8' }}>
          {interestRateCaption}
        </Text>
      </View>
      <View
        sx={{
          pt: '$8',
          pb: '$16',
          mx: '$16',
          borderBottomColor: '$border',
          borderBottomWidth: 1,
        }}
      >
        <Text variant="xsHeader">
          {t('Content.PurchaseYourUnloanCard.Term')}
        </Text>
        <Text variant="sHeader">
          {t('Content.Common.YearPluralization', {
            count: loanTermInYears,
          })}
        </Text>
      </View>
      <View
        sx={{
          pt: '$8',
          pb: '$16',
          mx: '$16',
        }}
      >
        <Text variant="xsHeader">
          {t('Content.PurchaseYourUnloanCard.MinimumRepayments')}
        </Text>
        <Text variant="sHeader">{minRepaymentText}</Text>
        <Text variant="caption" sx={{ pt: '$8' }}>
          {t('Content.PurchaseYourUnloanCard.MinimumRepaymentsCaption')}
        </Text>
      </View>
    </View>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
