import mitt from 'mitt';

export type FastlinkEvent = {
  completed: {
    requestId: string;
    institutionConnectionId: number;
  };
  failed: {
    reason?: string;
    requestId: string;
    institutionConnectionId: number;
  };
};

const fastlinkEmitter = mitt<FastlinkEvent>();

export { fastlinkEmitter };
