import { useDripsyTheme } from 'dripsy';

import { Card } from '../../../ui/atoms/Card';
import { Separator } from '../../../ui/atoms/Separator';
import { useLoanAccountInterestSavings } from '../../hooks/useLoanAccountInterestSavings';
import { Disclaimer } from './Disclaimer';
import { NoInterestSavingsAlert } from './NoInterestSavingsAlert';
import { PredictedTotalInterestSavingsCaption } from './PredictedTotalInterestSavingsCaption';
import { SavedOnInterestBarChartGraph } from './SavedOnInterestBarChartGraph';

type Props = {
  historicalInterestSavings: NonNullable<
    ReturnType<
      typeof useLoanAccountInterestSavings
    >['historicalInterestSavings']
  >;
  totalInterestSavingsUntilNow: number;
  predictedTotalInterestSavings: number;
};

export const SavedOnInterestCard = ({
  historicalInterestSavings,
  totalInterestSavingsUntilNow,
  predictedTotalInterestSavings,
}: Props) => {
  const { theme } = useDripsyTheme();
  const noTotalInterestSavingsUntilNow = !totalInterestSavingsUntilNow;
  const noSavingsInPassedPeriod =
    historicalInterestSavings.length === 0 ||
    historicalInterestSavings.every((saving) => saving.interestSaving === 0);

  if (noTotalInterestSavingsUntilNow) {
    return (
      <Card
        mb="l"
        style={{
          paddingVertical: theme.space.$32,
          paddingHorizontal: theme.space.$24,
        }}
      >
        <NoInterestSavingsAlert
          amount={0}
          alertMessage={t(
            'Content.HomeLoan.Insights.TotalInterestSavings.YouCloudBeSavingOnInterest',
          )}
        />
      </Card>
    );
  }

  if (!noTotalInterestSavingsUntilNow && noSavingsInPassedPeriod) {
    return (
      <Card
        mb="l"
        style={{
          paddingVertical: theme.space.$32,
          paddingHorizontal: theme.space.$24,
        }}
      >
        <NoInterestSavingsAlert
          amount={totalInterestSavingsUntilNow}
          alertMessage={t(
            'Content.HomeLoan.Insights.TotalInterestSavings.YouHaveNotSavingInPassSixMonths',
          )}
        />
      </Card>
    );
  }

  return (
    <>
      <Card
        style={{
          paddingTop: theme.space.$18,
          paddingBottom: theme.space.$32,
          paddingHorizontal: theme.space.$24,
        }}
      >
        <SavedOnInterestBarChartGraph
          values={
            historicalInterestSavings?.map(
              (saving) => saving.interestSaving || 0,
            ) || []
          }
          labels={
            historicalInterestSavings?.map(
              (saving) => saving.scheduleDate || '',
            ) || []
          }
          totalInterestSavingsUntilNow={totalInterestSavingsUntilNow}
        />
        <Separator
          style={{
            marginVertical: theme.space.$24,
          }}
        />

        <PredictedTotalInterestSavingsCaption
          amount={predictedTotalInterestSavings}
        />
      </Card>
      <Disclaimer />
    </>
  );
};
