import { gql } from '@apollo/client';

import { useGetMeQuery } from '../generated/graphql';
import { HasuraAddress } from '../LoanApplication/graphql/fragments';
import { useApolloClient } from '../utils/apollo';
import { getLogger } from '../utils/loggerHelper';
import { useAuthContext } from './context';

const logger = getLogger('AUTH');

export const empty = {
  object: {},
  array: [],
};

export const GET_ME_QUERY = gql`
  query GetMe {
    me {
      id
      user {
        id
        intercom_connection {
          user_id
          hash_id
          ios_hash_id
          android_hash_id
        }
        cba_customer_id
        email
        mobile
        preferred_name
        applicants {
          id
          loan_application_id
          role
          loan_application {
            id
            type
          }
        }
        residential_address {
          ...HasuraAddress
        }
        mailing_address {
          ...HasuraAddress
        }
        identity_profile {
          id
          first_name
          last_name
          full_name
          frankieone_entity_id
          has_active_id_validation_restart_request
          id_validation_result
        }
      }
    }
  }

  ${HasuraAddress}
`;

export const useUser = () => {
  const { error, loading, data } = useGetMeQuery();

  const user = data?.me?.[0]?.user;
  return { user: error ? null : user, loading, error };
};

export const useAuth = () => {
  const apolloClient = useApolloClient();
  const authContext = useAuthContext();

  logger?.log(
    JSON.stringify({
      isLoading: authContext?.isLoading,
      isAuthenticated: authContext?.isAuthenticated,
    }),
  );
  return {
    login: (inviteCode?: string) => authContext?.login({ inviteCode }),
    logout: () => {
      authContext?.logout();
      apolloClient?.clearStore();
    },
    loading: authContext?.isLoading,
    isAuthenticated: authContext?.isAuthenticated,
    isLoggedIn: authContext?.isLoggedIn,
  };
};
