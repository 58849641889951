import { createStackNavigator } from '@react-navigation/stack';

import { AccountPermissions } from '../../AccountPermissions/screens/AccountPermissions';
import { AccountPermissionsApprovalScreen } from '../../AccountPermissions/screens/AccountPermissionsApprovalScreen';
import { AccountAddressBook } from '../../HomeLoan/screens/AccountAddressBook/AccountAddressBook';
import { AddNewAccount } from '../../HomeLoan/screens/AccountAddressBook/AddNewAccount';
import { LoanDetails } from '../../HomeLoan/screens/LoanDetails';
import { VOICompletedDashboard } from '../../LoanApplication/screens/VOICompletedDashboard';
import { ReduceRepayments } from '../../LoanVariation/screens/ReduceRepayments';
import { ReduceRepaymentsApprovalScreen } from '../../LoanVariation/screens/ReduceRepaymentsApprovalScreen';
import { ReduceRepaymentsConfirmation } from '../../LoanVariation/screens/ReduceRepaymentsConfirmation';
import { HomeDashboardStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { StatementsListV2 } from '../../Statements/screens/StatementsListV2';
import { useView } from '../../utils/hooks/useBreakpoint';
import { WithAuthenticationHome } from '../screens/Home';
import { HomeLoan } from '../screens/HomeLoan';
import { TopNavBar } from './components/TopNavBar';

const Stack = createStackNavigator<HomeDashboardStackParams>();

export function HomeDashboardNavigator() {
  const { isMobileNativeView, isDesktopWebView } = useView();
  const headerShown = isMobileNativeView || isDesktopWebView;

  return (
    <Stack.Navigator
      screenOptions={{
        headerTransparent: true,
        header: TopNavBar,
      }}
    >
      <Stack.Screen
        name={Screen.HOME}
        component={WithAuthenticationHome}
        options={{ title: getPageTitle(Screen.HOME) }}
      />
      <Stack.Screen
        name={Screen.HOME_LOAN_SETTINGS}
        component={LoanDetails}
        options={{
          headerShown,
          title: getPageTitle(Screen.HOME_LOAN_SETTINGS),
        }}
      />
      <Stack.Screen
        name={Screen.ACCOUNT_ADDRESS_BOOK}
        component={AccountAddressBook}
        options={{
          headerShown,
          title: getPageTitle(Screen.ACCOUNT_ADDRESS_BOOK),
        }}
      />
      <Stack.Screen
        name={Screen.ADD_NEW_ACCOUNT}
        component={AddNewAccount}
        options={{
          headerShown,
          title: getPageTitle(Screen.ADD_NEW_ACCOUNT),
        }}
      />
      <Stack.Screen
        name={Screen.HOME_LOAN_STATEMENTS}
        component={StatementsListV2}
        options={{
          headerShown,
          title: getPageTitle(Screen.HOME_LOAN_STATEMENTS),
        }}
      />
      <Stack.Screen
        name={Screen.HOME_LOAN}
        component={HomeLoan}
        options={{
          headerShown,
          title: getPageTitle(Screen.HOME_LOAN),
        }}
      />
      <Stack.Screen
        name={Screen.ACCOUNT_PERMISSIONS}
        component={AccountPermissions}
        options={{
          headerShown,
          title: getPageTitle(Screen.ACCOUNT_PERMISSIONS),
        }}
      />
      <Stack.Screen
        name={Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN}
        component={AccountPermissionsApprovalScreen}
        options={{
          headerShown,
          title: getPageTitle(Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN),
        }}
      />
      <Stack.Screen
        name={Screen.LOAN_VARIATION_REDUCE_REPAYMENTS}
        component={ReduceRepayments}
        options={{
          headerShown,
          title: getPageTitle(Screen.LOAN_VARIATION_REDUCE_REPAYMENTS),
        }}
      />
      <Stack.Screen
        name={Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION}
        component={ReduceRepaymentsConfirmation}
        options={{
          headerShown,
          title: getPageTitle(
            Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION,
          ),
        }}
      />
      <Stack.Screen
        name={Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN}
        component={ReduceRepaymentsApprovalScreen}
        options={{
          headerShown,
          title: getPageTitle(
            Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN,
          ),
        }}
      />
      <Stack.Screen
        name={Screen.VOI_COMPLETED}
        component={VOICompletedDashboard}
        options={{
          title: getPageTitle(Screen.VOI_COMPLETED),
        }}
      />
    </Stack.Navigator>
  );
}
