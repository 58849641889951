import omitBy from 'lodash/omitBy';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Literal, Number, Record, String } from 'runtypes';

import { captureException } from '../../sentry';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { Maybe } from '../../utils/typesHelpers';
import {
  FastlinkFlow,
  OnCloseData,
  OnErrorData,
  OnSuccessData,
} from './fastlinkWebTypes';

export const FASTLINK_CONFIG = {
  // This configName can be found in My Yodlee | Config Tool app. Currently we're using this as our configuration
  configName: 'Unloan',
};

export function constructFastlinkBody(
  accessToken: string | undefined | null,
  providerId: Maybe<number>,
  fastlinkConfig = FASTLINK_CONFIG,
) {
  // Didnt support nested object, so we need to split the stringify function
  const extraParams = qs.stringify({
    ...fastlinkConfig,
    providerId,
  });
  return qs.stringify({
    accessToken: `Bearer ${accessToken}`,
    extraParams,
  });
}

export function useConstructFastlinkConfigForNative({
  accessToken,
  institutionId,
}: {
  accessToken: Maybe<string>;
  institutionId?: Maybe<number>;
}) {
  const { config } = useEnvConfig();
  const postData = constructFastlinkBody(accessToken, institutionId);
  return {
    uri: config.fastlinkURL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: postData,
    method: 'POST',
  };
}

export function parseFastlinkData(data: string) {
  try {
    const parsedData = JSON.parse(data);
    const FastLinkPostMessage = Record({
      data: Record({
        additionalStatus: String,
        fnToCall: String,
        providerAccountId: Number,
        providerId: Number,
        providerName: String,
        requestId: String,
        status: String,
        reason: String.optional(),
      }),
      type: Literal('POST_MESSAGE'),
    });
    if (FastLinkPostMessage.guard(parsedData)) {
      return parsedData;
    }
    return null;
  } catch (e: unknown) {
    return null;
  }
}

const FASTLINK_SCRIPT_ELEMENT_ID = 'fastlink-script';
export function useYodleeFastlinkWeb({
  fastlinkContainerElementId,
  onSuccess,
  onError,
  onClose,
  errorContext = {},
  flow,
  providerAccountId,
}: {
  fastlinkContainerElementId: string;
  onSuccess: (data: OnSuccessData) => void;
  onError?: (data: OnErrorData) => void;
  onClose: (data: OnCloseData) => void;
  errorContext?: { loanApplicationId?: string };
  flow?: FastlinkFlow;
  providerAccountId?: number;
}) {
  const [scriptLoading, setScriptLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { config } = useEnvConfig();

  const onErrorWrapped = (data: OnErrorData) => {
    let errorMessage = 'Error during account linking process inside Fastlink';
    if (data.fnToCall === 'errorHandler') {
      errorMessage = 'Error from Yodlee side when doing action inside Fastlink';
    }
    captureException(errorMessage, { data });
    onError?.(data);
  };

  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onErrorWrapped);
  const onCloseRef = useRef(onClose);

  useEffect(() => {
    onSuccessRef.current = onSuccess;
    onCloseRef.current = onClose;
    onErrorRef.current = onErrorWrapped;
  });

  useEffect(() => {
    const fastlinkScriptElm = document.getElementById(
      FASTLINK_SCRIPT_ELEMENT_ID,
    );
    if (fastlinkScriptElm != null) {
      return () => {
        window.fastlink?.close?.();
        document.body.removeChild(fastlinkScriptElm);
      };
    }
    const script = document.createElement('script');
    script.id = FASTLINK_SCRIPT_ELEMENT_ID;
    script.src = config.fastlinkScriptURL;
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoading(false);
    script.onerror = () => {
      // eslint-disable-next-line no-console
      console.error('Unable to load Yodlee Fastlink lib');
      setError('Unable to Load');
      setScriptLoading(false);
    };

    document.body.appendChild(script);

    return () => {
      window.fastlink?.close?.();
      document.body.removeChild(script);
    };
  }, [config.fastlinkScriptURL]);

  const initFastlink = useCallback(
    ({ accessToken }: { accessToken: string }) => {
      try {
        // TODO Detect whether the element has been rendered to improve UX
        const fastlinkContainer = document.getElementById(
          fastlinkContainerElementId,
        );
        if (fastlinkContainer == null) {
          return;
        }

        const rawOptionalParams = { providerAccountId, flow };
        // We need to filter fields that have undefined value. If not, the undefined will get stringified as "undefined" when sending the param to Fastlink
        const optionalParams = omitBy(rawOptionalParams, (val) => val == null);
        window.fastlink?.open?.(
          {
            fastLinkURL: config.fastlinkURL,
            // TODO Create construct access token header
            accessToken: `Bearer ${accessToken}`,
            params: {
              configName: FASTLINK_CONFIG.configName,
              obAppName: config.fastlinkObAppName,
              ...optionalParams,
            },
            onSuccess: onSuccessRef.current,
            onClose: onCloseRef.current,
            onError: onErrorRef.current,
            forceIframe: true,
          },
          fastlinkContainerElementId,
        );
      } catch (e: unknown) {
        captureException(
          'Error while loading FastLink element',
          errorContext,
          e,
        );
      }
    },
    [
      providerAccountId,
      flow,
      fastlinkContainerElementId,
      errorContext,
      config.fastlinkURL,
      config.fastlinkObAppName,
    ],
  );

  return { initFastlink, loading: scriptLoading, error };
}
