import { Liability_Type_Enum } from '../../generated/graphql';
import { getPageGTMTitle, Screen } from '../../navigation/types/screens';
import { FieldInteractionKey, SectionInteractionKey } from '../types';

/**
 * Generates the application interaction event key for Google Tag Manager
 * in the format `section-virtual_page_name-field`. The typing is to ensure
 * that we only construct keys using the below helper functions.
 */

export type ApplicationInteractionEventKeyType = string & {
  __ApplicationInteractionEventKeyType: true;
};

export function buildApplicationInteractionEventKey(
  section: SectionInteractionKey,
  screen: Screen,
  field: FieldInteractionKey,
): ApplicationInteractionEventKeyType {
  const pageGTMTitle = getPageGTMTitle(screen);

  return `${section}-${formatKey(
    pageGTMTitle,
  )}-${field}` as ApplicationInteractionEventKeyType;
}

export function buildApplicationInteractionEventKeyForLoanDetailsForm(
  screen: Screen,
  liabilityType: Liability_Type_Enum,
  field: FieldInteractionKey,
): ApplicationInteractionEventKeyType {
  const pageGTMTitle = getPageGTMTitle(screen);
  const section = SectionInteractionKey.Debts;

  return `${section}-${formatKey(pageGTMTitle)}_${formatKey(
    liabilityType,
  )}-${field}` as ApplicationInteractionEventKeyType;
}

export function formatKey(input: string): string {
  return input.replace(/[\s-]+/g, '_').toLowerCase();
}
