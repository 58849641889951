import { useMemo } from 'react';

import { oneOfEnum } from '../../components/utils/oneOfEnum';
import { UNLOAN_BSB } from '../../constants/unloan';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { ExternalAccount } from '../../HomeLoan/types';
import { formatCurrency } from '../../utils/currencyHelpers';
import { getMaximumDate, getMinimumDateToday } from '../../utils/dateHelpers';
import { formatBsb } from '../../utils/formatBsbAndAccountNumber';
import {
  yup,
  yupExternalAccountBsb,
  yupExternalAccountName,
  yupExternalAccountNumber,
} from '../../utils/yup';
import {
  AutoPaySetupFormField,
  MinimumRepaymentAmountsByFrequency,
} from '../types';

type UseAutopaySetupV2ValidationSchemaProps = {
  maximumDirectDebitAmount: number;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  externalAccounts: ExternalAccount[];
};

export const autopayStartDateValidationSchema = () =>
  yup
    .date()
    .required(t('Content.AutopaySettings.SelectStartDate'))
    .typeError(t('Content.AutopaySettings.SelectStartDate'))
    .min(
      getMinimumDateToday(),
      t('Content.AutopaySettings.InvalidDateMustBeInFuture'),
    )
    .max(
      getMaximumDate(),
      t('Content.AutopaySettings.InvalidDateMustBeNotTooFarInFuture'),
    );

export const useAutopaySetupV2ValidationSchema = ({
  minimumRepaymentAmountsByFrequency,
  maximumDirectDebitAmount,
  externalAccounts,
}: UseAutopaySetupV2ValidationSchemaProps) => {
  const customRepaymentAmountYup = useMemo(
    () =>
      yup
        .number()
        .typeError(t('Content.AutopaySettings.RepaymentAmountRequired'))
        .required(t('Content.AutopaySettings.RepaymentAmountRequired'))
        .max(
          maximumDirectDebitAmount,
          t('Content.AutopaySettings.MaximumDirectDebitLimit', {
            value: formatCurrency(maximumDirectDebitAmount),
          }),
        ),
    [maximumDirectDebitAmount],
  );

  return useMemo(
    () =>
      yup.object({
        [AutoPaySetupFormField.IsCustomRepaymentAmount]: yup.boolean(),

        [AutoPaySetupFormField.RepaymentFrequency]: yup
          .mixed<string>()
          .nullable()
          .when(AutoPaySetupFormField.IsCustomRepaymentAmount, {
            is: false,
            then: oneOfEnum(Autopay_Frequency_Input_Enum).required(
              t('Content.AutopaySettings.RepaymentFrequencyRequired'),
            ),
          }),
        [AutoPaySetupFormField.RepaymentAmount]: yup
          .number()
          .nullable()
          .when(AutoPaySetupFormField.IsCustomRepaymentAmount, {
            is: true,
            then: customRepaymentAmountYup.when(
              AutoPaySetupFormField.RepaymentFrequency,
              (frequency: Autopay_Frequency_Input_Enum | null) => {
                if (!frequency) {
                  return customRepaymentAmountYup;
                }

                const minimumAmount =
                  minimumRepaymentAmountsByFrequency[frequency];

                return customRepaymentAmountYup.min(
                  minimumAmount,
                  t('Content.AutopaySettings.InvalidCustomRepaymentTooLow', {
                    frequency: frequency.toLowerCase(),
                    minimumAmount: formatCurrency(minimumAmount),
                  }),
                );
              },
            ),
          }),
        [AutoPaySetupFormField.PaymentStartDate]:
          autopayStartDateValidationSchema(),
        [AutoPaySetupFormField.IsManualAccountInput]: yup.boolean(),
        [AutoPaySetupFormField.ExternalAccountId]: yup
          .string()
          .when(AutoPaySetupFormField.IsManualAccountInput, {
            is: false,
            then: yup
              .string()
              .uuid()
              .required(t('Content.AutopaySettings.AccountRequired'))
              .test(
                'invalidBsb',
                t('Content.AutopaySettings.InvalidBsb'),
                (value) => {
                  const externalAccount = externalAccounts.find(
                    (account) => account.id === value,
                  );

                  return (
                    formatBsb(externalAccount?.account_bsb ?? '') !==
                    formatBsb(UNLOAN_BSB)
                  );
                },
              ),
          }),
        [AutoPaySetupFormField.DirectDebitAccountName]: yup
          .string()
          .when(AutoPaySetupFormField.IsManualAccountInput, {
            is: true,
            then: yupExternalAccountName,
          }),
        [AutoPaySetupFormField.DirectDebitAccountBsb]: yup
          .string()
          .when(AutoPaySetupFormField.IsManualAccountInput, {
            is: true,
            then: yupExternalAccountBsb.test(
              'invalidBsb',
              t('Content.AutopaySettings.InvalidBsb'),
              (value = '') => formatBsb(value) !== formatBsb(UNLOAN_BSB),
            ),
          }),
        [AutoPaySetupFormField.DirectDebitAccountNumber]: yup
          .string()
          .when(AutoPaySetupFormField.IsManualAccountInput, {
            is: true,
            then: yupExternalAccountNumber,
          }),
      }),
    [
      customRepaymentAmountYup,
      externalAccounts,
      minimumRepaymentAmountsByFrequency,
    ],
  );
};
