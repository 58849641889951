import { isNotNullOrUndefined } from '@unloan/common-ui';

import { Liability_Type_Enum } from '../generated/graphql';

type Liability = {
  id: string;
  current_liability_id?: string | null | undefined;
  dynamite_liability_type?: string | null | undefined;
  dynamite_address?:
    | {
        id: string;
        domain_api_property_id?: string | null | undefined;
        full_display_address?: string | null | undefined;
      }
    | null
    | undefined;
};

type LoanApplicationSecurity = {
  id: string;
  property: {
    id: string;
    address: {
      id: string;
      domain_api_property_id?: string | null | undefined;
      short_address_format?: string | null | undefined;
      full_display_address?: string | null | undefined;
    };
  };
};

export function liabilitiesMatchingSecurity(
  liabilities: Array<Liability> | null | undefined,
  securities: Array<LoanApplicationSecurity> | null | undefined,
): Array<Liability> {
  if (!liabilities || !securities) {
    return [];
  }
  const securityAddresses = securities.map(
    (security) => security.property.address,
  );
  const securityFullDisplayAddresses = securityAddresses
    .map((address) => address.full_display_address?.toLowerCase())
    .filter(isNotNullOrUndefined);
  const securityAddressIds = new Set(
    securityAddresses.map((address) => address.id),
  );
  const securityAddressDomainApiIds = new Set(
    securityAddresses
      .map((address) => address.domain_api_property_id)
      .filter(isNotNullOrUndefined),
  );
  return liabilities.filter((liability) => {
    const liabilityAddress = liability.dynamite_address;
    if (
      liability.dynamite_liability_type === Liability_Type_Enum.HomeLoan &&
      liabilityAddress
    ) {
      const fullDisplayAddress =
        liabilityAddress.full_display_address?.toLowerCase();
      const doesFullDisplayAddressMatch = fullDisplayAddress
        ? securityFullDisplayAddresses.filter(
            (address) => address === fullDisplayAddress,
          ).length > 0
        : false;
      const doesAddressIdMatch = securityAddressIds.has(liabilityAddress.id);
      const doesAddressDomainApiIdMatch =
        liabilityAddress.domain_api_property_id
          ? securityAddressDomainApiIds.has(
              liabilityAddress.domain_api_property_id,
            )
          : false;
      return (
        doesAddressIdMatch ||
        doesAddressDomainApiIdMatch ||
        doesFullDisplayAddressMatch
      );
    }
    return false;
  });
}

export function anyLiabilityMatchingSecurity(
  liabilities: Array<Liability> | null | undefined,
  securities: Array<LoanApplicationSecurity> | null | undefined,
): boolean {
  return liabilitiesMatchingSecurity(liabilities, securities).length > 0;
}
