import { atomFamily } from 'recoil';

export const AccountPermissionsShowErrorAtomFamily = atomFamily<
  boolean | undefined,
  string
>({
  key: 'AccountPermissionsShowErrorAtomFamily',
  default: undefined,
});

export const LeaveAccountPermissionsScreenAtomFamily = atomFamily<
  boolean | undefined,
  string
>({
  key: 'LeaveAccountPermissionsScreenAtomFamily',
  default: undefined,
});

export const AccountPermissionsApprovalScreenErrorAtomFamily = atomFamily<
  boolean | undefined,
  string
>({
  key: 'AccountPermissionsApprovalScreenErrorAtomFamily',
  default: undefined,
});

export const LeaveAccountPermissionsApprovalScreenAtomFamily = atomFamily<
  boolean | undefined,
  string
>({
  key: 'LeaveAccountPermissionsApprovalScreenAtomFamily',
  default: undefined,
});
