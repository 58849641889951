import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { Text } from 'dripsy';
import { FormikProps } from 'formik';
import * as yup from 'yup';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormTextInputV2 } from '../../components/form/FormikInputs';
import { Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { FormErrorText, FormikFormError } from '../../ui/v2/FormError';
import { isWeb } from '../../utils/platformUtils';
import { PersonalDetailsModalForm } from './PersonalDetailsModalForm';

const validationSchema = yup.object({
  code: yup
    .string()
    .matches(/^[0-9]*$/, t('Content.PersonalDetails.Common.InvalidCode'))
    .length(6, t('Content.PersonalDetails.Common.InvalidCode'))
    .required(t('Content.PersonalDetails.ChangeMobile.CodeRequiredLabel')),
});

type BaseMobileVerifyValues = yup.Asserts<typeof validationSchema>;

const INIT_VALS: BaseMobileVerifyValues = {
  code: '',
};

type BaseMobileVerifyProps = {
  screen: Screen;
  description: string;
  errorMessage?: string;
  loading?: boolean;
  target: string;
  testID: string;
  title?: string;
  navigation: NavigationProp<ParamListBase>;
  onSubmit: (props: { code: string }) => void;
  onPressResend?: () => void;
  inputValidationError?: string;
};

export const BaseMobileVerify = ({
  screen,
  description,
  errorMessage,
  loading = false,
  target,
  testID,
  title,
  navigation,
  onSubmit,
  onPressResend,
  inputValidationError,
}: BaseMobileVerifyProps) => {
  const hasError = (formProps: FormikProps<BaseMobileVerifyValues>) =>
    formProps.touched.code && !!formProps.errors.code;

  return (
    <PersonalDetailsModalForm
      initialValues={INIT_VALS}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      navigation={navigation}
      showSpinner={loading}
      submitLabel={t('Content.Common.ButtonLabel.Continue')}
      title={title}
      errorMessage={errorMessage}
      description={
        <Text testID={testID} variant="sBody">
          {description}
          {'\n'}
          {target}.
        </Text>
      }
    >
      {(formProps) => (
        <>
          <FormTextInputV2
            inputTestID={TestID.PersonalDetails.ChangePersonalDetailsCodeInput}
            name="code"
            autoComplete="one-time-code"
            label={t('Content.PersonalDetails.ChangeMobile.CodeLabel')}
            maxLength={6}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.PersonalDetails,
              screen,
              FieldInteractionKey.Code,
            )}
          />
          <FormikFormError name="code" sx={{ mt: '$8' }} />

          {!hasError(formProps) && inputValidationError ? (
            <FormErrorText sx={{ mt: '$8' }}>
              {inputValidationError}
            </FormErrorText>
          ) : null}

          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.PersonalDetails.ChangeMobile.DidNotGetACodeLabel')}{' '}
            <Text
              testID={TestID.PersonalDetails.ChangePersonalDetailsResendCode}
              variant="caption"
              onPress={!errorMessage ? onPressResend : undefined}
              sx={{
                display: isWeb ? 'inline' : 'flex',
                color: onPressResend && !errorMessage ? '$link' : '$disabled',
              }}
            >
              {t('Content.PersonalDetails.ChangeMobile.ResendCodeLinkLabel')}
            </Text>
            {!onPressResend ? (
              <Spinner
                testID={
                  TestID.PersonalDetails.ChangePersonalDetailsResendSpinner
                }
                size={10}
              />
            ) : undefined}
          </Text>
        </>
      )}
    </PersonalDetailsModalForm>
  );
};
