import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function ConditionalApprovalCalculatorIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fill="#dedede"
        d="M8.27 4.577h18.486c2.149 0 3.892 1.742 3.892 3.892v18.486c0 2.149-1.742 3.892-3.892 3.892h-18.486c-2.149 0-3.892-1.742-3.892-3.892v-18.486c0-2.149 1.742-3.892 3.892-3.892z"
      />
      <Path
        fill="#ccc"
        d="M14.914 6.219h-7.115c-0.981 0-1.779 0.798-1.779 1.779v7.115c0 0.981 0.798 1.779 1.779 1.779h7.115c0.981 0 1.779-0.798 1.779-1.779v-7.115c0-0.981-0.798-1.779-1.779-1.779z"
      />
      <Path
        fill="#f7f7f7"
        d="M14.914 18.533h-7.115c-0.981 0-1.779 0.798-1.779 1.779v7.115c0 0.981 0.798 1.779 1.779 1.779h7.115c0.981 0 1.779-0.798 1.779-1.779v-7.115c0-0.981-0.798-1.779-1.779-1.779z"
      />
      <Path
        fill="#f7f7f7"
        d="M27.228 6.219h-7.115c-0.981 0-1.779 0.798-1.779 1.779v7.115c0 0.981 0.798 1.779 1.779 1.779h7.115c0.981 0 1.779-0.798 1.779-1.779v-7.115c0-0.981-0.798-1.779-1.779-1.779z"
      />
      <Path
        fill="#ccc"
        d="M27.228 18.533h-7.115c-0.981 0-1.779 0.798-1.779 1.779v7.115c0 0.981 0.798 1.779 1.779 1.779h7.115c0.981 0 1.779-0.798 1.779-1.779v-7.115c0-0.981-0.798-1.779-1.779-1.779z"
      />
      <Path
        fill="#2c2c2c"
        d="M13.819 12.339h-4.926c-0.34 0-0.616-0.275-0.616-0.616s0.275-0.616 0.616-0.616h4.926c0.34 0 0.616 0.275 0.616 0.616s-0.275 0.616-0.616 0.616z"
      />
      <Path
        fill="#2c2c2c"
        d="M26.133 12.339h-4.926c-0.34 0-0.616-0.275-0.616-0.616s0.275-0.616 0.616-0.616h4.926c0.341 0 0.616 0.275 0.616 0.616s-0.275 0.616-0.616 0.616z"
      />
      <Path
        fill="#2c2c2c"
        d="M26.133 23.422h-4.926c-0.34 0-0.616-0.275-0.616-0.616s0.275-0.616 0.616-0.616h4.926c0.341 0 0.616 0.275 0.616 0.616s-0.275 0.616-0.616 0.616z"
      />
      <Path
        fill="#2c2c2c"
        d="M26.133 25.885h-4.926c-0.34 0-0.616-0.275-0.616-0.616s0.275-0.616 0.616-0.616h4.926c0.341 0 0.616 0.275 0.616 0.616s-0.275 0.616-0.616 0.616z"
      />
      <Path
        fill="#2c2c2c"
        d="M11.356 14.802c-0.34 0-0.616-0.275-0.616-0.616v-4.926c0-0.34 0.275-0.616 0.616-0.616s0.616 0.275 0.616 0.616v4.926c0 0.34-0.275 0.616-0.616 0.616z"
      />
      <Path
        fill="#2c2c2c"
        d="M13.511 26.808c-0.158 0-0.315-0.060-0.435-0.18l-4.31-4.31c-0.241-0.241-0.241-0.63 0-0.871s0.63-0.241 0.871 0l4.31 4.31c0.241 0.241 0.241 0.63 0 0.871-0.12 0.12-0.278 0.18-0.435 0.18z"
      />
      <Path
        fill="#2c2c2c"
        d="M9.201 26.808c-0.158 0-0.315-0.060-0.435-0.18-0.241-0.241-0.241-0.63 0-0.871l4.31-4.31c0.241-0.241 0.63-0.241 0.871 0s0.241 0.63 0 0.871l-4.31 4.31c-0.12 0.12-0.278 0.18-0.435 0.18z"
      />
    </Svg>
  );
}
