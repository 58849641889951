import { FormikContextType } from 'formik';

import { FormikControlProps } from '../../../components/form/types';
import { toFloat } from '../../../utils/numberHelpers';
import { SelectProps } from './model';
import { Select } from './Select';

type PossibleValue = string | number | null;
export type SelectForFormikProps = Expand<
  SelectProps &
    Omit<FormikControlProps<PossibleValue>, 'setFieldValue'> & {
      setFieldValue?: (
        value: PossibleValue,
        shouldValidate?: boolean,
      ) => Promise<void>;
      formik?: FormikContextType<Record<string, PossibleValue>>;
    }
>;

export function SelectForFormik({
  setFieldTouched,
  setFieldValue,
  items,
  touched,
  value: selectedValue,

  // These props should not be passed to inner select
  formik: _,
  name: __,
  onValueChange: baseOnValueChange,
  ...otherProps
}: SelectForFormikProps) {
  const onValueChange = (value: unknown, index: number) => {
    // The first index reserved is as a placeholder, in order to get correct index, we need to substract by 1
    const selectedIndexInOptions = index - 1;
    // On web, <select> will call the onchange callback with the stringified value
    // instead of the original number.
    const valueShouldBeNumber =
      typeof items?.[selectedIndexInOptions]?.value === 'number';

    let valueToSet: PossibleValue;
    if (value == null) {
      valueToSet = null;
    } else if (valueShouldBeNumber) {
      valueToSet = toFloat(value);
    } else {
      valueToSet = String(value);
    }

    baseOnValueChange?.(valueToSet, index);

    setFieldValue?.(valueToSet, false).then(() => {
      setFieldTouched?.(true, true);
    });
  };

  return (
    <Select
      {...otherProps}
      items={items}
      onValueChange={onValueChange}
      selectedValue={selectedValue}
    />
  );
}
