import { gql } from '@apollo/client';
import { useCallback } from 'react';

import {
  InlifeLoanAccountFragmentDoc,
  PaymentRequestFragmentDoc,
  useGetLastAutopayChangeRequestSubscription,
  useGetLoanAccountByIdQuery,
  useGetLoanAccountCurrentAutopayChangeRequestSubscription,
  useGetLoanAccountDirectDebitSettingsQuery,
  useGetLoanAccountPendingWithdrawalsSubscription,
  useGetLoanAccountPropertyAddressQuery,
  useGetMeQuery,
} from '../../generated/graphql';
import { useAccountActivityWatcher } from '../../Home/utils/useAccountActivityWatcher';
import { usePollIntervalForActiveUser } from '../../utils/hooks/usePollIntervalForActiveUser';

const POLL_INTERVAL = 300000; // 5 minutes

export const GET_LOAN_ACCOUNT_BY_ID = gql`
  query GetLoanAccountById($cba_account_id: String!) {
    loan_account_by_id(cba_account_id: $cba_account_id) {
      ...InlifeLoanAccount
    }
  }

  ${InlifeLoanAccountFragmentDoc}
`;

export const GET_LOAN_ACCOUNT_DIRECT_DEBIT_SETTINGS_QUERY = gql`
  query GetLoanAccountDirectDebitSettings($cba_account_id: String!) {
    loan_account_settings(cba_account_id: $cba_account_id) {
      id
      bsb
      account_number
      name
      autopay {
        id
        direct_debit_account
        direct_debit_bsb
        repayment_amount
        repayment_frequency
        __typename
      }
      __typename
    }
  }
`;

export const GET_LOAN_ACCOUNT_DIRECT_DEBIT_LIMITS_QUERY = gql`
  query GetLoanAccountDirectDebitLimits($cba_account_id: String!) {
    loan_account(where: { cba_account_id: { _eq: $cba_account_id } }) {
      limits {
        maximum_direct_debit_amount
      }
      monthly_minimum_repayment: calculate_minimum_repayment(
        repayment_frequency: Monthly
      )
    }
  }
`;

export const GET_LOAN_ACCOUNT_PENDING_WITHDRAWALS_SUBSCRIPTION = gql`
  subscription GetLoanAccountPendingWithdrawals($cba_account_id: String!) {
    pending_withdrawals: payment_request(
      where: {
        cba_account_id: { _eq: $cba_account_id }
        type: { _eq: WITHDRAWAL }
        status: { _in: [PENDING] }
        withdrawal_request: {
          approval_request: { status: { _in: [APPROVED, PENDING] } }
        }
      }
    ) {
      ...PaymentRequest
      __typename
    }
  }

  ${PaymentRequestFragmentDoc}
`;

export const GET_LOAN_ACCOUNT_CURRENT_AUTOPAY_CHANGE_REQUEST_SUBSCRIPTION = gql`
  subscription GetLoanAccountCurrentAutopayChangeRequest(
    $cba_account_id: String!
  ) {
    current_autopay_change_request: autopay_change_request(
      where: {
        cba_account_id: { _eq: $cba_account_id }
        approval_request: { status: { _eq: PENDING } }
      }
    ) {
      id
      first_repayment_date
      expires_at
    }
  }
`;

export const GET_LOAN_ACCOUNT_PENDING_PROCESS_AUTOPAY_REQUEST = gql`
  subscription GetLastAutopayChangeRequest($cba_account_id: String!) {
    last_autopay_change_request: autopay_change_request(
      where: { cba_account_id: { _eq: $cba_account_id } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      id
      first_repayment_date
      repayment_amount
      status
      expires_at
      approval_request {
        status
        requested_by_id
        requested_by {
          user_id
        }
        responses {
          id
          identity_profile_id
          identity_profile {
            first_name
          }
        }
      }
    }
  }
`;

export function useLoanAccountDirectDebitSettings(cba_account_id: string) {
  const { loading: loanAccountDirectDebitSettingsLoading, data } =
    useGetLoanAccountDirectDebitSettingsQuery({
      variables: { cba_account_id },
      context: {
        sentryContext: {
          cbaAccountId: cba_account_id,
        },
      },
    });

  const loading = loanAccountDirectDebitSettingsLoading;

  return {
    loading,
    data: loading ? null : data?.loan_account_settings,
  };
}

// Use this to fetch preloaded data through loan_account_by_id dataloaders
export function useLoanAccountState(cba_account_id: string) {
  const pollIntervalWhenActive = usePollIntervalForActiveUser({
    pollInterval: POLL_INTERVAL,
  });

  const {
    loading,
    data: loanAccountStateData,
    refetch,
    error,
  } = useGetLoanAccountByIdQuery({
    variables: {
      cba_account_id,
    },
    context: {
      sentryContext: {
        cbaAccountId: cba_account_id,
        description: 'Fetching loan account details.',
      },
    },
    pollInterval: pollIntervalWhenActive,
  });

  useAccountActivityWatcher(cba_account_id, refetch);

  return {
    loading,
    error,
    refetch,
    pollInterval: pollIntervalWhenActive,
    data: loanAccountStateData?.loan_account_by_id,
  };
}

export function useLoanAccountWithTransactions(cba_account_id: string) {
  const {
    loading: loanAccountStateLoading,
    data,
    refetch: refetchLoanAccountState,
    error: loanAccountStateError,
  } = useLoanAccountState(cba_account_id);

  const context = {
    sentryContext: {
      cbaAccountId: cba_account_id,
    },
  };

  const {
    loading: pendingWithdrawalsLoading,
    data: pendingWithdrawalsData,
    error: pendingWithdrawalsError,
  } = useGetLoanAccountPendingWithdrawalsSubscription({
    variables: { cba_account_id },
    context,
  });

  const {
    loading: currentAutopayChangeRequestLoading,
    data: currentAutopayChangeRequestData,
    error: currentAutopayChangeRequestError,
  } = useGetLoanAccountCurrentAutopayChangeRequestSubscription({
    variables: { cba_account_id },
    context,
  });

  const {
    loading: loanLastAutopayChangeRequestLoading,
    data: loanLastAutopayChangeRequestData,
    error: loanLastAutopayChangeRequestError,
  } = useGetLastAutopayChangeRequestSubscription({
    variables: { cba_account_id },
    context,
  });

  const { loading: propertyAddressLoading, data: propertyAddressData } =
    useGetLoanAccountPropertyAddressQuery({
      variables: { cba_account_id },
      context,
    });

  const { loading: meLoading, data: meData } = useGetMeQuery();

  const myIdentityProfileId = meData?.me?.[0]?.user?.identity_profile?.id;

  const loading =
    loanAccountStateLoading ||
    pendingWithdrawalsLoading ||
    currentAutopayChangeRequestLoading ||
    propertyAddressLoading ||
    loanLastAutopayChangeRequestLoading ||
    meLoading;

  const error =
    loanAccountStateError ??
    pendingWithdrawalsError ??
    loanLastAutopayChangeRequestError ??
    currentAutopayChangeRequestError;

  const refetch = useCallback(() => {
    refetchLoanAccountState();
  }, [refetchLoanAccountState]);

  return {
    loading,
    error,
    refetch,
    data: loading
      ? null
      : {
          ...data,
          myIdentityProfileId,
          pending_withdrawals: pendingWithdrawalsData?.pending_withdrawals,
          current_autopay_change_request:
            currentAutopayChangeRequestData?.current_autopay_change_request,
          last_autopay_change_request:
            loanLastAutopayChangeRequestData?.last_autopay_change_request[0],
          loan_application_security:
            propertyAddressData?.loan_account?.[0]?.loan_application_target
              ?.loan_application_securities?.[0],
        },
  };
}
