import * as restyled from '@shopify/restyle';
import { TextStyle } from 'react-native';

import { Theme } from './theme';

export const typographyProperties = {
  fontFamily: true,
  fontStyle: true,
  letterSpacing: true,
  textAlign: true,
  textDecorationLine: true,
  textDecorationStyle: true,
  textTransform: true,
};

export type TypographyProps = {
  [Key in keyof typeof typographyProperties]?: restyled.ResponsiveValue<
    TextStyle[Key],
    Theme
  >;
};

export type LayoutProps = restyled.LayoutProps<Theme>;
export type VisibleProps = restyled.VisibleProps<Theme>;
export type OpacityProps = restyled.OpacityProps<Theme>;
export type SpacingProps = restyled.SpacingShorthandProps<Theme>;
export type ShadowProps = restyled.ShadowProps<Theme>;
export type PositionProps = restyled.PositionProps<Theme>;
export type BackgroundColorProps =
  restyled.BackgroundColorShorthandProps<Theme> &
    restyled.BackgroundColorProps<Theme>;
export type BorderProps = restyled.BorderProps<Theme>;
export type ColorProps = restyled.ColorProps<Theme>;
export type TextVariantProps = restyled.VariantProps<Theme, 'textVariants'>;

export type TextShadowProps = restyled.TextShadowProps<Theme>;

export type TextType = TextVariantProps['variant'];
export type Size = restyled.ResponsiveValue<keyof Theme['sizes'], Theme>;
export type FontSize = restyled.ResponsiveValue<
  keyof Theme['fontSizes'],
  Theme
>;

export interface FontSizeProps {
  fontSize?: restyled.ResponsiveValue<keyof Theme['fontSizes'], Theme>;
}

export interface FontWeightProps {
  fontWeight?: restyled.ResponsiveValue<keyof Theme['fontWeights'], Theme>;
}

export interface LineHeightProps {
  lineHeight?: restyled.ResponsiveValue<keyof Theme['lineHeights'], Theme>;
}

export type FontWeight = FontWeightProps['fontWeight'];

export type Color = ColorProps['color'];
