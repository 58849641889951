import Svg, { Path, SvgProps } from 'react-native-svg';

export function AlertCircleIcon({ color, ...otherProps }: SvgProps) {
  return (
    <Svg width={18} height={18} fill="none" {...otherProps}>
      <Path
        fill={color}
        d="M9 .75C4.451.75.75 4.451.75 9c0 4.549 3.701 8.25 8.25 8.25 4.549 0 8.25-3.701 8.25-8.25C17.25 4.451 13.549.75 9 .75Zm0 1.238A7.003 7.003 0 0 1 16.012 9 7.003 7.003 0 0 1 9 16.012 7.003 7.003 0 0 1 1.987 9 7.003 7.003 0 0 1 9 1.987Zm-.01 2.466a.619.619 0 0 0-.609.627v4.95a.619.619 0 1 0 1.238 0v-4.95a.619.619 0 0 0-.629-.627ZM9 11.887a.825.825 0 1 0 0 1.65.825.825 0 0 0 0-1.65Z"
      />
    </Svg>
  );
}
