import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  formatLiabilityLabel,
  formatLiabilitySublabels,
  formatOwners,
} from '../components/utils/formatLiabilityRow';
import { useFullNameByApplicantIds } from '../DebtsWizard/utils/useAllLiabilitiesQuery';
import {
  ApplicantForApplicationSummaryFragment,
  EmploymentIncomeFragment,
  Frequency_Enum,
  HouseholdForSummaryFragment,
  Income_Type_Enum,
  Liability_Type_Enum,
  Living_Situation_Enum,
  Loan_Application_Type_Enum,
  Loan_Interest_Rate_Type_Enum,
  LoanAppForGtmEventFragmentDoc,
  MergedLiabilityForApplicationSummaryFragment,
  Property_Purpose_Enum,
  Property_Type_Enum,
  Refinancing_Reason_Input_Enum,
  RentalIncomeFragment,
  useGetDisclosureContentQuery,
  useGetMinimumRepaymentQuery,
  useReviewLoanApplicationQuery,
} from '../generated/graphql';
import { YourUnloanCardData } from '../LoanApplication/components/types';
import { DisclosureSectionData } from '../LoanApplication/components/YourUnloanCardDisclosureSection';
import {
  EMPLOYMENT_INCOME_FRAGMENT,
  HasuraAddress,
  RENTAL_INCOME_FRAGMENT,
} from '../LoanApplication/graphql/fragments';
import { createTopUpTotalLoanAmountCaption } from '../LoanApplication/utils/createTopUpTotalLoanAmountCaption';
import { removeNullish } from '../utils/arrayHelpers';
import { parseEnumType } from '../utils/ensureEnumType';
import { isTopUpHomeLoan } from '../utils/isTopUpHomeLoan';
import { formatAddress } from '../utils/stringHelpers';
import { sortHouseholdExpensesFields } from './utils/remoteDataHelpers';

export const HOUSEHOLD_FOR_SUMMARY_FRAGMENT = gql`
  fragment HouseholdForSummary on household {
    id
    applicants {
      id
      latest_full_name
    }
    adults
    dependants
    living_situation
    household_expenses_aggregate {
      aggregate {
        sum {
          monthly_amount
        }
      }
    }
    unordered_household_expenses: household_expenses {
      id
      expense_type
      monthly_amount
    }
  }
`;

export const ApplicationDetailsFragments = gql`
  fragment MergedLiabilityForApplicationSummary on merged_liability {
    id
    current_liability_id
    detected_liability_identifier

    dynamite_applicant_ids
    dynamite_balance
    dynamite_institution_name
    dynamite_liability_type
    dynamite_limit

    dynamite_flagged_incorrect
    manually_added
    dynamite_for_refinancing
  }

  fragment ApplicantForApplicationSummary on applicant {
    id
    latest_full_name
    is_current_logged_in_applicant
    household_id
    role
    user_identity_profile {
      frankieone_entity_id
    }
    applicant_invites {
      is_expired
      accepted_at
    }
  }

  ${HasuraAddress}
`;

export const REVIEW_APPLICATION_QUERY = gql`
  query ReviewLoanApplication($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      is_lmi_applicable
      ...LoanAppForGtmEvent

      status_info {
        id
        can_user_edit
        loan_application_stage
      }

      applicants {
        ...ApplicantForApplicationSummary
      }

      parent_loan_account {
        balances {
          available_redraw_balance
        }
        settings {
          interest_rate
          interest_rate_discount
          remaining_term_in_months
        }
      }
      top_up_home_loan_liability: merged_liabilities(
        where: {
          dynamite_liability_type: { _eq: "HOME_LOAN" }
          dynamite_for_refinancing: { _eq: true }
        }
      ) {
        dynamite_balance
        dynamite_limit
        dynamite_repayment_amount
        dynamite_loan_term_months
      }

      loan_application_targets {
        id
        loan_amount
        interest_only_period_months
        repayment_type
        term_months
        top_up_amount
        total_and_fees {
          refinanced_amount
          total_fees
          buffer_amount
          lmi_premium
          total_top_up
        }
        target_product_rate {
          interest_rate
          id: product_rate_id
          loan_interest_rate_type
          rate_adjustment
          product_type
        }
        loan_application_securities {
          id
          property_purpose
          property_type
          owner_estimated_value
          purchase_price
          property {
            id
            address {
              ...HasuraAddress
            }
          }
        }
      }
      loan_application_target_total_and_fees {
        mortgage_registration_fee
        mortgage_discharge_fee
      }

      incomes(
        where: {
          _not: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
        }
      ) {
        id
        amount
        frequency
        income_type
        income_owners {
          id
          applicant_id
        }

        employment_income {
          ...EmploymentIncome
        }
        rental_income {
          ...RentalIncome
        }
      }
      expectedRentalIncomes: incomes(
        where: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
      ) {
        id
        amount
        frequency
        rental_income {
          id
          rentalExpense: rental_expense
          rentalExpenseFrequency: rental_expense_frequency
        }
      }
      financial_declaration {
        ...FinDecForApplicationSummary
      }

      docusign_envelope {
        id
        envelope_id
      }
    }

    get_household_expenses_fields(loan_application_id: $loanApplicationId) {
      household_id
      household_expenses_order
      household {
        ...HouseholdForSummary
      }
    }

    mergedLiabilities: merged_liability(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...MergedLiabilityForApplicationSummary
    }

    mergedLiabilitiesForRefinancing: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        dynamite_for_refinancing: { _eq: true }
        dynamite_flagged_incorrect: { _is_null: true }
      }
    ) {
      ...MergedLiabilityForApplicationSummary
    }
  }

  fragment FinDecForApplicationSummary on financial_declaration {
    id
    refinancing_reason
    maintain_current_financial_situation

    top_up_reasons {
      id
      top_up_reason
      top_up_amount
      other_top_up_reason_description
    }

    retirement_repayment_plans {
      id
      retirement_repayment_plan
    }
  }

  fragment FinancialDeclarationTopUpReason on financial_declaration_top_up_reason {
    id
    top_up_reason
    top_up_amount
    top_up_reason_confirmed_at
  }
  ${ApplicationDetailsFragments}
  ${EMPLOYMENT_INCOME_FRAGMENT}
  ${RENTAL_INCOME_FRAGMENT}
  ${HOUSEHOLD_FOR_SUMMARY_FRAGMENT}
  ${HasuraAddress}
  ${LoanAppForGtmEventFragmentDoc}
`;

export const GET_DISCLOSURE_CONTENT = gql`
  query GetDisclosureContent($loanApplicationId: uuid!) {
    disclosureContent: get_disclosure_content(
      loan_application_id: $loanApplicationId
    ) {
      required_top_up_reasons_disclosure
      need_debt_consolidation_disclosure
    }
  }
`;

/** TODO: streamline these props */
export type SectionDataProps = {
  loanApplicationId: string;
  loanApplicationType: Loan_Application_Type_Enum;
  isAllApplicantsHasHousehold: boolean;
  property: {
    id: string;
    addressLine1: string;
    addressLine2?: string;
    propertyPurpose: Property_Purpose_Enum | null;
    propertyType: Property_Type_Enum | null;
    propertyEstimatedValue: number | null;
    propertyPurchasePrice: number | null;
    loanApplicationSecurityId: string;
    propertyAddressLatLong: { latitude: number; longitude: number } | null;
    propertyDisplayAddress: string | undefined;
    expectedRentalIncome?: number;
    expectedRentalExpense?: number;
    rentalIncomeFrequency?: Frequency_Enum;
    rentalExpenseFrequency?: Frequency_Enum;
  };
  loan: YourUnloanCardData &
    DisclosureSectionData & {
      targetLoanId: string;
    };
  borrowers: Array<ApplicantForApplicationSummaryFragment>;
  financials: {
    incomes: Array<{
      id: string;
      amount: number;
      frequency: Frequency_Enum;
      incomeType: Income_Type_Enum;
      formattedIncomeOwnerNames: string;
      employmentIncome?: EmploymentIncomeFragment | null;
      rentalIncome?: RentalIncomeFragment | null;
    }>;
    households: Array<{
      id: string;
      livingSituation?: Living_Situation_Enum;
      householdExpensesMonthlyAmount?: number | null;
      formattedHouseholdMemberNames: string;
      householdExpenseDetails?: HouseholdForSummaryFragment['unordered_household_expenses'];
      numberOfDependents?: number;
    }>;
  };
  debtsAndLiabilities: Array<{
    id: MergedLiabilityForApplicationSummaryFragment['id'];
    currentLiabilityId: MergedLiabilityForApplicationSummaryFragment['current_liability_id'];
    label: string;
    type: Liability_Type_Enum;
    caption: string;
    owners: string;
    detectedLiabilityIdentifier: MergedLiabilityForApplicationSummaryFragment['detected_liability_identifier'];
    liabilityManuallyAdded: MergedLiabilityForApplicationSummaryFragment['manually_added'];
    isTopUpHomeLoanLiability: boolean;
  }>;
  financialDeclaration: {
    loanPurpose: string;
    topUpReasons?: Array<string>;
    financialStability: string;
    reasonForFinancing: string | null;
    retirementConsideration?: Array<string>;
  };
};

/**
 * Dear maintainer:
 *
 * If you need to update the UI that seems like reading data from here,
 * please double check what fields is being used first in the render
 * logic where your UI is located.
 *
 * In some cases, this might be not obvious because of multiple
 * branching based on loan application type in the render logic.
 *
 * I recommend checking the storybook if you're not sure
 * whether your change will affect certain application type or not.
 */
export function useReviewApplicationQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, loading, error, networkStatus, ...others } =
    useReviewLoanApplicationQuery({
      variables: {
        loanApplicationId: loanApplicationId || '',
      },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  const { data: getDisclosureData, loading: getDisclosureDataLoading } =
    useGetDisclosureContentQuery({
      variables: { loanApplicationId: loanApplicationId || '' },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

  const fullNameByApplicantIds = useFullNameByApplicantIds(
    data?.loan_application_by_pk?.applicants,
  );

  const normalizedLoanSummaryData = useMemo(() => {
    const {
      loan_application_targets: loanApplicationTargets,
      financial_declaration: financialDeclaration,
      type: loanApplicationType,
      applicants,
      incomes,
      expectedRentalIncomes,
      parent_loan_account: parentLoanAccount,
    } = data?.loan_application_by_pk || {};

    const householdExpenses = data?.get_household_expenses_fields;
    // Right now we only handle single target.
    const targetLoan = loanApplicationTargets?.[0];
    const productRate = targetLoan?.target_product_rate;
    const targetLoanSecurity = targetLoan?.loan_application_securities?.[0];
    const expectedRentalIncome = expectedRentalIncomes?.[0];

    const interestRate =
      (loanApplicationType === Loan_Application_Type_Enum.TopUp
        ? parentLoanAccount?.settings?.interest_rate
        : productRate?.interest_rate) ?? 0;
    const rateAdjustment =
      loanApplicationType === Loan_Application_Type_Enum.TopUp
        ? data?.loan_application_by_pk?.parent_loan_account?.settings
            ?.interest_rate_discount
        : productRate?.rate_adjustment;
    const loanInterestRateType = parseEnumType(
      Loan_Interest_Rate_Type_Enum,
      productRate?.loan_interest_rate_type,
    );

    const productType = productRate?.product_type;

    return {
      targetLoan,
      targetLoanSecurity,
      loanApplicationType,
      applicants,
      incomes,
      householdExpenses,
      financialDeclaration,
      expectedRentalIncome,
      interestRate,
      rateAdjustment,
      productType,
      loanInterestRateType,
    };
  }, [data?.get_household_expenses_fields, data?.loan_application_by_pk]);

  const { data: minimumRepaymentResult, loading: minRepaymentLoading } =
    useGetMinimumRepaymentQuery({
      variables: {
        interestRate: normalizedLoanSummaryData.interestRate,
        loanAmount: normalizedLoanSummaryData.targetLoan?.loan_amount || 0,
        termInMonths: normalizedLoanSummaryData.targetLoan?.term_months || 0,
      },
      skip:
        !loanApplicationId ||
        !normalizedLoanSummaryData.interestRate ||
        !normalizedLoanSummaryData.targetLoan,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

  const cashoutList = useMemo(
    () =>
      normalizedLoanSummaryData.financialDeclaration?.top_up_reasons.map(
        (i) => ({
          reason: i.top_up_reason,
          amount: i.top_up_amount || 0,
          otherReason: i.other_top_up_reason_description,
        }),
      ),
    [normalizedLoanSummaryData.financialDeclaration?.top_up_reasons],
  );

  const refinancedAmount =
    normalizedLoanSummaryData.targetLoan?.total_and_fees?.refinanced_amount;

  const redrawBalance =
    data?.loan_application_by_pk?.parent_loan_account?.balances
      ?.available_redraw_balance;

  const topUpData = useMemo(() => {
    const { type: loanApplicationType } = data?.loan_application_by_pk || {};

    if (loanApplicationType !== Loan_Application_Type_Enum.TopUp) {
      return {};
    }

    const topUpHomeLoanLiability =
      data?.loan_application_by_pk?.top_up_home_loan_liability?.[0];

    const previousMinRepaymentAmount =
      topUpHomeLoanLiability?.dynamite_repayment_amount;

    const unloanLiabilityBalance =
      topUpHomeLoanLiability?.dynamite_balance || 0;

    const consolidatedDebtsAmount =
      (normalizedLoanSummaryData.targetLoan?.loan_amount ?? 0) -
      (normalizedLoanSummaryData.targetLoan?.total_and_fees?.total_top_up ??
        0) -
      (topUpHomeLoanLiability?.dynamite_limit ?? 0);

    return {
      previousMinRepaymentAmount,
      totalLoanAmountCaption: createTopUpTotalLoanAmountCaption({
        unloanLiabilityBalance,
        cashoutList,
        consolidatedDebtsAmount,
        redrawAmount: redrawBalance,
      }),
    };
  }, [
    cashoutList,
    data?.loan_application_by_pk,
    normalizedLoanSummaryData.targetLoan?.loan_amount,
    normalizedLoanSummaryData.targetLoan?.total_and_fees?.total_top_up,
    redrawBalance,
  ]);

  const sectionData: SectionDataProps = useMemo(() => {
    const rawMergedLiabilities = data?.mergedLiabilities || [];
    const mergedLiabilities = rawMergedLiabilities.filter(
      (ml) => ml.dynamite_flagged_incorrect == null,
    );

    const {
      targetLoan,
      targetLoanSecurity,
      loanApplicationType,
      applicants,
      incomes,
      householdExpenses,
      financialDeclaration,
      expectedRentalIncome,
      interestRate,
      rateAdjustment,
      productType,
      loanInterestRateType,
    } = normalizedLoanSummaryData || {};

    const targetLoanId = targetLoan?.id || '';
    const loanAmount = targetLoan?.loan_amount || 0;

    const previousTermInMonths =
      loanApplicationType === Loan_Application_Type_Enum.TopUp
        ? data?.loan_application_by_pk?.parent_loan_account?.settings
            ?.remaining_term_in_months
        : undefined;

    const targetSecurityId = targetLoanSecurity?.id || '';
    const targetAddress = targetLoanSecurity?.property?.address;
    const targetAddressId = targetAddress?.id || '';

    // Data from minimumRepaymentQuery
    const minimumRepaymentMonthlyAmount =
      minimumRepaymentResult?.repayment_schedule.monthly_amount;
    const minRepaymentCalcError =
      minimumRepaymentResult?.repayment_schedule.repayment_calculation_error;

    const loanApplicationTargetTotalAndFees =
      data?.loan_application_by_pk?.loan_application_target_total_and_fees?.[0];
    const mortgageRegistrationFee =
      loanApplicationTargetTotalAndFees?.mortgage_registration_fee;
    const mortgageDischargeFee =
      loanApplicationTargetTotalAndFees?.mortgage_discharge_fee;

    const parsedRefinancingReason = parseEnumType(
      Refinancing_Reason_Input_Enum,
      financialDeclaration?.refinancing_reason,
    );
    const parsedPropertyPurpose = parseEnumType(
      Property_Purpose_Enum,
      targetLoanSecurity?.property_purpose,
    );
    const parsedPropertyType = parseEnumType(
      Property_Type_Enum,
      targetLoanSecurity?.property_type,
    );
    const estimatedPropertyValue =
      targetLoanSecurity?.owner_estimated_value || null;

    // #region Formatted values
    const [addressLine1, addressLine2] = formatAddress(targetAddress);

    const addressLatLong =
      targetAddress?.latitude != null && targetAddress?.longitude != null
        ? {
            latitude: targetAddress.latitude,
            longitude: targetAddress.longitude,
          }
        : null;
    const propertyPurposeValue = parsedPropertyPurpose
      ? t(`Content.PropertyPurpose.${parsedPropertyPurpose}`)
      : '--';

    const topUpReasonsValue = financialDeclaration?.top_up_reasons?.map(
      ({ top_up_reason }) =>
        t(`Content.TopUpReasonEnum.${top_up_reason}.title`),
    );
    const financialStabilityValue =
      financialDeclaration?.maintain_current_financial_situation
        ? t('Content.Common.ButtonLabel.Yes')
        : t('Content.Common.ButtonLabel.No');
    const reasonForFinancingValue = parsedRefinancingReason
      ? `${t(`Content.RefinancingReasonEnum.${parsedRefinancingReason}`)}`
      : null;
    const retirementConsiderationValue =
      financialDeclaration?.retirement_repayment_plans?.map(
        ({ retirement_repayment_plan }) =>
          t(
            `Content.RetirementRepaymentPlanReasonEnum.${retirement_repayment_plan}.title`,
          ),
      );
    // #endregion

    // #region Sanitized Values
    const sanitizedBorrowers: SectionDataProps['borrowers'] = removeNullish(
      applicants || [],
    );
    const sanitizedIncomes: SectionDataProps['financials']['incomes'] =
      incomes?.map(
        ({
          employment_income: employmentIncome,
          rental_income: rentalIncome,
          id,
          amount,
          frequency,
          income_type: incomeType,
          income_owners,
        }) => ({
          id,
          amount,
          frequency,
          incomeType,
          formattedIncomeOwnerNames: formatOwners({
            fullNameByApplicantIds,
            applicantIds: income_owners.map((owner) => owner.applicant_id),
          }),
          employmentIncome,
          rentalIncome,
        }),
      ) || [];
    const sanitizedHouseholds: SectionDataProps['financials']['households'] =
      householdExpenses?.map(
        ({ household_id, household, household_expenses_order }) => ({
          id: household_id,
          householdExpensesMonthlyAmount:
            household[0]?.household_expenses_aggregate.aggregate?.sum
              ?.monthly_amount,
          formattedHouseholdMemberNames: formatOwners({
            fullNameByApplicantIds,
            applicantIds: household[0]?.applicants.map(
              (applicant) => applicant.id,
            ),
          }),
          householdExpenseDetails: sortHouseholdExpensesFields({
            expensesOrder: household_expenses_order,
            unorderedHouseholdExpenses:
              household[0]?.unordered_household_expenses || [],
          }),
          livingSituation: household[0]?.living_situation,
          numberOfDependents: household[0]?.dependants,
        }),
      ) || [];
    const sanitizedLiabilities: SectionDataProps['debtsAndLiabilities'] =
      mergedLiabilities.map((ml) => {
        const liabilityType =
          parseEnumType(Liability_Type_Enum, ml.dynamite_liability_type) ||
          Liability_Type_Enum.Other;
        return {
          id: ml.id,
          currentLiabilityId: ml.current_liability_id,
          label: formatLiabilityLabel({
            institutionName: ml.dynamite_institution_name,
            liabilityType,
          }),
          type: liabilityType,
          caption: formatLiabilitySublabels({
            liabilityType,
            balance: ml.dynamite_balance,
            limit: ml.dynamite_limit,
          }).join('\n'),
          owners: formatOwners({
            fullNameByApplicantIds,
            applicantIds: ml.dynamite_applicant_ids,
          }),
          detectedLiabilityIdentifier: ml.detected_liability_identifier,
          liabilityManuallyAdded: ml.manually_added,
          isTopUpHomeLoanLiability: isTopUpHomeLoan({
            loanApplicationType,
            liabilityType,
            forRefinancing: ml.dynamite_for_refinancing,
          }),
        };
      });
    // #endregion

    return {
      loanApplicationId: loanApplicationId || '',
      loanApplicationType:
        loanApplicationType || Loan_Application_Type_Enum.Refinance,
      isAllApplicantsHasHousehold: sanitizedBorrowers.every(
        (a) => a.household_id != null,
      ),
      property: {
        id: targetAddressId,
        addressLine1,
        addressLine2,
        propertyPurpose: parsedPropertyPurpose,
        propertyType: parsedPropertyType,
        propertyEstimatedValue: estimatedPropertyValue,
        propertyPurchasePrice: targetLoanSecurity?.purchase_price || null,
        loanApplicationSecurityId: targetSecurityId,
        propertyAddressLatLong: addressLatLong,
        propertyDisplayAddress: targetAddress?.display_address,
        expectedRentalIncome: expectedRentalIncome?.amount,
        rentalIncomeFrequency: expectedRentalIncome?.frequency,
        expectedRentalExpense:
          expectedRentalIncome?.rental_income?.rentalExpense,
        rentalExpenseFrequency:
          expectedRentalIncome?.rental_income?.rentalExpenseFrequency,
      },
      loan: {
        targetLoanId,
        loanInterestRateType,
        minRepaymentAmount: minimumRepaymentMonthlyAmount ?? null,
        previousMinRepaymentAmount: topUpData.previousMinRepaymentAmount,
        minRepaymentCalcError: minRepaymentCalcError ?? null,
        totalLoanAmount: loanAmount,
        interestRate,
        rateAdjustment,
        productType,
        propertyPurpose: targetLoanSecurity?.property_purpose,
        refinancedAmount,
        refinancePurpose: financialDeclaration?.refinancing_reason,
        feesAndBufferAmount:
          (targetLoan?.total_and_fees?.total_fees || 0) +
          (targetLoan?.total_and_fees?.buffer_amount || 0),
        bufferAmount: targetLoan?.total_and_fees?.buffer_amount || 0,
        termInMonths: targetLoan?.term_months || 0,
        previousTermInMonths,
        oldCashoutAmount: targetLoan?.top_up_amount,
        cashoutList,
        repaymentType: targetLoan?.repayment_type,
        mergedLiabilitiesForRefinancing:
          data?.mergedLiabilitiesForRefinancing || [],
        disclosureContent: getDisclosureData?.disclosureContent,
        getDisclosureDataLoading,
        redrawBalance,
        totalLoanAmountCaption: topUpData.totalLoanAmountCaption,
        mortgageRegistrationFee,
        mortgageDischargeFee,
        lmiPremium: targetLoan?.total_and_fees?.lmi_premium,
      },
      borrowers: sanitizedBorrowers,
      financials: {
        incomes: sanitizedIncomes,
        households: sanitizedHouseholds,
      },
      debtsAndLiabilities: sanitizedLiabilities,
      financialDeclaration: {
        loanPurpose: propertyPurposeValue,
        topUpReasons: topUpReasonsValue,
        financialStability: financialStabilityValue,
        reasonForFinancing: reasonForFinancingValue,
        retirementConsideration: retirementConsiderationValue,
      },
    };
  }, [
    data?.mergedLiabilities,
    data?.loan_application_by_pk?.parent_loan_account?.settings
      ?.remaining_term_in_months,
    data?.mergedLiabilitiesForRefinancing,
    normalizedLoanSummaryData,
    minimumRepaymentResult?.repayment_schedule.monthly_amount,
    minimumRepaymentResult?.repayment_schedule.repayment_calculation_error,
    loanApplicationId,
    topUpData.previousMinRepaymentAmount,
    topUpData.totalLoanAmountCaption,
    refinancedAmount,
    cashoutList,
    getDisclosureData?.disclosureContent,
    getDisclosureDataLoading,
    redrawBalance,
    fullNameByApplicantIds,
    data?.loan_application_by_pk?.loan_application_target_total_and_fees,
  ]);

  return {
    sectionData,
    data,
    loading: loading || minRepaymentLoading,
    error,
    ...others,
  };
}
