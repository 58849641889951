import { differenceInMonths } from 'date-fns';

import {
  Frequency_Input_Enum,
  Liability_Type_Enum,
  MergedLiabilityForYourDebtsFragment,
} from '../../generated/graphql';

export function getHeaderTextByLiabilityType(
  liabilityType: Liability_Type_Enum,
) {
  switch (liabilityType) {
    case Liability_Type_Enum.HomeLoan:
    case Liability_Type_Enum.Personal:
    case Liability_Type_Enum.LineOfCredit:
    case Liability_Type_Enum.MarginOrInvestmentLoan:
    case Liability_Type_Enum.PersonalOverdraft:
    case Liability_Type_Enum.Bnpl:
    case Liability_Type_Enum.CreditCard:
    case Liability_Type_Enum.ChargeCard:
    case Liability_Type_Enum.StoreCard:
    case Liability_Type_Enum.NovatedLease:
    case Liability_Type_Enum.HecsHelp:
    case Liability_Type_Enum.Other:
      return t(`Content.Common.LiabilityType.${liabilityType}`);
    default:
      return t('Content.DebtsReviewDetailsV2.LoanDetails');
  }
}

const liabilityTypeOrder = [
  Liability_Type_Enum.HomeLoan,
  Liability_Type_Enum.LineOfCredit,
  Liability_Type_Enum.MarginOrInvestmentLoan,
  Liability_Type_Enum.Personal,
  Liability_Type_Enum.PersonalOverdraft,
  Liability_Type_Enum.CreditCard,
  Liability_Type_Enum.ChargeCard,
  Liability_Type_Enum.Bnpl,
  Liability_Type_Enum.NovatedLease,
  Liability_Type_Enum.HecsHelp,
  Liability_Type_Enum.Other,
] as const;

export function getLiabilityTypeItems(hideHecsLiabilityType: boolean) {
  return liabilityTypeOrder
    .filter((value) =>
      value === Liability_Type_Enum.HecsHelp ? !hideHecsLiabilityType : true,
    )
    .map((value) => ({
      label: t(`Content.Common.LiabilityType.${value}`),
      value,
    }));
}

export function getLoanTermValue(
  ml: Omit<MergedLiabilityForYourDebtsFragment, 'current_liability'>,
) {
  if (ml.dynamite_loan_term_months) {
    return ml.dynamite_loan_term_months;
  }
  if (ml.dynamite_term_months_end_date) {
    return (
      differenceInMonths(
        new Date(ml.dynamite_term_months_end_date),
        new Date(),
      ) + 1
    );
  }
  return null;
}

export function getRepaymentFrequencyFromLiabilityType(
  liabilityType: Liability_Type_Enum,
): Frequency_Input_Enum | null {
  if (liabilityType === Liability_Type_Enum.HecsHelp) {
    return null;
  }

  return Frequency_Input_Enum.Monthly;
}
