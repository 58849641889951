import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import { useDebounce } from './useDebounce';
import { useSendDataToGTM } from './useSendDataToGTM';

const DEBOUNCE_WAIT_IN_MS = 1000;

export const useDebouncedSendAppInteractionEventToGTM = (
  description: GTMAppInteractionEventDescription,
  interactionKey?: ApplicationInteractionEventKeyType,
  wait: number = DEBOUNCE_WAIT_IN_MS,
) => {
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();
  return useDebounce(
    () =>
      sendAppInteractionEventToGTM({
        description,
        additionalData: {
          application_interaction_event_key: interactionKey,
        },
      }),
    wait,
  );
};
