import { useCallback } from 'react';

import { ActionSheetType } from '../../navigation/types/screens';
import { BaseConfirmationSuccessActionSheet } from '../components/BaseConfirmationSuccessActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props = ActionSheetScreenProps<ActionSheetType.CONFIRMATION_SUCCESS>;

export function ConfirmationSuccessActionSheet({ navigation, route }: Props) {
  const { message, supportingText, includeCloseButton } = route.params;

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <BaseConfirmationSuccessActionSheet
      message={message}
      supportingText={supportingText}
      includeCloseButton={includeCloseButton}
      onClose={onClose}
    />
  );
}
