import { useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { useHideBackButton } from '../../utils/hooks/useHeaderButton';
import { GreenCheckAnimation } from '../components/GreenCheckAnimation';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationV2ScreenProps } from '../navigation/types';

type Props = LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_COMPLETED>;

/**
 * This screen was completely reused from v1, no changes from v1 implementation
 */
function CompletedLoanApplicationBase({ navigation }: Props) {
  useHideBackButton(navigation);
  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);
  return (
    <LoanApplicationScreenContainer containerStyle={styles.root} px="l">
      <NavHeaderSpacer />
      <GreenCheckAnimation />
      <StyledText variant="header" mt="l" textAlign="center">
        {t('Content.CompletedLoanApplication.Header')}
      </StyledText>
      <StyledText my="s" textAlign="center">
        {t('Content.CompletedLoanApplication.Caption')}
      </StyledText>
      <Button
        label={t('Content.Common.ButtonLabel.BackToDashboard')}
        testID={TestID.CompletedLoanApplication.BackToDashboardButton}
        width="100%"
        onPress={navigateToHome}
      />
    </LoanApplicationScreenContainer>
  );
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const CompletedLoanApplication = withAuthenticationRequired(
  CompletedLoanApplicationBase,
);
