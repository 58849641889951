import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function DeleteIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(22, 24, size);
  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 22 24"
      fill="none"
      {...props}
    >
      <Path
        d="M20.75 3.75h-3.89l-1.594-2.625C14.844.422 14.14 0 13.344 0H8.609c-.796 0-1.5.422-1.921 1.125L5.093 3.75H1.25c-.422 0-.75.375-.75.75v.75c0 .422.328.75.75.75H2v15c0 1.688 1.313 3 3 3h12c1.64 0 3-1.313 3-3V6h.75c.375 0 .75-.328.75-.75V4.5c0-.375-.375-.75-.75-.75ZM8.516 2.39c.046-.046.14-.14.28-.14h4.407c.094 0 .188.094.235.14l.796 1.36H7.72l.797-1.36ZM17 21.75H5a.74.74 0 0 1-.75-.75V6h13.5v15c0 .422-.375.75-.75.75Zm-6-2.25c.375 0 .75-.328.75-.75V9c0-.375-.375-.75-.75-.75-.422 0-.75.375-.75.75v9.75c0 .422.328.75.75.75Zm-3.75 0c.375 0 .75-.328.75-.75V9c0-.375-.375-.75-.75-.75-.422 0-.75.375-.75.75v9.75c0 .422.328.75.75.75Zm7.5 0c.375 0 .75-.328.75-.75V9c0-.375-.375-.75-.75-.75-.422 0-.75.375-.75.75v9.75c0 .422.328.75.75.75Z"
        fill={color}
        fillOpacity={0.95}
      />
    </Svg>
  );
}
