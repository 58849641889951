import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function InfoIconFilled({ color, size, ...props }: SvgIconProps) {
  return (
    <Svg
      width={resizeIconWidth(24, 24, size)}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <Path
        d="M12 23.2012C17.9941 23.2012 22.957 18.2275 22.957 12.2441C22.957 6.25 17.9834 1.28711 11.9893 1.28711C6.00586 1.28711 1.04297 6.25 1.04297 12.2441C1.04297 18.2275 6.0166 23.2012 12 23.2012ZM11.8926 8.32324C11.0869 8.32324 10.4424 7.67871 10.4424 6.8623C10.4424 6.0459 11.0869 5.40137 11.8926 5.40137C12.6982 5.40137 13.332 6.0459 13.332 6.8623C13.332 7.67871 12.6982 8.32324 11.8926 8.32324ZM10.0557 18.3564C9.59375 18.3564 9.23926 18.0234 9.23926 17.5615C9.23926 17.1318 9.59375 16.7773 10.0557 16.7773H11.4199V11.7285H10.2383C9.78711 11.7285 9.43262 11.3955 9.43262 10.9336C9.43262 10.5039 9.78711 10.1494 10.2383 10.1494H12.3223C12.8916 10.1494 13.1924 10.5576 13.1924 11.1592V16.7773H14.5566C15.0186 16.7773 15.373 17.1318 15.373 17.5615C15.373 18.0234 15.0186 18.3564 14.5566 18.3564H10.0557Z"
        fill="#3C3C43"
        fillOpacity="0.33"
      />
    </Svg>
  );
}
