import * as Sentry from '@sentry/react';
import * as React from 'react';

import { isRunningOnStorybook } from './isRunningOnStorybook';

const IsStorybook = isRunningOnStorybook();
export function withSentryProfiler<Props extends Record<string, unknown>>(
  Component: React.ComponentType<Props>,
) {
  if (IsStorybook) {
    return Component;
  }
  return Sentry.withProfiler(Component);
}
