import { TestID } from '../../../testID/constants';
import {
  FormCollapsibleSelectionRow,
  FormCurrencyInput,
} from '../../components/form/FormikInputs';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';

export const CustomRepaymentFormInput = ({
  validationErrorMessage,
  loading,
}: {
  validationErrorMessage?: string | null | undefined;
  loading?: boolean;
}) => (
  <ListRowGroup mx={0} footerErrorMessage={validationErrorMessage}>
    <Skeleton show={loading}>
      <FormCurrencyInput
        inputTestID={TestID.CustomRepaymentFormInput.CustomRepaymentInput}
        name="customRepaymentAmount"
        placeholder={t('Content.Common.Placeholder.EnterAmount')}
        autoFocus
        displayError={false}
      />
    </Skeleton>
    <Skeleton show={loading}>
      <FormCollapsibleSelectionRow
        testID={TestID.CustomRepaymentFormInput.CustomRepaymentFrequencyTrigger}
        groupTestId={TestID.CustomRepaymentFormInput.CustomRepaymentFrequency}
        name="customRepaymentFrequency"
        placeholder={t('Content.AutopaySettings.RepaymentFrequency')}
        last
        displayError={false}
        options={[
          {
            label: t('Content.AutopaySettings.PerWeek'),
            value: Autopay_Frequency_Input_Enum.Weekly,
          },
          {
            label: t('Content.AutopaySettings.PerFortnight'),
            value: Autopay_Frequency_Input_Enum.Fortnightly,
          },
          {
            label: t('Content.AutopaySettings.PerMonth'),
            value: Autopay_Frequency_Input_Enum.Monthly,
          },
        ]}
      />
    </Skeleton>
  </ListRowGroup>
);
