import { useYourExpensesScreenQuery } from '../graphql/queries';

type ExpenseListProps = Pick<
  ReturnType<typeof useYourExpensesScreenQuery>,
  'householdExpenses' | 'applicants'
>;

export function hasAllApplicantsDeclaredHousehold(
  householdExpenses: NonNullable<ExpenseListProps['householdExpenses']>,
  applicants: NonNullable<ExpenseListProps['applicants']>,
) {
  const applicantInHouseholds = householdExpenses.flatMap((expense) =>
    expense.household.flatMap((currentHousehold) =>
      currentHousehold.applicants.map((applicant) => applicant.id),
    ),
  );

  return applicantInHouseholds.length === applicants.length;
}
