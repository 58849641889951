import * as DocumentPicker from 'expo-document-picker';
import { useMemo } from 'react';

import {
  allowedExtensions,
  allowedMimeTypes,
  MimeTypeDictionary,
} from '../../DocumentCentre/constants/mimeTypes';
import { File_Type } from '../../generated/graphql';
import { captureException } from '../../sentry';
import { Alert } from '../../ui/atoms/Alert';
import { isWeb } from '../platformUtils';

/**
 * Currently Expo's Document Picker doesn't support passing array of mimetypes
 * Waiting for https://github.com/expo/expo/pull/13751 to be released so we can do that
 * In the meantime, the workaround is:
 * - In web, we supply array of mimetypes joined with comma [just like how input type file works in web](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file)
 * - For native, we let user pick everything and filter after user pick the document
 */
const ALLOWED_MIME_TYPES = isWeb ? allowedMimeTypes.join(',') : '*/*';

export type SelectedDocument = {
  name: string;
  size: number;
  webOnlyFile: File | null;
  fileType: File_Type;
  fileUri: string;
  lastModifiedAt: number;
};

type DocumentPickerHandlerOptions = {
  onDocumentSelect: (document: SelectedDocument) => void | Promise<void>;
  onCancel?: () => void;
  onError?: () => void;
};

export function useDocumentPicker() {
  return useMemo(
    () => ({
      makeDocumentPickerHandler:
        ({
          onCancel,
          onError,
          onDocumentSelect,
        }: DocumentPickerHandlerOptions) =>
        async () => {
          const result = await DocumentPicker.getDocumentAsync({
            type: ALLOWED_MIME_TYPES,
          });
          if (result.canceled) {
            onCancel?.();
            return;
          }
          const asset = result.assets?.[0];
          if (asset == null) {
            onCancel?.();
            return;
          }

          const { name, file, size, lastModified, uri, mimeType } = asset;
          if (size == null) {
            Alert.alert(
              t('Content.DocumentPicker.NoFileSizeErrorTitle'),
              t('Content.DocumentPicker.NoFileSizeErrorMessage'),
            );
            onError?.();
            return;
          }

          const detectedType = mimeType ? MimeTypeDictionary[mimeType] : null;
          if (detectedType == null) {
            Alert.alert(
              t('Content.DocumentPicker.InvalidMimeTypeErrorTitle'),
              t('Content.DocumentPicker.InvalidMimeTypeErrorMessage', {
                extensions: allowedExtensions.join(', '),
              }),
            );
            onError?.();
            return;
          }

          if (isWeb && file == null) {
            Alert.alert(
              t('Content.DocumentPicker.NoFileOnWebErrorTitle'),
              t('Content.DocumentPicker.NoFileOnWebErrorMessage'),
            );
            onError?.();
            // `result.file` is optional from the typing fo `expo-document-picker`.
            // But from observation result, it is always defined on web platform,
            // and undefined on native.
            // Need confirmation on this behavior from Expo, as this is undocumented.
            // In the meantime, we have this exception captured and reported back.
            // If this exception is removed, application code that handles the upload
            // needs to be updated to account for this case.
            captureException(
              'Unexpected nil `file` from `DocumentPicker.getDocumentAsync()` result on web',
              { rawPickerResult: { name, size, lastModified, uri, mimeType } },
            );
            return;
          }

          const document: SelectedDocument = {
            name,
            size,
            webOnlyFile: file ?? null,
            fileType: detectedType.fileType,
            fileUri: uri,
            lastModifiedAt: lastModified ?? Date.now(),
          };
          await onDocumentSelect(document);
        },
    }),
    [],
  );
}
