import { useCallback } from 'react';

import { GTMEvent, SendDataToGTM } from '../../Analytics/types';
import { useGTMDispatchInstance } from '../../Analytics/useGTMDispatchInstance';
import { FeatureFlag, Flags } from '../../FeatureFlags/featureFlags';

export const useSendExperimentFlagToGTM = () => {
  const sendDataToGTM: SendDataToGTM = useGTMDispatchInstance();

  return useCallback(
    (
      flag: FeatureFlag,
      variant: Flags[FeatureFlag],
      experiment: string,
      userId?: string,
      loanApplicationId?: string,
    ) =>
      userId &&
      sendDataToGTM({
        event: GTMEvent.ExperimentExposure,
        experiment_name: experiment,
        experiment_flag: flag,
        experiment_variant: variant,
        user_id: userId,
        ...(loanApplicationId && { loan_application_id: loanApplicationId }),
      }),
    [sendDataToGTM],
  );
};
