import { useState } from 'react';
import * as React from 'react';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { SwitchRow } from '../../ui/molecules/SwitchRow';
import { SettingsScreenProps } from '../navigation/types';

type Props = SettingsScreenProps<Screen.SETTINGS_NOTIFICATION>;

const NotificationsBase: React.FC<Props> = () => {
  const [notifications, setNotifications] = useState(false);

  return (
    <ScreenContainer bg="bg" safeAreaTop={false}>
      <NavHeaderSpacer />
      <Box mt="m">
        <ListRowGroup
          headerText="Reminders"
          footerText="We’ll still notify you about things relating to the security of your accounts, even if you’ve turned notifications off."
        >
          <SwitchRow
            label="Upcoming Repayments"
            value={notifications}
            setFieldValue={setNotifications}
            last
          />
        </ListRowGroup>
      </Box>
    </ScreenContainer>
  );
};

export const WithAuthenticationNotifications =
  withAuthenticationRequired(NotificationsBase);
