import React, { useCallback, useMemo } from 'react';

import { useIntercom } from '../../Home/navigation/utils/useIntercom';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { AmortizationFailureDetails } from '../components/AmortizationFailureDetails';
import { DirectDebitFailureDetails } from '../components/DirectDebitFailureDetails';
import { NeedAssistance } from '../components/NeedAssistance';
import { usePaymentFailureDetails } from '../hooks/usePaymentFailureDetails';
import { showDirectDebitModal } from '../utils';

export type Props = SingleModalStackScreenProps<Screen.PAYMENT_FAILURE_MODAL>;

export const PaymentFailureModal = ({ navigation, route }: Props) => {
  const cbaAccountId = route?.params?.cbaAccountId;
  const { onChatIconPress: openIntercomChat } = useIntercom();
  const { amortizationFailureDetails, directDebitFailureDetails, loading } =
    usePaymentFailureDetails(route?.params?.cbaAccountId);

  const openAutoPaySettings = useCallback(() => {
    if (!cbaAccountId) return;
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_EXISTING_SETTINGS,
      params: { cbaAccountId },
    });
  }, [cbaAccountId, navigation]);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const content = useMemo(() => {
    if (loading) return <Spinner />;

    if (amortizationFailureDetails) {
      return (
        <>
          <AmortizationFailureDetails {...amortizationFailureDetails} />
          <NeedAssistance openIntercom={openIntercomChat} />
        </>
      );
    }

    if (
      directDebitFailureDetails &&
      showDirectDebitModal(directDebitFailureDetails)
    ) {
      return (
        <>
          <DirectDebitFailureDetails
            {...directDebitFailureDetails}
            openAutoPaySettings={openAutoPaySettings}
          />
          <NeedAssistance openIntercom={openIntercomChat} />
        </>
      );
    }

    return <NotFoundScreen />;
  }, [
    amortizationFailureDetails,
    directDebitFailureDetails,
    loading,
    openAutoPaySettings,
    openIntercomChat,
  ]);

  return (
    <ModalScreenContainer onClose={onClose} hideBackButton>
      {content}
    </ModalScreenContainer>
  );
};
