import { formatCurrency } from '../../utils/currencyHelpers';

export const formatInstitutionAccountCaption = (
  accountNumber: string,
  isCredit: boolean,
  amount?: number,
): string =>
  `${getFormattedAccountNumber(accountNumber)}\n${getFormattedBalanceOrLimit(
    formatCurrency(amount || 0),
    isCredit,
  )}`;

function getFormattedAccountNumber(accountNumber: string) {
  return accountNumber
    .replace(/[xX]/g, '•')
    .replace(/(.{4})/g, '$1 ')
    .trim();
}

function getFormattedBalanceOrLimit(amount?: string, isCredit?: boolean) {
  if (isCredit) {
    return t('Content.ConnectedBanks.Limit', { value: amount || '--' });
  }
  return t('Content.ConnectedBanks.Balance', { value: amount || '--' });
}
