import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function DocumentIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(20, 24, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 20 24" fill="none" {...props}>
      <Path
        d="M4.296 23.76h11.408c2.224 0 3.33-1.128 3.33-3.363v-9.753c0-1.386-.161-1.988-1.02-2.869l-5.92-6.026C11.279.911 10.603.729 9.399.729H4.296C2.083.729.966 1.867.966 4.102v16.296c0 2.245 1.106 3.362 3.33 3.362zm.075-1.73c-1.106 0-1.676-.59-1.676-1.665V4.134c0-1.064.57-1.676 1.687-1.676h4.78v6.241c0 1.354.677 2.02 2.02 2.02h6.123v9.646c0 1.075-.57 1.665-1.687 1.665H4.371zm7.004-12.933c-.43 0-.59-.172-.59-.602V2.791l6.187 6.306h-5.597zm2.578 4.587H5.8a.656.656 0 00-.677.655c0 .376.29.666.677.666h8.153c.376 0 .655-.29.655-.666a.644.644 0 00-.655-.655zm0 3.749H5.8c-.387 0-.677.3-.677.676 0 .366.29.645.677.645h8.153c.376 0 .655-.28.655-.645 0-.376-.279-.676-.655-.676z"
        fill={color}
      />
    </Svg>
  );
}
