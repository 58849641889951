import { SxProp, View } from 'dripsy';
import * as React from 'react';

type CardHeaderProps = {
  avatar?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  sx?: SxProp;
};

export const CardHeader = ({
  avatar,
  children,
  subtitle,
  title,
  sx = {},
}: CardHeaderProps) => (
  <View
    sx={{
      flexDirection: 'row',
      px: '$16',
      pt: '$16',
      ...sx,
    }}
  >
    {/* TODO add styles for avatar when we have a usecase */}
    {avatar ? <View>{avatar}</View> : null}
    <View>
      {title || null}
      {subtitle || null}
    </View>
    {children}
  </View>
);
