import { gql } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import { useCallback, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { useAuth, useUser } from '../../Auth/hooks';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { useBiocatch } from '../../biocatch/BiocatchProvider';
import { SuccessRow } from '../../components/SuccessRow';
import { ENV_CONFIG, ENVIRONMENT } from '../../config';
import { VERSION_STRING } from '../../constants/appVersion';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  useGetBackendVersionQuery,
  useGetObDataRecipientFeatureFlagForShowingConnectedBanksRowQuery,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { useIntercom } from '../../Home/navigation/utils/useIntercom';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Alert } from '../../ui/atoms/Alert';
import { Box } from '../../ui/atoms/Box';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowGroupProps,
} from '../../ui/molecules/DataRow';
import { InstallPwaListRow } from '../../ui/organisms/PwaInstall/InstallPwaListRow';
import { Color } from '../../ui/types';
import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import { useView } from '../../utils/hooks/useBreakpoint';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { isWeb } from '../../utils/platformUtils';
import { DeleteAccount } from '../components/DeleteAccount';
import { SettingsScreenProps } from '../navigation/types';
import { settingsSuccessMessageAtom } from '../recoil/settingsSuccessMessage';

const SUBLABELS_COLOR: Color = 'secondaryContent';

export type SettingsProps = SettingsScreenProps<Screen.SETTINGS_HOME>;

export const GET_BACKEND_VERSION = gql`
  query GetBackendVersion {
    version
  }
`;

export const GetOBDataRecipientFeatureFlagForShowingConnectedBanksRow = gql`
  query GetOBDataRecipientFeatureFlagForShowingConnectedBanksRow {
    user {
      id
      identity_profile {
        id
      }
    }
  }
`;

const dataRowGroupProps: DataRowGroupProps = {
  mt: 's',
};

type BaseSettingsProps = {
  loading?: boolean;
  navigation: SettingsProps['navigation'];
  successMessage?: string | null;
  showManageDataSharing?: boolean;
  showConnectedBanksRow?: boolean;
  showPwaInstallPrompt?: boolean;
  showDiagnostics?: boolean;
  showNewFeaturesCard?: boolean;
  showSettingsFeedbackCard?: boolean;
  onPressBack: () => void;
  navigateToPersonalDetails: () => void;
  navigateToSecurity: () => void;
  navigateToDailyTransferLimit: () => void;
  navigateToManageData: () => void;
  navigateToConnectedBanks: () => void;
  onNewFeatures?: () => void;
  onGiveFeedback?: () => void;

  signOut: () => void;
};

export function BaseSettings({
  loading,
  navigation,
  successMessage,
  showManageDataSharing,
  showConnectedBanksRow,
  showPwaInstallPrompt,
  showDiagnostics,
  showNewFeaturesCard,
  showSettingsFeedbackCard,
  onPressBack,
  navigateToPersonalDetails,
  navigateToSecurity,
  navigateToDailyTransferLimit,

  navigateToManageData,

  navigateToConnectedBanks,
  onNewFeatures,
  onGiveFeedback,
  signOut,
}: BaseSettingsProps) {
  const { isMobileNativeView } = useView();
  return (
    <UpliftScreenContainer
      hideCopyright={false}
      onPressBack={isMobileNativeView ? undefined : onPressBack}
    >
      <HomeHeader
        title={t('Content.Settings.ScreenTitle')}
        subTitle={t('Content.Settings.ScreenSubTitle')}
      />

      {successMessage ? (
        <SuccessRow mb="s" message={successMessage} autoDismiss />
      ) : null}

      <DataRowGroup {...dataRowGroupProps} mt={0}>
        <DataRow
          loading={loading}
          label={t('Content.Settings.PersonalDetails.Title')}
          iconName="profile"
          iconFamilyName="svg"
          iconColor="accent"
          onPress={navigateToPersonalDetails}
          testID={TestID.Settings.PersonalDetailsButton}
          sublabels={[t('Content.Settings.PersonalDetails.SupportingText')]}
          sublabelsColor={SUBLABELS_COLOR}
        />
      </DataRowGroup>

      {isWeb ? null : (
        <DataRowGroup {...dataRowGroupProps}>
          <DataRow
            loading={loading}
            label={t('Content.Settings.Security.Title')}
            iconName="security"
            iconFamilyName="svg"
            iconColor="accent"
            onPress={navigateToSecurity}
            testID={TestID.Settings.SecurityRow}
            sublabels={[t('Content.Settings.Security.SupportingText')]}
            sublabelsColor={SUBLABELS_COLOR}
          />
        </DataRowGroup>
      )}

      <DataRowGroup {...dataRowGroupProps}>
        <DataRow
          loading={loading}
          label={t('Content.Settings.DailyTransferLimit.Title')}
          iconName="transfer"
          iconFamilyName="svg"
          iconColor="accent"
          onPress={navigateToDailyTransferLimit}
          testID={TestID.Settings.DailyTransferLimitButton}
          sublabels={[t('Content.Settings.DailyTransferLimit.SupportingText')]}
          sublabelsColor={SUBLABELS_COLOR}
        />
      </DataRowGroup>
      {showManageDataSharing ? (
        <DataRowGroup {...dataRowGroupProps}>
          <DataRow
            loading={loading}
            label={t('Content.Settings.ManageDataSharing.Title')}
            iconName="dataSharing"
            iconFamilyName="svg"
            onPress={navigateToManageData}
            iconColor="accent"
            testID={TestID.Settings.ManageDataButton}
            sublabels={[t('Content.Settings.ManageDataSharing.SupportingText')]}
            sublabelsColor={SUBLABELS_COLOR}
          />
        </DataRowGroup>
      ) : null}
      {showConnectedBanksRow ? (
        <DataRowGroup {...dataRowGroupProps}>
          <DataRow
            loading={loading}
            label={t('Content.Settings.ConnectedBanks.Title')}
            iconName="institution"
            iconFamilyName="svg"
            onPress={navigateToConnectedBanks}
            iconColor="accent"
            testID={TestID.Settings.ConnectedBanksButton}
            sublabels={[t('Content.Settings.ConnectedBanks.SupportingText')]}
            sublabelsColor={SUBLABELS_COLOR}
          />
        </DataRowGroup>
      ) : null}

      {showNewFeaturesCard ? (
        <DataRowGroup {...dataRowGroupProps}>
          <DataRow
            loading={loading}
            label={t('Content.Settings.NewFeatures.Title')}
            iconName="flash"
            iconFamilyName="svg"
            onPress={onNewFeatures}
            iconColor="accent"
            testID={TestID.Settings.NewFeaturesButton}
            sublabels={[t('Content.Settings.NewFeatures.SupportingText')]}
            sublabelsColor={SUBLABELS_COLOR}
          />
        </DataRowGroup>
      ) : null}

      {showSettingsFeedbackCard ? (
        <DataRowGroup {...dataRowGroupProps}>
          <DataRow
            loading={loading}
            label={t('Content.Settings.GiveFeedback.Title')}
            iconName="lightBulb"
            iconFamilyName="svg"
            onPress={onGiveFeedback}
            iconColor="accent"
            testID={TestID.Settings.GiveFeedbackButton}
            sublabels={[t('Content.Settings.GiveFeedback.SupportingText')]}
            sublabelsColor={SUBLABELS_COLOR}
          />
        </DataRowGroup>
      ) : null}

      {showPwaInstallPrompt ? (
        <InstallPwaListRow loading={loading} {...dataRowGroupProps} />
      ) : null}

      <DataRowGroup {...dataRowGroupProps} mt="xl" mb="s">
        <DataRow
          loading={loading}
          label={t('Content.Settings.LogOut')}
          labelColor="error"
          labelFontWeight="medium"
          onPress={signOut}
          testID={TestID.Settings.SignOut}
          useArrow={false}
        />
      </DataRowGroup>

      <DeleteAccount loading={loading} navigation={navigation} />
      <Box dataSet={chromaticIgnoreDataSet} opacity={loading ? 0 : undefined}>
        {showDiagnostics ? <Diagnostics /> : null}
        <StyledText
          variant="caption"
          alignSelf="center"
          textAlign="center"
          testID={TestID.Settings.AppVersionRow}
        >
          {`Version: ${VERSION_STRING}`}
        </StyledText>
      </Box>
    </UpliftScreenContainer>
  );
}

function Settings({ navigation }: SettingsProps) {
  const { data, loading } =
    useGetObDataRecipientFeatureFlagForShowingConnectedBanksRowQuery();

  const { flags } = useContext(FeatureFlagsContext);
  const showSettingsFeedbackCard = flags.ENABLE_SETTINGS_FEEDBACK_CARD;

  const showConnectedBanksRow =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT &&
    data?.user?.[0]?.identity_profile?.id != null;

  const showPwaInstallPrompt = flags.ENABLE_PWA_INSTALL_PROMPT;

  const showNewFeaturesCard = flags.ENABLE_NEW_FEATURES_SCREEN;

  const [successMessage, setSuccessMessage] = useRecoilState(
    settingsSuccessMessageAtom,
  );

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) {
      setSuccessMessage(null);
    }
  }, [isFocused, setSuccessMessage]);

  const navigateToPersonalDetails = useCallback(
    () => navigation.navigate(Screen.SETTINGS_PERSONAL),
    [navigation],
  );

  const navigateToSecurity = useCallback(
    () => navigation.navigate(Screen.SETTINGS_SECURITY),
    [navigation],
  );

  const navigateToConnectedBanks = useCallback(
    () => navigation.navigate(Screen.SETTINGS_CONNECTED_BANKS),
    [navigation],
  );

  const navigateToManageData = useCallback(
    () => navigation.navigate(Screen.SETTINGS_MANAGE_DATA_SHARING),
    [navigation],
  );

  const navigateToDailyTransferLimit = useCallback(
    () => navigation.navigate(Screen.SETTINGS_DAILY_TRANSFER_LIMIT),
    [navigation],
  );

  const navigateToNewFeatures = useCallback(
    () => navigation.navigate(Screen.SETTINGS_NEW_FEATURES),
    [navigation],
  );

  const { onGiveFeedback } = useIntercom();

  const onPressBack = useCallback(() => {
    setSuccessMessage(null);
    navigation.goBack();
  }, [navigation, setSuccessMessage]);

  const { env, config } = useEnvConfig();

  const { logout } = useAuth();

  return (
    <BaseSettings
      loading={loading}
      navigation={navigation}
      successMessage={successMessage}
      onPressBack={onPressBack}
      navigateToPersonalDetails={navigateToPersonalDetails}
      navigateToSecurity={navigateToSecurity}
      navigateToDailyTransferLimit={navigateToDailyTransferLimit}
      showManageDataSharing={config.showManageDataSharing}
      navigateToManageData={navigateToManageData}
      showConnectedBanksRow={showConnectedBanksRow}
      showNewFeaturesCard={showNewFeaturesCard}
      showSettingsFeedbackCard={showSettingsFeedbackCard}
      navigateToConnectedBanks={navigateToConnectedBanks}
      onNewFeatures={navigateToNewFeatures}
      onGiveFeedback={onGiveFeedback}
      showPwaInstallPrompt={showPwaInstallPrompt}
      showDiagnostics={ENV_CONFIG[env].showDiagnostics}
      signOut={logout}
    />
  );
}

function Diagnostics() {
  const navigation = useNavigation();
  const { env, config } = useEnvConfig();
  const { user } = useUser();

  const { data: backendVersionData, loading: backendVersionLoading } =
    useGetBackendVersionQuery();

  const { customerSessionId } = useBiocatch();

  const onEnvPress = useCallback(
    () =>
      env !== ENVIRONMENT.PROD_V3
        ? navigation.navigate(ActionSheetType.DEVELOPER_TOOLS)
        : null,
    [env, navigation],
  );

  return (
    <Box px="m" alignItems="center" dataSet={chromaticIgnoreDataSet}>
      <StyledText
        variant="body"
        textAlign="center"
        color="link"
        onPress={onEnvPress}
        testID={TestID.Settings.EnvironmentRow}
      >
        Environment: {env}
      </StyledText>
      <StyledText
        variant="caption"
        textAlign="center"
        testID={TestID.Settings.EndpointRow}
      >
        {config.graphqlURL.replace(/^https:\/\//, '')}
      </StyledText>
      <StyledText
        variant="caption"
        textAlign="center"
        testID={TestID.Settings.UserIdRow}
        onPress={
          user?.id
            ? () => {
                Alert.alert(
                  'Warning: Pressing OK will overwrite the contents of the clipboard with the user id',
                  '',
                  [
                    {
                      text: 'OK',
                      onPress: () => {
                        Clipboard.setStringAsync(user.id);
                      },
                    },
                  ],
                );
              }
            : undefined
        }
      >
        UserID: {user?.id}
      </StyledText>
      <StyledText
        variant="caption"
        textAlign="center"
        testID={TestID.Settings.CustomerSessionIdRow}
        onPress={
          typeof customerSessionId === 'string'
            ? () => {
                Alert.alert(
                  'Warning: Pressing OK will overwrite the contents of the clipboard with the Customer Session ID',
                  '',
                  [
                    {
                      text: 'OK',
                      onPress: async () => {
                        Clipboard.setStringAsync(customerSessionId);
                      },
                    },
                  ],
                );
              }
            : undefined
        }
      >
        Customer Session ID: {customerSessionId}
      </StyledText>
      <StyledText
        variant="caption"
        textAlign="center"
        testID={TestID.Settings.BackendVersionRow}
        dataSet={chromaticIgnoreDataSet}
      >
        Backend Version:{' '}
        {backendVersionLoading ? 'Loading...' : backendVersionData?.version}
      </StyledText>
    </Box>
  );
}

export const WithAuthenticationSettings = withAuthenticationRequired(Settings);
