import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { Status } from '../../DataSharingConfirmation/types';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyFormatDate } from '../../utils/dateHelpers';

type DataSharingMessageProps = {
  status: Status;
  requestExpiryDate?: string | null;
};

const DataSharingMessage: React.FC<DataSharingMessageProps> = ({
  status,
  requestExpiryDate,
}) => {
  let displayText;
  if (status === Status.Pending)
    displayText = requestExpiryDate
      ? t(
          'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.Message',
          {
            date: safelyFormatDate(requestExpiryDate, 'dd MMM yyyy') || '--',
          },
        )
      : t(
          'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.MessageWithoutDate',
        );
  else if (status === Status.Enabled)
    displayText = t(
      'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingEnabled.Message',
    );
  else
    displayText = t(
      'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingDisabled.Message',
    );
  return (
    <>
      <Separator spacer />
      <StyledText
        variant="caption"
        mx="m"
        testID={TestID.ManageDataSharing.DataSharingMessage}
      >
        {displayText}
      </StyledText>
      <Separator spacer />
    </>
  );
};

export default DataSharingMessage;
