import { A, Text, View } from 'dripsy';
import React from 'react';

import { TestID } from '../../../testID/constants';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../ui/v2/List';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsb } from '../../utils/formatBsbAndAccountNumber';

export type Props = {
  accountDetails: {
    bsb: string;
    accountNumber: string;
    name: string;
  };
  daysInArrears: number;
  daysLate: number;
  totalOverdueAmount: number;
};

const TEXT_PREFIX = 'Content.PaymentFailureModal.AmortizationFailure';

const AccountOwingListItem = ({
  title,
  subText,
}: {
  title: string;
  subText: string;
}) => (
  <ListItem
    sx={{ py: '$16', mx: '$16' }}
    title={<Text variant="xsHeader">{title}</Text>}
    line={<Text variant="sBody">{subText}</Text>}
  />
);

const AccountOwingDetails = ({
  daysLate,
  totalOverdueAmount,
}: Pick<Props, 'daysLate' | 'totalOverdueAmount'>) => (
  <CardList sx={{ mb: 0 }}>
    <AccountOwingListItem
      title={t(`${TEXT_PREFIX}.OutstandingDetails.AmountOwing`)}
      subText={formatCurrency(totalOverdueAmount)}
    />
    <Divider />
    <AccountOwingListItem
      title={t(`${TEXT_PREFIX}.OutstandingDetails.DaysLate`)}
      subText={`${daysLate} ${t(
        `${TEXT_PREFIX}.OutstandingDetails.DaysLateSuffix`,
      )}`}
    />
  </CardList>
);

const PaymentOptionListItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <ListItem
    sx={{ py: '$16', mx: '$16' }}
    title={<Text variant="xsHeader">{title}</Text>}
    line={
      <Text sx={{ mt: '$4', pl: '$14' }} variant="sBody">
        {description}
      </Text>
    }
  />
);

const PaymentOptionsDetails = ({
  accountDetails: { accountNumber, bsb },
}: Pick<Props, 'accountDetails'>) => (
  <CardList>
    <PaymentOptionListItem
      title={t(`${TEXT_PREFIX}.PaymentOptions.TransferFunds.Title`)}
      description={t(
        `${TEXT_PREFIX}.PaymentOptions.TransferFunds.Description`,
        {
          accountNumber,
          bsb: formatBsb(bsb),
        },
      )}
    />
    <Divider />
    <PaymentOptionListItem
      title={t(`${TEXT_PREFIX}.PaymentOptions.Telephone.Title`)}
      description={t(`${TEXT_PREFIX}.PaymentOptions.Telephone.Description`)}
    />
  </CardList>
);

export const AmortizationFailureDetails = ({
  accountDetails,
  daysInArrears,
  daysLate,
  totalOverdueAmount,
}: Props) => {
  const isInArrears = daysInArrears > 0;
  // business rule - show warning text if the repayment is atleast 4 days late
  const showWarningText = daysLate >= 4;
  return (
    <View testID={TestID.PaymentFailure.AmortizationFailureDetails}>
      <Text sx={{ paddingVertical: '$8' }} variant="header">
        {isInArrears
          ? t(`${TEXT_PREFIX}.Title.AccountInArrears`)
          : t(`${TEXT_PREFIX}.Title.WithinTolerancePeriod`)}
      </Text>

      {/* Outstanding Details Section */}
      <View sx={{ mt: '$16' }}>
        <Text sx={{ paddingVertical: '$8' }} variant="sBody">
          {t(`${TEXT_PREFIX}.OutstandingDetails.Title`, {
            loanAccountName: accountDetails.name,
          })}
        </Text>
        <AccountOwingDetails
          daysLate={daysLate}
          totalOverdueAmount={totalOverdueAmount}
        />
        {showWarningText ? (
          <Text sx={{ paddingVertical: '$8' }} variant="sBody">
            {t(`${TEXT_PREFIX}.OutstandingDetails.WarningText`)}
          </Text>
        ) : null}
      </View>

      {/* Payment Options Section */}
      <View sx={{ mt: '$16' }}>
        <Text sx={{ paddingVertical: '$8' }} variant="sBody">
          {t(`${TEXT_PREFIX}.PaymentOptions.Title`)}
        </Text>
        <PaymentOptionsDetails accountDetails={accountDetails} />
        <Text variant="sBody">
          {t(`${TEXT_PREFIX}.PaymentOptions.CashPostWarning`)}
        </Text>
        <Text sx={{ mt: '$16' }} variant="sBody">
          {t(`${TEXT_PREFIX}.PaymentOptions.HelpStayOnTrack`)}
          <A
            variant="sBodyLink"
            href={t('Link.HowToSetupAutoPay')}
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {t(`${TEXT_PREFIX}.PaymentOptions.LearnMore`)}
          </A>
        </Text>
      </View>
    </View>
  );
};
