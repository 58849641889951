import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheetScreenProps } from '../navigation/types';
import {
  AutopaySetupConfirmationStep,
  AutopaySetupConfirmationStepAtom,
} from '../recoil/AutopaySetupConfirmationStep';
import { BaseAddDirectDebitAccountConfirmationActionSheet } from './AddDirectDebitAccountConfirmationActionSheet';

type AddDirectDebitAccountConfirmationV2ActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2>;

export function AddDirectDebitAccountConfirmationV2ActionSheet({
  navigation,
}: AddDirectDebitAccountConfirmationV2ActionSheetProps) {
  const setAutopaySetupConfirmationStep = useSetRecoilState(
    AutopaySetupConfirmationStepAtom,
  );

  const onClose = useCallback(() => navigation.goBack(), [navigation]);

  return (
    <BaseAddDirectDebitAccountConfirmationActionSheet
      closeModal={onClose}
      actionList={[
        {
          label: t('Content.Withdraw.AccountAuthorityConfirm'),
          secondary: true,
          testID:
            TestID.AddDirectDebitAccountConfirmationActionSheet.ConfirmButton,
          onPress: () => {
            setAutopaySetupConfirmationStep(
              AutopaySetupConfirmationStep.RequiresNewAccountConfirmationPassed,
            );

            onClose();
          },
        },
      ]}
    />
  );
}
