enum BooleanFeatureFlag {
  EnableAccountAddressBook = 'ENABLE_ACCOUNT_ADDRESS_BOOK',
  EnableAccountInsightsDiscountBump = 'ENABLE_ACCOUNT_INSIGHTS_DISCOUNT_BUMP',
  EnableAccountInsightsEstimatedPropertyValue = 'ENABLE_ACCOUNT_INSIGHTS_ESTIMATED_PROPERTY_VALUE',
  EnableAccountInsightsTermRemaining = 'ENABLE_ACCOUNT_INSIGHTS_TERM_REMAINING',
  EnableAccountInsightsTotalInterestSavings = 'ENABLE_ACCOUNT_INSIGHTS_TOTAL_INTEREST_SAVINGS',
  EnableAccountPermissions = 'ENABLE_ACCOUNT_PERMISSIONS',
  EnableAppDirectDebitFailureAlert = 'ENABLE_APP_DIRECT_DEBIT_FAILURE_ALERT',
  EnableApplyLoanCardV2 = 'ENABLE_APPLY_LOAN_CARD_V2',
  EnableAppPaymentFailureAlert = 'ENABLE_APP_PAYMENT_FAILURE_ALERT',
  EnableAuth0NewLoginScreen = 'ENABLE_AUTH0_NEW_LOGIN_SCREEN',
  EnableAutopayModalV2 = 'ENABLE_AUTOPAY_MODAL_V2',
  EnableAppShortfallAlert = 'ENABLE_APP_SHORTFALL_ALERT',
  EnableAvailableFundsCapture = 'ENABLE_AVAILABLE_FUNDS_CAPTURE',
  EnableBiocatchClientDataCapture = 'ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE',
  EnableCaConfirmationPrompt = 'ENABLE_CA_CONFIRMATION_PROMPT',
  EnableDashboardV2 = 'ENABLE_DASHBOARD_V2',
  EnableDeleteUser = 'ENABLE_DELETE_USER',
  EnableDuplicateCoBorrowerHandler = 'ENABLE_DUPLICATE_CO_BORROWER_HANDLER',
  EnableEquifaxIncomeVerification = 'ENABLE_EQUIFAX_INCOME_VERIFICATION',
  EnableEvidenceScreenUplift = 'ENABLE_EVIDENCE_SCREEN_UPLIFT',
  EnableExpensesScreenAndHemGuideUplift = 'ENABLE_EXPENSES_SCREEN_AND_HEM_GUIDE_UPLIFT',
  /** See https://unloan.atlassian.net/browse/UDEL-6785 */
  EnableHomeLoanAccountDetailsScreenV3 = 'ENABLE_HOME_LOAN_ACCOUNT_DETAILS_SCREEN_V3',
  EnableLmi = 'ENABLE_LMI',
  EnableLoanDocuments = 'ENABLE_LOAN_DOCUMENTS',
  EnableLoanTopUp = 'ENABLE_LOAN_TOP_UP',
  EnableManualKyc = 'ENABLE_MANUAL_KYC',
  EnableNativeAppOnboardingHandoff = 'ENABLE_NATIVE_APP_ONBOARDING_HANDOFF',
  EnableNewFeaturesScreen = 'ENABLE_NEW_FEATURES_SCREEN',
  EnableNppDescriptionValidation = 'ENABLE_NPP_DESCRIPTION_VALIDATION',
  EnableNppExtraIncomingTxnFields = 'ENABLE_NPP_EXTRA_INCOMING_TXN_FIELDS',
  EnableNppLongDescription = 'ENABLE_NPP_LONG_DESCRIPTION',
  EnableOpenBankingDataHolder = 'ENABLE_OPEN_BANKING_DATA_HOLDER',
  EnableOpenBankingDataRecipient = 'ENABLE_OPEN_BANKING_DATA_RECIPIENT',
  EnableOutOfService = 'ENABLE_OUT_OF_SERVICE',
  EnablePrincipalReduction = 'ENABLE_PRINCIPAL_REDUCTION',
  EnablePrepopulateEmploymentIncome = 'ENABLE_PREPOPULATE_EMPLOYMENT_INCOME',
  EnablePwaInstallPrompt = 'ENABLE_PWA_INSTALL_PROMPT',
  EnableSettingsFeedbackCard = 'ENABLE_SETTINGS_FEEDBACK_CARD',
  EnableShowSavingsOnYourUnloan = 'ENABLE_SHOW_SAVINGS_ON_YOUR_UNLOAN',
  EnableStatementsModalV2 = 'ENABLE_STATEMENTS_MODAL_V2',
  EnableSuburbAutocomplete = 'ENABLE_SUBURB_AUTOCOMPLETE',
  EnableTopUpDebtConsolidation = 'ENABLE_TOP_UP_DEBT_CONSOL',
  EnableTransferModalV2 = 'ENABLE_TRANSFER_MODAL_V2',
  EnableTransferFeature = 'ENABLE_TRANSFER_FEATURE',
  EnableTransferReference = 'ENABLE_TRANSFER_REFERENCE_ID',
  EnableTransferCents = 'ENABLE_TRANSFER_CENTS',
  EnableUpdateLegalName = 'ENABLE_UPDATE_LEGAL_NAME',
  EnableUpdateEmail = 'ENABLE_UPDATE_EMAIL',
  EnableUpdateMobileNumber = 'ENABLE_UPDATE_MOBILE_NUMBER',
  EnableUpdatePhysicalAddress = 'ENABLE_UPDATE_PHYSICAL_ADDRESS',
  EnableUseOfNewLoanTermField = 'ENABLE_USE_OF_NEW_LOAN_TERM_FIELD',
  EnableUsePexaWorkspaceSummary = 'ENABLE_USE_PEXA_WORKSPACE_SUMMARY',
  EnableVca = 'ENABLE_VCA',
  EnableWarningForExpectedRentalIncomeUpdate = 'ENABLE_WARNING_FOR_EXPECTED_RENTAL_INCOME_UPDATE',
  EnableViewExpensesInDynamics = 'ENABLE_VIEW_EXPENSES_IN_DYNAMICS',
  EnablePostEvidenceUpload = 'ENABLE_POST_EVIDENCE_UPLOAD',
}

enum StringFeatureFlag {
  DashboardVersion = 'DASHBOARD_VERSION',
  MinimumAppVersion = 'MINIMUM_APP_VERSION',
}

export const FeatureFlag = {
  ...BooleanFeatureFlag,
  ...StringFeatureFlag,
};
export type ExperimentFlagConfig = {
  [flag in FeatureFlag]?: {
    experimentName: string;
    defaultVariant: boolean;
  };
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FeatureFlag = BooleanFeatureFlag | StringFeatureFlag;
export type Flags = Record<BooleanFeatureFlag, boolean> &
  Record<StringFeatureFlag, string>;

export const defaultBooleanFeatureFlag: Record<BooleanFeatureFlag, boolean> = {
  [BooleanFeatureFlag.EnableAccountAddressBook]: false,
  [BooleanFeatureFlag.EnableAccountInsightsDiscountBump]: false,
  [BooleanFeatureFlag.EnableAccountInsightsEstimatedPropertyValue]: false,
  [BooleanFeatureFlag.EnableAccountInsightsTermRemaining]: false,
  [BooleanFeatureFlag.EnableAccountInsightsTotalInterestSavings]: false,
  [BooleanFeatureFlag.EnableAccountPermissions]: false,
  [BooleanFeatureFlag.EnableAppDirectDebitFailureAlert]: false,
  [BooleanFeatureFlag.EnableApplyLoanCardV2]: false,
  [BooleanFeatureFlag.EnableAppPaymentFailureAlert]: false,
  [BooleanFeatureFlag.EnableAuth0NewLoginScreen]: false,
  [BooleanFeatureFlag.EnableAutopayModalV2]: false,
  [BooleanFeatureFlag.EnableAppShortfallAlert]: false,
  [BooleanFeatureFlag.EnableAvailableFundsCapture]: false,
  [BooleanFeatureFlag.EnableBiocatchClientDataCapture]: false,
  [BooleanFeatureFlag.EnableCaConfirmationPrompt]: false,
  [BooleanFeatureFlag.EnableDashboardV2]: false,
  [BooleanFeatureFlag.EnableDeleteUser]: false,
  [BooleanFeatureFlag.EnableDuplicateCoBorrowerHandler]: false,
  [BooleanFeatureFlag.EnableEquifaxIncomeVerification]: false,
  [BooleanFeatureFlag.EnableEvidenceScreenUplift]: false,
  [BooleanFeatureFlag.EnableExpensesScreenAndHemGuideUplift]: false,
  [BooleanFeatureFlag.EnableHomeLoanAccountDetailsScreenV3]: false,
  [BooleanFeatureFlag.EnableLmi]: false,
  [BooleanFeatureFlag.EnableLoanDocuments]: false,
  [BooleanFeatureFlag.EnableLoanTopUp]: false,
  [BooleanFeatureFlag.EnableManualKyc]: false,
  [BooleanFeatureFlag.EnableNativeAppOnboardingHandoff]: false,
  [BooleanFeatureFlag.EnableNewFeaturesScreen]: false,
  [BooleanFeatureFlag.EnableNppDescriptionValidation]: false,
  [BooleanFeatureFlag.EnableNppExtraIncomingTxnFields]: false,
  [BooleanFeatureFlag.EnableNppLongDescription]: false,
  [BooleanFeatureFlag.EnableOpenBankingDataHolder]: false,
  [BooleanFeatureFlag.EnableOpenBankingDataRecipient]: false,
  [BooleanFeatureFlag.EnableOutOfService]: false,
  [BooleanFeatureFlag.EnablePrincipalReduction]: false,
  [BooleanFeatureFlag.EnablePrepopulateEmploymentIncome]: false,
  [BooleanFeatureFlag.EnablePwaInstallPrompt]: false,
  [BooleanFeatureFlag.EnableSettingsFeedbackCard]: false,
  [BooleanFeatureFlag.EnableShowSavingsOnYourUnloan]: false,
  [BooleanFeatureFlag.EnableStatementsModalV2]: false,
  [BooleanFeatureFlag.EnableSuburbAutocomplete]: false,
  [BooleanFeatureFlag.EnableTopUpDebtConsolidation]: false,
  [BooleanFeatureFlag.EnableTransferModalV2]: false,
  [BooleanFeatureFlag.EnableTransferFeature]: false,
  [BooleanFeatureFlag.EnableTransferReference]: false,
  [BooleanFeatureFlag.EnableTransferCents]: false,
  [BooleanFeatureFlag.EnableUpdateLegalName]: false,
  [BooleanFeatureFlag.EnableUpdateEmail]: false,
  [BooleanFeatureFlag.EnableUpdateMobileNumber]: false,
  [BooleanFeatureFlag.EnableUpdatePhysicalAddress]: false,
  [BooleanFeatureFlag.EnableUseOfNewLoanTermField]: false,
  [BooleanFeatureFlag.EnableUsePexaWorkspaceSummary]: false,
  [BooleanFeatureFlag.EnableVca]: false,
  [BooleanFeatureFlag.EnableWarningForExpectedRentalIncomeUpdate]: false,
  [BooleanFeatureFlag.EnableViewExpensesInDynamics]: false,
  [BooleanFeatureFlag.EnablePostEvidenceUpload]: false,
};

export const defaultStringFeatureFlag: Record<StringFeatureFlag, string> = {
  [StringFeatureFlag.DashboardVersion]:
    'MISSING_DASHBOARD_VERSION_FALLBACK_VALUE',
  /**
   * Aligned with default value set in
   * apps/unloan-app/src/MinimalRequiredVersion/hooks/useMinimumAppVersion.ts
   * */
  [StringFeatureFlag.MinimumAppVersion]: '0.0.0',
};

export const FALLBACK_FLAGS: Flags = {
  ...defaultBooleanFeatureFlag,
  ...defaultStringFeatureFlag,
};
