import { gql } from '@apollo/client';
import { StackScreenProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import * as React from 'react';
import { useSetupActiveAccountDevTool } from 'src/DevTools/useSetupActiveAccountDevTool';

import { useAuth, useUser } from '../../Auth/hooks';
import { useDeleteAccountMutation } from '../../generated/graphql';
import { ActionSheetStackParams } from '../../navigation/types/navTypes';
import { ActionSheetType } from '../../navigation/types/screens';
import { Alert } from '../../ui/atoms/Alert';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccount {
    delete_account {
      user_id
    }
  }
`;

type Props = StackScreenProps<
  ActionSheetStackParams,
  ActionSheetType.DEVELOPER_TOOLS
>;

export const DeveloperToolsActionSheet: React.FC<Props> = ({ navigation }) => {
  const { logout } = useAuth();

  const { user } = useUser();
  const accountId = user?.id;

  const [deleteAccount, { error, loading: deleteAccountLoading }] =
    useDeleteAccountMutation();

  const onDeleteAccount = useCallback(() => {
    Alert.alert(
      'Delete Account?',
      'This will remove all data associated to your account, including loan application & data related to it.',
      [
        {
          text: 'Delete',
          onPress: async () => {
            const [res, _] = await safelyCallMutation(deleteAccount, {
              context: {
                sentryContext: {
                  accountId,
                  description: 'Deleting account',
                },
              },
            });

            if (res) {
              logout();
            }
          },
          style: 'destructive',
        },
        {
          text: 'Cancel',
          onPress: () => {},
          style: 'cancel',
        },
      ],
    );
  }, [accountId, deleteAccount, logout]);

  const { setupCustomMockAccount } = useSetupActiveAccountDevTool();

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={deleteAccountLoading}
      title="Developer Tools"
      actionList={[
        {
          label: 'Delete Account',
          onPress: onDeleteAccount,
          isDestructive: true,
          disabled: deleteAccountLoading,
          showSpinner: deleteAccountLoading,
        },
        {
          label: 'Set Up Mock Account',
          onPress: setupCustomMockAccount,
          secondary: true,
        },
      ]}
      errorMessage={
        error ? t('Content.Common.Error.FailToDeleteAccount') : null
      }
    />
  );
};
