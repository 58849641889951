import { Text } from 'dripsy';
import { ComponentProps } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormPropertyInputV2 } from '../../components/form/FormikInputs';
import { Screen } from '../../navigation/types/screens';
import { DataRowGroup } from '../../ui/molecules/DataRow';
import { FormikFormError } from '../../ui/v2/FormError';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import { AddressInputType, AddressType } from '../types/addressTypes';
import { ManualAddressInputs } from './ManualAddressInputs';

export type AddressPickerProps = {
  screen: Screen;
  isManualInput: boolean;
  addressType: AddressType;
  placeHolder: string;
  setValueOfStateField: (value: string) => void;
  testIdAddressType: 'UpdateResidentialAddress' | 'UpdateMailingAddress';
} & Pick<
  ComponentProps<typeof DataRowGroup>,
  'footerErrorMessage' | 'onFooterTextLinkPress' | 'footerTextTestID'
>;

export const AddressPicker = ({
  screen,
  isManualInput,
  addressType,
  onFooterTextLinkPress,
  placeHolder,
  setValueOfStateField,
  testIdAddressType,
  footerTextTestID,
  footerErrorMessage,
}: AddressPickerProps) => {
  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  if (!isManualInput) {
    return (
      <>
        <FormPropertyInputV2
          clearable
          name={`${addressType}.${AddressInputType.DomainApi}`}
          label={placeHolder}
          inputTestID={
            TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
              .DomainApi
          }
          propertySuggestionData={propertySuggestionData}
          loadSuggestionData={loadSuggestionData}
          isLoadingSuggestionData={isLoadingSuggestionData}
          sx={{ mt: '$10' }}
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.PersonalDetails,
            screen,
            FieldInteractionKey.NewResidentialAddress,
          )}
        />
        {footerErrorMessage ? (
          <FormikFormError
            name={`${addressType}.${AddressInputType.DomainApi}`}
            sx={{ mt: '$8' }}
            errorMessageParser={() => footerErrorMessage}
          />
        ) : null}
        <Text variant="sBody" sx={{ my: '$8' }} testID={footerTextTestID}>
          {t('Content.PersonalDetails.UpdateAddress.CantFindAddress')}
          <Text variants={['sBody', 'link']} onPress={onFooterTextLinkPress}>
            {t('Content.PersonalDetails.UpdateAddress.EnterAddressManually')}
          </Text>
        </Text>
      </>
    );
  }

  return (
    <>
      <ManualAddressInputs
        screen={screen}
        groupName={addressType}
        setValueOfStateField={setValueOfStateField}
        testIdAddressType={testIdAddressType}
      />
      {footerErrorMessage ? (
        <FormikFormError
          name={`${addressType}.${AddressInputType.Manual}`}
          sx={{ mt: '$8' }}
          errorMessageParser={() => footerErrorMessage}
        />
      ) : null}
      <Text
        sx={{ mt: '$8' }}
        variants={['sBody', 'link']}
        onPress={onFooterTextLinkPress}
        testID={footerTextTestID}
      >
        {t('Content.PersonalDetails.UpdateAddress.UseAddressSearch')}
      </Text>
    </>
  );
};
