import { camelCase } from 'lodash';

import {
  Expense_Type_Input_Enum,
  Frequency_Input_Enum,
  Household_Expense_Input,
  Living_Situation_Input_Enum,
} from '../../generated/graphql';
import { TranslateKeys } from '../../localization/constants';
import { formatCurrency } from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { assertUnreachable } from '../../utils/typesHelpers';
import { AllExpensesTypeFormValues, ExpensesTypeFormValuesRent } from './forms';
import { ApplicantOptions, ExpenseFields } from './remoteData';
import { ExpensesTypesField } from './types';

export function isLivingSituationOwnProperty(
  livingSituation?: Living_Situation_Input_Enum,
) {
  return (
    livingSituation === Living_Situation_Input_Enum.OwnPropertyOutright ||
    livingSituation === Living_Situation_Input_Enum.OwnPropertyWithMortgage
  );
}

export function mapApplicantOptionsForExpenses(
  applicantOptionsData: ApplicantOptions,
  householdId?: string,
) {
  const applicantOptions = applicantOptionsData.map(
    ({ household_id: applicantHouseholdId, ...otherApplicantData }) => ({
      ...otherApplicantData,
      disabled: applicantHouseholdId
        ? applicantHouseholdId !== householdId
        : false,
    }),
  );
  return applicantOptions;
}

export function getExpensesGuideTextV2(options: {
  from?: number | null;
  to?: number | null;
  fieldName: Expense_Type_Input_Enum;
}) {
  const { from, to, fieldName } = options;
  const fromAmount = formatCurrency(from);
  const toAmount = formatCurrency(to);
  const guideText = t(`Content.ExpensesForm.ExpensesType.${fieldName}.guide`, {
    from: fromAmount,
    to: toAmount,
  });

  return guideText;
}

export function getExpenseGuideByFrequency(options: {
  expenseField: ExpenseFields[number];
  frequency: Frequency_Input_Enum | null;
}) {
  const { expenseField, frequency } = options;

  switch (frequency) {
    case Frequency_Input_Enum.Daily:
      return {
        from: expenseField.daily?.from,
        to: expenseField.daily?.to,
      };
    case Frequency_Input_Enum.Weekly:
      return {
        from: expenseField.weekly?.from,
        to: expenseField.weekly?.to,
      };
    case Frequency_Input_Enum.Fortnightly:
      return {
        from: expenseField.fortnightly?.from,
        to: expenseField.fortnightly?.to,
      };
    case Frequency_Input_Enum.Monthly:
      return {
        from: expenseField.monthly?.from,
        to: expenseField.monthly?.to,
      };
    case Frequency_Input_Enum.Quarterly:
      return {
        from: expenseField.quarterly?.from,
        to: expenseField.quarterly?.to,
      };
    case Frequency_Input_Enum.Annual:
      return {
        from: expenseField.annual?.from,
        to: expenseField.annual?.to,
      };
    case null:
      return null;
    default:
      return assertUnreachable(frequency);
  }
}

export function getExpenseGuideWithFrequencyText(options: {
  fieldName: ExpenseFields[number]['field_name'];
  frequency: Frequency_Input_Enum | null;
  from: number | null | undefined;
  to: number | null | undefined;
}): string | null {
  const { fieldName, frequency, from, to } = options;

  if (!frequency) return null;

  const hasEqualFromAndToAmounts =
    from !== null &&
    from !== undefined &&
    to !== null &&
    to !== undefined &&
    from === to;

  const formattedFrom = formatCurrency(from);
  const formattedTo = formatCurrency(to);
  const formattedFrequency = t(
    `Content.Common.FrequencyEnum.${frequency}` as TranslateKeys,
  );

  const formattedGuide = hasEqualFromAndToAmounts
    ? `Content.ExpensesForm.ExpensesTypeV2.${fieldName}.guideWithEqualFromAndToAmounts`
    : `Content.ExpensesForm.ExpensesTypeV2.${fieldName}.guide`;

  return t(formattedGuide as TranslateKeys, {
    from: formattedFrom,
    to: formattedTo,
    frequency: formattedFrequency,
  });
}

export function calculateMonthlyAmountV2({
  amount,
  frequency,
}: {
  amount?: number;
  frequency?: Frequency_Input_Enum | string;
}) {
  if (amount == null) {
    return 0;
  }

  const parsedFrequency = parseEnumType(Frequency_Input_Enum, frequency);
  if (!amount || parsedFrequency == null) {
    return amount;
  }

  switch (frequency) {
    case Frequency_Input_Enum.Annual:
      return Math.round(amount / 12);
    case Frequency_Input_Enum.Daily:
      return Math.round((amount * 365) / 12);
    case Frequency_Input_Enum.Weekly:
      return Math.round((amount * 52) / 12);
    case Frequency_Input_Enum.Fortnightly:
      return Math.round((amount * 26) / 12);
    case Frequency_Input_Enum.Quarterly:
      return Math.round(amount / 3);
    case Frequency_Input_Enum.Monthly:
      return amount;
    default:
      return 0;
  }
}

export const transformToFormExpensesType = (
  type: Expense_Type_Input_Enum,
): {
  expenseInputName: ExpensesTypesField | null;
  frequencyInputName: ExpensesTypesField | null;
} => {
  switch (type) {
    case Expense_Type_Input_Enum.FoodAndGroceries:
      return {
        expenseInputName: ExpensesTypesField.FoodAndGroceries,
        frequencyInputName: ExpensesTypesField.FoodAndGroceriesFrequency,
      };
    case Expense_Type_Input_Enum.HomeAndUtilities:
      return {
        expenseInputName: ExpensesTypesField.HomeAndUtilities,
        frequencyInputName: ExpensesTypesField.HomeAndUtilitiesFrequency,
      };
    case Expense_Type_Input_Enum.ClothingAndPersonalCare:
      return {
        expenseInputName: ExpensesTypesField.ClothingAndPersonalCare,
        frequencyInputName: ExpensesTypesField.ClothingAndPersonalCareFrequency,
      };
    case Expense_Type_Input_Enum.MedicalHealthFitness:
      return {
        expenseInputName: ExpensesTypesField.MedicalHealthFitness,
        frequencyInputName: ExpensesTypesField.MedicalHealthFitnessFrequency,
      };
    case Expense_Type_Input_Enum.Transport:
      return {
        expenseInputName: ExpensesTypesField.Transport,
        frequencyInputName: ExpensesTypesField.TransportFrequency,
      };
    case Expense_Type_Input_Enum.EntertainmentAndPets:
      return {
        expenseInputName: ExpensesTypesField.EntertainmentAndPets,
        frequencyInputName: ExpensesTypesField.EntertainmentAndPetsFrequency,
      };
    case Expense_Type_Input_Enum.ChildcareAndEducation:
      return {
        expenseInputName: ExpensesTypesField.ChildcareAndEducation,
        frequencyInputName: ExpensesTypesField.ChildcareAndEducationFrequency,
      };
    case Expense_Type_Input_Enum.RentOrBoard:
      return {
        expenseInputName: ExpensesTypesField.RentOrBoard,
        frequencyInputName: ExpensesTypesField.RentOrBoardFrequency,
      };
    case Expense_Type_Input_Enum.ChildAndSpouseSupport:
      return {
        expenseInputName: ExpensesTypesField.ChildAndSpouseSupport,
        frequencyInputName: ExpensesTypesField.ChildAndSpouseSupportFrequency,
      };
    case Expense_Type_Input_Enum.AdditionalExpenses:
      return {
        expenseInputName: ExpensesTypesField.AdditionalExpenses,
        frequencyInputName: ExpensesTypesField.AdditionalExpensesFrequency,
      };
    default:
      return {
        expenseInputName: null,
        frequencyInputName: null,
      };
  }
};

export function calculateFormTotalMonthlyExpenses(
  values: ExpensesTypeFormValuesRent,
  livingSituation: Living_Situation_Input_Enum,
) {
  const { rentOrBoard, ...valuesWithoutRent } = values;

  const valuesToCalculate = isLivingSituationOwnProperty(livingSituation)
    ? valuesWithoutRent
    : values;

  const totalExpenses = Object.keys(valuesToCalculate).map((key) => {
    if (!key.includes('Frequency')) {
      const inputFrequency = `${camelCase(
        key,
      )}Frequency` as unknown as ExpensesTypesField;
      const frequencyValue = values[inputFrequency] as Frequency_Input_Enum;
      const amount = Number(values[key as unknown as ExpensesTypesField]);
      return calculateMonthlyAmountV2({ amount, frequency: frequencyValue });
    }
    return 0;
  });

  return totalExpenses
    .filter((total) => total)
    .reduce((sum, expense) => sum + expense, 0);
}

export function formatToExpensesInputV2(
  values: AllExpensesTypeFormValues,
): Array<Household_Expense_Input> {
  return [
    {
      expense_type: Expense_Type_Input_Enum.ChildcareAndEducation,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.ChildcareAndEducation],
        frequency: values[ExpensesTypesField.ChildcareAndEducationFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.ChildAndSpouseSupport,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.ChildAndSpouseSupport],
        frequency: values[ExpensesTypesField.ChildAndSpouseSupportFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.ClothingAndPersonalCare,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.ClothingAndPersonalCare],
        frequency: values[ExpensesTypesField.ClothingAndPersonalCareFrequency],
      }),
    },

    {
      expense_type: Expense_Type_Input_Enum.EntertainmentAndPets,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.EntertainmentAndPets],
        frequency: values[ExpensesTypesField.EntertainmentAndPetsFrequency],
      }),
    },

    {
      expense_type: Expense_Type_Input_Enum.FoodAndGroceries,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.FoodAndGroceries],
        frequency: values[ExpensesTypesField.FoodAndGroceriesFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.MedicalHealthFitness,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.MedicalHealthFitness],
        frequency: values[ExpensesTypesField.MedicalHealthFitnessFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.AdditionalExpenses,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.AdditionalExpenses],
        frequency: values[ExpensesTypesField.AdditionalExpensesFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.RentOrBoard,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.RentOrBoard],
        frequency: values[ExpensesTypesField.RentOrBoardFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.Transport,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.Transport],
        frequency: values[ExpensesTypesField.TransportFrequency],
      }),
    },
    {
      expense_type: Expense_Type_Input_Enum.HomeAndUtilities,
      monthly_amount: calculateMonthlyAmountV2({
        amount: values[ExpensesTypesField.HomeAndUtilities],
        frequency: values[ExpensesTypesField.HomeAndUtilitiesFrequency],
      }),
    },
  ];
}

export function groupExpenseFieldsByExpenseGroup(expenseFields: ExpenseFields) {
  const groupedExpenseFields = expenseFields.reduce((acc, expenseField) => {
    const expenseFieldGroup = expenseField.group || '';

    if (!acc.has(expenseFieldGroup)) {
      acc.set(expenseFieldGroup, []);
    }

    acc.get(expenseFieldGroup)?.push(expenseField);

    return acc;
  }, new Map<string, ExpenseFields>());

  return Array.from(groupedExpenseFields.entries());
}

/**
 * We do not want to include 'Daily' frequency in the
 * list of expense frequency options for expenses screen.
 */
export function getExpensesFrequencyOptions(
  frequencyOptions: {
    label: string;
    value: string;
  }[],
) {
  return frequencyOptions.filter(
    (option) => option.value !== Frequency_Input_Enum.Daily,
  );
}
