import { FC, useCallback } from 'react';

import { AddExternalAccount } from '../../ExternalAccount/components/AddExternalAccount';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { AutopaySettingsScreenProps } from '../navigation/types';

type Props = AutopaySettingsScreenProps<Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT>;

export const AutopayAddAccount: FC<Props> = ({ navigation, route }) => {
  const handleSubmit = useCallback(() => {
    navigation.navigate(ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION, {
      cbaAccountId: route.params.cbaAccountId,
    });
  }, [navigation, route.params.cbaAccountId]);

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.AutopaySettings.AccountAdd')}
      onClose={onClose}
      scrollable
    >
      <AddExternalAccount onSubmit={handleSubmit} />
    </ModalScreenContainer>
  );
};
