import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormPropertyInputV2,
  FormRadioInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import { ManualPropertyInputFields } from '../../ui/v2/ManualPropertyInputFields';
import { SuggestionDataLoader } from '../../ui/v2/PropertyInput';
import { makeTestId } from '../../utils/stringHelpers';
import {
  DEFAULT_PROPERTY_DETAILS_REFI_INITIAL_VALUES,
  getPropertyDetailsFormSchema,
  PropertyDetailsFormFields,
  PropertyDetailsFormValue,
  propertyDetailsManualAddressPrefix,
  propertyDetailsManualInputFieldNames,
} from '../utils/propertyDetailsFormUtils';

export type PropertyDetailsFormProps = {
  screen: Screen;
  initialValues?: PropertyDetailsFormValue;
  propertyTypeOptions: PickerOptions<string>;
  propertyPurposeOptions: PickerOptions<string>;
  streetTypeOptions: PickerOptions<string>;
  stateOptions: PickerOptions<string>;
  isSubmitting?: boolean;
  onSubmit: (values: PropertyDetailsFormValue) => Promise<void>;
  showDeleteButton?: boolean;
  onDelete?: () => Promise<void>;
} & SuggestionDataLoader;

export function PropertyDetailsForm({
  screen,
  initialValues = DEFAULT_PROPERTY_DETAILS_REFI_INITIAL_VALUES,
  propertyTypeOptions = [],
  propertyPurposeOptions = [],
  stateOptions = [],
  streetTypeOptions = [],
  isSubmitting,
  onSubmit,
  showDeleteButton,
  onDelete,

  // For property address input
  loadSuggestionData,
  isLoadingSuggestionData,
  propertySuggestionData,
}: PropertyDetailsFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getPropertyDetailsFormSchema()}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <SHeaderText>
            {t('Content.PropertyDetails.Form.WhatsYourPropertyAddress')}
          </SHeaderText>
          {formProps.values?.[
            propertyDetailsManualInputFieldNames.isManualInput
          ] ? (
            <>
              <ManualPropertyInputFields
                screen={screen}
                sectionInteractionKey={SectionInteractionKey.Property}
                fieldNamePrefix={propertyDetailsManualAddressPrefix}
                containerSx={{ mt: '$16' }}
                stateOptions={stateOptions}
                streetTypeOptions={streetTypeOptions}
              />
              <Text
                variants={['caption', 'link']}
                sx={{ mt: '$8' }}
                onPress={() =>
                  formProps.setFieldValue(
                    propertyDetailsManualInputFieldNames.isManualInput,
                    false,
                    false,
                  )
                }
              >
                {t('Content.PropertyForm.FormV2.BackToSelectInput')}
              </Text>
            </>
          ) : (
            <>
              <FormPropertyInputV2
                name={PropertyDetailsFormFields.AddressResult}
                label={t('Content.PropertyDetails.Form.PropertyAddress')}
                inputTestID={TestID.PropertyDetails.PropertyAddressPicker}
                propertySuggestionData={propertySuggestionData}
                loadSuggestionData={loadSuggestionData}
                isLoadingSuggestionData={isLoadingSuggestionData}
                sx={{ mt: '$16' }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Property,
                  screen,
                  FieldInteractionKey.PropertyAddress,
                )}
              />
              <FormikFormError
                name={PropertyDetailsFormFields.AddressResult}
                sx={{ mt: '$8' }}
                errorMessageParser={() =>
                  t('Content.PropertyDetails.Form.PropertyAddressError')
                }
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                {t('Content.PropertyForm.FormV2.GoToManualInput')}
                <Text
                  variants={['caption', 'link']}
                  onPress={() =>
                    formProps.setFieldValue(
                      propertyDetailsManualInputFieldNames.isManualInput,
                      true,
                      false,
                    )
                  }
                >
                  {t('Content.PropertyForm.FormV2.GoToManualInputLink')}
                </Text>
              </Text>
            </>
          )}
          <FormSelectV2
            name={PropertyDetailsFormFields.PropertyType}
            label={t('Content.PropertyDetails.Form.PropertyType')}
            testID={TestID.PropertyDetails.PropertyType}
            items={propertyTypeOptions}
            sx={{ mt: '$24' }}
          />
          <FormikFormError
            name={PropertyDetailsFormFields.PropertyType}
            sx={{ mt: '$8' }}
          />
          <FormCurrencyInputV2
            name={PropertyDetailsFormFields.PropertyValue}
            label={t('Content.PropertyDetails.Form.EstimatedPropertyValue')}
            inputTestID={TestID.PropertyDetails.EstimatedPropertyValueInput}
            sx={{ mt: '$16' }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Property,
              screen,
              FieldInteractionKey.EstimatedPropertyValue,
            )}
          />
          <FormikFormError
            name={PropertyDetailsFormFields.PropertyValue}
            sx={{ mt: '$8' }}
          />

          <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
            {t('Content.PropertyDetails.Form.AreYouLivingInThisProperty')}
          </SHeaderText>
          {propertyPurposeOptions.map(({ label, value }, index) => (
            <FormRadioInputV2
              key={value}
              name={PropertyDetailsFormFields.PropertyPurpose}
              value={value}
              testID={makeTestId([
                TestID.PropertyDetails.PropertyPurpose,
                value,
              ])}
              label={label}
              containerStyle={{
                flex: 1,
                ...(index !== 0 ? { mt: '$8' } : undefined),
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Property,
                screen,
                FieldInteractionKey.AreYouLivingInThisProperty,
              )}
            />
          ))}
          <FormikFormError
            name={PropertyDetailsFormFields.PropertyPurpose}
            sx={{ mt: '$8' }}
          />

          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            testID={TestID.PropertyDetails.SubmitButton}
            onPress={() => {
              formProps.handleSubmit();
            }}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            py="m"
            mt="xl"
            alignSelf="stretch"
          />

          {showDeleteButton ? (
            <Button
              label={t('Content.PropertyDetails.ButtonLabel.DeleteProperty')}
              testID={TestID.PropertyDetails.DeleteButton}
              onPress={onDelete}
              tertiary
              disabled={isSubmitting}
              fontWeight="normal"
              alignSelf="stretch"
              mt="s"
              style={{
                paddingVertical: 0,
              }}
            />
          ) : null}
        </View>
      )}
    </Formik>
  );
}

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
