import { SxProp, useSx } from 'dripsy';

export type SupProps = JSX.IntrinsicElements['sup'] & {
  sx?: SxProp;
};

export function Sup({ children, sx: sxProp }: SupProps) {
  const sx = useSx();
  return <sup style={sxProp ? sx(sxProp) : undefined}>{children}</sup>;
}
