import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { differenceInDays, parseISO } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Applicant_Income_Verification_Method_Enum,
  ApplicantStatusInfoFragment,
  Fms_Deal_Documents_Delivery_Method_Enum,
  Loan_Application_Status_Info_Stage_Enum,
  Loan_Application_Type_Enum,
  Pexa_Acceptance_Status_Enum,
  useCreateDocusignEmbeddedContractMutation,
  useGetFmsEsignSignerSummaryLazyQuery,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { AppStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Alert } from '../../ui/atoms/Alert';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { openUrl } from '../../utils/openUrl';
import { joinListOfString } from '../../utils/stringHelpers';
import { assertUnreachable } from '../../utils/typesHelpers';
import {
  HomeStackNavigationProps,
  HomeStackScreenProps,
  HomeTabNavigationProps,
  HomeTabScreenProps,
} from '../navigation/types';
import {
  getApplicantStateFromWorkspace,
  getPexaWorkspaceStatus,
  getWorkspaceBookedCaption,
} from './getPexaWorkplaceStatus';

// This enum should match applicant.applicant_status_info.status.
// Currently Hasura does not include the enum in the schema.
export enum ApplicantStatusInfoEnum {
  Added = 'ADDED',
  Invited = 'INVITED',
  Joined = 'JOINED',
  ConditionalApprovalEvaluated = 'CONDITIONAL_APPROVAL_EVALUATED',
  Submitted = 'SUBMITTED',
  Evidenced = 'EVIDENCED',
  Signed = 'SIGNED',
}

// Represent the main variation of ApplicationTracking screen.
// There are more variations in a single stage depending on the applicant status.
export enum ApplicationTrackingStage {
  Apply,
  Decision,
  Accept,
  Settlement,
}

// A single field to determine which card to display in home
// and what action can the applicant do against the application.
// This is temporary until the backend consolidate
// applicant status info and loan application stage.
export enum ApplicantState {
  // Apply stage
  NotYetJoined, // e.g. added or invited
  Joined, // complete your application
  PendingConditionallyApproved, // Waiting for co-borrower to join the application. (purchase only)
  ConditionallyApproved, // complete your application once a contract of sale has been signed. (purchase only)
  Submitted, // verify your income
  PendingCoborrowerEvidenced,
  // Decision stage
  Evidenced,
  PendingPortalApproved,
  PaperContract,
  // After decision
  Declined,
  // Accept stage
  Approved,
  PendingCoborrowerSigned,
  Signed,
  // Settlement stage
  DocumentsCompleted, // coborrowers has completed all necessary docs
  Booked,
  BookedWorkspaceAccepted,
  BookedWorkspacePending,
  Settled,
  TopUpPreDisbursement,
  Disbursed,
}

const LoanApplicationStagesNeedFmsPortalUrl = [
  Loan_Application_Status_Info_Stage_Enum.Approved,
  Loan_Application_Status_Info_Stage_Enum.PendingSigned,
  Loan_Application_Status_Info_Stage_Enum.Signed,
  Loan_Application_Status_Info_Stage_Enum.DocumentsCompleted,
  Loan_Application_Status_Info_Stage_Enum.Booked,
] as const;
const LoanApplicationStagesNeedFmsPortalUrlSet = new Set(
  LoanApplicationStagesNeedFmsPortalUrl,
);
type LoanApplicationStageNeedFmsPortalUrl =
  (typeof LoanApplicationStagesNeedFmsPortalUrl)[number];

function needFmsPortalUrl(
  applicationStage: Loan_Application_Status_Info_Stage_Enum | null,
) {
  if (applicationStage == null) {
    return false;
  }

  return LoanApplicationStagesNeedFmsPortalUrlSet.has(
    applicationStage as LoanApplicationStageNeedFmsPortalUrl,
  );
}

export function getApplicantState(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
  portalUrl: string | null | undefined,
  documentsDeliveryMethod: Fms_Deal_Documents_Delivery_Method_Enum | undefined,
  usePexaWorkspaceStates = false,
): ApplicantState {
  if (!applicantStatusInfo) {
    return ApplicantState.NotYetJoined;
  }

  const myStatus = parseEnumType(
    ApplicantStatusInfoEnum,
    applicantStatusInfo.myStatusInfo?.myStatus,
    'applicantStatusInfo.myStatusInfo.myStatus',
  );

  const applicationStage = parseEnumType(
    Loan_Application_Status_Info_Stage_Enum,
    applicantStatusInfo.loanApplication.statusInfo?.loanApplicationStage,
    'applicantStatusInfo.loanApplication.statusInfo.loanApplicationStage',
  );

  const isTopUp =
    applicantStatusInfo?.loanApplication.type ===
    Loan_Application_Type_Enum.TopUp;

  if (
    isTopUp &&
    applicationStage === Loan_Application_Status_Info_Stage_Enum.Signed
  ) {
    return ApplicantState.TopUpPreDisbursement;
  }

  if (!myStatus || !applicationStage) {
    return ApplicantState.NotYetJoined;
  }

  switch (applicationStage) {
    case Loan_Application_Status_Info_Stage_Enum.Disbursed: {
      return ApplicantState.Disbursed;
    }
    case Loan_Application_Status_Info_Stage_Enum.Declined: {
      return ApplicantState.Declined;
    }
    case Loan_Application_Status_Info_Stage_Enum.Settled: {
      return ApplicantState.Settled;
    }

    case Loan_Application_Status_Info_Stage_Enum.Booked: {
      const workspaces =
        applicantStatusInfo.loanApplication.fms_deals?.[0]?.pexa_workspaces;

      if (!workspaces || !usePexaWorkspaceStates) {
        return ApplicantState.Booked;
      }
      return getApplicantStateFromWorkspace(workspaces);
    }

    case Loan_Application_Status_Info_Stage_Enum.DocumentsCompleted: {
      return ApplicantState.DocumentsCompleted;
    }

    case Loan_Application_Status_Info_Stage_Enum.Signed: {
      if (
        documentsDeliveryMethod === Fms_Deal_Documents_Delivery_Method_Enum.Post
      ) {
        return ApplicantState.PaperContract;
      }

      // If document delivery method is not Post, wait for portal url
      const isPortalReady = portalUrl != null;
      if (isPortalReady) {
        return ApplicantState.Signed;
      }
      return ApplicantState.PendingPortalApproved;
    }

    case Loan_Application_Status_Info_Stage_Enum.PendingSigned:
    case Loan_Application_Status_Info_Stage_Enum.Approved: {
      if (myStatus === ApplicantStatusInfoEnum.Signed) {
        return ApplicantState.PendingCoborrowerSigned;
      }
      // When stage is approved,
      // check if loan application was approved after Docusign feature flag was turned on
      const isApprovedUsingDocusignFlow =
        applicantStatusInfo.loanApplication.docusign_envelope?.envelope_id;

      // If loan application was signed with docusign,
      // should be able to navigate to Your Unloan Offer to open contract
      if (isApprovedUsingDocusignFlow) {
        return ApplicantState.Approved;
      }

      // If loan application was approved using old flow,
      // it should wait for portal url
      const isPortalReady = portalUrl != null;
      if (isPortalReady) {
        return ApplicantState.Approved;
      }
      return ApplicantState.PendingPortalApproved;
    }

    // We're auto progressing loan application stage to `EVIDENCED` if one of the applicant's status is `EVIDENCED`
    case Loan_Application_Status_Info_Stage_Enum.Evidenced: {
      if (
        myStatus === ApplicantStatusInfoEnum.Submitted ||
        myStatus === ApplicantStatusInfoEnum.Evidenced
      ) {
        return ApplicantState.Evidenced;
      }

      // But if user hasn't submitted, we want the user to still be able to edit the loan application
      return ApplicantState.Joined;
    }

    case Loan_Application_Status_Info_Stage_Enum.Submitted: {
      return ApplicantState.Submitted;
    }

    case Loan_Application_Status_Info_Stage_Enum.PendingSubmitted: {
      if (myStatus === ApplicantStatusInfoEnum.Submitted) {
        return ApplicantState.Submitted;
      }

      if (myStatus === ApplicantStatusInfoEnum.ConditionalApprovalEvaluated) {
        return ApplicantState.ConditionallyApproved;
      }

      return ApplicantState.Joined;
    }

    case Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved:
    case Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved:
    case Loan_Application_Status_Info_Stage_Enum.ConditionallyApproved: {
      return ApplicantState.ConditionallyApproved;
    }

    case Loan_Application_Status_Info_Stage_Enum.PendingConditionallyApproved: {
      if (myStatus === ApplicantStatusInfoEnum.ConditionalApprovalEvaluated) {
        return ApplicantState.PendingConditionallyApproved;
      }

      if (
        myStatus === ApplicantStatusInfoEnum.Added ||
        myStatus === ApplicantStatusInfoEnum.Invited
      ) {
        return ApplicantState.NotYetJoined;
      }

      return ApplicantState.Joined;
    }

    case Loan_Application_Status_Info_Stage_Enum.Started: {
      if (
        myStatus === ApplicantStatusInfoEnum.Added ||
        myStatus === ApplicantStatusInfoEnum.Invited
      ) {
        return ApplicantState.NotYetJoined;
      }
      return ApplicantState.Joined;
    }

    case Loan_Application_Status_Info_Stage_Enum.PendingEvidenced: {
      // This loan application stage is no longer used.
      return ApplicantState.NotYetJoined;
    }

    default: {
      assertUnreachable(applicationStage);
      return ApplicantState.NotYetJoined;
    }
  }
}

function getApplicationTrackingStage(
  applicantState: ApplicantState,
): ApplicationTrackingStage {
  switch (applicantState) {
    case ApplicantState.Joined:
    case ApplicantState.NotYetJoined:
    case ApplicantState.Submitted:
    case ApplicantState.PendingCoborrowerEvidenced:
    case ApplicantState.PendingConditionallyApproved:
    case ApplicantState.ConditionallyApproved:
      return ApplicationTrackingStage.Apply;
    case ApplicantState.Evidenced:
      return ApplicationTrackingStage.Decision;
    case ApplicantState.PendingPortalApproved:
    case ApplicantState.PaperContract:
    case ApplicantState.Approved:
    case ApplicantState.PendingCoborrowerSigned:
    case ApplicantState.Signed:
      return ApplicationTrackingStage.Accept;
    case ApplicantState.DocumentsCompleted:
    case ApplicantState.Booked:
    case ApplicantState.BookedWorkspaceAccepted:
    case ApplicantState.BookedWorkspacePending:
    case ApplicantState.Settled:
    case ApplicantState.TopUpPreDisbursement:
    case ApplicantState.Disbursed:
      return ApplicationTrackingStage.Settlement;
    // These state are handled separetely as we dont have tracker for these state
    // by design and only included for TS completeness.
    case ApplicantState.Declined:
      return ApplicationTrackingStage.Apply;
    default:
      assertUnreachable(applicantState);
      return ApplicationTrackingStage.Apply;
  }
}

export type ApplicantAction = {
  actionLabelShort: string;
  actionLabelLong: string;
  onPress: () => void;
};

const ActionableApplicantStates = [
  ApplicantState.Joined,
  ApplicantState.PendingConditionallyApproved,
  ApplicantState.ConditionallyApproved,
  ApplicantState.Submitted,
  ApplicantState.Approved,
  ApplicantState.PendingCoborrowerSigned,
  ApplicantState.Signed,
] as const;
const ActionableApplicantStateSet = new Set(ActionableApplicantStates);

const ShowFmsPortalApplicantStates = [
  ApplicantState.PendingCoborrowerSigned,
  ApplicantState.DocumentsCompleted,
  ApplicantState.Booked,
];

const ShowFmsPortalApplicantStateSet = new Set(ShowFmsPortalApplicantStates);

function shouldShowFmsPortal(
  applicantState: ApplicantState,
  loanApplicationId: string | null | undefined,
  portalUrl: string | null | undefined,
) {
  if (
    !ShowFmsPortalApplicantStateSet.has(
      applicantState as ActionableApplicantState,
    ) ||
    !loanApplicationId ||
    !portalUrl
  ) {
    return false;
  }

  return true;
}

type ActionableApplicantState = (typeof ActionableApplicantStates)[number];

export type ApplicantNavigation =
  | StackNavigationProp<AppStackParams, Screen.APPLICATION_TRACKING>
  | HomeTabNavigationProps<Screen.HOME>
  | HomeStackNavigationProps<Screen.HOME>;
export type ApplicantRoute =
  | RouteProp<AppStackParams, Screen.APPLICATION_TRACKING>
  | HomeTabScreenProps<Screen.HOME>['route']
  | HomeStackScreenProps<Screen.HOME>['route'];

export function openPortalUrl(
  applicantState: ApplicantState,
  loanApplicationId: string,
  portalUrl: string | null | undefined,
) {
  if (!portalUrl) {
    return captureException(
      'Unexpected to get here. When portal url is not available, application should be in decisioning and not actionable',
      {
        loanApplicationId,
        applicantState,
      },
    );
  }
  return openUrl(portalUrl);
}

function openDocusignContract(docusignContract: string | null) {
  if (!docusignContract) {
    // TODO: This needs better handling
    Alert.alert(
      `Sorry, we're unable to get you the link to view the contract right now.`,
    );
    return;
  }
  window.location.href = docusignContract;
}

function getApplicantAction({
  navigateToLoanApplicationScreen,
  applicantState,
  loanApplicationId,
  loanApplicationType,
  envelopeId,
  portalUrl,
  enableOpenBankingDataRecipient,
  createDocusignEmbeddedContractUrl,
  myIncomeVerificationMethod,
  hasSelectedIncomeVerificationMethod,
}: {
  navigateToLoanApplicationScreen: (params: {
    section: LoanApplicationSection;
  }) => void;
  applicantState: ApplicantState;
  loanApplicationId: string | null | undefined;
  loanApplicationType: Loan_Application_Type_Enum | null | undefined;
  envelopeId: string | null | undefined;
  portalUrl: string | null | undefined;
  enableOpenBankingDataRecipient: boolean | undefined;
  createDocusignEmbeddedContractUrl: () => Promise<string | null>;
  myIncomeVerificationMethod:
    | Applicant_Income_Verification_Method_Enum
    | null
    | undefined;
  hasSelectedIncomeVerificationMethod: boolean;
}): ApplicantAction | null {
  if (
    !ActionableApplicantStateSet.has(
      applicantState as ActionableApplicantState,
    ) ||
    !loanApplicationId
  ) {
    return null;
  }

  const navigateToFinaliseLoanApplication = () =>
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Submit,
    });

  const navigateToVerifyIncomeOpenBanking = () =>
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.VerifyIncomeOpenBanking,
    });

  const navigateToConditionalApprovalScreen = () =>
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.ConditionalApproval,
    });

  const actionableApplicantState = applicantState as ActionableApplicantState;
  switch (actionableApplicantState) {
    case ApplicantState.Joined: {
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        onPress: () => {
          const section =
            loanApplicationType === Loan_Application_Type_Enum.Purchase
              ? LoanApplicationSection.Borrowers
              : LoanApplicationSection.Property;

          navigateToLoanApplicationScreen({
            section,
          });
        },
      };
    }
    case ApplicantState.PendingConditionallyApproved:
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        onPress: navigateToConditionalApprovalScreen,
      };
    case ApplicantState.ConditionallyApproved: {
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        onPress: navigateToConditionalApprovalScreen,
      };
    }
    case ApplicantState.Submitted: {
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        onPress: () => {
          if (!enableOpenBankingDataRecipient) {
            return navigateToFinaliseLoanApplication();
          }
          if (
            !hasSelectedIncomeVerificationMethod ||
            myIncomeVerificationMethod == null
          ) {
            return navigateToVerifyIncomeOpenBanking();
          }

          switch (myIncomeVerificationMethod) {
            case Applicant_Income_Verification_Method_Enum.OpenBanking:
            case Applicant_Income_Verification_Method_Enum.ManualUpload:
              return navigateToFinaliseLoanApplication();
            default:
              assertUnreachable(myIncomeVerificationMethod);
              return navigateToFinaliseLoanApplication();
          }
        },
      };
    }
    case ApplicantState.Approved: {
      return {
        actionLabelLong: t('Content.ApplicationTracking.SignContract'),
        actionLabelShort: t('Content.Common.ButtonLabel.Sign'),
        onPress: () =>
          navigateToLoanApplicationScreen({
            section: LoanApplicationSection.YourUnloanOffer,
          }),
      };
    }
    case ApplicantState.PendingCoborrowerSigned: {
      const onPressPortal = () => {
        openPortalUrl(applicantState, loanApplicationId, portalUrl);
      };
      const onPressDocusign = async () => {
        const docusignContractLink = await createDocusignEmbeddedContractUrl();
        openDocusignContract(docusignContractLink);
      };
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        // If loan application was approved using docusign flow
        // it should open docusign screen, else open fms portal.
        onPress: envelopeId ? onPressDocusign : onPressPortal,
      };
    }
    case ApplicantState.Signed: {
      return {
        actionLabelLong: t('Content.Common.ButtonLabel.Continue'),
        actionLabelShort: t('Content.Common.ButtonLabel.Continue'),
        onPress: () =>
          openPortalUrl(applicantState, loanApplicationId, portalUrl),
      };
    }
    default:
      assertUnreachable(actionableApplicantState);
      return null;
  }
}

function formatSettlementBookedAtDate(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
): string {
  return (
    safelyFormatDate(
      applicantStatusInfo?.loanApplication?.statusInfo?.settlementBookedAt,
      'd',
    ) || '--'
  );
}

function formatSettlementBookedAtMonth(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
): string {
  return (
    safelyFormatDate(
      applicantStatusInfo?.loanApplication?.statusInfo?.settlementBookedAt,
      'MMMM',
    ) || '--'
  );
}

export function getDifferenceInDays(
  settlementBookedAt: string | null | undefined,
) {
  const today = new Date();
  const settlementDate =
    typeof settlementBookedAt === 'string'
      ? parseISO(settlementBookedAt)
      : settlementBookedAt;
  if (settlementDate == null) {
    return null;
  }
  return differenceInDays(settlementDate, today);
}

export function getBookedCaption(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
) {
  const settlementBookedAt =
    applicantStatusInfo?.loanApplication.statusInfo?.settlementBookedAt;
  const daysDifference = getDifferenceInDays(settlementBookedAt);
  if (daysDifference == null) {
    return null;
  }
  // If more than a week, show the date
  if (daysDifference > 7) {
    return t('Content.Home.BookedCaption', {
      date: formatSettlementBookedAtDate(applicantStatusInfo),
      month: formatSettlementBookedAtMonth(applicantStatusInfo),
    });
  }
  // If less than a week, show countdown
  if (daysDifference > 0 && daysDifference <= 7) {
    return t('Content.Home.BookedCaptionLessThanAWeek', {
      difference: daysDifference,
    });
  }
  // If day is today, show "today is settlement day"
  if (daysDifference === 0) {
    return t('Content.Home.BookedCaptionToday');
  }
  // If day has passed, don't show anything.
  // This is considered an error state.
  return null;
}
export function getCaption({
  applicantState,
  applicantStatusInfo,
  pexaWorkspaceStatus,
  hasAddedLoanSecurity,
}: {
  applicantState: ApplicantState;
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined;
  pexaWorkspaceStatus: Pexa_Acceptance_Status_Enum | null;
  hasAddedLoanSecurity: boolean;
}): string | null {
  const coborrowerNames = joinListOfString(
    applicantStatusInfo?.loanApplication.applicants
      .filter((applicant) => !applicant.isCurrent)
      .map((applicant) => applicant.latestFirstName)
      .filter(isNotNullOrUndefined) || [],
  );

  switch (applicantState) {
    case ApplicantState.NotYetJoined:
      return null;
    // Apply stage
    case ApplicantState.Joined:
      return t('Content.Home.JoinedCaption');
    case ApplicantState.PendingConditionallyApproved:
      return t('Content.Home.PendingConditionallyApprovedCaption');
    case ApplicantState.ConditionallyApproved:
      if (hasAddedLoanSecurity) {
        return t('Content.Home.ConditionallyApprovedAndPropertyAddedCaption');
      }

      return t('Content.Home.ConditionallyApprovedCaption');
    case ApplicantState.Submitted:
      return t('Content.Home.SubmittedCaption');
    case ApplicantState.PendingCoborrowerEvidenced:
      return t('Content.Home.PendingCoborrowerEvidencedCaption', {
        name: coborrowerNames,
      });
    // Decision stage
    case ApplicantState.Evidenced:
      return t('Content.Home.DecisionCaption');
    case ApplicantState.PendingPortalApproved:
      return t('Content.Home.PendingPortalCaption');
    case ApplicantState.PaperContract:
      return t('Content.Home.PaperContractCaption');
    // Accept stage
    case ApplicantState.Approved:
      return t('Content.Home.ContractReadyCaption');
    case ApplicantState.PendingCoborrowerSigned:
      return t('Content.Home.PendingCoborrowerSignedCaption', {
        name: coborrowerNames,
      });
    case ApplicantState.Signed:
      return t('Content.Home.SignedCaption');
    case ApplicantState.DocumentsCompleted:
      return t('Content.Home.DocumentsCompletedCaption');
    case ApplicantState.Booked:
      return getBookedCaption(applicantStatusInfo);
    case ApplicantState.BookedWorkspacePending:
      return getWorkspaceBookedCaption(
        applicantStatusInfo,
        pexaWorkspaceStatus,
      );
    case ApplicantState.BookedWorkspaceAccepted:
      return getWorkspaceBookedCaption(
        applicantStatusInfo,
        pexaWorkspaceStatus,
      );
    case ApplicantState.Settled:
      return t('Content.Home.SettledCaption');
    case ApplicantState.TopUpPreDisbursement:
      return t('Content.Home.TopUpPreDisbursementCaption');
    case ApplicantState.Disbursed:
      return null;
    // After decision
    case ApplicantState.Declined:
      return null;
    default:
      assertUnreachable(applicantState);
      return null;
  }
}

function getShortAddressFormat(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
) {
  return applicantStatusInfo?.loanApplication.loanApplicationSecurities[0]
    ?.property.address.shortAddressFormat;
}

function getMyIncomeVerificationMethod(
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
) {
  return applicantStatusInfo?.myIncomeVerificationMethod;
}

// https://github.com/unloan/docs/wiki/Design:-State-Management
export function useApplicantStates(
  navigation: ApplicantNavigation,
  route: ApplicantRoute,
  applicantStatusInfo: ApplicantStatusInfoFragment | undefined,
) {
  const loanApplicationId = applicantStatusInfo?.loanApplication.id;
  const loanApplicationType = applicantStatusInfo?.loanApplication.type;
  const envelopeId =
    applicantStatusInfo?.loanApplication.docusign_envelope?.envelope_id;
  const documentsDeliveryMethod =
    applicantStatusInfo?.loanApplication.fms_deals[0]
      ?.documents_delivery_method;
  const { flags } = useContext(FeatureFlagsContext);
  const pexaFeatureFlag = flags.ENABLE_USE_PEXA_WORKSPACE_SUMMARY;
  const enableOpenBankingDataRecipient =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT;

  const [
    getFmsSignerSummary,
    { loading: fmsSignerSummaryLoading, data: fmsSignerSummary },
  ] = useGetFmsEsignSignerSummaryLazyQuery({
    // To make the unit test work with mocked provider,
    // the variables needs to be specified here
    // instead of the lazy query function
    variables: { loanApplicationId: loanApplicationId || '' },
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [createDocusignEmbeddedContract] =
    useCreateDocusignEmbeddedContractMutation();

  const createDocusignEmbeddedContractUrl = useCallback(async () => {
    const [result] = await safelyCallMutation(createDocusignEmbeddedContract, {
      variables: {
        loan_application_id: loanApplicationId || '',
      },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

    return (
      result?.data?.create_docusign_embedded_contract.docusign_contract_link ??
      null
    );
  }, [createDocusignEmbeddedContract, loanApplicationId]);

  const applicationStage = parseEnumType(
    Loan_Application_Status_Info_Stage_Enum,
    applicantStatusInfo?.loanApplication.statusInfo?.loanApplicationStage,
    'applicantStatusInfo.loanApplication.statusInfo.loanApplicationStage',
  );
  const waitForFmsPortal = needFmsPortalUrl(applicationStage);

  const applicantStateLoading =
    // in loading state for several reasons:
    // 1. applicantStatusInfo query has not loaded
    applicantStatusInfo == null ||
    // 2. or applicantStatusInfo has loaded but,
    // application stage point to a stage that needs FMS portal URL,
    // now we need to wait for the portal URL that will be lazy loaded.
    (waitForFmsPortal && fmsSignerSummaryLoading);

  // This effect fires the lazy query to fetch FMS portal URL
  // if the loan application stages indicate that we need
  // the portal URL.
  useEffect(() => {
    if (applicationStage != null && waitForFmsPortal) {
      const fetchGetContractAndPortal = async () => {
        try {
          await getFmsSignerSummary();
        } catch (error: unknown) {
          captureException(
            'query GetFmsEsignSignerSummary failed',
            { loanApplicationId },
            error,
          );
        }
      };

      fetchGetContractAndPortal();
    }
  }, [
    applicationStage,
    createDocusignEmbeddedContract,
    getFmsSignerSummary,
    loanApplicationId,
    waitForFmsPortal,
  ]);

  const portalUrl = fmsSignerSummary?.fms_esign_signer_summary?.portal_url;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  return {
    applicantStateLoading,
    ...useMemo(() => {
      const applicantState = getApplicantState(
        applicantStatusInfo,
        portalUrl,
        documentsDeliveryMethod,
        pexaFeatureFlag,
      );

      const applicationTrackingStage =
        getApplicationTrackingStage(applicantState);
      const myIncomeVerificationMethod =
        getMyIncomeVerificationMethod(applicantStatusInfo);
      const hasSelectedIncomeVerificationMethod =
        applicantStatusInfo?.incomeVerificationMethodSelectedAt != null;
      const hasAddedLoanSecurity =
        (applicantStatusInfo?.loanApplication.loanApplicationSecurities
          ?.length ?? 0) > 0;

      const action = getApplicantAction({
        navigateToLoanApplicationScreen,
        applicantState,
        loanApplicationId,
        envelopeId,
        portalUrl,
        enableOpenBankingDataRecipient,
        createDocusignEmbeddedContractUrl,
        myIncomeVerificationMethod,
        hasSelectedIncomeVerificationMethod,
        loanApplicationType,
      });

      const shortAddressFormat = getShortAddressFormat(applicantStatusInfo);
      const pexaWorkspaces =
        applicantStatusInfo?.loanApplication?.fms_deals?.[0]?.pexa_workspaces;
      const pexaWorkspaceStatus = pexaWorkspaces
        ? getPexaWorkspaceStatus(pexaWorkspaces)
        : null;
      const caption = getCaption({
        applicantState,
        applicantStatusInfo,
        pexaWorkspaceStatus,
        hasAddedLoanSecurity,
      });
      const showFmsPortal = shouldShowFmsPortal(
        applicantState,
        loanApplicationId,
        portalUrl,
      );
      return {
        action,
        applicantState,
        applicationTrackingStage,
        caption,
        shortAddressFormat,
        loanApplicationId,
        showFmsPortal,
        portalUrl,
      };
    }, [
      applicantStatusInfo,
      portalUrl,
      documentsDeliveryMethod,
      loanApplicationId,
      loanApplicationType,
      envelopeId,
      enableOpenBankingDataRecipient,
      createDocusignEmbeddedContractUrl,
      navigateToLoanApplicationScreen,
      pexaFeatureFlag,
    ]),
  };
}
