import { format } from 'date-fns-tz';
import * as React from 'react';
import { useRecoilValue } from 'recoil';

import { TestID } from '../../testID/constants';
import { underMaintenanceAtom } from '../Home/atoms/underMaintenanceAtom';
import { useEnvConfig } from '../utils/hooks/useEnvConfig';
import { ErrorRow } from './ErrorRow';

type Props = Omit<React.ComponentProps<typeof ErrorRow>, 'message'>;

const ScheduledMaintenance = (props: Props) => {
  const { config } = useEnvConfig();

  const maintenanceEndTime = new Date(config.maintenanceEndTime);

  const date = format(maintenanceEndTime, "EEEE dd MMMM p 'AEDT'", {
    timeZone: 'Australia/Sydney',
  });

  const isAppUnderMaintenance = useRecoilValue(underMaintenanceAtom);

  if (!isAppUnderMaintenance) return null;

  return (
    <ErrorRow
      message={t('Content.Home.ScheduledMaintenance', {
        date,
      })}
      testID={TestID.Dashboard.ScheduledMaintenance}
      {...props}
    />
  );
};

export default ScheduledMaintenance;
