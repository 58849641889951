import { A, Text } from 'dripsy';
import { ComponentProps } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { formatCurrency } from '../../utils/currencyHelpers';

type Props = Pick<ComponentProps<typeof ErrorRow>, 'sx'> & {
  daysInArrears: number;
  loanAccountName: string;
  navigateOnPress: () => void;
  totalOverdueAmount: number; // sum of all the overdue amounts
};

const TEXT_PREFIX = 'Content.PaymentFailureNotification.AmortizationFailure';

export const AmortizationFailureNotification = ({
  daysInArrears,
  loanAccountName,
  navigateOnPress,
  sx = {},
  totalOverdueAmount,
}: Props) => {
  const isInArrears = daysInArrears > 0;
  const message = isInArrears
    ? t(`${TEXT_PREFIX}.AccountInArrears`, {
        loanAccountName,
        overdueAmount: formatCurrency(totalOverdueAmount),
      })
    : t(`${TEXT_PREFIX}.WithinTolerancePeriod`, {
        loanAccountName,
        overdueAmount: formatCurrency(totalOverdueAmount),
      });

  return (
    <ErrorRow
      sx={{ mb: '$8', ...sx }}
      testID={TestID.PaymentFailure.AmortizationFailureNotification}
    >
      <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
        {message}{' '}
        <A
          variants={['caption', 'link']}
          onPress={navigateOnPress}
          testID={TestID.PaymentFailure.SeeMoreText}
          sx={{ textDecorationLine: 'underline' }}
        >
          {t('Content.PaymentFailureNotification.SeeMore')}
        </A>
      </Text>
    </ErrorRow>
  );
};
