import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { isAndroidWeb, isIosWeb } from '../../utils/platformUtils';
import { redirectedFromNativeAppAtom } from './atoms/redirectedFromNativeAppAtom';

export function useEnableNativeAppMonitor(redirectedFromNativeApp = false) {
  const setRedirectedFromNativeApp = useSetRecoilState(
    redirectedFromNativeAppAtom,
  );

  useEffect(() => {
    if (!isIosWeb() && !isAndroidWeb()) {
      return;
    }

    setRedirectedFromNativeApp(redirectedFromNativeApp);
  }, [redirectedFromNativeApp, setRedirectedFromNativeApp]);
}
