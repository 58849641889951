import { useRoute } from '@react-navigation/native';
import { H2, Pressable, Text, View } from 'dripsy';
import { Fragment, useCallback, useContext, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  useGetLoanAccountPropertyAddressQuery,
  useGetLoanDetailsScreenDataQuery,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { formatNextAutopayDateForLoanDetails } from '../../Home/utils/formatNextAutopayDate';
import {
  ApplicantRoute,
  useApplicantStates,
} from '../../Home/utils/useApplicantStates';
import { useDashboardQuery } from '../../Home/utils/useDashboardQuery';
import { useHomeNavigation } from '../../Home/utils/useHomeNavigation';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem, LoadingListItem } from '../../ui/v2/List';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import { getRepaymentLabelWithInterestMonths } from '../../utils/repaymentHelpers';
import { formatLoanTerm } from '../../utils/stringHelpers';
import { AccountPermissionsSection } from '../components/AccountPermissionsSection';
import { LoanTopUpSection } from '../components/LoanTopUpSection';
import { LoanVariationSection } from '../components/LoanVariationSection';
import { useLoanAccountState } from '../graphql/loanAccountQueries';
import { formatAutopaySettingsLabel } from '../utils/formatAutopaySettingsLabel';

type Props = HomeTabScreenProps<Screen.HOME_LOAN_SETTINGS>;

function BaseLoanDetails({ navigation, route }: Props) {
  const { cbaAccountId } = route.params;
  const { flags } = useContext(FeatureFlagsContext);
  const homeNavigation = useHomeNavigation();
  const applicantRoute = useRoute<ApplicantRoute>();
  const { loading: loadingLoan, data: loan } =
    useLoanAccountState(cbaAccountId);
  const { appliedLoansForDisplay } = useDashboardQuery();
  const onGoingTopUpApplicationId = loan?.on_going_top_up_application_id;
  const onGoingTopUpApplication = appliedLoansForDisplay.find(
    (appliedLoan) =>
      appliedLoan.loanApplication.id === onGoingTopUpApplicationId,
  );
  const isLmiApplicable = loan?.is_lmi_applicable;

  const { action } = useApplicantStates(
    homeNavigation,
    applicantRoute,
    onGoingTopUpApplication,
  );
  const onContinueTopUpApplication = onGoingTopUpApplicationId
    ? action?.onPress ||
      (() => {
        captureException('Failed to continue on-going top up application', {
          onGoingTopUpApplicationId,
        });
      })
    : undefined;
  const isTopUpNotAllowed = useMemo(
    () =>
      loan?.account_owners?.some(
        (owner) => owner.personal_status === 'DECEASED',
      ) ||
      loan?.in_arrears ||
      false,
    [loan?.account_owners, loan?.in_arrears],
  );

  const { data: screenData, loading: screenDataLoading } =
    useGetLoanDetailsScreenDataQuery({
      context: {
        sentryContext: {
          description: 'Get Loan Details Screen Data',
        },
      },
      variables: {
        cbaAccountId,
      },
    });

  const isAccountPermissionsEnabled =
    !!flags[FeatureFlag.EnableAccountPermissions];

  const isPrincipalReductionEnabled = flags.ENABLE_PRINCIPAL_REDUCTION;

  const nextInstallmentAmount = screenData?.next_installment_amount;

  const isLoanDocumentsEnabled = flags.ENABLE_LOAN_DOCUMENTS;

  const isStatementV2Enabled = flags.ENABLE_STATEMENTS_MODAL_V2;

  const isLoanTopUpEnabled = flags.ENABLE_LOAN_TOP_UP;

  // At the moment we don't support top up for loans with LMI
  const showTopUpSection = isLoanTopUpEnabled && !isLmiApplicable;

  const { loading: propertyAddressLoading, data: propertyAddress } =
    useGetLoanAccountPropertyAddressQuery({
      variables: { cba_account_id: cbaAccountId },
      context: { sentryContext: { cbaAccountId } },
    });
  const propertyData =
    propertyAddress?.loan_account?.[0]?.loan_application_target
      ?.loan_application_securities?.[0];

  const onStatementsPress = useCallback(() => {
    if (isStatementV2Enabled) {
      navigation.navigate(Screen.HOME_LOAN_STATEMENTS, { cbaAccountId });
    } else {
      navigation.navigate(Screen.STATEMENTS_MODAL, {
        screen: Screen.STATEMENTS_LIST_MODAL,
        params: {
          cbaAccountId: route.params.cbaAccountId,
        },
      });
    }
  }, [
    cbaAccountId,
    isStatementV2Enabled,
    navigation,
    route.params.cbaAccountId,
  ]);

  const onAutopaySetup = useCallback(() => {
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_SETUP,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const navigateToAutopayModal = useCallback(() => {
    navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
      screen: Screen.AUTOPAY_EXISTING_SETTINGS,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const accountPermissionsButtonOnPress = useCallback(
    (hasPendingRequest: boolean) => {
      // Change this route
      if (hasPendingRequest) {
        navigation.navigate(Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN, {
          cbaAccountId,
        });
      } else {
        navigation.navigate(Screen.ACCOUNT_PERMISSIONS, {
          cbaAccountId,
        });
      }
    },
    [cbaAccountId, navigation],
  );

  const navigateToAccountAddressBook = useCallback(() => {
    navigation.navigate(Screen.ACCOUNT_ADDRESS_BOOK, { cbaAccountId });
  }, [cbaAccountId, navigation]);

  const loading =
    loan == null || loadingLoan || propertyAddressLoading || screenDataLoading;

  const propertyShortAddress =
    propertyData?.property?.address?.short_address_format;

  const loanPurpose = loan?.settings?.loan_purpose;

  const accountName = propertyShortAddress || loan?.settings?.name;

  const isJointAccount = (loan?.settings?.account_owners?.length ?? 0) > 1;

  const {
    autopay: autopaySettings,
    bsb = '',
    account_number: accountNumber = '',
  } = loan?.settings ?? {};

  const bsbJoinedWithAccountNumber = formatBsbAndAccountNumber(
    bsb,
    accountNumber,
  );

  const onCopyPress = () => {
    navigation.navigate(ActionSheetType.COPY_BSB_ACCOUNT_NUMBER, {
      bsb,
      accountNumber,
    });
  };

  const navigateToReduceYourRepayments = useCallback(() => {
    navigation.navigate(Screen.LOAN_VARIATION_REDUCE_REPAYMENTS, {
      cbaAccountId,
    });
  }, [navigation, cbaAccountId]);

  const navigateToLoanVariationApprovalPage = useCallback(() => {
    navigation.navigate(
      Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN,
      {
        cbaAccountId,
      },
    );
  }, [navigation, cbaAccountId]);

  return (
    <>
      <H2 variant="sHeader" sx={{ my: 0 }}>
        {t('Content.LoanDetail.LoanSummary')}
      </H2>

      <DataRowGroup>
        {!!loanPurpose && (
          <>
            <DataRow
              loading={loading}
              label="Product"
              caption={t(`Content.LoanDetail.LoanPurposeEnum.${loanPurpose}`)}
              subCaption={getRepaymentLabelWithInterestMonths(
                loan?.settings?.repayment_type,
                loan?.settings?.interest_only_period_months,
              )}
              testID={TestID.LoanDetails.Product}
            />
            <DataRowSeparator />
          </>
        )}

        <DataRow
          loading={loading}
          label="Rate"
          caption={t('Content.LoanDetail.InterestRate', {
            rate: loan?.settings?.interest_rate,
          })}
          subCaption={t('Content.LoanDetail.InterestRateDiscount')}
          testID={TestID.LoanDetails.InterestRate}
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label="Loan Amount"
          caption={formatCurrency(loan?.balances?.balance || 0)}
          testID={TestID.LoanDetails.AccountBalance}
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label="Term Remaining"
          caption={formatLoanTerm(
            loan?.settings?.remaining_term_in_months,
            true,
          )}
          testID={TestID.LoanDetails.LoanTerm}
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label={t('Content.LoanDetail.MinimumRepayment')}
          caption={formatCurrency(loan?.next_installment?.amount ?? 0)}
          subCaption={t('Content.LoanDetail.MinimumRepaymentDate', {
            date: formatNextAutopayDateForLoanDetails(
              loan?.next_installment?.next_installment_date ?? '',
            ),
          })}
        />
      </DataRowGroup>

      {isPrincipalReductionEnabled || showTopUpSection ? (
        <H2
          variant="sHeader"
          sx={{ mt: '$32', mb: '$16' }}
          testID={TestID.LoanDetails.ReduceYourRepaymentsTitle}
        >
          {t('Content.LoanDetail.LoanVariation')}
        </H2>
      ) : null}

      {isPrincipalReductionEnabled ? (
        <LoanVariationSection
          loading={loading}
          cbaAccountId={cbaAccountId}
          redrawAmount={loan?.balances?.available_redraw_balance ?? 0}
          nextRepaymentAmount={nextInstallmentAmount ?? 0}
          onPress={navigateToReduceYourRepayments}
          navigateToLoanVariationApprovalPage={
            navigateToLoanVariationApprovalPage
          }
        />
      ) : null}

      {isPrincipalReductionEnabled && showTopUpSection ? (
        <Separator spacer py="xs" />
      ) : null}

      {showTopUpSection ? (
        <LoanTopUpSection
          cbaAccountId={cbaAccountId}
          loading={loading}
          notAllowed={isTopUpNotAllowed}
          onContinue={onContinueTopUpApplication}
        />
      ) : null}

      <H2 variant="sHeader" sx={{ mt: '$32', mb: 0 }}>
        {t('Content.LoanDetail.AccountOwners')}
      </H2>

      <DataRowGroup testID={TestID.LoanDetails.AccountOwnersList}>
        {loading ? <DataRow loading={loading} label="" /> : null}
        {loan?.settings?.account_owners?.map((owner, idx) => (
          <Fragment key={owner}>
            {idx > 0 && <DataRowSeparator />}
            <DataRow loading={loading} label={owner || ''} />
          </Fragment>
        ))}
      </DataRowGroup>

      <H2 variant="sHeader" sx={{ mt: '$32', mb: '$16' }}>
        {t('Content.LoanDetail.AccountDetails')}
      </H2>

      <CardList>
        {loading ? (
          <LoadingListItem />
        ) : (
          <ListItem
            sx={{ py: '$16', mx: '$16' }}
            title={
              <Text variant="body" sx={{ fontWeight: '600' }}>
                {t('Content.LoanDetail.Nickname')}
              </Text>
            }
            line={<Text variant="sSubHeading">{accountName}</Text>}
            testID={TestID.LoanDetails.AccountName}
          />
        )}
        <Divider />

        {loading ? (
          <LoadingListItem />
        ) : (
          <ListItem
            sx={{ py: '$16', mx: '$16' }}
            title={
              <Text variant="body" sx={{ fontWeight: '600' }}>
                {t('Content.LoanDetail.Account')}
              </Text>
            }
            line={
              <Pressable
                onPress={onCopyPress}
                testID={TestID.LoanDetails.AccountNumberWithBsb}
              >
                <View
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text variant="sBody">{bsbJoinedWithAccountNumber} </Text>
                  <StyledIcon family="svg" name="copy" size="s" />
                </View>
              </Pressable>
            }
          />
        )}
      </CardList>

      <H2 variant="sHeader" sx={{ mt: '$32', mb: 0 }}>
        {t('Content.LoanDetail.PaymentSettings')}
      </H2>

      <DataRowGroup>
        {!autopaySettings && (
          <DataRow
            loading={loading}
            label={t('Content.LoanDetail.Autopay')}
            caption={t('Content.LoanDetail.AutopayCaption')}
            onRightButtonPress={onAutopaySetup}
            rightButtonLabel={t('Content.LoanDetail.AutopayButton')}
            rightButtonSecondary
            rightButtonTestID={TestID.LoanDetails.SetupAutopay}
          />
        )}
        {autopaySettings ? (
          <DataRow
            loading={loading}
            label={t('Content.LoanDetail.Autopay')}
            caption={formatAutopaySettingsLabel({
              amount: autopaySettings.repayment_amount,
              frequency: autopaySettings.repayment_frequency,
            })}
            subCaption={t('Content.LoanDetail.AutopaySubCaption', {
              date: formatNextAutopayDateForLoanDetails(
                loan?.next_repayment?.next_repayment_day ?? '',
              ),
            })}
            testID={TestID.LoanDetails.ExistingAutopay}
            onPress={navigateToAutopayModal}
          />
        ) : null}
      </DataRowGroup>

      {flags.ENABLE_ACCOUNT_ADDRESS_BOOK ? (
        <DataRowGroup mt="s">
          <DataRow
            loading={loading}
            label={t('Content.AccountAddressBookEntry.Title')}
            caption={t('Content.AccountAddressBookEntry.SubTitle')}
            testID={TestID.LoanDetails.AccountAddressBook}
            onPress={navigateToAccountAddressBook}
          />
        </DataRowGroup>
      ) : null}

      {!!isAccountPermissionsEnabled && isJointAccount ? (
        <AccountPermissionsSection
          mt="s"
          cbaAccountId={cbaAccountId}
          loading={loading}
          onPress={accountPermissionsButtonOnPress}
        />
      ) : undefined}

      {isLoanDocumentsEnabled ? (
        <>
          <H2 variant="sHeader" sx={{ mt: '$32', mb: 0 }}>
            {t('Content.LoanDetail.LoanDocuments')}
          </H2>

          <DataRowGroup>
            <DataRow
              loading={loading}
              label={t('Content.LoanDetail.Statements')}
              iconName="statements"
              iconFamilyName="svg"
              iconColor="accent"
              onPress={onStatementsPress}
              testID={TestID.LoanDetails.Statements}
              leftAlignItems="center"
            />
          </DataRowGroup>
        </>
      ) : null}
    </>
  );
}

export function LoanDetails({ navigation, route }: Props) {
  const { cbaAccountId } = route.params;

  const onPressBack = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN, {
      cbaAccountId,
    });
  }, [cbaAccountId, navigation]);

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader title={t('Content.LoanDetail.Title')} />
      <BaseLoanDetails navigation={navigation} route={route} />
    </UpliftScreenContainer>
  );
}
