import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
} from '@react-navigation/native';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  AppSummaryScreen,
  PreviousAppSummaryScreenState,
} from '../../recoil/PreviousAppSummaryScreenState';

/** This hook should only be used in ApplicationSummary screen */
export function useNavigateFromApplicationSummary<
  T extends NavigationProp<ParamListBase>,
>(navigation: T) {
  const setPreviousAppSummaryScreenState = useSetRecoilState(
    PreviousAppSummaryScreenState,
  );

  useFocusEffect(() => {
    setPreviousAppSummaryScreenState(null);
  });

  const navigateFromAppSummary = useCallback(
    <RouteName extends keyof ReactNavigation.RootParamList>(
      destination: RouteName,
      params: ReactNavigation.RootParamList[RouteName],
    ) => {
      setPreviousAppSummaryScreenState(AppSummaryScreen.ReviewSummary);
      navigation.navigate(destination as string, params);
    },
    [navigation, setPreviousAppSummaryScreenState],
  );

  return { navigateFromAppSummary };
}
