export enum FastlinkCallbackStatus {
  /* The provider is successfully added by the consumer. */
  SUCCESS = 'SUCCESS',
  /*	The provider addition process has failed. */
  FAILED = 'FAILED',
  /* The consumer has terminated the account addition process before it completes. */
  MIGRATION_IN_PROGRESS = 'MIGRATION_IN_PROGRESS',
  /**
   * Suppose the consumer is migrating accounts from screen-scraping user logins(i.e., the login which correspond to the already added accounts) to the new Open Banking(OB) user login.
   * And the migration process takes more time than expected; the application ends the flow and sends this status.
   */
  ACTION_ABANDONED = 'ACTION_ABANDONED',
}

// Fastlink Flow docs: https://developer.yodlee.com/docs/fastlink/4.0/advanced#DeeplinkingAttributes
export enum FastlinkFlow {
  balanceRefresh = 'balanceRefresh',
  refresh = 'refresh',
  editRealEstate = 'editRealEstate',
  editManual = 'editManual',
  edit = 'edit',
  addRealEstate = 'addRealEstate',
  addManual = 'addManual',
  add = 'add',
  manageConsent = 'manageConsent',
}

export type OnSuccessData = {
  fnToCall: string;
  providerName: string;
  requestId: string;
  providerAccountId: number;
  status: FastlinkCallbackStatus.SUCCESS;
  providerId: number;
  additionalStatus: string;
};

export type OnErrorData = {
  fnToCall: string;
  providerName: string;
  requestId: string;
  providerAccountId: number;
  status: FastlinkCallbackStatus.FAILED;
  providerId: number;
  additionalStatus: string;
  reason: string;
};

export type OnCloseData = {
  action: 'exit';
  fnToCall: 'accountStatus';
  sites: Array<unknown>;
  status: 'USER_CLOSE_ACTION';
};

export interface FastlinkWeb {
  close?: () => void;
  open?: (
    p: {
      fastLinkURL: string;
      accessToken: string;
      params: {
        // Use the appropriate configuration name from the My Yodlee | Config Tool
        configName: string;
        obAppName: string;
        flow?: FastlinkFlow;
        providerId?: number;
      };

      onSuccess?: (data: OnSuccessData) => void;
      onClose?: (data: OnCloseData) => void;
      onError?: (data: OnErrorData) => void;
      onEvent?: (data: unknown) => void;
      // Pass true or false to display the FastLink application inline even on mobile platforms. The parameter is optional to pass, and by default, the parameter value is false.
      forceIframe: boolean;
    },
    containerElementId: string,
  ) => void;
}
