import {
  Auth0Provider,
  AuthorizationParams,
  useAuth0,
} from '@auth0/auth0-react';
import { ReactNode, useCallback, useMemo } from 'react';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useBiocatch } from '../biocatch/BiocatchProvider';
import { captureException, setSentryUserId } from '../sentry';
import { useEnvConfig } from '../utils/hooks/useEnvConfig';
import { getLogger } from '../utils/loggerHelper';
import { normalizeError } from '../utils/normalizeError';
import { AuthContext } from './context';
import { getInviteCode } from './inviteCodeStore';
import { useSessionExpiration } from './useSessionExpiration';
import { useHandleAuthError } from './utils';

/** *
 * As the Auth0Provider from react-native-auth0 is not properly typed
 * and by default, the component types returned are not from the .web component
 * we would want the wrapper props for both versions to be consistent
 */
export type Props = {
  children: ReactNode;
};

const logger = getLogger('AUTH');

export const BaseAuthProvider: React.FC<Props> = ({ children }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
  } = useAuth0();

  const biocatch = useBiocatch();

  const lockSession = useCallback(() => {
    biocatch.handleSessionTimeoutWeb();
    window.location?.replace('/auth/session-locked');
  }, [biocatch]);

  const { sessionExpiryTime, handleSessionExpiryUpdated } =
    useSessionExpiration({ onSessionExpired: lockSession });

  const getAccessToken = useCallback(async (): Promise<string | null> => {
    if (!isAuthenticated) {
      // When not authenticated, we don't need to get the access token
      return null;
    }
    const inviteCode = await getInviteCode();

    const accessTokenAuthParams = {
      invite_code: inviteCode,
    };

    try {
      const token = await getAccessTokenSilently({
        authorizationParams: accessTokenAuthParams,
      });
      logger?.log(`Access Token: ${token}`);
      handleSessionExpiryUpdated();
      return token;
    } catch (e: unknown) {
      const err = normalizeError(e);
      if (err.message === 'Multifactor authentication required') {
        // This is not an exception scenario, it just means the user needs
        // to login using MFA, eg. their session has expired.
        lockSession();
        return null;
      }
      captureException('Fail to get access token', accessTokenAuthParams, e);
      return null;
    }
  }, [
    getAccessTokenSilently,
    handleSessionExpiryUpdated,
    isAuthenticated,
    lockSession,
  ]);

  const authContextValue = useMemo(
    () => ({
      getAccessToken,
      isAuthenticated,
      shouldShowEnableFaceId: false,
      sessionExpiryTime,
      isLoading,
      logout: () => {
        setSentryUserId(null);
        biocatch.handleLogoutWeb();
        logout({
          logoutParams: {
            returnTo: t('Link.HomePage'),
          },
        });
      },
      login: (opts: { inviteCode?: string; returnTo?: string }) => {
        const { inviteCode, returnTo } = opts;
        const authorizationParams = {
          invite_code: inviteCode,
          ...biocatch.authParams,
        };
        return loginWithRedirect({
          appState: { returnTo },
          authorizationParams,
        });
      },
    }),
    [
      getAccessToken,
      isAuthenticated,
      sessionExpiryTime,
      isLoading,
      biocatch,
      logout,
      loginWithRedirect,
    ],
  );

  useHandleAuthError(authContextValue);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useMfaSessionId = () =>
  React.useMemo(() => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem('sessionId', sessionId);
      logger?.log(`Generated new PageSessionId ${sessionId}`);
      return sessionId;
    }
    logger?.log(`Use existing PageSessionID= ${sessionId}`);
    return sessionId;
  }, []);

const Auth0ProviderWrapper: React.FC<Props> = ({ children }) => {
  const { config } = useEnvConfig();
  const sessionId = useMfaSessionId();

  const authorizationParams: AuthorizationParams = useMemo(
    () => ({
      redirect_uri: window.location.origin,
      audience: config.graphqlURL,
      scope: 'profile openid email',
      unl_session_id: sessionId,
    }),
    [config.graphqlURL, sessionId],
  );

  return (
    <Auth0Provider
      domain={config.openIdDomain}
      clientId={config.openIdClientId}
      authorizationParams={authorizationParams}
      useRefreshTokens
      useRefreshTokensFallback
    >
      <BaseAuthProvider>{children}</BaseAuthProvider>
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
