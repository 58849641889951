import { TestID } from '../../../testID/constants';
import { BorrowerListItem_BorrowerFragment } from '../../generated/graphql';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { isLast } from '../../utils/arrayHelpers';
import { BorrowerListItem } from './BorrowerListItem';

export const MAX_BORROWERS = 2;

type Props = BoxProps & {
  borrowers: Array<BorrowerListItem_BorrowerFragment> | undefined;
  onAddBorrower: () => void;
  onBorrowerPress: (data: BorrowerListItem_BorrowerFragment) => void;
  maxBorrowers?: number;
};

export function BorrowerListV2(props: Props) {
  const {
    borrowers,
    onBorrowerPress,
    onAddBorrower,
    maxBorrowers = MAX_BORROWERS,
    ...otherProps
  } = props;

  if (borrowers == null) {
    return null;
  }

  const displayAddBorrowerButton = borrowers.length < maxBorrowers;

  return (
    <Box flex={1} alignSelf="stretch" {...otherProps}>
      {borrowers?.map((borrowerData, index) => (
        <BorrowerListItem
          {...borrowerData}
          onBorrowerPress={onBorrowerPress}
          key={borrowerData.id}
          last={!displayAddBorrowerButton && isLast(borrowers, index)}
          testIDPrefix={TestID.LoanApplicationBorrowers.BorrowerItem}
          mt={0}
        />
      ))}

      {displayAddBorrowerButton ? (
        <Button
          fontWeight="normal"
          label={t(
            'Content.LoanApplicationBorrowers.ButtonLabel.AddCoborrower',
          )}
          icon="add"
          tertiary
          testID={TestID.BorrowerList.AddAnotherBorrowerButton}
          onPress={onAddBorrower}
        />
      ) : null}
    </Box>
  );
}
