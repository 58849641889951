import { FormikHelpers } from 'formik';
import { ComponentProps, useCallback, useState } from 'react';

import {
  refetchGetMeQuery,
  Update_Address_Status,
  useUpdatePhysicalAddressMutation,
} from '../../generated/graphql';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { UpdateAddressModalForm } from '../components/UpdateAddressModalForm';
import {
  getAddressValidationSchema,
  getInitialValues,
  mapPhysicalAddressForMutation,
  SelectAddressValues,
} from '../utils/address';

type Props = SingleModalStackScreenProps<Screen.UPDATE_ADDRESS_MODAL>;

type FormOnSubmit = ComponentProps<typeof UpdateAddressModalForm>['onSubmit'];

export const UpdateAddressModal = ({ navigation }: Props) => {
  // State variables
  const [isResidentialAddressManualInput, setIsResidentialAddressManualInput] =
    useState<boolean>(false);
  const [isMailingAddressManualInput, setIsMailingAddressManualInput] =
    useState<boolean>(false);
  const [isAddressSame, setIsAddressSame] = useState<boolean>(true);

  const [residentialAddressErrorOnSubmit, setResidentialAddressErrorOnSubmit] =
    useState<string | undefined>(undefined);
  const [mailingAddressErrorOnSubmit, setMailingAddressErrorOnSubmit] =
    useState<string | undefined>(undefined);

  // Mutations
  const [
    updatePhysicalAddress,
    {
      error: updatePhysicalAddressError,
      loading: isUpdatePhysicalAddressLoading,
    },
  ] = useUpdatePhysicalAddressMutation({
    refetchQueries: [refetchGetMeQuery()],
  });

  // Callbacks
  const onClose = useCallback(() => navigation.goBack(), [navigation]);

  const onSubmit: FormOnSubmit = useCallback(
    async (
      addresses: SelectAddressValues,
      formHelpers: FormikHelpers<SelectAddressValues>,
    ) => {
      setMailingAddressErrorOnSubmit(undefined);
      setResidentialAddressErrorOnSubmit(undefined);
      formHelpers.setErrors({});
      const [response] = await safelyCallMutation(updatePhysicalAddress, {
        context: {
          sentryContext: {
            description: 'Update physical address mutation.',
          },
        },
        variables: mapPhysicalAddressForMutation(
          addresses,
          isAddressSame,
          isResidentialAddressManualInput,
          isMailingAddressManualInput,
        ),
      });

      const { ValidationError, Success } = Update_Address_Status;
      const validationErrorMessage = t(
        'Content.PersonalDetails.UpdateAddress.ValidationError',
      );

      if (response?.data?.update_physical_address) {
        const mailingAddressStatus =
          response.data.update_physical_address.mailing_address_status;
        const residentialAddressStatus =
          response.data.update_physical_address.residential_address_status;

        if (mailingAddressStatus === ValidationError) {
          setMailingAddressErrorOnSubmit(validationErrorMessage);
        }

        if (residentialAddressStatus === ValidationError) {
          setResidentialAddressErrorOnSubmit(validationErrorMessage);
        }

        if (
          mailingAddressStatus === Success &&
          residentialAddressStatus === Success
        ) {
          navigation.replace(
            ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET,
            {
              bottomText: t(
                'Content.PersonalDetails.UpdateAddress.AddressUpdated',
              ),
            },
          );
        }
      }
    },
    [
      navigation,
      isAddressSame,
      isResidentialAddressManualInput,
      isMailingAddressManualInput,
      updatePhysicalAddress,
    ],
  );

  const changeMailingAddressInputType = useCallback(
    (val: boolean) => setIsMailingAddressManualInput(val),
    [setIsMailingAddressManualInput],
  );

  const changeResidentialAddressInputType = useCallback(
    (val: boolean) => setIsResidentialAddressManualInput(val),
    [setIsResidentialAddressManualInput],
  );

  const toggleCheckBox = useCallback(
    () => setIsAddressSame((prev) => !prev),
    [setIsAddressSame],
  );

  // Variables
  const validationSchema = getAddressValidationSchema({
    isResidentialAddressManualInput,
    isAddressSame,
    isMailingAddressManualInput,
  });

  const initialValues = getInitialValues({
    isResidentialAddressManualInput,
    isMailingAddressManualInput,
  });

  const errorMessage = updatePhysicalAddressError
    ? t('Content.PersonalDetails.UpdateAddress.UnableToChangeAddress')
    : undefined;

  // Render
  return (
    <UpdateAddressModalForm
      screen={Screen.UPDATE_ADDRESS_MODAL}
      errorMessage={errorMessage}
      initialValues={initialValues}
      isAddressSame={isAddressSame}
      isLoading={isUpdatePhysicalAddressLoading}
      isMailingAddressManualInput={isMailingAddressManualInput}
      isResidentialAddressManualInput={isResidentialAddressManualInput}
      changeMailingAddressInputType={changeMailingAddressInputType}
      changeResidentialAddressInputType={changeResidentialAddressInputType}
      onClose={onClose}
      onSubmit={onSubmit}
      mailingAddressErrorOnSubmit={mailingAddressErrorOnSubmit}
      residentialAddressErrorOnSubmit={residentialAddressErrorOnSubmit}
      toggleCheckBox={toggleCheckBox}
      validationSchema={validationSchema}
    />
  );
};
