import { useNavigation } from '@react-navigation/native';

import { Screen } from '../../navigation/types/screens';
import {
  HomeStackNavigationProps,
  HomeTabNavigationProps,
} from '../navigation/types';

export function useHomeNavigation() {
  return useNavigation<
    HomeTabNavigationProps<Screen.HOME> | HomeStackNavigationProps<Screen.HOME>
  >();
}
