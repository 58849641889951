import { useCallback } from 'react';
import * as React from 'react';

import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { TermRemainingCard } from '../components/TermRemaining/TermRemainingCard';
import { useLoanAccountTermRemainingInsight } from '../hooks/useLoanAccountTermRemainingInsight';
import { HomeLoanInsightsScreenProps } from '../navigation/types';

type Props =
  HomeLoanInsightsScreenProps<Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING>;

export const TermRemainingInsightsScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { cbaAccountId } = route.params;
  const { loading, data } = useLoanAccountTermRemainingInsight(cbaAccountId);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.HomeLoan.Insights.TermRemaining.Header')}
      onClose={onClose}
      hideBackButton
      scrollable
      loading={loading}
    >
      <ScreenLoadingContainer loading={loading}>
        <TermRemainingCard data={data} />
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
};
