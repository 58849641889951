import { useDripsyTheme } from 'dripsy';
import * as React from 'react';

import { ArrowBackIcon } from '../svgs/ArrowBackIcon';

type Props = Partial<React.ComponentProps<typeof ArrowBackIcon>>;

export function BackButton({ ...otherProps }: Props) {
  const { theme } = useDripsyTheme();
  return <ArrowBackIcon size={theme.iconSizes.$arrowBack} {...otherProps} />;
}
