import { useDripsyTheme } from 'dripsy';

import { Card } from '../../../ui/atoms/Card';
import { Separator } from '../../../ui/atoms/Separator';
import { formatLoanTerm } from '../../../utils/stringHelpers';
import { useLoanAccountTermRemainingInsight } from '../../hooks/useLoanAccountTermRemainingInsight';
import { BadgeExplanation } from './BadgeExplanation';
import { Disclaimer } from './Disclaimer';
import { PredictablePayOffDateCaption } from './ProjectedPayOffDateCaption';
import { SuggestAdditionalPaymentsCaption } from './SuggestAdditionalPaymentsCaption';
import { TermRemainingInsightGraph } from './TermRemainingInsightGraph';

type Props = {
  data: ReturnType<typeof useLoanAccountTermRemainingInsight>['data'];
};

export const TermRemainingCard = ({ data }: Props) => {
  const { theme } = useDripsyTheme();
  const { isNoEarlierTerms, projectedPayOffDate, termsInMonthsBeEarlier } =
    data;

  return (
    <>
      <Card
        style={{
          paddingTop: theme.space.$18,
          paddingBottom: theme.space.$32,
          paddingHorizontal: theme.space.$24,
        }}
      >
        <TermRemainingInsightGraph data={data} enableHoverOn displayTooltip />

        <Separator my="l" />

        {isNoEarlierTerms ? (
          <SuggestAdditionalPaymentsCaption />
        ) : (
          <PredictablePayOffDateCaption
            absoluteDate={projectedPayOffDate}
            termEarlier={formatLoanTerm(termsInMonthsBeEarlier, true)}
          />
        )}

        <BadgeExplanation hasGradientBallIcon={!isNoEarlierTerms} />
      </Card>

      {!isNoEarlierTerms && <Disclaimer />}
    </>
  );
};
