import type { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';

import { isTest } from '../utils/platformUtils';
import { ENV_AUTH0_CONFIG } from './expo/auth0';
import { ENVIRONMENT, expoPublicEnv } from './expo/environment';

export const CONFIG = {
  RESET_TOKEN_STORE: true,
};

const showConsoleLog = __DEV__ && !isTest;
export const LOG_CONFIG = {
  AUTH: showConsoleLog,
  APP: showConsoleLog,
  IDENTITY_CHECK: showConsoleLog,
  INTERCOM: showConsoleLog,
};

type GTMConfig = ISnippetsParams;
type GTMEnv = 'PROD' | 'DEV';
const GTMCustomDomain = 'https://metrics.unloan.com.au';
const GTM_CONFIG: Record<GTMEnv, GTMConfig> = {
  PROD: {
    id: 'GTM-MLX74LH',
    customDomain: GTMCustomDomain,
  },
  DEV: {
    id: 'GTM-MT3F59Q',
    customDomain: GTMCustomDomain,
  },
};

export const UNLOAN_ICON_URI = 'https://i.imgur.com/dOn5jBH.png';

const FASTLINK_SCRIPT_URL = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';

const YODLEE_CONFIG = {
  development: {
    fastlinkURL:
      'https://finapp.unloanstage.yodlee.com.au/authenticate/unloan/fastlink',
    fastlinkScriptURL: FASTLINK_SCRIPT_URL,
    fastlinkObAppName: 'Unloan Stage',
  },
  production: {
    fastlinkURL:
      'https://finapp.unloan.yodlee.com.au/authenticate/unloanagg/fastlink',
    fastlinkScriptURL: FASTLINK_SCRIPT_URL,
    fastlinkObAppName: 'UnloanProd',
  },
};

const BIOCATCH_CONFIG = {
  PROD: {
    biocatchScriptURL: 'https://static.unloan.com.au/scripts/d8814f41.js',
    biocatchWupServerURL: 'https://wup-coco.au.v2.customers.biocatch.com',
    biocatchLogServerURL:
      'https://log-coco.au.v2.customers.biocatch.com/api/v1/sendLogs',
    biocatchCustomerId: 'coco',
  },
  NON_PROD: {
    biocatchScriptURL: 'https://static.nonp.unloan.com.au/scripts/2a817845.js',
    biocatchWupServerURL: 'https://wup-cocotest.au.v2.customers.biocatch.com',
    biocatchLogServerURL:
      'https://log-cocotest.au.v2.customers.biocatch.com/api/v1/sendLogs',
    biocatchCustomerId: 'cocotest',
  },
};

export interface IConfig {
  name: string;
  appUrl: string;
  graphqlURL: string;
  graphqlSubscriptionURL: string;
  testSupportAppURL?: string;
  gtmConfig: GTMConfig;
  openIdClientId: string;
  openIdDomain: string;
  showDiagnostics: boolean;
  showManageDataSharing: boolean;
  copyErrorsToClipboard: boolean;
  useManualAddressInput: boolean;
  fastlinkURL: string;
  fastlinkScriptURL: string;
  fastlinkObAppName: string;
  /** The domain used in the handover cookie, as set by an external application such as "Get Started" */
  handoverCookieDomain: string;
  allowTransferToBankwestMambu: boolean;
  maintenanceStartTime: string;
  maintenanceEndTime: string;
  intercomFeedbackSurveyId: number;
  intercomNativeAppFeedbackSurveyId: number;
  biocatchScriptURL?: string;
  biocatchWupServerURL?: string;
  biocatchLogServerURL?: string;
  biocatchCustomerId?: string;
}

export { ENVIRONMENT };

function parseEnv<T extends string | number>(
  v: string | undefined,
  defaultValue: T,
): T {
  if (!v) return defaultValue;

  if (typeof defaultValue === 'number') {
    return Number(v) as T;
  }
  return v as T;
}

export const DEFAULT_ENV = parseEnv(
  process.env.UNLOAN_ENV || expoPublicEnv,
  ENVIRONMENT.LOCAL_DEV,
);

export const ContactDetails = {
  supportEmail: 'support@unloan.com.au',
  unloanEmail: 'hello@unloan.com.au',
};

const maintenancePeriod = {
  maintenanceStartTime: '2023-02-10T23:00:00.000+11:00',
  maintenanceEndTime: '2023-02-11T08:00:00.000+11:00',
};

// TODO: env config should come from the backend. This should also specify the yodlee environment
export const ENV_CONFIG: Record<ENVIRONMENT, IConfig> = {
  [ENVIRONMENT.PROD_V3]: {
    name: 'Production (v3)',
    appUrl: 'https://app.unloan.com.au',
    graphqlURL: 'https://api.unloan.com.au/gql/v1/graphql',
    graphqlSubscriptionURL: 'wss://api.unloan.com.au/gql/v1/graphql',
    showDiagnostics: false,
    showManageDataSharing: true,
    copyErrorsToClipboard: false,
    gtmConfig: GTM_CONFIG.PROD,
    useManualAddressInput: true,
    allowTransferToBankwestMambu: true,
    intercomFeedbackSurveyId: 38642378,
    intercomNativeAppFeedbackSurveyId: 37007779,
    handoverCookieDomain: 'unloan.com.au',
    ...BIOCATCH_CONFIG.PROD,
    ...maintenancePeriod,
    ...ENV_AUTH0_CONFIG[ENVIRONMENT.PROD_V3],
    ...YODLEE_CONFIG.production,
  },
  [ENVIRONMENT.STAGING_V3]: {
    name: 'Staging (v3)',
    appUrl: 'https://app-stg.nonp.unloan.com.au',
    graphqlURL: 'https://api-stg.nonp.unloan.com.au/gql/v1/graphql',
    graphqlSubscriptionURL: 'wss://api-stg.nonp.unloan.com.au/gql/v1/graphql',
    showDiagnostics: true,
    showManageDataSharing: true,
    copyErrorsToClipboard: true,
    gtmConfig: GTM_CONFIG.DEV,
    useManualAddressInput: true,
    allowTransferToBankwestMambu: true,
    intercomFeedbackSurveyId: 38642433,
    intercomNativeAppFeedbackSurveyId: 36819034,
    handoverCookieDomain: 'unloan.com.au',
    ...BIOCATCH_CONFIG.NON_PROD,
    ...maintenancePeriod,
    ...ENV_AUTH0_CONFIG[ENVIRONMENT.STAGING_V3],
    ...YODLEE_CONFIG.development,
  },
  [ENVIRONMENT.TEST_V3]: {
    name: 'Test (v3)',
    appUrl: 'https://app-tst.nonp.unloan.com.au',
    graphqlURL: 'https://api-tst.nonp.unloan.com.au/gql/v1/graphql',
    graphqlSubscriptionURL: 'wss://api-tst.nonp.unloan.com.au/gql/v1/graphql',
    testSupportAppURL: 'https://api-tst.nonp.unloan.com.au/testsupport',
    showDiagnostics: true,
    showManageDataSharing: true,
    copyErrorsToClipboard: true,
    gtmConfig: GTM_CONFIG.DEV,
    useManualAddressInput: true,
    allowTransferToBankwestMambu: true,
    maintenanceStartTime: '2022-11-18T12:00:00.000+11:00',
    maintenanceEndTime: '2022-11-19T12:00:00.000+11:00',
    intercomFeedbackSurveyId: 38642433,
    intercomNativeAppFeedbackSurveyId: 36819034,
    handoverCookieDomain: 'unloan.com.au',
    ...BIOCATCH_CONFIG.NON_PROD,
    ...ENV_AUTH0_CONFIG[ENVIRONMENT.TEST_V3],
    ...YODLEE_CONFIG.development,
  },
  [ENVIRONMENT.DEV_V3]: {
    name: 'Dev (v3)',
    appUrl: 'https://app-dev.nonp.unloan.com.au',
    graphqlURL: 'https://api-dev.nonp.unloan.com.au/gql/v1/graphql',
    graphqlSubscriptionURL: 'wss://api-dev.nonp.unloan.com.au/gql/v1/graphql',
    testSupportAppURL: 'https://api-dev.nonp.unloan.com.au/testsupport',
    showDiagnostics: true,
    showManageDataSharing: true,
    copyErrorsToClipboard: true,
    gtmConfig: GTM_CONFIG.DEV,
    useManualAddressInput: true,
    allowTransferToBankwestMambu: true,
    maintenanceStartTime: '2022-11-18T12:00:00.000+11:00',
    maintenanceEndTime: '2022-11-19T12:00:00.000+11:00',
    intercomFeedbackSurveyId: 38642433,
    intercomNativeAppFeedbackSurveyId: 36819034,
    handoverCookieDomain: 'unloan.com.au',
    ...BIOCATCH_CONFIG.NON_PROD,
    ...ENV_AUTH0_CONFIG[ENVIRONMENT.DEV_V3],
    ...YODLEE_CONFIG.development,
  },
  [ENVIRONMENT.LOCAL_DEV]: {
    name: 'Local Dev',
    appUrl: 'http://localhost:19006',
    graphqlURL: 'http://localhost:8080/v1/graphql',
    graphqlSubscriptionURL: 'ws://localhost:8080/v1/graphql',
    testSupportAppURL: 'http://localhost:7074/api',
    showDiagnostics: true,
    showManageDataSharing: true,
    copyErrorsToClipboard: true,
    gtmConfig: GTM_CONFIG.DEV,
    useManualAddressInput: true,
    allowTransferToBankwestMambu: true,
    maintenanceStartTime: '2022-11-18T10:58:00.000+11:00',
    maintenanceEndTime: '2022-11-18T12:19:15.000+11:00',
    intercomFeedbackSurveyId: 38642433,
    intercomNativeAppFeedbackSurveyId: 36819034,
    handoverCookieDomain: 'localhost',
    ...BIOCATCH_CONFIG.NON_PROD,
    ...ENV_AUTH0_CONFIG[ENVIRONMENT.LOCAL_DEV],
    ...YODLEE_CONFIG.development,
  },
};

export const getEnvironmentName = (env: ENVIRONMENT): string =>
  ENV_CONFIG[env].name;
