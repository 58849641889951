import { gql } from '@apollo/client';
import { Text } from 'dripsy';
import { useCallback, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import {
  Liability_Type_Enum,
  LiabilitySupportingDocumentUploadDetailQuery,
  Loan_Application_Task_Type,
  useLiabilitySupportingDocumentsSubscription,
  useLiabilitySupportingDocumentUploadDetailQuery,
} from '../../generated/graphql';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { TextList } from '../../ui/v2/TextList';
import { useDocumentPicker } from '../../utils/hooks/useDocumentPicker';
import {
  getErrorMessage,
  useLiabilitySupportingDocumentUpload,
} from '../../utils/hooks/useSupportingDocumentUpload';
import { DocumentRow } from '../../VerifyFinancials/components/DocumentRow';
import { mapMimeTypeToDocumentIconType } from '../../VerifyFinancials/utils/mapMimeTypeToDocumentIconType';
import { DocumentUpload } from '../components/DocumentUpload';
import { LiabilitySummaryCard } from '../components/LiabilitySummaryCard';
import { UploadOverlay } from '../components/UploadOverlay';

export const LiabilitySupportingDocumentsSubscription = gql`
  subscription LiabilitySupportingDocuments(
    $detected_liability_identifier: String!
    $loan_application_id: uuid!
  ) {
    mergedLiabilityDocs: liability_supporting_document(
      where: {
        detected_liability_identifier: { _eq: $detected_liability_identifier }
        file_upload: { status: { _neq: PENDING_UPLOAD } }
        loan_application_id: { _eq: $loan_application_id }
      }
      order_by: { file_upload: { created_at: desc } }
    ) {
      id
      file_upload {
        id
        status
        mimeType: mime_type
        originalName: original_name
        sizeInBytes: size_in_bytes
        xaiDocumentId: xai_document_id
        createdAt: created_at
      }
    }
  }
`;

export const LiabilitySupportingDocumentsScreenQuery = gql`
  query LiabilitySupportingDocumentUploadDetail($mergedLiabilityId: String!) {
    merged_liability_by_pk(id: $mergedLiabilityId) {
      ...LiabilitySupportingDocumentUpload_LiabilityDetailFragment
    }
  }
`;

export const LiabilitySupportingDocumentUploadLiabilityDetailFragment = gql`
  fragment LiabilitySupportingDocumentUpload_LiabilityDetailFragment on merged_liability {
    id
    dynamite_liability_type
    dynamite_institution_name
    dynamite_applicant_full_names
    dynamite_account_number
    dynamite_display_address
    detected_liability_identifier
  }
`;

export function getTitleAndCaption(
  data?: LiabilitySupportingDocumentUploadDetailQuery,
  loanApplicationTaskType?: Loan_Application_Task_Type,
): {
  title: string;
  captions: {
    caption?: string;
    items?: string[];
  }[];
} {
  const emptyTitleAndCaptions = { title: '', captions: [] };
  const liability = data?.merged_liability_by_pk;
  if (!liability) {
    return emptyTitleAndCaptions;
  }
  const liabilityType = liability.dynamite_liability_type;
  if (!liabilityType) {
    return emptyTitleAndCaptions;
  }

  switch (loanApplicationTaskType) {
    case Loan_Application_Task_Type.AccountClosureSupportingDocument: {
      return {
        title: t(
          `Content.Common.LiabilityType.${
            liabilityType as Liability_Type_Enum
          }`,
        ),
        captions: [
          {
            caption: t(
              `Content.LiabilitySupportingDocumentUpload.ClosedAccounts.Caption`,
            ),
            items: [
              t(
                `Content.LiabilitySupportingDocumentUpload.ClosedAccounts.ItemOne`,
              ),
              t(
                `Content.LiabilitySupportingDocumentUpload.ClosedAccounts.ItemTwo`,
              ),
            ],
          },
        ],
      };
    }
    case Loan_Application_Task_Type.ReducedLimitSupportingDocument: {
      return {
        title: t(
          `Content.Common.LiabilityType.${
            liabilityType as Liability_Type_Enum
          }`,
        ),
        captions: [
          {
            caption: t(
              `Content.LiabilitySupportingDocumentUpload.ReducedLimits.Caption`,
            ),
            items: [
              t(
                `Content.LiabilitySupportingDocumentUpload.ReducedLimits.ItemOne`,
              ),
              t(
                `Content.LiabilitySupportingDocumentUpload.ReducedLimits.ItemTwo`,
              ),
            ],
          },
        ],
      };
    }
    case Loan_Application_Task_Type.RefinancingSupportingDocument: {
      return {
        title: t(
          `Content.Common.LiabilityType.${
            liabilityType as Liability_Type_Enum
          }`,
        ),
        captions: [
          {
            caption: t(
              `Content.LiabilitySupportingDocumentUpload.Refinancing.Caption`,
            ),
          },
        ],
      };
    }
    default:
      return emptyTitleAndCaptions;
  }
}

type Props =
  SingleModalStackScreenProps<Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL>;

export function LiabilitySupportingDocumentUpload({
  route,
  navigation,
}: Props) {
  const loanApplicationId = route.params?.loanApplicationId ?? '';
  const loanApplicationTaskType = route.params?.loanApplicationTaskType;

  const {
    uploadLiabilitySupportingDocument,
    isUploading,
    error: uploadError,
  } = useLiabilitySupportingDocumentUpload(loanApplicationId);
  const mergedLiabilityId = route.params?.mergedLiabilityId;
  const {
    error: liabilityDetailQueryError,
    loading: liabilityDetailQueryLoading,
    data: liabilityDetailQueryResult,
    refetch,
  } = useLiabilitySupportingDocumentUploadDetailQuery({
    variables: {
      mergedLiabilityId: mergedLiabilityId || '',
    },
    context: {
      sentryContext: {
        mergedLiabilityId,
      },
    },
  });

  const { title, captions } = getTitleAndCaption(
    liabilityDetailQueryResult,
    loanApplicationTaskType,
  );

  const { makeDocumentPickerHandler } = useDocumentPicker();

  const liabilitySupportingDocsSubscription =
    useLiabilitySupportingDocumentsSubscription({
      variables: {
        detected_liability_identifier:
          liabilityDetailQueryResult?.merged_liability_by_pk
            ?.detected_liability_identifier || '',
        loan_application_id: loanApplicationId,
      },
      skip: !liabilityDetailQueryResult,
    });
  const { loading, error: liabilitySupportingDocsSubscriptionError } =
    liabilitySupportingDocsSubscription;
  const liabilitySupportingDocs =
    liabilitySupportingDocsSubscription.data?.mergedLiabilityDocs || [];
  const openDocumentPicker = useRef(
    makeDocumentPickerHandler({
      onDocumentSelect: async (selectedDocs) => {
        await uploadLiabilitySupportingDocument({
          document: selectedDocs,
          mergedLiabilityId: mergedLiabilityId || '',
          loanApplicationTaskType,
        });
      },
    }),
  );
  const onUploadDocumentPress = useCallback(async () => {
    await openDocumentPicker.current();
  }, []);

  let errorMessage = null;

  if (liabilitySupportingDocsSubscriptionError) {
    errorMessage = t('Content.Common.Error.FailFetchUploadedDocuments');
  } else if (uploadError) {
    errorMessage = getErrorMessage(uploadError);
  }

  return (
    <>
      <ModalScreenContainer
        onClose={navigation.goBack}
        headerText={title}
        scrollable
        hideBackButton
        loading={liabilityDetailQueryLoading || isUploading}
      >
        <ScreenErrorFallback
          error={liabilityDetailQueryError}
          displayMessage={t(
            'Content.LiabilitySupportingDocumentUpload.Error.FailFetchIncomeDetail',
          )}
          refetch={refetch}
        >
          <ErrorRow message={errorMessage} mb="l" />
          <>
            <LiabilitySummaryCard
              liability={liabilityDetailQueryResult?.merged_liability_by_pk}
            />
            {captions.map((c) => (
              <>
                <Text>{c.caption}</Text>
                <TextList
                  listSx={{
                    pl: '$8',
                    py: '$8',
                  }}
                  ordered={false}
                  items={c.items || []}
                />
              </>
            ))}
          </>
          <DocumentUpload
            onUploadDocumentPress={onUploadDocumentPress}
            my="l"
            testID={TestID.LiabilitySupportingDocumentUpload.UploadButton}
          />
          {loading ? (
            <>
              <Separator spacer />
              <Spinner />
              <Separator spacer />
            </>
          ) : null}
          {liabilitySupportingDocs.map(
            ({
              file_upload: {
                id,
                originalName,
                mimeType,
                sizeInBytes,
                createdAt,
              },
            }) => (
              <DocumentRow
                key={id}
                name={originalName}
                sizeInBytes={sizeInBytes}
                createdAt={createdAt}
                iconType={mapMimeTypeToDocumentIconType(mimeType)}
              />
            ),
          )}
          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            width="100%"
            mb="l"
            mt="xl"
            testID={TestID.LiabilitySupportingDocumentUpload.DoneButton}
            disabled={liabilityDetailQueryLoading}
            onPress={navigation.goBack}
          />
        </ScreenErrorFallback>
      </ModalScreenContainer>
      {isUploading ? <UploadOverlay /> : null}
    </>
  );
}
