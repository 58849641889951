import { styled, Text, View } from 'dripsy';
import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { RadioGroup, RadioGroupItem } from '../../ui/v2/RadioGroup';
import { parseEnumType } from '../../utils/ensureEnumType';
import { makeTestId } from '../../utils/stringHelpers';
import {
  EmploymentIncomeForm,
  EmploymentIncomeFormDetails,
  EmploymentIncomeFormSubmitLabel,
} from '../components/EmploymentIncomeForm';
import {
  useCreateEmploymentIncomeForV2Form,
  useGetOptionsForEmploymentIncomeQuery,
  usePrepopulateEmploymentIncomeForV2Form,
} from '../graphql/incomeHooks';
import { useCheckExpectedRentalIncome } from '../hooks/useCheckExpectedRentalIncome';
import { YourIncomeWizardScreenProps } from '../navigation/incomeTypes';
import { IncomeYesNoEnum } from '../utils/incomeFormTypes';
import { composeIncomeWizardScreenName } from '../utils/incomeNavigationUtils';

export type Props =
  YourIncomeWizardScreenProps<`${Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT}:${string}`>;

export function EmploymentIncomeWizardScreen({ navigation, route }: Props) {
  const { params } = route;

  const {
    loanApplicationId = '',
    incomeVerificationId = '',
    currentApplicant,
  } = params || {};
  const applicantId = currentApplicant?.id || '';
  const coBorrowerApplicantName =
    currentApplicant?.is_current_logged_in_applicant
      ? undefined
      : currentApplicant?.latest_first_name || '';

  const [showFormEnumState, setShowFormEnumState] = useState<IncomeYesNoEnum>();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const {
    occupationTypeOptions,
    employmentTypeOptions,
    incomeOwnersOptions,
    loading: employmentIncomeOptionsLoading,
  } = useGetOptionsForEmploymentIncomeQuery({
    loanApplicationId: loanApplicationId ?? '',
  });

  const {
    initialValue: formInitialValues,
    loading: isPrepopulatingForm,
    hasDetectedValues,
  } = usePrepopulateEmploymentIncomeForV2Form({
    applicantId,
    incomeVerificationId,
    loanApplicationId,
  });

  const { createEmploymentIncome, isCreatingEmploymentIncome } =
    useCreateEmploymentIncomeForV2Form({
      loanApplicationId,
      incomeVerificationId,
    });
  const {
    shouldIncludeAddExpectedRentalIncome,
    loading: shouldIncludeAddExpectedRentalIncomeLoading,
  } = useCheckExpectedRentalIncome(loanApplicationId);

  const navigateToNextForm = () => {
    if (params.nextApplicant) {
      navigation.navigate(
        composeIncomeWizardScreenName(
          Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT,
          params.nextApplicant?.id,
        ),
        { loanApplicationId: params.loanApplicationId },
      );
      return;
    }
    let nextScreen = Screen.YOUR_INCOME_V2_WIZARD_RENTAL;
    if (shouldIncludeAddExpectedRentalIncome) {
      nextScreen = Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL;
    }
    navigation.navigate(nextScreen, {
      loanApplicationId: params.loanApplicationId,
    });
  };

  const onSubmit = async (values: EmploymentIncomeFormDetails) => {
    if (showFormEnumState === IncomeYesNoEnum.No) {
      navigateToNextForm();
      return;
    }

    const { success, errorMessage } = await createEmploymentIncome(values);

    if (success) {
      navigateToNextForm();
    } else {
      setFormErrorMessage(errorMessage);
    }
  };

  const showLoading =
    employmentIncomeOptionsLoading ||
    isPrepopulatingForm ||
    shouldIncludeAddExpectedRentalIncomeLoading;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.EmploymentIncome')}
      scrollable
      hideBackButton
      loading={isCreatingEmploymentIncome}
      onClose={() => {
        navigateToLoanApplicationScreen({
          section: LoanApplicationSection.Income,
        });
      }}
    >
      {showLoading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.EmploymentIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage}
            testID={TestID.EmploymentIncomeForm.ErrorText}
          />
          {hasDetectedValues ? (
            <Text sx={{ mb: '$32' }}>
              {t('Content.YourIncome.Form.DetectedEmploymentDetails')}
            </Text>
          ) : (
            <>
              <SHeaderText>
                {coBorrowerApplicantName
                  ? t(
                      'Content.YourIncome.Form.IsEmployedQuestions.NamedQuestion',
                      { name: coBorrowerApplicantName },
                    )
                  : t(
                      'Content.YourIncome.Form.IsEmployedQuestions.YourQuestion',
                    )}
              </SHeaderText>
              <RowView sx={{ mt: '$16', mb: '$32' }}>
                <RadioGroup
                  value={showFormEnumState}
                  onValueSelected={(v) => {
                    const parsedValue = parseEnumType(IncomeYesNoEnum, v);
                    if (parsedValue) {
                      setShowFormEnumState(parsedValue);
                    }
                  }}
                >
                  <RadioGroupItem
                    value={IncomeYesNoEnum.Yes}
                    label={t('Content.Common.ButtonLabel.Yes')}
                    testID={makeTestId([
                      TestID.EmploymentIncomeForm.IsCurrentlyEmployed,
                      IncomeYesNoEnum.Yes,
                    ])}
                    containerStyle={{
                      mr: '$16',
                      flex: 1,
                    }}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.Income,
                      Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT,
                      FieldInteractionKey.AreYouCurrentlyEmployed,
                    )}
                  />
                  <RadioGroupItem
                    value={IncomeYesNoEnum.No}
                    label={t('Content.Common.ButtonLabel.No')}
                    testID={makeTestId([
                      TestID.EmploymentIncomeForm.IsCurrentlyEmployed,
                      IncomeYesNoEnum.No,
                    ])}
                    containerStyle={{
                      flex: 1,
                    }}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.Income,
                      Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT,
                      FieldInteractionKey.AreYouCurrentlyEmployed,
                    )}
                  />
                </RadioGroup>
              </RowView>
            </>
          )}
          {showFormEnumState === IncomeYesNoEnum.Yes || hasDetectedValues ? (
            <EmploymentIncomeForm
              screen={Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT}
              initialValues={formInitialValues}
              isSubmitting={isCreatingEmploymentIncome}
              onSubmit={onSubmit}
              employmentTypeOptions={employmentTypeOptions}
              occupationTypeOptions={occupationTypeOptions}
              payeeOptions={incomeOwnersOptions}
              submitLabel={EmploymentIncomeFormSubmitLabel.Next}
            />
          ) : (
            // A duplicated button with the same style as the one used in EmploymentIncomeForm.
            // Needed to skip this step if applicant doesn't have employment income.
            <Button
              label={t('Content.Common.ButtonLabel.Next')}
              testID={TestID.EmploymentIncomeForm.SubmitButton}
              onPress={() => navigateToNextForm()}
              disabled={!showFormEnumState}
              py="m"
              alignSelf="stretch"
            />
          )}
        </>
      )}
    </ModalScreenContainer>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
