import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const RefinanceIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none" {...props}>
    <Path
      d="M7.5 7.5V32.5H32.5M28.3333 26.9444V18.6111C28.3333 12.4746 23.3587 7.5 17.2222 7.5H13.0556M28.3333 26.9444L24.1667 22.7778M28.3333 26.9444L32.5 22.7778"
      stroke={color}
      strokeOpacity="0.95"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
