import { Box, BoxProps } from '../ui/atoms/Box';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { StyledText } from '../ui/atoms/StyledText';
import { Color } from '../ui/types';
import { BannerWrapper } from './BannerWrapper';

type Props = BoxProps & {
  message?: string;
  messageLink?: string;
  onLinkPress?: () => void;
  mode?: 'success' | 'warning' | 'error' | 'info';
  children?: React.ReactNode;
};

function rowStyle(mode: string): {
  bg: BoxProps['bg'];
  color: Color;
} {
  switch (mode) {
    case 'success':
      return {
        bg: 'successBg',
        color: 'success',
      };
    case 'warning':
      return {
        bg: 'warningBg',
        color: 'warning',
      };
    case 'error':
      return {
        bg: 'errorBg',
        color: 'error',
      };
    case 'info':
      return {
        bg: 'buttonDisabledBg',
        color: 'accentBg',
      };
    default:
      return {
        bg: 'buttonDisabledBg',
        color: 'accentBg',
      };
  }
}

export function InfoRow({
  message,
  messageLink,
  onLinkPress,
  mode = 'info',
  children,
  ...props
}: Props) {
  const linkEl = messageLink ? (
    <StyledText variant="caption" color="link" onPress={onLinkPress}>
      {messageLink}
    </StyledText>
  ) : null;

  return (
    <BannerWrapper>
      <Box
        bg={rowStyle(mode).bg}
        row
        borderRadius="card"
        p="m"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <StyledIcon
          name="information-circle"
          color={rowStyle(mode).color}
          mr="m"
        />

        {!!message && (
          <StyledText variant="caption" flex={1}>
            {message}
            {linkEl}
          </StyledText>
        )}
        {children}
      </Box>
    </BannerWrapper>
  );
}
