import { useNavigation } from '@react-navigation/native';

import {
  Props as ScreenContainerProps,
  ScreenContainer,
} from '../ui/atoms/ScreenContainer';
import { useXButton } from '../utils/hooks/useHeaderButton';

type Props = ScreenContainerProps;

export function CardModalScreenContainer(props: Props) {
  const navigation = useNavigation();
  useXButton(navigation);

  return (
    <ScreenContainer safeAreaBottom={false} safeAreaTop={false} {...props} />
  );
}
