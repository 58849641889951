import { useCallback, useEffect } from 'react';

import { Screen } from '../../navigation/types/screens';
import { SessionExpired } from '../components/SessionExpired';
import { Welcome } from '../components/Welcome';
import { useAuth } from '../hooks';
import { AuthScreenProps } from '../navigation/types';

type Props = AuthScreenProps;

export function BrowserAuthFlow({ navigation, route }: Props) {
  const { login, loading, isAuthenticated } = useAuth();
  const { reason } = route.params ?? {};

  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: { screen: Screen.HOME },
    });
  }, [navigation]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (isAuthenticated) {
      navigateToHome();
    }
  }, [loading, isAuthenticated, navigateToHome, login]);

  const onContinueAuth = useCallback(() => {
    if (isAuthenticated) {
      navigateToHome();
    } else {
      login();
    }
  }, [isAuthenticated, navigateToHome, login]);

  if (reason === 'session-locked') {
    return <SessionExpired loading={loading} onPressResume={onContinueAuth} />;
  }

  return <Welcome loading={loading} onPressContinue={onContinueAuth} />;
}
