import { Box, BoxProps } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';
import { Skeleton } from '../../ui/v2/Skeleton';

type LoanDetailsProps = BoxProps & {
  label: string;
  value: string;
  loading?: boolean;
  caption?: React.ReactNode;
  valueTestId?: string;
  tooltip?: React.JSX.Element;
};

export function LoanDetails({
  label,
  value,
  caption,
  loading,
  valueTestId,
  tooltip,
  ...otherProps
}: LoanDetailsProps) {
  const theme = useTheme();
  return (
    <Box {...otherProps}>
      <StyledText variant="body" fontWeight="semiBold">
        {label}
      </StyledText>
      {loading ? (
        <Skeleton
          width={120}
          height={theme.textVariants.numberSmall.lineHeight}
          show
        />
      ) : (
        <StyledText
          variant="numberSmall"
          fontWeight="semiBold"
          testID={valueTestId}
        >
          {value}
        </StyledText>
      )}
      {caption ? (
        <StyledText variant="caption" mt="s">
          {caption} {tooltip}
        </StyledText>
      ) : null}
    </Box>
  );
}
