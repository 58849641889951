import { Formik, FormikProps } from 'formik';
import { useCallback } from 'react';
import * as React from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { Form } from '../../components/form/FormikInputs';
import { SubmitButton } from '../../components/form/SubmitButton';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { oneOfEnum } from '../../components/utils/oneOfEnum';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { formatCurrency } from '../../utils/currencyHelpers';
import { yup } from '../../utils/yup';
import {
  autopayCustomRepaymentFormAtom,
  AutopayCustomRepaymentFormValues,
} from '../autopayAtom';
import { CustomRepaymentFormInput } from '../components/CustomRepaymentFormInput';
import { useDirectDebitLimits } from '../hooks/useDirectDebitLimits';
import { AutopaySettingsScreenProps } from '../navigation/types';

type Props = AutopaySettingsScreenProps<Screen.AUTOPAY_CUSTOM_REPAYMENT>;

function getMinimumRepaymentAmountForSelectedFrequency(
  minimimRepaymentAmount: Record<Autopay_Frequency_Input_Enum, number>,
  selectedRepaymentFrequency: Autopay_Frequency_Input_Enum | '',
): number {
  const customRepaymentFrequency =
    selectedRepaymentFrequency === ''
      ? Autopay_Frequency_Input_Enum.Monthly
      : selectedRepaymentFrequency;
  return minimimRepaymentAmount[customRepaymentFrequency];
}

function getSelectedFrequencyLabel(
  customRepaymentFrequency: Autopay_Frequency_Input_Enum | '',
) {
  switch (customRepaymentFrequency) {
    case Autopay_Frequency_Input_Enum.Weekly:
      return t('Content.Common.FrequencyEnum.WEEKLY');
    case Autopay_Frequency_Input_Enum.Fortnightly:
      return t('Content.Common.FrequencyEnum.FORTNIGHTLY');
    case Autopay_Frequency_Input_Enum.Monthly:
    default:
      return t('Content.Common.FrequencyEnum.MONTHLY');
  }
}

export const AutopayCustomRepayment: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { cbaAccountId } = route.params;

  const { data: limitsData, loading } = useDirectDebitLimits(cbaAccountId);

  const [autopayCustomRepaymentForm, setAutopayCustomRepaymentForm] =
    useRecoilState(autopayCustomRepaymentFormAtom);

  const handleSubmit = useCallback(
    (values: AutopayCustomRepaymentFormValues) => {
      setAutopayCustomRepaymentForm({
        customRepaymentAmount: values.customRepaymentAmount,
        customRepaymentFrequency: values.customRepaymentFrequency,
      });
      navigation.goBack();
    },
    [navigation, setAutopayCustomRepaymentForm],
  );

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  if (loading || !limitsData) {
    return (
      <ModalScreenContainer
        headerText={t('Content.AutopaySettings.CustomRepayment')}
        onClose={onClose}
        scrollable
      >
        <ScreenLoadingContainer loading={loading} />
      </ModalScreenContainer>
    );
  }

  const { maximumDirectDebitAmount, minimimRepaymentAmount } = limitsData;

  const validationSchema = yup.object({
    customRepaymentAmount: yup
      .number()
      .required('Repayment amount required')
      .when(
        'customRepaymentFrequency',
        (frequency: Autopay_Frequency_Input_Enum | '') => {
          if (!frequency) return yup.number();
          const minimumAmount = minimimRepaymentAmount?.[frequency];
          return yup.number().min(
            minimumAmount,
            t('Content.AutopaySettings.InvalidCustomRepaymentTooLow', {
              frequency: frequency.toLowerCase(),
              minimumAmount: formatCurrency(minimumAmount),
            }),
          );
        },
      )
      .max(
        maximumDirectDebitAmount,
        t('Content.AutopaySettings.MaximumDirectDebitLimit', {
          value: formatCurrency(maximumDirectDebitAmount),
        }),
      ),
    customRepaymentFrequency: oneOfEnum(Autopay_Frequency_Input_Enum).required(
      t('Content.AutopaySettings.RepaymentFrequencyRequired'),
    ),
  });

  const makeOnSubmitPress =
    ({ isValid, submitForm }: FormikProps<AutopayCustomRepaymentFormValues>) =>
    () => {
      if (!isValid) {
        return;
      }
      submitForm();
    };

  return (
    <ModalScreenContainer
      headerText={t('Content.AutopaySettings.CustomRepayment')}
      onClose={onClose}
      scrollable
    >
      <ScreenLoadingContainer loading={loading}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={autopayCustomRepaymentForm}
        >
          {(formProps) => (
            <Form>
              <CustomRepaymentFormInput
                validationErrorMessage={
                  formProps.touched.customRepaymentAmount &&
                  formProps.values.customRepaymentFrequency !== '' &&
                  formProps.values.customRepaymentAmount !== null
                    ? formProps.errors.customRepaymentFrequency ??
                      formProps.errors.customRepaymentAmount
                    : undefined
                }
              />
              <Box pt="m">
                <StyledText
                  testID={TestID.AutopayCustomRepayment.MinimumRepaymentLabel}
                  variant="caption"
                >
                  {t(
                    'Content.AutopaySettings.CustomRepaymentScreen.MinimumRepayment',
                    {
                      amount: formatCurrency(
                        getMinimumRepaymentAmountForSelectedFrequency(
                          limitsData.minimimRepaymentAmount,
                          formProps.values.customRepaymentFrequency,
                        ),
                      ),
                      frequency: getSelectedFrequencyLabel(
                        formProps.values.customRepaymentFrequency,
                      ),
                    },
                  )}
                </StyledText>
              </Box>
              <Box pt="m">
                <StyledText variant="caption">
                  {t(
                    'Content.AutopaySettings.CustomRepaymentScreen.SmallPrint',
                  )}
                </StyledText>
              </Box>
              <SubmitButton
                testID={TestID.AutopayCustomRepayment.SubmitButton}
                label={t('Content.Common.ButtonLabel.Done')}
                onPress={makeOnSubmitPress(formProps)}
                mt="l"
              />
            </Form>
          )}
        </Formik>
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
};
