import { Platform, StyleSheet } from 'react-native';

import { InfoRow } from '../../components/InfoRow';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';

export type Props = BoxProps & {
  title: string;
  caption?: string;
  caption2?: string;
  captionTestId?: string;
  learnMoreText?: string;
  learnMoreTestId?: string;
  onLearnMorePress?: () => void;
  infoText?: string;
};

export function LoanScreenHeader({
  title,
  caption,
  caption2,
  captionTestId,
  learnMoreText,
  learnMoreTestId,
  onLearnMorePress,
  mx = 'l',
  infoText,
  ...otherProps
}: Props) {
  const infoEl = infoText ? <InfoRow message={infoText} my="s" /> : null;

  return (
    <Box centered pt="s" mx={mx} {...otherProps}>
      <StyledText variant="header" textAlign="center" my="s">
        {title}
      </StyledText>
      {infoEl}
      {!!caption || !!onLearnMorePress || !!caption2 ? (
        <StyledText mt="s" textAlign="center" testID={captionTestId}>
          {caption || null}
          {onLearnMorePress ? (
            <StyledText
              color="link"
              onPress={onLearnMorePress}
              style={styles.inlineLink}
              testID={learnMoreTestId}
            >
              {` ${learnMoreText}`}
            </StyledText>
          ) : null}
          {caption2 || null}
        </StyledText>
      ) : null}
    </Box>
  );
}

const styles = StyleSheet.create({
  // @ts-expect-error This style is needed for web
  inlineLink: Platform.select({
    web: { display: 'contents' },
  }),
});
