import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function ShareIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(20, 25, size);
  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 20 25"
      fill="none"
      {...props}
    >
      <Path
        d="M10.268 16.166c.461 0 .859-.387.859-.838V4.296l-.065-1.611.72.762 1.633 1.74c.15.172.365.258.58.258.44 0 .784-.322.784-.762 0-.226-.096-.398-.258-.559L10.892.622c-.215-.215-.398-.29-.623-.29-.215 0-.398.075-.623.29L6.014 4.124a.75.75 0 0 0-.247.559c0 .44.322.762.773.762.204 0 .44-.086.59-.258l1.623-1.74.73-.762-.064 1.61v11.033c0 .451.387.838.849.838Zm-6.156 8.411h12.322c2.245 0 3.373-1.117 3.373-3.33v-10.72c0-2.214-1.128-3.33-3.373-3.33h-2.998v1.729h2.965c1.064 0 1.676.58 1.676 1.697V21.15c0 1.118-.612 1.698-1.676 1.698H4.134c-1.074 0-1.665-.58-1.665-1.698V10.623c0-1.117.59-1.697 1.665-1.697h2.975v-1.73H4.112C1.867 7.196.74 8.313.74 10.526v10.721c0 2.213 1.128 3.33 3.373 3.33Z"
        fill={color}
      />
    </Svg>
  );
}
