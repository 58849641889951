import { useCallback, useContext, useEffect, useState } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { experiments } from '../../FeatureFlags/experiments';
import {
  FALLBACK_FLAGS,
  FeatureFlag,
  Flags,
} from '../../FeatureFlags/featureFlags';
import { useSendExperimentFlagToGTM } from './useSendExperimentFlagToGTM';

type FeatureFlagReturnType<T extends FeatureFlag> = T extends keyof Flags
  ? Flags[T]
  : never;

export const useExperimentFeatureFlag = <T extends FeatureFlag>(flag: T) => {
  const sendExperimentFlagToGTM = useSendExperimentFlagToGTM();
  const { variation, flags, ldContext } = useContext(FeatureFlagsContext);
  const [featureFlag, setFeatureFlag] = useState<FeatureFlagReturnType<T>>(
    (flags[flag] || FALLBACK_FLAGS[flag]) as FeatureFlagReturnType<T>,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const getFlag = useCallback(async () => {
    const experiment = experiments[flag];
    if (experiment) {
      const result =
        (await variation?.(flag)) || experiment?.defaultVariant || flags[flag];
      setFeatureFlag(result);
      sendExperimentFlagToGTM(
        flag,
        result,
        experiment.experimentName,
        ldContext?.userId,
        ldContext?.loanApplicationId,
      );
    }
    setLoading(false);
  }, [flags, ldContext, flag, variation, sendExperimentFlagToGTM]);

  useEffect(() => {
    getFlag();
  }, [variation, flag, getFlag, ldContext?.userId]);

  return { featureFlag, loading };
};
