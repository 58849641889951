import { useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { useHideBackButton } from '../../utils/hooks/useHeaderButton';
import { GreenCheckAnimation } from '../components/GreenCheckAnimation';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';

export type Props = HomeTabScreenProps<Screen.VOI_COMPLETED>;

function VOICompletedDashboardBase({ navigation }: Props) {
  useHideBackButton(navigation);

  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: { screen: Screen.HOME },
    });
  }, [navigation]);

  return (
    <LoanApplicationScreenContainer containerStyle={styles.root} px="l">
      <NavHeaderSpacer />
      <GreenCheckAnimation />
      <StyledText variant="header" mt="l" textAlign="center">
        {t('Content.FinaliseYourApplication.VOICompleted.Header')}
      </StyledText>
      <StyledText my="s" mb="m" textAlign="center">
        {t('Content.FinaliseYourApplication.VOICompleted.Caption')}
      </StyledText>
      <Button
        label="Continue"
        testID={TestID.CompletedLoanApplication.BackToDashboardButton}
        width="100%"
        onPress={navigateToHome}
      />
    </LoanApplicationScreenContainer>
  );
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const VOICompletedDashboard = withAuthenticationRequired(
  VOICompletedDashboardBase,
);
