import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  FinancialDeclarationFragmentDoc,
  Top_Up_Reason_Enum,
  useGetFinancialDeclarationOptionsQuery,
  useGetFinancialDeclarationQuery,
} from '../../generated/graphql';
import { useGetExistingTopupAmount } from '../../LoanConfiguration/screens/graphql/queries';
import { TranslateKeys } from '../../localization/constants';

export const GET_FINANCIAL_DECLARATION_OPTIONS_QUERY = gql`
  query GetFinancialDeclarationOptions($loanApplicationId: uuid!) {
    refinancing_reason {
      reason
      description
    }
    retirement_repayment_plan {
      repayment_plan
      description
    }
    old_top_up_reasons: top_up_reason(
      where: {
        reason: {
          _in: [
            # Old UI only display these 4 reasons
            "PROPERTY"
            "FINANCIAL_INVESTMENT"
            "PERSONAL_USE"
            "OTHER"
          ]
        }
      }
    ) {
      ...TopUpReasonForOptions
    }
    new_top_up_reasons: top_up_reason(
      where: {
        reason: {
          _in: [
            # New UI has 5 reasons
            "YOUR_HOME"
            "INVESTMENT_PROPERTY"
            "FINANCIAL_INVESTMENT"
            "PERSONAL_USE"
            "OTHER"
          ]
        }
      }
    ) {
      ...TopUpReasonForOptions
    }

    financial_declaration_validation(loan_application_id: $loanApplicationId) {
      retired_before_end_of_loan
      is_top_up
      should_declare_substantial_benefit_consent
    }
  }

  fragment TopUpReasonForOptions on top_up_reason {
    reason
    description
    meta {
      order_weight
    }
  }
`;

export const GetFinancialDeclaration = gql`
  query GetFinancialDeclaration($loanApplicationId: uuid!) {
    financial_declaration(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...FinancialDeclaration
    }
  }

  ${FinancialDeclarationFragmentDoc}
`;

const maintainCurrentFinancialOptions = [
  {
    value: true,
    label: t('Content.FinancialDeclaration.NoExpectedChanges'),
  },
  {
    value: false,
    label: t('Content.FinancialDeclaration.ThereAreExpectedChanges'),
  },
];

export type TopUpReasonOptions = Array<{
  value: Top_Up_Reason_Enum | null;
  label: string;
  caption: string;
}>;
export function useFinancialDeclarationQuery(loanApplicationId?: string) {
  const { showOldCashoutUi, loading: showOldCashoutLoading } =
    useGetExistingTopupAmount({
      loanApplicationId: loanApplicationId || '',
    });

  const { data, loading, ...others } = useGetFinancialDeclarationOptionsQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    fetchPolicy: 'network-only',
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const {
    data: getFinancialDeclarationRes,
    loading: getFinancialDeclarationResLoading,
  } = useGetFinancialDeclarationQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    fetchPolicy: 'network-only',
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  // TODO Create tests to see if we didnt translate the enum when updated in DB
  const refinancingReasonOptions =
    data?.refinancing_reason.map(({ reason, description }) => ({
      value: reason,
      label: t(`Content.RefinancingReasonEnum.${reason}` as TranslateKeys, {
        defaultValue: description,
      }),
    })) || [];

  const retirementRepaymentPlanOptions =
    data?.retirement_repayment_plan.map(({ repayment_plan, description }) => ({
      value: repayment_plan,
      label: t(
        `Content.RetirementRepaymentPlanReasonEnum.${repayment_plan}.title` as TranslateKeys,
        {
          defaultValue: description,
        },
      ),
      caption: t(
        `Content.RetirementRepaymentPlanReasonEnum.${repayment_plan}.description` as TranslateKeys,
        {
          defaultValue: '',
        },
      ),
    })) || [];

  const topUpReasonOptions = useMemo(() => {
    const unsortedReasons =
      (showOldCashoutUi
        ? data?.old_top_up_reasons
        : data?.new_top_up_reasons) ?? [];
    const defaultWeight = 99;
    const sortedReasons = [...unsortedReasons].sort(
      (a, b) =>
        (a.meta?.order_weight || defaultWeight) -
        (b.meta?.order_weight || defaultWeight),
    );
    return sortedReasons.map(({ reason, description }) => ({
      value: reason,
      label: t(`Content.TopUpReasonEnum.${reason}.title` as TranslateKeys, {
        defaultValue: description,
      }),
      caption: t(
        `Content.TopUpReasonEnum.${reason}.description` as TranslateKeys,
        {
          defaultValue: '',
        },
      ),
    }));
  }, [data?.new_top_up_reasons, data?.old_top_up_reasons, showOldCashoutUi]);

  const financialDeclaration = data?.financial_declaration_validation;

  return {
    refinancingReasonOptions,
    retirementRepaymentPlanOptions,
    topUpReasonOptions,
    maintainCurrentFinancialOptions,
    loading: loading || showOldCashoutLoading,
    getFinancialDeclarationResLoading,
    isRetiredBeforeEndOfLoan: financialDeclaration?.retired_before_end_of_loan,
    isTopUp: financialDeclaration?.is_top_up,
    shouldDeclareSubstantialBenefitConsent:
      financialDeclaration?.should_declare_substantial_benefit_consent,
    financialDeclaration:
      getFinancialDeclarationRes?.financial_declaration?.[0],
    ...others,
  };
}
