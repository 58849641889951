import { Text, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { Card } from '../../../ui/atoms/Card';
import { Tooltip } from '../../../ui/atoms/Tooltip';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { EstimatedEquityPositionBar } from './EstimatedEquityPositionBar';

type Props = {
  equityPercentage: number;
  equityAmount: number;
  isThirdPartyValuation?: boolean;
};

export const EstimatedEquityPositionCard = ({
  equityAmount,
  equityPercentage,
  isThirdPartyValuation,
}: Props) => (
  <Card>
    <View sx={{ p: '$20' }}>
      <View sx={{ flexDirection: 'row', mb: '$4', alignItems: 'center' }}>
        <Text variant="xsHeader" sx={{ mr: '$4' }}>
          {t(
            'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityPositionHeader',
          )}
        </Text>
        <Tooltip
          content={t(
            'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityPositionInfo',
          )}
          iconSize="2s"
          iconColor="infoIcon"
          iconOpacity={1}
          testID={TestID.Insights.PropertyValue.EstimatedPropertyValueInfo}
        />
      </View>

      <View
        sx={{
          flexDirection: 'row',
          mb: '$8',
        }}
      >
        <Text variant="lHeader">{formatCurrency(equityAmount)}</Text>
        <Text sx={{ mt: '$2' }}>*</Text>
      </View>

      <EstimatedEquityPositionBar equityPercentage={equityPercentage} />

      <Text variant="sBody" sx={{ mt: '$24' }}>
        {isThirdPartyValuation
          ? t(
              'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityLabelWithThirdPartyValuation',
              {
                percentage: Math.round(equityPercentage),
              },
            )
          : t(
              'Content.HomeLoan.Insights.PropertyValue.EstimatedHomeEquityLabelWithOriginalValuation',
              {
                percentage: Math.round(equityPercentage),
              },
            )}
      </Text>
    </View>
  </Card>
);
