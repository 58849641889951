import { utcToZonedTime } from 'date-fns-tz';
import { H2, Text, useDripsyTheme, View } from 'dripsy';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Available_Statement, Statement_Type } from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { Skeleton } from '../../ui/v2/Skeleton';
import { AustralianTimezones, safelyFormatDate } from '../../utils/dateHelpers';
import { useStatementV2PeriodsQuery } from '../remoteData';

const STATEMENTS_DATE_FORMAT = 'dd MMM yyyy';

type Props = HomeTabScreenProps<Screen.HOME_LOAN_STATEMENTS>;

const StatementSkeleton = () => {
  const { theme } = useDripsyTheme();
  return (
    <Box>
      {[...Array(2).keys()].map((number, index) => (
        <View key={number} sx={{ mt: index !== 0 ? '$16' : undefined }}>
          <Skeleton show width="20%" height={theme.space.$20} />
          {[...Array(2).keys()].map((num) => (
            <DataRowGroup key={num}>
              <DataRow loading label="" caption="" />
            </DataRowGroup>
          ))}
        </View>
      ))}
    </Box>
  );
};

export function StatementsListV2({ navigation, route }: Props) {
  const { flags } = useContext(FeatureFlagsContext);

  const isStatementV2Enabled = flags.ENABLE_STATEMENTS_MODAL_V2;

  const { cbaAccountId } = route.params;
  const { theme } = useDripsyTheme();
  const {
    statements,
    startDate,
    endDate,
    loading: isLoadingStatementPeriods,
    error,
  } = useStatementV2PeriodsQuery(cbaAccountId);

  const isLoading = isLoadingStatementPeriods;

  const navigateToStatementsFilter = (
    initStartDate: number,
    initEndDate: number,
  ) => {
    navigation.navigate(Screen.STATEMENTS_V2_MODAL, {
      screen: Screen.STATEMENTS_FILTER_V2_MODAL,
      params: {
        cbaAccountId,
        startDate: initStartDate,
        endDate: initEndDate,
      },
    });
  };

  const statementsByYear = useMemo(() => {
    if (!statements || isEmpty(statements)) {
      return {};
    }
    return statements.reduce((acc, statement) => {
      const { year } = statement;
      const temp = acc[year];
      if (!temp) {
        acc[year] = [statement];
      } else {
        acc[year] = [...temp, statement].sort((a, b) => {
          if (a.type === Statement_Type.Eofy) {
            return -1;
          }

          if (a.type === b.type) {
            return b.start_date - a.start_date;
          }

          return 1;
        });
      }
      return acc;
    }, {} as Record<string, Available_Statement[]>);
  }, [statements]);

  const navigateToStatementPreview = (start: number, end: number) => {
    navigation.navigate(Screen.STATEMENTS_V2_MODAL, {
      screen: Screen.STATEMENTS_PREVIEW_V2_MODAL,
      params: {
        cbaAccountId,
        startDate: start,
        endDate: end,
      },
    });
  };

  const onPressBack = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN_SETTINGS, {
      cbaAccountId,
    });
  }, [cbaAccountId, navigation]);

  /**
   * TODO: Remove this once we have a proper error handling
   * currently force back to setting page if ff is off for statement screen v2
   */
  useEffect(() => {
    if (isStatementV2Enabled === false) {
      navigation.replace(Screen.HOME_LOAN_SETTINGS, { cbaAccountId });
    }
  }, [cbaAccountId, isStatementV2Enabled, navigation]);

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader title={t('Content.StatementsV2.Title')} />
      {isLoading ? (
        <StatementSkeleton />
      ) : (
        <>
          {error ? (
            <ErrorRow
              message={t('Content.StatementsV2.Error.FailFetchStatementsList')}
            />
          ) : null}
          {isEmpty(statements) ? (
            <Text
              variant="caption"
              sx={{
                textAlign: 'center',
                mb: '$24',
                mt: '$18',
              }}
            >
              {t('Content.StatementsV2.StatementsNotAvailable')}
            </Text>
          ) : (
            <>
              {Object.keys(statementsByYear)
                .sort()
                .reverse()
                .map((year, index) => (
                  <View key={year}>
                    <H2
                      variant="sHeader"
                      sx={{ mb: 0, mt: index !== 0 ? '$16' : 0 }}
                    >
                      {year}
                    </H2>
                    {statementsByYear[year]?.map(
                      ({ start_date, end_date, type }) => {
                        const formattedStartDate = `${safelyFormatDate(
                          utcToZonedTime(
                            start_date,
                            AustralianTimezones.SYDNEY,
                          ),
                          STATEMENTS_DATE_FORMAT,
                        )}`;
                        const formattedEndDate = `${safelyFormatDate(
                          utcToZonedTime(end_date, AustralianTimezones.SYDNEY),
                          STATEMENTS_DATE_FORMAT,
                        )}`;
                        return (
                          <DataRowGroup
                            key={`${formattedStartDate}-${formattedEndDate}-${type}`}
                            testID={`${formattedStartDate}-${formattedEndDate}-${type}`}
                            mt="s"
                          >
                            <DataRow
                              label={
                                type === Statement_Type.Eofy
                                  ? t(
                                      'Content.StatementsV2.StatementRowEOFYTitle',
                                    )
                                  : t('Content.StatementsV2.StatementRowTitle')
                              }
                              labelFontSize="s"
                              caption={`${formattedStartDate} - ${formattedEndDate}`}
                              onPress={() =>
                                navigateToStatementPreview(start_date, end_date)
                              }
                            />
                          </DataRowGroup>
                        );
                      },
                    )}
                  </View>
                ))}
            </>
          )}
          <Button
            label={t('Content.StatementsV2.GenerateAStatement')}
            py="m"
            width="100%"
            maxWidth="100%"
            color="primaryContent"
            testID={TestID.Statements.GenerateStatementButton}
            style={{
              backgroundColor: theme.colors.$buttonSecondaryBg,
              marginTop: theme.space.$32,
            }}
            onPress={() => navigateToStatementsFilter(startDate, endDate)}
          />
          <Text
            variant="caption"
            sx={{
              mt: '$8',
            }}
          >
            {t('Content.StatementsV2.GenerateAStatementDescription')}
          </Text>
        </>
      )}
    </UpliftScreenContainer>
  );
}
