import type { FullNameByApplicantIds } from '../../DebtsWizard/utils/types';
import { Liability_Type_Enum, Merged_Liability } from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { formatCurrency } from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { joinListOfString } from '../../utils/stringHelpers';

export function formatLiabilityLabel({
  institutionName,
  liabilityType,
}: {
  institutionName: string | undefined | null;
  liabilityType:
    | Liability_Type_Enum
    | Merged_Liability['dynamite_liability_type'];
}) {
  const type = parseEnumType(Liability_Type_Enum, liabilityType);
  const formattedInstitutionName = institutionName ? `${institutionName} ` : '';

  switch (type) {
    case Liability_Type_Enum.HomeLoan:
    case Liability_Type_Enum.Personal:
    case Liability_Type_Enum.LineOfCredit:
    case Liability_Type_Enum.MarginOrInvestmentLoan:
    case Liability_Type_Enum.PersonalOverdraft:
    case Liability_Type_Enum.Bnpl:
    case Liability_Type_Enum.CreditCard:
    case Liability_Type_Enum.ChargeCard:
    case Liability_Type_Enum.StoreCard:
    case Liability_Type_Enum.NovatedLease:
    case Liability_Type_Enum.Other:
      return `${formattedInstitutionName}${t(
        `Content.Common.LiabilityType.${type}`,
      )}`;
    case Liability_Type_Enum.HecsHelp:
      return `${t(`Content.Common.LiabilityType.${type}`)}`;
    default:
      return `${formattedInstitutionName}${t('Content.Common.Word.Unknown')}`;
  }
}

export function formatLiabilitySublabels({
  balance,
  limit,
  liabilityType,
  address,
}: {
  balance?: number | null;
  limit?: number | null;
  liabilityType: Liability_Type_Enum;
  address?: string | null;
}) {
  if (
    liabilityType === Liability_Type_Enum.Bnpl ||
    liabilityType === Liability_Type_Enum.ChargeCard ||
    liabilityType === Liability_Type_Enum.CreditCard ||
    liabilityType === Liability_Type_Enum.LineOfCredit ||
    liabilityType === Liability_Type_Enum.MarginOrInvestmentLoan ||
    liabilityType === Liability_Type_Enum.PersonalOverdraft ||
    liabilityType === Liability_Type_Enum.StoreCard
  ) {
    return [
      t('Content.YourDebts.Limit', {
        value: formatCurrency(limit, { noFraction: true }),
      }),
    ];
  }

  if (liabilityType === Liability_Type_Enum.HomeLoan) {
    const balanceRow = t('Content.YourDebts.Balance', {
      value: formatCurrency(balance, { noFraction: true }),
    });
    // TODO: Adjust sublabels spacing
    // The default one has zero padding/margin but the default
    // line height is giving too much spaces.
    // TODO: Make this always display address row
    // Need to update query on all screen that uses this
    return address ? [address, balanceRow] : [balanceRow];
  }
  if (
    liabilityType === Liability_Type_Enum.Other ||
    liabilityType === Liability_Type_Enum.Personal ||
    liabilityType === Liability_Type_Enum.NovatedLease ||
    liabilityType === Liability_Type_Enum.HecsHelp
  ) {
    return [
      t('Content.YourDebts.Balance', {
        value: formatCurrency(balance, { noFraction: true }),
      }),
    ];
  }
  return [
    t('Content.YourDebts.Balance', {
      value: formatCurrency(balance, { noFraction: true }),
    }),
  ];
}

export function formatOwners({
  fullNameByApplicantIds,
  applicantIds,
}: {
  fullNameByApplicantIds: FullNameByApplicantIds;
  applicantIds: Array<string> | undefined | null;
}) {
  const ownerNames =
    applicantIds
      ?.map((applicantId) => fullNameByApplicantIds[applicantId])
      .filter(isNotNullOrUndefined) || [];
  return ownerNames.length ? joinListOfString(ownerNames) : '--';
}
