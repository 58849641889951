import { createStackNavigator } from '@react-navigation/stack';

import { LoanConfigurationV2StackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { LoanConfigurationSelectRefiReasonScreenV2 } from '../screens/LoanConfigurationSelectRefiReasonScreenV2';
import { LoanConfigurationSetupLoanScreenV2 } from '../screens/LoanConfigurationSetupLoanScreenV2';

const StackV2 = createStackNavigator<LoanConfigurationV2StackParams>();

export function LoanConfigurationNavigatorV2() {
  return (
    <StackV2.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
      <StackV2.Screen
        name={Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON}
        component={LoanConfigurationSelectRefiReasonScreenV2}
        options={{
          title: getPageTitle(Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON),
        }}
      />
      <StackV2.Screen
        name={Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN}
        component={LoanConfigurationSetupLoanScreenV2}
        options={{
          title: getPageTitle(Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN),
        }}
      />
    </StackV2.Navigator>
  );
}
