import { Text, useSx, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormDateInputV2,
  FormSelectV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import { Id_Type_Enum, State_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { FormikFormError } from '../../ui/v2/FormError';
import { SelectForFormikProps } from '../../ui/v2/Select/SelectForFormik';
import {
  BorrowerDriversLicenceField,
  BorrowerDriversLicenceFormValues,
  BorrowerDriversLicenceInitialValues,
  BorrowerDriversLicenceLabel,
  BorrowerIdentityDetailsFormValues,
  getBorrowerDriversLicenceValidationSchema,
} from '../utils/borrowerFormUtil';

export type BorrowerDriverLicenceFormProps = {
  screen: Screen;
  stateOptions: SelectForFormikProps['items'];
} & Partial<{
  primaryBorrowerDriverLicenceNumber: string | null;
  initialValues: BorrowerDriversLicenceFormValues;
  isSubmitting: boolean;
  onSubmit: (values: BorrowerIdentityDetailsFormValues) => void;
  enableExpiryDate: boolean;
}>;

function getCardNoHelperText(state: State_Enum) {
  return t(`Content.Borrower.DriverLicenceForm.CardNumberHelpText.${state}`);
}

export function BorrowerDriverLicenceForm(
  props: BorrowerDriverLicenceFormProps,
) {
  const {
    screen,
    primaryBorrowerDriverLicenceNumber,
    initialValues = BorrowerDriversLicenceInitialValues,
    stateOptions,
    isSubmitting,
    onSubmit = () => {},
    enableExpiryDate = false,
  } = props;
  const sx = useSx();

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={() =>
        getBorrowerDriversLicenceValidationSchema(
          enableExpiryDate,
          primaryBorrowerDriverLicenceNumber,
        )
      }
      initialValues={{
        ...initialValues,
        identityType: Id_Type_Enum.DriversLicence,
      }}
    >
      {(formProps) => {
        const { stateOfIssue } = formProps.values;
        const hasValidState = stateOfIssue != null;

        return (
          <View pointerEvents={isSubmitting ? 'none' : undefined}>
            <Text variant="sHeader">
              {t('Content.Borrower.DriverLicenceForm.Title')}
            </Text>
            <FormSelectV2
              name={BorrowerDriversLicenceField.StateOfIssue}
              label={
                BorrowerDriversLicenceLabel[
                  BorrowerDriversLicenceField.StateOfIssue
                ]
              }
              testID={TestID.BorrowerDriverLicenceForm.StateOfIssueInput}
              items={stateOptions}
              sx={{ mt: '$16' }}
            />
            <FormikFormError
              name={BorrowerDriversLicenceField.StateOfIssue}
              sx={{ mt: '$8' }}
            />

            {hasValidState ? (
              <>
                <FormTextInputV2
                  name={BorrowerDriversLicenceField.LicenceNo}
                  label={
                    BorrowerDriversLicenceLabel[
                      BorrowerDriversLicenceField.LicenceNo
                    ]
                  }
                  inputTestID={TestID.BorrowerDriverLicenceForm.LicenceNoInput}
                  sx={{ mt: '$16' }}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Borrowers,
                    screen,
                    FieldInteractionKey.LicenceNumber,
                  )}
                />
                <FormikFormError
                  name={BorrowerDriversLicenceField.LicenceNo}
                  sx={{ mt: '$8' }}
                />
                {enableExpiryDate ? (
                  <>
                    <FormDateInputV2
                      name={BorrowerDriversLicenceField.ExpiryDate}
                      label={
                        BorrowerDriversLicenceLabel[
                          BorrowerDriversLicenceField.ExpiryDate
                        ]
                      }
                      inputTestID={
                        TestID.BorrowerDriverLicenceForm.ExpiryDateInput
                      }
                      sx={{ mt: '$16' }}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Borrowers,
                        screen,
                        FieldInteractionKey.ExpiryDate,
                      )}
                    />
                    <FormikFormError
                      name={BorrowerDriversLicenceField.ExpiryDate}
                      sx={{ mt: '$8' }}
                    />
                  </>
                ) : null}
                <FormTextInputV2
                  name={BorrowerDriversLicenceField.DriverLicenceCardNo}
                  label={
                    BorrowerDriversLicenceLabel[
                      BorrowerDriversLicenceField.DriverLicenceCardNo
                    ]
                  }
                  inputTestID={
                    TestID.BorrowerDriverLicenceForm.DriverLicenceCardNoInput
                  }
                  sx={{ mt: '$16' }}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Borrowers,
                    screen,
                    FieldInteractionKey.CardNumber,
                  )}
                />
                <FormikFormError
                  name={BorrowerDriversLicenceField.DriverLicenceCardNo}
                  sx={{ mt: '$8' }}
                />
                <Text variant="caption" sx={{ my: '$8' }}>
                  {getCardNoHelperText(stateOfIssue)}
                  {stateOfIssue === State_Enum.Vic && (
                    <Link
                      style={sx({ variant: 'text.caption', color: '$link' })}
                      href={t(`Link.VerifyDriverLicenceVIC`)}
                    >
                      {t(
                        'Content.Borrower.DriverLicenceForm.DriverLicenceCardFindDetails',
                      )}
                    </Link>
                  )}
                </Text>
              </>
            ) : null}
            <Button
              onPress={() => formProps.handleSubmit()}
              label={t('Content.Common.ButtonLabel.Done')}
              alignSelf="stretch"
              mt="xl"
              disabled={!hasValidState || isSubmitting}
              showSpinner={isSubmitting}
              // TODO: Change test ID button for automation
              testID={TestID.BorrowerDriverLicenceForm.DoneButton}
            />
          </View>
        );
      }}
    </Formik>
  );
}
