import { useDripsyTheme } from 'dripsy';
import { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Change_Request_Status_Enum,
  useGetExternalAccountsQuery,
} from '../../generated/graphql';
import { useLoanAccountDirectDebitSettings } from '../../HomeLoan/graphql/loanAccountQueries';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import {
  autopayCancellationStatusAtom,
  autopayChangeFormAtom,
} from '../autopayAtom';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { formatUnloanAccountSubtitle } from '../utils/formatUnloanAccountSubtitle';

type Props = AutopaySettingsScreenProps<Screen.AUTOPAY_EXISTING_SETTINGS>;

// If autopay has been setup this screen is shown..
// It displays the autopay settings which are read from Mambu.

export function AutopayExistingSettings({ navigation, route }: Props) {
  const { cbaAccountId } = route.params;

  const { flags } = useContext(FeatureFlagsContext);

  const autopayV2Enabled = flags.ENABLE_AUTOPAY_MODAL_V2;

  const { data, loading: loanAccountDirectDebitSettingsLoading } =
    useLoanAccountDirectDebitSettings(cbaAccountId);

  const { data: loanAccountNameData, loading: loanAccountNameLoading } =
    useGetLoanAccountName(cbaAccountId);

  const { data: externalAccountsData, loading: externalAccountsLoading } =
    useGetExternalAccountsQuery();

  const loading =
    loanAccountDirectDebitSettingsLoading ||
    loanAccountNameLoading ||
    externalAccountsLoading;

  const { accountName = '', bsbJoinedWithAccountNumber = '' } =
    loanAccountNameData ?? {};

  const autopaySettings = data?.autopay;

  const externalAccounts = useMemo(
    () =>
      externalAccountsData?.me?.[0]?.user?.identity_profile
        ?.external_accounts ?? [],
    [externalAccountsData?.me],
  );

  const externalAccount = externalAccounts.find(
    (account) =>
      account.account_number === autopaySettings?.direct_debit_account &&
      account.account_bsb === autopaySettings?.direct_debit_bsb,
  );

  const resetAutopayChangeForm = useResetRecoilState(autopayChangeFormAtom);
  const autopayCancellationStatus = useRecoilValue(
    autopayCancellationStatusAtom,
  );

  const onPressChangeAutoPay = useCallback(() => {
    // Reset form values
    resetAutopayChangeForm();

    if (autopayV2Enabled && autopaySettings) {
      navigation.replace(Screen.AUTOPAY_SETTINGS_MODAL, {
        screen: Screen.AUTOPAY_EDIT,
        params: {
          cbaAccountId,
          autopaySettings,
        },
      });
    } else {
      navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
        screen: Screen.AUTOPAY_SETUP,
        params: {
          cbaAccountId,
        },
      });
    }
  }, [
    resetAutopayChangeForm,
    autopayV2Enabled,
    autopaySettings,
    navigation,
    cbaAccountId,
  ]);

  const onPressCancelAutoPay = useCallback(() => {
    navigation.replace(ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION, {
      cbaAccountId,
    });
  }, [cbaAccountId, navigation]);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const { theme } = useDripsyTheme();

  return (
    <ModalScreenContainer
      headerText={t('Content.AutopaySettings.AutoPayHeader')}
      onClose={onClose}
      scrollable
      hideBackButton
    >
      {autopayCancellationStatus === Change_Request_Status_Enum.Failed ? (
        <ErrorRow
          mb="l"
          message={t('Content.AutopaySettings.CancellingAutopayFailure')}
        />
      ) : null}
      {loading ? <Skeleton show height={theme.space.$20} width={240} /> : null}
      {!loading && (
        <StyledText variant="body">
          {formatUnloanAccountSubtitle({
            accountName,
            bsbJoinedWithAccountNumber,
          })}
        </StyledText>
      )}
      <Separator spacer py="s" />

      <DataRowGroup mt={0}>
        <DataRow
          loading={loading}
          label={t('Content.Approvals.Status')}
          caption={t('Content.AutopaySettings.Active')}
        />
      </DataRowGroup>
      <DataRowGroup>
        <DataRow
          loading={loading}
          label={t('Content.AutopaySettings.Amount')}
          caption={
            autopaySettings?.repayment_amount
              ? formatCurrency(autopaySettings?.repayment_amount)
              : ''
          }
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label={t('Content.AutopaySettings.FromAccount')}
          caption={
            <>
              {externalAccount?.account_name}

              <Separator spacer py={0} />

              {formatBsbAndAccountNumber(
                autopaySettings?.direct_debit_bsb ?? '',
                autopaySettings?.direct_debit_account ?? '',
              )}
            </>
          }
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label={t('Content.AutopaySettings.ToAccount')}
          caption={
            <>
              {accountName}
              {bsbJoinedWithAccountNumber ? (
                <>
                  <Separator spacer py={0} />
                  {bsbJoinedWithAccountNumber}
                </>
              ) : null}
            </>
          }
        />
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label={t('Content.AutopaySettings.Frequency')}
          caption={autopaySettings?.repayment_frequency}
        />
      </DataRowGroup>
      <Box
        mt="l"
        mb="m"
        flexDirection="row"
        bottom={0}
        justifyContent="space-between"
        alignItems="stretch"
        opacity={loading ? 0 : undefined}
      >
        <Button
          secondary
          label={t('Content.AutopaySettings.ChangeAutopay')}
          flexGrow={1}
          onPress={onPressChangeAutoPay}
          disabled={loading}
        />
        <Button
          secondary
          label={t('Content.AutopaySettings.CancelAutopay')}
          color="error"
          flexGrow={1}
          disabled={
            loading ||
            autopayCancellationStatus === Change_Request_Status_Enum.Applied
          }
          ml="s"
          onPress={onPressCancelAutoPay}
        />
      </Box>
    </ModalScreenContainer>
  );
}
