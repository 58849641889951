import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const TickIconV2: React.FC<SvgIconProps> = ({
  color: colorProp,
  size,
  ...props
}) => {
  const theme = useTheme();
  const color = colorProp ?? theme.colors.accent;
  return (
    <Svg
      width={resizeIconWidth(18, 18, size)}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <Path
        fill={color}
        d="M7.364 15.047c-.342 0-.594-.143-.834-.437l-3.54-4.43c-.172-.212-.247-.41-.247-.615 0-.472.349-.814.827-.814.301 0 .506.11.704.376l3.063 3.931 5.94-9.433c.205-.322.404-.438.739-.438.478 0 .806.328.806.8 0 .184-.054.376-.198.595L8.198 14.603a.947.947 0 0 1-.834.444Z"
      />
    </Svg>
  );
};
