import { useRestyle } from '@shopify/restyle';
import { forwardRef } from 'react';

import {
  TypographyProps,
  typographyRestyleFunctions,
} from '../../ui/atoms/StyledText';
import {
  ListRowGroup,
  Props as ListRowGroupProps,
} from '../../ui/molecules/ListRowGroup';
import { InputRow, Props as InputRowProps } from '../../ui/organisms/InputRow';
import { Theme } from '../../ui/theme';
import {
  PhoneInput,
  PhoneNumberData,
  Props as PhoneInputProps,
} from './PhoneInput';
import { FocusingInputRef, FormikControlProps } from './types';

export type Props = Pick<
  ListRowGroupProps,
  | 'headerText'
  | 'uppercaseHeader'
  | 'footerErrorMessage'
  | 'footerErrorMessageTestID'
> &
  Omit<InputRowProps, 'ref'> &
  TypographyProps &
  Omit<PhoneInputProps, 'value' | 'onChangePhoneNumber' | 'error'> &
  FormikControlProps<PhoneNumberData>;

export const PhoneInputRow = forwardRef<FocusingInputRef, Props>(
  (
    {
      setFieldValue,
      setFieldTouched,
      value,
      error,
      placeholder,
      headerText,
      uppercaseHeader = true,
      footerErrorMessage,
      footerErrorMessageTestID,
      testID,
      ...props
    },
    ref,
  ) => {
    const passedProps = useRestyle<Theme, TypographyProps, Props>(
      typographyRestyleFunctions,
      props,
    );

    return (
      <ListRowGroup
        headerText={headerText}
        uppercaseHeader={uppercaseHeader}
        footerErrorMessage={footerErrorMessage}
        footerErrorMessageTestID={footerErrorMessageTestID}
        overflow="visible"
        zIndex="up"
      >
        <InputRow
          fixedLabel
          {...props}
          error={error?.phoneNumber || error?.isValid}
          overflow="visible"
          borderRadius="card"
        >
          <PhoneInput
            style={passedProps.style}
            placeholder={
              placeholder || t('Content.Common.Placeholder.MobileNumber')
            }
            onBlur={(event) => {
              setFieldTouched?.(Boolean(event));
            }}
            value={value}
            onChangePhoneNumber={(phoneNumber) => {
              setFieldValue?.(phoneNumber);
            }}
            ref={ref}
            testID={testID}
          />
        </InputRow>
      </ListRowGroup>
    );
  },
);

PhoneInputRow.defaultProps = {
  fontSize: 'm',
  fontWeight: 'normal',
  noBorder: true,
};
