// TODO: change file name
import { Asserts } from 'yup';

import {
  AppAddressFormat,
  PickerOption,
  yupAddress,
} from '../../../components/form/types';
import { State_Enum } from '../../../generated/graphql';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames,
} from '../../../ui/v2/ManualPropertyInputFields';
import { yup, yupEmail, yupMobileNumber, yupName } from '../../../utils/yup';

export enum InviteCoBorrowerField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Mobile = 'mobile',
  Email = 'email',
}

export const InviteCoBorrowerLabel = {
  [InviteCoBorrowerField.FirstName]: t(
    'Content.InviteCoBorrower.Form.FirstName',
  ),
  [InviteCoBorrowerField.LastName]: t('Content.InviteCoBorrower.Form.Surname'),
  [InviteCoBorrowerField.Mobile]: t(
    'Content.InviteCoBorrower.Form.MobileNumber',
  ),
  [InviteCoBorrowerField.Email]: t(
    'Content.InviteCoBorrower.Form.EmailAddress',
  ),
};

export const InviteCoBorrowerValidationSchema = yup.object({
  [InviteCoBorrowerField.FirstName]: yupName.required(),
  [InviteCoBorrowerField.LastName]: yupName.required(),
  [InviteCoBorrowerField.Mobile]: yupMobileNumber.required(),
  [InviteCoBorrowerField.Email]: yupEmail.required(),
});

export const InviteCoborrowerInitialValues: InviteCoBorrowerFormValues = {
  [InviteCoBorrowerField.Mobile]: {
    phoneNumber: '',
    countryIso: 'AU',
    countryPrefix: '+61',
    isValid: true,
  },
  [InviteCoBorrowerField.Email]: '',
  [InviteCoBorrowerField.FirstName]: '',
  [InviteCoBorrowerField.LastName]: '',
};
export type InviteCoBorrowerFormValues = Partial<
  Asserts<typeof InviteCoBorrowerValidationSchema>
>;

export enum BorrowerDetailsEditField {
  MailingAddress = 'mailingAddress',
}

export const manualMailingAddressPrefix = 'manualMailingAddressInput';
export const manualMailingAddressInputFieldNames = getManualInputFieldNames(
  manualMailingAddressPrefix,
);

export const BorrowerDetailsEditLabel = {
  [BorrowerDetailsEditField.MailingAddress]: t(
    'Content.Borrower.YourInformation.Details.MailingAddress',
  ),
};

const BorrowerDetailsEditValidationObject = {
  [BorrowerDetailsEditField.MailingAddress]: yup
    .object()
    .when(manualMailingAddressInputFieldNames.isManualInput, {
      is: true,
      then: yup.object().nullable(),
      otherwise: yupAddress().nullable().required(),
    }),
};
export const getBorrowerDetailsEditValidationSchema = () =>
  yup
    .object(BorrowerDetailsEditValidationObject)
    .concat(getManualAddressYupSchema(manualMailingAddressPrefix));

export type BorrowerDetailsEditFormValues = {
  [BorrowerDetailsEditField.MailingAddress]: AppAddressFormat | null;
} & Partial<{
  [manualMailingAddressInputFieldNames.isManualInput]: boolean;
  [manualMailingAddressInputFieldNames.postcode]: string;
  [manualMailingAddressInputFieldNames.state]: State_Enum;
  [manualMailingAddressInputFieldNames.streetName]: string;
  [manualMailingAddressInputFieldNames.streetNo]: string;
  [manualMailingAddressInputFieldNames.streetType]: PickerOption<string>;
  [manualMailingAddressInputFieldNames.suburb]: string;
  [manualMailingAddressInputFieldNames.unitNo]: string;
}>;

export const BorrowerDetailsEditIntialValues: BorrowerDetailsEditFormValues = {
  [BorrowerDetailsEditField.MailingAddress]: null,
  [manualMailingAddressInputFieldNames.isManualInput]: undefined,
  [manualMailingAddressInputFieldNames.postcode]: undefined,
  [manualMailingAddressInputFieldNames.state]: undefined,
  [manualMailingAddressInputFieldNames.streetName]: undefined,
  [manualMailingAddressInputFieldNames.streetNo]: undefined,
  [manualMailingAddressInputFieldNames.streetType]: undefined,
  [manualMailingAddressInputFieldNames.suburb]: undefined,
  [manualMailingAddressInputFieldNames.unitNo]: undefined,
};
