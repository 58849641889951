import { FormikErrors, FormikTouched } from 'formik';

import { MaxValue, MinValue } from '../constants/fieldRules';
import { formatCurrency } from './currencyHelpers';
import { joinListOfString } from './stringHelpers';
import { assertUnreachable } from './typesHelpers';

export const FieldValidationError = {
  ExceededMaxMoney: 'ExceededMaxMoney',
  LessThanMinMoney: 'LessThanMinMoney',
  Required: 'Required',
  InvalidName: 'InvalidName',
  InvalidPhoneNumber: 'InvalidPhoneNumber',
  InvalidEmail: 'InvalidEmail',
  UnsupportedExternalAccount: 'UnsupportedExternalAccount',
  InvalidNumericString: 'InvalidNumericString',
  InvalidAlphanumericString: 'InvalidAlphanumericString',
  InvalidDOB: 'InvalidDOB',
  InvalidDateFormat: 'InvalidDateFormat',
  AgeRequirement: 'AgeRequirement',
  DuplicateFullName: 'DuplicateFullName',
} as const;

type FieldName = string;
export type FieldValidationErrorType = keyof typeof FieldValidationError;

export function buildErrorMessage(
  errorType: string,
  fieldNames: Array<string>,
) {
  const joinedFieldName = joinListOfString(fieldNames);

  const fieldCount = fieldNames.length;

  const castedErrorType = errorType as FieldValidationErrorType;

  switch (castedErrorType) {
    case FieldValidationError.InvalidAlphanumericString:
      return t(
        'Content.Common.Error.FieldValidationError.InvalidAlphanumericString',
        {
          field: joinedFieldName,
        },
      );
    case FieldValidationError.InvalidNumericString:
      return t(
        'Content.Common.Error.FieldValidationError.InvalidNumericString',
        {
          field: joinedFieldName,
        },
      );
    case FieldValidationError.ExceededMaxMoney:
      return t('Content.Common.Error.FieldValidationError.ExceededMaxMoney', {
        field: joinedFieldName,
        maxValue: formatCurrency(MaxValue.number),
      });
    case FieldValidationError.LessThanMinMoney:
      return t('Content.Common.Error.FieldValidationError.LessThanMinMoney', {
        field: joinedFieldName,
        minValue: formatCurrency(MinValue.money),
      });
    case FieldValidationError.InvalidEmail:
    case FieldValidationError.InvalidName:
    case FieldValidationError.InvalidPhoneNumber:
      return t(
        'Content.Common.Error.FieldValidationError.GeneralInvalidFormatPluralization',
        {
          field: joinedFieldName,
          count: fieldCount,
        },
      );

    case FieldValidationError.Required:
      return t(
        'Content.Common.Error.FieldValidationError.RequiredPluralization',
        {
          field: joinedFieldName,
          count: fieldCount,
        },
      );
    case FieldValidationError.UnsupportedExternalAccount:
      return t(
        'Content.Common.Error.FieldValidationError.UnsupportedExternalAccount',
      );
    case FieldValidationError.InvalidDateFormat:
      return t('Content.Common.Error.FieldValidationError.InvalidDateFormat', {
        field: joinedFieldName,
      });
    case FieldValidationError.DuplicateFullName:
      return t('Content.Borrower.DetailsForm.Error.DuplicateFullName');
    case FieldValidationError.InvalidDOB:
      return t('Content.InformationForm.FormError.InvalidDOB');
    case FieldValidationError.AgeRequirement:
      return t('Content.InformationForm.FormError.AgeRequirement');
    default:
      assertUnreachable(castedErrorType);
      return castedErrorType;
  }
}

/**
 * This function will return grouped error message for all touched fields (i.e it will ignore any fields that has never been pressed / focused)
 * @param errors Errors coming from Formik. It will be passed in this format: `{[fieldName]: FieldValidationError}`
 * @param fieldLabelList List of user-friendly label for each field (e.g: firstName is our field name, the label would be "First Name")
 * @param touchedFieldList List of field that has been touched / has been being focused on
 * @returns Grouped error message (e.g: "First Name and Surname is required")
 */
export function groupFormikErrors<T extends Record<string, unknown>>(
  errors: FormikErrors<T> | undefined,
  fieldLabelList: Partial<Record<keyof T, string>>,
  touchedFieldList: FormikTouched<T> | undefined,
) {
  if (errors == null) {
    return null;
  }

  const groupedErrorByTypeMap = new Map<string, Array<FieldName>>();
  Object.entries(errors).forEach(([fieldName, errorType]) => {
    const displayFieldName = fieldLabelList[fieldName];
    if (!touchedFieldList?.[fieldName] || !displayFieldName) {
      // do not generate the error message for untouched fields / if there is no specified display field name
      return;
    }
    const existingErrors = groupedErrorByTypeMap.get(errorType);

    if (existingErrors) {
      groupedErrorByTypeMap.set(errorType, [
        ...existingErrors,
        displayFieldName,
      ]);
    } else {
      groupedErrorByTypeMap.set(errorType, [displayFieldName]);
    }
  });
  const groupedErrorMessages = Array.from(
    groupedErrorByTypeMap,
    ([errorType, fieldNames]) => buildErrorMessage(errorType, fieldNames),
  );
  return groupedErrorMessages.join(' ');
}
