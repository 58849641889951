import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const TransferIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fill={color}
      d="M4.093 24C1.37 24 0 22.657 0 19.959V4.04C0 1.343 1.369 0 4.093 0h15.814C22.644 0 24 1.356 24 4.041V19.96C24 22.644 22.644 24 19.907 24H4.093Zm.027-2.099h15.76c1.291 0 2.021-.69 2.021-2.047V4.146c0-1.356-.73-2.047-2.02-2.047H4.12c-1.304 0-2.021.69-2.021 2.047v15.708c0 1.356.717 2.047 2.02 2.047Zm14.6-9.934c0 .287-.13.522-.378.756l-4.406 4.42a.944.944 0 0 1-.678.274.93.93 0 0 1-.939-.952c0-.287.105-.521.287-.691l1.643-1.616 1.564-1.317-2.75.091H6.256c-.56 0-.964-.404-.964-.965 0-.573.404-.977.964-.977h6.806l2.75.104-1.564-1.317-1.643-1.616a1.017 1.017 0 0 1-.287-.704c0-.535.404-.939.939-.939.274 0 .508.091.678.26l4.406 4.433c.26.261.378.47.378.756Z"
    />
  </Svg>
);
