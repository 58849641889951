import { SxProp } from 'dripsy';

import { ErrorRow } from '../../../components/ErrorRow';
import { SetupLoanPurchaseErrorBannerType } from '../../utils/setupLoanPurchaseErrorBannerReducer';
import { TryAgainErrorRow } from './TryAgainErrorRow';

type Props = {
  type: SetupLoanPurchaseErrorBannerType;
  message: string | null;
  testID?: string;
  sx?: SxProp;

  retryLmiQuoteCalculation: () => void;
  retryLmiQuoteCalculationLoading: boolean;

  retrySecurityCostCalculation: () => void;
  retrySecurityCostCalculationLoading: boolean;
};

export function SetupLoanPurchaseErrorBanner({
  type,
  message,
  testID,
  sx: sxProp,

  retryLmiQuoteCalculation,
  retryLmiQuoteCalculationLoading,

  retrySecurityCostCalculation,
  retrySecurityCostCalculationLoading,
}: Props) {
  if (type === SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationFailed) {
    return (
      <TryAgainErrorRow
        onTryAgainPress={retryLmiQuoteCalculation}
        sx={sxProp}
        tryAgainDisabled={retryLmiQuoteCalculationLoading}
        tryAgainLoading={retryLmiQuoteCalculationLoading}
        testID={testID}
      />
    );
  }

  if (type === SetupLoanPurchaseErrorBannerType.SecurityCostCalculationError) {
    return (
      <TryAgainErrorRow
        onTryAgainPress={retrySecurityCostCalculation}
        sx={sxProp}
        tryAgainDisabled={retrySecurityCostCalculationLoading}
        tryAgainLoading={retrySecurityCostCalculationLoading}
        testID={testID}
      />
    );
  }

  return <ErrorRow message={message} sx={sxProp} testID={testID} />;
}
