import { Text, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { StyledIcon } from '../../../ui/atoms/StyledIcon';

type Props = {
  shortFormatAddress: string;
  addressState: string;
  bedrooms?: number;
  bathrooms?: number;
  garages?: number;
  propertyType?: string;
};

const PropertyFeature = ({ icon, value }: { icon: string; value: string }) => (
  <View sx={{ flexDirection: 'row', alignItems: 'center', mr: '$8' }}>
    <StyledIcon name={icon} mr="xs" family="svg" size="tiny" />
    <Text variant="caption" sx={{ ml: '$2', color: '$labelsPrimary' }}>
      {value}
    </Text>
  </View>
);

export const PropertyDetails = ({
  shortFormatAddress,
  addressState,
  bedrooms,
  bathrooms,
  garages,
  propertyType,
}: Props) => (
  <View>
    <Text variant="xsHeader">{shortFormatAddress},</Text>
    <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
      {addressState}
    </Text>
    {bedrooms || bathrooms || garages || propertyType ? (
      <View
        sx={{ flexDirection: 'row', alignItems: 'center', mt: '$8' }}
        testID={TestID.Insights.PropertyValue.PropertyFeatures}
      >
        <PropertyFeature
          icon="bed"
          value={bedrooms ? bedrooms.toString() : '-'}
        />
        <PropertyFeature
          icon="bath"
          value={bathrooms ? bathrooms.toString() : '-'}
        />
        <PropertyFeature
          icon="car"
          value={garages ? garages.toString() : '-'}
        />
        <Text sx={{ mr: '$8' }}>•</Text>
        <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
          {propertyType}
        </Text>
      </View>
    ) : null}
  </View>
);
