import { RowRight } from '../../ui/atoms/RowRight';
import { StyledTextInput } from '../../ui/atoms/StyledTextInput';
import { ListRow, Props as ListRowProps } from '../../ui/molecules/ListRow';
import { Stepper } from '../../ui/molecules/Stepper';
import { stripNonDigit } from '../../utils/currencyHelpers';
import { toInt } from '../../utils/numberHelpers';
import { FormikControlProps } from './types';

export type Props = FormikControlProps<number | undefined> &
  ListRowProps & {
    minimumValue?: number;
    inputTestID?: string;
    decreaseButtonTestID?: string;
    increaseButtonTestID?: string;
  };

const StepperRowTextInputWidth = 40;
export function StepperRow({
  value,
  setFieldValue,
  minimumValue = 0,
  inputTestID,
  decreaseButtonTestID,
  increaseButtonTestID,
  ...props
}: Props) {
  // Set default value from prop so we can still count this when `value` is `undefined`
  const valueNum = value || 0;
  const onDecreasePress = () => {
    setFieldValue?.(Math.max(valueNum - 1, minimumValue));
  };

  const onIncreasePress = () => {
    setFieldValue?.(valueNum + 1);
  };

  const onChangeText = (input: string) => {
    // When `input` is falsy (e.g: empty string), set field value to `undefined`
    if (!input) {
      setFieldValue?.(undefined);
      return;
    }
    const digitOnly = stripNonDigit(input);
    setFieldValue?.(toInt(digitOnly));
  };

  const onBlur = () => {
    if (value == null) {
      // Set field value to 0 when value is undefined / null when blurred
      setFieldValue?.(toInt(value));
    }
  };

  return (
    <ListRow {...props}>
      <RowRight row alignItems="center" flex={1} justifyContent="flex-end">
        <StyledTextInput
          mr="s"
          color="secondaryContent"
          value={value != null ? String(value) : ''}
          onChangeText={onChangeText}
          onBlur={onBlur}
          noFlex
          textAlign="right"
          keyboardType="numeric"
          py={0}
          testID={inputTestID}
          width={StepperRowTextInputWidth}
        />
        <Stepper
          onDecreasePress={onDecreasePress}
          onIncreasePress={onIncreasePress}
          decreaseButtonTestID={decreaseButtonTestID}
          increaseButtonTestID={increaseButtonTestID}
        />
      </RowRight>
    </ListRow>
  );
}
