import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
} from '@react-navigation/native';
import * as Linking from 'expo-linking';

import {
  LOAN_APPLICATION_PATH,
  LOAN_APPLICATION_TRACKING_PATH,
  LOAN_TOP_UP_PATH,
} from './constants';
import { NavigatorFullScreenModalScreen } from './NavigatorFullScreenModal';
import { NavigatorModalScreen } from './NavigatorModal';
import { PathConfigMapWrapped } from './types/linkingConfigTypes';
import {
  ActionSheetStackParams,
  AppStackParams,
  StandaloneModalStackParams,
} from './types/navTypes';
import { ActionSheetType, Screen } from './types/screens';
import { loanApplicationIdParamParser } from './utils/loanApplicationIdParser';
import { parseApplicantToken } from './utils/parseApplicantToken';
import { parseParam } from './utils/parseParam';
import { shouldHideScreenFromPath } from './utils/shouldHideScreenFromPath';

const prefixes = [Linking.createURL('/')];

const actionSheetLinkingConfig: PathConfigMapWrapped<ActionSheetStackParams> = {
  [ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE]: {
    path: ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE,
  },
  [ActionSheetType.ENV_SWITCHER]: {
    path: ActionSheetType.ENV_SWITCHER,
  },
  [ActionSheetType.SOCIAL_SIGN_IN]: {
    path: ActionSheetType.SOCIAL_SIGN_IN,
  },
  [ActionSheetType.DEVELOPER_TOOLS]: {
    path: ActionSheetType.DEVELOPER_TOOLS,
  },
  [ActionSheetType.DISCONNECT_BANK]: {
    path: ActionSheetType.DISCONNECT_BANK,
  },
  [ActionSheetType.WITHDRAW_CONFIRMATION]: {
    path: ActionSheetType.WITHDRAW_CONFIRMATION,
  },
  [ActionSheetType.CA_CONFIRMATION]: {
    path: ActionSheetType.CA_CONFIRMATION,
  },
  [ActionSheetType.CONFIRMATION_SUCCESS]: {
    path: ActionSheetType.CONFIRMATION_SUCCESS,
  },
  [ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION]: {
    path: ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION,
  },
  [ActionSheetType.TRANSFER_LIMIT_REACHED]: {
    path: ActionSheetType.TRANSFER_LIMIT_REACHED,
  },
  [ActionSheetType.REMOVE_CO_BORROWER_V2]: {
    path: ActionSheetType.REMOVE_CO_BORROWER_V2,
  },
  [ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2]: {
    path: ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2,
  },
  [ActionSheetType.TRANSACTION_HISTORY]: {
    path: ActionSheetType.TRANSACTION_HISTORY,
  },
  [ActionSheetType.REMOVE_PROPERTY_V2]: {
    path: ActionSheetType.REMOVE_PROPERTY_V2,
  },
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION]: {
    path: ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION,
  },
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2]: {
    path: ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2,
  },
  [ActionSheetType.NO_DEBTS_CONFIRMATION]: {
    path: ActionSheetType.NO_DEBTS_CONFIRMATION,
  },
  [ActionSheetType.LOW_EXPENSES]: {
    path: ActionSheetType.LOW_EXPENSES,
  },
  [ActionSheetType.AUTOPAY_CONFIRMATION]: {
    path: ActionSheetType.AUTOPAY_CONFIRMATION,
  },
  [ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION]: {
    path: ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION,
  },
  [ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2]: {
    path: ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2,
  },
  [ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION]: {
    path: ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION,
  },
  [ActionSheetType.STOP_DATA_SHARE_CONFIRMATION]: {
    path: ActionSheetType.STOP_DATA_SHARE_CONFIRMATION,
  },
  [ActionSheetType.CREDIT_CHECK_CONFIRMATION]: {
    path: ActionSheetType.CREDIT_CHECK_CONFIRMATION,
  },
  [ActionSheetType.DEBTS_MANUAL_ENTRY_REQUIRED]: {
    path: ActionSheetType.DEBTS_MANUAL_ENTRY_REQUIRED,
  },
  [ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION]: {
    path: ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION,
  },
  [ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE]: {
    path: ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE,
  },
  [ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET]: {
    path: ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET,
  },
  [ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET]: {
    path: ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET,
  },
  [ActionSheetType.COPY_BSB_ACCOUNT_NUMBER]: {
    path: ActionSheetType.COPY_BSB_ACCOUNT_NUMBER,
  },
  [ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3]: {
    path: ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3,
  },
  [ActionSheetType.DELETE_EXPENSE_ACTION_SHEET]: {
    path: ActionSheetType.DELETE_EXPENSE_ACTION_SHEET,
  },
  [ActionSheetType.DELETE_INCOME_ACTION_SHEET]: {
    path: ActionSheetType.DELETE_INCOME_ACTION_SHEET,
  },
  [ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET]: {
    path: ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET,
  },
  [ActionSheetType.FLAG_DETECTED_LIABILITY]: {
    path: ActionSheetType.FLAG_DETECTED_LIABILITY,
  },
  [ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET]: {
    path: ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET,
  },
  [ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET]: {
    path: ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET,
  },
  [ActionSheetType.UPDATE_APPLICATION_CONFIRMATION]: {
    path: ActionSheetType.UPDATE_APPLICATION_CONFIRMATION,
  },
  // Principal Reduction Action Sheets
  [ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST,
  },
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED]: {
    path: ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED,
  },
  // Account Permissions Action Sheets
  [ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES]: {
    path: ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES,
  },
  [ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE]: {
    path: ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE,
  },
  [ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED]: {
    path: ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED,
  },
  [ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL]: {
    path: ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL,
  },
  [ActionSheetType.ACCOUNT_PERMISSIONS_APPLIED]: {
    path: ActionSheetType.ACCOUNT_PERMISSIONS_APPLIED,
  },
};

/**
 * V1 standalone modals
 */
const standaloneLinkingConfig: PathConfigMapWrapped<StandaloneModalStackParams> =
  {
    [Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL]: {
      path: Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL,
    },
    [Screen.ACCEPTABLE_DOCUMENTS_MODAL]: {
      path: Screen.ACCEPTABLE_DOCUMENTS_MODAL,
    },
    [Screen.DOCUMENT_CENTRE_MODAL]: { path: Screen.DOCUMENT_CENTRE_MODAL },
    [Screen.DOCUMENT_PREVIEW_MODAL]: { path: Screen.DOCUMENT_PREVIEW_MODAL },
    [Screen.LEARN_OPEN_BANKING_MODAL]: {
      path: Screen.LEARN_OPEN_BANKING_MODAL,
    },

    [Screen.JOIN_APPLICATION_MODAL]: {
      path: `join-application/:token`,
      parse: {
        token: parseApplicantToken,
      },
    },
    [Screen.APPROVAL_RESPONSE_MODAL]: {
      path: `approval-required/:approvalResponseId`,
    },
  };

/**
 * The `path` in the linking config affect
 * the URL on the web platform.
 *
 * URL on web are used by:
 * - GTM
 * - Intercom
 * which relies on regex to do matching with the URL.
 *
 * This means, after changing `path`, please make sure to
 * contact marketing team to tell them the URL will be
 * updated before deploying to prod.
 */
const linkingConfig: PathConfigMapWrapped<AppStackParams> = {
  ...actionSheetLinkingConfig,
  ...standaloneLinkingConfig,
  [Screen.MAIN_NAVIGATOR]: {
    path: '/',
    initialRouteName: Screen.HOME_DASHBOARD,
    screens: {
      [Screen.HOME_DASHBOARD]: {
        path: '',
        initialRouteName: Screen.HOME,
        screens: {
          [Screen.HOME]: {
            path: '',
          },
          [Screen.HOME_LOAN_SETTINGS]: {
            path: 'account/:cbaAccountId/settings',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.HOME_LOAN_STATEMENTS]: {
            path: 'account/:cbaAccountId/settings/statements',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.HOME_LOAN]: {
            path: 'account/:cbaAccountId/:modal?',
            parse: {
              cbaAccountId: parseParam,
              modal: parseParam,
            },
          },
          [Screen.ACCOUNT_ADDRESS_BOOK]: {
            path: 'account/:cbaAccountId/account-address-book',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.ADD_NEW_ACCOUNT]: {
            path: 'account/:cbaAccountId/account-address-book/add-new-account',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.ACCOUNT_PERMISSIONS]: {
            path: 'account/:cbaAccountId/settings/permissions',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN]: {
            path: 'account/:cbaAccountId/settings/permissions/approval-required',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS]: {
            path: 'account/:cbaAccountId/settings/reduce-repayments',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION]: {
            path: 'account/:cbaAccountId/settings/reduce-repayments/confirm',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN]: {
            path: 'account/:cbaAccountId/settings/reduce-repayments/approval-required',
            parse: {
              cbaAccountId: parseParam,
            },
          },
          [Screen.VOI_COMPLETED]: {
            path: '/identity-verification-completed',
          },
        },
      },
      [Screen.SETTINGS]: {
        initialRouteName: Screen.SETTINGS_HOME,
        path: Screen.SETTINGS,
        screens: {
          [Screen.SETTINGS_HOME]: {
            path: '',
          },
          [Screen.SETTINGS_PERSONAL]: {
            path: 'personal',
          },
          [Screen.SETTINGS_SECURITY]: {
            path: Screen.SETTINGS_SECURITY,
          },
          [Screen.SETTINGS_SECURITY_FACE_ID]: {
            path: Screen.SETTINGS_SECURITY_FACE_ID,
          },
          [Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE]: {
            path: 'personal/legal-name-change',
          },
          [Screen.SETTINGS_DAILY_TRANSFER_LIMIT]: {
            path: 'daily-transfer-limit',
          },
          [Screen.SETTINGS_NOTIFICATION]: {
            path: 'notifications',
          },
          [Screen.SETTINGS_NEW_FEATURES]: {
            path: 'new-features',
          },
          [Screen.SETTINGS_CONNECTED_BANKS]: {
            path: 'banks',
          },
          [Screen.SETTINGS_BANK_PROFILE]: {
            path: 'banks/:institutionConnectionId',
          },
          [Screen.SETTINGS_BANK_ACCOUNT_DETAIL]: {
            path: Screen.SETTINGS_BANK_ACCOUNT_DETAIL,
          },
          [Screen.SETTINGS_MANAGE_DATA_SHARING]: {
            path: Screen.SETTINGS_MANAGE_DATA_SHARING.replace(
              `${Screen.SETTINGS}-`,
              '',
            ),
          },
          [Screen.SETTINGS_DATA_SHARING_DETAILS]: {
            path: Screen.SETTINGS_DATA_SHARING_DETAILS.replace(
              `${Screen.SETTINGS}-`,
              '',
            ),
          },
          [Screen.SETTINGS_DATA_SHARING_PREFERENCES]: {
            path: Screen.SETTINGS_DATA_SHARING_PREFERENCES,
          },
          [Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES]: {
            path: Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES,
          },
          [Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST]:
            {
              path: Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST,
            },
          [Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES]: {
            path: Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES,
          },
          [Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY]: {
            path: Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY,
          },
        },
      },
    },
  },
  [Screen.AUTH_FLOW]: {
    path: 'auth/:reason?',
  },
  [Screen.INVALID_APPLICATION_INVITE]: {
    path: 'invalid-invite',
  },
  [Screen.NAVIGATOR_MODAL]: {
    path: Screen.NAVIGATOR_MODAL,
    screens: {
      [NavigatorModalScreen.EnableFaceId]: {
        path: NavigatorModalScreen.EnableFaceId,
      },
    },
  },
  [Screen.NAVIGATOR_FULL_SCREEN_MODAL]: {
    path: Screen.NAVIGATOR_FULL_SCREEN_MODAL,
    screens: {
      [NavigatorFullScreenModalScreen.OutOfService]: {
        path: NavigatorFullScreenModalScreen.OutOfService,
      },
    },
  },
  [Screen.SINGLE_V2_MODAL]: {
    path: Screen.SINGLE_V2_MODAL,
    screens: {
      [Screen.YOUR_PROPERTY_V2_MODAL]: {
        path: Screen.YOUR_PROPERTY_V2_MODAL,
      },
      [Screen.YOUR_PROPERTY_PURCHASE_MODAL]: {
        path: Screen.YOUR_PROPERTY_PURCHASE_MODAL,
      },
      [Screen.TOP_UP_PROPERTY_VALUE_MODAL]: {
        path: Screen.TOP_UP_PROPERTY_VALUE_MODAL,
      },
      [Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL]: {
        path: Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL,
      },
      [Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL]: {
        path: Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL,
      },
      [Screen.DEBTS_EDIT_LIABILITY_V2_MODAL]: {
        path: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
      },
      [Screen.FINANCIAL_DECLARATION_MODAL_V2]: {
        path: Screen.FINANCIAL_DECLARATION_MODAL_V2,
      },
      [Screen.SELECT_REFI_REASON_V2_MODAL]: {
        path: Screen.SELECT_REFI_REASON_V2_MODAL,
      },
      [Screen.SETUP_LOAN_V2_MODAL]: {
        path: Screen.SETUP_LOAN_V2_MODAL,
      },
      [Screen.SETUP_LOAN_FOR_PURCHASE_MODAL]: {
        path: Screen.SETUP_LOAN_FOR_PURCHASE_MODAL,
      },
      [Screen.SETUP_LOAN_FOR_TOP_UP_MODAL]: {
        path: Screen.SETUP_LOAN_FOR_TOP_UP_MODAL,
      },
      [Screen.PRIVACY_COLLECTION_STATEMENT_MODAL]: {
        path: Screen.PRIVACY_COLLECTION_STATEMENT_MODAL,
      },
      [Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL]: {
        path: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
      },
      [Screen.AML_CTF_ACT_V2_MODAL]: {
        path: Screen.AML_CTF_ACT_V2_MODAL,
      },
      [Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL]: {
        path: Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL,
      },
      [Screen.MANUAL_VERIFICATION_V2_MODAL]: {
        path: Screen.MANUAL_VERIFICATION_V2_MODAL,
      },
      [Screen.INCOME_SUPPORTING_DOCUMENT_MODAL]: {
        path: Screen.INCOME_SUPPORTING_DOCUMENT_MODAL,
      },
      [Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL]: {
        path: Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL,
      },
      [Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL]: {
        path: Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL,
      },
      [Screen.UPDATE_ADDRESS_MODAL]: {
        path: Screen.UPDATE_ADDRESS_MODAL,
      },
      [Screen.NEW_FEATURES_MODAL]: {
        path: Screen.NEW_FEATURES_MODAL,
      },
      [Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL]: {
        path: Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL,
      },
      [Screen.MOCK_ACCOUNT_MODAL]: {
        path: Screen.MOCK_ACCOUNT_MODAL,
      },
      [Screen.PAYMENT_FAILURE_MODAL]: {
        path: Screen.PAYMENT_FAILURE_MODAL,
      },
    },
  },
  [Screen.LOAN_APPLICATION_V2]: {
    path: LOAN_APPLICATION_PATH,
    screens: {
      [Screen.LOAN_APPLICATION_V2_SPLASH]: {
        path: ':loanApplicationId?',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_INTRO]: {
        path: 'refi/:loanApplicationId/intro',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY]: {
        path: 'refi/:loanApplicationId/property',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_BORROWERS]: {
        path: 'refi/:loanApplicationId/borrowers',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN]: {
        path: 'refi/:loanApplicationId/loan',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_YOUR_DEBTS]: {
        path: 'refi/:loanApplicationId/debts',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_YOUR_INCOME]: {
        path: 'refi/:loanApplicationId/income',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES]: {
        path: 'refi/:loanApplicationId/expenses',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT]: {
        path: 'refi/:loanApplicationId/verified',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY]: {
        path: 'refi/:loanApplicationId/review',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_SUBMIT]: {
        path: 'refi/:loanApplicationId/submit',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_VOI_COMPLETED]: {
        path: 'refi/:loanApplicationId/identity-verification-completed',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_COMPLETED]: {
        path: 'refi/:loanApplicationId/completed',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_OFFER]: {
        path: 'refi/:loanApplicationId/sign',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_OFFER_ESIGN]: {
        path: 'refi/:loanApplicationId/esign',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB]: {
        path: 'refi/:loanApplicationId/connect',
      },

      [Screen.LOAN_APPLICATION_PURCHASE_INTRO]: {
        path: 'buy/:loanApplicationId/intro',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY]: {
        path: 'buy/:loanApplicationId/property',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_BORROWERS]: {
        path: 'buy/:loanApplicationId/borrowers',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN]: {
        path: 'buy/:loanApplicationId/loan',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS]: {
        path: 'buy/:loanApplicationId/debts',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME]: {
        path: 'buy/:loanApplicationId/income',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES]: {
        path: 'buy/:loanApplicationId/expenses',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT]: {
        path: 'buy/:loanApplicationId/verified',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY]: {
        path: 'buy/:loanApplicationId/review',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_SUBMIT]: {
        path: 'buy/:loanApplicationId/submit',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED]: {
        path: 'buy/:loanApplicationId/identity-verification-completed',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_COMPLETED]: {
        path: 'buy/:loanApplicationId/completed',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_OFFER]: {
        path: 'buy/:loanApplicationId/sign',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_OFFER_ESIGN]: {
        path: 'buy/:loanApplicationId/esign',
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB]: {
        path: 'buy/:loanApplicationId/connect',
      },
      [Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL]: {
        path: 'buy/:loanApplicationId/conditional-approval',
      },

      [Screen.LOAN_APPLICATION_TOP_UP_INTRO]: {
        path: `${LOAN_TOP_UP_PATH}/:cbaAccountId/intro`,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/property`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_BORROWERS]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/borrowers`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/loan`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/debts`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/income`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/expenses`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/verified`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/review`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_SUBMIT]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/submit`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_COMPLETED]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/completed`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_OFFER]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/sign`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_OFFER_ESIGN]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/esign`,
        parse: loanApplicationIdParamParser,
      },
      [Screen.LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB]: {
        path: `${LOAN_TOP_UP_PATH}/:loanApplicationId/connect`,
      },
    },
  },
  [Screen.DOCUSIGN_CONTRACT_RETURN]: {
    path: `callback/:loanApplicationId?`,
    parse: loanApplicationIdParamParser,
  },
  [Screen.APPLICATION_TRACKING]: {
    path: LOAN_APPLICATION_TRACKING_PATH,
    parse: loanApplicationIdParamParser,
  },
  [Screen.HOME_LOAN_MODAL]: {
    path: Screen.HOME_LOAN_MODAL,
    screens: {
      [Screen.HOME_LOAN_WITHDRAWAL]: {
        path: Screen.HOME_LOAN_WITHDRAWAL,
      },
      [Screen.HOME_LOAN_WITHDRAWAL_TO]: {
        path: Screen.HOME_LOAN_WITHDRAWAL_TO,
      },
      [Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT]: {
        path: Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT,
      },
      [Screen.HOME_LOAN_WITHDRAWAL_V2]: {
        path: Screen.HOME_LOAN_WITHDRAWAL_V2,
      },
      [Screen.HOME_PENDING_TRANSACTION]: {
        path: Screen.HOME_PENDING_TRANSACTION,
      },
      [Screen.HOME_PROCESSED_TRANSACTION]: {
        path: Screen.HOME_PROCESSED_TRANSACTION,
      },
      [Screen.HOME_NEXT_REPAYMENT]: {
        path: Screen.HOME_NEXT_REPAYMENT,
      },
    },
  },
  [Screen.AUTOPAY_SETTINGS_MODAL]: {
    screens: {
      [Screen.AUTOPAY_EXISTING_SETTINGS]: {
        path: Screen.AUTOPAY_EXISTING_SETTINGS,
      },
      [Screen.AUTOPAY_SETUP]: {
        path: Screen.AUTOPAY_SETUP,
      },
      [Screen.AUTOPAY_EDIT]: {
        path: Screen.AUTOPAY_EDIT,
      },
      [Screen.AUTOPAY_CUSTOM_REPAYMENT]: {
        path: Screen.AUTOPAY_CUSTOM_REPAYMENT,
      },
      [Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT]: {
        path: Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT,
      },
      [Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT]: {
        path: Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT,
      },
      [Screen.AUTOPAY_CHANGE_REQUEST]: {
        path: Screen.AUTOPAY_CHANGE_REQUEST,
      },
    },
  },
  [Screen.HOME_LOAN_INSIGHTS_MODAL]: {
    screens: {
      [Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING]: {
        path: Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING,
      },
      [Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP]: {
        path: Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP,
      },
      [Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS]: {
        path: Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS,
      },
      [Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE]: {
        path: Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE,
      },
    },
  },
  [Screen.LOAN_CONFIGURATION_V2_MODAL]: {
    screens: {
      [Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON]: {
        path: Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON,
      },
      [Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN]: {
        path: Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN,
      },
    },
  },

  [Screen.YOUR_INCOME_V2_WIZARD]: Screen.YOUR_INCOME_V2_WIZARD,
  [Screen.YOUR_INCOME_V2_MODAL]: {
    screens: {
      [Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL,
      },
      [Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL]: {
        path: Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL,
      },
    },
  },
  [Screen.YOUR_EXPENSES_V2_WIZARD]: Screen.YOUR_EXPENSES_V2_WIZARD,
  [Screen.DEBTS_WIZARD_V2_MODAL]: Screen.DEBTS_WIZARD_V2_MODAL,
  [Screen.SELECT_INSTITUTION_MODAL]: {
    screens: {
      [Screen.BANK_LOGIN]: { path: Screen.BANK_LOGIN },
      [Screen.BANK_REFRESH]: { path: Screen.BANK_REFRESH },
      [Screen.SELECT_BANK]: { path: Screen.SELECT_BANK },
      [Screen.MANAGE_CONSENTS]: { path: Screen.MANAGE_CONSENTS },
    },
  },
  [Screen.DATA_SHARING_CONFIRMATION_MODAL]: {
    screens: {
      [Screen.DATA_SHARING_CURRENT_STATUS]: {
        path: Screen.DATA_SHARING_CURRENT_STATUS,
      },
      [Screen.DATA_SHARING_SUMMARY]: {
        path: Screen.DATA_SHARING_SUMMARY,
      },
      [Screen.DATA_SHARING_CONFIRMATION]: {
        path: Screen.DATA_SHARING_CONFIRMATION,
      },
    },
  },
  [Screen.IDENTIFICATION_V2_MODAL]: {
    screens: {
      [Screen.IDENTIFICATION_V2_YOUR_INFORMATION]: {
        path: Screen.IDENTIFICATION_V2_YOUR_INFORMATION,
      },
      [Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE]: {
        path: Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE,
      },
      [Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT]: {
        path: Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT,
      },
      [Screen.IDENTITY_VERIFICATION_PENDING_V2]: {
        path: Screen.IDENTITY_VERIFICATION_PENDING_V2,
      },
    },
  },
  [Screen.BORROWER_V2_MODAL]: {
    screens: {
      [Screen.BORROWER_V2_DETAILS]: { path: Screen.BORROWER_V2_DETAILS },
      [Screen.BORROWER_V2_INVITE_COBORROWER]: {
        path: Screen.BORROWER_V2_INVITE_COBORROWER,
      },
    },
  },
  [Screen.CONDITIONAL_APPROVAL_MODAL]: {
    screens: {
      [Screen.CONDITIONAL_APPROVAL_APP_DETAILS]: {
        path: Screen.CONDITIONAL_APPROVAL_APP_DETAILS,
      },
      [Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS]: {
        path: Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS,
      },
    },
  },
  [Screen.CONNECTED_BANKS_MODAL]: {
    screens: {
      [Screen.BANK_ACCOUNT_DETAILS]: { path: Screen.BANK_ACCOUNT_DETAILS },
    },
  },
  [Screen.STATEMENTS_MODAL]: {
    screens: {
      [Screen.STATEMENTS_LIST_MODAL]: {
        path: Screen.STATEMENTS_LIST_MODAL,
      },
      [Screen.STATEMENTS_PREVIEW_MODAL]: {
        path: Screen.STATEMENTS_PREVIEW_MODAL,
      },
      [Screen.STATEMENTS_FILTER_MODAL]: {
        path: Screen.STATEMENTS_FILTER_MODAL,
      },
    },
  },
  [Screen.STATEMENTS_V2_MODAL]: {
    screens: {
      [Screen.STATEMENTS_PREVIEW_V2_MODAL]: {
        path: Screen.STATEMENTS_PREVIEW_V2_MODAL,
      },
      [Screen.STATEMENTS_FILTER_V2_MODAL]: {
        path: Screen.STATEMENTS_FILTER_V2_MODAL,
      },
    },
  },
  [Screen.TRANSACTION_MODAL]: {
    screens: {
      [Screen.TRANSACTION_FILTER]: { path: Screen.TRANSACTION_FILTER },
    },
  },
  [Screen.PERSONAL_DETAILS_MODAL]: {
    path: Screen.PERSONAL_DETAILS_MODAL,
    screens: {
      // Mobile Change
      [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY,
      },
      [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY,
      },
      [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE]: {
        path: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE,
      },
      [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY,
      },
      // Email Change
      [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY,
      },
      [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY,
      },
      [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL]: {
        path: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL,
      },
      [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY]: {
        path: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY,
      },
    },
  },
  [Screen.NOT_FOUND]: '*',
};

const getScreenName = (state?: NavigationState) => {
  const { index } = state ?? {};
  const screenName =
    index !== undefined ? state?.routes?.[index]?.name : undefined;

  return screenName;
};

type NavigationState = NonNullable<ReturnType<typeof getStateFromPath>>;
// We need to preserve previous state without modal / actionsheet so we dont render modal as page (that has link)
let prevStateWithoutModalAndActionSheet: NavigationState | undefined;
export const LINKING: LinkingOptions<AppStackParams> = {
  prefixes,
  getPathFromState: (state, options) => {
    const currentScreenName = getScreenName(state as NavigationState);

    if (shouldHideScreenFromPath(currentScreenName)) {
      // Return previous path when we want the screen to not having its own path (eg Modal / Action Sheet)
      return getPathFromState(
        prevStateWithoutModalAndActionSheet ?? state,
        options,
      );
    }

    // Casting to NavigationState because kept running into mismatching `stale` type
    prevStateWithoutModalAndActionSheet = state as NavigationState;
    return getPathFromState(state, options);
  },
  getStateFromPath: (path, options) => {
    const state = getStateFromPath(path, options);

    const currentScreenName = getScreenName(state);

    if (!shouldHideScreenFromPath(currentScreenName)) {
      prevStateWithoutModalAndActionSheet = state;
    }
    return state;
  },
  config: {
    screens: linkingConfig,
  },
};
