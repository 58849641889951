import {
  backgroundColorShorthand,
  border,
  color,
  createRestyleComponent,
  createRestyleFunction,
  createVariant,
  layout,
  opacity,
  spacingShorthand,
  textShadow,
  visible,
} from '@shopify/restyle';
import { getKeys } from '@shopify/restyle/dist/typeHelpers';
import * as React from 'react';
import { FlexStyle, Text, TextProps } from 'react-native';

import { isWeb } from '../../utils/platformUtils';
import { Theme } from '../theme';
import {
  BackgroundColorProps,
  BorderProps,
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  LayoutProps,
  LineHeightProps,
  OpacityProps,
  SpacingProps,
  TextShadowProps,
  TextVariantProps,
  typographyProperties,
  TypographyProps as BaseTypographyProps,
  VisibleProps,
} from '../types';

export type TypographyProps = BaseTypographyProps &
  FontSizeProps &
  FontWeightProps;

export type StyledTextProps = TypographyProps &
  ColorProps &
  OpacityProps &
  VisibleProps &
  BackgroundColorProps &
  LayoutProps &
  SpacingProps &
  TextShadowProps &
  LineHeightProps &
  TextVariantProps &
  BorderProps;

const fontSize = createRestyleFunction({
  property: 'fontSize',
  themeKey: 'fontSizes',
});

const lineHeight = createRestyleFunction({
  property: 'lineHeight',
  themeKey: 'lineHeights',
});

const fontWeight = createRestyleFunction({
  property: 'fontWeight',
  themeKey: 'fontWeights',
  styleProperty: 'fontFamily',
});

const typography = getKeys(typographyProperties).map((property) =>
  createRestyleFunction({
    property,
  }),
);

export type Props = StyledTextProps &
  Omit<TextProps, keyof StyledTextProps> & {
    children?: React.ReactNode;
    inlineFlex?: boolean;
  };

export const typographyRestyleFunctions = [typography, fontSize, fontWeight];

export const textRestyleFunctions = [
  color,
  opacity,
  visible,
  spacingShorthand,
  backgroundColorShorthand,
  layout,
  lineHeight,
  textShadow,
  border,
  ...typographyRestyleFunctions,
  createVariant({ themeKey: 'textVariants' }),
];

export const RestyledText = createRestyleComponent<Props, Theme>(
  textRestyleFunctions,
  Text,
);

const WEB_DEFAULT_DISPLAY = 'inline' as FlexStyle['display'];

const WEB_INLINE_FLEX_DISPLAY = 'inline-flex' as FlexStyle['display'];

export function StyledText({ style, inlineFlex, ...props }: Props) {
  const webDisplay = inlineFlex ? WEB_INLINE_FLEX_DISPLAY : WEB_DEFAULT_DISPLAY;

  return (
    <RestyledText
      style={[
        /**
         * Use default behavior of `display` Text in web, so we can have nested Text working correctly in Web.
         * `display` will automatically evaluates to `flex` when not overidden since we're using layout restyle function.
         */
        {
          display: isWeb ? webDisplay : 'flex',
        },
        style,
      ]}
      {...props}
    />
  );
}

StyledText.defaultProps = {
  variant: 'default',
};
