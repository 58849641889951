import { useHeaderHeight } from '@react-navigation/elements';
import { useEffect, useMemo } from 'react';

import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useGetObAccessTokenLazyQuery } from '../../generated/graphql';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { StyledText } from '../../ui/atoms/StyledText';
import { MAX_MOBILE_HEIGHT } from '../../utils/NavConstants';
import { useYodleeFastlinkWeb } from '../utils/fastlinkHelpers';
import { FastlinkProps } from './fastlinkTypes';

const FASTLINK_DIV_ID = 'fastlink';

export function Fastlink({
  onSuccess,
  onError,
  onClose,
  errorContext,
  flow,
  navigateToManualUpload,
  navigateBack,
  providerAccountId,
}: FastlinkProps) {
  const {
    initFastlink,
    loading: scriptLoading,
    error,
  } = useYodleeFastlinkWeb({
    fastlinkContainerElementId: FASTLINK_DIV_ID,
    onSuccess: (data) =>
      onSuccess({
        providerAccountId: data.providerAccountId,
        requestId: data.requestId,
      }),
    onError: (data) => {
      onError?.({
        providerAccountId: data.providerAccountId,
        requestId: data.requestId,
        reason: data.reason,
      });
    },
    onClose,
    errorContext,
    flow,
    providerAccountId,
  });

  const [getObAccessToken, { loading, data }] = useGetObAccessTokenLazyQuery();

  const accessToken = data?.getOpenBankingAccessToken?.accessToken;
  useEffect(() => {
    if (accessToken == null) {
      getObAccessToken();
    }
  }, [accessToken, getObAccessToken]);

  useEffect(() => {
    if (!scriptLoading && accessToken) {
      initFastlink?.({ accessToken });
    }
  }, [initFastlink, scriptLoading, accessToken]);

  const headerHeight = useHeaderHeight();
  const fastlinkContainerStyle = useMemo(
    () => ({
      // We need to set fixed height so we can make the container scrollable
      height: MAX_MOBILE_HEIGHT - headerHeight,
      overflow: 'scroll',
    }),
    [headerHeight],
  );
  if (error) {
    return (
      <FastlinkErrorScreen
        navigateToManualUpload={navigateToManualUpload}
        navigateBack={navigateBack}
      />
    );
  }
  return (
    <ScreenLoadingContainer loading={loading || scriptLoading}>
      <div id={FASTLINK_DIV_ID} style={fastlinkContainerStyle} />
    </ScreenLoadingContainer>
  );
}

function FastlinkErrorScreen({
  navigateBack,
  navigateToManualUpload,
}: Pick<FastlinkProps, 'navigateToManualUpload' | 'navigateBack'>) {
  return (
    <ScreenContainer justifyContent="center">
      <Box flex={1} justifyContent="center">
        <StyledText variant="header" mx="xl" textAlign="center">
          {t('Content.FastLink.Error.Title')}
        </StyledText>
        <StyledText my="m" mx="xl" textAlign="center">
          {t('Content.FastLink.Error.Description')}
        </StyledText>
        {navigateToManualUpload ? (
          <Button
            label={t('Content.FastLink.Error.UploadDocs')}
            width="100%"
            mb="m"
            onPress={navigateToManualUpload}
          />
        ) : null}
        <Button
          label={t('Content.FastLink.Error.ResumeLater')}
          tertiary
          width="100%"
          onPress={navigateBack}
        />
      </Box>
    </ScreenContainer>
  );
}
