import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { ActionSheet } from '../../ActionSheet/components/ActionSheet';
import { ActionSheetScreenProps } from '../../ActionSheet/navigation/types';
import { HomeNavigatorNavigationProps } from '../../Home/navigation/types';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { formatCurrency } from '../../utils/currencyHelpers';

type Props = ActionSheetScreenProps<ActionSheetType.TRANSFER_LIMIT_REACHED>;

export function TransferLimitReachedActionSheet({ navigation, route }: Props) {
  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const homeNavigation =
    useNavigation<HomeNavigatorNavigationProps<Screen.HOME_DASHBOARD>>();

  const onUpdate = useCallback(() => {
    homeNavigation.navigate(Screen.SETTINGS, {
      screen: Screen.SETTINGS_DAILY_TRANSFER_LIMIT,
      params: {
        previousScreen: Screen.HOME_LOAN,
      },
    });
  }, [homeNavigation]);

  const actionList = [
    {
      label: t('Content.Common.ButtonLabel.Update'),
      secondary: true,
      onPress: onUpdate,
      flex: 1,
    },
    {
      label: t('Content.Common.ButtonLabel.Cancel'),
      secondary: true,
      onPress: goBack,
      flex: 1,
    },
  ];

  const exceededAmount = route.params.exceededByAmount
    ? formatCurrency(route.params.exceededByAmount)
    : null;

  const content = exceededAmount
    ? t('Content.DailyTransactionLimit.ErrorMessage', { exceededAmount })
    : t('Content.DailyTransactionLimit.ErrorMessageNoExceededByAmount');

  return (
    <ActionSheet
      closeModal={goBack}
      title={t('Content.DailyTransactionLimit.ErrorTitle')}
      actionList={actionList}
      isRowButton
    >
      <Box py="m">
        <StyledText textAlign="center">{content}</StyledText>
      </Box>
    </ActionSheet>
  );
}
