import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function FinancialEmploymentIncomeIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('FinancialEmploymentIncomeIcon');

  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        d="M33 5.25H32.25C32.25 5.664 31.914 6 31.5 6C31.086 6 30.75 5.664 30.75 5.25H29.25C29.25 5.664 28.914 6 28.5 6C28.086 6 27.75 5.664 27.75 5.25H26.25C26.25 5.664 25.914 6 25.5 6C25.086 6 24.75 5.664 24.75 5.25H23.25C23.25 5.664 22.914 6 22.5 6C22.086 6 21.75 5.664 21.75 5.25H20.25C20.25 5.664 19.914 6 19.5 6C19.086 6 18.75 5.664 18.75 5.25H17.25C17.25 5.664 16.914 6 16.5 6C16.086 6 15.75 5.664 15.75 5.25H14.25C14.25 5.664 13.914 6 13.5 6C13.086 6 12.75 5.664 12.75 5.25H11.25C11.25 5.664 10.914 6 10.5 6C10.086 6 9.75 5.664 9.75 5.25H8.25C8.25 5.664 7.914 6 7.5 6C7.086 6 6.75 5.664 6.75 5.25H5.25C5.25 5.664 4.914 6 4.5 6C4.086 6 3.75 5.664 3.75 5.25H3C2.586 5.25 2.25 5.586 2.25 6V30C2.25 30.8288 2.92125 30.75 3.75 30.75C3.75 30.75 31.4212 30.75 32.25 30.75C33.0788 30.75 33.75 30.8288 33.75 30V6C33.75 5.586 33.414 5.25 33 5.25Z"
        fill={`url(#${idPrefix}_paint0_linear_8221_238175)`}
      />
      <Path
        d="M17.625 12.75H6.375C6.168 12.75 6 12.582 6 12.375V11.625C6 11.418 6.168 11.25 6.375 11.25H17.625C17.832 11.25 18 11.418 18 11.625V12.375C18 12.582 17.832 12.75 17.625 12.75Z"
        fill="#2C2C2C"
      />
      <Path
        d="M15.375 15.75H6.375C6.168 15.75 6 15.582 6 15.375V14.625C6 14.418 6.168 14.25 6.375 14.25H15.375C15.582 14.25 15.75 14.418 15.75 14.625V15.375C15.75 15.582 15.582 15.75 15.375 15.75Z"
        fill="#2C2C2C"
      />
      <Path
        d="M30.5797 21.5145C30.5797 17.274 24.3187 18.102 24.3187 15.5962C24.3187 14.2882 25.7887 14.2192 26.2492 14.2192C27.354 14.2192 28.4212 14.5155 29.2342 14.8973C29.4907 15.0173 29.7847 14.8395 29.7847 14.5567V13.323C29.7847 12.909 29.6287 12.6637 29.3805 12.567C28.8225 12.3502 28.0222 12.192 26.838 12.153V10.7947C26.838 10.632 26.706 10.5 26.5432 10.5H25.9537C25.791 10.5 25.659 10.632 25.659 10.7947V12.1747C23.823 12.354 21.918 13.3905 21.918 15.8025C21.918 20.1982 28.1782 19.3267 28.1782 21.642C28.1782 22.3695 27.7132 22.9972 26.2477 22.9972C24.7747 22.9972 23.421 22.4827 22.6717 22.0995C22.4197 21.9705 22.1227 22.1573 22.1227 22.4408V23.8455C22.1227 24.1147 22.2847 24.3547 22.533 24.4597C23.421 24.837 24.6112 25.122 25.6582 25.206V26.7068C25.6582 26.8695 25.7902 27.0015 25.953 27.0015H26.5425C26.7052 27.0015 26.8372 26.8695 26.8372 26.7068V25.2068C29.5867 24.978 30.5797 23.1383 30.5797 21.5145Z"
        fill="#2C2C2C"
      />
      <Path
        d="M10.5008 27C10.1468 27 9.83631 26.8343 9.64806 26.5463C9.52131 26.3513 9.45681 26.109 9.45681 25.8263C9.45681 25.335 9.71856 24.4785 10.1348 23.622C10.0201 22.8668 9.80331 22.059 9.45906 21.4747C9.23856 21.099 9.06306 21 9.00081 21C8.21856 21 7.63356 22.644 7.48656 23.394C7.40706 23.8005 7.01781 24.0645 6.60606 23.9858C6.19956 23.9063 5.93406 23.5117 6.01431 23.1052C6.08706 22.737 6.78456 19.5 9.00081 19.5C10.1273 19.5 10.8436 20.607 11.2598 21.8805C11.7263 21.3547 12.2408 21 12.7508 21C14.2456 21 14.7368 22.734 15.0961 24.0007C15.1088 24.0442 15.1216 24.09 15.1351 24.1365C15.1673 24.0727 15.1981 24.0113 15.2258 23.955C15.7043 22.9913 16.2466 21.9 17.2508 21.9C17.6656 21.9 18.0008 22.236 18.0008 22.65C18.0008 23.043 17.6986 23.3663 17.3131 23.3978C17.1031 23.5478 16.7573 24.243 16.5691 24.6225C16.1356 25.4963 15.7606 26.25 15.0008 26.25C14.1743 26.25 13.9328 25.3973 13.6531 24.4103C13.5053 23.8875 13.1116 22.5 12.7508 22.5C12.5078 22.5938 12.0683 23.2102 11.6836 23.901C11.7218 24.246 11.7391 24.5632 11.7391 24.8295C11.7391 25.3335 11.6873 25.7528 11.5838 26.076C11.3183 26.91 10.7378 27 10.5008 27Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8221_238175`}
          x1="2.25"
          y1="5.25"
          x2="27.1935"
          y2="36.0601"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
