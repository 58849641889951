import { RouteProp, useRoute } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  Biza_Account_Notification_Preferences,
  Biza_Update_Notification_Preferences_Input,
  refetchGetBizaAccountByIdQuery,
  useGetBizaAccountByIdQuery,
  useUpdateNotificationPreferencesMutation,
} from '../../generated/graphql';
import { SettingsStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { SwitchRow } from '../../ui/molecules/SwitchRow';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { DataSharingFooterCaption } from './ManageDataSharing';

const AccountNotificationPreferences = ({
  accountId,
  preferences,
}: {
  accountId: string;
  preferences: Biza_Account_Notification_Preferences;
}) => {
  const [updateNotificationPreferences, { loading: updatePreferencesLoading }] =
    useUpdateNotificationPreferencesMutation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [preferencesState, setPreferencesState] = useState({
    data_sharing_started: preferences.data_sharing_started,
    data_sharing_stopped: preferences.data_sharing_stopped,
    data_sharing_not_approved: preferences.data_sharing_not_approved,
  });

  const updatePreferences = useCallback(
    async (newPreferences: Biza_Update_Notification_Preferences_Input) => {
      setPreferencesState(newPreferences);
      const [_, error] = await safelyCallMutation(
        updateNotificationPreferences,
        {
          variables: {
            account_id: accountId,
            preferences: newPreferences,
          },
          refetchQueries: [refetchGetBizaAccountByIdQuery({ accountId })],
          context: {
            sentryContext: {
              accountId,
            },
          },
        },
      );

      if (error) {
        setErrorMessage(t('Content.Common.Error.FailUpdatePreferences'));
      }
    },
    [updateNotificationPreferences, accountId],
  );

  const setDataSharingStarted = useCallback(
    (data_sharing_started: boolean) =>
      updatePreferences({ ...preferencesState, data_sharing_started }),
    [preferencesState, updatePreferences],
  );

  const setDataSharingStopped = useCallback(
    (data_sharing_stopped: boolean) =>
      updatePreferences({ ...preferencesState, data_sharing_stopped }),
    [preferencesState, updatePreferences],
  );

  const setDataSharingNotApproved = useCallback(
    (data_sharing_not_approved: boolean) =>
      updatePreferences({ ...preferencesState, data_sharing_not_approved }),
    [preferencesState, updatePreferences],
  );

  return (
    <Box mt="m">
      <ErrorRow message={errorMessage} mx="m" />
      <ListRowGroup headerText="Data Sharing Started">
        <SwitchRow
          label={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchLabel',
          )}
          caption={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchCaption',
          )}
          value={preferencesState.data_sharing_started}
          setFieldValue={setDataSharingStarted}
          disabled={updatePreferencesLoading}
          last
        />
      </ListRowGroup>
      <ListRowGroup headerText="Data Sharing Stopped">
        <SwitchRow
          label={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchLabel',
          )}
          caption={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchCaption',
          )}
          value={preferencesState.data_sharing_stopped}
          setFieldValue={setDataSharingStopped}
          disabled={updatePreferencesLoading}
          last
        />
      </ListRowGroup>
      <ListRowGroup headerText="Data Sharing Not Approved">
        <SwitchRow
          label={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchLabel',
          )}
          caption={t(
            'Content.DataSharingNotificationPreferences.NotificationPreferenceSwitchCaption',
          )}
          value={preferencesState.data_sharing_not_approved}
          setFieldValue={setDataSharingNotApproved}
          disabled={updatePreferencesLoading}
          last
        />
      </ListRowGroup>
    </Box>
  );
};

function SettingsDataSharingAccountNotificationPreferencesBase(): JSX.Element {
  const route =
    useRoute<
      RouteProp<
        SettingsStackParams,
        Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES
      >
    >();
  const { accountId } = route.params;

  const { data, loading, error } = useGetBizaAccountByIdQuery({
    variables: { accountId },
    context: {
      sentryContext: {
        bizaAccountId: accountId,
        description: 'Fetching Biza Account By Id.',
      },
    },
  });
  let content;

  if (error)
    content = <ErrorRow message="Unable to fetch data sharing preferences." />;
  if (loading) content = <Spinner />;
  if (data) {
    const preferences =
      data.me[0]?.user?.biza_account_by_id?.notification_preferences;

    content = preferences ? (
      <AccountNotificationPreferences
        accountId={accountId}
        preferences={preferences}
      />
    ) : (
      <ErrorRow message="Unable to fetch data sharing preferences." />
    );
  }

  return (
    <ScreenContainer bg="bgSecondary" scrollable px="m">
      <NavHeaderSpacer />
      {content}
      <Separator spacer />
      <DataSharingFooterCaption />
    </ScreenContainer>
  );
}

export const WithAuthenticationSettingsDataSharingAccountNotificationPreferences =
  withAuthenticationRequired(
    SettingsDataSharingAccountNotificationPreferencesBase,
  );
