import { Text, View } from 'dripsy';
import { Formik } from 'formik';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import { useGetAccountPermissionsDataByAccountIdQuery } from '../../generated/graphql';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Spinner } from '../../ui/atoms/Spinner';
import { Notification } from '../components/Notification';
import { PermissionSection } from '../components/PermissionSection';
import { AccountPermissionType } from '../types';
import {
  AccountPermissionsShowErrorAtomFamily,
  LeaveAccountPermissionsScreenAtomFamily,
} from '../utils/atoms';
import {
  FormValues,
  getInitialValues,
  getPermissionsToUpdate,
  isAnyNewPermissionOneToApprove,
  validationSchema,
} from '../utils/formData';

type Props = HomeTabScreenProps<Screen.ACCOUNT_PERMISSIONS>;

const AccountPermissionsContent = ({
  onSubmit,
  onChangeValue,
}: {
  onSubmit: () => void;
  onChangeValue: () => void;
}) => (
  <View>
    <PermissionSection
      title={t('Content.AccountPermissions.Withdrawal.Title')}
      permissionType={AccountPermissionType.Withdrawal}
      onChangeValue={onChangeValue}
      sx={{ mb: '$24' }}
    />
    <PermissionSection
      title={t('Content.AccountPermissions.AutoPay.Title')}
      permissionType={AccountPermissionType.Autopay}
      onChangeValue={onChangeValue}
      sx={{ mb: '$16' }}
    />
    <Button
      onPress={onSubmit}
      label={t('Content.Common.ButtonLabel.Continue')}
      width="100%"
      testID={TestID.AccountPermissions.SubmitForm}
      mt="m"
    />
  </View>
);

export const AccountPermissions = ({ navigation, route }: Props) => {
  const { cbaAccountId } = route.params;

  const { flags } = useContext(FeatureFlagsContext);
  const LeavePageAtom = LeaveAccountPermissionsScreenAtomFamily(cbaAccountId);
  const shouldGoBack = useRecoilValue(LeavePageAtom);
  const resetShouldGoBack = useResetRecoilState(LeavePageAtom);

  const ShowErrorAtom = AccountPermissionsShowErrorAtomFamily(cbaAccountId);
  const hasMutationFailed = useRecoilValue(ShowErrorAtom);
  const resetHasMutationFailed = useResetRecoilState(ShowErrorAtom);

  const { data, loading, error, refetch } =
    useGetAccountPermissionsDataByAccountIdQuery({
      context: {
        sentryContext: {
          description: 'Get Account Permissions By Account Id',
          cbaAccountId,
        },
      },
      variables: {
        cbaAccountId,
      },
    });

  // Will navigate back when user clicks on 'Leave' button in
  // the Leave Page Action Sheet
  useEffect(() => {
    if (shouldGoBack) {
      resetShouldGoBack();
      navigation.goBack();
    }
  }, [shouldGoBack, resetShouldGoBack, navigation]);

  const initialValues: FormValues = useMemo(() => {
    const {
      require_approvals_for_autopay_change: requireApprovalForAutoPayChange,
      require_approvals_for_withdrawals: requireApprovalForWithdrawals,
    } = data?.loan_account_by_id?.permissions || {};
    return getInitialValues({
      requireApprovalForAutoPayChange,
      requireApprovalForWithdrawals,
    });
  }, [data]);

  const coBorrowerName = useMemo(() => {
    const requestorId = data?.me[0]?.user?.identity_profile?.id;
    const loanAccount = data?.loan_account_by_id;
    const borrowers = loanAccount?.loan_application_target?.applicants;
    const coBorrower = borrowers?.find(
      (b) => b.user_identity_profile?.id !== requestorId,
    );
    return coBorrower?.user_identity_profile?.full_name ?? '';
  }, [data]);

  const notificationMessage = useMemo(() => {
    if (hasMutationFailed) {
      return t('Content.AccountPermissions.PageError') as string;
    }
    return t('Content.AccountPermissions.NotificationMessage', {
      coBorrower: coBorrowerName,
    }) as string;
  }, [coBorrowerName, hasMutationFailed]);

  const onGoBack = useCallback(
    (isFormDirty: boolean) => {
      if (isFormDirty) {
        navigation.navigate(ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE, {
          cbaAccountId,
        });
      } else navigation.goBack();
    },
    [navigation, cbaAccountId],
  );

  const onChangeValue = useCallback(() => {
    if (hasMutationFailed) resetHasMutationFailed();
  }, [hasMutationFailed, resetHasMutationFailed]);

  const onSubmitForm = useCallback(
    (newValues: FormValues) => {
      if (hasMutationFailed) resetHasMutationFailed();
      const permissionsToUpdate = getPermissionsToUpdate(
        newValues,
        initialValues,
      );

      if (permissionsToUpdate.length < 1) return;
      navigation.navigate(ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES, {
        cbaAccountId,
        coBorrowerName,
        permissionsToUpdate,
      });
    },
    [
      initialValues,
      cbaAccountId,
      coBorrowerName,
      navigation,
      hasMutationFailed,
      resetHasMutationFailed,
    ],
  );

  const isFeatureEnabled = !!flags[FeatureFlag.EnableAccountPermissions];
  if (!loading && !isFeatureEnabled) {
    return <NotFoundScreen />;
  }

  return (
    // The whole screen is wrapped around Formik because we need to know
    // if the form is dirty or not in order to show the Leave Page Action Sheet
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitForm}
    >
      {(formProps) => (
        <UpliftScreenContainer onPressBack={() => onGoBack(formProps.dirty)}>
          <ScreenErrorFallback
            displayMessage={t('Content.AccountPermissions.PageError')}
            error={error}
            refetch={refetch}
          >
            <View sx={{ pb: '$16' }}>
              <Text variant="header" sx={{ mt: '$8', py: '$8' }}>
                {t('Content.AccountPermissions.Title')}
              </Text>
              <Notification
                showNotification={
                  formProps.dirty
                    ? isAnyNewPermissionOneToApprove(
                        formProps.values,
                        initialValues,
                      )
                    : false
                }
                message={notificationMessage}
                isError={!!hasMutationFailed}
              />
              <Text variant="subHeading" sx={{ py: '$8' }}>
                {t('Content.AccountPermissions.SubTitle')}
              </Text>
            </View>
            {/* Render Spinner when loading */}
            {loading ? (
              <View sx={{ mt: '$16', py: '$32' }}>
                <Spinner
                  testID={TestID.AccountPermissions.Spinner}
                  size="large"
                />
              </View>
            ) : null}
            {/* Render form when data is fetched */}
            {!loading && (
              <AccountPermissionsContent
                onSubmit={() =>
                  formProps.dirty ? formProps.handleSubmit() : undefined
                }
                onChangeValue={onChangeValue}
              />
            )}
          </ScreenErrorFallback>
        </UpliftScreenContainer>
      )}
    </Formik>
  );
};
