import { useRef } from 'react';

let idCounter = 0;
function getIdCounter() {
  idCounter += 1;
  return idCounter;
}

export function useSvgIdPrefix(prefix: string) {
  const idPrefix = useRef(`${getIdCounter()}_${prefix}`);

  return idPrefix.current;
}
