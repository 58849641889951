import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function ConditionalApprovalDocumentIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fill="#dedede"
        d="M30 11.686v19.734c0 0.873-0.717 1.581-1.599 1.581h-20.802c-0.882 0-1.599-0.707-1.599-1.581v-26.838c0-0.873 0.717-1.581 1.599-1.581h13.776l2.226 6.317 6.399 2.368z"
      />
      <Path
        fill="#b8b8b8"
        d="M21.382 3v7.105c0 0.873 0.722 1.581 1.618 1.581h7l-8.618-8.686z"
      />
      <Path
        fill="#a2a2a2"
        d="M25.029 21.081h-14.061c-0.257 0-0.468-0.178-0.468-0.397v-0.787c0-0.219 0.211-0.397 0.468-0.397h14.061c0.26 0 0.471 0.178 0.471 0.397v0.787c0 0.219-0.211 0.397-0.471 0.397z"
      />
      <Path
        fill="#a2a2a2"
        d="M22.167 24.078h-11.335c-0.182 0-0.333-0.175-0.333-0.394v-0.79c0-0.216 0.15-0.394 0.333-0.394h11.335c0.182 0 0.333 0.178 0.333 0.394v0.79c0 0.219-0.15 0.394-0.333 0.394z"
      />
      <Path
        fill="#353535"
        d="M14.762 14.232c0.016-0.302-0.087-0.597-0.287-0.824-0.28-0.257-0.613-0.45-0.975-0.565-0.405-0.149-0.801-0.32-1.187-0.514-0.31-0.156-0.598-0.353-0.855-0.587-0.236-0.219-0.425-0.485-0.555-0.78-0.14-0.338-0.208-0.701-0.199-1.067-0.012-0.323 0.043-0.644 0.16-0.945s0.295-0.574 0.522-0.804c0.491-0.471 1.133-0.753 1.812-0.795v-0.846c0-0.134 0.053-0.262 0.148-0.357s0.223-0.148 0.357-0.148c0.134 0 0.262 0.053 0.357 0.148s0.148 0.223 0.148 0.357v0.865c0.49 0.046 0.956 0.232 1.344 0.535s0.68 0.712 0.842 1.176c0.050 0.137 0.067 0.283 0.049 0.428s-0.071 0.282-0.154 0.402c-0.083 0.12-0.193 0.218-0.322 0.286s-0.272 0.104-0.417 0.106c-0.197-0.001-0.389-0.067-0.545-0.187s-0.269-0.289-0.32-0.479c-0.044-0.171-0.123-0.331-0.234-0.468-0.101-0.123-0.229-0.221-0.374-0.286s-0.303-0.096-0.462-0.090c-0.147-0.009-0.294 0.012-0.432 0.063s-0.264 0.129-0.37 0.231c-0.098 0.11-0.174 0.238-0.223 0.377s-0.069 0.287-0.061 0.434c-0.007 0.14 0.014 0.28 0.062 0.413s0.121 0.254 0.216 0.357c0.302 0.263 0.652 0.465 1.032 0.593 0.425 0.166 0.839 0.356 1.241 0.571 0.301 0.162 0.577 0.366 0.82 0.606 0.22 0.22 0.394 0.482 0.512 0.77 0.124 0.321 0.184 0.663 0.176 1.008 0.013 0.321-0.040 0.641-0.155 0.94s-0.29 0.573-0.515 0.802c-0.502 0.471-1.155 0.75-1.843 0.788v0.755c0 0.133-0.053 0.26-0.146 0.353s-0.221 0.146-0.353 0.146c-0.132 0-0.259-0.053-0.353-0.146s-0.146-0.221-0.146-0.353v-0.744c-0.76-0.044-1.475-0.372-2.004-0.918-0.232-0.255-0.41-0.554-0.523-0.88-0.047-0.136-0.060-0.281-0.039-0.423s0.075-0.277 0.159-0.394c0.084-0.117 0.194-0.212 0.322-0.277s0.269-0.1 0.413-0.099h0.007c0.192-0 0.379 0.061 0.533 0.176s0.267 0.277 0.321 0.461c0.053 0.19 0.148 0.366 0.277 0.515 0.131 0.138 0.291 0.245 0.468 0.314s0.367 0.099 0.557 0.086c0.319 0.018 0.633-0.087 0.877-0.294 0.107-0.101 0.191-0.224 0.246-0.361s0.079-0.284 0.072-0.431z"
      />
    </Svg>
  );
}
