import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { ApplicationSummaryDetailsV2 } from '../../ApplicationSummary/components/ApplicationSummaryDetailsV2';
import { useReviewApplicationQuery } from '../../ApplicationSummary/remoteData';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  Loan_Application_Type_Enum,
  useGetFmsEsignSignerSummaryQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { openUrl } from '../../utils/openUrl';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

export type Props = LoanApplicationV2ScreenProps<
  Screen.LOAN_APPLICATION_V2_OFFER | Screen.LOAN_APPLICATION_TOP_UP_OFFER
>;

function openPortalUrl(
  loanApplicationId: string,
  portalUrl: string | null | undefined,
) {
  if (!portalUrl) {
    return captureException(
      'Unexpected to get here. When portal url is not available, application should be in decisioning and not actionable',
      {
        loanApplicationId,
      },
    );
  }
  return openUrl(portalUrl);
}

function YourUnloanOfferV2Base({ route, navigation }: Props) {
  const loanApplicationId = route.params?.loanApplicationId || '';

  const {
    loading: dealSummaryLoading,
    data: dealSummaryData,
    error: getFmsEsignSignerSummaryError,
  } = useGetFmsEsignSignerSummaryQuery({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const portalUrl = dealSummaryData?.fms_esign_signer_summary?.portal_url;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const openDocusignContract = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.YourUnloanOfferEsign,
    });
  };

  const {
    sectionData,
    data,
    loading: reviewApplicationLoading,
    error: reviewApplicationError,
    refetch,
  } = useReviewApplicationQuery({
    loanApplicationId,
  });

  const loanApplicationType = data?.loan_application_by_pk?.type;
  const isTopUpApplication =
    loanApplicationType === Loan_Application_Type_Enum.TopUp;

  const headerTitle = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariation.Title')
        : t('Content.YourUnloanOffer.Title'),
    [isTopUpApplication],
  );

  const headerCaption = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariation.Caption')
        : t('Content.YourUnloanOffer.Caption'),
    [isTopUpApplication],
  );

  const footerText = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariation.Footer')
        : t('Content.YourUnloanOffer.Footer'),
    [isTopUpApplication],
  );

  const buttonLabel = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariation.ViewAndAcceptOfferButtonLabel')
        : t('Content.YourUnloanOffer.ViewAndAcceptOfferButtonLabel'),
    [isTopUpApplication],
  );

  // Used to determine whether loan application was approved using docusign flow or fms flow
  const envelopeId =
    data?.loan_application_by_pk?.docusign_envelope?.envelope_id;

  if (reviewApplicationLoading || dealSummaryLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenLoadingContainer loading centered flex={1} />
      </LoanApplicationScreenContainer>
    );
  }

  if (getFmsEsignSignerSummaryError) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={getFmsEsignSignerSummaryError}
          refetch={refetch}
          displayMessage={t(
            'Content.YourUnloanOffer.GetFmsEsignSignerSummaryError',
          )}
        />
      </LoanApplicationScreenContainer>
    );
  }

  if (reviewApplicationError) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={reviewApplicationError}
          refetch={refetch}
          displayMessage={t(
            'Content.YourUnloanOffer.GetReviewApplicationError',
          )}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: data?.loan_application_by_pk,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  // If the loan application was approved using docusign flow
  // it should open docusign screen, else try to open fms portal.
  const onPressButton = envelopeId
    ? {
        onPress: openDocusignContract,
      }
    : {
        onPress: () => openPortalUrl(loanApplicationId, portalUrl),
        disabled: !portalUrl,
      };

  const showLinkToLmiFactSheet =
    !!data?.loan_application_by_pk?.is_lmi_applicable;

  return (
    <LoanApplicationScreenContainer pb="m" px="m">
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={headerTitle}
        caption={headerCaption}
        contentContainer
        alignSelf="center"
      />
      <StyledText mt="m" variant="caption" textAlign="center">
        {footerText}
      </StyledText>
      <Button
        py="m"
        mt="m"
        width="100%"
        label={buttonLabel}
        testID={TestID.YourUnloanOffer.ViewAndAcceptOfferButton}
        {...onPressButton}
      />
      <Separator spacer />
      <ApplicationSummaryDetailsV2
        screen={Screen.LOAN_APPLICATION_V2_OFFER}
        mode="VIEW"
        sectionData={sectionData}
        showLinkToLmiFactSheet={showLinkToLmiFactSheet}
        showDependentsAndFutureLivingSituation
      />
    </LoanApplicationScreenContainer>
  );
}

export const YourUnloanOfferV2 = withAuthenticationRequired(
  YourUnloanOfferV2Base,
);
