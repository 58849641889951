import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import { NavigatorModalScreen } from '../../navigation/NavigatorModal';
import { Screen } from '../../navigation/types/screens';
import { useOutOfService } from '../../OutOfService';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { StyledImage } from '../../ui/atoms/StyledImage';
import { useAuthContext } from '../context';
import { setNotShownFaceIdPrompt } from '../hasShownFaceIdPrompt';
import { useAuth } from '../hooks';
import { AuthScreenProps } from '../navigation/types';

type Props = AuthScreenProps;

export function NativeAuthFlow({ navigation }: Props) {
  const { login, loading, isAuthenticated, isLoggedIn } = useAuth();
  /**
   * Besides initial login which user will always navigate to home screen, we want user to navigate to previous screen
   * When the app is boosted for the first time, isLoggedIn will be false and indicate it's for the initial login
   * If the user moves between background and foreground, we will try to get user back to previous screen
   */
  const initialLoginRef = useRef(false);

  useOutOfService();

  useEffect(() => {
    if (!isLoggedIn) {
      initialLoginRef.current = true;
    }
  }, [isLoggedIn]);

  const postLogin = useCallback(
    (firstLogin: boolean) => {
      // initial login
      if (firstLogin || navigation.getState().index === 0) {
        navigation.replace(Screen.MAIN_NAVIGATOR, {
          screen: Screen.HOME_DASHBOARD,
          params: { screen: Screen.HOME },
        });
      } else {
        navigation.goBack();
      }
    },
    [navigation],
  );

  useEffect(() => {
    if (!loading && isAuthenticated) {
      postLogin(initialLoginRef.current);
      initialLoginRef.current = false;
    }
  }, [loading, isAuthenticated, postLogin]);

  const authContext = useAuthContext();
  const rootNavigation = useNavigation();

  useEffect(() => {
    if (authContext?.shouldShowEnableFaceId) {
      setNotShownFaceIdPrompt();
      rootNavigation.navigate(Screen.NAVIGATOR_MODAL, {
        screen: NavigatorModalScreen.EnableFaceId,
      });
    }
  }, [authContext?.shouldShowEnableFaceId, rootNavigation]);

  return (
    <ScreenContainer p="m">
      <Box flex={1} alignItems="center" justifyContent="center">
        <StyledImage
          height={60}
          width={180}
          contentFit="contain"
          source={require('../../../assets/unloanBuiltByCBALogo.svg')}
        />
      </Box>
      <Button
        testID={TestID.NativeAuth.SignIn}
        label={t('Content.NativeAuth.SignIn')}
        disabled={loading}
        showSpinner={loading}
        onPress={() => login()}
      />
    </ScreenContainer>
  );
}
