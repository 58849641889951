import { isNotNullOrUndefined } from '@unloan/common-ui';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { sortHouseholdExpensesFields } from '../../ApplicationSummary/utils/remoteDataHelpers';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { ExpenseRow } from '../../Expenses/components/ExpenseRow';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useYourExpensesScreenQuery } from '../graphql/queries';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import { getYourExpensesModalFooterPropsV2 } from '../utils/getYourExpensesModalFooterPropsV2';
import { hasAllApplicantsDeclaredHousehold } from '../utils/hasAllApplicantsDeclaredHousehold';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES>;
const currentSection = LoanApplicationSection.Expense;

export function LoanApplicationYourExpensesV2Base({
  navigation,
  route,
}: Props) {
  const loanApplicationId = route.params?.loanApplicationId;

  const {
    applicants,
    householdExpenses,
    loading,
    error,
    refetch,
    loanApplication,
  } = useYourExpensesScreenQuery(loanApplicationId);

  const navigateToAddExpenseScreen = () => {
    navigation.navigate(Screen.YOUR_EXPENSES_V2_WIZARD, {
      screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
      params: {
        loanApplicationId: route.params?.loanApplicationId,
      },
    });
  };

  const { navigateToNextLoanApplicationScreen, getScreenOrder } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  const navigateToNextScreen = () => {
    navigateToNextLoanApplicationScreen({
      currentSection,
    });
  };

  const {
    primaryButtonLabel,
    onPrimaryButtonPress,
    disablePrimaryButton,
    footerCaption,
    footerCaptionError,
    isPrimaryButtonLoading,
  } = getYourExpensesModalFooterPropsV2({
    householdExpenses,
    applicants,
    loading,
    navigateToNextScreen,
    navigateToAddExpenseScreen,
  });

  const onExpenseRowPress = useCallback(
    (id: string) => {
      navigation.navigate(Screen.YOUR_EXPENSES_V2_WIZARD, {
        screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
        params: {
          ...route.params,
          householdId: id,
        },
      });
    },
    [navigation, route.params],
  );

  const onAddExpenseButtonPress = () => {
    navigation.navigate(Screen.YOUR_EXPENSES_V2_WIZARD, {
      screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
      params: {
        ...route.params,
        loanApplicationId,
      },
    });
  };

  if (loading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.YourExpenses.Header.Title')}
          caption={t('Content.YourExpenses.Header.Caption')}
        />
        <ScreenLoadingContainer loading />
      </LoanApplicationScreenContainer>
    );
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.YourExpenses.GetExpensesError')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.YourExpenses.Header.Title')}
        caption={t('Content.YourExpenses.Header.Caption')}
      />
      <ExpenseList
        householdExpenses={householdExpenses}
        onAddExpenseButtonPress={onAddExpenseButtonPress}
        onExpenseRowPress={onExpenseRowPress}
        applicants={applicants}
      />
      <LoanApplicationWizardFooter
        onPrimaryButtonPress={onPrimaryButtonPress}
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonTestID={TestID.LoanApplicationYourExpenses.ContinueButton}
        disablePrimaryButton={disablePrimaryButton}
        footerCaptionError={footerCaptionError}
        footerCaption={footerCaption}
        isPrimaryButtonLoading={isPrimaryButtonLoading}
      />
    </LoanApplicationScreenContainer>
  );
}

type ExpenseListProps = Pick<
  ReturnType<typeof useYourExpensesScreenQuery>,
  'householdExpenses' | 'applicants'
> & {
  onAddExpenseButtonPress: () => void;
  onExpenseRowPress: (id: string) => void;
};

function ExpenseList({
  householdExpenses,
  onAddExpenseButtonPress,
  onExpenseRowPress,
  applicants,
}: ExpenseListProps) {
  if (householdExpenses == null) {
    return null;
  }

  if (householdExpenses.length < 1) {
    return (
      <Box flex={1} alignItems="center" my="xl">
        <EmptyStateIllustration name="expensesV2" />
      </Box>
    );
  }

  return (
    <Box px="l" my="xl">
      {householdExpenses.map(
        ({ household_id, household_expenses_order, household }, index) =>
          household.map(
            ({
              living_situation,
              applicants: householdApplicants,
              unordered_household_expenses,
            }) => (
              <ExpenseRow
                key={household_id}
                expenseDetails={sortHouseholdExpensesFields({
                  expensesOrder: household_expenses_order,
                  unorderedHouseholdExpenses: unordered_household_expenses,
                })}
                id={household_id}
                onPress={onExpenseRowPress}
                householdOwners={householdApplicants
                  .map((a) => a.latest_full_name)
                  .filter(isNotNullOrUndefined)}
                index={index}
                testIDPrefix={TestID.LoanApplicationYourExpenses.HouseholdItem}
                livingSituation={living_situation}
              />
            ),
          ),
      )}
      {!hasAllApplicantsDeclaredHousehold(householdExpenses, applicants) ? (
        <Button
          label={t('Content.YourExpenses.ButtonLabel.AddExpenses')}
          icon="add"
          tertiary
          onPress={onAddExpenseButtonPress}
          testID={TestID.LoanApplicationYourExpenses.AddExpensesButton}
          fontWeight="normal"
        />
      ) : null}
    </Box>
  );
}

export const LoanApplicationYourExpensesV2 = withAuthenticationRequired(
  LoanApplicationYourExpensesV2Base,
);
