import {
  Frequency_Enum,
  Property_Purpose_Enum,
  Property_Type_Enum,
} from '../../generated/graphql';
import { Box } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  formatCurrency,
  formatCurrencyWithPeriod,
} from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';

type PropertyDetailsCaptionProps = {
  propertyType: Property_Type_Enum | null;
  propertyValue: number | null;
  propertyPurpose: Property_Purpose_Enum | null;
  testId?: string;
};
export function PropertyDetailsCaption(props: PropertyDetailsCaptionProps) {
  const { propertyType, propertyValue, propertyPurpose, testId } = props;

  const parsedPropertyType = parseEnumType(Property_Type_Enum, propertyType);
  const parsedPropertyPurpose = parseEnumType(
    Property_Purpose_Enum,
    propertyPurpose,
  );

  return (
    <Box testID={testId} px="m" pb="s">
      <Separator mx="xs" mb="s" />
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t(`Content.PropertyDetails.Caption.PropertyType`)}
        </StyledText>
        <StyledText variant="caption">
          {parsedPropertyType
            ? t(`Content.Common.PropertyType.${parsedPropertyType}`)
            : '-'}
        </StyledText>
      </Box>
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t('Content.PropertyDetails.Caption.EstimatedPropertyValue')}
        </StyledText>
        <StyledText variant="caption">
          {propertyValue ? formatCurrency(propertyValue) : '-'}
        </StyledText>
      </Box>
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t(`Content.PropertyDetails.Caption.PropertyPurpose`)}
        </StyledText>
        <StyledText variant="caption">
          {parsedPropertyPurpose
            ? t(`Content.Common.PropertyPurpose.${parsedPropertyPurpose}`)
            : '-'}
        </StyledText>
      </Box>
    </Box>
  );
}

type PropertyDetailsCaptionForPurchaseProps = PropertyDetailsCaptionProps &
  Partial<{
    expectedRentalIncome: number;
    expectedRentalExpense: number;
    rentalIncomeFrequency: Frequency_Enum;
    rentalExpenseFrequency: Frequency_Enum;
  }>;
export function PropertyDetailsCaptionForPurchase(
  props: PropertyDetailsCaptionForPurchaseProps,
) {
  const {
    propertyType,
    propertyValue,
    propertyPurpose,
    testId,
    expectedRentalExpense,
    expectedRentalIncome,
    rentalExpenseFrequency,
    rentalIncomeFrequency,
  } = props;

  const parsedPropertyType = parseEnumType(Property_Type_Enum, propertyType);
  const parsedPropertyPurpose = parseEnumType(
    Property_Purpose_Enum,
    propertyPurpose,
  );

  const expectedRentalIncomeValue = expectedRentalIncome
    ? formatCurrencyWithPeriod(
        expectedRentalIncome,
        rentalIncomeFrequency,
        true,
      )
    : null;

  const expectedRentalExpenseValue = expectedRentalExpense
    ? formatCurrencyWithPeriod(
        expectedRentalExpense,
        rentalExpenseFrequency,
        true,
      )
    : null;

  return (
    <Box testID={testId} px="m" pb="s">
      <Separator mx="xs" mb="s" />
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t(`Content.PropertyDetails.Caption.PropertyType`)}
        </StyledText>
        <StyledText variant="caption">
          {parsedPropertyType
            ? t(`Content.Common.PropertyType.${parsedPropertyType}`)
            : '-'}
        </StyledText>
      </Box>
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t('Content.PropertyDetails.Caption.PurchasePrice')}
        </StyledText>
        <StyledText variant="caption">
          {propertyValue ? formatCurrency(propertyValue) : '-'}
        </StyledText>
      </Box>
      <Box row pb="2xs" justifyContent="space-between">
        <StyledText variant="caption">
          {t(`Content.PropertyDetails.Caption.PropertyPurpose`)}
        </StyledText>
        <StyledText variant="caption">
          {parsedPropertyPurpose
            ? t(`Content.Common.PropertyPurpose.${parsedPropertyPurpose}`)
            : '-'}
        </StyledText>
      </Box>
      {!!expectedRentalIncome && (
        <Box row pb="2xs" justifyContent="space-between">
          <StyledText variant="caption">
            {t(`Content.PropertyDetails.Caption.ExpectedRentalIncome`)}
          </StyledText>
          <StyledText variant="caption">{expectedRentalIncomeValue}</StyledText>
        </Box>
      )}
      {!!expectedRentalExpense && (
        <Box row pb="2xs" justifyContent="space-between">
          <StyledText variant="caption">
            {t(`Content.PropertyDetails.Caption.ExpectedRentalExpense`)}
          </StyledText>
          <StyledText variant="caption">
            {expectedRentalExpenseValue}
          </StyledText>
        </Box>
      )}
    </Box>
  );
}
