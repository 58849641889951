import { Rental_Income_Type_Enum } from '../../generated/graphql';
import { useYourFinancialsScreenQuery } from '../graphql/queries';

type Incomes = ReturnType<typeof useYourFinancialsScreenQuery>['incomes'];
export function checkExpectedRentalIncomeExisted(incomes: Incomes) {
  const expectedRentalIncome = incomes?.filter(
    ({ rental_income }) =>
      rental_income?.rental_income_type === Rental_Income_Type_Enum.Expected,
  );
  return (expectedRentalIncome?.length ?? 0) > 0;
}
