import { Text } from 'dripsy';
import { Formik } from 'formik';
import { useCallback } from 'react';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import { Form, FormTextInputV2 } from '../../../components/form/FormikInputs';
import { FormSpacer } from '../../../components/form/FormSpacer';
import { SubmitButton } from '../../../components/form/SubmitButton';
import { HomeHeader } from '../../../Home/components/HomeHeader';
import { HomeTabScreenProps } from '../../../Home/navigation/types';
import { Screen } from '../../../navigation/types/screens';
import { UpliftScreenContainer } from '../../../ui/atoms/ScreenContainer';
import { FormikFormError } from '../../../ui/v2/FormError';
import {
  AccountField,
  accountValidationSchema,
  AddExternalAccountFormValues,
  ProcessAccountStatus,
} from './models';
import { useAddExternalAccount } from './useAddExternalAccount';

type Props = HomeTabScreenProps<Screen.ADD_NEW_ACCOUNT>;

const initialAccount: AddExternalAccountFormValues = {
  [AccountField.AccountBsb]: '',
  [AccountField.AccountName]: '',
  [AccountField.AccountNumber]: '',
};

export const AddNewAccount = ({ navigation, route }: Props) => {
  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const { cbaAccountId, onNavigateBack } = route.params;

  const { addExternalAccount, loading } = useAddExternalAccount(cbaAccountId);

  const onSubmit = useCallback(
    async (values: AddExternalAccountFormValues) => {
      const [result, error] = await addExternalAccount(values);
      if (!result?.data?.add_external_account?.returning?.id || error) {
        navigation.goBack();
        onNavigateBack(ProcessAccountStatus.Fail);
        return;
      }
      navigation.goBack();
      onNavigateBack(ProcessAccountStatus.Success);
    },
    [addExternalAccount, navigation, onNavigateBack],
  );

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader title={t('Content.AddNewAccount.Header')} />

      <Formik
        initialValues={initialAccount}
        onSubmit={onSubmit}
        validationSchema={accountValidationSchema}
      >
        {(formProps) => (
          <Form overflow="visible">
            <FormTextInputV2
              inputTestID={TestID.AddNewAccount.AccountName}
              name={AccountField.AccountName}
              label={t('Content.AddNewAccount.AccountName')}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Accounts,
                Screen.ADD_NEW_ACCOUNT,
                FieldInteractionKey.AccountName,
              )}
            />
            <FormikFormError
              name={AccountField.AccountName}
              sx={{ mt: '$8' }}
            />
            <FormSpacer />

            <FormTextInputV2
              inputTestID={TestID.AddNewAccount.AccountBsb}
              name={AccountField.AccountBsb}
              label={t('Content.AddNewAccount.AccountBsb')}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Accounts,
                Screen.ADD_NEW_ACCOUNT,
                FieldInteractionKey.AccountBsb,
              )}
            />
            <FormikFormError name={AccountField.AccountBsb} sx={{ mt: '$8' }} />

            <FormSpacer />

            <FormTextInputV2
              inputTestID={TestID.AddNewAccount.AccountNumber}
              name={AccountField.AccountNumber}
              label={t('Content.AddNewAccount.AccountNumber')}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Accounts,
                Screen.ADD_NEW_ACCOUNT,
                FieldInteractionKey.AccountNumber,
              )}
            />
            <FormikFormError
              name={AccountField.AccountNumber}
              sx={{ mt: '$8' }}
            />

            <Text variant="caption" sx={{ my: '$8' }}>
              {t('Content.AddNewAccount.Description')}
            </Text>

            <FormSpacer />

            <SubmitButton
              testID={TestID.Withdraw.TransferButton}
              label={t('Content.AddNewAccount.Continue')}
              onPress={() => formProps.handleSubmit()}
              sx={{ mt: 18 }}
              disabled={loading}
            />
          </Form>
        )}
      </Formik>
    </UpliftScreenContainer>
  );
};
