import { isObject } from 'formik';

import { FieldValidationError } from '../../utils/yup';
import { BorrowerDetailsField } from './borrowerFormUtil';

export const buildBorrowerDetailsErrorMessage = (
  name: BorrowerDetailsField,
  error: string,
) => {
  // Address error code is contained within the props
  let errorCode = error;
  if (
    isObject(error) &&
    (name === BorrowerDetailsField.ResidentialAddress ||
      name === BorrowerDetailsField.MailingAddress)
  ) {
    const [_, value] = Object.entries(error)?.[0] ?? ['', ''];
    errorCode = value;
  }

  switch (errorCode) {
    case FieldValidationError.Required:
      switch (name) {
        case BorrowerDetailsField.FirstName:
          return t('Content.Borrower.DetailsForm.Error.FirstNameRequired');
        case BorrowerDetailsField.LastName:
          return t('Content.Borrower.DetailsForm.Error.LastNameRequired');
        case BorrowerDetailsField.DateOfBirth:
          return t('Content.Borrower.DetailsForm.Error.DateOfBirthRequired');
        case BorrowerDetailsField.Citizenship:
          return t('Content.Borrower.DetailsForm.Error.CitizenshipRequired');
        case BorrowerDetailsField.Gender:
          return t('Content.Borrower.DetailsForm.Error.GenderRequired');
        case BorrowerDetailsField.ResidentialAddress:
          return t(
            'Content.Borrower.DetailsForm.Error.ResidentialAddressRequired',
          );
        case BorrowerDetailsField.MailingAddress:
          return t('Content.Borrower.DetailsForm.Error.MailingAddressRequired');
        default:
          return null;
      }

    case FieldValidationError.InvalidName:
      switch (name) {
        case BorrowerDetailsField.FirstName:
          return t('Content.Borrower.DetailsForm.Error.FirstNameInvalid');
        case BorrowerDetailsField.MiddleName:
          return t('Content.Borrower.DetailsForm.Error.MiddleNameInvalid');
        case BorrowerDetailsField.LastName:
          return t('Content.Borrower.DetailsForm.Error.LastNameInvalid');
        case BorrowerDetailsField.OtherNames:
          return t('Content.Borrower.DetailsForm.Error.OtherNamesInvalid');
        default:
          return null;
      }

    case FieldValidationError.InvalidDateFormat:
      switch (name) {
        case BorrowerDetailsField.DateOfBirth:
          return t('Content.Borrower.DetailsForm.Error.InvalidDOBFormat');
        default:
          return null;
      }

    case FieldValidationError.InvalidDOB:
      return t('Content.Borrower.DetailsForm.Error.InvalidDOB');
    case FieldValidationError.AgeRequirement:
      return t('Content.Borrower.DetailsForm.Error.AgeRequirement');
    case FieldValidationError.DuplicateFullName:
      return t('Content.Borrower.DetailsForm.Error.DuplicateFullName');
    default:
      return null;
  }
};
