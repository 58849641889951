import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { SvgIconProps } from './types';

export const MoreIcon: React.FC<SvgIconProps> = ({ color, size, ...props }) => {
  const theme = useTheme();
  return (
    <Svg width={size} height={size} viewBox="0 0 30 30" fill="none" {...props}>
      <Path
        d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
        fill={theme.colors.buttonDisabledBg}
      />
      <Path
        d="M17.0625 14.75C17.0625 13.6328 16.1172 12.6875 15 12.6875C13.8398 12.6875 12.9375 13.6328 12.9375 14.75C12.9375 15.9102 13.8398 16.8125 15 16.8125C16.1172 16.8125 17.0625 15.9102 17.0625 14.75ZM22.2188 12.6875C21.0586 12.6875 20.1562 13.6328 20.1562 14.75C20.1562 15.9102 21.0586 16.8125 22.2188 16.8125C23.3359 16.8125 24.2812 15.9102 24.2812 14.75C24.2812 13.6328 23.3359 12.6875 22.2188 12.6875ZM7.78125 12.6875C6.62109 12.6875 5.71875 13.6328 5.71875 14.75C5.71875 15.9102 6.62109 16.8125 7.78125 16.8125C8.89844 16.8125 9.84375 15.9102 9.84375 14.75C9.84375 13.6328 8.89844 12.6875 7.78125 12.6875Z"
        fill={color}
      />
    </Svg>
  );
};
