import { gql } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  refetchGetViewerLoanApplicationsQuery,
  useGetLoanApplicationTasksQuery,
  useProcessIdvCheckMutation,
  useSubmitDocumentsForVerifyMutation,
} from '../../generated/graphql';
import { useStartIDValidation } from '../../Identification/hooks/useStartIDValidation';
import { IdvDisplayError } from '../../Identification/utils/identityCheckUtils';
import { Screen } from '../../navigation/types/screens';
import { getIdvErrorMessage } from '../../utils/getErrorMessage';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useExperimentFeatureFlag } from '../../utils/hooks/useExperimentFeatureFlag';
import { useOnfidoEvents } from '../../utils/hooks/useOnfidoEvents';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationTasks } from '../components/LoanApplicationTasks';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  onfidoCompletedEvents,
  OnfidoEventOrigin,
} from '../utils/idValidationEventEmitter';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

type FinaliseLoanApplicationScreenError = {
  getTokenError: Error | undefined;
  processIDVError: Error | undefined;
  submitDocsToVerifyError: Error | undefined;
  idvError: IdvDisplayError | null;
};
function getErrorMessage({
  getTokenError,
  processIDVError,
  submitDocsToVerifyError,
  idvError,
}: FinaliseLoanApplicationScreenError) {
  if (getTokenError || processIDVError || idvError != null) {
    return getIdvErrorMessage({
      getTokenError,
      processIDVError,
      idvError,
    });
  }

  if (submitDocsToVerifyError) {
    return t('Content.Common.Error.FailSubmitDocumentEvidence');
  }

  return null;
}

export const SubmitDocumentsForVerify = gql`
  mutation SubmitDocumentsForVerify($loanApplicationId: uuid!) {
    submit_documents_for_verify(loan_application_id: $loanApplicationId) {
      loan_application_id
    }
  }
`;

/**
 * While the props are the same, but the routes is actually different.
 * Here's the list of routes where this screen component is used.
 * - Screen.LOAN_APPLICATION_V2_SUBMIT
 * - Screen.LOAN_APPLICATION_TOP_UP_SUBMIT
 * - Screen.LOAN_APPLICATION_PURCHASE_SUBMIT
 */
export type FinaliseLoanApplicationProps = LoanApplicationV2ScreenProps<
  | Screen.LOAN_APPLICATION_V2_SUBMIT
  | Screen.LOAN_APPLICATION_PURCHASE_SUBMIT
  | Screen.LOAN_APPLICATION_TOP_UP_SUBMIT
>;

function FinaliseLoanApplicationBase({
  navigation,
  route,
}: FinaliseLoanApplicationProps) {
  const {
    featureFlag: enableEvidenceScreenUplift,
    loading: enableEvidenceScreenUpliftLoading,
  } = useExperimentFeatureFlag(FeatureFlag.EnableEvidenceScreenUplift);

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const { loanApplicationId = '', _initialSubmitErrorMessage } =
    route.params || {};

  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);

  const { data, loading, error, refetch } = useGetLoanApplicationTasksQuery({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
        screen: route.name,
      },
    },
  });

  useFocusEffect(
    useCallback(() => {
      if (loanApplicationId) {
        refetch();
      }
    }, [loanApplicationId, refetch]),
  );

  const [submit, { loading: isSubmitting, error: submitError }] =
    useSubmitDocumentsForVerifyMutation({
      refetchQueries: [refetchGetViewerLoanApplicationsQuery()],
      awaitRefetchQueries: true,
    });

  const { startIDValidation, createIDVCheckMutationResult, idvError } =
    useStartIDValidation({
      loanApplicationId,
      completedEvent:
        onfidoCompletedEvents[OnfidoEventOrigin.FinaliseLoanApplication],
    });

  const [processIDVCheck, processIDVCheckMutationResult] =
    useProcessIdvCheckMutation();

  const { loanApplicationType, navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  useOnfidoEvents({
    loanApplicationId,
    loanApplicationType,
    startIDValidation,
    processIDVCheck,
    onfidoEventOrigin: OnfidoEventOrigin.FinaliseLoanApplication,
    navigation,
  });

  if (loading || enableEvidenceScreenUpliftLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.FinaliseYourApplication.ScreenHeader')}
          mb="xl"
        />
        <ScreenLoadingContainer
          loading
          testID={TestID.FinaliseLoanApplication.LoadingIndicator}
        />
      </LoanApplicationScreenContainer>
    );
  }

  // Wrong or invalid loan application id
  // will hit error block instead of the loan application validation
  // because we rely on hasura action, which returns
  // error instead of null on loan application not found.
  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t(
            'Content.FinaliseYourApplication.Error.FailedToFetchLoanApplicationTasks',
          )}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: data?.loan_application_by_pk,
  });
  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const onSubmit = async () => {
    const [, onSubmitError] = await safelyCallMutation(submit, {
      variables: { loanApplicationId },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
    if (!onSubmitError) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              route.name,
              FieldInteractionKey.Finish,
            ),
        },
      });
      navigateToNextLoanApplicationScreen({
        currentSection: LoanApplicationSection.Submit,
      });
    }
  };

  const errorMessage = getErrorMessage({
    submitDocsToVerifyError:
      submitError ??
      (_initialSubmitErrorMessage
        ? new Error(_initialSubmitErrorMessage)
        : undefined),
    processIDVError: processIDVCheckMutationResult.error,
    getTokenError: createIDVCheckMutationResult.error,
    idvError,
  });

  const hasCompletedAllTasks =
    data?.get_loan_application_tasks.allow_to_proceed;
  const tasks = data?.get_loan_application_tasks.loan_application_tasks;

  return (
    <LoanApplicationScreenContainer px="m">
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.FinaliseYourApplication.ScreenHeader')}
        caption={
          enableEvidenceScreenUplift
            ? t('Content.FinaliseYourApplication.ScreenCaptionV2')
            : t('Content.FinaliseYourApplication.ScreenCaption')
        }
        mb="xl"
      />
      <ErrorRow
        message={errorMessage}
        testID={TestID.FinaliseLoanApplication.FinaliseLoanApplicationErrorRow}
      />
      <LoanApplicationTasks
        loanApplicationId={loanApplicationId}
        tasks={tasks}
        currentLoggedInApplicantIDVLoading={
          createIDVCheckMutationResult.loading ||
          processIDVCheckMutationResult.loading
        }
        enableEvidenceScreenUplift={enableEvidenceScreenUplift}
        /**
         * Why we use route.name instead of explicitly passing the screen name?
         * Because this screen component is actually used on different routes
         * in our navigation stack.
         */
        parentScreenName={route.name}
      />
      <LoanApplicationWizardFooter
        mt="l"
        px={0}
        primaryButtonLabel={t('Content.Common.ButtonLabel.Finish')}
        onPrimaryButtonPress={onSubmit}
        primaryButtonTestID={
          TestID.FinaliseLoanApplication.LoanApplicationSubmitButton
        }
        secondaryButtonLabel={t(
          'Content.FinaliseYourApplication.ResumeLaterButtonLabel',
        )}
        onSecondaryButtonPress={navigateToHome}
        secondaryButtonTestID={TestID.FinaliseLoanApplication.ResumeLaterButton}
        footerCaption={
          hasCompletedAllTasks
            ? undefined
            : t('Content.FinaliseYourApplication.FooterCaption')
        }
        isPrimaryButtonLoading={isSubmitting}
        disablePrimaryButton={isSubmitting || !hasCompletedAllTasks}
      />
    </LoanApplicationScreenContainer>
  );
}

export const FinaliseLoanApplication = withAuthenticationRequired(
  FinaliseLoanApplicationBase,
);
