import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { CloseModalIconButton } from '../../components/CloseModalIconButton';
import { ErrorRow } from '../../components/ErrorRow';
import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { DocumentUrl } from '../../constants/documentUrls';
import { useGetBizaAccountByIdQuery } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { useEnableDataSharing } from '../../Settings/utils/useDataSharing';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { DataSharingConfirmationProps } from '../navigation/types';
import { BizaAccount } from '../types';
import { getBizaAccount } from '../utils/biza';

type Props = DataSharingConfirmationProps<Screen.DATA_SHARING_SUMMARY>;

const SummaryTitle: React.FC = () => (
  <>
    <Separator spacer />
    <StyledText variant="header" textAlign="center">
      {t('Content.DataSharingSummary.Title')}
    </StyledText>
  </>
);

const SummaryMessage: React.FC = () => (
  <>
    <Separator spacer my="s" />
    <StyledText variant="caption" textAlign="center" mx="m">
      {t('Content.DataSharingSummary.Text')}
    </StyledText>
    <Separator spacer />
    <StyledText variant="caption" textAlign="center" mx="m">
      {t('Content.DataSharingSummary.SubText')}
    </StyledText>
  </>
);

const ChangeSummary: React.FC<{ accountNumber: string }> = ({
  accountNumber,
}) => (
  <Box mx="m">
    <Separator spacer my="s" />
    <ListRow
      label={t('Content.DataSharingSummary.Account')}
      labelFontWeight="semiBold"
      placeholder={accountNumber}
    />
    <ListRow
      label={t('Content.DataSharingSummary.DataSharing')}
      last
      labelFontWeight="semiBold"
      placeholder="Enable"
    />
  </Box>
);

const Caption: React.FC = () => (
  <>
    <Separator spacer />
    <StyledText variant="caption" mx="m">
      {t('Content.DataSharingSummary.Caption')}
    </StyledText>
  </>
);

const ConfirmButton: React.FC<{
  disabled: boolean;
  onPress: () => void;
  showSpinner: boolean;
}> = ({ disabled, onPress, showSpinner }) => (
  <>
    <Separator spacer my="s" />
    <Button
      label={t('Content.DataSharingPreferences.Confirm')}
      flex={1}
      width="100%"
      onPress={onPress}
      showSpinner={showSpinner}
      disabled={disabled}
    />
  </>
);

const Summary: React.FC<{
  account: BizaAccount;
  onClose: () => void;
  onConfirmation: () => void;
  loading: boolean;
  dataSharingError: boolean;
}> = ({ account, onClose, onConfirmation, loading, dataSharingError }) => {
  const { masked_account_number: accountNumber } = account;

  return (
    <Box mx="s">
      <CloseModalIconButton disabled={loading} onPress={onClose} />
      <Box mx="l">
        <SummaryTitle />
        <SummaryMessage />
        {accountNumber ? (
          <ChangeSummary accountNumber={accountNumber.toUpperCase()} />
        ) : (
          <ErrorRow
            message={t('Content.DataSharingPreferences.Error.AccountNotFound')}
          />
        )}
        <Caption />
        {dataSharingError ? (
          <>
            <Separator spacer my="xs" />
            <ErrorRow
              message={t('Content.DataSharingPreferences.Error.MutationError')}
            />
          </>
        ) : (
          <ConfirmButton
            disabled={!accountNumber || loading}
            onPress={onConfirmation}
            showSpinner={loading}
          />
        )}
        <Separator spacer />
        {/* Use re-usable component for this text */}
        <StyledText variant="caption" mx="m">
          {t('Content.ManageDataSharing.SharingDataWithFooter')}
          <Link href={DocumentUrl.CDRPolicy} variant="caption">
            {t('Content.ManageDataSharing.SharingDataWithFooterLink')}
          </Link>
        </StyledText>
      </Box>
    </Box>
  );
};

export const Content: React.FC<{
  accountId: string;
  closeModal: () => void;
  goToNextScreen: () => void;
}> = ({ accountId, closeModal, goToNextScreen }) => {
  const { data, loading, error } = useGetBizaAccountByIdQuery({
    variables: { accountId },
    context: {
      sentryContext: {
        bizaAccountId: accountId,
        description: 'Fetching Biza Account By Id.',
      },
    },
  });

  const {
    callDataSharingMutation,
    dataSharingError,
    dataSharingLoading,
    sharingComplete,
  } = useEnableDataSharing(accountId);

  // Show confirmation when query completes
  useEffect(() => {
    if (sharingComplete) goToNextScreen();
  }, [sharingComplete, goToNextScreen]);

  const onConfirmation = useCallback(
    () => callDataSharingMutation(),
    [callDataSharingMutation],
  );

  if (error)
    return (
      <ErrorRow
        message={t('Content.DataSharingPreferences.Error.FetchAccount')}
      />
    );

  if (loading)
    return <Spinner testID={TestID.DataSharingConfirmation.Summary.Spinner} />;

  const account = getBizaAccount(data);

  if (!account)
    return (
      <ErrorRow
        message={t('Content.DataSharingPreferences.Error.AccountNotFound')}
      />
    );

  return (
    <Summary
      account={account}
      onClose={closeModal}
      onConfirmation={onConfirmation}
      loading={dataSharingLoading}
      dataSharingError={!!dataSharingError}
    />
  );
};

export const DataSharingSummary: React.FC<Props> = ({ navigation, route }) => {
  const { accountId } = route.params;

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  const goToNextScreen = useCallback(
    () => navigation.replace(Screen.DATA_SHARING_CONFIRMATION),
    [navigation],
  );

  return (
    <ModalScreenContainer bg="bg" inset scrollable>
      <NavHeaderSpacer />
      <Content
        accountId={accountId}
        closeModal={closeModal}
        goToNextScreen={goToNextScreen}
      />
    </ModalScreenContainer>
  );
};
