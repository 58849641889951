import { endOfDay, subYears } from 'date-fns';
import { Asserts } from 'yup';

import {
  AppAddressFormat,
  BorrowerFormV2ContextValues,
  PickerOption,
  yupAddress,
} from '../../components/form/types';
import { Gender_Enum, Id_Type_Enum, State_Enum } from '../../generated/graphql';
import {
  getManualAddressYupSchema,
  getManualInputFieldDefaultValues,
  getManualInputFieldNames,
} from '../../ui/v2/ManualPropertyInputFields';
import { FieldValidationError } from '../../utils/buildFormError';
import { getMaximumDOB, getMinimumDate } from '../../utils/dateHelpers';
import { yup, yupDriverLicense, yupName } from '../../utils/yup';

export const DEFAULT_CITIZENSHIP = 'AU';

export enum BorrowerDetailsField {
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  OtherNames = 'otherNames',
  DateOfBirth = 'dateOfBirth',
  ResidentialAddress = 'residentialAddress',
  MailingAddress = 'mailingAddress',
  Gender = 'gender',
  Citizenship = 'citizenship',
  UseResidentialAddressAsMailingAddress = 'useResidentialAddressAsMailingAddress',
}

export enum UseResidentialAddressAsMailingAddressYesNoEnum {
  Yes = 'Yes',
  No = 'No',
}
export const manualResidentialAddressPrefix = 'manualResidentialAddressInput';
export const manualResidentialInputFieldNames = getManualInputFieldNames(
  manualResidentialAddressPrefix,
);

export const manualMailingAddressPrefix = 'manualMailingAddressInput';
export const manualMailingInputFieldNames = getManualInputFieldNames(
  manualMailingAddressPrefix,
);

export const BorrowerDetailLabel = {
  [BorrowerDetailsField.FirstName]: t(
    'Content.Borrower.DetailsForm.Name.FirstName',
  ),
  [BorrowerDetailsField.MiddleName]: t(
    'Content.Borrower.DetailsForm.Name.MiddleName',
  ),
  [BorrowerDetailsField.LastName]: t(
    'Content.Borrower.DetailsForm.Name.LastName',
  ),
  [BorrowerDetailsField.OtherNames]: t(
    'Content.Borrower.DetailsForm.OtherNames.OtherNames',
  ),
  [BorrowerDetailsField.DateOfBirth]: t(
    'Content.Borrower.DetailsForm.OtherInformation.DateOfBirth',
  ),
  [BorrowerDetailsField.ResidentialAddress]: t(
    'Content.Borrower.DetailsForm.Address.ResidentialAddress',
  ),
  [BorrowerDetailsField.MailingAddress]: t(
    'Content.Borrower.DetailsForm.Address.MailingAddress',
  ),
  [BorrowerDetailsField.Gender]: t(
    'Content.Borrower.DetailsForm.OtherInformation.Gender',
  ),
  [BorrowerDetailsField.Citizenship]: t(
    'Content.Borrower.DetailsForm.OtherInformation.Citizenship',
  ),
  [BorrowerDetailsField.UseResidentialAddressAsMailingAddress]: t(
    'Content.Borrower.DetailsForm.Address.UseAsMailingAddress',
  ),
};

export function generateDobValidationObject(maxDOB: Date, minDOB: Date) {
  return {
    [BorrowerDetailsField.DateOfBirth]: yup
      .date()
      .nullable()
      .typeError(FieldValidationError.InvalidDateFormat)
      .max(endOfDay(maxDOB), FieldValidationError.AgeRequirement)
      .min(endOfDay(minDOB), FieldValidationError.InvalidDOB)
      .required(),
  };
}

export const DOBValidationObject = generateDobValidationObject(
  getMaximumDOB(),
  getMinimumDate(),
);

export type FullName = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

type BorrowerDetailsValidationSchemaOptions = {
  primaryBorrowerFullName?: FullName;
};

const getBaseBorrowerDetailsValidationSchema = (
  options: BorrowerDetailsValidationSchemaOptions,
) =>
  yup.object({
    ...{
      [BorrowerDetailsField.FirstName]: yupName
        .test({
          test: (_a, testContext) => {
            const firstName =
              testContext.parent[BorrowerDetailsField.FirstName];
            const middleName =
              testContext.parent[BorrowerDetailsField.MiddleName];
            const lastName = testContext.parent[BorrowerDetailsField.LastName];

            return !isDuplicateFullName(
              {
                firstName,
                middleName,
                lastName,
              },
              options.primaryBorrowerFullName,
            );
          },
        })
        .required(),
      [BorrowerDetailsField.MiddleName]: yupName
        .test({
          test: (_a, testContext) => {
            const firstName =
              testContext.parent[BorrowerDetailsField.FirstName];
            const middleName =
              testContext.parent[BorrowerDetailsField.MiddleName];
            const lastName = testContext.parent[BorrowerDetailsField.LastName];

            return !isDuplicateFullName(
              {
                firstName,
                middleName,
                lastName,
              },
              options.primaryBorrowerFullName,
            );
          },
        })
        .nullable(),
      [BorrowerDetailsField.LastName]: yupName
        .test({
          message: 'DuplicateFullName',
          test: (_a, testContext) => {
            const firstName =
              testContext.parent[BorrowerDetailsField.FirstName];
            const middleName =
              testContext.parent[BorrowerDetailsField.MiddleName];
            const lastName = testContext.parent[BorrowerDetailsField.LastName];

            return !isDuplicateFullName(
              {
                firstName,
                middleName,
                lastName,
              },
              options.primaryBorrowerFullName,
            );
          },
        })
        .required(),
    },
    [BorrowerDetailsField.OtherNames]: yup.string().nullable(),
    ...DOBValidationObject,
    [BorrowerDetailsField.Gender]: yup
      .mixed<Gender_Enum>()
      .oneOf(Object.values(Gender_Enum))
      .required(),
    [BorrowerDetailsField.Citizenship]: yup.string().nullable().required(),
    [BorrowerDetailsField.ResidentialAddress]: yup
      .object()
      .when(manualResidentialInputFieldNames.isManualInput, {
        is: true,
        then: yupAddress().nullable(),
        otherwise: yupAddress().nullable().required(),
      }),
    [BorrowerDetailsField.UseResidentialAddressAsMailingAddress]: yup
      .array()
      .of(yup.mixed<UseResidentialAddressAsMailingAddressYesNoEnum>())
      .nullable(),
    [BorrowerDetailsField.MailingAddress]: yup
      .object()
      .when(
        [
          BorrowerDetailsField.UseResidentialAddressAsMailingAddress,
          manualMailingInputFieldNames.isManualInput,
        ],
        {
          is: (
            useResidentialAddressAsMailingAddress: UseResidentialAddressAsMailingAddressYesNoEnum,
            isManualInput: boolean,
          ) =>
            useResidentialAddressAsMailingAddress?.[0] ===
              UseResidentialAddressAsMailingAddressYesNoEnum.Yes ||
            isManualInput,
          then: yupAddress().nullable(),
          otherwise: yupAddress().nullable().required(),
        },
      ),
  });

export const getBorrowerDetailsValidationSchema = (
  options: BorrowerDetailsValidationSchemaOptions,
) =>
  getBaseBorrowerDetailsValidationSchema(options)
    .concat(getManualAddressYupSchema(manualResidentialAddressPrefix))
    .concat(
      getManualAddressYupSchema(manualMailingAddressPrefix, {
        dependencyFieldNames: [
          BorrowerDetailsField.UseResidentialAddressAsMailingAddress,
        ],
        dependencyCheckFunctions: [
          (isUsingResidentialAsMailingString) =>
            (
              isUsingResidentialAsMailingString as [
                UseResidentialAddressAsMailingAddressYesNoEnum,
              ]
            )?.[0] !== UseResidentialAddressAsMailingAddressYesNoEnum.Yes,
        ],
      }),
    );

type BorrowerFormValueFromSchema = yup.InferType<
  ReturnType<typeof getBaseBorrowerDetailsValidationSchema>
>;

export type BorrowerForm = Partial<
  Omit<BorrowerFormValueFromSchema, 'residentialAddress' | 'mailingAddress'>
> & {
  residentialAddress: AppAddressFormat | null;
  mailingAddress: AppAddressFormat | null;
} & Partial<{
    [manualResidentialInputFieldNames.isManualInput]: boolean;
    [manualResidentialInputFieldNames.postcode]: string;
    [manualResidentialInputFieldNames.state]: State_Enum;
    [manualResidentialInputFieldNames.streetName]: string;
    [manualResidentialInputFieldNames.streetNo]: string;
    [manualResidentialInputFieldNames.streetType]: PickerOption<string>;
    [manualResidentialInputFieldNames.suburb]: string;
    [manualResidentialInputFieldNames.unitNo]: string;

    [manualMailingInputFieldNames.isManualInput]: boolean;
    [manualMailingInputFieldNames.postcode]: string;
    [manualMailingInputFieldNames.state]: State_Enum;
    [manualMailingInputFieldNames.streetName]: string;
    [manualMailingInputFieldNames.streetNo]: string;
    [manualMailingInputFieldNames.streetType]: PickerOption<string>;
    [manualMailingInputFieldNames.suburb]: string;
    [manualMailingInputFieldNames.unitNo]: string;
  }>;

export const BorrowerDetailsInitialValues: BorrowerForm = {
  [BorrowerDetailsField.FirstName]: '',
  [BorrowerDetailsField.MiddleName]: '',
  [BorrowerDetailsField.LastName]: '',
  [BorrowerDetailsField.OtherNames]: '',
  [BorrowerDetailsField.DateOfBirth]: undefined,
  [BorrowerDetailsField.ResidentialAddress]: null,
  [BorrowerDetailsField.MailingAddress]: null,
  [BorrowerDetailsField.Gender]: undefined,
  [BorrowerDetailsField.Citizenship]: DEFAULT_CITIZENSHIP,
  [BorrowerDetailsField.UseResidentialAddressAsMailingAddress]: null,

  // Manual address input values
  [manualResidentialInputFieldNames.isManualInput]: undefined,
  [manualResidentialInputFieldNames.postcode]: undefined,
  [manualResidentialInputFieldNames.state]: undefined,
  [manualResidentialInputFieldNames.streetName]: undefined,
  [manualResidentialInputFieldNames.streetNo]: undefined,
  [manualResidentialInputFieldNames.streetType]: undefined,
  [manualResidentialInputFieldNames.suburb]: undefined,
  [manualResidentialInputFieldNames.unitNo]: undefined,

  [manualMailingInputFieldNames.isManualInput]: undefined,
  [manualMailingInputFieldNames.postcode]: undefined,
  [manualMailingInputFieldNames.state]: undefined,
  [manualMailingInputFieldNames.streetName]: undefined,
  [manualMailingInputFieldNames.streetNo]: undefined,
  [manualMailingInputFieldNames.streetType]: undefined,
  [manualMailingInputFieldNames.suburb]: undefined,
  [manualMailingInputFieldNames.unitNo]: undefined,
};

export enum BorrowerDriversLicenceField {
  StateOfIssue = 'stateOfIssue',
  LicenceNo = 'licenceNo',
  DriverLicenceCardNo = 'driverLicenceCardNo',
  ExpiryDate = 'expiryDate',
}

export const BorrowerDriversLicenceLabel = {
  [BorrowerDriversLicenceField.StateOfIssue]: t(
    'Content.Borrower.DriverLicenceForm.StateOfIssue',
  ),
  [BorrowerDriversLicenceField.LicenceNo]: t(
    'Content.Borrower.DriverLicenceForm.LicenceNo',
  ),
  [BorrowerDriversLicenceField.DriverLicenceCardNo]: t(
    'Content.Borrower.DriverLicenceForm.DriverLicenceCardNo',
  ),
  [BorrowerDriversLicenceField.ExpiryDate]: t(
    'Content.Borrower.DriverLicenceForm.ExpiryDate',
  ),
};

const NumericRegex = /^\d+$/;
const AlphanumericRegex = /^[a-zA-Z0-9]+$/;

const yupCardNoStringAlphaNumeric = (schema: yup.StringSchema) =>
  schema.matches(
    AlphanumericRegex,
    t('Content.Borrower.DriverLicenceForm.Error.AlphaNumericCardNo'),
  );
const yupCardNoStringAlphaNumericRequired = (schema: yup.StringSchema) =>
  yupCardNoStringAlphaNumeric(schema).required(
    t('Content.Borrower.DriverLicenceForm.Error.DriverLicenceCardNoRequired'),
  );
const yupCardNoStringNumericRequired = (schema: yup.StringSchema) =>
  schema
    .matches(
      NumericRegex,
      t('Content.Borrower.DriverLicenceForm.Error.NumericCardNo'),
    )
    .required(
      t('Content.Borrower.DriverLicenceForm.Error.DriverLicenceCardNoRequired'),
    )
    .nullable();
export const ExpiryDateValidationSchema = yup.object({
  [BorrowerDriversLicenceField.ExpiryDate]: yup
    .date()
    .typeError(t('Content.Borrower.DriverLicenceForm.Error.InvalidExpiryDate'))
    .required(t('Content.Borrower.DriverLicenceForm.Error.ExpiryDateRequired')),
});
export const getBorrowerDriversLicenceValidationSchema = (
  enableExpiryDateValidation: boolean,
  primaryBorrowerDriverLicenceNumber?: string | null,
) =>
  getBaseBorrowerDriversLicenceValidationSchema({
    primaryBorrowerDriverLicenceNumber,
  }).concat(
    enableExpiryDateValidation
      ? ExpiryDateValidationSchema
      : yup.object({
          [BorrowerDriversLicenceField.ExpiryDate]: yup
            .date()
            .typeError(FieldValidationError.InvalidDateFormat)
            .nullable(),
        }),
  );
export const getBaseBorrowerDriversLicenceValidationSchema = (options: {
  primaryBorrowerDriverLicenceNumber?: string | null;
}) =>
  yup.object({
    ...{
      [BorrowerDriversLicenceField.LicenceNo]: yupDriverLicense
        .test({
          message: t(
            'Content.Borrower.DriverLicenceForm.Error.DuplicateLicenceNumber',
          ),
          test: (licenceNumber) => {
            if (!options.primaryBorrowerDriverLicenceNumber) return true;
            return licenceNumber !== options.primaryBorrowerDriverLicenceNumber;
          },
        })
        .required(
          t('Content.Borrower.DriverLicenceForm.Error.LicenceNoRequired'),
        ),
    },
    [BorrowerDriversLicenceField.StateOfIssue]: yup
      .mixed<State_Enum>()
      .oneOf(Object.values(State_Enum))
      .required(
        t('Content.Borrower.DriverLicenceForm.Error.StateOfIssueRequired'),
      ),
    [BorrowerDriversLicenceField.DriverLicenceCardNo]: yup
      .string()
      .label(
        t('Content.Borrower.DriverLicenceForm.Error.DriverLicenceCardNoLabel'),
      )
      .when('stateOfIssue', (stateOfIssue: State_Enum, schema) => {
        switch (stateOfIssue) {
          case State_Enum.Act:
            return yupCardNoStringAlphaNumericRequired(schema).length(10);
          case State_Enum.Nsw:
            return yupCardNoStringNumericRequired(schema).length(10);
          case State_Enum.Sa:
            return yupCardNoStringAlphaNumericRequired(schema).length(9);
          case State_Enum.Tas:
            return yupCardNoStringAlphaNumericRequired(schema).length(9);
          case State_Enum.Wa:
            return yupCardNoStringAlphaNumericRequired(schema).min(8).max(10);
          case State_Enum.Nt:
            return yupCardNoStringNumericRequired(schema).min(6).max(8);
          case State_Enum.Qld:
            return yupCardNoStringAlphaNumericRequired(schema).length(10);
          case State_Enum.Vic:
            return yupCardNoStringAlphaNumericRequired(schema).length(8);
          default:
            return yupCardNoStringAlphaNumeric(schema).nullable();
        }
      }),
  });

export const BorrowerDriversLicenceInitialValues: BorrowerDriversLicenceFormValues =
  {
    [BorrowerDriversLicenceField.LicenceNo]: '',
    [BorrowerDriversLicenceField.StateOfIssue]: undefined,
    [BorrowerDriversLicenceField.DriverLicenceCardNo]: '',
  };

export type BorrowerDriversLicenceFormValues = Partial<
  yup.InferType<ReturnType<typeof getBorrowerDriversLicenceValidationSchema>> &
    Asserts<typeof ExpiryDateValidationSchema>
>;

export enum BorrowerPassportField {
  PassportNo = 'passportNo',
  ExpiryDate = 'expiryDate',
}

export const BorrowerPassportLabel = {
  [BorrowerPassportField.PassportNo]: t(
    'Content.Borrower.PassportForm.PassportNo',
  ),
  [BorrowerPassportField.ExpiryDate]: t(
    'Content.Borrower.PassportForm.ExpiryDate',
  ),
};

export const getBorrowerPassportValidationSchema = (options: {
  primaryBorrowerPassportNumber?: string | null;
}) =>
  yup.object({
    ...{
      [BorrowerPassportField.PassportNo]: yup
        .string()
        .test({
          message: t(
            'Content.Borrower.PassportForm.Error.DuplicatePassportNumber',
          ),
          test: (passportNumber) => {
            if (!options.primaryBorrowerPassportNumber) return true;
            return passportNumber !== options.primaryBorrowerPassportNumber;
          },
        })
        .required(t('Content.Borrower.PassportForm.Error.PassportNoRequired'))
        .matches(
          AlphanumericRegex,
          t('Content.Borrower.PassportForm.Error.PassportNoMustBe8Or9Digits'),
        )
        .min(
          8,
          t('Content.Borrower.PassportForm.Error.PassportNoMustBe8Or9Digits'),
        )
        .max(
          9,
          t('Content.Borrower.PassportForm.Error.PassportNoMustBe8Or9Digits'),
        ),
    },
    [BorrowerPassportField.ExpiryDate]: yup
      .date()
      .typeError(t('Content.Borrower.PassportForm.Error.InvalidExpiryDate'))
      .required(t('Content.Borrower.PassportForm.Error.ExpiryDateRequired'))
      .min(
        subYears(new Date(), 2),
        t(
          'Content.Borrower.PassportForm.Error.ExpiryDateInThePastMoreThan2Years',
        ),
      ),
  });

export const BorrowerPassportInitialValues: BorrowerPassportFormValues = {
  [BorrowerPassportField.PassportNo]: '',
  [BorrowerPassportField.ExpiryDate]: undefined,
};

export type BorrowerPassportFormValues = Partial<
  yup.InferType<ReturnType<typeof getBorrowerPassportValidationSchema>>
>;

export enum BorrowerIdentityDetailsField {
  IdentityType = 'identityType',
}

export const BorrowerIdentityDetailsLabel = {
  [BorrowerIdentityDetailsField.IdentityType]: t(
    'Content.Borrower.IdentityDetailsForm.IdentityType',
  ),
  [Id_Type_Enum.Passport]: t('Content.Borrower.IdentityDetailsForm.Passport'),
  [Id_Type_Enum.DriversLicence]: t(
    'Content.Borrower.IdentityDetailsForm.DriversLicence',
  ),
};

export type BorrowerIdentityDetailsFormValues = {
  [BorrowerIdentityDetailsField.IdentityType]: Id_Type_Enum | undefined;
} & BorrowerDriversLicenceFormValues &
  BorrowerPassportFormValues;

export const BorrowerIdentityDetailsInitialValues: BorrowerIdentityDetailsFormValues =
  {
    [BorrowerIdentityDetailsField.IdentityType]: undefined,
  };

export const InitialBorrowerFormV2ContextValues: BorrowerFormV2ContextValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  otherNames: '',
  dateOfBirth: undefined,
  residentialAddress: null,
  mailingAddress: null,
  licenceNo: '',
  gender: undefined,
  citizenship: DEFAULT_CITIZENSHIP,
  stateOfIssue: undefined,
  driverLicenceCardNo: '',
  expiryDate: undefined,
  passportNo: '',
  identityCheckResult: '',
  useResidentialAddressAsMailingAddress: null,
  loanApplicationId: null,
  applicantId: null,
  applicantRole: null,
  isCurrentLoggedInApplicant: false,
  identityType: Id_Type_Enum.DriversLicence,
  ...getManualInputFieldDefaultValues(manualResidentialAddressPrefix),
  ...getManualInputFieldDefaultValues(manualMailingAddressPrefix),
};

export function isDuplicateFullName(
  inputFullName: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  },
  comparisonFullName?: FullName,
) {
  if (
    !comparisonFullName ||
    !comparisonFullName.firstName ||
    !comparisonFullName.lastName
  )
    return false;

  const normalize = (name?: string | null) => name?.trim().toLowerCase() || '';

  return (
    normalize(inputFullName.firstName) ===
      normalize(comparisonFullName.firstName) &&
    normalize(inputFullName.middleName) ===
      normalize(comparisonFullName.middleName) &&
    normalize(inputFullName.lastName) === normalize(comparisonFullName.lastName)
  );
}
