import { ENVIRONMENT } from '../config';
import { isWeb } from './platformUtils';

export const GRAPHQL_URL_LOCAL_STORAGE_KEY = 'graphQLURL';
export const GRAPHQL_SUBSCRIPTION_URL_LOCAL_STORAGE_KEY =
  'graphQLSubscriptionURL';

function isCorrectEnvironment(currentEnv: ENVIRONMENT) {
  return (
    (currentEnv === ENVIRONMENT.DEV_V3 ||
      currentEnv === ENVIRONMENT.LOCAL_DEV) &&
    isWeb
  );
}

export function isUsingCustomBackend() {
  if (!isWeb) {
    return false;
  }

  const graphQLURL = localStorage?.getItem(GRAPHQL_URL_LOCAL_STORAGE_KEY);
  const graphQLSubscriptionURL = localStorage?.getItem(
    GRAPHQL_SUBSCRIPTION_URL_LOCAL_STORAGE_KEY,
  );

  return graphQLURL != null || graphQLSubscriptionURL != null;
}

function getGraphqlUrlFromSearchParam() {
  const urlParams = new URLSearchParams(window.location.search);
  const key = [...urlParams.keys()].find(
    (k) => k.toLowerCase() === 'graphqlurl',
  );
  return key ? urlParams.get(key) : null;
}

function getGraphQLOverwriteUrlParam() {
  const GRAPHQLPARAM = getGraphqlUrlFromSearchParam();

  if (GRAPHQLPARAM) {
    const GRAPHQLURL = decodeURIComponent(GRAPHQLPARAM ?? '');
    localStorage?.setItem(
      GRAPHQL_URL_LOCAL_STORAGE_KEY,
      encodeURIComponent(GRAPHQLPARAM),
    );
    return GRAPHQLURL;
  }

  const graphQLURL = localStorage?.getItem(GRAPHQL_URL_LOCAL_STORAGE_KEY);
  if (graphQLURL) {
    return decodeURIComponent(graphQLURL);
  }

  return null;
}

export function getGraphQLOverwriteUrl(
  environment: ENVIRONMENT,
  currentGraphQLUrl: string,
): string {
  if (!isCorrectEnvironment(environment)) return currentGraphQLUrl;
  return getGraphQLOverwriteUrlParam() || currentGraphQLUrl;
}

export function getGraphQLOverwriteSubscriptionUrl(
  environment: ENVIRONMENT,
  currentUrl: string,
): string {
  if (!isCorrectEnvironment(environment)) return currentUrl;

  const GRAPHQLPARAM = getGraphqlUrlFromSearchParam();

  if (GRAPHQLPARAM) {
    const GRAPHQLURL = decodeURIComponent(GRAPHQLPARAM ?? '');
    const parsedGraphQLURL = new URL(GRAPHQLURL);
    const newProtocol = parsedGraphQLURL.protocol === 'https:' ? 'wss:' : 'ws:';
    parsedGraphQLURL.protocol = newProtocol;
    localStorage?.setItem(
      GRAPHQL_SUBSCRIPTION_URL_LOCAL_STORAGE_KEY,
      encodeURIComponent(parsedGraphQLURL.toString()),
    );
    return parsedGraphQLURL.toString();
  }

  const graphQLURL = localStorage?.getItem(
    GRAPHQL_SUBSCRIPTION_URL_LOCAL_STORAGE_KEY,
  );
  if (graphQLURL) {
    return decodeURIComponent(graphQLURL);
  }

  return currentUrl;
}

/**
 * @returns the test support app URL to use based on the environment
 * and the graphql URL (if it was overwritten via query param).
 */
export function getTestSupportAppOverwriteUrl(
  environment: ENVIRONMENT,
  currentUrl?: string,
) {
  if (!isCorrectEnvironment(environment)) return currentUrl;

  const graphQLUrlParam = getGraphQLOverwriteUrlParam();
  if (!graphQLUrlParam) return currentUrl;

  try {
    const url = new URL(graphQLUrlParam);
    // e.g. https://api-dev.nonp.unloan.com.au/playpen1/gql/v1/graphql
    //   => https://api-dev.nonp.unloan.com.au/playpen1/testsupport
    return url.href.replace(/(\/gql.*)/, '/testsupport');
  } catch (_) {
    // Fallback to the current testSupportAppUrl if graphQLUrl is malformed
    return currentUrl;
  }
}

export function clearPersistedCustomBackend() {
  if (!isWeb) {
    return;
  }

  localStorage?.removeItem(GRAPHQL_URL_LOCAL_STORAGE_KEY);
  localStorage?.removeItem(GRAPHQL_SUBSCRIPTION_URL_LOCAL_STORAGE_KEY);
}
