import enAUConsent from '../../languages/en_AU/en_AU.consent.json';
import enAUContent from '../../languages/en_AU/en_AU.content.json';
import enAULink from '../../languages/en_AU/en_AU.links.json';

export const TRANSLATIONS = {
  en_AU: {
    Content: enAUContent,
    Consent: enAUConsent,
    Link: enAULink,
  },
};
export const DEFAULT_LOCALE = 'en_AU' as const;

const DEFAULT_DICTIONARY = TRANSLATIONS[DEFAULT_LOCALE];
export type TranslationDictionary = typeof DEFAULT_DICTIONARY;

// The current approach is not ideal.
// Right now we're deriving the keys of the dictionary from the JSON
// for the t() fn parameter.
// This has several weakness:
// 1. Cannot support pluralization without doing more complex TS typing
// that checks the final leaf in the dict to be object in the shape of
// Partial<{one: string, zero: string, other: string}>
// see https://github.com/fnando/i18n#pluralization
// 2. Rotting orphan translation.
// Unused translation are being left around even unused, making the dictionary
// JSON keep increasing in size.
// To solve this, we need a static analysis that checks for unused translation
// throughout the codebase.
// This can be implemented either using babel plugin or eslint rule,
// but requires the t() fn parameter to be a string literal, not a dynamic value.
// e.g. you must use t('abc'), t(`${myVariable}`) is not allowed.
// see https://github.com/gilbsgilbs/babel-plugin-i18next-extract for example
// 3. Hard to see what is the actual string being rendered from the code.
// We need to go to through the dict and find a matching path if any
// or see it from the running code.
// If supporting multiple language is a requirement in the future,
// we should consider moving to a different approach.
// The current approach is barely good enough for a single locale,
// and lacking real multi-locale support.
export type TranslateKeys = JoinNestedObjectPathIntoUnion<
  NestedObjectPathTuple<TranslationDictionary>,
  '.'
>;

export const NUMBER_REGEX = /^\d+$/;
export const ALPHANUMERIC_SEPARATED_BY_DASH_REGEX =
  /^([\d\w]+)(\s*-\s*([\d\w]+))?$/;
