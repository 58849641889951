import { View } from 'dripsy';
import { StyleSheet } from 'react-native';

import { TestID } from '../../testID/constants';
import { Box } from '../ui/atoms/Box';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { useTheme } from '../ui/theme';

type Props = {
  showBadge?: boolean;
  nativeID?: string;
};

export function ChatButton({ showBadge = false, nativeID }: Props) {
  const theme = useTheme();
  return (
    <View sx={{ position: 'relative' }} testID={TestID.ChatButton}>
      {showBadge ? <Box style={styles.redBadgePoint} /> : null}
      {/* This is a float element that used for binding a nativeID same as the Intercom custom launcher config in GTM, used for toggling intercom messenger.
        The issue in GTM is that it could not catch the outer element's nativeID, but only the most inner element be clicked.
        e.g. <div id="<configured id>"><button>Click</button></div> could not trigger the GTM event, but <div><button id="<configured id>">Click</button></div> could.
        So in this case, attaching a nativeID to the most outer Box element will not work, but attaching a nativeID to the inner Button element will work.
        While our custom <Button> component does not support nativeID, so here create a same size empty Box float on top of the Button to catch the nativeID.
        This is a workaround for the issue
    */}
      <View
        role="button"
        nativeID={nativeID}
        sx={{
          borderRadius: 50,
          width: theme.sizes['2m'],
          cursor: 'pointer',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.colors.buttonSecondaryBg,
        }}
      >
        <StyledIcon
          size="2s"
          family="svg"
          name="supportFilled"
          aria-label={t('Content.Accessibility.TopNavBar.ChatButtonLabel')}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  redBadgePoint: {
    backgroundColor: 'red',
    width: 10,
    height: 10,
    borderRadius: 50,
    position: 'absolute',
    right: 0,
    zIndex: 200,
  },
});
