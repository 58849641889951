import { FC, ReactNode, useState } from 'react';

import { useGetExternalAccountsQuery } from '../../generated/graphql';
import { Avatar } from '../../ui/atoms/Avatar';
import { Box } from '../../ui/atoms/Box';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { Separator } from '../../ui/atoms/Separator';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { ListRow, Props as ListRowProps } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { SearchBar } from '../../ui/organisms/SearchBar';
import { UnloanIcon } from '../../ui/svgs/UnloanIcon';
import { useTheme } from '../../ui/theme';
import { isLast } from '../../utils/arrayHelpers';
import { Account, AccountDetail, AccountType } from '../types';

type AccountItemProps = Account & {
  type: AccountType;
  last: boolean;
  left: ReactNode;
  onPress: () => void;
  selected: boolean;
};

const AccountItem: FC<AccountItemProps> = (props) => {
  const { selected, accountDetails, name, onPress } = props;

  const checkMark:
    | Pick<ListRowProps, 'rightIconName' | 'rightIconFamilyName'>
    | undefined = selected
    ? { rightIconName: 'tick', rightIconFamilyName: 'svg' }
    : undefined;

  return (
    <ListRow
      {...props}
      {...checkMark}
      label={name}
      caption={accountDetails}
      useArrow={false}
      onPress={onPress}
    />
  );
};

export const bankIcon = (account: Account, type: AccountType, size: number) => {
  if (type === 'Unloan') {
    return <UnloanIcon size={size} />;
  }

  if (type === 'Connected') {
    return <Avatar size="l" square url={account.icon} />;
  }

  return <StyledIcon family="fontAwesome" name="bank" />;
};

interface AccountListProps {
  type: AccountType;
  accounts: Array<Account>;
  last?: boolean;
  selectedAccountId?: string;
  onSelectAccount: (account: AccountDetail) => void;
}

function AccountList({
  type,
  accounts,
  last,
  selectedAccountId,
  onSelectAccount,
}: AccountListProps) {
  const theme = useTheme();

  return accounts.length ? (
    <>
      <ListRowGroup uppercaseHeader mx={0}>
        {accounts.map((account, i) => (
          <AccountItem
            key={account.name}
            {...account}
            left={bankIcon(account, type, theme.sizes.m)}
            last={isLast(accounts, i)}
            type={type}
            selected={account.externalAccountId === selectedAccountId}
            onPress={() => {
              onSelectAccount({ type, ...account });
            }}
          />
        ))}
      </ListRowGroup>
      {!last && <Separator spacer />}
    </>
  ) : null;
}

const useFilteredExternalAccountsQuery = (search: string) => {
  const { loading, data } = useGetExternalAccountsQuery();
  const accounts = data?.me?.[0]?.user?.identity_profile?.external_accounts;

  if (!accounts) {
    return { loading, accounts: null };
  }

  return {
    loading,
    accounts: accounts
      .filter((account) =>
        account.account_name.toLowerCase().includes(search.toLowerCase()),
      )
      .map(
        (account): Account => ({
          externalAccountId: account.id,
          maskedAccountNumber: account.masked_account_number ?? '',
          accountDetails: account.account_details ?? '',
          name: account.account_name,
          bsbNumber: account.account_bsb,
        }),
      ),
  };
};

type AccountSelectorProps = {
  onPressAddAccount: () => void;
  onSelectAccount: (account: AccountDetail) => void;
  selectedAccountId?: string;
};

export function AccountSelector({
  onPressAddAccount,
  onSelectAccount,
  selectedAccountId,
}: AccountSelectorProps) {
  const [search, setSearch] = useState('');

  const { loading, accounts } = useFilteredExternalAccountsQuery(search);

  if (loading || !accounts) {
    return null;
  }

  return (
    <>
      <Box mt="m">
        <SearchBar withSearchIcon onChangeText={setSearch} />
      </Box>
      <ScrollContainer pt="m">
        <AccountList
          type="Other"
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          onSelectAccount={onSelectAccount}
          last
        />
        <ListRowGroup mx={0} spacer>
          <ListRow
            label="Add New Account"
            color="link"
            last
            onPress={onPressAddAccount}
            isButton
          />
        </ListRowGroup>
      </ScrollContainer>
    </>
  );
}
