import { FetchResult } from '@apollo/client';
import { has } from 'lodash';

import { PickerOptions } from '../../components/form/types';
import {
  Create_Rental_Income_Error_Type,
  CreateEmploymentIncomeForV2Mutation,
  CreateRentalIncomeForV2Mutation,
  Employment_Income_Error_Type,
  Frequency_Enum,
  GetDetectedEmploymentIncomeResultForV2Query,
  GetEmploymentIncomeDetailForV2Query,
  GetExpectedRentalIncomeDetailQuery,
  GetOtherIncomeDetailForV2Query,
  GetRentalIncomeDetailForV2Query,
  Property_Type_Enum,
  Rental_Income_Type_Enum,
  Set_Rental_Income_Error_Type,
  State_Enum,
  UpdateEmploymentIncomeForV2Mutation,
  UpdateRentalIncomeForV2Mutation,
} from '../../generated/graphql';
import { parseEnumType } from '../../utils/ensureEnumType';
import { mapHasuraAddressToAppAddress } from '../../utils/mapToAppAddress';
import { assertUnreachable } from '../../utils/typesHelpers';
import { DividendIncomeFormDetails } from '../components/DividendIncomeForm';
import {
  DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES,
  EmploymentIncomeFormDetails,
  EmploymentIncomeFormFields,
} from '../components/EmploymentIncomeForm';
import {
  DEFAULT_EXPECTED_RENTAL_INCOME_INITIAL_VALUES,
  ExpectedRentalIncomeFormDetails,
  ExpectedRentalIncomeFormFields,
  expectedRentalIncomeManualInputFieldNames,
} from '../components/ExpectedRentalIncomeForm';
import { GovernmentIncomeFormDetails } from '../components/GovernmentIncomeForm';
import {
  DEFAULT_RENTAL_INCOME_INITIAL_VALUES,
  manualInputFieldNames,
  RentalIncomeFormDetails,
  RentalIncomeFormFields,
} from '../components/RentalIncomeForm';
import { IncomeYesNoEnum } from './incomeFormTypes';

function isUpdateEmploymentIncomeMutationData(
  data:
    | CreateEmploymentIncomeForV2Mutation
    | UpdateEmploymentIncomeForV2Mutation,
): data is UpdateEmploymentIncomeForV2Mutation {
  if (has(data, 'set_employment_income')) {
    return true;
  }
  return false;
}

export function getEmploymentIncomeMutationResponseError(
  res:
    | FetchResult<CreateEmploymentIncomeForV2Mutation>
    | FetchResult<UpdateEmploymentIncomeForV2Mutation>
    | null,
): {
  errorMessage: string | null;
  errorType: Employment_Income_Error_Type | null;
} {
  if (res == null) {
    // Unknown error
    return {
      errorMessage: t('Content.Common.Error.FailSaveEmploymentIncome'),
      errorType: null,
    };
  }

  const { data } = res;
  let errorType: Employment_Income_Error_Type | null | undefined;
  if (!data) {
    // No data returned from mutation
    return {
      errorMessage: t('Content.Common.Error.FailSaveEmploymentIncome'),
      errorType: null,
    };
  }

  if (isUpdateEmploymentIncomeMutationData(data)) {
    errorType = data.set_employment_income.error_type;
  } else {
    errorType = data.create_employment_income?.error_type;
  }

  if (errorType == null) {
    return {
      errorMessage: null,
      errorType: null,
    };
  }

  switch (errorType) {
    case Employment_Income_Error_Type.MaxEmploymentIncomeReached:
      return {
        errorMessage: t(
          'Content.EmploymentIncome.MoreThanOneEmploymentIncomeError',
        ),
        errorType: Employment_Income_Error_Type.MaxEmploymentIncomeReached,
      };
    default:
      assertUnreachable(errorType);
      return {
        errorMessage: t('Content.Common.Error.FailSaveEmploymentIncome'),
        errorType: null,
      };
  }
}

function isUpdateRentalIncomeMutationData(
  data: UpdateRentalIncomeForV2Mutation | CreateRentalIncomeForV2Mutation,
): data is UpdateRentalIncomeForV2Mutation {
  if (has(data, 'set_rental_income')) {
    return true;
  }
  return false;
}

export function getRentalIncomeMutationResponseErrorMessage(
  res:
    | FetchResult<UpdateRentalIncomeForV2Mutation>
    | FetchResult<CreateRentalIncomeForV2Mutation>
    | null,
  rentalIncomeType: Rental_Income_Type_Enum,
): string | null {
  const defaultErrorMessage =
    rentalIncomeType === Rental_Income_Type_Enum.Expected
      ? t('Content.Common.Error.FailSaveExpectedRentalIncome')
      : t('Content.Common.Error.FailSaveRentalIncome');

  if (res == null) {
    // Unknown error
    return defaultErrorMessage;
  }

  const { data } = res;
  let errorType:
    | Set_Rental_Income_Error_Type
    | Create_Rental_Income_Error_Type
    | null
    | undefined;
  if (data && isUpdateRentalIncomeMutationData(data)) {
    errorType = data?.set_rental_income.error_type;
  } else {
    errorType = data?.create_rental_income.error_type;
  }

  if (errorType == null) {
    return null;
  }

  switch (errorType) {
    case Set_Rental_Income_Error_Type.AddressConflictWithNonInvestmentSecuredProperty:
    case Create_Rental_Income_Error_Type.AddressConflictWithNonInvestmentSecuredProperty:
      return t('Content.Common.Error.InvestmentAddressConflict');
    case Set_Rental_Income_Error_Type.DuplicatedRentalIncomeAddress:
    case Create_Rental_Income_Error_Type.DuplicatedRentalIncomeAddress:
      return t('Content.Common.Error.DuplicatedRentalIncomeAddress');
    default:
      assertUnreachable(errorType);
      return defaultErrorMessage;
  }
}

export function mapPrepopulatedDataForEmploymentIncomeV2Form({
  detectedIncomeResults,
  applicantId,
}: {
  detectedIncomeResults:
    | GetDetectedEmploymentIncomeResultForV2Query['user_latest_detected_employment_income']
    | undefined;
  applicantId: string | undefined;
}): EmploymentIncomeFormDetails {
  // Only use latest result which is returned in the first index
  const detectedEmploymentIncomeResult = detectedIncomeResults?.[0];

  if (detectedEmploymentIncomeResult == null) {
    return {
      ...DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES,
      [EmploymentIncomeFormFields.PayeeApplicantId]: applicantId
        ? [applicantId]
        : undefined,
    };
  }
  return {
    ...DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES,
    [EmploymentIncomeFormFields.PayeeApplicantId]: applicantId
      ? [applicantId]
      : undefined,
    [EmploymentIncomeFormFields.Salary]:
      detectedEmploymentIncomeResult?.annual_salary || undefined,
    [EmploymentIncomeFormFields.Allowances]:
      detectedEmploymentIncomeResult?.annual_allowance || undefined,
    [EmploymentIncomeFormFields.Overtime]:
      detectedEmploymentIncomeResult?.annual_overtime || undefined,
    [EmploymentIncomeFormFields.EmployerName]:
      detectedEmploymentIncomeResult?.employer_name || undefined,
    [EmploymentIncomeFormFields.EmploymentType]:
      detectedEmploymentIncomeResult?.unloan_employment_type || undefined,
  };
}

function mapToIncomeYesNoEnum(value: boolean): IncomeYesNoEnum {
  return value ? IncomeYesNoEnum.Yes : IncomeYesNoEnum.No;
}

export function mapEmploymentIncomeInitialValuesForV2Form({
  queriedDetails,
  occupationTypes,
}: {
  queriedDetails: GetEmploymentIncomeDetailForV2Query | undefined;
  occupationTypes: PickerOptions<string>;
}): EmploymentIncomeFormDetails {
  // Only use latest result which is returned in the first index
  const incomeToMap = queriedDetails?.income?.[0];
  const {
    employer,
    employment_type: employmentType,
    annual_allowances: allowances,
    annual_bonus: bonus,
    annual_commissions: commissions,
    annual_overtime: overtime,
    occupation_type: thisIncomeOccupationType,
  } = incomeToMap?.employment_income || {};

  // There should only be one owner of employment income
  const incomeOwner = incomeToMap?.income_owners[0];
  const incomeOwnerApplicant = incomeOwner?.applicant;
  const {
    id: incomeOwnerApplicantId,
    hecs_loan_balance: hecsLoanBalance,
    is_repaying_help: isPayingHECS,
    has_voluntary_deductions: hasVoluntaryDeductions,
    can_stop_voluntary_deductions: canStopVoluntaryDeductions,
  } = incomeOwnerApplicant || {};

  return {
    ...DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES,
    [EmploymentIncomeFormFields.PayeeApplicantId]: incomeOwnerApplicantId
      ? [incomeOwnerApplicantId]
      : undefined,
    [EmploymentIncomeFormFields.Salary]: incomeToMap?.amount,
    [EmploymentIncomeFormFields.Allowances]: allowances ?? undefined,
    [EmploymentIncomeFormFields.Overtime]: overtime ?? undefined,
    [EmploymentIncomeFormFields.Bonuses]: bonus ?? undefined,
    [EmploymentIncomeFormFields.Commissions]: commissions ?? undefined,
    [EmploymentIncomeFormFields.EmployerName]: employer ?? undefined,
    [EmploymentIncomeFormFields.EmploymentType]: employmentType ?? undefined,
    [EmploymentIncomeFormFields.IsPayingHECS]: isPayingHECS
      ? IncomeYesNoEnum.Yes
      : IncomeYesNoEnum.No,
    [EmploymentIncomeFormFields.HecsLoanBalance]: hecsLoanBalance ?? undefined,
    [EmploymentIncomeFormFields.OccupationType]: thisIncomeOccupationType
      ? occupationTypes.find(({ value }) => value === thisIncomeOccupationType)
      : undefined,
    [EmploymentIncomeFormFields.HasVoluntaryDeductions]:
      hasVoluntaryDeductions != null
        ? mapToIncomeYesNoEnum(hasVoluntaryDeductions)
        : undefined,
    [EmploymentIncomeFormFields.CanStopVoluntaryDeductions]:
      canStopVoluntaryDeductions != null
        ? mapToIncomeYesNoEnum(canStopVoluntaryDeductions)
        : undefined,
  };
}

export function mapExpectedRentalIncomeInitialValuesForm({
  queriedDetails,
}: {
  queriedDetails: GetExpectedRentalIncomeDetailQuery | undefined;
}): ExpectedRentalIncomeFormDetails {
  const {
    amount: rentalIncome,
    frequency: rentalIncomeFrequency,
    rental_income: rentalIncomeDetails,
  } = queriedDetails?.income[0] || {};

  const {
    rental_expense: rentalExpense,
    rental_expense_frequency: rentalExpenseFrequency,
    expected_postcode: expectedPostcode,
    expected_state: expectedState,
    expected_suburb: expectedSuburb,
    estimated_loan_amount: estimatedLoanAmount,
  } = rentalIncomeDetails || {};

  return {
    ...DEFAULT_EXPECTED_RENTAL_INCOME_INITIAL_VALUES,
    [ExpectedRentalIncomeFormFields.EstimatedLoanAmount]:
      estimatedLoanAmount ?? undefined,
    [ExpectedRentalIncomeFormFields.RentalExpenses]: rentalExpense ?? undefined,
    [ExpectedRentalIncomeFormFields.RentalExpensesFrequency]:
      parseEnumType(Frequency_Enum, rentalExpenseFrequency) ?? undefined,
    [ExpectedRentalIncomeFormFields.RentalIncome]: rentalIncome ?? undefined,
    [ExpectedRentalIncomeFormFields.RentalIncomeFrequency]:
      parseEnumType(Frequency_Enum, rentalIncomeFrequency) ?? undefined,
    [expectedRentalIncomeManualInputFieldNames.isManualInput]: true,
    [expectedRentalIncomeManualInputFieldNames.postcode]:
      expectedPostcode ?? undefined,
    [expectedRentalIncomeManualInputFieldNames.state]:
      parseEnumType(State_Enum, expectedState) ?? undefined,
    [expectedRentalIncomeManualInputFieldNames.suburb]:
      expectedSuburb ?? undefined,
  };
}

export function mapRentalIncomeInitialValuesForV2Form({
  queriedDetails,
}: {
  queriedDetails: GetRentalIncomeDetailForV2Query | undefined;
}): RentalIncomeFormDetails {
  const {
    amount: rentalIncome,
    frequency: rentalIncomeFrequency,
    rental_income: rentalIncomeDetails,
    income_owners: incomeOwners,
  } = queriedDetails?.income[0] || {};

  const {
    rental_expense: rentalExpense,
    rental_expense_frequency: rentalExpenseFrequency,
    property_type: propertyType,
    address,
  } = rentalIncomeDetails || {};

  const currentPropertyAddress = address
    ? mapHasuraAddressToAppAddress(address)
    : {};

  const {
    isProvidedByUser,
    postcode,
    state,
    street,
    streetNo,
    streetType,
    suburb,
    unitNo,
  } = currentPropertyAddress;

  return {
    ...DEFAULT_RENTAL_INCOME_INITIAL_VALUES,
    [RentalIncomeFormFields.PropertyAddress]: isProvidedByUser
      ? undefined
      : currentPropertyAddress,
    [RentalIncomeFormFields.PropertyOwner]: incomeOwners
      ? incomeOwners.map(({ applicant }) => applicant.id)
      : undefined,
    [RentalIncomeFormFields.PropertyType]:
      parseEnumType(Property_Type_Enum, propertyType) ?? undefined,
    [RentalIncomeFormFields.RentalExpenses]: rentalExpense ?? undefined,
    [RentalIncomeFormFields.RentalExpensesFrequency]:
      parseEnumType(Frequency_Enum, rentalExpenseFrequency) ?? undefined,
    [RentalIncomeFormFields.RentalIncome]: rentalIncome ?? undefined,
    [RentalIncomeFormFields.RentalIncomeFrequency]:
      parseEnumType(Frequency_Enum, rentalIncomeFrequency) ?? undefined,
    [manualInputFieldNames.isManualInput]: isProvidedByUser ?? undefined,
    [manualInputFieldNames.postcode]: postcode ?? undefined,
    [manualInputFieldNames.state]:
      parseEnumType(State_Enum, state) ?? undefined,
    [manualInputFieldNames.streetName]: street ?? undefined,
    [manualInputFieldNames.streetNo]: streetNo ?? undefined,
    [manualInputFieldNames.streetType]: streetType
      ? {
          label: streetType,
          value: streetType,
        }
      : undefined,
    [manualInputFieldNames.suburb]: suburb ?? undefined,
    [manualInputFieldNames.unitNo]: unitNo ?? undefined,
  };
}

export function mapOtherIncomeInitialValuesForV2Form({
  queriedDetails,
}: {
  queriedDetails: GetOtherIncomeDetailForV2Query | undefined;
}): GovernmentIncomeFormDetails | DividendIncomeFormDetails {
  const {
    amount,
    frequency,
    income_owners: incomeOwners,
  } = queriedDetails?.income_by_pk || {};

  // Not using template as keys here, as Government and Dividend
  // currently shares the same key
  return {
    amount,
    frequency,
    payees: incomeOwners
      ? incomeOwners.map(({ applicant }) => applicant.id)
      : undefined,
  };
}
