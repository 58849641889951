import { View } from 'dripsy';
import { useState } from 'react';
import * as React from 'react';

import { Location } from '../../../components/StaticMapView';
import PropertyStaticMap from '../../../LoanApplication/components/PropertyStaticMap';
import { Card } from '../../../ui/atoms/Card';
import { Separator } from '../../../ui/atoms/Separator';
import { EstimatedPropertyValue } from './EstimatedPropertyValue';
import { OriginalValuation } from './OriginalValuation';
import { PropertyDetails } from './PropertyDetails';

type Props = {
  geoLocation?: string | Location;
  propertyDetails: React.ComponentProps<typeof PropertyDetails>;
  estimatedPropertyValue?: React.ComponentProps<typeof EstimatedPropertyValue>;
  originalValuation: number;
};

export const PropertyAttributesCard = ({
  geoLocation,
  propertyDetails,
  estimatedPropertyValue,
  originalValuation,
}: Props) => {
  const [hasGoogleMapError, setHasGoogleMapError] = useState(false);

  return (
    <View>
      <Card>
        {geoLocation && !hasGoogleMapError ? (
          <PropertyStaticMap
            location={geoLocation}
            locationName=""
            containerHeight={125}
            zoom={15}
            scale={2}
            onError={() => setHasGoogleMapError(true)}
          />
        ) : null}
        <View sx={{ paddingHorizontal: '$24', pb: '$24', pt: '$16' }}>
          <PropertyDetails {...propertyDetails} />

          <Separator mb="m" mt="s" />

          {estimatedPropertyValue ? (
            <EstimatedPropertyValue {...estimatedPropertyValue} />
          ) : (
            <OriginalValuation value={originalValuation} />
          )}
        </View>
      </Card>
    </View>
  );
};
