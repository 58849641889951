import { formatInTimeZone } from 'date-fns-tz';
import { A, Text } from 'dripsy';
import React, { ComponentProps, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { WarningRow } from '../../components/WarningRow';
import { Direct_Debit_Failure_Type_Enum } from '../../generated/graphql';
import { assertUnreachable } from '../../utils/typesHelpers';
import { showDirectDebitModal } from '../utils';

type Props = Pick<ComponentProps<typeof WarningRow>, 'sx'> & {
  failureType: Direct_Debit_Failure_Type_Enum;
  loanAccountName: string;
  navigateOnPress: () => void;
  nextRepaymentDate: string;
  willRetry: boolean;
};

const TEXT_PREFIX = 'Content.PaymentFailureNotification.DirectDebitFailure';

export const DirectDebitFailureNotification = ({
  failureType,
  loanAccountName,
  navigateOnPress,
  nextRepaymentDate,
  sx = {},
  willRetry,
}: Props) => {
  const message = useMemo(() => {
    const formattedNextRepaymentDate = formatInTimeZone(
      nextRepaymentDate,
      'Australia/Sydney',
      'dd MMMM',
    );

    switch (failureType) {
      case Direct_Debit_Failure_Type_Enum.BusinessFailure:
        return t(`${TEXT_PREFIX}.BusinessFailure`, {
          loanAccountName,
          nextRepaymentDate: formattedNextRepaymentDate,
        });
      case Direct_Debit_Failure_Type_Enum.TechnicalFailure:
        return willRetry
          ? t(`${TEXT_PREFIX}.TechnicalFailure.WillRetry`, {
              loanAccountName,
            })
          : t(`${TEXT_PREFIX}.TechnicalFailure.Skipped`, {
              loanAccountName,
              nextRepaymentDate: formattedNextRepaymentDate,
            });
      default:
        assertUnreachable(failureType);
    }
    return '';
  }, [failureType, loanAccountName, nextRepaymentDate, willRetry]);

  const showModal = showDirectDebitModal({ failureType, willRetry });

  return (
    <WarningRow
      sx={{ mb: '$8', ...sx }}
      testID={TestID.PaymentFailure.DirectDebitFailureNotification}
    >
      <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
        {message}
        {showModal ? (
          <>
            {' '}
            <A
              variants={['caption', 'link']}
              onPress={navigateOnPress}
              testID={TestID.PaymentFailure.SeeMoreText}
              sx={{ textDecorationLine: 'underline' }}
            >
              {t('Content.PaymentFailureNotification.SeeMore')}
            </A>
          </>
        ) : null}
      </Text>
    </WarningRow>
  );
};
