import { A, Text, useSx, View } from 'dripsy';
import { Image as ExpoImage } from 'expo-image';
import { AnimatePresence, MotiView } from 'moti';
import { Easing } from 'react-native-reanimated';

import { BoxProps } from '../../ui/atoms/Box';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { Divider } from '../../ui/v2/Divider/Divider';
import { RadioGroupItemAsCard } from '../../ui/v2/RadioGroup';
import { Sup } from '../../ui/v2/Text/Sup';
import { isChromatic } from '../../utils/isRunningOnStorybook';

export type Props = BoxProps & {
  title: string;
  optionValue: string;
  /** percentage; 5.99 = 5.99% */
  variableRate: number;
  /** percentage; 5.99 = 5.99% */
  comparisonRate: number;
  features: readonly (string | React.ReactElement)[];
  testID?: string;
  showLmiFeature: boolean;
  cardIndex: number;
};

// These are off-theme typography required to match the designs
const customTypography = {
  secondaryBold: {
    fontWeight: '600',
    fontSize: 12,
    color: '$secondary',
    lineHeight: 16,
    letterSpacing: '-0.03em',
  },
  secondary: {
    fontWeight: '400',
    fontSize: 12,
    color: '$secondary',
    lineHeight: 16,
    letterSpacing: '-0.03em',
  },
  interestRateValue: {
    fontSize: 40,
    fontWeight: '600',
    lineHeight: 30,
    letterSpacing: '-0.03em',
  },
  interestRateSup: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 20,
    letterSpacing: '-0.03em',
  },
  interestRateSub: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 12,
    letterSpacing: '-0.03em',
  },
};

function InterestRateDisplay({ rate }: { rate: number }) {
  return (
    <View
      aria-label={`${rate} percent per annum.`}
      sx={{ gap: 0, display: 'flex', flexDirection: 'row' }}
    >
      <Text sx={customTypography.interestRateValue}>{rate.toFixed(2)}</Text>
      <View>
        <Text
          sx={{
            ...customTypography.interestRateSup,
            scale: '125%',
            position: 'relative',
            left: '10%',
          }}
        >
          %
        </Text>
        <Text
          sx={{
            ...customTypography.interestRateSub,
            position: 'relative',
            bottom: '$4',
          }}
        >
          p.a.
        </Text>
      </View>
    </View>
  );
}

function BuiltAndFunded() {
  return (
    <View
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '$4',
      }}
    >
      <Text sx={customTypography.secondary}>
        {t('Content.LoanApplicationSplash.BuiltAndFunded')}
      </Text>
      <ExpoImage
        source={require('../../../assets/cba-logo-24x23.png')}
        style={{
          width: 24,
          height: 23,
        }}
        alt="CommBank logo"
      />
    </View>
  );
}

type TransitionProp = NonNullable<
  React.ComponentProps<typeof MotiView>['transition']
>;
const Transition = {
  // This is mimicking the bottom-up keyframe in
  // https://www.unloan.com.au/get-started
  bottomUp: (overrides: Partial<{ delay: number }> = {}): TransitionProp => ({
    type: 'timing',
    duration: isChromatic() ? 0 : 600,
    easing: Easing.inOut(Easing.quad),
    ...overrides,
  }),
};

export function PropertyPurposeOptionCard({
  optionValue,
  title,
  variableRate,
  comparisonRate,
  features,
  testID,
  showLmiFeature,
  cardIndex,
}: Props) {
  const sx = useSx();
  return (
    <AnimatePresence>
      <MotiView
        style={sx({
          minWidth: 290,
          maxWidth: [343, 358],
          width: '100%',
          flex: 1,
        })}
        from={{
          opacity: 0,
          translateY: 50,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
        }}
        transition={Transition.bottomUp({
          delay: isChromatic() ? 0 : cardIndex * 300,
        })}
      >
        <RadioGroupItemAsCard
          testID={testID}
          value={optionValue}
          noRadioIfUnselected
        >
          <View
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$16',
            }}
          >
            <Text variant="subHeading2">{title}</Text>
            <Divider sx={{ marginX: '$0' }} />
            <View sx={{ display: 'flex', flexDirection: 'row', gap: '$8' }}>
              <View sx={{ flex: 1 }}>
                <InterestRateDisplay rate={variableRate} />
                <Text
                  sx={{
                    marginTop: '$10',
                    ...customTypography.secondaryBold,
                  }}
                >
                  {t(
                    'Content.LoanApplicationSplash.PropertyPurposeOurRateSubtitle',
                  )}
                </Text>
                <Text
                  sx={{
                    ...customTypography.secondary,
                  }}
                >
                  {showLmiFeature ? (
                    <>
                      {t(
                        'Content.LoanApplicationSplash.PropertyPurposeOurRateSubtitleClarificationV2Part1',
                      )}
                      <Sup>†</Sup>
                      {t(
                        'Content.LoanApplicationSplash.PropertyPurposeOurRateSubtitleClarificationV2Part2',
                      )}
                    </>
                  ) : (
                    t(
                      'Content.LoanApplicationSplash.PropertyPurposeOurRateSubtitleClarification',
                    )
                  )}
                </Text>
              </View>
              <View sx={{ flex: 1 }}>
                <InterestRateDisplay rate={comparisonRate} />
                <Text
                  sx={{
                    marginTop: '$10',
                    ...customTypography.secondaryBold,
                  }}
                >
                  {t(
                    'Content.LoanApplicationSplash.PropertyPurposeComparisonRateSubtitle',
                  )}
                </Text>
                {showLmiFeature ? (
                  <Text
                    sx={{
                      ...customTypography.secondary,
                    }}
                  >
                    {t(
                      'Content.LoanApplicationSplash.PropertyPurposeComparisonRateSubtitleClarificationPart1',
                    )}
                    <Sup>†</Sup>
                    {t(
                      'Content.LoanApplicationSplash.PropertyPurposeComparisonRateSubtitleClarificationPart2',
                    )}
                  </Text>
                ) : null}
              </View>
            </View>

            <View
              role="list"
              sx={{ display: 'flex', flexDirection: 'column', gap: '$4' }}
            >
              {features.map((feature, i) => (
                <View
                  // This is a static list of features
                  // eslint-disable-next-line react/no-array-index-key
                  key={String(i)}
                  role="listitem"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '$8',
                    alignItems: 'start',
                  }}
                >
                  <StyledIcon
                    name="tickV2"
                    color="success"
                    family="svg"
                    size="2s"
                    style={{ marginTop: -2 }}
                  />
                  <Text
                    sx={{
                      ...customTypography.secondary,
                      flex: 1,
                    }}
                  >
                    {feature}
                  </Text>
                </View>
              ))}
            </View>
            <A
              href={t('Link.FullProductFeatures')}
              hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
              variant="link"
              sx={{ width: 'fit-content' }}
            >
              {t('Content.LoanApplicationSplash.ViewFullProductFeatures')}
            </A>
            <Divider sx={{ marginX: '$0' }} />
            <BuiltAndFunded />
          </View>
        </RadioGroupItemAsCard>
      </MotiView>
    </AnimatePresence>
  );
}
