import { capitalize } from 'lodash';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import {
  PendingDomRequestUser,
  Status,
} from '../../DataSharingConfirmation/types';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';

type DataSharingStatusType = {
  status: Status;
  domRequestUsers: PendingDomRequestUser[];
};

type UsersWithApprovalStatesProps = {
  status: Status;
  domRequestUsers: PendingDomRequestUser[];
};

const UsersWithApprovalStates: React.FC<UsersWithApprovalStatesProps> = ({
  status,
  domRequestUsers,
}) => (
  <>
    <ListRow
      label={t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharing',
      )}
      labelFontWeight="semiBold"
      placeholder={status}
      last={domRequestUsers.length === 0}
    />
    {domRequestUsers.map(({ id, name, status: requestStatus }) => (
      <ListRow
        key={id}
        label={name}
        labelFontWeight="semiBold"
        placeholder={capitalize(requestStatus)}
        last
      />
    ))}
  </>
);

const DataSharingStatus: React.FC<DataSharingStatusType> = ({
  status,
  domRequestUsers,
}) => {
  let content;
  if (status === Status.Pending)
    content = (
      <UsersWithApprovalStates
        status={status}
        domRequestUsers={domRequestUsers}
      />
    );
  else
    content = (
      <ListRow
        label={t(
          'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharing',
        )}
        labelFontWeight="semiBold"
        placeholder={status}
        last
      />
    );
  return (
    <ListRowGroup
      mx={0}
      overflow="visible"
      testID={TestID.ManageDataSharing.DataSharingStatus}
    >
      {content}
    </ListRowGroup>
  );
};

export default DataSharingStatus;
