import { useApolloClient } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { useAuthContext } from 'src/Auth/context';
import { useUser } from 'src/Auth/hooks';
import { ActionSheetType, Screen } from 'src/navigation/types/screens';
import { Alert } from 'src/ui/atoms/Alert';
import { useEnvConfig } from 'src/utils/hooks/useEnvConfig';
import { normalizeError } from 'src/utils/normalizeError';

import { CreateMockAccountFormValues } from './types';

export function useSetupActiveAccountDevTool() {
  const [setupActiveAccountLoading, setActiveAccountSetupLoading] =
    useState(false);
  const [setupJointAccountLoading, setJointAccountSetupLoading] =
    useState(false);

  const navigation = useNavigation();
  const { user } = useUser();
  const apollo = useApolloClient();
  const envConfig = useEnvConfig();
  const authContext = useAuthContext();

  const setupActiveAccount = useCallback(
    async ({
      loanAmount = 1_000_000,
      redrawAmount = 25_000,
      disbursementDate,
    }: Partial<Omit<CreateMockAccountFormValues, 'coBorrowerEmail'>> = {}) => {
      const email = user?.email;

      const { testSupportAppURL } = envConfig.config;

      const token = await authContext?.getAccessToken();

      if (!testSupportAppURL) {
        Alert.alert('Error: not supported');
        return;
      }

      if (!email) {
        Alert.alert('Error: local user context not found');
        return;
      }

      setActiveAccountSetupLoading(true);
      const url = `${testSupportAppURL}/authenticated-test-user-setup`;
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: new Headers({ Authorization: `Bearer ${token}` }),
          body: JSON.stringify({
            emails: [email],
            loan_amount: loanAmount,
            redraw_amount: redrawAmount,
            ...(disbursementDate
              ? { disbursement_date: disbursementDate }
              : {}),
          }),
        });
        if (res.status === 200) {
          // Refetch the data for the home screen to get the new account
          await apollo.refetchQueries({ include: 'all' });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore this does work, just need to fix the types for action sheet navigator
          navigation.replace(Screen.MAIN_NAVIGATOR, {
            screen: Screen.HOME_DASHBOARD,
            params: { screen: Screen.HOME },
          });
          navigation.navigate(ActionSheetType.CONFIRMATION_SUCCESS, {
            message: `Test Account Created`,
          });
        } else {
          Alert.alert(
            `Error: Setup Active Account API respondeded with unexpected status ${res.status} ${res.statusText}`,
          );
        }
        setActiveAccountSetupLoading(false);
      } catch (err: unknown) {
        setActiveAccountSetupLoading(false);
        Alert.alert(
          `Error creating test account: ${normalizeError(err).message}`,
        );
      }
    },
    [user?.email, envConfig.config, authContext, navigation, apollo],
  );

  const setupJointAccount = useCallback(
    async ({
      coBorrowerEmail: initialCoBorrowerEmail,
      loanAmount = 1_000_000,
      redrawAmount = 25_000,
      disbursementDate,
    }: Partial<CreateMockAccountFormValues> = {}) => {
      const coBorrowerEmail =
        // eslint-disable-next-line no-alert
        initialCoBorrowerEmail || window.prompt('Enter co-borrower email');
      if (!coBorrowerEmail) {
        return;
      }

      const email = user?.email;

      const { testSupportAppURL } = envConfig.config;

      const token = await authContext?.getAccessToken();

      if (!testSupportAppURL) {
        Alert.alert('Error: not supported');
        return;
      }

      if (!email) {
        Alert.alert('Error: local user context not found');
        return;
      }

      setJointAccountSetupLoading(true);
      const url = `${testSupportAppURL}/authenticated-test-user-setup`;
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: new Headers({ Authorization: `Bearer ${token}` }),
          body: JSON.stringify({
            emails: [email, coBorrowerEmail],
            loan_amount: loanAmount,
            redraw_amount: redrawAmount,
            ...(disbursementDate
              ? { disbursement_date: disbursementDate }
              : {}),
          }),
        });
        if (res.status === 200) {
          // Refetch the data for the home screen to get the new account
          await apollo.refetchQueries({ include: 'all' });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore this does work, just need to fix the types for action sheet navigator
          navigation.replace(Screen.MAIN_NAVIGATOR, {
            screen: Screen.HOME_DASHBOARD,
            params: { screen: Screen.HOME },
          });
          navigation.navigate(ActionSheetType.CONFIRMATION_SUCCESS, {
            message: `Test Account Created`,
          });
        } else {
          Alert.alert(
            `Error: Setup Active Account API respondeded with unexpected status ${res.status} ${res.statusText}`,
          );
        }
        setJointAccountSetupLoading(false);
      } catch (err: unknown) {
        setJointAccountSetupLoading(false);
        Alert.alert(
          `Error creating test account: ${normalizeError(err).message}`,
        );
      }
    },
    [user?.email, envConfig.config, authContext, navigation, apollo],
  );

  const setupCustomMockAccount = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.replace(Screen.SINGLE_V2_MODAL, {
      screen: Screen.MOCK_ACCOUNT_MODAL,
    });
  }, [navigation]);

  return {
    setupActiveAccount,
    setupJointAccount,
    setupCustomMockAccount,
    setupJointAccountLoading,
    setupActiveAccountLoading,
  };
}
