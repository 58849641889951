import { GetExternalAccountsQuery } from '../generated/graphql';

export enum HomeLoanModal {
  NONE = '',
  TRANSFER = 'transfer',
  AUTOPAY_CHANGE_REQUEST = 'autopay-change-request',
  AUTOPAY_SETUP = 'autopay-setup',
  AUTOPAY_EXISTING_SETTINGS = 'autopay-existing-settings',
}

export enum WithdrawalFormField {
  ExternalAccountId = 'external_account_id',
  Amount = 'amount',
  Description = 'description',
  Reference = 'reference',
  IsManualAccountInput = 'is_manual_account_input',
  AccountName = 'account_name',
  AccountBsb = 'account_bsb',
  AccountNumber = 'account_number',
}

export type WithdrawForm = {
  [WithdrawalFormField.Amount]: number;
  [WithdrawalFormField.ExternalAccountId]: string;
  [WithdrawalFormField.Description]: string;
};

export type WithdrawFormV2 = {
  [WithdrawalFormField.Amount]: number | null;
  [WithdrawalFormField.ExternalAccountId]: string;
  [WithdrawalFormField.Description]: string;
  [WithdrawalFormField.Reference]: string | undefined | null;
  [WithdrawalFormField.IsManualAccountInput]: boolean;
  [WithdrawalFormField.AccountName]: string;
  [WithdrawalFormField.AccountBsb]: string;
  [WithdrawalFormField.AccountNumber]: string;
};

export type ExternalAccount = NonNullable<
  NonNullable<GetExternalAccountsQuery['me'][0]['user']>['identity_profile']
>['external_accounts'][0];
