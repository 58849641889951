export declare type Maybe<T> = T | null | undefined;
export type SpecificRequired<T, U extends keyof T> = Omit<T, U> & {
  [K in U]-?: NonNullable<T[K]>;
};
export type PickRequired<T, U extends keyof T> = {
  [K in U]-?: NonNullable<T[K]>;
};

export type StandardEnum = Record<string, unknown> | Record<number, unknown>;

type RelayStyleEnumFutureType = '%future added value';

export type WithFutureType<T> = T | RelayStyleEnumFutureType;

export type ExcludeEnumFutureType<T> = Exclude<
  NonNullable<T>,
  RelayStyleEnumFutureType
>;

export type ExcludeEnumFutureTypeByKey<
  ObjectLike,
  Key extends keyof ObjectLike,
> = Omit<ObjectLike, Key> & {
  [KeyWithFuture in Key]: ExcludeEnumFutureType<ObjectLike[KeyWithFuture]>;
};

export function notNil<T>(nullable: Maybe<T>, desc: Maybe<string>): T {
  if (nullable == null) {
    throw new Error(`Unexpected null: ${desc ?? typeof nullable}`);
  }
  return nullable;
}

/**
 * WARNING: Use this sparingly as typecheck error will block core PR
 * from being merged because we check each core PR against current prod app code.
 *
 * Use this to assert all possible values has been enumerated in a switch case.
 *
 * Unlike backend code, app code generally never want to throw when encountering
 * unexpected values. Instead, app code should handle unexpected values in a
 * safe way using default case.
 *
 * We get few benefits from doing this:
 * - if backend is updated to send a new value, app will not crash on prod.
 * - when updating local app with new backend running, we get typecheck
 * when new value is unhandled.
 *
 * e.g. when formatting an enum for display:
 * ```
 * enum Drink {
 *  Coffee = 'coffee'
 * }
 *
 * switch (drinkFromServer) {
 *   case Drink.Coffee:
 *     return 'Coffee';
 *   default:
 *     assertUnreachable(drinkFromServer);
 *     return 'Unknown Drink';
 * }
 * ```
 */
export function assertUnreachable(_: never | RelayStyleEnumFutureType): void {}

export function isKnownEnumValue<T extends StandardEnum>(
  myEnum: T,
  value: unknown,
): value is T[keyof T] {
  const knownValues = new Set(Object.values(myEnum));
  return knownValues.has(value);
}
