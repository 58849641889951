import { MainNavigatorStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { SettingsNavigator } from '../../Settings/navigation/Navigator';
import { SVG_ICON_LIST } from '../../ui/svgs/svgIconList';
import { isWeb } from '../../utils/platformUtils';
import { HomeDashboardNavigator } from './HomeDashboardNavigator';

type HomeNavigatorConfig = {
  label: string;
  icon: keyof typeof SVG_ICON_LIST;
  iconText: string;
  name: keyof MainNavigatorStackParams;
  component: React.ComponentType<unknown>;
};

type HomeNavigatorProps = Array<HomeNavigatorConfig>;

export const SCREEN_CONFIGS: HomeNavigatorProps = [
  {
    label: 'Home',
    icon: isWeb ? 'home' : 'unloanIcon',
    iconText: t('Content.TabNavigation.Dashboard'),
    component: HomeDashboardNavigator,
    name: Screen.HOME_DASHBOARD,
  },
  {
    label: 'Supports',
    icon: 'intercomSupport',
    iconText: t('Content.TabNavigation.Support'),
    component: () => null,
    name: Screen.INTERCOM_CHAT,
  },
  {
    label: 'Settings',
    icon: 'tabNavSettings',
    iconText: t('Content.TabNavigation.Settings'),
    component: SettingsNavigator,
    name: Screen.SETTINGS,
  },
];
