import {
  containsNonNumber,
  isASCIICharacter,
  isCBANameFieldValid,
  validateEmail,
} from '../utils/stringHelpers';

export const MaxCharacter = {
  email: 320,
  general: 100,
  withdrawalReference: 35,
  multilineTextInput: 500,
  driverLicence: 20,
  name: 30,
  postcode: 4,
};

export const MaxValue = {
  // Max Value for general number
  number: 99999999,
  interestRate: 99.99,
};

export const MinValue = {
  money: 1,
};

export const FieldRules = {
  name: {
    // Each part of the name (First, Middle, and Last) should be less than 30 chars, and needs to be ASCII
    isValid: (x: string) =>
      x.length <= MaxCharacter.name && isCBANameFieldValid(x),
  },
  email: {
    // Email should be less than equal to 320 chars
    isValid: (v: string) => v.length <= MaxCharacter.email && validateEmail(v),
  },
  driverLicense: {
    // Driver Licence should be less than equal to 20 chars
    isValid: (v: string) =>
      v.length <= MaxCharacter.driverLicence &&
      isASCIICharacter(v, { allowWhitespace: false }),
  },
  interestRate: {
    // Interest rate should be less than 99.99
    isValid: (v: number) => v <= MaxValue.interestRate,
  },
  currency: {
    // Numeric fields should be less than 99_999_999
    isValid: (v: number) => v <= MaxValue.number,
  },
  postcode: {
    // Postcode should exactly be 4 numbers
    isValid: (x: string) =>
      x.length <= MaxCharacter.postcode && !containsNonNumber(x),
  },
};
