import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Autopay_Frequency_Input_Enum,
  Shortfall_Calculation_Repayment_Frequency,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { BaseAutopaySetupV2 } from '../components/BaseAutopaySetupV2';
import { useAutopayMinimumRepaymentAmount } from '../hooks/useAutopayMinimumRepaymentAmount';
import { useAutopaySetupActions } from '../hooks/useAutopaySetupActions';
import { useAutopaySetupData } from '../hooks/useAutopaySetupData';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { AutopayChangeRequestFormV2, AutoPaySetupFormField } from '../types';

const transformToAutopayFrequencyEnum = (
  repaymentFrequency: Shortfall_Calculation_Repayment_Frequency,
): Autopay_Frequency_Input_Enum => {
  switch (repaymentFrequency) {
    case Shortfall_Calculation_Repayment_Frequency.Weekly:
      return Autopay_Frequency_Input_Enum.Weekly;
    case Shortfall_Calculation_Repayment_Frequency.Fortnightly:
      return Autopay_Frequency_Input_Enum.Fortnightly;
    case Shortfall_Calculation_Repayment_Frequency.Monthly:
    default:
      return Autopay_Frequency_Input_Enum.Monthly;
  }
};

export type AutopayEditV2Props =
  AutopaySettingsScreenProps<Screen.AUTOPAY_EDIT>;

export function AutopayEditV2({ navigation, route }: AutopayEditV2Props) {
  const { cbaAccountId, autopaySettings } = route.params;

  const [initFormValues, setInitFormValues] = useState(
    {} as AutopayChangeRequestFormV2 & { key: string },
  );

  const {
    loading,
    externalAccounts,
    maximumDirectDebitAmount,
    unloanAccountName,
    unloanBsb,
    unloanAccountNumber,
    nextRepayment,
    nextInstallment,
    availableRedrawBalance,
  } = useAutopaySetupData(cbaAccountId);

  const nextRepaymentDate = nextRepayment?.next_repayment_day ?? null;

  const {
    monthlyMinimumRepaymentAmountData,
    minimumRepaymentAmountsByFrequency,
    refetchMonthlyMinimumRepaymentAmount,
    isLoadingMonthlyMinimumRepaymentAmount,
    monthlyMinimumRepaymentAmountError,
  } = useAutopayMinimumRepaymentAmount(cbaAccountId, nextRepaymentDate || '');

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const {
    onContinuePress,
    onConfirmPress,
    onBack,
    showConfirmation,
    getEstimatedShortfallAmountLoading,
    confirmAutopayLoading,
    errorMessage,
  } = useAutopaySetupActions({
    navigation,
    cbaAccountId,
    minimumRepaymentAmountsByFrequency,
    monthlyMinimumRepaymentAmountError,
  });

  const externalAccountId = externalAccounts.find(
    (externalAccount) =>
      externalAccount.account_number === autopaySettings.direct_debit_account &&
      externalAccount.account_bsb === autopaySettings.direct_debit_bsb,
  )?.id;

  useEffect(() => {
    if (
      isEmpty(initFormValues) &&
      (monthlyMinimumRepaymentAmountData || monthlyMinimumRepaymentAmountError)
    ) {
      const frequency = transformToAutopayFrequencyEnum(
        autopaySettings.repayment_frequency,
      );

      const isCustomRepaymentAmount =
        autopaySettings.repayment_amount !==
        minimumRepaymentAmountsByFrequency[frequency];

      setInitFormValues({
        key: uuidv4(),
        [AutoPaySetupFormField.RepaymentFrequency]: frequency,
        [AutoPaySetupFormField.IsCustomRepaymentAmount]:
          isCustomRepaymentAmount,
        [AutoPaySetupFormField.RepaymentAmount]: Math.ceil(
          autopaySettings.repayment_amount,
        ),
        [AutoPaySetupFormField.ExternalAccountId]: externalAccountId ?? '',
        [AutoPaySetupFormField.PaymentStartDate]: nextRepaymentDate
          ? new Date(nextRepaymentDate)
          : null,
        [AutoPaySetupFormField.IsManualAccountInput]: !externalAccountId,
        [AutoPaySetupFormField.DirectDebitAccountName]: '',
        [AutoPaySetupFormField.DirectDebitAccountBsb]: externalAccountId
          ? ''
          : autopaySettings.direct_debit_bsb,
        [AutoPaySetupFormField.DirectDebitAccountNumber]: externalAccountId
          ? ''
          : autopaySettings.direct_debit_account,
      });
    }
  }, [
    autopaySettings,
    externalAccountId,
    initFormValues,
    isLoadingMonthlyMinimumRepaymentAmount,
    minimumRepaymentAmountsByFrequency,
    monthlyMinimumRepaymentAmountData,
    monthlyMinimumRepaymentAmountError,
    nextRepaymentDate,
  ]);

  return (
    <BaseAutopaySetupV2
      screen={Screen.AUTOPAY_EDIT}
      key={initFormValues.key}
      initialValues={initFormValues}
      isEdit
      unloanAccountName={unloanAccountName}
      unloanBsb={unloanBsb}
      unloanAccountNumber={unloanAccountNumber}
      minimumRepaymentAmountsByFrequency={minimumRepaymentAmountsByFrequency}
      maximumDirectDebitAmount={maximumDirectDebitAmount}
      externalAccounts={externalAccounts}
      showConfirmation={showConfirmation}
      onClose={onClose}
      onBack={onBack}
      onContinuePress={onContinuePress}
      onConfirmPress={onConfirmPress}
      loading={loading || isEmpty(initFormValues)}
      getEstimatedShortfallAmountLoading={getEstimatedShortfallAmountLoading}
      confirmAutopayLoading={confirmAutopayLoading}
      errorMessage={errorMessage}
      isLoadingMonthlyMinimumRepaymentAmount={
        isLoadingMonthlyMinimumRepaymentAmount
      }
      refetchMonthlyMinimumRepaymentAmount={
        refetchMonthlyMinimumRepaymentAmount
      }
      monthlyMinimumRepaymentAmountError={monthlyMinimumRepaymentAmountError}
      nextInstallmentAmount={nextInstallment?.amount}
      nextInstallmentDate={nextInstallment?.next_installment_date}
      availableRedrawBalance={availableRedrawBalance}
    />
  );
}
