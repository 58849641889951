import { memo } from 'react';

import { RowRight } from '../atoms/RowRight';
import { Switch } from '../atoms/Switch';
import { ListRow, Props as ListRowProps } from './ListRow';

export type Props = ListRowProps & {
  label: string;
  value?: boolean;
  setFieldValue?: (value: boolean) => void;
  switchTestId?: string;
};

export const SwitchRow = memo<Props>(
  ({ value, disabled, children, setFieldValue, switchTestId, ...props }) => (
    <ListRow {...props} disabled={disabled}>
      {children}
      <RowRight>
        <Switch
          disabled={disabled}
          value={value}
          onValueChange={setFieldValue}
          testID={switchTestId}
        />
      </RowRight>
    </ListRow>
  ),
);
