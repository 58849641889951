import { SxProp, Text, useSx } from 'dripsy';
import { AnimatePresence, MotiView } from 'moti';
import { useEffect, useState } from 'react';

import { Box, BoxProps } from '../ui/atoms/Box';
import { StyledIcon } from '../ui/atoms/StyledIcon';
import { chromaticIgnoreDataSet } from '../utils/chromatic';
import { BannerWrapper, FadeInAnimationPreset } from './BannerWrapper';

type SuccessRowProps = Omit<BoxProps, 'style'> & {
  message?: string | null;
  autoDismiss: boolean;
  children?: React.ReactNode;
  sx?: SxProp;
};

const FadeOutAnimationPreset = {
  from: FadeInAnimationPreset.animate,
  exit: FadeInAnimationPreset.from,
  transition: FadeInAnimationPreset.transition,
};

const TIMEOUT_MS = 4000;

export function SuccessRow({
  message,
  autoDismiss,
  children,
  sx: sxProp,
  ...props
}: SuccessRowProps) {
  const [visible, setVisible] = useState(true);
  const sx = useSx();

  const hasContent = Boolean(message || children);

  useEffect(() => {
    if (hasContent && autoDismiss) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }

    return () => {};
  });

  if (!hasContent) {
    return null;
  }

  return (
    <AnimatePresence>
      {visible ? (
        <MotiView {...FadeOutAnimationPreset} dataSet={chromaticIgnoreDataSet}>
          <BannerWrapper>
            <Box
              bg="successBg"
              row
              borderRadius="card"
              p="m"
              alignItems="center"
              style={sxProp ? sx(sxProp) : undefined}
              {...props}
            >
              <StyledIcon name="checkmark-circle" color="success" mr="s" />
              {message ? (
                <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
                  {message}
                </Text>
              ) : null}
              {children}
            </Box>
          </BannerWrapper>
        </MotiView>
      ) : null}
    </AnimatePresence>
  );
}
