import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';

export enum Navigator {
  AUTH = 'auth',
  APP = 'app',
}

export enum ActionSheetType {
  ACCOUNT_ADDRESS_BOOK_DELETE = 'account-address-book-delete-action-sheet',
  ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION = 'add-direct-debit-account-confirmation-action-sheet',
  ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2 = 'add-direct-debit-account-confirmation-v2-action-sheet',
  AUTOPAY_CANCELLATION_CONFIRMATION = 'autopay-cancellation-confirmation-action-sheet',
  AUTOPAY_CONFIRMATION = 'autopay-confirmation-action-sheet',
  AUTOPAY_SHORTFALL_CONFIRMATION = 'autopay-shortfall-confirmation-action-sheet',
  AUTOPAY_SHORTFALL_CONFIRMATION_V2 = 'autopay-shortfall-confirmation-v2-action-sheet',
  CA_CONFIRMATION = 'ca-confirmation-action-sheet',
  CONFIRMATION_SUCCESS = 'confirmation-success-action-sheet',
  CO_BORROWER_INFORMATION_CONFIRMATION = 'co-borrower-information-confirmation-action-sheet',
  COPY_BSB_ACCOUNT_NUMBER = 'copy-bsb-account-number-action-sheet',
  COPY_BSB_ACCOUNT_NUMBER_V3 = 'copy-bsb-account-number-action-sheet-v3',
  CREDIT_CHECK_CONFIRMATION = 'credit-check-confirmation-action-sheet',
  DEBTS_MANUAL_ENTRY_REQUIRED = 'debts-manual-entry-required-action-sheet',
  DELETE_ACCOUNT_COMPLETED_ACTION_SHEET = 'delete-account-completed-action-sheet',
  DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET = 'delete-account-confirmation-check-action-sheet',
  DELETE_EXPENSE_ACTION_SHEET = 'delete-expense-action-sheet',
  DELETE_INCOME_ACTION_SHEET = 'delete-income-action-sheet',
  DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET = 'delete-manually-added-liability-action-sheet',
  DEVELOPER_TOOLS = 'developer-tools-action-sheet',
  DISCONNECT_BANK = 'disconnect-bank-action-sheet',
  ENV_SWITCHER = 'environment-switcher-action-sheet',
  FLAG_DETECTED_LIABILITY = 'flag-detected-liability-action-sheet',
  GREEN_TICK_CONFIRMATION_ACTION_SHEET = 'green-tick-confirmation-action-sheet',
  LOW_EXPENSES = 'low-expenses-action-sheet',
  OTHER_TOP_UP_REASON_CONFIRMATION = 'other-top-up-reason-confirmation-action-sheet',
  PERSONAL_USE_TOP_UP_REASON_CONFIRMATION = 'personal-use-top-up-reason-confirmation-action-sheet',
  PREVENT_CLOSING_MODAL_ACTION_SHEET = 'prevent-closing-modal-action-sheet',
  REMOVE_CO_BORROWER_V2 = 'remove-co-borrower-action-sheet-v2',
  REMOVE_PROPERTY_V2 = 'remove-property-action-sheet-v2',
  REPAYMENT_PLAN_FOR_RETIREMENT_V2 = 'repayment-plan-for-retirement-action-sheet-v2',
  SETTINGS_DATA_SHARING_CONFIRMATION = 'settings-data-sharing-confirmation',
  SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE = 'settings-data-sharing-confirmation-message',
  SOCIAL_SIGN_IN = 'social-sign-in-action-sheet',
  STOP_DATA_SHARE_CONFIRMATION = 'stop-data-share-confirmation-action-sheet',
  TRANSACTION_HISTORY = 'transaction-history-action-sheet',
  TRANSFER_LIMIT_REACHED = 'transfer-limit-reached-action-sheet',
  WITHDRAW_CONFIRMATION = 'withdraw-confirmation-action-sheet',
  UPDATE_APPLICATION_CONFIRMATION = 'update-application-confirmation-action-sheet',
  YOUR_HOME_TOP_UP_REASON_CONFIRMATION = 'your-home-property-top-up-reason-confirmation-action-sheet',
  NO_DEBTS_CONFIRMATION = 'no-debts-confirmation-action-sheet',

  // Principal Reduction
  REDUCE_REPAYMENTS_CONFIRM_CHANGES = 'reduce-repayments-confirm-changes-action-sheet',
  REDUCE_REPAYMENTS_SUBMITTED = 'reduce-repayments-submitted-action-sheet',
  REDUCE_REPAYMENTS_CANCEL_REQUEST = 'reduce-repayments-cancel-request-action-sheet',
  REDUCE_REPAYMENTS_REQUEST_CANCELLED = 'reduce-repayments-request-cancelled-action-sheet',
  REDUCE_REPAYMENTS_DECLINE_REQUEST = 'reduce-repayments-decline-request-action-sheet',
  REDUCE_REPAYMENTS_REQUEST_DECLINED = 'reduce-repayments-request-declined-action-sheet',
  REDUCE_REPAYMENTS_APPROVE_REQUEST = 'reduce-repayments-approve-request-action-sheet',
  REDUCE_REPAYMENTS_REQUEST_APPROVED = 'reduce-repayments-request-approved-action-sheet',
  // Account Permissions
  ACCOUNT_PERMISSIONS_CONFIRM_CHANGES = 'account-permissions-confirm-changes-action-sheet',
  ACCOUNT_PERMISSIONS_LEAVE_PAGE = 'account-permissions-leave-page-action-sheet',
  ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED = 'account-permissions-change-request-submitted-action-sheet',
  ACCOUNT_PERMISSIONS_APPROVAL = 'account-permissions-approval-action-sheet',
  ACCOUNT_PERMISSIONS_APPLIED = 'account-permissions-applied-action-sheet',
}

export enum Screen {
  ACCOUNT_ADDRESS_BOOK = 'account-address-book',
  ADD_NEW_ACCOUNT = 'add-new-account',
  AUTH_FLOW = 'auth-flow',
  ACTION_SHEET = 'action-sheet',

  INVALID_APPLICATION_INVITE = 'invalid-application-invite',

  MAIN_NAVIGATOR = 'main-navigator',

  NAVIGATOR_FULL_SCREEN_MODAL = 'navigator-full-screen-modal',
  NAVIGATOR_MODAL = 'navigator-modal',

  PAYMENT_FAILURE_MODAL = 'payment-failure-modal',

  HOME_DASHBOARD = 'home-dashboard',
  HOME = 'home',
  HOME_LOAN = 'home-loan',
  HOME_LOAN_SETTINGS = 'home-loan-settings',
  HOME_LOAN_STATEMENTS = 'home-loan-statements',

  VOI_COMPLETED = 'identity-verification-completed',

  ACCOUNT_PERMISSIONS = 'account-permissions',
  ACCOUNT_PERMISSIONS_APPROVAL_SCREEN = 'account-permission-approval-screen',

  SETTINGS = 'settings',
  SETTINGS_HOME = 'settings-home',
  SETTINGS_PERSONAL = 'settings-personal',
  SETTINGS_SECURITY = 'settings-security',
  SETTINGS_SECURITY_FACE_ID = 'settings-security-face-id',
  SETTINGS_DAILY_TRANSFER_LIMIT = 'settings-daily-transfer-limit',
  SETTINGS_NOTIFICATION = 'settings-notification',
  SETTINGS_CONNECTED_BANKS = 'settings-connected-banks',
  SETTINGS_BANK_PROFILE = 'settings-bank-profile',
  SETTINGS_BANK_ACCOUNT_DETAIL = 'settings-bank-account-detail',
  SETTINGS_MANAGE_DATA_SHARING = 'settings-manage-data-sharing',
  SETTINGS_DATA_SHARING_DETAILS = 'settings-data-sharing-details',
  SETTINGS_DATA_SHARING_PREFERENCES = 'settings-data-sharing-preferences',
  SETTINGS_JOINT_ACCOUNT_PREFERENCES = 'settings-joint-account-preferences',
  SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST = 'settings-joint-account-notification-preferences',
  SETTINGS_DATA_SHARING_CONSENT_HISTORY = 'settings-consent-history',
  SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES = 'settings-data-sharing-account-notification-preferences',
  SETTINGS_NEW_FEATURES = 'settings-new-features',

  DATA_SHARING_CONFIRMATION_MODAL = 'data-sharing-confirmation-modal',
  DATA_SHARING_CURRENT_STATUS = 'data-sharing-current-status',
  DATA_SHARING_SUMMARY = 'data-sharing-summary',
  DATA_SHARING_CONFIRMATION = 'data-sharing-confirmation',

  LOAN_APPLICATION_V2 = 'loan-application-v2',
  LOAN_APPLICATION_V2_SPLASH = 'loan-application-splash-v2',
  LOAN_APPLICATION_V2_INTRO = 'loan-application-intro-v2',
  LOAN_APPLICATION_V2_YOUR_PROPERTY = 'loan-application-your-property-v2',
  LOAN_APPLICATION_V2_BORROWERS = 'loan-application-borrowers-v2',
  LOAN_APPLICATION_V2_YOUR_UNLOAN = 'loan-application-your-unloan-v2',
  LOAN_APPLICATION_V2_YOUR_DEBTS = 'loan-application-your-debts-liabilities-v2',
  LOAN_APPLICATION_V2_YOUR_INCOME = 'loan-application-your-income-v2',
  LOAN_APPLICATION_V2_YOUR_EXPENSES = 'loan-application-your-expenses-v2',
  LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT = 'loan-application-interstitial-submit-v2',
  LOAN_APPLICATION_V2_REVIEW_SUMMARY = 'loan-application-review-summary-v2',
  LOAN_APPLICATION_V2_SUBMIT = 'loan-application-submit-v2',
  LOAN_APPLICATION_V2_VOI_COMPLETED = 'loan-application-identity-verification-completed-v2',
  LOAN_APPLICATION_V2_VERIFY_INCOME_OB = 'loan-application-verify-income-ob-v2',
  LOAN_APPLICATION_V2_COMPLETED = 'loan-application-completed-v2',
  LOAN_APPLICATION_V2_OFFER = 'loan-application-offer-v2',
  LOAN_APPLICATION_V2_OFFER_ESIGN = 'loan-application-offer-esign-v2',

  LOAN_APPLICATION_TOP_UP_INTRO = 'loan-application-top-up-intro',
  LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY = 'loan-application-top-up-your-property',
  LOAN_APPLICATION_TOP_UP_BORROWERS = 'loan-application-top-up-borrowers',
  LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN = 'loan-application-top-up-your-unloan',
  LOAN_APPLICATION_TOP_UP_YOUR_DEBTS = 'loan-application-top-up-your-debts-liabilities',
  LOAN_APPLICATION_TOP_UP_YOUR_INCOME = 'loan-application-top-up-your-income',
  LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES = 'loan-application-top-up-your-expenses',
  LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT = 'loan-application-top-up-interstitial-submit',
  LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY = 'loan-application-top-up-review-summary',
  LOAN_APPLICATION_TOP_UP_SUBMIT = 'loan-application-top-up-submit',
  LOAN_APPLICATION_TOP_UP_VOI_COMPLETED = 'loan-application-top-up-identity-verification-completed',
  LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB = 'loan-application-top-up-verify-income-ob',
  LOAN_APPLICATION_TOP_UP_COMPLETED = 'loan-application-top-up-completed',
  LOAN_APPLICATION_TOP_UP_OFFER = 'loan-application-top-up-offer',
  LOAN_APPLICATION_TOP_UP_OFFER_ESIGN = 'loan-application-top-up-offer-esign',

  LOAN_APPLICATION_PURCHASE_INTRO = 'loan-application-purchase-intro',
  LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY = 'loan-application-purchase-your-property',
  LOAN_APPLICATION_PURCHASE_BORROWERS = 'loan-application-purchase-borrowers',
  LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN = 'loan-application-purchase-your-unloan',
  LOAN_APPLICATION_PURCHASE_YOUR_DEBTS = 'loan-application-purchase-your-debts-liabilities',
  LOAN_APPLICATION_PURCHASE_YOUR_INCOME = 'loan-application-purchase-your-income',
  LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES = 'loan-application-purchase-your-expenses',
  LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT = 'loan-application-purchase-interstitial-submit',
  LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY = 'loan-application-purchase-review-summary',
  LOAN_APPLICATION_PURCHASE_SUBMIT = 'loan-application-purchase-submit',
  LOAN_APPLICATION_PURCHASE_VOI_COMPLETED = 'loan-application-purchase-identity-verification-completed',
  LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB = 'loan-application-purchase-verify-income-ob',
  LOAN_APPLICATION_PURCHASE_COMPLETED = 'loan-application-purchase-completed',
  LOAN_APPLICATION_PURCHASE_OFFER = 'loan-application-purchase-offer',
  LOAN_APPLICATION_PURCHASE_OFFER_ESIGN = 'loan-application-purchase-offer-esign',
  LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL = 'loan-application-purchase-conditional-approval',

  LOAN_CONFIGURATION_V2_MODAL = 'loan-configuration-modal-v2',
  LOAN_CONFIGURATION_V2_SELECT_REFI_REASON = 'loan-configuration-select-refi-reason-v2',
  LOAN_CONFIGURATION_V2_SETUP_LOAN = 'loan-configuration-setup-loan-v2',

  YOUR_INCOME_V2_WIZARD = 'your-income-v2-wizard-modal',
  YOUR_INCOME_V2_WIZARD_EMPLOYMENT = 'your-income-v2-wizard-employment-income-modal',
  YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL = 'your-income-v2-wizard-expected-rental-income-modal',
  YOUR_INCOME_V2_WIZARD_RENTAL = 'your-income-v2-wizard-rental-income-modal',

  // Loan Variation Screens
  LOAN_VARIATION_REDUCE_REPAYMENTS = 'loan-variation-reduce-repayments',
  LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION = 'loan-variation-reduce-repayments-confirmation',
  LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN = 'loan-variation-reduce-repayments-coborrower-approval',

  YOUR_INCOME_V2_MODAL = 'your-income-v2-modal',
  YOUR_INCOME_V2_ADD_INCOME_MODAL = 'your-income-v2-add-income-modal',
  YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL = 'your-income-v2-add-expected-rental-income-modal',
  YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL = 'your-income-v2-edit-employment-income-modal',
  YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL = 'your-income-v2-edit-expected-rental-income-modal',
  YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL = 'your-income-v2-edit-rental-income-modal',
  YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL = 'your-income-v2-edit-dividend-income-modal',
  YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL = 'your-income-v2-edit-government-income-modal',

  YOUR_EXPENSES_V2_WIZARD = 'your-expenses-v2-wizard-modal',
  YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS = 'your-expenses-v2-wizard-monthly-expenses-details-modal',
  YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES = 'your-expenses-v2-wizard-monthly-expenses-types-modal',

  FINANCIAL_DECLARATION_MODAL_V2 = 'financial-declaration-modal-v2',

  DEBTS_WIZARD_V2_MODAL = 'debts-wizard-modal-v2',
  DEBTS_WIZARD_V2_DEBT_LIST = 'debts-wizard-debt-list-v2',
  DEBTS_WIZARD_V2_REVIEW_DETAILS = 'debts-wizard-review-details',

  // Open banking modals
  SELECT_INSTITUTION_MODAL = 'select-institution-modal',
  SELECT_BANK = 'select-bank',
  BANK_LOGIN = 'bank-login',
  BANK_REFRESH = 'bank-refresh',
  MANAGE_CONSENTS = 'manage-consents',

  IDENTIFICATION_V2_MODAL = 'identification-modal-v2',
  IDENTIFICATION_V2_YOUR_INFORMATION = 'identification-your-information-v2',
  IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE = 'identification-borrower-driver-licence-v2',
  IDENTIFICATION_V2_ID_VERIFICATION_CONSENT = 'identification-id-verification-consent-v2',
  UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL = 'unable-to-verify-your-identity-modal',
  IDENTITY_VERIFICATION_PENDING_V2 = 'identity-verification-pending-action-sheet-v2',

  HOME_LOAN_MODAL = 'home-loan-modal',
  HOME_LOAN_WITHDRAWAL = 'home-loan-modal-withdrawal',
  HOME_LOAN_WITHDRAWAL_TO = 'home-loan-modal-withdrawal-to',
  HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT = 'home-loan-modal-withdrawal-add-account',
  HOME_LOAN_WITHDRAWAL_V2 = 'home-loan-modal-withdrawal-v2',
  HOME_PENDING_TRANSACTION = 'home-loan-modal-pending-transaction',
  HOME_PROCESSED_TRANSACTION = 'home-loan-modal-processed-transaction',
  HOME_NEXT_REPAYMENT = 'home-loan-next-repayment',
  APPLICATION_TRACKING = 'application-tracking',

  PERSONAL_DETAILS_MODAL = 'personal-details-modal',

  // Personal Details Modal - Mobile Change
  PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY = 'personal-details-modal-mobile-change-email-verify',
  PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY = 'personal-details-modal-mobile-change-old-mobile-verify',
  PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE = 'personal-details-modal-mobile-change-new-mobile',
  PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY = 'personal-details-modal-mobile-change-new-mobile-verify',

  // Personal Details Modal - Email Change
  PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY = 'personal-details-modal-email-change-email-verify',
  PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY = 'personal-details-modal-email-change-old-mobile-verify',
  PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL = 'personal-details-modal-email-change-new-email',
  PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY = 'personal-details-modal-email-change-new-email-verify',

  // Personal Details Page - Legal Name Change
  PERSONAL_DETAILS_LEGAL_NAME_CHANGE = 'personal-details-legal-name-change',

  AUTOPAY_SETTINGS_MODAL = 'autopay-settings-modal',
  AUTOPAY_EXISTING_SETTINGS = 'autopay-existing-settings-modal',
  AUTOPAY_SETUP = 'autopay-setup-modal',
  AUTOPAY_EDIT = 'autopay-edit-modal',
  AUTOPAY_CUSTOM_REPAYMENT = 'autopay-custom-repayment-modal',
  AUTOPAY_CHANGE_REQUEST = 'autopay-change-request-modal',
  AUTOPAY_SETTINGS_SELECT_ACCOUNT = 'autopay-settings-select-account-modal',
  AUTOPAY_SETTINGS_ADD_ACCOUNT = 'autopay-settings-add-account-modal',

  HOME_LOAN_INSIGHTS_MODAL = 'home-loan-insights-modal',
  HOME_LOAN_INSIGHTS_TERM_REMAINING = 'home-loan-insights-term-remaining',
  HOME_LOAN_INSIGHTS_PROPERTY_VALUE = 'home-loan-insights-property-value',
  HOME_LOAN_INSIGHTS_DISCOUNT_BUMP = 'home-loan-insights-discount-bump',
  HOME_LOAN_INSIGHTS_INTEREST_SAVINGS = 'home-loan-insights-interest-savings',

  BORROWER_V2_MODAL = 'borrower-modal-v2',
  BORROWER_V2_DETAILS = 'borrower-details-v2',
  BORROWER_V2_INVITE_COBORROWER = 'invite-coborrower-v2',

  CONDITIONAL_APPROVAL_MODAL = 'conditional-approval-modal',
  CONDITIONAL_APPROVAL_APP_DETAILS = 'conditional-approval-application-details',
  CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS = 'conditional-approval-your-borrowing-power-details',
  CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS = 'conditional-approval-your-conditional-approval-details',
  CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS = 'conditional-approval-verify-your-financials',

  LOAN_VERIFICATION = 'loan-verification',

  CONNECTED_BANKS_MODAL = 'connected-banks-modal',
  CONNECTED_BANKS = 'connected-banks',
  BANK_PROFILE = 'bank-profile',
  BANK_ACCOUNT_DETAILS = 'bank-account-details',

  TRANSACTION_MODAL = 'transaction-modal',
  TRANSACTION_FILTER = 'transaction-filter',

  STATEMENTS_MODAL = 'statements-modal',
  STATEMENTS_LIST_MODAL = 'statements-list-modal',
  STATEMENTS_PREVIEW_MODAL = 'statements-preview-modal',
  STATEMENTS_FILTER_MODAL = 'statements-filter-modal',

  // statement UI v2
  STATEMENTS_V2_MODAL = 'statements-v2-modal',
  STATEMENTS_PREVIEW_V2_MODAL = 'statements-preview-v2-modal',
  STATEMENTS_FILTER_V2_MODAL = 'statements-filter-v2-modal',

  // Standalone modals
  ACCEPTABLE_DOCUMENTS_MODAL = 'acceptable-documents-modal',
  DOCUMENT_CENTRE_MODAL = 'document-centre-modal',
  DOCUMENT_PREVIEW_MODAL = 'document-preview-modal',
  LEARN_OPEN_BANKING_MODAL = 'learn-open-banking-modal',
  VERIFY_YOUR_IDENTITY_CONSENT_MODAL = 'verify-your-identity-consent-modal',
  JOIN_APPLICATION_MODAL = 'join-application-modal',
  APPROVAL_RESPONSE_MODAL = 'approval-response-modal',

  // Standalone V2 Modal
  SINGLE_V2_MODAL = 'single-v2-modal',
  YOUR_PROPERTY_V2_MODAL = 'your-property-v2-modal',
  YOUR_PROPERTY_PURCHASE_MODAL = 'your-property-purchase-modal',
  DEBTS_NO_DETECTED_LIABILITY_MODAL = 'debts-no-detected-liability-modal',
  DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL = 'debts-add-manual-liability-modal-v2',
  DEBTS_EDIT_LIABILITY_V2_MODAL = 'debts-edit-liability-modal-v2',
  SELECT_REFI_REASON_V2_MODAL = 'select-refi-reason-v2-modal',
  SETUP_LOAN_V2_MODAL = 'setup-loan-v2-modal',
  SETUP_LOAN_FOR_PURCHASE_MODAL = 'setup-loan-for-purchase-modal',
  SETUP_LOAN_FOR_TOP_UP_MODAL = 'setup-loan-for-top-up-modal',
  RENTAL_EXPENSES_LEARN_MORE_V2_MODAL = 'rental-expenses-learn-more-v2-modal',
  AML_CTF_ACT_V2_MODAL = 'aml-ctf-act-v2-modal',
  VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL = 'verify-your-identity-learn-more-v2-modal',
  MANUAL_VERIFICATION_V2_MODAL = 'manual-verification-v2-modal',
  INCOME_SUPPORTING_DOCUMENT_MODAL = 'income-supporting-document-modal',
  SECURITY_SUPPORTING_DOCUMENT_MODAL = 'security-supporting-document-modal',
  LIABILITY_SUPPORTING_DOCUMENT_MODAL = 'liability-supporting-document-modal',

  PRIVACY_COLLECTION_STATEMENT_MODAL = 'privacy-collection-statement-modal',

  UPDATE_ADDRESS_MODAL = 'update-address-modal',

  NEW_FEATURES_MODAL = 'new-features-modal',

  TOP_UP_PROPERTY_VALUE_MODAL = 'top-up-property-value-modal',

  DOCUSIGN_CONTRACT_RETURN = 'docusign-contract-return',

  NOT_FOUND = 'not-found',
  INTERCOM_CHAT = 'intercom-chat',

  MOCK_ACCOUNT_MODAL = 'mock-account-modal',
}

export enum PageStateForList {
  Empty = 'Empty',
  HasData = 'HasData',
}

export enum PageStateForStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Declined = 'Declined',
  ConductRulesNotRunDeclined = 'ConductRulesNotRunDeclined',
  ConductRulesFailedDeclined = 'ConductRulesFailedDeclined',
}

type PageGTMTitleWithStatesType = {
  [Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB]: Record<
    PageStateForList,
    string
  >;
  [Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL]: Record<
    PageStateForStatus,
    string
  >;
};

export const PageGTMTitleWithStates: PageGTMTitleWithStatesType = {
  [Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB]: {
    [PageStateForList.Empty]: 'App - Verify - Connect Your Bank',
    [PageStateForList.HasData]: 'App - Verify - Connected Accounts',
  },
  [Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL]: {
    [PageStateForStatus.Approved]: 'App - Purchase - Conditionally Approved',
    [PageStateForStatus.Pending]:
      'App - Purchase - Pending Conditional Approval',
    [PageStateForStatus.Declined]:
      'App - Purchase - Conditional Approval Declined',
    [PageStateForStatus.ConductRulesFailedDeclined]:
      'App - Purchase - Conditional Approval Conduct Rules Failed Declined',
    [PageStateForStatus.ConductRulesNotRunDeclined]:
      'App - Purchase - Conditional Approval Conduct Rules Not Run Declined',
  },
};

const LoanApplicationPageGTMTitle: Record<LoanApplicationSection, string> = {
  [LoanApplicationSection.Splash]: 'loan-application-splash',
  [LoanApplicationSection.YourUnloanOffer]: 'loan-application-offer',
  [LoanApplicationSection.InterstitialLoanSubmit]:
    'loan-application-interstitial-submit',
  [LoanApplicationSection.Completed]: 'loan-application-completed',

  [LoanApplicationSection.Intro]: 'App - Apply - Intro',
  [LoanApplicationSection.Property]: 'App - Apply - Property',
  [LoanApplicationSection.Borrowers]: 'App - Apply - Borrowers',
  [LoanApplicationSection.Income]: 'App - Apply - Income',
  [LoanApplicationSection.Expense]: 'App - Apply - Expenses',
  [LoanApplicationSection.ConditionalApproval]:
    'App - Purchase - Conditional Approval',
  [LoanApplicationSection.Debts]: 'App - Apply - Debts and Liabilities',
  [LoanApplicationSection.YourUnloan]: 'App - Apply - Your Unloan',
  [LoanApplicationSection.ReviewSummary]: 'App - Apply - Review Summary',
  [LoanApplicationSection.Submit]: 'App - Apply - Submit',
  [LoanApplicationSection.YourUnloanOfferEsign]: 'App - Contract - eSign Offer',
  [LoanApplicationSection.VerifyIncomeOpenBanking]:
    'App - Verify - Connect Your Bank',
  [LoanApplicationSection.VOICompleted]:
    'App - Apply - Verification of Identity Completed',
};

const PageGTMTitle: Record<Screen | ActionSheetType, string> = {
  [Screen.PAYMENT_FAILURE_MODAL]: 'App - Payment Failure - Modal',
  [Screen.ACCEPTABLE_DOCUMENTS_MODAL]: 'App - Acceptable Documents - Index',
  [Screen.DOCUMENT_PREVIEW_MODAL]: 'App - Document Preview - Modal',
  [ActionSheetType.LOW_EXPENSES]: 'App - Low Expenses - Action Sheet',
  [ActionSheetType.NO_DEBTS_CONFIRMATION]: 'App - Your Debts - Action Sheet',
  [ActionSheetType.OTHER_TOP_UP_REASON_CONFIRMATION]:
    'App - Other Top-up Reason Confirmation  - Action Sheet',
  [ActionSheetType.PERSONAL_USE_TOP_UP_REASON_CONFIRMATION]:
    'App - Personal Use Top-up Reason Confirmation - Action Sheet',
  [ActionSheetType.YOUR_HOME_TOP_UP_REASON_CONFIRMATION]:
    'App - Property Your-Home Top-up Reason Confirmation - Action Sheet',
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION]:
    'App - Add Direct Debit Account Confirmation - Action Sheet',
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2]:
    'App - Add Direct Debit Account Confirmation V2 - Action Sheet',
  [Screen.AUTOPAY_CHANGE_REQUEST]: 'App - AutoPay Change Request - Modal',
  [ActionSheetType.AUTOPAY_CONFIRMATION]:
    'App - AutoPay Confirmation - Action Sheet',
  [Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT]:
    'App - AutoPay Settings Add Account - Modal',
  [Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT]:
    'App - AutoPay Settings Select Account - Modal',
  [Screen.AUTOPAY_SETUP]: 'App - AutoPay Setup - Modal',
  [Screen.AUTOPAY_EDIT]: 'App - AutoPay Edit - Modal',
  [Screen.HOME_LOAN]: 'App - Home Loan Account - Modal',
  [Screen.HOME_PENDING_TRANSACTION]:
    'App - Home Loan Pending Transaction - Modal',
  [Screen.HOME_PROCESSED_TRANSACTION]:
    'App - Home Loan Processed Transaction - Modal',
  [Screen.HOME_NEXT_REPAYMENT]: 'App - Home Loan Next Repayment - Modal',
  [Screen.HOME_LOAN_WITHDRAWAL]: 'App - Home Loan Withdrawal - Modal',
  [Screen.HOME_LOAN_WITHDRAWAL_TO]: 'App - Home Loan Withdrawal To - Modal',
  [Screen.HOME_LOAN_WITHDRAWAL_V2]: 'App - Home Loan Withdrawal - Modal',
  [Screen.INCOME_SUPPORTING_DOCUMENT_MODAL]:
    'App - Income Supporting Document - Modal',
  [Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL]:
    'App - Security Supporting Document - Modal',
  [Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL]:
    'App - Liability Supporting Document - Modal',
  [Screen.STATEMENTS_FILTER_MODAL]: 'App - Statements Filter - Modal',
  [Screen.STATEMENTS_FILTER_V2_MODAL]: 'App - Statements Filter - V2 - Modal',
  [Screen.STATEMENTS_LIST_MODAL]: 'App - Statements List - Modal',
  [Screen.STATEMENTS_PREVIEW_MODAL]: 'App - Statements Preview - Modal',
  [Screen.STATEMENTS_PREVIEW_V2_MODAL]: 'App - Statements Preview - V2 - Modal',
  [ActionSheetType.WITHDRAW_CONFIRMATION]:
    'App - Withdrawal Confirmation - Action Sheet',
  [ActionSheetType.CONFIRMATION_SUCCESS]:
    'App - Confirmation Success - Action Sheet',
  [ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION]:
    'App - Co-Borrower Information Confirmation - Action Sheet',
  [Screen.NOT_FOUND]: 'App - Page Not Found',
  [Screen.AUTOPAY_EXISTING_SETTINGS]: 'App - AutoPay Existing Settings - Modal',
  [Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT]:
    'App - Home Loan Withdrawal Add Account - Modal',
  [Screen.APPLICATION_TRACKING]: '',
  [Screen.AUTH_FLOW]: '',
  [Screen.HOME]: 'App - Home - Index',
  [Screen.INVALID_APPLICATION_INVITE]: '',
  [Screen.JOIN_APPLICATION_MODAL]: '',
  [Screen.LEARN_OPEN_BANKING_MODAL]: 'App - Verify - Learn Open Banking Modal',
  [Screen.BANK_LOGIN]: 'App - Verify - Open Banking Login',
  [Screen.BANK_REFRESH]: 'App - Verify - Open Banking Refresh',
  [Screen.SETTINGS_HOME]: 'App - Settings - Index',
  [Screen.SETTINGS_MANAGE_DATA_SHARING]: 'App - Settings - Manage Data Sharing',
  [Screen.SETTINGS_PERSONAL]: 'App - Settings - Personal',
  [Screen.SETTINGS_DAILY_TRANSFER_LIMIT]:
    'App - Settings - Daily Transfer Limit',
  [Screen.DOCUSIGN_CONTRACT_RETURN]: 'App - Contract - Accepted',
  [Screen.VOI_COMPLETED]: 'App - Home - Verification of Identity Completed',

  // The v1 variant of these screens only specify an empty PageGTMTitle here.
  // This means, the GTM tags being sent for these screens fallback to use the Screen enum value.
  // To replicate the same GTM tags for the v2 variant,
  // We use the v1 variant Screen enum value as the PageGTMTitle.
  [Screen.LOAN_APPLICATION_V2_SPLASH]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Splash],
  [Screen.LOAN_APPLICATION_V2_OFFER]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloanOffer],
  [Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.InterstitialLoanSubmit],
  [Screen.LOAN_APPLICATION_V2_COMPLETED]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Completed],
  [Screen.LOAN_APPLICATION_PURCHASE_OFFER]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloanOffer],
  [Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.InterstitialLoanSubmit],
  [Screen.LOAN_APPLICATION_PURCHASE_COMPLETED]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Completed],
  [Screen.BORROWER_V2_INVITE_COBORROWER]: 'invite-coborrower',

  // V2 Main screen
  [Screen.LOAN_APPLICATION_V2_INTRO]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Intro],
  [Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Property],
  [Screen.LOAN_APPLICATION_V2_BORROWERS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Borrowers],
  [Screen.LOAN_APPLICATION_V2_YOUR_INCOME]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Income],
  [Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Expense],
  [Screen.LOAN_APPLICATION_V2_YOUR_DEBTS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Debts],
  [Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloan],
  [Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.ReviewSummary],
  [Screen.LOAN_APPLICATION_V2_SUBMIT]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Submit],
  [Screen.LOAN_APPLICATION_V2_VOI_COMPLETED]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.VOICompleted],
  [Screen.LOAN_APPLICATION_V2_OFFER_ESIGN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloanOfferEsign],

  // Top Up
  [Screen.LOAN_APPLICATION_TOP_UP_INTRO]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Intro],
  [Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Property],
  [Screen.LOAN_APPLICATION_TOP_UP_BORROWERS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Borrowers],
  [Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Income],
  [Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Expense],
  [Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Debts],
  [Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloan],
  [Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.ReviewSummary],
  [Screen.LOAN_APPLICATION_TOP_UP_SUBMIT]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Submit],
  [Screen.LOAN_APPLICATION_TOP_UP_VOI_COMPLETED]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.VOICompleted],
  [Screen.LOAN_APPLICATION_TOP_UP_OFFER_ESIGN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloanOfferEsign],

  [Screen.LOAN_APPLICATION_PURCHASE_INTRO]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Intro],
  [Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Property],
  [Screen.LOAN_APPLICATION_PURCHASE_BORROWERS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Borrowers],
  [Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Income],
  [Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Expense],
  [Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Debts],
  [Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloan],
  [Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.ReviewSummary],
  [Screen.LOAN_APPLICATION_PURCHASE_SUBMIT]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.Submit],
  [Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.VOICompleted],
  [Screen.LOAN_APPLICATION_PURCHASE_OFFER_ESIGN]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.YourUnloanOfferEsign],
  [Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL]:
    LoanApplicationPageGTMTitle[LoanApplicationSection.ConditionalApproval],

  // V2 Sub screens
  [Screen.YOUR_PROPERTY_V2_MODAL]: 'App - Property Details - Modal',
  [Screen.YOUR_PROPERTY_PURCHASE_MODAL]: 'App - Property Details - Modal',
  [ActionSheetType.REMOVE_PROPERTY_V2]: 'App - Remove Property - Action Sheet',
  [Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE]:
    'App - ID - Borrower Drivers License',
  [Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT]:
    'App - ID - Verification Consent',
  [Screen.IDENTITY_VERIFICATION_PENDING_V2]: 'App - ID - Verification Consent',
  [Screen.IDENTIFICATION_V2_YOUR_INFORMATION]: 'App - ID - Borrower Details',
  [Screen.BORROWER_V2_DETAILS]: 'App - Borrower Details - Index',
  [ActionSheetType.REMOVE_CO_BORROWER_V2]:
    'App - Remove Co-borrower - Action Sheet',
  [Screen.CONDITIONAL_APPROVAL_APP_DETAILS]:
    'App - Purchase - Application Details',
  [Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS]:
    'App - Borrowing power - View Details',
  [Screen.CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS]:
    'App - Conditional approval - View Conditions',
  [Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS]:
    'App - Purchase - Conditional Approval Verify Financials',
  [ActionSheetType.DELETE_INCOME_ACTION_SHEET]:
    'App - Delete Income - Action Sheet',
  [Screen.YOUR_INCOME_V2_MODAL]: 'App - Financials - Modal',
  [Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL]: 'App - Financials - Add New Income',
  [Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL]:
    'App - Financials Wizard - Expected Rental Income',
  [Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL]:
    'App - Financials - Edit Employment Income',
  [Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL]:
    'App - Financials - Edit Expected Rental Income',
  [Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL]:
    'App - Financials - Edit Rental Income',
  [Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL]:
    'App - Financials - Edit Other Income',
  [Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL]:
    'App - Financials - Edit Other Income',
  [Screen.YOUR_INCOME_V2_WIZARD]: 'App - Financials Wizard - Income',
  [Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT]:
    'App - Financials Wizard - Employment Income',
  [Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL]:
    'App - Financials Wizard - Expected Rental Income',
  [Screen.YOUR_INCOME_V2_WIZARD_RENTAL]:
    'App - Financials Wizard - Rental Income',
  [Screen.YOUR_EXPENSES_V2_WIZARD]: 'App - Financials Wizard - Expenses',
  [Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS]:
    'App - Financials Wizard - Household Living Situation',
  [Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES]:
    'App - Financials Wizard - Monthly Expenses',
  [Screen.DEBTS_WIZARD_V2_MODAL]: 'App - Debts Wizard - Modal',
  [Screen.DEBTS_WIZARD_V2_DEBT_LIST]: 'App - Debts Wizard - List',
  [Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL]:
    'App - Debts Wizard - No Detected Liability',
  [Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL]:
    'App - Debts Wizard - Add Credit Account',
  [Screen.DEBTS_EDIT_LIABILITY_V2_MODAL]:
    'App - Debts Wizard - Edit Credit Account',
  [Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS]:
    'App - Debts Wizard - Review Details',
  [Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON]:
    'App - Loan Configuration - Select Refinance Reason',
  [Screen.SETUP_LOAN_V2_MODAL]: 'App - Loan Configuration - Setup Loan',
  [Screen.SETUP_LOAN_FOR_PURCHASE_MODAL]:
    'App - Loan Configuration - Setup Loan',
  [Screen.SETUP_LOAN_FOR_TOP_UP_MODAL]: 'App - Loan Configuration - Setup Loan',
  [Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN]:
    'App - Loan Configuration - Setup Loan',
  [Screen.FINANCIAL_DECLARATION_MODAL_V2]: 'App - Financial Declaration',
  [ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2]:
    'App - Repayment Plan for Retirement - Action Sheet',
  [Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL]:
    'App - Rental Expenses Learn More - Modal',
  [Screen.AML_CTF_ACT_V2_MODAL]: 'App - AML CTF Act - Modal',
  [Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL]:
    'App - ID - Unable to verify your identity',
  [Screen.ACCOUNT_ADDRESS_BOOK]: 'App - Account Address Book',
  [Screen.ADD_NEW_ACCOUNT]: 'App - Add New Account',
  [Screen.ACTION_SHEET]: 'App - Action Sheet',
  [Screen.MAIN_NAVIGATOR]: 'App - Main Navigator',
  [Screen.NAVIGATOR_FULL_SCREEN_MODAL]: 'App - Navigator Full Screen - Modal',
  [Screen.NAVIGATOR_MODAL]: 'App - Navigator - Modal',
  [Screen.HOME_DASHBOARD]: 'App - Home - Dashboard',
  [Screen.HOME_LOAN_SETTINGS]: 'App - Home Loan - Settings',
  [Screen.HOME_LOAN_STATEMENTS]: 'App - Home Loan - Statements',
  [Screen.ACCOUNT_PERMISSIONS]: 'App - Account Permissions - Index',
  [Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN]:
    'App - Account Permissions - Approval Screen',
  [Screen.SETTINGS]: 'App - Settings - Index',
  [Screen.SETTINGS_SECURITY]: 'App - Settings - Security',
  [Screen.SETTINGS_SECURITY_FACE_ID]: 'App - Settings - Security Face Id',
  [Screen.SETTINGS_NOTIFICATION]: 'App - Settings - Notification',
  [Screen.SETTINGS_CONNECTED_BANKS]: 'App - Settings - Connected Banks',
  [Screen.SETTINGS_BANK_PROFILE]: 'App - Settings - Bank Profile',
  [Screen.SETTINGS_BANK_ACCOUNT_DETAIL]: 'App - Settings - Bank Account Detail',
  [Screen.SETTINGS_DATA_SHARING_DETAILS]:
    'App - Settings - Data Sharing Details',
  [Screen.SETTINGS_DATA_SHARING_PREFERENCES]:
    'App - Settings - Data Sharing Preferences',
  [Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES]:
    'App - Settings - Joint Account Preferences',
  [Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST]:
    'App - Settings - Data Sharing Notification Preferences Account List',
  [Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY]:
    'App - Settings - Data Sharing Consent History',
  [Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES]:
    'App - Settings - Data Sharing Account Notification Preferences',
  [Screen.SETTINGS_NEW_FEATURES]: 'App - Settings - New Features',
  [Screen.DATA_SHARING_CURRENT_STATUS]: 'App - Data Sharing - Current Status',
  [Screen.DATA_SHARING_SUMMARY]: 'App - Data Sharing - Summary',
  [Screen.DATA_SHARING_CONFIRMATION]: 'App - Data Sharing Confirmation - Index',
  [Screen.DATA_SHARING_CONFIRMATION_MODAL]:
    'App - Data Sharing Confirmation - Modal',
  [Screen.LOAN_APPLICATION_V2]: 'App - Loan Application - Index',
  [Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB]:
    'App - Loan Application - Verify Income Ob',
  [Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT]:
    'App - Loan Application Top Up - Interstitial Loan Submit',
  [Screen.LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB]:
    'App - Loan Application Top Up - Verify Income Ob',
  [Screen.LOAN_APPLICATION_TOP_UP_COMPLETED]:
    'App - Loan Application Top Up - Completed',
  [Screen.LOAN_APPLICATION_TOP_UP_OFFER]:
    'App - Loan Application Top Up - Offer',
  [Screen.LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB]:
    'App - Loan Application - Purchase Verify Income Ob',
  [Screen.LOAN_CONFIGURATION_V2_MODAL]: 'App - Loan Configuration - Modal',
  [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS]:
    'App - Loan Variation - Reduce Repayments',
  [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_CONFIRMATION]:
    'App - Loan Variation - Reduce Repayments Confirmation',
  [Screen.LOAN_VARIATION_REDUCE_REPAYMENTS_APPROVAL_SCREEN]:
    'App - Loan Variation - Reduce Repayments Approval Screen',
  [Screen.SELECT_INSTITUTION_MODAL]: 'App - Select Institution - Modal',
  [Screen.SELECT_BANK]: 'App - Select Bank',
  [Screen.MANAGE_CONSENTS]: 'App - Manage Consents',
  [Screen.IDENTIFICATION_V2_MODAL]: 'App - Identification - Modal',
  [Screen.HOME_LOAN_MODAL]: 'App - Home Loan - Modal',
  [Screen.PERSONAL_DETAILS_MODAL]: 'App - Personal Details - Modal',
  [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY]:
    'App - Personal Details - Mobile Change Email Verify',
  [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY]:
    'App - Personal Details - Mobile Change Old Mobile Verify',
  [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE]:
    'App - Personal Details - Mobile Change New Mobile',
  [Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY]:
    'App - Personal Details - Mobile Change New Mobile Verify',
  [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY]:
    'App - Personal Details - Email Change Email Verify',
  [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY]:
    'App - Personal Details - Email Change Old Mobile Verify',
  [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL]:
    'App - Personal Details - Email Change New Email',
  [Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY]:
    'App - Personal Details - Email Change New Email Verify',
  [Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE]:
    'App - Personal Details - Legal Name Change',
  [Screen.AUTOPAY_SETTINGS_MODAL]: 'App - Autopay Settings - Modal',
  [Screen.AUTOPAY_CUSTOM_REPAYMENT]: 'App - Autopay - Custom Repayment',
  [Screen.HOME_LOAN_INSIGHTS_MODAL]: 'App - Home Loan Insights - Modal',
  [Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING]:
    'App - Home Loan Insights - Term Remaining',
  [Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE]:
    'App - Home Loan Insights - Property Value',
  [Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP]:
    'App - Home Loan Insights - Discount Bump',
  [Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS]:
    'App - Home Loan Insights - Interest Savings',
  [Screen.BORROWER_V2_MODAL]: 'App - Borrower Details - Modal',
  [Screen.CONDITIONAL_APPROVAL_MODAL]: 'App - Purchase - Modal',
  [Screen.LOAN_VERIFICATION]: 'App - Loan Verification',
  [Screen.CONNECTED_BANKS_MODAL]: 'App - Connected Banks - Modal',
  [Screen.CONNECTED_BANKS]: 'App - Connected Banks - Index',
  [Screen.BANK_PROFILE]: 'App - Bank Profile',
  [Screen.BANK_ACCOUNT_DETAILS]: 'App - Bank Account Details',
  [Screen.TRANSACTION_MODAL]: 'App - Transaction - Modal',
  [Screen.TRANSACTION_FILTER]: 'App - Transaction - Filter',
  [Screen.STATEMENTS_MODAL]: 'App - Statements - Modal',
  [Screen.STATEMENTS_V2_MODAL]: 'App - Statements - Modal',
  [Screen.DOCUMENT_CENTRE_MODAL]: 'App - Document Centre - Modal',
  [Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL]:
    'App - Verify Your Identity Consent - Modal',
  [Screen.APPROVAL_RESPONSE_MODAL]: 'App - Approval Response - Modal',
  [Screen.SINGLE_V2_MODAL]: 'App - Single - Modal',
  [Screen.SELECT_REFI_REASON_V2_MODAL]: 'App - Select Refi Reason - Modal',
  [Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL]:
    'App - Verify Your Identity Learn More - Modal',
  [Screen.MANUAL_VERIFICATION_V2_MODAL]: 'App - Manual Verification - Modal',
  [Screen.PRIVACY_COLLECTION_STATEMENT_MODAL]:
    'App - Privacy Collection Statement - Modal',
  [Screen.UPDATE_ADDRESS_MODAL]: 'App - Update Address - Modal',
  [Screen.NEW_FEATURES_MODAL]: 'App - New Features - Modal',
  [Screen.TOP_UP_PROPERTY_VALUE_MODAL]: 'App - Top Up Property Value - Modal',
  [Screen.INTERCOM_CHAT]: 'App - Intercom Chat',
  [Screen.MOCK_ACCOUNT_MODAL]: 'App - Mock Account - Modal',

  [ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE]:
    'App - Account Address Book Delete - Action Sheet',
  [ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION]:
    'App - Autopay Cancellation Confirmation - Action Sheet',
  [ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION]:
    'App - Autopay Shortfall Confirmation - Action Sheet',
  [ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2]:
    'App - Autopay Shortfall Confirmation - Action Sheet',
  [ActionSheetType.CA_CONFIRMATION]: 'App - Ca Confirmation - Action Sheet',
  [ActionSheetType.COPY_BSB_ACCOUNT_NUMBER]:
    'App - Copy Bsb Account Number - Action Sheet',
  [ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3]:
    'App - Copy Bsb Account Number - V3 - Action Sheet',
  [ActionSheetType.CREDIT_CHECK_CONFIRMATION]:
    'App - Credit Check Confirmation - Action Sheet',
  [ActionSheetType.DEBTS_MANUAL_ENTRY_REQUIRED]:
    'App - Debts Manual Entry Required - Action Sheet',
  [ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET]:
    'App - Delete Account Completed - Action Sheet',
  [ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET]:
    'App - Delete Account Confirmation Check - Action Sheet',
  [ActionSheetType.DELETE_EXPENSE_ACTION_SHEET]:
    'App - Delete Expense - Action Sheet',
  [ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET]:
    'App - Delete Manually Added Liability - Action Sheet',
  [ActionSheetType.DEVELOPER_TOOLS]: 'App - Developer Tools - Action Sheet',
  [ActionSheetType.DISCONNECT_BANK]: 'App - Disconnect Bank - Action Sheet',
  [ActionSheetType.ENV_SWITCHER]: 'App - Env Switcher - Action Sheet',
  [ActionSheetType.FLAG_DETECTED_LIABILITY]:
    'App - Flag Detected Liability - Action Sheet',
  [ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET]:
    'App - Green Tick Confirmation - Action Sheet',
  [ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET]:
    'App - Prevent Closing Modal - Action Sheet',
  [ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION]:
    'App - Settings Data Sharing Confirmation - Action Sheet',
  [ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE]:
    'App - Settings Data Sharing Confirmation Message - Action Sheet',
  [ActionSheetType.SOCIAL_SIGN_IN]: 'App - Social Sign In - Action Sheet',
  [ActionSheetType.STOP_DATA_SHARE_CONFIRMATION]:
    'App - Stop Data Share Confirmation - Action Sheet',
  [ActionSheetType.TRANSACTION_HISTORY]:
    'App - Transaction History - Action Sheet',
  [ActionSheetType.TRANSFER_LIMIT_REACHED]:
    'App - Transfer Limit Reached - Action Sheet',
  [ActionSheetType.UPDATE_APPLICATION_CONFIRMATION]:
    'App - Update Application Confirmation - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES]:
    'App - Reduce Repayments Confirm Changes - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED]:
    'App - Reduce Repayments Submitted - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST]:
    'App - Reduce Repayments Cancel Request - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED]:
    'App - Reduce Repayments Request Cancelled - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST]:
    'App - Reduce Repayments Decline Request - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED]:
    'App - Reduce Repayments Request Declined - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST]:
    'App - Reduce Repayments Approve Request - Action Sheet',
  [ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED]:
    'App - Reduce Repayments Request Approved - Action Sheet',
  [ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES]:
    'App - Account Permissions Confirm Changes - Action Sheet',
  [ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE]:
    'App - Account Permissions Leave Page - Action Sheet',
  [ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED]:
    'App - Account Permissions Change Request Submitted - Action Sheet',
  [ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL]:
    'App - Account Permissions Approval - Action Sheet',
  [ActionSheetType.ACCOUNT_PERMISSIONS_APPLIED]:
    'App - Account Permissions Applied - Action Sheet',
};

const DEFAULT_TITLE = 'Unloan';

export function getPageGTMTitle(screen: Screen | ActionSheetType) {
  return PageGTMTitle[screen] || screen;
}
const PageTitle: Partial<Record<Screen | ActionSheetType, string>> = {
  [Screen.AML_CTF_ACT_V2_MODAL]: 'Borrowers',
  [Screen.BORROWER_V2_DETAILS]: 'Borrowers',
  [Screen.BORROWER_V2_INVITE_COBORROWER]: 'Invite Co-Borrower',
  [ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION]: 'Invite Co-Borrower',
  [Screen.IDENTITY_VERIFICATION_PENDING_V2]: 'Borrowers',
  [ActionSheetType.REMOVE_CO_BORROWER_V2]: 'Borrowers',

  // Conditional Approval
  [Screen.CONDITIONAL_APPROVAL_APP_DETAILS]: 'Application Details',
  [Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS]:
    'Your Borrowing Power',
  [Screen.CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS]:
    'Your Conditional Approval',

  // Identification v2
  [Screen.LOAN_APPLICATION_V2_BORROWERS]: 'Borrowers',
  [Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE]: 'Borrowers',
  [Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT]: 'Borrowers',
  [Screen.IDENTIFICATION_V2_YOUR_INFORMATION]: 'Borrowers',
  [Screen.UNABLE_TO_VERIFY_YOUR_IDENTITY_MODAL]: 'Borrowers',
  // Debts v2
  [Screen.LOAN_APPLICATION_V2_YOUR_DEBTS]: 'Debts & Liabilities',
  [Screen.DEBTS_WIZARD_V2_DEBT_LIST]: 'Debts & Liabilities',
  [Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS]: 'Debts & Liabilities',
  [Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL]: 'Debts & Liabilities',
  [Screen.DEBTS_EDIT_LIABILITY_V2_MODAL]: 'Debts & Liabilities',
  [ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET]:
    'Debts & Liabilities',
  [ActionSheetType.NO_DEBTS_CONFIRMATION]: 'Debts & Liabilities',
  [ActionSheetType.LOW_EXPENSES]: 'Expenses',
  [Screen.LOAN_APPLICATION_V2_YOUR_INCOME]: 'Income',
  [Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL]: 'Income',
  [Screen.YOUR_INCOME_V2_WIZARD]: 'Income',
  [Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT]: 'Income',
  [Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL]: 'Income',
  [Screen.YOUR_INCOME_V2_WIZARD_RENTAL]: 'Income',
  [ActionSheetType.DELETE_INCOME_ACTION_SHEET]: 'Income',
  [Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES]: 'Expenses',
  [Screen.YOUR_EXPENSES_V2_WIZARD]: 'Expenses',
  [Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS]: 'Expenses',
  [Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES]: 'Expenses',
  [ActionSheetType.DELETE_EXPENSE_ACTION_SHEET]: 'Expenses',
  [Screen.LOAN_APPLICATION_V2_INTRO]: 'Introduction',
  [Screen.JOIN_APPLICATION_MODAL]: 'Join',
  [Screen.LOAN_APPLICATION_V2_OFFER]: 'Application Offer',
  [Screen.LOAN_APPLICATION_V2_OFFER_ESIGN]: 'Application Offer',
  [Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT]: 'Submit',
  [Screen.LOAN_APPLICATION_V2_COMPLETED]: 'Submitted',
  [Screen.VOI_COMPLETED]: 'VOI Completed',
  [Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY]: 'Property',
  [Screen.YOUR_PROPERTY_V2_MODAL]: 'Property',
  [ActionSheetType.REMOVE_PROPERTY_V2]: 'Property',
  [Screen.LOAN_APPLICATION_V2_SPLASH]: 'Splash',
  [Screen.PRIVACY_COLLECTION_STATEMENT_MODAL]: 'Splash',
  [Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB]: 'Verify Income',
  [Screen.BANK_LOGIN]: 'Connecting Bank',
  [Screen.BANK_REFRESH]: 'Connecting Bank',
  [Screen.INCOME_SUPPORTING_DOCUMENT_MODAL]: 'Submit',
  [Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL]: 'Submit',
  [Screen.LIABILITY_SUPPORTING_DOCUMENT_MODAL]: 'Submit',
  [ActionSheetType.OTHER_TOP_UP_REASON_CONFIRMATION]: 'Your Unloan',
  [ActionSheetType.PERSONAL_USE_TOP_UP_REASON_CONFIRMATION]: 'Your Unloan',
  [ActionSheetType.YOUR_HOME_TOP_UP_REASON_CONFIRMATION]: 'Your Unloan',
  [ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2]: 'Your Unloan',
  [Screen.ACCEPTABLE_DOCUMENTS_MODAL]: DEFAULT_TITLE,
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION]: DEFAULT_TITLE,
  [ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2]: DEFAULT_TITLE,
  [Screen.APPLICATION_TRACKING]: DEFAULT_TITLE,
  [Screen.AUTH_FLOW]: DEFAULT_TITLE,
  [Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT]: DEFAULT_TITLE,
  [Screen.AUTOPAY_CHANGE_REQUEST]: DEFAULT_TITLE,
  [ActionSheetType.AUTOPAY_CONFIRMATION]: DEFAULT_TITLE,
  [Screen.AUTOPAY_CUSTOM_REPAYMENT]: DEFAULT_TITLE,
  [Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT]: DEFAULT_TITLE,
  [Screen.AUTOPAY_EXISTING_SETTINGS]: DEFAULT_TITLE,
  [Screen.AUTOPAY_SETUP]: DEFAULT_TITLE,
  [Screen.DOCUMENT_PREVIEW_MODAL]: DEFAULT_TITLE,
  [Screen.HOME]: DEFAULT_TITLE,
  [Screen.HOME_LOAN]: 'Loan Account',
  [Screen.HOME_LOAN_SETTINGS]: 'Manage your home loan',
  [Screen.HOME_LOAN_STATEMENTS]: DEFAULT_TITLE,
  [Screen.HOME_PENDING_TRANSACTION]: DEFAULT_TITLE,
  [Screen.HOME_PROCESSED_TRANSACTION]: DEFAULT_TITLE,
  [Screen.HOME_NEXT_REPAYMENT]: DEFAULT_TITLE,
  [Screen.HOME_LOAN_WITHDRAWAL]: DEFAULT_TITLE,
  [Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT]: DEFAULT_TITLE,
  [Screen.HOME_LOAN_WITHDRAWAL_TO]: DEFAULT_TITLE,
  [Screen.HOME_LOAN_WITHDRAWAL_V2]: DEFAULT_TITLE,
  [Screen.INVALID_APPLICATION_INVITE]: DEFAULT_TITLE,
  [Screen.NOT_FOUND]: DEFAULT_TITLE,
  [Screen.STATEMENTS_FILTER_MODAL]: DEFAULT_TITLE,
  [Screen.STATEMENTS_LIST_MODAL]: DEFAULT_TITLE,
  [Screen.STATEMENTS_PREVIEW_MODAL]: DEFAULT_TITLE,
  [Screen.STATEMENTS_FILTER_V2_MODAL]: DEFAULT_TITLE,
  [Screen.STATEMENTS_PREVIEW_V2_MODAL]: DEFAULT_TITLE,
  [Screen.SETTINGS_DATA_SHARING_PREFERENCES]: 'Data Sharing Preferences',
  [Screen.SETTINGS_MANAGE_DATA_SHARING]: 'Manage Data Sharing',
  [Screen.DATA_SHARING_CURRENT_STATUS]: 'Data Sharing',
  [ActionSheetType.WITHDRAW_CONFIRMATION]: DEFAULT_TITLE,
  [Screen.SETTINGS_CONNECTED_BANKS]: 'Connected Banks',
  [Screen.SETTINGS_BANK_PROFILE]: 'Bank Account Details',
  [Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST]:
    'Notification Settings',
  [Screen.SETTINGS_DATA_SHARING_ACCOUNT_NOTIFICATION_PREFERENCES]:
    'Update Notifications',
  [Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY]: 'Consent History',
  [Screen.SETTINGS_NEW_FEATURES]: "What's New",
};

export function getPageTitle(screen: Screen | ActionSheetType) {
  // This page title will be used as the screen `title`.
  // On the web browser, this will be displayed in the browser tab.
  // When screen `headerTitle` is not specified, `headerTitle`
  // will use the screen `title`.
  // `headerTitle` is displayed inside the screen by react-navigation.
  return PageTitle[screen] || DEFAULT_TITLE;
}
