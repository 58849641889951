import { isNotNullOrUndefined } from '@unloan/common-ui';
import { useContext, useMemo } from 'react';

import { AppAddressFormat } from '../../components/form/types';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  CreateEmploymentIncomeForV2MutationVariables,
  CreateOtherIncomeForV2MutationVariables,
  CreateRentalIncomeForV2MutationVariables,
  Deduction_Frequency_Enum,
  Deduction_Type_Enum,
  Employment_Income_Error_Type,
  Employment_Type_Enum,
  Frequency_Input_Enum,
  Income_Type_Enum,
  Other_Income_Type_Input_Enum,
  Property_Type_Input_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchLoanApplicationSecuritiesQuery,
  refetchPurchaseYourUnloanScreenQuery,
  refetchReviewLoanApplicationQuery,
  refetchYourFinancialsQuery,
  Rental_Income_Type_Enum,
  UpdateEmploymentIncomeForV2MutationVariables,
  UpdateOtherIncomeForV2MutationVariables,
  UpdateRentalIncomeForV2MutationVariables,
  useCreateEmploymentIncomeForV2Mutation,
  useCreateOtherIncomeForV2Mutation,
  useCreateRentalIncomeForV2Mutation,
  useGetAddIncomeV2FormOptionsQuery,
  useGetDetectedEmploymentIncomeResultForV2Query,
  useGetEmploymentIncomeDetailForV2Query,
  useGetEmploymentIncomeV2FormOptionsQuery,
  useGetExpectedRentalIncomeDetailQuery,
  useGetExpectedRentalIncomeFormOptionsQuery,
  useGetOtherIncomeDetailForV2Query,
  useGetOtherIncomeV2FormOptionsQuery,
  useGetRentalIncomeDetailForV2Query,
  useGetRentalIncomeV2FormOptionsQuery,
  useUpdateEmploymentIncomeForV2Mutation,
  useUpdateOtherIncomeForV2Mutation,
  useUpdateRentalIncomeForV2Mutation,
} from '../../generated/graphql';
import { sortApplicants } from '../../LoanApplication/utils/sortApplicants';
import { parseEnumType } from '../../utils/ensureEnumType';
import { streetTypeOptions as streetTypeOptionsConst } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import {
  mapExpectedAddressToExpectedRentalIncomeAddressInput,
  parseAddressInputForMutationAction,
} from '../../utils/validateAddressHelpers';
import { DividendIncomeFormDetails } from '../components/DividendIncomeForm';
import { EmploymentIncomeFormDetails } from '../components/EmploymentIncomeForm';
import {
  ExpectedRentalIncomeFormDetails,
  expectedRentalIncomeManualInputFieldNames,
} from '../components/ExpectedRentalIncomeForm';
import { GovernmentIncomeFormDetails } from '../components/GovernmentIncomeForm';
import {
  manualInputFieldNames,
  RentalIncomeFormDetails,
} from '../components/RentalIncomeForm';
import { IncomeYesNoEnum } from '../utils/incomeFormTypes';
import {
  getEmploymentIncomeMutationResponseError,
  getRentalIncomeMutationResponseErrorMessage,
  mapEmploymentIncomeInitialValuesForV2Form,
  mapExpectedRentalIncomeInitialValuesForm,
  mapOtherIncomeInitialValuesForV2Form,
  mapPrepopulatedDataForEmploymentIncomeV2Form,
  mapRentalIncomeInitialValuesForV2Form,
} from '../utils/incomeFormUtils';

export function useGetOptionsForEmploymentIncomeQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, ...others } = useGetEmploymentIncomeV2FormOptionsQuery({
    variables: {
      loanApplicationId,
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const occupationTypeOptions = useMemo(
    () =>
      data?.occupation_type.map(({ occupation, label }) => ({
        label,
        value: occupation,
      })) ?? [],
    [data?.occupation_type],
  );

  const employmentTypeOptions = useMemo(
    () =>
      data?.employment_type.map(({ type, description }) => ({
        label: description,
        value: type,
      })) ?? [],
    [data?.employment_type],
  );

  const incomeOwnersOptions = useMemo(
    () =>
      sortApplicants(data?.loan_application_by_pk?.applicants).map(
        ({ id, household_id, latest_full_name }) => ({
          label: latest_full_name || '--',
          value: id,
          household_id,
        }),
      ),
    [data?.loan_application_by_pk?.applicants],
  );

  return {
    occupationTypeOptions,
    employmentTypeOptions,
    incomeOwnersOptions,
    ...others,
  };
}

export function usePrepopulateEmploymentIncomeForV2Form({
  incomeVerificationId,
  loanApplicationId,
  applicantId,
  forceSkip = false,
}: {
  incomeVerificationId: string;
  loanApplicationId: string;
  applicantId: string;
  forceSkip?: boolean;
}) {
  const { flags } = useContext(FeatureFlagsContext);

  const isEnabled = flags.ENABLE_PREPOPULATE_EMPLOYMENT_INCOME;

  const skipEmploymentIncomePrepoulate =
    !isEnabled || !incomeVerificationId || forceSkip;

  const { data: queryResult, loading: isLoadingPrepopulatedData } =
    useGetDetectedEmploymentIncomeResultForV2Query({
      variables: { incomeVerificationId },
      skip: skipEmploymentIncomePrepoulate,
      context: {
        sentryContext: {
          loanApplicationId,
          applicantId,
          incomeVerificationId,
        },
      },
    });

  const detectedResult = queryResult?.user_latest_detected_employment_income;

  return {
    isEnabled,
    initialValue: mapPrepopulatedDataForEmploymentIncomeV2Form({
      detectedIncomeResults: detectedResult,
      applicantId,
    }),
    hasDetectedValues: detectedResult ? detectedResult.length > 0 : false,
    loading: isLoadingPrepopulatedData,
  };
}

export function useGetEmploymentIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const { data, loading } = useGetEmploymentIncomeDetailForV2Query({
    variables: {
      incomeId,
    },
    skip: !loanApplicationId || !incomeId,
    context: {
      sentryContext: {
        loanApplicationId,
        incomeId,
      },
    },
  });

  // Required to parse occupation type initial values
  const occupationTypeOptions =
    data?.occupation_type.map(({ label, occupation }) => ({
      label,
      value: occupation,
    })) || [];

  return {
    initialValues: mapEmploymentIncomeInitialValuesForV2Form({
      queriedDetails: data,
      occupationTypes: occupationTypeOptions,
    }),
    currentLoanIncomeCount:
      data?.income[0]?.loan_application.incomes.length ?? 0,
    loading,
  };
}

function transformToDeductionInput(
  monthlyRepayments: EmploymentIncomeFormDetails['novatedLeaseMonthlyRepayments'],
) {
  return monthlyRepayments
    ?.filter(isNotNullOrUndefined)
    .map((monthlyRepayment) => ({
      type: Deduction_Type_Enum.NovatedLease,
      repayment_amount: monthlyRepayment,
      repayment_frequency: Deduction_Frequency_Enum.Monthly,
    }));
}

export function useCreateEmploymentIncomeForV2Form({
  loanApplicationId,
  incomeVerificationId,
}: {
  loanApplicationId: string;
  incomeVerificationId?: string;
}) {
  const [
    createEmploymentIncomeMutation,
    { loading: isCreatingEmploymentIncome },
  ] = useCreateEmploymentIncomeForV2Mutation();

  const createEmploymentIncome = async (
    values: EmploymentIncomeFormDetails,
  ): Promise<
    | { success: true; errorMessage: null; errorType: null }
    | {
        success: false;
        errorMessage: string;
        errorType?: Employment_Income_Error_Type | null | undefined;
      }
  > => {
    const {
      employerName,
      employmentType,
      occupationType,
      isPayingHECS,
      hecsLoanBalance,
      salary,
      commissions,
      allowances,
      bonuses,
      overtime,
      payeeApplicantId,
    } = values;

    if (
      loanApplicationId == null ||
      employerName == null ||
      employmentType == null ||
      occupationType == null ||
      occupationType.value == null ||
      isPayingHECS == null ||
      (isPayingHECS === IncomeYesNoEnum.Yes && hecsLoanBalance == null) ||
      salary == null ||
      // We only allow one owner of employment income
      payeeApplicantId?.[0] == null
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveEmploymentIncome'),
      };
    }

    const parsedEmploymentType = parseEnumType(
      Employment_Type_Enum,
      employmentType,
    );

    if (parsedEmploymentType == null) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidEmploymentType'),
      };
    }

    const isPayingHECSValue = isPayingHECS === IncomeYesNoEnum.Yes;
    const incomeOwnerId = payeeApplicantId[0];

    const createEmploymentIncomeVariables: CreateEmploymentIncomeForV2MutationVariables =
      {
        input: {
          loan_application_id: loanApplicationId,
          employer: employerName,
          employment_type: parsedEmploymentType,
          occupation_type: occupationType.value,
          is_paying_hecs: isPayingHECSValue,
          hecs_loan_balance: isPayingHECSValue ? hecsLoanBalance : null,
          annual_salary: salary,
          annual_commissions: commissions,
          annual_allowances: allowances,
          annual_bonus: bonuses,
          annual_overtime: overtime,
          applicant_id: incomeOwnerId,
          deductions: transformToDeductionInput(
            values.novatedLeaseMonthlyRepayments,
          ),
          has_voluntary_deductions: values.hasVoluntaryDeductions
            ? values.hasVoluntaryDeductions === IncomeYesNoEnum.Yes
            : null,
          can_stop_voluntary_deductions: values.canStopVoluntaryDeductions
            ? values.canStopVoluntaryDeductions === IncomeYesNoEnum.Yes
            : null,
          income_verification_id: incomeVerificationId,
        },
      };

    const [res] = await safelyCallMutation(createEmploymentIncomeMutation, {
      variables: createEmploymentIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { employmentType, applicantId: payeeApplicantId },
        },
      },
    });

    const { errorMessage, errorType } =
      getEmploymentIncomeMutationResponseError(res);
    if (errorMessage) {
      return {
        success: false,
        errorMessage,
        errorType,
      };
    }

    return {
      success: true,
      errorMessage: null,
      errorType: null,
    };
  };

  return {
    createEmploymentIncome,
    isCreatingEmploymentIncome,
  };
}

export function useUpdateEmploymentIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const [
    updateEmploymentIncomeMutation,
    { loading: isUpdatingEmploymentIncome },
  ] = useUpdateEmploymentIncomeForV2Mutation();

  const updateEmploymentIncome = async (
    values: EmploymentIncomeFormDetails,
  ): Promise<
    | { success: true; errorMessage: null }
    | { success: false; errorMessage: string }
  > => {
    const {
      employerName,
      employmentType,
      occupationType,
      isPayingHECS,
      hecsLoanBalance,
      salary,
      commissions,
      allowances,
      bonuses,
      overtime,
      payeeApplicantId,
    } = values;

    if (
      loanApplicationId == null ||
      employerName == null ||
      employmentType == null ||
      occupationType == null ||
      occupationType.value == null ||
      isPayingHECS == null ||
      (isPayingHECS === IncomeYesNoEnum.Yes && hecsLoanBalance == null) ||
      salary == null ||
      // We only allow one owner of employment income
      payeeApplicantId?.[0] == null
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailUpdateEmploymentIncome'),
      };
    }

    const parsedEmploymentType = parseEnumType(
      Employment_Type_Enum,
      employmentType,
    );

    if (parsedEmploymentType == null) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidEmploymentType'),
      };
    }

    const isPayingHECSValue = isPayingHECS === IncomeYesNoEnum.Yes;
    const incomeOwnerId = payeeApplicantId[0];

    const updateEmploymentIncomeVariables: UpdateEmploymentIncomeForV2MutationVariables =
      {
        input: {
          income_id: incomeId,
          employer: employerName,
          employment_type: parsedEmploymentType,
          occupation_type: occupationType.value,
          is_paying_hecs: isPayingHECSValue,
          hecs_loan_balance: isPayingHECSValue ? hecsLoanBalance : null,
          annual_salary: salary,
          annual_commissions: commissions,
          annual_allowances: allowances,
          annual_bonus: bonuses,
          annual_overtime: overtime,
          applicant_id: incomeOwnerId,
          has_voluntary_deductions: values.hasVoluntaryDeductions
            ? values.hasVoluntaryDeductions === IncomeYesNoEnum.Yes
            : null,
          can_stop_voluntary_deductions: values.canStopVoluntaryDeductions
            ? values.canStopVoluntaryDeductions === IncomeYesNoEnum.Yes
            : null,
        },
      };

    const [res] = await safelyCallMutation(updateEmploymentIncomeMutation, {
      variables: updateEmploymentIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { employmentType, applicantId: payeeApplicantId },
        },
      },
    });

    const { errorMessage } = getEmploymentIncomeMutationResponseError(res);
    if (errorMessage) {
      return {
        success: false,
        errorMessage,
      };
    }

    return {
      success: true,
      errorMessage: null,
    };
  };

  return {
    updateEmploymentIncome,
    isUpdatingEmploymentIncome,
  };
}

export function useGetOptionsForExpectedRentalIncomeQuery() {
  const { data, ...others } = useGetExpectedRentalIncomeFormOptionsQuery({
    context: {
      sentryContext: {
        description: 'Expected rental income form options',
      },
    },
  });

  const frequencyOptions = useMemo(
    () =>
      data?.frequency.map(({ description, frequency }) => ({
        label: description,
        value: frequency,
      })) ?? [],
    [data?.frequency],
  );

  const stateOptions = useMemo(
    () =>
      data?.state.map(({ code }) => ({
        // Both label and name are using code intentionally
        // As we only need the shorthand for states.
        label: code,
        value: code,
      })) ?? [],
    [data?.state],
  );

  return {
    frequencyOptions,
    stateOptions,
    ...others,
  };
}

export function useGetOptionsForRentalIncomeQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, ...others } = useGetRentalIncomeV2FormOptionsQuery({
    variables: {
      loanApplicationId,
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });
  const frequencyOptions = useMemo(
    () =>
      data?.frequency.map(({ description, frequency }) => ({
        label: description,
        value: frequency,
      })) ?? [],
    [data?.frequency],
  );

  const propertyTypeOptions = useMemo(
    () =>
      data?.property_type.map(({ description, type }) => ({
        label: description,
        value: type,
      })) ?? [],
    [data?.property_type],
  );

  const stateOptions = useMemo(
    () =>
      data?.state.map(({ code }) => ({
        // Both label and name are using code intentionally
        // As we only need the shorthand for states.
        label: code,
        value: code,
      })) ?? [],
    [data?.state],
  );

  // Re-assigned just for clarity that streetTypeOptions is needed
  // for Rental Income
  const streetTypeOptions = streetTypeOptionsConst;

  const incomeOwnerOptions = useMemo(
    () =>
      sortApplicants(data?.loan_application_by_pk?.applicants).map(
        ({ id, household_id, latest_full_name }) => ({
          label: latest_full_name || '--',
          value: id,
          household_id,
        }),
      ),
    [data?.loan_application_by_pk?.applicants],
  );

  return {
    frequencyOptions,
    propertyTypeOptions,
    stateOptions,
    streetTypeOptions,
    incomeOwnerOptions,
    ...others,
  };
}

export function useGetExpectedRentalIncomeForForm({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const { data, loading } = useGetExpectedRentalIncomeDetailQuery({
    variables: { incomeId },
    skip: !loanApplicationId || !incomeId,
    fetchPolicy: 'cache-and-network',
    context: {
      sentryContext: {
        loanApplicationId,
        incomeId,
      },
    },
  });

  return {
    initialValues: mapExpectedRentalIncomeInitialValuesForm({
      queriedDetails: data,
    }),
    hasLoanApplicationProperty:
      (data?.income[0]?.loan_application.loan_application_securities.length ??
        0) > 0,
    loading,
  };
}

export function useGetRentalIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const { data, loading } = useGetRentalIncomeDetailForV2Query({
    variables: {
      incomeId,
    },
    skip: !loanApplicationId || !incomeId,
    context: {
      sentryContext: {
        loanApplicationId,
        incomeId,
      },
    },
  });

  return {
    initialValues: mapRentalIncomeInitialValuesForV2Form({
      queriedDetails: data,
    }),
    currentLoanIncomeCount:
      data?.income[0]?.loan_application.incomes.length ?? 0,
    loading,
  };
}

type RentalIncomeReturnedType = Promise<
  | { success: true; errorMessage: null }
  | { success: false; errorMessage: string }
>;

export function useCreateRentalIncomeForV2Form({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const [createRentalIncomeMutation, { loading: isCreatingRentalIncome }] =
    useCreateRentalIncomeForV2Mutation();

  const createExistingRentalIncome = async (
    values: RentalIncomeFormDetails,
  ): RentalIncomeReturnedType => {
    const rentalIncomeType = Rental_Income_Type_Enum.Existing;

    // Map manual input from form schema to variable for mutation
    const isManualInput = values[manualInputFieldNames.isManualInput];
    let addressToSubmit: AppAddressFormat | null = null;
    if (isManualInput) {
      addressToSubmit = {
        isProvidedByUser: true,
        postcode: values[manualInputFieldNames.postcode],
        street: values[manualInputFieldNames.streetName],
        streetNo: values[manualInputFieldNames.streetNo],
        unitNo: values[manualInputFieldNames.unitNo],
        state: values[manualInputFieldNames.state],
        streetType: values[manualInputFieldNames.streetType]?.value,
        suburb: values[manualInputFieldNames.suburb],
      };
    } else {
      addressToSubmit = values.propertyAddress ?? null;
    }

    const {
      propertyOwners,
      propertyType,
      rentalExpenses,
      rentalExpensesFrequency,
      rentalIncome,
      rentalIncomeFrequency,
    } = values;

    if (
      loanApplicationId == null ||
      addressToSubmit == null ||
      propertyOwners == null ||
      // We need owner of this property selected
      propertyOwners.length < 1 ||
      propertyType == null ||
      rentalExpenses == null ||
      rentalExpensesFrequency == null ||
      rentalIncome == null ||
      rentalIncomeFrequency == null
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveRentalIncome'),
      };
    }

    const {
      manualAddressInput: validatedManualAddressInput,
      domainApiAddressInput: validatedDomainApiAddressInput,
    } = parseAddressInputForMutationAction(addressToSubmit);

    if (!validatedDomainApiAddressInput && !validatedManualAddressInput) {
      // If both have no value, return error
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidSelectedAddress'),
      };
    }

    const parsedRentalExpenseFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalExpensesFrequency,
    );
    const parsedRentalIncomeFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalIncomeFrequency,
    );
    const parsedPropertyType = parseEnumType(
      Property_Type_Input_Enum,
      propertyType,
    );

    if (
      !parsedRentalExpenseFrequency ||
      !parsedRentalIncomeFrequency ||
      !parsedPropertyType
    ) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveRentalIncome'),
      };
    }

    const createRentalIncomeVariables: CreateRentalIncomeForV2MutationVariables =
      {
        input: {
          loan_application_id: loanApplicationId,
          domain_api_address_input: validatedDomainApiAddressInput,
          user_provided_property: validatedManualAddressInput,
          expense_amount: rentalExpenses,
          expense_frequency: parsedRentalExpenseFrequency,
          income_amount: rentalIncome,
          income_frequency: parsedRentalIncomeFrequency,
          property_owners: propertyOwners,
          property_type: parsedPropertyType,
          rental_income_type: rentalIncomeType,
        },
      };

    const [res] = await safelyCallMutation(createRentalIncomeMutation, {
      variables: createRentalIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { propertyType, propertyOwners, rentalIncomeType },
        },
      },
    });

    const resErrorMessage = getRentalIncomeMutationResponseErrorMessage(
      res,
      rentalIncomeType,
    );
    if (resErrorMessage) {
      return { success: false, errorMessage: resErrorMessage };
    }

    return { success: true, errorMessage: null };
  };

  const createExpectedRentalIncome = async (
    values: ExpectedRentalIncomeFormDetails,
  ): RentalIncomeReturnedType => {
    const rentalIncomeType = Rental_Income_Type_Enum.Expected;

    const expectedAddressInput = {
      postcode: values[expectedRentalIncomeManualInputFieldNames.postcode],
      state: values[expectedRentalIncomeManualInputFieldNames.state],
      suburb: values[expectedRentalIncomeManualInputFieldNames.suburb],
    };

    const {
      rentalExpenses,
      rentalExpensesFrequency,
      rentalIncome,
      rentalIncomeFrequency,
      estimatedLoanAmount,
    } = values;

    if (
      loanApplicationId == null ||
      rentalExpenses == null ||
      rentalExpensesFrequency == null ||
      rentalIncome == null ||
      rentalIncomeFrequency == null ||
      estimatedLoanAmount == null
    ) {
      // Should not go to this block, since these values are validated with yup in formik, except the loan application ID
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveExpectedRentalIncome'),
      };
    }

    const expectedUserProvidedProperty =
      mapExpectedAddressToExpectedRentalIncomeAddressInput(
        expectedAddressInput,
      );

    if (!expectedUserProvidedProperty) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidSelectedAddress'),
      };
    }

    const parsedRentalExpenseFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalExpensesFrequency,
    );
    const parsedRentalIncomeFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalIncomeFrequency,
    );

    if (!parsedRentalExpenseFrequency || !parsedRentalIncomeFrequency) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveExpectedRentalIncome'),
      };
    }

    const createRentalIncomeVariables: CreateRentalIncomeForV2MutationVariables =
      {
        input: {
          loan_application_id: loanApplicationId,
          expected_user_provided_property: expectedUserProvidedProperty,
          expense_amount: rentalExpenses,
          expense_frequency: parsedRentalExpenseFrequency,
          income_amount: rentalIncome,
          income_frequency: parsedRentalIncomeFrequency,
          rental_income_type: rentalIncomeType,
          estimated_loan_amount: estimatedLoanAmount,
        },
      };

    const [res] = await safelyCallMutation(createRentalIncomeMutation, {
      variables: createRentalIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
        refetchPurchaseYourUnloanScreenQuery({ loanApplicationId }),
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { rentalIncomeType },
        },
      },
    });

    const resErrorMessage = getRentalIncomeMutationResponseErrorMessage(
      res,
      rentalIncomeType,
    );
    if (resErrorMessage) {
      return { success: false, errorMessage: resErrorMessage };
    }

    return { success: true, errorMessage: null };
  };

  return {
    createExistingRentalIncome,
    createExpectedRentalIncome,
    isCreatingRentalIncome,
  };
}

export function useUpdateRentalIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const [updateRentalIncomeMutation, { loading: isUpdatingRentalIncome }] =
    useUpdateRentalIncomeForV2Mutation();

  const updateExistingRentalIncome = async (
    values: RentalIncomeFormDetails,
  ): RentalIncomeReturnedType => {
    const rentalIncomeType = Rental_Income_Type_Enum.Existing;
    // Map manual input from form schema to variable for mutation
    const isManualInput = values[manualInputFieldNames.isManualInput];
    let addressToSubmit: AppAddressFormat | null = null;
    if (isManualInput) {
      addressToSubmit = {
        isProvidedByUser: true,
        postcode: values[manualInputFieldNames.postcode],
        street: values[manualInputFieldNames.streetName],
        streetNo: values[manualInputFieldNames.streetNo],
        unitNo: values[manualInputFieldNames.unitNo],
        state: values[manualInputFieldNames.state],
        streetType: values[manualInputFieldNames.streetType]?.value,
        suburb: values[manualInputFieldNames.suburb],
      };
    } else {
      addressToSubmit = values.propertyAddress ?? null;
    }

    const {
      propertyOwners,
      propertyType,
      rentalExpenses,
      rentalExpensesFrequency,
      rentalIncome,
      rentalIncomeFrequency,
    } = values;

    if (
      loanApplicationId == null ||
      incomeId == null ||
      addressToSubmit == null ||
      propertyOwners == null ||
      // We need owner of this property selected
      propertyOwners.length < 1 ||
      propertyType == null ||
      rentalExpenses == null ||
      rentalExpensesFrequency == null ||
      rentalIncome == null ||
      rentalIncomeFrequency == null
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveRentalIncome'),
      };
    }

    const {
      manualAddressInput: validatedManualAddressInput,
      domainApiAddressInput: validatedDomainApiAddressInput,
    } = parseAddressInputForMutationAction(addressToSubmit);

    if (!validatedDomainApiAddressInput && !validatedManualAddressInput) {
      // If both have no value, return error
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidSelectedAddress'),
      };
    }

    const parsedRentalExpenseFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalExpensesFrequency,
    );
    const parsedRentalIncomeFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalIncomeFrequency,
    );
    const parsedPropertyType = parseEnumType(
      Property_Type_Input_Enum,
      propertyType,
    );

    if (
      !parsedRentalExpenseFrequency ||
      !parsedRentalIncomeFrequency ||
      !parsedPropertyType
    ) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveRentalIncome'),
      };
    }

    const updateRentalIncomeVariables: UpdateRentalIncomeForV2MutationVariables =
      {
        input: {
          income_id: incomeId,
          applicant_ids: propertyOwners,
          domain_api_address_input: validatedDomainApiAddressInput,
          user_provided_property: validatedManualAddressInput,
          rental_expense: rentalExpenses,
          rental_expense_frequency: parsedRentalExpenseFrequency,
          rental_income: rentalIncome,
          rental_income_frequency: parsedRentalIncomeFrequency,
          rental_income_type: rentalIncomeType,
          property_type: parsedPropertyType,
        },
      };

    const [res] = await safelyCallMutation(updateRentalIncomeMutation, {
      variables: updateRentalIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { propertyType, propertyOwners, rentalIncomeType },
        },
      },
    });

    const resErrorMessage = getRentalIncomeMutationResponseErrorMessage(
      res,
      rentalIncomeType,
    );
    if (resErrorMessage) {
      return {
        success: false,
        errorMessage: resErrorMessage,
      };
    }

    return {
      success: true,
      errorMessage: null,
    };
  };

  const updateExpectedRentalIncome = async (
    values: ExpectedRentalIncomeFormDetails,
  ): RentalIncomeReturnedType => {
    const rentalIncomeType = Rental_Income_Type_Enum.Expected;
    const expectedAddressInput = {
      postcode: values[expectedRentalIncomeManualInputFieldNames.postcode],
      state: values[expectedRentalIncomeManualInputFieldNames.state],
      suburb: values[expectedRentalIncomeManualInputFieldNames.suburb],
    };

    const {
      rentalExpenses,
      rentalExpensesFrequency,
      rentalIncome,
      rentalIncomeFrequency,
      estimatedLoanAmount,
    } = values;

    if (
      loanApplicationId == null ||
      rentalExpenses == null ||
      rentalExpensesFrequency == null ||
      rentalIncome == null ||
      rentalIncomeFrequency == null ||
      estimatedLoanAmount == null
    ) {
      // Should not go to this block, since these values are validated with yup in formik, except the loan application ID
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveExpectedRentalIncome'),
      };
    }

    const expectedUserProvidedProperty =
      mapExpectedAddressToExpectedRentalIncomeAddressInput(
        expectedAddressInput,
      );
    if (!expectedUserProvidedProperty) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.InvalidSelectedAddress'),
      };
    }

    const parsedRentalExpenseFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalExpensesFrequency,
    );
    const parsedRentalIncomeFrequency = parseEnumType(
      Frequency_Input_Enum,
      rentalIncomeFrequency,
    );

    if (!parsedRentalExpenseFrequency || !parsedRentalIncomeFrequency) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveExpectedRentalIncome'),
      };
    }

    const updateRentalIncomeVariables: UpdateRentalIncomeForV2MutationVariables =
      {
        input: {
          income_id: incomeId,
          rental_income_type: rentalIncomeType,
          expected_user_provided_property: expectedUserProvidedProperty,
          rental_expense: rentalExpenses,
          rental_expense_frequency: parsedRentalExpenseFrequency,
          rental_income: rentalIncome,
          rental_income_frequency: parsedRentalIncomeFrequency,
          estimated_loan_amount: estimatedLoanAmount,
        },
      };

    const [res] = await safelyCallMutation(updateRentalIncomeMutation, {
      variables: updateRentalIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchPurchaseYourUnloanScreenQuery({ loanApplicationId }),
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { rentalIncomeType },
        },
      },
    });

    const resErrorMessage = getRentalIncomeMutationResponseErrorMessage(
      res,
      rentalIncomeType,
    );
    if (resErrorMessage) {
      return {
        success: false,
        errorMessage: resErrorMessage,
      };
    }

    return {
      success: true,
      errorMessage: null,
    };
  };

  return {
    updateExistingRentalIncome,
    updateExpectedRentalIncome,
    isUpdatingRentalIncome,
  };
}

export function useGetOptionsForAddIncomeQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, ...others } = useGetAddIncomeV2FormOptionsQuery({
    variables: {
      loanApplicationId,
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const occupationTypeOptions = useMemo(
    () =>
      data?.occupation_type.map(({ occupation, label }) => ({
        label,
        value: occupation,
      })) ?? [],
    [data?.occupation_type],
  );

  const employmentTypeOptions = useMemo(
    () =>
      data?.employment_type.map(({ type, description }) => ({
        label: description,
        value: type,
      })) ?? [],
    [data?.employment_type],
  );

  const frequencyOptions = useMemo(
    () =>
      data?.frequency.map(({ description, frequency }) => ({
        label: description,
        value: frequency,
      })) ?? [],
    [data?.frequency],
  );

  const propertyTypeOptions = useMemo(
    () =>
      data?.property_type.map(({ description, type }) => ({
        label: description,
        value: type,
      })) ?? [],
    [data?.property_type],
  );

  const stateOptions = useMemo(
    () =>
      data?.state.map(({ code }) => ({
        // Both label and name are using code intentionally
        // As we only need the shorthand for states.
        label: code,
        value: code,
      })) ?? [],
    [data?.state],
  );

  // Re-assigned just for clarity that streetTypeOptions is needed
  // for Rental Income
  const streetTypeOptions = streetTypeOptionsConst;

  const incomeOwnerOptions = useMemo(
    () =>
      sortApplicants(data?.loan_application_by_pk?.applicants).map(
        ({ id, household_id, latest_full_name }) => ({
          label: latest_full_name || '--',
          value: id,
          household_id,
        }),
      ),
    [data?.loan_application_by_pk?.applicants],
  );

  return {
    occupationTypeOptions,
    employmentTypeOptions,
    incomeOwnerOptions,
    frequencyOptions,
    propertyTypeOptions,
    stateOptions,
    streetTypeOptions,
    ...others,
  };
}

export function useCreateOtherIncomeForV2Form({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const [createOtherIncomeMutation, { loading: isCreatingOtherIncome }] =
    useCreateOtherIncomeForV2Mutation();

  const createOtherIncome = async ({
    values,
    incomeType,
  }: {
    values: GovernmentIncomeFormDetails | DividendIncomeFormDetails;
    incomeType:
      | Income_Type_Enum.GovernmentPayments
      | Income_Type_Enum.ShareDividends;
  }): Promise<
    | { success: true; errorMessage: null }
    | { success: false; errorMessage: string }
  > => {
    const { amount, frequency, payees } = values;

    if (
      amount == null ||
      frequency == null ||
      payees == null ||
      payees.length < 1
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    const parsedIncomeType = parseEnumType(
      Other_Income_Type_Input_Enum,
      incomeType,
    );
    const parsedFrequency = parseEnumType(Frequency_Input_Enum, frequency);
    if (parsedFrequency == null || parsedIncomeType == null) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    const createOtherIncomeVariables: CreateOtherIncomeForV2MutationVariables =
      {
        data: {
          amount,
          frequency: parsedFrequency,
          income_owner_applicant_ids: payees,
          income_type: parsedIncomeType,
          loan_application_id: loanApplicationId,
        },
      };

    const [res] = await safelyCallMutation(createOtherIncomeMutation, {
      variables: createOtherIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { incomeType, applicantIds: payees },
        },
      },
    });

    // No expected error is returned from other income mutations
    if (res == null) {
      // Unknown error
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    return {
      success: true,
      errorMessage: null,
    };
  };

  return {
    createOtherIncome,
    isCreatingOtherIncome,
  };
}

export function useUpdateOtherIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const [updateOtherIncomeMutation, { loading: isUpdatingOtherIncome }] =
    useUpdateOtherIncomeForV2Mutation();

  const updateOtherIncome = async ({
    values,
    incomeType,
  }: {
    values: GovernmentIncomeFormDetails | DividendIncomeFormDetails;
    incomeType:
      | Income_Type_Enum.GovernmentPayments
      | Income_Type_Enum.ShareDividends;
  }): Promise<
    | { success: true; errorMessage: null }
    | { success: false; errorMessage: string }
  > => {
    const { amount, frequency, payees } = values;

    if (
      amount == null ||
      frequency == null ||
      payees == null ||
      payees.length < 1
    ) {
      // Should not go to this block since we already validated the value with yup in formik
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    const parsedIncomeType = parseEnumType(
      Other_Income_Type_Input_Enum,
      incomeType,
    );
    const parsedFrequency = parseEnumType(Frequency_Input_Enum, frequency);
    if (parsedFrequency == null || parsedIncomeType == null) {
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    const updateOtherIncomeVariables: UpdateOtherIncomeForV2MutationVariables =
      {
        data: {
          income_id: incomeId,
          amount,
          frequency: parsedFrequency,
          income_owner_applicant_ids: payees,
        },
      };

    const [res] = await safelyCallMutation(updateOtherIncomeMutation, {
      variables: updateOtherIncomeVariables,
      refetchQueries: [
        refetchYourFinancialsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          input: { incomeType, applicantIds: payees },
        },
      },
    });

    // No expected error is returned from other income mutations
    if (res == null) {
      // Unknown error
      return {
        success: false,
        errorMessage: t('Content.Common.Error.FailSaveOtherIncome'),
      };
    }

    return {
      success: true,
      errorMessage: null,
    };
  };

  return {
    updateOtherIncome,
    isUpdatingOtherIncome,
  };
}

export function useGetOtherIncomeForV2Form({
  loanApplicationId,
  incomeId,
}: {
  loanApplicationId: string;
  incomeId: string;
}) {
  const { data, loading } = useGetOtherIncomeDetailForV2Query({
    variables: {
      incomeId,
    },
    skip: !loanApplicationId || !incomeId,
    context: {
      sentryContext: {
        loanApplicationId,
        incomeId,
      },
    },
  });

  return {
    initialValues: mapOtherIncomeInitialValuesForV2Form({
      queriedDetails: data,
    }),
    currentLoanIncomeCount:
      data?.income_by_pk?.loan_application.incomes.length ?? 0,
    loading,
  };
}

export function useGetOptionsForOtherIncomeQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, ...others } = useGetOtherIncomeV2FormOptionsQuery({
    variables: {
      loanApplicationId,
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });
  const frequencyOptions = useMemo(
    () =>
      data?.frequency.map(({ description, frequency }) => ({
        label: description,
        value: frequency,
      })) ?? [],
    [data?.frequency],
  );

  const incomeOwnerOptions = useMemo(
    () =>
      sortApplicants(data?.loan_application_by_pk?.applicants).map(
        ({ id, household_id, latest_full_name }) => ({
          label: latest_full_name || '--',
          value: id,
          household_id,
        }),
      ),
    [data?.loan_application_by_pk?.applicants],
  );

  return {
    frequencyOptions,
    incomeOwnerOptions,
    ...others,
  };
}
