import { format, parse } from 'date-fns';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { formatWithMask, Masks } from 'react-native-mask-input';

import { DATE_INPUT_FORMAT, safelyFormatDate } from '../../utils/dateHelpers';
import { mergeRefs } from '../../utils/mergeRefs';
import { isWeb } from '../../utils/platformUtils';
import { DateInputForFormikProps, DateInputV2Props } from './DateInputTypes';
import { TextInput } from './TextInput';

const Separator = '/';
const MaskPlaceholder = 'DD/MM/YYYY';
const DateFormat = 'dd/MM/yyyy';

const ValidInputRegex = /^[0-9/]*$/;
const ValidFullDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

export const DateInput = forwardRef<RNTextInput, DateInputV2Props>(
  ({ ...otherProps }, ref) => (
    <TextInput
      ref={ref}
      placeholder={MaskPlaceholder}
      alwaysShowPlaceholderBehindValue
      keyboardType="numeric"
      {...otherProps}
    />
  ),
);

DateInput.displayName = 'DateInputV2';

export function formatDateForDateInputDisplay(
  value: Parameters<typeof safelyFormatDate>[0],
) {
  return safelyFormatDate(value, DateFormat) ?? '';
}

export const DateInputForFormik = forwardRef<
  RNTextInput,
  DateInputForFormikProps
>(
  (
    {
      skipFieldTouchOnChange,
      setFieldTouched,
      setFieldValue,
      value: valueProp,
      onChangeText: USE_ON_CHANGE_INSTEAD_OF_THIS_onChangeText,
      onDateFullyEntered,
      ...otherProps
    },
    ref,
  ) => {
    const localRef = useRef<RNTextInput>(null);

    const [inputValue, setInputValue] = useState<string>(() =>
      formatDateForDateInputDisplay(valueProp),
    );

    // Ccaret correction code are removed for now
    // because of the regression happening after Expo 49 upgrade,
    // where caret does not move to the end after entering
    // character next to a separator.
    // Additional work is also needed to make the formatting does
    // not drop invalid date while user is typing.
    // See https://github.com/unloan/unloan-app/issues/6562
    const caretJumpRef = useRef({
      selection: { start: 0, end: 0 },
      rawUserInput: '',
    });

    const isMounted = useRef(true);
    useEffect(
      () => () => {
        isMounted.current = false;
      },
      [],
    );

    const onChangeText = useCallback(
      async (value: string, selectionStart = 0, selectionEnd = 0) => {
        const { masked } = formatWithMask({
          text: value,
          mask: Masks.DATE_DDMMYYYY,
        });

        // Caret will jump to the end if
        // the value from the onChange event
        // does not match the value set the input.
        // See https://github.com/facebook/react/issues/955#issuecomment-469352730
        // setDidCaretJump(masked !== target.value);
        caretJumpRef.current = {
          rawUserInput: value,
          selection: {
            start: selectionStart,
            end: selectionEnd,
          },
        };

        if (masked.length > MaskPlaceholder.length) {
          return;
        }
        const isValid = ValidInputRegex.test(masked);
        if (!isValid) {
          return;
        }

        // Here, we keep the local state in sync with the input value
        setInputValue(masked);

        // And sync the Date with Formik state. Formik will do the validation.
        const convertedDate = parse(masked, DateFormat, new Date());

        await setFieldValue?.(convertedDate, false);
        if (!skipFieldTouchOnChange) {
          await setFieldTouched?.();
        }

        if (ValidFullDateRegex.test(masked) && onDateFullyEntered) {
          onDateFullyEntered(format(convertedDate, DATE_INPUT_FORMAT));
        }
      },
      [
        onDateFullyEntered,
        setFieldTouched,
        setFieldValue,
        skipFieldTouchOnChange,
      ],
    );

    const onChange = useCallback<NonNullable<DateInputV2Props['onChange']>>(
      async (event) => {
        const target = event.target as unknown as HTMLInputElement;

        await onChangeText(
          target.value,
          target.selectionStart ?? 0,
          target.selectionEnd ?? 0,
        );
      },
      [onChangeText],
    );

    return (
      <DateInput
        maxLength={MaskPlaceholder.length}
        ref={mergeRefs([localRef, ref])}
        {...otherProps}
        value={inputValue}
        onChange={isWeb ? onChange : undefined}
        onChangeText={isWeb ? undefined : onChangeText}
      />
    );
  },
);
DateInputForFormik.displayName = 'DateInputForFormik';

const getSeparatorCount = (text: string) => text.split(Separator).length - 1;
const correctSeparatorPositions = new Set(
  DateFormat.split('')
    .map((char, index) => (char === Separator ? index + 1 : null))
    .filter((x: number | null) => x != null),
);
const stripSeparator = (text: string) =>
  text.replace(new RegExp(Separator, 'g'), '');
const maskSeparatorCount = getSeparatorCount(DateFormat);

export function getCorrectedCaretPosition({
  textFromOnChange,
  textInputValue,
  previousTextInputValue,
  caretPosition,
}: {
  textInputValue: string;
  previousTextInputValue: string;
  textFromOnChange: string;
  caretPosition: number;
}) {
  const textFromOnChangeWithoutSeparator = stripSeparator(textFromOnChange);
  const textInputValueWithoutSeparator = stripSeparator(textInputValue);

  const textFromOnChangeSeparatorCount = getSeparatorCount(textFromOnChange);
  const textInputValueSeparatorCount = getSeparatorCount(textInputValue);
  const previousTextInputValueSeparatorCount = getSeparatorCount(
    previousTextInputValue,
  );
  const didMaskingAddSeparator =
    textInputValueSeparatorCount > textFromOnChangeSeparatorCount;
  const didUserRemoveSeparator =
    previousTextInputValueSeparatorCount > textFromOnChangeSeparatorCount;
  const didUserAddExcessSeparator =
    textFromOnChangeSeparatorCount > maskSeparatorCount;
  const isExcessSeparatorBeforeCorrectSeparator =
    correctSeparatorPositions.has(caretPosition);

  if (
    textFromOnChangeWithoutSeparator.length ===
    textInputValueWithoutSeparator.length
  ) {
    if (didUserAddExcessSeparator) {
      if (isExcessSeparatorBeforeCorrectSeparator) {
        return {
          correctedCaretPosition: caretPosition,
        };
      }
      return {
        correctedCaretPosition: caretPosition - 1,
      };
    }

    if (didMaskingAddSeparator) {
      if (didUserRemoveSeparator) {
        return {
          correctedCaretPosition: caretPosition,
        };
      }
      return {
        correctedCaretPosition: caretPosition + 1,
      };
    }
  }
  return {
    correctedCaretPosition: caretPosition,
  };
}
