import { ComponentProps } from 'react';

import { TestID } from '../../../testID/constants';
import { DataRowGroup } from '../../ui/molecules/DataRow';
import { ActionCard } from '../../ui/organisms/ActionCard';
import { useGetPendingLoanVariationRequest } from '../utils/useGetPendingLoanVariationRequest';

export type Props = {
  cbaAccountId: string;
  onPress?: () => void;
  loading: boolean;
} & Pick<ComponentProps<typeof DataRowGroup>, 'mx'>;

export const LoanVariationStatusCard = ({
  cbaAccountId,
  onPress,
  mx = 0,
  loading = false,
}: Props) => {
  const {
    loading: loanVariationRequestLoading,
    isPendingMyApproval,
    loanVariationRequest,
    otherBorrowersNames,
  } = useGetPendingLoanVariationRequest(cbaAccountId);

  const isLoading = loading || loanVariationRequestLoading;

  // If all requests are approved and the status is 'Pending'
  // that means this request is being processed by Mambu
  // Should wait for main loading state before rendering
  const shouldNotRender = isLoading || !loanVariationRequest;
  if (shouldNotRender) {
    return null;
  }

  if (isPendingMyApproval) {
    return (
      <DataRowGroup
        testID={TestID.LoanVariation.ReduceRepayments.PendingMyApprovalCard}
        mb="s"
        mx={mx}
        mt={0}
        spacer={false}
      >
        <ActionCard
          label={t('Content.HomeLoan.LoanVariation.LoanRepaymentChange')}
          caption={t('Content.HomeLoan.LoanVariation.Pending')}
          alertAction={{
            alertLabel: t('Content.HomeLoan.LoanVariation.ApprovalRequired'),
            alertActionLabel: t(
              'Content.HomeLoan.LoanVariation.ViewAndApprove',
            ),
            onPressAlertAction: onPress,
          }}
        />
      </DataRowGroup>
    );
  }

  return (
    <DataRowGroup
      testID={
        TestID.LoanVariation.ReduceRepayments.PendingCoBorrowerApprovalCard
      }
      mb="s"
      mx={mx}
      mt={0}
      spacer={false}
    >
      <ActionCard
        label={t('Content.HomeLoan.LoanVariation.LoanRepaymentChange')}
        onPress={onPress}
        subCaption={t('Content.HomeLoan.LoanVariation.Pending')}
        loading={isLoading}
        alertAction={
          !otherBorrowersNames
            ? undefined
            : {
                showPendingIcon: true,
                alertLabel: t(
                  'Content.HomeLoan.LoanVariation.WaitingForApprovals',
                  {
                    otherBorrowers: otherBorrowersNames,
                  },
                ),
                alertActionLabel: t(
                  'Content.HomeLoan.LoanVariation.ViewAndApprove',
                ),
              }
        }
      />
    </DataRowGroup>
  );
};
