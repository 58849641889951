import { SecureStore } from './secureStore';

const AUTH_INVITE_CODE = 'unloan-auth-invite-code';

export const getInviteCode = async (): Promise<string | null> => {
  if (!SecureStore.isAvailable) {
    return Promise.resolve(null);
  }
  const inviteCodeId = await SecureStore.getItem(AUTH_INVITE_CODE);
  return inviteCodeId;
};

export const setInviteCode = async (applicantId: string): Promise<void> => {
  if (!SecureStore.isAvailable) {
    return;
  }
  await SecureStore.setItem(AUTH_INVITE_CODE, applicantId);
};

export const clearInviteCode = async (): Promise<void> => {
  if (!SecureStore.isAvailable) {
    return;
  }
  await SecureStore.removeItem(AUTH_INVITE_CODE);
};
