import { forwardRef, useCallback } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { FieldRules } from '../constants/fieldRules';
import {
  InputField,
  Props as InputFieldProps,
} from '../ui/organisms/InputField';

export type Props = Omit<InputFieldProps, 'testID'> & {
  inputTestID?: string;
};

export const PostcodeField = forwardRef<RNTextInput, Props>((props, ref) => {
  const { value, onChangeText, ...otherProps } = props;
  const onChangeValidatedPostcode = useCallback(
    (val: string) => {
      if (FieldRules.postcode.isValid(val)) {
        onChangeText?.(val);
      }
    },
    [onChangeText],
  );
  return (
    <InputField
      ref={ref}
      keyboardType="decimal-pad"
      lineHeight={0}
      maxLength={4}
      value={value}
      onChangeText={onChangeValidatedPostcode}
      {...otherProps}
    />
  );
});
