import { useCallback, useContext } from 'react';

import { TestID } from '../../../testID/constants';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useGetUserDetailsForDeleteAccountQuery } from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { SettingsDrawerNavigationProps } from '../navigation/types';

type DeleteAccountProps = {
  loading?: boolean;
  navigation: SettingsDrawerNavigationProps<Screen.SETTINGS_HOME>;
};

function Loader() {
  return (
    <Box m="s" centered>
      <Spinner testID={TestID.Settings.DeleteUser.Spinner} size="small" />
    </Box>
  );
}

export function DeleteAccount({ loading, navigation }: DeleteAccountProps) {
  const { flags } = useContext(FeatureFlagsContext);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetUserDetailsForDeleteAccountQuery({
    context: {
      sentryContext: {
        description: 'Fetching user data for deleting their account.',
      },
    },
  });

  const user = userData?.me?.[0]?.user;

  const showConfirmationModal = useCallback(
    () =>
      navigation.navigate(
        ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET,
      ),
    [navigation],
  );

  if (userLoading) {
    return <Loader />;
  }

  if (userError) {
    return null;
  }

  if (!flags.ENABLE_DELETE_USER) {
    return null;
  }

  const isUserHavingLoanApps: boolean = (user?.applicants ?? []).length > 0;

  const canUserDeleteAccount: boolean =
    !isUserHavingLoanApps &&
    user?.cba_customer_id === null &&
    user?.identity_profile === null &&
    user?.residential_address_id === null &&
    user?.mailing_address_id === null;

  if (!canUserDeleteAccount) {
    return null;
  }

  return (
    <StyledText
      testID={TestID.Settings.DeleteUser.DeleteUserText}
      mx="m"
      mt="2xs"
      mb="m"
      variant="caption"
      opacity={loading ? 0 : undefined}
    >
      {t('Content.Settings.DeleteAccount.WantToDeleteYourAccount')}{' '}
      <StyledText onPress={showConfirmationModal} fontSize="xs" color="link">
        {t('Content.Settings.DeleteAccount.Delete')}
      </StyledText>
    </StyledText>
  );
}
