import {
  AppMergedLiability,
  compareDeclaredAndDetectedLiabilityOwners,
  LiabilityOwnerCompareResult,
} from '../DebtsWizard/utils/useAllLiabilitiesQuery';
import { ApplicantForYourDebtsFragment } from '../generated/graphql';
import { Props as RowProps, Row } from '../ui/molecules/Row';
import { isLast } from '../utils/arrayHelpers';
import { joinListOfString } from '../utils/stringHelpers';
import { buildLiabilityTestId } from './LiabilityPanel';
import { LiabilityRowProps } from './LiabilityRow';
import { YourLiabilityRow } from './YourLiabilityRow';

type Props = Omit<RowProps, 'label'> & {
  liabilities: Array<AppMergedLiability>;
  onLiabilityPress: (aml: AppMergedLiability) => void;
  groupTestId: string;
  currentApplicant: ApplicantForYourDebtsFragment;
  forV2?: boolean;
  noLiabilitiesLabel?: string;
};

export function LiabilityList({
  liabilities,
  onLiabilityPress,
  groupTestId,
  currentApplicant,
  forV2,
  noLiabilitiesLabel = 'No Debts or Liabilities',
  ...otherProps
}: Props) {
  if (!liabilities.length) {
    return (
      <Row
        centered
        label={noLiabilitiesLabel}
        labelFontSize="xs"
        labelColor="secondaryContent"
      />
    );
  }

  return (
    <>
      {liabilities.map((aml, i) => {
        const { key, ...rowProps } = transformLiabilityToLiabilityRowProps(
          aml,
          currentApplicant,
        );
        const last = isLast(liabilities, i);
        const onPress = () => {
          onLiabilityPress(aml);
        };
        return (
          <YourLiabilityRow
            testID={buildLiabilityTestId(
              groupTestId,
              aml.liabilityType,
              aml.dynamite_account_number,
            )}
            key={key}
            {...otherProps}
            {...rowProps}
            onPress={onPress}
            last={last}
            forV2={forV2}
          />
        );
      })}
    </>
  );
}

function transformLiabilityToLiabilityRowProps(
  liability: AppMergedLiability,
  currentApplicant: ApplicantForYourDebtsFragment,
): Omit<LiabilityRowProps, 'onPress'> {
  const result = compareDeclaredAndDetectedLiabilityOwners({
    aml: liability,
    currentApplicant,
  });

  const showAlertIcon =
    result === LiabilityOwnerCompareResult.LiabilityHasUnlistedDeclaredOwner ||
    liability.current_liability[0]?.is_draft ||
    liability.hasMissingDetails;

  const error = showAlertIcon
    ? (t('Content.YourDebts.CompleteMissingLoanDetails') as string)
    : null;

  return {
    key: liability.id,
    institutionName: liability.dynamite_institution_name,
    liabilityType: liability.liabilityType,
    forRefinancing: liability.dynamite_for_refinancing || false,
    balance: liability.dynamite_balance,
    limit: liability.dynamite_limit,
    owners: liability.ownerFullNames.length
      ? joinListOfString(liability.ownerFullNames)
      : '--',
    hasArrow: true,
    showAlertIcon,
    error,
    isFlaggedIncorrect: liability.dynamite_flagged_incorrect != null,
  };
}
