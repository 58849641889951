import { useCallback, useContext, useState } from 'react';
import * as React from 'react';

import { useUser } from '../../Auth/hooks';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useMfaLoginInitMutation } from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { Screen } from '../../navigation/types/screens';
import { AccountDetailsSection } from '../../PersonalDetails/components/AccountDetailsSection';
import { AddressSection } from '../../PersonalDetails/components/AddressSection';
import { UserNamesSection } from '../../PersonalDetails/components/UserNamesSection';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { SettingsScreenProps } from '../navigation/types';
import { getFormattedAddress } from '../utils/formatAddress';

type Props = SettingsScreenProps<Screen.SETTINGS_PERSONAL>;

const PersonalDetailsBase: React.FC<Props> = ({ navigation }) => {
  const { user, loading: userLoading, error: userError } = useUser();

  // Show different loading states for email and mobile change
  const [isEmailChangeInProgress, setIsEmailChangeInProgress] =
    useState<boolean>(false);
  const [isMobileChangeInProgress, setIsMobileChangeInProgress] =
    useState<boolean>(false);

  const [showNetworkError, setShowNetworkError] = useState<boolean>(false);

  const { flags } = useContext(FeatureFlagsContext);

  const [mfaLoginInit, { error: mfaLoginInitError }] =
    useMfaLoginInitMutation();

  const initiateEmailOrMobileChange = useCallback(
    async ({ sentryDescription }: { sentryDescription: string }) => {
      const [response] = await safelyCallMutation(mfaLoginInit, {
        context: {
          sentryContext: {
            userId: user?.id,
            description: sentryDescription,
          },
        },
      });
      const auth0MfaSessionId =
        response?.data?.mfa_login_init.auth0_mfa_session_id;

      const auth0MfaError = response?.data?.mfa_login_init?.error_type;
      if (auth0MfaError) {
        return undefined;
      }

      return auth0MfaSessionId;
    },
    [user, mfaLoginInit],
  );

  const onEmailChange = useCallback(async () => {
    setIsEmailChangeInProgress(true);
    const auth0MfaSessionId = await initiateEmailOrMobileChange({
      sentryDescription: 'Email Change Request - Initiating MFA Login',
    });
    setIsEmailChangeInProgress(false);
    if (!auth0MfaSessionId) {
      setShowNetworkError(true);
      return;
    }
    navigation.navigate(Screen.PERSONAL_DETAILS_MODAL, {
      screen: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY,
      params: {
        auth0MfaSessionId,
      },
    });
  }, [
    initiateEmailOrMobileChange,
    setShowNetworkError,
    setIsEmailChangeInProgress,
    navigation,
  ]);

  const onMobileChange = useCallback(async () => {
    setIsMobileChangeInProgress(true);
    const auth0MfaSessionId = await initiateEmailOrMobileChange({
      sentryDescription: 'Mobile Change Request - Initiating MFA Login',
    });
    setIsMobileChangeInProgress(false);
    if (!auth0MfaSessionId) {
      setShowNetworkError(true);
      return;
    }
    navigation.navigate(Screen.PERSONAL_DETAILS_MODAL, {
      screen: Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY,
      params: {
        auth0MfaSessionId,
      },
    });
  }, [
    initiateEmailOrMobileChange,
    setShowNetworkError,
    setIsMobileChangeInProgress,
    navigation,
  ]);

  const onLegalNameChange = useCallback(() => {
    if (user?.identity_profile?.full_name) {
      navigation.navigate(Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE);
    }
  }, [navigation, user]);

  const onUpdateAddressModal = useCallback(() => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.UPDATE_ADDRESS_MODAL,
    });
  }, [navigation]);

  const hasNetworkErrored = !!mfaLoginInitError || showNetworkError;

  const loading = userLoading;

  return (
    <UpliftScreenContainer onPressBack={navigation.goBack}>
      <HomeHeader title={t('Content.Settings.PersonalDetails.Title')} />

      {userError ? (
        <ErrorRow
          message={t(
            'Content.Common.Error.ErrorOccurredWhileRetrievingUsersProfile',
          )}
        />
      ) : undefined}

      {hasNetworkErrored ? (
        <ErrorRow message={t('Content.PersonalDetails.NetworkError')} />
      ) : undefined}

      <AccountDetailsSection
        featureFlagLoading={loading}
        email={user?.email ?? ''}
        mobile={user?.mobile ?? ''}
        emailChangeInProgress={isEmailChangeInProgress}
        mobileChangeInProgress={isMobileChangeInProgress}
        onEmailChange={flags.ENABLE_UPDATE_EMAIL ? onEmailChange : undefined}
        onMobileChange={
          flags.ENABLE_UPDATE_MOBILE_NUMBER ? onMobileChange : undefined
        }
      />
      {user?.cba_customer_id ? (
        <>
          <UserNamesSection
            loading={loading}
            fullName={user.identity_profile?.full_name ?? ''}
            onLegalNameChange={
              flags.ENABLE_UPDATE_LEGAL_NAME ? onLegalNameChange : undefined
            }
          />
          <AddressSection
            loading={loading}
            residentialAddress={getFormattedAddress(user.residential_address)}
            mailingAddress={
              user.residential_address?.full_display_address !==
              user.mailing_address?.full_display_address
                ? getFormattedAddress(user.mailing_address)
                : t('Content.PersonalDetails.Address.SameAddress')
            }
            onAddressChange={
              flags.ENABLE_UPDATE_PHYSICAL_ADDRESS
                ? onUpdateAddressModal
                : undefined
            }
          />
        </>
      ) : null}
    </UpliftScreenContainer>
  );
};

export const WithAuthenticationPersonalDetails =
  withAuthenticationRequired(PersonalDetailsBase);
