import {
  Account_Permission_Type_Enum,
  Account_Permission_Type_Input_Enum,
} from '../../generated/graphql';
import { assertUnreachable, notNil } from '../../utils/typesHelpers';
import {
  AccountPermission,
  AccountPermissionType,
  PermissionsToUpdate,
} from '../types';

const TextPrefix = 'Content.AccountPermissions.ConfirmChanges.Disclaimer';
export const messageMap = {
  AutoPay: {
    OneToApprove: t(`${TextPrefix}.AutoPay.OneToApprove`),
    AllToApprove: t(`${TextPrefix}.AutoPay.AllToApprove`),
  },
  Withdrawal: {
    OneToApprove: t(`${TextPrefix}.Withdrawal.OneToApprove`),
    AllToApprove: t(`${TextPrefix}.Withdrawal.AllToApprove`),
  },
  Both: {
    OneToApprove: t(`${TextPrefix}.Both.OneToApprove`),
    AllToApprove: t(`${TextPrefix}.Both.AllToApprove`),
    AutoPayAllToApproveAndWithdrawalOneToApprove: t(
      `${TextPrefix}.Both.AutoPayAllToApproveAndWithdrawalOneToApprove`,
    ),
    AutoPayOneToApproveAndWithdrawalAllToApprove: t(
      `${TextPrefix}.Both.AutoPayOneToApproveAndWithdrawalAllToApprove`,
    ),
  },
};

export const getMessageToDisplay = (permissions: PermissionsToUpdate) => {
  if (permissions.length < 1) return ''; // This should not happen
  const { Autopay, Withdrawal } = AccountPermissionType;

  // Updating both permissions
  if (permissions.length > 1) {
    const areBothOneToApprove = permissions.every((a) => !a.require_approval);
    if (areBothOneToApprove) return messageMap.Both.OneToApprove;

    const areBothAllToApprove = permissions.every((p) => p.require_approval);
    if (areBothAllToApprove) return messageMap.Both.AllToApprove;

    const isAutoPayAllToApproveAndWithdrawalOneToApprove =
      permissions.some((p) => p.type === Autopay && p.require_approval) &&
      permissions.some((p) => p.type === Withdrawal && !p.require_approval);
    if (isAutoPayAllToApproveAndWithdrawalOneToApprove) {
      return messageMap.Both.AutoPayAllToApproveAndWithdrawalOneToApprove;
    }

    const isAutoPayOneToApproveAndWithdrawalAllToApprove =
      permissions.some((p) => p.type === Autopay && !p.require_approval) &&
      permissions.some((p) => p.type === Withdrawal && p.require_approval);
    if (isAutoPayOneToApproveAndWithdrawalAllToApprove) {
      return messageMap.Both.AutoPayOneToApproveAndWithdrawalAllToApprove;
    }

    return '';
  }

  // Updating one permission
  const permission = notNil(
    permissions[0],
    'Permissions array should not be empty at this point',
  );
  switch (permission.type) {
    case Autopay: {
      const allToApprove = permission.require_approval;
      return allToApprove
        ? messageMap.AutoPay.AllToApprove
        : messageMap.AutoPay.OneToApprove;
    }
    case Withdrawal: {
      const allToApprove = permission.require_approval;
      return allToApprove
        ? messageMap.Withdrawal.AllToApprove
        : messageMap.Withdrawal.OneToApprove;
    }
    default: {
      assertUnreachable(permission.type);
      return '';
    }
  }
};

const permissionsMap: Record<
  Account_Permission_Type_Enum,
  Account_Permission_Type_Input_Enum
> = {
  [Account_Permission_Type_Enum.Autopay]:
    Account_Permission_Type_Input_Enum.Autopay,
  [Account_Permission_Type_Enum.Withdrawal]:
    Account_Permission_Type_Input_Enum.Withdrawal,
};

export const formatPermissions = (
  permissions: AccountPermission[],
): PermissionsToUpdate =>
  permissions.map((p) => ({
    type: permissionsMap[p.type],
    require_approval: p.require_approvals,
  }));
