import { SxProp, Text, View } from 'dripsy';

import { useTheme } from '../../ui/theme';
import { formatCurrency } from '../../utils/currencyHelpers';

type YourUnloanSavingsBannerProps = {
  savingsAmount: number;
  sx?: SxProp;
};

export function YourUnloanSavingsBanner({
  savingsAmount,
  sx = {},
}: YourUnloanSavingsBannerProps) {
  const theme = useTheme();
  return (
    <View
      sx={{
        padding: '$16',
        borderRadius: 12,
        backgroundColor: theme.colors.shapeBg,
        ...sx,
      }}
    >
      <Text
        variant="subHeading"
        style={{
          fontWeight: '600',
          color: '$labelsPrimary',
          textAlign: 'center',
        }}
      >
        {t('Content.YourUnloan.SavingsSection.YouCouldSaveUpTo')}
      </Text>
      <Text
        variant="xlHeader"
        sx={{
          backgroundImage:
            'linear-gradient(110.24deg, #F5C05A 24.55%, #F4B965 24.55%, #EB88B1 57.59%, #B965E4 94.55%, #61A6F9 132.07%)',
          backgroundClip: 'text',
          color: 'transparent',
          textAlign: 'center',
          py: '$4',
        }}
      >
        {formatCurrency(savingsAmount)}
      </Text>
      <Text variant="sSubHeading" sx={{ textAlign: 'center' }}>
        {t('Content.YourUnloan.SavingsSection.OverTheLifeOfTheLoan')}
      </Text>
    </View>
  );
}
