import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import {
  BizaAccount,
  DataSharingEventType,
} from '../../DataSharingConfirmation/types';
import { getBizaAccount } from '../../DataSharingConfirmation/utils/biza';
import {
  GetBizaAccountByIdQuery,
  useGetBizaAccountByIdQuery,
} from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import {
  useCancelDataSharing,
  useDeclineDataSharing,
  useDisableDataSharing,
} from '../../Settings/utils/useDataSharing';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION>;

const Error: React.FC = () => (
  <ErrorRow
    testID={TestID.ActionSheet.DataSharingConfirmation.Error}
    message="Error Loading Account Details"
  />
);

const Message: React.FC<{ texts: string[] }> = ({ texts }) => (
  <>
    <Separator spacer />
    <ActionSheet.MessageText
      testID={TestID.ActionSheet.DataSharingConfirmation.Message}
    >
      {texts.map((text) => text)}
    </ActionSheet.MessageText>
  </>
);

const TitleByEventType = {
  [DataSharingEventType.Disable]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.ConfirmStopDataSharing',
  ),
  [DataSharingEventType.Cancel]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.CancelDataSharingRequest',
  ),
  [DataSharingEventType.Decline]: t(
    'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.DeclineDataSharingRequest',
  ),
};

const getDisplayTextByEventType = (
  dataSharingEventType: DataSharingEventType,
  accountDetails: { accountName: string; accountNumber: string },
): string[] => {
  if (dataSharingEventType === DataSharingEventType.Disable) {
    return [
      t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.DisableDataText',
      ),
      t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.DisableDataSubText',
      ),
    ];
  }
  const { accountName, accountNumber } = accountDetails;
  return [
    t(
      'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.CancelOrDeclineDataText',
      { accountName, accountNumber },
    ),
    t(
      'Content.DataSharingPreferences.MenuItems.JointAccounts.ActionSheet.CancelOrDeclineDataSubText',
    ),
  ];
};

const Content: React.FC<{
  error: boolean;
  mutationError: boolean;
  loading: boolean;
  dataSharingEventType: DataSharingEventType;
  data?: GetBizaAccountByIdQuery;
}> = ({ error, mutationError, loading, dataSharingEventType, data }) => {
  if (error) return <Error />;

  if (loading)
    return (
      <Spinner testID={TestID.ActionSheet.DataSharingConfirmation.Spinner} />
    );

  if (!data) return <Error />;

  const account: BizaAccount | null = getBizaAccount(data);

  if (!account) return <Error />;

  const {
    sharing_status: sharingStatus,
    name: accountName,
    masked_account_number: accountNumber,
  } = account;

  if (!(sharingStatus && accountName && accountNumber)) return <Error />;

  const texts: string[] = getDisplayTextByEventType(dataSharingEventType, {
    accountName,
    accountNumber,
  });

  return (
    <>
      <Message texts={texts} />
      {mutationError ? (
        <>
          <Separator spacer />
          <ErrorRow
            testID={TestID.ActionSheet.DataSharingConfirmation.MutationError}
            message="Error performing that action."
          />
        </>
      ) : null}
    </>
  );
};

const DataSharingConfirmationActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { accountId, dataSharingEventType, requestId } = route.params;

  const { data, loading, error } = useGetBizaAccountByIdQuery({
    variables: { accountId },
    context: {
      sentryContext: {
        bizaAccountId: accountId,
        description: 'Fetching Biza Account By Id.',
      },
    },
  });

  const {
    callDataSharingMutation: disableMutation,
    dataSharingError: disableError,
    dataSharingLoading: disableLoading,
    sharingComplete: disableComplete,
  } = useDisableDataSharing(accountId);

  const {
    callDataSharingMutation: declineMutation,
    dataSharingError: declineError,
    dataSharingLoading: declineLoading,
    sharingComplete: declineComplete,
  } = useDeclineDataSharing(accountId, requestId);

  const {
    callDataSharingMutation: cancelMutation,
    dataSharingError: cancelError,
    dataSharingLoading: cancelLoading,
    sharingComplete: cancelComplete,
  } = useCancelDataSharing(accountId, requestId);

  const onPress = useCallback(async () => {
    const mutation = {
      [DataSharingEventType.Disable]: disableMutation,
      [DataSharingEventType.Decline]: declineMutation,
      [DataSharingEventType.Cancel]: cancelMutation,
    };
    mutation[dataSharingEventType]();
  }, [disableMutation, declineMutation, cancelMutation, dataSharingEventType]);

  useEffect(() => {
    if (disableComplete || declineComplete || cancelComplete) {
      navigation.replace(
        ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE,
        {
          dataSharingEventType,
        },
      );
    }
  }, [
    navigation,
    disableComplete,
    declineComplete,
    cancelComplete,
    dataSharingEventType,
  ]);

  const mutationError = disableError || declineError || cancelError;

  const isLoading = disableLoading || declineLoading || cancelLoading;

  const closeModal = useCallback(() => navigation.goBack(), [navigation]);

  return (
    <ActionSheet
      closeModal={closeModal}
      preventCloseModal={isLoading}
      title={TitleByEventType[dataSharingEventType]}
      message={
        <Content
          error={!!error}
          mutationError={!!mutationError}
          loading={loading}
          data={data}
          dataSharingEventType={dataSharingEventType}
        />
      }
      actionList={[
        {
          label: t('Content.DataSharingPreferences.Confirm'),
          onPress,
          secondary: true,
          disabled: loading || isLoading || !!error || !!mutationError,
          showSpinner: isLoading,
          color: 'error',
        },
        {
          label: t('Content.DataSharingPreferences.Cancel'),
          tertiary: true,
          disabled: isLoading,
          onPress: navigation.goBack,
        },
      ]}
    />
  );
};

export default DataSharingConfirmationActionSheet;
