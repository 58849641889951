import type { TextInputV2Props as BaseTextInputV2Props } from '@unloan/common-ui';
import { TextInput as BaseTextInput } from '@unloan/common-ui';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import { useDebouncedSendAppInteractionEventToGTM } from '../../utils/hooks/useDebouncedSendAppInteractionEventToGTM';

export type TextInputV2Props = BaseTextInputV2Props & {
  interactionKey: ApplicationInteractionEventKeyType;
};

function TextInputWithGtm(
  { interactionKey, onChangeText, ...otherProps }: TextInputV2Props,
  ref: ForwardedRef<RNTextInput>,
) {
  const debouncedSendAppInteractionEventToGTM =
    useDebouncedSendAppInteractionEventToGTM(
      GTMAppInteractionEventDescription.TextInputChanged,
      interactionKey,
    );
  const onChangeTextWithGTM = useMemo(
    () => (text: string) => {
      onChangeText?.(text);
      if (interactionKey) {
        debouncedSendAppInteractionEventToGTM();
      }
    },
    [interactionKey, onChangeText, debouncedSendAppInteractionEventToGTM],
  );
  return (
    <BaseTextInput
      ref={ref}
      id={interactionKey}
      onChangeText={onChangeTextWithGTM}
      {...otherProps}
    />
  );
}

export const TextInput = forwardRef<RNTextInput, TextInputV2Props>(
  TextInputWithGtm,
);
