import { useDripsyTheme } from 'dripsy';

import { Box, BoxProps } from '../../ui/atoms/Box';

export type TooltipContainerProps = {
  displayBoarder?: boolean;
} & BoxProps;

export const TooltipContainer = ({
  displayBoarder = false,
  children,
  testID,
  style,
  ...props
}: TooltipContainerProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Box row width="100%" testID={testID}>
      <Box
        borderWidth={2}
        borderRadius="card"
        borderColor={displayBoarder ? 'border' : 'transparent'}
        style={[
          {
            paddingVertical: theme.space.$12,
            paddingHorizontal: displayBoarder ? theme.space.$12 : 0,
          },
          style,
        ]}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
};
