import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useDripsyTheme } from 'dripsy';
import { printToFileAsync } from 'expo-print';
import { shareAsync } from 'expo-sharing';
import { useCallback } from 'react';
import WebView from 'react-native-webview';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import {
  AustralianTimezones,
  DATE_INPUT_FORMAT,
} from '../../utils/dateHelpers';
import { useHeaderButton } from '../../utils/hooks/useHeaderButton';
import usePrintIFrame from '../../utils/hooks/usePrintIFrame';
import { isWeb as defaultIsWeb } from '../../utils/platformUtils';
import { StatementsV2ModalScreenProps } from '../navigation/types';
import { useGeneratedStatementQuery } from '../remoteData';

type Props =
  StatementsV2ModalScreenProps<Screen.STATEMENTS_PREVIEW_V2_MODAL> & {
    isWeb?: boolean;
  };

export const STATEMENTS_IFRAME_ID = 'statements-iframe';

export function StatementPreviewV2({
  navigation,
  route,
  isWeb = defaultIsWeb,
}: Props) {
  const { theme } = useDripsyTheme();
  const { html, loading, error } = useGeneratedStatementQuery({
    cbaAccountId: route.params.cbaAccountId,
    startDate: format(
      utcToZonedTime(route.params.startDate, AustralianTimezones.SYDNEY),
      DATE_INPUT_FORMAT,
    ),
    endDate: format(
      utcToZonedTime(route.params.endDate, AustralianTimezones.SYDNEY),
      DATE_INPUT_FORMAT,
    ),
  });

  const printIFrame = usePrintIFrame(STATEMENTS_IFRAME_ID);

  const printToFile = useCallback(async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    const { uri } = await printToFileAsync({ html });

    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  }, [html]);

  const print = isWeb ? printIFrame : printToFile;

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useHeaderButton(navigation, {
    iconName: 'share',
    iconSize: 'm',
    iconFamily: 'svg',
    position: 'left',
    onPress: print,
    hidden: !html,
    'aria-label': t('Content.Accessibility.Statements.PrintButton'),
  });

  return (
    <ModalScreenContainer
      onClose={onClose}
      hideBackButton
      scrollable
      contentFullScreen
      contentXPadding="$20"
    >
      <ScreenLoadingContainer loading={loading}>
        <>
          {error ? (
            <ErrorRow
              my="m"
              message={t('Content.Common.Error.FailFetchStatement')}
            />
          ) : null}
          {isWeb ? (
            <iframe
              style={{
                marginTop: -theme.space.$32,
                border: 'none',
              }}
              title={STATEMENTS_IFRAME_ID}
              id={STATEMENTS_IFRAME_ID}
              height="100%"
              width="100%"
              srcDoc={html}
            />
          ) : (
            <WebView
              testID={TestID.Statements.StatementPreviewMobile}
              height="100%"
              width="100%"
              source={{ html }}
            />
          )}
        </>
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
}
