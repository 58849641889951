import { ReactNode } from 'react';
import Collapsible from 'react-native-collapsible';

import { useFormCollapsible } from '../../utils/hooks/useFormCollapsible';
import { getMultiselectDisplayValue } from '../utils/getMultiselectDisplayValue';
import {
  MultiSelectionRowGroup,
  Props as SelectionRowGroupProps,
  SingleSelectionRowGroup,
} from './SelectionRowGroup';

type Props = SelectionRowGroupProps<string> & {
  collapsed?: boolean;
  name?: string;
  isLoading?: boolean;
  disableAutoClose?: boolean;
  label?: string;
  /** Append unit suffix when user select more than 2 options (only applicable when `isMultiselect` set to `true`) */
  unit?: string;
  onPress?: () => void;
  children: (props: {
    isPickerOpened: boolean;
    selectedLabel: string;
    onPress: () => void;
  }) => ReactNode;
  onSelectionItemPress?: (value: string) => void;
};

export function CollapsibleSelection({
  collapsed: collapsedProp,
  name,
  onPress,
  options,
  last: lastProp,
  children,
  ...otherProps
}: Props) {
  const { isPickerOpened, onPressWrapped, setPickerOpened } =
    useFormCollapsible({
      collapsedProp,
      name,
      onPress,
      optionsLength: options.length,
    });
  return (
    <>
      {children({
        isPickerOpened,
        selectedLabel: getDisplayValue({ options, ...otherProps }),
        onPress: onPressWrapped,
      })}
      <Collapsible collapsed={!isPickerOpened}>
        {otherProps.isMultiselect ? (
          <MultiSelectionRowGroup
            options={options}
            last={lastProp}
            {...otherProps}
          />
        ) : (
          <SingleSelectionRowGroup
            {...otherProps}
            options={options}
            last={lastProp}
            setFieldValue={(v) => {
              otherProps.onSelectionItemPress?.(v);
              /**
               * If the collapsible selection is not for a multi select input,
               * close the picker after the user selects an option.
               */
              setPickerOpened(false);
              otherProps.setFieldValue?.(v);
            }}
          />
        )}
      </Collapsible>
    </>
  );
}

function getDisplayValue(
  params: Pick<Props, 'isMultiselect' | 'options' | 'unit' | 'value'>,
) {
  if (params.isMultiselect) {
    const selectedValues = new Set(params.value);
    const selectedOptions = params.options.filter((item) =>
      selectedValues.has(item.value),
    );
    return (
      getMultiselectDisplayValue(selectedOptions, params.options, {
        unit: params.unit,
      }) || ''
    );
  }

  const selectedOption = params.options?.find(
    (item) => item.value === params.value,
  );
  return selectedOption?.label || '';
}
