import { gql } from '@apollo/client';
import * as FileSystem from 'expo-file-system';
import { useCallback, useState } from 'react';

import {
  Commence_Income_Supporting_Document_Upload_Error_Type,
  Commence_Liability_Supporting_Document_Upload_Error_Type,
  Commence_Security_Supporting_Document_Upload_Error_Type,
  Loan_Application_Task_Type,
  refetchGetLoanApplicationTasksQuery,
  useCommenceIncomeSupportingDocumentUploadMutation,
  useCommenceLiabilitySupportingDocumentUploadMutation,
  useCommenceSecuritySupportingDocumentUploadMutation,
  useFinaliseDocumentUploadsMutation,
} from '../../generated/graphql';
import { captureException } from '../../sentry';
import { normalizeError } from '../normalizeError';
import { isWeb } from '../platformUtils';
import { assertUnreachable } from '../typesHelpers';
import { safelyCallMutation } from './errorUtils';
import { SelectedDocument } from './useDocumentPicker';

export const USE_SUPPORTING_DOCUMENT_UPLOAD_GQL_OPERATIONS = gql`
  mutation CommenceIncomeSupportingDocumentUpload(
    $incomeId: uuid!
    $lastModifiedAt: timestamptz!
    $originalName: String!
    $sizeInBytes: Int!
  ) {
    commence_income_supporting_document_upload(
      income_id: $incomeId
      last_modified_at: $lastModifiedAt
      original_name: $originalName
      size_in_bytes: $sizeInBytes
    ) {
      file_name
      file_upload_id
      upload_url
      error_type
    }
  }

  mutation CommenceSecuritySupportingDocumentUpload(
    $loanApplicationSecurityId: uuid!
    $lastModifiedAt: timestamptz!
    $originalName: String!
    $sizeInBytes: Int!
  ) {
    commence_security_supporting_document_upload(
      loan_application_security_id: $loanApplicationSecurityId
      last_modified_at: $lastModifiedAt
      original_name: $originalName
      size_in_bytes: $sizeInBytes
    ) {
      file_name
      file_upload_id
      upload_url
      error_type
    }
  }

  mutation CommenceLiabilitySupportingDocumentUpload(
    $mergedLiabilityId: String!
    $loanApplicationTaskType: loan_application_task_type!
    $lastModifiedAt: timestamptz!
    $originalName: String!
    $sizeInBytes: Int!
  ) {
    commence_liability_supporting_document_upload(
      merged_liability_id: $mergedLiabilityId
      loan_application_task_type: $loanApplicationTaskType
      last_modified_at: $lastModifiedAt
      original_name: $originalName
      size_in_bytes: $sizeInBytes
    ) {
      file_name
      file_upload_id
      upload_url
      merged_liability_id
      error_type
    }
  }

  mutation FinaliseDocumentUploads($fileUploadId: uuid!) {
    finalise_document_uploads(file_upload_id: $fileUploadId) {
      observable_task_id
    }
  }
`;

type UploadIncomeSupportingDocumentUploadVariables = {
  document: SelectedDocument;
  incomeId: string;
};

type UploadLiabilitySupportingDocumentUploadVariables = {
  document: SelectedDocument;
  mergedLiabilityId: string;
  loanApplicationTaskType?: Loan_Application_Task_Type;
};

type UploadError =
  | 'NO_UPLOAD_ID_OR_URL'
  | 'NO_FILE_ON_WEB'
  | 'UPLOAD_FAILED'
  | 'FINALISE_UPLOAD_FAILED'
  | 'FILE_SIZE_EXCEEDED_LIMIT'
  | 'FAILED_TO_COMMENCE_UPLOAD';
type State = {
  isUploading: boolean;
  error?: UploadError | null;
  rawError?: Error | null;
};

export function getErrorMessage(error: State['error']) {
  if (!error) {
    return null;
  }
  switch (error) {
    case 'UPLOAD_FAILED':
    case 'FAILED_TO_COMMENCE_UPLOAD':
      return t('Content.IncomeSupportingDocumentUpload.Error.UploadFailed');
    case 'FINALISE_UPLOAD_FAILED':
      return t(
        'Content.IncomeSupportingDocumentUpload.Error.FinaliseUploadFailed',
      );
    case 'NO_UPLOAD_ID_OR_URL':
      return t('Content.IncomeSupportingDocumentUpload.Error.NoUploadIdOrUrl');
    case 'NO_FILE_ON_WEB':
      return t('Content.IncomeSupportingDocumentUpload.Error.NoFileOnWeb');
    case 'FILE_SIZE_EXCEEDED_LIMIT':
      return t(
        'Content.IncomeSupportingDocumentUpload.Error.FileSizeExceededLimit',
      );
    default:
      assertUnreachable(error);
      return t('Content.IncomeSupportingDocumentUpload.Error.Unexpected');
  }
}

// To do document upload for income or security supporting document,
// the app need to do 3 thing:
// 1. Call commence_x_supporting_document_upload mutation with the file metadata.
// 2. Upload file to upload URL returned by step #1.
// 3. Call finalise_document_uploads mutation after step #2 is done.
// Please refer to https://github.com/unloan/unloan-app/issues/3520
// for overview of the implementation.
function useTaskSupportingDocumentUpload(loanApplicationId = '') {
  const [state, setState] = useState<State>({
    isUploading: false,
    error: null,
    rawError: null,
  });

  const [finaliseDocumentUploads] = useFinaliseDocumentUploadsMutation({
    refetchQueries: [
      refetchGetLoanApplicationTasksQuery({ loanApplicationId }),
    ],
    awaitRefetchQueries: true,
  });

  const setUploadingFalseWithError = useCallback(
    (error: UploadError, rawError: State['rawError'] = null) =>
      setState({ isUploading: false, error, rawError }),
    [],
  );

  const handleCommenceDocUploadMutError = useCallback(
    (context: ObjectOf<unknown>, mutationError: Error) => {
      captureException(
        'An error occurred when calling commence mutation',
        context,
        normalizeError(mutationError),
      );
      setState({ isUploading: false, error: 'FAILED_TO_COMMENCE_UPLOAD' });
    },
    [],
  );

  const handleNoIdOrUrlError = useCallback((context: ObjectOf<unknown>) => {
    captureException(
      'No file upload id or upload url returned from XAI when trying to upload supporting document',
      context,
    );
    setState({ isUploading: false, error: 'NO_UPLOAD_ID_OR_URL' });
  }, []);

  const uploadDocAndFinaliseFileUploadSafely = useCallback(
    async (
      context: ObjectOf<unknown>,
      {
        document,
        uploadUrl,
        fileUploadId,
      }: {
        document: SelectedDocument;
        uploadUrl: string;
        fileUploadId: string;
      },
    ) => {
      if (isWeb && document.webOnlyFile == null) {
        // This is an exception and is captured inside document picker.
        setState({ isUploading: false, error: 'NO_FILE_ON_WEB' });
        return;
      }

      try {
        if (isWeb) {
          await fetch(uploadUrl, { method: 'PUT', body: document.webOnlyFile });
        } else {
          await FileSystem.uploadAsync(uploadUrl, document.fileUri, {
            httpMethod: 'PUT',
            uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
          });
        }
      } catch (error: unknown) {
        const rawError = normalizeError(error);
        setState({ isUploading: false, error: 'UPLOAD_FAILED', rawError });
        return;
      }

      const [finaliseRes] = await safelyCallMutation(finaliseDocumentUploads, {
        variables: { fileUploadId },
        context: {
          sentryContext: {
            ...context,
            fileUploadId,
          },
        },
      });

      if (!finaliseRes) {
        setState({
          isUploading: false,
          error: 'FINALISE_UPLOAD_FAILED',
          rawError: null,
        });
        return;
      }

      setState({ isUploading: false });
    },
    [finaliseDocumentUploads],
  );

  return {
    state,
    setState,
    setUploadingFalseWithError,
    handleCommenceDocUploadMutError,
    handleNoIdOrUrlError,
    uploadDocAndFinaliseFileUploadSafely,
  };
}

export function useIncomeSupportingDocumentUpload(loanApplicationId = '') {
  const {
    state,
    setState,
    setUploadingFalseWithError,
    handleCommenceDocUploadMutError,
    handleNoIdOrUrlError,
    uploadDocAndFinaliseFileUploadSafely,
  } = useTaskSupportingDocumentUpload(loanApplicationId);

  const [commenceIncomeSupportingDocumentUpload] =
    useCommenceIncomeSupportingDocumentUploadMutation();

  const uploadIncomeSupportingDocument = async ({
    document,
    incomeId,
  }: UploadIncomeSupportingDocumentUploadVariables) => {
    if (state.isUploading) {
      return;
    }

    setState({ isUploading: true });
    const context = {
      incomeId,
      file: {
        name: document.name,
        size: document.size,
        type: document.fileType,
      },
    };
    const [res, err] = await safelyCallMutation(
      commenceIncomeSupportingDocumentUpload,
      {
        variables: {
          originalName: document.name,
          sizeInBytes: document.size,
          lastModifiedAt: new Date(document.lastModifiedAt).toISOString(),
          incomeId,
        },
        context: { sentryContext: context },
      },
    );

    if (err) {
      handleCommenceDocUploadMutError(context, err);
      return;
    }

    const {
      upload_url: uploadUrl,
      file_upload_id: fileUploadId,
      error_type: errorType,
    } = res.data?.commence_income_supporting_document_upload || {};

    if (errorType) {
      switch (errorType) {
        case Commence_Income_Supporting_Document_Upload_Error_Type.MaxFileSizeExceeded:
          setUploadingFalseWithError('FILE_SIZE_EXCEEDED_LIMIT');
          return;

        default:
          assertUnreachable(errorType);
          setUploadingFalseWithError('UPLOAD_FAILED');
          return;
      }
    }

    if (!uploadUrl || !fileUploadId) {
      handleNoIdOrUrlError(context);
      return;
    }

    await uploadDocAndFinaliseFileUploadSafely(context, {
      document,
      uploadUrl,
      fileUploadId,
    });
  };

  return { ...state, uploadIncomeSupportingDocument };
}

export function useSecuritySupportingDocumentUpload(loanApplicationId = '') {
  const {
    state,
    setState,
    setUploadingFalseWithError,
    handleCommenceDocUploadMutError,
    handleNoIdOrUrlError,
    uploadDocAndFinaliseFileUploadSafely,
  } = useTaskSupportingDocumentUpload(loanApplicationId);

  const [commenceSecurityDocUpload] =
    useCommenceSecuritySupportingDocumentUploadMutation();

  const uploadSecuritySupportingDocument = async ({
    document,
    loanApplicationSecurityId,
  }: {
    document: SelectedDocument;
    loanApplicationSecurityId: string;
  }) => {
    if (state.isUploading) {
      return;
    }

    setState({ isUploading: true });
    const context = {
      loanApplicationSecurityId,
      file: {
        name: document.name,
        size: document.size,
        type: document.fileType,
      },
    };
    const [res, err] = await safelyCallMutation(commenceSecurityDocUpload, {
      variables: {
        originalName: document.name,
        sizeInBytes: document.size,
        lastModifiedAt: new Date(document.lastModifiedAt).toISOString(),
        loanApplicationSecurityId,
      },
      context: { sentryContext: context },
    });

    if (err) {
      handleCommenceDocUploadMutError(context, err);
      return;
    }

    const {
      upload_url: uploadUrl,
      file_upload_id: fileUploadId,
      error_type: errorType,
    } = res.data?.commence_security_supporting_document_upload || {};
    if (errorType) {
      switch (errorType) {
        case Commence_Security_Supporting_Document_Upload_Error_Type.MaxFileSizeExceeded:
          setUploadingFalseWithError('FILE_SIZE_EXCEEDED_LIMIT');
          return;

        default:
          assertUnreachable(errorType);
          setUploadingFalseWithError('UPLOAD_FAILED');
          return;
      }
    }

    if (!uploadUrl || !fileUploadId) {
      handleNoIdOrUrlError(context);
      return;
    }

    await uploadDocAndFinaliseFileUploadSafely(context, {
      document,
      uploadUrl,
      fileUploadId,
    });
  };

  return { ...state, uploadSecuritySupportingDocument };
}

export function useLiabilitySupportingDocumentUpload(loanApplicationId = '') {
  const {
    state,
    setState,
    setUploadingFalseWithError,
    handleCommenceDocUploadMutError,
    handleNoIdOrUrlError,
    uploadDocAndFinaliseFileUploadSafely,
  } = useTaskSupportingDocumentUpload(loanApplicationId);

  const [commenceLiabilitySupportingDocumentUpload] =
    useCommenceLiabilitySupportingDocumentUploadMutation();

  const uploadLiabilitySupportingDocument = async ({
    document,
    mergedLiabilityId,
    loanApplicationTaskType,
  }: UploadLiabilitySupportingDocumentUploadVariables) => {
    if (state.isUploading) {
      return;
    }
    if (!loanApplicationTaskType) {
      return;
    }

    setState({ isUploading: true });
    const context = {
      mergedLiabilityId,
      file: {
        name: document.name,
        size: document.size,
        type: document.fileType,
      },
    };
    const [res, err] = await safelyCallMutation(
      commenceLiabilitySupportingDocumentUpload,
      {
        variables: {
          originalName: document.name,
          sizeInBytes: document.size,
          lastModifiedAt: new Date(document.lastModifiedAt).toISOString(),
          mergedLiabilityId,
          loanApplicationTaskType,
        },
        context: { sentryContext: context },
      },
    );

    if (err) {
      handleCommenceDocUploadMutError(context, err);
      return;
    }

    const {
      upload_url: uploadUrl,
      file_upload_id: fileUploadId,
      error_type: errorType,
    } = res.data?.commence_liability_supporting_document_upload || {};

    if (errorType) {
      switch (errorType) {
        case Commence_Liability_Supporting_Document_Upload_Error_Type.MaxFileSizeExceeded:
          setUploadingFalseWithError('FILE_SIZE_EXCEEDED_LIMIT');
          return;

        default:
          assertUnreachable(errorType);
          setUploadingFalseWithError('UPLOAD_FAILED');
          return;
      }
    }

    if (!uploadUrl || !fileUploadId) {
      handleNoIdOrUrlError(context);
      return;
    }

    await uploadDocAndFinaliseFileUploadSafely(context, {
      document,
      uploadUrl,
      fileUploadId,
    });
  };

  return { ...state, uploadLiabilitySupportingDocument };
}
