import { gql } from '@apollo/client';
import { useRef } from 'react';

import { useMyYodleeRefreshSubscription } from '../../generated/graphql';
import { captureException } from '../../sentry';

export const MyYodleRefreshSubscription = gql`
  subscription MyYodleeRefresh($myUserId: uuid!) {
    yodlee_fastlink_refresh_by_pk(user_id: $myUserId) {
      id: user_id
      lastRefreshAt: updated_at
    }
  }
`;

type RefreshSubOption = {
  myUserId: string | undefined;
  onYodleeDataRefresh: () => Promise<void>;
};

// This subscription provides a way for the client
// to subscribe to Yodlee REFRESH event (https://developer.yodlee.com/docs/api/1.1/Webhooks/Refresh_Event)
// that indicates there's an update to the user's Yodlee account
// and the client should refetch connected banks and bank accounts query.
export function useMyYodleeFastlinkRefreshSubscription({
  myUserId,
  onYodleeDataRefresh,
}: RefreshSubOption) {
  const isFirstDataFromServer = useRef(true);

  useMyYodleeRefreshSubscription({
    skip: !myUserId,
    variables: { myUserId: myUserId ?? '' },
    onData: async ({ data }) => {
      // We only want to trigger the refresh callback
      // if the data from the server has changed after the initial load.
      if (isFirstDataFromServer.current) {
        // We don't care about the initial data itself
        // as the client will fetch the current connected banks
        // regardless of the refresh status
        // and we don't want to trigger a refetch on initial data load.
        isFirstDataFromServer.current = false;
        return;
      }

      const lastRefreshAt =
        data.data?.yodlee_fastlink_refresh_by_pk?.lastRefreshAt;
      if (!lastRefreshAt) {
        // Don't do anything if no lastRefreshAt is undefined.
        return;
      }

      try {
        await onYodleeDataRefresh();
      } catch (error: unknown) {
        captureException(
          `Unhandled error in useMyYodleeFastlinkRefreshSubscription.
          onYodleeDataRefresh must not throw in this context.`,
          {},
          error,
        );
      }
    },
  });
}
