import * as yup from 'yup';

import { FieldRules, MaxValue, MinValue } from '../constants/fieldRules';
import { NUMBER_REGEX } from '../localization/constants';
import { FieldValidationError } from './buildFormError';

yup.setLocale({
  mixed: {
    required: FieldValidationError.Required,
  },
});

const NumericRegex = /^\d+$/;
const AlphanumericRegex = /^[a-zA-Z0-9]+$/;

const yupNullableString = yup.string().nullable();

const yupStringNumeric = (yupString: yup.StringSchema) =>
  yupString.matches(NumericRegex, FieldValidationError.InvalidNumericString);

const yupStringAlphanumeric = (yupString: yup.StringSchema) =>
  yupString.matches(
    AlphanumericRegex,
    FieldValidationError.InvalidAlphanumericString,
  );

/**
 * Formik + Yup does not have a built in support for file input.
 * This yup file validator is only used to check whether there
 * are selected files or not by checking the name,
 * not for the full property of the object,
 */
const yupFile = yup.object({ name: yup.string().required() });

// TODO: Consider deprecating this
// The correct usage for making required() with initial value null is
// to use nullable()
// @see https://github.com/jquense/yup/tree/pre-v1#mixedrequiredmessage-string--function-schema
const yupNumber = yup.number().transform((val) => {
  if (Number.isNaN(val)) {
    /**
     * To fix issue of `typeError` error message override the required error message
     * https://github.com/jquense/yup/issues/971#issuecomment-675528093
     */
    return undefined;
  }
  return val;
});

const yupMoney = yupNumber
  .max(MaxValue.number, FieldValidationError.ExceededMaxMoney)
  .min(MinValue.money, FieldValidationError.LessThanMinMoney);
const yupName = yup.string().test({
  name: 'Name',
  // Name can be optional sometimes (e.g: middle name),
  // so we'll allow empty string and let yup handle the required rule separately
  test: (value) =>
    (value ?? '') === '' ? true : FieldRules.name.isValid(value || ''),
  message: FieldValidationError.InvalidName,
  exclusive: false,
});
const yupDriverLicense = yup.string().test({
  name: 'DriverLicense',
  test: (value) => FieldRules.driverLicense.isValid(value || ''),
  message: FieldValidationError.InvalidName,
  exclusive: false,
});
const yupEmail = yup.string().email(FieldValidationError.InvalidEmail);
const yupMobileNumber = yup
  .object({
    phoneNumber: yup.string(),
    countryIso: yup.string().required(),
    countryPrefix: yup.string().required(),
    isValid: yup.bool(),
  })
  .test({
    name: 'MobileNumberRequired',
    test: (value) => !!value.phoneNumber,
    message: FieldValidationError.Required,
    exclusive: false,
  })
  .test({
    name: 'MobileNumberFormat',
    test: (value) => !!value.isValid,
    message: FieldValidationError.InvalidPhoneNumber,
    exclusive: false,
  });
const yupPostcode = yup
  .string()
  .nullable()
  .length(4, t('Content.Common.Form.Postcode4DigitError'))
  .matches(NUMBER_REGEX, t('Content.Common.Form.PostcodeNumericError'))
  .required(t('Content.Common.Form.PostcodeError'));

const yupExternalAccountName = yup
  .string()
  .required(t('Content.Common.Form.AccountNameRequiredError'));

const yupExternalAccountBsb = yup
  .string()
  .length(6, t('Content.Common.Form.Bsb6DigitsError'))
  .matches(NUMBER_REGEX, t('Content.Common.Form.BsbNumericError'))
  .required(t('Content.Common.Form.BsbRequiredError'));

const yupExternalAccountNumber = yup
  .string()
  .min(6, t('Content.Common.Form.AccountNumber6to9DigitsError'))
  .max(9, t('Content.Common.Form.AccountNumber6to9DigitsError'))
  .matches(NUMBER_REGEX, t('Content.Common.Form.AccountNumberNumericError'))
  .required(t('Content.Common.Form.AccountNumberRequiredError'));

function createValidationSchema<T>(
  builder: (instance: typeof yup) => Record<keyof T, yup.AnySchema>,
) {
  return yup.object(builder(yup));
}

export {
  FieldValidationError,
  yup,
  yupDriverLicense,
  yupEmail,
  yupFile,
  yupMobileNumber,
  yupMoney,
  yupName,
  yupExternalAccountName,
  yupExternalAccountBsb,
  yupExternalAccountNumber,
  yupPostcode,
  yupStringNumeric,
  yupStringAlphanumeric,
  yupNumber,
  yupNullableString,
  createValidationSchema,
};
