import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { LoanApplicationScreenContainer } from '../LoanApplication/components/LoanApplicationScreenContainer';
import { Props as ScreenContainerProps } from '../ui/atoms/ScreenContainer';
import { useTheme } from '../ui/theme';
import { useXButton } from '../utils/hooks/useHeaderButton';

type Props = ScreenContainerProps;

export const LearnMoreScreenContainer: React.FC<Props> = ({ ...props }) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const containerStyle = { padding: theme.spacing.l, paddingTop: 0 };
  useXButton(navigation);
  return (
    <LoanApplicationScreenContainer
      scrollable
      containerStyle={containerStyle}
      {...props}
    />
  );
};
