import { useNavigation } from '@react-navigation/native';
import { Text } from 'dripsy';

import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

export function RentalExpensesLearnMoreModalV2() {
  const navigation = useNavigation();
  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      // TODO: This translated text should not be from YourDebts
      headerText={t('Content.YourDebts.LearnMoreModal.Title')}
      hideBackButton
    >
      <Text>{t('Content.YourDebts.LearnMoreModal.Content')}</Text>
    </ModalScreenContainer>
  );
}
