import 'react-phone-number-input/style.css';
import './styles/phoneInput.module.css';

import { useCallback } from 'react';
import { StyleProp, TextInputProps, ViewStyle } from 'react-native';
import ReactPhoneInput, {
  Country,
  Props as ReactPhoneInputProps,
} from 'react-phone-number-input';

import { StyledTextInput } from '../../ui/atoms/StyledTextInput';
import {
  DEFAULT_PHONE_COUNTRY_CODE,
  E164Mobile,
  isValidMobile,
} from '../../utils/phoneHelpers';
import { stringIsNotNullOrEmpty } from '../../utils/stringHelpers';

export type PhoneNumberData = {
  phoneNumber: string;
  countryIso?: Country;
  countryPrefix?: string;
  isValid?: boolean;
};

export type Props = Omit<
  ReactPhoneInputProps<TextInputProps>,
  'onChange' | 'value'
> & {
  onChangePhoneNumber: (data: PhoneNumberData) => void;
  value?: PhoneNumberData;
  style?: StyleProp<ViewStyle>;
  onBlur?: TextInputProps['onBlur'];
};

function getFlagEmoji(countryCode: Country) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

type EmojiFlagProps = {
  country: Country;
};

const EmojiFlag = ({ country }: EmojiFlagProps) => <>{getFlagEmoji(country)}</>;

export function PhoneInput({
  onChangePhoneNumber,
  value,
  style: styleProps,
  ...props
}: Props) {
  const handleNumberChange = useCallback(
    (phoneNumber?: E164Mobile) => {
      onChangePhoneNumber({
        ...value,
        phoneNumber: phoneNumber ?? '',
        isValid: isValidMobile(phoneNumber ?? '', value?.countryIso),
      });
    },
    [onChangePhoneNumber, value],
  );

  const setCountryCode = useCallback(
    (countryCode?: Country) => {
      onChangePhoneNumber({
        ...value,
        phoneNumber: value?.phoneNumber ?? '',
        countryIso: countryCode,
        isValid: isValidMobile(value?.phoneNumber ?? '', countryCode),
      });
    },
    [onChangePhoneNumber, value],
  );

  return (
    <ReactPhoneInput
      {...props}
      flagComponent={EmojiFlag}
      onCountryChange={setCountryCode}
      onChange={handleNumberChange}
      value={value?.phoneNumber || ''}
      inputComponent={StyledTextInput}
      defaultCountry={
        stringIsNotNullOrEmpty(value?.countryIso)
          ? value?.countryIso
          : DEFAULT_PHONE_COUNTRY_CODE
      }
      autoFormat
    />
  );
}
