import { useCallback, useRef, useState } from 'react';

import {
  Biza_Account_Type,
  refetchGetBizaAccountByIdQuery,
  refetchGetBizaAccountsQuery,
  useApproveSharingRequestMutation,
  useCancelSharingRequestMutation,
  useDeclineSharingRequestMutation,
  useDisableDataSharingMutation,
  useEnableDataSharingMutation,
} from '../../generated/graphql';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';

// Enable
export function useEnableDataSharing(accountId: string) {
  const [sharingComplete, setSharingComplete] = useState<boolean>(false);

  const [
    enableDataSharing,
    { loading: dataSharingLoading, error: dataSharingError },
  ] = useEnableDataSharingMutation();

  const dataSharingRef = useRef(enableDataSharing);

  const onCompleted = useCallback(
    () => setSharingComplete(true),
    [setSharingComplete],
  );

  const callDataSharingMutation = async () => {
    await safelyCallMutation(dataSharingRef.current, {
      variables: { accountId },
      awaitRefetchQueries: false,
      refetchQueries: [
        refetchGetBizaAccountByIdQuery({ accountId }),
        refetchGetBizaAccountsQuery({ type: Biza_Account_Type.Joint }),
      ],
      context: {
        sentryContext: {
          accountId,
          description: 'Enabling Data Sharing',
        },
      },
      onCompleted,
    });
  };

  return {
    callDataSharingMutation,
    dataSharingLoading,
    dataSharingError,
    sharingComplete,
  };
}

// Disable
export function useDisableDataSharing(accountId: string) {
  const [sharingComplete, setSharingComplete] = useState<boolean>(false);

  const [
    disableDataSharing,
    { loading: dataSharingLoading, error: dataSharingError },
  ] = useDisableDataSharingMutation();

  const dataSharingRef = useRef(disableDataSharing);

  const onCompleted = useCallback(
    () => setSharingComplete(true),
    [setSharingComplete],
  );

  const callDataSharingMutation = async () => {
    await safelyCallMutation(dataSharingRef.current, {
      variables: { accountId },
      awaitRefetchQueries: false,
      refetchQueries: [
        refetchGetBizaAccountByIdQuery({ accountId }),
        refetchGetBizaAccountsQuery({ type: Biza_Account_Type.Joint }),
      ],
      context: {
        sentryContext: {
          accountId,
          description: 'Disabling Data Sharing',
        },
      },
      onCompleted,
    });
  };

  return {
    callDataSharingMutation,
    dataSharingLoading,
    dataSharingError,
    sharingComplete,
  };
}

// Approve
export function useApproveDataSharing(accountId: string, requestId?: string) {
  const [sharingComplete, setSharingComplete] = useState<boolean>(false);

  const [
    approveDataSharing,
    { loading: dataSharingLoading, error: dataSharingError },
  ] = useApproveSharingRequestMutation();

  const dataSharingRef = useRef(approveDataSharing);

  const onCompleted = useCallback(
    () => setSharingComplete(true),
    [setSharingComplete],
  );

  const callDataSharingMutation = async () => {
    if (requestId) {
      await safelyCallMutation(dataSharingRef.current, {
        variables: { accountId, requestId },
        awaitRefetchQueries: false,
        refetchQueries: [
          refetchGetBizaAccountByIdQuery({ accountId }),
          refetchGetBizaAccountsQuery({ type: Biza_Account_Type.Joint }),
        ],
        context: {
          sentryContext: {
            requestId,
            accountId,
            description: 'Approving pending Biza request',
          },
        },
        onCompleted,
      });
    }
  };

  return {
    callDataSharingMutation,
    dataSharingLoading,
    dataSharingError,
    sharingComplete,
  };
}

// Decline
export function useDeclineDataSharing(accountId: string, requestId?: string) {
  const [sharingComplete, setSharingComplete] = useState<boolean>(false);

  const [
    declineDataSharing,
    { loading: dataSharingLoading, error: dataSharingError },
  ] = useDeclineSharingRequestMutation();

  const dataSharingRef = useRef(declineDataSharing);

  const onCompleted = useCallback(
    () => setSharingComplete(true),
    [setSharingComplete],
  );

  const callDataSharingMutation = async () => {
    if (requestId) {
      await safelyCallMutation(dataSharingRef.current, {
        variables: { accountId, requestId },
        awaitRefetchQueries: false,
        refetchQueries: [
          refetchGetBizaAccountByIdQuery({ accountId }),
          refetchGetBizaAccountsQuery({ type: Biza_Account_Type.Joint }),
        ],
        context: {
          sentryContext: {
            requestId,
            accountId,
            description: 'Declining pending Biza request',
          },
        },
        onCompleted,
      });
    }
  };

  return {
    callDataSharingMutation,
    dataSharingLoading,
    dataSharingError,
    sharingComplete,
  };
}

// Cancel
export function useCancelDataSharing(accountId: string, requestId?: string) {
  const [sharingComplete, setSharingComplete] = useState<boolean>(false);

  const [
    cancelDataSharing,
    { loading: dataSharingLoading, error: dataSharingError },
  ] = useCancelSharingRequestMutation();

  const dataSharingRef = useRef(cancelDataSharing);

  const onCompleted = useCallback(
    () => setSharingComplete(true),
    [setSharingComplete],
  );

  const callDataSharingMutation = async () => {
    if (requestId) {
      await safelyCallMutation(dataSharingRef.current, {
        variables: { accountId, requestId },
        awaitRefetchQueries: false,
        refetchQueries: [
          refetchGetBizaAccountByIdQuery({ accountId }),
          refetchGetBizaAccountsQuery({ type: Biza_Account_Type.Joint }),
        ],
        context: {
          sentryContext: {
            requestId,
            accountId,
            description: 'Cancel Data Sharing',
          },
        },
        onCompleted,
      });
    }
  };

  return {
    callDataSharingMutation,
    dataSharingLoading,
    dataSharingError,
    sharingComplete,
  };
}
