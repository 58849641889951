import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function TimeIcon({ size, color, ...props }: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        fill={color}
        d="M6.61 13.892c3.543 0 6.475-2.94 6.475-6.475 0-3.542-2.939-6.475-6.48-6.475C3.068.942.135 3.875.135 7.417c0 3.536 2.94 6.475 6.475 6.475Zm0-1.08a5.37 5.37 0 0 1-5.388-5.395 5.364 5.364 0 0 1 5.382-5.396 5.38 5.38 0 0 1 5.402 5.396c0 2.996-2.4 5.396-5.395 5.396Zm-3.319-4.71h3.313c.248 0 .445-.19.445-.444V3.38a.436.436 0 0 0-.445-.438.43.43 0 0 0-.438.438v3.84H3.292a.432.432 0 0 0-.444.438c0 .254.19.445.444.445Z"
      />
    </Svg>
  );
}
