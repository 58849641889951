import * as Clipboard from 'expo-clipboard';
import { useState } from 'react';

import { SuccessRow } from '../../components/SuccessRow';
import { ActionSheetType } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import {
  formatBsb,
  formatBsbAndAccountNumber,
} from '../../utils/formatBsbAndAccountNumber';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props = ActionSheetScreenProps<ActionSheetType.COPY_BSB_ACCOUNT_NUMBER>;

type ButtonType = 'bsb' | 'accountNumber' | 'both';

const buttons: { type: ButtonType; label: string }[] = [
  { type: 'bsb', label: t('Content.CopyBsbAccountNumberActionSheet.Bsb') },
  {
    type: 'accountNumber',
    label: t('Content.CopyBsbAccountNumberActionSheet.AccountNumber'),
  },
  {
    type: 'both',
    label: `${t('Content.CopyBsbAccountNumberActionSheet.Bsb')} & ${t(
      'Content.CopyBsbAccountNumberActionSheet.AccountNumber',
    )}`,
  },
];

type BaseCopyBsbAccountNumberActionSheetProps = {
  selected?: ButtonType;
  onClose: () => void;
  onSelect: (type: ButtonType) => void;
};

export function BaseCopyBsbAccountNumberActionSheet({
  onClose,
  selected,
  onSelect,
}: BaseCopyBsbAccountNumberActionSheetProps) {
  return (
    <ActionSheet
      title={t('Content.CopyBsbAccountNumberActionSheet.SheetTitle')}
      closeModal={onClose}
    >
      <Separator spacer />
      {selected ? (
        <SuccessRow
          message={t('Content.CopyBsbAccountNumberActionSheet.SuccessMessage', {
            label: buttons.find(({ type }) => type === selected)?.label,
          })}
          mb="m"
          autoDismiss
        />
      ) : null}

      {buttons.map(({ type, label }, index) => (
        <Button
          key={type}
          secondary
          label={label}
          width="100%"
          mt={index > 0 ? 's' : undefined}
          onPress={() => onSelect(type)}
        />
      ))}
    </ActionSheet>
  );
}

export function CopyBsbAccountNumberActionSheet({ navigation, route }: Props) {
  const { bsb, accountNumber } = route.params;

  const [selectedButton, setSelectedButton] = useState<ButtonType>();

  const onSelect = async (type: ButtonType) => {
    switch (type) {
      case 'bsb':
        await Clipboard.setStringAsync(formatBsb(bsb));
        break;
      case 'accountNumber':
        await Clipboard.setStringAsync(accountNumber);
        break;
      case 'both':
        await Clipboard.setStringAsync(
          formatBsbAndAccountNumber(bsb, accountNumber),
        );
        break;
      default:
        return;
    }

    setSelectedButton(type);
  };

  return (
    <BaseCopyBsbAccountNumberActionSheet
      onClose={navigation.goBack}
      selected={selectedButton}
      onSelect={onSelect}
    />
  );
}
