import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function TooltipArrowIcon({
  size,
  color,
  opacity,
  ...props
}: SvgIconProps) {
  const width = resizeIconWidth(15, 7, size);

  return (
    <Svg width={width} height={size} fill="none" {...props}>
      <Path d="m7.5 7-7-7h14l-7 7Z" fill={color} opacity={opacity} />
    </Svg>
  );
}
