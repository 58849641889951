import { NetworkStatus } from '@apollo/client';
import { Text } from 'dripsy';
import { useCallback, useContext, useMemo, useState } from 'react';

import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../../components/ErrorRow';
import { RefreshPageErrorRow } from '../../../components/RefreshPageErrorRow';
import { ScreenLoadingContainer } from '../../../components/ScreenLoadingContainer';
import { useFullNameByApplicantIds } from '../../../DebtsWizard/utils/useAllLiabilitiesQuery';
import { FeatureFlagsContext } from '../../../FeatureFlags/context';
import {
  MergedLiabilityForSetupLoanScreenFragment,
  refetchGetDisclosureContentQuery,
  refetchGetTotalLoanAmountQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourUnloanScreenV2Query,
  Refinancing_Reason_Input_Enum,
  Security_Cost_Calculation_State_Enum,
  Top_Up_Reason_Input,
  Top_Up_Reason_Input_Enum,
  UpsertLoanApplicationTargetV3MutationVariables,
  useGetSecurityCostAndBufferForRefiLazyQuery,
  useGetTargetLoanQuery,
  useSetupLoanScreenQuery,
  useUpsertLoanApplicationTargetV3Mutation,
} from '../../../generated/graphql';
import { NoLoanApplicationFound } from '../../../LoanApplication/components/NoLoanApplicationFound';
import { Screen } from '../../../navigation/types/screens';
import { captureMessage } from '../../../sentry';
import { ModalScreenContainer } from '../../../ui/v2/ModalScreenContainer';
import { isNotNullOrUndefined } from '../../../utils/arrayHelpers';
import { parseEnumType } from '../../../utils/ensureEnumType';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { useSecurityCostCalculationState } from '../../../utils/hooks/useSecurityCostCalculationState';
import { useSendDataToGTM } from '../../../utils/hooks/useSendDataToGTM';
import { handleSetupLoanErrorType } from '../../utils/handleSetupLoanErrorType';
import {
  getInitialSetupLoanFormWithLoanAmountValues,
  SetupLoanFormFieldV2,
  SetupLoanFormWithLoanAmountInitialValues,
  SetupLoanYesNoEnum,
} from '../../utils/setupLoanFormHelper';
import { useLoanAmountCalculationV3 } from '../../utils/useSetupLoanCalculation';
import { SetupLoanForm as SetupLoanFormWithLoanAmount } from '../components/SetupLoanFormWithLoanAmount';

type Props = {
  screen: Screen;
  onSubmitSuccess: () => void;
  onClose: () => void;
  navigateToSelectRefiReason?: () => void;
  navigateToYourDebts: () => void;
  showSelectRefiReason: boolean;
  hideBackButton: boolean;
  loanApplicationTargetId: string | undefined;
  loanApplicationId: string | undefined;
};

export function SetupLoanV2Base({
  screen,
  onSubmitSuccess,
  onClose,
  hideBackButton,
  loanApplicationTargetId,
  loanApplicationId,
  navigateToSelectRefiReason,
  showSelectRefiReason,
  navigateToYourDebts,
}: Props) {
  const { flags } = useContext(FeatureFlagsContext);
  const enableShowSavingsOnYourUnloan =
    flags.ENABLE_SHOW_SAVINGS_ON_YOUR_UNLOAN;

  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  const {
    data,
    loading: setupLoanScreenLoading,
    networkStatus,
  } = useSetupLoanScreenQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const { data: targetLoanData, loading: targetLoanLoading } =
    useGetTargetLoanQuery({
      variables: {
        targetLoanId: loanApplicationTargetId || '',
      },
      skip: !loanApplicationTargetId,
      notifyOnNetworkStatusChange: true,
      context: {
        sentryContext: {
          loanApplicationId,
          loanApplicationTargetId,
        },
      },
    });

  const [
    getSecurityCostAndBuffer,
    {
      data: securityCostAndBufferData,
      loading: getSecurityCostAndBufferLoading,
    },
  ] = useGetSecurityCostAndBufferForRefiLazyQuery();

  const {
    calculateLoanAmounts: calculateLoanAmountsV3,
    calculationData: calculationDataV3,
    calculationLoading: calculationLoadingV3,
  } = useLoanAmountCalculationV3();

  const savingsAmount = calculationDataV3?.get_savings_calculation?.savings;
  const productRate =
    calculationDataV3?.calculate_app_total_loan_amount_v2.interest_rate;

  const captureCurrentLoadingStates = useCallback(
    (costCalculationState?: Security_Cost_Calculation_State_Enum | null) => {
      captureMessage(
        'Security cost calculation took too long - SetupLoanScreenV2Base',
        {
          loanApplicationId,
          costCalculationState,
          // Adding other loading states to help debug and discover false positive
          getSecurityCostAndBufferLoading,
          calculationLoadingV3,
        },
      );
    },
    [calculationLoadingV3, getSecurityCostAndBufferLoading, loanApplicationId],
  );

  const {
    waitingForCostCalculationToBeCompleted,
    shouldShowErrorForCostCalculation,
    retrySecurityCostCalculation,
    isWaitingForRetryToComplete,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    onCalculationComplete: async () => {
      await getSecurityCostAndBuffer({
        variables: { loanApplicationId: loanApplicationId ?? '' },
        fetchPolicy: 'network-only',
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      });
    },
    logToSentryIfCalculationTookLongTime: true,
    onLogCalculationTookTooLong: captureCurrentLoadingStates,
  });

  const loanApplication = data?.loanApplication;

  const existingLoanTarget = targetLoanData?.loan_application_target_by_pk;
  // There will always be 1 financial declaration per loan application
  const financialDeclaration = data?.financial_declaration?.[0];

  const fullNameByApplicantIds = useFullNameByApplicantIds(data?.applicants);

  const allSecurities = useMemo(
    () => loanApplication?.loanApplicationSecurities ?? [],
    [loanApplication?.loanApplicationSecurities],
  );

  const loading =
    setupLoanScreenLoading ||
    targetLoanLoading ||
    networkStatus === NetworkStatus.refetch;

  const mergedLiabilitiesAllowedToBeRefinanced = useMemo(
    () =>
      data?.mergedLiabilitiesAllowedToBeRefinanced
        .map((ml) => ml.merged_liability)
        .filter(
          (ml): ml is MergedLiabilityForSetupLoanScreenFragment =>
            isNotNullOrUndefined(ml) && ml.dynamite_flagged_incorrect == null,
        ) || [],
    [data?.mergedLiabilitiesAllowedToBeRefinanced],
  );

  const initialValuesWithLoanAmount =
    getInitialSetupLoanFormWithLoanAmountValues({
      targetLoan: existingLoanTarget,
      mergedLiabilities: mergedLiabilitiesAllowedToBeRefinanced,
      allSecurities,
      financialDeclarationTopUpDetails:
        financialDeclaration?.top_up_reasons || [],
    });

  const [upsertLoanApplicationTargetV3, { loading: upsertTargetV3Loading }] =
    useUpsertLoanApplicationTargetV3Mutation();

  const refinanceReason = financialDeclaration?.refinancing_reason || undefined;

  const parsedRefinanceReason = parseEnumType(
    Refinancing_Reason_Input_Enum,
    refinanceReason,
  );

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onSubmitWithLoanAmount = useCallback(
    async (values: SetupLoanFormWithLoanAmountInitialValues) => {
      if (
        !loanApplicationId ||
        !parsedRefinanceReason ||
        values.termInMonths == null ||
        values.selectedLoansToBeRefinanced == null ||
        values.desiredLoanAmount == null
      ) {
        setFormErrorMessage(
          t('Content.Common.Error.FailCreateLoanApplicationTarget'),
        );
        return;
      }
      setFormErrorMessage(null);
      const topUpDetails: Array<Top_Up_Reason_Input> =
        values.hasTopUpAmount === SetupLoanYesNoEnum.Yes
          ? [
              {
                top_up_reason: Top_Up_Reason_Input_Enum.YourHome,
                top_up_amount:
                  values[SetupLoanFormFieldV2.YourHomeTopUpAmount] ?? 0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.FinancialInvestment,
                top_up_amount:
                  values[SetupLoanFormFieldV2.FinancialInvestmentTopUpAmount] ??
                  0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.InvestmentProperty,
                top_up_amount:
                  values[SetupLoanFormFieldV2.InvestmentPropertyTopUpAmount] ??
                  0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.PersonalUse,
                top_up_amount:
                  values[SetupLoanFormFieldV2.PersonalUseTopUpAmount] ?? 0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.Other,
                top_up_amount:
                  values[SetupLoanFormFieldV2.OtherTopUpAmount] ?? 0,
                other_top_up_reason_description:
                  values[SetupLoanFormFieldV2.OtherTopUpDescription] ?? '',
              },
            ].filter(
              ({ top_up_amount }) => top_up_amount != null && top_up_amount > 0,
            )
          : [];

      const upsertLoanTargetVariables: UpsertLoanApplicationTargetV3MutationVariables =
        {
          desired_loan_amount: values.desiredLoanAmount,
          loan_application_id: loanApplicationId,
          loan_term_in_months: values.termInMonths,
          refinanced_liability_ids: values.selectedLoansToBeRefinanced,
          refinanced_security_ids:
            allSecurities?.map((security) => security.id) || [],
          refinancing_reason: parsedRefinanceReason,
          top_up_reasons: topUpDetails,
        };

      const [upsertLoanTargetRes] = await safelyCallMutation(
        upsertLoanApplicationTargetV3,
        {
          variables: upsertLoanTargetVariables,
          refetchQueries: [
            refetchYourUnloanScreenV2Query({
              loanApplicationId,
            }),
            refetchGetTotalLoanAmountQuery({ loanApplicationId }),
            refetchReviewLoanApplicationQuery({ loanApplicationId }),
            refetchGetDisclosureContentQuery({ loanApplicationId }),
            refetchSetupLoanScreenQuery({ loanApplicationId }),
          ],
          awaitRefetchQueries: true,
          context: {
            sentryContext: {
              loanApplicationId,
              refinancedLiabilityIds:
                upsertLoanTargetVariables.refinanced_liability_ids,
              loanTermInMonths: upsertLoanTargetVariables.loan_term_in_months,
              refinancedSecurityIds:
                upsertLoanTargetVariables.refinanced_security_ids,
            },
          },
        },
      );

      if (upsertLoanTargetRes == null) {
        setFormErrorMessage(
          t('Content.Common.Error.FailUpdateLoanApplicationTarget'),
        );
        return;
      }

      const upsertLoanTarget =
        upsertLoanTargetRes.data?.upsert_loan_application_target_v3;
      const upsertLoanTargetErrorType = upsertLoanTarget?.error_type;
      const minTotalLoanAmountBeforeCosts =
        upsertLoanTarget?.minimum_total_loan_amount_before_cost;

      if (upsertLoanTargetErrorType) {
        setFormErrorMessage(
          handleSetupLoanErrorType(upsertLoanTargetErrorType, {
            minTotalLoanAmountBeforeCosts,
          }),
        );
        return;
      }
      const showSavingsAmountAndDescription =
        enableShowSavingsOnYourUnloan &&
        values.hasTopUpAmount !== SetupLoanYesNoEnum.Yes &&
        savingsAmount &&
        savingsAmount > 0;
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.YourUnloan,
              screen,
              FieldInteractionKey.Continue,
            ),
          savings_banner_visible: !!showSavingsAmountAndDescription,
          savings_amount: showSavingsAmountAndDescription ? savingsAmount : 0,
        },
      });
      onSubmitSuccess();
    },
    [
      loanApplicationId,
      parsedRefinanceReason,
      allSecurities,
      upsertLoanApplicationTargetV3,
      enableShowSavingsOnYourUnloan,
      savingsAmount,
      screen,
      sendAppInteractionEventToGTM,
      onSubmitSuccess,
    ],
  );

  if (!loanApplicationId) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={onClose}
        hideBackButton={hideBackButton}
      >
        <NoLoanApplicationFound />
      </ModalScreenContainer>
    );
  }

  if (loading) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={onClose}
        hideBackButton={hideBackButton}
      >
        <ScreenLoadingContainer loading />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      scrollable
      headerText={t('Content.SetupLoanV2.ScreenTitle')}
      onClose={onClose}
      hideBackButton={hideBackButton}
      loading={upsertTargetV3Loading}
    >
      {shouldShowErrorForCostCalculation ? (
        <RefreshPageErrorRow
          onRefreshPress={retrySecurityCostCalculation}
          sx={{ mb: '$16' }}
          refreshDisabled={isWaitingForRetryToComplete}
          refreshLoading={isWaitingForRetryToComplete}
        />
      ) : null}
      <ErrorRow message={formErrorMessage} my="s" />
      {showSelectRefiReason ? (
        <Text sx={{ mb: '$16' }}>
          {t('Content.SetupLoan.SetupHomeLoanFooter', {
            reason: parsedRefinanceReason
              ? t(
                  `Content.SetupLoan.RefinancingReason.${parsedRefinanceReason}`,
                )
              : '--',
          })}
          <Text
            variant="link"
            onPress={() => {
              if (navigateToSelectRefiReason) {
                setFormErrorMessage(null);
                navigateToSelectRefiReason();
              }
            }}
          >
            {t('Content.Common.ButtonLabel.Change')}
          </Text>
        </Text>
      ) : null}
      <SetupLoanFormWithLoanAmount
        screen={screen}
        liabilitiesAllowedToBeRefinanced={
          mergedLiabilitiesAllowedToBeRefinanced
        }
        costAndBufferLoading={
          getSecurityCostAndBufferLoading ||
          waitingForCostCalculationToBeCompleted
        }
        allSecurities={allSecurities}
        fullNameByApplicantIds={fullNameByApplicantIds}
        productRate={productRate ?? undefined}
        costAndBufferDetails={
          securityCostAndBufferData?.sumLoanApplicationCostAndBuffer
        }
        initialValues={initialValuesWithLoanAmount}
        loanApplicationId={loanApplicationId}
        isSubmitting={upsertTargetV3Loading}
        onSubmit={onSubmitWithLoanAmount}
        calculateLoanAmounts={calculateLoanAmountsV3}
        calculationData={calculationDataV3}
        calculationLoading={
          calculationLoadingV3 || waitingForCostCalculationToBeCompleted
        }
        navigateToYourDebts={navigateToYourDebts}
      />
    </ModalScreenContainer>
  );
}
