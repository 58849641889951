import * as React from 'react';

import { useGetMeQuery } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { BaseMobileVerify } from './BaseMobileVerify';

type EmailVerifyProps = Omit<
  React.ComponentProps<typeof BaseMobileVerify>,
  'target'
> & {
  screen: Screen;
  onPressResend?: () => void;
};

export const EmailVerify = ({ onPressResend, ...props }: EmailVerifyProps) => {
  const { data } = useGetMeQuery();
  const email = data?.me[0]?.user?.email ?? '';

  return (
    <BaseMobileVerify onPressResend={onPressResend} target={email} {...props} />
  );
};
