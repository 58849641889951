import { useNavigation } from '@react-navigation/native';
import { useContext, useEffect, useRef } from 'react';

import { FeatureFlagsContext } from '../FeatureFlags/context';
import { NavigatorFullScreenModalScreen } from '../navigation/NavigatorFullScreenModal';
import { Screen } from '../navigation/types/screens';

export const useOutOfService = () => {
  const { flags } = useContext(FeatureFlagsContext);
  const navigation = useNavigation();
  const shownOutOfService = useRef(false);

  useEffect(() => {
    if (flags.ENABLE_OUT_OF_SERVICE && !shownOutOfService.current) {
      navigation.navigate(Screen.NAVIGATOR_FULL_SCREEN_MODAL, {
        screen: NavigatorFullScreenModalScreen.OutOfService,
      });
      shownOutOfService.current = true;
      return;
    }
    if (!flags.ENABLE_OUT_OF_SERVICE && shownOutOfService.current) {
      navigation.goBack();
      shownOutOfService.current = false;
    }
  }, [flags.ENABLE_OUT_OF_SERVICE, navigation]);

  return flags.ENABLE_OUT_OF_SERVICE;
};
