import { atom } from 'recoil';

import { CombinedExpensesFormValues } from '../utils/forms';

const ExpensesFormAtomName = 'ExpensesForm';

export type ExpensesFormAtomValues = CombinedExpensesFormValues | null;
// Main purpose is to pass data to Low Expense Action Sheet
export const expensesFormAtom = atom<ExpensesFormAtomValues>({
  key: ExpensesFormAtomName,
  default: null,
});
