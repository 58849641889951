import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import * as React from 'react';

import { StaticWidgetLine } from '../../HomeLoanInsights/components/PropertyValue/StaticWidgetLine';
import { usePropertyValueInsight } from '../../HomeLoanInsights/hooks/usePropertyValueInsight';
import { Screen } from '../../navigation/types/screens';
import { formatCurrency } from '../../utils/currencyHelpers';
import { HomeLoanLoadingInsight } from './HomeLoanLoadingInsight';
import { InsightWidgetContainer } from './InsightWidgetContainer';

export type Props = Partial<
  React.ComponentProps<typeof InsightWidgetContainer>
> & {
  cbaAccountId: string;
  loading?: boolean;
};

export const InsightsPropertyValueWidget = ({
  cbaAccountId,
  loading = false,
  ...props
}: Props) => {
  const navigation = useNavigation();
  const { propertyValuation, loading: isLoadingPropertyInsights } =
    usePropertyValueInsight(cbaAccountId);

  const openEstimatedPropertyValueInsightModal = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN_INSIGHTS_MODAL, {
      screen: Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const isPropertyInsightsUnavailable =
    !isLoadingPropertyInsights && !loading && !propertyValuation;

  if (loading || isLoadingPropertyInsights) {
    return <HomeLoanLoadingInsight />;
  }

  return (
    <InsightWidgetContainer
      onPress={openEstimatedPropertyValueInsightModal}
      disabled={
        loading || isLoadingPropertyInsights || isPropertyInsightsUnavailable
      }
      header={formatCurrency(propertyValuation, {
        displayInThousand: true,
        displayInMillion: true,
      })}
      caption={t('Content.HomeLoan.Insights.PropertyValue.PropertyValue')}
      graphPlaceholder={
        <StaticWidgetLine disabled={isPropertyInsightsUnavailable} />
      }
      {...props}
    />
  );
};
