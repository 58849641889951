import { gql } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Frequency_Enum,
  Income_Type_Enum,
  Rental_Income_Type_Enum,
  useCommenceIncomeVerificationMutation,
  useStoreApplicantConsentForIncomeVerificationMutation,
} from '../../generated/graphql';
import {
  getIncomeFinancialRowCaption,
  IncomeRow,
  IncomeRowProps,
  IncomeRowWithError,
  IncomeRowWithErrorProps,
} from '../../Income/components/IncomeRow';
import { useCheckExpectedRentalIncome } from '../../Income/hooks/useCheckExpectedRentalIncome';
import { composeScreenNameForEmploymentIncome } from '../../Income/navigation/YourIncomeWizardNavigator';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { isLast } from '../../utils/arrayHelpers';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useYourFinancialsScreenQuery } from '../graphql/queries';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import { getYourIncomeCaptionAndPrimaryButtonProps } from '../utils/getYourIncomeCaptionAndPrimaryButtonProps';
import { checkExpectedRentalIncomeExisted } from '../utils/incomeHelpers';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';
import { useCompletedIncomeVerification } from '../utils/useCompletedIncomeVerificationQuery';
import { usePrepopulateIncome } from '../utils/usePrepopulateIncome';

export const CommenceIncomeVerification = gql`
  mutation CommenceIncomeVerification($loanApplicationId: uuid!) {
    commence_income_verification(loan_application_id: $loanApplicationId) {
      income_verification_id
    }
  }
`;

export const StoreApplicantConsentForIncomeVerification = gql`
  mutation StoreApplicantConsentForIncomeVerification(
    $loanApplicationId: uuid!
  ) {
    store_applicant_consent_for_income_verification(
      loan_application_id: $loanApplicationId
    ) {
      applicant {
        id
        income_verification_consent_at
      }
    }
  }
`;

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_YOUR_INCOME>;
const currentSection = LoanApplicationSection.Income;

const EXPECTED_RENTAL_INCOME_REQUIRED_ERROR_ITEM = {
  id: 'expected-rental-income-required-id',
  type: Income_Type_Enum.Rental,
  message: t('Content.YourIncome.MissingExpectedRentalDetails'),
  rentalIncome: {
    id: 'expected-rental-income-required-rental-id',
    rental_income_type: Rental_Income_Type_Enum.Expected,
    rental_expense: 0,
    rental_expense_frequency: Frequency_Enum.Annual,
  },
  employmentIncome: null,
};

function LoanApplicationYourIncomeBaseV2({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;

  // TODO(uiv2): consider to create a specific v2 query for this screen
  // For now keep using legacy query which query both income and expense fields
  // in one query.
  const {
    applicants,
    incomes,
    loading: getYourFinancialsLoading,
    loanApplication,
    enableEquifaxIncomeVerificationForPOC,
    userHasPassedKYC,
    error,
    refetch,
  } = useYourFinancialsScreenQuery(loanApplicationId);

  const { navigateToNextLoanApplicationScreen, getScreenOrder } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  const { flags } = useContext(FeatureFlagsContext);

  const enablePrepopulateEmploymentIncome =
    flags.ENABLE_PREPOPULATE_EMPLOYMENT_INCOME;

  const {
    shouldIncludeAddExpectedRentalIncome,
    loading: shouldIncludeAddExpectedRentalIncomeLoading,
  } = useCheckExpectedRentalIncome(loanApplicationId ?? '');

  const [
    commenceIncomeVerification,
    { loading: commenceIncomeVerificationLoading },
  ] = useCommenceIncomeVerificationMutation();

  const [
    storeApplicantConsentForIncomeVerification,
    { loading: storeApplicantConsentLoading },
  ] = useStoreApplicantConsentForIncomeVerificationMutation();

  const [validIncomeVerificationId, setValidIncomeVerificationId] = useState<
    string | null
  >(null);

  const { incomeVerificationData, incomeVerificationLoading } =
    useCompletedIncomeVerification({
      validIncomeVerificationId,
    });

  const incomeVerificationStatus =
    incomeVerificationData?.income_verification_by_pk?.status;
  const incomeVerificationState =
    incomeVerificationData?.income_verification_by_pk?.state;

  const emptyIncomeList = incomes == null || incomes?.length < 1;

  const navigateToPrepopulatedEmploymentIncomeScreen = useCallback(
    (incomeVerificationId: string) => {
      navigation.navigate(Screen.YOUR_INCOME_V2_WIZARD, {
        applicants,
        screen: composeScreenNameForEmploymentIncome(applicants?.[0]?.id || ''),
        params: {
          loanApplicationId,
          incomeVerificationId,
        },
      });
    },
    [applicants, loanApplicationId, navigation],
  );

  const navigateToCheckEmploymentIncomeWizard = useCallback(
    (incomeVerificationId?: string) => {
      navigation.navigate(Screen.YOUR_INCOME_V2_WIZARD, {
        applicants,
        screen: composeScreenNameForEmploymentIncome(applicants?.[0]?.id || ''),
        params: {
          loanApplicationId,
          incomeVerificationId,
        },
      });
    },
    [applicants, loanApplicationId, navigation],
  );
  const detectedEmploymentIncomeSummaries =
    incomeVerificationData?.income_verification_by_pk
      ?.detectedEmploymentIncomeSummaries;

  usePrepopulateIncome({
    commenceIncomeVerificationLoading,
    incomeVerificationLoading,
    validIncomeVerificationId,
    enablePrepopulateEmploymentIncome,
    incomeVerificationState,
    incomeVerificationStatus,
    detectedEmploymentIncomeSummaries,
    navigateToPrepopulatedEmploymentIncomeScreen,
    navigateToCheckEmploymentIncomeWizard,
    setValidIncomeVerificationId,
    getYourFinancialsLoading,
    hasUserDeclaredIncome: !emptyIncomeList,
  });

  const isPrimaryButtonLoading =
    getYourFinancialsLoading ||
    commenceIncomeVerificationLoading ||
    storeApplicantConsentLoading;

  const onPrimaryButtonPress = async () => {
    if (!loanApplicationId) {
      return;
    }
    if (incomes && incomes.length > 0) {
      navigateToNextLoanApplicationScreen({
        currentSection,
      });
      return;
    }

    if (!userHasPassedKYC) {
      navigateToCheckEmploymentIncomeWizard();
      return;
    }

    // If feature flag for income prepopulation is on and user has passed KYC,
    // we want to store the income verification consent & start commencing income verification
    if (enablePrepopulateEmploymentIncome) {
      const [res, err] = await safelyCallMutation(commenceIncomeVerification, {
        variables: {
          loanApplicationId,
        },
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      });

      const incomeVerificationId =
        res?.data?.commence_income_verification?.income_verification_id;

      setValidIncomeVerificationId(incomeVerificationId || null);

      if (err || incomeVerificationId == null) {
        // When error occured or no incomeVerificationId, navigate to "Check Employment" screen
        navigateToCheckEmploymentIncomeWizard(incomeVerificationId);
      }
      return;
    }

    // If FF for POC is on and user has passed KYC,
    // we want to store the income verification consent
    if (enableEquifaxIncomeVerificationForPOC) {
      await safelyCallMutation(storeApplicantConsentForIncomeVerification, {
        variables: {
          loanApplicationId,
        },
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      });
      navigateToCheckEmploymentIncomeWizard();
      return;
    }

    // If both FF is off, navigate to check employment screen wizard as usual
    navigateToCheckEmploymentIncomeWizard();
  };

  const onIncomeRowPress = useCallback<NonNullable<IncomeRowProps['onPress']>>(
    ({ id, type, ...otherData }) => {
      if (type === Income_Type_Enum.Employment) {
        navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL,
          params: {
            ...route.params,
            incomeId: id,
          },
        });
        return;
      }
      if (type === Income_Type_Enum.Rental) {
        const { rentalIncomeType = Rental_Income_Type_Enum.Existing } =
          otherData;

        const screen =
          rentalIncomeType === Rental_Income_Type_Enum.Expected
            ? Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL
            : Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL;

        navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
          screen,
          params: { ...route.params, incomeId: id },
        });
        return;
      }
      if (type === Income_Type_Enum.GovernmentPayments) {
        navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL,
          params: {
            ...route.params,
            incomeId: id,
          },
        });
        return;
      }
      if (type === Income_Type_Enum.ShareDividends) {
        navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL,
          params: {
            ...route.params,
            incomeId: id,
          },
        });
      }
    },
    [navigation, route.params],
  );

  const onIncomeRowWithErrorPress = useCallback(() => {
    navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
      screen: Screen.YOUR_INCOME_V2_ADD_EXPECTED_RENTAL_INCOME_MODAL,
      params: { loanApplicationId },
    });
  }, [loanApplicationId, navigation]);

  const onAddIncomeButtonPress = () => {
    navigation.navigate(Screen.YOUR_INCOME_V2_MODAL, {
      screen: Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
      params: route.params,
    });
  };

  // Display income verification loading when commencing & when waiting for incomeVerification to be completed
  if (commenceIncomeVerificationLoading || incomeVerificationLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader title={t('Content.YourIncome.Header.Title')} />
        <LoadingState
          description={t(
            'Content.EmploymentIncome.IncomeVerificationLoadingState',
          )}
        />
      </LoanApplicationScreenContainer>
    );
  }

  // Display general loading indicator when loading required data
  if (getYourFinancialsLoading || shouldIncludeAddExpectedRentalIncomeLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader title={t('Content.YourIncome.Header.Title')} />
        <LoadingState description={t('Content.Common.Placeholder.Loading')} />
      </LoanApplicationScreenContainer>
    );
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.YourIncome.GetIncomeError')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const requiredIncomeError: ErrorIncomes = [];
  // If there is no income, we do not show the error
  if (
    (incomes?.length ?? 0) > 0 &&
    shouldIncludeAddExpectedRentalIncome &&
    !checkExpectedRentalIncomeExisted(incomes)
  ) {
    requiredIncomeError.push({
      ...EXPECTED_RENTAL_INCOME_REQUIRED_ERROR_ITEM,
      formattedIncomeOwners: applicants
        .map(({ latest_full_name }) => latest_full_name)
        .join(', '),
    });
  }

  const needIncomeVerification =
    (enableEquifaxIncomeVerificationForPOC ||
      enablePrepopulateEmploymentIncome) &&
    userHasPassedKYC;
  const { primaryButtonLabel, footerCaption, testID, disablePrimaryButton } =
    getYourIncomeCaptionAndPrimaryButtonProps({
      incomes,
      needIncomeVerification,
      hasRequiredIncomeError: requiredIncomeError.length > 0,
    });

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.YourIncome.Header.Title')}
        caption={t('Content.YourIncome.Header.Caption')}
        captionTestId={TestID.LoanApplicationYourIncome.Caption}
      />
      <IncomeList
        incomes={incomes}
        errorIncomes={requiredIncomeError}
        onAddIncomeButtonPress={onAddIncomeButtonPress}
        onIncomeRowPress={onIncomeRowPress}
        onIncomeRowWithErrorPress={onIncomeRowWithErrorPress}
        showEquifaxConsent={needIncomeVerification}
      />
      <LoanApplicationWizardFooter
        onPrimaryButtonPress={onPrimaryButtonPress}
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonTestID={testID}
        footerCaption={footerCaption}
        disablePrimaryButton={disablePrimaryButton}
        isPrimaryButtonLoading={isPrimaryButtonLoading}
      />
    </LoanApplicationScreenContainer>
  );
}

type ErrorIncomes = Array<
  Pick<
    IncomeRowWithErrorProps,
    | 'type'
    | 'id'
    | 'message'
    | 'formattedIncomeOwners'
    | 'employmentIncome'
    | 'rentalIncome'
  >
>;
type IncomeListProps = Pick<
  ReturnType<typeof useYourFinancialsScreenQuery>,
  'incomes'
> & {
  errorIncomes?: ErrorIncomes;
  onAddIncomeButtonPress: () => void;
  onIncomeRowPress: IncomeRowProps['onPress'];
  onIncomeRowWithErrorPress?: () => void;
  showEquifaxConsent: boolean | undefined;
};

function IncomeList({
  incomes,
  errorIncomes,
  onIncomeRowPress,
  onIncomeRowWithErrorPress,
  onAddIncomeButtonPress,
  showEquifaxConsent,
}: IncomeListProps) {
  if (incomes == null || incomes.length < 1) {
    return <EmptyState showEquifaxConsent={showEquifaxConsent} />;
  }

  return (
    <Box px="l" my="l">
      {incomes?.map(
        (
          {
            employment_income: employmentIncome,
            rental_income: rentalIncome,
            id,
            amount,
            frequency,
            income_type: incomeType,
            income_owners: incomeOwners,
          },
          index,
        ) => (
          <IncomeRow
            key={id}
            id={id}
            amount={amount}
            frequency={frequency}
            employmentIncome={employmentIncome}
            rentalIncome={rentalIncome}
            type={incomeType}
            formattedIncomeOwners={getIncomeFinancialRowCaption(incomeOwners)}
            onPress={onIncomeRowPress}
            mt={0}
            mb={isLast(incomes, index) ? 0 : 's'}
          />
        ),
      )}
      {errorIncomes?.map((data) => (
        <IncomeRowWithError
          {...data}
          key={data.id}
          mt={incomes.length > 0 ? 's' : 0}
          mb={0}
          onPress={onIncomeRowWithErrorPress}
        />
      ))}

      <Button
        label={t('Content.YourIncome.ButtonLabel.AddMoreIncome')}
        icon="add"
        tertiary
        fontWeight="normal"
        testID={TestID.LoanApplicationYourIncome.AddIncomeButton}
        onPress={onAddIncomeButtonPress}
        mt="s"
      />
    </Box>
  );
}

function EmptyState({
  showEquifaxConsent,
}: Pick<IncomeListProps, 'showEquifaxConsent'>) {
  return (
    <>
      <Box flex={1} alignItems="center" my="xl">
        <EmptyStateIllustration
          name="incomeV2"
          testID={TestID.LoanApplicationYourIncome.EmptyState}
        />
      </Box>
      {showEquifaxConsent ? (
        <StyledText variant="caption" textAlign="center" mx="l">
          {t('Consent.EquifaxIncomeVerification.ConsentCaptionLinkPrefix')}
          <Link
            variant="caption"
            testID={
              TestID.LoanApplicationYourIncome.EquifaxIncomeVerification
                .ConsentCaptionLink
            }
            href={t('Link.UnloanEquifaxTermsOfService')}
          >
            {t('Consent.EquifaxIncomeVerification.ConsentCaptionLink')}
          </Link>
          {t('Consent.EquifaxIncomeVerification.ConsentCaptionLinkPostfix')}
        </StyledText>
      ) : null}
    </>
  );
}

export const LoanApplicationYourIncomeV2 = withAuthenticationRequired(
  LoanApplicationYourIncomeBaseV2,
);
