import { Linking } from 'react-native';

import { Alert } from '../ui/atoms/Alert';

export async function openUrl(url: string) {
  const canOpen = await Linking.canOpenURL(url);
  if (canOpen) {
    await Linking.openURL(url);
  } else {
    Alert.alert(t('Content.Common.Error.UnsupportedUrl', { url }));
  }
}
