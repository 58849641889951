import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function StatementsIcon({ size, color, ...otherProps }: SvgIconProps) {
  const width = resizeIconWidth(24, 27, size);
  const theme = useTheme();
  const actualColor = color ?? theme.colors.accent;

  return (
    <Svg
      width={width}
      height={size}
      fill="none"
      viewBox="0 0 24 27"
      {...otherProps}
    >
      <Path
        d="M7.677 6h4.045c.376 0 .655.29.655.656 0 .375-.28.666-.655.666H7.677A.658.658 0 0 1 7 6.656C7 6.29 7.29 6 7.677 6ZM7.677 9.5h4.045c.376 0 .655.29.655.655 0 .377-.28.667-.655.667H7.677A.658.658 0 0 1 7 10.155c0-.366.29-.656.677-.656ZM7.677 13.25h8.668c.376 0 .655.3.655.676 0 .365-.28.645-.655.645H7.677c-.387 0-.677-.28-.677-.645 0-.376.29-.677.677-.677ZM7.677 16.5h8.668c.376 0 .655.301.655.677 0 .365-.28.645-.655.645H7.677c-.387 0-.677-.28-.677-.645 0-.376.29-.677.677-.677ZM7.677 20h6.168c.376 0 .655.301.655.677 0 .365-.28.645-.655.645H7.677c-.387 0-.677-.28-.677-.645 0-.376.29-.677.677-.677Z"
        fill={actualColor}
      />
      <Path
        d="M5.777 26.102h12.446c2.425 0 3.633-1.23 3.633-3.668v-10.64c0-1.513-.176-2.169-1.114-3.13L14.285 2.09c-.89-.914-1.629-1.113-2.941-1.113H5.777c-2.414 0-3.632 1.242-3.632 3.68v17.777c0 2.45 1.207 3.668 3.632 3.668Zm.082-1.887c-1.207 0-1.828-.644-1.828-1.816V4.692c0-1.16.621-1.828 1.84-1.828h5.215v6.808c0 1.477.738 2.203 2.203 2.203h6.68V22.4c0 1.172-.621 1.816-1.84 1.816H5.859Zm7.641-14.11c-.469 0-.645-.187-.645-.656V3.227l6.75 6.879H13.5Z"
        fill={actualColor}
      />
    </Svg>
  );
}
