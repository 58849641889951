import { Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { sleep } from '../../utils/promiseHelpers';
import { Fastlink } from '../components/Fastlink';
import { SelectInstitutionScreenProps } from '../navigation/types';
import { fastlinkEmitter } from '../utils/fastlinkEmitter';
import { FastlinkFlow } from '../utils/fastlinkWebTypes';

type Props = SelectInstitutionScreenProps<Screen.MANAGE_CONSENTS>;

const FastlinkConfig = {
  flow: FastlinkFlow.manageConsent,
};

const MANAGE_CONSENT_UPDATE_DELAY = 2000;

export function ManageConsent({ navigation, route }: Props) {
  return (
    <ScreenContainer safeAreaBottom={false}>
      <Fastlink
        flow={FastlinkConfig.flow}
        providerAccountId={route.params.providerAccountId}
        onSuccess={async ({ requestId, providerAccountId }) => {
          // We need to wait for 2s to cater for update delay on the remote schema
          await sleep(MANAGE_CONSENT_UPDATE_DELAY);
          fastlinkEmitter.emit('completed', {
            requestId,
            institutionConnectionId: Number(providerAccountId),
          });
        }}
        onClose={navigation.goBack}
        errorContext={FastlinkConfig}
      />
    </ScreenContainer>
  );
}
