import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { BorrowerListItem_BorrowerFragment } from '../../generated/graphql';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { isLast } from '../../utils/arrayHelpers';
import { BorrowerListItem } from './BorrowerListItem';

export const MAX_BORROWERS = 2;

type Props = BoxProps & {
  borrowers: Array<BorrowerListItem_BorrowerFragment> | undefined;
  onAddBorrower: () => void;
  onBorrowerPress: (data: BorrowerListItem_BorrowerFragment) => void;
  maxBorrowers?: number;
};

export const BorrowerList: React.FC<Props> = ({
  borrowers,
  onBorrowerPress,
  onAddBorrower,
  maxBorrowers = MAX_BORROWERS,
  ...props
}) => {
  if (borrowers == null) {
    return null;
  }
  const displayAddBorrowerButton = borrowers.length < maxBorrowers;
  return (
    <Box flex={1} alignSelf="stretch" {...props}>
      {borrowers?.map((borrowerData, index) => (
        <BorrowerListItem
          {...borrowerData}
          onBorrowerPress={onBorrowerPress}
          key={borrowerData.id}
          last={!displayAddBorrowerButton && isLast(borrowers, index)}
          testIDPrefix={TestID.LoanApplicationBorrowers.BorrowerItem}
        />
      ))}

      {displayAddBorrowerButton ? (
        <Button
          label={t(
            'Content.LoanApplicationBorrowers.ButtonLabel.InviteCoborrower',
          )}
          icon="add"
          tertiary
          testID={TestID.BorrowerList.AddAnotherBorrowerButton}
          onPress={onAddBorrower}
        />
      ) : null}
    </Box>
  );
};
