import { SxProp, Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { SvgIcon } from '../../ui/svgs/SvgIcon';
import { useTheme } from '../../ui/theme';
import { makeTestId } from '../../utils/stringHelpers';
import { ExpensesTypesField } from '../utils/types';

type ExpenseWarningRowProps = {
  warningText: string;
  expenseInputName: ExpensesTypesField;
  sx?: SxProp;
};

export function ExpenseWarningRow({
  warningText,
  expenseInputName,
  sx = {},
}: ExpenseWarningRowProps) {
  const theme = useTheme();
  return (
    <View
      sx={{
        flexDirection: 'row',
        alignItems: 'start',
        ...sx,
      }}
      testID={makeTestId([TestID.ExpensesForm.WarningRow, expenseInputName])}
    >
      <SvgIcon
        name="warningIcon"
        color={theme.colors.warning}
        size={16}
        style={{ marginRight: 8, flexShrink: 0 }}
      />
      <Text variant="caption" sx={{ color: '$labelsWarning' }}>
        {warningText}
      </Text>
    </View>
  );
}
