import {
  addYears,
  differenceInCalendarDays,
  format,
  getDaysInYear,
  isBefore,
  subYears,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useMemo } from 'react';

import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import {
  AustralianTimezones,
  DATE_INPUT_FORMAT,
} from '../../utils/dateHelpers';
import { formatInterestRate } from '../../utils/stringHelpers';

const ANNIVERSARY_PERIOD_RANGE = 14;
const MAX_PERCENTAGE_DAYS_REMAINING = 1 - 1 / 365; // make the gradient circle UI looks better with at least 1 day passes
export const ANNUAL_DISCOUNT_BUMP = -0.01;

export const useDiscountBumpInsight = (cbaAccountId: string) => {
  const { data: loan, loading: isLoadingLoanAccount } =
    useLoanAccountState(cbaAccountId);

  const result = useMemo(() => {
    const today = new Date();
    const daysInYear = getDaysInYear(today);

    const currentInterestRate = formatInterestRate(
      loan?.settings?.interest_rate ?? 0,
    );
    const currentInterestRateDiscount = formatInterestRate(
      Math.abs(loan?.settings?.interest_rate_discount ?? 0),
    );
    const nextInterestRateDiscount = formatInterestRate(
      Math.abs(
        (loan?.settings?.interest_rate_discount ?? 0) + ANNUAL_DISCOUNT_BUMP,
      ),
    );

    const loanStartDate = loan?.settings?.init_start_date || '';
    const isLoanAccountWithinFirstYear = loanStartDate
      ? isBefore(
          today,
          addYears(
            zonedTimeToUtc(loanStartDate, AustralianTimezones.SYDNEY),
            1,
          ),
        )
      : false;
    const nextAnniversaryDate = loan?.settings?.loan_anniversary_date || '';

    const nextAnniversaryDateInUTC = nextAnniversaryDate
      ? zonedTimeToUtc(nextAnniversaryDate, AustralianTimezones.SYDNEY)
      : today;

    const passedAnniversaryDateInUTC = subYears(nextAnniversaryDateInUTC, 1);
    const passedAnniversaryDate = format(
      utcToZonedTime(passedAnniversaryDateInUTC, AustralianTimezones.SYDNEY),
      DATE_INPUT_FORMAT,
    );

    const discountBumpInDays = differenceInCalendarDays(
      nextAnniversaryDateInUTC,
      today,
    );

    const isAnniversaryPeriod =
      !isLoanAccountWithinFirstYear &&
      (discountBumpInDays >= daysInYear - ANNIVERSARY_PERIOD_RANGE ||
        discountBumpInDays <= 0);

    return {
      nextAnniversaryDate,
      passedAnniversaryDate,
      discountBumpInDays,
      currentInterestRate,
      currentInterestRateDiscount,
      nextInterestRateDiscount,
      percentageDaysRemaining: isAnniversaryPeriod
        ? 0
        : Math.min(
            discountBumpInDays / daysInYear,
            MAX_PERCENTAGE_DAYS_REMAINING,
          ),
      isAnniversaryPeriod,
    };
  }, [loan?.settings]);

  return {
    ...result,
    loading: isLoadingLoanAccount,
  };
};
