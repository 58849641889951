import { createStackNavigator } from '@react-navigation/stack';

import { OutOfServiceScreen } from '../OutOfService';
import { DefaultModalV2NavigatorOptions } from '../utils/NavConstants';

export enum NavigatorFullScreenModalScreen {
  OutOfService = 'OutOfService',
}

export type NavigatorFullScreenModalStackParamList = {
  [NavigatorFullScreenModalScreen.OutOfService]: undefined;
};

const ModalStack =
  createStackNavigator<NavigatorFullScreenModalStackParamList>();

export const NavigatorFullScreenModal = () => (
  <ModalStack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
    <ModalStack.Screen
      name={NavigatorFullScreenModalScreen.OutOfService}
      component={OutOfServiceScreen}
    />
  </ModalStack.Navigator>
);
