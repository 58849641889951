import {
  FontAwesome,
  FontAwesome5,
  Ionicons,
  SimpleLineIcons,
} from '@expo/vector-icons';
import {
  color,
  createRestyleComponent,
  createRestyleFunction,
  layout,
  opacity,
  spacingShorthand,
  useTheme,
} from '@shopify/restyle';
import useDimensions from '@shopify/restyle/dist/hooks/useDimensions';
import * as React from 'react';
import { Platform, TextProps } from 'react-native';

import { GoogleIcon } from '../svgs/GoogleIcon';
import { SvgIcon } from '../svgs/SvgIcon';
import { Theme } from '../theme';
import {
  ColorProps,
  LayoutProps,
  OpacityProps,
  Size,
  SpacingProps,
} from '../types';
import { Box, BoxProps } from './Box';

type SizingProps = {
  size?: Size;
};

type BooleanProps = {
  fixedHeight?: boolean;
  fixedWidth?: boolean;
  roundBg?: boolean;
};

type StyledIconProps = SpacingProps &
  LayoutProps &
  ColorProps &
  SizingProps &
  BooleanProps &
  OpacityProps & { name: string };

export const iconSize = createRestyleFunction({
  property: 'size',
  themeKey: 'sizes',
});

const restyleFunctions = [color, spacingShorthand, layout, opacity];

export const iconFamilies = {
  ionicons: createRestyleComponent<StyledIconProps, Theme>(
    restyleFunctions,
    Ionicons,
  ),
  simple: createRestyleComponent<StyledIconProps, Theme>(
    restyleFunctions,
    SimpleLineIcons,
  ),
  fontAwesome: createRestyleComponent<StyledIconProps, Theme>(
    restyleFunctions,
    FontAwesome,
  ),
  fontAwesome5: createRestyleComponent<StyledIconProps, Theme>(
    restyleFunctions,
    FontAwesome5,
  ),
  svg: createRestyleComponent<StyledIconProps, Theme>(
    restyleFunctions,
    SvgIcon,
  ),
};

export type IconFamilyName = keyof typeof iconFamilies;

export type MultiplatformIconName = {
  ios: string;
  android: string;
};

export type Props = StyledIconProps &
  Omit<TextProps, keyof StyledIconProps> & {
    name: string | MultiplatformIconName;
    family?: IconFamilyName;
    outline?: boolean;
    bg?: BoxProps['bg'];
  };

export function getIoniconsIconName(
  name: string | MultiplatformIconName,
  outline: boolean,
): string {
  if (typeof name === 'object') {
    return flattenMultiplatformName(name);
  }

  const iconName: string[] = [name];

  if (
    !name.startsWith('ios-') &&
    !name.startsWith('md-') &&
    !name.startsWith('logo-')
  ) {
    if (Platform.OS === 'ios') {
      iconName.unshift('ios');
      if (outline) iconName.push('outline');
    } else {
      iconName.unshift('md');
    }
  }
  return iconName.join('-');
}

export function getIconName(name: string | MultiplatformIconName): string {
  if (typeof name === 'object') {
    return flattenMultiplatformName(name);
  }
  return name;
}

function flattenMultiplatformName(name: MultiplatformIconName): string {
  if (Platform.OS === 'ios') return name.ios;
  return name.android;
}

const BaseStyledIcon: React.FC<Props> = ({
  family = 'ionicons',
  outline,
  name,
  fixedWidth,
  fixedHeight,
  roundBg,
  size = 'm',
  bg = 'accentBg',
  ...props
}) => {
  const theme = useTheme<Theme>();

  const dimensions = useDimensions();

  const { size: calculatedSize } = iconSize.func(
    { ...props, size },
    { theme, dimensions },
  );

  if (name === 'svg-google') {
    return <GoogleIcon size={calculatedSize} />;
  }

  const StyledIconInstance = iconFamilies[family];

  const fullName =
    family === 'ionicons'
      ? getIoniconsIconName(name, outline || false)
      : getIconName(name);

  const iconInstance = (
    <StyledIconInstance
      name={fullName}
      style={{
        ...(fixedHeight && { height: calculatedSize }),
        ...(fixedWidth && { width: calculatedSize, textAlign: 'center' }),
      }}
      {...props}
      size={calculatedSize}
    />
  );

  return roundBg ? (
    <Box p="xs" borderRadius="rounded" bg={bg}>
      {iconInstance}
    </Box>
  ) : (
    iconInstance
  );
};

export const StyledIcon = React.memo<Props>(BaseStyledIcon);
