import { createStackNavigator } from '@react-navigation/stack';

import { ConditionalApprovalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { ApplicationDetails } from '../screens/ApplicationDetails';
import { VerifyYourFinancials } from '../screens/VerifyYourFinancials';
import { YourBorrowingPowerDetails } from '../screens/YourBorrowingPowerDetails';
import { YourConditionalApprovalDetails } from '../screens/YourConditionalApprovalDetails';

const ConditionalApprovalStack =
  createStackNavigator<ConditionalApprovalStackParams>();
export function ConditionalApprovalNavigator() {
  return (
    <ConditionalApprovalStack.Navigator
      screenOptions={DefaultModalV2NavigatorOptions}
    >
      <ConditionalApprovalStack.Screen
        name={Screen.CONDITIONAL_APPROVAL_APP_DETAILS}
        component={ApplicationDetails}
        options={{
          title: getPageTitle(Screen.CONDITIONAL_APPROVAL_APP_DETAILS),
        }}
      />
      <ConditionalApprovalStack.Screen
        name={Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS}
        component={YourBorrowingPowerDetails}
        options={{
          title: getPageTitle(
            Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS,
          ),
        }}
      />
      <ConditionalApprovalStack.Screen
        name={Screen.CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS}
        component={YourConditionalApprovalDetails}
        options={{
          title: getPageTitle(
            Screen.CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS,
          ),
        }}
      />

      <ConditionalApprovalStack.Screen
        name={Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS}
        component={VerifyYourFinancials}
        options={{
          title: getPageTitle(
            Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS,
          ),
        }}
      />
    </ConditionalApprovalStack.Navigator>
  );
}
