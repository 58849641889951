import { formatInTimeZone } from 'date-fns-tz';
import { A, Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Direct_Debit_Failure_Type_Enum } from '../../generated/graphql';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../ui/v2/List';
import { usePaymentFailureDetails } from '../hooks/usePaymentFailureDetails';

export type Props = ReturnType<
  typeof usePaymentFailureDetails
>['directDebitFailureDetails'] & {
  openAutoPaySettings: () => void;
};

const TEXT_PREFIX = 'Content.PaymentFailureModal.DirectDebitFailure';

const GetLoanBackOnTrackListItem = ({
  indexValue,
  subText,
  title,
}: {
  indexValue: number;
  subText?: string;
  title: string;
}) => (
  <ListItem
    sx={{ py: '$16', mx: '$16' }}
    title={
      <View style={{ flexDirection: 'row' }}>
        <Text sx={{ mr: '$8' }} variant="xsHeader">
          {indexValue}.
        </Text>
        <View style={{ flex: 1 }}>
          <Text variant="xsHeader">{title}</Text>
          {subText ? (
            <Text sx={{ mt: '$4' }} variant="sBody">
              {subText}
            </Text>
          ) : undefined}
        </View>
      </View>
    }
  />
);

const GetLoanBackOnTrackDetails = ({
  failureType,
  nextDirectDebitDate,
}: Pick<Props, 'nextDirectDebitDate' | 'failureType'>) => (
  <CardList sx={{ mb: 0 }}>
    <GetLoanBackOnTrackListItem
      indexValue={1}
      title={t(`${TEXT_PREFIX}.GetLoanBackOnTrack.CheckRedraw.Title`)}
      subText={t(`${TEXT_PREFIX}.GetLoanBackOnTrack.CheckRedraw.Description`, {
        retryDate: formatInTimeZone(
          nextDirectDebitDate,
          'Australia/Sydney',
          'dd/MM/yyyy',
        ),
      })}
    />

    {failureType === Direct_Debit_Failure_Type_Enum.BusinessFailure ? (
      <View>
        <Divider />
        <GetLoanBackOnTrackListItem
          indexValue={2}
          title={t(`${TEXT_PREFIX}.GetLoanBackOnTrack.InsufficientFunds`)}
        />
        <Divider />
        <GetLoanBackOnTrackListItem
          indexValue={3}
          title={t(`${TEXT_PREFIX}.GetLoanBackOnTrack.InactiveAccount.Title`)}
          subText={t(
            `${TEXT_PREFIX}.GetLoanBackOnTrack.InactiveAccount.Description`,
          )}
        />
      </View>
    ) : null}
  </CardList>
);

export const DirectDebitFailureDetails = ({
  loanAccountName,
  openAutoPaySettings,
  ...props
}: Props) => (
  <View testID={TestID.PaymentFailure.DirectDebitFailureDetails}>
    <Text sx={{ paddingVertical: '$8' }} variant="header">
      {t(`${TEXT_PREFIX}.Title`)}
    </Text>

    {/* Get Loan Back on Track Section */}
    <Text sx={{ mt: '$16', paddingVertical: '$8' }} variant="sBody">
      {t(`${TEXT_PREFIX}.GetLoanBackOnTrack.Title`, {
        loanAccountName,
      })}
    </Text>
    <GetLoanBackOnTrackDetails {...props} />

    {/* Autopay Settings Section */}
    <View sx={{ paddingVertical: '$8', gap: '$8' }}>
      <Text variant="caption">
        {t(`${TEXT_PREFIX}.AutoPaySettings.MakeChanges`)}
        <A
          variant="link"
          onPress={openAutoPaySettings}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t(`${TEXT_PREFIX}.AutoPaySettings.AutoPaySettings`)}.
        </A>
      </Text>

      {/* Service Agreement Section */}
      <Text variant="caption">
        {t(`${TEXT_PREFIX}.ServiceAgreement.Part1`)}
        <A
          variant="link"
          href={t('Link.DirectDebitTerms')}
          hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {t(`${TEXT_PREFIX}.ServiceAgreement.DirectDebitsServiceAgreement`)}
        </A>
        {t(`${TEXT_PREFIX}.ServiceAgreement.Part2`)}
      </Text>
    </View>
  </View>
);
