import { Optional, Record, Static, String } from 'runtypes';

import { InstitutionConnection } from '../../generated/graphql';
import { pluralize } from '../../utils/stringHelpers';

const Name = Record({
  first: Optional(String),
  fullName: Optional(String),
});
const Profile = Record({
  name: Name,
});

export function getInstitutionConnectionName(
  maybeProfiles: InstitutionConnection['profiles'],
) {
  const profiles = maybeProfiles?.filter((item) =>
    Profile.guard(item),
  ) as Array<Static<typeof Profile>>;

  if (profiles.length === 0) {
    return '--';
  }

  if (profiles.length <= 2) {
    return profiles
      .map(({ name: { first, fullName } }) => {
        if (profiles.length === 2) {
          return first;
        }
        return fullName;
      })
      .join(' & ');
  }
  return pluralize(profiles.length, 'other');
}
