import * as React from 'react';

import { Props as InputFieldProps } from '../../ui/organisms/InputField';
import { CollapsibleSelection } from './CollapsibleSelection';
import { PickerTrigger } from './PickerTrigger';
import { Props as SelectionRowGroupProps } from './SelectionRowGroup';

export type Props = Omit<InputFieldProps, 'value' | 'noBorder' | 'label'> &
  SelectionRowGroupProps<string> & {
    collapsed?: boolean;
    name?: string;
    isLoading?: boolean;
    label?: string;
    /** Append unit suffix when user select more than 2 options (only applicable when `isMultiselect` set to `true`) */
    unit?: string;
    disabled?: boolean;
    onSelectionItemPress?: (value: string) => void;
    valueTestId?: string;
  };

export const CollapsibleSelectionRow: React.FC<Props> = ({
  options,
  label,
  last: lastProp,
  isLoading = false,
  disabled = false,
  groupTestId,
  placeholder: propsPlaceholder,
  onSelectionItemPress,
  valueTestId,
  ...otherProps
}) => {
  let placeholder: string | undefined = propsPlaceholder;
  const noOptionsAvailable = options.length === 0;

  if (isLoading) {
    placeholder = t('Content.Common.Placeholder.Loading');
  }

  if (noOptionsAvailable) {
    placeholder = t('Content.Common.Placeholder.NoAvailableOptions');
  }

  return (
    <CollapsibleSelection
      groupTestId={groupTestId}
      options={options}
      last={lastProp}
      onSelectionItemPress={onSelectionItemPress}
      {...otherProps}
    >
      {({ isPickerOpened, onPress, selectedLabel }) => {
        const isLast = isPickerOpened ? false : lastProp;
        const pickerTriggerTestId = otherProps.testID || groupTestId;
        return (
          <PickerTrigger
            {...otherProps}
            last={isLast}
            /**
             * Needed to fix bug where
             * if the container have style overflow: 'visible'
             * the component won't have rounded edge
             */
            {...(isLast ? { borderRadius: 'field' } : {})}
            value={selectedLabel}
            label={label}
            testID={pickerTriggerTestId}
            placeholder={placeholder}
            placeholderTestId={valueTestId}
            onPress={
              isLoading || noOptionsAvailable || disabled ? undefined : onPress
            }
            disabled={noOptionsAvailable}
            labelNumberOfLines={1}
            placeholderContainerMaxWidth={150}
          />
        );
      }}
    </CollapsibleSelection>
  );
};
