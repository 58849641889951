import { memo } from 'react';

import { StyledText } from '../../ui/atoms/StyledText';

type Props = {
  fileName: string;
};

export const NoPreview = memo(({ fileName }: Props) => (
  <StyledText variant="body">
    {t('Content.DocumentPreview.NoPreview', { documentName: fileName })}
  </StyledText>
));
