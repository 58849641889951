import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { PendingTransaction } from '../../Home/screens/PendingTransaction';
import { HomeLoanStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { NextRepayment } from '../screens/NextRepayment';
import { Transaction } from '../screens/Transaction';
import { HomeLoanWithdrawal } from '../screens/Withdraw';
import { WithdrawAddAccount } from '../screens/WithdrawAddAccount';
import { WithdrawTo } from '../screens/WithdrawTo';
import { WithdrawV2 } from '../screens/WithdrawV2';

const HomeLoanStack = createStackNavigator<HomeLoanStackParams>();

export const HomeLoanNavigator: React.FC = () => (
  <HomeLoanStack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
    <HomeLoanStack.Screen
      name={Screen.HOME_LOAN_WITHDRAWAL}
      component={HomeLoanWithdrawal}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_WITHDRAWAL),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_LOAN_WITHDRAWAL_TO}
      component={WithdrawTo}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_WITHDRAWAL_TO),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT}
      component={WithdrawAddAccount}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_LOAN_WITHDRAWAL_V2}
      component={WithdrawV2}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_WITHDRAWAL_V2),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_PENDING_TRANSACTION}
      component={PendingTransaction}
      options={{
        title: getPageTitle(Screen.HOME_PENDING_TRANSACTION),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_PROCESSED_TRANSACTION}
      component={Transaction}
      options={{
        title: getPageTitle(Screen.HOME_PROCESSED_TRANSACTION),
      }}
    />
    <HomeLoanStack.Screen
      name={Screen.HOME_NEXT_REPAYMENT}
      component={NextRepayment}
      options={{
        title: getPageTitle(Screen.HOME_NEXT_REPAYMENT),
      }}
    />
  </HomeLoanStack.Navigator>
);
