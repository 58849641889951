import { captureException } from '../sentry';

export enum BioCatchError {
  InitFailed = 'Initialization failed',
  LogoutFailed = 'Failed to handle Logout',
  SessionTimeoutFailed = 'Failed to handle Session Timeout',
  UnexpectedPlatform = 'Unexpected Platform',
  StartNewSessionFailed = 'Start New Session Failed',
}

const formatForSentry = (error: BioCatchError) => `BioCatch Error: ${error}`;

export function captureBiocatchException(
  error: BioCatchError,
  customerSessionId: string | undefined,
  cause: Error | string,
) {
  const description = formatForSentry(error);

  const context = {
    customerSessionId,
  };

  captureException(
    description,
    context,
    cause instanceof Error ? cause : new Error(cause),
  );
}
