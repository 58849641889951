import { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { MaxCharacter } from '../../constants/fieldRules';
import {
  Props as StyledTextInputProps,
  StyledTextInput,
} from '../atoms/StyledTextInput';
import { useTheme } from '../theme';
import { InputRow, Props as InputRowProps } from './InputRow';

// TextInputRow is <TextInput> wrapped with a <Row>.
// Commonly used <TextInput> props can be specified here
// so the props can be specified directly on top level props.
// Otherwise, <TextInput> props will need to be specified
// in the textInputProps like:
// <TextInputRow value="abc" textInputProps={{ color: 'green' }} {...otherRowProps} />
const DirectTextInputPropsKeys = [
  'value',
  'onChangeText',
  'placeholder',
  'testID',
  'autoFocus',
  'returnKeyType',
  'textAlign',
  'editable',
] as const;

type DirectPropsKeys = (typeof DirectTextInputPropsKeys)[number];
type PropsForStyledTextInput = Pick<StyledTextInputProps, DirectPropsKeys> & {
  textInputProps?: Omit<
    StyledTextInputProps,
    DirectPropsKeys | 'type' | 'autoCapitalize'
  >;
  autoCapitalise?: 'word' | 'sentence';
  alphaNumericOnly?: boolean;
  escapeEmoji?: boolean;
  escapeCharsBy?: RegExp;
};

export type Props = Omit<InputRowProps, 'ref'> &
  PropsForStyledTextInput & {
    name?: string;
  };

export function splitTextInputRowProps({ textInputProps, ...rawProps }: Props) {
  const props = { ...rawProps };
  const directProps = DirectTextInputPropsKeys.reduce((direct, current) => {
    if (current in props) {
      // @ts-expect-error TS does not know here we copy these specific key value pair over to a new object.
      direct[current] = props[current]; // eslint-disable-line no-param-reassign
    }
    delete props[current];
    return direct;
  }, {} as Pick<StyledTextInputProps, DirectPropsKeys>);

  return {
    textInputProps: { ...directProps, ...textInputProps },
    otherRowProps: props as Omit<Props, DirectPropsKeys>,
  };
}

export const TextInputRow = forwardRef<RNTextInput, Omit<Props, 'multiline'>>(
  (props, ref) => {
    const { textInputProps, otherRowProps } = splitTextInputRowProps(props);
    const { name, ...inputRowProps } = otherRowProps;
    return (
      <InputRow fixedLabel {...inputRowProps}>
        <StyledTextInput
          autoCorrect={false}
          {...textInputProps}
          ref={ref}
          testID={textInputProps.testID || `textInput-${name}`}
        />
      </InputRow>
    );
  },
);

export const MultilineTextInputRow = forwardRef<RNTextInput, Props>(
  (props, ref) => {
    const theme = useTheme();
    const { textInputProps, otherRowProps } = splitTextInputRowProps(props);
    const { name, ...inputRowProps } = otherRowProps;
    return (
      <InputRow fixedLabel {...inputRowProps}>
        <StyledTextInput
          autoCorrect={false}
          height={theme.constants.maxMultilineTextInputHeight}
          multiline
          {...textInputProps}
          maxLength={
            textInputProps.maxLength || MaxCharacter.multilineTextInput
          }
          ref={ref}
        />
      </InputRow>
    );
  },
);
