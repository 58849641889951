import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { useGetAppLdUserContextLazyQuery } from '../generated/graphql';
import { getCookie } from '../utils/cookies';

// this cookie is set on the marketing site (www.unloan.com.au) and is
// used as the randomisation unit in marketing experiments. we use it here
// to ensure that metrics triggered by unloan-app can be attributed to experiments
// ran on the marketing site.
export const LD_ANONYMOUS_USER_ID_COOKIE_NAME = 'anonymous_user_id';

export function shouldUseAnonymousUserId(): boolean {
  return getCookie(LD_ANONYMOUS_USER_ID_COOKIE_NAME) !== null;
}

export function getAnonymousUserIdContext() {
  const anonymousUserId = getCookie(LD_ANONYMOUS_USER_ID_COOKIE_NAME);

  if (!anonymousUserId) {
    return undefined;
  }

  return {
    anonymous_user_id: {
      kind: 'anonymous_user_id',
      key: anonymousUserId,
    },
  };
}

export const LD_CONTEXT_DOC = gql`
  query GetAppLdUserContext {
    get_app_ld_user_context {
      user_context {
        kind
        key
        email_hash
        _meta {
          privateAttributes
        }
      }
    }
  }
`;

export function useFetchLdUserContext() {
  const [fetchLdUserContext] = useGetAppLdUserContextLazyQuery({
    fetchPolicy: 'network-only',
    context: {
      sentryContext: {},
    },
  });

  return useCallback(async () => {
    try {
      const res = await fetchLdUserContext();
      return res.data?.get_app_ld_user_context?.user_context;
    } catch {
      return null;
    }
  }, [fetchLdUserContext]);
}
