import { createContext, useContext } from 'react';

export type AuthContextValue = {
  isAuthenticated: boolean;
  isLoggedIn?: boolean;
  isLoading: boolean;
  shouldShowEnableFaceId: boolean;
  getAccessToken: () => Promise<string | null>;
  logout: () => void;
  login: (opts: { inviteCode?: string; returnTo?: string }) => Promise<void>;
  setShouldShowEnableFaceId?: (value: boolean) => void;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

export function useAuthContext(): AuthContextValue | null {
  return useContext(AuthContext);
}
