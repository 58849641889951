import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function ConditionalApprovalPurchaseIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08972 16.5V30.75H31.6621V16.5L18.3759 3.75L5.08972 16.5ZM5.08972 33.0001V30.7501H31.6621V33.0001C31.6621 33.7614 31.1047 33.7569 30.3818 33.7511C30.3177 33.7506 30.2523 33.7501 30.1859 33.7501H6.56597C6.49954 33.7501 6.43415 33.7506 6.37006 33.7511C5.64712 33.7569 5.08972 33.7614 5.08972 33.0001Z"
        fill="url(#paint_gradient)"
      />
      <Path
        opacity="0.05"
        d="M18.3747 4.5C18.0248 4.5 17.675 4.626 17.396 4.87725L13.4404 8.4855L5.0885 16.5V20.5425L18.3747 8.571L31.6609 20.5425V16.5L23.309 8.4855L19.3535 4.87725C19.0744 4.626 18.7246 4.5 18.3747 4.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33.5028 16.1302L19.3538 3.37725C19.0747 3.126 18.7249 3 18.375 3C18.0251 3 17.6753 3.126 17.3962 3.37725L3.24718 16.1302C2.93717 16.4108 2.91577 16.8953 3.19994 17.2028L4.40751 18.5115C4.67988 18.807 5.13382 18.8295 5.4335 18.5618L18.375 7.071L31.3165 18.5625C31.6154 18.8295 32.0701 18.8078 32.3425 18.5123L33.5501 17.2035C33.8342 16.8952 33.8128 16.4108 33.5028 16.1302Z"
        fill="#A3A3A3"
      />
      <Path
        d="M14.0026 27.6236C14.2235 27.3991 14.5252 27.3107 14.8128 27.3512L15.7043 26.4453C15.6368 26.1862 15.7 25.8989 15.9003 25.6954C16.1003 25.4921 16.383 25.4279 16.6384 25.4962L17.5264 24.594L15.3579 22.3906L11.2457 26.569C11.1018 26.7153 11.0213 26.9133 11.0213 27.1198L11.021 27.899L12.0074 28.9012L12.8352 28.9707C13.0599 28.9897 13.2813 28.9073 13.4407 28.7454L13.7346 28.4468C13.6947 28.1546 13.7817 27.848 14.0026 27.6236Z"
        fill="#2C2C2C"
      />
      <Path
        d="M23.4911 23.4918C25.4373 21.5143 25.4373 18.3084 23.4911 16.3309C21.545 14.3534 18.3899 14.3534 16.4437 16.3309C16.352 16.424 16.2691 16.5221 16.1864 16.6204L16.1726 16.6064L14.0042 18.8097C13.4054 19.4181 13.4054 20.4047 14.0042 21.0131L18.8832 25.9706C19.4819 26.579 20.4529 26.579 21.0516 25.9706L23.2201 23.7672L23.2063 23.7532C23.303 23.6692 23.3995 23.5849 23.4911 23.4918ZM22.1359 17.708C22.7346 18.3164 22.7346 19.303 22.1359 19.9114C21.5371 20.5198 20.5662 20.5198 19.9674 19.9114C19.3686 19.303 19.3686 18.3164 19.9674 17.708C20.5662 17.0996 21.5371 17.0996 22.1359 17.708Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id="paint_gradient"
          x1="5.08972"
          y1="3.75"
          x2="34.8721"
          y2="30.1278"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
