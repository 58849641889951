import { ReactNode } from 'react';
import { Image, StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { StyledText } from '../../ui/atoms/StyledText';
import { useIsTablet } from '../../utils/hooks/useBreakpoint';

type Props = {
  onJoinApplication?: () => void;
  onJoinApplicationLoading?: boolean;
  onLogout?: () => void;
  onLogoutLoading?: boolean;
  title: string;
  message: string;
  children?: ReactNode;
};

export function JoinApplicationContent({
  onJoinApplication,
  onLogout,
  onLogoutLoading,
  title,
  message,
  onJoinApplicationLoading,
  children,
}: Props) {
  const isTablet = useIsTablet();
  return (
    <ScrollContainer bg="bg" contentContainerStyle={styles.contentContainer}>
      <NavHeaderSpacer />
      <Box contentContainer alignSelf="center" px="m">
        <StyledText variant="headerLarge" textAlign="center" my="s">
          {title}
        </StyledText>
        <StyledText mt="s" textAlign="center" alignSelf="center">
          {message}
        </StyledText>
        {onJoinApplication ? (
          <Button
            label={t('Content.JoinApplication.Accept')}
            onPress={onJoinApplication}
            width="70%"
            mt="xl"
            alignSelf="center"
            showSpinner={onJoinApplicationLoading}
            disabled={onJoinApplicationLoading}
            testID={TestID.JoinApplicationContent.Button}
          />
        ) : null}
        {onLogout ? (
          <Button
            label={t('Content.JoinApplication.LogOut')}
            onPress={onLogout}
            width="30%"
            mt="xl"
            alignSelf="center"
            showSpinner={onLogoutLoading}
            disabled={onLogoutLoading}
            testID={TestID.JoinApplicationContent.LogoutButton}
          />
        ) : null}
      </Box>
      <Image
        source={require('../../../assets/hero-image-652x421.png')}
        style={styles.backgroundImage}
        resizeMode={isTablet ? 'center' : 'cover'}
        testID={TestID.JoinApplicationContent.Image}
      />

      {children}
    </ScrollContainer>
  );
}

const styles = StyleSheet.create({
  contentContainer: { flexGrow: 1 },
  backgroundImage: {
    width: '100%',
    height: '50%',
    maxWidth: '100%',
  },
});
