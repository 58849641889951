import { FieldInputProps, FieldMetaProps, FormikBag } from 'formik';
import { TextInput } from 'react-native';

import {
  Applicant_Role_Enum,
  Country_Enum,
  Gender_Enum,
  State_Enum,
} from '../../generated/graphql';
import {
  BorrowerDetailsField,
  BorrowerDriversLicenceField,
  BorrowerForm,
  BorrowerIdentityDetailsField,
  BorrowerIdentityDetailsFormValues,
  BorrowerPassportField,
} from '../../Identification/utils/borrowerFormUtil';
import { SpecificRequired } from '../../utils/typesHelpers';
import { yup } from '../../utils/yup';

export type FocusingInputRef = Pick<TextInput, 'focus' | 'blur'>;

export type FormikControlProps<T> = {
  error?: T extends { [key in keyof T]: unknown }
    ? { [key in keyof T]: string }
    : string | null;
  value?: T | undefined;
  setFieldValue?(value: T): void;
  setFieldTouched?(isTouched?: boolean, validate?: boolean): void;
  name?: string;
  touched?: boolean;
};

export type PickerOption<Value> = {
  label: string;
  value: Value;
  disabled?: boolean;
};

export type PickerOptions<Value> = Array<PickerOption<Value>>;

export type FieldArgs<Props, V> = {
  form: FormikBag<Props, V>;
  field: FieldInputProps<V>;
  meta: FieldMetaProps<V>;
};

/**
 * Generic / unified Address type for the App
 * Address format in app should be consistent and decoupled from vendor.
 */

export type AppAddressFormat = {
  // Required Address Components
  displayAddress?: string | null;
  postcode?: string | null;
  state?: string | null;
  street?: string | null;
  streetNo?: string | null;
  streetNoTwo?: string | null;
  streetType?: string | null;
  suburb?: string | null;
  unitNo?: string | null;
  // Used to flag manually inputted address
  isProvidedByUser?: boolean | null;
  countryCode?: Country_Enum | null;
  // Vendor specific Ids which we can use to enrich the address data on certain vendor
  domainApiId?: string | null;
};

export type SelectedAddress = AppAddressFormat | null;

/**
 * This address schema will use AppAddressFormat
 * With addition of isProvidedByUser prop to track manually inputted address,
 * and domainApiId prop to track input that comes from domain API
 *
 */
export const yupAddressSchema = yup
  .object({
    displayAddress: yup.string().nullable(),
    postcode: yup.string().nullable(),
    state: yup.string().nullable(),
    street: yup.string().nullable(),
    streetNo: yup.string().nullable(),
    streetNoTwo: yup.string().nullable(),
    streetType: yup.string().nullable(),
    suburb: yup.string().nullable(),
    unitNo: yup.string().nullable(),
    isProvidedByUser: yup.boolean().nullable(),
    domainApiId: yup
      .string()
      .nullable()
      .when('isProvidedByUser', (value: boolean) =>
        value ? yup.string().nullable() : yup.string().required(),
      ),
  })
  .default({});

export const yupAddress = () => yupAddressSchema;

export type YupAddressResult = Partial<yup.Asserts<typeof yupAddressSchema>>;

export type BorrowerFormContextValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  otherNames: string;
  dateOfBirth: Date | null;
  gender: Gender_Enum | null;
  citizenships: Array<string> | null;
  residentialAddress: AppAddressFormat | null;
  mailingAddress: AppAddressFormat | null;
  stateOfIssue: State_Enum | null;
  licenseNo: string;
  driverLicenseCardNo: string | null;
  identityCheckResult: string | null | undefined;
  useResidentialAddressAsMailingAddress: boolean | null;
  loanApplicationId: string | null;
  applicantId: string | null;
  applicantRole: Applicant_Role_Enum | null;
  isCurrentLoggedInApplicant: boolean;
};

type BorrowerDetailsForm = SpecificRequired<
  BorrowerForm,
  | BorrowerDetailsField.Citizenship
  | BorrowerDetailsField.FirstName
  | BorrowerDetailsField.LastName
>;

type BorrowerIdentityDetailsForm = SpecificRequired<
  BorrowerIdentityDetailsFormValues,
  | BorrowerDriversLicenceField.DriverLicenceCardNo
  | BorrowerDriversLicenceField.LicenceNo
  | BorrowerPassportField.PassportNo
  | BorrowerIdentityDetailsField.IdentityType
>;

// We use this values as initial values for forms
export type BorrowerFormV2ContextValues = BorrowerDetailsForm &
  BorrowerIdentityDetailsForm & {
    identityCheckResult: string | null | undefined;
    loanApplicationId: string | null;
    applicantId: string | null;
    applicantRole: Applicant_Role_Enum | null;
    isCurrentLoggedInApplicant: boolean;
  };
