import { SxProp, Text, Theme, View } from 'dripsy';
import * as React from 'react';

type TextListProps = {
  testID?: string;
  variant?: keyof Theme['text'];
  tabIndent?: keyof Theme['space'];
  items: React.ReactNode[];
  separator?: React.ReactNode;
  ordered?: boolean;
  itemSx?: SxProp;
  listSx?: SxProp;
  getKey?: (...args: [React.ReactNode, number]) => string;
  getDecorator?: (...args: [boolean, number]) => string;
};

const defaultGetKey = (item: React.ReactNode, index: number) => {
  if (typeof item === 'string') {
    return `${index}-${item}`;
  }
  return `${index}`;
};

const defaultGetDecorator = (ordered: boolean, index: number) =>
  ordered ? `${index + 1}.` : '\u2022';

const maxDecoratorWidth = (
  getDecorator: (...args: [boolean, number]) => string,
  ordered: boolean,
  itemsLength: number,
) =>
  Math.max(
    ...Array.from(
      { length: itemsLength },
      (_, i) => getDecorator(ordered, i).length,
    ),
  );

export const TextList = ({
  testID,
  variant = 'body',
  tabIndent = '$8',
  items,
  ordered = true,
  separator,
  itemSx,
  listSx,
  getKey = defaultGetKey,
  getDecorator = defaultGetDecorator,
}: TextListProps) => {
  const decoratorWidth = maxDecoratorWidth(getDecorator, ordered, items.length);
  return (
    <View
      testID={testID}
      sx={{ ...listSx, flexDirection: 'column', width: '100%' }}
    >
      {items.map((item, index, arr) => (
        <View key={getKey(item, index)}>
          <View
            sx={{
              ...itemSx,
              flexDirection: 'row',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Text
              variant={variant}
              sx={{
                textAlign: 'right',
                pr: tabIndent,
                minWidth: `${decoratorWidth}em`,
              }}
            >
              {getDecorator(ordered, index)}
            </Text>
            {typeof item === 'string' ? (
              <Text variant={variant} sx={{ flexShrink: 1 }}>
                {item}
              </Text>
            ) : (
              <View sx={{ flexShrink: 1 }}>{item}</View>
            )}
          </View>
          {index < arr.length - 1 && separator}
        </View>
      ))}
    </View>
  );
};
