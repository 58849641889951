import { useCallback, useEffect, useState } from 'react';

import { useEnvConfig } from '../../../utils/hooks/useEnvConfig';

/**
 * the intercom script is injected through Google tag manager through component GTMProvider.web.tsx component
 * During app launch, seems it will attach the global Intercom object to Window.
 * https://www.intercom.com/help/en/articles/2631808-install-intercom-with-google-tag-manager
 */

export type UseIntercomResult = {
  unreadMessagesCount: number;
  onChatIconPress?: () => Promise<boolean>;
  presentMessageComposer?: () => Promise<boolean>;
  onGiveFeedback?: () => Promise<void>;
};

export const useIntercom = (): UseIntercomResult => {
  const { config } = useEnvConfig();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    // wait for Intercom script to be loaded
    // TODO: how to handle if Intercom is never loaded?
    const intervalId = setInterval(() => {
      if (window.Intercom) {
        window.Intercom('onUnreadCountChange', (count: number) => {
          setUnreadMessagesCount(count && count > 0 ? count : 0);
        });
        clearInterval(intervalId);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return {
    unreadMessagesCount,
    onGiveFeedback: useCallback(async () => {
      window.Intercom?.('startSurvey', config.intercomFeedbackSurveyId);
    }, [config.intercomFeedbackSurveyId]),
  };
};
