import { Text } from 'dripsy';
import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { useDeclineRequestMutation } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST>;

export const ReduceRepaymentsDeclineRequestActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { approvalRequestId, cbaAccountId } = route.params;
  const [
    declineRequest,
    { loading: declineRequestLoading, error: declineRequestError },
  ] = useDeclineRequestMutation();

  const onPress = useCallback(async () => {
    const [response, error] = await safelyCallMutation(declineRequest, {
      variables: {
        approval_request_id: approvalRequestId,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Loan Variation - Decline Request Mutation',
        },
      },
    });

    if (!error && !!response.data) {
      navigation.replace(ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED);
    }
  }, [cbaAccountId, approvalRequestId, declineRequest, navigation]);

  const errorMessage = declineRequestError
    ? (t(
        'Content.LoanVariation.ReduceRepaymentDeclineRequest.UnableToProcessRequest',
      ) as string)
    : undefined;

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      showCloseButton={false}
      isRowButton
      errorMessage={errorMessage}
      title={t('Content.LoanVariation.ReduceRepaymentDeclineRequest.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.LoanVariation.DeclineRequest.CancelButton,
          disabled: declineRequestLoading,
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          showSpinner: declineRequestLoading,
          disabled: declineRequestLoading || !!declineRequestError,
          testID: TestID.LoanVariation.DeclineRequest.ConfirmButton,
          onPress,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <Text
        variant="body"
        sx={{ py: '$8', px: '$16', mb: '$16', textAlign: 'center' }}
      >
        {t('Content.LoanVariation.ReduceRepaymentDeclineRequest.SubTitle')}
      </Text>
    </ActionSheet>
  );
};
