import * as React from 'react';
import Svg, { Circle, G, SvgProps } from 'react-native-svg';

import { Box, BoxProps } from '../../ui/atoms/Box';
import { useTheme } from '../../ui/theme';

export const INSIGHT_SVG_GRAPH_WIDTH = 100;
export const INSIGHT_SVG_GRAPH_RATIO = 2;
export const INSIGHT_SVG_GRAPH_HEIGHT =
  INSIGHT_SVG_GRAPH_WIDTH / INSIGHT_SVG_GRAPH_RATIO;

type BackgroundDotsLineProps = {
  bottomPadding?: number;
  numOfDotsLines?: number;
  dotRadius?: number;
};

type Props = Pick<BoxProps, 'onLayout' | 'testID'> &
  Pick<SvgProps, 'dataSet'> &
  BackgroundDotsLineProps & {
    viewBox?: string;
    showBackgroundDotsLine?: boolean;
    children?: React.ReactNode;
    aspectRatio?: number;
  };

const BackgroundDotsLines = ({
  bottomPadding = 0,
  dotRadius = 0.2,
  numOfDotsLines = 4,
}: BackgroundDotsLineProps) => {
  const theme = useTheme();
  const dotSpacing = 6 * dotRadius;
  const numOfDotsPerLine = Math.floor(INSIGHT_SVG_GRAPH_WIDTH / dotSpacing);
  const heightDelta =
    (INSIGHT_SVG_GRAPH_HEIGHT - bottomPadding - dotRadius * 2) /
    (numOfDotsLines - 1);
  const widthDelta =
    (INSIGHT_SVG_GRAPH_WIDTH - dotRadius * 2) / (numOfDotsPerLine - 1);

  const dots = new Array(numOfDotsPerLine).fill(null);
  return (
    <G>
      {new Array(numOfDotsLines).fill(null).map((_, lineIndex) =>
        dots.map((_dash, dotIndex) => (
          <Circle
            // eslint-disable-next-line react/no-array-index-key
            key={`dot-${lineIndex}-${dotIndex}`}
            cx={dotRadius + dotIndex * widthDelta}
            cy={dotRadius + lineIndex * heightDelta}
            r={dotRadius}
            fill={theme.colors.insightGrey}
            opacity={0.18}
          />
        )),
      )}
    </G>
  );
};

export const InsightGraphContainer = ({
  showBackgroundDotsLine,
  viewBox,
  onLayout,
  dataSet,
  aspectRatio,
  children,
  testID,
  ...props
}: Props) => (
  <Box
    onLayout={onLayout}
    testID={testID}
    aspectRatio={aspectRatio || INSIGHT_SVG_GRAPH_RATIO}
  >
    <Svg
      dataSet={dataSet}
      width="100%"
      height="100%"
      viewBox={
        viewBox || `0 0 ${INSIGHT_SVG_GRAPH_WIDTH} ${INSIGHT_SVG_GRAPH_HEIGHT}`
      }
    >
      {showBackgroundDotsLine ? <BackgroundDotsLines {...props} /> : null}
      {children}
    </Svg>
  </Box>
);
