import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function YourLiabilityCreditCardIcon({
  color,
  ...otherProps
}: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('YourLiabilityCreditCardIcon');
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...otherProps}>
      <Path
        d="M33.0938 7.5H2.90625C1.71525 7.5 1.5 7.71525 1.5 8.90625V28.5938C1.5 29.7847 1.71525 30 2.90625 30H33.0938C34.2848 30 34.5 29.7847 34.5 28.5938V8.90625C34.5 7.71525 34.2848 7.5 33.0938 7.5Z"
        fill={`url(#${idPrefix}_paint0_linear_8859_2998)`}
      />
      <Path d="M34.5 10.5H1.5V13.5H34.5V10.5Z" fill="#2C2C2C" />
      <Path
        d="M31.5 18H4.5C4.086 18 3.75 17.664 3.75 17.25V15.75C3.75 15.336 4.086 15 4.5 15H31.5C31.914 15 32.25 15.336 32.25 15.75V17.25C32.25 17.664 31.914 18 31.5 18Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8859_2998`}
          x1="1.5"
          y1="7.5"
          x2="22.445"
          y2="38.2193"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
