import { Text, View } from 'dripsy';
import { Formik, FormikProps } from 'formik';
import { useCallback } from 'react';
import { Screen } from 'src/navigation/types/screens';
import { createValidationSchema } from 'src/utils/yup';

import { FieldInteractionKey, SectionInteractionKey } from '../Analytics/types';
import { buildApplicationInteractionEventKey } from '../Analytics/utils/gtmKeyUtils';
import { MOCK_APPLICATION_INTERACTION_EVENT_KEY } from '../Analytics/utils/sample-data';
import {
  FormCurrencyInputV2,
  FormDateInputV2,
  FormTextInputV2,
} from '../components/form/FormikInputs';
import { FormSpacer } from '../components/form/FormSpacer';
import { SubmitButton } from '../components/form/SubmitButton';
import { SingleModalStackScreenProps } from '../navigation/types/navTypes';
import { FormikFormError } from '../ui/v2/FormError';
import { ModalScreenContainer } from '../ui/v2/ModalScreenContainer';
import { CreateMockAccountFormValues } from './types';
import { useSetupActiveAccountDevTool } from './useSetupActiveAccountDevTool';

const initialValues: CreateMockAccountFormValues = {
  coBorrowerEmail: '',
  loanAmount: 1_000_000,
  redrawAmount: 25_000,
};

const validationSchema = createValidationSchema<CreateMockAccountFormValues>(
  (yup) => ({
    coBorrowerEmail: yup.string().email('Enter a valid email address'),
    loanAmount: yup.number().typeError('Enter a valid number'),
    redrawAmount: yup.number().typeError('Enter a valid number'),
    disbursementDate: yup.string().default(new Date().toISOString()),
  }),
);

type Props = SingleModalStackScreenProps<Screen.MOCK_ACCOUNT_MODAL>;

export const MockAccountModal = ({ navigation }: Props) => {
  const {
    setupActiveAccount,
    setupJointAccount,
    setupActiveAccountLoading,
    setupJointAccountLoading,
  } = useSetupActiveAccountDevTool();

  const loading = setupActiveAccountLoading || setupJointAccountLoading;

  const onClose = useCallback(() => {
    if (loading) {
      return;
    }

    navigation.goBack();
  }, [loading, navigation]);

  const onSubmit = useCallback(
    async (values: CreateMockAccountFormValues) => {
      if (values.coBorrowerEmail) {
        setupJointAccount(values);
      } else {
        setupActiveAccount(values);
      }
    },
    [setupActiveAccount, setupJointAccount],
  );

  const handleDisbursementSelected = useCallback(
    (formProps: FormikProps<CreateMockAccountFormValues>, date: string) => {
      formProps.setFieldValue('disbursementDate', date);
    },
    [],
  );

  // Render
  return (
    <ModalScreenContainer
      headerText="Create Mock Account"
      onClose={onClose}
      hideBackButton
      scrollable
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formProps) => (
          <View>
            <View>
              <FormTextInputV2
                interactionKey={MOCK_APPLICATION_INTERACTION_EVENT_KEY}
                name="coBorrowerEmail"
                label="Co-Borrower Email"
                keyboardType="decimal-pad"
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                Required if you want to create a joint account
              </Text>
              <FormikFormError name="coBorrowerEmail" sx={{ mt: '$8' }} />
            </View>

            <View sx={{ mt: '$16' }}>
              <FormCurrencyInputV2
                interactionKey={MOCK_APPLICATION_INTERACTION_EVENT_KEY}
                name="loanAmount"
                label="Loan Amount"
                keyboardType="decimal-pad"
              />
              <FormikFormError name="loanAmount" sx={{ mt: '$8' }} />
            </View>

            <View sx={{ mt: '$16' }}>
              <FormCurrencyInputV2
                interactionKey={MOCK_APPLICATION_INTERACTION_EVENT_KEY}
                name="redrawAmount"
                label="Redraw Amount"
                keyboardType="decimal-pad"
              />
              <FormikFormError name="redrawAmount" sx={{ mt: '$8' }} />
            </View>

            <View sx={{ mt: '$16' }}>
              <FormDateInputV2
                name="disbursementDate"
                label="Disbursement Date"
                onDateFullyEntered={(date) =>
                  handleDisbursementSelected(formProps, date)
                }
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.MockAccountModal,
                  Screen.MOCK_ACCOUNT_MODAL,
                  FieldInteractionKey.DisbursementDate,
                )}
              />
              <FormikFormError name="disbursementDate" sx={{ mt: '$8' }} />
            </View>

            <FormSpacer py="$16" />

            <SubmitButton
              disabled={loading}
              showSpinner={loading}
              label={t('Content.Common.ButtonLabel.Submit')}
              onPress={() => formProps.handleSubmit()}
            />
          </View>
        )}
      </Formik>
    </ModalScreenContainer>
  );
};
