import {
  Autopay_Frequency_Input_Enum,
  Shortfall_Calculation_Repayment_Frequency,
} from '../../generated/graphql';
import { formatCurrency } from '../../utils/currencyHelpers';

export const formatAutopaySettingsLabel = ({
  amount,
  frequency,
}: {
  amount: number;
  frequency:
    | Autopay_Frequency_Input_Enum
    | Shortfall_Calculation_Repayment_Frequency
    | null;
}) => {
  const formattedAmount = formatCurrency(amount);
  const formattedFrequency = frequency
    ? t(`Content.LoanDetail.AutopayFrequencyEnum.${frequency}`)
    : '';

  return `${formattedAmount} ${formattedFrequency}`.trim();
};
