import { useMemo } from 'react';
import type { VictoryAnimationProps, VictoryPieProps } from 'victory';

import { Colors, useTheme } from '../../ui/theme';

export enum ChartSlice {
  REDRAW_PERCENTAGE,
  PRINCIPAL_PERCENTAGE,
}

export const STROKE_WIDTH = 8;

export const CHART_ANIMATION_DURATION = 800;

export const defaultAnimationConfig: Omit<
  VictoryAnimationProps,
  'children' | 'data'
> = {
  duration: CHART_ANIMATION_DURATION,
  easing: 'expOut',
};

export const useChartConfig = ({
  chartContainerWidth,
  activeChart,
  secondaryBarColor,
  loading,
}: {
  chartContainerWidth: null | number;
  activeChart?: boolean;
  secondaryBarColor?: Colors[keyof Colors];
  loading?: boolean;
}): VictoryPieProps => {
  const { colors } = useTheme();

  const barColor = useMemo(() => {
    if (loading) {
      return 'transparent';
    }

    if (activeChart) {
      return colors.buttonPrimaryBg;
    }

    return colors.disabledContent;
  }, [activeChart, colors.buttonPrimaryBg, colors.disabledContent, loading]);

  if (!chartContainerWidth) {
    return {};
  }
  const radius = chartContainerWidth / 2 - STROKE_WIDTH / 2;

  return {
    animate: defaultAnimationConfig,
    standalone: false,
    startAngle: 0,
    endAngle: -360,
    labels: [],
    radius,
    innerRadius: radius,
    style: {
      data: {
        stroke: ({ datum }: { datum?: { x: ChartSlice; y: number } }) =>
          datum?.x === ChartSlice.PRINCIPAL_PERCENTAGE
            ? barColor
            : secondaryBarColor ?? colors.bg,
        strokeWidth: STROKE_WIDTH,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
    },
  };
};
