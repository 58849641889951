import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function NotificationIcon({ color, ...props }: SvgIconProps) {
  return (
    <Svg width={20} height={23} viewBox="0 0 20 23" fill="none" {...props}>
      <Path
        d="M19.238 16.348c-.816-.903-2.406-2.235-2.406-6.66 0-3.31-2.32-5.973-5.5-6.66v-.903c0-.73-.602-1.375-1.332-1.375-.774 0-1.375.645-1.375 1.375v.902c-3.18.688-5.5 3.352-5.5 6.66 0 4.426-1.59 5.758-2.406 6.66-.258.258-.387.602-.344.903 0 .73.515 1.375 1.375 1.375h16.457c.86 0 1.375-.645 1.418-1.375 0-.3-.13-.645-.387-.902zm-15.984.215c.902-1.16 1.89-3.18 1.933-6.833v-.043c0-2.62 2.149-4.812 4.813-4.812 2.62 0 4.812 2.191 4.812 4.813 0 .042-.043.042-.043.042.043 3.653 1.032 5.672 1.934 6.832H3.253zM10 22.75c1.504 0 2.707-1.203 2.707-2.75H7.25A2.715 2.715 0 0010 22.75z"
        fill={color}
      />
    </Svg>
  );
}
