import { Pressable, PressableProps } from 'react-native';

import { Props as StyledIconProps, StyledIcon } from '../atoms/StyledIcon';

export type PressableIconProps = PressableProps & {
  iconName: StyledIconProps['name'];
  iconColor?: StyledIconProps['color'];
  iconSize?: StyledIconProps['size'];
  iconRoundBg?: StyledIconProps['roundBg'];
  iconFamily?: StyledIconProps['family'];
  iconOpacity?: StyledIconProps['opacity'];
  iconFixedHeight?: StyledIconProps['fixedHeight'];
  additionalIconProps?: Omit<
    StyledIconProps,
    'name' | 'color' | 'size' | 'roundBg' | 'family' | 'opacity' | 'fixedHeight'
  >;
};

export function PressableIcon({
  iconName,
  iconColor,
  iconSize,
  iconRoundBg,
  iconFamily,
  iconOpacity,
  iconFixedHeight,
  additionalIconProps,
  ...otherProps
}: PressableIconProps) {
  return (
    <Pressable role="button" {...otherProps}>
      <StyledIcon
        name={iconName}
        color={iconColor}
        size={iconSize}
        roundBg={iconRoundBg}
        family={iconFamily}
        opacity={iconOpacity}
        fixedHeight={iconFixedHeight}
        {...additionalIconProps}
      />
    </Pressable>
  );
}
