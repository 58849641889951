import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Frequency_Enum, State_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames,
  ManualSuburbInputFields,
} from '../../ui/v2/ManualSuburbInputFields';
import { formatCurrency } from '../../utils/currencyHelpers';
import { yup } from '../../utils/yup';

export const manualSuburbPrefix = 'expectedRentalIncomeManualSuburbInput';
export const expectedRentalIncomeManualInputFieldNames =
  getManualInputFieldNames(manualSuburbPrefix);

export enum ExpectedRentalIncomeFormFields {
  RentalIncome = 'rentalIncome',
  RentalIncomeFrequency = 'rentalIncomeFrequency',
  RentalExpenses = 'rentalExpenses',
  RentalExpensesFrequency = 'rentalExpensesFrequency',
  EstimatedLoanAmount = 'estimatedLoanAmount',
}

// TODO: query these values from LOAN_APPLICATION_TARGET_CONFIGURATION in backend
const MIN_ESTIMATED_LOAN_AMOUNT = 10_000;
const MAX_ESTIMATED_LOAN_AMOUNT = 10_000_000;

const getExpectedRentalIncomeSchema = () =>
  yup
    .object({
      [ExpectedRentalIncomeFormFields.RentalIncome]: yup
        .number()
        .nullable()
        .required(t('Content.YourIncome.Form.RentalIncomeError')),
      [ExpectedRentalIncomeFormFields.RentalIncomeFrequency]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
      [ExpectedRentalIncomeFormFields.RentalExpenses]: yup
        .number()
        .nullable()
        .required(t('Content.YourIncome.Form.RentalExpensesError')),
      [ExpectedRentalIncomeFormFields.RentalExpensesFrequency]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
      [ExpectedRentalIncomeFormFields.EstimatedLoanAmount]: yup
        .number()
        .nullable()
        .required(t('Content.YourIncome.Form.EnterEstimatedLoanAmountError'))
        .moreThan(
          MIN_ESTIMATED_LOAN_AMOUNT - 1,
          t('Content.SetupLoanPurchase.FieldError.MinXLoanAmount', {
            x: formatCurrency(MIN_ESTIMATED_LOAN_AMOUNT, {
              noFraction: true,
            }),
          }),
        )
        .lessThan(
          MAX_ESTIMATED_LOAN_AMOUNT + 1,
          t('Content.SetupLoanPurchase.FieldError.MaxXLoanAmount', {
            x: formatCurrency(MAX_ESTIMATED_LOAN_AMOUNT, {
              noFraction: true,
            }),
          }),
        ),
    })
    // Append with manual address input fields
    .concat(getManualAddressYupSchema(manualSuburbPrefix));

export type ExpectedRentalIncomeFormDetails = {
  [ExpectedRentalIncomeFormFields.RentalIncome]: number | undefined;
  [ExpectedRentalIncomeFormFields.RentalIncomeFrequency]:
    | Frequency_Enum
    | undefined;
  [ExpectedRentalIncomeFormFields.RentalExpenses]: number | undefined;
  [ExpectedRentalIncomeFormFields.RentalExpensesFrequency]:
    | Frequency_Enum
    | undefined;

  [ExpectedRentalIncomeFormFields.EstimatedLoanAmount]: number | undefined;

  // Manual address input values
  [expectedRentalIncomeManualInputFieldNames.isManualInput]:
    | boolean
    | undefined;
  [expectedRentalIncomeManualInputFieldNames.postcode]: string | undefined;
  [expectedRentalIncomeManualInputFieldNames.state]: State_Enum | undefined;
  [expectedRentalIncomeManualInputFieldNames.suburb]: string | undefined;
};

export const DEFAULT_EXPECTED_RENTAL_INCOME_INITIAL_VALUES: ExpectedRentalIncomeFormDetails =
  {
    rentalExpenses: undefined,
    rentalExpensesFrequency: Frequency_Enum.Monthly,
    rentalIncome: undefined,
    rentalIncomeFrequency: Frequency_Enum.Monthly,
    estimatedLoanAmount: undefined,
    // Manual address input values
    [expectedRentalIncomeManualInputFieldNames.isManualInput]: true,
    [expectedRentalIncomeManualInputFieldNames.postcode]: undefined,
    [expectedRentalIncomeManualInputFieldNames.state]: undefined,
    [expectedRentalIncomeManualInputFieldNames.suburb]: undefined,
  };

export type ExpectedRentalIncomeFormProps = {
  screen: Screen;
  initialValues?: ExpectedRentalIncomeFormDetails;
  frequencyOptions: PickerOptions<string>;
  stateOptions: PickerOptions<string>;
  isSubmitting?: boolean;
  onSubmit: (values: ExpectedRentalIncomeFormDetails) => Promise<void>;
  onRentalExpenseLearnMoreClick?: () => void;
};

export function ExpectedRentalIncomeForm({
  screen,
  initialValues = DEFAULT_EXPECTED_RENTAL_INCOME_INITIAL_VALUES,
  frequencyOptions = [],
  stateOptions = [],
  isSubmitting,
  onSubmit,
  onRentalExpenseLearnMoreClick,
}: ExpectedRentalIncomeFormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getExpectedRentalIncomeSchema}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <Text
            sx={{
              mb: '$24',
            }}
          >
            {t(
              'Content.YourIncome.Form.ExpectedIncomeFromInvestmentPropertyCaption',
            )}
          </Text>

          <SHeaderText>
            {t('Content.YourIncome.Form.EnterExpectedLocation')}
          </SHeaderText>
          <ManualSuburbInputFields
            screen={screen}
            fieldNamePrefix={manualSuburbPrefix}
            containerSx={{ mt: '$16' }}
            stateOptions={stateOptions}
          />

          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.YourIncome.Form.EnterEstimatedLoanAmount')}
          </SHeaderText>
          <FormCurrencyInputV2
            name={ExpectedRentalIncomeFormFields.EstimatedLoanAmount}
            error={
              formProps.errors?.[
                ExpectedRentalIncomeFormFields.EstimatedLoanAmount
              ]
            }
            label={t('Content.YourIncome.Form.EstimatedLoanAmount')}
            sx={{ mt: '$8' }}
            inputTestID={TestID.ExpectedRentalIncomeForm.EstimatedLoanAmount}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Income,
              screen,
              FieldInteractionKey.EstimatedLoanAmount,
            )}
          />
          <FormikFormError
            name={ExpectedRentalIncomeFormFields.EstimatedLoanAmount}
            sx={{ mt: '$8' }}
          />

          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.YourIncome.Form.EnterExpectedRentalIncome')}
          </SHeaderText>
          <RowView sx={{ mt: '$16' }}>
            <FormCurrencyInputV2
              name={ExpectedRentalIncomeFormFields.RentalIncome}
              sx={{ mr: '$16', flex: 1 }}
              label={t('Content.YourIncome.Form.RentalIncome')}
              inputTestID={
                TestID.ExpectedRentalIncomeForm.RentalIncomeValueInput
              }
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.RentalIncome,
              )}
            />
            <FormSelectV2
              name={ExpectedRentalIncomeFormFields.RentalIncomeFrequency}
              label={t('Content.YourIncome.Form.IncomeFrequency')}
              testID={TestID.ExpectedRentalIncomeForm.RentalIncomeFrequency}
              items={frequencyOptions}
              sx={{ flex: 1 }}
            />
          </RowView>
          <FormikFormError
            name={ExpectedRentalIncomeFormFields.RentalIncome}
            sx={{ mt: '$8' }}
          />
          <FormikFormError
            name={ExpectedRentalIncomeFormFields.RentalIncomeFrequency}
            sx={{ mt: '$8' }}
          />
          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.YourIncome.Form.ExpectedRentalIncomeCaption')}
          </Text>

          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.YourIncome.Form.EnterExpectedRentalExpenses')}
          </SHeaderText>
          <RowView sx={{ mt: '$16' }}>
            <FormCurrencyInputV2
              name={ExpectedRentalIncomeFormFields.RentalExpenses}
              sx={{ mr: '$16', flex: 1 }}
              label={t('Content.YourIncome.Form.RentalExpenses')}
              inputTestID={
                TestID.ExpectedRentalIncomeForm.RentalExpensesValueInput
              }
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.RentalExpenses,
              )}
            />
            <FormSelectV2
              name={ExpectedRentalIncomeFormFields.RentalExpensesFrequency}
              label={t('Content.YourIncome.Form.IncomeFrequency')}
              testID={TestID.ExpectedRentalIncomeForm.RentalExpensesFrequency}
              items={frequencyOptions}
              sx={{ flex: 1 }}
            />
          </RowView>
          <FormikFormError
            name={ExpectedRentalIncomeFormFields.RentalExpenses}
            sx={{ mt: '$8' }}
          />
          <FormikFormError
            name={ExpectedRentalIncomeFormFields.RentalExpensesFrequency}
            sx={{ mt: '$8' }}
          />
          <Text
            sx={{ mt: '$8' }}
            variants={['caption', 'link']}
            onPress={() => onRentalExpenseLearnMoreClick?.()}
          >
            {t('Content.YourIncome.Form.RentalExpensesHelp')}
          </Text>

          <BottomSpacer />
          <Button
            label={t('Content.Common.ButtonLabel.Next')}
            testID={TestID.ExpectedRentalIncomeForm.SubmitButton}
            onPress={async () => {
              formProps.handleSubmit();
            }}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            py="m"
            alignSelf="stretch"
          />
        </View>
      )}
    </Formik>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
const BottomSpacer = styled(View)({
  mb: '$32',
});
