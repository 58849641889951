import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const IntercomSupportIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    width={resizeIconWidth(20, 20, size)}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <Path
      d="M5.58076 20C5.26618 20 5.02117 19.8872 4.84574 19.6617C4.67635 19.4426 4.59165 19.1461 4.59165 18.7724V16.2591H4.15608C3.25469 16.2591 2.49546 16.0883 1.8784 15.7467C1.26134 15.3987 0.792499 14.8961 0.471869 14.2388C0.15729 13.5814 0 12.7791 0 11.8318V4.42726C0 3.47994 0.15729 2.67762 0.471869 2.0203C0.792499 1.36298 1.26134 0.863541 1.8784 0.521991C2.49546 0.173997 3.25469 0 4.15608 0H15.8439C16.7453 0 17.5045 0.173997 18.1216 0.521991C18.7387 0.863541 19.2045 1.36298 19.5191 2.0203C19.8397 2.67762 20 3.47994 20 4.42726V11.8318C20 12.7791 19.8397 13.5814 19.5191 14.2388C19.2045 14.8961 18.7387 15.3987 18.1216 15.7467C17.5045 16.0883 16.7453 16.2591 15.8439 16.2591H10.0181L6.88748 19.2267C6.61524 19.4845 6.38536 19.6778 6.19782 19.8067C6.01028 19.9356 5.8046 20 5.58076 20Z"
      fill={color}
    />
    <Path
      d="M9.10163 9.36684C9.10163 9.87595 9.34362 10.1305 9.82759 10.1305C10.0514 10.1305 10.2269 10.0628 10.3539 9.9275C10.487 9.79217 10.5535 9.63106 10.5535 9.44418V9.38618V9.32818C10.5535 9.05752 10.6292 8.82552 10.7804 8.63219C10.9316 8.43242 11.1676 8.21975 11.4882 7.9942C11.9238 7.68487 12.2837 7.36266 12.5681 7.02755C12.8524 6.69244 12.9946 6.2349 12.9946 5.65491C12.9946 5.12003 12.8584 4.66892 12.5862 4.30159C12.32 3.93427 11.9691 3.65716 11.5336 3.47028C11.098 3.27695 10.6231 3.18028 10.1089 3.18028C9.32849 3.18028 8.69026 3.35106 8.19419 3.69261C7.70417 4.02771 7.39564 4.41759 7.2686 4.86225C7.2444 4.93958 7.22626 5.01692 7.21416 5.09425C7.20206 5.17158 7.19601 5.25214 7.19601 5.33591C7.19601 5.55502 7.26255 5.72257 7.39564 5.83857C7.52874 5.94812 7.6709 6.0029 7.82214 6.0029C7.97338 6.0029 8.10042 5.96746 8.20327 5.89657C8.31216 5.82568 8.40593 5.73224 8.48457 5.61624L8.64791 5.38424C8.75681 5.19736 8.88082 5.03947 9.01996 4.91059C9.1591 4.77525 9.31337 4.67214 9.48276 4.60126C9.6582 4.53037 9.84574 4.49493 10.0454 4.49493C10.4628 4.49493 10.7955 4.61092 11.0436 4.84292C11.2916 5.06847 11.4156 5.36491 11.4156 5.73224C11.4156 6.0609 11.3188 6.33156 11.1252 6.54422C10.9316 6.75044 10.6352 7.00177 10.2359 7.29821C9.90926 7.53665 9.63702 7.80087 9.41924 8.09087C9.2075 8.38086 9.10163 8.7643 9.10163 9.24118V9.30885V9.36684Z"
      fill="white"
    />
    <Path
      d="M9.11978 12.7211C9.31337 12.9145 9.54023 13.0111 9.80036 13.0111C10.0665 13.0111 10.2964 12.9145 10.49 12.7211C10.6836 12.5278 10.7804 12.2894 10.7804 12.0058C10.7804 11.7222 10.6836 11.4838 10.49 11.2905C10.2964 11.0971 10.0665 11.0005 9.80036 11.0005C9.53418 11.0005 9.3043 11.1004 9.11071 11.3001C8.92317 11.4935 8.8294 11.7287 8.8294 12.0058C8.8294 12.2829 8.9262 12.5213 9.11978 12.7211Z"
      fill="white"
    />
  </Svg>
);
