import { gql } from '@apollo/client';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  refetchGetLoanApplicationTasksQuery,
  refetchGetViewerLoanApplicationsQuery,
  useGetLoanApplicationTasksQuery,
  useSubmitDocumentsForVcaMutation,
} from '../../generated/graphql';
import { LoanApplicationTasks } from '../../LoanApplication/components/LoanApplicationTasks';
import { LoanApplicationWizardFooter } from '../../LoanApplication/components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../../LoanApplication/components/LoanScreenHeader';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useExperimentFeatureFlag } from '../../utils/hooks/useExperimentFeatureFlag';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { ConditionalApprovalScreenProps } from '../navigation/types';

export const SubmitDocumentsForVca = gql`
  mutation SubmitDocumentsForVca($loanApplicationId: uuid!) {
    submit_documents_for_vca(loan_application_id: $loanApplicationId) {
      returning {
        id
        status_info {
          id
          loan_application_stage
        }
      }
    }
  }
`;

export type VerifyYourFinancialsProps =
  ConditionalApprovalScreenProps<Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS>;

export function VerifyYourFinancials({
  navigation,
  route,
}: VerifyYourFinancialsProps) {
  const {
    featureFlag: enableEvidenceScreenUplift,
    loading: enableEvidenceScreenUpliftLoading,
  } = useExperimentFeatureFlag(FeatureFlag.EnableEvidenceScreenUplift);

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const { loanApplicationId = '', _storySubmitErrorMessage } =
    route.params || {};

  const { data, loading, error, refetch } = useGetLoanApplicationTasksQuery({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
        screen: Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS,
      },
    },
  });

  const [submit, { loading: isSubmitting, error: submitError }] =
    useSubmitDocumentsForVcaMutation({
      refetchQueries: [
        refetchGetViewerLoanApplicationsQuery(),
        refetchGetLoanApplicationTasksQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
    });

  if (loading || enableEvidenceScreenUpliftLoading) {
    return (
      <ModalScreenContainer onClose={navigation.goBack} hideBackButton>
        <LoanScreenHeader
          title={t(
            'Content.ConditionalApproval.VerifyYourFinancialsModal.Title',
          )}
          caption={t(
            'Content.ConditionalApproval.VerifyYourFinancialsModal.TitleCaption',
          )}
          mb="xl"
        />
        <ScreenLoadingContainer
          loading
          testID={TestID.VerifyYourFinancials.LoadingIndicator}
        />
      </ModalScreenContainer>
    );
  }

  if (error) {
    return (
      <ModalScreenContainer onClose={navigation.goBack} hideBackButton>
        <ScreenErrorFallback
          error={error}
          displayMessage={t(
            'Content.ConditionalApproval.VerifyYourFinancialsModal.Error.FailedToFetchLoanApplicationTasks',
          )}
          refetch={refetch}
        />
      </ModalScreenContainer>
    );
  }

  const onSubmit = async () => {
    const [, onSubmitError] = await safelyCallMutation(submit, {
      variables: { loanApplicationId },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
    if (!onSubmitError) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.LoanApplicationTask,
              Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS,
              FieldInteractionKey.Finish,
            ),
        },
      });

      navigation.goBack();
    }
  };

  const errorMessage =
    submitError || _storySubmitErrorMessage
      ? t('Content.Common.Error.FailSubmitDocumentEvidence')
      : undefined;

  const hasCompletedAllTasks =
    data?.get_loan_application_tasks.allow_to_proceed;
  const tasks = data?.get_loan_application_tasks.loan_application_tasks;

  return (
    <ModalScreenContainer onClose={navigation.goBack} hideBackButton>
      <LoanScreenHeader
        title={t('Content.ConditionalApproval.VerifyYourFinancialsModal.Title')}
        caption={t(
          'Content.ConditionalApproval.VerifyYourFinancialsModal.TitleCaption',
        )}
        mb="xl"
      />
      <ErrorRow
        message={errorMessage}
        testID={TestID.VerifyYourFinancials.ErrorRow}
      />
      <LoanApplicationTasks
        loanApplicationId={loanApplicationId}
        tasks={tasks}
        enableEvidenceScreenUplift={enableEvidenceScreenUplift}
        parentScreenName={Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS}
      />
      <LoanApplicationWizardFooter
        mt="l"
        px={0}
        primaryButtonLabel={t('Content.Common.ButtonLabel.Finish')}
        onPrimaryButtonPress={onSubmit}
        primaryButtonTestID={TestID.VerifyYourFinancials.FinishButton}
        footerCaption={t(
          'Content.ConditionalApproval.VerifyYourFinancialsModal.FooterCaption',
        )}
        isPrimaryButtonLoading={isSubmitting}
        disablePrimaryButton={isSubmitting || !hasCompletedAllTasks}
      />
    </ModalScreenContainer>
  );
}
