import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import Animated, {
  Easing,
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Circle, Defs, G, LinearGradient, Stop } from 'react-native-svg';

import { TestID } from '../../../../testID/constants';
import { Box } from '../../../ui/atoms/Box';
import { useTheme } from '../../../ui/theme';
import { chromaticIgnoreDataSet } from '../../../utils/chromatic';
import { useIsMobile } from '../../../utils/hooks/useBreakpoint';
import {
  INSIGHT_SVG_GRAPH_WIDTH,
  InsightGraphContainer,
} from '../InsightGraphContainer';

type DiscountCircularGraphProps = {
  aspectRatio?: number;
  percentageRemaining: number;
  children?: React.ReactNode;
};

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

export const DiscountCircularGraph = ({
  percentageRemaining,
  aspectRatio = 2,
  children,
}: DiscountCircularGraphProps) => {
  const isMobile = useIsMobile();
  const { colors } = useTheme();
  const [graphHeightInPixel, setGraphHeightInPixel] = useState(0);

  const svgMetaData = useMemo(() => {
    const strokeWidthInPixel = isMobile ? 8 : 10;
    const graphHeightInSvg = INSIGHT_SVG_GRAPH_WIDTH / aspectRatio;
    const strokeWidthInSvg =
      (strokeWidthInPixel / graphHeightInPixel) * graphHeightInSvg;
    const radius = graphHeightInSvg / 2;
    const innerRadius = radius - strokeWidthInSvg / 2;
    const circumference = radius * Math.PI * 2;

    return {
      graphHeightInSvg,
      strokeWidthInSvg,
      radius,
      innerRadius,
      circumference,
    };
  }, [aspectRatio, graphHeightInPixel, isMobile]);

  const progressCircle = useSharedValue(0.999);
  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: svgMetaData.circumference * progressCircle.value,
  }));

  useEffect(() => {
    progressCircle.value = withTiming(percentageRemaining, {
      duration: 2000,
      easing: Easing.out(Easing.ease),
    });
  }, [progressCircle, percentageRemaining]);

  return (
    <Box testID={TestID.Insights.DiscountBump.CircularGraph}>
      <InsightGraphContainer
        onLayout={(event) => {
          setGraphHeightInPixel(event.nativeEvent.layout.height);
        }}
        aspectRatio={aspectRatio}
        viewBox={`0 0 ${INSIGHT_SVG_GRAPH_WIDTH} ${svgMetaData.graphHeightInSvg}`}
        dataSet={chromaticIgnoreDataSet}
      >
        <G>
          <Defs>
            <LinearGradient
              id="discount_gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
              gradientTransform="rotate(-20 0 0)"
            >
              <Stop offset="0%" stopColor="#5188EE" />
              <Stop offset="80%" stopColor="#FF7BAD" />
            </LinearGradient>
          </Defs>
          <Circle
            cx={svgMetaData.radius}
            cy={svgMetaData.radius}
            r={svgMetaData.innerRadius}
            fill="transparent"
            stroke={colors.bg}
            strokeWidth={svgMetaData.strokeWidthInSvg}
          />
          <AnimatedCircle
            animatedProps={animatedProps}
            cx={svgMetaData.radius}
            cy={svgMetaData.radius}
            r={svgMetaData.innerRadius}
            fill="transparent"
            stroke="url(#discount_gradient)"
            strokeDasharray={`${svgMetaData.circumference} ${svgMetaData.circumference}`}
            strokeWidth={svgMetaData.strokeWidthInSvg}
            strokeLinecap="round"
            transform={`rotate(-90 ${svgMetaData.radius} ${svgMetaData.radius})`}
          />
        </G>
      </InsightGraphContainer>
      {children}
    </Box>
  );
};
