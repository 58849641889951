import { View } from 'dripsy';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import { Separator } from '../../ui/atoms/Separator';

const InsightsGridRow = ({ children }: { children: React.ReactNode }) => (
  <View
    sx={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...styles.container,
    }}
  >
    {children}
  </View>
);

const InsightsGridCol = ({ children }: { children?: React.ReactNode }) => (
  <View sx={{ flex: 1, ...styles.child }}>{children}</View>
);

type Props = {
  children?: React.ReactNode | React.ReactNode[];
};

export const HomeLoanInsightsWidgetsContainer = ({ children }: Props) => {
  if (!children) return null;

  if (!Array.isArray(children)) {
    return (
      <View sx={{ overflow: 'visible' }}>
        <InsightsGridRow>
          <InsightsGridCol>{children}</InsightsGridCol>
          <InsightsGridCol />
        </InsightsGridRow>
      </View>
    );
  }

  const childrenArray = children.filter((child) => !!child);

  return (
    <View sx={{ overflow: 'visible' }}>
      <InsightsGridRow>
        <InsightsGridCol>{childrenArray?.[0]}</InsightsGridCol>
        <InsightsGridCol>{childrenArray?.[1]}</InsightsGridCol>
      </InsightsGridRow>
      <Separator spacer py="xs" />
      <InsightsGridRow>
        <InsightsGridCol>{childrenArray?.[2]}</InsightsGridCol>
        <InsightsGridCol>{childrenArray?.[3]}</InsightsGridCol>
      </InsightsGridRow>
    </View>
  );
};

// Gap you want to achieve
const GAP = 8;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: GAP / -2,
  },
  child: {
    marginHorizontal: GAP / 2,
  },
});
