import { Text, useSx, View } from 'dripsy';

import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { Button } from '../../ui/atoms/Button';
import { ActionSheet } from './ActionSheet';

export type BaseConfirmationSuccessActionSheetProps = {
  message: string;
  supportingText?: string;
  includeCloseButton?: boolean;
  onClose: () => void;
};

export function BaseConfirmationSuccessActionSheet({
  message,
  supportingText,
  includeCloseButton,
  onClose,
}: BaseConfirmationSuccessActionSheetProps) {
  const sx = useSx();

  return (
    <ActionSheet
      showCloseButton={false}
      preventCloseModal
      message={
        <View sx={{ pb: '$32' }}>
          <GreenCheckAnimation
            onAnimationFinish={includeCloseButton ? undefined : onClose}
          />
          <Text variant="sHeader" sx={{ textAlign: 'center' }}>
            {message}
          </Text>

          {supportingText ? (
            <Text variant="body" sx={{ textAlign: 'center', mt: '$16' }}>
              {supportingText}
            </Text>
          ) : null}

          {includeCloseButton ? (
            <Button
              label={t('Content.Common.ButtonLabel.Close')}
              style={sx({ mt: '$24', width: '100%' })}
              onPress={onClose}
            />
          ) : null}
        </View>
      }
    />
  );
}
