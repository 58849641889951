import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  CreateHouseholdMutationVariables,
  Living_Situation_Input_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchGetLoanApplicantsQuery,
  refetchReviewLoanApplicationQuery,
  refetchYourExpensesQuery,
  UpdateHouseholdExpensesMutationVariables,
  useCreateHouseholdMutation,
  useUpdateHouseholdExpensesMutation,
} from '../../generated/graphql';
import { captureException } from '../../sentry';
import { parseEnumType } from '../../utils/ensureEnumType';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { expensesFormAtom } from '../recoil/expensesFormAtom';
import { formatToExpensesInputV2 } from './expensesFormUtils';
import { AllExpensesTypeFormValues, CombinedExpensesFormValues } from './forms';
import { useHandleHouseholdExpenseValidationError } from './useHandleHouseholdExpenseValidationError';

export enum AddHouseholdExpenseResult {
  LOW_HEM_STATUS,
  ERROR_OCCURED,
  SUCCESS,
}
export function useSubmitHouseholdExpensesV2({
  loanApplicationId,
  householdId,
  needHemCheck = true,
  navigateBackToLoanApplicationExpenses,
}: {
  loanApplicationId?: string;
  householdId?: string;
  needHemCheck: boolean;
  navigateBackToLoanApplicationExpenses: () => void;
}) {
  const [createHouseholdExpense, { loading: createHouseholdExpenseLoadingV2 }] =
    useCreateHouseholdMutation();

  const [
    updateHouseholdExpensesV2,
    { loading: updateHouseholdExpensesLoadingV2 },
  ] = useUpdateHouseholdExpensesMutation();

  const [submitHouseholdErrorMessageV2, setSubmitHouseholdErrorMessageV2] =
    useState<string | null>(null);

  const { handleHouseholdExpenseValidationError } =
    useHandleHouseholdExpenseValidationError();

  const setExpensesFormValues = useSetRecoilState(expensesFormAtom);

  const submitHouseholdExpensesV2 = async (
    values: CombinedExpensesFormValues,
  ) => {
    const { selectedBorrowers, dependentsCount, livingSituation, ...others } =
      values;
    if (!loanApplicationId) {
      return;
    }

    const expenses: AllExpensesTypeFormValues = others;
    const parsedLivingSituation = parseEnumType(
      Living_Situation_Input_Enum,
      livingSituation,
    );
    if (parsedLivingSituation == null) {
      setSubmitHouseholdErrorMessageV2(
        t('Content.AddHouseholdExpense.Error.FailAddHouseholdExpense'),
      );
      captureException(
        'Unable to handle new enum of living situation while creating household expenses - useAddHouseholdExpense',
        { livingSituation },
      );
      return;
    }

    setExpensesFormValues(null);

    // Edit if it has household id
    if (householdId) {
      const variables: UpdateHouseholdExpensesMutationVariables = {
        data: {
          household_id: householdId,
          household: {
            adult: 0,
            children: dependentsCount || 0,
          },
          living_situation: parsedLivingSituation,
          expenses: formatToExpensesInputV2(expenses),
          applicant_ids: selectedBorrowers || [],
        },
        skip_hem_check: !needHemCheck,
      };

      const [res, error] = await safelyCallMutation(updateHouseholdExpensesV2, {
        variables,
        refetchQueries: [
          refetchYourExpensesQuery({ loanApplicationId }),
          refetchGetLoanApplicantsQuery({ loanApplicationId }),
          refetchReviewLoanApplicationQuery({ loanApplicationId }),
          refetchConditionalApprovalGetReviewLoanApplicationQuery({
            loanApplicationId,
          }),
        ],
        awaitRefetchQueries: true,
        context: {
          sentryContext: {
            loanApplicationId,
            householdId,
          },
        },
      });

      if (res == null || error) {
        setSubmitHouseholdErrorMessageV2(
          t('Content.Common.Error.FailUpdateHouseholdExpenses'),
        );
        return;
      }

      if (res.data?.set_household_expenses.error_type) {
        handleHouseholdExpenseValidationError({
          error: res.data?.set_household_expenses.error_type,
          values,
          type: 'EDIT',
          loanApplicationId,
          householdId,
        });
        return;
      }
      navigateBackToLoanApplicationExpenses();
      return;
    }

    // Add household if no household id
    const variables: CreateHouseholdMutationVariables = {
      input: {
        household: {
          adult: 0,
          children: dependentsCount || 0,
        },
        living_situation: parsedLivingSituation,
        loan_application_id: loanApplicationId,
        expenses: formatToExpensesInputV2(expenses),
        applicant_ids: selectedBorrowers || [],
      },
      skip_hem_check: !needHemCheck,
    };

    const [res, error] = await safelyCallMutation(createHouseholdExpense, {
      variables,
      refetchQueries: [
        refetchYourExpensesQuery({ loanApplicationId }),
        refetchGetLoanApplicantsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

    if (res == null || error) {
      setSubmitHouseholdErrorMessageV2(
        t('Content.AddHouseholdExpense.Error.FailAddHouseholdExpense'),
      );
      return;
    }

    if (res?.data?.create_household.error_type) {
      handleHouseholdExpenseValidationError({
        error: res.data.create_household.error_type,
        values,
        type: 'ADD',
        loanApplicationId,
      });
      return;
    }

    navigateBackToLoanApplicationExpenses();
  };

  return {
    submitHouseholdExpensesV2,
    submitHouseholdExpenseLoadingV2:
      createHouseholdExpenseLoadingV2 || updateHouseholdExpensesLoadingV2,
    submitHouseholdErrorMessageV2,
  };
}
