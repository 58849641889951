import { gql } from '@apollo/client';

import {
  LoanAppForGtmEventFragmentDoc,
  refetchGetViewerLoanApplicationsQuery,
  useSubmitLoanApplicationMutation,
} from '../../generated/graphql';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';

export const SUBMIT_LOAN_APPLICATION_MUTATION = gql`
  mutation SubmitLoanApplication($loanApplicationId: uuid!) {
    submit_loan_application(loan_application_id: $loanApplicationId) {
      loan_application_id
      error_type
      loan_application {
        ...LoanAppForGtmEvent
      }
    }
  }

  ${LoanAppForGtmEventFragmentDoc}
`;

export default function useSubmitLoanApplication() {
  const [submit, result] = useSubmitLoanApplicationMutation();

  return {
    submitLoanApplication: async (loanApplicationId: string) =>
      safelyCallMutation(submit, {
        variables: { loanApplicationId },
        refetchQueries: [refetchGetViewerLoanApplicationsQuery()],
        awaitRefetchQueries: true,
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      }),
    ...result,
  };
}
