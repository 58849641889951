import { useIsFocused } from '@react-navigation/native';
import { useEffect, useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  Loan_Application_Type_Enum,
  useCreateDocusignEmbeddedContractMutation,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { NoLoanApplicationFound } from '../components/NoLoanApplicationFound';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';
import { useLoanApplication } from '../utils/useLoanApplication';

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_OFFER_ESIGN>;

function YourUnloanOfferEsignBase({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId || '';
  const [shouldShowErrorScreen, setShouldShowErrorScreen] = useState(false);
  const [isNavigatingToDocusign, setIsNavigatingToDocusign] = useState(false);
  const [docusignContractLink, setDocusignContractLink] = useState<
    string | null
  >(null);
  const isScreenFocused = useIsFocused();
  const { loading, loanApplicationType } =
    useLoanApplication(loanApplicationId);

  const [
    createDocusignEmbeddedContract,
    { loading: createDocusignEmbeddedContractLoading },
  ] = useCreateDocusignEmbeddedContractMutation();

  const openDocusignContract = async () => {
    if (!docusignContractLink) {
      return;
    }

    setIsNavigatingToDocusign(true);
    // TODO: separate this for native implementation
    window.location.href = docusignContractLink;
  };

  useEffect(() => {
    if (!loanApplicationId || !isScreenFocused) {
      return;
    }
    const getDocusignLink = async () => {
      setDocusignContractLink(null);

      const [res] = await safelyCallMutation(createDocusignEmbeddedContract, {
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
        variables: {
          loan_application_id: loanApplicationId,
        },
      });

      const docusignContractLinkRes =
        res?.data?.create_docusign_embedded_contract.docusign_contract_link;

      // Only do state update when screen is mounted/focused
      if (isScreenFocused) {
        if (!docusignContractLinkRes) {
          setShouldShowErrorScreen(true);
          return;
        }
        setDocusignContractLink(docusignContractLinkRes);
      }
    };

    if (!docusignContractLink) {
      getDocusignLink();
    }
  }, [
    createDocusignEmbeddedContract,
    docusignContractLink,
    isScreenFocused,
    loanApplicationId,
  ]);

  const isTopUpApplication =
    loanApplicationType === Loan_Application_Type_Enum.TopUp;

  const headerTitle = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariationEsign.Title')
        : t('Content.YourUnloanOfferEsign.Title'),
    [isTopUpApplication],
  );

  const headerCaption = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariationEsign.Subtitle')
        : t('Content.YourUnloanOfferEsign.Subtitle'),
    [isTopUpApplication],
  );

  const buttonLabel = useMemo(
    () =>
      isTopUpApplication
        ? t('Content.YourUnloanVariationEsign.ViewAndAcceptOfferButtonLabel')
        : t('Content.YourUnloanOfferEsign.ViewAndAcceptOfferButtonLabel'),
    [isTopUpApplication],
  );

  const descriptionText = useMemo(() => {
    if (isTopUpApplication) {
      return t('Content.YourUnloanVariationEsign.Caption');
    }

    return (
      <>
        <StyledText variant="body">
          {t('Content.YourUnloanOfferEsign.CaptionLine1')}
        </StyledText>
        <Link variant="body" href={t('Link.UnloanHomeLoanTermsAndConditions')}>
          {t('Content.YourUnloanOfferEsign.CaptionTermsAndConditionLink')}
        </Link>
        <StyledText variant="body">
          {t('Content.YourUnloanOfferEsign.CaptionLine2')}
        </StyledText>
      </>
    );
  }, [isTopUpApplication]);

  if (
    createDocusignEmbeddedContractLoading ||
    isNavigatingToDocusign ||
    loading
  ) {
    return (
      <LoanApplicationScreenContainer>
        <ScreenLoadingContainer loading centered flex={1} />
      </LoanApplicationScreenContainer>
    );
  }

  if (!loanApplicationId) {
    return <NoLoanApplicationFound />;
  }

  if (shouldShowErrorScreen) {
    return (
      <LoanApplicationScreenContainer pb="m" px="m">
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.YourUnloanOfferEsign.NoLinkErrorTitle')}
          caption={t('Content.YourUnloanOfferEsign.NoLinkErrorCaption')}
          contentContainer
          alignSelf="center"
        />
        <Button
          py="m"
          mt="m"
          width="100%"
          label={t('Content.YourUnloanOfferEsign.NoLinkErrorButton')}
          testID={TestID.YourUnloanOfferEsign.NoLinkErrorButton}
          onPress={() => {
            setShouldShowErrorScreen(false);
            navigation.navigate(Screen.MAIN_NAVIGATOR, {
              screen: Screen.HOME_DASHBOARD,
              params: {
                screen: Screen.HOME,
              },
            });
          }}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: {
      id: loanApplicationId,
      type: loanApplicationType,
    },
  });
  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer pb="m" px="m">
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={headerTitle}
        caption={headerCaption}
        contentContainer
        alignSelf="center"
      />
      <StyledText variant="body" pt="m" alignSelf="center" textAlign="center">
        {descriptionText}
      </StyledText>
      <Button
        py="m"
        mt="m"
        width="100%"
        label={buttonLabel}
        testID={TestID.YourUnloanOfferEsign.ViewAndAcceptOfferButton}
        onPress={openDocusignContract}
      />
    </LoanApplicationScreenContainer>
  );
}

export const YourUnloanOfferEsign = withAuthenticationRequired(
  YourUnloanOfferEsignBase,
);
