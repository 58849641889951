import { createStackNavigator } from '@react-navigation/stack';

import { IdentityVerificationPendingActionSheetV2 } from '../../ActionSheet/screens/IdentityVerificationPendingActionSheetV2';
import { IDVerificationConsentActionSheetV2 } from '../../ActionSheet/screens/IDVerificationConsentActionSheetV2';
import { IdentificationV2StackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import {
  ActionSheetPreset,
  DefaultModalV2NavigatorOptions,
} from '../../utils/NavConstants';
import BorrowerFormContext from '../components/BorrowerFormContext';
import { BorrowerDetails } from '../screens/BorrowerDetails';
import { BorrowerIdentityVerification } from '../screens/BorrowerIdentityVerification';
import { InitialBorrowerFormV2ContextValues } from '../utils/borrowerFormUtil';

const IdentificationV2Stack =
  createStackNavigator<IdentificationV2StackParams>();
export const IdentificationV2Navigator = () => (
  <BorrowerFormContext initialValues={InitialBorrowerFormV2ContextValues}>
    <IdentificationV2Stack.Navigator>
      <IdentificationV2Stack.Group
        screenOptions={DefaultModalV2NavigatorOptions}
      >
        <IdentificationV2Stack.Screen
          name={Screen.IDENTIFICATION_V2_YOUR_INFORMATION}
          component={BorrowerDetails}
          options={{
            title: getPageTitle(Screen.IDENTIFICATION_V2_YOUR_INFORMATION),
          }}
        />
        <IdentificationV2Stack.Screen
          name={Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE}
          component={BorrowerIdentityVerification}
          options={{
            title: getPageTitle(
              Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE,
            ),
          }}
        />
      </IdentificationV2Stack.Group>
      <IdentificationV2Stack.Group screenOptions={ActionSheetPreset}>
        <IdentificationV2Stack.Screen
          name={Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT}
          component={IDVerificationConsentActionSheetV2}
          options={{
            title: getPageTitle(
              Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT,
            ),
            headerShown: false,
          }}
        />
        <IdentificationV2Stack.Screen
          name={Screen.IDENTITY_VERIFICATION_PENDING_V2}
          component={IdentityVerificationPendingActionSheetV2}
          options={{
            title: getPageTitle(Screen.IDENTITY_VERIFICATION_PENDING_V2),
            headerShown: false,
          }}
        />
      </IdentificationV2Stack.Group>
    </IdentificationV2Stack.Navigator>
  </BorrowerFormContext>
);
