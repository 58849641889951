import { DocumentIconType } from '../components/DocumentRow';

export function mapMimeTypeToDocumentIconType(
  mimeType: string,
): DocumentIconType {
  switch (mimeType) {
    case 'application/pdf':
      return 'document';
    case 'image/png':
    case 'image/jpeg':
      return 'image';
    default:
      return 'document';
  }
}
