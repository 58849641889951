import { useDripsyTheme } from 'dripsy';

import { LoanDataProps } from '../types';
import { LoanCircularProgressBar } from './LoanCircularProgressBar';

type HomeLoanProgressBarProps = {
  loading: boolean;
  loanData: LoanDataProps;
};

export function HomeLoanProgressBar({
  loading,
  loanData,
}: HomeLoanProgressBarProps) {
  const { theme } = useDripsyTheme();

  return (
    <LoanCircularProgressBar
      loading={loading}
      loanData={loanData}
      secondaryBarColor={
        loading
          ? theme.colors.$buttonSecondaryBg
          : theme.colors.$buttonSecondaryBg
      }
    />
  );
}
