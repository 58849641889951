import { IConfig } from '../config';

export type BiocatchConfig = {
  biocatchScriptURL: string;
  biocatchWupServerURL: string;
  biocatchLogServerURL: string;
  biocatchCustomerId: string;
};

export function getBiocatchConfig(
  config: Partial<IConfig>,
): BiocatchConfig | undefined {
  const {
    biocatchScriptURL,
    biocatchWupServerURL,
    biocatchLogServerURL,
    biocatchCustomerId,
  } = config;

  if (
    !biocatchScriptURL ||
    !biocatchWupServerURL ||
    !biocatchLogServerURL ||
    !biocatchCustomerId
  ) {
    return undefined;
  }

  return {
    biocatchScriptURL,
    biocatchWupServerURL,
    biocatchLogServerURL,
    biocatchCustomerId,
  };
}
