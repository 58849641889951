import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function CloseIcon({ size, color, ...props }: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 30 30" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
        fill="#767680"
        fillOpacity={0.12}
      />
      <Path
        d="M9.922 20.453c-.39-.39-.375-1.055-.008-1.422l3.664-3.664-3.664-3.656c-.367-.367-.383-1.031.008-1.422.383-.39 1.047-.375 1.422-.008L15 13.945l3.656-3.656a1.024 1.024 0 011.422.008c.39.383.383 1.039.008 1.414l-3.656 3.656 3.656 3.664a1.014 1.014 0 01-.008 1.414c-.39.39-1.039.39-1.422.008L15 16.797l-3.656 3.664c-.375.367-1.04.383-1.422-.008z"
        fill="#3C3C43"
        fillOpacity={0.6}
      />
    </Svg>
  );
}
