import { isWeb } from '../../utils/platformUtils';

/**
 * React navigation treats modal as a screen
 * Which means they have their own path
 * In general, we don't want to display modal as a screen (so we don't run into problem
 * of refreshing Modal screen & user loses the ability to navigate back to other screen)
 * To fix this, we can _hide_ the Modal & Action Sheet from path, so the moment user refreshes, user doesn't lose their current screen state
 */
const MODAL_SUFFIX = 'modal';
const ACTION_SHEET_SUFFIX = 'action-sheet';
export function shouldHideScreenFromPath(routeName: string | undefined) {
  return (
    (routeName?.includes(MODAL_SUFFIX) ||
      routeName?.includes(ACTION_SHEET_SUFFIX)) &&
    isWeb
  );
}
