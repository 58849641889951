import { Cb_TransactionType } from '../generated/graphql';

type Transaction = Array<{
  type: Cb_TransactionType;
  date: string;
  amount: number;
}>;

const TRANSACTIONS_MOCK: Transaction = [
  {
    type: Cb_TransactionType.Withdrawal,
    date: '22 Aug 2021',
    amount: 12000,
  },
  {
    type: Cb_TransactionType.Disbursement,
    date: '20 Mar 2021',
    amount: 12000,
  },
  {
    type: Cb_TransactionType.Repayment,
    date: '21 Aug 2021',
    amount: 12000,
  },
];

const MOCK_CATEGORY_OPTIONS = [
  {
    value: '1',
    label: 'Any',
  },
  {
    value: '2',
    label: 'Repayment',
  },
  {
    value: '3',
    label: 'Interest Charged',
  },
  {
    value: '4',
    label: 'Deposit',
  },
  {
    value: '5',
    label: 'Withdrawal',
  },
];

export { TRANSACTIONS_MOCK, MOCK_CATEGORY_OPTIONS };
