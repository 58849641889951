import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import {
  BizaAccount,
  DataSharingEventType,
  Status,
} from '../../DataSharingConfirmation/types';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { SettingStackNavigationProp } from '../navigation/types';
import {
  useApproveDataSharing,
  useCancelDataSharing,
  useDeclineDataSharing,
  useDisableDataSharing,
  useEnableDataSharing,
} from '../utils/useDataSharing';
import ErrorMessage from './ErrorMessage';

const openActionSheet = (
  navigation: SettingStackNavigationProp<Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>,
  accountId: BizaAccount['id'],
  eventType: Exclude<DataSharingEventType, DataSharingEventType.Approve>,
  requestId?: string,
) => {
  if (eventType === DataSharingEventType.Enable) {
    // OPEN MODAL
    navigation.navigate(Screen.DATA_SHARING_CONFIRMATION_MODAL, {
      screen: Screen.DATA_SHARING_CURRENT_STATUS,
      params: { accountId },
    });
  } else {
    // OPEN ACTION SHEET
    const navParams = {
      accountId,
      dataSharingEventType: eventType as Exclude<
        DataSharingEventType,
        DataSharingEventType.Enable | DataSharingEventType.Approve
      >,
      requestId,
    };
    navigation.navigate(
      ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION,
      navParams,
    );
  }
};

const PendingRequestMessage: React.FC = () => (
  <>
    <StyledText
      testID={TestID.ManageDataSharing.DataSharingPendingRequestMessage}
      variant="caption"
      mx="m"
    >
      {t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.ApprovalRequestMessage',
      )}
    </StyledText>
    <Separator spacer />
  </>
);

type PendingRequestButtonsProps = {
  onApproveLoading: boolean;
  onApprove: () => void;
  onDeclineLoading: boolean;
  onDecline: () => void;
};

const PendingRequestButtons: React.FC<PendingRequestButtonsProps> = ({
  onApprove,
  onApproveLoading,
  onDeclineLoading,
  onDecline,
}) => (
  <Box
    mt="l"
    mx="m"
    flexDirection="row"
    bottom={0}
    justifyContent="space-between"
    alignItems="stretch"
  >
    <Button
      secondary
      label={t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.DeclineRequest',
      )}
      color="error"
      flexBasis={0}
      flexGrow={1}
      mr="s"
      onPress={onDecline}
      showSpinner={onDeclineLoading}
      disabled={onApproveLoading || onDeclineLoading}
    />
    <Button
      label={t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.ApproveRequest',
      )}
      flexBasis={0}
      flexGrow={1}
      ml="s"
      onPress={onApprove}
      showSpinner={onApproveLoading}
      disabled={onApproveLoading || onDeclineLoading}
    />
  </Box>
);

const CancelButton: React.FC<{
  onCancel: () => void;
  cancelLoading: boolean;
}> = ({ onCancel, cancelLoading }) => (
  <ListRowGroup
    mx={0}
    overflow="visible"
    testID={TestID.ManageDataSharing.DataSharingChangeStatus}
  >
    <ListRow
      label={t(
        'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingPending.CancelRequest',
      )}
      color="error"
      isButton
      onPress={onCancel}
      disabled={cancelLoading}
      loading={cancelLoading}
      useArrow={false}
      last
    />
  </ListRowGroup>
);

const PendingRequest: React.FC<{
  navigation: SettingStackNavigationProp<Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>;
  isMyResponsePending: boolean;
  accountId: string;
  requestId: string;
}> = ({ navigation, isMyResponsePending, accountId, requestId }) => {
  const {
    callDataSharingMutation: approveMutation,
    dataSharingError: approveError,
    dataSharingLoading: approveLoading,
  } = useApproveDataSharing(accountId, requestId);

  const { dataSharingError: declineError, dataSharingLoading: declineLoading } =
    useDeclineDataSharing(accountId, requestId);

  const { dataSharingError: cancelError, dataSharingLoading: cancelLoading } =
    useCancelDataSharing(accountId, requestId);

  // Do mutation directly on approve
  const onApprove = useCallback(() => approveMutation(), [approveMutation]);

  const onDecline = useCallback(() => {
    openActionSheet(
      navigation,
      accountId,
      DataSharingEventType.Decline,
      requestId,
    );
  }, [navigation, accountId, requestId]);

  const onCancel = useCallback(() => {
    openActionSheet(
      navigation,
      accountId,
      DataSharingEventType.Cancel,
      requestId,
    );
  }, [navigation, accountId, requestId]);

  if (approveError || declineError || cancelError)
    return (
      <ErrorMessage
        message={t('Content.DataSharingPreferences.Error.MutationError')}
      />
    );

  if (isMyResponsePending) {
    return (
      <>
        <PendingRequestMessage />
        <PendingRequestButtons
          onApprove={onApprove}
          onApproveLoading={approveLoading}
          onDecline={onDecline}
          onDeclineLoading={declineLoading}
        />
      </>
    );
  }

  return (
    <CancelButton
      onCancel={onCancel}
      cancelLoading={approveLoading || declineLoading || cancelLoading}
    />
  );
};

const DataSharingButton: React.FC<{
  accountId: BizaAccount['id'];
  status: Status;
  label: string;
  handleDataSharing: () => void;
}> = ({ accountId, status, label, handleDataSharing }) => {
  const { dataSharingError: enableError, dataSharingLoading: enableLoading } =
    useEnableDataSharing(accountId);

  const { dataSharingError: disableError, dataSharingLoading: disableLoading } =
    useDisableDataSharing(accountId);

  if (enableError || disableError)
    return (
      <ErrorMessage
        message={t('Content.DataSharingPreferences.Error.MutationError')}
      />
    );

  return (
    <ListRow
      label={label}
      color={status === Status.Enabled ? 'error' : 'link'}
      isButton
      onPress={handleDataSharing}
      disabled={enableLoading || disableLoading}
      loading={enableLoading || disableLoading}
      useArrow={false}
      last
    />
  );
};

type DataSharingChangeStatusProps = {
  status: Status;
  isMyResponsePending: boolean;
  accountId: string;
  requestId: string | undefined;
};

const DataSharingChangeStatus: React.FC<DataSharingChangeStatusProps> = ({
  status,
  isMyResponsePending,
  accountId,
  requestId,
}) => {
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_JOINT_ACCOUNT_PREFERENCES>
    >();

  const handleDataSharing = useCallback(() => {
    openActionSheet(
      navigation,
      accountId,
      status === Status.Enabled
        ? DataSharingEventType.Disable
        : DataSharingEventType.Enable,
    );
  }, [navigation, accountId, status]);

  if (status === Status.Pending) {
    return requestId ? (
      <PendingRequest
        navigation={navigation}
        isMyResponsePending={isMyResponsePending}
        requestId={requestId}
        accountId={accountId}
      />
    ) : (
      <ErrorMessage
        message={t('Content.DataSharingPreferences.Error.UnableToShareData')}
      />
    );
  }

  return (
    <ListRowGroup
      mx={0}
      overflow="visible"
      testID={TestID.ManageDataSharing.DataSharingChangeStatus}
    >
      <DataSharingButton
        accountId={accountId}
        status={status}
        label={
          status === Status.Enabled
            ? t(
                'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingEnabled.ChangeStatusMessage',
              )
            : t(
                'Content.DataSharingPreferences.MenuItems.JointAccounts.DataSharingDisabled.ChangeStatusMessage',
              )
        }
        handleDataSharing={handleDataSharing}
      />
    </ListRowGroup>
  );
};

export default DataSharingChangeStatus;
