import { Pressable, SxProp, Text, useSx, View } from 'dripsy';
import { useState } from 'react';
import * as React from 'react';
import Animated from 'react-native-reanimated';

import {
  useHoverContext,
  withHoverContextProvider,
} from '../../utils/useHoverContext';
import { Button } from '../atoms/Button';
import { StyledIcon } from '../atoms/StyledIcon';
import { LoadingRow } from '../molecules/LoadingRow';
import { ChevronRightIcon } from '../svgs/ChevronRightIcon';
import { useCardChevronTranslateXAnimatedStyle } from '../utils/useCardChevronTranslateXAnimatedStyle';
import { useCardHoverShadowStyle } from '../utils/useCardHoverShadowStyle';

type UpcomingCardProps = {
  sx?: SxProp;
  children?: React.ReactNode;
  testID?: string;
};

function BaseUpcomingCard({
  children,
  sx: sxProps = {},
  testID,
}: UpcomingCardProps) {
  const [isHover, setIsHover] = useHoverContext();

  const shadowProps = useCardHoverShadowStyle({
    isHover,
    excludeInitialShadow: true,
  });

  return (
    <View
      testID={testID}
      onPointerEnter={() => setIsHover(true)}
      onPointerLeave={() => setIsHover(false)}
      sx={{
        backgroundColor: '$inputBackground',
        borderRadius: '$card',
        overflow: 'visible',
        cursor: 'unset',
        ...shadowProps,
        ...sxProps,
      }}
    >
      {children}
    </View>
  );
}

export const UpcomingCard = withHoverContextProvider(BaseUpcomingCard);

type TransferRowProps = {
  label?: string;
  amountLabel?: string;
  caption?: string;
  subCaption?: string;
  onPress?: () => void;
  loading?: boolean;
  isMultiRow?: boolean;
};

export function TransferRow({
  label,
  amountLabel,
  caption,
  subCaption,
  onPress,
  loading,
  isMultiRow,
}: TransferRowProps) {
  const [isHover, setIsHover] = useHoverContext();

  const [rowIsHover, setRowIsHover] = useState(false);

  const onHover = (newIsHover: boolean) => {
    setIsHover(newIsHover);
    setRowIsHover(newIsHover);
  };

  const animatedChevronStyle = useCardChevronTranslateXAnimatedStyle(
    isMultiRow ? rowIsHover : isHover,
  );

  if (loading) {
    return <LoadingRow />;
  }

  const content = (
    <>
      <View>
        {label ? (
          <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
            {label}
          </Text>
        ) : null}
        {caption ? (
          <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
            {caption}
          </Text>
        ) : null}

        {subCaption ? <Text variant="caption">{subCaption}</Text> : null}
      </View>

      <View sx={{ flexDirection: 'row' }}>
        <Text variant="sBody">{amountLabel}</Text>
        {onPress ? (
          <View sx={{ ml: '$16', mt: '$4' }}>
            <Animated.View style={animatedChevronStyle}>
              <ChevronRightIcon size={14} />
            </Animated.View>
          </View>
        ) : null}
      </View>
    </>
  );

  const sxProps: SxProp = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingX: '$16',
    paddingY: '$10',
    cursor: onPress ? 'pointer' : 'unset',
  };

  if (onPress) {
    return (
      <Pressable
        role="button"
        sx={sxProps}
        onPress={onPress}
        onHoverIn={() => setRowIsHover(true)}
        onHoverOut={() => setRowIsHover(false)}
        onPressIn={() => onHover(true)}
        onPressOut={() => onHover(false)}
      >
        {content}
      </Pressable>
    );
  }

  return <View sx={sxProps}>{content}</View>;
}

type TransferStatusRowProps = {
  label?: React.ReactNode;
  buttonTestID?: string;
  buttonLabel?: string;
  onPress?: () => void;
  isSecondary?: boolean;
};

export function TransferRowSeparator() {
  return (
    <View
      sx={{
        borderTopColor: '$border',
        borderTopWidth: 1,
        marginX: '$16',
      }}
    />
  );
}

export function TransferStatusRow({
  label,
  buttonTestID,
  buttonLabel,
  onPress,
  isSecondary,
}: TransferStatusRowProps) {
  const sx = useSx();
  const [, setIsHover] = useHoverContext();

  return (
    <>
      <TransferRowSeparator />
      <View
        sx={{
          paddingX: '$16',
          paddingY: '$8',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {typeof label === 'string' ? (
          <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
            {label}
          </Text>
        ) : null}

        {typeof label !== 'string' ? label : null}

        {onPress ? (
          <Button
            variant="pill"
            testID={buttonTestID}
            label={buttonLabel}
            onPress={onPress}
            onPressIn={() => setIsHover(true)}
            onPressOut={() => setIsHover(false)}
            secondary={isSecondary}
            style={sx({ ml: '$16' })}
          />
        ) : null}
      </View>
    </>
  );
}

type TransferStatusPendingLabelProps = {
  showPendingIcon?: boolean;
  label?: string;
  isSecondary?: boolean;
};

export function TransferStatusPendingLabel({
  showPendingIcon,
  label,
  isSecondary,
}: TransferStatusPendingLabelProps) {
  return (
    <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
      {showPendingIcon ? (
        <StyledIcon
          color="secondaryContent"
          family="svg"
          name="time"
          size="s"
        />
      ) : null}
      <Text
        variant="caption"
        sx={{
          ml: showPendingIcon ? '$4' : undefined,
          color:
            showPendingIcon || isSecondary ? '$secondary' : '$primaryLabel',
        }}
      >
        {label}
      </Text>
    </View>
  );
}
