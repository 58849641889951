import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';

import { ActionSheetStackParams } from '../../navigation/types/navTypes';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';

type Props = StackScreenProps<
  ActionSheetStackParams,
  ActionSheetType.SOCIAL_SIGN_IN
>;
export const SocialSignInActionSheet: React.FC<Props> = ({ navigation }) => (
  <ActionSheet
    closeModal={navigation.goBack}
    title="Add A Sign In"
    actionList={[
      {
        label: 'Continue With Apple',
        icon: 'logo-apple',
        color: 'primaryContent',
        onPress: navigation.goBack,
      },
      {
        label: 'Continue With Google',
        icon: 'svg-google',
        color: 'primaryContent',
        onPress: navigation.goBack,
        outline: true,
        borderColor: 'border',
      },
    ]}
  />
);
