import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { getPageTitle, Screen } from '../../navigation/types/screens';
import { TranslucentModalNavOptions } from '../../utils/NavConstants';
import { TransactionFilter } from '../screens/TransactionFilter';

const Stack = createStackNavigator();
export const TransactionNavigator: React.FC = () => (
  <Stack.Navigator screenOptions={TranslucentModalNavOptions}>
    <Stack.Screen
      name={Screen.TRANSACTION_FILTER}
      component={TransactionFilter}
      options={{
        title: getPageTitle(Screen.TRANSACTION_FILTER),
      }}
    />
  </Stack.Navigator>
);
