import * as Localization from 'expo-localization';
import { I18n, TranslateOptions } from 'i18n-js';
import memoize from 'lodash/memoize';

import type { TranslateKeys } from './constants';
import { DEFAULT_LOCALE, TRANSLATIONS } from './constants';

const MissingTranslationPrefix = 'MT| ';

const i18n = new I18n(TRANSLATIONS);

export const translate = memoize(
  (key: TranslateKeys, config?: TranslateOptions): string => {
    const translated = i18n.t(key, config);
    return translated?.startsWith(MissingTranslationPrefix) ? key : translated;
  },
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

let isInitialized = false;
export function initializeLocalization(): void {
  if (isInitialized) {
    return;
  }
  translate.cache.clear?.();
  i18n.defaultLocale = DEFAULT_LOCALE;
  i18n.enableFallback = true;
  i18n.locale = Localization.locale.substring(0, 2);
  i18n.missingBehavior = 'guess';
  i18n.missingTranslationPrefix = MissingTranslationPrefix;
  isInitialized = true;
}

type TranslateFn = typeof translate;
export type { TranslateFn };
