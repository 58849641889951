import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import {
  AppStackScreenProps,
  DebtsWizardV2StackParams,
} from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { DetectedDebtListV2 } from '../screens/DetectedDebtListV2';
import { DebtsWizardContextProviderV2 } from '../utils/DebtsWizardFormProviderV2';
import { useAllLiabilitiesQuery } from '../utils/useAllLiabilitiesQuery';
import { buildDebtsWizard } from './utils/debtsScreenHelpers';

type V2Props = AppStackScreenProps<Screen.DEBTS_WIZARD_V2_MODAL>;

const V2Stack = createStackNavigator<DebtsWizardV2StackParams>();
export type DebtsWizardV2StackScreenProps = React.ComponentProps<
  typeof V2Stack.Screen
>;

export function DebtsWizardNavigatorV2({ route: { params } }: V2Props) {
  const loanApplicationId = params?.params?.loanApplicationId;
  // When navigated to this wizard navigator
  // query for AllLiabilities will be loaded already.
  const { liabilitiesForDebtsWizard } = useAllLiabilitiesQuery({
    loanApplicationId,
  });
  return (
    <DebtsWizardContextProviderV2>
      <V2Stack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
        <V2Stack.Screen
          name={Screen.DEBTS_WIZARD_V2_DEBT_LIST}
          component={DetectedDebtListV2}
          options={{
            title: getPageTitle(Screen.DEBTS_WIZARD_V2_MODAL),
          }}
        />
        {buildDebtsWizard(liabilitiesForDebtsWizard, {
          loanApplicationId,
        }).map((screenProps) => (
          <V2Stack.Screen key={screenProps.name} {...screenProps} />
        ))}
      </V2Stack.Navigator>
    </DebtsWizardContextProviderV2>
  );
}
