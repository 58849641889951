import { TestID } from '../../../testID/constants';
import { Button } from '../../ui/atoms/Button';

type Props = {
  onPress: () => void;
};

export function ApplyForAnotherLoanButton({ onPress }: Props) {
  return (
    <Button
      secondary
      icon="add"
      variant="pill"
      iconFamily="svg"
      iconSize="s"
      label={t('Content.Home.Apply')}
      testID={TestID.ApplyForAnotherLoanButton}
      onPress={onPress}
    />
  );
}
