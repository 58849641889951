import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { createBox } from '../utils/createBox';
import { Box } from './Box';

const BaseCard = createBox(TouchableOpacity);

export type Props = React.ComponentProps<typeof BaseCard> & {
  shadowed?: boolean;
};

const commonProps = {
  bg: 'shapeBg',
  borderRadius: 'card',
} as const;

export const cardShadowProps = {
  shadowColor: 'secondaryContent',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 10,
  elevation: 4,
} as const;

export function Card({ shadowed, hitSlop, ...props }: Props) {
  const shadow = shadowed && cardShadowProps;

  if (props.onPress) {
    return (
      <BaseCard
        activeOpacity={0.5}
        role="button"
        hitSlop={hitSlop}
        {...commonProps}
        {...props}
        {...shadow}
      />
    );
  }

  return <Box {...commonProps} {...props} {...shadow} />;
}
