import { View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormSelectV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import {
  useGetStateQuery,
  useGetStreetTypesQuery,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import {
  AddressFields,
  AddressInputType,
  AddressType,
} from '../types/addressTypes';

const useStateOptionsQuery = () => {
  const { data, loading } = useGetStateQuery();
  return {
    data:
      data?.state.map(({ code }) => ({
        label: code,
        value: code,
      })) ?? [],
    loading,
  };
};

const useStreetOptionsQuery = () => {
  const { data, loading } = useGetStreetTypesQuery();
  return {
    data:
      data?.street_type.map((street) => ({
        label: street.street_type_long,
        value: street.street_type_long,
      })) ?? [],
    loading,
  };
};

type Props = {
  screen: Screen;
  groupName: AddressType;
  setValueOfStateField: (value: string) => void;
  testIdAddressType: 'UpdateResidentialAddress' | 'UpdateMailingAddress';
};

export const ManualAddressInputs = ({
  screen,
  // errorMessageFn,
  groupName,
  setValueOfStateField,
  testIdAddressType,
}: Props) => {
  const { data: stateOptions, loading: isStateOptionsLoading } =
    useStateOptionsQuery();
  const { data: streetOptions, loading: isStreetOptionsLoading } =
    useStreetOptionsQuery();

  return (
    <View>
      <FormTextInputV2
        name={`${groupName}.${AddressInputType.Manual}.${AddressFields.UnitNo}`}
        label={t('Content.PersonalDetails.UpdateAddressFields.UnitNumber')}
        inputTestID={
          TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType].UnitNo
        }
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.PersonalDetails,
          screen,
          FieldInteractionKey.UnitNumber,
        )}
      />

      <FormTextInputV2
        name={`${groupName}.${AddressInputType.Manual}.${AddressFields.StreetNo}`}
        label={t('Content.PersonalDetails.UpdateAddressFields.StreetNumber')}
        inputTestID={
          TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
            .StreetNo
        }
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.PersonalDetails,
          screen,
          FieldInteractionKey.StreetNumber,
        )}
      />

      <FormTextInputV2
        name={`${groupName}.${AddressInputType.Manual}.${AddressFields.Street}`}
        label={t('Content.PersonalDetails.UpdateAddressFields.StreetName')}
        inputTestID={
          TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
            .StreetName
        }
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.PersonalDetails,
          screen,
          FieldInteractionKey.StreetName,
        )}
      />

      <FormSelectV2
        name={`${groupName}.${AddressInputType.Manual}.${AddressFields.StreetType}`}
        label={t('Content.PersonalDetails.UpdateAddressFields.StreetType')}
        items={streetOptions}
        disabled={isStreetOptionsLoading}
        testID={
          TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
            .StreetType
        }
        sx={{ mt: '$16' }}
      />

      <FormTextInputV2
        name={`${groupName}.${AddressInputType.Manual}.${AddressFields.Suburb}`}
        label={t('Content.PersonalDetails.UpdateAddressFields.Suburb')}
        inputTestID={
          TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType].Suburb
        }
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.PersonalDetails,
          screen,
          FieldInteractionKey.Suburb,
        )}
      />

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        sx={{ mt: '$16' }}
      >
        <View sx={{ pr: '$4' }} style={{ flex: 1 }}>
          <FormSelectV2
            name={`${groupName}.${AddressInputType.Manual}.${AddressFields.State}`}
            label={t('Content.PersonalDetails.UpdateAddressFields.State')}
            items={stateOptions}
            disabled={isStateOptionsLoading}
            onValueChange={(value) => setValueOfStateField(value as string)}
            testID={
              TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
                .State
            }
          />
        </View>

        <View sx={{ pl: '$4' }} style={{ flex: 1 }}>
          <FormTextInputV2
            name={`${groupName}.${AddressInputType.Manual}.${AddressFields.Postcode}`}
            type="digits"
            label={t('Content.PersonalDetails.UpdateAddressFields.Postcode')}
            inputTestID={
              TestID.PersonalDetails.UpdatePhysicalAddress[testIdAddressType]
                .Postcode
            }
            maxLength={4}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.PersonalDetails,
              screen,
              FieldInteractionKey.Postcode,
            )}
          />
        </View>
      </View>
    </View>
  );
};
