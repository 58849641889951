import { styled, Text, View } from 'dripsy';
import { Fragment } from 'react';

import { type PickerOptions } from '../../components/form/types';
import { Liability_Type_Enum } from '../../generated/graphql';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../ui/v2/List';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatLoanTerm } from '../../utils/stringHelpers';
import {
  getAccountNumberLabel,
  LoanDetailsFormField,
  LoanDetailsFormValues,
} from './LoanDetailsForm';

export type LoanDetailsFormProps = {
  liabilityType: Liability_Type_Enum;
  values: LoanDetailsFormValues;
  accountOwnerOptions: PickerOptions<string>;
};

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});

export function TopUpHomeLoanLiabilityDetails({
  liabilityType,
  values,
  accountOwnerOptions,
}: LoanDetailsFormProps) {
  const loanTermInMonths =
    (values[LoanDetailsFormField.TermInMonthsYearPart] ?? 0) +
    (values[LoanDetailsFormField.TermInMonthsMonthPart] ?? 0);

  return (
    <View>
      <CardList>
        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {t('Content.YourDebts.Form.Institution')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {values[LoanDetailsFormField.Institution]?.name}
            </Text>
          }
        />

        <Divider />

        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {getAccountNumberLabel(liabilityType)}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {values[LoanDetailsFormField.AccountNumber]}
            </Text>
          }
        />

        <Divider />

        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {t('Content.YourDebts.Form.Balance')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {formatCurrency(values[LoanDetailsFormField.Balance])}
            </Text>
          }
        />

        <Divider />

        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {t('Content.YourDebts.Form.InterestRate')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {t('Content.YourUnloan.LoanCard.InterestRate.Value', {
                interestRate: values[LoanDetailsFormField.InterestRate],
              })}
            </Text>
          }
        />

        <Divider />

        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {t('Content.YourDebts.Form.TermRemaining')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {formatLoanTerm(loanTermInMonths, true)}
            </Text>
          }
        />
      </CardList>

      <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
        {t('Content.YourDebts.Form.WhatsThePropertyAddress')}
      </SHeaderText>

      <CardList>
        <ListItem
          sx={{ py: '$16', mx: '$16' }}
          title={
            <Text variant="body" sx={{ fontWeight: '600' }}>
              {t('Content.YourDebts.Form.PropertyAddress')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {values[LoanDetailsFormField.PropertyAddress]?.displayAddress}
            </Text>
          }
        />
      </CardList>

      <SHeaderText sx={{ mt: '$32', mb: '$8' }}>
        {t('Content.YourDebts.Form.WhoAreTheAccountOwners')}
      </SHeaderText>

      <CardList>
        {accountOwnerOptions.map(({ value, label }, index) => (
          <Fragment key={value}>
            {index > 0 ? <Divider /> : null}

            <ListItem
              sx={{ py: '$16', mx: '$16' }}
              title={
                <Text variant="body" sx={{ fontWeight: '600' }}>
                  {label}
                </Text>
              }
            />
          </Fragment>
        ))}
      </CardList>
    </View>
  );
}
