import { useCallback, useEffect, useMemo } from 'react';

import {
  Autopay_Frequency_Input_Enum,
  useGetMinimumRepaymentAmountByFrequencyLazyQuery,
} from '../../generated/graphql';
import { MinimumRepaymentAmountsByFrequency } from '../types';

export const useAutopayMinimumRepaymentAmount = (
  cbaAccountId: string,
  initStartDate?: string,
) => {
  const [
    fetchMonthlyMinimumRepaymentAmount,
    {
      loading: isLoadingMonthlyMinimumRepaymentAmount,
      data: monthlyMinimumRepaymentAmountData,
      error: monthlyMinimumRepaymentAmountError,
    },
  ] = useGetMinimumRepaymentAmountByFrequencyLazyQuery({
    fetchPolicy: 'network-only', // Forces server-side calculation every time
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  useEffect(() => {
    if (initStartDate) {
      fetchMonthlyMinimumRepaymentAmount({
        variables: {
          cba_account_id: cbaAccountId,
          effective_date: initStartDate,
        },
      });
    }
  }, [cbaAccountId, fetchMonthlyMinimumRepaymentAmount, initStartDate]);

  const refetchMonthlyMinimumRepaymentAmount = useCallback(
    (newStartDate: string) =>
      fetchMonthlyMinimumRepaymentAmount({
        variables: {
          cba_account_id: cbaAccountId,
          effective_date: newStartDate,
        },
      }),
    [cbaAccountId, fetchMonthlyMinimumRepaymentAmount],
  );

  const minimumRepaymentAmountsByFrequency =
    useMemo<MinimumRepaymentAmountsByFrequency>(
      () => ({
        [Autopay_Frequency_Input_Enum.Monthly]:
          monthlyMinimumRepaymentAmountData?.calculate_minimum_repayment_amount
            ?.monthly || 0,
        [Autopay_Frequency_Input_Enum.Fortnightly]:
          monthlyMinimumRepaymentAmountData?.calculate_minimum_repayment_amount
            ?.fortnightly || 0,
        [Autopay_Frequency_Input_Enum.Weekly]:
          monthlyMinimumRepaymentAmountData?.calculate_minimum_repayment_amount
            ?.weekly || 0,
      }),
      [monthlyMinimumRepaymentAmountData],
    );

  return {
    monthlyMinimumRepaymentAmountData,
    minimumRepaymentAmountsByFrequency,
    refetchMonthlyMinimumRepaymentAmount,
    isLoadingMonthlyMinimumRepaymentAmount,
    monthlyMinimumRepaymentAmountError,
  };
};
