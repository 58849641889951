import { formatCurrency } from '../../utils/currencyHelpers';

export function checkShowLmiDisclaimer(lmiPremium: number | null | undefined) {
  return lmiPremium != null && lmiPremium > 0;
}

export function formatLmiPremiumToDisplay(
  lmiPremium: number | null | undefined,
) {
  const showLmiDisclaimer = checkShowLmiDisclaimer(lmiPremium);
  return lmiPremium != null
    ? `${formatCurrency(lmiPremium, {
        withFractionOnRoundedAmount: true,
      })}${showLmiDisclaimer ? '^' : ''}`
    : '$--';
}
