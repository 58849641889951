import { useNavigation } from '@react-navigation/native';
import { Text } from 'dripsy';

import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

export function YourBorrowingPowerDetails() {
  const navigation = useNavigation();
  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      headerText={t(
        'Content.ConditionalApproval.YourBorrowingPowerModal.Title',
      )}
      hideBackButton
    >
      <Text>
        {t('Content.ConditionalApproval.YourBorrowingPowerModal.Content')}
      </Text>
    </ModalScreenContainer>
  );
}
