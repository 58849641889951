import BaseSegmentedControl, {
  NativeSegmentedControlIOSChangeEvent,
  SegmentedControlProps,
} from '@react-native-segmented-control/segmented-control';
import { useMemo } from 'react';
import * as React from 'react';
import { NativeSyntheticEvent } from 'react-native';

import { useTheme } from '../../ui/theme';
import { FormikControlProps } from './types';

export type Props = FormikControlProps<number> & SegmentedControlProps;

export const SegmentedControl: React.FC<Props> = ({
  setFieldValue,
  style,
  ...otherProps
}) => {
  const theme = useTheme();
  const { activeFontStyle, fontStyle, segmentedContainerStyle } = useMemo(
    () => ({
      activeFontStyle: {
        fontFamily: theme.fontWeights.semiBold,
      },
      fontStyle: {
        fontFamily: theme.fontWeights.medium,
      },
      segmentedContainerStyle: {
        backgroundColor: theme.colors.segmentedControlBg,
      },
    }),
    [theme],
  );

  const onSegmentedControlChange = (
    e: NativeSyntheticEvent<NativeSegmentedControlIOSChangeEvent>,
  ) => {
    setFieldValue?.(e.nativeEvent.selectedSegmentIndex);
    otherProps?.onChange?.(e);
  };
  return (
    <BaseSegmentedControl
      {...otherProps}
      style={[segmentedContainerStyle, style]}
      onChange={onSegmentedControlChange}
      // Right now we're forcing light mode
      // This should follow useColorScheme or the useTheme() to respect the theme color
      appearance="light"
      activeFontStyle={activeFontStyle}
      fontStyle={fontStyle}
    />
  );
};
