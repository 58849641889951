import { gql } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  GrantDetailsFragmentDoc,
  refetchGetMyGrantsQuery,
  useRevokeGrantMutation,
} from '../../generated/graphql';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Alert } from '../../ui/atoms/Alert';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export const RevokeGrant = gql`
  mutation RevokeGrant($grantId: String!) {
    revoke_grant(grantId: $grantId) {
      success
      user {
        grant(grantId: $grantId) {
          ...GrantDetails
        }
      }
    }
  }
  ${GrantDetailsFragmentDoc}
`;

type Props =
  ActionSheetScreenProps<ActionSheetType.STOP_DATA_SHARE_CONFIRMATION> & {
    // For storybook only
    _initiallyHasRevoked?: boolean;
  };

const NavigateToManageDataSharingDelayInMs = 2000;

export function StopDataShareConfirmationActionSheet({
  navigation,
  route,
  _initiallyHasRevoked: initiallyHasRevoked = false,
}: Props) {
  const { grantId, brandName } = route.params;

  const [revokeGrant, { error, loading }] = useRevokeGrantMutation();
  const [hasRevoked, setHasRevoked] = useState(initiallyHasRevoked);
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onStopSharingPress = async () => {
    if (!grantId) {
      Alert.alert('No data sharing to revoke.');
      return;
    }

    const [res, _] = await safelyCallMutation(revokeGrant, {
      variables: { grantId },
      awaitRefetchQueries: true,
      refetchQueries: [refetchGetMyGrantsQuery()],
      context: {
        sentryContext: { grantId },
      },
    });

    if (res) {
      setHasRevoked(true);
    }
  };

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
      if (timerId.current != null) {
        clearTimeout(timerId.current);
      }
    },
    [],
  );

  const navigateToManageDataSharing = useRef(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.SETTINGS,
      params: {
        screen: Screen.SETTINGS_MANAGE_DATA_SHARING,
      },
    });
  });

  const onSuccessAnimationFinish = () => {
    const enableRedirect = hasRevoked && !initiallyHasRevoked;
    if (enableRedirect && isMounted.current === true) {
      timerId.current = setTimeout(() => {
        navigateToManageDataSharing.current();
      }, NavigateToManageDataSharingDelayInMs);
    }
  };

  if (hasRevoked) {
    return (
      <ActionSheet
        closeModal={navigateToManageDataSharing.current}
        title=""
        footerText={t('Content.StopDataShareConfirmation.StopDataShareSuccess')}
      >
        <GreenCheckAnimation onAnimationFinish={onSuccessAnimationFinish} />
      </ActionSheet>
    );
  }

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      title={t('Content.StopDataShareConfirmation.Title')}
      message={<Message name={brandName} />}
      actionList={[
        {
          label: t('Content.StopDataShareConfirmation.StopButton'),
          onPress: onStopSharingPress,
          showSpinner: loading,
          disabled: loading,
          testID: TestID.StopDataShareConfirmationActionSheet.StopButton,
        },
      ]}
      errorMessage={
        error
          ? t('Content.StopDataShareConfirmation.Error.FailToStopDataSharing')
          : null
      }
    />
  );
}

function Message({ name = '--' }: { name?: string }) {
  return (
    <>
      <ActionSheet.MessageText pt="m">
        {t('Content.StopDataShareConfirmation.ConfirmationMessagePara1', {
          name,
        })}
      </ActionSheet.MessageText>
      <ActionSheet.MessageText pt="m" fontWeight="medium">
        {t('Content.StopDataShareConfirmation.ConfirmationMessagePara2Header')}
      </ActionSheet.MessageText>
      <ActionSheet.MessageText pt="s">
        {t('Content.StopDataShareConfirmation.ConfirmationMessagePara2', {
          name,
        })}
      </ActionSheet.MessageText>
      <ActionSheet.MessageText pt="s" pb="m">
        {t('Content.StopDataShareConfirmation.ConfirmationMessagePara3', {
          name,
        })}
      </ActionSheet.MessageText>
    </>
  );
}
