import { useSx } from 'dripsy';

import ContentJson from '../../../assets/newFeatures/content.json';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { Screen } from '../../navigation/types/screens';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { BaseSettingsNewFeatures } from '../components/BaseSettingsNewFeatures';
import { SettingsScreenProps } from '../navigation/types';

type SettingsNewFeaturesProps =
  SettingsScreenProps<Screen.SETTINGS_NEW_FEATURES>;

function SettingsNewFeatures({ navigation }: SettingsNewFeaturesProps) {
  const sx = useSx();

  return (
    <UpliftScreenContainer
      onPressBack={navigation.goBack}
      contentContainer={false}
      containerStyle={sx({ maxWidth: 768 })}
    >
      <BaseSettingsNewFeatures content={ContentJson} />
    </UpliftScreenContainer>
  );
}

export const WithAuthenticationSettingsNewFeatures =
  withAuthenticationRequired(SettingsNewFeatures);
