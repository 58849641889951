import { createStackNavigator } from '@react-navigation/stack';

import { EnableFaceIdScreen } from '../EnableFaceId/EnableFaceId';
import { DefaultModalV2NavigatorOptions } from '../utils/NavConstants';

export enum NavigatorModalScreen {
  EnableFaceId = 'EnableFaceId',
}

export type NavigatorModalStackParamList = {
  [NavigatorModalScreen.EnableFaceId]: undefined;
};

const ModalStack = createStackNavigator<NavigatorModalStackParamList>();

export const NavigatorModal = () => (
  <ModalStack.Navigator screenOptions={DefaultModalV2NavigatorOptions}>
    <ModalStack.Screen
      name={NavigatorModalScreen.EnableFaceId}
      component={EnableFaceIdScreen}
    />
  </ModalStack.Navigator>
);
