import { FetchResult } from '@apollo/client';
import { addMonths } from 'date-fns';

import {
  Add_Current_Liability_Error_Type,
  Add_Current_Liability_Input,
  AddManualLiabilityV2MutationMutation,
  Liability_Type_Enum,
  Liability_Type_Input_Enum,
  Loan_Interest_Rate_Type_Input_Enum,
} from '../../generated/graphql';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import {
  LoanDetailsFormField,
  LoanDetailsFormValues,
} from '../components/LoanDetailsForm';
import { getRepaymentFrequencyFromLiabilityType } from './debtsScreenUtils';
import { getPropertyAddressFromFormValues } from './loanDetailsFormUtils';

type BuildAddLiabilityMutationInputParams = {
  formValues: LoanDetailsFormValues;
  loanApplicationId: string;
  selectedLiabilityType: Liability_Type_Enum;
  enableAvailableFundsCapture: boolean;
};

export function buildAddLiabilityMutationInput({
  formValues,
  loanApplicationId,
  selectedLiabilityType,
  enableAvailableFundsCapture,
}: BuildAddLiabilityMutationInputParams): Add_Current_Liability_Input {
  let propertyData: Pick<
    Add_Current_Liability_Input,
    'user_provided_property' | 'domain_api_address_input'
  > | null = null;

  const propertyAddress = getPropertyAddressFromFormValues(formValues);
  if (propertyAddress) {
    const { manualAddressInput, domainApiAddressInput } =
      parseAddressInputForMutationAction(propertyAddress);
    propertyData = {
      user_provided_property: manualAddressInput,
      domain_api_address_input: domainApiAddressInput,
    };
  }

  const interestRateType = parseEnumType(
    Loan_Interest_Rate_Type_Input_Enum,
    formValues[LoanDetailsFormField.LoanRateType],
  );

  const totalTermInMonths =
    formValues[LoanDetailsFormField.TermInMonthsYearPart] != null &&
    formValues[LoanDetailsFormField.TermInMonthsMonthPart] != null
      ? formValues[LoanDetailsFormField.TermInMonthsYearPart] +
        formValues[LoanDetailsFormField.TermInMonthsMonthPart]
      : null;

  const calculatedLimit =
    enableAvailableFundsCapture &&
    formValues[LoanDetailsFormField.AvailableBalance] != null &&
    formValues[LoanDetailsFormField.Balance] != null
      ? formValues[LoanDetailsFormField.AvailableBalance] +
        formValues[LoanDetailsFormField.Balance]
      : null;

  return {
    loan_application_id: loanApplicationId,
    account_owners: formValues[LoanDetailsFormField.AccountOwners],
    account_number: formValues[LoanDetailsFormField.AccountNumber],
    limit: formValues[LoanDetailsFormField.Limit] ?? calculatedLimit,
    balance: formValues[LoanDetailsFormField.Balance],
    type: selectedLiabilityType as unknown as Liability_Type_Input_Enum,
    term_months_end_date:
      totalTermInMonths != null
        ? addMonths(new Date(), totalTermInMonths).toISOString()
        : null,
    repayment_amount: formValues[LoanDetailsFormField.MonthlyRepaymentAmount],
    repayment_frequency: getRepaymentFrequencyFromLiabilityType(
      selectedLiabilityType,
    ),
    interest_rate: formValues[LoanDetailsFormField.InterestRate]
      ? parseFloat(formValues[LoanDetailsFormField.InterestRate])
      : null,
    institution: {
      id: formValues[LoanDetailsFormField.Institution]?.id || null,
      name: formValues[LoanDetailsFormField.Institution]?.name || '',
    },
    ...propertyData,
    ...(interestRateType
      ? {
          interest_rate_type: interestRateType,
          fixed_rate_expiry_date:
            interestRateType === Loan_Interest_Rate_Type_Input_Enum.Fixed &&
            formValues[LoanDetailsFormField.FixedRateExpiryDate]
              ? safelyFormatDate(
                  formValues[LoanDetailsFormField.FixedRateExpiryDate],
                  'yyyy-MM-dd',
                )
              : null,
        }
      : null),
    loan_term_months: totalTermInMonths,
  };
}

export function buildErrorMessageFromAddLiabilityResult(
  res: FetchResult<AddManualLiabilityV2MutationMutation> | null,
): string | null {
  if (res?.data == null) {
    return t('Content.AddCreditAccount.UnknownError');
  }

  if (
    res.data.add_current_liability.error_type ===
    Add_Current_Liability_Error_Type.DbConstraintViolation
  ) {
    return t('Content.AddCreditAccount.DbConstraintViolationError');
  }

  if (res.data.add_current_liability.error_type != null) {
    return t('Content.AddCreditAccount.UnknownError');
  }

  return null;
}
