import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function BedIcon({
  color = '#010101',
  size,
  fillOpacity = '0.95',
  ...otherProps
}: SvgIconProps) {
  return (
    <Svg
      width={resizeIconWidth(17, 11, size)}
      height={size}
      viewBox="0 0 17 11"
      fill={color}
      fillOpacity={fillOpacity}
      {...otherProps}
    >
      <Path d="M0.69207 10.7681C0.527031 10.7681 0.395846 10.7194 0.298515 10.6221C0.201185 10.5247 0.152519 10.3936 0.152519 10.2285V5.88037C0.152519 5.27523 0.313327 4.81396 0.634941 4.49658C0.956556 4.17497 1.41993 4.01416 2.02508 4.01416H14.3649C14.9743 4.01416 15.4377 4.17497 15.7551 4.49658C16.0767 4.81396 16.2375 5.27523 16.2375 5.88037V10.2285C16.2375 10.3936 16.1888 10.5247 16.0915 10.6221C15.9942 10.7194 15.863 10.7681 15.6979 10.7681H15.3171C15.1563 10.7681 15.0251 10.7194 14.9235 10.6221C14.8262 10.5247 14.7775 10.3936 14.7775 10.2285V9.28906C14.7437 9.29753 14.6823 9.30599 14.5934 9.31445C14.5046 9.31868 14.4221 9.3208 14.3459 9.3208H2.04412C1.96795 9.3208 1.88543 9.31868 1.79656 9.31445C1.71193 9.30599 1.65057 9.29753 1.61248 9.28906V10.2285C1.61248 10.3936 1.5617 10.5247 1.46014 10.6221C1.36281 10.7194 1.23374 10.7681 1.07293 10.7681H0.69207ZM1.42205 8.39404H14.9679C15.1118 8.39404 15.1838 8.31152 15.1838 8.14648V5.73438C15.1838 5.49316 15.1097 5.30062 14.9616 5.15674C14.8177 5.01286 14.6231 4.94092 14.3776 4.94092H2.01873C1.77329 4.94092 1.57651 5.01286 1.4284 5.15674C1.28029 5.30062 1.20623 5.49316 1.20623 5.73438V8.14648C1.20623 8.31152 1.27817 8.39404 1.42205 8.39404ZM1.80291 4.14746V1.83691C1.80291 1.2487 1.95525 0.806478 2.25994 0.510254C2.56886 0.209798 3.00685 0.0595703 3.57391 0.0595703H12.8161C13.3874 0.0595703 13.8254 0.209798 14.1301 0.510254C14.4347 0.806478 14.5871 1.2487 14.5871 1.83691V4.14746H12.9177V2.83984C12.9177 2.54785 12.8373 2.32357 12.6764 2.16699C12.5156 2.00618 12.285 1.92578 11.9846 1.92578H9.75652C9.4603 1.92578 9.23178 2.00618 9.07098 2.16699C8.91017 2.32357 8.82977 2.54785 8.82977 2.83984V4.14746H7.56023V2.83984C7.56023 2.54785 7.47983 2.32357 7.31902 2.16699C7.15822 2.00618 6.9297 1.92578 6.63348 1.92578H4.40545C4.10499 1.92578 3.87436 2.00618 3.71355 2.16699C3.55275 2.32357 3.47234 2.54785 3.47234 2.83984V4.14746H1.80291Z" />
    </Svg>
  );
}
