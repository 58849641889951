import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function CopyIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(22, 28, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 22 28" fill="none" {...props}>
      <Path
        d="M0 23.654c0 2.245 1.106 3.373 3.33 3.373h10.098c2.223 0 3.33-1.138 3.33-3.373v-1.987h1.88c2.213 0 3.33-1.139 3.33-3.373V9.57c0-1.321-.269-2.159-1.074-2.986l-5.425-5.51C14.706.29 13.804 0 12.654 0H8.54C6.327 0 5.21 1.139 5.21 3.373V5.36H3.33C1.117 5.36 0 6.488 0 8.733v14.921zm15.737-11.247L9.818 6.381c-.816-.838-1.493-1.02-2.696-1.02H6.94V3.404c0-1.063.57-1.676 1.687-1.676h4.748V7.24c0 1.246.602 1.837 1.837 1.837h5.027v9.185c0 1.074-.58 1.675-1.697 1.675h-1.783v-4.672c0-1.375-.161-1.977-1.02-2.858zm-.805-5.425V2.578l4.855 4.942h-4.329c-.376 0-.526-.162-.526-.538zM1.729 23.622V8.755c0-1.053.57-1.665 1.687-1.665h3.523v6.23c0 1.354.688 2.03 2.02 2.03h6.07v8.272c0 1.074-.58 1.676-1.687 1.676H3.405c-1.106 0-1.676-.602-1.676-1.676zm7.434-9.893c-.43 0-.601-.172-.601-.602v-5.64l6.133 6.242H9.163z"
        fill={color}
      />
    </Svg>
  );
}
