import { Pressable, Text } from 'dripsy';
import { FormikProps } from 'formik';
import { useEffect } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormTextInputV2 } from '../../components/form/FormikInputs';
import { FormSpacer } from '../../components/form/FormSpacer';
import { Screen } from '../../navigation/types/screens';
import { FormikFormError } from '../../ui/v2/FormError';
import { LoadingInput } from '../../ui/v2/LoadingInput';
import { ExternalAccount, WithdrawalFormField, WithdrawFormV2 } from '../types';
import { AccountDropdown } from './AccountDropdown';

type WithdrawToSectionProps = {
  screen: Screen;
  loading?: boolean;
  suggestionData: Array<ExternalAccount>;
  formProps: FormikProps<WithdrawFormV2>;
  transferFeatureDisabled?: boolean;
};

export function WithdrawToSection({
  screen,
  loading,
  suggestionData,
  formProps,
  transferFeatureDisabled,
}: WithdrawToSectionProps) {
  const isManualInput =
    formProps.values[WithdrawalFormField.IsManualAccountInput];

  const isManualInputTouched =
    formProps.touched[WithdrawalFormField.IsManualAccountInput];

  const suggestionsCount = suggestionData.length;

  useEffect(() => {
    if (!isManualInputTouched) {
      formProps.setFieldValue(
        WithdrawalFormField.IsManualAccountInput,
        !suggestionsCount,
      );
    }

    // below is needed to avoid infinite loop whenever formProps is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualInputTouched, suggestionsCount]);

  const toggleIsManualInput = () => {
    if (transferFeatureDisabled) {
      return;
    }

    formProps.setFieldValue(
      WithdrawalFormField.IsManualAccountInput,
      !isManualInput,
    );

    formProps.setTouched({
      ...formProps.touched,
      [WithdrawalFormField.ExternalAccountId]: false,
      [WithdrawalFormField.AccountName]: false,
      [WithdrawalFormField.AccountBsb]: false,
      [WithdrawalFormField.AccountNumber]: false,
    });
  };

  const heading = (
    <Text variant="sHeader" sx={{ mb: '$8' }}>
      {t('Content.Withdraw.ToSection')}
    </Text>
  );

  if (loading) {
    return (
      <>
        {heading}

        <LoadingInput />
      </>
    );
  }

  if (!transferFeatureDisabled && isManualInput) {
    return (
      <>
        {heading}

        <FormTextInputV2
          inputTestID={TestID.Withdraw.AccountNameInput}
          name={WithdrawalFormField.AccountName}
          label={t('Content.Withdraw.AccountName')}
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.Withdrawal,
            screen,
            FieldInteractionKey.AccountName,
          )}
        />

        <FormikFormError
          name={WithdrawalFormField.AccountName}
          sx={{ mt: '$8' }}
        />

        <FormSpacer />

        <FormTextInputV2
          inputTestID={TestID.Withdraw.AccountBsbInput}
          name={WithdrawalFormField.AccountBsb}
          label={t('Content.Withdraw.BSB')}
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.Withdrawal,
            screen,
            FieldInteractionKey.AccountBsb,
          )}
        />

        <FormikFormError
          name={WithdrawalFormField.AccountBsb}
          sx={{ mt: '$8' }}
        />

        <FormSpacer />

        <FormTextInputV2
          inputTestID={TestID.Withdraw.AccountNumberInput}
          name={WithdrawalFormField.AccountNumber}
          label={t('Content.Withdraw.AccountNumber')}
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.Withdrawal,
            screen,
            FieldInteractionKey.AccountNumber,
          )}
        />

        <FormikFormError
          name={WithdrawalFormField.AccountNumber}
          sx={{ mt: '$8' }}
        />

        <Pressable
          onPress={toggleIsManualInput}
          sx={{ mt: '$8' }}
          style={{ alignSelf: 'flex-start' }}
        >
          <Text variant="caption" sx={{ color: '$link' }}>
            {t('Content.Withdraw.BackToAccountList')}
          </Text>
        </Pressable>
      </>
    );
  }

  return (
    <>
      {heading}

      <AccountDropdown
        inputTestID={TestID.Withdraw.AccountDropdownInput}
        containerTestID={TestID.Withdraw.AccountDropdownRowInput}
        suggestionsTestIDPrefix={TestID.Withdraw.AccountDropdownInput}
        name={WithdrawalFormField.ExternalAccountId}
        suggestionData={suggestionData}
        placeholder={t('Content.Withdraw.AccountSelect')}
        focusPlaceholder={t('Content.Withdraw.AccountSearch')}
        selectedAccountId={
          formProps.values[WithdrawalFormField.ExternalAccountId]
        }
        disabled={transferFeatureDisabled}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Withdrawal,
          screen,
          FieldInteractionKey.AccountList,
        )}
      />

      <FormikFormError
        name={WithdrawalFormField.ExternalAccountId}
        sx={{ mt: '$8' }}
      />

      <Pressable
        onPress={toggleIsManualInput}
        sx={{ mt: '$8' }}
        style={{ alignSelf: 'flex-start' }}
      >
        <Text variant="caption" sx={{ color: '$link' }}>
          {t('Content.Withdraw.AccountAdd')}
        </Text>
      </Pressable>
    </>
  );
}
