import * as React from 'react';
import { ColorValue, StyleProp, StyleSheet, TextStyle } from 'react-native';

import { useTheme } from '../theme';
import { SVG_ICON_LIST } from './svgIconList';
import { SvgIconProps } from './types';

export type Props = Omit<SvgIconProps, 'size' | 'style' | 'testID' | 'name'> & {
  size: number;
  style?: StyleProp<TextStyle>;
  testID?: string;
  name: keyof typeof SVG_ICON_LIST;
};

export const SvgIcon: React.FC<Props> = ({
  size,
  style,
  testID,
  name,
  ...otherProps
}) => {
  const theme = useTheme();
  const IconComponent = SVG_ICON_LIST[name];

  if (IconComponent == null) {
    // eslint-disable-next-line no-console
    console.warn(
      'Invalid icon name supplied, expected one of',
      Object.keys(SVG_ICON_LIST),
    );
    return null;
  }

  let color: ColorValue = theme.colors.primaryContent;
  const flattenedStyle = StyleSheet.flatten(style) || {};
  if (flattenedStyle.color && typeof flattenedStyle.color !== 'symbol') {
    color = flattenedStyle.color;
  }

  return (
    <IconComponent
      size={size}
      style={style}
      testID={testID}
      color={color}
      {...otherProps}
    />
  );
};
