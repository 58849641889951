import {
  AddManualLiabilityV2Query,
  EditLiabilityV2Query,
  Liability_Type_Enum,
  NoDetectedDebtScreenQuery,
} from '../../generated/graphql';

export type ApplicantLike = {
  id: string;
  latest_full_name?: string | null;
  hecs_loan_balance?: number | null;
};

export type MergedLiabilities =
  | AddManualLiabilityV2Query['mergedLiabilities']
  | EditLiabilityV2Query['mergedLiabilities']
  | NoDetectedDebtScreenQuery['mergedLiabilities'];

export type AccountOwnerOption = {
  label: string;
  value: string;
  hasHecsLoan: boolean;
  disabled?: boolean;
};

export function filterLiabilitiesByType(
  mergedLiabilities: MergedLiabilities | undefined,
  type: Liability_Type_Enum,
) {
  return (
    mergedLiabilities?.filter(
      (liability) => liability.dynamite_liability_type === type,
    ) || []
  );
}

export function hasHecsLoan(
  applicantId: string,
  hecsLoanBalance: number | null | undefined,
  mergedLiabilities: MergedLiabilities | undefined,
) {
  const hecsLiabilities = filterLiabilitiesByType(
    mergedLiabilities,
    Liability_Type_Enum.HecsHelp,
  );

  return (
    hecsLiabilities.some((liability) =>
      liability?.dynamite_applicant_ids?.includes(applicantId),
    ) || !!hecsLoanBalance
  );
}

export function shouldDisableAccountOwnerOptionForHecs({
  applicantId,
  mergedLiabilities,
  selectedLiabilityId,
  applicantHasHecsLoan,
  allApplicantsHaveHecsLoan,
  isEditingLiability,
}: {
  applicantId: string;
  mergedLiabilities: MergedLiabilities | undefined;
  selectedLiabilityId: string | undefined;
  applicantHasHecsLoan: boolean;
  allApplicantsHaveHecsLoan: boolean;
  isEditingLiability: boolean | undefined;
}): boolean {
  if (isEditingLiability) {
    if (allApplicantsHaveHecsLoan) {
      const mergedLiability = mergedLiabilities?.find(
        (ml) => ml.id === selectedLiabilityId,
      );
      const applicantOwnsSelectedLiability =
        mergedLiability?.dynamite_applicant_ids?.[0] === applicantId;

      // When all applicants have HECS loans and the selected liability is owned
      // by this applicant, we enable the account owner option for visual purposes,
      // even though the user can't change the account owner.
      return !applicantOwnsSelectedLiability;
    }

    return false;
  }

  return applicantHasHecsLoan;
}

export function mapApplicantsToAccountOwnerOptions({
  applicants,
  mergedLiabilities,
  selectedLiabilityId,
  selectedLiabilityType,
  isEditingLiability,
}: {
  applicants?: Array<ApplicantLike>;
  mergedLiabilities?: MergedLiabilities;
  selectedLiabilityId?: string;
  selectedLiabilityType?: Liability_Type_Enum | null;
  isEditingLiability?: boolean;
}): AccountOwnerOption[] {
  const allApplicantsHaveHecsLoan =
    applicants?.every(({ id, hecs_loan_balance }) =>
      hasHecsLoan(id, hecs_loan_balance, mergedLiabilities),
    ) || false;

  return (
    applicants?.map(({ id, latest_full_name, hecs_loan_balance }) => {
      const applicantHasHecsLoan = hasHecsLoan(
        id,
        hecs_loan_balance,
        mergedLiabilities,
      );

      const accountOwner = {
        label: latest_full_name || '',
        value: id,
        hasHecsLoan: applicantHasHecsLoan,
      };

      if (selectedLiabilityType === Liability_Type_Enum.HecsHelp) {
        return {
          ...accountOwner,
          disabled: shouldDisableAccountOwnerOptionForHecs({
            applicantId: id,
            mergedLiabilities,
            selectedLiabilityId,
            applicantHasHecsLoan,
            allApplicantsHaveHecsLoan,
            isEditingLiability,
          }),
        };
      }

      return accountOwner;
    }) || []
  );
}
