import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { parseISO } from 'date-fns';
import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { SegmentedControl } from '../../components/form/SegmentedControl';
import { ContactDetails } from '../../config';
import { DocumentUrl } from '../../constants/documentUrls';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  GrantAccountStatus,
  GrantDetailsFragment,
  MyGrantsFragmentDoc,
  useGetMyGrantsQuery,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { Screen } from '../../navigation/types/screens';
import { Link } from '../../ui/atoms/Link';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast, isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { SettingStackNavigationProp } from '../navigation/types';

export enum ManageDataSharingTab {
  Active,
  Expired,
}

const TAB_LABELS: Record<ManageDataSharingTab, string> = {
  [ManageDataSharingTab.Active]: t('Content.ManageDataSharing.Active'),
  [ManageDataSharingTab.Expired]: t('Content.ManageDataSharing.Expired'),
};

const TAB_ORDERING = [
  ManageDataSharingTab.Active,
  ManageDataSharingTab.Expired,
].map((tab) => TAB_LABELS[tab]);

/**
 * Each grant represent a data sharing between
 * the user and the product specified inside the grant.
 */
export const GetMyGrants = gql`
  query GetMyGrants {
    me {
      user {
        ...MyGrants
      }
    }
  }
  ${MyGrantsFragmentDoc}
`;

type Grant = GrantDetailsFragment;

function getTimeForComparison(grant: Grant): number {
  if (grant.status == null) {
    return 0;
  }

  switch (grant.status) {
    case GrantAccountStatus.Initialise:
      return 0;
    case GrantAccountStatus.Active:
      return grant.created ? parseISO(grant.created).getTime() : 0;
    case GrantAccountStatus.Revoked:
    case GrantAccountStatus.Expired:
      return grant.expiry ? parseISO(grant.expiry).getTime() : 0;
    default:
      return 0;
  }
}

function useDataSharings() {
  const { data, loading, error } = useGetMyGrantsQuery();

  const memoized = useMemo(() => {
    const user = data?.me[0]?.user;

    const activeGrants = (user?.activeGrants || []).filter(
      isNotNullOrUndefined,
    );
    const expiredGrants = (user?.expiredGrants || []).filter(
      isNotNullOrUndefined,
    );

    activeGrants.sort((a, b) => {
      if (
        // Pending approval sharing always on top
        a.status === GrantAccountStatus.Initialise &&
        b.status !== GrantAccountStatus.Initialise
      ) {
        return -1;
      }
      if (
        // Otherwise, sort by date descending
        a.status === GrantAccountStatus.Active &&
        b.status === GrantAccountStatus.Active
      ) {
        return getTimeForComparison(b) - getTimeForComparison(a);
      }
      return 0;
    });
    expiredGrants.sort(
      (a, b) => getTimeForComparison(b) - getTimeForComparison(a),
    );

    return {
      activeGrants,
      expiredGrants,
    };
  }, [data]);

  return {
    ...memoized,
    data,
    error,
    loading,
  };
}

export const DataSharingDateFormat = 'd MMM y';

function getDataSharingCaption(grant: Grant): string {
  if (grant.status == null) {
    return '--';
  }
  switch (grant.status) {
    case GrantAccountStatus.Initialise:
      return t('Content.ManageDataSharing.PendingApproval');
    case GrantAccountStatus.Active:
      return t('Content.ManageDataSharing.SharedFromDate', {
        date: safelyFormatDate(grant.created, DataSharingDateFormat) || '--',
      });
    case GrantAccountStatus.Revoked:
      return t('Content.ManageDataSharing.WithdrawnOnDate', {
        date: safelyFormatDate(grant.expiry, DataSharingDateFormat) || '--',
      });
    case GrantAccountStatus.Expired:
      return t('Content.ManageDataSharing.ExpiredOnDate', {
        date: safelyFormatDate(grant.expiry, DataSharingDateFormat) || '--',
      });
    default:
      return '--';
  }
}

type DataSharingFooterCaptionProps = ComponentProps<typeof StyledText> & {
  showFurtherRecordsCopy?: boolean;
};

export function DataSharingFooterCaption(props: DataSharingFooterCaptionProps) {
  const { showFurtherRecordsCopy = false, ...otherProps } = props;
  return (
    <>
      <StyledText
        variant="caption"
        mx="m"
        testID={TestID.ManageDataSharing.DataSharingFooterLearnMore}
        {...otherProps}
      >
        {t('Content.ManageDataSharing.SharingDataWithFooter')}
        <Link href={DocumentUrl.CDRPolicy} variant="caption">
          {t('Content.ManageDataSharing.SharingDataWithFooterLink')}
        </Link>
      </StyledText>
      <StyledText variant="caption" mx="m" {...otherProps} mt="s">
        {t('Content.ManageDataSharing.SharingDataWithFooterDisclaimer1')}
      </StyledText>
      <StyledText variant="caption" mx="m" {...otherProps} mt="s">
        {t('Content.ManageDataSharing.SharingDataWithFooterDisclaimer2')}
      </StyledText>
      <StyledText variant="caption" mx="m" {...otherProps} mt="s">
        {t('Content.ManageDataSharing.SharingDataWithFooterDisclaimer3')}
      </StyledText>
      {showFurtherRecordsCopy ? (
        <StyledText
          variant="caption"
          mx="m"
          mt="s"
          testID={TestID.ManageDataSharing.DataSharingFooterFurtherRecords}
          {...otherProps}
        >
          {t('Content.ManageDataSharing.SharingDataWithFooterFurtherRecords')}
          <Link href={`mailto:${ContactDetails.unloanEmail}`} variant="caption">
            {ContactDetails.unloanEmail}
          </Link>
        </StyledText>
      ) : null}
    </>
  );
}

function DataSharingPreferencesSection(): JSX.Element {
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_DATA_SHARING_PREFERENCES>
    >();
  const listItems = [
    {
      label: t('Content.DataSharingPreferences.MenuItems.JointAccounts.Title'),
      onPress: useCallback(
        () => navigation.navigate(Screen.SETTINGS_DATA_SHARING_PREFERENCES),
        [navigation],
      ),
    },
    {
      label: t(
        'Content.DataSharingPreferences.MenuItems.NotificationSettings.Title',
      ),
      onPress: useCallback(
        () =>
          navigation.navigate(
            Screen.SETTINGS_DATA_SHARING_NOTIFICATION_PREFERENCES_ACCOUNT_LIST,
          ),
        [navigation],
      ),
    },
  ] as {
    label: string;
    onPress: () => void;
  }[];

  return (
    <>
      <Separator spacer />
      <ListRowGroup
        mx={0}
        headerText={t('Content.DataSharingPreferences.Title')}
        overflow="visible"
        testID={TestID.ManageDataSharing.DataSharingPreferences}
      >
        {listItems.map(({ label, onPress }, idx) => (
          <ListRow
            key={label}
            label={label}
            labelFontWeight="semiBold"
            onPress={() => onPress()}
            last={isLast(listItems, idx)}
          />
        ))}
      </ListRowGroup>
    </>
  );
}

function GrantList({
  enableOpenBankingDataHolderFeatureFlag,
  list,
  onGrantPress,
}: {
  enableOpenBankingDataHolderFeatureFlag: boolean;
  list: Array<Grant>;
  onGrantPress: (grantId: string, brandName?: string) => void;
}) {
  if (list.length === 0) {
    return (
      <>
        <StyledText textAlign="center" variant="caption" pt="m">
          {t('Content.ManageDataSharing.NoDataSharing')}
        </StyledText>
        {enableOpenBankingDataHolderFeatureFlag ? (
          <DataSharingPreferencesSection />
        ) : null}
        <Separator spacer />
        <DataSharingFooterCaption showFurtherRecordsCopy />
      </>
    );
  }

  return (
    <>
      <ListRowGroup
        mx={0}
        headerText={t('Content.ManageDataSharing.WhoIsAccessingYourData')}
        overflow="visible"
      >
        {list.map((grant, i) => {
          const grantId = grant.id;
          const onRowPress = grantId
            ? () =>
                onGrantPress(
                  grantId,
                  grant.softwareProduct?.brandName ?? undefined,
                )
            : undefined;

          return (
            <ListRow
              key={grantId}
              label={grant.softwareProduct?.brandName || '--'}
              caption={getDataSharingCaption(grant)}
              onPress={onRowPress}
              last={isLast(list, i)}
            />
          );
        })}
      </ListRowGroup>
      {enableOpenBankingDataHolderFeatureFlag ? (
        <DataSharingPreferencesSection />
      ) : null}
      <Separator spacer />
      <DataSharingFooterCaption showFurtherRecordsCopy />
    </>
  );
}

function ManageDataSharingBase() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    ManageDataSharingTab.Active,
  );
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_MANAGE_DATA_SHARING>
    >();
  const navigateToDataSharingDetails = (
    grantId: string,
    brandName?: string,
  ) => {
    navigation.navigate(Screen.SETTINGS_DATA_SHARING_DETAILS, {
      grantId,
      brandName,
    });
  };

  const { flags } = useContext(FeatureFlagsContext);

  const { activeGrants, expiredGrants, data, loading, error } =
    useDataSharings();

  let content;

  if (error) {
    // TODO: This need to use query error handler
    content = <ErrorRow message="Unable to fetch data sharings." />;
  }

  if (loading) {
    content = <Spinner />;
  }

  if (data) {
    content = (
      <GrantList
        enableOpenBankingDataHolderFeatureFlag={
          flags.ENABLE_OPEN_BANKING_DATA_HOLDER
        }
        list={
          selectedTabIndex === ManageDataSharingTab.Active
            ? activeGrants
            : expiredGrants
        }
        onGrantPress={navigateToDataSharingDetails}
      />
    );
  }

  return (
    <UpliftScreenContainer onPressBack={navigation.goBack}>
      <HomeHeader title={t('Content.Settings.ManageDataSharing.Title')} />

      <SegmentedControl
        accessibilityLabel="tabs for filtering data sharing by their expiry"
        values={TAB_ORDERING}
        selectedIndex={selectedTabIndex}
        onChange={(event) =>
          setSelectedTabIndex(event.nativeEvent.selectedSegmentIndex)
        }
        testID={TestID.ManageDataSharing.SegmentedControl}
      />
      <Separator spacer />
      {content}
    </UpliftScreenContainer>
  );
}

export const WithAuthenticationManageDataSharing = withAuthenticationRequired(
  ManageDataSharingBase,
);
