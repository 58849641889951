import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryEmploymentIncome = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryEmploymentIncome');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        d="M33 5.25h-.75a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0h-1.5a.75.75 0 0 1-1.5 0H3a.75.75 0 0 0-.75.75v24c0 .829.671.75 1.5.75h28.5c.829 0 1.5.079 1.5-.75V6a.75.75 0 0 0-.75-.75Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M17.625 12.75H6.375A.375.375 0 0 1 6 12.375v-.75c0-.207.168-.375.375-.375h11.25c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375ZM15.375 15.75h-9A.375.375 0 0 1 6 15.375v-.75c0-.207.168-.375.375-.375h9c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375ZM30.58 21.515c0-4.24-6.261-3.412-6.261-5.918 0-1.308 1.47-1.377 1.93-1.377 1.105 0 2.172.296 2.985.678.257.12.55-.058.55-.34v-1.234c0-.414-.155-.66-.403-.756-.558-.217-1.359-.375-2.543-.414v-1.358a.295.295 0 0 0-.295-.295h-.59a.295.295 0 0 0-.294.295v1.38c-1.836.179-3.741 1.215-3.741 3.627 0 4.396 6.26 3.524 6.26 5.84 0 .727-.465 1.355-1.93 1.355-1.473 0-2.827-.515-3.576-.898a.379.379 0 0 0-.55.341v1.405c0 .27.163.51.411.614.888.378 2.078.663 3.125.747v1.5c0 .163.132.295.295.295h.59a.295.295 0 0 0 .294-.294v-1.5c2.75-.23 3.743-2.069 3.743-3.693Z"
        fill="#2C2C2C"
      />
      <Path
        d="M10.5 27a.999.999 0 0 1-.853-.454 1.3 1.3 0 0 1-.191-.72c0-.491.262-1.348.678-2.204-.115-.755-.332-1.563-.676-2.147C9.238 21.099 9.062 21 9 21c-.782 0-1.367 1.644-1.514 2.394a.75.75 0 1 1-1.472-.289c.072-.368.77-3.605 2.986-3.605 1.126 0 1.843 1.107 2.259 2.38.466-.525.98-.88 1.49-.88 1.496 0 1.987 1.734 2.346 3l.04.137.09-.182c.478-.964 1.02-2.055 2.025-2.055a.75.75 0 0 1 .062 1.498c-.21.15-.556.845-.744 1.224-.433.874-.808 1.628-1.568 1.628-.827 0-1.068-.853-1.348-1.84-.148-.523-.541-1.91-.902-1.91-.243.094-.683.71-1.067 1.401.038.345.055.662.055.928 0 .505-.052.924-.155 1.247-.266.834-.846.924-1.083.924Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={2.25}
          y1={5.25}
          x2={27.194}
          y2={36.06}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
