import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ReactNode, useContext } from 'react';
import * as React from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { MainNavigatorStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import {
  InstallPwaBanner,
  InstallPwaTooltip,
} from '../../ui/organisms/PwaInstall';
import { useTheme } from '../../ui/theme';
import { SCREEN_CONFIGS } from './config';
import { AndroidInstallPwaEventProvider } from './utils/useAndroidInstallPwaEvent';

const Stack = createStackNavigator<MainNavigatorStackParams>();

export function BaseHomeNavigator({ children }: { children: ReactNode }) {
  const { flags } = useContext(FeatureFlagsContext);
  const pwaInstallPromptEnabled = flags.ENABLE_PWA_INSTALL_PROMPT;

  return (
    <AndroidInstallPwaEventProvider>
      {pwaInstallPromptEnabled ? <InstallPwaBanner /> : null}
      {children}
      {pwaInstallPromptEnabled ? <InstallPwaTooltip /> : null}
    </AndroidInstallPwaEventProvider>
  );
}

export const HomeNavigator: React.FC = () => {
  const navigation = useNavigation();
  const theme = useTheme();

  return (
    <BaseHomeNavigator>
      <Stack.Navigator
        initialRouteName={Screen.HOME_DASHBOARD}
        screenOptions={{
          /* The screen has flickering when navigating back to it from another screen.
           This is a workaround for a bug in react-native-screens:
           https://github.com/software-mansion/react-native-screens/issues/111#issuecomment-862988015
        */
          detachPreviousScreen: !navigation.isFocused(),
          headerTransparent: true,
          headerShown: false,
          cardStyle: {
            padding: 1, // workaround for a bug in react navigation that the flex 1 could not make the screen fill window full height when header is transparent
            backgroundColor: theme.colors.bg,
          },
        }}
      >
        {SCREEN_CONFIGS.map(({ name, component }) => (
          <Stack.Screen
            key={name}
            name={name}
            component={component}
            options={{
              title: getPageTitle(name),
            }}
          />
        ))}
      </Stack.Navigator>
    </BaseHomeNavigator>
  );
};
