import { RouteProp } from '@react-navigation/native';
import { Card, CardContent, Text, theme as themeV3 } from '@unloan/ui';
import * as Clipboard from 'expo-clipboard';
import { AnimatePresence, MotiView } from 'moti';
import { useEffect, useState } from 'react';
import { ActionSheetV3 } from 'src/components/ActionSheetV3/ActionSheetV3';
import { FadeInAnimationPreset } from 'src/components/BannerWrapper';
import { ActionSheetStackParams } from 'src/navigation/types/navTypes';
import { ActionSheetType } from 'src/navigation/types/screens';
import { TickIconV2 } from 'src/ui/svgs/TickIconV2';
import { chromaticIgnoreDataSet } from 'src/utils/chromatic';
import {
  formatBsb,
  formatBsbAndAccountNumber,
} from 'src/utils/formatBsbAndAccountNumber';

import { ActionSheetScreenProps } from '../navigation/types';

type RouteParams = RouteProp<
  ActionSheetStackParams,
  ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3
>['params'];

const BUTTONS = [
  {
    label: t('Content.CopyBsbAccountNumberActionSheet.Bsb'),
    onPress: ({ bsb }: RouteParams) => Clipboard.setStringAsync(formatBsb(bsb)),
  },
  {
    label: t('Content.CopyBsbAccountNumberActionSheet.AccountNumber'),
    onPress: ({ accountNumber }: RouteParams) =>
      Clipboard.setStringAsync(accountNumber),
  },
  {
    label: `${t('Content.CopyBsbAccountNumberActionSheet.Bsb')} & ${t(
      'Content.CopyBsbAccountNumberActionSheet.AccountNumber',
    )}`,
    onPress: ({ accountNumber, bsb }: RouteParams) =>
      Clipboard.setStringAsync(formatBsbAndAccountNumber(bsb, accountNumber)),
  },
];

type Props = ActionSheetScreenProps<ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3>;

export function CopyBsbAccountNumberActionSheetV3({
  navigation,
  route,
}: Props) {
  const [copiedLabel, setCopiedLabel] = useState<string>();
  return (
    <ActionSheetV3
      onClose={navigation.goBack}
      header={
        copiedLabel ? <CopiedToClipboardMessage label={copiedLabel} /> : null
      }
      buttons={BUTTONS.map((button) => ({
        variant: 'secondary',
        label: button.label,
        onPress: () => {
          setCopiedLabel(button.label);
          return button.onPress(route.params);
        },
      }))}
    />
  );
}

const MESSAGE_TIMEOUT_MS = 4000;
const MESSAGE_FADE_OUT_ANIMATION = {
  from: FadeInAnimationPreset.animate,
  exit: FadeInAnimationPreset.from,
  transition: FadeInAnimationPreset.transition,
};

/** Tells the user that copying to clipboard was successful. Automatically dismisses after a while. */
function CopiedToClipboardMessage({ label }: { label: string }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(true);
    const timeout = setTimeout(() => setIsVisible(false), MESSAGE_TIMEOUT_MS);
    return () => clearTimeout(timeout);
  }, [label]);

  return (
    <AnimatePresence>
      {isVisible ? (
        <MotiView
          {...MESSAGE_FADE_OUT_ANIMATION}
          dataSet={chromaticIgnoreDataSet}
        >
          <Card
            sx={(theme) => ({
              backgroundColor: theme.colors.$neutral.$900,
              shadowColor: theme.colors.$transparent,
              paddingHorizontal: theme.space.$16,
              paddingVertical: theme.space.$12,
              borderRadius: theme.sizes.$8,
            })}
          >
            <CardContent
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text size="body2" variant="inverse">
                {t('Content.CopyBsbAccountNumberActionSheet.SuccessMessage', {
                  label,
                })}
              </Text>
              <TickIconV2 color={themeV3.colors.$neutral.$100} size={20} />
            </CardContent>
          </Card>
        </MotiView>
      ) : null}
    </AnimatePresence>
  );
}
