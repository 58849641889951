import { useFocusEffect } from '@react-navigation/native';
import { styled, Text } from 'dripsy';
import { useState } from 'react';
import * as React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { IDVerificationConsentActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import {
  Id_Type_Enum,
  useGetPreDvsConsentAtQuery,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { RadioGroup, RadioGroupItem } from '../../ui/v2/RadioGroup';
import { parseEnumType } from '../../utils/ensureEnumType';
import { makeTestId } from '../../utils/stringHelpers';
import { BorrowerDriverLicenceForm } from '../components/BorrowerDriverLicenceForm';
import { useBorrowerWizardState } from '../components/BorrowerFormContext';
import { BorrowerPassportForm } from '../components/BorrowerPassportForm';
import { useStateOptionsQuery } from '../hooks/useStateOptions';
import { IdentificationV2ScreenProps } from '../navigation/types';
import {
  BorrowerIdentityDetailsFormValues,
  BorrowerIdentityDetailsLabel,
} from '../utils/borrowerFormUtil';
import { handleIdentityCheckResult } from '../utils/handleIdentityCheckResult';
import {
  getUpdateBorrowerErrorMessage,
  useUpdateBorrower,
} from '../utils/useUpdateBorrower';

type Props =
  IdentificationV2ScreenProps<Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE>;

export function BorrowerIdentityVerification({ navigation, route }: Props) {
  const { primaryBorrowerDriverLicenceNumber, primaryBorrowerPassportNumber } =
    route.params || {};

  const { values, setValues } = useBorrowerWizardState();

  const [onSubmitErrorMessage, setOnSubmitErrorMessage] = useState<
    string | null
  >(null);

  const consentErrorState = useRecoilValue(
    IDVerificationConsentActionSheetErrorAtom,
  );
  const resetConsentErrorState = useResetRecoilState(
    IDVerificationConsentActionSheetErrorAtom,
  );

  useFocusEffect(() => () => resetConsentErrorState());
  const applicantId = values.applicantId ?? '';
  const { loanApplicationId, identityType } = values;

  /** Errors on this query shouldn't be a stopper  */
  const { data: consentAtResult, loading: consentLoading } =
    useGetPreDvsConsentAtQuery({
      variables: {
        applicantId,
      },
      skip: !values.applicantId,
      context: {
        sentryContext: {
          loanApplicationId,
          applicantId: values.applicantId,
        },
      },
    });

  const { data: stateOptions, loading: stateLoading } = useStateOptionsQuery();
  const { updateBorrower, updateBorrowerLoading } = useUpdateBorrower({
    loanApplicationId: loanApplicationId ?? '',
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(
      navigation,
      route,
      loanApplicationId ?? '',
    );

  const onCloseModal = React.useCallback(() => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  }, [navigateToLoanApplicationScreen]);

  const navigateToYourInformation = React.useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Screen.IDENTIFICATION_V2_YOUR_INFORMATION,
            params: {
              loanApplicationId,
            },
          },
        ],
      }),
    [loanApplicationId, navigation],
  );

  const navigateToIdvPendingActionSheet = React.useCallback(
    () =>
      navigation.navigate(Screen.IDENTITY_VERIFICATION_PENDING_V2, {
        loanApplicationId: loanApplicationId ?? undefined,
      }),
    [loanApplicationId, navigation],
  );

  const onSubmit = React.useCallback(
    async (formValues: BorrowerIdentityDetailsFormValues) => {
      setOnSubmitErrorMessage(null);

      if (!loanApplicationId) {
        /** Should be unreachable, unless we navigate using unintended method */
        setOnSubmitErrorMessage(
          t('Content.Common.Error.NoAssociatedLoanApplication'),
        );
        return;
      }

      setValues({ ...formValues });

      if (consentAtResult?.applicant_by_pk?.pre_dvs_consent_at) {
        navigateToYourInformation();
        return;
      }

      if (!values.identityCheckResult) {
        navigation.navigate(Screen.IDENTIFICATION_V2_MODAL, {
          screen: Screen.IDENTIFICATION_V2_ID_VERIFICATION_CONSENT,
        });
        return;
      }

      const { data, error } = await updateBorrower({
        values: {
          ...values,
          ...formValues,
          identityType: formValues.identityType ?? Id_Type_Enum.DriversLicence,
        },
        isManualKyc: false,
      });

      if (data) {
        handleIdentityCheckResult({
          data,
          navigateToBorrowers: onCloseModal,
          navigateToIdvPendingActionSheet,
          navigateToYourInformation,
          isSummary: true,
        });
        return;
      }
      setOnSubmitErrorMessage(getUpdateBorrowerErrorMessage(error));
    },
    [
      consentAtResult?.applicant_by_pk?.pre_dvs_consent_at,
      loanApplicationId,
      navigateToIdvPendingActionSheet,
      navigateToYourInformation,
      navigation,
      onCloseModal,
      setValues,
      updateBorrower,
      values,
    ],
  );

  const [selectedIdentityType, setSelectedIdentityType] =
    React.useState<Id_Type_Enum | null>(
      identityType ?? Id_Type_Enum.DriversLicence,
    );
  if (consentLoading || stateLoading) {
    return <LoadingState />;
  }

  return (
    <ModalScreenContainer
      onClose={onCloseModal}
      headerText={t('Content.Borrowers.Header.YourInformation')}
      scrollable
      loading={updateBorrowerLoading}
    >
      <ErrorRow mb="l" message={onSubmitErrorMessage || consentErrorState} />
      <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
        {t('Content.Borrower.IdentityDetailsForm.Header')}
      </SHeaderText>

      <RadioGroup
        value={selectedIdentityType}
        onValueSelected={(v) => {
          const parsedValue = parseEnumType(Id_Type_Enum, v);
          if (parsedValue) {
            setSelectedIdentityType(parsedValue);
          }
        }}
      >
        {Object.keys(Id_Type_Enum).map((key) => {
          const value = Id_Type_Enum[key as keyof typeof Id_Type_Enum];
          return (
            <RadioGroupItem
              value={value}
              label={BorrowerIdentityDetailsLabel[value]}
              key={key}
              testID={makeTestId([
                TestID.BorrowerIdentityDetailsForm.IdentityType,
                key,
              ])}
              containerStyle={{
                mb: '$16',
                flex: 1,
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Borrowers,
                Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE,
                FieldInteractionKey.EnterYourIdentityDocumentDetails,
              )}
            />
          );
        })}
      </RadioGroup>
      {selectedIdentityType === Id_Type_Enum.Passport ? (
        <BorrowerPassportForm
          screen={Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE}
          primaryBorrowerPassportNumber={primaryBorrowerPassportNumber}
          isSubmitting={updateBorrowerLoading}
          onSubmit={onSubmit}
          initialValues={values}
        />
      ) : (
        <BorrowerDriverLicenceForm
          screen={Screen.IDENTIFICATION_V2_BORROWER_DRIVER_LICENCE}
          primaryBorrowerDriverLicenceNumber={
            primaryBorrowerDriverLicenceNumber
          }
          isSubmitting={updateBorrowerLoading}
          stateOptions={stateOptions}
          onSubmit={onSubmit}
          initialValues={values}
          enableExpiryDate
        />
      )}
    </ModalScreenContainer>
  );
}

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
