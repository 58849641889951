import { Box, BoxProps } from '../ui/atoms/Box';
import { Spinner } from '../ui/atoms/Spinner';
import { NavHeaderSpacer } from './NavHeaderSpacer';

type Props = BoxProps & {
  loading: boolean;
  children?: JSX.Element;
  addNavHeaderSpacer?: boolean;
};
export function ScreenLoadingContainer({
  children,
  loading,
  testID,
  addNavHeaderSpacer = false,
  ...props
}: Props) {
  if (loading) {
    return (
      <Box pt="l" {...props}>
        {addNavHeaderSpacer ? <NavHeaderSpacer /> : null}
        <Spinner size="large" testID={testID} />
      </Box>
    );
  }
  return children || null;
}
