import { Frequency_Enum } from '../../generated/graphql';
import { assertUnreachable } from '../../utils/typesHelpers';

const MONTHS_IN_A_YEAR = 12;
export function splitMonthsIntoYearMonthParts(
  monthsToSplit: number,
): [yearPartInMonths: number, monthPartInMonths: number] {
  return [
    Math.floor(monthsToSplit / MONTHS_IN_A_YEAR) * MONTHS_IN_A_YEAR,
    monthsToSplit % MONTHS_IN_A_YEAR,
  ];
}

export function toAnnualisedAmount(amount: number, frequency: Frequency_Enum) {
  switch (frequency) {
    case Frequency_Enum.Annual:
      return amount;
    case Frequency_Enum.Monthly:
      return amount * 12;
    case Frequency_Enum.Quarterly:
      return amount * 4;
    case Frequency_Enum.Daily:
      return amount * 365;
    case Frequency_Enum.Weekly:
      return amount * 52;
    case Frequency_Enum.Fortnightly:
      return amount * 26;
    default:
      assertUnreachable(frequency);
      return amount;
  }
}

export function toMonthlyAmount(amount: number, frequency: Frequency_Enum) {
  return toAnnualisedAmount(amount, frequency) / 12.0;
}
