import { Text, TextInput as DripsyTextInput, ThemeColorName } from 'dripsy';
import { ComponentProps, forwardRef, useMemo, useState } from 'react';
import { LayoutChangeEvent, TextInput as RNTextInput } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { isWeb } from '../../utils/platformUtils';
import { InputRow } from './InputRow';
import { TextInput, TextInputV2Props } from './TextInput';

export type PercentInputV2Props = TextInputV2Props;

type OnChangeText = ComponentProps<typeof TextInput>['onChangeText'];

const PERCENT_REGEX = /^\d{1,2}(?:\.\d{0,2})?$/;

function wrapOnChangeText(onChangeText: OnChangeText): OnChangeText {
  if (onChangeText == null) {
    return undefined;
  }

  return (text) => {
    const isValid = text === '' || PERCENT_REGEX.test(text);
    if (isValid) {
      onChangeText(text);
    }
  };
}

export const PercentInput = forwardRef<RNTextInput, PercentInputV2Props>(
  (
    {
      label,
      value,
      inputTestID,
      containerTestID,
      disabled,
      sx: sxProp,
      // These are provided by Formik
      error,
      focused,
      onBlur,
      onFocus,
      onChangeText,
      // These should go to RN text input
      maxLength,
      keyboardType = 'decimal-pad',
    },
    ref,
  ) => {
    const [labelId] = useState(() => uuidv4());
    const [valueTextLayout, setValueTextLayout] =
      useState<LayoutChangeEvent | null>(null);

    const wrappedOnChange = useMemo(
      () => wrapOnChangeText(onChangeText),
      [onChangeText],
    );

    const focusedDataset = isWeb ? { focused: 'true' } : undefined;

    let borderColor: ThemeColorName = '$border';
    let labelColor: ThemeColorName = '$secondary';
    let bgColor: ThemeColorName = '$inputBackground';
    let valueColor: ThemeColorName = '$labelsPrimary';
    if (error) {
      borderColor = '$error';
      labelColor = '$error';
    }
    if (focused) {
      borderColor = '$focus';
    }
    if (disabled) {
      labelColor = '$secondaryDisabled';
      bgColor = '$inputBackgroundDisabled';
      valueColor = '$secondary';
    }

    const isFilled = value && String(value).length > 0;
    const smallLabel = focused || isFilled;

    const py = 7;

    return (
      <InputRow
        sx={{
          bg: bgColor,
          px: '$16',
          py,
          borderColor,
          ...sxProp,
        }}
        testID={containerTestID}
        dataSet={focused ? focusedDataset : undefined}
      >
        <Text
          variant={smallLabel ? 'tiny' : 'default'}
          sx={{
            color: labelColor,
            position: 'absolute',
            ...(smallLabel ? { top: py } : { zIndex: -1 }),
          }}
          numberOfLines={1}
          nativeID={labelId}
          selectable={false}
        >
          {label}
        </Text>
        <DripsyTextInput
          sx={{
            variant: 'text.default',
            color: valueColor,
            pt: '$16',
          }}
          value={value != null ? String(value) : ''}
          editable={!disabled}
          maxLength={maxLength || 5}
          numberOfLines={1}
          keyboardType={keyboardType}
          onChangeText={wrappedOnChange}
          onBlur={onBlur}
          onFocus={onFocus}
          underlineColorAndroid="transparent"
          // This is a browser specific fix for Chrome only.
          //  see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error `chrome-off` is a web only enum and
          // our RN types override stopped working since Expo 49 upgrades,
          // see `@types/react-native.d.ts` for existing overrides.
          autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
          testID={inputTestID}
          ref={ref}
          accessibilityLabelledBy={labelId}
          autoCorrect={false}
        />
        {isFilled && (valueTextLayout?.nativeEvent?.layout?.width ?? 0) > 0 ? (
          <Text
            variant="default"
            sx={(theme) => ({
              position: 'absolute',
              left:
                theme.space.$16 +
                (valueTextLayout?.nativeEvent?.layout?.width ?? 0),
              bottom: py,
            })}
          >
            %
          </Text>
        ) : null}
        {isFilled ? (
          <Text
            accessibilityElementsHidden
            numberOfLines={1}
            selectable={false}
            variant="default"
            onLayout={(event) => setValueTextLayout(event)}
            sx={{
              position: 'absolute',
              color: 'transparent',
              zIndex: -1,
            }}
          >
            {value}
          </Text>
        ) : null}
      </InputRow>
    );
  },
);

PercentInput.displayName = 'PercentInputV2';
