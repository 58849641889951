import { Text, View } from 'dripsy';
import { Formik, FormikProps } from 'formik';
import { ComponentProps, useCallback, useEffect } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Checkbox } from '../../ui/v2/Checkbox';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import {
  AddressFields,
  AddressInputType,
  AddressType,
} from '../types/addressTypes';
import {
  getAddressValidationSchema,
  getErrorMessage,
  getInitialValues,
  SelectAddressValues,
  setEnumValueOfStateField,
} from '../utils/address';
import { AddressPicker, AddressPickerProps } from './AddressPicker';

// For each 'Residential Address' and 'Mailing Address' sections, this component
// is used to render the autocomplete address picker or manual input fields
type AddressSectionProps = {
  screen: Screen;
  addressSectionTitle?: string;
  addressType: AddressType;
  formProps: FormikProps<SelectAddressValues>;
  isManualInput: boolean;
  placeHolder: string;
} & Pick<
  AddressPickerProps,
  'footerErrorMessage' | 'onFooterTextLinkPress' | 'footerTextTestID'
>;

const AddressSection = ({
  screen,
  addressSectionTitle,
  addressType,
  formProps,
  isManualInput,
  placeHolder,
  ...props
}: AddressSectionProps) => {
  const setValueOfStateField = useCallback(
    (value: string) => setEnumValueOfStateField(formProps, addressType, value),
    [formProps, addressType],
  );

  const testIdAddressType: keyof typeof TestID.PersonalDetails.UpdatePhysicalAddress =
    addressType === AddressType.Residential
      ? 'UpdateResidentialAddress'
      : 'UpdateMailingAddress';

  return (
    <>
      {addressSectionTitle ? (
        <Text variant="sHeader" sx={{ marginTop: '$12' }}>
          {addressSectionTitle}
        </Text>
      ) : null}
      <AddressPicker
        screen={screen}
        {...props}
        isManualInput={isManualInput}
        addressType={addressType}
        placeHolder={placeHolder}
        setValueOfStateField={setValueOfStateField}
        testIdAddressType={testIdAddressType}
      />
    </>
  );
};

// Modal Content Component
type ModalContentProps = {
  screen: Screen;
  formProps: FormikProps<SelectAddressValues>;
  errorMessage?: string | null;
  isAddressSame: boolean;
  isLoading: boolean;
  isMailingAddressManualInput: boolean;
  isResidentialAddressManualInput: boolean;
  changeMailingAddressInputType: (val: boolean) => void;
  changeResidentialAddressInputType: (val: boolean) => void;
  toggleCheckBox: () => void;
  mailingAddressErrorOnSubmit?: string | null;
  residentialAddressErrorOnSubmit?: string | null;
};

const ModalContent = ({
  screen,
  formProps,
  errorMessage,
  isAddressSame,
  isLoading,
  isMailingAddressManualInput,
  isResidentialAddressManualInput,
  changeMailingAddressInputType,
  changeResidentialAddressInputType,
  toggleCheckBox,
  mailingAddressErrorOnSubmit,
  residentialAddressErrorOnSubmit,
}: ModalContentProps) => {
  useEffect(() => {
    if (mailingAddressErrorOnSubmit && isMailingAddressManualInput) {
      formProps.setErrors({
        ...formProps.errors,
        [AddressType.Mailing]: {
          ...formProps.errors[AddressType.Mailing],
          [AddressInputType.Manual]: {
            [AddressFields.Postcode]: mailingAddressErrorOnSubmit,
            [AddressFields.Suburb]: mailingAddressErrorOnSubmit,
          },
        },
      });
    }

    if (residentialAddressErrorOnSubmit && isResidentialAddressManualInput) {
      formProps.setErrors({
        ...formProps.errors,
        [AddressType.Residential]: {
          ...formProps.errors[AddressType.Residential],
          [AddressInputType.Manual]: {
            [AddressFields.Postcode]: residentialAddressErrorOnSubmit,
            [AddressFields.Suburb]: residentialAddressErrorOnSubmit,
          },
        },
      });
    }
  }, [
    changeMailingAddressInputType,
    formProps,
    isResidentialAddressManualInput,
    isAddressSame,
    isMailingAddressManualInput,
    mailingAddressErrorOnSubmit,
    residentialAddressErrorOnSubmit,
  ]);

  const changeResidentialAddressInitialValues = useCallback(() => {
    const newInitialValues = getInitialValues({
      isMailingAddressManualInput,
      isResidentialAddressManualInput: !isResidentialAddressManualInput,
    });
    formProps.setValues({
      [AddressType.Residential]: newInitialValues.residentialAddress,
      [AddressType.Mailing]: formProps.values[AddressType.Mailing],
    });
    formProps.setTouched({
      [AddressType.Mailing]: {
        ...formProps.touched[AddressType.Mailing],
      },
    });
    formProps.setErrors({
      [AddressType.Mailing]: {
        ...formProps.errors[AddressType.Mailing],
      },
    });
    changeResidentialAddressInputType(!isResidentialAddressManualInput);
  }, [
    changeResidentialAddressInputType,
    formProps,
    isMailingAddressManualInput,
    isResidentialAddressManualInput,
  ]);

  const changeMailingAddressInitialValues = useCallback(() => {
    const newInitialValues = getInitialValues({
      isMailingAddressManualInput: !isMailingAddressManualInput,
      isResidentialAddressManualInput,
    });
    formProps.setValues({
      [AddressType.Mailing]: newInitialValues.mailingAddress,
      [AddressType.Residential]: formProps.values[AddressType.Residential],
    });
    formProps.setTouched({
      [AddressType.Residential]: {
        ...formProps.touched[AddressType.Residential],
      },
    });
    formProps.setErrors({
      [AddressType.Residential]: {
        ...formProps.errors[AddressType.Residential],
      },
    });
    changeMailingAddressInputType(!isMailingAddressManualInput);
  }, [
    changeMailingAddressInputType,
    formProps,
    isMailingAddressManualInput,
    isResidentialAddressManualInput,
  ]);

  const onToggleCheckBox = useCallback(() => {
    formProps.setTouched({
      [AddressType.Residential]: {
        ...formProps.touched[AddressType.Residential],
      },
    });
    formProps.setErrors({
      [AddressType.Residential]: {
        ...formProps.errors[AddressType.Residential],
      },
    });
    toggleCheckBox();
  }, [formProps, toggleCheckBox]);

  // Residential Address Values
  const residentialAddressTitle = isResidentialAddressManualInput
    ? t('Content.PersonalDetails.UpdateAddress.ResidentialAddress')
    : undefined;

  const residentialAddressFooterErrorMessage =
    residentialAddressErrorOnSubmit ??
    getErrorMessage(
      formProps,
      AddressType.Residential,
      isResidentialAddressManualInput
        ? AddressInputType.Manual
        : AddressInputType.DomainApi,
    );

  const residentialAddressFooterTextTestId =
    TestID.PersonalDetails.UpdatePhysicalAddress.UpdateResidentialAddress
      .Footer;

  // Mailing Address Values
  const mailingAddressTitle = isMailingAddressManualInput
    ? t('Content.PersonalDetails.UpdateAddress.MailingAddress')
    : undefined;

  const mailingAddressFooterErrorMessage =
    mailingAddressErrorOnSubmit ??
    getErrorMessage(
      formProps,
      AddressType.Mailing,
      isMailingAddressManualInput
        ? AddressInputType.Manual
        : AddressInputType.DomainApi,
    );

  const mailingAddressFooterTextTestId =
    TestID.PersonalDetails.UpdatePhysicalAddress.UpdateMailingAddress.Footer;

  return (
    <>
      <ErrorRow
        my="m"
        testID={TestID.PersonalDetails.UpdatePhysicalAddress.ErrorMessage}
        message={errorMessage}
      />
      {/* Residential Address Section */}
      <AddressSection
        screen={screen}
        addressSectionTitle={residentialAddressTitle}
        addressType={AddressType.Residential}
        footerErrorMessage={residentialAddressFooterErrorMessage}
        footerTextTestID={residentialAddressFooterTextTestId}
        formProps={formProps}
        isManualInput={isResidentialAddressManualInput}
        onFooterTextLinkPress={changeResidentialAddressInitialValues}
        placeHolder={t(
          'Content.PersonalDetails.UpdateAddress.ResidentialAddress',
        )}
      />

      <View sx={{ my: '$16' }}>
        <Checkbox
          label={t('Content.PersonalDetails.UpdateAddress.UseAsMailingAddress')}
          isSelected={isAddressSame}
          onItemPress={onToggleCheckBox}
          testID={TestID.PersonalDetails.UpdatePhysicalAddress.CheckBox}
          bordered={false}
        />
      </View>

      {/* Mailing Address Section */}
      {isAddressSame ? null : (
        <AddressSection
          screen={screen}
          addressSectionTitle={mailingAddressTitle}
          addressType={AddressType.Mailing}
          footerErrorMessage={mailingAddressFooterErrorMessage}
          footerTextTestID={mailingAddressFooterTextTestId}
          formProps={formProps}
          isManualInput={isMailingAddressManualInput}
          onFooterTextLinkPress={changeMailingAddressInitialValues}
          placeHolder={t(
            'Content.PersonalDetails.UpdateAddress.MailingAddress',
          )}
        />
      )}

      {/* Submit Button */}
      <Button
        onPress={() => formProps.handleSubmit()}
        disabled={!!errorMessage || isLoading}
        showSpinner={isLoading}
        label={t('Content.PersonalDetails.UpdateAddress.Title')}
        py="m"
        my="l"
        testID={TestID.PersonalDetails.UpdatePhysicalAddress.Submit}
        width="100%"
        maxWidth="100%"
      />
    </>
  );
};

// This is the main component for the Update Address Modal
type UpdateAddressModalFormProps = ComponentProps<
  typeof Formik<SelectAddressValues>
> &
  Omit<ModalContentProps, 'formProps'> & {
    onClose: () => void;
    validationSchema: ReturnType<typeof getAddressValidationSchema>;
  };

export const UpdateAddressModalForm = ({
  initialValues,
  onClose,
  onSubmit,
  validationSchema,
  ...props
}: UpdateAddressModalFormProps) => (
  <ModalScreenContainer
    headerText={t('Content.PersonalDetails.UpdateAddress.Title')}
    onClose={onClose}
    hideBackButton
    scrollable
  >
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formProps) => <ModalContent {...props} formProps={formProps} />}
    </Formik>
  </ModalScreenContainer>
);
