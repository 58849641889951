import { v4 as uuidv4 } from 'uuid';

import { getLogger } from '../utils/loggerHelper';

const logger = getLogger('APP');

export function generateCustomerSessionId() {
  const customerSessionId = uuidv4();
  logger?.log('Generated new customer session ID', customerSessionId);
  return customerSessionId;
}
