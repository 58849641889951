import { createStackNavigator } from '@react-navigation/stack';
import { Fragment } from 'react';

import {
  AppStackScreenProps,
  YourIncomeWizardStackParams,
} from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { EmploymentIncomeWizardScreen } from '../screens/EmploymentIncomeWizardScreen';
import { ExpectedRentalIncomeWizardScreen } from '../screens/ExpectedRentalIncomeWizardScreen';
import { RentalIncomeWizardScreen } from '../screens/RentalIncomeWizardScreen';

const YourIncomeWizardStackNavigator =
  createStackNavigator<YourIncomeWizardStackParams>();

type Props = AppStackScreenProps<Screen.YOUR_INCOME_V2_WIZARD>;

const ROUTE_PARAM_SEPARATOR = ':';
export function composeScreenNameForEmploymentIncome(applicantId: string) {
  return `${Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT}${ROUTE_PARAM_SEPARATOR}${applicantId}` as const;
}

export function YourIncomeWizardNavigator({ route: { params } }: Props) {
  const applicants = params?.applicants ?? [];

  return (
    <YourIncomeWizardStackNavigator.Navigator
      screenOptions={DefaultModalV2NavigatorOptions}
    >
      {applicants.map((currentApplicant, index) => {
        const nextApplicant = applicants[index + 1];

        const screenName = composeScreenNameForEmploymentIncome(
          currentApplicant.id,
        );
        const screenTitle = getPageTitle(
          Screen.YOUR_INCOME_V2_WIZARD_EMPLOYMENT,
        );

        return (
          <Fragment key={screenName}>
            <YourIncomeWizardStackNavigator.Screen
              name={screenName}
              component={EmploymentIncomeWizardScreen}
              initialParams={{ currentApplicant, nextApplicant }}
              options={{
                title: screenTitle,
              }}
            />
          </Fragment>
        );
      })}
      <YourIncomeWizardStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL}
        component={ExpectedRentalIncomeWizardScreen}
        options={{
          title: getPageTitle(Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL),
        }}
      />
      <YourIncomeWizardStackNavigator.Screen
        name={Screen.YOUR_INCOME_V2_WIZARD_RENTAL}
        component={RentalIncomeWizardScreen}
        options={{
          title: getPageTitle(Screen.YOUR_INCOME_V2_WIZARD_RENTAL),
        }}
      />
    </YourIncomeWizardStackNavigator.Navigator>
  );
}
