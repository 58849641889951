import { Retirement_Repayment_Plan_Enum } from '../../../generated/graphql';
import { yup } from '../../../utils/yup';

export enum FinancialDeclarationYesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum FinancialDeclarationFormFieldsV2 {
  MaintainFinancialSituation = 'maintainFinancialSituation',
  RetirementRepaymentPlans = 'retirementRepaymentPlans',
  OtherRetirementRepaymentPlan = 'otherRetirementRepaymentPlan',
}

export const FinancialDeclarationV2BaseValidationSchema = yup.object({
  [FinancialDeclarationFormFieldsV2.MaintainFinancialSituation]: yup
    .mixed<FinancialDeclarationYesNoEnum>()
    .oneOf([
      FinancialDeclarationYesNoEnum.Yes,
      FinancialDeclarationYesNoEnum.No,
    ])
    .required(t('Content.Common.Error.PleaseSelectAnOption')),
});

export const FinancialDeclarationWithRetirementPlanV2ValidationSchema =
  FinancialDeclarationV2BaseValidationSchema.shape({
    [FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans]: yup
      .array()
      .min(1, t('Content.Common.Error.PleaseSelectAnOption')),
    [FinancialDeclarationFormFieldsV2.OtherRetirementRepaymentPlan]: yup
      .string()
      .nullable()
      .when(
        FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans,
        (plan: Retirement_Repayment_Plan_Enum | undefined, schema) =>
          plan?.includes(Retirement_Repayment_Plan_Enum.Other)
            ? schema.required()
            : schema,
      ),
  });

export function getFinancialDeclarationSchemaV2(
  isRetiredBeforeEndOfLoan: boolean | undefined,
) {
  if (isRetiredBeforeEndOfLoan) {
    return FinancialDeclarationWithRetirementPlanV2ValidationSchema;
  }
  return FinancialDeclarationV2BaseValidationSchema;
}
