import * as React from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import {
  refetchLoanApplicationSecuritiesQuery,
  refetchReviewLoanApplicationQuery,
  useRemoveLoanApplicationSecurityMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import { RemovePropertyActionSheetErrorAtom } from '../recoil/ActionSheetErrors';

type Props = ActionSheetScreenProps<ActionSheetType.REMOVE_PROPERTY_V2>;

export const RemovePropertyActionSheetV2: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const {
    loanApplicationId: loanAppIdFromParam,
    loanApplicationSecurityId,
    ...otherParams
  } = route.params || {};

  const loanApplicationId = loanAppIdFromParam ?? '';
  const navigateToPropertyDetailsModal = () => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.YOUR_PROPERTY_V2_MODAL,
      params: {
        ...otherParams,
        loanApplicationId,
        loanApplicationSecurityId,
      },
    });
  };

  const setRemovePropertyError = useSetRecoilState(
    RemovePropertyActionSheetErrorAtom,
  );

  const [removeProperty, { loading }] =
    useRemoveLoanApplicationSecurityMutation();

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onRemovePropertyPress = async () => {
    const [res, error] = await safelyCallMutation(removeProperty, {
      variables: {
        loan_application_security_id: loanApplicationSecurityId,
      },
      refetchQueries: [
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          loanApplicationSecurityId,
        },
      },
    });
    if (res == null || error) {
      setRemovePropertyError(t('Content.Common.Error.FailRemoveProperty'));
      navigateToPropertyDetailsModal();
      return;
    }

    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Property,
    });
  };

  return (
    <ActionSheet
      closeModal={navigateToPropertyDetailsModal}
      preventCloseModal={loading}
      title={t('Content.RemoveProperty.TitleV2')}
      message={t('Content.RemoveProperty.ContentV2')}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.PropertyDetails.DeleteActionSheet.Cancel,
          disabled: loading,
          secondary: true,
          onPress: navigateToPropertyDetailsModal,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Delete'),
          testID: TestID.PropertyDetails.DeleteActionSheet.Delete,
          disabled: loading,
          showSpinner: loading,
          secondary: true,
          isDestructive: true,
          onPress: onRemovePropertyPress,
          flex: 1,
        },
      ]}
    />
  );
};
