import { Liability_Type_Enum } from '../generated/graphql';
import { ListRow } from '../ui/molecules/ListRow';
import {
  generateCensoredCreditCardNumber,
  removeCensoredAccountNumber,
  replaceSpaceWithSeparator,
} from '../utils/stringHelpers';
import { DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE } from './YourLiabilityRow';

// This test id function should not be here
export function buildLiabilityTestId(
  prefix: string,
  liabilityType: Liability_Type_Enum,
  accountNumber: string | null | undefined,
  options?: {
    isPreselected: boolean;
  },
) {
  const accountNumberWithoutCensor = accountNumber
    ? removeCensoredAccountNumber(accountNumber)
    : '';
  switch (liabilityType) {
    case Liability_Type_Enum.HomeLoan:
    case Liability_Type_Enum.Personal:
    case Liability_Type_Enum.LineOfCredit:
    case Liability_Type_Enum.MarginOrInvestmentLoan:
    case Liability_Type_Enum.PersonalOverdraft:
    case Liability_Type_Enum.Bnpl:
    case Liability_Type_Enum.CreditCard:
    case Liability_Type_Enum.ChargeCard:
    case Liability_Type_Enum.StoreCard:
    case Liability_Type_Enum.NovatedLease:
    case Liability_Type_Enum.Other: {
      return `${prefix}-${replaceSpaceWithSeparator(
        // Why is this test id using translated string?
        t(`Content.Common.LiabilityType.${liabilityType}`),
        '-',
      )}-${accountNumberWithoutCensor}${
        options?.isPreselected ? '-preselected' : ''
      }`;
    }
    default: {
      return `${prefix}-${replaceSpaceWithSeparator(
        t('Content.Common.Word.Unknown'),
        '-',
      )}-${accountNumberWithoutCensor}${
        options?.isPreselected ? '-preselected' : ''
      }`;
    }
  }
}

function formatCaption({
  liabilityType,
  accountNumber,
}: Pick<Props, 'liabilityType' | 'accountNumber'>) {
  const isHomeOrPersonalLoan =
    liabilityType === Liability_Type_Enum.HomeLoan ||
    liabilityType === Liability_Type_Enum.Personal;

  const censoredAccountNumber = accountNumber
    ? generateCensoredCreditCardNumber(accountNumber)
    : null;

  const displayedAccountNumber = isHomeOrPersonalLoan
    ? accountNumber
    : censoredAccountNumber;

  switch (liabilityType) {
    case Liability_Type_Enum.HomeLoan:
    case Liability_Type_Enum.Personal:
    case Liability_Type_Enum.LineOfCredit:
    case Liability_Type_Enum.MarginOrInvestmentLoan:
    case Liability_Type_Enum.PersonalOverdraft:
    case Liability_Type_Enum.Bnpl:
    case Liability_Type_Enum.CreditCard:
    case Liability_Type_Enum.ChargeCard:
    case Liability_Type_Enum.StoreCard:
    case Liability_Type_Enum.NovatedLease:
    case Liability_Type_Enum.Other:
      // This is not the proper way to do i18n.
      // We should not do string concatenation on translated strings.
      // Instead, use 2nd argument of t() to pass value to the template.
      return `${t(
        `Content.Common.LiabilityType.${liabilityType}`,
      )} ${displayedAccountNumber}`;
    default:
      return `${t('Content.Common.Word.Unknown')} ${displayedAccountNumber}`;
  }
}

type Props = {
  liabilityType: Liability_Type_Enum;
  accountNumber: string | null | undefined;
  institutionName: string;
  name: string;
  forV2?: boolean;
};

export function LiabilityPanel({
  institutionName,
  liabilityType,
  accountNumber,
  name,
  forV2,
}: Props) {
  return (
    <ListRow
      label={institutionName}
      testID={buildLiabilityTestId(name, liabilityType, accountNumber)}
      caption={formatCaption({ liabilityType, accountNumber })}
      iconFamilyName="svg"
      iconColor="primaryContent"
      last
      {...(forV2
        ? {
            labelVariant: 'body',
            labelFontWeight: 'semiBold',
            captionVariant: 'body',
            captionColor: 'secondaryV2',
            // ListRow has hardcoded paddings,
            // here we extend the padding because
            // we can't override them from props.
            // The total padding might be off by 2-4px
            // TODO: Stop using ListRow here
            px: '2xs',
            py: 's',
          }
        : {
            iconName:
              DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE[liabilityType] ||
              'liabilityOtherLoan',
          })}
    />
  );
}
