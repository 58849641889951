import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { useGetAutopaySettingsQuery } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { formatCurrency } from '../../utils/currencyHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { HomeLoanScreenProps } from '../navigation/types';
import { useShowHomeLoanAutopayModal } from '../utils/useShowHomeLoanAutopayModal';

function NextRepaymentView({
  loading,
  amount,
  dueDate,
  onSetupAutopay,
}: {
  loading?: boolean;
  amount?: number;
  dueDate?: string;
  onSetupAutopay: () => void;
}) {
  const formattedAmount = amount
    ? formatCurrency(amount, { withFractionOnRoundedAmount: true })
    : undefined;

  const formattedDate = dueDate
    ? safelyFormatDate(new Date(dueDate), 'dd MMM yyyy') ?? ''
    : '';

  return (
    <>
      <DataRowGroup>
        <DataRow
          label={t('Content.NextRepayment.MinimumRepayment')}
          caption={formattedAmount}
          captionTestId={TestID.HomeLoan.NextRepayment.MinimumRepaymentAmount}
          loading={loading}
        />
        <DataRowSeparator />
        <DataRow
          label={t('Content.NextRepayment.When')}
          caption={formattedDate}
          captionTestId={TestID.HomeLoan.NextRepayment.DueDate}
          loading={loading}
        />
      </DataRowGroup>

      {!loading && (
        <Box column mt="l">
          <Button
            secondary
            width="100%"
            maxWidth="100%"
            label={t('Content.NextRepayment.SetupAutopay')}
            testID={TestID.HomeLoan.NextRepayment.SetupAutopayButton}
            onPress={onSetupAutopay}
          />
          <StyledText variant="caption" mt="s">
            {t('Content.NextRepayment.SetupAutopaySupportingText')}
          </StyledText>
        </Box>
      )}
    </>
  );
}

export type Props = HomeLoanScreenProps<Screen.HOME_NEXT_REPAYMENT>;

export function NextRepayment({ navigation, route }: Props) {
  const { cbaAccountId, amount, dueDate } = route.params;

  const {
    loading: autopaySettingsLoading,
    data: autopaySettingsData,
    error: autopaySettingsError,
    refetch,
  } = useGetAutopaySettingsQuery({
    variables: { cba_account_id: cbaAccountId },
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  const currentAutopayChangeRequest =
    autopaySettingsData?.current_autopay_change_request?.[0];

  const onSetupAutopay = useShowHomeLoanAutopayModal({
    navigate: navigation.navigate,
    cbaAccountId,
    currentAutopayChangeRequest,
    hasAutopay: !!autopaySettingsData?.loan_account_settings?.autopay,
    loading: autopaySettingsLoading,
    deeplinkModal: false,
  });

  const onClosePress = useCallback(() => navigation.goBack(), [navigation]);

  if (autopaySettingsError) {
    return (
      <ModalScreenContainer
        headerText={t('Content.HomeLoan.Repayment')}
        onClose={onClosePress}
        scrollable
        hideBackButton
      >
        <ScreenErrorFallback
          error={autopaySettingsError}
          displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
          refetch={refetch}
        />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      headerText={t('Content.HomeLoan.Repayment')}
      onClose={onClosePress}
      scrollable
      hideBackButton
      loading={autopaySettingsLoading}
    >
      <NextRepaymentView
        loading={autopaySettingsLoading}
        amount={amount}
        dueDate={dueDate}
        onSetupAutopay={onSetupAutopay}
      />
    </ModalScreenContainer>
  );
}
