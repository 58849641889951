import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function FinancialOtherIncomeIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        fill="#A3A3A3"
        d="M30.539 25.114c1.11 0 2.009-.887 2.009-1.982s-.9-1.982-2.009-1.982c-1.11 0-2.009.887-2.009 1.982s.9 1.982 2.009 1.982Z"
      />
      <Path
        fill="#A3A3A3"
        d="m19.86 31.206 11.628-6.335-1.72-3.57-10.077 4.606.17 5.299Z"
      />
      <Path
        fill="url(#a)"
        d="M34.959 23.925c0-1.095-.9-1.982-2.01-1.982-.273 0-.533.055-.77.152l-.01.003-.005.003L22.101 26.7l-1.205-.793c1.11 0 2.009-.887 2.009-1.982a1.97 1.97 0 0 0-1.018-1.715l.003-.013-10.97-5.443h-.02a4.038 4.038 0 0 0-1.656-.361 4.04 4.04 0 0 0-1.619.34h-.018l-6.399 2.831v10.308l6.501-2.948 12.786 5.16c.247.106.517.166.803.166.35 0 .673-.096.96-.251l.014-.001 11.627-6.334-.001-.002a1.97 1.97 0 0 0 1.06-1.737Z"
      />
      <Path
        fill="#2C2C2C"
        d="M20.896 25.114c.666 0 1.206-.532 1.206-1.189s-.54-1.19-1.206-1.19c-.665 0-1.205.533-1.205 1.19 0 .657.54 1.19 1.206 1.19Z"
      />
      <Path
        fill="url(#b)"
        d="m22.101 26.7-1.196-.791c-1.009-.048-1.802-.371-8.447-2.785v2.783l9.283 3.754s-.658-1.81.36-2.96Z"
      />
      <Path
        fill="#2C2C2C"
        d="M24.405 18.75a7.125 7.125 0 1 0 0-14.25 7.125 7.125 0 0 0 0 14.25Z"
      />
      <Path
        fill="url(#c)"
        d="M24.375 17.25a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Z"
      />
      <Path
        fill="#2C2C2C"
        d="M27 13.007c0-2.12-3.253-1.706-3.253-2.96 0-.653.764-.688 1.003-.688a3.87 3.87 0 0 1 1.551.34c.133.06.286-.03.286-.17V8.91c0-.207-.081-.33-.21-.378-.29-.108-.706-.187-1.32-.207v-.679a.15.15 0 0 0-.154-.147h-.306a.15.15 0 0 0-.154.147v.69c-.953.09-1.943.608-1.943 1.814 0 2.198 3.252 1.762 3.252 2.92 0 .363-.241.677-1.003.677-.765 0-1.468-.257-1.857-.449-.131-.064-.286.03-.286.17v.703c0 .135.084.255.213.307a5.76 5.76 0 0 0 1.624.373v.75a.15.15 0 0 0 .153.148h.306a.15.15 0 0 0 .154-.147v-.75C26.484 14.738 27 13.819 27 13.007Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={1.208}
          x2={13.415}
          y1={16.393}
          y2={42.372}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={16.747}
          x2={18.154}
          y1={26.992}
          y2={24.533}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CCC" stopOpacity={0.5} />
          <Stop offset={0.512} stopColor="#A3A3A3" stopOpacity={0.5} />
          <Stop offset={1} stopColor="#A3A3A3" />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={18.75}
          x2={30}
          y1={6}
          y2={17.25}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
