import { gql } from '@apollo/client';

import {
  Create_Loan_Application_Type_Input_Enum,
  Loan_Application_Type_Enum,
  LoanAppForGtmEventFragmentDoc,
  Property_Purpose_Enum,
  Property_Purpose_Input_Enum,
  refetchGetViewerLoanApplicationsQuery,
  useCreateLoanApplicationMutation,
  useCreateTopUpLoanApplicationMutation,
} from '../../generated/graphql';
import { getCookie } from '../../utils/cookies';
import { ensureEnumType, parseEnumType } from '../../utils/ensureEnumType';
import { EVERFLOW_TRACKING_COOKIE_NAME } from '../../utils/referralHelpers';

export const CREATE_LOAN_APPLICATION_MUTATION = gql`
  mutation CreateLoanApplication(
    $type: create_loan_application_type_input_enum!
    $trackingCookies: track_cookies
    $initialPropertyPurpose: property_purpose_input_enum
    $transactionId: String
  ) {
    create_loan_application(
      type: $type
      cookies: $trackingCookies
      initial_property_purpose: $initialPropertyPurpose
      transactionId: $transactionId
    ) {
      loan_application_id
      applicant_id
      loan_application {
        id
        type
        initial_property_purpose
        is_lmi_enabled
        ...LoanAppForGtmEvent
      }
    }
  }

  ${LoanAppForGtmEventFragmentDoc}
`;

export const CREATE_TOP_UP_LOAN_APPLICATION_MUTATION = gql`
  mutation CreateTopUpLoanApplication(
    $cbaAccountId: String!
    $trackingCookies: track_cookies
    $transactionId: String
  ) {
    create_top_up_loan_application(
      cbaAccountId: $cbaAccountId
      cookies: $trackingCookies
      transactionId: $transactionId
    ) {
      loan_application_id
      applicant_ids
    }
  }
`;

export function useCreateLoanApplication() {
  const [createLoanApplication, result] = useCreateLoanApplicationMutation();
  return [
    async (
      loanApplicationType: Loan_Application_Type_Enum,
      initialPropertyPurpose?: Property_Purpose_Enum,
    ) => {
      const { data } = await createLoanApplication({
        variables: {
          type: ensureEnumType(
            Create_Loan_Application_Type_Input_Enum,
            loanApplicationType,
          ),
          initialPropertyPurpose: parseEnumType(
            Property_Purpose_Input_Enum,
            initialPropertyPurpose,
          ),
          trackingCookies: {
            FPAU: getCookie('FPAU'),
            FPGCLAW: getCookie('FPGCLAW'),
            FPGCLDC: getCookie('FPGCLDC'),
            FPID: getCookie('FPID'),
            FPLC: getCookie('FPLC'),
            _fbc: getCookie('_fbc'),
            _fbp: getCookie('_fbp'),
            _ga: getCookie('_ga'),
            _ga_90HC7KTCGV: getCookie('_ga_90HC7KTCGV'),
            _ga_DH00NNSZXX: getCookie('_ga_DH00NNSZXX'),
            _gcl_au: getCookie('_gcl_au'),
            _gcl_gb: getCookie('_gcl_gb'),
            _gid: getCookie('_gid'),
          },
          transactionId: getCookie(EVERFLOW_TRACKING_COOKIE_NAME),
        },
        refetchQueries: [refetchGetViewerLoanApplicationsQuery()],
        context: {
          sentryContext: {
            loanApplicationType,
          },
        },
      });
      return {
        loanApplicationId: data?.create_loan_application?.loan_application_id,
        loanApplication: data?.create_loan_application.loan_application,
      };
    },
    result,
  ] as const;
}

export function useCreateTopUpLoanApplication() {
  const [createTopUpLoanApplication, result] =
    useCreateTopUpLoanApplicationMutation();
  return [
    async (cbaAccountId: string) => {
      const { data } = await createTopUpLoanApplication({
        variables: {
          cbaAccountId,
          trackingCookies: {
            FPAU: getCookie('FPAU'),
            FPGCLAW: getCookie('FPGCLAW'),
            FPGCLDC: getCookie('FPGCLDC'),
            FPID: getCookie('FPID'),
            FPLC: getCookie('FPLC'),
            _fbc: getCookie('_fbc'),
            _fbp: getCookie('_fbp'),
            _ga: getCookie('_ga'),
            _ga_90HC7KTCGV: getCookie('_ga_90HC7KTCGV'),
            _ga_DH00NNSZXX: getCookie('_ga_DH00NNSZXX'),
            _gcl_au: getCookie('_gcl_au'),
            _gcl_gb: getCookie('_gcl_gb'),
            _gid: getCookie('_gid'),
          },
          transactionId: getCookie(EVERFLOW_TRACKING_COOKIE_NAME),
        },
        refetchQueries: [refetchGetViewerLoanApplicationsQuery()],
        context: {
          sentryContext: {
            loanApplicationType: Loan_Application_Type_Enum.TopUp,
          },
        },
      });
      return {
        loanApplicationId:
          data?.create_top_up_loan_application?.loan_application_id,
      };
    },
    result,
  ] as const;
}
