import * as React from 'react';
import {
  Platform,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';

import { Color, Size } from '../types';
import { Box } from './Box';
import {
  IconFamilyName,
  Props as StyledIconProps,
  StyledIcon,
} from './StyledIcon';
import { StyledText } from './StyledText';

type BaseProps = Pick<
  React.ComponentProps<typeof StyledIcon>,
  'width' | 'height'
> & {
  position: 'left' | 'right';
  iconName?: string;
  iconSize?: Size;
  iconFamily?: IconFamilyName;
  iconRoundedBg?: boolean;
  iconBg?: StyledIconProps['bg'];
  color?: Color;
  text?: string;
  disabled?: boolean;
  hidden?: boolean;
  onPress?: () => void;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
};

export type Props = Omit<TouchableOpacityProps, keyof BaseProps | 'hitSlop'> &
  BaseProps;

export function shouldRenderIcon(
  text: string | undefined,
  iconName: string | undefined,
): boolean {
  return Boolean(
    (Platform.OS === 'android' && text && iconName) || (iconName && !text),
  );
}

const hitSlop = {
  top: 8,
  left: 8,
  right: 8,
  bottom: 8,
};

export const HeaderButton: React.FC<Props> = ({
  position,
  iconName,
  iconFamily,
  iconSize = 'navIcon',
  iconRoundedBg,
  iconBg,
  color,
  text,
  disabled,
  hidden,
  onPress,
  textStyle,
  width,
  height,
  ...props
}) => {
  if (hidden) return null;
  const content =
    shouldRenderIcon(text, iconName) && iconName ? (
      <StyledIcon
        name={iconName}
        color={disabled ? 'disabledContent' : color ?? 'accent'}
        style={textStyle}
        size={iconSize}
        fixedHeight
        family={iconFamily}
        roundBg={iconRoundedBg}
        bg={iconBg}
        width={width}
        height={height}
      />
    ) : (
      <StyledText
        color={disabled ? 'disabledContent' : 'accent'}
        fontWeight={position === 'right' ? 'semiBold' : undefined}
        fontSize="m"
        style={textStyle}
      >
        {text}
      </StyledText>
    );
  if (!onPress) {
    return <Box {...props}>{content}</Box>;
  }
  return (
    <TouchableOpacity
      role="button"
      onPress={onPress}
      disabled={disabled || !onPress}
      {...props}
      hitSlop={hitSlop}
    >
      {content}
    </TouchableOpacity>
  );
};
