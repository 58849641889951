import * as React from 'react';

import { Screen } from '../../navigation/types/screens';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Fastlink } from '../components/Fastlink';
import { SelectInstitutionScreenProps } from '../navigation/types';
import { fastlinkEmitter } from '../utils/fastlinkEmitter';
import { FastlinkFlow } from '../utils/fastlinkWebTypes';

type Props = SelectInstitutionScreenProps<Screen.BANK_REFRESH>;

export const BankAccountRefresh: React.FC<Props> = ({ navigation, route }) => {
  const { providerAccountId } = route.params;

  return (
    <ScreenContainer safeAreaBottom={false}>
      <Fastlink
        onSuccess={({
          providerAccountId: institutionConnectionId,
          requestId,
        }) => {
          fastlinkEmitter.emit('completed', {
            requestId,
            institutionConnectionId: Number(institutionConnectionId),
          });
        }}
        onError={({
          providerAccountId: institutionConnectionId,
          requestId,
          reason,
        }) => {
          fastlinkEmitter.emit('failed', {
            institutionConnectionId: Number(institutionConnectionId),
            requestId,
            reason,
          });
        }}
        onClose={navigation.goBack}
        errorContext={route.params}
        navigateBack={navigation.goBack}
        flow={FastlinkFlow.refresh}
        providerAccountId={providerAccountId}
      />
    </ScreenContainer>
  );
};
