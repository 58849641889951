import { NetworkStatus } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { ErrorRow } from '../../../components/ErrorRow';
import { RefreshPageErrorRow } from '../../../components/RefreshPageErrorRow';
import { ScreenLoadingContainer } from '../../../components/ScreenLoadingContainer';
import { useFullNameByApplicantIds } from '../../../DebtsWizard/utils/useAllLiabilitiesQuery';
import {
  Liability_Type_Enum,
  refetchGetDisclosureContentQuery,
  refetchGetTotalLoanAmountQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourUnloanScreenV2Query,
  Refinancing_Reason_Input_Enum,
  Security_Cost_Calculation_State_Enum,
  Top_Up_Reason_Input,
  Top_Up_Reason_Input_Enum,
  UpsertTopUpLoanApplicationTargetMutationVariables,
  useGetTargetLoanQuery,
  useSetupLoanScreenQuery,
  useUpsertTopUpLoanApplicationTargetMutation,
} from '../../../generated/graphql';
import { NoLoanApplicationFound } from '../../../LoanApplication/components/NoLoanApplicationFound';
import { Screen } from '../../../navigation/types/screens';
import { captureMessage } from '../../../sentry';
import { ModalScreenContainer } from '../../../ui/v2/ModalScreenContainer';
import { parseEnumType } from '../../../utils/ensureEnumType';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { useSecurityCostCalculationState } from '../../../utils/hooks/useSecurityCostCalculationState';
import { isTopUpHomeLoan } from '../../../utils/isTopUpHomeLoan';
import {
  getMergedLiabilitiesAllowedToBeConsolidated,
  getMergedLiabilitiesAllowedToBeRefinanced,
} from '../../../utils/mergedLiabilities';
import { handleSetupLoanErrorType } from '../../utils/handleSetupLoanErrorType';
import {
  getInitialSetupLoanTopUpValues,
  SetupLoanFormFieldV2,
  SetupLoanTopUpFormInitialValues,
  SetupLoanYesNoEnum,
} from '../../utils/setupLoanFormHelper';
import { useLoanAmountCalculationV2 } from '../../utils/useSetupLoanCalculation';
import { SetupLoanTopUpForm } from '../components/SetupLoanTopUpForm';

type Props = {
  screen: Screen;
  onSubmitSuccess: () => void;
  onClose: () => void;
  hideBackButton: boolean;
  loanApplicationTargetId: string | undefined;
  loanApplicationId: string | undefined;
};

export function SetupLoanTopUpBase({
  screen,
  onSubmitSuccess,
  onClose,
  hideBackButton,
  loanApplicationTargetId,
  loanApplicationId,
}: Props) {
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  const {
    data,
    loading: setupLoanScreenLoading,
    networkStatus,
  } = useSetupLoanScreenQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const { data: targetLoanData, loading: targetLoanLoading } =
    useGetTargetLoanQuery({
      variables: {
        targetLoanId: loanApplicationTargetId || '',
      },
      skip: !loanApplicationTargetId,
      notifyOnNetworkStatusChange: true,
      context: {
        sentryContext: {
          loanApplicationId,
          loanApplicationTargetId,
        },
      },
    });

  const captureCurrentLoadingStates = useCallback(
    (costCalculationState?: Security_Cost_Calculation_State_Enum | null) => {
      captureMessage(
        'Security cost calculation took too long - SetupLoanTopUpBase',
        {
          loanApplicationId,
          costCalculationState,
          // Adding other loading states to help debug and discover false positive
          targetLoanLoading,
        },
      );
    },
    [loanApplicationId, targetLoanLoading],
  );

  const {
    waitingForCostCalculationToBeCompleted,
    isWaitingForRetryToComplete,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    logToSentryIfCalculationTookLongTime: true,
    onLogCalculationTookTooLong: captureCurrentLoadingStates,
  });

  const loanApplication = data?.loanApplication;

  const loanApplicationType = loanApplication?.type;

  const parentLoanAccount = loanApplication?.parent_loan_account;

  const redrawBalance = parentLoanAccount?.balances?.available_redraw_balance;

  const initialLoanTermMonths =
    parentLoanAccount?.settings?.remaining_term_in_months;

  const existingLoanTarget = targetLoanData?.loan_application_target_by_pk;
  // There will always be 1 financial declaration per loan application
  const financialDeclaration = data?.financial_declaration?.[0];

  const initialEffectiveInterestRate =
    parentLoanAccount?.settings?.interest_rate;

  const fullNameByApplicantIds = useFullNameByApplicantIds(data?.applicants);

  const loading =
    setupLoanScreenLoading ||
    targetLoanLoading ||
    networkStatus === NetworkStatus.refetch;

  const mergedLiabilitiesAllowedToBeRefinanced = useMemo(
    () =>
      getMergedLiabilitiesAllowedToBeRefinanced(
        data?.mergedLiabilitiesAllowedToBeRefinanced.map(
          (ml) => ml.merged_liability,
        ),
      ),
    [data?.mergedLiabilitiesAllowedToBeRefinanced],
  );

  const [
    upsertTopUpLoanApplicationTarget,
    { loading: upsertTopUpLoanApplicationTargetLoading },
  ] = useUpsertTopUpLoanApplicationTargetMutation();

  const refinanceReason = financialDeclaration?.refinancing_reason || undefined;

  const parsedRefinanceReason = parseEnumType(
    Refinancing_Reason_Input_Enum,
    refinanceReason,
  );

  const onSubmit = useCallback(
    async (values: SetupLoanTopUpFormInitialValues) => {
      const termInMonths =
        (values.termYearsInMonths ?? 0) + (values.termMonths ?? 0);

      if (
        !loanApplicationId ||
        !parsedRefinanceReason ||
        !termInMonths ||
        values.selectedLoansToBeRefinanced == null ||
        values.desiredLoanAmount == null
      ) {
        setFormErrorMessage(
          t('Content.Common.Error.FailCreateLoanApplicationTarget'),
        );
        return;
      }
      setFormErrorMessage(null);
      const topUpDetails: Array<Top_Up_Reason_Input> =
        values.hasTopUpAmount === SetupLoanYesNoEnum.Yes
          ? [
              {
                top_up_reason: Top_Up_Reason_Input_Enum.YourHome,
                top_up_amount:
                  values[SetupLoanFormFieldV2.YourHomeTopUpAmount] ?? 0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.FinancialInvestment,
                top_up_amount:
                  values[SetupLoanFormFieldV2.FinancialInvestmentTopUpAmount] ??
                  0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.InvestmentProperty,
                top_up_amount:
                  values[SetupLoanFormFieldV2.InvestmentPropertyTopUpAmount] ??
                  0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.PersonalUse,
                top_up_amount:
                  values[SetupLoanFormFieldV2.PersonalUseTopUpAmount] ?? 0,
              },
              {
                top_up_reason: Top_Up_Reason_Input_Enum.Other,
                top_up_amount:
                  values[SetupLoanFormFieldV2.OtherTopUpAmount] ?? 0,
                other_top_up_reason_description:
                  values[SetupLoanFormFieldV2.OtherTopUpDescription] ?? '',
              },
            ].filter(
              ({ top_up_amount }) => top_up_amount != null && top_up_amount > 0,
            )
          : [];

      const upsertLoanTargetVariables: UpsertTopUpLoanApplicationTargetMutationVariables =
        {
          desired_loan_amount: values.desiredLoanAmount,
          loan_application_id: loanApplicationId,
          loan_term_in_months: termInMonths,
          consolidated_liability_ids: values.selectedLoansToBeRefinanced,
          top_up_reason: parsedRefinanceReason,
          cash_out_reasons: topUpDetails,
        };

      const [upsertLoanTargetRes] = await safelyCallMutation(
        upsertTopUpLoanApplicationTarget,
        {
          variables: upsertLoanTargetVariables,
          refetchQueries: [
            refetchYourUnloanScreenV2Query({
              loanApplicationId,
            }),
            refetchGetTotalLoanAmountQuery({ loanApplicationId }),
            refetchReviewLoanApplicationQuery({ loanApplicationId }),
            refetchGetDisclosureContentQuery({ loanApplicationId }),
            refetchSetupLoanScreenQuery({ loanApplicationId }),
          ],
          awaitRefetchQueries: true,
          context: {
            sentryContext: {
              loanApplicationId,
              consolidatedLiabilityIds:
                upsertLoanTargetVariables.consolidated_liability_ids,
              loanTermInMonths: upsertLoanTargetVariables.loan_term_in_months,
            },
          },
        },
      );

      if (upsertLoanTargetRes == null) {
        setFormErrorMessage(
          t('Content.Common.Error.FailUpdateLoanApplicationTarget'),
        );
        return;
      }

      const upsertLoanTarget =
        upsertLoanTargetRes.data?.upsert_top_up_loan_application_target;
      const upsertLoanTargetErrorType = upsertLoanTarget?.error_type;
      const minTotalLoanAmountBeforeCosts =
        upsertLoanTarget?.minimum_total_loan_amount_before_cost;

      if (upsertLoanTargetErrorType) {
        setFormErrorMessage(
          handleSetupLoanErrorType(upsertLoanTargetErrorType, {
            minTotalLoanAmountBeforeCosts,
          }),
        );
        return;
      }

      onSubmitSuccess();
    },
    [
      loanApplicationId,
      parsedRefinanceReason,
      upsertTopUpLoanApplicationTarget,
      onSubmitSuccess,
    ],
  );

  const {
    calculateLoanAmounts: calculateLoanAmountsV2,
    calculationData: calculationDataV2,
    calculationLoading: calculationLoadingV2,
  } = useLoanAmountCalculationV2();

  const topUpProps = useMemo(() => {
    const topUpLiability = mergedLiabilitiesAllowedToBeRefinanced.find(
      (liability) =>
        isTopUpHomeLoan({
          loanApplicationType,
          liabilityType: parseEnumType(
            Liability_Type_Enum,
            liability.dynamite_liability_type,
          ),
          forRefinancing: liability.dynamite_for_refinancing,
        }),
    );

    // This should not happen, but if it does, we should show an error
    if (!topUpLiability) {
      return null;
    }

    const liabilitiesAllowedToBeConsolidated =
      getMergedLiabilitiesAllowedToBeConsolidated(
        mergedLiabilitiesAllowedToBeRefinanced,
        loanApplicationType,
      );

    return {
      topUpLiability,
      liabilitiesAllowedToBeConsolidated,
    };
  }, [loanApplicationType, mergedLiabilitiesAllowedToBeRefinanced]);

  if (loading) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={onClose}
        hideBackButton={hideBackButton}
      >
        <ScreenLoadingContainer loading />
      </ModalScreenContainer>
    );
  }

  if (!loanApplicationId || !topUpProps) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={onClose}
        hideBackButton={hideBackButton}
      >
        <NoLoanApplicationFound />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      scrollable
      headerText={t('Content.SetupLoanTopUp.ScreenTitle')}
      onClose={onClose}
      hideBackButton={hideBackButton}
      loading={upsertTopUpLoanApplicationTargetLoading}
    >
      {shouldShowErrorForCostCalculation ? (
        <RefreshPageErrorRow
          onRefreshPress={retrySecurityCostCalculation}
          sx={{ mb: '$16' }}
          refreshDisabled={isWaitingForRetryToComplete}
          refreshLoading={isWaitingForRetryToComplete}
        />
      ) : null}
      <ErrorRow message={formErrorMessage} my="s" />

      <SetupLoanTopUpForm
        screen={screen}
        {...topUpProps}
        fullNameByApplicantIds={fullNameByApplicantIds}
        productRate={initialEffectiveInterestRate}
        initialValues={getInitialSetupLoanTopUpValues({
          ...topUpProps,
          targetLoan: existingLoanTarget,
          financialDeclarationTopUpDetails:
            financialDeclaration?.top_up_reasons || [],
          initialLoanTermMonths,
        })}
        loanApplicationId={loanApplicationId}
        isSubmitting={upsertTopUpLoanApplicationTargetLoading}
        onSubmit={onSubmit}
        calculateLoanAmounts={calculateLoanAmountsV2}
        calculationData={calculationDataV2}
        calculationLoading={
          calculationLoadingV2 || waitingForCostCalculationToBeCompleted
        }
        initialLoanTermMonths={initialLoanTermMonths}
        redrawBalance={redrawBalance}
      />
    </ModalScreenContainer>
  );
}
