import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { ErrorFallback } from '../../components/ErrorFallback';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { LoanApplicationScreenContainer } from '../../LoanApplication/components/LoanApplicationScreenContainer';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';

export function CompletedContractSigning({
  navigateToHome,
  errorMessage,
  loanApplicationType,
}: {
  navigateToHome: () => void;
  errorMessage?: string | null;
  loanApplicationType?: Loan_Application_Type_Enum;
}) {
  const content = useMemo(() => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      return (
        <>
          <StyledText variant="header" mt="l" mb="m" textAlign="center">
            {t('Content.CompletedVariationAgreementSigning.Header')}
          </StyledText>
          <StyledText variant="body" mt="l" mb="m" textAlign="center">
            {t('Content.CompletedVariationAgreementSigning.Content')}
          </StyledText>
        </>
      );
    }

    return (
      <StyledText variant="header" mt="l" mb="m" textAlign="center">
        {t('Content.CompletedContractSigning.Header')}
      </StyledText>
    );
  }, [loanApplicationType]);

  if (errorMessage) {
    return (
      <ErrorFallback
        title={errorMessage}
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        hideUnloanLogo
        bg="transparent"
        flex={1}
        justifyContent="center"
      />
    );
  }

  return (
    <LoanApplicationScreenContainer containerStyle={styles.root}>
      <NavHeaderSpacer />
      <GreenCheckAnimation />
      {content}
      <Button
        label={t('Content.Common.ButtonLabel.BackToDashboard')}
        m="m"
        testID={TestID.CompletedContractSigning.BackToDashboardButton}
        width="100%"
        onPress={navigateToHome}
      />
    </LoanApplicationScreenContainer>
  );
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
