import {
  ApplicantStatusInfoFragment,
  Loan_Application_Status_Info_Status_Enum,
} from '../../generated/graphql';

/**
 * Filter out any applied loans that are not supposed to be visible to the user.
 * i.e. canceled or won
 *
 * see details in https://github.com/unloan/unloan-app/issues/4362
 */
export function filterAppliedLoansForDisplay(
  appliedLoans: Array<ApplicantStatusInfoFragment>,
) {
  return appliedLoans.filter(
    (appliedLoan) =>
      appliedLoan.loanApplication.statusInfo?.loanApplicationStatus ===
      Loan_Application_Status_Info_Status_Enum.Active,
  );
}
