import { add } from 'date-fns';
import { useMemo } from 'react';

import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { generateTermRemainingGraphData } from '../utils/termRemainingGraphUtils';

export const RATE_ADJUSTMENT_INTERVAL_IN_MONTHS = 12;

export const useLoanAccountTermRemainingInsight = (cbaAccountId: string) => {
  const { data: loan, loading: isLoadingLoanAccount } =
    useLoanAccountState(cbaAccountId);
  const termRemainingInsights = loan?.term_remaining_insights;

  const data = useMemo(() => {
    const {
      originalRepaymentPoints,
      originalRepaymentPath,
      projectedRepaymentPoints,
      projectedRepaymentPath,
    } = generateTermRemainingGraphData(termRemainingInsights?.details || []);

    const termsInMonthsBeEarlier =
      termRemainingInsights?.payoff_ahead_in_months || 0;

    const isNoEarlierTerms = termsInMonthsBeEarlier === 0;

    const remainTermsWithAdditionalRepayment =
      (loan?.settings?.remaining_term_in_months || 0) - termsInMonthsBeEarlier;

    const currentDate = new Date();
    const originalPayOffYear = add(currentDate, {
      months: loan?.settings?.remaining_term_in_months || 0,
    })
      .getFullYear()
      .toString();
    const projectedPayOffYear = add(currentDate, {
      months: remainTermsWithAdditionalRepayment,
    })
      .getFullYear()
      .toString();

    const projectedPayOffDate =
      safelyFormatDate(
        add(currentDate, {
          months: remainTermsWithAdditionalRepayment,
        }),
        'MMMM yyyy',
      ) || '';

    return {
      projectedRepaymentPath: isNoEarlierTerms ? '' : projectedRepaymentPath,
      projectedRepaymentPoints,
      originalRepaymentPath,
      originalRepaymentPoints,
      isNoEarlierTerms,
      originalPayOffYear,
      projectedPayOffYear,
      projectedPayOffDate,
      termsInMonthsBeEarlier,
      currentRedrawBalance: loan?.balances?.available_redraw_balance,
    };
  }, [
    loan?.balances?.available_redraw_balance,
    loan?.settings?.remaining_term_in_months,
    termRemainingInsights?.details,
    termRemainingInsights?.payoff_ahead_in_months,
  ]);

  return {
    data,
    loading: isLoadingLoanAccount,
  };
};
