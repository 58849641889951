import { useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { useDeleteUserAccountMutation } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET>;

export const DeleteAccountConfirmationCheckActionSheet = ({
  navigation,
}: Props) => {
  const [deleteAccount, { loading, error }] = useDeleteUserAccountMutation();
  const closeModal = useCallback(() => navigation.goBack(), [navigation]);
  const deleteAccountFn = useCallback(async () => {
    const [deleteUserRes] = await safelyCallMutation(deleteAccount, {
      context: {
        sentryContext: {
          description: 'Deleting user account.',
        },
      },
    });

    if (deleteUserRes?.data?.delete_user_account?.user_id) {
      navigation.replace(ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET);
    }
  }, [deleteAccount, navigation]);

  return (
    <ActionSheet
      closeModal={closeModal}
      preventCloseModal={loading}
      title={t('Content.Settings.DeleteAccount.ConfirmationCheckTitle')}
      showCloseButton={false}
      actionList={[
        {
          label: t('Content.Settings.DeleteAccount.DeleteButton'),
          onPress: deleteAccountFn,
          isDestructive: false,
          testID: TestID.Settings.DeleteUser.ConfirmationCheckDeleteButton,
          fontWeight: 'normal',
          disabled: loading,
          showSpinner: loading,
        },
        {
          label: t('Content.Settings.DeleteAccount.GoBackButton'),
          onPress: closeModal,
          tertiary: true,
          color: 'buttonSecondaryContent',
          disabled: loading,
          showSpinner: false,
          fontWeight: 'normal',
        },
      ]}
      errorMessage={
        error ? t('Content.Common.Error.UnableToProcessRequest') : undefined
      }
    >
      <StyledText mt="m" textAlign="center" fontSize="s">
        {t('Content.Settings.DeleteAccount.ConfirmationText')}
      </StyledText>
      <StyledText
        mt="m"
        lineHeight="xs"
        color="secondaryContent"
        textAlign="center"
        px="s"
        fontSize="xs"
      >
        {t('Content.Settings.DeleteAccount.ConfirmationSubTextPart1')}{' '}
        <Link fontSize="xs" href={t('Link.PrivacyPolicy')}>
          {t('Content.Settings.DeleteAccount.PrivacyPolicy')}
        </Link>{' '}
        {t('Content.Settings.DeleteAccount.ConfirmationSubTextPart2')}
      </StyledText>
    </ActionSheet>
  );
};
