import { addDays } from 'date-fns';
import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { CloseModalIconButton } from '../../components/CloseModalIconButton';
import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { DataSharingConfirmationProps } from '../navigation/types';

type Props = DataSharingConfirmationProps<Screen.DATA_SHARING_CONFIRMATION> & {
  // Required for storybook
  _today?: Date;
};

const RequestSent: React.FC = () => (
  <StyledText variant="default" mt="s" fontWeight="semiBold">
    {t('Content.DataSharingSummary.RequestSent')}
  </StyledText>
);

const ConfirmationMessage: React.FC<{
  date: Date;
  onPress: () => void;
}> = ({ date, onPress }) => (
  <>
    <StyledText variant="body" mx="m" mt="l" textAlign="center">
      {t('Content.DataSharingSummary.Confirmation', {
        date: safelyFormatDate(date, 'dd MMM yyyy'),
      })}
    </StyledText>
    <StyledText onPress={onPress} variant="body" mt="xs" color="link">
      {t('Content.DataSharingSummary.BackToSharingPreferences')}
    </StyledText>
  </>
);

export function DataSharingConfirmation({
  navigation,
  // Required so we can inject hard-coded date in Storybook
  _today = new Date(),
}: Props) {
  const closeModal = useCallback(() => navigation.goBack(), [navigation]);
  const date = addDays(_today, 7); // Future Work - should come from graphql instead
  return (
    <ModalScreenContainer bg="bg" inset>
      <NavHeaderSpacer />
      <CloseModalIconButton onPress={closeModal} />
      <Separator spacer my="3xl" />
      <Box centered flexDirection="column">
        <GreenCheckAnimation
          testID={TestID.DataSharingConfirmation.Confirmation.GreenCheck}
        />
        <RequestSent />
        <ConfirmationMessage date={date} onPress={closeModal} />
      </Box>
    </ModalScreenContainer>
  );
}
