import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export const YourPropertyIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => {
  const idPrefix = useSvgIdPrefix('YourPropertyIcon');
  return (
    <Svg width={45} height={45} viewBox="0 0 45 45" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.361 20.625V41.25c0 .952.697.946 1.6.939l.246-.002H37.73l.245.002c.904.007 1.6.013 1.6-.939V20.625L22.97 4.687 6.361 20.626Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M12.82 25.313h9.227c.509 0 .922.42.922.937v15.938H11.897V26.25a.93.93 0 0 1 .923-.938Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.05}
        d="M22.97 5.625c-.438 0-.876.157-1.224.472l-4.945 4.51-10.44 10.018v5.053L22.97 10.714l16.608 14.964v-5.053l-10.44-10.018-4.945-4.51a1.824 1.824 0 0 0-1.223-.472Z"
        fill="#2C2C2C"
      />
      <Path
        d="M41.879 20.163 24.192 4.222a1.824 1.824 0 0 0-2.447 0L4.06 20.162a.947.947 0 0 0-.06 1.341l1.51 1.636c.34.37.908.398 1.283.063L22.969 8.84l16.177 14.364a.913.913 0 0 0 1.282-.063l1.51-1.636a.948.948 0 0 0-.06-1.341Z"
        fill="#A3A3A3"
      />
      <Path
        d="M27.582 25.313h5.536c.51 0 .922.42.922.937v5.625a.93.93 0 0 1-.922.938h-5.536a.93.93 0 0 1-.923-.938V26.25a.93.93 0 0 1 .923-.938Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={6.361}
          y1={4.688}
          x2={43.589}
          y2={37.66}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
