import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { InfoRow } from '../../components/InfoRow';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import { homeLoanWithdrawalDetailAtom } from '../../HomeLoan/recoil/homeLoanWithdrawalDetailAtom';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { formatCurrency } from '../../utils/currencyHelpers';
import { ActionSheet } from '../components/ActionSheet';
import { BaseConfirmationSuccessActionSheet } from '../components/BaseConfirmationSuccessActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import { useConfirmPayment } from '../utils/useConfirmPayment';

type Props = ActionSheetScreenProps<ActionSheetType.WITHDRAW_CONFIRMATION>;

export const makeRedrawThresholdExceededWarning = ({
  nextInstalmentAmount,
  nextInstalmentDate,
}: {
  nextInstalmentAmount: number;
  nextInstalmentDate?: string;
}) =>
  t('Content.Withdraw.RedrawThresholdExceededWarning', {
    amount: formatCurrency(nextInstalmentAmount),
    date: nextInstalmentDate
      ? format(new Date(nextInstalmentDate), 'dd MMM yyyy')
      : '--',
  });

export const WithdrawConfirmationActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const [paymentProcessError, setPaymentProcessError] = useState<string>();

  const selectedAccount = useRecoilValue(selectedExternalAccountAtom);

  const resetSelectedExternalAccount = useResetRecoilState(
    selectedExternalAccountAtom,
  );

  const withdrawDetail = useRecoilValue(homeLoanWithdrawalDetailAtom);
  const cbaAccountId = route.params.cbaAccountId || '';

  const [createdPaymentRequestId, setCreatedPaymentRequestId] = useState<
    string | null
  >(null);

  const onExceedsDailyLimit = useCallback(
    (exceededByAmount?: number | null) => {
      navigation.navigate(ActionSheetType.TRANSFER_LIMIT_REACHED, {
        cbaAccountId,
        exceededByAmount,
      });
    },
    [cbaAccountId, navigation],
  );

  const onSuccess = useCallback(
    (newPaymentRequestId: string) => {
      resetSelectedExternalAccount();
      setCreatedPaymentRequestId(newPaymentRequestId);
    },
    [resetSelectedExternalAccount],
  );

  const {
    confirmPayment,
    loading,
    error: confirmPaymentError,
  } = useConfirmPayment({
    screen: ActionSheetType.WITHDRAW_CONFIRMATION,
    cbaAccountId,
    onError: setPaymentProcessError,
    onExceedsDailyLimit,
    onSuccess,
  });

  const handleConfirmPayment = useCallback(
    () => confirmPayment(withdrawDetail),
    [confirmPayment, withdrawDetail],
  );

  const { data: loanData } = useLoanAccountState(route.params.cbaAccountId);

  const loanAccountName = loanData?.settings?.name ?? '';

  const targetAccountNumber = selectedAccount?.accountDetails ?? '';

  const { amount } = withdrawDetail;

  const availableRedrawAmount =
    loanData?.balances?.available_redraw_balance ?? 0;

  const nextInstalmentAmount = loanData?.next_installment?.amount ?? 0;

  const nextInstalmentDate = loanData?.next_installment?.next_installment_date;

  const redrawThreshold =
    availableRedrawAmount > nextInstalmentAmount
      ? availableRedrawAmount - nextInstalmentAmount
      : 0;

  // Show warning only if the amount exceeds the threshold
  // Future work: the feature flag needs to be removed
  const warningMessage =
    amount > redrawThreshold ? (
      <Box
        testID={
          TestID.WithdrawConfirmationActionSheet.RedrawThresholdExceededWarning
        }
        mt="s"
        pb="m"
      >
        <InfoRow
          message={makeRedrawThresholdExceededWarning({
            nextInstalmentAmount,
            nextInstalmentDate,
          })}
        />
      </Box>
    ) : null;

  const getErrorMessage = () => {
    if (confirmPaymentError) {
      return t('Content.Common.Error.FailWithdrawRequest');
    }

    if (paymentProcessError) {
      return t('Content.Common.Error.FailPaymentProcessing');
    }

    return null;
  };

  if (createdPaymentRequestId) {
    const onConfirmed = () => {
      navigation.navigate(Screen.HOME_LOAN_MODAL, {
        screen: Screen.HOME_PENDING_TRANSACTION,
        params: {
          paymentRequestId: createdPaymentRequestId,
        },
      });
    };

    return (
      <BaseConfirmationSuccessActionSheet
        message={t('Content.Withdraw.ConfirmSuccessMessage')}
        onClose={onConfirmed}
      />
    );
  }

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      title={t('Content.Withdraw.ConfirmTransfer')}
      actionList={[
        {
          label: t('Content.Withdraw.Confirm'),
          secondary: false,
          disabled: loading,
          showSpinner: loading,
          testID: TestID.WithdrawConfirmationActionSheet.ConfirmButton,
          onPress: handleConfirmPayment,
        },
      ]}
      errorMessage={getErrorMessage()}
    >
      {warningMessage}
      <Box p="m" px="xl">
        <StyledText textAlign="center">
          {t('Content.Withdraw.ConfirmContent', {
            amount: formatCurrency(amount),
            from: loanAccountName,
            to: targetAccountNumber,
          })}
        </StyledText>
      </Box>
      <StyledText variant="caption" mt="l" textAlign="center">
        {t('Content.Withdraw.ConfirmCaption')}
      </StyledText>
    </ActionSheet>
  );
};
