import { getLogger } from '../utils/loggerHelper';
import { BiocatchConfig } from './biocatchConfigHelper';
import {
  getCustomerSessionId,
  setCustomerSessionId,
} from './biocatchWebStorage';
import { generateCustomerSessionId } from './generateCSID';

// Manually curated from Biocatch documentation
// 1. https://docs.biocatch.com/docs/js-mandatory-functions
// 2. https://docs.biocatch.com/docs/js-extended-functions
export interface BiocatchJsApi {
  startNewSession: () => void;
  changeContext: (event: string) => void;
  setCustomerSessionId: (csid: string) => void;
  setCustomerBrand: (brand: string) => void;
  registerSessionNumberChange: (callback: (sid: number) => void) => void;
  pauseCollection: () => void;
  resumeCollection: () => void;
  sendMetadata: (metadata: Record<string, unknown>) => void;
  flush: () => void;
}

export interface Window {
  cdApi?: BiocatchJsApi;
}

export const isBiocatchWebInitialised = () => !!window.cdApi;

const logger = getLogger('APP');

function loadBiocatchScript(biocatchScriptURL: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    if (isBiocatchWebInitialised()) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = biocatchScriptURL;
    script.async = true;
    script.onload = () => {
      if (!isBiocatchWebInitialised()) {
        reject(new Error('Biocatch script failed to load'));
      } else {
        resolve();
      }
    };
    document.body.appendChild(script);
  });
}

export function getOrCreateBiocatchSessionId(): string {
  let sessionId = getCustomerSessionId();
  if (!sessionId) {
    sessionId = generateCustomerSessionId();
    setCustomerSessionId(sessionId);
  }
  return sessionId;
}

export async function startBiocatchDataCaptureWeb(config: BiocatchConfig) {
  await loadBiocatchScript(config.biocatchScriptURL);

  const sessionId = getOrCreateBiocatchSessionId();

  window.cdApi.setCustomerSessionId(sessionId);

  return sessionId;
}

export function changeContextWeb(event: string) {
  if (!isBiocatchWebInitialised()) {
    logger?.log(
      'Biocatch - Skipping change context as Biocatch is not loaded',
      event,
    );
    return;
  }

  window.cdApi.changeContext(event);
}
