import { AuthType } from '../generated/graphql';

export const mockPaymentSource = {
  __typename: 'Institution' as const,
  authType: AuthType.Credentials,
  name: 'Commonwealth Bank CBA',
  logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3857_3_1.PNG',
  loginUrl: 'https://www.my.commbank.com.au/netbank/Logon/Logon.aspx',
  id: 3857,
  baseUrl: 'https://www.commbank.com.au/',
  favicon: 'https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_3857.PNG',
  cardNumber: '8888',
};

export const ACCOUNT_DETAILS = {
  name: '11 Diggers Ave',
  account: '013-964 446981201',
  payId: '48473733@unloan.me',
};

export const SENDER_DETAIL = {
  __typename: 'Institution' as const,
  authType: AuthType.Credentials,
  name: 'Commonwealth Bank CBA',
  logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3857_3_1.PNG',
  loginUrl: 'https://www.my.commbank.com.au/netbank/Logon/Logon.aspx',
  id: 3857,
  baseUrl: 'https://www.commbank.com.au/',
  favicon: 'https://yodlee-1.hs.llnwd.net/v1/FAVICON/FAV_3857.PNG',
  cardNumber: '2829',
};

export const LOCATION = {
  latitude: -33.82006,
  longitude: 151.128999,
};
export const mockPaymentSourceList = {
  3857: mockPaymentSource,
};
