import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { SvgIconProps } from './types';

export const TickIcon: React.FC<SvgIconProps> = ({ size, color, ...props }) => {
  const theme = useTheme();
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M6.54443 15.4692C7.04248 15.4692 7.43262 15.27 7.70654 14.8633L15.4761 2.87695C15.6753 2.57812 15.7583 2.3042 15.7583 2.04688C15.7583 1.36621 15.2603 0.876465 14.563 0.876465C14.0815 0.876465 13.791 1.04248 13.5005 1.50732L6.51123 12.564L2.94189 8.06494C2.65967 7.72461 2.36914 7.56689 1.9541 7.56689C1.24854 7.56689 0.742188 8.06494 0.742188 8.75391C0.742188 9.05273 0.841797 9.33496 1.09912 9.63379L5.39062 14.9048C5.71436 15.2949 6.07129 15.4692 6.54443 15.4692Z"
        fill={color ?? theme.colors.accent}
      />
    </Svg>
  );
};
