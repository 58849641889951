import { Image } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { LoanScreenHeader } from '../../LoanApplication/components/LoanScreenHeader';
import { StyledText } from '../../ui/atoms/StyledText';

type Props = {
  imageHeight: number;
};

export function DeclinedByConductRules({ imageHeight }: Props) {
  return (
    <>
      <LoanScreenHeader
        title={t(
          'Content.ConditionalApproval.Header.DeclinedByConductRulesTitle',
        )}
      />
      <StyledText textAlign="center" mt="s">
        {t('Content.ConditionalApproval.Header.DeclinedByConductRulesCaption')}
      </StyledText>

      <Image
        source={require('../../../assets/hero-image-652x421.png')}
        sx={{ width: '100%', height: imageHeight }}
        resizeMode="contain"
        testID={TestID.LoanApplicationConditionalApproval.Illustration}
      />
    </>
  );
}
