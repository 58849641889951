import { useNavigation, useRoute } from '@react-navigation/native';
import { A, Text } from 'dripsy';
import { useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { SingleModalStackRoute } from '../../navigation/SingleModalNavigator';
import { Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useLoanApplication } from '../utils/useLoanApplication';

export function PrivacyCollectionStatementModal() {
  const navigation = useNavigation();
  const route =
    useRoute<
      SingleModalStackRoute<Screen.PRIVACY_COLLECTION_STATEMENT_MODAL>
    >();

  const { loading, loanApplication } = useLoanApplication(
    route.params?.loanApplicationId,
  );

  const { flags } = useContext(FeatureFlagsContext);
  const enableLmi = loanApplication?.is_lmi_enabled ?? flags.ENABLE_LMI;
  const lmiPrivacyPolicyInLine = enableLmi
    ? t('Content.Common.Disclaimer.PrivacyPolicyLmiInLine')
    : '';

  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      hideBackButton
      headerText={t(
        'Content.LoanApplicationSplash.PrivacyCollectionStatement.Title',
      )}
    >
      {loading ? (
        <Spinner size="large" />
      ) : (
        <Text>
          {t('Content.Common.Disclaimer.PrivacyPolicy', {
            lmiPrivacyPolicyInLine,
          })}
          <A
            href={t('Link.PrivacyPolicy')}
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            variant="link"
          >
            {t('Content.Common.Disclaimer.PrivacyPolicyInlineLink')}
          </A>
        </Text>
      )}
    </ModalScreenContainer>
  );
}
