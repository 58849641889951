import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export const YourFinancialsIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => {
  const idPrefix = useSvgIdPrefix('YourFinancialsIcon');
  return (
    <Svg width={45} height={45} viewBox="0 0 45 45" fill="none" {...props}>
      <Path
        d="M41.25 6.5625H40.3125C40.3125 7.08 39.8925 7.5 39.375 7.5C38.8575 7.5 38.4375 7.08 38.4375 6.5625H36.5625C36.5625 7.08 36.1425 7.5 35.625 7.5C35.1075 7.5 34.6875 7.08 34.6875 6.5625H32.8125C32.8125 7.08 32.3925 7.5 31.875 7.5C31.3575 7.5 30.9375 7.08 30.9375 6.5625H29.0625C29.0625 7.08 28.6425 7.5 28.125 7.5C27.6075 7.5 27.1875 7.08 27.1875 6.5625H25.3125C25.3125 7.08 24.8925 7.5 24.375 7.5C23.8575 7.5 23.4375 7.08 23.4375 6.5625H21.5625C21.5625 7.08 21.1425 7.5 20.625 7.5C20.1075 7.5 19.6875 7.08 19.6875 6.5625H17.8125C17.8125 7.08 17.3925 7.5 16.875 7.5C16.3575 7.5 15.9375 7.08 15.9375 6.5625H14.0625C14.0625 7.08 13.6425 7.5 13.125 7.5C12.6075 7.5 12.1875 7.08 12.1875 6.5625H10.3125C10.3125 7.08 9.8925 7.5 9.375 7.5C8.8575 7.5 8.4375 7.08 8.4375 6.5625H6.5625C6.5625 7.08 6.1425 7.5 5.625 7.5C5.1075 7.5 4.6875 7.08 4.6875 6.5625H3.75C3.2325 6.5625 2.8125 6.9825 2.8125 7.5V37.5C2.8125 38.5359 3.65156 38.4375 4.6875 38.4375C4.6875 38.4375 39.2766 38.4375 40.3125 38.4375C41.3484 38.4375 42.1875 38.5359 42.1875 37.5V7.5C42.1875 6.9825 41.7675 6.5625 41.25 6.5625Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M22.0312 15.9375H7.96875C7.71 15.9375 7.5 15.7275 7.5 15.4688V14.5312C7.5 14.2725 7.71 14.0625 7.96875 14.0625H22.0312C22.29 14.0625 22.5 14.2725 22.5 14.5312V15.4688C22.5 15.7275 22.29 15.9375 22.0312 15.9375Z"
        fill="#2C2C2C"
      />
      <Path
        d="M19.2188 19.6875H7.96875C7.71 19.6875 7.5 19.4775 7.5 19.2188V18.2812C7.5 18.0225 7.71 17.8125 7.96875 17.8125H19.2188C19.4775 17.8125 19.6875 18.0225 19.6875 18.2812V19.2188C19.6875 19.4775 19.4775 19.6875 19.2188 19.6875Z"
        fill="#2C2C2C"
      />
      <Path
        d="M38.2246 26.8941C38.2246 21.5935 30.3984 22.6285 30.3984 19.4963C30.3984 17.8613 32.2359 17.775 32.8115 17.775C34.1925 17.775 35.5265 18.1454 36.5428 18.6225C36.8634 18.7725 37.2309 18.5504 37.2309 18.1969V16.6547C37.2309 16.1372 37.0359 15.8307 36.7256 15.7097C36.0281 15.4388 35.0278 15.241 33.5475 15.1922V13.4944C33.5475 13.291 33.3825 13.126 33.179 13.126H32.4421C32.2387 13.126 32.0737 13.291 32.0737 13.4944V15.2194C29.7787 15.4435 27.3975 16.7391 27.3975 19.7541C27.3975 25.2488 35.2228 24.1594 35.2228 27.0535C35.2228 27.9629 34.6415 28.7475 32.8096 28.7475C30.9684 28.7475 29.2762 28.1044 28.3396 27.6253C28.0246 27.4641 27.6534 27.6975 27.6534 28.0519V29.8078C27.6534 30.1444 27.8559 30.4444 28.1662 30.5757C29.2762 31.0472 30.764 31.4035 32.0728 31.5085V33.3844C32.0728 33.5879 32.2378 33.7528 32.4412 33.7528H33.1781C33.3815 33.7528 33.5465 33.5879 33.5465 33.3844V31.5094C36.9834 31.2235 38.2246 28.9238 38.2246 26.8941Z"
        fill="#2C2C2C"
      />
      <Path
        d="M13.125 33.75C12.6825 33.75 12.2944 33.5428 12.0591 33.1828C11.9007 32.9391 11.82 32.6363 11.82 32.2828C11.82 31.6688 12.1472 30.5981 12.6675 29.5275C12.5241 28.5834 12.2532 27.5737 11.8228 26.8434C11.5472 26.3737 11.3278 26.25 11.25 26.25C10.2722 26.25 9.54097 28.305 9.35722 29.2425C9.25785 29.7506 8.77128 30.0806 8.2566 29.9822C7.74847 29.8828 7.4166 29.3897 7.51691 28.8816C7.60785 28.4212 8.47972 24.375 11.25 24.375C12.6582 24.375 13.5535 25.7587 14.0738 27.3506C14.6569 26.6934 15.3 26.25 15.9375 26.25C17.806 26.25 18.42 28.4175 18.8691 30.0009C18.885 30.0553 18.901 30.1125 18.9178 30.1706C18.9582 30.0909 18.9966 30.0141 19.0313 29.9438C19.6294 28.7391 20.3072 27.375 21.5625 27.375C22.081 27.375 22.5 27.795 22.5 28.3125C22.5 28.8038 22.1222 29.2078 21.6403 29.2472C21.3778 29.4347 20.9457 30.3038 20.7103 30.7781C20.1685 31.8703 19.6997 32.8125 18.75 32.8125C17.7169 32.8125 17.415 31.7466 17.0653 30.5128C16.8807 29.8594 16.3885 28.125 15.9375 28.125C15.6338 28.2422 15.0844 29.0128 14.6035 29.8763C14.6513 30.3075 14.6728 30.7041 14.6728 31.0369C14.6728 31.6669 14.6082 32.1909 14.4788 32.595C14.1469 33.6375 13.4213 33.75 13.125 33.75Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={2.8125}
          y1={6.5625}
          x2={33.9918}
          y2={45.0751}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
