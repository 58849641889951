import { Text, View } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';
import { Platform } from 'react-native';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { Screen } from '../../navigation/types/screens';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { SettingsScreenProps } from '../navigation/types';

type Props = SettingsScreenProps<Screen.SETTINGS_SECURITY>;

const Security: React.FC<Props> = ({ navigation }) => {
  const navigateToManageFaceIdScreen = useCallback(() => {
    navigation.navigate(Screen.SETTINGS_SECURITY_FACE_ID);
  }, [navigation]);
  return (
    <UpliftScreenContainer onPressBack={navigation.goBack}>
      <View sx={{ mt: '$16' }}>
        <Text variant="header" sx={{ mb: '$16' }}>
          {t('Content.Settings.Security.Title')}
        </Text>
        <Text variant="body" sx={{ mt: '$8', mb: '$16' }}>
          {t('Content.Settings.Security.Body')}
        </Text>

        <DataRowGroup mt={0}>
          <DataRow
            label={
              Platform.OS === 'ios'
                ? t('Content.Settings.Security.FaceId.Entry')
                : t('Content.Settings.Security.Biometrics.Entry')
            }
            onPress={navigateToManageFaceIdScreen}
            testID={TestID.Settings.SecurityRow}
          />
        </DataRowGroup>
      </View>
    </UpliftScreenContainer>
  );
};

export const WithAuthenticationSecurity = withAuthenticationRequired(Security);
