import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const UnloanIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 25.999 16.86" {...props}>
    <Path
      d="M-1043.844-410.239a2.224 2.224 0 01-.961-.4 2.066 2.066 0 01-.629-.795 3.9 3.9 0 01-.2-1.358v-7.817a1.77 1.77 0 00-.6-1.49 2.274 2.274 0 00-1.558-.5h-.242a2.277 2.277 0 00-1.558.5 1.716 1.716 0 00-.594 1.38v4.383a5.49 5.49 0 01-2.021 4.538 8.879 8.879 0 01-5.766 1.656h-.242a8.879 8.879 0 01-5.766-1.656 5.49 5.49 0 01-2.021-4.538V-426.7a7.672 7.672 0 011.06-.2 10.25 10.25 0 011.458-.1 8.065 8.065 0 011.326.1 2.224 2.224 0 01.961.4 2.051 2.051 0 01.629.795 3.908 3.908 0 01.2 1.358v7.817a1.77 1.77 0 00.6 1.49 2.275 2.275 0 001.558.5h.242a2.277 2.277 0 001.558-.5 1.7 1.7 0 00.594-1.355v-4.408a5.49 5.49 0 012.021-4.538 8.879 8.879 0 015.765-1.659h.242a8.879 8.879 0 015.766 1.656 5.49 5.49 0 012.021 4.538v10.368a7.674 7.674 0 01-1.06.2 10.385 10.385 0 01-1.458.1 8.168 8.168 0 01-1.325-.101z"
      transform="translate(1066 427)"
      fill={color}
    />
  </Svg>
);
