import { A, SxProp, Text, useDripsyTheme, View } from 'dripsy';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMEvent,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  GetDefaultProductRateForSplashScreenQuery,
  Loan_Application_Type_Enum,
  LoanAppForGtmEventFragment,
  Property_Purpose_Enum,
  useGetDefaultProductRateForSplashScreenQuery,
  useGetLoanApplicationInitialDataQuery,
} from '../../generated/graphql';
import { useEnableNativeAppMonitor } from '../../Home/utils/useEnableNativeAppMonitor';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Button } from '../../ui/atoms/Button';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { RadioGroup } from '../../ui/v2/RadioGroup';
import { Sup } from '../../ui/v2/Text/Sup';
import { deleteCookie, getCookie } from '../../utils/cookies';
import { parseEnumType } from '../../utils/ensureEnumType';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { useReferralDisclosure } from '../../utils/hooks/useReferralDisclosure';
import { useSendLoanAppEventToGTM } from '../../utils/hooks/useSendDataToGTM';
import { isWeb } from '../../utils/platformUtils';
import { makeTestId } from '../../utils/stringHelpers';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanTypeOptionCard } from '../components/LoanTypeOptionCard';
import { PropertyPurposeOptionCard } from '../components/PropertyPurposeOptionCard';
import {
  getNextScreenNameForLoanApplicationWizard,
  useNavigateToLoanApplicationScreen,
} from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import {
  LoanApplicationV2NavigationProps,
  LoanApplicationV2ScreenProps,
} from '../navigation/types';
import { useCreateLoanApplication } from '../utils/useCreateLoanApplication';

const MAX_DISCLAIMER_WIDTH = 1176;
const DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS = {
  rel: 'noreferrer',
  target: '_blank',
} as const;
// Sorted enum values for options in splash screen
// Not fetching options from backend to avoid unnecessary query
const selectablePropertyPurposeOptions = [
  {
    value: Property_Purpose_Enum.LivingIn,
    label: t('Content.PropertyPurpose.LIVING_IN'),
  },
  {
    value: Property_Purpose_Enum.Investing,
    label: t('Content.PropertyPurpose.INVESTING'),
  },
];

function getProductRateByPropertyPurpose(
  productRates:
    | GetDefaultProductRateForSplashScreenQuery['product_rate']
    | undefined,
  propertyPurpose: Property_Purpose_Enum,
) {
  return productRates?.find(
    (rate) => rate.property_purpose === propertyPurpose,
  );
}

const handleCreateLoanApplication = async ({
  createLoanApplication,
  loanType,
  navigation,
  propertyPurpose,
  sendLoanAppEventToGTM,
  ldTrack,
}: {
  createLoanApplication: (
    loanApplicationType: Loan_Application_Type_Enum,
    initialPropertyPurpose?: Property_Purpose_Enum | undefined,
  ) => Promise<{
    loanApplicationId: string | undefined;
    loanApplication: LoanAppForGtmEventFragment | null | undefined;
  }>;
  navigation: LoanApplicationV2NavigationProps<Screen.LOAN_APPLICATION_V2_SPLASH>;
  loanType: Loan_Application_Type_Enum;
  propertyPurpose?: Property_Purpose_Enum;
  sendLoanAppEventToGTM: (params: {
    loanApplicationDetails: LoanAppForGtmEventFragment;
    event: GTMEvent.LoanEvent;
  }) => void;
  ldTrack?: (event: string) => void;
}) => {
  try {
    const { loanApplicationId, loanApplication } = await createLoanApplication(
      loanType,
      propertyPurpose,
    );

    if (loanApplication != null) {
      sendLoanAppEventToGTM({
        event: GTMEvent.LoanEvent,
        loanApplicationDetails: loanApplication,
      });
      ldTrack?.('loan-application-created');
    }

    // After creating the loan, update the params rather than a refetch
    navigation.setParams({
      loanApplicationId,
    });
    /**
     * We're using `getNextScreenNameForLoanApplicationWizard` explicitly here instead of
     * `navigateToNextLoanApplicationModal` because we want to navigate to the next screen based on the created loan application ID and type.
     */
    const nextScreenName = getNextScreenNameForLoanApplicationWizard(
      loanType,
      LoanApplicationSection.Splash,
      // When we pass `nextScreenName` to `navigation.navigate`, TypeScript throws an error even though it is a valid LoanApplicationScreen name.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;
    navigation.navigate(nextScreenName, {
      loanApplicationId,
    });
  } catch (error: unknown) {
    captureException(
      t('Content.Common.Error.FailCreateLoanApplication'),
      null,
      error,
    );
  }
};

const readPreSelectionFromCookies = (
  cookieDomain: string,
): {
  loanType:
    | Loan_Application_Type_Enum.Purchase
    | Loan_Application_Type_Enum.Refinance
    | undefined;
  propertyPurpose: Property_Purpose_Enum | undefined;
} => {
  // Note: This cookie is set by other apps like Unloan-Web's get started flow.
  const getStartedCookie = getCookie('get-started-selections');
  if (getStartedCookie) {
    try {
      const { loanType, propertyPurpose } = JSON.parse(getStartedCookie);
      const validLoanTypes = [
        Loan_Application_Type_Enum.Purchase,
        Loan_Application_Type_Enum.Refinance,
      ];
      const validPropertyPurposes = [
        Property_Purpose_Enum.Investing,
        Property_Purpose_Enum.LivingIn,
      ];
      if (
        validLoanTypes.includes(loanType) &&
        validPropertyPurposes.includes(propertyPurpose)
      ) {
        return { loanType, propertyPurpose };
      }

      throw Error(
        `Successfully parsed cookie, but unexpected values received. loanType: "${loanType}", propertyPurpose: "${propertyPurpose}"`,
      );
    } catch (error) {
      captureException(
        'Failed to pre-select loan application values from hand-over cookie',
        null,
        error,
      );

      return {
        loanType: undefined,
        propertyPurpose: undefined,
      };
    } finally {
      deleteCookie('get-started-selections', '/', cookieDomain);
    }
  }
  return {
    loanType: undefined,
    propertyPurpose: undefined,
  };
};

const productFeatures = [
  t('Content.LoanApplicationSplash.ProductFeatures.PrincipalAndInterest'),
  t('Content.LoanApplicationSplash.ProductFeatures.NoFees'),
  t('Content.LoanApplicationSplash.ProductFeatures.Redraw'),
  t('Content.LoanApplicationSplash.ProductFeatures.Discounts'),
  t('Content.LoanApplicationSplash.ProductFeatures.AdditionalRepayments'),
];

/**
 * New features has LMI and the no Unloan fees is replaced
 * with no Unloan application fees because LMI premium is considered a fee.
 */
const productFeaturesWithLmi = [
  t('Content.LoanApplicationSplash.ProductFeatures.PrincipalAndInterest'),
  t('Content.LoanApplicationSplash.ProductFeatures.NoApplicationFees'),
  t('Content.LoanApplicationSplash.ProductFeatures.Redraw'),
  t('Content.LoanApplicationSplash.ProductFeatures.Discounts'),
  t('Content.LoanApplicationSplash.ProductFeatures.AdditionalRepayments'),
  <>
    {t(
      'Content.LoanApplicationSplash.ProductFeatures.LendersMortgageInsurance',
    )}
    <Sup>†</Sup>
  </>,
];

function getProductFeatures(
  loanApplicationType: Loan_Application_Type_Enum | null,
  lmiFeatureEnabled: boolean,
) {
  if (!loanApplicationType) {
    return productFeatures;
  }

  switch (loanApplicationType) {
    case Loan_Application_Type_Enum.Purchase:
      if (!lmiFeatureEnabled) {
        return productFeatures;
      }
      return productFeaturesWithLmi;
    default:
      return productFeatures;
  }
}

const UnselectedPropertyPurposeError = ({ sx }: { sx: SxProp }) => (
  <View
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '$2',
      paddingTop: '$4',
    }}
  >
    <StyledIcon name="info" color="error" family="svg" size="s" />
    <Text
      sx={{
        ...sx,
        color: '$error',
      }}
    >
      {t('Content.LoanApplicationSplash.UnselectedPropertyPurpose')}
    </Text>
  </View>
);

type Props = LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_SPLASH>;

function LoanApplicationSplashCardLayoutBase({ navigation, route }: Props) {
  const sendLoanAppEventToGTM = useSendLoanAppEventToGTM();
  const { flags, track: ldTrack } = useContext(FeatureFlagsContext);

  const { params } = route;
  const {
    loanApplicationId,
    redirectedFromNativeApp,
    _initialSelectedLoanApplicationType,
  } = params || {};
  const { theme } = useDripsyTheme();
  const loanApplicationIdFromScreenParam = loanApplicationId;
  const loanAlreadyExist = loanApplicationIdFromScreenParam != null;
  const [selectedLoanApplicationType, setSelectedLoanApplicationType] =
    useState<Loan_Application_Type_Enum | null>(
      _initialSelectedLoanApplicationType ?? null,
    );

  const [unselectedLoanPurposeError, setUnselectedLoanPurposeError] =
    useState(false);

  const [selectedPropertyPurpose, setSelectedPropertyPurpose] =
    useState<Property_Purpose_Enum | null>(null);
  const shouldShowReferralDisclosure = useReferralDisclosure();
  useEnableNativeAppMonitor(redirectedFromNativeApp);

  const {
    data: initialLoanApplication,
    loading: loadingInitialLoanApplicationData,
    error: initialLoanApplicationError,
    refetch: refetchInitialLoanApplication,
  } = useGetLoanApplicationInitialDataQuery({
    skip: !loanApplicationIdFromScreenParam,
    variables: { loanApplicationId: loanApplicationIdFromScreenParam || '' },
    context: {
      sentryContext: {
        loanApplicationId: loanApplicationIdFromScreenParam,
      },
    },
    onCompleted: (data) => {
      setSelectedLoanApplicationType(data.loan_application_by_pk?.type ?? null);
      setSelectedPropertyPurpose(
        data.loan_application_by_pk?.initial_property_purpose ?? null,
      );
    },
  });

  const [
    createLoanApplication,
    { loading: isCreatingLoanApplication, error: createLoanApplicationError },
  ] = useCreateLoanApplication();

  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(
      navigation,
      route,
      loanApplicationIdFromScreenParam,
    );

  const {
    data: getProductRateData,
    loading: loadingGetProductRate,
    error: getProductRateError,
    refetch: refetchProductRate,
  } = useGetDefaultProductRateForSplashScreenQuery({
    context: {
      sentryContext: {
        loanApplicationId: loanApplicationIdFromScreenParam,
      },
    },
  });

  const isLoadingScreenData =
    loadingInitialLoanApplicationData || loadingGetProductRate;

  const [attemptingCookieHandover, setAttemptingCookieHandover] =
    useState<boolean>(false);

  const { config } = useEnvConfig();

  const createLoanApplicationFromCookies = useCallback(async () => {
    if (isWeb && !loanAlreadyExist && !isCreatingLoanApplication) {
      const { loanType, propertyPurpose } = readPreSelectionFromCookies(
        config.handoverCookieDomain,
      );
      if (loanType && propertyPurpose) {
        setAttemptingCookieHandover(true);
        await handleCreateLoanApplication({
          createLoanApplication,
          loanType,
          navigation,
          propertyPurpose,
          sendLoanAppEventToGTM,
          ldTrack,
        });
        setAttemptingCookieHandover(false);
      }
    }
  }, [
    createLoanApplication,
    isCreatingLoanApplication,
    loanAlreadyExist,
    navigation,
    sendLoanAppEventToGTM,
    config,
    ldTrack,
  ]);

  useEffect(() => {
    if (!attemptingCookieHandover) {
      createLoanApplicationFromCookies();
    }
  }, [attemptingCookieHandover, createLoanApplicationFromCookies]);

  const continueLoanApplication = useCallback(
    async (input: {
      loanType: Loan_Application_Type_Enum;
      propertyPurpose?: Property_Purpose_Enum;
    }) => {
      const { loanType, propertyPurpose } = input;
      if (loanAlreadyExist) {
        // Continue if loan already exist
        navigateToNextLoanApplicationScreen({
          currentSection: LoanApplicationSection.Splash,
        });
        return;
      }

      await handleCreateLoanApplication({
        createLoanApplication,
        loanType,
        navigation,
        propertyPurpose,
        sendLoanAppEventToGTM,
        ldTrack,
      });
    },
    [
      createLoanApplication,
      loanAlreadyExist,
      navigateToNextLoanApplicationScreen,
      navigation,
      sendLoanAppEventToGTM,
      ldTrack,
    ],
  );

  const onContinuePress = useCallback(() => {
    if (!selectedLoanApplicationType || !selectedPropertyPurpose) {
      setUnselectedLoanPurposeError(true);
      return;
    }

    continueLoanApplication({
      loanType: selectedLoanApplicationType,
      propertyPurpose: selectedPropertyPurpose,
    });
  }, [
    continueLoanApplication,
    selectedLoanApplicationType,
    selectedPropertyPurpose,
  ]);

  const isLmiEnabled =
    initialLoanApplication?.loan_application_by_pk?.is_lmi_enabled ??
    flags.ENABLE_LMI;

  const selectableLoanApplicationTypeOptions = useMemo(
    () => [
      {
        value: Loan_Application_Type_Enum.Purchase,
        label: t('Content.LoanApplicationSplash.BuyAHome'),
        subtitle: isLmiEnabled
          ? t('Content.LoanApplicationSplash.BuyAHomeSubtitleV2')
          : t('Content.LoanApplicationSplash.BuyAHomeSubtitle'),
      },
      {
        value: Loan_Application_Type_Enum.Refinance,
        label: t('Content.LoanApplicationSplash.Refinancing'),
        subtitle: t('Content.LoanApplicationSplash.RefinancingSubtitle'),
      },
    ],
    [isLmiEnabled],
  );

  if (isLoadingScreenData || attemptingCookieHandover) {
    return (
      <LoanApplicationScreenContainer>
        <ScreenLoadingContainer loading />
      </LoanApplicationScreenContainer>
    );
  }

  // These are off-theme typography required to match the designs
  const customTypography = {
    caption: {
      fontWeight: '400',
      fontSize: 12,
      color: '$secondary',
      lineHeight: 16,
      letterSpacing: '-0.03em',
    },
    pageHeading: {
      fontWeight: [
        theme.text.heading2.fontWeight,
        theme.text.heading3.fontWeight,
      ],
      fontSize: [theme.text.heading2.fontSize, theme.text.heading3.fontSize],
      lineHeight: [
        theme.text.heading2.lineHeight,
        theme.text.heading3.lineHeight,
      ],
    },
    groupHeading: {
      fontWeight: [
        theme.text.subHeading1.fontWeight,
        theme.text.heading4.fontWeight,
      ],
      fontSize: [theme.text.subHeading1.fontSize, theme.text.heading4.fontSize],
      lineHeight: [
        theme.text.subHeading1.lineHeight,
        theme.text.heading4.lineHeight,
      ],
      letterSpacing: [
        theme.text.subHeading1.letterSpacing,
        theme.text.heading4.letterSpacing,
      ],
    },
  };

  const lmiDisclaimer =
    isLmiEnabled &&
    selectedLoanApplicationType === Loan_Application_Type_Enum.Purchase ? (
      <>
        <Sup>†</Sup>
        {t('Content.LoanApplicationSplash.LmiDisclaimerPart1')}
        <A
          variant="link"
          href={t('Link.LmiBenefits')}
          hrefAttrs={DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS}
        >
          {`${t('Content.LoanApplicationSplash.LmiDisclaimerPart2')}`}
        </A>
        {t('Content.LoanApplicationSplash.LmiDisclaimerPart3')}
        <A
          variant="link"
          href={t('Link.ReferenceRates')}
          hrefAttrs={DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS}
        >
          {`${t('Content.LoanApplicationSplash.LmiDisclaimerPart4')}`}
        </A>
        {t('Content.LoanApplicationSplash.LmiDisclaimerPart5')}
      </>
    ) : null;

  return (
    <View
      testID={TestID.LoanApplicationSplash.ScreenContainer}
      sx={{
        overflow: 'visible',
        alignItems: 'stretch',
        maxWidth: '100%',
        bg: 'bg',
        marginX: '$16',
      }}
    >
      <NavHeaderSpacer />
      <ScreenErrorFallback
        error={initialLoanApplicationError || getProductRateError}
        displayMessage={t('Content.Common.Error.FailFetchLoanApplication')}
        refetch={async () => {
          await Promise.all([
            refetchInitialLoanApplication(),
            refetchProductRate(),
          ]);
        }}
      >
        <View
          testID={TestID.LoanApplicationScreenContainer.ContentVariant3}
          sx={{
            ...theme.sizes.contentContainer,
            justifyContent: 'center',
            alignSelf: 'center',
            overflow: 'visible',
            marginBottom: '$40',
            display: 'flex',
            gap: '$24',
          }}
        >
          <Text
            sx={{
              ...customTypography.pageHeading,
              textAlign: 'center',
              mx: ['$8', '$16'],
              my: ['$16', '$24'],
            }}
          >
            {t('Content.LoanApplicationSplash.LetsGetStarted')}
          </Text>
          <ErrorRow
            m="m"
            message={
              createLoanApplicationError
                ? t('Content.Common.Error.FailCreateLoanApplication')
                : null
            }
          />
          <View
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: ['$16', '$24'],
            }}
          >
            <Text
              sx={{
                ...customTypography.groupHeading,
                textAlign: 'center',
                my: '$8',
              }}
            >
              {t('Content.LoanApplicationSplash.WhatAreYouLookingToDo')}
            </Text>
            <RadioGroup
              value={String(selectedLoanApplicationType)}
              disabled={loanAlreadyExist}
              onValueSelected={(value) =>
                setSelectedLoanApplicationType(
                  parseEnumType(Loan_Application_Type_Enum, value),
                )
              }
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Splash,
                Screen.LOAN_APPLICATION_V2_SPLASH,
                FieldInteractionKey.WhatAreYouLookingToDo,
              )}
            >
              <View
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'noWrap',
                  gap: '$16',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}
              >
                {selectableLoanApplicationTypeOptions.map(
                  ({ value, label, subtitle }) => (
                    <LoanTypeOptionCard
                      key={value}
                      testID={makeTestId([
                        TestID.LoanApplicationSplash.LoanApplicationTypeOption,
                        value,
                      ])}
                      title={label}
                      subtitle={subtitle}
                      optionValue={value}
                    />
                  ),
                )}
              </View>
            </RadioGroup>
          </View>
          {selectedLoanApplicationType ? (
            <>
              <View
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: ['$16', '$24'],
                }}
              >
                <Text
                  sx={{
                    ...customTypography.groupHeading,
                    textAlign: 'center',
                    my: '$8',
                  }}
                >
                  {t(
                    'Content.LoanApplicationSplash.WhatIsThePurposeOfYourLoan',
                  )}
                </Text>
                <View>
                  <RadioGroup
                    value={String(selectedPropertyPurpose)}
                    onValueSelected={(value) => {
                      setSelectedPropertyPurpose(
                        parseEnumType(Property_Purpose_Enum, value),
                      );
                      setUnselectedLoanPurposeError(false);
                    }}
                    disabled={loanAlreadyExist}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.Splash,
                      Screen.LOAN_APPLICATION_V2_SPLASH,
                      FieldInteractionKey.WhatsThePurposeOfYourLoan,
                    )}
                  >
                    <View
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '$16',
                        width: '100%',
                        height: 'fit-content',
                      }}
                    >
                      {selectablePropertyPurposeOptions.map(
                        ({ value, label }, index) => {
                          const productRate = getProductRateByPropertyPurpose(
                            getProductRateData?.product_rate,
                            value,
                          );
                          return (
                            <PropertyPurposeOptionCard
                              key={`${selectedLoanApplicationType}-${value}`}
                              title={label}
                              optionValue={value}
                              variableRate={productRate?.interest_rate ?? 0}
                              comparisonRate={productRate?.comparison_rate ?? 0}
                              features={getProductFeatures(
                                selectedLoanApplicationType,
                                isLmiEnabled,
                              )}
                              showLmiFeature={
                                selectedLoanApplicationType ===
                                  Loan_Application_Type_Enum.Purchase &&
                                isLmiEnabled
                              }
                              cardIndex={index}
                            />
                          );
                        },
                      )}
                    </View>
                  </RadioGroup>
                  {unselectedLoanPurposeError ? (
                    <UnselectedPropertyPurposeError
                      sx={customTypography.caption}
                    />
                  ) : null}
                </View>
              </View>
              <View
                sx={{
                  maxWidth: 458,
                  alignItems: 'center',
                  alignSelf: 'center',
                  my: 's',
                  display: 'flex',
                  gap: '$16',
                }}
              >
                <View
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '$4',
                    textAlign: 'center',
                  }}
                >
                  {shouldShowReferralDisclosure ? (
                    <Text
                      sx={{
                        ...customTypography.caption,
                        textAlign: 'center',
                      }}
                    >
                      {t('Content.Common.Disclaimer.ReferralDisclosure')}{' '}
                      <A
                        href={t('Link.ReferralArticle')}
                        hrefAttrs={DEFAULT_HREF_ATTRS_FOR_EXTERNAL_LINKS}
                        variant="link"
                        sx={{ py: '$8' }}
                      >
                        {t(
                          'Content.Common.Disclaimer.ReferralDisclosureInlineLink',
                        )}
                        {'\n'}
                      </A>
                    </Text>
                  ) : null}
                  <Text
                    sx={{
                      ...customTypography.caption,
                      textAlign: 'center',
                    }}
                  >
                    {t('Content.Common.Disclaimer.PrivacyPolicyForSplash')}
                    <Text
                      sx={{
                        ...customTypography.caption,
                        color: '$link',
                      }}
                      onPress={() => {
                        navigation.navigate(Screen.SINGLE_V2_MODAL, {
                          screen: Screen.PRIVACY_COLLECTION_STATEMENT_MODAL,
                          params: {
                            loanApplicationId,
                          },
                        });
                      }}
                    >
                      {t(
                        'Content.Common.Disclaimer.PrivacyPolicyForSplashInlineLink',
                      )}
                    </Text>
                  </Text>
                </View>
                <Button
                  py="m"
                  onPress={onContinuePress}
                  width="100%"
                  label={t('Content.Common.ButtonLabel.Continue')}
                  disabled={
                    isCreatingLoanApplication ||
                    loadingInitialLoanApplicationData
                  }
                  showSpinner={isCreatingLoanApplication}
                  testID={TestID.LoanApplicationSplash.ContinueButton}
                />
              </View>
            </>
          ) : null}
        </View>
      </ScreenErrorFallback>
      <Text
        sx={{
          ...customTypography.caption,
          px: ['$16', '$40'],
          py: '$40',
          maxWidth: MAX_DISCLAIMER_WIDTH,
          alignSelf: 'center',
        }}
      >
        {t('Content.LoanApplicationSplash.DisclaimerPart1')}
        {lmiDisclaimer}
        {t('Content.LoanApplicationSplash.DisclaimerPart2')}
      </Text>
    </View>
  );
}

export const LoanApplicationSplashCardLayout = withAuthenticationRequired(
  LoanApplicationSplashCardLayoutBase,
);
