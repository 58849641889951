import { EmptyState } from '../../ui/organisms/EmptyState';
import { LoanValidationResult } from '../utils/loanApplicationUtils';
import { NoLoanApplicationFound } from './NoLoanApplicationFound';

export function InvalidLoanApplication({
  validationResult,
}: {
  validationResult: Exclude<LoanValidationResult, LoanValidationResult.Valid>;
}) {
  if (
    validationResult === LoanValidationResult.NotFound ||
    validationResult === LoanValidationResult.LoanApplicationTypeMismatch
  ) {
    return <NoLoanApplicationFound />;
  }

  return (
    <EmptyState
      title={t('Content.Common.ErrorTitle')}
      description={t(
        'Content.Common.Error.UnableToDisplayLoanApplicationDetails',
      )}
    />
  );
}
