import { Card, CardContent, CardProps, Text, theme, View } from '@unloan/ui';
import { ReactNode } from 'react';

import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { SvgIcon } from '../../ui/svgs/SvgIcon';
import { SVG_ICON_LIST } from '../../ui/svgs/svgIconList';

type Props = Pick<CardProps, 'sx' | 'onPress' | 'testID'> & {
  icon: keyof typeof SVG_ICON_LIST;
  title: string;
  caption: string;
  additionalInfo?: ReactNode;
};

export function InfoButton(props: Props) {
  const { title, icon, caption, additionalInfo, sx, ...otherProps } = props;
  return (
    <Card sx={({ space }) => ({ p: space.$16, ...sx })} {...otherProps}>
      <CardContent
        sx={({ space }) => ({ flex: 1, flexDirection: 'row', gap: space.$12 })}
      >
        <SvgIcon name={icon} size={theme.sizes.$36} />

        <View sx={({ space }) => ({ flex: 1, gap: space.$4 })}>
          <Text size="heading5" weight="semiBold">
            {title}
          </Text>
          <Text size="body3">{caption}</Text>
          {additionalInfo != null ? (
            <Text size="body3" variant="secondary">
              <StyledIcon
                name="info"
                family="svg"
                size="tiny"
                style={{
                  position: 'relative',
                  top: 2,
                  marginRight: theme.space.$4,
                  color: theme.colors.$neutral.$800,
                }}
              />
              {additionalInfo}
            </Text>
          ) : null}
        </View>
      </CardContent>
    </Card>
  );
}
