import { ViewProps } from 'react-native';

import { TestID } from '../../testID/constants';
import { Button, Props as ButtonProps } from '../ui/atoms/Button';
import { useTheme } from '../ui/theme';

type Props = ButtonProps & ViewProps;

export function ProfileButton(props: Props) {
  const theme = useTheme();
  return (
    <Button
      icon="profileFilled"
      variant="circle"
      iconFamily="svg"
      iconSize="m"
      backgroundColor="transparent"
      width={theme.sizes['2m']}
      height={theme.sizes['2m']}
      testID={TestID.ProfileButton}
      aria-label={t('Content.Accessibility.TopNavBar.ProfileButtonLabel')}
      {...props}
    />
  );
}
