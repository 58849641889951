import { gql } from '@apollo/client';

import {
  Income_Verification_State_Enum,
  useIncomeVerificationStateSubscription,
} from '../../generated/graphql';
import useTimeout from '../../utils/hooks/useTimeout';

const INCOME_VERIFICATION_SUBSCRIPTION_TIMEOUT = 30e3;

export const IncomeVerificationState = gql`
  subscription IncomeVerificationState($incomeVerificationId: uuid!) {
    income_verification_by_pk(id: $incomeVerificationId) {
      id
      state
      status
      detectedEmploymentIncomes: detected_incomes(
        where: { income_type: { _eq: EMPLOYMENT } }
      ) {
        income_type
        id
      }
      detectedEmploymentIncomeSummaries: calculated_detected_employment_incomes {
        id
        annual_salary
      }
    }
  }
`;

export function useCompletedIncomeVerification({
  validIncomeVerificationId,
  subscriptionTimeout = INCOME_VERIFICATION_SUBSCRIPTION_TIMEOUT,
}: {
  validIncomeVerificationId: string | null;
  subscriptionTimeout?: number;
}) {
  const { hasTimedout } = useTimeout(subscriptionTimeout);

  const skipSubscription = !validIncomeVerificationId;
  const {
    data,
    loading: incomeVerificationStateLoading,
    error,
  } = useIncomeVerificationStateSubscription({
    variables: { incomeVerificationId: validIncomeVerificationId || '' },
    skip: skipSubscription,
  });

  const hasIncomeVerificationFinished =
    data?.income_verification_by_pk?.state ===
      Income_Verification_State_Enum.Completed ||
    data?.income_verification_by_pk?.state ===
      Income_Verification_State_Enum.Failed;

  const detectedEmploymentIncomes =
    data?.income_verification_by_pk?.detectedEmploymentIncomes;
  const detectedEmploymentIncomeSummaries =
    data?.income_verification_by_pk?.detectedEmploymentIncomeSummaries;
  const hasIncomeVerificationCalculationFinished =
    detectedEmploymentIncomeSummaries?.length ===
    detectedEmploymentIncomes?.length;

  const waitingForIncomeVerificationToBeFinished = skipSubscription
    ? false
    : !hasIncomeVerificationFinished ||
      !hasIncomeVerificationCalculationFinished;

  const incomeVerificationLoading = hasTimedout
    ? false
    : incomeVerificationStateLoading ||
      waitingForIncomeVerificationToBeFinished;

  return {
    incomeVerificationData: data,
    incomeVerificationLoading,
    error,
  };
}
