import { isLast } from '@unloan/common-ui';
import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Loan_Application_Task_Type } from '../../generated/graphql';
import { captureException } from '../../sentry';
import { makeTestId } from '../../utils/stringHelpers';
import {
  LoanApplicationTaskData,
  LoanApplicationTaskItem,
  LoanApplicationTaskItemProps,
} from './LoanApplicationTaskItem';

/**
 * Avoid spreading the props to avoid passing
 * in unwanted props to the child component
 */
type CommonLoanApplicationTaskItemProp = Omit<
  LoanApplicationTaskItemProps,
  'taskData' | 'isLast'
>;

type LoanApplicationTaskListProps = {
  title: string;
  tasks: Array<LoanApplicationTaskData>;
} & CommonLoanApplicationTaskItemProp;

/**
 * Component to render the rows on each section
 */
function LoanApplicationTaskList({
  title,
  tasks,
  loanApplicationId,
  enableEvidenceScreenUplift,
  currentLoggedInApplicantIDVLoading,
  parentScreenName,
}: LoanApplicationTaskListProps) {
  if (!tasks || tasks.length === 0) {
    return null;
  }
  return (
    <>
      <Text
        variant="sHeader"
        sx={{ my: '$16' }}
        testID={`${
          TestID.LoanApplicationTaskItem.LoanApplicationTaskHeader
        }-${makeTestId([title])}`}
      >
        {title}
      </Text>
      {tasks.map((task, index) => (
        <LoanApplicationTaskItem
          loanApplicationId={loanApplicationId}
          enableEvidenceScreenUplift={enableEvidenceScreenUplift}
          currentLoggedInApplicantIDVLoading={
            currentLoggedInApplicantIDVLoading
          }
          taskData={task}
          key={task.id}
          isLast={isLast(tasks, index)}
          parentScreenName={parentScreenName}
        />
      ))}
    </>
  );
}

const loanApplicationTaskSectionOrder: Loan_Application_Task_Type[] = [
  Loan_Application_Task_Type.Idv,
  Loan_Application_Task_Type.IncomeSupportingDocument,
  Loan_Application_Task_Type.SecuritySupportingDocument,
  Loan_Application_Task_Type.AccountClosureSupportingDocument,
  Loan_Application_Task_Type.ReducedLimitSupportingDocument,
  Loan_Application_Task_Type.RefinancingSupportingDocument,
];

function getLoanApplicationTaskSectionTitle(
  taskType: Loan_Application_Task_Type,
) {
  switch (taskType) {
    case Loan_Application_Task_Type.Idv:
      return t(`Content.LoanApplicationTasks.TaskItemSectionHeader.IDV`);
    case Loan_Application_Task_Type.IncomeSupportingDocument:
      return t(
        `Content.LoanApplicationTasks.TaskItemSectionHeader.INCOME_SUPPORTING_DOCUMENT`,
      );
    case Loan_Application_Task_Type.SecuritySupportingDocument:
      return t(
        `Content.LoanApplicationTasks.TaskItemSectionHeader.SECURITY_SUPPORTING_DOCUMENT`,
      );
    case Loan_Application_Task_Type.AccountClosureSupportingDocument:
      return t(
        `Content.LoanApplicationTasks.TaskItemSectionHeader.ACCOUNT_CLOSURE_SUPPORTING_DOCUMENT`,
      );
    case Loan_Application_Task_Type.ReducedLimitSupportingDocument:
      return t(
        `Content.LoanApplicationTasks.TaskItemSectionHeader.REDUCED_LIMIT_SUPPORTING_DOCUMENT`,
      );
    case Loan_Application_Task_Type.RefinancingSupportingDocument:
      return t(
        `Content.LoanApplicationTasks.TaskItemSectionHeader.REFINANCING_SUPPORTING_DOCUMENT`,
      );
    default:
      // Not using assertUnreachable here to avoid blocking customers
      return '';
  }
}

type LoanApplicationTaskSectionsProps = {
  allTasks: Array<LoanApplicationTaskData>;
} & CommonLoanApplicationTaskItemProp;

/**
 * Component to render each task separated by task type
 */
export function LoanApplicationTaskSections(
  props: LoanApplicationTaskSectionsProps,
) {
  const {
    allTasks,
    loanApplicationId,
    currentLoggedInApplicantIDVLoading,
    enableEvidenceScreenUplift,
    parentScreenName,
  } = props;

  // Capture unhandled loan application task types
  const otherTasks = allTasks?.filter(
    (task) => !Object.values(Loan_Application_Task_Type).includes(task.type),
  );
  if (otherTasks && otherTasks.length > 0) {
    captureException('Unexpected loan application task type', {
      loanApplicationId,
      loanApplicationTasks: otherTasks.map((task) => ({
        type: task.type,
        status: task.status,
        incomeId: task.income_id || null,
        mergedLiabilityId: task.merged_liability_id || null,
        loanApplicationSecurityId: task.loan_application_security_id || null,
        applicantId: task.applicant_id || null,
      })),
    });
  }

  return loanApplicationTaskSectionOrder.map((taskType) => (
    <LoanApplicationTaskList
      loanApplicationId={loanApplicationId}
      currentLoggedInApplicantIDVLoading={currentLoggedInApplicantIDVLoading}
      enableEvidenceScreenUplift={enableEvidenceScreenUplift}
      key={taskType}
      title={getLoanApplicationTaskSectionTitle(taskType)}
      tasks={allTasks?.filter((task) => task.type === taskType)}
      parentScreenName={parentScreenName}
    />
  ));
}
