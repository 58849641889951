import { Text } from 'dripsy';
import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Asserts } from 'yup';

import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import {
  FormCheckboxInputV2,
  FormMultilineTextInputV2,
  FormRadioInputV2,
} from '../../components/form/FormikInputs';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  FinancialDeclarationFragment,
  Retirement_Repayment_Plan_Enum,
  Retirement_Repayment_Plan_Input,
  Retirement_Repayment_Plan_Input_Enum,
  useUpsertFinancialSituationAndRetirementPlanMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { Button } from '../../ui/atoms/Button';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { FormikFormError } from '../../ui/v2/FormError';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { parseEnumType } from '../../utils/ensureEnumType';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useFinancialDeclarationQuery } from '../data/useFinancialDeclarationQuery';
import {
  FinancialDeclarationFormFieldsV2,
  FinancialDeclarationWithRetirementPlanV2ValidationSchema,
  FinancialDeclarationYesNoEnum,
  getFinancialDeclarationSchemaV2,
} from './utils/setupFinancialDeclarationForm';

export type Props =
  SingleModalStackScreenProps<Screen.FINANCIAL_DECLARATION_MODAL_V2>;

export type FinancialDeclarationFormValuesV2 = Partial<
  Asserts<typeof FinancialDeclarationWithRetirementPlanV2ValidationSchema>
>;
function maybeBooleanToYesNo(
  boolValue: boolean | null | undefined,
): FinancialDeclarationYesNoEnum | undefined {
  if (boolValue == null) {
    return undefined;
  }

  return boolValue
    ? FinancialDeclarationYesNoEnum.Yes
    : FinancialDeclarationYesNoEnum.No;
}

function getInitialValues(
  financialDeclaration: FinancialDeclarationFragment | undefined,
): FinancialDeclarationFormValuesV2 {
  if (financialDeclaration == null) {
    return {
      [FinancialDeclarationFormFieldsV2.MaintainFinancialSituation]: undefined,
      [FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans]: undefined,
      [FinancialDeclarationFormFieldsV2.OtherRetirementRepaymentPlan]:
        undefined,
    };
  }

  const otherReasonDescription =
    financialDeclaration.retirement_repayment_plans.filter(
      ({ retirement_repayment_plan }) =>
        retirement_repayment_plan === Retirement_Repayment_Plan_Enum.Other,
    )?.[0]?.other_retirement_repayment_plan_description;

  return {
    [FinancialDeclarationFormFieldsV2.MaintainFinancialSituation]:
      maybeBooleanToYesNo(
        financialDeclaration.maintain_current_financial_situation,
      ),
    [FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans]:
      financialDeclaration.retirement_repayment_plans.map(
        ({ retirement_repayment_plan }) => retirement_repayment_plan,
      ) || undefined,
    [FinancialDeclarationFormFieldsV2.OtherRetirementRepaymentPlan]:
      otherReasonDescription || undefined,
  };
}

function OtherRepaymentPlanField({
  retirementRepaymentPlans,
  name,
}: Pick<
  FinancialDeclarationFormValuesV2,
  FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans
> & { name: FinancialDeclarationFormFieldsV2 }) {
  const retirementRepaymentPlanSet = new Set(retirementRepaymentPlans);
  if (
    !retirementRepaymentPlanSet.has(Retirement_Repayment_Plan_Input_Enum.Other)
  ) {
    return null;
  }
  return (
    <FormMultilineTextInputV2
      label=""
      name={name}
      sx={{ py: '$8' }}
      placeholder={t('Content.FinancialDeclaration.ExplainOtherPayOffMethod')}
      interactionKey={buildApplicationInteractionEventKey(
        SectionInteractionKey.YourUnloan,
        Screen.FINANCIAL_DECLARATION_MODAL_V2,
        FieldInteractionKey.FinancialDeclarationExplainOtherPayOffMethod,
      )}
    />
  );
}

export function FinancialDeclarationBasic({ navigation, route }: Props) {
  const { loanApplicationId } = route.params || {};

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const onModalClose = useCallback(
    () =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.YourUnloan,
      }),
    [navigateToLoanApplicationScreen],
  );

  const [
    upsertFinancialSituationAndRetirement,
    { loading: upsertFinancialSituationAndRetirementLoading },
  ] = useUpsertFinancialSituationAndRetirementPlanMutation();

  const [formErrorMessage, setFormErrorMessage] = useState<string | null>();

  const {
    maintainCurrentFinancialOptions,
    loading,
    retirementRepaymentPlanOptions,
    isRetiredBeforeEndOfLoan,
    financialDeclaration,
  } = useFinancialDeclarationQuery(loanApplicationId);

  const initialValues = getInitialValues(financialDeclaration);

  if (!loanApplicationId) {
    return (
      <ModalScreenContainer scrollable hideBackButton onClose={onModalClose}>
        <EmptyState
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }

  if (loading) {
    return (
      <ModalScreenContainer scrollable hideBackButton onClose={onModalClose}>
        <ScreenLoadingContainer loading />
      </ModalScreenContainer>
    );
  }

  const onSubmit = async ({
    maintainFinancialSituation,
    retirementRepaymentPlans,
    otherRetirementRepaymentPlan,
  }: FinancialDeclarationFormValuesV2) => {
    if (maintainFinancialSituation == null) {
      setFormErrorMessage(
        t(
          'Content.FinancialDeclaration.Error.MissingMaintainCurrentFinancialSituation',
        ),
      );
      return;
    }

    const repaymentPlansInput: Array<Retirement_Repayment_Plan_Input> = [];

    if (isRetiredBeforeEndOfLoan) {
      retirementRepaymentPlans?.forEach(
        (plan: Retirement_Repayment_Plan_Input_Enum) => {
          const repaymentPlan = parseEnumType(
            Retirement_Repayment_Plan_Input_Enum,
            plan,
          );
          if (!repaymentPlan) {
            captureException(
              'Unable to handle new enum of retirement repayment plan while choosing retirement repayment plans - useSetRetirementRepaymentPlansMutation',
              { repaymentPlan },
            );
            return false;
          }
          repaymentPlansInput.push({
            retirement_repayment_plan: repaymentPlan,
            other_retirement_repayment_plan_description:
              plan === Retirement_Repayment_Plan_Input_Enum.Other
                ? otherRetirementRepaymentPlan
                : undefined,
          });
          return true;
        },
      );
      if (retirementRepaymentPlans?.length !== repaymentPlansInput.length) {
        setFormErrorMessage(t('Content.Common.Error.FailSubmit'));
        return;
      }
    }
    const [res] = await safelyCallMutation(
      upsertFinancialSituationAndRetirement,
      {
        variables: {
          data: {
            loan_application_id: loanApplicationId,
            maintain_current_financial_situation:
              maintainFinancialSituation === FinancialDeclarationYesNoEnum.Yes,
            retirement_repayment_plans: repaymentPlansInput,
          },
        },
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      },
    );

    const data = res?.data?.upsert_financial_situation_and_retirement_plan;

    if (data == null) {
      setFormErrorMessage(
        t(
          'Content.FinancialDeclaration.Error.UnableToStoreFinancialDeclaration',
        ),
      );
      return;
    }

    if (
      isRetiredBeforeEndOfLoan &&
      financialDeclaration?.retirement_repayment_plan_confirmed_at == null
    ) {
      navigation.navigate(ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2, {
        loanApplicationId,
      });
      return;
    }

    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.ReviewSummary,
    });
  };

  return (
    <ModalScreenContainer
      scrollable
      hideBackButton
      onClose={onModalClose}
      headerText={t('Content.FinancialDeclaration.Header')}
      loading={upsertFinancialSituationAndRetirementLoading}
    >
      <ErrorRow message={formErrorMessage} my="s" />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={getFinancialDeclarationSchemaV2(
          isRetiredBeforeEndOfLoan,
        )}
      >
        {(props) => (
          <>
            <Text sx={{ my: '$16', variant: 'text.sHeader' }}>
              {t(
                'Content.FinancialDeclaration.MaintainCurrentFinancialSituationHeader',
              )}
            </Text>

            {maintainCurrentFinancialOptions.map((financialOption) => (
              <FormRadioInputV2
                name={
                  FinancialDeclarationFormFieldsV2.MaintainFinancialSituation
                }
                key={financialOption.label}
                label={financialOption.label}
                containerStyle={{ mb: '$8' }}
                value={
                  financialOption.value
                    ? FinancialDeclarationYesNoEnum.Yes
                    : FinancialDeclarationYesNoEnum.No
                }
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.YourUnloan,
                  Screen.FINANCIAL_DECLARATION_MODAL_V2,
                  FieldInteractionKey.FinancialDeclarationExpectedChanges,
                )}
              />
            ))}
            <FormikFormError
              name={FinancialDeclarationFormFieldsV2.MaintainFinancialSituation}
              sx={{ my: '$8' }}
            />

            {isRetiredBeforeEndOfLoan ? (
              <>
                <Text sx={{ my: '$16', variant: 'text.sHeader' }}>
                  {t(
                    'Content.FinancialDeclaration.RetirementRepaymentPlanHeaderV2',
                  )}
                </Text>

                {retirementRepaymentPlanOptions.map((option) => (
                  <FormCheckboxInputV2
                    name={
                      FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans
                    }
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    subtitle={option.caption}
                    containerStyle={{ mb: '$8' }}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.YourUnloan,
                      Screen.FINANCIAL_DECLARATION_MODAL_V2,
                      FieldInteractionKey.FinancialDeclarationRetirement,
                    )}
                  />
                ))}
                <FormikFormError
                  name={
                    FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans
                  }
                  sx={{ my: '$8' }}
                />

                <OtherRepaymentPlanField
                  retirementRepaymentPlans={
                    props.values[
                      FinancialDeclarationFormFieldsV2.RetirementRepaymentPlans
                    ]
                  }
                  name={
                    FinancialDeclarationFormFieldsV2.OtherRetirementRepaymentPlan
                  }
                />
                <FormikFormError
                  name={
                    FinancialDeclarationFormFieldsV2.OtherRetirementRepaymentPlan
                  }
                  sx={{ my: '$8' }}
                />
              </>
            ) : null}

            <Button
              py="m"
              mt="m"
              onPress={() => props.handleSubmit()}
              width="100%"
              label={t('Content.Common.ButtonLabel.Done')}
              disabled={loading || upsertFinancialSituationAndRetirementLoading}
              showSpinner={
                loading || upsertFinancialSituationAndRetirementLoading
              }
            />
          </>
        )}
      </Formik>
    </ModalScreenContainer>
  );
}
