import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function RefinancingSupportingDocumentIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={36} height={37} fill="none" {...props}>
      <Path
        fill="url(#a)"
        fillRule="evenodd"
        d="M5.09 17V33.497c0 .76.557.756 1.28.75H30.382c.723.006 1.28.01 1.28-.75V16.999L18.376 4.25 5.09 17Z"
        clipRule="evenodd"
      />
      <Path
        fill="#2C2C2C"
        d="M18.375 5c-.35 0-.7.126-.979.377L13.44 8.985 5.09 17v4.042l13.286-11.97L31.66 21.04V17l-8.352-8.014-3.955-3.608a1.46 1.46 0 0 0-.98-.377Z"
        opacity={0.05}
      />
      <Path
        fill="#2C2C2C"
        d="M18.375 4.25c-.35 0-.7.125-.979.375L13.44 8.213 5.09 16.18V20.2L18.375 8.297 31.66 20.2V16.18l-8.352-7.968-3.955-3.588a1.464 1.464 0 0 0-.98-.375Z"
        opacity={0.07}
      />
      <Path
        fill="#A3A3A3"
        d="M33.503 16.63 19.353 3.876a1.46 1.46 0 0 0-1.957 0L3.247 16.63a.757.757 0 0 0-.047 1.073l1.208 1.308a.73.73 0 0 0 1.026.05L18.375 7.57l12.942 11.491a.73.73 0 0 0 1.026-.05l1.207-1.308a.758.758 0 0 0-.047-1.074Z"
      />
      <Path
        fill="#2C2C2C"
        d="M13.981 23.84c-.896 0-1.618-.296-2.166-.888-.547-.593-.821-1.39-.821-2.39 0-1.094.291-1.961.874-2.602.583-.64 1.36-.961 2.333-.961.932 0 1.66.29 2.184.87.524.58.787 1.395.787 2.445 0 1.08-.29 1.939-.87 2.574-.581.634-1.354.952-2.32.952Zm9.02-6.658a.1.1 0 0 1 .085.153l-8.082 12.933a.1.1 0 0 1-.085.047H13.01a.1.1 0 0 1-.084-.153l8.065-12.933a.1.1 0 0 1 .085-.047H23Zm-8.853 1.428c-.856 0-1.283.62-1.283 1.86 0 1.172.407 1.758 1.221 1.758.832 0 1.248-.61 1.248-1.832 0-1.19-.395-1.786-1.186-1.786Zm7.68 11.888c-.896 0-1.618-.296-2.166-.888-.548-.592-.821-1.39-.821-2.39 0-1.093.29-1.96.874-2.601.583-.642 1.36-.962 2.333-.962.937 0 1.667.287 2.188.861.521.574.782 1.386.782 2.436 0 1.081-.29 1.942-.87 2.584-.58.64-1.353.96-2.32.96Zm.14-5.23c-.849 0-1.273.62-1.273 1.86 0 1.172.413 1.759 1.239 1.759.832 0 1.247-.61 1.247-1.832 0-.568-.11-1.008-.333-1.32-.223-.311-.516-.466-.88-.466Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={5.09}
          x2={34.87}
          y1={4.25}
          y2={30.628}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
