import { useContext } from 'react';

import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import {
  ROW_LABEL_BY_INSTITUTION_ACCOUNT_TYPE,
  useAllInstitutionAccountsQuery,
} from '../../ConnectedBanks/remoteData';
import { formatInstitutionAccountCaption } from '../../ConnectedBanks/utils/formatInstitutionAccountCaption';
import { getInstitutionConnectionName } from '../../ConnectedBanks/utils/getInstitutionConnectionName';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { ContainerInput } from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Link } from '../../ui/atoms/Link';
import {
  ScreenContainer,
  UpliftScreenContainer,
} from '../../ui/atoms/ScreenContainer';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { isLast } from '../../utils/arrayHelpers';
import { SettingsScreenProps } from '../navigation/types';

type Props = SettingsScreenProps<Screen.SETTINGS_BANK_PROFILE>;

function SettingsBankProfileBase({ navigation, route }: Props) {
  const { institutionConnectionId } = route.params;

  const normalizedInstitutionConnectionId =
    typeof institutionConnectionId === 'string'
      ? parseInt(institutionConnectionId, 10)
      : institutionConnectionId;

  const { institutionAccounts, connectedInstitutionDetails, error, loading } =
    useAllInstitutionAccountsQuery(normalizedInstitutionConnectionId);

  const { accountHolders, institutionFavIcon, institutionName } =
    connectedInstitutionDetails;

  const { flags } = useContext(FeatureFlagsContext);
  const enableOpenBankingDataRecipient =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT;

  if (!enableOpenBankingDataRecipient) {
    return <NotFoundScreen />;
  }

  if (error) {
    return (
      <ScreenContainer pt="m" safeAreaBottom={false} bg="bg">
        <EmptyState
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchInstitutionAccounts')}
        />
      </ScreenContainer>
    );
  }

  return (
    <UpliftScreenContainer
      onPressBack={navigation.goBack}
      safeAreaBottom={false}
    >
      <HomeHeader title={t('Content.ConnectedBanks.BankAccountDetailsTitle')} />
      <ListRowGroup removeTopSeparator mx={0}>
        {loading ? (
          <Skeleton width="100%" />
        ) : (
          <ListRow
            label={institutionName ?? ''}
            caption={getInstitutionConnectionName(accountHolders)}
            imageUrl={institutionFavIcon ?? ''}
            borderedImage
            last
          />
        )}
      </ListRowGroup>
      <Box bg="bg" pt="m">
        <ListRowGroup
          headerText={t('Content.ConnectedBanks.ConnectedAccounts')}
          mx={0}
        >
          {error ? (
            <StyledText mx="m" mt="s" variant="caption">
              {t('Content.Common.Error.FailFetchInstitutionAccountsOBPoll')}
            </StyledText>
          ) : null}
          <Skeleton width="100%" show={loading}>
            <>
              {institutionAccounts.map(
                (
                  {
                    id,
                    accountType,
                    container,
                    totalCreditLine,
                    balance,
                    accountNumber,
                  },
                  index,
                ) => {
                  const last = isLast(institutionAccounts, index);
                  const label =
                    ROW_LABEL_BY_INSTITUTION_ACCOUNT_TYPE[
                      accountType as keyof typeof ROW_LABEL_BY_INSTITUTION_ACCOUNT_TYPE
                    ] || accountType;

                  /**
                   * For every account that belongs to creditCard container, should display the `totalCreditLine` (limit)
                   * For every account that belongs to loan, should display the `balance`
                   */
                  const isCredit = container === ContainerInput.CreditCard;
                  const displayBalance = isCredit
                    ? totalCreditLine?.amount
                    : balance?.amount;

                  const caption = formatInstitutionAccountCaption(
                    accountNumber,
                    isCredit,
                    displayBalance,
                  );
                  return (
                    <ListRow
                      key={id}
                      label={label}
                      caption={caption}
                      last={last}
                    />
                  );
                },
              )}
            </>
          </Skeleton>
        </ListRowGroup>
      </Box>
      <Box mt="m" pb="s">
        <StyledText mx="m" mt="s" variant="caption">
          {t('Content.ConnectedBanks.Footer')}
          <Link
            variant="caption"
            href={t('Link.UnloanConsumerDataRightPolicy')}
          >
            {t('Content.ConnectedBanks.FooterLink')}
          </Link>
        </StyledText>
      </Box>
    </UpliftScreenContainer>
  );
}

export const WithAuthenticationSettingsBankProfile = withAuthenticationRequired(
  SettingsBankProfileBase,
);
