import { Text, View } from 'dripsy';
import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { ActionSheet } from '../../ActionSheet/components/ActionSheet';
import { ActionSheetScreenProps } from '../../ActionSheet/navigation/types';
import {
  Account_Permission,
  useCreateAccountPermissionChangeRequestMutation,
} from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { PermissionsToUpdate } from '../types';
import { AccountPermissionsShowErrorAtomFamily } from '../utils/atoms';
import { getMessageToDisplay } from '../utils/disclaimerText';

export type Props =
  ActionSheetScreenProps<ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES>;

const getPermissionsToUpdate = (
  permissions: PermissionsToUpdate,
): Account_Permission | Account_Permission[] => permissions;

export const AccountPermissionsConfirmChangesActionSheet = ({
  navigation,
  route,
}: Props) => {
  // Route params
  const { cbaAccountId } = route.params;
  const { coBorrowerName } = route.params;
  const permissions = route.params.permissionsToUpdate;

  const ShowErrorAtom = AccountPermissionsShowErrorAtomFamily(cbaAccountId);
  const setShowError = useSetRecoilState(ShowErrorAtom);

  const [createChangeRequest, { loading }] =
    useCreateAccountPermissionChangeRequestMutation();

  const message = useMemo(
    () => getMessageToDisplay(permissions),
    [permissions],
  );

  const isAnyPermissionsOneToApprove = useMemo(
    () => permissions.some((p) => !p.require_approval),
    [permissions],
  );

  const subText = useMemo(() => {
    const areAllPermissionsOneToApprove = permissions.every(
      (p) => !p.require_approval,
    );
    const textPrefix = 'Content.AccountPermissions.ConfirmChanges.SubText';
    return areAllPermissionsOneToApprove
      ? t(`${textPrefix}.EmailConfirmationOnApprove`)
      : t(`${textPrefix}.EmailConfirmationOnSubmit`);
  }, [permissions]);

  const submitRequest = useCallback(async () => {
    const [_, error] = await safelyCallMutation(createChangeRequest, {
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Create Account Permission Change Request',
        },
      },
      variables: {
        cbaAccountId,
        permissions: getPermissionsToUpdate(permissions),
      },
    });

    // On error it should go back to the Account Permissions Page
    if (error) {
      // Display the error on the page using atom
      setShowError(true);
      navigation.goBack();
    } else {
      // On success it should display the success message and go back to the Account Permissions Page
      const textPrefix = 'Content.AccountPermissions.ConfirmChanges';
      navigation.replace(
        ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED,
        {
          cbaAccountId,
          message: isAnyPermissionsOneToApprove
            ? t(`${textPrefix}.RequestSubmitted`)
            : t(`${textPrefix}.PermissionsUpdated`),
          supportingText: isAnyPermissionsOneToApprove
            ? t(`${textPrefix}.CoBorrowerApprovalRequired`, {
                coBorrower: coBorrowerName,
              })
            : undefined,
        },
      );
    }
  }, [
    cbaAccountId,
    createChangeRequest,
    permissions,
    navigation,
    setShowError,
    isAnyPermissionsOneToApprove,
    coBorrowerName,
  ]);

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      showCloseButton={false}
      isRowButton
      title={t('Content.AccountPermissions.ConfirmChanges.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          disabled: loading,
          testID: TestID.AccountPermissions.ConfirmChanges.CancelButton,
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          disabled: loading,
          showSpinner: loading,
          testID: TestID.AccountPermissions.ConfirmChanges.ConfirmButton,
          onPress: submitRequest,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <View sx={{ py: '$8', px: '$16', mb: '$8' }}>
        <Text sx={{ textAlign: 'center' }}>{message}</Text>
        <Text sx={{ mt: '$8', textAlign: 'center' }}>{subText}</Text>
      </View>
    </ActionSheet>
  );
};
