import { ReactNode } from 'react';

import { useAndroidInstallPwaEvent } from '../../../Home/navigation/utils/useAndroidInstallPwaEvent';
import {
  isIosPwaInstalled,
  isIPhoneSafari,
  useIsAndroidPwaInstalled,
} from '../../../utils/platformUtils';

type InstallPwaRulesProps = {
  children: ReactNode;
};

export function InstallPwaRules({
  children,
}: InstallPwaRulesProps): JSX.Element | null {
  const androidInstallPwaEvent = useAndroidInstallPwaEvent();

  const isAndroidPwaInstalled = useIsAndroidPwaInstalled();

  if (!isIPhoneSafari() && !androidInstallPwaEvent) {
    return null;
  }

  if (isIosPwaInstalled() || isAndroidPwaInstalled) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
