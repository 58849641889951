import { LiabilityPanel } from '../../components/LiabilityPanel';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { Props as RowProps, Row } from '../../ui/molecules/Row';
import { AppMergedLiability } from '../utils/useAllLiabilitiesQuery';

type Props = Omit<RowProps, 'label'> & {
  liabilities: Array<AppMergedLiability>;
  forV2?: boolean;
};

function JoinedLiabilityList({ liabilities, forV2 }: Props) {
  if (!liabilities.length) {
    return (
      <Row
        centered
        label="No Debts or Liabilities"
        labelFontSize="xs"
        labelColor="secondaryContent"
        last
      />
    );
  }

  return (
    <>
      {liabilities.map((aml) => (
        <ListRowGroup
          key={aml.id}
          spacer={false}
          {...(forV2 ? { mx: 0 } : null)}
        >
          <LiabilityPanel
            name="joinedLiabilityItem"
            institutionName={aml.dynamite_institution_name || ''}
            liabilityType={aml.liabilityType}
            accountNumber={aml.dynamite_account_number}
            forV2={forV2}
          />
        </ListRowGroup>
      ))}
    </>
  );
}

export default JoinedLiabilityList;
