import { ManualPropertyFieldNames } from '../v2/ManualPropertyInputFields';
import { ManualSuburbFieldNames } from '../v2/ManualSuburbInputFields';

function isOtherValuesMadeThisFieldRequired(
  currentValues: Array<unknown> = [],
  checkFunctions: Array<(currentValue: unknown) => boolean> = [],
) {
  if (checkFunctions.length < 1) {
    return true;
  }
  return currentValues.every((currentValue, index) =>
    checkFunctions[index]?.(currentValue),
  );
}

export function checkIsRequiredBasedOnIsManualInputAndDependencyFieldNames(
  dependencyCheckFunctions: Array<(currentValue: unknown) => boolean> = [],
) {
  return (isManualInput: boolean, ...otherValues: Array<unknown>) =>
    isManualInput &&
    isOtherValuesMadeThisFieldRequired(otherValues, dependencyCheckFunctions);
}
type ManualAddressFieldsNames =
  | ManualPropertyFieldNames
  | ManualSuburbFieldNames;

export function computeManualAddressInputFieldName<
  T extends string,
  U extends ManualAddressFieldsNames,
>(prefix: T, field: U) {
  return `${prefix}-${field}` as `${T}-${U}`;
}
