import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { TestID } from '../../../testID/constants';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const GrayBallIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    testID={TestID.Icon.GrayBallIcon}
    width={resizeIconWidth(10, 10, size)}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    {...props}
  >
    <Path
      d="M4.94602 10.1477C4.27509 10.1477 3.64418 10.0215 3.05327 9.76918C2.46544 9.51681 1.94685 9.1675 1.49751 8.72124C1.05125 8.2719 0.700402 7.75331 0.444957 7.16548C0.19259 6.57765 0.0679451 5.94673 0.0710228 5.27273C0.0741004 4.59872 0.201823 3.9678 0.45419 3.37997C0.709635 2.79214 1.06049 2.27509 1.50675 1.82884C1.95301 1.3795 2.47005 1.02865 3.05788 0.776278C3.64572 0.523911 4.27509 0.397727 4.94602 0.397727C5.62003 0.397727 6.25095 0.523911 6.83878 0.776278C7.42969 1.02865 7.94673 1.3795 8.38992 1.82884C8.83617 2.27509 9.18549 2.79214 9.43786 3.37997C9.69022 3.9678 9.81795 4.59872 9.82102 5.27273C9.8241 5.94673 9.69946 6.57765 9.44709 7.16548C9.19472 7.75331 8.84541 8.2719 8.39915 8.72124C7.95289 9.1675 7.4343 9.51681 6.84339 9.76918C6.25249 10.0215 5.62003 10.1477 4.94602 10.1477Z"
      fill="#E0E0E1"
    />
  </Svg>
);
