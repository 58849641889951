import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import { useDebouncedEffect } from './useDebouncedEffect';
import { useSendDataToGTM } from './useSendDataToGTM';

const DEBOUNCE_WAIT_IN_MS = 1000;

export const useDebouncedGtmDataLayer = (
  description: GTMAppInteractionEventDescription,
  interactionKey: ApplicationInteractionEventKeyType,
  dependency: unknown,
  additionalData: Record<string, unknown>,
  delay: number = DEBOUNCE_WAIT_IN_MS,
) => {
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  useDebouncedEffect(
    () =>
      sendAppInteractionEventToGTM({
        description,
        additionalData: {
          application_interaction_event_key: interactionKey,
          ...additionalData,
        },
      }),
    [dependency],
    delay,
  );
};
