import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';

export type Props =
  SingleModalStackScreenProps<Screen.VERIFY_YOUR_IDENTITY_LEARN_MORE_V2_MODAL>;
export function VerifyingYourIdentityLearnMoreModalV2({
  navigation,
  route,
}: Props) {
  const navigateToManualIdentityVerification = () => {
    navigation.navigate(
      Screen.MANUAL_VERIFICATION_V2_MODAL,
      route.params ?? {},
    );
  };

  return (
    <ModalScreenContainer
      onClose={navigation.goBack}
      headerText={t('Consent.VerifyingYourIdentity.Title')}
      scrollable
      hideBackButton
    >
      <Text>
        {t('Consent.VerifyingYourIdentity.VerifyingYourIdentityContent')}
      </Text>
      <Text variant="caption" sx={{ mt: '$24' }}>
        {t('Consent.VerifyingYourIdentity.ManualKYC')}
        <Text
          variants={['caption', 'link']}
          onPress={navigateToManualIdentityVerification}
          testID={TestID.VerifyingYourIdentityLearnMoreModal.LearnMoreButton}
        >
          {` ${t('Content.Common.ButtonLabel.LearnMore')}`}
        </Text>
      </Text>
    </ModalScreenContainer>
  );
}
