import { useCallback, useState } from 'react';

import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { AutopayGetStartedV2 } from '../components/AutopayGetStartedV2';
import { BaseAutopaySetupV2 } from '../components/BaseAutopaySetupV2';
import { useAutopayMinimumRepaymentAmount } from '../hooks/useAutopayMinimumRepaymentAmount';
import { useAutopaySetupActions } from '../hooks/useAutopaySetupActions';
import { useAutopaySetupData } from '../hooks/useAutopaySetupData';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { AutopayChangeRequestFormV2, AutoPaySetupFormField } from '../types';

const initialValues: AutopayChangeRequestFormV2 = {
  [AutoPaySetupFormField.RepaymentFrequency]:
    Autopay_Frequency_Input_Enum.Monthly,
  [AutoPaySetupFormField.RepaymentAmount]: null,
  [AutoPaySetupFormField.IsCustomRepaymentAmount]: false,
  [AutoPaySetupFormField.ExternalAccountId]: '',
  [AutoPaySetupFormField.PaymentStartDate]: null,
  [AutoPaySetupFormField.IsManualAccountInput]: false,
  [AutoPaySetupFormField.DirectDebitAccountName]: '',
  [AutoPaySetupFormField.DirectDebitAccountBsb]: '',
  [AutoPaySetupFormField.DirectDebitAccountNumber]: '',
};

export type AutopaySetupV2Props =
  AutopaySettingsScreenProps<Screen.AUTOPAY_SETUP>;

export function AutopaySetupV2({ navigation, route }: AutopaySetupV2Props) {
  const { cbaAccountId, _initialGetStartedVisited = false } = route.params;

  const {
    loading,
    externalAccounts,
    maximumDirectDebitAmount,
    unloanAccountName,
    unloanBsb,
    unloanAccountNumber,
    nextInstallment,
    availableRedrawBalance,
  } = useAutopaySetupData(cbaAccountId);

  const {
    minimumRepaymentAmountsByFrequency,
    refetchMonthlyMinimumRepaymentAmount,
    monthlyMinimumRepaymentAmountError,
    isLoadingMonthlyMinimumRepaymentAmount,
  } = useAutopayMinimumRepaymentAmount(cbaAccountId);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const {
    onContinuePress,
    onConfirmPress,
    onBack,
    showConfirmation,
    getEstimatedShortfallAmountLoading,
    confirmAutopayLoading,
    errorMessage,
  } = useAutopaySetupActions({
    navigation,
    cbaAccountId,
    minimumRepaymentAmountsByFrequency,
    monthlyMinimumRepaymentAmountError,
  });

  const [getStartedVisited, setAutopayGetStartedVisited] = useState(
    _initialGetStartedVisited,
  );

  if (!getStartedVisited) {
    return (
      <AutopayGetStartedV2
        onClose={onClose}
        onGetStarted={() => setAutopayGetStartedVisited(true)}
      />
    );
  }

  return (
    <BaseAutopaySetupV2
      screen={Screen.AUTOPAY_SETUP}
      initialValues={initialValues}
      unloanAccountName={unloanAccountName}
      unloanBsb={unloanBsb}
      unloanAccountNumber={unloanAccountNumber}
      minimumRepaymentAmountsByFrequency={minimumRepaymentAmountsByFrequency}
      maximumDirectDebitAmount={maximumDirectDebitAmount}
      externalAccounts={externalAccounts}
      showConfirmation={showConfirmation}
      onClose={onClose}
      onBack={onBack}
      onContinuePress={onContinuePress}
      onConfirmPress={onConfirmPress}
      loading={loading}
      getEstimatedShortfallAmountLoading={getEstimatedShortfallAmountLoading}
      confirmAutopayLoading={confirmAutopayLoading}
      errorMessage={errorMessage}
      monthlyMinimumRepaymentAmountError={monthlyMinimumRepaymentAmountError}
      refetchMonthlyMinimumRepaymentAmount={
        refetchMonthlyMinimumRepaymentAmount
      }
      isLoadingMonthlyMinimumRepaymentAmount={
        isLoadingMonthlyMinimumRepaymentAmount
      }
      nextInstallmentAmount={nextInstallment?.amount}
      nextInstallmentDate={nextInstallment?.next_installment_date}
      availableRedrawBalance={availableRedrawBalance}
    />
  );
}
