import { useCallback } from 'react';

import {
  Autopay_Frequency_Input_Enum,
  GetEstimatedShortfallAmountQueryVariables,
  Shortfall_Calculation_Repayment_Frequency,
  useGetEstimatedShortfallAmountLazyQuery,
} from '../../generated/graphql';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';

const transformToShortfallCalculationRepaymentFrequencyEnum = (
  repaymentFrequency: Autopay_Frequency_Input_Enum,
): Shortfall_Calculation_Repayment_Frequency => {
  switch (repaymentFrequency) {
    case Autopay_Frequency_Input_Enum.Weekly:
      return Shortfall_Calculation_Repayment_Frequency.Weekly;
    case Autopay_Frequency_Input_Enum.Fortnightly:
      return Shortfall_Calculation_Repayment_Frequency.Fortnightly;
    case Autopay_Frequency_Input_Enum.Monthly:
    default:
      return Shortfall_Calculation_Repayment_Frequency.Monthly;
  }
};

type UseGetEstimatedShortfallAmountProps = {
  cbaAccountId: string;
};

export const useGetEstimatedShortfallAmount = ({
  cbaAccountId,
}: UseGetEstimatedShortfallAmountProps) => {
  const [getShortfallAmount, { error, loading }] =
    useGetEstimatedShortfallAmountLazyQuery({
      fetchPolicy: 'network-only', // Forces server-side calculation every time
      context: {
        sentryContext: {
          cbaAccountId,
        },
      },
    });

  const getEstimatedShortfallAmount = useCallback(
    ({
      repayment_frequency,
      ...rest
    }: Omit<
      GetEstimatedShortfallAmountQueryVariables,
      'repayment_frequency'
    > & { repayment_frequency: Autopay_Frequency_Input_Enum }) =>
      safelyCallMutation(getShortfallAmount, {
        variables: {
          ...rest,
          repayment_frequency:
            transformToShortfallCalculationRepaymentFrequencyEnum(
              repayment_frequency,
            ),
        },
        context: {
          sentryContext: {
            cbaAccountId,
          },
        },
      }),
    [cbaAccountId, getShortfallAmount],
  );

  return {
    getEstimatedShortfallAmount,
    loading,
    error,
  };
};
