import { SxProp, Text, View } from 'dripsy';
import { memo, useState } from 'react';
import Animated from 'react-native-reanimated';

import { SettlementProcessRow } from '../../../Settlement/components/SettlementProcessRow';
import { Button } from '../../../ui/atoms/Button';
import { Separator } from '../../../ui/atoms/Separator';
import { useCardHoverShadowStyle } from '../../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../../ui/utils/useCardTranslateYAnimatedStyle';
import { TouchableWithoutFeedback } from '../../../ui/v2/Gesture/TouchableWithoutFeedback';
import { LoanCardChinContainer } from '../LoanCardChinContainer';
import { ApplyLoanCardHeader } from './ApplyLoanCardHeader';

type Props = {
  onPress?: () => void;
  primaryButtonText?: string;
  sx?: SxProp;
};

export const ApplyLoanCard = memo<Props>(
  ({
    onPress,
    primaryButtonText = t('Content.Common.ButtonLabel.GetStarted'),
    sx: sxProps = {},
  }) => {
    const [isHover, setIsHover] = useState(false);

    const shadowProps = useCardHoverShadowStyle({
      isHover,
    });

    const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

    return (
      <TouchableWithoutFeedback
        onPress={onPress}
        onPressIn={() => setIsHover(true)}
        onPressOut={() => setIsHover(false)}
      >
        <Animated.View style={animatedStyle}>
          <View
            accessible
            role="button"
            sx={{
              backgroundColor: '$inputBackground',
              borderRadius: '$card',
              overflow: 'visible',
              cursor: 'unset',
              ...shadowProps,
              ...sxProps,
            }}
          >
            <ApplyLoanCardHeader isHover={isHover} />
            <Separator mx="m" />
            <LoanCardChinContainer
              justifyContent="space-between"
              height={SettlementProcessRow.height}
            >
              <View sx={{ flex: 1 }}>
                <Text variant="xsHeader">
                  {t('Content.Home.ApplyForUnloan')}
                </Text>
                <Text variant="caption">
                  {t('Content.Home.ApplyForUnloanCaption')}
                </Text>
              </View>
              <Button
                label={primaryButtonText}
                variant="pill"
                onPress={onPress}
                alignSelf="center"
                onPressIn={() => setIsHover(true)}
                onPressOut={() => setIsHover(false)}
              />
            </LoanCardChinContainer>
          </View>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  },
);
