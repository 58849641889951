import { Text, View } from 'dripsy';
import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { SubmitButton } from '../../components/form/SubmitButton';
import { WarningRow } from '../../components/WarningRow';
import {
  DATE_FORMAT_DAY_MONTH_YEAR,
  DEFAULT_DATE_DISPLAY_FORMAT,
} from '../../constants/dateFormats';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { ExternalAccount } from '../../HomeLoan/types';
import { formatAutopaySettingsLabel } from '../../HomeLoan/utils/formatAutopaySettingsLabel';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../ui/v2/List';
import { formatCurrency } from '../../utils/currencyHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import {
  AutopayChangeRequestFormV2,
  MinimumRepaymentAmountsByFrequency,
} from '../types';
import { AutopayAgreementText } from './AutopayAgreementText';

export type AutopaySetupV2ConfirmationProps = {
  values: AutopayChangeRequestFormV2;
  externalAccounts: ExternalAccount[];
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  onConfirmPress: () => void;
  confirmLoading?: boolean;
  nextInstallmentAmount?: number;
  nextInstallmentDate?: string;
  availableRedrawBalance?: number;
};

export function AutopaySetupV2Confirmation({
  values,
  externalAccounts,
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  minimumRepaymentAmountsByFrequency,
  onConfirmPress,
  confirmLoading,
  nextInstallmentAmount = 0,
  nextInstallmentDate = '',
  availableRedrawBalance = 0,
}: AutopaySetupV2ConfirmationProps) {
  const {
    repaymentFrequency,
    isCustomRepaymentAmount,
    repaymentAmount,
    externalAccountId,
    isManualAccountInput,
    directDebitAccountName,
    directDebitAccountBsb,
    directDebitAccountNumber,
    paymentStartDate,
  } = values;

  const externalAccount = isManualAccountInput
    ? {
        account_name: directDebitAccountName,
        account_details: formatBsbAndAccountNumber(
          directDebitAccountBsb,
          directDebitAccountNumber,
        ),
      }
    : externalAccounts.find(({ id }) => id === externalAccountId);

  const bsbJoinedWithAccountNumber = formatBsbAndAccountNumber(
    unloanBsb,
    unloanAccountNumber,
  );

  const { amount, frequency } = useMemo(() => {
    if (isCustomRepaymentAmount) {
      return {
        amount: repaymentAmount ?? 0,
        frequency: repaymentFrequency,
      };
    }
    return {
      amount:
        minimumRepaymentAmountsByFrequency[
          repaymentFrequency || Autopay_Frequency_Input_Enum.Monthly
        ],
      frequency: repaymentFrequency,
    };
  }, [
    isCustomRepaymentAmount,
    minimumRepaymentAmountsByFrequency,
    repaymentAmount,
    repaymentFrequency,
  ]);

  const repaymentLabel = formatAutopaySettingsLabel({ amount, frequency });
  const amountCaption = isCustomRepaymentAmount
    ? repaymentLabel
    : t('Content.AutopaySettings.RepaymentMinimum', {
        repayment: repaymentLabel,
      });

  return (
    <>
      {nextInstallmentAmount > availableRedrawBalance ? (
        <WarningRow
          message={t('Content.AutopaySettings.ConfirmationWarning', {
            nextRepaymentAmount: formatCurrency(nextInstallmentAmount),
            nextRepaymentDate: safelyFormatDate(
              nextInstallmentDate,
              DATE_FORMAT_DAY_MONTH_YEAR,
            ),
          })}
          sx={{ mb: '$8' }}
        />
      ) : null}

      <CardList>
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.AutopaySettings.Amount')}
            </Text>
          }
          line={<Text variant="sSubHeading">{amountCaption}</Text>}
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.AutopaySettings.FromAccount')}
            </Text>
          }
          line={
            <View>
              <Text variant="sSubHeading">{externalAccount?.account_name}</Text>
              <Text variant="sSubHeading">
                {externalAccount?.account_details}
              </Text>
            </View>
          }
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.AutopaySettings.ToAccount')}
            </Text>
          }
          line={
            <View>
              <Text variant="sSubHeading">{unloanAccountName}</Text>
              <Text variant="sSubHeading">{bsbJoinedWithAccountNumber}</Text>
            </View>
          }
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.AutopaySettings.Commencing')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {safelyFormatDate(paymentStartDate, DEFAULT_DATE_DISPLAY_FORMAT)}
            </Text>
          }
        />
      </CardList>

      <AutopayAgreementText />

      <SubmitButton
        testID={TestID.AutopaySetup.ConfirmButton}
        label={t('Content.Common.ButtonLabel.Confirm')}
        onPress={onConfirmPress}
        sx={{ mt: '$24' }}
        showSpinner={confirmLoading}
        disabled={confirmLoading}
      />
    </>
  );
}
