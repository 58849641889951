import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Button, Props as ButtonProps } from '../../ui/atoms/Button';
import { underMaintenanceAtom } from '../atoms/underMaintenanceAtom';

type Props = {
  loading?: boolean;
  withdrawDisabled?: boolean;
  onWithdrawPress?: () => void;
  onDetailPress?: () => void;
};

const buttonProps: ButtonProps = {
  secondary: true,
  minWidth: 0,
  fontWeight: 'semiBold',
  flex: 1,
};

export const HomeLoanButtons = ({
  loading,
  withdrawDisabled,
  onWithdrawPress,
  onDetailPress,
}: Props) => {
  const isAppUnderMaintenance = useRecoilValue(underMaintenanceAtom);

  return (
    <Box
      row
      testID={TestID.HomeLoan.Header}
      justifyContent={{ desktop: 'flex-end' }}
    >
      <Button
        label={t('Content.HomeLoan.TransferButton')}
        icon="rightArrow"
        iconFamily="svg"
        onPress={onWithdrawPress}
        disabled={loading || withdrawDisabled || isAppUnderMaintenance}
        testID={TestID.HomeLoan.TransferButton}
        {...buttonProps}
        mr="s"
      />
      <Button
        label={t('Content.HomeLoan.ManageButton')}
        icon="settings"
        iconFamily="svg"
        onPress={onDetailPress}
        disabled={loading}
        testID={TestID.HomeLoan.ManageButton}
        {...buttonProps}
      />
    </Box>
  );
};
