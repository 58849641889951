import {
  CalculatePurchaseLoanAmountAndDepositQuery,
  Lmi_Quote_Error_Type,
  Repayment_Calculation_Error,
  Upsert_Loan_Application_Target_For_Purchase_Error_Type,
  Upsert_Loan_Application_Target_For_Purchase_Output,
} from '../../generated/graphql';
import { formatCurrency } from '../../utils/currencyHelpers';
import { transformStringToEnum } from '../../utils/enumHelpers';
import { formatLvrPercentage } from '../../utils/percentageFormatter';
import { assertUnreachable, WithFutureType } from '../../utils/typesHelpers';
import { formatPurchaseSetupLoanErrorType } from './handleSetupLoanErrorType';

export enum SetupLoanPurchaseErrorBannerType {
  LmiQuoteCalculationFailed = 'LMI_QUOTE_CALCULATION_FAILED',
  LmiQuoteTotalLoanAmountExceededMaximum = 'LMI_QUOTE_TOTAL_LOAN_AMOUNT_EXCEEDED_MAXIMUM',
  LmiQuoteCalculationDefaultError = 'LMI_QUOTE_CALCULATION_DEFAULT_ERROR',

  CalculatePurchaseLoanAmountAndDepositError = 'CALCULATE_PURCHASE_LOAN_AMOUNT_AND_DEPOSIT_ERROR',

  SecurityCostCalculationError = 'SECURITY_COST_CALCULATION_ERROR',

  MinRepaymentCalculationError = 'MIN_REPAYMENT_CALCULATION_ERROR',

  FormError = 'FORM_ERROR',
}

export type SetupLoanPurchaseBannerState = {
  type: SetupLoanPurchaseErrorBannerType;
  message: string | null;
} | null;

export type UpsertCompletedErrorPayload = {
  minTotalLoanAmountBeforeCosts: number;
  maxLoanAmount: number;
  maxTotalLoanAmount: Upsert_Loan_Application_Target_For_Purchase_Output['max_total_loan_amount'];
  errorType: Upsert_Loan_Application_Target_For_Purchase_Error_Type;
  totalLoanAmount: number | undefined;
  maxLvrApplicable: number | null | undefined;
};
type SetupLoanPurchaseBannerAction =
  | {
      type: 'CALCULATE_PURCHASE_LOAN_AMOUNT_AND_DEPOSIT_COMPLETED';
      payload: {
        data: CalculatePurchaseLoanAmountAndDepositQuery;
      };
    }
  | {
      type: 'SUBMIT_FORM_FAILED';
      message: string;
    }
  | {
      type: 'UPSERT_LOAN_APPLICATION_TARGET_FOR_PURCHASE_COMPLETED_WITH_ERROR';
      payload: UpsertCompletedErrorPayload;
    }
  | {
      type: 'CALCULATE_SECURITY_COST_FAILED';
    }
  | {
      type: 'CALCULATE_SECURITY_COST_COMPLETED';
    };
export function errorBannerStateReducer(
  state: SetupLoanPurchaseBannerState,
  action: SetupLoanPurchaseBannerAction,
): SetupLoanPurchaseBannerState {
  switch (action.type) {
    case 'CALCULATE_PURCHASE_LOAN_AMOUNT_AND_DEPOSIT_COMPLETED':
      return handleCalculatePurchaseLoanAmountAndDepositCompleted(
        state,
        action.payload,
      );

    case 'SUBMIT_FORM_FAILED':
      return {
        type: SetupLoanPurchaseErrorBannerType.FormError,
        message: action.message,
      };

    case 'UPSERT_LOAN_APPLICATION_TARGET_FOR_PURCHASE_COMPLETED_WITH_ERROR': {
      return handleUpsertLoanApplicationTargetForPurchaseError(action.payload);
    }

    case 'CALCULATE_SECURITY_COST_FAILED':
      return {
        type: SetupLoanPurchaseErrorBannerType.SecurityCostCalculationError,
        message: null,
      };

    case 'CALCULATE_SECURITY_COST_COMPLETED':
      if (
        state?.type ===
        SetupLoanPurchaseErrorBannerType.SecurityCostCalculationError
      ) {
        return null;
      }
      return state;

    default:
      return state;
  }
}

function formatPurchaseRepaymentCalculationErrorMessage(
  minRepaymentErrorType: WithFutureType<Repayment_Calculation_Error>,
): string | null {
  switch (minRepaymentErrorType) {
    case Repayment_Calculation_Error.LessThanMinAmount:
    case Repayment_Calculation_Error.MoreThanMaxAmount:
    case Repayment_Calculation_Error.InvalidAmount:
      return null;
    case Repayment_Calculation_Error.MambuApiCallFailed:
    case Repayment_Calculation_Error.NoLoanPreviewProductCode:
      return t('Content.SetupLoan.FieldError.UnableToCalculateMinRepayment');
    default:
      return t('Content.SetupLoan.FieldError.UnableToCalculateMinRepayment');
  }
}

export const LmiQuoteErrorBannerTypes = new Set([
  SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationFailed,
  SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
  SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationDefaultError,
]);
export function handleCalculatePurchaseLoanAmountAndDepositCompleted(
  state: SetupLoanPurchaseBannerState,
  payload: {
    data: CalculatePurchaseLoanAmountAndDepositQuery;
  },
) {
  const { data } = payload;
  const { lmiQuoteErrorType, minRepaymentErrorType: rawMinRepaymentErrorType } =
    data.calculate_purchase_loan_amount_and_upfront_costs;
  const { maxLvrApplicable } =
    data.calculate_purchase_loan_amount_and_upfront_costs;

  /**
   * The logic sequence matters.
   * We prioritize LMI error over min repayment error.
   */
  if (lmiQuoteErrorType) {
    switch (lmiQuoteErrorType) {
      case Lmi_Quote_Error_Type.LmiQuoteCalculationFailed:
        return {
          type: SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationFailed,
          message: null,
        };

      case Lmi_Quote_Error_Type.TotalLoanAmountExceededPropertyMaxLvr: {
        const { totalLoanAmount, maxTotalLoanAmount } =
          data.calculate_purchase_loan_amount_and_upfront_costs;

        // This is a special case where the LVR rules specify a postcode
        // restriction, setting the property max LVR to 0%.
        if (maxTotalLoanAmount === 0) {
          return {
            type: SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
            message: t(
              'Content.SetupLoan.FieldError.TotalLoanAmountExceededPropertyMaxLvrZero',
            ),
          };
        }

        return maxLvrApplicable === 70
          ? {
              // We have specific wording when the max LVR is 70%. This happens when VRA type is 1, 2, or 3.
              type: SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
              message: t(
                'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxLvrSeventy',
                { totalLoanAmount: formatCurrency(totalLoanAmount) },
              ),
            }
          : {
              type: SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
              message: t(
                'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithLvr',
                {
                  totalLoanAmount: formatCurrency(totalLoanAmount),
                  maxLvr: maxLvrApplicable
                    ? formatLvrPercentage(maxLvrApplicable)
                    : '--%',
                },
              ),
            };
      }
      case Lmi_Quote_Error_Type.TotalLoanAmountExceededCapAmount: {
        return {
          type: SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
          message: t(
            'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithMaxTotal',
            {
              totalLoanAmount: formatCurrency(
                data.calculate_purchase_loan_amount_and_upfront_costs
                  .totalLoanAmount,
              ),
              maxTotal: formatCurrency(
                data.calculate_purchase_loan_amount_and_upfront_costs
                  .maxTotalLoanAmount,
              ),
            },
          ),
        };
      }
      case Lmi_Quote_Error_Type.DesiredLoanAmountExceededMaximum:
      case Lmi_Quote_Error_Type.TotalLoanAmountExceededMaximum: {
        /** @deprecated */
        return {
          type: SetupLoanPurchaseErrorBannerType.LmiQuoteTotalLoanAmountExceededMaximum,
          message: t(
            'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithLvr',
            {
              totalLoanAmount: formatCurrency(
                data.calculate_purchase_loan_amount_and_upfront_costs
                  .totalLoanAmount,
              ),
              maxLvr: maxLvrApplicable
                ? formatLvrPercentage(maxLvrApplicable)
                : '--%',
            },
          ),
        };
      }
      case Lmi_Quote_Error_Type.LmiApplicableCheckFailed:
        return {
          type: SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationDefaultError,
          message: t('Content.SetupLoanPurchase.UnableToCheckLmiApplicability'),
        };
      default:
        assertUnreachable(lmiQuoteErrorType);
        return {
          type: SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationDefaultError,
          message: t('Content.SetupLoanPurchase.UnableToCalculateLmiPremium'),
        };
    }
  }

  const minRepaymentErrorType = rawMinRepaymentErrorType
    ? transformStringToEnum(
        rawMinRepaymentErrorType,
        Repayment_Calculation_Error,
      )
    : null;

  if (minRepaymentErrorType) {
    return {
      type: SetupLoanPurchaseErrorBannerType.MinRepaymentCalculationError,
      message: formatPurchaseRepaymentCalculationErrorMessage(
        minRepaymentErrorType,
      ),
    };
  }

  const isLmiQuoteErrorDisplayed = state
    ? LmiQuoteErrorBannerTypes.has(state.type)
    : false;
  if (lmiQuoteErrorType == null && isLmiQuoteErrorDisplayed) {
    // Reset if the current banner is lmi quote error
    return null;
  }

  const isMinRepaymentErrorDisplayed =
    state?.type ===
    SetupLoanPurchaseErrorBannerType.MinRepaymentCalculationError;
  if (minRepaymentErrorType == null && isMinRepaymentErrorDisplayed) {
    // Reset if the current banner is min repayment error
    return null;
  }

  return state;
}

export function handleUpsertLoanApplicationTargetForPurchaseError(
  payload: UpsertCompletedErrorPayload,
) {
  if (
    payload.errorType ===
    Upsert_Loan_Application_Target_For_Purchase_Error_Type.LmiQuoteRecalculationFailed
  ) {
    return {
      type: SetupLoanPurchaseErrorBannerType.LmiQuoteCalculationFailed,
      message: null,
    };
  }

  return {
    type: SetupLoanPurchaseErrorBannerType.FormError,
    message: formatPurchaseSetupLoanErrorType(payload),
  };
}
