import { createContext, useContext, useState } from 'react';
import * as React from 'react';

type HoverContext = [boolean, (isHover: boolean) => void];

const Context = createContext<HoverContext>([false, () => {}]);

type HoverContextProviderProps = {
  children?: React.ReactNode;
};

export function HoverContextProvider({ children }: HoverContextProviderProps) {
  const value = useState(false);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useHoverContext() {
  return useContext(Context);
}

export function withHoverContextProvider<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
) {
  return (props: T) => (
    <HoverContextProvider>
      <Component {...props} />
    </HoverContextProvider>
  );
}
