import { Text } from 'dripsy';
import { connect } from 'formik';
import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';
import { withFocus, withFormikControl } from 'react-native-formik';
import { compose } from 'recompose';

import { FieldProps } from '../../components/form/FormikInputs';
import { Box } from '../../ui/atoms/Box';
import {
  FormikAwareComboboxProps,
  makeCombobox,
  makeFormikAwareCombobox,
} from '../../ui/v2/Combobox';
import { makeSuggestionsResult } from '../../ui/v2/Combobox/SuggestionsResult';
import { ExternalAccount } from '../types';

function AccountSelection({ data }: { data?: ExternalAccount }) {
  if (!data) {
    return null;
  }

  return (
    <Box column>
      <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
        {data.account_name}
      </Text>
      <Text variant="caption">{data.account_details}</Text>
    </Box>
  );
}

function AccountSuggestion({ data }: { data: ExternalAccount }) {
  return (
    <Box column>
      <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
        {data.account_name}
      </Text>
      <Text variant="caption">{data.account_details}</Text>
    </Box>
  );
}

const AccountSuggestionsResult = makeSuggestionsResult<ExternalAccount>(
  AccountSuggestion,
  {
    getSuggestionItemKey: (item) => item.id,
    isSelected: (suggestionItem, selectedValue) =>
      suggestionItem.id === selectedValue?.id,
  },
);

const AccountCombobox = makeCombobox<ExternalAccount>(
  AccountSuggestionsResult,
  AccountSelection,
);

type AccountComboboxProps = FormikAwareComboboxProps<ExternalAccount, string>;

type BaseAccountDropdownProps = AccountComboboxProps & FieldProps;

const BaseAccountDropdown = compose<
  AccountComboboxProps,
  BaseAccountDropdownProps
>(
  withFocus,
  withFormikControl,
  connect,
)(
  makeFormikAwareCombobox<ExternalAccount, string>(AccountCombobox, {
    getDataItem: (data, fieldValue) =>
      data.find((item) => item.id === fieldValue),
    getFieldValue: (dataItem) => dataItem.id,
  }),
);

export type AccountDropdownProps = Omit<
  BaseAccountDropdownProps,
  'onChangeSearchText'
> & { selectedAccountId?: string };

export function AccountDropdown({
  suggestionData: initialSuggestionsData,
  selectedAccountId,
  ...props
}: AccountDropdownProps) {
  const [searchText, setSearchText] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(initialSuggestionsData, {
        fieldNormWeight: 1,
        findAllMatches: true,
        keys: ['account_name', 'account_details'],
      }),
    [initialSuggestionsData],
  );

  const suggestionData = useMemo(() => {
    const selectedAccount = initialSuggestionsData.find(
      (account) => account.id === selectedAccountId,
    );

    if (selectedAccount) {
      return [
        selectedAccount,
        ...initialSuggestionsData.filter(
          (account) => account.id !== selectedAccountId,
        ),
      ];
    }

    return initialSuggestionsData;
  }, [initialSuggestionsData, selectedAccountId]);

  const filteredSuggestionData = useMemo(
    () =>
      searchText
        ? fuse.search(searchText).map((result) => result.item)
        : suggestionData,
    [fuse, searchText, suggestionData],
  );

  return (
    <BaseAccountDropdown
      {...props}
      suggestionData={filteredSuggestionData}
      onChangeSearchText={setSearchText}
    />
  );
}
