import { Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCheckboxInputV2,
  FormRadioInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { FormikFormError } from '../../ui/v2/FormError';
import { generateDependentsCountOptions } from '../../utils/pickerHelpers';
import { makeTestId } from '../../utils/stringHelpers';
import {
  CombinedExpensesFormValues,
  ExpensesFormInitialValues,
  ExpensesFormValidationSchema,
  ExpensesFormValues,
} from '../utils/forms';
import { ExpensesFormField } from '../utils/types';

export type ExpensesFormProps = {
  screen: Screen;
  onSubmit: (values: CombinedExpensesFormValues) => void;
  isSubmitting?: boolean;
  applicantOptions: PickerOptions<string>;
  livingSituationOptions: PickerOptions<string>;
  initialValues?: ExpensesFormValues;
  loading?: boolean;
};

export const DEPENDENTS_COUNT_OPTIONS = generateDependentsCountOptions();

export function ExpensesDetailsForm({
  screen,
  onSubmit,
  isSubmitting,
  applicantOptions,
  livingSituationOptions,
  initialValues = ExpensesFormInitialValues,
  loading,
}: ExpensesFormProps) {
  if (loading) {
    return <LoadingState />;
  }
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={ExpensesFormValidationSchema}
      initialValues={initialValues}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <Text variant="sHeader" sx={{ mb: '$8' }}>
            {t('Content.ExpensesForm.BorrowersQuestion')}
          </Text>
          {applicantOptions.map(({ value, label, disabled }) => (
            <FormCheckboxInputV2
              key={value}
              name={ExpensesFormField.SelectedBorrowers}
              testID={makeTestId([
                TestID.ExpensesForm.BorrowersInThisHousehold,
                label,
              ])}
              value={value}
              label={label}
              disabled={disabled}
              containerStyle={{
                mt: '$8',
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Expenses,
                screen,
                FieldInteractionKey.WhoAreTheBorrowersInThisHousehold,
              )}
            />
          ))}
          <FormikFormError
            name={ExpensesFormField.SelectedBorrowers}
            sx={{ mt: '$8' }}
          />

          <Text variant="sHeader" sx={{ mb: '$16', mt: '$32' }}>
            {t('Content.ExpensesForm.DependentsQuestion')}
          </Text>
          <FormSelectV2
            name={ExpensesFormField.DependentsCount}
            testID={TestID.ExpensesForm.DependantsInput}
            items={DEPENDENTS_COUNT_OPTIONS}
            label={t('Content.ExpensesForm.NumberOfDependents')}
          />
          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.ExpensesForm.DependentsFooter')}
          </Text>
          <FormikFormError
            name={ExpensesFormField.DependentsCount}
            sx={{ mt: '$8' }}
          />

          <Text variant="sHeader" sx={{ mb: '$8', mt: '$32' }}>
            {t('Content.ExpensesForm.LivingSituationQuestion')}
          </Text>
          {livingSituationOptions.map(({ value, label }) => (
            <FormRadioInputV2
              key={value}
              name={ExpensesFormField.LivingSituation}
              value={value}
              testID={makeTestId([
                TestID.ExpensesForm.FutureLivingSituation,
                label,
              ])}
              label={label}
              containerStyle={{
                mt: '$8',
              }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Expenses,
                screen,
                FieldInteractionKey.LivingSituation,
              )}
            />
          ))}
          <FormikFormError
            name={ExpensesFormField.LivingSituation}
            sx={{ mt: '$8' }}
          />

          <Button
            label={t('Content.Common.ButtonLabel.Next')}
            testID={TestID.ExpensesForm.NextButton}
            onPress={() => formProps.handleSubmit()}
            py="m"
            mt="xl"
            alignSelf="stretch"
            showSpinner={isSubmitting}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
}
