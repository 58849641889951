import { FC, useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { newExternalAccountAtom } from '../../ExternalAccount/atoms/newExternalAccountAtom';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { AccountSelector } from '../../ExternalAccount/components/AccountSelector';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { AutopaySettingsScreenProps } from '../navigation/types';

type Props = AutopaySettingsScreenProps<Screen.AUTOPAY_SETTINGS_SELECT_ACCOUNT>;

export const AutopaySelectAccount: FC<Props> = ({ navigation, route }) => {
  const resetNewExternalAccount = useResetRecoilState(newExternalAccountAtom);

  const [selectedExternalAccount, setSelectedExternalAccount] = useRecoilState(
    selectedExternalAccountAtom,
  );

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.AutopaySettings.AccountSelect')}
      onClose={onClose}
      scrollable
    >
      <AccountSelector
        selectedAccountId={selectedExternalAccount?.externalAccountId}
        onPressAddAccount={() => {
          resetNewExternalAccount();
          navigation.navigate(Screen.AUTOPAY_SETTINGS_ADD_ACCOUNT, {
            cbaAccountId: route.params.cbaAccountId,
          });
        }}
        onSelectAccount={(account) => {
          setSelectedExternalAccount(account);
          navigation.goBack();
        }}
      />
    </ModalScreenContainer>
  );
};
