import mitt from 'mitt';

import {
  Action_Error_Details,
  Add_Coborrower_Error_Type,
  AddCoBorrowerMutationResult,
} from '../../../generated/graphql';

export type InviteCoBorrowerDuplicateError = Extract<
  Add_Coborrower_Error_Type,
  | Add_Coborrower_Error_Type.DuplicateEmail
  | Add_Coborrower_Error_Type.DuplicateNumber
  | Add_Coborrower_Error_Type.DuplicateNumberAndEmail
>;
export type InviteCoBorrowerOtherError = Exclude<
  Add_Coborrower_Error_Type,
  InviteCoBorrowerDuplicateError
>;

export type InviteCoBorrowerErrorEvent = {
  formLevelError: {
    type: InviteCoBorrowerOtherError | null;
    details?: Array<Action_Error_Details> | null;
  };
  fieldLevelError: {
    type: InviteCoBorrowerDuplicateError;
  };
  clearError: string;
};

export const inviteCoBorrowerErrorEmitter = mitt<InviteCoBorrowerErrorEvent>();

export function emitInviteCoBorrowerErrorBasedOnType(
  data: NonNullable<AddCoBorrowerMutationResult['data']>['add_coborrower'],
) {
  switch (data.error_type) {
    case Add_Coborrower_Error_Type.InvalidInput:
    case Add_Coborrower_Error_Type.MaxBorrowersReached: {
      inviteCoBorrowerErrorEmitter.emit('formLevelError', {
        type: data.error_type,
        details: data?.error_details,
      });
      break;
    }
    case Add_Coborrower_Error_Type.DuplicateEmail:
    case Add_Coborrower_Error_Type.DuplicateNumber:
    case Add_Coborrower_Error_Type.DuplicateNumberAndEmail: {
      inviteCoBorrowerErrorEmitter.emit('fieldLevelError', {
        type: data.error_type,
      });
      break;
    }
    default: {
      inviteCoBorrowerErrorEmitter.emit('formLevelError', {
        type: null,
      });
    }
  }
}
