import { gql } from '@apollo/client';

import {
  useGetAvailableStatementsQuery,
  useGetGeneratedStatementQuery,
  useGetStatementPeriodsQuery,
} from '../generated/graphql';
import { ViewStatementParams } from '../navigation/types/navTypes';

export const GetStatementPeriods = gql`
  query GetStatementPeriods($cba_account_id: String!) {
    get_statement_periods(cba_account_id: $cba_account_id) {
      periods {
        period
        end
        start
      }
      statement_end_date
      statement_start_date
    }
  }
`;

export const GetGeneratedStatement = gql`
  query GetGeneratedStatement(
    $cbaAccountId: String!
    $endDate: String!
    $startDate: String!
  ) {
    generate_statement(
      cba_account_id: $cbaAccountId
      end_date: $endDate
      start_date: $startDate
    ) {
      html
    }
  }
`;

export const GetAvailableStatements = gql`
  query GetAvailableStatements($cba_account_id: String!) {
    get_available_statements(cba_account_id: $cba_account_id) {
      statements {
        type
        year
        start_date
        end_date
      }
      min_start_date
      max_end_date
    }
  }
`;

export function useStatementPeriodsQuery(cbaAccountId: string) {
  const { data, ...others } = useGetStatementPeriodsQuery({
    variables: {
      cba_account_id: cbaAccountId,
    },
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  return {
    data: {
      statement_periods: {
        statement_end_date:
          data?.get_statement_periods?.statement_end_date ?? null,
        statement_start_date:
          data?.get_statement_periods?.statement_start_date ?? null,
        periods: data?.get_statement_periods?.periods ?? [],
      },
    },
    ...others,
  };
}

export function useStatementV2PeriodsQuery(cbaAccountId: string) {
  const { data, ...others } = useGetAvailableStatementsQuery({
    variables: {
      cba_account_id: cbaAccountId,
    },
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  const response = data?.get_available_statements;
  const startDate = response?.min_start_date ?? new Date().getTime();
  const endDate = response?.max_end_date ?? new Date().getTime();
  const statements = response?.statements;

  return {
    startDate,
    endDate,
    statements,
    ...others,
  };
}

export function useGeneratedStatementQuery({
  cbaAccountId,
  startDate,
  endDate,
}: ViewStatementParams) {
  const { data, ...others } = useGetGeneratedStatementQuery({
    variables: {
      cbaAccountId,
      endDate,
      startDate,
    },
    fetchPolicy: 'network-only',
    context: {
      sentryContext: {
        cbaAccountId,
      },
    },
  });

  return {
    html: data?.generate_statement?.html ?? '',
    ...others,
  };
}
