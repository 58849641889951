import { Text, useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Skeleton } from '../../ui/v2/Skeleton';
import { formatCurrency } from '../../utils/currencyHelpers';

type Props = {
  loading: boolean;
  newMonthlyRepayment: number;
  reducedAmount: number;
};

export const NewMonthlyRepayments = ({
  loading,
  newMonthlyRepayment,
  reducedAmount,
}: Props) => {
  const { theme } = useDripsyTheme();
  return (
    <View sx={{ py: '$8' }}>
      <Text variants={['caption', 'xsHeader']}>
        {t('Content.LoanVariation.ReduceRepayment.NewMonthlyRepaymentHeading')}
      </Text>
      {loading ? (
        <View sx={{ py: '$2' }}>
          <Skeleton width="100%" height={theme.space.$40} show />
        </View>
      ) : (
        <View
          sx={{
            flexDirection: 'row',
          }}
          testID={TestID.LoanVariation.ReduceRepayments.NewMonthlyRepayments}
        >
          <Text variant="lNumber">
            {formatCurrency(newMonthlyRepayment, {
              withFractionOnRoundedAmount: true,
            })}
          </Text>
          <Text sx={{ py: '$4' }}>*</Text>
        </View>
      )}
      <View sx={{ mt: '$8' }}>
        {loading ? (
          <Skeleton width="100%" height={theme.space.$20} show />
        ) : (
          <Text
            testID={
              TestID.LoanVariation.ReduceRepayments.ReducedMonthlyRepayments
            }
            variant="xsHeader"
          >
            {t('Content.LoanVariation.ReduceRepayment.ReducedRepaymentText', {
              reducedRepaymentAmount: formatCurrency(reducedAmount, {
                withFractionOnRoundedAmount: true,
              }),
            })}
          </Text>
        )}
      </View>
    </View>
  );
};
