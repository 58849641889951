import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function DollarCircleFilledIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} fill="none" {...props}>
      <Path
        fill="url(#a)"
        d="M13.5 26.783C6.429 26.783.55 20.905.55 13.834.55 6.75 6.417.884 13.488.884c7.084 0 12.962 5.866 12.962 12.95 0 7.071-5.865 12.95-12.949 12.95Zm0-5.472a.6.6 0 0 0 .622-.622v-.914c2.019-.19 3.605-1.37 3.605-3.351 0-1.777-1.155-2.857-3.427-3.263l-.178-.038V9.479c.94.166 1.587.724 1.854 1.6.126.394.38.648.8.648a.747.747 0 0 0 .774-.775c0-.127-.038-.267-.064-.406-.342-1.41-1.688-2.387-3.364-2.577v-.902a.6.6 0 0 0-.622-.622.6.6 0 0 0-.622.622v.902c-1.98.216-3.402 1.46-3.402 3.25 0 1.663 1.13 2.653 3.313 3.174l.089.025v3.86c-1.168-.166-1.854-.826-2.07-1.69-.152-.456-.418-.66-.812-.66-.444 0-.762.318-.762.775 0 .14.026.28.064.406.368 1.511 1.828 2.476 3.58 2.641v.94a.6.6 0 0 0 .622.621Zm-.622-8.518-.089-.025c-1.168-.343-1.688-.864-1.688-1.638 0-.787.672-1.46 1.777-1.65v3.313Zm1.244 1.93.28.076c1.269.343 1.7.889 1.7 1.714 0 .914-.647 1.612-1.98 1.764v-3.554Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={-2}
          x2={85.289}
          y1={14}
          y2={39.731}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F5C05A" />
          <Stop stopColor="#F4B965" />
          <Stop offset={0.307} stopColor="#EB88B1" />
          <Stop offset={0.651} stopColor="#B965E4" />
          <Stop offset={1} stopColor="#61A6F9" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
