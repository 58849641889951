import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const AutopayOutgoingExpenses: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => {
  const width = resizeIconWidth(45, 45, size);

  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 45 45"
      fill="none"
      {...props}
    >
      <Path
        fill="#2C2C2C"
        d="M41.25 28.125h-22.5v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="url(#a)"
        d="M41.25 24.375h-22.5v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="#2C2C2C"
        d="M41.25 20.625h-22.5v3.75C18.75 27.482 23.787 30 30 30s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="url(#b)"
        d="M41.25 16.875h-22.5v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="#2C2C2C"
        d="M41.25 13.125h-22.5v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="url(#c)"
        d="M41.25 9.375h-22.5v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="#C6C6C6"
        d="M30 15c6.213 0 11.25-2.518 11.25-5.625S36.213 3.75 30 3.75 18.75 6.268 18.75 9.375 23.787 15 30 15Z"
      />
      <Path
        fill="#2C2C2C"
        d="M18.75 17.168v14.707c0 2.726 3.878 4.998 9.026 5.514.22-.563.349-1.15.349-1.764v-11.25c0-3.524-3.837-6.33-9.375-7.207Z"
        opacity={0.08}
      />
      <Path
        fill="#2C2C2C"
        d="M18.75 18.12v13.755c0 2.55 3.398 4.702 8.053 5.392a3.898 3.898 0 0 0 .384-1.642v-11.25c0-2.972-3.494-5.422-8.437-6.255Z"
        opacity={0.08}
      />
      <Path
        fill="url(#d)"
        d="M26.25 31.875H3.75v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="#2C2C2C"
        d="M26.25 28.125H3.75v3.75C3.75 34.982 8.787 37.5 15 37.5s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="url(#e)"
        d="M26.25 24.375H3.75v3.75c0 3.107 5.037 5.625 11.25 5.625s11.25-2.518 11.25-5.625v-3.75Z"
      />
      <Path
        fill="url(#f)"
        d="M15 30c6.213 0 11.25-2.518 11.25-5.625S21.213 18.75 15 18.75 3.75 21.268 3.75 24.375 8.787 30 15 30Z"
      />
      <Path
        fill="#C6C6C6"
        d="M15 30c6.213 0 11.25-2.518 11.25-5.625S21.213 18.75 15 18.75 3.75 21.268 3.75 24.375 8.787 30 15 30Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={18.75}
          x2={25.407}
          y1={24.375}
          y2={40.351}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={18.75}
          x2={25.407}
          y1={16.875}
          y2={32.851}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={18.75}
          x2={25.407}
          y1={9.375}
          y2={25.351}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="d"
          x1={3.75}
          x2={10.407}
          y1={31.875}
          y2={47.851}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="e"
          x1={3.75}
          x2={10.407}
          y1={24.375}
          y2={40.351}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="f"
          x1={8.868}
          x2={21.266}
          y1={18.243}
          y2={30.641}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FEDE00" />
          <Stop offset={1} stopColor="#FFD000" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
