import { Field } from 'formik';
import { forwardRef } from 'react';
import { TextInput as RNTextInput, TouchableOpacity } from 'react-native';

import { StyledText } from '../../ui/atoms/StyledText';
import { CollapsibleSelection } from './CollapsibleSelection';
import {
  CurrencyInputRow,
  Props as CurrencyInputRowProps,
} from './CurrencyInputRow';
import { SelectionRowOptions } from './SelectionRowGroup';
import { FieldArgs } from './types';

export type Props = CurrencyInputRowProps & {
  collapsed?: boolean;
  suffixOptions: SelectionRowOptions<string>;
  suffixPickerName: string;
  isSuffixPickerLoading?: boolean;
  name: string;
};

export const CurrencyInputRowWithSuffixSelection = forwardRef<
  RNTextInput,
  Props
>(
  (
    {
      suffixPickerName,
      suffixOptions,
      last: lastProp,
      isSuffixPickerLoading,
      ...otherProps
    },
    ref,
  ) => (
    <Field name={suffixPickerName}>
      {({
        form: { setFieldValue: setSuffixValue },
        field: { value: suffixValue },
      }: FieldArgs<Props, string>) => (
        <CollapsibleSelection
          options={suffixOptions}
          name={suffixPickerName}
          value={suffixValue}
          setFieldValue={(val: string) => setSuffixValue(suffixPickerName, val)}
          isLoading={isSuffixPickerLoading}
          last={lastProp}
          groupTestId={suffixPickerName}
        >
          {({ onPress, isPickerOpened, selectedLabel }) => (
            <CurrencyInputRow
              /**
               * When label is specified, the row will have 3 element, label, currency amount, and frequency.
               * The amount should be right aligned to match the design.
               * The default one should look like this with label:
               * | Expected rent     $125 per week |
               * Without label:
               * | $125                   per week |
               */
              {...(otherProps.label != null ? { textAlign: 'right' } : null)}
              {...otherProps}
              last={isPickerOpened ? false : lastProp}
              ref={ref}
              inputTestID={otherProps.name}
            >
              <TouchableOpacity onPress={onPress} testID={suffixPickerName}>
                <StyledText color="link">{selectedLabel}</StyledText>
              </TouchableOpacity>
            </CurrencyInputRow>
          )}
        </CollapsibleSelection>
      )}
    </Field>
  ),
);
