/**
 * Residential or Mailing
 */
export enum AddressType {
  Residential = 'residentialAddress',
  Mailing = 'mailingAddress',
}
/**
 * DomainApi or Manual
 */
export enum AddressInputType {
  DomainApi = 'domain_api_input',
  Manual = 'manual_input',
}

export enum AddressFields {
  DisplayAddress = 'displayAddress',
  Postcode = 'postcode',
  State = 'state',
  Street = 'street',
  StreetNo = 'streetNo',
  StreetNoTwo = 'streetNoTwo',
  StreetType = 'streetType',
  Suburb = 'suburb',
  UnitNo = 'unitNo',
  IsProvidedByUser = 'isProvidedByUser',
  DomainApiId = 'domainApiId',
}
