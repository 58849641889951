import { atom } from 'recoil';

export const RemovePropertyActionSheetErrorAtom = atom<string | null>({
  key: 'removePropertyActionSheetErrorState',
  default: null,
});

export const IDVerificationConsentActionSheetErrorAtom = atom<string | null>({
  key: 'idVerificationConsentActionSheetErrorState',
  default: null,
});

export const DeleteIncomeActionSheetErrorAtom = atom<string | null>({
  key: 'deleteIncomeActionSheetErrorState',
  default: null,
});
