import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useRef, useState } from 'react';

export const useSessionExpiration = ({
  onSessionExpired,
}: {
  onSessionExpired: () => void;
}) => {
  const { getIdTokenClaims } = useAuth0();

  const sessionLockTimeout = useRef<NodeJS.Timeout | null>(null);

  const [sessionExpiryTime, setSessionExpiryTime] = useState(
    new Date().getTime(),
  );

  // When token is refreshed, there will be a new session expiry time.
  // The session expiry time is included inside the ID Token.
  const handleSessionExpiryUpdated = useCallback(async () => {
    const claims = await getIdTokenClaims();

    const sessionExpiryClaim =
      claims?.['au.com.unloan.app.PageSessionExpiresAt'];

    if (typeof sessionExpiryClaim !== 'string') {
      return;
    }

    const newSessionExpiryTime = Date.parse(sessionExpiryClaim);
    setSessionExpiryTime(newSessionExpiryTime);
    const timeUntilSessionExpires = newSessionExpiryTime - new Date().getTime();

    if (sessionLockTimeout.current) {
      clearTimeout(sessionLockTimeout.current);
    }

    if (timeUntilSessionExpires > 0) {
      sessionLockTimeout.current = setTimeout(
        onSessionExpired,
        timeUntilSessionExpires,
      );
    }
  }, [getIdTokenClaims, onSessionExpired]);

  return {
    sessionExpiryTime,
    handleSessionExpiryUpdated,
  };
};
