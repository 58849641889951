import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const GoogleIcon: React.FC<SvgIconProps> = ({ size }) => (
  <Svg width={size} height={size} viewBox="0 0 18 16" fill="none">
    <Path
      d="M8.81454 3.16662C10.0227 3.16662 11.1046 3.57328 11.9578 4.3666L14.2956 2.0833C12.8759 0.793321 11.0227 0 8.81454 0C5.61325 0 2.8454 1.79331 1.49731 4.4066L4.2208 6.4699C4.86583 4.57326 6.67807 3.16662 8.81454 3.16662Z"
      fill="#EA4335"
    />
    <Path
      d="M16.6577 8.18266C16.6577 7.65933 16.6066 7.15267 16.5281 6.66602H8.81494V9.67264H13.2312C13.0333 10.6593 12.4599 11.4993 11.5999 12.0659L14.238 14.0659C15.7772 12.6726 16.6577 10.6126 16.6577 8.18266Z"
      fill="#4285F4"
    />
    <Path
      d="M4.21779 9.52951C4.05397 9.04618 3.95841 8.53285 3.95841 7.99953C3.95841 7.4662 4.05056 6.95288 4.21779 6.46955L1.49431 4.40625C0.938009 5.48623 0.624023 6.70621 0.624023 7.99953C0.624023 9.29284 0.938009 10.5128 1.49772 11.5928L4.21779 9.52951Z"
      fill="#FBBC05"
    />
    <Path
      d="M8.81502 15.9996C11.0266 15.9996 12.8866 15.2896 14.2381 14.063L11.5999 12.063C10.8662 12.5463 9.9208 12.8297 8.81502 12.8297C6.67856 12.8297 4.86631 11.423 4.21787 9.52637L1.49438 11.5897C2.84589 14.2063 5.61374 15.9996 8.81502 15.9996Z"
      fill="#34A853"
    />
  </Svg>
);
