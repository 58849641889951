import { atom } from 'recoil';

import { InviteCoBorrowerFormValues } from '../screens/utils/forms';

const InviteCoBorrowerFormDataAtomName = 'InviteCoBorrowerFormData';

export type InviteCoBorrowerFormDataAtomValues =
  InviteCoBorrowerFormValues | null;
// Pass data to Co-Borrower Info Confirmation action sheet
export const inviteCoBorrowerFormDataAtom =
  atom<InviteCoBorrowerFormDataAtomValues>({
    key: InviteCoBorrowerFormDataAtomName,
    default: null,
  });
