import { useRoute } from '@react-navigation/native';
import { Text, View } from 'dripsy';
import { LinearGradient } from 'expo-linear-gradient';
import { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import {
  ApplicantStatusInfoFragment,
  Loan_Application_Type_Enum,
} from '../../../generated/graphql';
import { Button } from '../../../ui/atoms/Button';
import { useCardHoverShadowStyle } from '../../../ui/utils/useCardHoverShadowStyle';
import { useCardTranslateYAnimatedStyle } from '../../../ui/utils/useCardTranslateYAnimatedStyle';
import { Card, CardContent, CardFooter, CardHeader } from '../../../ui/v2/Card';
import { TouchableWithoutFeedback } from '../../../ui/v2/Gesture/TouchableWithoutFeedback';
import { convertRGB, getRGB, RGB } from '../../../ui/v2/LinearGradient';
import { percentageFormatter } from '../../../utils/percentageFormatter';
import { isWeb } from '../../../utils/platformUtils';
import {
  ApplicantRoute,
  useApplicantStates,
} from '../../utils/useApplicantStates';
import { useHomeNavigation } from '../../utils/useHomeNavigation';
import { useNativeAppApplicationNavigation } from '../../utils/useNativeAppApplicationNavigation';
import { getStepperProgresses } from './getStepperProgresses';

const styles = StyleSheet.create({
  stepperContainer: {
    flex: 1,
    height: 7,
    backgroundColor: '#D9D9D9',
    borderRadius: 7,
    marginLeft: 4,
  },
  stepper: {
    flex: 1,
    borderRadius: 7,
  },
});

const StepperColor = {
  Apply: { r: 246, g: 193, b: 92 },
  Approve: { r: 243, g: 176, b: 116 },
  Sign: { r: 240, g: 159, b: 143 },
  Settle: { r: 236, g: 142, b: 170 },
};

export type ApplicationTrackerProps = {
  appliedLoan: ApplicantStatusInfoFragment;
};

const getTitleByLoanType = (
  loanApplicationType: Loan_Application_Type_Enum,
): string => {
  switch (loanApplicationType) {
    case Loan_Application_Type_Enum.Purchase:
      return t('Content.Common.LoanApplicationType.Purchase');
    case Loan_Application_Type_Enum.Refinance:
      return t('Content.Common.LoanApplicationType.Refinance');
    case Loan_Application_Type_Enum.Variation:
      return t('Content.Common.LoanApplicationType.Variation');
    case Loan_Application_Type_Enum.TopUp:
      return t('Content.Common.LoanApplicationType.TopUp');
    default:
      return t('Content.Common.Word.Unknown');
  }
};

const Stepper = ({
  progress,
  first = false,
  startColor,
  endColor,
}: {
  progress: number;
  first?: boolean;
  startColor: RGB;
  endColor: RGB;
}) => (
  <View style={[styles.stepperContainer, first ? { marginLeft: 0 } : {}]}>
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={[convertRGB(startColor), getRGB(startColor, endColor, progress)]}
      style={[styles.stepper, { width: percentageFormatter(progress) }]}
    />
  </View>
);

export const ApplicationTracker = ({
  appliedLoan,
}: ApplicationTrackerProps) => {
  const loanApplicationId = appliedLoan.loanApplication.id;
  const navigation = useHomeNavigation();
  const route = useRoute<ApplicantRoute>();

  const {
    action,
    applicantState,
    // applicationTrackingStage,
    caption,
    shortAddressFormat,
    // showFmsPortal,
    // portalUrl,
    // applicantStateLoading,
  } = useApplicantStates(navigation, route, appliedLoan);

  const { onApplicationTracking } = useNativeAppApplicationNavigation();

  const onApplicationTrackingCardPress = useCallback(() => {
    onApplicationTracking(loanApplicationId);
  }, [onApplicationTracking, loanApplicationId]);

  // Native: Application tracking can be used as the generic screen for all application states
  const onActionPress = useMemo(
    () => (isWeb ? action?.onPress : onApplicationTrackingCardPress),
    [action?.onPress, onApplicationTrackingCardPress],
  );

  const loanTypeHeaderTitle = getTitleByLoanType(
    appliedLoan.loanApplication.type,
  );

  const [isHover, setIsHover] = useState(false);

  const shadowProps = useCardHoverShadowStyle({ isHover });

  const animatedStyle = useCardTranslateYAnimatedStyle(isHover);

  const progresses = getStepperProgresses(applicantState);
  return (
    <TouchableWithoutFeedback
      onPress={onApplicationTrackingCardPress}
      onPressIn={() => setIsHover(true)}
      onPressOut={() => setIsHover(false)}
    >
      <Animated.View
        style={animatedStyle}
        testID={`applicationCard-${loanApplicationId}`}
      >
        <Card sx={shadowProps}>
          <CardHeader
            title={<Text variant="xsHeader">{loanTypeHeaderTitle}</Text>}
            subtitle={
              <Text variant="sSubHeading">{shortAddressFormat || ' '}</Text>
            }
          />
          <CardContent>
            <View sx={{ flex: 1, flexDirection: 'row', mb: '$8' }}>
              <Stepper
                progress={progresses[0]}
                first
                startColor={StepperColor.Apply}
                endColor={StepperColor.Approve}
              />
              <Stepper
                progress={progresses[1]}
                startColor={StepperColor.Approve}
                endColor={StepperColor.Sign}
              />
              <Stepper
                progress={progresses[2]}
                startColor={StepperColor.Sign}
                endColor={StepperColor.Settle}
              />
            </View>
            <View
              sx={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text variant="tiny" sx={{ fontWeight: 700 }}>
                  {t('Content.ApplicationTracker.Apply')}
                </Text>
              </View>
              <View>
                <Text variant="tiny" sx={{ fontWeight: 700, left: -8 }}>
                  {t('Content.ApplicationTracker.Approve')}
                </Text>
              </View>
              <View>
                <Text variant="tiny" sx={{ fontWeight: 700, left: 2 }}>
                  {t('Content.ApplicationTracker.Sign')}
                </Text>
              </View>
              <View>
                <Text variant="tiny" sx={{ fontWeight: 700 }}>
                  {t('Content.ApplicationTracker.Settle')}
                </Text>
              </View>
            </View>
          </CardContent>
          <CardFooter
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text variant="caption">{caption}</Text>
            {action?.actionLabelShort ? (
              <Button
                ml="l"
                label={action?.actionLabelShort}
                onPress={onActionPress}
                variant="pill"
                alignSelf="center"
                fontSize="xs"
                sx={{
                  px: 12,
                }}
                testID={`applicationActionButton-${loanApplicationId}`}
              />
            ) : null}
          </CardFooter>
        </Card>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};
