import { gql } from '@apollo/client';
import { Text } from 'dripsy';
import { useCallback, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { useSecuritySupportingDocumentsSubscription } from '../../generated/graphql';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { Spinner } from '../../ui/atoms/Spinner';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDocumentPicker } from '../../utils/hooks/useDocumentPicker';
import {
  getErrorMessage,
  useSecuritySupportingDocumentUpload,
} from '../../utils/hooks/useSupportingDocumentUpload';
import { DocumentRow } from '../../VerifyFinancials/components/DocumentRow';
import { mapMimeTypeToDocumentIconType } from '../../VerifyFinancials/utils/mapMimeTypeToDocumentIconType';
import { DocumentUpload } from '../components/DocumentUpload';
import { UploadOverlay } from '../components/UploadOverlay';

export const SECURITY_SUPPORTING_DOCUMENT_GQL_OPERATIONS = gql`
  subscription SecuritySupportingDocuments($loanApplicationSecurityId: uuid!) {
    securitySupportingDocs: loan_application_security_supporting_document(
      where: {
        loan_application_security_id: { _eq: $loanApplicationSecurityId }
        file_upload: { status: { _neq: PENDING_UPLOAD } }
      }
      order_by: { file_upload: { created_at: desc } }
    ) {
      id
      file_upload {
        id
        status
        mimeType: mime_type
        originalName: original_name
        sizeInBytes: size_in_bytes
        xaiDocumentId: xai_document_id
        createdAt: created_at
      }
    }
  }
`;

type Props =
  SingleModalStackScreenProps<Screen.SECURITY_SUPPORTING_DOCUMENT_MODAL>;

export function SecuritySupportingDocumentUpload({ route, navigation }: Props) {
  const { loanApplicationId = '', loanApplicationSecurityId = '' } =
    route.params ?? {};

  const {
    uploadSecuritySupportingDocument,
    isUploading,
    error: uploadError,
  } = useSecuritySupportingDocumentUpload(loanApplicationId);

  const { makeDocumentPickerHandler } = useDocumentPicker();

  const {
    data: subsData,
    loading: subsLoading,
    error: subsError,
  } = useSecuritySupportingDocumentsSubscription({
    variables: { loanApplicationSecurityId },
    skip: !loanApplicationSecurityId,
  });
  const securitySupportingDocs = subsData?.securitySupportingDocs ?? [];
  const openDocumentPicker = useRef(
    makeDocumentPickerHandler({
      onDocumentSelect: async (selectedDocs) => {
        await uploadSecuritySupportingDocument({
          document: selectedDocs,
          loanApplicationSecurityId,
        });
      },
    }),
  );
  const onUploadDocumentPress = useCallback(async () => {
    await openDocumentPicker.current();
  }, []);

  let errorMessage = null;

  if (subsError) {
    errorMessage = t('Content.Common.Error.FailFetchUploadedDocuments');
  } else if (uploadError) {
    errorMessage = getErrorMessage(uploadError);
  }
  return (
    <>
      <ModalScreenContainer
        onClose={navigation.goBack}
        headerText={t('Content.SecuritySupportingDocumentUpload.Title')}
        scrollable
        hideBackButton
        loading={isUploading}
      >
        <ErrorRow message={errorMessage} mb="l" />
        <Text>{t('Content.SecuritySupportingDocumentUpload.Caption')}</Text>
        <DocumentUpload
          onUploadDocumentPress={onUploadDocumentPress}
          my="l"
          testID={TestID.IncomeSupportingDocumentUpload.UploadButton}
        />
        {subsLoading ? (
          <>
            <Separator spacer />
            <Spinner />
            <Separator spacer />
          </>
        ) : null}
        {securitySupportingDocs.map(
          ({
            file_upload: { id, originalName, mimeType, sizeInBytes, createdAt },
          }) => (
            <DocumentRow
              key={id}
              name={originalName}
              sizeInBytes={sizeInBytes}
              createdAt={createdAt}
              iconType={mapMimeTypeToDocumentIconType(mimeType)}
            />
          ),
        )}
        <Button
          label={t('Content.Common.ButtonLabel.Done')}
          width="100%"
          mb="l"
          mt="xl"
          testID={TestID.IncomeSupportingDocumentUpload.DoneButton}
          onPress={navigation.goBack}
        />
      </ModalScreenContainer>
      {isUploading ? <UploadOverlay /> : null}
    </>
  );
}
