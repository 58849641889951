import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryRentalIncome = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryRentalIncome');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09 16.5v14.25h26.572V16.5L18.376 3.75 5.09 16.5Zm0 15.75v-1.5h26.572v1.5c0 .829-.66 1.5-1.476 1.5H6.566a1.488 1.488 0 0 1-1.476-1.5Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M22.5 25.014c0-4.433-6.505-3.567-6.505-6.187 0-1.367 1.527-1.439 2.005-1.439 1.148 0 2.257.31 3.102.709a.397.397 0 0 0 .572-.356v-1.29c0-.433-.162-.69-.42-.79-.58-.227-1.411-.392-2.642-.433v-1.42a.307.307 0 0 0-.306-.308h-.613a.307.307 0 0 0-.306.308v1.443c-1.908.187-3.887 1.27-3.887 3.792 0 4.595 6.505 3.684 6.505 6.104 0 .76-.483 1.417-2.006 1.417-1.53 0-2.937-.538-3.716-.938a.394.394 0 0 0-.57.356v1.469c0 .281.168.532.426.642.923.394 2.16.692 3.247.78v1.569c0 .17.137.308.307.308h.612c.17 0 .306-.138.306-.308v-1.568c2.857-.24 3.889-2.162 3.889-3.86Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.05}
        d="M18.376 4.5c-.35 0-.7.126-.979.377l-3.955 3.609L5.09 16.5v4.043L18.376 8.57l13.286 11.972V16.5L23.31 8.486l-3.955-3.609a1.46 1.46 0 0 0-.979-.377Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33.503 16.13 19.353 3.377a1.46 1.46 0 0 0-1.957 0L3.247 16.13a.758.758 0 0 0-.047 1.073l1.208 1.309a.73.73 0 0 0 1.026.05L18.375 7.07l12.942 11.492a.73.73 0 0 0 1.026-.05l1.207-1.31a.758.758 0 0 0-.047-1.073Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={5.09}
          y1={3.75}
          x2={34.87}
          y2={30.128}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
