import { Theme, useDripsyTheme } from 'dripsy';
import { MotiView } from 'moti';
import { Skeleton as BaseSkeleton } from 'moti/skeleton';
import { ReactNode } from 'react';
import * as React from 'react';
import { TextStyle } from 'react-native';

import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import { useTheme } from '../theme';

const skeletonWrapperTransitionOptions = {
  from: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.9,
  },
  transition: {
    type: 'timing' as const,
  },
};

type WrapperProps = { children: ReactNode; key: string };

export function SkeletonWrapper({ children, key }: WrapperProps) {
  return (
    <MotiView {...skeletonWrapperTransitionOptions} key={key}>
      {children}
    </MotiView>
  );
}

const skeletonContentWrapper = {
  from: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export function ContentWrapper({ children, key }: WrapperProps) {
  return (
    <MotiView {...skeletonContentWrapper} key={key}>
      {children}
    </MotiView>
  );
}

type SkeletonProps = Omit<
  React.ComponentProps<typeof BaseSkeleton>,
  'children'
> & { children?: ReactNode };

export function Skeleton({ show, children, ...props }: SkeletonProps) {
  const theme = useTheme();
  const colors = React.useMemo(
    () => [
      theme.colors.skeletonPrimaryV2,
      theme.colors.skeletonSecondaryV2,
      theme.colors.skeletonPrimaryV2,
      theme.colors.skeletonSecondaryV2,
      theme.colors.skeletonPrimaryV2,
      theme.colors.skeletonSecondaryV2,
    ],
    [theme.colors.skeletonPrimaryV2, theme.colors.skeletonSecondaryV2],
  );

  // Skeleton wraps the components with a div which breaks any z-index specified in the content
  if (!show) {
    return children;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const content = <>{children}</>;

  return (
    // @TODO: Make skeleton doesnt accept children
    // Skeleton are ignored for chromatic to avoid creating visual diffs when animating
    // This will also ignore everything rendered inside the skeleton
    <BaseSkeleton
      colors={colors}
      dataSet={chromaticIgnoreDataSet}
      show={show}
      {...props}
    >
      {content}
    </BaseSkeleton>
  );
}

/**
 * Return the width and height of a skeleton that covers
 * an area of text with the given width and number of lines.
 */
export function useTextSkeletonDimension(
  widthByCharCount: number,
  numberOfLines: number = 1,
  textVariant: keyof Theme['text'] = 'default',
) {
  const { theme } = useDripsyTheme();

  return React.useMemo(() => {
    const textStyle = (theme.text[textVariant] ?? {}) as TextStyle;
    const defaultStyle = theme.text.default;
    return {
      // This width doesn't take account of letter spacing for simplicity
      width: (textStyle.fontSize ?? defaultStyle.fontSize) * widthByCharCount,
      height: (textStyle.lineHeight ?? defaultStyle.lineHeight) * numberOfLines,
    };
  }, [numberOfLines, textVariant, theme.text, widthByCharCount]);
}
