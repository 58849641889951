import { Text } from 'dripsy';
import { FormikProps } from 'formik';
import { useContext } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  Form,
  FormCurrencyInputV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import { FormSpacer } from '../../components/form/FormSpacer';
import { SubmitButton } from '../../components/form/SubmitButton';
import { MaxCharacter } from '../../constants/fieldRules';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Screen } from '../../navigation/types/screens';
import { FormikFormError } from '../../ui/v2/FormError';
import { LoadingInput } from '../../ui/v2/LoadingInput';
import { formatCurrency } from '../../utils/currencyHelpers';
import { NOT_ALLOWED_TRANSACTION_CHARS_REGEX } from '../constants';
import { ExternalAccount, WithdrawalFormField, WithdrawFormV2 } from '../types';
import { UnloanAccountInputRow } from './UnloanAccountInputRow';
import { WithdrawToSection } from './WithdrawToSection';

export const NPP_DESCRIPTION_MAX_LENGTH = 280;

type WithdrawV2FormProps = {
  screen: Screen;
  formProps: FormikProps<WithdrawFormV2>;
  externalAccounts: ExternalAccount[];
  loading?: boolean;
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  availableRedrawAmount: number;
  transferFeatureDisabled?: boolean;
  onSubmitPress: () => void;
};

export function WithdrawV2Form({
  screen,
  formProps,
  externalAccounts,
  loading,
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  availableRedrawAmount,
  transferFeatureDisabled,
  onSubmitPress,
}: WithdrawV2FormProps) {
  const { flags } = useContext(FeatureFlagsContext);
  const isReferenceInputEnabled = flags.ENABLE_TRANSFER_REFERENCE_ID || false;
  const isNPPLongDescriptionEnabled =
    flags.ENABLE_NPP_LONG_DESCRIPTION || false;
  const isNPPDescriptionValidationEnabled =
    flags.ENABLE_NPP_DESCRIPTION_VALIDATION || false;
  const isTransferCentsEnabled = flags.ENABLE_TRANSFER_CENTS || false;

  return (
    <Form overflow="visible">
      <Text variant="sHeader" sx={{ mb: '$8' }}>
        {t('Content.Withdraw.FromSection')}
      </Text>

      <UnloanAccountInputRow
        loading={loading}
        accountName={unloanAccountName}
        bsb={unloanBsb}
        accountNumber={unloanAccountNumber}
      />

      <FormSpacer />

      <WithdrawToSection
        screen={screen}
        loading={loading}
        suggestionData={externalAccounts}
        formProps={formProps}
        transferFeatureDisabled={transferFeatureDisabled}
      />

      <FormSpacer />

      {loading ? (
        <LoadingInput />
      ) : (
        <>
          <FormCurrencyInputV2
            inputTestID={TestID.Withdraw.AmountInput}
            name={WithdrawalFormField.Amount}
            label={t('Content.Withdraw.Amount')}
            disabled={transferFeatureDisabled}
            allowsCents={isTransferCentsEnabled}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Withdrawal,
              screen,
              FieldInteractionKey.Amount,
            )}
          />
          <FormikFormError
            name={WithdrawalFormField.Amount}
            sx={{ mt: '$8' }}
          />
          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.Withdraw.AvailableAmount', {
              amount: formatCurrency(availableRedrawAmount, {
                // If transfer cents is enabled, we want to show the cents
                // even if the amount is a whole number.
                noFraction: !isTransferCentsEnabled,
                withFractionOnRoundedAmount: isTransferCentsEnabled,
              }),
            })}
          </Text>
        </>
      )}

      <FormSpacer />

      {loading ? (
        <LoadingInput />
      ) : (
        <>
          <FormTextInputV2
            inputTestID={TestID.Withdraw.DescriptionInput}
            name={WithdrawalFormField.Description}
            label={t('Content.Withdraw.DescriptionSection')}
            maxLength={
              isNPPLongDescriptionEnabled
                ? NPP_DESCRIPTION_MAX_LENGTH
                : MaxCharacter.general
            }
            alphaNumericOnly={!isNPPDescriptionValidationEnabled}
            escapeEmoji={isNPPDescriptionValidationEnabled}
            escapeCharsBy={
              isNPPDescriptionValidationEnabled
                ? NOT_ALLOWED_TRANSACTION_CHARS_REGEX
                : undefined
            }
            disabled={transferFeatureDisabled}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Withdrawal,
              screen,
              FieldInteractionKey.Description,
            )}
          />
          <FormikFormError
            name={WithdrawalFormField.Description}
            sx={{ mt: '$8' }}
          />
          <Text variant="caption" sx={{ mt: '$8' }}>
            {t('Content.Withdraw.MaxDescriptionLength', {
              maxLength: isNPPLongDescriptionEnabled
                ? NPP_DESCRIPTION_MAX_LENGTH
                : MaxCharacter.general,
            })}
          </Text>
        </>
      )}

      {isReferenceInputEnabled ? (
        <>
          <FormSpacer />
          {loading ? (
            <LoadingInput />
          ) : (
            <>
              <FormTextInputV2
                inputTestID={TestID.Withdraw.ReferenceInput}
                name={WithdrawalFormField.Reference}
                label={t('Content.Withdraw.ReferenceSection')}
                maxLength={MaxCharacter.withdrawalReference}
                disabled={transferFeatureDisabled}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Withdrawal,
                  screen,
                  FieldInteractionKey.Reference,
                )}
              />
              <FormikFormError
                name={WithdrawalFormField.Reference}
                sx={{ mt: '$8' }}
              />
              <Text variant="caption" sx={{ mt: '$8' }}>
                {t('Content.Withdraw.ReferenceSectionLint')}
              </Text>
            </>
          )}
        </>
      ) : null}

      <FormSpacer py="$16" />

      <SubmitButton
        testID={TestID.Withdraw.TransferButton}
        label={t('Content.Withdraw.Transfer')}
        onPress={onSubmitPress}
        sx={{ opacity: loading ? 0 : undefined }}
        disabled={loading || transferFeatureDisabled}
      />
    </Form>
  );
}
