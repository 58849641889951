import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { DeleteIncomeActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import {
  RentalIncomeForm,
  RentalIncomeFormDetails,
  RentalIncomeFormSubmitLabel,
} from '../components/RentalIncomeForm';
import {
  useGetOptionsForRentalIncomeQuery,
  useGetRentalIncomeForV2Form,
  useUpdateRentalIncomeForV2Form,
} from '../graphql/incomeHooks';
import { YourIncomeModalScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeModalScreenProps<Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL>;

export function EditRentalIncomeScreen({ navigation, route }: Props) {
  const { params } = route;

  const { loanApplicationId = '', incomeId = '' } = params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToMainScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  const {
    initialValues,
    loading: initialValuesLoading,
    currentLoanIncomeCount,
  } = useGetRentalIncomeForV2Form({ loanApplicationId, incomeId });
  const {
    frequencyOptions,
    propertyTypeOptions,
    stateOptions,
    streetTypeOptions,
    incomeOwnerOptions,
    loading: rentalIncomeOptionsLoading,
  } = useGetOptionsForRentalIncomeQuery({
    loanApplicationId,
  });
  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  // TODO(uiv2): create a specific v2 mutation
  const { updateExistingRentalIncome, isUpdatingRentalIncome } =
    useUpdateRentalIncomeForV2Form({
      loanApplicationId,
      incomeId,
    });

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [
    deleteIncomeActionSheetErrorMessage,
    setDeleteIncomeActionSheetErrorMessage,
  ] = useRecoilState(DeleteIncomeActionSheetErrorAtom);

  useFocusEffect(() => () => setDeleteIncomeActionSheetErrorMessage(null));

  const onSubmit = async (formValues: RentalIncomeFormDetails) => {
    setDeleteIncomeActionSheetErrorMessage(null);

    const { success, errorMessage } = await updateExistingRentalIncome(
      formValues,
    );
    if (!success) {
      setFormErrorMessage(errorMessage);
      return;
    }

    navigateBackToMainScreen();
  };

  const onDelete = async () => {
    if (!incomeId) {
      return;
    }
    navigation.navigate(ActionSheetType.DELETE_INCOME_ACTION_SHEET, {
      loanApplicationId: loanApplicationId ?? '',
      incomeId,
      ...route.params,
    });
  };

  const showLoading = rentalIncomeOptionsLoading || initialValuesLoading;

  // We're only allowing income deletion if there're more than one income
  const showDeleteButton = currentLoanIncomeCount > 1;

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.RentalIncome')}
      scrollable
      loading={isUpdatingRentalIncome}
      onClose={() => {
        navigateBackToMainScreen();
      }}
      hideBackButton
    >
      {showLoading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.RentalIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage || deleteIncomeActionSheetErrorMessage}
            testID={TestID.RentalIncomeForm.ErrorText}
          />
          <RentalIncomeForm
            screen={Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL}
            initialValues={initialValues}
            isSubmitting={isUpdatingRentalIncome}
            onSubmit={onSubmit}
            frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
            propertyOwnerOptions={incomeOwnerOptions}
            propertyTypeOptions={propertyTypeOptions}
            propertySuggestionData={propertySuggestionData}
            streetTypeOptions={streetTypeOptions}
            stateOptions={stateOptions}
            loadSuggestionData={loadSuggestionData}
            isLoadingSuggestionData={isLoadingSuggestionData}
            submitLabel={RentalIncomeFormSubmitLabel.Done}
            onRentalExpenseLearnMoreClick={() => {
              navigation.navigate(Screen.SINGLE_V2_MODAL, {
                screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
              });
            }}
            showDeleteButton={showDeleteButton}
            onDelete={onDelete}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
