import { useRoute } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';

import {
  ApplicantStatusInfoFragment,
  Loan_Application_Type_Enum,
} from '../../generated/graphql';
import { isWeb } from '../../utils/platformUtils';
import {
  ApplicantRoute,
  ApplicantState,
  openPortalUrl,
  useApplicantStates,
} from '../utils/useApplicantStates';
import { useHomeNavigation } from '../utils/useHomeNavigation';
import { useNativeAppApplicationNavigation } from '../utils/useNativeAppApplicationNavigation';
import { PreDisbursementApplicationCard } from './PreDisbursementApplicationCard';

export type DashboardLoanCardProps = {
  appliedLoan: ApplicantStatusInfoFragment;
};

function getTitleByLoanType(loanApplicationType: Loan_Application_Type_Enum) {
  let loanTitle: string;

  switch (loanApplicationType) {
    case Loan_Application_Type_Enum.Purchase:
      loanTitle = t('Content.Common.LoanApplicationType.Purchase');
      break;
    case Loan_Application_Type_Enum.Refinance:
      loanTitle = t('Content.Common.LoanApplicationType.Refinance');
      break;
    case Loan_Application_Type_Enum.Variation:
      loanTitle = t('Content.Common.LoanApplicationType.Variation');
      break;
    case Loan_Application_Type_Enum.TopUp:
      loanTitle = t('Content.Common.LoanApplicationType.TopUp');
      break;
    default:
      loanTitle = t('Content.Common.Word.Unknown');
      break;
  }

  return loanTitle.toUpperCase();
}

export function DashboardLoanCard({ appliedLoan }: DashboardLoanCardProps) {
  const loanApplicationId = appliedLoan.loanApplication.id;
  const navigation = useHomeNavigation();
  const route = useRoute<ApplicantRoute>();

  const {
    action,
    applicantState,
    applicationTrackingStage,
    caption,
    shortAddressFormat,
    showFmsPortal,
    portalUrl,
    applicantStateLoading,
  } = useApplicantStates(navigation, route, appliedLoan);

  const loanTypeHeaderTitle = getTitleByLoanType(
    appliedLoan.loanApplication.type,
  );

  const headerTitle = shortAddressFormat ?? loanTypeHeaderTitle;

  const { onApplicationTracking } = useNativeAppApplicationNavigation();

  const onApplicationTrackingCardPress = useCallback(() => {
    onApplicationTracking(loanApplicationId);
  }, [onApplicationTracking, loanApplicationId]);

  // Native: Application tracking can be used as the generic screen for all application states
  const onActionPress = useMemo(
    () => (isWeb ? action?.onPress : onApplicationTrackingCardPress),
    [action?.onPress, onApplicationTrackingCardPress],
  );

  // Settlement is executed. Funds disbursed. Loan account is now live.
  // Settled loan application will be handled separately
  // using loan_accounts instead of loan_application query
  if (applicantState === ApplicantState.Disbursed) {
    return null;
  }

  // Right now we show nothing when the application is declined
  if (applicantState === ApplicantState.Declined) {
    return null;
  }

  const onViewDocumentsPress = showFmsPortal
    ? {
        link: t('Content.ApplicationTracking.ViewDocuments'),
        onLinkPress: () =>
          openPortalUrl(applicantState, loanApplicationId, portalUrl),
      }
    : null;

  const isTopUp =
    appliedLoan.loanApplication.type === Loan_Application_Type_Enum.TopUp;

  return (
    <PreDisbursementApplicationCard
      sx={{ mb: '$24' }}
      applicantStateLoading={applicantStateLoading}
      applicationTrackingStage={applicationTrackingStage}
      actionLabel={action?.actionLabelShort}
      caption={caption || ''}
      headerTitle={headerTitle}
      isTopUp={isTopUp}
      onActionPress={onActionPress}
      onCardPress={onApplicationTrackingCardPress}
      testID={`applicationCard-${loanApplicationId}`}
      actionButtonTestID={`applicationActionButton-${loanApplicationId}`}
      {...onViewDocumentsPress}
    />
  );
}
