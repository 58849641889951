import {
  addMinutes,
  differenceInCalendarDays,
  format,
  millisecondsInSecond,
  minutesInHour,
} from 'date-fns';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';

import { DATE_FORMAT_DAY_MONTH } from '../../constants/dateFormats';
import { AustralianTimezones } from '../../utils/dateHelpers';
import { pluralize } from '../../utils/stringHelpers';

const getNextAutopayDifferenceInDays = (nextPaymentDate: string) => {
  const sydneyTimezoneOffsetInMinutes =
    -getTimezoneOffset(
      AustralianTimezones.SYDNEY,
      zonedTimeToUtc(nextPaymentDate, AustralianTimezones.SYDNEY),
    ) /
    minutesInHour /
    millisecondsInSecond;

  const localDateTimezoneOffsetInMinutes = new Date(
    zonedTimeToUtc(nextPaymentDate, AustralianTimezones.SYDNEY),
  ).getTimezoneOffset();
  const timeDifferenceMinutes =
    localDateTimezoneOffsetInMinutes - sydneyTimezoneOffsetInMinutes;

  // add the time difference so that all geo-located user will see persistent Sydney timezone payment date
  const nextRepaymentDateInUTC = addMinutes(
    zonedTimeToUtc(nextPaymentDate, AustralianTimezones.SYDNEY),
    timeDifferenceMinutes,
  );

  const differenceInDays = differenceInCalendarDays(
    nextRepaymentDateInUTC.getTime(),
    new Date().getTime(),
  );

  return {
    date: nextRepaymentDateInUTC,
    differenceInDays,
  };
};

export const formatNextAutopayDate = (
  nextPaymentDate: string | undefined | null,
): string => {
  if (!nextPaymentDate) {
    return '';
  }

  const { date, differenceInDays } =
    getNextAutopayDifferenceInDays(nextPaymentDate);

  if (differenceInDays < 0) {
    return '';
  }

  if (differenceInDays === 0) {
    return 'Today';
  }

  if (differenceInDays <= 6) {
    return `in ${pluralize(differenceInDays, 'day')}`;
  }

  return format(date, DATE_FORMAT_DAY_MONTH);
};

export const formatNextAutopayDateForLoanDetails = (
  nextPaymentDate: string | undefined | null,
): string => {
  if (!nextPaymentDate) {
    return '';
  }

  const { date, differenceInDays } =
    getNextAutopayDifferenceInDays(nextPaymentDate);

  if (differenceInDays < 0) {
    return '';
  }

  return `${format(date, DATE_FORMAT_DAY_MONTH)} (${
    differenceInDays === 0 ? 'Today' : pluralize(differenceInDays, 'day')
  })`;
};
