import { gql, NetworkStatus } from '@apollo/client';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { TestID } from '../../../testID/constants';
import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { getInstitutionConnectionName } from '../../ConnectedBanks/utils/getInstitutionConnectionName';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Applicant_Income_Verification_Method_Input_Enum,
  BankProviderFragmentDoc,
  GetConnectedInstitutionsForVerifyIncomeQuery,
  MyConnectedBankFragment,
  refetchGetLoanApplicationTasksQuery,
  useGetConnectedInstitutionsForVerifyIncomeQuery,
  useSetApplicantIncomeVerificationMethodMutation,
} from '../../generated/graphql';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import {
  PageGTMTitleWithStates,
  PageStateForList,
  Screen,
} from '../../navigation/types/screens';
import { fastlinkEmitter } from '../../SelectInstitution/utils/fastlinkEmitter';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { Spinner } from '../../ui/atoms/Spinner';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { isLast, isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useMyYodleeFastlinkRefreshSubscription } from '../../utils/hooks/useMyYodleeFastlinkRefreshSubscription';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { useThrottleRefetch } from '../../utils/hooks/useThrottleRefetch';
import {
  ConnectedBankRow,
  ConnectedBankRowProps,
} from '../components/ConnectedBankRow';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB>;

export const GetConnectedInstitutionsQuery = gql`
  query GetConnectedInstitutionsForVerifyIncome($loanApplicationId: uuid!) {
    me {
      user {
        id
      }
    }

    getAllInstitutionConnections {
      ...MyConnectedBank
    }

    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
    }
  }

  fragment MyConnectedBank on InstitutionConnection {
    id
    updateEligibilityBasicAgg
    loginStatus
    ...ActiveAccounts
    institution {
      ...BankProvider
    }
    profiles {
      name {
        fullName
        first
      }
    }
  }

  fragment ActiveAccounts on InstitutionConnection {
    activeAccounts {
      id
      accountName
      accountNumber
      accountType
    }
  }

  ${BankProviderFragmentDoc}
`;

export const SetApplicantIncomeVerificationMethod = gql`
  mutation SetApplicantIncomeVerificationMethod(
    $loanApplicationId: uuid!
    $method: applicant_income_verification_method_input_enum!
  ) {
    set_applicant_income_verification_method(
      loan_application_id: $loanApplicationId
      method: $method
    ) {
      applicant_id
    }
  }
`;

function mapMyConnectedBankToConnectedBankRowProps(
  connectedBank: Omit<MyConnectedBankFragment, 'id'> & { id: number },
): Omit<ConnectedBankRowProps, 'navigateToConsentDetails'> {
  return {
    bankName: connectedBank.institution?.name || '--',
    bankConnectionOwnerName: getInstitutionConnectionName(
      connectedBank.profiles || [],
    ),
    bankAccounts:
      connectedBank.activeAccounts?.map((account) => ({
        accountName: account.accountName || account.accountType || '--',
        last4AccountNumber: account.accountNumber?.slice(-4) || '--',
      })) ?? [],
    bankLogoUrl: connectedBank.institution?.favicon,
    providerAccountId: connectedBank.id,
    loginStatus: connectedBank.loginStatus,
    updateEligibilityBasicAgg: connectedBank.updateEligibilityBasicAgg,
  };
}

function getNonNullConnectedInsitutionsFromQueryData(
  data?: GetConnectedInstitutionsForVerifyIncomeQuery,
) {
  return data?.getAllInstitutionConnections?.filter(isNotNullOrUndefined);
}

function EmptyState(props: BoxProps) {
  return (
    <Box flex={1} alignItems="center" {...props}>
      <EmptyStateIllustration name="bank" />
    </Box>
  );
}

function Header() {
  return (
    <LoanScreenHeader
      title={t('Content.VerifyIncomeOpenBanking.Title')}
      caption={t('Content.VerifyIncomeOpenBanking.Caption')}
    />
  );
}

function VerifyIncomeOpenBankingV2Base({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId || '';
  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const prevConnectedBanksCount = useRef<null | number>(null);

  const [
    executeSetApplicantIncomeVerificationMethodMutation,
    { loading: setApplicantIncomeVerificationMethodLoading },
  ] = useSetApplicantIncomeVerificationMethodMutation();

  const { flags } = useContext(FeatureFlagsContext);
  const enableOpenBankingDataRecipient =
    flags.ENABLE_OPEN_BANKING_DATA_RECIPIENT;

  const setApplicantIncomeVerificationMethod = (
    method: Applicant_Income_Verification_Method_Input_Enum,
  ) =>
    safelyCallMutation(executeSetApplicantIncomeVerificationMethodMutation, {
      variables: {
        loanApplicationId,
        method,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchGetLoanApplicationTasksQuery({ loanApplicationId }),
      ],
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

  const navigateToBankLogin = useCallback(
    () =>
      navigation.navigate(Screen.SELECT_INSTITUTION_MODAL, {
        screen: Screen.BANK_LOGIN,
        params: { loanApplicationId },
      }),
    [navigation, loanApplicationId],
  );

  const navigateToLearnOpenBankingModal = useCallback(
    () => navigation.navigate(Screen.LEARN_OPEN_BANKING_MODAL),
    [navigation],
  );

  const { sendVirtualPageViewEventToGTM, sendAppInteractionEventToGTM } =
    useSendDataToGTM();

  const sendDataToGTM = useCallback(
    (data: GetConnectedInstitutionsForVerifyIncomeQuery) => {
      const connectedBanksCount =
        getNonNullConnectedInsitutionsFromQueryData(data)?.length ?? 0;

      if (prevConnectedBanksCount.current === connectedBanksCount) {
        return;
      }
      const isFirstTimeFetching = prevConnectedBanksCount.current === null;

      // Send virtual_page_view event when screen loads for the
      // first time and on every screen-state changes
      // either from empty to has data, or the other way around
      if (
        prevConnectedBanksCount.current === 0 ||
        connectedBanksCount === 0 ||
        isFirstTimeFetching
      ) {
        let pageState: PageStateForList = PageStateForList.Empty;
        if (connectedBanksCount !== 0) {
          pageState = PageStateForList.HasData;
        }

        prevConnectedBanksCount.current = connectedBanksCount;
        sendVirtualPageViewEventToGTM({
          virtualPageName:
            PageGTMTitleWithStates[
              Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB
            ]?.[pageState],
          additionalData: {
            current_application_id: loanApplicationId,
            item_list_count: connectedBanksCount,
          },
        });
        return;
      }

      // Send app_interaction event for every additional bank connections
      // in other words, when itemListCount is more than 1.
      prevConnectedBanksCount.current = connectedBanksCount;
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.AddBankConnection,
        additionalData: {
          current_application_id: loanApplicationId,
          connected_bank_list_count: connectedBanksCount,
        },
      });
    },
    [
      loanApplicationId,
      sendAppInteractionEventToGTM,
      sendVirtualPageViewEventToGTM,
    ],
  );

  const { loading, data, error, refetch, networkStatus } =
    useGetConnectedInstitutionsForVerifyIncomeQuery({
      notifyOnNetworkStatusChange: true,
      skip: !enableOpenBankingDataRecipient,
      variables: { loanApplicationId },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
      onCompleted: sendDataToGTM,
    });

  const refetchConnectedInstitutions = useThrottleRefetch(refetch);

  useEffect(() => {
    fastlinkEmitter.on('completed', async () => {
      await refetchConnectedInstitutions.throttled();
    });
    fastlinkEmitter.on('failed', async () => {
      await refetchConnectedInstitutions.throttled();
    });
    return () => {
      // Need to specify each listeners separately
      // Tried using wildcard * but the listeners
      // are not removed
      fastlinkEmitter.off('completed');
      fastlinkEmitter.off('failed');
    };
  }, [refetchConnectedInstitutions]);

  const myUserId = data?.me[0]?.user?.id;
  useMyYodleeFastlinkRefreshSubscription({
    myUserId,
    onYodleeDataRefresh: async () => {
      await refetchConnectedInstitutions.throttled();
    },
  });

  const connectedInstitutions =
    getNonNullConnectedInsitutionsFromQueryData(data);
  const hasConnectedInstitutions = !!connectedInstitutions?.length;

  const navigateToFinaliseYourApplication = useCallback(
    () =>
      navigateToNextLoanApplicationScreen({
        currentSection: LoanApplicationSection.VerifyIncomeOpenBanking,
      }),
    [navigateToNextLoanApplicationScreen],
  );

  const onContinuePress = async () => {
    // Waiting for mutation to finish because task list in
    // Finalise Your Application is retrieved based on the
    // income verification method value
    await setApplicantIncomeVerificationMethod(
      Applicant_Income_Verification_Method_Input_Enum.OpenBanking,
    );
    navigateToFinaliseYourApplication();
  };

  const onUploadDocsManuallyPress = async () => {
    // Waiting for mutation to finish because task list in
    // Finalise Your Application is retrieved based on the
    // income verification method value
    await setApplicantIncomeVerificationMethod(
      Applicant_Income_Verification_Method_Input_Enum.ManualUpload,
    );
    navigateToFinaliseYourApplication();
  };

  const primaryButton = hasConnectedInstitutions
    ? {
        primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
        onPrimaryButtonPress: onContinuePress,
      }
    : {
        primaryButtonLabel: t(
          'Content.VerifyIncomeOpenBanking.ConnectYourBank',
        ),
        onPrimaryButtonPress: navigateToBankLogin,
      };

  const secondaryButton =
    hasConnectedInstitutions || loading
      ? {}
      : {
          secondaryButtonLabel: t(
            'Content.VerifyIncomeOpenBanking.ManuallyUploadDocument',
          ),
          onSecondaryButtonPress: onUploadDocsManuallyPress,
        };
  const isRefetch = networkStatus === NetworkStatus.refetch;

  // If this is loading and not caused by a `refetch`, display screen loading
  if (loading && !isRefetch) {
    return (
      <LoanApplicationScreenContainer>
        <ScrollContainer>
          <NavHeaderSpacer />
          <Header />
          <Box flex={1} alignItems="center" justifyContent="center" my="l">
            <Spinner
              size="large"
              testID={TestID.VerifyIncomeOpenBanking.Spinner}
            />
          </Box>
        </ScrollContainer>
      </LoanApplicationScreenContainer>
    );
  }

  // Show loading screen while waiting for the mutation to finish
  // before navigating to next screen
  if (setApplicantIncomeVerificationMethodLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoadingState description={t('Content.Common.Placeholder.Loading')} />
      </LoanApplicationScreenContainer>
    );
  }

  if (!enableOpenBankingDataRecipient) {
    return <NotFoundScreen />;
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.PropertyCheck.Error.ScreenInitialQuery')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: data?.loan_application_by_pk,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const onPressConnectedBankCard = (connectionId: number) =>
    navigation.navigate(Screen.SELECT_INSTITUTION_MODAL, {
      screen: Screen.MANAGE_CONSENTS,
      params: { providerAccountId: connectionId },
    });

  // This indicates if query is loading and caused by a refetch
  const isRefetching = isRefetch && loading;
  return (
    <LoanApplicationScreenContainer>
      <ScrollContainer>
        <NavHeaderSpacer />
        <Header />

        <Box>
          {!hasConnectedInstitutions ? (
            <EmptyState my="xl" />
          ) : (
            <Box px="m" mt="xl" mb="m">
              {isRefetching ? (
                <Box my="m">
                  <Spinner />
                </Box>
              ) : null}
              {connectedInstitutions.map((connection, index) => {
                if (connection.id == null) {
                  return null;
                }
                const connectionId = connection.id;
                return (
                  <ConnectedBankRow
                    {...mapMyConnectedBankToConnectedBankRowProps({
                      ...connection,
                      id: connectionId,
                    })}
                    key={connectionId}
                    navigateToConsentDetails={() =>
                      onPressConnectedBankCard(connectionId)
                    }
                    mb={isLast(connectedInstitutions, index) ? undefined : 's'}
                    mt={0}
                  />
                );
              })}
              <Button
                fontWeight="normal"
                label={t('Content.VerifyIncomeOpenBanking.ConnectAnotherBank')}
                icon="add"
                tertiary
                onPress={navigateToBankLogin}
              />
            </Box>
          )}
        </Box>
        <LoanApplicationWizardFooter
          footerCaption={
            connectedInstitutions?.length
              ? t(
                  'Content.VerifyIncomeOpenBanking.MakeSureYouHaveConnectedAllAccounts',
                )
              : t('Content.VerifyIncomeOpenBanking.OpenBankingFooterConsent')
          }
          footerCaptionLink={t('Content.Common.ButtonLabel.LearnMore')}
          onLinkPress={navigateToLearnOpenBankingModal}
          isPrimaryButtonLoading={loading}
          primaryButtonTestID={TestID.VerifyIncomeOpenBanking.ConnectYourBank}
          {...primaryButton}
          secondaryButtonTestID={
            TestID.VerifyIncomeOpenBanking.UploadDocumentManual
          }
          {...secondaryButton}
        />
      </ScrollContainer>
    </LoanApplicationScreenContainer>
  );
}

export const VerifyIncomeOpenBankingV2 = withAuthenticationRequired(
  VerifyIncomeOpenBankingV2Base,
);
