import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { StatementsV2ModalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { StatementPreviewV2 } from '../screens/StatementPreviewV2';
import { StatementsFilterV2 } from '../screens/StatementsFilterV2';

const StatementsModalStack =
  createStackNavigator<StatementsV2ModalStackParams>();

export const StatementsModalNavigatorV2: React.FC = () => (
  <StatementsModalStack.Navigator
    screenOptions={DefaultModalV2NavigatorOptions}
  >
    <StatementsModalStack.Screen
      name={Screen.STATEMENTS_PREVIEW_V2_MODAL}
      component={StatementPreviewV2}
      options={{
        title: getPageTitle(Screen.STATEMENTS_PREVIEW_V2_MODAL),
      }}
    />
    <StatementsModalStack.Screen
      name={Screen.STATEMENTS_FILTER_V2_MODAL}
      component={StatementsFilterV2}
      options={{
        title: getPageTitle(Screen.STATEMENTS_FILTER_V2_MODAL),
      }}
    />
  </StatementsModalStack.Navigator>
);
