import { useCallback, useState } from 'react';
import * as yup from 'yup';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { FormTextInputV2 } from '../../components/form/FormikInputs';
import { useCreateEmailChangeRequestMutation } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { FormikFormError } from '../../ui/v2/FormError';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { PersonalDetailsModalForm } from '../components/PersonalDetailsModalForm';
import { PersonalDetailsModalScreenProps } from '../navigation/types';

const NEW_EMAIL_FIELD = 'newEmail';

const INIT_VALS = {
  [NEW_EMAIL_FIELD]: '',
};

const validationSchema = yup.object({
  [NEW_EMAIL_FIELD]: yup
    .string()
    .email(t('Content.PersonalDetails.ChangeEmail.InvalidEmailAddress'))
    .required(t('Content.PersonalDetails.ChangeEmail.NewEmailRequiredLabel')),
});

type Props =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL>;

export function EmailChangeNewEmail({ navigation, route }: Props) {
  const { auth0MfaSessionId } = route.params;

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    createEmailChangeRequest,
    {
      loading: createEmailChangeRequestLoading,
      error: createEmailChangeRequestError,
    },
  ] = useCreateEmailChangeRequestMutation();

  const onSubmit = useCallback(
    async ({ newEmail }: { newEmail: string }) => {
      setErrorMessage(undefined);
      const [response] = await safelyCallMutation(createEmailChangeRequest, {
        variables: {
          auth0_mfa_session_id: auth0MfaSessionId,
          new_email: newEmail,
        },
        context: {
          sentryContext: {
            description: 'Email Change Request - Create Change Request',
          },
        },
      });

      const errorType = response?.data?.create_email_change_request?.error_type;

      if (errorType) {
        // Should always be a system error
        const message = t(
          'Content.PersonalDetails.ChangeEmail.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      const emailChangeRequestId =
        response?.data?.create_email_change_request?.email_change_request_id;

      if (!emailChangeRequestId) {
        const message = t(
          'Content.PersonalDetails.ChangeEmail.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(Screen.PERSONAL_DETAILS_MODAL, {
        screen: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY,
        params: {
          auth0MfaSessionId,
          emailChangeRequestId,
          newEmail,
        },
      });
    },
    [createEmailChangeRequest, auth0MfaSessionId, navigation, setErrorMessage],
  );

  return (
    <PersonalDetailsModalForm
      title={t('Content.PersonalDetails.ChangeEmail.NewEmailLabel')}
      initialValues={INIT_VALS}
      onSubmit={onSubmit}
      showSpinner={createEmailChangeRequestLoading}
      validationSchema={validationSchema}
      navigation={navigation}
      submitLabel={t('Content.Common.ButtonLabel.Continue')}
      errorMessage={
        createEmailChangeRequestError
          ? t('Content.PersonalDetails.ChangeEmail.UnableToProcessRequest')
          : errorMessage
      }
    >
      {() => (
        <>
          <FormTextInputV2
            inputTestID={TestID.PersonalDetails.ChangeEmailNewEmailInput}
            name={NEW_EMAIL_FIELD}
            label={t('Content.PersonalDetails.ChangeEmail.NewEmailPlaceholder')}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.PersonalDetails,
              Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL,
              FieldInteractionKey.NewEmailAddress,
            )}
          />

          <FormikFormError name={NEW_EMAIL_FIELD} sx={{ mt: '$8' }} />
        </>
      )}
    </PersonalDetailsModalForm>
  );
}
