import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const AutopayRefinanceIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => {
  const width = resizeIconWidth(45, 45, size);

  return (
    <Svg
      width={width}
      height={width}
      viewBox="0 0 45 45"
      fill="none"
      {...props}
    >
      <Path
        fill="url(#a)"
        fillRule="evenodd"
        d="M6.517 19.688v20.625c0 .951.697.945 1.6.938l.246-.001h29.525l.244.001c.904.008 1.6.013 1.6-.938V19.687L23.126 3.75 6.517 19.688Z"
        clipRule="evenodd"
      />
      <Path
        fill="#2C2C2C"
        d="M23.126 4.688c-.438 0-.875.157-1.224.471l-4.944 4.51-10.44 10.018v5.054L23.126 9.776l16.607 14.965v-5.053L29.293 9.668 24.35 5.16a1.824 1.824 0 0 0-1.223-.471Z"
        opacity={0.05}
      />
      <Path
        fill="#A3A3A3"
        d="M41.879 19.225 24.192 3.285a1.824 1.824 0 0 0-2.447 0L4.06 19.224a.947.947 0 0 0-.06 1.34l1.51 1.637c.34.37.908.397 1.283.063L22.969 7.9l16.177 14.365a.913.913 0 0 0 1.282-.063l1.51-1.636a.948.948 0 0 0-.06-1.342Z"
      />
      <Path
        fill="#2C2C2C"
        d="m30.555 25.341.21-.113c.427-.23.629-.752.447-1.182-1.69-3.993-6.148-6.012-10.49-4.545-2.855.965-5.004 3.214-5.974 5.883l2.5.762c.686-1.854 2.184-3.414 4.171-4.085 3.06-1.035 6.287.367 8.014 3.017.24.367.719.48 1.122.263ZM15.587 29.257l-.214.109a.93.93 0 0 0-.417 1.212c1.925 4.231 6.65 6.524 11.113 5.179 2.935-.884 5.08-3.136 5.982-5.875l-2.636-.9c-.638 1.902-2.134 3.464-4.177 4.079-3.145.948-6.563-.65-8.472-3.482a.92.92 0 0 0-1.18-.322Z"
      />
      <Path
        fill="#2C2C2C"
        d="M18.795 22.955a6.113 6.113 0 0 0-1.387 2.21l1.14.38a.388.388 0 0 1 .055.71l-3.019 1.555a.535.535 0 0 1-.724-.241l-1.541-3.074c-.154-.306.133-.646.454-.54l1.178.393c1.181-3.388 4.357-5.813 8.1-5.806a8.553 8.553 0 0 1 6.097 2.576s-6.096-2.488-10.353 1.837Zm14.022 7.141-1.542-3.073a.536.536 0 0 0-.724-.241l-3.019 1.555a.388.388 0 0 0 .056.71l1.14.379a6.113 6.113 0 0 1-1.388 2.21c-4.256 4.326-10.353 1.838-10.353 1.838a8.552 8.552 0 0 0 6.097 2.576c3.744.007 6.919-2.418 8.1-5.806l1.178.392c.322.107.608-.233.455-.54Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={6.517}
          x2={43.745}
          y1={3.75}
          y2={36.722}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
