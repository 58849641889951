import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { BankAccountDetails } from '../../ConnectedBanks/components/BankAccountDetails';
import { Screen } from '../../navigation/types/screens';
import { SettingsScreenProps } from '../navigation/types';

type Props = SettingsScreenProps<Screen.SETTINGS_BANK_ACCOUNT_DETAIL>;
function SettingsBankAccountDetailsBase({ route }: Props) {
  return <BankAccountDetails {...route.params} />;
}

export const WithAuthenticationSettingsBankAccountDetails =
  withAuthenticationRequired(SettingsBankAccountDetailsBase);
