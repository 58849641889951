import { Text } from 'dripsy';
import { Formik } from 'formik';
import { useState } from 'react';

import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { FormCurrencyInputV2 } from '../../components/form/FormikInputs';
import { AppAddressFormat } from '../../components/form/types';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  refetchLoanApplicationSecuritiesQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  UpsertLoanApplicationSecurityMutationVariables,
  useUpsertLoanApplicationSecurityMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { FormikFormError } from '../../ui/v2/FormError';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import { yup } from '../../utils/yup';
import { useGetInitialFormValues } from '../hooks/useGetInitialFormValues';
import {
  PropertyDetailsFormFields,
  propertyDetailsManualInputFieldNames,
  propertyValueYupSchema,
} from '../utils/propertyDetailsFormUtils';
import { getUpsertApplicationSecurityErrorMessage } from '../utils/propertyDetailsUtils';

const validationSchema = yup.object({
  [PropertyDetailsFormFields.PropertyValue]: propertyValueYupSchema,
});

type BaseTopUpPropertyValueModalProps = {
  propertyValue?: number;
  onSubmit: ({ propertyValue }: { propertyValue?: number }) => void;
  submitLoading?: boolean;
  formErrorMessage?: string;
};

function BaseTopUpPropertyValueModal({
  propertyValue,
  onSubmit,
  submitLoading,
  formErrorMessage,
}: BaseTopUpPropertyValueModalProps) {
  return (
    <>
      <ErrorRow mb="l" message={formErrorMessage} />

      <Text variant="body">{t('Content.TopUpPropertyValue.Caption')}</Text>

      <Formik
        initialValues={{ propertyValue }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <>
            <FormCurrencyInputV2
              name={PropertyDetailsFormFields.PropertyValue}
              label={t(
                'Content.PropertyDetails.Caption.EstimatedPropertyValue',
              )}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Property,
                Screen.TOP_UP_PROPERTY_VALUE_MODAL,
                FieldInteractionKey.EstimatedPropertyValue,
              )}
            />

            <FormikFormError
              name={PropertyDetailsFormFields.PropertyValue}
              sx={{ mt: '$8' }}
            />

            <Text variant="caption" sx={{ mt: '$8' }}>
              {t(
                'Content.PropertyDetails.Caption.TopUpsEstimatedPropertyValueSupportingText',
              )}
            </Text>

            <Button
              label={t('Content.Common.ButtonLabel.Confirm')}
              onPress={() => {
                formProps.handleSubmit();
              }}
              sx={{ width: '100%', mt: '$32' }}
              showSpinner={submitLoading}
              disabled={submitLoading}
            />
          </>
        )}
      </Formik>
    </>
  );
}

export type Props =
  SingleModalStackScreenProps<Screen.TOP_UP_PROPERTY_VALUE_MODAL>;

export function TopUpPropertyValueModal({ navigation, route }: Props) {
  const { loanApplicationId, loanApplicationSecurityId, _initialErrorMessage } =
    route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const [formErrorMessage, setFormErrorMessage] = useState(
    _initialErrorMessage ?? '',
  );

  const {
    initialFormValues: {
      propertyValue,
      propertyType,
      addressResult,
      propertyPurpose,
      addressId,
      ...manualAddressProps
    },
    loanApplicationTargetId,
    loading: initialFormLoading,
  } = useGetInitialFormValues({
    loanApplicationId,
    loanApplicationSecurityId,
  });

  const [
    upsertLoanApplicationSecurity,
    { loading: upsertLoanApplicationSecurityLoading },
  ] = useUpsertLoanApplicationSecurityMutation();

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToPropertyScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Property,
      });
    });
  };

  const onSubmit = async ({
    propertyValue: newPropertyValue,
  }: {
    propertyValue?: number;
  }) => {
    const isManualInput =
      manualAddressProps[propertyDetailsManualInputFieldNames.isManualInput];
    let addressToSubmit: AppAddressFormat | null = null;
    if (isManualInput) {
      addressToSubmit = {
        isProvidedByUser: true,
        postcode:
          manualAddressProps[propertyDetailsManualInputFieldNames.postcode],
        street:
          manualAddressProps[propertyDetailsManualInputFieldNames.streetName],
        streetNo:
          manualAddressProps[propertyDetailsManualInputFieldNames.streetNo],
        unitNo: manualAddressProps[propertyDetailsManualInputFieldNames.unitNo],
        state: manualAddressProps[propertyDetailsManualInputFieldNames.state],
        streetType:
          manualAddressProps[propertyDetailsManualInputFieldNames.streetType]
            ?.value,
        suburb: manualAddressProps[propertyDetailsManualInputFieldNames.suburb],
      };
    } else {
      addressToSubmit = addressResult ?? null;
    }

    if (
      !loanApplicationId ||
      !addressToSubmit ||
      !propertyType ||
      !propertyPurpose ||
      !newPropertyValue
    ) {
      setFormErrorMessage(
        t('Content.PropertyDetails.Error.FailSubmittingForm'),
      );
      return;
    }

    const {
      manualAddressInput: validatedManualAddressInput,
      domainApiAddressInput: validatedDomainApiAddressInput,
    } = parseAddressInputForMutationAction(addressToSubmit);

    const propertyVariables: Pick<
      UpsertLoanApplicationSecurityMutationVariables,
      'manualAddressInput' | 'domainApiAddressInput'
    > = {
      domainApiAddressInput: validatedDomainApiAddressInput,
      manualAddressInput: validatedManualAddressInput,
    };

    const variables: UpsertLoanApplicationSecurityMutationVariables = {
      loanApplicationSecurityId,
      loanApplicationTargetId,
      loanApplicationId,
      propertyType,
      propertyPurpose,
      propertyValue: newPropertyValue,
      ...propertyVariables,
      isCreate: !loanApplicationSecurityId,
      addressId,
    };

    const [res] = await safelyCallMutation(upsertLoanApplicationSecurity, {
      variables,
      refetchQueries: [
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: { variables, loanApplicationId },
      },
    });

    const errMessage = getUpsertApplicationSecurityErrorMessage(res);

    if (errMessage != null) {
      setFormErrorMessage(errMessage);
    }

    navigateBackToPropertyScreen();
  };

  return (
    <ModalScreenContainer
      headerText={t('Content.PropertyDetails.Header.Title')}
      scrollable
      hideBackButton
      onClose={navigateBackToPropertyScreen}
      loading={upsertLoanApplicationSecurityLoading}
    >
      {initialFormLoading ? (
        <ScreenLoadingContainer loading />
      ) : (
        <BaseTopUpPropertyValueModal
          propertyValue={propertyValue || undefined}
          onSubmit={onSubmit}
          submitLoading={upsertLoanApplicationSecurityLoading}
          formErrorMessage={formErrorMessage}
        />
      )}
    </ModalScreenContainer>
  );
}
