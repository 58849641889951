import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { useUser } from '../../Auth/hooks';
import { newExternalAccountAtom } from '../../ExternalAccount/atoms/newExternalAccountAtom';
import {
  AddExternalAccountFor,
  useAddExternalAccount,
} from '../../ExternalAccount/utils/useAddExternalAccount';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { StyledText } from '../../ui/atoms/StyledText';
import { ActionSheet, Props } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export function BaseAddDirectDebitAccountConfirmationActionSheet({
  closeModal,
  preventCloseModal,
  actionList,
}: Pick<Props, 'actionList' | 'preventCloseModal' | 'closeModal'>) {
  const { user, loading } = useUser();

  return (
    <ActionSheet
      closeModal={closeModal}
      preventCloseModal={preventCloseModal || loading}
      title={t('Content.Withdraw.AccountAuthority')}
      actionList={actionList}
    >
      <Box p="m">
        <Skeleton show={loading}>
          <StyledText textAlign="center">
            {t('Content.Withdraw.AccountAuthorityContent', {
              name: user?.identity_profile?.full_name || '--',
            })}
          </StyledText>
        </Skeleton>
      </Box>
    </ActionSheet>
  );
}

type AddDirectDebitAccountConfirmationActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION>;

export function AddDirectDebitAccountConfirmationActionSheet({
  navigation,
  route,
}: AddDirectDebitAccountConfirmationActionSheetProps) {
  const newExternalAccount = useRecoilValue(newExternalAccountAtom);

  const cbaAccountId = route.params?.cbaAccountId || '';

  const { addExternalAccount, addExternalAccountLoading } =
    useAddExternalAccount({
      cbaAccountId,
      accountFor: AddExternalAccountFor.AutoPay,
    });

  return (
    <BaseAddDirectDebitAccountConfirmationActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={addExternalAccountLoading}
      actionList={[
        {
          label: t('Content.Withdraw.AccountAuthorityConfirm'),
          secondary: true,
          testID:
            TestID.AddDirectDebitAccountConfirmationActionSheet.ConfirmButton,
          showSpinner: addExternalAccountLoading,
          disabled: addExternalAccountLoading,
          onPress: async () => {
            if (newExternalAccount) {
              await addExternalAccount(newExternalAccount);

              navigation.navigate(Screen.AUTOPAY_SETTINGS_MODAL, {
                screen: Screen.AUTOPAY_SETUP,
                params: {
                  cbaAccountId,
                },
              });
            }
          },
        },
      ]}
    />
  );
}
