import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { LoanApplicationV2StackParams } from '../../navigation/types/navTypes';
import {
  PurchaseRouteMap,
  RefiRouteMap,
  TopUpRouteMap,
} from '../navigation/loanApplicationRouteMapping';

const purchaseScreenNames = new Set(
  Object.values(PurchaseRouteMap).map(({ screenName }) => screenName),
);
const refinanceScreenNames = new Set(
  Object.values(RefiRouteMap).map(({ screenName }) => screenName),
);

const topUpScreenNames = new Set(
  Object.values(TopUpRouteMap).map(({ screenName }) => screenName),
);

function getExpectedLoanType(screenName: keyof LoanApplicationV2StackParams) {
  if (purchaseScreenNames.has(screenName)) {
    return Loan_Application_Type_Enum.Purchase;
  }
  if (refinanceScreenNames.has(screenName)) {
    return Loan_Application_Type_Enum.Refinance;
  }
  if (topUpScreenNames.has(screenName)) {
    return Loan_Application_Type_Enum.TopUp;
  }
  return null;
}

export enum LoanValidationResult {
  NotFound = 'NotFound',
  LoanApplicationTypeMismatch = 'LoanApplicationTypeMismatch',
  Valid = 'Valid',
}

type LoanApplication = {
  id: string | undefined;
  type: Loan_Application_Type_Enum | undefined;
};

export function validateLoanApplicationForScreen({
  screenName,
  loanApplication,
}: {
  screenName: keyof LoanApplicationV2StackParams;
  loanApplication: LoanApplication | undefined | null;
}) {
  const expectedType = getExpectedLoanType(screenName);
  if (
    loanApplication == null ||
    loanApplication.id == null ||
    loanApplication.type == null
  ) {
    return LoanValidationResult.NotFound;
  }
  if (loanApplication.type !== expectedType) {
    return LoanValidationResult.LoanApplicationTypeMismatch;
  }
  return LoanValidationResult.Valid;
}
