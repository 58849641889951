import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  refetchGetExternalAccountsQuery,
  useAddExternalAccountMutation,
} from '../../generated/graphql';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useIsBsbNumberForWithdrawValid } from '../../utils/isBsbNumberForWithdrawValid';
import { selectedExternalAccountAtom } from '../atoms/selectedExternalAccount';
import { AddExternalAccountFormValues } from '../components/AddExternalAccount';

export enum AddExternalAccountFor {
  Withdrawal,
  AutoPay,
}

export type UseAddExternalAccountProps = {
  cbaAccountId?: string;
  accountFor: AddExternalAccountFor;
  preventSettingSelectedExternalAccount?: boolean;
};

export function useAddExternalAccount({
  cbaAccountId,
  accountFor,
  preventSettingSelectedExternalAccount,
}: UseAddExternalAccountProps) {
  const [formError, setFormError] = useState<string | null>(null);
  const isBsbNumberForWithdrawValid = useIsBsbNumberForWithdrawValid();
  const setSelectedExternalAccount = useSetRecoilState(
    selectedExternalAccountAtom,
  );

  const [
    executeAddExternalAccountMutation,
    { loading: addExternalAccountLoading },
  ] = useAddExternalAccountMutation({});

  const addExternalAccount = useCallback(
    async (newExternalAccount: AddExternalAccountFormValues) => {
      setFormError(null);

      if (!cbaAccountId) {
        setFormError(t('Content.Withdraw.Error.NoAccountId'));
        return null;
      }

      if (
        accountFor === AddExternalAccountFor.Withdrawal &&
        // No support for withdrawal to specific bsb, need
        // manual workaround.
        // https://github.com/unloan/unloan-app/issues/4424
        !isBsbNumberForWithdrawValid(newExternalAccount.account_bsb)
      ) {
        setFormError(t('Content.Withdraw.Error.UnableToWithdrawToBankWest'));
        return null;
      }

      const [upsertExternalAccountResult] = await safelyCallMutation(
        executeAddExternalAccountMutation,
        {
          variables: newExternalAccount,
          context: { sentryContext: { cbaAccountId } },
          refetchQueries: [refetchGetExternalAccountsQuery()],
        },
      );

      if (upsertExternalAccountResult == null) {
        setFormError(t('Content.Withdraw.Error.AddAccountFailed'));
        return null;
      }

      const externalAccountId =
        upsertExternalAccountResult.data?.add_external_account?.returning?.id ??
        '';

      if (!preventSettingSelectedExternalAccount) {
        setSelectedExternalAccount({
          externalAccountId,
          type: 'Other',
          maskedAccountNumber: newExternalAccount.account_number,
          name: newExternalAccount.account_name,
          bsbNumber: newExternalAccount.account_bsb,
          accountDetails: `${newExternalAccount.account_bsb.replace(
            /(\d{3})(\d{3})/,
            '$1-$2',
          )} ${newExternalAccount.account_number}`,
        });
      }

      return externalAccountId;
    },
    [
      cbaAccountId,
      accountFor,
      isBsbNumberForWithdrawValid,
      executeAddExternalAccountMutation,
      preventSettingSelectedExternalAccount,
      setSelectedExternalAccount,
    ],
  );

  return {
    addExternalAccount,
    addExternalAccountLoading,
    formError,
  };
}
