import { LazyQueryExecFunction } from '@apollo/client';
import { monthsToYears } from 'date-fns';
import { A, styled, SxProp, Text, useDripsyTheme, View } from 'dripsy';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormSelectV2,
} from '../../../components/form/FormikInputs';
import {
  CalculatePurchaseLoanAmountAndDepositQuery,
  CalculatePurchaseLoanAmountAndDepositQueryVariables,
  EstimatedUpfrontCostsAndMaxLoanFragment,
  Lmi_Quote_Error_Type,
  State_Enum,
} from '../../../generated/graphql';
import { Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { Tooltip } from '../../../ui/atoms/Tooltip';
import { FormikFormError } from '../../../ui/v2/FormError';
import { Skeleton, useTextSkeletonDimension } from '../../../ui/v2/Skeleton';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { formatLvrPercentage } from '../../../utils/percentageFormatter';
import { yup } from '../../../utils/yup';
import {
  checkShowLmiDisclaimer,
  formatLmiPremiumToDisplay,
} from '../../utils/lmiHelpers';
import {
  MAX_LOAN_TERM_IN_MONTHS,
  MIN_LOAN_TERM_IN_MONTHS,
  YEAR_TERM_OPTIONS,
} from '../../utils/setupLoanFormHelper';
import { UpfrontCostsDetails } from './UpfrontCostsDetails';

export enum SetupLoanPurchaseFormField {
  LoanAmount = 'loanAmount',
  TermInMonths = 'termInMonths',
}

const SetupLoanPurchaseFormValidationSchema = yup.object({
  [SetupLoanPurchaseFormField.LoanAmount]: yup.number().required(),
  [SetupLoanPurchaseFormField.TermInMonths]: yup
    .number()
    .nullable()
    .min(MIN_LOAN_TERM_IN_MONTHS)
    .max(MAX_LOAN_TERM_IN_MONTHS)
    .required(),
});

export type SetupLoanPurchaseFormInitialValues = Partial<
  yup.InferType<typeof SetupLoanPurchaseFormValidationSchema>
>;

function getFeeLabelsBasedOnIsLmiEnabled(isLmiEnabled: boolean) {
  return isLmiEnabled
    ? {
        unloanFeeLabel: t(
          'Content.SetupLoanPurchase.FeesSection.UnloanApplicationFees',
        ),
        mortgageDischargeFeeLabel: t(
          'Content.SetupLoanPurchase.FeesSection.MortgageRegistrationDischargeFee',
        ),
      }
    : {
        unloanFeeLabel: t('Content.SetupLoanPurchase.FeesSection.UnloanFees'),
        mortgageDischargeFeeLabel: t(
          'Content.SetupLoanPurchase.FeesSection.MortgageDischargeFee',
        ),
      };
}

export type SetupLoanPurchaseFormProps = {
  screen: Screen;
  interestRate: number | undefined;
  loanApplicationId: string;
  initialValues: SetupLoanPurchaseFormInitialValues;
  onSubmit: (values: SetupLoanPurchaseFormInitialValues) => Promise<void>;
  isSubmitting: boolean;
  disableSubmitButton?: boolean;
  calculateLoanAmountAndDeposit: LazyQueryExecFunction<
    CalculatePurchaseLoanAmountAndDepositQuery,
    CalculatePurchaseLoanAmountAndDepositQueryVariables
  >;
  calculateLoanAmountAndDepositResult:
    | CalculatePurchaseLoanAmountAndDepositQuery['calculate_purchase_loan_amount_and_upfront_costs']
    | undefined;
  calculateLoanAmountAndDepositLoading: boolean;
  loanSecurityState: State_Enum | undefined;
  estimatedUpfrontCostsAndMaxLoanAmount:
    | EstimatedUpfrontCostsAndMaxLoanFragment
    | null
    | undefined;
  estimatedUpfrontCostsAndMaxLoanAmountLoading: boolean;
  isLmiEnabled: boolean;
};

export function SetupLoanPurchaseForm({
  screen,
  interestRate,
  onSubmit,
  isSubmitting,
  initialValues,
  disableSubmitButton,
  estimatedUpfrontCostsAndMaxLoanAmount,
  estimatedUpfrontCostsAndMaxLoanAmountLoading,
  loanSecurityState,
  calculateLoanAmountAndDeposit,
  calculateLoanAmountAndDepositLoading,
  calculateLoanAmountAndDepositResult,
  loanApplicationId,
  isLmiEnabled,
}: SetupLoanPurchaseFormProps) {
  const {
    maxLoanAmount,
    maxLvr,
    rangeLvrWithLmiMin,
    rangeLvrWithLmiMax,
    government_fees: governmentFees,
    upfront_costs: upfrontCosts,
  } = estimatedUpfrontCostsAndMaxLoanAmount ?? {};
  const { dischargeFee, registrationFee, transferFee } = governmentFees ?? {};
  const {
    stampDuty,
    estimatedConveyancing,
    deposit: storedDeposit,
    totalUpfrontCost: storedTotalUpfrontCost,
  } = upfrontCosts ?? {};

  const depositToDisplay =
    calculateLoanAmountAndDepositResult?.upfront_costs?.deposit ??
    storedDeposit;
  const estimatedTotalUpfrontCostToDisplay =
    calculateLoanAmountAndDepositResult?.upfront_costs?.totalUpfrontCost ??
    storedTotalUpfrontCost;

  const lmiPremium = calculateLoanAmountAndDepositResult?.lmiPremium;

  const totalEstimatedFees =
    calculateLoanAmountAndDepositResult?.totalEstimatedFees;

  const totalEstimatedFeesToBeDisplayed =
    totalEstimatedFees != null
      ? formatCurrency(totalEstimatedFees, {
          withFractionOnRoundedAmount: true,
        })
      : '--';

  const totalGovernmentFees = governmentFees?.totalGovernmentFees;
  const totalGovernmentFeesToBeDisplayed =
    totalGovernmentFees != null
      ? formatCurrency(totalGovernmentFees, {
          withFractionOnRoundedAmount: true,
        })
      : '--';

  const shouldShowLmiDescription = isLmiEnabled
    ? checkShowLmiDisclaimer(lmiPremium)
    : false;

  const { unloanFeeLabel, mortgageDischargeFeeLabel } =
    getFeeLabelsBasedOnIsLmiEnabled(isLmiEnabled);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SetupLoanPurchaseFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, handleSubmit, values }) => {
        const termInMonths = values[SetupLoanPurchaseFormField.TermInMonths];
        const termInYears =
          termInMonths != null ? monthsToYears(termInMonths) : undefined;

        return (
          <View pointerEvents={isSubmitting ? 'none' : undefined}>
            <SHeaderText sx={{ py: '$8' }}>
              {t('Content.SetupLoanPurchase.LoanAmountTitle')}
            </SHeaderText>

            <FormCurrencyInputV2
              name={SetupLoanPurchaseFormField.LoanAmount}
              error={errors?.[SetupLoanPurchaseFormField.LoanAmount]}
              label={t('Content.SetupLoanPurchase.LoanAmount')}
              sx={{ mt: '$8' }}
              inputTestID={TestID.SetupLoanPurchase.LoanAmount}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.YourUnloan,
                screen,
                FieldInteractionKey.LoanAmount,
              )}
            />
            {isLmiEnabled ? (
              <Text sx={{ my: '$8' }} variant="caption">
                {t(
                  'Content.SetupLoanPurchase.DesiredLoanAmountDescriptionWithLmi',
                  {
                    maxDesiredLoanAmount: formatCurrency(maxLoanAmount),
                    propertyMaxLvr: maxLvr
                      ? formatLvrPercentage(maxLvr)
                      : '--%',
                    lmiLvrStart: rangeLvrWithLmiMin
                      ? formatLvrPercentage(rangeLvrWithLmiMin)
                      : '--%',
                    lmiLvrEnd: rangeLvrWithLmiMax
                      ? formatLvrPercentage(rangeLvrWithLmiMax)
                      : '--',
                  },
                )}
                <A
                  variant="link"
                  href={t('Link.LmiBenefits')}
                  hrefAttrs={{
                    rel: 'noreferrer',
                    target: '_blank',
                  }}
                >
                  {` ${t('Content.Common.ButtonLabel.LearnMore')}`}
                </A>
              </Text>
            ) : (
              <Text sx={{ my: '$8' }} variant="caption">
                {t('Content.SetupLoanPurchase.DesiredLoanAmountDescription', {
                  maxLoanAmount: formatCurrency(maxLoanAmount),
                  maxLvr: maxLvr ? formatLvrPercentage(maxLvr) : '--%',
                })}
              </Text>
            )}

            <FormSelectV2
              sx={{ mt: '$8' }}
              name={SetupLoanPurchaseFormField.TermInMonths}
              testID={TestID.SetupLoanPurchase.LoanTerm}
              items={YEAR_TERM_OPTIONS}
              label={t('Content.SetupLoanPurchase.LoanTerm')}
            />
            <FormikFormError
              name={SetupLoanPurchaseFormField.TermInMonths}
              sx={{ mt: '$8' }}
            />
            <View sx={{ my: '$16' }}>
              <SectionHeading
                sectionTitle={t('Content.SetupLoanPurchase.FeesSection.Title')}
                sectionValue={
                  isLmiEnabled
                    ? totalEstimatedFeesToBeDisplayed
                    : totalGovernmentFeesToBeDisplayed
                }
                sectionValueLoading={calculateLoanAmountAndDepositLoading}
                sectionValueTestID={TestID.SetupLoanForm.TotalFeesAmount}
                tooltipContent={
                  <>
                    {t('Content.SetupLoanPurchase.GovernmentFeesDisclaimer')}
                    <A
                      href={t('Link.WhatAreGovernmentFees')}
                      hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
                      style={{ textDecorationLine: 'underline' }}
                    >
                      {t(
                        'Content.SetupLoanPurchase.GovernmentFeesDisclaimerLink',
                      )}
                    </A>
                  </>
                }
              />

              <FeeDetail
                label={unloanFeeLabel}
                // Unloan fee is always 0
                value={formatCurrency(0, {
                  withFractionOnRoundedAmount: true,
                })}
              />
              <FeeDetail
                label={t(
                  'Content.SetupLoanPurchase.FeesSection.MortgageRegistrationFee',
                )}
                value={
                  registrationFee != null
                    ? formatCurrency(registrationFee, {
                        withFractionOnRoundedAmount: true,
                      })
                    : '--'
                }
                loading={estimatedUpfrontCostsAndMaxLoanAmountLoading}
              />
              <FeeDetail
                label={mortgageDischargeFeeLabel}
                value={
                  dischargeFee != null
                    ? formatCurrency(dischargeFee, {
                        withFractionOnRoundedAmount: true,
                      })
                    : '--'
                }
                loading={estimatedUpfrontCostsAndMaxLoanAmountLoading}
              />
              <FeeDetail
                label={t('Content.SetupLoanPurchase.FeesSection.TransferFee')}
                value={
                  transferFee != null
                    ? formatCurrency(transferFee, {
                        withFractionOnRoundedAmount: true,
                      })
                    : '--'
                }
                loading={estimatedUpfrontCostsAndMaxLoanAmountLoading}
              />
              {isLmiEnabled ? (
                <FeeDetail
                  label={t('Content.SetupLoanPurchase.FeesSection.Lmi')}
                  value={formatLmiPremiumToDisplay(lmiPremium)}
                  valueTestId={TestID.SetupLoanForm.LmiPremium}
                  loading={calculateLoanAmountAndDepositLoading}
                />
              ) : null}
            </View>
            <TotalLoanAmountAndMinRepayment
              termInMonths={termInMonths}
              loanApplicationId={loanApplicationId}
              loanAmount={values[SetupLoanPurchaseFormField.LoanAmount]}
              calculateLoanAmountAndDeposit={calculateLoanAmountAndDeposit}
              calculateLoanAmountAndDepositResult={
                calculateLoanAmountAndDepositResult
              }
              calculateLoanAmountAndDepositLoading={
                calculateLoanAmountAndDepositLoading
              }
              isLmiEnabled={isLmiEnabled}
            />

            <UpfrontCostsDetails
              sxProp={{ my: '$16' }}
              loanSecurityState={loanSecurityState}
              deposit={depositToDisplay}
              depositLoading={
                calculateLoanAmountAndDepositLoading ||
                estimatedUpfrontCostsAndMaxLoanAmountLoading
              }
              estimatedConveyancing={estimatedConveyancing}
              estimatedConveyancingLoading={
                estimatedUpfrontCostsAndMaxLoanAmountLoading
              }
              stampDuty={stampDuty}
              stampDutyLoading={estimatedUpfrontCostsAndMaxLoanAmountLoading}
              estimatedTotalUpfrontCost={estimatedTotalUpfrontCostToDisplay}
              estimatedTotalUpfrontCostLoading={
                calculateLoanAmountAndDepositLoading ||
                estimatedUpfrontCostsAndMaxLoanAmountLoading
              }
            />
            <View sx={{ mb: '$8' }}>
              <Text variant="caption">
                {t('Content.SetupLoanPurchase.MinimumRepaymentDescription', {
                  loanTermInYear: termInYears ?? '--',
                  interestRate: interestRate ?? '--',
                })}
              </Text>

              {shouldShowLmiDescription ? (
                <Text variant="caption">
                  {t('Content.SetupLoanPurchase.LmiPremiumDescription')}
                </Text>
              ) : null}
            </View>
            <Button
              label={t('Content.Common.ButtonLabel.Done')}
              mt="xl"
              width="100%"
              onPress={() => handleSubmit()}
              showSpinner={isSubmitting}
              disabled={isSubmitting || disableSubmitButton}
            />
          </View>
        );
      }}
    </Formik>
  );
}

function TotalLoanAmountAndMinRepayment({
  loanApplicationId,
  termInMonths,
  loanAmount,
  calculateLoanAmountAndDeposit,
  calculateLoanAmountAndDepositResult,
  calculateLoanAmountAndDepositLoading,
  isLmiEnabled,
}: {
  loanApplicationId: string;

  termInMonths: number | undefined;
  loanAmount: number | undefined;
  calculateLoanAmountAndDeposit: LazyQueryExecFunction<
    CalculatePurchaseLoanAmountAndDepositQuery,
    CalculatePurchaseLoanAmountAndDepositQueryVariables
  >;
  calculateLoanAmountAndDepositResult:
    | CalculatePurchaseLoanAmountAndDepositQuery['calculate_purchase_loan_amount_and_upfront_costs']
    | undefined;
  calculateLoanAmountAndDepositLoading: boolean;
  isLmiEnabled: boolean;
}) {
  const debouncedCalculateLoanAmountAndDeposit = useRef(
    debounce(calculateLoanAmountAndDeposit, 300),
  );

  useEffect(() => {
    debouncedCalculateLoanAmountAndDeposit.current({
      variables: {
        data: {
          // This query should be executed even when some inputs are missing
          // because we want to display the correct total loan amount
          // returned by this query.
          loan_amount: loanAmount ?? 0,
          term_in_months: termInMonths ?? 0,
          loan_application_id: loanApplicationId,
        },
      },
      fetchPolicy: 'network-only',
    });
  }, [termInMonths, loanAmount, loanApplicationId]);
  const totalLoanAmountSkeleton = useTextSkeletonDimension(6, 1, 'lNumber');
  const minMonthlyRepaymentSkeleton = useTextSkeletonDimension(
    6,
    1,
    'xsHeader',
  );

  const {
    minRepaymentPerMonth,
    totalLoanAmount,
    minRepaymentErrorType,
    lmiQuoteErrorType,
  } = calculateLoanAmountAndDepositResult ?? {};

  const isLmiQuoteCalcFailing =
    lmiQuoteErrorType === Lmi_Quote_Error_Type.LmiQuoteCalculationFailed;
  const shouldHideTotalLoanAmount = isLmiQuoteCalcFailing;
  const shouldHideMinRepayment =
    minRepaymentErrorType != null || isLmiQuoteCalcFailing;

  const formattedMinRepaymentAmount = shouldHideMinRepayment
    ? '$--'
    : formatCurrency(minRepaymentPerMonth, {
        withFractionOnRoundedAmount: true,
      });

  return (
    <View sx={{ borderTopColor: '$border', borderTopWidth: 1, py: '$8' }}>
      <XSHeaderText sx={{ color: '$secondary' }}>
        {t('Content.SetupLoanPurchase.TotalLoanAmount')}
      </XSHeaderText>

      {calculateLoanAmountAndDepositLoading ? (
        <>
          <View sx={{ mb: '$8' }}>
            <Skeleton
              width={totalLoanAmountSkeleton.width}
              height={totalLoanAmountSkeleton.height}
              show
            />
          </View>
          <Skeleton
            width={minMonthlyRepaymentSkeleton.width}
            height={minMonthlyRepaymentSkeleton.height}
            show
          />
        </>
      ) : (
        <>
          <Text variant="lNumber" testID={TestID.SetupLoanForm.TotalLoanAmount}>
            {shouldHideTotalLoanAmount
              ? '$--'
              : formatCurrency(totalLoanAmount, {
                  withFractionOnRoundedAmount: true,
                })}
          </Text>
          <XSHeaderText>
            {isLmiEnabled
              ? t('Content.Common.X/Month*', { x: formattedMinRepaymentAmount })
              : t('Content.Common.XPerMonth*', {
                  x: formattedMinRepaymentAmount,
                })}
          </XSHeaderText>
        </>
      )}
    </View>
  );
}

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
const XSHeaderText = styled(Text)({
  variant: 'text.xsHeader',
});
const RowView = styled(View)({
  flexDirection: 'row',
});

function SectionHeading({
  containerSx,
  sectionTitle,
  sectionValue,
  sectionValueLoading,
  sectionValueTestID,
  tooltipContent,
  tooltipTestID,
}: {
  containerSx?: SxProp;
  sectionTitle: string;
  sectionValue?: string;
  sectionValueLoading?: boolean;
  sectionValueTestID?: string;
  tooltipContent?: React.ComponentProps<typeof Tooltip>['content'];
  tooltipTestID?: string;
}) {
  const { width, height } = useTextSkeletonDimension(4, 1, 'sHeader');
  return (
    <RowView sx={{ justifyContent: 'space-between', py: '$8', ...containerSx }}>
      <RowView sx={{ alignItems: 'center' }}>
        <SHeaderText sx={{ mr: tooltipContent ? '$8' : '$0' }}>
          {sectionTitle}
        </SHeaderText>
        {tooltipContent ? (
          <Tooltip content={tooltipContent} testID={tooltipTestID} />
        ) : null}
      </RowView>

      {sectionValueLoading ? (
        <Skeleton width={width} height={height} show />
      ) : (
        <SHeaderText sx={{ fontWeight: '400' }} testID={sectionValueTestID}>
          {sectionValue}
        </SHeaderText>
      )}
    </RowView>
  );
}

const FeeDetailText = styled(Text)({
  fontSize: '$13',
});

function FeeDetail({
  label,
  value,
  loading,
  valueTestId,
}: {
  label: string;
  value: string;
  valueTestId?: string;
  loading?: boolean;
}) {
  const { theme } = useDripsyTheme();
  return (
    <RowView sx={{ justifyContent: 'space-between' }}>
      <FeeDetailText>{label}</FeeDetailText>
      {loading ? (
        <Skeleton width={40} height={theme.fontSizes.$13} show />
      ) : (
        <FeeDetailText testID={valueTestId}>{value}</FeeDetailText>
      )}
    </RowView>
  );
}
