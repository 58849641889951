import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function FinancialRentalIncomeIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('FinancialRentalIncomeIcon');

  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08984 16.5V30.75H31.6622V16.5L18.376 3.75L5.08984 16.5ZM5.08984 32.2501V30.7501H31.6622V32.2501C31.6622 33.0788 31.0016 33.7501 30.186 33.7501H6.56609C5.75046 33.7501 5.08984 33.0788 5.08984 32.2501Z"
        fill={`url(#${idPrefix}_paint0_linear_8888_902)`}
      />
      <Path
        d="M22.5 25.0141C22.5 20.5813 15.9945 21.4468 15.9945 18.8274C15.9945 17.4601 17.5219 17.388 18.0004 17.388C19.1483 17.388 20.2572 17.6976 21.102 18.0967C21.3685 18.2222 21.674 18.0363 21.674 17.7408V16.451C21.674 16.0183 21.5119 15.7619 21.2539 15.6608C20.6741 15.4342 19.8426 15.2687 18.6121 15.228V13.8081C18.6121 13.638 18.475 13.5 18.3059 13.5H17.6933C17.5242 13.5 17.3871 13.638 17.3871 13.8081V15.2507C15.4794 15.4381 13.5 16.5216 13.5 19.043C13.5 23.6382 20.0047 22.7271 20.0047 25.1474C20.0047 25.9079 19.5216 26.5641 17.9988 26.5641C16.4683 26.5641 15.0617 26.0263 14.2832 25.6256C14.0213 25.4908 13.7127 25.686 13.7127 25.9824V27.4508C13.7127 27.7323 13.8811 27.9832 14.139 28.093C15.0617 28.4873 16.2984 28.7852 17.3863 28.8731V30.4419C17.3863 30.612 17.5235 30.75 17.6926 30.75H18.3051C18.4742 30.75 18.6114 30.612 18.6114 30.4419V28.8738C21.4682 28.6347 22.5 26.7115 22.5 25.0141Z"
        fill="#2C2C2C"
      />
      <Path
        opacity="0.05"
        d="M18.376 4.5C18.0262 4.5 17.6763 4.626 17.3973 4.87725L13.4417 8.4855L5.08984 16.5V20.5425L18.376 8.571L31.6622 20.5425V16.5L23.3104 8.4855L19.3548 4.87725C19.0758 4.626 18.7259 4.5 18.376 4.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33.5028 16.1302L19.3538 3.37725C19.0747 3.126 18.7249 3 18.375 3C18.0251 3 17.6753 3.126 17.3962 3.37725L3.24718 16.1302C2.93717 16.4108 2.91577 16.8953 3.19994 17.2028L4.40751 18.5115C4.67988 18.807 5.13382 18.8295 5.4335 18.5618L18.375 7.071L31.3165 18.5625C31.6154 18.8295 32.0701 18.8078 32.3425 18.5123L33.5501 17.2035C33.8342 16.8952 33.8128 16.4108 33.5028 16.1302Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8888_902`}
          x1="5.08984"
          y1="3.75"
          x2="34.8705"
          y2="30.128"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset="1" stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
