import { MotiView } from 'moti';
import { Skeleton as BaseSkeleton } from 'moti/skeleton';
import { ReactNode } from 'react';
import * as React from 'react';

import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import { useTheme } from '../theme';

const skeletonWrapperTransitionOptions = {
  from: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.9,
  },
  transition: {
    type: 'timing' as const,
  },
};

type WrapperProps = { children: ReactNode; key: string };

export function SkeletonWrapper({ children, key }: WrapperProps) {
  return (
    <MotiView {...skeletonWrapperTransitionOptions} key={key}>
      {children}
    </MotiView>
  );
}

const skeletonContentWrapper = {
  from: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export function ContentWrapper({ children, key }: WrapperProps) {
  return (
    <MotiView {...skeletonContentWrapper} key={key}>
      {children}
    </MotiView>
  );
}

type SkeletonProps = Omit<
  React.ComponentProps<typeof BaseSkeleton>,
  'children'
> & { children?: ReactNode };

/**
 * @deprecated use '@unloan/ui/v2/Skeleton' or '@unloan/ui/molecules/LoadingRow' instead.
 */
export function Skeleton({ show, children, ...props }: SkeletonProps) {
  const theme = useTheme();
  const colors = React.useMemo(
    () => [
      theme.colors.skeletonPrimary,
      theme.colors.skeletonSecondary,
      theme.colors.skeletonPrimary,
      theme.colors.skeletonSecondary,
      theme.colors.skeletonPrimary,
      theme.colors.skeletonSecondary,
    ],
    [theme.colors.skeletonPrimary, theme.colors.skeletonSecondary],
  );

  // Skeleton wraps the components with a div which breaks any z-index specified in the content
  if (!show) {
    return children;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const content = <>{children}</>;

  return (
    // @TODO: Make skeleton doesnt accept children
    // Skeleton are ignored for chromatic to avoid creating visual diffs when animating
    // This will also ignore everything rendered inside the skeleton
    <BaseSkeleton
      colors={colors}
      radius={4}
      dataSet={chromaticIgnoreDataSet}
      show={show}
      {...props}
    >
      {content}
    </BaseSkeleton>
  );
}
