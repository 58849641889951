import Svg, { Path, Rect } from 'react-native-svg';

import { SvgIconProps } from './types';

export function UnloanLogoRound({ size = 36, ...props }: SvgIconProps) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Rect x={0.5} y={0.5} width={35} height={35} rx={7.5} fill="#fff" />
      <Rect x={0.5} y={0.5} width={35} height={35} rx={7.5} stroke="#F4F4F4" />
      <Path fill="#D8D8D8" fillOpacity={0.01} d="M0 0H36V36H0z" />
      <Path
        d="M21.78 15.095h.197c.538 0 .96.134 1.264.403.323.25.484.654.484 1.21v6.342c0 .448.054.815.161 1.102.126.269.296.484.511.645.215.161.475.269.78.323.323.053.681.08 1.076.08a8.36 8.36 0 001.183-.08c.394-.054.68-.108.86-.162v-8.412c0-1.577-.547-2.804-1.64-3.682-1.094-.896-2.653-1.344-4.679-1.344a10.722 10.722 0 00-.196 0c-2.026 0-3.586.448-4.68 1.344-1.093.878-1.64 2.105-1.64 3.682v4.006c.18.054.467.108.861.162.377.053.77.08 1.183.08.395 0 .753-.027 1.076-.08.305-.054.564-.162.78-.323.215-.161.385-.376.51-.645.108-.287.162-.654.162-1.102v-1.937c0-.555.161-.958.484-1.21.305-.268.726-.402 1.264-.402z"
        fill="#000"
      />
      <Path
        d="M13.716 21.626c-.033 0-.066 0-.098-.002l-.099.002c-.538 0-.959-.135-1.264-.404-.322-.25-.484-.654-.484-1.209V13.67c0-.448-.053-.815-.161-1.102a1.67 1.67 0 00-.51-.645 1.798 1.798 0 00-.78-.322 6.569 6.569 0 00-1.076-.081 8.36 8.36 0 00-1.183.08 6.245 6.245 0 00-.86.162v8.412c0 1.577.546 2.805 1.64 3.682 1.093.896 2.652 1.344 4.678 1.344a10.722 10.722 0 00.197 0c2.026 0 3.585-.448 4.679-1.344 1.093-.878 1.64-2.105 1.64-3.682v-4.006a6.247 6.247 0 00-.86-.161 8.36 8.36 0 00-1.184-.081c-.394 0-.753.027-1.075.08a1.798 1.798 0 00-.78.323 1.673 1.673 0 00-.511.645c-.108.287-.161.654-.161 1.102v1.937c0 .555-.162.959-.484 1.21-.305.268-.726.403-1.264.403z"
        fill="#000"
      />
    </Svg>
  );
}
