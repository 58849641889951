import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { StyledImage } from '../../ui/atoms/StyledImage';
import { AppTerms } from './AppTerms';
import { AuthScreenContainer } from './AuthScreenContainer';

export const Welcome = ({
  loading,
  onPressContinue,
}: {
  loading?: boolean;
  onPressContinue: () => void;
}) => (
  <AuthScreenContainer>
    <Box
      position="absolute"
      width="100%"
      height="85%"
      flex={1}
      justifyContent="center"
    >
      <StyledImage
        minHeight={421}
        maxWidth={652}
        contentFit="cover"
        source={require('../../../assets/hero-image-652x421.png')}
      />
    </Box>
    <Box flex={1} alignItems="center" justifyContent="center">
      <Box
        flex={1}
        alignItems="center"
        justifyContent="center"
        my="m"
        maxHeight={700}
      >
        <Box mb="m" alignItems="center">
          <Text variant="xlHeader" style={{ textAlign: 'center' }}>
            {t('Content.Auth.Welcome')}
          </Text>
        </Box>
        <Box flex={1} />
        <Box maxWidth={440} mx="m" mt="m">
          <AppTerms />
        </Box>
        <Box maxWidth={440} width="100%" px="m">
          <Button
            width="100%"
            testID={TestID.Auth.Continue}
            label={t('Content.Common.ButtonLabel.Continue')}
            disabled={loading}
            showSpinner={loading}
            onPress={onPressContinue}
          />
        </Box>
      </Box>
    </Box>
  </AuthScreenContainer>
);
