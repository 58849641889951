import { HeaderBackButton } from '@react-navigation/elements';
import * as React from 'react';

import { TestID } from '../../testID/constants';
import { HeaderButton } from '../ui/atoms/HeaderButton';

export const ArrowBackButton: React.FC<
  React.ComponentProps<typeof HeaderBackButton>
> = (props) => (
  <HeaderButton
    position="left"
    iconName="arrowLeft"
    accessibilityLabel="back"
    iconFamily="svg"
    testID={TestID.BackButton}
    aria-label={t('Content.Accessibility.Navigation.ArrowBackLabel')}
    {...props}
  />
);
