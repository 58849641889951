import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { EmailChangeEmailVerify } from '../screens/EmailChangeEmailVerify';
import { EmailChangeNewEmail } from '../screens/EmailChangeNewEmail';
import { EmailChangeNewEmailVerify } from '../screens/EmailChangeNewEmailVerify';
import { EmailChangeOldMobileVerify } from '../screens/EmailChangeOldMobileVerify';
import { MobileChangeEmailVerify } from '../screens/MobileChangeEmailVerify';
import { MobileChangeNewMobile } from '../screens/MobileChangeNewMobile';
import { MobileChangeNewMobileVerify } from '../screens/MobileChangeNewMobileVerify';
import { MobileChangeOldMobileVerify } from '../screens/MobileChangeOldMobileVerify';
import { PersonalDetailsModalStackParams } from './navTypes';

const PersonalDetailsModalStack =
  createStackNavigator<PersonalDetailsModalStackParams>();

export const PersonalDetailsModalNavigator: React.FC = () => (
  <PersonalDetailsModalStack.Navigator
    screenOptions={DefaultModalV2NavigatorOptions}
  >
    {/* Mobile Change Screens */}
    <PersonalDetailsModalStack.Group>
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE}
        component={MobileChangeNewMobile}
        options={{
          title: getPageTitle(Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY}
        component={MobileChangeEmailVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_MOBILE_CHANGE_EMAIL_VERIFY,
          ),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY}
        component={MobileChangeOldMobileVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_MOBILE_CHANGE_OLD_MOBILE_VERIFY,
          ),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY}
        component={MobileChangeNewMobileVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_MOBILE_CHANGE_NEW_MOBILE_VERIFY,
          ),
        }}
      />
    </PersonalDetailsModalStack.Group>

    {/* Email Change Screens */}
    <PersonalDetailsModalStack.Group>
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY}
        component={EmailChangeEmailVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_EMAIL_CHANGE_EMAIL_VERIFY,
          ),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY}
        component={EmailChangeOldMobileVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY,
          ),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL}
        component={EmailChangeNewEmail}
        options={{
          title: getPageTitle(Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL),
        }}
      />
      <PersonalDetailsModalStack.Screen
        name={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY}
        component={EmailChangeNewEmailVerify}
        options={{
          title: getPageTitle(
            Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY,
          ),
        }}
      />
    </PersonalDetailsModalStack.Group>
  </PersonalDetailsModalStack.Navigator>
);
