import { LiabilitySupportingDocumentUploadDetailQuery } from '../../generated/graphql';
import { NewListRow } from '../../ui/molecules/NewListRow';
import { joinListOfString } from '../../utils/stringHelpers';

type LiabilitySummaryCardProps = {
  liability?: LiabilitySupportingDocumentUploadDetailQuery['merged_liability_by_pk'];
};

export function LiabilitySummaryCard({ liability }: LiabilitySummaryCardProps) {
  if (!liability) {
    return null;
  }

  const {
    dynamite_institution_name,
    dynamite_account_number,
    dynamite_applicant_full_names,
  } = liability;

  const liabilityOwners = joinListOfString(dynamite_applicant_full_names || []);

  const getTextAndFooterProps = () => ({
    title: dynamite_institution_name || '--',
    caption: dynamite_account_number ? `•••• ${dynamite_account_number}` : '--',
    subcaption: liabilityOwners,
  });

  return (
    <NewListRow {...getTextAndFooterProps()} style={{ marginBottom: 24 }} />
  );
}
