import { gql } from '@apollo/client';
import { View } from 'dripsy';
import { useState } from 'react';

import {
  FlagDetectedLiabilityActionSheetWithCurrentLiabilityMutationMutationVariables,
  FlagDetectedLiabilityActionSheetWithoutCurrentLiabilityMutationV2MutationVariables,
  Flagged_Incorrect_Detected_Liability_Input_Enum,
  Flagged_Incorrect_Input_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  useFlagDetectedLiabilityActionSheetWithCurrentLiabilityMutationMutation,
  useFlagDetectedLiabilityActionSheetWithoutCurrentLiabilityMutationV2Mutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { useIsMobile } from '../../utils/hooks/useBreakpoint';
import { ActionListButton } from '../components/ActionListButton';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type FlagDetectedLiabilityActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.FLAG_DETECTED_LIABILITY>;

export const FLAG_DETECTED_LIABILITY_CONFIRMATION_ACTION_SHEET_MUTATION_DOC = gql`
  mutation FlagDetectedLiabilityActionSheetWithCurrentLiabilityMutation(
    $data: set_flagged_incorrect_on_current_liability_input!
  ) {
    set_flagged_incorrect_on_current_liability(data: $data) {
      current_liability_id
    }
  }

  mutation FlagDetectedLiabilityActionSheetWithoutCurrentLiabilityMutationV2(
    $data: set_flagged_incorrect_on_detected_liability_v2_input!
  ) {
    set_flagged_incorrect_on_detected_liability_v2(data: $data) {
      current_liability_id
    }
  }
`;

export type FlagDetectedLiabilityActionSheetParams = {
  loanApplicationId?: string;
  currentLiabilityId?: string;
  detectedLiabilityIdentifier?: string;
  onErrorReturnTo?: Screen;
  onErrorReturnToParams?: Record<string, unknown>;
  _initiallySelectedFlagIncorrectReason?: Flagged_Incorrect_Input_Enum;
};

function getSheetMessage(
  flagIncorrectReason: Flagged_Incorrect_Input_Enum | null,
) {
  if (flagIncorrectReason == null) {
    return t('Content.FlagDetectedLiabilityActionSheet.SelectAReason');
  }
  switch (flagIncorrectReason) {
    case Flagged_Incorrect_Input_Enum.ClosedAccount:
      return t(
        'Content.FlagDetectedLiabilityActionSheet.AccountHasBeenClosedMessage',
      );
    case Flagged_Incorrect_Input_Enum.NotOwner:
      return t(
        'Content.FlagDetectedLiabilityActionSheet.AccountIsntYoursMessage',
      );
    default:
      return null;
  }
}

export function FlagDetectedLiabilityActionSheet({
  navigation,
  route,
}: FlagDetectedLiabilityActionSheetProps) {
  const {
    currentLiabilityId,
    loanApplicationId,
    detectedLiabilityIdentifier,
    onErrorReturnTo = Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
    onErrorReturnToParams,
    _initiallySelectedFlagIncorrectReason,
  } = route.params || {};

  const isMobile = useIsMobile();
  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const [flagIncorrectReason, setFlagIncorrectReason] =
    useState<Flagged_Incorrect_Input_Enum | null>(
      _initiallySelectedFlagIncorrectReason ?? null,
    );

  const [
    commitWithCurrentLiability,
    { loading: inFlightWithCurrentLiability },
  ] = useFlagDetectedLiabilityActionSheetWithCurrentLiabilityMutationMutation();

  const [
    commitWithoutCurrentLiabilityV2,
    { loading: inFlightWithoutCurrentLiabilityV2 },
  ] =
    useFlagDetectedLiabilityActionSheetWithoutCurrentLiabilityMutationV2Mutation();

  const isLoading =
    inFlightWithCurrentLiability || inFlightWithoutCurrentLiabilityV2;

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onRemovePress = async () => {
    if (
      loanApplicationId == null ||
      flagIncorrectReason == null ||
      detectedLiabilityIdentifier == null
    ) {
      return;
    }

    const commonMutationOptions = {
      refetchQueries: [
        refetchYourDebtsQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          currentLiabilityId,
          detectedLiabilityIdentifier,
        },
      },
    };

    const withCurrentLiability = currentLiabilityId != null;

    let mutationResponse = null;

    if (withCurrentLiability) {
      const [res] = await safelyCallMutation(commitWithCurrentLiability, {
        variables: {
          data: {
            current_liability_id: currentLiabilityId,
            flagged_incorrect: flagIncorrectReason,
          },
        } as FlagDetectedLiabilityActionSheetWithCurrentLiabilityMutationMutationVariables,
        ...commonMutationOptions,
      });

      mutationResponse = res;
    } else {
      const [res] = await safelyCallMutation(commitWithoutCurrentLiabilityV2, {
        variables: {
          data: {
            detected_liability_identifier: detectedLiabilityIdentifier,
            flagged_incorrect:
              flagIncorrectReason as unknown as Flagged_Incorrect_Detected_Liability_Input_Enum,
            loan_application_id: loanApplicationId,
          },
        } as FlagDetectedLiabilityActionSheetWithoutCurrentLiabilityMutationV2MutationVariables,
        ...commonMutationOptions,
      });

      mutationResponse = res;
    }

    if (mutationResponse?.data == null) {
      (
        navigation.navigate as (
          screen: Screen,
          params: Record<string, unknown>,
        ) => void
      )(onErrorReturnTo, {
        ...onErrorReturnToParams,
        mutationErrorFromParams: t(
          'Content.FlagDetectedLiabilityActionSheet.MutationError',
        ),
      });
      return;
    }
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Debts,
      });
    });
  };

  const goBack = () => navigation.goBack();

  return (
    <ActionSheet
      closeModal={goBack}
      preventCloseModal={isLoading}
      title={t('Content.FlagDetectedLiabilityActionSheet.FlagAsIncorrect')}
      contentContainerStyle={{
        height: isMobile ? 200 : 236,
      }}
    >
      {loadingLoanApplicationMetadata ? (
        <Spinner />
      ) : (
        <ActionSheet.MessageText pt="m">
          {getSheetMessage(flagIncorrectReason)}
        </ActionSheet.MessageText>
      )}

      <View
        sx={{
          mt: '$16',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        {flagIncorrectReason != null ? (
          <ActionListButton
            label={t(
              'Content.FlagDetectedLiabilityActionSheet.RemoveThisAccount',
            )}
            onPress={onRemovePress}
            disabled={isLoading}
            showSpinner={isLoading}
            secondary
            isDestructive
          />
        ) : (
          <>
            <ActionListButton
              label={t(
                'Content.FlagDetectedLiabilityActionSheet.AccountHasBeenClosed',
              )}
              secondary
              onPress={() =>
                setFlagIncorrectReason(
                  Flagged_Incorrect_Input_Enum.ClosedAccount,
                )
              }
              mb="s"
            />
            <ActionListButton
              label={t(
                'Content.FlagDetectedLiabilityActionSheet.ThisIsNotMyAccount',
              )}
              secondary
              onPress={() =>
                setFlagIncorrectReason(Flagged_Incorrect_Input_Enum.NotOwner)
              }
            />
          </>
        )}
      </View>
    </ActionSheet>
  );
}
