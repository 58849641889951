import { A, Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';

export const NeedAssistance = ({
  openIntercom,
}: {
  openIntercom?: () => void;
}) => (
  <View
    testID={TestID.PaymentFailure.NeedAssistanceSection}
    sx={{ marginVertical: '$32', gap: '$16' }}
  >
    <Text variant="xsHeader">
      {t('Content.PaymentFailureModal.NeedAssistance.Title')}
    </Text>
    <Text variant="sBody">
      {t('Content.PaymentFailureModal.NeedAssistance.ChatWithUs')}{' '}
      <A
        nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
        variant="sBodyLink"
        sx={{ cursor: 'pointer' }}
        {...(openIntercom ? { onPress: openIntercom } : {})}
      >
        {t('Content.PaymentFailureModal.NeedAssistance.ContactUs')}
      </A>
      {'. '}
      {t('Content.PaymentFailureModal.NeedAssistance.FinancialDifficulty')}
    </Text>
  </View>
);
