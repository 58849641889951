import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function GoodFinancialDecisionIcon({ size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(37, 36, size);
  const theme = useTheme();
  return (
    <Svg width={width} height={size} viewBox="0 0 37 36" fill="none" {...props}>
      <Path
        d="M27.828 27.75h-8.203V13.312c1.266-.374 2.25-1.453 2.531-2.812h5.719a1.06 1.06 0 001.078-1.078c0-.656-.469-1.172-1.125-1.172h-5.953C21.312 6.937 20 6 18.5 6c-1.547 0-2.86.938-3.469 2.25H9.125A1.11 1.11 0 008 9.375c0 .563.469 1.125 1.125 1.125h5.672c.281 1.36 1.265 2.438 2.531 2.813V27.75H9.125A1.11 1.11 0 008 28.875C8 29.438 8.469 30 9.125 30h18.703a1.06 1.06 0 001.078-1.078c.047-.656-.422-1.172-1.078-1.172z"
        fill="#A0A0A0"
      />
      <Path
        d="M29.422 12.266C33.5 20.375 33.453 20 33.453 20.75c0 2.11-2.672 3.75-5.953 3.75-3.328 0-6-1.64-6-3.75 0-.703-.094-.328 3.938-8.484.375-.844 1.218-1.266 2.015-1.266.797 0 1.64.422 1.97 1.266z"
        fill={theme.colors.accent}
      />
      <Path
        d="M27.453 14.25L24.078 21h6.75l-3.375-6.75z"
        fill={theme.colors.accent}
      />
      <Path
        d="M27.453 14.25L24.078 21h6.75l-3.375-6.75z"
        fill={theme.colors.accent}
      />
      <Path
        d="M27.375 14L24 20.75h6.75L27.375 14z"
        fill={theme.colors.buttonPrimaryContent}
      />
      <Path
        d="M11.422 12.266C15.5 20.375 15.453 20 15.453 20.75c0 2.11-2.672 3.75-5.953 3.75-3.328 0-6-1.64-6-3.75 0-.703-.094-.328 3.938-8.484C7.813 11.422 8.656 11 9.453 11c.797 0 1.64.422 1.97 1.266z"
        fill={theme.colors.accent}
      />
      <Path
        d="M9.375 14L6 20.75h6.75L9.375 14z"
        fill={theme.colors.buttonPrimaryContent}
      />
      <Path
        d="M26.703 27H20V13.219c1.078-.516 1.922-1.5 2.156-2.719H27.5c.797 0 1.453-.656 1.453-1.5 0-.797-.703-1.5-1.453-1.5h-6.047c-.703-.89-1.734-1.5-3-1.5-1.219 0-2.297.61-2.953 1.5h-6C8.656 7.5 8 8.203 8 9c0 .844.656 1.5 1.453 1.5h5.297c.234 1.219 1.078 2.203 2.203 2.719V27H10.25c-1.266 0-2.297 1.031-2.297 2.25 0 .422.375.75.75.75h19.5a.74.74 0 00.75-.75C29 28.031 27.97 27 26.703 27z"
        fill="#A0A0A0"
      />
    </Svg>
  );
}
