import {
  Pressable,
  ScrollView,
  Text,
  TextInput as DripsyTextInput,
  ThemeColorName,
  useSx,
} from 'dripsy';
import { FormikContextType } from 'formik';
import {
  ComponentProps,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { useSuburbAutocomplete } from '../../Address/GoogleMapsApi.web';
import {
  formatSuburbDetails,
  SuburbDetails,
} from '../../Address/GoogleMapsApiCommon';
import { PoweredByGoogle } from '../../Address/PoweredByGoogle';
import { FormikControlProps } from '../../components/form/types';
import { MaxCharacter } from '../../constants/fieldRules';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { mergeRefs } from '../../utils/mergeRefs';
import { isWeb } from '../../utils/platformUtils';
import { InputRow } from './InputRow';
import { TextInputV2Props } from './TextInput';
import { LISTBOX_ACCESSBILITY_ROLE, OPTION_ACCESSBILITY_ROLE } from './utils';

const MAX_PICKER_ITEM_SHOWN_AT_ONCE = 5;

export type SuburbAutocompleteInputProps = Omit<
  TextInputV2Props,
  'onChangeText' | 'value'
> & {
  initialValue?: string;
  onChangeSearchText?: (text: string) => void;
  onSuburbSelect?: (suburbDetails: SuburbDetails) => Promise<void> | void;
};

const focusedDataset = isWeb ? { focused: 'true' } : undefined;
export const SuburbAutocompleteInput = forwardRef<
  RNTextInput | null,
  SuburbAutocompleteInputProps
>(
  (
    {
      label,
      inputTestID,
      containerTestID,
      disabled,
      sx: sxProp,
      onChangeSearchText: onChangeSearchTextProp,
      onSuburbSelect,
      initialValue,

      // These need to be controlled by a form controller
      error,
      focused,
      onBlur: onBlurProp,
      onFocus: onFocusProp,

      // These should go to RN text input
      maxLength,
      keyboardType,
    }: SuburbAutocompleteInputProps,
    ref,
  ) => {
    // TODO: All z-index usage should be specified in themes
    // instead of hardcoded on individual components.
    const suggestionResultZIndex = 2;

    const textInputLocalRef = useRef<RNTextInput>(null);

    const [labelId] = useState(() => uuidv4());
    const [searchValue, setSearchValue] = useState(initialValue ?? '');

    const showAutocomplete = focused && searchValue.trim().length >= 3;

    const {
      getSuburbDetailsList,
      suburbDetailsList,
      resetAutocompleteSessionToken,
    } = useSuburbAutocomplete();
    const getPredictions = useDebounce(getSuburbDetailsList, 300);

    const onChangeSearchText = useCallback(
      (text: string) => {
        setSearchValue(text);
        onChangeSearchTextProp?.(text);
        getPredictions(text);
      },
      [getPredictions, onChangeSearchTextProp],
    );

    const onFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (disabled) {
          return;
        }

        onFocusProp?.(e);
      },
      [disabled, onFocusProp],
    );

    const onEntryPress = useCallback(
      async (entry: AutocompleteEntry) => {
        if (entry.type === 'details') {
          setSearchValue(entry.suburbDetails.suburb ?? '');
          await onSuburbSelect?.(entry.suburbDetails);
        }

        if (entry.type === 'user_provided') {
          resetAutocompleteSessionToken();
          await onSuburbSelect?.({
            suburb: entry.userProvidedValue,
            postcode: null,
            state: null,
          });
        }

        textInputLocalRef.current?.blur();
      },
      [onSuburbSelect, resetAutocompleteSessionToken],
    );

    let borderColor: ThemeColorName = '$border';
    let labelColor: ThemeColorName = '$secondary';
    let bgColor: ThemeColorName = '$inputBackground';
    let valueColor: ThemeColorName = '$labelsPrimary';
    if (error) {
      borderColor = '$error';
      labelColor = '$error';
    }
    if (focused) {
      borderColor = '$focus';
    }
    if (disabled) {
      labelColor = '$secondaryDisabled';
      bgColor = '$inputBackgroundDisabled';
      valueColor = '$secondary';
    }

    const smallLabel = focused || searchValue.length > 0;
    return (
      <InputRow
        sx={{
          bg: bgColor,
          py: '$7',
          borderColor,
          ...(focused
            ? { overflow: 'visible', zIndex: suggestionResultZIndex }
            : null),
          ...sxProp,
        }}
        testID={containerTestID}
        dataSet={focused ? focusedDataset : undefined}
      >
        <Text
          variant={smallLabel ? 'tiny' : 'default'}
          sx={{
            px: '$16',
            color: labelColor,
            position: 'absolute',
            ...(smallLabel ? { top: '$7' } : { zIndex: -1 }),
          }}
          numberOfLines={1}
          nativeID={labelId}
          selectable={false}
        >
          {label}
        </Text>
        <DripsyTextInput
          sx={{
            variant: 'text.default',
            color: valueColor,
            pt: '$16',
            px: '$16',
          }}
          value={searchValue}
          editable={!disabled}
          maxLength={maxLength || MaxCharacter.general}
          numberOfLines={1}
          keyboardType={keyboardType}
          onChangeText={onChangeSearchText}
          onBlur={onBlurProp}
          onFocus={onFocus}
          underlineColorAndroid="transparent"
          // This is a browser specific fix for Chrome only.
          // see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error `chrome-off` is a web only enum and
          // our RN types override stopped working since Expo 49 upgrades,
          // see `@types/react-native.d.ts` for existing overrides.
          autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
          testID={inputTestID}
          ref={mergeRefs([textInputLocalRef, ref])}
          accessibilityLabelledBy={labelId}
          autoCorrect={false}
        />
        {showAutocomplete ? (
          <SuburbAutocomplete
            predictions={suburbDetailsList}
            searchText={searchValue}
            onEntryPress={onEntryPress}
          />
        ) : null}
      </InputRow>
    );
  },
);
SuburbAutocompleteInput.displayName = 'SuburbAutocompleteInput';

const disableBubblingFocusEventProps = isWeb
  ? {
      // eslint-disable-next-line
      // @ts-ignore - onMouseDown is forwarded to View, but not exposed in Pressable
      onMouseDown: (event) => {
        event.preventDefault();
      },
    }
  : null;

type AutocompleteEntry =
  | {
      type: 'details';
      suburbDetails: SuburbDetails;
    }
  | {
      type: 'user_provided';
      userProvidedValue: string;
    };

type SuburbAutocompleteProps = {
  predictions: Array<SuburbDetails>;
  searchText: string;
  onEntryPress: (entry: AutocompleteEntry) => void;
};

function PressableEntry({
  sx: sxProp,
  ...otherProps
}: ComponentProps<typeof Pressable>) {
  const sx = useSx();

  const resolvedStyle = sxProp && sx(sxProp);

  return (
    <Pressable
      {...disableBubblingFocusEventProps}
      role={OPTION_ACCESSBILITY_ROLE}
      sx={{
        justifyContent: 'center',
        py: '$10',
        borderBottomWidth: '$1',
        borderBottomColor: '$border',
        ...resolvedStyle,
      }}
      {...otherProps}
    />
  );
}

function SuburbAutocomplete({
  onEntryPress,
  predictions,
  searchText,
}: SuburbAutocompleteProps) {
  const predictionElements = useMemo(
    () =>
      predictions.map((suburbDetails) => {
        const label = formatSuburbDetails(suburbDetails);
        return (
          <PressableEntry
            key={label}
            onPress={() => {
              onEntryPress({
                type: 'details',
                suburbDetails,
              });
            }}
          >
            <Text>{label}</Text>
          </PressableEntry>
        );
      }),
    [onEntryPress, predictions],
  );

  return (
    <ScrollView
      sx={(theme) => ({
        position: 'absolute',
        width: '100%',
        // Positioned below input
        top: theme.sizes.inputRow.minHeight - theme.borderWidths.$1,
        borderBottomLeftRadius: '$input',
        borderBottomRightRadius: '$input',
        borderWidth: '$1',
        borderColor: '$border',
        maxHeight:
          (theme.sizes.inputRow.minHeight +
            theme.borderWidths.$1 -
            theme.space.$10) *
          (MAX_PICKER_ITEM_SHOWN_AT_ONCE + 2),
        backgroundColor: '$inputBackground',
        boxShadow: 'dropShadow',
      })}
      contentContainerSx={{
        px: '$16',
        justifyContent: 'center',
      }}
      role={LISTBOX_ACCESSBILITY_ROLE}
    >
      {predictionElements}
      <PressableEntry
        onPress={() =>
          onEntryPress({
            type: 'user_provided',
            userProvidedValue: searchText,
          })
        }
        focusable={false}
      >
        <Text variant="emphasis">
          {t('Content.Common.ButtonLabel.UseX', {
            x: searchText,
          })}
        </Text>
      </PressableEntry>
      <PoweredByGoogle sx={{ py: '$10' }} />
    </ScrollView>
  );
}

export type SuburbAutocompleteInputPropsForFormik = Omit<
  SuburbAutocompleteInputProps,
  'onChange' | 'onChangeSearchText' | 'setFieldValue' | 'setFieldTouched'
> &
  Omit<
    FormikControlProps<string>,
    'error' | 'setFieldValue' | 'setFieldTouched'
  > & {
    // This formik is injected by `connect` from formik
    formik?: FormikContextType<unknown>;
    error?: string;
    // Internally, Formik return Promise
    // for setFieldValue and setFieldTouched
    // https://github.com/jaredpalmer/formik/issues/2457#issuecomment-1227803618
    setFieldValue?(value: string, validate?: boolean): Promise<void>;
    setFieldTouched?(isTouched?: boolean, validate?: boolean): Promise<void>;
  };

export const FormikAwareSuburbAutocompleteInput = forwardRef<
  RNTextInput,
  SuburbAutocompleteInputPropsForFormik
>(
  (
    {
      formik,
      setFieldValue,
      setFieldTouched,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      value,
      onSuburbSelect: onSuburbSelectProp,
      ...otherProps
    },
    ref,
  ) => {
    const hasSearchTextChangedRef = useRef(false);

    const onBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        // Only set field as touched if user has typed in the input before
        // This effectively make the input to validate only after user
        // has done editing the field
        if (hasSearchTextChangedRef.current) {
          setFieldTouched?.(true);
        }
        onBlurProp?.(event);
      },
      [onBlurProp, setFieldTouched],
    );

    const onFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocusProp?.(event);
      },
      [onFocusProp],
    );

    const onChangeSearchText = useCallback(
      async (searchText: string) => {
        if (searchText) {
          hasSearchTextChangedRef.current = true;
        }
        // Reset field value on change to make sure user select another option
        if (value) {
          await setFieldValue?.('');
        }
      },
      [setFieldValue, value],
    );

    const onSuburbSelect = useCallback(
      async (suburbDetails: SuburbDetails) => {
        const { suburb } = suburbDetails;
        if (suburb != null) {
          await setFieldValue?.(suburb);
        }
        await onSuburbSelectProp?.(suburbDetails);
      },
      [onSuburbSelectProp, setFieldValue],
    );

    return (
      <SuburbAutocompleteInput
        ref={ref}
        onSuburbSelect={onSuburbSelect}
        onChangeSearchText={onChangeSearchText}
        onBlur={onBlur}
        onFocus={onFocus}
        initialValue={value}
        {...otherProps}
      />
    );
  },
);
