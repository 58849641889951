import { getPageTitle, Screen } from '../../../navigation/types/screens';
import { captureException } from '../../../sentry';
import { SpecificRequired } from '../../../utils/typesHelpers';
import { DebtsReviewDetailsV2 } from '../../screens/DebtsReviewDetailsV2';
import { AppMergedLiability } from '../../utils/useAllLiabilitiesQuery';
import type { DebtsWizardV2StackScreenProps } from '../DebtsWizardNavigator';

type HasIdentifier = SpecificRequired<
  AppMergedLiability,
  'detected_liability_identifier'
>;

export function composeDebtsWizardScreenName(
  liabilitiesForDebtsWizard: Array<AppMergedLiability>,
  {
    detectedLiabilityIdentifier,
  }: {
    detectedLiabilityIdentifier: string;
  },
) {
  const index = liabilitiesForDebtsWizard.findIndex(
    (aml) => aml.detected_liability_identifier === detectedLiabilityIdentifier,
  );

  return `${Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS}-${
    index === -1 ? 0 : index
  }` as const;
}

export function buildDebtsWizard(
  liabilitiesForDebtsWizard: Array<AppMergedLiability>,
  { loanApplicationId }: { loanApplicationId?: string } = {},
): Array<DebtsWizardV2StackScreenProps> {
  const identifiers = new Set();

  const title = getPageTitle(Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS);

  const filteredLiabilitiesForDebtsWizard = liabilitiesForDebtsWizard.filter(
    (aml): aml is HasIdentifier => {
      if (aml.detected_liability_identifier == null) {
        captureException(
          'Found liabilities with no identifier when building debts wizard',
          {
            loanApplicationId,
            mergedLiabilityId: aml.id,
          },
        );
        return false;
      }

      const liability = aml as HasIdentifier;
      if (identifiers.has(aml.detected_liability_identifier)) {
        captureException(
          'Found liabilities with duplicate identifier when building debts wizard',
          {
            loanApplicationId,
            detected_liability_identifier:
              liability.detected_liability_identifier,
          },
        );
        return false;
      }
      identifiers.add(liability.detected_liability_identifier);
      return true;
    },
  );

  return filteredLiabilitiesForDebtsWizard.map((aml) => {
    const screenName = composeDebtsWizardScreenName(liabilitiesForDebtsWizard, {
      detectedLiabilityIdentifier: aml.detected_liability_identifier,
    });
    return {
      name: screenName,
      component: DebtsReviewDetailsV2,
      initialParams: {
        detectedLiabilityIdentifier: aml.detected_liability_identifier,
        loanApplicationId,
      },
      options: {
        title,
      },
    };
  });
}
