import { useCallback } from 'react';

import {
  refetchGetExternalAccountsQuery,
  useAddExternalAccountMutation,
} from '../../../generated/graphql';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { AddExternalAccountFormValues } from './models';

export const useAddExternalAccount = (cbaAccountId: string) => {
  const [executeAddExternalAccountMutation, { loading }] =
    useAddExternalAccountMutation();

  const addExternalAccount = useCallback(
    async (account: AddExternalAccountFormValues) =>
      safelyCallMutation(executeAddExternalAccountMutation, {
        variables: account,
        context: { sentryContext: { cbaAccountId } },
        refetchQueries: [refetchGetExternalAccountsQuery()],
      }),
    [cbaAccountId, executeAddExternalAccountMutation],
  );

  return {
    addExternalAccount,
    loading,
  };
};
