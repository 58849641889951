import '@expo/match-media';
import '@unloan/react-native-overrides';
import './localization';
import './reanimatedFix';
import './styles';
// Ensure that appropriate event handlers are registered with React Native
import 'react-native-gesture-handler';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'expo-dev-client';

import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MenuProvider } from 'react-native-popup-menu';
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from 'react-native-safe-area-context';
import { RecoilRoot, useSetRecoilState } from 'recoil';

import { GoogleMapsApiProvider } from './Address/GoogleMapsApi.web';
import { GTMProvider } from './Analytics/GTMProvider';
import { AuthProvider } from './Auth/AuthProvider';
import { BiocatchProvider, useBiocatch } from './biocatch/BiocatchProvider';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ENV_CONFIG } from './config';
import { DebugTopBar } from './DevTools/DebugTopBar';
import { FeatureFlagsProvider } from './FeatureFlags/FeatureFlagsProvider';
import { withLDProvider } from './FeatureFlags/withLDProvider';
import { Init } from './Init';
import { AppNavigator } from './navigation/AppNavigator';
import { LINKING } from './navigation/config';
import {
  ActionSheetType,
  getPageGTMTitle,
  PageGTMTitleWithStates,
  Screen,
} from './navigation/types/screens';
import { getRootRouteName } from './navigation/utils/getRootRouteName';
import { navigationReadyAtom } from './recoil/navigationReady';
import { routingInstrumentation } from './sentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LightNavTheme, useInitialTheme } from './theme';
import { ThemeProvider } from './theme/ThemeProvider';
import { ThemeProviderV2 } from './themeV2';
import { AppLoading } from './ui/organisms/AppLoading';
import { ApolloClientProvider } from './utils/ApolloClientProvider';
import {
  getGraphQLOverwriteSubscriptionUrl,
  getGraphQLOverwriteUrl,
  getTestSupportAppOverwriteUrl,
} from './utils/ephemeralEnvHelper';
import {
  EnvContextProvider,
  usePersistedEnv,
} from './utils/hooks/useEnvConfig';
import { useRefreshLdContext } from './utils/hooks/useRefreshLdContext';
import { useSendDataToGTM } from './utils/hooks/useSendDataToGTM';
import { JoinApplicationInvite } from './utils/JoinApplicationInvite';
import { getLogger } from './utils/loggerHelper';
import { isAndroidWeb } from './utils/platformUtils';
import { withSentryProfiler } from './utils/withSentryProfiler';

const logger = getLogger('APP');

function App(): JSX.Element {
  const [env, setEnv, hydrated] = usePersistedEnv();
  const config = useMemo(() => ENV_CONFIG[env], [env]);

  config.graphqlURL = getGraphQLOverwriteUrl(env, config.graphqlURL);
  config.graphqlSubscriptionURL = getGraphQLOverwriteSubscriptionUrl(
    env,
    config.graphqlSubscriptionURL,
  );
  config.testSupportAppURL = getTestSupportAppOverwriteUrl(
    env,
    config.testSupportAppURL,
  );

  const theme = useInitialTheme();

  useEffect(() => {
    logger?.log(`Using GraphQL Endpoint: ${config.graphqlURL}`);
  }, [config.graphqlURL]);

  if (!hydrated) {
    return <AppLoading />;
  }

  return (
    <FeatureFlagsProvider>
      <ActionSheetProvider>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <RecoilRoot>
            <ThemeProvider theme={theme}>
              <ThemeProviderV2>
                <StatusBar style="dark" />
                <EnvContextProvider value={{ config, setEnv, env }}>
                  <ErrorBoundary>
                    <BiocatchProvider>
                      <AuthProvider>
                        <ApolloClientProvider>
                          <GTMProvider>
                            <MenuProvider>
                              <GoogleMapsApiProvider>
                                <Init>
                                  <DebugTopBar testID="debug-top-bar" />
                                  <Navigation />
                                </Init>
                              </GoogleMapsApiProvider>
                            </MenuProvider>
                          </GTMProvider>
                        </ApolloClientProvider>
                      </AuthProvider>
                    </BiocatchProvider>
                  </ErrorBoundary>
                </EnvContextProvider>
              </ThemeProviderV2>
            </ThemeProvider>
          </RecoilRoot>
        </SafeAreaProvider>
      </ActionSheetProvider>
    </FeatureFlagsProvider>
  );
}

function Navigation() {
  const navigationRef = useNavigationContainerRef();
  const { sendVirtualPageViewEventToGTM } = useSendDataToGTM();
  const biocatch = useBiocatch();

  const refreshLdContext = useRefreshLdContext();
  const previousLoanApplicationId = useRef<string | undefined>(undefined);

  const handleNavigation = useCallback(async () => {
    const currentRouteName = getRootRouteName(
      navigationRef.getCurrentRoute()?.name,
    );
    const params = navigationRef.getCurrentRoute()?.params as {
      loanApplicationId?: string;
    };
    if (
      params?.loanApplicationId !== previousLoanApplicationId.current ||
      params?.loanApplicationId === undefined
    ) {
      previousLoanApplicationId.current = params?.loanApplicationId;
      refreshLdContext(params?.loanApplicationId);
    }

    if (currentRouteName) {
      biocatch.captureNavigationEvent(currentRouteName);
    }

    // Skip sending event to GTM, because this scene will send
    // different view_page_title for each page states
    if (
      Object.hasOwnProperty.call(PageGTMTitleWithStates, currentRouteName ?? '')
    ) {
      return;
    }

    const currentRouteVirtualPageTitle = currentRouteName
      ? getPageGTMTitle(currentRouteName as Screen | ActionSheetType)
      : currentRouteName;

    if (currentRouteVirtualPageTitle) {
      sendVirtualPageViewEventToGTM({
        virtualPageName: currentRouteVirtualPageTitle,
        additionalData: {
          current_application_id: params?.loanApplicationId,
        },
      });
    }
  }, [
    navigationRef,
    sendVirtualPageViewEventToGTM,
    refreshLdContext,
    biocatch,
  ]);

  const setNavigationReady = useSetRecoilState(navigationReadyAtom);

  const onReady = useCallback(() => {
    routingInstrumentation?.registerNavigationContainer(navigationRef);

    handleNavigation();

    setNavigationReady(true);
  }, [handleNavigation, navigationRef, setNavigationReady]);

  return (
    <JoinApplicationInvite>
      <NavigationContainer
        theme={LightNavTheme}
        linking={LINKING}
        ref={navigationRef}
        onReady={onReady}
        onStateChange={handleNavigation}
      >
        <AppNavigator />
      </NavigationContainer>
    </JoinApplicationInvite>
  );
}

export default withLDProvider(withSentryProfiler(App));

if (isAndroidWeb()) {
  serviceWorkerRegistration.register();
}
