import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';
import { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

import { captureException } from '../../sentry';
import { getGlobal } from '../getGlobal';

type SecuredKey = `s-${string}`;
type InsecuredKey = `i-${string}`;

/**
 * @web `window.crypto.subtle.digest()` is available only in secure context.
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * https://docs.expo.dev/versions/latest/sdk/crypto/#cryptodigeststringasyncalgorithm-data-options
 */
async function doDigestSha256(message: string): Promise<SecuredKey> {
  const digest = await Crypto.digestStringAsync(
    Crypto.CryptoDigestAlgorithm.SHA256,
    message,
    {
      encoding: Crypto.CryptoEncoding.HEX,
    },
  );

  return `s-${digest}`;
}

function doWebFakeSha256(message: string): InsecuredKey {
  return `i-${window.btoa(message)}`;
}

async function digestSha256(
  message: string,
): Promise<SecuredKey | InsecuredKey> {
  const isSecureContext = getGlobal()?.isSecureContext || false;

  if (Platform.OS === 'web' && !isSecureContext) {
    return doWebFakeSha256(message);
  }

  return doDigestSha256(message);
}

type SingleShotEffectOptions = {
  /**
   * Skip effect execution when set to true.
   */
  skip?: boolean;
  /**
   * Unique key used to determine whether the effect has run before.
   * Will be hashed with sha256 to avoid leaving plain id in device storage.
   */
  storageKey: string;
  /**
   * Prefix will be appended to the storage key after hashing.
   * e.g. 'prefix_' + sha256('key') = 'prefix_hashedkey'
   */
  storageKeyPrefix?: string;
};

/**
 * Run an effect only once then set a flag in AsyncStorage
 * to prevent running it again.
 *
 * In case where the storage key cannot be determined or
 * storage is not accessible, the effect could run multiple times.
 */
export function useSingleShotEffect(
  effect: () => void | Promise<void>,
  {
    skip,
    storageKey: unhashedStorageKey,
    storageKeyPrefix = '',
  }: SingleShotEffectOptions,
) {
  const effectRef = useRef(effect);
  useEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    const runEffect = async () => {
      if (skip) {
        return;
      }

      let hashedKey: SecuredKey | InsecuredKey | null = null;
      try {
        hashedKey = await digestSha256(unhashedStorageKey);
      } catch (error) {
        captureException(
          'Failed to compute sha256 hash',
          {
            unhashedStorageKey,
          },
          error,
        );
        return;
      }

      const storageKey = hashedKey ? `${storageKeyPrefix}${hashedKey}` : null;

      let storedValue: string | null = null;
      try {
        storedValue = storageKey
          ? await AsyncStorage.getItem(storageKey)
          : null;
      } catch {
        // No storage access, let it proceed.
      }

      if (storedValue != null) {
        // Already ran before.
        return;
      }

      try {
        await effectRef.current();
        if (storageKey != null) {
          await AsyncStorage.setItem(storageKey, '1');
        }
      } catch (_error) {
        // Ignore storage access error.
        // Technically the effect() can also throw,
        // but the callee should catch it.
      }
    };

    runEffect();
  }, [skip, storageKeyPrefix, unhashedStorageKey]);
}
