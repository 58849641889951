import { useTheme } from '@shopify/restyle';
import { ReactNode } from 'react';
import * as React from 'react';

import { Box, BoxProps } from '../atoms/Box';
import { RowRight } from '../atoms/RowRight';
import { StyledText, StyledTextProps } from '../atoms/StyledText';
import { Theme } from '../theme';
import { Color, FontSize, FontWeight, TextType } from '../types';

type BaseRowProps = {
  disabled?: boolean;
  labelColor?: Color;
  labelFontSize?: FontSize;
  labelFontWeight?: FontWeight;
  labelVariant?: TextType;
  labelNumberOfLines?: number;
  label: React.ReactNode;
  labelTestId?: string;
  labelMl?: BoxProps['ml'];
  /**
   * Element to render on the right side of the label.
   * Will cause the label to be wrapped in another Box.
   */
  labelRightElement?: ReactNode;
  sublabels?: Array<string>;
  sublabelsColor?: Color;
  placeholder?: string;
  placeholderColor?: Color;
  placeholderCaption?: string;
  placeholderContainerMaxWidth?: number;
  placeholderVariant?: TextType;
  placeholderTestId?: string;
  placeholderNumberOfLines?: number;
  caption?: React.ReactNode;
  subCaption?: React.ReactNode;
  captionVariant?: TextType;
  captionColor?: Color;
  captionTestId?: string;
  last?: boolean;
  left?: React.ReactNode;
  captionLink?: React.ReactNode;
  onCaptionLinkPress?: () => void;
  captionLinkTestId?: string;
  captionLinkInlineFlex?: boolean;
  captionLinkAlignItems?: StyledTextProps['alignItems'];
  inset?: boolean;
  strikethroughTexts?: boolean;
  labelWrapperProps?: BoxProps;
  rightContent?: React.ReactNode;
};

export type Props = Omit<BoxProps, keyof BaseRowProps> & BaseRowProps;

export const Row = React.memo<Props>(
  ({
    disabled,
    centered,
    labelFontSize,
    labelFontWeight,
    labelColor = 'primaryContent',
    label,
    labelMl = 'm',
    sublabels,
    sublabelsColor,
    caption,
    subCaption,
    last,
    placeholder,
    placeholderColor = 'secondaryContent',
    placeholderCaption,
    placeholderTestId,
    placeholderNumberOfLines = 1,
    children,
    left,
    captionVariant = 'caption',
    alignItems = 'center',
    captionColor,
    captionLink,
    onCaptionLinkPress,
    captionLinkTestId,
    captionLinkInlineFlex,
    captionLinkAlignItems,
    inset,
    labelRightElement,
    labelNumberOfLines,
    placeholderContainerMaxWidth,
    strikethroughTexts,
    labelVariant,
    placeholderVariant,
    labelTestId,
    captionTestId,
    labelWrapperProps,
    rightContent,
    ...otherProps
  }) => {
    const theme = useTheme<Theme>();
    const textDecorationLine = strikethroughTexts ? 'line-through' : undefined;

    const labelElement = (
      <StyledText
        color={labelColor}
        fontSize={labelFontSize}
        fontWeight={labelFontWeight}
        numberOfLines={labelNumberOfLines}
        textDecorationLine={textDecorationLine}
        variant={labelVariant}
        testID={labelTestId}
      >
        {label}
      </StyledText>
    );
    return (
      <Box
        row
        minHeight={theme.constants.minRowHeight}
        alignItems={alignItems}
        borderColor="border"
        borderBottomWidth={inset || last ? 0 : theme.constants.borderWidth}
        pt="s"
        {...otherProps}
      >
        {left}
        <Box
          row
          flex={1}
          alignItems={alignItems}
          borderColor="border"
          borderBottomWidth={inset && !last ? theme.constants.borderWidth : 0}
          ml={inset || left ? labelMl : 0}
          mr={inset ? 'm' : 0}
          /**
           * Previously we use py="s" for the inner Box.
           * But now we split the pt and pb.
           * We use pt on the outer Box and pb in the inner Box.
           * This is intended to avoid having the pb in the outer Box,
           * causing the border from the inner Box to be pushed
           * inside by the padding, making it looks wonky.
           * For long term solution, we should avoid using the inner Box border
           * to give the separator.
           * Instead, we should have a separate Box only for the separator
           * as the sibling of the outer Box, but make it respect the horizontal margin/padding.
           */
          pb="s"
        >
          <Box
            justifyContent="center"
            flex={1}
            opacity={disabled ? 0.25 : 1}
            centered={centered}
            {...labelWrapperProps}
          >
            {labelRightElement ? (
              <Box row alignItems="center">
                {labelElement}
                {labelRightElement}
              </Box>
            ) : (
              labelElement
            )}
            {sublabels?.length
              ? sublabels.map((sublabel, i) => (
                  <StyledText
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    color={sublabelsColor || 'primaryContent'}
                    fontSize="xs"
                    textDecorationLine={textDecorationLine}
                  >
                    {sublabel}
                  </StyledText>
                ))
              : null}
            {caption || captionLink ? (
              <StyledText
                variant={captionVariant}
                color={captionColor}
                textDecorationLine={textDecorationLine}
                testID={captionTestId}
              >
                {caption}
                {captionLink ? (
                  <StyledText
                    testID={captionLinkTestId}
                    variant={captionVariant}
                    color="accent"
                    onPress={onCaptionLinkPress}
                    textDecorationLine={textDecorationLine}
                    inlineFlex={captionLinkInlineFlex}
                    alignItems={captionLinkAlignItems}
                  >
                    {captionLink}
                  </StyledText>
                ) : null}
              </StyledText>
            ) : null}
            {subCaption ? (
              <StyledText
                variant={captionVariant}
                color="secondaryContent"
                textDecorationLine={textDecorationLine}
              >
                {subCaption}
              </StyledText>
            ) : null}
          </Box>

          {placeholder || placeholderCaption || rightContent ? (
            <RowRight
              row
              alignItems="center"
              justifyContent="flex-end"
              flex={placeholder || placeholderCaption ? 1 : undefined}
            >
              {placeholder || placeholderCaption ? (
                <RowRight
                  opacity={disabled ? 0.25 : 1}
                  flex={1}
                  maxWidth={placeholderContainerMaxWidth}
                  ml={0}
                >
                  {placeholder ? (
                    <StyledText
                      color={placeholderColor}
                      numberOfLines={placeholderNumberOfLines}
                      textAlign="right"
                      textDecorationLine={textDecorationLine}
                      variant={placeholderVariant}
                      testID={placeholderTestId}
                    >
                      {placeholder}
                    </StyledText>
                  ) : null}
                  {placeholderCaption ? (
                    <StyledText
                      variant="caption"
                      textAlign="right"
                      numberOfLines={1}
                      textDecorationLine={textDecorationLine}
                    >
                      {placeholderCaption}
                    </StyledText>
                  ) : null}
                </RowRight>
              ) : null}
              {rightContent}
            </RowRight>
          ) : null}

          {children}
        </Box>
      </Box>
    );
  },
);
