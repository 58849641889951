import * as React from 'react';
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Box } from '../atoms/Box';
import { StyledText } from '../atoms/StyledText';

type Props = {
  icon?: React.ReactNode | null;
  title?: string | null;
  description?: string | null;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export const EmptyState: React.FC<Props> = ({
  icon,
  title,
  children,
  description,
  style,
}) => (
  <Box flex={1} centered p="m" style={style}>
    {icon}
    <Box my="m" centered>
      {title ? (
        <StyledText variant="headerSmall" textAlign="center">
          {title}
        </StyledText>
      ) : null}
      {description ? (
        <StyledText
          color="secondaryContent"
          textAlign="center"
          style={styles.wordBreak}
        >
          {description}
        </StyledText>
      ) : null}
    </Box>
    {children}
  </Box>
);

const styles = StyleSheet.create({
  // @ts-expect-error This style is needed in web so the text could wrap when it's too long
  wordBreak: Platform.select({
    web: { wordBreak: 'break-all' },
  }),
});
