import { connect, FormikProps } from 'formik';
import {
  handleTextInput as formikHandleTextInput,
  withFocus,
  withFormikControl,
  withInputTypeProps,
} from 'react-native-formik';
import { compose, withProps } from 'recompose';

import {
  alphaNumericOnlyInput,
  capitalizeInput,
  escapeChars,
  escapeEmojis,
} from './stringHelpers';

export function shouldDisableSubmit<Values>(
  { isValid, isSubmitting, dirty }: FormikProps<Values>,
  allowClean?: boolean,
): boolean {
  const submittingOrInvalid = isSubmitting || !isValid;
  if (allowClean) return submittingOrInvalid;

  return submittingOrInvalid || !dirty;
}

export type HandleTextInputProps = {
  formik: { dirty: boolean; isSubmitting: boolean };
  setFieldValue: (value: string) => void;
  setFieldTouched: (touched: boolean, validate: boolean) => void;
  onChangeText: (text: string) => void;
  onBlur: () => void;
  autoCapitalise?: 'word' | 'sentence';
  alphaNumericOnly?: boolean;
  escapeEmoji?: boolean;
  escapeCharsBy?: RegExp;
};

export const handleTextInput: typeof formikHandleTextInput = compose(
  withFormikControl,
  withFocus,
  withInputTypeProps,
  connect,
  withProps(
    ({
      formik: { dirty, isSubmitting },
      setFieldValue,
      setFieldTouched,
      onChangeText,
      onBlur,
      autoCapitalise,
      alphaNumericOnly = false,
      escapeEmoji = false,
      escapeCharsBy,
    }: HandleTextInputProps) => ({
      onChangeText: (originalText: string) => {
        let formattedText = originalText;
        if (autoCapitalise) {
          formattedText = capitalizeInput(formattedText, autoCapitalise);
        }

        if (alphaNumericOnly) {
          formattedText = alphaNumericOnlyInput(formattedText);
        }

        if (escapeEmoji) {
          formattedText = escapeEmojis(formattedText);
        }

        if (escapeCharsBy) {
          formattedText = escapeChars(formattedText, escapeCharsBy);
        }

        setFieldValue(formattedText);
        if (onChangeText) onChangeText(formattedText);
      },
      onBlur: () => {
        // validate onBlur only while not submitting
        // this prevents validating twice in succession when clicking 'done' on keyboard - first onSubmitEditing, then onBlur
        setFieldTouched(true, !isSubmitting && dirty);
        if (onBlur) onBlur();
      },
    }),
  ),
);

/**
 * Determine whether user can skip pass through the Form
 * If initial values contains data, user can skip to fill the Form. Else, user needs to fill the data.
 *
 * This check is useful for implementing Form inside Wizard because we do not allow clean form to be submitted by default.
 *
 * Problem we're trying to solve:
 * Assume there is a wizard with flow Form 1 -> Form 2 -> Form 3 -> Form 4
 * 1. User enters Form 1 for the first time, initialValues is still undefined, hence user needs to fill the value
 * 2. User enters Form 2 for the first time, initialValues is still undefined, hence user needs to fill the value
 * 3. User enters Form 3 for the first time, initialValues is still undefined, hence user needs to fill the value
 * 4. User went to go back to Form 2, update the value. This means screen in Form 3 got unmounted.
 * 5. User go back to Form 3 & find that they can't continue their progress, because by default we didn't allow clean form to `submit` / continue. We need to use this function to determine
 *
 * @param formValues Form Initial Values
 * @param ignoredFieldNames List of field name that will be ignored while checking
 * @returns boolean if form allowed to be clean
 */
export function isFormAllowedToBeClean<T extends Record<string, unknown>>(
  formValues: T,
  ignoredFieldNames?: Array<keyof T>,
) {
  const keys = Object.keys(formValues);

  for (const key of keys) {
    if (ignoredFieldNames != null && ignoredFieldNames.includes(key)) {
      // When ignoredFieldNames is provided, check if `key` is included in the list, if so ignore & continue to the next key loop
      // eslint-disable-next-line no-continue
      continue;
    }
    if (formValues[key as keyof T] == null) {
      return false;
    }
  }
  return true;
}
