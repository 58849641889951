export function getAmountPickerValue(
  value: number,
  action: 'add' | 'subtract',
  minimum = 0,
  maximum = Number.MAX_SAFE_INTEGER,
): number {
  const unit = Math.floor(Math.log10(value));
  const steps = 10 ** (value === 0 || unit - 1 === 0 ? 1 : unit - 1);
  const isRounded = value % steps !== 0;

  if (action === 'add') {
    const newValue = isRounded
      ? Math.ceil(value / steps) * steps
      : value + steps;
    return Math.min(newValue, maximum);
  }

  const newValue = isRounded
    ? Math.floor(value / steps) * steps
    : value - steps;
  return Math.max(newValue, minimum);
}
