import { Text } from 'dripsy';
import { useContext, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { BorrowerV2ScreenProps } from '../navigation/types';
import { inviteCoBorrowerFormDataAtom } from '../recoil/coBorrowerFormAtom';
import {
  InviteCoBorrowerForm,
  InviteCoBorrowerFormProps,
} from './components/InviteCoBorrowerForm';
import { InviteCoBorrowerFormValues } from './utils/forms';
import { inviteCoBorrowerErrorEmitter } from './utils/inviteCoBorrowerErrorEmitter';
import { mapInviteCoBorrowerErrorToFormError } from './utils/mapInviteCoBorrowerMutationError';
import { useInviteCoBorrower } from './utils/useInviteCoBorrower';

export type Props = BorrowerV2ScreenProps<Screen.BORROWER_V2_INVITE_COBORROWER>;

export function InviteCoBorrowerV2({ route, navigation }: Props) {
  const { loanApplicationId, _errorMessage } = route.params ?? {};

  const { flags } = useContext(FeatureFlagsContext);
  const enableDuplicateCoBorrowerHandler =
    flags.ENABLE_DUPLICATE_CO_BORROWER_HANDLER;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToBorrowerScreen = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  };

  const setCoBorrowerFormValues = useSetRecoilState(
    inviteCoBorrowerFormDataAtom,
  );
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    inviteCoBorrowerErrorEmitter.on('formLevelError', ({ type, details }) => {
      setFormError(mapInviteCoBorrowerErrorToFormError(type, details));
    });
    inviteCoBorrowerErrorEmitter.on('clearError', () => {
      setFormError(null);
    });

    return () => {
      inviteCoBorrowerErrorEmitter.off('formLevelError');
      inviteCoBorrowerErrorEmitter.off('clearError');
    };
  }, []);

  const { inviteCoBorrower, loading } = useInviteCoBorrower({
    loanApplicationId: loanApplicationId ?? '',
    navigateBackToBorrowerScreen,
  });

  const onSubmit = (values: InviteCoBorrowerFormValues) => {
    if (!enableDuplicateCoBorrowerHandler) {
      inviteCoBorrower({
        values,
        shouldNavToInviteCoBorrowerScreenOnError: false,
      });
      return;
    }
    setCoBorrowerFormValues(values);
    navigation.navigate(ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION, {
      loanApplicationId: loanApplicationId ?? '',
    });
  };

  if (!loanApplicationId) {
    return (
      <ModalScreenContainer
        onClose={navigateBackToBorrowerScreen}
        headerText={t('Content.InviteCoBorrower.Title')}
        hideBackButton
      >
        <Text sx={{ mb: '$32' }}>
          {t('Content.InviteCoBorrower.Description')}
        </Text>
        <EmptyState
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }
  return (
    <InviteCoBorrowerV2Loaded
      screen={Screen.BORROWER_V2_INVITE_COBORROWER}
      onCloseModal={navigateBackToBorrowerScreen}
      onSubmit={onSubmit}
      loading={loading}
      errorMessage={_errorMessage || formError}
    />
  );
}

type InviteCoBorrowerProps = InviteCoBorrowerFormProps & {
  errorMessage: string | null;
} & Partial<{
    loading: boolean;
    onCloseModal: () => void;
  }>;

export function InviteCoBorrowerV2Loaded(props: InviteCoBorrowerProps) {
  const {
    screen,
    errorMessage,
    loading,
    onSubmit,
    onCloseModal = () => {},
  } = props;

  return (
    <ModalScreenContainer
      onClose={onCloseModal}
      headerText={t('Content.InviteCoBorrower.Title')}
      hideBackButton
      scrollable
      loading={loading}
    >
      <ErrorRow message={errorMessage} mb="l" />
      <Text sx={{ mb: '$32' }}>
        {t('Content.InviteCoBorrower.Description')}
      </Text>
      <InviteCoBorrowerForm
        screen={screen}
        onSubmit={onSubmit}
        isSubmitting={loading}
      />
    </ModalScreenContainer>
  );
}
