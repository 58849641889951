import { gql } from '@apollo/client';

import {
  Identity_Check_Result_Enum,
  UpdateBorrowerV2OutputFragment,
} from '../../generated/graphql';

export const UPDATE_BORROWER_V2 = gql`
  mutation UpdateBorrowerV2($data: update_borrower_v2_input!) {
    update_borrower_v2(data: $data) {
      ...UpdateBorrowerV2Output
    }
  }

  fragment UpdateBorrowerV2Output on update_borrower_v2_output {
    id
    identity_check_result
    frankieone_entity_id
    failed_id_check_count
    max_identity_check_attempt
    borrower {
      id
      latest_full_name
      user_identity_profile {
        id
      }
    }
  }
`;

export type HandleIdentityCheckResultParams = {
  data: Pick<
    UpdateBorrowerV2OutputFragment,
    | 'failed_id_check_count'
    | 'identity_check_result'
    | 'borrower'
    | 'max_identity_check_attempt'
  >;
  navigateToYourInformation: () => void;
  navigateToIdvPendingActionSheet: () => void;
  navigateToBorrowers: () => void;
  isSummary: boolean;
};

export function handleIdentityCheckResult({
  data,
  navigateToYourInformation,
  navigateToBorrowers,
  navigateToIdvPendingActionSheet,

  isSummary,
}: HandleIdentityCheckResultParams) {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    failed_id_check_count,
    identity_check_result,
    max_identity_check_attempt,
  } = data;

  if (
    (identity_check_result === Identity_Check_Result_Enum.Fail ||
      identity_check_result === Identity_Check_Result_Enum.FailManual ||
      identity_check_result == null) &&
    !isSummary
  ) {
    navigateToYourInformation();
    return;
  }
  // If failed_id_check_count is greater than 2, show identity verification pending
  if (failed_id_check_count >= max_identity_check_attempt) {
    navigateToIdvPendingActionSheet();
    return;
  }

  // If the result is PASS / PASS_MANUAL / REFER / OTHER, navigate to Borrowers scene
  navigateToBorrowers();
}
