import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { useEffect } from 'react';

import { SegmentedProgress } from '../../ui/organisms/SegmentedProgress';

export const useLoanApplicationHeaderProgress = <
  T extends NavigationProp<ParamListBase>,
>(
  navigation: T,
  { current, total = 6 }: { current: number; total?: number },
): void => {
  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => <SegmentedProgress current={current} total={total} />,
    });
  }, [navigation, current, total]);
};
