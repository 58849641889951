import { ReactNode, useCallback, useMemo, useState } from 'react';

import { createContext } from '../../utils/createContext';
import { CombinedExpensesFormValues } from './forms';

export type ExpensesFormValues = {
  expenses: CombinedExpensesFormValues;
};

const [useExpensesForm, BaseExpensesFormProvider] = createContext<{
  values: ExpensesFormValues;
  setExpenses: (value: Partial<CombinedExpensesFormValues>) => void;
}>('ExpensesForm');

function ExpensesFormProvider({
  initialValues,
  ...otherProps
}: {
  initialValues: ExpensesFormValues;
  children: ReactNode;
}) {
  const [expenses, updateExpenses] = useState(initialValues.expenses);

  const setExpenses = useCallback(
    (value: Partial<CombinedExpensesFormValues>) => {
      updateExpenses((prevData) => ({ ...prevData, ...value }));
    },
    [],
  );
  const memoizedValues = useMemo(
    () => ({
      values: { expenses },
      setExpenses,
    }),
    [expenses, setExpenses],
  );
  return <BaseExpensesFormProvider value={memoizedValues} {...otherProps} />;
}

export { useExpensesForm, ExpensesFormProvider };
