import { createStackNavigator } from '@react-navigation/stack';

import { HomeLoanInsightsStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { DefaultModalV2NavigatorOptions } from '../../utils/NavConstants';
import { DiscountBumpInsightsScreen } from '../screens/DiscountBumpInsightsScreen';
import { InterestSavingsInsightsScreen } from '../screens/InterestSavingsInsightsScreen';
import { PropertyValueInsightsScreen } from '../screens/PropertyValueInsightsScreen';
import { TermRemainingInsightsScreen } from '../screens/TermRemainingInsightsScreen';

const HomeLoanInsightsStack =
  createStackNavigator<HomeLoanInsightsStackParams>();

export const HomeLoanInsightsNavigator = () => (
  <HomeLoanInsightsStack.Navigator
    screenOptions={DefaultModalV2NavigatorOptions}
  >
    <HomeLoanInsightsStack.Screen
      name={Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING}
      component={TermRemainingInsightsScreen}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_INSIGHTS_TERM_REMAINING),
      }}
    />
    <HomeLoanInsightsStack.Screen
      name={Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP}
      component={DiscountBumpInsightsScreen}
      options={{
        ...DefaultModalV2NavigatorOptions,
        title: getPageTitle(Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP),
      }}
    />
    <HomeLoanInsightsStack.Screen
      name={Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS}
      component={InterestSavingsInsightsScreen}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS),
      }}
    />
    <HomeLoanInsightsStack.Screen
      name={Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE}
      component={PropertyValueInsightsScreen}
      options={{
        title: getPageTitle(Screen.HOME_LOAN_INSIGHTS_PROPERTY_VALUE),
      }}
    />
  </HomeLoanInsightsStack.Navigator>
);
