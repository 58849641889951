import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function LiabilityHomeLoanIcon({ color, ...otherProps }: SvgIconProps) {
  return (
    <Svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.016 12.613l.645.569c.3.258.73.258.988-.043l.903-1.032a.64.64 0 00-.043-.945l-2.493-2.234V3.04c0-.344-.343-.687-.687-.687h-2.75a.707.707 0 00-.688.687v2.234L13.51 1.451a1.662 1.662 0 00-2.278 0l-11 9.711a.64.64 0 00-.043.945l.903 1.032c.257.3.687.3.988.043l.687-.607v9.716c0 .387.301.688.688.688h17.875c.344 0 .687-.301.687-.688v-9.678zm-12.891.595c.526 0 1.05-.198 1.474-.59l.005-.004a2.122 2.122 0 000-2.99l-.005-.005C10.18 9.233 9.658 9 9.125 9c-.562 0-1.085.232-1.504.619l-.005.005a2.122 2.122 0 000 2.99l.005.005c.424.392.949.588 1.504.588zm-.574-1.54a.754.754 0 010-1.098l.006-.005a.805.805 0 01.568-.25c.184 0 .382.094.538.25l.005.005a.754.754 0 010 1.098l-.005.005a.758.758 0 01-.538.22.778.778 0 01-.568-.22l-.006-.005zm8.331-1.845l-.007-.01-.68-.68-.01-.007c-.172-.13-.424-.151-.591.016l-8.46 8.466c-.167.167-.145.419-.016.591l.007.01.68.68.01.008c.172.13.424.15.591-.016l8.46-8.467c.167-.167.145-.418.016-.59zm-3.48 5.58c.418-.386.941-.618 1.503-.618.533 0 1.056.233 1.474.619l.005.005a2.122 2.122 0 010 2.99l-.005.005a2.166 2.166 0 01-1.474.588c-.555 0-1.08-.197-1.504-.588l-.005-.005a2.122 2.122 0 010-2.99l.005-.005zm2.046 2.05a.754.754 0 000-1.098l-.005-.005c-.156-.156-.354-.25-.538-.25a.805.805 0 00-.568.25l-.005.005a.754.754 0 000 1.098l.005.005c.144.143.338.22.568.22a.758.758 0 00.539-.22l.004-.005z"
        fill={color}
      />
    </Svg>
  );
}
