import {
  Expense_Type_Enum,
  Expense_Type_Input_Enum,
  HouseholdForSummaryFragment,
} from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';

type SortHouseholdExpensesFields = {
  expensesOrder: Expense_Type_Input_Enum[] | null | undefined;
  unorderedHouseholdExpenses: HouseholdForSummaryFragment['unordered_household_expenses'];
};

export function sortHouseholdExpensesFields({
  expensesOrder,
  unorderedHouseholdExpenses,
}: SortHouseholdExpensesFields) {
  return (
    expensesOrder
      ?.map((order) => {
        const expenseOrder = parseEnumType(Expense_Type_Enum, order);
        const foundOrderedExpenses = unorderedHouseholdExpenses.find(
          (expense) => expense.expense_type === expenseOrder,
        );
        return foundOrderedExpenses;
      })
      .filter(isNotNullOrUndefined) || []
  );
}
