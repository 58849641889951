import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { TestID } from '../../../testID/constants';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const GradientBallIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    testID={TestID.Icon.GradientBallIcon}
    width={resizeIconWidth(16, 16, size)}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <Path
      d="M8.22852 15.591C6.85352 15.591 5.59784 15.2557 4.46148 14.5853C3.32511 13.9035 2.41602 12.9944 1.73421 11.858C1.06375 10.7217 0.728523 9.46597 0.728523 8.09097C0.728523 6.70461 1.06375 5.44892 1.73421 4.32392C2.41602 3.18756 3.32511 2.28415 4.46148 1.6137C5.59784 0.931878 6.85352 0.590969 8.22852 0.590969C9.61489 0.590969 10.8706 0.931878 11.9956 1.6137C13.1319 2.28415 14.0353 3.18756 14.7058 4.32392C15.3876 5.44892 15.7285 6.70461 15.7285 8.09097C15.7285 9.46597 15.3876 10.7217 14.7058 11.858C14.0353 12.9944 13.1319 13.9035 11.9956 14.5853C10.8706 15.2557 9.61489 15.591 8.22852 15.591Z"
      fill="url(#paint0_linear_2693_260654)"
      fillOpacity="0.95"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_2693_260654"
        x1="-1"
        y1="-1.49994"
        x2="15"
        y2="15.0001"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF7BAD" />
        <Stop offset="0.979167" stopColor="#5188EE" />
      </LinearGradient>
    </Defs>
  </Svg>
);
