import { MouseEventHandler, TouchEventHandler, useCallback } from 'react';
import * as React from 'react';

type Props = {
  pointsXPosInPixel: number[];
  setActivePointIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const TooltipInteractiveArea = ({
  pointsXPosInPixel,
  setActivePointIndex,
}: Props) => {
  const handleOnTouchMoveInMobileWeb = useCallback<
    TouchEventHandler<HTMLDivElement>
  >(
    (e) => {
      const currentTouchXPos = Math.max(
        (e.targetTouches[0]?.pageX || 0) -
          e.currentTarget.getBoundingClientRect().left,
        0,
      );

      const closestPointIndex = pointsXPosInPixel.reduce(
        (prev, curr, index) =>
          Math.abs(curr - currentTouchXPos) <
          Math.abs((pointsXPosInPixel[prev] || 0) - currentTouchXPos)
            ? index
            : prev,
        0,
      );

      setActivePointIndex(closestPointIndex);
    },
    [pointsXPosInPixel, setActivePointIndex],
  );

  const handleOnMouseMoveInDesktopWeb = useCallback<
    MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      const currentTouchXPos = Math.max(
        (e.pageX || 0) - e.currentTarget.getBoundingClientRect().left,
        0,
      );

      const closestPointIndex = pointsXPosInPixel.reduce(
        (prev, curr, index) =>
          Math.abs(curr - currentTouchXPos) <
          Math.abs((pointsXPosInPixel[prev] || 0) - currentTouchXPos)
            ? index
            : prev,
        0,
      );

      setActivePointIndex(closestPointIndex);
    },
    [pointsXPosInPixel, setActivePointIndex],
  );

  return (
    <div
      style={{
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
      onTouchMove={handleOnTouchMoveInMobileWeb}
      onTouchEnd={() => setActivePointIndex(-1)}
      onMouseMove={handleOnMouseMoveInDesktopWeb}
      onMouseLeave={() => setActivePointIndex(-1)}
    />
  );
};
