import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function SafariShareIcon({
  size,
  color,
  opacity,
  ...props
}: SvgIconProps) {
  const height = resizeIconWidth(17, 16, size);

  return (
    <Svg width={size} height={height} fill="none" {...props}>
      <Path
        d="M8.916 11.661a.734.734 0 0 0 .736-.723V4.755l-.063-.927.33.489.768.812c.12.14.292.21.476.21.336 0 .635-.248.635-.61a.592.592 0 0 0-.197-.444L9.5 2.26C9.303 2.07 9.113 2 8.916 2c-.197 0-.387.07-.578.26L6.231 4.285a.592.592 0 0 0-.197.444c0 .362.299.61.635.61.178 0 .355-.07.476-.21l.768-.812.33-.495-.057.933v6.183c0 .387.324.723.73.723ZM5.241 16.88h7.35c1.454 0 2.241-.794 2.241-2.228v-6.1c0-1.435-.787-2.222-2.24-2.222H10.96v1.638h1.454c.507 0 .78.247.78.787v5.694c0 .54-.273.787-.78.787H5.418c-.507 0-.78-.248-.78-.787V8.754c0-.54.273-.787.78-.787h1.486V6.329H5.24C3.793 6.33 3 7.11 3 8.551v6.1c0 1.44.793 2.228 2.24 2.228Z"
        fill={color}
        opacity={opacity}
      />
    </Svg>
  );
}
