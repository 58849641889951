import { gql } from '@apollo/client';

import { TestID } from '../../../testID/constants';
import {
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchGetFinancialDeclarationOptionsQuery,
  refetchGetLoanApplicantsQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  refetchYourFinancialsQuery,
  useRemoveCoBorrowerMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props = ActionSheetScreenProps<ActionSheetType.REMOVE_CO_BORROWER_V2>;

export const REMOVE_COBORROWER_MUTATION = gql`
  mutation RemoveCoBorrower($id: uuid!) {
    remove_borrower(id: $id) {
      success
    }
  }
`;

export function RemoveCoBorrowerActionSheetV2({ navigation, route }: Props) {
  const {
    borrowerFullName,
    borrowerId,
    loanApplicationId = '',
  } = route.params || {};
  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const [removeCoBorrower, { loading, error }] = useRemoveCoBorrowerMutation();

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onRemoveCoBorrower = async () => {
    const loanApplicationVariable = { loanApplicationId };

    const [_, err] = await safelyCallMutation(removeCoBorrower, {
      variables: { id: borrowerId ?? '' },
      refetchQueries: [
        refetchGetLoanApplicantsQuery(loanApplicationVariable),
        refetchYourFinancialsQuery(loanApplicationVariable),
        refetchGetFinancialDeclarationOptionsQuery(loanApplicationVariable),
        refetchYourDebtsQuery(loanApplicationVariable),
        refetchSetupLoanScreenQuery(loanApplicationVariable),
        refetchReviewLoanApplicationQuery(loanApplicationVariable),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: { loanApplicationId, borrowerId },
      },
    });

    if (err == null) {
      tryNavigateBackToSummary(() => {
        navigateToLoanApplicationScreen({
          section: LoanApplicationSection.Borrowers,
        });
      });
    }
  };
  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      showCloseButton={false}
      isRowButton
      title={t('Content.RemoveBorrower.Title')}
      message={t('Content.RemoveBorrower.Content', { borrowerFullName })}
      errorMessage={error ? t('Content.Common.Error.FailRemoveBorrower') : null}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.RemoveBorrower.CancelButton,
          onPress: navigation.goBack,
          disabled: loading,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.RemoveBorrower.Button'),
          testID: TestID.RemoveBorrower.RemoveButton,
          onPress: onRemoveCoBorrower,
          showSpinner: loading,
          disabled: loading,
          isDestructive: true,
          flex: 1,
        },
      ]}
    />
  );
}
