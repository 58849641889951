import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const TaskRequestIcon = ({ color, size, ...props }: SvgIconProps) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 20 48"
    fill={color ?? 'none'}
    {...props}
  >
    <Path
      fill="#CCC"
      d="M23.25 9v18.75a1.5 1.5 0 0 1-1.5 1.5H2.25a1.5 1.5 0 0 1-1.5-1.5V2.25a1.5 1.5 0 0 1 1.5-1.5H15L23.25 9Z"
    />
    <Path fill="#B8B8B8" d="M15 .75V7.5A1.5 1.5 0 0 0 16.5 9h6.75L15 .75Z" />
    <Path
      fill="#353535"
      d="M18.375 15.75H5.625a.375.375 0 0 1-.375-.375v-.75c0-.207.168-.375.375-.375h12.75c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375ZM16.875 18.75H5.625a.375.375 0 0 1-.375-.375v-.75c0-.207.168-.375.375-.375h11.25c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375ZM18.375 21.75H5.625a.375.375 0 0 1-.375-.375v-.75c0-.207.168-.375.375-.375h12.75c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375ZM16.875 24.75H5.625a.375.375 0 0 1-.375-.375v-.75c0-.207.168-.375.375-.375h11.25c.207 0 .375.168.375.375v.75a.375.375 0 0 1-.375.375Z"
    />
  </Svg>
);
