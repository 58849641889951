import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { captureException } from '../sentry';
import { Alert } from '../ui/atoms/Alert';
import { AuthContextValue } from './context';
import { SecureStore } from './secureStore';

export const AUTH0_ERROR_MESSAGE = {
  UNAUTHORIZED: 'Access denied',
  INVALID_STATE: 'Invalid state',
};

export const ACCESS_TOKEN_RETRY_COUNT = {
  max: 3,
  storageKey: 'unloan-access-token-retry-count',
};

export type UseHandleAuthErrorParams = {
  logout: Auth0ContextInterface['logout'];
  error: Error | undefined;
};

export function useHandleAuthError(authContextValue: AuthContextValue) {
  const { error } = useAuth0();
  const { logout, getAccessToken } = authContextValue;

  useEffect(() => {
    (async () => {
      if (error) {
        if (error.message.includes(AUTH0_ERROR_MESSAGE.UNAUTHORIZED)) {
          logout();
          Alert.alert(t('Content.Common.Error.UnauthorizedAccess'));
          return;
        }

        // Only retry getting access token when retry attempt is less than 3
        if (
          error.message.includes(AUTH0_ERROR_MESSAGE.INVALID_STATE) &&
          SecureStore.isAvailable
        ) {
          const retryAttemptRes = await SecureStore.getItem(
            ACCESS_TOKEN_RETRY_COUNT.storageKey,
          );

          const retryAttempt = Number(retryAttemptRes || 0);
          if (retryAttempt > ACCESS_TOKEN_RETRY_COUNT.max) {
            return;
          }
          await SecureStore.setItem(
            ACCESS_TOKEN_RETRY_COUNT.storageKey,
            String(retryAttempt + 1),
          );
          await getAccessToken();
          return;
        }

        logout();
        captureException(t('Content.Common.Error.UnableToLogin'), null, error);
        Alert.alert(t('Content.Common.Error.UnableToLogin'));
      }
    })();
  }, [error, logout, getAccessToken]);
}
