import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';

type RefetchFn = (() => void) | (() => Promise<void>);

export const REFETCH_THROTTLE_WAIT_TIME_IN_MS = 2000;

export function useThrottleRefetch(refetch: RefetchFn) {
  const refetchRef = useRef(refetch);
  useEffect(() => {
    refetchRef.current = refetch;
  });

  const throttledRef = useRef(
    throttle(() => refetchRef.current(), REFETCH_THROTTLE_WAIT_TIME_IN_MS, {
      leading: true,
      trailing: true,
    }),
  );

  return { throttled: throttledRef.current };
}
