import { isNotNullOrUndefined } from '@unloan/common-ui';

import { Top_Up_Reason_Enum } from '../../generated/graphql';
import { formatCurrency } from '../../utils/currencyHelpers';
import { joinListOfString } from '../../utils/stringHelpers';

type CashOut = {
  reason: Top_Up_Reason_Enum;
  amount: number | null | undefined;
  otherReason?: string | null;
};
const PREFIX =
  'Content.YourUnloan.TopUpVariant.BalanceRemaining.Caption' as const;

const getTopUpReasonCaption = ({ reason, amount, otherReason }: CashOut) => {
  switch (reason) {
    case Top_Up_Reason_Enum.YourHome: {
      return amount
        ? (t(`${PREFIX}.YourHome`, {
            amount: formatCurrency(amount),
          }) as string)
        : undefined;
    }
    case Top_Up_Reason_Enum.InvestmentProperty: {
      return amount
        ? (t(`${PREFIX}.InvestmentProperty`, {
            amount: formatCurrency(amount),
          }) as string)
        : undefined;
    }
    case Top_Up_Reason_Enum.FinancialInvestment: {
      return amount
        ? (t(`${PREFIX}.FinancialInvestment`, {
            amount: formatCurrency(amount),
          }) as string)
        : undefined;
    }
    case Top_Up_Reason_Enum.PersonalUse: {
      return amount
        ? (t(`${PREFIX}.PersonalUse`, {
            amount: formatCurrency(amount),
          }) as string)
        : undefined;
    }
    case Top_Up_Reason_Enum.Other: {
      return amount
        ? (t(`${PREFIX}.Other`, {
            amount: formatCurrency(amount),
            reason: otherReason ?? 'other reasons',
          }) as string)
        : undefined;
    }
    case Top_Up_Reason_Enum.Property:
    default: {
      return undefined;
    }
  }
};

export const createTopUpTotalLoanAmountCaption = ({
  unloanLiabilityBalance,
  cashoutList,
  redrawAmount,
  consolidatedDebtsAmount,
}: {
  unloanLiabilityBalance: number;
  cashoutList?: CashOut[];
  redrawAmount?: number;
  consolidatedDebtsAmount?: number;
}) => {
  const currentLoanCaption = t(`${PREFIX}.UnpaidBalance`, {
    unpaidBalance: formatCurrency(unloanLiabilityBalance),
  }) as string;

  const redrawCaption = redrawAmount
    ? (t(`${PREFIX}.AvailableRedraw`, {
        availableRedraw: formatCurrency(redrawAmount),
      }) as string)
    : undefined;

  const topUpRequestedAmount =
    (cashoutList?.reduce((prev, item) => prev + (item.amount ?? 0), 0) ?? 0) +
    (consolidatedDebtsAmount ?? 0);

  const totalTopUpAmountCaption = t(`${PREFIX}.TotalTopUp`, {
    topUpRequestedAmount: formatCurrency(topUpRequestedAmount),
  }) as string;

  const consolidatedDebtsCaption = consolidatedDebtsAmount
    ? (t(`${PREFIX}.ConsolidatedDebts`, {
        amount: formatCurrency(consolidatedDebtsAmount),
      }) as string)
    : undefined;

  const updatedLoanAmountCaptions = [
    currentLoanCaption,
    redrawCaption,
    totalTopUpAmountCaption,
  ].filter(isNotNullOrUndefined);

  const topUpReasonsCaptions =
    [
      ...(cashoutList?.map((cashout) => getTopUpReasonCaption(cashout)) ?? []),
      consolidatedDebtsCaption,
    ].filter(isNotNullOrUndefined) ?? [];

  return `${joinListOfString(updatedLoanAmountCaptions)} (${joinListOfString(
    topUpReasonsCaptions,
  )}).`;
};
