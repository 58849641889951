import 'react-phone-number-input/style.css';
import './styles/phoneInput.module.css';

import { useDripsyTheme, useSx } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';
import { Country } from 'react-phone-number-input';

import {
  DEFAULT_PHONE_COUNTRY_CODE,
  E164Mobile,
  isValidMobile,
} from '../../utils/phoneHelpers';
import { stringIsNotNullOrEmpty } from '../../utils/stringHelpers';
import {
  PhoneInputForFormikProps,
  PhoneInputV2Props,
  PhoneNumberData,
  WebPhoneInput,
} from './PhoneInputTypes';
import { TextInput } from './TextInput';

const PHONE_INPUT_WEB_CLASS_NAME = 'PhoneInputWebV2';

export const PhoneInput = React.forwardRef<RNTextInput, PhoneInputV2Props>(
  ({ onChangePhoneNumber, value, sx: sxProp, ...otherProps }, ref) => {
    const { theme } = useDripsyTheme();
    const sx = useSx();

    const handleNumberChange = useCallback(
      (phoneNumber?: E164Mobile) => {
        onChangePhoneNumber({
          ...value,
          phoneNumber: phoneNumber ?? '',
          isValid: isValidMobile(phoneNumber ?? '', value?.countryIso),
        });
      },
      [onChangePhoneNumber, value],
    );

    const handleCountryChange = useCallback(
      (countryCode?: string) => {
        onChangePhoneNumber({
          ...value,
          phoneNumber: value?.phoneNumber ?? '',
          countryIso: countryCode,
          isValid: isValidMobile(value?.phoneNumber ?? '', countryCode),
        });
      },
      [onChangePhoneNumber, value],
    );

    return (
      <WebPhoneInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error Need to use the correct ref type
        ref={ref}
        {...otherProps}
        onCountryChange={handleCountryChange}
        onChange={handleNumberChange}
        value={value?.phoneNumber || ''}
        inputComponent={TextInput}
        defaultCountry={
          stringIsNotNullOrEmpty(value?.countryIso)
            ? (value?.countryIso as Country)
            : DEFAULT_PHONE_COUNTRY_CODE
        }
        containerComponent="div"
        className={PHONE_INPUT_WEB_CLASS_NAME}
        // This style prop will be passed to the `containerComponent`
        // https://gitlab.com/catamphetamine/react-phone-number-input/-/blob/master/source/PhoneInputWithCountry.js#L467
        style={{
          gap: theme.space.$16,
          ...sx(sxProp ?? {}),
        }}
        // This sx will be passed to component used for `inputComponent`
        // https://gitlab.com/catamphetamine/react-phone-number-input/-/blob/master/source/PhoneInputWithCountry.js#L494
        sx={{
          flex: 1,
        }}
      />
    );
  },
);

PhoneInput.displayName = 'PhoneInputV2';

export const PhoneInputForFormik = React.forwardRef<
  RNTextInput,
  PhoneInputForFormikProps
>(
  (
    {
      setFieldTouched,
      setFieldValue,
      formik,
      onBlur: onBlurProp,
      ...otherProps
    },
    ref,
  ) => {
    const onBlur = React.useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        const { isSubmitting, dirty } = formik || {};
        setFieldTouched?.(true, !isSubmitting && dirty);
        onBlurProp?.(event);
      },
      [formik, onBlurProp, setFieldTouched],
    );

    const onChangePhoneNumber = React.useCallback(
      (phoneNumberData: PhoneNumberData) => {
        setFieldValue?.(phoneNumberData);
      },
      [setFieldValue],
    );

    return (
      <PhoneInput
        ref={ref}
        {...otherProps}
        onBlur={onBlur}
        onChangePhoneNumber={onChangePhoneNumber}
      />
    );
  },
);

PhoneInputForFormik.displayName = 'PhoneInputForFormik';
