import { Text } from 'dripsy';

import { TestID } from '../../../../testID/constants';

export const Disclaimer = () => (
  <Text
    variant="caption"
    testID={TestID.Insights.TermRemaining.Disclaimer}
    sx={{ mt: '$24' }}
  >
    {t('Content.HomeLoan.Insights.TermRemaining.Disclaimer')}
  </Text>
);
