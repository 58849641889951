import { gql } from '@apollo/client';
import { isLast } from '@unloan/common-ui';

import {
  GetLoanApplicationTasks_LoanApplicationTaskItemFragmentDoc,
  Loan_Application_Task_Status,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  LoanApplicationTaskData,
  LoanApplicationTaskItem,
} from './LoanApplicationTaskItem';
import { LoanApplicationTaskSections } from './LoanApplicationTaskSection';

export const GetLoanApplicationTasks = gql`
  query GetLoanApplicationTasks($loanApplicationId: uuid!) {
    get_loan_application_tasks(loan_application_id: $loanApplicationId) {
      loan_application_tasks {
        ...GetLoanApplicationTasks_LoanApplicationTaskItem
      }
      allow_to_proceed
    }

    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
    }
  }

  ${GetLoanApplicationTasks_LoanApplicationTaskItemFragmentDoc}
`;

type LoanApplicationTasksProps = {
  loanApplicationId: string;
  tasks?: Array<LoanApplicationTaskData>;

  /**
   * Only relevant on screens where we have Idv task shown.
   * Currently only happen in FinaliseLoanApplication screen
   */
  currentLoggedInApplicantIDVLoading?: boolean;

  enableEvidenceScreenUplift: boolean;

  parentScreenName: Screen;
};

export function LoanApplicationTasks(props: LoanApplicationTasksProps) {
  const {
    loanApplicationId,
    tasks,
    currentLoggedInApplicantIDVLoading,
    enableEvidenceScreenUplift,
    parentScreenName,
  } = props;

  const incompleteTasksCount =
    tasks?.filter(
      (task) => task.status !== Loan_Application_Task_Status.Provided,
    ).length ?? 0;

  const itemsRemainingCaption =
    incompleteTasksCount > 0
      ? t('Content.FinaliseYourApplication.XItemsRemaining', {
          x: incompleteTasksCount,
        })
      : t('Content.FinaliseYourApplication.AllItemsComplete');

  return enableEvidenceScreenUplift ? (
    <LoanApplicationTaskSections
      loanApplicationId={loanApplicationId}
      currentLoggedInApplicantIDVLoading={currentLoggedInApplicantIDVLoading}
      enableEvidenceScreenUplift={enableEvidenceScreenUplift}
      allTasks={tasks ?? []}
      parentScreenName={parentScreenName}
    />
  ) : (
    <>
      <StyledText variant="caption" color="secondaryContent" py="s">
        {itemsRemainingCaption}
      </StyledText>
      {tasks?.map((task, index) => (
        <LoanApplicationTaskItem
          taskData={task}
          loanApplicationId={loanApplicationId}
          currentLoggedInApplicantIDVLoading={
            currentLoggedInApplicantIDVLoading
          }
          key={task.id}
          isLast={isLast(tasks, index)}
          enableEvidenceScreenUplift={enableEvidenceScreenUplift}
          parentScreenName={parentScreenName}
        />
      ))}
    </>
  );
}
