import { useContext } from 'react';

import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useStateOptionsQuery } from '../../Identification/hooks/useStateOptions';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { streetTypeOptions } from '../../utils/formOptions';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { BorrowerV2ScreenProps } from '../navigation/types';
import { BorrowerDetailsEditForm } from './components/BorrowerDetailsEditForm';
import { CoBorrowerDetails } from './components/CoBorrowerDetails';
import { BorrowerDetailsEditFormValues } from './utils/forms';
import { useGetBorrowerDetailsInitialValue } from './utils/useGetBorrowerDetailsInitialValue';
import { useUpdateBorrowerMailingAddress } from './utils/useUpdateBorrowerMailingAddress';

export type Props = BorrowerV2ScreenProps<Screen.BORROWER_V2_DETAILS>;

export function BorrowerDetailsV2({ navigation, route }: Props) {
  const {
    _storyErrorMessage,
    borrowerId = '',
    loanApplicationId = '',
    isCurrentLoggedInApplicant,
  } = route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { flags } = useContext(FeatureFlagsContext);
  const enableDuplicateCoBorrowerHandler =
    flags.ENABLE_DUPLICATE_CO_BORROWER_HANDLER;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToBorrowerScreen = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  };

  const navigateBackToPreviousMainPage = () =>
    tryNavigateBackToSummary(() => {
      navigateBackToBorrowerScreen();
    });

  const { data: stateOptions, loading: stateOptionsLoading } =
    useStateOptionsQuery();

  const {
    borrowerData,
    borrowerDetailLoading,
    isCurrentBorrower = isCurrentLoggedInApplicant ?? false,
    isPrimaryBorrower,
    initialFormValues,
    loanApplicationData,
  } = useGetBorrowerDetailsInitialValue(borrowerId, loanApplicationId);

  const queriesLoading = stateOptionsLoading || borrowerDetailLoading;

  const {
    updateBorrowerMailingAddress,
    errorMessage,
    loading: loadingUpdateUserMailingAddress,
  } = useUpdateBorrowerMailingAddress({
    loanApplicationId,
    applicantId: borrowerId,
  });
  const modalHeaderTitle = isCurrentBorrower
    ? t('Content.Borrower.YourInformation.ScreenTitle')
    : t('Content.Borrower.YourInformation.CoBorrowerScreenTitle');

  const propertySuggestionProps = usePropertySuggestionLoader();

  const navigateToRemoveCoBorrower = () => {
    if (enableDuplicateCoBorrowerHandler) {
      return;
    }
    navigation.navigate(ActionSheetType.REMOVE_CO_BORROWER_V2, {
      borrowerId,
      borrowerFullName: borrowerData.fullName,
      loanApplicationId,
    });
  };

  if (queriesLoading) {
    return (
      <ModalScreenContainer
        hideBackButton
        scrollable
        headerText={modalHeaderTitle}
        onClose={navigateBackToPreviousMainPage}
      >
        <ScreenLoadingContainer loading />
      </ModalScreenContainer>
    );
  }

  const { residentialAddress, mailingAddress } = borrowerData;

  const onSubmitEdit = async (values: BorrowerDetailsEditFormValues) => {
    const success = await updateBorrowerMailingAddress({
      values,
      mailingAddress,
      residentialAddress,
    });
    if (!success) {
      return;
    }
    navigateBackToPreviousMainPage();
  };

  // Removing primary borrower is not allowed
  const showRemoveCoBorrower =
    !isPrimaryBorrower && !enableDuplicateCoBorrowerHandler;

  const showCoBorrowerEmailInviteBanner =
    !isPrimaryBorrower &&
    !isCurrentLoggedInApplicant &&
    loanApplicationData.submittedAt === null &&
    loanApplicationData.conditionalApprovalStatus === null;

  return (
    <ModalScreenContainer
      scrollable
      hideBackButton
      headerText={modalHeaderTitle}
      onClose={navigateBackToPreviousMainPage}
      loading={loadingUpdateUserMailingAddress}
    >
      {isCurrentBorrower ? (
        <>
          <ErrorRow mb="l" message={_storyErrorMessage ?? errorMessage} />
          <BorrowerDetailsEditForm
            screen={Screen.BORROWER_V2_DETAILS}
            isSubmitting={loadingUpdateUserMailingAddress}
            initialFormValues={initialFormValues}
            stateOptions={stateOptions}
            streetTypeOptions={streetTypeOptions}
            onSubmit={onSubmitEdit}
            {...propertySuggestionProps}
            {...borrowerData}
          />
        </>
      ) : (
        <CoBorrowerDetails
          onPressDone={navigateBackToPreviousMainPage}
          onPressRemove={
            showRemoveCoBorrower ? navigateToRemoveCoBorrower : undefined
          }
          showCoBorrowerEmailInviteBanner={showCoBorrowerEmailInviteBanner}
          {...borrowerData}
        />
      )}
    </ModalScreenContainer>
  );
}
