import { useEffect } from 'react';
import {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { usePrevious } from '../../utils/hooks/usePrevious';

const DEFAULT_DURATION = 400;

const INITIAL_SCALE = 1;
const HOVER_SCALE = 1.1;

export function useCardScaleAnimatedStyle(isHover: boolean) {
  const scale = useSharedValue(INITIAL_SCALE);

  const previousValue = usePrevious(isHover);

  useEffect(() => {
    if (previousValue !== isHover) {
      scale.value = withTiming(isHover ? HOVER_SCALE : INITIAL_SCALE, {
        duration: DEFAULT_DURATION,
      });
    }
  }, [isHover, previousValue, scale]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  return animatedStyle;
}
