import { styled, Text, View } from 'dripsy';

import { Separator } from '../../../ui/atoms/Separator';
import { formatPhoneNumber } from '../../../utils/phoneHelpers';
import {
  FormatAddressInput,
  formatFullAddress,
} from '../../../utils/stringHelpers';

const SBodyTitle = styled(Text)({
  variant: 'text.sBody',
  fontWeight: '600',
});
const SBodyText = styled(Text)({
  variant: 'text.sBody',
});

export type BorrowerDataViewProps = {
  fullName: string;
  email: string;
  mobileNumber: string;
  residentialAddress?: FormatAddressInput | null;
  mailingAddress?: FormatAddressInput | null;
};
export function BorrowerDataView(props: BorrowerDataViewProps) {
  const { email, fullName, mobileNumber, residentialAddress, mailingAddress } =
    props;
  return (
    <View
      sx={{
        p: '$16',
        backgroundColor: '$inputBackground',
        borderRadius: '$card',
        mt: '$16',
      }}
    >
      <SBodyTitle>
        {t('Content.Borrower.YourInformation.Details.FullName')}
      </SBodyTitle>
      <SBodyText>{fullName}</SBodyText>
      <Separator my="m" />
      <SBodyTitle>
        {t('Content.Borrower.YourInformation.Details.Email')}
      </SBodyTitle>
      <SBodyText>{email}</SBodyText>
      <Separator my="m" />
      <SBodyTitle>
        {t('Content.Borrower.YourInformation.Details.MobileNumber')}
      </SBodyTitle>
      <SBodyText>{formatPhoneNumber(mobileNumber)}</SBodyText>
      {residentialAddress ? (
        <>
          <Separator my="m" />
          <SBodyTitle>
            {t('Content.Borrower.YourInformation.Details.ResidentialAddress')}
          </SBodyTitle>
          <SBodyText>{formatFullAddress(residentialAddress)}</SBodyText>
        </>
      ) : null}
      {mailingAddress ? (
        <>
          <Separator my="m" />
          <SBodyTitle>
            {t('Content.Borrower.YourInformation.Details.MailingAddress')}
          </SBodyTitle>
          <SBodyText>{formatFullAddress(mailingAddress)}</SBodyText>
        </>
      ) : null}
    </View>
  );
}
