import { useIsFocused } from '@react-navigation/native';
import { Text } from 'dripsy';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { atomFamily, useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { LiabilityList } from '../../components/LiabilityList';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  AppMergedLiability,
  useYourDebts,
} from '../../DebtsWizard/utils/useAllLiabilitiesQuery';
import { useCompletedCreditChecksByLoanIdQuery } from '../../DebtsWizard/utils/useCreditCheckStatesByLoanApplicationIdQuery';
import {
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
  Manual_Kyc_Result_Enum,
  Two_Plus_Result_Enum,
  useCommenceCreditCheckV2Mutation,
  useGetCreditCheckByLoanApplicationIdQuery,
  useGetLoanApplicantsQuery,
  useSetReviewedLiabilityMutation,
} from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

const SUBSCRIPTION_TIMEOUT = 15_000;

type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_YOUR_DEBTS>;

const currentSection = LoanApplicationSection.Debts;

export type PrimaryButtonInputProps = {
  hasIncompleteAccounts: boolean | null;
  loading: boolean;
  navigation: Props['navigation'];
  route: Props['route'];
  hasCurrentApplicantCompleteWizard: boolean | null | undefined;
  hasAnyOfApplicantCompleteWizard: boolean;
  liabilities: Array<AppMergedLiability>;
  loanApplicationType: Loan_Application_Type_Enum | undefined;
  hasDetectedLiabilities: boolean;
  primaryAction?: () => void;
  isCreditCheckAvailable?: boolean;
  hasCurrentLoggedInApplicantPassedKyc?: boolean;
  hasIncompleteNovatedLeases: boolean;
  allLiabilitiesWithoutNovatedLeasesAndHecs?: Array<AppMergedLiability>;
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | null>;
};

export type PrimaryButtonOutput = {
  primaryButtonLabel: string;
  onPrimaryButtonPress?: () => void;
  disablePrimaryButton?: boolean;
  footerCaption?: string;
  footerCaptionLink?: string;
  onFooterCaptionLinkPress?: () => void;
  hasCompletedWizard?: boolean | null | undefined;
  primaryButtonTestId?: string;
};

export const hasOpenedWizardStateByLoanApplicationIdV2 = atomFamily({
  key: 'HasOpenedDebtsWizardV2',
  default: false,
});

export const hasOpenedNoDetectedDebtsWizardStateByLoanApplicationId =
  atomFamily({
    key: 'HasOpenedNoDetectedDebtsWizard',
    default: false,
  });

function checkAllowToContinueWithoutDebt(
  loanApplicationType: Loan_Application_Type_Enum | undefined,
) {
  return loanApplicationType === Loan_Application_Type_Enum.Purchase;
}

export function usePrimaryButtonProps({
  loading,
  hasIncompleteAccounts,
  navigation,
  route,
  hasCurrentApplicantCompleteWizard,
  hasAnyOfApplicantCompleteWizard,
  liabilities,
  loanApplicationType,
  hasDetectedLiabilities,
  primaryAction,
  isCreditCheckAvailable = false,
  hasCurrentLoggedInApplicantPassedKyc,
  hasIncompleteNovatedLeases,
  allLiabilitiesWithoutNovatedLeasesAndHecs,
  timeoutRef,
}: PrimaryButtonInputProps): PrimaryButtonOutput {
  const loanApplicationId = route.params?.loanApplicationId;

  const allowToContinueWithoutDebt =
    checkAllowToContinueWithoutDebt(loanApplicationType);
  const hasHomeLoans = liabilities.filter(
    (lia) => lia.liabilityType === Liability_Type_Enum.HomeLoan,
  );

  // Setup wizard properties
  const hasOpenedWizard = useRecoilValue(
    hasOpenedWizardStateByLoanApplicationIdV2(loanApplicationId),
  );
  const hasCompletedWizard =
    hasOpenedWizard || hasCurrentApplicantCompleteWizard;

  // Setup navigation properties
  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToNextScreen = () => {
    // If there is a timeout started for the subscription we must clear it
    // before navigating to the next screen.
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    navigateToNextLoanApplicationScreen({
      currentSection: LoanApplicationSection.Debts,
    });
  };
  const navigateToNoDetectedDebtModal = () =>
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL,
      params: {
        loanApplicationId,
        hideSubheading: !hasCurrentLoggedInApplicantPassedKyc,
      },
    });
  const navigateToManualDebtsAndLiabilityModal = () => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL,
      params: {
        loanApplicationId,
      },
    });
  };
  const navigateToDetectedDebtListModal = () => {
    navigation.navigate(Screen.DEBTS_WIZARD_V2_MODAL, {
      screen: Screen.DEBTS_WIZARD_V2_DEBT_LIST,
      params: {
        ...route.params,
      },
    });
  };
  const navigateToNoDebtsConfirmationActionSheet = () => {
    navigation.navigate(ActionSheetType.NO_DEBTS_CONFIRMATION, {
      loanApplicationId,
      noHomeLoans: hasHomeLoans.length === 0 && liabilities.length > 0,
    });
  };

  // Set reviewed liability action
  const [setReviewedLiability] = useSetReviewedLiabilityMutation({});
  const setHasReviewedLiability = async () => {
    if (loanApplicationId == null) {
      return;
    }
    await safelyCallMutation(setReviewedLiability, {
      variables: {
        loanApplicationId,
      },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  };

  // Setup continue button properties
  const ContinueButton = {
    Loading: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Loading'),
      onPrimaryButtonPress: () => {},
      disablePrimaryButton: true,
      hasCompletedWizard,
    },
    SetReviewedLiability: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: async () => {
        await setHasReviewedLiability();
      },
      hasCompletedWizard,
    },
    DisabledConfirmDetailsAreCorrect: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: () => {},
      disablePrimaryButton: true,
      footerCaption: t('Content.YourDebts.Footer'),
      hasCompletedWizard,
    },
    NavigateToManualDebtsAndLiabilityModal: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToManualDebtsAndLiabilityModal,
      hasCompletedWizard,
    },
    NavigateToManualDebtsAndLiabilityModalAddCreditSelector: {
      primaryButtonLabel: t('Content.YourDebts.Header.AddCreditSelector'),
      onPrimaryButtonPress: navigateToManualDebtsAndLiabilityModal,
      primaryButtonTestId: TestID.LoanApplicationYourDebts.AddDebtButton,
      hasCompletedWizard,
    },
    NavigateToNextScreen: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToNextScreen,
      footerCaption: t('Content.YourDebts.Footer'),
      hasCompletedWizard,
    },
    CommenceCreditCheck: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: primaryAction,
      footerCaption: t('Content.YourDebts.CreditCheckNotification'),
      hasCompletedWizard,
    },
    NavigateToNoDetectedDebtModal: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToNoDetectedDebtModal,
      primaryButtonTestId: TestID.LoanApplicationYourDebts.ContinueButton,
      hasCompletedWizard,
    },
    NavigateToDetectedDebtListModal: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToDetectedDebtListModal,
      primaryButtonTestId: TestID.LoanApplicationYourDebts.ContinueButton,
      hasCompletedWizard,
    },
    NoDebtsConfirmationActionSheet: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToNoDebtsConfirmationActionSheet,
      footerCaption: t('Content.YourDebts.Footer'),
      hasCompletedWizard,
    },
    // Novated leases continue button props
    CompleteMissingInfo: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: () => {},
      disablePrimaryButton: true,
      footerCaption: t('Content.YourDebts.CompleteMissingInfo'),
      hasCompletedWizard,
    },
    UnableToPrefillCreditInformation: {
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      onPrimaryButtonPress: navigateToNoDetectedDebtModal,
      footerCaption: t('Content.YourDebts.UnableToPrefillCreditInformation'),
      hasCompletedWizard,
    },
  };

  // Loading
  if (loading) {
    return ContinueButton.Loading;
  }

  // KYC fail scenarios
  if (!hasCurrentLoggedInApplicantPassedKyc) {
    if (
      !hasCurrentApplicantCompleteWizard &&
      !hasAnyOfApplicantCompleteWizard
    ) {
      return ContinueButton.NavigateToNoDetectedDebtModal;
    }
    if (!hasCurrentApplicantCompleteWizard && hasAnyOfApplicantCompleteWizard) {
      return ContinueButton.SetReviewedLiability;
    }
    if (liabilities.length === 0) {
      if (!allowToContinueWithoutDebt) {
        return ContinueButton.NoDebtsConfirmationActionSheet;
      }
      return ContinueButton.NavigateToNextScreen;
    }
    if (liabilities.length > 0) {
      if (hasIncompleteNovatedLeases) {
        return ContinueButton.CompleteMissingInfo;
      }
      if (!allowToContinueWithoutDebt) {
        if (hasHomeLoans.length === 0) {
          return ContinueButton.NoDebtsConfirmationActionSheet;
        }
      }
    }
    return ContinueButton.NavigateToNextScreen;
  }

  // Refinance - No liabilities
  if (liabilities.length === 0 && !allowToContinueWithoutDebt) {
    if (!isCreditCheckAvailable) {
      return ContinueButton.CommenceCreditCheck;
    }
    return ContinueButton.NoDebtsConfirmationActionSheet;
  }

  // Purchase - No liabilities
  if (liabilities.length === 0 && allowToContinueWithoutDebt) {
    if (!isCreditCheckAvailable) {
      return ContinueButton.CommenceCreditCheck;
    }
    if (!hasCurrentApplicantCompleteWizard) {
      return ContinueButton.NavigateToNoDetectedDebtModal;
    }
    return ContinueButton.NavigateToNextScreen;
  }

  // Refinance and Purchase - Has liabilities without a credit check. KYC pass.
  if (
    liabilities.length > 0 &&
    !isCreditCheckAvailable &&
    hasCurrentLoggedInApplicantPassedKyc
  ) {
    return ContinueButton.CommenceCreditCheck;
  }

  // Refinance and Purchase - CCR returns liabilities with missing data
  if (hasIncompleteAccounts) {
    return ContinueButton.DisabledConfirmDetailsAreCorrect;
  }

  // Incomplete Novated Leases - Show prompt to complete missing information
  if (hasIncompleteNovatedLeases) {
    return ContinueButton.CompleteMissingInfo;
  }

  // Purchase - No CCR detected liabilities, current borrower has not completed wizard,
  // and there are 0 liabilities not including novated leases
  if (
    allowToContinueWithoutDebt &&
    !hasDetectedLiabilities &&
    !hasCompletedWizard &&
    allLiabilitiesWithoutNovatedLeasesAndHecs?.length === 0
  ) {
    return ContinueButton.UnableToPrefillCreditInformation;
  }

  // Dont allow user to continue if this is a refinanced application and doesn't have home loan
  const homeLoans = liabilities.filter(
    (lia) => lia.liabilityType === Liability_Type_Enum.HomeLoan,
  );
  if (
    homeLoans.length === 0 &&
    loanApplicationType === Loan_Application_Type_Enum.Refinance
  ) {
    return ContinueButton.NoDebtsConfirmationActionSheet;
  }
  return ContinueButton.NavigateToNextScreen;
}

export function V2Container(
  props: React.ComponentProps<typeof LoanApplicationScreenContainer>,
) {
  return (
    <LoanApplicationScreenContainer
      // There is a size mismatch between top nav bar and padding in the screen container
      // (57px vs 60px) causing a 3px white gap between the screen content and the top nav border.
      // Setting this bg to non-white color will reveal the gap.
      // TODO(uiv2): Use the correct bg color.
      // bg="bg"
      {...props}
    />
  );
}

function checkShouldShowEmptyState({
  hasCompletedWizard,
  isCreditCheckAvailable,
  hasCurrentLoggedInApplicantPassedKyc,
}: {
  hasCompletedWizard: boolean | null | undefined;
  isCreditCheckAvailable?: boolean;
  hasCurrentLoggedInApplicantPassedKyc?: boolean;
}) {
  return hasCurrentLoggedInApplicantPassedKyc
    ? !isCreditCheckAvailable
    : !hasCompletedWizard;
}

export function LoanApplicationYourDebtsBaseV2({ navigation, route }: Props) {
  const loanApplicationId = route?.params?.loanApplicationId;

  // See if this loan already has a credit check available.
  // A credit check will be available if the borrower has already filled out their debts and liabilities.
  const [isCreditCheckAvailable, setIsCreditCheckAvailable] = useState(false);
  const {
    data: creditCheckData,
    loading: creditCheckByLoanApplicationLoading,
  } = useGetCreditCheckByLoanApplicationIdQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    context: {
      sentryContext: {
        description: `Get credit check by the loan application ID ${loanApplicationId}`,
      },
    },
  });

  // Get the applicants for this loan and check if logged in borrower has passed KYC.
  const { data: loanApplicantsData, loading: loanApplicantsLoading } =
    useGetLoanApplicantsQuery({
      variables: {
        loanApplicationId: loanApplicationId || '',
      },
      context: {
        sentryContext: {
          description: `Get applicants by the loan application ID ${loanApplicationId}`,
        },
      },
    });

  const identityProfile =
    loanApplicantsData?.loan_application_by_pk?.applicants.find(
      (x) => x.is_current_logged_in_applicant,
    )?.user_identity_profile;

  const [
    commenceCreditCheckV2Mutation,
    { data, loading: commenceCreditCheckV2Loading },
  ] = useCommenceCreditCheckV2Mutation();

  // Fetch the borrower's debts.
  // Fired on page load. Fired when credit check marked as completed.
  const {
    allLiabilities,
    hasIncompleteAccounts,
    hasIncompleteNovatedLeases,
    creditCheckHasDetectedDebts,
    currentApplicantDetectedLiabilities,
    loading: allLiabilitiesQueryLoading,
    error,
    hasCurrentApplicantCompleteWizard,
    hasAnyOfApplicantCompleteWizard,
    currentApplicant,
    refetch,
    loanApplication,
    allLiabilitiesWithoutNovatedLeasesAndHecs,
  } = useYourDebts({
    loanApplicationId,
    forV2: true,
  });

  const loanApplicationType = loanApplication?.type;

  const hasCurrentLoggedInApplicantPassedKyc =
    loanApplicationType === Loan_Application_Type_Enum.TopUp ||
    identityProfile?.two_plus_result === Two_Plus_Result_Enum.Pass ||
    identityProfile?.manual_kyc_result === Manual_Kyc_Result_Enum.Pass;

  // Subscription to credit check and merged liabilities tables.
  const [skipSubscription, setSkipSubscription] = useState<boolean>(true);
  const [isSubscriptionTimeout, setIsSubscriptionTimeout] =
    useState<boolean>(false);

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const {
    loading: completedCreditCheckIdsLoading,
    isAllCreditCheckCompleted,
    isMergedLiabilitiesPopulated,
  } = useCompletedCreditChecksByLoanIdQuery({
    loanApplicationId,
    skip: skipSubscription,
    currentApplicantId: currentApplicant?.id,
  });

  useEffect(() => {
    // When merged liabilities table is populated we refetch YourDebts and close subscriptions.
    if (!skipSubscription && isMergedLiabilitiesPopulated) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setSkipSubscription(true);
      refetch();
    }

    if (!skipSubscription && isAllCreditCheckCompleted) {
      refetch();
    }

    // Skip subscription is marked as false by the commence credit check mutation
    // Set time out for 15 seconds then close subscription.
    // Subscription should be closed earlier if a completed result is returned.
    if (!skipSubscription && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        setSkipSubscription(true);
        setIsSubscriptionTimeout(true);
      }, SUBSCRIPTION_TIMEOUT);
    }

    // Credit check is available if:
    // - It's application ID matches the one of this application or
    // - It's created at date is after this applications created at date and its not linked to any other loan application or
    // - The commence credit check mutation has finished.
    const creditCheck = creditCheckData?.applicant.find(
      (x) => x.is_current_logged_in_applicant,
    )?.user_identity_profile?.credit_checks[0];
    if (loanApplication && creditCheck) {
      if (
        creditCheck?.loan_application_id === loanApplicationId ||
        (creditCheck?.created_at > loanApplication?.created_at &&
          !creditCheck.loan_application_id)
      ) {
        setIsCreditCheckAvailable(true);
      }
    }
    if (data?.commence_credit_check_v2.credit_check_id) {
      setIsCreditCheckAvailable(true);
    }
    return () => {
      setIsCreditCheckAvailable(false);
    };
  }, [
    loanApplication,
    skipSubscription,
    creditCheckData,
    isAllCreditCheckCompleted,
    isMergedLiabilitiesPopulated,
    data,
    refetch,
    loanApplicationId,
    timeoutRef,
  ]);

  // Mutation is passed into primary button action.
  // Triggering th emutation will commence a credit check. When it returns it will
  // set skipSubscription to false to begin subscription to credit check table.
  const triggerCommenceMutation = useCallback(async () => {
    const [res] = await safelyCallMutation(commenceCreditCheckV2Mutation, {
      variables: {
        loan_application_id: loanApplicationId || '',
        identity_profile_id: currentApplicant?.user_identity_profile?.id || '',
      },
      context: {
        sentryContext: {
          description: `Commence credit check mutation for loan application ${loanApplicationId}`,
        },
      },
    });
    if (res?.data) {
      setSkipSubscription(false);
    }
  }, [
    commenceCreditCheckV2Mutation,
    currentApplicant?.user_identity_profile?.id,
    loanApplicationId,
  ]);

  const hasDetectedLiabilities = currentApplicantDetectedLiabilities.length > 0;
  const hasOpenedWizard = useRecoilValue(
    hasOpenedWizardStateByLoanApplicationIdV2(loanApplicationId),
  );
  const hasOpenedNoDetectedDebtsWizard = useRecoilValue(
    hasOpenedNoDetectedDebtsWizardStateByLoanApplicationId(loanApplicationId),
  );

  if (
    !hasCurrentApplicantCompleteWizard &&
    !hasOpenedWizard &&
    hasDetectedLiabilities &&
    creditCheckHasDetectedDebts === true &&
    !completedCreditCheckIdsLoading &&
    isAllCreditCheckCompleted &&
    isCreditCheckAvailable
  ) {
    navigation.navigate(Screen.DEBTS_WIZARD_V2_MODAL, {
      screen: Screen.DEBTS_WIZARD_V2_DEBT_LIST,
      params: {
        ...route.params,
      },
    });
  }

  const isFocused = useIsFocused();

  if (
    isFocused &&
    !hasCurrentApplicantCompleteWizard &&
    !hasOpenedNoDetectedDebtsWizard &&
    !hasDetectedLiabilities &&
    (creditCheckHasDetectedDebts === false || isSubscriptionTimeout) &&
    !completedCreditCheckIdsLoading &&
    isAllCreditCheckCompleted &&
    isCreditCheckAvailable
  ) {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL,
      params: {
        ...route.params,
      },
    });
  }

  const { getScreenOrder } = useNavigateToLoanApplicationScreen(
    navigation,
    route,
    loanApplicationId,
  );
  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  // Show loading spinner when:
  // - YourDebts is loading
  // - Skip subscription flag is true and credit checks have not finished returning
  // - Loading status from completed credit check subscription is open
  // - Initial query for available credit check
  // - Subscribed to merged liabilities
  const loading =
    allLiabilitiesQueryLoading ||
    (!skipSubscription && !isAllCreditCheckCompleted) ||
    completedCreditCheckIdsLoading ||
    creditCheckByLoanApplicationLoading ||
    loanApplicantsLoading ||
    commenceCreditCheckV2Loading;

  const {
    primaryButtonLabel,
    onPrimaryButtonPress,
    disablePrimaryButton,
    hasCompletedWizard,
    footerCaption,
    footerCaptionLink,
    onFooterCaptionLinkPress,
    primaryButtonTestId,
  } = usePrimaryButtonProps({
    hasIncompleteAccounts,
    hasIncompleteNovatedLeases,
    loading,
    navigation,
    route,
    hasCurrentApplicantCompleteWizard,
    liabilities: allLiabilities,
    loanApplicationType,
    hasDetectedLiabilities,
    hasAnyOfApplicantCompleteWizard,
    primaryAction: triggerCommenceMutation,
    isCreditCheckAvailable,
    hasCurrentLoggedInApplicantPassedKyc,
    allLiabilitiesWithoutNovatedLeasesAndHecs,
    timeoutRef,
  });

  if (loading) {
    return (
      <V2Container>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.YourDebts.Header.YourDebts')}
          caption={t('Content.YourDebts.Header.CaptionV2')}
          px="l"
        />
        <ScreenLoadingContainer loading centered />
        <Box centered>
          <Text sx={{ pt: '$16' }} variant="caption">
            {isCreditCheckAvailable
              ? t('Content.YourDebts.DetectingCreditAccountsLoading')
              : null}
          </Text>
        </Box>
      </V2Container>
    );
  }

  if (error || !currentApplicant) {
    return (
      <V2Container>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.YourDebts.QueryError')}
          refetch={refetch}
        />
      </V2Container>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });
  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const shouldShowEmptyState = checkShouldShowEmptyState({
    hasCompletedWizard,
    isCreditCheckAvailable,
    hasCurrentLoggedInApplicantPassedKyc,
  });

  if (shouldShowEmptyState) {
    return (
      <V2Container>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.YourDebts.Header.YourDebts')}
          caption={t('Content.YourDebts.Header.CaptionV2')}
          px="l"
        />
        <EmptyState />
        <LoanApplicationWizardFooter
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonTestID={
            primaryButtonTestId ||
            TestID.LoanApplicationYourDebts.ContinueButton
          }
          footerCaption={footerCaption}
          onPrimaryButtonPress={onPrimaryButtonPress}
          disablePrimaryButton={disablePrimaryButton}
        />
      </V2Container>
    );
  }

  const onLiabilityPress = (aml: AppMergedLiability) => {
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
      params: {
        loanApplicationId: route.params?.loanApplicationId,
        liabilityType: aml.liabilityType,
        currentLiabilityId: aml.current_liability_id || undefined,
        detectedLiabilityIdentifier:
          aml.detected_liability_identifier || undefined,
        liabilityManuallyAdded: !!aml.manually_added,
      },
    });
  };

  return (
    <V2Container>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.YourDebts.Header.YourDebts')}
        caption={t('Content.YourDebts.Header.CaptionV2')}
        px="l"
      />
      <Box px="l" mt="xl">
        <LiabilityList
          currentApplicant={currentApplicant}
          groupTestId={TestID.LoanApplicationYourDebts.LiabilityList}
          liabilities={allLiabilities}
          onLiabilityPress={onLiabilityPress}
          forV2
        />
      </Box>
      <Button
        label={t('Content.YourDebts.AddDebt')}
        icon="add"
        tertiary
        testID={TestID.LoanApplicationYourDebts.AddDebtButton}
        fontWeight="normal"
        onPress={() => {
          navigation.navigate(Screen.SINGLE_V2_MODAL, {
            screen: Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL,
            params: { loanApplicationId: route.params?.loanApplicationId },
          });
        }}
        my="s"
      />
      <LoanApplicationWizardFooter
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonTestID={TestID.LoanApplicationYourDebts.ContinueButton}
        onPrimaryButtonPress={onPrimaryButtonPress}
        disablePrimaryButton={disablePrimaryButton}
        footerCaption={footerCaption}
        footerCaptionLink={footerCaptionLink}
        onLinkPress={onFooterCaptionLinkPress}
      />
    </V2Container>
  );
}

export function EmptyState() {
  return (
    <Box flex={1} alignItems="center" my="xl">
      <EmptyStateIllustration
        name="debtsV2"
        testID={TestID.LoanApplicationYourDebts.YourDebtsEmptyState}
      />
    </Box>
  );
}

export const WithAuthenticationLoanApplicationYourDebtsV2 =
  withAuthenticationRequired(LoanApplicationYourDebtsBaseV2);
