import { formatInTimeZone } from 'date-fns-tz';
import { Text, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { StyledIcon } from '../../../ui/atoms/StyledIcon';

type Props = {
  passedAnniversaryDate: string;
  dateFormat?: string;
};

export const InfoCard = ({
  passedAnniversaryDate,
  dateFormat = 'dd MMMM, yyyy',
}: Props) => {
  if (!passedAnniversaryDate) {
    return null;
  }
  return (
    <View
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        py: '$16',
        px: '$16',
        backgroundColor: 'rgba(116, 116, 128, 0.08)',
        borderRadius: '$card',
      }}
    >
      <View testID={TestID.Insights.DiscountBump.InfoIcon}>
        <StyledIcon name="infoFilled" family="svg" />
      </View>
      <Text
        variant="caption"
        sx={{ pl: '$16' }}
        testID={TestID.Insights.DiscountBump.InfoText}
      >
        {t(
          'Content.HomeLoan.Insights.DiscountBump.InAnniversaryPeriod.extraInfo',
          {
            passedAnniversaryDate: formatInTimeZone(
              passedAnniversaryDate,
              'Australia/Sydney',
              dateFormat,
            ),
          },
        )}
      </Text>
    </View>
  );
};
