import { Text } from 'dripsy';
import { useMemo, useState } from 'react';

import { Box } from '../../../ui/atoms/Box';

type Props = {
  hideMiddleLabel?: boolean;
  middleLabel: string;
  rightLabel: string;
  middleLabelMiddlePos?: number;
};

const GAP = 8;

export const LoanTermLabels = ({
  hideMiddleLabel = false,
  middleLabel,
  rightLabel,
  middleLabelMiddlePos = 0,
}: Props) => {
  const [middleLabelWidth, setMiddleLabelWidth] = useState(0);
  const [labelWidth, setLabelWidth] = useState(0);
  const [rightLabelXPos, setRightLabelXPos] = useState(0);

  const constrainedMiddleLabelLeftPos = useMemo(() => {
    if (middleLabelMiddlePos - middleLabelWidth / 2 <= labelWidth) {
      return labelWidth + GAP;
    }
    if (middleLabelMiddlePos + middleLabelWidth / 2 >= rightLabelXPos) {
      return rightLabelXPos - middleLabelWidth - GAP;
    }

    return middleLabelMiddlePos - middleLabelWidth / 2;
  }, [labelWidth, middleLabelMiddlePos, middleLabelWidth, rightLabelXPos]);

  return (
    <Box row mt="m">
      <Text
        variant="label"
        onLayout={(event) => {
          setLabelWidth(event.nativeEvent.layout.width);
        }}
      >
        {t('Content.HomeLoan.Insights.TermRemaining.Today')}
      </Text>
      {!hideMiddleLabel ? (
        <Box left={constrainedMiddleLabelLeftPos} position="absolute">
          <Text
            variant="label"
            key={middleLabel}
            onLayout={(event) => {
              setMiddleLabelWidth(event.nativeEvent.layout.width);
            }}
          >
            {middleLabel}
          </Text>
        </Box>
      ) : null}
      <Box
        right={0}
        position="absolute"
        onLayout={(event) => {
          setRightLabelXPos(event.nativeEvent.layout.x);
        }}
      >
        <Text variant="label">{rightLabel}</Text>
      </Box>
    </Box>
  );
};
