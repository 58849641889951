import { Text, useDripsyTheme, View } from 'dripsy';
import { FormikProps } from 'formik';
import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormRadioInputV2,
} from '../../components/form/FormikInputs';
import { FormSpacer } from '../../components/form/FormSpacer';
import { Autopay_Frequency_Input_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { FormikFormError } from '../../ui/v2/FormError';
import { RadioGroup, RadioGroupItem } from '../../ui/v2/RadioGroup';
import { formatCurrency } from '../../utils/currencyHelpers';
import {
  AutopayChangeRequestFormV2,
  AutoPaySetupFormField,
  MinimumRepaymentAmountsByFrequency,
} from '../types';

type AutopaySetupV2RepaymentSelectProps = {
  screen: Screen;
  formProps: FormikProps<AutopayChangeRequestFormV2>;
  minimumRepaymentAmountsByFrequency: MinimumRepaymentAmountsByFrequency;
  loading?: boolean;
  isLoadingMinimumRepaymentAmount?: boolean;
};

export function AutopaySetupV2RepaymentSelect({
  screen,
  formProps,
  minimumRepaymentAmountsByFrequency,
  loading,
  isLoadingMinimumRepaymentAmount,
}: AutopaySetupV2RepaymentSelectProps) {
  const { theme } = useDripsyTheme();
  const isCustomRepaymentAmount =
    formProps.values[AutoPaySetupFormField.IsCustomRepaymentAmount];

  const toggleIsCustomRepaymentAmount = (value: boolean) => {
    formProps.setFieldValue(
      AutoPaySetupFormField.IsCustomRepaymentAmount,
      value,
    );
  };

  const minimumAmount = useMemo(() => {
    const frequency =
      formProps.values[AutoPaySetupFormField.RepaymentFrequency];

    if (
      (minimumRepaymentAmountsByFrequency[
        Autopay_Frequency_Input_Enum.Monthly
      ] === 0 &&
        !isLoadingMinimumRepaymentAmount) ||
      !frequency
    ) {
      return null;
    }

    return (
      <Skeleton show={isLoadingMinimumRepaymentAmount} width={theme.space.$120}>
        <Text variant="caption">
          {t(`Content.AutopaySettings.${frequency}Amount`, {
            amount: formatCurrency(
              minimumRepaymentAmountsByFrequency[frequency],
            ),
          })}
        </Text>
      </Skeleton>
    );
  }, [
    formProps.values,
    isLoadingMinimumRepaymentAmount,
    minimumRepaymentAmountsByFrequency,
    theme,
  ]);

  return (
    <>
      <Skeleton show={loading}>
        <>
          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.AutopaySettings.LoanRepaymentFrequency.Title')}
          </Text>

          <View sx={{ flexDirection: 'column' }}>
            <FormRadioInputV2
              testID={TestID.AutopaySetup.MonthlyRepaymentRadioInput}
              name={AutoPaySetupFormField.RepaymentFrequency}
              value={Autopay_Frequency_Input_Enum.Monthly}
              label={t(
                'Content.AutopaySettings.LoanRepaymentFrequency.Monthly',
              )}
              containerStyle={{ mb: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.AutopaySetup,
                screen,
                FieldInteractionKey.MonthlyRepayment,
              )}
            />

            <FormRadioInputV2
              testID={TestID.AutopaySetup.FortnightlyRepaymentRadioInput}
              name={AutoPaySetupFormField.RepaymentFrequency}
              value={Autopay_Frequency_Input_Enum.Fortnightly}
              label={t(
                'Content.AutopaySettings.LoanRepaymentFrequency.Fortnightly',
              )}
              containerStyle={{ mb: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.AutopaySetup,
                screen,
                FieldInteractionKey.FortnightlyRepayment,
              )}
            />

            <FormRadioInputV2
              testID={TestID.AutopaySetup.WeeklyRepaymentRadioInput}
              name={AutoPaySetupFormField.RepaymentFrequency}
              value={Autopay_Frequency_Input_Enum.Weekly}
              label={t('Content.AutopaySettings.LoanRepaymentFrequency.Weekly')}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.AutopaySetup,
                screen,
                FieldInteractionKey.WeeklyRepayment,
              )}
            />
          </View>

          <FormSpacer py="$12" />

          <Text variant="sHeader" sx={{ mb: '$16' }}>
            {t('Content.AutopaySettings.LoanRepaymentAmount.Title')}
          </Text>

          <RadioGroup
            value={String(isCustomRepaymentAmount)}
            onValueSelected={(value) =>
              toggleIsCustomRepaymentAmount(value === 'true')
            }
          >
            <RadioGroupItem
              testID={TestID.AutopaySetup.CustomRepaymentRadioInput}
              value="false"
              label={t(
                'Content.AutopaySettings.LoanRepaymentAmount.MinimumAmount',
              )}
              subtitle={minimumAmount}
              containerStyle={{ mb: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.AutopaySetup,
                screen,
                FieldInteractionKey.CustomRepaymentMinimum,
              )}
            />
            <RadioGroupItem
              testID={TestID.AutopaySetup.CustomRepaymentRadioInput}
              value="true"
              label={t(
                'Content.AutopaySettings.LoanRepaymentAmount.CustomAmount',
              )}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.AutopaySetup,
                screen,
                FieldInteractionKey.CustomRepaymentCustom,
              )}
            />
          </RadioGroup>
        </>
      </Skeleton>

      {!loading && isCustomRepaymentAmount ? (
        <>
          <FormSpacer />

          <FormCurrencyInputV2
            inputTestID={TestID.AutopaySetup.CustomRepaymentAmountInput}
            name={AutoPaySetupFormField.RepaymentAmount}
            label={t('Content.AutopaySettings.Amount')}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.AutopaySetup,
              screen,
              FieldInteractionKey.CustomRepaymentCustomAmount,
            )}
          />

          <FormikFormError
            name={AutoPaySetupFormField.RepaymentAmount}
            sx={{ mt: '$8' }}
          />
        </>
      ) : null}
    </>
  );
}
