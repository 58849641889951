import { FC, useCallback, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { newExternalAccountAtom } from '../../ExternalAccount/atoms/newExternalAccountAtom';
import { selectedExternalAccountAtom } from '../../ExternalAccount/atoms/selectedExternalAccount';
import { AccountSelector } from '../../ExternalAccount/components/AccountSelector';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useIsBsbNumberForWithdrawValid } from '../../utils/isBsbNumberForWithdrawValid';
import { HomeLoanScreenProps } from '../navigation/types';

type Props = HomeLoanScreenProps<Screen.HOME_LOAN_WITHDRAWAL_TO>;

export const WithdrawTo: FC<Props> = ({ navigation, route }) => {
  const resetNewExternalAccount = useResetRecoilState(newExternalAccountAtom);

  const [selectedExternalAccount, setSelectedExternalAccount] = useRecoilState(
    selectedExternalAccountAtom,
  );

  const [formError, setFormError] = useState<string | null>(null);

  const isBsbNumberForWithdrawValid = useIsBsbNumberForWithdrawValid();

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.Withdraw.AccountSelect')}
      onClose={onClose}
      scrollable
    >
      <ErrorRow message={formError} m="m" mx={0} />
      <AccountSelector
        selectedAccountId={selectedExternalAccount?.externalAccountId}
        onPressAddAccount={() => {
          resetNewExternalAccount();
          navigation.navigate(Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT, {
            cbaAccountId: route.params.cbaAccountId,
          });
        }}
        onSelectAccount={(account) => {
          if (!isBsbNumberForWithdrawValid(account.bsbNumber)) {
            setSelectedExternalAccount(null);
            setFormError(
              t('Content.Withdraw.Error.UnableToWithdrawToBankWest'),
            );
            return;
          }
          setFormError(null);
          setSelectedExternalAccount(account);
          navigation.goBack();
        }}
      />
    </ModalScreenContainer>
  );
};
