import { useEffect } from 'react';

import { captureException } from '../../sentry';

export function useCaptureException(
  description: string,
  context: ObjectOf<unknown> | null,
  error?: unknown,
) {
  useEffect(() => {
    if (error) {
      captureException(description, context, error);
    }
    // Only capture when the error changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}
