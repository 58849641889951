import { gql } from '@apollo/client';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import {
  ApplicantForYourDebtsFragment,
  Credit_Check_Status_Enum,
  Flagged_Incorrect_Enum,
  Liability_Type_Enum,
  MergedLiabilityForYourDebtsFragment,
  useYourDebtsQuery,
  YourDebtsQuery,
} from '../../generated/graphql';
import { HasuraAddress } from '../../LoanApplication/graphql/fragments';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { isTopUpHomeLoan } from '../../utils/isTopUpHomeLoan';
import { getLoanTermValue } from './debtsScreenUtils';
import { doesMLHasMissingDetails } from './detectedLiability';
import { ApplicantForFullName, FullNameByApplicantIds } from './types';
import { useCompletedCreditChecksQuery } from './useCompletedCreditChecksQuery';

export type AppMergedLiability = MergedLiabilityForYourDebtsFragment & {
  loanTermInMonth: number | null;
  liabilityType: Liability_Type_Enum;
  hasMissingDetails: boolean;
  ownerFullNames: Array<string>;
  flaggedIncorrect: Flagged_Incorrect_Enum | null;
};

export const YOUR_DEBTS_QUERY = gql`
  query YourDebts($loanApplicationId: uuid!) {
    # This is used to check whether loan application can be found & preload data
    loan_application_by_pk(id: $loanApplicationId) {
      ...LoanApplicationForYourDebts

      credit_checks(order_by: { created_at: desc }, limit: 1) {
        id
        status
      }
    }

    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...ApplicantForYourDebts
    }

    merged_liability(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...MergedLiabilityForYourDebts
    }
  }

  fragment LoanApplicationForYourDebts on loan_application {
    id
    type
    created_at
    loan_application_securities {
      id
      property {
        id
        address {
          id
          short_address_format
        }
      }
    }
  }

  fragment MergedLiabilityForYourDebts on merged_liability {
    id
    current_liability_id
    current_liability {
      is_draft
    }

    dynamite_account_number_source
    dynamite_account_number
    dynamite_applicant_ids
    dynamite_applicant_ids_source
    dynamite_balance
    dynamite_institution_name
    dynamite_liability_type
    dynamite_limit
    dynamite_term_months_end_date
    dynamite_loan_term_months
    dynamite_interest_rate_type
    dynamite_fixed_rate_expiry_date
    ccr_applicant_ids
    dynamite_flagged_incorrect
    dynamite_for_refinancing
    dynamite_institution_id
    dynamite_interest_rate
    dynamite_repayment_amount
    dynamite_repayment_frequency
    detected_liability_identifier
    manually_added
    dynamite_address {
      ...HasuraAddress
    }
  }

  fragment ApplicantForYourDebts on applicant {
    id
    latest_first_name
    latest_full_name
    reviewed_liability_at
    is_current_logged_in_applicant
    user_identity_profile {
      id
      kyc_status
    }
  }
  ${HasuraAddress}
`;

export function mapMergedLiabilityToAppMergedLiability(
  ml: MergedLiabilityForYourDebtsFragment,
  {
    fullNameByApplicantIds,
  }: {
    fullNameByApplicantIds: FullNameByApplicantIds;
  },
): AppMergedLiability {
  const loanTermInMonth = getLoanTermValue(ml);

  const liabilityType =
    parseEnumType(Liability_Type_Enum, ml.dynamite_liability_type) ||
    Liability_Type_Enum.Other;

  const ownerFullNames =
    ml.dynamite_applicant_ids
      ?.map((applicantId) => fullNameByApplicantIds[applicantId])
      .filter(isNotNullOrUndefined) || [];

  return {
    ...ml,
    hasMissingDetails: doesMLHasMissingDetails(ml),
    liabilityType,
    loanTermInMonth,
    ownerFullNames,
    flaggedIncorrect: parseEnumType(
      Flagged_Incorrect_Enum,
      ml.dynamite_flagged_incorrect,
    ),
  };
}

export function sortAppMergedLiabilities(
  amls: Array<AppMergedLiability>,
): Array<AppMergedLiability> {
  const unflaggedLiabilities: typeof amls = [];
  const flaggedLiabilites: typeof amls = [];
  amls.forEach((aml) => {
    if (aml.dynamite_flagged_incorrect != null) {
      flaggedLiabilites.push(aml);
    } else {
      unflaggedLiabilities.push(aml);
    }
  });

  const sortByInstitutionName = (liabilities: typeof amls) =>
    sortBy(liabilities, [(aml) => aml.dynamite_institution_name]);
  const sortedUnflaggedLiabilities =
    sortByInstitutionName(unflaggedLiabilities);
  const sortedFlaggedLiabilites = sortByInstitutionName(flaggedLiabilites);
  // Flagged liabilities always appear last.
  return [...sortedUnflaggedLiabilities, ...sortedFlaggedLiabilites];
}

export function filterAMLForDebtsWizard(
  liabilities: Array<AppMergedLiability>,
) {
  // This filter does not match POSSIBLE_MISSING_FIELDS_BY_LIABILITY_TYPE
  return liabilities.filter(
    ({ liabilityType }) =>
      liabilityType === Liability_Type_Enum.HomeLoan ||
      liabilityType === Liability_Type_Enum.Personal ||
      liabilityType === Liability_Type_Enum.LineOfCredit ||
      // Why do we show CC when it has no possible missing fields in the wizard?
      // Is this filter intended only for listing the detected debts list,
      // not for filling the details in the wizard?
      liabilityType === Liability_Type_Enum.CreditCard,
  );
}

function hasDetectedDebts(
  creditCheck:
    | NonNullable<
        YourDebtsQuery['loan_application_by_pk']
      >['credit_checks'][number]
    | undefined,
): boolean | undefined {
  if (creditCheck?.status === Credit_Check_Status_Enum.Match) return true;
  if (creditCheck?.status === Credit_Check_Status_Enum.NoMatch) return false;
  return undefined;
}

export function useAllLiabilitiesQuery({
  loanApplicationId,
  forV2 = false,
  skipCCRCompletenessCheck = true,
}: {
  loanApplicationId: string | undefined;
  forV2?: boolean;
  /**
   * skipCCRCompletenessCheck works as optimization options to trade off between
   * total loading time vs liability data freshness.
   *
   * When skipCCRCompletenessCheck is enabled, subscription for the CCR
   * will not run, and useAllLiabilitiesQuery will immediately
   * query the merged_liability. This is the default behavior.
   *
   * When skipCCRCompletenessCheck is disabled, useAllLiabilitiesQuery will subscribe
   * to latest credit checks of all applicants. Making sure
   * all CCR is done before querying for the liabilities.
   * This is the proper behavior because CCR is happening asynchronously
   * and can change anytime since CCR result has validity.
   * But this will incur costs:
   * - loading time, subscription is not cached, need to open WS connection
   * whenever subscribing and
   * - DB load, hasura subscription translates into SQL that runs at set
   * interval
   *
   * Hence, we default to enabling skipCCRCompletenessCheck.
   * Only do CCR completeness check in the first screen
   * that use useAllLiabilitiesQuery,
   * and assume no CCR has been added within the timeframe
   * starting at borrower opened Your Debts until reaching next screen.
   */
  skipCCRCompletenessCheck?: boolean;
}) {
  const { loading: completedCreditCheckIdsLoading, error: creditCheckError } =
    useCompletedCreditChecksQuery({
      loanApplicationId,
      skipCCRCompletenessCheck,
    });

  const {
    data: yourDebtsData,
    loading: yourDebtsLoading,
    error: yourDebtsError,
    refetch: yourDebtsRefetch,
  } = useYourDebtsQuery({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId || completedCreditCheckIdsLoading,
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const loading = completedCreditCheckIdsLoading || yourDebtsLoading;
  const error = creditCheckError || yourDebtsError;

  const applicants = yourDebtsData?.applicants;

  const rawMergedLiabilities = yourDebtsData?.merged_liability;

  const fullNameByApplicantIds = useFullNameByApplicantIds(applicants);
  const allLiabilities = useMemo(() => {
    const mergedLiabilities = forV2
      ? rawMergedLiabilities?.filter(
          (ml) => ml.dynamite_flagged_incorrect == null,
        )
      : rawMergedLiabilities;

    return sortAppMergedLiabilities(
      mergedLiabilities?.map((ml) =>
        mapMergedLiabilityToAppMergedLiability(ml, {
          fullNameByApplicantIds,
        }),
      ) || [],
    );
  }, [forV2, fullNameByApplicantIds, rawMergedLiabilities]);

  const hasIncompleteAccounts = allLiabilities.some(
    (aml) => aml.hasMissingDetails,
  );

  const hasIncompleteNovatedLeases = allLiabilities.some(
    (aml) => aml.current_liability[0]?.is_draft,
  );

  const currentApplicant = applicants?.find(
    (applicant) => !!applicant.is_current_logged_in_applicant,
  );
  const currentApplicantReviewedLiabilityTimestamp =
    currentApplicant?.reviewed_liability_at;

  const loanApplication = yourDebtsData?.loan_application_by_pk;

  const currentApplicantDetectedLiabilities = currentApplicant
    ? allLiabilities.filter(
        (aml) =>
          aml.dynamite_applicant_ids?.includes(currentApplicant.id) &&
          !aml.manually_added &&
          !isTopUpHomeLoan({
            loanApplicationType: loanApplication?.type,
            liabilityType: aml.liabilityType,
            forRefinancing: aml.dynamite_for_refinancing,
          }),
      )
    : [];

  const hasCurrentApplicantCompleteWizard =
    !!currentApplicantReviewedLiabilityTimestamp;

  const hasAnyOfApplicantCompleteWizard =
    applicants?.some((applicant) => applicant.reviewed_liability_at != null) ??
    false;

  return {
    loading,
    error,
    allLiabilities,
    currentApplicantDetectedLiabilities,
    liabilitiesForDebtsWizard: filterAMLForDebtsWizard(
      currentApplicantDetectedLiabilities,
    ),
    hasIncompleteAccounts,
    hasIncompleteNovatedLeases,
    hasCurrentApplicantCompleteWizard,
    hasAnyOfApplicantCompleteWizard,
    currentApplicant,
    // Credit check subscription can't be refetched,
    // here we only refetch the query.
    refetch: yourDebtsRefetch,
    loanApplication,
  };
}

export function useYourDebts({
  loanApplicationId,
  forV2 = false,
}: {
  loanApplicationId: string | undefined;
  forV2?: boolean;
}) {
  const {
    data: yourDebtsData,
    loading: yourDebtsLoading,
    error: yourDebtsError,
    refetch: yourDebtsRefetch,
  } = useYourDebtsQuery({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId,
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const loading = yourDebtsLoading;
  const error = yourDebtsError;

  const applicants = yourDebtsData?.applicants;
  const rawMergedLiabilities = yourDebtsData?.merged_liability;

  const fullNameByApplicantIds = useFullNameByApplicantIds(applicants);
  const allLiabilities = useMemo(() => {
    const mergedLiabilities = forV2
      ? rawMergedLiabilities?.filter(
          (ml) => ml.dynamite_flagged_incorrect == null,
        )
      : rawMergedLiabilities;

    return sortAppMergedLiabilities(
      mergedLiabilities?.map((ml) =>
        mapMergedLiabilityToAppMergedLiability(ml, {
          fullNameByApplicantIds,
        }),
      ) || [],
    );
  }, [forV2, fullNameByApplicantIds, rawMergedLiabilities]);

  const hasIncompleteAccounts = allLiabilities.some(
    (aml) => aml.hasMissingDetails,
  );

  const hasIncompleteNovatedLeases = allLiabilities.some(
    (aml) => aml.current_liability[0]?.is_draft,
  );

  const allLiabilitiesWithoutNovatedLeasesAndHecs = allLiabilities.filter(
    (aml) =>
      aml.liabilityType !== Liability_Type_Enum.NovatedLease &&
      aml.liabilityType !== Liability_Type_Enum.HecsHelp,
  );

  const currentApplicant = applicants?.find(
    (applicant) => !!applicant.is_current_logged_in_applicant,
  );
  const currentApplicantReviewedLiabilityTimestamp =
    currentApplicant?.reviewed_liability_at;

  const loanApplication = yourDebtsData?.loan_application_by_pk;

  const currentApplicantDetectedLiabilities = currentApplicant
    ? allLiabilities.filter(
        (aml) =>
          aml.dynamite_applicant_ids?.includes(currentApplicant.id) &&
          !aml.manually_added &&
          !isTopUpHomeLoan({
            loanApplicationType: loanApplication?.type,
            liabilityType: aml.liabilityType,
            forRefinancing: aml.dynamite_for_refinancing,
          }),
      )
    : [];

  const hasCurrentApplicantCompleteWizard =
    !!currentApplicantReviewedLiabilityTimestamp;

  const hasAnyOfApplicantCompleteWizard =
    applicants?.some((applicant) => applicant.reviewed_liability_at != null) ??
    false;

  const creditCheckHasDetectedDebts = hasDetectedDebts(
    loanApplication?.credit_checks[0],
  );

  return {
    loading,
    error,
    allLiabilities,
    creditCheckHasDetectedDebts,
    currentApplicantDetectedLiabilities,
    liabilitiesForDebtsWizard: filterAMLForDebtsWizard(
      currentApplicantDetectedLiabilities,
    ),
    hasIncompleteAccounts,
    hasIncompleteNovatedLeases,
    allLiabilitiesWithoutNovatedLeasesAndHecs,
    hasCurrentApplicantCompleteWizard,
    hasAnyOfApplicantCompleteWizard,
    currentApplicant,
    // Credit check subscription can't be refetched,
    // here we only refetch the query.
    refetch: yourDebtsRefetch,
    loanApplication,
  };
}

export enum LiabilityOwnerCompareResult {
  LiabilityHasUnlistedDeclaredOwner = 'LiabilityHasUnlistedDeclaredOwner',
  LiabilityListAllDetectedOwners = 'LiabilityListAllDetectedOwners',
  UserHasReviewedDeclaredOwners = 'UserHasReviewedDeclaredOwners',
}

export function compareDeclaredAndDetectedLiabilityOwners({
  aml,
  currentApplicant,
}: {
  aml: AppMergedLiability;
  currentApplicant: ApplicantForYourDebtsFragment;
}): LiabilityOwnerCompareResult {
  if (currentApplicant.reviewed_liability_at != null) {
    // Applicant has went through the debts wizard and
    // acknowledged the current declared owners.
    return LiabilityOwnerCompareResult.UserHasReviewedDeclaredOwners;
  }

  if (aml.dynamite_applicant_ids_source === 'CUSTOMER') {
    // Only care if source is CUSTOMER,
    // if source = CCR, user has not made any declared input
    const diff = difference(
      aml.ccr_applicant_ids || [],
      aml.dynamite_applicant_ids || [],
    );
    if (diff.length > 0) {
      return LiabilityOwnerCompareResult.LiabilityHasUnlistedDeclaredOwner;
    }
  }

  return LiabilityOwnerCompareResult.LiabilityListAllDetectedOwners;
}

export function useFullNameByApplicantIds(
  applicants: Array<ApplicantForFullName> = [],
) {
  return useMemo(
    () =>
      applicants.reduce(
        (map, applicant) => ({
          ...map,
          [applicant.id]: applicant.latest_full_name,
        }),
        {} as FullNameByApplicantIds,
      ),
    [applicants],
  );
}
