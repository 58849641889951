import { gql } from '@apollo/client';
import { useMemo } from 'react';
import {
  Array,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from 'runtypes';

import {
  BankProviderFragmentDoc,
  InstitutionAccountStatus as InstitutionAccountStatusEnum,
  useGetAllInstitutionAccountsWithInstitutionConnectionQuery,
  useGetConnectedInstitutionsQuery,
} from '../generated/graphql';
import { filterArray } from '../utils/runtypes';

export const GetAllInstitutionAccountsWithInstitutionConnection = gql`
  query GetAllInstitutionAccountsWithInstitutionConnection(
    $institutionConnectionId: Int!
    $status: InstitutionAccountStatus
  ) {
    getInstitutionConnection(
      institutionConnectionId: $institutionConnectionId
    ) {
      id
      institution {
        name
        favicon
        id
      }
      profiles {
        name {
          fullName
          first
        }
      }
    }
    getAllInstitutionAccounts(
      institutionConnectionId: $institutionConnectionId
      status: $status
    ) {
      id
      container
      accountName
      accountNumber
      accountBsb
      accountType
      accountStatus
      institutionConnectionId
      balance {
        amount
        currency
      }
      totalCreditLine {
        amount
        currency
      }
    }
  }
`;

const InstitutionAccountStatus = Union(
  Literal('ACTIVE'),
  Literal('CLOSED'),
  Literal('DELETED'),
  Literal('INACTIVE'),
  Literal('TO_BE_CLOSED'),
);

const Money = Record({
  amount: Number,
  currency: String,
});

const InstitutionAccount = Record({
  id: Number,
  accountName: String,
  accountNumber: String,
  accountType: String,
  container: String,
  accountStatus: InstitutionAccountStatus,
  institutionConnectionId: Number,
  balance: Optional(Money.Or(Null)),
  totalCreditLine: Optional(Money.Or(Null)),
});

export type InstitutionAccountType = Static<typeof InstitutionAccount>;

export const GetConnectedInstitutionsQuery = gql`
  query GetConnectedInstitutions {
    me {
      user {
        id
      }
    }

    getAllInstitutionConnections {
      id
      institutionId
      accountCount
      institution {
        ...BankProvider
      }
      profiles {
        name {
          fullName
          first
        }
      }
    }
  }
  ${BankProviderFragmentDoc}
`;

const Institution = Record({
  id: Number,
  name: String,
  favicon: String,
});

const InstitutionConnectionProfile = Record({
  name: Record({ fullName: String.nullable(), first: String.nullable() }),
});

const ConnectedInstitution = Record({
  id: Number,
  institutionId: Number,
  institution: Institution,
  accountCount: Number,
  profiles: Array(InstitutionConnectionProfile),
});

export type ConnectedInstitutionType = Static<typeof ConnectedInstitution>;

export function useConnectedInstitutionsQuery() {
  const { data, ...others } = useGetConnectedInstitutionsQuery({
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {},
    },
  });

  const connectedInstitutions = filterArray(
    ConnectedInstitution,
    data?.getAllInstitutionConnections || [],
  );

  return { data, ...others, connectedInstitutions };
}

/**
 * A map of the row label by the account type.
 * If the account type is not listed here, that account will be displayed as `other`.
 * Account types pulled from: https://developer.yodlee.com/Yodlee_API/docs/v1_1/Data_Model/Resource_Account#Account_Type
 */
export const ROW_LABEL_BY_INSTITUTION_ACCOUNT_TYPE = {
  // TODO: [i18n] Use getter to allow translating string inside constants
  // Container type: bank
  CHECKING: 'Transaction Account',
  // Container type: loan
  MORTGAGE: 'Mortgage',
  INSTALLMENT_LOAN: 'Installment Loan',
  PERSONAL_LOAN: 'Personal Loan',
  HOME_LOAN: 'Home Loan',
  LINE_OF_CREDIT: 'Line Of Credit',
  // Container type: creditCard
  CREDIT: 'Credit Card',
  STORE: 'Store Card',
  SAVINGS: 'Savings',
};

export function useAllInstitutionAccountsQuery(
  institutionConnectionId: number,
) {
  const { data, ...others } =
    useGetAllInstitutionAccountsWithInstitutionConnectionQuery({
      skip: institutionConnectionId == null,
      variables: {
        institutionConnectionId,
        status: InstitutionAccountStatusEnum.Active,
      },
      context: {
        sentryContext: {
          institutionConnectionId,
        },
      },
    });
  const institutionAccounts = filterArray(
    InstitutionAccount,
    data?.getAllInstitutionAccounts || [],
  );

  const institutionDetails = useMemo(
    () => ({
      institutionName: data?.getInstitutionConnection?.institution?.name,
      institutionFavIcon: data?.getInstitutionConnection?.institution?.favicon,
      accountHolders: data?.getInstitutionConnection?.profiles,
    }),
    [
      data?.getInstitutionConnection?.institution?.favicon,
      data?.getInstitutionConnection?.institution?.name,
      data?.getInstitutionConnection?.profiles,
    ],
  );

  return {
    ...others,
    institutionAccounts,
    connectedInstitutionDetails: institutionDetails,
  };
}
