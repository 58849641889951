import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { useLoanApplicationSecuritiesQuery } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Spinner } from '../../ui/atoms/Spinner';
import { ListRow } from '../../ui/molecules/ListRow';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { useLoanApplicationHeaderProgress } from '../../utils/hooks/useLoanApplicationHeaderProgress';
import { formatAddress } from '../../utils/stringHelpers';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationBackButton } from '../components/LoanApplicationBackButton';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { PropertyDetailsCaptionForPurchase } from '../components/PropertyDetailsCaption';
import PropertyStaticMap from '../components/PropertyStaticMap';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY>;
const currentSection = LoanApplicationSection.Property;

function LoanApplicationYourPropertyPurchaseBase({ navigation, route }: Props) {
  const { params } = route;

  const loanApplicationId = params?.loanApplicationId;

  const {
    data,
    loading: applicationSecuritiesLoading,
    error: applicationSecuritiesError,
    refetch: applicationSecuritiesRefetch,
  } = useLoanApplicationSecuritiesQuery({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });
  const loanApplication = data?.loan_application_by_pk;
  const expectedRentalIncome = loanApplication?.expectedRentalIncomes?.[0];
  const loanApplicationSecurities =
    loanApplication?.loan_application_securities ?? [];

  const hasSavedProperties = loanApplicationSecurities.length > 0;
  const [firstLoanApplicationSecurity] = loanApplicationSecurities;
  const { latitude, longitude } =
    firstLoanApplicationSecurity?.property?.address || {};
  const addressLatLong =
    latitude != null && longitude != null ? { latitude, longitude } : null;
  const [first, last] = formatAddress(
    firstLoanApplicationSecurity?.property?.address,
  );
  const googleMapDisplayLabel =
    firstLoanApplicationSecurity?.property.address.display_address;
  const primaryButtonLabel = hasSavedProperties
    ? t('Content.Common.ButtonLabel.Continue')
    : t('Content.PropertyDetails.ButtonLabel.SelectYourProperty');

  const {
    getScreenOrder,
    navigateToNextLoanApplicationScreen,
    navigateToLoanApplicationScreen,
  } = useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const headerLeft = React.useCallback(
    (props: React.ComponentProps<typeof LoanApplicationBackButton>) => (
      <LoanApplicationBackButton
        {...props}
        onPress={() =>
          navigateToLoanApplicationScreen({
            // User should go back to conditional approval
            // regardless of where they came from.
            section: LoanApplicationSection.ConditionalApproval,
          })
        }
      />
    ),
    [navigateToLoanApplicationScreen],
  );

  React.useEffect(() => {
    navigation.setOptions({
      headerLeft,
    });
  }, [headerLeft, navigation]);

  const navigateToPropertyDetailsModal = () =>
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.YOUR_PROPERTY_PURCHASE_MODAL,
      params: {
        ...params,
        loanApplicationSecurityId: firstLoanApplicationSecurity?.id,
      },
    });

  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({ currentSection }),
  });

  const onPrimaryButtonPress = () => {
    if (hasSavedProperties) {
      navigateToNextLoanApplicationScreen({
        currentSection,
      });
      return;
    }

    navigateToPropertyDetailsModal();
  };

  if (!loanApplicationId) {
    return (
      <LoanApplicationScreenContainer>
        <EmptyState
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </LoanApplicationScreenContainer>
    );
  }

  if (applicationSecuritiesLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <EmptyState title={t('Content.PropertyDetails.Header.Checking')}>
          <Spinner size="large" />
        </EmptyState>
      </LoanApplicationScreenContainer>
    );
  }

  if (applicationSecuritiesError) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={applicationSecuritiesError}
          displayMessage={t('Content.PropertyCheck.Error.ScreenInitialQuery')}
          refetch={applicationSecuritiesRefetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });
  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanScreenHeader
        title={t('Content.PropertyDetails.Header.Title')}
        caption={t(
          'Content.PropertyDetails.Header.CaptionSelectPropertiesPurchase',
        )}
      />
      {hasSavedProperties ? (
        <Box my="xl" borderRadius="card" bg="shapeBg" px="xs" py="xs" mx="m">
          <Box borderRadius="card">
            <PropertyStaticMap
              location={addressLatLong || googleMapDisplayLabel}
              locationName={first}
            />
            <ListRow
              testID={TestID.LoanApplicationPropertyCheck.AddressDetails}
              arrowForwardColor="secondaryContent"
              isButton
              label={first || ''}
              labelFontWeight="semiBold"
              caption={last || ''}
              useArrow
              color="primaryContent"
              onPress={navigateToPropertyDetailsModal}
              inset
              last
            />
            <PropertyDetailsCaptionForPurchase
              propertyValue={
                firstLoanApplicationSecurity?.purchase_price || null
              }
              propertyPurpose={
                firstLoanApplicationSecurity?.property_purpose || null
              }
              propertyType={firstLoanApplicationSecurity?.property_type || null}
              testId={
                TestID.LoanApplicationPropertyCheck.SelectedPropertyDetails
              }
              expectedRentalIncome={expectedRentalIncome?.rentalIncome}
              rentalIncomeFrequency={
                expectedRentalIncome?.rentalIncomeFrequency
              }
              expectedRentalExpense={
                expectedRentalIncome?.rental_income?.rentalExpense
              }
              rentalExpenseFrequency={
                expectedRentalIncome?.rental_income?.rentalExpenseFrequency
              }
            />
          </Box>
        </Box>
      ) : (
        <Box my="xl" alignItems="center" bg="bg">
          <EmptyStateIllustration name="propertyV2" />
        </Box>
      )}
      <LoanApplicationWizardFooter
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonPress={onPrimaryButtonPress}
        primaryButtonTestID={TestID.LoanApplicationPropertyCheck.ContinueButton}
      />
    </LoanApplicationScreenContainer>
  );
}

export const LoanApplicationYourPropertyPurchase = withAuthenticationRequired(
  LoanApplicationYourPropertyPurchaseBase,
);
