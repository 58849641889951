import { useTheme } from '@shopify/restyle';
import * as React from 'react';

import { Theme } from '../theme';
import { createBox } from '../utils/createBox';

const BaseBox = createBox();

export type BoxProps = React.ComponentProps<typeof BaseBox> & {
  bordered?: boolean;
  row?: boolean;
  column?: boolean;
  centered?: boolean;
  contentContainer?: boolean;
  wideContentContainer?: boolean;
};

/**
 * @deprecated moving away from @shopify/restyle to use View from
 * dripsy
 */
export function Box({
  centered,
  bordered,
  row,
  column,
  contentContainer,
  wideContentContainer = false,
  overflow = 'hidden',
  ...props
}: BoxProps) {
  const theme = useTheme<Theme>();
  return (
    <BaseBox
      {...(centered && { alignItems: 'center', justifyContent: 'center' })}
      {...(row && { flexDirection: 'row' })}
      {...(column && { flexDirection: 'column' })}
      {...(bordered && {
        borderStyle: 'solid',
        borderWidth: theme.constants.borderWidth,
        borderColor: 'border',
      })}
      {...(contentContainer && {
        width: '100%',
        maxWidth: wideContentContainer
          ? theme.constants.maxDashboardWidth
          : theme.constants.maxContentWidth,
      })}
      overflow={overflow}
      {...props}
    />
  );
}

export function useContentContainerStyles() {
  const theme = useTheme<Theme>();
  return {
    width: '100%',
    maxWidth: theme.constants.maxContentWidth,
  };
}
