import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { RemovePropertyActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import { ErrorRow } from '../../components/ErrorRow';
import { AppAddressFormat } from '../../components/form/types';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  Frequency_Enum,
  Frequency_Input_Enum,
  Property_Purpose_Enum,
  refetchLoanApplicationSecuritiesQuery,
  refetchPurchaseSetupLoanScreenQuery,
  refetchReviewLoanApplicationQuery,
  refetchYourFinancialsQuery,
  State_Enum,
  UpsertLoanApplicationSecurityMutationVariables,
  useGetLoanApplicationDetailsQuery,
  useGetLoanApplicationSecurityDetailsFromLoanApplicationQuery,
  useUpsertLoanApplicationSecurityMutation,
} from '../../generated/graphql';
import { useStateOptionsQuery } from '../../Identification/hooks/useStateOptions';
import { SecurityUpdateWarningRow } from '../../Income/components/SecurityUpdateWarningRow';
import { SingleModalStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapHasuraAddressToAppAddress } from '../../utils/mapToAppAddress';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import { PropertyDetailsPurchaseForm } from '../components/PropertyDetailsPurchaseForm';
import { usePropertyOptions } from '../graphql/query';
import {
  DEFAULT_PROPERTY_DETAILS_PURCHASE_INITIAL_VALUES,
  PropertyDetailsPurchaseFormFields,
  PropertyDetailsPurchaseFormValue,
  propertyDetailsPurchaseManualInputFieldNames,
  PropertyYesNoEnum,
} from '../utils/propertyDetailsPurchaseFormUtils';
import {
  getUpsertApplicationSecurityErrorMessage,
  mapPropertyPurposeForV2,
} from '../utils/propertyDetailsUtils';

export type Props =
  SingleModalStackScreenProps<Screen.YOUR_PROPERTY_PURCHASE_MODAL>;

function useGetInitialFormValues({
  loanApplicationId,
  loanApplicationSecurityId,
}: {
  loanApplicationId?: string;
  loanApplicationSecurityId?: string;
}) {
  const { data, ...others } =
    useGetLoanApplicationSecurityDetailsFromLoanApplicationQuery({
      variables: {
        loanApplicationId: loanApplicationId ?? '',
        loanApplicationSecurityIdWhere: loanApplicationSecurityId
          ? {
              _eq: loanApplicationSecurityId,
            }
          : {},
      },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationSecurityId,
        },
      },
    });

  // Populate the initial state when provided.
  const currentLoanApplicationSecurity =
    data?.loan_application_by_pk?.loan_application_securities?.[0];
  const currentSelectedProperty =
    currentLoanApplicationSecurity?.property.address;

  const currentAppAddressForCurrentProperty = currentSelectedProperty
    ? mapHasuraAddressToAppAddress(currentSelectedProperty)
    : undefined;

  // Living-in only
  let initialFirstTimeHomeBuyer: PropertyYesNoEnum | undefined;
  const currentIsFirstTimeHomeBuyer =
    currentLoanApplicationSecurity?.is_first_home_buyer;
  if (isNotNullOrUndefined(currentIsFirstTimeHomeBuyer)) {
    initialFirstTimeHomeBuyer =
      PropertyYesNoEnum[currentIsFirstTimeHomeBuyer ? 'Yes' : 'No'];
  }

  const livingInInitialValues = {
    [PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer]:
      initialFirstTimeHomeBuyer,
  };

  // Investment only
  // There should only be one expected income
  const currentExpectedRentalIncome = data?.loan_application_by_pk?.incomes[0];
  const currentExpectedRentalIncomeAmount =
    currentExpectedRentalIncome?.expected_rental_income;
  const currentExpectedRentalIncomeFrequency =
    currentExpectedRentalIncome?.expected_rental_income_frequency;
  const currentExpectedRentalExpenseAmount =
    currentExpectedRentalIncome?.rental_income?.expected_rental_expense;
  const currentExpectedRentalExpenseFrequency =
    currentExpectedRentalIncome?.rental_income
      ?.expected_rental_expense_frequency;

  const investmentInitialValues = {
    [PropertyDetailsPurchaseFormFields.ExpectedRentalIncome]:
      currentExpectedRentalIncomeAmount,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency]:
      currentExpectedRentalIncomeFrequency ?? Frequency_Enum.Monthly,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses]:
      currentExpectedRentalExpenseAmount,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency]:
      currentExpectedRentalExpenseFrequency ?? Frequency_Enum.Monthly,
  };

  const existingPropertyPurpose =
    currentLoanApplicationSecurity?.property_purpose ??
    data?.loan_application_by_pk?.initial_property_purpose;

  const initialFormValues: PropertyDetailsPurchaseFormValue = {
    ...DEFAULT_PROPERTY_DETAILS_PURCHASE_INITIAL_VALUES,
    [PropertyDetailsPurchaseFormFields.PropertyPurpose]:
      existingPropertyPurpose ?? undefined,
    [PropertyDetailsPurchaseFormFields.PropertyType]:
      currentLoanApplicationSecurity?.property_type ?? undefined,
    [PropertyDetailsPurchaseFormFields.PropertyValue]:
      currentLoanApplicationSecurity?.purchase_price ?? undefined,

    ...(existingPropertyPurpose === Property_Purpose_Enum.LivingIn &&
      livingInInitialValues),
    ...(existingPropertyPurpose === Property_Purpose_Enum.Investing &&
      investmentInitialValues),

    // Only populate this if current address is from suggestion result
    // reason being that manual address values are in separate fields
    [PropertyDetailsPurchaseFormFields.AddressResult]:
      !currentAppAddressForCurrentProperty?.isProvidedByUser
        ? currentAppAddressForCurrentProperty
        : undefined,

    // Manual address input initial values
    [propertyDetailsPurchaseManualInputFieldNames.isManualInput]:
      currentAppAddressForCurrentProperty?.isProvidedByUser ?? undefined,
    [propertyDetailsPurchaseManualInputFieldNames.postcode]:
      currentAppAddressForCurrentProperty?.postcode ?? undefined,
    [propertyDetailsPurchaseManualInputFieldNames.state]:
      parseEnumType(State_Enum, currentAppAddressForCurrentProperty?.state) ??
      undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetName]:
      currentAppAddressForCurrentProperty?.street ?? undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetNo]:
      currentAppAddressForCurrentProperty?.streetNo ?? undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetType]:
      currentAppAddressForCurrentProperty?.streetType
        ? {
            label: currentAppAddressForCurrentProperty.streetType,
            value: currentAppAddressForCurrentProperty.streetType,
          }
        : undefined,
    [propertyDetailsPurchaseManualInputFieldNames.suburb]:
      currentAppAddressForCurrentProperty?.suburb ?? undefined,
    [propertyDetailsPurchaseManualInputFieldNames.unitNo]:
      currentAppAddressForCurrentProperty?.unitNo ?? undefined,
  };

  return {
    initialFormValues,
    loanApplicationTargetId:
      currentLoanApplicationSecurity?.loan_application_target_id,
    ...others,
  };
}

export function PropertyDetailsPurchase({ navigation, route }: Props) {
  const { loanApplicationId, loanApplicationSecurityId, _initialErrorMessage } =
    route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const [formErrorMessage, setFormErrorMessage] = useState(
    _initialErrorMessage ?? '',
  );
  const removePropertyActionSheetErrorMessage = useRecoilValue(
    RemovePropertyActionSheetErrorAtom,
  );
  const resetRemovePropertyActionSheetErrorMessage = useResetRecoilState(
    RemovePropertyActionSheetErrorAtom,
  );

  useFocusEffect(() => () => resetRemovePropertyActionSheetErrorMessage());

  const {
    loadingPropertyOptions,
    propertyPurposeOptions,
    propertyTypeOptions,
    frequencyOptions,
  } = usePropertyOptions();

  const { data: stateOptions, loading: stateOptionsLoading } =
    useStateOptionsQuery();

  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  const { data, loading: loanApplicationDetailsLoading } =
    useGetLoanApplicationDetailsQuery({
      variables: {
        id: loanApplicationId || '',
      },
      skip: !loanApplicationId,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  const loanApplication = data?.loan_application_by_pk;
  const isLmiEnabled = loanApplication?.is_lmi_enabled ?? false;

  // Populate the initial state when provided.
  const {
    initialFormValues,
    loanApplicationTargetId: currentSecurityLoanApplicationTargetId,
    loading: initialFormLoading,
  } = useGetInitialFormValues({
    loanApplicationId,
    loanApplicationSecurityId,
  });

  const [
    upsertLoanApplicationSecurity,
    { loading: upsertLoanApplicationSecurityLoading },
  ] = useUpsertLoanApplicationSecurityMutation();

  const navigateBackToPreviousScreen = () => {
    tryNavigateBackToSummary(() =>
      navigation.navigate(Screen.LOAN_APPLICATION_V2, {
        screen: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
        params: {
          loanApplicationId,
        },
      }),
    );
  };

  /**
   * Navigate back to Your Property screen if target has been removed
   * and LMI FF is enabled after upserting property details
   */
  const navigateBackToPreviousScreenAfterUpsert = ({
    forceBackToYourPropertyScreen,
  }: {
    forceBackToYourPropertyScreen: boolean;
  }) => {
    tryNavigateBackToSummary(
      () =>
        navigation.navigate(Screen.LOAN_APPLICATION_V2, {
          screen: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
          params: {
            loanApplicationId,
          },
        }),
      forceBackToYourPropertyScreen,
    );
  };

  const onSubmit = async (values: PropertyDetailsPurchaseFormValue) => {
    resetRemovePropertyActionSheetErrorMessage();

    const {
      propertyType,
      propertyValue,
      addressResult,
      propertyPurpose,
      firstTimeHomeBuyer,
      expectedRentalExpenses,
      expectedRentalExpensesFrequency,
      expectedRentalIncome,
      expectedRentalIncomeFrequency,
    } = values;

    const isManualInput =
      values[propertyDetailsPurchaseManualInputFieldNames.isManualInput];
    let addressToSubmit: AppAddressFormat | null = null;
    if (isManualInput) {
      addressToSubmit = {
        isProvidedByUser: true,
        postcode: values[propertyDetailsPurchaseManualInputFieldNames.postcode],
        street: values[propertyDetailsPurchaseManualInputFieldNames.streetName],
        streetNo: values[propertyDetailsPurchaseManualInputFieldNames.streetNo],
        unitNo: values[propertyDetailsPurchaseManualInputFieldNames.unitNo],
        state: values[propertyDetailsPurchaseManualInputFieldNames.state],
        streetType:
          values[propertyDetailsPurchaseManualInputFieldNames.streetType]
            ?.value,
        suburb: values[propertyDetailsPurchaseManualInputFieldNames.suburb],
      };
    } else {
      addressToSubmit = addressResult ?? null;
    }

    if (
      !loanApplicationId ||
      !propertyType ||
      propertyValue == null ||
      propertyPurpose == null ||
      addressToSubmit == null
    ) {
      setFormErrorMessage(
        t('Content.PropertyDetails.Error.FailSubmittingForm'),
      );
      return;
    }

    const {
      manualAddressInput: validatedManualAddressInput,
      domainApiAddressInput: validatedDomainApiAddressInput,
    } = parseAddressInputForMutationAction(addressToSubmit);

    const propertyVariables: Pick<
      UpsertLoanApplicationSecurityMutationVariables,
      'manualAddressInput' | 'domainApiAddressInput'
    > = {
      domainApiAddressInput: validatedDomainApiAddressInput,
      manualAddressInput: validatedManualAddressInput,
    };

    if (
      !propertyVariables.domainApiAddressInput &&
      !propertyVariables.manualAddressInput
    ) {
      setFormErrorMessage(t('Content.Common.Error.InvalidInputtedAddress'));
      return;
    }
    const loanApplicationTargetId =
      currentSecurityLoanApplicationTargetId ??
      data?.loan_application_by_pk?.loan_application_targets?.[0]?.id;

    const isFirstHomeBuyer = firstTimeHomeBuyer === PropertyYesNoEnum.Yes;

    const parsedExpectedRentalIncomeFrequency = parseEnumType(
      Frequency_Input_Enum,
      expectedRentalIncomeFrequency,
    );
    const parsedExpectedRentalExpensesFrequency = parseEnumType(
      Frequency_Input_Enum,
      expectedRentalExpensesFrequency,
    );
    const isMissingExpectedRentalIncomeInputs =
      !isNotNullOrUndefined(expectedRentalIncome) ||
      !isNotNullOrUndefined(expectedRentalExpenses) ||
      !parsedExpectedRentalIncomeFrequency ||
      !parsedExpectedRentalExpensesFrequency;

    const requireExpectedRentalIncomeInput =
      propertyPurpose === Property_Purpose_Enum.Investing;

    if (
      requireExpectedRentalIncomeInput &&
      isMissingExpectedRentalIncomeInputs
    ) {
      setFormErrorMessage(
        t('Content.PropertyDetails.Error.FailSubmittingForm'),
      );
      return;
    }

    const expectedRentalIncomeInput: UpsertLoanApplicationSecurityMutationVariables['expectedRentalIncomeInput'] =
      requireExpectedRentalIncomeInput && !isMissingExpectedRentalIncomeInputs
        ? {
            income_amount: expectedRentalIncome,
            income_frequency: parsedExpectedRentalIncomeFrequency,
            expense_amount: expectedRentalExpenses,
            expense_frequency: parsedExpectedRentalExpensesFrequency,
          }
        : undefined;

    const variables: UpsertLoanApplicationSecurityMutationVariables = {
      loanApplicationSecurityId,
      loanApplicationTargetId,
      loanApplicationId,
      propertyType,
      propertyValue,
      propertyPurpose,
      isFirstHomeBuyer,
      expectedRentalIncomeInput,
      ...propertyVariables,
      isCreate: !loanApplicationSecurityId,
    };
    const [res] = await safelyCallMutation(upsertLoanApplicationSecurity, {
      variables,
      refetchQueries: [
        refetchLoanApplicationSecuritiesQuery({ loanApplicationId }),
        refetchPurchaseSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchYourFinancialsQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: { variables, loanApplicationId },
      },
    });

    const errMessage = getUpsertApplicationSecurityErrorMessage(res);

    if (errMessage != null) {
      setFormErrorMessage(errMessage);
      return;
    }
    const isLoanAppTargetNotExist =
      res?.data?.upsert_loan_application_security.returning
        ?.loan_application_target_id == null;
    navigateBackToPreviousScreenAfterUpsert({
      forceBackToYourPropertyScreen: isLmiEnabled && isLoanAppTargetNotExist,
    });
  };

  const showLoading =
    loanApplicationDetailsLoading ||
    loadingPropertyOptions ||
    stateOptionsLoading ||
    initialFormLoading;

  const showUpdateWarning =
    !formErrorMessage && isLmiEnabled && currentSecurityLoanApplicationTargetId;

  return (
    <ModalScreenContainer
      headerText={t('Content.PropertyDetails.Header.Title')}
      scrollable
      hideBackButton
      onClose={navigateBackToPreviousScreen}
      loading={upsertLoanApplicationSecurityLoading}
    >
      {showLoading ? (
        <ScreenLoadingContainer loading />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={removePropertyActionSheetErrorMessage || formErrorMessage}
          />
          {showUpdateWarning ? (
            <SecurityUpdateWarningRow sx={{ mb: '$24' }} />
          ) : null}
          <PropertyDetailsPurchaseForm
            screen={Screen.YOUR_PROPERTY_PURCHASE_MODAL}
            initialValues={initialFormValues}
            propertyPurposeOptions={mapPropertyPurposeForV2(
              propertyPurposeOptions,
            )}
            propertyTypeOptions={propertyTypeOptions}
            stateOptions={stateOptions}
            streetTypeOptions={streetTypeOptions}
            isSubmitting={upsertLoanApplicationSecurityLoading}
            onSubmit={onSubmit}
            isLoadingSuggestionData={isLoadingSuggestionData}
            loadSuggestionData={loadSuggestionData}
            propertySuggestionData={propertySuggestionData}
            frequencyOptions={frequencyOptions}
            onRentalExpenseLearnMoreClick={() => {
              navigation.navigate(Screen.SINGLE_V2_MODAL, {
                screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
              });
            }}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
