import { Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import { FormRadioInputV2 } from '../../../components/form/FormikInputs';
import { PickerOption } from '../../../components/form/types';
import { Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { Spinner } from '../../../ui/atoms/Spinner';
import { FormikFormError } from '../../../ui/v2/FormError';
import { makeTestId } from '../../../utils/stringHelpers';
import { yup } from '../../../utils/yup';

enum SelectRefiValidationFields {
  RefinancingReason = 'refinancingReason',
}

const SelectRefiValidationSchema = yup.object({
  [SelectRefiValidationFields.RefinancingReason]: yup
    .string()
    .required(t('Content.Common.Error.PleaseSelectAnOption')),
});

export type SelectRefiValidationFormValues = Partial<
  yup.InferType<typeof SelectRefiValidationSchema>
>;

const DEFAULT_INITIAL_VALUES: SelectRefiValidationFormValues = {
  refinancingReason: undefined,
};

type Props = {
  screen: Screen;
  onSubmit: (values: SelectRefiValidationFormValues) => void;
  isSubmitting: boolean;
  reasonOptions: Array<PickerOption<string>>;
  reasonOptionsLoading: boolean;
  initialValues?: SelectRefiValidationFormValues;
};

export function SelectRefiReasonForm({
  screen,
  onSubmit,
  isSubmitting,
  reasonOptions,
  reasonOptionsLoading,
  initialValues = DEFAULT_INITIAL_VALUES,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SelectRefiValidationSchema}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <Text sx={{ py: '$8', variant: 'text.sHeader' }}>
            {t('Content.SelectRefiReasonV2.Header')}
          </Text>
          {/* TODO Confirm with Chloe how to handle loading here */}
          {reasonOptionsLoading ? (
            <Spinner />
          ) : (
            reasonOptions.map((item) => (
              <FormRadioInputV2
                key={item.value}
                name={SelectRefiValidationFields.RefinancingReason}
                value={item.value}
                testID={makeTestId([
                  TestID.SelectRefiReasonV2.RefiReason,
                  item.value,
                ])}
                label={item.label}
                containerStyle={{
                  mt: '$8',
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.YourUnloan,
                  screen,
                  FieldInteractionKey.RefinancingReason,
                )}
              />
            ))
          )}
          <FormikFormError
            name={SelectRefiValidationFields.RefinancingReason}
            sx={{ my: '$8' }}
          />
          <Button
            testID={TestID.SelectRefiReasonV2.SubmitButton}
            label={t('Content.Common.ButtonLabel.Next')}
            mt="xl"
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
            showSpinner={isSubmitting}
            alignSelf="stretch"
          />
        </View>
      )}
    </Formik>
  );
}
