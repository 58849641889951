import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { ScreenContainer } from '../../ui/atoms/ScreenContainer';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { StyledText } from '../../ui/atoms/StyledText';
import { ListRow } from '../../ui/molecules/ListRow';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast } from '../../utils/arrayHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { StatementsModalScreenProps } from '../navigation/types';
import { useStatementPeriodsQuery } from '../remoteData';

type Props = StatementsModalScreenProps<Screen.STATEMENTS_LIST_MODAL>;

const STATEMENTS_DATE_FORMAT = 'dd MMM yyyy';

export function StatementsList({ navigation, route }: Props) {
  const { cbaAccountId } = route.params;
  const { data, loading, error } = useStatementPeriodsQuery(cbaAccountId);

  const {
    periods,
    statement_end_date: endDate,
    statement_start_date: startDate,
  } = data.statement_periods;

  const navigateToStatementsFilter = (
    start: string | null,
    end: string | null,
  ) => {
    if (start && end) {
      navigation.navigate(Screen.STATEMENTS_MODAL, {
        screen: Screen.STATEMENTS_FILTER_MODAL,
        params: {
          cbaAccountId,
          startDate: start,
          endDate: end,
        },
      });
    }
  };

  const navigateToStatementPreview = (start: string, end: string) => {
    navigation.navigate(Screen.STATEMENTS_MODAL, {
      screen: Screen.STATEMENTS_PREVIEW_MODAL,
      params: {
        cbaAccountId,
        startDate: start,
        endDate: end,
      },
    });
  };

  const formatStatementDate = (start: string, end: string) =>
    `${safelyFormatDate(
      new Date(start),
      STATEMENTS_DATE_FORMAT,
    )} - ${safelyFormatDate(new Date(end), STATEMENTS_DATE_FORMAT)}`;

  return (
    <ScreenContainer bg="bg" safeAreaBottom={false} safeAreaTop={false}>
      <NavHeaderSpacer />

      <ScreenLoadingContainer loading={loading}>
        <ScrollContainer overflow="visible">
          <Box mb="l">
            {error ? (
              <ErrorRow
                m="m"
                message={t('Content.Common.Error.FailFetchStatementsList')}
              />
            ) : null}
            {periods.length === 0 ? (
              <StyledText variant="caption" textAlign="center">
                {t('Content.Statements.StatementsNotAvailable')}
              </StyledText>
            ) : (
              <ListRowGroup testID={TestID.Statements.StatementList}>
                {periods.map(({ start, end, period }, index, values) => (
                  <ListRow
                    key={period}
                    label={t('Content.Statements.StatementRowTitle')}
                    caption={formatStatementDate(start, end)}
                    onPress={() => navigateToStatementPreview(start, end)}
                    last={isLast(values, index)}
                  />
                ))}
              </ListRowGroup>
            )}

            {startDate && endDate ? (
              <ListRowGroup mt="m">
                <ListRow
                  isButton
                  labelTestId={TestID.Statements.GenerateStatementButton}
                  label={t('Content.Statements.Titles.FilterButton')}
                  onPress={() => navigateToStatementsFilter(startDate, endDate)}
                  last
                />
              </ListRowGroup>
            ) : null}
          </Box>
        </ScrollContainer>
      </ScreenLoadingContainer>
    </ScreenContainer>
  );
}
