import { ApolloError, gql } from '@apollo/client';
import { Image, Text, View } from 'dripsy';
import { useCallback, useContext, useEffect, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import {
  ConditionallyApprovedContentWithVca,
  LoanApplicationForVcaDetails,
} from '../../ConditionalApproval/components/ConditionallyApprovedContentWithVca';
import { DeclinedByConductRules } from '../../ConditionalApproval/components/DeclinedByConductRules';
import { DeclinedConditionalApproval } from '../../ConditionalApproval/components/DeclinedConditionalApproval';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Conditional_Approval_Evaluation_State_Enum,
  Conditional_Approval_Status_Output_Enum,
  ConditionalApprovalDetailsSubscription,
  GetConditionalApprovalDataQuery,
  useConditionalApprovalDetailsSubscription,
  useGetConditionalApprovalDataLazyQuery,
  useRequestXaiSyncMutation,
  useSetConditionalApprovalEvaluatedAtMutation,
} from '../../generated/graphql';
import {
  ActionSheetType,
  PageGTMTitleWithStates,
  PageStateForStatus,
  Screen,
} from '../../navigation/types/screens';
import { addBreadcrumb } from '../../sentry';
import { StyledText } from '../../ui/atoms/StyledText';
import { EmptyState as EmptyStateComponent } from '../../ui/organisms/EmptyState';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { formatCurrency } from '../../utils/currencyHelpers';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { titleCase } from '../../utils/stringHelpers';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';
import { useLoanApplication } from '../utils/useLoanApplication';

export const SetConditionalApprovalEvaluatedAt = gql`
  mutation SetConditionalApprovalEvaluatedAt($loanApplicationId: uuid!) {
    set_conditional_approval_evaluated_at(
      loan_application_id: $loanApplicationId
    ) {
      applicant_id
    }
  }
`;

export const GetConditionalApprovalData = gql`
  query GetConditionalApprovalData(
    $loanApplicationId: uuid!
    $enableVca: Boolean!
  ) {
    get_conditional_approval_data(loan_application_id: $loanApplicationId) {
      loan_application @include(if: $enableVca) {
        ...LoanApplicationForVcaDetails
      }
      conditionalApprovalStatus: conditional_approval_status
      conditionallyApprovedAmount: conditionally_approved_amount
      pending_applicants {
        applicantFullName: latest_full_name
      }
      isCcrExpired: is_ccr_expired
    }
  }

  ${LoanApplicationForVcaDetails}
`;

export const RequestXaiSync = gql`
  mutation RequestXaiSync($loanApplicationId: uuid!) {
    request_xai_sync(loan_application_id: $loanApplicationId) {
      success
    }
  }
`;

function getScreenPropsBasedOnConditionalApprovalStatus({
  conditionalApprovalStatus,
  isLmiEnabled,
  otherApplicantName,
  navigateToYourBorrowingPowerInformationScreen,
  navigateToYourConditionalApprovalInformationScreen,
}: {
  conditionalApprovalStatus: Conditional_Approval_Status_Output_Enum | null;
  isLmiEnabled?: boolean;
  otherApplicantName?: string | null;
  navigateToYourBorrowingPowerInformationScreen: () => void;
  navigateToYourConditionalApprovalInformationScreen: () => void;
}) {
  if (
    conditionalApprovalStatus ===
    Conditional_Approval_Status_Output_Enum.Approved
  ) {
    return {
      title: t('Content.ConditionalApproval.Header.ApprovedTitle'),
      caption: t('Content.ConditionalApproval.Content.LoanAmountInformation'),
      footerCaption: t('Content.ConditionalApproval.Content.OnceYouveSigned'),
      captionLinkText: t('Content.ConditionalApproval.Content.ViewConditions'),
      showCaptionLink: isLmiEnabled,
      disablePrimaryButton: false,
      onLinkPress: navigateToYourConditionalApprovalInformationScreen,
    };
  }

  return {
    title: t('Content.ConditionalApproval.Header.BorrowingPowerTitle'),
    caption: t(
      'Content.ConditionalApproval.Content.MaxBorrowingPowerInformation',
    ),
    footerCaption: t('Content.ConditionalApproval.Content.ToContinue', {
      coborrower: titleCase(otherApplicantName ?? ''),
    }),
    captionLinkText: t('Content.ConditionalApproval.Content.ViewDetails'),
    showCaptionLink: true,
    disablePrimaryButton: true,
    onLinkPress: navigateToYourBorrowingPowerInformationScreen,
  };
}

function mapConditionalApprovalMutationDataToGTMPageState(
  status: Conditional_Approval_Status_Output_Enum | null,
) {
  switch (status) {
    case Conditional_Approval_Status_Output_Enum.Declined: {
      return PageStateForStatus.Declined;
    }
    case Conditional_Approval_Status_Output_Enum.ConductRulesFailedDeclined: {
      return PageStateForStatus.ConductRulesFailedDeclined;
    }
    case Conditional_Approval_Status_Output_Enum.ConductRulesNotRunDeclined: {
      return PageStateForStatus.ConductRulesNotRunDeclined;
    }
    case Conditional_Approval_Status_Output_Enum.InProgress: {
      return PageStateForStatus.Pending;
    }
    case Conditional_Approval_Status_Output_Enum.Pending: {
      return PageStateForStatus.Pending;
    }
    case Conditional_Approval_Status_Output_Enum.Approved: {
      return PageStateForStatus.Approved;
    }
    default: {
      return null;
    }
  }
}

function evaluateCAEvaluationState({
  caEvaluationStateData,
}: {
  caEvaluationStateData?: ConditionalApprovalDetailsSubscription;
}) {
  const conditionalApprovalEvaluationState =
    caEvaluationStateData?.loan_application_by_pk
      ?.conditional_approval_evaluation_state;
  const isPending =
    conditionalApprovalEvaluationState ===
    Conditional_Approval_Evaluation_State_Enum.InProgress;
  const isError =
    conditionalApprovalEvaluationState ===
    Conditional_Approval_Evaluation_State_Enum.ProcessingError;
  const isNeedRecalculation =
    conditionalApprovalEvaluationState ===
    Conditional_Approval_Evaluation_State_Enum.NeedRecalculation;
  return { isPending, isError, isNeedRecalculation };
}

const RESYNC_XAI_DEBOUNCE_WAIT_IN_MS = 500;

function useXaiResyncLogic({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  // Need this state because there's a delay between when user
  // requests resync and when the status/state is updated.
  const [clientResyncXaiLoading, setClientResyncXaiLoading] = useState(false);

  const [
    resyncXai,
    { loading: serverLoadingResyncXai, error: errorResyncXai },
  ] = useRequestXaiSyncMutation();

  const resyncXaiMutation = useCallback(() => {
    setClientResyncXaiLoading(true);

    safelyCallMutation(resyncXai, {
      variables: { loanApplicationId },
      context: {
        sentryContext: {
          description: 'Customer resyncing XAI application.',
          loanApplicationId,
        },
      },
    });
  }, [loanApplicationId, resyncXai]);

  const triggerResyncXai = useDebounce(
    resyncXaiMutation,
    RESYNC_XAI_DEBOUNCE_WAIT_IN_MS,
  );

  return {
    loadingResyncXai: serverLoadingResyncXai || clientResyncXaiLoading,
    errorResyncXai,
    triggerResyncXai,
    setClientResyncXaiLoading,
  };
}

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL>;
export const ConditionalApprovalIllustrationHeight = 248;

function LoanApplicationConditionalApprovalBase({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId ?? '';

  const { sendVirtualPageViewEventToGTM } = useSendDataToGTM();
  const sendDataToGTM = useCallback(
    (queryResult: GetConditionalApprovalDataQuery) => {
      const { conditionalApprovalStatus: currentConditionalApprovalStatus } =
        queryResult?.get_conditional_approval_data ?? {};

      const currentStatus = mapConditionalApprovalMutationDataToGTMPageState(
        currentConditionalApprovalStatus ?? null,
      );

      if (!currentStatus) {
        return;
      }

      sendVirtualPageViewEventToGTM({
        virtualPageName:
          PageGTMTitleWithStates[
            Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL
          ]?.[currentStatus] ??
          Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
        additionalData: {
          current_application_id: loanApplicationId,
        },
      });
    },
    [loanApplicationId, sendVirtualPageViewEventToGTM],
  );

  const { flags } = useContext(FeatureFlagsContext);

  const enableCaConfirmationPrompt = flags.ENABLE_CA_CONFIRMATION_PROMPT;
  const enableVca = flags.ENABLE_VCA;

  const [
    fetchConditionalApprovalData,
    {
      data,
      loading: loadingFetchingConditionalApprovalData,
      error: errorFetchingConditionalApprovalData,
    },
  ] = useGetConditionalApprovalDataLazyQuery({
    context: {
      sentryContext: {
        description: 'Fetching conditional approval data.',
        loanApplicationId,
      },
    },
    variables: { loanApplicationId, enableVca },
    onCompleted: sendDataToGTM,
    fetchPolicy: 'network-only',
  });

  const [
    setConditionalApprovalEvaluatedAt,
    {
      loading: loadingSetConditionalApproval,
      error: errorSetConditionalApproval,
    },
  ] = useSetConditionalApprovalEvaluatedAtMutation();

  const {
    errorResyncXai,
    loadingResyncXai,
    triggerResyncXai,
    setClientResyncXaiLoading,
  } = useXaiResyncLogic({ loanApplicationId });

  const {
    data: getCAEvaluationStateRes,
    loading: loadingConditionalApprovalEvaluationState,
  } = useConditionalApprovalDetailsSubscription({
    variables: { loanApplicationId },
    skip: !loanApplicationId,
    onData: (options) => {
      setClientResyncXaiLoading(false);
      const { isPending, isError, isNeedRecalculation } =
        evaluateCAEvaluationState({
          caEvaluationStateData: options.data.data,
        });
      const caEvaluationState =
        options.data.data?.loan_application_by_pk
          ?.conditional_approval_evaluation_state;

      const caStatus =
        options.data.data?.loan_application_by_pk?.conditional_approval_status;

      addBreadcrumb('CA Details gets updated', {
        caEvaluationState,
        caStatus,
      });

      if (isNeedRecalculation) {
        addBreadcrumb('Retriggering XAI calculation');
        triggerResyncXai();
        return;
      }

      if (isPending || isError || caStatus == null) {
        return;
      }

      addBreadcrumb(
        `Fetching conditional approval data after CA evaluation state = ${caEvaluationState} and CA status = ${caStatus}`,
      );

      fetchConditionalApprovalData().catch(
        // To prevent unhandled error from Promise rejection,
        // but we do not care about the error.
        () => {},
      );
    },
  });

  const {
    loading: loadingLoanApplication,
    loanApplicationType,
    loanApplication,
  } = useLoanApplication(loanApplicationId);

  const triggerSendConditionalApprovalEvaluatedAt = useCallback(() => {
    safelyCallMutation(setConditionalApprovalEvaluatedAt, {
      variables: { loanApplicationId },
      context: {
        sentryContext: {
          description: 'Setting conditional approval evaluated at.',
          loanApplicationId,
        },
      },
    });
  }, [loanApplicationId, setConditionalApprovalEvaluatedAt]);

  useEffect(() => {
    if (!loanApplicationId) {
      return;
    }
    triggerSendConditionalApprovalEvaluatedAt();
  }, [loanApplicationId, triggerSendConditionalApprovalEvaluatedAt]);

  const triggerRefetchQueriesBasedOnError = useCallback(async () => {
    if (errorSetConditionalApproval) {
      triggerSendConditionalApprovalEvaluatedAt();
    }
    if (errorFetchingConditionalApprovalData) {
      await triggerResyncXai();
    }
  }, [
    errorFetchingConditionalApprovalData,
    errorSetConditionalApproval,
    triggerResyncXai,
    triggerSendConditionalApprovalEvaluatedAt,
  ]);

  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const continueToNextScreen = () => {
    if (enableCaConfirmationPrompt) {
      navigation.navigate(ActionSheetType.CA_CONFIRMATION, {
        loanApplicationId,
      });
    } else {
      navigateToNextLoanApplicationScreen({
        currentSection: LoanApplicationSection.ConditionalApproval,
      });
    }
  };

  const navigateToApplicationDetailsScreen = () => {
    navigation.navigate(Screen.CONDITIONAL_APPROVAL_MODAL, {
      screen: Screen.CONDITIONAL_APPROVAL_APP_DETAILS,
      params: {
        loanApplicationId: loanApplicationId ?? '',
      },
    });
  };

  const navigateToYourBorrowingPowerInformationScreen = () => {
    navigation.navigate(Screen.CONDITIONAL_APPROVAL_MODAL, {
      screen: Screen.CONDITIONAL_APPROVAL_YOUR_BORROWING_POWER_DETAILS,
      params: {
        loanApplicationId: loanApplicationId ?? '',
      },
    });
  };

  const navigateToYourConditionalApprovalInformationScreen = () => {
    navigation.navigate(Screen.CONDITIONAL_APPROVAL_MODAL, {
      screen: Screen.CONDITIONAL_APPROVAL_YOUR_CONDITIONAL_APPROVAL_DETAILS,
      params: {
        loanApplicationId: loanApplicationId ?? '',
      },
    });
  };

  const navigateToVerifyYourFinancialsModal = () => {
    navigation.navigate(Screen.CONDITIONAL_APPROVAL_MODAL, {
      screen: Screen.CONDITIONAL_APPROVAL_VERIFY_YOUR_FINANCIALS,
      params: {
        loanApplicationId: loanApplicationId ?? '',
      },
    });
  };

  if (!loanApplicationId) {
    // Shouldn't this case be handled by `validateLoanApplicationForScreen` too?
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <EmptyStateComponent
          title={t('Content.Common.Error.NoAssociatedLoanApplication')}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication:
      loanApplicationId && loanApplicationType
        ? {
            id: loanApplicationId,
            type: loanApplicationType,
          }
        : undefined,
  });

  if (
    !loadingLoanApplication &&
    loanValidationResult !== LoanValidationResult.Valid
  ) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const {
    isPending: caEvaluationPending,
    isError: caEvaluationError,
    isNeedRecalculation: caEvaluationNeedRecalculation,
  } = evaluateCAEvaluationState({
    caEvaluationStateData: getCAEvaluationStateRes,
  });

  const shouldShowLoadingBasedOnEvaluationState =
    loadingConditionalApprovalEvaluationState ||
    caEvaluationPending ||
    caEvaluationNeedRecalculation;

  const shouldShowLoadingState =
    shouldShowLoadingBasedOnEvaluationState ||
    loadingSetConditionalApproval ||
    loadingLoanApplication ||
    loadingFetchingConditionalApprovalData ||
    loadingResyncXai;

  if (shouldShowLoadingState) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoadingState description={t('Content.Common.Placeholder.Loading')} />
      </LoanApplicationScreenContainer>
    );
  }

  if (errorResyncXai || caEvaluationError) {
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <ScreenErrorFallback
          // This should not create error object inline.
          // Either we force the screen to display the refetch
          // or use different component.
          // Specifying dummy error object here will
          // create sentry breadcrumb needlessly.
          error={new ApolloError({})}
          displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
          refetch={triggerResyncXai}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const {
    conditionalApprovalStatus = null,
    pending_applicants: pendingApplicants,
    conditionallyApprovedAmount,
    isCcrExpired,
  } = data?.get_conditional_approval_data ?? {};

  if (
    conditionalApprovalStatus ===
    Conditional_Approval_Status_Output_Enum.Declined
  ) {
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <DeclinedConditionalApproval
          onYourApplicationPress={navigateToApplicationDetailsScreen}
          imageHeight={ConditionalApprovalIllustrationHeight}
        />
      </LoanApplicationScreenContainer>
    );
  }

  if (
    conditionalApprovalStatus ===
      Conditional_Approval_Status_Output_Enum.ConductRulesFailedDeclined ||
    conditionalApprovalStatus ===
      Conditional_Approval_Status_Output_Enum.ConductRulesNotRunDeclined
  ) {
    return (
      <LoanApplicationScreenContainer bg="bg">
        <NavHeaderSpacer />
        <DeclinedByConductRules
          imageHeight={ConditionalApprovalIllustrationHeight}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const {
    title,
    caption,
    footerCaption,
    captionLinkText,
    // What's caption link?
    // There are many "captions" in this screen.
    // Saying caption link is ambiguous.
    showCaptionLink,
    disablePrimaryButton,
    onLinkPress,
  } = getScreenPropsBasedOnConditionalApprovalStatus({
    conditionalApprovalStatus,
    otherApplicantName: pendingApplicants?.[0]?.applicantFullName,
    navigateToYourBorrowingPowerInformationScreen,
    navigateToYourConditionalApprovalInformationScreen,
    isLmiEnabled: loanApplication?.is_lmi_enabled,
  });

  const showContentWithVca =
    /**
     * These logic might look weird at glance.
     * - Pending VCA or VCA stage requires CA to be approved.
     * - We have new design for CA approved.
     * - Pending VCA and VCA is also new design.
     * - All new these new design must be behind FF.
     * The simplified logic would be:
     * - If FF is enabled and CA is approved, show new design.
     * - Else, use existing design (this includes CA declined etc.)
     */
    enableVca &&
    conditionalApprovalStatus ===
      Conditional_Approval_Status_Output_Enum.Approved;

  return (
    <LoanApplicationScreenContainer bg="bg">
      <NavHeaderSpacer />
      <ScreenErrorFallback
        error={
          errorSetConditionalApproval || errorFetchingConditionalApprovalData
        }
        displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
        refetch={triggerRefetchQueriesBasedOnError}
      >
        {showContentWithVca ? (
          <ConditionallyApprovedContentWithVca
            loanApplicationId={loanApplicationId}
            conditionallyApprovedAmount={conditionallyApprovedAmount}
            showCaptionLink={showCaptionLink}
            isCcrExpired={isCcrExpired}
            onAppDetailsPress={navigateToApplicationDetailsScreen}
            onLinkPress={onLinkPress}
            onFoundYourHomePress={continueToNextScreen}
            onVerifyFinancialsPress={navigateToVerifyYourFinancialsModal}
          />
        ) : (
          <>
            <LoanScreenHeader
              title={title}
              caption={t('Content.ConditionalApproval.Header.Caption')}
              learnMoreText={t(
                'Content.ConditionalApproval.Header.ViewApplication',
              )}
              learnMoreTestId={
                TestID.LoanApplicationConditionalApproval.ViewApplication
              }
              onLearnMorePress={navigateToApplicationDetailsScreen}
              infoText={
                isCcrExpired
                  ? t(
                      'Content.ConditionalApproval.Header.CcrExpiryNotification',
                    )
                  : undefined
              }
            />

            <View sx={{ mx: '$16' }}>
              <Text
                sx={{
                  fontSize: '$48',
                  lineHeight: 58,
                  fontWeight: '600',
                  textAlign: 'center',
                  mt: '$32',
                }}
                testID={
                  TestID.LoanApplicationConditionalApproval
                    .ConditionallyApprovedAmount
                }
              >
                {formatCurrency(conditionallyApprovedAmount)}
              </Text>
              <StyledText
                variant="caption"
                color="secondaryContent"
                textAlign="center"
                my="s"
              >
                {caption}
                {showCaptionLink ? (
                  <StyledText
                    variant="caption"
                    color="link"
                    onPress={onLinkPress}
                  >
                    {' '}
                    {captionLinkText}
                  </StyledText>
                ) : null}
              </StyledText>
            </View>

            <Image
              source={require('../../../assets/hero-image-652x421.png')}
              sx={{
                width: '100%',
                height: ConditionalApprovalIllustrationHeight,
              }}
              resizeMode="contain"
              testID={TestID.LoanApplicationConditionalApproval.Illustration}
            />
            <LoanApplicationWizardFooter
              mt={0}
              footerCaption={footerCaption}
              primaryButtonLabel={t(
                'Content.ConditionalApproval.ButtonLabel.CompleteApplication',
              )}
              onPrimaryButtonPress={continueToNextScreen}
              primaryButtonTestID={
                TestID.LoanApplicationConditionalApproval.ContinueButton
              }
              disablePrimaryButton={disablePrimaryButton}
              secondaryButtonLabel={t(
                'Content.Common.ButtonLabel.BackToDashboard',
              )}
              secondaryButtonTestID={
                TestID.LoanApplicationConditionalApproval.BackToDashboardButton
              }
              onSecondaryButtonPress={() =>
                navigation.navigate(Screen.MAIN_NAVIGATOR, {
                  screen: Screen.HOME_DASHBOARD,
                  params: { screen: Screen.HOME },
                })
              }
            />
          </>
        )}
      </ScreenErrorFallback>
    </LoanApplicationScreenContainer>
  );
}

export const LoanApplicationConditionalApproval = withAuthenticationRequired(
  LoanApplicationConditionalApprovalBase,
);
