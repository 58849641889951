import { env, ENVIRONMENT, useAuth0Sandbox } from './environment';

// It's safe to store googleMapsApiKey in the repository because google has all the restrictions from setup
// i.e the key is only available for specific bundle ids etc.
export const EXPO_CONFIG = {
  PROD: {
    name: 'Unloan',
    slug: 'unloan-app',
    scheme: 'Unloan',
    bundleIdentifier: 'au.com.unloan',
    projectId: 'c0a69893-0a59-4cf4-a2a1-d5d08ef6519c',
    updateUrl: 'https://u.expo.dev/c0a69893-0a59-4cf4-a2a1-d5d08ef6519c',
    android: {
      googleMapsApiKey: 'AIzaSyAvvYcA3vl9lhIisYQVLdP8iLjN9w2Va1U',
    },
    ios: {
      googleMapsApiKey: 'AIzaSyCxWv5mD2D8Jt03Md0zeV9KRmBNC__1DAQ',
    },
  },
  STAGE: {
    name: 'UnloanStg',
    slug: 'unloan-stg',
    scheme: 'UnloanStg',
    bundleIdentifier: 'au.com.unloan.stg',
    projectId: '9726a9b2-bf14-4846-8674-53426aaf358e',
    updateUrl: 'https://u.expo.dev/9726a9b2-bf14-4846-8674-53426aaf358e',
    android: {
      googleMapsApiKey: 'AIzaSyAgBo3a-aMooVnLy8mTJGfGnh24gV38vZU',
    },
    ios: {
      googleMapsApiKey: 'AIzaSyCbnfldjd7UxefwgJJBGkD2v1l78MMLQ6A',
    },
  },
  TEST: {
    name: 'UnloanTst',
    slug: 'unloan-tst',
    scheme: 'UnloanTst',
    bundleIdentifier: 'au.com.unloan.tst',
    projectId: 'ebdb7340-a961-4d17-85dc-7e3b637640f8',
    updateUrl: 'https://u.expo.dev/ebdb7340-a961-4d17-85dc-7e3b637640f8',
    android: {
      googleMapsApiKey: 'AIzaSyAgBo3a-aMooVnLy8mTJGfGnh24gV38vZU',
    },
    ios: {
      googleMapsApiKey: 'AIzaSyCbnfldjd7UxefwgJJBGkD2v1l78MMLQ6A',
    },
  },
  DEV: {
    name: 'UnloanDev',
    slug: 'unloan-dev',
    scheme: 'UnloanDev',
    bundleIdentifier: 'au.com.unloan.dev',
    projectId: 'd38d7ab6-d0ab-4b4d-af1f-b06de6ba0c04',
    updateUrl: 'https://u.expo.dev/d38d7ab6-d0ab-4b4d-af1f-b06de6ba0c04',
    android: {
      googleMapsApiKey: 'AIzaSyAgBo3a-aMooVnLy8mTJGfGnh24gV38vZU',
    },
    ios: {
      googleMapsApiKey: 'AIzaSyCbnfldjd7UxefwgJJBGkD2v1l78MMLQ6A',
    },
  },
  SANDBOX: {
    name: 'UnloanDev',
    slug: 'unloan-dev',
    scheme: 'UnloanDev',
    bundleIdentifier: 'au.com.unloan.dev',
    projectId: 'd38d7ab6-d0ab-4b4d-af1f-b06de6ba0c04',
    updateUrl: 'https://u.expo.dev/d38d7ab6-d0ab-4b4d-af1f-b06de6ba0c04',
    android: {
      googleMapsApiKey: 'AIzaSyAgBo3a-aMooVnLy8mTJGfGnh24gV38vZU',
    },
    ios: {
      googleMapsApiKey: 'AIzaSyCbnfldjd7UxefwgJJBGkD2v1l78MMLQ6A',
    },
  },
};

export const ENV_EXPO_CONFIG: Record<ENVIRONMENT, typeof EXPO_CONFIG.PROD> = {
  [ENVIRONMENT.PROD_V3]: EXPO_CONFIG.PROD,
  [ENVIRONMENT.STAGING_V3]: EXPO_CONFIG.STAGE,
  [ENVIRONMENT.TEST_V3]: EXPO_CONFIG.TEST,
  [ENVIRONMENT.DEV_V3]: EXPO_CONFIG.DEV,
  [ENVIRONMENT.LOCAL_DEV]: useAuth0Sandbox
    ? EXPO_CONFIG.SANDBOX
    : EXPO_CONFIG.DEV,
};

export const expoConfig = ENV_EXPO_CONFIG[env];

const platform = (process.env.EXPO_PUBLIC_PLATFORM ?? 'ios') as
  | 'ios'
  | 'android';
export const googleMapsApiKey =
  ENV_EXPO_CONFIG[env]?.[platform]?.googleMapsApiKey;
