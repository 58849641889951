import { useNavigation } from '@react-navigation/native';
import { Fragment } from 'react';

import { TestID } from '../../../testID/constants';
import { PaymentRequestFragment } from '../../generated/graphql';
import { useLoanAccountWithTransactions } from '../../HomeLoan/graphql/loanAccountQueries';
import { Screen } from '../../navigation/types/screens';
import { TransactionRow, TransactionsCard } from '../../ui/v2/TransactionsCard';
import { TransferRowSeparator } from '../../ui/v2/UpcomingCard';
import { formatCurrency } from '../../utils/currencyHelpers';

type ProcessingPendingWithdrawalRowProps = {
  onPress?: () => void;
  amount: number | null | undefined;
  accountName?: string;
  testID?: string;
};

export const ProcessingPendingWithdrawalRow = ({
  amount,
  onPress,
  accountName,
  testID,
}: ProcessingPendingWithdrawalRowProps) => {
  const label = t('Content.TransactionList.Withdrawal');
  const amountLabel = `-${formatCurrency(Math.abs(amount ?? 0), {
    withFractionOnRoundedAmount: true,
  })}`;

  return (
    <TransactionRow
      testID={testID}
      label={label}
      amountLabel={amountLabel}
      caption={t('Content.Approvals.Processing')}
      subCaption={accountName}
      onPress={onPress}
    />
  );
};

type PendingWithdrawalsProps = {
  showAccountName?: boolean;
  loan?: ReturnType<typeof useLoanAccountWithTransactions>['data'];
  pending_withdrawals: Array<PaymentRequestFragment>;
};

export function ProcessingPendingWithdrawals({
  showAccountName,
  loan,
  pending_withdrawals,
}: PendingWithdrawalsProps) {
  const navigation = useNavigation();

  const propertyShortAddress =
    loan?.loan_application_security?.property?.address?.short_address_format;

  const accountName = showAccountName
    ? propertyShortAddress || loan?.settings?.name
    : undefined;

  const onPressPendingWithdrawal = (paymentRequestId: string) =>
    navigation.navigate(Screen.HOME_LOAN_MODAL, {
      screen: Screen.HOME_PENDING_TRANSACTION,
      params: {
        paymentRequestId,
      },
    });

  return (
    <TransactionsCard>
      {(pending_withdrawals ?? []).map((item, index) => (
        <Fragment key={item.id}>
          {index > 0 ? <TransferRowSeparator /> : null}
          <ProcessingPendingWithdrawalRow
            key={item.id}
            testID={TestID.HomeLoan.PendingWithdrawalsList}
            amount={item.withdrawal_request?.amount}
            onPress={() => onPressPendingWithdrawal(item.id)}
            accountName={accountName}
          />
        </Fragment>
      ))}
    </TransactionsCard>
  );
}
