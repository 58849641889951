import { SxProp, View } from 'dripsy';

type DividerProps = {
  sx?: SxProp;
};

export const Divider = ({ sx = {} }: DividerProps) => (
  <View
    sx={{
      borderTopColor: '$border',
      borderTopWidth: 0.5,
      marginX: '$16',
      ...sx,
    }}
  />
);
