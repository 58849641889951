import { useCallback } from 'react';
import { Platform } from 'react-native';

function usePrintIFrame(id: string) {
  return useCallback(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const iframe = document.getElementById(id) as Partial<HTMLIFrameElement>;

    if (iframe != null) {
      const iframeWindow = iframe?.contentWindow;

      if (iframeWindow?.print && iframe?.focus) {
        iframe.focus();
        iframeWindow.print();
      }
    }
  }, [id]);
}

export default usePrintIFrame;
