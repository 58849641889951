import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  ApplicationDetailsFragments,
  HOUSEHOLD_FOR_SUMMARY_FRAGMENT,
} from '../ApplicationSummary/remoteData';
import { sortHouseholdExpensesFields } from '../ApplicationSummary/utils/remoteDataHelpers';
import {
  formatLiabilityLabel,
  formatLiabilitySublabels,
  formatOwners,
} from '../components/utils/formatLiabilityRow';
import { useFullNameByApplicantIds } from '../DebtsWizard/utils/useAllLiabilitiesQuery';
import {
  ApplicantForApplicationSummaryFragment,
  EmploymentIncomeFragment,
  ExpectedRentalIncomeFragment,
  Frequency_Enum,
  HouseholdForSummaryFragment,
  Income_Type_Enum,
  Liability_Type_Enum,
  Living_Situation_Enum,
  Loan_Application_Type_Enum,
  MergedLiabilityForApplicationSummaryFragment,
  useConditionalApprovalGetReviewLoanApplicationQuery,
} from '../generated/graphql';
import {
  EMPLOYMENT_INCOME_FRAGMENT,
  EXPECTED_RENTAL_INCOME_FRAGMENT,
} from '../LoanApplication/graphql/fragments';
import { removeNullish } from '../utils/arrayHelpers';
import { parseEnumType } from '../utils/ensureEnumType';
import { isTopUpHomeLoan } from '../utils/isTopUpHomeLoan';

export const ConditionalApprovalGetReviewLoanApplication = gql`
  query ConditionalApprovalGetReviewLoanApplication($loanApplicationId: uuid!) {
    loan_application_by_pk(id: $loanApplicationId) {
      id

      status_info {
        id
        can_user_edit
        loan_application_stage
      }

      applicants {
        ...ApplicantForApplicationSummary
      }
      type
      incomes {
        id
        amount
        frequency
        income_type
        income_owners {
          id
          applicant_id
        }

        employment_income {
          ...EmploymentIncome
        }
        rental_income {
          ...ExpectedRentalIncome
        }
      }
    }

    get_household_expenses_fields(loan_application_id: $loanApplicationId) {
      household_id
      household_expenses_order
      household {
        ...HouseholdForSummary
      }
    }

    mergedLiabilities: merged_liability(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...MergedLiabilityForApplicationSummary
    }
  }
  ${EMPLOYMENT_INCOME_FRAGMENT}
  ${EXPECTED_RENTAL_INCOME_FRAGMENT}
  ${HOUSEHOLD_FOR_SUMMARY_FRAGMENT}
  ${ApplicationDetailsFragments}
`;

export type ApplicationDetailsSectionDataProps = {
  loanApplicationId: string;
  loanApplicationType: Loan_Application_Type_Enum | null;
  isAllApplicantsHasHousehold: boolean;
  borrowers: Array<ApplicantForApplicationSummaryFragment>;
  financials: {
    incomes: Array<{
      id: string;
      amount: number;
      frequency: Frequency_Enum;
      incomeType: Income_Type_Enum;
      formattedIncomeOwnerNames: string;
      employmentIncome?: EmploymentIncomeFragment | null;
      rentalIncome?: ExpectedRentalIncomeFragment | null;
    }>;
    households: Array<{
      id: string;
      livingSituation?: Living_Situation_Enum;
      householdExpensesMonthlyAmount?: number | null;
      formattedHouseholdMemberNames: string;
      householdExpenseDetails?: HouseholdForSummaryFragment['unordered_household_expenses'];
    }>;
  };
  debtsAndLiabilities: Array<{
    id: MergedLiabilityForApplicationSummaryFragment['id'];
    currentLiabilityId: MergedLiabilityForApplicationSummaryFragment['current_liability_id'];
    label: string;
    type: Liability_Type_Enum;
    caption: string;
    owners: string;
    detectedLiabilityIdentifier: MergedLiabilityForApplicationSummaryFragment['detected_liability_identifier'];
    liabilityManuallyAdded: MergedLiabilityForApplicationSummaryFragment['manually_added'];
    isTopUpHomeLoanLiability: boolean;
  }>;
};

export function useConditionalApprovalApplicationDetailsQuery({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const { data, loading, error, networkStatus, ...others } =
    useConditionalApprovalGetReviewLoanApplicationQuery({
      variables: { loanApplicationId: loanApplicationId ?? '' },
      skip: !loanApplicationId,
      context: {
        sentryContext: { loanApplicationId },
      },
    });

  const fullNameByApplicantIds = useFullNameByApplicantIds(
    data?.loan_application_by_pk?.applicants,
  );

  const normalizedLoanSummaryData = useMemo(() => {
    const {
      type: loanType,
      applicants,
      incomes,
    } = data?.loan_application_by_pk || {};

    const householdExpenses = data?.get_household_expenses_fields;

    return {
      loanType,
      applicants,
      incomes,
      householdExpenses,
    };
  }, [data?.get_household_expenses_fields, data?.loan_application_by_pk]);

  const sectionData: ApplicationDetailsSectionDataProps = useMemo(() => {
    const rawMergedLiabilities = data?.mergedLiabilities ?? [];
    const mergedLiabilities = rawMergedLiabilities.filter(
      (ml) => ml.dynamite_flagged_incorrect == null,
    );

    const { loanType, applicants, incomes, householdExpenses } =
      normalizedLoanSummaryData ?? {};

    const parsedLoanType = parseEnumType(Loan_Application_Type_Enum, loanType);

    // #region Sanitized Values
    const sanitizedBorrowers: ApplicationDetailsSectionDataProps['borrowers'] =
      removeNullish(applicants || []);
    const sanitizedIncomes: ApplicationDetailsSectionDataProps['financials']['incomes'] =
      incomes?.map(
        ({
          employment_income: employmentIncome,
          rental_income: rentalIncome,
          id,
          amount,
          frequency,
          income_type: incomeType,
          income_owners,
        }) => ({
          id,
          amount,
          frequency,
          incomeType,
          formattedIncomeOwnerNames: formatOwners({
            fullNameByApplicantIds,
            applicantIds: income_owners.map((owner) => owner.applicant_id),
          }),
          employmentIncome,
          rentalIncome,
        }),
      ) || [];
    const sanitizedHouseholds: ApplicationDetailsSectionDataProps['financials']['households'] =
      householdExpenses?.map(
        ({ household_id, household, household_expenses_order }) => ({
          id: household_id,
          householdExpensesMonthlyAmount:
            household[0]?.household_expenses_aggregate.aggregate?.sum
              ?.monthly_amount,
          formattedHouseholdMemberNames: formatOwners({
            fullNameByApplicantIds,
            applicantIds: household[0]?.applicants.map(
              (applicant) => applicant.id,
            ),
          }),
          householdExpenseDetails: sortHouseholdExpensesFields({
            expensesOrder: household_expenses_order,
            unorderedHouseholdExpenses:
              household[0]?.unordered_household_expenses || [],
          }),
          livingSituation: household[0]?.living_situation,
        }),
      ) || [];
    const sanitizedLiabilities: ApplicationDetailsSectionDataProps['debtsAndLiabilities'] =
      mergedLiabilities.map((ml) => {
        const liabilityType =
          parseEnumType(Liability_Type_Enum, ml.dynamite_liability_type) ||
          Liability_Type_Enum.Other;

        return {
          id: ml.id,
          currentLiabilityId: ml.current_liability_id,
          label: formatLiabilityLabel({
            institutionName: ml.dynamite_institution_name,
            liabilityType,
          }),
          type: liabilityType,
          caption: formatLiabilitySublabels({
            liabilityType,
            balance: ml.dynamite_balance,
            limit: ml.dynamite_limit,
          }).join('\n'),
          owners: formatOwners({
            fullNameByApplicantIds,
            applicantIds: ml.dynamite_applicant_ids,
          }),
          detectedLiabilityIdentifier: ml.detected_liability_identifier,
          liabilityManuallyAdded: ml.manually_added,
          isTopUpHomeLoanLiability: isTopUpHomeLoan({
            loanApplicationType: loanType,
            liabilityType,
            forRefinancing: ml.dynamite_for_refinancing,
          }),
        };
      });
    // #endregion

    return {
      loanApplicationId: loanApplicationId ?? '',
      loanApplicationType: parsedLoanType,
      isAllApplicantsHasHousehold: sanitizedBorrowers.every(
        (borrower) => borrower.household_id != null,
      ),
      borrowers: sanitizedBorrowers,
      financials: {
        incomes: sanitizedIncomes,
        households: sanitizedHouseholds,
      },
      debtsAndLiabilities: sanitizedLiabilities,
    };
  }, [
    data?.mergedLiabilities,
    normalizedLoanSummaryData,
    loanApplicationId,
    fullNameByApplicantIds,
  ]);

  return { sectionData, data, loading, error, ...others };
}
