import { gql } from '@apollo/client';
import { useMemo } from 'react';

import { useGetJoinApplicationLoanDetailsLazyQuery } from '../generated/graphql';
import { buildAuthHeader } from '../utils/apollo';

export const JOIN_APPLICATION_DETAILS_QUERY = gql`
  query GetJoinApplicationLoanDetails {
    applicant_invite {
      invite_code
      is_revoked
      is_used
      is_expired
      applicant {
        id
        role
        latest_full_name
        loan_application {
          id
          is_lmi_enabled
          primary_applicant: applicants(
            where: { role: { _eq: PRIMARY_BORROWER } }
          ) {
            id
            role
            latest_full_name
          }
        }
      }
    }
  }
`;

export function useJoinApplicationDetailsQuery(token: string) {
  const [getLoanApplicationDetails, { data, loading, error }] =
    useGetJoinApplicationLoanDetailsLazyQuery({
      context: {
        headers: buildAuthHeader(token),
        sentryContext: {
          description: 'Error occured when querying for invite',
        },
      },
    });

  // There will always be 1 applicant assigned to the unique invite code
  const applicantInvite = data?.applicant_invite?.[0];

  const loanDetails = useMemo(() => {
    const currentApplicant = applicantInvite?.applicant;
    if (currentApplicant == null) {
      return undefined;
    }
    const { primary_applicant: primaryBorrower } =
      currentApplicant.loan_application;

    return {
      invitee: currentApplicant?.latest_full_name || '',
      inviter: primaryBorrower?.[0]?.latest_full_name || '',
      inviteCode: applicantInvite?.invite_code,
      isRevoked: applicantInvite?.is_revoked,
      isExpired: applicantInvite?.is_expired,
      isUsed: applicantInvite?.is_used,
      isLmiEnabled: currentApplicant.loan_application.is_lmi_enabled,
    };
  }, [applicantInvite]);

  return { getLoanApplicationDetails, data: loanDetails, loading, error };
}
