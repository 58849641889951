import { Text } from 'dripsy';
import { Fragment } from 'react';

import { TestID } from '../../../testID/constants';
import { Account_Permission_Type_Enum } from '../../generated/graphql';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { assertUnreachable } from '../../utils/typesHelpers';
import { AccountPermission } from '../types';

type Props = {
  loading?: boolean;
  permissions: AccountPermission[];
};

const TextPrefix = 'Content.AccountPermissions.ApprovalScreen.Permissions';
const getPermissionType = (type: Account_Permission_Type_Enum) => {
  switch (type) {
    case Account_Permission_Type_Enum.Autopay:
      return t(`${TextPrefix}.AutoPay.Title`);
    case Account_Permission_Type_Enum.Withdrawal:
      return t(`${TextPrefix}.Withdrawal.Title`);
    default:
      assertUnreachable(type);
      return '';
  }
};

const getPermissionSubText = (
  type: Account_Permission_Type_Enum,
  requireApprovals: boolean,
) => {
  switch (type) {
    case Account_Permission_Type_Enum.Autopay:
      return requireApprovals
        ? t(`${TextPrefix}.AutoPay.AllToApprove`)
        : t(`${TextPrefix}.AutoPay.OneToApprove`);
    case Account_Permission_Type_Enum.Withdrawal:
      return requireApprovals
        ? t(`${TextPrefix}.Withdrawal.AllToApprove`)
        : t(`${TextPrefix}.Withdrawal.OneToApprove`);
    default:
      assertUnreachable(type);
      return '';
  }
};

export const ReviewPermissions = ({ loading, permissions }: Props) => (
  <DataRowGroup
    testID={TestID.AccountPermissions.ApprovalScreen.ReviewPermissions}
    mt={0}
  >
    {permissions.map((permission, idx) => (
      <Fragment key={permission.type}>
        {idx !== 0 ? <DataRowSeparator /> : null}
        <DataRow
          loading={loading}
          label={
            <Text variant="xsHeader">{getPermissionType(permission.type)}</Text>
          }
          subCaption={getPermissionSubText(
            permission.type,
            permission.require_approvals,
          )}
        />
      </Fragment>
    ))}
  </DataRowGroup>
);
