import { useCallback } from 'react';
import * as React from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Button, Props as ButtonProps } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';
import { getAmountPickerValue } from '../utils/getAmountPickerValue';
import { CurrencyInput, Props as CurrencyInputProps } from './CurrencyInput';

export type Props = CurrencyInputProps & {
  caption?: string;
  minimumValue?: number;
  maximumValue?: number;
};

export const AmountPicker = React.forwardRef<RNTextInput, Props>(
  (
    {
      minimumValue = 0,
      caption,
      value,
      onChangeText,
      maximumValue = Number.MAX_SAFE_INTEGER,
      ...props
    },
    ref,
  ) => {
    const valueNum = Number(value ?? 0);
    const onAddButtonPress = useCallback(
      () =>
        onChangeText?.(
          getAmountPickerValue(valueNum, 'add', minimumValue, maximumValue),
        ),
      [onChangeText, valueNum, minimumValue, maximumValue],
    );
    const onSubstractButtonPress = useCallback(
      () =>
        onChangeText?.(
          getAmountPickerValue(
            valueNum,
            'subtract',
            minimumValue,
            maximumValue,
          ),
        ),
      [onChangeText, valueNum, minimumValue, maximumValue],
    );

    const onChangeTextWrapped = useCallback(
      (typedValue: number | null) => {
        onChangeText?.(Math.min(maximumValue, typedValue || 0));
      },
      [onChangeText, maximumValue],
    );

    const onBlur = useCallback(() => {
      onChangeText?.(Math.max(minimumValue, value ?? 0));
    }, [onChangeText, minimumValue, value]);

    return (
      <Box pt="s">
        <Box row alignItems="center" justifyContent="space-between">
          <Box p="m">
            <AmountPickerButton
              icon="remove"
              onPress={onSubstractButtonPress}
              disabled={
                !valueNum || (minimumValue ? valueNum <= minimumValue : false)
              }
              testID={TestID.AmountPicker.SubstractButton}
            />
          </Box>
          <CurrencyInput
            {...props}
            ref={ref}
            color="primaryContent"
            fontWeight="medium"
            fontSize="2xl"
            lineHeight="2xl"
            alignSelf="stretch"
            returnKeyType="done"
            placeholder="$0"
            textAlign="center"
            value={value}
            onChangeText={onChangeTextWrapped}
            onBlur={onBlur}
          />
          <Box p="m">
            <AmountPickerButton
              icon="add"
              onPress={onAddButtonPress}
              disabled={maximumValue ? valueNum >= maximumValue : false}
              testID={TestID.AmountPicker.AddButton}
            />
          </Box>
        </Box>
        {caption ? (
          <StyledText variant="caption" flex={1} textAlign="center" mb="m">
            {caption}
          </StyledText>
        ) : null}
      </Box>
    );
  },
);

const AmountPickerButton: React.FC<
  Pick<ButtonProps, 'icon' | 'onPress' | 'disabled' | 'testID'>
> = ({ icon, onPress, disabled, testID }) => {
  const theme = useTheme();
  return (
    <Button
      variant="circle"
      icon={icon}
      width={theme.sizes.m}
      height={theme.sizes.m}
      alignSelf="center"
      style={{ paddingLeft: 2, paddingBottom: 1 }}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
    />
  );
};
