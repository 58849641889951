import { Text, useDripsyTheme, View } from 'dripsy';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  Approval_Status_Enum,
  useGetAccountPermissionApprovalScreenDataQuery,
} from '../../generated/graphql';
import { HomeTabScreenProps } from '../../Home/navigation/types';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { ApprovalActions } from '../components/ApprovalActions';
import { ApprovalResponses } from '../components/ApprovalResponses';
import { ReviewPermissions } from '../components/ReviewPermissions';
import {
  AccountPermission,
  ApprovalResponseType,
  ChangeRequestResponse,
} from '../types';
import {
  AccountPermissionsApprovalScreenErrorAtomFamily,
  LeaveAccountPermissionsApprovalScreenAtomFamily,
} from '../utils/atoms';

type Props = HomeTabScreenProps<Screen.ACCOUNT_PERMISSIONS_APPROVAL_SCREEN>;

type ContentProps = {
  changeRequestResponses: ChangeRequestResponse[];
  loading?: boolean;
  hasErrored?: boolean;
  isRequestor?: boolean;
  onPressBack: () => void;
  permissions: AccountPermission[];
  onCancelRequest: () => void;
  onApproveRequest: () => void;
  onDeclineRequest: () => void;
};

const ScreenHeader = ({
  errorMessage,
  subHeadingText,
}: {
  errorMessage?: string;
  subHeadingText?: string;
}) => {
  const { theme } = useDripsyTheme();
  return (
    <View sx={{ pb: '$16' }}>
      <Text variant="header" sx={{ mt: '$8', py: '$8' }}>
        {t('Content.AccountPermissions.ApprovalScreen.Title')}
      </Text>
      {errorMessage ? (
        <View sx={{ mt: '$16', mb: '$8' }}>
          <ErrorRow message={errorMessage} />
        </View>
      ) : null}
      {subHeadingText ? (
        <Text variant="subHeading" sx={{ py: '$8' }}>
          {subHeadingText}
        </Text>
      ) : (
        <Skeleton
          width="100%"
          height={theme.text.subHeading.lineHeight}
          show={!subHeadingText}
        />
      )}
    </View>
  );
};

export const ApprovalScreenContent = ({
  changeRequestResponses,
  loading = false,
  hasErrored = false,
  isRequestor = false,
  onPressBack,
  permissions,
  onCancelRequest,
  onApproveRequest,
  onDeclineRequest,
}: ContentProps) => {
  const subHeadingText: string | undefined = useMemo(() => {
    if (loading) return undefined;
    return isRequestor
      ? t('Content.AccountPermissions.ApprovalScreen.Subtitle.Requestor')
      : t('Content.AccountPermissions.ApprovalScreen.Subtitle.Approver');
  }, [loading, isRequestor]);

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <ScreenHeader
        errorMessage={
          hasErrored ? t('Content.AccountPermissions.PageError') : undefined
        }
        subHeadingText={subHeadingText}
      />
      <Text variant="sHeader" sx={{ py: '$8' }}>
        {t(
          'Content.AccountPermissions.ApprovalScreen.UpdatedAccountPermissions',
        )}
      </Text>
      <ReviewPermissions loading={loading} permissions={permissions} />
      <ApprovalResponses loading={loading} responses={changeRequestResponses} />
      <ApprovalActions
        loading={loading}
        isRequestor={!!isRequestor}
        onCancelRequest={onCancelRequest}
        onApproveRequest={onApproveRequest}
        onDeclineRequest={onDeclineRequest}
      />
    </UpliftScreenContainer>
  );
};

// This is the screen component
export const AccountPermissionsApprovalScreen = ({
  navigation,
  route,
}: Props) => {
  const { cbaAccountId } = route.params;

  const { flags } = useContext(FeatureFlagsContext);

  const LeavePageAtom =
    LeaveAccountPermissionsApprovalScreenAtomFamily(cbaAccountId);
  const shouldGoBack = useRecoilValue(LeavePageAtom);
  const resetShouldGoBack = useResetRecoilState(LeavePageAtom);

  const ShowErrorAtom =
    AccountPermissionsApprovalScreenErrorAtomFamily(cbaAccountId);
  const hasMutationFailed = useRecoilValue(ShowErrorAtom);
  const resetHasMutationFailed = useResetRecoilState(ShowErrorAtom);

  const { data, loading, error, called } =
    useGetAccountPermissionApprovalScreenDataQuery({
      fetchPolicy: 'network-only',
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Account Permissions Approval Screen Data',
        },
      },
      variables: {
        cbaAccountId,
      },
    });

  const isFeatureEnabled = !!flags[FeatureFlag.EnableAccountPermissions];

  const approvalRequest = data?.approval_request[0];
  const approvalRequestId = approvalRequest?.id;
  const userId = data?.me[0]?.user?.id;
  const requestorUserId = approvalRequest?.requested_by?.user_id;
  const isRequestor = userId === requestorUserId;
  const newPermissions = useMemo(
    () =>
      approvalRequest?.account_permission_change_requests?.map(
        (changeRequest) => ({
          type: changeRequest.permission_type,
          require_approvals: changeRequest.require_approvals,
        }),
      ) ?? [],
    [approvalRequest],
  );
  const responses =
    approvalRequest?.responses?.map((response) => {
      const res: ChangeRequestResponse = {
        id: response.id,
        name: response.identity_profile.full_name ?? '--',
        hasApproved: response.status === Approval_Status_Enum.Approved,
      };
      return res;
    }) ?? [];

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const navigateToApprovalActionSheet = useCallback(
    (responseType: ApprovalResponseType) => {
      if (approvalRequestId) {
        resetHasMutationFailed();
        navigation.navigate(ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL, {
          approvalRequestId,
          cbaAccountId,
          responseType,
          permissions: newPermissions,
        });
      }
    },
    [
      approvalRequestId,
      cbaAccountId,
      newPermissions,
      navigation,
      resetHasMutationFailed,
    ],
  );

  useEffect(() => {
    if (shouldGoBack || (!loading && called && !approvalRequest)) {
      resetShouldGoBack();
      resetHasMutationFailed();
      navigation.goBack();
    }
  }, [
    approvalRequest,
    loading,
    called,
    shouldGoBack,
    resetShouldGoBack,
    navigation,
    resetHasMutationFailed,
  ]);

  if (!loading && !isFeatureEnabled) {
    return <NotFoundScreen />;
  }

  return (
    <ApprovalScreenContent
      isRequestor={isRequestor}
      loading={loading}
      hasErrored={!!error || !!hasMutationFailed}
      onPressBack={onPressBack}
      onCancelRequest={() =>
        navigateToApprovalActionSheet(ApprovalResponseType.Cancel)
      }
      onApproveRequest={() =>
        navigateToApprovalActionSheet(ApprovalResponseType.Approve)
      }
      onDeclineRequest={() =>
        navigateToApprovalActionSheet(ApprovalResponseType.Decline)
      }
      permissions={newPermissions}
      changeRequestResponses={responses}
    />
  );
};
