import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const UserProfileFilledIcon = ({
  color,
  size,
  ...props
}: SvgIconProps) => {
  const theme = useTheme();
  const fill = color ?? theme.colors.buttonPrimaryBg;
  const width = resizeIconWidth(20, 24, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 20 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78259 6.58394C4.78259 9.95883 7.07605 12.4704 9.99224 12.5018C12.9236 12.5332 15.2019 9.95883 15.2019 6.58394C15.2019 3.41312 12.9084 0.776001 9.99224 0.776001C7.09124 0.776001 4.7674 3.41312 4.78259 6.58394ZM0.666504 19.4085C2.99034 21.9671 6.51408 23.4427 9.99224 23.4427C13.4856 23.4427 16.9941 21.9671 19.3332 19.4085C17.6776 16.7086 14.0779 15.1703 9.99224 15.1703C5.87616 15.1703 2.30686 16.74 0.666504 19.4085Z"
        fill={fill}
      />
    </Svg>
  );
};
