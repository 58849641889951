import { createContext, useContext } from 'react';

export type ListRowGroupInsetContextValue = boolean | undefined;

export const ListRowGroupInsetContext =
  createContext<ListRowGroupInsetContextValue>(undefined);

export const useListRowGroupInset = (): ListRowGroupInsetContextValue => {
  const listRowGroupInset = useContext<ListRowGroupInsetContextValue>(
    ListRowGroupInsetContext,
  );
  if (!listRowGroupInset) {
    return undefined;
  }
  return listRowGroupInset;
};
