import { useDripsyTheme } from 'dripsy';
import { last } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {
  Circle,
  Defs,
  G,
  Line,
  LinearGradient,
  Path,
  Stop,
} from 'react-native-svg';

import { TestID } from '../../../../testID/constants';
import { Box } from '../../../ui/atoms/Box';
import { formatLoanTerm } from '../../../utils/stringHelpers';
import { useLoanAccountTermRemainingInsight } from '../../hooks/useLoanAccountTermRemainingInsight';
import { RepaymentPoint } from '../../utils/termRemainingGraphUtils';
import {
  INSIGHT_SVG_GRAPH_WIDTH,
  InsightGraphContainer,
} from '../InsightGraphContainer';
import {
  TERM_REMAINING_VIEWBOX_HEIGHT,
  TERM_REMAINING_VIEWBOX_PADDING,
  TERM_REMAINING_VIEWBOX_WIDTH,
} from './const';
import { LoanTermLabels } from './LoanTermLabels';
import { TermRemainingTooltip } from './TermRemainingTooltip';
import { TooltipInteractiveArea } from './TooltipInteractiveArea';

export const GRADIENT = ['#FF7BAD', '#5188EE'];

const AnimatedPath = Animated.createAnimatedComponent(Path);

const RepaymentLines = ({
  grayPath,
  gradientPath,
  activeGrayPoint,
  activeGradientPoint,
  onAnimationFinish,
}: {
  onAnimationFinish?: (isFinished: boolean) => void;
  grayPath: string;
  gradientPath: string;
  activeGrayPoint?: RepaymentPoint | null;
  activeGradientPoint?: RepaymentPoint | null;
}) => {
  const { theme } = useDripsyTheme();
  const theta = useSharedValue(120);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: withTiming(
      theta.value,
      {
        duration: 2000,
      },
      (finished) => {
        if (onAnimationFinish) {
          runOnJS(onAnimationFinish)(finished || false);
        }
      },
    ),
  }));

  useEffect(() => {
    theta.value = 0;
  }, [theta]);

  return (
    <G>
      <Defs>
        <LinearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          {GRADIENT.map((g, i) => (
            <Stop key={g} offset={i / GRADIENT.length} stopColor={g} />
          ))}
        </LinearGradient>
      </Defs>
      <AnimatedPath
        transform=""
        d={grayPath}
        strokeWidth={1}
        fill="none"
        opacity={0.18}
        stroke={theme.colors.$gray}
        strokeLinecap="round"
        strokeDasharray="120"
        animatedProps={animatedProps}
      />
      <AnimatedPath
        transform=""
        id="plot"
        d={gradientPath}
        stroke="url(#linear)"
        fill="none"
        strokeWidth={1}
        strokeLinecap="round"
        strokeDasharray="120"
        animatedProps={animatedProps}
      />
      {activeGrayPoint ? (
        <Line
          x1={activeGrayPoint?.x}
          y1={-20}
          x2={activeGrayPoint?.x}
          y2={activeGradientPoint ? activeGradientPoint.y : activeGrayPoint?.y}
          strokeWidth={0.5}
          opacity={0.15}
          stroke="#000"
          strokeLinecap="round"
        />
      ) : null}
      {activeGrayPoint ? (
        <Circle
          cx={activeGrayPoint.x}
          cy={activeGrayPoint.y}
          r={1}
          fill="#fff"
          strokeWidth={0.5}
          stroke="#000"
        />
      ) : null}
      {activeGradientPoint ? (
        <Circle
          cx={activeGradientPoint.x}
          cy={activeGradientPoint.y}
          r={1}
          fill="#fff"
          strokeWidth={0.5}
          stroke="#000"
        />
      ) : null}
    </G>
  );
};

type Props = {
  data: ReturnType<typeof useLoanAccountTermRemainingInsight>['data'];
  enableHoverOn?: boolean;
  displayTooltip?: boolean;
  hideLabels?: boolean;
} & React.ComponentProps<typeof InsightGraphContainer>;

export const TermRemainingInsightGraph: React.FC<Props> = ({
  data,
  enableHoverOn = false,
  displayTooltip = false,
  showBackgroundDotsLine = true,
  dotRadius = 0.2,
  numOfDotsLines = 5,
  hideLabels = false,
}) => {
  const {
    projectedRepaymentPoints,
    originalRepaymentPoints,
    projectedRepaymentPath,
    originalRepaymentPath,
    isNoEarlierTerms,
    projectedPayOffDate,
    originalPayOffYear,
  } = data;
  const [graphWidthInPx, setGraphWidthInPx] = useState(0);
  const [toolTipWidth, setToolTipWidth] = useState(0);
  const [activePointIndex, setActivePointIndex] = useState(-1);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  const tooltipPos = useMemo(() => {
    const translateX = Math.min(
      Math.max(
        0,
        (graphWidthInPx *
          (originalRepaymentPoints?.[activePointIndex]?.x || 0)) /
          INSIGHT_SVG_GRAPH_WIDTH -
          toolTipWidth * 0.5,
      ),
      graphWidthInPx - toolTipWidth,
    );

    return {
      transform: [
        {
          translateX,
        },
      ],
    };
  }, [activePointIndex, graphWidthInPx, originalRepaymentPoints, toolTipWidth]);

  const projectedPayOffDateLabelXPos = useMemo(() => {
    const lastPointXPosInPath =
      Number(last(projectedRepaymentPath.split(' '))?.split(',')[0]) || 0;
    return (lastPointXPosInPath * graphWidthInPx) / INSIGHT_SVG_GRAPH_WIDTH;
  }, [graphWidthInPx, projectedRepaymentPath]);

  const pointsXPosInPixel = useMemo(
    () =>
      originalRepaymentPoints.map(
        (point) =>
          TERM_REMAINING_VIEWBOX_PADDING +
          (point.x * graphWidthInPx) / TERM_REMAINING_VIEWBOX_WIDTH,
      ),
    [graphWidthInPx, originalRepaymentPoints],
  );

  return (
    <Box testID={TestID.Insights.TermRemaining.LineGraph}>
      {displayTooltip ? (
        <TermRemainingTooltip
          onLayout={(event) => {
            setToolTipWidth(event.nativeEvent.layout.width);
          }}
          remainingYears={formatLoanTerm(
            originalRepaymentPoints[
              activePointIndex === -1 ? 0 : activePointIndex
            ]?.remainedTerms || 0,
            true,
          )}
          loanAmount={
            originalRepaymentPoints[
              activePointIndex === -1 ? 0 : activePointIndex
            ]?.amount || 0
          }
          estimatedBalance={
            projectedRepaymentPoints[
              activePointIndex === -1 ? 0 : activePointIndex
            ]?.amount || 0
          }
          displayBoarder={activePointIndex !== -1}
          displayProjectedBalance={!isNoEarlierTerms}
          style={tooltipPos}
        />
      ) : null}
      <Box>
        {enableHoverOn && isAnimationFinished ? (
          <TooltipInteractiveArea
            pointsXPosInPixel={pointsXPosInPixel}
            setActivePointIndex={setActivePointIndex}
          />
        ) : null}
        <InsightGraphContainer
          onLayout={(event) => {
            setGraphWidthInPx(event.nativeEvent.layout.width);
          }}
          showBackgroundDotsLine={showBackgroundDotsLine}
          dotRadius={dotRadius}
          numOfDotsLines={numOfDotsLines}
          viewBox={`${-TERM_REMAINING_VIEWBOX_PADDING} ${-TERM_REMAINING_VIEWBOX_PADDING} ${TERM_REMAINING_VIEWBOX_WIDTH} ${TERM_REMAINING_VIEWBOX_HEIGHT}`}
        >
          <RepaymentLines
            gradientPath={projectedRepaymentPath}
            grayPath={originalRepaymentPath}
            activeGradientPoint={projectedRepaymentPoints?.[activePointIndex]}
            activeGrayPoint={originalRepaymentPoints?.[activePointIndex]}
            onAnimationFinish={setIsAnimationFinished}
          />
        </InsightGraphContainer>
      </Box>

      {hideLabels ? null : (
        <LoanTermLabels
          hideMiddleLabel={isNoEarlierTerms}
          middleLabel={projectedPayOffDate}
          rightLabel={originalPayOffYear}
          middleLabelMiddlePos={projectedPayOffDateLabelXPos}
        />
      )}
    </Box>
  );
};
