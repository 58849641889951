import { ApplicantStatusInfoFragment } from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { ApplicantState, getApplicantState } from './useApplicantStates';

export type LoanAccount = {
  id: string;
  cba_account_id: string;
};

export function filterLoanAccounts(
  appliedLoans: Array<ApplicantStatusInfoFragment>,
  loanAccounts: Array<LoanAccount>,
) {
  const allLoanApplicationTargetAccountIds = new Set(
    appliedLoans
      .flatMap((appliedLoan) =>
        appliedLoan.loanApplication.loanApplicationTargets.map(
          (target) => target.cbaAccountId,
        ),
      )
      .filter(isNotNullOrUndefined),
  );

  const disbursedCbaAccountIds = new Set(
    appliedLoans
      .filter(
        (appliedLoan) =>
          getApplicantState(appliedLoan, undefined, undefined) ===
          ApplicantState.Disbursed,
      )
      .flatMap((appliedLoan) =>
        appliedLoan.loanApplication.loanApplicationTargets.map(
          (target) => target.cbaAccountId,
        ),
      )
      .filter(isNotNullOrUndefined),
  );

  // To avoid duplicating the loan account card with the loan application
  // card, filter out any loan accounts which are not yet disbursed, using
  // the loan application state to decide whether the account is disbursed.
  //
  // Note: the source of truth for whether an account is disbursed or not
  // is actually CBA/Mambu, but we need to be able to render the home screen
  // without a dependency on CBA/Mambu availability so that we can take new
  // applications if CBA or Mambu is unavailable.  With this in mind it's
  // better to use the loan application data which should be accurate enough
  // for this purpose.
  //
  const loanApplicationDataSaysDisbursed = (loanAccount: {
    cba_account_id: string;
  }) => disbursedCbaAccountIds.has(loanAccount.cba_account_id);

  // In some scenarios there can be a loan account for the user but no loan
  // application data for the account. This will be most common in non-prod
  // environments where we have reassigned a customer ID to a user for testing
  // purposes. If there is no loan application data then we should show the
  // account on the home screen.
  //
  const loanAccountNotInLoanApplicationData = (loanAccount: {
    cba_account_id: string;
  }) => !allLoanApplicationTargetAccountIds.has(loanAccount.cba_account_id);

  const shouldShowLoanAccountOnHomeScreen = (loanAccount: {
    cba_account_id: string;
  }) =>
    loanApplicationDataSaysDisbursed(loanAccount) ||
    loanAccountNotInLoanApplicationData(loanAccount);

  return loanAccounts.filter(shouldShowLoanAccountOnHomeScreen);
}
