import { Text, View } from 'dripsy';
import { ComponentProps, useCallback } from 'react';

import { TestID } from '../../../testID/constants';
import { useNativeAppApplicationNavigation } from '../../Home/utils/useNativeAppApplicationNavigation';
import { Button } from '../../ui/atoms/Button';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';

export type Props = {
  cbaAccountId: string;
  loading?: boolean;
  notAllowed?: boolean;
  onContinue?: ComponentProps<typeof Button>['onPress'];
};

export const LoanTopUpSection = ({
  cbaAccountId,
  loading,
  notAllowed,
  onContinue,
}: Props) => {
  const { onTopUpIntro } = useNativeAppApplicationNavigation();
  const startTopUpApplication = useCallback(async () => {
    onTopUpIntro(cbaAccountId);
  }, [cbaAccountId, onTopUpIntro]);

  return (
    <View>
      <DataRowGroup mt={0}>
        <DataRow
          loading={loading}
          disabled={loading}
          label={t('Content.LoanDetail.LoanTopUp.Title')}
          subCaption={t('Content.LoanDetail.LoanTopUp.SubTitle')}
          captionVariant="caption"
          testID={TestID.LoanDetails.TopUpYourLoanButton}
          onRightButtonPress={onContinue ?? startTopUpApplication}
          rightButtonLabel={
            onContinue
              ? t('Content.LoanDetail.LoanTopUp.Continue')
              : t('Content.LoanDetail.LoanTopUp.GetStarted')
          }
          rightButtonDisabled={notAllowed}
          rightButtonSecondary
        />
      </DataRowGroup>
      {notAllowed ? (
        <Text
          variant="caption"
          sx={{
            fontSize: '$14',
            color: '$secondary',
            marginTop: '$8',
          }}
        >
          <Text sx={{ verticalAlign: 'middle' }}>
            <StyledIcon
              name="information-circle-outline"
              color="secondaryContent"
              size="navIcon"
            />
          </Text>{' '}
          {t('Content.LoanDetail.LoanTopUp.DisabledAlert')}
        </Text>
      ) : null}
    </View>
  );
};
