import { gql } from '@apollo/client';
import {
  FinancialDeclarationFragmentDoc,
  refetchSelectRefiReasonScreenQuery,
  refetchSetupLoanScreenQuery,
  Refinancing_Reason_Input_Enum,
  useCreateFinancialDeclarationMutation,
  useSelectRefiReasonScreenQuery,
  useUpdateFinancialDeclarationMutation,
} from 'src/generated/graphql';
import { parseEnumType } from 'src/utils/ensureEnumType';
import { safelyCallMutation } from 'src/utils/hooks/errorUtils';

export const SelectRefiReasonScreen = gql`
  query SelectRefiReasonScreen($loanApplicationId: uuid!) {
    refinancing_reason {
      reason
      description
    }
    financial_declaration(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      refinancing_reason
    }
  }
`;
export const CREATE_FINANCIAL_DECLARATION = gql`
  mutation CreateFinancialDeclaration(
    $data: create_financial_declaration_input!
  ) {
    create_financial_declaration(data: $data) {
      id
      financial_declaration {
        ...FinancialDeclaration
      }
    }
  }

  ${FinancialDeclarationFragmentDoc}
`;

export function useRefinanceDeclaration(loanApplicationId: string) {
  const { data, loading } = useSelectRefiReasonScreenQuery({
    variables: {
      loanApplicationId,
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const refinancingReasonOptions =
    data?.refinancing_reason.map(({ reason, description }) => {
      const parsedReason = parseEnumType(Refinancing_Reason_Input_Enum, reason);
      return {
        value: reason,
        label: parsedReason
          ? t(`Content.RefinancingReasonEnum.${parsedReason}`)
          : description,
      };
    }) || [];

  const financialDeclaration = data?.financial_declaration?.[0];

  const [
    createFinancialDeclaration,
    {
      loading: createFinancialDeclarationLoading,
      error: createFinancialDeclarationError,
    },
  ] = useCreateFinancialDeclarationMutation();

  const [
    updateFinancialDeclaration,
    {
      loading: updateFinancialDeclarationLoading,
      error: updateFinancialDeclarationError,
    },
  ] = useUpdateFinancialDeclarationMutation();

  const upsertFinancialDeclarationLoading =
    createFinancialDeclarationLoading || updateFinancialDeclarationLoading;

  const upsertFinancialDeclarationError =
    createFinancialDeclarationError || updateFinancialDeclarationError;

  const upsertFinancialDeclaration = async (
    refinancingReason: Refinancing_Reason_Input_Enum,
  ) => {
    if (financialDeclaration?.id) {
      return safelyCallMutation(updateFinancialDeclaration, {
        variables: {
          data: {
            loan_application_id: loanApplicationId,
            refinancing_reason: refinancingReason,
          },
        },
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
        refetchQueries: [
          refetchSelectRefiReasonScreenQuery({ loanApplicationId }),
          refetchSetupLoanScreenQuery({ loanApplicationId }),
        ],
      });
    }

    return safelyCallMutation(createFinancialDeclaration, {
      variables: {
        data: {
          loan_application_id: loanApplicationId,
          refinancing_reason: refinancingReason,
        },
      },
      refetchQueries: [
        refetchSelectRefiReasonScreenQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
      ],
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  };

  return {
    refinancingReasonOptions,
    financialDeclaration,
    loading,
    upsertFinancialDeclarationLoading,
    upsertFinancialDeclarationError,
    upsertFinancialDeclaration,
  };
}
