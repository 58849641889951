import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isAndroidNative, isIosNative, isWeb } from 'src/utils/platformUtils';

import { FeatureFlagsContext } from '../FeatureFlags/context';
import { setSentryCustomerSessionId } from '../sentry';
import { useEnvConfig } from '../utils/hooks/useEnvConfig';
import { getLogger } from '../utils/loggerHelper';
import { normalizeError } from '../utils/normalizeError';
import { getBiocatchConfig } from './biocatchConfigHelper';
import { BioCatchError, captureBiocatchException } from './biocatchErrorHelper';
import {
  changeContextNative,
  isBiocatchNativeAvailable,
  isBiocatchNativeInitialised,
  startBiocatchDataCaptureNative,
  startNewSessionNative,
} from './biocatchNativeHelper';
import {
  changeContextWeb,
  isBiocatchWebInitialised,
  startBiocatchDataCaptureWeb,
} from './biocatchWebHelper';
import * as biocatchWebStorage from './biocatchWebStorage';

const logger = getLogger('APP');

export type BiocatchContextType = {
  customerSessionId?: string | undefined;
  authParams: Record<string, string | undefined> | undefined;
  captureNavigationEvent: (event: string) => void;
  handleLogoutWeb: () => void;
  handleLogoutNativeApp: () => void;
  handleSessionTimeoutWeb: () => void;
  handleIdleTimeoutAndroid: () => void;
  handleAppBackground: () => void;
  handleAppForeground: () => void;
  handleAppInactive: () => void;
};

export type BiocatchAuthParams = {
  'ext-biocatchScriptURL': string;
  'ext-customerSessionId': string;
  customerSessionId: string;
};

const BiocatchContext = createContext<BiocatchContextType | null>(null);

export enum InitOutcome {
  Success = 'Success',
  Failure = 'Failure',
  InvalidConfig = 'InvalidConfig',
  FeatureDisabled = 'FeatureDisabled',
  UnsupportedPlatform = 'UnsupportedPlatform',
}

/**
 * The role of the provider is to initialise Biocatch SDK when user activity
 * data capture is enabled by the feature flag. It will load the Biocatch SDK,
 * configure it using the active environment config, and provide the
 * AuthProvider with a way to pass the customer session ID through to Auth0
 * during login.
 *
 * The provider also provides a set of functions to handle various events
 * that the Biocatch SDK needs to know about, such as navigation, logout,
 * session timeout, and app lifecycle events.
 */
export function BiocatchProvider({
  children,
  onInitialised,
}: {
  children?: React.ReactNode;
  onInitialised?: (outcome: InitOutcome) => void;
}) {
  const { flags } = useContext(FeatureFlagsContext);
  const { config } = useEnvConfig();

  const initRef = useRef(false);

  const [customerSessionId, setCustomerSessionId] = useState<
    string | undefined
  >();

  const [authParams, setAuthParams] = useState<
    BiocatchAuthParams | undefined
  >();

  /**
   * Capture a navigation event in the Biocatch SDK.
   * This is used to track the user's navigation within the app.
   *
   * Works for both web and native platforms.
   */
  const captureNavigationEvent = useCallback((event: string) => {
    logger?.log('Biocatch - captureNavigationEvent', event);
    if (isWeb) {
      changeContextWeb(event);
    } else {
      changeContextNative(event);
    }
  }, []);

  /**
   * Web only.
   *
   * For logout, the browser first redirects to Auth0 domain to
   * end the session, then back to the app via the callback URL.
   * This redirect means the JavaScript memory is cleared so
   * unlike session timeout we don't need to worry about starting
   * a new session or updating the session ID in component state.
   *
   * We do need to flush the Biocatch data before the redirect,
   * and ensure the session ID is cleared from session storage
   * so that a new session is started when the user returns to
   * the app.
   */
  const handleLogoutWeb = useCallback(() => {
    if (!isWeb) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleLogoutWeb called on non-web platform',
      );
      return;
    }

    biocatchWebStorage.clearCustomerSessionId();
    logger?.log('Biocatch - Handle Logout', customerSessionId);
  }, [customerSessionId]);

  /**
   * Native app only.
   */
  const handleLogoutNativeApp = useCallback(() => {
    if (!isAndroidNative && !isIosNative) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleLogoutNativeApp called on non-Native platform',
      );
      return;
    }

    logger?.log('Biocatch - handleLogoutNativeApp', customerSessionId);
    setCustomerSessionId(undefined);
  }, [customerSessionId]);

  /**
   * Web only.
   *
   * When the session times out, we need to end the Biocatch session.
   * Unlike the logout case, in which the browser will navigate away
   * to Auth0 and back to the app, the session timeout will not trigger
   * a navigation event, so we need to manually end the Biocatch session.
   *
   * Starts a new session in biocatch, so that if/when the user returns
   * to the app, a new session will be started that will begin with
   * the authentication flow.
   */
  const handleSessionTimeoutWeb = useCallback(() => {
    if (!isWeb) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleSessionTimeoutWeb called on non-web platform',
      );
      return;
    }

    if (!isBiocatchWebInitialised()) {
      logger?.log('Biocatch - handleSessionTimeoutWeb Biocatch is not loaded');
      return;
    }

    try {
      // Clear from session storage
      biocatchWebStorage.clearCustomerSessionId();
      window.cdApi.startNewSession();
      logger?.log('Biocatch - Handle Session Timeout', customerSessionId);
    } catch (e) {
      captureBiocatchException(
        BioCatchError.SessionTimeoutFailed,
        customerSessionId,
        normalizeError(e),
      );
    }
  }, [customerSessionId]);

  /**
   * Android only.
   */
  const handleIdleTimeoutAndroid = useCallback(async () => {
    if (!isAndroidNative) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleIdleTimeoutAndroid called on non-Android platform',
      );
      return;
    }

    if (!isBiocatchNativeInitialised()) {
      logger?.log(
        'Biocatch - handleIdleTimeoutAndroid native SDK is not running',
      );
      return;
    }

    await changeContextNative('auth-idle-timeout');
    logger?.log('Biocatch - handleIdleTimeoutAndroid', customerSessionId);

    try {
      const newSessionId = await startNewSessionNative();
      setCustomerSessionId(newSessionId);
      setSentryCustomerSessionId(newSessionId);
    } catch (e) {
      captureBiocatchException(
        BioCatchError.StartNewSessionFailed,
        customerSessionId,
        normalizeError(e),
      );
    }
  }, [customerSessionId]);

  /**
   * iOS only
   * Gives a signal that the app is going to the background
   */
  const handleAppBackground = useCallback(() => {
    if (!isIosNative) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleAppBackground called on non-iOS platform',
      );
      return;
    }

    logger?.log('Biocatch - handleAppBackground', customerSessionId);
    changeContextNative('app-background');
  }, [customerSessionId]);

  /**
   * iOS only
   * Gives a signal that the app is coming back to the foreground
   */
  const handleAppForeground = useCallback(async () => {
    if (!isIosNative) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleAppForeground called on non-iOS platform',
      );
      return;
    }

    if (!isBiocatchNativeInitialised()) {
      logger?.log('Biocatch - handleAppForeground native SDK is not running');
      return;
    }

    await changeContextNative('app-foreground');
    logger?.log('Biocatch - handleAppForeground', customerSessionId);

    try {
      const newSessionId = await startNewSessionNative();
      setCustomerSessionId(newSessionId);
      setSentryCustomerSessionId(newSessionId);
    } catch (e) {
      captureBiocatchException(
        BioCatchError.StartNewSessionFailed,
        customerSessionId,
        normalizeError(e),
      );
    }
  }, [customerSessionId]);

  /**
   * iOS only
   * Gives a signal that the app is going to the inactive state, which
   * means the app is visible but not responding to events.
   */
  const handleAppInactive = useCallback(() => {
    if (!isIosNative) {
      captureBiocatchException(
        BioCatchError.UnexpectedPlatform,
        customerSessionId,
        'handleAppInactive called on non-iOS platform',
      );
      return;
    }

    logger?.log('Biocatch - handleAppInactive', customerSessionId);
    changeContextNative('app-inactive');
  }, [customerSessionId]);

  const handleAppStart = useCallback(async (): Promise<InitOutcome> => {
    if (flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE === false) {
      logger?.log(
        'Biocatch data capture is disabled by feature flag',
        flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE,
      );
      return InitOutcome.FeatureDisabled;
    }

    const biocatchConfig = getBiocatchConfig(config);

    if (!biocatchConfig) {
      logger?.error('Biocatch config missing in environment config');
      captureBiocatchException(
        BioCatchError.InitFailed,
        customerSessionId,
        'Biocatch config missing in environment config',
      );
      return InitOutcome.InvalidConfig;
    }

    logger?.log('Biocatch data capture enabled');

    let startBiocatchDataCapture;
    if (isWeb) {
      startBiocatchDataCapture = startBiocatchDataCaptureWeb;
    } else if (isAndroidNative || isIosNative) {
      if (!isBiocatchNativeAvailable()) {
        logger?.log('Biocatch data capture not supported on old app versions');
        return InitOutcome.UnsupportedPlatform;
      }
      startBiocatchDataCapture = startBiocatchDataCaptureNative;
    } else {
      logger?.log('Biocatch data capture not supported on non-web platforms');
      return InitOutcome.UnsupportedPlatform;
    }

    return startBiocatchDataCapture(biocatchConfig)
      .then((csid) => {
        setSentryCustomerSessionId(csid);
        setCustomerSessionId(csid);
        setAuthParams({
          // The ext- prefix is required by Auth0 to pass custom parameters
          // through to the HTML template.
          'ext-biocatchScriptURL': biocatchConfig.biocatchScriptURL,
          'ext-customerSessionId': csid,

          // The CSID also needs to be passed without the ext- prefix
          // so that the Custom Action script in Auth0 can access it.
          customerSessionId: csid,
        });
        logger?.log('Biocatch data capture initialised', csid);
        return InitOutcome.Success;
      })
      .catch((e) => {
        logger?.error('Failed to initialise biocatch client data capture', e);
        captureBiocatchException(
          BioCatchError.InitFailed,
          customerSessionId,
          normalizeError(e),
        );
        return InitOutcome.Failure;
      });
  }, [config, customerSessionId, flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE]);

  useEffect(() => {
    logger?.log(
      'Biocatch ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE=',
      flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE,
    );

    if (initRef.current) {
      // biocatch setup is already done, don't do it again
      return;
    }

    if (flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE === undefined) {
      // feature flags have not loaded yet, don't do anything
      logger?.log('BiocatchProvider - feature flags not loaded yet');
      return;
    }

    initRef.current = true;

    handleAppStart().then((outcome) => {
      logger?.log('BiocatchProvider - initialised', outcome, customerSessionId);
      onInitialised?.(outcome);
    });
  }, [
    customerSessionId,
    flags.ENABLE_BIOCATCH_CLIENT_DATA_CAPTURE,
    handleAppStart,
    onInitialised,
  ]);

  const biocatchContext = useMemo(
    () => ({
      customerSessionId,
      authParams,
      captureNavigationEvent,
      handleLogoutWeb,
      handleLogoutNativeApp,
      handleSessionTimeoutWeb,
      handleIdleTimeoutAndroid,
      handleAppBackground,
      handleAppForeground,
      handleAppInactive,
    }),
    [
      customerSessionId,
      authParams,
      captureNavigationEvent,
      handleLogoutWeb,
      handleLogoutNativeApp,
      handleSessionTimeoutWeb,
      handleIdleTimeoutAndroid,
      handleAppBackground,
      handleAppForeground,
      handleAppInactive,
    ],
  );

  return (
    <BiocatchContext.Provider value={biocatchContext}>
      {children}
    </BiocatchContext.Provider>
  );
}

export function useBiocatch() {
  const context = useContext(BiocatchContext);
  if (!context) {
    throw new Error('useBiocatch must be used within a BiocatchProvider');
  }
  return context;
}
