import { gql } from '@apollo/client';
import { A, Text } from 'dripsy';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { TestID } from '../../../../testID/constants';
import { ScreenLoadingContainer } from '../../../components/ScreenLoadingContainer';
import { SuccessRow } from '../../../components/SuccessRow';
import {
  refetchGetStoredLoanAmountAndSecurityCostsQuery,
  refetchPurchaseYourUnloanScreenQuery,
  refetchReviewLoanApplicationQuery,
  Repayment_Calculation_Error,
  Security_Cost_Calculation_State_Enum,
  UpsertLoanApplicationTargetForPurchaseMutation,
  UpsertLoanApplicationTargetForPurchaseMutationVariables,
  useCalculatePurchaseLoanAmountAndDepositLazyQuery,
  useGetStoredLoanAmountAndSecurityCostsLazyQuery,
  usePurchaseSetupLoanScreenQuery,
  useUpsertLoanApplicationTargetForPurchaseMutation,
} from '../../../generated/graphql';
import { NoLoanApplicationFound } from '../../../LoanApplication/components/NoLoanApplicationFound';
import { useNavigateToLoanApplicationScreen } from '../../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../../navigation/types/navTypes';
import { Screen } from '../../../navigation/types/screens';
import { captureMessage } from '../../../sentry';
import { ModalScreenContainer } from '../../../ui/v2/ModalScreenContainer';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { transformStringToEnum } from '../../../utils/enumHelpers';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../../utils/hooks/useAppSummaryScreenNavigation';
import { useSecurityCostCalculationState } from '../../../utils/hooks/useSecurityCostCalculationState';
import { DEFAULT_LOAN_TERM_IN_MONTHS } from '../../utils/setupLoanFormHelper';
import { errorBannerStateReducer } from '../../utils/setupLoanPurchaseErrorBannerReducer';
import { SetupLoanPurchaseErrorBanner } from '../components/SetupLoanPurchaseErrorBanner';
import {
  SetupLoanPurchaseForm,
  SetupLoanPurchaseFormInitialValues,
} from '../components/SetupLoanPurchaseForm';

type Props = SingleModalStackScreenProps<Screen.SETUP_LOAN_FOR_PURCHASE_MODAL>;

export const UpsertLoanApplicationTargetForPurchase = gql`
  mutation UpsertLoanApplicationTargetForPurchase(
    $data: upsert_loan_application_target_for_purchase_input!
  ) {
    upsert_loan_application_target_for_purchase(data: $data) {
      error_type
      loan_application_target {
        id
        desired_loan_amount
        term_months
        target_product_rate {
          interest_rate
          id: product_rate_id
        }
        # Query back securities to update cache in property screen
        loan_application_securities {
          id
          loan_application_target_id
          loan_application_target {
            id
          }
        }
      }
      loan_application_target_id
      minimum_total_loan_amount_before_cost
      maximum_total_loan_amount_before_cost
      min_total_loan_amount_before_cost
      max_total_loan_amount_before_cost
      max_total_loan_amount
    }
  }
`;

export const PurchaseSetupLoanScreen = gql`
  query PurchaseSetupLoanScreen($loanApplicationId: uuid!) {
    # Why this target query from the loan_application_by_pk query?
    loan_application_target(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      desired_loan_amount
      term_months
    }

    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      is_lmi_enabled
      incomes(
        where: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
      ) {
        id
        rental_income {
          id
          estimated_loan_amount
        }
      }

      loan_application_securities {
        id
        property {
          id
          address {
            id
            state
          }
        }
      }
    }
  }
`;

export const CalculatePurchaseLoanAmountAndDeposit = gql`
  query CalculatePurchaseLoanAmountAndDeposit(
    $data: calculate_purchase_loan_amount_and_upfront_costs_input!
  ) {
    calculate_purchase_loan_amount_and_upfront_costs(data: $data) {
      totalLoanAmount: total_loan_amount
      appliedInterestRate: applied_interest_rate
      defaultInterestRate: default_interest_rate
      # This query intentionally do not query the min loan amount
      # because we do not want to show them in real time while user types.
      # Instead, the min error will be shown on submit and
      # is coming from the save mutation.
      minRepaymentPerMonth: min_repayment_per_month
      # We do still want to display some specific type of errors
      # that will block the user from submitting,
      # hence error type is included.
      minRepaymentErrorType: min_repayment_error_type
      totalEstimatedFees: total_estimated_fees
      maxLvrApplicable: max_lvr_applicable
      maxTotalLoanAmount: max_loan_amount
      lmiNeeded: lmi_needed
      lmiPremium: lmi_premium
      lmiQuoteErrorType: lmi_quote_error_type

      upfront_costs {
        totalUpfrontCost: total_upfront_cost
        stampDuty: stamp_duty
        estimatedConveyancing: estimated_conveyancing
        deposit
      }
    }
  }
`;

export function SetupLoanPurchase({ navigation, route }: Props) {
  const { loanApplicationId, _initialErrorBanner } = route.params ?? {};

  const [errorBannerState, dispatch] = useReducer(
    errorBannerStateReducer,
    _initialErrorBanner ?? null,
  );

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { data, loading } = usePurchaseSetupLoanScreenQuery({
    variables: {
      loanApplicationId: loanApplicationId ?? '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [
    getLoanAmountAndSecurityCosts,
    {
      data: loanAmountAndSecurityCosts,
      loading: getLoanAmountAndSecurityCostsLoading,
    },
  ] = useGetStoredLoanAmountAndSecurityCostsLazyQuery();

  const [upsertLoanTarget, { loading: upsertLoanTargetLoading }] =
    useUpsertLoanApplicationTargetForPurchaseMutation();

  const [
    calculateLoanAmountAndDeposit,
    {
      data: calculateLoanAmountAndDepositResult,
      loading: calculateLoanAmountAndDepositLoading,
      refetch: refetchCalculateLoanAmountAndDeposit,
    },
  ] = useCalculatePurchaseLoanAmountAndDepositLazyQuery({
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
    onCompleted: (res) => {
      dispatch({
        type: 'CALCULATE_PURCHASE_LOAN_AMOUNT_AND_DEPOSIT_COMPLETED',
        payload: {
          data: res,
        },
      });
    },
  });

  const calculateLoanAmountAndDepositData =
    calculateLoanAmountAndDepositResult?.calculate_purchase_loan_amount_and_upfront_costs;
  const maxLvrApplicable = calculateLoanAmountAndDepositData?.maxLvrApplicable;

  const captureCurrentLoadingStates = useCallback(
    (costCalculationState?: Security_Cost_Calculation_State_Enum | null) => {
      captureMessage(
        'Security cost calculation took too long - SetupLoanPurchase',
        {
          loanApplicationId,
          costCalculationState,
          // Adding other loading states to help debug and discover false positive
          getLoanAmountAndSecurityCostsLoading,
          calculateLoanAmountAndDepositLoading,
        },
      );
    },
    [
      calculateLoanAmountAndDepositLoading,
      getLoanAmountAndSecurityCostsLoading,
      loanApplicationId,
    ],
  );

  const {
    waitingForCostCalculationToBeCompleted,
    isWaitingForRetryToComplete,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    onCalculationComplete: async () => {
      try {
        dispatch({
          type: 'CALCULATE_SECURITY_COST_COMPLETED',
        });
        await getLoanAmountAndSecurityCosts({
          variables: { loanApplicationId: loanApplicationId ?? '' },
          fetchPolicy: 'network-only',
          context: {
            sentryContext: {
              loanApplicationId,
            },
          },
        });
      } catch (e) {
        // Error has been logged using apollo error link
      }
    },
    logToSentryIfCalculationTookLongTime: true,
    onLogCalculationTookTooLong: captureCurrentLoadingStates,
  });

  useEffect(() => {
    if (shouldShowErrorForCostCalculation) {
      dispatch({
        type: 'CALCULATE_SECURITY_COST_FAILED',
      });
    }
  }, [shouldShowErrorForCostCalculation]);

  const loanApplication = data?.loan_application_by_pk;
  const loanApplicationTarget = data?.loan_application_target?.[0];

  const appliedInterestRate =
    calculateLoanAmountAndDepositData?.appliedInterestRate ?? undefined;
  const loanSecurityState =
    loanApplication?.loan_application_securities[0]?.property.address.state;
  const estimatedLoanAmount =
    data?.loan_application_by_pk?.incomes[0]?.rental_income
      ?.estimated_loan_amount;

  const initialValues = useMemo(
    () => ({
      loanAmount:
        loanApplicationTarget?.desired_loan_amount ??
        estimatedLoanAmount ??
        undefined,
      termInMonths:
        loanApplicationTarget?.term_months ?? DEFAULT_LOAN_TERM_IN_MONTHS,
    }),
    [
      estimatedLoanAmount,
      loanApplicationTarget?.desired_loan_amount,
      loanApplicationTarget?.term_months,
    ],
  );

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourUnloan = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.YourUnloan,
      }),
    );
  };

  const rawMinRepaymentErrorType =
    calculateLoanAmountAndDepositResult
      ?.calculate_purchase_loan_amount_and_upfront_costs.minRepaymentErrorType;
  const minRepaymentErrorType = rawMinRepaymentErrorType
    ? transformStringToEnum(
        rawMinRepaymentErrorType,
        Repayment_Calculation_Error,
      )
    : null;

  const isMinRepaymentCalcFailing =
    minRepaymentErrorType != null &&
    // Min amount is not counted as failing in purchase, handled in submit
    minRepaymentErrorType !== Repayment_Calculation_Error.LessThanMinAmount &&
    // Max amount is not counted as failing in purchase, handled in submit
    minRepaymentErrorType !== Repayment_Calculation_Error.MoreThanMaxAmount;

  const totalLoanAmount = calculateLoanAmountAndDepositData?.totalLoanAmount;

  const onSubmit = async (values: SetupLoanPurchaseFormInitialValues) => {
    if (
      loanApplicationId == null ||
      values.loanAmount == null ||
      values.termInMonths == null
    ) {
      dispatch({
        type: 'SUBMIT_FORM_FAILED',
        message: t('Content.Common.Error.FailCreateLoanApplicationTarget'),
      });
      return;
    }

    const [res] = await safelyCallMutation<
      UpsertLoanApplicationTargetForPurchaseMutation,
      UpsertLoanApplicationTargetForPurchaseMutationVariables
    >(upsertLoanTarget, {
      variables: {
        data: {
          loan_application_id: loanApplicationId,
          desired_loan_amount: values.loanAmount,
          loan_term_in_months: values.termInMonths,
        },
      },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
      refetchQueries: [
        refetchPurchaseYourUnloanScreenQuery({
          loanApplicationId,
        }),
        refetchGetStoredLoanAmountAndSecurityCostsQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
    });

    if (
      res?.data?.upsert_loan_application_target_for_purchase
        .loan_application_target_id
    ) {
      navigateToYourUnloan();
      return;
    }

    if (res?.data?.upsert_loan_application_target_for_purchase.error_type) {
      const {
        minimum_total_loan_amount_before_cost,
        maximum_total_loan_amount_before_cost,
        max_total_loan_amount,
        error_type,
      } = res.data.upsert_loan_application_target_for_purchase;

      dispatch({
        type: 'UPSERT_LOAN_APPLICATION_TARGET_FOR_PURCHASE_COMPLETED_WITH_ERROR',
        payload: {
          minTotalLoanAmountBeforeCosts: minimum_total_loan_amount_before_cost,
          // Why max total loan amount before cost is mapped to max loan amount?
          maxLoanAmount: maximum_total_loan_amount_before_cost,
          maxTotalLoanAmount: max_total_loan_amount,
          errorType: error_type,
          maxLvrApplicable,
          totalLoanAmount,
        },
      });
      return;
    }
    dispatch({
      type: 'SUBMIT_FORM_FAILED',
      message: t('Content.Common.Error.FailCreateLoanApplicationTarget'),
    });
  };

  if (!loanApplicationId) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={navigateToYourUnloan}
        hideBackButton
      >
        <NoLoanApplicationFound />
      </ModalScreenContainer>
    );
  }

  if (loading) {
    return (
      <ModalScreenContainer
        scrollable
        onClose={navigateToYourUnloan}
        hideBackButton
      >
        <ScreenLoadingContainer
          loading
          testID={TestID.SetupLoanPurchase.Loading}
        />
      </ModalScreenContainer>
    );
  }

  const isLmiQuoteCalcFailing =
    calculateLoanAmountAndDepositResult
      ?.calculate_purchase_loan_amount_and_upfront_costs.lmiQuoteErrorType !=
    null;

  const isLmiEnabled = loanApplication?.is_lmi_enabled ?? false;
  const isLmiNeeded =
    calculateLoanAmountAndDepositResult
      ?.calculate_purchase_loan_amount_and_upfront_costs.lmiNeeded;
  const lmiPremium =
    calculateLoanAmountAndDepositResult
      ?.calculate_purchase_loan_amount_and_upfront_costs.lmiPremium;

  const disableSubmitButton =
    waitingForCostCalculationToBeCompleted ||
    calculateLoanAmountAndDepositLoading ||
    getLoanAmountAndSecurityCostsLoading ||
    isMinRepaymentCalcFailing ||
    isLmiQuoteCalcFailing;

  // Only show LMI banner if LMI is enabled and needed
  // and there is no error banner.
  const showLmiBanner =
    !disableSubmitButton &&
    isLmiEnabled &&
    isLmiNeeded &&
    errorBannerState == null;

  const maxDesiredLoanAmount =
    loanAmountAndSecurityCosts?.get_estimated_upfront_costs_and_max_loan
      .maxLoanAmount;

  const defaultInterestRate =
    calculateLoanAmountAndDepositData?.defaultInterestRate;
  const isRateDifferentThanDefault =
    appliedInterestRate != null &&
    defaultInterestRate != null &&
    appliedInterestRate !== defaultInterestRate;

  return (
    <ModalScreenContainer
      scrollable
      headerText={t('Content.SetupLoanV2.ScreenTitle')}
      onClose={navigateToYourUnloan}
      hideBackButton
      loading={upsertLoanTargetLoading}
    >
      {showLmiBanner ? (
        <SuccessRow autoDismiss={false} sx={{ my: '$8' }}>
          <Text variant="caption" sx={{ color: '$labelsPrimary' }}>
            {isRateDifferentThanDefault
              ? t('Content.SetupLoanPurchase.LmiPremiumWithInterestRate', {
                  maxDesiredLoanAmount: formatCurrency(maxDesiredLoanAmount),
                  lmiPremium: formatCurrency(lmiPremium),
                  interestRate: appliedInterestRate,
                })
              : t('Content.SetupLoanPurchase.LmiPremium', {
                  maxDesiredLoanAmount: formatCurrency(maxDesiredLoanAmount),
                  lmiPremium: formatCurrency(lmiPremium),
                })}
            <A
              href={t('Link.LmiBenefits')}
              variants={['caption', 'link']}
              hrefAttrs={{
                rel: 'noreferrer',
                target: '_blank',
              }}
            >
              {` ${t('Content.Common.ButtonLabel.LearnMore')}`}
            </A>
          </Text>
        </SuccessRow>
      ) : null}

      {errorBannerState ? (
        <SetupLoanPurchaseErrorBanner
          message={errorBannerState.message}
          type={errorBannerState.type}
          retrySecurityCostCalculation={retrySecurityCostCalculation}
          retrySecurityCostCalculationLoading={isWaitingForRetryToComplete}
          retryLmiQuoteCalculation={async () => {
            await refetchCalculateLoanAmountAndDeposit();
          }}
          retryLmiQuoteCalculationLoading={calculateLoanAmountAndDepositLoading}
          testID={TestID.SetupLoanPurchase.ErrorBanner}
          sx={{ my: '$8' }}
        />
      ) : null}

      <SetupLoanPurchaseForm
        screen={Screen.SETUP_LOAN_FOR_PURCHASE_MODAL}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isSubmitting={upsertLoanTargetLoading}
        disableSubmitButton={disableSubmitButton}
        interestRate={appliedInterestRate}
        loanApplicationId={loanApplicationId}
        calculateLoanAmountAndDeposit={calculateLoanAmountAndDeposit}
        calculateLoanAmountAndDepositResult={calculateLoanAmountAndDepositData}
        calculateLoanAmountAndDepositLoading={
          calculateLoanAmountAndDepositLoading ||
          waitingForCostCalculationToBeCompleted
        }
        estimatedUpfrontCostsAndMaxLoanAmount={
          loanAmountAndSecurityCosts?.get_estimated_upfront_costs_and_max_loan
        }
        estimatedUpfrontCostsAndMaxLoanAmountLoading={
          getLoanAmountAndSecurityCostsLoading ||
          waitingForCostCalculationToBeCompleted
        }
        loanSecurityState={loanSecurityState}
        isLmiEnabled={isLmiEnabled}
      />
    </ModalScreenContainer>
  );
}
