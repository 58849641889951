import { useGetAccountsQuery } from '../../../generated/graphql';
import { getUnloanAccounts } from './utils/getUnloanAccounts';

export const useAccounts = () => {
  const { data, loading } = useGetAccountsQuery();

  const unloanAccounts = getUnloanAccounts(data);

  const externalAccounts =
    data?.me?.[0]?.user?.identity_profile?.external_accounts ?? [];

  return {
    loading,
    unloanAccounts,
    externalAccounts,
  };
};
