import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Number, Record, Static, String } from 'runtypes';

import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  useGetYodleeInstitutionsQuery as useGetYodleeInstitutionsQueryBase,
  useSearchYodleeInstitutionsLazyQuery,
} from '../../generated/graphql';
import {
  //   ConnectedBanksScreenParamsRuntype,
  //   SelectInstitutionStackRoute,
  SelectInstitutionStackNavigation,
} from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { useCancelButton } from '../../utils/hooks/useHeaderButton';
import { filterArray } from '../../utils/runtypes';
import { InstitutionList } from '../components/InstitutionList';

export const YodleeInstitution = Record({
  id: Number,
  name: String,
  favicon: String.optional(),
});

export type YodleeInstitutionType = Static<typeof YodleeInstitution>;

export const QUERY = gql`
  query GetYodleeInstitutions {
    getAllInstitutions(top: 300) {
      ...YodleeInstitution
    }
  }
  query SearchYodleeInstitutions($query: String!) {
    getAllInstitutions(query: $query) {
      ...YodleeInstitution
    }
  }

  fragment YodleeInstitution on InstitutionDetails {
    id
    name
    favicon
  }
`;

function useGetYodleeInstitutions() {
  const { data, ...other } = useGetYodleeInstitutionsQueryBase();

  const institutions = useMemo(
    () => filterArray(YodleeInstitution, data?.getAllInstitutions || []),
    [data],
  );

  return { ...other, institutions };
}
function useSearchYodleeInstitutions(query: string) {
  const [search, { data, ...other }] = useSearchYodleeInstitutionsLazyQuery();

  useEffect(() => {
    if (!query) {
      return;
    }
    search({
      variables: {
        query,
      },
    });
  }, [query, search]);

  const institutions = useMemo(
    () => filterArray(YodleeInstitution, data?.getAllInstitutions || []),
    [data],
  );

  return { ...other, institutions };
}

export function SelectBank() {
  const navigation =
    useNavigation<SelectInstitutionStackNavigation<Screen.SELECT_BANK>>();
  //   const route = useRoute<SelectInstitutionStackRoute<Screen.SELECT_BANK>>();
  useCancelButton(navigation);
  //   const params = useMemo(
  //     () =>
  //       ConnectedBanksScreenParamsRuntype.guard(route.params)
  //         ? route.params
  //         : undefined,
  //     [route.params],
  //   );

  const [searchValue, setSearchValue] = useState('');

  const debouncedSetSearch = debounce((s) => setSearchValue(s), 300);

  const { institutions, loading, error } = useGetYodleeInstitutions();
  const {
    institutions: searchResult,
    loading: searchResultLoading,
    error: searchResultError,
  } = useSearchYodleeInstitutions(searchValue);

  return (
    <ModalScreenContainer>
      <NavHeaderSpacer />
      <InstitutionList
        searchValue={searchValue}
        setSearchValue={debouncedSetSearch}
        loading={searchValue ? searchResultLoading : loading}
        error={searchValue ? searchResultError : error}
        data={searchValue ? searchResult : institutions}
        onInstitutionPress={() => {
          // Disabling this for now. In the future sprint, we need to get list of institutions that support open-banking from Yodlee
          //   if (params) {
          //     navigation.navigate(Screen.BANK_LOGIN, {
          //       institutionId: institution.id,
          //       ...params,
          //     });
          //     return;
          //   }
          navigation.goBack();
        }}
        contentContainerStyle={
          institutions.length ? undefined : styles.emptyList
        }
      />
    </ModalScreenContainer>
  );
}

const styles = StyleSheet.create({
  emptyList: { flex: 1 },
});
