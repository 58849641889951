import { isValidPhoneNumber } from 'libphonenumber-js';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/mobile';

import { captureException } from '../sentry';

export const DEFAULT_PHONE_COUNTRY_CODE: CountryCode = 'AU';
export const LOWERCASED_DEFAULT_PHONE_COUNTRY_CODE =
  DEFAULT_PHONE_COUNTRY_CODE.toLowerCase() as Lowercase<CountryCode>;

export function formatPhoneNumber(
  phoneNumber: string | undefined | null,
  countryCode: CountryCode = DEFAULT_PHONE_COUNTRY_CODE,
): string {
  try {
    if (phoneNumber == null) {
      return '';
    }
    const parsedNumber = parsePhoneNumber(phoneNumber, countryCode);
    return parsedNumber.formatInternational();
  } catch (_error: unknown) {
    return phoneNumber || '--';
  }
}

declare const validE164Mobile: unique symbol;

export type E164Mobile = string & {
  [validE164Mobile]: true;
};

export function formatPhoneNumberToE164(
  input: string,
  countryCode: string = DEFAULT_PHONE_COUNTRY_CODE,
): E164Mobile | null {
  const parsedCountryCode = countryCode.toUpperCase() as CountryCode;

  if (input.trim() === '') {
    return null;
  }

  try {
    const phoneNumber = parsePhoneNumber(input, parsedCountryCode);
    if (!phoneNumber) {
      return null;
    }
    if (!phoneNumber.isValid()) {
      return null;
    }
    return phoneNumber.format('E.164') as E164Mobile;
  } catch (e: unknown) {
    captureException(
      'Error while formatting phone number to e164',
      { countryCode, input: input.slice(4) },
      e,
    );
    return null;
  }
}

export function isValidMobile(
  mobile: string,
  region: string = DEFAULT_PHONE_COUNTRY_CODE,
): boolean {
  try {
    return isValidPhoneNumber(mobile, region.toUpperCase() as CountryCode);
  } catch (e: unknown) {
    return false;
  }
}

export function parseNumber(
  mobile: string,
  region: CountryCode = DEFAULT_PHONE_COUNTRY_CODE,
): string {
  try {
    const parsedNumber = parsePhoneNumber(mobile, region);
    return parsedNumber.format('E.164');
  } catch (error: unknown) {
    return mobile || '';
  }
}
