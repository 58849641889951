import isFuture from 'date-fns/isFuture';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const isTokenExpiryDateInFuture = (token: string) => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const { exp } = decoded;

    // No point in creating complicated checks because isFuture should fail if
    // we don't have a number that can be parsed into a valid date
    // This is just to make TypeScript happy
    if (typeof exp === 'number') {
      return isFuture(new Date(exp * 1000));
    }
    return false;
  } catch (e: unknown) {
    // The token is probably not a valid JWT token
    // (can't decode or can't parse a date from exp value)
    return false;
  }
};
