import { SxProp, View } from 'dripsy';
import * as React from 'react';

type CardContentProps = {
  children?: React.ReactNode;
  sx?: SxProp;
};

export const CardContent = ({ children, sx = {} }: CardContentProps) => (
  <View
    sx={{
      p: '$16',
      ...sx,
    }}
  >
    {children}
  </View>
);
