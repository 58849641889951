import { Text, View } from 'dripsy';
import { FormikErrors } from 'formik';
import { Fragment } from 'react';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormMultilineTextInputV2,
} from '../../../components/form/FormikInputs';
import { InfoRow } from '../../../components/InfoRow';
import { Top_Up_Reason_Enum } from '../../../generated/graphql';
import { Screen } from '../../../navigation/types/screens';
import { FormikFormError } from '../../../ui/v2/FormError';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { DISPLAYED_TOP_UP_REASONS } from '../../utils/getTotalCashoutAmount';
import {
  SetupLoanFormWithLoanAmountField,
  SetupLoanFormWithLoanAmountInitialValues,
  SetupLoanTopUpFieldNameMap,
  SetupLoanYesNoEnum,
} from '../../utils/setupLoanFormHelper';
import { SectionHeading } from './SetupLoanFormWithLoanAmount';

type SetupLoanTopUpFormTopUpProps = {
  screen: Screen;
  values: SetupLoanFormWithLoanAmountInitialValues;
  errors: FormikErrors<SetupLoanFormWithLoanAmountInitialValues>;
  sumTopUpAmount: number;
};

export function SetupLoanTopUpFormTopUp({
  screen,
  values,
  errors,
  sumTopUpAmount,
}: SetupLoanTopUpFormTopUpProps) {
  return (
    <>
      <SectionHeading
        sectionTitle={t('Content.SetupLoanTopUp.TopUpSection.Title')}
        sectionValue={formatCurrency(sumTopUpAmount, {
          withFractionOnRoundedAmount: true,
        })}
      />
      <Text sx={{ mb: '$8', variant: 'text.sBody' }}>
        {t('Content.SetupLoanTopUp.TopUpSection.Subtitle')}
      </Text>

      <InfoRow message={t('Content.SetupLoanTopUp.TopUpSection.Caption')} />

      {values[SetupLoanFormWithLoanAmountField.HasTopUpAmount] ===
      SetupLoanYesNoEnum.Yes ? (
        <View>
          {DISPLAYED_TOP_UP_REASONS.map((topUpReason) => {
            const fieldName = SetupLoanTopUpFieldNameMap[topUpReason];
            const description =
              topUpReason === Top_Up_Reason_Enum.Other
                ? t('Content.TopUpReasonEnum.OTHER.descriptionV2')
                : t(`Content.TopUpReasonEnum.${topUpReason}.description`);
            return (
              <Fragment key={topUpReason}>
                <FormCurrencyInputV2
                  name={fieldName}
                  // Because we're using ghost field to validate whether any of the top up amount is filled
                  // We need to inject SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled's error state in this field
                  error={
                    errors[fieldName] ||
                    errors[
                      SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled
                    ]
                  }
                  label={t(`Content.TopUpReasonEnum.${topUpReason}.title`)}
                  sx={{ mt: '$16' }}
                  inputTestID={TestID.SetupLoanForm.TopUpFields[topUpReason]}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.YourUnloan,
                    screen,
                    FieldInteractionKey.Reason,
                  )}
                />
                <FormikFormError name={fieldName} sx={{ my: '$8' }} />
                <FormikFormError
                  name={
                    SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled
                  }
                  sx={{ my: '$8' }}
                />
                <Text sx={{ my: '$8' }} variant="caption">
                  {description}
                </Text>
              </Fragment>
            );
          })}
          {values[SetupLoanFormWithLoanAmountField.OtherTopUpAmount] ? (
            <>
              <FormMultilineTextInputV2
                name={SetupLoanFormWithLoanAmountField.OtherTopUpDescription}
                label={t(
                  'Content.SetupLoanTopUp.TopUpSection.OtherDescriptionLabel',
                )}
                sx={{ mt: '$8' }}
                inputTestID={TestID.SetupLoanForm.TopUpFields.OtherDescription}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.YourUnloan,
                  screen,
                  FieldInteractionKey.OtherDescription,
                )}
              />
              <FormikFormError
                name={SetupLoanFormWithLoanAmountField.OtherTopUpDescription}
                sx={{ mt: '$8' }}
              />
            </>
          ) : null}
        </View>
      ) : null}
    </>
  );
}
