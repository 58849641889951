import { A, Text, Theme, View } from 'dripsy';
import { useContext } from 'react';

import { TestID } from '../../../testID/constants';
import { ActionListButton } from '../../ActionSheet/components/ActionListButton';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import {
  INTERCOM_CUSTOM_LAUNCHER_SELECTOR,
  INTERCOM_LEGAL_NAME_CHANGE_SELECTOR,
} from '../../constants/intercom';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useIntercom } from '../../Home/navigation/utils/useIntercom';
import { NotFoundScreen } from '../../navigation/screens/NotFoundScreen';
import { Screen } from '../../navigation/types/screens';
import { SettingsScreenProps } from '../../Settings/navigation/types';
import { Card } from '../../ui/atoms/Card';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { CompoundText } from '../../ui/v2/Text';
import { TextLink } from '../../ui/v2/Text/TextLink';
import { TextList } from '../../ui/v2/TextList';

type LegalNameChangeBaseProps =
  SettingsScreenProps<Screen.PERSONAL_DETAILS_LEGAL_NAME_CHANGE>;

const BasicSeparator = ({
  my = '$16',
  spacer = false,
}: {
  my?: keyof Theme['space'];
  spacer?: boolean;
}) => (
  <View
    sx={{
      borderBottomColor: '$lightBorder',
      borderBottomWidth: spacer ? 0 : '$1',
      my,
    }}
  />
);

const DocumentTypeOne = () => (
  <>
    <View>
      <Text variant="body" sx={{ fontWeight: '500' }}>
        {
          t('Content.PersonalDetails.ChangeLegalName.DocumentTypeOne').split(
            t('Content.PersonalDetails.ChangeLegalName.One'),
          )[0]
        }
        <Text sx={{ fontWeight: '500', textDecorationLine: 'underline' }}>
          {t('Content.PersonalDetails.ChangeLegalName.One')}
        </Text>
        {
          t('Content.PersonalDetails.ChangeLegalName.DocumentTypeOne').split(
            t('Content.PersonalDetails.ChangeLegalName.One'),
          )[1]
        }
      </Text>
    </View>
    <TextList
      itemSx={{ my: '$2' }}
      ordered={false}
      variant="sBody"
      items={[
        t('Content.PersonalDetails.ChangeLegalName.ChangeOfNameCertificate'),
        t('Content.PersonalDetails.ChangeLegalName.MarriageCertificate'),
        t('Content.PersonalDetails.ChangeLegalName.BirthCertificate'),
        t('Content.PersonalDetails.ChangeLegalName.DecreeNisi'),
      ]}
    />
  </>
);

const DocumentTypeTwo = () => (
  <>
    <View>
      <Text variant="body" sx={{ fontWeight: '500' }}>
        {
          t('Content.PersonalDetails.ChangeLegalName.DocumentTypeTwo').split(
            t('Content.PersonalDetails.ChangeLegalName.Two'),
          )[0]
        }
        <Text sx={{ fontWeight: '500', textDecorationLine: 'underline' }}>
          {t('Content.PersonalDetails.ChangeLegalName.Two')}
        </Text>
        {
          t('Content.PersonalDetails.ChangeLegalName.DocumentTypeTwo').split(
            t('Content.PersonalDetails.ChangeLegalName.Two'),
          )[1]
        }
      </Text>
    </View>
    <TextList
      itemSx={{ my: '$4' }}
      ordered={false}
      variant="xsHeader"
      items={[
        <>
          <Text variant="xsHeader">
            {t('Content.PersonalDetails.ChangeLegalName.DriversLicense')}
          </Text>
          <Text variant="sBody">
            {t(
              'Content.PersonalDetails.ChangeLegalName.DriversLicenseDescription',
            )}
          </Text>
        </>,
        <>
          <Text variant="xsHeader">
            {t('Content.PersonalDetails.ChangeLegalName.Passport')}
          </Text>
          <Text variant="sBody">
            {t('Content.PersonalDetails.ChangeLegalName.PassportDescription')}
          </Text>
        </>,
        <>
          <Text variant="xsHeader">
            {t('Content.PersonalDetails.ChangeLegalName.UtilitiesNotice')}
          </Text>
          <Text variant="sBody">
            {t(
              'Content.PersonalDetails.ChangeLegalName.UtilitiesNoticeDescription',
            )}
          </Text>
        </>,
      ]}
    />
  </>
);

const LegalNameChangeBase = ({ navigation }: LegalNameChangeBaseProps) => {
  const intercom = useIntercom();

  const { flags } = useContext(FeatureFlagsContext);

  const changeLegalNameEnabled = flags.ENABLE_UPDATE_LEGAL_NAME;

  if (!changeLegalNameEnabled) {
    return <NotFoundScreen />;
  }

  return (
    <UpliftScreenContainer
      onPressBack={navigation.goBack}
      testID={TestID.PersonalDetails.ChangeLegalNameScreen}
    >
      <View sx={{ mt: '$8' }}>
        <Text variant="header" sx={{ py: '$8', mb: '$24' }}>
          {t('Content.PersonalDetails.ChangeLegalName.Title')}
        </Text>
      </View>

      <Text variant="body" sx={{ py: '$8' }}>
        {t('Content.PersonalDetails.ChangeLegalName.Intro')}
      </Text>
      <Card p="m">
        <TextList
          separator={<BasicSeparator />}
          items={[
            <DocumentTypeOne key="document-type-one" />,
            <DocumentTypeTwo key="document-type-two" />,
          ]}
        />
      </Card>

      <View
        sx={{
          py: '$16',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <CompoundText variant="sSubHeading">
          {t('Content.PersonalDetails.ChangeLegalName.FinalStep')}
        </CompoundText>
        <TextLink
          variant="link"
          href="#"
          nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
          onPress={intercom.presentMessageComposer}
          sx={{
            ml: -4,
          }}
        >
          {t('Content.PersonalDetails.ChangeLegalName.ChatToUs')}
        </TextLink>
      </View>

      <Text variant="body">
        <A
          href={t('Link.LearnMoreLegalNameChange')}
          hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
          variant="link"
        >
          {t('Content.PersonalDetails.ChangeLegalName.LearnMore')}
        </A>
      </Text>
      <BasicSeparator spacer my="$24" />
      <View sx={{ mt: '$16' }} nativeID={INTERCOM_LEGAL_NAME_CHANGE_SELECTOR}>
        <ActionListButton
          secondary
          maxWidth={600}
          onPress={intercom.presentMessageComposer}
          label={t('Content.PersonalDetails.ChangeLegalName.ChatWithUs')}
        />
      </View>
    </UpliftScreenContainer>
  );
};

export const WithAuthenticationLegalNameChange =
  withAuthenticationRequired(LegalNameChangeBase);
