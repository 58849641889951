import { DependencyList, useEffect } from 'react';

/**
 * A helper function, which wraps useEffect, so that it
 * only fires once within a given delay time frame.
 *
 * @link
 *  Reference: https://stackoverflow.com/a/61127960
 * @example
 *  const [loan, setLoan] = useState()
 *  useDebouncedEffect(
 *    () => console.log(loan),
 *    [loan],
 *    1_000
 *  );
 */
export const useDebouncedEffect = (
  effect: { (): void; (): void },
  deps: DependencyList,
  delay?: number,
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- To avoid triggering useEffect on every render. See: https://stackoverflow.com/a/61127960
  }, [...(deps || []), delay]);
};
