import * as React from 'react';

import { Box, BoxProps } from './Box';
import { StyledText } from './StyledText';

type Props = BoxProps & {
  copyrightText: string;
};

export const Copyright: React.FC<Props> = ({ copyrightText, ...props }) => (
  <Box p="xl" centered {...props}>
    <StyledText variant="footer" textAlign="center">
      {`${new Date().getFullYear()} © ${copyrightText}`}
    </StyledText>
  </Box>
);
