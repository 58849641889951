import { Text, View } from 'dripsy';
import { ComponentProps } from 'react';

import { SvgIcon } from '../ui/svgs/SvgIcon';

type Props = ComponentProps<typeof View> & {
  message?: string;
};

const iconSize = 16;
export function InlineInfoRow({ message, children, sx, ...props }: Props) {
  const mergedSx: Props['sx'] = {
    pl: iconSize + 4,
    ...sx,
  };

  return (
    <View sx={mergedSx} {...props}>
      <SvgIcon
        name="infoFilled"
        color="secondaryV2"
        size={iconSize}
        style={{
          position: 'absolute',
          left: 0,
        }}
      />
      {message != null ? <Text variant="caption">{message}</Text> : children}
    </View>
  );
}
