import {
  ContainerInput,
  Frequency_Enum,
  InstitutionAccountDetailsFragment,
  InstitutionAccountType,
} from '../generated/graphql';
import {
  formatCurrency,
  formatCurrencyWithPeriod,
} from '../utils/currencyHelpers';
import { ensureField } from '../utils/ensureField';
import { formatLoanTerm } from '../utils/stringHelpers';

export const ACCOUNT_LABEL_LIST = {
  [InstitutionAccountType.PersonalLoan]: 'Personal Loan',
  [InstitutionAccountType.Credit]: 'Credit Card',
  [InstitutionAccountType.HomeLoan]: 'Home Loan',
  [InstitutionAccountType.Checking]: 'Transaction Account',
  [InstitutionAccountType.LineOfCredit]: 'Line of Credit',
  [InstitutionAccountType.Mortgage]: 'Mortgage',
  [InstitutionAccountType.Store]: 'Store Card',
  [InstitutionAccountType.Savings]: 'Savings',
  [InstitutionAccountType.Cd]: 'Cd',
};

export type FieldConfigList = {
  [K in keyof Omit<InstitutionAccountDetailsFragment, 'container'>]: {
    label: string;
    formatValue: (val: InstitutionAccountDetailsFragment[K]) => string;
    hideField?: (container: ContainerInput) => boolean;
  };
};

const FieldConfigs: FieldConfigList = {
  accountNumber: {
    label: 'Account Number',
    formatValue: (val) => ensureField(val),
  },
  balance: {
    label: 'Balance',
    formatValue: (val) => ensureField(formatCurrency(val?.amount || 0)),
  },
  // Expiry
  totalCreditLine: {
    label: 'Limit',
    formatValue: (val) => ensureField(formatCurrency(val?.amount || 0)),
    hideField: (container: ContainerInput) =>
      container !== ContainerInput.CreditCard,
  },
  interestRate: {
    label: 'Interest Rate',
    formatValue: (val) => `${ensureField(val)}% pa`,
    hideField: (container: ContainerInput) => container !== ContainerInput.Loan,
  },
  interestRateType: {
    label: 'Rate Type',
    formatValue: (val) => ensureField(val),

    hideField: (container: ContainerInput) => container !== ContainerInput.Loan,
  },
  termInMonths: {
    label: 'Loan Term',
    formatValue: (val) => ensureField(formatLoanTerm(val, true)),
    hideField: (container: ContainerInput) => container !== ContainerInput.Loan,
  },
  //   TODO Map these types into our supported types https://developer.yodlee.com/Yodlee_API/docs/v1_1/Data_Model/Resource_Account#Loan_Repayment_Plan_Types
  repaymentType: {
    label: 'Repayment Type',
    hideField: (container: ContainerInput) => container !== ContainerInput.Loan,
    formatValue: (val) => ensureField(val),
  },
  minimumAmountDue: {
    label: 'Min. Repayment',
    formatValue: (v) =>
      formatCurrencyWithPeriod(v?.amount || 0, Frequency_Enum.Monthly),
    hideField: (container: ContainerInput) =>
      container !== ContainerInput.Loan &&
      container !== ContainerInput.CreditCard,
  },
};

export const getFieldConfigList = (
  container: ContainerInput | null | undefined,
): FieldConfigList => {
  if (!container) {
    return {};
  }
  let filteredConfigs: FieldConfigList = {};
  const fieldConfigKeys = Object.keys(FieldConfigs) as Array<
    keyof FieldConfigList
  >;
  fieldConfigKeys.forEach((key) => {
    const config = FieldConfigs[key];

    if (!config?.hideField?.(container)) {
      filteredConfigs = { ...filteredConfigs, [key]: config };
    }
  });
  return filteredConfigs;
};
