import { useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Screen } from '../../navigation/types/screens';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { AutopaySetup } from './AutopaySetup';
import { AutopaySetupV2 } from './AutopaySetupV2';

export type AutopaySetupModalProps =
  AutopaySettingsScreenProps<Screen.AUTOPAY_SETUP>;

export function AutopaySetupModal({
  navigation,
  route,
}: AutopaySetupModalProps) {
  const { flags } = useContext(FeatureFlagsContext);

  return flags.ENABLE_AUTOPAY_MODAL_V2 ? (
    <AutopaySetupV2 navigation={navigation} route={route} />
  ) : (
    <AutopaySetup navigation={navigation} route={route} />
  );
}
