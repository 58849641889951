import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { DataSharingConfirmationModalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { TranslucentModalNavOptions } from '../../utils/NavConstants';
import { DataSharingCurrentStatus, DataSharingSummary } from '../screens';
import { DataSharingConfirmation } from '../screens/DataSharingConfirmation';

const DataSharingConfirmationModalStack =
  createStackNavigator<DataSharingConfirmationModalStackParams>();

export const DataSharingConfirmationModalNavigator: React.FC = () => (
  <DataSharingConfirmationModalStack.Navigator
    screenOptions={TranslucentModalNavOptions}
  >
    <DataSharingConfirmationModalStack.Screen
      name={Screen.DATA_SHARING_CURRENT_STATUS}
      component={DataSharingCurrentStatus}
      options={{
        title: getPageTitle(Screen.DATA_SHARING_CURRENT_STATUS),
        headerTransparent: true,
      }}
    />
    <DataSharingConfirmationModalStack.Screen
      name={Screen.DATA_SHARING_SUMMARY}
      component={DataSharingSummary}
      options={{
        title: getPageTitle(Screen.DATA_SHARING_SUMMARY),
        headerShown: false,
      }}
    />
    <DataSharingConfirmationModalStack.Screen
      name={Screen.DATA_SHARING_CONFIRMATION}
      component={DataSharingConfirmation}
      options={{
        title: getPageTitle(Screen.DATA_SHARING_CONFIRMATION),
        headerShown: false,
      }}
    />
  </DataSharingConfirmationModalStack.Navigator>
);
