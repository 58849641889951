import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryCreditCard = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryCreditCard');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        d="M33.094 7.5H2.906c-1.19 0-1.406.215-1.406 1.406v19.688c0 1.19.215 1.406 1.406 1.406h30.188c1.19 0 1.406-.215 1.406-1.406V8.906c0-1.19-.215-1.406-1.406-1.406Z"
        fill={`url(#${idPrefix})`}
      />
      <Path d="M34.5 10.5h-33v3h33v-3Z" fill="#2C2C2C" />
      <Path
        d="M31.5 18h-27a.75.75 0 0 1-.75-.75v-1.5A.75.75 0 0 1 4.5 15h27a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-.75.75Z"
        fill="#A3A3A3"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={1.5}
          y1={7.5}
          x2={22.445}
          y2={38.219}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
