import { A, Box, Image } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';
import { LoanScreenHeader } from '../../LoanApplication/components/LoanScreenHeader';
import { StyledText } from '../../ui/atoms/StyledText';

type Props = {
  onYourApplicationPress: () => void;
  imageHeight: number;
};

export function DeclinedConditionalApproval({
  imageHeight,
  onYourApplicationPress,
}: Props) {
  return (
    <>
      <LoanScreenHeader
        title={t('Content.ConditionalApproval.Header.BorrowingPowerTitle')}
        caption={t('Content.ConditionalApproval.Header.DeclinedCaption')}
        learnMoreText={t('Content.ConditionalApproval.Header.YourApplication')}
        learnMoreTestId={
          TestID.LoanApplicationConditionalApproval.YourApplication
        }
        onLearnMorePress={onYourApplicationPress}
      />
      <StyledText mt="l" textAlign="center">
        {t('Content.ConditionalApproval.Content.PreferToChat')}
        <A
          variant="link"
          testID={TestID.LoanApplicationConditionalApproval.LetsTalk}
          sx={{ cursor: 'pointer' }}
        >
          {/*
           * Just like what has been explained in `apps/unloan-app/src/components/ChatButton.tsx`
           * GTM can only catch the innermost element's nativeID.
           * So we need to create a box with nativeID which will trigger intercom launcher.
           */}
          <Box
            nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
            style={{
              zIndex: 100,
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
          />
          {t('Content.ConditionalApproval.Content.LetsTalk')}
        </A>
      </StyledText>

      <Image
        source={require('../../../assets/hero-image-652x421.png')}
        sx={{ width: '100%', height: imageHeight }}
        resizeMode="contain"
        testID={TestID.LoanApplicationConditionalApproval.Illustration}
      />
    </>
  );
}
