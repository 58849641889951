import { Text, TextInput as DripsyTextInput, ThemeColorName } from 'dripsy';
import { forwardRef, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { MaxCharacter } from '../../constants/fieldRules';
import { isWeb } from '../../utils/platformUtils';
import { InputRow } from './InputRow';
import { TextInputV2Props } from './TextInput';

export type MultilineTextInputV2Props = TextInputV2Props;

const focusedDataset = isWeb ? { focused: 'true' } : undefined;
export const MultilineTextInput = forwardRef<
  RNTextInput,
  MultilineTextInputV2Props
>(
  (
    {
      label,
      value,
      inputTestID,
      containerTestID,
      disabled,
      sx: sxProp,
      // These are provided by Formik
      error,
      focused,
      onBlur,
      onFocus,
      onChangeText,
      // These should go to RN text input
      maxLength,
      keyboardType,
    }: MultilineTextInputV2Props,
    ref,
  ) => {
    const [labelId] = useState(() => uuidv4());

    let borderColor: ThemeColorName = '$border';
    let labelColor: ThemeColorName = '$secondary';
    let bgColor: ThemeColorName = '$inputBackground';
    let valueColor: ThemeColorName = '$labelsPrimary';
    if (error) {
      borderColor = '$error';
      labelColor = '$error';
    }
    if (focused) {
      borderColor = '$focus';
    }
    if (disabled) {
      labelColor = '$secondaryDisabled';
      bgColor = '$inputBackgroundDisabled';
      valueColor = '$secondary';
    }

    const smallLabel = focused || (value && String(value).length > 0);

    const py = 8;
    return (
      <InputRow
        sx={{
          bg: bgColor,
          px: '$16',
          borderColor,
          justifyContent: 'flex-start',
          py: smallLabel ? py : 15,
          ...sxProp,
        }}
        testID={containerTestID}
        dataSet={focused ? focusedDataset : undefined}
      >
        <Text
          variant={smallLabel ? 'tiny' : 'default'}
          sx={{
            color: labelColor,
            position: 'absolute',
            width: 'full',
            ...(smallLabel ? { top: py } : { zIndex: -1 }),
          }}
          numberOfLines={1}
          nativeID={labelId}
          selectable={false}
        >
          {label}
        </Text>
        <DripsyTextInput
          sx={{
            variant: 'text.default',
            color: valueColor,
            mt: '$16',
            minHeight: 'multilineInputRow.minHeight',
          }}
          multiline
          value={value}
          editable={!disabled}
          maxLength={maxLength || MaxCharacter.multilineTextInput}
          keyboardType={keyboardType}
          onChangeText={onChangeText}
          onBlur={onBlur}
          onFocus={onFocus}
          underlineColorAndroid="transparent"
          // This is a browser specific fix for Chrome only.
          //  see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error `chrome-off` is a web only enum and
          // our RN types override stopped working since Expo 49 upgrades,
          // see `@types/react-native.d.ts` for existing overrides.
          autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
          testID={inputTestID}
          ref={ref}
          accessibilityLabelledBy={labelId}
        />
      </InputRow>
    );
  },
);

MultilineTextInput.displayName = 'MultilineTextInputV2';
