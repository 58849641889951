import { format } from 'date-fns';
import { useCallback } from 'react';

import {
  Autopay_Frequency_Input_Enum,
  useCreateAutopayChangeRequestMutation,
} from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';

const transformToAutopayFrequencyInputEnum = (
  frequency: Autopay_Frequency_Input_Enum,
): Autopay_Frequency_Input_Enum => {
  switch (frequency) {
    case Autopay_Frequency_Input_Enum.Weekly:
      return Autopay_Frequency_Input_Enum.Weekly;
    case Autopay_Frequency_Input_Enum.Fortnightly:
      return Autopay_Frequency_Input_Enum.Fortnightly;
    case Autopay_Frequency_Input_Enum.Monthly:
    default:
      return Autopay_Frequency_Input_Enum.Monthly;
  }
};

export type ConfirmAutopayChangeRequestProps = {
  repaymentFrequency: Autopay_Frequency_Input_Enum;
  repaymentAmount: number;
  paymentStartDate: Date;
  externalAccountId: string;
  isMinimumAmount: boolean;
};

export type UseConfirmAutopayProps = {
  screen: Screen | ActionSheetType;
  cbaAccountId: string;
};

export const useConfirmAutopay = ({
  screen,
  cbaAccountId,
}: UseConfirmAutopayProps) => {
  const [submitAutopayChangeRequest, { loading, error }] =
    useCreateAutopayChangeRequestMutation();

  const confirmAutopay = useCallback(
    async (autopayChangeRequest: ConfirmAutopayChangeRequestProps) => {
      const {
        repaymentAmount,
        externalAccountId,
        paymentStartDate,
        repaymentFrequency,
        isMinimumAmount,
      } = autopayChangeRequest;

      const [res, err] = await safelyCallMutation(submitAutopayChangeRequest, {
        variables: {
          cba_account_id: cbaAccountId,
          repayment_amount: repaymentAmount,
          first_repayment_date: format(paymentStartDate, 'yyyy-MM-dd'),
          frequency: transformToAutopayFrequencyInputEnum(repaymentFrequency),
          external_account_id: externalAccountId,
          is_minimum_amount: isMinimumAmount,
        },
        context: { sentryContext: { cbaAccountId } },
      });

      if (err) {
        // Mutation failed, no point going further.
        // The error should get populated by the mutation hook
        return null;
      }

      const autopayChangeRequestId =
        res?.data?.create_autopay_change_request?.returning?.id;

      if (res && !autopayChangeRequestId) {
        // This is something unexpected: the id was not returned but the query
        // did not error. Was the query changed not to return the id?
        // Was something renamed?
        captureException(
          'Submitted Autopay Setup Request Return Id Not Found',
          {
            cbaAccountId,
            screen,
            description:
              'Unexpected error: Submitted autopay setup request did not return an id',
          },
        );
      }

      return autopayChangeRequestId ?? null;
    },
    [submitAutopayChangeRequest, cbaAccountId, screen],
  );

  return { confirmAutopay, loading, error };
};
