import { useNavigateToLoanApplicationScreen } from '../../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../../navigation/types/navTypes';
import { Screen } from '../../../navigation/types/screens';
import { useAppSummaryScreenNavigation } from '../../../utils/hooks/useAppSummaryScreenNavigation';
import { SetupLoanV2Base } from '../base/SetupLoanScreenV2Base';

type Props = SingleModalStackScreenProps<Screen.SETUP_LOAN_V2_MODAL>;

export function SetupLoanStandaloneModalV2({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;
  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourUnloan = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.YourUnloan,
      }),
    );
  };

  const navigateToSelectRefiReason = () =>
    navigation.navigate(Screen.SINGLE_V2_MODAL, {
      screen: Screen.SELECT_REFI_REASON_V2_MODAL,
      params: {
        loanApplicationId: route.params?.loanApplicationId,
        loanApplicationTargetId: route.params?.loanApplicationTargetId,
      },
    });

  const navigateToYourDebts = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Debts,
    });
  };

  return (
    <SetupLoanV2Base
      screen={Screen.SETUP_LOAN_V2_MODAL}
      showSelectRefiReason
      hideBackButton
      navigateToSelectRefiReason={navigateToSelectRefiReason}
      onSubmitSuccess={navigateToYourUnloan}
      loanApplicationId={route.params?.loanApplicationId}
      loanApplicationTargetId={route.params?.loanApplicationTargetId}
      onClose={navigateToYourUnloan}
      navigateToYourDebts={navigateToYourDebts}
    />
  );
}
