import { Box, BoxProps } from '../../ui/atoms/Box';
import { FlexSlider, FlexSliderProps } from '../../ui/molecules/FlexSlider';
import { ListRow } from '../../ui/molecules/ListRow';
import { getTermPickerLabel } from '../../utils/pickerHelpers';
import { FormikControlProps } from './types';

export type TermSliderRowProps = FormikControlProps<number> &
  BoxProps &
  FlexSliderProps & {
    label: string;
    valueTestID: string;
  };

export function TermSliderRow({
  min,
  max,
  step,
  setFieldValue,
  /**
   * The value should be in years
   */
  value,
  label,
  valueTestID,
  ...otherProps
}: TermSliderRowProps) {
  return (
    <Box borderRadius="card" bg="shapeBg" {...otherProps}>
      <ListRow
        label={label}
        placeholder={getTermPickerLabel(value != null ? value * 12 : undefined)}
        placeholderColor="link"
        placeholderTestId={valueTestID}
        last
      />
      <Box mx="m" py="s" overflow="visible">
        <FlexSlider
          value={value}
          onValueChange={setFieldValue}
          min={min}
          max={max}
          step={step}
        />
      </Box>
    </Box>
  );
}
