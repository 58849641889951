import * as React from 'react';

import { Box, BoxProps } from '../atoms/Box';

export type SegmentedProgressProps = BoxProps & {
  current?: number;
  total: number;
};

export const SegmentedProgress: React.FC<SegmentedProgressProps> = ({
  current = 0,
  total,
  ...otherProps
}) => {
  const circles = [];
  for (let i = 1; i <= total; i += 1) {
    const circle = <Circle key={i} active={i <= current} />;
    circles.push(circle);
  }
  return (
    <Box row alignSelf="center" {...otherProps}>
      {circles}
    </Box>
  );
};

type CircleProps = {
  active: boolean;
  size?: number;
};
const Circle: React.FC<CircleProps> = ({ active, size = 7 }) => (
  <Box
    width={size}
    height={size}
    borderRadius="rounded"
    bg={active ? 'primaryContent' : 'disabledContent'}
    mx="xs"
  />
);
