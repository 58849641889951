import { HeaderBackButton } from '@react-navigation/elements';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import { TestID } from '../../testID/constants';
import { HeaderButton } from '../ui/atoms/HeaderButton';
import { spacing } from '../ui/theme';

/**
 * @deprecated This use chevron (<) and used only in v1.
 * See v2/BackButton for v2 which use arrow with tail (←).
 */
export const BackButton: React.FC<
  React.ComponentProps<typeof HeaderBackButton>
> = (props) => (
  <HeaderButton
    position="left"
    iconName="chevronLeft"
    accessibilityLabel="back"
    iconFamily="svg"
    testID={TestID.BackButton}
    style={styles.button}
    {...props}
  />
);

const styles = StyleSheet.create({
  button: { paddingHorizontal: spacing.s },
});
