import { ActivityIndicator, StyleSheet, View } from 'react-native';

export function AppLoading() {
  return (
    <View style={styles.root}>
      <ActivityIndicator size="large" />
    </View>
  );
}

const styles = StyleSheet.create({
  root: { flex: 1, justifyContent: 'center', alignItems: 'center' },
});
