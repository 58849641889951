import { Text } from 'dripsy';

import { TestID } from '../../../../testID/constants';

export const Disclaimer = () => (
  <Text
    variant="caption"
    testID={TestID.Insights.InterestSavings.Disclaimer}
    sx={(theme) => ({
      marginTop: theme.space.$24,
    })}
  >
    {t('Content.HomeLoan.Insights.TotalInterestSavings.Disclaimer')}
  </Text>
);
