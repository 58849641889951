import { gql } from '@apollo/client';
import { useDripsyTheme } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { AutopayDetailListGroup } from '../../AutopaySettings/components/AutopayDetailListGroup';
import { formatUnloanAccountSubtitle } from '../../AutopaySettings/utils/formatUnloanAccountSubtitle';
import { isAutopayChangeRequestExpired } from '../../AutopaySettings/utils/isAutopayChangeRequestExpired';
import { ErrorFallback } from '../../components/ErrorFallback';
import {
  Approval_Status_Enum,
  ApprovalRequestNotificationFragment,
  AutopayApprovalRequestFragment,
  Change_Request_Status_Enum,
  useCancelRequestMutation,
} from '../../generated/graphql';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { Skeleton } from '../../ui/v2/Skeleton';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ApprovalControls } from './ApprovalControls';
import { ApprovalStatusListGroup } from './ApprovalStatusListGroup';

export const AutopayApprovalRequestFragmentDef = gql`
  fragment AutopayApprovalRequest on approval_request {
    autopay_change_request {
      id
      cba_account_id
      status
      repayment_amount
      first_repayment_date
      frequency
      expires_at
      external_account {
        id
        masked_account_number
        account_name
        account_bsb
        account_number
      }
    }
  }
`;

type AutopayChangeRequest = NonNullable<
  AutopayApprovalRequestFragment['autopay_change_request']
>;

export const getAutopayChangeRequestStatusLabel = (
  autopayChangeRequest?: AutopayChangeRequest,
) => {
  if (!autopayChangeRequest) {
    return '';
  }

  switch (autopayChangeRequest?.status) {
    case Change_Request_Status_Enum.Pending:
      return t('Content.Approvals.Processing');
    case Change_Request_Status_Enum.Applied:
      return t('Content.Approvals.Processed');
    case Change_Request_Status_Enum.Failed:
      return t('Content.Approvals.Failed');
    default:
      throw new Error(`Unknown autopay change request status`);
  }
};

export type AutopayApprovalRequest = ApprovalRequestNotificationFragment & {
  autopay_change_request: AutopayChangeRequest;
};

export const ApprovalAutopay: React.FC<{
  loading: boolean;
  approvalRequest: AutopayApprovalRequest;
  onDismiss: () => void;
}> = ({ loading: initialLoading, approvalRequest, onDismiss }) => {
  const { data: loanAccountNameData, loading: loanAccountNameLoading } =
    useGetLoanAccountName(
      approvalRequest.autopay_change_request.cba_account_id,
    );

  const loading = initialLoading || loanAccountNameLoading;

  const { accountName = '', bsbJoinedWithAccountNumber = '' } =
    loanAccountNameData ?? {};

  const autopayChangeRequest = approvalRequest.autopay_change_request;
  const isApproved = approvalRequest?.status === Approval_Status_Enum.Approved;

  const isExpired = autopayChangeRequest
    ? isAutopayChangeRequestExpired(autopayChangeRequest)
    : false;

  const statusListGroup = React.useMemo(() => {
    if (autopayChangeRequest && isApproved) {
      return (
        <DataRowGroup>
          <DataRow
            loading={loading}
            label={t('Content.Approvals.Status')}
            caption={getAutopayChangeRequestStatusLabel(autopayChangeRequest)}
          />
        </DataRowGroup>
      );
    }

    return (
      <ApprovalStatusListGroup
        loading={loading}
        approvalRequest={approvalRequest}
        expiryWarningText={t('Content.AutopaySettings.ExpiryWarning')}
      />
    );
  }, [approvalRequest, autopayChangeRequest, isApproved, loading]);

  const [cancelRequest, { loading: dismissInFlight }] =
    useCancelRequestMutation();

  const dismissExpiredRequest = useCallback(async () => {
    if (!approvalRequest) {
      return;
    }

    const approvalRequestId = approvalRequest?.id;
    const cbaAccountId = autopayChangeRequest.cba_account_id;

    await safelyCallMutation(cancelRequest, {
      variables: {
        approval_request_id: approvalRequest?.id,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          approvalRequestId,
        },
      },
    });

    onDismiss();
  }, [
    approvalRequest,
    autopayChangeRequest?.cba_account_id,
    cancelRequest,
    onDismiss,
  ]);

  const { theme } = useDripsyTheme();

  if (autopayChangeRequest && isExpired) {
    return (
      <ErrorFallback
        title={t('Content.AutopaySettings.ExpiredTitle')}
        caption={t('Content.AutopaySettings.ExpiredPleaseRetry', {
          firstRepaymentDate: safelyFormatDate(
            autopayChangeRequest.first_repayment_date,
            'EEE d MMM',
          ),
        })}
        hideUnloanLogo
        bg="transparent"
      >
        <Box flexDirection="column" flexGrow={1}>
          <Box
            mt="l"
            mx="m"
            flexDirection="row"
            bottom={0}
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Button
              secondary
              label={t('Content.AutopaySettings.Dismiss')}
              color="error"
              flexGrow={1}
              mr="s"
              onPress={dismissExpiredRequest}
              showSpinner={dismissInFlight}
              disabled={dismissInFlight}
            />
          </Box>
        </Box>
      </ErrorFallback>
    );
  }

  return (
    <>
      {loading ? <Skeleton show height={theme.space.$20} width={240} /> : null}
      {!loading && (
        <StyledText variant="body">
          {formatUnloanAccountSubtitle({
            accountName,
            bsbJoinedWithAccountNumber,
          })}
        </StyledText>
      )}

      {statusListGroup}
      <AutopayDetailListGroup
        loading={loading}
        repaymentAmount={autopayChangeRequest?.repayment_amount}
        accountName={accountName}
        bsbJoinedWithAccountNumber={bsbJoinedWithAccountNumber}
        externalAccountName={
          autopayChangeRequest?.external_account?.account_name
        }
        externalAccountBsb={autopayChangeRequest?.external_account?.account_bsb}
        externalAccountNumber={
          autopayChangeRequest?.external_account?.account_number
        }
        firstRepaymentDate={autopayChangeRequest?.first_repayment_date}
        frequency={autopayChangeRequest?.frequency}
      />
      <ApprovalControls loading={loading} approvalRequest={approvalRequest} />
    </>
  );
};
