export const generateGreeting = (now: Date): string => {
  const currentHour = now.getHours();
  if (currentHour < 3 || currentHour >= 16) {
    return t('Content.Home.Greeting.Evening');
  }
  if (currentHour >= 12) {
    return t('Content.Home.Greeting.Afternoon');
  }
  return t('Content.Home.Greeting.Morning');
};
