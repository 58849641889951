export const SessionIdKey = 'customerSessionId';

export function getCustomerSessionId(): string | undefined {
  return sessionStorage.getItem(SessionIdKey) ?? undefined;
}

export function setCustomerSessionId(sessionId: string): void {
  sessionStorage.setItem(SessionIdKey, sessionId);
}

export function clearCustomerSessionId(): void {
  sessionStorage.removeItem(SessionIdKey);
}
