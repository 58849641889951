/* eslint-disable no-nested-ternary */
import { A, H1, SxProp, Text, useSx, View } from 'dripsy';
import { Children, cloneElement, useMemo } from 'react';
import { Dimensions } from 'react-native';
import Markdown, { RenderRules } from 'react-native-markdown-display';

import ContentJson from '../../../assets/newFeatures/content.json';
import { StyledImage } from '../../ui/atoms/StyledImage';

export const createFeatureMarkdownRules = (
  sxProps: SxProp = { textAlign: 'center' },
): RenderRules => ({
  strong: function MarkdownStrong(node, children, _, styles) {
    return (
      <Text key={node.key} variant="xsHeader" style={styles.strong}>
        {children}
      </Text>
    );
  },
  textgroup: function MarkdownTextGroup(node, children, _, styles) {
    const sx = useSx();

    return (
      <Text
        key={node.key}
        variant="sBody"
        style={[styles.textgroup, sx(sxProps)]}
      >
        {children}
      </Text>
    );
  },
  paragraph: function MarkdownParagraph(node, children) {
    const sx = useSx();

    return (
      <View key={node.key} style={sx(sxProps)}>
        {children}
      </View>
    );
  },

  link: function MarkdownLink(node, children) {
    // This is needed as "children" already has a hard-coded style with "textDecorationLine: 'underline'"
    const content = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const child = Children.toArray(children)[0] as any;
      if (!child || typeof child !== 'object') {
        return children;
      }

      return cloneElement(child, {
        ...child.props,
        style: {},
      });
    }, [children]);

    return (
      <A
        key={node.key}
        variant="link"
        sx={{ textDecorationLine: 'none' }}
        href={node.attributes.href}
        hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
      >
        {content}
      </A>
    );
  },
});

const rules = createFeatureMarkdownRules();

// https://reactnative.dev/docs/images
export const getFeatureProps = (key: string) => {
  switch (key) {
    case 'appstore':
      return {
        source: require('../../../assets/newFeatures/images/appstore.jpg'),
      };

    case 'account_permissions':
      return {
        source: require('../../../assets/newFeatures/images/account_permissions.jpg'),
      };

    case 'buy_a_home':
      return {
        source: require('../../../assets/newFeatures/images/buy_a_home.jpg'),
      };

    case 'statements':
      return {
        source: require('../../../assets/newFeatures/images/statements.jpg'),
      };

    default:
      return null;
  }
};

const DEFAULT_IMAGE_SIZE = 335;

type FeatureItemProps = {
  index: number;
  feature: (typeof ContentJson.features)[0];
};

function FeatureItem({ index, feature }: FeatureItemProps) {
  const sx = useSx();

  const imageProps = useMemo(() => getFeatureProps(feature.key), [feature.key]);

  const maxImageSize = useMemo(() => Dimensions.get('window').width - 32, []);

  const sizeProps = useMemo(
    () => ({
      width: DEFAULT_IMAGE_SIZE,
      height: DEFAULT_IMAGE_SIZE,
      maxWidth: maxImageSize,
      maxHeight: maxImageSize,
    }),
    [maxImageSize],
  );

  return (
    <View sx={{ mt: index > 0 ? '$40' : undefined }}>
      {imageProps ? (
        feature.image_cta?.url ? (
          <A
            href={feature.image_cta.url}
            hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
            sx={{
              ...sizeProps,
              flex: 1,
              alignSelf: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StyledImage
              contentFit="contain"
              style={sx({
                flex: 1,
                mb: '$16',
                borderRadius: '$card',
              })}
              {...imageProps}
              {...sizeProps}
            />
          </A>
        ) : (
          <View
            sx={{
              ...sizeProps,
              alignSelf: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StyledImage
              contentFit="contain"
              style={sx({
                mb: '$16',
                borderRadius: '$card',
              })}
              {...imageProps}
              {...sizeProps}
            />
          </View>
        )
      ) : null}

      {feature.title ? (
        <Text variant="sHeader" sx={{ textAlign: 'center', mb: '$8' }}>
          {feature.title}
        </Text>
      ) : null}

      <View sx={{ alignSelf: 'center', textAlign: 'center' }}>
        <Markdown rules={rules}>{feature.supportingText}</Markdown>
      </View>
    </View>
  );
}

export type BaseNewFeaturesProps = {
  content: typeof ContentJson;
};

export function BaseNewFeatures({ content }: BaseNewFeaturesProps) {
  return (
    <>
      <Text variant="body" sx={{ textAlign: 'center' }}>
        {content.modal.subTitle}
      </Text>
      <H1 variant="lHeader" sx={{ textAlign: 'center', my: '$8' }}>
        {content.modal.title}
      </H1>
      <Text variant="sBody" sx={{ textAlign: 'center' }}>
        {content.modal.supportingText}
      </Text>

      <View sx={{ mt: '$32', px: '$16' }}>
        {content.features.map((feature, index) => (
          <FeatureItem key={feature.key} index={index} feature={feature} />
        ))}
      </View>
    </>
  );
}
