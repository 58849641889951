import { isEqual } from 'lodash';
import { useState } from 'react';

import { AppAddressFormat } from '../../../components/form/types';
import {
  Country_Enum,
  Domain_Api_Address_Input,
  Manual_Address_Input,
  refetchGetBorrowerDetailQuery,
  refetchGetLoanApplicantsQuery,
  UpdateUserMailingAddressMutationVariables,
  useUpdateUserMailingAddressMutation,
} from '../../../generated/graphql';
import { safelyCallMutation } from '../../../utils/hooks/errorUtils';
import { parseAddressInputForMutationAction } from '../../../utils/validateAddressHelpers';
import {
  BorrowerDetailsEditFormValues,
  manualMailingAddressInputFieldNames,
} from './forms';

type Params = {
  loanApplicationId: string;
  applicantId: string;
};

type UpdateBorrowerMailingAddressParams = {
  values: BorrowerDetailsEditFormValues;
  mailingAddress: AppAddressFormat | null;
  residentialAddress: AppAddressFormat | null;
};
export function useUpdateBorrowerMailingAddress({
  applicantId,
  loanApplicationId,
}: Params) {
  const [updateUserMailingAddress, { loading }] =
    useUpdateUserMailingAddressMutation({
      refetchQueries: [refetchGetLoanApplicantsQuery({ loanApplicationId })],
    });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const updateBorrowerMailingAddress = async (
    params: UpdateBorrowerMailingAddressParams,
  ) => {
    const { values, mailingAddress, residentialAddress } = params;
    setErrorMessage(null);
    const {
      [manualMailingAddressInputFieldNames.isManualInput]: isManualInput,
    } = values;

    let newMailingAddress: AppAddressFormat | null = null;
    let oldMailingAddress: AppAddressFormat | null = mailingAddress;
    if (isManualInput) {
      newMailingAddress = {
        isProvidedByUser: true,
        postcode: values[manualMailingAddressInputFieldNames.postcode],
        street: values[manualMailingAddressInputFieldNames.streetName],
        streetNo: values[manualMailingAddressInputFieldNames.streetNo],
        unitNo: values[manualMailingAddressInputFieldNames.unitNo] ?? null,
        state: values[manualMailingAddressInputFieldNames.state],
        streetType:
          values[manualMailingAddressInputFieldNames.streetType]?.value,
        suburb: values[manualMailingAddressInputFieldNames.suburb],
        // Needed to compare with the old mailing address value
        countryCode: Country_Enum.Au,
        streetNoTwo: null,
      };
      const { displayAddress, domainApiId, ...mailingAddressMainData } =
        mailingAddress ?? {};
      oldMailingAddress = mailingAddressMainData;
    } else {
      newMailingAddress = values.mailingAddress;
    }

    if (!newMailingAddress || isEqual(newMailingAddress, oldMailingAddress)) {
      return true;
    }

    const useResidentialAddressAsMailingAddress =
      newMailingAddress === residentialAddress;

    let mailingAddressInput: {
      mailing_address_provided_by_user?: Manual_Address_Input | null;
      domain_api_mailing_address_input?: Domain_Api_Address_Input | null;
    } = {
      mailing_address_provided_by_user: undefined,
      domain_api_mailing_address_input: undefined,
    };

    if (!useResidentialAddressAsMailingAddress) {
      const {
        manualAddressInput: validatedManualMailingAddressInput,
        domainApiAddressInput: validatedDomainApiMailingAddressInput,
      } = parseAddressInputForMutationAction(newMailingAddress);

      mailingAddressInput = {
        mailing_address_provided_by_user: validatedManualMailingAddressInput,
        domain_api_mailing_address_input: validatedDomainApiMailingAddressInput,
      };
    }

    const variables: UpdateUserMailingAddressMutationVariables = {
      data: {
        ...mailingAddressInput,
        use_residential_address_as_mailing_address:
          useResidentialAddressAsMailingAddress,
      },
    };

    const [res, error] = await safelyCallMutation(updateUserMailingAddress, {
      context: { sentryContext: { loanApplicationId } },
      variables,
      refetchQueries: [
        refetchGetBorrowerDetailQuery({ applicantId, loanApplicationId }),
      ],
    });

    if (!res || error) {
      setErrorMessage(t('Content.Common.Error.FailUpdateBorrowerDetail'));
      return false;
    }

    return true;
  };

  return {
    updateBorrowerMailingAddress,
    errorMessage,
    loading,
  };
}
