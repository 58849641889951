import { Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';
import { useIntercom } from '../../Home/navigation/utils/useIntercom';

export function IntercomPrompt() {
  const { onChatIconPress } = useIntercom();
  return (
    <Text variant="caption">
      {t('Content.YourIncome.Form.OneEmploymentIncomePerBorrowerText')}
      <Text
        nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
        variants={['caption', 'link']}
        testID={TestID.AddIncomeForm.SpeakWithOurTeam}
        onPress={onChatIconPress}
        sx={{ cursor: 'pointer' }}
      >
        {t('Content.YourIncome.Form.SpeakWithOurTeam')}
      </Text>
      .
    </Text>
  );
}
