import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import { useMemo } from 'react';

import {
  PurchaseLoanApplicationRouteList,
  RefiLoanApplicationV2RouteList,
  TopUpLoanApplicationRouteList,
} from '../../Home/utils/generateLoanApplicationRouteState';
import { LoanApplicationV2StackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { LoanApplicationBackButton } from '../components/LoanApplicationBackButton';
import { LoanApplicationSplashCardLayout } from '../screens/SplashCardLayout';
import { LoanApplicationScreenName } from './loanApplicationRouteMapping';

/**
 * V2 navigator doesn't need to have custom navigator.
 * For changing navigation behavior, please do so from the screen
 * using navigation hook instead
 */
const StackV2 = createStackNavigator<LoanApplicationV2StackParams>();

/**
 * Since we have web version & native version for Loan Application Navigator
 * We need to have a shared component to be used for both
 * So when we need make changes to the navigator (e.g. adding new screen), we only need to change it in one place
 */
export function LoanApplicationBaseNavigator({
  loanApplicationId,
  initialRouteName,
  screenOptions,
}: {
  loanApplicationId: string | undefined;
  initialRouteName: LoanApplicationScreenName | undefined;
  screenOptions: Omit<StackNavigationOptions, 'headerLeft'>;
}) {
  const initialParams = useMemo(
    () => (loanApplicationId ? { loanApplicationId } : {}),
    [loanApplicationId],
  );

  return (
    <StackV2.Navigator
      screenOptions={{
        ...screenOptions,

        headerLeft: LoanApplicationBackButton,
      }}
      initialRouteName={initialRouteName}
    >
      <StackV2.Screen
        name={Screen.LOAN_APPLICATION_V2_SPLASH}
        component={LoanApplicationSplashCardLayout}
        options={{
          headerShown: false,
          title: getPageTitle(Screen.LOAN_APPLICATION_V2_SPLASH),
        }}
        initialParams={initialParams}
      />
      {RefiLoanApplicationV2RouteList.map(({ key, component, name, options }) =>
        component ? (
          <StackV2.Screen
            key={key}
            name={name}
            component={component}
            options={{
              ...options,
              title: getPageTitle(name),
            }}
            initialParams={initialParams}
          />
        ) : null,
      )}
      {TopUpLoanApplicationRouteList.map(({ key, component, name, options }) =>
        component ? (
          <StackV2.Screen
            key={key}
            name={name}
            component={component}
            options={{
              ...options,
              title: getPageTitle(name),
            }}
            initialParams={initialParams}
          />
        ) : null,
      )}
      {PurchaseLoanApplicationRouteList.map(
        ({ key, component, name, options }) =>
          component ? (
            <StackV2.Screen
              key={key}
              name={name}
              component={component}
              options={{
                ...options,
                title: getPageTitle(name),
              }}
              initialParams={initialParams}
            />
          ) : null,
      )}
    </StackV2.Navigator>
  );
}
