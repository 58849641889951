import { useAuth } from '../../Auth/hooks';
import { Accept_Application_Invite_Error } from '../../generated/graphql';
import {
  JoinApplicationContextValue,
  useJoinApplication,
} from '../../utils/JoinApplicationInvite';
import { assertUnreachable } from '../../utils/typesHelpers';
import { JoinApplicationContent } from '../components/JoinApplicationContent';

export function getErrorContent(
  errorType: NonNullable<JoinApplicationContextValue['errorType']>,
) {
  switch (errorType) {
    case Accept_Application_Invite_Error.Used:
      return {
        title: t('Content.JoinApplication.UsedInvitation.Title'),
        message: t('Content.JoinApplication.UsedInvitation.Description'),
      };
    case Accept_Application_Invite_Error.Expired:
      return {
        title: t('Content.JoinApplication.ExpiredInvitation.Title'),
        message: t('Content.JoinApplication.ExpiredInvitation.Description'),
      };
    case Accept_Application_Invite_Error.Revoked:
      return {
        title: t('Content.JoinApplication.RevokedInvitation.Title'),
        message: t('Content.JoinApplication.RevokedInvitation.Description'),
      };
    case Accept_Application_Invite_Error.ExistingMember:
      return {
        title: t('Content.JoinApplication.ExistingMember.Title'),
        message: t('Content.JoinApplication.ExistingMember.Description'),
      };
    case Accept_Application_Invite_Error.NotFound:
      return {
        title: t('Content.JoinApplication.ApplicationNotFound.Title'),
        message: t('Content.JoinApplication.ApplicationNotFound.Description'),
      };
    case Accept_Application_Invite_Error.NotAllowed:
      return {
        title: t('Content.JoinApplication.ApplicationNotAllowed.Title'),
        message: t('Content.JoinApplication.ApplicationNotAllowed.Description'),
      };
    case Accept_Application_Invite_Error.SelfJoin:
      return {
        title: t('Content.JoinApplication.SelfJoin.Title'),
        message: t('Content.JoinApplication.SelfJoin.Description'),
      };
    case 'EXCEPTION':
      return {
        title: t('Content.JoinApplication.Exception.Title'),
        message: t('Content.JoinApplication.Exception.Description'),
      };
    default:
      assertUnreachable(errorType);
      return {
        title: t('Content.JoinApplication.Exception.Title'),
        message: t('Content.JoinApplication.Exception.Description'),
      };
  }
}

export function InvalidApplicationInvite() {
  const { logout, loading } = useAuth();

  const onLogout = async () => {
    logout();
  };

  const joinApplicationData = useJoinApplication();

  const { errorType } = joinApplicationData;

  if (errorType == null) {
    return null;
  }

  const { title, message } = getErrorContent(errorType);
  return (
    <JoinApplicationContent
      title={title}
      message={message}
      onLogout={
        errorType === Accept_Application_Invite_Error.SelfJoin
          ? onLogout
          : undefined
      }
      onLogoutLoading={
        errorType === Accept_Application_Invite_Error.SelfJoin
          ? loading
          : undefined
      }
    />
  );
}
