import { NetworkStatus } from '@apollo/client';
import { useDripsyTheme } from 'dripsy';

import {
  Expense_Type_Enum,
  GetHouseholdExpensesFieldsWithGuideQuery,
  GetHouseholdExpensesFieldsWithGuideQueryVariables,
  Living_Situation_Input_Enum,
  useGetExpensesDetailsQuery,
  useGetFrequencyQuery,
  useGetHouseholdExpensesFieldsWithGuideLazyQuery,
  useGetLivingSituationQuery,
  useGetLoanApplicantsQuery,
  useGetMonthlyExpensesTypesValuesQuery,
} from '../../generated/graphql';
import { sortApplicants } from '../../LoanApplication/utils/sortApplicants';
import { parseEnumType } from '../../utils/ensureEnumType';
import { mapApplicantOptionsForExpenses } from './expensesFormUtils';
import {
  ExpensesFormValues,
  ExpensesTypeFormInitialValues,
  ExpensesTypeFormValuesRent,
} from './forms';
import { ExpensesFormField, ExpensesTypesField } from './types';

export type ApplicantOptions = Array<{
  label: string;
  value: string;
  household_id?: string | null;
}>;

export function useApplicantOptions(loanApplicationId: string | undefined) {
  /**
   * Reusing loan applicant query from `Borrowers` screen, so the payee options will always be in sync.
   * This will less likely to fire another HTTP request,
   * because this has been fetched in `Borrowers` screen & the we use Apollo client's default fetch policy (cache-first).
   */
  const { data, ...other } = useGetLoanApplicantsQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
    },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });
  const applicantOptions: ApplicantOptions = sortApplicants(
    data?.loan_application_by_pk?.applicants,
  ).map(({ id, household_id, latest_full_name }) => ({
    label: latest_full_name || '--',
    value: id,
    household_id,
  }));
  return {
    applicantOptions,
    ...other,
  };
}

export function useLivingSituationOptions() {
  const { data, ...others } = useGetLivingSituationQuery();
  return {
    livingSituationOptions:
      data?.living_situation.map(({ situation, description }) => ({
        label: description,
        value: situation,
      })) ?? [],
    ...others,
  };
}

export const useFrequencyOptions = () => {
  const { data, ...others } = useGetFrequencyQuery();
  return {
    frequencyOptions:
      data?.frequencies.map(({ description, frequency }) => ({
        label: description,
        value: frequency,
      })) ?? [],
    ...others,
  };
};

export type ExpenseFields =
  GetHouseholdExpensesFieldsWithGuideQuery['get_household_expenses_fields_with_guide']['expense_fields'];

export function useGetExpenseFieldsWithGuide() {
  const [
    getExpenseFieldsGuide,
    {
      data: expensesFieldsWithGuide,
      loading: expensesFieldsWithGuideLoading,
      previousData: expensesFieldsWithGuidePrevData,
      networkStatus: getHouseholdExpensesFieldGuideNetworkStatus,
    },
  ] = useGetHouseholdExpensesFieldsWithGuideLazyQuery();

  const { theme } = useDripsyTheme();

  const isRefetchingHouseholdExpenseFieldGuide =
    (getHouseholdExpensesFieldGuideNetworkStatus ===
      NetworkStatus.setVariables ||
      getHouseholdExpensesFieldGuideNetworkStatus === NetworkStatus.refetch) &&
    expensesFieldsWithGuideLoading;

  const fetchHouseholdExpensesFieldGuide = async (
    variables: GetHouseholdExpensesFieldsWithGuideQueryVariables,
  ) => {
    await getExpenseFieldsGuide({
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });
  };

  /**
   * When refetching, apollo will return undefined for current `data`
   * But in this case we still want to use field list data from previous fetch
   * & we only want to update the guide amount.
   * That's why in here, if current data is undefined, we fall back to previous data
   */
  const expensesFieldsWithGuideData: ExpenseFields =
    expensesFieldsWithGuide?.get_household_expenses_fields_with_guide
      .expense_fields ||
    expensesFieldsWithGuidePrevData?.get_household_expenses_fields_with_guide
      .expense_fields ||
    [];

  const isExpensesFieldLoading =
    expensesFieldsWithGuideLoading || expensesFieldsWithGuideData == null;

  // expenseCategoryHemComparableCount is coming from the count of expense category that is comparable to HEM - See AC 1 in https://github.com/unloan/unloan-app/issues/5130
  const expenseCategoryHemComparableCount = 7;
  const householdPlaceholderHeight =
    theme.sizes.minRowHeight * expenseCategoryHemComparableCount;

  return {
    fetchHouseholdExpensesFieldGuide,
    isRefetchingHouseholdExpenseFieldGuide,
    expensesFieldsWithGuideLoading,
    expensesFieldsWithGuidePrevData,
    expensesFieldsWithGuide,
    householdPlaceholderHeight,
    expenseCategoryHemComparableCount,
    isExpensesFieldLoading,
    expensesFieldsWithGuideData,
  };
}

export function useGetExpensesDetailsInitialValues(
  loanApplicationId: string | undefined,
  householdId: string | undefined,
) {
  const { data, ...others } = useGetExpensesDetailsQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
      householdId: householdId || '',
    },
    skip: !loanApplicationId || !householdId,
    context: {
      sentryContext: {
        loanApplicationId,
        householdId,
      },
    },
  });

  const household = data?.household?.[0];

  const currentLivingSituation = household?.living_situation;
  const parsedCurrentLivingSituation = parseEnumType(
    Living_Situation_Input_Enum,
    currentLivingSituation,
  );

  const initialValues: ExpensesFormValues = {
    [ExpensesFormField.LivingSituation]:
      parsedCurrentLivingSituation || undefined,
    [ExpensesFormField.SelectedBorrowers]:
      household?.applicants.map(({ id }) => id) || [],
    [ExpensesFormField.DependentsCount]: household?.dependants || 0,
  };

  return {
    initialValues,
    ...others,
  };
}

export function useGetMonthlyExpensesTypesInitialValues(
  loanApplicationId: string | undefined,
  householdId: string | undefined,
) {
  const { data, ...others } = useGetMonthlyExpensesTypesValuesQuery({
    variables: {
      loanApplicationId: loanApplicationId || '',
      householdId: householdId || '',
    },
    skip: !loanApplicationId || !householdId,
    context: {
      sentryContext: {
        loanApplicationId,
        householdId,
      },
    },
  });

  const household = data?.household?.[0];
  const expensesTypesMap = new Map<string, { amount: number }>();

  household?.household_expenses.forEach((e) => {
    expensesTypesMap.set(e.expense_type, {
      amount: e.monthly_amount,
    });
  });

  const initialValues: ExpensesTypeFormValuesRent = {
    ...ExpensesTypeFormInitialValues,
    [ExpensesTypesField.FoodAndGroceries]: expensesTypesMap.get(
      Expense_Type_Enum.FoodAndGroceries,
    )?.amount,

    [ExpensesTypesField.HomeAndUtilities]: expensesTypesMap.get(
      Expense_Type_Enum.HomeAndUtilities,
    )?.amount,

    [ExpensesTypesField.ClothingAndPersonalCare]: expensesTypesMap.get(
      Expense_Type_Enum.ClothingAndPersonalCare,
    )?.amount,

    [ExpensesTypesField.MedicalHealthFitness]: expensesTypesMap.get(
      Expense_Type_Enum.MedicalHealthFitness,
    )?.amount,

    [ExpensesTypesField.Transport]: expensesTypesMap.get(
      Expense_Type_Enum.Transport,
    )?.amount,

    [ExpensesTypesField.EntertainmentAndPets]: expensesTypesMap.get(
      Expense_Type_Enum.EntertainmentAndPets,
    )?.amount,

    [ExpensesTypesField.ChildcareAndEducation]: expensesTypesMap.get(
      Expense_Type_Enum.ChildcareAndEducation,
    )?.amount,

    [ExpensesTypesField.ChildAndSpouseSupport]: expensesTypesMap.get(
      Expense_Type_Enum.ChildAndSpouseSupport,
    )?.amount,

    [ExpensesTypesField.AdditionalExpenses]: expensesTypesMap.get(
      Expense_Type_Enum.AdditionalExpenses,
    )?.amount,

    [ExpensesTypesField.RentOrBoard]: expensesTypesMap.get(
      Expense_Type_Enum.RentOrBoard,
    )?.amount,
  };

  return {
    initialValues,
    ...others,
  };
}

export function useGetOptionsForExpensesDetails({
  loanApplicationId,
  householdId,
}: {
  loanApplicationId?: string;
  householdId?: string;
}) {
  const { livingSituationOptions, loading: livingSituationOptionsLoading } =
    useLivingSituationOptions();

  const {
    applicantOptions: applicantOptionsData,
    loading: applicantOptionsLoading,
  } = useApplicantOptions(loanApplicationId);

  const applicantOptions = mapApplicantOptionsForExpenses(
    applicantOptionsData,
    householdId,
  );

  const loading = livingSituationOptionsLoading || applicantOptionsLoading;
  return {
    livingSituationOptions,
    applicantOptions,
    loading,
  };
}
