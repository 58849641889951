/**
 * @module
 *
 * This module contains the platform agnostic implementation
 * for caching responses from Google Maps Places API.
 *
 * The cache here MUST be in-memory and is not stored for long term.
 * The policies for Places API forbid us from caching or storing
 * the content of the responses.
 * https://developers.google.com/maps/documentation/places/web-service/policies#cache-policy
 *
 * No-cache policy is useful for general use case
 * to avoid displaying stale data.
 * But in our case, we're calling Place details API for
 * multiple predictions from Autocomplete API.
 * This results in multiple Place details API calls for the same place
 * while user is typing the suburb.
 *
 * Hence we're caching the place details responses using in-memory LRU cache.
 * Stale data is low risk because suburb, postcode, and state
 * doesn't change often in real time.
 */

import type LRUCacheType from 'mnemonist/lru-cache';

import { captureMessage } from '../sentry';
import { normalizeError } from '../utils/normalizeError';
import { SuburbDetails, SuburbPrediction } from './GoogleMapsApiCommon';

let suburbDetailsCache: LRUCacheType<
  SuburbPrediction['placeId'],
  SuburbDetails
> | null = null;

async function setupCache() {
  if (suburbDetailsCache) {
    return;
  }

  try {
    const LRUCache = (await import('mnemonist/lru-cache')).default;
    suburbDetailsCache = new LRUCache(200);
  } catch (error) {
    captureMessage('Failed to setup LRU cache', {
      errorMessage: normalizeError(error).message,
    });
  }
}

export const SuburbDetailsCache = {
  setupCache,
  get: (key: SuburbPrediction['placeId']) => suburbDetailsCache?.get(key),
  set: (key: SuburbPrediction['placeId'], value: SuburbDetails) => {
    suburbDetailsCache?.set(key, value);
  },
};
