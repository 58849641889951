import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import {
  Menu,
  MenuOption,
  MenuOptionCustomStyle,
  MenuOptions,
  MenuOptionsCustomStyle,
  MenuTrigger,
  MenuTriggerProps,
  renderers,
} from 'react-native-popup-menu';

import { TestID } from '../../../../testID/constants';
import { useAuth } from '../../../Auth/hooks';
import { ProfileButton } from '../../../components/ProfileButton';
import { Screen } from '../../../navigation/types/screens';
import { useTheme } from '../../../ui/theme';
import { useIsMobile } from '../../../utils/hooks/useBreakpoint';
import { isWeb } from '../../../utils/platformUtils';
import { MenuItem } from './MenuItem';

interface ProfileMenuProps {
  isOpen?: boolean;
}
export function ProfileMenu({ isOpen = false }: ProfileMenuProps) {
  const [showMenu, setShowMenu] = useState(isOpen);

  const { logout, isAuthenticated } = useAuth();
  const navigation = useNavigation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const menuTriggerStyles: MenuTriggerProps['customStyles'] = {
    triggerTouchable: { underlayColor: 'transparent' },
    triggerOuterWrapper: {
      padding: 0,
      margin: 0,
      marginLeft: theme.spacing.s,
    },
  };

  const menuWrapperStyles: MenuOptionsCustomStyle = {
    optionsContainer: {
      backgroundColor: theme.colors.shapeBg,
      borderRadius: theme.borderRadii.card,
      width: 250,
      elevation: 0,
      shadowOpacity: 0,
      borderColor: theme.colors.border,
      borderWidth: 1,
      marginTop: isMobile ? 15 : 30,
      // Position is calculated from the trigger (profile icon in the menubar)
      // Right margin had no effect, so we use a negative left margin instead
      // to pull the menu wrapper to the left
      marginLeft: isMobile ? -10 : -30,
      paddingVertical: 5,
    },
  };

  const menuItemStyles: MenuOptionCustomStyle = {
    optionTouchable: {
      // Removes the backdrop
      underlayColor: 'transparent',
    },
    optionWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      marginHorizontal: 10,
      marginVertical: 0,
      padding: 0,
    },
  };

  const navigateToDashboard = useCallback(() => {
    setShowMenu(false);
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);

  const navigateToSettings = useCallback(() => {
    setShowMenu(false);
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.SETTINGS,
      params: {
        screen: Screen.SETTINGS_HOME,
      },
    });
  }, [navigation]);

  const signOut = useCallback(() => {
    setShowMenu(false);
    if (isAuthenticated) {
      logout();
    }
    if (!isWeb) {
      // On web, logout from Auth0 already redirect the browser to the
      // origin location.
      // If we also call navigate on web, it might causes race condition
      // where after redirecting to the origin location, navigate will
      // redirect to BrowserAuthFlow, which redirects to Home screen
      // because isAuthenticated from Auth0 state is not yet cleared.
      navigation.navigate(Screen.AUTH_FLOW);
    }
  }, [isAuthenticated, logout, navigation]);

  return (
    <Menu
      opened={showMenu}
      onBackdropPress={() => setShowMenu(false)}
      onOpen={() => setShowMenu(true)}
      onSelect={() => setShowMenu(true)}
      renderer={renderers.Popover}
      rendererProps={{
        placement: 'bottom',
        anchorStyle: {
          display: 'none',
        },
      }}
    >
      <MenuTrigger
        onPress={() => setShowMenu(!showMenu)}
        customStyles={menuTriggerStyles}
      >
        <ProfileButton
          onPress={() => setShowMenu(!showMenu)}
          secondary
          testID={TestID.ProfileButton}
        />
      </MenuTrigger>
      <MenuOptions customStyles={menuWrapperStyles}>
        <MenuOption
          customStyles={menuItemStyles}
          onSelect={navigateToDashboard}
        >
          <MenuItem
            onPress={navigateToDashboard}
            label={t('Content.ProfileMenu.Dashboard')}
            icon="profileMenuHome"
            testID={TestID.ProfileMenu.Home}
          />
        </MenuOption>
        <MenuOption customStyles={menuItemStyles} onSelect={navigateToSettings}>
          <MenuItem
            onPress={navigateToSettings}
            label={t('Content.ProfileMenu.Settings')}
            icon="profileMenuSettings"
            testID={TestID.ProfileMenu.Settings}
          />
        </MenuOption>
        <MenuOption customStyles={menuItemStyles} onSelect={signOut}>
          <MenuItem
            onPress={signOut}
            label={t('Content.ProfileMenu.LogOut')}
            icon="profileMenuSignOut"
            testID={TestID.ProfileMenu.SignOut}
            isLast
          />
        </MenuOption>
      </MenuOptions>
    </Menu>
  );
}
