import { TestID } from '../../../testID/constants';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

function NoLoansMessage() {
  return (
    <ActionSheet.MessageText pt="m">
      {t('Content.YourDebts.HaveYouMissedAnythingModal.NoLoans')}
    </ActionSheet.MessageText>
  );
}

function NoHomeLoansMessage() {
  return (
    <ActionSheet.MessageText pt="m">
      {t('Content.YourDebts.HaveYouMissedAnythingModal.NoHomeLoans')}
    </ActionSheet.MessageText>
  );
}

type Props = ActionSheetScreenProps<ActionSheetType.NO_DEBTS_CONFIRMATION>;

export function NoDebtsConfirmationActionSheet({ navigation, route }: Props) {
  const { loanApplicationId, noHomeLoans } = route.params;

  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateToNextScreen = () =>
    navigateToNextLoanApplicationScreen({
      currentSection: LoanApplicationSection.Debts,
    });

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      title={t('Content.YourDebts.HaveYouMissedAnythingModal.Title')}
      message={noHomeLoans ? <NoHomeLoansMessage /> : <NoLoansMessage />}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Back'),
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
          testID: TestID.NoDebtsConfirmationActionSheet.BackButton,
        },
        {
          label: t('Content.Common.ButtonLabel.Continue'),
          onPress: navigateToNextScreen,
          flex: 1,
          secondary: true,
          testID: TestID.NoDebtsConfirmationActionSheet.ContinueButton,
        },
      ]}
    />
  );
}
