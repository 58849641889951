import { Text, useDripsyTheme, View } from 'dripsy';

import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { BoxProps } from '../../ui/atoms/Box';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { RadioGroupItemAsCard } from '../../ui/v2/RadioGroup';

export type Props = BoxProps & {
  subtitle: string;
  testID?: string;
  title: string;
  optionValue: Loan_Application_Type_Enum;
};

// These are off-theme typography required to match the designs
const customTypography = {
  secondary: {
    fontWeight: '400',
    fontSize: 12,
    color: '$secondary',
    lineHeight: 16,
    letterSpacing: '-0.03em',
  },
};

export function LoanTypeOptionCard({
  optionValue,
  subtitle,
  testID,
  title,
}: Props) {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        minWidth: 163,
        maxWidth: 312,
        width: '100%',
        flex: 1,
      }}
    >
      <RadioGroupItemAsCard
        testID={testID}
        value={optionValue}
        noRadioIfUnselected
        compact
      >
        <View
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '$8',
            padding: ['$0', '$16'],
          }}
        >
          {optionValue === Loan_Application_Type_Enum.Purchase && (
            <StyledIcon
              name="house"
              color="primaryContent"
              family="svg"
              size="l"
            />
          )}
          {optionValue === Loan_Application_Type_Enum.Refinance && (
            <StyledIcon
              name="refinance"
              color="primaryContent"
              family="svg"
              size="l"
            />
          )}
          <Text
            sx={{
              fontWeight: [
                theme.text.subHeading3.fontWeight,
                theme.text.subHeading2.fontWeight,
              ],
              fontSize: [
                theme.text.subHeading3.fontSize,
                theme.text.subHeading2.fontSize,
              ],
              lineHeight: [
                theme.text.subHeading3.lineHeight,
                theme.text.subHeading2.lineHeight,
              ],
              letterSpacing: [
                theme.text.subHeading3.letterSpacing,
                theme.text.subHeading2.letterSpacing,
              ],
            }}
          >
            {title}
          </Text>
          <Text
            variant="caption"
            sx={{
              ...customTypography.secondary,
              flexShrink: 1,
              textAlign: 'center',
            }}
          >
            {subtitle}
          </Text>
        </View>
      </RadioGroupItemAsCard>
    </View>
  );
}
