import { ReactNode } from 'react';

import { Theme, ThemeProvider as RestyleThemeProvider } from '../ui/theme';
import { LightTheme } from './index';

type Props = {
  theme?: Theme;
  children: ReactNode;
};

export function ThemeProvider({ children, theme = LightTheme }: Props) {
  return <RestyleThemeProvider theme={theme}>{children}</RestyleThemeProvider>;
}
