import { FetchResult } from '@apollo/client';

import { PickerOptions } from '../../components/form/types';
import {
  Property_Purpose_Enum,
  Upsert_Loan_Application_Security_Error_Type,
  UpsertLoanApplicationSecurityMutation,
} from '../../generated/graphql';
import { assertUnreachable } from '../../utils/typesHelpers';

export function getUpsertApplicationSecurityErrorMessage(
  res: FetchResult<UpsertLoanApplicationSecurityMutation> | null,
): string | null {
  if (!res) {
    return t('Content.Common.Error.FailSaveProperty');
  }

  const { data } = res;
  const errorType = data?.upsert_loan_application_security.error_type;

  if (errorType == null) {
    return null;
  }

  switch (errorType) {
    case Upsert_Loan_Application_Security_Error_Type.LivingInAddressConflictWithRentalIncome:
      return t(
        'Content.Common.Error.InvestmentAddressConflictWhenSavingProperty',
      );
    default:
      assertUnreachable(errorType);
      return t('Content.Common.Error.FailSaveProperty');
  }
}

export function mapPropertyPurposeForV2(options: PickerOptions<string>) {
  return options.map(({ label, value }) => {
    let newLabel = label;
    switch (value) {
      case Property_Purpose_Enum.LivingIn: {
        newLabel = t('Content.PropertyDetails.Form.YesItsMyHome');
        break;
      }
      case Property_Purpose_Enum.Investing: {
        newLabel = t('Content.PropertyDetails.Form.NoItsInvestment');
        break;
      }
      default: {
        break;
      }
    }

    return { value, label: newLabel };
  });
}
