import {
  HeaderBackButton,
  HeaderBackButtonProps,
} from '@react-navigation/elements';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback } from 'react';

import {
  AppStackNavigationProps,
  LoanApplicationV2StackParams,
} from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';

export function LoanApplicationBackButton(props: HeaderBackButtonProps) {
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<LoanApplicationV2StackParams>,
        AppStackNavigationProps<Screen.LOAN_APPLICATION_V2>
      >
    >();

  const { canGoBack, onPress: onPressProp, ...otherProps } = props;

  const navigateToHome = useCallback(() => {
    // We're using `replace` instead of `navigate` to reset the route state of the loan application navigator.
    // If we used `navigate` to go to the Home screen, it would keep the route state in the loan application navigator until user refreshes the page.
    // This would break the back button, as pressing the back button could navigate the user back to another loan application they has opened.
    navigation.replace(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);

  const defaultOnPress = canGoBack ? navigation.goBack : navigateToHome;

  return (
    <HeaderBackButton {...otherProps} onPress={onPressProp ?? defaultOnPress} />
  );
}
