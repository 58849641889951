import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryProperty = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryProperty');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.089 16.5V33c0 .761.557.757 1.28.751l.196-.001h23.62l.196.001c.723.006 1.28.01 1.28-.751V16.5L18.375 3.75 5.09 16.5Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M10.256 20.25h7.38c.408 0 .739.336.739.75v12.75H9.518V21c0-.414.33-.75.738-.75Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.05}
        d="M18.375 4.5c-.35 0-.7.126-.979.377l-3.955 3.609L5.089 16.5v4.043L18.375 8.57l13.286 11.972V16.5L23.31 8.486l-3.955-3.609a1.46 1.46 0 0 0-.979-.377Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33.503 16.13 19.353 3.377a1.46 1.46 0 0 0-1.957 0L3.247 16.13a.758.758 0 0 0-.047 1.073l1.208 1.309a.73.73 0 0 0 1.026.05L18.375 7.07l12.942 11.492a.73.73 0 0 0 1.026-.05l1.207-1.31a.758.758 0 0 0-.047-1.073Z"
        fill="#A3A3A3"
      />
      <Path
        d="M22.066 20.25h4.428c.408 0 .739.336.739.75v4.5c0 .414-.331.75-.739.75h-4.428a.744.744 0 0 1-.738-.75V21c0-.414.33-.75.738-.75Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={5.089}
          y1={3.75}
          x2={34.871}
          y2={30.128}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
