import { useMfaLoginInitMutation } from '../../generated/graphql';

export function useMFALoginInit() {
  const [mfaLoginInit, { loading, error }] = useMfaLoginInitMutation();

  return {
    mfaLoginInit,
    loading,
    error,
  };
}
