import { useTheme } from '@shopify/restyle';
import * as React from 'react';
import { Platform } from 'react-native';

import { Theme } from '../theme';
import { createBox } from '../utils/createBox';
import { Props as StyledTextProps, StyledText } from './StyledText';

const BaseSeparator = createBox();

export type SeparatorProps = React.ComponentProps<typeof BaseSeparator> & {
  spacer?: boolean;
  header?: string;
  headerLink?: string;
  caption?: string | React.ReactNode;
  uppercaseHeader?: boolean;
  variant?: StyledTextProps['variant'];
  onHeaderLinkPress?: () => void;
};

export const Separator = React.memo<SeparatorProps>(
  ({
    header,
    caption,
    children,
    variant = 'caption',
    uppercaseHeader = Platform.OS === 'ios' && variant === 'caption',
    spacer,
    headerLink,
    onHeaderLinkPress,
    ...props
  }) => {
    const theme = useTheme<Theme>();

    return (
      <BaseSeparator
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth={spacer ? 0 : theme.constants.borderWidth}
        borderColor="border"
        minHeight={1}
        alignSelf="stretch"
        px="m"
        {...((spacer || caption || header) && {
          py: 's',
        })}
        {...props}
      >
        {header ? (
          <StyledText variant={variant}>
            {uppercaseHeader ? header.toUpperCase() : header}
            <StyledText
              onPress={onHeaderLinkPress}
              color="link"
              variant={variant}
            >
              {headerLink}
            </StyledText>
          </StyledText>
        ) : null}
        {caption ? <StyledText variant="caption">{caption}</StyledText> : null}
        {children}
      </BaseSeparator>
    );
  },
);
