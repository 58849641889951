import { SxProp, Text } from 'dripsy';

import { WarningRow } from '../../components/WarningRow';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';
import { useIntercom } from '../navigation/utils/useIntercom';

type TransferDisabledWarningRowProps = {
  sx?: SxProp;
};

export function TransferDisabledWarningRow({
  sx,
}: TransferDisabledWarningRowProps) {
  const { onChatIconPress } = useIntercom();

  return (
    <WarningRow sx={sx}>
      <Text variant="caption" sx={{ flex: 1, color: '$labelsPrimary' }}>
        {t('Content.HomeLoan.TransferDisabled1')}
        <Text
          nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
          variant="caption"
          sx={{
            color: '$labelsPrimary',
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
          onPress={onChatIconPress}
        >
          {t('Content.HomeLoan.TransferDisabled2')}
        </Text>
      </Text>
    </WarningRow>
  );
}
