import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';

import { ActionSheetStackParams } from '../../navigation/types/navTypes';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';

type Props = StackScreenProps<
  ActionSheetStackParams,
  ActionSheetType.TRANSACTION_HISTORY
>;
export const TransactionHistoryActionSheet: React.FC<Props> = ({
  navigation,
}) => (
  <ActionSheet
    closeModal={navigation.goBack}
    title="Actions"
    actionList={[
      {
        label: 'Export As PDF',
        onPress: () => {},
        secondary: true,
      },
      {
        label: 'View Statements',
        onPress: () => {},
        secondary: true,
      },
    ]}
  />
);
