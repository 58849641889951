import { useNavigation } from '@react-navigation/native';
import { Text, useDripsyTheme, View } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { DiscountCircularGraph } from '../../HomeLoanInsights/components/DiscountBump/DiscountCircularGraph';
import { useDiscountBumpInsight } from '../../HomeLoanInsights/hooks/useDiscountBumpInsight';
import { Screen } from '../../navigation/types/screens';
import { useIsMobile } from '../../utils/hooks/useBreakpoint';
import { HomeLoanLoadingInsight } from './HomeLoanLoadingInsight';
import { InsightWidgetContainer } from './InsightWidgetContainer';

type Props = Partial<React.ComponentProps<typeof InsightWidgetContainer>> & {
  onPress?: () => void;
  loading?: boolean;
  remainingTermsInMonths?: number;
  cbaAccountId: string;
};

export const InsightsDiscountBumpWidget = ({
  cbaAccountId,
  loading: initialLoading = false,
  onPress,
  ...props
}: Props) => {
  const isMobile = useIsMobile();
  const { theme } = useDripsyTheme();
  const navigation = useNavigation();

  const {
    discountBumpInDays,
    percentageDaysRemaining,
    isAnniversaryPeriod,
    currentInterestRateDiscount,
    loading: isLoadingInsightsData,
  } = useDiscountBumpInsight(cbaAccountId);

  const loading = initialLoading || isLoadingInsightsData;

  const openDiscountBumpInsightModal = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN_INSIGHTS_MODAL, {
      screen: Screen.HOME_LOAN_INSIGHTS_DISCOUNT_BUMP,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  if (loading) {
    return <HomeLoanLoadingInsight />;
  }

  return (
    <InsightWidgetContainer
      testID={TestID.Insights.DiscountBump.WidgetContainer}
      onPress={onPress || openDiscountBumpInsightModal}
      disabled={loading || isLoadingInsightsData}
      header={
        isAnniversaryPeriod ? (
          <View sx={{ flexDirection: 'row' }}>
            <Text
              testID={TestID.Insights.DiscountBump.DiscountData}
              variant={isMobile ? 'header' : 'lHeader'}
            >
              {currentInterestRateDiscount}
            </Text>
            <Text
              variant="xsHeader"
              sx={{
                lineHeight:
                  (isMobile
                    ? theme.text.header.lineHeight
                    : theme.text.lHeader.lineHeight) / 2,
              }}
            >
              p.a.
            </Text>
          </View>
        ) : (
          t(
            'Content.HomeLoan.Insights.DiscountBump.NotInAnniversaryPeriod.DaysUntilDiscount',
            {
              days: discountBumpInDays,
            },
          )
        )
      }
      caption={
        isAnniversaryPeriod
          ? t(
              'Content.HomeLoan.Insights.DiscountBump.InAnniversaryPeriod.DiscountApplied',
            )
          : t('Content.HomeLoan.Insights.DiscountBump.Header')
      }
      graphPlaceholder={
        !loading ? (
          <DiscountCircularGraph
            percentageRemaining={percentageDaysRemaining}
          />
        ) : null
      }
      {...props}
    />
  );
};
