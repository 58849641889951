import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

declare global {
  interface Navigator {
    getInstalledRelatedApps: () => Promise<
      { id: string; platform: string; url: string }[]
    >;
  }
}

export const isWeb = Platform.OS === 'web';

export const isAndroidNative = Platform.OS === 'android';

export const isIosNative = Platform.OS === 'ios';

// https://jestjs.io/docs/environment-variables#jest_worker_id
export const isTest = !!process.env.JEST_WORKER_ID;

export const isIPhoneSafari = () => {
  if (Platform.OS !== 'web') {
    return false;
  }

  const ua = window.navigator.userAgent;
  const isIos = !!ua.match(/iPhone/i);
  const isWebkit = !!ua.match(/WebKit/i);

  return isIos && isWebkit && !ua.match(/CriOS/i);
};

export const canInstallIosPwa = () =>
  Platform.OS === 'web' && 'standalone' in window.navigator;

export const isIosPwaInstalled = () =>
  Platform.OS === 'web' &&
  'standalone' in window.navigator &&
  window.navigator.standalone === true;

export const useIsAndroidPwaInstalled = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    (async () => {
      if (isAndroidWeb() && 'getInstalledRelatedApps' in navigator) {
        setIsInstalled(!!(await navigator.getInstalledRelatedApps()).length);
      }
    })();
  }, []);

  return isInstalled;
};

export const isIosWeb = () => {
  if (Platform.OS !== 'web') {
    return false;
  }

  const ua = window.navigator.userAgent;
  return !!ua?.match(/iP(ad|hone|od)/i);
};

export const isAndroidWeb = () => {
  if (Platform.OS !== 'web') {
    return false;
  }

  const ua = window.navigator.userAgent;
  return !!ua?.match(/Android/i);
};
