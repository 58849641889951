import { Text } from 'dripsy';

import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';

type Props = {
  loading: boolean;
  responses?: {
    id: string;
    name: string;
    status: string;
  }[];
};

export const ReduceRepaymentsApprovalResponses = ({
  loading,
  responses,
}: Props) => (
  <DataRowGroup>
    <DataRow
      loading={loading}
      label={
        <Text variant="xsHeader">
          {t('Content.LoanVariation.ReduceRepaymentApproverFlow.Status')}
        </Text>
      }
      caption={t(
        'Content.LoanVariation.ReduceRepaymentApproverFlow.PendingApproval',
      )}
    />
    {responses ? (
      <>
        <DataRowSeparator />
        {responses.map((response) => (
          <DataRow
            key={response.id}
            loading={loading}
            label={<Text variant="xsHeader">{response.name}</Text>}
            caption={response.status}
          />
        ))}
      </>
    ) : null}
  </DataRowGroup>
);
