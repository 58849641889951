import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import {
  ExpectedRentalIncomeForm,
  ExpectedRentalIncomeFormDetails,
} from '../components/ExpectedRentalIncomeForm';
import {
  useCreateRentalIncomeForV2Form,
  useGetOptionsForExpectedRentalIncomeQuery,
} from '../graphql/incomeHooks';
import { YourIncomeWizardScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeWizardScreenProps<Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL>;

export function ExpectedRentalIncomeWizardScreen({ navigation, route }: Props) {
  const { params } = route;
  const { loanApplicationId = '', _initialErrorMessage } = params;
  const [formErrorMessage, setFormErrorMessage] = useState(
    _initialErrorMessage ?? '',
  );

  const {
    frequencyOptions,
    stateOptions,
    loading: formOptionsLoading,
  } = useGetOptionsForExpectedRentalIncomeQuery();

  const { createExpectedRentalIncome, isCreatingRentalIncome } =
    useCreateRentalIncomeForV2Form({
      loanApplicationId,
    });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToNextForm = () => {
    navigation.navigate(Screen.YOUR_INCOME_V2_WIZARD_RENTAL, {
      loanApplicationId: params.loanApplicationId,
    });
  };

  const navigateToMainScreen = () => {
    navigateToLoanApplicationScreen({ section: LoanApplicationSection.Income });
  };

  const onSubmit = async (formValues: ExpectedRentalIncomeFormDetails) => {
    const { success, errorMessage } = await createExpectedRentalIncome(
      formValues,
    );

    if (!success) {
      setFormErrorMessage(errorMessage);
      return;
    }
    navigateToNextForm();
  };

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.ExpectedRentalIncome')}
      scrollable
      onClose={navigateToMainScreen}
      // Hide back button to prevent applicant trying to "edit" previously
      // submitted employment income in this wizard.
      // To edit, user need to go to dedicated edit screen instead.
      hideBackButton
    >
      {formOptionsLoading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.ExpectedRentalIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage}
            testID={TestID.ExpectedRentalIncomeForm.ErrorText}
          />
          <ExpectedRentalIncomeForm
            screen={Screen.YOUR_INCOME_V2_WIZARD_EXPECTED_RENTAL}
            onSubmit={onSubmit}
            isSubmitting={isCreatingRentalIncome}
            frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
            stateOptions={stateOptions}
            onRentalExpenseLearnMoreClick={() => {
              navigation.navigate(Screen.SINGLE_V2_MODAL, {
                screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
              });
            }}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
