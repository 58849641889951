import { NetworkStatus } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  useGetViewerLoanAccountsQuery,
  useGetViewerLoanApplicationsQuery,
} from '../../generated/graphql';
import { filterAppliedLoansForDisplay } from './filterAppliedLoansForDisplay';
import { filterLoanAccounts } from './filterLoanAccounts';

export function useDashboardQuery() {
  const {
    loading: loanApplicationsLoading,
    data: loanApplicationsData,
    error: loanApplicationsError,
    refetch: refetchLoanApplications,
    networkStatus: loanApplicationsNetworkStatus,
  } = useGetViewerLoanApplicationsQuery({
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {
        description: 'Error occured in useViewerLoanApplication',
      },
    },
  });

  const {
    loading: loanAccountsLoading,
    data: loanAccountsData,
    error: loanAccountsError,
    refetch: refetchLoanAccounts,
    networkStatus: loanAccountsNetworkStatus,
  } = useGetViewerLoanAccountsQuery({
    notifyOnNetworkStatusChange: true,
    context: {
      sentryContext: {
        description: 'Error occured in useViewerLoanAccounts',
      },
    },
  });

  const isRefetching =
    loanApplicationsNetworkStatus === NetworkStatus.refetch ||
    loanAccountsNetworkStatus === NetworkStatus.refetch;

  const error = loanApplicationsError ?? loanAccountsError;

  const refetchAll = useCallback(() => {
    refetchLoanApplications();
    refetchLoanAccounts();
  }, [refetchLoanApplications, refetchLoanAccounts]);

  return {
    loanApplicationsData,
    ...useMemo(() => {
      const me = loanApplicationsData?.me?.[0];
      const user = me?.user;
      const appliedLoans = user?.appliedLoans || [];
      const loanAccounts = loanAccountsData?.me?.[0]?.loan_account_owners ?? [];
      const appliedLoansForDisplay = filterAppliedLoansForDisplay(appliedLoans);
      const loanAccountsForDisplay = filterLoanAccounts(
        appliedLoans,
        loanAccounts,
      );

      return {
        user,
        appliedLoansForDisplay,
        loanAccountsForDisplay,
      };
    }, [loanAccountsData, loanApplicationsData]),
    isRefetching,
    error,
    loanApplicationsError,
    loanAccountsError,
    loanApplicationsLoading,
    loanAccountsLoading,
    refetchAll,
  };
}
