import * as Linking from 'expo-linking';
import { useCallback, useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  LOAN_APPLICATION_PATH,
  LOAN_APPLICATION_TRACKING_PATH,
  LOAN_TOP_UP_PATH,
} from '../../navigation/constants';
import { Screen } from '../../navigation/types/screens';
import { useEnvConfig } from '../../utils/hooks/useEnvConfig';
import { isWeb as defaultIsWeb } from '../../utils/platformUtils';
import { REDIRECTED_FROM_NATIVE_APP_ATOM_KEY } from './atoms/redirectedFromNativeAppAtom';
import { useHomeNavigation } from './useHomeNavigation';

const getApplicationUrl = (appUrl: string) =>
  `${appUrl}/${LOAN_APPLICATION_PATH}?${REDIRECTED_FROM_NATIVE_APP_ATOM_KEY}=true`;

const getApplicationTrackingUrl = ({
  appUrl,
  loanApplicationId,
}: {
  appUrl: string;
  loanApplicationId: string;
}) =>
  `${appUrl}/${LOAN_APPLICATION_TRACKING_PATH.replace(
    ':loanApplicationId',
    loanApplicationId,
  )}?${REDIRECTED_FROM_NATIVE_APP_ATOM_KEY}=true`;

const getTopUpApplicationUrl = ({
  appUrl,
  cbaAccountId,
}: {
  appUrl: string;
  cbaAccountId: string;
}) =>
  `${appUrl}/${LOAN_APPLICATION_PATH}/${LOAN_TOP_UP_PATH}/${cbaAccountId}/intro?${REDIRECTED_FROM_NATIVE_APP_ATOM_KEY}=true`;

export function useNativeAppApplicationNavigation(
  { isWeb }: { isWeb: boolean } = { isWeb: defaultIsWeb },
) {
  const { flags } = useContext(FeatureFlagsContext);

  const isEnabled = flags.ENABLE_NATIVE_APP_ONBOARDING_HANDOFF;

  const { config } = useEnvConfig();
  const { appUrl } = config;

  const navigation = useHomeNavigation();

  const onSetupLoan = useCallback(() => {
    if (isWeb || !isEnabled) {
      navigation.navigate(Screen.LOAN_APPLICATION_V2, {
        screen: Screen.LOAN_APPLICATION_V2_SPLASH,
      });
    } else {
      Linking.openURL(getApplicationUrl(appUrl));
    }
  }, [appUrl, isEnabled, isWeb, navigation]);

  const onApplicationTracking = useCallback(
    (loanApplicationId: string) => {
      if (isWeb || !isEnabled) {
        navigation.navigate(Screen.APPLICATION_TRACKING, {
          loanApplicationId,
        });
      } else {
        Linking.openURL(
          getApplicationTrackingUrl({ appUrl, loanApplicationId }),
        );
      }
    },
    [appUrl, isEnabled, isWeb, navigation],
  );

  const onTopUpIntro = useCallback(
    (cbaAccountId: string) => {
      if (isWeb || !isEnabled) {
        navigation.navigate(Screen.LOAN_APPLICATION_V2, {
          screen: Screen.LOAN_APPLICATION_TOP_UP_INTRO,
          params: {
            cbaAccountId,
          },
        });
      } else {
        Linking.openURL(getTopUpApplicationUrl({ appUrl, cbaAccountId }));
      }
    },
    [appUrl, isEnabled, isWeb, navigation],
  );

  return {
    onSetupLoan,
    onApplicationTracking,
    onTopUpIntro,
  };
}
