import { useRef } from 'react';

import {
  CalculateAppSetupLoanApplicationAmountsV2QueryVariables,
  CalculateAppSetupLoanApplicationAmountsV3QueryVariables,
  useCalculateAppSetupLoanApplicationAmountsV2LazyQuery,
  useCalculateAppSetupLoanApplicationAmountsV3LazyQuery,
} from '../../generated/graphql';
import { captureException } from '../../sentry';

export function useLoanAmountCalculationV3() {
  const [
    calculateSetupLoanAmounts,
    { data: calculationData, loading: calculationLoading },
  ] = useCalculateAppSetupLoanApplicationAmountsV3LazyQuery({
    context: {
      sentryContext: {},
    },
    fetchPolicy: 'network-only',
  });
  const calculateLoanAmountRef = useRef(
    async (
      variables: CalculateAppSetupLoanApplicationAmountsV3QueryVariables,
    ) => {
      try {
        await calculateSetupLoanAmounts({
          variables,
          context: {
            sentryContext: {
              loanApplicationId: variables.data.loan_application_id,
            },
          },
        });
      } catch (e: unknown) {
        captureException('Calculate setup loan amounts', { variables }, e);
      }
    },
  );
  return {
    calculateLoanAmounts: calculateLoanAmountRef.current,
    calculationLoading,
    calculationData,
  };
}

/**
 *  Used in the top up flow which does not include a savings calculation.
 */
export function useLoanAmountCalculationV2() {
  const [
    calculateSetupLoanAmounts,
    { data: calculationData, loading: calculationLoading },
  ] = useCalculateAppSetupLoanApplicationAmountsV2LazyQuery({
    context: {
      sentryContext: {},
    },
    fetchPolicy: 'network-only',
  });
  const calculateLoanAmountRef = useRef(
    async (
      variables: CalculateAppSetupLoanApplicationAmountsV2QueryVariables,
    ) => {
      try {
        await calculateSetupLoanAmounts({
          variables,
          context: {
            sentryContext: {
              loanApplicationId: variables.data.loan_application_id,
            },
          },
        });
      } catch (e: unknown) {
        captureException('Calculate setup loan amounts', { variables }, e);
      }
    },
  );
  return {
    calculateLoanAmounts: calculateLoanAmountRef.current,
    calculationLoading,
    calculationData,
  };
}

export type UseLoanAmountCalculationV2Return = ReturnType<
  typeof useLoanAmountCalculationV2
>;

export type UseLoanAmountCalculationV3Return = ReturnType<
  typeof useLoanAmountCalculationV3
>;
