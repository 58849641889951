import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function FlashIcon({ color, size, ...props }: SvgIconProps) {
  const width = resizeIconWidth(24, 25, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 24 25" fill="none" {...props}>
      <Path
        d="M10.4992 1.00244C10.171 1.00244 9.8679 1.18057 9.71008 1.46964L3.10989 13.47C2.95676 13.7481 2.96301 14.0872 3.12395 14.3606C3.28646 14.6341 3.58022 14.8028 3.89898 14.8028H7.95691L4.26618 23.7609C4.10523 24.1578 4.2443 24.6141 4.60056 24.8516C4.95682 25.0891 5.43183 25.0422 5.73497 24.7391L20.7354 9.73863C20.9932 9.48081 21.0698 9.09486 20.9307 8.75891C20.7916 8.4214 20.4635 8.20265 20.0994 8.20265H16.2665L19.676 2.3556C19.8385 2.07747 19.8401 1.73371 19.6791 1.45402C19.5197 1.17432 19.2213 1.00244 18.8994 1.00244H10.4992ZM11.032 2.80249H17.3322L13.9227 8.64953C13.7602 8.92767 13.7586 9.27143 13.9196 9.55112C14.079 9.83082 14.3774 10.0027 14.6993 10.0027H17.9259L7.6444 20.2858L10.132 14.245C10.2445 13.9669 10.2132 13.6512 10.046 13.4012C9.87884 13.1528 9.59914 13.0028 9.29913 13.0028H5.4209L11.032 2.80249Z"
        fill={color}
      />
    </Svg>
  );
}
