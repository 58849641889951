import { Picker } from '@react-native-picker/picker';
import { SxProp } from 'dripsy';
import { ComponentProps } from 'react';

export type SelectProps = Expand<
  Pick<
    ComponentProps<typeof Picker>,
    'selectedValue' | 'onValueChange' | 'testID'
  > & {
    label: string;
    items: Array<
      Expand<Pick<ComponentProps<(typeof Picker)['Item']>, 'label' | 'value'>>
    >;
    sx?: SxProp;
    disabled?: boolean;

    // These are from formik
    // and used for styling the inputs
    focused?: boolean;
    error?: string;
  }
>;

export const SELECT_PLACEHOLDER_VALUE = '_PLACEHOLDER_VALUE';
