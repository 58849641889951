import AsyncStorageBase from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { AtomEffect, DefaultValue } from 'recoil';

/**
 * AsyncStorage on web use window.localStorage
 * In some specific configurations + browsers,
 * window.localStorage might be set to null or
 * throw permission denied error.
 */
const AsyncStorage = {
  getItem: async (key: string) => {
    try {
      return await AsyncStorageBase.getItem(key);
    } catch (_: unknown) {
      return undefined;
    }
  },
  setItem: async (key: string, value: string) => {
    try {
      return await AsyncStorageBase.setItem(key, value);
    } catch (_: unknown) {
      return undefined;
    }
  },
  removeItem: async (key: string) => {
    try {
      return await AsyncStorageBase.removeItem(key);
    } catch (_: unknown) {
      return undefined;
    }
  },
};

export const asyncStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const setSelfPromise = (async () => {
      try {
        const savedValue = await AsyncStorage.getItem(key);
        if (savedValue !== null && savedValue !== undefined) {
          return JSON.parse(savedValue);
        }

        return new DefaultValue();
      } catch {
        return new DefaultValue();
      }
    })();

    setSelf(setSelfPromise);

    // Subscribe to state changes and persist them to localForage
    onSet((newValue, _, isReset) => {
      try {
        if (isReset) {
          AsyncStorage.removeItem(key);
        } else {
          AsyncStorage.setItem(key, JSON.stringify(newValue));
        }
        return undefined;
      } catch {
        return undefined;
      }
    });
  };

export const useAsyncStorage = <T>(
  key: string,
  defaultValue: T,
): [T, (newValue: T) => void, boolean] => {
  const [state, setState] = useState({
    hydrated: false,
    storageValue: defaultValue,
  });
  const { hydrated, storageValue } = state;

  const pullFromStorage = async () => {
    const fromStorage = await AsyncStorage.getItem(key);
    let value = defaultValue;
    if (fromStorage) {
      value = JSON.parse(fromStorage);
    }
    setState({ hydrated: true, storageValue: value });
  };

  const updateStorage = async (newValue: T) => {
    setState({ hydrated: true, storageValue: newValue });
    await AsyncStorage.setItem(key, JSON.stringify(newValue));
  };

  useEffect(() => {
    pullFromStorage();
    // We only want to pull from storage once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storageValue, updateStorage, hydrated];
};
