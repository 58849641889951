import { gql } from '@apollo/client';
import { useCallback, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { CardModalScreenContainer } from '../../components/CardModalScreenContainer';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import {
  refetchGetLoanApplicationTasksQuery,
  refetchGetMeQuery,
  useSetIdValidationConsentMutation,
} from '../../generated/graphql';
import { AppStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { onfidoEmitter } from '../utils/idValidationEventEmitter';

type Props = AppStackScreenProps<Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL>;

export const SetIDValidationConsent = gql`
  mutation SetIDValidationConsent {
    set_id_validation_consent {
      identity_profile_id
      identity_profile {
        id
        id_validation_result
      }
    }
  }
`;

export function VerifyYourIdentityConsentModal({ navigation, route }: Props) {
  const [setIDVConsent, { loading }] = useSetIdValidationConsentMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const loanApplicationId = route.params?.loanApplicationId;
  const consentGrantedEvent = route.params?.consentGrantedEvent;

  const onVerifyPress = useCallback(async () => {
    const [res] = await safelyCallMutation(setIDVConsent, {
      refetchQueries: loanApplicationId
        ? [refetchGetLoanApplicationTasksQuery({ loanApplicationId })]
        : [refetchGetMeQuery()],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

    if (res?.data?.set_id_validation_consent == null) {
      setErrorMessage(t('Consent.VOI.Error.FailStoreConsent'));
      return;
    }

    if (consentGrantedEvent) {
      onfidoEmitter.emit(consentGrantedEvent, {
        identityProfileId:
          res.data.set_id_validation_consent.identity_profile_id,
      });
    }

    navigation.goBack();
  }, [consentGrantedEvent, loanApplicationId, navigation, setIDVConsent]);

  return (
    <CardModalScreenContainer px="xl" pb="l">
      <NavHeaderSpacer />

      <StyledText variant="header" mb="s" textAlign="center">
        {t('Consent.VOI.Title')}
      </StyledText>
      {errorMessage ? <ErrorRow message={errorMessage} /> : null}
      <StyledText mt="m" textAlign="center">
        {t('Consent.VOI.Content')}
      </StyledText>
      <StyledText mt="l" variant="caption" textAlign="center">
        {t('Consent.VOI.Footer')}
        <Link href={t('Link.PrivacyPolicy')} variant="caption">
          {t('Consent.VOI.FooterInlinePrivacyPolicyLink')}
        </Link>
        {t('Consent.VOI.Footer2')}
      </StyledText>
      <Button
        label={t('Consent.VOI.ButtonLabel')}
        width="100%"
        my="l"
        testID={TestID.VerifyYourIdentityConsent.VerifyButton}
        onPress={onVerifyPress}
        showSpinner={loading}
        disabled={loading}
      />
    </CardModalScreenContainer>
  );
}
