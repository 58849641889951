import { styled, Text, View } from 'dripsy';
import { ReactNode, useContext, useMemo } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE } from '../../components/YourLiabilityRow';
import { getExpenseFinancialRowDetailsFooter } from '../../Expenses/utils/getExpenseDetailsFooter';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { FeatureFlag } from '../../FeatureFlags/featureFlags';
import {
  Frequency_Enum,
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
} from '../../generated/graphql';
import { IncomeRow } from '../../Income/components/IncomeRow';
import { BorrowerListItem } from '../../LoanApplication/components/BorrowerListItem';
import {
  PropertyDetailsCaption,
  PropertyDetailsCaptionForPurchase,
} from '../../LoanApplication/components/PropertyDetailsCaption';
import PropertyStaticMap from '../../LoanApplication/components/PropertyStaticMap';
import { PurchaseYourUnloanCard } from '../../LoanApplication/components/PurchaseYourUnloanCard';
import { YourUnloanCard } from '../../LoanApplication/components/YourUnloanCard';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ListRow } from '../../ui/molecules/ListRow';
import { NewListRow, NewListRowProps } from '../../ui/molecules/NewListRow';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { isLast } from '../../utils/arrayHelpers';
import { formatCurrencyWithPeriod } from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { SectionDataProps } from '../remoteData';

type SummarySectionWrapperProps = {
  title: string;
  children: ReactNode;
  onAddButtonPress?: () => void;
  isOnEditMode?: boolean;
  addButtonTestID?: string;
};

const SpacedRowView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export function SummarySectionV2({
  title,
  onAddButtonPress,
  isOnEditMode,
  addButtonTestID,
  children,
}: SummarySectionWrapperProps) {
  const showHeaderRow = title || (isOnEditMode && onAddButtonPress);
  return (
    <View sx={{ mb: '$24', mt: '$8' }}>
      {showHeaderRow ? (
        <SpacedRowView sx={{ mb: '$16' }}>
          <Text variant="sHeader">{title}</Text>
          {isOnEditMode && onAddButtonPress ? (
            <Button
              icon="add"
              label={t('Content.Common.ButtonLabel.Add')}
              onPress={onAddButtonPress}
              variant="pill"
              secondary
              testID={addButtonTestID}
            />
          ) : null}
        </SpacedRowView>
      ) : null}
      {children}
    </View>
  );
}

type SummarySectionItemsCommon = {
  testIDPrefix?: string;
  isOnEditMode?: boolean;
};

function generateOnPressWithRightArrowProps<T, K>(
  onPress: (data: T, ...args: Array<K>) => void,
  data: T,
  otherArgs?: Array<K>,
): Pick<
  NewListRowProps,
  | 'onRowPress'
  | 'rightIconName'
  | 'rightIconFamily'
  | 'rightIconSize'
  | 'rightIconColor'
> & {
  onPress: () => void;
} {
  return {
    onRowPress: () => onPress(data, ...(otherArgs ?? [])),
    // onPress for income row
    onPress: () => onPress(data, ...(otherArgs ?? [])),
    rightIconName: 'chevronRight',
    rightIconFamily: 'svg',
    rightIconSize: 's',
    rightIconColor: 'secondaryContent',
  };
}

type YourPropertyProps = {
  data: SectionDataProps['property'];
  onPress?: (data: SectionDataProps['property']) => void;
  testID?: string;
  loanApplicationType: Loan_Application_Type_Enum | null;
} & Omit<SummarySectionItemsCommon, 'testIDPrefix'>;

function YourPropertySection({
  data,
  onPress,
  isOnEditMode,
  testID,
  loanApplicationType,
}: YourPropertyProps) {
  return (
    <Box borderRadius="card" bg="shapeBg" mt="s">
      <PropertyStaticMap
        location={data.propertyAddressLatLong || data.propertyDisplayAddress}
        locationName={data.addressLine1}
      />
      <ListRow
        arrowForwardColor="secondaryContent"
        isButton
        label={data.addressLine1}
        labelFontWeight="semiBold"
        caption={data.addressLine2}
        useArrow={isOnEditMode}
        color="primaryContent"
        onPress={onPress && isOnEditMode ? () => onPress(data) : undefined}
        inset
        last
        testID={testID}
      />
      {loanApplicationType === Loan_Application_Type_Enum.Purchase ? (
        <PropertyDetailsCaptionForPurchase
          propertyPurpose={data.propertyPurpose}
          propertyType={data.propertyType}
          propertyValue={data.propertyPurchasePrice}
          expectedRentalExpense={data.expectedRentalExpense}
          expectedRentalIncome={data.expectedRentalIncome}
          rentalExpenseFrequency={data.rentalExpenseFrequency}
          rentalIncomeFrequency={data.rentalIncomeFrequency}
        />
      ) : (
        <PropertyDetailsCaption
          propertyPurpose={data.propertyPurpose}
          propertyType={data.propertyType}
          propertyValue={data.propertyEstimatedValue}
        />
      )}
    </Box>
  );
}

type BorrowersProps = {
  data: SectionDataProps['borrowers'];
  onPress?: (data: SectionDataProps['borrowers'][number]) => void;
} & SummarySectionItemsCommon;

function BorrowersSection({
  data,
  onPress,
  isOnEditMode,
  testIDPrefix,
}: BorrowersProps) {
  if (data.length === 0) {
    return (
      <EmptySection label={t('Content.ApplicationSummary.Empty.Borrower')} />
    );
  }

  return (
    <>
      {data.map((borrower, index) => (
        <BorrowerListItem
          key={borrower.id}
          {...borrower}
          testIDPrefix={testIDPrefix}
          onBorrowerPress={onPress && isOnEditMode ? onPress : undefined}
          mt={0}
          mb={isLast(data, index) ? 0 : 's'}
        />
      ))}
    </>
  );
}

type IncomeProps = {
  data: SectionDataProps['financials']['incomes'];
  onPress?: (data: SectionDataProps['financials']['incomes'][number]) => void;
} & SummarySectionItemsCommon;

function IncomeSection({
  data,
  onPress,
  isOnEditMode,
  testIDPrefix,
}: IncomeProps) {
  if (data.length === 0) {
    return (
      <EmptySection label={t('Content.ApplicationSummary.Empty.Income')} />
    );
  }
  return (
    <>
      {data.map((income, index) => {
        const {
          id,
          incomeType,
          employmentIncome,
          rentalIncome,
          amount,
          frequency,
          formattedIncomeOwnerNames,
        } = income;
        return (
          <IncomeRow
            key={id}
            id={id}
            amount={amount}
            frequency={frequency}
            employmentIncome={employmentIncome}
            rentalIncome={rentalIncome}
            type={incomeType}
            formattedIncomeOwners={formattedIncomeOwnerNames}
            {...(onPress && isOnEditMode
              ? generateOnPressWithRightArrowProps(onPress, income)
              : null)}
            testID={`${testIDPrefix}-${id}`}
            mt={0}
            mb={isLast(data, index) ? 0 : 's'}
          />
        );
      })}
    </>
  );
}

type ExpenseProps = {
  data: SectionDataProps['financials']['households'];
  onPress?: (
    data: SectionDataProps['financials']['households'][number],
  ) => void;
  showDependentsAndFutureLivingSituation?: boolean;
} & SummarySectionItemsCommon;

function ExpenseSection({
  data,
  onPress,
  isOnEditMode,
  testIDPrefix,
  showDependentsAndFutureLivingSituation = false,
}: ExpenseProps) {
  const { flags } = useContext(FeatureFlagsContext);

  const enableShowDependentsAndFutureLivingSituation =
    flags[FeatureFlag.EnableViewExpensesInDynamics];

  const showNumOfDependentsAndFutureLivingSituation =
    enableShowDependentsAndFutureLivingSituation &&
    showDependentsAndFutureLivingSituation;

  if (data.length === 0) {
    return (
      <EmptySection label={t('Content.ApplicationSummary.Empty.Expense')} />
    );
  }
  return (
    <>
      {data.map((expense, index) => {
        const {
          id,
          householdExpensesMonthlyAmount,
          formattedHouseholdMemberNames,
          householdExpenseDetails,
          livingSituation,
          numberOfDependents,
        } = expense;

        return (
          <NewListRow
            key={id}
            leftIconFamily="svg"
            leftIconName="summaryExpenses"
            leftIconColor="primaryContent"
            leftIconSize="l"
            title={t('Content.ApplicationSummary.HouseholdIndex', {
              index: index + 1,
            })}
            caption={formatCurrencyWithPeriod(
              householdExpensesMonthlyAmount || 0,
              Frequency_Enum.Monthly,
              true,
            )}
            subcaption={formattedHouseholdMemberNames}
            testID={`${testIDPrefix}-${id}`}
            {...(onPress && isOnEditMode
              ? generateOnPressWithRightArrowProps(onPress, expense)
              : null)}
            footer={getExpenseFinancialRowDetailsFooter({
              householdExpense: householdExpenseDetails,
              livingSituation,
              testIDPrefix: `${testIDPrefix}-${id}`,
              numberOfDependents,
              showDependentsAndFutureLivingSituation:
                showNumOfDependentsAndFutureLivingSituation,
            })}
            shadowed={false}
            mt={0}
            mb={isLast(data, index) ? 0 : 's'}
          />
        );
      })}
    </>
  );
}

type DebtsAndLiabilitiesProps = {
  data: SectionDataProps['debtsAndLiabilities'];
  onPress?: (data: SectionDataProps['debtsAndLiabilities'][number]) => void;
} & SummarySectionItemsCommon;

function DebtsAndLiabilitiesSection({
  data,
  onPress,
  isOnEditMode,
  testIDPrefix,
}: DebtsAndLiabilitiesProps) {
  if (data.length === 0) {
    return (
      <EmptySection label={t('Content.ApplicationSummary.Empty.Liabilities')} />
    );
  }
  return (
    <>
      {data.map((liability, index) => {
        const { id, label, caption, owners, type } = liability;
        const parsedType = parseEnumType(Liability_Type_Enum, type);

        return (
          <NewListRow
            key={id}
            leftIconFamily="svg"
            leftIconName={
              parsedType
                ? DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE[parsedType]
                : 'summaryExpenses'
            }
            leftIconColor="primaryContent"
            leftIconSize="l"
            title={label}
            caption={caption}
            subcaption={owners}
            testID={`${testIDPrefix}-${id}`}
            {...(onPress && isOnEditMode
              ? generateOnPressWithRightArrowProps(onPress, liability)
              : null)}
            shadowed={false}
            mt={0}
            mb={isLast(data, index) ? 0 : 's'}
          />
        );
      })}
    </>
  );
}

type YourUnloanSectionProps = SummarySectionItemsCommon & {
  screen: Screen;
  loanData: SectionDataProps['loan'];
  financialDeclarationData: SectionDataProps['financialDeclaration'];
  onPress?: (
    loanData: SectionDataProps['loan'],
    financialDeclarationData: SectionDataProps['financialDeclaration'],
  ) => void;
  minimumRepaymentTestId: string;
  totalLoanAmountTestId: string;
  loanTermTestId: string;
  cardTestId: string;
  onDisclosureCheckboxPress?: () => void;
  disclosureCheckboxLoading?: boolean;
  onCheckboxLayout?: (event: LayoutChangeEvent) => void;
  showRequiredDisclosureError?: boolean;
  loanApplicationType: Loan_Application_Type_Enum | null;
  showLinkToLmiFactSheet?: boolean;
};

function YourUnloanSection({
  screen,
  loanData,
  financialDeclarationData,
  onPress: onPressProp,
  isOnEditMode,
  minimumRepaymentTestId,
  totalLoanAmountTestId,
  loanTermTestId,
  cardTestId,
  onDisclosureCheckboxPress,
  disclosureCheckboxLoading,
  onCheckboxLayout,
  showRequiredDisclosureError,
  loanApplicationType,
  showLinkToLmiFactSheet,
}: YourUnloanSectionProps) {
  const {
    mergedLiabilitiesForRefinancing,
    disclosureContent,
    mortgageRegistrationFee,
    mortgageDischargeFee,
  } = loanData;
  const disclosureData = useMemo(
    () => ({ mergedLiabilitiesForRefinancing, disclosureContent }),
    [mergedLiabilitiesForRefinancing, disclosureContent],
  );

  const onPress =
    isOnEditMode && onPressProp
      ? () => onPressProp(loanData, financialDeclarationData)
      : undefined;

  if (loanApplicationType === Loan_Application_Type_Enum.Purchase) {
    return (
      <PurchaseYourUnloanCard
        loanAmount={loanData.totalLoanAmount}
        lmiPremium={loanData.lmiPremium}
        minRepaymentAmount={loanData.minRepaymentAmount}
        minRepaymentCalcError={loanData.minRepaymentCalcError}
        termInMonths={loanData.termInMonths}
        interestRate={loanData.interestRate}
        rateAdjustment={loanData.rateAdjustment}
        productType={loanData.productType}
        canBePressed={!!isOnEditMode}
        onPress={onPress}
        governmentFees={loanData?.feesAndBufferAmount}
        showLinkToLmiFactSheet={showLinkToLmiFactSheet}
        totalLoanAmountTestID={totalLoanAmountTestId}
      />
    );
  }
  return (
    <YourUnloanCard
      screen={screen}
      {...loanData}
      minimumRepaymentTestId={minimumRepaymentTestId}
      totalLoanAmountTestId={totalLoanAmountTestId}
      loanTermTestId={loanTermTestId}
      canBePressed={!!isOnEditMode}
      onPress={onPress}
      disclosureData={disclosureData}
      onDisclosureCheckboxPress={onDisclosureCheckboxPress}
      disclosureCheckboxLoading={disclosureCheckboxLoading}
      onCheckboxLayout={onCheckboxLayout}
      showRequiredDisclosureError={showRequiredDisclosureError}
      cardTestID={cardTestId}
      loanApplicationType={loanApplicationType || undefined}
      mortgageRegistrationFee={mortgageRegistrationFee}
      mortgageDischargeFee={mortgageDischargeFee}
    />
  );
}

type EmptySectionProps = {
  label: string;
};

function EmptySection({ label }: EmptySectionProps) {
  return <EmptyState description={label} />;
}

export type SummarySectionItemOnPressTypes = {
  loan: YourUnloanSectionProps['onPress'];
  property: YourPropertyProps['onPress'];
  borrowers: BorrowersProps['onPress'];
  income: IncomeProps['onPress'];
  expense: ExpenseProps['onPress'];
  liabilities: DebtsAndLiabilitiesProps['onPress'];
};

SummarySectionV2.YourProperty = YourPropertySection;
SummarySectionV2.Borrowers = BorrowersSection;
SummarySectionV2.Income = IncomeSection;
SummarySectionV2.Expense = ExpenseSection;
SummarySectionV2.DebtsAndLiabilities = DebtsAndLiabilitiesSection;
SummarySectionV2.YourUnloan = YourUnloanSection;
SummarySectionV2.Empty = EmptySection;
