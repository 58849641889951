import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function LiabilityOtherLoanIcon({ color, ...otherProps }: SvgIconProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        d="M12 23.201c5.994 0 10.957-4.974 10.957-10.957 0-5.994-4.974-10.957-10.968-10.957-5.983 0-10.946 4.963-10.946 10.957 0 5.983 4.974 10.957 10.957 10.957zm-.01-4.63a.508.508 0 01-.527-.526v-.795c-1.483-.14-2.718-.956-3.03-2.234a1.21 1.21 0 01-.053-.344c0-.387.268-.655.644-.655.333 0 .559.171.688.558.183.73.763 1.29 1.75 1.429v-3.266l-.074-.021c-1.848-.44-2.804-1.279-2.804-2.686 0-1.514 1.203-2.567 2.879-2.75V6.52c0-.312.226-.527.526-.527.301 0 .527.215.527.527v.762c1.418.161 2.556.989 2.846 2.18.022.12.054.237.054.345 0 .386-.28.655-.655.655-.355 0-.57-.215-.677-.548-.226-.741-.774-1.214-1.568-1.353v3.083l.15.032c1.923.344 2.9 1.257 2.9 2.76 0 1.676-1.342 2.675-3.05 2.836v.774a.508.508 0 01-.527.526zm-.527-7.208V8.56c-.935.16-1.504.73-1.504 1.396 0 .655.44 1.096 1.429 1.386l.075.021zm1.053 1.633v3.008c1.128-.129 1.675-.72 1.675-1.493 0-.698-.365-1.16-1.439-1.45l-.236-.065z"
        fill={color}
      />
    </Svg>
  );
}
