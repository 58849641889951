import { gql } from '@apollo/client';

import { TestID } from '../../../testID/constants';
import {
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  useDeleteManuallyAddedLiabilityActionSheetMutationMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export const DELETE_MANUALLY_ADDED_LIABILITY_MUTATION_DOC = gql`
  mutation DeleteManuallyAddedLiabilityActionSheetMutation(
    $detectedLiabilityIdentifier: String!
    $loanApplicationId: uuid!
  ) {
    remove_manually_added_liability(
      detected_liability_identifier: $detectedLiabilityIdentifier
      loan_application_id: $loanApplicationId
    ) {
      success
    }
  }
`;

type DeleteManuallyAddedLiabilityActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET>;

export function DeleteManuallyAddedLiabilityActionSheet({
  navigation,
  route,
}: DeleteManuallyAddedLiabilityActionSheetProps) {
  const {
    detectedLiabilityIdentifier,
    loanApplicationId = '',
    returnToParams,
  } = route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const [commit, { loading }] =
    useDeleteManuallyAddedLiabilityActionSheetMutationMutation();

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onDeletePress = async () => {
    if (loanApplicationId == null || detectedLiabilityIdentifier == null) {
      return;
    }

    const [res, error] = await safelyCallMutation(commit, {
      variables: {
        detectedLiabilityIdentifier,
        loanApplicationId,
      },
      refetchQueries: [
        refetchYourDebtsQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          detectedLiabilityIdentifier,
        },
      },
    });

    if (!res?.data?.remove_manually_added_liability.success || error) {
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
        params: {
          ...returnToParams,
          mutationErrorFromParams: t(
            'Content.DeleteManuallyAddedLiabilityActionSheet.MutationError',
          ),
        },
      });
      return;
    }
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Debts,
      }),
    );
  };

  const goBack = () => navigation.goBack();

  const shouldDisableActionButton = loading || loadingLoanApplicationMetadata;

  return (
    <ActionSheet
      closeModal={goBack}
      preventCloseModal={loading}
      title={t('Content.DeleteManuallyAddedLiabilityActionSheet.Title')}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          testID: TestID.DeleteManuallyAddedLiabilityActionSheet.Cancel,
          disabled: shouldDisableActionButton,
          secondary: true,
          onPress: goBack,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Delete'),
          testID: TestID.DeleteManuallyAddedLiabilityActionSheet.Delete,
          disabled: shouldDisableActionButton,
          showSpinner: loading,
          secondary: true,
          isDestructive: true,
          onPress: onDeletePress,
          flex: 1,
        },
      ]}
    >
      {loadingLoanApplicationMetadata ? (
        <Spinner />
      ) : (
        <ActionSheet.MessageText pt="m">
          {t('Content.DeleteManuallyAddedLiabilityActionSheet.Message')}
        </ActionSheet.MessageText>
      )}
    </ActionSheet>
  );
}
