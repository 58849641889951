import { Top_Up_Reason_Enum } from 'src/generated/graphql';

const LiabilityFormTestIDs = {
  InstitutionPicker: 'institutionPicker',
  AccountNumberInput: 'account-number-input',
  BalanceInput: 'balance-input',
  RepaymentAmountInput: 'repayment-amount-input',
  LimitInput: 'limit-input',
  InterestRateInput: 'interest-rate-input',
  TermPickerYear: 'term-picker-year',
  TermPickerMonth: 'term-picker-month',
  AccountOwner: 'accountOwner',
  DeleteCredit: 'deleteCredit',
  LiabilityPropertyPicker: 'liabilityPropertyPicker',
  LoanType: 'loanType',
  FixedRateExpiryDate: 'fixedRateExpiryDate',
  FixedRateExpiryDateTooltip: 'fixedRateExpiryDateTooltip',
};

const HouseholdExpenseTestIDs = {
  BorrowersInThisHousehold: 'borrowersInThisHousehold',
  DependantsInput: 'householdExpenseDependantsInput',
  DecreaseButton: 'householdExpenseDependantsDecreaseButton',
  IncreaseButton: 'householdExpenseDependantsIncreaseButton',
  FutureLivingSituation: 'futureLivingSituation',
};

const FinaliseLoanApplicationTestIDs = {
  BackToDashboardButton: 'backToDashboardButton',
};

const PropertyTestIDs = {
  PropertyAddressPicker: 'propertyAddressPicker',
  PropertyType: 'propertyType',
  PropertyOwners: 'propertyOwners',
  ToggleInputType: 'switchPropertyInputType',
  ManualAddressInput: {
    UnitNo: 'manualAddressUnitNoInput',
    StreetNumber: 'manualAddressStreetNumberInput',
    StreetName: 'manualAddressStreetNameInput',
    StreetType: 'manualAddressStreetTypePicker',
    Suburb: 'manualAddressSuburbInput',
    State: 'propertyStateGroup',
    Postcode: 'manualAddressPostcodeInput',
  },
};

const SuburbTestIDs = {
  SuburbPicker: 'suburbPicker',
  ManualSuburbInput: {
    Suburb: 'manualSuburbSuburbInput',
    State: 'manualSuburbStateGroup',
    Postcode: 'manualSuburbPostcodeInput',
  },
};

const IncomeFormCommon = {
  ScreenLoading: 'screenIncomeLoading',
  SubmitButton: 'submitIncomeButton',
  DeleteButton: 'deleteIncomeButton',
  ErrorText: 'errorIncomeText',
};

const EmploymentIncomeForm = {
  ...IncomeFormCommon,
  IsCurrentlyEmployed: 'isCurrentlyEmployedInput',
  EmployerName: 'employerName',
  EmploymentType: 'employmentType',
  SelectedEmploymentType: 'selectedEmploymentType',
  OccupationPicker: 'occupationPicker',
  HasNovatedLeases: 'hasNovatedLeases',
  NovatedLeaseMonthlyRepayment: 'novatedLeaseMonthlyRepayment',
  RemoveNovatedLeaseMonthlyRepayment: 'removeNovatedLeaseMonthlyRepayment',
  IsPayingHECS: 'isPayingHECS',
  HasVoluntaryDeductions: 'hasVoluntaryDeductions',
  CanStopVoluntaryDeductions: 'canStopVoluntaryDeductions',
  HECSBalance: 'hecsBalanceInput',
  SalaryInput: 'salaryInput',
  OvertimeInput: 'overtimeInput',
  BonusesInput: 'bonusesInput',
  CommissionsInput: 'commissionsInput',
  AllowancesInput: 'allowancesInput',
  PaidTo: 'paidTo',
};

const ExpectedRentalIncomeForm = {
  ...IncomeFormCommon,
  RentalIncomeValueInput: 'rentalIncomeInput',
  RentalIncomeFrequency: 'rentalIncomeFrequencyInput',
  RentalExpensesValueInput: 'rentalExpensesInput',
  RentalExpensesFrequency: 'rentalExpensesFrequencyInput',
  EstimatedLoanAmount: 'estimatedLoanAmountInput',
  ...SuburbTestIDs,
};

const RentalIncomeForm = {
  ...IncomeFormCommon,
  HaveRentalIncome: 'haveRentalIncomeInput',
  RentalIncomeValueInput: 'rentalIncomeInput',
  RentalIncomeFrequency: 'rentalIncomeFrequencyInput',
  RentalExpensesValueInput: 'rentalExpensesInput',
  RentalExpensesFrequency: 'rentalExpensesFrequencyInput',
  ...PropertyTestIDs,
};

const OtherIncomeForm = {
  ...IncomeFormCommon,
  AmountInput: 'incomeAmountInput',
  AmountFrequencyInput: 'incomeAmountFrequencyInput',
  IncomeOwner: 'incomeOwner',
};

const LoanApplicationTaskItem = {
  IDVButton: 'idVerificationButton',
  IDVSuccessIcon: 'idVerificationSuccessIcon',
  UploadIncomeDocumentButton: 'uploadIncomeDocumentButton',
  UploadIncomeDocumentSuccessIcon: 'uploadIncomeDocumentSuccessIcon',
  UploadSecurityDocumentButton: 'uploadIncomeDocumentButton',
  UploadSecurityDocumentSuccessIcon: 'uploadSecurityDocumentSuccessIcon',
  LoanApplicationTaskHeader: 'loanApplicationTaskHeader',
};

export const TestID = {
  AccountPermissions: {
    Spinner: 'accountPermissionsSpinner',
    SubmitForm: 'accountPermissionsSubmitForm',
    Notification: 'accountPermissionsNotification',
    ErrorMessage: 'accountPermissionsErrorMessage',
    AutoPay: {
      Title: 'accountPermissionsAutoPayTitle',
      AllToAuthorizeOption: 'accountPermissionsAutoPayAllToAuthorizeOption',
      OneToAuthorizeOption: 'accountPermissionsAutoPayOneToAuthorizeOption',
    },
    Withdrawal: {
      Title: 'accountPermissionsWithdrawalsTitle',
      AllToAuthorizeOption: 'accountPermissionsWithdrawalsAllToAuthorizeOption',
      OneToAuthorizeOption: 'accountPermissionsWithdrawalsOneToAuthorizeOption',
    },
    LeavePage: {
      LeaveButton: 'accountPermissionsLeavePageLeaveButton',
      StayButton: 'accountPermissionsLeavePageStayButton',
    },
    ConfirmChanges: {
      ConfirmButton: 'accountPermissionsConfirmChangesConfirmButton',
      CancelButton: 'accountPermissionsConfirmChangesCancelButton',
    },
    ApprovalScreen: {
      ApprovalResponses: 'accountPermissionsApprovalScreenApprovalResponses',
      ReviewPermissions: 'accountPermissionsApprovalScreenReviewPermissions',
      CancelButton: 'accountPermissionsApprovalScreenCancelButton',
      DeclineButton: 'accountPermissionsApprovalScreenDeclineButton',
      ApproveButton: 'accountPermissionsApprovalScreenApproveButton',
    },
    ApprovalActionSheet: {
      CancelButton: 'accountPermissionsApprovalActionSheetCancelButton',
      ConfirmButton: 'accountPermissionsApprovalActionSheetConfirmButton',
    },
  },
  ActionSheet: {
    CloseButton: 'actionSheetCloseButton',
    ContentContainer: 'actionSheetContentContainer',
    FooterTextComponentWrapper: 'footerTextComponentWrapper',
    DataSharingConfirmation: {
      Message: 'dataSharingConfirmationMessage',
      Spinner: 'dataSharingConfirmationSpinner',
      Error: 'dataSharingConfirmationError',
      MutationError: 'dataSharingConfirmationMutationError',
      GreenCheck: 'dataSharingGreenCheck',
    },
    GreenTicketConfirmation: 'greenTicketConfirmation',
  },
  AddManualLiabilityV2: {
    LoanType: 'loanType',
    MutationErrorRow: 'addManualLiabilityV2MutationError',
  },
  AdditionalCashoutForm: {
    TopUpReasons: 'topUpReasons',
    OtherTopUpReasons: 'otherTopUpReasons',
  },
  AddDirectDebitAccountConfirmationActionSheet: {
    ConfirmButton: 'add-account-confirmation',
  },
  AddNewIncome: {
    EmploymentIncome: 'employmentIncome',
    RentalIncome: 'rentalIncome',
    Dividends: 'dividends',
    GovernmentPayments: 'governmentPayments',
  },
  AmountPicker: {
    AddButton: 'amountPickerAddButton',
    SubstractButton: 'amountPickerSubstractButton',
  },
  ApplicationDetails: {
    DoneButton: 'applicationDetailsDoneButton',
  },
  ApplyCard: {
    LoanApplicationHeader: 'loanApplicationHeader',
    Card: 'loanApplicationCard',
    ApplyLoanButton: 'applyLoanButton',
  },
  ApplyForAnotherLoanButton: 'applyLoanButtonInline',
  AutopayCancellationConfirmationActionSheet: {
    Confirm: 'confirm-autopay-cancel',
  },
  BackButton: 'backButton',
  BorrowerAddressForm: {
    UseAsMailingAddress: 'useAsMailingAddress',
  },
  BorrowerDetailsForm: {
    FirstNameInput: 'borrowerDetailsFormFirstNameInput',
    MiddleNameInput: 'borrowerDetailsFormMiddleNameInput',
    LastNameInput: 'borrowerDetailsFormLastNameInput',
    OtherNamesInput: 'borrowerDetailsOtherNamesInput',
    AddOtherNames: 'borrowerDetailsFormAddOtherNames',
    CancelAddOtherNames: 'borrowerDetailsFormCancelAddOtherNames',
    DateOfBirthInput: 'borrowerDetailsFormDateOfBirthInputInput',
    GenderOption: 'borrowerDetailsFormGenderOption',
    CitizenshipInput: 'borrowerDetailsFormCitizenshipInput',
    ResidentialAddressInput: 'borrowerDetailsFormResidentialAddressInput',
    MailingAddressInput: 'borrowerDetailsFormMailingAddressInput',
    UseAsMailingAddressCheckBox:
      'borrowerDetailsFormUseAsMailingAddressCheckBox',
    NextButton: 'borrowerDetailsFormNextButton',
  },
  BorrowerDetailsEditForm: {
    MailingAddressInput: 'borrowerDetailsEditFormMailingAddressInput',
    DoneButton: 'borrowerDetailsEditFormDoneButton',
  },
  CoBorrowerDetails: {
    DoneButton: 'coBorrowerDetailsDoneButton',
    RemoveCoBorrowerButton: 'coBorrowerDetailsRemoveCoBorrowerButton',
  },
  CoBorrowerInformationConfirmation: {
    ContinueButton: 'coBorrowerInformationConfirmationContinueButton',
    ReviewButton: 'coBorrowerInformationConfirmationReviewButton',
  },
  BorrowerDOBForm: { BorrowerDOBPicker: 'borrowerDOBPicker' },
  BorrowerDriverLicenseForm: {
    StateOfIssue: 'stateOfIssue',
    LicenseNo: 'licenseNo',
    CardNumber: 'borrowerDriverLicenseFormCardNumber',
  },
  BorrowerDriverLicenceForm: {
    StateOfIssueInput: 'borrowerDriverLicenseFormStateOfIssue',
    LicenceNoInput: 'borrowerDriverLicenseFormLicenseNo',
    DriverLicenceCardNoInput: 'borrowerDriverLicenseFormCardNumber',
    ExpiryDateInput: 'borrowerDriverLicenseFormExpiryDate',
    DoneButton: 'borrowerDriverLicenseFormDoneButton',
  },
  BorrowerPassportForm: {
    PassportNoInput: 'borrowerPassportFormPassportNo',
    ExpiryDateInput: 'borrowerPassportFormExpiryDate',
    DoneButton: 'borrowerPassportFormDoneButton',
  },
  BorrowerIdentityDetailsForm: {
    IdentityType: 'borrowerIdentityDetailsFormIdentityType',
  },
  BorrowerGenderForm: { Gender: 'gender' },
  BorrowerList: { AddAnotherBorrowerButton: 'addAnotherBorrowerButton' },
  Borrowers: { checkIcon: 'checkIcon' },
  ChatButton: 'chatButton',
  CheckBox: 'checkBox',
  CheckMark: 'checkMark',
  CollapsibleSelectLoanToRefi: {
    SelectedLiabilityCollapsible: 'selectedLiabilityCollapsible',
    SelectedLiabilityIds: 'selectedLiabilityIds',
    LiabilityToBeSelected: 'liabilityToBeSelected',
  },
  CompletedLoanApplication: {
    ...FinaliseLoanApplicationTestIDs,
  },
  CompletedContractSigning: {
    ...FinaliseLoanApplicationTestIDs,
  },
  CreditForm: {
    ...LiabilityFormTestIDs,
  },
  CreditCheckConfirmationActionSheet: {
    ConfirmButton: 'confirmCreditCheckButton',
  },
  CustomRepaymentFormInput: {
    CustomRepaymentInput: 'customRepaymentInput',
    CustomRepaymentFrequencyTrigger: 'customRepaymentFrequencyTrigger',
    CustomRepaymentFrequency: 'customRepaymentFrequency',
  },
  Dashboard: {
    Header: 'dashboardHeader',
    ActiveLoanCard: 'dashboardActiveLoanCard',
    ApproverNotification: 'dashboardApproverNotification',
    LoanCard: 'dashboardLoanCard',
    LoanCardError: 'dashboardLoanCardError',
    ScreenError: 'dashboardScreenError',
    ScheduledMaintenance: 'dashboard-scheduled-maintenance',
    LoanAccountLoading: 'dashboardLoanAccountLoading',
    IDVTaskTile: 'dashboardIDVTaskTile',
    IDVTaskTileErrorRow: 'dashboardIDVTaskTileErrorRow',
    LoanApplicationTaskRequest: 'loanApplicationTaskRequest',
  },
  DataSharingDetails: { StopDataSharingButton: 'stop-data-sharing-button' },
  DataSharingConfirmation: {
    CurrentStatus: {
      Spinner: 'data-sharing-confirmation-spinner',
      StatusList: 'data-sharing-confirmation-status-list',
    },
    Summary: {
      Spinner: 'data-sharing-summary-spinner',
    },
    Confirmation: {
      GreenCheck: 'data-sharing-confirmation-green-check',
    },
  },
  DebtsManualEntryRequiredActionSheet: {
    EnterManuallyButton: 'enterManuallyButton',
  },
  EnableFaceId: {
    FaceIdImage: 'enableFaceIdFaceIdImage',
    EnableButton: 'enableFaceIdEnableButton',
    MaybeLaterButton: 'enableFaceIdMaybeLaterButton',
  },
  NoDetectedDebts: {
    HaveAnyLoans: 'hasAnyLoans',
    MutationErrorRow: 'noDetectedDebtsMutationErrorRow',
  },
  DetectedDebtsForm: {
    ...LiabilityFormTestIDs,
  },
  EditLiabilityV2: {
    MutationErrorRow: 'editLiabilityV2MutationError',
  },
  EmploymentIncomeForm: {
    ...EmploymentIncomeForm,
  },
  ExpectedRentalIncomeForm: {
    ...ExpectedRentalIncomeForm,
  },
  RentalIncomeForm: {
    ...RentalIncomeForm,
  },
  OtherIncomeForm: {
    ...OtherIncomeForm,
  },
  AddIncomeForm: {
    ...EmploymentIncomeForm,
    ...RentalIncomeForm,
    ...OtherIncomeForm,
    IncomeTypeInput: 'incomeTypeRadioInput',
    SpeakWithOurTeam: 'speakWithOurTeamIntercomButton',
  },
  DeleteIncomeActionSheet: {
    Cancel: 'deleteIncomeActionSheetCancelButton',
    Delete: 'deleteIncomeActionSheetDeleteButton',
  },
  EmploymentIncomeOccupationForm: { OccupationPicker: 'occupationPicker' },
  ErrorRow: 'errorRow',
  FinaliseLoanApplication: {
    ...FinaliseLoanApplicationTestIDs,
    LoanApplicationSubmitButton: 'loanApplicationSubmitButton',
    ResumeLaterButton: 'resumeLaterButton',
    LoadingIndicator: 'loadingIndicator',
    FinaliseLoanApplicationErrorRow: 'finaliseLoanApplicationErrorRow',
  },
  FinancialBankListItem: { Spinner: 'financialBankListItemSpinner' },
  FinancialWizardEmploymentWizardIncomeDetails: {
    SalaryInput: 'salaryInput',
    OvertimeInput: 'overtimeInput',
    BonusesInput: 'bonusesInput',
    CommissionsInput: 'commissionsInput',
    AllowancesInput: 'allowancesInput',
    ScreenLoading: 'financialsWizardEmploymentIncomeFormLoading',
  },
  FinancialsWizardCheckEmployment: {
    CurrentlyEmployedYes: 'currentlyEmployed-Yes',
    CurrentlyEmployedNo: 'currentlyEmployed-No',
  },
  FinancialsWizardCheckRentalIncome: {
    IncomeYes: 'financialWizardCheckRentalIncomeYes',
    IncomeNo: 'financialWizardCheckRentalIncomeNo',
  },
  FinancialsWizardHigherEduRepayments: {
    LoanBalance: 'financialWizardLoanBalance',
  },
  FlaggedAsIncorrectInfoRow: {
    Spinner: 'flaggedAsIncorrectSpinner',
  },
  FormNavButtonHandler: { ChildTestId: 'childTestId' },
  HamburgerMobileMenuButton: 'mobileMenuButton',
  HeaderButton: { Spinner: 'headerButtonSpinner' },
  Home: {
    Alerts: 'homeAlerts',
    Avatar: 'homeHeaderAvatar',
    SupportIconButton: 'homeHeaderSupportIconButton',
    ProfileIconButton: 'homeHeaderProfileIconButton',
  },
  HomeLoan: {
    ScreenContainer: {
      Mobile: 'homeLoanPageMobile',
      Desktop: 'homeLoanPageDesktop',
    },
    Header: 'homeLoanHeaderContainer',
    AccountInfo: {
      Name: 'homeLoanAccountName',
      Balance: 'homeLoanBalance',
      RedrawBalance: 'homeLoanRedrawBalance',
    },
    TransferButton: 'homeLoanTransferButton',
    ManageButton: 'homeLoanManageButton',
    BankAccountCard: 'homeLoanBankAccountCard',
    CopyBSBAndAccountNumber: 'homeLoanCopyBSBAndAccountNumber',
    PendingWithdrawalsList: 'homeLoanPendingWithdrawalsList',
    Repayment: {
      Upcoming: 'homeLoanUpcomingRepayment',
      Loading: 'homeLoanUpcomingRepaymentLoading',
      List: 'homeLoanUpcomingRepaymentList',
    },
    NextRepayment: {
      MinimumRepaymentAmount: 'nextRepaymentMinimumRepaymentAmount',
      DueDate: 'nextRepaymentLoanDueDate',
      SetupAutopayButton: 'nextRepaymentSetupAutopayButton',
    },
    RecentTransactions: {
      Processing: 'homeLoanRecentTransactionsProcessing',
      Loading: 'homeLoanRecentTransactionsLoading',
      NoItems: 'homeLoanRecentTransactionsNoItems',
      List: 'homeLoanRecentTransactionsList',
    },
    ApprovalRequest: {
      ViewAndApproveButton: 'viewAndApproveButton',
    },
    Notifications: 'homeLoanNotifications',
  },
  HomeLoanTitle: {
    AccountDetailsIcon: 'AccountDetailsIcon',
    AccountDetailsText: 'accountDetailsText',
  },
  ExpensesForm: {
    ...HouseholdExpenseTestIDs,
    ExpenseCurrencyInput: 'expensesFormExpenseCurrencyInput',
    FrequencyInput: 'expensesFormFrequencyInput',
    GuideText: 'expensesFormGuideText',
    DoneButton: 'expensesFormDoneButton',
    NextButton: 'expensesFormNextButton',
    TotalMonthlyExpensesAmount: 'expensesFormTotalMonthlyExpensesAmount',
    DeleteHousehold: 'expensesFormDeleteHousehold',
    WarningRow: 'expensesFormWarningRow',
    ErrorRow: 'expensesFormErrorRow',
  },
  DeleteExpense: {
    DeleteButton: 'deleteExpenseDeleteButton',
    CancelButton: 'deleteExpenseCancelButton',
  },
  HouseholdExpense: {
    ...HouseholdExpenseTestIDs,
  },
  IDVerificationConsentActionSheet: {
    AmlCtfLink: 'amlCtfLink',
    ManualKYCLink: 'manualKYCLink',
  },
  IdentityVerificationPendingActionSheet: {
    ContinueButton: 'ContinueIdentityVerificationPending',
  },
  IncomeSupportingDocumentUpload: {
    UploadButton: 'incomeSupportingDocumentUploadButton',
    DoneButton: 'incomeSupportingDocumentDoneButton',
  },
  LiabilitySupportingDocumentUpload: {
    UploadButton: 'liabilitySupportingDocumentUploadButton',
    DoneButton: 'liabilitySupportingDocumentDoneButton',
  },
  InterstitialLoanSubmitted: {
    SubmittedDoneButton: 'loanApplicationSubmittedDoneButton',
    Loading: 'loanApplicationSubmittedLoading',
  },
  Intro: {
    BeforeWeBeginContinueButton: 'loanApplicationBeforeWeBeginContinueButton',
    CheckMyEligibilityButton:
      'loanApplicationBNeforeWeBeginCheckMyEligibilityButton',
  },
  InviteCoBorrower: {
    FirstNameInput: 'inviteCoBorrowerFirstNameInput',
    LastNameInput: 'inviteCoBorrowerLastNameInput',
    MobileNumberInput: 'inviteCoBorrowerMobileNumber',
    EmailInput: 'inviteCoBorrowerEmailInput',
    DoneButton: 'inviteCoBorrowerDoneButton',
  },
  JoinApplicationContent: {
    Button: 'joinApplicationButton',
    Image: 'joinApplicationImage',
    LogoutButton: 'joinApplicationLogoutButton',
  },
  LiabilityList: { MockLiabilities: 'mockLiabilities' },
  LoanApplicationTaskItem: {
    ...LoanApplicationTaskItem,
  },
  LoanApplicationNavigator: { Loading: 'loanApplicationV2NavigatorLoading' },
  LoanApplicationBorrowers: {
    ContinueButton: 'loanApplicationBorrowersContinueButton',
    BorrowerItem: 'loanApplicationBorrowerBorrowerItem',
  },
  LoanApplicationPropertyCheck: {
    EmptyStateIllustration:
      'loanApplicationPropertyCheckEmptyStateIllustration',
    ContinueButton: 'loanApplicationYourPropertyContinueButton',
    SelectedPropertyDetails: 'loanApplicationYourPropertyDetails',
    AddressDetails: 'loanApplicationYourPropertyAddressDetails',
  },
  LoanApplicationScreenContainer: {
    ScreenContainer: 'LoanApplicationScreenContainerScreenContainer',
    ContentVariant1: 'LoanApplicationScreenContentVariant1',
    ContentVariant2: 'LoanApplicationScreenContentVariant2',
    ContentVariant3: 'LoanApplicationScreenContentVariant3',
  },
  LoanApplicationSplash: {
    ScreenContainer: 'loanApplicationSplashScreenContainer',
    PurchaseButton: 'loanApplicationSplashPurchaseButton',
    RefiButton: 'loanApplicationSplashRefiButton',
    SplashAnimationImage: 'splashAnimationImage',
    ContinueButton: 'loanApplicationSplashContinueButton',
    LoanApplicationTypeOption: 'loanApplicationSplashLoanApplicationTypeOption',
    InitialPropertyPurposeOption:
      'loanApplicationSplashInitialPropertyPurposeOption',
  },
  LoanApplicationYourDebts: {
    YourDebtsLoading: 'yourDebtsLoading',
    ContinueButton: 'loanApplicationYourDebtsContinueButton',
    LiabilityList: 'liabilities',
    AddDebtButton: 'AddDebtButton',
    YourDebtsEmptyState: 'yourDebtsEmptyState',
  },
  LoanApplicationYourExpenses: {
    AddExpensesButton: 'loanApplicationYourExpensesAddExpensesButton',
    ContinueButton: 'loanApplicationYourExpensesContinueButton',
    HouseholdItem: 'loanApplicationYourExpensesItem',
  },
  LoanApplicationYourIncome: {
    Caption: 'loanApplicationYourIncomeCaption',
    ContinueButton: 'loanApplicationYourIncomeContinueButton',
    AddIncomeButton: 'addIncomeButton',
    EquifaxCaption: 'loanApplicationEquifaxCaption',
    EquifaxIncomeVerification: {
      ConsentCaptionLink: 'loanApplicationEquifaxIncomeConsentCaptionLink',
    },
    EmptyState: 'loanApplicationYourIncomeEmptyState',
  },
  LoanApplicationConditionalApproval: {
    Illustration: 'loanApplicationConditionalApprovalIllustration',
    ViewApplication: 'loanApplicationConditionalApprovalViewApplication',
    YourApplication: 'loanApplicationConditionalApprovalYourApplication',
    ContinueButton: 'loanApplicationConditionalApprovalContinueButton',
    BackToDashboardButton:
      'loanApplicationConditionalApprovalBackToDashboardButton',
    LetsTalk: 'loanApplicationConditionalApprovalLetsTalk',
    ConditionallyApprovedAmount: 'loanApplicationConditionalApprovalAmount',
    AppDetailsInfoButton:
      'loanApplicationConditionalApprovalAppDetailsInfoButton',
    FoundYourHomeInfoButton:
      'loanApplicationConditionalApprovalFoundYourHomeInfoButton',
    VerifyFinancialsInfoButton:
      'loanApplicationConditionalApprovalVerifyFinancialsInfoButton',
    CalculatorsInfoButton:
      'loanApplicationConditionalApprovalCalculatorsInfoButton',
    SearchPropertyButton:
      'loanApplicationConditionalApprovalSearchPropertyButton',
  },
  VerifyYourFinancials: {
    FinishButton: 'verifyFinancialFinishButton',
    LoadingIndicator: 'verifyFinancialLoadingIndicator',
    ErrorRow: 'verifyFinancialErrorRow',
  },
  LoanCircularProgressBar: {
    Container: 'loanCircularProgressBarContainer',
    LoanAmount: 'loanAmount',
    RedrawAvailable: 'redrawAvailable',
  },
  LoanDetails: {
    Product: 'loanDetailsProduct',
    AccountAddressBook: 'loanDetailsAddressAccountBook',
    AccountName: 'loanDetailsAccountName',
    AccountNumberWithBsb: 'loanDetailsAccountNumberWithBsb',
    AccountPermissions: 'loanDetailsAccountPermissions',
    AccountBalance: 'loanDetailsAccountBalance',
    InterestRate: 'loanDetailsInterestRate',
    Repayments: 'loanDetailsRepayments',
    LoanTerm: 'loanDetailsLoanTerm',
    AccountOwnersList: 'loanDetailsAccountOwnersList',
    Statements: 'loanDetailsStatements',
    SetupAutopay: 'loanDetailsSetupAutopay',
    ExistingAutopay: 'loanDetailsExistingAutopay',
    ReduceYourRepaymentsTitle: 'loanDetailsReduceYourRepaymentsTitle',
    ReduceYourRepaymentsDataRow: 'loanDetailsReduceYourRepaymentsDataRow',
    ReduceYourRepaymentsInsufficientText:
      'loanDetailsReduceYourRepaymentsInsufficientText',
    TopUpYourLoanButton: 'topUpYourLoanButton',
  },
  LoanDetailsForm: {
    InstitutionInput: 'institutionInput',
    PropertyAddressInput: 'propertyAddressInput',
    AccountNumberInput: 'accountNumberInput',
    BalanceInput: 'balanceInput',
    AvailableBalanceInput: 'AvailableBalanceInput',
    LimitInput: 'limitInput',
    MonthlyRepaymentInput: 'monthlyRepaymentInput',
    InterestRateInput: 'interestRateInput',
    LoanTermYearPartPicker: 'loanTermYearPart',
    LoanTermMonthPartPicker: 'loanTermMonthPart',
    AccountOwner: 'accountOwner',
    LoanRateType: 'loanRateType',
    FixedRateExpiryDateInput: 'fixedRateExpiryDateInput',
    SubmitButton: 'loanDetailsSubmitButton',
    DeleteButton: 'loanDetailsDeleteButton',
  },
  LoanVariation: {
    ReduceRepayments: {
      PendingMyApprovalCard:
        'loanVariationReduceRepaymentsPendingMyApprovalCard',
      PendingCoBorrowerApprovalCard:
        'loanVariationReduceRepaymentsPendingCoBorrowerApprovalCard',
      AmountToBeReducedInput:
        'loanVariationReduceRepaymentsAmountToBeReducedInput',
      NewMonthlyRepayments: 'loanVariationReduceRepaymentsNewMonthlyRepayments',
      ReducedMonthlyRepayments:
        'loanVariationReduceRepaymentsReducedMonthlyRepayments',
      SubmitButton: 'loanVariationReduceRepaymentsSubmitButton',
    },
    ConfirmRepayment: {
      SubmitButton: 'loanVariationConfirmRepaymentSubmitButton',
    },
    RepaymentApproval: {
      CancelButton: 'loanVariationRepaymentApprovalCancelButton',
      ApproveButton: 'loanVariationRepaymentApprovalApproveButton',
      DeclineButton: 'loanVariationRepaymentApprovalDeclineButton',
    },
    ApproveRequest: {
      CancelButton: 'loanVariationApproveRequestCancelButton',
      ConfirmButton: 'loanVariationApproveRequestConfirmButton',
    },
    CancelRequest: {
      CancelButton: 'loanVariationCancelRequestCancelButton',
      ConfirmButton: 'loanVariationCancelRequestConfirmButton',
    },
    ConfirmRequest: {
      CancelButton: 'loanVariationConfirmRequestCancelButton',
      ConfirmButton: 'loanVariationConfirmRequestConfirmButton',
    },
    DeclineRequest: {
      CancelButton: 'loanVariationDeclineRequestCancelButton',
      ConfirmButton: 'loanVariationDeclineRequestConfirmButton',
    },
  },
  DeleteManuallyAddedLiabilityActionSheet: {
    Cancel: 'deleteManuallyAddedLiabilityCancelButton',
    Delete: 'deleteManuallyAddedLiabilityDeleteButton',
  },
  Statements: {
    StatementPreviewMobile: 'statementPreviewMobile',
    StatementList: 'statementList',
    GenerateStatementButton: 'generateStatementButton',
    StatementsFilter: {
      StartDateInput: 'statementsFilterStartDateInput',
      StartDateInputAlert: 'statementsFilterStartDateInputAlert',
      EndDateInput: 'statementsFilterEndDateInput',
      EndDateInputAlert: 'statementsFilterEndDateInputAlert',
      DoneButton: 'statementsFilterDoneButton',
    },
  },
  LowExpensesActionSheet: {
    ContinueButton: 'lowExpenseModalContinueButton',
    ReviewButton: 'lowExpenseModalReviewButton',
  },
  NoDebtsConfirmationActionSheet: {
    ContinueButton: 'noDebtsConfirmationActionSheetContinueButton',
    BackButton: 'noDebtsConfirmationActionSheetBackButton',
  },
  ManageDataSharing: {
    SegmentedControl: 'segmented-control',
    DataSharingPreferences: 'data-sharing-preferences',
    DataSharingAccountList: 'data-sharing-account-list',
    DataSharingNoAccountsMessage: 'data-sharing-no-accounts-message',
    DataSharingAccountDetailsNotFound:
      'data-sharing-accounts-details-not-found',
    DataSharingStatus: 'data-sharing-status',
    DataSharingMessage: 'data-sharing-message',
    DataSharingChangeStatus: 'data-sharing-change-status',
    DataSharingPendingRequestMessage: 'data-sharing-pending-request-message',
    DataSharingFooterFurtherRecords: 'data-sharing-footer-further-records',
    DataSharingFooterLearnMore: 'data-sharing-footer-learn-more',
  },
  Insights: {
    InsightWidgetContainer: 'insightWidgetContainer',
    InsightWidgetLoading: 'insightWidgetLoading',
    InterestSavings: {
      ProjectedTotalInterestSavings: 'projectedTotalInterestSavings',
      Disclaimer: 'interestSavingsDisclaimer',
    },
    TermRemaining: {
      WidgetContainer: 'termRemainingWidgetContainer',
      SuggestAdditionalPayment: 'suggestExtraPayment',
      LineGraph: 'termRemainingLineGraph',
      DisabledLineGraph: 'termRemainingDisabledLineGraph',
      SayGoodbyeIn: 'sayGoodbyeIn',
      Disclaimer: 'termRemainingDisclaimer',
      GrayBadgeExplanation: 'grayBadgeExplanation',
      GradientBadgeExplanation: 'gradientBadgeExplanation',
    },
    DiscountBump: {
      DiscountHeader: 'discountBumpHeader',
      DiscountData: 'discountBumpData',
      NextDiscountDescription: 'nextDiscountDescription',
      CircularGraph: 'discountBumpCircularGraph',
      InfoIcon: 'InfoIcon',
      InfoText: 'InfoText',
      WidgetContainer: 'discountBumpWidgetContainer',
    },
    PropertyValue: {
      StaticLine: {
        Disabled: 'disabledStaticLine',
        Active: 'activeStaticLine',
      },
      EstimatedPropertyValueInfo: 'estimatedPropertyValueInfo',
      PropertyFeatures: 'PropertyFeatures',
    },
  },
  NativeAuth: {
    SignIn: 'signIn',
  },
  Auth: {
    Continue: 'authContinue',
    Resume: 'authResume',
  },
  LoanApplicationWizardFooter: { Container: 'modalFooterContainer' },
  OtherIncomeFormEdit: { IncomePaidTo: 'incomePaidTo' },
  PaymentFailure: {
    AmortizationFailureNotification: 'amortizationFailureNotification',
    DirectDebitFailureNotification: 'directDebitFailureNotification',
    SeeMoreText: 'paymentFailureSeeMoreText',
    AmortizationFailureDetails: 'amortizationFailureDetails',
    DirectDebitFailureDetails: 'directDebitFailureDetails',
    NeedAssistanceSection: 'needAssistanceSection',
  },
  ProfileButton: 'profileButton',
  ProfileMenu: {
    Home: 'profileMenuHome',
    Settings: 'profileMenuSettings',
    SignOut: 'profileMenuSignOut',
  },
  PropertyDetails: {
    ...PropertyTestIDs,
    PropertyPurpose: 'propertyPurpose',
    EstimatedPropertyValueInput: 'estimatedPropertyValueInput',
    PropertyValueInput: 'propertyValueInput',
    FirstTimeHomeBuyers: 'propertyDetailsFirstTimeHomeBuyers',
    SubmitButton: 'propertyDetailsSubmitButton',
    DeleteButton: 'propertyDetailsDeleteButton',
    DeleteActionSheet: {
      Cancel: 'removePropertyActionSheetCancelButton',
      Delete: 'removePropertyActionSheetDeleteButton',
    },
  },
  PropertyDetailsPurchase: {
    ...PropertyTestIDs,
    PropertyPurpose: 'propertyPurpose',
    PropertyValueInput: 'propertyValueInput',
    FirstTimeHomeBuyers: 'propertyDetailsFirstTimeHomeBuyers',
    SubmitButton: 'propertyDetailsSubmitButton',
  },
  RemoveBorrower: {
    RemoveButton: 'removeBorrowerRemoveButton',
    CancelButton: 'removeBorrowerCancelButton',
  },
  RentalIncome: {
    ...PropertyTestIDs,
    DeleteIncome: 'deleteIncome',
  },
  RepaymentCard: { Card: 'repaymentCard' },
  RepaymentPlanForRetirementActionSheet: {
    ConfirmButton: 'retirementModalConfirmButton',
  },
  ReviewApplicationSummary: {
    SubmitButton: 'reviewApplicationSubmitButton',
    DisclosureCheckbox: 'reviewApplicationDisclosureCheckbox',
  },
  SelectCitizenship: {
    ModalContainer: 'select-citizenship-modal-container',
    SearchBar: 'select-citizenship-search-bar',
  },
  SelectInstitution: {
    ModalContainer: 'select-institution-modal-container',
    UserInputRow: 'select-institution-user-input-row',
    SearchBar: 'select-institution-search-bar',
    FastLinkWebView: 'select-institution-fastlink-web-view',
  },
  SelectOccupationType: {
    ModalContainer: 'select-occupation-modal-container',
    SearchBar: 'select-occupation-search-bar',
  },
  SelectProperty: {
    ModalContainer: 'select-property-modal-container',
    SearchBar: 'select-property-search-bar',
    MailingAddressSearchBar: 'mailingAddressSearchBar',
    UseManualInputLink: 'useManualInputLink',
  },
  InputProperty: {
    UseSelectInputLink: 'useSelectInputLink',
    StreetTypeManualInput: 'street-type-user-input-row',
  },
  SearchBar: {
    AddressSearchPropertyInput: 'addressSearchPropertyInput',
  },
  SelectRefiReasonV2: {
    RefiReason: 'refinancingReason',
    SubmitButton: 'selectRefiReasonSubmitButton',
  },
  SelectTopUpScrollContainer: {
    TotalCashoutAmount: 'totalCashoutAmount',
  },
  SetTopUpAmount: { TopUpAmountInput: 'topUpAmountInput' },
  Settings: {
    EndpointRow: 'endpointRow',
    UserIdRow: 'userIdRow',
    CustomerSessionIdRow: 'customerSessionIdRow',
    BackendVersionRow: 'backendVersionRow',
    EnvironmentRow: 'environmentRow',
    AppVersionRow: 'appVersionRow',
    PersonalDetailsButton: 'personalDetailsButton',
    SecurityRow: 'settingsSecurityRow',
    DailyTransferLimitButton: 'dailyTransferLimitButton',
    InstallApp: 'installAppButton',
    ManageDataButton: 'manageDataButton',
    ConnectedBanksButton: 'connectedBanksButton',
    NewFeaturesButton: 'newFeaturesButton',
    GiveFeedbackButton: 'giveFeedbackButton',
    DeleteUser: {
      Spinner: 'deleteUserSpinner',
      DeleteUserText: 'deleteUserText',
      ConfirmationCheckDeleteButton: 'deleteUserConfirmationCheckDeleteButton',
    },
    DailyTransferLimit: {
      AmountInput: 'dailyTransferLimitAmountInput',
      SubmitButton: 'dailyTransferLimitSubmitButton',
    },
    SignOut: 'signOut',
  },
  PersonalDetails: {
    ErrorMessage: 'changePersonalDetailsErrorMessage',
    ChangeCompletedAnimation: 'changeCompletedAnimation',
    ChangeCompletedMessage: 'changeCompletedMessage',
    ChangeCompletedSubText: 'changeCompletedSubText',
    ChangeEmailButton: 'changeEmailButton',
    ChangeMobileButton: 'changeMobileButton',
    ChangeNameButton: 'changeNameButton',
    ChangePersonalDetailsContinueButton: 'changePersonalDetailsContinueButton',
    ChangePersonalDetailsResendSpinner: 'changePersonalDetailsResendSpinner',
    ChangeMobileNewMobileInput: 'changeMobileNewMobileInput',

    ChangePersonalDetailsCodeInput: 'changePersonalDetailsCodeInput',
    ChangePersonalDetailsResendCode: 'changePersonalDetailsResendCode',
    ChangeMobileVerifyText: 'changeMobileVerifyText',
    ChangeMobileVerifyEmail: 'changeMobileVerifyEmail',
    ChangeMobileVerifyOldMobile: 'changeMobileVerifyOldMobile',
    ChangeMobileVerifyNewMobile: 'changeMobileVerifyNewMobile',
    ChangeEmailVerifyEmail: 'changeEmailVerifyEmail',
    ChangeEmailVerifyNewEmail: 'changeEmailVerifyNewEmail',
    ChangeEmailNewEmailInput: 'changeEmailNewEmailInput',
    ChangeEmailVerifyOldMobile: 'changeEmailVerifyOldMobile',
    ChangeLegalName: 'changeLegalName',
    ChangeLegalNameScreen: 'changeLegalNameScreen',
    ChangeAddressButton: 'changeAddressButton',
    UpdatePhysicalAddress: {
      CheckBox: 'updatePhysicalAddressCheckBox',
      ErrorMessage: 'updatePhysicalAddressErrorMessage',
      UpdateResidentialAddress: {
        DomainApi: 'updateResidentialAddressDomainApi',
        Footer: 'updateResidentialAddressFooter',
        UnitNo: 'updateResidentialAddressUnitNo',
        StreetNo: 'updateResidentialAddressStreetNo',
        StreetName: 'updateResidentialAddressStreetName',
        StreetType: 'updateResidentialAddressStreetType',
        Suburb: 'updateResidentialAddressSuburb',
        State: 'updateResidentialAddressState',
        Postcode: 'updateResidentialAddressPostcode',
      },
      UpdateMailingAddress: {
        DomainApi: 'updateMailingAddressDomainApi',
        Footer: 'updateMailingAddressFooter',
        UnitNo: 'updateMailingAddressUnitNo',
        StreetNo: 'updateMailingAddressStreetNo',
        StreetName: 'updateMailingAddressStreetName',
        StreetType: 'updateMailingAddressStreetType',
        Suburb: 'updateMailingAddressSuburb',
        State: 'updateMailingAddressState',
        Postcode: 'updateMailingAddressPostcode',
      },
      Submit: 'updatePhysicalAddressSubmit',
    },
  },
  ScrollContainer: 'ScrollContainer',
  SetupLoanForm: {
    SelectedLiabilityIds: 'selectedLiabilityIds',
    LoansToRefinance: 'loansToRefinance',
    LoansToRefinanceValue: 'setupLoanFormLoansToRefinanceValue',
    TopUpAmountConfigurationButton:
      'setupLoanFormTopUpAmountConfigurationButton',
    TopUpAmount: 'setupLoanFormTopUpAmount',
    LoanTerm: 'setupLoanFormLoanTerm',
    LoanTermYearsInMonths: 'setupLoanFormLoanTermYearsInMonths',
    LoanTermMonths: 'setupLoanFormLoanTermMonths',
    TotalFeesAmount: 'setupLoanTotalFeesAmount',
    BufferAmount: 'setupLoanBufferAmount',
    HasTopUpAmount: 'setupLoanFormHasTopUpAmount',
    SubmitButton: 'setupLoanFormSubmitButton',
    TopUpFields: {
      [Top_Up_Reason_Enum.YourHome]: 'setupLoanFormYourHomeTopUpAmount',
      [Top_Up_Reason_Enum.InvestmentProperty]:
        'setupLoanFormInvestmentPropertyTopUpAmount',
      [Top_Up_Reason_Enum.FinancialInvestment]:
        'setupLoanFormFinancialInvestmentTopUpAmount',
      [Top_Up_Reason_Enum.PersonalUse]: 'setupLoanFormPersonalUseTopUpAmount',
      [Top_Up_Reason_Enum.Other]: 'setupLoanFormOtherTopUpAmount',
      OtherDescription: 'setupLoanFormOtherTopUpDescription',
    },
    LoanAmountInput: 'loanAmountInput',
    TotalLoanAmount: 'totalLoanAmount',
    LmiPremium: 'lmiPremium',
  },
  SetupLoanScreen: {
    FooterText: 'setupLoanFooterCaption',
    TotalLoanAmountText: 'setupLoanTotalLoanAmount',
    MinRepaymentAmountText: 'setupLoanMinRepaymentAmount',
  },
  SetupLoanPurchase: {
    LoanAmount: 'setupLoanPurchaseLoanAmount',
    LoanTerm: 'setupLoanPurchaseLoanTerm',
    Loading: 'setupLoanPurchaseLoading',
    ErrorBanner: 'setupLoanPurchaseErrorBanner',
  },
  StopDataShareConfirmationActionSheet: {
    StopButton: 'stop-sharing-button',
  },
  SubmittingAutoPayIndicator: 'submittingAutopayIndicator',
  SummarySection: {
    RefinanceReason: 'applicationSummaryRefinanceReason',
    TopUpReason: 'applicationSummaryTopUpReason',
    FutureFinancialSituation: 'applicationSummaryFutureFinancialSituation',
    RetirementConsideration: 'applicationSummaryRetirementConsideration',
    PropertyItem: 'applicationSummaryProperty',
    BorrowerAdd: 'applicationSummaryAddBorrowerButton',
    BorrowerItemPrefix: 'applicationSummaryBorrowerItem',
    IncomeAdd: 'applicationSummaryAddIncomeButton',
    IncomeItemPrefix: 'applicationSummaryIncomeItem',
    ExpenseAdd: 'applicationSummaryAddExpenseButton',
    ExpenseItemPrefix: 'applicationSummaryExpenseItem',
    LiabilityAdd: 'applicationSummaryAddLiabilityButton',
    LiabilityItemPrefix: 'applicationSummaryLiabilityItem',
    MinRepayment: 'applicationSummaryMinRepayment',
    TotalLoanAmount: 'applicationSummaryTotalLoanAmount',
    LoanTerm: 'applicationSummaryLoanTerm',
    YourUnloanCard: 'applicationSummaryYourUnloanCard',
  },
  SupportCard: { Button: 'supportCardButton' },
  Transaction: {
    TransactionAmount: 'transaction-amount',
    TransactionDescription: 'transaction-description',
    TransactionStatus: 'transaction-status',
    TransactionDate: 'transaction-date',
    TransactionReference: 'transaction-reference',
    TransactionReceiptNo: 'transaction-receipt-no',
  },
  TransactionFilter: { Category: 'category' },
  UnloanTopBarLogo: 'unloanTopBarLogo',
  VerifyIncomeOpenBanking: {
    ConnectYourBank: 'verifyIncomeOBConnectYourBankButton',
    UploadDocumentManual: 'verifyIncomeOBUploadDocsManual',
    Spinner: 'verifyIncomeOBSpinner',
    ConnectedBank: 'verifyIncomeOBConnectedBank',
    ConnectYourBankRetryButton: 'verifyIncomeOBConnectYourBankRetryButton',
  },
  VerifyYourIdentityConsent: {
    VerifyButton: 'verifyYourIdentityConsentVerifyButton',
  },
  AddExternalAccount: {
    AccountName: 'addExternalAccountAccountName',
    Bsb: 'addExternalAccountBsb',
    AccountNumber: 'addExternalAccountAccountNumber',
    SubmitButton: 'addExternalAccountSubmitButton',
  },
  AddNewAccount: {
    AccountBsb: 'addNewAccountAccountBsb',
    AccountName: 'AddNewAccountAccountName',
    AccountNumber: 'addNewAccountAccountNumber',
    SubmitButton: 'addNewAccountSubmitButton',
  },
  Withdraw: {
    TransferButton: 'transferButton',
    AmountRequiredError: 'amountRequiredError',
    AccountRequiredError: 'accountRequiredError',
    DescriptionRequiredError: 'descriptionRequiredError',
    AccountDropdownInput: 'accountDropdownInput',
    AccountDropdownRowInput: 'accountDropdownRowInput',
    AmountInput: 'amountInput',
    AccountNameInput: 'accountNameInput',
    AccountBsbInput: 'accountBsbInput',
    AccountNumberInput: 'accountNumberInput',
    DescriptionInput: 'descriptionInput',
    ReferenceInput: 'referenceInput',
    ConfirmButton: 'confirmButton',
  },
  AccountAddressBook: {
    UnloanAccount: 'accountAccountAddressBookUnloanAccount',
    ExternalAccount: 'accountAccountAddressBookExternalAccount',
    DeleteExternalAccountButton:
      'accountAccountAddressBookDeleteExternalAccountButton',
  },
  AccountAddressBookDeleteActionSheet: {
    CancelButton: 'accountAddressBookDeleteActionSheetCancelButton',
    ConfirmButton: 'accountAddressBookDeleteActionSheetConfirmButton',
  },
  WithdrawConfirmationActionSheet: {
    ConfirmButton: 'withdrawConfirmButton',
    RedrawThresholdExceededWarning: 'redrawThresholdExceededWarning',
  },
  YourApplicationHeader: {
    Gradient: 'yourApplicationHeaderGradient',
  },
  YourUnloan: {
    ContinueButton: 'loanApplicationYourUnloanContinueButton',
    ProductLabel: 'yourUnloanProductLabel',
    MinimumRepaymentTestId: 'yourUnloanMinimumRepayment',
    TotalLoanAmountTestId: 'yourUnloanTotalLoanAmount',
    AvailableRedrawTestId: 'yourUnloanAvailableRedraw',
    LoanTermTestId: 'yourUnloanLoanTerm',
    CardTestId: 'yourUnloanCard',
    EmptyStateIllustration: 'yourUnloanEmptyStateIllustration',
  },
  PurchaseYourUnloanCard: {
    TotalLoanAmount: 'purchaseYourUnloanCardTotalLoanAmount',
  },
  ApplicationTracker: {
    Card: 'applicationTrackerCard',
  },
  ApplicationTracking: {
    ApplicantActionButton: 'applicationTrackingApplicantActionButton',
    DownloadDocumentsLink: 'applicationTrackingDownloadDocumentsLink',
    CloseButton: 'applicationTrackingClose',
  },
  YourUnloanOffer: { ViewAndAcceptOfferButton: 'viewAndAcceptOfferButton' },
  YourUnloanOfferEsign: {
    ViewAndAcceptOfferButton: 'viewAndAcceptOfferButton',
    NoLinkErrorButton: 'backToDashboardButton',
  },
  Icon: {
    Alert: 'alertIcon',
    Warning: 'warning',
    GrayBallIcon: 'grayBallIcon',
    GradientBallIcon: 'gradientBallIcon',
  },
  AutopaySetup: {
    AccountDropdownInput: 'accountDropdownInput',
    AccountDropdownRowInput: 'accountDropdownRowInput',
    AccountNameInput: 'accountNameInput',
    AccountBsbInput: 'accountBsbInput',
    AccountNumberInput: 'accountNumberInput',
    WeeklyRepaymentRadioInput: 'weeklyRepaymentRadioInput',
    FortnightlyRepaymentRadioInput: 'fortnightlyRepaymentRadioInput',
    MonthlyRepaymentRadioInput: 'monthlyRepaymentRadioInput',
    CustomRepaymentRadioInput: 'customRepaymentRadioInput',
    CustomRepaymentAmountInput: 'customRepaymentAmountInput',
    CustomRepaymentFrequencySelect: 'customRepaymentFrequencySelect',
    StartDateInput: 'startDateInput',
    SubmitButton: 'submitButton',
    ContinueButton: 'continueButton',
    ShortfallConfirmButton: 'shortfallConfirmButton',
    ConfirmButton: 'confirmButton',
  },
  AutopayCustomRepayment: {
    SubmitButton: 'autopayCustomRepaymentSubmitButton',
    MinimumRepaymentLabel: 'autopayCustomRepaymentMinimumRepaymentLabel',
  },
  Pwa: {
    InstallBanner: 'installBanner',
    CloseBannerButton: 'closeBannerButton',
    InstallPwaButton: 'installPwaButton',
    InstallTooltip: 'installTooltip',
    CloseTooltipButton: 'closeTooltipButton',
  },
  ManualIdentityVerificationModal: {
    BackToDigitalIdentityVerificationButton:
      'backToDigitalIdentityVerificationButton',
    ContinueWithManualVerificationButton:
      'continueWithManualVerificationButton',
  },
  VerifyingYourIdentityLearnMoreModal: {
    LearnMoreButton: 'verifyingYourIdentityLearnMoreButton',
  },
  UnableToVerifyYourIdentityModal: {
    LearnMoreButton: 'unableToVerifyYourIdentityModalLearnMoreButton',
  },
  PendingWithdrawal: {
    ViewAndApproveButton: 'pendingWithdrawalViewAndApproveButton',
  },
  PendingTransaction: {
    ApprovalStatus: 'pendingTransactionApprovalStatus',
    CancelButton: 'pendingTransactionCancelButton',
    DeclineButton: 'pendingTransactionDeclineButton',
    ApproveButton: 'pendingTransactionApproveButton',
    ToAccount: 'pendingTransactionToAccount',
  },
  ScreenContainer: {
    BackButton: 'screenContainerBackButton',
  },
  ModalScreenContainer: {
    CloseButton: 'modalScreenContainerCloseButton',
    BackButton: 'modalScreenContainerBackButton',
  },
  Input: {
    ClearButton: 'inputClearButton',
  },
  UpfrontCostsDetails: {
    Pressable: 'upfrontCostsDetailsPressable',
    TotalUpfrontCost: 'totalUpfrontCost',
  },
  TabNav: {
    Badge: 'tabNavBadge',
  },
  LoadingRow: {
    Skeleton: 'loadingRowSkeleton',
  },
  LoadingInput: {
    Skeleton: 'loadingInputSkeleton',
  },
  NewFeaturesModal: 'newFeaturesModal',
};
