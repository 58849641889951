import { Pressable } from 'dripsy';

import { TestID } from '../../testID/constants';
import { StyledImage } from '../ui/atoms/StyledImage';
import { useTheme } from '../ui/theme';
import { resizeIconWidth } from '../ui/utils/resizeIconWidth';
import { useIsMobile } from '../utils/hooks/useBreakpoint';

type Props = {
  onPress?: () => void;
};

export function UnloanBuiltByCBALogo({ onPress }: Props) {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const height = isMobile ? theme.sizes.m : theme.sizes.l;
  const width = resizeIconWidth(100, 36, height);

  return (
    <Pressable
      onPress={onPress}
      testID={TestID.UnloanTopBarLogo}
      aria-label={t('Content.Accessibility.TopNavBar.LogoLabel')}
      role="button"
    >
      <StyledImage
        contentFit="contain"
        width={width}
        height={height}
        source={require('../../assets/unloanBuiltByCBALogo.svg')}
        alt={t('Content.Accessibility.TopNavBar.LogoAltText')}
        aria-hidden
      />
    </Pressable>
  );
}
