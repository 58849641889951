import { Text, View } from 'dripsy';

import { formatCurrency } from '../../../utils/currencyHelpers';

type Props = {
  value: number;
};

export const OriginalValuation = ({ value }: Props) => (
  <View>
    <Text
      variant="caption"
      sx={{ mr: '$4', color: '$fieldPromptText', mb: '$4' }}
    >
      {t('Content.HomeLoan.Insights.PropertyValue.OriginalValuationHeader')}
    </Text>
    <Text variant="body" sx={{ fontWeight: '600' }}>
      {formatCurrency(value, {
        displayInMillion: true,
        displayInThousand: true,
      })}
    </Text>
  </View>
);
