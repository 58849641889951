import { styled, Text, View } from 'dripsy';
import { useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { InlineInfoRow } from '../../components/InlineInfoRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  Employment_Income_Error_Type,
  Income_Type_Enum,
} from '../../generated/graphql';
import { useYourFinancialsScreenQuery } from '../../LoanApplication/graphql/queries';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useDomainAPIPropertySuggestionLoader } from '../../ui/v2/PropertyInput';
import {
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemWithSubtitle,
} from '../../ui/v2/RadioGroup';
import { parseEnumType } from '../../utils/ensureEnumType';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import { makeTestId } from '../../utils/stringHelpers';
import {
  DividendIncomeForm,
  DividendIncomeFormDetails,
} from '../components/DividendIncomeForm';
import {
  EmploymentIncomeForm,
  EmploymentIncomeFormDetails,
  EmploymentIncomeFormSubmitLabel,
} from '../components/EmploymentIncomeForm';
import {
  GovernmentIncomeForm,
  GovernmentIncomeFormDetails,
} from '../components/GovernmentIncomeForm';
import { IntercomPrompt } from '../components/IntercomPrompt';
import {
  RentalIncomeForm,
  RentalIncomeFormDetails,
  RentalIncomeFormSubmitLabel,
} from '../components/RentalIncomeForm';
import {
  useCreateEmploymentIncomeForV2Form,
  useCreateOtherIncomeForV2Form,
  useCreateRentalIncomeForV2Form,
  useGetOptionsForAddIncomeQuery,
} from '../graphql/incomeHooks';
import { YourIncomeModalScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeModalScreenProps<Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL>;

export function AddNewIncomeScreen({ navigation, route }: Props) {
  const { params } = route;

  const { loanApplicationId = '', hideNovatedLeaseFields = false } =
    params || {};

  const [incomeTypeState, setIncomeTypeState] = useState<Income_Type_Enum>();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [
    showMultipleEmploymentIncomesErrorMessage,
    setShowMultipleEmploymentIncomesErrorMessage,
  ] = useState(false);

  const { applicants, incomes = [] } =
    useYourFinancialsScreenQuery(loanApplicationId);
  const noOfApplicants = applicants.length;
  const employmentIncomeAmount =
    incomes?.filter(
      (income) => income.income_type === Income_Type_Enum.Employment,
    ).length || 0;
  const hideAddEmploymentIncomeRadioButton =
    noOfApplicants === 1 && employmentIncomeAmount === 1;

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    loanApplicationId,
    navigation,
    route,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToMainScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  const {
    occupationTypeOptions,
    employmentTypeOptions,
    incomeOwnerOptions,
    frequencyOptions,
    streetTypeOptions,
    propertyTypeOptions,
    stateOptions,
    loading: incomeOptionsLoading,
  } = useGetOptionsForAddIncomeQuery({
    loanApplicationId: loanApplicationId ?? '',
  });

  // Domain API
  const {
    isLoadingSuggestionData,
    loadSuggestionData,
    propertySuggestionData,
  } = useDomainAPIPropertySuggestionLoader();

  const { createEmploymentIncome, isCreatingEmploymentIncome } =
    useCreateEmploymentIncomeForV2Form({
      loanApplicationId,
    });

  const { createExistingRentalIncome, isCreatingRentalIncome } =
    useCreateRentalIncomeForV2Form({
      loanApplicationId,
    });
  const { createOtherIncome, isCreatingOtherIncome } =
    useCreateOtherIncomeForV2Form({
      loanApplicationId,
    });

  const onEmploymentIncomeFormSubmit = async (
    values: EmploymentIncomeFormDetails,
  ) => {
    const { success, errorMessage, errorType } = await createEmploymentIncome(
      values,
    );
    if (success) {
      navigateBackToMainScreen();
    } else if (
      errorType === Employment_Income_Error_Type.MaxEmploymentIncomeReached
    ) {
      setShowMultipleEmploymentIncomesErrorMessage(true);
    } else {
      setFormErrorMessage(errorMessage);
    }
  };

  const onRentalIncomeFormSubmit = async (values: RentalIncomeFormDetails) => {
    const { success, errorMessage } = await createExistingRentalIncome(values);
    if (success) {
      navigateBackToMainScreen();
    } else {
      setFormErrorMessage(errorMessage);
    }
  };

  const onOtherIncomeFormSubmit = async (
    values: GovernmentIncomeFormDetails | DividendIncomeFormDetails,
    incomeType:
      | Income_Type_Enum.GovernmentPayments
      | Income_Type_Enum.ShareDividends,
  ) => {
    const { success, errorMessage } = await createOtherIncome({
      values,
      incomeType,
    });
    if (success) {
      navigateBackToMainScreen();
    } else {
      setFormErrorMessage(errorMessage);
    }
  };

  const isCreatingIncome =
    isCreatingEmploymentIncome ||
    isCreatingRentalIncome ||
    isCreatingOtherIncome;

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.AddIncome')}
      scrollable
      loading={isCreatingIncome}
      onClose={() => {
        navigateBackToMainScreen();
      }}
      hideBackButton
    >
      {incomeOptionsLoading ? (
        <ScreenLoadingContainer loading />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage}
            testID={TestID.AddIncomeForm.ErrorText}
          >
            {showMultipleEmploymentIncomesErrorMessage ? (
              <IntercomPrompt />
            ) : null}
          </ErrorRow>
          <SHeaderText>
            {t('Content.YourIncome.Form.WhatsTheIncomeType')}
          </SHeaderText>
          <View sx={{ mt: '$16', mb: '$32' }}>
            <RadioGroup
              value={incomeTypeState}
              onValueSelected={(v) => {
                const parsedValue = parseEnumType(Income_Type_Enum, v);
                if (parsedValue) {
                  setIncomeTypeState(parsedValue);
                }
              }}
            >
              {!hideAddEmploymentIncomeRadioButton && (
                <RadioGroupItemWithSubtitle
                  value={Income_Type_Enum.Employment}
                  label={t(
                    'Content.YourIncome.Form.AddNewIncomeOptions.Employment.Label',
                  )}
                  subtitle={t(
                    'Content.YourIncome.Form.AddNewIncomeOptions.Employment.Subtitle',
                  )}
                  testID={makeTestId([
                    TestID.AddIncomeForm.IncomeTypeInput,
                    Income_Type_Enum.Employment,
                  ])}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Income,
                    Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
                    FieldInteractionKey.IncomeTypeEmployment,
                  )}
                />
              )}
              <RadioGroupItemWithSubtitle
                value={Income_Type_Enum.Rental}
                label={t(
                  'Content.YourIncome.Form.AddNewIncomeOptions.Rental.Label',
                )}
                subtitle={t(
                  'Content.YourIncome.Form.AddNewIncomeOptions.Rental.Subtitle',
                )}
                testID={makeTestId([
                  TestID.AddIncomeForm.IncomeTypeInput,
                  Income_Type_Enum.Rental,
                ])}
                containerStyle={{
                  mt: '$8',
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
                  FieldInteractionKey.IncomeTypeRental,
                )}
              />
              <RadioGroupItem
                value={Income_Type_Enum.ShareDividends}
                label={t(
                  'Content.YourIncome.Form.AddNewIncomeOptions.Dividends.Label',
                )}
                testID={makeTestId([
                  TestID.AddIncomeForm.IncomeTypeInput,
                  Income_Type_Enum.ShareDividends,
                ])}
                containerStyle={{
                  mt: '$8',
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
                  FieldInteractionKey.IncomeTypeDividends,
                )}
              />
              <RadioGroupItemWithSubtitle
                value={Income_Type_Enum.GovernmentPayments}
                label={t(
                  'Content.YourIncome.Form.AddNewIncomeOptions.Government.Label',
                )}
                subtitle={t(
                  'Content.YourIncome.Form.AddNewIncomeOptions.Government.Subtitle',
                )}
                testID={makeTestId([
                  TestID.AddIncomeForm.IncomeTypeInput,
                  Income_Type_Enum.GovernmentPayments,
                ])}
                containerStyle={{
                  mt: '$8',
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
                  FieldInteractionKey.IncomeTypeGovernmentPayments,
                )}
              />
            </RadioGroup>
            <InlineInfoRow sx={{ mt: '$8' }}>
              <IntercomPrompt />
            </InlineInfoRow>
          </View>
          {incomeTypeState === Income_Type_Enum.Employment && (
            <EmploymentIncomeForm
              screen={Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL}
              isSubmitting={isCreatingEmploymentIncome}
              onSubmit={onEmploymentIncomeFormSubmit}
              employmentTypeOptions={employmentTypeOptions}
              occupationTypeOptions={occupationTypeOptions}
              payeeOptions={incomeOwnerOptions}
              submitLabel={EmploymentIncomeFormSubmitLabel.Done}
              hideNovatedLeaseFields={hideNovatedLeaseFields}
            />
          )}
          {incomeTypeState === Income_Type_Enum.Rental && (
            <RentalIncomeForm
              screen={Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL}
              isSubmitting={isCreatingRentalIncome}
              onSubmit={onRentalIncomeFormSubmit}
              frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
              propertyOwnerOptions={incomeOwnerOptions}
              propertyTypeOptions={propertyTypeOptions}
              propertySuggestionData={propertySuggestionData}
              streetTypeOptions={streetTypeOptions}
              stateOptions={stateOptions}
              loadSuggestionData={loadSuggestionData}
              isLoadingSuggestionData={isLoadingSuggestionData}
              submitLabel={RentalIncomeFormSubmitLabel.Done}
              onRentalExpenseLearnMoreClick={() => {
                navigation.navigate(Screen.SINGLE_V2_MODAL, {
                  screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
                });
              }}
            />
          )}
          {incomeTypeState === Income_Type_Enum.GovernmentPayments && (
            <GovernmentIncomeForm
              screen={Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL}
              isSubmitting={isCreatingOtherIncome}
              payeeOptions={incomeOwnerOptions}
              frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
              onSubmit={async (values) => {
                onOtherIncomeFormSubmit(
                  values,
                  Income_Type_Enum.GovernmentPayments,
                );
              }}
            />
          )}
          {incomeTypeState === Income_Type_Enum.ShareDividends && (
            <DividendIncomeForm
              screen={Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL}
              isSubmitting={isCreatingOtherIncome}
              payeeOptions={incomeOwnerOptions}
              frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
              onSubmit={async (values) => {
                onOtherIncomeFormSubmit(
                  values,
                  Income_Type_Enum.ShareDividends,
                );
              }}
            />
          )}
          {incomeTypeState == null && (
            // A duplicated button with the same style as the one used in forms above.
            // This is a visual button to indicate that applicant can't proceed unless
            // they choose employment type above
            <Button
              label={t('Content.Common.ButtonLabel.Done')}
              disabled
              py="m"
              alignSelf="stretch"
            />
          )}
        </>
      )}
    </ModalScreenContainer>
  );
}

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
