import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function ConditionalApprovalVerifyIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fill="#676767"
        d="M32.862 24.953h-29.701v-10.081c0-0.536 0.275-1.036 0.728-1.322l12.87-8.129c0.765-0.483 1.739-0.483 2.504 0l12.87 8.129c0.453 0.287 0.729 0.785 0.729 1.322v10.081z"
      />
      <Path
        fill="#dedede"
        d="M29.065 9.038v20.355c0 0.795-0.661 1.44-1.474 1.44h-19.173c-0.813 0-1.474-0.644-1.474-1.44v-24.445c0-0.795 0.661-1.44 1.474-1.44h15.116l1.382 4.148 4.148 1.383z"
      />
      <Path
        fill="#ccc"
        d="M23.534 3.508v4.524c0 0.556 0.449 1.006 1.005 1.006h4.525l-5.53-5.53z"
      />
      <Path
        fill="#000"
        d="M24.534 11.112h-13.059c-0.21 0-0.383-0.156-0.383-0.345v-0.693c0-0.192 0.173-0.345 0.383-0.345h13.059c0.21 0 0.383 0.153 0.383 0.345v0.693c0 0.189-0.173 0.345-0.383 0.345z"
      />
      <Path
        fill="#000"
        d="M23.144 13.877h-11.664c-0.213 0-0.388-0.155-0.388-0.347v-0.689c0-0.192 0.175-0.347 0.388-0.347h11.664c0.216 0 0.391 0.155 0.391 0.347v0.689c0 0.192-0.175 0.347-0.391 0.347z"
      />
      <Path
        fill="#000"
        d="M23.144 19.407h-11.664c-0.213 0-0.388-0.155-0.388-0.347v-0.689c0-0.192 0.175-0.347 0.388-0.347h11.664c0.216 0 0.391 0.155 0.391 0.347v0.689c0 0.192-0.175 0.347-0.391 0.347z"
      />
      <Path
        fill="#000"
        d="M24.534 16.642h-13.059c-0.21 0-0.383-0.153-0.383-0.345v-0.693c0-0.189 0.173-0.345 0.383-0.345h13.059c0.21 0 0.383 0.156 0.383 0.345v0.693c0 0.192-0.173 0.345-0.383 0.345z"
      />
      <Path
        fill="#000"
        d="M24.534 22.172h-13.059c-0.21 0-0.383-0.153-0.383-0.345v-0.693c0-0.189 0.173-0.345 0.383-0.345h13.059c0.21 0 0.383 0.156 0.383 0.345v0.693c0 0.192-0.173 0.345-0.383 0.345z"
      />
      <Path
        fill="#000"
        d="M24.062 27.952h-13.125c-0.24 0-0.437-0.192-0.437-0.425v-0.854c0-0.237 0.197-0.425 0.437-0.425h13.125c0.243 0 0.44 0.189 0.44 0.425v0.854c0 0.234-0.197 0.425-0.44 0.425z"
      />
      <Path
        fill="#c1c1c1"
        d="M33 15.142v16.614c0 0.753-0.621 1.363-1.387 1.363h-24.75l3.754-4.945 22.383-13.032z"
      />
      <Path
        fill="#ccc"
        d="M3.111 15.142v16.614c0 0.753 0.621 1.363 1.387 1.363h26.928c0.438 0 0.823-0.203 1.077-0.514l-29.392-17.462z"
      />
    </Svg>
  );
}
