/**
 * @module @web
 *
 * This module implement the integration to Google Maps API
 * for the web app.
 *
 * Right now we use these APIs:
 * - Maps -> Static maps
 * - Places -> Autocomplete, Place details
 *
 * @see https://developers.google.com/maps/documentation
 */

import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useRef, useState } from 'react';

import { State_Enum } from '../generated/graphql';
import { captureMessage } from '../sentry';
import { parseEnumType } from '../utils/ensureEnumType';
import { getGlobal } from '../utils/getGlobal';
import { normalizeError } from '../utils/normalizeError';
import {
  GOOGLE_MAPS_API_KEY,
  GoogleMapsApiProviderProps,
  SuburbDetails,
  SuburbPrediction,
  UseSuburbAutocompleteReturn,
} from './GoogleMapsApiCommon';
import { SuburbDetailsCache } from './GoogleMapsPlacesCache';

export function GoogleMapsApiProvider({
  children,
}: GoogleMapsApiProviderProps) {
  return <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>{children}</APIProvider>;
}

/**
 * @web not yet stubbed on native
 */
export function GoogleMapsApiProviderForTest({
  children,
}: GoogleMapsApiProviderProps) {
  return <APIProvider apiKey="rubbish">{children}</APIProvider>;
}

/**
 * # About attributions
 *
 * Google policies requires us to display attributions when displaying
 * a place details.
 *
 * This is why creating a new instance of `PlacesService` requires
 * specifying an HTML container.
 * Which will be used to inject the html_attributions from
 * place details response.
 *
 * But in our case, we do autocomplete and place details for
 * suburb, state, and postcode.
 * These generally don't have attributions.
 *
 * Things that have attributions are like photos, reviews, etc.
 * Which we don't fetch and display.
 *
 * So we should never receive html_attributions in our place details response.
 * Hence, we are using hidden div for attributions container.
 *
 * @see https://developers.google.com/maps/documentation/places/web-service/policies
 */
const ATTR_CONTAINER_ID = 'places-attr-container';
function getPlacesServiceAttributionContainer() {
  const glbl = getGlobal();

  if (glbl == null) {
    return null;
  }
  const existing = glbl.document.getElementById(
    ATTR_CONTAINER_ID,
  ) as HTMLDivElement | null;

  if (existing != null) {
    return existing;
  }

  const attrContainer = glbl.document.createElement('div');
  attrContainer.setAttribute('id', ATTR_CONTAINER_ID);
  attrContainer.setAttribute('style', 'display: none;');

  glbl.document.body.appendChild(attrContainer);

  return attrContainer;
}

/**
 * A language identifier for the language in which the results should
 * be returned, if possible.
 * Results in the selected language may be given a higher ranking,
 * but predictions are not restricted to this language.
 *
 * @see https://developers.google.com/maps/faq#languagesupport
 */
const PREFERRED_LANGUAGE = 'en-AU';

/**
 * ISO 3166-1 alpha-2 codes are two-letter country codes defined in ISO 3166-1,
 * to represent countries, dependent territories,
 * and special areas of geographical interest.
 *
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
const AUSTRALIA_ISO_ALPHA_2_CODE = 'AU';

/**
 * Place details API is not supposed to be called on every predictions result
 * from Autocomplete API.
 * Normally, it's only called on one prediction that user picked.
 *
 * But we need the details (suburb, state, and postcode) for the UI we want.
 * Hence, we're calling Place details API for every prediction.
 *
 * To limit the cost, we only call Place details API for
 * the first 5 predictions on every autocomplete search.
 */
const FETCH_DETAILS_PER_PREDICTIONS_LIMIT = 5;

export function useSuburbAutocomplete(): UseSuburbAutocompleteReturn {
  /**
   * @see https://developers.google.com/maps/documentation/places/web-service
   */
  const placesLib = useMapsLibrary('places');

  /**
   * Session token is a random string which identifies an autocomplete session
   * for billing purposes.
   *
   * @see https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
   */
  const sessionTokenRef =
    useRef<google.maps.places.AutocompleteSessionToken | null>(null);

  /**
   * @see https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
   */
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  /**
   * @see https://developers.google.com/maps/documentation/javascript/reference/places-service
   */
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [suburbDetailsList, setSuburbDetailsList] = useState<
    Array<SuburbDetails>
  >([]);

  useEffect(() => {
    SuburbDetailsCache.setupCache();
  }, []);

  useEffect(() => {
    if (!placesLib) {
      return () => {};
    }

    const attrContainer = getPlacesServiceAttributionContainer();
    if (attrContainer == null) {
      return () => {};
    }

    setAutocompleteService(new placesLib.AutocompleteService());
    setPlacesService(new placesLib.PlacesService(attrContainer));
    sessionTokenRef.current = new placesLib.AutocompleteSessionToken();

    return () => {
      setAutocompleteService(null);
    };
  }, [placesLib]);

  /**
   * The session token should be reset after user is done searching a place.
   * On a typical flow, done means user pick one of the predictions.
   *
   * But in our product, we have a manual input flow.
   * Which means user won't pick a prediction.
   *
   * In this case, we need to explicitly reset the session token
   * so the next autocomplete search will use a new token.
   */
  const resetAutocompleteSessionToken = useCallback(() => {
    if (placesLib) {
      sessionTokenRef.current = new placesLib.AutocompleteSessionToken();
    }
  }, [placesLib]);

  const fetchSuburbPredictions = useCallback(
    async (rawInput: string): Promise<Array<SuburbPrediction>> => {
      if (autocompleteService == null) {
        return [];
      }

      const input = rawInput.trim();

      // In Australia, there are no suburbs with 2 letters or less.
      // The shortest suburbs are 3 letters like Ayr in QLD or Yea in VIC.
      if (input.length < 3) {
        return [];
      }

      try {
        const res = await autocompleteService.getPlacePredictions({
          input,
          /**
           * > locality indicates an incorporated city or town political entity.
           *
           * In Australia, `locality` represent suburb.
           * @see https://developers.google.com/maps/documentation/javascript/supported_types#table3
           * @see https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
           */
          types: ['locality'],
          componentRestrictions: {
            country: AUSTRALIA_ISO_ALPHA_2_CODE,
          },
          sessionToken: sessionTokenRef.current ?? undefined,
          language: PREFERRED_LANGUAGE,
          region: AUSTRALIA_ISO_ALPHA_2_CODE,
        });

        return res.predictions.map((p) => ({
          description: p.description,
          placeId: p.place_id,
        }));
      } catch (error) {
        captureMessage('Failed to fetch suburb predictions', {
          input,
          errorMessage: normalizeError(error).message,
        });
        return [];
      }
    },
    [autocompleteService],
  );

  /**
   * TODO: This could be optimized even further but requires
   * analysis on average API call count.
   *
   * Instead of doing autocomplete + details + geocoding,
   * doing autocomplete + geocoding only might result in lower billing cost.
   *
   * @see https://developers.google.com/maps/documentation/places/web-service/autocomplete#select-location-performant
   */
  const fetchSuburbDetails = useCallback(
    async (placeId: string): Promise<SuburbDetails | null> => {
      if (placesLib == null || placesService == null) {
        return null;
      }

      const cachedDetails = SuburbDetailsCache.get(placeId);

      if (cachedDetails) {
        resetAutocompleteSessionToken();
        return cachedDetails;
      }

      const placeResultPromise =
        new Promise<google.maps.places.PlaceResult | null>((resolve) => {
          placesService.getDetails(
            {
              placeId,
              sessionToken: sessionTokenRef.current ?? undefined,
              fields: [
                'address_components',
                /**
                 * `geometry` is unused right now.
                 * Will be needed if we do reverse geocoding to populate
                 * missing postcode.
                 * Commented out for now because every additional fields cost
                 * us money.
                 */
                // 'geometry',
              ],
              language: PREFERRED_LANGUAGE,
            },
            (res) => resolve(res),
          );
        });

      try {
        const placeResult = await placeResultPromise;

        if ((placeResult?.html_attributions?.length ?? 0) > 0) {
          captureMessage('Unexpected HTML attributions in place details', {
            placeId,
            htmlAttributions: placeResult?.html_attributions,
          });
        }

        resetAutocompleteSessionToken();

        const suburbDetails = mapPlaceResultToSuburbDetails(placeResult);

        if (suburbDetails) {
          SuburbDetailsCache.set(placeId, suburbDetails);
        }

        return suburbDetails;
      } catch (error) {
        captureMessage('Failed to fetch suburb details', {
          placeId,
          errorMessage: normalizeError(error).message,
        });
        return null;
      }
    },
    [placesLib, placesService, resetAutocompleteSessionToken],
  );

  const fetchSuburbDetailsList = useCallback(
    async (rawInput: string) => {
      const allPredictions = await fetchSuburbPredictions(rawInput);
      const predictions = allPredictions.slice(
        0,
        FETCH_DETAILS_PER_PREDICTIONS_LIMIT,
      );

      const detailsList = (
        await Promise.all(predictions.map((p) => fetchSuburbDetails(p.placeId)))
      ).filter((s): s is SuburbDetails => s != null);

      return detailsList;
    },
    [fetchSuburbPredictions, fetchSuburbDetails],
  );

  const getSuburbDetailsList = useCallback(
    async (rawInput: string) => {
      const detailsList = await fetchSuburbDetailsList(rawInput);
      setSuburbDetailsList(detailsList);
      return detailsList;
    },
    [fetchSuburbDetailsList],
  );

  return {
    getSuburbDetailsList,
    fetchSuburbDetails,
    suburbDetailsList,
    resetAutocompleteSessionToken,
  };
}

function mapPlaceResultToSuburbDetails(
  placeRes: google.maps.places.PlaceResult | null,
): SuburbDetails | null {
  if (placeRes == null) {
    return null;
  }

  /**
   * For possible address component types:
   * @see https://developers.google.com/maps/documentation/javascript/supported_types
   */
  const addressComponents = placeRes.address_components;

  const suburb =
    addressComponents?.find((c) => c.types.includes('locality'))?.long_name ??
    null;

  const rawState =
    addressComponents?.find((c) =>
      c.types.includes('administrative_area_level_1'),
    )?.short_name ?? null;

  const state = rawState
    ? parseEnumType(State_Enum, rawState.toUpperCase())
    : null;

  const postcode =
    addressComponents?.find((c) => c.types.includes('postal_code'))
      ?.long_name ?? null;

  return {
    suburb,
    state,
    postcode,
  };
}
