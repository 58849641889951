import { useFocusEffect } from '@react-navigation/native';
import { View } from 'dripsy';
import FocusTrap from 'focus-trap-react';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { ViewProps } from 'react-native';

import { isRunningOnStorybook } from '../utils/isRunningOnStorybook';
import { isTest } from '../utils/platformUtils';

const DELAY_ENABLE_FOCUS_ON_MS = 250;

const IsStorybook = isRunningOnStorybook();

export type FocusOnProps = {
  enabled?: boolean;
  children: React.ReactNode;
  onClickOutside?: () => void;
  onEscapeKey?: () => void;
  style?: ViewProps['style'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRefs?: React.RefObject<any>[];
  // Focus trap delay is a workaround for broken animation.
  // Having timer in tests is complicating things,
  // hence we don't use timer and let animation breaks in tests by default.
  initialDelayedFocusTrapEnabled?: boolean;
};

export function FocusOn({
  children,
  enabled: initialEnabled = true,
  onClickOutside,
  onEscapeKey,
  style,
  containerRefs,
  initialDelayedFocusTrapEnabled = isTest || IsStorybook,
}: FocusOnProps) {
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null,
  );

  const [delayedFocusTrapEnabled, setDelayedFocusTrapEnabled] = useState(
    initialDelayedFocusTrapEnabled,
  );

  const enabled = initialEnabled && delayedFocusTrapEnabled;

  // Note: This is required to apply focus trap once the modal is rendered.
  // If we don't delay, the modal animation will be broken.
  useFocusEffect(
    useCallback(() => {
      if (initialDelayedFocusTrapEnabled) {
        return () => {};
      }

      const timeoutId = setTimeout(() => {
        setDelayedFocusTrapEnabled(true);
      }, DELAY_ENABLE_FOCUS_ON_MS);

      return () => {
        clearTimeout(timeoutId);
      };
    }, [initialDelayedFocusTrapEnabled]),
  );

  const clickOutsideDeactivates = () => {
    if (onClickOutside) {
      onClickOutside();

      return true;
    }

    return false;
  };

  const escapeDeactivates = () => {
    if (onEscapeKey) {
      onEscapeKey();

      return true;
    }

    return false;
  };

  return (
    <View
      ref={(element) => setContainerElement(element as unknown as HTMLElement)}
      style={style}
    >
      {containerElement ? (
        <FocusTrap
          active={enabled}
          containerElements={[
            ...(containerRefs?.map((ref) => ref.current) ?? []),
            containerElement,
          ]}
          focusTrapOptions={{
            clickOutsideDeactivates,
            escapeDeactivates,
            fallbackFocus: containerElement,
            initialFocus: false,
          }}
        >
          <View style={{ height: '100%', width: '100%' }}>{children}</View>
        </FocusTrap>
      ) : null}
    </View>
  );
}
