import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function FilterIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(16, 17, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 25 25" fill="none" {...props}>
      <Path
        d="M12.0508 24.4922C18.5898 24.4922 24.0039 19.0664 24.0039 12.5391C24.0039 6 18.5781 0.585938 12.0391 0.585938C5.51172 0.585938 0.0976562 6 0.0976562 12.5391C0.0976562 19.0664 5.52344 24.4922 12.0508 24.4922ZM12.0508 22.5C6.51953 22.5 2.10156 18.0703 2.10156 12.5391C2.10156 7.00781 6.50781 2.57812 12.0391 2.57812C17.5703 2.57812 22 7.00781 22.0117 12.5391C22.0234 18.0703 17.582 22.5 12.0508 22.5ZM5.67578 10.2305H18.4492C18.9648 10.2305 19.3398 9.90234 19.3398 9.41016C19.3398 8.90625 18.9648 8.57812 18.4492 8.57812H5.67578C5.14844 8.57812 4.78516 8.90625 4.78516 9.41016C4.78516 9.90234 5.14844 10.2305 5.67578 10.2305ZM7.41016 14.1094H16.7148C17.2305 14.1094 17.5938 13.7812 17.5938 13.2891C17.5938 12.7969 17.2305 12.457 16.7148 12.457H7.41016C6.88281 12.457 6.51953 12.7969 6.51953 13.2891C6.51953 13.7812 6.88281 14.1094 7.41016 14.1094ZM9.21484 18H14.8984C15.4141 18 15.7891 17.6719 15.7891 17.1797C15.7891 16.6758 15.4141 16.3477 14.8984 16.3477H9.21484C8.69922 16.3477 8.33594 16.6758 8.33594 17.1797C8.33594 17.6719 8.69922 18 9.21484 18Z"
        fill={color}
      />
    </Svg>
  );
}
