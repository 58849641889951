import * as React from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { expensesFormAtom } from '../../Expenses/recoil/expensesFormAtom';
import { useSubmitHouseholdExpensesV2 } from '../../Expenses/utils/useSubmitHouseholdExpensesV2';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props = ActionSheetScreenProps<ActionSheetType.LOW_EXPENSES>;

export const LowExpensesActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { loanApplicationId, householdId } = route.params || {};
  const { tryNavigateBackToSummary: tryNavigateBackToSummaryV2 } =
    useAppSummaryScreenNavigation({
      navigation,
      route,
      loanApplicationId,
    });

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToLoanApplicationExpenses = () =>
    tryNavigateBackToSummaryV2(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Expense,
      });
    });

  const { submitHouseholdExpensesV2, submitHouseholdExpenseLoadingV2 } =
    useSubmitHouseholdExpensesV2({
      loanApplicationId,
      householdId,
      needHemCheck: false,
      navigateBackToLoanApplicationExpenses,
    });

  const isSubmitting = submitHouseholdExpenseLoadingV2;

  const [expensesFormValuesV2, setExpensesFormValuesV2] =
    useRecoilState(expensesFormAtom);

  const onContinuePress = async () => {
    if (expensesFormValuesV2 == null) {
      navigateBackToLoanApplicationExpenses();
      return;
    }
    await submitHouseholdExpensesV2(expensesFormValuesV2);
    // Reset form to initial value after storing the household
    setExpensesFormValuesV2(null);
    navigateBackToLoanApplicationExpenses();
  };

  const shouldDisableActionButton =
    loadingLoanApplicationMetadata || isSubmitting;

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={isSubmitting}
      title={t('Content.YourExpenses.LowExpenses.Title')}
      isRowButton
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Continue'),
          onPress: onContinuePress,
          showSpinner: isSubmitting,
          disabled: shouldDisableActionButton,
          secondary: true,
          testID: TestID.LowExpensesActionSheet.ContinueButton,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Review'),
          onPress: navigation.goBack,
          secondary: true,
          testID: TestID.LowExpensesActionSheet.ReviewButton,
          flex: 1,
          disabled: shouldDisableActionButton,
        },
      ]}
    >
      {loadingLoanApplicationMetadata ? (
        <Spinner />
      ) : (
        <ActionSheet.MessageText pt="m">
          {t('Content.YourExpenses.LowExpenses.Description')}
        </ActionSheet.MessageText>
      )}
    </ActionSheet>
  );
};
