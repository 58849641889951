import { getGlobal } from './getGlobal';

export function isRunningOnStorybook(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: this global value is set in storybook/storybookEnv.ts
  return getGlobal()?.STORYBOOK === true;
}

/**
 * Generally we want to toggle off the animation when running in Chromatic
 * to ensure consistent visual tests and avoid false positives.
 * https://www.chromatic.com/docs/animations
 *
 * We need a better strategy to turn off animation globally,
 * not only for Chromatic, but some times for test too.
 * Right now we don't have a lot of animations
 * and existing ones have various different way of implementing them.
 *
 * If we ever plan to standardize animations, we should also plan on
 * how to disable them globally.
 */
export function isChromatic(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: this global value is set in storybook/storybookEnv.ts
  return getGlobal()?.CHROMATIC === true;
}
