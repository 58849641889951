import { ApplicantState } from '../../utils/useApplicantStates';

const STEPPER_STATES = [
  [
    ApplicantState.Joined,
    ApplicantState.NotYetJoined,
    ApplicantState.Submitted,
    ApplicantState.PendingCoborrowerEvidenced,
    ApplicantState.PendingConditionallyApproved,
    ApplicantState.ConditionallyApproved,
    ApplicantState.Evidenced,
  ],
  [
    ApplicantState.PendingPortalApproved,
    ApplicantState.PaperContract,
    ApplicantState.Approved,
    ApplicantState.PendingCoborrowerSigned,
    ApplicantState.Signed,
  ],
  [
    ApplicantState.DocumentsCompleted,
    ApplicantState.Booked,
    ApplicantState.BookedWorkspaceAccepted,
    ApplicantState.BookedWorkspacePending,
    ApplicantState.Settled,
    ApplicantState.TopUpPreDisbursement,
    ApplicantState.Disbursed,
  ],
];

export const getStepperProgresses = (
  state: ApplicantState,
): [number, number, number] => {
  if (state === ApplicantState.Declined) {
    return [0, 0, 0];
  }
  let found = false;
  return STEPPER_STATES.map((states): number => {
    const index = states.indexOf(state);
    if (found) {
      return 0;
    }
    if (index < 0) {
      return 1;
    }
    found = true;
    return (index + 1) / states.length;
  }) as [number, number, number];
};
