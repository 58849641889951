import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { useEffect, useRef } from 'react';

import {
  Exact,
  GetMeQuery,
  Loan_Application_Type_Enum,
  ProcessIdvCheckMutation,
  refetchGetLoanApplicationTasksQuery,
  refetchGetMeQuery,
} from '../../generated/graphql';
import {
  onfidoCompletedEvents,
  onfidoConsentGrantedEvents,
  onfidoEmitter,
  OnfidoEventOrigin,
} from '../../LoanApplication/utils/idValidationEventEmitter';
import { Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from './errorUtils';

type useOnfidoEventsProps = {
  loanApplicationId?: string;
  loanApplicationType?: Loan_Application_Type_Enum;
  user?: GetMeQuery['me'][number]['user'];
  onfidoEventOrigin: OnfidoEventOrigin;
  startIDValidation: () => Promise<void>;
  processIDVCheck: (
    options?: MutationFunctionOptions<
      ProcessIdvCheckMutation,
      Exact<{ checkId: string }>
    >,
  ) => Promise<FetchResult<ProcessIdvCheckMutation>>;
  navigation: NavigationProp<ParamListBase>;
};

export const useOnfidoEvents = ({
  loanApplicationId = '',
  loanApplicationType,
  user,
  onfidoEventOrigin,
  startIDValidation,
  processIDVCheck,
  navigation,
}: useOnfidoEventsProps) => {
  /**
   * Why we use useRef here?
   * We're using these two values in the event handler and we don't want them as useEffect's dependency
   * We don't want to add new event listener if startIDValidation / processIDVCheck function's reference is changing
   * One way to fix this is to store the function reference in ref
   * this is a recommended way by react
   * "If the function you’re memoizing is an event handler and isn’t used during rendering, you can use ref as an instance variable, and save the last committed value into it manually". See: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
   */
  const startIDValidationRef = useRef(startIDValidation);
  const processIDVCheckRef = useRef(processIDVCheck);

  useEffect(() => {
    const consentGrantedEvent = onfidoConsentGrantedEvents[onfidoEventOrigin];
    const completedEvent = onfidoCompletedEvents[onfidoEventOrigin];

    if (consentGrantedEvent) {
      onfidoEmitter.on(consentGrantedEvent, async () => {
        await startIDValidationRef.current();
      });
    }

    if (completedEvent) {
      onfidoEmitter.on(completedEvent, async ({ kycCheckId }) => {
        const [_, error] = await safelyCallMutation(
          processIDVCheckRef.current,
          {
            variables: { checkId: kycCheckId },
            refetchQueries: [
              refetchGetMeQuery(),
              ...(loanApplicationId
                ? [refetchGetLoanApplicationTasksQuery({ loanApplicationId })]
                : []),
            ],
            awaitRefetchQueries: true,
            context: {
              sentryContext: {
                loanApplicationId,
                kycCheckId,
              },
            },
          },
        );
        if (!error) {
          if (loanApplicationType === Loan_Application_Type_Enum.Purchase) {
            navigation.navigate(
              Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED,
              {
                loanApplicationId,
              },
            );
          } else if (
            loanApplicationType === Loan_Application_Type_Enum.Refinance
          ) {
            navigation.navigate(Screen.LOAN_APPLICATION_V2_VOI_COMPLETED, {
              loanApplicationId,
            });
          } else {
            navigation.navigate(Screen.VOI_COMPLETED);
          }
        }
      });
    }

    return () => {
      if (consentGrantedEvent) {
        onfidoEmitter.off(consentGrantedEvent);
      }
      if (completedEvent) {
        onfidoEmitter.off(completedEvent);
      }
    };
  }, [
    loanApplicationId,
    loanApplicationType,
    onfidoEventOrigin,
    user,
    navigation,
  ]);
};
