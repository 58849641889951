import { gql } from '@apollo/client';

import { useUpdateApplicationConfirmationActionSheetMutation } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export const UPDATE_APPLICATION_MUTATION_DOC = gql`
  mutation UpdateApplicationConfirmationActionSheet($loanApplicationId: uuid!) {
    rework_loan_application_to_conditionally_approved(
      loan_application_id: $loanApplicationId
    ) {
      returning {
        id
        conditional_approval_evaluation_state
        pending_verified_conditionally_approved_at
        verified_conditionally_approved_at

        status_info {
          id
          can_user_edit
          loan_application_stage
        }
      }
    }
  }
`;

type UpdateApplicationConfirmationActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.UPDATE_APPLICATION_CONFIRMATION>;

export function UpdateApplicationConfirmationActionSheet({
  navigation,
  route,
}: UpdateApplicationConfirmationActionSheetProps) {
  const { loanApplicationId = '' } = route.params || {};

  const goBack = () => navigation.goBack();

  const [commit, { loading }] =
    useUpdateApplicationConfirmationActionSheetMutation();

  const onContinuePress = async () => {
    const [_, error] = await safelyCallMutation(commit, {
      variables: {
        loanApplicationId,
      },
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

    if (error) {
      // For MVP, we are okay with no UI feedback when there is an error,
      // and let user retry.
      goBack();
      return;
    }

    goBack();
  };

  const shouldDisableActionButton = loading;

  return (
    <ActionSheet
      closeModal={goBack}
      preventCloseModal={loading}
      title={t('Content.UpdateApplicationConfirmationActionSheet.Title')}
      isRowButton
      showCloseButton={false}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          disabled: shouldDisableActionButton,
          secondary: true,
          onPress: goBack,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Continue'),
          disabled: shouldDisableActionButton,
          showSpinner: loading,
          secondary: true,
          onPress: onContinuePress,
          flex: 1,
        },
      ]}
    >
      <ActionSheet.MessageText pt="m">
        {t('Content.UpdateApplicationConfirmationActionSheet.Message')}
      </ActionSheet.MessageText>
    </ActionSheet>
  );
}
