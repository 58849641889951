import { useMemo } from 'react';

import {
  Applicant_Role_Enum,
  useGetBorrowerDetailQuery,
} from '../../../generated/graphql';
import {
  mapFrankieoneAddressToAppAddress,
  mapHasuraAddressToAppAddress,
} from '../../../utils/mapToAppAddress';
import { parseAddressToBorrowerDetailsEditFormInitialValues } from './formInitialValues';
import { BorrowerDetailsEditFormValues } from './forms';

export function useGetBorrowerDetailsInitialValue(
  borrowerId: string,
  loanApplicationId: string,
) {
  const { data, loading: borrowerDetailLoading } = useGetBorrowerDetailQuery({
    variables: { applicantId: borrowerId, loanApplicationId },
    skip: !borrowerId,
    context: {
      sentryContext: { applicantId: borrowerId },
    },
  });

  const borrower = data?.applicant_by_pk;
  const firstName = borrower?.latest_first_name ?? '--';
  const fullName = borrower?.latest_full_name ?? '--';
  const isCurrentBorrower =
    borrower?.is_current_logged_in_applicant ?? undefined;
  const isPrimaryBorrower =
    borrower?.role === Applicant_Role_Enum.PrimaryBorrower;
  const mailingAddress = borrower?.user?.mailing_address
    ? mapHasuraAddressToAppAddress(borrower?.user?.mailing_address)
    : null;
  const residentialAddress = borrower?.user_identity_profile
    ?.frankieone_entity_details?.current_residential_address
    ? mapFrankieoneAddressToAppAddress(
        borrower?.user_identity_profile?.frankieone_entity_details
          ?.current_residential_address,
      )
    : null;
  const email = borrower?.latest_email ?? '--';
  const mobileNumber = borrower?.latest_mobile ?? '--';

  const borrowerData = {
    firstName,
    fullName,
    mailingAddress,
    residentialAddress,
    email,
    mobileNumber,
  };

  const submittedAt = data?.loan_application_by_pk?.submitted_at;
  const conditionalApprovalStatus =
    data?.loan_application_by_pk?.conditional_approval_status;

  const loanApplicationData = {
    submittedAt,
    conditionalApprovalStatus,
  };

  const initialFormValues: BorrowerDetailsEditFormValues | undefined =
    useMemo(() => {
      if (isCurrentBorrower) {
        return parseAddressToBorrowerDetailsEditFormInitialValues(
          mailingAddress,
        );
      }
      return undefined;
    }, [isCurrentBorrower, mailingAddress]);

  return {
    borrowerData,
    borrowerDetailLoading,
    isCurrentBorrower,
    isPrimaryBorrower,
    initialFormValues,
    loanApplicationData,
  };
}
