import { useFocusEffect } from '@react-navigation/native';
import { Suspense, useCallback, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ContentJson from '../../../assets/newFeatures/content.json';
import { useUser } from '../../Auth/hooks';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useHomeNavigation } from '../../Home/utils/useHomeNavigation';
import { Screen } from '../../navigation/types/screens';
import { navigationReadyAtom } from '../../recoil/navigationReady';
import { newFeaturesModalVersionAtomFamily } from '../recoil/newFeaturesModalVersion';

const isWithinSchedule = (scheduleFrom: string, scheduleTo: string) => {
  const now = new Date();
  const from = new Date(scheduleFrom);
  const to = new Date(scheduleTo);

  return now >= from && now <= to;
};

export function useNewFeaturesModalMonitor(content = ContentJson) {
  const user = useUser();

  const userId = user?.user?.id ?? '';

  const [version, setVersion] = useRecoilState(
    newFeaturesModalVersionAtomFamily(userId),
  );

  const { flags } = useContext(FeatureFlagsContext);

  const { version: newVersion } = content;

  const { from: scheduleFrom, to: scheduleTo } = content.schedule;

  const navigationReady = useRecoilValue(navigationReadyAtom);

  const navigation = useHomeNavigation();

  useFocusEffect(
    useCallback(() => {
      if (!navigationReady) {
        return;
      }

      if (!userId) {
        return;
      }

      if (!flags.ENABLE_NEW_FEATURES_SCREEN) {
        return;
      }

      if (!isWithinSchedule(scheduleFrom, scheduleTo)) {
        return;
      }

      if (version === newVersion) {
        return;
      }

      setVersion(newVersion);

      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.NEW_FEATURES_MODAL,
      });
    }, [
      navigationReady,
      userId,
      flags.ENABLE_NEW_FEATURES_SCREEN,
      scheduleFrom,
      scheduleTo,
      version,
      newVersion,
      setVersion,
      navigation,
    ]),
  );
}

function BaseNewFeaturesModalMonitor() {
  useNewFeaturesModalMonitor();

  return null;
}

export function NewFeaturesModalMonitor() {
  // Suspense is needed here because of @react-native-async-storage/async-storage in newFeaturesModalVersionAtomFamily
  return (
    <Suspense fallback={null}>
      <BaseNewFeaturesModalMonitor />
    </Suspense>
  );
}
