import { useMemo } from 'react';

import { useGetLoanAccountPropertyAddressQuery } from '../../generated/graphql';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';

export const useGetLoanAccountName = (cbaAccountId: string) => {
  const { data, loading, error } = useGetLoanAccountPropertyAddressQuery({
    variables: { cba_account_id: cbaAccountId },
    context: { sentryContext: { cbaAccountId } },
  });

  const loanAccount = data?.loan_account[0]?.settings;

  const {
    name: loanAccountName,
    bsb: accountBsb,
    account_number: accountNumber,
  } = loanAccount ?? {};

  const propertyDisplayAddress =
    data?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.property?.address?.short_address_format;

  const bsbJoinedWithAccountNumber =
    accountBsb && accountNumber
      ? formatBsbAndAccountNumber(accountBsb, accountNumber)
      : '';

  const accountName = propertyDisplayAddress || loanAccountName;

  return {
    loading,
    error,
    data: useMemo(
      () => ({
        accountName,
        accountBsb,
        accountNumber,
        bsbJoinedWithAccountNumber,
      }),
      [accountBsb, accountName, accountNumber, bsbJoinedWithAccountNumber],
    ),
  };
};
