import {
  BaseTheme,
  ThemeProvider as RestyleThemeProvider,
  useTheme as useRestyleTheme,
} from '@shopify/restyle';
import { fontWeights, getLetterSpacing } from '@unloan/common-ui';
import { StyleSheet } from 'react-native';

export const ThemeProvider = RestyleThemeProvider;
export const createTheme = <T extends BaseTheme>(themeObject: T): T =>
  themeObject;

// Custom values
export const constants = {
  borderWidth: StyleSheet.hairlineWidth,
  minRowHeight: 48,
  minRowHeightV2: 54,
  maxContentWidth: 450,
  maxContentColumnWidth: 420,
  maxDashboardWidth: 976,
  // The marker (knob) size of a slider.
  markerSize: 28,
  verticalStepper: {
    /**
     * Used for the bar width and the bullet border width
     */
    barWidth: 2,
    /**
     * Spacing between the bullet and the bar
     */
    barSpacing: 6,
    bulletSize: 16,
    /**
     * Overlay size displayed behind the currently active bullet
     */
    currentBulletSize: 30,
  },
  maxMultilineTextInputHeight: 96,
  phoneNumberFlagContainerWidth: 86,
};

export const tabletConstants: typeof constants = {
  ...constants,
  minRowHeight: 56,
  maxContentWidth: 550,
  maxDashboardWidth: 976,
};

export const sizes = {
  stepperIcon: 10,
  navIcon: 21,
  xs: 8,
  tiny: 12,
  s: 16,
  '2s': 18,
  m: 24, // < default
  '2m': 32,
  l: 36,
  xl: 60,
  '2xl': 90,
  '3xl': 140,
};

// Layout / Sizing
export const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
};

export const spacing = {
  0: 0,
  '2xs': 2,
  xs: 4,
  s: 8, // < base (content padding)
  m: 16, // screen margin
  l: 24,
  xl: 32,
  '2xl': 48,
  '3xl': 64,
  '4xl': 96,
};

export const tabletSpacing: typeof spacing = {
  0: 0,
  '2xs': 2,
  xs: 4,
  s: 10, // < base (content padding)
  m: 20, // screen margin
  l: 30,
  xl: 40,
  '2xl': 60,
  '3xl': 80,
  '4xl': 100,
};

export const borderRadii = {
  tag: 4,
  rowIcon: 6,
  field: 10,
  button: 10,
  card: 12,
  row: 15,
  rounded: 100,
  rowV2: 12,
};

const getColor = (color: string, opacity = 1) =>
  color.replace(
    /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
    `rgba$1,${opacity})`,
  );

// Colors
const lightPalette = {
  blue: '#0066CC',
  red: '#D70015',
  orange: 'rgb(255,149,0)',
  yellow: 'rgb(255,204,0)',
  green: 'rgb(0, 125, 27)',
  indigo: 'rgb(88,86,214)',
  purple: 'rgb(175,82,222)',
  pink: 'rgb(255,47,85)',
};

const darkPalette: typeof lightPalette = {
  blue: 'rgb(10,132,255)',
  red: 'rgb(255,69,58)',
  orange: 'rgb(255,159,10)',
  yellow: 'rgb(255,214,10)',
  green: 'rgb(50,215,75)',
  indigo: 'rgb(94,92,230)',
  purple: 'rgb(191,90,242)',
  pink: 'rgb(255,55,95)',
};

const greyPalette = {
  transparent: 'transparent',
  light: 'rgb(255,255,255)',
  // Note: If this is changed, please also update the value in app.config.ts web.themeColor
  lightGray: 'rgb(247,247,247)',
  darkGray: '#1C1C1E',
  oledDark: '#010101',
  dark: 'rgb(0,0,0)',
};

const iosSystemFillColor = {
  lightTertiary: 'rgba(118, 118, 128, 0.12)',
  darkTertiary: 'rgba(118, 118, 128, 0.24)',
};

export type Colors = typeof lightColors;

const PRIMARY_OPACITY = 0.95;
const SECONDARY_OPACITY = 0.6;
const DISABLED_OPACITY = 0.2;

const lightColors = {
  transparent: greyPalette.transparent,
  accent: greyPalette.dark,
  accentBg: getColor(greyPalette.dark, DISABLED_OPACITY),
  primaryContent: getColor(greyPalette.dark, PRIMARY_OPACITY),
  secondaryContent: getColor(greyPalette.dark, SECONDARY_OPACITY),
  disabledContent: getColor(greyPalette.dark, DISABLED_OPACITY),
  link: lightPalette.blue,

  // Can we reuse same color naming with figma?
  // e.g. Labels/LM Secondary, Status/LM Hyperlink
  // instead of inventing a new one here
  // V2 palette
  focus: 'rgba(0, 122, 255, 0.6)',
  secondaryV2: '#666',
  secondaryHoverV2: '#000',
  secondaryDisabledV2: '#6E6E73',
  borderV2: 'rgba(60, 60, 67, 0.15)',
  inputBgV2: '#FFF',
  inputDisabledBgV2: '#F4F4F4',

  // Buttons
  buttonPrimaryContent: getColor(greyPalette.light, PRIMARY_OPACITY),
  buttonPrimaryBg: greyPalette.dark,
  buttonPrimaryLightContent: getColor(greyPalette.dark, PRIMARY_OPACITY),
  buttonPrimaryLightBg: greyPalette.light,
  buttonSecondaryContent: greyPalette.dark,
  buttonSecondaryBg: getColor(greyPalette.dark, 0.05),
  buttonTertiaryContent: greyPalette.dark,
  buttonTertiaryBg: greyPalette.transparent,
  buttonDisabledBg: getColor(greyPalette.dark, 0.05),
  buttonTertiaryDisabledBg: greyPalette.transparent,
  xButtonBg: 'rgba(0, 0, 0, 0.5)',

  // Tooltip
  tooltipPrimaryContent: getColor(greyPalette.dark, 0.9),

  // Chin
  chinContentPrimary: getColor(greyPalette.light, PRIMARY_OPACITY),
  chinContentSecondary: getColor(greyPalette.light, SECONDARY_OPACITY),
  chinBg: greyPalette.dark,
  chinValue: getColor(greyPalette.light, PRIMARY_OPACITY),

  success: lightPalette.green,
  successBg: getColor(lightPalette.green, 0.1),
  warning: lightPalette.orange,
  warningBg: getColor(lightPalette.orange, 0.1),
  alert: lightPalette.yellow,
  alertBg: getColor(lightPalette.yellow, 0.1),
  error: lightPalette.red,
  errorBg: 'rgb(247, 235, 237)',

  bg: greyPalette.lightGray,
  translucentGreyBg: getColor(greyPalette.lightGray, 0.05),
  bgSecondary: '#F2F2F7',
  shapeBg: greyPalette.light,
  greyBg: '#202020EB',
  translucentBg: getColor(greyPalette.light, 0.95),
  border: getColor(greyPalette.dark, 0.1),

  overlayBg: getColor(greyPalette.dark, SECONDARY_OPACITY),
  overlayContent: greyPalette.light,

  // Skeleton colors
  skeletonPrimary: 'rgb(250, 250, 250)',
  skeletonSecondary: 'rgb(205, 205, 205)',
  skeletonPrimaryV2: 'rgba(202, 202, 202, 0.12)',
  skeletonSecondaryV2: 'rgba(120, 120, 128, 0.00)',

  // Segmented control
  segmentedControlBg: iosSystemFillColor.lightTertiary,

  // insight graph
  insightGrey: 'rgb(60, 60, 67)',
  inactiveTabNav: '#8A8A8E',
  infoIconDefault: '#3C3C43',
  infoIcon: '#86868B',
};

const darkColors: Colors = {
  transparent: greyPalette.transparent,
  accent: greyPalette.light,
  accentBg: getColor(greyPalette.dark, DISABLED_OPACITY),
  primaryContent: getColor(greyPalette.light, PRIMARY_OPACITY),
  secondaryContent: getColor(greyPalette.light, SECONDARY_OPACITY),
  disabledContent: getColor(greyPalette.light, DISABLED_OPACITY),
  link: darkPalette.blue,

  focus: 'rgba(0, 122, 255, 0.6)',
  secondaryV2: '#666',
  secondaryHoverV2: getColor(greyPalette.light, PRIMARY_OPACITY),
  secondaryDisabledV2: '#6E6E73',
  borderV2: 'rgba(60, 60, 67, 0.15)',
  inputBgV2: '#FFF',
  inputDisabledBgV2: '#F4F4F4',

  // Buttons
  buttonPrimaryContent: getColor(greyPalette.dark, PRIMARY_OPACITY),
  buttonPrimaryBg: greyPalette.light,
  buttonPrimaryLightContent: getColor(greyPalette.light, PRIMARY_OPACITY),
  buttonPrimaryLightBg: greyPalette.dark,
  buttonSecondaryContent: greyPalette.light,
  buttonSecondaryBg: getColor(greyPalette.light, 0.15),
  buttonTertiaryContent: greyPalette.light,
  buttonTertiaryBg: greyPalette.transparent,
  buttonDisabledBg: getColor(greyPalette.light, 0.1),
  buttonTertiaryDisabledBg: greyPalette.transparent,
  xButtonBg: 'rgba(0, 0, 0, 0.5)',

  // Tooltip
  tooltipPrimaryContent: getColor(greyPalette.light, 0.9),

  // Chin
  chinContentPrimary: getColor(greyPalette.dark, PRIMARY_OPACITY),
  chinContentSecondary: getColor(greyPalette.dark, SECONDARY_OPACITY),
  chinBg: greyPalette.light,
  chinValue: getColor(greyPalette.dark, PRIMARY_OPACITY),

  success: darkPalette.green,
  successBg: getColor(darkPalette.green, 0.2),
  warning: darkPalette.orange,
  warningBg: getColor(darkPalette.orange, 0.2),
  alert: darkPalette.yellow,
  alertBg: getColor(darkPalette.yellow, 0.2),
  error: darkPalette.red,
  // TODO: Need bg color for dark theme
  errorBg: 'rgba(215, 0, 21, 0.2)',

  bg: greyPalette.oledDark,
  // There's no reference for this color in figma
  // TODO: Provide bg secondary for dark color scheme
  bgSecondary: '#F2F2F7',
  shapeBg: greyPalette.darkGray,
  greyBg: '#202020EB',
  translucentGreyBg: getColor(greyPalette.darkGray, 0.05),
  translucentBg: getColor(greyPalette.dark, 0.95),
  border: getColor(greyPalette.light, 0.2),

  overlayBg: getColor(greyPalette.dark, SECONDARY_OPACITY),
  overlayContent: greyPalette.light,

  // Skeleton colors
  skeletonPrimary: 'rgb(17, 17, 17)',
  skeletonSecondary: 'rgb(51, 51, 51)',
  skeletonPrimaryV2: 'rgb(17, 17, 17)',
  skeletonSecondaryV2: 'rgb(51, 51, 51)',

  // Segmented control
  segmentedControlBg: iosSystemFillColor.darkTertiary,

  // insight graph
  insightGrey: 'rgba(60, 60, 67, 0.15)',
  inactiveTabNav: '#8A8A8E',
  infoIconDefault: '#3C3C43',
  infoIcon: '#86868B',
};

// Typography
const fontSizes = {
  '2xs': 10,
  xs: 13,
  s: 15,
  m: 17,
  l: 20,
  xl: 28,
  '2xl': 34,
  '3xl': 48,
};
const tabletFontSizes = {
  '2xs': 12,
  xs: 16,
  s: 18,
  m: 20,
  l: 24,
  xl: 28,
  '2xl': 41,
  '3xl': 58,
};

const lineHeights = {
  0: 0,
  '2xs': 15,
  xs: 17,
  s: 20,
  m: 22,
  l: 26,
  xl: 32,
  '2xl': 40,
  '3xl': 54,
};
const tabletLineHeights = {
  '0': 0,
  '2xs': 18,
  xs: 20,
  s: 24,
  m: 26,
  l: 31,
  xl: 38,
  '2xl': 48,
  '3xl': 65,
};

// These are actually font families, but since we are using a custom font,
// we cant use the fontWeight style in react native to change the weight.
// Instead, we have to specify a different family. Setting this as fontWeights
// to make it explicit that this is to be consumed by the fontWeight prop in restyle
export { fontWeights, getLetterSpacing };

const zIndices = {
  up: 1,
  down: -1,
};

export const textVariants = {
  headerLarge: {
    fontSize: fontSizes['3xl'],
    letterSpacing: getLetterSpacing(fontSizes['3xl']),
    lineHeight: lineHeights['3xl'],
    fontFamily: fontWeights.bold,
    color: 'primaryContent',
  },
  header: {
    fontSize: fontSizes['2xl'],
    letterSpacing: getLetterSpacing(fontSizes['2xl']),
    lineHeight: lineHeights['2xl'],
    fontFamily: fontWeights.bold,
    color: 'primaryContent',
  },
  headerMedium: {
    fontSize: fontSizes.xl,
    letterSpacing: getLetterSpacing(fontSizes.xl),
    lineHeight: lineHeights['2xl'],
    fontFamily: fontWeights.semiBold,
    color: 'primaryContent',
  },
  headerSmall: {
    fontSize: fontSizes.l,
    letterSpacing: getLetterSpacing(fontSizes.l),
    lineHeight: lineHeights.l,
    fontFamily: fontWeights.semiBold,
    color: 'primaryContent',
  },
  caption: {
    fontSize: fontSizes.xs,
    letterSpacing: getLetterSpacing(fontSizes.xs),
    lineHeight: lineHeights.xs,
    fontFamily: fontWeights.normal,
    color: 'secondaryContent',
  },
  default: {
    fontSize: fontSizes.m,
    letterSpacing: getLetterSpacing(fontSizes.m),
    lineHeight: lineHeights.m,
    fontFamily: fontWeights.normal,
    color: 'primaryContent',
  },
  body: {
    fontSize: fontSizes.s,
    letterSpacing: getLetterSpacing(fontSizes.s),
    lineHeight: lineHeights.s,
    fontFamily: fontWeights.normal,
    color: 'primaryContent',
  },
  numberLarge: {
    fontSize: fontSizes['3xl'],
    letterSpacing: getLetterSpacing(fontSizes['3xl']),
    lineHeight: lineHeights['3xl'],
    fontFamily: fontWeights.semiBold,
    color: 'primaryContent',
  },
  number: {
    fontSize: fontSizes['2xl'],
    letterSpacing: getLetterSpacing(fontSizes['2xl']),
    lineHeight: lineHeights['2xl'],
    fontFamily: fontWeights.medium,
    color: 'primaryContent',
  },
  numberSmall: {
    fontSize: fontSizes.l,
    letterSpacing: getLetterSpacing(fontSizes.l),
    lineHeight: lineHeights.l,
    fontFamily: fontWeights.medium,
    color: 'primaryContent',
  },
  button: {
    fontSize: fontSizes.m,
    letterSpacing: getLetterSpacing(fontSizes.m),
    lineHeight: lineHeights.m,
    fontFamily: fontWeights.semiBold,
    color: 'primaryContent',
  },
  contentEmphasis: {
    fontSize: fontSizes.m,
    letterSpacing: getLetterSpacing(fontSizes.m),
    lineHeight: lineHeights.m,
    fontFamily: fontWeights.medium,
    color: 'primaryContent',
  },
  cardTitle: {
    fontSize: fontSizes['2xl'],
    letterSpacing: getLetterSpacing(fontSizes['2xl']),
    lineHeight: lineHeights['2xl'],
    fontFamily: fontWeights.semiBold,
    color: 'primaryContent',
  },
  tiny: {
    fontSize: 11,
    letterSpacing: getLetterSpacing(11),
    lineHeight: 15,
    fontFamily: fontWeights.normal,
    color: 'secondaryV2',
  },
  footer: {
    fontSize: fontSizes['2xs'],
    letterSpacing: getLetterSpacing(fontSizes['2xs']),
    lineHeight: lineHeights['2xs'],
    fontFamily: fontWeights.normal,
    color: 'secondaryContent',
  },
} as const;

const tabletTextVariants: typeof textVariants = {
  headerLarge: {
    ...textVariants.headerLarge,
    fontSize: tabletFontSizes['3xl'],
    letterSpacing: getLetterSpacing(tabletFontSizes['3xl']),
    lineHeight: tabletLineHeights['3xl'],
  },
  header: {
    ...textVariants.header,
    fontSize: tabletFontSizes['2xl'],
    letterSpacing: getLetterSpacing(tabletFontSizes['2xl']),
    lineHeight: tabletLineHeights['2xl'],
  },
  headerMedium: {
    ...textVariants.headerMedium,
    fontSize: tabletFontSizes.xl,
    letterSpacing: getLetterSpacing(tabletFontSizes.xl),
    lineHeight: tabletLineHeights['2xl'],
  },
  headerSmall: {
    ...textVariants.headerSmall,
    fontSize: tabletFontSizes.l,
    letterSpacing: getLetterSpacing(tabletFontSizes.l),
    lineHeight: tabletLineHeights.l,
  },
  caption: {
    ...textVariants.caption,
    fontSize: tabletFontSizes.xs,
    letterSpacing: getLetterSpacing(tabletFontSizes.xs),
    lineHeight: tabletLineHeights.xs,
  },
  default: {
    ...textVariants.default,
    fontSize: tabletFontSizes.m,
    letterSpacing: getLetterSpacing(tabletFontSizes.m),
    lineHeight: tabletLineHeights.m,
  },
  body: {
    ...textVariants.body,
    fontSize: tabletFontSizes.s,
    letterSpacing: getLetterSpacing(tabletFontSizes.s),
    lineHeight: tabletLineHeights.s,
  },
  numberLarge: {
    ...textVariants.numberLarge,
    fontSize: tabletFontSizes['3xl'],
    letterSpacing: getLetterSpacing(tabletFontSizes['3xl']),
    lineHeight: tabletLineHeights['3xl'],
  },
  number: {
    ...textVariants.number,
    fontSize: tabletFontSizes['2xl'],
    letterSpacing: getLetterSpacing(tabletFontSizes['2xl']),
    lineHeight: tabletLineHeights['2xl'],
  },
  numberSmall: {
    ...textVariants.numberSmall,
    fontSize: tabletFontSizes.l,
    letterSpacing: getLetterSpacing(tabletFontSizes.l),
    lineHeight: tabletLineHeights.l,
  },
  button: {
    ...textVariants.button,
    fontSize: tabletFontSizes.m,
    letterSpacing: getLetterSpacing(tabletFontSizes.m),
    lineHeight: tabletLineHeights.m,
  },
  contentEmphasis: {
    ...textVariants.contentEmphasis,
    fontSize: tabletFontSizes.m,
    letterSpacing: getLetterSpacing(tabletFontSizes.m),
    lineHeight: tabletLineHeights.m,
  },
  cardTitle: {
    ...textVariants.cardTitle,
    fontSize: tabletFontSizes['2xl'],
    letterSpacing: getLetterSpacing(tabletFontSizes['2xl']),
    lineHeight: tabletLineHeights['2xl'],
  },
  tiny: {
    // TODO: Tablet sizings?
    ...textVariants.tiny,
  },
  footer: {
    ...textVariants.footer,
    fontSize: tabletFontSizes['2xs'],
    letterSpacing: getLetterSpacing(tabletFontSizes['2xs']),
    lineHeight: tabletLineHeights['2xs'],
  },
};

const buttonVariants = {
  base: {
    minHeight: constants.minRowHeight,
    alignSelf: {
      tablet: 'center',
    },
  },
  pill: {
    fontSize: fontSizes.s,
    minHeight: 28,
    borderRadius: 'rounded',
  },
  circle: {
    aspectRatio: 1,
    borderRadius: 'rounded',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: sizes.l,
    height: sizes.l,
  },
};
const tabletButtonVariants = {
  ...buttonVariants,
  pill: {
    ...buttonVariants.pill,
    fontSize: tabletFontSizes.s,
  },
  base: {
    minHeight: tabletConstants.minRowHeight,
    alignSelf: {
      tablet: 'center',
    },
  },
};

export const defaultLightTheme = createTheme({
  spacing,
  breakpoints,
  colors: lightColors,
  sizes,
  fontSizes,
  lineHeights,
  zIndices,
  fontWeights,
  borderRadii,
  constants,
  textVariants,
  buttonVariants,
});

export type Theme = typeof defaultLightTheme;

export const useTheme = () => useRestyleTheme<Theme>();

export const defaultTabletLightTheme: Theme = {
  ...defaultLightTheme,
  fontSizes: tabletFontSizes,
  lineHeights: tabletLineHeights,
  textVariants: tabletTextVariants,
  buttonVariants: tabletButtonVariants,
  spacing: tabletSpacing,
  constants: tabletConstants,
};

export const defaultDarkTheme: Theme = {
  ...defaultLightTheme,
  colors: darkColors,
};
export const defaultTabletDarkTheme: Theme = {
  ...defaultTabletLightTheme,
  colors: darkColors,
};
