import { gql } from '@apollo/client';
import type { SdkHandle, SdkInitMethod, SdkOptions } from 'onfido-sdk-ui';

import {
  refetchGetLoanApplicationTasksQuery,
  useCreateIdvCheckMutation,
} from '../../generated/graphql';
import {
  OnfidoCompletedEvent,
  onfidoEmitter,
} from '../../LoanApplication/utils/idValidationEventEmitter';
import { addBreadcrumb, captureException } from '../../sentry';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import {
  idCheckLogger,
  StartIdValidationHookResult,
} from '../utils/identityCheckUtils';

export const CREATE_IDV_CHECK = gql`
  mutation CreateIDVCheck($forWeb: Boolean!) {
    create_idv_check(data: { for_web: $forWeb }) {
      token
      kyc_check_id
    }
  }
`;

export const PROCESS_IDV_CHECK = gql`
  mutation ProcessIDVCheck($checkId: String!) {
    process_idv_check(data: { kyc_check_id: $checkId }) {
      kyc_check_id
    }
  }
`;

export const IDV_RESULT_SUBSCRIPTION = gql`
  subscription IdvResult {
    me {
      user {
        identity_profile {
          id
          id_validation_result
          id_validation_started_at
        }
      }
    }
  }
`;

// Onfido Container ID HTML - this needs to be synced with the id in index.html
const OnfidoContainerIdHTML = 'idv-modal';

const OnfidoWebSdkConfig: SdkOptions = {
  useModal: true,
  containerId: OnfidoContainerIdHTML,
  isModalOpen: true,
  // We need to set `useMemoryHistory` to true so when we press the back button within the SDK, it doesn't mess up with the browser's history
  // See SDK navigation issues section in https://www.npmjs.com/package/onfido-sdk-ui
  useMemoryHistory: true,
  shouldCloseOnOverlayClick: true,
  steps: [
    {
      type: 'document',
      options: {
        documentTypes: {
          driving_licence: {
            country: 'AUS',
          },

          passport: {
            country: 'AUS',
          },
        },
        /**
         * This forces user to capture using their phone. The reason is:
         * - We haven't been approved for upload capabilities per Jack Li's statement on 15 June. (I assumed this was talking about Risk approval)
         * - We can't turn off upload capabilities on desktop in Onfido SDK, this is a fallback for when user doesn't have webcam on their desktop device
         */
        forceCrossDevice: true,
      },
    },
    { type: 'face' },
  ],
  _crossDeviceLinkMethods: ['qr_code', 'copy_link'],
};

let onfidoWebSdk: SdkHandle | undefined;
export function useStartIDValidation({
  loanApplicationId = '',
  completedEvent,
}: {
  loanApplicationId?: string;
  completedEvent?: OnfidoCompletedEvent;
}): StartIdValidationHookResult {
  const [createIDVCheck, createIDVCheckMutationResult] =
    useCreateIdvCheckMutation();

  const startIDValidation = async () => {
    const [idvCheckResult] = await safelyCallMutation(createIDVCheck, {
      variables: {
        forWeb: true,
      },
      refetchQueries: loanApplicationId
        ? [refetchGetLoanApplicationTasksQuery({ loanApplicationId })]
        : [],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {},
      },
    });
    const idvCheckData = idvCheckResult?.data?.create_idv_check;
    idCheckLogger?.log('Created KYC Check Result', idvCheckData);
    try {
      addBreadcrumb('Lazy Loading Onfido SDK', {
        kycCheckId: idvCheckData?.kyc_check_id,
      });

      const { init } = (await import('onfido-sdk-ui')) as {
        init: SdkInitMethod;
      };
      onfidoWebSdk = init({
        ...OnfidoWebSdkConfig,
        token: idvCheckData?.token,
        onModalRequestClose() {
          onfidoWebSdk?.setOptions({ isModalOpen: false });
          onfidoWebSdk?.tearDown();
        },
        onComplete: () => {
          if (completedEvent && idvCheckData?.kyc_check_id) {
            onfidoEmitter.emit(completedEvent, {
              kycCheckId: idvCheckData.kyc_check_id,
            });
          }
          onfidoWebSdk?.tearDown();
        },
        onUserExit() {
          onfidoWebSdk?.setOptions({ isModalOpen: false });
          onfidoWebSdk?.tearDown();
        },
      });
    } catch (e: unknown) {
      captureException(
        'Error occured while loading onfido SDK for VOI',
        { kycCheckId: idvCheckData?.kyc_check_id },
        e,
      );
    }
  };

  return {
    startIDValidation,
    createIDVCheckMutationResult,
    // On web, IDV flow will be executed on the user's phone
    // by scanning a QR code/opening a link shown in our app.
    // Error from that flow will not be communicated to app,
    // so it will always be null here.
    idvError: null,
  };
}
