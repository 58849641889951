import { TestID } from '../../../testID/constants';
import { Props as ModalFooterProps } from '../components/LoanApplicationWizardFooter';
import { useYourFinancialsScreenQuery } from '../graphql/queries';

type GetYourIncomeModalFooterParams = Pick<
  ReturnType<typeof useYourFinancialsScreenQuery>,
  'incomes'
> & { needIncomeVerification: boolean; hasRequiredIncomeError: boolean };

export function getYourIncomeCaptionAndPrimaryButtonProps({
  incomes,
  needIncomeVerification,
  hasRequiredIncomeError,
}: GetYourIncomeModalFooterParams): Pick<
  ModalFooterProps,
  'footerCaption' | 'primaryButtonLabel' | 'testID' | 'disablePrimaryButton'
> {
  const userHasFilledWizard = incomes && incomes.length > 0;

  if (!userHasFilledWizard) {
    if (needIncomeVerification) {
      // Display continue button when user need income detection
      return {
        primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
        testID: TestID.LoanApplicationYourIncome.ContinueButton,
      };
    }

    return {
      primaryButtonLabel: t('Content.YourIncome.ButtonLabel.AddIncome'),
      testID: TestID.LoanApplicationYourIncome.AddIncomeButton,
    };
  }

  if (hasRequiredIncomeError) {
    return {
      footerCaption: t('Content.YourIncome.MissingIncomeFooter'),
      primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
      testID: TestID.LoanApplicationYourIncome.ContinueButton,
      disablePrimaryButton: true,
    };
  }

  return {
    footerCaption: t('Content.YourIncome.ConfirmationFooter'),
    primaryButtonLabel: t('Content.Common.ButtonLabel.Continue'),
    testID: TestID.LoanApplicationYourIncome.ContinueButton,
  };
}
