import { format } from 'date-fns';
import * as React from 'react';

import { DEFAULT_DATE_DISPLAY_FORMAT } from '../../constants/dateFormats';
import { Autopay_Frequency_Enum } from '../../generated/graphql';
import { Separator } from '../../ui/atoms/Separator';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';

type Props = {
  loading?: boolean;
  repaymentAmount?: number;
  accountName?: string;
  bsbJoinedWithAccountNumber?: string;
  externalAccountName?: string;
  externalAccountBsb?: string;
  externalAccountNumber?: string;
  firstRepaymentDate?: string;
  frequency?: Autopay_Frequency_Enum;
};

export const AutopayDetailListGroup: React.FC<Props> = ({
  repaymentAmount,
  externalAccountName,
  externalAccountBsb,
  externalAccountNumber,
  accountName,
  bsbJoinedWithAccountNumber,
  firstRepaymentDate,
  frequency,
  loading,
}) => (
  <DataRowGroup>
    <DataRow
      loading={loading}
      label={t('Content.AutopaySettings.Amount')}
      caption={repaymentAmount ? formatCurrency(repaymentAmount) : ''}
    />
    <DataRowSeparator />
    <DataRow
      loading={loading}
      label={t('Content.AutopaySettings.FromAccount')}
      my={loading ? 'm' : 0}
      caption={
        <>
          {externalAccountName}

          <Separator spacer py={0} />

          {formatBsbAndAccountNumber(
            externalAccountBsb ?? '',
            externalAccountNumber ?? '',
          )}
        </>
      }
    />
    <DataRowSeparator />
    <DataRow
      loading={loading}
      label={t('Content.AutopaySettings.ToAccount')}
      my={loading ? 'm' : 0}
      caption={
        <>
          {accountName}
          {bsbJoinedWithAccountNumber ? (
            <>
              <Separator spacer py={0} />
              {bsbJoinedWithAccountNumber}
            </>
          ) : null}
        </>
      }
    />
    <DataRowSeparator />
    <DataRow
      loading={loading}
      label={t('Content.AutopaySettings.Commencing')}
      caption={
        firstRepaymentDate
          ? format(Date.parse(firstRepaymentDate), DEFAULT_DATE_DISPLAY_FORMAT)
          : ''
      }
    />
    <DataRowSeparator />
    <DataRow
      loading={loading}
      label={t('Content.AutopaySettings.Frequency')}
      caption={
        frequency ? t(`Content.AutopaySettings.FrequencyEnum.${frequency}`) : ''
      }
    />
  </DataRowGroup>
);
