import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function FingerprintIcon({ color, ...props }: SvgIconProps) {
  return (
    <Svg width={23} height={23} viewBox="0 0 23 23" fill="none" {...props}>
      <Path
        d="M12 11.32c-.602 0-1.031.473-1.031 1.032.043 3.136-.387 6.101-1.203 9.109-.13.43.086 1.289.988 1.289.473 0 .86-.258.988-.73.602-2.063 1.332-5.372 1.29-9.668 0-.559-.473-1.032-1.032-1.032zm-.043-3.48c-2.621 0-4.469 2.02-4.426 4.34 0 2.062-.172 4.125-.601 6.101-.086.559.258 1.117.816 1.246.559.086 1.117-.258 1.203-.816.473-2.148.645-4.34.645-6.574-.043-1.117.86-2.235 2.32-2.235 1.332 0 2.45 1.075 2.492 2.364 0 2.062-.129 4.168-.472 6.187a1.02 1.02 0 00.859 1.16c.86.172 1.16-.644 1.16-.816.387-2.149.516-4.383.516-6.574-.043-2.364-2.063-4.34-4.512-4.383zm-4.77-.86a1.071 1.071 0 00-1.46.13c-1.075 1.374-1.676 3.093-1.633 4.812 0 1.633-.13 3.265-.43 4.812-.086.602.3 1.118.86 1.203.859.172 1.16-.601 1.203-.816.3-1.719.43-3.48.43-5.2a5.135 5.135 0 011.202-3.48c.344-.472.258-1.117-.171-1.46zm4.727-2.664a6.079 6.079 0 00-1.976.215c-.56.13-.903.688-.774 1.203.129.559.688.903 1.246.774a8.036 8.036 0 011.461-.13c3.223.044 5.93 2.622 5.973 5.759 0 1.633-.086 3.265-.258 4.898-.043.559.344 1.074.902 1.117.73.086 1.117-.472 1.16-.902.13-1.246.258-3.266.258-4.512v-.644c-.086-4.211-3.652-7.735-7.992-7.778zm10.828 5.2a1.036 1.036 0 00-1.246-.774c-.558.13-.902.645-.773 1.203.172.946.215 1.633.172 2.664 0 .559.472 1.032 1.03 1.032.602 0 1.032-.43 1.032-1.032.043-.945.043-1.89-.215-3.093zm-1.762-3.91C18.918 2.64 15.48.835 11.829.792 8.863.75 6.07 1.824 4.008 3.887 2.03 5.907.957 8.57 1 11.363v.946c-.043.558.43 1.03.988 1.03h.043a1 1 0 00.989-.987l.042-1.032C3.02 9.086 3.88 6.98 5.47 5.348c1.676-1.633 3.91-2.535 6.36-2.493a9.307 9.307 0 017.476 3.954c.343.472.988.558 1.46.257.43-.343.56-.988.215-1.46z"
        fill={color}
      />
    </Svg>
  );
}
