import { gql } from '@apollo/client';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { styled, Text, View } from 'dripsy';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  useAddManualLiabilityAndSetReviewedLiabilityMutation,
  useNoDetectedDebtScreenQuery,
  useSetReviewedLiabilityMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { hasOpenedNoDetectedDebtsWizardStateByLoanApplicationId } from '../../LoanApplication/screens/YourDebtsV2';
import {
  SingleModalNavigationScreenProp,
  SingleModalStackRoute,
} from '../../navigation/SingleModalNavigator';
import { Screen } from '../../navigation/types/screens';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { RadioGroup, RadioGroupItem } from '../../ui/v2/RadioGroup';
import { Select } from '../../ui/v2/Select/Select';
import { parseEnumType } from '../../utils/ensureEnumType';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { makeTestId } from '../../utils/stringHelpers';
import {
  LoanDetailsForm,
  LoanDetailsFormSubmitButton,
  LoanDetailsFormValues,
} from '../components/LoanDetailsForm';
import {
  buildAddLiabilityMutationInput,
  buildErrorMessageFromAddLiabilityResult,
} from '../utils/addManualLiabilityUtils';
import { getLiabilityTypeItems } from '../utils/debtsScreenUtils';
import { mapApplicantsToAccountOwnerOptions } from '../utils/liabilityRemoteDataHelpers';

export type NoDetectedDebtParamsForStoryBook = Partial<{
  _initialHaveAnotherLoan: HaveAnotherLoanYesNoEnum;
  _initiallySelectedLiabilityType: Liability_Type_Enum;
  _forceLoading: boolean;
  _forceDataQueryError: boolean;
  _forceMutationError: string;
}>;

export enum HaveAnotherLoanYesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export const ADD_MANUAL_LIABILITY_AND_SET_REVIEWED_LIABILITY_MUTATION_DOC = gql`
  mutation AddManualLiabilityAndSetReviewedLiability(
    $input: add_current_liability_input!
    $loanApplicationId: uuid!
  ) {
    add_current_liability(data: $input) {
      current_liability_id
      error_type
    }
    set_reviewed_liability(loan_application_id: $loanApplicationId) {
      applicant_id
    }
  }
`;

export const NO_DETECTED_DEBT_SCREEN = gql`
  query NoDetectedDebtScreen($loanApplicationId: uuid!) {
    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      latest_full_name
      hecs_loan_balance
    }

    loanApplication: loan_application_by_pk(id: $loanApplicationId) {
      id
      type
    }

    loanApplicationSecurities: loan_application_security(
      where: { loan_application_id: { _eq: $loanApplicationId } }
      limit: 1
    ) {
      id
      property {
        id
        address {
          id
          shortAddressFormat: short_address_format
        }
      }
    }

    institutions: institution(
      where: {
        # Show only common institutions
        provided_by_user_id: { _is_null: true }
        provided_by_staff: { _is_null: true }
        provided_by_api: { _is_null: true }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }

    states: state {
      code
      name
    }

    mergedLiabilities: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        dynamite_flagged_incorrect: { _is_null: true }
      }
    ) {
      id
      dynamite_applicant_ids
      dynamite_liability_type
    }
  }
`;

export function NoDetectedDebt() {
  const navigation =
    useNavigation<
      SingleModalNavigationScreenProp<Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL>
    >();

  const route =
    useRoute<SingleModalStackRoute<Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL>>();

  const {
    loanApplicationId,
    hideSubheading,
    _initialHaveAnotherLoan,
    _initiallySelectedLiabilityType,
    _forceLoading,
    _forceDataQueryError,
    _forceMutationError,
  } = route.params || {};

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const onCloseModal = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Debts,
      }),
    );
  };

  const { flags } = useContext(FeatureFlagsContext);
  const enableAvailableFundsCapture = flags.ENABLE_AVAILABLE_FUNDS_CAPTURE;

  const [selectedLiabilityType, setSelectedLiabilityType] =
    useState<Liability_Type_Enum | null>(
      _initiallySelectedLiabilityType ?? null,
    );

  const [haveAnotherLoan, setHaveAnotherLoan] =
    useState<HaveAnotherLoanYesNoEnum | null>(_initialHaveAnotherLoan ?? null);

  const setHasOpenedWizard = useSetRecoilState(
    hasOpenedNoDetectedDebtsWizardStateByLoanApplicationId(loanApplicationId),
  );

  useFocusEffect(
    useCallback(() => {
      const id = setTimeout(() => {
        // Wait until the modal is fully opened before setting the open state to true
        setHasOpenedWizard(true);
        // The timeout value is obtained by observing how long the spring animation would take.
        // To calculate the actual value, we need to calculate the duration from
        // a spring based animation, which is non-trivial.
        // Using InteractionManager to wait for the animation to finish does not work on web.
      }, 200);
      return () => clearTimeout(id);
    }, [setHasOpenedWizard]),
  );

  const propertySuggestionLoader = usePropertySuggestionLoader();

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useNoDetectedDebtScreenQuery({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [mutationErrorForDisplay, setMutationErrorForDisplay] = useState<
    string | null
  >(_forceMutationError ?? null);

  const [commit, { loading: isSubmitting }] =
    useAddManualLiabilityAndSetReviewedLiabilityMutation({
      onError: () =>
        setMutationErrorForDisplay(
          t('Content.AddManualLiabilityV2.MutationError'),
        ),
    });
  const [setReviewedLiability, { loading: setReviewedLiabilityLoading }] =
    useSetReviewedLiabilityMutation({
      onError: () =>
        setMutationErrorForDisplay(
          t('Content.NoDetectedDebt.FailSetReviewedLiability'),
        ),
    });

  const accountOwnerOptions = mapApplicantsToAccountOwnerOptions({
    applicants: data?.applicants,
    mergedLiabilities: data?.mergedLiabilities,
    selectedLiabilityType,
  });

  const hideHecsLiabilityType = accountOwnerOptions.every(
    (option) => option.hasHecsLoan === true,
  );

  const manualAddressStateOptions = useMemo(
    () =>
      data?.states?.map(({ code }) => ({
        label: code,
        value: code,
      })) ?? [],
    [data?.states],
  );

  const isLoading = queryLoading || _forceLoading;
  const isQueryError = queryError != null || _forceDataQueryError;

  const isInvalidState =
    loanApplicationId == null || selectedLiabilityType == null;

  const setHasReviewedLiability = async () => {
    if (loanApplicationId == null) {
      return;
    }
    await safelyCallMutation(setReviewedLiability, {
      variables: {
        loanApplicationId,
      },
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });
  };

  const onSubmit = async (formValues: LoanDetailsFormValues) => {
    setMutationErrorForDisplay(null);
    if (isInvalidState) {
      return;
    }

    const [res] = await safelyCallMutation(commit, {
      variables: {
        input: buildAddLiabilityMutationInput({
          formValues,
          loanApplicationId,
          selectedLiabilityType,
          enableAvailableFundsCapture: !!enableAvailableFundsCapture,
        }),
        loanApplicationId,
      },
      refetchQueries: [
        refetchYourDebtsQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
        },
      },
    });

    const displayErr = buildErrorMessageFromAddLiabilityResult(res);
    if (displayErr) {
      setMutationErrorForDisplay(displayErr);
      return;
    }

    onCloseModal();
  };

  if (isLoading) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <ScreenLoadingContainer flex={1} centered loading />
      </ModalScreenContainer>
    );
  }

  if (isQueryError) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <EmptyState
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }

  const showAddLoanForm =
    selectedLiabilityType != null &&
    haveAnotherLoan === HaveAnotherLoanYesNoEnum.Yes;

  const showDeclareAssociatedLoanMessage =
    data?.loanApplication?.type !== Loan_Application_Type_Enum.Purchase;

  return (
    <ModalScreenContainer
      headerText={t('Content.NoDetectedDebt.DebtsAndLiabilities')}
      scrollable
      onClose={onCloseModal}
      hideBackButton
    >
      <ErrorRow
        message={mutationErrorForDisplay}
        mb="l"
        testID={TestID.NoDetectedDebts.MutationErrorRow}
      />
      <Text
        sx={{
          mb: '$24',
        }}
      >
        {hideSubheading
          ? null
          : t('Content.NoDetectedDebt.NoDetectedDebtFromCreditCheck')}
      </Text>
      <Text variant="sHeader">
        {t('Content.NoDetectedDebt.DoYouHaveAnyLoans')}
      </Text>
      <RowView sx={{ mt: '$16', mb: '$24' }}>
        <RadioGroup
          value={haveAnotherLoan}
          onValueSelected={(v) => {
            const parsedValue = parseEnumType(HaveAnotherLoanYesNoEnum, v);
            if (parsedValue) {
              setHaveAnotherLoan(parsedValue);
            }
          }}
        >
          <RadioGroupItem
            value={HaveAnotherLoanYesNoEnum.Yes}
            label={t('Content.Common.ButtonLabel.Yes')}
            testID={makeTestId([
              TestID.NoDetectedDebts.HaveAnyLoans,
              HaveAnotherLoanYesNoEnum.Yes,
            ])}
            containerStyle={{
              mr: '$16',
              flex: 1,
            }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Debts,
              Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL,
              FieldInteractionKey.DoYouHaveAnyLoans,
            )}
          />
          <RadioGroupItem
            value={HaveAnotherLoanYesNoEnum.No}
            label={t('Content.Common.ButtonLabel.No')}
            testID={makeTestId([
              TestID.NoDetectedDebts.HaveAnyLoans,
              HaveAnotherLoanYesNoEnum.No,
            ])}
            containerStyle={{
              flex: 1,
            }}
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.Debts,
              Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL,
              FieldInteractionKey.DoYouHaveAnyLoans,
            )}
          />
        </RadioGroup>
      </RowView>
      {haveAnotherLoan === HaveAnotherLoanYesNoEnum.Yes ? (
        <>
          <Text variant="sHeader">
            {t('Content.AddManualLiabilityV2.WhatTypeOfLoanAccountDoYouHave')}
          </Text>
          <Select
            label={t('Content.AddManualLiabilityV2.LoanType')}
            testID={TestID.AddManualLiabilityV2.LoanType}
            items={getLiabilityTypeItems(hideHecsLiabilityType)}
            onValueChange={(value) =>
              setSelectedLiabilityType(value as Liability_Type_Enum | null)
            }
            selectedValue={selectedLiabilityType}
            sx={{
              mb: showDeclareAssociatedLoanMessage ? '$8' : '$24',
              mt: '$16',
            }}
          />
          {showDeclareAssociatedLoanMessage ? (
            <Text variant="caption" sx={{ mb: '$24' }}>
              {t(
                'Content.NoDetectedDebt.DontForgetToIncludeYourCurrentLoanForAddress',
                {
                  address:
                    data?.loanApplicationSecurities?.[0]?.property.address
                      .shortAddressFormat ?? '',
                },
              )}
            </Text>
          ) : null}
        </>
      ) : null}
      {showAddLoanForm ? (
        <LoanDetailsForm
          // Make liability type a React key to force remount
          // to clear the filled values when changing type.
          screen={Screen.DEBTS_NO_DETECTED_LIABILITY_MODAL}
          key={selectedLiabilityType}
          accountOwnerOptions={accountOwnerOptions}
          manualAddressStateOptions={manualAddressStateOptions}
          manualAddressStreetTypeOptions={streetTypeOptions}
          liabilityType={selectedLiabilityType}
          onSubmit={onSubmit}
          liabilityManuallyAdded
          isSubmitting={isSubmitting}
          institutionsData={data?.institutions}
          {...propertySuggestionLoader}
          allowEditAvailableBalanceField={!!enableAvailableFundsCapture}
        />
      ) : (
        <LoanDetailsFormSubmitButton
          disabled={
            setReviewedLiabilityLoading ||
            haveAnotherLoan !== HaveAnotherLoanYesNoEnum.No
          }
          onPress={async () => {
            await setHasReviewedLiability();
            onCloseModal();
          }}
          showSpinner={setReviewedLiabilityLoading}
        />
      )}
    </ModalScreenContainer>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
