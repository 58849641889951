import { Defs, LinearGradient, Stop } from 'react-native-svg';

type Props = {
  gradient: string[];
  id: string;
  direction?: 'horizontal' | 'vertical';
  reverse?: boolean;
};

export const SvgGradientDefs = ({
  direction = 'horizontal',
  id,
  gradient,
  reverse = false,
}: Props) => {
  const directionProps =
    direction === 'horizontal'
      ? { x1: '0%', y1: '0%', x2: '100%', y2: '0%' }
      : { x1: '0%', y1: '0%', x2: '0%', y2: '100%' };
  const reversedGradient = reverse ? gradient.slice().reverse() : gradient;

  return (
    <Defs>
      <LinearGradient
        id={id}
        {...directionProps}
        gradientTransform={
          direction === 'horizontal' ? undefined : 'scale(-1, 1)'
        }
      >
        {reversedGradient.map((g, i) => (
          <Stop key={g} offset={i / (gradient.length - 1)} stopColor={g} />
        ))}
      </LinearGradient>
    </Defs>
  );
};
