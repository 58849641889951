import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { inviteCoBorrowerFormDataAtom } from '../../Borrower/recoil/coBorrowerFormAtom';
import { useInviteCoBorrower } from '../../Borrower/screens/utils/useInviteCoBorrower';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props =
  ActionSheetScreenProps<ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION>;

export function CoBorrowerInfoConfirmationActionSheet({
  navigation,
  route,
}: Props) {
  const { loanApplicationId } = route.params;

  const coBorrowerFormValues = useRecoilValue(inviteCoBorrowerFormDataAtom);

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateBackToBorrowerScreen = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Borrowers,
    });
  };
  const goBack = () => navigation.goBack();

  const { inviteCoBorrower, loading } = useInviteCoBorrower({
    loanApplicationId: loanApplicationId ?? '',
    navigateBackToBorrowerScreen,
    navigateBackToInviteCoBorrowerScreen: goBack,
  });

  const onConfirmPress = () => {
    if (!coBorrowerFormValues || !loanApplicationId) {
      goBack();
      return;
    }
    inviteCoBorrower({
      values: coBorrowerFormValues,
      shouldNavToInviteCoBorrowerScreenOnError: true,
    });
  };

  const commonActionProp = {
    disabled: loading,
    secondary: true,
    flex: 1,
  };

  return (
    <ActionSheet
      closeModal={goBack}
      showCloseButton={false}
      preventCloseModal={loading}
      isRowButton
      title={t('Content.CoBorrowerInfoConfirmationActionSheet.Title')}
      message={t('Content.CoBorrowerInfoConfirmationActionSheet.Message')}
      actionList={[
        {
          ...commonActionProp,
          showSpinner: loading,
          label: t('Content.Common.ButtonLabel.Continue'),
          onPress: onConfirmPress,
          testID: TestID.CoBorrowerInformationConfirmation.ContinueButton,
        },
        {
          ...commonActionProp,
          label: t('Content.Common.ButtonLabel.Review'),
          onPress: goBack,
          testID: TestID.CoBorrowerInformationConfirmation.ReviewButton,
        },
      ]}
    />
  );
}
