import { useNavigation } from '@react-navigation/native';
import { Pressable, Text, useDripsyTheme, View } from 'dripsy';
import { useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ActionSheetType } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { Skeleton } from '../../ui/v2/Skeleton';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';

export function HomeLoanTitle({
  loading,
  name,
  propertyDisplayAddress,
  bsb = '',
  accountNumber = '',
}: {
  loading: boolean;
  name?: string;
  propertyDisplayAddress?: string | null;
  bsb?: string;
  accountNumber?: string;
}) {
  const navigation = useNavigation();

  const [hoverAccountDetails, setHoverAccountDetails] = useState(false);

  const { theme } = useDripsyTheme();

  const accountDetailsColor = useMemo(
    () =>
      hoverAccountDetails
        ? theme.colors.$labelsPrimary
        : theme.colors.$secondary,
    [hoverAccountDetails, theme.colors.$labelsPrimary, theme.colors.$secondary],
  );

  const bsbJoinedWithAccountNumber =
    bsb && accountNumber ? formatBsbAndAccountNumber(bsb, accountNumber) : '';

  if (loading) {
    return <Skeleton show height={theme.space.$32} width={260} />;
  }

  const onCopyPress = async () => {
    navigation.navigate(ActionSheetType.COPY_BSB_ACCOUNT_NUMBER, {
      bsb,
      accountNumber,
    });
  };

  return (
    <Box flex={1}>
      <Text variant="header" role="heading">
        {propertyDisplayAddress || name}
      </Text>
      {bsbJoinedWithAccountNumber ? (
        <Box row alignItems="center">
          <Pressable
            role="button"
            onHoverIn={() => setHoverAccountDetails(true)}
            onHoverOut={() => setHoverAccountDetails(false)}
            onPress={onCopyPress}
            aria-label={t(
              'Content.Accessibility.HomeLoan.CopyBSBAndAccountNumber',
            )}
            sx={{
              position: 'relative',
              py: '$4',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              variant="sBody"
              sx={{
                color: accountDetailsColor,
              }}
            >
              {bsbJoinedWithAccountNumber}{' '}
            </Text>
            <StyledIcon
              family="svg"
              name="copy"
              size="s"
              testID={TestID.HomeLoanTitle.AccountDetailsIcon}
              aria-hidden
              style={{ color: accountDetailsColor }}
            />

            <View
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTopWidth: hoverAccountDetails ? 1 : 0,
                borderTopColor: accountDetailsColor,
              }}
            />
          </Pressable>
        </Box>
      ) : null}
    </Box>
  );
}
