import { atom } from 'recoil';

/**
 * This atom is used to check if the screen should navigate
 * directly to Application Summary screens.
 *
 * Should only be set to true from ApplicationSummary screens,
 * namely Review Application and Application Details modal.
 */
export enum AppSummaryScreen {
  ReviewSummary,
  ConditionalApprovalAppDetails,
}

export const PreviousAppSummaryScreenState = atom<AppSummaryScreen | null>({
  key: 'previousAppSummaryScreenState',
  default: null,
});
