import { memo, ReactElement } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { TestID } from '../../../testID/constants';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { useTheme } from '../../ui/theme';

export type Props = BoxProps & {
  footerCaption?: string;
  footerCaptionError?: boolean;
  footerCaptionLink?: string;
  primaryButtonLabel?: string;
  primaryButtonTestID?: string;
  isPrimaryButtonLoading?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonTestID?: string;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  onLinkPress?: () => void;
  onPrimaryButtonPress?: () => void;
  onSecondaryButtonPress?: () => void;
  /**
   * When provided, will replace the default primary button.
   */
  primaryButtonRow?: ReactElement;
  style?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
};

export const LoanApplicationWizardFooter = memo<Props>(
  ({
    footerCaption,
    footerCaptionError,
    footerCaptionLink,
    primaryButtonLabel,
    isPrimaryButtonLoading,
    disablePrimaryButton,
    secondaryButtonLabel,
    disableSecondaryButton,
    onLinkPress,
    onPrimaryButtonPress,
    onSecondaryButtonPress,
    primaryButtonRow: primaryButtonRowProps,
    children,
    primaryButtonTestID,
    secondaryButtonTestID,
    style,
    wrapperStyle,
    ...otherProps
  }) => {
    const { bottom: insetBottom } = useSafeAreaInsets();
    const theme = useTheme();

    let primaryButtonRow = primaryButtonRowProps;
    if (!primaryButtonRow && onPrimaryButtonPress) {
      primaryButtonRow = (
        <Button
          py="m"
          mt="m"
          onPress={onPrimaryButtonPress}
          width="100%"
          label={primaryButtonLabel}
          disabled={disablePrimaryButton || isPrimaryButtonLoading}
          showSpinner={isPrimaryButtonLoading}
          testID={primaryButtonTestID}
        />
      );
    }

    return (
      <Box
        px="l"
        style={[
          {
            paddingBottom:
              insetBottom === 0
                ? theme.spacing.xl
                : insetBottom + theme.spacing.m,
          },
          style,
        ]}
        testID={TestID.LoanApplicationWizardFooter.Container}
        {...otherProps}
      >
        {children}
        {footerCaption ? (
          <StyledText
            variant="caption"
            color={footerCaptionError ? 'error' : 'secondaryContent'}
            textAlign="center"
            mx="m"
          >
            {footerCaption}
            {footerCaptionLink ? (
              <StyledText variant="caption" color="link" onPress={onLinkPress}>
                {footerCaptionLink}
              </StyledText>
            ) : null}
          </StyledText>
        ) : null}
        {primaryButtonRow}
        {secondaryButtonLabel ? (
          <>
            <Separator spacer />
            <Button
              onPress={onSecondaryButtonPress}
              disabled={disableSecondaryButton}
              width="100%"
              tertiary
              label={secondaryButtonLabel}
              testID={secondaryButtonTestID}
            />
          </>
        ) : null}
      </Box>
    );
  },
);
