import { TestID } from '../../../testID/constants';
import {
  SummarySectionItemOnPressTypes,
  SummarySectionV2,
} from '../../ApplicationSummary/components/SummarySectionV2';
import { MAX_BORROWERS } from '../../LoanApplication/components/BorrowerList';
import { ApplicationDetailsSectionDataProps } from '../remoteData';

export type ApplicationDetailsSummarySectionHeaderOnPressTypes = {
  borrowers: () => void;
  income: () => void;
  expense: () => void;
  liabilities: () => void;
};

export type ApplicationDetailsSummarySectionItemOnPressTypes = Omit<
  SummarySectionItemOnPressTypes,
  'property' | 'loan'
>;

type Props = {
  sectionData: ApplicationDetailsSectionDataProps;
  onSectionHeaderPress?: ApplicationDetailsSummarySectionHeaderOnPressTypes;
  onSectionItemPress?: ApplicationDetailsSummarySectionItemOnPressTypes;
  isEditMode: boolean;
};

export function ApplicationDetailsSections({
  sectionData,
  onSectionItemPress,
  onSectionHeaderPress,
  isEditMode,
}: Props) {
  return (
    <>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Borrowers')}
        isOnEditMode={isEditMode}
        onAddButtonPress={
          sectionData.borrowers.length < MAX_BORROWERS
            ? onSectionHeaderPress?.borrowers
            : undefined
        }
        addButtonTestID={TestID.SummarySection.BorrowerAdd}
      >
        <SummarySectionV2.Borrowers
          data={sectionData.borrowers}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.borrowers}
          testIDPrefix={TestID.SummarySection.BorrowerItemPrefix}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Income')}
        isOnEditMode={isEditMode}
        onAddButtonPress={onSectionHeaderPress?.income}
        addButtonTestID={TestID.SummarySection.IncomeAdd}
      >
        <SummarySectionV2.Income
          data={sectionData.financials.incomes}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.income}
          testIDPrefix={TestID.SummarySection.IncomeItemPrefix}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Expenses')}
        isOnEditMode={isEditMode}
        onAddButtonPress={
          sectionData.isAllApplicantsHasHousehold
            ? undefined
            : onSectionHeaderPress?.expense
        }
        addButtonTestID={TestID.SummarySection.ExpenseAdd}
      >
        <SummarySectionV2.Expense
          data={sectionData.financials.households}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.expense}
          testIDPrefix={TestID.SummarySection.ExpenseItemPrefix}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.DebtsAndLiabilities')}
        isOnEditMode={isEditMode}
        onAddButtonPress={onSectionHeaderPress?.liabilities}
        addButtonTestID={TestID.SummarySection.LiabilityAdd}
      >
        <SummarySectionV2.DebtsAndLiabilities
          data={sectionData.debtsAndLiabilities}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.liabilities}
          testIDPrefix={TestID.SummarySection.LiabilityItemPrefix}
        />
      </SummarySectionV2>
    </>
  );
}
