import { useCallback, useState } from 'react';
import * as React from 'react';
import BaseTooltip, {
  TooltipProps as BaseTooltipProps,
} from 'react-native-walkthrough-tooltip';

import { FocusOn } from '../../components/FocusOn';
import { isWeb } from '../../utils/platformUtils';
import { PressableIcon } from '../molecules/PressableIcon';
import { useTheme } from '../theme';
import { StyledText } from './StyledText';

type TooltipProps = Pick<BaseTooltipProps, 'placement'> & {
  content: string | React.ReactElement;
  iconSize?: React.ComponentProps<typeof PressableIcon>['iconSize'];
  iconColor?: React.ComponentProps<typeof PressableIcon>['iconColor'];
  iconOpacity?: React.ComponentProps<typeof PressableIcon>['iconOpacity'];
  testID?: string;
};
export function Tooltip({
  content: contentProp,
  placement,
  iconSize = '2s',
  iconColor = 'infoIconDefault',
  iconOpacity = 0.33,
  testID,
}: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();

  const textContent = (
    <StyledText
      maxWidth={300}
      minWidth={150}
      minHeight={20}
      variant="caption"
      color="buttonPrimaryContent"
      textAlign="left"
      flex={1}
      px="s"
    >
      {contentProp}
    </StyledText>
  );

  const onClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const content =
    // This workaround is only for web
    // as native has no focus trap.
    isWeb ? (
      <FocusOn enabled onClickOutside={onClose}>
        {textContent}
      </FocusOn>
    ) : (
      textContent
    );

  return (
    <BaseTooltip
      isVisible={showTooltip}
      content={content}
      onClose={onClose}
      placement={placement || 'bottom'}
      showChildInTooltip={false}
      contentStyle={{
        backgroundColor: theme.colors.greyBg,
        borderRadius: theme.sizes.xs,
      }}
      useInteractionManager
      disableShadow
      backgroundColor="transparent"
    >
      <PressableIcon
        iconName="info"
        iconFamily="svg"
        iconSize={iconSize}
        iconColor={iconColor}
        iconOpacity={iconOpacity}
        onPress={() => {
          setShowTooltip(true);
        }}
        testID={testID}
      />
    </BaseTooltip>
  );
}
