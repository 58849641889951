import { styled, Text, View } from 'dripsy';
import { Formik } from 'formik';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import { FormPropertyInputV2 } from '../../../components/form/FormikInputs';
import { Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { FormikFormError } from '../../../ui/v2/FormError';
import {
  ManualPropertyFieldsProps,
  ManualPropertyInputFields,
} from '../../../ui/v2/ManualPropertyInputFields';
import { SuggestionDataLoader } from '../../../ui/v2/PropertyInput';
import { buildBorrowerDetailsEditErrorMessage } from '../utils/buildFormErrorMessage';
import {
  BorrowerDetailsEditField,
  BorrowerDetailsEditFormValues,
  BorrowerDetailsEditIntialValues,
  BorrowerDetailsEditLabel,
  getBorrowerDetailsEditValidationSchema,
  manualMailingAddressInputFieldNames,
  manualMailingAddressPrefix,
} from '../utils/forms';
import { BorrowerDataView, BorrowerDataViewProps } from './BorrowerDataView';

export type Props = Omit<BorrowerDataViewProps, 'mailingAddress'> &
  Pick<ManualPropertyFieldsProps, 'stateOptions' | 'streetTypeOptions'> &
  SuggestionDataLoader & { screen: Screen } & Partial<{
    initialFormValues: BorrowerDetailsEditFormValues;
    isSubmitting: boolean;
    onSubmit: (values: BorrowerDetailsEditFormValues) => void;
  }>;

const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});

export function BorrowerDetailsEditForm(props: Props) {
  const {
    screen,
    initialFormValues = BorrowerDetailsEditIntialValues,
    loadSuggestionData,
    isLoadingSuggestionData,
    propertySuggestionData,
    stateOptions,
    streetTypeOptions,
    isSubmitting,
    email,
    fullName,
    mobileNumber,
    residentialAddress,
    onSubmit = () => {},
  } = props;

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validationSchema={getBorrowerDetailsEditValidationSchema()}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          <SHeaderText>
            {t('Content.Borrower.YourInformation.Details.Title')}
          </SHeaderText>
          <BorrowerDataView
            email={email}
            fullName={fullName}
            mobileNumber={mobileNumber}
            residentialAddress={residentialAddress}
          />
          <SHeaderText sx={{ mt: '$32' }}>
            {t('Content.Borrower.YourInformation.Details.MailingAddress')}
          </SHeaderText>
          {formProps.values?.[
            manualMailingAddressInputFieldNames.isManualInput
          ] ? (
            <>
              <ManualPropertyInputFields
                screen={screen}
                sectionInteractionKey={SectionInteractionKey.Borrowers}
                fieldNamePrefix={manualMailingAddressPrefix}
                containerSx={{ mt: '$16' }}
                stateOptions={stateOptions}
                streetTypeOptions={streetTypeOptions}
              />
              <Text
                variants={['caption', 'link']}
                sx={{ my: '$8' }}
                onPress={() =>
                  formProps.setFieldValue(
                    manualMailingAddressInputFieldNames.isManualInput,
                    false,
                    false,
                  )
                }
              >
                {t('Content.PropertyForm.FormV2.BackToSelectInput')}
              </Text>
            </>
          ) : (
            <>
              <FormPropertyInputV2
                name={BorrowerDetailsEditField.MailingAddress}
                label={
                  BorrowerDetailsEditLabel[
                    BorrowerDetailsEditField.MailingAddress
                  ]
                }
                inputTestID={TestID.BorrowerDetailsEditForm.MailingAddressInput}
                propertySuggestionData={propertySuggestionData}
                loadSuggestionData={loadSuggestionData}
                isLoadingSuggestionData={isLoadingSuggestionData}
                sx={{ mt: '$16' }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Borrowers,
                  screen,
                  FieldInteractionKey.MailingAddress,
                )}
              />
              <FormikFormError
                name={BorrowerDetailsEditField.MailingAddress}
                errorMessageParser={buildBorrowerDetailsEditErrorMessage}
                sx={{ mt: '$8' }}
              />
              <Text variant="caption" sx={{ my: '$8' }}>
                {t('Content.Address.UseManualInput')}
                <Text
                  variants={['caption', 'link']}
                  onPress={() => {
                    formProps.setFieldValue(
                      manualMailingAddressInputFieldNames.isManualInput,
                      true,
                      false,
                    );
                  }}
                >
                  {t('Content.Address.UseManualInputLink')}
                </Text>
              </Text>
            </>
          )}
          <Button
            onPress={() => formProps.handleSubmit()}
            label={t('Content.Common.ButtonLabel.Done')}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            mt="xl"
            alignSelf="stretch"
            testID={TestID.BorrowerDetailsEditForm.DoneButton}
          />
        </View>
      )}
    </Formik>
  );
}
