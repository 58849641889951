import { Text } from 'dripsy';

import { BoxProps } from '../../../ui/atoms/Box';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { safelyFormatDate } from '../../../utils/dateHelpers';
import { TooltipContainer, TooltipContainerProps } from '../TooltipContainer';

type Props = {
  date: string;
  savedInterestAmount: number;
} & BoxProps &
  TooltipContainerProps;

export const SavedOnInterestTooltip = ({
  date,
  savedInterestAmount,
  displayBoarder,
  ...props
}: Props) => (
  <TooltipContainer displayBoarder={displayBoarder} {...props}>
    <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
      {displayBoarder
        ? t('Content.HomeLoan.Insights.TotalInterestSavings.SavedOnInterest')
        : t(
            'Content.HomeLoan.Insights.TotalInterestSavings.TotalSavedOnInterest',
          )}
    </Text>
    <Text variant="lHeader">
      {formatCurrency(savedInterestAmount, {
        noFraction: false,
        withFractionOnRoundedAmount: true,
      })}
    </Text>
    <Text variant="xsHeader">{safelyFormatDate(date, 'MMMM yyyy') || ' '}</Text>
  </TooltipContainer>
);
