import { gql } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { useUser } from '../../Auth/hooks';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { CollapsibleListRow } from '../../components/CollapsibleListRow';
import { ErrorFallback } from '../../components/ErrorFallback';
import {
  GrantEventType,
  useGetConsentHistoryQuery,
} from '../../generated/graphql';
import { HomeHeader } from '../../Home/components/HomeHeader';
import { SettingsStackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { UpliftScreenContainer } from '../../ui/atoms/ScreenContainer';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { isLast } from '../../utils/arrayHelpers';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { SettingStackNavigationProp } from '../navigation/types';
import { DataSharingDateFormat } from './ManageDataSharing';

export const GetConsentHistory = gql`
  query GetConsentHistory($grantId: String!, $principalId: String!) {
    listGrantLogs(grantId: $grantId, principalId: $principalId) {
      content
      created
      grantEventUType
      id
    }
  }
`;

export const grantEventMap: Record<GrantEventType, string> = {
  [GrantEventType.GrantCreationEvent]: 'Consent Creation',
  [GrantEventType.GrantAmendmentEvent]: 'Consent Amendment',
  [GrantEventType.GrantAccessTokenIssuanceEvent]: '',
  [GrantEventType.GrantCodeClaimedEvent]: '',
  [GrantEventType.GrantHeldViaRegisterUpdateEvent]: '',
  [GrantEventType.GrantIdTokenIssuanceEvent]: '',
  [GrantEventType.GrantReactivatedViaRegisterUpdateEvent]: '',
  [GrantEventType.GrantRefreshTokenIssuanceEvent]: '',
  [GrantEventType.GrantRevokedByAdminEvent]: '',
  [GrantEventType.GrantRevokedByDashboardEvent]: '',
  [GrantEventType.GrantRevokedByRecipientEvent]: '',
  [GrantEventType.GrantRevokedViaClosedPrincipalPollEvent]: '',
  [GrantEventType.GrantRevokedViaRegisterUpdateEvent]: '',
};

export function ConsentHistoryDetails() {
  const navigation =
    useNavigation<
      SettingStackNavigationProp<Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY>
    >();

  const route =
    useRoute<
      RouteProp<
        SettingsStackParams,
        Screen.SETTINGS_DATA_SHARING_CONSENT_HISTORY
      >
    >();

  const { user } = useUser();

  const principalId = user?.cba_customer_id;
  const { grantId } = route.params;

  const { data, error } = useGetConsentHistoryQuery({
    variables: {
      grantId,
      principalId: principalId || '',
    },
    context: {
      sentryContext: {
        description: 'Get Consent History',
      },
    },
  });

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const [openCollapsibleRowIndex, setOpenCollapsibleRowIndex] = useState<
    number | null
  >(null);

  if (error) {
    return (
      <ErrorFallback
        title={t('Content.Common.Error.SomethingWentWrong.Title')}
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
      />
    );
  }

  return (
    <UpliftScreenContainer onPressBack={onPressBack}>
      <HomeHeader
        title={t('Content.DataSharingDetails.ConsentHistory')}
        subTitle={t('Content.DataSharingDetails.ConsentHistorySubTitle')}
      />
      <ListRowGroup mx={0} headerText="" overflow="visible">
        {data?.listGrantLogs?.map((log, i) => (
          <CollapsibleListRow
            key={log?.id}
            label={
              safelyFormatDate(log?.created, DataSharingDateFormat) || '--'
            }
            caption={
              grantEventMap[(log?.grantEventUType as GrantEventType) || ''] ||
              ''
            }
            isCollapsed={i !== openCollapsibleRowIndex}
            onPress={() => {
              setOpenCollapsibleRowIndex((openIndex) =>
                openIndex === i ? null : i,
              );
            }}
            collapsedContent={log?.content}
            px="m"
            last={isLast(data.listGrantLogs || [], i)}
          />
        ))}
      </ListRowGroup>
    </UpliftScreenContainer>
  );
}

export const WithAuthenticationConsentHistoryDetails =
  withAuthenticationRequired(ConsentHistoryDetails);
