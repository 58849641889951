import {
  Loan_Account_Settings,
  Maybe,
  Redraw_Lock_Status_Enum,
} from '../../generated/graphql';

const DefaultLockStatus = Redraw_Lock_Status_Enum.Locked;

export const isWithdrawDisabled = (
  loanAccount?: Maybe<{
    settings?: Maybe<{
      redraw_lock_status?: Partial<Loan_Account_Settings>['redraw_lock_status'];
    }>;
  }>,
) =>
  (loanAccount?.settings?.redraw_lock_status ?? DefaultLockStatus) ===
  Redraw_Lock_Status_Enum.Locked;
