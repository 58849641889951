import { Box } from '../../ui/atoms/Box';
import { Button, Props as ButtonProps } from '../../ui/atoms/Button';
import {
  Props as StyledTextProps,
  StyledText,
} from '../../ui/atoms/StyledText';

type Props = {
  title: string;
  caption: string;
  link?: string;
  onLinkPress?: () => void;
  buttonLabel?: string;
  onButtonPress?: () => void;
  height?: number;
  buttonTestId?: string;
} & Pick<ButtonProps, 'onPressIn' | 'onPressOut'>;

function RowButton({
  buttonLabel,
  onButtonPress,
  buttonTestId,
  onPressIn,
  onPressOut,
}: Required<Pick<Props, 'buttonLabel' | 'onButtonPress'>> &
  Pick<Props, 'buttonTestId' | 'onPressIn' | 'onPressOut'>) {
  return (
    <Button
      ml="l"
      label={buttonLabel}
      onPress={onButtonPress}
      variant="pill"
      alignSelf="center"
      testID={buttonTestId}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
    />
  );
}

function Title(props: StyledTextProps) {
  return <StyledText variant="contentEmphasis" {...props} />;
}

const DEFAULT_ROW_HEIGHT = 80;

/**
 * @deprecated Settlement never got to prod, so we can remove this Settlement directory.
 * But this component is used in another components.
 * TODO: Factor this out of Settlement and remove Settlement dir.
 */
export function SettlementProcessRow({
  title,
  caption,
  link,
  onLinkPress,
  buttonLabel,
  onButtonPress,
  height = DEFAULT_ROW_HEIGHT,
  buttonTestId,
  onPressIn,
  onPressOut,
}: Props) {
  const captionLink =
    link != null && onLinkPress ? (
      <StyledText
        role="link"
        onPress={onLinkPress}
        variant="caption"
        color="link"
      >
        {link}
      </StyledText>
    ) : null;
  const rowButton =
    buttonLabel != null && onButtonPress ? (
      <RowButton
        buttonLabel={buttonLabel}
        onButtonPress={onButtonPress}
        buttonTestId={buttonTestId}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
      />
    ) : null;
  return (
    <Box
      flex={1}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pl="m"
      maxHeight={height}
    >
      <Box flex={1}>
        <Title>{title}</Title>
        <StyledText variant="caption" flexWrap="wrap">
          {caption}
          {captionLink}
        </StyledText>
      </Box>
      {rowButton}
    </Box>
  );
}

SettlementProcessRow.height = DEFAULT_ROW_HEIGHT;
