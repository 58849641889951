import { gql } from '@apollo/client';
import { useDripsyTheme } from 'dripsy';
import { useCallback, useMemo } from 'react';

import { ApprovalControls } from '../../ApprovalRequest/components/ApprovalControls';
import { ApprovalStatusListGroup } from '../../ApprovalRequest/components/ApprovalStatusListGroup';
import { ErrorFallback } from '../../components/ErrorFallback';
import { DEFAULT_DATE_DISPLAY_FORMAT } from '../../constants/dateFormats';
import {
  Approval_Status_Enum,
  ApprovalRequestFragmentDoc,
  AutopayChangeRequestFragment,
  AutopayChangeRequestFragmentDoc,
  Change_Request_Status_Enum,
  useCancelRequestMutation,
  useGetAutopayChangeRequestSubscription,
} from '../../generated/graphql';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { StyledText } from '../../ui/atoms/StyledText';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { AutopayDetailListGroup } from '../components/AutopayDetailListGroup';
import { AutopaySettingsScreenProps } from '../navigation/types';
import { formatUnloanAccountSubtitle } from '../utils/formatUnloanAccountSubtitle';
import { isAutopayChangeRequestExpired } from '../utils/isAutopayChangeRequestExpired';

type Props = AutopaySettingsScreenProps<Screen.AUTOPAY_CHANGE_REQUEST>;

export const AUTOPAY_CHANGE_REQUEST_QUERY = gql`
  subscription GetAutopayChangeRequest($autopay_change_request_id: uuid!) {
    autopay_change_request_by_pk(id: $autopay_change_request_id) {
      ...AutopayChangeRequest
    }
  }

  ${AutopayChangeRequestFragmentDoc}
`;

export const AUTOPAY_CHANGE_REQUEST_FRAGMENT = gql`
  fragment AutopayChangeRequest on autopay_change_request {
    id
    cba_account_id
    status
    repayment_amount
    first_repayment_date
    frequency
    expires_at
    external_account {
      id
      masked_account_number
      account_name
      account_bsb
      account_number
    }
    approval_request {
      ...ApprovalRequest
    }
  }

  ${ApprovalRequestFragmentDoc}
`;

export const getAutopayChangeRequestStatusLabel = (
  autopayChangeRequest?: AutopayChangeRequestFragment,
) => {
  if (!autopayChangeRequest) {
    return '';
  }

  switch (autopayChangeRequest?.status) {
    case Change_Request_Status_Enum.Pending:
      return t('Content.Approvals.Processing');
    case Change_Request_Status_Enum.Applied:
      return t('Content.Approvals.Processed');
    case Change_Request_Status_Enum.Failed:
      return t('Content.Approvals.Failed');
    default:
      throw new Error(`Unknown autopay change request status`);
  }
};

type AutopayChangeRequestProps = {
  cbaAccountId: string;
  autopayChangeRequestId: string;
  onDismiss: () => void;
  onApproveRequestCallback: () => void;
  onDeclineRequestCallback: () => void;
  onCancelRequestCallback: () => void;
};

export function AutopayChangeRequest({
  cbaAccountId,
  autopayChangeRequestId,
  onDismiss,
  onApproveRequestCallback,
  onDeclineRequestCallback,
  onCancelRequestCallback,
}: AutopayChangeRequestProps) {
  const context = { sentryContext: { cbaAccountId, autopayChangeRequestId } };

  const {
    data: autopayChangeRequestData,
    loading: autopayChangeRequestLoading,
    error: autopayChangeRequestError,
  } = useGetAutopayChangeRequestSubscription({
    variables: {
      autopay_change_request_id: autopayChangeRequestId,
    },
    context,
  });

  const {
    data: loanAccountNameData,
    loading: loanAccountNameLoading,
    error: loanAccountNameError,
  } = useGetLoanAccountName(cbaAccountId);

  const isError = !!autopayChangeRequestError || !!loanAccountNameError;

  const loading = autopayChangeRequestLoading || loanAccountNameLoading;

  const { accountName = '', bsbJoinedWithAccountNumber = '' } =
    loanAccountNameData ?? {};

  const autopayChangeRequest =
    autopayChangeRequestData?.autopay_change_request_by_pk;

  const approvalRequest = autopayChangeRequest?.approval_request;

  const isApproved = approvalRequest?.status === Approval_Status_Enum.Approved;

  const isExpired = autopayChangeRequest
    ? isAutopayChangeRequestExpired(autopayChangeRequest)
    : false;

  const statusListGroup = useMemo(() => {
    if (autopayChangeRequest && isApproved) {
      return (
        <DataRowGroup>
          <DataRow
            loading={loading}
            label={t('Content.Approvals.Status')}
            caption={getAutopayChangeRequestStatusLabel(autopayChangeRequest)}
          />
        </DataRowGroup>
      );
    }

    return (
      <ApprovalStatusListGroup
        loading={loading}
        approvalRequest={autopayChangeRequest?.approval_request}
        expiryWarningText={t('Content.AutopaySettings.ExpiryWarning')}
      />
    );
  }, [loading, isApproved, autopayChangeRequest]);

  const [cancelRequest, { loading: dismissInFlight }] =
    useCancelRequestMutation();

  const dismissExpiredRequest = useCallback(async () => {
    if (!approvalRequest) {
      return;
    }

    const approvalRequestId = approvalRequest?.id;

    await safelyCallMutation(cancelRequest, {
      variables: {
        approval_request_id: approvalRequest?.id,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          approvalRequestId,
        },
      },
    });

    onDismiss();
  }, [approvalRequest, cancelRequest, cbaAccountId, onDismiss]);

  const { theme } = useDripsyTheme();

  if (autopayChangeRequest && isExpired) {
    return (
      <ErrorFallback
        title={t('Content.AutopaySettings.ExpiredTitle')}
        caption={t('Content.AutopaySettings.ExpiredPleaseRetry', {
          firstRepaymentDate: safelyFormatDate(
            autopayChangeRequest.first_repayment_date,
            DEFAULT_DATE_DISPLAY_FORMAT,
          ),
        })}
        hideUnloanLogo
        bg="transparent"
      >
        <Box flexDirection="column" flexGrow={1}>
          <Box
            mt="l"
            mx="m"
            mb="m"
            flexDirection="row"
            bottom={0}
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Button
              secondary
              label={t('Content.AutopaySettings.Dismiss')}
              color="error"
              flexGrow={1}
              mr="s"
              onPress={dismissExpiredRequest}
              showSpinner={dismissInFlight}
            />
          </Box>
        </Box>
      </ErrorFallback>
    );
  }

  if (isError) {
    return (
      <ErrorFallback
        title=""
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        captionLink={t('Content.Common.Error.SomethingWentWrong.CaptionLink')}
        hideUnloanLogo
        bg="transparent"
        flex={1}
        justifyContent="center"
      />
    );
  }

  return (
    <>
      {loading ? (
        <Skeleton show height={theme.space.$20} width={240} />
      ) : (
        <StyledText variant="body">
          {formatUnloanAccountSubtitle({
            accountName,
            bsbJoinedWithAccountNumber,
          })}
        </StyledText>
      )}

      {statusListGroup}
      <AutopayDetailListGroup
        loading={loading}
        repaymentAmount={autopayChangeRequest?.repayment_amount}
        accountName={accountName}
        bsbJoinedWithAccountNumber={bsbJoinedWithAccountNumber}
        externalAccountName={
          autopayChangeRequest?.external_account?.account_name
        }
        externalAccountBsb={autopayChangeRequest?.external_account?.account_bsb}
        externalAccountNumber={
          autopayChangeRequest?.external_account?.account_number
        }
        firstRepaymentDate={autopayChangeRequest?.first_repayment_date}
        frequency={autopayChangeRequest?.frequency}
      />
      {approvalRequest ? (
        <ApprovalControls
          loading={loading}
          approvalRequest={approvalRequest}
          onApproveRequestCallback={onApproveRequestCallback}
          onDeclineRequestCallback={onDeclineRequestCallback}
          onCancelRequestCallback={onCancelRequestCallback}
        />
      ) : null}
    </>
  );
}

export function AutopayChangeRequestScreen({ navigation, route }: Props) {
  const onDismiss = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  const onApproveRequestCallback = useCallback(() => {
    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.AutopaySettings.AutoPayRequestApproved'),
    });
  }, [navigation]);

  const onDeclineRequestCallback = useCallback(() => {
    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.AutopaySettings.AutoPayRequestDeclined'),
    });
  }, [navigation]);

  const onCancelRequestCallback = useCallback(() => {
    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.AutopaySettings.AutoPayRequestCancelled'),
    });
  }, [navigation]);

  const { cbaAccountId, autopayChangeRequestId } = route.params;

  return (
    <ModalScreenContainer
      headerText={t('Content.AutopaySettings.AutoPayHeader')}
      onClose={onClose}
      scrollable
      hideBackButton
    >
      <AutopayChangeRequest
        cbaAccountId={cbaAccountId}
        autopayChangeRequestId={autopayChangeRequestId}
        onDismiss={onDismiss}
        onApproveRequestCallback={onApproveRequestCallback}
        onDeclineRequestCallback={onDeclineRequestCallback}
        onCancelRequestCallback={onCancelRequestCallback}
      />
    </ModalScreenContainer>
  );
}
