import { useLoanApplicationQuery } from '../../generated/graphql';

export function useLoanApplication(loanApplicationId: string | undefined) {
  const { data, loading, error, refetch } = useLoanApplicationQuery({
    skip: !loanApplicationId,
    variables: { loanApplicationId: loanApplicationId || '' },
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  return {
    loanApplicationId: data?.loan_application_by_pk?.id,
    loanApplicationType: data?.loan_application_by_pk?.type,
    loanApplication: data?.loan_application_by_pk,
    loading,
    error,
    refetch,
  };
}
