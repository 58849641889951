import { ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { useConfirmPayment } from '../../ActionSheet/utils/useConfirmPayment';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { MaxCharacter } from '../../constants/fieldRules';
import { DEFAULT_UNLOAN_ACCOUNT_NAME } from '../../constants/unloan';
import {
  AddExternalAccountFor,
  useAddExternalAccount,
} from '../../ExternalAccount/utils/useAddExternalAccount';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  useGetExternalAccountsQuery,
  useGetLoanAccountPropertyAddressQuery,
} from '../../generated/graphql';
import { TransferDisabledWarningRow } from '../../Home/components/TransferDisabledWarningRow';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { formatCurrency } from '../../utils/currencyHelpers';
import {
  yupExternalAccountBsb,
  yupExternalAccountName,
  yupExternalAccountNumber,
} from '../../utils/yup';
import {
  NPP_DESCRIPTION_MAX_LENGTH,
  WithdrawV2Form,
} from '../components/WithdrawV2Form';
import { ALLOWED_REFERENCE_CHARS_REGEX } from '../constants';
import { useLoanAccountState } from '../graphql/loanAccountQueries';
import { HomeLoanScreenProps } from '../navigation/types';
import { ExternalAccount, WithdrawalFormField, WithdrawFormV2 } from '../types';
import { WithdrawConfirmationV2 } from './WithdrawConfirmationV2';

const createValidationSchema = (
  availableRedrawAmount: number,
  isNPPLongDescriptionEnabled = false,
) =>
  yup.object({
    [WithdrawalFormField.Amount]: yup
      .number()
      .typeError(t('Content.Withdraw.Error.AmountRequired'))
      .moreThan(0, t('Content.Withdraw.Error.AmountMoreThan0'))
      .test(
        'noAvailableAmount',
        t('Content.Withdraw.Error.NoAvailableAmount'),
        () => availableRedrawAmount > 0,
      )
      .required(t('Content.Withdraw.Error.AmountRequired'))
      .test(
        'cannotExceedAvailableAmount',
        t('Content.Withdraw.Error.CannotExceedAvailableAmount', {
          amount: formatCurrency(availableRedrawAmount, {
            noFraction: true,
          }),
        }),
        (value) => (value ?? 0) <= availableRedrawAmount,
      ),
    [WithdrawalFormField.Description]: yup
      .string()
      .required(t('Content.Withdraw.Error.DescriptionRequired'))
      .max(
        isNPPLongDescriptionEnabled
          ? NPP_DESCRIPTION_MAX_LENGTH
          : MaxCharacter.general,
        t('Content.Withdraw.Error.MaxDescriptionLength', {
          maxLength: isNPPLongDescriptionEnabled
            ? NPP_DESCRIPTION_MAX_LENGTH
            : MaxCharacter.general,
        }),
      ),
    [WithdrawalFormField.Reference]: yup
      .string()
      .nullable()
      .max(
        MaxCharacter.withdrawalReference,
        t('Content.Withdraw.Error.MaxReferenceLength', {
          maxLength: MaxCharacter.withdrawalReference,
        }),
      )
      .test(
        'invalidChars',
        t('Content.Withdraw.Error.InvalidReferenceChars'),
        (value) => {
          if (!value) return true;
          return ALLOWED_REFERENCE_CHARS_REGEX.test(value);
        },
      ),
    [WithdrawalFormField.IsManualAccountInput]: yup.boolean(),
    [WithdrawalFormField.ExternalAccountId]: yup
      .string()
      .when(WithdrawalFormField.IsManualAccountInput, {
        is: false,
        then: yup
          .string()
          .uuid()
          .required(t('Content.Withdraw.Error.AccountRequired')),
      }),
    [WithdrawalFormField.AccountName]: yup
      .string()
      .when(WithdrawalFormField.IsManualAccountInput, {
        is: true,
        then: yupExternalAccountName,
      }),
    [WithdrawalFormField.AccountBsb]: yup
      .string()
      .when(WithdrawalFormField.IsManualAccountInput, {
        is: true,
        then: yupExternalAccountBsb,
      }),
    [WithdrawalFormField.AccountNumber]: yup
      .string()
      .when(WithdrawalFormField.IsManualAccountInput, {
        is: true,
        then: yupExternalAccountNumber,
      }),
  });

type BaseWithdrawV2Props = {
  screen: Screen;
  initialValues: WithdrawFormV2;
  loading?: boolean;
  externalAccounts?: ExternalAccount[];
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  availableRedrawAmount: number;
  nextInstalmentAmount: number;
  nextInstalmentDate?: string;
  showConfirmation: boolean;
  confirmPaymentLoading?: boolean;
  transferFeatureDisabled?: boolean;
  errorMessage?: string;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  onConfirm: (values: WithdrawFormV2) => void;
  error?: ApolloError;
  refetch: () => void;
};

export function BaseWithdrawV2({
  screen,
  initialValues,
  loading,
  externalAccounts = [],
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  availableRedrawAmount,
  nextInstalmentAmount,
  nextInstalmentDate,
  showConfirmation,
  confirmPaymentLoading,
  transferFeatureDisabled,
  errorMessage,
  onClose,
  onBack,
  onSubmit,
  onConfirm,
  error,
  refetch,
}: BaseWithdrawV2Props) {
  const { flags } = useContext(FeatureFlagsContext);
  const isNPPLongDescriptionEnabled =
    flags.ENABLE_NPP_LONG_DESCRIPTION || false;

  const [validationSchema, setValidationSchema] = useState(
    createValidationSchema(availableRedrawAmount, isNPPLongDescriptionEnabled),
  );

  useEffect(() => {
    setValidationSchema(
      createValidationSchema(
        availableRedrawAmount,
        isNPPLongDescriptionEnabled,
      ),
    );
  }, [availableRedrawAmount, isNPPLongDescriptionEnabled]);

  return (
    <ModalScreenContainer
      headerText={
        showConfirmation
          ? t('Content.Withdraw.ConfirmTransfer')
          : t('Content.Withdraw.Transfer')
      }
      onClose={onClose}
      scrollable
      loading={loading}
      hideBackButton={!showConfirmation}
      onBack={onBack}
    >
      {!loading && transferFeatureDisabled ? (
        <TransferDisabledWarningRow sx={{ mb: '$8' }} />
      ) : null}
      {errorMessage ? <ErrorRow message={errorMessage} mb="m" /> : null}

      <ScreenErrorFallback
        error={error}
        refetch={refetch}
        displayMessage={t('Content.Common.Error.SomethingWentWrong.Title')}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formProps) => (
            <>
              {!showConfirmation && (
                <WithdrawV2Form
                  screen={screen}
                  formProps={formProps}
                  externalAccounts={externalAccounts}
                  loading={loading}
                  unloanAccountName={unloanAccountName}
                  unloanBsb={unloanBsb}
                  unloanAccountNumber={unloanAccountNumber}
                  availableRedrawAmount={availableRedrawAmount}
                  transferFeatureDisabled={transferFeatureDisabled}
                  onSubmitPress={formProps.handleSubmit}
                />
              )}

              {showConfirmation ? (
                <WithdrawConfirmationV2
                  values={formProps.values}
                  externalAccounts={externalAccounts}
                  unloanAccountName={unloanAccountName}
                  unloanBsb={unloanBsb}
                  unloanAccountNumber={unloanAccountNumber}
                  availableRedrawAmount={availableRedrawAmount}
                  nextInstalmentAmount={nextInstalmentAmount}
                  nextInstalmentDate={nextInstalmentDate}
                  onConfirmPress={() => onConfirm(formProps.values)}
                  confirmLoading={confirmPaymentLoading}
                />
              ) : null}
            </>
          )}
        </Formik>
      </ScreenErrorFallback>
    </ModalScreenContainer>
  );
}

const initialValues: WithdrawFormV2 = {
  [WithdrawalFormField.Amount]: null,
  [WithdrawalFormField.ExternalAccountId]: '',
  [WithdrawalFormField.Description]: '',
  [WithdrawalFormField.Reference]: null,
  [WithdrawalFormField.IsManualAccountInput]: false,
  [WithdrawalFormField.AccountName]: '',
  [WithdrawalFormField.AccountBsb]: '',
  [WithdrawalFormField.AccountNumber]: '',
};

type WithdrawV2Props = HomeLoanScreenProps<Screen.HOME_LOAN_WITHDRAWAL_V2>;

export function WithdrawV2({ navigation, route }: WithdrawV2Props) {
  const { loading: externalAccountsLoading, data } =
    useGetExternalAccountsQuery();

  const { cbaAccountId } = route.params;

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSubmit = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const {
    loading: loanAccountLoading,
    data: loanAccount,
    error,
    refetch,
  } = useLoanAccountState(cbaAccountId);

  const { loading: propertyAddressLoading, data: propertyAddress } =
    useGetLoanAccountPropertyAddressQuery({
      variables: { cba_account_id: cbaAccountId },
      context: {
        sentryContext: {
          cbaAccountId,
        },
      },
    });

  const { flags } = useContext(FeatureFlagsContext);

  const loading =
    loanAccountLoading || externalAccountsLoading || propertyAddressLoading;

  const availableRedrawAmount =
    loanAccount?.balances?.available_redraw_balance ?? 0;

  const nextInstalmentAmount = loanAccount?.next_installment?.amount ?? 0;
  const nextInstalmentDate =
    loanAccount?.next_installment?.next_installment_date;

  const propertyDisplayAddress =
    propertyAddress?.loan_account[0]?.loan_application_target
      ?.loan_application_securities[0]?.property?.address?.short_address_format;

  const unloanAccountName =
    (propertyDisplayAddress || loanAccount?.settings?.name) ??
    DEFAULT_UNLOAN_ACCOUNT_NAME;

  const unloanBsb = loanAccount?.settings?.bsb ?? '';

  const unloanAccountNumber = loanAccount?.settings?.account_number ?? '';

  const externalAccounts =
    data?.me?.[0]?.user?.identity_profile?.external_accounts ?? [];

  const onBack = useCallback(() => setShowConfirmation(false), []);

  const [paymentProcessError, setPaymentProcessError] = useState('');

  const onExceedsDailyLimit = useCallback(
    (exceededByAmount?: number | null) => {
      navigation.navigate(ActionSheetType.TRANSFER_LIMIT_REACHED, {
        cbaAccountId,
        exceededByAmount,
      });
    },
    [cbaAccountId, navigation],
  );

  const {
    confirmPayment,
    loading: confirmPaymentLoading,
    error: confirmPaymentError,
  } = useConfirmPayment({
    screen: Screen.HOME_LOAN_WITHDRAWAL_V2,
    cbaAccountId,
    onError: setPaymentProcessError,
    onExceedsDailyLimit,
    onSuccess: () => {},
  });

  const { addExternalAccount, addExternalAccountLoading } =
    useAddExternalAccount({
      cbaAccountId,
      accountFor: AddExternalAccountFor.Withdrawal,
      preventSettingSelectedExternalAccount: true,
    });

  const getOrAddExternalAccountId = useCallback(
    async (formValues: WithdrawFormV2) => {
      if (!formValues.is_manual_account_input) {
        return formValues.external_account_id;
      }

      const newExternalAccountId = await addExternalAccount({
        account_name: formValues.account_name,
        account_bsb: formValues.account_bsb,
        account_number: formValues.account_number,
      });

      return newExternalAccountId;
    },
    [addExternalAccount],
  );

  const onConfirm = useCallback(
    async (values: WithdrawFormV2) => {
      const externalAccountId = await getOrAddExternalAccountId(values);

      if (!externalAccountId) {
        return;
      }

      const createPaymentRequestId = await confirmPayment({
        amount: values.amount ?? 0,
        cba_account_id: cbaAccountId,
        description: values.description,
        reference: values.reference || null,
        external_account_id: externalAccountId,
      });

      if (createPaymentRequestId) {
        navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
          message: t('Content.Withdraw.ConfirmSuccessMessage'),
        });
      }
    },
    [cbaAccountId, confirmPayment, getOrAddExternalAccountId, navigation],
  );

  const errorMessage: string = useMemo(() => {
    if (confirmPaymentError) {
      return t('Content.Common.Error.FailWithdrawRequest');
    }

    if (paymentProcessError) {
      return t('Content.Common.Error.FailPaymentProcessing');
    }

    return '';
  }, [confirmPaymentError, paymentProcessError]);

  return (
    <BaseWithdrawV2
      screen={Screen.HOME_LOAN_WITHDRAWAL_V2}
      initialValues={initialValues}
      loading={loading}
      externalAccounts={externalAccounts}
      unloanAccountName={unloanAccountName}
      unloanBsb={unloanBsb}
      unloanAccountNumber={unloanAccountNumber}
      availableRedrawAmount={availableRedrawAmount}
      nextInstalmentAmount={nextInstalmentAmount}
      nextInstalmentDate={nextInstalmentDate}
      showConfirmation={showConfirmation}
      confirmPaymentLoading={addExternalAccountLoading || confirmPaymentLoading}
      transferFeatureDisabled={!flags.ENABLE_TRANSFER_FEATURE}
      errorMessage={errorMessage}
      onClose={onClose}
      onBack={onBack}
      onSubmit={onSubmit}
      onConfirm={onConfirm}
      error={error}
      refetch={refetch}
    />
  );
}
