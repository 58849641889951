import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryHomeLoan = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryHomeLoan');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09 16.5V32.997c0 .76.557.757 1.28.75H30.382c.723.007 1.28.01 1.28-.75V16.499L18.376 3.75 5.09 16.5Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        opacity={0.05}
        d="M18.375 4.5c-.35 0-.7.126-.98.377L13.44 8.485 5.088 16.5v4.043L18.375 8.57 31.66 20.54V16.5l-8.352-8.014-3.956-3.608a1.46 1.46 0 0 0-.978-.377Z"
        fill="#2C2C2C"
      />
      <Path
        opacity={0.07}
        d="M18.375 3.75c-.35 0-.7.125-.98.375L13.44 7.713 5.088 15.68V19.7L18.375 7.797 31.66 19.7v-4.019l-8.352-7.968-3.956-3.588a1.464 1.464 0 0 0-.978-.375Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33.503 16.13 19.353 3.376a1.46 1.46 0 0 0-1.957 0L3.247 16.13a.757.757 0 0 0-.047 1.073l1.208 1.308a.73.73 0 0 0 1.026.05L18.375 7.07l12.942 11.491a.73.73 0 0 0 1.026-.05l1.207-1.308a.758.758 0 0 0-.047-1.074Z"
        fill="#A3A3A3"
      />
      <Path
        d="M13.981 23.34c-.896 0-1.618-.296-2.166-.888-.548-.593-.821-1.39-.821-2.39 0-1.094.291-1.96.874-2.602.583-.64 1.36-.961 2.333-.961.931 0 1.66.29 2.184.87.524.58.786 1.395.786 2.445 0 1.08-.29 1.939-.87 2.574-.58.634-1.353.952-2.32.952Zm9.02-6.658a.1.1 0 0 1 .085.153l-8.083 12.933a.1.1 0 0 1-.084.047H13.01a.1.1 0 0 1-.085-.153l8.066-12.933a.1.1 0 0 1 .084-.047h1.926Zm-8.853 1.429c-.856 0-1.284.619-1.284 1.859 0 1.172.408 1.758 1.222 1.758.832 0 1.248-.61 1.248-1.832 0-1.19-.396-1.785-1.186-1.785Zm7.68 11.887c-.896 0-1.618-.296-2.166-.888-.548-.592-.822-1.389-.822-2.39 0-1.093.292-1.96.875-2.601.582-.641 1.36-.962 2.333-.962.937 0 1.666.287 2.188.861.52.574.782 1.387.782 2.436 0 1.081-.29 1.942-.87 2.584-.58.64-1.353.96-2.32.96Zm.14-5.23c-.85 0-1.273.62-1.273 1.86 0 1.173.413 1.759 1.238 1.759.832 0 1.248-.61 1.248-1.832 0-.568-.11-1.008-.334-1.319-.222-.312-.515-.467-.878-.467Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={5.09}
          y1={3.75}
          x2={34.87}
          y2={30.128}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
