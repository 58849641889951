import mitt from 'mitt';

type OnfidoEvent = {
  completedFromDashboard: {
    kycCheckId: string;
  };
  completedFromFinaliseLoanApplication: {
    kycCheckId: string;
  };
  consentGrantedFromDashboard: {
    identityProfileId: string;
  };
  consentGrantedFromFinaliseLoanApplication: {
    identityProfileId: string;
  };
};

export enum OnfidoEventOrigin {
  Dashboard = 'Dashboard',
  FinaliseLoanApplication = 'FinaliseLoanApplication',
}

export type OnfidoCompletedEvent =
  | 'completedFromDashboard'
  | 'completedFromFinaliseLoanApplication';

export type OnfidoConsentGrantedEvent =
  | 'consentGrantedFromDashboard'
  | 'consentGrantedFromFinaliseLoanApplication';

export const onfidoCompletedEvents: Record<string, OnfidoCompletedEvent> = {
  [OnfidoEventOrigin.Dashboard]: 'completedFromDashboard',
  [OnfidoEventOrigin.FinaliseLoanApplication]:
    'completedFromFinaliseLoanApplication',
};

export const onfidoConsentGrantedEvents: Record<
  string,
  OnfidoConsentGrantedEvent
> = {
  [OnfidoEventOrigin.Dashboard]: 'consentGrantedFromDashboard',
  [OnfidoEventOrigin.FinaliseLoanApplication]:
    'consentGrantedFromFinaliseLoanApplication',
};

const onfidoEmitter = mitt<OnfidoEvent>();

export { onfidoEmitter };
