import { ComponentProps } from 'react';

import { Props as TextProps, StyledText } from './StyledText';

const DefaultHrefAttrs: ComponentProps<typeof StyledText>['hrefAttrs'] = {
  rel: 'noreferrer',
  target: '_blank',
};

export function Link(props: TextProps) {
  return (
    <StyledText
      color="link"
      role="link"
      hrefAttrs={DefaultHrefAttrs}
      {...props}
    />
  );
}
