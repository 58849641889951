import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { SvgIconProps } from './types';

export const BorrowerProfileIcon: React.FC<SvgIconProps> = ({
  size,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        d="M18 36c9.9 0 18-8.095 18-18.01C36 8.096 27.9 0 18 0S0 8.095 0 17.99C0 27.906 8.1 36 18 36z"
        fill={theme.colors.buttonSecondaryBg}
      />
      <Path
        d="M23.266 14.234c0-3.109-2.328-5.734-5.266-5.734-2.922 0-5.281 2.625-5.266 5.734 0 3.313 2.282 5.875 5.266 5.875 3 0 5.266-2.562 5.266-5.875zM28.203 27.156v-.218C26.234 24.53 22 22.813 18 22.813c-3.984 0-8.234 1.718-10.203 4.125v.218C10.063 29.75 13.53 31.734 18 31.734c4.469 0 7.938-1.984 10.203-4.578z"
        fill={theme.colors.accent}
      />
    </Svg>
  );
};
