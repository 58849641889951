import { ErrorRow } from '../../components/ErrorRow';

type ErrorMessageProp = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProp) => {
  const defaultMessage = t('Content.DataSharingPreferences.Error.FetchAccount');
  return <ErrorRow message={message || defaultMessage} />;
};

export default ErrorMessage;
