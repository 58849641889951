import { ReactNode, useCallback, useMemo, useState } from 'react';

import { BorrowerFormV2ContextValues } from '../../components/form/types';
import { createContext } from '../../utils/createContext';

export const [useBorrowerWizardState, BorrowerWizardFormProvider] =
  createContext<{
    values: BorrowerFormV2ContextValues;
    setValues: (value: Partial<BorrowerFormV2ContextValues>) => void;
  }>('BorrowerWizardForm');

export default function BorrowerFormContext({
  initialValues,
  ...otherProps
}: {
  initialValues: BorrowerFormV2ContextValues;
  children: ReactNode;
}) {
  const [values, updateValues] =
    useState<BorrowerFormV2ContextValues>(initialValues);

  const setValues = useCallback(
    (value: Partial<BorrowerFormV2ContextValues>) => {
      updateValues((prevData) => ({ ...prevData, ...value }));
    },
    [],
  );

  const memoizedValues = useMemo(
    () => ({
      values,
      setValues,
    }),
    [values, setValues],
  );

  return <BorrowerWizardFormProvider value={memoizedValues} {...otherProps} />;
}
