import { useNavigation, useRoute } from '@react-navigation/native';
import { A, Text } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { InfoRow } from '../../components/InfoRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { INTERCOM_CUSTOM_LAUNCHER_SELECTOR } from '../../constants/intercom';
import {
  Income_Type_Enum,
  Loan_Application_Status_Info_Stage_Enum,
  Rental_Income_Type_Enum,
} from '../../generated/graphql';
import { useIntercom } from '../../Home/navigation/utils/useIntercom';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import {
  ApplicationDetailsSections,
  ApplicationDetailsSummarySectionHeaderOnPressTypes,
  ApplicationDetailsSummarySectionItemOnPressTypes,
} from '../components/ApplicationDetailsSections';
import {
  ConditionalApprovalNavigationProps,
  ConditionalApprovalScreenProps,
  ConditionalApprovalStackRoute,
} from '../navigation/types';
import { useConditionalApprovalApplicationDetailsQuery } from '../remoteData';
import { useNavigateFromApplicationDetails } from '../utils/useApplicationDetailsNavigation';

export type Props =
  ConditionalApprovalScreenProps<Screen.CONDITIONAL_APPROVAL_APP_DETAILS>;

export function ApplicationDetails() {
  const navigation =
    useNavigation<
      ConditionalApprovalNavigationProps<Screen.CONDITIONAL_APPROVAL_APP_DETAILS>
    >();
  const route =
    useRoute<
      ConditionalApprovalStackRoute<Screen.CONDITIONAL_APPROVAL_APP_DETAILS>
    >();
  const { loanApplicationId = '' } = route.params || {};
  const { goBack } = navigation;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const { navigateFromAppDetails } =
    useNavigateFromApplicationDetails(navigation);

  const navigateBackToPreviousMainPage = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.ConditionalApproval,
    });
  };

  const {
    data: queryRes,
    sectionData,
    loading,
    error,
    refetch,
  } = useConditionalApprovalApplicationDetailsQuery({ loanApplicationId });

  // Define which screen to navigate to when adding item to each section
  const onSectionHeaderPress: ApplicationDetailsSummarySectionHeaderOnPressTypes =
    {
      borrowers: () => {
        navigateToLoanApplicationScreen({
          section: LoanApplicationSection.Borrowers,
        });
      },
      income: () => {
        navigateFromAppDetails(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
          params: { loanApplicationId, hideNovatedLeaseFields: true },
        });
      },
      expense: () => {
        navigateFromAppDetails(Screen.YOUR_EXPENSES_V2_WIZARD, {
          screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
          params: { loanApplicationId },
        });
      },
      liabilities: () => {
        navigateFromAppDetails(Screen.SINGLE_V2_MODAL, {
          screen: Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL,
          params: { loanApplicationId },
        });
      },
    };

  // Define which screen to navigate to when editing an item for each section
  const onSectionItemPress: ApplicationDetailsSummarySectionItemOnPressTypes = {
    borrowers: (data) => {
      navigateFromAppDetails(Screen.BORROWER_V2_MODAL, {
        screen: Screen.BORROWER_V2_DETAILS,
        params: {
          loanApplicationId,
          borrowerId: data.id,
          isCurrentLoggedInApplicant: data.is_current_logged_in_applicant,
        },
      });
    },
    income: (data) => {
      const { incomeType, id: incomeId } = data;
      if (incomeType === Income_Type_Enum.Employment) {
        navigateFromAppDetails(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL,
          params: {
            loanApplicationId,
            incomeId,
          },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.Rental) {
        const rentalIncomeType =
          data.rentalIncome?.rental_income_type ??
          Rental_Income_Type_Enum.Existing;

        const screen =
          rentalIncomeType === Rental_Income_Type_Enum.Expected
            ? Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL
            : Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL;

        navigateFromAppDetails(Screen.YOUR_INCOME_V2_MODAL, {
          screen,
          params: { loanApplicationId, incomeId },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.GovernmentPayments) {
        navigateFromAppDetails(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL,
          params: { loanApplicationId, incomeId },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.ShareDividends) {
        navigateFromAppDetails(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL,
          params: { loanApplicationId, incomeId },
        });
      }
    },
    expense: (data) => {
      navigateFromAppDetails(Screen.YOUR_EXPENSES_V2_WIZARD, {
        screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
        params: {
          loanApplicationId,
          householdId: data.id,
        },
      });
    },
    liabilities: (data) => {
      const {
        currentLiabilityId,
        type,
        detectedLiabilityIdentifier,
        liabilityManuallyAdded,
      } = data;

      navigateFromAppDetails(Screen.SINGLE_V2_MODAL, {
        screen: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
        params: {
          loanApplicationId,
          liabilityType: type,
          currentLiabilityId: currentLiabilityId || undefined,
          detectedLiabilityIdentifier: detectedLiabilityIdentifier || undefined,
          liabilityManuallyAdded: !!liabilityManuallyAdded,
        },
      });
    },
  };

  const onUpdateApplicationPress = () => {
    /**
     * This intentionally do not use `navigateFromAppDetails` to
     * avoid setting the previous screen state in the recoil state.
     * We don't need the recoil state because the update application
     * confirmation always use navigation.goBack() to return.
     */
    navigation.navigate(ActionSheetType.UPDATE_APPLICATION_CONFIRMATION, {
      loanApplicationId,
    });
  };

  if (loading) {
    return (
      <ModalScreenContainer
        hideBackButton
        onClose={navigateBackToPreviousMainPage}
      >
        <ScreenLoadingContainer flex={1} centered loading />
      </ModalScreenContainer>
    );
  }

  if (error) {
    return (
      <ModalScreenContainer
        hideBackButton
        onClose={navigateBackToPreviousMainPage}
      >
        <ScreenErrorFallback
          error={error}
          displayMessage={t(
            'Content.ConditionalApproval.Details.Error.FailFetchApplicationSummary',
          )}
          refetch={refetch}
        />
      </ModalScreenContainer>
    );
  }

  const statusInfo = queryRes?.loan_application_by_pk?.status_info;
  const canUserEdit =
    (statusInfo?.can_user_edit &&
      statusInfo?.loan_application_stage !==
        Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved &&
      statusInfo?.loan_application_stage !==
        Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved) ||
    false;

  return (
    <ModalScreenContainer
      scrollable
      hideBackButton
      headerText={t('Content.ConditionalApproval.Details.Title')}
      onClose={navigateBackToPreviousMainPage}
    >
      {statusInfo?.loan_application_stage ===
      Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved ? (
        <WeAreAssessingRow />
      ) : null}
      {statusInfo?.loan_application_stage ===
      Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved ? (
        <UpdateApplicationRow
          onUpdateApplicationPress={onUpdateApplicationPress}
        />
      ) : null}
      <ApplicationDetailsSections
        sectionData={sectionData}
        onSectionItemPress={onSectionItemPress}
        onSectionHeaderPress={onSectionHeaderPress}
        isEditMode={canUserEdit}
      />
      <Button
        onPress={goBack}
        label={t('Content.Common.ButtonLabel.Done')}
        mt="s"
        alignSelf="stretch"
        testID={TestID.ApplicationDetails.DoneButton}
      />
    </ModalScreenContainer>
  );
}

function WeAreAssessingRow() {
  const { onChatIconPress } = useIntercom();

  return (
    <InfoRow mb="s">
      <Text
        variant="caption"
        sx={{
          flex: 1,
        }}
      >
        {t('Content.ConditionalApproval.Details.WeAreAssessing')}
        <Text
          nativeID={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
          variants={['caption', 'link']}
          onPress={onChatIconPress}
          sx={{ cursor: 'pointer' }}
        >
          {t('Content.ConditionalApproval.Details.ContactUs')}
        </Text>
      </Text>
    </InfoRow>
  );
}

function UpdateApplicationRow({
  onUpdateApplicationPress,
}: {
  onUpdateApplicationPress: () => void;
}) {
  return (
    <InfoRow mb="s">
      <Text
        variant="caption"
        sx={{
          flex: 1,
        }}
      >
        {t('Content.ConditionalApproval.Details.ToMakeAnyChanges')}
        <A variant="link" onPress={onUpdateApplicationPress}>
          {t('Content.ConditionalApproval.Details.UpdateApplication')}
        </A>
      </Text>
    </InfoRow>
  );
}
