import { getCookie } from './cookies';

export const EVERFLOW_TRACKING_COOKIE_NAME = 'everflow_transaction_id';

export function shouldShowReferral(): boolean {
  return getCookie(EVERFLOW_TRACKING_COOKIE_NAME) !== null;
}

export function getEverflowTransactionId(): string | null {
  return getCookie(EVERFLOW_TRACKING_COOKIE_NAME);
}
