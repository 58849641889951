import { Formik, FormikHelpers } from 'formik';
import { useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import { Form, FormDatePicker } from '../../components/form/FormikInputs';
import { ModalScreenContainer } from '../../components/ModalScreenContainer';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { Screen } from '../../navigation/types/screens';
import { Separator } from '../../ui/atoms/Separator';
import { ListRowGroup } from '../../ui/molecules/ListRowGroup';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { FormNavButtonHandler } from '../../utils/FormNavButtonHandler';
import { yup } from '../../utils/yup';
import { StatementsModalScreenProps } from '../navigation/types';

export const validationSchema = yup.object({
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), t('Content.Statements.FilterEndDateValidation'))
    .required(),
});

export type FormValues = yup.Asserts<typeof validationSchema>;
type Props = StatementsModalScreenProps<Screen.STATEMENTS_FILTER_MODAL>;

export function StatementsFilter({ navigation, route }: Props) {
  const initialValues = useMemo<FormValues>(
    () => ({
      startDate: new Date(route.params.startDate),
      endDate: new Date(route.params.endDate),
    }),
    [route.params.startDate, route.params.endDate],
  );

  const onSubmit = (value: FormValues, formik: FormikHelpers<FormValues>) => {
    formik.setSubmitting(false);

    navigation.navigate(Screen.STATEMENTS_MODAL, {
      screen: Screen.STATEMENTS_PREVIEW_MODAL,
      params: {
        cbaAccountId: route.params.cbaAccountId,
        startDate: safelyFormatDate(value.startDate) ?? route.params.startDate,
        endDate: safelyFormatDate(value.endDate) ?? route.params.endDate,
      },
    });
  };

  return (
    <ModalScreenContainer bg="bg">
      <NavHeaderSpacer />
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formProps) => (
          <Form>
            <FormNavButtonHandler
              formProps={formProps}
              navigation={navigation}
              submitText={t('Content.Common.ButtonLabel.Done')}
              submitButtonTestID={TestID.Statements.StatementsFilter.DoneButton}
              allowClean
            >
              <ListRowGroup
                headerText={t('Content.Statements.Titles.FilterGroupTitle')}
              >
                <FormDatePicker
                  name="startDate"
                  label={t('Content.Statements.Titles.FilterStartDate')}
                  minimumDate={initialValues.startDate}
                  maximumDate={initialValues.endDate}
                  last
                />

                <Separator />

                <FormDatePicker
                  name="endDate"
                  label={t('Content.Statements.Titles.FilterEndDate')}
                  minimumDate={initialValues.startDate}
                  maximumDate={initialValues.endDate}
                  last
                />
              </ListRowGroup>
            </FormNavButtonHandler>
          </Form>
        )}
      </Formik>
    </ModalScreenContainer>
  );
}
