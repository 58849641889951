import { gql } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Text } from 'dripsy';
import { useMemo } from 'react';

import { ErrorRow } from '../../components/ErrorRow';
import { InfoRow } from '../../components/InfoRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import {
  ApplicantForYourDebtsFragment,
  ApplicantForYourDebtsFragmentDoc,
  Loan_Interest_Rate_Type_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  State_Enum,
  useDebtsReviewDetailsV2Query,
  useSubmitDetectedLiabilitiesDetailsV2WizardMutation,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { safelyParseDate } from '../../utils/dateHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { mapHasuraAddressToAppAddress } from '../../utils/mapToAppAddress';
import {
  LoanDetailsForm,
  LoanDetailsFormField,
  LoanDetailsFormValues,
  LoanDetailsManualAddressFormField,
  pickBalanceAndLimitValue,
} from '../components/LoanDetailsForm';
import {
  DebtsWizardNavigationScreenProp,
  DebtsWizardStackRoute,
} from '../navigation/types';
import { composeDebtsWizardScreenName } from '../navigation/utils/debtsScreenHelpers';
import { getHeaderTextByLiabilityType } from '../utils/debtsScreenUtils';
import { useDebtsWizardContextV2 } from '../utils/DebtsWizardFormProviderV2';
import { mapApplicantsToAccountOwnerOptions } from '../utils/liabilityRemoteDataHelpers';
import { splitMonthsIntoYearMonthParts } from '../utils/mergedLiabilityUtils';
import {
  AppMergedLiability,
  compareDeclaredAndDetectedLiabilityOwners,
  LiabilityOwnerCompareResult,
  useAllLiabilitiesQuery,
} from '../utils/useAllLiabilitiesQuery';

export const DEBTS_REVIEW_DETAILS_V2_DOC = gql`
  query DebtsReviewDetailsV2($loanApplicationId: uuid!) {
    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...ApplicantForYourDebts
    }
    states: state {
      code
      name
    }
  }
  ${ApplicantForYourDebtsFragmentDoc}
`;

function getNextLiabilityInWizard(
  all: Array<AppMergedLiability>,
  currentAml: AppMergedLiability | undefined,
) {
  if (!currentAml || currentAml.detected_liability_identifier == null) {
    return null;
  }

  const currentAmlIndex = all.findIndex(
    (aml) =>
      aml.detected_liability_identifier ===
      currentAml.detected_liability_identifier,
  );

  if (currentAmlIndex === -1) {
    return null;
  }

  const nextIndex = currentAmlIndex + 1;
  const next = all[nextIndex];

  return next;
}

function getLoanDetailsFormInitialValues(
  aml: AppMergedLiability | undefined,
): LoanDetailsFormValues {
  const [termInMonthsYearPart, termInMonthsMonthPart] =
    aml?.loanTermInMonth != null
      ? splitMonthsIntoYearMonthParts(aml.loanTermInMonth)
      : [null, null];

  const propertyAddress = aml?.dynamite_address
    ? mapHasuraAddressToAppAddress(aml.dynamite_address)
    : null;

  const { balance, limit } = pickBalanceAndLimitValue({
    liabilityType: aml?.liabilityType,
    mlBalance: aml?.dynamite_balance,
    mlLimit: aml?.dynamite_limit,
  });

  return {
    [LoanDetailsFormField.Institution]:
      aml?.dynamite_institution_name != null
        ? {
            id: aml.dynamite_institution_id ?? undefined,
            name: aml.dynamite_institution_name,
          }
        : null,
    [LoanDetailsFormField.AccountNumber]: aml?.dynamite_account_number ?? '',
    [LoanDetailsFormField.AccountOwners]: aml?.dynamite_applicant_ids ?? [],
    [LoanDetailsFormField.Balance]: balance ?? null,
    // Available balance is null for detected liabilities
    [LoanDetailsFormField.AvailableBalance]: null,
    [LoanDetailsFormField.LoanRateType]:
      (aml?.dynamite_interest_rate_type as Loan_Interest_Rate_Type_Enum) ??
      null,
    [LoanDetailsFormField.FixedRateExpiryDate]: safelyParseDate(
      aml?.dynamite_fixed_rate_expiry_date,
      'yyyy-MM-dd',
    ),
    [LoanDetailsFormField.InterestRate]:
      aml?.dynamite_interest_rate != null
        ? String(aml.dynamite_interest_rate)
        : null,
    [LoanDetailsFormField.Limit]: limit ?? null,
    [LoanDetailsFormField.MonthlyRepaymentAmount]:
      aml?.dynamite_repayment_amount ?? null,
    [LoanDetailsFormField.PropertyAddress]: propertyAddress,
    [LoanDetailsFormField.TermInMonthsMonthPart]: termInMonthsMonthPart,
    [LoanDetailsFormField.TermInMonthsYearPart]: termInMonthsYearPart,
    // TODO(uiv2): implement flagged incorrect post 15 May
    // flaggedIncorrect: aml?.flaggedIncorrect ?? undefined,

    [LoanDetailsManualAddressFormField.isManualInput]:
      propertyAddress?.isProvidedByUser ?? null,
    [LoanDetailsManualAddressFormField.postcode]:
      propertyAddress?.postcode ?? null,
    [LoanDetailsManualAddressFormField.state]:
      parseEnumType(State_Enum, propertyAddress?.state) ?? null,
    [LoanDetailsManualAddressFormField.streetName]:
      propertyAddress?.street ?? null,
    [LoanDetailsManualAddressFormField.streetNo]:
      propertyAddress?.streetNo ?? null,
    [LoanDetailsManualAddressFormField.streetType]: propertyAddress?.streetType
      ? { label: propertyAddress.streetType, value: propertyAddress.streetType }
      : null,
    [LoanDetailsManualAddressFormField.suburb]: propertyAddress?.suburb ?? null,
    [LoanDetailsManualAddressFormField.unitNo]: propertyAddress?.unitNo ?? null,
  };
}

export function DebtsReviewDetailsV2() {
  const navigation =
    useNavigation<
      DebtsWizardNavigationScreenProp<Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS>
    >();

  const route =
    useRoute<DebtsWizardStackRoute<Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS>>();

  const {
    loanApplicationId,
    detectedLiabilityIdentifier,
    _initialErrorMessage,
  } = route.params ?? {};

  const {
    currentApplicant,
    liabilitiesForDebtsWizard,
    loading: liabilitiesLoading,
    error: liabilitiesError,
  } = useAllLiabilitiesQuery({
    loanApplicationId,
    forV2: true,
  });

  const propertySuggestionLoader = usePropertySuggestionLoader();

  const currentAml = liabilitiesForDebtsWizard.find(
    (aml) => aml.detected_liability_identifier === detectedLiabilityIdentifier,
  );

  const nextAml = getNextLiabilityInWizard(
    liabilitiesForDebtsWizard,
    currentAml,
  );
  const isLastLiability = nextAml == null;

  const {
    setFormValuesToWizardState,
    getMutationVariablesForSubmitLiabilityDetails,
  } = useDebtsWizardContextV2();

  const {
    data: optionsData,
    loading: optionsLoading,
    error: optionsError,
  } = useDebtsReviewDetailsV2Query({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const loading = liabilitiesLoading || optionsLoading;
  const error = liabilitiesError || optionsError;

  const accountOwnerOptions = mapApplicantsToAccountOwnerOptions({
    applicants: optionsData?.applicants,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const manualAddressStateOptions = useMemo(
    () =>
      optionsData?.states?.map(({ code }) => ({
        label: code,
        value: code,
      })) ?? [],
    [optionsData?.states],
  );

  const loanDetailsFormInitialValues = useMemo(
    () => getLoanDetailsFormInitialValues(currentAml),
    [currentAml],
  );

  const [submitDetailsV2, { loading: isSubmittingV2, error: submitErrorV2 }] =
    useSubmitDetectedLiabilitiesDetailsV2WizardMutation();

  const isSubmitting = isSubmittingV2;
  const submitError = submitErrorV2 || _initialErrorMessage;
  const navigateToLoanApplicationDebts = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Debts,
    });
  };

  const onSubmit = async (formValues: LoanDetailsFormValues) => {
    if (!loanApplicationId || !currentAml || !detectedLiabilityIdentifier) {
      // Cannot submit without these
      return;
    }

    setFormValuesToWizardState({
      detectedLiabilityIdentifier,
      formValues,
    });

    if (isLastLiability) {
      const input = {
        ...getMutationVariablesForSubmitLiabilityDetails(),
        loan_application_id: loanApplicationId,
      };

      await safelyCallMutation(submitDetailsV2, {
        variables: {
          input,
          loanApplicationId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchYourDebtsQuery({ loanApplicationId }),
          refetchSetupLoanScreenQuery({ loanApplicationId }),
          refetchReviewLoanApplicationQuery({ loanApplicationId }),
          refetchConditionalApprovalGetReviewLoanApplicationQuery({
            loanApplicationId,
          }),
        ],
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      });

      navigateToLoanApplicationDebts();
      return;
    }

    if (!nextAml.detected_liability_identifier) {
      // unexpected
      return;
    }

    navigation.navigate(
      composeDebtsWizardScreenName(liabilitiesForDebtsWizard, {
        detectedLiabilityIdentifier: nextAml.detected_liability_identifier,
      }),
      {
        loanApplicationId,
      },
    );
  };

  if (loading) {
    return (
      <ModalScreenContainer onClose={navigateToLoanApplicationDebts}>
        <ScreenLoadingContainer flex={1} centered loading />
      </ModalScreenContainer>
    );
  }

  if (error || !loanApplicationId || !currentAml || !currentApplicant) {
    return (
      <ModalScreenContainer onClose={navigateToLoanApplicationDebts}>
        <EmptyState
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      headerText={getHeaderTextByLiabilityType(currentAml.liabilityType)}
      scrollable
      onClose={navigateToLoanApplicationDebts}
      loading={isSubmitting}
    >
      {submitError ? (
        <ErrorRow
          message={t('Content.DebtsReviewDetailsV2.MutationError')}
          mb="l"
        />
      ) : null}
      <Text sx={{ mb: '$32' }}>
        {t('Content.DebtsReviewDetailsV2.ReviewYourLoanDetails')}
      </Text>

      {/* TODO(uiv2): flagged incorrect needs to be revisited after May 15th */}
      {/* https://github.com/unloan/unloan-app/issues/6327 */}
      {/* {showFlaggedIncorrectInfoRow ? (
        <FlaggedAsIncorrectInfoRow {...params} />
      ) : null} */}
      <RemindApplicantToCheckLiabilityOwnersInfoRow
        aml={currentAml}
        currentApplicant={currentApplicant}
      />
      <LoanDetailsForm
        screen={Screen.DEBTS_WIZARD_V2_REVIEW_DETAILS}
        accountOwnerOptions={accountOwnerOptions}
        manualAddressStateOptions={manualAddressStateOptions}
        manualAddressStreetTypeOptions={streetTypeOptions}
        liabilityType={currentAml.liabilityType}
        onSubmit={onSubmit}
        liabilityManuallyAdded={false}
        initialValues={loanDetailsFormInitialValues}
        submitButtonLabel={
          nextAml ? t('Content.Common.ButtonLabel.Next') : undefined
        }
        isSubmitting={isSubmitting}
        {...propertySuggestionLoader}
        // Hide available balance field for detected liabilities
        allowEditAvailableBalanceField={false}
      />
    </ModalScreenContainer>
  );
}

function RemindApplicantToCheckLiabilityOwnersInfoRow({
  aml,
  currentApplicant,
}: {
  aml: AppMergedLiability;
  currentApplicant: ApplicantForYourDebtsFragment;
}) {
  const result = useMemo(
    () => compareDeclaredAndDetectedLiabilityOwners({ aml, currentApplicant }),
    [aml, currentApplicant],
  );

  if (
    result === LiabilityOwnerCompareResult.LiabilityHasUnlistedDeclaredOwner
  ) {
    return (
      <InfoRow
        message={t(
          'Content.DebtsReviewDetailsV2.LiabilityHasUnlistedDeclaredOwner',
        )}
        mb="m"
      />
    );
  }
  return null;
}
