import { Text, View } from 'dripsy';
import { Formik } from 'formik';
import { Fragment, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormSelectV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import {
  Expense_Type_Input_Enum,
  Frequency_Input_Enum,
  GetHouseholdExpensesFieldsWithGuideQuery,
  Living_Situation_Input_Enum,
} from '../../generated/graphql';
import { TranslateKeys } from '../../localization/constants';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { LoadingState } from '../../ui/organisms/LoadingState';
import { FormikFormError } from '../../ui/v2/FormError';
import { formatCurrency } from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { makeTestId } from '../../utils/stringHelpers';
import { yup } from '../../utils/yup';
import { useExpensesForm } from '../utils/ExpensesFormProvider';
import {
  calculateFormTotalMonthlyExpenses,
  getExpenseGuideByFrequency,
  getExpenseGuideWithFrequencyText,
  getExpensesGuideTextV2,
  groupExpenseFieldsByExpenseGroup,
  isLivingSituationOwnProperty,
  transformToFormExpensesType,
} from '../utils/expensesFormUtils';
import {
  CombinedExpensesFormValues,
  ExpensesTypeFormInitialValues,
  ExpensesTypeFormValidationSchemaOwnProperty,
  ExpensesTypeFormValidationSchemaRent,
  ExpensesTypeFormValuesRent,
  yupExpensesAmount,
} from '../utils/forms';
import { ExpenseWarningRow } from './ExpenseWarningRow';

export type ExpensesTypesFormProps = {
  screen: Screen;
  onSubmit: (values: CombinedExpensesFormValues) => void;
  isSubmitting?: boolean;
  expenseFieldsWithGuide: GetHouseholdExpensesFieldsWithGuideQuery['get_household_expenses_fields_with_guide']['expense_fields'];
  frequencyOptions: PickerOptions<string>;
  showDeleteButton?: boolean;
  onDeleteHousehold?: () => void;
  initialValues?: ExpensesTypeFormValuesRent;
  loading?: boolean;
  enableExpensesScreenAndHemGuideUplift?: boolean;
};

function getExpensesInteractionEventKey(screen: Screen) {
  return {
    [Expense_Type_Input_Enum.FoodAndGroceries]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.FoodGroceriesTakeAway,
      ),
    [Expense_Type_Input_Enum.HomeAndUtilities]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.HomeUtilities,
      ),
    [Expense_Type_Input_Enum.ClothingAndPersonalCare]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.ClothingPersonalCare,
      ),
    [Expense_Type_Input_Enum.MedicalHealthFitness]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.MedicalHealthFitness,
      ),
    [Expense_Type_Input_Enum.EntertainmentAndPets]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.EntertainmentPets,
      ),
    [Expense_Type_Input_Enum.ChildcareAndEducation]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.ChildcareEducation,
      ),
    [Expense_Type_Input_Enum.ChildAndSpouseSupport]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.ChildAndSpouseSupport,
      ),
    [Expense_Type_Input_Enum.AdditionalExpenses]:
      buildApplicationInteractionEventKey(
        SectionInteractionKey.Expenses,
        screen,
        FieldInteractionKey.AdditionalExpenses,
      ),
    [Expense_Type_Input_Enum.RentOrBoard]: buildApplicationInteractionEventKey(
      SectionInteractionKey.Expenses,
      screen,
      FieldInteractionKey.RentOrBoard,
    ),
    [Expense_Type_Input_Enum.Transport]: buildApplicationInteractionEventKey(
      SectionInteractionKey.Expenses,
      screen,
      FieldInteractionKey.Transport,
    ),
  };
}

export function ExpensesTypesForm({
  screen,
  onSubmit,
  isSubmitting,
  frequencyOptions,
  expenseFieldsWithGuide,
  showDeleteButton,
  onDeleteHousehold,
  initialValues = ExpensesTypeFormInitialValues,
  loading,
  enableExpensesScreenAndHemGuideUplift = false,
}: ExpensesTypesFormProps) {
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [warningShownBeforeFocus, setWarningShownBeforeFocus] = useState<
    boolean | 0 | null | undefined
  >(null);
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const { values } = useExpensesForm();

  const { livingSituation } = values.expenses;

  const validationSchema = isLivingSituationOwnProperty(livingSituation)
    ? ExpensesTypeFormValidationSchemaOwnProperty
    : ExpensesTypeFormValidationSchemaRent;

  if (loading) {
    return <LoadingState />;
  }

  const groupedExpenseFieldsWithGuide = enableExpensesScreenAndHemGuideUplift
    ? groupExpenseFieldsByExpenseGroup(expenseFieldsWithGuide)
    : [];

  function handleOnBlur(
    expenseGuideFrom: number | null | undefined,
    expenseInputValue: number,
    expenseFieldName: Expense_Type_Input_Enum,
  ) {
    setFocusedField(null);
    setWarningShownBeforeFocus(null);
    if (
      !(
        !warningShownBeforeFocus &&
        expenseGuideFrom &&
        expenseInputValue < expenseGuideFrom
      )
    ) {
      return;
    }
    sendAppInteractionEventToGTM({
      description: GTMAppInteractionEventDescription.WarningShown,
      additionalData: {
        application_interaction_event_key: buildApplicationInteractionEventKey(
          SectionInteractionKey.Expenses,
          screen,
          FieldInteractionKey.WarningShown,
        ),
        warning_shown_for: expenseFieldName,
      },
    });
  }

  const expensesInteractionEventKeys = getExpensesInteractionEventKey(screen);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {(formProps) => (
        <View pointerEvents={isSubmitting ? 'none' : undefined}>
          {enableExpensesScreenAndHemGuideUplift
            ? groupedExpenseFieldsWithGuide.map(
                ([groupName, expenseFields]) => (
                  <Fragment key={groupName}>
                    <Text variant="sHeader" sx={{ mb: '$16', mt: '$24' }}>
                      {t(
                        `Content.ExpensesForm.ExpensesGroup.${groupName}` as TranslateKeys,
                      )}
                    </Text>
                    {expenseFields.map((expenseField, index) => {
                      const { expenseInputName, frequencyInputName } =
                        transformToFormExpensesType(expenseField.field_name);

                      if (expenseInputName && frequencyInputName) {
                        const frequencyInputValue = parseEnumType(
                          Frequency_Input_Enum,
                          formProps.values[frequencyInputName],
                        );

                        const expenseGuide = getExpenseGuideByFrequency({
                          expenseField,
                          frequency: frequencyInputValue,
                        });

                        const guideText = getExpenseGuideWithFrequencyText({
                          fieldName: expenseField.field_name,
                          frequency: frequencyInputValue,
                          from: expenseGuide?.from,
                          to: expenseGuide?.to,
                        });

                        const expenseInputValue = formProps.values?.[
                          expenseInputName
                        ] as yup.InferType<typeof yupExpensesAmount>;

                        const showWarningRow =
                          focusedField !== expenseInputName &&
                          expenseGuide?.from &&
                          expenseInputValue < expenseGuide?.from;

                        return (
                          <Fragment key={expenseField.field_name}>
                            <Text
                              sx={{
                                mb: '$8',
                                mt: index === 0 ? '$0' : '$24',
                              }}
                              variant="emphasis"
                            >
                              {t(
                                `Content.ExpensesForm.ExpensesTypeV2.${expenseField.field_name}.title`,
                              )}
                            </Text>
                            <Text variant="caption" sx={{ mb: '$8' }}>
                              {t(
                                `Content.ExpensesForm.ExpensesTypeV2.${expenseField.field_name}.caption`,
                              )}
                            </Text>
                            <View sx={{ flexDirection: 'row' }}>
                              <FormCurrencyInputV2
                                name={expenseInputName}
                                label="Amount"
                                sx={{ flex: 1 }}
                                // Set the field as focused when user is typing as
                                // we do not want to show warning when user is typing.
                                onFocus={() => {
                                  setFocusedField(expenseInputName);
                                  setWarningShownBeforeFocus(
                                    !!(
                                      expenseGuide?.from &&
                                      expenseInputValue < expenseGuide?.from
                                    ),
                                  );
                                }}
                                onBlur={() =>
                                  handleOnBlur(
                                    expenseGuide?.from,
                                    expenseInputValue,
                                    expenseField.field_name,
                                  )
                                }
                                inputTestID={makeTestId([
                                  TestID.ExpensesForm.ExpenseCurrencyInput,
                                  expenseInputName,
                                ])}
                                interactionKey={
                                  expensesInteractionEventKeys[
                                    expenseField.field_name
                                  ]
                                }
                              />
                              <FormSelectV2
                                name={frequencyInputName}
                                items={frequencyOptions}
                                label="Frequency"
                                sx={{
                                  ml: '$16',
                                  minWidth: 'frequencyInput',
                                  flex: 1,
                                }}
                                testID={makeTestId([
                                  TestID.ExpensesForm.FrequencyInput,
                                  expenseInputName,
                                ])}
                              />
                            </View>
                            {!showWarningRow &&
                            guideText &&
                            !formProps?.errors[frequencyInputName] ? (
                              <Text
                                variant="caption"
                                sx={{ mt: '$8' }}
                                testID={makeTestId([
                                  TestID.ExpensesForm.GuideText,
                                  expenseInputName,
                                ])}
                              >
                                {guideText}
                              </Text>
                            ) : null}
                            {showWarningRow &&
                            guideText &&
                            !formProps?.errors[frequencyInputName] ? (
                              <ExpenseWarningRow
                                warningText={t(
                                  `Content.ExpensesForm.ExpensesTypeV2.${expenseField.field_name}.warning` as TranslateKeys,
                                  {
                                    expenseTitle: t(
                                      `Content.ExpensesForm.ExpensesTypeV2.${expenseField.field_name}.title`,
                                    ).toLowerCase(),
                                    guide: guideText,
                                  },
                                )}
                                expenseInputName={expenseInputName}
                                sx={{ mt: '$8' }}
                              />
                            ) : null}
                            <FormikFormError
                              name={expenseInputName}
                              sx={{ mt: '$8' }}
                            />
                            <FormikFormError
                              name={frequencyInputName}
                              sx={{ mt: '$8' }}
                            />
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                  </Fragment>
                ),
              )
            : expenseFieldsWithGuide.map(({ field_name, from, to }, index) => {
                const guideText = getExpensesGuideTextV2({
                  fieldName: field_name,
                  from,
                  to,
                });

                const { expenseInputName, frequencyInputName } =
                  transformToFormExpensesType(field_name);
                if (expenseInputName && frequencyInputName) {
                  return (
                    <Fragment key={field_name}>
                      <Text
                        sx={{ mb: '$8', mt: index === 0 ? '$0' : '$24' }}
                        variant="emphasis"
                      >
                        {t(
                          `Content.ExpensesForm.ExpensesType.${field_name}.title`,
                        )}
                      </Text>
                      <Text variant="caption" sx={{ mb: '$8' }}>
                        {t(
                          `Content.ExpensesForm.ExpensesType.${field_name}.caption`,
                        )}
                      </Text>
                      <View sx={{ flexDirection: 'row' }}>
                        <FormCurrencyInputV2
                          name={expenseInputName}
                          label="Amount"
                          sx={{ flex: 1 }}
                          inputTestID={makeTestId([
                            TestID.ExpensesForm.ExpenseCurrencyInput,
                            expenseInputName,
                          ])}
                          interactionKey={
                            expensesInteractionEventKeys[field_name]
                          }
                        />
                        <FormSelectV2
                          name={frequencyInputName}
                          items={frequencyOptions}
                          label="Frequency"
                          sx={{
                            ml: '$16',
                            minWidth: 'frequencyInput',
                            flex: 1,
                          }}
                        />
                      </View>
                      <Text
                        variant="caption"
                        sx={{ mt: '$8' }}
                        testID={makeTestId([
                          TestID.ExpensesForm.GuideText,
                          expenseInputName,
                        ])}
                      >
                        {guideText}
                      </Text>
                      <FormikFormError
                        name={expenseInputName}
                        sx={{ mt: '$8' }}
                      />
                      <FormikFormError
                        name={frequencyInputName}
                        sx={{ mt: '$8' }}
                      />
                    </Fragment>
                  );
                }
                return null;
              })}
          <Separator mt="l" mb="s" />
          <Text
            variant="lNumber"
            testID={TestID.ExpensesForm.TotalMonthlyExpensesAmount}
          >
            {formatCurrency(
              calculateFormTotalMonthlyExpenses(
                formProps.values,
                livingSituation as Living_Situation_Input_Enum,
              ),
              {
                withFractionOnRoundedAmount: true,
              },
            )}
          </Text>
          <Text variant="sHeader">{t('Content.ExpensesForm.PerMonth')}</Text>
          <Button
            label={t('Content.Common.ButtonLabel.Done')}
            testID={TestID.ExpensesForm.DoneButton}
            onPress={() => formProps.handleSubmit()}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
            alignSelf="stretch"
            mt="xl"
            style={{
              paddingVertical: 0,
            }}
          />
          {showDeleteButton ? (
            <Button
              label={t('Content.ExpensesForm.DeleteHousehold')}
              testID={TestID.ExpensesForm.DeleteHousehold}
              onPress={onDeleteHousehold}
              tertiary
              showSpinner={isSubmitting}
              disabled={isSubmitting}
              fontWeight="normal"
              alignSelf="stretch"
              mt="s"
              style={{
                paddingVertical: 0,
              }}
            />
          ) : null}
        </View>
      )}
    </Formik>
  );
}
