import { isLast } from '../../utils/arrayHelpers';
import { Props as SelectionRowProps, SelectionRow } from './SelectionRow';
import { FormikControlProps, PickerOption } from './types';

export type SelectionRowOption<Value> = PickerOption<Value> & {
  subPickerName?: string;
  caption?: string;
};

export type SelectionRowOptions<Value> = Array<SelectionRowOption<Value>>;

export type MultiSelectProps<Value> = {
  isMultiselect: true;
  maxSelection?: number;
} & FormikControlProps<Array<Value>>;

export type SingleSelectProps<Value> = {
  isMultiselect?: false;
} & FormikControlProps<Value>;

export type SelectionRowValueProps<Value> =
  | MultiSelectProps<Value>
  | SingleSelectProps<Value>;
export type BaseProps<Value> = {
  options: SelectionRowOptions<Value>;
  groupTestId: string;
  last?: boolean;
};
export type Props<Value> = Pick<SelectionRowProps, 'tickPosition'> &
  SelectionRowValueProps<Value> &
  BaseProps<Value> & { disabled?: boolean; onPressCheckmark?: () => void };

const SpaceSeparatorRegex = / /g;
export function generateSelectionRowTestID(groupTestId: string, label: string) {
  return `${groupTestId}-${label.replace(SpaceSeparatorRegex, '-')}`;
}

export function SelectionRowGroup<Value = string>(otherProps: Props<Value>) {
  const {
    options,
    last,
    name,
    tickPosition,
    disabled: groupDisabled,
    groupTestId,
    onPressCheckmark,
  } = otherProps;
  /* eslint-disable react/destructuring-assignment */
  const selectedValues = otherProps.isMultiselect
    ? new Set(otherProps.value)
    : undefined;
  return (
    <>
      {options.map(
        (
          { label, value, subPickerName, disabled: optionDisabled, caption },
          index,
        ) => {
          const isLastOptions = isLast(options, index);

          return (
            <SelectionRow
              testID={generateSelectionRowTestID(groupTestId, label)}
              tickPosition={tickPosition}
              label={label}
              key={String(value)}
              caption={caption}
              onPress={() => {
                onPressCheckmark?.();
                if (!otherProps.isMultiselect) {
                  // Single-select
                  otherProps.setFieldValue?.(value);
                  return;
                }
                // Multi-select but with maximum of one
                if (otherProps.maxSelection === 1) {
                  selectedValues?.clear();
                  selectedValues?.add(value);
                  otherProps.setFieldValue?.([value]);
                  return;
                }
                // Multi-select
                if (selectedValues?.has(value)) {
                  selectedValues.delete(value);
                } else {
                  // If maxSelection props is defined, check the size
                  if (
                    selectedValues &&
                    otherProps.maxSelection &&
                    selectedValues.size >= otherProps.maxSelection
                  ) {
                    return;
                  }
                  selectedValues?.add(value);
                }
                otherProps.setFieldValue?.([
                  ...(selectedValues?.values() ?? []),
                ]);
              }}
              selected={Boolean(
                otherProps.isMultiselect
                  ? selectedValues?.has(value)
                  : value === otherProps.value,
              )}
              last={isLastOptions ? last : false}
              subPickerName={subPickerName}
              name={name}
              disabled={optionDisabled || groupDisabled}
            />
          );
        },
      )}
    </>
  );
}

export function MultiSelectionRowGroup<Value = string>(
  props: Omit<MultiSelectProps<Value>, 'isMultiselect'> & BaseProps<Value>,
) {
  return <SelectionRowGroup isMultiselect {...props} />;
}

export function SingleSelectionRowGroup<Value = string>(
  props: Omit<SingleSelectProps<Value>, 'isMultiselect'> & BaseProps<Value>,
) {
  return <SelectionRowGroup isMultiselect={false} {...props} />;
}
