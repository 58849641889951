import { Field } from 'formik';
import * as React from 'react';

import { ListRow, Props as ListRowProps } from '../../ui/molecules/ListRow';
import { TermPicker } from './TermPicker';
import { FieldArgs } from './types';

type BaseSelectionRowProps = ListRowProps & {
  selected: boolean;
  tickPosition?: 'left' | 'right';
};

export type Props = BaseSelectionRowProps & {
  label: string;
  subPickerName?: string;
  name?: string;
};

function BaseSelectionRow({
  selected,
  tickPosition = 'left',
  ...otherProps
}: BaseSelectionRowProps) {
  const iconColor: ListRowProps['iconColor'] = selected
    ? 'link'
    : 'transparent';

  const iconOpacity = selected ? 1 : 0;
  const icon =
    tickPosition === 'left'
      ? {
          iconName: 'tick',
          iconFamilyName: 'svg' as const,
          iconSize: 's' as const,
          iconColor,
          iconOpacity,
        }
      : {
          rightIconName: 'tick',
          rightIconFamilyName: 'svg' as const,
          iconSize: 's' as const,
          rightIconColor: iconColor,
          rightIconOpacity: iconOpacity,
        };
  return <ListRow {...otherProps} {...icon} useArrow={false} color="accent" />;
}

export const SelectionRow: React.FC<Props> = ({
  selected,
  label,
  onPress,
  subPickerName,
  name,
  caption: captionProp,
  ...otherProps
}) => {
  if (subPickerName == null) {
    return (
      <BaseSelectionRow
        {...otherProps}
        caption={captionProp}
        selected={selected}
        onPress={onPress}
        label={label}
      />
    );
  }
  return (
    <Field name={subPickerName}>
      {({
        form: { setFieldValue: setSubPickerValue },
        field: { value: subPickerValue },
      }: FieldArgs<Props, number>) => {
        const setFieldValue = (value: number) =>
          subPickerName && setSubPickerValue(subPickerName, value);

        return (
          <TermPicker
            onPress={onPress}
            setFieldValue={setFieldValue}
            value={subPickerValue}
            collapsed={!selected}
            name={subPickerName}
            parentCollapsibleFieldName={name}
          >
            {({ selectedLabel, isPickerOpened, onPress: onRowPress }) => {
              const caption = selectedLabel || captionProp;
              return (
                <BaseSelectionRow
                  {...otherProps}
                  selected={selected}
                  onPress={onRowPress}
                  last={isPickerOpened ? false : otherProps.last}
                  label={label}
                  caption={caption}
                  rightIconName={
                    subPickerName ? 'information-circle-outline' : undefined
                  }
                />
              );
            }}
          </TermPicker>
        );
      }}
    </Field>
  );
};
