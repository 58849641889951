import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const SupportFilledIcon = ({ color, size, ...props }: SvgIconProps) => {
  const theme = useTheme();
  const fill = color ?? theme.colors.buttonPrimaryBg;
  const width = resizeIconWidth(18, 18, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9 0.442627C4.02632 0.442627 0 3.80724 0 7.91955C0 10.0795 1.08947 11.9903 2.84211 13.3611V17.058L6.53684 15.1057C7.29474 15.2719 8.14737 15.3965 9 15.3965C13.9737 15.3965 18 12.0319 18 7.91955C18 3.80724 13.9737 0.442627 9 0.442627Z"
        fill={fill}
      />
    </Svg>
  );
};
