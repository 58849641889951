import { format } from 'date-fns';

import {
  AppAddressFormat,
  BorrowerFormContextValues,
  BorrowerFormV2ContextValues,
} from '../../components/form/types';
import {
  Country_Enum,
  Domain_Api_Address_Input,
  Gender_Enum,
  Id_Type_Enum,
  Manual_Address_Input,
  refetchGetFinancialDeclarationOptionsQuery,
  refetchGetLoanApplicantsQuery,
  State_Of_Issue_Enum,
  Update_Borrower_V2_Input,
  UpdateBorrowerV2MutationVariables,
  useUpdateBorrowerV2Mutation,
} from '../../generated/graphql';
import { parseEnumType } from '../../utils/ensureEnumType';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { assertUnreachable } from '../../utils/typesHelpers';
import { parseAddressInputForMutationAction } from '../../utils/validateAddressHelpers';
import {
  BorrowerForm,
  DEFAULT_CITIZENSHIP,
  manualMailingInputFieldNames,
  manualResidentialInputFieldNames,
  UseResidentialAddressAsMailingAddressYesNoEnum,
} from './borrowerFormUtil';

export type UpdateBorrower = Omit<
  BorrowerFormContextValues,
  | 'identityCheckResult'
  | 'loanApplicationId'
  | 'applicantRole'
  | 'isCurrentLoggedInApplicant'
>;

function transformOtherNamesIntoArray(otherNames: string | undefined) {
  return otherNames?.split(',').map((name) => name.trim());
}

export enum UpdateBorrowerError {
  MISSING_REQUIRED_VALUES = 'MISSING_REQUIRED_VALUES',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  FAIL_TO_UPDATE_BORROWER = 'FAIL_TO_UPDATE_BORROWER',
}

export function getUpdateBorrowerErrorMessage(error: UpdateBorrowerError) {
  switch (error) {
    case UpdateBorrowerError.MISSING_REQUIRED_VALUES:
      return t('Content.Borrower.YourInformation.Error.EmptyValues');
    case UpdateBorrowerError.INVALID_ADDRESS:
      return t('Content.Borrower.YourInformation.Error.InvalidAddress');
    case UpdateBorrowerError.FAIL_TO_UPDATE_BORROWER:
      return t('Content.Common.Error.FailUpdateBorrowerDetail');

    default:
      assertUnreachable(error);
      return t('Content.Common.Error.FailUpdateBorrowerDetail');
  }
}

function assignFormValuesToMailingAddressInput(
  values: BorrowerForm,
): AppAddressFormat | null {
  const { [manualMailingInputFieldNames.isManualInput]: isManualInput } =
    values;

  if (isManualInput) {
    return {
      isProvidedByUser: true,
      postcode: values[manualMailingInputFieldNames.postcode],
      street: values[manualMailingInputFieldNames.streetName],
      streetNo: values[manualMailingInputFieldNames.streetNo],
      unitNo: values[manualMailingInputFieldNames.unitNo] ?? null,
      state: values[manualMailingInputFieldNames.state],
      streetType: values[manualMailingInputFieldNames.streetType]?.value,
      suburb: values[manualMailingInputFieldNames.suburb],
    };
  }
  return values.mailingAddress;
}

function assignFormValuesToResidentialAddressInput(
  values: BorrowerForm,
): AppAddressFormat | null {
  const { [manualResidentialInputFieldNames.isManualInput]: isManualInput } =
    values;

  if (isManualInput) {
    return {
      isProvidedByUser: true,
      postcode: values[manualResidentialInputFieldNames.postcode],
      street: values[manualResidentialInputFieldNames.streetName],
      streetNo: values[manualResidentialInputFieldNames.streetNo],
      unitNo: values[manualResidentialInputFieldNames.unitNo] ?? null,
      state: values[manualResidentialInputFieldNames.state],
      streetType: values[manualResidentialInputFieldNames.streetType]?.value,
      suburb: values[manualResidentialInputFieldNames.suburb],
    };
  }
  return values.residentialAddress;
}

export type UpdateBorrowerV2 = Omit<
  BorrowerFormV2ContextValues,
  | 'identityCheckResult'
  | 'loanApplicationId'
  | 'applicantRole'
  | 'isCurrentLoggedInApplicant'
>;

export function useUpdateBorrower({
  loanApplicationId,
}: {
  loanApplicationId: string;
}) {
  const [updateBorrowerV2Mutation, { loading: updateBorrowerLoading }] =
    useUpdateBorrowerV2Mutation();

  const updateBorrower = async ({
    values,
    isManualKyc,
  }: {
    values: UpdateBorrowerV2;
    isManualKyc: boolean;
  }) => {
    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      licenceNo,
      stateOfIssue,
      gender,
      citizenship,
      otherNames,
      useResidentialAddressAsMailingAddress,
      applicantId,
      driverLicenceCardNo,
      passportNo,
      expiryDate,
      identityType = Id_Type_Enum.DriversLicence,
    } = values;

    const residentialAddress =
      assignFormValuesToResidentialAddressInput(values);
    const mailingAddress = assignFormValuesToMailingAddressInput(values);

    const parsedUseResidentialAddressAsMailingAddress =
      useResidentialAddressAsMailingAddress?.[0] ===
      UseResidentialAddressAsMailingAddressYesNoEnum.Yes;
    const parsedStateOfIssue = parseEnumType(State_Of_Issue_Enum, stateOfIssue);
    const parsedGender = parseEnumType(Gender_Enum, gender);
    const parsedCitizenship = citizenship
      ? parseEnumType(Country_Enum, citizenship)
      : DEFAULT_CITIZENSHIP;
    if (
      !dateOfBirth ||
      !residentialAddress ||
      // Check mailing address exist when not using residential as mailing
      (parsedUseResidentialAddressAsMailingAddress ? false : !mailingAddress) ||
      !applicantId ||
      (!parsedStateOfIssue && identityType === Id_Type_Enum.DriversLicence) ||
      !parsedGender ||
      !parsedCitizenship
    ) {
      return { data: null, error: UpdateBorrowerError.MISSING_REQUIRED_VALUES };
    }
    const {
      manualAddressInput: validatedManualResidentialAddressInput,
      domainApiAddressInput: validatedDomainApiResidentialAddressInput,
    } = parseAddressInputForMutationAction(residentialAddress);

    const residentialAddressData: Pick<
      Update_Borrower_V2_Input,
      | 'residential_address_provided_by_user'
      | 'domain_api_residential_address_input'
    > = {
      residential_address_provided_by_user:
        validatedManualResidentialAddressInput,
      domain_api_residential_address_input:
        validatedDomainApiResidentialAddressInput,
    };

    let mailingAddressData: {
      mailing_address_provided_by_user?: Manual_Address_Input | null;
      domain_api_mailing_address_input?: Domain_Api_Address_Input | null;
    } = {
      mailing_address_provided_by_user: undefined,
      domain_api_mailing_address_input: undefined,
    };
    if (mailingAddress && !parsedUseResidentialAddressAsMailingAddress) {
      const {
        manualAddressInput: validatedManualMailingAddressInput,
        domainApiAddressInput: validatedDomainApiMailingAddressInput,
      } = parseAddressInputForMutationAction(mailingAddress);

      mailingAddressData = {
        mailing_address_provided_by_user: validatedManualMailingAddressInput,
        domain_api_mailing_address_input: validatedDomainApiMailingAddressInput,
      };
    }

    if (
      !residentialAddressData.residential_address_provided_by_user &&
      !residentialAddressData.domain_api_residential_address_input
    ) {
      return { data: null, error: UpdateBorrowerError.INVALID_ADDRESS };
    }

    const commonVars = {
      first_name: firstName.trim(),
      middle_name: middleName ? middleName.trim() : undefined,
      last_name: lastName.trim(),
      date_of_birth: format(dateOfBirth, 'yyyy-MM-dd'),
      ...residentialAddressData,
      ...mailingAddressData,
      use_residential_address_as_mailing_address:
        parsedUseResidentialAddressAsMailingAddress,
      applicant_id: applicantId,
      gender: parsedGender,
      citizenships: [parsedCitizenship],
      other_names: transformOtherNamesIntoArray(otherNames ?? ''),
      is_manual_kyc: isManualKyc,
    };

    const fetchIdentityDetails = (idType: Id_Type_Enum) => {
      switch (idType) {
        case Id_Type_Enum.Passport:
          return {
            id_number: passportNo?.trim(),
            id_expiry: expiryDate ? format(expiryDate, 'yyyy-MM-dd') : '',
            id_type: idType,
          };
        case Id_Type_Enum.DriversLicence:
        default:
          return {
            id_number: licenceNo?.trim(),
            id_card_number: driverLicenceCardNo?.trim(),
            id_expiry: expiryDate ? format(expiryDate, 'yyyy-MM-dd') : '',
            id_type: idType,
            state_of_issue: parsedStateOfIssue,
          };
      }
    };

    const vars: UpdateBorrowerV2MutationVariables = {
      data: {
        ...commonVars,
        ...fetchIdentityDetails(identityType),
      },
    };

    const [res] = await safelyCallMutation(updateBorrowerV2Mutation, {
      variables: vars,
      refetchQueries: [
        refetchGetLoanApplicantsQuery({ loanApplicationId }),
        refetchGetFinancialDeclarationOptionsQuery({ loanApplicationId }),
      ],
      awaitRefetchQueries: true,
      context: {
        sentryContext: {
          loanApplicationId,
          applicantId,
        },
      },
    });

    if (res?.data?.update_borrower_v2 == null) {
      return {
        data: null,
        error: UpdateBorrowerError.FAIL_TO_UPDATE_BORROWER,
      };
    }

    return {
      data: res.data.update_borrower_v2,
      error: null,
    };
  };

  return {
    updateBorrower,
    updateBorrowerLoading,
  };
}
