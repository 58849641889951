import { useCallback } from 'react';

import { ErrorRow } from '../../components/ErrorRow';
import {
  AddExternalAccount,
  AddExternalAccountFormValues,
} from '../../ExternalAccount/components/AddExternalAccount';
import {
  AddExternalAccountFor,
  useAddExternalAccount,
} from '../../ExternalAccount/utils/useAddExternalAccount';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { HomeLoanScreenProps } from '../navigation/types';

type Props = HomeLoanScreenProps<Screen.HOME_LOAN_WITHDRAWAL_ADD_ACCOUNT>;

export function WithdrawAddAccount({ navigation, route }: Props) {
  const cbaAccountId = route.params?.cbaAccountId;
  const { addExternalAccount, addExternalAccountLoading, formError } =
    useAddExternalAccount({
      cbaAccountId,
      accountFor: AddExternalAccountFor.Withdrawal,
    });

  const handleSubmit = useCallback(
    async (newExternalAccount: AddExternalAccountFormValues) => {
      await addExternalAccount(newExternalAccount);

      navigation.navigate(Screen.HOME_LOAN_MODAL, {
        screen: Screen.HOME_LOAN_WITHDRAWAL,
        params: { cbaAccountId },
      });
    },
    [addExternalAccount, cbaAccountId, navigation],
  );

  const onClose = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t('Content.Withdraw.AccountAdd')}
      onClose={onClose}
      scrollable
      loading={addExternalAccountLoading}
    >
      <ErrorRow message={formError} m="m" mx={0} />
      <AddExternalAccount
        onSubmit={handleSubmit}
        isSubmitting={addExternalAccountLoading}
      />
    </ModalScreenContainer>
  );
}
