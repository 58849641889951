/**
 * LoanApplicationSection enum is used to group the screens in a loan application by their section.
 * Each loan application type will have these screens, but they will have different screen names.
 */
export enum LoanApplicationSection {
  Splash = 'Splash',
  Intro = 'Intro',
  Property = 'YourProperty',
  ReviewSummary = 'ReviewSummary',
  Borrowers = 'Borrowers',
  Income = 'Income',
  Expense = 'Expense',
  Debts = 'Debts',
  ConditionalApproval = 'ConditionalApproval', // Only available for Purchase
  YourUnloan = 'YourUnloan',
  InterstitialLoanSubmit = 'InterstitialLoanSubmit',
  VerifyIncomeOpenBanking = 'VerifyIncomeOpenBanking',
  Submit = 'Submit',
  Completed = 'Completed',
  YourUnloanOffer = 'YourUnloanOffer',
  YourUnloanOfferEsign = 'YourUnloanOfferEsign',
  VOICompleted = 'VerificationOfIdentityCompleted',
}
