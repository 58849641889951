/**
 * @module
 *
 * This module provide shared code between
 * web and native SDK for Google Maps API integration.
 *
 * The web and native should have common interface for the API
 * so the rest of the app can use it without touching platform
 * specific details.
 */

import { PropsWithChildren } from 'react';

import { googleMapsApiKey } from '../config/expo';
import { State_Enum } from '../generated/graphql';

export const GOOGLE_MAPS_API_KEY =
  // On web, variable that starts with `process.env` will be replaced by
  // the actual value during build time
  // using babel `transform-inline-environment-variables`.
  process.env.GOOGLE_MAPS_API_KEY || googleMapsApiKey;

/**
 * Represent individual possible suburb prediction given a search input.
 *
 * Description is a plain string intended for display only.
 * So details like suburb name itself needs to be retrieved from separate
 * API call using the placeId.
 */
export type SuburbPrediction = {
  description: string;
  placeId: string;
};

/**
 * Details of a suburb retrieved from Google Maps Places API.
 *
 * This intentionally does not contain place_id because the API
 * does not return it in the response.
 * Only the TS typing has it.
 */
export type SuburbDetails = {
  suburb: string | null;
  state: State_Enum | null;
  postcode: string | null;
};

export type GoogleMapsApiProviderProps = PropsWithChildren;

export type UseSuburbAutocompleteReturn = {
  getSuburbDetailsList: (rawInput: string) => Promise<SuburbDetails[]>;
  fetchSuburbDetails: (placeId: string) => Promise<SuburbDetails | null>;
  suburbDetailsList: SuburbDetails[];
  resetAutocompleteSessionToken: () => void;
};

/**
 * Format suburb details for display.
 * Example output: "Mascot NSW 2020"
 *
 * Consult with designer before changing this because we
 * also display this information outside the app,
 * e.g. property checker.
 */
export function formatSuburbDetails(suburbDetails: SuburbDetails): string {
  return [suburbDetails.suburb, suburbDetails.state, suburbDetails.postcode]
    .filter(Boolean)
    .join(' ');
}
