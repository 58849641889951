import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType } from '../../navigation/types/screens';
import { Spinner } from '../../ui/atoms/Spinner';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type CaConfirmationActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.CA_CONFIRMATION>;

export function CaConfirmationActionSheet({
  navigation,
  route,
}: CaConfirmationActionSheetProps) {
  const loanApplicationId = route.params?.loanApplicationId;

  const {
    navigateToNextLoanApplicationScreen,
    loadingLoanApplicationMetadata,
  } = useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onContinuePress = () => {
    navigateToNextLoanApplicationScreen({
      currentSection: LoanApplicationSection.ConditionalApproval,
    });
  };

  const goBack = () => navigation.goBack();

  const commonActionProp = {
    disabled: loadingLoanApplicationMetadata,
    secondary: true,
    flex: 1,
  };

  return (
    <ActionSheet
      closeModal={goBack}
      showCloseButton={false}
      title={t('Content.CaConfirmationActionSheet.Title')}
      isRowButton
      actionList={[
        {
          ...commonActionProp,
          label: t('Content.Common.ButtonLabel.Continue'),
          onPress: onContinuePress,
        },
        {
          ...commonActionProp,
          label: t('Content.Common.ButtonLabel.Cancel'),
          onPress: goBack,
        },
      ]}
    >
      {loadingLoanApplicationMetadata ? (
        <Spinner />
      ) : (
        <ActionSheet.MessageText pt="m">
          {t('Content.CaConfirmationActionSheet.Message')}
        </ActionSheet.MessageText>
      )}
    </ActionSheet>
  );
}
