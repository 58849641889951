import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export const BorrowersIcon: React.FC<SvgIconProps> = ({
  size,
  color,
  ...props
}) => {
  const idPrefix = useSvgIdPrefix('BorrowersIcon');
  return (
    <Svg width={45} height={45} viewBox="0 0 45 45" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42383 19.6875V37.5V40.3125C5.42383 41.2641 6.12058 41.2585 7.02425 41.2513C7.10436 41.2507 7.18611 41.25 7.26913 41.25H36.794C36.8771 41.25 36.9588 41.2507 37.0389 41.2513C37.9426 41.2585 38.6393 41.2641 38.6393 40.3125V37.5V19.6875L22.0316 3.75L5.42383 19.6875Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        opacity={0.05}
        d="M22.0316 4.6875C21.5942 4.6875 21.1569 4.845 20.8081 5.15906L15.8636 9.66937L5.42383 19.6875V24.7406L22.0316 9.77625L38.6393 24.7406V19.6875L28.1995 9.66937L23.255 5.15906C22.9063 4.845 22.4689 4.6875 22.0316 4.6875Z"
        fill="#2C2C2C"
      />
      <Path
        d="M40.941 19.2253L23.2547 3.28406C22.9059 2.97 22.4686 2.8125 22.0312 2.8125C21.5939 2.8125 21.1566 2.97 20.8078 3.28406L3.12148 19.2253C2.73397 19.5759 2.70721 20.1816 3.06243 20.5659L4.57189 22.2019C4.91235 22.5712 5.47978 22.5994 5.85438 22.2647L22.0312 7.90125L38.2081 22.2656C38.5818 22.5994 39.1502 22.5722 39.4906 22.2028L41.0001 20.5669C41.3553 20.1816 41.3285 19.5759 40.941 19.2253Z"
        fill="#A3A3A3"
      />
      <Path
        d="M28.125 33.75C30.7138 33.75 32.8125 31.6513 32.8125 29.0625C32.8125 26.4737 30.7138 24.375 28.125 24.375C25.5362 24.375 23.4375 26.4737 23.4375 29.0625C23.4375 31.6513 25.5362 33.75 28.125 33.75Z"
        fill="#A3A3A3"
      />
      <Path
        d="M20.625 41.25H35.625C34.848 37.4923 31.7853 34.6875 28.125 34.6875C24.4647 34.6875 21.402 37.4923 20.625 41.25Z"
        fill="#A3A3A3"
      />
      <Path
        d="M16.875 30C19.4638 30 21.5625 27.9013 21.5625 25.3125C21.5625 22.7237 19.4638 20.625 16.875 20.625C14.2862 20.625 12.1875 22.7237 12.1875 25.3125C12.1875 27.9013 14.2862 30 16.875 30Z"
        fill="#2C2C2C"
      />
      <Path
        d="M8.4375 41.25H25.3125V40.3125C25.3125 35.1347 21.535 30.9375 16.875 30.9375C12.215 30.9375 8.4375 35.1347 8.4375 40.3125V41.25Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={5.42383}
          y1={3.75}
          x2={42.6517}
          y2={36.7223}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
