import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { RouteProp } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import {
  AppSummaryScreen,
  PreviousAppSummaryScreenState,
} from '../../recoil/PreviousAppSummaryScreenState';

/** Use this hook on screen / modal that can go back
 * to ReviewApplication or ApplicationDetails modal
 */
export function useAppSummaryScreenNavigation({
  navigation,
  route,
  loanApplicationId,
}: {
  navigation: NavigationProp<ParamListBase>;
  route: RouteProp<ParamListBase>;
  loanApplicationId?: string | undefined;
}) {
  const [previousAppSummaryScreenState, setPreviousAppSummaryScreenState] =
    useRecoilState(PreviousAppSummaryScreenState);

  const { loanApplicationType, navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const shouldBackToAppSummary = previousAppSummaryScreenState != null;

  const tryNavigateBackToSummary = useCallback(
    (defaultNavigateFn: () => void, forceDefaultNavigate?: boolean) => {
      if (forceDefaultNavigate) {
        setPreviousAppSummaryScreenState(null);
        defaultNavigateFn();
        return;
      }

      switch (previousAppSummaryScreenState) {
        case AppSummaryScreen.ReviewSummary: {
          navigateToLoanApplicationScreen({
            section: LoanApplicationSection.ReviewSummary,
          });
          return;
        }
        case AppSummaryScreen.ConditionalApprovalAppDetails: {
          navigation.navigate(Screen.CONDITIONAL_APPROVAL_MODAL, {
            screen: Screen.CONDITIONAL_APPROVAL_APP_DETAILS,
            params: { loanApplicationId },
          });
          return;
        }
        default: {
          defaultNavigateFn();
        }
      }
    },
    [
      loanApplicationId,
      navigateToLoanApplicationScreen,
      navigation,
      previousAppSummaryScreenState,
      setPreviousAppSummaryScreenState,
    ],
  );

  return {
    loanApplicationType,
    tryNavigateBackToSummary,
    shouldBackToAppSummary,
  };
}
