import { PickerOptions } from '../components/form/types';
import { isNotNullOrUndefined } from './arrayHelpers';
import { pluralize } from './stringHelpers';

export const MAX_YEAR = 40;
export const MAX_MONTH = 11;
export const MONTHS_IN_A_YEAR = 12;

/**
 * Generate year term options.
 * @param startFromZero List will contain 0 - MAX_YEAR year options when this sets to true
 * @param maxYear Maximum year of loan. Defaults to 30
 * @returns List of year term option (value **in months**)
 */

export function generateYearTermOptions({
  startFromZero = false,
  maxYear = MAX_YEAR,
} = {}): PickerOptions<number> {
  const years = startFromZero ? maxYear + 1 : maxYear;
  return Array.from(Array(years).keys()).map((year) => {
    const currentYear = startFromZero ? year : year + 1;
    return {
      label: pluralize(currentYear, t('Content.Common.Frequency.Year')),
      value: getTermInMonth(currentYear),
    };
  });
}

/**
 * Generate month term options for Term Picker
 * @param startFromZero List will contain 0 - MAX_MONTH month options when this param sets to true
 * @param maxMonth Maximum month that wants to be generated. Defaults to 12
 * @returns List of month term option
 */
export function generateMonthTermOptions({
  startFromZero = true,
  maxMonth = MAX_MONTH,
} = {}): PickerOptions<number> {
  const months = startFromZero ? maxMonth + 1 : maxMonth;

  return Array.from(Array(months).keys()).map((month) => {
    const currentMonth = startFromZero ? month : month + 1;
    return {
      label: pluralize(currentMonth, t('Content.Common.Frequency.Month')),
      value: currentMonth,
    };
  });
}

export function generateDependentsCountOptions({
  maxCount = 10,
} = {}): PickerOptions<number> {
  return Array.from(Array(maxCount).keys()).map((count) => ({
    label: String(count),
    value: count,
  }));
}

export function getTermPickerLabel(
  termInMonths: number | undefined,
  placeholder = '',
) {
  if (termInMonths == null) {
    return placeholder;
  }
  const { yearInMonths, remainderMonths } = extractTermInMonths(termInMonths);
  return [
    yearInMonths
      ? pluralize(yearInMonths / 12, t('Content.Common.Frequency.Year'))
      : null,
    remainderMonths
      ? pluralize(remainderMonths, t('Content.Common.Frequency.Month'))
      : null,
  ]
    .filter(isNotNullOrUndefined)
    .join(', ');
}

function getTermInMonth(termInYear: number) {
  return termInYear * 12;
}

/**
 * Separate `termInMonths` into year value (in months) & month value
 * @param termInMonths
 * @returns [yearInMonths, months]
 */
export function extractTermInMonths(termInMonths: number | undefined) {
  if (termInMonths == null) {
    return { yearInMonths: 0, remainderMonths: 0 };
  }
  const month = termInMonths % 12;
  return { yearInMonths: termInMonths - month, remainderMonths: month };
}
