import { InputRow } from '@unloan/common-ui';
import { useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Skeleton } from './Skeleton';

export function LoadingInput() {
  const { theme } = useDripsyTheme();

  return (
    <InputRow sx={{ borderColor: theme.colors.$inputBackground, px: '$16' }}>
      <View testID={TestID.LoadingInput.Skeleton} sx={{ mb: '$4' }}>
        <Skeleton show width="25%" height={theme.space.$14} />
      </View>
      <View testID={TestID.LoadingInput.Skeleton}>
        <Skeleton show width="45%" height={theme.space.$20} />
      </View>
    </InputRow>
  );
}
