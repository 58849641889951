import {
  AppAddressFormat,
  PickerOption,
  yupAddress,
} from '../../components/form/types';
import { MinValue } from '../../constants/fieldRules';
import {
  Frequency_Enum,
  Property_Purpose_Enum,
  Property_Type_Enum,
  State_Enum,
} from '../../generated/graphql';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames,
} from '../../ui/v2/ManualPropertyInputFields';
import { yup } from '../../utils/yup';
import { DEFAULT_PROPERTY_DETAILS_REFI_INITIAL_VALUES } from './propertyDetailsFormUtils';

export enum PropertyDetailsPurchaseFormFields {
  PropertyPurpose = 'propertyPurpose',
  PropertyType = 'propertyType',
  PropertyValue = 'propertyValue',
  AddressResult = 'addressResult',
  // Purchase only
  FirstTimeHomeBuyer = 'firstTimeHomeBuyer',
  ExpectedRentalIncome = 'expectedRentalIncome',
  ExpectedRentalIncomeFrequency = 'expectedRentalIncomeFrequency',
  ExpectedRentalExpenses = 'expectedRentalExpenses',
  ExpectedRentalExpensesFrequency = 'expectedRentalExpensesFrequency',
}

export enum PropertyYesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export const propertyDetailsPurchaseManualAddressPrefix =
  'propertyDetailsPurchaseManualAddressPrefix';
export const propertyDetailsPurchaseManualInputFieldNames =
  getManualInputFieldNames(propertyDetailsPurchaseManualAddressPrefix);

export const getPropertyDetailsPurchaseFormSchema = () =>
  yup
    .object({
      [PropertyDetailsPurchaseFormFields.AddressResult]: yup
        .object()
        .when(propertyDetailsPurchaseManualInputFieldNames.isManualInput, {
          is: true,
          then: yup.object().nullable(),
          otherwise: yupAddress().nullable().required(),
        }),
      [PropertyDetailsPurchaseFormFields.PropertyType]: yup
        .string()
        .nullable()
        .required(t('Content.PropertyDetails.Form.PropertyTypeError')),
      [PropertyDetailsPurchaseFormFields.PropertyValue]: yup
        .number()
        .nullable()
        .required(
          t('Content.PropertyDetails.PurchaseForm.PurchasePriceRequiredError'),
        )
        .min(
          MinValue.money,
          t('Content.PropertyDetails.PurchaseForm.PurchasePriceMinError', {
            value: MinValue.money,
          }),
        ),
      [PropertyDetailsPurchaseFormFields.PropertyPurpose]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
      [PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer]: yup
        .string()
        .when(PropertyDetailsPurchaseFormFields.PropertyPurpose, {
          is: Property_Purpose_Enum.LivingIn,
          then: yup
            .string()
            .nullable()
            .required(t('Content.Common.Error.PleaseSelectAnOption')),
          otherwise: yup.string().nullable(),
        }),
      [PropertyDetailsPurchaseFormFields.ExpectedRentalIncome]: yup
        .number()
        .when(PropertyDetailsPurchaseFormFields.PropertyPurpose, {
          is: Property_Purpose_Enum.Investing,
          then: yup
            .number()
            .nullable()
            .required(t('Content.YourIncome.Form.RentalIncomeError')),
          otherwise: yup.number().nullable(),
        }),
      [PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency]: yup
        .string()
        .when(PropertyDetailsPurchaseFormFields.PropertyPurpose, {
          is: Property_Purpose_Enum.Investing,
          then: yup
            .string()
            .nullable()
            .required(t('Content.Common.Error.PleaseSelectAnOption')),
          otherwise: yup.string().nullable(),
        }),
      [PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses]: yup
        .number()
        .when(PropertyDetailsPurchaseFormFields.PropertyPurpose, {
          is: Property_Purpose_Enum.Investing,
          then: yup
            .number()
            .nullable()
            .required(t('Content.YourIncome.Form.RentalExpensesError')),
          otherwise: yup.number().nullable(),
        }),
      [PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency]: yup
        .string()
        .when(PropertyDetailsPurchaseFormFields.PropertyPurpose, {
          is: Property_Purpose_Enum.Investing,
          then: yup
            .string()
            .nullable()
            .required(t('Content.Common.Error.PleaseSelectAnOption')),
          otherwise: yup.string().nullable(),
        }),
    })
    // Append with manual address input fields
    .concat(
      getManualAddressYupSchema(propertyDetailsPurchaseManualAddressPrefix),
    );

export type PropertyDetailsPurchaseFormValue = {
  [PropertyDetailsPurchaseFormFields.AddressResult]:
    | AppAddressFormat
    | undefined;
  [PropertyDetailsPurchaseFormFields.PropertyType]:
    | Property_Type_Enum
    | undefined;
  [PropertyDetailsPurchaseFormFields.PropertyValue]: number | undefined;
  [PropertyDetailsPurchaseFormFields.PropertyPurpose]:
    | Property_Purpose_Enum
    | undefined;

  // Living in only
  [PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer]:
    | PropertyYesNoEnum
    | undefined;

  // Investing only
  [PropertyDetailsPurchaseFormFields.ExpectedRentalIncome]: number | undefined;
  [PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency]:
    | Frequency_Enum
    | undefined;
  [PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses]:
    | number
    | undefined;
  [PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency]:
    | Frequency_Enum
    | undefined;

  // Manual address input values
  [propertyDetailsPurchaseManualInputFieldNames.isManualInput]:
    | boolean
    | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.postcode]: string | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.state]: State_Enum | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.streetName]: string | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.streetNo]: string | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.streetType]:
    | PickerOption<string>
    | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.suburb]: string | undefined;
  [propertyDetailsPurchaseManualInputFieldNames.unitNo]: string | undefined;
};

export const DEFAULT_PROPERTY_DETAILS_PURCHASE_INITIAL_VALUES: PropertyDetailsPurchaseFormValue =
  {
    ...DEFAULT_PROPERTY_DETAILS_REFI_INITIAL_VALUES,
    [PropertyDetailsPurchaseFormFields.FirstTimeHomeBuyer]: undefined,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalIncome]: undefined,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalIncomeFrequency]:
      Frequency_Enum.Monthly,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalExpenses]: undefined,
    [PropertyDetailsPurchaseFormFields.ExpectedRentalExpensesFrequency]:
      Frequency_Enum.Monthly,

    // Manual address input values
    [propertyDetailsPurchaseManualInputFieldNames.isManualInput]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.postcode]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.state]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetName]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetNo]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.streetType]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.suburb]: undefined,
    [propertyDetailsPurchaseManualInputFieldNames.unitNo]: undefined,
  };
