import { isObject } from 'formik';

import { FieldValidationError } from '../../../utils/yup';
import { BorrowerDetailsEditField, InviteCoBorrowerField } from './forms';

export const buildInviteCoborrowerErrorMessage = (
  name: InviteCoBorrowerField,
  errorCode: string,
) => {
  switch (errorCode) {
    case FieldValidationError.Required:
      switch (name) {
        case InviteCoBorrowerField.Email:
          return t('Content.InviteCoBorrower.Error.EmailRequired');
        case InviteCoBorrowerField.FirstName:
          return t('Content.InviteCoBorrower.Error.FirstNameRequired');
        case InviteCoBorrowerField.LastName:
          return t('Content.InviteCoBorrower.Error.SurnameRequired');
        case InviteCoBorrowerField.Mobile:
          return t('Content.InviteCoBorrower.Error.MobileNumberRequired');
        default:
          return null;
      }
    case FieldValidationError.InvalidName:
      switch (name) {
        case InviteCoBorrowerField.FirstName:
          return t('Content.InviteCoBorrower.Error.FirstNameInvalid');
        case InviteCoBorrowerField.LastName:
          return t('Content.InviteCoBorrower.Error.SurnameInvalid');
        default:
          return null;
      }
    case FieldValidationError.InvalidEmail:
      return t('Content.InviteCoBorrower.Error.EmailInvalid');
    case FieldValidationError.InvalidPhoneNumber:
      return t('Content.InviteCoBorrower.Error.MobileNumberInvalid');
    // Some error code returned by mutation are transformed to error
    // message outside formik and assigned to the field error directly,
    // hence the need to check for the error message instead of the error code.
    case t('Content.InviteCoBorrower.Error.DuplicateEmail'):
      return t('Content.InviteCoBorrower.Error.DuplicateEmail');
    case t('Content.InviteCoBorrower.Error.DuplicateNumber'):
      return t('Content.InviteCoBorrower.Error.DuplicateNumber');
    default:
      return null;
  }
};

export const buildBorrowerDetailsEditErrorMessage = (
  _name: BorrowerDetailsEditField,
  error: string,
) => {
  // Address error code is contained within the props
  let errorCode = error;
  if (isObject(error)) {
    const [_, value] = Object.entries(error)?.[0] ?? ['', ''];
    errorCode = value;
  }

  switch (errorCode) {
    case FieldValidationError.Required:
      return t('Content.Borrower.DetailsForm.Error.MailingAddressRequired');
    default:
      return null;
  }
};
