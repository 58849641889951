import { Text } from 'dripsy';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import {
  Loan_Variation_Type_Input_Enum,
  useCreateLoanVariationRequestMutation,
} from '../../generated/graphql';
import { ReduceRepaymentsAtomFamily } from '../../LoanVariation/utils/reduceRepaymentsAtom';
import { ActionSheetType } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

export type Props =
  ActionSheetScreenProps<ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES>;

export const ReduceRepaymentsConfirmationActionSheet = ({
  navigation,
  route,
}: Props) => {
  const { cbaAccountId, otherBorrowersNames } = route.params;
  const { redrawReduced } = useRecoilValue(
    ReduceRepaymentsAtomFamily(cbaAccountId),
  );

  const [
    createLoanVariation,
    { loading: createLoanVariationLoading, error: createLoanVariationError },
  ] = useCreateLoanVariationRequestMutation();

  const onPress = useCallback(async () => {
    const [response, error] = await safelyCallMutation(createLoanVariation, {
      variables: {
        amount: redrawReduced,
        cbaAccountId,
        type: Loan_Variation_Type_Input_Enum.ReduceAmountPerInstallment,
      },
      context: {
        sentryContext: {
          cbaAccountId,
          description:
            'Loan Variation - Reduce Amount Per Installment Mutation',
        },
      },
    });

    if (!error && !!response.data) {
      navigation.replace(ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED, {
        cbaAccountId,
        otherBorrowersNames,
      });
    }
  }, [
    cbaAccountId,
    redrawReduced,
    createLoanVariation,
    navigation,
    otherBorrowersNames,
  ]);

  const invalidAmountError =
    redrawReduced < 1
      ? (t(
          'Content.LoanVariation.ReduceRepaymentConfirmation.InvalidRedrawAmount',
        ) as string)
      : undefined;

  const mutationError = createLoanVariationError
    ? (t(
        'Content.LoanVariation.ReduceRepaymentConfirmation.UnableToProcessRequest',
      ) as string)
    : undefined;

  const errorMessage = mutationError ?? invalidAmountError;

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      showCloseButton={false}
      isRowButton
      errorMessage={errorMessage}
      title={t('Content.LoanVariation.ReduceRepaymentConfirmation.Title')}
      actionList={[
        {
          label: t('Content.Common.ButtonLabel.Cancel'),
          disabled: createLoanVariationLoading,
          testID: TestID.LoanVariation.ConfirmRequest.CancelButton,
          onPress: navigation.goBack,
          secondary: true,
          flex: 1,
        },
        {
          label: t('Content.Common.ButtonLabel.Confirm'),
          testID: TestID.LoanVariation.ConfirmRequest.ConfirmButton,
          showSpinner: createLoanVariationLoading,
          disabled: !!errorMessage || createLoanVariationLoading,
          onPress,
          secondary: true,
          flex: 1,
        },
      ]}
    >
      <Text
        variant="body"
        sx={{ py: '$8', px: '$16', mb: '$16', textAlign: 'center' }}
      >
        {t('Content.LoanVariation.ReduceRepaymentConfirmation.WarningText')}
      </Text>
    </ActionSheet>
  );
};
