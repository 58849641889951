import { memo, useCallback } from 'react';

import {
  Cb_LoanState,
  useGetLoanAccountDetailsForActiveLoanCardQuery,
} from '../../generated/graphql';
import { Props as CardProps } from '../../ui/atoms/Card';
import { DashboardLoadingLoanCard } from './DashboardLoadingLoanCard';
import { PreparingForSettlementLoanCard } from './InactiveLoanCard';
import { LoanCard } from './LoanCard';

type Props = Omit<CardProps, 'onPress'> & {
  onPress: (cbaAccountId: string) => void;
  cbaAccountId: string;
};

const inactiveLoanDataValues = {
  availableRedrawBalance: 0,
  balance: 0,
  principalBalance: 0,
};

export const ActiveLoanCard = memo<Props>(
  ({ cbaAccountId, onPress, ...otherProps }) => {
    const { data, loading, error } =
      useGetLoanAccountDetailsForActiveLoanCardQuery({
        variables: { cbaAccountId },
        context: {
          sentryContext: {
            cbaAccountId,
            description: 'Get loan details for active loan card',
          },
        },
      });

    const onCardPress = useCallback(() => {
      onPress(cbaAccountId);
    }, [cbaAccountId, onPress]);

    if (loading) return <DashboardLoadingLoanCard width="100%" mb="l" />;

    const loanAccount = data?.loan_account_by_id;

    if (!!error || !loanAccount) {
      return (
        <LoanCard
          active={false}
          loanData={inactiveLoanDataValues}
          displayName=""
          showError
          {...otherProps}
        />
      );
    }

    const isActive = loanAccount.state?.account_state === Cb_LoanState.Active;
    const displayName =
      loanAccount.loan_application_target?.loan_application_securities[0]
        ?.property.address.short_address_format ??
      loanAccount?.settings?.name ??
      '';

    const principalBalance = loanAccount.balances?.principal_balance ?? 0;
    const balance = loanAccount.balances?.balance ?? 0;
    const availableRedrawBalance =
      loanAccount.balances?.available_redraw_balance ?? 0;

    const loanDataValues = {
      principalBalance,
      balance,
      availableRedrawBalance,
    };

    const loanData = !isActive ? inactiveLoanDataValues : loanDataValues;

    if (!isActive) {
      return (
        <PreparingForSettlementLoanCard
          {...otherProps}
          displayName={displayName}
          loanData={loanData}
        />
      );
    }

    // Active loan card
    return (
      <LoanCard
        {...otherProps}
        active
        displayName={displayName}
        loanData={loanData}
        onPress={onCardPress}
      />
    );
  },
);
