import { SxProp, useSx } from 'dripsy';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button, Props as ButtonProps } from '../../ui/atoms/Button';
import { useTheme } from '../../ui/theme';

export type SubmitButtonProps = Pick<
  ButtonProps,
  'onPress' | 'disabled' | 'showSpinner' | 'mt' | 'label' | 'testID'
> & { sx?: SxProp };

export function SubmitButton({
  label = t('Content.Common.ButtonLabel.Submit'),
  sx: sxProp = {},
  ...props
}: SubmitButtonProps) {
  const { bottom: bottomInset } = useSafeAreaInsets();
  const theme = useTheme();

  const sx = useSx();

  return (
    <Button
      {...props}
      label={label}
      py="m"
      style={[
        {
          marginBottom: bottomInset + theme.spacing.l,
          // This maxWidth: 100% is required to unset the maxWidth applied to the Button
          maxWidth: '100%',
          width: '100%',
        },
        sx(sxProp),
      ]}
    />
  );
}
