import { Frequency_Enum, Frequency_Input_Enum } from '../generated/graphql';

const CURRENCY_REGEX = /(\d)(?=(\d{3})+(?!\d))/g;
const DOLLAR_SIGN_REGEX = /\$|,/g;
const NON_DIGIT_REGEX = /\D/g;
const NON_NUMERIC_LETTERS_REGEX = /[^\d.]/g;

export function formatCurrency(
  amount: number | undefined | null,
  options?: {
    noFraction?: boolean;
    withFractionOnRoundedAmount?: boolean;
    displayInThousand?: boolean;
    displayInMillion?: boolean;
  },
): string {
  const {
    noFraction,
    withFractionOnRoundedAmount,
    displayInThousand,
    displayInMillion,
  } = options ?? {};
  let currencyAmount = Number(amount || 0);
  let amountStr;
  if (noFraction) {
    currencyAmount = Math.ceil(currencyAmount);
    amountStr = `${currencyAmount}`;
  } else {
    const isInteger = Math.round(currencyAmount) === currencyAmount;
    if (isInteger && !withFractionOnRoundedAmount) {
      // 2000 stays as "2000" and doesn't become "2000.00"
      amountStr = `${currencyAmount}`;
    } else {
      // 2000.15 becomes "2000.15" and with the occasional
      // JS floating point fun 2000.1499998 becomes "2000.15"
      amountStr = currencyAmount.toFixed(2);
    }
  }

  if (displayInMillion && currencyAmount >= 1000000) {
    return `$${(currencyAmount / 1000000).toFixed(2)}M`;
  }

  if (displayInThousand && currencyAmount >= 10000) {
    return `$${(currencyAmount / 1000).toFixed(1)}K`;
  }

  return `$${amountStr.replace(CURRENCY_REGEX, '$1,')}`;
}

const FREQUENCY_LABEL = {
  [Frequency_Enum.Annual]: t('Content.Common.Frequency.Year'),
  [Frequency_Enum.Monthly]: t('Content.Common.Frequency.Month'),
  [Frequency_Enum.Quarterly]: t('Content.Common.Frequency.Quarter'),
  [Frequency_Enum.Weekly]: t('Content.Common.Frequency.Week'),
  [Frequency_Enum.Fortnightly]: t('Content.Common.Frequency.Fortnight'),
  [Frequency_Enum.Daily]: t('Content.Common.Frequency.Day'),
};

export function formatCurrencyWithPeriod(
  amount: number,
  frequency: Frequency_Enum | Frequency_Input_Enum | undefined,
  noFraction?: boolean,
): string {
  if (frequency == null) {
    return formatCurrency(amount, { noFraction });
  }
  return `${formatCurrency(amount, { noFraction })}/${
    FREQUENCY_LABEL[frequency]
  }`;
}

export function stripDollarSign(num: string): string {
  return num.replace(DOLLAR_SIGN_REGEX, '');
}

export function stripNonDigit(num: string): string {
  return num.replace(NON_DIGIT_REGEX, '');
}

export function stripNonNumericLetters(num: string): string {
  let value = num.replace(NON_NUMERIC_LETTERS_REGEX, '');
  // replace the first . with M
  value = value.replace('.', 'M');
  // strip non numeric letters except "M"
  value = value.replace(/[^\dM]/g, '');
  value = value.replace('M', '.');
  // strip leading 0
  value = value.replace(/^(-)?0+(?=\d)/, '$1');
  if (value.startsWith('.')) {
    value = `0.${value.slice(1)}`;
  }
  const parts = value.split('.');
  return parts
    .slice(0, 2)
    .map((part, index) => {
      // only allow 2 decimals
      if (index === 1) {
        return part.slice(0, 2);
      }
      return part;
    })
    .join('.');
}
