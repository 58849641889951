import LottieView from 'lottie-react-native';
import { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import { useLayoutAnimation } from '../../ui/utils/useLayoutAnimation';
import { chromaticIgnoreDataSet } from '../../utils/chromatic';
import useCountInterval from '../../utils/hooks/useCountInterval';

type Props = {
  durationPerText?: number;
  onCompleted?: () => void;
  testID?: string;
};

function SubmittingLoanIndicator({
  durationPerText = 600,
  onCompleted,
  testID,
}: Props) {
  const texts = useMemo(
    () => [
      t('Content.SubmittingLoanIndicator.CheckingProperty'),
      t('Content.SubmittingLoanIndicator.VerifyingBorrowers'),
      t('Content.SubmittingLoanIndicator.CheckingIncome'),
      t('Content.SubmittingLoanIndicator.CheckingExpenses'),
      t('Content.SubmittingLoanIndicator.VerifyingDebtsAndLiabilities'),
      t('Content.SubmittingLoanIndicator.ConfirmingYourLoanSetup'),
    ],
    [],
  );
  const { currentCount } = useCountInterval(durationPerText);
  useLayoutAnimation([currentCount]);
  const i = currentCount % texts.length;
  const currentText = texts[i];

  const showCheck = currentCount >= texts.length;

  useEffect(() => {
    if (showCheck) {
      onCompleted?.();
    }
    // Execute the callback only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheck]);

  return (
    <Box centered dataSet={chromaticIgnoreDataSet} testID={testID}>
      <LottieView
        key="activity-indicator"
        style={styles.lottieView}
        autoPlay
        loop
        source={require('../../../assets/lottie/activityIndicatorGreen.json')}
      />
      <StyledText fontSize="xs">{currentText}</StyledText>
    </Box>
  );
}

const styles = StyleSheet.create({
  lottieView: {
    width: 171,
    height: 171,
  },
});

export default SubmittingLoanIndicator;
