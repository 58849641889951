import { useIsFocused } from '@react-navigation/native';
import { useCardAnimation } from '@react-navigation/stack';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Text,
  theme as themeV3,
  View,
} from '@unloan/ui';
import { PropsWithChildren, ReactNode } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { MAX_DRAWER_WIDTH } from 'src/utils/NavConstants';
import { TestID } from 'testID/constants';

import { FocusOn } from '../FocusOn';

type ButtonContent = { label: string } & Parameters<typeof Button>[0];

type Props = {
  onClose: () => void;
  buttons: ButtonContent[];
  /** Component rendered above the buttons (e.g. text prompt that provides context for the buttons below) */
  header?: ReactNode;
};

/**
 * Displays a modal containing call-to-actions (e.g. confirming something, or copying content).
 * For mobile, this is displayed at the bottom of the screen.
 * For tablet and desktop, this is displayed at the center of the screen.
 */
export function ActionSheetV3(props: Props) {
  return (
    <ActionSheetBackdrop {...props}>
      <ActionSheetModal {...props} />
    </ActionSheetBackdrop>
  );
}

type BackdropProps = PropsWithChildren<Pick<Props, 'onClose'>>;
function ActionSheetBackdrop({ onClose, children }: BackdropProps) {
  const isCurrentScreenFocused = useIsFocused();
  const insets = useSafeAreaInsets();
  const cardAnimation = useCardAnimation();

  return (
    <View
      sx={(theme) => ({
        flex: 1,
        justifyContent: ['flex-end', 'center', 'center'],
        alignItems: 'center',
        paddingBottom: [insets.bottom || theme.space.$64, 0, 0],
      })}
    >
      <Animated.View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: themeV3.colors.$neutral.$1000,
          opacity: cardAnimation.current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.4],
            extrapolate: 'clamp',
          }),
        }}
      />
      <FocusOn
        onClickOutside={onClose}
        onEscapeKey={onClose}
        style={{ width: '100%', maxWidth: MAX_DRAWER_WIDTH }}
        enabled={isCurrentScreenFocused}
      >
        {children}
      </FocusOn>
    </View>
  );
}

type ModalProps = Pick<Props, 'header' | 'buttons' | 'onClose'>;
function ActionSheetModal({ header, buttons, onClose }: ModalProps) {
  return (
    <View
      sx={(theme) => ({
        gap: theme.space.$8,
        width: '100%',
        paddingHorizontal: theme.space.$8,
      })}
      testID={TestID.ActionSheet.ContentContainer}
    >
      <Card
        sx={(theme) => ({
          padding: theme.space.$16,
          shadowColor: theme.colors.$transparent,
          width: '100%',
          gap: theme.space.$8,
        })}
      >
        <CardHeader>{header ?? null}</CardHeader>
        <CardContent sx={(theme) => ({ gap: theme.space.$8 })}>
          {buttons.map((props) => (
            <Button {...props} stretched key={props.label}>
              {props.label}
            </Button>
          ))}
        </CardContent>
      </Card>
      <Card
        sx={(theme) => ({
          paddingVertical: theme.space.$12,
          paddingHorizontal: theme.space.$24,
          shadowColor: theme.colors.$transparent,
        })}
        onPress={onClose}
      >
        <CardContent sx={{ alignItems: 'center' }}>
          <Text variant="tertiary" size="label2" weight="semiBold">
            Close
          </Text>
        </CardContent>
      </Card>
    </View>
  );
}
