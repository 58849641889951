import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useRef, useState } from 'react';

// How long do we allow the user to be idle before we stop polling
const POLLING_INACTIVITY = 10 * 60 * 1000; // 10 minutes

// Only poll if the user has been interacting with the app. Otherwise, don't poll.
// Polling uses resources and will keep a session alive. There is no point polling
// if user is not using the app
export const usePollIntervalForActiveUser = ({
  pollInterval,
  inactivityTimeout = POLLING_INACTIVITY,
}: {
  pollInterval: number;
  inactivityTimeout?: number;
}) => {
  const { addListener, removeListener } = useNavigation();

  const [isPolling, setIsPolling] = useState(true);

  const createTimeout = useCallback(
    () =>
      setTimeout(() => {
        setIsPolling(false);
      }, inactivityTimeout),
    [inactivityTimeout],
  );

  const timer = useRef<NodeJS.Timeout | null>(createTimeout());

  const handleNavigationStateChange = useCallback(() => {
    if (timer.current !== null) clearTimeout(timer.current);
    timer.current = createTimeout();
  }, [createTimeout]);

  useEffect(() => {
    addListener('state', handleNavigationStateChange);
    return () => {
      if (timer.current !== null) clearTimeout(timer.current);
      removeListener('state', handleNavigationStateChange);
    };
  }, [addListener, handleNavigationStateChange, removeListener]);

  return isPolling ? pollInterval : 0;
};
