import { View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Button } from '../../ui/atoms/Button';

type Props = {
  isRequestor?: boolean;
  loading: boolean;
  onCancelRequest: () => void;
  onApproveRequest: () => void;
  onDeclineRequest: () => void;
};

export const ApprovalActions = ({
  isRequestor = false,
  loading,
  onCancelRequest,
  onApproveRequest,
  onDeclineRequest,
}: Props) => (
  <View sx={{ py: '$16', mt: '$16' }}>
    {isRequestor ? (
      <Button
        alignSelf="stretch"
        testID={TestID.AccountPermissions.ApprovalScreen.CancelButton}
        secondary
        label={t('Content.Common.ButtonLabel.Cancel')}
        onPress={onCancelRequest}
        disabled={loading}
      />
    ) : (
      <View style={{ flexDirection: 'row' }}>
        <Button
          flex={1}
          label={t('Content.Common.ButtonLabel.Decline')}
          testID={TestID.AccountPermissions.ApprovalScreen.DeclineButton}
          secondary
          color="error"
          mr="s"
          onPress={onDeclineRequest}
          disabled={loading}
        />
        <Button
          flex={1}
          label={t('Content.Common.ButtonLabel.Approve')}
          testID={TestID.AccountPermissions.ApprovalScreen.ApproveButton}
          ml="s"
          onPress={onApproveRequest}
          disabled={loading}
        />
      </View>
    )}
  </View>
);
