import { yup } from '../../utils/yup';

export enum FormFields {
  Amount = 'amount',
}

export const getValidationSchema = (maxAmount: number) =>
  yup
    .object({
      [FormFields.Amount]: yup.number().required().min(1).max(maxAmount),
    })
    .required();

export type FormValues = yup.Asserts<ReturnType<typeof getValidationSchema>>;

export const getInitialValue = (maxAmount: number) => ({
  [FormFields.Amount]: maxAmount,
});

export const getExpiryTimeInHours = (
  expiryTimeStr: string | null | undefined,
) => {
  if (!expiryTimeStr) return 24;
  const currentTime = Date.now();
  const expiryTime = new Date(expiryTimeStr).getTime();
  const differenceInMs = expiryTime - currentTime;
  const differenceInHrs = differenceInMs / (1000 * 60 * 60);
  return differenceInHrs > 0 ? Math.ceil(differenceInHrs) : 0;
};
