import { A, Text } from 'dripsy';
import { TouchableOpacity } from 'react-native';

import { isWeb } from '../../../utils/platformUtils';
import { TextProps } from './CompoundText';

export type TextLinkProps = TextProps & {
  children: string;
  href?: string;
  nativeID?: string;
  onPress?: () => void;
};

export const TextLink = ({ children, onPress, ...otherProps }: TextLinkProps) =>
  isWeb ? (
    <A onPress={onPress} {...otherProps}>
      {children}
    </A>
  ) : (
    <TouchableOpacity
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      onPress={onPress}
    >
      <Text {...otherProps}>{`${children} `}</Text>
    </TouchableOpacity>
  );

export const CompoundTextLink = ({ children, ...otherProps }: TextProps) =>
  children
    .split(' ')
    // eslint-disable-next-line react/jsx-key
    .map((word) => <TextLink {...otherProps}>{word}</TextLink>);
