import {
  AuthenticationType,
  getEnrolledLevelAsync,
  SecurityLevel,
  supportedAuthenticationTypesAsync,
} from 'expo-local-authentication';
import { useCallback, useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { setNotShownFaceIdPrompt } from '../../Auth/hasShownFaceIdPrompt';

type ExpoAuth = {
  supportFaceId?: boolean;
  securityLevel?: SecurityLevel;
  faceIdEnabled?: boolean;
};

export const useExpoAuth = () => {
  const [expoAuth, setExpoAuth] = useState<ExpoAuth>({});

  const getExpoAuth = useCallback(async () => {
    const supportFaceId = (await supportedAuthenticationTypesAsync()).includes(
      AuthenticationType.FACIAL_RECOGNITION,
    );
    const securityLevel = await getEnrolledLevelAsync();

    const faceIdEnabled =
      supportFaceId && securityLevel === SecurityLevel.BIOMETRIC;

    if (faceIdEnabled) {
      await setNotShownFaceIdPrompt();
    }

    setExpoAuth({
      supportFaceId,
      securityLevel,
      faceIdEnabled,
    });
  }, []);

  // refresh expoAuth when user app becomes active because user may change system settings
  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active') {
          getExpoAuth();
        }
      },
    );

    return () => {
      subscription.remove();
    };
  }, [getExpoAuth]);

  useEffect(() => {
    getExpoAuth();
  }, [getExpoAuth]);

  return expoAuth;
};
