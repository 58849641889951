import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import { useMemo } from 'react';
import { CopyBsbAccountNumberActionSheetV3 } from 'src/ActionSheet/screens/CopyBsbAccountNumberActionSheetV3';

import { AccountPermissionsAppliedActionSheet } from '../AccountPermissions/screens/AccountPermissionsAppliedActionSheet';
import { AccountPermissionsApprovalActionSheet } from '../AccountPermissions/screens/AccountPermissionsApprovalActionSheet';
import { AccountPermissionsChangeRequestSubmittedActionSheet } from '../AccountPermissions/screens/AccountPermissionsChangeRequestSubmittedActionSheet';
import { AccountPermissionsConfirmChangesActionSheet } from '../AccountPermissions/screens/AccountPermissionsConfirmChangesActionSheet';
import { AccountPermissionsLeavePageActionSheet } from '../AccountPermissions/screens/AccountPermissionsLeavePageActionSheet';
import { AccountAddressBookDeleteActionSheet } from '../ActionSheet/screens/AccountAddressBookDeleteActionSheet';
import { AddDirectDebitAccountConfirmationActionSheet } from '../ActionSheet/screens/AddDirectDebitAccountConfirmationActionSheet';
import { AddDirectDebitAccountConfirmationV2ActionSheet } from '../ActionSheet/screens/AddDirectDebitAccountConfirmationV2ActionSheet';
import { AutopayCancellationConfirmationActionSheet } from '../ActionSheet/screens/AutopayCancellationConfirmationActionSheet';
import { AutopayChangeConfirmationActionSheet } from '../ActionSheet/screens/AutopayChangeConfirmationActionSheet';
import { AutopayShortfallConfirmationActionSheet } from '../ActionSheet/screens/AutopayShortfallConfirmationActionSheet';
import { AutopayShortfallConfirmationV2ActionSheet } from '../ActionSheet/screens/AutopayShortfallConfirmationV2ActionSheet';
import { CaConfirmationActionSheet } from '../ActionSheet/screens/CaConfirmationActionSheet';
import { CoBorrowerInfoConfirmationActionSheet } from '../ActionSheet/screens/CoBorrowerInfoConfirmationActionSheet';
import { ConfirmationSuccessActionSheet } from '../ActionSheet/screens/ConfirmationSuccessActionSheet';
import { CopyBsbAccountNumberActionSheet } from '../ActionSheet/screens/CopyBsbAccountNumberActionSheet';
import { CreditCheckConfirmationActionSheet } from '../ActionSheet/screens/CreditCheckConfirmationActionSheet';
import DataSharingConfirmationActionSheet from '../ActionSheet/screens/DataSharingConfirmationActionSheet';
import DataSharingConfirmationMessage from '../ActionSheet/screens/DataSharingConfirmationMessageActionSheet';
import { DeleteAccountCompletedActionSheet } from '../ActionSheet/screens/DeleteAccountCompletedActionSheet';
import { DeleteAccountConfirmationCheckActionSheet } from '../ActionSheet/screens/DeleteAccountConfirmationCheckActionSheet';
import { DeleteExpenseActionSheet } from '../ActionSheet/screens/DeleteExpenseActionSheet';
import { DeleteIncomeActionSheet } from '../ActionSheet/screens/DeleteIncomeActionSheet';
import { DeleteManuallyAddedLiabilityActionSheet } from '../ActionSheet/screens/DeleteManuallyAddedLiabilityActionSheet';
import { DeveloperToolsActionSheet } from '../ActionSheet/screens/DeveloperTools';
import { DisconnectBankActionSheet } from '../ActionSheet/screens/DisconnectBankActionSheet';
import { EnvSwitcherActionSheet } from '../ActionSheet/screens/EnvSwitcherActionSheet';
import { FlagDetectedLiabilityActionSheet } from '../ActionSheet/screens/FlagDetectedLiabilityActionSheet';
import { GreenTickConfirmationActionSheet } from '../ActionSheet/screens/GreenTickConfirmationActionSheet';
import { LowExpensesActionSheet } from '../ActionSheet/screens/LowExpensesActionSheet';
import { NoDebtsConfirmationActionSheet } from '../ActionSheet/screens/NoDebtsConfirmationActionSheet';
import { PreventClosingModalActionSheet } from '../ActionSheet/screens/PreventClosingModalActionSheet';
import { ReduceRepaymentsApproveRequestActionSheet } from '../ActionSheet/screens/ReduceRepaymentsApproveRequestActionSheet';
import { ReduceRepaymentsCancelRequestActionSheet } from '../ActionSheet/screens/ReduceRepaymentsCancelRequestActionSheet';
import { ReduceRepaymentsConfirmationActionSheet } from '../ActionSheet/screens/ReduceRepaymentsConfirmationActionSheet';
import { ReduceRepaymentsDeclineRequestActionSheet } from '../ActionSheet/screens/ReduceRepaymentsDeclineRequestActionSheet';
import { ReduceRepaymentsRequestApprovedActionSheet } from '../ActionSheet/screens/ReduceRepaymentsRequestApprovedActionSheet';
import { ReduceRepaymentsRequestCancelledActionSheet } from '../ActionSheet/screens/ReduceRepaymentsRequestCancelledActionSheet';
import { ReduceRepaymentsRequestDeclinedActionSheet } from '../ActionSheet/screens/ReduceRepaymentsRequestDeclinedActionSheet';
import { ReduceRepaymentsSubmittedActionSheet } from '../ActionSheet/screens/ReduceRepaymentsSubmittedActionSheet';
import { RemoveCoBorrowerActionSheetV2 } from '../ActionSheet/screens/RemoveCoBorrowerActionSheetV2';
import { RemovePropertyActionSheetV2 } from '../ActionSheet/screens/RemovePropertyActionSheetV2';
import { RepaymentPlanForRetirementActionSheetV2 } from '../ActionSheet/screens/RepaymentPlanForRetirementActionSheetV2';
import { SocialSignInActionSheet } from '../ActionSheet/screens/SocialSignInActionSheet';
import { StopDataShareConfirmationActionSheet } from '../ActionSheet/screens/StopDataShareConfirmationActionSheet';
import { TransactionHistoryActionSheet } from '../ActionSheet/screens/TransactionHistoryActionSheet';
import { UpdateApplicationConfirmationActionSheet } from '../ActionSheet/screens/UpdateApplicationConfirmationActionSheet';
import { WithdrawConfirmationActionSheet } from '../ActionSheet/screens/WithdrawConfirmationActionSheet';
import { ApproverScreen } from '../ApprovalRequest/screens/ApproverScreen';
import { BrowserAuthFlow } from '../Auth/screens/BrowserAuthFlow';
import { NativeAuthFlow } from '../Auth/screens/NativeAuthFlow';
import { AutopaySettingsNavigator } from '../AutopaySettings/navigation/Navigator';
import { BorrowerV2Navigator } from '../Borrower/navigation/Navigator';
import { ConditionalApprovalNavigator } from '../ConditionalApproval/navigation/Navigator';
import { ConnectedBanksModalNavigator } from '../ConnectedBanks/navigation/Navigator';
import { DataSharingConfirmationModalNavigator } from '../DataSharingConfirmation/navigation/Navigator';
import { DebtsWizardNavigatorV2 } from '../DebtsWizard/navigation/DebtsWizardNavigator';
import { DocumentPreview } from '../DocumentPreview/screens/DocumentPreview';
import { DocusignContractReturn } from '../DocusignContractReturn/screens/DocusignContractReturn';
import { ExpensesWizardNavigator } from '../Expenses/navigation/ExpensesWizardNavigator';
import { HomeNavigator } from '../Home/navigation/Navigator';
import { ApplicationTracking } from '../Home/screens/ApplicationTracking';
import { HomeLoanNavigator } from '../HomeLoan/navigation/Navigator';
import { TransferLimitReachedActionSheet } from '../HomeLoan/screens/TransferLimitReachedActionSheet';
import { HomeLoanInsightsNavigator } from '../HomeLoanInsights/navigation/Navigator';
import { IdentificationV2Navigator } from '../Identification/navigation/Navigator';
import { YourIncomeModalNavigator } from '../Income/navigation/YourIncomeModalNavigator';
import { YourIncomeWizardNavigator } from '../Income/navigation/YourIncomeWizardNavigator';
import { InvalidApplicationInvite } from '../JoinApplication/screens/InvalidApplicationInvite';
import { JoinApplication } from '../JoinApplication/screens/JoinApplication';
import { LoanApplicationV2Navigator } from '../LoanApplication/navigation/Navigator';
import { VerifyYourIdentityConsentModal } from '../LoanApplication/screens/VerifyYourIdentityConsent';
import { LoanConfigurationNavigatorV2 } from '../LoanConfiguration/navigation/Navigator';
import { PersonalDetailsModalNavigator } from '../PersonalDetails/navigation/Navigator';
import { SelectInstitutionNavigator } from '../SelectInstitution/Navigator';
import { StatementsModalNavigator } from '../Statements/navigation/Navigator';
import { StatementsModalNavigatorV2 } from '../Statements/navigation/NavigatorV2';
import { TransactionNavigator } from '../Transaction/navigation/TransactionNavigator';
import { useTheme } from '../ui/theme';
import { useIsTablet } from '../utils/hooks/useBreakpoint';
import { useJoinApplication } from '../utils/JoinApplicationInvite';
import {
  ActionSheetPreset,
  DefaultModalV2NavigatorOptions,
  DefaultNavOptions,
  TranslucentHeaderModalNavOptions,
  TransparentHeaderNavOptions,
  useModalScreenOptions,
  useModalScreenOptionsV2,
} from '../utils/NavConstants';
import { isWeb } from '../utils/platformUtils';
import { AcceptableDocumentsModal } from '../VerifyFinancials/screens/AcceptableDocumentsModal';
import { LearnOpenBankingModal } from '../VerifyFinancials/screens/LearnOpenBankingModal';
import { NavigatorFullScreenModal } from './NavigatorFullScreenModal';
import { NavigatorModal } from './NavigatorModal';
import { NotFoundScreen } from './screens/NotFoundScreen';
import { SingleModalNavigator } from './SingleModalNavigator';
import { AppStackParams } from './types/navTypes';
import { ActionSheetType, getPageTitle, Screen } from './types/screens';

const loanApplicationOptions = {
  ...DefaultNavOptions,
  ...TransitionPresets.ModalSlideFromBottomIOS,
  headerShown: false,
  animationEnabled: !isWeb,
};

const singleModalScreenOptions = {
  headerShown: true,
  ...TranslucentHeaderModalNavOptions,
};

const fullScreenOptions = {
  ...singleModalScreenOptions,
  headerTitle: '',
};

const AppStack = createStackNavigator<AppStackParams>();

export function AppNavigator() {
  const joinApplicationData = useJoinApplication();
  const isTablet = useIsTablet();

  const modalScreenOptionsV2 = useModalScreenOptionsV2();
  const fullScreenModalScreenOptionsV2 = useModalScreenOptionsV2(true);

  const modalScreenOptions = useModalScreenOptions();
  // The default modalScreen has fixed height set
  // but in transition to newer modals, we want the height to be dynamic depending on content for desktop
  const modalScreenWithDynamicHeightOptions = useMemo(
    () => ({
      ...TransparentHeaderNavOptions,
      cardStyle: [
        modalScreenOptions.cardStyle,
        {
          flexBasis: isTablet ? ('auto' as const) : 1,
          flexGrow: isTablet ? 0 : 1,
          minHeight: isTablet ? 0 : undefined,
        },
      ],
    }),
    [isTablet, modalScreenOptions],
  );
  const theme = useTheme();
  return (
    <AppStack.Navigator
      initialRouteName={joinApplicationData?.initialRouteName}
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: theme.colors.bg },
      }}
    >
      <AppStack.Screen
        name={Screen.AUTH_FLOW}
        component={isWeb ? BrowserAuthFlow : NativeAuthFlow}
        options={{
          title: getPageTitle(Screen.AUTH_FLOW),
          gestureEnabled: false,
        }}
      />
      <AppStack.Screen
        name={Screen.JOIN_APPLICATION_MODAL}
        component={JoinApplication}
        options={{
          title: getPageTitle(Screen.JOIN_APPLICATION_MODAL),
        }}
      />
      <AppStack.Screen
        name={Screen.INVALID_APPLICATION_INVITE}
        component={InvalidApplicationInvite}
        options={{
          title: getPageTitle(Screen.INVALID_APPLICATION_INVITE),
        }}
      />
      <AppStack.Group screenOptions={loanApplicationOptions}>
        <AppStack.Screen
          name={Screen.LOAN_APPLICATION_V2}
          component={LoanApplicationV2Navigator}
          options={{
            title: getPageTitle(Screen.LOAN_APPLICATION_V2),
          }}
        />
        <AppStack.Screen
          name={Screen.APPLICATION_TRACKING}
          component={ApplicationTracking}
          options={{
            title: getPageTitle(Screen.APPLICATION_TRACKING),
          }}
        />
      </AppStack.Group>
      <AppStack.Screen
        name={Screen.MAIN_NAVIGATOR}
        component={HomeNavigator}
        options={{
          title: getPageTitle(Screen.MAIN_NAVIGATOR),
        }}
      />
      <AppStack.Screen
        options={modalScreenOptionsV2}
        name={Screen.NAVIGATOR_MODAL}
        component={NavigatorModal}
      />
      <AppStack.Screen
        options={fullScreenModalScreenOptionsV2}
        name={Screen.NAVIGATOR_FULL_SCREEN_MODAL}
        component={NavigatorFullScreenModal}
      />
      <AppStack.Group screenOptions={modalScreenOptionsV2}>
        {/* Modal v2 */}
        <AppStack.Screen
          name={Screen.DEBTS_WIZARD_V2_MODAL}
          component={DebtsWizardNavigatorV2}
          options={{
            title: getPageTitle(Screen.DEBTS_WIZARD_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.LOAN_CONFIGURATION_V2_MODAL}
          component={LoanConfigurationNavigatorV2}
          options={{
            title: getPageTitle(Screen.LOAN_CONFIGURATION_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.YOUR_INCOME_V2_WIZARD}
          component={YourIncomeWizardNavigator}
          options={{
            title: getPageTitle(Screen.YOUR_INCOME_V2_WIZARD),
          }}
        />
        <AppStack.Screen
          name={Screen.YOUR_INCOME_V2_MODAL}
          component={YourIncomeModalNavigator}
          options={{
            title: getPageTitle(Screen.YOUR_INCOME_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.YOUR_EXPENSES_V2_WIZARD}
          component={ExpensesWizardNavigator}
          options={{
            title: getPageTitle(Screen.YOUR_EXPENSES_V2_WIZARD),
          }}
        />

        <AppStack.Screen
          name={Screen.SINGLE_V2_MODAL}
          component={SingleModalNavigator}
          options={{
            title: getPageTitle(Screen.SINGLE_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.IDENTIFICATION_V2_MODAL}
          component={IdentificationV2Navigator}
          options={{
            title: getPageTitle(Screen.IDENTIFICATION_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.BORROWER_V2_MODAL}
          component={BorrowerV2Navigator}
          options={{
            title: getPageTitle(Screen.BORROWER_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.CONDITIONAL_APPROVAL_MODAL}
          component={ConditionalApprovalNavigator}
          options={{
            title: getPageTitle(Screen.CONDITIONAL_APPROVAL_MODAL),
          }}
        />
      </AppStack.Group>
      <AppStack.Group screenOptions={modalScreenOptions}>
        <AppStack.Screen
          name={Screen.AUTOPAY_SETTINGS_MODAL}
          component={AutopaySettingsNavigator}
          options={{
            ...modalScreenOptionsV2,
            title: getPageTitle(Screen.AUTOPAY_SETTINGS_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.HOME_LOAN_INSIGHTS_MODAL}
          component={HomeLoanInsightsNavigator}
          options={{
            ...modalScreenOptionsV2,
            title: getPageTitle(Screen.HOME_LOAN_INSIGHTS_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.HOME_LOAN_MODAL}
          component={HomeLoanNavigator}
          options={{
            ...modalScreenOptionsV2,
            title: getPageTitle(Screen.HOME_LOAN_MODAL),
          }}
        />

        <AppStack.Screen
          name={Screen.SELECT_INSTITUTION_MODAL}
          component={SelectInstitutionNavigator}
          options={{
            title: getPageTitle(Screen.SELECT_INSTITUTION_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.DATA_SHARING_CONFIRMATION_MODAL}
          component={DataSharingConfirmationModalNavigator}
          options={{
            title: getPageTitle(Screen.DATA_SHARING_CONFIRMATION_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.STATEMENTS_MODAL}
          component={StatementsModalNavigator}
          options={{
            title: getPageTitle(Screen.STATEMENTS_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.STATEMENTS_V2_MODAL}
          component={StatementsModalNavigatorV2}
          options={{
            ...modalScreenOptionsV2,
            title: getPageTitle(Screen.STATEMENTS_V2_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.CONNECTED_BANKS_MODAL}
          component={ConnectedBanksModalNavigator}
          options={{
            title: getPageTitle(Screen.CONNECTED_BANKS_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.TRANSACTION_MODAL}
          component={TransactionNavigator}
          options={{
            title: getPageTitle(Screen.TRANSACTION_MODAL),
          }}
        />
        <AppStack.Screen
          name={Screen.PERSONAL_DETAILS_MODAL}
          component={PersonalDetailsModalNavigator}
          options={{
            ...modalScreenOptionsV2,
            title: getPageTitle(Screen.PERSONAL_DETAILS_MODAL),
          }}
        />

        <AppStack.Group screenOptions={singleModalScreenOptions}>
          <AppStack.Screen
            name={Screen.APPROVAL_RESPONSE_MODAL}
            component={ApproverScreen}
            options={{
              ...modalScreenOptionsV2,
              ...DefaultModalV2NavigatorOptions,
              title: getPageTitle(Screen.APPROVAL_RESPONSE_MODAL),
            }}
          />
          <AppStack.Screen
            name={Screen.DOCUMENT_PREVIEW_MODAL}
            component={DocumentPreview}
            options={{
              headerTitle: '',
              title: getPageTitle(Screen.DOCUMENT_PREVIEW_MODAL),
            }}
          />
          <AppStack.Group screenOptions={fullScreenOptions}>
            <AppStack.Screen
              name={Screen.ACCEPTABLE_DOCUMENTS_MODAL}
              component={AcceptableDocumentsModal}
              options={{
                title: getPageTitle(Screen.ACCEPTABLE_DOCUMENTS_MODAL),
              }}
            />
            <AppStack.Screen
              name={Screen.VERIFY_YOUR_IDENTITY_CONSENT_MODAL}
              component={VerifyYourIdentityConsentModal}
              options={{
                title: t('Content.IncomeSupportingDocumentUpload.ScreenTitle'),
                ...modalScreenWithDynamicHeightOptions,
              }}
            />

            <AppStack.Screen
              name={Screen.LEARN_OPEN_BANKING_MODAL}
              component={LearnOpenBankingModal}
              options={{
                title: getPageTitle(Screen.LEARN_OPEN_BANKING_MODAL),
              }}
            />
          </AppStack.Group>
        </AppStack.Group>
      </AppStack.Group>

      <AppStack.Screen
        name={Screen.DOCUSIGN_CONTRACT_RETURN}
        component={DocusignContractReturn}
        options={{
          title: getPageTitle(Screen.DOCUSIGN_CONTRACT_RETURN),
        }}
      />
      <AppStack.Group screenOptions={ActionSheetPreset}>
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE}
          component={AccountAddressBookDeleteActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.ACCOUNT_ADDRESS_BOOK_DELETE),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.SOCIAL_SIGN_IN}
          component={SocialSignInActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.SOCIAL_SIGN_IN),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.WITHDRAW_CONFIRMATION}
          component={WithdrawConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.WITHDRAW_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.CA_CONFIRMATION}
          component={CaConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.CA_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.CONFIRMATION_SUCCESS}
          component={ConfirmationSuccessActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.CONFIRMATION_SUCCESS),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION}
          component={CoBorrowerInfoConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.CO_BORROWER_INFORMATION_CONFIRMATION,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ENV_SWITCHER}
          component={EnvSwitcherActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.ENV_SWITCHER),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REMOVE_CO_BORROWER_V2}
          component={RemoveCoBorrowerActionSheetV2}
          options={{
            title: getPageTitle(ActionSheetType.REMOVE_CO_BORROWER_V2),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.CREDIT_CHECK_CONFIRMATION}
          component={CreditCheckConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.CREDIT_CHECK_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DISCONNECT_BANK}
          component={DisconnectBankActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.DISCONNECT_BANK),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DEVELOPER_TOOLS}
          component={DeveloperToolsActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.DEVELOPER_TOOLS),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2}
          component={RepaymentPlanForRetirementActionSheetV2}
          options={{
            title: getPageTitle(
              ActionSheetType.REPAYMENT_PLAN_FOR_RETIREMENT_V2,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.TRANSACTION_HISTORY}
          component={TransactionHistoryActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.TRANSACTION_HISTORY),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REMOVE_PROPERTY_V2}
          component={RemovePropertyActionSheetV2}
          options={{
            title: getPageTitle(ActionSheetType.REMOVE_PROPERTY_V2),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION}
          component={AddDirectDebitAccountConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2}
          component={AddDirectDebitAccountConfirmationV2ActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.ADD_DIRECT_DEBIT_ACCOUNT_CONFIRMATION_V2,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.LOW_EXPENSES}
          component={LowExpensesActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.LOW_EXPENSES),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.NO_DEBTS_CONFIRMATION}
          component={NoDebtsConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.NO_DEBTS_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.AUTOPAY_CONFIRMATION}
          component={AutopayChangeConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.AUTOPAY_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION}
          component={AutopayShortfallConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2}
          component={AutopayShortfallConfirmationV2ActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.AUTOPAY_CANCELLATION_CONFIRMATION}
          component={AutopayCancellationConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.STOP_DATA_SHARE_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.TRANSFER_LIMIT_REACHED}
          component={TransferLimitReachedActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.TRANSFER_LIMIT_REACHED),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.STOP_DATA_SHARE_CONFIRMATION}
          component={StopDataShareConfirmationActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.STOP_DATA_SHARE_CONFIRMATION),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION}
          component={DataSharingConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE}
          component={DataSharingConfirmationMessage}
          options={{
            title: getPageTitle(
              ActionSheetType.SETTINGS_DATA_SHARING_CONFIRMATION_MESSAGE,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET}
          component={DeleteAccountConfirmationCheckActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.DELETE_ACCOUNT_CONFIRMATION_CHECK_ACTION_SHEET,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET}
          component={DeleteAccountCompletedActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.DELETE_ACCOUNT_COMPLETED_ACTION_SHEET,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.COPY_BSB_ACCOUNT_NUMBER}
          component={CopyBsbAccountNumberActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.COPY_BSB_ACCOUNT_NUMBER),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3}
          component={CopyBsbAccountNumberActionSheetV3}
          options={{
            title: getPageTitle(ActionSheetType.COPY_BSB_ACCOUNT_NUMBER_V3),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DELETE_EXPENSE_ACTION_SHEET}
          component={DeleteExpenseActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.DELETE_EXPENSE_ACTION_SHEET),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DELETE_INCOME_ACTION_SHEET}
          component={DeleteIncomeActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.DELETE_INCOME_ACTION_SHEET),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET}
          component={DeleteManuallyAddedLiabilityActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.DELETE_MANUALLY_ADDED_LIABILITY_ACTION_SHEET,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.FLAG_DETECTED_LIABILITY}
          component={FlagDetectedLiabilityActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.FLAG_DETECTED_LIABILITY),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET}
          component={GreenTickConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.GREEN_TICK_CONFIRMATION_ACTION_SHEET,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET}
          component={PreventClosingModalActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.UPDATE_APPLICATION_CONFIRMATION}
          component={UpdateApplicationConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.UPDATE_APPLICATION_CONFIRMATION,
            ),
          }}
        />

        {/* Principal Reduction Action Sheets */}
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES}
          component={ReduceRepaymentsConfirmationActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_CONFIRM_CHANGES,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED}
          component={ReduceRepaymentsSubmittedActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.REDUCE_REPAYMENTS_SUBMITTED),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST}
          component={ReduceRepaymentsCancelRequestActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_CANCEL_REQUEST,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED}
          component={ReduceRepaymentsRequestCancelledActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_REQUEST_CANCELLED,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST}
          component={ReduceRepaymentsDeclineRequestActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_DECLINE_REQUEST,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED}
          component={ReduceRepaymentsRequestDeclinedActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_REQUEST_DECLINED,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST}
          component={ReduceRepaymentsApproveRequestActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_APPROVE_REQUEST,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED}
          component={ReduceRepaymentsRequestApprovedActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.REDUCE_REPAYMENTS_REQUEST_APPROVED,
            ),
          }}
        />
        {/* Account Permissions Action Sheets */}
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES}
          component={AccountPermissionsConfirmChangesActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.ACCOUNT_PERMISSIONS_CONFIRM_CHANGES,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE}
          component={AccountPermissionsLeavePageActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.ACCOUNT_PERMISSIONS_LEAVE_PAGE),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED}
          component={AccountPermissionsChangeRequestSubmittedActionSheet}
          options={{
            title: getPageTitle(
              ActionSheetType.ACCOUNT_PERMISSIONS_CHANGE_REQUEST_SUBMITTED,
            ),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL}
          component={AccountPermissionsApprovalActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.ACCOUNT_PERMISSIONS_APPROVAL),
          }}
        />
        <AppStack.Screen
          name={ActionSheetType.ACCOUNT_PERMISSIONS_APPLIED}
          component={AccountPermissionsAppliedActionSheet}
          options={{
            title: getPageTitle(ActionSheetType.ACCOUNT_PERMISSIONS_APPLIED),
          }}
        />
      </AppStack.Group>

      <AppStack.Screen
        name={Screen.NOT_FOUND}
        component={NotFoundScreen}
        options={{ title: getPageTitle(Screen.NOT_FOUND) }}
      />
    </AppStack.Navigator>
  );
}
