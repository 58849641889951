import { Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';

type UserNamesSectionProps = {
  fullName: string;
  loading?: boolean;
  onLegalNameChange?: () => void;
};

export const UserNamesSection = ({
  fullName,
  loading = false,
  onLegalNameChange,
}: UserNamesSectionProps) => (
  <View sx={{ mt: '$24' }}>
    <Text variant="sHeader" sx={{ py: '$8', mb: '$8' }}>
      {t('Content.PersonalDetails.NameHeading')}
    </Text>
    <DataRowGroup mt={0}>
      <DataRow
        label=""
        testID={TestID.PersonalDetails.ChangeNameButton}
        onPress={!loading ? onLegalNameChange : undefined}
        loading={loading}
        caption={
          <View>
            <Text variant="xsHeader">
              {t('Content.PersonalDetails.LegalName')}
            </Text>
            <Text variant="body">
              {loading ? t('Content.Common.Placeholder.Loading') : fullName}
            </Text>
          </View>
        }
      />
    </DataRowGroup>
  </View>
);
