import { Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { makeRedrawThresholdExceededWarning } from '../../ActionSheet/screens/WithdrawConfirmationActionSheet';
import { SubmitButton } from '../../components/form/SubmitButton';
import { InfoRow } from '../../components/InfoRow';
import { Divider } from '../../ui/v2/Divider/Divider';
import { CardList, ListItem } from '../../ui/v2/List';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import { ExternalAccount, WithdrawFormV2 } from '../types';

type WithdrawConfirmationV2Props = {
  values: WithdrawFormV2;
  externalAccounts: ExternalAccount[];
  unloanAccountName: string;
  unloanBsb: string;
  unloanAccountNumber: string;
  availableRedrawAmount: number;
  nextInstalmentAmount: number;
  nextInstalmentDate?: string;
  onConfirmPress: () => void;
  confirmLoading?: boolean;
};

export function WithdrawConfirmationV2({
  values,
  externalAccounts,
  unloanAccountName,
  unloanBsb,
  unloanAccountNumber,
  availableRedrawAmount,
  nextInstalmentAmount,
  nextInstalmentDate,
  onConfirmPress,
  confirmLoading,
}: WithdrawConfirmationV2Props) {
  const {
    amount,
    external_account_id: externalAccountId,
    is_manual_account_input: isManualInput,
    account_name: accountName,
    account_bsb: accountBsb,
    account_number: accountNumber,
    description,
    reference,
  } = values;

  const externalAccount = isManualInput
    ? {
        account_name: accountName,
        account_details: formatBsbAndAccountNumber(accountBsb, accountNumber),
      }
    : externalAccounts.find(({ id }) => id === externalAccountId);

  const bsbJoinedWithAccountNumber = formatBsbAndAccountNumber(
    unloanBsb,
    unloanAccountNumber,
  );

  const redrawThreshold =
    availableRedrawAmount > nextInstalmentAmount
      ? availableRedrawAmount - nextInstalmentAmount
      : 0;

  const showWarningMessage = (amount ?? 0) > redrawThreshold;

  return (
    <>
      {showWarningMessage ? (
        <InfoRow
          message={makeRedrawThresholdExceededWarning({
            nextInstalmentAmount,
            nextInstalmentDate,
          })}
          mb="m"
        />
      ) : null}
      <CardList>
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.Withdraw.Amount')}
            </Text>
          }
          line={
            <Text variant="sSubHeading">
              {formatCurrency(amount, { withFractionOnRoundedAmount: true })}
            </Text>
          }
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.Withdraw.FromSection')}
            </Text>
          }
          line={
            <View>
              <Text variant="sSubHeading">{unloanAccountName}</Text>
              <Text variant="sSubHeading">{bsbJoinedWithAccountNumber}</Text>
            </View>
          }
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.Withdraw.ToSection')}
            </Text>
          }
          line={
            <View>
              <Text variant="sSubHeading">{externalAccount?.account_name}</Text>
              <Text variant="sSubHeading">
                {externalAccount?.account_details}
              </Text>
            </View>
          }
        />
        <Divider />
        <ListItem
          sx={{ p: '$16' }}
          title={
            <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
              {t('Content.Withdraw.DescriptionSection')}
            </Text>
          }
          line={<Text variant="sSubHeading">{description}</Text>}
        />

        {reference ? (
          <>
            <Divider />
            <ListItem
              sx={{ p: '$16' }}
              title={
                <Text variant="xsHeader" sx={{ fontSize: '$17' }}>
                  {t('Content.Withdraw.Reference')}
                </Text>
              }
              line={<Text variant="sSubHeading">{reference}</Text>}
            />
          </>
        ) : null}
      </CardList>

      <Text variant="caption" sx={{ mt: '$16' }}>
        {t('Content.Withdraw.ConfirmCaption')}
      </Text>

      <SubmitButton
        testID={TestID.Withdraw.ConfirmButton}
        label={t('Content.Withdraw.Confirm')}
        onPress={onConfirmPress}
        sx={{ mt: '$32' }}
        disabled={confirmLoading}
        showSpinner={confirmLoading}
      />
    </>
  );
}
