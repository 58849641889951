import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export function ConditionalApprovalDocumentPendingIcon({
  color,
  size,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
      <Path
        fill="#dedede"
        d="M27.285 11.246v18.734c0 0.829-0.681 1.501-1.518 1.501h-19.749c-0.837 0-1.518-0.672-1.518-1.501v-25.479c0-0.829 0.681-1.501 1.518-1.501h13.078l2.113 5.997 6.075 2.248z"
      />
      <Path
        fill="#b8b8b8"
        d="M19.103 3v6.745c0 0.829 0.686 1.501 1.536 1.501h6.646l-8.182-8.246z"
      />
      <Path
        fill="#a2a2a2"
        d="M22.565 20.165h-13.349c-0.244 0-0.444-0.169-0.444-0.377v-0.748c0-0.208 0.2-0.377 0.444-0.377h13.349c0.247 0 0.447 0.169 0.447 0.377v0.748c0 0.208-0.2 0.377-0.447 0.377z"
      />
      <Path
        fill="#a2a2a2"
        d="M19.849 23.011h-10.761c-0.173 0-0.316-0.166-0.316-0.374v-0.75c0-0.205 0.142-0.374 0.316-0.374h10.761c0.173 0 0.316 0.169 0.316 0.374v0.75c0 0.208-0.142 0.374-0.316 0.374z"
      />
      <Path
        fill="#353535"
        d="M12.818 13.663c0.015-0.286-0.083-0.567-0.272-0.782-0.266-0.244-0.582-0.427-0.926-0.536-0.385-0.141-0.761-0.304-1.127-0.488-0.295-0.148-0.568-0.336-0.812-0.558-0.224-0.208-0.404-0.46-0.527-0.74-0.133-0.321-0.197-0.666-0.189-1.013-0.011-0.306 0.041-0.611 0.152-0.897s0.28-0.545 0.495-0.763c0.466-0.447 1.075-0.715 1.72-0.755v-0.803c0-0.127 0.050-0.249 0.14-0.339s0.212-0.14 0.339-0.14c0.127 0 0.249 0.051 0.339 0.141s0.14 0.212 0.14 0.339v0.821c0.465 0.044 0.908 0.22 1.276 0.508s0.645 0.676 0.799 1.117c0.048 0.13 0.064 0.269 0.046 0.406s-0.067 0.268-0.146 0.382-0.183 0.207-0.306 0.271c-0.122 0.065-0.258 0.099-0.396 0.1-0.187-0.001-0.369-0.063-0.517-0.178s-0.255-0.274-0.304-0.455c-0.042-0.162-0.117-0.314-0.222-0.445-0.096-0.117-0.217-0.209-0.355-0.271s-0.288-0.091-0.439-0.085c-0.139-0.009-0.279 0.012-0.41 0.060s-0.251 0.123-0.351 0.219c-0.094 0.104-0.165 0.226-0.212 0.358s-0.066 0.272-0.058 0.412c-0.006 0.133 0.014 0.266 0.059 0.392s0.115 0.241 0.205 0.339c0.286 0.25 0.619 0.441 0.98 0.563 0.403 0.157 0.796 0.338 1.178 0.542 0.286 0.154 0.548 0.347 0.779 0.576 0.209 0.209 0.374 0.457 0.486 0.731 0.117 0.305 0.174 0.63 0.168 0.956 0.012 0.304-0.038 0.608-0.147 0.893s-0.275 0.544-0.488 0.761c-0.477 0.447-1.096 0.712-1.75 0.748v0.717c0 0.126-0.050 0.246-0.139 0.335s-0.21 0.139-0.335 0.139c-0.126 0-0.246-0.050-0.335-0.139s-0.139-0.209-0.139-0.335v-0.706c-0.721-0.042-1.4-0.353-1.903-0.871-0.22-0.242-0.389-0.526-0.496-0.835-0.044-0.129-0.057-0.267-0.037-0.402s0.072-0.263 0.151-0.374c0.079-0.111 0.184-0.201 0.305-0.263s0.256-0.095 0.392-0.094h0.006c0.182-0 0.36 0.058 0.506 0.167s0.253 0.263 0.305 0.438c0.050 0.18 0.14 0.347 0.263 0.488 0.125 0.131 0.276 0.233 0.445 0.298s0.349 0.094 0.529 0.082c0.303 0.017 0.601-0.083 0.833-0.279 0.102-0.096 0.181-0.213 0.233-0.343s0.075-0.269 0.068-0.409z"
      />
      <Path
        fill="#d9d9d9"
        d="M33 27c0 3.314-2.686 6-6 6s-6-2.686-6-6c0-3.314 2.686-6 6-6s6 2.686 6 6z"
      />
      <Path
        fill="#353535"
        d="M27 33c-3.306 0-6-2.694-6-6 0-3.312 2.694-6 6-6 3.312 0 6 2.688 6 6 0 3.306-2.688 6-6 6zM23.924 27.635h3.071c0.229 0 0.412-0.176 0.412-0.412v-3.965c0-0.229-0.182-0.406-0.412-0.406s-0.406 0.176-0.406 0.406v3.559h-2.665c-0.235 0-0.412 0.176-0.412 0.406 0 0.235 0.176 0.412 0.412 0.412z"
      />
    </Svg>
  );
}
