import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Text } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';
import { useSetRecoilState } from 'recoil';

import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { hasOpenedWizardStateByLoanApplicationIdV2 } from '../../LoanApplication/screens/YourDebtsV2';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { ScrollContainer } from '../../ui/atoms/ScrollContainer';
import { Spinner } from '../../ui/atoms/Spinner';
import { StyledText } from '../../ui/atoms/StyledText';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import JoinedLiabilityList from '../components/JoinedLiabilityList';
import {
  DebtsWizardNavigationScreenProp,
  DebtsWizardStackRoute,
} from '../navigation/types';
import { composeDebtsWizardScreenName } from '../navigation/utils/debtsScreenHelpers';
import { useAllLiabilitiesQuery } from '../utils/useAllLiabilitiesQuery';

export function DetectedDebtListV2() {
  const navigation =
    useNavigation<
      DebtsWizardNavigationScreenProp<Screen.DEBTS_WIZARD_V2_DEBT_LIST>
    >();
  const route =
    useRoute<DebtsWizardStackRoute<Screen.DEBTS_WIZARD_V2_DEBT_LIST>>();
  const loanApplicationId = route.params?.loanApplicationId;

  const {
    loading,
    error,
    currentApplicantDetectedLiabilities,
    liabilitiesForDebtsWizard,
  } = useAllLiabilitiesQuery({
    loanApplicationId,
    forV2: true,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToYourDebts = () => {
    navigateToLoanApplicationScreen({
      section: LoanApplicationSection.Debts,
    });
  };

  const onNextPress = () => {
    const firstLiabilityInWizard = liabilitiesForDebtsWizard[0];
    if (firstLiabilityInWizard == null) {
      // This block should never be executed
      // because user can't start the wizard
      // without liabilities.
      navigateToYourDebts();
      return;
    }

    navigation.navigate(Screen.DEBTS_WIZARD_V2_MODAL, {
      screen: composeDebtsWizardScreenName(liabilitiesForDebtsWizard, {
        detectedLiabilityIdentifier:
          firstLiabilityInWizard.detected_liability_identifier || '',
      }),
      params: {
        ...route.params,
      },
    });
  };

  const setHasOpenedWizard = useSetRecoilState(
    hasOpenedWizardStateByLoanApplicationIdV2(loanApplicationId),
  );
  useFocusEffect(
    useCallback(() => {
      const id = setTimeout(() => {
        // Wait until the modal is fully opened before setting the open state to true
        setHasOpenedWizard(true);
        // The timeout value is obtained by observing how long the spring animation would take.
        // To calculate the actual value, we need to calculate the duration from
        // a spring based animation, which is non-trivial.
        // Using InteractionManager to wait for the animation to finish does not work on web.
      }, 200);
      return () => clearTimeout(id);
    }, [setHasOpenedWizard]),
  );

  if (loading) {
    return (
      <ModalScreenContainer onClose={navigateToYourDebts}>
        <ScrollContainer>
          <NavHeaderSpacer />
          <Box row centered mt="m">
            <Spinner size="small" />
            <StyledText ml="s" fontSize="xs" color="secondaryContent">
              Detecting...
            </StyledText>
          </Box>
        </ScrollContainer>
      </ModalScreenContainer>
    );
  }

  return (
    <DetectedDebtListV2Loaded
      error={error}
      liabilities={currentApplicantDetectedLiabilities}
      onNextPress={onNextPress}
      onClosePress={navigateToYourDebts}
    />
  );
}

type DetectedDebtListV2LoadedProps = {
  error?: Error;
  liabilities: React.ComponentProps<typeof JoinedLiabilityList>['liabilities'];
  onNextPress: () => void;
  onClosePress: () => void;
};
function DetectedDebtListV2Loaded({
  error,
  liabilities,
  onNextPress,
  onClosePress,
}: DetectedDebtListV2LoadedProps) {
  return (
    <ModalScreenContainer
      headerText={t('Content.DetectedDebtListV2.DebtsAndLiabilities')}
      scrollable
      hideBackButton
      onClose={onClosePress}
    >
      {error ? (
        <ErrorRow
          message={t('Content.Common.Error.FailFetchLiabilities')}
          mb="l"
        />
      ) : null}
      <Text
        sx={{
          mb: '$24',
        }}
      >
        {t('Content.YourDebts.DetectedFollowingDebts')}
      </Text>
      <JoinedLiabilityList liabilities={liabilities} forV2 />
      <Button
        label={t('Content.Common.ButtonLabel.Next')}
        onPress={onNextPress}
        py="m"
        mt="l"
        alignSelf="stretch"
      />
    </ModalScreenContainer>
  );
}
