import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';

export const ApplicationSummaryProfile = (props: SvgProps) => {
  const idPrefix = useSvgIdPrefix('ApplicationSummaryProfile');
  return (
    <Svg width={36} height={36} fill="none" {...props}>
      <Path
        d="M33.75 18c0 8.698-7.052 15.75-15.75 15.75S2.25 26.698 2.25 18 9.302 2.25 18 2.25 33.75 9.302 33.75 18Z"
        fill={`url(#${idPrefix})`}
      />
      <Path
        d="M18 19.575a6.3 6.3 0 1 0 0-12.6 6.3 6.3 0 0 0 0 12.6ZM18 33.75c3.95 0 7.55-1.464 10.315-3.866-1.568-4.179-5.588-7.159-10.314-7.159-4.727 0-8.747 2.98-10.315 7.16a15.673 15.673 0 0 0 10.315 3.865Z"
        fill="#2C2C2C"
      />
      <Defs>
        <LinearGradient
          id={idPrefix}
          x1={2.25}
          y1={2.25}
          x2={33.75}
          y2={33.75}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
