import { gql } from '@apollo/client';

import {
  LastProcessedRequestsFragmentDoc,
  useAccountActivityWatcherSubscription,
} from '../../generated/graphql';

// Querying the most recent COMPLETED task means that the subscription
// callback will be triggered after the change has been applied. The
// callback can be used to refetch the account information from Mambu
export const AccountActivityWatcherSubscription = gql`
  subscription AccountActivityWatcher($cba_account_id: String!) {
    loan_account(where: { cba_account_id: { _eq: $cba_account_id } }) {
      id
      ...LastProcessedRequests
    }
  }

  ${LastProcessedRequestsFragmentDoc}
`;

export const useAccountActivityWatcher = (
  cba_account_id: string,
  refetch: () => void,
) => {
  const { data, error, loading } = useAccountActivityWatcherSubscription({
    variables: { cba_account_id },
    fetchPolicy: 'cache-first',
    onSubscriptionData() {
      // Ensure refetch is only called when data changes, not the first
      // time that the subscription data loads
      if (data) {
        refetch();
      }
    },
    context: {
      sentryContext: {
        description: 'Error occured in useAccountActivityWatcher',
      },
    },
  });

  return { data, error, loading };
};
