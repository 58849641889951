import { RuntypeBase, Static } from 'runtypes/lib/runtype';

export function filterArray<T extends RuntypeBase>(
  runtype: T,
  arr: Array<unknown>,
): Array<Static<T>> {
  const filteredArray: Array<Static<T>> = [];
  for (const maybeValid of arr) {
    if (runtype.guard(maybeValid)) {
      filteredArray.push(maybeValid);
    }
  }
  return filteredArray;
}
