import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../../ui/theme';
import { isWeb } from '../platformUtils';

export const useIsTablet = (): boolean =>
  useMediaQuery({ minWidth: breakpoints.tablet });

export const useIsDesktop = (): boolean =>
  useMediaQuery({ minWidth: breakpoints.desktop });

export const useIsMobile = (): boolean =>
  useMediaQuery({
    minWidth: breakpoints.mobile,
    maxWidth: breakpoints.tablet - 1,
  });

export const useView = () => {
  const isDesktopWebView = useIsTablet();
  const isMobileWebView = useIsMobile() && isWeb;
  const isMobileNativeView = useIsMobile() && !isWeb;

  return {
    isDesktopWebView,
    isMobileWebView,
    isMobileNativeView,
  };
};
