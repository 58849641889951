import { gql } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Text } from 'dripsy';
import { useContext, useMemo, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import {
  Liability_Type_Enum,
  Loan_Application_Type_Enum,
  refetchConditionalApprovalGetReviewLoanApplicationQuery,
  refetchReviewLoanApplicationQuery,
  refetchSetupLoanScreenQuery,
  refetchYourDebtsQuery,
  useAddManualLiabilityV2MutationMutation,
  useAddManualLiabilityV2Query,
} from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import type {
  SingleModalNavigationScreenProp,
  SingleModalStackRoute,
} from '../../navigation/SingleModalNavigator';
import { Screen } from '../../navigation/types/screens';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { useLayoutAnimation } from '../../ui/utils/useLayoutAnimation';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Select } from '../../ui/v2/Select/Select';
import { streetTypeOptions } from '../../utils/formOptions';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { usePropertySuggestionLoader } from '../../utils/hooks/usePropertySuggestionLoader';
import { anyLiabilityMatchingSecurity } from '../../utils/matchingSecurityAddress';
import {
  LoanDetailsForm,
  LoanDetailsFormSubmitButton,
  LoanDetailsFormValues,
} from '../components/LoanDetailsForm';
import {
  buildAddLiabilityMutationInput,
  buildErrorMessageFromAddLiabilityResult,
} from '../utils/addManualLiabilityUtils';
import { getLiabilityTypeItems } from '../utils/debtsScreenUtils';
import { mapApplicantsToAccountOwnerOptions } from '../utils/liabilityRemoteDataHelpers';

export const ADD_MANUAL_LIABILITY_V2_DOC = gql`
  query AddManualLiabilityV2($loanApplicationId: uuid!) {
    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      latest_full_name
      hecs_loan_balance
    }

    loanApplication: loan_application_by_pk(id: $loanApplicationId) {
      id
      type
    }

    loanApplicationSecurities: loan_application_security(
      where: { loan_application_id: { _eq: $loanApplicationId } }
      limit: 1
    ) {
      id
      property {
        id
        address {
          id
          domain_api_property_id
          shortAddressFormat: short_address_format
          full_display_address
        }
      }
    }
    institutions: institution(
      where: {
        # Show only common institutions
        provided_by_user_id: { _is_null: true }
        provided_by_staff: { _is_null: true }
        provided_by_api: { _is_null: true }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
    states: state {
      code
      name
    }
    homeLoanLiabilities: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        dynamite_liability_type: { _eq: "HOME_LOAN" }
        dynamite_flagged_incorrect: { _is_null: true }
      }
    ) {
      id
      dynamite_liability_type
      dynamite_address {
        id
        domain_api_property_id
        full_display_address
      }
    }
    mergedLiabilities: merged_liability(
      where: {
        loan_application_id: { _eq: $loanApplicationId }
        dynamite_flagged_incorrect: { _is_null: true }
      }
    ) {
      id
      dynamite_applicant_ids
      dynamite_liability_type
    }
  }
`;

export const ADD_MANUAL_LIABILITY_V2_MUTATION_DOC = gql`
  mutation AddManualLiabilityV2Mutation($input: add_current_liability_input!) {
    add_current_liability(data: $input) {
      current_liability_id
      error_type
    }
  }
`;

export type AddManualLiabilityParamsForStoryBook = {
  _initiallySelectedLiabilityType?: Liability_Type_Enum;
  _forceLoading?: boolean;
  _forceDataQueryError?: boolean;
};

export function AddManualLiabilityV2() {
  const navigation =
    useNavigation<
      SingleModalNavigationScreenProp<Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL>
    >();
  const route =
    useRoute<
      SingleModalStackRoute<Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL>
    >();

  const {
    loanApplicationId,
    _initiallySelectedLiabilityType,
    _forceLoading,
    _forceDataQueryError,
  } = route.params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { flags } = useContext(FeatureFlagsContext);
  const enableAvailableFundsCapture = flags.ENABLE_AVAILABLE_FUNDS_CAPTURE;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const [selectedLiabilityType, setSelectedLiabilityType] =
    useState<Liability_Type_Enum | null>(
      _initiallySelectedLiabilityType ?? null,
    );

  const propertySuggestionLoader = usePropertySuggestionLoader();
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useAddManualLiabilityV2Query({
    variables: { loanApplicationId: loanApplicationId || '' },
    fetchPolicy: 'no-cache',
    skip: !loanApplicationId,
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [mutationErrorForDisplay, setMutationErrorForDisplay] = useState<
    string | null
  >(null);

  const [commit, { loading: isSubmitting }] =
    useAddManualLiabilityV2MutationMutation({
      onError: (_err) => {
        setMutationErrorForDisplay(
          t('Content.AddManualLiabilityV2.MutationError'),
        );
      },
    });

  const showDeclareAssociatedLoanMessage =
    data &&
    data.loanApplication?.type !== Loan_Application_Type_Enum.Purchase &&
    !anyLiabilityMatchingSecurity(
      data.homeLoanLiabilities,
      data.loanApplicationSecurities,
    );

  const isLoading = queryLoading || _forceLoading;
  const isQueryError = queryError != null || _forceDataQueryError;

  const accountOwnerOptions = mapApplicantsToAccountOwnerOptions({
    applicants: data?.applicants,
    mergedLiabilities: data?.mergedLiabilities,
    selectedLiabilityType,
  });

  const hideHecsLiabilityType = accountOwnerOptions.every(
    (option) => option.hasHecsLoan === true,
  );

  const manualAddressStateOptions = useMemo(
    () =>
      data?.states?.map(({ code }) => ({
        label: code,
        value: code,
      })) ?? [],
    [data?.states],
  );

  const onCloseModal = () => {
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Debts,
      }),
    );
  };

  const isInvalidState =
    loanApplicationId == null || selectedLiabilityType == null;

  const onSubmit = async (formValues: LoanDetailsFormValues) => {
    setMutationErrorForDisplay(null);
    if (isInvalidState) {
      // TODO(uiv2): show error on invalid app state or render app not found
      return;
    }

    const [res] = await safelyCallMutation(commit, {
      variables: {
        input: buildAddLiabilityMutationInput({
          formValues,
          loanApplicationId,
          selectedLiabilityType,
          enableAvailableFundsCapture: !!enableAvailableFundsCapture,
        }),
      },
      refetchQueries: [
        refetchYourDebtsQuery({ loanApplicationId }),
        refetchSetupLoanScreenQuery({ loanApplicationId }),
        refetchReviewLoanApplicationQuery({ loanApplicationId }),
        refetchConditionalApprovalGetReviewLoanApplicationQuery({
          loanApplicationId,
        }),
      ],
      awaitRefetchQueries: true,
      context: { sentryContext: { loanApplicationId } },
    });

    const displayErr = buildErrorMessageFromAddLiabilityResult(res);
    if (displayErr) {
      setMutationErrorForDisplay(displayErr);
      return;
    }
    onCloseModal();
  };

  useLayoutAnimation([isLoading, isSubmitting]);

  if (isLoading) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <ScreenLoadingContainer flex={1} centered loading />
      </ModalScreenContainer>
    );
  }

  if (isQueryError) {
    return (
      <ModalScreenContainer hideBackButton onClose={onCloseModal}>
        <EmptyState
          title={t('Content.Common.ErrorTitle')}
          description={t('Content.Common.Error.FailFetchLoanApplication')}
        />
      </ModalScreenContainer>
    );
  }

  return (
    <ModalScreenContainer
      headerText={t('Content.AddManualLiabilityV2.AddDebtOrLiability')}
      scrollable
      onClose={onCloseModal}
      hideBackButton
      loading={isSubmitting}
    >
      <ErrorRow
        message={mutationErrorForDisplay}
        mb="l"
        testID={TestID.AddManualLiabilityV2.MutationErrorRow}
      />
      <Text variant="sHeader">
        {t('Content.AddManualLiabilityV2.WhatTypeOfLoanAccountDoYouHave')}
      </Text>
      <Select
        label={t('Content.AddManualLiabilityV2.LoanType')}
        testID={TestID.AddManualLiabilityV2.LoanType}
        items={getLiabilityTypeItems(hideHecsLiabilityType)}
        onValueChange={(value) =>
          setSelectedLiabilityType(value as Liability_Type_Enum | null)
        }
        selectedValue={selectedLiabilityType}
        sx={{ mt: '$16', mb: showDeclareAssociatedLoanMessage ? 0 : '$32' }}
      />
      {showDeclareAssociatedLoanMessage ? (
        <Text variant="caption" sx={{ mt: '$8', mb: '$32' }}>
          {t(
            'Content.AddManualLiabilityV2.DontForgetToIncludeYourCurrentLoanForAddress',
            {
              address:
                data?.loanApplicationSecurities?.[0]?.property.address
                  .shortAddressFormat ?? '',
            },
          )}
        </Text>
      ) : null}
      {selectedLiabilityType != null ? (
        <>
          <Text
            variant="sHeader"
            sx={{
              mb: '$16',
            }}
          >
            {t('Content.AddManualLiabilityV2.EnterYourLoanDetails')}
          </Text>
          <LoanDetailsForm
            // Make liability type a React key to force remount
            // to clear the filled values when changing type.
            screen={Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL}
            key={selectedLiabilityType}
            accountOwnerOptions={accountOwnerOptions}
            manualAddressStateOptions={manualAddressStateOptions}
            manualAddressStreetTypeOptions={streetTypeOptions}
            liabilityType={selectedLiabilityType}
            onSubmit={onSubmit}
            liabilityManuallyAdded
            isSubmitting={isSubmitting}
            institutionsData={data?.institutions}
            {...propertySuggestionLoader}
            allowEditAvailableBalanceField={!!enableAvailableFundsCapture}
          />
        </>
      ) : (
        <LoanDetailsFormSubmitButton disabled />
      )}
    </ModalScreenContainer>
  );
}
