import { Text } from 'dripsy';
import { useCallback, useContext, useEffect } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Living_Situation_Input_Enum } from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Skeleton } from '../../ui/atoms/Skeleton';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import { ExpensesTypesForm } from '../components/ExpensesTypesForm';
import { ExpensesWizardScreenProps } from '../navigation/ExpensesWizardNavigator';
import { useExpensesForm } from '../utils/ExpensesFormProvider';
import { getExpensesFrequencyOptions } from '../utils/expensesFormUtils';
import { CombinedExpensesFormValues } from '../utils/forms';
import {
  useFrequencyOptions,
  useGetExpenseFieldsWithGuide,
  useGetMonthlyExpensesTypesInitialValues,
} from '../utils/remoteData';
import { useSubmitHouseholdExpensesV2 } from '../utils/useSubmitHouseholdExpensesV2';

export type Props =
  ExpensesWizardScreenProps<Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES>;

export function ExpensesWizardMonthlyExpensesTypes({
  navigation,
  route,
}: Props) {
  const { flags } = useContext(FeatureFlagsContext);
  const enableExpensesScreenAndHemGuideUplift =
    flags.ENABLE_EXPENSES_SCREEN_AND_HEM_GUIDE_UPLIFT;

  const { loanApplicationId = '', householdId = '' } = route.params || {};
  const { tryNavigateBackToSummary, shouldBackToAppSummary } =
    useAppSummaryScreenNavigation({
      navigation,
      route,
      loanApplicationId,
    });

  const { frequencyOptions, loading: frequencyOptionsLoading } =
    useFrequencyOptions();

  const mappedFrequencyOptions = mapFrequencyOptionsForV2(frequencyOptions);
  const mappedExpensesFrequencyOptions = getExpensesFrequencyOptions(
    mappedFrequencyOptions,
  );

  const { initialValues, loading: initialValuesLoading } =
    useGetMonthlyExpensesTypesInitialValues(loanApplicationId, householdId);

  const { setExpenses, values } = useExpensesForm();

  const { livingSituation, dependentsCount, selectedBorrowers } =
    values.expenses;

  const {
    fetchHouseholdExpensesFieldGuide,
    isRefetchingHouseholdExpenseFieldGuide,
    isExpensesFieldLoading,
    householdPlaceholderHeight,
    expensesFieldsWithGuideData,
  } = useGetExpenseFieldsWithGuide();

  useEffect(() => {
    fetchHouseholdExpensesFieldGuide({
      livingSituation: livingSituation || Living_Situation_Input_Enum.Renting,
      loanApplicationId,
      dependants: dependentsCount || 0,
      applicantIds: selectedBorrowers || [],
    });
    // @deandrasita: Why is this disabled?
    // This shouldn't be disabled and we should fix it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isExpensesFieldLoading || frequencyOptionsLoading;

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateBackToLoanApplicationExpenses = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Expense,
      });
    });
  };

  const {
    submitHouseholdExpensesV2,
    submitHouseholdErrorMessageV2,
    submitHouseholdExpenseLoadingV2,
  } = useSubmitHouseholdExpensesV2({
    loanApplicationId,

    householdId,
    needHemCheck: true,
    navigateBackToLoanApplicationExpenses,
  });

  const onSubmit = useCallback(
    async (formVal: CombinedExpensesFormValues) => {
      if (values == null) {
        return;
      }
      setExpenses(formVal);
      await submitHouseholdExpensesV2({ ...values.expenses, ...formVal });
    },
    [submitHouseholdExpensesV2, setExpenses, values],
  );

  const onDeleteHousehold = async () => {
    if (!householdId) {
      return;
    }
    navigation.navigate(ActionSheetType.DELETE_EXPENSE_ACTION_SHEET, {
      loanApplicationId,
      householdId,
      ...route.params,
    });
  };

  return (
    <ModalScreenContainer
      headerText={t('Content.ExpensesForm.YourExpenses')}
      scrollable
      onClose={navigateBackToLoanApplicationExpenses}
    >
      {isLoading && !isRefetchingHouseholdExpenseFieldGuide ? (
        // Display skeleton when expense fields are loading and not refetching
        <>
          <Text sx={{ mb: '$32' }}>
            {enableExpensesScreenAndHemGuideUplift
              ? t('Content.ExpensesForm.EstimateYourFutureExpenses')
              : t('Content.ExpensesForm.EstimateMonthlyExpenses')}
          </Text>
          <Skeleton show height={householdPlaceholderHeight} width="100%" />
        </>
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={submitHouseholdErrorMessageV2}
            testID={TestID.ExpensesForm.ErrorRow}
          />
          {enableExpensesScreenAndHemGuideUplift ? (
            <Text sx={{ mb: '$8' }}>
              {t('Content.ExpensesForm.EstimateYourFutureExpenses')}
            </Text>
          ) : (
            <Text sx={{ mb: '$32' }}>
              {t('Content.ExpensesForm.EstimateMonthlyExpenses')}
            </Text>
          )}
          <ExpensesTypesForm
            screen={Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_TYPES}
            initialValues={initialValues}
            isSubmitting={submitHouseholdExpenseLoadingV2}
            expenseFieldsWithGuide={expensesFieldsWithGuideData}
            onSubmit={onSubmit}
            // Don't show delete button if coming from app summary
            showDeleteButton={shouldBackToAppSummary ? false : !!householdId}
            onDeleteHousehold={onDeleteHousehold}
            frequencyOptions={
              enableExpensesScreenAndHemGuideUplift
                ? mappedExpensesFrequencyOptions
                : mappedFrequencyOptions
            }
            loading={initialValuesLoading}
            enableExpensesScreenAndHemGuideUplift={
              enableExpensesScreenAndHemGuideUplift
            }
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
