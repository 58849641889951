import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { DeleteIncomeActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import {
  EmploymentIncomeForm,
  EmploymentIncomeFormDetails,
  EmploymentIncomeFormSubmitLabel,
} from '../components/EmploymentIncomeForm';
import {
  useGetEmploymentIncomeForV2Form,
  useGetOptionsForEmploymentIncomeQuery,
  useUpdateEmploymentIncomeForV2Form,
} from '../graphql/incomeHooks';
import { YourIncomeModalScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeModalScreenProps<Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL>;

export function EditEmploymentIncomeScreen({ navigation, route }: Props) {
  const { params } = route;

  const { loanApplicationId = '', incomeId = '' } = params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToMainScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  const {
    occupationTypeOptions,
    employmentTypeOptions,
    incomeOwnersOptions,
    loading: employmentIncomeOptionsLoading,
  } = useGetOptionsForEmploymentIncomeQuery({
    loanApplicationId: loanApplicationId ?? '',
  });

  const {
    initialValues,
    loading: initialValuesLoading,
    currentLoanIncomeCount,
  } = useGetEmploymentIncomeForV2Form({ incomeId, loanApplicationId });

  const { updateEmploymentIncome, isUpdatingEmploymentIncome } =
    useUpdateEmploymentIncomeForV2Form({
      loanApplicationId,
      incomeId,
    });

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [
    deleteIncomeActionSheetErrorMessage,
    setDeleteIncomeActionSheetErrorMessage,
  ] = useRecoilState(DeleteIncomeActionSheetErrorAtom);

  useFocusEffect(() => () => setDeleteIncomeActionSheetErrorMessage(null));

  const onSubmit = async (values: EmploymentIncomeFormDetails) => {
    setDeleteIncomeActionSheetErrorMessage(null);
    const { success, errorMessage } = await updateEmploymentIncome(values);
    if (success) {
      navigateBackToMainScreen();
    } else {
      setFormErrorMessage(errorMessage);
    }
  };

  const onDelete = async () => {
    if (!incomeId) {
      return;
    }
    navigation.navigate(ActionSheetType.DELETE_INCOME_ACTION_SHEET, {
      loanApplicationId: loanApplicationId ?? '',
      incomeId,
      ...route.params,
    });
  };

  const showLoading =
    employmentIncomeOptionsLoading ||
    initialValuesLoading ||
    loadingLoanApplicationMetadata;

  // We're only allowing income deletion if there're more than one income
  const showDeleteButton = currentLoanIncomeCount > 1;

  const hideHecsFields = !initialValues.hecsLoanBalance;

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.EmploymentIncome')}
      scrollable
      loading={isUpdatingEmploymentIncome}
      onClose={() => {
        navigateBackToMainScreen();
      }}
      hideBackButton
    >
      {showLoading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.EmploymentIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage || deleteIncomeActionSheetErrorMessage}
            testID={TestID.EmploymentIncomeForm.ErrorText}
          />
          <EmploymentIncomeForm
            screen={Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL}
            initialValues={initialValues}
            isSubmitting={isUpdatingEmploymentIncome}
            onSubmit={onSubmit}
            employmentTypeOptions={employmentTypeOptions}
            occupationTypeOptions={occupationTypeOptions}
            payeeOptions={incomeOwnersOptions}
            submitLabel={EmploymentIncomeFormSubmitLabel.Done}
            showDeleteButton={showDeleteButton}
            onDelete={onDelete}
            hideHecsFields={hideHecsFields}
            hideNovatedLeaseFields
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
