import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function ActiveFilterIcon({ size, color, ...props }: SvgIconProps) {
  const width = resizeIconWidth(16, 17, size);
  return (
    <Svg width={width} height={size} viewBox="0 0 25 25" fill="none" {...props}>
      <Path
        d="M12.0508 24.4922C18.5898 24.4922 24.0039 19.0664 24.0039 12.5391C24.0039 6 18.5781 0.585938 12.0391 0.585938C5.51172 0.585938 0.0976562 6 0.0976562 12.5391C0.0976562 19.0664 5.52344 24.4922 12.0508 24.4922ZM5.47656 10.1367C4.9375 10.1367 4.55078 9.79688 4.55078 9.28125C4.55078 8.76562 4.9375 8.42578 5.47656 8.42578H18.6484C19.1758 8.42578 19.5742 8.76562 19.5742 9.28125C19.5742 9.79688 19.1758 10.1367 18.6484 10.1367H5.47656ZM7.26953 14.1445C6.71875 14.1445 6.34375 13.8047 6.34375 13.2773C6.34375 12.7617 6.71875 12.4219 7.26953 12.4219H16.8555C17.3945 12.4219 17.7695 12.7617 17.7695 13.2773C17.7695 13.8047 17.3945 14.1445 16.8555 14.1445H7.26953ZM9.13281 18.1523C8.59375 18.1523 8.21875 17.8008 8.21875 17.2852C8.21875 16.7812 8.59375 16.4414 9.13281 16.4414H14.9805C15.5195 16.4414 15.9062 16.7812 15.9062 17.2852C15.9062 17.8008 15.5195 18.1523 14.9805 18.1523H9.13281Z"
        fill="#010101"
        fillOpacity="0.95"
      />
    </Svg>
  );
}
