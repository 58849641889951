import { useGetCountryOptionsQuery } from '../../generated/graphql';

export const useCountryOptionsQuery = () => {
  const { data, loading } = useGetCountryOptionsQuery();
  return {
    data:
      data?.country.map(({ code, description }) => ({
        label: description,
        value: code,
      })) ?? [],
    loading,
  };
};
