import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import * as React from 'react';

import { SavedOnInterestBarChartGraph } from '../../HomeLoanInsights/components/InterestSavings/SavedOnInterestBarChartGraph';
import {
  MAX_PERIODS,
  useLoanAccountInterestSavings,
} from '../../HomeLoanInsights/hooks/useLoanAccountInterestSavings';
import { Screen } from '../../navigation/types/screens';
import { formatCurrency } from '../../utils/currencyHelpers';
import { HomeLoanLoadingInsight } from './HomeLoanLoadingInsight';
import { InsightWidgetContainer } from './InsightWidgetContainer';

type Props = Partial<React.ComponentProps<typeof InsightWidgetContainer>> & {
  disabled?: boolean;
  onPress?: () => void;
  loading?: boolean;
  cbaAccountId: string;
};

export const InsightsInterestSavingsWidget = ({
  disabled = false,
  cbaAccountId,
  loading = false,
  onPress,
  ...props
}: Props) => {
  const navigation = useNavigation();

  const openInterestSavingsInsightModal = useCallback(() => {
    navigation.navigate(Screen.HOME_LOAN_INSIGHTS_MODAL, {
      screen: Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS,
      params: {
        cbaAccountId,
      },
    });
  }, [cbaAccountId, navigation]);

  const { historicalInterestSavings, totalInterestSavingsUntilNow } =
    useLoanAccountInterestSavings(cbaAccountId);

  if (loading) {
    return <HomeLoanLoadingInsight />;
  }

  return (
    <InsightWidgetContainer
      onPress={onPress || openInterestSavingsInsightModal}
      disabled={disabled}
      header={formatCurrency(totalInterestSavingsUntilNow, {
        noFraction: false,
        withFractionOnRoundedAmount: totalInterestSavingsUntilNow !== 0,
        displayInThousand: true,
      })}
      caption={t('Content.HomeLoan.Insights.TotalInterestSavings.Header')}
      graphPlaceholder={
        <SavedOnInterestBarChartGraph
          totalInterestSavingsUntilNow={
            disabled ? 0 : totalInterestSavingsUntilNow || 0
          }
          enableBarHoverOn={false}
          numOfDotsLines={4}
          displayTooltip={false}
          ratio={3}
          values={
            disabled
              ? new Array(MAX_PERIODS).fill(0)
              : historicalInterestSavings.map(
                  (saving) => saving.interestSaving || 0,
                )
          }
        />
      }
      {...props}
    />
  );
};
