import { Text, View } from 'dripsy';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import {
  FormPhoneInputV2,
  FormTextInputV2,
} from '../../../components/form/FormikInputs';
import { InfoRow } from '../../../components/InfoRow';
import { Screen } from '../../../navigation/types/screens';
import { Button } from '../../../ui/atoms/Button';
import { FormikFormError } from '../../../ui/v2/FormError';
import { buildInviteCoborrowerErrorMessage } from '../utils/buildFormErrorMessage';
import {
  InviteCoBorrowerField,
  InviteCoBorrowerFormValues,
  InviteCoborrowerInitialValues,
  InviteCoBorrowerLabel,
  InviteCoBorrowerValidationSchema,
} from '../utils/forms';
import { inviteCoBorrowerErrorEmitter } from '../utils/inviteCoBorrowerErrorEmitter';
import { mapInviteCoBorrowerErrorToFieldError } from '../utils/mapInviteCoBorrowerMutationError';

type BaseInviteCoBorrowerFormProps = { screen: Screen } & Partial<{
  isSubmitting: boolean;
}> & {
    formProps: FormikProps<InviteCoBorrowerFormValues>;
  };
function BaseInviteCoBorrowerForm(props: BaseInviteCoBorrowerFormProps) {
  const { screen, isSubmitting, formProps } = props;

  useEffect(() => {
    inviteCoBorrowerErrorEmitter.on('fieldLevelError', async ({ type }) => {
      mapInviteCoBorrowerErrorToFieldError(type, formProps.setFieldError);
    });

    return () => {
      inviteCoBorrowerErrorEmitter.off('fieldLevelError');
    };
  }, [formProps.setFieldError]);

  const formPopulated =
    formProps.values.email &&
    formProps.values.firstName &&
    formProps.values.lastName &&
    formProps.values.mobile &&
    Object.keys(formProps.errors).length === 0;

  return (
    <View pointerEvents={isSubmitting ? 'none' : undefined}>
      <Text variant="sHeader">
        {t('Content.InviteCoBorrower.Form.NameFieldsTitle')}
      </Text>
      <FormTextInputV2
        name={InviteCoBorrowerField.FirstName}
        label={InviteCoBorrowerLabel[InviteCoBorrowerField.FirstName]}
        inputTestID={TestID.InviteCoBorrower.FirstNameInput}
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Borrowers,
          screen,
          FieldInteractionKey.FirstName,
        )}
      />
      <FormikFormError
        name={InviteCoBorrowerField.FirstName}
        errorMessageParser={buildInviteCoborrowerErrorMessage}
        sx={{ mt: '$8' }}
      />

      <FormTextInputV2
        name={InviteCoBorrowerField.LastName}
        label={InviteCoBorrowerLabel[InviteCoBorrowerField.LastName]}
        inputTestID={TestID.InviteCoBorrower.LastNameInput}
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Borrowers,
          screen,
          FieldInteractionKey.LastName,
        )}
      />
      <FormikFormError
        name={InviteCoBorrowerField.LastName}
        errorMessageParser={buildInviteCoborrowerErrorMessage}
        sx={{ mt: '$8' }}
      />

      <Text variant="sHeader" sx={{ mt: '$32' }}>
        {t('Content.InviteCoBorrower.Form.MobileFieldTitle')}
      </Text>
      <FormPhoneInputV2
        name={InviteCoBorrowerField.Mobile}
        label={InviteCoBorrowerLabel[InviteCoBorrowerField.Mobile]}
        inputTestID={TestID.InviteCoBorrower.MobileNumberInput}
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Borrowers,
          screen,
          FieldInteractionKey.MobileNumber,
        )}
      />
      <FormikFormError
        name={InviteCoBorrowerField.Mobile}
        errorMessageParser={buildInviteCoborrowerErrorMessage}
        sx={{ mt: '$8' }}
      />

      <Text variant="sHeader" sx={{ mt: '$32' }}>
        {t('Content.InviteCoBorrower.Form.EmailFieldTitle')}
      </Text>
      <FormTextInputV2
        name={InviteCoBorrowerField.Email}
        label={InviteCoBorrowerLabel[InviteCoBorrowerField.Email]}
        inputTestID={TestID.InviteCoBorrower.EmailInput}
        sx={{ mt: '$16' }}
        interactionKey={buildApplicationInteractionEventKey(
          SectionInteractionKey.Borrowers,
          screen,
          FieldInteractionKey.EmailAddress,
        )}
      />
      <FormikFormError
        name={InviteCoBorrowerField.Email}
        errorMessageParser={buildInviteCoborrowerErrorMessage}
        sx={{ mt: '$8' }}
      />

      {formPopulated ? (
        <InfoRow mode="warning" mt="m">
          <Text variant="caption" sx={{ flex: 1, color: '$labelsPrimary' }}>
            {t('Content.InviteCoBorrower.CoBorrowerInviteEmailNotice', {
              coborrower: formProps.values.firstName,
            })}
          </Text>
        </InfoRow>
      ) : null}

      <Text
        variant="caption"
        sx={{ mt: '$24', px: '$16', py: '$8', textAlign: 'center' }}
      >
        {t('Content.InviteCoBorrower.Form.ConfirmCoborrowerInformation')}
      </Text>
      <Button
        onPress={() => formProps.handleSubmit()}
        label={t('Content.Common.ButtonLabel.Done')}
        mt="s"
        alignSelf="stretch"
        showSpinner={isSubmitting}
        disabled={isSubmitting}
        testID={TestID.InviteCoBorrower.DoneButton}
      />
    </View>
  );
}

export type InviteCoBorrowerFormProps = Omit<
  BaseInviteCoBorrowerFormProps,
  'formProps'
> & { screen: Screen } & Partial<{
    onSubmit: (
      values: InviteCoBorrowerFormValues,
      formikHelpers: FormikHelpers<InviteCoBorrowerFormValues>,
    ) => void;
  }>;

export function InviteCoBorrowerForm(props: InviteCoBorrowerFormProps) {
  const { onSubmit = () => {}, ...otherProps } = props;

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={InviteCoBorrowerValidationSchema}
      initialValues={InviteCoborrowerInitialValues}
    >
      {(formProps) => (
        <BaseInviteCoBorrowerForm formProps={formProps} {...otherProps} />
      )}
    </Formik>
  );
}
