import { A, Pressable, styled, Text, useDripsyTheme, View } from 'dripsy';
import { FieldArray, Formik } from 'formik';
import { Fragment } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCurrencyInputV2,
  FormRadioInputV2,
  FormSelectV2,
  FormTextInputV2,
  FormTextPickerInputV2,
} from '../../components/form/FormikInputs';
import { PickerOption, PickerOptions } from '../../components/form/types';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { CloseIcon } from '../../ui/svgs/CloseIcon';
import { FormikFormError } from '../../ui/v2/FormError';
import { TextLink } from '../../ui/v2/Text';
import { makeTestId } from '../../utils/stringHelpers';
import { yup } from '../../utils/yup';
import { IncomeYesNoEnum } from '../utils/incomeFormTypes';

export enum EmploymentIncomeFormFields {
  EmployerName = 'employerName',
  EmploymentType = 'employmentType',
  OccupationType = 'occupationType',
  IsPayingHECS = 'isPayingHECS',
  HasVoluntaryDeductions = 'hasVoluntaryDeductions',
  HasNovatedLeases = 'hasNovatedLeases',
  NovatedLeaseMonthlyRepayments = 'novatedLeaseMonthlyRepayments',
  CanStopVoluntaryDeductions = 'canStopVoluntaryDeductions',
  HecsLoanBalance = 'hecsLoanBalance',
  Salary = 'salary',
  Commissions = 'commissions',
  Allowances = 'allowances',
  Bonuses = 'bonuses',
  Overtime = 'overtime',
  PayeeApplicantId = 'payeeApplicantId',
}

const baseEmploymentIncomeSchemaObj = {
  [EmploymentIncomeFormFields.EmployerName]: yup
    .string()
    .required(t('Content.YourIncome.Form.EmployerNameError')),
  [EmploymentIncomeFormFields.EmploymentType]: yup
    .string()
    .nullable()
    .required(t('Content.YourIncome.Form.EmploymentTypeError')),
  [EmploymentIncomeFormFields.OccupationType]: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string().required(),
    })
    .nullable()
    .required(),
  [EmploymentIncomeFormFields.Salary]: yup
    .number()
    .nullable()
    .required(t('Content.YourIncome.Form.SalaryError')),
  [EmploymentIncomeFormFields.Commissions]: yup.number().nullable(),
  [EmploymentIncomeFormFields.Allowances]: yup.number().nullable(),
  [EmploymentIncomeFormFields.Bonuses]: yup.number().nullable(),
  [EmploymentIncomeFormFields.Overtime]: yup.number().nullable(),
  [EmploymentIncomeFormFields.PayeeApplicantId]: yup
    .array()
    .of(yup.string().required())
    .nullable()
    .min(1)
    .max(1)
    .required(),
};

const hecsFields = {
  [EmploymentIncomeFormFields.IsPayingHECS]: yup
    .string()
    .required(t('Content.YourIncome.Form.HECSError')),
  [EmploymentIncomeFormFields.HecsLoanBalance]: yup
    .number()
    .nullable()
    .when([EmploymentIncomeFormFields.IsPayingHECS], {
      is: IncomeYesNoEnum.Yes,
      then: yup.number().nullable().required(),
    }),
};

const MAX_NOVATED_LEASES = 5;

const novatedLeaseFields = {
  [EmploymentIncomeFormFields.HasNovatedLeases]: yup
    .string()
    .required(t('Content.YourIncome.Form.NovatedLeasesError')),
  [EmploymentIncomeFormFields.NovatedLeaseMonthlyRepayments]: yup
    .array()
    .when([EmploymentIncomeFormFields.HasNovatedLeases], {
      is: IncomeYesNoEnum.Yes,
      then: yup
        .array()
        .of(
          yup
            .number()
            .nullable()
            .required(
              t('Content.YourIncome.Form.NovatedLeaseMonthlyRepaymentError'),
            ),
        )
        .min(1)
        .max(MAX_NOVATED_LEASES)
        .required(),
    }),
};
function getEmploymentIncomeValidationSchema({
  hideHecsFields,
  hideNovatedLeaseFields,
}: {
  hideHecsFields?: boolean;
  hideNovatedLeaseFields?: boolean;
}) {
  return yup.object({
    ...baseEmploymentIncomeSchemaObj,
    ...(hideHecsFields ? {} : hecsFields),
    ...(hideNovatedLeaseFields ? {} : novatedLeaseFields),
    [EmploymentIncomeFormFields.HasVoluntaryDeductions]: yup
      .string()
      .required(t('Content.YourIncome.Form.VoluntaryDeductionsError')),
    [EmploymentIncomeFormFields.CanStopVoluntaryDeductions]: yup
      .string()
      .nullable()
      .when([EmploymentIncomeFormFields.HasVoluntaryDeductions], {
        is: IncomeYesNoEnum.Yes,
        then: yup
          .string()
          .nullable()
          .required(
            t('Content.YourIncome.Form.WillingToStopVoluntaryDeductionsError'),
          ),
      }),
  });
}

export type EmploymentIncomeFormDetails = {
  [EmploymentIncomeFormFields.EmployerName]: string | undefined;
  [EmploymentIncomeFormFields.EmploymentType]: string | undefined;
  [EmploymentIncomeFormFields.OccupationType]: PickerOption<string> | undefined;
  [EmploymentIncomeFormFields.Salary]: number | undefined;
  [EmploymentIncomeFormFields.Allowances]: number | undefined;
  [EmploymentIncomeFormFields.Bonuses]: number | undefined;
  [EmploymentIncomeFormFields.Commissions]: number | undefined;
  [EmploymentIncomeFormFields.Overtime]: number | undefined;
  [EmploymentIncomeFormFields.IsPayingHECS]: IncomeYesNoEnum | undefined;
  [EmploymentIncomeFormFields.HecsLoanBalance]: number | undefined;

  [EmploymentIncomeFormFields.HasVoluntaryDeductions]:
    | IncomeYesNoEnum
    | undefined;
  [EmploymentIncomeFormFields.CanStopVoluntaryDeductions]:
    | IncomeYesNoEnum
    | undefined;

  [EmploymentIncomeFormFields.HasNovatedLeases]: IncomeYesNoEnum | undefined;
  [EmploymentIncomeFormFields.NovatedLeaseMonthlyRepayments]:
    | Array<number | undefined>
    | undefined;

  [EmploymentIncomeFormFields.PayeeApplicantId]: Array<string> | undefined;
};

export const DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES: EmploymentIncomeFormDetails =
  {
    employerName: undefined,
    employmentType: undefined,
    occupationType: undefined,
    salary: undefined,
    allowances: undefined,
    bonuses: undefined,
    commissions: undefined,
    overtime: undefined,
    hecsLoanBalance: undefined,
    hasNovatedLeases: undefined,
    novatedLeaseMonthlyRepayments: [undefined],
    isPayingHECS: undefined,
    hasVoluntaryDeductions: undefined,
    canStopVoluntaryDeductions: undefined,
    payeeApplicantId: undefined,
  };

export enum EmploymentIncomeFormSubmitLabel {
  Done = 'Done',
  Next = 'Next',
}

export type EmploymentIncomeFormProps = {
  screen: Screen;
  submitLabel?: EmploymentIncomeFormSubmitLabel;
  initialValues?: EmploymentIncomeFormDetails;
  employmentTypeOptions: PickerOptions<string>;
  occupationTypeOptions: PickerOptions<string>;
  payeeOptions: PickerOptions<string>;
  isSubmitting?: boolean;
  onSubmit: (values: EmploymentIncomeFormDetails) => Promise<void>;
  showDeleteButton?: boolean;
  onDelete?: () => Promise<void>;
  hideHecsFields?: boolean;
  hideNovatedLeaseFields?: boolean;
};

export function EmploymentIncomeForm({
  screen,
  submitLabel = EmploymentIncomeFormSubmitLabel.Done,
  initialValues = DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES,
  occupationTypeOptions = [],
  employmentTypeOptions = [],
  payeeOptions = [],
  isSubmitting,
  onSubmit,
  showDeleteButton,
  onDelete,
  hideHecsFields,
  hideNovatedLeaseFields,
}: EmploymentIncomeFormProps) {
  const { theme: themeV2 } = useDripsyTheme();
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getEmploymentIncomeValidationSchema({
        hideHecsFields,
        hideNovatedLeaseFields,
      })}
    >
      {(formProps) => {
        const shouldShowHecsLoanBalance =
          formProps.values.isPayingHECS === IncomeYesNoEnum.Yes;
        const shouldShowWillingToStopVoluntaryDeductions =
          formProps.values.hasVoluntaryDeductions === IncomeYesNoEnum.Yes;
        const shouldShowNovatedLeaseRepayment =
          formProps.values.hasNovatedLeases === IncomeYesNoEnum.Yes;

        return (
          <View pointerEvents={isSubmitting ? 'none' : undefined}>
            <SHeaderText>
              {t('Content.YourIncome.Form.EnterEmploymentDetails')}
            </SHeaderText>
            <FormTextInputV2
              name={EmploymentIncomeFormFields.EmployerName}
              label={t('Content.YourIncome.Form.EmployerName')}
              inputTestID={TestID.EmploymentIncomeForm.EmployerName}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.EmployerName,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.EmployerName}
              sx={{ mt: '$8' }}
            />
            <FormSelectV2
              name={EmploymentIncomeFormFields.EmploymentType}
              label={t('Content.YourIncome.Form.EmploymentType')}
              testID={TestID.EmploymentIncomeForm.EmploymentType}
              items={employmentTypeOptions}
              sx={{ mt: '$16' }}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.EmploymentType}
              sx={{ mt: '$8' }}
            />
            <FormTextPickerInputV2
              name={EmploymentIncomeFormFields.OccupationType}
              optionsData={occupationTypeOptions}
              label={t('Content.YourIncome.Form.OccupationType')}
              inputTestID={TestID.EmploymentIncomeForm.OccupationPicker}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.OccupationType,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.OccupationType}
              sx={{ mt: '$8' }}
              errorMessageParser={() =>
                t('Content.YourIncome.Form.OccupationTypeError')
              }
            />
            <SHeaderText sx={{ mt: '$32' }}>
              {t('Content.YourIncome.Form.EnterAnnualIncome')}
            </SHeaderText>
            <FormCurrencyInputV2
              name={EmploymentIncomeFormFields.Salary}
              label={t('Content.YourIncome.Form.Salary')}
              inputTestID={TestID.EmploymentIncomeForm.SalaryInput}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.Salary,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.Salary}
              sx={{ mt: '$8' }}
            />
            <FormCurrencyInputV2
              name={EmploymentIncomeFormFields.Overtime}
              label={t('Content.YourIncome.Form.Overtime')}
              inputTestID={TestID.EmploymentIncomeForm.OvertimeInput}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.Overtime,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.Overtime}
              sx={{ mt: '$8' }}
            />
            <FormCurrencyInputV2
              name={EmploymentIncomeFormFields.Bonuses}
              label={t('Content.YourIncome.Form.Bonus')}
              inputTestID={TestID.EmploymentIncomeForm.BonusesInput}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.Bonus,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.Bonuses}
              sx={{ mt: '$8' }}
            />
            <FormCurrencyInputV2
              name={EmploymentIncomeFormFields.Commissions}
              label={t('Content.YourIncome.Form.Commissions')}
              inputTestID={TestID.EmploymentIncomeForm.CommissionsInput}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.Commissions,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.Commissions}
              sx={{ mt: '$8' }}
            />
            <FormCurrencyInputV2
              name={EmploymentIncomeFormFields.Allowances}
              label={t('Content.YourIncome.Form.Allowances')}
              inputTestID={TestID.EmploymentIncomeForm.AllowancesInput}
              sx={{ mt: '$16' }}
              interactionKey={buildApplicationInteractionEventKey(
                SectionInteractionKey.Income,
                screen,
                FieldInteractionKey.Allowances,
              )}
            />
            <FormikFormError
              name={EmploymentIncomeFormFields.Allowances}
              sx={{ mt: '$8' }}
            />
            {hideHecsFields ? null : (
              <>
                <SHeaderText sx={{ mt: '$32' }}>
                  {t('Content.YourIncome.Form.EnterHECS')}
                </SHeaderText>
                <RowView sx={{ mt: '$16' }}>
                  <FormRadioInputV2
                    name={EmploymentIncomeFormFields.IsPayingHECS}
                    value={IncomeYesNoEnum.Yes}
                    testID={makeTestId([
                      TestID.EmploymentIncomeForm.IsPayingHECS,
                      IncomeYesNoEnum.Yes,
                    ])}
                    label={t('Content.Common.ButtonLabel.Yes')}
                    containerStyle={{
                      mr: '$16',
                      flex: 1,
                    }}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.Income,
                      screen,
                      FieldInteractionKey.IsPayingHECS,
                    )}
                  />
                  <FormRadioInputV2
                    name={EmploymentIncomeFormFields.IsPayingHECS}
                    value={IncomeYesNoEnum.No}
                    testID={makeTestId([
                      TestID.EmploymentIncomeForm.IsPayingHECS,
                      IncomeYesNoEnum.No,
                    ])}
                    label={t('Content.Common.ButtonLabel.No')}
                    containerStyle={{
                      flex: 1,
                    }}
                    interactionKey={buildApplicationInteractionEventKey(
                      SectionInteractionKey.Income,
                      screen,
                      FieldInteractionKey.IsPayingHECS,
                    )}
                  />
                </RowView>
                {shouldShowHecsLoanBalance ? (
                  <>
                    <FormCurrencyInputV2
                      name={EmploymentIncomeFormFields.HecsLoanBalance}
                      label={t('Content.YourIncome.Form.LoanBalance')}
                      inputTestID={TestID.EmploymentIncomeForm.HECSBalance}
                      sx={{ mt: '$16' }}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Income,
                        screen,
                        FieldInteractionKey.HecsHelpMonthlyRepayment,
                      )}
                    />
                    <FormikFormError
                      name={EmploymentIncomeFormFields.HecsLoanBalance}
                      sx={{ mt: '$8' }}
                    />
                    {/* TODO: Check whether we need to create a v2 Link component */}
                    <Link
                      href={t('Link.HECSHelpUrl')}
                      style={{
                        marginTop: 8,
                        fontSize: 13,
                        lineHeight: 17,
                      }}
                    >
                      {t('Content.YourIncome.Form.HECSHelp')}
                    </Link>
                  </>
                ) : null}
                <FormikFormError
                  name={EmploymentIncomeFormFields.IsPayingHECS}
                  sx={{ mt: '$8' }}
                />
              </>
            )}
            <>
              {hideNovatedLeaseFields ? null : (
                <>
                  <SHeaderText sx={{ mt: '$32' }}>
                    {t('Content.YourIncome.Form.EnterNovatedLeases')}
                  </SHeaderText>
                  <RowView sx={{ mt: '$16' }}>
                    <FormRadioInputV2
                      name={EmploymentIncomeFormFields.HasNovatedLeases}
                      value={IncomeYesNoEnum.Yes}
                      testID={makeTestId([
                        TestID.EmploymentIncomeForm.HasNovatedLeases,
                        IncomeYesNoEnum.Yes,
                      ])}
                      label={t('Content.Common.ButtonLabel.Yes')}
                      containerStyle={{
                        mr: '$16',
                        flex: 1,
                      }}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Income,
                        screen,
                        FieldInteractionKey.HasNovatedLeases,
                      )}
                    />
                    <FormRadioInputV2
                      name={EmploymentIncomeFormFields.HasNovatedLeases}
                      value={IncomeYesNoEnum.No}
                      testID={makeTestId([
                        TestID.EmploymentIncomeForm.HasNovatedLeases,
                        IncomeYesNoEnum.No,
                      ])}
                      label={t('Content.Common.ButtonLabel.No')}
                      containerStyle={{
                        flex: 1,
                      }}
                      onChangeValue={() =>
                        formProps.setFieldValue(
                          EmploymentIncomeFormFields.NovatedLeaseMonthlyRepayments,
                          DEFAULT_EMPLOYMENT_INCOME_INITIAL_VALUES[
                            EmploymentIncomeFormFields
                              .NovatedLeaseMonthlyRepayments
                          ],
                          true,
                        )
                      }
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Income,
                        screen,
                        FieldInteractionKey.HasNovatedLeases,
                      )}
                    />
                  </RowView>
                  <FormikFormError
                    name={EmploymentIncomeFormFields.HasNovatedLeases}
                    sx={{ mt: '$8' }}
                  />
                </>
              )}
              {shouldShowNovatedLeaseRepayment ? (
                <FieldArray
                  name={
                    EmploymentIncomeFormFields.NovatedLeaseMonthlyRepayments
                  }
                  render={(arrayHelpers) => {
                    const monthlyRepaymentValues =
                      formProps.values.novatedLeaseMonthlyRepayments;
                    const shouldHideRemoveButton =
                      monthlyRepaymentValues?.length === 1;

                    return (
                      <>
                        {monthlyRepaymentValues?.map((_value, index) => {
                          const fieldName = `${EmploymentIncomeFormFields.NovatedLeaseMonthlyRepayments}.${index}`;

                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Fragment key={index}>
                              <RowView
                                sx={{
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  width: '100%',
                                  mt: '$16',
                                }}
                              >
                                <FormCurrencyInputV2
                                  name={fieldName}
                                  label={t(
                                    'Content.YourIncome.Form.MonthlyRepayment',
                                  )}
                                  sx={{ flex: 1 }}
                                  interactionKey={buildApplicationInteractionEventKey(
                                    SectionInteractionKey.Income,
                                    screen,
                                    FieldInteractionKey.NovatedLeaseMonthlyRepayment,
                                  )}
                                />
                                {shouldHideRemoveButton ? null : (
                                  <Pressable
                                    onPress={() => arrayHelpers.remove(index)}
                                    sx={{ marginLeft: '$16' }}
                                    role="button"
                                  >
                                    <CloseIcon
                                      size={themeV2.iconSizes.$arrowBack}
                                    />
                                  </Pressable>
                                )}
                              </RowView>
                              <FormikFormError
                                name={fieldName}
                                sx={{ mt: '$8' }}
                              />
                            </Fragment>
                          );
                        })}
                        {monthlyRepaymentValues &&
                        monthlyRepaymentValues?.length < MAX_NOVATED_LEASES ? (
                          <TextLink
                            onPress={() => {
                              // Add new empty row
                              arrayHelpers.push(undefined);
                            }}
                            variants={['caption', 'link']}
                            sx={{ mt: '$8' }}
                          >
                            {t('Content.YourIncome.Form.AddNovatedLease')}
                          </TextLink>
                        ) : null}
                      </>
                    );
                  }}
                />
              ) : null}
              {hideNovatedLeaseFields ? null : (
                <Text variant="caption" sx={{ mt: '$8' }}>
                  {t('Content.YourIncome.Form.NovatedLeasesDescription')}
                </Text>
              )}

              <SHeaderText sx={{ mt: '$32' }}>
                {t('Content.YourIncome.Form.EnterVoluntaryDeductions')}
              </SHeaderText>
              <RowView sx={{ mt: '$16' }}>
                <FormRadioInputV2
                  name={EmploymentIncomeFormFields.HasVoluntaryDeductions}
                  value={IncomeYesNoEnum.Yes}
                  testID={makeTestId([
                    TestID.EmploymentIncomeForm.HasVoluntaryDeductions,
                    IncomeYesNoEnum.Yes,
                  ])}
                  label={t('Content.Common.ButtonLabel.Yes')}
                  containerStyle={{
                    mr: '$16',
                    flex: 1,
                  }}
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Income,
                    screen,
                    FieldInteractionKey.HasVoluntaryDeductions,
                  )}
                />
                <FormRadioInputV2
                  name={EmploymentIncomeFormFields.HasVoluntaryDeductions}
                  value={IncomeYesNoEnum.No}
                  testID={makeTestId([
                    TestID.EmploymentIncomeForm.HasVoluntaryDeductions,
                    IncomeYesNoEnum.No,
                  ])}
                  label={t('Content.Common.ButtonLabel.No')}
                  containerStyle={{
                    flex: 1,
                  }}
                  onChangeValue={() =>
                    formProps.setFieldValue(
                      EmploymentIncomeFormFields.CanStopVoluntaryDeductions,
                      // Setting field value to undefined prevents error for this field from showing up
                      // https://github.com/jaredpalmer/formik/issues/2332
                      null,
                      true,
                    )
                  }
                  interactionKey={buildApplicationInteractionEventKey(
                    SectionInteractionKey.Income,
                    screen,
                    FieldInteractionKey.HasVoluntaryDeductions,
                  )}
                />
              </RowView>
              <FormikFormError
                name={EmploymentIncomeFormFields.HasVoluntaryDeductions}
                sx={{ mt: '$8' }}
              />
              <RowView
                sx={{
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  pt: '$8',
                  flex: 1,
                }}
              >
                <Text variant="caption">
                  {t('Content.YourIncome.Form.VoluntaryDeductionsDescription')}{' '}
                </Text>
                <A
                  variants={['caption', 'link']}
                  href={t('Link.WhatDeductionsDoINeed')}
                  hrefAttrs={{ rel: 'noreferrer', target: '_blank' }}
                >
                  {t('Content.Common.ButtonLabel.LearnMore')}
                </A>
              </RowView>

              {shouldShowWillingToStopVoluntaryDeductions ? (
                <>
                  <SHeaderText sx={{ mt: '$32' }}>
                    {t('Content.YourIncome.Form.CanStopVoluntaryDeductions')}
                  </SHeaderText>
                  <RowView sx={{ mt: '$16' }}>
                    <FormRadioInputV2
                      name={
                        EmploymentIncomeFormFields.CanStopVoluntaryDeductions
                      }
                      value={IncomeYesNoEnum.Yes}
                      testID={makeTestId([
                        TestID.EmploymentIncomeForm.CanStopVoluntaryDeductions,
                        IncomeYesNoEnum.Yes,
                      ])}
                      label={t('Content.Common.ButtonLabel.Yes')}
                      containerStyle={{
                        mr: '$16',
                        flex: 1,
                      }}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Income,
                        screen,
                        FieldInteractionKey.CanStopVoluntaryDeductions,
                      )}
                    />
                    <FormRadioInputV2
                      name={
                        EmploymentIncomeFormFields.CanStopVoluntaryDeductions
                      }
                      value={IncomeYesNoEnum.No}
                      testID={makeTestId([
                        TestID.EmploymentIncomeForm.CanStopVoluntaryDeductions,
                        IncomeYesNoEnum.No,
                      ])}
                      label={t('Content.Common.ButtonLabel.No')}
                      containerStyle={{
                        flex: 1,
                      }}
                      interactionKey={buildApplicationInteractionEventKey(
                        SectionInteractionKey.Income,
                        screen,
                        FieldInteractionKey.CanStopVoluntaryDeductions,
                      )}
                    />
                  </RowView>
                  <FormikFormError
                    name={EmploymentIncomeFormFields.CanStopVoluntaryDeductions}
                    sx={{ mt: '$8' }}
                  />
                  {formProps.values.canStopVoluntaryDeductions ===
                  IncomeYesNoEnum.Yes ? (
                    <Text variant="caption" sx={{ pt: '$8' }}>
                      {t(
                        'Content.YourIncome.Form.WillingToStopVoluntaryDeductionsDescription',
                      )}
                    </Text>
                  ) : null}
                  {formProps.values.canStopVoluntaryDeductions ===
                  IncomeYesNoEnum.No ? (
                    <Text variant="caption" sx={{ pt: '$8' }}>
                      {t(
                        'Content.YourIncome.Form.NotWillingToStopVoluntaryDeductionsDescription',
                      )}
                    </Text>
                  ) : null}
                </>
              ) : null}
            </>
            <SHeaderText sx={{ mt: '$32', mb: '$8' }}>
              {t('Content.YourIncome.Form.EnterPaidTo')}
            </SHeaderText>
            {payeeOptions.map(({ value, label }) => (
              <FormRadioInputV2
                checkboxCompatible
                key={value}
                name={EmploymentIncomeFormFields.PayeeApplicantId}
                testID={makeTestId([TestID.EmploymentIncomeForm.PaidTo, value])}
                value={value}
                label={label}
                containerStyle={{
                  mt: '$8',
                }}
                interactionKey={buildApplicationInteractionEventKey(
                  SectionInteractionKey.Income,
                  screen,
                  FieldInteractionKey.WhoIsThisIncomePaidTo,
                )}
              />
            ))}
            <FormikFormError
              name={EmploymentIncomeFormFields.PayeeApplicantId}
              sx={{ mt: '$8' }}
              errorMessageParser={() =>
                t('Content.YourIncome.Form.IncomeOwnerError')
              }
            />
            <BottomSpacer />
            <Button
              label={
                submitLabel === EmploymentIncomeFormSubmitLabel.Done
                  ? t('Content.Common.ButtonLabel.Done')
                  : t('Content.Common.ButtonLabel.Next')
              }
              testID={TestID.EmploymentIncomeForm.SubmitButton}
              onPress={async () => {
                formProps.handleSubmit();
              }}
              showSpinner={isSubmitting}
              disabled={isSubmitting}
              py="m"
              alignSelf="stretch"
            />
            {showDeleteButton ? (
              <Button
                label={t('Content.YourIncome.Form.DeleteIncomeButton')}
                testID={TestID.EmploymentIncomeForm.DeleteButton}
                onPress={onDelete}
                tertiary
                disabled={isSubmitting}
                fontWeight="normal"
                alignSelf="stretch"
                mt="s"
                style={{
                  paddingVertical: 0,
                }}
              />
            ) : null}
          </View>
        );
      }}
    </Formik>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});
const BottomSpacer = styled(View)({
  mb: '$32',
});
