import { gql } from '@apollo/client';
import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { StyleSheet } from 'react-native';
import { React } from 'sentry-expo';

import { TestID } from '../../../testID/constants';
import { GTMEvent } from '../../Analytics/types';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { RefreshPageErrorRow } from '../../components/RefreshPageErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import useSubmitLoanApplication from '../../FinancialDeclaration/utils/useSubmitLoanApplication';
import {
  Income_Type_Enum,
  Loan_Application_Status_Info_Stage_Enum,
  Loan_Application_Type_Enum,
  refetchGetDisclosureContentQuery,
  Rental_Income_Type_Enum,
  ReviewLoanApplicationQuery,
  Submit_Loan_Application_Error_Type,
  useAcceptDisclosureMutation,
} from '../../generated/graphql';
import { InvalidLoanApplication } from '../../LoanApplication/components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../../LoanApplication/components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../../LoanApplication/components/LoanApplicationWizardFooter';
import { LoanScreenHeader } from '../../LoanApplication/components/LoanScreenHeader';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ScrollContainerRefContext } from '../../LoanApplication/navigation/Navigator.web';
import { LoanApplicationV2ScreenProps } from '../../LoanApplication/navigation/types';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../../LoanApplication/utils/loanApplicationUtils';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Spinner } from '../../ui/atoms/Spinner';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { useSecurityCostCalculationState } from '../../utils/hooks/useSecurityCostCalculationState';
import { useSendLoanAppEventToGTM } from '../../utils/hooks/useSendDataToGTM';
import { isWeb } from '../../utils/platformUtils';
import {
  ApplicationSummaryDetailsV2,
  SummarySectionHeaderOnPressTypes,
} from '../components/ApplicationSummaryDetailsV2';
import { SummarySectionItemOnPressTypes } from '../components/SummarySectionV2';
import { useReviewApplicationQuery } from '../remoteData';
import { useNavigateFromApplicationSummary } from '../utils/useNavigateFromApplicationSummary';

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY>;

export const AcceptDisclosure = gql`
  mutation AcceptDisclosure($loanApplicationId: uuid!) {
    accept_cash_out_and_debt_consolidation_disclosure(
      data: { loan_application_id: $loanApplicationId }
    ) {
      loan_application_id
    }
  }
`;

function getAppSummaryDetailsMode(
  statusInfo: NonNullable<
    ReviewLoanApplicationQuery['loan_application_by_pk']
  >['status_info'],
): ComponentProps<typeof ApplicationSummaryDetailsV2>['mode'] {
  if (statusInfo?.can_user_edit) {
    if (
      statusInfo?.loan_application_stage ===
        Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved ||
      statusInfo?.loan_application_stage ===
        Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved
    ) {
      return 'PARTIAL_EDIT_FOR_VCA';
    }

    return 'EDIT';
  }

  return 'VIEW';
}

function ReviewApplicationSummaryBase({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId ?? '';

  const { navigateFromAppSummary } =
    useNavigateFromApplicationSummary(navigation);
  const [acceptDisclosure, { loading: acceptDisclosureLoading }] =
    useAcceptDisclosureMutation();
  const {
    data: queryRes,
    sectionData,
    loading: reviewApplicationLoading,
    error: reviewApplicationError,
    refetch,
  } = useReviewApplicationQuery({
    loanApplicationId,
  });

  const sendLoanAppEventToGTM = useSendLoanAppEventToGTM();

  // Used to detect if there's an error in security cost calculation
  const {
    waitingForCostCalculationToBeCompleted,
    isWaitingForRetryToComplete,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    onCalculationComplete: async () => {
      await refetch();
    },
  });

  const { submitLoanApplication, loading: submitLoading } =
    useSubmitLoanApplication();
  const { loanApplicationType } = sectionData;

  const {
    navigateToNextLoanApplicationScreen,
    navigateToLoanApplicationScreen,
  } = useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  // Define which screen to navigate to when adding item to each section
  const onSectionHeaderPress: SummarySectionHeaderOnPressTypes = {
    borrowers: () => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Borrowers,
      });
    },
    income: () => {
      navigateFromAppSummary(Screen.YOUR_INCOME_V2_MODAL, {
        screen: Screen.YOUR_INCOME_V2_ADD_INCOME_MODAL,
        params: {
          loanApplicationId,
          hideNovatedLeaseFields: true,
        },
      });
    },
    expense: () => {
      navigateFromAppSummary(Screen.YOUR_EXPENSES_V2_WIZARD, {
        screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
        params: {
          loanApplicationId,
        },
      });
    },
    liabilities: () => {
      navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
        screen: Screen.DEBTS_ADD_MANUAL_LIABILITY_V2_MODAL,
        params: {
          loanApplicationId,
        },
      });
    },
  };

  // Define which screen to navigate to when editing an item for each section
  const onSectionItemPress: SummarySectionItemOnPressTypes = {
    loan: ({ targetLoanId }) => {
      switch (loanApplicationType) {
        case Loan_Application_Type_Enum.Purchase:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.SETUP_LOAN_FOR_PURCHASE_MODAL,
            params: {
              loanApplicationId,
            },
          });
        case Loan_Application_Type_Enum.TopUp:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.SETUP_LOAN_FOR_TOP_UP_MODAL,
            params: {
              loanApplicationId,
              loanApplicationTargetId: targetLoanId,
            },
          });
        default:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.SETUP_LOAN_V2_MODAL,
            params: {
              loanApplicationId,
              loanApplicationTargetId: targetLoanId,
            },
          });
      }
    },
    property: (data) => {
      switch (loanApplicationType) {
        case Loan_Application_Type_Enum.Purchase:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.YOUR_PROPERTY_PURCHASE_MODAL,
            params: {
              loanApplicationId,
              loanApplicationSecurityId: data.loanApplicationSecurityId,
            },
          });
        case Loan_Application_Type_Enum.TopUp:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.TOP_UP_PROPERTY_VALUE_MODAL,
            params: {
              loanApplicationId,
              loanApplicationSecurityId: data.loanApplicationSecurityId,
            },
          });
        default:
          return navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
            screen: Screen.YOUR_PROPERTY_V2_MODAL,
            params: {
              loanApplicationId,
              loanApplicationSecurityId: data.loanApplicationSecurityId,
            },
          });
      }
    },
    borrowers: (data) => {
      navigateFromAppSummary(Screen.BORROWER_V2_MODAL, {
        screen: Screen.BORROWER_V2_DETAILS,
        params: {
          loanApplicationId,
          borrowerId: data.id,
          isCurrentLoggedInApplicant: data.is_current_logged_in_applicant,
        },
      });
    },
    income: (data) => {
      const { incomeType, id } = data;
      if (incomeType === Income_Type_Enum.Employment) {
        navigateFromAppSummary(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL,
          params: {
            loanApplicationId,
            incomeId: id,
          },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.Rental) {
        // Expected rental income won't be visible in Review Your Application
        // per https://unloan.atlassian.net/browse/UDEL-984
        const rentalIncomeType =
          data.rentalIncome?.rental_income_type ??
          Rental_Income_Type_Enum.Existing;

        if (rentalIncomeType === Rental_Income_Type_Enum.Expected) {
          return;
        }

        navigateFromAppSummary(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_RENTAL_INCOME_MODAL,
          params: {
            loanApplicationId,
            incomeId: id,
          },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.GovernmentPayments) {
        navigateFromAppSummary(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL,
          params: {
            loanApplicationId,
            incomeId: id,
          },
        });
        return;
      }
      if (incomeType === Income_Type_Enum.ShareDividends) {
        navigateFromAppSummary(Screen.YOUR_INCOME_V2_MODAL, {
          screen: Screen.YOUR_INCOME_V2_EDIT_DIVIDEND_INCOME_MODAL,
          params: {
            loanApplicationId,
            incomeId: id,
          },
        });
      }
    },
    expense: (data) => {
      navigateFromAppSummary(Screen.YOUR_EXPENSES_V2_WIZARD, {
        screen: Screen.YOUR_EXPENSES_V2_WIZARD_MONTHLY_EXPENSES_DETAILS,
        params: {
          loanApplicationId,
          householdId: data.id,
        },
      });
    },
    liabilities: (data) => {
      const {
        currentLiabilityId,
        type,
        detectedLiabilityIdentifier,
        liabilityManuallyAdded,
      } = data;

      navigateFromAppSummary(Screen.SINGLE_V2_MODAL, {
        screen: Screen.DEBTS_EDIT_LIABILITY_V2_MODAL,
        params: {
          loanApplicationId,
          liabilityType: type,
          currentLiabilityId: currentLiabilityId || undefined,
          detectedLiabilityIdentifier: detectedLiabilityIdentifier || undefined,
          liabilityManuallyAdded: !!liabilityManuallyAdded,
        },
      });
    },
  };

  // Required for content inset
  const [containerPaddingBottom, setContainerPaddingBottom] =
    useState<number>(0);

  const [actionErrorMessage, setActionErrorMessage] = useState<string | null>(
    null,
  );

  const scrollContainerRef = useContext(ScrollContainerRefContext);

  const [checkboxYLocation, setCheckboxYLocation] = useState(0);
  const [showRequiredDisclosureError, setShowRequiredDisclosureError] =
    useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null,
  );

  const onDisclosureCheckboxPress = useCallback(async () => {
    setShowRequiredDisclosureError(false);

    const [res] = await safelyCallMutation(acceptDisclosure, {
      variables: { loanApplicationId: loanApplicationId || '' },
      context: { sentryContext: { loanApplicationId } },
      refetchQueries: [refetchGetDisclosureContentQuery({ loanApplicationId })],
      awaitRefetchQueries: true,
    });

    if (res?.data?.accept_cash_out_and_debt_consolidation_disclosure == null) {
      setActionErrorMessage(t('Consent.Common.Error.FailStoreConsent'));
      return;
    }

    setActionErrorMessage(null);
  }, [acceptDisclosure, loanApplicationId]);

  const submitLoan = async () => {
    setSubmitErrorMessage(null);
    if (!loanApplicationId) {
      setSubmitErrorMessage(
        t('Content.Common.Error.NoAssociatedLoanApplication'),
      );
      return;
    }

    const [res, error] = await submitLoanApplication(loanApplicationId);

    if (error) {
      setSubmitErrorMessage(t('Content.Common.Error.FailToSubmitLoan'));
      return;
    }
    const submitErrorType = res?.data?.submit_loan_application.error_type;
    if (
      submitErrorType ===
        Submit_Loan_Application_Error_Type.MissingTopUpReasonConsent ||
      submitErrorType ===
        Submit_Loan_Application_Error_Type.MissingDebtConsolidationConsent
    ) {
      scrollContainerRef?.current?.scrollTo?.({
        x: 0,
        y: checkboxYLocation - containerPaddingBottom,
        animated: true,
      });
      setShowRequiredDisclosureError(true);
      return;
    }

    if (submitErrorType) {
      // TODO(uiv2): Confirm with Chloe about how to handle other submitErrorType
      setSubmitErrorMessage(t('Content.Common.Error.FailToSubmitLoan'));
      return;
    }

    if (res?.data?.submit_loan_application?.loan_application != null) {
      setSubmitErrorMessage(null);
      sendLoanAppEventToGTM({
        event: GTMEvent.LoanAppSubmitted,
        loanApplicationDetails:
          res.data.submit_loan_application.loan_application,
      });
      navigateToNextLoanApplicationScreen({
        currentSection: LoanApplicationSection.ReviewSummary,
      });
      return;
    }

    setSubmitErrorMessage(t('Content.Common.Error.FailToSubmitLoan'));
  };

  const caption = useMemo(() => {
    switch (loanApplicationType) {
      case Loan_Application_Type_Enum.Purchase:
        return t('Content.ApplicationSummary.ReviewSummary.PurchaseCaption');
      case Loan_Application_Type_Enum.TopUp:
        return t('Content.ApplicationSummary.ReviewSummary.TopUpCaption');
      default:
        return t('Content.ApplicationSummary.ReviewSummary.RefiCaption');
    }
  }, [loanApplicationType]);

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication: {
      id: loanApplicationId,
      type: loanApplicationType,
    },
  });

  if (
    !reviewApplicationLoading &&
    loanValidationResult !== LoanValidationResult.Valid
  ) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const disableCallToAction =
    !loanApplicationId ||
    reviewApplicationLoading ||
    submitLoading ||
    waitingForCostCalculationToBeCompleted;

  const statusInfo = queryRes?.loan_application_by_pk?.status_info;

  const footerSecondaryButtonProps: Partial<
    ComponentProps<typeof LoanApplicationWizardFooter>
  > | null =
    statusInfo?.loan_application_stage ===
      Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved ||
    statusInfo?.loan_application_stage ===
      Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved
      ? {
          secondaryButtonLabel: t(
            'Content.ApplicationSummary.ReviewSummary.UpdateApplication',
          ),
          onSecondaryButtonPress: () => {
            navigation.navigate(
              ActionSheetType.UPDATE_APPLICATION_CONFIRMATION,
              {
                loanApplicationId,
              },
            );
          },
          disableSecondaryButton: disableCallToAction,
        }
      : null;

  return (
    <LoanApplicationScreenContainer
      containerStyle={[
        { paddingBottom: containerPaddingBottom ?? 0 },
        styles.contentContainer,
      ]}
      pb="m"
      px="l"
    >
      <NavHeaderSpacer />
      <ScreenErrorFallback
        error={reviewApplicationError}
        refetch={refetch}
        displayMessage={t(
          'Content.ApplicationSummary.Error.FailFetchApplicationSummary',
        )}
      >
        {shouldShowErrorForCostCalculation ? (
          <RefreshPageErrorRow
            onRefreshPress={retrySecurityCostCalculation}
            sx={{ mb: '$16' }}
            refreshDisabled={isWaitingForRetryToComplete}
            refreshLoading={isWaitingForRetryToComplete}
          />
        ) : null}
        <LoanScreenHeader
          title={t('Content.ApplicationSummary.ReviewSummary.Title')}
          caption={caption}
          mb="l"
        />
        <ErrorRow message={actionErrorMessage || submitErrorMessage} />
        {reviewApplicationLoading ? (
          <Box centered m="l" flex={1}>
            <Spinner size="large" />
          </Box>
        ) : (
          <ApplicationSummaryDetailsV2
            screen={Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY}
            sectionData={sectionData}
            onSectionItemPress={onSectionItemPress}
            onSectionHeaderPress={onSectionHeaderPress}
            mode={getAppSummaryDetailsMode(statusInfo)}
            disclosureCheckboxLoading={acceptDisclosureLoading}
            onDisclosureCheckboxPress={onDisclosureCheckboxPress}
            onCheckboxLayout={(e) => {
              if (isWeb) {
                setCheckboxYLocation(e.nativeEvent.layout.top || 0);
                return;
              }
              setCheckboxYLocation(e.nativeEvent.layout.y);
            }}
            showRequiredDisclosureError={showRequiredDisclosureError}
          />
        )}
        <LoanApplicationWizardFooter
          mt="s"
          px={0}
          footerCaption={t('Content.ApplicationSummary.ReviewSummary.Footer')}
          primaryButtonLabel={t('Content.Common.ButtonLabel.Submit')}
          isPrimaryButtonLoading={submitLoading}
          disablePrimaryButton={disableCallToAction}
          onPrimaryButtonPress={submitLoan}
          primaryButtonTestID={TestID.ReviewApplicationSummary.SubmitButton}
          onLayout={(e) =>
            setContainerPaddingBottom(e.nativeEvent.layout.height)
          }
          {...footerSecondaryButtonProps}
        />
      </ScreenErrorFallback>
    </LoanApplicationScreenContainer>
  );
}

const styles = StyleSheet.create({
  contentContainer: { flexGrow: 1 },
});

export const ReviewApplicationSummaryV2 = withAuthenticationRequired(
  ReviewApplicationSummaryBase,
);
