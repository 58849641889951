import * as React from 'react';

import { Box } from '../../ui/atoms/Box';
import { useTheme } from '../../ui/theme';

export function HomeLoanGrid({ children }: { children: React.ReactNode }) {
  const { constants } = useTheme();

  return (
    <Box width="100%" alignItems="center" overflow="visible">
      <Box
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        width="100%"
        maxWidth={constants.maxDashboardWidth}
        justifyContent={{ mobile: 'flex-start', desktop: 'space-between' }}
        alignItems={{ mobile: 'center', desktop: 'flex-start' }}
        overflow="visible"
      >
        {children}
      </Box>
    </Box>
  );
}
