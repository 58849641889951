import { useCallback } from 'react';
import * as React from 'react';

import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { SavedOnInterestCard } from '../components/InterestSavings/SavedOnInterestCard';
import { useLoanAccountInterestSavings } from '../hooks/useLoanAccountInterestSavings';
import { HomeLoanInsightsScreenProps } from '../navigation/types';

type Props =
  HomeLoanInsightsScreenProps<Screen.HOME_LOAN_INSIGHTS_INTEREST_SAVINGS>;

export const InterestSavingsInsightsScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { cbaAccountId } = route.params;
  const {
    historicalInterestSavings,
    totalInterestSavingsUntilNow,
    predictedTotalInterestSavings,
    loading,
  } = useLoanAccountInterestSavings(cbaAccountId);

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <ModalScreenContainer
      headerText={t(
        'Content.HomeLoan.Insights.TotalInterestSavings.ScreenHeader',
      )}
      onClose={onClose}
      hideBackButton
      scrollable
    >
      <ScreenLoadingContainer loading={loading}>
        <SavedOnInterestCard
          predictedTotalInterestSavings={predictedTotalInterestSavings}
          totalInterestSavingsUntilNow={totalInterestSavingsUntilNow || 0}
          historicalInterestSavings={historicalInterestSavings || []}
        />
      </ScreenLoadingContainer>
    </ModalScreenContainer>
  );
};
