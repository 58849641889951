"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth0CustomScheme = exports.nativeAuth0Config = exports.ENV_AUTH0_CONFIG = exports.AUTH0_CONFIG = exports.AUTH0_NATIVE_CONFIG = exports.AUTH0_WEB_CONFIG = void 0;
const environment_1 = require("./environment");
exports.AUTH0_WEB_CONFIG = {
    PROD: {
        openIdClientId: '5cg5xW2O3p01jYaL5RpFI9WZL2HNogls',
        openIdDomain: 'auth.unloan.com.au',
        customScheme: 'au.com.commbank.unloan',
    },
    STAGE: {
        openIdClientId: 'vuu82S9eB0LQSJr3LnQ1CCAQPIRedTTs',
        openIdDomain: 'login-stage.unloan.com.au',
        customScheme: 'au.com.commbank.unloan.stg',
    },
    TEST: {
        openIdClientId: 'vuu82S9eB0LQSJr3LnQ1CCAQPIRedTTs',
        openIdDomain: 'login-stage.unloan.com.au',
        customScheme: 'au.com.commbank.unloan.tst',
    },
    DEV: {
        openIdClientId: '2NcvdyFzEKADsGIHlz6AEqDt7VRrHSyD',
        openIdDomain: 'login-dev.unloan.com.au',
        customScheme: 'au.com.commbank.unloan.dev',
    },
    SANDBOX: {
        openIdClientId: 'NPxIqTPF99E8Iv1fXpOr32YsvZ7agGeA',
        openIdDomain: 'login-sandbox.unloan.com.au',
        customScheme: 'au.com.commbank.unloan.sandbox',
    },
};
exports.AUTH0_NATIVE_CONFIG = {
    PROD: {
        openIdClientId: 'xipbCwueNzhRJgYVOnDn43cMfULYPuou',
        openIdDomain: 'auth.unloan.com.au',
        customScheme: 'au.com.unloan',
    },
    STAGE: {
        openIdClientId: 'dEatZwUI5nStMZPUNnrLa0IV5uK2ndUi',
        openIdDomain: 'login-stage.unloan.com.au',
        customScheme: 'au.com.unloan.stg',
    },
    TEST: {
        openIdClientId: 'dEatZwUI5nStMZPUNnrLa0IV5uK2ndUi',
        openIdDomain: 'login-stage.unloan.com.au',
        customScheme: 'au.com.unloan.tst',
    },
    DEV: {
        openIdClientId: 'ojRLBPD4SFU8hCQbcZHnWL1vKmXwRL0h',
        openIdDomain: 'login-dev.unloan.com.au',
        customScheme: 'au.com.unloan.dev',
    },
    SANDBOX: {
        openIdClientId: 'mBPCz7oO0fkbzmhGBZ9iUOlfC6hIbF1k',
        openIdDomain: 'login-sandbox.unloan.com.au',
        customScheme: 'au.com.unloan.sandbox',
    },
};
exports.AUTH0_CONFIG = environment_1.isNative ? exports.AUTH0_NATIVE_CONFIG : exports.AUTH0_WEB_CONFIG;
exports.ENV_AUTH0_CONFIG = {
    [environment_1.ENVIRONMENT.PROD_V3]: exports.AUTH0_CONFIG.PROD,
    [environment_1.ENVIRONMENT.STAGING_V3]: exports.AUTH0_CONFIG.STAGE,
    [environment_1.ENVIRONMENT.TEST_V3]: exports.AUTH0_CONFIG.TEST,
    [environment_1.ENVIRONMENT.DEV_V3]: exports.AUTH0_CONFIG.DEV,
    [environment_1.ENVIRONMENT.LOCAL_DEV]: environment_1.useAuth0Sandbox
        ? exports.AUTH0_CONFIG.SANDBOX
        : exports.AUTH0_CONFIG.DEV,
};
exports.nativeAuth0Config = exports.ENV_AUTH0_CONFIG[environment_1.env];
exports.auth0CustomScheme = exports.nativeAuth0Config.customScheme;
