import { useDripsyTheme, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { Skeleton } from '../../ui/v2/Skeleton';
import { InsightWidgetContainer } from './InsightWidgetContainer';

export function HomeLoanLoadingInsight() {
  const { theme } = useDripsyTheme();

  return (
    <InsightWidgetContainer
      testID={TestID.Insights.InsightWidgetLoading}
      caption={
        <View sx={{ width: '100%', mb: '$8' }}>
          <Skeleton show width="100%" height={theme.space.$20} />
        </View>
      }
      header={<Skeleton show width="75%" height={theme.space.$24} />}
    />
  );
}
