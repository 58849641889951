import { SxProp } from 'dripsy';
import { useMemo } from 'react';

import { boxShadowTransition } from '../v2/constants';

const INITIAL_SHADOW_Y = 3;
const HOVER_SHADOW_Y = 6;

const INITIAL_SHADOW_BLUR = 40;
const HOVER_SHADOW_BLUR = 32;

const INITIAL_SHADOW_OPACITY = 0.07;
const HOVER_SHADOW_OPACITY = 0.12;

export function useCardHoverShadowStyle({
  isHover,
  excludeInitialShadow = false,
}: {
  isHover: boolean;
  excludeInitialShadow?: boolean;
}): SxProp {
  return useMemo<SxProp>(() => {
    if (isHover) {
      return {
        transition: boxShadowTransition,
        shadowColor: '#000',
        elevation: HOVER_SHADOW_Y,
        shadowOffset: {
          width: 0,
          height: HOVER_SHADOW_Y,
        },
        shadowRadius: HOVER_SHADOW_BLUR,
        shadowOpacity: HOVER_SHADOW_OPACITY,
      };
    }

    if (excludeInitialShadow) {
      return { transition: boxShadowTransition };
    }

    return {
      transition: boxShadowTransition,
      shadowColor: '#000',
      elevation: INITIAL_SHADOW_Y,
      shadowOffset: {
        width: 0,
        height: INITIAL_SHADOW_Y,
      },
      shadowRadius: INITIAL_SHADOW_BLUR,
      shadowOpacity: INITIAL_SHADOW_OPACITY,
    };
  }, [excludeInitialShadow, isHover]);
}
