import { printToFileAsync } from 'expo-print';
import { shareAsync } from 'expo-sharing';
import { useCallback } from 'react';
import { WebView } from 'react-native-webview';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import {
  useCloseButton,
  useHeaderButton,
} from '../../utils/hooks/useHeaderButton';
import usePrintIFrame from '../../utils/hooks/usePrintIFrame';
import { isWeb as defaultIsWeb } from '../../utils/platformUtils';
import { StatementsModalScreenProps } from '../navigation/types';
import { useGeneratedStatementQuery } from '../remoteData';

type Props = StatementsModalScreenProps<Screen.STATEMENTS_PREVIEW_MODAL> & {
  isWeb?: boolean;
};

export const STATEMENTS_IFRAME_ID = 'statements-iframe';

export function StatementPreview({
  navigation,
  route,
  isWeb = defaultIsWeb,
}: Props) {
  const { html, loading, error } = useGeneratedStatementQuery({
    cbaAccountId: route.params.cbaAccountId,
    endDate: route.params.endDate,
    startDate: route.params.startDate,
  });

  const printIFrame = usePrintIFrame(STATEMENTS_IFRAME_ID);

  const printToFile = useCallback(async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    const { uri } = await printToFileAsync({ html });

    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
  }, [html]);

  const print = isWeb ? printIFrame : printToFile;

  useCloseButton(navigation, {
    navigateTo: () =>
      navigation.navigate(Screen.STATEMENTS_MODAL, {
        screen: Screen.STATEMENTS_LIST_MODAL,
        params: {
          cbaAccountId: route.params.cbaAccountId,
        },
      }),
  });

  useHeaderButton(navigation, {
    iconName: 'share',
    iconSize: 'm',
    iconFamily: 'svg',
    position: 'right',
    onPress: print,
    hidden: !html,
  });

  return (
    <>
      <NavHeaderSpacer />
      <ScreenLoadingContainer loading={loading}>
        <Box
          flex={1}
          width="100%"
          height="100%"
          flexDirection="column"
          overflow="hidden"
        >
          {error ? (
            <ErrorRow
              my="m"
              message={t('Content.Common.Error.FailFetchStatement')}
            />
          ) : null}
          {isWeb ? (
            <iframe
              title={STATEMENTS_IFRAME_ID}
              id={STATEMENTS_IFRAME_ID}
              height="100%"
              width="100%"
              srcDoc={html}
            />
          ) : (
            <WebView
              testID={TestID.Statements.StatementPreviewMobile}
              height="100%"
              width="100%"
              source={{ html }}
            />
          )}
        </Box>
      </ScreenLoadingContainer>
    </>
  );
}
