import { AppAddressFormat } from '../components/form/types';
import {
  Domain_Api_Address_Input,
  Manual_Address_Input,
  Property_Purpose_Enum,
  State_Of_Issue_Enum,
} from '../generated/graphql';
import { parseEnumType } from './ensureEnumType';

type Address = {
  dpid?: string | null;
  domain_api_property_id?: string | null;
  domainApiId?: string | null;
};

function getDomainApiId(address: Address) {
  return 'domainApiId' in address
    ? address.domainApiId
    : address.domain_api_property_id;
}

export function doesAddressMatch(a: Address, b: Address) {
  if (!!a.dpid && a.dpid === b.dpid) {
    return true;
  }
  const aDomainApiId = getDomainApiId(a);
  const bDomainApiId = getDomainApiId(b);
  if (!!aDomainApiId && aDomainApiId === bDomainApiId) {
    return true;
  }
  return false;
}

export enum AddressValidationError {
  RentalIncomeAddressConflictWIthNonInvestmentSecuredProperty = 'RentalIncomeAddressConflictWIthNonInvestmentSecuredProperty',
}

type SecuredProperty = {
  address: Address;
  purpose: Property_Purpose_Enum | null | undefined;
};

export function validateAddress({
  securedProperties,
  rentalIncomeAddresses,
}: {
  securedProperties: Array<SecuredProperty>;
  rentalIncomeAddresses: Array<Address>;
}): AddressValidationError | null {
  let error: AddressValidationError | null = null;
  const nonInvestmentSecuredProperties = securedProperties.filter(
    (s) => s.purpose != null && s.purpose !== Property_Purpose_Enum.Investing,
  );

  for (const { address } of nonInvestmentSecuredProperties) {
    for (const rentalIncomeAddress of rentalIncomeAddresses) {
      if (doesAddressMatch(address, rentalIncomeAddress)) {
        error =
          AddressValidationError.RentalIncomeAddressConflictWIthNonInvestmentSecuredProperty;
      }
    }
  }

  return error;
}

export function parseManuallyInputtedAddress(
  manuallyInputtedAddress: AppAddressFormat,
): Manual_Address_Input | null {
  const {
    postcode,
    street,
    streetNo,
    unitNo,
    state,
    streetType,
    suburb,
    isProvidedByUser,
  } = manuallyInputtedAddress;

  if (!isProvidedByUser) {
    return null;
  }

  const parsedState = parseEnumType(
    State_Of_Issue_Enum,
    state?.trim(),
    'State enum for manual address input',
  );

  if (
    postcode == null ||
    street == null ||
    streetNo == null ||
    parsedState == null ||
    suburb == null
  ) {
    return null;
  }

  return {
    postcode,
    street_name: street.trim(),
    combined_street_no: streetNo.trim(),
    unit_no: unitNo?.trim(),
    state: parsedState,
    street_type: streetType,
    suburb: suburb.trim(),
  };
}

export function parseDomainAPIAddressInput(
  domainAPIAddress: AppAddressFormat,
): Domain_Api_Address_Input | null {
  const {
    displayAddress,
    domainApiId,
    postcode,
    state,
    street,
    streetNo,
    streetType,
    suburb,
    unitNo,
  } = domainAPIAddress;

  if (!domainApiId) {
    return null;
  }

  const parsedState = parseEnumType(
    State_Of_Issue_Enum,
    state?.trim(),
    'State enum for manual address input',
  );

  if (
    displayAddress == null ||
    postcode == null ||
    street == null ||
    streetNo == null ||
    parsedState == null ||
    suburb == null
  ) {
    return null;
  }

  return {
    display_address: displayAddress,
    domain_api_property_id: domainApiId,
    postcode: postcode.trim(),
    state: parsedState,
    street: street.trim(),
    street_no: streetNo.trim(),
    street_type: streetType?.trim(),
    suburb: suburb.trim(),
    unit_no: unitNo?.trim(),
  };
}

export function parseAddressInputForMutationAction(address: AppAddressFormat) {
  return {
    manualAddressInput: parseManuallyInputtedAddress(address),
    domainApiAddressInput: parseDomainAPIAddressInput(address),
  };
}

type ExpectedAddress = Partial<{
  postcode: string;
  state: string;
  suburb: string;
}>;
export function mapExpectedAddressToExpectedRentalIncomeAddressInput({
  postcode,
  state,
  suburb,
}: ExpectedAddress) {
  const parsedState = parseEnumType(
    State_Of_Issue_Enum,
    state?.trim(),
    'State enum for manual address input',
  );

  if (postcode == null || parsedState == null || suburb == null) {
    return null;
  }

  return {
    expected_postcode: postcode.trim(),
    expected_state: parsedState,
    expected_suburb: suburb.trim(),
  };
}
