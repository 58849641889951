import { yup } from '../../utils/yup';
import {
  AccountPermissionType,
  ApprovalType,
  PermissionsToUpdate,
} from '../types';

export const validationSchema = yup
  .object({
    [AccountPermissionType.Autopay]: yup
      .mixed<ApprovalType>()
      .oneOf(Object.values(ApprovalType))
      .defined(),
    [AccountPermissionType.Withdrawal]: yup
      .mixed<ApprovalType>()
      .oneOf(Object.values(ApprovalType))
      .defined(),
  })
  .required();

export type FormValues = yup.Asserts<typeof validationSchema>;

export const getInitialValues = ({
  requireApprovalForAutoPayChange = true,
  requireApprovalForWithdrawals = true,
}: {
  requireApprovalForAutoPayChange?: boolean;
  requireApprovalForWithdrawals?: boolean;
}): FormValues => ({
  [AccountPermissionType.Autopay]: requireApprovalForAutoPayChange
    ? ApprovalType.AllToApprove
    : ApprovalType.OneToApprove,
  [AccountPermissionType.Withdrawal]: requireApprovalForWithdrawals
    ? ApprovalType.AllToApprove
    : ApprovalType.OneToApprove,
});

export const getPermissionsToUpdate = (
  newValues: FormValues,
  initialValues: FormValues,
): PermissionsToUpdate => {
  const permissionsToUpdate: PermissionsToUpdate = [];

  const shouldUpdateAutoPay =
    newValues[AccountPermissionType.Autopay] !==
    initialValues[AccountPermissionType.Autopay];
  if (shouldUpdateAutoPay) {
    permissionsToUpdate.push({
      type: AccountPermissionType.Autopay,
      require_approval:
        newValues[AccountPermissionType.Autopay] === ApprovalType.AllToApprove,
    });
  }

  const shouldUpdateWithdrawal =
    newValues[AccountPermissionType.Withdrawal] !==
    initialValues[AccountPermissionType.Withdrawal];
  if (shouldUpdateWithdrawal) {
    permissionsToUpdate.push({
      type: AccountPermissionType.Withdrawal,
      require_approval:
        newValues[AccountPermissionType.Withdrawal] ===
        ApprovalType.AllToApprove,
    });
  }

  return permissionsToUpdate;
};

export const isAnyNewPermissionOneToApprove = (
  newValues: FormValues,
  initialValues: FormValues,
): boolean => {
  const newAutoPayValue = newValues[AccountPermissionType.Autopay];
  const initialAutoPayValue = initialValues[AccountPermissionType.Autopay];
  const newWithdrawalValue = newValues[AccountPermissionType.Withdrawal];
  const initialWithdrawalValue =
    initialValues[AccountPermissionType.Withdrawal];

  if (
    (newAutoPayValue !== initialAutoPayValue &&
      newAutoPayValue === ApprovalType.OneToApprove) ||
    (newWithdrawalValue !== initialWithdrawalValue &&
      newWithdrawalValue === ApprovalType.OneToApprove)
  ) {
    return true;
  }

  return false;
};
