import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const RightArrowIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg width={size} height={size} viewBox="0 0 26 22" fill="none" {...props}>
    <Path
      d="M25.6875 11.1289C25.6875 10.7871 25.5508 10.4727 25.2773 10.2129L16.2129 1.17578C15.9121 0.875 15.625 0.765625 15.2969 0.765625C14.627 0.765625 14.1074 1.25781 14.1074 1.94141C14.1074 2.26953 14.2168 2.58398 14.4355 2.80273L17.498 5.91992L22.0918 10.1172L18.7969 9.91211H1.50195C0.791016 9.91211 0.298828 10.418 0.298828 11.1289C0.298828 11.8398 0.791016 12.3457 1.50195 12.3457H18.7969L22.1055 12.1406L17.498 16.3379L14.4355 19.4551C14.2168 19.6602 14.1074 19.9883 14.1074 20.3164C14.1074 21 14.627 21.4922 15.2969 21.4922C15.625 21.4922 15.9121 21.3691 16.1855 21.1094L25.2773 12.0449C25.5508 11.7852 25.6875 11.4707 25.6875 11.1289Z"
      fill={color}
    />
  </Svg>
);
