import { SxProp } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import {
  getTransactionAmountLabel,
  getTransactionTypeLabel,
} from '../../components/utils/getTransactionTypeLabel';
import { Cb_Transaction } from '../../generated/graphql';
import { LoadingRow } from '../../ui/molecules/LoadingRow';
import { TransactionRow, TransactionsCard } from '../../ui/v2/TransactionsCard';
import { TransferRowSeparator } from '../../ui/v2/UpcomingCard';
import { formatDistanceToNow } from '../../utils/dateHelpers';

type Props = {
  loading: boolean;
  transactions: Array<Cb_Transaction>;
  onPressCompletedTransaction?: (id: string) => void;
  sx?: SxProp;
};

export const TransactionList: React.FC<Props> = ({
  loading,
  transactions = [],
  onPressCompletedTransaction,
  sx: sxProp = {},
}) => {
  const onPressTransaction = useCallback(
    (transaction: Cb_Transaction) => {
      onPressCompletedTransaction?.(transaction.id);
    },
    [onPressCompletedTransaction],
  );

  if (loading) {
    return (
      <TransactionsCard sx={sxProp}>
        <LoadingRow />
      </TransactionsCard>
    );
  }

  if (transactions.length === 0) {
    return (
      <TransactionsCard
        testID={TestID.HomeLoan.RecentTransactions.NoItems}
        sx={sxProp}
      >
        <TransactionRow label={t('Content.HomeLoan.NoRecentTransactions')} />
      </TransactionsCard>
    );
  }

  return (
    <TransactionsCard
      testID={TestID.HomeLoan.RecentTransactions.List}
      sx={sxProp}
    >
      {transactions.map((transaction, index) => (
        <React.Fragment key={transaction.id}>
          {index > 0 ? <TransferRowSeparator /> : null}
          <TransactionRow
            label={getTransactionTypeLabel(
              transaction.type,
              transaction.channel_type,
            )}
            caption={formatDistanceToNow(new Date(transaction.date))}
            amountLabel={getTransactionAmountLabel(transaction)}
            onPress={() => onPressTransaction(transaction)}
          />
        </React.Fragment>
      ))}
    </TransactionsCard>
  );
};
