import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary';
import { ErrorFallback } from '../../components/ErrorFallback';
import {
  Approval_Type_Enum,
  ApprovalRequestNotificationFragment,
  AutopayApprovalRequestFragmentDoc,
  useGetApprovalNotificationSubscription,
  WithdrawalApprovalRequestFragmentDoc,
} from '../../generated/graphql';
import { AppStackScreenProps } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { ApprovalAutopay } from '../components/ApprovalAutopay';
import { ApprovalWithdrawal } from '../components/ApprovalWithdrawal';

type Props = AppStackScreenProps<Screen.APPROVAL_RESPONSE_MODAL>;

export const GetApprovalNotificationSubcription = gql`
  subscription GetApprovalNotification($approvalResponseId: uuid!) {
    approval_response_by_pk(id: $approvalResponseId) {
      id
      approval_request {
        id
        ...ApprovalRequestNotification
      }
    }
  }
`;

export const ApprovalRequestNotificationFrag = gql`
  fragment ApprovalRequestNotification on approval_request {
    id
    approval_type
    status
    requested_by {
      id
      first_name
    }
    my_approval {
      id
      response {
        id
        status
      }
    }
    responses {
      id
      status
      identity_profile {
        id
        full_name
      }
    }
    ...WithdrawalApprovalRequest
    ...AutopayApprovalRequest
  }

  ${WithdrawalApprovalRequestFragmentDoc}
  ${AutopayApprovalRequestFragmentDoc}
`;

const ApproverScreenContent = ({
  loading,
  onDismiss,
  approvalRequest,
}: {
  loading: boolean;
  onDismiss: () => void;
  approvalRequest: ApprovalRequestNotificationFragment;
}) => {
  const type = approvalRequest.approval_type;

  const approvalRequestId = approvalRequest.id;

  if (!type || !approvalRequestId) {
    return null;
  }

  switch (type) {
    case Approval_Type_Enum.AutopayChange: {
      if (!approvalRequest.autopay_change_request)
        throw new Error('Invalid approval request');
      return (
        <ApprovalAutopay
          loading={loading}
          onDismiss={onDismiss}
          approvalRequest={{
            ...approvalRequest,
            autopay_change_request: approvalRequest.autopay_change_request,
          }}
        />
      );
    }
    case Approval_Type_Enum.Withdrawal:
      if (!approvalRequest.withdrawal_request)
        throw new Error('Invalid approval request');
      return (
        <ApprovalWithdrawal
          loading={loading}
          approvalRequest={{
            ...approvalRequest,
            withdrawal_request: approvalRequest.withdrawal_request,
          }}
        />
      );
    default:
      return null;
  }
};

export function ApproverScreen({ navigation, route }: Props) {
  const approvalResponseId = route.params?.approvalResponseId ?? '';

  const { data, loading, error } = useGetApprovalNotificationSubscription({
    variables: {
      approvalResponseId,
    },
    context: {
      sentryContext: {
        approvalResponseId,
      },
    },
  });

  const approvalRequest = data?.approval_response_by_pk?.approval_request;

  const onDismiss = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  if (error) {
    return (
      <ErrorFallback
        title=""
        caption={t('Content.Common.Error.SomethingWentWrong.Caption')}
        captionLink={t('Content.Common.Error.SomethingWentWrong.CaptionLink')}
        hideUnloanLogo
        bg="transparent"
        flex={1}
        justifyContent="center"
      />
    );
  }

  return (
    <ModalScreenContainer
      headerText={
        approvalRequest?.approval_type === Approval_Type_Enum.AutopayChange
          ? t('Content.AutopaySettings.AutoPayHeader')
          : undefined
      }
      onClose={onDismiss}
      scrollable
      hideBackButton
    >
      <ErrorBoundary>
        <Box mb="m">
          {approvalRequest ? (
            <ApproverScreenContent
              onDismiss={onDismiss}
              loading={loading}
              approvalRequest={approvalRequest}
            />
          ) : undefined}
        </Box>
      </ErrorBoundary>
    </ModalScreenContainer>
  );
}
