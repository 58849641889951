import { Loan_Application_Type_Enum } from '../../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../../LoanApplication/navigation/loanApplicationSection';
import { SingleModalStackScreenProps } from '../../../navigation/types/navTypes';
import { Screen } from '../../../navigation/types/screens';
import { useAppSummaryScreenNavigation } from '../../../utils/hooks/useAppSummaryScreenNavigation';
import { SelectRefiReasonV2Base } from '../base/SelectRefiReasonV2Base';

export type Props =
  SingleModalStackScreenProps<Screen.SELECT_REFI_REASON_V2_MODAL>;

export function SelectRefiReasonModalV2({ navigation, route }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;

  const { loanApplicationType, tryNavigateBackToSummary } =
    useAppSummaryScreenNavigation({
      navigation,
      route,
      loanApplicationId,
    });

  const navigateToSetupLoanModal = () => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      navigation.navigate(Screen.SETUP_LOAN_FOR_TOP_UP_MODAL, {
        loanApplicationId,
        loanApplicationTargetId: route.params?.loanApplicationTargetId,
      });
    } else {
      navigation.navigate(Screen.SETUP_LOAN_V2_MODAL, {
        loanApplicationId,
        loanApplicationTargetId: route.params?.loanApplicationTargetId,
      });
    }
  };

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);
  const navigateToYourUnloan = () =>
    tryNavigateBackToSummary(() =>
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.YourUnloan,
      }),
    );

  return (
    <SelectRefiReasonV2Base
      screen={Screen.SELECT_REFI_REASON_V2_MODAL}
      hideBackButton={false}
      onSubmitSuccess={navigateToSetupLoanModal}
      loanApplicationId={loanApplicationId}
      onClose={navigateToYourUnloan}
    />
  );
}
