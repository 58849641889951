import { useTheme } from '@shopify/restyle';
import * as React from 'react';
import { Switch as SwitchBase, SwitchProps } from 'react-native';

import { Theme } from '../theme';

export type Props = SwitchProps;

export const Switch: React.FC<Props> = ({ ...props }) => {
  const theme = useTheme<Theme>();
  return (
    <SwitchBase
      ios_backgroundColor={theme.colors.bg}
      trackColor={{ false: theme.colors.bg, true: theme.colors.success }}
      {...props}
    />
  );
};
