import { Text } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../../Analytics/utils/gtmKeyUtils';
import { FormCheckboxInputV2 } from '../../../components/form/FormikInputs';
import { buildLiabilityTestId } from '../../../components/LiabilityPanel';
import {
  formatLiabilityLabel,
  formatLiabilitySublabels,
  formatOwners,
} from '../../../components/utils/formatLiabilityRow';
import { FullNameByApplicantIds } from '../../../DebtsWizard/utils/types';
import {
  Liability_Type_Enum,
  MergedLiabilityForSetupLoanScreenFragment,
} from '../../../generated/graphql';
import { Screen } from '../../../navigation/types/screens';
import { FormikFormError } from '../../../ui/v2/FormError';
import { isLast } from '../../../utils/arrayHelpers';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { parseEnumType } from '../../../utils/ensureEnumType';
import { SetupLoanFormWithLoanAmountField } from '../../utils/setupLoanFormHelper';
import { SectionHeading } from './SetupLoanFormWithLoanAmount';

type SetupLoanTopUpFormConsolidateYourDebtsProps = {
  screen: Screen;
  fullNameByApplicantIds: FullNameByApplicantIds;
  liabilitiesAllowedToBeConsolidated: MergedLiabilityForSetupLoanScreenFragment[];
  resetDesiredLoanAmount: (selectedLiabilityId: string) => () => void;
  sumOfSelectedConsolidatedLiabilities: number;
};

export function SetupLoanTopUpFormConsolidateYourDebts({
  screen,
  fullNameByApplicantIds,
  liabilitiesAllowedToBeConsolidated,
  resetDesiredLoanAmount,
  sumOfSelectedConsolidatedLiabilities,
}: SetupLoanTopUpFormConsolidateYourDebtsProps) {
  return (
    <>
      <SectionHeading
        sectionTitle={t(
          'Content.SetupLoanTopUp.ConsolidateYourDebtsSection.Title',
        )}
        sectionValue={formatCurrency(sumOfSelectedConsolidatedLiabilities, {
          withFractionOnRoundedAmount: true,
        })}
        containerSx={{ mt: '$24' }}
      />
      <Text sx={{ mb: '$8' }} variant="sBody">
        {t('Content.SetupLoanTopUp.ConsolidateYourDebtsSection.Subtitle')}
      </Text>
      {liabilitiesAllowedToBeConsolidated.map((item, index) => {
        if (item.current_liability_id == null) {
          return null;
        }

        const parsedLiabilityType =
          parseEnumType(Liability_Type_Enum, item.dynamite_liability_type) ||
          Liability_Type_Enum.Other;
        return (
          <FormCheckboxInputV2
            key={item.current_liability_id}
            name={SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced}
            value={item.current_liability_id ? item.current_liability_id : ''}
            label={formatLiabilityLabel({
              institutionName: item.dynamite_institution_name,
              liabilityType: item.dynamite_liability_type,
            })}
            subtitle={[
              ...formatLiabilitySublabels({
                balance: item.dynamite_balance,
                limit: item.dynamite_limit,
                liabilityType: parsedLiabilityType,
                address: item.dynamite_address?.short_address_format,
              }),
              formatOwners({
                fullNameByApplicantIds,
                applicantIds: item.dynamite_applicant_ids,
              }),
            ].join('\n')}
            disabled={false}
            containerStyle={{
              mt: '$8',
              mb: isLast(liabilitiesAllowedToBeConsolidated, index)
                ? '$8'
                : undefined,
            }}
            testID={buildLiabilityTestId(
              TestID.SetupLoanForm.LoansToRefinance,
              parsedLiabilityType,
              item.dynamite_account_number,
            )}
            onItemPressCallback={
              item.current_liability_id
                ? resetDesiredLoanAmount(item.current_liability_id)
                : undefined
            }
            interactionKey={buildApplicationInteractionEventKey(
              SectionInteractionKey.YourUnloan,
              screen,
              FieldInteractionKey.SelectedLoansToBeRefinanced,
            )}
          />
        );
      })}
      <FormikFormError
        name={SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced}
        sx={{ mt: '$8' }}
      />
    </>
  );
}
