import { atomFamily } from 'recoil';

export type ReduceRepaymentsValues = {
  newMinimumRepaymentAmount: number;
  previousRepaymentAmount: number;
  minimumRepaymentReducedAmount: number;
  redrawBalanceAvailable: number;
  previousRedrawAmount: number;
  redrawReduced: number;
};

export const ReduceRepaymentsAtomFamily = atomFamily<
  ReduceRepaymentsValues,
  string
>({
  key: 'ReduceRepaymentsAtomFamily',
  default: {
    newMinimumRepaymentAmount: 0,
    previousRepaymentAmount: 0,
    minimumRepaymentReducedAmount: 0,
    redrawBalanceAvailable: 0,
    previousRedrawAmount: 0,
    redrawReduced: 0,
  },
});

export const ReduceRepaymentsConfirmedAtomFamily = atomFamily<boolean, string>({
  key: 'ReduceRepaymentsConfirmedAtomFamily',
  default: false,
});
