import { ApolloError } from '@apollo/client';
import { useContext } from 'react';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { HomeLoanWithdrawalDetail } from '../../HomeLoan/recoil/homeLoanWithdrawalDetailAtom';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { useConfirmPaymentAllowCents } from './useConfirmPaymentAllowCents';
import { useConfirmPaymentWithoutCents } from './useConfirmPaymentWithoutCents';

type UseConfirmPaymentProps = {
  screen: Screen | ActionSheetType;
  cbaAccountId: string;
  onError: (message: string) => void;
  onExceedsDailyLimit: (exceededByAmount?: number | null) => void;
  onSuccess: (paymentRequestId: string) => void;
};

type UseConfirmPayment = {
  loading: boolean;
  error: ApolloError | undefined;
  confirmPayment: (vars: HomeLoanWithdrawalDetail) => Promise<string | null>;
};

export const useConfirmPayment = (
  props: UseConfirmPaymentProps,
): UseConfirmPayment => {
  const { flags } = useContext(FeatureFlagsContext);
  const isTransferCentsEnabled = flags.ENABLE_TRANSFER_CENTS || false;

  const confirmPaymentAllowCents = useConfirmPaymentAllowCents(props);
  const confirmPaymentDisallowCents = useConfirmPaymentWithoutCents(props);

  return isTransferCentsEnabled
    ? confirmPaymentAllowCents
    : confirmPaymentDisallowCents;
};
