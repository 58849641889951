import { View } from 'dripsy';
import { useCallback } from 'react';
import * as React from 'react';

import { toAnnualisedAmount } from '../../DebtsWizard/utils/mergedLiabilityUtils';
import {
  EmploymentIncomeFragment,
  ExpectedRentalIncomeFragment,
  Frequency_Enum,
  Income_Type_Enum,
  IncomeOwnerFragment,
  Rental_Income_Type_Enum,
  RentalIncomeFragment,
} from '../../generated/graphql';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { NewListRow } from '../../ui/molecules/NewListRow';
import { SvgIconName } from '../../ui/svgs/svgIconList';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import {
  formatCurrency,
  formatCurrencyWithPeriod,
} from '../../utils/currencyHelpers';
import { formatAddress } from '../../utils/stringHelpers';

type IncomeExpenseRowHelperParams = {
  type: Income_Type_Enum | 'EXPENSE';
  amount: number;
  frequency: Frequency_Enum;

  /** Only used when type is `Income_Type_Enum` */
  incomeOwners?: Array<IncomeOwnerFragment>;

  /** Only used when `type === Income_Type_Enum.Employment` */
  employmentIncome?: EmploymentIncomeFragment | null;

  /** Only used when `type === Income_Type_Enum.Rental` */
  rentalIncome?: RentalIncomeFragment | ExpectedRentalIncomeFragment | null;

  /** Only used when `type === "EXPENSE"` */
  householdCount?: {
    children: number;
    adults: number;
  };
};

function isExpectedRentalIncomeFragment(
  rentalIncome: RentalIncomeFragment | ExpectedRentalIncomeFragment,
): rentalIncome is ExpectedRentalIncomeFragment {
  return 'expected_suburb' in rentalIncome;
}

export function getIncomeExpenseRowLabel({
  type,
  rentalIncome,
  employmentIncome,
}: Pick<
  IncomeExpenseRowHelperParams,
  'type' | 'rentalIncome' | 'employmentIncome'
>) {
  switch (type) {
    case 'EXPENSE':
      return t('Content.YourFinancials.HouseholdExpenses');
    case Income_Type_Enum.Employment:
      return (
        employmentIncome?.employer ||
        t('Content.YourFinancials.EmploymentIncome')
      );
    case Income_Type_Enum.Rental: {
      if (
        rentalIncome?.rental_income_type === Rental_Income_Type_Enum.Expected
      ) {
        return t('Content.YourFinancials.YourNewInvestmentProperty');
      }
      if (!rentalIncome?.address) {
        return t('Content.YourFinancials.RentalIncome');
      }
      const [streetAddress] = formatAddress(rentalIncome.address);
      return streetAddress;
    }
    case Income_Type_Enum.SavingsInterest:
      return t('Content.YourFinancials.SavingsInterest');
    case Income_Type_Enum.ShareDividends:
      return t('Content.YourFinancials.DividendIncome');
    case Income_Type_Enum.GovernmentPayments:
      return t('Content.YourFinancials.GovernmentPayments');
    default:
      return t('Content.YourFinancials.OtherIncome');
  }
}

export function getIncomeCaption({
  amount,
  frequency,
  type,
  employmentIncome,
  rentalIncome,
}: Pick<
  IncomeExpenseRowHelperParams,
  'amount' | 'frequency' | 'type' | 'employmentIncome' | 'rentalIncome'
>) {
  // Per requirement in https://unloan.atlassian.net/browse/UDEL-1300
  // We only recorded annual income for income types other than salary,
  // Hence we need to convert the salary to annualised amount so we
  // can calculate the total income correctly
  if (type === Income_Type_Enum.Employment && employmentIncome) {
    // Calculate annualised salary amount
    const annualSalary = toAnnualisedAmount(amount, frequency);
    const {
      annual_allowances: annualAllowances,
      annual_bonus: annualBonus,
      annual_commissions: annualComissions,
      annual_overtime: annualOvertime,
    } = employmentIncome;
    const totalIncomeAmount =
      annualSalary +
      (annualAllowances ?? 0) +
      (annualBonus ?? 0) +
      (annualComissions ?? 0) +
      (annualOvertime ?? 0);
    return formatCurrencyWithPeriod(
      totalIncomeAmount,
      Frequency_Enum.Annual,
      true,
    );
  }

  if (
    type === Income_Type_Enum.Rental &&
    rentalIncome?.rental_income_type === Rental_Income_Type_Enum.Expected &&
    isExpectedRentalIncomeFragment(rentalIncome)
  ) {
    return rentalIncome.expected_suburb ?? '';
  }

  const formattedIncomeAmount = formatCurrencyWithPeriod(
    amount,
    frequency,
    true,
  );

  return formattedIncomeAmount;
}

type IncomeFooter = { label: string; value: number };
export function transformEmploymentIncomeToIncomeFooterDetails(
  employmentIncome: IncomeExpenseRowHelperParams['employmentIncome'],
  salary: number,
) {
  const incomeDetails: Array<IncomeFooter> = [
    {
      label: t('Content.YourFinancials.EmploymentIncomeSalaryLabel'),
      value: salary,
    },
  ];

  if (employmentIncome?.annual_overtime != null) {
    incomeDetails.push({
      label: t('Content.YourFinancials.EmploymentIncomeOvertimeLabel'),
      value: employmentIncome.annual_overtime,
    });
  }

  if (employmentIncome?.annual_bonus != null) {
    incomeDetails.push({
      label: t('Content.YourFinancials.EmploymentIncomeBonusLabel'),
      value: employmentIncome.annual_bonus,
    });
  }

  if (employmentIncome?.annual_commissions != null) {
    incomeDetails.push({
      label: t('Content.YourFinancials.EmploymentIncomeCommissionLabel'),
      value: employmentIncome.annual_commissions,
    });
  }

  if (employmentIncome?.annual_allowances != null) {
    incomeDetails.push({
      label: t('Content.YourFinancials.EmploymentIncomeAllowancesLabel'),
      value: employmentIncome.annual_allowances,
    });
  }

  return incomeDetails;
}
export function transformExpectedRentalIncomeToIncomeFooterDetails({
  rentalIncome,
  incomeAmount,
  incomeFrequency,
}: {
  rentalIncome: ExpectedRentalIncomeFragment;
  incomeAmount: number;
  incomeFrequency: Frequency_Enum;
}) {
  const incomeDetails = [
    {
      label: t(
        'Content.YourFinancials.ExpectedRentalIncomeEstimatedLoanAmountLabel',
      ),
      value: formatCurrency(rentalIncome.estimated_loan_amount),
    },
    {
      label: t('Content.YourFinancials.ExpectedRentalIncomeIncomeLabel'),
      value: formatCurrencyWithPeriod(incomeAmount, incomeFrequency, true),
    },
    {
      label: t('Content.YourFinancials.ExpectedRentalIncomeExpenseLabel'),
      value: formatCurrencyWithPeriod(
        rentalIncome.rental_expense,
        rentalIncome.rental_expense_frequency,
        true,
      ),
    },
  ];

  return incomeDetails;
}

export function EmploymentIncomeDetailsFooter({
  amount,
  frequency,
  employmentIncome,
}: Pick<
  IncomeExpenseRowHelperParams,
  'type' | 'amount' | 'frequency' | 'employmentIncome'
>) {
  const employmentIncomeDetails =
    transformEmploymentIncomeToIncomeFooterDetails(
      employmentIncome,
      toAnnualisedAmount(amount, frequency),
    );

  if (employmentIncomeDetails.length === 0) {
    return null;
  }

  return (
    <View>
      <Separator my="s" />
      {employmentIncomeDetails.map(({ label, value }, index) => (
        <View
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: index === 0 ? undefined : '$2',
          }}
          key={label}
          testID={`income-details-footer-${index}`}
        >
          {/* TODO: change to new component when font sizes are adjusted */}
          <StyledText variant="caption">{label}</StyledText>
          <StyledText variant="caption">{formatCurrency(value)}</StyledText>
        </View>
      ))}
    </View>
  );
}

export function ExpectedRentalIncomeDetailsFooter({
  amount,
  frequency,
  rentalIncome,
}: Pick<IncomeExpenseRowHelperParams, 'type' | 'amount' | 'frequency'> & {
  rentalIncome: ExpectedRentalIncomeFragment;
}) {
  const incomeDetails = transformExpectedRentalIncomeToIncomeFooterDetails({
    rentalIncome,
    incomeAmount: amount,
    incomeFrequency: frequency,
  });

  return (
    <View>
      <Separator my="s" />
      {incomeDetails.map(({ label, value }, index) => (
        <View
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: index === 0 ? undefined : '$2',
          }}
          key={label}
          testID={`income-details-footer-${index}`}
        >
          {/* TODO: change to new component when font sizes are adjusted */}
          <StyledText variant="caption">{label}</StyledText>
          <StyledText variant="caption">{value}</StyledText>
        </View>
      ))}
    </View>
  );
}

export function getIncomeFinancialRowCaption(
  incomeOwners: IncomeExpenseRowHelperParams['incomeOwners'],
) {
  return incomeOwners
    ?.map(({ applicant }) => applicant?.latest_full_name)
    .filter(isNotNullOrUndefined)
    .join(', ');
}

const INCOME_EXPENSE_ICON_BY_TYPE: Record<
  Income_Type_Enum | 'EXPENSE',
  SvgIconName
> = {
  EXPENSE: 'liabilityPersonalLoan',
  [Income_Type_Enum.Employment]: 'financialEmploymentIncome',
  [Income_Type_Enum.Rental]: 'financialRentalIncome',
  [Income_Type_Enum.SavingsInterest]: 'financialSavingIncome',
  [Income_Type_Enum.ShareDividends]: 'financialSavingIncome',
  [Income_Type_Enum.GovernmentPayments]: 'financialSavingIncome',
};

export type IncomeRowProps = Pick<
  React.ComponentProps<typeof NewListRow>,
  'testID' | 'mt' | 'mb'
> & {
  type: Income_Type_Enum;
  amount: number;
  frequency: Frequency_Enum;

  onPress?: (params: {
    id: string;
    type: Income_Type_Enum;
    rentalIncomeType: Rental_Income_Type_Enum | null;
  }) => void;

  id: string;

  /** Only used when type is `Income_Type_Enum` */
  formattedIncomeOwners?: string;

  /** Only used when `type === Income_Type_Enum.Employment` */
  employmentIncome?: EmploymentIncomeFragment | null;

  /** Only used when `type === Income_Type_Enum.Rental` */
  rentalIncome?: RentalIncomeFragment | null;
};

/**
 * This component is copy pasted from v1.
 * Originally was kept alongside income row in `src/Financials/components/IncomeExpenseRow.tsx`
 */
export function IncomeRow({
  type,
  amount,
  frequency,

  onPress,
  id,

  formattedIncomeOwners,
  employmentIncome,
  rentalIncome,

  testID,
  mt,
  mb,
}: IncomeRowProps) {
  const onPressWrapped = useCallback(() => {
    onPress?.({
      id,
      type,
      rentalIncomeType: rentalIncome?.rental_income_type ?? null,
    });
  }, [onPress, id, type, rentalIncome?.rental_income_type]);

  let IncomeFooter;
  let subcaption = formattedIncomeOwners;

  if (type === Income_Type_Enum.Employment) {
    IncomeFooter = (
      <EmploymentIncomeDetailsFooter
        type={type}
        amount={amount}
        frequency={frequency}
        employmentIncome={employmentIncome}
      />
    );
  } else if (
    type === Income_Type_Enum.Rental &&
    rentalIncome?.rental_income_type === Rental_Income_Type_Enum.Expected &&
    isExpectedRentalIncomeFragment(rentalIncome)
  ) {
    subcaption = undefined;
    IncomeFooter = (
      <ExpectedRentalIncomeDetailsFooter
        type={type}
        amount={amount}
        frequency={frequency}
        rentalIncome={rentalIncome}
      />
    );
  }

  return (
    <NewListRow
      key={id}
      leftIconFamily="svg"
      leftIconName={INCOME_EXPENSE_ICON_BY_TYPE[type]}
      leftIconColor="primaryContent"
      title={getIncomeExpenseRowLabel({ type, rentalIncome, employmentIncome })}
      caption={getIncomeCaption({
        amount,
        frequency,
        type,
        employmentIncome,
        rentalIncome,
      })}
      footer={IncomeFooter}
      subcaption={subcaption}
      onRowPress={onPress ? onPressWrapped : undefined}
      showArrow={!!onPress}
      testID={testID}
      shadowed={false}
      mt={mt}
      mb={mb}
    />
  );
}

export type IncomeRowWithErrorProps = Pick<
  IncomeRowProps,
  | 'id'
  | 'type'
  | 'testID'
  | 'employmentIncome'
  | 'formattedIncomeOwners'
  | 'rentalIncome'
  | 'mb'
  | 'mt'
> & {
  message: string;
  onPress?: (params: { id: string; type: Income_Type_Enum }) => void;
};

export function IncomeRowWithError({
  type,
  message,
  onPress,
  id,

  formattedIncomeOwners,
  employmentIncome,
  rentalIncome,

  testID,
  mt,
  mb,
}: IncomeRowWithErrorProps) {
  const onPressWrapped = useCallback(() => {
    onPress?.({ id, type });
  }, [onPress, id, type]);

  return (
    <NewListRow
      key={id}
      leftIconFamily="svg"
      leftIconName={INCOME_EXPENSE_ICON_BY_TYPE[type]}
      leftIconColor="primaryContent"
      title={getIncomeExpenseRowLabel({ type, rentalIncome, employmentIncome })}
      caption={formattedIncomeOwners}
      captionColor="secondaryContent"
      subcaption={message}
      subcaptionColor="error"
      onRowPress={onPress ? onPressWrapped : undefined}
      showArrow={!!onPress}
      rightIconColor="error"
      rightIconName="alert-circle"
      testID={testID}
      shadowed={false}
      mt={mt}
      mb={mb}
    />
  );
}
