import { IdvDisplayError } from '../Identification/utils/identityCheckUtils';

export type IdvError = {
  getTokenError: Error | undefined;
  processIDVError: Error | undefined;
  idvError: IdvDisplayError | null;
};

export function getIdvErrorMessage({
  getTokenError,
  processIDVError,
  idvError,
}: IdvError) {
  if (getTokenError) {
    return t('Content.FinaliseYourApplication.Error.FailedToGetIDVToken');
  }
  if (processIDVError) {
    return t('Content.FinaliseYourApplication.Error.FailedToProcessIDV');
  }
  if (idvError != null) {
    switch (idvError) {
      case IdvDisplayError.NoCameraAccess:
        return t('Content.FinaliseYourApplication.Error.NoCameraAccess');
      case IdvDisplayError.DiskWriteError:
        return t('Content.FinaliseYourApplication.Error.DiskWriteError');
      case IdvDisplayError.UnknownError:
      default:
        return t('Content.FinaliseYourApplication.Error.UnknownError');
    }
  }

  return null;
}
