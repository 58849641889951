import { SxProp, View } from 'dripsy';
import * as React from 'react';

type CardListProps = {
  children: React.ReactNode;
  sx?: SxProp;
  dataSet?: Record<string, unknown>;
};

export const Card = ({ children, sx = {}, dataSet }: CardListProps) => (
  <View
    sx={{
      backgroundColor: '$inputBackground',
      borderRadius: '$card',
      overflow: 'hidden',
      cursor: 'unset',
      mb: '$16',
      ...sx,
    }}
    dataSet={dataSet}
  >
    {children}
  </View>
);
