import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { useSvgIdPrefix } from '../utils/useSvgIdPrefix';
import { SvgIconProps } from './types';

export function YourLiabilityPersonalLoanIcon({ ...otherProps }: SvgIconProps) {
  const idPrefix = useSvgIdPrefix('YourLiabilityPersonalLoanIcon');

  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...otherProps}>
      <Path
        d="M33 22.5H15C15 23.0265 15 24.9735 15 25.5C15 27.9855 19.0297 30 24 30C28.9703 30 33 27.9855 33 25.5C33 24.9735 33 23.0265 33 22.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 19.5H15C15 20.0265 15 21.9735 15 22.5C15 24.9855 19.0297 27 24 27C28.9703 27 33 24.9855 33 22.5C33 21.9735 33 20.0265 33 19.5Z"
        fill={`url(#${idPrefix}_paint0_linear_8859_3144)`}
      />
      <Path
        d="M33 16.5H15C15 17.0265 15 18.9735 15 19.5C15 21.9855 19.0297 24 24 24C28.9703 24 33 21.9855 33 19.5C33 18.9735 33 17.0265 33 16.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 13.5H15C15 14.0265 15 15.9735 15 16.5C15 18.9855 19.0297 21 24 21C28.9703 21 33 18.9855 33 16.5C33 15.9735 33 14.0265 33 13.5Z"
        fill={`url(#${idPrefix}_paint1_linear_8859_3144)`}
      />
      <Path
        d="M33 10.5H15C15 11.0265 15 12.9735 15 13.5C15 15.9855 19.0297 18 24 18C28.9703 18 33 15.9855 33 13.5C33 12.9735 33 11.0265 33 10.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M33 7.5H15C15 8.0265 15 9.9735 15 10.5C15 12.9855 19.0297 15 24 15C28.9703 15 33 12.9855 33 10.5C33 9.9735 33 8.0265 33 7.5Z"
        fill={`url(#${idPrefix}_paint2_linear_8859_3144)`}
      />
      <Path
        d="M24 12C28.9706 12 33 9.98528 33 7.5C33 5.01472 28.9706 3 24 3C19.0294 3 15 5.01472 15 7.5C15 9.98528 19.0294 12 24 12Z"
        fill="#C6C6C6"
      />
      <Path
        opacity="0.08"
        d="M15 13.7344C15 18.8104 15 25.1809 15 25.5004C15 27.6814 18.1028 29.4986 22.221 29.9119C22.3973 29.4611 22.5 28.9909 22.5 28.5004V19.5004C22.5 16.6811 19.4303 14.4371 15 13.7344Z"
        fill="#2C2C2C"
      />
      <Path
        opacity="0.08"
        d="M15 14.4961C15 19.4251 15 25.1963 15 25.5001C15 27.5408 17.7188 29.2613 21.4425 29.8133C21.636 29.3941 21.75 28.9561 21.75 28.5001V19.5001C21.75 17.1226 18.9547 15.1628 15 14.4961Z"
        fill="#2C2C2C"
      />
      <Path
        d="M21 25.5H3C3 26.0265 3 27.9735 3 28.5C3 30.9855 7.02975 33 12 33C16.9703 33 21 30.9855 21 28.5C21 27.9735 21 26.0265 21 25.5Z"
        fill={`url(#${idPrefix}_paint3_linear_8859_3144)`}
      />
      <Path
        d="M21 22.5H3C3 23.0265 3 24.9735 3 25.5C3 27.9855 7.02975 30 12 30C16.9703 30 21 27.9855 21 25.5C21 24.9735 21 23.0265 21 22.5Z"
        fill="#2C2C2C"
      />
      <Path
        d="M21 19.5H3C3 20.0265 3 21.9735 3 22.5C3 24.9855 7.02975 27 12 27C16.9703 27 21 24.9855 21 22.5C21 21.9735 21 20.0265 21 19.5Z"
        fill={`url(#${idPrefix}_paint4_linear_8859_3144)`}
      />
      <Path
        d="M12 24C16.9706 24 21 21.9853 21 19.5C21 17.0147 16.9706 15 12 15C7.02944 15 3 17.0147 3 19.5C3 21.9853 7.02944 24 12 24Z"
        fill={`url(#${idPrefix}_paint5_linear_8859_3144)`}
      />
      <Path
        d="M12 24C16.9706 24 21 21.9853 21 19.5C21 17.0147 16.9706 15 12 15C7.02944 15 3 17.0147 3 19.5C3 21.9853 7.02944 24 12 24Z"
        fill="#C6C6C6"
      />
      <Defs>
        <LinearGradient
          id={`${idPrefix}_paint0_linear_8859_3144`}
          x1="15"
          y1="19.5"
          x2="20.3254"
          y2="32.2811"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint1_linear_8859_3144`}
          x1="15"
          y1="13.5"
          x2="20.3254"
          y2="26.2811"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint2_linear_8859_3144`}
          x1="15"
          y1="7.5"
          x2="20.3254"
          y2="20.2811"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint3_linear_8859_3144`}
          x1="3"
          y1="25.5"
          x2="8.32544"
          y2="38.2811"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint4_linear_8859_3144`}
          x1="3"
          y1="19.5"
          x2="8.32544"
          y2="32.2811"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id={`${idPrefix}_paint5_linear_8859_3144`}
          x1="7.09425"
          y1="14.5942"
          x2="17.013"
          y2="24.513"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FEDE00" />
          <Stop offset="1" stopColor="#FFD000" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
