import { LayoutChangeEvent } from 'react-native';

import { TestID } from '../../../testID/constants';
import { MAX_BORROWERS } from '../../LoanApplication/components/BorrowerList';
import { Screen } from '../../navigation/types/screens';
import { SectionDataProps } from '../remoteData';
import {
  SummarySectionItemOnPressTypes,
  SummarySectionV2,
} from './SummarySectionV2';

export type SummarySectionHeaderOnPressTypes = {
  borrowers: () => void;
  income: () => void;
  expense: () => void;
  liabilities: () => void;
};

type Props = {
  screen: Screen;
  sectionData: SectionDataProps;
  mode:
    | 'EDIT'
    | 'VIEW'
    // In partial edit, only specific sections are editable.
    // For VCA, the rule of thumb is,
    // everything after CA screen should be editable,
    // and everything before CA screen should be read-only.
    | 'PARTIAL_EDIT_FOR_VCA';
  onSectionHeaderPress?: SummarySectionHeaderOnPressTypes;
  onSectionItemPress?: SummarySectionItemOnPressTypes;
  onDisclosureCheckboxPress?: () => void;
  disclosureCheckboxLoading?: boolean;
  onCheckboxLayout?: (event: LayoutChangeEvent) => void;
  showRequiredDisclosureError?: boolean;
  showLinkToLmiFactSheet?: boolean;
  showDependentsAndFutureLivingSituation?: boolean;
};

export function ApplicationSummaryDetailsV2({
  screen,
  sectionData,
  mode,
  onSectionItemPress,
  onSectionHeaderPress,
  onDisclosureCheckboxPress,
  disclosureCheckboxLoading,
  onCheckboxLayout,
  showRequiredDisclosureError,
  showLinkToLmiFactSheet,
  showDependentsAndFutureLivingSituation = false,
}: Props) {
  const isEditMode = mode === 'EDIT';

  return (
    <>
      <SummarySectionV2 title="">
        <SummarySectionV2.YourUnloan
          screen={screen}
          loanData={sectionData.loan}
          financialDeclarationData={sectionData.financialDeclaration}
          isOnEditMode={isEditMode || mode === 'PARTIAL_EDIT_FOR_VCA'}
          onPress={onSectionItemPress?.loan}
          minimumRepaymentTestId={TestID.SummarySection.MinRepayment}
          totalLoanAmountTestId={TestID.SummarySection.TotalLoanAmount}
          loanTermTestId={TestID.SummarySection.LoanTerm}
          cardTestId={TestID.SummarySection.YourUnloanCard}
          onDisclosureCheckboxPress={onDisclosureCheckboxPress}
          disclosureCheckboxLoading={disclosureCheckboxLoading}
          onCheckboxLayout={onCheckboxLayout}
          showRequiredDisclosureError={showRequiredDisclosureError}
          loanApplicationType={sectionData.loanApplicationType}
          showLinkToLmiFactSheet={showLinkToLmiFactSheet}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Property')}
      >
        <SummarySectionV2.YourProperty
          loanApplicationType={sectionData.loanApplicationType}
          data={sectionData.property}
          isOnEditMode={isEditMode || mode === 'PARTIAL_EDIT_FOR_VCA'}
          onPress={onSectionItemPress?.property}
          testID={TestID.SummarySection.PropertyItem}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Borrowers')}
        isOnEditMode={isEditMode}
        onAddButtonPress={
          sectionData.borrowers.length < MAX_BORROWERS
            ? onSectionHeaderPress?.borrowers
            : undefined
        }
        addButtonTestID={TestID.SummarySection.BorrowerAdd}
      >
        <SummarySectionV2.Borrowers
          data={sectionData.borrowers}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.borrowers}
          testIDPrefix={TestID.SummarySection.BorrowerItemPrefix}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Income')}
        isOnEditMode={isEditMode}
        onAddButtonPress={onSectionHeaderPress?.income}
        addButtonTestID={TestID.SummarySection.IncomeAdd}
      >
        <SummarySectionV2.Income
          data={sectionData.financials.incomes}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.income}
          testIDPrefix={TestID.SummarySection.IncomeItemPrefix}
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.Expenses')}
        isOnEditMode={isEditMode}
        onAddButtonPress={
          sectionData.isAllApplicantsHasHousehold
            ? undefined
            : onSectionHeaderPress?.expense
        }
        addButtonTestID={TestID.SummarySection.ExpenseAdd}
      >
        <SummarySectionV2.Expense
          data={sectionData.financials.households}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.expense}
          testIDPrefix={TestID.SummarySection.ExpenseItemPrefix}
          showDependentsAndFutureLivingSituation={
            showDependentsAndFutureLivingSituation
          }
        />
      </SummarySectionV2>
      <SummarySectionV2
        title={t('Content.ApplicationSummary.SectionTitle.DebtsAndLiabilities')}
        isOnEditMode={isEditMode}
        onAddButtonPress={onSectionHeaderPress?.liabilities}
        addButtonTestID={TestID.SummarySection.LiabilityAdd}
      >
        <SummarySectionV2.DebtsAndLiabilities
          data={sectionData.debtsAndLiabilities}
          isOnEditMode={isEditMode}
          onPress={onSectionItemPress?.liabilities}
          testIDPrefix={TestID.SummarySection.LiabilityItemPrefix}
        />
      </SummarySectionV2>
    </>
  );
}
