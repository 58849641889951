import { atomFamily } from 'recoil';

import {
  BorrowerFormContextValues,
  BorrowerFormV2ContextValues,
} from '../components/form/types';

type LoanApplicationId = string;
export const currentLoggedInBorrowerDetailsAtom = atomFamily<
  BorrowerFormContextValues | null,
  LoanApplicationId
>({
  key: 'CurrentLoggedInBorrowerDetails',
  default: null,
});

/**
 * This atom is used to pass data from borrower wizard screen
 * to manual kyc screen.
 *
 * The difference with v1 is the data structure
 */
export const currentLoggedInBorrowerDetailsV2Atom = atomFamily<
  BorrowerFormV2ContextValues | null,
  LoanApplicationId
>({
  key: 'CurrentLoggedInBorrowerDetailsV2',
  default: null,
});
