import {
  Approval_Status_Enum,
  Change_Request_Status_Enum,
  useGetPendingLoanVariationRequestByAccountIdSubscription,
} from '../../generated/graphql';

export const useGetPendingLoanVariationRequest = (cbaAccountId: string) => {
  const { data, loading } =
    useGetPendingLoanVariationRequestByAccountIdSubscription({
      context: {
        sentryContext: {
          cbaAccountId,
          description: 'Get Loan Variation Request by CBA Account ID',
        },
      },
      variables: {
        cbaAccountId,
      },
    });

  const loanVariationRequest = data?.loan_variation_request[0];

  const amountToBeReduced = loanVariationRequest?.amount;
  const approvalRequest = loanVariationRequest?.approval_request;
  const myApprovalResponse = approvalRequest?.my_approval;

  const myApprovalResponseStatus = myApprovalResponse?.response?.status;

  const myIdentityProfileId = myApprovalResponse?.response?.identity_profile_id;
  const otherPendingApprovalResponses = approvalRequest?.responses.filter(
    (response) =>
      response.identity_profile_id !== myIdentityProfileId &&
      response.status === Approval_Status_Enum.Pending,
  );
  const borrowersPendingApproval = otherPendingApprovalResponses?.map(
    (response) => response.identity_profile?.first_name,
  );
  const otherBorrowersNames = borrowersPendingApproval?.join(' and ');

  const allApprovalResponses = approvalRequest?.responses.map((response) => ({
    id: response.id,
    fullName: response.identity_profile.full_name,
    status: response.status,
  }));

  const anyPendingApprovals =
    approvalRequest?.status === Approval_Status_Enum.Pending;

  const isRequestPending =
    loanVariationRequest?.status === Change_Request_Status_Enum.Pending;

  const isLoanVariationRequestProcessing =
    isRequestPending && !anyPendingApprovals;

  return {
    approvalRequestId: approvalRequest?.id,
    isLoanVariationRequestProcessing,
    amountToBeReduced,
    isPendingMyApproval:
      myApprovalResponseStatus === Approval_Status_Enum.Pending,
    loading,
    loanVariationRequest,
    loanVariationRequestId: loanVariationRequest?.id,
    otherBorrowersNames,
    allApprovalResponses,
  };
};
