import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  Frequency_Enum,
  Property_Purpose_Enum,
  useGetPropertyOptionsQuery,
} from '../../generated/graphql';
import {
  AddressSearchSuggestion,
  LOAN_APPLICATION_SECURITY,
} from '../../LoanApplication/graphql/fragments';
import { removeNullish } from '../../utils/arrayHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';

export const GET_STREET_TYPE_OPTIONS = gql`
  query GetStreetTypeOptions {
    street_type {
      street_type_long
      street_type_short
    }
  }
`;

export const GET_SUGGESTED_ADDRESS_FROM_DOMAIN_API = gql`
  query GetSuggestedAddressFromDomainAPI($search: String!, $resultLimit: Int) {
    suggested_domain_api_addresses(query: $search, result_limit: $resultLimit) {
      ...AddressSearchSuggestion
    }
  }
  ${AddressSearchSuggestion}
`;

export const QUERY = gql`
  query GetPropertyOptions {
    property_types: property_type {
      type
      description
    }
    property_purposes: property_purpose {
      purpose
      description
    }
    frequencies: frequency {
      frequency
      description
    }
  }
`;

export function usePropertyOptions() {
  const { loading, data } = useGetPropertyOptionsQuery();
  return {
    ...useMemo(
      () => ({
        propertyTypeOptions: removeNullish(data?.property_types || []).map(
          (propertyType) => ({
            label: propertyType.description,
            value: propertyType.type,
          }),
        ),
        propertyPurposeOptions: removeNullish(
          data?.property_purposes || [],
        ).map((propertyPurpose) => {
          const purpose = parseEnumType(
            Property_Purpose_Enum,
            propertyPurpose.purpose,
          );
          return {
            label: purpose
              ? t(`Content.PropertyPurpose.${purpose}`)
              : propertyPurpose.description,
            value: propertyPurpose.purpose,
          };
        }),
        frequencyOptions: removeNullish(data?.frequencies || []).map((f) => {
          const frequency = parseEnumType(Frequency_Enum, f.frequency);
          return {
            label: frequency
              ? t(`Content.Common.FrequencyEnum.${frequency}`)
              : f.description,
            value: f.frequency,
          };
        }),
      }),
      [data],
    ),
    loadingPropertyOptions: loading,
  };
}

export const GET_LOAN_APPLICATION_DETAILS = gql`
  query GetLoanApplicationDetails($id: uuid!) {
    loan_application_by_pk(id: $id) {
      id
      is_lmi_enabled
      initial_property_purpose
      loan_application_targets {
        id
      }
    }
  }
`;

export const EXPECTED_RENTAL_INCOME_FOR_PROPERTY_SCREEN = gql`
  fragment ExpectedRentalIncomeForPropertyScreen on income {
    id
    expected_rental_income: amount
    expected_rental_income_frequency: frequency
    rental_income {
      id
      expected_rental_expense: rental_expense
      expected_rental_expense_frequency: rental_expense_frequency
    }
  }
`;

export const GET_LOAN_APPLICATION_SECURITY_DETAILS_FROM_LOAN_APPLICATION = gql`
  query GetLoanApplicationSecurityDetailsFromLoanApplication(
    $loanApplicationId: uuid!
    $loanApplicationSecurityIdWhere: uuid_comparison_exp!
  ) {
    loan_application_by_pk(id: $loanApplicationId) {
      id
      type
      initial_property_purpose
      loan_application_securities(
        where: { id: $loanApplicationSecurityIdWhere }
      ) {
        ...LoanApplicationSecurityDetails
      }
      incomes(
        where: { rental_income: { rental_income_type: { _eq: EXPECTED } } }
      ) {
        ...ExpectedRentalIncomeForPropertyScreen
      }
      parent_loan_account {
        security {
          owner_estimated_value
        }
      }
    }
  }
  ${LOAN_APPLICATION_SECURITY}
  ${EXPECTED_RENTAL_INCOME_FOR_PROPERTY_SCREEN}
`;
