import { Text, View } from 'dripsy';

import { TestID } from '../../../../testID/constants';
import { GradientBallIcon } from '../../../ui/svgs/GradientBallIcon';
import { GrayBallIcon } from '../../../ui/svgs/GrayBallIcon';

type Props = {
  hasGradientBallIcon?: boolean;
};

export const BadgeExplanation = ({ hasGradientBallIcon = false }: Props) => (
  <View sx={{ mt: '$16' }}>
    {hasGradientBallIcon ? (
      <View sx={{ flexDirection: 'row', mb: '$12' }}>
        <View sx={{ mr: '$8', mt: '$2' }}>
          <GradientBallIcon size={12} />
        </View>
        <Text
          variant="caption"
          testID={TestID.Insights.TermRemaining.GradientBadgeExplanation}
        >
          {t('Content.HomeLoan.Insights.TermRemaining.GradientLineExplanation')}
        </Text>
      </View>
    ) : null}
    <View sx={{ flexDirection: 'row' }}>
      <View sx={{ mr: '$8', mt: '$2' }}>
        <GrayBallIcon size={12} />
      </View>
      <Text
        variant="caption"
        testID={TestID.Insights.TermRemaining.GrayBadgeExplanation}
      >
        {t('Content.HomeLoan.Insights.TermRemaining.GrayLineExplanation')}
      </Text>
    </View>
  </View>
);
