import { Text, View } from 'dripsy';

import { DataRowGroup, DataRowSeparator } from '../../ui/molecules/DataRow';
import { LoadingRow } from '../../ui/molecules/LoadingRow';
import { formatCurrency } from '../../utils/currencyHelpers';

type Props = {
  loading?: boolean;
  newMinimumRepaymentAmount: number;
  previousRepaymentAmount: number;
  minimumRepaymentReducedAmount: number;
  redrawBalanceAvailable: number;
  previousRedrawAmount: number;
  redrawReduced: number;
};

export const UpdatedRepaymentsDetails = ({
  loading = false,
  newMinimumRepaymentAmount,
  previousRepaymentAmount,
  minimumRepaymentReducedAmount,
  redrawBalanceAvailable,
  previousRedrawAmount,
  redrawReduced,
}: Props) => (
  <DataRowGroup mt={0}>
    {loading ? (
      <LoadingRow />
    ) : (
      <View sx={{ padding: '$16' }}>
        <Text variant="xsHeader">
          {t(
            'Content.LoanVariation.ReduceRepaymentConfirmation.MinimumRepaymentsHeading',
          )}
        </Text>
        <View>
          <Text variant="sHeader">
            {t(
              'Content.LoanVariation.ReduceRepaymentConfirmation.MinimumRepaymentsAmount',
              {
                minimumRepaymentAmount: formatCurrency(
                  newMinimumRepaymentAmount,
                  {
                    withFractionOnRoundedAmount: true,
                  },
                ),
              },
            )}
          </Text>
          <Text
            variant="sBodySecondary"
            style={{ textDecorationLine: 'line-through' }}
          >
            {t(
              'Content.LoanVariation.ReduceRepaymentConfirmation.PreviousRepaymentAmount',
              {
                previousRepaymentAmount: formatCurrency(
                  previousRepaymentAmount,
                ),
              },
            )}
          </Text>
          <Text variant="sBodySecondary">
            {t(
              'Content.LoanVariation.ReduceRepaymentConfirmation.MinimumRepaymentsReducedSubTitle',
              {
                reducedAmount: formatCurrency(minimumRepaymentReducedAmount, {
                  withFractionOnRoundedAmount: true,
                }),
              },
            )}
          </Text>
        </View>
      </View>
    )}
    <DataRowSeparator />

    {loading ? (
      <LoadingRow />
    ) : (
      <View sx={{ padding: '$16' }}>
        <Text variant="xsHeader">
          {t(
            'Content.LoanVariation.ReduceRepaymentConfirmation.RedrawAvailableHeading',
          )}
        </Text>
        <View>
          <Text variant="sHeader">
            {formatCurrency(redrawBalanceAvailable, {
              withFractionOnRoundedAmount: true,
            })}
          </Text>
          <Text
            variant="sBodySecondary"
            style={{ textDecorationLine: 'line-through' }}
          >
            {t(
              'Content.LoanVariation.ReduceRepaymentConfirmation.PreviousRedrawAmount',
              {
                previousRedrawAmount: formatCurrency(previousRedrawAmount, {
                  withFractionOnRoundedAmount: true,
                }),
              },
            )}
          </Text>
          <Text variant="sBodySecondary">
            {t(
              'Content.LoanVariation.ReduceRepaymentConfirmation.RedrawReducedSubTitle',
              {
                redrawReduced: formatCurrency(redrawReduced, {
                  withFractionOnRoundedAmount: true,
                }),
              },
            )}
          </Text>
        </View>
      </View>
    )}
  </DataRowGroup>
);
