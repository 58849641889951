import { Box } from '../ui/atoms/Box';
import { Button } from '../ui/atoms/Button';

type CloseModalIconButtonProps = {
  disabled?: boolean;
  onPress: () => void;
};

export function CloseModalIconButton({
  disabled,
  onPress,
}: CloseModalIconButtonProps) {
  return (
    <Box flexDirection="row-reverse">
      <Button
        icon="close"
        secondary
        disabled={!!disabled}
        variant="circle"
        onPress={onPress}
      />
    </Box>
  );
}
