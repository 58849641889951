import { Pressable, Text, View } from 'dripsy';
import { useState } from 'react';
import { StyleSheet } from 'react-native';

import { IconFamilyName, StyledIcon } from '../../../ui/atoms/StyledIcon';
import { Theme, useTheme } from '../../../ui/theme';

interface MenuItemProps {
  onPress: () => void;
  testID?: string;
  icon: string;
  iconFamily?: IconFamilyName;
  label: string;
  isLast?: boolean;
}

const getMenuItemStyles = (theme: Theme, hoverText: boolean, isLast: boolean) =>
  StyleSheet.create({
    menuItemPressable: {
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 10,
      borderBottomWidth: 1,
      borderColor: isLast ? theme.colors.transparent : theme.colors.borderV2,
    },
    menuItemView: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuItemIcon: { marginRight: 15 },
    menuItemText: {
      fontSize: theme.fontSizes.s,
      fontWeight: '500',
      textDecorationLine: hoverText ? 'underline' : 'none',
    },
  });

export const MenuItem = ({
  icon,
  iconFamily = 'svg',
  label,
  onPress,
  testID,
  isLast = false,
}: MenuItemProps) => {
  const [hoverText, setHoverText] = useState(false);
  const theme = useTheme();

  const MenuItemStyles = getMenuItemStyles(theme, hoverText, isLast);

  return (
    <Pressable
      onPress={onPress}
      testID={testID}
      onHoverIn={() => setHoverText(true)}
      onHoverOut={() => setHoverText(false)}
      style={MenuItemStyles.menuItemPressable}
    >
      <View style={MenuItemStyles.menuItemView}>
        <StyledIcon
          name={icon}
          size="m"
          family={iconFamily}
          bg="transparent"
          style={MenuItemStyles.menuItemIcon}
        />
        <Text style={MenuItemStyles.menuItemText}>{label}</Text>
      </View>
    </Pressable>
  );
};
