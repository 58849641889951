import { useHeaderHeight } from '@react-navigation/elements';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Text, View } from 'dripsy';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import { View as RNView, ViewProps } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { ModalCanBeClosedAtomFamily } from '../../ActionSheet/recoil/PreventClosingModal';
import { FocusOn, FocusOnProps } from '../../components/FocusOn';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ActionSheetType } from '../../navigation/types/screens';
import { useModalHeaderButtonV2 } from '../../utils/hooks/useHeaderButton';
import { isWeb } from '../../utils/platformUtils';

type Props = {
  headerText?: string;
  scrollable?: boolean;
  children?: ReactNode;
  hideBackButton?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  loading?: boolean;
  contentXPadding?: string;
  contentFullScreen?: boolean;
  initialDelayedFocusTrapEnabled?: boolean;
  preventClosingModalWithDialog?: boolean;
  preventClosingModalTitle?: string;
  keyboardShouldPersistTaps?: React.ComponentProps<
    typeof KeyboardAwareScrollView
  >['keyboardShouldPersistTaps'];
} & ViewProps;

function ModalScreenContent({
  headerText,
  contentFullScreen = false,
  contentXPadding = '$120',
  children,
}: Pick<
  Props,
  'headerText' | 'children' | 'contentXPadding' | 'contentFullScreen'
>) {
  return (
    <View
      sx={{
        flex: 1,
        height: contentFullScreen ? '100%' : undefined,
        backgroundColor: '$background',
        borderRadius: '$card',
        mb: ['$32', '$56', '$56'],
        alignItems: 'center',
      }}
    >
      <View
        sx={{
          pt: '$8',
          px: ['$16', contentXPadding, contentXPadding],
          borderRadius: [0, '$card', '$card'],
          width: '100%',
          height: contentFullScreen ? '100%' : undefined,
        }}
      >
        {!!headerText && (
          <Text role="heading" variant="modalHeader" sx={{ mb: '$24' }}>
            {headerText}
          </Text>
        )}
        {children}
      </View>
    </View>
  );
}

export function ModalScreenContainer({
  children,
  headerText,
  scrollable = true,
  hideBackButton = false,
  onClose: initialOnClose,
  onBack,
  contentXPadding,
  contentFullScreen,
  loading = false,
  initialDelayedFocusTrapEnabled,
  preventClosingModalWithDialog = false,
  preventClosingModalTitle = '',
  keyboardShouldPersistTaps = 'handled',
  ...viewProps
}: Props) {
  const navigation = useNavigation();

  // To support cases in which we have multiple modals stacked on top of each other
  const { current: modalId } = useRef(uuid());

  const modalCanBeClosed = useRecoilValue(ModalCanBeClosedAtomFamily(modalId));

  const resetModalCanBeClosed = useResetRecoilState(
    ModalCanBeClosedAtomFamily(modalId),
  );

  useEffect(() => {
    if (preventClosingModalWithDialog && modalCanBeClosed) {
      initialOnClose?.();
      resetModalCanBeClosed();
    }
  }, [
    initialOnClose,
    modalCanBeClosed,
    preventClosingModalWithDialog,
    resetModalCanBeClosed,
  ]);

  const onClose = useMemo(() => {
    if (!preventClosingModalWithDialog) {
      return initialOnClose;
    }

    return () =>
      navigation.navigate(ActionSheetType.PREVENT_CLOSING_MODAL_ACTION_SHEET, {
        modalId,
        title: preventClosingModalTitle,
      });
  }, [
    initialOnClose,
    modalId,
    navigation,
    preventClosingModalTitle,
    preventClosingModalWithDialog,
  ]);

  const headerRef = useRef<RNView>(null);

  const isCurrentScreenFocused = useIsFocused();

  const focusTrapEnabled = isCurrentScreenFocused && !loading;

  useModalHeaderButtonV2({
    onClose,
    onBack,
    hideBackButton,
    headerRef,
    navigation,
    disableCloseButton: loading,
    isCurrentScreenFocused,
  });

  const headerHeight = useHeaderHeight();

  const focusTrapConfiguration: Omit<FocusOnProps, 'children'> = {
    enabled: focusTrapEnabled,
    onEscapeKey: loading ? undefined : onClose,
    containerRefs: [headerRef],
    initialDelayedFocusTrapEnabled,
  };

  const content = (
    <ModalScreenContent
      headerText={headerText}
      contentXPadding={contentXPadding}
      contentFullScreen={contentFullScreen}
    >
      {children}
    </ModalScreenContent>
  );

  return scrollable ? (
    <View sx={{ flex: 1 }} {...viewProps}>
      <View
        sx={{
          height: headerHeight,
          width: '100%',
        }}
      />
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={
          // Used for fixing the dropdown selection issue in Native APP: https://unloan.atlassian.net/browse/UDEL-421
          // Solution: https://stackoverflow.com/questions/53928432/double-tap-button-issue-when-keyboard-opens-react-native
          isWeb ? undefined : keyboardShouldPersistTaps
        }
        persistentScrollbar
        showsVerticalScrollIndicator
        style={{
          width: '100%',
        }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <FocusOn style={{ height: '100%' }} {...focusTrapConfiguration}>
          {content}
        </FocusOn>
      </KeyboardAwareScrollView>
    </View>
  ) : (
    <FocusOn {...focusTrapConfiguration}>
      <NavHeaderSpacer />
      {content}
    </FocusOn>
  );
}
