import { isNotNullOrUndefined } from '@unloan/common-ui';
import { View } from 'dripsy';

import {
  Frequency_Enum,
  Income_Type_Enum,
  IncomeSupportingDocumentUploadIncomeDetailQuery,
} from '../../generated/graphql';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { NewListRow } from '../../ui/molecules/NewListRow';
import { formatCurrency } from '../../utils/currencyHelpers';
import { parseEnumType } from '../../utils/ensureEnumType';
import { joinListOfString } from '../../utils/stringHelpers';
import { assertUnreachable } from '../../utils/typesHelpers';

type IncomeSummaryCardProps = {
  income: IncomeSupportingDocumentUploadIncomeDetailQuery['income_by_pk'];
};

export function IncomeSummaryCard({ income }: IncomeSummaryCardProps) {
  if (!income) {
    return null;
  }

  const {
    amount,
    frequency,
    income_type,
    income_owners,
    employment_income,
    rental_income,
  } = income;

  let incomeFrequency: string;
  const parsedFrequency = parseEnumType(Frequency_Enum, frequency);
  if (!parsedFrequency) {
    incomeFrequency = '--';
  } else {
    incomeFrequency = t(`Content.Common.FrequencyEnumV2.${parsedFrequency}`);
  }

  const incomeOwners = joinListOfString(
    income_owners
      .map(({ applicant: incomeOwner }) => incomeOwner.latest_full_name)
      .filter(isNotNullOrUndefined) || [],
  );

  const getTextAndFooterProps = () => {
    switch (income_type) {
      case Income_Type_Enum.Employment:
        return {
          title: employment_income?.employer || '--',
          caption: `${formatCurrency(amount)}/${incomeFrequency}`,
          subcaption: incomeOwners,
          footer: (
            <View>
              <Separator mx="xs" my="s" />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StyledText variant="caption">
                  {t(
                    `Content.IncomeSupportingDocumentUpload.IncomeSummaryCard.Salary`,
                  )}
                </StyledText>
                <StyledText variant="caption">
                  {formatCurrency(amount)}
                </StyledText>
              </View>
              {employment_income?.annual_overtime ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledText variant="caption">
                    {t(
                      `Content.IncomeSupportingDocumentUpload.IncomeSummaryCard.Overtime`,
                    )}
                  </StyledText>
                  <StyledText variant="caption">
                    {formatCurrency(employment_income?.annual_overtime)}
                  </StyledText>
                </View>
              ) : null}
              {employment_income?.annual_bonus ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledText variant="caption">
                    {t(
                      `Content.IncomeSupportingDocumentUpload.IncomeSummaryCard.Bonus`,
                    )}
                  </StyledText>
                  <StyledText variant="caption">
                    {formatCurrency(employment_income?.annual_bonus)}
                  </StyledText>
                </View>
              ) : null}
              {employment_income?.annual_commissions ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledText variant="caption">
                    {t(
                      `Content.IncomeSupportingDocumentUpload.IncomeSummaryCard.Commission`,
                    )}
                  </StyledText>
                  <StyledText variant="caption">
                    {formatCurrency(employment_income?.annual_commissions)}
                  </StyledText>
                </View>
              ) : null}
              {employment_income?.annual_allowances ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledText variant="caption">
                    {t(
                      `Content.IncomeSupportingDocumentUpload.IncomeSummaryCard.Allowance`,
                    )}
                  </StyledText>
                  <StyledText variant="caption">
                    {formatCurrency(employment_income?.annual_allowances)}
                  </StyledText>
                </View>
              ) : null}
            </View>
          ),
        };
      case Income_Type_Enum.Rental:
        return {
          title: rental_income?.address?.short_address_format || '--',
          caption: `${formatCurrency(amount)}/${incomeFrequency}`,
          subcaption: incomeOwners,
        };
      case Income_Type_Enum.GovernmentPayments:
      case Income_Type_Enum.SavingsInterest:
      case Income_Type_Enum.ShareDividends:
        return {
          title: '',
          caption: `${formatCurrency(amount)}/${incomeFrequency}`,
          subcaption: incomeOwners,
        };
      default:
        assertUnreachable(income_type);
        return {
          title: '',
        };
    }
  };

  return (
    <NewListRow {...getTextAndFooterProps()} style={{ marginBottom: 24 }} />
  );
}
