import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { Box, BoxProps } from '../atoms/Box';
import { Spinner } from '../atoms/Spinner';
import { StyledText } from '../atoms/StyledText';

export type OverlayProps = BoxProps & {
  children?: ReactNode;
};

export function Overlay(props: OverlayProps) {
  return (
    <Box bg="overlayBg" centered style={StyleSheet.absoluteFill} {...props} />
  );
}

export type SpinnerWithTextOverlayProps = Omit<OverlayProps, 'children'> & {
  text: string;
};
export function SpinnerWithTextOverlay({
  text,
  ...otherProps
}: SpinnerWithTextOverlayProps) {
  return (
    <Overlay {...otherProps}>
      <Spinner color="overlayContent" />
      <StyledText variant="caption" color="overlayContent" mt="xs">
        {text}
      </StyledText>
    </Overlay>
  );
}
