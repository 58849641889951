import { useNavigation } from '@react-navigation/native';
import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Household_Expense_Validation_Error_Type } from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { assertUnreachable } from '../../utils/typesHelpers';
import { expensesFormAtom } from '../recoil/expensesFormAtom';
import { CombinedExpensesFormValues } from './forms';

type HandleHouseholdExpenseValidationErrorBaseParams = {
  error: Household_Expense_Validation_Error_Type;
  values: CombinedExpensesFormValues;
  loanApplicationId: string | undefined;
};

type HandleAddHouseholdExpenseValidationErrorParams =
  HandleHouseholdExpenseValidationErrorBaseParams & {
    type: 'ADD';
  };
type HandleEditHouseholdExpenseValidationErrorParams =
  HandleHouseholdExpenseValidationErrorBaseParams & {
    type: 'EDIT';
    householdId: string | undefined;
  };

export function useHandleHouseholdExpenseValidationError() {
  const navigation = useNavigation();
  const { track } = useContext(FeatureFlagsContext);

  const setExpensesFormValuesV2 = useSetRecoilState(expensesFormAtom);

  return {
    handleHouseholdExpenseValidationError: ({
      error,
      values,
      loanApplicationId,
      ...params
    }:
      | HandleAddHouseholdExpenseValidationErrorParams
      | HandleEditHouseholdExpenseValidationErrorParams) => {
      switch (error) {
        case Household_Expense_Validation_Error_Type.LowExpense:
          setExpensesFormValuesV2({ ...values });
          track?.('low-expenses-modal-shown');
          navigation.navigate(ActionSheetType.LOW_EXPENSES, {
            loanApplicationId,
            householdId:
              params.type === 'EDIT' ? params.householdId : undefined,
          });
          return;

        default:
          assertUnreachable(error);
      }
    },
  };
}
