import {
  SelectedDocument,
  useDocumentPicker,
} from '../../utils/hooks/useDocumentPicker';
import { PickerTrigger, Props as PickerTriggerProps } from './PickerTrigger';
import { FormikControlProps } from './types';

type Documents = Array<SelectedDocument>;
export type FileInputRowProps = Omit<PickerTriggerProps, 'onPress' | 'value'> &
  FormikControlProps<Documents>;

function FileInputRow({
  value,
  setFieldValue,
  ...otherProps
}: FileInputRowProps) {
  const { makeDocumentPickerHandler } = useDocumentPicker();
  const onPress = makeDocumentPickerHandler({
    // On select, we replace the all the selected documents
    // For multi file support, we need to append instead of creating a new array.
    onDocumentSelect: (document) => setFieldValue?.([document]),
    // Right now, canceling or error thrown when selecting document doesnt clear the selection.
  });
  const fileCount = value?.length;
  return (
    <PickerTrigger
      {...otherProps}
      onPress={onPress}
      placeholder={
        fileCount
          ? t('Content.Common.ButtonLabel.XAttachment', { x: fileCount })
          : t('Content.Common.ButtonLabel.Upload')
      }
      ignoreValueForPlaceholderColor
    />
  );
}

export default FileInputRow;
