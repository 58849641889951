import { useMemo } from 'react';

import {
  Button,
  ButtonColorVariantProps,
  Props as ButtonProps,
} from '../../ui/atoms/Button';

export type Props = {
  isDestructive?: boolean;
} & ButtonProps;

export function ActionListButton({
  label,
  isDestructive,
  onPress,
  primaryLight,
  secondary,
  tertiary,
  ...otherProps
}: Props) {
  const memoizedColorVariantProps = useMemo((): ButtonColorVariantProps => {
    if (isDestructive) return { secondary: true };

    return {
      primaryLight,
      secondary,
      tertiary,
    } as ButtonColorVariantProps;
  }, [isDestructive, primaryLight, secondary, tertiary]);

  return (
    <Button
      {...memoizedColorVariantProps}
      label={label}
      color={isDestructive ? 'error' : undefined}
      width="100%"
      onPress={onPress}
      {...otherProps}
    />
  );
}
