import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { Box } from '../../ui/atoms/Box';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  LoanCircularProgressBar,
  LoanCircularProgressBarProps,
} from './LoanCircularProgressBar';

export type InLifeCardContentProps = LoanCircularProgressBarProps & {
  displayName: string;
  showError?: boolean;
};

export const InLifeCardContent: React.FC<InLifeCardContentProps> = ({
  displayName,
  showError,
  ...otherProps
}) => (
  <Box pt="m">
    {showError ? (
      <ErrorRow
        testID={TestID.Dashboard.LoanCardError}
        message={t('Content.Home.LoanCardErrorMessage')}
        mb="l"
      />
    ) : (
      <StyledText variant="caption" fontWeight="semiBold" mb="m">
        {displayName.toUpperCase()}
      </StyledText>
    )}
    <LoanCircularProgressBar {...otherProps} />
  </Box>
);
