import { useRestyle, useTheme } from '@shopify/restyle';
import { forwardRef } from 'react';
import {
  TextInput as RNTextInput,
  TextInput,
  TextInputProps,
} from 'react-native';

import { MaxCharacter } from '../../constants/fieldRules';
import { isWeb } from '../../utils/platformUtils';
import { Theme } from '../theme';
import { StyledTextProps, textRestyleFunctions } from './StyledText';

export type Props = StyledTextProps &
  Omit<TextInputProps, keyof StyledTextProps> & {
    noFlex?: boolean;
    wrapText?: boolean;
    wrapNumberOfLines?: number;
    /** When this prop is set
     * wrapText will be automatically set
     * to true after value of the text input
     * exceeded the limit.
     */
    autoWrapTextLengthLimit?: number;
  };

export const StyledTextInputDefaultProps = {
  flex: 1,
  py: isWeb ? 's' : 0,
  px: 0,
  alignSelf: 'stretch',
  variant: 'default',
  fontSize: 'm',
} as const;

export const StyledTextInput = forwardRef<TextInput, Props>(
  (
    {
      noFlex,
      wrapText: wrapTextProp,
      wrapNumberOfLines,
      value,
      autoWrapTextLengthLimit,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme<Theme>();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO implement fontSize restyle function
    const passedProps = useRestyle<Theme, StyledTextProps, Props>(
      textRestyleFunctions,
      props,
    );

    let wrapText = wrapTextProp;
    if (
      autoWrapTextLengthLimit != null &&
      (value?.length ?? 0) > autoWrapTextLengthLimit
    ) {
      wrapText = true;
    }

    const style = [
      passedProps.style,
      ...(noFlex ? [{ flex: undefined }] : []),
      ...(wrapText ? [{ height: theme.sizes.xl }] : []),
    ];

    return (
      <RNTextInput
        placeholderTextColor={theme.colors.disabledContent}
        // For now we use the default selection color on Android
        // Carded in https://github.com/unloan/unloan-app/issues/5738
        // selectionColor={theme.colors.accent}
        keyboardAppearance="default"
        underlineColorAndroid="transparent"
        {...passedProps}
        maxLength={passedProps.maxLength || MaxCharacter.general}
        autoFocus={isWeb ? false : passedProps.autoFocus}
        style={style}
        ref={ref}
        {...(noFlex && {
          flex: undefined,
        })}
        value={value}
        // This is a browser specific fix for Chrome only.
        //  see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error `chrome-off` is a web only enum and
        // our RN types override stopped working since Expo 49 upgrades,
        // see `@types/react-native.d.ts` for existing overrides.
        autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
        {...(wrapText && {
          multiline: true,
          numberOfLines: wrapNumberOfLines,
        })}
      />
    );
  },
);

StyledTextInput.defaultProps = StyledTextInputDefaultProps;

StyledTextInput.displayName = 'StyledTextInput';
