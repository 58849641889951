import { PickerOptions } from '../components/form/types';
import { Frequency_Enum } from '../generated/graphql';
import { parseEnumType } from './ensureEnumType';

export function mapFrequencyOptionsForV2(options: PickerOptions<string>) {
  return options.map(({ label, value }) => {
    const parsedFrequency = parseEnumType(Frequency_Enum, value);
    return {
      label: parsedFrequency
        ? t(`Content.Common.FrequencyLabel.${parsedFrequency}`)
        : label,
      value,
    };
  });
}
