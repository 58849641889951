import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { TestID } from '../../../testID/constants';
import { DeleteIncomeActionSheetErrorAtom } from '../../ActionSheet/recoil/ActionSheetErrors';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Income_Type_Enum } from '../../generated/graphql';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import {
  GovernmentIncomeForm,
  GovernmentIncomeFormDetails,
} from '../components/GovernmentIncomeForm';
import {
  useGetOptionsForOtherIncomeQuery,
  useGetOtherIncomeForV2Form,
  useUpdateOtherIncomeForV2Form,
} from '../graphql/incomeHooks';
import { YourIncomeModalScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeModalScreenProps<Screen.YOUR_INCOME_V2_EDIT_GOVERNMENT_INCOME_MODAL>;

export function EditGovernmentIncomeScreen({ navigation, route }: Props) {
  const { params } = route;

  const { loanApplicationId = '', incomeId = '' } = params || {};

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateBackToMainScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  const {
    frequencyOptions,
    incomeOwnerOptions,
    loading: otherIncomeOptionsLoading,
  } = useGetOptionsForOtherIncomeQuery({ loanApplicationId });

  const {
    initialValues,
    loading: initialValuesLoading,
    currentLoanIncomeCount,
  } = useGetOtherIncomeForV2Form({ loanApplicationId, incomeId });

  const { updateOtherIncome, isUpdatingOtherIncome } =
    useUpdateOtherIncomeForV2Form({ loanApplicationId, incomeId });

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [
    deleteIncomeActionSheetErrorMessage,
    setDeleteIncomeActionSheetErrorMessage,
  ] = useRecoilState(DeleteIncomeActionSheetErrorAtom);

  useFocusEffect(() => () => setDeleteIncomeActionSheetErrorMessage(null));

  const onSubmit = async (formValues: GovernmentIncomeFormDetails) => {
    setDeleteIncomeActionSheetErrorMessage(null);

    const { success, errorMessage } = await updateOtherIncome({
      values: formValues,
      incomeType: Income_Type_Enum.GovernmentPayments,
    });
    if (!success) {
      setFormErrorMessage(errorMessage);
      return;
    }

    navigateBackToMainScreen();
  };

  const onDelete = async () => {
    if (!incomeId) {
      return;
    }
    navigation.navigate(ActionSheetType.DELETE_INCOME_ACTION_SHEET, {
      loanApplicationId,
      incomeId,
      ...route.params,
    });
  };

  const showLoading = otherIncomeOptionsLoading || initialValuesLoading;

  // We're only allowing income deletion if there're more than one income
  const showDeleteButton = currentLoanIncomeCount > 1;

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.GovernmentIncome')}
      scrollable
      loading={isUpdatingOtherIncome}
      onClose={() => {
        navigateBackToMainScreen();
      }}
      hideBackButton
    >
      {showLoading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.OtherIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage || deleteIncomeActionSheetErrorMessage}
            testID={TestID.OtherIncomeForm.ErrorText}
          />
          <GovernmentIncomeForm
            screen={Screen.YOUR_INCOME_V2_EDIT_EMPLOYMENT_INCOME_MODAL}
            initialValues={initialValues}
            isSubmitting={isUpdatingOtherIncome}
            onSubmit={onSubmit}
            frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
            payeeOptions={incomeOwnerOptions}
            showDeleteButton={showDeleteButton}
            onDelete={onDelete}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
