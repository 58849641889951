import { StyleSheet } from 'react-native';

import { StyledIcon } from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { LoanCard, LoanCardProps } from './LoanCard';
import { LoanCardChinContainer } from './LoanCardChinContainer';

type Props = Omit<LoanCardProps, 'active' | 'children'>;

export function ClockIcon() {
  return (
    <StyledIcon
      role="img"
      aria-label="Clock"
      name="time-outline"
      size="s"
      mr="2xs"
      color="secondaryContent"
      style={styles.clockIcon}
    />
  );
}

export type InactiveLoanCardProps = Props & {
  description: string;
};

export function InactiveLoanCard({
  description,
  ...otherProps
}: InactiveLoanCardProps) {
  return (
    <LoanCard active={false} {...otherProps}>
      <LoanCardChinContainer py="s">
        <ClockIcon />
        <StyledText variant="body" color="secondaryContent">
          {description}
        </StyledText>
      </LoanCardChinContainer>
    </LoanCard>
  );
}

export function PreparingForSettlementLoanCard(props: Props) {
  return (
    <InactiveLoanCard
      {...props}
      description={t('Content.HomeLoan.PreparingForSettlement')}
    />
  );
}

const styles = StyleSheet.create({
  clockIcon: {
    transform: [{ scaleX: -1 }],
  },
});
