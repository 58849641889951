import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function AccountClosureCreditCardIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={36} height={37} fill="none" {...props}>
      <Path
        fill="url(#a)"
        d="M28.85 9.5H4.15C3.177 9.5 3 9.676 3 10.65v16.108c0 .975.176 1.151 1.15 1.151h24.7c.974 0 1.15-.176 1.15-1.15V10.65c0-.975-.176-1.151-1.15-1.151Z"
      />
      <Path fill="#676767" d="M30 11.954H3v2.455h27v-2.455Z" />
      <Path
        fill="#A2A2A2"
        d="M27.546 18.09H5.455a.614.614 0 0 1-.614-.613V16.25c0-.339.275-.614.614-.614h22.09c.34 0 .614.275.614.614v1.227a.614.614 0 0 1-.613.614Z"
      />
      <Path fill="#EFEFEF" d="M33 27.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" />
      <Path
        fill="#353535"
        d="M27 21.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm.707 6 1.646 1.646a.5.5 0 1 1-.707.707L27 28.207l-1.646 1.646a.5.5 0 1 1-.707-.707l1.646-1.646-1.646-1.646a.5.5 0 1 1 .707-.707L27 26.793l1.646-1.646a.5.5 0 1 1 .707.707L27.707 27.5Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={3}
          x2={20.137}
          y1={9.5}
          y2={34.634}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
