import {
  Cb_Transaction,
  Cb_TransactionChannelType,
  Cb_TransactionType,
} from '../../generated/graphql';
import { isAutopayDebit } from '../../HomeLoan/utils/isAutopayDebit';
import { formatCurrency } from '../../utils/currencyHelpers';
import { WithFutureType } from '../../utils/typesHelpers';

export function getTransactionTypeLabel(
  transactionType: WithFutureType<Cb_TransactionType>,
  channelType?: WithFutureType<Cb_TransactionChannelType | null>,
): string {
  switch (transactionType) {
    case Cb_TransactionType.InterestCharged:
    case Cb_TransactionType.InterestApplied:
      return t('Content.TransactionList.InterestCharged');
    case Cb_TransactionType.Repayment:
    case Cb_TransactionType.RedrawRepayment:
    case Cb_TransactionType.PaymentMade:
      if (isAutopayDebit(channelType)) {
        return t('Content.TransactionList.AutoPayDebit');
      }

      return t('Content.TransactionList.Repayment');
    case Cb_TransactionType.Withdrawal:
    case Cb_TransactionType.WithdrawalRedraw:
      return t('Content.TransactionList.Withdrawal');
    case Cb_TransactionType.Disbursement:
      return t('Content.TransactionList.Disbursement');
    case Cb_TransactionType.FeeApplied:
      return t('Content.TransactionList.Fee');
    default:
      return '--';
  }
}

export const getTransactionAmountLabel = (transaction: Cb_Transaction) =>
  transaction.type !== Cb_TransactionType.Repayment &&
  transaction.type !== Cb_TransactionType.PaymentMade
    ? `-${formatCurrency(Math.abs(transaction.amount || 0), {
        withFractionOnRoundedAmount: true,
      })}`
    : formatCurrency(transaction.amount || 0, {
        withFractionOnRoundedAmount: true,
      });
