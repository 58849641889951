import { SxProp, View } from 'dripsy';
import * as React from 'react';

type CardFooterProps = {
  children?: React.ReactNode;
  sx?: SxProp;
};

export const CardFooter = ({ children, sx = {} }: CardFooterProps) => (
  <View
    sx={{
      px: '$16',
      pb: '$16',
      ...sx,
    }}
  >
    {children}
  </View>
);
