import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../theme';
import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export function StackedScreensIcon({
  size,
  color,
  ...otherProps
}: SvgIconProps) {
  const width = resizeIconWidth(22, 22, size);
  const theme = useTheme();
  const actualColor = color ?? theme.colors.accent;

  return (
    <Svg
      width={width}
      height={size}
      fill="none"
      viewBox="0 0 22 22"
      {...otherProps}
    >
      <Path
        d="M4.74986 1.34635H17.2602C17.1913 0.477431 16.6396 0 15.6644 0H6.33582C5.37046 0 4.81882 0.477431 4.74986 1.34635ZM2.76003 4.19184H19.2501C19.1121 3.25608 18.6098 2.71181 17.5262 2.71181H4.4839C3.39047 2.71181 2.88809 3.25608 2.76003 4.19184ZM3.8633 22H18.1369C20.3139 22 21.4763 20.8828 21.4763 18.7917V8.96615C21.4763 6.875 20.3139 5.75781 18.1369 5.75781H3.8633C1.67645 5.75781 0.523926 6.86545 0.523926 8.96615V18.7917C0.523926 20.8924 1.67645 22 3.8633 22ZM4.05047 19.8802C3.18361 19.8802 2.72063 19.4505 2.72063 18.572V9.18576C2.72063 8.30729 3.18361 7.88715 4.05047 7.88715H17.9498C18.8068 7.88715 19.2796 8.30729 19.2796 9.18576V18.572C19.2796 19.4505 18.8068 19.8802 17.9498 19.8802H4.05047Z"
        fill={actualColor}
      />
    </Svg>
  );
}
