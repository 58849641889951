import { gql } from '@apollo/client';
import { Text } from 'dripsy';
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import {
  Loan_Application_Type_Enum,
  Loan_Interest_Rate_Type_Enum,
  Product_Type_Enum,
  Refinancing_Reason_Input_Enum,
  useGetMinimumRepaymentLazyQuery,
  useGetSavingsCalculationLazyQuery,
  useGetTotalLoanAmountLazyQuery,
  useYourUnloanScreenV2Query,
} from 'src/generated/graphql';
import { LoanApplicationWizardFooter } from 'src/LoanApplication/components/LoanApplicationWizardFooter';
import { useRefinanceDeclaration } from 'src/LoanConfiguration/utils/useRefinanceDeclaration';
import { useLoanApplicationHeaderProgress } from 'src/utils/hooks/useLoanApplicationHeaderProgress';
import { useSecurityCostCalculationState } from 'src/utils/hooks/useSecurityCostCalculationState';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { RefreshPageErrorRow } from '../../components/RefreshPageErrorRow';
import { ScreenErrorFallback } from '../../components/ScreenErrorFallback';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { getSumOfMergedLiabilities } from '../../LoanConfiguration/utils/getSumOfSelectedRefinancedLiabilities';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { parseEnumType } from '../../utils/ensureEnumType';
import { useDebouncedGtmDataLayer } from '../../utils/hooks/useDebouncedSendToGTM';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import {
  getMergedLiabilitiesAllowedToBeConsolidated,
  getMergedLiabilitiesAllowedToBeRefinanced,
} from '../../utils/mergedLiabilities';
import {
  formatInterestRate,
  formatInterestRates,
} from '../../utils/stringHelpers';
import { EmptyStateIllustration } from '../components/EmptyStateIllustration';
import { InvalidLoanApplication } from '../components/InvalidLoanApplication';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanScreenHeader } from '../components/LoanScreenHeader';
import { YourUnloanCard } from '../components/YourUnloanCard';
import { YourUnloanSavingsBanner } from '../components/YourUnloanSavingsBanner';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import { createTopUpTotalLoanAmountCaption } from '../utils/createTopUpTotalLoanAmountCaption';
import {
  LoanValidationResult,
  validateLoanApplicationForScreen,
} from '../utils/loanApplicationUtils';

export type Props =
  LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN>;

export const YOUR_UNLOAN_SCREEN_V2_QUERY = gql`
  query YourUnloanScreenV2($loanApplicationId: uuid!) {
    loanApplication: loan_application_by_pk(id: $loanApplicationId) {
      parent_loan_account {
        balances {
          available_redraw_balance
        }
        settings {
          interest_rate
          interest_rate_discount
          remaining_term_in_months
        }
      }

      top_up_home_loan_liability: merged_liabilities(
        where: {
          dynamite_liability_type: { _eq: "HOME_LOAN" }
          dynamite_for_refinancing: { _eq: true }
        }
      ) {
        dynamite_balance
        dynamite_repayment_amount
        dynamite_loan_term_months
      }

      mergedLiabilitiesForRefinance: merged_liabilities(
        where: { dynamite_for_refinancing: { _eq: true } }
      ) {
        id
      }

      ...LoanApplicationForSetupLoanScreen
    }

    loan_application_target(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...LoanApplicationTarget
    }

    # Preload data for Setup Loan Screen
    financial_declaration(
      where: { loan_application_id: { _eq: $loanApplicationId } }
      limit: 1
    ) {
      ...FinancialDeclarationForSetupLoanScreen
    }

    sumLoanApplicationCostAndBuffer: get_loan_application_cost_and_buffer(
      loan_application_id: $loanApplicationId
    ) {
      ...LoanApplicationCostAndBufferForSetupLoanScreen
    }

    mergedLiabilitiesAllowedToBeRefinanced: get_merged_liabilities_allowed_to_be_refinanced(
      loan_application_id: $loanApplicationId
    ) {
      merged_liability {
        ...MergedLiabilityForSetupLoanScreen
      }
    }

    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      ...ApplicantForSetupLoanScreen
    }
  }

  fragment LoanApplicationTarget on loan_application_target {
    id
    desired_loan_amount
    term_months
    repayment_type
    top_up_amount
    loan_application_id
    loan_application_securities {
      id
      property_purpose
    }
    # Updating cache for target product rate
    target_product_rate {
      id: product_rate_id
      interest_rate
      loan_interest_rate_type
      rate_adjustment
      product_type
    }
  }
`;

export const GET_TOTAL_LOAN_AMOUNT = gql`
  # We need to separate query for total loan amount
  # So we can use network-only fetch policy to make sure we always get the latest total loan amount
  # One case we're trying to solve in here is we received outdated loan amount if we refetch right after user updates their property address
  # Related card: https://github.com/unloan/unloan-app/issues/5029
  query GetTotalLoanAmount($loanApplicationId: uuid!) {
    loan_application_target_total_and_fees(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      loan_application_id
      total_loan_amount
      refinanced_amount
      total_fees
      buffer_amount
      mortgage_registration_fee
      mortgage_discharge_fee
    }
  }
`;
export const GET_MINIMUM_REPAYMENT = gql`
  query GetMinimumRepayment(
    $interestRate: Float!
    $loanAmount: Float!
    $termInMonths: Int!
  ) {
    repayment_schedule(
      interestRate: $interestRate
      loanAmount: $loanAmount
      termInMonths: $termInMonths
    ) {
      min_loan_amount
      max_loan_amount
      monthly_amount
      repayment_calculation_error
      total_interest_payable
    }
  }
`;

export const GET_SAVINGS_CALCULATION = gql`
  query GetSavingsCalculation(
    $loanApplicationId: uuid!
    $desiredLoanAmount: Float!
    $liabilityIds: [String!]!
    $loanTerm: Int!
  ) {
    get_savings_calculation(
      loan_application_id: $loanApplicationId
      desired_loan_amount: $desiredLoanAmount
      liability_ids: $liabilityIds
      loan_term: $loanTerm
    ) {
      savings
      merged_liabilities {
        id
        interest_rate
      }
      savings_calculation_error_type
    }
  }
`;

function LoanApplicationYourUnloanBase({ navigation, route }: Props) {
  const { params } = route;
  const loanApplicationId = params?.loanApplicationId || '';

  const { flags } = React.useContext(FeatureFlagsContext);
  const enableShowSavingsOnYourUnloan =
    flags.ENABLE_SHOW_SAVINGS_ON_YOUR_UNLOAN;

  const {
    data,
    loading: yourUnloanScreenLoading,
    error,
    refetch,
  } = useYourUnloanScreenV2Query({
    variables: {
      loanApplicationId,
    },
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  const [
    getTotalLoanAmount,
    { data: getTotalLoanAmountData, loading: getTotalLoanAmountLoading },
  ] = useGetTotalLoanAmountLazyQuery();

  const [
    callMinimumRepaymentQuery,
    { data: minimumRepaymentResult, loading: minRepaymentLoading },
  ] = useGetMinimumRepaymentLazyQuery();

  const [getSavingsCalculation, { data: savingsCalculationData }] =
    useGetSavingsCalculationLazyQuery();

  const loanApplication = data?.loanApplication;
  const loanApplicationType = loanApplication?.type;
  const targetLoan = data?.loan_application_target?.[0];
  const getTotalLoanAmountRes =
    getTotalLoanAmountData?.loan_application_target_total_and_fees?.[0];
  const loanAmount = getTotalLoanAmountRes?.total_loan_amount || 0;
  const termInMonths = targetLoan?.term_months;

  const previousTermInMonths = useMemo(() => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      return loanApplication?.parent_loan_account?.settings
        ?.remaining_term_in_months;
    }

    return undefined;
  }, [loanApplication, loanApplicationType]);

  const interestRate = useMemo(() => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      return loanApplication?.parent_loan_account?.settings?.interest_rate;
    }
    return targetLoan?.target_product_rate?.interest_rate;
  }, [
    loanApplication?.parent_loan_account?.settings?.interest_rate,
    loanApplicationType,
    targetLoan?.target_product_rate?.interest_rate,
  ]);

  const rateAdjustment = useMemo(() => {
    if (loanApplicationType === Loan_Application_Type_Enum.TopUp) {
      return loanApplication?.parent_loan_account?.settings
        ?.interest_rate_discount;
    }
    return targetLoan?.target_product_rate?.rate_adjustment;
  }, [
    loanApplication?.parent_loan_account?.settings?.interest_rate_discount,
    loanApplicationType,
    targetLoan?.target_product_rate?.rate_adjustment,
  ]);

  const desiredLoanAmount = targetLoan?.desired_loan_amount;
  const mergedLiabilitiesForRefinance =
    data?.loanApplication?.mergedLiabilitiesForRefinance;

  const savingsAmount =
    savingsCalculationData?.get_savings_calculation?.savings;
  const liabilityInterestRates =
    savingsCalculationData?.get_savings_calculation?.merged_liabilities?.map(
      (ml) => ml?.interest_rate,
    ) || [];

  const mortgageRegistrationFee =
    getTotalLoanAmountRes?.mortgage_registration_fee || 0;
  const mortgageDischargeFee =
    getTotalLoanAmountRes?.mortgage_discharge_fee || 0;

  const {
    waitingForCostCalculationToBeCompleted,
    isWaitingForRetryToComplete,
    retrySecurityCostCalculation,
    shouldShowErrorForCostCalculation,
  } = useSecurityCostCalculationState({
    loanApplicationId,
    onCalculationComplete: async () => {
      await getTotalLoanAmount({
        variables: { loanApplicationId: loanApplicationId ?? '' },
        fetchPolicy: 'network-only',
        context: {
          sentryContext: {
            loanApplicationId,
          },
        },
      });
    },
  });

  const loadingLoanAmountAndSecurityCosts =
    getTotalLoanAmountLoading || waitingForCostCalculationToBeCompleted;

  const { getScreenOrder } = useNavigateToLoanApplicationScreen(
    navigation,
    route,
    loanApplicationId,
  );
  useLoanApplicationHeaderProgress(navigation, {
    current: getScreenOrder({
      currentSection: LoanApplicationSection.YourUnloan,
    }),
  });

  useEffect(() => {
    if (loanAmount && interestRate && termInMonths) {
      callMinimumRepaymentQuery({
        variables: {
          loanAmount,
          interestRate,
          termInMonths,
        },
      });
    }

    const mergedLiabilitiesForRefinanceIds =
      mergedLiabilitiesForRefinance?.map((ml) => ml.id) || [];

    if (
      desiredLoanAmount &&
      mergedLiabilitiesForRefinanceIds.length > 0 &&
      termInMonths
    ) {
      getSavingsCalculation({
        variables: {
          loanApplicationId,
          desiredLoanAmount,
          liabilityIds: mergedLiabilitiesForRefinanceIds,
          loanTerm: termInMonths,
        },
      });
    }
  }, [
    callMinimumRepaymentQuery,
    desiredLoanAmount,
    getSavingsCalculation,
    interestRate,
    loanAmount,
    loanApplicationId,
    mergedLiabilitiesForRefinance,
    termInMonths,
  ]);

  const minimumRepaymentAmount =
    minimumRepaymentResult?.repayment_schedule.monthly_amount;
  const minRepaymentCalcError =
    minimumRepaymentResult?.repayment_schedule.repayment_calculation_error;

  const navigateToLoanSetup = () => {
    if (isTopUpApplication) {
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.SETUP_LOAN_FOR_TOP_UP_MODAL,
        params: {
          ...params,
          loanApplicationTargetId: targetLoan?.id,
        },
      });
    } else {
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.SETUP_LOAN_V2_MODAL,
        params: {
          ...params,
          loanApplicationTargetId: targetLoan?.id,
        },
      });
    }
  };

  const {
    financialDeclaration: selectedFinancialDeclaration,
    loading: refinanceDeclarationLoading,
    upsertFinancialDeclarationLoading,
    upsertFinancialDeclaration,
  } = useRefinanceDeclaration(loanApplicationId);

  const screenLoading = yourUnloanScreenLoading || refinanceDeclarationLoading;

  const financialDeclaration = data?.financial_declaration?.[0];

  const cashoutList = useMemo(
    () =>
      financialDeclaration?.top_up_reasons.map((i) => ({
        reason: i.top_up_reason,
        amount: i.top_up_amount,
        otherReason: i.other_top_up_reason_description,
      })),
    [financialDeclaration?.top_up_reasons],
  );

  const showSavingsAmountAndDescription =
    enableShowSavingsOnYourUnloan &&
    (!cashoutList || cashoutList.length === 0) &&
    savingsAmount &&
    savingsAmount > 0;

  useDebouncedGtmDataLayer(
    GTMAppInteractionEventDescription.ValueChanged,
    buildApplicationInteractionEventKey(
      SectionInteractionKey.YourUnloan,
      Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
      FieldInteractionKey.SavingsValueChange,
    ),
    savingsAmount,
    {
      savings_amount: savingsAmount,
      savings_banner_visible: !!showSavingsAmountAndDescription,
    },
  );

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onPrimaryButtonPress = async () => {
    if (targetLoan) {
      sendAppInteractionEventToGTM({
        description: GTMAppInteractionEventDescription.ButtonClicked,
        additionalData: {
          application_interaction_event_key:
            buildApplicationInteractionEventKey(
              SectionInteractionKey.YourUnloan,
              Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
              FieldInteractionKey.Continue,
            ),
          savings_banner_visible: !!showSavingsAmountAndDescription,
          savings_amount: showSavingsAmountAndDescription ? savingsAmount : 0,
        },
      });
      navigation.navigate(Screen.SINGLE_V2_MODAL, {
        screen: Screen.FINANCIAL_DECLARATION_MODAL_V2,
        params: { loanApplicationId },
      });
      return;
    }

    if (isTopUpApplication) {
      if (!selectedFinancialDeclaration?.id) {
        await upsertFinancialDeclaration(
          Refinancing_Reason_Input_Enum.BorrowMoreMoney,
        );
      }

      navigation.navigate(Screen.LOAN_CONFIGURATION_V2_MODAL, {
        screen: Screen.LOAN_CONFIGURATION_V2_SETUP_LOAN,
        params: { loanApplicationId },
      });

      return;
    }

    navigation.navigate(Screen.LOAN_CONFIGURATION_V2_MODAL, {
      screen: Screen.LOAN_CONFIGURATION_V2_SELECT_REFI_REASON,
      params: {
        ...params,
      },
    });
  };

  const refinancedAmount = getTotalLoanAmountRes?.refinanced_amount || 0;

  const isTopUpApplication =
    loanApplication?.type === Loan_Application_Type_Enum.TopUp;

  const topUpHomeLoanLiability =
    loanApplication?.top_up_home_loan_liability?.[0];

  const redrawBalance =
    loanApplication?.parent_loan_account?.balances?.available_redraw_balance;

  const { previousMinRepaymentAmount, totalLoanAmountCaption } = useMemo(() => {
    if (!isTopUpApplication) {
      return {};
    }

    const unloanLiabilityBalance =
      topUpHomeLoanLiability?.dynamite_balance || 0;

    const mergedLiabilitiesAllowedToBeRefinanced =
      getMergedLiabilitiesAllowedToBeRefinanced(
        data?.mergedLiabilitiesAllowedToBeRefinanced.map(
          (ml) => ml.merged_liability,
        ),
      );

    const liabilitiesToBeConsolidated =
      getMergedLiabilitiesAllowedToBeConsolidated(
        mergedLiabilitiesAllowedToBeRefinanced,
        loanApplicationType,
      ).filter((liability) => liability.dynamite_for_refinancing);

    const consolidatedDebtsAmount = getSumOfMergedLiabilities(
      liabilitiesToBeConsolidated,
    );

    return {
      previousMinRepaymentAmount:
        topUpHomeLoanLiability?.dynamite_repayment_amount,
      totalLoanAmountCaption: createTopUpTotalLoanAmountCaption({
        unloanLiabilityBalance,
        cashoutList,
        consolidatedDebtsAmount,
        redrawAmount: redrawBalance,
      }),
    };
  }, [
    cashoutList,
    data?.mergedLiabilitiesAllowedToBeRefinanced,
    isTopUpApplication,
    loanApplicationType,
    redrawBalance,
    topUpHomeLoanLiability?.dynamite_balance,
    topUpHomeLoanLiability?.dynamite_repayment_amount,
  ]);

  const { title, caption } = useMemo(() => {
    if (isTopUpApplication) {
      return {
        title: t('Content.YourUnloan.TopUpHeader.Title'),
        caption: t('Content.YourUnloan.TopUpHeader.Caption'),
      };
    }

    return {
      title: t('Content.YourUnloan.Header.Title'),
      caption: t('Content.YourUnloan.Header.Caption'),
    };
  }, [isTopUpApplication]);

  const primaryButtonLabel = useMemo(() => {
    if (targetLoan) {
      return t('Content.Common.ButtonLabel.Continue');
    }

    if (isTopUpApplication) {
      return t('Content.YourUnloan.TopUpSetupHomeLoan');
    }

    return t('Content.YourUnloan.SetupHomeLoan');
  }, [isTopUpApplication, targetLoan]);

  const footerCaption = useMemo(() => {
    if (targetLoan) {
      if (isTopUpApplication) {
        return t('Content.YourUnloan.TopUpVariant.Footer');
      }
      return t('Content.YourUnloan.Footer');
    }
    return undefined;
  }, [isTopUpApplication, targetLoan]);

  if (screenLoading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanScreenHeader
          title={t('Content.YourUnloan.Header.Title')}
          caption={t('Content.YourUnloan.Header.Caption')}
        />
        <ScreenLoadingContainer loading />
      </LoanApplicationScreenContainer>
    );
  }

  if (error) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <ScreenErrorFallback
          error={error}
          displayMessage={t('Content.YourUnloan.QueryError')}
          refetch={refetch}
        />
      </LoanApplicationScreenContainer>
    );
  }

  const loanValidationResult = validateLoanApplicationForScreen({
    screenName: route.name,
    loanApplication,
  });

  if (loanValidationResult !== LoanValidationResult.Valid) {
    return <InvalidLoanApplication validationResult={loanValidationResult} />;
  }

  const totalFeesAndBuffer =
    (getTotalLoanAmountRes?.total_fees || 0) +
    (getTotalLoanAmountRes?.buffer_amount || 0);

  return (
    <LoanApplicationScreenContainer flex={1}>
      <NavHeaderSpacer />
      {shouldShowErrorForCostCalculation ? (
        <Box px="m">
          <RefreshPageErrorRow
            onRefreshPress={retrySecurityCostCalculation}
            refreshDisabled={isWaitingForRetryToComplete}
            refreshLoading={isWaitingForRetryToComplete}
          />
        </Box>
      ) : null}
      <LoanScreenHeader title={title} caption={caption} />
      {targetLoan ? (
        <Box px="m" my="xl" bg="bg">
          {showSavingsAmountAndDescription ? (
            <YourUnloanSavingsBanner
              savingsAmount={savingsAmount}
              sx={{ marginBottom: '$24' }}
            />
          ) : null}
          <YourUnloanCard
            screen={Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN}
            canBePressed
            loanApplicationType={loanApplicationType}
            onPress={navigateToLoanSetup}
            totalLoanAmount={loanAmount}
            totalLoanAmountLoading={loadingLoanAmountAndSecurityCosts}
            interestRate={interestRate}
            propertyPurpose={
              targetLoan.loan_application_securities[0]?.property_purpose
            }
            loanInterestRateType={parseEnumType(
              Loan_Interest_Rate_Type_Enum,
              targetLoan?.target_product_rate?.loan_interest_rate_type,
            )}
            productType={parseEnumType(
              Product_Type_Enum,
              targetLoan?.target_product_rate?.product_type,
            )}
            rateAdjustment={rateAdjustment}
            refinancedAmount={refinancedAmount}
            refinancePurpose={financialDeclaration?.refinancing_reason}
            repaymentType={targetLoan.repayment_type}
            feesAndBufferAmount={totalFeesAndBuffer}
            feesAndBufferAmountLoading={loadingLoanAmountAndSecurityCosts}
            bufferAmount={getTotalLoanAmountRes?.buffer_amount || 0}
            termInMonths={targetLoan.term_months}
            previousTermInMonths={previousTermInMonths}
            cashoutList={cashoutList}
            oldCashoutAmount={targetLoan.top_up_amount}
            minRepaymentAmount={minimumRepaymentAmount}
            previousMinRepaymentAmount={previousMinRepaymentAmount}
            minRepaymentCalcError={minRepaymentCalcError}
            minRepaymentLoading={minRepaymentLoading}
            redrawBalance={redrawBalance}
            totalLoanAmountCaption={totalLoanAmountCaption}
            mortgageRegistrationFee={mortgageRegistrationFee}
            mortgageDischargeFee={mortgageDischargeFee}
            minimumRepaymentTestId={TestID.YourUnloan.MinimumRepaymentTestId}
            totalLoanAmountTestId={TestID.YourUnloan.TotalLoanAmountTestId}
            availableRedrawTestId={TestID.YourUnloan.AvailableRedrawTestId}
            loanTermTestId={TestID.YourUnloan.LoanTermTestId}
            cardTestID={TestID.YourUnloan.CardTestId}
          />
        </Box>
      ) : (
        <EmptyState />
      )}
      <LoanApplicationWizardFooter
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonTestID={TestID.YourUnloan.ContinueButton}
        onPrimaryButtonPress={onPrimaryButtonPress}
        isPrimaryButtonLoading={upsertFinancialDeclarationLoading}
        footerCaption={footerCaption}
      />
      {targetLoan && showSavingsAmountAndDescription ? (
        <>
          <Text variant="caption" sx={{ px: '$20' }}>
            {t('Content.YourUnloan.SavingsDescription1', {
              s: liabilityInterestRates.length > 1 ? 's' : '',
              interestRates: formatInterestRates(liabilityInterestRates),
              productRate: formatInterestRate(interestRate),
            })}
          </Text>
          <Text
            variant="caption"
            sx={{ px: '$20', paddingTop: '$8', paddingBottom: '$32' }}
          >
            {t('Content.YourUnloan.SavingsDescription2')}
          </Text>
        </>
      ) : null}
    </LoanApplicationScreenContainer>
  );
}

const EmptyState: React.FC = () => (
  <Box
    testID={TestID.YourUnloan.EmptyStateIllustration}
    alignItems="center"
    my="xl"
  >
    <EmptyStateIllustration name="loanV2" />
  </Box>
);

export const LoanApplicationYourUnloanV2 = withAuthenticationRequired(
  LoanApplicationYourUnloanBase,
);
