import { atom } from 'recoil';

import {
  Autopay_Frequency_Input_Enum,
  Change_Request_Status_Enum,
} from '../generated/graphql';

export type SubmittedAutopayChangeRequest = {
  cbaAccountId: string;
  repaymentFrequency: Autopay_Frequency_Input_Enum;
  repaymentAmount: number;
  paymentStartDate: Date;
  directDebitAccountNumber: string;
  externalAccountId: string;
  estimatedShortfall: number;
  isMinimumAmount: boolean;
};

export const autopayChangeFormAtom = atom<SubmittedAutopayChangeRequest | null>(
  {
    key: 'autopayChangeForm',
    default: null,
  },
);

export type AutopayCustomRepaymentFormValues = {
  customRepaymentFrequency: Autopay_Frequency_Input_Enum | '';
  customRepaymentAmount: number | null;
};

export const autopayCustomRepaymentFormAtom =
  atom<AutopayCustomRepaymentFormValues>({
    key: 'autopayCustomRepaymentForm',
    default: {
      customRepaymentFrequency: '',
      customRepaymentAmount: null,
    },
  });

export const autopayCancellationStatusAtom =
  atom<Change_Request_Status_Enum | null>({
    key: 'autopayCancellationStatusAtom',
    default: null,
  });
