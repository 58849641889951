import { gql } from '@apollo/client';
import { useCallback } from 'react';

import {
  useCreateWithdrawalRequestWithoutCentsMutation,
  Withdrawal_Request_Rejection_Reason,
} from '../../generated/graphql';
import { INLIFE_PAYMENT_REQUEST_FRAGMENT } from '../../Home/graphql/fragments';
import { HomeLoanWithdrawalDetail } from '../../HomeLoan/recoil/homeLoanWithdrawalDetailAtom';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { assertUnreachable } from '../../utils/typesHelpers';

export const CREATE_WITHDRAWAL_REQUEST_WITHOUT_CENTS_MUTATION = gql`
  mutation CreateWithdrawalRequestWithoutCents(
    $cba_account_id: uuid!
    $amount: Int!
    $external_account_id: uuid!
    $description: String!
    $reference: String
  ) {
    create_withdrawal_request(
      input: {
        cba_account_id: $cba_account_id
        amount: $amount
        external_account_id: $external_account_id
        description: $description
        reference: $reference
      }
    ) {
      id
      rejected_reason
      remaining_amount
      exceeded_by_amount
      withdrawal_request {
        payment_request {
          ...PaymentRequest
        }
      }
    }
  }

  ${INLIFE_PAYMENT_REQUEST_FRAGMENT}
`;

type UseConfirmPaymentProps = {
  screen: Screen | ActionSheetType;
  cbaAccountId: string;
  onError: (message: string) => void;
  onExceedsDailyLimit: (exceededByAmount?: number | null) => void;
  onSuccess: (paymentRequestId: string) => void;
};

export const useConfirmPaymentWithoutCents = ({
  screen,
  cbaAccountId,
  onError,
  onExceedsDailyLimit,
  onSuccess,
}: UseConfirmPaymentProps) => {
  const [createWithdrawalRequest, { loading, error }] =
    useCreateWithdrawalRequestWithoutCentsMutation();

  const confirmPayment = useCallback(
    async (variables: HomeLoanWithdrawalDetail) => {
      const [result, err] = await safelyCallMutation(createWithdrawalRequest, {
        variables,
        context: { sentryContext: { cbaAccountId } },
      });

      if (err) {
        // Mutation failed, no point going further.
        // The error should get populated by the mutation hook
        return null;
      }

      if (!result?.data?.create_withdrawal_request) {
        onError(t('Content.Common.Error.FailPaymentProcessing'));
        captureException('Unexpected missing create_withdrawal_request', {
          cbaAccountId,
          description:
            'CreateWithdrawalRequestMutation did not error but it returned an unexpected value.',
          screen,
        });
        return null;
      }

      const {
        withdrawal_request: withdrawalRequest,
        rejected_reason: rejectedReason,
        exceeded_by_amount: exceededByAmount,
      } = result.data.create_withdrawal_request;

      if (rejectedReason) {
        switch (rejectedReason) {
          case Withdrawal_Request_Rejection_Reason.ExceedsDailyLimit: {
            onExceedsDailyLimit(exceededByAmount);
            break;
          }
          default:
            assertUnreachable(rejectedReason);
        }

        return null;
      }

      if (!withdrawalRequest) {
        onError(t('Content.Common.Error.FailPaymentProcessing'));
        captureException('Unexpected missing withdrawalRequest', {
          cbaAccountId,
          description:
            'CreateWithdrawalRequestMutation did not error but the return value did not contain expected withdrawalRequest.',
          screen,
        });
        return null;
      }

      onSuccess(withdrawalRequest.payment_request.id);

      return withdrawalRequest.payment_request.id;
    },
    [
      cbaAccountId,
      createWithdrawalRequest,
      onError,
      onExceedsDailyLimit,
      onSuccess,
      screen,
    ],
  );

  return { confirmPayment, loading, error };
};
