import {
  AppAddressFormat,
  PickerOption,
  yupAddress,
} from '../../components/form/types';
import {
  Property_Purpose_Enum,
  Property_Type_Enum,
  State_Enum,
} from '../../generated/graphql';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames,
} from '../../ui/v2/ManualPropertyInputFields';
import { yup } from '../../utils/yup';

export enum PropertyDetailsFormFields {
  PropertyPurpose = 'propertyPurpose',
  PropertyType = 'propertyType',
  PropertyValue = 'propertyValue',
  AddressResult = 'addressResult',
}

export const propertyDetailsManualAddressPrefix =
  'propertyDetailsManualAddressPrefix';
export const propertyDetailsManualInputFieldNames = getManualInputFieldNames(
  propertyDetailsManualAddressPrefix,
);

export const propertyValueYupSchema = yup
  .number()
  .nullable()
  .required(t('Content.PropertyDetails.Form.EstimatedPropertyValueError'))
  .min(1, t('Content.PropertyDetails.Form.EstimatedPropertyValueError'));

/**
 * This schema is currently specific for refinance flow
 */
export const getPropertyDetailsFormSchema = () =>
  yup
    .object({
      [PropertyDetailsFormFields.AddressResult]: yup
        .object()
        .when(propertyDetailsManualInputFieldNames.isManualInput, {
          is: true,
          then: yup.object().nullable(),
          otherwise: yupAddress().nullable().required(),
        }),
      [PropertyDetailsFormFields.PropertyType]: yup
        .string()
        .nullable()
        .required(t('Content.PropertyDetails.Form.PropertyTypeError')),
      [PropertyDetailsFormFields.PropertyValue]: propertyValueYupSchema,
      [PropertyDetailsFormFields.PropertyPurpose]: yup
        .string()
        .nullable()
        .required(t('Content.Common.Error.PleaseSelectAnOption')),
    })
    // Append with manual address input fields
    .concat(getManualAddressYupSchema(propertyDetailsManualAddressPrefix));

export type PropertyDetailsFormValue = {
  addressId?: string;

  [PropertyDetailsFormFields.AddressResult]: AppAddressFormat | undefined;
  [PropertyDetailsFormFields.PropertyType]: Property_Type_Enum | undefined;
  [PropertyDetailsFormFields.PropertyValue]: number | undefined;
  [PropertyDetailsFormFields.PropertyPurpose]:
    | Property_Purpose_Enum
    | undefined;

  // Manual address input values
  [propertyDetailsManualInputFieldNames.isManualInput]: boolean | undefined;
  [propertyDetailsManualInputFieldNames.postcode]: string | undefined;
  [propertyDetailsManualInputFieldNames.state]: State_Enum | undefined;
  [propertyDetailsManualInputFieldNames.streetName]: string | undefined;
  [propertyDetailsManualInputFieldNames.streetNo]: string | undefined;
  [propertyDetailsManualInputFieldNames.streetType]:
    | PickerOption<string>
    | undefined;
  [propertyDetailsManualInputFieldNames.suburb]: string | undefined;
  [propertyDetailsManualInputFieldNames.unitNo]: string | undefined;
};

export const DEFAULT_PROPERTY_DETAILS_REFI_INITIAL_VALUES: PropertyDetailsFormValue =
  {
    [PropertyDetailsFormFields.AddressResult]: undefined,
    [PropertyDetailsFormFields.PropertyType]: undefined,
    [PropertyDetailsFormFields.PropertyValue]: undefined,
    [PropertyDetailsFormFields.PropertyPurpose]: undefined,

    // Manual address input values
    [propertyDetailsManualInputFieldNames.isManualInput]: undefined,
    [propertyDetailsManualInputFieldNames.postcode]: undefined,
    [propertyDetailsManualInputFieldNames.state]: undefined,
    [propertyDetailsManualInputFieldNames.streetName]: undefined,
    [propertyDetailsManualInputFieldNames.streetNo]: undefined,
    [propertyDetailsManualInputFieldNames.streetType]: undefined,
    [propertyDetailsManualInputFieldNames.suburb]: undefined,
    [propertyDetailsManualInputFieldNames.unitNo]: undefined,
  };
