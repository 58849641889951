import { useIsFocused } from '@react-navigation/native';
import { useEffect, useRef } from 'react';

import { HomeTabScreenProps } from '../../Home/navigation/types';
import { Screen } from '../../navigation/types/screens';
import { HomeLoanModal } from '../types';

const DEFAULT_NAVIGATE_TIMEOUT = 150;

export const useHomeLoanModalIsOpenEffect = ({
  modal,
  modalToScreenMappings,
  setParams,
}: {
  modal?: HomeLoanModal;
  modalToScreenMappings: Partial<Record<HomeLoanModal, Screen>>;
  setParams?: HomeTabScreenProps<Screen.HOME_LOAN>['navigation']['setParams'];
}) => {
  const isFocused = useIsFocused();

  const modalIsOpenRef = useRef(false);
  useEffect(() => {
    if (!modal) {
      return;
    }

    const screen = modalToScreenMappings[modal];

    if (!screen) {
      return;
    }

    // When the modal is closed, isFocused will be set back to "true"
    if (!isFocused) {
      modalIsOpenRef.current = true;
    } else if (modalIsOpenRef.current) {
      modalIsOpenRef.current = false;
      setTimeout(
        () => setParams?.({ modal: HomeLoanModal.NONE }),
        DEFAULT_NAVIGATE_TIMEOUT,
      );
    }
  }, [isFocused, modal, modalToScreenMappings, setParams]);
};
