import { forwardRef } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { FieldRules } from '../../constants/fieldRules';
import {
  Props as StyledTextInputProps,
  StyledTextInput,
} from '../../ui/atoms/StyledTextInput';
import {
  formatCurrency,
  stripDollarSign,
  stripNonDigit,
} from '../../utils/currencyHelpers';
import { toFloat } from '../../utils/numberHelpers';

export type Props = Omit<StyledTextInputProps, 'value' | 'onChangeText'> & {
  // For currency, we use number for the state and the callback instead of string.
  value?: number | null;
  onChangeText?: (value: number | null) => void;
};

export function guardChangedValue(
  changedValue: number | null,
  previousValue: number | null,
) {
  if (changedValue == null) {
    return null;
  }
  const isNewValueValid = FieldRules.currency.isValid(changedValue);
  if (isNewValueValid) {
    return changedValue;
  }
  return previousValue;
}

export const CurrencyInput = forwardRef<RNTextInput, Props>((props, ref) => (
  <StyledTextInput
    {...props}
    width="100%"
    ref={ref}
    keyboardType="numeric"
    value={
      props.value != null
        ? formatCurrency(props.value, { noFraction: true })
        : ''
    }
    onChangeText={(text) => {
      const withoutDollarSign = stripDollarSign(text);
      // Right now we strip all non-digit chars, including commas and dots,
      // this will prevent the user to input fraction for the currency.
      const digitOnly = stripNonDigit(withoutDollarSign);
      // Only run onChangeText() if the text contains valid changes
      if (digitOnly === withoutDollarSign) {
        const parsedDigit =
          withoutDollarSign === '' ? null : toFloat(withoutDollarSign);

        props.onChangeText?.(
          guardChangedValue(parsedDigit, props.value || null),
        );
      }
    }}
  />
));
