import { MAX_LOAN_TERM_IN_YEARS } from '../../LoanConfiguration/utils/setupLoanFormHelper';
import { Separator } from '../../ui/atoms/Separator';
import { Tooltip } from '../../ui/atoms/Tooltip';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatLoanTerm } from '../../utils/stringHelpers';
import { LoanDetailsRenderProps } from './types';
import { LoanDetails } from './YouUnloanCardLoanDetails';

export function LoanDetailsDefault({
  totalLoanAmount,
  interestRate,
  rateAdjustment,
  refinancedAmount,
  refinancePurpose,
  termInMonths,
  totalLoanAmountLoading,
  repaymentType,
  minimumRepaymentTestId,
  totalLoanAmountTestId,
  loanTermTestId,
  redrawBalance,
  cashoutDescription,
  feesAndBufferCaption,
  productName,
  minRepaymentText,
  mortgageRegistrationFee,
  mortgageDischargeFee,
}: LoanDetailsRenderProps) {
  return (
    <>
      <Separator mt="s" mb="m" />
      <LoanDetails
        label={t('Content.YourUnloan.LoanCard.TotalLoanAmount.Header')}
        valueTestId={totalLoanAmountTestId}
        value={formatCurrency(totalLoanAmount)}
        loading={totalLoanAmountLoading}
        caption={t('Content.YourUnloan.LoanCard.TotalLoanAmount.Caption', {
          refinancedAmount: formatCurrency(refinancedAmount),
          additionalDetailsCaption: cashoutDescription
            ? `${cashoutDescription}; ${feesAndBufferCaption}`
            : feesAndBufferCaption,
          refinancePurpose: refinancePurpose
            ? t(
                `Content.YourUnloan.LoanCard.TotalLoanAmount.RefinancingReason.${refinancePurpose}`,
              )
            : '--',
        })}
        tooltip={
          <Tooltip
            content={
              <>
                {t('Content.YourUnloan.LoanCard.TotalLoanAmount.Tooltip', {
                  mortgageRegistrationFee: formatCurrency(
                    mortgageRegistrationFee,
                  ),
                  mortgageDischargeFee: formatCurrency(mortgageDischargeFee),
                })}
              </>
            }
            iconSize="s"
            placement="bottom"
            testID="test"
          />
        }
      />
      <LoanDetails
        mt="m"
        label={t('Content.YourUnloan.LoanCard.InterestRate.Header')}
        value={t('Content.YourUnloan.LoanCard.InterestRate.Value', {
          interestRate,
        })}
        caption={t('Content.YourUnloan.LoanCard.InterestRate.Caption', {
          productName,
          rateAdjustment: rateAdjustment ? Math.abs(rateAdjustment) : '--',
          maxLoanTermInYears: MAX_LOAN_TERM_IN_YEARS,
        })}
      />
      <LoanDetails
        mt="m"
        valueTestId={loanTermTestId}
        label={t('Content.YourUnloan.LoanCard.LoanTerm.Header')}
        value={formatLoanTerm(termInMonths, true)}
      />
      <LoanDetails
        mt="m"
        label={t('Content.YourUnloan.LoanCard.MinimumRepayments.Header')}
        valueTestId={minimumRepaymentTestId}
        value={minRepaymentText}
        caption={
          repaymentType
            ? t(`Content.YourUnloan.LoanCard.RepaymentType.${repaymentType}`)
            : '--'
        }
      />

      {redrawBalance ? (
        <LoanDetails
          mt="m"
          label={t('Content.YourUnloan.LoanCard.AvailableRedraw.Header')}
          valueTestId={minimumRepaymentTestId}
          value={formatCurrency(redrawBalance)}
          caption={t('Content.YourUnloan.LoanCard.AvailableRedraw.Caption')}
        />
      ) : null}
    </>
  );
}
