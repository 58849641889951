import { gql, useFragment } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Button, Card, CardContent, Image, Text, View } from '@unloan/ui';
import { Pressable } from 'dripsy';
import { ReactElement, useEffect, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  GTMAppInteractionEventDescription,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  Loan_Application_Status_Info_Stage_Enum,
  LoanApplicationForVcaDetailsFragment,
  useConditionallyApprovedContentWithVcaTestQueryQuery,
} from '../../generated/graphql';
import { ApprovedConfetti } from '../../LoanApplication/components/ApprovedConfetti';
import { GreenCheckAnimation } from '../../LoanApplication/components/GreenCheckAnimation';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { SvgIconName } from '../../ui/svgs/svgIconList';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatExpiryCountdown } from '../../utils/dateHelpers';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { openUrl } from '../../utils/openUrl';
import { Maybe } from '../../utils/typesHelpers';
import { InfoButton } from './InfoButton';

export const LoanApplicationForVcaDetails = gql`
  fragment LoanApplicationForVcaDetails on loan_application {
    id
    status_info {
      id
      loan_application_stage
    }
    vca_details {
      id
      vca_expired_at
      is_vca_expired
    }
    current_applicants: applicants(
      where: { is_current_logged_in_applicant: { _eq: true } }
      limit: 1
    ) {
      id
      is_current_logged_in_applicant
    }
  }
`;

type Props = Partial<{
  conditionallyApprovedAmount: number;
  showCaptionLink: boolean;
  isCcrExpired: Maybe<boolean>;
  onLinkPress: () => void;
}> & {
  onAppDetailsPress: () => void;
  onVerifyFinancialsPress: () => void;
  onFoundYourHomePress: () => void;
  loanApplicationId: string;
};

function getVcaInfoButtonprops({
  loanApplication,
  isCcrExpired,
}: Pick<Props, 'isCcrExpired'> & {
  loanApplication: DeepPartial<LoanApplicationForVcaDetailsFragment>;
}):
  | {
      header: string;
      appDetailsCaption: string | null;
      appDetailsIcon: SvgIconName;
      showVerifyFinancials: boolean;
      verifyFinancialsAdditionalInfo?: string | null;
      expiryCountdown?: ReactElement;
      greenCheck?: ReactElement;
      error?: never;
    }
  | {
      error: string;
    } {
  switch (loanApplication.status_info?.loan_application_stage) {
    case Loan_Application_Status_Info_Stage_Enum.PendingVerifiedConditionallyApproved:
      return {
        header: t('Content.ConditionalApproval.Header.ApprovedTitle'),
        appDetailsCaption: t(
          'Content.ConditionalApproval.Options.ApplicationDetails.VcaPendingInformation',
        ),
        appDetailsIcon: 'conditionalApprovalDocumentPendingIcon',
        showVerifyFinancials: false,
      };
    case Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved:
      return {
        header: t('Content.ConditionalApproval.Header.VcaApprovedTitle'),
        appDetailsCaption: null,
        appDetailsIcon: 'conditionalApprovalDocumentIcon',
        showVerifyFinancials: false,
        expiryCountdown: (
          <ExpiryCountdown
            expireAt={loanApplication.vca_details?.vca_expired_at ?? null}
          />
        ),
        greenCheck: <GreenCheckAnimation />,
      };
    case Loan_Application_Status_Info_Stage_Enum.ConditionallyApproved: {
      return {
        header: t('Content.ConditionalApproval.Header.ApprovedTitle'),
        appDetailsCaption: isCcrExpired
          ? t(
              'Content.ConditionalApproval.Options.ApplicationDetails.CcrExpiredInformation',
            )
          : null,
        appDetailsIcon: 'conditionalApprovalDocumentIcon',
        showVerifyFinancials: true,
        verifyFinancialsAdditionalInfo: loanApplication.vca_details
          ?.is_vca_expired
          ? t(
              'Content.ConditionalApproval.Options.VerifyYourFinancials.AssessmentExpiredInformation',
            )
          : null,
      };
    }
    default:
      return {
        error: `Unexpected loan_application_stage for VCA: ${loanApplication.status_info?.loan_application_stage}`,
      };
  }
}

function ExpiryCountdown({ expireAt }: { expireAt: string | null }) {
  const [formattedCountdown] = useState(() =>
    expireAt != null ? formatExpiryCountdown(new Date(expireAt)) : null,
  );

  // This component can implement interval to refresh the visible countdown.
  // Not implementing it now.

  return formattedCountdown != null ? (
    <View
      sx={(theme) => ({
        ...theme.borders.$sm,
        bg: theme.colors.$background.$success,
        px: '$8',
        py: '$4',
        alignSelf: 'center',
      })}
    >
      <Text size="body3" variant="success" weight="semiBold">
        {formattedCountdown}
      </Text>
    </View>
  ) : null;
}

export function ConditionallyApprovedContentWithVca({
  conditionallyApprovedAmount,
  showCaptionLink,
  isCcrExpired,
  onAppDetailsPress,
  onLinkPress,
  onFoundYourHomePress,
  onVerifyFinancialsPress,
  loanApplicationId,
}: Props) {
  const { data: loanApplication } =
    useFragment<LoanApplicationForVcaDetailsFragment>({
      fragment: LoanApplicationForVcaDetails,
      from: {
        __typename: 'loan_application',
        id: loanApplicationId,
      },
      optimistic: false,
    });

  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  const onPropertyInsightsPress = () => {
    sendAppInteractionEventToGTM({
      description: GTMAppInteractionEventDescription.ButtonClicked,
      additionalData: {
        application_interaction_event_key: buildApplicationInteractionEventKey(
          SectionInteractionKey.ConditionalApproval,
          Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
          FieldInteractionKey.PropertyInsights,
        ),
      },
    });
    openUrl(t('Link.CheckAProperty'));
  };

  const onCalculatorsPress = () => {
    sendAppInteractionEventToGTM({
      description: GTMAppInteractionEventDescription.ButtonClicked,
      additionalData: {
        application_interaction_event_key: buildApplicationInteractionEventKey(
          SectionInteractionKey.ConditionalApproval,
          Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
          FieldInteractionKey.Calculators,
        ),
      },
    });

    openUrl(t('Link.Calculators'));
  };

  const res = getVcaInfoButtonprops({ loanApplication, isCcrExpired });

  useEffect(() => {
    if (res.error != null) {
      captureException(res.error, {
        loanApplicationId,
      });
    }
  }, [loanApplicationId, res.error]);

  if (res.error != null) {
    return null;
  }

  const currentApplicant = loanApplication.current_applicants?.[0];
  const confetti =
    loanApplication.status_info?.loan_application_stage ===
      Loan_Application_Status_Info_Stage_Enum.VerifiedConditionallyApproved &&
    currentApplicant?.id != null ? (
      <ApprovedConfetti applicantId={currentApplicant.id} />
    ) : null;

  const {
    header,
    showVerifyFinancials,
    appDetailsIcon,
    appDetailsCaption,
    verifyFinancialsAdditionalInfo,
    expiryCountdown,
    greenCheck,
  } = res;

  return (
    <>
      <View
        sx={({ space }) => ({
          mb: space.$32,
          gap: space.$16,
          px: space.$16,
        })}
      >
        {greenCheck}
        <Text size="heading3" textAlign="center">
          {header}
        </Text>
        <Text
          size="display1"
          textAlign="center"
          testID={
            TestID.LoanApplicationConditionalApproval
              .ConditionallyApprovedAmount
          }
        >
          {formatCurrency(conditionallyApprovedAmount)}
        </Text>
        {expiryCountdown}
        <Text size="body3" textAlign="center">
          {t('Content.ConditionalApproval.Content.LoanAmountInformation')}
          {showCaptionLink ? (
            // TODO: Refactor to use Link component
            <Pressable onPress={onLinkPress}>
              <Text size="body3" variant="link" textAlign="center">
                {' '}
                {t('Content.ConditionalApproval.Content.ViewConditions')}
              </Text>
            </Pressable>
          ) : null}
        </Text>
      </View>
      <View sx={({ space }) => ({ gap: space.$32, px: space.$16 })}>
        <InfoButton
          icon={appDetailsIcon}
          title={t(
            'Content.ConditionalApproval.Options.ApplicationDetails.Title',
          )}
          caption={t(
            'Content.ConditionalApproval.Options.ApplicationDetails.Description',
          )}
          additionalInfo={appDetailsCaption}
          onPress={onAppDetailsPress}
          testID={
            TestID.LoanApplicationConditionalApproval.AppDetailsInfoButton
          }
        />

        <View sx={({ space }) => ({ gap: space.$8 })}>
          <Text size="heading4">
            {t('Content.ConditionalApproval.SectionTitle.Next')}
          </Text>

          {showVerifyFinancials ? (
            <InfoButton
              icon="conditionalApprovalVerifyIcon"
              title={t(
                'Content.ConditionalApproval.Options.VerifyYourFinancials.Title',
              )}
              caption={t(
                'Content.ConditionalApproval.Options.VerifyYourFinancials.Description',
              )}
              additionalInfo={verifyFinancialsAdditionalInfo}
              onPress={onVerifyFinancialsPress}
              testID={
                TestID.LoanApplicationConditionalApproval
                  .VerifyFinancialsInfoButton
              }
            />
          ) : null}

          <InfoButton
            icon="conditionalApprovalPurchaseIcon"
            title={t('Content.ConditionalApproval.Options.FoundYourHome.Title')}
            caption={t(
              'Content.ConditionalApproval.Options.FoundYourHome.Description',
            )}
            onPress={onFoundYourHomePress}
            testID={
              TestID.LoanApplicationConditionalApproval.FoundYourHomeInfoButton
            }
          />
        </View>
        <View sx={({ space }) => ({ gap: space.$8, pb: space.$32 })}>
          <Text size="heading4">
            {t('Content.ConditionalApproval.SectionTitle.Tools')}
          </Text>
          <Card>
            <CardContent sx={{ p: '$4', gap: '$16' }}>
              <Image
                source={require('../../../assets/property-insights-900×506.png')}
                alt="Property insights illustration"
                resizeMode="cover"
                sx={(theme) => ({
                  height: 337,
                  width: '100%',
                  backgroundColor: '#FBFBFB',
                  ...theme.borders.$sm,
                })}
              />
              <View
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '$16',
                  pb: '$12',
                  px: '$12',
                }}
              >
                <View sx={{ flex: 1, gap: '$4' }}>
                  <Text size="heading5">
                    {t(
                      'Content.ConditionalApproval.Options.PropertyInsights.Title',
                    )}
                  </Text>
                  <Text size="body3">
                    {t(
                      'Content.ConditionalApproval.Options.PropertyInsights.Description',
                    )}
                  </Text>
                </View>

                <Button
                  variant="stroke"
                  shape="capsule"
                  size="xs"
                  onPress={onPropertyInsightsPress}
                  testID={
                    TestID.LoanApplicationConditionalApproval
                      .SearchPropertyButton
                  }
                >
                  {t('Content.Common.ButtonLabel.Search')}
                </Button>
              </View>
            </CardContent>
          </Card>
          <InfoButton
            icon="conditionalApprovalCalculatorIcon"
            title={t('Content.ConditionalApproval.Options.Calculators.Title')}
            caption={t(
              'Content.ConditionalApproval.Options.Calculators.Description',
            )}
            onPress={onCalculatorsPress}
            testID={
              TestID.LoanApplicationConditionalApproval.CalculatorsInfoButton
            }
          />
        </View>
      </View>
      {confetti}
    </>
  );
}

export const ConditionallyApprovedContentWithVcaTestQuery = gql`
  query ConditionallyApprovedContentWithVcaTestQuery(
    $loanApplicationId: uuid!
  ) {
    loan_application_by_pk(id: $loanApplicationId) {
      ...LoanApplicationForVcaDetails
    }
  }
`;

type ConditionallyApprovedContentWithVcaQueryRendererProps = Props;
export function ConditionallyApprovedContentWithVcaQueryRenderer({
  loanApplicationId,
  ...otherProps
}: ConditionallyApprovedContentWithVcaQueryRendererProps) {
  useConditionallyApprovedContentWithVcaTestQueryQuery({
    variables: {
      loanApplicationId,
    },
    context: {
      sentryContext: {
        loanApplicationId,
      },
    },
  });

  return (
    <ConditionallyApprovedContentWithVca
      loanApplicationId={loanApplicationId}
      {...otherProps}
    />
  );
}
