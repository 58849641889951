import { useNavigation } from '@react-navigation/native';
import camelCase from 'lodash/camelCase';
import { StyleSheet } from 'react-native';

import {
  InstitutionAccountLoginStatus,
  InstitutionConnectionUpdateEligibilityStatus,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box, BoxProps } from '../../ui/atoms/Box';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import { NewListRow, NewListRowProps } from '../../ui/molecules/NewListRow';
import { isLast } from '../../utils/arrayHelpers';

type ConnectedBankAccount = {
  accountName: string;
  last4AccountNumber: string;
};

export type ConnectedBankRowProps = Pick<NewListRowProps, 'mb' | 'mt'> & {
  bankName: string;
  bankConnectionOwnerName: string;
  bankLogoUrl: string | null | undefined;
  bankAccounts: Array<ConnectedBankAccount>;
  providerAccountId: number;
  loginStatus: InstitutionAccountLoginStatus | null | undefined;
  updateEligibilityBasicAgg:
    | InstitutionConnectionUpdateEligibilityStatus
    | null
    | undefined;
  navigateToConsentDetails: () => void;
};

function makeTestId(...args: Array<string>) {
  return args.map((arg) => camelCase(arg.trim())).join('-');
}

function BankAccountRow({
  accountName,
  last4AccountNumber: rawLast4AccountNumber,
  ...boxProps
}: ConnectedBankAccount & BoxProps) {
  const last4AccountNumber = rawLast4AccountNumber.slice(-4);
  const testId = makeTestId(accountName, last4AccountNumber);
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      testID={testId}
      {...boxProps}
    >
      <StyledText variant="caption">{accountName}</StyledText>
      <StyledText
        variant="caption"
        style={styles.accountNumber}
      >{`•••• ${last4AccountNumber}`}</StyledText>
    </Box>
  );
}

const styles = StyleSheet.create({
  accountNumber: {
    fontVariant: ['tabular-nums'],
  },
});

function makeFooterRowElement(
  bankAccounts: ConnectedBankRowProps['bankAccounts'],
) {
  if (!bankAccounts.length) {
    return undefined;
  }

  return (
    <>
      <Separator mb="s" />
      <Box>
        {bankAccounts.map((bankAccount, index) => (
          <BankAccountRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...bankAccount}
            mb={isLast(bankAccounts, index) ? undefined : 'xs'}
          />
        ))}
      </Box>
    </>
  );
}

export function ConnectedBankRow({
  bankName,
  bankConnectionOwnerName,
  bankLogoUrl,
  bankAccounts,
  loginStatus,
  updateEligibilityBasicAgg,
  providerAccountId,
  navigateToConsentDetails,
  ...otherProps
}: ConnectedBankRowProps) {
  const testId = makeTestId(
    'ConnectedBankRow',
    bankName,
    bankConnectionOwnerName,
  );
  const navigation = useNavigation();
  const footer = makeFooterRowElement(bankAccounts);
  const needRetry =
    loginStatus === InstitutionAccountLoginStatus.Failed &&
    updateEligibilityBasicAgg ===
      InstitutionConnectionUpdateEligibilityStatus.AllowUpdate;
  const allowUserToNavigateToConsentDetails = !needRetry;

  return (
    <NewListRow
      {...otherProps}
      title={bankName}
      caption={bankConnectionOwnerName}
      onRowPress={
        allowUserToNavigateToConsentDetails
          ? navigateToConsentDetails
          : undefined
      }
      showArrow={allowUserToNavigateToConsentDetails}
      // fallback to undefined to avoid rendering image with source set to empty string
      imageUrl={bankLogoUrl || undefined}
      footer={footer}
      py="s"
      testID={testId}
      {...(needRetry
        ? {
            buttonLabel: t('Content.Common.ButtonLabel.Retry'),
            onButtonPress: () =>
              navigation.navigate(Screen.SELECT_INSTITUTION_MODAL, {
                screen: Screen.BANK_REFRESH,
                params: { providerAccountId },
              }),
            subcaption: t(
              'Content.VerifyIncomeOpenBanking.AccountDetailsError',
            ),
            subcaptionColor: 'error',
          }
        : {})}
      shadowed={false}
    />
  );
}
