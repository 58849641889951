import { Text, View } from 'dripsy';

import { ActionSheetType } from '../../navigation/types/screens';
import { formatCurrency } from '../../utils/currencyHelpers';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type BaseAutopayShortfallConfirmationActionSheetProps = {
  shortfallAmount: number;
};

export function BaseAutopayShortfallConfirmationActionSheet({
  shortfallAmount,
}: BaseAutopayShortfallConfirmationActionSheetProps) {
  return (
    <View>
      <Text variant="sBody" sx={{ textAlign: 'center' }}>
        {t('Content.AutopaySettings.ConfirmShortfallContent', {
          amount: formatCurrency(shortfallAmount),
        })}
      </Text>
      <Text variant="tiny" sx={{ textAlign: 'center', mt: '$32', mx: '$24' }}>
        {t('Content.AutopaySettings.ConfirmShortfallFooter')}
      </Text>
    </View>
  );
}

type AutopayShortfallConfirmationActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION>;

export function AutopayShortfallConfirmationActionSheet({
  navigation,
  route,
}: AutopayShortfallConfirmationActionSheetProps) {
  const { shortfallAmount } = route.params;

  const actionList = [
    {
      label: t('Content.AutopaySettings.ConfirmShortfallButton'),
      secondary: false,
      onPress: () => {
        navigation.navigate(ActionSheetType.AUTOPAY_CONFIRMATION, {
          cbaAccountId: route.params.cbaAccountId,
        });
      },
    },
  ];

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      title={t('Content.AutopaySettings.ConfirmShortfallHeading')}
      actionList={actionList}
    >
      <BaseAutopayShortfallConfirmationActionSheet
        shortfallAmount={shortfallAmount}
      />
    </ActionSheet>
  );
}
