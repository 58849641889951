import * as yup from 'yup';

import {
  Liability_Type_Enum,
  LoanApplicationTargetFragment,
  MergedLiabilityForSetupLoanScreenFragment,
  SetupLoanScreenQuery,
  Top_Up_Reason_Enum,
  TopUpReasonDetailsForSetupLoanFragment,
} from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import { liabilitiesMatchingSecurity } from '../../utils/matchingSecurityAddress';
import {
  generateMonthTermOptions,
  generateYearTermOptions,
} from '../../utils/pickerHelpers';
import { getSumOfSelectedRefinancedLiabilities } from './getSumOfSelectedRefinancedLiabilities';

// Min 1 year
export const MIN_LOAN_TERM_IN_YEARS = 1;
// Max 30 years
export const MAX_LOAN_TERM_IN_YEARS = 30;
// Default to 30 years
export const DEFAULT_LOAN_TERM_IN_YEARS = 30;

export enum SetupLoanFormFields {
  SelectedLiabilityIds = 'selectedLiabilityIds',
  TermInYears = 'termInYears',
  TopUpAmount = 'topUpAmount',
}

export const setupLoanValidationSchema = yup.object({
  [SetupLoanFormFields.SelectedLiabilityIds]: yup
    .array()
    .of(yup.string().required())
    .required(),
  [SetupLoanFormFields.TermInYears]: yup
    .number()
    .min(MIN_LOAN_TERM_IN_YEARS)
    .max(MAX_LOAN_TERM_IN_YEARS)
    .required(),
  [SetupLoanFormFields.TopUpAmount]: yup.number(),
});

type Schema = typeof setupLoanValidationSchema;
export type SetupLoanFormValues = yup.Asserts<Schema>;

type LoanApplicationForSetupLoan = NonNullable<
  SetupLoanScreenQuery['loanApplication']
>;
type SecuritiesForSetupLoan = NonNullable<
  LoanApplicationForSetupLoan['loanApplicationSecurities']
>;

export type LiabilityCheckerOptions = {
  mergedLiabilities: Array<MergedLiabilityForSetupLoanScreenFragment>;
  allSecurities: SecuritiesForSetupLoan;
};

function makeLiabilityChecker({
  mergedLiabilities,
  allSecurities,
}: LiabilityCheckerOptions) {
  const needToBeSelectedLiabilityIds = liabilitiesMatchingSecurity(
    mergedLiabilities,
    allSecurities,
  )
    .map((ml) => ml.current_liability_id)
    .filter(isNotNullOrUndefined);
  const needToBeSelectedLiabilityIdsSet = new Set(needToBeSelectedLiabilityIds);
  const checkAtLeast1HomeLoanSelected = ({
    selectedLiabilityIds,
  }: {
    selectedLiabilityIds: Array<string>;
  }) => {
    const mergedLiabilityByCurrentLiabilityId = new Map(
      mergedLiabilities.map((ml) => [ml.current_liability_id, ml]),
    );

    const selectedLiabilityTypes = new Set(
      selectedLiabilityIds
        .map(
          (selectedLiabilityId) =>
            mergedLiabilityByCurrentLiabilityId.get(selectedLiabilityId)
              ?.dynamite_liability_type as Liability_Type_Enum,
        )
        .filter(isNotNullOrUndefined),
    );
    return selectedLiabilityTypes.has(Liability_Type_Enum.HomeLoan);
  };

  return {
    doesLiabilityNeedToBeSelected: ({
      currentLiabilityId,
    }: {
      currentLiabilityId: string | null | undefined;
    }) =>
      currentLiabilityId
        ? needToBeSelectedLiabilityIdsSet.has(currentLiabilityId)
        : false,

    checkAtLeast1HomeLoanSelected,
    isSelectedLiabilityIdsValid: ({
      selectedLiabilityIds,
    }: {
      selectedLiabilityIds: Array<string>;
    }) => {
      const selectedIds = new Set(selectedLiabilityIds);
      if (
        needToBeSelectedLiabilityIds.some(
          (neededId) => !selectedIds.has(neededId),
        )
      ) {
        return false;
      }
      return true;
    },
  };
}

// Form v2

export enum SetupLoanFormFieldV2 {
  SelectedLoansToBeRefinanced = 'selectedLoansToBeRefinanced',
  HasTopUpAmount = 'hasTopUpAmount',

  OtherTopUpDescription = 'otherTopUpDescription',
  TermInMonths = 'termInMonths',
  IsAnyOfTopUpAmountFilled = 'isAnyOfTopUpAmountFieldsFilled',

  // Top Up Fields
  YourHomeTopUpAmount = 'yourHomeTopUpAmount',
  InvestmentPropertyTopUpAmount = 'investmentPropertyTopUpAmount',
  FinancialInvestmentTopUpAmount = 'financialInvestmentTopUpAmount',
  PersonalUseTopUpAmount = 'personalUseTopUpAmount',
  OtherTopUpAmount = 'otherTopUpAmount',
}

export enum SetupLoanYesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

// Min 1 year
export const MIN_LOAN_TERM_IN_MONTHS = 12;
// Max 30 years
export const MAX_LOAN_TERM_IN_MONTHS = 360;
// Default to 30 years
export const DEFAULT_LOAN_TERM_IN_MONTHS = MAX_LOAN_TERM_IN_MONTHS;

export const YEAR_TERM_OPTIONS = generateYearTermOptions({
  startFromZero: false,
  maxYear: MAX_LOAN_TERM_IN_MONTHS / 12,
});

export const MONTH_TERM_OPTIONS = generateMonthTermOptions({
  startFromZero: true,
  maxMonth: 11,
});

export const SetupLoanTopUpFieldNameMap = {
  [Top_Up_Reason_Enum.YourHome]: SetupLoanFormFieldV2.YourHomeTopUpAmount,
  [Top_Up_Reason_Enum.FinancialInvestment]:
    SetupLoanFormFieldV2.FinancialInvestmentTopUpAmount,
  [Top_Up_Reason_Enum.InvestmentProperty]:
    SetupLoanFormFieldV2.InvestmentPropertyTopUpAmount,
  [Top_Up_Reason_Enum.PersonalUse]: SetupLoanFormFieldV2.PersonalUseTopUpAmount,
  [Top_Up_Reason_Enum.Other]: SetupLoanFormFieldV2.OtherTopUpAmount,
} as const;

export function makeSetupLoanV2ValidationSchema(
  options: LiabilityCheckerOptions,
) {
  const { isSelectedLiabilityIdsValid } = makeLiabilityChecker(options);
  return yup.object({
    [SetupLoanFormFieldV2.SelectedLoansToBeRefinanced]: yup
      .array()
      .of(yup.string().required())
      .test({
        name: 'homeLoanRequired',
        message: t('Content.YourUnloan.LoanCard.HomeLoanRequiredError'),
        test: (selectedLiabilityIds) =>
          isSelectedLiabilityIdsValid({
            selectedLiabilityIds: (selectedLiabilityIds as Array<string>) ?? [],
          }),
      })
      .required(),
    [SetupLoanFormFieldV2.TermInMonths]: yup
      .number()
      .nullable()
      .min(MIN_LOAN_TERM_IN_MONTHS)
      .max(MAX_LOAN_TERM_IN_MONTHS)
      .required(),
    [SetupLoanFormFieldV2.HasTopUpAmount]: yup.string().required(),
    [SetupLoanFormFieldV2.YourHomeTopUpAmount]: yup.number().nullable(),
    [SetupLoanFormFieldV2.InvestmentPropertyTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormFieldV2.FinancialInvestmentTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormFieldV2.PersonalUseTopUpAmount]: yup.number().nullable(),
    [SetupLoanFormFieldV2.OtherTopUpAmount]: yup.number().nullable(),
    [SetupLoanFormFieldV2.OtherTopUpDescription]: yup
      .string()
      .nullable()
      .when(SetupLoanFormFieldV2.OtherTopUpAmount, (plan, schema) =>
        plan > 0
          ? schema.required(
              t('Content.SetupLoanV2.CashOutSection.OtherDescriptionRequired'),
            )
          : schema,
      ),
    // Ghost field to check whether user has filled any of the top up amount fields when they declare they want to have top up
    [SetupLoanFormFieldV2.IsAnyOfTopUpAmountFilled]: yup
      .bool()
      .when(
        [
          SetupLoanFormFieldV2.HasTopUpAmount,
          ...Object.values(SetupLoanTopUpFieldNameMap),
        ],
        {
          is: (
            hasTopUpamount: SetupLoanYesNoEnum,
            ...reasonAmounts: Array<number | null>
          ) =>
            hasTopUpamount === SetupLoanYesNoEnum.Yes
              ? reasonAmounts.every((amount) => !amount)
              : false,
          then: yup
            .bool()
            .required(t('Content.AdditionalCashout.EnterAtLeastOneCashout')),
          otherwise: yup.bool(),
        },
      ),
  });
}

// Why we need separate type for initial values only?
export type SetupLoanFormV2InitialValues = Partial<
  yup.InferType<ReturnType<typeof makeSetupLoanV2ValidationSchema>>
>;

export const DefaultSetupLoanFormV2InitialValues: SetupLoanFormV2InitialValues =
  {
    [SetupLoanFormFieldV2.SelectedLoansToBeRefinanced]: [],
    [SetupLoanFormFieldV2.TermInMonths]: DEFAULT_LOAN_TERM_IN_MONTHS,
    [SetupLoanFormFieldV2.HasTopUpAmount]: undefined,
    [SetupLoanFormFieldV2.YourHomeTopUpAmount]: 0,
    [SetupLoanFormFieldV2.InvestmentPropertyTopUpAmount]: 0,
    [SetupLoanFormFieldV2.FinancialInvestmentTopUpAmount]: 0,
    [SetupLoanFormFieldV2.PersonalUseTopUpAmount]: 0,
    [SetupLoanFormFieldV2.OtherTopUpAmount]: 0,
    [SetupLoanFormFieldV2.OtherTopUpDescription]: undefined,
    [SetupLoanFormFieldV2.IsAnyOfTopUpAmountFilled]: undefined,
  };

// Form with Loan Amount field
export enum SetupLoanFormWithLoanAmountField {
  DesiredLoanAmount = 'desiredLoanAmount',
  SelectedLoansToBeRefinanced = 'selectedLoansToBeRefinanced',
  SelectedMergedLiabilitiesToBeRefinanced = 'selectedMergedLiabilitiesToBeRefinanced',
  HasTopUpAmount = 'hasTopUpAmount',

  OtherTopUpDescription = 'otherTopUpDescription',
  TermInMonths = 'termInMonths',
  IsAnyOfTopUpAmountFilled = 'isAnyOfTopUpAmountFieldsFilled',

  // Top Up Fields
  YourHomeTopUpAmount = 'yourHomeTopUpAmount',
  InvestmentPropertyTopUpAmount = 'investmentPropertyTopUpAmount',
  FinancialInvestmentTopUpAmount = 'financialInvestmentTopUpAmount',
  PersonalUseTopUpAmount = 'personalUseTopUpAmount',
  OtherTopUpAmount = 'otherTopUpAmount',
}

export enum SetupLoanTopUpFormField {
  DesiredLoanAmount = 'desiredLoanAmount',
  SelectedLoansToBeRefinanced = 'selectedLoansToBeRefinanced',
  HasTopUpAmount = 'hasTopUpAmount',

  OtherTopUpDescription = 'otherTopUpDescription',
  TermYearsInMonths = 'termYearsInMonths',
  TermMonths = 'termMonths',
  IsAnyOfTopUpAmountFilled = 'isAnyOfTopUpAmountFieldsFilled',

  // Top Up Fields
  YourHomeTopUpAmount = 'yourHomeTopUpAmount',
  InvestmentPropertyTopUpAmount = 'investmentPropertyTopUpAmount',
  FinancialInvestmentTopUpAmount = 'financialInvestmentTopUpAmount',
  PersonalUseTopUpAmount = 'personalUseTopUpAmount',
  OtherTopUpAmount = 'otherTopUpAmount',
}

export function makeSetupLoanWithLoanAmountValidationSchema(
  options: LiabilityCheckerOptions,
) {
  const { isSelectedLiabilityIdsValid } = makeLiabilityChecker(options);
  const loanAmountError = t(
    'Content.SetupLoanWithLoanAmount.RefinanceSection.LoanAmountErrorV2',
  );
  return yup.object({
    [SetupLoanFormWithLoanAmountField.DesiredLoanAmount]: yup
      .number()
      .test({
        name: 'desiredLoanAmountRequired',
        message: loanAmountError,
        test: (desiredLoanAmount, testContext) => {
          if (desiredLoanAmount === undefined) return false;
          const selectedLiabilityIds =
            testContext.parent[
              SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced
            ];
          const refinancedLiabilitySum = getSumOfSelectedRefinancedLiabilities({
            mergedLiabilities: options.mergedLiabilities,
            selectedCurrentLiabilityForRefinanceIds: new Set(
              selectedLiabilityIds,
            ),
          });
          return desiredLoanAmount <= refinancedLiabilitySum;
        },
      })
      .required(),
    [SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced]: yup
      .array()
      .of(yup.string().required())
      .test({
        name: 'homeLoanRequired',
        message: t('Content.YourUnloan.LoanCard.HomeLoanRequiredError'),
        test: (selectedLiabilityIds) =>
          isSelectedLiabilityIdsValid({
            selectedLiabilityIds: (selectedLiabilityIds as Array<string>) ?? [],
          }),
      })
      .required(),
    [SetupLoanFormWithLoanAmountField.SelectedMergedLiabilitiesToBeRefinanced]:
      yup.array().of(yup.string().required()).required(),
    [SetupLoanFormWithLoanAmountField.TermInMonths]: yup
      .number()
      .nullable()
      .min(MIN_LOAN_TERM_IN_MONTHS)
      .max(MAX_LOAN_TERM_IN_MONTHS)
      .required(),
    [SetupLoanFormWithLoanAmountField.HasTopUpAmount]: yup.string().required(),
    [SetupLoanFormWithLoanAmountField.YourHomeTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormWithLoanAmountField.InvestmentPropertyTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormWithLoanAmountField.FinancialInvestmentTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormWithLoanAmountField.PersonalUseTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormWithLoanAmountField.OtherTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanFormWithLoanAmountField.OtherTopUpDescription]: yup
      .string()
      .nullable()
      .when(SetupLoanFormWithLoanAmountField.OtherTopUpAmount, (plan, schema) =>
        plan > 0
          ? schema.required(
              t('Content.SetupLoanV2.CashOutSection.OtherDescriptionRequired'),
            )
          : schema,
      ),
    // Ghost field to check whether user has filled any of the top up amount fields when they declare they want to have top up
    [SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled]: yup
      .bool()
      .when(
        [
          SetupLoanFormWithLoanAmountField.HasTopUpAmount,
          ...Object.values(SetupLoanTopUpFieldNameMap),
        ],
        {
          is: (
            hasTopUpamount: SetupLoanYesNoEnum,
            ...reasonAmounts: Array<number | null>
          ) =>
            hasTopUpamount === SetupLoanYesNoEnum.Yes
              ? reasonAmounts.every((amount) => !amount)
              : false,
          then: yup
            .bool()
            .required(t('Content.AdditionalCashout.EnterAtLeastOneCashout')),
          otherwise: yup.bool(),
        },
      ),
  });
}

// Why we need separate type for initial values only?
export type SetupLoanFormWithLoanAmountInitialValues = Partial<
  yup.InferType<ReturnType<typeof makeSetupLoanWithLoanAmountValidationSchema>>
>;

export const DefaultSetupLoanFormWithLoanAmountInitialValues: SetupLoanFormWithLoanAmountInitialValues =
  {
    [SetupLoanFormWithLoanAmountField.DesiredLoanAmount]: 0,
    [SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced]: [],
    [SetupLoanFormWithLoanAmountField.SelectedMergedLiabilitiesToBeRefinanced]:
      [],
    [SetupLoanFormWithLoanAmountField.TermInMonths]:
      DEFAULT_LOAN_TERM_IN_MONTHS,
    [SetupLoanFormWithLoanAmountField.HasTopUpAmount]: undefined,
    [SetupLoanFormWithLoanAmountField.YourHomeTopUpAmount]: 0,
    [SetupLoanFormWithLoanAmountField.InvestmentPropertyTopUpAmount]: 0,
    [SetupLoanFormWithLoanAmountField.FinancialInvestmentTopUpAmount]: 0,
    [SetupLoanFormWithLoanAmountField.PersonalUseTopUpAmount]: 0,
    [SetupLoanFormWithLoanAmountField.OtherTopUpAmount]: 0,
    [SetupLoanFormWithLoanAmountField.OtherTopUpDescription]: undefined,
    [SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled]: undefined,
  };

export function makeSetupLoanTopUpValidationSchema() {
  return yup.object({
    // This should be provided by the current Unloan liability
    [SetupLoanTopUpFormField.DesiredLoanAmount]: yup.number().required(),
    [SetupLoanTopUpFormField.SelectedLoansToBeRefinanced]: yup
      .array()
      .of(yup.string().required())
      .required(),
    [SetupLoanTopUpFormField.TermYearsInMonths]: yup
      .number()
      .nullable()
      .min(0)
      .max(MAX_LOAN_TERM_IN_MONTHS)
      .required(),
    [SetupLoanTopUpFormField.TermMonths]: yup
      .number()
      .nullable()
      .min(0)
      .max(MIN_LOAN_TERM_IN_MONTHS - 1)
      .required(),
    [SetupLoanTopUpFormField.HasTopUpAmount]: yup.string().required(),
    [SetupLoanTopUpFormField.YourHomeTopUpAmount]: yup.number().nullable(),
    [SetupLoanTopUpFormField.InvestmentPropertyTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanTopUpFormField.FinancialInvestmentTopUpAmount]: yup
      .number()
      .nullable(),
    [SetupLoanTopUpFormField.PersonalUseTopUpAmount]: yup.number().nullable(),
    [SetupLoanTopUpFormField.OtherTopUpAmount]: yup.number().nullable(),
    [SetupLoanTopUpFormField.OtherTopUpDescription]: yup
      .string()
      .nullable()
      .when(SetupLoanTopUpFormField.OtherTopUpAmount, (plan, schema) =>
        plan > 0
          ? schema.required(
              t('Content.SetupLoanV2.CashOutSection.OtherDescriptionRequired'),
            )
          : schema,
      ),
  });
}

export type SetupLoanTopUpFormInitialValues = Partial<
  yup.InferType<ReturnType<typeof makeSetupLoanTopUpValidationSchema>>
>;

export function getInitialSetupLoanFormWithLoanAmountValues({
  targetLoan,
  mergedLiabilities,
  financialDeclarationTopUpDetails,
  allSecurities,
}: {
  targetLoan: LoanApplicationTargetFragment | undefined | null;
  mergedLiabilities: Array<MergedLiabilityForSetupLoanScreenFragment>;
  financialDeclarationTopUpDetails: Array<TopUpReasonDetailsForSetupLoanFragment>;
  allSecurities: SecuritiesForSetupLoan | undefined;
}): SetupLoanFormWithLoanAmountInitialValues {
  const topUpReasonMap = new Map<
    string,
    { value: number; description?: string | null }
  >();

  financialDeclarationTopUpDetails.forEach((i) => {
    topUpReasonMap.set(i.top_up_reason, {
      value: i.top_up_amount || 0,
      description: i.other_top_up_reason_description,
    });
  });
  const sumOfTopUpReasonValue = [...topUpReasonMap.values()].reduce(
    (prev, current) => prev + current.value,
    0,
  );

  if (targetLoan) {
    const selectedRefiLiabilityIds = mergedLiabilities
      .filter((ml) => ml.dynamite_for_refinancing)
      .map((ml) => ml.current_liability_id)
      .filter(isNotNullOrUndefined);

    const selectedRefiMergedLiabilityIds = mergedLiabilities
      .filter((ml) => ml.dynamite_for_refinancing)
      .map((ml) => ml.id);

    // when desired_loan_amount is null/undefined means this is a app in progress before we introduce this field,
    // we will use the sum of selected refinanced liabilities as the initial value for desired loan amount.
    const desiredLoanAmount =
      targetLoan.desired_loan_amount ??
      getSumOfSelectedRefinancedLiabilities({
        mergedLiabilities,
        selectedCurrentLiabilityForRefinanceIds: new Set(
          selectedRefiLiabilityIds,
        ),
      });
    return {
      [SetupLoanFormWithLoanAmountField.DesiredLoanAmount]: desiredLoanAmount,
      [SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced]:
        selectedRefiLiabilityIds,
      [SetupLoanFormWithLoanAmountField.SelectedMergedLiabilitiesToBeRefinanced]:
        selectedRefiMergedLiabilityIds,
      [SetupLoanFormWithLoanAmountField.TermInMonths]: targetLoan.term_months,
      [SetupLoanFormWithLoanAmountField.HasTopUpAmount]:
        sumOfTopUpReasonValue > 0
          ? SetupLoanYesNoEnum.Yes
          : SetupLoanYesNoEnum.No,

      [SetupLoanFormWithLoanAmountField.YourHomeTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.YourHome)?.value,
      [SetupLoanFormWithLoanAmountField.InvestmentPropertyTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.InvestmentProperty)?.value,
      [SetupLoanFormWithLoanAmountField.FinancialInvestmentTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.FinancialInvestment)?.value,
      [SetupLoanFormWithLoanAmountField.PersonalUseTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.PersonalUse)?.value,
      [SetupLoanFormWithLoanAmountField.OtherTopUpAmount]: topUpReasonMap.get(
        Top_Up_Reason_Enum.Other,
      )?.value,
      [SetupLoanFormWithLoanAmountField.OtherTopUpDescription]:
        topUpReasonMap.get(Top_Up_Reason_Enum.Other)?.description,
      [SetupLoanFormWithLoanAmountField.IsAnyOfTopUpAmountFilled]: undefined,
    };
  }

  const { doesLiabilityNeedToBeSelected } = makeLiabilityChecker({
    mergedLiabilities,
    allSecurities: allSecurities || [],
  });

  const preselectedLiabilityIds = mergedLiabilities
    .filter((ml) =>
      doesLiabilityNeedToBeSelected({
        currentLiabilityId: ml.current_liability_id,
      }),
    )
    .map((ml) => ml.current_liability_id)
    .filter(isNotNullOrUndefined);

  const preselectedMergedLiabilityIds = mergedLiabilities
    .filter((ml) =>
      doesLiabilityNeedToBeSelected({
        currentLiabilityId: ml.current_liability_id,
      }),
    )
    .map((ml) => ml.id);

  const desiredLoanAmount = getSumOfSelectedRefinancedLiabilities({
    mergedLiabilities,
    selectedCurrentLiabilityForRefinanceIds: new Set(preselectedLiabilityIds),
  });

  return {
    ...DefaultSetupLoanFormWithLoanAmountInitialValues,
    [SetupLoanFormWithLoanAmountField.DesiredLoanAmount]: desiredLoanAmount,
    [SetupLoanFormWithLoanAmountField.SelectedLoansToBeRefinanced]:
      preselectedLiabilityIds,
    [SetupLoanFormWithLoanAmountField.SelectedMergedLiabilitiesToBeRefinanced]:
      preselectedMergedLiabilityIds,
  };
}

export const DefaultSetupLoanTopUpFormInitialValues: SetupLoanTopUpFormInitialValues =
  {
    [SetupLoanTopUpFormField.DesiredLoanAmount]: 0,
    [SetupLoanTopUpFormField.SelectedLoansToBeRefinanced]: [],
    [SetupLoanTopUpFormField.TermYearsInMonths]: DEFAULT_LOAN_TERM_IN_MONTHS,
    [SetupLoanTopUpFormField.TermMonths]: 0,
    [SetupLoanTopUpFormField.HasTopUpAmount]: SetupLoanYesNoEnum.Yes,
    [SetupLoanTopUpFormField.YourHomeTopUpAmount]: 0,
    [SetupLoanTopUpFormField.InvestmentPropertyTopUpAmount]: 0,
    [SetupLoanTopUpFormField.FinancialInvestmentTopUpAmount]: 0,
    [SetupLoanTopUpFormField.PersonalUseTopUpAmount]: 0,
    [SetupLoanTopUpFormField.OtherTopUpAmount]: 0,
    [SetupLoanTopUpFormField.OtherTopUpDescription]: undefined,
  };

export function getInitialSetupLoanTopUpValues({
  targetLoan,
  topUpLiability,
  liabilitiesAllowedToBeConsolidated,
  financialDeclarationTopUpDetails,
  initialLoanTermMonths,
}: {
  targetLoan?: LoanApplicationTargetFragment | null;
  topUpLiability: MergedLiabilityForSetupLoanScreenFragment;
  liabilitiesAllowedToBeConsolidated: Array<MergedLiabilityForSetupLoanScreenFragment>;
  financialDeclarationTopUpDetails: Array<TopUpReasonDetailsForSetupLoanFragment>;
  initialLoanTermMonths?: number | null;
}): SetupLoanTopUpFormInitialValues {
  const topUpReasonMap = new Map<
    string,
    { value: number; description?: string | null }
  >();

  financialDeclarationTopUpDetails.forEach((i) => {
    topUpReasonMap.set(i.top_up_reason, {
      value: i.top_up_amount || 0,
      description: i.other_top_up_reason_description,
    });
  });

  const termMonths = targetLoan?.term_months ?? initialLoanTermMonths ?? 360;

  if (targetLoan) {
    const selectedRefiLiabilityIds = liabilitiesAllowedToBeConsolidated
      .filter((ml) => ml.dynamite_for_refinancing)
      .map((ml) => ml.current_liability_id)
      .filter(isNotNullOrUndefined);

    // when desired_loan_amount is null/undefined means this is a app in progress before we introduce this field,
    // we will use the sum of selected refinanced liabilities as the initial value for desired loan amount.
    const desiredLoanAmount =
      targetLoan.desired_loan_amount ??
      (topUpLiability.dynamite_limit ?? 0) +
        getSumOfSelectedRefinancedLiabilities({
          mergedLiabilities: liabilitiesAllowedToBeConsolidated,
          selectedCurrentLiabilityForRefinanceIds: new Set(
            selectedRefiLiabilityIds,
          ),
        });
    const initialValues = {
      [SetupLoanTopUpFormField.DesiredLoanAmount]: desiredLoanAmount,
      [SetupLoanTopUpFormField.SelectedLoansToBeRefinanced]:
        selectedRefiLiabilityIds,
      [SetupLoanTopUpFormField.TermYearsInMonths]:
        Math.floor(termMonths / 12) * 12,
      [SetupLoanTopUpFormField.TermMonths]: termMonths % 12,
      [SetupLoanTopUpFormField.HasTopUpAmount]: SetupLoanYesNoEnum.Yes,

      [SetupLoanFormWithLoanAmountField.YourHomeTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.YourHome)?.value,
      [SetupLoanFormWithLoanAmountField.InvestmentPropertyTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.InvestmentProperty)?.value,
      [SetupLoanFormWithLoanAmountField.FinancialInvestmentTopUpAmount]:
        topUpReasonMap.get(Top_Up_Reason_Enum.FinancialInvestment)?.value,
      [SetupLoanTopUpFormField.PersonalUseTopUpAmount]: topUpReasonMap.get(
        Top_Up_Reason_Enum.PersonalUse,
      )?.value,
      [SetupLoanTopUpFormField.OtherTopUpAmount]: topUpReasonMap.get(
        Top_Up_Reason_Enum.Other,
      )?.value,
      [SetupLoanTopUpFormField.OtherTopUpDescription]: topUpReasonMap.get(
        Top_Up_Reason_Enum.Other,
      )?.description,
      [SetupLoanTopUpFormField.IsAnyOfTopUpAmountFilled]: undefined,
    };

    return initialValues;
  }

  const { doesLiabilityNeedToBeSelected } = makeLiabilityChecker({
    mergedLiabilities: liabilitiesAllowedToBeConsolidated,
    allSecurities: [],
  });

  const preselectedLiabilityIds = liabilitiesAllowedToBeConsolidated
    .filter((ml) =>
      doesLiabilityNeedToBeSelected({
        currentLiabilityId: ml.current_liability_id,
      }),
    )
    .map((ml) => ml.current_liability_id)
    .filter(isNotNullOrUndefined);

  const desiredLoanAmount =
    (topUpLiability.dynamite_limit ?? 0) +
    getSumOfSelectedRefinancedLiabilities({
      mergedLiabilities: liabilitiesAllowedToBeConsolidated,
      selectedCurrentLiabilityForRefinanceIds: new Set(preselectedLiabilityIds),
    });

  return {
    ...DefaultSetupLoanTopUpFormInitialValues,
    [SetupLoanTopUpFormField.TermYearsInMonths]:
      Math.floor(termMonths / 12) * 12,
    [SetupLoanTopUpFormField.TermMonths]: termMonths % 12,
    [SetupLoanTopUpFormField.DesiredLoanAmount]: desiredLoanAmount,
    [SetupLoanTopUpFormField.SelectedLoansToBeRefinanced]:
      preselectedLiabilityIds,
  };
}
