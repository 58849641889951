import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { resizeIconWidth } from '../utils/resizeIconWidth';
import { SvgIconProps } from './types';

export const SecurityIcon: React.FC<SvgIconProps> = ({
  color,
  size,
  ...props
}) => (
  <Svg
    width={resizeIconWidth(20, 24, size)}
    height={size}
    viewBox="0 0 20 24"
    fill="none"
    {...props}
  >
    <Path
      d="M9.6227 5.97026e-05C9.41554 -0.0016108 9.21006 0.0318011 9.0146 0.0969545L1.31644 2.66301C0.527912 2.92529 0 3.66036 0 4.48898V15.1742C0 17.1087 0.960599 18.9046 2.57106 19.9772L7.75496 23.4337C8.32129 23.8112 8.97283 24 9.6227 24C10.2726 24 10.9241 23.8112 11.4904 23.4337L16.6743 19.9772C18.2848 18.9046 19.2454 17.1087 19.2454 15.1742V4.48898C19.2454 3.66036 18.7175 2.92529 17.929 2.66301L10.2308 0.0969545C10.0353 0.0318011 9.82985 5.97026e-05 9.6227 5.97026e-05ZM9.6227 1.92627C9.62771 1.92627 9.63272 1.92627 9.63773 1.92794L17.2874 4.47729C17.3075 4.48397 17.3209 4.50402 17.3209 4.52406V15.1742C17.3209 16.4606 16.6777 17.6634 15.6068 18.3767L10.4229 21.8316C9.93677 22.1557 9.30862 22.1557 8.82248 21.8316L3.63858 18.3767C2.56772 17.6617 1.92454 16.4606 1.92454 15.1725V4.52406C1.92454 4.50402 1.9379 4.48397 1.95795 4.47729L9.60766 1.92794C9.61267 1.92627 9.61768 1.92627 9.6227 1.92627ZM9.6227 8.31467C8.35136 8.31467 7.31726 9.34878 7.31726 10.6201C7.31726 11.5456 7.87023 12.3425 8.66043 12.7084V14.0766C8.66043 14.6078 9.09144 15.0389 9.6227 15.0389C10.1539 15.0389 10.585 14.6078 10.585 14.0766V12.7084C11.3752 12.3425 11.9281 11.5473 11.9281 10.6201C11.9281 9.34878 10.894 8.31467 9.6227 8.31467Z"
      fill={color}
    />
  </Svg>
);
