import {
  Account_Permission,
  Account_Permission_Type_Enum,
  Account_Permission_Type_Input_Enum,
} from '../generated/graphql';

export enum ApprovalType {
  AllToApprove = 'all-to-approve',
  OneToApprove = 'one-to-approve',
}

export { Account_Permission_Type_Input_Enum as AccountPermissionType };

export type PermissionsToUpdate = Account_Permission[];

export type ChangeRequestResponse = {
  id: string;
  name: string;
  hasApproved: boolean;
};

export enum ApprovalResponseType {
  Approve = 'Approve',
  Decline = 'Decline',
  Cancel = 'Cancel',
}

export type AccountPermission = {
  type: Account_Permission_Type_Enum;
  require_approvals: boolean;
};
