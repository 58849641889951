import { A, styled, Text, View } from 'dripsy';
import { Formik } from 'formik';
import pickBy from 'lodash/pickBy';
import { useMemo } from 'react';
import * as React from 'react';
import type { AnySchema } from 'yup';
import { AnyObject } from 'yup/lib/types';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKeyForLoanDetailsForm } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormCheckboxInputV2,
  FormCurrencyInputV2,
  FormDateInputV2,
  FormInstitutionInputV2,
  FormPercentInputV2,
  FormPropertyInputV2,
  FormRadioInputV2,
  FormSelectV2,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import {
  type PickerOption,
  type PickerOptions,
  yupAddress,
  type YupAddressResult,
} from '../../components/form/types';
import { MaxValue, MinValue } from '../../constants/fieldRules';
import {
  Liability_Type_Enum,
  Loan_Interest_Rate_Type_Enum,
  State_Enum,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { LiabilityTypeSupportedByApp } from '../../supportedEnumTypes';
import { Button, ButtonColorVariantProps } from '../../ui/atoms/Button';
import { Link } from '../../ui/atoms/Link';
import { formatDateForDateInputDisplay } from '../../ui/v2/DateInput';
import { FormErrorText, FormikFormError } from '../../ui/v2/FormError';
import {
  getManualAddressYupSchema,
  getManualInputFieldNames as getManualAddressInputFieldNames,
  ManualPropertyInputFields,
} from '../../ui/v2/ManualPropertyInputFields';
import { SuggestionDataLoader } from '../../ui/v2/PropertyInput';
import { isLast } from '../../utils/arrayHelpers';
import { formatCurrency } from '../../utils/currencyHelpers';
import { getMinimumDate, safelyFormatDate } from '../../utils/dateHelpers';
import {
  generateMonthTermOptions,
  generateYearTermOptions,
  MAX_YEAR,
} from '../../utils/pickerHelpers';
import { makeTestId } from '../../utils/stringHelpers';
import { yup, yupNumber } from '../../utils/yup';

export type LoanDetailsFormProps = Expand<
  SuggestionDataLoader & {
    screen: Screen;
    liabilityType: Liability_Type_Enum;
    liabilityManuallyAdded: boolean;

    creditCheckDate?: string;
    ccrBalance?: number | null;

    isSubmitting?: boolean;
    onSubmit: (values: LoanDetailsFormValues) => void;
    initialValues?: LoanDetailsFormValues;

    submitButtonLabel?: string;

    accountOwnerOptions: PickerOptions<string>;
    institutionsData?: React.ComponentProps<
      typeof FormInstitutionInputV2
    >['institutionsData'];
    manualAddressStreetTypeOptions?: React.ComponentProps<
      typeof ManualPropertyInputFields
    >['streetTypeOptions'];
    manualAddressStateOptions?: React.ComponentProps<
      typeof ManualPropertyInputFields
    >['stateOptions'];

    /**
     * User can only delete manually added liabilities
     */
    showDeleteButton?: boolean;
    onDelete?: () => void;

    /**
     * User can only flag detected liabilities
     */
    showFlagAsIncorrectButton?: boolean;
    onFlagAsIncorrect?: () => void;
    allowEditAvailableBalanceField: boolean;
  }
>;

export enum LoanDetailsFormField {
  AccountNumber = 'accountNumber',
  AccountOwners = 'accountOwners',
  Balance = 'balance',
  AvailableBalance = 'availableBalance',
  FixedRateExpiryDate = 'fixedRateExpiryDate',
  Institution = 'institution',
  InterestRate = 'interestRate',
  Limit = 'limit',
  LoanRateType = 'loanRateType',
  MonthlyRepaymentAmount = 'monthlyRepaymentAmount',
  PropertyAddress = 'propertyAddress',
  TermInMonthsMonthPart = 'termInMonthsMonthPart',
  TermInMonthsYearPart = 'termInMonthsYearPart',
  // FlaggedIncorrect = 'flaggedIncorrect',
}

const manualAddressFieldPrefix = 'manualAddressInput';
export const LoanDetailsManualAddressFormField =
  getManualAddressInputFieldNames(manualAddressFieldPrefix);

export type LoanDetailsFormValues = {
  [LoanDetailsFormField.Institution]: {
    /**
     * `id` field only exist for institution that already exist in DB.
     */
    id?: string;
    name: string;
  } | null;
  [LoanDetailsFormField.AccountNumber]: string;
  [LoanDetailsFormField.AccountOwners]: Array<string>;
  [LoanDetailsFormField.Balance]: number | null;
  [LoanDetailsFormField.AvailableBalance]: number | null;
  [LoanDetailsFormField.LoanRateType]: Loan_Interest_Rate_Type_Enum | null;
  [LoanDetailsFormField.FixedRateExpiryDate]: Date | null;
  [LoanDetailsFormField.InterestRate]: string | null;
  [LoanDetailsFormField.Limit]: number | null;
  [LoanDetailsFormField.MonthlyRepaymentAmount]: number | null;
  [LoanDetailsFormField.PropertyAddress]: YupAddressResult | null;
  [LoanDetailsFormField.TermInMonthsMonthPart]: number | null;
  [LoanDetailsFormField.TermInMonthsYearPart]: number | null;
  // Manual address fields:
  [LoanDetailsManualAddressFormField.isManualInput]: boolean | null;
  [LoanDetailsManualAddressFormField.postcode]: string | null;
  [LoanDetailsManualAddressFormField.state]: State_Enum | null;
  [LoanDetailsManualAddressFormField.streetName]: string | null;
  [LoanDetailsManualAddressFormField.streetNo]: string | null;
  [LoanDetailsManualAddressFormField.streetType]: PickerOption<string> | null;
  [LoanDetailsManualAddressFormField.suburb]: string | null;
  [LoanDetailsManualAddressFormField.unitNo]: string | null;
};

const FORM_INITIAL_VALUES: LoanDetailsFormValues = {
  [LoanDetailsFormField.AccountNumber]: '',
  [LoanDetailsFormField.AccountOwners]: [],
  [LoanDetailsFormField.Balance]: null,
  [LoanDetailsFormField.AvailableBalance]: null,
  [LoanDetailsFormField.LoanRateType]: null,
  [LoanDetailsFormField.FixedRateExpiryDate]: null,
  [LoanDetailsFormField.Institution]: null,
  [LoanDetailsFormField.InterestRate]: null,
  [LoanDetailsFormField.Limit]: null,
  [LoanDetailsFormField.MonthlyRepaymentAmount]: null,
  [LoanDetailsFormField.PropertyAddress]: null,
  [LoanDetailsFormField.TermInMonthsMonthPart]: null,
  [LoanDetailsFormField.TermInMonthsYearPart]: null,
  [LoanDetailsManualAddressFormField.isManualInput]: null,
  [LoanDetailsManualAddressFormField.postcode]: null,
  [LoanDetailsManualAddressFormField.state]: null,
  [LoanDetailsManualAddressFormField.streetName]: null,
  [LoanDetailsManualAddressFormField.streetNo]: null,
  [LoanDetailsManualAddressFormField.streetType]: null,
  [LoanDetailsManualAddressFormField.suburb]: null,
  [LoanDetailsManualAddressFormField.unitNo]: null,
};

type Fields = Array<LoanDetailsFormField>;
type FormFieldsByLiabilityType = Record<
  LiabilityTypeSupportedByApp,
  Set<LoanDetailsFormField>
>;

export const YEAR_TERM_OPTIONS = generateYearTermOptions({
  startFromZero: true,
  maxYear: MAX_YEAR,
});
export const MONTH_TERM_OPTIONS = generateMonthTermOptions();

const commonFields: Fields = [
  LoanDetailsFormField.Institution,
  LoanDetailsFormField.AccountOwners,
];
const loanTermRemainingFields = [
  LoanDetailsFormField.TermInMonthsYearPart,
  LoanDetailsFormField.TermInMonthsMonthPart,
];
const makeFieldSet = (additionalFields: Fields) =>
  new Set([...commonFields, ...additionalFields]);

export function makeLoanDetailsFormFieldsByLiabilityType(): FormFieldsByLiabilityType {
  const homeLoanFields: Fields = [
    LoanDetailsFormField.AccountNumber,
    LoanDetailsFormField.Balance,
    LoanDetailsFormField.AvailableBalance,
    LoanDetailsFormField.InterestRate,
    LoanDetailsFormField.PropertyAddress,
    LoanDetailsFormField.LoanRateType,
    LoanDetailsFormField.FixedRateExpiryDate,
    ...loanTermRemainingFields,
  ];
  const personalLoanFields: Fields = [
    LoanDetailsFormField.AccountNumber,
    LoanDetailsFormField.Balance,
    LoanDetailsFormField.MonthlyRepaymentAmount,
  ];
  const limitOnly: Fields = [
    LoanDetailsFormField.AccountNumber,
    LoanDetailsFormField.Limit,
  ];
  const limitAndInterestRate: Fields = [
    ...limitOnly,
    LoanDetailsFormField.InterestRate,
  ];
  return {
    [Liability_Type_Enum.HomeLoan]: makeFieldSet(homeLoanFields),
    [Liability_Type_Enum.LineOfCredit]: makeFieldSet(limitAndInterestRate),
    [Liability_Type_Enum.MarginOrInvestmentLoan]:
      makeFieldSet(limitAndInterestRate),
    [Liability_Type_Enum.Personal]: makeFieldSet(personalLoanFields),
    [Liability_Type_Enum.PersonalOverdraft]: makeFieldSet(limitOnly),
    [Liability_Type_Enum.CreditCard]: makeFieldSet(limitOnly),
    [Liability_Type_Enum.ChargeCard]: makeFieldSet(limitOnly),
    [Liability_Type_Enum.Bnpl]: makeFieldSet(limitOnly),
    [Liability_Type_Enum.StoreCard]: makeFieldSet(limitOnly),
    [Liability_Type_Enum.NovatedLease]: makeFieldSet([
      LoanDetailsFormField.Balance,
      LoanDetailsFormField.MonthlyRepaymentAmount,
    ]),
    [Liability_Type_Enum.HecsHelp]: new Set([
      LoanDetailsFormField.Balance,
      LoanDetailsFormField.AccountOwners,
    ]),
    [Liability_Type_Enum.Other]: makeFieldSet([
      LoanDetailsFormField.Balance,
      LoanDetailsFormField.MonthlyRepaymentAmount,
      ...loanTermRemainingFields,
    ]),
  };
}

const LOAN_DETAILS_FORM_FIELDS_BY_LIABILITY_TYPE =
  makeLoanDetailsFormFieldsByLiabilityType();

function shouldShowFieldByType(
  type: Liability_Type_Enum,
  field: LoanDetailsFormField,
): boolean {
  const formFields =
    type in LOAN_DETAILS_FORM_FIELDS_BY_LIABILITY_TYPE
      ? LOAN_DETAILS_FORM_FIELDS_BY_LIABILITY_TYPE[
          type as keyof typeof LOAN_DETAILS_FORM_FIELDS_BY_LIABILITY_TYPE
        ]
      : null;
  return formFields?.has(field) ?? false;
}

/**
 * Creating a separated function, as Available field has more conditions.
 *
 * When adding manual liability,
 * allowEditAvailableBalanceField = enableAvailableFundsCapture FF
 *
 * When updating manual liability,
 * allowEditAvailableBalanceField = enableAvailableFundsCapture FF OR
 * existing loan_liability record has limit value
 *
 * When updating detected liability, allowEditAvailableBalanceField = false
 */

function shouldShowAvailableField({
  liabilityType,
  liabilityManuallyAdded,
  allowEditAvailableBalanceField,
}: {
  liabilityType: Liability_Type_Enum;
  liabilityManuallyAdded: boolean;
  allowEditAvailableBalanceField: boolean;
}) {
  return (
    shouldShowFieldByType(
      liabilityType,
      LoanDetailsFormField.AvailableBalance,
    ) &&
    liabilityManuallyAdded &&
    allowEditAvailableBalanceField
  );
}

export function pickBalanceAndLimitValue({
  liabilityType,
  mlBalance,
  mlLimit,
  forceUseLimit = false,
}: {
  liabilityType: Liability_Type_Enum | null | undefined;
  mlBalance: number | null | undefined;
  mlLimit: number | null | undefined;
  forceUseLimit?: boolean | undefined;
}) {
  if (liabilityType == null) {
    return {
      balance: mlBalance,
      limit: mlLimit,
    };
  }
  const useBalance = shouldShowFieldByType(
    liabilityType,
    LoanDetailsFormField.Balance,
  );
  const useLimit =
    forceUseLimit ||
    shouldShowFieldByType(liabilityType, LoanDetailsFormField.Limit);
  return {
    balance: useBalance ? mlBalance : null,
    limit: useLimit ? mlLimit : null,
  };
}

export function calculateAvailableBalanceValue({
  liabilityType,
  mlBalance,
  mlLimit,
  liabilityManuallyAdded,
  allowEditAvailableBalanceField,
}: {
  liabilityType: Liability_Type_Enum | null | undefined;
  mlBalance: number | null | undefined;
  mlLimit: number | null | undefined;
  liabilityManuallyAdded: boolean;
  allowEditAvailableBalanceField: boolean;
}) {
  if (liabilityType == null || mlLimit == null || mlBalance == null) {
    return null;
  }
  const useAvailableBalance = shouldShowAvailableField({
    liabilityType,
    liabilityManuallyAdded,
    allowEditAvailableBalanceField,
  });

  const availableBalance = mlLimit - mlBalance;

  if (availableBalance < 0 || !useAvailableBalance) {
    return null;
  }
  return availableBalance;
}

export function getAccountNumberLabel(
  liabilityType: Liability_Type_Enum,
): string {
  switch (liabilityType) {
    case Liability_Type_Enum.HomeLoan:
    case Liability_Type_Enum.Personal:
      return t('Content.YourDebts.Form.AccountNumber');
    case Liability_Type_Enum.LineOfCredit:
    case Liability_Type_Enum.MarginOrInvestmentLoan:
    case Liability_Type_Enum.PersonalOverdraft:
    case Liability_Type_Enum.Bnpl:
      return t('Content.YourDebts.Form.AccountNumberLast4Digits');
    case Liability_Type_Enum.CreditCard:
    case Liability_Type_Enum.ChargeCard:
    case Liability_Type_Enum.StoreCard:
      return t('Content.YourDebts.Form.CardNumberLast4Digits');
    case Liability_Type_Enum.NovatedLease:
    case Liability_Type_Enum.Other:
      return '';
    default:
      return t('Content.Common.Word.Unknown');
  }
}
const MIN_TERM_IN_MONTHS_ERROR = 'min-terms-in-month';

const yupAccountOwners = yup.array().of(yup.string().required()).required();
function makeValidatorSchema(
  {
    liabilityType,
    liabilityManuallyAdded,
  }: Pick<LoanDetailsFormProps, 'liabilityType' | 'liabilityManuallyAdded'>,
  allowEditAvailableBalanceField: boolean,
) {
  const validatorByFormFields = {
    [LoanDetailsFormField.Institution]: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string().required(),
      })
      .nullable()
      .required(),
    [LoanDetailsFormField.AccountOwners]:
      liabilityType === Liability_Type_Enum.CreditCard
        ? yupAccountOwners.length(
            1,
            t('Content.Common.Error.PleaseSelectAnOption'),
          )
        : yupAccountOwners.min(
            1,
            t('Content.Common.Error.PleaseSelectAnOption'),
          ),
    [LoanDetailsFormField.TermInMonthsYearPart]: yupNumber
      .required(t('Content.Common.Error.PleaseSelectAnOption'))
      .test({
        name: MIN_TERM_IN_MONTHS_ERROR,
        test: (_value, context) => {
          const parent = context.parent as LoanDetailsFormValues;
          const [yearPart, monthPart] = [
            parent[LoanDetailsFormField.TermInMonthsYearPart],
            parent[LoanDetailsFormField.TermInMonthsMonthPart],
          ];
          if (typeof yearPart === 'number' && typeof monthPart === 'number') {
            const total = yearPart + monthPart;
            return total > 0;
          }

          // Return true to mark as valid.
          // This should be unreachable since we mark both part field
          // as required and this min-test run last.
          return true;
        },
        message: MIN_TERM_IN_MONTHS_ERROR,
      }),
    [LoanDetailsFormField.TermInMonthsMonthPart]: yupNumber.required(
      t('Content.Common.Error.PleaseSelectAnOption'),
    ),
    [LoanDetailsFormField.Balance]: yupNumber
      .min(
        MinValue.money,
        t('Content.YourDebts.Form.BalanceMinError', { value: MinValue.money }),
      )
      .max(
        MaxValue.number,
        t('Content.YourDebts.Form.BalanceMaxError', { value: MaxValue.number }),
      )
      .required(t('Content.YourDebts.Form.BalanceRequiredError')),
    [LoanDetailsFormField.AvailableBalance]:
      liabilityManuallyAdded && allowEditAvailableBalanceField
        ? yupNumber
            .max(
              MaxValue.number,
              t('Content.YourDebts.Form.AvailableBalanceMaxError', {
                value: MaxValue.number,
              }),
            )
            .required(t('Content.YourDebts.Form.AvailableBalanceRequiredError'))
        : yupNumber.nullable(),
    [LoanDetailsFormField.Limit]: yupNumber
      .min(
        MinValue.money,
        t('Content.YourDebts.Form.LimitMinError', { value: MinValue.money }),
      )
      .max(
        MaxValue.number,
        t('Content.YourDebts.Form.LimitMaxError', { value: MaxValue.number }),
      )
      .required(t('Content.YourDebts.Form.LimitRequiredError')),
    [LoanDetailsFormField.MonthlyRepaymentAmount]: yupNumber
      .min(
        MinValue.money,
        t('Content.YourDebts.Form.MonthlyRepaymentMinError', {
          value: MinValue.money,
        }),
      )
      .max(
        MaxValue.number,
        t('Content.YourDebts.Form.MonthlyRepaymentMaxError', {
          value: MaxValue.number,
        }),
      )
      .required(t('Content.YourDebts.Form.MonthlyRepaymentRequiredError')),
    [LoanDetailsFormField.InterestRate]: yupNumber
      .min(0)
      .required(t('Content.YourDebts.Form.InterestRateRequiredError')),
    [LoanDetailsFormField.AccountNumber]: yup
      .string()
      .min(4, t('Content.YourDebts.Form.AccountNumberMinError'))
      .required(t('Content.YourDebts.Form.AccountNumberRequiredError')),
    [LoanDetailsFormField.PropertyAddress]: yup
      .object()
      .when(LoanDetailsManualAddressFormField.isManualInput, {
        is: true,
        then: yup.object().nullable(),
        otherwise: yupAddress()
          .nullable()
          .required(t('Content.YourDebts.Form.PropertyAddressError')),
      }),
    [LoanDetailsFormField.LoanRateType]: yup
      .string()
      .nullable()
      .required(t('Content.Common.Error.PleaseSelectAnOption')),
    [LoanDetailsFormField.FixedRateExpiryDate]: yup
      .date()
      .nullable()
      .when(
        LoanDetailsFormField.LoanRateType,
        (
          loanRateType: Loan_Interest_Rate_Type_Enum,
          schema: yup.DateSchema<
            Date | null | undefined,
            AnyObject,
            Date | null | undefined
          >,
        ) => {
          const minDate = getMinimumDate();
          return loanRateType === Loan_Interest_Rate_Type_Enum.Fixed
            ? schema
                .typeError(t('Content.YourDebts.Form.InvalidDateFormat'))
                .min(
                  minDate,
                  t('Content.YourDebts.Form.FixedRateExpiryDateMinError', {
                    value: formatDateForDateInputDisplay(minDate),
                  }),
                )
                .required(
                  t('Content.YourDebts.Form.FixedRateExpiryDateRequiredError'),
                )
            : schema;
        },
      ),
  };

  const schema = yup.object(
    pickBy(
      validatorByFormFields,
      (validator, formField: LoanDetailsFormField) =>
        validator != null && shouldShowFieldByType(liabilityType, formField),
    ) as Record<LoanDetailsFormField, AnySchema>,
  );

  return schema.concat(getManualAddressYupSchema(manualAddressFieldPrefix));
}

const RowView = styled(View)({
  flexDirection: 'row',
});
const SHeaderText = styled(Text)({
  variant: 'text.sHeader',
});

export function LoanDetailsForm({
  screen,
  liabilityType,
  liabilityManuallyAdded,
  initialValues = FORM_INITIAL_VALUES,
  accountOwnerOptions,

  institutionsData,
  manualAddressStreetTypeOptions,
  manualAddressStateOptions,

  onSubmit,
  isSubmitting,
  submitButtonLabel,

  onDelete,
  showDeleteButton,

  onFlagAsIncorrect,
  showFlagAsIncorrectButton,

  // For property address input
  loadSuggestionData,
  isLoadingSuggestionData,
  propertySuggestionData,
  allowEditAvailableBalanceField,
  creditCheckDate,
  ccrBalance,
}: LoanDetailsFormProps) {
  const { validationSchema, shouldShowField } = useMemo(
    () => ({
      validationSchema: makeValidatorSchema(
        {
          liabilityType,
          liabilityManuallyAdded,
        },
        allowEditAvailableBalanceField,
      ),
      shouldShowField: (field: LoanDetailsFormField) =>
        shouldShowFieldByType(liabilityType, field),
    }),
    [allowEditAvailableBalanceField, liabilityManuallyAdded, liabilityType],
  );

  const isDetectedLiability = !liabilityManuallyAdded;
  const isHomeLoanLiability = liabilityType === Liability_Type_Enum.HomeLoan;
  const isHecsHelpLiability = liabilityType === Liability_Type_Enum.HecsHelp;

  // Some fields are only displayed for specific type of form.
  const showInstitutionField = shouldShowField(
    LoanDetailsFormField.Institution,
  );
  const showAccountNumberField = shouldShowField(
    LoanDetailsFormField.AccountNumber,
  );
  const showBalanceField = shouldShowField(LoanDetailsFormField.Balance);
  const showLimitField = shouldShowField(LoanDetailsFormField.Limit);
  const showAvailableField = shouldShowAvailableField({
    liabilityType,
    liabilityManuallyAdded,
    allowEditAvailableBalanceField,
  });

  const showTermRemainingField = shouldShowField(
    LoanDetailsFormField.TermInMonthsYearPart,
  );
  const showInterestRateField = shouldShowField(
    LoanDetailsFormField.InterestRate,
  );
  const showMonthlyRepaymentField = shouldShowField(
    LoanDetailsFormField.MonthlyRepaymentAmount,
  );
  const showPropertyAddressField = shouldShowField(
    LoanDetailsFormField.PropertyAddress,
  );
  const showLoanRateTypeField = shouldShowField(
    LoanDetailsFormField.LoanRateType,
  );

  return (
    // This pointerEvents=none only prevents user from clicking on the inputs
    // while the form is submitting.
    // It doesn't prevent the user from focusing on the inputs using keyboard.
    <View {...(isSubmitting ? { pointerEvents: 'none' } : null)}>
      <Formik
        onSubmit={(values) => {
          onSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formProps) => {
          const { touched, errors } = formProps;
          const getTermRemainingError = () => {
            const [yearPartError, monthPartError] = [
              errors[LoanDetailsFormField.TermInMonthsYearPart],
              errors[LoanDetailsFormField.TermInMonthsMonthPart],
            ];
            if (
              (touched[LoanDetailsFormField.TermInMonthsYearPart] &&
                yearPartError) ||
              (touched[LoanDetailsFormField.TermInMonthsMonthPart] &&
                monthPartError)
            ) {
              return yearPartError === MIN_TERM_IN_MONTHS_ERROR
                ? // TODO(uiv2): Revisit error message for all fields with Chloe
                  'Term remaining must be at least 1 month.'
                : t('Content.Common.Error.PleaseSelectAnOption');
            }
            return null;
          };

          const isManualAddressInput =
            formProps.values?.[LoanDetailsManualAddressFormField.isManualInput];
          const addressInput = isManualAddressInput ? (
            <>
              <ManualPropertyInputFields
                screen={screen}
                sectionInteractionKey={SectionInteractionKey.Debts}
                fieldNamePrefix={manualAddressFieldPrefix}
                stateOptions={manualAddressStateOptions}
                streetTypeOptions={manualAddressStreetTypeOptions}
              />
              <Text
                variants={['caption', 'link']}
                sx={{ mt: '$8' }}
                onPress={() =>
                  formProps.setFieldValue(
                    LoanDetailsManualAddressFormField.isManualInput,
                    false,
                    false,
                  )
                }
              >
                {t('Content.PropertyForm.FormV2.BackToSelectInput')}
              </Text>
            </>
          ) : (
            <>
              <FormPropertyInputV2
                name={LoanDetailsFormField.PropertyAddress}
                label={t('Content.YourDebts.Form.PropertyAddress')}
                inputTestID={TestID.LoanDetailsForm.PropertyAddressInput}
                propertySuggestionData={propertySuggestionData}
                loadSuggestionData={loadSuggestionData}
                isLoadingSuggestionData={isLoadingSuggestionData}
                sx={{ mb: '$8' }}
                interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                  screen,
                  liabilityType,
                  FieldInteractionKey.PropertyAddress,
                )}
              />
              <FormikFormError
                name={LoanDetailsFormField.PropertyAddress}
                sx={{ mb: '$16' }}
              />
              <Text variant="caption">
                {t('Content.YourDebts.Form.CantFindYourAddress')}
                <Text
                  variants={['caption', 'link']}
                  onPress={() =>
                    formProps.setFieldValue(
                      LoanDetailsManualAddressFormField.isManualInput,
                      true,
                      false,
                    )
                  }
                >
                  {t('Content.YourDebts.Form.EnterAddressManually')}
                </Text>
              </Text>
            </>
          );

          return (
            <>
              {showInstitutionField ? (
                <>
                  <FormInstitutionInputV2
                    name={LoanDetailsFormField.Institution}
                    label={t('Content.YourDebts.Form.Institution')}
                    institutionsData={institutionsData}
                    userInputtedItemTestID={
                      TestID.SelectInstitution.UserInputRow
                    }
                    inputTestID={TestID.LoanDetailsForm.InstitutionInput}
                    disabled={isDetectedLiability}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.Institution,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.Institution}
                    sx={{ mt: '$8' }}
                    errorMessageParser={() =>
                      t('Content.YourDebts.Form.InstitutionRequiredError')
                    }
                  />
                </>
              ) : null}
              {showAccountNumberField ? (
                <>
                  <FormTextInputV2
                    name={LoanDetailsFormField.AccountNumber}
                    label={getAccountNumberLabel(liabilityType)}
                    inputTestID={TestID.LoanDetailsForm.AccountNumberInput}
                    sx={{ mt: '$16' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.AccountNumber,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.AccountNumber}
                    sx={{ mt: '$8' }}
                  />
                </>
              ) : null}

              {showBalanceField ? (
                <>
                  <FormCurrencyInputV2
                    name={LoanDetailsFormField.Balance}
                    label={t('Content.YourDebts.Form.Balance')}
                    inputTestID={TestID.LoanDetailsForm.BalanceInput}
                    sx={{ mt: showInstitutionField ? '$16' : '$0' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.Balance,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.Balance}
                    sx={{ mt: '$8' }}
                  />
                  {isDetectedLiability &&
                  isHomeLoanLiability &&
                  creditCheckDate &&
                  ccrBalance ? (
                    <>
                      <Text variant="caption" sx={{ mt: '$8' }}>
                        {t(
                          'Content.YourDebts.Form.LoanAmountDescriptionLine1',
                          {
                            value: formatCurrency(ccrBalance),
                            date: safelyFormatDate(
                              creditCheckDate,
                              'dd MMM yyyy',
                            ),
                          },
                        )}
                      </Text>

                      <Text variant="caption" sx={{ mt: '$8' }}>
                        {t('Content.YourDebts.Form.LoanAmountDescriptionLine2')}
                        <Text variant="caption">
                          <A
                            href={t('Link.LearnMoreOnLoanAmountDescription')}
                            hrefAttrs={{
                              rel: 'noreferrer',
                              target: '_blank',
                            }}
                            variant="link"
                          >
                            {t(
                              'Content.YourDebts.Form.LoanAmountDescriptionLearnMore',
                            )}
                          </A>
                        </Text>
                      </Text>
                    </>
                  ) : null}
                  {!isDetectedLiability &&
                  isHomeLoanLiability &&
                  allowEditAvailableBalanceField ? (
                    <Text variant="caption" sx={{ mt: '$8' }}>
                      {t('Content.YourDebts.Form.LookForLoanBalance')}
                    </Text>
                  ) : null}
                  {isHecsHelpLiability ? (
                    <Link
                      href={t('Link.HECSHelpUrl')}
                      style={{
                        marginTop: 8,
                        fontSize: 13,
                        lineHeight: 17,
                      }}
                    >
                      {t('Content.YourDebts.Form.HECSHelp')}
                    </Link>
                  ) : null}
                </>
              ) : null}
              {showAvailableField ? (
                <>
                  <FormCurrencyInputV2
                    name={LoanDetailsFormField.AvailableBalance}
                    label={t('Content.YourDebts.Form.AvailableBalance')}
                    inputTestID={TestID.LoanDetailsForm.AvailableBalanceInput}
                    sx={{ mt: '$16' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.AvailableBalance,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.AvailableBalance}
                    sx={{ mt: '$8' }}
                  />
                  <Text variant="caption" sx={{ mt: '$8' }}>
                    {t('Content.YourDebts.Form.LookForAvailableBalance')}
                  </Text>
                </>
              ) : null}
              {showLimitField ? (
                <>
                  <FormCurrencyInputV2
                    name={LoanDetailsFormField.Limit}
                    label={t('Content.YourDebts.Form.Limit')}
                    inputTestID={TestID.LoanDetailsForm.LimitInput}
                    sx={{ mt: '$16' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.Limit,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.Limit}
                    sx={{ mt: '$8' }}
                  />
                </>
              ) : null}
              {showMonthlyRepaymentField ? (
                <>
                  <FormCurrencyInputV2
                    name={LoanDetailsFormField.MonthlyRepaymentAmount}
                    label={t('Content.YourDebts.Form.MonthlyRepayment')}
                    inputTestID={TestID.LoanDetailsForm.MonthlyRepaymentInput}
                    sx={{ mt: '$16' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.MonthlyRepaymentAmount,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.MonthlyRepaymentAmount}
                    sx={{ mt: '$8' }}
                  />
                </>
              ) : null}
              {showInterestRateField ? (
                <>
                  <FormPercentInputV2
                    name={LoanDetailsFormField.InterestRate}
                    label={t('Content.YourDebts.Form.InterestRate')}
                    inputTestID={TestID.LoanDetailsForm.InterestRateInput}
                    sx={{ mt: '$16' }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.InterestRate,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.InterestRate}
                    sx={{ mt: '$8' }}
                  />
                </>
              ) : null}
              {showTermRemainingField ? (
                <>
                  <Text
                    variant="emphasis"
                    sx={{
                      mt: '$16',
                    }}
                  >
                    {t('Content.YourDebts.Form.TermRemaining')}
                  </Text>
                  <RowView sx={{ mt: '$8' }}>
                    <FormSelectV2
                      name={LoanDetailsFormField.TermInMonthsYearPart}
                      testID={TestID.LoanDetailsForm.LoanTermYearPartPicker}
                      items={YEAR_TERM_OPTIONS}
                      label={t('Content.YourDebts.Form.Years')}
                      sx={{ flex: 1 }}
                    />
                    <FormSelectV2
                      name={LoanDetailsFormField.TermInMonthsMonthPart}
                      testID={TestID.LoanDetailsForm.LoanTermMonthPartPicker}
                      items={MONTH_TERM_OPTIONS}
                      label={t('Content.YourDebts.Form.Months')}
                      sx={{ ml: '$16', flex: 1 }}
                    />
                  </RowView>
                  <FormErrorText sx={{ mt: '$8' }}>
                    {getTermRemainingError()}
                  </FormErrorText>
                </>
              ) : null}
              {showLoanRateTypeField ? (
                <>
                  <SHeaderText sx={{ mt: '$32' }}>
                    {t('Content.YourDebts.Form.WhatsTheLoanType')}
                  </SHeaderText>
                  <RowView sx={{ mt: '$16' }}>
                    <FormRadioInputV2
                      name={LoanDetailsFormField.LoanRateType}
                      value={Loan_Interest_Rate_Type_Enum.Fixed}
                      // TODO: this test id needs to expose the state for automation
                      testID={makeTestId([
                        TestID.LoanDetailsForm.LoanRateType,
                        Loan_Interest_Rate_Type_Enum.Fixed,
                      ])}
                      label={t('Content.YourDebts.Form.LoanTypeOptions.FIXED')}
                      containerStyle={{
                        mr: '$16',
                        flex: 1,
                      }}
                      interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                        screen,
                        liabilityType,
                        FieldInteractionKey.LoanType,
                      )}
                    />
                    <FormRadioInputV2
                      name={LoanDetailsFormField.LoanRateType}
                      value={Loan_Interest_Rate_Type_Enum.Variable}
                      testID={makeTestId([
                        TestID.LoanDetailsForm.LoanRateType,
                        Loan_Interest_Rate_Type_Enum.Variable,
                      ])}
                      label={t(
                        'Content.YourDebts.Form.LoanTypeOptions.VARIABLE',
                      )}
                      containerStyle={{
                        flex: 1,
                      }}
                      interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                        screen,
                        liabilityType,
                        FieldInteractionKey.LoanType,
                      )}
                    />
                  </RowView>
                  <FormikFormError
                    name={LoanDetailsFormField.LoanRateType}
                    sx={{ mt: '$8' }}
                  />
                </>
              ) : null}
              {shouldShowField(LoanDetailsFormField.FixedRateExpiryDate) &&
              formProps.values.loanRateType ===
                Loan_Interest_Rate_Type_Enum.Fixed ? (
                <>
                  <FormDateInputV2
                    name={LoanDetailsFormField.FixedRateExpiryDate}
                    inputTestID={
                      TestID.LoanDetailsForm.FixedRateExpiryDateInput
                    }
                    label={t('Content.YourDebts.Form.FixedRateExpiryDate')}
                    sx={{
                      mt: '$16',
                      mb: '$8',
                    }}
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.FixedRateExpiryDate,
                    )}
                  />
                  <FormikFormError
                    name={LoanDetailsFormField.FixedRateExpiryDate}
                    sx={{ mb: '$16' }}
                  />
                  <Text variant="caption" sx={{ mb: '$8' }}>
                    {t(
                      'Content.YourDebts.Form.FixedRateExpiryDateSupportingText',
                    )}
                  </Text>
                </>
              ) : null}
              {showPropertyAddressField ? (
                <>
                  <SHeaderText sx={{ mt: '$32', mb: '$16' }}>
                    {t('Content.YourDebts.Form.WhatsThePropertyAddress')}
                  </SHeaderText>
                  {addressInput}
                </>
              ) : null}
              <SHeaderText sx={{ mt: '$32', mb: '$8' }}>
                {t('Content.YourDebts.Form.WhoAreTheAccountOwners')}
              </SHeaderText>
              {accountOwnerOptions.map(({ value, label, disabled }, index) => {
                const InputForAccountOwners =
                  liabilityType === Liability_Type_Enum.CreditCard ||
                  liabilityType === Liability_Type_Enum.NovatedLease ||
                  liabilityType === Liability_Type_Enum.HecsHelp
                    ? FormRadioInputV2
                    : FormCheckboxInputV2;
                return (
                  <InputForAccountOwners
                    key={value}
                    name={LoanDetailsFormField.AccountOwners}
                    testID={makeTestId([
                      TestID.LoanDetailsForm.AccountOwner,
                      value,
                    ])}
                    value={value}
                    label={label}
                    disabled={disabled}
                    containerStyle={{
                      mt: '$8',
                      mb: isLast(accountOwnerOptions, index) ? '$8' : undefined,
                    }}
                    checkboxCompatible
                    interactionKey={buildApplicationInteractionEventKeyForLoanDetailsForm(
                      screen,
                      liabilityType,
                      FieldInteractionKey.AccountOwner,
                    )}
                  />
                );
              })}
              <FormikFormError
                name={LoanDetailsFormField.AccountOwners}
                sx={{ mb: '$16' }}
              />
              {isDetectedLiability && showFlagAsIncorrectButton ? (
                <Text variant="caption" sx={{ mb: '$8' }}>
                  {t('Content.YourDebts.Form.FlagAsIncorrectFooter')}
                  <Text
                    variants={['caption', 'link']}
                    onPress={onFlagAsIncorrect}
                  >
                    {t('Content.YourDebts.Form.FlagAsIncorrect')}
                  </Text>
                </Text>
              ) : null}
              <LoanDetailsFormSubmitButton
                label={submitButtonLabel}
                onPress={() => formProps.handleSubmit()}
                mt="xl"
                showSpinner={isSubmitting}
                disabled={isSubmitting}
              />
              {liabilityManuallyAdded && showDeleteButton ? (
                <DeleteButton
                  mt="s"
                  onPress={onDelete}
                  disabled={isSubmitting}
                />
              ) : null}
            </>
          );
        }}
      </Formik>
    </View>
  );
}

const V2ButtonProps: Omit<
  React.ComponentProps<typeof Button>,
  keyof ButtonColorVariantProps
> = {
  alignSelf: 'stretch',
  py: 'm',
};

export function LoanDetailsFormSubmitButton({
  label,
  ...otherProps
}: React.ComponentProps<typeof Button>) {
  return (
    <Button
      label={label ?? t('Content.Common.ButtonLabel.Done')}
      testID={TestID.LoanDetailsForm.SubmitButton}
      {...V2ButtonProps}
      {...otherProps}
    />
  );
}

function DeleteButton(
  props: Omit<
    React.ComponentProps<typeof Button>,
    keyof ButtonColorVariantProps
  >,
) {
  return (
    <Button
      label={t('Content.YourDebts.Form.DeleteLoanAccount')}
      testID={TestID.LoanDetailsForm.DeleteButton}
      fontWeight="normal"
      tertiary
      {...V2ButtonProps}
      {...props}
    />
  );
}
