import { Autopay_Frequency_Input_Enum } from '../generated/graphql';

export enum AutoPaySetupFormField {
  RepaymentFrequency = 'repaymentFrequency',
  RepaymentAmount = 'repaymentAmount',
  IsCustomRepaymentAmount = 'isCustomRepaymentAmount',
  CustomRepaymentFrequency = 'customRepaymentFrequency',
  CustomRepaymentAmount = 'customRepaymentAmount',
  PaymentStartDate = 'paymentStartDate',
  ExternalAccountId = 'externalAccountId',
  IsManualAccountInput = 'isManualAccountInput',
  DirectDebitAccountName = 'directDebitAccountName',
  DirectDebitAccountBsb = 'directDebitAccountBsb',
  DirectDebitAccountNumber = 'directDebitAccountNumber',
  IsMinimumAmount = 'isMinimumAmount',
}

export type AutopayChangeRequestForm = {
  [AutoPaySetupFormField.RepaymentFrequency]:
    | Autopay_Frequency_Input_Enum
    | undefined;
  [AutoPaySetupFormField.RepaymentAmount]: number;
  [AutoPaySetupFormField.ExternalAccountId]: string;
  [AutoPaySetupFormField.DirectDebitAccountNumber]: string;
  [AutoPaySetupFormField.PaymentStartDate]: Date | undefined;
  [AutoPaySetupFormField.IsMinimumAmount]: boolean;
};

export type AutopayChangeRequestFormV2 = {
  [AutoPaySetupFormField.RepaymentFrequency]: Autopay_Frequency_Input_Enum | null;
  [AutoPaySetupFormField.RepaymentAmount]: number | null;
  [AutoPaySetupFormField.IsCustomRepaymentAmount]: boolean;
  [AutoPaySetupFormField.ExternalAccountId]: string;
  [AutoPaySetupFormField.PaymentStartDate]: Date | null;
  [AutoPaySetupFormField.IsManualAccountInput]: boolean;
  [AutoPaySetupFormField.DirectDebitAccountName]: string;
  [AutoPaySetupFormField.DirectDebitAccountBsb]: string;
  [AutoPaySetupFormField.DirectDebitAccountNumber]: string;
};

export type MinimumRepaymentAmountsByFrequency = Record<
  Autopay_Frequency_Input_Enum,
  number
>;
