import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { ActionSheetType } from '../../navigation/types/screens';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';
import {
  AutopaySetupConfirmationStep,
  AutopaySetupConfirmationStepAtom,
} from '../recoil/AutopaySetupConfirmationStep';
import { BaseAutopayShortfallConfirmationActionSheet } from './AutopayShortfallConfirmationActionSheet';

type AutopayShortfallConfirmationV2ActionSheetProps =
  ActionSheetScreenProps<ActionSheetType.AUTOPAY_SHORTFALL_CONFIRMATION_V2>;

export function AutopayShortfallConfirmationV2ActionSheet({
  navigation,
  route,
}: AutopayShortfallConfirmationV2ActionSheetProps) {
  const setAutopaySetupConfirmationStep = useSetRecoilState(
    AutopaySetupConfirmationStepAtom,
  );

  const { shortfallAmount } = route.params;

  const onClose = useCallback(() => navigation.goBack(), [navigation]);

  const actionList = [
    {
      label: t('Content.AutopaySettings.ConfirmShortfallButton'),
      secondary: false,
      onPress: () => {
        setAutopaySetupConfirmationStep(
          AutopaySetupConfirmationStep.RequiresShortfallConfirmationPassed,
        );
        onClose();
      },
    },
  ];

  return (
    <ActionSheet
      closeModal={onClose}
      title={t('Content.AutopaySettings.ConfirmShortfallHeading')}
      actionList={actionList}
    >
      <BaseAutopayShortfallConfirmationActionSheet
        shortfallAmount={shortfallAmount}
      />
    </ActionSheet>
  );
}
