import type {
  LDClient as LDReactClient,
  LDContext as LDReactContext,
  LDFlagValue,
} from 'launchdarkly-react-client-sdk';
import type {
  LDContext as LDNativeContext,
  LDFlagSet,
} from 'launchdarkly-react-native-client-sdk';
import { createContext, useContext } from 'react';

import { FALLBACK_FLAGS, Flags } from './featureFlags';

export type LaunchDarklyContextProps = {
  userId?: string;
  loanApplicationId?: string;
};

export type LaunchDarklyClient = {
  identify?: (
    context: LDReactContext | LDNativeContext,
  ) => Promise<null> | Promise<LDFlagSet>;
  variation?: (
    ...args: Parameters<LDReactClient['variation']>
  ) => Promise<boolean> | LDFlagValue;
  track?: (...args: Parameters<LDReactClient['track']>) => void;
  allFlags?: () => LDFlagSet | Promise<LDFlagSet>;
};

export type FeatureFlags = {
  flags: Flags;
  identify?: (context: LDReactContext | LDNativeContext) => Promise<void>;
  track?: (...args: Parameters<LDReactClient['track']>) => void;
  variation?: (
    ...args: Parameters<LDReactClient['variation']>
  ) => Promise<boolean> | LDFlagValue;
  ldContext?: LaunchDarklyContextProps;
  setLdContext?: (context: LaunchDarklyContextProps) => void;
};

export const FeatureFlagsContext = createContext<FeatureFlags>({
  flags: FALLBACK_FLAGS,
});

export function useFeatureFlagContext(): FeatureFlags {
  return useContext(FeatureFlagsContext);
}
