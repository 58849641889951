import { styled, SxProp, View } from 'dripsy';
import { useFormikContext } from 'formik';
import { ComponentProps, useCallback, useContext, useMemo } from 'react';

import { TestID } from '../../../testID/constants';
import {
  FieldInteractionKey,
  SectionInteractionKey,
} from '../../Analytics/types';
import { buildApplicationInteractionEventKey } from '../../Analytics/utils/gtmKeyUtils';
import {
  FormSelectV2,
  FormSuburbAutocompleteInput,
  FormTextInputV2,
} from '../../components/form/FormikInputs';
import { PickerOptions } from '../../components/form/types';
import { MaxCharacter } from '../../constants/fieldRules';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { Screen } from '../../navigation/types/screens';
import { makeTestId } from '../../utils/stringHelpers';
import { yup, yupNullableString, yupPostcode } from '../../utils/yup';
import { computeManualAddressInputFieldName } from '../utils/manualAddressHelper';
import { FormikFormError } from './FormError';

export enum ManualSuburbFieldNames {
  Suburb = 'suburb',
  State = 'state',
  Postcode = 'postcode',

  // Internal field name to determine whether current form is using
  // manual or suggested suburb input
  isManualInput = 'isManualInput',
}

export function getManualInputFieldNames<T extends string>(fieldsPrefix: T) {
  return {
    isManualInput: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualSuburbFieldNames.isManualInput,
    ),
    suburb: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualSuburbFieldNames.Suburb,
    ),
    state: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualSuburbFieldNames.State,
    ),
    postcode: computeManualAddressInputFieldName(
      fieldsPrefix,
      ManualSuburbFieldNames.Postcode,
    ),
  };
}

export function getManualInputFieldDefaultValues<T extends string>(
  fieldsPrefix: T,
) {
  const fieldNames = getManualInputFieldNames(fieldsPrefix);
  return {
    [fieldNames.isManualInput]: undefined,
    [fieldNames.postcode]: undefined,
    [fieldNames.state]: undefined,
    [fieldNames.suburb]: undefined,
  };
}

/**
 * Use this function to get schema that can be used to extend a form schema
 * to include fields for manual address input
 *
 * so the resulting schema will look like
 *
 * {
 *   ...ExistingSchema,
 *   "FieldPrefix-suburb": yupValidation(),
 *   "FieldPrefix-postcode": yupValidation(),
 *   "FieldPrefix-state": yupValidation(),
 *   . . .
 * }
 *
 * @param options - used to determine if field should become
 * required based on other fields values.
 */
export const getManualAddressYupSchema = (fieldsPrefix: string) => {
  const fieldNames = getManualInputFieldNames(fieldsPrefix);

  return yup.object({
    [fieldNames.isManualInput]: yup.boolean().nullable(),
    [fieldNames.suburb]: yupNullableString.when([fieldNames.isManualInput], {
      is: (isManualInput: boolean) => isManualInput,
      then: yupNullableString.required(
        t('Content.SuburbForm.Form.SuburbError'),
      ),
    }),
    [fieldNames.state]: yupNullableString.when([fieldNames.isManualInput], {
      is: (isManualInput: boolean) => isManualInput,
      then: yupNullableString.required(t('Content.SuburbForm.Form.StateError')),
    }),
    [fieldNames.postcode]: yupNullableString.when([fieldNames.isManualInput], {
      is: (isManualInput: boolean) => isManualInput,
      then: yupPostcode,
    }),
  });
};

export type ManualSuburbFieldsProps = {
  screen: Screen;
  stateOptions?: PickerOptions<string>;
  containerSx?: SxProp;
  fieldNamePrefix: string;
};

export function ManualSuburbInputFields({
  screen,
  stateOptions = [],
  containerSx,
  fieldNamePrefix,
}: ManualSuburbFieldsProps) {
  const formik = useFormikContext();

  const fieldNames = useMemo(
    () => getManualInputFieldNames(fieldNamePrefix),
    [fieldNamePrefix],
  );

  const { flags } = useContext(FeatureFlagsContext);
  const enableSuburbAutocomplete = flags.ENABLE_SUBURB_AUTOCOMPLETE;

  const commonSuburbInputProps: Expand<
    ComponentProps<typeof FormSuburbAutocompleteInput> &
      ComponentProps<typeof FormTextInputV2>
  > = {
    name: fieldNames.suburb,
    sx: { flex: 1 },
    label: t('Content.SuburbForm.Form.Suburb'),
    inputTestID: makeTestId([
      fieldNamePrefix,
      TestID.ExpectedRentalIncomeForm.ManualSuburbInput.Suburb,
    ]),
    interactionKey: buildApplicationInteractionEventKey(
      SectionInteractionKey.Income,
      screen,
      FieldInteractionKey.Suburb,
    ),
  };

  const onSuburbSelect = useCallback<
    NonNullable<
      ComponentProps<typeof FormSuburbAutocompleteInput>['onSuburbSelect']
    >
  >(
    (suburbDetails) => {
      const { state, postcode } = suburbDetails;
      if (state != null) {
        formik.setFieldValue(fieldNames.state, state);
      }
      if (postcode != null) {
        formik.setFieldValue(fieldNames.postcode, postcode);
      }
      setTimeout(() => {
        formik.validateForm();
      }, 1);
    },
    [fieldNames.postcode, fieldNames.state, formik],
  );

  const suburbInput = enableSuburbAutocomplete ? (
    <FormSuburbAutocompleteInput
      {...commonSuburbInputProps}
      onSuburbSelect={onSuburbSelect}
    />
  ) : (
    <FormTextInputV2 {...commonSuburbInputProps} />
  );

  return (
    <View sx={{ zIndex: 2, ...containerSx }}>
      {suburbInput}
      <FormikFormError name={fieldNames.suburb} sx={{ mt: '$8' }} />
      <RowView sx={{ mt: '$16' }}>
        <FormSelectV2
          name={fieldNames.state}
          sx={{ mr: '$16', flex: 1 }}
          label={t('Content.SuburbForm.Form.State')}
          testID={makeTestId([
            fieldNamePrefix,
            TestID.ExpectedRentalIncomeForm.ManualSuburbInput.State,
          ])}
          items={stateOptions}
        />
        <FormTextInputV2
          name={fieldNames.postcode}
          sx={{ flex: 1 }}
          maxLength={MaxCharacter.postcode}
          label={t('Content.SuburbForm.Form.Postcode')}
          inputTestID={makeTestId([
            fieldNamePrefix,
            TestID.ExpectedRentalIncomeForm.ManualSuburbInput.Postcode,
          ])}
          interactionKey={buildApplicationInteractionEventKey(
            SectionInteractionKey.Income,
            screen,
            FieldInteractionKey.Postcode,
          )}
        />
      </RowView>
      <FormikFormError name={fieldNames.state} sx={{ mt: '$8' }} />
      <FormikFormError name={fieldNames.postcode} sx={{ mt: '$8' }} />
    </View>
  );
}

const RowView = styled(View)({
  flexDirection: 'row',
});
