import { MouseEventHandler, TouchEventHandler, useCallback } from 'react';
import * as React from 'react';

import { INSIGHT_SVG_GRAPH_WIDTH } from '../InsightGraphContainer';

type Props = {
  enableBarHoverOn: boolean;
  graphWidthInPx: number;
  barWidth: number;
  barGap: number;
  setActiveBarIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const TooltipInteractiveArea = ({
  setActiveBarIndex,
  enableBarHoverOn,
  graphWidthInPx,
  barGap,
  barWidth,
}: Props) => {
  const handleOnTouchMoveInMobileWeb = useCallback<
    TouchEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (enableBarHoverOn) {
        const barIndex = Math.floor(
          ((event.targetTouches[0]?.pageX || 0) -
            event.currentTarget.getBoundingClientRect().left) /
            ((graphWidthInPx * (barWidth + barGap)) / INSIGHT_SVG_GRAPH_WIDTH),
        );
        setActiveBarIndex(barIndex);
      }
    },
    [enableBarHoverOn, graphWidthInPx, barWidth, barGap, setActiveBarIndex],
  );

  const handleOnMouseMoveInDesktopWeb = useCallback<
    MouseEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (enableBarHoverOn) {
        const barIndex = Math.floor(
          (event.clientX - event.currentTarget.getBoundingClientRect().left) /
            ((graphWidthInPx * (barWidth + barGap)) / INSIGHT_SVG_GRAPH_WIDTH),
        );
        setActiveBarIndex(barIndex);
      }
    },
    [enableBarHoverOn, graphWidthInPx, barWidth, barGap, setActiveBarIndex],
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
        borderWidth: 4,
      }}
      onMouseLeave={() => {
        setActiveBarIndex(-1);
      }}
      onMouseMove={handleOnMouseMoveInDesktopWeb}
      onTouchMove={handleOnTouchMoveInMobileWeb}
      onTouchEnd={() => setActiveBarIndex(-1)}
    />
  );
};
