import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import { StatementsModalStackParams } from '../../navigation/types/navTypes';
import { getPageTitle, Screen } from '../../navigation/types/screens';
import { TranslucentModalNavOptions } from '../../utils/NavConstants';
import { StatementPreview } from '../screens/StatementPreview';
import { StatementsFilter } from '../screens/StatementsFilter';
import { StatementsList } from '../screens/StatementsList';

const StatementsModalStack = createStackNavigator<StatementsModalStackParams>();

export const StatementsModalNavigator: React.FC = () => (
  <StatementsModalStack.Navigator screenOptions={TranslucentModalNavOptions}>
    <StatementsModalStack.Screen
      name={Screen.STATEMENTS_LIST_MODAL}
      component={StatementsList}
      options={{
        title: getPageTitle(Screen.STATEMENTS_LIST_MODAL),
        headerTitle: t('Content.Statements.Titles.List'),
      }}
    />
    <StatementsModalStack.Screen
      name={Screen.STATEMENTS_PREVIEW_MODAL}
      component={StatementPreview}
      options={{
        title: getPageTitle(Screen.STATEMENTS_PREVIEW_MODAL),
        headerTitle: '',
      }}
    />
    <StatementsModalStack.Screen
      name={Screen.STATEMENTS_FILTER_MODAL}
      component={StatementsFilter}
      options={{
        title: getPageTitle(Screen.STATEMENTS_FILTER_MODAL),
        headerTitle: t('Content.Statements.Titles.Filter'),
      }}
    />
  </StatementsModalStack.Navigator>
);
