import { FormikProps, FormikState } from 'formik';

import { yupAddressSchema } from '../../components/form/types';
import {
  State_Of_Issue_Enum,
  Update_Address_Input,
  UpdatePhysicalAddressMutationVariables,
} from '../../generated/graphql';
import {
  ALPHANUMERIC_SEPARATED_BY_DASH_REGEX,
  NUMBER_REGEX,
} from '../../localization/constants';
import { parseEnumType } from '../../utils/ensureEnumType';
import { yup } from '../../utils/yup';
import {
  AddressFields,
  AddressInputType,
  AddressType,
} from '../types/addressTypes';

export const getManualAddressSchema = () =>
  yup
    .object({
      postcode: yup
        .string()
        .length(4, 'Should contain 4 digits')
        .matches(NUMBER_REGEX, 'Should contain numbers only')
        .required(),
      state: yup.string().required(),
      street: yup.string().required(),
      streetNo: yup
        .string()
        .matches(
          ALPHANUMERIC_SEPARATED_BY_DASH_REGEX,
          'Street number should only contain alphanumeric separated with a dash',
        )
        .required(),
      streetType: yup.string().nullable(),
      unitNo: yup.string().nullable(),
      suburb: yup.string().required(),
    })
    .default({});

export const getAddressValidationSchema = (params: {
  isResidentialAddressManualInput: boolean;
  isAddressSame: boolean;
  isMailingAddressManualInput: boolean;
}) => {
  const {
    isResidentialAddressManualInput,
    isAddressSame,
    isMailingAddressManualInput,
  } = params;

  const notRequiredSchema = yup.object({}).optional().nullable();

  return yup
    .object({
      [AddressType.Residential]: yup
        .object({
          [AddressInputType.DomainApi]: !isResidentialAddressManualInput
            ? yupAddressSchema.required(
                t('Content.Common.Error.FieldRequiredError'),
              )
            : notRequiredSchema,
          [AddressInputType.Manual]: isResidentialAddressManualInput
            ? getManualAddressSchema().required()
            : notRequiredSchema,
        })
        .required(),
      [AddressType.Mailing]: isAddressSame
        ? yup.object().optional()
        : yup
            .object({
              [AddressInputType.DomainApi]: !isMailingAddressManualInput
                ? yupAddressSchema.required(
                    t('Content.Common.Error.FieldRequiredError'),
                  )
                : notRequiredSchema,
              [AddressInputType.Manual]: isMailingAddressManualInput
                ? getManualAddressSchema().required()
                : notRequiredSchema,
            })
            .required(),
    })
    .required();
};

export type SelectAddressValues = yup.Asserts<
  ReturnType<typeof getAddressValidationSchema>
>;

export const getInitialValues = (params: {
  isResidentialAddressManualInput: boolean;
  isMailingAddressManualInput: boolean;
}): SelectAddressValues => {
  const { isResidentialAddressManualInput, isMailingAddressManualInput } =
    params;

  const initialAddressValue = {
    [AddressFields.DisplayAddress]: '',
    [AddressFields.Postcode]: '',
    [AddressFields.State]: '',
    [AddressFields.Street]: '',
    [AddressFields.StreetNo]: '',
    [AddressFields.StreetNoTwo]: '',
    [AddressFields.StreetType]: '',
    [AddressFields.Suburb]: '',
    [AddressFields.UnitNo]: '',
    [AddressFields.IsProvidedByUser]: false,
    [AddressFields.DomainApiId]: '',
  };

  return {
    [AddressType.Residential]: {
      [AddressInputType.DomainApi]: !isResidentialAddressManualInput
        ? { ...initialAddressValue }
        : undefined,
      [AddressInputType.Manual]: isResidentialAddressManualInput
        ? { ...initialAddressValue }
        : undefined,
    },
    [AddressType.Mailing]: {
      [AddressInputType.DomainApi]: !isMailingAddressManualInput
        ? { ...initialAddressValue }
        : undefined,
      [AddressInputType.Manual]: isMailingAddressManualInput
        ? { ...initialAddressValue }
        : undefined,
    },
  };
};

export type DomainApiSchema = yup.Asserts<typeof yupAddressSchema>;
export type ManualInputSchema = yup.Asserts<
  ReturnType<typeof getManualAddressSchema>
>;

export type RequiredSchema<Schema> = {
  [Key in keyof Schema]-?: Required<NonNullable<Schema[Key]>>;
};

export const mapPhysicalAddressForMutation = (
  address: SelectAddressValues,
  isAddressSame: boolean,
  isResidentialAddressManualInput: boolean,
  isMailingAddressManualInput: boolean,
): UpdatePhysicalAddressMutationVariables => {
  const { mailingAddress, residentialAddress } = address;
  const mapDomainApiInput = (
    domainAddress: RequiredSchema<DomainApiSchema>,
  ): Update_Address_Input['domain_api_input'] => ({
    display_address: domainAddress.displayAddress,
    domain_api_property_id: domainAddress.domainApiId,
    postcode: domainAddress.postcode,
    street: domainAddress.street,
    street_no: domainAddress.streetNo,
    street_type: domainAddress.streetType,
    suburb: domainAddress.suburb,
    unit_no: domainAddress.unitNo,
    state: parseEnumType(
      State_Of_Issue_Enum,
      domainAddress.state,
    ) as NonNullable<ReturnType<typeof parseEnumType>>,
  });
  const mapManualInput = (
    manualAddress: RequiredSchema<ManualInputSchema>,
  ): Update_Address_Input['manual_input'] => ({
    combined_street_no: manualAddress.streetNo,
    postcode: manualAddress.postcode,
    street_type: manualAddress.streetType,
    suburb: manualAddress.suburb,
    unit_no: manualAddress.unitNo,
    state: parseEnumType(
      State_Of_Issue_Enum,
      manualAddress.state,
    ) as NonNullable<ReturnType<typeof parseEnumType>>,
    street_name: manualAddress.street,
  });
  const residentialAddressInput = {
    ...(isResidentialAddressManualInput && !!residentialAddress.manual_input
      ? {
          [AddressInputType.Manual]: mapManualInput(
            residentialAddress.manual_input,
          ),
        }
      : {
          [AddressInputType.DomainApi]: mapDomainApiInput(
            residentialAddress.domain_api_input,
          ),
        }),
  };
  const mailingAddressInput = {
    ...(isMailingAddressManualInput && !!mailingAddress.manual_input
      ? {
          [AddressInputType.Manual]: mapManualInput(
            mailingAddress.manual_input,
          ),
        }
      : {
          [AddressInputType.DomainApi]: mapDomainApiInput(
            mailingAddress.domain_api_input,
          ),
        }),
  };
  return {
    mailingAddress: isAddressSame
      ? residentialAddressInput
      : mailingAddressInput,
    residentialAddress: residentialAddressInput,
  };
};

export const setEnumValueOfStateField = (
  formProps: FormikProps<SelectAddressValues>,
  addressType: AddressType,
  value: string,
) => {
  formProps.setValues({
    ...formProps.values,
    [addressType]: {
      ...formProps.values[addressType],
      [AddressFields.State]: value,
    },
  });
};

export const getErrorMessage = (
  formProps: FormikState<SelectAddressValues>,
  addressType: AddressType,
  addressInputType: AddressInputType,
) => {
  const touched = formProps.touched[addressType];
  if (!touched) return undefined;

  const addressTypeTouched = touched[addressInputType];
  if (!addressTypeTouched) return undefined;

  if (addressInputType === AddressInputType.DomainApi) {
    const errors = formProps.errors[addressType];
    if (!errors) return undefined;
    if (errors[addressInputType]) {
      return t('Content.Common.Error.FieldRequiredError');
    }
  }

  const addressInputTypeTouched = Object.keys(addressTypeTouched);
  if (addressInputTypeTouched.length < 1) return undefined;

  const errors = formProps.errors[addressType];
  if (!errors) return undefined;

  const addressTypeErrors = errors[addressInputType];
  if (!addressTypeErrors) return undefined;

  const addressInputTypeErrors = Object.keys(addressTypeErrors);

  const shouldDisplayError = addressInputTypeTouched.some((touchedField) =>
    addressInputTypeErrors.includes(touchedField),
  );

  if (shouldDisplayError) {
    return t('Content.Common.Error.FieldRequiredError');
  }

  return undefined;
};
