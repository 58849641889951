import { Text, View } from 'dripsy';

import { TestID } from '../../../testID/constants';
import { DataRow, DataRowGroup } from '../../ui/molecules/DataRow';

type Props = {
  residentialAddress: string;
  mailingAddress: string;
  loading?: boolean;
  onAddressChange?: () => void;
};

export const AddressSection = ({
  residentialAddress,
  mailingAddress,
  loading = false,
  onAddressChange,
}: Props) => (
  <View sx={{ mt: '$24' }}>
    <Text variant="sHeader" sx={{ py: '$8', mb: '$8' }}>
      {t('Content.PersonalDetails.Address.Title')}
    </Text>
    <DataRowGroup mt={0}>
      <DataRow
        label=""
        caption={
          <View>
            <View>
              <Text variant="xsHeader">
                {t('Content.PersonalDetails.Address.ResidentialAddress')}
              </Text>
              <Text variant="body">
                {loading
                  ? t('Content.Common.Placeholder.Loading')
                  : residentialAddress}
              </Text>
            </View>
            <View sx={{ mt: '$8' }}>
              <Text variant="xsHeader">
                {t('Content.PersonalDetails.Address.MailingAddress')}
              </Text>
              <Text variant="body">
                {loading
                  ? t('Content.Common.Placeholder.Loading')
                  : mailingAddress}
              </Text>
            </View>
          </View>
        }
        loading={loading}
        disabled={loading}
        onPress={!loading ? onAddressChange : undefined}
        testID={TestID.PersonalDetails.ChangeAddressButton}
      />
    </DataRowGroup>
  </View>
);
