import { useState } from 'react';
import { ErrorRow } from 'src/components/ErrorRow';
import { Refinancing_Reason_Input_Enum } from 'src/generated/graphql';
import { useRefinanceDeclaration } from 'src/LoanConfiguration/utils/useRefinanceDeclaration';
import { captureException } from 'src/sentry';
import { ModalScreenContainer } from 'src/ui/v2/ModalScreenContainer';
import { parseEnumType } from 'src/utils/ensureEnumType';
import { yup } from 'src/utils/yup';

import { Screen } from '../../../navigation/types/screens';
import {
  SelectRefiReasonForm,
  SelectRefiValidationFormValues,
} from '../components/SelectRefiReasonForm';

const validationSchema = yup.object({
  refinancingReason: yup.string().required(),
});

export type RefiReasonFormValues = Partial<
  yup.Asserts<typeof validationSchema>
>;

type Props = {
  screen: Screen;
  onSubmitSuccess: () => void;
  onClose: () => void;
  loanApplicationId?: string;
  hideBackButton: boolean;
};

export function SelectRefiReasonV2Base({
  screen,
  onSubmitSuccess,
  onClose,
  loanApplicationId = '',
  hideBackButton,
}: Props) {
  const {
    refinancingReasonOptions,
    financialDeclaration,
    loading,
    upsertFinancialDeclaration,
    upsertFinancialDeclarationLoading: isSubmitting,
  } = useRefinanceDeclaration(loanApplicationId);

  const initialFormValues: RefiReasonFormValues = {
    refinancingReason: financialDeclaration?.refinancing_reason || undefined,
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (values: SelectRefiValidationFormValues) => {
    const parsedRefinancingReason = parseEnumType(
      Refinancing_Reason_Input_Enum,
      values.refinancingReason,
    );

    if (!loanApplicationId) {
      setErrorMessage(t('Content.Common.Error.NoAssociatedLoanApplication'));
      return;
    }

    if (parsedRefinancingReason == null) {
      captureException('Unable to parse refinance reason', {
        parsedRefinancingReason,
        values,
      });
      return;
    }

    const [res, error] = await upsertFinancialDeclaration(
      parsedRefinancingReason,
    );

    if (res == null || error) {
      setErrorMessage(t('Content.Common.Error.FailUpdateFinancialDeclaration'));
      return;
    }
    onSubmitSuccess();
  };

  return (
    <ModalScreenContainer
      scrollable
      headerText={t('Content.SetupLoanV2.ScreenTitle')}
      onClose={onClose}
      hideBackButton={hideBackButton}
      loading={isSubmitting}
    >
      {errorMessage ? <ErrorRow message={errorMessage} my="s" /> : null}
      <SelectRefiReasonForm
        screen={screen}
        reasonOptions={refinancingReasonOptions}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        reasonOptionsLoading={loading}
        initialValues={initialFormValues}
      />
    </ModalScreenContainer>
  );
}
