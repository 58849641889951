import { GetMeQuery } from '../../generated/graphql';

type User = NonNullable<GetMeQuery['me'][0]['user']>;
export type Address = User['residential_address'] | User['mailing_address'];

export const getFormattedAddress = (address: Address) => {
  const country = 'Australia' as const;

  return [
    address?.short_address_format,
    address?.short_address_format_two,
    country,
  ]
    .filter(Boolean)
    .join('\n');
};
