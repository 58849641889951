import {
  Loan_Application_Target_Error_Type,
  Upsert_Loan_Application_Target_For_Purchase_Error_Type,
} from '../../generated/graphql';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatLvrPercentage } from '../../utils/percentageFormatter';
import { assertUnreachable } from '../../utils/typesHelpers';
import type { UpsertCompletedErrorPayload } from './setupLoanPurchaseErrorBannerReducer';

export function handleSetupLoanErrorType(
  errorType: Loan_Application_Target_Error_Type,
  {
    minTotalLoanAmountBeforeCosts = 10000,
  }: { minTotalLoanAmountBeforeCosts?: number } = {},
) {
  switch (errorType) {
    case Loan_Application_Target_Error_Type.BorrowMoreMoneyNoCashoutAmount:
      return t('Content.SetupLoan.FieldError.EnterCashoutAmount');
    // TODO: This error message doesn't specify what field is invalid
    // and is not helpful to the user.
    case Loan_Application_Target_Error_Type.InvalidInput:
      return t('Content.SetupLoan.FieldError.InvalidInput');
    case Loan_Application_Target_Error_Type.SelectedLiabilitiesNotAllowedToBeRefinanced:
      return t(
        'Content.SetupLoan.FieldError.SelectedLiabilitiesNotAllowedToBeRefinanced',
      );
    case Loan_Application_Target_Error_Type.TotalLoanAmountBeforeCostsBelowMinimum:
      return t(
        'Content.SetupLoan.FieldError.TotalLoanAmountBeforeCostsBelowX',
        {
          x: formatCurrency(minTotalLoanAmountBeforeCosts, {
            noFraction: true,
          }),
        },
      );
    case Loan_Application_Target_Error_Type.DesiredLoanAmountGreaterThanAllowed:
      return t(
        'Content.SetupLoan.FieldError.DesiredLoanAmountGreaterThanAllowed',
      );
    default:
      // TODO: Uncomment this when the schema in Core is updated
      // assertUnreachable(errorType);
      return t('Content.Common.Error.FailCreateLoanApplicationTarget');
  }
}

// Why is this declared here when it's only used in the screen reducer?
export function formatPurchaseSetupLoanErrorType({
  errorType,
  minTotalLoanAmountBeforeCosts,
  maxLoanAmount,
  totalLoanAmount,
  maxLvrApplicable,
  maxTotalLoanAmount,
}: UpsertCompletedErrorPayload) {
  switch (errorType) {
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.LmiQuoteRecalculationFailed:
      // Handled outside of this function
      return null;

    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.TotalLoanAmountExceededPropertyMaxLvr: {
      // This is a special case where the LVR rules specify a postcode
      // restriction, setting the property max LVR to 0%.
      if (maxTotalLoanAmount === 0) {
        return t(
          'Content.SetupLoan.FieldError.TotalLoanAmountExceededPropertyMaxLvrZero',
        );
      }

      return maxLvrApplicable === 70
        ? // We have specific wording when the max LVR is 70%. This happens when VRA type is 1, 2, or 3.
          t(
            'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxLvrSeventy',
            { totalLoanAmount: formatCurrency(totalLoanAmount) },
          )
        : t('Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithLvr', {
            totalLoanAmount: formatCurrency(totalLoanAmount),
            maxLvr: maxLvrApplicable
              ? formatLvrPercentage(maxLvrApplicable)
              : '--%',
          });
    }
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.TotalLoanAmountExceededCapAmount: {
      return t(
        'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithMaxTotal',
        {
          totalLoanAmount: formatCurrency(totalLoanAmount),
          maxTotal: formatCurrency(maxTotalLoanAmount),
        },
      );
    }
    /** @deprecated */
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.TotalLoanAmountExceededMaximum: {
      if (maxLvrApplicable === 0) {
        return t('Content.SetupLoanPurchase.FieldError.MaxXLoanAmount', {
          x: formatCurrency(maxLoanAmount, {
            noFraction: true,
          }),
        });
      }
      return t(
        'Content.SetupLoan.FieldError.TotalLoanAmountExceededMaxWithLvr',
        {
          totalLoanAmount: formatCurrency(totalLoanAmount),
          maxLvr: maxLvrApplicable
            ? formatLvrPercentage(maxLvrApplicable)
            : '--%',
        },
      );
    }
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.DesiredLoanAmountBelowMinimum:
      return t('Content.SetupLoanPurchase.FieldError.MinXLoanAmount', {
        x: formatCurrency(minTotalLoanAmountBeforeCosts, {
          noFraction: true,
        }),
      });
    /** @deprecated */
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.DesiredLoanAmountExceededMaximum:
      return t('Content.SetupLoanPurchase.FieldError.MaxXLoanAmount', {
        x: formatCurrency(maxLoanAmount, {
          noFraction: true,
        }),
      });
    case Upsert_Loan_Application_Target_For_Purchase_Error_Type.LmiApplicableCheckFailed:
      return t('Content.Common.Error.FailCreateLoanApplicationTarget');
    default:
      assertUnreachable(errorType);
      return t('Content.Common.Error.FailCreateLoanApplicationTarget');
  }
}
