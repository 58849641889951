import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import { NavHeaderSpacer } from '../../components/NavHeaderSpacer';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { EmptyState } from '../../ui/organisms/EmptyState';
import { openUrl } from '../../utils/openUrl';
import { IntroSection } from '../components/IntroSection';
import { LoanApplicationScreenContainer } from '../components/LoanApplicationScreenContainer';
import { LoanApplicationWizardFooter } from '../components/LoanApplicationWizardFooter';
import {
  LoanScreenHeader,
  Props as LoanScreenHeaderProps,
} from '../components/LoanScreenHeader';
import { NoLoanApplicationFound } from '../components/NoLoanApplicationFound';
import { useNavigateToLoanApplicationScreen } from '../navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../navigation/loanApplicationSection';
import { LoanApplicationV2ScreenProps } from '../navigation/types';
import { useLoanApplication } from '../utils/useLoanApplication';

type Props = LoanApplicationV2ScreenProps<Screen.LOAN_APPLICATION_V2_INTRO>;

type LoanApplicationHeaderProps = Pick<
  LoanScreenHeaderProps,
  'learnMoreTestId' | 'learnMoreText' | 'onLearnMorePress'
>;
function LoanApplicationIntroHeader(props: LoanApplicationHeaderProps) {
  return (
    <LoanScreenHeader
      title={t('Content.LoanApplicationIntro.BeforeWeBegin')}
      caption={t('Content.LoanApplicationIntro.TakeAMoment')}
      pb="xl"
      {...props}
    />
  );
}

function getComponentPropsBasedOnLoanApplicationType({
  loanApplicationType,
}: {
  loanApplicationType: Loan_Application_Type_Enum | undefined;
}) {
  switch (loanApplicationType) {
    case Loan_Application_Type_Enum.Purchase: {
      return {
        additionalHeaderProps: {
          learnMoreTestId: TestID.Intro.CheckMyEligibilityButton,
          learnMoreText: t('Content.LoanApplicationIntro.CheckMyEligibility'),
          onLearnMorePress: () => {
            openUrl(t('Link.CheckMyEligibility'));
          },
        },
        yourUnloanSection: null,
      };
    }
    case Loan_Application_Type_Enum.Refinance: {
      return {
        additionalHeaderProps: null,
        yourUnloanSection: {
          description: t(
            'Content.LoanApplicationIntro.LoanApplicationType.REFINANCE.YourUnloanCaption',
          ),
        },
      };
    }
    case Loan_Application_Type_Enum.Variation:
    default: {
      return {
        additionalHeaderProps: null,
        yourUnloanSection: null,
      };
    }
  }
}
function LoanApplicationIntroV2Base({ route, navigation }: Props) {
  const loanApplicationId = route.params?.loanApplicationId;
  const { loanApplicationType, loading, loanApplication } =
    useLoanApplication(loanApplicationId);

  const { navigateToNextLoanApplicationScreen } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const onContinuePress = () => {
    navigateToNextLoanApplicationScreen({
      currentSection: LoanApplicationSection.Intro,
    });
  };

  const { additionalHeaderProps, yourUnloanSection } =
    getComponentPropsBasedOnLoanApplicationType({
      loanApplicationType,
    });

  if (loading) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanApplicationIntroHeader />
        <ScreenLoadingContainer loading />
      </LoanApplicationScreenContainer>
    );
  }

  if (loanApplicationType == null) {
    return <NoLoanApplicationFound />;
  }

  if (loanApplicationType === Loan_Application_Type_Enum.Variation) {
    return (
      <LoanApplicationScreenContainer>
        <NavHeaderSpacer />
        <LoanApplicationIntroHeader />
        <EmptyState
          title={t(
            'Content.LoanApplicationIntro.LoanApplicationType.VARIATION.Unavailable',
          )}
        />
      </LoanApplicationScreenContainer>
    );
  }

  return (
    <LoanApplicationScreenContainer>
      <NavHeaderSpacer />
      <LoanApplicationIntroHeader {...additionalHeaderProps} />
      <Box mx="l">
        <IntroSection
          icon="yourProperty"
          title={t('Content.LoanApplicationIntro.YourProperty')}
          description={t(
            `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.YourPropertyCaption`,
            // maxLvr to 90% is only applicable to purchase applications
            { maxLvr: loanApplication?.is_lmi_enabled ? '90' : '80' },
          )}
        />
        <IntroSection
          icon="borrowers"
          title={t('Content.LoanApplicationIntro.Borrowers')}
          description={t(
            `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.BorrowersCaption`,
          )}
        />
        <IntroSection
          icon="yourFinancial"
          title={t('Content.LoanApplicationIntro.YourFinancials')}
          description={t(
            `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.YourFinancialsCaption`,
          )}
        />
        {yourUnloanSection ? (
          <IntroSection
            icon="yourCurrentLoan"
            title={t('Content.LoanApplicationIntro.YourUnloan')}
            description={yourUnloanSection.description}
          />
        ) : null}
      </Box>
      <LoanApplicationWizardFooter
        primaryButtonLabel={t(
          `Content.LoanApplicationIntro.LoanApplicationType.${loanApplicationType}.ButtonLabel`,
        )}
        onPrimaryButtonPress={onContinuePress}
        primaryButtonTestID={TestID.Intro.BeforeWeBeginContinueButton}
      />
    </LoanApplicationScreenContainer>
  );
}

export const LoanApplicationIntroV2 = withAuthenticationRequired(
  LoanApplicationIntroV2Base,
);
