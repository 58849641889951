import type { DebouncedFunc, DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any) => any>(
  functionToDebounce: T,
  wait: number,
  options?: DebounceSettings,
): DebouncedFunc<T> {
  const fnRef = useRef<T>(functionToDebounce);
  const debouncedFnRef = useRef<DebouncedFunc<T>>();

  const setup = useCallback(() => {
    const wrapper = ((...args) => fnRef.current(...args)) as T;
    debouncedFnRef.current = debounce(wrapper, wait, options);
  }, [options, wait]);

  if (debouncedFnRef.current == null) {
    setup();
  }

  useEffect(() => {
    fnRef.current = functionToDebounce;
  }, [functionToDebounce]);

  useEffect(() => {
    setup();
  }, [setup]);

  return debouncedFnRef.current as DebouncedFunc<T>;
}
