import { gql } from '@apollo/client';
import { useCallback } from 'react';
import * as React from 'react';

import {
  refetchGetConnectedInstitutionsQuery,
  useDeleteInstitutionConnectionMutation,
} from '../../generated/graphql';
import { ActionSheetType } from '../../navigation/types/screens';
import { Alert } from '../../ui/atoms/Alert';
import { ActionSheet } from '../components/ActionSheet';
import { ActionSheetScreenProps } from '../navigation/types';

type Props = ActionSheetScreenProps<ActionSheetType.DISCONNECT_BANK>;

export const DeleteInstitutionConnectionMutation = gql`
  mutation DeleteInstitutionConnection($id: Int!) {
    deleteInstitutionConnection(institutionConnectionId: $id) {
      success
    }
  }
`;

export const DisconnectBankActionSheet: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { institutionConnectionId } = route.params;

  const [deleteInstitutionConnection, { loading }] =
    useDeleteInstitutionConnectionMutation({
      refetchQueries: [refetchGetConnectedInstitutionsQuery()],
      awaitRefetchQueries: true,
    });

  const onDisconnect = useCallback(async () => {
    try {
      await deleteInstitutionConnection({
        variables: { id: institutionConnectionId },
        context: {
          sentryContext: { institutionConnectionId },
        },
      });
      navigation.goBack();
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error(e);
      Alert.alert('Failed to disconnect bank');
    }
  }, [navigation, deleteInstitutionConnection, institutionConnectionId]);

  return (
    <ActionSheet
      closeModal={navigation.goBack}
      preventCloseModal={loading}
      title="Disconnect this bank?"
      message="This will remove all associated accounts and transactions."
      actionList={[
        {
          label: 'Disconnect',
          disabled: loading,
          showSpinner: loading,
          isDestructive: true,
          onPress: onDisconnect,
        },
      ]}
    />
  );
};
