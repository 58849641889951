import {
  Biza_Account,
  Biza_Account_Sharing_Status,
} from '../generated/graphql';

export type BizaAccount = Biza_Account;

export type PendingDomsRequest = BizaAccount['pending_doms_request'];

export type PendingDomRequestUser = {
  id: string;
  name: string;
  status: string;
};

export enum Status {
  Pending = 'Pending',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export const AccountStatus = {
  [Biza_Account_Sharing_Status.PendingApproval]: Status.Pending,
  [Biza_Account_Sharing_Status.SharingEnabled]: Status.Enabled,
  [Biza_Account_Sharing_Status.SharingDisabled]: Status.Disabled,
};

export enum DataSharingEventType {
  Enable = 'Enable',
  Disable = 'Disable',
  Approve = 'Approve',
  Decline = 'Decline',
  Cancel = 'Cancel',
}
