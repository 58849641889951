import {
  Pressable,
  ScrollView,
  SxProp,
  Text,
  TextInput as DripsyTextInput,
  ThemeColorName,
  View,
} from 'dripsy';
import { FormikContextType } from 'formik';
import Fuse from 'fuse.js';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputFocusEventData,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import {
  FormikControlProps,
  PickerOption,
  PickerOptions,
} from '../../components/form/types';
import { MaxCharacter } from '../../constants/fieldRules';
import { isLast } from '../../utils/arrayHelpers';
import { mergeRefs } from '../../utils/mergeRefs';
import { isWeb } from '../../utils/platformUtils';
import { makeTestId } from '../../utils/stringHelpers';
import { InputRow } from './InputRow';
import { TextInputV2Props } from './TextInput';
import { LISTBOX_ACCESSBILITY_ROLE, OPTION_ACCESSBILITY_ROLE } from './utils';

const MAX_PICKER_ITEM_SHOWN_AT_ONCE = 5;

type TextPickerInputValue = PickerOption<string> | null;

export type TextPickerInputProps = Omit<
  TextInputV2Props,
  'onChangeText' | 'value'
> & {
  value?: TextPickerInputValue;
  optionsData: PickerOptions<string>;
  suggestionsTestIDPrefix?: string;
  allowUserInput?: boolean;
  userInputtedItemTestID?: string;
  maxSuggestions?: number;
  suggestionResultZIndex?: number;
  onChangeSearchText?: (search: string) => void;
  onSuggestionSelect?: (value: TextPickerInputValue) => Promise<void>;

  // Specific for test and storybook
  searchTextInitialValue?: string;
};

const focusedDataset = isWeb ? { focused: 'true' } : undefined;
export const TextPickerInputBase = forwardRef<
  RNTextInput | null,
  TextPickerInputProps
>(
  (
    {
      label,
      optionsData,
      suggestionsTestIDPrefix,
      inputTestID,
      containerTestID,
      disabled,
      allowUserInput,
      userInputtedItemTestID,
      maxSuggestions,
      // Generally we want this to be higher than other components
      suggestionResultZIndex = 2,
      sx: sxProp,
      onChangeSearchText,
      onSuggestionSelect,
      searchTextInitialValue,
      // These need to be controlled by a form controller
      value,
      error,
      focused,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      // These should go to RN text input
      maxLength,
      keyboardType,
    }: TextPickerInputProps,
    ref,
  ) => {
    const localTextInputRefs = useRef<RNTextInput>(null);

    const [labelId] = useState(() => uuidv4());
    const [searchValue, setSearchValue] = useState(
      (searchTextInitialValue || value?.label) ?? '',
    );

    // Using fuse for fuzzy search
    const fuse = useMemo(
      () =>
        new Fuse(optionsData, {
          fieldNormWeight: 1,
          findAllMatches: true,
          keys: ['label'],
        }),
      [optionsData],
    );

    const filteredOptions = useMemo(
      () =>
        searchValue
          ? fuse
              .search(
                searchValue,
                maxSuggestions
                  ? {
                      limit: maxSuggestions,
                    }
                  : undefined,
              )
              .map((result) => result.item)
          : optionsData,
      [searchValue, fuse, maxSuggestions, optionsData],
    );

    const changeSearchText = useCallback(
      (searchText: string) => {
        setSearchValue(searchText);
        onChangeSearchText?.(searchText);
      },
      [onChangeSearchText],
    );

    const onBlur = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onBlurProp?.(e);
      },
      [onBlurProp],
    );

    const onFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (disabled) {
          return;
        }

        onFocusProp?.(e);
      },
      [disabled, onFocusProp],
    );

    const onResultItemPress = useCallback(
      async (data: PickerOption<string>) => {
        setSearchValue(data.label);
        await onSuggestionSelect?.(data);
        localTextInputRefs.current?.blur();
      },
      [onSuggestionSelect],
    );

    let borderColor: ThemeColorName = '$border';
    let labelColor: ThemeColorName = '$secondary';
    let bgColor: ThemeColorName = '$inputBackground';
    let valueColor: ThemeColorName = '$labelsPrimary';
    if (error) {
      borderColor = '$error';
      labelColor = '$error';
    }
    if (focused) {
      borderColor = '$focus';
    }
    if (disabled) {
      labelColor = '$secondaryDisabled';
      bgColor = '$inputBackgroundDisabled';
      valueColor = '$secondary';
    }

    const smallLabel = focused || searchValue.length > 0;
    return (
      <InputRow
        sx={{
          bg: bgColor,
          py: '$7',
          borderColor,
          ...(focused
            ? { overflow: 'visible', zIndex: suggestionResultZIndex }
            : null),
          ...sxProp,
        }}
        testID={containerTestID}
        dataSet={focused ? focusedDataset : undefined}
      >
        <Text
          variant={smallLabel ? 'tiny' : 'default'}
          sx={{
            px: '$16',
            color: labelColor,
            position: 'absolute',
            ...(smallLabel ? { top: '$7' } : { zIndex: -1 }),
          }}
          numberOfLines={1}
          nativeID={labelId}
          selectable={false}
        >
          {label}
        </Text>
        <DripsyTextInput
          sx={{
            variant: 'text.default',
            color: valueColor,
            pt: '$16',
            px: '$16',
          }}
          value={searchValue}
          editable={!disabled}
          maxLength={maxLength || MaxCharacter.general}
          numberOfLines={1}
          keyboardType={keyboardType}
          onChangeText={changeSearchText}
          onBlur={onBlur}
          onFocus={onFocus}
          underlineColorAndroid="transparent"
          // This is a browser specific fix for Chrome only.
          // see discussion for further info: https://github.com/unloan/unloan-app/issues/3428
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error `chrome-off` is a web only enum and
          // our RN types override stopped working since Expo 49 upgrades,
          // see `@types/react-native.d.ts` for existing overrides.
          autoComplete={isWeb ? 'chrome-off' : undefined} // to disable autocomplete in chrome
          testID={inputTestID}
          ref={mergeRefs([localTextInputRefs, ref])}
          accessibilityLabelledBy={labelId}
          autoCorrect={false}
        />
        <SuggestionsResult
          searchResult={filteredOptions}
          showResults={focused === true && searchValue.length > 0}
          currentValue={searchValue}
          userInputtedItemTestID={userInputtedItemTestID}
          allowUserInput={allowUserInput}
          suggestionsTestIDPrefix={suggestionsTestIDPrefix}
          onResultItemPress={onResultItemPress}
          containerSx={(theme) => ({
            position: 'absolute',
            width: '100%',
            // Positioned below input
            top: theme.sizes.inputRow.minHeight - theme.borderWidths.$1,
            borderBottomLeftRadius: '$input',
            borderBottomRightRadius: '$input',
            borderWidth: '$1',
            borderColor: '$border',
            minHeight: theme.sizes.inputRow.minHeight,
            // TODO(uiv2): use a fixed height instead of calculating,
            //             need to confirm with chloe the exact numbers
            maxHeight:
              // Height of the options is input row height - padding of 10px
              (theme.sizes.inputRow.minHeight - theme.space.$10) *
              (MAX_PICKER_ITEM_SHOWN_AT_ONCE + (allowUserInput ? 1 : 0)),
            backgroundColor: '$inputBackground',
            boxShadow: 'dropShadow',
          })}
          contentSx={{
            px: '$16',
            justifyContent: 'center',
          }}
        />
      </InputRow>
    );
  },
);
TextPickerInputBase.displayName = 'TextPickerInputV2';

const onMouseProp = isWeb
  ? {
      // eslint-disable-next-line
      // @ts-ignore - onMouseDown is forwarded to View, but not exposed in Pressable
      onMouseDown: (event) => {
        event.preventDefault();
      },
    }
  : null;

function SuggestionsResult({
  containerSx,
  contentSx,
  searchResult,
  showResults,
  allowUserInput,
  currentValue,
  onResultItemPress,
  suggestionsTestIDPrefix,
  userInputtedItemTestID,
}: {
  containerSx?: SxProp;
  contentSx?: SxProp;
  searchResult: PickerOptions<string>;
  showResults: boolean;
  allowUserInput?: boolean;
  currentValue: string;
  suggestionsTestIDPrefix?: string;
  userInputtedItemTestID?: string;

  onResultItemPress: (data: PickerOption<string>) => void;
}) {
  const Results = useMemo(() => {
    if (searchResult.length === 0) {
      return (
        <View
          sx={() => ({
            backgroundColor: '$inputBackground',
            justifyContent: 'center',
            py: '$14',
          })}
          focusable={false}
        >
          <Text variant="caption">
            {t('Content.Common.Placeholder.NoResultsFound')}
          </Text>
        </View>
      );
    }

    return (
      <>
        {searchResult.map((data, index, results) => {
          const last = isLast(results, index);

          return (
            <Pressable
              key={data.value}
              onPress={() => {
                onResultItemPress(data);
              }}
              // This is here to prevent blur event when selecting the item.
              // We'll call blur imperatively after we set the values
              {...onMouseProp}
              testID={makeTestId([suggestionsTestIDPrefix, data.value])}
              role={OPTION_ACCESSBILITY_ROLE}
            >
              <View
                sx={{
                  justifyContent: 'center',
                  ...(last && !allowUserInput
                    ? undefined
                    : {
                        borderBottomWidth: '$1',
                        borderBottomColor: '$border',
                      }),
                  py: '$10',
                }}
              >
                <Text variant="default">{data.label}</Text>
              </View>
            </Pressable>
          );
        })}
      </>
    );
  }, [
    allowUserInput,
    onResultItemPress,
    searchResult,
    suggestionsTestIDPrefix,
  ]);

  const AddUserValue = useMemo(
    () => (
      <Pressable
        onPress={() =>
          onResultItemPress({ label: currentValue, value: currentValue })
        }
        // This is here to prevent blur event when selecting the item.
        // We'll call blur imperatively after we set the values
        {...onMouseProp}
        focusable={false}
        testID={userInputtedItemTestID}
        role={OPTION_ACCESSBILITY_ROLE}
      >
        <View
          sx={{
            justifyContent: 'center',
            borderBottomStartRadius: '$input',
            borderBottomEndRadius: '$input',
            py: '$10',
          }}
        >
          <Text variant="emphasis">
            {t('Content.Common.ButtonLabel.UseX', {
              x: currentValue,
            })}
          </Text>
        </View>
      </Pressable>
    ),
    [currentValue, onResultItemPress, userInputtedItemTestID],
  );

  if (!showResults) {
    return null;
  }

  return (
    <ScrollView
      sx={containerSx}
      contentContainerSx={contentSx}
      role={LISTBOX_ACCESSBILITY_ROLE}
    >
      {Results}
      {allowUserInput ? AddUserValue : null}
    </ScrollView>
  );
}

export type TextPickerInputPropsForFormik = Omit<
  TextPickerInputProps,
  | 'onChange'
  | 'onChangeSearchText'
  | 'onSuggestionSelect'
  | 'setFieldValue'
  | 'setFieldTouched'
> &
  Omit<
    FormikControlProps<TextPickerInputValue>,
    'error' | 'setFieldValue' | 'setFieldTouched'
  > & {
    // This formik is injected by `connect` from formik
    formik?: FormikContextType<unknown>;
    error?: string;
    // Internally, Formik return Promise
    // for setFieldValue and setFieldTouched
    // https://github.com/jaredpalmer/formik/issues/2457#issuecomment-1227803618
    setFieldValue?(
      value: TextPickerInputValue,
      validate?: boolean,
    ): Promise<void>;
    setFieldTouched?(isTouched?: boolean, validate?: boolean): Promise<void>;
  };

export const FormikAwareTextPickerInput = forwardRef<
  RNTextInput,
  TextPickerInputPropsForFormik
>(
  (
    {
      // From formik
      formik,
      setFieldValue,
      setFieldTouched,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      value,
      ...otherProps
    },
    ref,
  ) => {
    const hasChangedSearchText = useRef(false);

    const onBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        // Only set field as touched if user has typed in the input before
        // This effectively make the input to validate only after user
        // has done editing the field
        if (hasChangedSearchText.current) {
          setFieldTouched?.(true);
        }
        onBlurProp?.(event);
      },
      [onBlurProp, setFieldTouched],
    );

    const onFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocusProp?.(event);
      },
      [onFocusProp],
    );

    const onChangeSearchText = useCallback(
      async (searchText: string) => {
        // set pseudo touched
        if (searchText && !hasChangedSearchText.current) {
          hasChangedSearchText.current = true;
        }
        // Reset field value on change to make sure user select another option
        if (value) {
          await setFieldValue?.(null);
        }
      },
      [setFieldValue, value],
    );

    const onSuggestionSelect = useCallback(
      async (data: TextPickerInputValue) => {
        await setFieldValue?.(data);
      },
      [setFieldValue],
    );

    return (
      <TextPickerInputBase
        ref={ref}
        onSuggestionSelect={onSuggestionSelect}
        onChangeSearchText={onChangeSearchText}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        {...otherProps}
      />
    );
  },
);
