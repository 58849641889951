import { StyledImage } from '../../ui/atoms/StyledImage';

type Props = {
  name: LoanApplicationEmptyStateIllustrationType;
  testID?: string;
};

const EmptyStateIllustrationMap = {
  debtsV2: require('../../../assets/loanApplicationEmptyStateIllustration/debtsV2.png'),
  expense: require('../../../assets/loanApplicationEmptyStateIllustration/expense.png'),
  expensesV2: require('../../../assets/loanApplicationEmptyStateIllustration/expensesV2.png'),
  income: require('../../../assets/loanApplicationEmptyStateIllustration/income.png'),
  incomeV2: require('../../../assets/loanApplicationEmptyStateIllustration/incomeV2.png'),
  borrower: require('../../../assets/loanApplicationEmptyStateIllustration/borrower.png'),
  borrowerV2: require('../../../assets/loanApplicationEmptyStateIllustration/borrowerV2.png'),
  loan: require('../../../assets/loanApplicationEmptyStateIllustration/loan.png'),
  loanV2: require('../../../assets/loanApplicationEmptyStateIllustration/loanV2.png'),
  property: require('../../../assets/loanApplicationEmptyStateIllustration/property.png'),
  propertyV2: require('../../../assets/loanApplicationEmptyStateIllustration/propertyV2.png'),
  bank: require('../../../assets/loanApplicationEmptyStateIllustration/bank.png'),
};

type LoanApplicationEmptyStateIllustrationType =
  keyof typeof EmptyStateIllustrationMap;

const IllustrationSize = {
  width: 300,
  height: 200,
};
export function EmptyStateIllustration({ name, testID }: Props) {
  return (
    <StyledImage
      source={EmptyStateIllustrationMap[name]}
      {...IllustrationSize}
      contentFit="contain"
      testID={testID}
    />
  );
}
