import { useCallback, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Change_Personal_Details_Error_Type,
  refetchGetMeQuery,
  useEmailRequestResendOtpMutation,
  useVerifyEmailChangeRequestMutation,
} from '../../generated/graphql';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { BaseMobileVerify } from '../components/BaseMobileVerify';
import { PersonalDetailsModalScreenProps } from '../navigation/types';
import getErrorMessage from '../utils/getErrorMessage';

type EmailChangeNewEmailVerifyProps =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY>;

export const EmailChangeNewEmailVerify = ({
  navigation,
  route,
}: EmailChangeNewEmailVerifyProps) => {
  const { auth0MfaSessionId, emailChangeRequestId, newEmail } = route.params;

  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    verifyNewEmail,
    { loading: verifyNewEmailLoading, error: verifyNewEmailError },
  ] = useVerifyEmailChangeRequestMutation();

  const [resendOtp, { loading: resendOtpLoading, error: resendOtpError }] =
    useEmailRequestResendOtpMutation();

  const onSubmit = useCallback(
    async ({ code }: { code: string }) => {
      setValidationErrorMessage(undefined);
      setErrorMessage(undefined);

      const [response] = await safelyCallMutation(verifyNewEmail, {
        variables: {
          email_change_request_id: emailChangeRequestId,
          otp: code,
        },
        refetchQueries: [refetchGetMeQuery()],
        context: {
          sentryContext: {
            description: 'Email Change Request - Verify New Email',
          },
        },
      });

      const errorType = response?.data?.verify_email_change_request.error_type;
      if (errorType) {
        const message = getErrorMessage(errorType, 'email');

        if (errorType === Change_Personal_Details_Error_Type.SystemError) {
          setErrorMessage(message);
          return;
        }

        setValidationErrorMessage(message);
        return;
      }

      if (!response?.data?.verify_email_change_request.success) {
        const message = t(
          'Content.PersonalDetails.ChangeEmail.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
        message: t('Content.PersonalDetails.ChangeEmail.EmailUpdated'),
        supportingText: t(
          'Content.PersonalDetails.ChangeEmail.EmailUpdatedSubText',
        ),
        includeCloseButton: true,
      });
    },
    [
      verifyNewEmail,
      emailChangeRequestId,
      navigation,
      setValidationErrorMessage,
      setErrorMessage,
    ],
  );

  const onPressResend = useCallback(async () => {
    setValidationErrorMessage(undefined);
    setErrorMessage(undefined);

    const [response] = await safelyCallMutation(resendOtp, {
      variables: {
        email_change_request_id: emailChangeRequestId,
      },
      context: {
        sentryContext: {
          auth0MfaSessionId,
          description: 'Email Change Request(Resend Code) - Verify New Email',
        },
      },
    });

    const errorType = response?.data?.email_request_resend_otp?.error_type;

    if (errorType || !response?.data?.email_request_resend_otp?.error_type) {
      const message = t('Content.PersonalDetails.Common.ResendFailed');
      setErrorMessage(message);
    }
  }, [
    resendOtp,
    emailChangeRequestId,
    auth0MfaSessionId,
    setValidationErrorMessage,
    setErrorMessage,
  ]);

  return (
    <BaseMobileVerify
      screen={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL_VERIFY}
      title={t('Content.PersonalDetails.ChangeEmail.VerifyNewEmailTitle')}
      description={t('Content.PersonalDetails.Common.VerifyEmailContent')}
      loading={verifyNewEmailLoading}
      testID={TestID.PersonalDetails.ChangeEmailVerifyNewEmail}
      target={newEmail}
      navigation={navigation}
      onSubmit={onSubmit}
      onPressResend={!resendOtpLoading ? onPressResend : undefined}
      inputValidationError={validationErrorMessage}
      errorMessage={
        verifyNewEmailError || resendOtpError
          ? t('Content.PersonalDetails.ChangeEmail.UnableToProcessRequest')
          : errorMessage
      }
    />
  );
};
