import { Text } from 'dripsy';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { StyledImage } from '../../ui/atoms/StyledImage';
import { StyledText } from '../../ui/atoms/StyledText';
import { AppTerms } from './AppTerms';
import { AuthScreenContainer } from './AuthScreenContainer';

export const SessionExpired = ({
  loading,
  onPressResume,
}: {
  loading?: boolean;
  onPressResume: () => void;
}) => (
  <AuthScreenContainer scrollable>
    <Box flex={1} alignItems="center" justifyContent="center">
      <Box
        position="relative"
        minHeight={150}
        maxWidth={150}
        width="100%"
        mb="l"
      >
        <TouchableOpacity
          onPress={onPressResume}
          style={StyleSheet.absoluteFill}
        >
          <StyledImage
            width="100%"
            height="100%"
            contentFit="cover"
            source={require('../../../assets/session-locked.svg')}
          />
        </TouchableOpacity>
      </Box>
      <Text variant="xlHeader" style={{ textAlign: 'center' }}>
        {t('Content.Auth.SessionLockedHeading')}
      </Text>
      <StyledText maxWidth={446} my="m" variant="body" textAlign="center">
        {t('Content.Auth.SessionLockedMessage')}
      </StyledText>
      <Box maxWidth={446} mx="m" mt="m">
        <AppTerms />
      </Box>
      <Box maxWidth={446} width="100%" px="m">
        <Button
          width="100%"
          testID={TestID.Auth.Resume}
          label={t('Content.Auth.ResumeButton')}
          disabled={loading}
          showSpinner={loading}
          onPress={onPressResume}
          mb="m"
        />
      </Box>
    </Box>
  </AuthScreenContainer>
);
