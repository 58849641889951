import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { SvgIconProps } from './types';

export const SearchIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <Svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <Path
      d="M7.02637 13.7466C8.4126 13.7466 9.69092 13.2983 10.7368 12.5513L14.6714 16.4858C14.854 16.6685 15.0947 16.7598 15.3521 16.7598C15.8916 16.7598 16.2734 16.3447 16.2734 15.8135C16.2734 15.5645 16.1904 15.332 16.0078 15.1494L12.0981 11.2314C12.9199 10.1523 13.4097 8.81592 13.4097 7.36328C13.4097 3.85205 10.5376 0.97998 7.02637 0.97998C3.51514 0.97998 0.643066 3.85205 0.643066 7.36328C0.643066 10.8745 3.51514 13.7466 7.02637 13.7466ZM7.02637 12.3687C4.28711 12.3687 2.021 10.1025 2.021 7.36328C2.021 4.62402 4.28711 2.35791 7.02637 2.35791C9.76562 2.35791 12.0317 4.62402 12.0317 7.36328C12.0317 10.1025 9.76562 12.3687 7.02637 12.3687Z"
      fill="#7F7F84"
    />
  </Svg>
);
