import { Box } from '../../ui/atoms/Box';
import { Card } from '../../ui/atoms/Card';
import {
  Props as StyledIconProps,
  StyledIcon,
} from '../../ui/atoms/StyledIcon';
import { StyledText } from '../../ui/atoms/StyledText';
import { safelyFormatDate } from '../../utils/dateHelpers';
import { formatBytes } from '../../utils/stringHelpers';

export type DocumentIconType = 'document' | 'image';

type Props = {
  onPress?: () => void;
  iconType: DocumentIconType;
  name: string;
  createdAt: string;
  sizeInBytes: number;
};

function mapIconTypeToIconName(
  iconType: DocumentIconType,
): StyledIconProps['name'] {
  switch (iconType) {
    case 'document':
      return 'document-text';
    case 'image':
      return 'image';
    default:
      return 'document-text';
  }
}

const DocumentDateFormat = 'd MMM yyyy';

export function DocumentRow({
  name,
  onPress,
  iconType,
  createdAt,
  sizeInBytes,
}: Props) {
  return (
    <Card
      flex={1}
      borderRadius="row"
      p="m"
      py="s"
      flexDirection="row"
      alignItems="center"
      bg="bg"
      onPress={onPress}
      px="m"
    >
      <Box
        width={36}
        height={36}
        bg="buttonSecondaryBg"
        mr="s"
        borderRadius="tag"
        centered
      >
        {/* TODO: use icon from figma instead of ionicons */}
        <StyledIcon name={mapIconTypeToIconName(iconType)} />
      </Box>
      <Box flexDirection="column" flex={1}>
        <StyledText variant="button" numberOfLines={1} lineBreakMode="middle">
          {name}
        </StyledText>
        <StyledText variant="caption">
          {`${safelyFormatDate(createdAt, DocumentDateFormat)} - ${formatBytes(
            sizeInBytes,
          )}`}
        </StyledText>
      </Box>
    </Card>
  );
}
