import { gql } from '@apollo/client';
import { useMemo } from 'react';

import {
  Credit_Check_State_Enum,
  CreditCheckForCompletenessCheckFragment,
  useLoanApplicationCreditCheckStatesSubscription,
} from '../../generated/graphql';
import { isNotNullOrUndefined } from '../../utils/arrayHelpers';
import useTimeout from '../../utils/hooks/useTimeout';

export const LOAN_APPLICATION_CREDIT_CHECK_STATES_SUBSCRIPTION = gql`
  subscription LoanApplicationCreditCheckStates($loanApplicationId: uuid!) {
    applicants: applicant(
      where: { loan_application_id: { _eq: $loanApplicationId } }
    ) {
      id
      user_identity_profile {
        id
        credit_checks(order_by: { created_at: desc }, limit: 1) {
          ...CreditCheckForCompletenessCheck
        }
      }
    }
  }

  fragment CreditCheckForCompletenessCheck on credit_check {
    id
    state
  }
`;

function isCreditCheckFinished(
  creditCheck: CreditCheckForCompletenessCheckFragment,
) {
  return (
    creditCheck.state === Credit_Check_State_Enum.Completed ||
    creditCheck.state === Credit_Check_State_Enum.Failed ||
    creditCheck.state === Credit_Check_State_Enum.TimedOut
  );
}

const CREDIT_CHECKS_SUBSCRIPTION_TIMEOUT = 30e3;
export function useCompletedCreditChecksQuery({
  loanApplicationId,
  skipCCRCompletenessCheck,
}: {
  loanApplicationId: string | undefined;
  skipCCRCompletenessCheck: boolean;
}) {
  const { hasTimedout } = useTimeout(CREDIT_CHECKS_SUBSCRIPTION_TIMEOUT);
  const {
    data,
    loading: subscriptionLoading,
    error,
  } = useLoanApplicationCreditCheckStatesSubscription({
    variables: { loanApplicationId: loanApplicationId || '' },
    skip: skipCCRCompletenessCheck || !loanApplicationId || hasTimedout,
  });

  const { isAllCreditCheckFinished } = useMemo(() => {
    const checks = (
      data?.applicants.map(
        (appli) => appli.user_identity_profile?.credit_checks?.[0],
      ) || []
    ).filter(isNotNullOrUndefined);

    return {
      creditChecks: checks,
      // If the applicants doesnt have any credit check, treat it as finished
      // and to manual flow.
      isAllCreditCheckFinished: checks.every(isCreditCheckFinished),
    };
  }, [data]);

  /**
   * Loading flag will be used to display the "Detecting..." spinner.
   * Display spinner while these conditions fulfilled and has not timed out:
   * - loading initial data from subscription
   * - loaded data has credit checks in unfinished state (e.g. pending, in progress, etc.)
   * Otherwise, if the check has timed out, stop loading.
   */
  const loading = hasTimedout
    ? false
    : subscriptionLoading || !isAllCreditCheckFinished;

  return {
    error,
    loading,
  };
}
