import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function AccountClosureHomeLoanIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={36} height={37} fill="none" {...props}>
      <Path
        fill="url(#a)"
        fillRule="evenodd"
        d="M4.835 27.865V15.353L16.5 4.16l11.666 11.194V29.84c0 .668-.49.664-1.124.66l-.172-.002H6.13l-.172.001c-.635.005-1.124.01-1.124-.66v-1.974Z"
        clipRule="evenodd"
      />
      <Path
        fill="#2C2C2C"
        d="M16.5 4.817c-.307 0-.614.11-.86.331l-3.472 3.168-7.334 7.037v3.55L16.5 8.39l11.666 10.511v-3.549l-7.333-7.037-3.473-3.168a1.282 1.282 0 0 0-.86-.33Z"
        opacity={0.05}
      />
      <Path
        fill="#2C2C2C"
        d="M16.5 4.159c-.307 0-.614.11-.86.329l-3.472 3.15-7.334 6.997v3.529L16.5 7.712l11.666 10.452v-3.53l-7.333-6.996-3.473-3.15a1.286 1.286 0 0 0-.86-.33Z"
        opacity={0.07}
      />
      <Path
        fill="#A3A3A3"
        d="M29.783 15.028 17.36 3.831a1.281 1.281 0 0 0-1.72 0L3.218 15.028a.665.665 0 0 0-.041.942l1.06 1.15c.239.259.638.278.9.043L16.5 7.074l11.364 10.09a.641.641 0 0 0 .9-.044l1.06-1.15a.666.666 0 0 0-.04-.942Z"
      />
      <Path
        fill="#676767"
        d="M13.992 20.061c-.598 0-1.08-.197-1.444-.592-.366-.395-.548-.926-.548-1.594 0-.729.194-1.307.583-1.734.388-.427.907-.641 1.555-.641.621 0 1.107.193 1.456.58.35.387.525.93.525 1.63 0 .72-.194 1.293-.58 1.716-.387.423-.903.635-1.547.635Zm5.953-4.44a.1.1 0 0 1 .085.154l-5.347 8.556a.1.1 0 0 1-.085.047h-1.193a.1.1 0 0 1-.085-.153l5.335-8.556a.1.1 0 0 1 .085-.047h1.205Zm-5.842.953c-.57 0-.856.413-.856 1.24 0 .782.272 1.172.815 1.172.554 0 .832-.407.832-1.22 0-.795-.264-1.192-.791-1.192Zm5.12 7.926c-.597 0-1.079-.197-1.444-.592-.365-.395-.547-.926-.547-1.594 0-.729.194-1.307.582-1.734.389-.427.908-.641 1.556-.641.625 0 1.111.191 1.459.574.347.383.521.924.521 1.624 0 .72-.193 1.295-.58 1.723-.386.427-.902.64-1.547.64Zm.094-3.486c-.566 0-.85.413-.85 1.24 0 .78.276 1.172.827 1.172.554 0 .832-.407.832-1.222 0-.378-.075-.671-.223-.879a.688.688 0 0 0-.586-.311Z"
      />
      <Path fill="#EFEFEF" d="M33 27.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" />
      <Path
        fill="#353535"
        d="M27 21.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm.707 6 1.646 1.646a.5.5 0 1 1-.707.707L27 28.207l-1.646 1.646a.5.5 0 1 1-.707-.707l1.646-1.646-1.646-1.646a.5.5 0 1 1 .707-.707L27 26.793l1.646-1.646a.5.5 0 1 1 .707.707L27.707 27.5Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={4.835}
          x2={30.983}
          y1={4.159}
          y2={27.32}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D8D8D8" />
          <Stop offset={1} stopColor="#C4C4C4" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
