import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

import { SvgIconProps } from './types';

export function AccountClosurePersonalLoanIcon({
  size,
  color,
  ...props
}: SvgIconProps) {
  return (
    <Svg width={36} height={37} fill="none" {...props}>
      <Path
        fill="#676767"
        d="M28.5 22.1H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="url(#a)"
        d="M28.5 19.7H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="#676767"
        d="M28.5 17.3H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="url(#b)"
        d="M28.5 14.9H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="#676767"
        d="M28.5 12.5H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="url(#c)"
        d="M28.5 10.1H14.1v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="#C6C6C6"
        d="M21.3 13.7c3.977 0 7.2-1.612 7.2-3.6s-3.223-3.6-7.2-3.6c-3.976 0-7.2 1.612-7.2 3.6s3.224 3.6 7.2 3.6Z"
      />
      <Path
        fill="#2C2C2C"
        d="M14.1 15.087V24.5c0 1.745 2.482 3.199 5.777 3.53.141-.361.223-.738.223-1.13v-7.2c0-2.255-2.456-4.05-6-4.613Z"
        opacity={0.08}
      />
      <Path
        fill="#2C2C2C"
        d="M14.1 15.697V24.5c0 1.633 2.175 3.009 5.154 3.45.155-.335.246-.685.246-1.05v-7.2c0-1.902-2.236-3.47-5.4-4.003Z"
        opacity={0.08}
      />
      <Path
        fill="url(#d)"
        d="M18.9 24.5H4.5v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="#676767"
        d="M18.9 22.1H4.5v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="url(#e)"
        d="M18.9 19.7H4.5v2.4c0 1.988 3.224 3.6 7.2 3.6s7.2-1.612 7.2-3.6v-2.4Z"
      />
      <Path
        fill="url(#f)"
        d="M11.7 23.3c3.977 0 7.2-1.612 7.2-3.6s-3.223-3.6-7.2-3.6c-3.976 0-7.2 1.612-7.2 3.6s3.224 3.6 7.2 3.6Z"
      />
      <Path
        fill="#C6C6C6"
        d="M11.7 23.3c3.977 0 7.2-1.612 7.2-3.6s-3.223-3.6-7.2-3.6c-3.976 0-7.2 1.612-7.2 3.6s3.224 3.6 7.2 3.6Z"
      />
      <Path fill="#EFEFEF" d="M33 27.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" />
      <Path
        fill="#353535"
        d="M27 21.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm.707 6 1.646 1.646a.5.5 0 1 1-.707.707L27 28.207l-1.646 1.646a.5.5 0 1 1-.707-.707l1.646-1.646-1.646-1.646a.5.5 0 1 1 .707-.707L27 26.793l1.646-1.646a.5.5 0 1 1 .707.707L27.707 27.5Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={14.1}
          x2={18.361}
          y1={19.7}
          y2={29.925}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={14.1}
          x2={18.361}
          y1={14.9}
          y2={25.125}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={14.1}
          x2={18.361}
          y1={10.1}
          y2={20.325}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="d"
          x1={4.5}
          x2={8.76}
          y1={24.5}
          y2={34.725}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="e"
          x1={4.5}
          x2={8.76}
          y1={19.7}
          y2={29.925}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset={1} stopColor="#D8D8D8" />
        </LinearGradient>
        <LinearGradient
          id="f"
          x1={7.775}
          x2={15.71}
          y1={15.775}
          y2={23.71}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FEDE00" />
          <Stop offset={1} stopColor="#FFD000" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
