import { Text } from 'dripsy';

import { Box } from '../../ui/atoms/Box';
import { InputRow } from '../../ui/v2/InputRow';
import { LoadingInput } from '../../ui/v2/LoadingInput';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';

export type UnloanAccountInputRowProps = {
  loading?: boolean;
  accountName: string;
  bsb: string;
  accountNumber: string;
};

export function UnloanAccountInputRow({
  loading,
  accountName,
  bsb,
  accountNumber,
}: UnloanAccountInputRowProps) {
  if (loading) {
    return <LoadingInput />;
  }

  return (
    <InputRow
      sx={{
        py: '$7',
        px: '$16',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box column>
        <Text variant="sBody" sx={{ fontWeight: 'medium' }}>
          {accountName}
        </Text>
        <Text variant="caption">
          {formatBsbAndAccountNumber(bsb, accountNumber)}
        </Text>
      </Box>
    </InputRow>
  );
}
