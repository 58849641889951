import { TestID } from '../../testID/constants';
import { Liability_Type_Enum } from '../generated/graphql';
import { NewListRow, NewListRowProps } from '../ui/molecules/NewListRow';
import { SVG_ICON_LIST } from '../ui/svgs/svgIconList';
import { Color } from '../ui/types';
import { Props as FinancialsRowProps } from './FinancialsRow';
import {
  formatLiabilityLabel,
  formatLiabilitySublabels,
} from './utils/formatLiabilityRow';

export type LiabilityRowProps = Omit<
  FinancialsRowProps,
  'label' | 'iconName'
> & {
  institutionName?: string | null;
  liabilityType: Liability_Type_Enum;
  balance?: number | null;
  limit?: number | null;
  address?: string | null;
  owners: string;
  isFlaggedIncorrect?: boolean;
  forRefinancing?: boolean;
  showAlertIcon?: boolean;
  error?: string | null;
  onPress: () => void;
  forV2?: boolean;
};

export const DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE: Partial<
  Record<Liability_Type_Enum, keyof typeof SVG_ICON_LIST>
> = {
  HOME_LOAN: 'yourLiabilityHomeLoan',

  PERSONAL: 'yourLiabilityPersonalLoan',
  MARGIN_OR_INVESTMENT_LOAN: 'yourLiabilityPersonalLoan',
  PERSONAL_OVERDRAFT: 'yourLiabilityPersonalLoan',
  BNPL: 'yourLiabilityPersonalLoan',
  NOVATED_LEASE: 'yourLiabilityPersonalLoan',
  HECS_HELP: 'yourLiabilityPersonalLoan',
  OTHER: 'yourLiabilityPersonalLoan',

  CREDIT_CARD: 'yourLiabilityCreditCard',
  STORE_CARD: 'yourLiabilityCreditCard',
  LINE_OF_CREDIT: 'yourLiabilityCreditCard',
  CHARGE_CARD: 'yourLiabilityCreditCard',
};

type FlaggedIncorrectStyle = {
  titleColor: Color;
  captionColor: Color;
  subcaptionColor: Color;
  strikethroughTexts: boolean;
};

type ShowAlertStyle = {
  rightIconColor: NewListRowProps['rightIconColor'];
  rightIconName: NewListRowProps['rightIconName'];
};

type ErrorStyle = {
  subcaptionColor: Color;
};

export function YourLiabilityRow({
  institutionName,
  liabilityType,
  balance,
  limit,
  owners,
  onPress,
  isFlaggedIncorrect,
  showAlertIcon,
  error,
  address,
  testID,
  forV2,
}: LiabilityRowProps) {
  const flaggedIncorrectStyle: FlaggedIncorrectStyle = {
    titleColor: 'disabledContent',
    captionColor: 'disabledContent',
    subcaptionColor: 'disabledContent',
    strikethroughTexts: true,
  };

  const showAlertStyle: ShowAlertStyle = {
    rightIconColor: 'error',
    rightIconName: 'alert-circle',
  };

  const errorStyle: ErrorStyle = {
    subcaptionColor: 'error',
  };

  return (
    <NewListRow
      testID={testID}
      onRowPress={onPress}
      title={formatLiabilityLabel({ institutionName, liabilityType })}
      caption={
        error
          ? owners
          : formatLiabilitySublabels({
              balance,
              limit,
              liabilityType,
              address,
            }).join('\n')
      }
      subcaption={error ?? owners}
      showArrow
      arrowTestId={TestID.CheckMark}
      leftIconName={DEBTS_AND_LIABILITIES_ICON_NAME_BY_TYPE[liabilityType]}
      leftIconFamily="svg"
      {...(isFlaggedIncorrect ? flaggedIncorrectStyle : {})}
      {...(showAlertIcon ? showAlertStyle : {})}
      {...(error ? errorStyle : {})}
      shadowed={false}
      {...(forV2 ? { my: 'xs' } : null)}
    />
  );
}
