import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { LoanApplicationV2StackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { useJoinApplication } from '../../utils/JoinApplicationInvite';

function getInitialRouteNameBasedOnLoanApplicationType(
  loanApplicationType: Loan_Application_Type_Enum,
) {
  switch (loanApplicationType) {
    case Loan_Application_Type_Enum.Purchase: {
      return Screen.LOAN_APPLICATION_PURCHASE_BORROWERS;
    }
    case Loan_Application_Type_Enum.TopUp:
      return Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY;
    case Loan_Application_Type_Enum.Refinance:
    default: {
      return Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY;
    }
  }
}

export function useCoborrowerLoanApplicationFlowV2(): {
  initialRouteName: keyof LoanApplicationV2StackParams | undefined;
  initialParams: { loanApplicationId: string | undefined } | undefined;
} {
  const joinLoanApplication = useJoinApplication();

  const { loanApplicationId, loanApplicationType } = joinLoanApplication;
  if (loanApplicationId && loanApplicationType) {
    const initialRouteName =
      getInitialRouteNameBasedOnLoanApplicationType(loanApplicationType);
    return {
      initialRouteName,
      initialParams: {
        loanApplicationId: joinLoanApplication.loanApplicationId,
      },
    };
  }
  return {
    initialRouteName: undefined,
    initialParams: undefined,
  };
}
