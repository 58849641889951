import { gql } from '@apollo/client';
import { format } from 'date-fns';
import { useDripsyTheme, View } from 'dripsy';
import { Fragment, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { TestID } from '../../../testID/constants';
import { withAuthenticationRequired } from '../../Auth/withAuthenticationRequired';
import ScheduledMaintenance from '../../components/ScheduledMaintenance';
import { DEFAULT_DATE_DISPLAY_FORMAT } from '../../constants/dateFormats';
import {
  Approval_Status_Enum,
  Payment_Request_Status_Enum,
  useApproveRequestMutation,
  useCancelRequestMutation,
  useDeclineRequestMutation,
  usePendingTransactionSubscription,
} from '../../generated/graphql';
import { HomeLoanScreenProps } from '../../HomeLoan/navigation/types';
import { useGetLoanAccountName } from '../../HomeLoan/utils/useGetLoanAccountName';
import { ActionSheetType, Screen } from '../../navigation/types/screens';
import { Box } from '../../ui/atoms/Box';
import { Button } from '../../ui/atoms/Button';
import { Separator } from '../../ui/atoms/Separator';
import { StyledText } from '../../ui/atoms/StyledText';
import {
  DataRow,
  DataRowGroup,
  DataRowSeparator,
} from '../../ui/molecules/DataRow';
import { LoadingRow } from '../../ui/molecules/LoadingRow';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { Skeleton } from '../../ui/v2/Skeleton';
import { formatCurrency } from '../../utils/currencyHelpers';
import { formatBsbAndAccountNumber } from '../../utils/formatBsbAndAccountNumber';
import { notNil } from '../../utils/typesHelpers';
import { underMaintenanceAtom } from '../atoms/underMaintenanceAtom';
import { INLIFE_PAYMENT_REQUEST_FRAGMENT } from '../graphql/fragments';
import { shouldShowApproveDeclineButtons } from '../utils/shouldShowApproveDeclineButtons';

export type PendingTransactionProps =
  HomeLoanScreenProps<Screen.HOME_PENDING_TRANSACTION>;

export const PENDING_TRANSACTION_QUERY = gql`
  subscription PendingTransaction($payment_request_id: uuid!) {
    payment_request_by_pk(id: $payment_request_id) {
      ...PaymentRequest
    }
  }

  ${INLIFE_PAYMENT_REQUEST_FRAGMENT}
`;

const getLabelForResponseStatus = (status: Approval_Status_Enum) => {
  switch (status) {
    case Approval_Status_Enum.Approved:
      return t('Content.TransactionList.Approved');
    case Approval_Status_Enum.Declined:
      return t('Content.TransactionList.Declined');
    default:
      return '--';
  }
};

type PendingTransactionActionButtonsProps = {
  loading?: boolean;
  overallApprovalStatus: Approval_Status_Enum;
  myResponseStatus: Approval_Status_Enum;
  onPressApprove: () => unknown;
  onPressDecline: () => unknown;
  onPressCancel: () => unknown;
  approveLoading: boolean;
  declineLoading: boolean;
  cancelLoading: boolean;
};

const PendingTransactionActionButtons = ({
  loading,
  overallApprovalStatus,
  myResponseStatus,
  onPressApprove,
  onPressDecline,
  onPressCancel,
  approveLoading,
  declineLoading,
  cancelLoading,
}: PendingTransactionActionButtonsProps) => {
  const isAppUnderMaintenance = useRecoilValue(underMaintenanceAtom);

  const showCancelButton =
    overallApprovalStatus === Approval_Status_Enum.Pending &&
    myResponseStatus === Approval_Status_Enum.Approved;

  if (showCancelButton) {
    return (
      <Box mt="l" opacity={loading ? 0 : undefined}>
        <Button
          testID={TestID.PendingTransaction.CancelButton}
          secondary
          label={t('Content.Approvals.CancelTransaction')}
          onPress={onPressCancel}
          disabled={loading || cancelLoading}
          showSpinner={cancelLoading}
          width="100%"
          style={{ maxWidth: '100%' }}
        />
      </Box>
    );
  }

  const showApproveDeclineButtons = shouldShowApproveDeclineButtons({
    overallApprovalStatus,
    myResponseStatus,
  });

  if (showApproveDeclineButtons) {
    return (
      <Box
        flexDirection="column"
        flexGrow={1}
        opacity={loading ? 0 : undefined}
      >
        <ScheduledMaintenance mt="m" />
        <Box
          mt="l"
          flexDirection="row"
          bottom={0}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Button
            testID={TestID.PendingTransaction.DeclineButton}
            secondary
            label={t('Content.Approvals.Decline')}
            color="error"
            flexGrow={1}
            mr="s"
            onPress={onPressDecline}
            showSpinner={declineLoading}
            disabled={isAppUnderMaintenance || declineLoading}
          />
          <Button
            testID={TestID.PendingTransaction.ApproveButton}
            label={t('Content.Approvals.Approve')}
            onPress={onPressApprove}
            showSpinner={approveLoading}
            flexGrow={1}
            ml="s"
            disabled={isAppUnderMaintenance || approveLoading}
          />
        </Box>
      </Box>
    );
  }

  return null;
};

function FromAccountDetails({ cbaAccountId }: { cbaAccountId: string }) {
  const { data, loading } = useGetLoanAccountName(cbaAccountId);

  const { accountName = '', bsbJoinedWithAccountNumber = '' } = data ?? {};

  return (
    <DataRow
      label="From"
      captionColor={loading ? 'disabledContent' : undefined}
      caption={
        loading ? (
          t('Content.Common.Placeholder.Loading')
        ) : (
          <>
            {accountName}
            <Separator spacer py={0} />
            {bsbJoinedWithAccountNumber}
          </>
        )
      }
    />
  );
}

function PendingTransactionBase({
  navigation,
  route,
}: PendingTransactionProps) {
  const { data: paymentRequestData, loading: requestLoading } =
    usePendingTransactionSubscription({
      variables: {
        payment_request_id: route.params.paymentRequestId,
      },
    });
  const [approve, { loading: approveLoading }] = useApproveRequestMutation();
  const [decline, { loading: declineLoading }] = useDeclineRequestMutation();
  const [cancel, { loading: cancelLoading }] = useCancelRequestMutation();

  const paymentRequest = paymentRequestData?.payment_request_by_pk;

  const withdrawalRequest = paymentRequest?.withdrawal_request;

  const approvalRequest = withdrawalRequest?.approval_request;

  const approvalRequestId = approvalRequest?.id;

  const overallApprovalStatus = approvalRequest?.status;
  const loading =
    requestLoading || approveLoading || declineLoading || cancelLoading;

  const onPressApprove = useCallback(async () => {
    await approve({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });

    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.Withdraw.TransferRequestApproved'),
    });
  }, [approvalRequestId, approve, navigation]);

  const onPressDecline = useCallback(async () => {
    await decline({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });

    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.Withdraw.TransferRequestDeclined'),
    });
  }, [approvalRequestId, decline, navigation]);

  const onPressCancel = useCallback(async () => {
    await cancel({
      variables: {
        approval_request_id: notNil(approvalRequestId, 'approvalRequestId'),
      },
      context: {
        sentryContext: {
          approvalRequestId,
        },
      },
    });

    navigation.replace(ActionSheetType.CONFIRMATION_SUCCESS, {
      message: t('Content.Withdraw.TransferRequestCancelled'),
    });
  }, [approvalRequestId, cancel, navigation]);

  const showWhenAndInitiatedBy =
    overallApprovalStatus === Approval_Status_Enum.Approved;

  const isApproved = overallApprovalStatus === Approval_Status_Enum.Approved;

  const paymentStatus = paymentRequest?.status;

  let paymentStatusLabel = t('Content.Approvals.Processing');
  if (paymentStatus === Payment_Request_Status_Enum.Completed) {
    paymentStatusLabel = t('Content.Approvals.Processed');
  }
  if (paymentStatus === Payment_Request_Status_Enum.Failed) {
    paymentStatusLabel = t('Content.Approvals.Failed');
  }

  let approvalStatusLabel = t('Content.Approvals.PendingApproval');
  if (overallApprovalStatus === Approval_Status_Enum.Approved) {
    approvalStatusLabel = t('Content.Approvals.Approved');
  }
  if (overallApprovalStatus === Approval_Status_Enum.Declined) {
    approvalStatusLabel = t('Content.Approvals.Declined');
  }
  if (overallApprovalStatus === Approval_Status_Enum.Cancelled) {
    approvalStatusLabel = t('Content.Approvals.Cancelled');
  }

  const statusLabel =
    overallApprovalStatus === Approval_Status_Enum.Approved
      ? paymentStatusLabel
      : approvalStatusLabel;

  const externalAccount = withdrawalRequest?.external_account;

  const { theme } = useDripsyTheme();

  return (
    <ModalScreenContainer
      onClose={() => navigation.getParent()?.goBack()}
      scrollable
      hideBackButton
    >
      {loading ? (
        <View
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Skeleton show height={theme.space.$56} width={160} />

          <View sx={{ mt: '$8' }}>
            <Skeleton show height={theme.space.$20} width={100} />
          </View>
        </View>
      ) : (
        <>
          <StyledText textAlign="center" variant="numberLarge">
            {`-${formatCurrency(withdrawalRequest?.amount ?? 0)}`}
          </StyledText>
          <StyledText textAlign="center">Transfer</StyledText>
          <StyledText textAlign="center" color="secondaryContent">
            {withdrawalRequest?.description}
          </StyledText>
        </>
      )}

      <DataRowGroup>
        <DataRow
          loading={loading}
          testID={TestID.PendingTransaction.ApprovalStatus}
          label={t('Content.Approvals.Status')}
          caption={statusLabel}
        />
        {isApproved
          ? null
          : approvalRequest?.responses.map((response) => (
              <Fragment key={response.id}>
                <DataRowSeparator />
                <DataRow
                  label={response.identity_profile?.full_name ?? ''}
                  caption={getLabelForResponseStatus(response.status)}
                />
              </Fragment>
            ))}
      </DataRowGroup>

      {overallApprovalStatus === Approval_Status_Enum.Pending && (
        <StyledText mt="s" variant="caption" color="secondaryContent">
          {t('Content.Approvals.ExpiryWarning')}
        </StyledText>
      )}

      <DataRowGroup>
        {loading ? <LoadingRow /> : null}
        {!loading && paymentRequest?.cba_account_id ? (
          <FromAccountDetails cbaAccountId={paymentRequest?.cba_account_id} />
        ) : null}
        <DataRowSeparator />
        <DataRow
          loading={loading}
          label="To"
          captionTestId={TestID.PendingTransaction.ToAccount}
          caption={
            <>
              {externalAccount?.account_name}

              <Separator spacer py={0} />

              {formatBsbAndAccountNumber(
                externalAccount?.account_bsb ?? '',
                externalAccount?.account_number ?? '',
              )}
            </>
          }
        />

        {showWhenAndInitiatedBy ? (
          <>
            {paymentRequest?.cba_receipt_id ? (
              <>
                <DataRowSeparator />
                <DataRow
                  label={t('Content.Approvals.ReceiptNo')}
                  caption={paymentRequest?.cba_receipt_id ?? ''}
                />
              </>
            ) : null}

            <DataRowSeparator />
            <DataRow
              loading={loading}
              label={t('Content.Approvals.When')}
              caption={
                paymentRequest?.created_at
                  ? format(
                      Date.parse(paymentRequest.created_at),
                      DEFAULT_DATE_DISPLAY_FORMAT,
                    )
                  : ''
              }
            />

            <DataRowSeparator />
            <DataRow
              loading={loading}
              label={t('Content.Approvals.InitiatedBy')}
              caption={
                paymentRequest?.withdrawal_request?.approval_request
                  .requested_by?.full_name ?? ''
              }
            />
          </>
        ) : null}
        {withdrawalRequest?.reference ? (
          <>
            <DataRowSeparator />
            <DataRow
              loading={loading}
              label={t('Content.Withdraw.Reference')}
              caption={withdrawalRequest.reference}
            />
          </>
        ) : null}
      </DataRowGroup>

      {showWhenAndInitiatedBy &&
      (paymentRequest?.withdrawal_request?.approval_request.responses ?? [])
        .length > 1 ? (
        <StyledText color="secondaryContent" fontSize="s" pl="m">
          {t('Content.Approvals.ApprovedBy', {
            approvers:
              paymentRequest?.withdrawal_request?.approval_request.responses
                .map((response) => response.identity_profile?.full_name)
                .join(', '),
          })}
        </StyledText>
      ) : null}

      <PendingTransactionActionButtons
        loading={loading}
        overallApprovalStatus={
          overallApprovalStatus ?? Approval_Status_Enum.Pending
        }
        myResponseStatus={
          approvalRequest?.my_approval?.response?.status ??
          Approval_Status_Enum.Pending
        }
        onPressApprove={onPressApprove}
        approveLoading={approveLoading}
        onPressDecline={onPressDecline}
        declineLoading={declineLoading}
        onPressCancel={onPressCancel}
        cancelLoading={cancelLoading}
      />
    </ModalScreenContainer>
  );
}

export const PendingTransaction = withAuthenticationRequired(
  PendingTransactionBase,
);
