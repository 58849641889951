import {
  isNotNullOrUndefined,
  stringIsNotNullOrEmpty,
} from '@unloan/common-ui';
import regex from 'emoji-regex';
import { startCase, toLower } from 'lodash';
import prettyBytes, { Options as PrettyBytesOptions } from 'pretty-bytes';

import { AppAddressFormat } from '../components/form/types';
import {
  AddressSearchSuggestionFragment,
  Country_Enum,
  HasuraAddressFragment,
  Maybe,
} from '../generated/graphql';
import { captureException } from '../sentry';

const emojiRegex = regex();

export { stringIsNotNullOrEmpty };

export const capitalize = (s: string): string =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const capitalizeSentence = (s: string): string =>
  s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// Format address helper will accept all structures we use
export type FormatAddressInput =
  // 1. Address saved in Hasura
  | HasuraAddressFragment
  // 2. Search result from Domain API
  | AddressSearchSuggestionFragment
  // 3. Address saved in form
  | AppAddressFormat
  | undefined
  | null;

function isDomainAPIAddressSuggestion(
  input: NonNullable<FormatAddressInput>,
): input is AddressSearchSuggestionFragment {
  return 'domain_api_property_id' in input;
}

function isDomainAPIAddressOrAppAddress(
  input: NonNullable<FormatAddressInput>,
): input is AppAddressFormat {
  return (
    ('isProvidedByUser' in input && 'domainApiId' in input) ||
    'displayAddress' in input
  );
}

/**
 * These formatters are temporary workaround until we serve the formatted address from the backend.
 * */
export function formatAddress(input: FormatAddressInput) {
  if (!input) {
    return [t('Content.Common.Placeholder.EnterAddress')] as const;
  }

  if (isDomainAPIAddressSuggestion(input)) {
    const [firstLine = '--', secondLine] =
      input.display_address?.split(',') || [];
    return [firstLine.trim(), secondLine?.trim()] as const;
  }

  if (isDomainAPIAddressOrAppAddress(input)) {
    const [firstLine = '--', secondLine] =
      input.displayAddress?.split(',') || [];
    return [firstLine.trim(), secondLine?.trim()] as const;
  }

  return [t('Content.Common.Placeholder.EnterAddress')] as const;
}

/**
 * @deprecated use formatFullAddress instead
 * */
export const legacyFormatFullAddress = (input: FormatAddressInput): string => {
  if (input == null) {
    return '';
  }

  if (isDomainAPIAddressSuggestion(input)) {
    return input.display_address || '--';
  }

  if (isDomainAPIAddressOrAppAddress(input)) {
    const streetLine = [input.streetNo, input.street, input.streetType]
      .filter(isNotNullOrUndefined)
      .join(' ');
    return [
      streetLine || '--',
      `${titleCase(input.suburb || '')} ${input.state} ${input.postcode}`,
      getCountryName(input.countryCode),
    ]
      .filter(Boolean)
      .join('\n');
  }

  return '';
};

/**
 * We'd want to use display_address without formatting it
 * */
export const formatFullAddress = (input: FormatAddressInput): string => {
  if (input == null) {
    return '';
  }

  if (isDomainAPIAddressSuggestion(input)) {
    return input.display_address || '--';
  }

  if (isDomainAPIAddressOrAppAddress(input)) {
    const streetLine = [input.streetNo, input.street, input.streetType]
      .filter(isNotNullOrUndefined)
      .join(' ');
    return [
      streetLine || '--',
      `${titleCase(input.suburb || '')} ${input.state} ${input.postcode}`,
    ]
      .filter(Boolean)
      .join('\n');
  }

  return '';
};

export function getCountryName(countryCode?: Maybe<string>) {
  if (countryCode === Country_Enum.Au) {
    return 'Australia';
  }
  captureException('No country name for given country code', { countryCode });
  return 'N/A';
}

export const titleCase = (s: string): string => startCase(toLower(s));

// Might need to use pluralize from i18n-js later
export const pluralize = (count: number, noun: string, suffix = 's'): string =>
  `${count != null ? `${count} ` : ''}${noun}${count > 1 ? suffix : ''}`;

// Why is this named as censored credit card number when it's not specific to
// credit card?
export const generateCensoredCreditCardNumber = (val: string): string =>
  `•••• ${val}`;

export const maskAccountNumber = (accountNumber: string) =>
  generateCensoredCreditCardNumber(accountNumber.slice(-4));

export const formatCensoredAccountNumber = (val: string): string =>
  val.replace(/\*/g, '•');
export const removeCensoredAccountNumber = (val: string): string =>
  val.replace(/\*/g, '').trim();

export const concatName = (firstName: string, lastName: string): string =>
  `${firstName} ${lastName}`;

export const formatLoanTerm = (
  termInMonths: number | null | undefined,
  verbose = false,
  comma = true,
): string => {
  if (!termInMonths || termInMonths <= 0) {
    return '--';
  }
  const year = Math.floor(termInMonths / 12);
  const month = termInMonths % 12;

  let formattedYear;
  let formattedMonth;

  if (verbose) {
    // eslint-disable-next-line no-nested-ternary
    formattedYear = year ? (year <= 1 ? `${year} year` : `${year} years`) : '';
    // eslint-disable-next-line no-nested-ternary
    formattedMonth = month
      ? month <= 1
        ? `${month} month`
        : `${month} months`
      : '';
  } else {
    formattedYear = year ? `${year}y` : '';
    formattedMonth = month ? `${month}m` : '';
  }

  return `${formattedYear}${
    formattedYear && formattedMonth
      ? `${
          comma ? ',' : `${' '}${t('Content.Common.Word.and')}`
        } ${formattedMonth}`
      : formattedMonth
  }`;
};

export function joinListOfString(inputs: Array<string>): string {
  const copyInputs = [...inputs];
  if (copyInputs.length > 2) {
    const lastApplicant = copyInputs.pop();
    return `${copyInputs.join(', ')} ${t(
      'Content.Common.Word.and',
    )} ${lastApplicant}`;
  }
  return copyInputs.join(` ${t('Content.Common.Word.and')} `);
}

const bytesFormatOptions: PrettyBytesOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
};
export function formatBytes(bytes: number) {
  return prettyBytes(bytes, bytesFormatOptions);
}

export function replaceSpaceWithSeparator(value: string, separator: string) {
  return value.split(' ').join(separator);
}
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email: string): boolean => emailRegex.test(email);

/**
 * The purpose of this regex is to only allow ASCII characters in between 0 or more whitespace
 * - The string starts and ends with optional spaces.
 * - It should contains one or more printable ASCII characters or a whitespace on the whole string.
 */
const asciiWithWhitespaceRegex = /(^[ ]*([\x21-\x7E] ?)+[ ]*$)/;
/**
 * The purpose of this regex is to only allow ASCII characters
 */
const asciiRegex = /^[\x21-\x7E]*$/;
/**
 * By default, this helper will allow leading and trailing spaces, please trim the value as needed.
 */
export function isASCIICharacter(
  val: string,
  opts: { allowWhitespace: boolean } = {
    allowWhitespace: true,
  },
) {
  const { allowWhitespace } = opts;
  if (allowWhitespace) {
    return asciiWithWhitespaceRegex.test(val);
  }
  return asciiRegex.test(val);
}

/**
 * This regex was used to validate CBA name field
 * See: https://github.com/unloan/unloan-app/issues/6723
 *
 * - It'll accept all alphanumeric characters, spaces, hyphens, and apostrophes
 */
const cbaNameFieldValidationRegex = /^[a-zA-Z0-9\s\-']+$/;
export function isCBANameFieldValid(val: string) {
  return cbaNameFieldValidationRegex.test(val);
}

const stringContainsNumberRegex = /\d/;
export function containsNumber(val: string) {
  return stringContainsNumberRegex.test(val);
}

const stringContainsNonNumberRegex = /\D/;
export function containsNonNumber(val: string) {
  return stringContainsNonNumberRegex.test(val);
}

// This supports decimal using period with 2 decimal places
const FLOAT_REGEX = /^[0-9]*([.]([0-9]{0,2}))?$/;
export function isStringFloat(val: string) {
  return FLOAT_REGEX.test(val);
}

export function formatInterestRate(number: number | null | undefined): string {
  if (number == null) {
    return '--%';
  }

  return `${number.toFixed(2)}%`;
}

export function formatInterestRates(rates: Array<number | null | undefined>) {
  if (rates.length === 0) {
    return '';
  }
  const formattedRates = rates.map((rate) =>
    rate === null || rate === undefined
      ? '--% p.a.'
      : `${rate.toFixed(2)}% p.a.`,
  );
  if (formattedRates.length === 1) {
    return formattedRates[0];
  }
  const allRatesExcludingLast = formattedRates.slice(0, -1).join(', ');
  const lastRate = formattedRates[formattedRates.length - 1];
  return `${allRatesExcludingLast} and ${lastRate}`;
}

export function capitalizeInput(text: string, type: 'word' | 'sentence') {
  let formattedText = text;
  if (type === 'sentence')
    formattedText = formattedText
      .slice(0, 1)
      .toUpperCase()
      .concat(formattedText.slice(1));
  if (type === 'word')
    formattedText = formattedText
      .split(' ')
      .map((word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)))
      .join(' ');
  return formattedText;
}

export function alphaNumericOnlyInput(text: string) {
  return text.replace(/[^a-z0-9 ]/gi, '');
}

export function escapeEmojis(text: string) {
  return text.replace(emojiRegex, '');
}

export function escapeChars(text: string, charsRegex: RegExp) {
  return text.replace(charsRegex, '');
}

const testIdSeparator = '-';
// This probably shouldn't be here
export function makeTestId(
  parts: Array<string | number | null | undefined>,
): string {
  return replaceSpaceWithSeparator(
    parts.filter(isNotNullOrUndefined).join(testIdSeparator),
    testIdSeparator,
  );
}
