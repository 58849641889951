import {
  Biza_Doms_Response_Status,
  GetBizaAccountByIdQuery,
  GetBizaAccountsQuery,
} from '../../generated/graphql';
import {
  BizaAccount,
  PendingDomRequestUser,
  PendingDomsRequest,
} from '../types';

export const getUserId = (data: GetBizaAccountByIdQuery) => {
  const user = data?.me[0]?.user;
  return user?.id;
};

export const getBizaAccounts = (data: GetBizaAccountsQuery): BizaAccount[] => {
  const user = data?.me[0]?.user;
  if (!user) return [];
  const { biza_accounts: accounts } = user;
  return accounts as BizaAccount[];
};

export const getBizaAccount = (
  data?: GetBizaAccountByIdQuery,
): BizaAccount | null => {
  if (!data) return null;
  const user = data?.me[0]?.user;
  if (!user) return null;
  const { biza_account_by_id: account } = user;
  return account as BizaAccount;
};

export const isUserPendingDomsRequest = (
  userId: string,
  pendingDomsRequest: PendingDomsRequest,
): boolean => {
  if (!pendingDomsRequest) return false;
  const { responses } = pendingDomsRequest;
  if (!responses) return false;
  const response = responses.find((each) => each.user?.id === userId);
  if (!response) return false;
  if (response.response === Biza_Doms_Response_Status.Pending) return true;
  return false;
};

export const getUsersWithDomsRequest = (
  _userId: string,
  pendingDomsRequest: PendingDomsRequest,
): PendingDomRequestUser[] => {
  if (!pendingDomsRequest) return [];
  const { responses } = pendingDomsRequest;
  if (!responses) return [];

  // TODO: Let's check this with design team.  The other area which has approvals is
  //       showing all account holders including the person who is logged in.
  //
  // const remainingResponses = responses.filter(
  //   (each) => each.user?.id !== userId,
  // );

  const users: PendingDomRequestUser[] = responses.map(
    (each) =>
      ({
        id: each.user?.id,
        name: each.user?.identity_profile?.full_name,
        status: each.response,
      } as PendingDomRequestUser),
  );
  return users;
};
