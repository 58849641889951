import { Text, View } from 'dripsy';
import { useMemo } from 'react';

import { ENV_CONFIG } from '../config';
import { ENVIRONMENT } from '../config/environment';
import {
  clearPersistedCustomBackend,
  isUsingCustomBackend,
} from '../utils/ephemeralEnvHelper';
import { usePersistedEnv } from '../utils/hooks/useEnvConfig';
import { isWeb } from '../utils/platformUtils';

const keywordColorPairs: Array<[keyword: string, color: string]> = [
  ['playpen1', '#18DE25'],
  ['playpen2', '#B1C478'],
  ['playpen3', '#D4188E'],
  ['playpen4', '#9C2E00'],
  ['playpen5', '#6F7383'],
  ['playpen6', '#AAB6EE'],
  ['playpen7', '#CCF8FB'],
  ['playpen8', '#9D6FEC'],
  ['playpen9', '#4D9E82'],
  ['playpen10', '#86E04C'],
];

function getBarColor(backendUrl: string) {
  const [_, color] =
    keywordColorPairs.find(([keyword]) => backendUrl.includes(keyword)) || [];

  return color || '#007AFF';
}

const lightTextColor = '#FFFFFF';
const darkTextColor = '#000000';

/**
 * This makes sure the text in the bar has enough contrast to be readable.
 * Similar to how GitHub light mode display labels.
 * Formula is from https://stackoverflow.com/a/3943023
 */
function getTextColor(backgroundColorHex: string) {
  const r = parseInt(backgroundColorHex.slice(1, 3), 16);
  const g = parseInt(backgroundColorHex.slice(3, 5), 16);
  const b = parseInt(backgroundColorHex.slice(5, 7), 16);
  const threshold = 149;
  return r * 0.299 + g * 0.587 + b * 0.114 > threshold
    ? darkTextColor
    : lightTextColor;
}

export function DebugTopBar({ testID }: { testID: string }) {
  const [env] = usePersistedEnv();
  const config = useMemo(() => ENV_CONFIG[env], [env]);

  if (!isWeb || env === ENVIRONMENT.PROD_V3 || !config.showDiagnostics) {
    return null;
  }

  if (isUsingCustomBackend()) {
    const graphqlUrl = config.graphqlURL;
    const color = getBarColor(graphqlUrl);
    const textStyle = {
      color: getTextColor(color),
      fontWeight: '600',
    };
    return (
      <View
        testID={testID}
        sx={{
          minHeight: 24,
          backgroundColor: color,
          alignItems: 'center',
          justifyContent: 'center',
          padding: '$4',
        }}
      >
        <Text variant="caption" sx={textStyle}>
          You&apos;re not using the default backend. Current backend:{' '}
          {graphqlUrl.replace(/^https?:\/\//, '')}.{' '}
          <Text
            onPress={() => {
              clearPersistedCustomBackend();
              window.location.reload();
            }}
            variant="caption"
            sx={textStyle}
          >
            Click here to use default backend and reload the page.
          </Text>
        </Text>
      </View>
    );
  }

  return null;
}
