import * as React from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { GTMAppInteractionEventDescription } from '../../Analytics/types';
import { ApplicationInteractionEventKeyType } from '../../Analytics/utils/gtmKeyUtils';
import { useSendDataToGTM } from '../../utils/hooks/useSendDataToGTM';
import { StyledIcon } from '../atoms/StyledIcon';
import { Color } from '../types';
import { createBox } from '../utils/createBox';

const BaseCheckbox = createBox();

export type Props = React.ComponentProps<typeof BaseCheckbox> & {
  checked?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  interactionKey: ApplicationInteractionEventKeyType;
};

export const CheckBox: React.FC<Props> = ({
  checked,
  disabled,
  onPress,
  testID,
  interactionKey,
  ...props
}) => {
  const { sendAppInteractionEventToGTM } = useSendDataToGTM();

  let iconColor: Color = disabled ? 'disabledContent' : 'buttonPrimaryBg';
  if (!checked) {
    iconColor = 'transparent';
  }
  const onCheckboxPress = React.useMemo(
    () => () => {
      onPress?.();
      if (interactionKey) {
        sendAppInteractionEventToGTM({
          description: GTMAppInteractionEventDescription.CheckboxClicked,
          additionalData: {
            application_interaction_event_key: interactionKey,
          },
        });
      }
    },
    [interactionKey, onPress, sendAppInteractionEventToGTM],
  );

  return (
    <TouchableOpacity
      role="checkbox"
      activeOpacity={0.8}
      onPress={onCheckboxPress}
      testID={testID}
    >
      <BaseCheckbox
        borderRadius="tag"
        borderWidth={1}
        width={22}
        height={22}
        borderColor="border"
        alignItems="center"
        {...(disabled && {
          borderColor: 'disabledContent',
        })}
        {...(checked && {
          bg: 'buttonPrimaryContent',
        })}
        {...props}
      >
        <StyledIcon
          name="checkmark"
          color={iconColor}
          style={[styles.icon, Platform.OS === 'web' && styles.web]}
        />
      </BaseCheckbox>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    lineHeight: 22,
    fontSize: 24,
    left: -1,
    textAlignVertical: 'center',
    textAlign: 'center',
    textShadowRadius: 0,
  },
  web: {
    lineHeight: 19,
    fontSize: 27,
    letterSpacing: -2,
  },
});
