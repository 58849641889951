import * as WebBrowser from 'expo-web-browser';
import { Component, ErrorInfo } from 'react';
import * as React from 'react';
import { Platform } from 'react-native';

import { ErrorFallback } from '../components/ErrorFallback';
import { captureException } from '../sentry';
import Auth0ProviderWrapper from './Auth0ProviderWrapper';

export const AUTH0_ERROR_MESSAGE = {
  UNAUTHORIZED: 'Access denied',
  INVALID_STATE: 'Invalid state',
};

if (Platform.OS === 'web') {
  try {
    WebBrowser.maybeCompleteAuthSession();
  } catch (_: unknown) {
    // This might fail when sessionStorage/localStorage is blocked
    // e.g. Blocking all cookies in mobile Safari
  }
}

type Props = {
  children: React.ReactNode;
};

/**
 * `AuthProvider` is our own AuthContext wrapped with Auth0Provider
 */
export class AuthProvider extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.message.startsWith('The operation is insecure.')) {
      // This is a known error,
      // happening when user blocks all cookies in Settings in iOS
      // Details: https://github.com/unloan/unloan-app/issues/5609
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log('Skipping report to Sentry...', { error, errorInfo });
      }
      return;
    }
    captureException(
      'Unhandled Auth0ProviderWrapper error',
      { errorInfo },
      error,
    );
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorFallback
          title={t('Content.Common.Error.Auth0Failure.Title')}
          caption={t('Content.Common.Error.Auth0Failure.Caption')}
        />
      );
    }
    return <Auth0ProviderWrapper>{children}</Auth0ProviderWrapper>;
  }
}
