import { useContext, useState } from 'react';

import { TestID } from '../../../testID/constants';
import { ErrorRow } from '../../components/ErrorRow';
import { ScreenLoadingContainer } from '../../components/ScreenLoadingContainer';
import { FeatureFlagsContext } from '../../FeatureFlags/context';
import { useNavigateToLoanApplicationScreen } from '../../LoanApplication/navigation/loanApplicationRouteMapping';
import { LoanApplicationSection } from '../../LoanApplication/navigation/loanApplicationSection';
import { Screen } from '../../navigation/types/screens';
import { ModalScreenContainer } from '../../ui/v2/ModalScreenContainer';
import { useAppSummaryScreenNavigation } from '../../utils/hooks/useAppSummaryScreenNavigation';
import { mapFrequencyOptionsForV2 } from '../../utils/mapFrequencyOptionsForV2';
import {
  ExpectedRentalIncomeForm,
  ExpectedRentalIncomeFormDetails,
} from '../components/ExpectedRentalIncomeForm';
import { ExpectedRentalIncomeUpdateWarningRow } from '../components/ExpectedRentalIncomeUpdateWarningRow';
import {
  useGetExpectedRentalIncomeForForm,
  useGetOptionsForExpectedRentalIncomeQuery,
  useUpdateRentalIncomeForV2Form,
} from '../graphql/incomeHooks';
import { YourIncomeModalScreenProps } from '../navigation/incomeTypes';

export type Props =
  YourIncomeModalScreenProps<Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL>;

export function EditExpectedRentalIncomeScreen({ navigation, route }: Props) {
  const { params } = route;
  const {
    loanApplicationId = '',
    incomeId = '',
    _initialErrorMessage = '',
  } = params || {};
  const [formErrorMessage, setFormErrorMessage] =
    useState(_initialErrorMessage);

  const { flags } = useContext(FeatureFlagsContext);
  const enableWarningForExpectedRentalIncomeUpdate =
    flags.ENABLE_WARNING_FOR_EXPECTED_RENTAL_INCOME_UPDATE;

  const { tryNavigateBackToSummary } = useAppSummaryScreenNavigation({
    navigation,
    route,
    loanApplicationId,
  });

  const { navigateToLoanApplicationScreen, loadingLoanApplicationMetadata } =
    useNavigateToLoanApplicationScreen(navigation, route, loanApplicationId);

  const navigateToMainScreen = () => {
    tryNavigateBackToSummary(() => {
      navigateToLoanApplicationScreen({
        section: LoanApplicationSection.Income,
      });
    });
  };

  const {
    initialValues,
    loading: initialValuesLoading,
    hasLoanApplicationProperty,
  } = useGetExpectedRentalIncomeForForm({ loanApplicationId, incomeId });

  const {
    frequencyOptions,
    stateOptions,
    loading: formOptionsLoading,
  } = useGetOptionsForExpectedRentalIncomeQuery();

  const { updateExpectedRentalIncome, isUpdatingRentalIncome } =
    useUpdateRentalIncomeForV2Form({
      loanApplicationId,
      incomeId,
    });

  const onSubmit = async (formValues: ExpectedRentalIncomeFormDetails) => {
    const { success, errorMessage } = await updateExpectedRentalIncome(
      formValues,
    );
    if (!success) {
      setFormErrorMessage(errorMessage);
      return;
    }
    navigateToMainScreen();
  };

  const loading =
    formOptionsLoading ||
    loadingLoanApplicationMetadata ||
    initialValuesLoading;

  const showUpdateWarning =
    !formErrorMessage &&
    hasLoanApplicationProperty &&
    enableWarningForExpectedRentalIncomeUpdate;

  return (
    <ModalScreenContainer
      headerText={t('Content.YourIncome.Form.ModalHeader.ExpectedRentalIncome')}
      scrollable
      onClose={navigateToMainScreen}
      hideBackButton
    >
      {loading ? (
        <ScreenLoadingContainer
          loading
          testID={TestID.ExpectedRentalIncomeForm.ScreenLoading}
        />
      ) : (
        <>
          <ErrorRow
            mb="l"
            message={formErrorMessage}
            testID={TestID.ExpectedRentalIncomeForm.ErrorText}
          />
          {showUpdateWarning ? (
            <ExpectedRentalIncomeUpdateWarningRow sx={{ mb: '$24' }} />
          ) : null}
          <ExpectedRentalIncomeForm
            screen={Screen.YOUR_INCOME_V2_EDIT_EXPECTED_RENTAL_INCOME_MODAL}
            initialValues={initialValues}
            onSubmit={onSubmit}
            isSubmitting={isUpdatingRentalIncome}
            frequencyOptions={mapFrequencyOptionsForV2(frequencyOptions)}
            stateOptions={stateOptions}
            onRentalExpenseLearnMoreClick={() => {
              navigation.navigate(Screen.SINGLE_V2_MODAL, {
                screen: Screen.RENTAL_EXPENSES_LEARN_MORE_V2_MODAL,
              });
            }}
          />
        </>
      )}
    </ModalScreenContainer>
  );
}
