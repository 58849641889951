import { Picker } from '@react-native-picker/picker';
import { SxProp, Text, ThemeColorName, useDripsyTheme, useSx } from 'dripsy';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { makeTestId } from '../../../utils/stringHelpers';
import { ChevronRightIcon } from '../../svgs/ChevronRightIcon';
import { InputRow } from '../InputRow';
import { SELECT_PLACEHOLDER_VALUE, SelectProps } from './model';

export function Select({
  label: pickerLabel,
  items,
  selectedValue,
  sx: sxProp,
  onValueChange: onValueChangeProp,
  focused,
  error,
  disabled,
  testID,
  ...otherProps
}: SelectProps) {
  const [labelId] = useState(() => uuidv4());
  const sx = useSx();

  const isPlaceholderSelected =
    selectedValue == null || selectedValue === SELECT_PLACEHOLDER_VALUE;

  const onValueChange = (value: unknown, index: number) => {
    onValueChangeProp?.(
      value === SELECT_PLACEHOLDER_VALUE ? null : value,
      index,
    );
  };

  let borderColor: ThemeColorName = '$border';
  let labelColor: ThemeColorName = '$secondary';
  let valueColor: ThemeColorName = '$labelsPrimary';
  let bgColor: ThemeColorName = '$inputBackground';

  if (isPlaceholderSelected) {
    valueColor = '$secondary';
  }
  if (error) {
    borderColor = '$error';
    valueColor = '$error';
  }
  if (focused) {
    borderColor = '$focus';
  }
  if (disabled) {
    labelColor = '$secondaryDisabled';
    bgColor = '$inputBackgroundDisabled';
    valueColor = '$secondary';
  }

  const mergedSx: SxProp = {
    borderColor,
    bg: bgColor,
    ...sxProp,
  };

  return (
    <InputRow
      dataSet={focused ? { focused: true } : { focusable: true }}
      sx={mergedSx}
    >
      <Text
        nativeID={labelId}
        variants={['tiny', 'inputLabel']}
        sx={{
          position: 'absolute',
          top: '$8',
          left: '$16',
          color: labelColor,
          opacity: isPlaceholderSelected ? 0 : 1,
        }}
      >
        {pickerLabel}
      </Text>
      <Picker
        testID={testID}
        onValueChange={onValueChange}
        {...otherProps}
        selectedValue={selectedValue ?? ''}
        // `enabled` does not work with iOS
        enabled={!disabled}
        style={sx({
          display: 'flex',
          flex: 1,
          pl: '$16',
          pr: '$32',
          borderRadius: '$input',
          variant: 'text.default',
          fontFamily: 'root',
          color: valueColor,
          ...(isPlaceholderSelected
            ? null
            : {
                pt: '$16',
              }),
        })}
        accessibilityLabelledBy={labelId}
      >
        <Picker.Item label={pickerLabel} value={SELECT_PLACEHOLDER_VALUE} />
        {items.map(({ label, value }) => (
          <Picker.Item
            key={value}
            label={label}
            value={value}
            testID={makeTestId([testID, value])}
          />
        ))}
      </Picker>
      <ChevronDownIcon disabled={disabled} />
    </InputRow>
  );
}

function ChevronDownIcon({ disabled }: Pick<SelectProps, 'disabled'>) {
  const { theme } = useDripsyTheme();

  return (
    <ChevronRightIcon
      size={12}
      style={{
        position: 'absolute',
        right: 16,
        transform: [{ rotate: '90deg' }, { translateY: 2 }],
        // Put icon behind the <select> to
        // allow clicking on the arrow itself
        zIndex: -1,
      }}
      color={disabled ? theme.colors.$secondaryDisabled : undefined}
    />
  );
}
