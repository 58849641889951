import { atom } from 'recoil';

export enum AutopaySetupConfirmationStep {
  RequiresNewAccountConfirmationPassed = 'requiresNewAccountConfirmationPassed',
  RequiresShortfallConfirmationPassed = 'requiresShortfallConfirmationPassed',
}

export const AutopaySetupConfirmationStepAtom =
  atom<AutopaySetupConfirmationStep | null>({
    key: 'autopaySetupConfirmationStepState',
    default: null,
  });
