import { Platform, StyleSheet } from 'react-native';

import { Link } from '../../ui/atoms/Link';
import { StyledText } from '../../ui/atoms/StyledText';

export const AppTerms = () => (
  <StyledText mb="l" variant="caption" textAlign="center">
    {t('Content.Auth.DisclaimerPart1')}
    <Link
      href={t('Link.TermsAndConditions')}
      variant="caption"
      style={styles.inlineLink}
    >
      {` ${t('Content.Auth.AppTermsLink')} `}
    </Link>
    {t('Content.Auth.DisclaimerPart2')}
    <Link
      color="link"
      href={t('Link.PrivacyPolicy')}
      variant="caption"
      style={styles.inlineLink}
    >
      {` ${t('Content.Auth.PrivacyPolicyLink')}.`}
    </Link>
  </StyledText>
);

const styles = StyleSheet.create({
  // @ts-expect-error This style is needed for web
  inlineLink: Platform.select({
    web: { display: 'contents' },
  }),
});
