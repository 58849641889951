import LottieView from 'lottie-react-native';
import { StyleSheet } from 'react-native';

import { TestID } from '../../../testID/constants';
import { Box } from '../../ui/atoms/Box';
import { chromaticIgnoreDataSet } from '../../utils/chromatic';

function SubmittingAutopayIndicator() {
  return (
    <Box
      centered
      dataSet={chromaticIgnoreDataSet}
      testID={TestID.SubmittingAutoPayIndicator}
    >
      <LottieView
        key="activity-indicator"
        style={styles.lottieView}
        autoPlay
        loop
        source={require('../../../assets/lottie/activityIndicatorGreen.json')}
      />
    </Box>
  );
}

const styles = StyleSheet.create({
  lottieView: {
    width: 171,
    height: 171,
  },
});

export default SubmittingAutopayIndicator;
