import { SxProp, useDripsyTheme, View } from 'dripsy';
import * as React from 'react';

import { TestID } from '../../../testID/constants';
import { Skeleton } from '../v2/Skeleton';

type LoadingRowGroupProps = {
  sx?: SxProp;
  children?: React.ReactNode;
};

export function LoadingRowGroup({ sx = {}, children }: LoadingRowGroupProps) {
  return (
    <View
      sx={{
        backgroundColor: '$inputBackground',
        borderRadius: '$card',
        ...sx,
      }}
    >
      {children}
    </View>
  );
}

type LoadingNavRowProps = {
  testID?: string;
  sx?: SxProp;
};

export function LoadingRow({ testID, sx = {} }: LoadingNavRowProps) {
  const { theme } = useDripsyTheme();

  return (
    <View
      testID={testID}
      sx={{
        paddingX: '$16',
        paddingTop: '$16',
        paddingBottom: '$24',
        ...sx,
      }}
    >
      <View testID={TestID.LoadingRow.Skeleton} sx={{ mb: '$4' }}>
        <Skeleton show width="60%" height={theme.space.$20} />
      </View>
      <View testID={TestID.LoadingRow.Skeleton}>
        <Skeleton show width="40%" height={theme.space.$10} />
      </View>
    </View>
  );
}
