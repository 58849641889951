import { useLoanAccountState } from '../../HomeLoan/graphql/loanAccountQueries';
import {
  INSIGHT_SVG_GRAPH_HEIGHT,
  INSIGHT_SVG_GRAPH_WIDTH,
} from '../components/InsightGraphContainer';

export type Installment = {
  balance: number;
  balanceEffective: number;
  balanceOffset: number;
  effectiveRate: number;
  paymentInterest: number;
  paymentPrincipal: number;
  paymentTotal: number;
};

export type RepaymentPoint = {
  x: number;
  y: number;
  amount: number;
  remainedTerms: number;
};

type Details = NonNullable<
  NonNullable<
    ReturnType<typeof useLoanAccountState>['data']
  >['term_remaining_insights']
>['details'];

export const generateTermRemainingGraphData = (details: Details) => {
  let stopProjected = false;
  let originalRepaymentPath = 'M';
  let projectedRepaymentPath = 'M';
  const originalRepaymentPoints: RepaymentPoint[] = [];
  const projectedRepaymentPoints: RepaymentPoint[] = [];

  const maxPaymentPrincipal = details[0]?.remained_principal_balance;
  const maxRemainedTermInMonths = details[0]?.remained_term_in_months;

  if (!maxPaymentPrincipal || !maxRemainedTermInMonths) {
    return {
      originalRepaymentPoints,
      originalRepaymentPath,
      projectedRepaymentPoints,
      projectedRepaymentPath,
    };
  }

  details.forEach(
    ({
      balance_effective,
      remained_principal_balance,
      remained_term_in_months,
    }) => {
      const originalRepaymentPoint: RepaymentPoint = {
        x: Number(
          (
            ((maxRemainedTermInMonths - remained_term_in_months) /
              maxRemainedTermInMonths) *
            INSIGHT_SVG_GRAPH_WIDTH
          ).toFixed(2),
        ),
        y: Number(
          (
            (1.0 - remained_principal_balance / maxPaymentPrincipal) *
            INSIGHT_SVG_GRAPH_HEIGHT
          ).toFixed(2),
        ),
        amount: remained_principal_balance,
        remainedTerms: remained_term_in_months,
      };

      const projectedRepaymentPoint: RepaymentPoint = {
        x: Number(
          (
            ((maxRemainedTermInMonths - remained_term_in_months) /
              maxRemainedTermInMonths) *
            INSIGHT_SVG_GRAPH_WIDTH
          ).toFixed(2),
        ),
        y: Number(
          (
            (1.0 - balance_effective / maxPaymentPrincipal) *
            INSIGHT_SVG_GRAPH_HEIGHT
          ).toFixed(2),
        ),
        amount: balance_effective,
        remainedTerms: remained_term_in_months,
      };

      if (Math.round(remained_principal_balance) !== 0) {
        originalRepaymentPoints.push(originalRepaymentPoint);
      }
      originalRepaymentPath += ` ${originalRepaymentPoint.x},${originalRepaymentPoint.y}`;

      if (!stopProjected) {
        projectedRepaymentPoints.push(projectedRepaymentPoint);
        projectedRepaymentPath += ` ${projectedRepaymentPoint.x},${projectedRepaymentPoint.y}`;

        if (balance_effective === 0) {
          stopProjected = true;
        }
      }
    },
  );

  return {
    originalRepaymentPoints,
    projectedRepaymentPoints,
    originalRepaymentPath,
    projectedRepaymentPath,
  };
};
