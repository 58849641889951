import { ReactNode, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { createContext } from '../../utils/createContext';
import { Box, BoxProps } from '../atoms/Box';
import { StyledIcon } from '../atoms/StyledIcon';
import { useTheme } from '../theme';

type Context = {
  /**
   * The row height need to be constant and specified
   * by the using code.
   * Making this dynamic requires using onLayout
   * or flex container with transform translate.
   */
  rowHeight: number;
};

const [useVerticalStepperCtx, CtxProvider] =
  createContext<Context>('VerticalStepper');

/**
 * FlatList style API
 */
type Props<T> = Context & {
  data: Array<T>;
  /**
   * Node to render on the right side of the stepper
   */
  renderItem: (item: T, index: number, itemState: StepState) => ReactNode;
  keyExtractor?: (item: T, index: number) => string;
  /**
   * Zero-based step
   */
  activeStep: number;
};

export type StepState = 'complete' | 'current' | 'incomplete';
type StepProps = {
  // Used by helper functions in the component
  // eslint-disable-next-line react/no-unused-prop-types
  position: 'first' | 'middle' | 'last';
  // Used by helper functions in the component
  // eslint-disable-next-line react/no-unused-prop-types
  state: StepState;
};

const STEP_COLUMN_WIDTH = 30;

function useVerticalStepperSizing() {
  const { rowHeight } = useVerticalStepperCtx();
  const { constants } = useTheme();
  const { barSpacing, bulletSize } = constants.verticalStepper;
  const barHeight = rowHeight - (barSpacing + bulletSize);
  return { ...constants.verticalStepper, barHeight, rowHeight };
}

function Bar({ color }: { color: BoxProps['bg'] }) {
  const { barHeight, barWidth, rowHeight, bulletSize, barSpacing } =
    useVerticalStepperSizing();
  // When offset is 0, the bar top position starts at the top of the current row
  // we want to translate the bar vertically so it starts
  // at the bullet + barSpacing.
  // Since the bullet is center aligned, we can calculate the
  // offset by calculating the distance between the distance
  // between the end of the row and the bullet.
  const offset = (rowHeight - bulletSize) / 2 - barSpacing;
  return (
    <Box
      bg={color}
      width={barWidth}
      height={barHeight}
      position="absolute"
      top={-offset}
      borderRadius="rounded"
    />
  );
}

function InactiveBar() {
  return <Bar color="border" />;
}

function ActiveBar() {
  return <Bar color="primaryContent" />;
}

function makeBarForStep({ position, state }: StepProps): ReactNode {
  if (position === 'first') {
    return null;
  }
  if (state === 'incomplete') {
    return <InactiveBar />;
  }
  return <ActiveBar />;
}

function Bullet(props: BoxProps) {
  const { bulletSize } = useVerticalStepperSizing();
  return (
    <Box
      width={bulletSize}
      height={bulletSize}
      borderRadius="rounded"
      {...props}
    />
  );
}

function CompleteBullet() {
  return (
    <Bullet bg="primaryContent" alignItems="center" justifyContent="center">
      <StyledIcon name="tick" family="svg" size="stepperIcon" color="bg" />
    </Bullet>
  );
}

function CurrentBullet() {
  const { bulletSize, currentBulletSize, barWidth } =
    useVerticalStepperSizing();
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      overflow="visible"
      width={bulletSize}
      height={bulletSize}
    >
      <Box
        position="absolute"
        borderRadius="rounded"
        width={currentBulletSize}
        height={currentBulletSize}
        style={styles.currentBulletWrapper}
      />
      <Bullet borderWidth={barWidth} borderColor="primaryContent" bg="bg" />
    </Box>
  );
}

function IncompleteBullet() {
  const { barWidth } = useVerticalStepperSizing();
  return <Bullet borderWidth={barWidth} borderColor="border" />;
}

function makeBulletForStep(props: StepProps) {
  switch (props.state) {
    case 'complete': {
      return <CompleteBullet />;
    }
    case 'current': {
      return <CurrentBullet />;
    }
    default: {
      return <IncompleteBullet />;
    }
  }
}

function Step(props: StepProps) {
  const { constants } = useTheme();
  const bar = makeBarForStep(props);
  const bullet = makeBulletForStep(props);
  return (
    <Box
      alignItems="center"
      overflow="visible"
      width={STEP_COLUMN_WIDTH}
      style={{ paddingTop: constants.verticalStepper.barSpacing }}
      justifyContent="center"
    >
      {bar}
      {bullet}
    </Box>
  );
}

function Row({ children, zIndex }: { children: ReactNode; zIndex: number }) {
  const { rowHeight } = useVerticalStepperCtx();
  return (
    <Box
      height={rowHeight}
      flexDirection="row"
      style={{ zIndex }}
      overflow="visible"
    >
      {children}
    </Box>
  );
}

function defaultKeyExtractor<T>(_item: T, index: number): string {
  return String(index);
}

function getPosition(length: number, index: number): StepProps['position'] {
  if (index === 0) {
    return 'first';
  }
  if (length - 1 === index) {
    return 'last';
  }
  return 'middle';
}

function getState(activeStep: number, index: number): StepState {
  if (activeStep > index) {
    return 'complete';
  }
  if (activeStep === index) {
    return 'current';
  }
  return 'incomplete';
}

function VerticalStepper<T>({
  data,
  renderItem,
  activeStep,
  keyExtractor: propKeyExtractor,
  rowHeight,
}: Props<T>) {
  const keyExtractor = propKeyExtractor || defaultKeyExtractor;
  const value = useMemo(() => ({ rowHeight }), [rowHeight]);
  return (
    <CtxProvider value={value}>
      {data.map((item, index) => {
        const stepState = getState(activeStep, index);
        return (
          <Row key={keyExtractor(item, index)} zIndex={index}>
            <Step
              position={getPosition(data.length, index)}
              state={stepState}
            />
            {renderItem(item, index, stepState)}
          </Row>
        );
      })}
    </CtxProvider>
  );
}

const styles = StyleSheet.create({
  currentBulletWrapper: {
    backgroundColor: '#00ACEA',
    opacity: 0.2,
  },
});

export default VerticalStepper;
