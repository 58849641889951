import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { ErrorFallback } from '../../components/ErrorFallback';
import { Screen } from '../../navigation/types/screens';
import { Button } from '../../ui/atoms/Button';

export function NoLoanApplicationFound() {
  const navigation = useNavigation();
  const navigateToHome = useCallback(() => {
    navigation.navigate(Screen.MAIN_NAVIGATOR, {
      screen: Screen.HOME_DASHBOARD,
      params: {
        screen: Screen.HOME,
      },
    });
  }, [navigation]);
  return (
    <ErrorFallback
      title={t('Content.Common.Error.LoanApplicationNotFoundTitle')}
      caption={t('Content.Common.Error.LoanApplicationNotFoundCaption')}
      safeAreaTop={false}
      safeAreaBottom={false}
      hideUnloanLogo
    >
      <Button
        label={t('Content.Common.ButtonLabel.BackToDashboard')}
        onPress={navigateToHome}
        my="m"
      />
    </ErrorFallback>
  );
}
