import { isNotNullOrUndefined } from '@unloan/common-ui';
import { Text, View } from 'dripsy';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import { TestID } from '../../../../testID/constants';
import { MergedLiabilityForSetupLoanScreenFragment } from '../../../generated/graphql';
import { addBreadcrumb } from '../../../sentry';
import { Skeleton } from '../../../ui/v2/Skeleton';
import { formatCurrency } from '../../../utils/currencyHelpers';
import { joinListOfString } from '../../../utils/stringHelpers';
import { useLoanAmountCalculationV2 } from '../../utils/useSetupLoanCalculation';

type SetupLoanTopUpFooterProps = ReturnType<
  typeof useLoanAmountCalculationV2
> & {
  loanApplicationId: string;
  termInMonths?: number;
  desiredLoanAmount?: number;
  sumTopUpAmount: number;
  sumSecurityCosts?: number;
  topUpLiability: MergedLiabilityForSetupLoanScreenFragment;
  redrawBalance?: number;
};

export function SetupLoanTopUpFooter({
  loanApplicationId,
  sumTopUpAmount,
  termInMonths,
  desiredLoanAmount,
  calculateLoanAmounts,
  calculationLoading,
  calculationData,
  sumSecurityCosts,
  topUpLiability,
  redrawBalance = 0,
}: SetupLoanTopUpFooterProps) {
  const debouncedCalculateSetupLoanAmounts = useRef(
    debounce(calculateLoanAmounts, 300),
  );

  useEffect(() => {
    addBreadcrumb('Calculate Setup Loan Amounts Args', {
      loan_application_id: loanApplicationId,
      loan_term_in_months: termInMonths,
      desired_loan_amount: desiredLoanAmount,
      top_up_amount: sumTopUpAmount,
    });
    debouncedCalculateSetupLoanAmounts.current({
      data: {
        loan_application_id: loanApplicationId,
        loan_term_in_months: termInMonths || 0,
        desired_loan_amount: desiredLoanAmount || 0,
        top_up_amount: sumTopUpAmount,
      },
    });
  }, [
    termInMonths,
    desiredLoanAmount,
    loanApplicationId,
    sumTopUpAmount,
    // When security costs change, we need to recalculate the total loan amount
    sumSecurityCosts,
  ]);

  const returning = calculationData?.calculate_app_total_loan_amount_v2;

  const topUpAmount =
    (returning?.total_loan_amount ?? 0) - (topUpLiability.dynamite_limit ?? 0);

  const formattedTopUpAmount = formatCurrency(topUpAmount, {
    withFractionOnRoundedAmount: true,
  });

  const formattedMinRepaymentAmount =
    returning?.min_repayment_error_type != null
      ? '$--'
      : formatCurrency(returning?.monthly_repayment_amount, {
          withFractionOnRoundedAmount: true,
        });

  const maximumLoanAmountCaption = useMemo(() => {
    const PREFIX =
      'Content.SetupLoanTopUp.SummarySection.MaximumLoanAmountCaption';

    const unpaidBalanceText = t(`${PREFIX}.UnpaidBalance`, {
      unpaidBalance: formatCurrency(topUpLiability.dynamite_balance, {
        withFractionOnRoundedAmount: true,
      }),
    });

    const redrawText =
      redrawBalance > 0
        ? t(`${PREFIX}.Redraw`, {
            redrawAmount: formatCurrency(redrawBalance, {
              withFractionOnRoundedAmount: true,
            }),
          })
        : undefined;

    const topUpText =
      topUpAmount > 0
        ? t(`${PREFIX}.TopUp`, {
            topUpAmount: formatCurrency(topUpAmount, {
              withFractionOnRoundedAmount: true,
            }),
          })
        : undefined;

    return joinListOfString(
      [unpaidBalanceText, redrawText, topUpText].filter(isNotNullOrUndefined) ??
        [],
    );
  }, [topUpLiability.dynamite_balance, redrawBalance, topUpAmount]);

  return (
    <>
      <Skeleton show={calculationLoading}>
        <Text
          variant="sBody"
          sx={{ mt: '$16', opacity: calculationLoading ? 0 : 1 }}
        >
          {t('Content.SetupLoanTopUp.SummarySection.YourTopUpOf')}{' '}
          <Text variant="sBody" sx={{ fontWeight: '700' }}>
            {formattedTopUpAmount}*
          </Text>
        </Text>
      </Skeleton>

      <Text
        variant="xsHeader"
        sx={{
          color: '$secondary',
          mt: '$16',
        }}
      >
        {t('Content.SetupLoanTopUp.SummarySection.MaximumLoanAmount')}
      </Text>

      <Skeleton show={calculationLoading}>
        <View sx={{ opacity: calculationLoading ? 0 : 1 }}>
          <Text
            variant="lNumber"
            testID={TestID.SetupLoanScreen.TotalLoanAmountText}
          >
            {formatCurrency(returning?.total_loan_amount, {
              withFractionOnRoundedAmount: true,
            })}
          </Text>
          <Text
            variant="caption"
            testID={TestID.SetupLoanScreen.MinRepaymentAmountText}
          >
            {maximumLoanAmountCaption}
          </Text>
          <Text
            variant="xsHeader"
            testID={TestID.SetupLoanScreen.MinRepaymentAmountText}
          >
            {t(
              'Content.SetupLoanTopUp.SummarySection.MinimumMonthlyRepayments',
            )}
            {formattedMinRepaymentAmount}**
          </Text>
        </View>
      </Skeleton>
    </>
  );
}
