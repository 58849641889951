import { Separator, SeparatorProps } from '../atoms/Separator';
import { ListRow, Props as ListRowProps } from './ListRow';
import { ListRowGroup, Props as ListRowGroupProps } from './ListRowGroup';
import { LoadingRow } from './LoadingRow';

export type DataRowGroupProps = Omit<
  ListRowGroupProps,
  'removeTopSeparator' | 'headerButtonLabel'
>;

// @deprecated - use CardList instead
export function DataRowGroup({
  pb = 0,
  mt = 'm',
  mx = 0,
  ...props
}: DataRowGroupProps) {
  return <ListRowGroup removeTopSeparator pb={pb} mt={mt} mx={mx} {...props} />;
}

type DataRowProps = Omit<ListRowProps, 'last'>;

// @deprecated - use ListItem instead
export function DataRow({
  labelFontWeight = 'semiBold',
  captionVariant = 'body',
  py = 's',
  leftAlignItems = 'flex-start',
  loading,
  label,
  caption,
  captionLink,
  subCaption,
  iconName,
  onPress,
  onRightButtonPress,
  testID,
  ...props
}: DataRowProps) {
  if (loading) {
    return <LoadingRow testID={testID} />;
  }

  return (
    <ListRow
      testID={testID}
      last
      labelFontWeight={labelFontWeight}
      captionVariant={captionVariant}
      py={py}
      leftAlignItems={leftAlignItems}
      label={label}
      caption={caption}
      captionLink={captionLink}
      subCaption={subCaption}
      iconName={iconName}
      onPress={onPress}
      onRightButtonPress={onRightButtonPress}
      {...props}
    />
  );
}

export function DataRowSeparator({ mx = 'm', ...props }: SeparatorProps) {
  return <Separator mx={mx} {...props} />;
}
