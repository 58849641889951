import { useCallback, useState } from 'react';

import { TestID } from '../../../testID/constants';
import {
  Change_Personal_Details_Error_Type,
  useMfaLoginVerify2faMutation,
  useResendMfaChallengeMutation,
} from '../../generated/graphql';
import { Screen } from '../../navigation/types/screens';
import { safelyCallMutation } from '../../utils/hooks/errorUtils';
import { OldMobileVerify } from '../components/OldMobileVerify';
import { PersonalDetailsModalScreenProps } from '../navigation/types';
import getErrorMessage from '../utils/getErrorMessage';

type Props =
  PersonalDetailsModalScreenProps<Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY>;

export const EmailChangeOldMobileVerify = ({ navigation, route }: Props) => {
  const { auth0MfaSessionId } = route.params;

  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [
    mfaLoginVerify2fa,
    { loading: mfaLoginVerify2faLoading, error: mfaLoginVerify2faError },
  ] = useMfaLoginVerify2faMutation();

  const [
    resendMfaChallenge,
    { loading: resendMfaChallengeLoading, error: resendMfaChallengeError },
  ] = useResendMfaChallengeMutation();

  const onPressResend = useCallback(async (): Promise<void> => {
    setValidationErrorMessage(undefined);
    setErrorMessage(undefined);

    const [response] = await safelyCallMutation(resendMfaChallenge, {
      variables: {
        auth0_mfa_session_id: auth0MfaSessionId,
      },
      context: {
        sentryContext: {
          description: 'Change Email(Resend Code) - Verify Old Mobile',
        },
      },
    });

    const errorType = response?.data?.resend_mfa_challenge?.error_type;

    if (errorType || !response?.data?.resend_mfa_challenge?.error_type) {
      const message = t('Content.PersonalDetails.Common.ResendFailed');
      setErrorMessage(message);
    }
  }, [
    resendMfaChallenge,
    auth0MfaSessionId,
    setErrorMessage,
    setValidationErrorMessage,
  ]);

  const onSubmit = useCallback(
    async ({ code }: { code: string }) => {
      setValidationErrorMessage(undefined);
      setErrorMessage(undefined);

      const [response] = await safelyCallMutation(mfaLoginVerify2fa, {
        variables: {
          auth0_mfa_session_id: auth0MfaSessionId,
          otp: code,
        },
        context: {
          sentryContext: {
            auth0MfaSessionId,
            description: 'Change Email - Verify Old Mobile',
          },
        },
      });

      const errorType = response?.data?.mfa_login_verify_2fa.error_type;

      if (errorType) {
        const message = getErrorMessage(errorType);

        if (errorType === Change_Personal_Details_Error_Type.SystemError) {
          setErrorMessage(message);
          return;
        }

        setValidationErrorMessage(message);
        return;
      }

      if (!response?.data?.mfa_login_verify_2fa.success) {
        const message = t(
          'Content.PersonalDetails.Common.UnableToProcessRequest',
        );
        setErrorMessage(message);
        return;
      }

      navigation.replace(Screen.PERSONAL_DETAILS_MODAL, {
        screen: Screen.PERSONAL_DETAILS_EMAIL_CHANGE_NEW_EMAIL,
        params: {
          auth0MfaSessionId,
        },
      });
    },
    [
      auth0MfaSessionId,
      mfaLoginVerify2fa,
      navigation,
      setErrorMessage,
      setValidationErrorMessage,
    ],
  );

  return (
    <OldMobileVerify
      screen={Screen.PERSONAL_DETAILS_EMAIL_CHANGE_OLD_MOBILE_VERIFY}
      title={t('Content.PersonalDetails.Common.VerifyMobileTitle')}
      description={t('Content.PersonalDetails.Common.VerifyMobileContent')}
      testID={TestID.PersonalDetails.ChangeEmailVerifyOldMobile}
      loading={mfaLoginVerify2faLoading}
      navigation={navigation}
      onSubmit={onSubmit}
      onPressResend={!resendMfaChallengeLoading ? onPressResend : undefined}
      inputValidationError={validationErrorMessage}
      errorMessage={
        mfaLoginVerify2faError || resendMfaChallengeError
          ? t('Content.PersonalDetails.Common.UnableToProcessRequest')
          : errorMessage
      }
    />
  );
};
