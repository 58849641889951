import {
  NavigationProp,
  ParamListBase,
  RouteProp,
} from '@react-navigation/native';
import { useCallback, useMemo } from 'react';

import { Loan_Application_Type_Enum } from '../../generated/graphql';
import { LoanApplicationV2StackParams } from '../../navigation/types/navTypes';
import { Screen } from '../../navigation/types/screens';
import { captureException } from '../../sentry';
import { useLoanApplication } from '../utils/useLoanApplication';
import { LoanApplicationSection } from './loanApplicationSection';

export type LoanApplicationScreenName = keyof LoanApplicationV2StackParams;

type LoanApplicationRouteMap = Record<
  LoanApplicationSection,
  {
    screenName: LoanApplicationScreenName;
    // Some screen will have next page, some will not
    // e.g: Screen.LOAN_APPLICATION_V2_COMPLETED does not have next page, because after user completed their application, they will be navigated back to Home screen
    nextScreenName?: LoanApplicationScreenName;
    screenOrder?: number;
  }
>;

type LoanApplicationRouteMapPerLoanApplicationType = Record<
  Loan_Application_Type_Enum | 'TOP_UP',
  LoanApplicationRouteMap
>;

export const PurchaseRouteMap: LoanApplicationRouteMap = {
  [LoanApplicationSection.Splash]: {
    screenName: Screen.LOAN_APPLICATION_V2_SPLASH,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_INTRO,
  },
  [LoanApplicationSection.Intro]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_INTRO,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_BORROWERS,
  },
  [LoanApplicationSection.Borrowers]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_BORROWERS,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME,
    screenOrder: 1,
  },
  [LoanApplicationSection.Income]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_INCOME,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES,
    screenOrder: 2,
  },
  [LoanApplicationSection.Expense]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_EXPENSES,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS,
    screenOrder: 3,
  },
  [LoanApplicationSection.Debts]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_DEBTS,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
    screenOrder: 4,
  },
  [LoanApplicationSection.ConditionalApproval]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
  },
  [LoanApplicationSection.Property]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_PROPERTY,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN,
    screenOrder: 5,
  },
  [LoanApplicationSection.YourUnloan]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_YOUR_UNLOAN,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY,
    screenOrder: 6,
  },
  [LoanApplicationSection.ReviewSummary]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_REVIEW_SUMMARY,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.InterstitialLoanSubmit]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.VerifyIncomeOpenBanking]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_VERIFY_INCOME_OB,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_SUBMIT,
  },
  [LoanApplicationSection.Submit]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_SUBMIT,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_COMPLETED,
  },
  [LoanApplicationSection.VOICompleted]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_VOI_COMPLETED,
    nextScreenName: Screen.LOAN_APPLICATION_PURCHASE_SUBMIT,
  },
  [LoanApplicationSection.Completed]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_COMPLETED,
  },
  [LoanApplicationSection.YourUnloanOffer]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_OFFER,
  },
  [LoanApplicationSection.YourUnloanOfferEsign]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_OFFER_ESIGN,
  },
};

export const RefiRouteMap: LoanApplicationRouteMap = {
  [LoanApplicationSection.Splash]: {
    screenName: Screen.LOAN_APPLICATION_V2_SPLASH,
    nextScreenName: Screen.LOAN_APPLICATION_V2_INTRO,
  },
  [LoanApplicationSection.Intro]: {
    screenName: Screen.LOAN_APPLICATION_V2_INTRO,
    nextScreenName: Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY,
  },
  [LoanApplicationSection.Property]: {
    screenName: Screen.LOAN_APPLICATION_V2_YOUR_PROPERTY,
    nextScreenName: Screen.LOAN_APPLICATION_V2_BORROWERS,
    screenOrder: 1,
  },
  [LoanApplicationSection.Borrowers]: {
    screenName: Screen.LOAN_APPLICATION_V2_BORROWERS,
    nextScreenName: Screen.LOAN_APPLICATION_V2_YOUR_INCOME,
    screenOrder: 2,
  },
  [LoanApplicationSection.Income]: {
    screenName: Screen.LOAN_APPLICATION_V2_YOUR_INCOME,
    nextScreenName: Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES,
    screenOrder: 3,
  },
  [LoanApplicationSection.Expense]: {
    screenName: Screen.LOAN_APPLICATION_V2_YOUR_EXPENSES,
    nextScreenName: Screen.LOAN_APPLICATION_V2_YOUR_DEBTS,
    screenOrder: 4,
  },
  [LoanApplicationSection.Debts]: {
    screenName: Screen.LOAN_APPLICATION_V2_YOUR_DEBTS,
    nextScreenName: Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
    screenOrder: 5,
  },
  [LoanApplicationSection.YourUnloan]: {
    screenName: Screen.LOAN_APPLICATION_V2_YOUR_UNLOAN,
    nextScreenName: Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY,
    screenOrder: 6,
  },
  [LoanApplicationSection.ReviewSummary]: {
    screenName: Screen.LOAN_APPLICATION_V2_REVIEW_SUMMARY,
    nextScreenName: Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.InterstitialLoanSubmit]: {
    screenName: Screen.LOAN_APPLICATION_V2_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.VerifyIncomeOpenBanking]: {
    screenName: Screen.LOAN_APPLICATION_V2_VERIFY_INCOME_OB,
    nextScreenName: Screen.LOAN_APPLICATION_V2_SUBMIT,
  },
  [LoanApplicationSection.Submit]: {
    screenName: Screen.LOAN_APPLICATION_V2_SUBMIT,
    nextScreenName: Screen.LOAN_APPLICATION_V2_COMPLETED,
  },
  [LoanApplicationSection.VOICompleted]: {
    screenName: Screen.LOAN_APPLICATION_V2_VOI_COMPLETED,
    nextScreenName: Screen.LOAN_APPLICATION_V2_SUBMIT,
  },
  [LoanApplicationSection.Completed]: {
    screenName: Screen.LOAN_APPLICATION_V2_COMPLETED,
  },
  [LoanApplicationSection.YourUnloanOffer]: {
    screenName: Screen.LOAN_APPLICATION_V2_OFFER,
  },
  [LoanApplicationSection.YourUnloanOfferEsign]: {
    screenName: Screen.LOAN_APPLICATION_V2_OFFER_ESIGN,
  },
  // TODO: remove this and fix typing
  [LoanApplicationSection.ConditionalApproval]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
  },
};

export const TopUpRouteMap: LoanApplicationRouteMap = {
  [LoanApplicationSection.Splash]: {
    screenName: Screen.LOAN_APPLICATION_V2_SPLASH,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_INTRO,
  },
  [LoanApplicationSection.Intro]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_INTRO,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY,
  },
  [LoanApplicationSection.Property]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_PROPERTY,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME,
    screenOrder: 1,
  },
  // Not used
  [LoanApplicationSection.Borrowers]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_BORROWERS,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME,
    screenOrder: 2,
  },
  [LoanApplicationSection.Income]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_INCOME,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES,
    screenOrder: 3,
  },
  [LoanApplicationSection.Expense]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_EXPENSES,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS,
    screenOrder: 4,
  },
  [LoanApplicationSection.Debts]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_DEBTS,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN,
    screenOrder: 5,
  },
  [LoanApplicationSection.YourUnloan]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_YOUR_UNLOAN,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY,
    screenOrder: 6,
  },
  [LoanApplicationSection.ReviewSummary]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_REVIEW_SUMMARY,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.InterstitialLoanSubmit]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_INTERSTITIAL_LOAN_SUBMIT,
  },
  [LoanApplicationSection.VerifyIncomeOpenBanking]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_VERIFY_INCOME_OB,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_SUBMIT,
  },
  [LoanApplicationSection.Submit]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_SUBMIT,
    nextScreenName: Screen.LOAN_APPLICATION_TOP_UP_COMPLETED,
  },
  [LoanApplicationSection.VOICompleted]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_VOI_COMPLETED,
  },
  [LoanApplicationSection.Completed]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_COMPLETED,
  },
  [LoanApplicationSection.YourUnloanOffer]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_OFFER,
  },
  [LoanApplicationSection.YourUnloanOfferEsign]: {
    screenName: Screen.LOAN_APPLICATION_TOP_UP_OFFER_ESIGN,
  },
  // TODO: remove this and fix typing
  [LoanApplicationSection.ConditionalApproval]: {
    screenName: Screen.LOAN_APPLICATION_PURCHASE_CONDITIONAL_APPROVAL,
  },
};

/**
 * This is a placeholder route map for Variation to satisfy typing.
 * This should be replaced with a valid route map once we are working on Variation flow.
 */
const VariationRouteMap: LoanApplicationRouteMap = RefiRouteMap;

const LoanApplicationRouteMapPerLoanType: LoanApplicationRouteMapPerLoanApplicationType =
  {
    [Loan_Application_Type_Enum.Purchase]: PurchaseRouteMap,
    [Loan_Application_Type_Enum.Refinance]: RefiRouteMap,
    TOP_UP: TopUpRouteMap,
    [Loan_Application_Type_Enum.Variation]: VariationRouteMap,
  };

export function getNextScreenNameForLoanApplicationWizard(
  loanApplicationType: Loan_Application_Type_Enum | 'TOP_UP',
  currentSection: LoanApplicationSection,
) {
  const nextScreenName =
    LoanApplicationRouteMapPerLoanType[loanApplicationType]?.[currentSection]
      ?.nextScreenName;
  return nextScreenName;
}

function getScreenNameByLoanApplicationSection(
  loanApplicationType: Loan_Application_Type_Enum,
  section: LoanApplicationSection,
) {
  const screenName =
    LoanApplicationRouteMapPerLoanType[loanApplicationType]?.[section]
      ?.screenName;

  return screenName;
}

function getScreenOrderByLoanApplicationScreenType(
  loanApplicationType: Loan_Application_Type_Enum | undefined,
  currentSection: LoanApplicationSection,
): number {
  const screenOrder = loanApplicationType
    ? LoanApplicationRouteMapPerLoanType[loanApplicationType]?.[currentSection]
        ?.screenOrder
    : 0;

  return screenOrder ?? 0;
}

export function useNavigateToLoanApplicationScreen<
  Nav extends NavigationProp<ParamListBase>,
  Route extends RouteProp<ParamListBase>,
>(navigation: Nav, route: Route, loanApplicationId: string | undefined) {
  const navigateToLoanApplicationModal = useCallback(
    (screenName: LoanApplicationScreenName) => {
      navigation.navigate(Screen.LOAN_APPLICATION_V2, {
        screen: screenName,
        params: loanApplicationId
          ? {
              loanApplicationId,
            }
          : {},
      });
    },
    [navigation, loanApplicationId],
  );

  const { loanApplicationType, loading: loadingLoanApplicationMetadata } =
    useLoanApplication(loanApplicationId);

  return useMemo(
    () => ({
      loanApplicationType,
      navigateToNextLoanApplicationScreen: ({
        currentSection,
      }: {
        currentSection: LoanApplicationSection;
      }) => {
        if (loanApplicationType == null) {
          navigation.goBack();
          return;
        }

        const nextScreenName = getNextScreenNameForLoanApplicationWizard(
          loanApplicationType,
          currentSection,
        );
        if (nextScreenName == null) {
          captureException(
            'Unhandled navigation in navigateToNextLoanApplicationScreen',
            {
              currentSection,
              screenName: route.name,
            },
          );
          navigation.goBack();
          return;
        }

        navigateToLoanApplicationModal(nextScreenName);
      },

      navigateToLoanApplicationScreen: ({
        section,
      }: {
        section: LoanApplicationSection;
      }) => {
        if (loanApplicationType == null) {
          navigation.goBack();
          return;
        }

        const screenName = getScreenNameByLoanApplicationSection(
          loanApplicationType,
          section,
        );
        navigateToLoanApplicationModal(screenName);
      },
      loadingLoanApplicationMetadata,
      getScreenOrder: ({
        currentSection,
      }: {
        currentSection: LoanApplicationSection;
      }) =>
        getScreenOrderByLoanApplicationScreenType(
          loanApplicationType,
          currentSection,
        ),
    }),
    [
      navigateToLoanApplicationModal,
      route.name,
      loanApplicationType,
      navigation,
      loadingLoanApplicationMetadata,
    ],
  );
}
