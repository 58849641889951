import { ReactNode } from 'react';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Box, BoxProps } from '../atoms/Box';
import { Button } from '../atoms/Button';
import { Separator } from '../atoms/Separator';
import { StyledIcon } from '../atoms/StyledIcon';
import { StyledText, StyledTextProps } from '../atoms/StyledText';
import { Theme } from '../theme';
import { Color, TextVariantProps } from '../types';
import { ListRowGroupInsetContext } from '../utils/ListRowGroupInsetContext';

export type Props = BoxProps & {
  headerText?: string;
  footerText?: string;
  footerTextTestID?: string;
  footerColor?: Color;
  onFooterTextLinkPress?: () => void;
  footerTextLink?: string;
  footerErrorMessage?: string | null;
  footerErrorMessageTestID?: string;
  uppercaseHeader?: boolean;
  spacer?: boolean;
  headerTextVariant?: TextVariantProps['variant'];
  inset?: boolean;
  childrenWrapperStyle?: StyleProp<ViewStyle>;
  headerLink?: string;
  headerButtonLabel?: string;
  headerButtonIconName?: string;
  headerButtonVariant?: keyof Theme['buttonVariants'];
  headerButtonTertiary?: boolean;
  headerButtonBg?: Color | null;
  headerButtonStyle?: StyleProp<ViewStyle>;
  headerButtonTestID?: string;
  headerButtonOnPress?: () => void;
  onHeaderLinkPress?: () => void;
  contentBg?: Color;
  removeTopSeparator?: boolean;
};

type ListRowFooterTextProps = Pick<Props, 'onFooterTextLinkPress'> &
  Omit<StyledTextProps, 'onPress' | 'variant'> & {
    children: ReactNode;
    testID?: string;
  };

function ListRowFooterText({
  color,
  onFooterTextLinkPress,
  children,
  testID,
  ...props
}: ListRowFooterTextProps) {
  return (
    <StyledText
      variant="caption"
      color={color}
      onPress={onFooterTextLinkPress}
      testID={testID}
      {...props}
    >
      {children}
    </StyledText>
  );
}

export const ListRowGroupWithInsetBorderRadius = 'card' as const;

export const ListRowGroup: React.FC<Props> = ({
  children,
  headerText,
  footerText,
  footerTextTestID,
  onFooterTextLinkPress,
  footerTextLink,
  spacer = true,
  headerTextVariant = 'caption',
  uppercaseHeader = headerTextVariant === 'caption',
  inset = true,
  childrenWrapperStyle,
  headerButtonLabel,
  headerButtonOnPress,
  headerButtonIconName,
  headerButtonVariant = 'pill',
  headerButtonTertiary,
  headerButtonBg,
  headerButtonStyle,
  headerButtonTestID,
  headerLink,
  onHeaderLinkPress,
  footerColor,
  footerErrorMessage,
  footerErrorMessageTestID,
  contentBg = 'shapeBg',
  mx = 'm',
  pb = 's',
  removeTopSeparator,
  ...otherProps
}) => (
  <ListRowGroupInsetContext.Provider value={inset}>
    <Box mx={mx} pb={pb} {...otherProps}>
      {removeTopSeparator && !headerButtonLabel ? null : (
        <Separator
          spacer={spacer}
          header={headerText}
          headerLink={headerLink}
          variant={headerTextVariant}
          uppercaseHeader={uppercaseHeader}
          onHeaderLinkPress={onHeaderLinkPress}
          borderBottomWidth={0}
          px={inset ? mx : 0}
        >
          {headerButtonLabel ? (
            <Button
              icon={headerButtonIconName}
              label={headerButtonLabel?.toUpperCase()}
              onPress={headerButtonOnPress}
              variant={headerButtonVariant}
              tertiary={headerButtonTertiary}
              testID={headerButtonTestID}
              bg={
                headerButtonBg === null
                  ? undefined
                  : headerButtonBg || 'buttonSecondaryBg'
              }
              color="buttonSecondaryContent"
              style={headerButtonStyle}
            />
          ) : null}
        </Separator>
      )}
      <Box
        bg={contentBg}
        borderRadius={inset ? ListRowGroupWithInsetBorderRadius : undefined}
        overflow={otherProps.overflow}
        style={childrenWrapperStyle}
      >
        {children}
      </Box>
      {footerErrorMessage ? (
        <Box row mx={mx} mt="s" alignItems="center" zIndex="down">
          <StyledIcon
            name="alert-circle"
            family="ionicons"
            color="error"
            mr="xs"
          />
          <ListRowFooterText color="error" testID={footerErrorMessageTestID}>
            {footerErrorMessage}
          </ListRowFooterText>
        </Box>
      ) : null}
      {footerText || footerTextLink ? (
        <ListRowFooterText
          mx={mx}
          mt="s"
          color={footerColor}
          testID={footerTextTestID}
        >
          {footerText}
          {onFooterTextLinkPress || footerTextLink ? (
            <ListRowFooterText
              color="link"
              onFooterTextLinkPress={onFooterTextLinkPress}
            >
              {footerTextLink}
            </ListRowFooterText>
          ) : null}
        </ListRowFooterText>
      ) : null}
    </Box>
  </ListRowGroupInsetContext.Provider>
);
